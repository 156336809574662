import React, { Component } from "react";
import SearchRental from "../../../Pages/RentalUnit/SearchRental";
import { downloadLeads, sendLeads } from "../../../../Services/ApiServices";
import LeadUnit from "./LeadUnit";
import "./table.css";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import Loader from "../../../Loader/Loader";
import Loader3 from "../../../Loader/loader3";
import { soraDetails } from "../../../../Services/JsonData";

class Lead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
      sora: soraDetails,
      unitDetail: [],
      listingIds: [],
      checkedAll: false,
      next: false,
      email: "",
      sendEmail: false,
    };
    this.checkedOne = this.checkedOne.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  filterLeads(property, unit) {
    let unitData = [];
    if (property === "Sora") {
      unitData = this.state.sora.filter((data) => data.unit === unit);
    }
    return unitData[0];
  }
  // changeSearch() {
  //   this.setState({ search: !this.state.search });
  // }
  checkedOne(name, id) {
    if (document.getElementById(name).checked === true) {
      document.getElementById(name).checked = false;
      let removeIds = this.state.listingIds.filter((data) => data !== id);
      this.setState({ listingIds: removeIds });
    } else {
      document.getElementById(name).checked = true;
      let ids = this.state.listingIds;
      ids.push(id);
      this.setState({ listingIds: ids });
    }
  }

  checkedAll() {
    let checkbox = document.getElementById("unitCheck");
    if (!this.state.checkedAll) {
      checkbox.indeterminate = true;
      this.setState({ checkedAll: true });
      let allIds = [];
      this.props.tableData.forEach((element) => {
        let check6 = document.getElementById("unitCheck" + element.id);
        check6.checked = true;
        allIds.push(element.id);
      });
      this.setState({ listingIds: allIds });
    } else {
      checkbox.indeterminate = false;
      this.setState({ checkedAll: false });
      this.props.tableData.forEach((element) => {
        let check7 = document.getElementById("unitCheck" + element.id);
        check7.checked = false;
      });
      this.setState({ listingIds: [] });
    }
  }
  downloadCsv(csvString) {
    let blob = new Blob([csvString]);
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "filename.csv");
    } else {
      let a = window.document.createElement("a");

      a.href = window.URL.createObjectURL(blob, {
        type: "text/plain",
      });
      a.download = "leads.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
  downloadList() {
    let item = {
      ids: this.state.listingIds,
    };
    let form_data = [];
    for (let key in item) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(item[key]);
      form_data.push(encodedKey + "=" + encodedValue);
    }
    form_data = form_data.join("&");
    downloadLeads(form_data).then((response) => {
      if (response.status === 200) {
        if (response.data.length !== 0) {
          this.props.pushAlert("Downloaded", "info");
          this.downloadCsv(response.data);
        }
      } else {
        this.props.pushAlert(
          "Something went wrong!, Please try again later.",
          "danger"
        );
      }
    });
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  checkEmail(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      (!re.test(String(value).toLowerCase()) || value === "") && this.state.next
    );
  }
  clickDropdown(e) {
    e.stopPropagation();
  }
  sendEmail(e) {
    e.stopPropagation();
    this.setState({ next: true, sendEmail: true });
    let item = {
      ids: this.state.listingIds,
      receipent: this.state.email,
    };
    let form_data = [];
    for (let key in item) {
      let encodedKey = encodeURIComponent(key);
      let encodedValue = encodeURIComponent(item[key]);
      form_data.push(encodedKey + "=" + encodedValue);
    }
    form_data = form_data.join("&");
    sendLeads(form_data).then((response) => {
      e.returnValue = true;
      if (response.status === 200) {
        if (response.data.length !== 0) {
          this.props.pushAlert("Email Send Successfully", "success");
          this.setState({ next: false, sendEmail: false });
        }
      } else {
        this.props.pushAlert(
          "Something went wrong!, Please try again later.",
          "danger"
        );
        this.setState({ next: true, sendEmail: false });
        this.props.pushAlert("Email not send.Try again later!", "warning");
      }
    });
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  render() {
    return (
      <>
        <div className="row ">
          <div className="col">
            <div
              className="card dashCover  shadow"
              style={{ minHeight: "500px" }}
            >
              <div
                className={
                  this.state.search
                    ? "row tHeadType1 mr-0 ml-0"
                    : "row  mr-0 ml-0"
                }
              >
                <div className="col-sm-4  ">
                  <h3 className=" pt-3 pl-1 tableHead cardHead">Leads</h3>
                </div>
                <div className="col-sm-8  pt-2">
                  {/* <label
                    onClick={() => this.changeSearch()}
                    className="nav-link pull-right"
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>{" "}
                    <span className="sr-only">(current)</span>
                  </label> */}
                  {this.state.listingIds.length !== 0 && (
                    <>
                      <div className="dropdown " style={{ zIndex: "10" }}>
                        <label
                          // onClick={() => this.downloadList()}
                          className="nav-link pull-right dropdown-toggle"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          <span className="sr-only">(current)</span>
                        </label>

                        <div
                          className="dropdown-menu dropdown-menu-right p-3"
                          aria-labelledby="dropdownMenuButton"
                          onClick={(e) => this.clickDropdown(e)}
                        >
                          <span className="span2 text-secondary mb-3">
                            {this.state.listingIds.length} Lead Lists selected
                          </span>

                          <ApplicationInput
                            name="Email"
                            type="email"
                            target="email"
                            important="true"
                            onChange={this.onchange}
                            errorMessage={
                              this.checkValidate(this.state.email)
                                ? " Email"
                                : " email correct format"
                            }
                            validate={this.checkEmail(this.state.email)}
                            value={this.state.email}
                          />
                          {this.state.sendEmail && <Loader3 />}
                          <button
                            onClick={(e) => this.sendEmail(e)}
                            className="tHeadType pull-right"
                            disabled={this.state.sendEmail}
                          >
                            {this.state.sendEmail && (
                              <>
                                <span className="span2 text-light ">
                                  please wait
                                </span>
                                <>&nbsp;&nbsp;</>
                              </>
                            )}
                            <i
                              className="fa fa-paper-plane"
                              aria-hidden="true"
                            ></i>{" "}
                            <span className="sr-only">(current)</span>
                          </button>
                        </div>
                      </div>
                      <label
                        onClick={() => this.downloadList()}
                        className="nav-link pull-right"
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>{" "}
                        <span className="sr-only">(current)</span>
                      </label>
                    </>
                  )}
                </div>
              </div>
              {this.props.refreshSearch && this.state.search && (
                <div className="row pr-4 pl-3 pb-2 dropDownSlow">
                  <div className="col RentalSearch1">
                    <SearchRental name="Email" search={this.props.search} />
                  </div>
                  <div className="col RentalSearch1">
                    <SearchRental name="Name" search={this.props.search} />
                  </div>
                  <div className="col RentalSearch1">
                    <SearchRental name="Mobile" search={this.props.search} />
                  </div>
                  <div className="col RentalSearch1">
                    <SearchRental name="Property" search={this.props.search} />
                  </div>
                  <div className="col RentalSearch1">
                    <SearchRental name="Unit" search={this.props.search} />
                  </div>
                  <button
                    className="tHeadType"
                    onClick={() => this.props.rentalRefreshFun()}
                    style={{ height: "40px", marginTop: "35px" }}
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                </div>
              )}
              {this.props.tableData.length !== 0 ? (
                <table
                  className="table table-striped table-hover table-responsive tableMaintain "
                  style={{ width: "100%" }}
                >
                  <thead className=" text-white ">
                    <tr className="bgDarkBlue rowTable">
                      {/* <th
                      className="headName"
                      scope="col"
                      style={{ width: "5%" }}
                    >
                      #
                    </th> */}
                      <th
                        className="headName text-center "
                        scope="col"
                        style={{ width: "10%", minWidth: "50px" }}
                      >
                        <div
                          className="custom-control custom-checkbox"
                          onClick={() => this.checkedAll()}
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input listCheck"
                            id="unitCheck"
                            style={{ background: "#000" }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck1"
                          ></label>
                        </div>
                      </th>
                      <th
                        className="headName"
                        scope="col"
                        style={{ width: "20%", minWidth: "300px" }}
                      >
                        Email
                      </th>
                      <th
                        className="headName"
                        scope="col"
                        style={{ width: "20%", minWidth: "200px" }}
                      >
                        Name
                      </th>
                      <th
                        className="headName "
                        scope="col"
                        style={{ width: "20%", minWidth: "200px" }}
                      >
                        Mobile
                      </th>
                      <th
                        className="headName"
                        scope="col"
                        style={{ width: "15%", minWidth: "200px" }}
                      >
                        Property
                      </th>
                      <th
                        className="headName text-center"
                        scope="col"
                        style={{ width: "15%", minWidth: "100px" }}
                      >
                        Unit
                      </th>
                    </tr>
                  </thead>
                  <tbody className="leadBody">
                    {this.props.tableData &&
                      this.props.tableData.map((tData, index) => (
                        <LeadUnit
                          key={index}
                          index={index}
                          data={tData}
                          unitDetail={this.filterLeads(
                            tData.property,
                            tData.rentalEntity
                          )}
                          checkedOne={this.checkedOne}
                        />
                      ))}
                  </tbody>
                </table>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Lead;
