import React, { Component } from "react";
import { sortText } from "../../../../Defined/Function";
import ImageViewer from "../../../UIServices/ImageViewer/ImageViewer2";
import "./MaintenanceRequestDescription.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
class MaintenanceRequestDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="maintenanceRequestCard">
        <span className="maintenanceRequestDetailText1 ">Request Details</span>
        {(this.props.roleStatus === "Landlord" &&
            this.props.data.landlordApprovel !== "approved" &&
            this.props.data.landlordApprovel !== "rejected" &&
            this.props.data.status !== "canceled" )&& 
        <span
          className="maintenanceRequestDetailText3 pointer  pull-right pt-1"
          data-target="#MaintenanceRequestChange"
          data-toggle="modal"
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Request Changes
        </span>}
        <h3 className="maintenanceRequestDetailText4  requestDescriptionText">
          {sortText(this.props.data.description, 0, 475)}
        </h3>
        {this.props.data.images&&(this.props.data.images.length > 0 && (
          <ImageViewer
            images={this.props.data.images}
            requestId={this.props.data.id}
            count={3}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    provider: state.providerReducer,
    providerArray: state.provider,
    user: state.userReducer,
    userDetailArray: state.user,
    role: state.RoleReducer,
    roleStatus: state.role,
  };
};
export default withRouter(
  connect(mapStateToProps, {})(MaintenanceRequestDescription)
);