import React, { useEffect, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";


function SignaturePad2(props) {
  const sigCanvas = useRef({});
  const [imageURL, setImageURL] = useState(null);
  const clear = () => {
    sigCanvas.current.clear();
    setImageURL(null);
  };
  const save = () => {
    var obj = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    props.changeSignatureObject(obj);
    if (
      obj !==
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC"
    ) {
      setImageURL(obj);
    }
  };

  useEffect(() => {
    save();
  });

  return (
    <div className="sigApp">
      <SignaturePad
        width="240"
        height="240"
        ref={sigCanvas}
        canvasProps={{
          className: "signatureCanvas border ",
        }}
        submitDisable={true}
      />
      <br></br>
      {/* <button className="buttonPri" onClick={save}>
        <i className="fa fa-floppy-o" aria-hidden="true"></i>
      </button> */}

      <button className="pri" onClick={clear}>
        <i className="fa fa-repeat" aria-hidden="true"></i>
      </button>
      {imageURL === null ? (
        <button
          className="secDisable pull-right"
          style={{ cursor: "not-allowed" }}
          data-toggle="tooltip"
          data-placement="bottom"
          title="Please provide and save your signature"
        >
          Continue
        </button>
      ) : (
        <button className="pull-right" onClick={props.next}>
          Continue
        </button>
      )}
    </div>
  );
}

export default SignaturePad2;
