import React, { Component } from "react";
import { withRouter } from "react-router";
import ApplicationInput from "../../../../UIServices/ApplicationInput";
import "../../officeApplication.css";

class OfficeApplicationStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      parentCompany: false,
    };
  }

  render() {
    return (
      <div className="row " id={"officeAppStep" + this.props.index}>
        <div className="col-3"></div>
        <div className="col-9  pt-1 d-flex justify-content-center">
          <div className="navBottom1">
            <p className="officeApplicationTitle mb-4 ">Business Information</p>

            <div className="row pr-3 pl-3">
              <div className="col-6 text-center ">
                <p className="officeApplicationSubTitle pull-left mb-3">
                  Number of Employees
                </p>
                <input
                  type="TEXT"
                  min="0"
                  className={
                    this.props.checkNull(
                      this.props.state.business.employessCount
                    )
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="employessCount"
                  onChange={this.props.onChange}
                  value={this.props.state.business.employessCount}
                  
                  placeholder="Numbers of Employed"
                />
                {this.props.checkNull(this.props.state.business.employessCount) && (
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Business Employee Count
                  </span>
                )}
              </div>
              <div className="col-6  ">
                <p className="officeApplicationSubTitle pull-left mb-3">
                  {" "}
                  Business Type{" "}
                </p>

                <select
                  className={
                    this.props.checkNull(this.props.state.business.businessType)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  value={this.props.state.business.businessType}
                  name={"businessType"}
                >
                  <option value="" disabled="">
                    Select value
                  </option>
                  <option value={"Retail"}>Retail</option>
                  <option value={"Wholesale"}>Wholesale</option>
                  <option value={"Manufacture"}>Manufacture</option>
                  <option value={"Other"}>Other</option>
                </select>
                {this.props.checkNull(this.props.state.business.businessType) && (
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Business Type
                  </span>
                )}
              </div>

              <div className="col-6  ">
                <p className="officeApplicationSubTitle  ">Check One </p>

                <label className="containerOfficeApp">
                  Sole Proprietor
                  <input
                    type="radio"
                    checked={
                      this.props.state.business.ownershipType ===
                        "Sole Proprietor" && "checked"
                    }
                    name="ownershipType"
                    value="Sole Proprietor"
                    onChange={this.props.onChange}
                  />
                  <span className="checkMark"></span>
                </label>
                <label className="containerOfficeApp">
                  Partnership
                  <input
                    type="radio"
                    checked={
                      this.props.state.business.ownershipType ===
                        "Partnership" && "checked"
                    }
                    name="ownershipType"
                    value="Partnership"
                    onChange={this.props.onChange}
                  />
                  <span className="checkMark"></span>
                </label>
                <label className="containerOfficeApp">
                  Corporation
                  <input
                    type="radio"
                    checked={
                      this.props.state.business.ownershipType ===
                        "Corporation" && "checked"
                    }
                    name="ownershipType"
                    value="Corporation"
                    onChange={this.props.onChange}
                  />
                  <span className="checkMark"></span>
                </label>
              </div>
              <div className="col-6 text-center ">
                <p className="officeApplicationSubTitle pull-left ">
                  Intended use of premises{" "}
                </p>
                <textarea
                  type="textarea"
                  className={
                    this.props.checkNull(this.props.state.business.intend)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="intend"
                  onChange={this.props.onChange}
                  value={this.props.state.business.intend}
                  placeholder="Intended use of premises"
                />
                  {this.props.checkNull(this.props.state.business.intend) && (
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Business Intend
                  </span>
                )}
              </div>
            </div>
            <div className="row pr-3 pl-3">
              <div className="col-6 text-center ">
                <p className="officeApplicationSubTitle pull-left mb-3">
                  Emergency Contact
                </p>
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.emergencyContactName)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="emergencyContactName"
                  onChange={this.props.onChange}
                  placeholder="Contact Name"
                  value={this.props.state.emergencyContactName}
                />
                {this.props.checkNull(this.props.state.emergencyContactName) && (
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Emergency Contact Name
                  </span>
                )}
              </div>
              <div className="col-6 text-center ">
                <p className="officeApplicationSubTitle pull-left mb-3">
                  Phone
                </p>
                <input
                  type="text"
                  className={
                    this.props.checkMobile(this.props.state.emergencyContactPhone)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="emergencyContactPhone"
                  onChange={this.props.onChange}
                  value={this.props.state.emergencyContactPhone}
                  placeholder="Phone no"
                />
                 {this.props.checkMobile(this.props.state.emergencyContactPhone) && (
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                      {this.props.checkNull(this.props.state.emergencyContactPhone)?"Please Enter Emergency Contact Phone Number":"Please Enter Emergency Contact Phone Number CorrectFormat"}
                  </span>
                )}
              </div>
            </div>
            <div className="row pr-3 pl-3">
              <div className="col-6  mt-3">
                <ApplicationInput
                  name="Do you have parent company ?"
                  type="optionYN"
                  target="parentCompany"
                  onChange={this.props.onchangeCheck}
                  value={this.props.state.parentCompany}
                />
              </div>
              {this.props.state.parentCompany && (
                <>
                  <div className="col-6 text-center mt-0 pt-0">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Parent Company Name
                    </p>
                    <input
                      type="text"
                      className={
                        this.props.checkNull(
                          this.props.state.parentCompanyName
                        )
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      name="parentCompanyName"
                      onChange={this.props.onChange}
                      placeholder="Parent Company Name"
                      value={this.props.state.parentCompanyName}
                      
                    />
                    {(this.props.checkNull(this.props.state.parentCompanyName) && 
                      <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                        Please Enter Parent Company Name 
                      </span>
                    )}
                  </div>
                  <div className="col-12 text-center ">
                    <p className="officeApplicationSubTitle pull-left ">
                      Address
                    </p>
                  </div>
                  <div className="col-6 text-center ">
                    <div className="input-group mb-3 ">
                      <input
                        type="text"
                        className={
                          this.props.checkNull(
                            this.props.state.parentCompanyAddress
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="parentCompanyAddress"
                        onChange={this.props.onChange}
                        placeholder="Address"
                        value={this.props.state.parentCompanyAddress}
                      />
                       {(this.props.checkNull(this.props.state.parentCompanyAddress) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Parent Company Address
                        </span>
                        )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group mb-3 ">
                      <input
                        type="text"
                        className={
                          this.props.checkNull(
                            this.props.state.parentCompanyState
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="parentCompanyState"
                        onChange={this.props.onChange}
                        placeholder="State"
                        value={this.props.state.parentCompanyState}
                      />
                      {(this.props.checkNull(this.props.state.parentCompanyState) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Parent Company State
                        </span>
                        )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group ">
                      <input
                        type="text"
                        className={
                          this.props.checkNull(
                            this.props.state.parentCompanyCity
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="parentCompanyCity"
                        onChange={this.props.onChange}
                        placeholder="City"
                        value={this.props.state.parentCompanyCity}
                      />
                       {(this.props.checkNull(this.props.state.parentCompanyCity) && 
                        <span className="textPri pull-left " style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Parent Company City
                        </span>
                        )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group ">
                      <input
                        type="text"
                        className={
                          this.props.checkNull(
                            this.props.state.parentCompanyZip
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="parentCompanyZip"
                        onChange={this.props.onChange}
                        placeholder="Zip"
                        value={this.props.state.parentCompanyZip}
                      />
                      
                      {(this.props.checkNull(this.props.state.parentCompanyZip) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Parent Company Zip
                        </span>
                        )}
                    </div>
                  </div>{" "}
                </>
              )}
            </div>

            {this.props.step === 1 && (
              <button
                className="pull-right mt-4"
                onClick={() => this.props.next()}
              >
                Continue
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfficeApplicationStep1);
