import React, { Component } from "react";
import { withRouter } from "react-router";
import "../../officeApplication.css";

class OfficeApplicationStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    
  }

  render() {
    return (
      <div className="row " id={"officeAppStep" + this.props.index}>
       <div className="col-3"></div>
            <div className="col-9  pt-1 d-flex justify-content-center">
              <div className="navBottom1">
          <p className="officeApplicationTitle mb-4 ">Rental Information</p>
          {(this.props.state.activeRental===1?<>
          <p className="officeApplicationSubTitle1 pb-0 mb-0">Present</p>
          <div className="row pr-3 pl-3">
            <div className="col-6 text-center">
              <p className="officeApplicationSubTitle pull-left ">
                Landlord Name
              </p>
              <div className="input-group ">
                <input
                  type="text"
                  placeholder="Landlord Name"
                  className={
                    this.props.checkNull(this.props.state.resPresentLandlord)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentLandlord"
                  onChange={this.props.onChange}
                  value={this.props.state.resPresentLandlord}
                />
                {(this.props.checkNull(this.props.state.resPresentLandlord) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Present Landlord Name
                  </span>
                  )}
              </div>
            </div>

            <div className="col-6 text-center">
              <p className="officeApplicationSubTitle pull-left ">Phone No</p>
              <div className="input-group ">
                <input
                  type="text"
                  className={
                    this.props.checkMobile(this.props.state.resPresentLandlordTp)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentLandlordTp"
                  onChange={this.props.onChange}
                  placeholder="Phone No"
                  value={this.props.state.resPresentLandlordTp}
                />
                {(this.props.checkMobile(this.props.state.resPresentLandlordTp) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                   {this.props.checkNull(this.props.state.resPresentLandlordTp)?"Please Enter Present Landlord Phone no":"Please Enter Present Landlord Phone no correct format"}
                  </span>
                  )}
              </div>
            </div>
          </div>
          <div className="row pr-3 pl-3">
            <div className="col-12 text-center ">
              <p className="officeApplicationSubTitle pull-left ">Address</p>
            </div>
            <div className="col-6 text-center ">
              <div className="input-group mb-3 ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPresentAddress)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentAddress"
                  onChange={this.props.onChange}
                  placeholder="Address"
                  value={this.props.state.resPresentAddress}
                />
                {(this.props.checkNull(this.props.state.resPresentAddress) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Present Resident Address
                  </span>
                  )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group mb-3 ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPresentState)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentState"
                  onChange={this.props.onChange}
                  placeholder="State"
                  value={this.props.state.resPresentState}
                />
                  {(this.props.checkNull(this.props.state.resPresentState) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Present Resident State
                  </span>
                  )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPresentCity)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentCity"
                  onChange={this.props.onChange}
                  placeholder="City"
                  value={this.props.state.resPresentCity}
                />
                {(this.props.checkNull(this.props.state.resPresentCity) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Present Resident City
                  </span>
                  )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPresentZip)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentZip"
                  onChange={this.props.onChange}
                  placeholder="Zip"
                  value={this.props.state.resPresentZip}
                />
                {(this.props.checkNull(this.props.state.resPresentZip) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Present Resident Zip
                  </span>
                  )}
              </div>
            </div>
          </div>
          <div className="row pr-3 pl-3">
            <div className="col-4 text-center">
              <p className="officeApplicationSubTitle pull-left ">From</p>
              <div className="input-group ">
                <input
                  type="date"
                  className={
                    this.props.checkNull(this.props.state.resPresentFrom)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentFrom"
                  onChange={this.props.onChangeDate}
                  placeholder="from"
                  value={this.props.state.resPresentFrom}
                />
                {(this.props.checkNull(this.props.state.resPresentFrom) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Occupancy From
                  </span>
                  )}
              </div>
            </div>
            <div className="col-4 text-center">
              <p className="officeApplicationSubTitle pull-left ">To</p>
              <div className="input-group ">
                
                <input
                  type="date"
                  className={
                    this.props.checkNull(this.props.state.resPresentTo)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentTo"
                  onChange={this.props.onChangeDate}
                  placeholder="to"
                  value={this.props.state.resPresentTo}
                />
                 {(this.props.checkNull(this.props.state.resPresentTo) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Occupancy To
                  </span>
                  )}
              </div>
            </div>
            <div className="col-4 text-center">
              <p className="officeApplicationSubTitle pull-left ">
                Monthly Rent
              </p>
              <div className="input-group ">
              <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fa fa-usd" aria-hidden="true"></i></span>
                </div>
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPresentRent)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPresentRent"
                  min="0"
                  onChange={this.props.onChange}
                  placeholder="Rent"
                  value={this.props.state.resPresentRent}
                />
                {(this.props.checkNull(this.props.state.resPresentRent) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Present Rental Rent
                  </span>
                  )}
              </div>
            </div>
          </div>
          </>:<div className="officeBar" onClick={()=>  this.props.setParentState("activeRental",1)}> <p className="officeApplicationSubTitle1 pb-0 mb-0"> Present Resident Info</p></div>)}
          {this.props.state.stageRental>1&&(this.props.state.activeRental===2?<>
          <p className="officeApplicationSubTitle1 pb-0 mb-0">Previous</p>
          <div className="row pr-3 pl-3">
            <div className="col-6 text-center">
              <p className="officeApplicationSubTitle pull-left ">
                Landlord Name
              </p>
              <div className="input-group">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPreviousLandlord)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousLandlord"
                  onChange={this.props.onChange}
                  placeholder="Landlord Name"
                  value={this.props.state.resPreviousLandlord}
                />
                {(this.props.checkNull(this.props.state.resPreviousLandlord) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous Landlord Name
                  </span>
                  )}
              </div>
            </div>

            <div className="col-6 text-center">
              <p className="officeApplicationSubTitle pull-left ">Phone No</p>
              <div className="input-group ">
                <input
                  type="text"
                  className={
                    this.props.checkMobile(this.props.state.resPreviousLandlordTp)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousLandlordTp"
                  onChange={this.props.onChange}
                  placeholder="Phone No"
                  value={this.props.state.resPreviousLandlordTp}
                  
                />
                  {(this.props.checkMobile(this.props.state.resPreviousLandlordTp) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                      {this.props.checkNull(this.props.state.resPreviousLandlordTp)?"Please Enter Previous Landlord Phone no":"Please Enter Previous Landlord Phone no correct format"}
                  </span>
                  )}
              </div>
            </div>
          </div>
          <div className="row pr-3 pl-3">
            <div className="col-12 text-center ">
              <p className="officeApplicationSubTitle pull-left ">Address</p>
            </div>
            <div className="col-6 text-center ">
              <div className="input-group mb-3 ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPreviousAddress)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousAddress"
                  onChange={this.props.onChange}
                  placeholder="Address"
                  value={this.props.state.resPreviousAddress}
                />
                {(this.props.checkNull(this.props.state.resPreviousAddress) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous Resident Address
                  </span>
                  )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group mb-3 ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPreviousState)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousState"
                  onChange={this.props.onChange}
                  placeholder="State"
                  value={this.props.state.resPreviousState}
                />
                 {(this.props.checkNull(this.props.state.resPreviousState) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous Resident State
                  </span>
                  )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPreviousCity)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousCity"
                  onChange={this.props.onChange}
                  placeholder="City"
                  value={this.props.state.resPreviousCity}
                />
                {(this.props.checkNull(this.props.state.resPreviousState) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous Resident City
                  </span>
                  )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input
                  type="text"
                  className={
                    this.props.checkNull(this.props.state.resPreviousZip)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousZip"
                  onChange={this.props.onChange}
                  placeholder="Zip"
                  value={this.props.state.resPreviousZip}
                />
                {(this.props.checkNull(this.props.state.resPreviousState) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous Resident Zip
                  </span>
                  )}
              </div>
            </div>
          </div>
          <div className="row pr-3 pl-3">
            <div className="col-4 text-center">
              <p className="officeApplicationSubTitle pull-left ">From</p>
              <div className="input-group ">
                <input
                  type="date"
                  className={
                    this.props.checkNull(this.props.state.resPreviousFrom)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousFrom"
                  onChange={this.props.onChangeDate}
                  placeholder="from"
                  value={this.props.state.resPreviousFrom}
                />
                {(this.props.checkNull(this.props.state.resPreviousFrom) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous occupancy from
                  </span>
                  )}
              </div>
            </div>
            <div className="col-4 text-center">
              <p className="officeApplicationSubTitle pull-left ">To</p>
              <div className="input-group ">
                
                <input
                  type="date"
                  className={
                    this.props.checkNull(this.props.state.resPreviousTo)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  name="resPreviousTo"
                  onChange={this.props.onChangeDate}
                  placeholder="to"
                  value={this.props.state.resPreviousTo}
                />
                {(this.props.checkNull(this.props.state.resPreviousTo) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous occupancy to
                  </span>
                )}
              </div>
            </div>
            <div className="col-4 text-center">
              <p className="officeApplicationSubTitle pull-left ">
                Monthly Rent
              </p>
              <div className="input-group ">
              <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fa fa-usd" aria-hidden="true"></i></span>
                </div>
                <input
                  type="number"
                  className={
                    this.props.checkNull(this.props.state.resPreviousRent)
                      ? "form-control alert mb-0"
                      : "form-control"
                  }
                  min="0"
                  name="resPreviousRent"
                  onChange={this.props.onChange}
                  placeholder="Rent"
                  value={this.props.state.resPreviousRent}
                />
                {(this.props.checkNull(this.props.state.resPreviousTo) && 
                  <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                    Please Enter Previous resident rent
                  </span>
                )}
              </div>
            </div>
          </div>
          </>:<div className="officeBar" onClick={()=>  this.props.setParentState("activeRental",2)}> <p className="officeApplicationSubTitle1 pb-0 mb-0"> Previous Residence Info</p></div>)}
          {this.props.step === 2 ?(this.props.state.stageRental>1?
           (<>
            <button
              className="pull-right mt-4"
              onClick={() => this.props.next()}
            >
              Continue
            </button>
            <button
               className="pull-left mt-4"
               onClick={() => this.props.removeRental()}
             >
               Remove Previous Info
             </button></>
          ):(
            <>
             <button
              className="pull-right mt-4"
              onClick={() => this.props.next()}
            >
              Continue
            </button>
               <button
               className="pull-left mt-4"
               onClick={() => this.props.addRental()}
             >
               Add Previous Info
             </button>
             </>
          )):(this.props.state.stageRental>1?
            (<>

             <button
                className="pull-left mt-4"
                onClick={() => this.props.removeRental()}
              >
                Remove Previous Info
              </button></>
           ):(
             <>
             
                <button
                className="pull-left mt-4"
                onClick={() => this.props.addRental()}
              >
                Add Previous Info
              </button>
              </>
           ))}
        </div>
      </div>
      </div>
    );
  }
}

export default withRouter(OfficeApplicationStep2);
