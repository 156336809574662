import React, { Component } from "react";
import "../maintenance.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  appendAlert,
  setMaintenance,
  appendTempUsers,
  setContract,
  setProvider,
  appendTempProfileImage,
  getTempProfileImage,
} from "../../../../actions";
import {
  betweenMinutes,
  convertGMT,
  dateTimeFormat,
  dateToDateOnly,
  dateToTime,
  removeDuplicate,
  timeFormat,
  today,
} from "../../../../Defined/Function";

import { CountryJson } from "../../Property/CountryJson";
import Dropzone from "react-dropzone-uploader";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import {
  addProvider,
  changeMaintenanceRequestLandlordStatus,
  changeMaintenanceRequestTenantStatus,
  createMaintenanceAppointment,
  getLandlordContracts,
  getMaintenanceRequest,
  getPropertyLists,
  getTenantContracts,
  sendProviderInvitation,
  userDetail,
  url,
} from "../../../../Services/ApiServices";
import Loader from "../../../Loader/Loader";
import TimeInput from "../../../UIServices/TimeInput";
import Gallery from "react-grid-gallery";
import Loader3 from "../../../Loader/loader3";
import SearchInput from "../../Application/SearchInput";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import FullNotification from "../../../UIServices/FullNotification/FullNotification";
import "./maintenanceRequestDetail.css";
import MaintenanceHistory from "../MaintenanceHistory/MaintenanceHistory";
import BasicMaintenanceRequestDetails from "../BasicDetails/BasicMaintenanceRequestDetails";
import MaintenanceRequestDescription from "../MaintenanceRequestDescription/MaintenanceRequestDescription";
import MaintenanceRequestOrder from "../MaintenanceRequestOrder/MaintenanceRequestOrder";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import image from "../../../../Images/profile.png";
import imageLoader from "../../../../Images/imageLoader.gif";

class MaintenanceRequestTenantDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:
        this.props.location.state !== undefined
          ? this.props.location.state.data
          : [],
      tenantDetails:
        this.props.location.state !== undefined
          ? this.props.location.state.tenantDetails
          : [],
      landlordDetails:
        this.props.location.state !== undefined
          ? this.props.location.state.landlordDetails
          : [],
      contractData:
        this.props.location.state !== undefined
          ? this.props.location.state.contractData
          : [],
      unitData:
        this.props.location.state !== undefined
          ? this.props.location.state.unitData
          : {
              rentalEntity: [],
              address: [],
            },
      coverImage: [],
      next: false,
      catFinder: 0,
      serviceProviderFirstName: "",
      serviceProviderLastName: "",
      serviceProviderEmail: "",
      serviceProviderMobile: "",

      address: "",
      state: "",
      city: "",
      country: "",
      zip: "",
      workingState: "",
      workingCity: "",
      companyName: "",

      provider: "",
      activeProvider: [],
      providerAlert: false,

      countryJson: [],
      stateJson: [],
      cityJson: [],
      countryList: [],
      stateList: [],
      cityList: [],

      workingCityJson: [],
      workingStateJson: [],
      workingCountryJson: [],
      workingCountryList: [],
      workingStateList: [],
      workingCityList: [],
      catFinderProvider: 0,
      descriptionProvider: "",
      skills: [],
      emailAlreadyExists: false,

      serviceChange: "",
      serviceChangeCount: 0,
      loader: this.props.location.state === undefined,
      editRequestModal: false,
      editRequestModalLoader: false,
      addProviderModal: false,
      addProviderModalLoader: false,
      providerModal: "add",
      providerInvitationLoader: false,
      descriptionInvitationProvider: "",
      providerInvitationSuccess: false,
      providerLink: "",

      category:
        this.props.location.state !== undefined
          ? this.props.location.state.data.category
          : "",
      subCategory:
        this.props.location.state !== undefined
          ? this.props.location.state.data.subCategory
          : "",
      description:
        this.props.location.state !== undefined
          ? this.props.location.state.data.description
          : "",
      appointmentDate:
        this.props.location.state !== undefined
          ? this.props.location.state.data.tenantAvailablityTimes.length > 0
            ? this.props.location.state.data.tenantAvailablityTimes[0].date
            : ""
          : "",
      appointmentAnotherDate:
        this.props.location.state !== undefined
          ? this.props.location.state.data.tenantAvailablityTimes.length > 1
            ? this.props.location.state.data.tenantAvailablityTimes[1].date
            : ""
          : "",
      appointmentTime:
        this.props.location.state !== undefined
          ? this.props.location.state.data.tenantAvailablityTimes.length > 0
            ? this.props.location.state.data.tenantAvailablityTimes[0].time
            : ""
          : "",
      appointmentTime1:
        this.props.location.state !== undefined
          ? this.props.location.state.data.tenantAvailablityTimes.length > 0
            ? this.props.location.state.data.tenantAvailablityTimes[0].time
            : ""
          : "",
      appointmentAnotherTime:
        this.props.location.state !== undefined
          ? this.props.location.state.data.tenantAvailablityTimes.length > 1
            ? this.props.location.state.data.tenantAvailablityTimes[1].time
            : ""
          : "",
      appointmentAnotherTime1:
        this.props.location.state !== undefined
          ? this.props.location.state.data.tenantAvailablityTimes.length > 1
            ? this.props.location.state.data.tenantAvailablityTimes[1].time
            : ""
          : "",
      maintenanceRequest: [
        "Plumbing",
        "Electrical",
        "Handyman",
        "HVAC",
        "Pest Control",
      ],
      cat: [
        [],
        [
          "Drain Repair",
          "Slink Replacement",
          "Plumbing Service",
          "Bathroom Repair",
          "Faucet Repair",
        ],

        [
          "Lights",
          "Wiring",
          "Lights",
          "Circuit breaker panel or fuse box",
          "Switches",
          "Ceiling fan",
        ],

        [
          "Broken Door",
          "Broken Windows",
          "Walls(inside)",
          "Walls(outside)",
          "Flooring",
          "Cabinets Repair",
        ],

        ["Heater repair", "Ventilation", "Air conditioner repair"],
        ["Insect Removal", "Rodent Elimination", "Bird Prevention"],
      ],
      currentImage: "",
      deleteImage: false,
      activeEstimate: [],
      activeEstimateSelected: {},
      finalizedEstimate: false,

      jobAppointmentStartDate: "",
      jobAppointmentStartTime: "",
      jobAppointmentStartTime1: "",
      jobAppointmentEndDate: "",
      jobAppointmentEndTime: "",
      jobAppointmentEndTime1: "",
      jobAppointmentDescription: "",
      jobAppointmentApproveCost: "",
      finalizedLoader: false,
      sendQuotationTimeDiff: false,
      tenantImage: imageLoader,
      landlordImage: imageLoader,
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeState = this.changeState.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.clickAddProvider = this.clickAddProvider.bind(this);
    this.clickEditProvider = this.clickEditProvider.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.acceptQuotation = this.acceptQuotation.bind(this);
    this.openEstimate = this.openEstimate.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.editRequest = this.editRequest.bind(this);
  }

  async loadMaintenance() {
    try {
      getMaintenanceRequest().then((response) => {
        if (response.status === 200) {
          response.data = response.data.sort((a, b) =>b.updatedAt.localeCompare(a.updatedAt));
          this.props.setMaintenance(response.data);
          var maintenanceReq = response.data.filter(
            (todo) =>
              parseInt(todo.id) === parseInt(this.props.match.params.requestId)
          );

          if (maintenanceReq.length === 0) {
            this.props.history.push({ pathname: "/maintenance" });
          } else {
            this.setState({ data: maintenanceReq[0] });
            var data = maintenanceReq[0];
            if (data.category === "Plumbing") {
              this.setState({
                catFinder: 1,
                catFinderProvider: 1,
              });
            } else if (data.category === "Electrical") {
              this.setState({
                catFinder: 2,
                catFinderProvider: 2,
              });
            } else if (data.category === "Handyman") {
              this.setState({
                catFinder: 3,
                catFinderProvider: 3,
              });
            } else if (data.category === "HVAC") {
              this.setState({
                catFinder: 4,
                catFinderProvider: 4,
              });
            } else if (data.category === "Pest Control") {
              this.setState({
                catFinder: 5,
                catFinderProvider: 5,
              });
            }
            this.setState({
              category: data.category,
              subCategory: data.subCategory,
              description: data.description,
            });
            if (data.tenantAvailablityTimes.length > 0) {
              this.setState({
                appointmentDate: data.tenantAvailablityTimes[0].date,
                appointmentTime: data.tenantAvailablityTimes[0].time,
                appointmentTime1: data.tenantAvailablityTimes[0].time,
              });
            }
            if (data.tenantAvailablityTimes.length > 1) {
              this.setState({
                appointmentAnotherDate: data.tenantAvailablityTimes[1].date,
                appointmentAnotherTime: data.tenantAvailablityTimes[1].time,
                appointmentAnotherTime1: data.tenantAvailablityTimes[1].time,
              });
            }

            if (this.props.location.state === undefined) {
              let usersTenant = this.props.tempUsersArray.filter(
                (todo) => todo.username === data.tenantId
              );
              if (usersTenant.length === 0) {
                this.getTenantDetails();
              } else {
                this.filterTenantImage(usersTenant[0]);
              }

              let usersLandlord = this.props.tempUsersArray.filter(
                (todo) => todo.username === data.landlordId
              );
              if (usersLandlord.length === 0) {
                this.getLandlordDetails();
              } else {
                this.filterLandlordImage(usersLandlord[0]);
              }
            } else {
              if (
                this.props.location.state.landlordDetails === undefined ||
                this.props.location.state.landlordDetails.length === 0
              ) {
                let usersLandlord = this.props.tempUsersArray.filter(
                  (todo) => todo.username === data.landlordId
                );
                if (usersLandlord.length === 0) {
                  this.getLandlordDetails();
                } else {
                  this.filterLandlordImage(usersLandlord[0]);
                }
              } else {
                this.filterLandlordImage(
                  this.props.location.state.landlordDetails
                );
              }
              if (
                this.props.location.state.tenantDetails === undefined ||
                this.props.location.state.tenantDetails.length === 0
              ) {
                let usersTenant = this.props.tempUsersArray.filter(
                  (todo) => todo.username === data.tenantId
                );
                if (usersTenant.length === 0) {
                  this.getTenantDetails();
                } else {
                  this.filterTenantImage(usersTenant[0]);
                }
              } else {
                this.filterTenantImage(this.props.location.state.tenantDetails);
              }
            }

            try {
              if (this.props.roleStatus === "Tenant") {
                getTenantContracts().then((response) => {
                  if (response.status === 200) {
                    if (response.data.length !== 0) {
                      var cont = response.data.filter(
                        (contract) =>
                          contract.contractId === parseInt(data.contractId)
                      )[0];
                      this.setState({
                        contractData: cont,
                      });
                      getPropertyLists(cont.rentalEntityId).then(
                        async (response1) => {
                          if (response1.status === 200) {
                            if (response1.data.length !== 0) {
                              await this.setState({
                                unitData: response1.data,
                              });
                              this.setState({ loader: false });
                            }
                          } else {
                            this.setState({ loader: false });
                          }
                        }
                      );
                    }
                  } else {
                    this.setState({ loader: false });
                  }
                });
              } else {
                getLandlordContracts().then((response) => {
                  if (response.status === 200) {
                    if (response.data.length !== 0) {
                      var cont = response.data.filter(
                        (contract) =>
                          contract.contractId === parseInt(data.contractId)
                      )[0];
                      this.setState({
                        contractData: cont,
                      });
                      getPropertyLists(cont.rentalEntityId).then(
                        async (response1) => {
                          if (response1.status === 200) {
                            if (response1.data.length !== 0) {
                              await this.setState({
                                unitData: response1.data,
                              });
                              this.setState({ loader: false });
                            }
                          } else {
                            this.setState({ loader: false });
                          }
                        }
                      );
                    }
                  } else {
                    this.setState({ loader: false });
                  }
                });
              }
            } catch (error) {}
          }
        } else {
          this.props.history.push({ pathname: "/maintenance" });
        }
      });
    } catch (error) {}
  }
  filterTenantImage(data) {
    let usersTenantImage = this.props.tempProfileImagesArray.filter(
      (todo) => todo.username === data.username
    );
    if (usersTenantImage.length === 0) {
      this.setImage(data, "tenantImage");
    } else {
      this.setState({ tenantImage: usersTenantImage[0].image });
    }
    this.setState({ tenantDetails: data });
  }
  filterLandlordImage(data) {
    let usersImage = this.props.tempProfileImagesArray.filter(
      (todo) => todo.username === data.username
    );
    if (usersImage.length === 0) {
      this.setImage(data, "landlordImage");
    } else {
      this.setState({ landlordImage: usersImage[0].image });
    }
    this.setState({ landlordDetails: data });
  }
  fetchImage(username, url, state) {
    const toDataURL = (url1) =>
      fetch(url1)
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          } else {
            throw new Error(response);
          }
        })
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        )
        .catch((res) => {
          throw new Error(res);
        });

    toDataURL(url)
      .then((dataUrl) => {
        this.setState({
          [state]: dataUrl,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: dataUrl,
        });
      })
      .catch((res) => {
        this.setState({
          [state]: image,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: image,
        });
      });
  }
  setImage(data, state) {
    if (data.provider === "" || data.provider === null) {
      this.setState({ [state]: image });
      this.props.appendTempProfileImage({
        username: data.username,
        image: image,
      });
    } else if (data.provider === "local") {
      if (data.coverImage === null) {
        this.setState({ [state]: image });
      } else {
        this.fetchImage(
          data.username,
          url + `api/user/${data.username}/image/download`,
          state
        );
      }
    } else {
      this.fetchImage(data.username, data.coverImage, state);
    }
  }
  editRequest() {
    this.setState({ editRequestModal: true });
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    const json = await CountryJson();
    this.setState({ countryJson: json });
    this.setState({ stateJson: json[0].states });
    this.setState({ workingCountryJson: json });
    this.setState({ workingStateJson: json[0].states });
    let countryList = [];
    let stateList = [];
    let workingCountryList = [];
    let workingStateList = [];
    this.state.countryJson.map((data, index) => countryList.push(data.name));
    this.setState({ countryList: countryList });
    this.state.stateJson.map((data, index) => stateList.push(data.name));
    this.setState({ stateList: stateList });

    this.state.workingCountryJson.map((data, index) =>
      workingCountryList.push(data.name)
    );
    this.setState({ workingCountryList: workingCountryList });
    this.state.workingStateJson.map((data, index) =>
      workingStateList.push(data.name)
    );
    this.setState({ workingStateList: workingStateList });

    if (this.props.location.state === undefined) {
      await this.loadMaintenance();
    } else {
      await this.loadMaintenance();
      var cat = this.props.location.state.data.category;
      if (cat === "Plumbing") {
        this.setState({
          catFinder: 1,
          catFinderProvider: 1,
        });
      } else if (cat === "Electrical") {
        this.setState({
          catFinder: 2,
          catFinderProvider: 2,
        });
      } else if (cat === "Handyman") {
        this.setState({
          catFinder: 3,
          catFinderProvider: 3,
        });
      } else if (cat === "HVAC") {
        this.setState({
          catFinder: 4,
          catFinderProvider: 4,
        });
      } else if (cat === "Pest Control") {
        this.setState({
          catFinder: 5,
          catFinderProvider: 5,
        });
      }

      if (this.props.roleStatus !== "Tenant") {
        if (
          this.props.location.state.tenantDetails === undefined ||
          this.props.location.state.tenantDetails.length === 0
        ) {
          var usersTenant = this.props.tempUsersArray.filter(
            (todo) => todo.username === this.state.data.tenantId
          );
          if (usersTenant.length === 0) {
            this.getTenantDetails();
          } else {
            this.filterTenantImage(usersTenant[0]);
          }
        } else {
          this.filterTenantImage(this.props.location.state.tenantDetails);
        }
      } else {
        this.setState({ tenantDetails: this.props.userDetailArray });
      }
      if (this.props.roleStatus !== "Landlord") {
        if (
          this.props.location.state.landlordDetails === undefined ||
          this.props.location.state.landlordDetails.length === 0
        ) {
          var usersLandlord = this.props.tempUsersArray.filter(
            (todo) => todo.username === this.state.data.landlordId
          );
          if (usersLandlord.length === 0) {
            this.getLandlordDetails();
          } else {
            this.filterLandlordImage(usersLandlord[0]);
          }
        } else {
          this.filterLandlordImage(this.props.location.state.landlordDetails);
        }
      } else {
        this.setState({ landlordDetails: this.props.userDetailArray });
      }
    }
  }
  getTenantDetails() {
    try {
      userDetail(this.state.data.tenantId).then((response) => {
        if (response.status) {
          this.props.appendTempUsers(response.data);
          this.setState({ tenantDetails: response.data });
          this.setImage(response.data, "tenantImage");
        }
      });
    } catch (error) {}
  }
  getLandlordDetails() {
    try {
      userDetail(this.state.data.landlordId).then((response) => {
        if (response.status) {
          this.props.appendTempUsers(response.data);
          this.setState({ landlordDetails: response.data });
          this.setImage(response.data, "landlordImage");
        }
      });
    } catch (error) {}
  }
  async handleChange(event) {
    let { name, value } = event.target;
    if (name === "serviceChange") {
      if (value.length < 51) {
        this.setState({
          [name]: value,
          serviceChangeCount: value.length,
        });
      }
    } else if (name === "serviceProviderMobile") {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (name === "category") {
      if (value === "Plumbing") {
        this.setState({
          catFinder: 1,
        });
      } else if (value === "Electrical") {
        this.setState({
          catFinder: 2,
        });
      } else if (value === "Handyman") {
        this.setState({
          catFinder: 3,
        });
      } else if (value === "HVAC") {
        this.setState({
          catFinder: 4,
        });
      } else if (value === "Pest Control") {
        this.setState({
          catFinder: 5,
        });
      }
      await this.setState({
        [name]: value,
      });
    } else if (name === "state") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data, index) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    } else if (name === "country") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data, index) =>
          stateList.push(data.name)
        );
        this.setState({ stateList: stateList });
        this.setState({ state: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state: "" });
        this.setState({ city: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
      }
    } else if (name === "workingState") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.workingStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let workingCityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ workingCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data, index) =>
          workingCityList.push(data.name)
        );
        this.setState({ workingCityList: workingCityList });
        this.setState({ workingCity: "" });
      } else {
        this.setState({ workingCity: "" });
        this.setState({ workingCityList: [] });
      }
    } else if (name === "country") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let workingStateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ workingStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data, index) =>
          workingStateList.push(data.name)
        );
        this.setState({ workingStateList: workingStateList });
        this.setState({ workingState: "" });
        this.setState({ workingCity: "" });
      } else {
        this.setState({ workingState: "" });
        this.setState({ workingCity: "" });
        this.setState({ workingStateList: [] });
        this.setState({ workingCityList: [] });
      }
    } else if (name === "jobAppointmentApproveCost") {
      let num = value.replace(/[^\d^.]/g, "");
      value = num;
      await this.setState({ [name]: value });
    } else {
      await this.setState({
        [name]: value,
      });
    }
  }
  async changeState(name, value) {
    await this.setState({ [name]: value });
    if (name === "state") {
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data, index) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    } else if (name === "country") {
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data, index) =>
          stateList.push(data.name)
        );
        this.setState({ stateList: stateList });
        this.setState({ state: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
        this.setState({ city: "" });
      }
    } else if (name === "workingState") {
      let selectedCities = this.state.workingStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let workingCityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ workingCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data, index) =>
          workingCityList.push(data.name)
        );
        this.setState({ workingCityList: workingCityList });
        this.setState({ workingCity: "" });
      } else {
        this.setState({ workingCity: "" });
        this.setState({ workingCityList: [] });
      }
    }
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    var skills = this.state.skills;

    if (checked) {
      skills.push(name);
      skills = removeDuplicate(skills);
      this.setState({ skills: skills });
    } else {
      var index = skills.indexOf(name);
      if (index !== -1) {
        skills.splice(index, 1);
      }
      this.setState({ skills: skills });
    }
  }

  checkValidate(value) {
    if (value === "" && this.state.next) {
      return true;
    }
    return false;
  }
  async onChangeTime(id, name, value, value1) {
    let sd = convertGMT(
      this.state.jobAppointmentStartDate +
        "T" +
        this.state.jobAppointmentStartTime
    );
    let ed = convertGMT(
      this.state.jobAppointmentEndDate + "T" + this.state.jobAppointmentEndTime
    );
    let diff = parseInt(betweenMinutes(sd, ed));
    this.setState({
      [id]: value,
      [id + "1"]: value1,
      sendQuotationTimeDiff: diff,
    });
  }
  onChangeDate(name, value) {
    this.setState({ [name]: value });
  }
  sendInvitation() {
    if (this.state.provider !== "") {
      this.setState({ providerInvitationLoader: true });

      try {
        let body = {
          maintenanceProvider: this.state.activeProvider,
          serviceRequest: this.state.data,
          inviteMode: "email",
          description: this.state.descriptionInvitationProvider,
        };
        sendProviderInvitation(body).then((response) => {
          if (response.status === 200) {
            this.setState({
              providerInvitationSuccess: true,
              providerLink: response.data.invitationLink,
            });
            // window.$("#MaintenanceRequestAssign").modal("hide");
            this.setState({
              providerInvitationLoader: false,
            });
            this.loadMaintenance();
          } else {
            window.$("#MaintenanceRequestAssign").modal("hide");
            this.setState({
              providerInvitationLoader: false,
            });
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
          this.setState({ providerInvitationLoader: false });
        });
      } catch (error) {
        window.$("#MaintenanceRequestAssign").modal("hide");
        this.setState({ providerInvitationLoader: false });
      }
    } else {
      this.setState({ providerAlert: true });
    }
  }
  addProvider() {
    this.setState({ next: false, emailAlreadyExists: false });
    if (
      this.state.serviceProviderFirstName !== "" &&
      this.state.serviceProviderLastName !== "" &&
      this.state.serviceProviderEmail !== "" &&
      this.state.serviceProviderMobile !== "" &&
      this.state.address !== "" &&
      this.state.city !== "" &&
      this.state.state !== "" &&
      this.state.zip !== "" &&
      this.state.workingState !== "" &&
      this.state.workingCity !== ""
    ) {
      this.setState({ providerModalLoader: true });

      try {
        let body = {
          firstName: this.state.serviceProviderFirstName,
          lastName: this.state.serviceProviderLastName,
          companyName: this.state.companyName,
          addedBy: "landlord",
          addedById: this.state.contractData.landLordId,
          email: this.state.serviceProviderEmail,
          mobile: this.state.serviceProviderMobile,
          address: {
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            country: "United States",
          },
          workingAreas: [
            {
              id: 0,
              state: this.state.workingState,
              city: this.state.workingCity,
            },
          ],
          skills: this.state.skills.toString(),

          about: this.state.descriptionProvider,
        };
        addProvider(body).then((response) => {
          if (response.status === 200) {
            this.props.appendAlert("Provider added successfully", "success");
            window.$("#AddProvider").modal("hide");
            this.setState({
              providerModalLoader: false,
              addProviderModal: false,
            });
            this.loadProviders();
          } else {
            if (response.response.data.errorcode === "EMAIL_ALREADY_EXISTS") {
              document.getElementById("providerModalBody").scrollTo(0, 0);
              this.setState({
                emailAlreadyExists: true,
                serviceProviderEmail: "",
              });
            } else {
              window.$("#AddProvider").modal("hide");
              this.setState({
                providerModalLoader: false,
                addProviderModal: false,
              });
              this.props.appendAlert(
                "Something went wrong!, Please try again later.",
                "danger"
              );
            }
            this.setState({ providerModalLoader: false });
          }
        });
      } catch (error) {
        window.$("#AddProvider").modal("hide");
        this.setState({ providerModalLoader: false, addProviderModal: false });
      }
    } else {
      this.setState({ next: true });
    }
  }
  updateRequest() {
    if (
      this.state.description !== "" &&
      this.state.category !== "" &&
      this.state.subCategory !== "" &&
      this.state.coverImage.length !== 0
    ) {
      this.setState({ newRequestModalLoader: true });
      var tenantAvailabilityTimes = [];
      if (this.state.appointmentDate !== "") {
        tenantAvailabilityTimes.push({
          date: this.state.appointmentDate,
          dayOfWeek: "MONDAY",
          time: this.state.appointmentTime,
        });
      }
      if (this.state.appointmentAnotherDate !== "") {
        tenantAvailabilityTimes.push({
          date: this.state.appointmentAnotherDate,
          dayOfWeek: "MONDAY",
          time: this.state.appointmentAnotherTime,
        });
      }
      try {
        // let body = {
        //   tenantId: this.props.userDetail.username,
        //   landlordId: this.state.contractData.landLordId,
        //   contractId: parseInt(this.state.contractData.contractId),
        //   rentalEntityId: this.state.contractData.rentalEntityId,
        //   category: this.state.category,
        //   subCategory: this.state.subCategory,
        //   description: this.state.description,
        //   tenantAvailablityTimes: tenantAvailabilityTimes,
        // };
        // createMaintenanceRequest(body).then((response) => {
        //   if (response.status === 200) {
        //     if (response.data.status) {
        //       this.props.appendAlert(
        //         "Maintenance request sent successfully",
        //         "success"
        //       );
        //       this.uploadImage(response.data.id);
        //       this.loadMaintenance();
        //     } else {
        //       this.loadMaintenance();
        //       this.props.appendAlert("Something went wrong!", "danger");
        //     }
        //   } else {
        //     this.props.appendAlert(
        //       "Something went wrong!, Please try again later.",
        //       "danger"
        //     );
        //   }
        // });
      } catch (error) {}
    } else {
      console.log("error");
      this.setState({ next: true });
    }
  }
  completedRequest() {
    try {
      changeMaintenanceRequestTenantStatus(
        this.state.data.id,
        "completed"
      ).then((response) => {
        if (response.status) {
          this.props.appendAlert(
            "Maintenance request job completed",
            "success"
          );
          this.loadMaintenance();
        } else {
          this.props.appendAlert("Please try again later", "danger");
        }
      });
    } catch (error) {
      this.props.appendAlert("Please try again later", "danger");
    }
  }
  cancelRequest() {
    try {
      changeMaintenanceRequestTenantStatus(this.state.data.id, "cancel").then(
        (response) => {
          if (response.status) {
            this.props.appendAlert("Maintenance request canceled", "success");
            this.loadMaintenance();
          } else {
            this.props.appendAlert("Please try again later", "danger");
          }
        }
      );
    } catch (error) {
      this.props.appendAlert("Please try again later", "danger");
    }
  }
  rejectRequest() {
    try {
      changeMaintenanceRequestLandlordStatus(
        this.state.data.id,
        "rejected"
      ).then((response) => {
        if (response.status) {
          this.props.appendAlert("Maintenance request rejected", "success");
          this.loadMaintenance();
        } else {
          this.props.appendAlert("Please try again later", "danger");
        }
      });
    } catch (error) {
      this.props.appendAlert("Please try again later", "danger");
    }
  }
  approveRequest() {
    try {
      changeMaintenanceRequestLandlordStatus(
        this.state.data.id,
        "approved"
      ).then((response) => {
        if (response.status) {
          this.props.appendAlert("Maintenance request approved", "success");
          this.loadMaintenance();
        } else {
          this.props.appendAlert("Please try again later", "danger");
        }
      });
    } catch (error) {
      this.props.appendAlert("Please try again later", "danger");
    }
  }

  clickAddProvider() {
    this.setState({
      addProviderModal: true,
      providerModal: "add",
      serviceProviderFirstName: "",
      serviceProviderLastName: "",
      companyName: "",
      serviceProviderEmail: "",
      serviceProviderMobile: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      workingState: "",
      workingCity: "",
      skills: [],
      descriptionProvider: "",
    });
  }
  clickEditProvider(data) {
    this.setState({
      addProviderModal: true,
      providerModal: "edit",
      serviceProviderFirstName: data.firstName,
      serviceProviderLastName: data.lastName,
      companyName: data.companyName,
      serviceProviderEmail: data.email,
      serviceProviderMobile: data.mobile,
      address: data.address.address,
      city: data.address.city,
      state: data.address.state,
      zip: data.address.zip,
      workingState:
        data.workingAreas.length > 0 ? data.workingAreas[0].state : "",
      workingCity:
        data.workingAreas.length > 0 ? data.workingAreas[0].city : "",
      skills: data.skills.split(","),
      descriptionProvider: data.about,
    });
  }
  copy(value) {
    navigator.clipboard.writeText(value);
    this.props.appendAlert(" Copied ", "success");
  }
  closeLink() {
    this.copy(this.state.providerLink);
    window.$("#MaintenanceRequestAssign").modal("hide");
  }
  onCurrentImageChange(index) {
    this.setState({ currentImage: index });
  }
  async deleteImage() {
    if (this.state.coverImage.length >= this.state.currentImage) {
      if (this.state.coverImage[this.state.currentImage].file) {
        var images = this.state.coverImage.slice();
        images.splice(this.state.currentImage, 1);
        this.setState({ coverImage: images });
        document.querySelector(".close_1x3s325").click();
      }
    } else {
      this.props.appendAlert("Error in deleting.Try again later!", "danger");
    }
  }
  checkStatus(id, array) {
    return (
      array.filter((element) => {
        return element.maintenanceProvider.id === id;
      }).length > 0
    );
  }

  openEstimate(data) {
    var filterEstimate = this.state.data.serviceQuotations.filter((element) => {
      return element.maintenanceProvider.id === data.maintenanceProvider.id;
    });
    this.setState({
      activeEstimate: filterEstimate,
      finalizedEstimate: false,
      activeEstimateSelected: filterEstimate[0],
      next: false,
    });
  }
  acceptQuotation() {
    if (this.state.finalizedEstimate) {
      let sd = convertGMT(
        this.state.jobAppointmentStartDate +
          "T" +
          this.state.jobAppointmentStartTime
      );
      let ed = convertGMT(
        this.state.jobAppointmentEndDate +
          "T" +
          this.state.jobAppointmentEndTime
      );
      let diff = parseInt(betweenMinutes(sd, ed));
      if (
        this.state.jobAppointmentStartDate !== "" &&
        this.state.jobAppointmentEndDate !== "" &&
        this.state.jobAppointmentStartTime !== "" &&
        this.state.jobAppointmentEndTime !== "" &&
        this.state.jobAppointmentApproveCost !== "" &&
        diff >= 0
      ) {
        let data = {
          quotation: this.state.activeEstimateSelected,
          maintenanceProvider:
            this.state.activeEstimateSelected.maintenanceProvider,
          serviceRequest: this.state.data,
          approvedCost: parseInt(this.state.jobAppointmentApproveCost),
          status: "pending",
          description: this.state.description,
          startOn: sd,
          endOn: ed,
          jobHours: diff,
          paid: false,
        };
        this.setState({ finalizedLoader: true, sendQuotationTimeDiff: diff });
        try {
          createMaintenanceAppointment(data).then((response) => {
            if (response.status) {
              this.loadMaintenance();
              this.setState({ finalizedLoader: false });
              window.$("#QuotationAppoint").modal("hide");
              this.props.appendAlert(
                "Maintenance quotation finalized",
                "success"
              );
            } else {
              this.setState({ finalizedLoader: false });
              window.$("#QuotationAppoint").modal("hide");
              this.props.appendAlert("Please try again later", "danger");
            }
          });
        } catch (error) {
          this.setState({ finalizedLoader: false });
          this.props.appendAlert("Please try again later", "danger");
        }
      } else {
        if (diff < 0) {
          this.setState({
            jobAppointmentStartTime: "",
            jobAppointmentStartTime1: "",
            jobAppointmentEndTime: "",
            jobAppointmentEndTime1: "",
            sendQuotationTimeDiff: diff,
          });
        }
        this.setState({ next: true });
      }
    } else {
      this.setState({
        finalizedEstimate: true,
        jobAppointmentStartDate: dateToDateOnly(
          this.state.activeEstimateSelected.startOn
        ),
        jobAppointmentStartTime: dateToTime(
          this.state.activeEstimateSelected.startOn
        ),
        jobAppointmentStartTime1: timeFormat(
          dateToTime(this.state.activeEstimateSelected.startOn)
        ),
        jobAppointmentEndDate: dateToDateOnly(
          this.state.activeEstimateSelected.endOn
        ),
        jobAppointmentEndTime: dateToTime(
          this.state.activeEstimateSelected.endOn
        ),
        jobAppointmentEndTime1: timeFormat(
          dateToTime(this.state.activeEstimateSelected.endOn)
        ),
        jobAppointmentApproveCost:
          this.state.activeEstimateSelected.estimatedCost,
        jobAppointmentDescription:
          this.state.activeEstimateSelected.description,
      });
    }
  }

  render() {
    let imgList = [];
    this.state.coverImage.forEach((img) => {
      imgList.push({
        src: URL.createObjectURL(img.file),
        thumbnail: URL.createObjectURL(img.file),
        thumbnailWidth: img.meta.width / 2,
        thumbnailHeight: img.meta.height / 2,
      });
    });
    return this.state.data !== [] &&
      this.state.unitData !== [] &&
      !this.state.loader ? (
      <div className="container-fluid ">
        <div className="maintenanceRequestDetailWindow">
          {!this.state.data.jobAssigned &&
            this.state.data.landlordApprovel !== "approved" &&
            this.state.data.landlordApprovel !== "rejected" && (
              <FullNotification>
                If you need to make any changes on the request details.You can
                <span className="textNotificationRed"> edit</span> the request
                before the landlord accept it.
              </FullNotification>
            )}
          <div className="row mt-4">
            <div className="col col-sm col-md col-lg col-xl">
              <BasicMaintenanceRequestDetails
                data={this.state.data}
                unitData={
                  this.state.unitData && this.state.unitData.rentalEntity
                }
                unitAddress={this.state.unitData && this.state.unitData.address}
                tenantDetails={this.state.tenantDetails}
                landlordDetails={this.state.landlordDetails}
                editRequest={this.editRequest}
                landlordImage={this.state.landlordImage}
                tenantImage={this.state.tenantImage}
              />
              <div className="tabViewHistory">
                <MaintenanceHistory
                  eventRecords={this.state.data.eventRecords}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-5">
              <MaintenanceRequestDescription data={this.state.data} />
              {this.state.data.jobAssigned && (
                <MaintenanceRequestOrder role="Tenant" data={this.state.data} />
              )}
            </div>
            <div className="col col-sm col-md col-lg col-xl webViewHistory">
              <MaintenanceHistory eventRecords={this.state.data.eventRecords} />
            </div>
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-lg"
          id={"MaintenanceRequestChange"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Request Changes in Service Request
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <div id="containerForm" className="p-0 m-0">
                  <div className="row mt-3 mr-3 ml-3">
                    <div className="col-12 ">
                      <ApplicationInput
                        name="non"
                        row={5}
                        type="textArea2"
                        designType="2"
                        target="serviceChange"
                        onChange={this.handleChange}
                        value={this.state.serviceChange}
                      />
                      <h1 className="maintenanceRequestText2">
                        {this.state.serviceChangeCount}/50
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer pt-3 border-0">
                <button className="buttonPri" data-dismiss="modal">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id={"MaintenanceRequestCancel"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Please confirm
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <span className=" pl-3 maintenanceRequestText4">
                  Are you sure want to cancel this request ?
                </span>
              </div>

              <div className="modal-footer pt-3 border-0">
                <button className="pri" data-dismiss="modal">
                  No
                </button>
                <button
                  className="buttonPri"
                  data-dismiss="modal"
                  onClick={() => this.cancelRequest()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-lg"
          id={"MaintenanceRequestReject"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Please confirm
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <span className=" pl-3 maintenanceRequestText4">
                  Are you sure want to reject this request ?
                </span>
              </div>

              <div className="modal-footer pt-3 border-0">
                <button className="pri" data-dismiss="modal">
                  No
                </button>
                <button
                  className="buttonPri"
                  data-dismiss="modal"
                  onClick={() => this.rejectRequest()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id={"MaintenanceRequestApprove"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Please confirm
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <span className=" pl-3 maintenanceRequestText4">
                  Are you sure you want to approve this request ?
                </span>
              </div>

              <div className="modal-footer pt-3 border-0">
                <button className="pri" data-dismiss="modal">
                  No
                </button>
                <button
                  className="buttonPri"
                  data-dismiss="modal"
                  onClick={() => this.approveRequest()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>

        {this.state.editRequestModal && (
          <div
            className="modal fade bd-example-modal-xl"
            id={"MaintenanceRequestEdit"}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ zIndex: "1500" }}
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                    Edit service request
                  </h1>
                  <button
                    type="button"
                    className="close "
                    data-dismiss="modal"
                    onClick={() => this.setState({ editRequestModal: false })}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="closeModal textPri">
                      &times;
                    </span>
                  </button>
                </div>
                <div
                  className="modal-body bodyAvailable2 pt-0  pb-0"
                  style={{
                    overflow: "scroll",
                    maxHeight: "500px",
                  }}
                >
                  <div id="containerForm" className="p-0 m-0">
                    <div className="row mt-3 mr-3 ml-3">
                      <div className="col-md-6">
                        <ApplicationInput
                          name="Maintenance Category"
                          target="category"
                          type="optional"
                          designType="2"
                          options={this.state.maintenanceRequest}
                          optionValue={this.state.maintenanceRequest}
                          onChange={this.handleChange}
                          important="true"
                          errorMessage="maintenance request category"
                          validate={this.checkValidate(this.state.category)}
                          value={this.state.category}
                          dark={this.props.dark}
                        />
                      </div>
                      <div className="col-md-6">
                        <ApplicationInput
                          name="Sub Category"
                          target="subCategory"
                          type="optional"
                          designType="2"
                          options={this.state.cat[this.state.catFinder]}
                          optionValue={this.state.cat[this.state.catFinder]}
                          onChange={this.handleChange}
                          important="true"
                          errorMessage="sub category"
                          validate={this.checkValidate(this.state.subCategory)}
                          value={this.state.subCategory}
                          dark={this.props.dark}
                        />
                      </div>

                      <div className="col-12 mt-3 mb-3">
                        <ApplicationInput
                          name="Service Description"
                          subName=" ( Please provide as much information as you can )"
                          row={5}
                          type="textArea"
                          designType="2"
                          target="description"
                          onChange={this.handleChange}
                          value={this.state.description}
                          errorMessage=" service request description"
                          important="true"
                          validate={this.checkValidate(this.state.description)}
                        />
                      </div>
                      <div className="col-xl-12 pt-0 mt-0 ">
                        <span className="searchListHeadApp2 mb-2">
                          Schedule an appointment
                        </span>
                      </div>
                      <div className="col-md-5">
                        <ApplicationInput
                          name="If available, which day works best for you?"
                          type="date"
                          target="appointmentDate"
                          onChange={this.onChangeDate}
                          designType="2"
                          // important="true"
                          // errorMessage="the move in date"
                          // validate={this.checkValidate(
                          //   this.state.appointmentAnotherDate === null
                          //     ? ""
                          //     : this.state.appointmentAnotherDate
                          // )}
                          value={this.state.appointmentDate}
                        />
                      </div>
                      <div className="col-md-6">
                        <TimeInput
                          id="appointmentTime"
                          name="appointmentTime"
                          designType="2"
                          target="appointmentTime"
                          onChange={this.onChangeTime}
                          value={this.state.appointmentTime1}
                          place="--:-- --"
                        />
                      </div>
                      <div className="col-md-5">
                        <ApplicationInput
                          name="What is another day that works for you?"
                          type="date"
                          target="appointmentAnotherDate"
                          onChange={this.onChangeDate}
                          designType="2"
                          value={this.state.appointmentAnotherDate}
                        />
                      </div>
                      <div className="col-md-6">
                        <TimeInput
                          designType="2"
                          id="appointmentAnotherTime"
                          name="appointmentAnotherTime"
                          target="appointmentAnotherTime"
                          onChange={this.onChangeTime}
                          value={this.state.appointmentAnotherTime1}
                          place="--:-- --"
                        />
                      </div>
                      <div className="col-xl-4 pt-0 mt-0 mb-4">
                        <span className="searchListHeadApp2 mb-2">
                          Image(s)
                        </span>
                        <Dropzone
                          inputContent={
                            <div className={this.state.next ? "alert" : ""}>
                              <div
                                className="dragDrop text-center pointer "
                                style={{
                                  fontSize: "60px",
                                  minHeight: "200px",
                                  paddingTop: "40px",
                                  marginBottom: "40px",
                                }}
                              >
                                <div className="row ">
                                  <div className="col">
                                    <i
                                      className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p className="licenseHead">
                                      Drag Or Click to Upload
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          inputWithFilesContent="Upload more"
                          onChangeStatus={this.handleChangeStatus}
                          accept=".jpeg,.png,.jpg"
                          submitButtonContent="Upload"
                        />
                      </div>
                      {this.state.coverImage.length > 0 && (
                        <div
                          style={{
                            display: "block",
                            width: "100%",
                          }}
                          className="col-md pl-5 pt-4 pb-4"
                        >
                          <Gallery
                            images={imgList}
                            enableLightbox={true}
                            enableImageSelection={false}
                            currentImageWillChange={this.onCurrentImageChange}
                            customControls={[
                              <button
                                key="deleteImage"
                                data-target="#deleteImage"
                                data-toggle="modal"
                                onClick={() =>
                                  this.setState({ deleteImage: true })
                                }
                                className="agreement  mr-2 ml-2 pri2"
                                style={{
                                  marginTop: "50px",
                                  height: "40px",
                                  zIndex: "4500",
                                }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>,
                            ]}
                            backdropClosesModal={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="modal-footer pt-3 ">
                  <button
                    className="pri"
                    data-dismiss="modal"
                    onClick={() => this.setState({ editRequestModal: false })}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    className="buttonPri"
                    onClick={() => this.updateRequest()}
                  >
                    Update Request
                    {this.state.editRequestModalLoader && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.addProviderModal && (
          <div
            className="modal fade bd-example-modal-xl"
            id={"AddProvider"}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ zIndex: "1500" }}
          >
            <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                    {this.state.providerModal === "add"
                      ? "Add Provider"
                      : "Edit Provider"}
                  </h1>
                  <button
                    type="button"
                    className="close "
                    data-dismiss="modal"
                    onClick={() => this.setState({ editRequestModal: false })}
                    aria-label="Close"
                  >
                    <span aria-hidden="true" className="closeModal textPri">
                      &times;
                    </span>
                  </button>
                </div>
                <div
                  className="modal-body bodyAvailable2 pt-0  pb-0"
                  style={{
                    overflow: "scroll",
                    maxHeight: "500px",
                  }}
                  id="providerModalBody"
                >
                  <h3 className="maintenanceRequestText2 pt-2">
                    Personal Details
                  </h3>
                  <div className="row">
                    <div className="col-6">
                      <ApplicationInput
                        name="First Name"
                        target="serviceProviderFirstName"
                        type="text"
                        designType="2"
                        onChange={this.handleChange}
                        important="true"
                        errorMessage="service provider first name"
                        validate={this.checkValidate(
                          this.state.serviceProviderFirstName
                        )}
                        value={this.state.serviceProviderFirstName}
                        dark={this.props.dark}
                      />
                    </div>
                    <div className="col-6">
                      <ApplicationInput
                        name=" Last Name"
                        target="serviceProviderLastName"
                        type="text"
                        designType="2"
                        onChange={this.handleChange}
                        important="true"
                        errorMessage="service provider name"
                        validate={this.checkValidate(
                          this.state.serviceProviderLastName
                        )}
                        value={this.state.serviceProviderLastName}
                        dark={this.props.dark}
                      />
                    </div>
                    <div className="col-6">
                      <ApplicationInput
                        name="Email"
                        target="serviceProviderEmail"
                        type="email"
                        designType="2"
                        onChange={this.handleChange}
                        important="true"
                        errorStart="false"
                        errorMessage={
                          this.state.emailAlreadyExists
                            ? "This email already exists"
                            : "Please enter service provider email"
                        }
                        validate={
                          this.state.emailAlreadyExists
                            ? this.state.emailAlreadyExists
                            : this.checkValidate(
                                this.state.serviceProviderEmail
                              )
                        }
                        value={this.state.serviceProviderEmail}
                        dark={this.props.dark}
                      />
                    </div>
                    <div className="col-6">
                      <ApplicationInput
                        name="Phone no"
                        target="serviceProviderMobile"
                        type="text"
                        designType="2"
                        onChange={this.handleChange}
                        important="true"
                        errorMessage="service provider phone no"
                        validate={this.checkValidate(
                          this.state.serviceProviderMobile
                        )}
                        value={this.state.serviceProviderMobile}
                        dark={this.props.dark}
                      />
                    </div>

                    <div className="col">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="address"
                        onChange={this.handleChange}
                        important="true"
                        errorMessage="your Present Address"
                        validate={this.checkValidate(this.state.address)}
                        value={this.state.address}
                      />
                    </div>
                    <div className="col alignInput">
                      <SearchInput
                        name="state"
                        id="State"
                        target="state"
                        onChange={this.handleChange}
                        list={this.state.stateList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="property State"
                        validate={this.checkValidate(this.state.state)}
                        value={this.state.state}
                      />
                    </div>

                    <div className="col alignInput">
                      <SearchInput
                        name="city"
                        id="City"
                        target="city"
                        onChange={this.handleChange}
                        list={this.state.cityList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="property City"
                        validate={this.checkValidate(this.state.city)}
                        value={this.state.city}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="number"
                        target="zip"
                        min="1"
                        onChange={this.handleChange}
                        important="true"
                        errorMessage="your Present Zip Code"
                        validate={this.checkValidate(this.state.zip)}
                        value={this.state.zip}
                      />
                    </div>
                  </div>
                  <h3 className="maintenanceRequestText2 pt-2">
                    Working Details
                  </h3>
                  <div className="row">
                    <div className="col-3 alignInput">
                      <SearchInput
                        name="workingState"
                        id="State"
                        target="workingState"
                        onChange={this.handleChange}
                        list={this.state.workingStateList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="working State"
                        validate={this.checkValidate(this.state.workingState)}
                        value={this.state.workingState}
                      />
                    </div>

                    <div className="col-3 alignInput">
                      <SearchInput
                        name="workingCity"
                        id="City"
                        target="workingCity"
                        onChange={this.handleChange}
                        list={this.state.workingCityList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="working City"
                        validate={this.checkValidate(this.state.workingCity)}
                        value={this.state.workingCity}
                      />
                    </div>

                    <div className="col-6">
                      <ApplicationInput
                        name="Company Name (If not individual)"
                        target="companyName"
                        designType="2"
                        type="text"
                        onChange={this.handleChange}
                        // important="true"
                        // errorMessage=" company name"
                        // validate={this.checkValidate(this.state.companyName)}
                        value={this.state.companyName}
                        dark={this.props.dark}
                      />
                    </div>
                  </div>
                  <h3 className="maintenanceRequestText2 pt-2">Skills</h3>
                  <div className="row mt-3 mb-3">
                    {this.state.maintenanceRequest.map((data, index) => (
                      <div className="col">
                        <ApplicationInput
                          name={data}
                          type="checkedBox"
                          target={data}
                          onChange={this.onchangeCheck}
                          value={
                            this.state.skills.filter((element, index) => {
                              return element === data;
                            }).length > 0
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <ApplicationInput
                        name="More about provider service (If any)"
                        target="descriptionProvider"
                        designType="2"
                        type="textArea"
                        row="8"
                        onChange={this.handleChange}
                        // important="true"
                        // errorMessage="short description"
                        // validate={this.checkValidate(
                        //   this.state.descriptionProvider
                        // )}
                        value={this.state.descriptionProvider}
                        dark={this.props.dark}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer pt-3 ">
                  <button
                    className="pri"
                    data-dismiss="modal"
                    onClick={() => this.setState({ addProviderModal: false })}
                  >
                    {" "}
                    Cancel
                  </button>
                  <button
                    className="buttonPri"
                    onClick={() => this.addProvider()}
                  >
                    {this.state.providerModal === "add" ? "Add" : "Update"}
                    {this.state.providerModalLoader && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="modal fade bd-example-modal-xl"
          id={"QuotationAppoint"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Quotation
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0 pt-3"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                {this.state.finalizedEstimate ? (
                  <>
                    <div className="row p-3 maintenanceCard mb-2 mr-2 ml-2 ">
                      <div className="col-md-3  ">
                        <h3 className="maintenanceRequestText2">
                          Estimate Cost: &nbsp;&nbsp;
                        </h3>
                        <span className="badge currency p-2 text-white">
                          <CurrencyFormat
                            value={
                              this.state.activeEstimateSelected.estimatedCost
                            }
                          />
                        </span>
                      </div>
                      <div className="col-md  ">
                        <h3 className="maintenanceRequestText2">
                          Estimate period: &nbsp;&nbsp;
                        </h3>
                        <span className="maintenanceRequestText4 ">
                          <span className="badge currency p-2 border bg-white">
                            {dateTimeFormat(
                              this.state.activeEstimateSelected.startOn
                            )}
                          </span>{" "}
                          <i
                            className="fa fa-minus pr-2 pl-2"
                            aria-hidden="true"
                          ></i>{" "}
                          <span className="badge currency p-2 border bg-white">
                            {dateTimeFormat(
                              this.state.activeEstimateSelected.endOn
                            )}
                          </span>
                        </span>
                      </div>

                      <div className="col-md-12 mt-3">
                        <h3 className="maintenanceRequestText2">
                          Description: &nbsp;&nbsp;
                        </h3>
                        <p className="maintenanceRequestText11 pt-0 mt-0">
                          &nbsp;&nbsp;{" "}
                          {this.state.activeEstimateSelected.description}
                        </p>
                      </div>
                    </div>

                    <div className="row mr-2 ml-2">
                      <div className="col-6">
                        <div className="row">
                          <div className="col-12">
                            <ApplicationInput
                              name="Final Estimated Cost"
                              type="text"
                              designType="2"
                              target="jobAppointmentApproveCost"
                              onChange={this.handleChange}
                              important="true"
                              min="1"
                              errorMessage="your estimated cost"
                              validate={this.checkValidate(
                                this.state.jobAppointmentApproveCost
                              )}
                              iconL={"fa-usd"}
                              value={this.state.jobAppointmentApproveCost}
                            />
                          </div>
                          <div className="col-12">
                            <ApplicationInput
                              name="More about service (If any)"
                              target="jobAppointmentDescription"
                              designType="2"
                              type="textArea"
                              row="8"
                              onChange={this.handleChange}
                              value={this.state.jobAppointmentDescription}
                              dark={this.props.dark}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="row">
                          <div className="col-md-6">
                            <ApplicationInput
                              name="Start Date"
                              type="date"
                              target="jobAppointmentStartDate"
                              min={today()}
                              max={this.state.endDate}
                              onChange={this.onChangeDate}
                              designType="2"
                              important="true"
                              errorMessage={
                                this.state.sendQuotationTimeDiff < 0
                                  ? "date-time logically"
                                  : "estimation start in date"
                              }
                              validate={this.checkValidate(
                                this.state.jobAppointmentStartDate === null ||
                                  this.state.sendQuotationTimeDiff < 0
                                  ? ""
                                  : this.state.jobAppointmentStartDate
                              )}
                              value={this.state.jobAppointmentStartDate}
                            />
                          </div>
                          <div className="col-md-6">
                            <TimeInput
                              id="jobAppointmentStartTime"
                              name="Time"
                              designType="2"
                              target="jobAppointmentStartTime"
                              onChange={this.onChangeTime}
                              value={this.state.jobAppointmentStartTime1}
                              place="--:-- --"
                            />
                          </div>
                          <div className="col-md-6">
                            <ApplicationInput
                              name="End Date"
                              type="date"
                              target="jobAppointmentEndDate"
                              min={
                                this.state.jobAppointmentStartDate === ""
                                  ? today()
                                  : this.state.jobAppointmentStartDate
                              }
                              onChange={this.onChangeDate}
                              designType="2"
                              value={this.state.jobAppointmentEndDate}
                              important="true"
                              errorMessage={
                                this.state.sendQuotationTimeDiff < 0
                                  ? "date-time logically"
                                  : "estimation start in date"
                              }
                              validate={this.checkValidate(
                                this.state.jobAppointmentEndDate === null ||
                                  this.state.sendQuotationTimeDiff < 0
                                  ? ""
                                  : this.state.jobAppointmentEndDate
                              )}
                            />
                          </div>
                          <div className="col-md-6">
                            <TimeInput
                              designType="2"
                              id="jobAppointmentEndTime"
                              name="Time"
                              target="jobAppointmentEndTime"
                              onChange={this.onChangeTime}
                              value={this.state.jobAppointmentEndTime1}
                              place="--:-- --"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  this.state.activeEstimate.map((data, index) => (
                    <div
                      className="row p-3 maintenanceCard mb-2 mr-2 ml-2 pointer"
                      onClick={() =>
                        this.setState({ activeEstimateSelected: data })
                      }
                    >
                      <div className="col-md-3  ">
                        <h3 className="maintenanceRequestText2">
                          Estimate Cost: &nbsp;&nbsp;
                        </h3>
                        <span className="badge currency p-2 text-white">
                          <CurrencyFormat value={data.estimatedCost} />
                        </span>
                      </div>
                      <div className="col-md  ">
                        <h3 className="maintenanceRequestText2">
                          Estimate period: &nbsp;&nbsp;
                        </h3>
                        <span className="maintenanceRequestText4 ">
                          <span className="badge currency p-2 border bg-white">
                            {dateTimeFormat(data.startOn)}
                          </span>{" "}
                          <i
                            className="fa fa-minus pr-2 pl-2"
                            aria-hidden="true"
                          ></i>{" "}
                          <span className="badge currency p-2 border bg-white">
                            {dateTimeFormat(data.endOn)}
                          </span>
                        </span>
                      </div>
                      {this.state.data.jobAppointment !== null && (
                        <div className="col-md-3 ">
                          {data.landlordStatus === "approved" ? (
                            <span className="badge badge02 text-success pull-right">
                              <i
                                className="fa fa-check-circle pr-2"
                                aria-hidden="true"
                              ></i>
                              Approved
                            </span>
                          ) : (
                            <span className="badge badge02 pull-right">
                              <i
                                className="fa fa-times-circle pr-2"
                                aria-hidden="true"
                              ></i>
                              Rejected
                            </span>
                          )}
                        </div>
                      )}
                      {this.state.activeEstimateSelected.id === data.id && (
                        <>
                          {this.state.data.jobAppointment === null && (
                            <div className="col-md-3 ">
                              {" "}
                              <span className="badge badge02 pull-right">
                                <i
                                  className="fa fa-check-circle pr-2"
                                  aria-hidden="true"
                                ></i>
                                Selected
                              </span>{" "}
                            </div>
                          )}

                          <div className="col-md-12 mt-3">
                            <h3 className="maintenanceRequestText2">
                              Description: &nbsp;&nbsp;
                            </h3>
                            <p className="maintenanceRequestText11 pt-0 mt-0">
                              &nbsp;&nbsp; {data.description}
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))
                )}
              </div>
              {this.state.data.jobAppointment === null && (
                <div className="modal-footer pt-3 border-0">
                  {this.state.finalizedEstimate ? (
                    <button
                      className="pri"
                      onClick={() =>
                        this.setState({ finalizedEstimate: false })
                      }
                    >
                      Back
                    </button>
                  ) : (
                    <button className="pri" data-dismiss="modal">
                      Cancel
                    </button>
                  )}
                  <button
                    className="buttonPri"
                    onClick={() => this.acceptQuotation()}
                  >
                    {this.state.finalizedEstimate ? "Finalized" : "Accept"}
                    {this.state.finalizedLoader && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="modal fade bd-example-modal-lg"
          id={"CompleteJob"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Please confirm
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <span className=" pl-3 maintenanceRequestText4">
                  Are you sure you want to close this job?
                </span>
              </div>

              <div className="modal-footer pt-3 border-0">
                <button className="pri" data-dismiss="modal">
                  No
                </button>
                <button
                  className="buttonPri"
                  data-dismiss="modal"
                  onClick={() => this.completedRequest()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.deleteImage && (
          <SmallModalContainer
            name="deleteImage"
            dark={this.props.dark}
            title="Confirm Delete?"
            confirmCommand="Delete"
            confirm={this.deleteImage}
            reject={() => this.setState({ deleteImage: false })}
            rejectCommand="Cancel"
            rejectCheck="true"
          >
            <div className="p-2 pull-left text-left">
              <span className="span7" style={{ fontSize: "16px" }}>
                Are you sure you want to delete this image?
              </span>
            </div>
          </SmallModalContainer>
        )}
      </div>
    ) : (
      <Loader />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    role: state.RoleReducer,
    roleStatus: state.role,
    user: state.userReducer,
    userDetailArray: state.user,
    contract: state.contractReducer,
    contractArray: state.contract,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    provider: state.providerReducer,
    providerArray: state.provider,
    tempProfileImages: state.tempProfileImagesReducer,
    tempProfileImagesArray: state.tempProfileImages,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setMaintenance,
    appendTempUsers,
    setContract,
    setProvider,
    appendTempProfileImage,
    getTempProfileImage,
  })(MaintenanceRequestTenantDetail)
);
