// Tenant Payment Status Reducer 
const customerPaymentReducer = (state = null, action) => {
     switch (action.type) {
       case "GET_PAYMENT_CUSTOMER_STATUS":
         return state;
       case "SET_PAYMENT_CUSTOMER_STATUS":
          return action.paymentStatus;
       case "REMOVE_PAYMENT_CUSTOMER_STATUS":
         return null;
       default:
         return state;
     }
};
export default customerPaymentReducer;
   