import React, { Component } from "react";
import "./BasicMaintenanceRequestDetails.css";
import image1 from "../../../../Images/profile.png";
import calender from "../../../../Images/calender.webp";
import property from "../../../../Images/build.jpg";
import imageLoader from "../../../../Images/imageLoader.gif";
import {
  addressFormatter,
  checkNull,
  convertLocalFullDate,
  convertLocalTime,
  exceptNameNull,
  exceptNull,
  sortText,
} from "../../../../Defined/Function";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import SplitToggleButton from "../../../UIServices/Buttons/SplitToggleButton";
import Loader3 from "../../../Loader/loader3";
class BasicMaintenanceRequestDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      this.props.data !== [] &&
      this.props.unitData !== [] && (
        <div className="maintenanceRequestCard mb-3">
          <span className="maintenanceRequestDetailText1 ">
            #{exceptNull(this.props.data.id)}{" "}
          </span>
          <i className="fa fa-ellipsis-h pull-right" aria-hidden="true"></i>
          <p className="maintenanceRequestDetailText2 mb-3 mt-2">
            {exceptNull(
              this.props.data.category + " " + this.props.data.subCategory
            )}
          </p>
          {this.props.mode === "open" &&
            (!this.props.data.jobAssigned ? (
              <span className="badgeGreen">Open</span>
            ) : this.props.data.jobAppointment === null ? (
              <span className="badgeRed">Closed</span>
            ) : parseInt(
                this.props.data.jobAppointment.maintenanceProvider.id
              ) === parseInt(atob(this.props.match.params.providerId)) ? (
              this.props.data.status === "complete" ? (
                <span className="badgeRed">Closed</span>
              ) : (
                <span className="badgeGreen">Open</span>
              )
            ) : (
              <span className="badgeRed">Closed</span>
            ))}

          {this.props.mode !== "open" &&
            this.props.roleStatus === "Landlord" &&
            this.props.data.landlordApprovel !== "approved" &&
            this.props.data.landlordApprovel !== "rejected" &&
            this.props.data.status !== "canceled" && (
              <>
                <button
                  className="buttonSquareLineSec mr-2"
                  data-target="#MaintenanceRequestReject"
                  data-toggle="modal"
                >
                  Reject
                </button>
                <button
                  className="buttonSquarePri"
                  data-target="#MaintenanceRequestApprove"
                  data-toggle="modal"
                >
                  Accept
                </button>{" "}
              </>
            )}
          {this.props.mode !== "open" &&
            this.props.roleStatus === "Tenant" &&
            this.props.data.landlordApprovel !== "approved" &&
            this.props.data.landlordApprovel !== "rejected" &&
            this.props.data.status !== "canceled" && (
              <>
                <button
                  className="buttonSquareLineSec mr-2"
                  data-target="#MaintenanceRequestCancel"
                  data-toggle="modal"
                >
                  Cancel
                </button>
                <button
                  className="buttonSquarePri"
                  data-target="#MaintenanceRequestEdit"
                  data-toggle="modal"
                  onClick={() => this.props.editRequest()}
                >
                  Edit
                </button>{" "}
              </>
            )}
          {this.props.mode !== "open" &&
            this.props.data.status === "canceled" && (
              <span className="badgeRed">Canceled</span>
            )}
          {this.props.mode !== "open" &&
            this.props.roleStatus === "Tenant" &&
            this.props.data.landlordApprovel === "approved" &&
            (this.props.data.status === "complete" ? (
              <span className="badgeGreen">Complete</span>
            ) : (
              this.props.data.status === "incomplete" && (
                <span className="badgeYellow">Incomplete</span>
              )
            ))}
          {this.props.mode !== "open" &&
            this.props.roleStatus === "Landlord" &&
            this.props.data.landlordApprovel === "approved" && (
              <SplitToggleButton text="Pending" color="yellow">
                <span>Cancel</span>
                <span>Other</span>
              </SplitToggleButton>
            )}

          <div className="row pl-3">
            <div className="col-12 mt-4 mb-2 pl-0">
              <span className="maintenanceRequestDetailText1 ">
                Unit Details
              </span>
            </div>

            <div className="maintenanceBasicDetailsImageAlign">
              <img
                alt="unit"
                src={property}
                className="maintenanceRequestDetailUnitImage"
              />
            </div>
            <div className="maintenanceBasicDetailsNameAlign">
              <h1 className="maintenanceRequestText2 textLeft">
                {checkNull([this.props.unitData]) &&
                  exceptNull(this.props.unitData.unitName)}
              </h1>
              <h3 className="maintenanceRequestText1 textLeft">
                {checkNull([this.props.unitAddress]) ? (
                  addressFormatter(this.props.unitAddress)
                ) : (
                  <Loader3 transY="50px" transX="-60px" />
                )}
              </h3>
            </div>
          </div>
          {this.props.roleStatus === "Landlord" &&
          this.props.mode !== "open" ? (
            <div className="row mb-4 pb-2 pl-3">
              <div className="col-12 mt-4 mb-2 pl-0">
                <span className="maintenanceRequestDetailText1 ">
                  Tenant Details
                </span>
              </div>

              <div className="maintenanceBasicDetailsImageAlign">
                <img
                  alt="tenant"
                  src={
                    this.props.tenantImage
                      ? this.props.tenantImage
                      : imageLoader
                  }
                  className="maintenanceRequestDetailTenantImage"
                />
              </div>
              <div className="maintenanceBasicDetailsNameAlign">
                <h1 className="maintenanceRequestText2 textLeft">
                  {sortText(
                    exceptNameNull(
                      this.props.tenantDetails.firstName,
                      this.props.tenantDetails.lastName
                    ),
                    0,
                    17
                  )}
                </h1>
                <h3 className="maintenanceRequestText1 textLeft">
                  {sortText(exceptNull(this.props.data.tenantId), 0, 23)}
                </h3>
              </div>
              <div
                className="maintenanceBasicDetailsExpandAlign"
                style={{ maxWidth: "70px", alignSelf: "center" }}
              >
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
            </div>
          ) : (
            <div className="row mb-4 pb-2 pl-3">
              <div className="col-12 mt-4 mb-2 pl-0">
                <span className="maintenanceRequestDetailText1 ">
                  Landlord Details
                </span>
              </div>

              <div className="maintenanceBasicDetailsImageAlign">
                <img
                  alt="tenant"
                  src={
                    this.props.landlordImage
                      ? this.props.landlordImage
                      : imageLoader
                  }
                  className="maintenanceRequestDetailTenantImage"
                />
              </div>
              <div className="maintenanceBasicDetailsNameAlign">
                <h1 className="maintenanceRequestText2 textLeft">
                  {sortText(
                    exceptNameNull(
                      this.props.landlordDetails.firstName,
                      this.props.landlordDetails.lastName
                    ),
                    0,
                    17
                  )}
                </h1>
                <h3 className="maintenanceRequestText1 textLeft">
                  {sortText(exceptNull(this.props.data.landlordId), 0, 23)}
                </h3>
              </div>
              <div
                className="maintenanceBasicDetailsExpandAlign"
                style={{ maxWidth: "70px", alignSelf: "center" }}
              >
                <i className="fa fa-angle-right" aria-hidden="true"></i>
              </div>
            </div>
          )}
          {this.props.mode !== "open" && (
            <>
              <hr />
              <div className="row pl-3 mb-4">
                <div className="col-12 mb-2 mt-1 pl-0">
                  <h3 className="maintenanceRequestDetailText5">
                    Service Provider
                  </h3>
                </div>
                <div className="maintenanceBasicDetailsImageAlign">
                  <img
                    alt="tenant"
                    src={image1}
                    className="maintenanceRequestDetailTenantImage"
                  />
                </div>
                {!this.props.data.jobAssigned ? (
                  <div className="maintenanceBasicDetailsNameAlign">
                    <h1 className="maintenanceRequestDetailNotJet pt-2">
                      Not Assigned Yet
                    </h1>
                  </div>
                ) : (
                  <>
                    <div className="maintenanceBasicDetailsNameAlign">
                      <h1 className="maintenanceRequestText2 textLeft">
                        {this.props.data.jobAppointment !== null &&
                          sortText(
                            exceptNull(
                              this.props.data.jobAppointment.maintenanceProvider
                                .firstName +
                                " " +
                                this.props.data.jobAppointment
                                  .maintenanceProvider.lastName
                            ),
                            0,
                            17
                          )}
                        {/* {this.props.data.jobAppointment === null?"-":sortText(exceptNull(
                      this.props.data.jobAppointment.maintenanceProvider.email
                    ),0,17)} */}
                      </h1>
                      <h3 className="maintenanceRequestText1 textLeft">
                        {this.props.data.jobAppointment !== null &&
                          sortText(
                            exceptNull(
                              this.props.data.jobAppointment.maintenanceProvider
                                .skills
                            ),
                            0,
                            23
                          )}
                      </h3>
                    </div>
                    <div className="maintenanceBasicDetailsExpandAlign">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {this.props.data.tenantAvailablityTimes &&
            this.props.data.tenantAvailablityTimes.length !== 0 && (
              <>
                <div className="row pl-3">
                  <div className="col-12  pl-0">
                    <span className="maintenanceRequestDetailText1 ">
                      Date and Time
                    </span>
                  </div>
                </div>
                {this.props.data.tenantAvailablityTimes.map((data1) => (
                  <div className="row mt-3 pl-3">
                    <div className="maintenanceBasicDetailsDateAlign ">
                      <img
                        alt="tenant"
                        src={calender}
                        className="maintenanceRequestDetailIconImage"
                      />
                    </div>

                    <div className="maintenanceBasicDetailsDateNameAlign">
                      <h1 className="maintenanceRequestText2 textLeft">
                        {exceptNull(convertLocalFullDate(data1.date))}
                      </h1>
                      <h3 className="maintenanceRequestText1 textLeft">
                        {exceptNull(convertLocalTime(data1.date))}
                      </h3>
                    </div>
                  </div>
                ))}
              </>
            )}
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    provider: state.providerReducer,
    providerArray: state.provider,
    user: state.userReducer,
    userDetailArray: state.user,
    role: state.RoleReducer,
    roleStatus: state.role,
  };
};
export default withRouter(
  connect(mapStateToProps, {})(BasicMaintenanceRequestDetails)
);
