import React, { useState, useEffect, useCallback } from "react";
import "./NavBar.css";
import { useSelector, useDispatch } from "react-redux";
import image from "../../Images/profile.png";
import icon from "../../Images/favicon.ico";
import JwtAuthService from "../../Services/JwtAuthService";
import { withRouter } from "react-router-dom";
import Notification from "../Notification/Notification";
import SockJsClient from "react-stomp";
import {
  getNotificationCount,
  deleteNotification,
  getNotification,
  updateNotification,
  url,
  getMaintenanceRequest,
} from "../../Services/ApiServices";
import {
  authorize,
  changeRole,
  unAuthorize,
  changeRoleGuest,
  setUser,
  removeCustomerPaymentDetail,
  removeCustomerPaymentStatus,
  removeAccountPaymentStatus,
  removeRecentPayment,
  removeApplication,
  removeProperty,
  removeContract,
  appendAlert,
  removeMaintenance,
  setMaintenance,
  appendTempProfileImage,
} from "../../actions";
import { sortText } from "../../Defined/Function";
import CookieService from "../../Services/CookieService";

function NavBar(props) {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [menu, setMenu] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [notification, setNotification] = useState(false);
  const [common, setCommon] = useState(false);
  const userData = useSelector((state) => state.user);
  const tempProfileImages = useSelector((state) => state.tempProfileImages);
  const dark = useSelector((state) => state.isDark);
  const role = useSelector((state) => state.role);
  const auth = useSelector((state) => state.isAuth);
  const [notificationData, setNotificationData] = useState([]);
  const [profileImage, setProfileImage] = useState(image);
  const [topicName, setTopicName] = useState("/landlord/user")

  const AuthCheck = () => {
    let UserData = CookieService.getArrayCookie("axUserDetails");
    let roleNo = CookieService.getArrayCookie("axUserRole");
    dispatch(setUser(UserData));
    if (UserData && roleNo) {
      dispatch(authorize());
      dispatch(changeRole(roleNo[0].id));
    } else {
      dispatch(unAuthorize());
      dispatch(changeRoleGuest());
    }
  };
  function changeNotification() {
    if (!notification) setNotification(true);
    else if (notification && common) {
      setNotification(false);
    }
    setTimeout(() => {
      setCommon(true);
    }, 10);
  }

  function handleDelete(data, _e) {
    setDeleteLoader(true);
    deleteNotification(data.id).then((response) => {
      setDeleteLoader(false);
      if (response.status !== 200) {
        dispatch(
          appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          )
        );
      }
      getCount();
    });
  }
  function loadMaintenance() {
    try {
      getMaintenanceRequest().then((response) => {
        if (response.status === 200) {
          response.data = response.data.reverse();
          dispatch(setMaintenance(response.data));
        } else {
          dispatch(setMaintenance([]));
        }
      });
    } catch (error) {}
  }
  function handleMaintenancePath(data) {
    loadMaintenance();
    props.history.push({
      pathname: "/maintenance/" + data.actionId,
    });
  }
  function handleClick(data, _index, e) {
    if (e.target.id !== "delete") {
      let updated = data;
      updated.seen = true;
      updateNotification(data.id).then((response) => {
        if (response.status === 200) {
          if (
            data.catogory === "application_received" ||
            data.catogory === "application_rejected" ||
            data.catogory === "application_rejected_system" ||
            data.catogory === "application_approved" ||
            data.catogory === "application_canceled" ||
            data.catogory === "viewing_req_received" ||
            data.catogory === "viewing_req_rejected" ||
            data.catogory === "viewing_req_canceled" ||
            data.catogory === "viewing_req_approved"
          )
            props.history.push({ pathname: "/applications" });
          else if (
            data.catogory === "contract_created" ||
            data.catogory === "contract_updated" ||
            data.catogory === "contract_terminated" ||
            data.catogory === "contract_approved" ||
            data.catogory === "contract_canceled" ||
            data.catogory === "contract_accepted" ||
            data.catogory === "backgroud_chk_received" ||
            data.catogory === "credit_chk_received"
          )
            props.history.push({ pathname: "/contracts" });
          else if (
            data.catogory === "maintenance_req_posted" ||
            data.catogory === "maintenance_req_approved" ||
            data.catogory === "maintenance_req_accepted" ||
            data.catogory === "maintenance_req_porArranged" ||
            data.catogory === "maintenance_req_finish"
          )
            props.history.push({ pathname: "/maintenance" });
          else if (
            data.catogory === "JobStatusPending" ||
            data.catogory === "JobStatusVisited" ||
            data.catogory === "JobStatusJobstarted" ||
            data.catogory === "JobStatusCanceled" ||
            data.catogory === "JobStatusFinished" ||
            data.catogory === "JobStatusRejected" ||
            data.catogory === "ServiceRequestCreated" ||
            data.catogory === "ServiceRequestApproved" ||
            data.catogory === "ServiceRequestRejected" ||
            data.catogory === "ServiceRequestCanceled" ||
            data.catogory === "ServiceRequestUpdatedByTenant" ||
            data.catogory === "ServiceRequestUpdatedByLandlord" ||
            data.catogory === "ServiceRequestMod}else ificationRequested" ||
            data.catogory === "ProviderInvited" ||
            data.catogory === "QuotationReceived" ||
            data.catogory === "QuotationApproved" ||
            data.catogory === "QuotationRejected" ||
            data.catogory === "QuotationModificationRequested" ||
            data.catogory === "QuotationUpdated" ||
            data.catogory === "QuotationCanceled" ||
            data.catogory === "JobAssigned" ||
            data.catogory === "JobAssignedUpdated" ||
            data.catogory === "JobFinishedByProvider" ||
            data.catogory === "JobFinishedApprovedByTenant" ||
            data.catogory === "JobCanceled" ||
            data.catogory === "JobDelayRequest" ||
            data.catogory === "JobDelayNotified"
          ) {
            handleMaintenancePath(data);
          } else props.history.push({ pathname: "/" });
        }
      });
    }
  }
  function handleMenuList() {
    let classes = "";
    classes += menu ? "navItem menuShow" : "navItem  ";
    return classes;
  }
  function changeMenuList() {
    setMenu(!menu);
  }
  const logout = () => {
    JwtAuthService.logout(null);
    AuthCheck();
    dispatch(removeCustomerPaymentDetail());
    dispatch(removeCustomerPaymentStatus());
    dispatch(removeAccountPaymentStatus());
    dispatch(removeRecentPayment());
    dispatch(removeContract());
    dispatch(removeApplication());
    dispatch(removeProperty());
    dispatch(removeMaintenance());
    changeSignState("signIn");
  };
  const changeSignState = (status) => {
    if (status === "signIn") {
      props.history.push({ pathname: "/login" });
      props.changeSign(true);
    } else {
      props.history.push({ pathname: "/signup" });
      props.changeSign(false);
    }
  };
  const handleNotification = useCallback(() => {
    if (userData !== null) {
      if (userData.username !== null) {
        try {
          getNotification(userData.username).then((response) => {
            if (response.status === 200) {
              if (response.data.length !== 0) {
                setNotificationData(response.data);
              } else {
                console.log("No Data!");
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, [userData]);

  const getCount = useCallback(() => {
    if (userData !== null) {
      if (userData.username !== "") {
        try {
          getNotificationCount(userData.username).then((response) => {
            if (response.status === 200) {
              setCount(response.data);
              handleNotification();
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, [userData, handleNotification]);
  useEffect(() => {
    window.addEventListener("click", function (event) {
      if (event.target.id !== "delete") {
        setCommon(false);
      }
      if (
        event.target.id !== "mobileMenu" &&
        event.target.id !== "mobileMenu1"
      ) {
        setMenu(false);
      }
    });
    handleNotification();
    getCount();
  }, [getCount, handleNotification]);

  useEffect(() => {
    const fetchImage = (username, url) => {
      const toDataURL = (url1) =>
        fetch(url1)
          .then((response) => {
            if (response.status === 200) {
              return response.blob();
            } else {
              throw new Error(response);
            }
          })
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          )
          .catch((res) => {
            throw new Error(res);
          });

      toDataURL(url)
        .then((dataUrl) => {
          setProfileImage(dataUrl);

          dispatch(
            appendTempProfileImage({
              username: username,
              image: dataUrl,
            })
          );
        })
        .catch((res) => {
          setProfileImage(image);
          dispatch(
            appendTempProfileImage({
              username: username,
              image: image,
            })
          );
        });
    };
    const setImage = (data) => {
      if (data.provider === "" || data.provider === null) {
        setProfileImage(image);
        dispatch(
          appendTempProfileImage({
            username: data.username,
            image: image,
          })
        );
      } else if (data.provider === "local") {
        if (data.coverImage === null) {
          setProfileImage(image);
        } else {
          fetchImage(
            data.username,
            url + `api/user/${data.username}/image/download`
          );
        }
      } else {
        fetchImage(data.username, data.coverImage);
      }
    };
    const filterImage = (data) => {
      let usersImage = tempProfileImages.filter(
        (todo) => todo.username === data.username
      );
      if (usersImage.length === 0) {
        setImage(data);
      } else {
        setProfileImage(usersImage[0].image);
      }
    };
    if (userData && role) {
      if (userData.username) {
        if (role === "Landlord") {
          setTopicName("/landlord/" + userData.username);
        } else {
          setTopicName("/tenant/" + userData.username);
        }
        filterImage(userData);
      }
    }
  }, [userData, role, dispatch, tempProfileImages]);

  return (
    <div className="navHead" style={{ zIndex: "999" }}>
      <SockJsClient
        url={url + "api/notify/websocket"}
        // topics={["/landlord/user"]}
        topics={[topicName]}
        // topics={[props.role !== null?(props.role === "Landlord"?"/landlord/user":props.role === "Tenant"?"/tenant/user":"/admin/user"):"/landlord/user"]}
        onConnect={() => {
          getCount();
          console.log("connected");
        }}
        onDisconnect={() => {
          console.log("Disconnected");
        }}
        onMessage={async (_mgs) => {
          await getCount();
        }}
        ref={(_client) => {
          // this.clientRef = client;
        }}
      />
      <header className={dark ? "dark box2" : " box2"}>
        <div className="container-fluid ">
          <nav className="row menu1 pt-3">
            <div className="col-10 menuAlign menuAlignLeft">
              <ul className=" p-0 m-0 ">
                <li>
                  <h2
                    className="one pointer"
                    onClick={() => props.history.push({ pathname: "/" })}
                  >
                    Axallant
                  </h2>
                  <span className="span4">
                    {props.location.pathname.slice(0, 12) === "/maintenance" &&
                    role !== "Guest" ? (
                      "Maintenance Manager"
                    ) : props.location.pathname.slice(0, 12) === "/dashboard" &&
                      role !== "Guest" ? (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Insights
                      </>
                    ) : role === "Landlord" ? (
                      <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                    ) : role === "Tenant" ? (
                      <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tenant Manager</>
                    ) : (
                      <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                    )}
                  </span>
                </li>
                {!auth && (
                  <>
                    <li>
                      <span
                        onClick={() =>
                          props.history.push({
                            pathname: "/rental-units",
                          })
                        }
                        className={
                          props.location.pathname === "/rental-units"
                            ? "navOne activeNav"
                            : "navOne"
                        }
                      >
                        Rental Units
                      </span>
                    </li>
                    {/* <li>
                      <span
                        // onClick={() =>
                        //   props.history.push({ pathname: "/features" })
                        // }
                        className={
                          props.location.pathname === "/features"
                            ? "navOne activeNav"
                            : "navOne"
                        }
                      >
                        Features
                      </span>
                    </li> */}

                    <li>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/about-us" })
                        }
                        className={
                          props.location.pathname === "/about-us"
                            ? "navOne activeNav"
                            : "navOne"
                        }
                      >
                        About Us
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/contact-us" })
                        }
                        className={
                          props.location.pathname === "/contact-us"
                            ? "navOne activeNav"
                            : "navOne"
                        }
                      >
                        Contact Us
                      </span>
                    </li>
                  </>
                )}
                {auth &&
                  (role === "Tenant" ? (
                    <>
                      <li>
                        <span
                          onClick={() => {
                            return props.history.push({
                              pathname: "/rental-units",
                            });
                          }}
                          className={
                            props.location.pathname === "/rental-units"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Rental Units
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            props.history.push({
                              pathname: "/applications",
                            })
                          }
                          className={
                            String(props.location.pathname).substring(0, 12) ===
                            "/application"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Applications
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            props.history.push({
                              pathname: "/contracts",
                            })
                          }
                          className={
                            props.location.pathname === "/contracts"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Contracts
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            props.history.push({ pathname: "/payments" })
                          }
                          className={
                            props.location.pathname === "/payments" ||
                            props.location.pathname === "/payments/add"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Payments
                        </span>
                      </li>
                      {process.env.REACT_APP_STATUS!=="Production"&&<><li>
                        <span
                          onClick={() =>
                            props.history.push({
                              pathname: "/maintenance",
                            })
                          }
                          className={
                            props.location.pathname === "/maintenance"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Maintenance
                        </span>
                      </li></>}
                    </>
                  ) : role === "Landlord" ? (
                    <>
                      <li>
                        <span
                          onClick={() =>
                            props.history.push({ pathname: "/properties" })
                          }
                          className={
                            props.location.pathname === "/properties"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Properties
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            return props.history.push({
                              pathname: "/applications",
                            });
                          }}
                          className={
                            String(props.location.pathname).substring(0, 12) ===
                            "/application"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Applications
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            return props.history.push({
                              pathname: "/contracts",
                            });
                          }}
                          className={
                            props.location.pathname === "/contracts"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Contracts
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            props.history.push({ pathname: "/payments" })
                          }
                          className={
                            props.location.pathname === "/payments" ||
                            props.location.pathname === "/payments/add"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Payments
                        </span>
                      </li>
                      {process.env.REACT_APP_STATUS!=="Production"&&<> <li>
                        <span
                          onClick={() => {
                            return props.history.push({
                              pathname: "/maintenance",
                            });
                          }}
                          className={
                            props.location.pathname === "/maintenance"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Maintenance
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() =>
                            props.history.push({
                              pathname: "/dashboard",
                            })
                          }
                          // style={{ position:"absolute",marginTop:"5px" }}
                          className={
                            props.location.pathname === "/dashboard"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Dashboard
                        </span>
                      </li></>}
                    </>
                  ) : (
                    <>
                      <li>
                        <span
                          onClick={() => {
                            return props.history.push({
                              pathname: "/rental-units",
                            });
                          }}
                          className={
                            props.location.pathname === "/rental-units"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Rental Units
                        </span>
                      </li>

                      <li>
                        <span
                          onClick={() =>
                            props.history.push({ pathname: "/payments" })
                          }
                          className={
                            props.location.pathname === "/payments" ||
                            props.location.pathname === "/payments/add"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Payments
                        </span>
                      </li>
                      {process.env.REACT_APP_STATUS!=="Production"&&<><li>
                        <span
                          onClick={() =>
                            props.history.push({
                              pathname: "/maintenance",
                            })
                          }
                          className={
                            props.location.pathname === "/maintenance"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Maintenance
                        </span>
                      </li>
                      <li>
                        <span
                          onClick={() => {
                            return props.history.push({
                              pathname: "/dashboard",
                            });
                          }}
                          className={
                            props.location.pathname === "/dashboard"
                              ? "navOne activeNav"
                              : "navOne"
                          }
                        >
                          Dashboard
                        </span>
                      </li></>}
                    </>
                  ))}
              </ul>
            </div>
            <div className="col-2 text-right menuAlign menuAlignRight" >
              <ul className="show" >
                {/* <input className={handleSearch()}></input> */}
                {!auth && (
                  <>
                    {/* <li>
                      <i
                        className="fa fa-search searchButton"
                        aria-hidden="true"
                        onClick={changeSearch}
                      ></i>
                    </li> */}
                    <li>
                      <span
                        onClick={() => changeSignState("signIn")}
                        className="navOne "
                      >
                        SignIn
                      </span>
                    </li>
                    |
                    <li>
                      <span
                        onClick={() => changeSignState("signUp")}
                        // onClick={() =>
                        //   dispatch(
                        //     appendAlert(
                        //       "Currently you can't register the new user."
                        //     )
                        //   )
                        // }
                        className="navOne"
                      >
                        SignUp
                      </span>
                    </li>
                  </>
                )}
                {auth && (
                  <>
                    {/* <li>
                      <i
                        className="fa fa-search searchButton"
                        aria-hidden="true"
                        onClick={changeSearch}
                      ></i>
                    </li> */}
                    <li className="pl-1 pr-2 " onClick={changeNotification}>
                      <i
                        className="fa fa-bell-o pt-1"
                        aria-hidden="true"
                        style={{
                          fontSize: "18px",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                      ></i>{" "}
                      {count > 0 && (
                        <span className="badge badge-danger  notification bgPri pointer">
                          {count}
                        </span>
                      )}{" "}
                    </li>
                    {/* <li
                      style={{
                        paddingLeft: "10px",
                        marginRight: "0px",
                        cursor: "pointer",
                      }}
                      onClick={() => dispatch(changeDark())}
                    >
                      <i
                        className={
                          dark ? "fa fa-toggle-on" : "fa fa-toggle-off "
                        }
                        style={{ fontSize: "20px" }}
                        aria-hidden="true"
                      ></i>
                    </li> */}
                    <li
                      style={{ paddingRight: "18px", cursor: "pointer" }}
                      type=""
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                    >
                      <img
                        src={profileImage}
                        alt="profile"
                        style={{
                          height: "25px",
                          width: "25px",
                          borderRadius: "15px",
                          marginLeft: "0px",
                        }}
                      ></img>
                      <i
                        className="fa fa-caret-down pl-2 pt-1"
                        aria-hidden="true"
                        style={{ fontSize: "22px", position: "absolute" }}
                      ></i>
                    </li>
                    <div
                      className="dropdown-menu  absolute profileMenu navProfile"
                      aria-labelledby="dropdownMenu2"
                      style={{
                        marginTop: "20px",
                        marginRight: "20px",
                        width: "200px",
                      }}
                    >
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/profile" })
                        }
                        className="dropdown-item profileList"
                      >
                        <i className="fa fa-user-circle" aria-hidden="true"></i>{" "}
                        &nbsp;Profile
                      </span>
                      <span
                        className="dropdown-item profileList "
                        onClick={logout}
                      >
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        &nbsp;Sign out
                      </span>
                      <span className="dropdown-item  noPointer navNameTag">
                        {userData.firstName
                          ? sortText(
                              userData.firstName + " " + userData.lastName,
                              0,
                              17
                            )
                          : sortText(userData.username, 0, 17)}
                      </span>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </nav>
          {common && notification && (
            <Notification
              handleDelete={handleDelete}
              notificationData={notificationData}
              handleClick={handleClick}
              deleteLoader={deleteLoader}
            />
          )}
          <nav className="row">
            <div className="col-4 menu2 pt-3 ">
              <h2
                className="one pointer"
                onClick={() => props.history.push({ pathname: "/" })}
              >
                Axallant
              </h2>
              <span className="span4">
                {props.location.pathname === "/maintenance" &&
                role !== "Guest" ? (
                  "Maintenance Manager"
                ) : props.location.pathname === "/dashboard" &&
                  role !== "Guest" ? (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Insights
                  </>
                ) : role === "Landlord" ? (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                ) : role === "Tenant" ? (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tenant Manager</>
                ) : (
                  <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                )}
              </span>
            </div>
            <div className="col-8 text-right menu2 menuAlign pt-3">
              <ul className="show" >
                {!auth && (
                  <>
                    {/* <input className={handleSearchMobile()}></input> */}
                    {/* <li>
                      <i
                        className="fa fa-search searchButton"
                        aria-hidden="true"
                        onClick={changeSearch}
                      ></i>
                    </li> */}
                    <li>
                      <span
                        onClick={() => changeSignState("signIn")}
                        className="navOne"
                      >
                        SignIn
                      </span>
                    </li>
                    |
                    <li>
                      <span
                        onClick={() => changeSignState("signUp")}
                        // onClick={() =>
                        //   pushAlert("Currently you can't register the new user.","warning")
                        // }
                        className="navOne"
                      >
                        SignUp
                      </span>
                    </li>
                    <li className="menuIcon pointer" onClick={changeMenuList}>
                      <i
                        className="fa fa-bars"
                        aria-hidden="true"
                        id="mobileMenu1"
                      ></i>
                    </li>
                  </>
                )}
                {auth && (
                  <>
                    {/* <input
                      className={handleSearchMobile()}
                      style={{ marginRight: "-65px" }}
                    ></input> */}
                    {/* <li>
                      <i
                        className="fa fa-search searchButton"
                        aria-hidden="true"
                        onClick={changeSearch}
                      ></i>
                    </li> */}
                    <li className="pl-1 pr-2" onClick={changeNotification}>
                      <i
                        className="fa fa-bell-o pt-1"
                        aria-hidden="true"
                        style={{
                          fontSize: "18px",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                      ></i>{" "}
                      {count > 0 && (
                        <span className="badge badge-danger round notification bgPri pointer">
                          {count}
                        </span>
                      )}{" "}
                    </li>
                    {/* <li
                      style={{
                        paddingLeft: "10px",
                        marginRight: "0px",
                        cursor: "pointer",
                      }}
                      onClick={() => dispatch(changeDark())}
                    >
                      <i
                        className={
                          dark ? "fa fa-toggle-on" : "fa fa-toggle-off"
                        }
                        style={{ fontSize: "20px" }}
                        aria-hidden="true"
                      ></i>
                    </li> */}
                    <li
                      style={{ paddingRight: "18px", cursor: "pointer" }}
                      type=""
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                    >
                      <img
                        src={profileImage}
                        alt="profile"
                        style={{
                          height: "25px",
                          width: "25px",
                          borderRadius: "15px",
                          marginLeft: "0px",
                        }}
                      ></img>
                      <i
                        className="fa fa-caret-down pl-2 pt-1"
                        aria-hidden="true"
                        style={{ fontSize: "22px", position: "absolute" }}
                      ></i>
                    </li>
                    <li
                      className="menuIcon pointer  ml-2 mr-4"
                      onClick={changeMenuList}
                    >
                      <i
                        className="fa fa-bars"
                        aria-hidden="true"
                        id="mobileMenu1"
                      ></i>
                    </li>
                    <div
                      className="dropdown-menu  absolute profileMenu"
                      aria-labelledby="dropdownMenu2"
                      style={{
                        marginTop: "20px",
                        marginRight: "20px",
                        width: "200px",
                      }}
                    >
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/profile" })
                        }
                        className="dropdown-item profileList"
                      >
                        <i className="fa fa-user-circle" aria-hidden="true"></i>{" "}
                        &nbsp;Profile
                      </span>
                      <span
                        className="dropdown-item profileList"
                        onClick={logout}
                      >
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        &nbsp;Sign out
                      </span>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/profile" })
                        }
                        className="dropdown-item profileList  navNameTag"
                      >
                        {userData.firstName
                          ? sortText(
                              userData.firstName + " " + userData.lastName,
                              0,
                              17
                            )
                          : sortText(userData.username, 0, 17)}
                      </span>
                    </div>
                  </>
                )}
              </ul>
            </div>
          </nav>
          <nav>
            <div className="row  " style={{ marginRight: "-30px" }}>
              <div className="col-4 menu3">
                <h2
                  className="one pointer "
                  onClick={() => props.history.push({ pathname: "/" })}
                >
                  Axallant
                </h2>
                <span
                  className="span4"
                  style={{ fontWeight: "100", top: "0", left: "140px" }}
                >
                  {props.location.pathname === "/maintenance" &&
                  role !== "Guest" ? (
                    "Maintenance Manager"
                  ) : props.location.pathname === "/dashboard" &&
                    role !== "Guest" ? (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Insights
                    </>
                  ) : role === "Landlord" ? (
                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                  ) : role === "Tenant" ? (
                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tenant Manager</>
                  ) : (
                    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                  )}
                </span>
              </div>
              <div className="col text-right menu3 menuMobileRight  mb-0 absolute mr-0 pr-2">
                {!auth && (
                  <>
                    <li className="p-0 m-0 mr-2 ml-2">
                      <span
                        onClick={() => changeSignState("signIn")}
                        className="navOne"
                      >
                        SignIn
                      </span>
                    </li>
                    |
                    <li className="p-0 m-0 mr-2 ml-2">
                      <span
                        onClick={() => changeSignState("signUp")}
                        // onClick={() =>
                        //   pushAlert("Currently you can't register the new user.","warning")
                        // }
                        className="navOne"
                      >
                        SignUp
                      </span>
                    </li>
                    <li className="menuIcon pointer" onClick={changeMenuList}>
                      <i
                        className="fa fa-bars"
                        aria-hidden="true"
                        id="mobileMenu1"
                      ></i>
                    </li>
                  </>
                )}
                {auth && (
                  <>
                    <li
                      className="pl-1 pr-2"
                      onClick={changeNotification}
                      style={{ marginRight: "14px" }}
                    >
                      <i
                        className="fa fa-bell-o pt-1"
                        aria-hidden="true"
                        style={{
                          fontSize: "18px",
                          position: "absolute",
                          cursor: "pointer",
                        }}
                      ></i>{" "}
                      {count > 0 && (
                        <span className="badge badge-danger round notification bgPri pointer">
                          {count}
                        </span>
                      )}{" "}
                    </li>
                    {/* <li
                      style={{
                        paddingLeft: "10px",
                        marginRight: "0px",
                        cursor: "pointer",
                      }}
                      onClick={() => dispatch(changeDark())}
                    >
                      <i
                        className={
                          dark ? "fa fa-toggle-on" : "fa fa-toggle-off"
                        }
                        style={{ fontSize: "20px" }}
                        aria-hidden="true"
                      ></i>
                    </li> */}
                    <li
                      style={{ paddingRight: "18px", cursor: "pointer" }}
                      type=""
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      // aria-haspopup="true"
                      // aria-expanded="false"
                    >
                      <img
                        src={profileImage}
                        alt="profile"
                        style={{
                          height: "25px",
                          width: "25px",
                          borderRadius: "15px",
                          marginLeft: "0px",
                        }}
                      ></img>
                      <i
                        className="fa fa-caret-down pl-2 pt-1"
                        aria-hidden="true"
                        style={{ fontSize: "22px", position: "absolute" }}
                      ></i>
                    </li>
                    <li
                      className="menuIcon pointer  ml-2 mr-4"
                      onClick={changeMenuList}
                    >
                      <i
                        className="fa fa-bars"
                        aria-hidden="true"
                        id="mobileMenu1"
                      ></i>
                    </li>
                    <div
                      className="dropdown-menu  absolute profileMenu"
                      aria-labelledby="dropdownMenu2"
                      style={{
                        marginTop: "20px",
                        marginRight: "20px",
                        width: "200px",
                      }}
                    >
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/profile" })
                        }
                        className="dropdown-item profileList"
                      >
                        <i className="fa fa-user-circle" aria-hidden="true"></i>{" "}
                        &nbsp;Profile
                      </span>
                      <span
                        className="dropdown-item profileList"
                        onClick={logout}
                      >
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                        &nbsp;Sign out
                      </span>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/profile" })
                        }
                        className="dropdown-item  noPointer navNameTag "
                      >
                        {userData.firstName
                          ? sortText(
                              userData.firstName + " " + userData.lastName,
                              0,
                              17
                            )
                          : sortText(userData.username, 0, 17)}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* <div
              className="row text-right menu3 menuAlign  pt-0 pb-2 "
              style={{ marginRight: "-30px" }}
            >
              <ul className="col">
                <input className={handleSearchMobile()}></input>

                <li>
                  <i
                    className="fa fa-search searchButton"
                    aria-hidden="true"
                    onClick={changeSearch}
                  ></i>
                </li>
                <li className="menuIcon pointer" onClick={changeMenuList}>
                  <i
                    className="fa fa-bars"
                    aria-hidden="true"
                    id="mobileMenu1"
                  ></i>
                </li>
              </ul>
            </div> */}
          </nav>
        </div>
      </header>
      {menu && (
        <div className={handleMenuList()} id="mobileMenu">
          <div className="container-fluid ">
            <div className="row ">
              <div className="col-6 text-center pt-5   ">
                <img
                  src={icon}
                  alt="profile"
                  style={{
                    height: "100px",
                    width: "100px",

                    marginLeft: "20px",
                  }}
                  className="mt-2"
                ></img>
                <h2
                  className="one pointer pt-3 menu4 "
                  onClick={() => props.history.push({ pathname: "/" })}
                >
                  Axallant
                </h2>

                <div style={{ marginTop: "-25px", marginLeft: "140px" }}>
                  <span className="span4">
                    {props.location.pathname === "/maintenance" &&
                    role !== "Guest" ? (
                      "Maintenance Manager"
                    ) : props.location.pathname === "/dashboard" &&
                      role !== "Guest" ? (
                      <>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Insights
                      </>
                    ) : role === "Landlord" ? (
                      <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                    ) : role === "Tenant" ? (
                      <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tenant Manager</>
                    ) : (
                      <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Property Manager</>
                    )}
                  </span>
                </div>
              </div>
              <div className="col text-left mobileNav">
                {!auth && (
                  <>
                    <span
                      onClick={() =>
                        props.history.push({ pathname: "/rental-units" })
                      }
                      className={
                        props.location.pathname === "/rental-units"
                          ? "navOne navOneMobile itemList activeNav"
                          : "navOne  navOneMobile"
                      }
                    >
                      Rental Units
                    </span>
                    {/* <br></br>
                    <span
                      // onClick={() =>
                      //   props.history.push({ pathname: "/features" })
                      // }
                      className={
                        props.location.pathname === "/features"
                          ? "navOne navOneMobile itemList activeNav"
                          : "navOne navOneMobile"
                      }
                    >
                      Features
                    </span> */}
                    <br />

                    <span
                      onClick={() =>
                        props.history.push({ pathname: "/about-us" })
                      }
                      className={
                        props.location.pathname === "/about-us"
                          ? "navOne navOneMobile itemList activeNav"
                          : "navOne navOneMobile"
                      }
                    >
                      About Us
                    </span>
                    <br />
                    <span
                      onClick={() =>
                        props.history.push({ pathname: "/contact-us" })
                      }
                      className={
                        props.location.pathname === "/contact-us"
                          ? "navOne navOneMobile itemList activeNav"
                          : "navOne navOneMobile"
                      }
                    >
                      Contact Us
                    </span>
                    <br />
                  </>
                )}
                {auth &&
                  (role === "Tenant" ? (
                    <>
                      <span
                        onClick={() =>
                          props.history.push({
                            pathname: "/rental-units",
                          })
                        }
                        className={
                          props.location.pathname === "/rental-units"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Rental Units
                      </span>
                      <br></br>
                      <span
                        onClick={() => {
                          return props.history.push({
                            pathname: "/applications",
                          });
                        }}
                        className={
                          props.location.pathname === "/applications"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne navOneMobile"
                        }
                      >
                        Applications
                      </span>
                      <br />
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/contracts" })
                        }
                        className={
                          props.location.pathname === "/contracts"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Contracts
                      </span>
                      <br></br>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/payments" })
                        }
                        className={
                          props.location.pathname === "/payments" ||
                          props.location.pathname === "/payments/add"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Payments
                      </span>
                      <br></br>
                      {process.env.REACT_APP_STATUS!=="Production"&&<><span
                        onClick={() =>
                          props.history.push({
                            pathname: "/maintenance",
                          })
                        }
                        className={
                          props.location.pathname === "/maintenance"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Maintenance
                      </span>
                      <br></br></>}
                    </>
                  ) : role === "Landlord" ? (
                    <>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/properties" })
                        }
                        className={
                          props.location.pathname === "/properties"
                            ? "navOne navOneMobile itemList activeNav "
                            : "navOne  navOneMobile "
                        }
                      >
                        Properties
                      </span>
                      <br></br>
                      <span
                        onClick={() =>
                          props.history.push({
                            pathname: "/applications",
                          })
                        }
                        className={
                          props.location.pathname === "/applications"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Applications
                      </span>
                      <br></br>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/contracts" })
                        }
                        className={
                          props.location.pathname === "/contracts"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Contracts
                      </span>
                      <br></br>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/payments" })
                        }
                        className={
                          props.location.pathname === "/payments" ||
                          props.location.pathname === "/payments/add"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Payments
                      </span>
                      <br></br>
                      {process.env.REACT_APP_STATUS!=="Production"&&<><span
                        onClick={() =>
                          props.history.push({
                            pathname: "/maintenance",
                          })
                        }
                        className={
                          props.location.pathname === "/maintenance"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Maintenance
                      </span>
                      <br></br>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/dashboard" })
                        }
                        className={
                          props.location.pathname === "/dashboard"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Dashboard
                      </span>
                      <br></br></>}
                    </>
                  ) : (
                    <>
                      <span
                        onClick={() =>
                          props.history.push({
                            pathname: "/rental-units",
                          })
                        }
                        className={
                          props.location.pathname === "/rental-units"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Rental Units
                      </span>
                      <br></br>

                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/payments" })
                        }
                        className={
                          props.location.pathname === "/payments" ||
                          props.location.pathname === "/payments/add"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Payments
                      </span>
                      <br></br>
                      {process.env.REACT_APP_STATUS!=="Production"&&<><span
                        onClick={() =>
                          props.history.push({
                            pathname: "/maintenance",
                          })
                        }
                        className={
                          props.location.pathname === "/maintenance"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Maintenance
                      </span>
                      <br></br>
                      <span
                        onClick={() =>
                          props.history.push({ pathname: "/dashboard" })
                        }
                        className={
                          props.location.pathname === "/dashboard"
                            ? "navOne navOneMobile itemList activeNav"
                            : "navOne  navOneMobile"
                        }
                      >
                        Dashboard
                      </span>
                      <br></br></>}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default withRouter(NavBar);
