import React, { Component } from "react";
import {
  getLandlordPayments,
  getPaymentDashboardDatas,
} from "../../../../Services/ApiServices";
import "../payment.css";
import "../../Maintenance/maintenance.css";
import Loader2 from "../../../Loader/Loader2";
import { withRouter } from "react-router-dom";
import PaymentDashboard from "../PaymentDashboard";
import { connect } from "react-redux";
import {
  appendAlert,
  setRecentPayment,
  setPaymentDashboardData,
} from "../../../../actions";
import LandlordRecentPaymentList from "./LandlordRecentPaymentList";
import { addressFormatter } from "../../../../Defined/Function";

class PaymentLandlord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractId: 0,
      // contractId: this.props.contractArray!==null?this.props.contractArray[0].contractId:0,
      paymentsList: [],
    };
    this.getUnit = this.getUnit.bind(this);
    this.onchange = this.onchange.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      await getLandlordPayments(this.props.userDetail.username).then(
       async (response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              await this.props.setRecentPayment(null);
              this.props.setRecentPayment(response.data.sort((a, b) =>b.paidDate.localeCompare(a.paidDate)));
            } else {
              this.props.setRecentPayment([]);
            }
          } else {
            // this.props.appendAlert("Something went wrong!, Please try again later.", "danger");
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
    try {
      await getPaymentDashboardDatas().then((response) => {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            this.props.setPaymentDashboardData(response.data);
          } else {
            this.props.setPaymentDashboardData([]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  getUnit(contract, index) {
    var unit = this.props.tempListingArray.filter(
      (unit) => unit.rentalEntity.id === contract.rentalEntityId
    );
    if (index === 0) {
      // this.setState({contractId:contract.id});
    }
    if (unit !== null) {
      if (unit.length > 0) {
        return (
          <option value={contract.contractId}>
            {addressFormatter(unit[0].address)}
          </option>
        );
      }
    }
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          paddingTop: "105px",
          minHeight: window.screen.height,
        }}
      >
        <PaymentDashboard tenantStatusComplete={0} tenantStatusTotal={0} />
        <div className="row  ml-1 mr-1 mb-3 mt-2">
          <div className="  ">
            <h1
              className={
                this.props.isDark ? "axText text-dark" : "axText text-dark"
              }
            >
              Recent Payments
            </h1>
          </div>
          <div className="col ">
            <hr className="new"></hr>
          </div>
        </div>

        {this.props.contractArray === null ||
        this.props.recentPaymentArray === null ||
        this.props.tempListingArray === null ? (
          <div className="col-12 mb-5">
            <Loader2 />
          </div>
        ) : (
          this.props.contractArray.length !== 0 && (
            <>
              <div className="row mr-1 ml-1 ">
                <div className="col-md-2 mr-2 squareDivPri maintenanceRequestHeaderAlign1 text-center">
                  Contracts
                </div>
                <div className="col-12 maintenanceRequestHeaderAlign2 mb-2 p-0">
                  <span className="axText0">Contracts</span>
                </div>

                <div className="col-md pr-0  p-0">
                  <select
                    className={"maintenanceInputSelect custom-select"}
                    onChange={this.onchange}
                    name={"contractId"}
                    id={"contractId"}
                  >
                    <option value={0} selected="selected">
                      All payments
                    </option>
                    {this.props.contractArray.map((contract, index) =>
                      this.getUnit(contract, index)
                    )}
                  </select>
                </div>
              </div>
              <div className={"row mt-4 mr-1 ml-1 mb-5  recentPayment"}>
                <div className="col-12 ">
                  <div className="recentPaymentTableWeb">
                    <div
                      className={"row"}
                      style={{
                        marginTop: "10px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        borderTopLeftRadius: "25px",
                        borderTopRightRadius: "25px",
                      }}
                    >
                      <div className="col-1 "></div>
                      <div className="col m-0 p-0 text-left ">
                        <p
                          className={"maintenanceHeadText"}
                          style={{ fontWeight: "bold" }}
                        >
                          Name
                        </p>
                      </div>
                      <div className="col-2 m-0 p-0  text-center">
                        <p
                          className={"maintenanceHeadText"}
                          style={{ fontWeight: "bold" }}
                        >
                          Type
                        </p>
                      </div>

                      <div className="col m-0 p-0 text-center">
                        <p
                          className={"maintenanceHeadText"}
                          style={{ fontWeight: "bold" }}
                        >
                          Paid
                        </p>
                      </div>
                      <div className="col m-0 p-0 text-center">
                        <p
                          className={"maintenanceHeadText"}
                          style={{ fontWeight: "bold" }}
                        >
                          Due
                        </p>
                      </div>
                      <div className="col-1 m-0 p-0 text-center">
                        <p
                          className={"maintenanceHeadText "}
                          style={{ fontWeight: "bold" }}
                        >
                          Status
                        </p>
                      </div>
                    </div>
                  </div>
                  {this.props.recentPaymentArray.length !== 0 ? (
                    this.props.recentPaymentArray
                      .filter(
                        (data) =>
                          parseInt(this.state.contractId) === 0 ||
                          parseInt(this.state.contractId) ===
                            parseInt(data.contractId)
                      )
                      .map((data, index) => {
                        return (
                          <LandlordRecentPaymentList
                            data={data}
                            eventKey={index}
                          />
                        );
                      })
                  ) : (
                    <div className="text-center mb-4 mt-4 ml-5">
                      <span className="spanBig ">
                        No transactions in recent payment history.
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDark: state.isDark,
    dark: state.darkReducer,
    user: state.userReducer,
    userDetail: state.user,
    alert: state.alertReducer,
    recentPayment: state.recentPaymentReducer,
    recentPaymentArray: state.recentPayment,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    contract: state.contractReducer,
    contractArray: state.contract,
    paymentDashboardData: state.paymentDashboardDataReducer,
    paymentDashboardDataArray: state.paymentDashboardData,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setRecentPayment,
    setPaymentDashboardData,
  })(PaymentLandlord)
);
