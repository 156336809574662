import React from "react";
import "./loader3.css";

const Loader3 = (props) => {
  const styleLoader={
    minWidth:props.width,
    transform: "translate("+props.transX+", "+props.transY+")"
  }
  return (
    <div className="loader3Head" style={ styleLoader }>
      <div className="loader3"></div>
    </div>
  );
};
export default Loader3;
