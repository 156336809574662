import React, { useState } from "react";
import "./style.css";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { resetPassword } from "../../Services/ApiServices";
import Loader3 from "../Loader/loader3";

const ResetPassword = (props) => {
  const dark = useSelector((state) => state.isDark);
  const [send, setSend] = useState(false);
  const [next, setNext] = useState(false);
  const [reset, setReset] = useState(false);
  const [state, setState] = useState({ password: "", confirmPassword: "" });
  const [passLength, changePassLength] = useState(false);
  const [passUpper, changePassUpper] = useState(false);
  const [passNum, changePassNum] = useState(false);
  const [passSpecial, changePassSpecial] = useState(false);
  const [strength, setStrength] = useState(1);

  function onchange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  }
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      resetFunction();
    }
  };

  function resetFunction() {
    if (state.password === "" || state.confirmPassword === "") {
      props.pushAlert("Please fill out all fields !", "warning");
    } else {
      if (strength > 4) {
        if (
          state.password !== "" &&
          state.confirmPassword !== "" &&
          state.password === state.confirmPassword
        ) {
          setSend(true);
          let data = {
            userName: window.atob(props.match.params.Id),
            password: state.password,
            token: props.match.params.token,
          };
          try {
            resetPassword(data).then((response) => {
              if (response.status === 200) {
                props.pushAlert("Your password is changed", "success");
                setSend(false);
                setReset(true);
              } else {
                setSend(false);
                setState({ password: "", confirmPassword: "" });
              }
            });
          } catch (error) {
            console.error(error);
          }
        } else {
          if (state.password !== state.confirmPassword) {
            props.pushAlert(
              "Those passwords didn't match. Try again. ",
              "warning"
            );
            setState({ ...state, password: "", confirmPassword: "" });
            changePassLength(false);
            changePassUpper(false);
            changePassNum(false);
            changePassSpecial(false);
            setStrength(1);
          }
          setNext(true);
        }
      } else {
        if (strength === 4) {
          props.pushAlert(
            "Your password strength is Fair,please give strong password!",
            "warning"
          );
        } else if (strength === 3) {
          props.pushAlert(
            "Your password strength is bad ,please give strong password!",
            "warning"
          );
        } else if (strength === 2) {
          props.pushAlert(
            "Your password strength is week ,please give strong password!",
            "warning"
          );
        } else if (strength === 1) {
          props.pushAlert( "Your password strength is week ,please give strong password!", "warning" );
        }
      }
    }
  }
  const handleChangePassword = (event) => {
    changePassUpper(false);
    changePassNum(false);
    changePassLength(false);
    changePassSpecial(false);
    let pass = event.target.value;
    const {
      target: { name, value },
    } = event;
    setState({ ...state, [name]: value });
    let x = 1;
    if (checkPasswordLength(pass)) {
      x = x + 1;
    }
    if (checkSpecialCharacters(pass)) {
      x = x + 1;
    }
    if (checkUppercase(pass)) {
      x = x + 1;
    }
    if (checkNumber(pass)) {
      x = x + 1;
    }
    if (pass === "") {
      x = 0;
    }
    setStrength(x);
  };
  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g; //eslint-disable-line
    if (pattern.test(password)) {
      changePassSpecial(true);
      return true;
    }
    return false;
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      changePassUpper(true);
      return true;
    }
    return false;
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      changePassNum(true);
      return true;
    }
    return false;
  };
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      changePassLength(true);
      return true;
    }
    return false;
  };
  return (
    <div
      className={
        dark
          ? "container-fluid forgotAlignDark mt-5"
          : "container-fluid forgotAlign "
      }
    >
      <div className="row">
        <div className=" col d-flex justify-content-center text-center  ">
          <div className="forgotCard">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="100px"
              viewBox="0 0 820.000000 764.000000"
              preserveAspectRatio="xMidYMid meet"
              className="pb-3"
            >
              <g
                transform="translate(0.000000,764.000000) scale(0.100000,-0.100000)"
                fill="rgb(255, 102, 102)"
                stroke="none"
              >
                <path
                  d="M3810 7139 c-614 -51 -1210 -276 -1693 -637 -1014 -760 -1498 -1957
                  -1306 -3229 83 -552 329 -1106 688 -1553 109 -135 376 -402 511 -511 389 -312
                  856 -538 1345 -650 252 -57 417 -73 745 -74 326 0 461 12 713 65 494 105 983
                  336 1382 655 134 106 411 384 520 520 475 595 725 1315 725 2085 -1 893 -319
                  1690 -927 2321 -574 595 -1303 940 -2129 1009 -137 11 -435 11 -574 -1z m645
                  -318 c631 -95 1194 -353 1677 -768 275 -236 555 -632 731 -1032 139 -315 221
                  -619 262 -976 19 -165 19 -296 0 -460 -74 -635 -293 -1172 -681 -1675 -491
                  -635 -1414 -1092 -2274 -1127 -327 -13 -778 75 -1165 229 -417 166 -859 463
                  -1113 748 -395 444 -676 1025 -776 1605 -47 273 -56 466 -31 680 83 707 365
                  1340 826 1848 169 185 461 406 744 561 387 211 776 330 1285 390 85 10 382 -4
                  515 -23z"
                />
                <path
                  d="M3972 5668 c-97 -13 -238 -62 -332 -115 -64 -36 -111 -74 -190 -153
                  -121 -121 -182 -218 -229 -365 -40 -122 -51 -240 -51 -528 l0 -244 -41 -18
                  c-57 -26 -153 -119 -194 -189 -65 -110 -66 -131 -63 -791 l3 -590 24 -60 c31
                  -79 83 -157 135 -201 52 -46 165 -101 235 -114 73 -14 1609 -14 1682 0 70 13
                  183 68 235 114 52 44 104 122 135 201 l24 60 3 590 c3 509 1 600 -13 661 -28
                  127 -123 254 -234 312 -119 64 -97 62 -858 62 l-693 0 0 268 c0 294 5 326 61
                  441 20 41 57 89 99 132 221 221 573 223 794 6 104 -102 152 -207 166 -360 11
                  -110 39 -165 103 -196 115 -56 240 4 268 127 12 52 -8 210 -38 306 -51 163
                  -124 278 -255 402 -205 193 -493 283 -776 242z m863 -1755 c47 -25 105 -84
                  131 -133 18 -32 19 -68 22 -464 3 -480 1 -498 -63 -573 -19 -23 -61 -54 -93
                  -70 l-57 -28 -660 0 c-623 0 -663 1 -709 19 -65 25 -124 79 -152 141 -24 50
                  -24 51 -24 481 0 473 1 479 60 554 18 23 56 52 92 70 l62 31 676 -3 c671 -3
                  675 -3 715 -25z"
                />
              </g>
            </svg>

            <h1 className="forgotHead pt-2">
              {!reset ? "Reset your password" : "Successful password reset !"}
            </h1>
            {!reset ? (
              <>
                <label className="checkedContainer text-left mt-5 resetLabel"></label>
                <input
                  className={
                    next && state.password === ""
                      ? "ApplicationInput alert"
                      : "ApplicationInput"
                  }
                  type="password"
                  name="password"
                  placeholder="New Password"
                  value={state.password}
                  onChange={handleChangePassword}
                  onKeyPress={onKeyUp}
                  id="passwordDropDown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                />
                <div
                  className="dropdown-menu p-0 resetStrength"
                  aria-labelledby="passwordDropDown"
                >
                  <div className=" passDropHead p-1 ">
                    {" "}
                    <span className="pl-2 resetStrengthText">
                      <b>Rules</b>
                    </span>
                  </div>
                  <div className=" passDropContent p-2 pr-3">
                    <span className="resetStrengthText">
                      Your password should contains,
                    </span>
                    <ul style={{ listStyleType: "none", marginLeft: "-20px" }}>
                      <li>
                        {passLength ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;
                        <span className="resetStrengthText">
                          At least 8 characters
                        </span>
                      </li>
                      <li>
                        {passUpper ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;
                        <span className="resetStrengthText">
                          At least 1 upper case letter
                        </span>
                      </li>
                      <li>
                        {passNum ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;
                        <span className="resetStrengthText">
                          At least 1 number
                        </span>
                      </li>
                      <li>
                        {passSpecial ? (
                          <i
                            className="fa fa-check-circle-o text-success"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle-o text-danger"
                            aria-hidden="true"
                          ></i>
                        )}
                        &nbsp;&nbsp;
                        <span className="resetStrengthText">
                          At least 1 special character
                        </span>
                      </li>
                    </ul>
                  </div>
                  <span className="pl-3 resetStrengthText">
                    <strong>strength:</strong>&nbsp;&nbsp;
                    {strength === 1
                      ? "Very Bad"
                      : strength === 2
                      ? "Bad"
                      : strength === 3
                      ? "OK"
                      : strength === 4
                      ? "Good"
                      : strength === 5
                      ? "Strong"
                      : ""}
                  </span>
                </div>
                {strength === 1 ? (
                  <div className="row   pb-1 resetAlert">
                    <div className="col text-center p-0 ">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{ width: "20%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 2 ? (
                  <div className="row pr-3 resetAlert pb-1">
                    <div className="col text-center p-0 ApplicationInput">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: "40%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 3 ? (
                  <div className="row pr-3 resetAlert pb-1">
                    <div className="col text-center p-0 ApplicationInput">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: "60%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 4 ? (
                  <div className="row pr-3 resetAlert pb-1">
                    <div className="col text-center p-0 ApplicationInput">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : strength === 5 ? (
                  <div className="row pr-3 resetAlert pb-1">
                    <div className="col text-center p-0 ApplicationInput">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row pr-3 resetAlert pb-1">
                    <div className="col text-center p-0 ApplicationInput">
                      <div className="progress " style={{ height: "5px" }}>
                        <div
                          className="progress-bar bg-light"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                {next && state.password === "" && (
                  <p className="spanValidateApp textPri text-left resetAlert ">
                    Please enter the password
                  </p>
                )}
                <label className="checkedContainer text-left mt-1 resetLabel"></label>
                <input
                  className={
                    next && state.confirmPassword === ""
                      ? "ApplicationInput alert"
                      : "ApplicationInput"
                  }
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={state.confirmPassword}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                />
                {next && state.confirmPassword === "" && (
                  <p className="spanValidateApp textPri text-left resetAlert">
                    Please enter the confirm password
                  </p>
                )}
                <button
                  onClick={() => resetFunction()}
                  name="reset"
                  id="reset"
                  className="mt-5 mb-5"
                  disabled={send && "disabled"}
                >
                  {!send ? (
                    "Reset my password"
                  ) : (
                    <>
                      Reset my password
                      <div className="text-center resetLoader1">
                        <Loader3 />
                      </div>
                    </>
                  )}
                </button>
              </>
            ) : (
              <>
                <p className="mr-3 ml-3 pt-3">
                  You can now use your new password to log in to your account!
                  &#128588;
                </p>
                <button
                  onClick={() => props.history.push({ pathname: "/login",state: {
                    sign:true
                  } })}
                  name="reset"
                  id="reset"
                  className="mt-3 mb-5"
                >
                  Login
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
