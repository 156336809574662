export const soraDetails = [
  {
    image:
      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/002-Lease-Plan-Image.png",
    name: "APT 002",
    unit: "002",
    plan: "002 SE Lower",
    sq: "303 sq. ft.",
    price: "1295",
    pdf: "https://soraseattle.com/uploads/primary/002-lease-plan.pdf",
    Features: ["1 Bath", "Quartz counters", "Washer / dryer", "Plank flooring"],
  },
  {
    image:
      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/003-Lease-Plan-Image.png",
    name: "APT 003",
    unit: "003",
    plan: "003 SW Lower",
    sq: "326 sq. ft.",
    price: "1265",
    pdf: "https://soraseattle.com/uploads/primary/003-lease-plan.pdf",
    Features: ["1 Bath", "Quartz counters", "Washer / dryer", "Plank flooring"],
  },
  {
    image:
      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/101-Lease-Plan-Image.png",
    name: "APT 101",
    unit: "101",
    plan: "101 Level 1",
    sq: "277 sq. ft.",
    price: "1350",
    pdf: "https://soraseattle.com/uploads/primary/101-lease-plan.pdf",
    Features: ["1 Bath", "Quartz counters", "Washer / dryer", "Plank flooring"],
  },
  {
    image:
      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/103-Lease-Plan-Image.png",
    name: "APT 103",
    unit: "103",
    plan: "103 Level 1",
    sq: "256 sq. ft.",
    price: "1340",
    pdf: "https://soraseattle.com/uploads/primary/103-lease-plan.pdf",
    Features: ["1 Bath", "Quartz counters", "Washer / dryer", "Plank flooring"],
  },

  //  {
  //    image:
  //      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/604-Lease-Plan-Image.png",
  //    name: "APT 603",
  //    unit: "603",
  //    plan: "603 Level 6",
  //    sq: "368 sq. ft.",
  //    price: "Income Restricted (MFTE)",
  //    pdf: "https://soraseattle.com/uploads/primary/603-Lease-Plan.pdf",
  //    Features: [
  //      "1 Bath",
  //      "Quartz counters",
  //      "Washer / dryer",
  //      "Plank flooring",
  //    ],
  //  },

  {
    image:
      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/701-Lease-Plan-Image.png",
    name: "APT 701",
    unit: "701",
    plan: "701 Loft Level 7",
    sq: "375 plus loft sq. ft.",
    price: "1915",
    pdf: "https://soraseattle.com/uploads/primary/701-lease-plan.pdf",
    Features: ["1 Bath", "Quartz counters", "Washer / dryer", "Plank flooring"],
  },

  {
    image:
      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/704-Lease-Plan-Image.png",
    name: "APT 704",
    unit: "704",
    plan: "704 Loft Level 7",
    sq: "450 plus loft sq. ft.",
    price: "2265",
    pdf: "https://soraseattle.com/uploads/primary/704-lease-plan.pdf",
    Features: [
      "1 Bath",
      "Quartz counters",
      "Washer / dryer",
      "Air Conditioning",
      "Plank flooring",
      "High ceilings with ladder loft",
    ],
  },
  {
    image:
      "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/705-Lease-Plan-Image.png",
    name: "APT 705",
    unit: "705",
    plan: "705 Loft Level 7",
    sq: "316 plus loft sq. ft.",
    price: "1715",
    pdf: "https://soraseattle.com/uploads/primary/705-lease-plan.pdf",
    Features: [
      "1 Bath",
      "High ceilings with ladder loft",
      "Air conditioning",
      "Quartz counters",
      "Washer / dryer",
      "Plank flooring",
    ],
  },
];
export const weekNumberArray = [1, 2, 3, 4, 5, 6, 7];
export const monthNumberArray = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export const monthNumberArrayString = [
  "1st day of month",
  " 2nd day of month",
  " 3rd day of month",
  "4th day of month",
  "5th day of month",
  "6th day of month",
  "7th day of month",
  "8th day of month",
  "9th day of month",
  "10th day of month",
  "11th day of month",
  "12th day of month",
  "13th day of month",
  "14th day of month",
  "15th day of month",
  "16th day of month",
  "17th day of month",
  "18th day of month",
  "19th day of month",
  "20th day of month",
  "21st day of month",
  "22nd day of month",
  "23rd day of month",
  "24th day of month",
  "25th day of month",
  "26th day of month",
  "27th day of month",
  "28th day of month",
  "29th day of month",
  "30th day of month",
  "31st day of month",
];
export const hundredNumberArray = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
  42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
  61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98,
  99, 100,
];
export const colors1 = [
  { backgroundColor: "#f038a3f3" },
  { backgroundColor: "#5e9ef3c4" },
 
  { backgroundColor: "#3023ecce" },
  { backgroundColor: "#bc38f0da" },
  { backgroundColor: "#f03838ea" },
  { backgroundColor: "#ec8423e8" },
  { backgroundColor: "#ecd823f3" },
  { backgroundColor: "#23eca9e7" },
  { backgroundColor: "#7dec23e8" },
  { backgroundColor: "rgb(212, 156, 72)" },
];
//  export const soraDetails1= [
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/Unit-001.png",
//     name: "APT 001",
//     unit: "001",
//     plan: "001 NE Lower",
//     sq: "299 sq. ft.",
//     price: "1200",
//     pdf: "https://soraseattle.com/uploads/primary/001-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/002-Lease-Plan-Image.png",
//     name: "APT 002",
//     unit: "002",
//     plan: "002 SE Lower",
//     sq: "303 sq. ft.",
//     price: "1225",
//     pdf: "https://soraseattle.com/uploads/primary/002-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/003-Lease-Plan-Image.png",
//     name: "APT 003",
//     unit: "003",
//     plan: "003 SW Lower",
//     sq: "326 sq. ft.",
//     price: "1250",
//     pdf: "https://soraseattle.com/uploads/primary/003-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/101-Lease-Plan-Image.png",
//     name: "APT 101",
//     unit: "101",
//     plan: "101 Level 1",
//     sq: "277 sq. ft.",
//     price: "1275",
//     pdf: "https://soraseattle.com/uploads/primary/101-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/103-Lease-Plan-Image.png",
//     name: "APT 103",
//     unit: "103",
//     plan: "103 Level 1",
//     sq: "256 sq. ft.",
//     price: "1325",
//     pdf: "https://soraseattle.com/uploads/primary/103-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/104-Lease-Plan-Image.png",
//     name: "APT 104",
//     unit: "104",
//     plan: "104 Level 1",
//     sq: "289 sq. ft.",
//     price: "1350",
//     pdf: "https://soraseattle.com/uploads/primary/104-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/201-Lease-Plan-Image.png",
//     name: "APT 201",
//     unit: "201",
//     plan: "201 Level 2",
//     sq: "281 sq. ft.",
//     price: "1350",
//     pdf: "https://soraseattle.com/uploads/primary/201-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/203-Lease-Plan-Image.png",
//     name: "APT 203",
//     unit: "203",
//     plan: "203",
//     sq: "293 sq. ft.",
//     price: "1375",
//     pdf: "https://soraseattle.com/uploads/primary/203-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/204-Lease-Plan-Image.png",
//     name: "APT 204",
//     unit: "204",
//     plan: "204 Level 2",
//     sq: "277 sq. ft.",
//     price: "1350",
//     pdf: "https://soraseattle.com/uploads/primary/204-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/205-Lease-Plan-Image.png",
//     name: "APT 205",
//     unit: "205",
//     plan: "205 Level 2",
//     sq: "258 sq. ft.",
//     price: "1295",
//     pdf: "https://soraseattle.com/uploads/primary/205-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/207-Lease-Plan-Image.png",
//     name: "APT 207",
//     unit: "207",
//     plan: "207 Level 2",
//     sq: "289 sq. ft.",
//     price: "1350",
//     pdf: "https://soraseattle.com/uploads/primary/207-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/209-Lease-Plan-Image.png",
//     name: "APT 209",
//     unit: "209",
//     plan: "209 Level 2",
//     sq: "250 sq. ft.",
//     price: "1295",
//     pdf: "https://soraseattle.com/uploads/primary/209-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },

//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/301-401-501-Lease-Plan-Image.png",
//     name: "APT 301",
//     unit: "301",
//     plan: "301 Level 3",
//     sq: "280 sq. ft.",
//     price: "1375",
//     pdf:
//       "https://soraseattle.com/uploads/primary/301-401-501-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/302-402-502-Lease-Plan-Image.png",
//     name: "APT 302",
//     unit: "302",
//     plan: "302 Level 3",
//     sq: "241 sq. ft.",
//     price: "1350",
//     pdf:
//       "https://soraseattle.com/uploads/primary/302-402-502-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/Unit-304-404-504.png",
//     name: "APT 304",
//     unit: "304",
//     plan: "304 Level 3",
//     sq: "274 sq. ft.",
//     price: "1350",
//     pdf:
//       "https://soraseattle.com/uploads/primary/304-404-504-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/305-405-505-Lease-Plan-Image.png",
//     name: "APT 305",
//     unit: "305",
//     plan: "305 Level 3",
//     sq: "258 sq. ft.",
//     price: "1325",
//     pdf:
//       "https://soraseattle.com/uploads/primary/305-405-505-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/306-406-506-Lease-Plan-Image.png",
//     name: "APT 306",
//     unit: "306",
//     plan: "306 Level 3",
//     sq: "256 sq. ft.",
//     price: "1350",
//     pdf:
//       "https://soraseattle.com/uploads/primary/306-406-506-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/Unit-308-408-508.png",
//     name: "APT 308",
//     unit: "308",
//     plan: "308 Level 3",
//     sq: "292 sq. ft.",
//     price: "1375",
//     pdf:
//       "https://soraseattle.com/uploads/primary/308-408-508-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/309-409-509-Lease-Plan-Image.png",
//     name: "APT 309",
//     unit: "309",
//     plan: "309 Level 3",
//     sq: "292 sq. ft.",
//     price: "1375",
//     pdf:
//       "https://soraseattle.com/uploads/primary/309-409-509-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/302-402-502-Lease-Plan-Image.png",
//     name: "APT 402",
//     unit: "402",
//     plan: "402 Level 4",
//     sq: "241 sq. ft.",
//     price: "1350",
//     pdf:
//       "https://soraseattle.com/uploads/primary/302-402-502-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/303-403-503-Lease-Plan-Image.png",
//     name: "APT 403",
//     unit: "403",
//     plan: "403 Level 4",
//     sq: "291 sq. ft.",
//     price: "1400",
//     pdf:
//       "https://soraseattle.com/uploads/primary/303-403-503-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },

//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/305-405-505-Lease-Plan-Image.png",
//     name: "APT 405",
//     unit: "405",
//     plan: "405 Level 4",
//     sq: "258 sq. ft.",
//     price: "1350",
//     pdf:
//       "https://soraseattle.com/uploads/primary/305-405-505-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/306-406-506-Lease-Plan-Image.png",
//     name: "APT 406",
//     unit: "406",
//     plan: "406 Level 4",
//     sq: "256 sq. ft.",
//     price: "1375",
//     pdf:
//       "https://soraseattle.com/uploads/primary/306-406-506-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/307-407-507-Lease-Plan-Image.png",
//     name: "APT 407",
//     unit: "407",
//     plan: "407 Level 4",
//     sq: "292 sq. ft.",
//     price: "1375",
//     pdf:
//       "https://soraseattle.com/uploads/primary/307-407-507-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/Unit-308-408-508.png",
//     name: "APT 408",
//     unit: "408",
//     plan: "408 Level 4",
//     sq: "292 sq. ft.",
//     price: "1400",
//     pdf:
//       "https://soraseattle.com/uploads/primary/308-408-508-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/301-401-501-Lease-Plan-Image.png",
//     name: "APT 501",
//     unit: "501",
//     plan: "501 Level 5",
//     sq: "280 sq. ft.",
//     price: "1400",
//     pdf:
//       "https://soraseattle.com/uploads/primary/301-401-501-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },

//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/303-403-503-Lease-Plan-Image.png",
//     name: "APT 503",
//     unit: "503",
//     plan: "503 Level 5",
//     sq: "291 sq. ft.",
//     price: "1425",
//     pdf:
//       "https://soraseattle.com/uploads/primary/303-403-503-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },

//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/Unit-304-404-504.png",
//     name: "APT 504",
//     unit: "504",
//     plan: "504 Level 5",
//     sq: "274 sq. ft.",
//     price: "1375",
//     pdf:
//       "https://soraseattle.com/uploads/primary/304-404-504-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/306-406-506-Lease-Plan-Image.png",
//     name: "APT 506",
//     unit: "506",
//     plan: "506 Level 5",
//     sq: "256 sq. ft.",
//     price: "1400",
//     pdf:
//       "https://soraseattle.com/uploads/primary/306-406-506-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/307-407-507-Lease-Plan-Image.png",
//     name: "APT 507",
//     unit: "507",
//     plan: "507 Level 5",
//     sq: "288 sq. ft.",
//     price: "1400",
//     pdf:
//       "https://soraseattle.com/uploads/primary/307-407-507-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },

//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/Unit-308-408-508.png",
//     name: "APT 508",
//     unit: "508",
//     plan: "508 Level 5",
//     sq: "292 sq. ft.",
//     price: "1425",
//     pdf:
//       "https://soraseattle.com/uploads/primary/308-408-508-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/309-409-509-Lease-Plan-Image.png",
//     name: "APT 509",
//     unit: "509",
//     plan: "509 Level 5",
//     sq: "250 sq. ft.",
//     price: "1375",
//     pdf:
//       "https://soraseattle.com/uploads/primary/309-409-509-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },

//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/601-Lease-Plan-Image.png",
//     name: "APT 601",
//     unit: "601",
//     plan: "601 Level 6",
//     sq: "375 sq. ft.",
//     price: "1700",
//     pdf: "https://soraseattle.com/uploads/primary/601-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/602-Lease-Plan-Image.png",
//     name: "APT 602",
//     unit: "602",
//     plan: "602 Level 6",
//     sq: "336 sq. ft.",
//     price: "1575",
//     pdf: "https://soraseattle.com/uploads/primary/602-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/604-Lease-Plan-Image.png",
//     name: "APT 604",
//     unit: "604",
//     plan: "604 Level 6",
//     sq: "450 sq. ft.",
//     price: "1950",
//     pdf: "https://soraseattle.com/uploads/primary/604-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/605-Lease-Plan-Image.png",
//     name: "APT 605",
//     unit: "605",
//     plan: "605 Level 6",
//     sq: "316 sq. ft.",
//     price: "1500",
//     pdf: "https://soraseattle.com/uploads/primary/605-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },

//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/701-Lease-Plan-Image.png",
//     name: "APT 701",
//     unit: "701",
//     plan: "701 Loft Level 7",
//     sq: "375 plus loft sq. ft.",
//     price: "1900",
//     pdf: "https://soraseattle.com/uploads/primary/701-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/702-Lease-Plan-Image.png",
//     name: "APT 702",
//     unit: "702",
//     plan: "702 Loft Level 7",
//     sq: "336 plus loft sq. ft.",
//     price: "1750",
//     pdf: "https://soraseattle.com/uploads/primary/702-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/704-Lease-Plan-Image.png",
//     name: "APT 704",
//     unit: "704",
//     plan: "704 Loft Level 7",
//     sq: "450 plus loft sq. ft.",
//     price: "2250",
//     pdf: "https://soraseattle.com/uploads/primary/704-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
//   {
//     image:
//       "https://soraseattle.com/uploads/primary/_2000xAUTO_crop_center-center/705-Lease-Plan-Image.png",
//     name: "APT 705",
//     unit: "705",
//     plan: "705 Loft Level 7",
//     sq: "316 plus loft sq. ft.",
//     price: "1700",
//     pdf: "https://soraseattle.com/uploads/primary/705-lease-plan.pdf",
//     Features: [
//       "1 Bath",
//       "High ceilings with ladder loft",
//       "Air conditioning",
//       "Quartz counters",
//       "Washer / dryer",
//       "Plank flooring",
//     ],
//   },
// ];
