import React, { Component } from "react";
class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: "",
    };
  }

  componentDidMount() {}
  downloadContract(name) {
    
    // var elementHTML = document.getElementById("containerForm");
    // // console.log(elementHTML);
    // var s = window.html2pdf(elementHTML, {
    //   margin: 0.9,
    //   filename: "myfile.pdf",
    //   image: { type: "jpeg", quality: 0.98 },
    //   html2canvas: {
    //     dpi: 192,
    //     letterRendering: true,
    //     scale: 2.5,
    //     // width: elementHTML.offsetWidth,
    //     // windowWidth: elementHTML.offsetWidth * 2,
    //   },
    //   jsPDF: {
    //     unit: "pt",
    //     format: "letter",
    //     orientation: "portrait",
    //     // eslint-disable-next-line
    //     format:[elementHTML.offsetWidth,elementHTML.offsetHeight],
    //   },
    // });
    // console.log(s);
    
  }
  render() {
    return (
      <>
        <div
          className="modal fade bd-example-modal-xl"
          id={this.props.name}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{  zIndex: "1500"}}
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div
                className="modal-header shadow"
                style={{  borderBottom: "1px solid #e09b81" }}
              >
                <h1
                  className="modal-title textAvailableUnit textPri axText1"
                  id="exampleModalLabel"
                  style={{ marginTop: "-10px" }}
                >
                  {this.props.title}
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span
                    aria-hidden="true"
                    className="closeModal textPri"
                    onClick={() => this.props.closeContract()}
                  >
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable pt-0  pb-0"
                style={{
                  minHeight: this.props.min,
                  maxHeight: this.props.max,
                  overflow: "scroll",
                }}
              >
                <div
                  id="containerForm"
                  className="p-0 m-0"
                  style={{ maxWidth: this.props.width==="auto"?"auto":"1400px" }}
                >
                  {this.props.children}
                </div>
              </div>
              {this.props.footer !== "false" && (
                <div
                  className="modal-footer pt-3 shadow1"
                  style={{ borderTop: "1px solid #d15428" }}
                >
                  <button className="pri" data-dismiss="modal">
                    {" "}
                    Close
                  </button>
                  
                </div>
              )}
              {this.props.footerDownload === "true" && (
                <div
                  className="modal-footer  shadow1"
                  style={{ borderTop: "1px solid #d15428" }}
                >
                  <button
                    className=" buttonPri mt-0"
                    onClick={() => this.downloadContract("containerForm")}
                    data-target={"#" + this.props.name}
                    data-toggle="modal"
                  >
                    Download
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ModalContainer;
