import React, { Component } from "react";
import "../application.css";
import logo from "../../../../Images/logo.jpeg";
import equalHouse from "../../../../Images/equalHouse.png";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import {
  addressFormatter,
  betweenExtraMonths,
  betweenYears,
  correctDateFormat,
} from "../../../../Defined/Function";

class ApplicationReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      spinner: true,
      landLoadName:
        (this.props.landlords[0].firstName
          ? this.props.landlords[0].firstName
          : "") +
        " " +
        (this.props.landlords[0].lastName
          ? this.props.landlords[0].lastName
          : ""),
      ManagerName: this.props.listingData.rentalEntity.property.managerName
        ? this.props.listingData.rentalEntity.property.managerName
        : "Jay Pathy",
      ManagerPhone: this.props.listingData.rentalEntity.property.managerPhone
        ? this.props.listingData.rentalEntity.property.managerPhone
        : "+94 (021) 2212 765",
      parkingAmount: "-",
      appCheck: "Application Cheque",
      applicationFee: "4000",
      licenseId: true,
      service: "full",
      residenceType: "own",
      residenceUnitType: "Apartment",
      apartment: "",
      petDep: "$150",
    };
    this.onChange = this.onChange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onchangeCheckOpp = this.onchangeCheckOpp.bind(this);
    this.onchangeCheckOption = this.onchangeCheckOption.bind(this);
  }
  async onChange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: checked });
  }
  async onchangeCheckOpp(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: !checked });
  }
  async onchangeCheckOption(name, value) {
    await this.setState({ [name]: value });
  }
  ModifyDate(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      let year = parseInt(dateItems[yearIndex]);
      let month = parseInt(dateItems[monthIndex]);
      let date = parseInt(dateItems[dayIndex]);
      return month.toString().padStart(2, 0) +
        "-" +
        date.toString().padStart(2, 0) +
        "-" +
        year;
    } else {
      return "";
    }
  }
  render() {
    const data = this.props.data;
    return (
      <>
        <div className="applicationAgreement">
          <div className="card applicationAgreementCard pb-5">
            <div className="row applicationAgreementHead ">
              <div className="col applicationAgreementHeadLeftBox">
                <div className="borderBlack">
                  <span className="span7 pl-1">
                    Management: Company Name/Landlord Name
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    name="landLoadName"
                    value={this.state.landLoadName}
                    readOnly
                  ></input>
                </div>

                <div className="borderBlack">
                  <span className="span7 pl-1">
                    Apartment Name/Property Address
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    name="ApartmentName"
                    value={
                      (this.props.listingData.rentalEntity.property.name
                        ? this.props.listingData.rentalEntity.property.name
                        : "") +
                      (this.props.listingData.rentalEntity.property.name !==
                        "" &&
                        this.props.listingData.rentalEntity.property.name !==
                          null &&
                        " / ") +
                      (this.props.listingData&&addressFormatter(this.props.listingData.address))
                    }
                    readOnly
                  ></input>
                </div>
                <div className="row p-0 m-0 ">
                  <div className="col p-0 m-0 borderBlack">
                    <span className="span7 pl-1">Managers Name</span>
                    <input
                      className="ApplicationInputAgg m-0"
                      value={this.state.ManagerName}
                      name="ManagerName"
                      readOnly
                    ></input>
                  </div>
                  <div className="col p-0 m-0 borderBlack">
                    <span className="span7 pl-1">Phone #</span>
                    <input
                      className="ApplicationInputAgg m-0"
                      name="ManagerPhone"
                      value={this.state.ManagerPhone}
                      readOnly
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col text-left applicationAgreementHeadRight">
                <div className=" text-center">
                  <img src={logo} className="agreementLogo " alt="logo" />
                </div>
                <span className="span7">
                  This application must be completed in full to assure prompt
                  processing.
                </span>
                <br></br>
                <span className="span7">
                  <b>Co-tenants must use separate applications</b>
                </span>
                <div className="row p-0 m-0">
                  <div className="col p-0 m-0 managerLandlord">
                    <span className="span7">
                      Managers/landlords – visual proof of driver’s license/or
                      State ID &nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col p-0 m-0 ">
                    <ApplicationInput
                      name="Yes"
                      type="checkedBox"
                      target="licenseId"
                      // onChange={this.onchangeCheck}
                      value={this.state.licenseId}
                    />
                  </div>
                  <div className="col p-0 m-0 ">
                    <ApplicationInput
                      name="No"
                      type="checkedBox"
                      target="licenseId"
                      // onChange={this.onchangeCheckOpp}

                      value={!this.state.licenseId}
                    />
                  </div>
                </div>
                <span className="span7">
                  <b>
                    Any question in this application that requires a yes or no
                    answer that is left blank{" "}
                  </b>
                </span>
                <span className="span7">
                  <b>
                    will be deemed a NO answer for the purpose of screening .or
                    eviction.
                  </b>
                </span>
              </div>
              <div className="col applicationAgreementHeadLeftBoxMobile">
                <div className="borderBlack">
                  <span className="span7 pl-1">
                    Management: Company Name/Landlord Name
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    // onChange={this.onChange}
                    readOnly
                    name="landLoadName"
                    value={this.state.landLoadName}
                  ></input>
                </div>

                <div className="borderBlack">
                  <span className="span7 pl-1">
                    Apartment Name/Property Address
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    // onChange={this.onChange}
                    name="ApartmentName"
                    value={
                      (this.props.listingData.rentalEntity.property.name
                        ? this.props.listingData.rentalEntity.property.name
                        : "") +
                      (this.props.listingData.rentalEntity.property.name !==
                        "" &&
                        this.props.listingData.rentalEntity.property.name !==
                          null &&
                        " / ") +
                      (this.props.listingData&&addressFormatter(this.props.listingData.address))
                    }
                    readOnly
                  ></input>
                </div>
                <div className="row p-0 m-0 ">
                  <div className="col p-0 m-0 borderBlack">
                    <span className="span7 pl-1">Managers Name</span>
                    <input
                      className="ApplicationInputAgg m-0"
                      // onChange={this.onChange}
                      name="ManagerName"
                      value={this.state.ManagerName}
                      readOnly
                    ></input>
                  </div>
                  <div className="col p-0 m-0 borderBlack">
                    <span className="span7 pl-1">Phone #</span>
                    <input
                      className="ApplicationInputAgg m-0"
                      // onChange={this.onChange}
                      name="ManagerPhone"
                      value={this.state.ManagerPhone}
                      readOnly
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="row applicationAgreementHead2">
              <div className="col-3 p-0 m-0 agreementCheckService">
                <span className="span7">
                  <b>SERVICE REQUESTED:</b>
                </span>
              </div>
              <div className="col-7">
                <div className="row">
                  <div className="col p-0 m-0 agreementCheck">
                    <ApplicationInput
                      name="FULL SERVICE "
                      type="checkedBox"
                      target="service"
                      // onChange={() =>
                      //   this.onchangeCheckOption("service", "full")
                      // }
                      value={this.state.service === "full"}
                    />
                  </div>
                  <div className="col p-0 m-0 agreementCheck">
                    <ApplicationInput
                      name="SHORT SERVICE"
                      type="checkedBox"
                      target="service"
                      // onChange={() =>
                      //   this.onchangeCheckOption("service", "short")
                      // }
                      value={this.state.service === "short"}
                    />
                  </div>
                  <div className="col p-0 m-0 agreementCheck">
                    <ApplicationInput
                      name="CREDIT ONLY"
                      type="checkedBox"
                      target="service"
                      // onChange={() =>
                      //   this.onchangeCheckOption("service", "credit")
                      // }
                      value={this.state.service === "credit"}
                    />
                  </div>
                  <div className="col p-0 m-0 agreementCheck">
                    <ApplicationInput
                      name="CO-SIGNER"
                      type="checkedBox"
                      target="service"
                      // onChange={() => this.onchangeCheckOption("service", "co")}
                      value={this.state.service === "co"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row  applicationAgreementHead3">
              <div className="col p-0 m-0 apartmentAgreement1">
                <div className="row p-0 m-0">
                  <div className="col p-0 m-0 pt-2">
                    <span className="span7">Apartment #</span>
                  </div>
                  <div className="col p-0 m-0">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChange}
                      name="Apartment"
                      value={
                        this.props.apartmentData
                          ? this.props.apartmentData.name
                          : ""
                      }
                      readOnly
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0 apartmentAgreement1">
                <div className="row p-0 m-0">
                  <div className="col p-0 m-0 pl-2 pt-2">
                    <span className="span7">Move in Date</span>
                  </div>
                  <div className="col p-0 m-0">
                    <input
                      className="ApplicationInputAgg"
                      name="moveInDate"
                      value={correctDateFormat(this.props.data.moveInDate)}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0 apartmentAgreement1">
                <div className="row p-0 m-0">
                  <div className="col p-0 m-0 pl-2 pt-2">
                    <span className="span7">Rent Amount</span>
                  </div>
                  <div className="col p-0 m-0">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChange}
                      name="rent"
                      readOnly
                      value={this.props.listingData.rentalEntity.rent}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0 apartmentAgreement1">
                <div className="row p-0 m-0">
                  <div className="col p-0 m-0 pl-2 pt-2">
                    <span className="span7">Parking Amount</span>
                  </div>
                  <div className="col p-0 m-0">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChange}
                      readOnly
                      name="parkingAmount"
                      value={this.state.parkingAmount}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0 apartmentAgreement1">
                <div className="row p-0 m-0">
                  <div className="col p-0 m-0 pl-2 pt-2">
                    <span className="span7 ">Lease Term</span>
                  </div>
                  <div className="col p-0 m-0">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChange}
                      name="leaseTerm"
                      value={
                        this.props.listingData
                          ? this.props.listingData.rentalEntity.leasePeriod
                          : data.leaseTerm === "1"
                          ? data.leaseTerm + " month"
                          : data.leaseTerm + " months"
                      }
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className=" agreementTableBody">
              <div className="row p-0 m-0">
                <div className="col-xl borderBlack  p-0 m-0 halfBreak">
                  <span className="span7 pl-1">
                    Application's &nbsp;&nbsp;&nbsp; Last Name
                    &nbsp;&nbsp;&nbsp; First Name &nbsp;&nbsp;&nbsp; Middle Name
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    // onChange={this.onChange}
                    readOnly
                    name="leaseTerm"
                    value={
                      data.lastName +
                      "  \t " +
                      data.firstName +
                      "  \t " +
                      data.middleName
                    }
                  ></input>
                </div>
                <div className="col p-0 m-0 halfBreak">
                  <div className="row p-0 m-0">
                    <div className="col halfBreak p-0 m-0 borderBlack ">
                      <span className="span7 pl-1 ">Birth Date</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onChangeDate}
                        name="dob"
                        type="text"
                        value={this.ModifyDate(data.dob)}
                      ></input>
                    </div>
                    <div className="col p-0 m-0 borderBlack halfBreak">
                      <span className="span7 pl-1">Social Security Number</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onChangeDate}
                        name="securityNo"
                        type="text"
                        value={data.securityNo}
                      ></input>
                    </div>
                    <div className="col p-0 m-0 borderBlack halfBreak">
                      <span className="span7 pl-1">Cell Number</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onchangeState}
                        name="mobile"
                        type="text"
                        value={data.mobile}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">E-MAIL ADDRESS</span>
                  <input
                    className="ApplicationInputAgg m-0"
                    //  onChange={this.props.onchangeState}
                    name="email"
                    type="email"
                    value={data.email}
                  ></input>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-xl-8 p-0 m-0 borderBlack">
                      <span className="span7 pl-1 ">
                        Drivers license No. & State ID
                      </span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onchangeState}
                        name="drivingNo"
                        type="text"
                        value={data.drivingNo}
                      ></input>
                    </div>

                    <div className="col p-0 m-0 borderBlack">
                      <span className="span7 pl-1">Home Number</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        // onChange={this.onChange}
                        readOnly
                        name="phone"
                        type="text"
                        value={data.workingNo}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">
                    Total Number of Occupants ? Name ?
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    //  onChange={this.props.onchangeState}
                    name="occupant"
                    type="text"
                    value={
                      data.occupant +
                      "   " +
                      data.occupant1 +
                      " " +
                      data.occupant2 +
                      " " +
                      data.occupant3 +
                      " " +
                      data.occupant4
                    }
                  ></input>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0 borderBlack">
                      <span className="span7 pl-1 ">
                        Have you used any other names? If yes, Name(s)
                      </span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onchangeState}
                        name="otherName"
                        type="text"
                        value={data.otherName}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 m-0">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-5 p-0 m-0 ">
                      <span className="span7 pl-1">
                        Do you have pets? &nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                    <div className="col p-0 m-0 pt-1">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target="pet"
                        // onChange={this.props.onchangeCheck}
                        value={data.pet === "true" ? true : false}
                      />
                    </div>
                    <div className="col p-0 m-0 pt-1">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target="pet"
                        // onChange={this.props.onchangeCheckOpp}
                        value={data.pet !== "true" ? true : false}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="span7 pl-1">How many? </span>
                      <input
                        className="ApplicationInputAgreement m-0"
                        // onChange={this.onChange}
                        readOnly
                        name="leaseTerm"
                        value={data.pet === "true" ? data.petCount : 0}
                      ></input>
                      <span className="span7 pl-1">
                        {" "}
                        Type and Size (Keeping of pets requires a pet deposit
                        and the Owner’s Consent.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0 ">
                      <div className="row borderBlack p-0 m-0 pt-1 pb-1">
                        <div className="col p-0 m-0 ">
                          <span className="span7 pl-1 ">
                            Nearest Relative or Emergency Contact:
                          </span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onchangeState}
                            name="relativeName"
                            value={data.relativeName}
                          ></input>
                        </div>
                      </div>

                      <div className="row borderBlack p-0 m-0 pt-1 pb-1">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">Phone:</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onchangeState}
                            name="relativeNum"
                            value={data.relativeNum}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row agreementTitleBar p-0 m-0 pt-1 pb-1">
                <div className="col p-0 m-0 text-center">
                  <p className="p-0 m-0">RESIDENCE HISTORY</p>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-4 halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">
                    Present Address &nbsp;&nbsp;&nbsp; City &nbsp;&nbsp;&nbsp;
                    State &nbsp;&nbsp;&nbsp; Zip
                  </span>

                  <textarea
                    className="ApplicationInputAgreement2 m-0 mt-1"
                    name="leaseTerm"
                    value={
                      data.address +
                      "  \t " +
                      data.city +
                      "  \t " +
                      data.state +
                      "  \t " +
                      data.zip
                    }
                  ></textarea>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-xl-6 p-0 m-0 ">
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">From:</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onchangeState}
                            name="from"
                            value={this.ModifyDate(data.from)}
                            type="text"
                          ></input>
                        </div>
                      </div>
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">To:</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onChangeDate}
                            name="to"
                            type="text"
                            value={this.ModifyDate(data.to)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col p-0 m-0  ">
                      <div className="row p-0 m-0 borderBlack  pt-1">
                        <div className="col pt-2 pb-1">
                          <ApplicationInput
                            name="Own "
                            type="checkedBox"
                            target="residenceType"
                            // onChange={() =>
                            //   this.props.onchangeCheckOption("propOwn", "Own")
                            // }
                            value={data.propOwn === "Own"}
                          />
                        </div>
                      </div>
                      <div className="row p-0 m-0 borderBlack pt-1">
                        <div className="col pt-1 pb-1">
                          <ApplicationInput
                            name="Rent "
                            type="checkedBox"
                            target="residenceType"
                            // onChange={() =>
                            //   this.props.onchangeCheckOption("propOwn", "Rent")
                            // }
                            value={data.propOwn === "Rent"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col Quarter p-0 m-0 borderBlack">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1">
                            Monthly Payment &nbsp;&nbsp;&nbsp;
                          </span>
                          <div className="row">
                            <div className="col-1 pt-3 ml-2">
                              <i className="fa fa-usd" aria-hidden="true"></i>
                            </div>
                            <div className="col">
                              <input
                                className="ApplicationInputAgreement2"
                                //  onChange={this.props.onchangeState}
                                name="rent"
                                value={data.rent}
                                type={"number"}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className=" col-xl-8 borderBlack halfBreak  p-0 m-0">
                  <div className="row p-0 m-0 pt-2">
                    <div className="col p-0 m-0  QuarterText">
                      <span className="span7 pl-1">
                        Unit type? &nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Apartment Community"
                        type="checkedBox"
                        target="unitType"
                        // onChange={() =>
                        //   this.props.onchangeCheckOption(
                        //     "unitType",
                        //     "Apartment"
                        //   )
                        // }
                        value={data.unitType === "Apartment"}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Mortgage Co"
                        type="checkedBox"
                        target="unitType"
                        // onChange={() =>
                        //   this.props.onchangeCheckOption(
                        //     "unitType",
                        //     "Mortgage"
                        //   )
                        // }
                        value={data.unitType === "Mortgage"}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Other"
                        type="checkedBox"
                        target="unitType"
                        // onChange={() =>
                        //   this.props.onchangeCheckOption("unitType", "Other")
                        // }
                        value={data.unitType === "Other"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <span className="span7 pl-1">
                        {" "}
                        Name of Present Landlord ?
                      </span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onchangeState}
                        name="presentLandlord"
                        value={data.presentLandlord}
                        type="text"
                      ></input>
                    </div>
                    <div className="col-6">
                      <span className="span7 pl-1">
                        {" "}
                        Landlord Email Address:
                      </span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onchangeState}
                        name="landLordEmail"
                        value={data.landLordEmail}
                        type="email"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col Break p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0 ">
                      <div className="row borderBlack p-0 m-0 pt-1 pb-1">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">Landlord phone</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onchangeState}
                            name="landLordMobile"
                            value={data.landLordMobile}
                            type="text"
                          ></input>
                        </div>
                      </div>

                      <div className="row  borderBlack p-0 m-0 pt-1 pb-1">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">Landlord Fax #:</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onchangeState}
                            name="landLordFax"
                            value={data.landLordFax}
                            type="text"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-4 halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">
                    Previous Residence Address &nbsp;&nbsp;&nbsp; City
                    &nbsp;&nbsp;&nbsp; State &nbsp;&nbsp;&nbsp; Zip
                  </span>

                  <textarea
                    className="ApplicationInputAgreement2 m-0 mt-1"
                    name="leaseTerm"
                    value={
                      data.preAddress +
                      "  \t " +
                      data.preCity +
                      "  \t " +
                      data.preState +
                      "  \t " +
                      data.preZip
                    }
                  ></textarea>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-xl-6 p-0 m-0 ">
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">From:</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onChangeDate}
                            name="preFrom"
                            value={this.ModifyDate(data.preFrom)}
                            type="text"
                          ></input>
                        </div>
                      </div>
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">To:</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onChangeDate}
                            name="preTo"
                            type="text"
                            value={this.ModifyDate(data.preTo)}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col p-0 m-0  ">
                      <div className="row p-0 m-0 borderBlack  pt-1">
                        <div className="col pt-2 pb-1">
                          <ApplicationInput
                            name="Own "
                            type="checkedBox"
                            target="residenceType"
                            // onChange={() =>
                            //   this.props.onchangeCheckOption("prePropOwn", "Own")
                            // }
                            value={data.prePropOwn === "Own"}
                          />
                        </div>
                      </div>
                      <div className="row p-0 m-0 borderBlack pt-1">
                        <div className="col pt-1 pb-1">
                          <ApplicationInput
                            name="Rent "
                            type="checkedBox"
                            target="residenceType"
                            // onChange={() =>
                            //   this.props.onchangeCheckOption("prePropOwn", "Rent")
                            // }
                            value={data.prePropOwn === "Rent"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col Quarter p-0 m-0 borderBlack">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1">
                            Monthly Payment &nbsp;&nbsp;&nbsp;
                          </span>
                          <div className="row">
                            <div className="col-1 pt-3 ml-2">
                              <i className="fa fa-usd" aria-hidden="true"></i>
                            </div>
                            <div className="col">
                              <input
                                className="ApplicationInputAgreement2"
                                //  onChange={this.props.onchangeState}
                                name="preRent"
                                value={data.preRent}
                                type={"number"}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className=" col-xl-8 borderBlack halfBreak  p-0 m-0">
                  <div className="row p-0 m-0 pt-2">
                    <div className="col p-0 m-0 QuarterText">
                      <span className="span7 pl-1">
                        Unit type? &nbsp;&nbsp;&nbsp;
                      </span>
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Apartment Community"
                        type="checkedBox"
                        target="preUnitType"
                        // onChange={() =>
                        //   this.props.onchangeCheckOption(
                        //     "preUnitType",
                        //     "Apartment"
                        //   )
                        // }
                        value={data.preUnitType === "Apartment"}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Mortgage Co"
                        type="checkedBox"
                        target="preUnitType"
                        // onChange={() =>
                        //   this.props.onchangeCheckOption(
                        //     "preUnitType",
                        //     "Mortgage"
                        //   )
                        // }
                        value={data.preUnitType === "Mortgage"}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Other"
                        type="checkedBox"
                        target="preUnitType"
                        // onChange={() =>
                        //   this.props.onchangeCheckOption("preUnitType", "Other")
                        // }
                        value={data.preUnitType === "Other"}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <span className="span7 pl-1">
                        {" "}
                        Name of Previous Landlord ?
                      </span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onchangeState}
                        name="preLandLord"
                        value={data.preLandLord}
                        type="text"
                      ></input>
                    </div>
                    <div className="col-6">
                      <span className="span7 pl-1">
                        {" "}
                        Landlord Email Address:
                      </span>
                      <input
                        className="ApplicationInputAgg m-0"
                        //  onChange={this.props.onchangeState}
                        name="preLandLordEmail"
                        value={data.preLandLordEmail}
                        type="email"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col Break p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0 ">
                      <div className="row borderBlack p-0 m-0 pt-1 pb-1">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">Landlord phone</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onchangeState}
                            name="preLandLordMobile"
                            value={data.preLandLordMobile}
                            type="text"
                          ></input>
                        </div>
                      </div>

                      <div className="row  borderBlack p-0 m-0 pt-1 pb-1">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">Landlord Fax #:</span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            //  onChange={this.props.onchangeState}
                            name="preLandLordFax"
                            value={data.preLandLordFax}
                            type="text"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row agreementTitleBar p-0 m-0 pt-1 pb-1">
                <div className="col p-0 m-0 text-center">
                  <p className="p-0 m-0">EMPLOYMENT DATA</p>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">Applicant Employed By</span>
                  <input
                    className="ApplicationInputAgreement2 m-0"
                    type="text"
                    //  onChange={this.props.onchangeState}
                    value={data.employmentCompany}
                    name="employmentCompany"
                  ></input>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-4 p-0 m-0 borderBlack">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1">
                            Position : &nbsp;&nbsp;&nbsp;
                          </span>

                          <input
                            className="ApplicationInputAgreement2"
                            type="text"
                            //  onChange={this.props.onchangeState}
                            value={data.employmentPosition}
                            name="employmentPosition"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col p-0 m-0 ">
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">
                            Company Phone Number:
                          </span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            type="text"
                            //  onChange={this.props.onchangeState}
                            value={data.employmentPhone}
                            name="employmentPhone"
                          ></input>
                        </div>
                      </div>
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">
                            HR Dept/Supervisor Email:
                          </span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            type="email"
                            //  onChange={this.props.onchangeState}
                            value={data.employmentEmail}
                            name="employmentEmail"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-4 halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">
                    Address &nbsp;&nbsp;&nbsp; City &nbsp;&nbsp;&nbsp; State
                    &nbsp;&nbsp;&nbsp; Zip
                  </span>
                  <input
                    className="ApplicationInputAgreement2 m-0"
                    type="text"
                    // //  onChange={this.props.onchangeState}
                    value={
                      data.employmentAddress +
                      "  \t " +
                      data.employmentCity +
                      "  \t " +
                      data.employmentState +
                      "  \t " +
                      data.employmentZip
                    }
                    // name="leaseTerm"
                  ></input>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-xl-7 p-0 m-0 borderBlack">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0">
                          <div className="row p-0 m-0">
                            <div className="col Quarter p-0 m-0">
                              <span className="span7 pl-1 ">
                                How Long? Years:
                              </span>
                              <input
                                className="ApplicationInputAgg m-0 mt-2"
                                type="number"
                                //  onChange={this.props.onchangeState}
                                value={betweenYears(
                                  data.employmentFrom,
                                  data.employmentUntil
                                )}
                                name="employmentFrom"
                              ></input>
                            </div>
                            <div className="col Quarter p-0 m-0 ml-2">
                              <span className="span7 pl-1 ">Months:</span>
                              <input
                                className="ApplicationInputAgg m-0 mt-2"
                                type="number"
                                //  onChange={this.props.onchangeState}
                                value={betweenExtraMonths(
                                  data.employmentFrom,
                                  data.employmentUntil
                                )}
                                name="employmentUntil"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col p-0 m-0 ">
                      <div className="row p-0 m-0 borderBlack ">
                        <div className="col p-0 m-0">
                          <div className="row p-0 m-0">
                            <div className="col Quarter p-0 m-0">
                              <span className="span7 pl-1 ">Salary :</span>
                              <div className="row">
                                <div className="col-1 pt-3 ml-2">
                                  <i
                                    className="fa fa-usd"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="col">
                                  <input
                                    className="ApplicationInputAgg"
                                    type="number"
                                    //  onChange={this.props.onchangeState}
                                    value={data.employmentSalary}
                                    name="employmentSalary"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 Quarter p-0 m-0 ml-2">
                              <span className="span7 pl-1 ">Per:</span>

                              <input
                                className="ApplicationInputAgg m-0 mt-2"
                                type="text"
                                value="Month"
                                readOnly
                                name="per"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">
                    Previous or 2nd Employment (Please Circle one)
                  </span>
                  <input
                    className="ApplicationInputAgreement2 m-0"
                    type="text"
                    //  onChange={this.props.onchangeState}
                    value={data.preEmploymentCompany}
                    name="preEmploymentCompany"
                  ></input>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-4 p-0 m-0 borderBlack">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1">
                            Position : &nbsp;&nbsp;&nbsp;
                          </span>

                          <input
                            className="ApplicationInputAgreement2"
                            type="text"
                            //  onChange={this.props.onchangeState}
                            value={data.preEmploymentPosition}
                            name="preEmploymentPosition"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col p-0 m-0 ">
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">
                            Company Phone Number:
                          </span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            type="text"
                            //  onChange={this.props.onchangeState}
                            value={data.preEmploymentPhone}
                            name="preEmploymentPhone"
                          ></input>
                        </div>
                      </div>
                      <div className="row p-0 m-0 borderBlack">
                        <div className="col p-0 m-0">
                          <span className="span7 pl-1 ">
                            HR Dept/Supervisor Email:
                          </span>
                        </div>
                        <div className="col p-0 m-0">
                          <input
                            className="ApplicationInputAgg m-0"
                            type="email"
                            //  onChange={this.props.onchangeState}
                            value={data.preEmploymentEmail}
                            name="preEmploymentEmail"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-4 halfBreak borderBlack  p-0 m-0">
                  <span className="span7 pl-1">
                    Address &nbsp;&nbsp;&nbsp; City &nbsp;&nbsp;&nbsp; State
                    &nbsp;&nbsp;&nbsp; Zip
                  </span>
                  <input
                    className="ApplicationInputAgreement2 m-0"
                    type="text"
                    readOnly
                    // //  onChange={this.props.onchangeState}
                    value={
                      data.preEmploymentAddress +
                      "  \t " +
                      data.preEmploymentCity +
                      "  \t " +
                      data.preEmploymentState +
                      "  \t " +
                      data.preEmploymentZip
                    }
                    // name="leaseTerm"
                  ></input>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-xl-7 p-0 m-0 borderBlack">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0">
                          <div className="row p-0 m-0">
                            <div className="col Quarter p-0 m-0">
                              <span className="span7 pl-1 ">
                                How Long? Years:
                              </span>
                              <input
                                className="ApplicationInputAgg m-0 mt-2"
                                type="number"
                                //  onChange={this.props.onchangeState}
                                value={betweenYears(
                                  data.preEmploymentFrom,
                                  data.preEmploymentUntil
                                )}
                                name="preEmploymentFrom"
                              ></input>
                            </div>
                            <div className="col Quarter p-0 m-0 ml-2">
                              <span className="span7 pl-1 ">Months:</span>
                              <input
                                className="ApplicationInputAgg m-0 mt-2"
                                type="number"
                                //  onChange={this.props.onchangeState}
                                value={betweenExtraMonths(
                                  data.preEmploymentFrom,
                                  data.preEmploymentUntil
                                )}
                                name="preEmploymentUntil"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col p-0 m-0 ">
                      <div className="row p-0 m-0 borderBlack ">
                        <div className="col p-0 m-0">
                          <div className="row p-0 m-0">
                            <div className="col Quarter p-0 m-0">
                              <span className="span7 pl-1 ">Salary :</span>
                              <div className="row">
                                <div className="col-1 pt-3 ml-2">
                                  <i
                                    className="fa fa-usd"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div className="col">
                                  <input
                                    className="ApplicationInputAgg"
                                    type="number"
                                    //  onChange={this.props.onchangeState}
                                    value={data.preEmploymentSalary}
                                    name="preEmploymentSalary"
                                  ></input>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 Quarter p-0 m-0 ml-2">
                              <span className="span7 pl-1 ">Per:</span>

                              <input
                                className="ApplicationInputAgg m-0 mt-2"
                                type="text"
                                value="Month"
                                readOnly
                                name="per"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 m-0 pl-2">
                  <span className="span7 pl-1">
                    ADDITIONAL INCOME: Additional income such as child support,
                    alimony or separate maintenance need not be described unless
                    such additional income is to be included for qualification
                    hereunder. Amount of{" "}
                  </span>
                  <div className="row">
                    <div className="col-1 pt-3 ml-2">
                      <i className="fa fa-usd" aria-hidden="true"></i>
                    </div>
                    <div className="col">
                      <input
                        className="ApplicationInputAgg"
                        type="number"
                        //  onChange={this.props.onchangeState}
                        value={data.additionalIncome}
                        name="additionalIncome"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col halfBreak p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-6 p-0 m-0 borderBlack">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0">
                          <div className="row p-0 m-0">
                            <div className="col p-0 m-0 mt-2">
                              <span className="span7 pl-1">
                                Auto/Year/License
                              </span>

                              <input
                                className="ApplicationInputAgreement2 mt-4"
                                type="text"
                                //  onChange={this.props.onchangeState}
                                value={data.drivingNo}
                                name="drivingNo"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col p-0 m-0 ">
                      <div className="row p-0 m-0 borderBlack ">
                        <div className="col p-0 m-0">
                          <div className="row p-0 m-0 ">
                            <div className="col p-0 m-0 ">
                              <span className="span7 pl-1">
                                Do you have any recreational vehicles, vans,
                                boats, or motorcycles? If so specify.
                              </span>
                              <textarea
                                className="ApplicationInputAgg"
                                type="text"
                                //  onChange={this.props.onchangeState}
                                value={data.vehicles}
                                name="vehicles"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 pl-2 m-0">
                  <span className="span7 pl-1">
                    Have you or any person who will be occupying this household
                    ever been found guilty of a crime?
                  </span>
                  <div className="row p-0 m-0">
                    <div className="col-4"></div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target="crimeCheck"
                        // onChange={this.props.onchangeCheck}
                        value={data.crimeCheck}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target="crimeCheck"
                        // onChange={this.props.onchangeCheckOpp}
                        value={!data.crimeCheck}
                      />
                    </div>
                  </div>
                </div>
                <div className="col halfBreak borderBlack  p-0 pl-2 m-0">
                  <span className="span7 pl-1">Ever filed bankruptcy?</span>
                  <div className="row p-0 m-0">
                    <div className="col-4"></div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target="bankruptcy"
                        // onChange={this.props.onchangeCheck}
                        value={data.bankruptcy}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target="bankruptcy"
                        // onChange={this.props.onchangeCheckOpp}
                        value={!data.bankruptcy}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col halfBreak borderBlack  p-0 pl-2 m-0">
                  <span className="span7 pl-1">
                    Have you or any person who will be occupying this household
                    ever been evicted?
                  </span>
                  <div className="row p-0 m-0">
                    <div className="col-4"></div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target="evicted"
                        // onChange={this.props.onchangeCheck}
                        value={data.evicted}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target="evicted"
                        // onChange={this.props.onchangeCheckOpp}
                        value={!data.evicted}
                      />
                    </div>
                  </div>
                </div>
                <div className="col halfBreak borderBlack  p-0 pl-2 m-0">
                  <span className="span7 pl-1">
                    Anyone in the Household Smoke?
                  </span>
                  <div className="row p-0 m-0">
                    <div className="col-4"></div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target="smoke"
                        // onChange={this.props.onchangeCheck}
                        value={data.smoke}
                      />
                    </div>
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target="smoke"
                        // onChange={this.props.onchangeCheckOpp}
                        value={!data.smoke}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <div className="card applicationAgreementCard pb-5">
            <div className="row applicationAgreementHead2 ">
              <div className="col p-0 m-0">
                <span className="span7 pl-1">Pg. 2</span>
              </div>
            </div>
            <div className="row applicationAgreementHeadBar "></div>
            <div className="row  applicationAgreementHead4">
              <div className="col p-0 m-0">
                <span className="span7">
                  I understand I acquire no rights in an apartment until I sign
                  an agreement in the form submitted to me and pay a HOLDING FEE
                  of $
                </span>
                <input
                  className="ApplicationInputAgreement m-0"
                  type="text"
                  readOnly
                  // onChange={this.props.onchange}
                  // value={data.preLandLordFax}
                  name="holdingFee"
                ></input>{" "}
                <span className="span7">
                  on the apartment I have selected, which fee is to be held in
                  accordance with the rental agreement.{" "}
                </span>
                <br></br>
                <span className="span7">
                  In consideration of the Landlord’s holding the apartment for
                  me, I hereby waive all rights to the return of this holding
                  fee and said holding fee shall be retained as liquidated
                  damages in the event I do not choose to enter into the
                  agreement applied for herein. In the event said application
                  for tenancy is not accepted, Fee shall be returned to
                  applicant.
                </span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4">
              <div className="col-2 p-0 m-0 text-right pt-3 agreementLogoDiv">
                <span className="span7">Non-Refundable Process</span>
              </div>
              <div className="col p-0 m-0 agreementLogoDiv">
                <div className="row">
                  <div className="col-4 pt-3 ml-2">
                    <span className="span7">Fee</span>{" "}
                    <i className="fa fa-usd" aria-hidden="true"></i>
                  </div>
                  <div className="col">
                    <input
                      className="ApplicationInputAgreement2"
                      // onChange={this.onChange}

                      readOnly
                      name="appFee"
                      value={data.propertyRent}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col p-0 m-0 agreementLogoDiv">
                <div className="row">
                  <div className="col-4 pt-3 ml-2">
                    <span className="span7">Check #</span>
                  </div>
                  <div className="col">
                    <input
                      className="ApplicationInputAgreement2"
                      // onChange={this.onChange}
                      readOnly
                      name="appCheck"
                      value=""
                      // value="applicationCheque"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-3 p-0 m-0 text-right pt-3 agreementLogoDiv">
                <img src={equalHouse} className="agreementLogo " alt="logo" />
              </div>
            </div>
            <div className="row  applicationAgreementHead4">
              <div className="col p-0 m-0">
                <span className="span7">
                  In compliance with the Fair Credit reporting act, we are
                  informing you that information as to your character, general
                  reputation and mode of living will be verified. I, as the
                  prospective tenant agree that the facts set forth in this
                  application are true and complete, and that a complete
                  investigation of all on this application will not constitute
                  invasion of privacy. I authorize LPS Inc. to obtain credit
                  reports, bank information, employment information, and/or
                  character reports as necessary. I authorize my employers and
                  references to release such information as necessary. LPS Inc.
                  has my permission to release information found in screening. I
                  understand that any misrepresentations will be sufficient
                  cause for dismissal or voiding of this application. False,
                  fraudulent or misleading information may be grounds for denial
                  of tenancy, or subsequent eviction. You have the right to
                  dispute the accuracy of the information reported, and upon
                  written request, the right to obtain a copy of any and all
                  reports. Direct inquiries to- LPS Inc. 16625 Redmond Way,
                  Ste#M-446, Redmond, WA 98052. 1-800-577-8282
                </span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4">
              <div className="col p-0 m-0">
                <div className="row">
                  <div className="col-3 pt-3 ml-2">
                    <span className="span7">Signed</span>
                  </div>
                  <div className="col text-center agreementLogoDiv">
                    <input
                      className="ApplicationInputAgg"
                      name="tenant"
                      readOnly
                      value={
                        data.lastName +
                        "  \t " +
                        data.firstName +
                        "  \t " +
                        data.middleName
                      }
                    ></input>
                    <span className="span7">Tenant</span>
                  </div>
                  <div className="col text-center agreementLogoDiv">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChange}
                      readOnly
                      name="landLordPosition"
                      // value="landLordPosition"
                    ></input>
                    <span className="span7">Position</span>
                  </div>
                  <div className="col text-center agreementLogoDiv">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChangeDate}
                      name="leaseTerm"
                      type="text"
                      value={this.ModifyDate(
                        new Date().toISOString().split("T")[0]
                      )}
                    ></input>
                    <span className="span7">Dated</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  applicationAgreementHead4">
              <div className="col p-0 m-0">
                <div className="row">
                  <div className="col-3 pt-3 ml-2 ">
                    <span className="span7">Signed</span>
                  </div>
                  <div className="col text-center agreementLogoDiv">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChange}
                      readOnly
                      name="landLoadName"
                      // value={this.state.ManagerName}
                      value={""}
                    ></input>
                    <span className="span7">Landlord</span>
                  </div>
                  <div className="col text-center agreementLogoDiv">
                    <input
                      className="ApplicationInputAgg"
                      readOnly
                      // onChange={this.onChange}
                      name="landLordPosition"
                    ></input>
                    <span className="span7">Position</span>
                  </div>
                  <div className="col text-center agreementLogoDiv">
                    <input
                      className="ApplicationInputAgg"
                      // onChange={this.onChange}
                      name="landLordSignDate"
                      // value={new Date().toISOString().split("T")[0]}
                      type="text"
                    ></input>
                    <span className="span7">Dated</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ApplicationReview;
