//Landlord Stripe Status Reducer
const accountPaymentReducer = (state = null, action) => {
     switch (action.type) {
       case "GET_PAYMENT_ACCOUNT_STATUS":
         return state;
       case "SET_PAYMENT_ACCOUNT_STATUS":
          return action.paymentStatus;
       case "REMOVE_PAYMENT_ACCOUNT_STATUS":
         return null;
       default:
         return state;
     }
};
export default accountPaymentReducer;
   