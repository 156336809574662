import React, { Component } from "react";
import "../application.css";
import { withRouter } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import { CountryJson } from "../../Property/CountryJson";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import LicenseModal from "../LicenseModal";
import {
  checkPaymentStatus,
  //setApplication,
  stripeKey,
  getApplicationsCount,
  addPaymentMethodGuest,
  getPropertyLists,
  urlProd,
  getPropertyRent,
  isAvailableUser,
  setWashingtonApplicationV2,
  getLandlordByRentalId,
} from "../../../../Services/ApiServices";

import { StripeProvider, Elements } from "react-stripe-elements";
import CardPayment from "../../../Pages/Payment/CardPayment";
import ApplicationReview from "../Review/ApplicationReview";
import ApplicationReview1 from "../Review/ApplicationReview1";
import ModalContainer from "../../Documents/ModalContainer";
import SignaturePad from "../../../UIServices/SignaturePad/SignaturePad";
import { soraDetails } from "../../../../Services/JsonData";
import Loader3 from "../../../Loader/loader3";
import {
  addressFormatter,
  betweenExtraMonths,
  betweenMonths,
  betweenYears,
  correctDateFormat,
} from "../../../../Defined/Function";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import SearchInput from "../SearchInput";
import CookieService from "../../../../Services/CookieService";
import { connect } from "react-redux";
import { appendAlert } from "../../../../actions";
import Dropzone from "react-dropzone";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";

class ListingApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      nowTab: 0,
      tabCheck: [false, false, false, false, false, false],
      next: false,
      nextEnable: false,
      licenseModal: false,
      footer: true,
      firstName: "",
      middleName: "",
      lastName: "",
      otherName: "",
      relativeName: "",
      email: this.props.UserData.username,
      dob: "",
      mobile: "",
      drivingNo: "",
      securityNo: "",
      workingNo: "",
      pet: "",
      petCount: 1,
      relativeNum: "",
      occupant: "1",
      occupantArray: [],
      occupantsArray: [],
      moveInDate: "",
      leaseTerm: 12,
      crimeCheck: false,
      bankruptcy: false,
      evicted: false,
      smoke: false,
      additionalIncome: "",
      vehicles: "",

      address: "",
      state: "",
      city: "",
      country: "",
      zip: "",
      from: "",
      to: this.today(),
      propOwn: "",
      presentLandlord: "",
      landLordEmail: "",
      rent: "",
      landLordMobile: "",
      landLordFax: "",
      unitType: "",

      preAddress: "",
      preState: "",
      preCity: "",
      preCountry: "",
      preZip: "",
      preFrom: "",
      preTo: "",
      prePropOwn: "",
      preLandLord: "",
      preLandLordEmail: "",
      preRent: "",
      preLandLordMobile: "",
      preLandLordFax: "",
      preUnitType: "",
      occupant0: "",
      occupant1: "",
      occupant2: "",
      occupant3: "",
      occupant4: "",
      occupant5: "",
      occupant6: "",
      occupant7: "",
      occupant8: "",
      occupant9: "",
      occupant10: "",
      occupant11: "",
      occupant12: "",
      occupant13: "",
      occupant14: "",
      occupant15: "",
      occupant16: "",

      employmentCompany: "",
      employmentPhone: "",
      employmentEmail: "",
      employmentZip: "",
      employmentCountry: "",
      employmentAddress: "",
      employmentCity: "",
      employmentState: "",
      employmentSalary: "",
      employmentPosition: "",
      employmentFrom: "",
      employmentUntil: this.today(),

      preEmploymentCompany: "",
      preEmploymentPhone: "",
      preEmploymentEmail: "",
      preEmploymentZip: "",
      preEmploymentAddress: "",
      preEmploymentCountry: "",
      preEmploymentCity: "",
      preEmploymentState: "",
      preEmploymentSalary: "",
      preEmploymentPosition: "",
      preEmploymentFrom: "",
      preEmploymentUntil: "",
      rentalUnit: this.props.match.params.id ? this.props.match.params.id : "",
      stripe_account_status: "",
      paymentToken: "",
      listingData: "",
      landlords: "",
      downloadModal: false,
      signatureObject: null,
      signatureObject2: null,
      signatureObject3: null,
      signatureTab: "pad",
      next_action: {
        type: "redirect_to_url",
        redirect_to_url: {
          url: "https://hooks.stripe.com/...",
          return_url: "https://http://localhost:3000/sora/application/",
        },
      },
      isButtonDisableFinish: true,
      pdfDocObject: "",
      applicationFee: 0,
      finishStatus: false,
      finishedModal: true,
      preRentalInfo: false,
      preEmploymentInfo: false,
      isJoinTenant: false,
      joinTenantCount: 0,
      joinTenant1: "",
      joinTenant2: "",

      countryList: [],
      stateList: [],
      cityList: [],

      preCountryList: [],
      preStateList: [],
      preCityList: [],

      employmentCountryList: [],
      employmentStateList: [],
      employmentCityList: [],

      preEmploymentCountryList: [],
      preEmploymentStateList: [],
      preEmploymentCityList: [],

      countryJson: [],
      stateJson: [],
      cityJson: [],

      preCountryJson: [],
      preStateJson: [],
      preCityJson: [],

      employmentCountryJson: [],
      employmentStateJson: [],
      employmentCityJson: [],

      preEmploymentCountryJson: [],
      preEmploymentStateJson: [],
      preEmploymentCityJson: [],
      changeBlob: true,
      mailCheck: false,
      primaryApply: true,
      nowJoinTenant: 1,
    };
    this.onchange = this.onchange.bind(this);
    this.getOccupant = this.getOccupant.bind(this);
    this.next = this.next.bind(this);
    this.changeLicense = this.changeLicense.bind(this);
    this.changeLicenseData = this.changeLicenseData.bind(this);
    this.keepWait = this.keepWait.bind(this);
    this.removeKeepWait = this.removeKeepWait.bind(this);
    this.showButton = this.showButton.bind(this);
    this.setPaymentToken = this.setPaymentToken.bind(this);
    this.changeTokenNull = this.changeTokenNull.bind(this);
    this.onchangeState = this.onchangeState.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onchangeCheckOpp = this.onchangeCheckOpp.bind(this);
    this.onchangeCheckOption = this.onchangeCheckOption.bind(this);
    this.closeDownloadModal = this.closeDownloadModal.bind(this);
    this.changeSignatureObject = this.changeSignatureObject.bind(this);
    this.changeSignatureObject2 = this.changeSignatureObject2.bind(this);
    this.changeSignatureObject3 = this.changeSignatureObject3.bind(this);
    this.changeState = this.changeState.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }
  async clickStep(step) {
    if (this.state.tabCheck[step]) {
      this.setState({ nowTab: step });
      if (step === 5) {
        this.getPdfBlob();
        await this.setState({ occupantsArray: [] });
        for (let i = 1; i < this.state.occupant; i++) {
          this.state.occupantsArray.push(this.state["occupant" + i]);
        }
      }
    } else {
      if (this.state.nowTab + 1 === step) {
        if (step === 5) {
          if (this.state.paymentToken !== "") {
            this.next();
            this.getPdfBlob();
            await this.setState({ occupantsArray: [] });
            for (let j = 1; j < this.state.occupant; j++) {
              this.state.occupantsArray.push(this.state["occupant" + j]);
            }
          }
        } else {
          this.next();
        }
      } else {
        if (step === 5) {
          if (this.state.paymentToken !== "") {
            this.setState({ nowTab: step });
            this.getPdfBlob();
            await this.setState({ occupantsArray: [] });
            for (let k = 1; k < this.state.occupant; k++) {
              this.state.occupantsArray.push(this.state["occupant" + k]);
            }
          }
        } else {
          this.props.appendAlert(
            "Please fill correct order, Your next step is " +
              (this.state.nowTab + 1),
            "warning"
          );
        }
      }
    }
  }
  checkTab() {
    if (this.state.tabCheck[this.state.nowTab - 1] || this.state.nowTab === 0) {
      return true;
    } else if (this.state.stripe_account_status.data === "verified") {
      if (
        this.state.tabCheck[0] &&
        this.state.tabCheck[1] &&
        this.state.tabCheck[2]
      ) {
        return true;
      }
    } else {
      for (let i = this.state.nowTab - 1; i > -1; i--) {
        if (this.state.tabCheck[i]) {
          this.props.appendAlert("Please fill correct order", "danger");
          this.setState({ nowTab: i + 1 });
          break;
        }

        if (i === 0) {
          this.props.appendAlert("Please fill correct order", "danger");
          this.setState({ nowTab: 0 });
          break;
        }
      }
    }
  }
  //get today date
  today() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }

  async changeSignatureObject(object) {
    await this.setState({ signatureObject: object });
    this.next();
  }
  changeSignatureObject2(object) {
    this.setState({ signatureObject2: object });
    window.$("#signatureModal").modal("hide");
  }
  changeSignatureObject3(object) {
    this.setState({ signatureObject3: object });
  }
  openDownloadModal() {
    this.setState({ downloadModal: true });
  }
  closeDownloadModal() {
    this.setState({ downloadModal: false });
  }
  beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?");
  };
  async onChangeDate(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    // window.onbeforeunload = "";
    const json = await CountryJson();
    this.setState({ countryJson: json });
    this.setState({ stateJson: json[0].states });
    this.setState({ preCountryJson: json });
    this.setState({ preStateJson: json[0].states });
    this.setState({ employmentCountryJson: json });
    this.setState({ employmentStateJson: json[0].states });
    this.setState({ preEmploymentCountryJson: json });
    this.setState({ preEmploymentStateJson: json[0].states });
    this.setState({
      accountStatus: this.props.status ? this.props.status : "",
    });
    if (this.props.role === "Guest" && this.props.match.params.id) {
      let apartmentData = soraDetails;
      let unit = this.props.match.params.id;
      apartmentData = apartmentData.filter(function (data) {
        return data.unit === unit;
      });
      this.setState({ apartmentData: apartmentData[0] });
      getPropertyRent("sora").then((response) => {
        if (response.status === 200) {
          this.setState({ applicationFee: response.data.fee / 100 });
        } else {
          if (response.data === undefined) {
            if (response.response.data !== undefined) {
              this.props.appendAlert(response.response.data.message, "danger");
            } else {
              this.props.appendAlert("Try again later", "warning");
            }
          }
          this.setState({ applicationFee: 0 });
        }
      });
    } else {
      this.setState({
        applicationFee: this.props.history.location.state
          ? this.props.history.location.state.applicationFee
          : 0,
      });
    }

    try {
      await getApplicationsCount(this.props.match.params.id).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.status) {
              response.data.data !== "0" &&
                this.props.appendAlert(response.data.data, "warning");

              if (this.props.role === "Landlord") {
                this.props.history.push({ pathname: "/rental-units" });
                this.props.appendAlert(
                  "Sorry!!! Landlord can't Apply Units",
                  "warning"
                );
              }
            } else {
              this.props.history.push({ pathname: "/rental-units" });
              this.props.appendAlert(response.data.data, "danger");
            }
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                this.props.appendAlert(
                  response.response.data.message,
                  "danger"
                );
              } else {
                this.props.appendAlert("Try again later", "warning");
              }
            }
          }
        }
      );
    } catch (error) {}

    this.setState({ user: this.props.userDetail });

    if (this.props.userDetail) {
      this.setState({ firstName: this.props.userDetail.firstName });
      this.setState({ lastName: this.props.userDetail.lastName });
      this.setState({ email: this.props.userDetail.username });
      this.setState({ address: this.props.userDetail.address.address });
      this.setState({ state: this.props.userDetail.address.state });
      this.setState({ city: this.props.userDetail.address.city });
      this.setState({ zip: this.props.userDetail.address.zip });
      this.setState({ mobile: this.props.userDetail.mobileNumber });
      try {
        await checkPaymentStatus().then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.setState({
                stripe_account_status: response.data,
              });
              if (response.data.data !== "verified") {
                this.props.history.push({
                  pathname: "/payments",
                });
                this.props.appendAlert("Please add payment method.", "warning");
              }
            }
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                this.props.appendAlert(
                  response.response.data.message,
                  "danger"
                );
              } else {
                this.props.appendAlert("Try again later", "warning");
              }
            }
          }
        });
      } catch (err) {}
      try {
        await getPropertyLists(this.props.match.params.id).then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.setState({
                listingData: response.data,
              });
            }
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                this.props.appendAlert(
                  response.response.data.message,
                  "danger"
                );
              } else {
                this.props.appendAlert("Try again later", "warning");
              }
            }
            this.props.history.push({ pathname: "/rental-units" });
          }
        });
      } catch (err) {}
      try {
        await getLandlordByRentalId(this.props.match.params.id).then(
          (response) => {
            if (response.status === 200) {
              if (response.status) {
                this.setState({
                  landlords: response.data,
                });
              }
            } else {
              if (response.data === undefined) {
                if (response.response.data !== undefined) {
                  this.props.appendAlert(
                    response.response.data.message,
                    "danger"
                  );
                } else {
                  this.props.appendAlert("Try again later", "warning");
                }
              }
              this.props.history.push({ pathname: "/rental-units" });
            }
          }
        );
      } catch (err) {}
    }
    let countryList = [];
    let preCountryList = [];
    let employmentCountryList = [];
    let preEmploymentCountryList = [];
    this.state.countryJson.map((data) => countryList.push(data.name));
    this.state.preCountryJson.map((data) => preCountryList.push(data.name));
    this.state.employmentCountryJson.map((data) =>
      employmentCountryList.push(data.name)
    );
    this.state.preEmploymentCountryJson.map((data) =>
      preEmploymentCountryList.push(data.name)
    );

    this.setState({ countryList: countryList });
    this.setState({ preCountryList: preCountryList });
    this.setState({ employmentCountryList: employmentCountryList });
    this.setState({ preEmploymentCountryList: preEmploymentCountryList });

    let stateList = [];
    let preStateList = [];
    let employmentStateList = [];
    let preEmploymentStateList = [];
    this.state.stateJson.map((data) => stateList.push(data.name));
    this.state.preStateJson.map((data) => preStateList.push(data.name));
    this.state.employmentStateJson.map((data) =>
      employmentStateList.push(data.name)
    );
    this.state.preEmploymentStateJson.map((data) =>
      preEmploymentStateList.push(data.name)
    );

    this.setState({ stateList: stateList });
    this.setState({ preStateList: preStateList });
    this.setState({ employmentStateList: employmentStateList });
    this.setState({ preEmploymentStateList: preEmploymentStateList });
  }
  setPaymentToken(token) {
    this.setState({ paymentToken: token });
  }
  changeTokenNull() {
    this.setState({ paymentToken: "" });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    if (name === "preRentalInfo" && checked) {
      this.setState({
        preAddress: "",
        preState: "",
        preCity: "",
        preCountry: "",
        preZip: "",
        preFrom: "",
        preTo: "",
        prePropOwn: "",
        preLandLord: "",
        preLandLordEmail: "",
        preRent: "",
        preLandLordMobile: "",
        preLandLordFax: "",
        preUnitType: "",
      });
    } else if (name === "preEmploymentInfo" && checked) {
      this.setState({
        preEmploymentCompany: "",
        preEmploymentPhone: "",
        preEmploymentEmail: "",
        preEmploymentZip: "",
        preEmploymentAddress: "",
        preEmploymentCountry: "",
        preEmploymentCity: "",
        preEmploymentState: "",
        preEmploymentSalary: "",
        preEmploymentPosition: "",
        preEmploymentFrom: "",
        preEmploymentUntil: "",
      });
    } else if (name === "isJoinTenant") {
      if (checked) {
        this.setState({ joinTenantCount: 1 });
      } else {
        this.setState({ joinTenantCount: 0 });
      }
    }

    this.setState({ next: false });
    await this.setState({ [name]: checked });
    this.nextCheck(this.state.nowTab);
  }
  async onchangeCheckOpp(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: !checked });
    this.nextCheck(this.state.nowTab);
  }

  async onchangeCheckOption(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  changeJoinTenantCount() {
    if (this.state.joinTenantCount === 1) {
      this.setState({ joinTenantCount: 2 });
    } else {
      this.setState({ joinTenantCount: 1 });
    }
  }
  changeLicenseData(data) {
    this.setState({
      dob: this.setDOB(data.dob),
      drivingNo: data.licenseNo,
      city: data.city,
      state: data.state,
      zip: data.zip,
      address: data.addressLine1,
    });
    if (
      this.state.firstName === "" ||
      this.state.firstName === "please wait..."
    ) {
      this.setState({ firstName: data.firstName });
    }
    if (
      this.state.lastName === "" ||
      this.state.lastName === "please wait..."
    ) {
      this.setState({ lastName: data.lastName });
    }
  }
  keepWait() {
    this.setState({
      dob: "please wait...",
      drivingNo: "please wait...",
      city: "please wait...",
      state: "please wait...",
      zip: "please wait...",
      address: "please wait...",
    });
    if (this.state.firstName === "") {
      this.setState({ firstName: "please wait..." });
    }
    if (this.state.lastName === "") {
      this.setState({ lastName: "please wait..." });
    }
  }
  removeKeepWait() {
    if (this.state.firstName === "please wait...") {
      this.setState({ firstName: "" });
    }
    if (this.state.lastName === "please wait...") {
      this.setState({ lastName: "" });
    }
    if (this.state.dob === "please wait...") {
      this.setState({ dob: "" });
    }
    if (this.state.drivingNo === "please wait...") {
      this.setState({ drivingNo: "" });
    }
    if (this.state.city === "please wait...") {
      this.setState({ city: "" });
    }
    if (this.state.state === "please wait...") {
      this.setState({ state: "" });
    }
    if (this.state.zip === "please wait...") {
      this.setState({ zip: "" });
    }
    if (this.state.address === "please wait...") {
      this.setState({ address: "" });
    }
  }
  setDOB(_date) {
    if (_date) {
      let formatLowerCase = "mm/dd/yyyy";
      let formatItems = formatLowerCase.split("/");
      let dateItems = _date.split("/");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      return (
        dateItems[yearIndex] +
        "-" +
        dateItems[monthIndex] +
        "-" +
        dateItems[dayIndex]
      );
    } else {
      return "";
    }
  }
  changeLicense() {
    this.setState({ licenseModal: !this.state.licenseModal });
  }
  showButton() {
    this.setState({ footer: true });
  }
  ModifyString(value) {
    if (value) {
      return value.toString();
    }
    return "";
  }
  async getPdfBlob() {
    this.setState({ changeBlob: false });
    const formUrl = urlProd + "api/application/washington/download";
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    try {
      const managementCompanyNameLandlord = form.getTextField(
        "Management Company NameLandlord Name"
      );

      managementCompanyNameLandlord.setText(
        this.state.landlords[0].firstName +
          " " +
          this.state.landlords[0].lastName
      );
      managementCompanyNameLandlord.enableReadOnly();
      const apartmentNamePropertyAddress = form.getTextField(
        "Apartment NameProperty Address"
      );
      apartmentNamePropertyAddress.setText(
        this.ModifyString(
          this.state.listingData.rentalEntity.property.name
            ? this.state.listingData.rentalEntity.property.name
            : ""
        ) +
          " / " +
          this.ModifyString(addressFormatter(this.state.listingData.address))
      );
      apartmentNamePropertyAddress.enableReadOnly();
      const managersNamePhone = form.getTextField("Managers Name");
      managersNamePhone.setText(
        this.ModifyString(
          this.state.listingData.rentalEntity.property.managerName
            ? this.state.listingData.rentalEntity.property.managerName
            : "Jay Pathy"
        )
      );
      managersNamePhone.enableReadOnly();
      const managersPhone = form.getTextField("Managers Phone");
      managersPhone.setText(
        this.ModifyString(
          this.state.listingData.rentalEntity.property.managerPhone
            ? this.state.listingData.rentalEntity.property.managerPhone
            : "+94 (021) 2212 765"
        )
      );
      managersPhone.enableReadOnly();
      const apartment = form.getTextField("Apartment");
      apartment.setText(
        this.ModifyString(
          this.state.listingData.rentalEntity.unitName
            ? this.state.listingData.rentalEntity.unitName
            : ""
        )
      );
      apartment.enableReadOnly();
      const formMoveInDate = form.getTextField("Move in Date");
      formMoveInDate.setText(correctDateFormat(this.state.moveInDate));
      formMoveInDate.enableReadOnly();
      const rentAmount = form.getTextField("Rent Amount");
      rentAmount.setText(
        this.ModifyString(
          this.state.apartmentData
            ? "$ " + this.state.apartmentData.price
            : "$ " + this.state.listingData.rentalEntity.rent
        )
      );
      rentAmount.enableReadOnly();

      const parkingAmount = form.getTextField("Parking Amount");
      parkingAmount.setText("-");
      parkingAmount.enableReadOnly();

      const leaseTerm = form.getTextField("Lease Term");
      leaseTerm.setText(
        this.ModifyString(
          this.state.leaseTerm === 1
            ? this.state.leaseTerm + " month"
            : this.state.leaseTerm + " months"
        )
      );
      leaseTerm.enableReadOnly();
      // visual proof of driver’s license/or State ID
      form.getCheckBox("Yes").check();
      let yes = form.getCheckBox("Yes");
      yes.enableReadOnly();

      let no = form.getCheckBox("No");
      no.enableReadOnly();

      // FULL SERVICE
      form.getCheckBox("FULL SERVICE").check();
      const full_service = form.getCheckBox("FULL SERVICE");
      full_service.enableReadOnly();

      const short_service = form.getCheckBox("SHORT SERVICE");
      short_service.enableReadOnly();
      const credit_only = form.getCheckBox("CREDIT ONLY");
      credit_only.enableReadOnly();
      const cosigner = form.getCheckBox("COSIGNER");
      cosigner.enableReadOnly();

      //FULL SERVICE is provided
      // SHORT SERVICE
      // form.getCheckBox('SHORT SERVICE').check();//FULL SERVICE is provided
      // CREDIT ONLY
      // form.getCheckBox('CREDIT ONLY').check();//FULL SERVICE is provided
      // COSIGNER
      // form.getCheckBox('COSIGNER').check();//FULL SERVICE is provided

      // Residence History
      // ApplicantsName
      const applicantsName = form.getTextField("ApplicantsName");
      applicantsName.setText(
        (this.state.lastName ? this.state.lastName + " " : " ") +
          (this.state.firstName ? this.state.firstName + " " : "") +
          (this.state.middleName ? this.state.middleName + " " : "")
      );
      applicantsName.enableReadOnly();
      // Birthdate
      const birthDate = form.getTextField("Birthdate");
      birthDate.setText(this.ModifyString(this.ModifyDate(this.state.dob)));
      birthDate.enableReadOnly();
      // Social Security Number
      const socialSecurityNumber = form.getTextField("Social Security Number");
      socialSecurityNumber.setText(
        this.ModifyString(String(this.state.securityNo))
      );
      socialSecurityNumber.enableReadOnly();
      // Cell Number
      const cellNumber = form.getTextField("Cell Number");
      if (this.state.mobile) {
        cellNumber.setText(this.ModifyString(this.state.mobile));
      } else {
        cellNumber.setText(this.ModifyString(""));
      }
      cellNumber.enableReadOnly();
      // EMAIL ADDRESS
      const email = form.getTextField("EMAIL ADDRESS");
      if (this.state.email) {
        email.setText(this.ModifyString(this.state.email));
      } else if (this.state.user) {
        email.setText(this.ModifyString(this.state.user.username));
      } else {
        email.setText(this.ModifyString(""));
      }
      email.enableReadOnly();
      // Drivers license No  State ID
      const driversLicense = form.getTextField("Drivers license No  State ID");
      driversLicense.setText(this.ModifyString(this.state.drivingNo));
      driversLicense.enableReadOnly();
      // Phone Number
      const phoneNumber = form.getTextField("Phone Number");
      phoneNumber.setText(this.ModifyString(this.state.workingNo));
      phoneNumber.enableReadOnly();
      // Total Number of Occupants Names
      const totalOccupants = form.getTextField(
        "Total Number of Occupants Names"
      );
      totalOccupants.setText(
        this.ModifyString(
          this.state.occupant +
            "   " +
            this.state.occupant1 +
            " " +
            this.state.occupant2 +
            " " +
            this.state.occupant3 +
            " " +
            this.state.occupant4
        )
      );
      totalOccupants.enableReadOnly();

      // Have you used any other names If yes Names
      const otherNames = form.getTextField(
        "Have you used any other names If yes Names"
      );
      otherNames.setText(this.ModifyString(this.state.otherName));
      otherNames.enableReadOnly();
      // Phone
      const phone = form.getTextField("Phone");
      phone.setText(this.ModifyString(this.state.relativeNum));
      phone.enableReadOnly();
      // Nearest Relative or Emergency Contact
      const nearestRelativeContact = form.getTextField(
        "Nearest Relative or Emergency Contact"
      );
      nearestRelativeContact.setText(
        this.ModifyString(this.state.relativeName)
      );
      nearestRelativeContact.enableReadOnly();
      const howMany = form.getTextField("How many");
      if (this.state.pet === "true") {
        // Yes_1
        form.getCheckBox("Yes_1").check();
        howMany.setText(this.ModifyString(this.state.petCount));
      } else {
        // No_1
        form.getCheckBox("No_1").check();
        howMany.setText(this.ModifyString(0));
      }
      howMany.enableReadOnly();
      const Yes_1 = form.getCheckBox("Yes_1");
      Yes_1.enableReadOnly();
      const No_1 = form.getCheckBox("No_1");
      No_1.enableReadOnly();
      // How many

      // havent getting no.of pets

      // Present Address City State Zip
      const presentAddressCityStateZip = form.getTextField(
        "Present Address City State Zip"
      );
      presentAddressCityStateZip.setText(
        this.ModifyString(
          (this.state.address ? this.state.address + "," : "") +
            (this.state.city ? this.state.city + "," : "") +
            (this.state.state ? this.state.state + "," : "") +
            (this.state.zip ? this.state.zip : "")
        )
      );
      presentAddressCityStateZip.enableReadOnly();
      // From
      const from = form.getTextField("From");
      from.setText(this.ModifyString(this.ModifyDate(this.state.from)));
      from.enableReadOnly();
      // To
      const to = form.getTextField("To");
      to.setText(this.ModifyString(this.ModifyDate(this.state.to)));
      to.enableReadOnly();
      if (this.state.propOwn === "Own") {
        // Own
        form.getCheckBox("Own").check();
      } else if (this.state.propOwn === "Rent") {
        // Rent
        form.getCheckBox("Rent").check();
      }
      const own = form.getCheckBox("Own");
      own.enableReadOnly();
      const rent = form.getCheckBox("Rent");
      rent.enableReadOnly();
      // Monthly Payment
      const monthlyPayment = form.getTextField("Monthly Payment");
      monthlyPayment.setText(this.ModifyString(this.state.rent));
      monthlyPayment.enableReadOnly();
      // Phone_2
      const Phone_2 = form.getTextField("Phone_2");
      Phone_2.setText(this.ModifyString(this.state.landLordMobile));
      Phone_2.enableReadOnly();
      // Name of Previous Landlord
      const presentLandlord = form.getTextField("Name of Present Landlord");
      presentLandlord.setText(this.ModifyString(this.state.presentLandlord));
      presentLandlord.enableReadOnly();
      // Landlord Email Address
      const presentLandlordEmail = form.getTextField("Landlord Email Address");
      presentLandlordEmail.setText(this.ModifyString(this.state.landLordEmail));
      presentLandlordEmail.enableReadOnly();
      // Fax
      const fax = form.getTextField("Fax");
      fax.setText(this.ModifyString(this.state.landLordFax));
      fax.enableReadOnly();

      if (this.state.unitType === "Apartment") {
        // Apartment Community
        form.getCheckBox("Apartment Community").check();
      } else if (this.state.unitType === "Mortgage") {
        // Mortgage Co
        form.getCheckBox("Mortgage Co").check();
      } else if (this.state.unitType === "Other") {
        //Other
        form.getCheckBox("Other").check();
      }
      const apartmentCommunity = form.getCheckBox("Apartment Community");
      apartmentCommunity.enableReadOnly();
      const mortgage = form.getCheckBox("Mortgage Co");
      mortgage.enableReadOnly();
      const other = form.getCheckBox("Other");
      other.enableReadOnly();

      if (this.state.preUnitType === "Apartment") {
        // Apartment Community
        form.getCheckBox("Apartment Community2").check();
      } else if (this.state.preUnitType === "Mortgage") {
        // Mortgage Co
        form.getCheckBox("Mortgage Co2").check();
      } else if (this.state.unitType === "Other") {
        //Other
        form.getCheckBox("Other2").check();
      }
      const apartmentCommunity2 = form.getCheckBox("Apartment Community2");
      apartmentCommunity2.enableReadOnly();
      const mortgage2 = form.getCheckBox("Mortgage Co2");
      mortgage2.enableReadOnly();
      const other2 = form.getCheckBox("Other2");
      other2.enableReadOnly();

      // Previous Residence Address City State Zip
      const previousResidenceAddressCityStateZip = form.getTextField(
        "Previous Residence Address City State Zip"
      );

      previousResidenceAddressCityStateZip.setText(
        this.ModifyString(
          (this.state.preAddress ? this.state.preAddress + "," : "") +
            (this.state.preCity ? this.state.preCity + "," : "") +
            (this.state.preState ? this.state.preState + "," : "") +
            (this.state.preZip ? this.state.preZip : "")
        )
      );
      previousResidenceAddressCityStateZip.enableReadOnly();
      // From_2
      const from_2 = form.getTextField("From_2");
      from_2.setText(this.ModifyString(this.ModifyDate(this.state.preFrom)));
      from_2.enableReadOnly();
      // To_2
      const to_2 = form.getTextField("To_2");
      to_2.setText(this.ModifyString(this.ModifyDate(this.state.preTo)));
      to_2.enableReadOnly();
      // Monthly Payment_2
      const monthlyPayment_2 = form.getTextField("Monthly Payment_2");
      monthlyPayment_2.setText(this.ModifyString(this.state.preRent));
      monthlyPayment_2.enableReadOnly();
      if (this.state.prePropOwn === "Own") {
        // Own_2
        form.getCheckBox("Own_2").check();
      } else if (this.state.prePropOwn === "Rent") {
        // Rent_2
        form.getCheckBox("Rent_2").check();
      }
      const own2 = form.getCheckBox("Own_2");
      own2.enableReadOnly();
      const rent2 = form.getCheckBox("Rent_2");
      rent2.enableReadOnly();
      // haven't added previous landlord name

      const previousLandlord = form.getTextField("Name of Previous Landlord");
      previousLandlord.setText(this.ModifyString(this.state.preLandLord));
      previousLandlord.enableReadOnly();
      // Phone_3
      const Phone_3 = form.getTextField("Phone_3");
      Phone_3.setText(this.ModifyString(this.state.preLandLordMobile));
      Phone_3.enableReadOnly();
      // Landlord Email Address_2
      const preLandLordEmail = form.getTextField("Landlord Email Address_2");
      preLandLordEmail.setText(this.ModifyString(this.state.preLandLordEmail));
      preLandLordEmail.enableReadOnly();
      // Fax_2
      const Fax_2 = form.getTextField("Fax_2");
      Fax_2.setText(this.ModifyString(this.state.preLandLordFax));
      Fax_2.enableReadOnly();
      //EMPLOYMENT DATA
      //Applicant Employed By
      const presentEmploy = form.getTextField("Applicant Employed By");
      presentEmploy.setText(this.ModifyString(this.state.employmentCompany));
      presentEmploy.enableReadOnly();
      //Position
      const position1 = form.getTextField("Position");
      position1.setText(this.ModifyString(this.state.employmentPosition));
      position1.enableReadOnly();
      //Company Phone Number
      const companyPhone1 = form.getTextField("Company Phone Number");
      companyPhone1.setText(this.ModifyString(this.state.employmentPhone));
      companyPhone1.enableReadOnly();
      //Hr Dept/ Supervisor Email:
      const companyEmail1 = form.getTextField("HR DeptSupervisor Email");
      companyEmail1.setText(this.ModifyString(this.state.employmentEmail));
      companyEmail1.enableReadOnly();

      //Address City
      const employAddress1 = form.getTextField("Address City State Zip");
      employAddress1.setText(
        this.ModifyString(
          (this.state.employmentAddress
            ? this.state.employmentAddress + ","
            : "") +
            (this.state.employmentCity ? this.state.employmentCity + "," : "") +
            (this.state.employmentState
              ? this.state.employmentState + ","
              : "") +
            (this.state.employmentZip ? this.state.employmentZip : "")
        )
      );
      employAddress1.enableReadOnly();
      //How Long?
      const howLongYer1 = form.getTextField("Yrs");
      howLongYer1.setText(
        this.ModifyString(
          betweenYears(
            this.state.employmentFrom,
            this.state.employmentUntil
          ) === 0
            ? "-"
            : betweenYears(
                this.state.employmentFrom,
                this.state.employmentUntil
              )
        )
      );
      howLongYer1.enableReadOnly();

      const howLongMonth1 = form.getTextField("Mos");
      howLongMonth1.setText(
        this.ModifyString(
          betweenExtraMonths(
            this.state.employmentFrom,
            this.state.employmentUntil
          ) === 0
            ? "-"
            : betweenExtraMonths(
                this.state.employmentFrom,
                this.state.employmentUntil
              )
        )
      );
      howLongMonth1.enableReadOnly();
      //Salary
      const salaryAmount1 = form.getTextField("undefined_7");
      salaryAmount1.setText(this.ModifyString(this.state.employmentSalary));
      salaryAmount1.enableReadOnly();

      const salaryPer1 = form.getTextField("Per"); // month or year or day
      salaryPer1.setText(this.ModifyString("month"));
      salaryPer1.enableReadOnly();
      //Previous or 2nd Employment (Please Circle one)
      const previousEmploy = form.getTextField(
        "Previous or 2 nd Employment Please Circle one"
      );

      previousEmploy.setText(
        this.ModifyString(this.state.preEmploymentCompany)
      );
      previousEmploy.enableReadOnly();
      //Position
      const position2 = form.getTextField("Position_2");
      position2.setText(this.ModifyString(this.state.preEmploymentPosition));
      position2.enableReadOnly();
      //Company Phone Number
      const companyPhone2 = form.getTextField("Company Phone Number_2");
      companyPhone2.setText(this.ModifyString(this.state.preEmploymentPhone));
      companyPhone2.enableReadOnly();
      //Hr Dept/ Supervisor Email:
      const companyEmail2 = form.getTextField("Hr Dept Supervisor Email");
      companyEmail2.setText(this.ModifyString(this.state.preEmploymentEmail));
      companyEmail2.enableReadOnly();
      //Address City
      const employAddress2 = form.getTextField("Address City State Zip_2");
      employAddress2.setText(
        this.ModifyString(
          (this.state.preEmploymentAddress
            ? this.state.preEmploymentAddress + ","
            : "") +
            (this.state.preEmploymentCity
              ? this.state.preEmploymentCity + ","
              : "") +
            (this.state.preEmploymentState
              ? this.state.preEmploymentState + ","
              : "") +
            (this.state.preEmploymentZip ? this.state.preEmploymentZip : "")
        )
      );
      employAddress2.enableReadOnly();
      //How Long?
      const howLongYer2 = form.getTextField("Yrs_2");
      howLongYer2.setText(
        this.ModifyString(
          betweenYears(
            this.state.preEmploymentFrom,
            this.state.preEmploymentUntil
          ) === 0
            ? "-"
            : betweenYears(
                this.state.preEmploymentFrom,
                this.state.preEmploymentUntil
              )
        )
      );
      howLongYer2.enableReadOnly();
      const howLongMonth2 = form.getTextField("Mos_2");
      howLongMonth2.setText(
        this.ModifyString(
          betweenExtraMonths(
            this.state.preEmploymentFrom,
            this.state.preEmploymentUntil
          ) === 0
            ? "-"
            : betweenExtraMonths(
                this.state.preEmploymentFrom,
                this.state.preEmploymentUntil
              )
        )
      );
      howLongMonth2.enableReadOnly();
      //Salary
      const salaryAmount2 = form.getTextField("undefined_8");
      salaryAmount2.setText(
        this.ModifyString(this.ModifyString(this.state.preEmploymentSalary))
      );
      salaryAmount2.enableReadOnly();
      const salaryPer2 = form.getTextField("undefined_9"); // month or year or day
      salaryPer2.setText(this.ModifyString("month"));
      salaryPer2.enableReadOnly();
      //ADDITIONAL INCOME: Additional income such as child support, alimony or separate maintenance need not be described unless such additional income is to be included for qualification hereunder. Amount of $
      const additionalIncome = form.getTextField(
        "ADDITIONAL INCOME Additional income such as child support alimony or separate maintenance need not be described unless such additional income is to be included for qualification hereunder Amount of"
      );
      additionalIncome.setText(this.ModifyString(this.state.additionalIncome));
      additionalIncome.enableReadOnly();
      //Auto/Year/License
      const autoYearLicense = form.getTextField("AutoYearLicense");
      autoYearLicense.setText(this.ModifyString(this.state.drivingNo));
      autoYearLicense.enableReadOnly();
      //Do you have any recreational vehicles, vans, boats, or motorcycles? If so specify.
      const vehicles = form.getTextField(
        "Do you have any recreational vehicles vans boats or motorcycles If so specify"
      );
      vehicles.setText(this.ModifyString(this.state.vehicles));
      vehicles.enableReadOnly();
      //Have you or any person who will be occupying this household ever been found guilty of a crime?
      if (this.state.crimeCheck) {
        form.getCheckBox("Yes_5").check();
      } else {
        form.getCheckBox("No_5").check();
      }
      const yes5 = form.getCheckBox("Yes_5");
      yes5.enableReadOnly();
      const no5 = form.getCheckBox("No_5");
      no5.enableReadOnly();
      // !!!!!!!!!!!!! put check boxes
      //Ever filed bankruptcy?
      if (this.state.bankruptcy) {
        form.getCheckBox("undefined_10").check();
      } else {
        form.getCheckBox("undefined_11").check();
      }
      const undefine10 = form.getCheckBox("undefined_10");
      undefine10.enableReadOnly();
      const undefine11 = form.getCheckBox("undefined_11");
      undefine11.enableReadOnly();
      const undefined12 = form.getTextField("undefined_12");
      undefined12.enableReadOnly();
      const signed3 = form.getTextField("Signed_3");
      signed3.enableReadOnly();
      // const bankruptcyResion = form.getTextField('Ever filed bankruptcy Yes No')
      // bankruptcyResion.setText(bankruptcyResion)
      //Have you or any person who will be occupying this household ever been evicted?

      if (this.state.evicted) {
        form.getCheckBox("been evicted").check();
      } else {
        form.getCheckBox("Yes_4").check();
      }
      const evicted = form.getCheckBox("been evicted");
      evicted.enableReadOnly();
      const yes4 = form.getCheckBox("Yes_4");
      yes4.enableReadOnly();
      //Anyone in the Household Smoke?
      if (this.state.smoke) {
        form.getCheckBox("Anyone in the Household Smoke").check();
      } else {
        form.getCheckBox("No_2").check();
      }
      const smoke = form.getCheckBox("Anyone in the Household Smoke");
      smoke.enableReadOnly();
      const no2 = form.getCheckBox("No_2");
      no2.enableReadOnly();

      //last page
      const holdingFee = form.getTextField("HOLDING FEE of");
      holdingFee.setText(this.ModifyString("-"));
      holdingFee.enableReadOnly();

      const applicationCheque = form.getTextField("Check");
      applicationCheque.setText("");
      applicationCheque.enableReadOnly();

      const applicationFee = form.getTextField("NonRefundable Process Fee");
      applicationFee.setText(this.ModifyString(this.state.applicationFee));
      applicationFee.enableReadOnly();

      const Dated = form.getTextField("Dated");
      Dated.setText(this.ModifyDate(this.today()));
      Dated.enableReadOnly();

      // const landlordSigned = form.getTextField("Signed_2");

      // landlordSigned.setText("");
      // landlordSigned.enableReadOnly();

      // const jpgUrl = 'https://pdf-lib.js.org/assets/cat_riding_unicorn.jpg'

      // const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
      // console.log(jpgImageBytes);
      // var x=new Blob([jpgImageBytes], { type: "image/jpeg" });
      // console.log(x);
      // console.log(jpgImageBytes);
      // console.log(this.state.signatureObject3);

      // console.log(new Response(this.state.signatureObject3).arrayBuffer());
      // console.log(jpgImageBytes);
      
      // var x= this.state.signatureObject3.arrayBuffer().then((blob) =>
      // new Promise((resolve, reject) => {
      //   const reader = new FileReader();
      //   reader.onloadend = () => resolve(reader.result);
      //   reader.onerror = reject;
      //   reader.readAsDataURL(blob);
      // }));
      // var x=new Response(this.state.signatureObject3).arrayBuffer().then((res) => res );
      
      // console.log(x);
      // const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
      // console.log(jpgImage);
      // const jpgImage2 = await pdfDoc.embedJpg(new Response(this.state.signatureObject3).arrayBuffer());
      // console.log(jpgImage2);
        // const jpgDims = jpgImage.scale(0.5);

        // const pages = pdfDoc.getPages();
        // pages[1].drawText('You can modify PDFs too!')
        // pages[1].drawImage(jpgImage, {
        //   x: 75,
        //   y: 463,
        //   width: 100,
        //   height: 20,
        // });


      const Dated_2 = form.getTextField("Dated_2");
      Dated_2.setText(this.ModifyDate(""));
      Dated_2.enableReadOnly();
      const pdfBytes = await pdfDoc.save();
      let blob = new Blob([pdfBytes], { type: "application/pdf" });

      this.setState({ pdfDocObject: blob, changeBlob: true });
    } catch (error) {
      this.setState({ pdfDocObject: "" });
      console.log("PDF Fetching Error");
    }
  }
  async downloadPDF() {
    if (this.state.pdfDocObject === "") {
      this.props.appendAlert("Downloading Error Found!!!", "danger");
    } else {
      const urlView = window.URL.createObjectURL(this.state.pdfDocObject);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = urlView;
      a.download = "file.pdf";
      a.click();
      window.URL.revokeObjectURL(urlView);
    }
  }
  ModifyDate(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      let year = parseInt(dateItems[yearIndex]);
      let month = parseInt(dateItems[monthIndex]);
      let date = parseInt(dateItems[dayIndex]);
      return month + "/" + date + "/" + year;
    } else {
      return "";
    }
  }
  emailReject() {
    window.$("#mailCheck").modal("hide");
    this.setState({ email: "" });
  }
  async emailAccept() {
    await this.setState({ mailCheck: true });
    this.next();
  }
  async next() {
    this.setState({ next: true });
    if (this.state.nowTab < 5) {
      if (this.state.nowTab === 0) {
        this.getPdfBlob();
        window.scrollTo(0, 0);
        this.setState({ next: false });
        if (this.checkTab()) {
          let newArray = this.state.tabCheck;
          newArray[this.state.nowTab] = true;
          this.setState({ tabCheck: newArray });
          if (!this.nextCheck(this.state.nowTab + 1)) {
            this.setState({ nextEnable: false });
          }
          this.setState({ nowTab: this.state.nowTab + 1 });
          window.onbeforeunload = this.beforeUnloadListener;
        }
      } else if (this.state.nowTab === 1) {
        window.scrollTo(0, 0);

        if (
          this.state.firstName !== "" &&
          this.state.lastName !== "" &&
          this.state.email !== "" &&
          this.checkEmailNext(this.state.email) &&
          this.state.relativeName !== "" &&
          this.state.dob !== "" &&
          this.state.drivingNo !== "" &&
          this.state.securityNo !== "" &&
          this.checkSINNext(this.state.securityNo) &&
          this.state.mobile !== "" &&
          this.checkMobileNext(this.state.mobile) &&
          this.state.pet !== "" &&
          this.state.relativeNum !== "" &&
          this.checkMobileNext(this.state.relativeNum) &&
          this.state.occupant !== "" &&
          this.state.moveInDate !== "" &&
          this.state.leaseTerm !== ""
        ) {
          if (this.state.isJoinTenant) {
            if (this.state.joinTenantCount === 1) {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1)
              ) {
                await this.setState({ occupantsArray: [] });
                let x = true;
                for (let i = 1; i < this.state.occupant; i++) {
                  if (this.state["occupant" + i] === "") {
                    x = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i]);
                }
                if (x) {
                  if (!this.state.mailCheck) {
                    if (this.props.UserData.username !== this.state.email) {
                      await isAvailableUser(this.state.email).then(
                        (response) => {
                          if (response.data) {
                            window.$("#mailCheck").modal("show");
                          } else {
                            this.setState({ next: false });
                            if (this.checkTab()) {
                              let newArray = this.state.tabCheck;
                              newArray[this.state.nowTab] = true;
                              this.setState({ tabCheck: newArray });

                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                            }
                          }
                        }
                      );
                    } else {
                      this.setState({ next: false });
                      if (this.checkTab()) {
                        let newArray = this.state.tabCheck;
                        newArray[this.state.nowTab] = true;
                        this.setState({ tabCheck: newArray });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                      }
                    }
                  } else {
                    this.setState({ next: false });
                    if (this.checkTab()) {
                      let newArray = this.state.tabCheck;
                      newArray[this.state.nowTab] = true;
                      this.setState({ tabCheck: newArray });
                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({ nowTab: this.state.nowTab + 1 });
                    }
                  }
                }
              }
            } else {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1) &&
                this.state.joinTenant2 !== "" &&
                this.checkEmailNext(this.state.joinTenant2)
              ) {
                await this.setState({ occupantsArray: [] });
                let x1 = true;
                for (let i1 = 1; i1 < this.state.occupant; i1++) {
                  if (this.state["occupant" + i1] === "") {
                    x1 = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i1]);
                }
                if (x1) {
                  if (!this.state.mailCheck) {
                    if (this.props.UserData.username !== this.state.email) {
                      await isAvailableUser(this.state.email).then(
                        (response) => {
                          if (response.data) {
                            window.$("#mailCheck").modal("show");
                          } else {
                            this.setState({ next: false });
                            if (this.checkTab()) {
                              let newArray = this.state.tabCheck;
                              newArray[this.state.nowTab] = true;
                              this.setState({ tabCheck: newArray });
                              if (!this.nextCheck(this.state.nowTab + 1)) {
                                this.setState({ nextEnable: false });
                              }
                              this.setState({ nowTab: this.state.nowTab + 1 });
                            }
                          }
                        }
                      );
                    } else {
                      this.setState({ next: false });
                      if (this.checkTab()) {
                        let newArray = this.state.tabCheck;
                        newArray[this.state.nowTab] = true;
                        this.setState({ tabCheck: newArray });

                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                      }
                    }
                  } else {
                    this.setState({ next: false });
                    if (this.checkTab()) {
                      let newArray = this.state.tabCheck;
                      newArray[this.state.nowTab] = true;
                      this.setState({ tabCheck: newArray });

                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({ nowTab: this.state.nowTab + 1 });
                    }
                  }
                }
              }
            }
          } else {
            await this.setState({ occupantsArray: [] });
            let x2 = true;
            for (let i2 = 1; i2 < this.state.occupant; i2++) {
              if (this.state["occupant" + i2] === "") {
                x2 = false;
              }
              this.state.occupantsArray.push(this.state["occupant" + i2]);
            }
            if (x2) {
              if (!this.state.mailCheck) {
                if (this.props.UserData.username !== this.state.email) {
                  await isAvailableUser(this.state.email).then((response) => {
                    if (response.data) {
                      window.$("#mailCheck").modal("show");
                    } else {
                      this.setState({ next: false });
                      if (this.checkTab()) {
                        let newArray = this.state.tabCheck;
                        newArray[this.state.nowTab] = true;
                        this.setState({ tabCheck: newArray });
                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                      }
                    }
                  });
                } else {
                  this.setState({ next: false });
                  if (this.checkTab()) {
                    let newArray = this.state.tabCheck;
                    newArray[this.state.nowTab] = true;
                    this.setState({ tabCheck: newArray });
                    if (!this.nextCheck(this.state.nowTab + 1)) {
                      this.setState({ nextEnable: false });
                    }
                    this.setState({ nowTab: this.state.nowTab + 1 });
                  }
                }
              } else {
                this.setState({ next: false });
                if (this.checkTab()) {
                  let newArray = this.state.tabCheck;
                  newArray[this.state.nowTab] = true;
                  this.setState({ tabCheck: newArray });
                  if (!this.nextCheck(this.state.nowTab + 1)) {
                    this.setState({ nextEnable: false });
                  }
                  this.setState({ nowTab: this.state.nowTab + 1 });
                }
              }
            }
          }
        }
      } else if (this.state.nowTab === 2) {
        window.scrollTo(0, 0);
        if (
          this.state.address !== "" &&
          this.state.state !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.checkPeriodNext(this.state.from, this.state.to) &&
          this.state.from !== "" &&
          this.state.to !== "" &&
          this.state.propOwn !== ""
        ) {
          if (this.state.propOwn === "Rent") {
            if (
              this.state.presentLandlord !== "" &&
              this.state.rent !== "" &&
              this.state.landLordEmail !== "" &&
              this.state.landLordMobile !== "" &&
              this.state.unitType !== ""
            ) {
              if (this.state.preRentalInfo) {
                if (
                  this.state.preAddress !== "" &&
                  this.state.preState !== "" &&
                  this.state.preCity !== "" &&
                  this.state.preZip !== "" &&
                  this.state.preFrom !== "" &&
                  this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                  this.state.preTo !== "" &&
                  this.state.prePropOwn !== ""
                ) {
                  if (this.state.prePropOwn === "Rent") {
                    if (
                      this.state.preLandLord !== "" &&
                      this.state.preRent !== "" &&
                      this.state.preLandLordEmail !== "" &&
                      this.state.preLandLordMobile !== "" &&
                      this.state.preUnitType !== ""
                    ) {
                      this.setState({ next: false });
                      if (this.checkTab()) {
                        let newArray = this.state.tabCheck;
                        newArray[this.state.nowTab] = true;
                        this.setState({ tabCheck: newArray });

                        if (!this.nextCheck(this.state.nowTab + 1)) {
                          this.setState({ nextEnable: false });
                        }
                        this.setState({ nowTab: this.state.nowTab + 1 });
                        if (this.state.prePropOwn === "Own") {
                          this.setState({
                            preLandLord: "",
                            preLandLordEmail: "",
                            preRent: "",
                            preLandLordMobile: "",
                            preLandLordFax: "",
                            preUnitType: "",
                          });
                        }
                        if (this.state.propOwn === "Own") {
                          this.setState({
                            presentLandlord: "",
                            landLordEmail: "",
                            rent: "",
                            landLordMobile: "",
                            landLordFax: "",
                            unitType: "",
                          });
                        }
                      }
                    }
                  } else {
                    this.setState({ next: false });
                    if (this.checkTab()) {
                      let newArray = this.state.tabCheck;
                      newArray[this.state.nowTab] = true;
                      this.setState({ tabCheck: newArray });

                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({ nowTab: this.state.nowTab + 1 });
                      if (this.state.prePropOwn === "Own") {
                        this.setState({
                          preLandLord: "",
                          preLandLordEmail: "",
                          preRent: "",
                          preLandLordMobile: "",
                          preLandLordFax: "",
                          preUnitType: "",
                        });
                      }
                      if (this.state.propOwn === "Own") {
                        this.setState({
                          presentLandlord: "",
                          landLordEmail: "",
                          rent: "",
                          landLordMobile: "",
                          landLordFax: "",
                          unitType: "",
                        });
                      }
                    }
                  }
                }
              } else {
                this.setState({ next: false });
                if (this.checkTab()) {
                  let newArray = this.state.tabCheck;
                  newArray[this.state.nowTab] = true;
                  this.setState({ tabCheck: newArray });

                  if (!this.nextCheck(this.state.nowTab + 1)) {
                    this.setState({ nextEnable: false });
                  }
                  this.setState({ nowTab: this.state.nowTab + 1 });
                  if (this.state.prePropOwn === "Own") {
                    this.setState({
                      preLandLord: "",
                      preLandLordEmail: "",
                      preRent: "",
                      preLandLordMobile: "",
                      preLandLordFax: "",
                      preUnitType: "",
                    });
                  }
                  if (this.state.propOwn === "Own") {
                    this.setState({
                      presentLandlord: "",
                      landLordEmail: "",
                      rent: "",
                      landLordMobile: "",
                      landLordFax: "",
                      unitType: "",
                    });
                  }
                }
              }
            }
          } else {
            if (this.state.preRentalInfo) {
              if (
                this.state.preAddress !== "" &&
                this.state.preState !== "" &&
                this.state.preCity !== "" &&
                this.state.preZip !== "" &&
                this.state.preFrom !== "" &&
                this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                this.state.preTo !== "" &&
                this.state.prePropOwn !== ""
              ) {
                if (this.state.prePropOwn === "Rent") {
                  if (
                    this.state.preLandLord !== "" &&
                    this.state.preRent !== "" &&
                    this.state.preLandLordEmail !== "" &&
                    this.state.preLandLordMobile !== "" &&
                    this.state.preUnitType !== ""
                  ) {
                    this.setState({ next: false });
                    if (this.checkTab()) {
                      let newArray = this.state.tabCheck;
                      newArray[this.state.nowTab] = true;
                      if (!this.nextCheck(this.state.nowTab + 1)) {
                        this.setState({ nextEnable: false });
                      }
                      this.setState({
                        tabCheck: newArray,
                        nowTab: this.state.nowTab + 1,
                        presentLandlord: "",
                        landLordEmail: "",
                        rent: "",
                        landLordMobile: "",
                        landLordFax: "",
                        unitType: "",
                      });
                      if (this.state.prePropOwn === "Own") {
                        this.setState({
                          preLandLord: "",
                          preLandLordEmail: "",
                          preRent: "",
                          preLandLordMobile: "",
                          preLandLordFax: "",
                          preUnitType: "",
                        });
                      }
                      if (this.state.propOwn === "Own") {
                        this.setState({
                          presentLandlord: "",
                          landLordEmail: "",
                          rent: "",
                          landLordMobile: "",
                          landLordFax: "",
                          unitType: "",
                        });
                      }
                    }
                  }
                } else {
                  this.setState({ next: false });
                  if (this.checkTab()) {
                    let newArray = this.state.tabCheck;
                    newArray[this.state.nowTab] = true;
                    if (!this.nextCheck(this.state.nowTab + 1)) {
                      this.setState({ nextEnable: false });
                    }
                    this.setState({
                      tabCheck: newArray,
                      nowTab: this.state.nowTab + 1,
                      presentLandlord: "",
                      landLordEmail: "",
                      rent: "",
                      landLordMobile: "",
                      landLordFax: "",
                      unitType: "",
                    });
                    if (this.state.prePropOwn === "Own") {
                      this.setState({
                        preLandLord: "",
                        preLandLordEmail: "",
                        preRent: "",
                        preLandLordMobile: "",
                        preLandLordFax: "",
                        preUnitType: "",
                      });
                    }
                    if (this.state.propOwn === "Own") {
                      this.setState({
                        presentLandlord: "",
                        landLordEmail: "",
                        rent: "",
                        landLordMobile: "",
                        landLordFax: "",
                        unitType: "",
                      });
                    }
                  }
                }
              }
            } else {
              this.setState({ next: false });
              if (this.checkTab()) {
                let newArray = this.state.tabCheck;
                newArray[this.state.nowTab] = true;
                if (!this.nextCheck(this.state.nowTab + 1)) {
                  this.setState({ nextEnable: false });
                }
                this.setState({
                  tabCheck: newArray,
                  nowTab: this.state.nowTab + 1,
                  presentLandlord: "",
                  landLordEmail: "",
                  rent: "",
                  landLordMobile: "",
                  landLordFax: "",
                  unitType: "",
                });
                if (this.state.prePropOwn === "Own") {
                  this.setState({
                    preLandLord: "",
                    preLandLordEmail: "",
                    preRent: "",
                    preLandLordMobile: "",
                    preLandLordFax: "",
                    preUnitType: "",
                  });
                }
                if (this.state.propOwn === "Own") {
                  this.setState({
                    presentLandlord: "",
                    landLordEmail: "",
                    rent: "",
                    landLordMobile: "",
                    landLordFax: "",
                    unitType: "",
                  });
                }
              }
            }
          }
        }
      } else if (this.state.nowTab === 3) {
        window.scrollTo(0, 0);
        if (this.state.preEmploymentInfo) {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            this.state.employmentEmail !== "" &&
            this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== "" &&
            this.state.preEmploymentCompany !== "" &&
            this.state.preEmploymentPhone !== "" &&
            this.checkMobileNext(this.state.preEmploymentPhone) &&
            this.checkPeriodNext(
              this.state.preEmploymentFrom,
              this.state.preEmploymentUntil
            ) &&
            this.state.preEmploymentEmail !== "" &&
            this.checkEmailNext(this.state.preEmploymentEmail) &&
            this.state.preEmploymentZip !== "" &&
            this.state.preEmploymentCity !== "" &&
            this.state.preEmploymentState !== "" &&
            this.state.preEmploymentSalary !== "" &&
            this.state.preEmploymentPosition !== "" &&
            this.state.preEmploymentFrom !== "" &&
            this.state.preEmploymentUntil !== ""
          ) {
            this.setState({ next: false });

            if (
              this.state.stripe_account_status.data === "verified"
              // this.state.paymentToken !== ""
            ) {
              if (this.checkTab()) {
                let newArray = this.state.tabCheck;
                newArray[this.state.nowTab] = true;
                this.setState({ tabCheck: newArray });
                if (!this.nextCheck(this.state.nowTab + 2)) {
                  this.setState({ nextEnable: false });
                }
                await this.setState({ nowTab: this.state.nowTab + 2 });
                this.getPdfBlob();
              }
            } else {
              if (this.checkTab()) {
                let newArray = this.state.tabCheck;
                newArray[this.state.nowTab] = true;
                this.setState({ tabCheck: newArray });
                if (!this.nextCheck(this.state.nowTab + 1)) {
                  this.setState({ nextEnable: false });
                }
                this.setState({ nowTab: this.state.nowTab + 1 });
                this.getPdfBlob();
              }
            }
          }
        } else {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            this.state.employmentEmail !== "" &&
            this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== ""
          ) {
            this.setState({ next: false });

            if (
              this.state.stripe_account_status.data === "verified"
              // this.state.paymentToken !== ""
            ) {
              if (this.checkTab()) {
                let newArray = this.state.tabCheck;
                newArray[this.state.nowTab] = true;
                this.setState({ tabCheck: newArray });
                if (!this.nextCheck(this.state.nowTab + 2)) {
                  this.setState({ nextEnable: false });
                }
                await this.setState({ nowTab: this.state.nowTab + 2 });
                this.getPdfBlob();
              }
            } else {
              if (this.checkTab()) {
                let newArray = this.state.tabCheck;
                newArray[this.state.nowTab] = true;
                this.setState({ tabCheck: newArray });

                if (!this.nextCheck(this.state.nowTab + 1)) {
                  this.setState({ nextEnable: false });
                }
                this.setState({ nowTab: this.state.nowTab + 1 });
                this.getPdfBlob();
              }
            }
          }
        }
      } else if (this.state.nowTab === 4) {
        window.scrollTo(0, 0);
        this.setState({ next: false });
        if (this.checkTab()) {
          let newArray = this.state.tabCheck;
          newArray[this.state.nowTab] = true;
          this.setState({ tabCheck: newArray });

          if (!this.nextCheck(this.state.nowTab + 1)) {
            this.setState({ nextEnable: false });
          }
          this.setState({ nowTab: this.state.nowTab + 1 });
        }

        this.setState({ stripe_account_status: "verified" });
      } else if (this.state.nowTab === 5) {
        window.scrollTo(0, 0);
        this.setState({ next: false });
        if (this.checkTab()) {
          let newArray = this.state.tabCheck;
          newArray[this.state.nowTab] = true;
          this.setState({ tabCheck: newArray });

          if (!this.nextCheck(this.state.nowTab + 1)) {
            this.setState({ nextEnable: false });
          }
          this.setState({ nowTab: this.state.nowTab + 1 });
        }
      }
    } else {
      window.scrollTo(0, 0);
      this.setState({ next: false });
      this.setState({ nowTab: 1 });
    }
  }
  async nextCheck(tab) {
    this.setState({ nextEnable: false });
    if (tab < 5) {
      if (tab === 0) {
        this.setState({ nextEnable: true });
        return true;
      } else if (tab === 1) {
        if (
          this.state.firstName !== "" &&
          this.state.lastName !== "" &&
          this.state.email !== "" &&
          this.checkEmailNext(this.state.email) &&
          this.state.relativeName !== "" &&
          this.state.dob !== "" &&
          this.state.drivingNo !== "" &&
          this.state.securityNo !== "" &&
          this.checkSINNext(this.state.securityNo) &&
          this.state.mobile !== "" &&
          this.checkMobileNext(this.state.mobile) &&
          this.state.pet !== "" &&
          this.state.relativeNum !== "" &&
          this.checkMobileNext(this.state.relativeNum) &&
          this.state.occupant !== "" &&
          this.state.moveInDate !== "" &&
          this.state.leaseTerm !== ""
        ) {
          if (this.state.isJoinTenant) {
            if (this.state.joinTenantCount === 1) {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1)
              ) {
                await this.setState({ occupantsArray: [] });
                let x = true;
                for (let i = 1; i < this.state.occupant; i++) {
                  if (this.state["occupant" + i] === "") {
                    x = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i]);
                }
                if (x) {
                  if (!this.state.mailCheck) {
                    if (this.props.UserData.username !== this.state.email) {
                      await isAvailableUser(this.state.email).then(
                        (response) => {
                          if (response.data) {
                            window.$("#mailCheck").modal("show");
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      );
                    } else {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  } else {
                    this.setState({ nextEnable: true });
                    return true;
                  }
                }
              }
            } else {
              if (
                this.state.joinTenant1 !== "" &&
                this.checkEmailNext(this.state.joinTenant1) &&
                this.state.joinTenant2 !== "" &&
                this.checkEmailNext(this.state.joinTenant2)
              ) {
                await this.setState({ occupantsArray: [] });
                let x1 = true;
                for (let i1 = 1; i1 < this.state.occupant; i1++) {
                  if (this.state["occupant" + i1] === "") {
                    x1 = false;
                  }
                  this.state.occupantsArray.push(this.state["occupant" + i1]);
                }
                if (x1) {
                  if (!this.state.mailCheck) {
                    if (this.props.UserData.username !== this.state.email) {
                      await isAvailableUser(this.state.email).then(
                        (response) => {
                          if (response.data) {
                            window.$("#mailCheck").modal("show");
                          } else {
                            this.setState({ nextEnable: true });
                            return true;
                          }
                        }
                      );
                    } else {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  } else {
                    this.setState({ nextEnable: true });
                    return true;
                  }
                }
              }
            }
          } else {
            await this.setState({ occupantsArray: [] });
            let x2 = true;
            for (let i2 = 1; i2 < this.state.occupant; i2++) {
              if (this.state["occupant" + i2] === "") {
                x2 = false;
              }
              this.state.occupantsArray.push(this.state["occupant" + i2]);
            }
            if (x2) {
              if (!this.state.mailCheck) {
                if (this.props.UserData.username !== this.state.email) {
                  await isAvailableUser(this.state.email).then((response) => {
                    if (response.data) {
                      window.$("#mailCheck").modal("show");
                    } else {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  });
                } else {
                  this.setState({ nextEnable: true });
                  return true;
                }
              } else {
                this.setState({ nextEnable: true });
                return true;
              }
            }
          }
        }
      } else if (tab === 2) {
        if (
          this.state.address !== "" &&
          this.state.state !== "" &&
          this.state.city !== "" &&
          this.state.zip !== "" &&
          this.checkPeriodNext(this.state.from, this.state.to) &&
          this.state.from !== "" &&
          this.state.to !== "" &&
          this.state.propOwn !== ""
        ) {
          if (this.state.propOwn === "Rent") {
            if (
              this.state.presentLandlord !== "" &&
              this.state.rent !== "" &&
              this.state.landLordEmail !== "" &&
              this.state.landLordMobile !== "" &&
              this.state.unitType !== ""
            ) {
              if (this.state.preRentalInfo) {
                if (
                  this.state.preAddress !== "" &&
                  this.state.preState !== "" &&
                  this.state.preCity !== "" &&
                  this.state.preZip !== "" &&
                  this.state.preFrom !== "" &&
                  this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                  this.state.preTo !== "" &&
                  this.state.prePropOwn !== ""
                ) {
                  if (this.state.prePropOwn === "Rent") {
                    if (
                      this.state.preLandLord !== "" &&
                      this.state.preRent !== "" &&
                      this.state.preLandLordEmail !== "" &&
                      this.state.preLandLordMobile !== "" &&
                      this.state.preUnitType !== ""
                    ) {
                      this.setState({ nextEnable: true });
                      return true;
                    }
                  } else {
                    this.setState({ nextEnable: true });
                    return true;
                  }
                }
              } else {
                this.setState({ nextEnable: true });
                return true;
              }
            }
          } else {
            if (this.state.preRentalInfo) {
              if (
                this.state.preAddress !== "" &&
                this.state.preState !== "" &&
                this.state.preCity !== "" &&
                this.state.preZip !== "" &&
                this.state.preFrom !== "" &&
                this.checkPeriodNext(this.state.preFrom, this.state.preTo) &&
                this.state.preTo !== "" &&
                this.state.prePropOwn !== ""
              ) {
                if (this.state.prePropOwn === "Rent") {
                  if (
                    this.state.preLandLord !== "" &&
                    this.state.preRent !== "" &&
                    this.state.preLandLordEmail !== "" &&
                    this.state.preLandLordMobile !== "" &&
                    this.state.preUnitType !== ""
                  ) {
                    this.setState({ nextEnable: true });
                    return true;
                  }
                } else {
                  this.setState({ nextEnable: true });
                  return true;
                }
              }
            } else {
              this.setState({ nextEnable: true });
              return true;
            }
          }
        }
      } else if (tab === 3) {
        if (this.state.preEmploymentInfo) {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            this.state.employmentEmail !== "" &&
            this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== "" &&
            this.state.preEmploymentCompany !== "" &&
            this.state.preEmploymentPhone !== "" &&
            this.checkMobileNext(this.state.preEmploymentPhone) &&
            this.checkPeriodNext(
              this.state.preEmploymentFrom,
              this.state.preEmploymentUntil
            ) &&
            this.state.preEmploymentEmail !== "" &&
            this.checkEmailNext(this.state.preEmploymentEmail) &&
            this.state.preEmploymentZip !== "" &&
            this.state.preEmploymentCity !== "" &&
            this.state.preEmploymentState !== "" &&
            this.state.preEmploymentSalary !== "" &&
            this.state.preEmploymentPosition !== "" &&
            this.state.preEmploymentFrom !== "" &&
            this.state.preEmploymentUntil !== ""
          ) {
            this.setState({ nextEnable: true });
            return true;
          }
        } else {
          if (
            this.state.employmentCompany !== "" &&
            this.state.employmentPhone !== "" &&
            this.checkMobileNext(this.state.employmentPhone) &&
            this.checkPeriodNext(
              this.state.employmentFrom,
              this.state.employmentUntil
            ) &&
            this.state.employmentEmail !== "" &&
            this.checkEmailNext(this.state.employmentEmail) &&
            this.state.employmentZip !== "" &&
            this.state.employmentCity !== "" &&
            this.state.employmentState !== "" &&
            this.state.employmentSalary !== "" &&
            this.state.employmentPosition !== "" &&
            this.state.employmentFrom !== "" &&
            this.state.employmentUntil !== ""
          ) {
            this.setState({ nextEnable: true });
            return true;
          }
        }
      } else if (tab === 4 || tab === 5) {
        this.setState({ nextEnable: true });
        return true;
      }
    } else {
      this.setState({ nextEnable: true });
      return true;
    }
    return false;
  }
  finished() {
    this.setState({ isButtonDisableFinish: false });

    if (this.checkTab()) {
      if (
        this.state.stripe_account_status.data === "verified" ||
        this.state.paymentToken !== ""
      ) {
        try {
          let userSample = [
            {
              user: {
                username: this.state.email,
                password: "",
                enabled: false,
                accountNonExpired: true,
                credentialsNonExpired: false,
                accountNonLocked: true,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                middleName: this.state.middleName,
                address: {
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "",
                  lat: 0,
                  lng: 0,
                },
                mobileNumber: this.state.mobile,
                stripe_id: "",
                provider: "local",
                coverImage: "",
              },
              order: 1,
            },
          ];
          let applicantSample = [
            {
              applicant: {
                username: this.state.email,
                drivingLicNo: this.state.drivingNo,
                emergencyNo: this.state.relativeNum,
                ssn: this.state.securityNo,
                dob: this.state.dob,
                otherName: this.state.otherName,
                gender: this.state.applicantGender,
                resPresentAddress: this.state.address,
                resPresentCity: this.state.city,
                resPresentState: this.state.state,
                resPresentZip: this.state.zip,
                resPresentRent: this.state.rent
                  ? parseInt(this.state.rent).toFixed(2)
                  : 0.0,
                resPresentFrom: this.state.from,
                resPresentTo: this.state.to,

                resPresentPhone: this.state.presentHomePhone,
                resPresentFax: this.state.presentHomeFax,

                resPresentOwnRent: this.state.propOwn,
                resPresentLandlord:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.presentLandlord,
                resPresentLandlordEmail:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.landLordEmail,
                resPresentLandlordTp:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.landLordMobile,
                resPresentLandlordFax:
                  this.state.resPresentOwnRent === "Own"
                    ? ""
                    : this.state.landLordFax,
                resPresentApartment:
                  this.state.resPresentOwnRent === "Rent" &&
                  this.state.unitType === "Apartment"
                    ? true
                    : false,
                resPresentUnitType: this.state.unitType,
                resPresentMortgage:
                  this.state.resPresentOwnRent === "Rent" &&
                  this.state.unitType === "Mortgage"
                    ? true
                    : false,
                resPresentReasonLeaving: this.state.presentReasonLeaving,
                resPresentLandlordIDProof: this.state.presentLandlordIDProof,

                resPreviousCity: this.state.preCity,
                resPreviousAddress: this.state.preAddress,
                resPreviousState: this.state.preState,
                resPreviousZip: this.state.preZip,
                resPreviousRent: this.state.preRent
                  ? parseInt(this.state.preRent).toFixed(2)
                  : 0.0,
                resPreviousFrom: this.state.preFrom,
                resPreviousTo: this.state.preTo,
                resPreviousOwnRent: this.state.prePropOwn,
                resPreviousLandlord: this.state.preLandLord,
                resPreviousLandlordEmail: this.state.preLandLordEmail,
                resPreviousLandlordTp: this.state.preLandLordMobile,
                resPreviousLandlordFax: this.state.preLandLordFax,
                resPreviousApartment:
                  this.state.resPreviousOwnRent === "Rent" &&
                  this.state.preUnitType === "Apartment"
                    ? true
                    : false,

                resPreviousUnitType: this.state.preUnitType,
                resPreviousMortgage:
                  this.state.resPreviousOwnRent === "Rent" &&
                  this.state.preUnitType === "Mortgage"
                    ? true
                    : false,
                resPreviousReasonLeaving: "",
                empPresentEmployed: this.state.employmentCompany,
                empPresentEmail: this.state.employmentEmail,
                empPresentAddress: this.state.employmentAddress,
                empPresentCity: this.state.employmentCity,
                empPresentState: this.state.employmentState,
                empPresentZip: this.state.employmentZip,
                empPresentPhone: this.state.employmentPhone,
                empPresentSalary: this.state.employmentSalary
                  ? parseInt(this.state.employmentSalary).toFixed(2)
                  : 0.0,
                empPresentFrom: this.state.employmentFrom,
                empPresentTo: this.state.employmentUntil,
                empPresentPosition: this.state.employmentPosition,

                empPresentAnnualIncome: 0.0,

                empPreviousEmployed:
                  this.state.preEmploymentCompany === ""
                    ? null
                    : this.state.preEmploymentCompany,
                empPreviousEmail: this.state.preEmploymentEmail,
                empPreviousAddress: this.state.preEmploymentAddress,
                empPreviousCity: this.state.preEmploymentCity,
                empPreviousState: this.state.preEmploymentState,
                empPreviousZip: this.state.preEmploymentZip,
                empPreviousPhone: this.state.preEmploymentPhone,
                empPreviousSalary: this.state.preEmploymentSalary
                  ? parseInt(this.state.preEmploymentSalary).toFixed(2)
                  : 0.0,
                empPreviousFrom: this.state.preEmploymentFrom,
                empPreviousTo: this.state.preEmploymentUntil,
                empPreviousPosition: this.state.preEmploymentPosition,
                empPreviousAnnualIncome: 0.0,
                emp2PreviousEmployed: null,
                emp2PreviousEmail: null,
                emp2PreviousAddress: this.state.preEmployment2Address,
                emp2PreviousCity: this.state.preEmployment2City,
                emp2PreviousState: this.state.preEmployment2State,
                emp2PreviousZip: this.state.preEmployment2Zip,
                emp2PreviousPhone: null,
                emp2PreviousSalary: 0.0,
                emp2PreviousFrom: this.state.preEmployment2From,
                emp2PreviousTo: this.state.preEmployment2Until,
                emp2PreviousPosition: this.state.preEmployment2Position,
                emp2PreviousAnnualIncome: 0.0,
                spousesEmployer: this.state.spousesEmployer,
                spousesEmployerAddress: this.state.spousesEmployerAddress,
                spousesIncome: this.state.spousesIncome
                  ? parseInt(this.state.spousesIncome).toFixed(2)
                  : 0.0,
                referenceName: "",
                referenceAddress: "",
                referenceTelephone: "",
                referenceName2: "",
                referenceAddress2: "",
                referenceTelephone2: "",
                emergencyContactName: this.state.emergencyContactName,
                emergencyContactPhone: this.state.emergencyContactPhone,
                emergencyContactFax: this.state.emergencyContactFax,
                emergencyContactRelation: this.state.emergencyContactRelation,
                primaryBankName: "",
                primaryBankBranch: "",
                primaryBankAccType: "",
                primaryBankAccNo: "",
                secondaryBankName: "",
                secondaryBankBranch: "",
                secondaryBankAccType: "",
                secondaryBankAccNo: "",
                relativeNum: this.state.relativeNum,
                relativeName: this.state.relativeName,
                additionalIncome: this.state.additionalIncome,
                bankruptcy: this.state.bankruptcy,
                crimeCheck: this.state.crimeCheck,
                evicted: this.state.evicted,
                smoke: this.state.smoke,
                homePhone: this.state.workingNo,
                resPresentYears: betweenYears(this.state.from, this.state.to),
                resPresentMonths: betweenMonths(this.state.from, this.state.to),
                resPreviousYear: betweenYears(
                  this.state.preFrom,
                  this.state.preTo
                ),
                resPreviousMonth: betweenMonths(
                  this.state.preFrom,
                  this.state.preTo
                ),

                empPresentYears: betweenYears(
                  this.state.employmentFrom,
                  this.state.employmentUntil
                ),
                empPresentMonths: betweenExtraMonths(
                  this.state.employmentFrom,
                  this.state.employmentUntil
                ),
                empPreviousYear: betweenYears(
                  this.state.preEmploymentFrom,
                  this.state.preEmploymentUntil
                ),
                empPreviousMonth: betweenExtraMonths(
                  this.state.preEmploymentFrom,
                  this.state.preEmploymentUntil
                ),
                vehicle: this.state.vehicles === "" ? false : true,
                vehicleType: this.state.vehicles,
                vehicleMadeBy: "",
                vehicleModel: "",
                vehicleYear: "",
                vehicleLicenseNumber: "",
                // createdAt: "2021-09-17T17:45:05.546+0000",
                // updatedAt: "2021-09-17T17:45:05.546+0000",
              },
              applicantOrder: 1,
            },
          ];
          if (this.state.joinTenantCount === 1) {
            userSample.push({
              user: {
                username: this.state.joinTenant1,
                password: "",
                enabled: false,
                accountNonExpired: true,
                credentialsNonExpired: false,
                accountNonLocked: true,
                firstName: "",
                lastName: "",
                middleName: "",
                address: {
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "",
                  lat: 0,
                  lng: 0,
                },
                mobileNumber: "",
                stripe_id: "",
                provider: "local",
                coverImage: "",
              },
              order: 2,
            });
            applicantSample.push({
              applicant: {
                username: this.state.joinTenant1,
                apply: null,
                drivingLicNo: 0.0,
                emergencyNo: null,
                ssn: 0.0,
                dob: null,
                otherName: null,
                gender: "",
                resPresentAddress: "",
                resPresentCity: "",
                resPresentState: "",
                resPresentZip: "",
                resPresentRent: 0.0,
                resPresentFrom: "",
                resPresentTo: "",
                resPresentPhone: "",
                resPresentFax: "",
                resPresentOwnRent: 0.0,
                resPresentLandlord: "",
                resPresentLandlordEmail: "",
                resPresentLandlordTp: "",
                resPresentLandlordFax: null,
                resPresentApartment: null,
                resPresentUnitType: "",
                resPresentMortgage: false,
                resPresentReasonLeaving: "",
                resPreviousAddress: "",
                resPreviousCity: "",
                resPreviousState: "",
                resPreviousZip: "",
                resPreviousRent: 0.0,
                resPreviousFrom: "",
                resPreviousTo: "",
                resPreviousOwnRent: 0.0,
                resPreviousLandlordEmail: null,
                resPreviousLandlordTp: "",
                resPreviousLandlordFax: null,
                resPreviousApartment: null,
                resPreviousUnitType: "",
                resPreviousMortgage: false,
                resPreviousReasonLeaving: "",
                empPresentEmployed: null,
                empPresentEmail: null,
                empPresentAddress: "",
                empPresentCity: "",
                empPresentState: "",
                empPresentZip: "",
                empPresentPhone: "",
                empPresentSalary: 0.0,
                empPresentFrom: "",
                empPresentTo: "",
                empPresentPosition: "",
                empPresentAnnualIncome: 0.0,

                empPreviousEmployed: null,
                empPreviousEmail: null,
                empPreviousAddress: "",
                empPreviousCity: "",
                empPreviousState: "",
                empPreviousZip: "",
                empPreviousPhone: null,
                empPreviousSalary: 0.0,
                empPreviousFrom: "",
                empPreviousTo: "",
                empPreviousPosition: "",
                empPreviousAnnualIncome: 0.0,
                emp2PreviousEmployed: null,
                emp2PreviousEmail: null,
                emp2PreviousAddress: "",
                emp2PreviousCity: "",
                emp2PreviousState: "",
                emp2PreviousZip: "",
                emp2PreviousPhone: null,
                emp2PreviousSalary: 0.0,
                emp2PreviousFrom: "",
                emp2PreviousTo: "",
                emp2PreviousPosition: "",
                emp2PreviousAnnualIncome: 0.0,
                spousesEmployer: "",
                spousesEmployerAddress: "",
                spousesIncome: 0.0,
                referenceName: "",
                referenceAddress: "",
                referenceTelephone: "",
                referenceName2: "",
                referenceAddress2: "",
                referenceTelephone2: "",
                emergencyContactName: "",
                emergencyContactPhone: "",
                emergencyContactFax: "",
                emergencyContactRelation: "",
                primaryBankName: "",
                primaryBankBranch: "",
                primaryBankAccType: "",
                primaryBankAccNo: "",
                secondaryBankName: "",
                secondaryBankBranch: "",
                secondaryBankAccType: "",
                secondaryBankAccNo: "",
                relativeNum: null,
                relativeName: null,
                additionalIncome: null,
                bankruptcy: false,
                crimeCheck: false,
                evicted: false,
                smoke: false,
                homePhone: null,
                resPresentYears: null,
                resPresentMonths: null,
                resPreviousYear: null,
                resPreviousMonth: null,
                empPresentYears: null,
                empPresentMonths: null,
                empPreviousYear: null,
                empPreviousMonth: null,
                vehicle: "",
                vehicleType: null,
                vehicleMadeBy: "",
                vehicleModel: "",
                vehicleYear: "",
                vehicleLicenseNumber: "",
              },
              applicantOrder: 2,
            });
          }
          if (this.state.joinTenantCount === 2) {
            userSample.push({
              user: {
                username: this.state.joinTenant2,
                password: "",
                enabled: false,
                accountNonExpired: true,
                credentialsNonExpired: false,
                accountNonLocked: true,
                firstName: "",
                lastName: "",
                middleName: "",
                address: {
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "",
                  lat: 0,
                  lng: 0,
                },
                mobileNumber: "",
                stripe_id: "",
                provider: "local",
                coverImage: "",
              },
              order: 3,
            });
            applicantSample.push({
              applicant: {
                username: this.state.joinTenant1,
                apply: null,
                drivingLicNo: 0.0,
                emergencyNo: null,
                ssn: 0.0,
                dob: null,
                otherName: null,
                gender: "",
                resPresentAddress: "",
                resPresentCity: "",
                resPresentState: "",
                resPresentZip: "",
                resPresentRent: 0.0,
                resPresentFrom: "",
                resPresentTo: "",
                resPresentPhone: "",
                resPresentFax: "",
                resPresentOwnRent: 0.0,
                resPresentLandlord: "",
                resPresentLandlordEmail: "",
                resPresentLandlordTp: "",
                resPresentLandlordFax: null,
                resPresentApartment: null,
                resPresentUnitType: "",
                resPresentMortgage: false,
                resPresentReasonLeaving: "",
                resPreviousAddress: "",
                resPreviousCity: "",
                resPreviousState: "",
                resPreviousZip: "",
                resPreviousRent: 0.0,
                resPreviousFrom: "",
                resPreviousTo: "",
                resPreviousOwnRent: 0.0,
                resPreviousLandlordEmail: null,
                resPreviousLandlordTp: "",
                resPreviousLandlordFax: null,
                resPreviousApartment: null,
                resPreviousUnitType: "",
                resPreviousMortgage: false,
                resPreviousReasonLeaving: "",
                empPresentEmployed: null,
                empPresentEmail: null,
                empPresentAddress: "",
                empPresentCity: "",
                empPresentState: "",
                empPresentZip: "",
                empPresentPhone: "",
                empPresentSalary: 0.0,
                empPresentFrom: "",
                empPresentTo: "",
                empPresentPosition: "",
                empPresentAnnualIncome: 0.0,

                empPreviousEmployed: null,
                empPreviousEmail: null,
                empPreviousAddress: "",
                empPreviousCity: "",
                empPreviousState: "",
                empPreviousZip: "",
                empPreviousPhone: null,
                empPreviousSalary: 0.0,
                empPreviousFrom: "",
                empPreviousTo: "",
                empPreviousPosition: "",
                empPreviousAnnualIncome: 0.0,
                emp2PreviousEmployed: null,
                emp2PreviousEmail: null,
                emp2PreviousAddress: "",
                emp2PreviousCity: "",
                emp2PreviousState: "",
                emp2PreviousZip: "",
                emp2PreviousPhone: null,
                emp2PreviousSalary: 0.0,
                emp2PreviousFrom: "",
                emp2PreviousTo: "",
                emp2PreviousPosition: "",
                emp2PreviousAnnualIncome: 0.0,
                spousesEmployer: "",
                spousesEmployerAddress: "",
                spousesIncome: 0.0,
                referenceName: "",
                referenceAddress: "",
                referenceTelephone: "",
                referenceName2: "",
                referenceAddress2: "",
                referenceTelephone2: "",
                emergencyContactName: "",
                emergencyContactPhone: "",
                emergencyContactFax: "",
                emergencyContactRelation: "",
                primaryBankName: "",
                primaryBankBranch: "",
                primaryBankAccType: "",
                primaryBankAccNo: "",
                secondaryBankName: "",
                secondaryBankBranch: "",
                secondaryBankAccType: "",
                secondaryBankAccNo: "",
                relativeNum: null,
                relativeName: null,
                additionalIncome: null,
                bankruptcy: false,
                crimeCheck: false,
                evicted: false,
                smoke: false,
                homePhone: null,
                resPresentYears: null,
                resPresentMonths: null,
                resPreviousYear: null,
                resPreviousMonth: null,
                empPresentYears: null,
                empPresentMonths: null,
                empPreviousYear: null,
                empPreviousMonth: null,
                vehicle: "",
                vehicleType: null,
                vehicleMadeBy: "",
                vehicleModel: "",
                vehicleYear: "",
                vehicleLicenseNumber: "",
              },
              applicantOrder: 3,
            });
          }
          let body = {
            users: userSample,
            application: {
              apply: applicantSample,
              occupants: [],
              rentalEntityId: this.state.rentalUnit,
              rent: this.props.history.location.state
                ? this.props.history.location.state.rent
                : 0.0,
              pets: this.state.pet === "true" ? true : false,
              petCount: this.state.pet === "true" ? this.state.petCount : 0,
              creditCheck: null,
              securityCheck: null,
              applicationForm: null,
              occupant: this.state.occupantsArray
                ? this.state.occupantsArray.toString()
                : "",
              leaseTerm: parseInt(this.state.leaseTerm),
              moveInDate: this.state.moveInDate,
              hydro: false,
              gas: false,
              heat: false,
              hotWaterHeater: false,
              hotWaterTank: false,
              internet: false,
              cableTv: false,
              other: false,
              water: false,
              status: null,
              totalOccupants: this.state.occupant
                ? parseInt(this.state.occupant)
                : "",
            },
            applicationFeeModel: {},
            token: "tok_visa",
            signature: true,
            file: this.state.signatureObject2,
          };
          if (this.props.role === "Guest") {
            let body_guest = {
              apartmentData: this.state.apartmentData,

              application: {
                apply: applicantSample,
                occupants: null,
                rentalEntityId: this.state.rentalUnit,
                rent: this.state.apartmentData
                  ? this.state.apartmentData.price
                  : "",
                pets: this.state.pet === "true" ? true : false,
                petCount: this.state.pet === "true" ? this.state.petCount : 0,
                creditCheck: null,
                securityCheck: null,
                applicationForm: null,
                occupant: this.state.occupantsArray
                  ? this.state.occupantsArray.toString()
                  : "",
                leaseTerm: parseInt(this.state.leaseTerm),
                moveInDate: this.state.moveInDate,
                hydro: false,
                gas: false,
                heat: false,
                hotWaterHeater: false,
                hotWaterTank: false,
                internet: false,
                cableTv: false,
                other: false,
                water: false,
                status: null,
                totalOccupants: this.state.occupant
                  ? parseInt(this.state.occupant)
                  : "",
              },
              user: userSample,
              applicationFeeModel: {
                // amount: 4000,
                // userName: this.state.email,
                // customerId: "cus_JFJjwfszSGfEQn",
                // contractId: 0,
                // applicationId: null,
              },
              token: this.state.paymentToken.id,
              signature: true,
              file: this.state.signatureObject2,
            };

            addPaymentMethodGuest(body_guest)
              .then((response) => {
                this.setState({ isButtonDisableFinish: true });
                if (response.status === 200) {
                  window.onbeforeunload = "";
                  if (response.status) {
                    let message = response.data.data;
                    this.props.appendAlert(
                      "Application Sent Successfully",
                      "success"
                    );

                    if (message === "Already Applied") {
                      this.props.appendAlert(
                        String("This application is" + message),
                        "warning"
                      );
                    } else {
                      if (parseInt(this.state.applicationFee) > 0) {
                        this.props.appendAlert("Paid Successfully", "success");
                      }
                    }

                    this.props.history.push({ pathname: "/applications" });
                  }
                } else if (response.response.status === 500) {
                  if (
                    response.response.data.message === "No such token: 'null'"
                  ) {
                    this.props.appendAlert("please try again", "warning");
                  }
                  this.props.appendAlert(
                    "Application creation failed",
                    "danger"
                  );
                } else if (response.response.status === 402) {
                  this.changeTokenNull();
                  this.setState({ nowTab: 4 });
                  this.props.appendAlert(response.response.data.message);
                } else {
                  this.props.appendAlert(
                    "Application creation failed ",
                    "danger"
                  );
                }
              })
              .catch((error) => {
                this.setState({ isButtonDisableFinish: true });
                console.log("Error" + error);
              });
          } else {
            setWashingtonApplicationV2(body).then((response) => {
              this.setState({ isButtonDisableFinish: true });

              if (response.status === 200) {
                window.onbeforeunload = "";
                if (response.status) {
                  let message = response.data.data;
                  this.props.appendAlert(
                    "Application Sent Successfully",
                    "success"
                  );
                  if (message === "Already Applied") {
                    this.props.appendAlert(
                      String("This application is" + message, "warning")
                    );
                  } else {
                    if (parseInt(this.state.applicationFee) > 0) {
                      this.props.appendAlert("Paid Successfully", "success");
                    }
                  }

                  this.props.history.push({ pathname: "/applications" });
                }
              } else if (response.response.status === 500) {
                if (
                  response.response.data.message === "No such token: 'null'"
                ) {
                  this.props.appendAlert("please try again", "warning");
                }
                this.props.appendAlert("Application creation failed", "danger");
              } else if (response.response.status === 402) {
                this.setPaymentToken();
                this.setState({ nowTab: 4 });
                this.props.appendAlert(
                  response.response.data.message,
                  "warning"
                );
              } else {
                this.props.appendAlert("Application creation failed", "danger");
              }
            });
          }
        } catch (error) {}
      } else {
        this.props.appendAlert("Please add card", "success");
        this.setState({ nowTab: this.state.nowTab - 1 });
      }
    }
  }

  back() {
    if (this.state.nowTab > 0) {
      window.scrollTo(0, 0);
      if (!this.nextCheck(this.state.nowTab - 1)) {
        this.setState({ nextEnable: false });
      }
      this.setState({ nowTab: this.state.nowTab - 1 });
    }
    if (this.state.nowTab === 2) {
      this.setState({ mailCheck: false });
    }
    if (this.state.nowTab === 5) {
      window.scrollTo(0, 0);

      if (
        this.state.stripe_account_status.data === "verified"
        // this.state.paymentToken !== ""
      ) {
        if (!this.nextCheck(this.state.nowTab - 2)) {
          this.setState({ nextEnable: false });
        }
        this.setState({ nowTab: this.state.nowTab - 2 });
      } else {
        if (!this.nextCheck(this.state.nowTab - 1)) {
          this.setState({ nextEnable: false });
        }
        this.setState({ nowTab: this.state.nowTab - 1 });
      }
    }
  }

  async onchange(e) {
    let { name, value } = e.target;

    if (name === "occupant") {
      this.getOccupant(this.state.occupant, value);
      await this.setState({ [name]: value });
    } else if (
      name === "mobile" ||
      name === "workingNo" ||
      name === "relativeNum" ||
      name === "employmentPhone" ||
      name === "preEmploymentPhone" ||
      name === "landLordMobile" ||
      name === "preLandLordMobile"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (
      name === "firstName" ||
      name === "middleName" ||
      name === "lastName" ||
      name === "otherName" ||
      name === "relativeName" ||
      name === "presentLandlord" ||
      name === "preLandLord" ||
      name === "occupant1" ||
      name === "occupant2" ||
      name === "occupant3" ||
      name === "occupant3" ||
      name === "occupant4" ||
      name === "occupant5" ||
      name === "occupant6" ||
      name === "occupant7" ||
      name === "occupant8" ||
      name === "occupant9" ||
      name === "occupant10" ||
      name === "occupant11" ||
      name === "occupant12" ||
      name === "occupant12" ||
      name === "occupant13" ||
      name === "occupant14" ||
      name === "city" ||
      name === "country" ||
      name === "state" ||
      name === "preCity" ||
      name === "preState" ||
      name === "preCountry" ||
      name === "employmentCity" ||
      name === "employmentState" ||
      name === "employmentCountry" ||
      name === "preEmploymentCity" ||
      name === "preEmploymentState" ||
      name === "preEmploymentCountry"
    ) {
      await this.setState({
        [name]: value.replace(/[^a-zA-Z/\s/g\s,\s.\s-]+/g, ""),
      });
    } else if (name === "state") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    } else if (name === "country") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateList: stateList });
        this.setState({ state: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state: "" });
        this.setState({ city: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
      }
    } else if (name === "preState") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.preStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preCityList: cityList });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preCity: "" });
        this.setState({ preCityList: [] });
      }
    } else if (name === "preCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.preCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preStateList: stateList });
        this.setState({ preState: "" });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preState: "" });
        this.setState({ preCity: "" });
        this.setState({ preStateList: [] });
        this.setState({ preCityList: [] });
      }
    } else if (name === "employmentState") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.employmentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ employmentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ employmentCityList: cityList });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentCity: "" });
        this.setState({ employmentCityList: [] });
      }
    } else if (name === "employmentCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.employmentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ employmentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ employmentStateList: stateList });
        this.setState({ employmentState: "" });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentState: "" });
        this.setState({ employmentCity: "" });
        this.setState({ employmentStateList: [] });
        this.setState({ employmentCityList: [] });
      }
    } else if (name === "preEmploymentState") {
      await this.setState({ [name]: value });
      let selectedCities = this.state.preEmploymentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preEmploymentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preEmploymentCityList: cityList });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentCity: "" });
        this.setState({ preEmploymentCityList: [] });
      }
    } else if (name === "preEmploymentCountry") {
      await this.setState({ [name]: value });
      let selectedCountry = this.state.preEmploymentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preEmploymentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preEmploymentStateList: stateList });
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentCity: "" });
        this.setState({ preEmploymentStateList: [] });
        this.setState({ preEmploymentCityList: [] });
      }
    } else if (name === "propOwn") {
      this.setState({
        presentLandlord: "",
        landLordEmail: "",
        rent: "",
        landLordMobile: "",
        landLordFax: "",
        unitType: "",
        next: false,
      });
      await this.setState({ [name]: value });
    } else if (name === "prePropOwn") {
      this.setState({
        preLandLord: "",
        preLandLordEmail: "",
        preRent: "",
        preLandLordMobile: "",
        preLandLordFax: "",
        preUnitType: "",
        next: false,
      });
      await this.setState({ [name]: value });
    } else if (name === "securityNo") {
      let sin = value.replace(/[^\d]/g, "");
      value = sin;
      if (sin.length > 5) {
        value = sin.replace(/(\d{3})(\d{2})/, "$1 $2 ");
      } else if (sin.length > 3) {
        value = sin.replace(/(\d{3})/, "$1 ");
      }
      if (sin.length > 9) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
    this.nextCheck(this.state.nowTab);
  }
  async changeState(name, value) {
    await this.setState({ [name]: value });
    if (name === "state") {
      let selectedCities = this.state.stateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ cityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ cityList: cityList });
        this.setState({ city: "" });
      } else {
        this.setState({ city: "" });
        this.setState({ cityList: [] });
      }
    } else if (name === "preState") {
      let selectedCities = this.state.preStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preCityList: cityList });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preCity: "" });
        this.setState({ preCityList: [] });
      }
    } else if (name === "country") {
      let selectedCountry = this.state.countryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ stateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ stateList: stateList });
        this.setState({ state: "" });
        this.setState({ city: "" });
      } else {
        this.setState({ state: "" });
        this.setState({ stateList: [] });
        this.setState({ cityList: [] });
        this.setState({ city: "" });
      }
    } else if (name === "preCountry") {
      let selectedCountry = this.state.preCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preStateList: stateList });
        this.setState({ preState: "" });
        this.setState({ preCity: "" });
      } else {
        this.setState({ preState: "" });
        this.setState({ preStateList: [] });
        this.setState({ preCityList: [] });
        this.setState({ preCity: "" });
      }
    } else if (name === "employmentState") {
      let selectedCities = this.state.employmentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ employmentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ employmentCityList: cityList });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentCity: "" });
        this.setState({ employmentCityList: [] });
      }
    } else if (name === "preEmploymentState") {
      let selectedCities = this.state.preEmploymentStateJson.filter(
        (state) =>
          state.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let cityList = [];
      if (selectedCities.length !== 0) {
        this.setState({ preEmploymentCityJson: selectedCities[0].cities });
        selectedCities[0].cities.map((data) => cityList.push(data.name));
        this.setState({ preEmploymentCityList: cityList });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentCity: "" });
        this.setState({ preEmploymentCityList: [] });
      }
    } else if (name === "employmentCountry") {
      let selectedCountry = this.state.employmentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ employmentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ employmentStateList: stateList });
        this.setState({ employmentState: "" });
        this.setState({ employmentCity: "" });
      } else {
        this.setState({ employmentState: "" });
        this.setState({ employmentStateList: [] });
        this.setState({ employmentCityList: [] });
        this.setState({ employmentCity: "" });
      }
    } else if (name === "preEmploymentCountry") {
      let selectedCountry = this.state.preEmploymentCountryJson.filter(
        (country) =>
          country.name.replace(/\s/g, "").toLowerCase() ===
          value.replace(/\s/g, "").toLowerCase()
      );
      let stateList = [];
      if (selectedCountry.length !== 0) {
        this.setState({ preEmploymentStateJson: selectedCountry[0].states });
        selectedCountry[0].states.map((data) => stateList.push(data.name));
        this.setState({ preEmploymentStateList: stateList });
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentCity: "" });
      } else {
        this.setState({ preEmploymentState: "" });
        this.setState({ preEmploymentStateList: [] });
        this.setState({ preEmploymentCityList: [] });
        this.setState({ preEmploymentCity: "" });
      }
    }
    this.nextCheck(this.state.nowTab);
  }
  async onchangeState(e) {
    let { name, value } = e.target;
    if (
      name === "mobile" ||
      name === "workingNo" ||
      name === "relativeNum" ||
      name === "employmentPhone" ||
      name === "preEmploymentPhone" ||
      name === "landLordMobile" ||
      name === "preLandLordMobile"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
    this.nextCheck(this.state.nowTab);
  }
  checkEmail(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (
      (!re.test(String(value).toLowerCase()) || value === "") && this.state.next
    );
  }

  checkEmailNext(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return !(!re.test(String(value).toLowerCase()) || value === "");
  }

  checkPeriod(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);

    return (
      (date2.getTime() - date1.getTime() <= 0 || d1 === "" || d2 === "") &&
      this.state.next
    );
  }
  checkPeriodNext(d1, d2) {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    if (d1 === "" || d2 === "") {
      return true;
    }
    return date2.getTime() - date1.getTime() > 0;
  }
  checkMobile(value) {
    return (value.length !== 14 || value === "") && this.state.next;
  }
  checkMobileNext(value) {
    return value.length === 14;
  }
  getOccupant(occ, val) {
    let x = [];
    for (let i = 1; i < parseInt(val); i++) {
      x.push("occupant" + i);
    }
    for (let j = 1; j < parseInt(occ); j++) {
      this.setState({ ["occupant" + j]: "" });
    }
    this.setState({ occupantArray: x });
  }
  checkSIN(value) {
    return (value.length !== 11 || value === "") && this.state.next;
  }
  checkSINNext(value) {
    return value.length === 11;
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  async onChangeParent(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  render() {
    return (
      <div
        className="container-fluid"
        style={{ minHeight: window.screen.height }}
      >
        {this.state.nowTab !== 0 ? (
          <>
            <div className="row stepFormNav">
              <div
                className="col text-center pointer"
                onClick={() => this.clickStep(1)}
              >
                <div className="row">
                  <div className="col SideLineHide"></div>
                  <div className="col">
                    <div
                      className={
                        this.state.nowTab !== 0
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Basic"
                    >
                      <div className="social ">
                        <i
                          className="fa fa-address-card-o "
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Basic"
                    >
                      step 1
                    </span>
                    <br></br>
                  </div>
                  <div
                    className={
                      this.state.nowTab > 1
                        ? "col  SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                </div>
              </div>
              <div
                className="col text-center pointer"
                onClick={() => this.clickStep(2)}
              >
                <div className="row">
                  <div
                    className={
                      this.state.nowTab > 1
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                  <div className="col">
                    <div
                      className={
                        this.state.nowTab > 1
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Residence History"
                    >
                      <div className="social ">
                        <i
                          className="fa fa-address-book "
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Residence History"
                    >
                      step 2
                    </span>
                    <br></br>
                  </div>
                  <div
                    className={
                      this.state.nowTab > 2
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                </div>
              </div>

              <div
                className="col text-center pointer"
                onClick={() => this.clickStep(3)}
              >
                <div className="row">
                  <div
                    className={
                      this.state.nowTab > 2
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                  <div className="col ">
                    <div
                      className={
                        this.state.nowTab > 2
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Employment Data"
                    >
                      <div className="social ">
                        <i className="fa fa-user" aria-hidden="true"></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Employment Data"
                    >
                      step 3
                    </span>
                    <br></br>
                  </div>
                  <div
                    className={
                      this.state.nowTab > 3
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>
                </div>
              </div>
              {this.state.stripe_account_status.data !== "verified" && (
                <div
                  className="col text-center pointer"
                  onClick={() => this.clickStep(4)}
                >
                  <div className="row">
                    <div
                      className={
                        this.state.nowTab > 3
                          ? "col SideLine activeLine"
                          : "col SideLine"
                      }
                    ></div>
                    <div className="col ">
                      <div
                        className={
                          this.state.nowTab > 3
                            ? "social-container-step activeStep"
                            : "social-container-step"
                        }
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Payment Method"
                      >
                        <div className="social ">
                          <i
                            className="fa fa-credit-card-alt"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <br></br>
                      <span
                        className="stepSpan"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Payment Method"
                      >
                        step 4
                      </span>
                      <br></br>
                    </div>
                    <div
                      className={
                        this.state.nowTab > 4
                          ? "col SideLine activeLine"
                          : "col SideLine"
                      }
                    ></div>
                  </div>
                </div>
              )}
              <div
                className="col text-center pointer"
                onClick={() =>
                  this.clickStep(
                    this.state.stripe_account_status.data === "verified" ? 4 : 5
                  )
                }
              >
                <div className="row">
                  <div
                    className={
                      this.state.nowTab > 4
                        ? "col SideLine activeLine"
                        : "col SideLine"
                    }
                  ></div>

                  <div className="col">
                    <div
                      className={
                        this.state.nowTab > 4
                          ? "social-container-step activeStep"
                          : "social-container-step"
                      }
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Review"
                    >
                      <div className="social">
                        <i className="fa fa-retweet " aria-hidden="true"></i>
                      </div>
                    </div>
                    <br></br>
                    <span
                      className="stepSpan"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Review"
                    >
                      step{" "}
                      {this.state.stripe_account_status.data === "verified"
                        ? "4"
                        : "5"}
                    </span>
                    <br></br>
                  </div>
                  <div className="col SideLineHide"></div>
                </div>
              </div>
            </div>
            <div className="row stepFormNavTitle titleStep">
              <div
                className="col d-flex justify-content-center text-center pointer"
                onClick={() => this.clickStep(1)}
              >
                {" "}
                <p className="stepTitle titleStep"> Basic</p>
              </div>
              <div
                className="col d-flex justify-content-center text-center pointer"
                onClick={() => this.clickStep(2)}
              >
                {" "}
                <p className="stepTitle titleStep">Residence History</p>
              </div>
              <div
                className="col d-flex justify-content-center text-center pointer"
                onClick={() => this.clickStep(3)}
              >
                {" "}
                <p className="stepTitle titleStep">Employment Data</p>
              </div>
              {this.state.stripe_account_status.data !== "verified" && (
                <div
                  className="col d-flex justify-content-center text-center  pointer"
                  onClick={() => this.clickStep(4)}
                >
                  {" "}
                  <p className="stepTitle titleStep">Payment Method</p>
                </div>
              )}
              <div
                className="col d-flex justify-content-center text-center  pointer"
                onClick={() =>
                  this.clickStep(
                    this.state.stripe_account_status.data === "verified" ? 4 : 5
                  )
                }
              >
                {" "}
                <p className="stepTitle titleStep">Review</p>
              </div>
            </div>
            <div className="row stepFormNavTitle pt-5 titleStepOpp">
              <div className="col d-flex justify-content-center text-center">
                <p className="stepTitle ">
                  {this.state.nowTab === 1
                    ? "Basic"
                    : this.state.nowTab === 2
                    ? "Residence History"
                    : this.state.nowTab === 3
                    ? "Employment Data"
                    : this.state.nowTab === 4 &&
                      this.state.stripe_account_status.data !== "verified"
                    ? "Payment Method"
                    : this.state.nowTab === 4 &&
                      this.state.stripe_account_status.data === "verified"
                    ? "Review"
                    : this.state.nowTab === 5
                    ? "Review"
                    : ""}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <br></br>
            <br></br>
            <br></br>
          </>
        )}
        {this.state.nowTab === 0 && (
          <>
            <div className="">
              <div
                className="cardApplication pb-5 pt-3 "
                style={{ paddingRight: "5%", paddingLeft: "5%" }}
              >
                <div className="row mt-3">
                  <div className="col">
                    <p className="axTextBold pb-0 mt-0">
                      Requirements to Be Approved Through Axallant Corporation
                    </p>
                  </div>
                </div>

                <div className="row pt-2 pb-2">
                  <div className="col">
                    <span className="span8">
                      We do not accept comprehensive reusable tenant screening
                      reports as defined by and pursuant to RCW 59.18.
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <span className="span8">
                      Axallant Corporation is prohibited from requiring
                      disclosure, asking about, rejecting an applicant, or
                      taking adverse action based on any arrest record,
                      conviction record,or criminal history, except for registry
                      information as described in subsections 14.09.025A.3,
                      14.09.025.A.4, and 14.09.025.A.5, and subject to the
                      exclusions and legal requirements in Section 14.09.115:We
                      are prohibited from taking an adverse action against a
                      tenant based on eviction history during or within six
                      months after the end of the civil emergency proclaimed by
                      Mayor Durkan on March 3, 2021. The Seattle Office for
                      Civil Rights is the department that will enforce any
                      violations of this ordinance.
                    </span>
                  </div>
                </div>
                <a
                  className="pb-0 mb-0 axText"
                  href="http://www.seattle.gov/Documents/Departments/RentingInSeattle/languages/English/RentersHandbook_English.pdf"
                >
                  Please click here to access your copy of the Seattle Renter's
                  Handbook.
                </a>
                <p className="axTextBold pb-0 mb-0">
                  <i>
                    The following types of information will be accessed to
                    conduct the tenant screening: credit history, credit score,
                    employment history, eviction history, rental history, and
                    sex offender status.
                  </i>
                </p>
                <p className="axTextBold pt-0">
                  When evaluating your report for tenant screening purposes, the
                  following minimum requirements for tenancy, along with items
                  which may result in the denial of your tenancy, are outlined
                  below.
                </p>
                <p className="span8 pt-0 mt-0 mb-0 pb-3">
                  <b className="axTextBold">Income:</b> &nbsp; &nbsp;Minimum
                  requirement
                </p>
                <ul className="span8">
                  <li>
                    History of acceptable level of income must cover the
                    previous 24 months.
                  </li>
                  <li>Rent to income ratio: 1 to 2.5.</li>
                  <li>Debt to income ratio: .20 to 1.</li>
                  <li>
                    Verification of income such as check stubs for the past two
                    pay periods, SSI/disability papers, child support papers,
                    etc.
                  </li>
                  <li>
                    Self-employed must provide last two years tax returns and 3
                    months of current bank statements.
                  </li>
                  <li>
                    Full-time students must provide written verification of
                    financial aid, parental support, scholarships, and/or
                    supplemental savings.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Income:</b> &nbsp; &nbsp;Criteria
                  that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>
                    Lack of acceptable level of income over the previous 24
                    months.
                  </li>
                  <li>Rent to income ratio below: 1 to 2.5.</li>
                  <li>Debt to income ratio above: .20 to 1.</li>
                  <li>
                    Lack of documentation to verify income such as check stubs
                    for the past two pay periods, SSI/disability papers, child
                    support papers, etc.
                  </li>
                  <li>
                    Self-employed unable to provide last two years tax returns
                    and 3 months of current bank statements.
                  </li>
                  <li>
                    Full-time students unable to provide written verification of
                    financial aid, parental support, scholarships, and/or
                    supplemental savings. Co-signer may be required.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Employment History:</b> &nbsp;
                  &nbsp;Minimum requirement
                </p>
                <ul className="span8">
                  <li>
                    For employment verification, you must provide a copy of your
                    2 most recent pay stubs and/or copies of government, pension
                    or valid program funding.
                  </li>
                  <li>
                    If self-employed, you must provide signed copies of the last
                    two years tax returns and the last 3 months bank statements.
                  </li>
                  <li>
                    If you do no have enough income to rent the property, you
                    must specify how you intend to pay and provide evidence of
                    the funds (i.e. bank statements.)
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Employment History:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>
                    Unable to provide a copy of your 2 most recent pay stubs
                    and/or copies of government, pension, or valid program
                    funding.
                  </li>
                  <li>
                    If self-employed, inability to provide signed copies of the
                    last two years tax returns and the last 3 months bank
                    statements.
                  </li>
                  <li>
                    Unable to provide documentation to support how you intend to
                    pay and provide evidence of the funds (i.e. bank
                    statements.)
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Credit History:</b> &nbsp;
                  &nbsp;Minimum requirement
                </p>
                <ul className="span8">
                  <li>
                    No more than 2 delinquent payments over the last 24 months.
                  </li>
                  <li>No accounts in collection.</li>
                  <li>No open bankruptcies.</li>
                  <li>No money owing to a previous landlord.</li>
                  <li>Credit score above 650.</li>
                  <li>
                    Must have two active accounts being rated to establish
                    history.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Credit History:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>
                    More than 2 delinquent payments over the last 24 months.
                  </li>
                  <li>Accounts in collection.</li>
                  <li>Non-discharged bankruptcies.</li>
                  <li>Money owing to a previous landlord.</li>
                  <li>Credit score less than 650.</li>
                  <li>
                    Less than two active accounts being rated to establish
                    history.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Landlord References:</b> &nbsp;
                  &nbsp;Minimum requirements.
                </p>
                <ul className="span8">
                  <li>
                    Each rental applicant must be able to demonstrate a pattern
                    of meeting their rental obligations, leaving prior rental
                    properties in good condition and not having a pattern of
                    complaints from neighbors.
                  </li>
                  <li>
                    Verifiable rental references for positive rental or mortgage
                    history over the last 24 months from unbiased sources; no
                    roommate or family references. Please note: you must provide
                    contact information for the previous landlord and the
                    previous landlord must take or return our screening calls
                    within 72 hours. If 72 hours is not enough time, please
                    notify us.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Landlord References:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my
                  application.
                </p>
                <ul className="span8">
                  <li>
                    Each rental applicant unable to demonstrate a pattern of
                    meeting their rental obligations, leaving prior rental
                    properties in poor condition or having a pattern of
                    complaints from neighbors.
                  </li>
                  <li>
                    No verifiable references for positive rental or mortgage
                    history over the last 24 months from unbiased sources; no
                    roommate or family references.
                  </li>
                  <li>
                    No contact information for previous landlord or no returned
                    phone calls from the landlord within 72 hours of application
                    submission. If 72 hours is not enough time, please notify us
                    if additional time is needed.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Other Factors:</b> &nbsp;
                  &nbsp;Minimum requirements
                </p>
                <ul className="span8">
                  <li>
                    Applications must be filled out completely and truthfully.
                  </li>
                  <li>
                    Applicant should be on time for showing appointment and act
                    respectfully toward residents and leasing staff.
                  </li>
                  <li>
                    Applicant should be able to pay complete reservation deposit
                    at time or application and any rent due at time of lease
                    signing.
                  </li>
                  <li>
                    Each person 18 and older who will be residing in the home
                    must be screened.
                  </li>
                  <li>Copy of current state or government identification.</li>
                  <li>
                    Out of state applicants with no local employer must provide
                    current bank statement with balance of 5x the rent.
                  </li>
                  <li>
                    International students must provide passport, proof of
                    current college or university full-time enrollment or
                    current student visa along with proof of funding (such as an
                    I-20) and/or supplemental savings.
                  </li>
                  <li>
                    Other applicants who are new to the country must provide
                    passport and proof of income (such as a letter of
                    employment), and/or documentation of supplemental savings.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Other Factors:</b> &nbsp;
                  &nbsp;Criteria that may result in the denial of my application
                </p>
                <ul className="span8">
                  <li>Application not filled out completed and truthfully.</li>
                  <li>
                    Incomplete or inaccurate applications cannot be processed.
                  </li>
                  <li>
                    Unfavorable information for any individual applicant may
                    result in denial of all applications for that group.
                  </li>
                  <li>
                    Applicants who do not show up on time for appointment and
                    act disrespectfully toward residents and leasing staff.
                  </li>
                  <li>
                    Unable to pay complete reservation deposit at time of
                    application and any rent due at time of lease signing.
                  </li>
                  <li>
                    Applicant over 18 does not want to apply for residency.
                  </li>
                  <li>
                    Unable to provide a copy of current state or government
                    identification.
                  </li>
                  <li>
                    Out of state applicants with no local employer who are
                    unable to provide a current bank statement with balance of
                    5x the rent.
                  </li>
                  <li>
                    International students unable to provide the following
                    documents: passport, proof of current college or university
                    full-time enrollment or current student visa along with
                    proof of funding (such as I-20) and/or supplemental savings.
                  </li>
                  <li>
                    Other applicants who are new to the country and unable to
                    provide: passport and proof of income (such as letter of
                    employment), and/or documentation of supplemental savings.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">
                    All applicants are judged on the same standards
                  </b>
                </p>
                <span className="span8">
                  All applicants are judged on the same standards; one person,
                  group, or family at a time on a first-come, first0served
                  basis. All vacancies will be filled by the first qualified
                  applicant who can move in closest to the availability date.
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-3">
                  <b className="axTextBold">Sex offender registry notice</b>
                </p>
                <span className="span8">
                  Do not list sex offender registry information if you are a
                  juvenile. Seattle Municipal Code (SMC) 14.09.025.A.4 prohibits
                  taking an adverse action based on registry information of a
                  juvenile prospective occupant, tenant, or household member.
                </span>
                <span className="span8">
                  Do not sex offender registry information if you were a
                  juvenile when the convictions occurred. SMC 14.09.025.A.5
                  prohibits adverse actions base don registry information
                  against adults if the convictions occurred when the individual
                  was a juvenile.
                </span>
                <span className="span8">
                  SMC 14.09.025.A.3 requires a legitimate business reason
                  analysis before a landlord or any person takes an adverse
                  action based on registry information of an adult prospective
                  occupant, tenant, or household member.
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-3">
                  <i className="axTextBold">
                    When considering sex offender registry information, the
                    following factors relating to the conviction(s) that
                    requires registry on a local, state, or national sex
                    offender registry:
                  </i>
                </p>
                <ul className="span8">
                  <li>The nature and severity of the conviction</li>
                  <li>The number and types of convictions</li>
                  <li>
                    The time that has elapsed since the date of conviction
                  </li>
                  <li>Age of the individual at the time of convictions</li>
                  <li>
                    Evidence of good tenant history before and/or after the
                    conviction occurred
                  </li>
                  <li>
                    Any supplemental information related to the individual's
                    rehabilitation, good conduct, and additional facts or
                    explanations provided by the individual, if the individual
                    chooses to do so.
                  </li>
                </ul>
                <p className="span8 pt-0 mt-0  mb-0 pb-3">
                  <b className="axTextBold">Additional Time needed</b>
                </p>
                <span className="span8">
                  If you require additional time to complete the application due
                  to the need to procure interpretation/translation services or
                  accommodations for a disability, please submit your request in
                  writing to info@axallant.com
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-3">
                  <b className="axTextBold">Co-Signers</b>
                </p>
                <span className="span8">
                  If you do not meet one or more of the above criteria, you may
                  be able to qualify for a rental unit if you have a third party
                  located within the state that will guarantee your lease. The
                  guarantor must fill out an application, pass the screening
                  process, and have income and debt to income ratio enough to
                  cover their expense and yours in event of default.
                </span>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-4">
                  <b className="axTextBold">Notice:</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-3 pt-1">
                  <b className="axTextBold">
                    Axallant 2021 is the consumer reporting agency who will be
                    compiling your consumer report. You have the right to obtain
                    a free copy of your consumer report in the event of a denial
                    or adverse action and to dispute the accuracy of the
                    information appearing in the consumer report. All inquiries
                    may be directed to:
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Axallant 2021, Inc.</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">11420 NE 20th St</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Bellevue WA 98004</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Phone:</b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 ">
                  <b className="axTextBold">Fax: </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    The screening fee is non-refundable.
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    If you plan to apply for more than one of our properties,
                    please only submit one application and let your agent know
                    which other property you would like to apply for.
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    By signing below, I certify and acknowledge I have applied
                    for a rental property withing the Seattle city limits.
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    ***If the rental property is outside of the Seattle city
                    limits please choose the other application, do not
                    proceed***
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    ***If the rental property is outside of the Seattle city
                    limits please choose the other application, do not
                    proceed***
                  </b>
                </p>
                <p className="span8 pt-0 mt-0  mb-0 pb-1 pt-3">
                  <b className="axTextBold">
                    Sign below to confirm that you have read and understand the
                    above, before continuing with the application
                  </b>
                </p>
                <span className="span8 pt-5 ">Signature :</span>
                <div className=" mt-4 ml-3">
                  <SignaturePad
                    name="applicationFront"
                    submitAlign="pull-right"
                    submitName="Continue"
                    type="base64"
                    value={this.state.signatureObject}
                    submit={this.changeSignatureObject}
                    submit2={this.changeSignatureObject3}
                    type2={true}
                    submitDisable={true}
                    maxWidth="550"
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {this.state.nowTab === 1 && (
          <>
            <div className="cardApplication pt-3 pb-5">
              <p className=" stepTitle subTitle pt-3 textDark ">Basic</p>
              <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                <div className="col alignInput ">
                  <ApplicationInput
                    name="First Name"
                    type="text"
                    target="firstName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your First Name"
                    validate={this.checkValidate(this.state.firstName)}
                    value={this.state.firstName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Middle Name"
                    type="text"
                    target="middleName"
                    onChange={this.onchange}
                    errorMessage="your Middle Name"
                    validate={this.checkValidate(this.state.middleName)}
                    value={this.state.middleName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Last Name"
                    type="text"
                    target="lastName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Last Name"
                    validate={this.checkValidate(this.state.lastName)}
                    value={this.state.lastName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Email"
                    type="email"
                    target="email"
                    important="true"
                    // onChange={this.onchange}
                    disabled
                    errorMessage={
                      this.checkValidate(this.state.email)
                        ? "your Email"
                        : "your email correct format"
                    }
                    validate={this.checkEmail(this.state.email)}
                    value={this.state.email}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Date of Birth"
                    target="dob"
                    type="date"
                    min={"1970-01-01"}
                    max={this.today()}
                    onChange={this.onChangeDate}
                    value={this.state.dob}
                    place="mm-dd-yyyy"
                    important="true"
                    errorMessage="your Date of Birth"
                    validate={this.checkValidate(this.state.dob)}
                  />
                </div>

                <div className="col alignInput ">
                  <ApplicationInput
                    name="Driving License Number"
                    type="text"
                    target="drivingNo"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Driving License Number"
                    validate={this.checkValidate(this.state.drivingNo)}
                    value={this.state.drivingNo}
                    icon={this.props.role !== "Guest" ? "fa-camera-retro" : ""}
                    clickIcon={this.changeLicense}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Social Security Number"
                    type="text"
                    target="securityNo"
                    min="1"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.securityNo)
                        ? "your social security number"
                        : "a valid social security number"
                    }
                    validate={this.checkSIN(this.state.securityNo)}
                    value={this.state.securityNo}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Mobile Number"
                    type="text"
                    target="mobile"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.mobile)
                        ? "your cell number"
                        : "the 10 digit number "
                    }
                    validate={this.checkMobile(this.state.mobile)}
                    value={this.state.mobile}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Home number"
                    type="text"
                    target="workingNo"
                    onChange={this.onchange}
                    errorMessage="your home number"
                    validate={this.checkMobile(this.state.workingNo)}
                    value={this.state.workingNo}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Other Names"
                    type="text"
                    target="otherName"
                    onChange={this.onchange}
                    errorMessage="your Other Name Used"
                    validate={this.checkValidate(this.state.otherName)}
                    value={this.state.otherName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Do you have pets?"
                    type="YN"
                    options={["Yes", "No"]}
                    target="pet"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="you have the pet or not"
                    validate={this.checkValidate(this.state.pet)}
                    value={this.state.pet}
                  />
                </div>
                {this.state.pet === "true" && (
                  <div className="col alignInput">
                    <ApplicationInput
                      name="How many pets?"
                      type="number"
                      target="petCount"
                      min="1"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage=" pets count do you have?"
                      // validate={this.checkValidate(this.state.petCount)}
                      value={this.state.petCount}
                    />
                  </div>
                )}
                <div className="col alignInput">
                  <ApplicationInput
                    name="Nearest Relative Name"
                    type="text"
                    // place="Relative Name"
                    target="relativeName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Relative Name"
                    validate={this.checkValidate(this.state.relativeName)}
                    value={this.state.relativeName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Nearest Relative Mobile Number"
                    type="text"
                    // place="Contact No"
                    target="relativeNum"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.relativeNum)
                        ? "your Relative Number"
                        : "the 10 digit number "
                    }
                    validate={this.checkMobile(this.state.relativeNum)}
                    value={this.state.relativeNum}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Total Occupants"
                    type="number"
                    place=""
                    target="occupant"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    max="10"
                    errorMessage="Occupant Number"
                    validate={this.checkValidate(this.state.occupant)}
                    value={this.state.occupant}
                  />
                </div>
              </div>
              {this.state.occupant > 1 && (
                <div
                  className="inputContainer pt-0 pb-0 mt-0 mb-0"
                  style={{ marginTop: "-45px", marginBottom: "-0px" }}
                >
                  <span className="searchListHeadApp">
                    Other Occupants Names
                  </span>
                </div>
              )}
              <div className="row inputContainer pt-0 pb-0 mt-0 mb-0">
                {this.state.occupantArray.map((occupant) => (
                  <div className="col alignInput">
                    <ApplicationInput
                      name="null"
                      type="text"
                      target={occupant}
                      onChange={this.onchange}
                      important="true"
                      errorMessage="occupant name"
                      validate={this.checkValidate(this.state[occupant])}
                      value={this.state[occupant]}
                      min="1"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="cardApplication pt-4 ">
              <p className="subTitle stepTitle pt-3 textDark ">Additional</p>
              <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                <div className="col alignInput ">
                  <ApplicationInput
                    name="Move In Date"
                    type="date"
                    // max={"2021-12-01"}
                    min={this.today()}
                    target="moveInDate"
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage="your Move In Date"
                    validate={this.checkValidate(this.state.moveInDate)}
                    value={this.state.moveInDate}
                  />
                </div>
                <div className="col alignInput ">
                  <ApplicationInput
                    name="Lease Term (Month)"
                    type="number"
                    target="leaseTerm"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    errorMessage="your Lease Term"
                    validate={this.checkValidate(this.state.leaseTerm)}
                    value={this.state.leaseTerm}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Additional Income (Monthly)"
                    type="number"
                    target="additionalIncome"
                    onChange={this.onchange}
                    min="1"
                    // important="true"
                    // errorMessage="your additional income."
                    // validate={this.checkValidate(this.state.additionalIncome)}
                    value={this.state.additionalIncome}
                    iconL={"fa-usd"}
                  />
                </div>
                <div className="col-12 ">
                  <ApplicationInput
                    name="Do you have any recreational vehicles, vans, boats, or
                          motorcycles? If so specify."
                    type="text"
                    target="vehicles"
                    onChange={this.onchange}
                    // important="true"
                    errorMessage="your vehicles"
                    validate={this.checkValidate(this.state.vehicles)}
                    value={this.state.vehicles}
                    titleBreck="true"
                  />
                </div>
              </div>

              <div className="row inputContainer pt-2  mt-0 pb-5">
                <div className="col-md-6  ">
                  <ApplicationInput
                    name="Have you or any person who will be occupying this household ever
                      been found guilty of a crime?"
                    type="optionYN"
                    target="crimeCheck"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.crimeCheck)}
                    value={this.state.crimeCheck}
                  />
                </div>
                <div className="col-6  alignInput ">
                  <ApplicationInput
                    name="Ever filed bankruptcy?"
                    type="optionYN"
                    target="bankruptcy"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.bankruptcy)}
                    value={this.state.bankruptcy}
                  />
                </div>

                <div className="col-md-6  ">
                  <ApplicationInput
                    name="Have you or any person who will be occupying this household ever
                      been evicted?"
                    type="optionYN"
                    target="evicted"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.evicted)}
                    value={this.state.evicted}
                  />
                </div>
                <div className="col-6  alignInput ">
                  <ApplicationInput
                    name="Anyone in the Household Smoke?"
                    type="optionYN"
                    target="smoke"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.smoke)}
                    value={this.state.smoke}
                  />
                </div>
                <div className="col  alignInput ">
                  <ApplicationInput
                    name="Do you have join tenant?"
                    type="optionYN"
                    target="isJoinTenant"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.isJoinTenant)}
                    value={this.state.isJoinTenant}
                  />
                </div>
                {this.state.isJoinTenant && (
                  <>
                    <div className="col  alignInput ">
                      <ApplicationInput
                        name="Email (Tenant-2)"
                        type="email"
                        target="joinTenant1"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.joinTenant1)
                            ? "the join tenant email address"
                            : "the join tenant email correct format"
                        }
                        validate={this.checkEmail(this.state.joinTenant1)}
                        value={this.state.joinTenant1}
                      />
                    </div>
                    {this.state.joinTenantCount === 2 && (
                      <div className="col  alignInput ">
                        <ApplicationInput
                          name="Email (Tenant-3)"
                          type="email"
                          target="joinTenant2"
                          onChange={this.onchange}
                          important="true"
                          errorMessage={
                            this.checkValidate(this.state.joinTenant2)
                              ? "the join tenant email address"
                              : "the join tenant email correct format"
                          }
                          validate={this.checkEmail(this.state.joinTenant2)}
                          value={this.state.joinTenant2}
                        />
                      </div>
                    )}
                    <div className="col pt-2">
                      <button
                        className="buttonPri mt-4"
                        onClick={() => this.changeJoinTenantCount()}
                      >
                        {this.state.joinTenantCount === 1 ? (
                          <i className="fa fa-plus" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-minus" aria-hidden="true"></i>
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {this.state.nowTab === 2 && (
          <>
            <div className="cardApplication pt-3 pb-3">
              <p className="subTitle stepTitle pt-3 textDark ">
                Present Address
              </p>
              <div className="row inputContainer  pt-5 pb-4">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Address"
                    type="text"
                    target="address"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Present Address"
                    validate={this.checkValidate(this.state.address)}
                    value={this.state.address}
                  />
                </div>
                {/* <div className="col alignInput">
                <SearchInput
                  name="country"
                  id="Country"
                  target="country"
                  onChange={this.onchange}
                  list={this.state.countryList}
                  changeState={this.changeState}
                  important="true"
                  errorMessage="property Country"
                  validate={this.checkValidate(this.state.country)}
                  value={this.state.country}
                />
              </div> */}
                <div className="col alignInput">
                  <SearchInput
                    name="state"
                    id="State"
                    target="state"
                    onChange={this.onchange}
                    list={this.state.stateList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="property State"
                    validate={this.checkValidate(this.state.state)}
                    value={this.state.state}
                  />
                </div>

                <div className="col alignInput">
                  <SearchInput
                    name="city"
                    id="City"
                    target="city"
                    onChange={this.onchange}
                    list={this.state.cityList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="property City"
                    validate={this.checkValidate(this.state.city)}
                    value={this.state.city}
                  />
                </div>
                {/* <div className="col alignInput">
                <ApplicationInput
                  name="City"
                  type="text"
                  target="city"
                  onChange={this.onchange}
                  important="true"
                  errorMessage="your Present City"
                  validate={this.checkValidate(this.state.city)}
                  value={this.state.city}
                />
              </div>
              
              <div className="col alignInput">
                <ApplicationInput
                  name="State"
                  type="text"
                  target="state"
                  onChange={this.onchange}
                  important="true"
                  errorMessage="your Present State"
                  validate={this.checkValidate(this.state.state)}
                  value={this.state.state}
                />
              </div> */}
                <div className="col alignInput">
                  <ApplicationInput
                    name="Zip      "
                    type="number"
                    target="zip"
                    min="1"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your Present Zip Code"
                    validate={this.checkValidate(this.state.zip)}
                    value={this.state.zip}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="From"
                    type="date"
                    min={"1910-01-01"}
                    max={this.state.to === "" ? this.today() : this.state.to}
                    target="from"
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.from)
                        ? "from when you were living?"
                        : "date before " + this.state.to
                    }
                    validate={this.checkPeriod(this.state.from, this.state.to)}
                    value={this.state.from}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="To"
                    type="date"
                    target="to"
                    onChange={this.onChangeDate}
                    min={this.state.from}
                    max={this.today()}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.to)
                        ? "to when you were living?"
                        : "date after " + this.state.from
                    }
                    validate={this.checkPeriod(this.state.from, this.state.to)}
                    value={this.state.to}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Property Type"
                    type="optional"
                    options={["Own", "Rent"]}
                    optionValue={["Own", "Rent"]}
                    target="propOwn"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="you were living own or rent"
                    validate={this.checkValidate(this.state.propOwn)}
                    value={this.state.propOwn}
                  />
                </div>
              </div>

              {this.state.propOwn === "Rent" && (
                <>
                  <div className="row  pt-2 inputContainer pb-4">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Name of Present Landlord"
                        type="text"
                        target="presentLandlord"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="from the name of present Landlord you are living."
                        validate={this.checkValidate(
                          this.state.presentLandlord
                        )}
                        value={this.state.presentLandlord}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Rent"
                        type="number"
                        target="rent"
                        onChange={this.onchange}
                        important="true"
                        min="1"
                        errorMessage="your monthly rental"
                        validate={this.checkValidate(this.state.rent)}
                        iconL={"fa-usd"}
                        value={this.state.rent}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Email"
                        type="email"
                        target="landLordEmail"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.landLordEmail)
                            ? "from the email address of present landlord you are living."
                            : "present landlord email correct format"
                        }
                        validate={this.checkEmail(this.state.landLordEmail)}
                        value={this.state.landLordEmail}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="Cell Number"
                        type="text"
                        target="landLordMobile"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the Cell number of present Landlord you are living."
                        validate={this.checkMobile(this.state.landLordMobile)}
                        value={this.state.landLordMobile}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Fax Number"
                        type="number"
                        min="1"
                        target="landLordFax"
                        onChange={this.onchange}
                        errorMessage="the Cell number of present Landlord you are living."
                        validate={this.checkValidate(this.state.landLordFax)}
                        value={this.state.landLordFax}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Property Type"
                        target="unitType"
                        type="optional"
                        options={["Apartment", "Mortgage", "Other"]}
                        optionValue={["Apartment", "Mortgage", "Other"]}
                        onChange={this.onchange}
                        important="true"
                        errorMessage="unit type you are living"
                        validate={this.checkValidate(this.state.unitType)}
                        value={this.state.unitType}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <div style={{ paddingRight: "10%", paddingLeft: "10%" }}>
              <hr className="line "></hr>
            </div> */}

            <p
              className="subTitle stepTitle pt-4 textDark "
              style={{ zIndex: "10" }}
            >
              Previous Resident Information &nbsp;&nbsp;&nbsp;&nbsp;
              <div
                className="pull-right preEmployeeHide1"
                style={{ zIndex: "900" }}
              >
                <ApplicationInput
                  name=""
                  type="checkedBox"
                  target="preRentalInfo"
                  onChange={this.onchangeCheck}
                  value={this.state.preRentalInfo}
                />
              </div>
            </p>

            <div
              className="subTitle stepTitle pt-5 pull-left preEmployeeHide2"
              style={{ zIndex: "900" }}
            >
              <ApplicationInput
                name=""
                type="checkedBox"
                target="preRentalInfo"
                onChange={this.onchangeCheck}
                value={this.state.preRentalInfo}
              />
            </div>
            <br></br>
            <br></br>
            {this.state.preRentalInfo && (
              <>
                <div className="preEmployeeHide2">
                  <br></br>
                  <br></br>
                </div>
                <div className="cardApplication pt-3 pb-4">
                  <div className="row  pt-3 pb-2  inputContainer">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="preAddress"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your previous Address"
                        validate={this.checkValidate(this.state.preAddress)}
                        value={this.state.preAddress}
                      />
                    </div>
                    {/* <div className="col alignInput">
                    <SearchInput
                      name="preCountry"
                      id="Country"
                      target="preCountry"
                      onChange={this.onchange}
                      list={this.state.preCountryList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="your previous Country"
                      validate={this.checkValidate(this.state.preCountry)}
                      value={this.state.preCountry}
                    />
                  </div> */}
                    <div className="col alignInput">
                      <SearchInput
                        name="preState"
                        id="State"
                        target="preState"
                        onChange={this.onchange}
                        list={this.state.preStateList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="your previous State"
                        validate={this.checkValidate(this.state.preState)}
                        value={this.state.preState}
                      />
                    </div>

                    <div className="col alignInput">
                      <SearchInput
                        name="preCity"
                        id="City"
                        target="preCity"
                        onChange={this.onchange}
                        list={this.state.preCityList}
                        changeState={this.changeState}
                        important="true"
                        errorMessage="your previous City"
                        validate={this.checkValidate(this.state.preCity)}
                        value={this.state.preCity}
                      />
                    </div>
                    {/* <div className="col alignInput">
                <ApplicationInput
                  name="City"
                  type="text"
                  target="preCity"
                  onChange={this.onchange}
                  errorMessage="your previous City"
                  validate={this.checkValidate(this.state.preCity)}
                  value={this.state.preCity}
                />
              </div>
              <div className="col alignInput">
                <ApplicationInput
                  name="State"
                  type="text"
                  target="preState"
                  onChange={this.onchange}
                  errorMessage="your previous State"
                  validate={this.checkValidate(this.state.preState)}
                  value={this.state.preState}
                />
              </div> */}
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="number"
                        target="preZip"
                        min="1"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your previous Zip Code"
                        validate={this.checkValidate(this.state.preZip)}
                        value={this.state.preZip}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="From"
                        type="date"
                        target="preFrom"
                        onChange={this.onChangeDate}
                        max={
                          this.state.preTo === ""
                            ? this.today()
                            : this.state.preTo
                        }
                        min={"1910-01-01"}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.preFrom)
                            ? "from when you were previous living?"
                            : "date before " + this.state.preTo
                        }
                        validate={this.checkValidate(this.state.preFrom)}
                        value={this.state.preFrom}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="To"
                        type="date"
                        target="preTo"
                        onChange={this.onChangeDate}
                        max={
                          this.state.from === ""
                            ? this.today()
                            : this.state.from
                        }
                        min={this.state.preFrom}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.preTo)
                            ? "to when you were previous living?"
                            : "date after " + this.state.preFrom
                        }
                        validate={this.checkValidate(this.state.preTo)}
                        value={this.state.preTo}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Property Type"
                        type="optional"
                        options={["Own", "Rent"]}
                        optionValue={["Own", "Rent"]}
                        target="prePropOwn"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="you were previous living own or rent"
                        validate={this.checkValidate(this.state.prePropOwn)}
                        value={this.state.prePropOwn}
                      />
                    </div>
                  </div>

                  {this.state.prePropOwn === "Rent" && (
                    <>
                      <div className="row  pt-2 inputContainer">
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Name of Previous Landlord"
                            type="text"
                            target="preLandLord"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="from the name of previous Landlord you are living."
                            validate={this.checkValidate(
                              this.state.preLandLord
                            )}
                            value={this.state.preLandLord}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Rent"
                            type="number"
                            target="preRent"
                            min="1"
                            onChange={this.onchange}
                            iconL={"fa-usd"}
                            important="true"
                            errorMessage="your monthly rental"
                            validate={this.checkValidate(this.state.preRent)}
                            value={this.state.preRent}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Email"
                            type="email"
                            target="preLandLordEmail"
                            onChange={this.onchange}
                            errorMessage={
                              this.checkValidate(this.state.preLandLordEmail)
                                ? "from the email address of present landlord you are living."
                                : "present landlord email correct format"
                            }
                            important="true"
                            validate={this.checkEmail(
                              this.state.preLandLordEmail
                            )}
                            value={this.state.preLandLordEmail}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Cell Number"
                            type="text"
                            target="preLandLordMobile"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the Cell number of previous Landlord you are living."
                            validate={this.checkMobile(
                              this.state.preLandLordMobile
                            )}
                            value={this.state.preLandLordMobile}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Fax Number"
                            type="text"
                            min="1"
                            target="preLandLordFax"
                            onChange={this.onchange}
                            errorMessage="the fax number of previous Landlord you are living."
                            validate={this.checkValidate(
                              this.state.preLandLordFax
                            )}
                            value={this.state.preLandLordFax}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Property Type"
                            target="preUnitType"
                            type="optional"
                            options={["Apartment", "Mortgage", "Other"]}
                            optionValue={["Apartment", "Mortgage", "Other"]}
                            onChange={this.onchange}
                            important="true"
                            errorMessage="unit type you are previous living"
                            validate={this.checkValidate(
                              this.state.preUnitType
                            )}
                            value={this.state.preUnitType}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {this.state.nowTab === 3 && (
          <>
            <div className="cardApplication pt-3 pb-3">
              <p className="subTitle stepTitle pt-3 textDark">
                Employment Details
              </p>
              <div className="row  pt-5 inputContainer pb-4">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed by"
                    type="text"
                    target="employmentCompany"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of your current company."
                    validate={this.checkValidate(this.state.employmentCompany)}
                    value={this.state.employmentCompany}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Company Phone Number"
                    type="text"
                    target="employmentPhone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.employmentPhone)
                        ? "the phone number of your current company."
                        : "the 10 digit number "
                    }
                    validate={this.checkMobile(this.state.employmentPhone)}
                    value={this.state.employmentPhone}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Email"
                    type="email"
                    target="employmentEmail"
                    important="true"
                    onChange={this.onchange}
                    errorMessage={
                      this.checkValidate(this.state.employmentEmail)
                        ? "the email address of your current company."
                        : "current company email correct format"
                    }
                    validate={this.checkEmail(this.state.employmentEmail)}
                    value={this.state.employmentEmail}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Address"
                    type="text"
                    target="employmentAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the address of your current company."
                    validate={this.checkValidate(this.state.employmentAddress)}
                    value={this.state.employmentAddress}
                  />
                </div>
                <div className="col alignInput">
                  <SearchInput
                    name="employmentState"
                    id="State"
                    target="employmentState"
                    onChange={this.onchange}
                    list={this.state.employmentStateList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the State of your current company "
                    validate={this.checkValidate(this.state.employmentState)}
                    value={this.state.employmentState}
                  />
                </div>

                <div className="col alignInput">
                  <SearchInput
                    name="employmentCity"
                    id="City"
                    target="employmentCity"
                    onChange={this.onchange}
                    list={this.state.employmentCityList}
                    changeState={this.changeState}
                    important="true"
                    errorMessage="the city of your current company"
                    validate={this.checkValidate(this.state.employmentCity)}
                    value={this.state.employmentCity}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Zip"
                    type="number"
                    target="employmentZip"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    errorMessage="the Zip Code of your current company."
                    validate={this.checkValidate(this.state.employmentZip)}
                    value={this.state.employmentZip}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Salary(Monthly)"
                    type="number"
                    target="employmentSalary"
                    onChange={this.onchange}
                    important="true"
                    min="1"
                    errorMessage="your salary at the current company."
                    validate={this.checkValidate(this.state.employmentSalary)}
                    value={this.state.employmentSalary}
                    iconL={"fa-usd"}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Position"
                    type="text"
                    target="employmentPosition"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your position in your current company."
                    validate={this.checkValidate(this.state.employmentPosition)}
                    value={this.state.employmentPosition}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed From"
                    type="date"
                    min={"1910-01-01"}
                    target="employmentFrom"
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.employmentFrom)
                        ? "your start date of your work."
                        : "date before " + this.state.employmentUntil
                    }
                    validate={this.checkPeriod(
                      this.state.employmentFrom,
                      this.state.employmentUntil
                    )}
                    max={
                      this.state.employmentUntil === ""
                        ? this.today()
                        : this.state.employmentUntil
                    }
                    value={this.state.employmentFrom}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed Until"
                    type="text"
                    target="employmentUntil"
                    value={"Present"}
                  />
                </div>
              </div>
            </div>
            <br></br>

            <p
              className="subTitle stepTitle  textDark pb-2"
              style={{ zIndex: "10" }}
            >
              Previous Employment Information&nbsp;&nbsp;&nbsp;&nbsp;
              <div
                className="pull-right preEmployeeHide1"
                style={{ zIndex: "900" }}
              >
                <ApplicationInput
                  name=""
                  type="checkedBox"
                  target="preEmploymentInfo"
                  onChange={this.onchangeCheck}
                  // important="true"
                  // errorMessage="sharedYard"
                  // validate={this.checkValidate(this.state.preEmploymentInfo)}
                  value={this.state.preEmploymentInfo}
                />
              </div>
            </p>

            <div
              className="subTitle stepTitle pt-5 pb-4 pull-left   preEmployeeHide2"
              style={{ zIndex: "900" }}
            >
              <ApplicationInput
                name=""
                type="checkedBox"
                target="preEmploymentInfo"
                onChange={this.onchangeCheck}
                // important="true"
                // errorMessage="sharedYard"
                // validate={this.checkValidate(this.state.preEmploymentInfo)}
                value={this.state.preEmploymentInfo}
              />
            </div>
            <br></br>

            {this.state.preEmploymentInfo && (
              <div className="cardApplication pt-3 pb-3">
                <div className="row inputContainer pt-3 pb-2">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employed by"
                      type="text"
                      target="preEmploymentCompany"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of your current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentCompany
                      )}
                      value={this.state.preEmploymentCompany}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Company Phone Number"
                      type="text"
                      target="preEmploymentPhone"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the phone number of your current company."
                      validate={this.checkMobile(this.state.preEmploymentPhone)}
                      value={this.state.preEmploymentPhone}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Email"
                      type="email"
                      target="preEmploymentEmail"
                      important="true"
                      onChange={this.onchange}
                      errorMessage={
                        this.checkValidate(this.state.preEmploymentEmail)
                          ? "the email address of your current company."
                          : "current company email correct format"
                      }
                      validate={this.checkEmail(this.state.preEmploymentEmail)}
                      value={this.state.preEmploymentEmail}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Address"
                      type="text"
                      target="preEmploymentAddress"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the address of your current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentAddress
                      )}
                      value={this.state.preEmploymentAddress}
                    />
                  </div>

                  <div className="col alignInput">
                    <SearchInput
                      name="preEmploymentState"
                      id="State"
                      target="preEmploymentState"
                      onChange={this.onchange}
                      list={this.state.preEmploymentStateList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="your previous State"
                      validate={this.checkValidate(
                        this.state.preEmploymentState
                      )}
                      value={this.state.preEmploymentState}
                    />
                  </div>

                  <div className="col alignInput">
                    <SearchInput
                      name="preEmploymentCity"
                      id="City"
                      target="preEmploymentCity"
                      onChange={this.onchange}
                      list={this.state.preEmploymentCityList}
                      changeState={this.changeState}
                      important="true"
                      errorMessage="your previous City"
                      validate={this.checkValidate(
                        this.state.preEmploymentCity
                      )}
                      value={this.state.preEmploymentCity}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Zip"
                      type="number"
                      min="1"
                      target="preEmploymentZip"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the Zip Code of your current company."
                      validate={this.checkValidate(this.state.preEmploymentZip)}
                      value={this.state.preEmploymentZip}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="Salary(Monthly)"
                      min="1"
                      type="number"
                      target="preEmploymentSalary"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="your salary at the current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentSalary
                      )}
                      value={this.state.preEmploymentSalary}
                      iconL={"fa-usd"}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Position"
                      type="text"
                      target="preEmploymentPosition"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="your position in your current company."
                      validate={this.checkValidate(
                        this.state.preEmploymentPosition
                      )}
                      value={this.state.preEmploymentPosition}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employed From"
                      type="date"
                      target="preEmploymentFrom"
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.preEmploymentFrom)
                          ? "your start date of your previous work."
                          : "date before " + this.state.preEmploymentUntil
                      }
                      min={"1910-01-01"}
                      validate={this.checkPeriod(
                        this.state.preEmploymentFrom,
                        this.state.preEmploymentUntil
                      )}
                      max={
                        this.state.preEmploymentUntil === ""
                          ? this.today()
                          : this.state.preEmploymentUntil
                      }
                      value={this.state.preEmploymentFrom}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Employed Until"
                      type="date"
                      target="preEmploymentUntil"
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.preEmploymentUntil)
                          ? "your end date of your previous work."
                          : "date after " + this.state.preEmploymentFrom
                      }
                      validate={this.checkPeriod(
                        this.state.preEmploymentFrom,
                        this.state.preEmploymentUntil
                      )}
                      max={
                        this.state.employmentFrom === ""
                          ? this.today()
                          : this.state.employmentFrom
                      }
                      min={this.state.preEmploymentFrom}
                      value={this.state.preEmploymentUntil}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {this.state.nowTab === 4 && (
          <AddTenantPayment
            data={this.state.user}
            status={this.state.stripe_account_status.data}
            type="initial"
            next={this.next}
            role={this.props.role}
            pushAlert={this.props.appendAlert}
            paymentToken={this.state.paymentToken}
            setPaymentToken={this.setPaymentToken}
            changeTokenNull={this.changeTokenNull}
            propertyRent={this.state.applicationFee}
          />
        )}
        {this.state.nowTab === 5 && (
          <>
            <ApplicationReview
              listingData={this.state.listingData}
              onchangeState={this.onchangeState}
              landlords={this.state.landlords}
              data={this.state}
              onchangeCheck={this.onchangeCheck}
              onchangeCheckOpp={this.onchangeCheckOpp}
              onchangeCheckOption={this.onchangeCheckOption}
              apartmentData={this.state.apartmentData}
            />
          </>
        )}

        {this.state.footer && (
          <div
            className="row appFootButton text-right inputContainer  "
            style={{ paddingBottom: "40px", paddingTop: "40px" }}
          >
            <div className="col ">
              {this.state.nowTab > 0 && (
                <>
                  {" "}
                  <button
                    className="pri mr-3"
                    onClick={() => this.props.history.goBack()}
                  >
                    Cancel
                  </button>
                  <button className="mr-3 pri" onClick={() => this.back()}>
                    Back
                  </button>
                </>
              )}
              {this.state.nowTab !== 5 &&
                this.state.nowTab !== 0 &&
                (this.state.nowTab === 4 ? (
                  this.props.role !== "Guest" ? (
                    this.state.stripe_account_status.data !== "verified" ? (
                      <button
                        className="secDisable"
                        style={{ cursor: "not-allowed" }}
                      >
                        Next
                      </button>
                    ) : this.state.paymentToken === "" ? (
                      <button
                        className="secDisable"
                        style={{ cursor: "not-allowed" }}
                      >
                        Next
                      </button>
                    ) : this.state.nextEnable ? (
                      <button
                        className="buttonPri "
                        onClick={() => this.next()}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className="secDisable "
                        onClick={() => this.next()}
                      >
                        Next
                      </button>
                    )
                  ) : this.state.stripe_account_status !== "verified" ? (
                    <button
                      className="secDisable"
                      style={{ cursor: "not-allowed" }}
                    >
                      Next
                    </button>
                  ) : this.state.paymentToken === "" ? (
                    <button
                      className="secDisable"
                      style={{ cursor: "not-allowed" }}
                    >
                      Next
                    </button>
                  ) : this.state.nextEnable ? (
                    <button className="buttonPri " onClick={() => this.next()}>
                      Next
                    </button>
                  ) : (
                    <button className="secDisable " onClick={() => this.next()}>
                      Next
                    </button>
                  )
                ) : this.state.nextEnable ? (
                  <button className="buttonPri " onClick={() => this.next()}>
                    Next
                  </button>
                ) : (
                  <button className="secDisable " onClick={() => this.next()}>
                    Next
                  </button>
                ))}
              {this.state.nowTab === 5 && (
                <>
                  <button
                    className={
                      !this.state.changeBlob
                        ? "secDisable notAllow"
                        : " buttonPri"
                    }
                    onClick={() => this.downloadPDF()}
                    data-target="#exampleModal1"
                    data-toggle="modal"
                  >
                    <i className="fa fa-download" aria-hidden="true"></i>
                    {!this.state.changeBlob && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>{" "}
                  &nbsp;&nbsp;
                  {this.state.signatureObject2 !== null ? (
                    <button
                      className={
                        !this.state.isButtonDisableFinish
                          ? "secDisable notAllow"
                          : " buttonPri"
                      }
                      data-target="#finishedModal"
                      data-toggle="modal"
                      // onClick={() => this.finished()}
                      disabled={!this.state.isButtonDisableFinish}
                    >
                      Proceed
                      {!this.state.isButtonDisableFinish && (
                        <Loader3 transY="50px" transX="0px" />
                      )}
                    </button>
                  ) : (
                    <button
                      className={" buttonPri"}
                      data-target="#signatureModal"
                      data-toggle="modal"
                    >
                      Sign here
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <LicenseModal
          pushAlert={this.props.appendAlert}
          changeLicense={this.changeLicense}
          licenseModal={this.state.licenseModal}
          changeLicenseData={this.changeLicenseData}
          keepWait={this.keepWait}
          removeKeepWait={this.removeKeepWait}
        />
        {this.state.downloadModal && (
          <ModalContainer
            // min="300px"
            max={window.innerHeight - 200}
            maxWidth={window.innerWidth}
            name="exampleModal1"
            title={"Download"}
            footer="false"
            heightAgreement="920"
            widthAgreement="290"
            footerDownload="true"
            minWidth="1000px"
            closeContract={this.closeDownloadModal}
          >
            <ApplicationReview1
              listingData={this.state.listingData}
              onchangeState={this.onchangeState}
              data={this.state}
              onchangeCheck={this.onchangeCheck}
              onchangeCheckOpp={this.onchangeCheckOpp}
              onChangeParent={this.onChangeParent}
              onchangeCheckOption={this.onchangeCheckOption}
              mobile="true"
            />
          </ModalContainer>
        )}
        {this.state.finishedModal && (
          <SmallModalContainer
            name="finishedModal"
            dark={this.props.dark}
            title="Confirmation"
            // footer="false"
            confirmCommand={
              this.state.applicationFee === 0
                ? "Proceed"
                : "Proceed to checkout"
            }
            confirm={() => this.finished()}
            // closeContract={()=>this.setState({finishedModal:false})}
          >
            <div className="p-3">
              <span className="span7" style={{ fontSize: "16px" }}>
                {" "}
                {this.state.applicationFee === 0
                  ? "No Application Fee will be charged when you submit this application"
                  : "Application Fee, " +
                    this.state.applicationFee +
                    ".00 USD will be charged when you submit this application"}
              </span>
            </div>
          </SmallModalContainer>
        )}
        <SmallModalContainer
          name="signatureModal"
          dark={this.props.dark}
          title="Please sign here"
          footer="false"
          size="lg"
        >
          <div className="row">
            <div className="col ">
              <button
                className={
                  this.state.signatureTab === "pad"
                    ? "mr-2 tabSignature"
                    : "mr-2  tabSignature2"
                }
                onClick={() =>
                  this.setState({ signatureTab: "pad", signatureObject2: null })
                }
              >
                Draw
              </button>
              <button
                className={
                  this.state.signatureTab === "upload"
                    ? "mr-2  tabSignature"
                    : "mr-2  tabSignature2"
                }
                onClick={() =>
                  this.setState({
                    signatureTab: "upload",
                    signatureObject2: null,
                  })
                }
              >
                Upload
              </button>
            </div>
          </div>

          <div className={"tab-content  borderSec"} id="myTabContent">
            <div
              className={
                this.state.signatureTab === "pad"
                  ? "tab-pane fade show active "
                  : "tab-pane fade "
              }
              id="upload"
              role="tabpanel"
              aria-labelledby="upload-tab"
            >
              <div className="d-flex justify-content-center pt-5 pb-4">
                <SignaturePad
                  name="applicationLast"
                  submitAlign="pull-right"
                  submitName="Submit"
                  type="base64"
                  value={this.state.signatureObject2}
                  submit={this.changeSignatureObject2}
                  submit2={this.changeSignatureObject3}
                  type2={true}
                  submitDisable={true}
                  
                  maxWidth="450"
                />
              </div>
            </div>
            <div
              className={
                this.state.signatureTab === "upload"
                  ? "tab-pane fade show active "
                  : "tab-pane fade "
              }
              id="upload"
              role="tabpanel"
              aria-labelledby="upload-tab"
            >
              <div className="d-flex justify-content-start mb-5">
                <Dropzone
                  accept=".jpeg,.png,.jpg"
                  maxSize={10485760}
                  onDrop={(files) => {
                    files.map((file) => {
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        if (event.target.result === undefined) {
                          this.props.appendAlert(
                            "Image size is too Long (max size = 10mb) ",
                            "danger"
                          );
                        } else {
                          this.setState({
                            signatureObject2: event.target.result,
                          });
                        }
                      };
                      reader.readAsDataURL(file);
                      return file;
                    });
                  }}
                  multiple={false}
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {this.state.signatureObject2 === null ? (
                          <div className="text-left p-4">
                            <div
                              className="dragDrop text-center  pointer"
                              style={{
                                fontSize: "60px",
                                minHeight: "250px",
                                paddingTop: "70px",
                              }}
                            >
                              <div className="row">
                                <div className="col">
                                  <i
                                    className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <p className="licenseHead">
                                    Drag Or Click to Upload
                                  </p>
                                </div>
                              </div>
                            </div>
                            <span className="span5 textPri ">
                              Max file size is 10MB per image. JPG, PNG, or GIF
                              formats only.
                            </span>
                          </div>
                        ) : (
                          <div className=" text-center ">
                            <img
                              src={this.state.signatureObject2}
                              className="licenseImage dragDrop pointer"
                              alt="propsImage"
                              style={{ height: "100%", width: "100%" }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </Dropzone>
              </div>
              <div className="row">
                <div className="col ">
                  <button
                    className="buttonSquareLinePri pull-left ml-3 mt-3"
                    onClick={() => this.setState({ signatureObject2: null })}
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                  {this.state.signatureObject2 !== null ? (
                    <button
                      className=" mr-3 pull-right mb-3"
                      onClick={() =>
                        this.changeSignatureObject2(this.state.signatureObject2)
                      }
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      className="secDisable pull-right mb-3 mr-3"
                      style={{ cursor: "not-allowed" }}
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Please provide your signature image"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SmallModalContainer>
        <SmallModalContainer
          name="mailCheck"
          dark={this.props.dark}
          title="Hi, have you been here before?"
          // footer="false"
          rejectCheck="true"
          confirmCommand="Continue"
          rejectCommand="Try another e-mail"
          reject={() => this.emailReject()}
          size="lg"
          confirm={() => this.emailAccept()}
          // closeContract={()=>this.setState({finishedModal:false})}
        >
          <div className="p-3">
            <span className="span7" style={{ fontSize: "16px" }}>
              {" "}
              A user with this e-mail already exists. How do you want to
              continue?
            </span>
            <br></br>
            <br></br>
            <i className="fa fa-info-circle " aria-hidden="true"></i>
            &nbsp;&nbsp;
            <span className="searchListContentApp">
              If you choose to continue with existing details(Name,Mobile),
              Press continue.
            </span>
            <br></br>
            <span className="searchListContentApp">
              &nbsp;&nbsp;&nbsp;&nbsp;Else try with another e-mail.
            </span>
          </div>
        </SmallModalContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    user: state.userReducer,
    userDetail: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert })(ListingApplication)
);
export class AddTenantPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: CookieService.getCookie("axTok"),
      data: this.props.data,
      accountStatus: "",
      type: "",
      activePaymentMethod: false,
      isLoading: true,
      isButtonDisable: true,
      account_holder_name: "",
      account_holder_type: "individual",
      routing_number: "",
      account_number: "",
      token_id: "",
      bank_account_id: "",
      deposit1: "",
      deposit2: "",
      nextPayment: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  async componentDidMount() {
    this.setState({
      accountStatus: this.props.status ? this.props.status : "",
    });
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  checkValidate(value) {
    return value === "" && this.state.nextPayment;
  }

  render() {
    return (
      <div className="medium-container" style={{ marginTop: "10px" }}>
        <div className="container-fluid">
          {this.state.accountStatus !== "new" && (
            <>
              <div className="row mb-0 ">
                <div className="col">
                  <StripeProvider apiKey={stripeKey}>
                    <Elements>
                      <CardPayment
                        access_token={this.state.access_token}
                        email={
                          this.state.data ? this.state.data.username : "email"
                        }
                        mode="temp"
                        history={this.props.history}
                        type={this.state.type}
                        pushAlert={this.props.appendAlert}
                        next={this.props.next}
                        role={this.props.role}
                        paymentToken={this.props.paymentToken}
                        setPaymentToken={this.props.setPaymentToken}
                        changeTokenNull={this.props.changeTokenNull}
                      />
                    </Elements>
                  </StripeProvider>
                </div>
                <div className="col ">
                  <div className="informationListAppNormal">
                    <i className="fa fa-info-circle " aria-hidden="true"></i>
                    &nbsp;&nbsp;
                    <span className="searchListContentApp">
                      Application Fee = &nbsp;&nbsp;
                      <CurrencyFormat value={this.state.applicationFee} />
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
