import { useEffect } from "react";
import "./style.css";
import { withRouter } from "react-router-dom";
import Loader from "../Loader/Loader";

const AcceptDirectContract = (props) => {
  useEffect(() => {
    props.history.push({
      pathname: "/accept-contract",
      state: {
        email: window.atob(props.match.params.tenantEmail),
        type: "directEmailTenantLogin",
      },
    });
  }, [props.match.params.tenantEmail, props.history]);
  return <Loader />;
};

export default withRouter(AcceptDirectContract);
