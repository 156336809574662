//Recent Payment Details Reducer
const recentPaymentReducer = (state = null, action) => {
     switch (action.type) {
       case "GET_RECENT_PAYMENT":
         return state;
       case "SET_RECENT_PAYMENT":
         return action.payment;
       case "REMOVE_RECENT_PAYMENT_BY_ID":
         return state.filter((todo) => todo.id !== action.id);
       case "REMOVE_RECENT_PAYMENT":
         return null;
       default:
         return state;
     }
   };
   export default recentPaymentReducer;
   