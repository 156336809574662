import React, { Component } from "react";
import "./SmallToggle.css";

class SmallToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
     <div className="">
     <label
       className={"smallSwitch "}
     >
       <input
         type="checkbox"
         checked={this.props.value ? "checked" : ""}
         onChange={this.props.onChange}
         name={this.props.name}
       />
       <div
         className={ "smallSlider round"}
       >
         <span className="onApp">
           <b> </b>
         </span>
         <span className="offApp">
           <b></b>
         </span>
       </div>
     </label>
   </div>
    );
  }
}

export default (SmallToggle);
