// @author Karunaaharan Bavaram
import React, { Component } from "react";
import "./chat.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { appendAlert } from "../../../actions";
import { url } from "../../../Services/ApiServices";
import image1 from "../../../Images/profile.png";
import ChatContact from "./ChatContact";
import logo from "../../../Images/favicon.ico";
import Loader3 from "../../Loader/loader3";
import { timeShortCalculativeFormat } from "../../../Defined/Function";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      tab: this.props.authStatus ? "contact" : "chat",
      activeContact: "",
      activeImage: null,
      profileImage: null,
      status: "active",
      chatImages: [],
      chatText: "",
      searchText: "",
      activeChatUser: {
        username: "info@axallant.com",
        password: "",
        enabled: false,
        accountNonExpired: true,
        credentialsNonExpired: false,
        accountNonLocked: true,
        firstName: "Axallant",
        lastName: "Bot",
        middleName: "Axallant",
        address: {
          address: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          lat: 0,
          lng: 0,
        },
      },
      contactList: [
        {
          username: "info@axallant.com",
          password: "",
          enabled: false,
          accountNonExpired: true,
          credentialsNonExpired: false,
          accountNonLocked: true,
          firstName: "Axallant",
          lastName: "Bot",
          middleName: "Axallant",
          address: {
            address: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            lat: 0,
            lng: 0,
          },
        },
      ],
      chat: [
        {
          message: "Really cool stuff!",
          published: new Date(Date.now() - 36000),
          sender: "Miro",
          receiver: "Martin",
        },
        {
          message: "Can you share a link the property unit to view ",
          published: new Date(Date.now() - 30000),
          sender: "Martin",
          receiver: "Miro",
        },
        {
          message: "Yeah, hold on",
          published: new Date(Date.now() - 30000),
          sender: "Miro",
          receiver: "Martin",
        },
        {
          message: "Yeah",
          published: new Date(Date.now() - 20000),
          sender: "Martin",
          receiver: "Miro",
        },
        {
          message: "https://demo.axallant.com/rental-units/14135",
          published: new Date(Date.now() - 10000),
          sender: "Miro",
          receiver: "Martin",
        },
        {
          message: "ok thanks",
          published: new Date(Date.now()),
          sender: "Martin",
          receiver: "Miro",
        },
      ],
    };
    this.goToChat = this.goToChat.bind(this);
    this.storeImage = this.storeImage.bind(this);
    this.onchange = this.onchange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }
  //first set profile image into profileImage
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    if (this.props.userDetailArray && this.props.roleStatus) {
      if (this.props.userDetailArray.username) {
        if (this.props.roleStatus === "Landlord") {
          this.setState({
            profileImage: "/landlord/" + this.props.userDetailArray.username,
          });
        } else {
          this.setState({
            profileImage: "/tenant/" + this.props.userDetailArray.username,
          });
        }
        if (this.props.userDetailArray.provider === "local") {
          fetch(
            url +
              `api/user/${this.props.userDetailArray.username}/image/download`
          )
            .then((res) => {
              if (res.status === 200) {
                this.setState({ profileImage: res.url });
              } else {
                this.setState({ profileImage: null });
              }
            })
            .catch((_res) => {
              this.setState({ profileImage: null });
            });
        } else {
          this.setState({ profileImage: null });
        }
      }
    }
  }
  //open chat window
  open() {
    this.setState({ display: !this.state.display });
  }

  //close chat
  closeChat() {
    if (this.props.authStatus) {
      this.setState({ tab: "contact" });
    } else {
      this.setState({ display: false });
    }
  }
  goToChat(data) {
    this.setState({ activeChatUser: data, tab: "chat", activeImage: null });
    if (data !== undefined) {
      if (data.provider === "local") {
        fetch(url + `api/user/${data.username}/image/download`)
          .then((res) => {
            if (res.status === 200) {
              this.setState({ activeImage: res.url });
            } else {
              this.setState({ activeImage: null });
            }
          })
          .catch((_res) => {
            this.setState({ activeImage: null });
          });
      } else {
        this.setState({ activeImage: null });
      }
    }
  }
  // store all contact profile images
  storeImage(id, image) {
    let arr = this.state.chatImages;
    arr.push({
      id: id,
      image: image,
    });
    this.setState({ chatImages: arr });
  }
  //onchange function for search and chat input
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  //enter key event function for search and chat input
  onKeyUp(event) {
    let { name, value } = event.target;
    if (event.key === "Enter" && !event.shiftKey) {
      if (name === "chatText") {
        var chats = this.state.chat;
        chats.push({
          message: value,
          published: new Date(Date.now()),
          sender: "Martin",
          receiver: "Miro",
        });
        this.setState({ chat: chats });
      } else {
      }
    }
  }
  //send chat function
  pushChat() {
    var chats = this.state.chat;
    chats.push({
      message: this.state.chatText,
      published: new Date(Date.now()),
      sender: "Miro",
      receiver: "Martin",
    });
    this.setState({ chat: chats });
  }
  render() {
    return (
      <div className="chatComponent">
        <div className="chatWindow">
          <span className="chatBox-open" onClick={() => this.open()}>
            <i
              className={
                this.state.display
                  ? "fa fa-close   pointer"
                  : "fa fa-comments   pointer"
              }
              aria-hidden="true"
            ></i>
          </span>

          <section
            className={
              this.state.display ? "chatBox-popup showChat" : "chatBox-popup"
            }
          >
            {(this.state.tab === "contact" || this.state.tab === "setting") &&
            this.props.authStatus ? (
              <header className="chatBox-popup__header0">
                <span
                  className={
                    this.state.tab === "contact" ? "chatTab active" : "chatTab"
                  }
                  style={{ borderTopLeftRadius: "12px" }}
                  onClick={() => this.setState({ tab: "contact" })}
                >
                  <i
                    className="fa fa-address-card pr-3 pl-3"
                    aria-hidden="true"
                  ></i>
                </span>
                <span
                  className={
                    this.state.tab === "chat" ? "chatTab active" : "chatTab"
                  }
                  onClick={() => this.setState({ tab: "chat" })}
                >
                  <i
                    className="fa fa-comments pr-3 pl-3"
                    aria-hidden="true"
                  ></i>
                </span>
                <span
                  className={
                    this.state.tab === "setting" ? "chatTab active" : "chatTab"
                  }
                  style={{ borderTopRightRadius: "12px" }}
                  onClick={() => this.setState({ tab: "setting" })}
                >
                  <i className="fa fa-cogs pr-3 pl-3" aria-hidden="true"></i>
                </span>
              </header>
            ) : (
              <header className="chatBox-popup__header">
                <aside style={{ flex: 3 }}>
                  {this.state.activeChatUser.username ===
                  "info@axallant.com" ? (
                    <img className="chatIcon" src={logo} alt="profileImage" />
                  ) : this.state.activeImage === null ? (
                    <i
                      className="fa fa-user-circle  chatBox-popup__avatar"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <img
                      className="chatIcon"
                      src={this.state.activeImage}
                      alt="profileImage"
                    />
                  )}
                </aside>
                <aside style={{ flex: 8 }}>
                  <h1 className="chat">
                    {this.state.activeChatUser.firstName +
                      " " +
                      this.state.activeChatUser.lastName}
                  </h1>
                </aside>
                <aside style={{ flex: 1 }} onClick={() => this.closeChat()}>
                  <i className="fa fa-close pointer" aria-hidden="true"></i>
                </aside>
              </header>
            )}
            {this.state.tab === "chat" || !this.props.authStatus ? (
              <main className="bg-white">
                <div id="chat-messages" className="chat-messages">
                  <label>Thursday 02</label>
                  {this.state.chat.map((data,index) =>
                    data.sender === "Miro" ? (
                      <div className="message " key={"chat"+index}>
                        <img
                          src={
                            this.state.activeChatUser.username ===
                            "info@axallant.com"
                              ? logo
                              : this.state.activeImage === null
                              ? image1
                              : this.state.activeImage
                          }
                          alt="profileImage"
                        />
                        <div className="bubble" >
                          {data.message}
                          <br></br>
                          <span>
                            {timeShortCalculativeFormat(data.published)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="message right" key={"chat2"+index}>
                        <img
                          src={
                            this.state.profileImage === null
                              ? image1
                              : this.state.profileImage
                          }
                          alt="profileImage"
                        />
                        <div className="bubble myChat">
                          {data.message}
                          <br></br>
                          <span>
                            {timeShortCalculativeFormat(data.published)}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                  <div className="message">
                    <img
                      src={
                        this.state.activeChatUser.username ===
                        "info@axallant.com"
                          ? logo
                          : this.state.activeImage === null
                          ? image1
                          : this.state.activeImage
                      }
                      alt="proImage"
                    />
                    <div className="bubble">
                      {/* <i className="fa fa-spinner fa-pulse fa-1x fa-fw"></i> */}
                      <Loader3 transY="40px" transX="0px" />
                      &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                      &nbsp;
                      {/* <span style={{ marginLeft:"40px" }}>typing ...</span> */}
                    </div>
                  </div>
                </div>
              </main>
            ) : this.state.tab === "contact" ? (
              <main className="bg-white">
                <div className="topmenu ">
                  <div id="contacts">
                    {this.state.contactList.map((data,index) => (
                      <div
                        className="contact"
                        onClick={() => this.goToChat(data)}
                        key={"chatContact"+index}
                      >
                        <img
                          src={
                            data.username === "info@axallant.com"
                              ? logo
                              : image1
                          }
                          alt="profileImage"
                        />
                        <p>
                          <strong>
                            {data.firstName + " " + data.lastName}
                          </strong>
                          <br></br>
                          <span>{data.username}</span>
                        </p>
                        <div className="status available"></div>
                      </div>
                    ))}
                    {this.props.tempUsersArray.map(
                      (data,index) =>
                        data !== undefined && (
                          <ChatContact
                            images={this.state.chatImages.filter(
                              (image) => data.username === image.id
                            )}
                            storeImage={this.storeImage}
                            contact={data}
                            goToChat={this.goToChat}
                            key={"chatUser"+index}
                          />
                        )
                    )}

                    <div className="contact">
                      <img
                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/3_copy.jpg"
                        alt="profileImage"
                      />
                      <p>
                        <strong>Tomas Kennedy</strong>
                        <br></br>
                        <span>tomaskennedy@gmail.com</span>
                      </p>
                      <div className="status away"></div>
                    </div>

                    <div className="contact">
                      <img
                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/4_copy.jpg"
                        alt="profileImage"
                      />
                      <p>
                        <strong>Enrique Sutton</strong>
                        <br></br>
                        <span>enriquesutton@gmail.com</span>
                      </p>
                      <div className="status away"></div>
                    </div>

                    <div className="contact">
                      <img
                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/5_copy.jpg"
                        alt="profileImage"
                      />
                      <p>
                        <strong> Darnell Strickland</strong>
                        <br></br>
                        <span>darnellstrickland@gmail.com</span>
                      </p>
                      <div className="status inactive"></div>
                    </div>
                  </div>
                </div>
              </main>
            ) : (
              this.state.tab === "setting" && (
                <main
                  className="bg-white "
                  style={{
                    borderBottomLeftRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <div id="profile">
                    {this.state.profileImage === null ? (
                      <img
                        className="chatProfile"
                        src={image1}
                        alt="profileImage"
                      />
                    ) : (
                      <img
                        className="chatProfile"
                        src={this.state.profileImage}
                        alt="profileImage"
                      />
                    )}
                    <p>
                      {this.props.userDetailArray.firstName +
                        " " +
                        this.props.userDetailArray.lastName}
                    </p>
                    <span>{this.props.userDetailArray.username}</span>
                  </div>
                  <div className="form-group col-12 pt-3 pl-4 pb-3 mb-0">
                    <label htmlFor="title">
                      <b>Change Display Name</b>
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Display name"
                        aria-label="Display name"
                        aria-describedby="basic-addon2"
                        value={
                          this.props.userDetailArray.firstName +
                          " " +
                          this.props.userDetailArray.lastName
                        }
                      />
                      <span
                        className="input-group-text pointer"
                        id="basic-addon2"
                      >
                        Change
                      </span>
                    </div>
                    <label htmlFor="title">
                      <b>Status</b>
                    </label>

                    <div
                      className="input-group "
                      onClick={() => this.setState({ status: "active" })}
                    >
                      <div className="radio-item pointer">
                        <input
                          type="radio"
                          className="active"
                          id="active"
                          name="active"
                          checked={this.state.status === "active" && "checked"}
                        />
                        <label htmlFor="active">&nbsp; Active</label>
                      </div>
                    </div>
                    <div
                      className="input-group"
                      onClick={() => this.setState({ status: "away" })}
                    >
                      <div className="radio-item pointer">
                        <input
                          type="radio"
                          className="away"
                          id="away"
                          name="away"
                          checked={this.state.status === "away" && "checked"}
                        />
                        <label htmlFor="away">&nbsp; Away</label>
                      </div>
                    </div>
                    <div
                      className="input-group pointer"
                      onClick={() => this.setState({ status: "inactive" })}
                    >
                      <div className="radio-item">
                        <input
                          type="radio"
                          className="inactive"
                          id="inactive"
                          name="inactive"
                          checked={
                            this.state.status === "inactive" && "checked"
                          }
                        />
                        <label htmlFor="inactive">&nbsp; Inactive</label>
                      </div>
                    </div>
                  </div>
                </main>
              )
            )}
            {this.props.authStatus ? (
              this.state.tab === "contact" ? (
                <footer className="chatBox-popup__footer bg-white">
                  <aside style={{ flex: 10 }}>
                    <textarea
                      className="chat"
                      type="text"
                      placeholder="Search contacts..."
                      name="searchText"
                      value={this.state.searchText}
                      onChange={this.onchange}
                      onKeyPress={this.onKeyUp}
                      autoFocus
                    ></textarea>
                  </aside>
                  <aside
                    style={{
                      flex: 1,
                      color: "#888",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </aside>
                </footer>
              ) : (
                this.state.tab === "chat" && (
                  <footer className="chatBox-popup__footer bg-white">
                    <aside style={{ flex: 10 }}>
                      <textarea
                        className="chat"
                        type="text"
                        placeholder="Type your message here..."
                        name="chatText"
                        value={this.state.chatText}
                        onChange={this.onchange}
                        onKeyPress={this.onKeyUp}
                        autoFocus
                      ></textarea>
                    </aside>
                    <aside
                      style={{
                        flex: 1,
                        color: "#888",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => this.pushChat()}
                    >
                      <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </aside>
                  </footer>
                )
              )
            ) : (
              <footer className="chatBox-popup__footer bg-white">
                <aside style={{ flex: 10 }}>
                  <textarea
                    className="chat"
                    type="text"
                    placeholder="Type your message here..."
                    name="chatText"
                    value={this.state.chatText}
                    onChange={this.onchange}
                    onKeyPress={this.onKeyUp}
                    autoFocus
                  ></textarea>
                </aside>
                <aside
                  style={{
                    flex: 1,
                    color: "#888",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => this.pushChat()}
                >
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </aside>
              </footer>
            )}
          </section>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    user: state.userReducer,
    userDetailArray: state.user,
    auth: state.AuthReducer,
    authStatus: state.isAuth,
    role: state.RoleReducer,
    roleStatus: state.role,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
  };
};
export default withRouter(connect(mapStateToProps, { appendAlert })(Chat));
