import React, { Component } from "react";
import { StripeProvider, Elements } from "react-stripe-elements";
import { withRouter } from "react-router-dom";
import CardPayment from "./CardPayment";
import ApplicationInput from "../../UIServices/ApplicationInput";
import {
  stripeKey,
  stripeToken,
  addPaymentMethod,
  verifyPaymentMethod,
  getCustomerPayment,
  updatePaymentMethod,
  checkPaymentStatus,
  userDetail,
} from "../../../Services/ApiServices";
import Loader3 from "../../Loader/loader3";
import CookieService from "../../../Services/CookieService";
import { connect } from "react-redux";
import { setCustomerPaymentStatus, setUser,appendAlert } from "../../../actions";

class AddTenantPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: CookieService.getCookie("axTok"),
      user: CookieService.getArrayCookie("axUserDetails"),
      data: this.props.location.state ? this.props.location.state.data : [],
      accountStatus: this.props.location.state
        ? this.props.location.state.status
        : "verified",
      type: this.props.location.state ? this.props.location.state.type : "",
      activePaymentMethod: false,
      isLoading: true,
      isButtonDisable: true,
      account_holder_name: "",
      account_holder_type: "individual",
      routing_number: "",
      account_number: "",
      verifiedAcc: "",
      verifiedId: "",
      token_id: "",
      bank_account_id: "",
      deposit1: "",
      deposit2: "",
      nextPayment: false,
      nextPayment1: false,
      accountNumbers: [],
      accountNum: "",
      accountId: [],
      customerData: [],
      status: this.props.location.state
        ? this.props.location.state.status
        : "verified",
      addLoading: false,
      verifiedLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
    this.changeTokenNull = this.changeTokenNull.bind(this);
    this.next = this.next.bind(this);
  }
  changeTokenNull() {
    this.setState({ accountStatus: "add" });
  }
  addCard() {
    if (this.state.accountNum === "") {
      this.setState({ nextPayment1: true });
    } else {
      this.setState({ nextPayment1: false });
      let formBody = [];
      let details = {
        sourceId: this.state.accountNum,
      };
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      try {
        updatePaymentMethod(formBody).then((response) => {
          if (response.data.status) {
            this.props.appendAlert(
              "Successfully Default card changed",
              "success"
            );
            this.props.history.push({
              pathname: "/payments",
            });
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
          this.setState({
            isButtonDisable: true,
          });
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
  async componentDidMount() {
    await this.setState({
      user: CookieService.getArrayCookie("axUserDetails"),
    });
    // if (this.props.customerPaymentStatus === null) {
    //   this.props.history.push({
    //     pathname: "/payments",
    //   });
    // }
    checkPaymentStatus().then((response) => {
      if (response.status === 200) {
        if (response.data.data === "verified") {
          this.setState({
            accountStatus: "add",
          });
          this.props.setCustomerPaymentStatus(response.data.data);
          userDetail(this.props.userDetail.username).then((response) => {
            if (response.status === 200) {
              CookieService.setArrayCookie("axUserDetails", response.data, 7);
              this.props.setUser(response.data);
            } else {
              // this.props.appendAlert("Something went wrong!, Please try again later.", "danger");
            }
          });
        }
      } else {
        this.props.appendAlert(
          "Something went wrong!, Please try again later.",
          "danger"
        );
      }
    });

    if (this.props.userDetail.stripeCustomerId !== null) {
      try {
        getCustomerPayment().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              let x = [];
              let y = [];
              response.data.sources.data.map((data, index) =>
                response.data.defaultSource !== data.id
                  ? (x.push("**** **** ****" + data.last4), y.push(data.id))
                  : this.setState({
                      status: data.status,
                      verifiedAcc: data.last4,
                      verifiedId: data.id,
                    })
              );
              this.setState({
                accountId: y,
                accountNumbers: x,
                customerData: response.data,
                isLoading: false,
              });
            } else {
              console.log("No data!");
              this.setState({ noData: true, isLoading: false });
            }
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  handleChange(event) {
    let { name, value } = event.target;
    if (name === "routing_number" || name === "account_number") {
      let num = value.replace(/[^\d]/g, "");
      value = num;
      this.setState({ [name]: value });
    } else if (name === "deposit1" || name === "deposit2") {
      let num = value.replace(/[^\d.]/g, "");
      value = num;
      this.setState({ [name]: value });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleSubmit = async () => {
    if (
      this.state.account_holder_name === "" ||
      this.state.routing_number === "" ||
      this.state.account_number === ""
    ) {
      this.setState({ nextPayment: true });
    } else {
      this.setState({ nextPayment: false, addLoading: true });

      try {
        this.setState({ isButtonDisable: false });
        let formBody = [];
        let details = {
          "bank_account[country]": "US",
          "bank_account[currency]": "usd",
          "bank_account[account_holder_name]": this.state.account_holder_name,
          "bank_account[account_holder_type]": this.state.account_holder_type,
          "bank_account[routing_number]": this.state.routing_number,
          "bank_account[account_number]": this.state.account_number,
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        stripeToken(formBody).then(async (response) => {
          if (response.status) {
            this.setState({
              token_id: response.data.id,
              bank_account_id: response.data.bank_account.id,
            });
            let item = {
              email: this.props.userDetail.username,
              token: this.state.token_id,
              bankAccountId: this.state.bank_account_id,
            };
            let form_data = [];
            for (let key in item) {
              let encodedKey = encodeURIComponent(key);
              let encodedValue = encodeURIComponent(item[key]);
              form_data.push(encodedKey + "=" + encodedValue);
            }
            form_data = form_data.join("&");
            await addPaymentMethod(this.state.type, form_data)
              .then((response2) => {
                this.setState({ addLoading: false });
                if (response2.data) {
                  this.setState({ nextPayment: false });
                  this.setState({ accountStatus: "new" });
                  this.props.appendAlert("Please Verify the amounts", "info");
                } else {
                  if (response2.data === undefined) {
                    if (response2.response.data !== undefined) {
                      this.props.appendAlert(
                        response2.response.data.message,
                        "danger"
                      );
                    } else {
                      this.props.appendAlert("Try again later", "warning");
                    }
                  }
                }
                this.setState({ isButtonDisable: true });
              })
              .catch((res3) => {
                this.props.appendAlert(
                  "Error in creating customer.Try again later!",
                  "danger"
                );
              });
            if (this.state.customerData.length === 0) {
              try {
                getCustomerPayment().then((response) => {
                  if (response.status === 200) {
                    if (response.data.length !== 0) {
                      let x = [];
                      let y = [];
                      response.data.sources.data.map((data, index) =>
                        response.data.defaultSource !== data.id
                          ? (x.push("**** **** ****" + data.last4),
                            y.push(data.id))
                          : this.setState({
                              status: data.status,
                              verifiedAcc: data.last4,
                              verifiedId: data.id,
                            })
                      );
                      this.setState({
                        accountId: y,
                        accountNumbers: x,
                        customerData: response.data,
                        isLoading: false,
                        addLoading: false,
                      });
                    } else {
                      console.log("No data!");
                      this.setState({
                        noData: true,
                        isLoading: false,
                        addLoading: false,
                      });
                    }
                  } else {
                    this.setState({
                      isLoading: false,
                    });
                    this.props.appendAlert(
                      "Something went wrong!, Please try again later.",
                      "danger"
                    );
                  }
                });
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            this.setState({
              noData: true,
              isLoading: false,
              addLoading: false,
            });
            if (
              response.response.data.error.code === "account_number_invalid"
            ) {
              this.props.appendAlert(
                "Account number Invalid. Please type correct account number",
                "danger"
              );
            } else if (response.response.data.error.code) {
              this.props.appendAlert(
                response.response.data.error.message,
                "warning"
              );
            } else {
              this.setState({
                isLoading: false,
              });
              this.props.appendAlert(
                "Something went wrong!, Please try again later.",
                "danger"
              );
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  verifyAccount = async () => {
    this.setState({ isButtonDisable: false, verifiedLoading: true });
    if (this.state.deposit1 === "" || this.state.deposit2 === "") {
      this.setState({ nextPayment: true });
    } else {
      this.setState({ nextPayment: false });
      try {
        let formBody = [];
        let details = {
          bankAccoutnId:
            this.state.bank_account_id !== ""
              ? this.state.bank_account_id
              : this.state.customerData.defaultSource,
          customerId: this.state.customerData.id,
          deposit1: parseInt(parseFloat(this.state.deposit1) * 100),
          deposit2: parseInt(parseFloat(this.state.deposit2) * 100),
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        verifyPaymentMethod(formBody).then((response) => {
          this.setState({ verifiedLoading: false });
          if (
            response.data.data === "Your account verified successfully verified"
          ) {
            if (this.props.userDetail.stripeCustomerId === null) {
              userDetail(this.props.userDetail.username).then((response1) => {
                if (response1.status === 200) {
                  CookieService.setArrayCookie(
                    "axUserDetails",
                    response1.data,
                    7
                  );
                  this.props.setUser(response1.data);
                  checkPaymentStatus().then((response2) => {
                    if (response2.status === 200) {
                      if (response2.status) {
                        this.props.setCustomerPaymentStatus(
                          response2.data.data
                        );
                        this.props.history.goBack();
                      }
                    }
                  });
                }
              });
            } else {
              this.props.history.goBack();
            }
            this.props.appendAlert("Verified successfully.", "success");
          } else {
            this.props.appendAlert(
              "Invalidate verification amount !!!",
              "danger"
            );
          }
        });
      } catch (error) {
        throw error;
      }
    }
  };
  checkValidate(value) {
    if (value === "" && this.state.nextPayment) {
      return true;
    }
    return false;
  }
  checkValidate1(value) {
    if (value === "" && this.state.nextPayment1) {
      return true;
    }
    return false;
  }
  next() {
    this.props.history.push({
      pathname: "/payments",
    });
  }
  render() {
    return (
      <div
        className="medium-container"
        style={{ marginTop: "80px", paddingTop: "30px", marginBottom: "16%" }}
      >
        <div className="container-fluid">
          {(this.state.accountStatus === "" ||
            this.state.accountStatus === "new" ||
            this.state.accountStatus === "add") && (
            <div className="row pb-2 ">
              <div className="col informationListAppAlign pt-0 pb-0">
                <div
                  className={
                    this.state.accountStatus === ""
                      ? "informationListAppWarning"
                      : this.state.accountStatus === "new" ||
                        this.state.accountStatus === "add"
                      ? "informationListAppInfo"
                      : "informationListApp"
                  }
                >
                  <i className="fa fa-info-circle " aria-hidden="true"></i>
                  &nbsp;&nbsp;
                  <span className="searchListContentApp">
                    {" "}
                    {this.state.accountStatus === ""
                      ? "No any payment methods available. Add your payment method here"
                      : this.state.accountStatus === "new"
                      ? "Verify your payment method for " +
                        (this.state.account_number !== ""
                          ? this.state.account_number
                          : "**** **** ****" + this.state.verifiedAcc)
                      : this.state.accountStatus === "add"
                      ? "Add new payment method here."
                      : null}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="row   pt-3 cardApplication pr-3 pl-3 pb-4 pm-2 ">
            {this.state.accountStatus === "new" ||
            this.state.accountStatus === "verification_failed" ? (
              <>
                <div className="col ">
                  <h1 className="one pl-0 pb-3 pt-2">Verify ACH</h1>
                  <ApplicationInput
                    name="Deposit Amount 1"
                    type="text"
                    target="deposit1"
                    place="Deposit Amount 1."
                    onChange={this.handleChange}
                    important="true"
                    errorMessage="Deposit Amount 1."
                    validate={this.checkValidate(this.state.deposit1)}
                    value={this.state.deposit1}
                  />
                  <ApplicationInput
                    name="Deposit Amount 2"
                    type="text"
                    target="deposit2"
                    onChange={this.handleChange}
                    important="true"
                    place="Deposit Amount 2."
                    errorMessage="Deposit Amount 2."
                    validate={this.checkValidate(this.state.deposit2)}
                    value={this.state.deposit2}
                  />
                  <br></br>
                  <div className="pull-right ">
                    <div
                      className=" "
                      style={{ marginRight: "10px", marginBottom: "-20px" }}
                    >
                      {this.state.verifiedLoading && <Loader3 />}
                    </div>
                    <button
                      type="submit"
                      onClick={() => this.verifyAccount()}
                      className={
                        this.state.verifiedLoading
                          ? "secDisable "
                          : "buttonPri "
                      }
                      disabled={this.state.verifiedLoading && "disabled"}
                    >
                      Verify Amounts
                    </button>
                  </div>
                  {(this.state.status === "new" ||
                    this.state.accountStatus === "verification_failed") &&
                    this.state.accountNumbers.length > 0 &&
                    (this.state.account_number === "" ||
                      this.state.account_number ===
                        this.state.verifiedAcc.substr(
                          this.state.verifiedAcc.length - 4
                        )) && (
                      <>
                        <h1 className="one pt-5 pb-3 pl-0 mt-5">
                          Do you want to add another account as default.
                        </h1>
                        <ApplicationInput
                          name="Account Number"
                          target="accountNum"
                          type="optional"
                          options={this.state.accountNumbers}
                          optionValue={this.state.accountId}
                          onChange={this.handleChange}
                          important="true"
                          errorMessage="account number"
                          validate={this.checkValidate1(this.state.accountNum)}
                          value={this.state.accountNum}
                        />
                        <div className="text-right pt-3">
                          <button
                            type="submit"
                            className="pri"
                            onClick={() => this.addCard()}
                          >
                            Change as default
                          </button>
                        </div>
                      </>
                    )}
                </div>
              </>
            ) : (
              <div className="col pb-4">
                <h1 className="one pl-0 pb-3 pt-2">
                  <i className="fa fa-university pr-3" aria-hidden="true"></i>
                  Bank account
                </h1>
                <ApplicationInput
                  name="Full Name"
                  type="text"
                  target="account_holder_name"
                  onChange={this.handleChange}
                  important="true"
                  place="Full Name"
                  errorMessage="Full Name"
                  validate={this.checkValidate(this.state.account_holder_name)}
                  value={this.state.account_holder_name}
                />
                <ApplicationInput
                  name="Routing Number"
                  type="text"
                  target="routing_number"
                  onChange={this.handleChange}
                  important="true"
                  place="110000000"
                  errorMessage="Routing Number"
                  validate={this.checkValidate(this.state.routing_number)}
                  value={this.state.routing_number}
                />
                <ApplicationInput
                  name="Account Number"
                  type="text"
                  target="account_number"
                  onChange={this.handleChange}
                  important="true"
                  place="000123456789"
                  errorMessage="Account Number"
                  validate={this.checkValidate(this.state.account_number)}
                  value={this.state.account_number}
                />
                <br></br>
                <br></br>

                <div className="pull-right">
                  <div
                    className=" "
                    style={{ marginRight: "10px", marginBottom: "-20px" }}
                  >
                    {this.state.addLoading && <Loader3 />}
                  </div>
                  <button
                    type="submit"
                    className={
                      this.state.addLoading ? "secDisable " : "buttonPri "
                    }
                    disabled={this.state.addLoading && "disabled"}
                    onClick={() => this.handleSubmit()}
                  >
                    Add Account
                  </button>
                </div>
              </div>
            )}
            <br></br>
            <div className="col ">
              <h1 className="one pl-0 pt-2">
                Steps to verify your ACH payment method.
              </h1>
              <br></br>
              <div className="informationListAppNormal mt-1">
                <span className="spanRound">1</span>&nbsp;&nbsp;
                <span className="searchListContentApp">
                  System automatically sends two small deposits for verification
                  purpose. These deposits take 1-2 business days to appear on
                  your online statement.
                </span>
              </div>
              <div className="informationListAppNormal mt-1">
                <span
                  className={
                    this.state.accountStatus === "new" ||
                    this.state.accountStatus === "verified"
                      ? "spanRound"
                      : "spanRoundDark"
                  }
                >
                  2
                </span>
                &nbsp;&nbsp;
                <span className="searchListContentApp">
                  The statement has a description that includes AMTS followed by
                  the two microdeposit amounts.
                </span>
              </div>
              <div className="informationListAppNormal mt-1">
                <span
                  className={
                    this.state.accountStatus === "verified"
                      ? "spanRound"
                      : "spanRoundDark"
                  }
                >
                  3
                </span>
                &nbsp;&nbsp;
                <span className="searchListContentApp">
                  Once you receive the two microdeposit amounts, update it in
                  your payment portal.
                </span>
              </div>
              {this.state.status === "new" &&
                (this.state.account_number === "" ||
                  this.state.account_number ===
                    this.state.verifiedAcc.substr(
                      this.state.verifiedAcc.length - 4
                    )) && (
                  <>
                    <button
                      type="submit"
                      className="pri pull-right mt-4"
                      onClick={() =>
                        this.setState({ accountStatus: "add", type: "addNew" })
                      }
                    >
                      Add New Account
                    </button>
                  </>
                )}
            </div>
          </div>

          {this.state.accountStatus !== "new" &&
            this.state.accountStatus !== "verification_failed" && (
              <div
                className="divider"
                style={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <span></span>
                <span>OR</span>
                <span></span>
              </div>
            )}
          {this.state.accountStatus !== "new" &&
            this.state.accountStatus !== "verification_failed" && (
              <div className="row  cardApplication pr-3 pl-3 pb-4">
                <div className="col-md-6">
                  <h1 className="one pl-0 pt-3">
                    <i
                      className="fa fa-credit-card-alt pr-3"
                      aria-hidden="true"
                    ></i>
                    Card{" "}
                  </h1>
                  <StripeProvider apiKey={stripeKey}>
                    <Elements>
                      <CardPayment
                        setCustomerPaymentStatus={
                          this.props.setCustomerPaymentStatus
                        }
                        setUser={this.props.setUser}
                        access_token={this.state.access_token}
                        email={this.props.userDetail.username}
                        history={this.props.history}
                        type={this.state.type}
                        pushAlert={this.props.appendAlert}
                        next={this.next}
                        changeTokenNull={this.changeTokenNull}
                        paymentToken={
                          this.state.accountStatus === "add" ? "" : ""
                        }
                        user={this.props.userDetail}
                      />
                    </Elements>
                  </StripeProvider>
                </div>
                <div
                  className="col-md-6 informationListAppAlign pt-3"
                  style={{ paddingTop: "50px" }}
                >
                  <div className="informationListAppNormal">
                    <i
                      className="fa fa-info-circle text-info"
                      aria-hidden="true"
                    ></i>
                    &nbsp;&nbsp;
                    <span className="searchListContentApp">
                      Your card will use for future payments. You can update the
                      payment method at your dashboard later.
                    </span>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerPayment: state.customerPaymentReducer,
    customerPaymentStatus: state.customerPayment,
    user: state.userReducer,
    userDetail: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { setCustomerPaymentStatus, setUser ,appendAlert})(
    AddTenantPayment
  )
);
