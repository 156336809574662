import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { LicenseData } from "../../../Services/ApiServices";
import Loader3 from "../../Loader/loader3";
import "./application.css";
class LicenseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      spinner: true,
      coverImage: [],
      cam: false,
      camPlay: true,
    };
  }
  componentDidMount() {
    this.setState({ cam: false, camPlay: true, coverImage: [] });
  }

  async changeCam() {
    await this.setState({ cam: true, camPlay: true, coverImage: [] });
    try {
      let video = document.querySelector("#video");
      let stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });
      video.srcObject = stream;
      video.setAttribute("autoplay", true);
    } catch (error) {}
  }
  async changeUpload() {
    try {
      let video = document.querySelector("#video");
      const mediaStream = video.srcObject;
      const tracks = mediaStream.getTracks();
      tracks[0].stop();
      tracks.forEach((track) => track.stop());
    } catch (error) {}
    this.setState({ cam: false, camPlay: true, coverImage: [] });
  }

  play() {
    if (this.state.camPlay) {
      try {
        let video = document.querySelector("#video");
        video.pause();
        let canvas = document.createElement("canvas");
        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, canvas.width, canvas.height);

        let image_data_url = canvas.toDataURL("image/jpeg");
        let blob = new Blob([image_data_url], {
          type: "image/jpeg",
        });

        this.setState({ coverImage: blob });
      } catch (error) {}
      this.setState({ camPlay: false });
    } else {
      try {
        let video = document.querySelector("#video");
        video.play();
      } catch (error) {}
      this.setState({ camPlay: true });
    }
  }
  changeLicense() {
    this.changeUpload();
    this.props.changeLicense();
  }
  upload() {
    this.props.keepWait();
    setTimeout(
      function () {
        this.changeLicense();
        this.setState({ coverImage: [], cam: false, camPlay: true });
      }.bind(this),
      1000
    );
    if (this.state.coverImage.length !== 0) {
      const formData = new FormData();
      formData.append("file", this.state.coverImage);
      LicenseData(formData)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            if (data.firstName) {
              this.props.changeLicenseData(data);
              this.props.pushAlert(
                "Image uploaded and verified successfully..",
                "success"
              );
            } else {
              this.props.removeKeepWait();
              this.props.pushAlert("No Details Extracted..", "warning");
            }
          } else {
            this.props.removeKeepWait();
          }
        })
        .catch((err) => {
          this.props.removeKeepWait();
          this.props.pushAlert("Image details couldn't be gathered", "warning");

          console.log(err);
        });
    }
  }
  render() {
    return (
      <>
        <div
          className={
            this.props.licenseModal
              ? "modal fade bd-example-modal show"
              : "modal fade bd-example-modal"
          }
          id="licenceModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="licenceModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1000" }}
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content">
              <div
                className=" shadow text-center pt-3"
                style={{ height: "60px", borderBottom: "1px solid #e09b81" }}
              >
                <h1
                  className="modal-title licenseHead textPri"
                  id="licenceModalLabel"
                  style={{ fontSize: "20px", marginTop: "0px" }}
                >
                  Upload Your License for Details
                </h1>
              </div>
              <div className="modal-body bodyAvailable p-5 text-center">
                {!this.state.cam ? (
                  <Dropzone
                    accept=".jpeg,.png,.jpg"
                    onDrop={(acceptedFiles) => {
                      this.setState({ coverImage: acceptedFiles[0] });
                      this.props.keepWait();
                      setTimeout(
                        function () {
                          this.props.changeLicense();
                          this.setState({ coverImage: [] });
                        }.bind(this),
                        1000
                      );
                      if (this.state.coverImage.length !== 0) {
                        const formData = new FormData();
                        formData.append("file", this.state.coverImage);
                        LicenseData(formData)
                          .then((response) => {
                            
                            if (response.status === 200) {
                              let data = response.data;
                              if (data.firstName) {
                                this.props.changeLicenseData(data);
                                this.props.pushAlert(
                                  "Image uploaded and verified successfully..",
                                  "success"
                                );
                              } else {
                                this.props.removeKeepWait();
                                this.props.pushAlert(
                                  "No Details Extracted..",
                                  "warning"
                                );
                              }
                            } else {
                              this.props.removeKeepWait();
                            }
                          })
                          .catch((err) => {
                            this.props.removeKeepWait();
                            this.props.pushAlert(
                              "Image details couldn't be gathered",
                              "warning"
                            );

                            console.log(err);
                          });
                      } 
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.state.coverImage.length === 0 ? (
                            <div
                              className="dragDrop text-center pt-5"
                              style={{ fontSize: "60px" }}
                            >
                              <div className="row">
                                <div className="col">
                                  <i
                                    className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <p className="licenseHead">
                                    Drag Or Click to Upload
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <Loader3 />
                              <img
                                src={URL.createObjectURL(this.state.coverImage)}
                                className="licenseImage"
                                alt="License"
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </Dropzone>
                ) : (
                  <div>
                    <video id="video" width="320" height="240" autoplay></video>
                  </div>
                )}
              </div>

              <div
                className=" p-3 shadow1"
                style={{ borderTop: "1px solid #d15428" }}
              >
                {!this.state.cam ? (
                  <div
                    className="social-container-step licenseButton pull-left mr-2"
                    data-dismiss="modal"
                    onClick={() => this.changeCam()}
                  >
                    <i
                      className="fa fa-camera-retro fa-10x "
                      aria-hidden="true"
                    ></i>
                  </div>
                ) : (
                  <>
                    <div
                      className="social-container-step licenseButton pull-left mr-2"
                      data-dismiss="modal"
                      onClick={() => this.changeUpload()}
                    >
                      <i
                        className="fa fa-upload fa-10x "
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div
                      className="social-container-step licenseButton pull-left mr-2"
                      data-dismiss="modal"
                      onClick={() => this.play()}
                    >
                      {this.state.camPlay ? (
                        <i
                          className="fa fa-pause fa-10x "
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-play fa-10x "
                          aria-hidden="true"
                        ></i>
                      )}
                    </div>
                    {this.state.camPlay ? (
                      <div
                        className="social-container-step licenseButtonDis pull-left "
                        data-dismiss="modal"
                      >
                        <i
                          className="fa fa-check fa-10x "
                          aria-hidden="true"
                        ></i>
                      </div>
                    ) : (
                      <div
                        className="social-container-step licenseButton pull-left"
                        data-dismiss="modal"
                        onClick={() => this.upload()}
                      >
                        <i
                          className="fa fa-check fa-10x "
                          aria-hidden="true"
                        ></i>
                      </div>
                    )}
                  </>
                )}
                <div
                  className="social-container-step licenseButton pull-right"
                  data-dismiss="modal"
                  onClick={() => this.changeLicense()}
                >
                  <i className="fa fa-close fa-10x " aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LicenseModal;
