import React, { Component } from "react";
import { withRouter } from "react-router";

import "../../officeApplication.css";

class OfficeApplicationStep6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      years: [],
      activeReference: 1,
    };
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.uploadQuestionImage = this.uploadQuestionImage.bind(this);
  }
  componentDidMount() {
    var x = [];
    for (var i = parseInt(new Date().getFullYear()); i > 1900; i--) {
      x.push(i);
    }
    this.setState({ years: x });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    this.setState({ [name]: checked });
  }
  addReference() {
    this.setState({
      activeReference: this.state.activeReference + 1,
      stage: this.state.stage + 1,
    });
  }
  uploadQuestionImage(e) {
    let { name } = e.target;
    if (e.target.files[0]) {
      var reader = new FileReader();
      reader.onloadend = () => {
        this.props.onChangeParent(name, reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }
  render() {
    return (
      <div className="row " id={"officeAppStep" + this.props.index}>
        <div className="col-3"></div>
        <div className="col-9  pt-1 d-flex justify-content-center">
          <div className="navBottom1 " style={{ width: "100%" }}>
            <p className="officeApplicationTitle mb-4 ">Upload Documents</p>

            <p className="officeApplicationSubTitle1 pb-0 mb-3 pt-0 mt-2 pl-3 text-dark">
              {" "}
              Profit & Loss
            </p>

            <div className="row pr-3 pl-3 pt-3 ">
              <div className="col-1">
                <div className="pt-1 span2">1 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"profitLossDoc1"}
                  className={
                    this.props.checkNull(this.props.state.profitLossDoc1)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"profitLossDoc1"}
                  style={{ display: "none" }}
                  type="file"
                  name="profitLossDoc1"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.profitLossDoc1 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.profitLossYear1)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"profitLossYear1"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="row pr-3 pl-3">
              <div className="col-1">
                <div className="pt-1 span2 ">2 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"profitLossDoc2"}
                  className={
                    this.props.checkNull(this.props.state.profitLossDoc2)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"profitLossDoc2"}
                  style={{ display: "none" }}
                  type="file"
                  name="profitLossDoc2"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.profitLossDoc2 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.profitLossYear2)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"profitLossYear2"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="row pr-3 pl-3">
              <div className="col-1">
                <div className="pt-1 span2 ">3 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"profitLossDoc3"}
                  className={
                    this.props.checkNull(this.props.state.profitLossDoc3)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"profitLossDoc3"}
                  style={{ display: "none" }}
                  type="file"
                  name="profitLossDoc3"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.profitLossDoc3 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.profitLossYear3)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"profitLossYear3"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>

            <p className="officeApplicationSubTitle1 pb-0 mb-3 mt-2 pl-3 mt-4 text-dark">
              {" "}
              Financial Statement
            </p>
            <div className="row pr-3 pl-3 pt-3">
              <div className="col-1">
                <div className="pt-1 span2">1 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"financialDoc1"}
                  className={
                    this.props.checkNull(this.props.state.financialDoc1)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"financialDoc1"}
                  style={{ display: "none" }}
                  type="file"
                  name="financialDoc1"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.financialDoc1 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.financialYear1)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"financialYear1"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="row pr-3 pl-3">
              <div className="col-1">
                <div className="pt-1 span2 ">2 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"financialDoc2"}
                  className={
                    this.props.checkNull(this.props.state.financialDoc2)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"financialDoc2"}
                  style={{ display: "none" }}
                  type="file"
                  name="financialDoc2"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.financialDoc2 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.financialYear2)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"financialYear2"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="row pr-3 pl-3">
              <div className="col-1">
                <div className="pt-1 span2 ">3 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"financialDoc3"}
                  className={
                    this.props.checkNull(this.props.state.financialDoc3)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"financialDoc3"}
                  style={{ display: "none" }}
                  type="file"
                  name="financialDoc3"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.financialDoc3 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.financialYear3)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"financialYear3"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>
            <p className="officeApplicationSubTitle1 pb-0 mb-3 mt-2 pl-3 mt-4 text-dark">
              {" "}
              Tax
            </p>
            <div className="row pr-3 pl-3 pt-3">
              <div className="col-1">
                <div className="pt-1 span2">1 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"TaxDoc1"}
                  className={
                    this.props.checkNull(this.props.state.TaxDoc1)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"TaxDoc1"}
                  style={{ display: "none" }}
                  type="file"
                  name="TaxDoc1"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.TaxDoc1 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.TaxYear1)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"TaxYear1"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="row pr-3 pl-3">
              <div className="col-1">
                <div className="pt-1 span2 ">2 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"TaxDoc2"}
                  className={
                    this.props.checkNull(this.props.state.TaxDoc2)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"TaxDoc2"}
                  style={{ display: "none" }}
                  type="file"
                  name="TaxDoc2"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.TaxDoc2 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.TaxYear2)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"TaxYear2"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="row pr-3 pl-3">
              <div className="col-1">
                <div className="pt-1 span2 ">3 )</div>
              </div>
              <div className="col-5 pt-1">
                <label
                  htmlFor={"TaxDoc3"}
                  className={
                    this.props.checkNull(this.props.state.TaxDoc3)
                      ? "buttonUploadAlert"
                      : "buttonUpload"
                  }
                >
                  {" "}
                  <i className="fa fa-file-text" aria-hidden="true"></i>{" "}
                  &nbsp;&nbsp;Upload
                </label>
                <input
                  id={"TaxDoc3"}
                  style={{ display: "none" }}
                  type="file"
                  name="TaxDoc3"
                  onChange={this.uploadQuestionImage}
                />
                {this.props.state.TaxDoc3 !== "" && (
                  <i
                    className="fa fa-check pt-2 pl-4 ax-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              <div className="col-4 pt-1">
                <select
                  className={
                    this.props.checkNull(this.props.state.TaxYear3)
                      ? "OfficeApplicationInputSelect custom-select alert mb-0"
                      : "OfficeApplicationInputSelect custom-select"
                  }
                  onChange={this.props.onChange}
                  name={"TaxYear3"}
                >
                  <option value="" disabled="">
                    Select year
                  </option>
                  {this.state.years.map((todo) => {
                    return <option value={todo}>{todo}</option>;
                  })}
                </select>
              </div>
            </div>
            <button
              className="pull-right mt-4"
              data-target="#exampleModal"
              data-toggle="modal"
            >
              Review
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfficeApplicationStep6);
