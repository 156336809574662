import React, { Component } from "react";
import Loader3 from "../../Loader/loader3";
import "./application.css";
class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      dataList: this.props.list,
      value: "",
      search: [],
      active: 0,
    };
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleFocus(e) {
    this.setState({ listOpen: true });
  }

  handleBlur(e) {
    setTimeout(
      function () {
        this.setState({ listOpen: false });
      }.bind(this),
      300
    );
  }
  handleClick() {
    this.setState({ listOpen: true });
    if (this.state.value === "") {
      this.setState({ dataList: this.props.list });
    }
  }
  onKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.changeState(
        this.props.target,
        this.state.dataList[this.state.active]
      );
    }
    this.setState({ listOpen: false });
  };
  onKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      this.setState({ active: this.state.active + 1 });
      this.setState({ listOpen: true });
    }
  };
  onKeyUp = (event) => {
    if (event.key === "ArrowUp") {
      this.setState({ active: this.state.active - 1 });
      this.setState({ listOpen: true });
    }
  };
  handleChange(e) {
    // eslint-disable-next-line
    var letterNumber = /^[0-9a-zA-Z/\.]+$/;
    if (
      e.target.value.replace(/\s/g, "").match(letterNumber) ||
      e.target.value === ""
    ) {
      let { value } = e.target;
      var filteredNames = this.props.list.filter(function (word) {
        return word.toLowerCase().search(value.toLowerCase()) !== -1;
      });
      if (!this.props.searchDisable) {
        this.setState({ dataList: filteredNames, value: value });
      }
      this.setState({ listOpen: true });
      if (this.props.inputDisable) {
        if (filteredNames.length !== 0) {
          if (!this.props.searchDisable) {
            this.props.onChange(e);
          }
        }
      } else {
        this.props.onChange(e);
      }
    }
  }
  changeState1(data) {
    this.setState({ listOpen: false });
    this.props.changeState(this.props.target, data);
  }
  render() {
    const style = {
      paddingRight: this.props.icon && "60px",
      paddingLeft: this.props.iconL && "40px",
    };

    return (
      <>
        {this.props.id === "" ? (
          <span
            className={
              this.state.titleBreak
                ? "searchListHeadApp"
                : "searchListHeadApp noBreak"
            }
          >
            -
          </span>
        ) : this.props.id === "null" ? (
          <></>
        ) : (
          <span
            className={
              this.state.titleBreak
                ? "searchListHeadApp"
                : "searchListHeadApp noBreak"
            }
          >
            {this.props.id}
            {this.props.important && (
              <span className="textPri" style={{ fontSize: "15px" }}>
                {" "}
                &nbsp;*
              </span>
            )}
          </span>
        )}
        {this.props.iconL && (
          <i
            className={this.props.iconL + " fa  fa-10x absolute pointer iconL"}
            style={{ marginLeft: "30px", marginTop: "45px", left: 0 }}
            aria-hidden="true"
          ></i>
        )}
        {this.props.iconClose && this.props.value !== "" && (
          <i
            className={
              this.props.iconClose + " fa  fa-10x absolute pointer iconL"
            }
            style={{ marginLeft: "14.5rem", marginTop: "45px", left: 0 }}
            aria-hidden="true"
            onClick={() => this.props.iconCloseClick(this.props.target)}
          ></i>
        )}
        <div>
          <input
            className={
              this.props.validate && this.props.important
                ? this.props.type === "2"
                  ? "ApplicationInputAgg alert"
                  : this.props.type === "3"
                  ? "form-control tag alert1"
                  : "ApplicationInput alert"
                : this.props.type === "2"
                ? "ApplicationInputAgg "
                : this.props.type === "3"
                ? "form-control  tag "
                : "ApplicationInput "
            }
            type={
              this.props.value === "please wait..." ? "text" : this.props.type
            }
            onFocus={this.handleFocus}
            onClick={this.handleClick}
            placeholder={this.props.place}
            onChange={this.handleChange}
            name={this.props.target}
            value={this.props.value}
            disabled={this.props.value === "please wait..." && "disabled"}
            max={this.props.max}
            min={this.props.min}
            style={style}
            onBlur={this.handleBlur}
            autocomplete="nope"
            onKeyPress={this.onKeyPress}
            onKeyUp={this.onKeyUp}
            onKeyDown={this.onKeyDown}
          ></input>{" "}
          {this.props.icon && (
            <i
              className={this.props.icon + " fa  fa-10x absolute pointer"}
              style={{ marginLeft: "-40px", marginTop: "20px" }}
              aria-hidden="true"
              onClick={() => this.props.clickIcon()}
            ></i>
          )}
          {this.props.value === "please wait..." && <Loader3 />}
          {this.props.validate && this.props.important && (
            <p className="spanValidateApp textPri">
              Please Enter {this.props.errorMessage}
            </p>
          )}
          {this.state.listOpen && (
            <div className="bg-white searchList">
              {this.state.dataList.map((data, index) =>
                index === this.state.active ? (
                  <div
                    className="drop-list act"
                    onClick={() => this.changeState1(data)}
                  >
                    <span className="searchInput">{data}</span>
                  </div>
                ) : (
                  <div
                    className="drop-list"
                    onClick={() => this.changeState1(data)}
                  >
                    <span className="searchInput">{data}</span>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SearchInput;
