import React, { Component } from "react";
import "./MaintenanceRequestInvitations.css";
import MaintenanceRequestInvitationList from "./MaintenanceRequestInvitationList";

class MaintenanceRequestInvitations extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };
  }
  
  getViewArray() {
    if (this.state.expand) {
      return this.props.data.providerInvites;
    } else {
      return this.props.data.providerInvites.slice(0, 3);
    }
  }
  render() {
    return (
      <div className="maintenanceRequestCard mt-3 pr-0">
        <div className="pr-4">
          <span className="maintenanceRequestDetailText1 ">Invitation</span>
          {this.props.data.providerInvites.length > 3 && (
            <>
              <span
                className="maintenanceRequestDetailText3 pointer pull-right pt-1 "
                onClick={() => this.setState({ expand: !this.state.expand })}
              >
                {this.state.expand ? "See less" : "See more"}
              </span>
              <span className="maintenanceRequestDetailText1  pull-right requestInvitationDivider pt-1">
                |
              </span>
            </>
          )}
          <span className="maintenanceRequestDetailText3 pointer  pull-right pt-1"  onClick={()=>this.props.inviteProvider()}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Invite
          </span>
        </div>
        <div className="requestInvitationText pr-4">
          {this.getViewArray().map((data, index) => (
            <>
              <MaintenanceRequestInvitationList data={data} />
              {this.getViewArray().length - 1 !== index && (
                <hr className="invitationDivider"></hr>
              )}
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default MaintenanceRequestInvitations;
