import React, { Component } from "react";
import "./SplitToggleButton.css";

class SplitToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
     <div className={this.props.color==="yellow"?"yellowDropdownButton":""}>
     <button className="dropdownButton" onClick={()=>this.props.Click&&this.props.Click()}>{this.props.text}</button>
     <div className="dropdownButton-component">
       <button className="dropdownButton icon">
         <i className="fa fa-caret-down"></i>
       </button>
       <div className="dropdownButton-content p-0">
         {this.props.children}
       </div>
     </div>
   </div>
    );
  }
}

export default (SplitToggleButton);
