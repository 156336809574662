import React, { useEffect, useState } from "react";
import "./style.css";
import { withRouter } from "react-router-dom";

import { useSelector } from "react-redux";
import {
  registrationConfirmation,
  resendLink,
} from "../../Services/ApiServices";
import Loader from "../Loader/Loader";

const RegistrationConfirm = (props) => {
  const dark = useSelector((state) => state.isDark);
  const [message, setMessage] = useState("");
  useEffect(() => {
    let formData = new FormData();
    formData.append("token", props.match.params.Id);
    try {
      registrationConfirmation(formData).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            setMessage(response.data.status);
          }
        } else {
          props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [props]);

  function resend() {
    let formData = new FormData();
    formData.append("token", props.match.params.Id);
    try {
      resendLink(formData).then((response) => {
        if (response.status === 200) {
          props.pushAlert("Check your email for new confirmation link", "info");
        } else {
          props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      props.pushAlert("something went wrong", "danger");
    }
  }
  return message === "" ? (
    <Loader />
  ) : (
    <div
      className={
        dark
          ? "container-fluid page404ContractDark mt-5 registrationUI "
          : "container-fluid page404Contract mt-5 registrationUI"
      }
    >
      <div className="row">
        <div className="col-12  text-center  ">
          {message === "sucessfully verified" ? (
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.000000 512.000000"
              width="12%"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#25c575"
                stroke="none"
              >
                <path
                  d="M2323 5110 c-598 -58 -1146 -316 -1571 -740 -397 -397 -640 -882
                  -729 -1460 -24 -161 -24 -539 0 -700 89 -577 330 -1059 727 -1458 397 -397
                  882 -640 1460 -729 161 -24 539 -24 700 0 577 89 1059 330 1458 727 397 397
                  640 882 729 1460 24 161 24 539 0 700 -89 577 -330 1059 -727 1458 -394 395
                  -885 641 -1450 727 -125 19 -468 27 -597 15z m562 -415 c462 -72 869 -279
                  1200 -610 334 -334 538 -738 612 -1210 12 -78 17 -170 17 -315 0 -145 -5 -237
                  -17 -315 -74 -472 -278 -876 -612 -1210 -334 -334 -738 -538 -1210 -612 -151
                  -24 -479 -24 -630 0 -472 74 -876 278 -1210 612 -334 334 -538 738 -612 1210
                  -24 151 -24 479 0 630 74 472 278 876 612 1210 368 368 805 572 1350 629 81 8
                  403 -4 500 -19z"
                />
                <path
                  d="M3470 3414 c-22 -9 -257 -237 -647 -627 l-613 -611 -257 256 c-142
                  140 -272 262 -288 271 -103 52 -219 15 -270 -87 -30 -60 -32 -115 -5 -170 12
                  -27 142 -163 367 -387 274 -273 356 -349 387 -359 51 -16 128 -7 169 21 48 33
                  1363 1347 1400 1399 28 40 32 53 32 112 0 58 -4 72 -30 109 -56 79 -159 110
                  -245 73z"
                />
              </g>
            </svg>
          ) : message === "invalid" ? (
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="10%"
              viewBox="0 0 512.000000 512.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="rgb(255, 102, 102)"
                stroke="none"
              >
                <path
                  d="M2315 5109 c-885 -90 -1647 -615 -2046 -1409 -372 -739 -355 -1642
                    44 -2365 119 -217 263 -409 438 -584 406 -405 901 -648 1494 -733 118 -17 512
                    -17 630 0 593 85 1088 328 1494 733 405 406 649 902 733 1494 17 118 17 512 0
                    630 -84 592 -328 1088 -733 1494 -396 396 -880 638 -1449 726 -125 19 -477 28
                    -605 14z m483 -280 c601 -65 1159 -368 1535 -834 377 -468 560 -1084 496
                    -1673 -65 -601 -368 -1159 -834 -1535 -138 -112 -255 -186 -426 -271 -793
                    -395 -1750 -289 -2444 271 -827 668 -1082 1832 -609 2782 124 249 263 440 455
                    626 363 352 838 577 1334 633 114 13 379 14 493 1z"
                />
                <path
                  d="M1554 3581 c-53 -32 -77 -106 -54 -168 6 -15 196 -214 423 -440 l412
                    -413 -417 -417 c-374 -375 -417 -422 -424 -458 -17 -92 44 -165 140 -165 l50
                    0 423 422 423 423 413 -412 c226 -226 422 -416 434 -422 12 -6 42 -11 67 -11
                    36 0 52 6 80 30 41 36 60 87 51 133 -6 25 -96 121 -428 454 l-422 423 422 423
                    c332 333 422 429 428 454 9 46 -10 97 -51 133 -28 24 -44 30 -80 30 -25 0 -55
                    -5 -67 -11 -12 -6 -208 -196 -434 -422 l-413 -412 -423 423 -423 422 -49 0
                    c-31 0 -62 -7 -81 -19z"
                />
              </g>
            </svg>
          ) : (
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="10%"
              viewBox="0 0 820.000000 860.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,860.000000) scale(0.100000,-0.100000)"
                fill="#dfbc23"
                stroke="none"
              >
                <path
                  d="M3795 8189 c-526 -44 -1009 -177 -1470 -406 -271 -135 -497 -279
           -737 -471 -146 -117 -432 -396 -550 -537 -485 -582 -791 -1267 -897 -2010
           -141 -990 89 -1982 651 -2812 142 -209 288 -384 482 -579 264 -263 529 -467
           837 -644 1156 -663 2571 -706 3761 -115 269 134 500 281 740 473 171 136 464
           429 602 602 457 571 743 1231 845 1945 141 990 -89 1982 -651 2812 -236 347
           -568 697 -903 949 -431 325 -933 562 -1468 693 -137 34 -373 74 -532 91 -137
           15 -575 21 -710 9z m505 -289 c794 -48 1481 -311 2119 -811 220 -173 535 -509
           714 -761 780 -1102 889 -2568 281 -3781 -177 -354 -382 -641 -661 -928 -343
           -352 -725 -618 -1163 -812 -1097 -484 -2378 -401 -3404 222 -421 255 -797 602
           -1091 1006 -698 959 -888 2217 -507 3347 141 417 334 768 623 1137 108 138
           423 453 564 565 672 533 1414 800 2295 824 36 1 139 -3 230 -8z"
                />
                <path
                  d="M3987 6629 c-142 -19 -281 -92 -384 -203 -73 -77 -105 -131 -139
           -232 -36 -109 -36 -192 4 -514 36 -290 48 -392 122 -1045 111 -975 141 -1224
           150 -1254 25 -89 103 -175 199 -219 80 -38 202 -38 282 0 96 44 174 130 199
           218 10 34 32 212 105 848 116 1013 137 1194 167 1432 41 336 43 380 24 469
           -69 328 -386 545 -729 500z"
                />
                <path
                  d="M4006 2799 c-28 -4 -83 -24 -124 -45 -147 -72 -249 -213 -273 -376
           -32 -218 96 -438 309 -527 85 -36 242 -41 332 -11 233 78 377 310 340 546 -44
           277 -303 460 -584 413z"
                />
              </g>
            </svg>
          )}
        </div>
        <div className="col mt-4 text-center">
          <h1 className="head2 mb-3">
            {message === "sucessfully verified"
              ? "Email Verification Successful"
              : message === "invalid"
              ? "This link is Invalid"
              : "This link is expired"}
          </h1>

          <h4 className="head2 ">
            {/* <b>{props.match.params.Id}</b> */}
            {message === "sucessfully verified"
              ? "Your email address is verified"
              : message === "invalid"
              ? "Please check your mail for proper link"
              : "Your link details is expired"}
          </h4>
          {message === "sucessfully verified" ? (
            <button
              className="pri mt-3"
              onClick={() => props.history.push({ pathname: "/login",state: {
                sign:true
              } })}
            >
              Sign In
            </button>
          ) : message === "expired" ? (
            <button className="pri mt-3" onClick={() => resend()}>
              Resend
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(RegistrationConfirm);
