import React, { Component } from "react";
import "../application.css";
import logo from "../../../../Images/logo.jpeg";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import {
  addMonthsYear,
  betweenYearsMonths,
  correctDateFormat,
  correctMonthYearFormat,
} from "../../../../Defined/Function";

class ApplicationReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      spinner: true,
      landLoadName: "KAMIAK",
      ManagerName: "John Smith",
      ManagerPhone: "(503) 769-4555",
      MoveInDate: "02/21/2021",
      parkingAmount: "$150",
      appCheck: "Application Cheque",
      applicationFee: "4000",
      licenseId: true,
      service: "full",
      residenceType: "own",
      residenceUnitType: "Apartment",
      petDep: "$150",
      apartmentDetails: {
        blogNo: "001",
        blogName: "Toronto",
        suitNo: "102",
        blockType: "Apartment",
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onchangeCheckOpp = this.onchangeCheckOpp.bind(this);
    this.onchangeCheckOption = this.onchangeCheckOption.bind(this);
  }
  async onChange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: checked });
  }
  async onchangeCheckOpp(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: !checked });
  }
  async onchangeCheckOption(name, value) {
    await this.setState({ [name]: value });
  }
  ModifyDate(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      let year = parseInt(dateItems[yearIndex]);
      let month = parseInt(dateItems[monthIndex]);
      let date = parseInt(dateItems[dayIndex]);
      return month + "/" + date + "/" + year;
    } else {
      return "";
    }
  }
  render() {
    const data = this.props.data;
    return (
      <>
        <div className="applicationAgreement">
          <div className="card pb-5">
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">- 1 -</span>
              </div>
            </div>
            <div className="row applicationAgreementHead pt-0 mt-0">
              <div className="col text-left applicationAgreementHeadRight pt-0 mt-0">
                <div className="text-center">
                  <img
                    src={logo}
                    className="agreementLogo pb-0 mb-0"
                    alt="logo"
                  />
                  <h6 className="headApplicationReview">
                    Axallant Corporation.
                  </h6>
                  <h6 className="headApplicationReview">
                    1315 Lawrence Avenue East, Suite 515, Toronto, ON M3A 3R3{" "}
                    <span className="text-danger">*</span>
                  </h6>
                  <h6 className="headApplicationReview">
                    Tel: (425) 890-9526 &nbsp;&nbsp; Fax: (425) 890-9526
                    &nbsp;&nbsp; Email: info@axallant.com
                  </h6>
                  <span className="span7">
                    This is the legal name and address of the Landlord provided
                    for purposes of the Residential Tenancies Act, 2006 until
                    subsequently notified.
                  </span>
                  <h2 className="headApplicationReview1 p-3">
                    RENTAL APPLICATION
                  </h2>
                </div>
              </div>
            </div>
            <div className="row applicationAgreementHead2 pt-0 mt-0">
              <div className="col-2 p-0 m-0 agreementCheckService pl-2">
                <span className="span7">
                  <b>
                    Date: &nbsp;&nbsp;{new Date().toISOString().split("T")[0]}
                  </b>
                </span>
              </div>
              <div className="col-7 p-0 m-0">
                <div className="row p-0 m-0">
                  <div className="col p-0 m-0 agreementCheck pl-2">
                    <ApplicationInput
                      name="New Application"
                      type="checkedBox"
                      target="new_application"
                      value="true"
                    />
                  </div>
                  <div className="col p-0 m-0 agreementCheck  pl-2">
                    <ApplicationInput
                      name="Assignment"
                      type="checkedBox"
                      target="assignment"
                    />
                  </div>
                  <div className="col p-0 m-0 agreementCheck  pl-2">
                    <ApplicationInput
                      name="Sublet"
                      type="checkedBox"
                      target="sublet"
                    />
                  </div>
                  <div className="col p-0 m-0 agreementCheck pl-2">
                    <ApplicationInput
                      name="Transfer"
                      type="checkedBox"
                      target="transfer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="agreementTableBody mt-2">
              <div className="row p-0 m-0">
                <div className="col-lg-8 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-4  borderBlack  p-0 m-0">
                      <span className="span7 pl-1">Bldg. No.:</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        name="from"
                        value={this.state.apartmentDetails.blogNo}
                        type="number"
                      ></input>
                    </div>
                    <div className="col-8  borderBlack  p-0 m-0">
                      <span className="span7 pl-1">Bldg.:</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        name="from"
                        value={this.state.apartmentDetails.blogName}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-6  borderBlack  p-0 m-0">
                      <span className="span7 pl-1">Suite No.:</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        name="from"
                        value={this.state.apartmentDetails.suitNo}
                        type="number"
                      ></input>
                    </div>
                    <div className="col-6  borderBlack  p-0 m-0">
                      <span className="span7 pl-1">Type:</span>
                      <input
                        className="ApplicationInputAgg m-0"
                        name="from"
                        value={this.state.apartmentDetails.blockType}
                        type="text"
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="agreementTableBody1 mt-3">
              <h6 className="headApplicationReview mb-0 pb-0 pt-2">
                NAME OF APPLICANTS FOR TENANCY:
              </h6>
              <div className="row p-0 m-0">
                <div className="col-xl-8 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-md-8 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7  w-5 align-self-end text-center">
                          (1)
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicant1Name"
                          value={
                            data.applicantLastName +
                            "  \t " +
                            data.applicantFirstName +
                            "  \t " +
                            data.applicantMiddleName
                          }
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20 align-self-end text-center">
                          D.O.B.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicantDob"
                          value={data.applicantDob}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0 p-2 ">
                          <ApplicationInput
                            name="M"
                            type="checkedBox"
                            target="applicantGenderMale"
                            value={data.applicantGender === "Male"}
                          />
                        </div>
                        <div className="col p-0 m-0 p-2">
                          <ApplicationInput
                            name="F"
                            type="checkedBox"
                            target="applicantGenderFemale"
                            value={data.applicantGender === "Female"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-7 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20  align-self-end text-center">
                          S.I.N.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicantSIN"
                          value={data.applicantSIN}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-8 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-md-8 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7  w-5 align-self-end text-center">
                          (2)
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicant2Name"
                          value={
                            data.applicant2LastName +
                            "  \t " +
                            data.applicant2FirstName +
                            "  \t " +
                            data.applicant2MiddleName
                          }
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20 align-self-end text-center">
                          D.O.B.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicant2Dob"
                          value={data.applicant2Dob}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0 p-2 ">
                          <ApplicationInput
                            name="M"
                            type="checkedBox"
                            target="applicant2GenderMale"
                            value={data.applicant2Gender === "Male"}
                          />
                        </div>
                        <div className="col p-0 m-0 p-2">
                          <ApplicationInput
                            name="F"
                            type="checkedBox"
                            target="applicant2GenderFemale"
                            value={data.applicant2Gender === "Female"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-7 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20  align-self-end text-center">
                          S.I.N.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicant2SIN"
                          value={data.applicant2SIN}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-8 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-md-8 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7  w-5 align-self-end text-center">
                          (3)
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicant3Name"
                          value={
                            data.applicant3LastName +
                            "  \t " +
                            data.applicant3FirstName +
                            "  \t " +
                            data.applicant3MiddleName
                          }
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20 align-self-end text-center">
                          D.O.B.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicant3Dob"
                          value={data.applicant3Dob}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0 p-2 ">
                          <ApplicationInput
                            name="M"
                            type="checkedBox"
                            target="applicant3GenderMale"
                            value={data.applicant3Gender === "Male"}
                          />
                        </div>
                        <div className="col p-0 m-0 p-2">
                          <ApplicationInput
                            name="F"
                            type="checkedBox"
                            target="applicant3GenderFemale"
                            value={data.applicant3Gender === "Female"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-7 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20  align-self-end text-center">
                          S.I.N.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="applicant3SIN"
                          value={data.applicant3SIN}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="headApplicationReview mb-0 pb-2 pt-3">
                NAME OF ADDITIONAL PROPOSED OCCUPANTS (including spouse, where
                not an applicant and children):
              </h6>
              <div className="row p-0 m-0">
                <div className="col-xl-8 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-md-8 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7  w-5 align-self-end text-center">
                          (1)
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicant1Name"
                          value={
                            data.additionalApplicantLastName +
                            "  \t " +
                            data.additionalApplicantFirstName +
                            "  \t " +
                            data.additionalApplicantMiddleName
                          }
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20 align-self-end text-center">
                          D.O.B.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicantDob"
                          value={data.additionalApplicantDob}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0 p-2 ">
                          <ApplicationInput
                            name="M"
                            type="checkedBox"
                            target="additionalApplicantGenderMale"
                            value={
                              data.additionalApplicantGender === "Male"
                            }
                          />
                        </div>
                        <div className="col p-0 m-0 p-2">
                          <ApplicationInput
                            name="F"
                            type="checkedBox"
                            target="additionalApplicantGenderFemale"
                            value={
                              data.additionalApplicantGender === "Female" 
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-7 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20  align-self-end text-center">
                          S.I.N.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicantSIN"
                          value={data.additionalApplicantSIN}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-8 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-md-8 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7  w-5 align-self-end text-center">
                          (2 )
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicant2Name"
                          value={
                            data.additionalApplicant2LastName +
                            "  \t " +
                            data.additionalApplicant2FirstName +
                            "  \t " +
                            data.additionalApplicant2MiddleName
                          }
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20 align-self-end text-center">
                          D.O.B.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicant2Dob"
                          value={data.additionalApplicant2Dob}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0 p-2 ">
                          <ApplicationInput
                            name="M"
                            type="checkedBox"
                            target="additionalApplicantGender2Male"
                            value={
                              data.additionalApplicant2Gender === "Male"
                            }
                          />
                        </div>
                        <div className="col p-0 m-0 p-2">
                          <ApplicationInput
                            name="F"
                            type="checkedBox"
                            target="additionalApplicantGender2Female"
                            value={
                              data.additionalApplicant2Gender === "Female" 
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-7 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20  align-self-end text-center">
                          S.I.N.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicant2SIN"
                          value={data.additionalApplicant2SIN}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-xl-8 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col-md-8 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7  w-5 align-self-end text-center">
                          (3)
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicant3Name"
                          value={
                            data.additionalApplicant3LastName +
                            "  \t " +
                            data.additionalApplicant3FirstName +
                            "  \t " +
                            data.additionalApplicant3MiddleName
                          }
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20 align-self-end text-center">
                          D.O.B.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicant3Dob"
                          value={data.additionalApplicant3Dob}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 p-0 m-0">
                  <div className="row p-0 m-0">
                    <div className="col p-0 m-0">
                      <div className="row p-0 m-0 ">
                        <div className="col p-0 m-0 p-2 ">
                          <ApplicationInput
                            name="M"
                            type="checkedBox"
                            target="additionalApplicant3GenderMale"
                            value={
                              data.additionalApplicant3Gender === "Male"
                            }
                          />
                        </div>
                        <div className="col p-0 m-0 p-2">
                          <ApplicationInput
                            name="F"
                            type="checkedBox"
                            target="additionalApplicant3GenderFemale"
                            value={
                              data.additionalApplicant3Gender === "Female" 
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-7 p-0 m-0">
                      <div className="row p-0 m-0">
                        <span className="span7 w-20  align-self-end text-center">
                          S.I.N.
                        </span>
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="additionalApplicant3SIN"
                          value={data.additionalApplicant3SIN}
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col-md-10 p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">
                    I understand and agree that if this application is accepted,
                    no person other than those identified above may occupy the
                    premises.
                  </span>
                </div>
                <div className="col-md p-0 m-0">
                  <div className="row p-0 m-0">
                    <span className="span7   align-self-end text-center">
                      Applicant’s Initials:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="initial"
                      value={data.applicantFirstName.charAt(0)}
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>

              <h6 className="headApplicationReview mb-0 pb-2 pt-3">
                RENTAL INFORMATION:
              </h6>
              <div className="row p-0 m-0">
                <div className="col-md-6 p-0 m-0">
                  <div className="row p-0 m-0">
                    <span className="span7  align-self-end text-center">
                      Term:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="from"
                      value={data.term + " Months"}
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="col p-0 m-0">
                  <div className="row p-0 m-0">
                    <span className="span7  align-self-end text-center">
                      Proposed Occupancy Date:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="moveInDate"
                      value={correctDateFormat(data.moveInDate)}
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0 mt-2">
                <div className="col-md-6 p-0 m-0">
                  <div className="row p-0 m-0">
                    <span className="span7  align-self-end text-center">
                      DATE TERM BEGINS: FIRST DAY OF
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="moveInDateStart"
                      value={correctMonthYearFormat(data.moveInDate)}
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="col p-0 m-0">
                  <div className="row p-0 m-0">
                    <span className="span7  align-self-end text-center">
                      DATE TERM ENDS: LAST DAY OF
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="moveInDateEnd"
                      value={addMonthsYear(data.moveInDate, data.term)}
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>
              <h6 className="headApplicationReview mb-0 pt-4">
                Total Monthly Rent payable in advance on the first day of each
                month:
              </h6>
              <span className="span7 ">
                Where the amount is left blank, or “N/A” is inserted, the item
                is not to be provided as part of the tenancy.
              </span>
            </div>
            <div className="agreementTableBody1 pt-3">
              <div className="agreementTableBody1 ">
                <div className="agreementTableBody mt-2">
                  <div className="row p-0 m-0">
                    <div className="col  borderBlack  p-0 m-0 text-center">
                      <h6 className="headApplicationReview mb-0 p-1">Item</h6>
                    </div>
                    <div className="col  borderBlack  p-0 m-0 text-center">
                      <h6 className="headApplicationReview mb-0 p-1">
                        Amount/Month
                      </h6>
                    </div>
                  </div>
                  <div className="row p-0 m-0">
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row  p-0 m-0">
                        <div className=" pt-3 ml-2">
                          <span className="span7">Rented premises:</span>
                        </div>
                        <div className="col  p-0 m-0 ml-2">
                          <input
                            className="ApplicationInputAgreement2"
                            name="rentedPremisesQut"
                            value={data.rentedPremisesQut}
                            type={"text"}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row">
                        <div className="col-1 pt-3 ml-2">
                          <i className="fa fa-usd" aria-hidden="true"></i>
                        </div>
                        <div className="col">
                          <input
                            className="ApplicationInputAgreement2"
                            name="rentedPremisesPrice"
                            value={data.rentedPremisesPrice}
                            type={"number"}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row p-0 m-0">
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row p-0 m-0">
                        <div className="col-md p-0 m-0">
                          <div className="row  p-0 m-0">
                            <div className="p-0 m-0 pt-3 ml-2">
                              <span className="span7">Parking:</span>
                            </div>
                            <div className="col  p-0 m-0 ml-2">
                              <input
                                className="ApplicationInputAgreement2"
                                name="parkingQut"
                                value={data.parkingQut}
                                type={"text"}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col-md p-0 m-0">
                          <div className="row  p-0 m-0">
                            <div className="p-0 m-0 pt-3 ml-2">
                              <span className="span7"># of Spaces</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row p-0 m-0">
                        <div className="col-md p-0 m-0">
                          <div className="row  p-0 m-0">
                            <div className="p-0 m-0 pt-3 ml-2">
                              <span className="span7">Locker:</span>
                            </div>
                            <div className="col  p-0 m-0 ml-2">
                              <input
                                className="ApplicationInputAgreement2"
                                name="lockerQut"
                                value={data.lockerQut}
                                type={"text"}
                              ></input>
                            </div>
                          </div>
                        </div>
                        <div className="col-md p-0 m-0">
                          <div className="row  p-0 m-0">
                            <div className="p-0 m-0 pt-3 ml-2">
                              <span className="span7"># of Spaces</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row">
                        <div className="col-1 pt-3 ml-2">
                          <i className="fa fa-usd" aria-hidden="true"></i>
                        </div>
                        <div className="col">
                          <input
                            className="ApplicationInputAgreement2"
                            name="parkingPrice"
                            value={data.parkingPrice}
                            type={"number"}
                          ></input>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-1 pt-3 ml-2">
                          <i className="fa fa-usd" aria-hidden="true"></i>
                        </div>
                        <div className="col">
                          <input
                            className="ApplicationInputAgreement2"
                            name="lockerPrice"
                            value={data.lockerPrice}
                            type={"number"}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row p-0 m-0">
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row  p-0 m-0">
                        <div className=" pt-3 ml-2">
                          <span className="span7">Other: [specify]:</span>
                        </div>
                        <div className="col  p-0 m-0 ml-2">
                          <input
                            className="ApplicationInputAgreement2"
                            name="otherQut"
                            value={data.otherQut}
                            type={"text"}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row">
                        <div className="col-1 pt-3 ml-2">
                          <i className="fa fa-usd" aria-hidden="true"></i>
                        </div>
                        <div className="col">
                          <input
                            className="ApplicationInputAgreement2"
                            name="otherPrice"
                            value={data.otherPrice}
                            type={"number"}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row p-0 m-0">
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row  p-0 m-0">
                        <div className=" pt-3 ml-2">
                          <h6 className="headApplicationReview mb-0 ">
                            Total Monthly Rent:
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="col  borderBlack  p-0 m-0">
                      <div className="row">
                        <div className="col-1 pt-3 ml-2">
                          <i className="fa fa-usd" aria-hidden="true"></i>
                        </div>
                        <div className="col">
                          <input
                            className="ApplicationInputAgreement2"
                            name="totalRent"
                            value={data.totalRent}
                            type={"number"}
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="agreementTableBody1 pt-3">
                <div className="row p-0 m-0">
                  <span className="span7  align-self-end text-center">
                    Last Month’s Rent Deposit:{" "}
                    <i className="fa fa-usd" aria-hidden="true"></i>
                  </span>
                  <input
                    className="ApplicationInput1 m-0 col-3 ml-2 fixedInputSize"
                    name="lastMonthRentDeposit"
                    value={data.lastMonthRentDeposit}
                    type="number"
                  ></input>
                </div>
                <div className="row p-0 m-0">
                  <span className="span7  align-self-end text-center">
                    A Pro-Rated Rent of{" "}
                    <i className="fa fa-usd" aria-hidden="true"></i>
                  </span>
                  <input
                    className="ApplicationInput1 m-0 col ml-2 fixedInputSize"
                    name="proRatedRent"
                    value={data.proRatedRent}
                    type="number"
                  ></input>
                  <span className="span7  align-self-end text-center">
                    is payable in advance to cover the period from
                  </span>
                  <input
                    className="ApplicationInput1 m-0 col ml-2 fixedInputSize"
                    name="payableFrom"
                    value={data.payableFrom}
                    type="text"
                  ></input>
                  <span className="span7  align-self-end text-center">to</span>
                  <input
                    className="ApplicationInput1 m-0 col ml-2 fixedInputSize"
                    name="payableTo"
                    value={data.payableTo}
                    type="number"
                  ></input>
                  <span className="span7  align-self-end text-center">
                    which shall be treated as part of the term of the tenancy.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card pb-5 mt-2">
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 pageNumberAlign ">
                <span className="span7 ">- 2 -</span>
              </div>
            </div>
            <div className="agreementTableBody1">
              <h6 className="headApplicationReview mb-0 pt-4">
                I agree to pay the supplier directly, or to pay the Landlord as
                a reimbursement of charges paid on my behalf for each of the
                following services applicable to the rented premises:
              </h6>
            </div>
            <div className="row agreementTableBody1 pb-0 mb-0 pt-2">
              <div className="col p-0 m-0 borderBlack">
                <div className="row p-0 m-0 pt-2">
                  <div className="col-lg-4 p-0 m-0 ">
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0  ">
                        <span className="span7 pl-3">Hydro</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.hydro}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.hydro}
                        />
                      </div>
                    </div>
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Gas</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.gas}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.gas}
                        />
                      </div>
                    </div>
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Heat</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.heat}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.heat}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 p-0 m-0 ">
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Hot Water Heater</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.hotWaterHeater}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.hotWaterHeater}
                        />
                      </div>
                    </div>
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Cable/Satellite TV</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.cableTv}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.cableTv}
                        />
                      </div>
                    </div>
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Water</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.water}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.water}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 p-0 m-0 ">
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Hot Water Tank</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.hotWaterTank}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.hotWaterTank}
                        />
                      </div>
                    </div>
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Internet</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.internet}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.internet}
                        />
                      </div>
                    </div>
                    <div className="row p-0 m-0 ">
                      <div className="col-6 p-0 m-0 ">
                        <span className="span7 pl-3">Other [specify]</span>
                      </div>
                      <div className="col p-0 m-0 ">
                        <ApplicationInput
                          name="Yes"
                          type="checkedBox"
                          target="transfer"
                          value={data.other}
                        />
                      </div>
                      <div className="col p-0 m-0">
                        <ApplicationInput
                          name="No"
                          type="checkedBox"
                          target="transfer"
                          value={!data.other}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="agreementTableBody1">
              <h6 className="headApplicationReview mb-0 pt-4">
                APPLICANT’S PARTICULARS: [Must be completed in full before
                application will be considered]
              </h6>
            </div>
            <div className="agreementTableBody1 mt-2">
              <div className="row p-0 m-0">
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1"> </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (1)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (2)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (3)
                  </h6>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">Name</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantName2"
                    value={
                      data.applicantLastName +
                      "  \t " +
                      data.applicantFirstName +
                      "  \t " +
                      data.applicantMiddleName
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2Name2"
                    value={
                      data.applicant2LastName +
                      "  \t " +
                      data.applicant2FirstName +
                      "  \t " +
                      data.applicant2MiddleName
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3Name2"
                    value={
                      data.applicant3LastName +
                      "  \t " +
                      data.applicant3FirstName +
                      "  \t " +
                      data.applicant3MiddleName
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">
                    Present Address Unit/ Street Address
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantAddress2"
                    value={
                      data.presentAddress +
                      "  \t " +
                      data.presentCity +
                      "  \t " +
                      data.presentState
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2Address2"
                    value={
                      data.applicant2PresentAddress +
                      "  \t " +
                      data.applicant2PresentCity +
                      "  \t " +
                      data.applicant2PresentState
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3Address2"
                    value={
                      data.applicant3PresentAddress +
                      "  \t " +
                      data.applicant3PresentCity +
                      "  \t " +
                      data.applicant3PresentState
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">
                    Province/Postal Code
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentZip"
                    value={data.presentZip}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentZip"
                    value={data.applicant2PresentZip}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentZip"
                    value={data.applicant3PresentZip}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">
                    Present Monthly Rent
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentMonthlyRent"
                    value={data.presentMonthlyRent}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentMonthlyRent"
                    value={data.applicant2PresentMonthlyRent}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentMonthlyRent"
                    value={data.applicant3PresentMonthlyRent}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">
                    Length of Occupancy
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantOccupancy"
                    value={betweenYearsMonths(data.presentFrom, data.presentTo)}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2Occupancy"
                    value={betweenYearsMonths(
                      data.applicant2PresentFrom,
                      data.applicant2PresentTo
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3Occupancy"
                    value={betweenYearsMonths(
                      data.applicant3PresentFrom,
                      data.applicant3PresentTo
                    )}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">Reason for Leaving</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentReasonLeaving"
                    value={data.presentReasonLeaving}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentReasonLeaving"
                    value={data.applicant2PresentReasonLeaving}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentReasonLeaving"
                    value={data.applicant3PresentReasonLeaving}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">Home phone</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentHomePhone"
                    value={data.presentHomePhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentHomePhone"
                    value={data.applicant2PresentHomePhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentHomePhone"
                    value={data.applicant3PresentHomePhone}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">Fax</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentHomeFax"
                    value={data.presentHomeFax}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentHomeFax"
                    value={data.applicant2PresentHomeFax}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentHomeFax"
                    value={data.applicant3PresentHomeFax}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">Landlord’s Name</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentLandlord"
                    value={data.presentLandlord}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentLandlord"
                    value={data.applicant2PresentLandlord}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentLandlord"
                    value={data.applicant3PresentLandlord}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">
                    Landlord’s Phone No.
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentLandlordPhone"
                    value={data.presentLandlordPhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentLandlordPhone"
                    value={data.applicant2PresentLandlordPhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentLandlordPhone"
                    value={data.applicant3PresentLandlordPhone}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">
                    Proof of Identification
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="presentLandlordIDProof"
                    value={data.presentLandlordIDProof}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PresentLandlordIDProof"
                    value={data.applicant2PresentLandlordIDProof}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PresentLandlordIDProof"
                    value={data.applicant3PresentLandlordIDProof}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Prior Address <br></br>
                    (if less than 3 years)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (1)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (2)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (3)
                  </h6>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Previous Address Unit/ Street Address
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="previousAddress"
                    value={
                      data.previousAddress +
                      "  \t " +
                      data.previousCity +
                      "  \t " +
                      data.previousState
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreviousAddress"
                    value={
                      data.applicant2PreviousAddress +
                      "  \t " +
                      data.applicant2PreviousCity +
                      "  \t " +
                      data.applicant2PreviousState
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreviousAddress"
                    value={
                      data.applicant3PreviousAddress +
                      "  \t " +
                      data.applicant3PreviousCity +
                      "  \t " +
                      data.applicant3PreviousState
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad">
                    Province/Postal Code
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="previousZip"
                    value={data.previousZip}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreviousZip"
                    value={data.applicant2PreviousZip}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreviousZip"
                    value={data.applicant3PreviousZip}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Monthly Rent</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="previousMonthlyRent"
                    value={data.previousMonthlyRent}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreviousMonthlyRent"
                    value={data.applicant2PreviousMonthlyRent}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreviousMonthlyRent"
                    value={data.applicant3PreviousMonthlyRent}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Length of Occupancy
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantPreOccupancy"
                    value={betweenYearsMonths(
                      data.previousFrom,
                      data.previousTo
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreOccupancy"
                    value={betweenYearsMonths(
                      data.applicant2PreviousFrom,
                      data.applicant2PreviousTo
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreOccupancy"
                    value={betweenYearsMonths(
                      data.applicant3PreviousFrom,
                      data.applicant3PreviousTo
                    )}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Reason for Leaving
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="previousReasonLeaving"
                    value={data.previousReasonLeaving}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreviousReasonLeaving"
                    value={data.applicant2PreviousReasonLeaving}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreviousReasonLeaving"
                    value={data.applicant3PreviousReasonLeaving}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Landlord’s Name</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="previousLandlord"
                    value={data.previousLandlord}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreviousLandlord"
                    value={data.applicant2PreviousLandlord}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreviousLandlord"
                    value={data.applicant3PreviousLandlord}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Landlord’s Phone No.
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="previousLandlordPhone"
                    value={data.previousLandlordPhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreviousLandlordPhone"
                    value={data.applicant2PreviousLandlordPhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreviousLandlordPhone"
                    value={data.applicant3PreviousLandlordPhone}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">Employment</h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (1)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (2)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (3)
                  </h6>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Employer #1: Name</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employerName"
                    value={
                      data.applicantLastName +
                      "  \t " +
                      data.applicantFirstName +
                      "  \t " +
                      data.applicantMiddleName
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employerName2"
                    value={
                      data.applicant2LastName +
                      "  \t " +
                      data.applicant2FirstName +
                      "  \t " +
                      data.applicant2MiddleName
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employerName3"
                    value={
                      data.applicant3LastName +
                      "  \t " +
                      data.applicant3FirstName +
                      "  \t " +
                      data.applicant3MiddleName
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Occupation</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employmentPosition"
                    value={data.employmentPosition}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2EmploymentPosition"
                    value={data.applicant2EmploymentPosition}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3EmploymentPosition"
                    value={data.applicant3EmploymentPosition}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">No. Of Years</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employmentDuration"
                    value={betweenYearsMonths(
                      data.employmentFrom,
                      data.employmentUntil
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employment2Duration"
                    value={betweenYearsMonths(
                      data.applicant2EmploymentFrom,
                      data.applicant2EmploymentUntil
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employment3Duration"
                    value={betweenYearsMonths(
                      data.applicant3EmploymentFrom,
                      data.applicant3EmploymentUntil
                    )}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Annual Income</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employmentAnnualIncome"
                    value={data.employmentAnnualIncome}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2EmploymentAnnualIncome"
                    value={data.applicant2EmploymentAnnualIncome}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3EmploymentAnnualIncome"
                    value={data.applicant3EmploymentAnnualIncome}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Employer’s Address
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employmentAddress"
                    value={
                      data.employmentAddress +
                      "  \t " +
                      data.employmentCity +
                      "  \t " +
                      data.employmentState +
                      "  \t " +
                      data.employmentZip
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employment2Address"
                    value={
                      data.applicant2EmploymentAddress +
                      "  \t " +
                      data.applicant2EmploymentCity +
                      "  \t " +
                      data.applicant2EmploymentState +
                      "  \t " +
                      data.applicant2EmploymentZip
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employment3Address"
                    value={
                      data.applicant3EmploymentAddress +
                      "  \t " +
                      data.applicant3EmploymentCity +
                      "  \t " +
                      data.applicant3EmploymentState +
                      "  \t " +
                      data.applicant3EmploymentZip
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Employer’s Telephone
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="employmentPhone"
                    value={data.employmentPhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2EmploymentPhone"
                    value={data.applicant2EmploymentPhone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3EmploymentPhone"
                    value={data.applicant3EmploymentPhone}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad "></span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="rent"
                    value={data.rent}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="rent"
                    value={data.rent}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="rent"
                    value={data.rent}
                    type={"text"}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="card pb-5 mt-2">
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">- 3 -</span>
              </div>
            </div>
            <div className="agreementTableBody1 mt-2">
              <div className="row p-0 m-0">
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray">
                  <h6 className="headApplicationReview mb-0 p-1">
                    If employment less than 3 years, provide information of
                    previous employers and occupation to total 3 years:
                  </h6>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Employer #2: Name & Address
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="preEmploymentAdd"
                    value={
                      data.preEmploymentAddress +
                      "  \t " +
                      data.preEmploymentCity +
                      "  \t " +
                      data.preEmploymentState +
                      "  \t " +
                      data.preEmploymentZip
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="preEmployment2Add"
                    value={
                      data.applicant3PreEmploymentAddress +
                      "  \t " +
                      data.applicant3PreEmploymentCity +
                      "  \t " +
                      data.applicant3PreEmploymentState +
                      "  \t " +
                      data.applicant3PreEmploymentZip
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="preEmployment3Add"
                    value={
                      data.applicant3PreEmploymentAddress +
                      "  \t " +
                      data.applicant3PreEmploymentCity +
                      "  \t " +
                      data.applicant3PreEmploymentState +
                      "  \t " +
                      data.applicant3PreEmploymentZip
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Occupation</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="preEmployment2Position"
                    value={data.preEmploymentPosition}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreEmployment2Position"
                    value={data.applicant2PreEmploymentPosition}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreEmploymentPosition"
                    value={data.applicant3PreEmploymentPosition}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Dates of Employment
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant1EmploymentDuration"
                    value={betweenYearsMonths(
                      data.preEmploymentFrom,
                      data.preEmploymentUntil
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2EmploymentDuration"
                    value={betweenYearsMonths(
                      data.applicant2PreEmploymentFrom,
                      data.applicant2PreEmploymentUntil
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3EmploymentDuration"
                    value={betweenYearsMonths(
                      data.applicant3PreEmploymentFrom,
                      data.applicant3PreEmploymentUntil
                    )}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Employer #3: Name & Address
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="prepreEmployment"
                    value={
                      data.preEmployment2Address +
                      "  \t " +
                      data.preEmployment2City +
                      "  \t " +
                      data.preEmployment2State +
                      "  \t " +
                      data.preEmployment2Zip
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="prepreEmployment2"
                    value={
                      data.applicant2PreEmployment2Address +
                      "  \t " +
                      data.applicant2PreEmployment2City +
                      "  \t " +
                      data.applicant2PreEmployment2State +
                      "  \t " +
                      data.applicant2PreEmployment2Zip
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="prepreEmployment3"
                    value={
                      data.applicant3PreEmployment2Address +
                      "  \t " +
                      data.applicant3PreEmployment2City +
                      "  \t " +
                      data.applicant3PreEmployment2State +
                      "  \t " +
                      data.applicant3PreEmployment2Zip
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Occupation</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="preEmployment2Position"
                    value={data.preEmployment2Position}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PreEmployment2Position"
                    value={data.applicant2PreEmployment2Position}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PreEmployment2Position"
                    value={data.applicant3PreEmployment2Position}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Dates of Employment
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant1Employment2Duration"
                    value={betweenYearsMonths(
                      data.preEmployment2From,
                      data.preEmployment2Until
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2Employment2Duration"
                    value={betweenYearsMonths(
                      data.applicant2PreEmployment2From,
                      data.applicant2PreEmployment2Until
                    )}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3Employment2Duration"
                    value={betweenYearsMonths(
                      data.applicant3PreEmployment2From,
                      data.applicant3PreEmployment2Until
                    )}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Credit Information
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (1)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (2)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (3)
                  </h6>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Primary Bank [Name,Branch]
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantPrimaryBankNameBranch"
                    value={
                      data.applicantPrimaryBankName +
                      " " +
                      data.applicantPrimaryBankBranch
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2PrimaryBankNameBranch"
                    value={
                      data.applicant2PrimaryBankName +
                      " " +
                      data.applicant2PrimaryBankBranch
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3PrimaryBankNameBranch"
                    value={
                      data.applicant2PrimaryBankName +
                      " " +
                      data.applicant2PrimaryBankBranch
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Account Type & No.
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <div className="row m-0 p-0 pt-1 pl-1">
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="chq"
                        type="checkedBox"
                        value={data.applicantPrimaryBankAccType === "Checking"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="sav."
                        type="checkedBox"
                        value={data.applicantPrimaryBankAccType === "Saving"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="trust"
                        type="checkedBox"
                        value={data.applicantPrimaryBankAccType === "Trust"}
                      />
                    </div>
                  </div>
                  <div className="row p-0 m-0 pb-1 pl-1">
                    <span className="span7  align-self-end text-center">
                      Acct. No.:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="applicantPrimaryBankAccNo"
                      value={data.applicantPrimaryBankAccNo}
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <div className="row m-0 p-0 pt-1 pl-1">
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="chq"
                        type="checkedBox"
                        value={data.applicant2PrimaryBankAccType === "Checking"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="sav."
                        type="checkedBox"
                        value={data.applicant2PrimaryBankAccType === "Saving"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="trust"
                        type="checkedBox"
                        value={data.applicant2PrimaryBankAccType === "Trust"}
                      />
                    </div>
                  </div>
                  <div className="row p-0 m-0 pb-1 pl-1">
                    <span className="span7  align-self-end text-center">
                      Acct. No.:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="applicant2PrimaryBankAccNo"
                      value={data.applicant2PrimaryBankAccNo}
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <div className="row m-0 p-0 pt-1 pl-1">
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="chq"
                        type="checkedBox"
                        value={data.applicant3PrimaryBankAccType === "Checking"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="sav."
                        type="checkedBox"
                        value={data.applicant3PrimaryBankAccType === "Saving"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="trust"
                        type="checkedBox"
                        value={data.applicant3PrimaryBankAccType === "Trust"}
                      />
                    </div>
                  </div>
                  <div className="row p-0 m-0 pb-1 pl-1">
                    <span className="span7  align-self-end text-center">
                      Acct. No.:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="applicant3PrimaryBankAccNo"
                      value={data.applicant3PrimaryBankAccNo}
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    {" "}
                    Secondary Bank [Name, Branch]
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    value={
                      data.applicantSecondaryBankName +
                      " " +
                      data.applicantSecondaryBankName
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    value={
                      data.applicant2SecondaryBankName +
                      " " +
                      data.applicant2SecondaryBankName
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    value={
                      data.applicant3SecondaryBankName +
                      " " +
                      data.applicant3SecondaryBankName
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Account Type & No.
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <div className="row m-0 p-0 pt-1 pl-1">
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="chq"
                        type="checkedBox"
                        value={
                          data.applicantSecondaryBankAccType === "Checking"
                        }
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="sav."
                        type="checkedBox"
                        value={data.applicantSecondaryBankAccType === "Saving"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="trust"
                        type="checkedBox"
                        value={data.applicantSecondaryBankAccType === "Trust"}
                      />
                    </div>
                  </div>
                  <div className="row p-0 m-0 pb-1 pl-1">
                    <span className="span7  align-self-end text-center">
                      Acct. No.:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="applicantSecondaryBankAccNo"
                      value={data.applicantSecondaryBankAccNo}
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <div className="row m-0 p-0 pt-1 pl-1">
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="chq"
                        type="checkedBox"
                        value={
                          data.applicant2SecondaryBankAccType === "Checking"
                        }
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="sav."
                        type="checkedBox"
                        value={data.applicant2SecondaryBankAccType === "Saving"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="trust"
                        type="checkedBox"
                        value={data.applicant2SecondaryBankAccType === "Trust"}
                      />
                    </div>
                  </div>
                  <div className="row p-0 m-0 pb-1 pl-1">
                    <span className="span7  align-self-end text-center">
                      Acct. No.:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="applicant2PrimaryBankAccNo"
                      value={data.applicant2PrimaryBankAccNo}
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <div className="row m-0 p-0 pt-1 pl-1">
                    <div className="col p-0 m-0 ">
                      <ApplicationInput
                        name="chq"
                        type="checkedBox"
                        value={
                          data.applicant3SecondaryBankAccType === "Checking"
                        }
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="sav."
                        type="checkedBox"
                        value={data.applicant3SecondaryBankAccType === "Saving"}
                      />
                    </div>
                    <div className="col p-0 m-0">
                      <ApplicationInput
                        name="trust"
                        type="checkedBox"
                        value={data.applicant3SecondaryBankAccType === "Trust"}
                      />
                    </div>
                  </div>
                  <div className="row p-0 m-0 pb-1 pl-1">
                    <span className="span7  align-self-end text-center">
                      Acct. No.:
                    </span>
                    <input
                      className="ApplicationInput1 m-0 col"
                      name="applicant2SecondaryBankAccNo"
                      value={data.applicant2SecondaryBankAccNo}
                      type="text"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    {" "}
                    Spouse’s Employer
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="spousesEmployer"
                    value={data.spousesEmployer}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2SpousesEmployer"
                    value={data.applicant2SpousesEmployer}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3SpousesEmployer"
                    value={data.applicant3SpousesEmployer}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad "> Address</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="spousesEmployerAddress"
                    value={data.spousesEmployerAddress}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2SpousesEmployerAddress"
                    value={data.applicant2SpousesEmployerAddress}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3SpousesEmployerAddress"
                    value={data.applicant3SpousesEmployerAddress}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Spouse’s Income</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="spousesIncome"
                    value={data.spousesIncome}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2SpousesIncome"
                    value={data.applicant2SpousesIncome}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3SpousesIncome"
                    value={data.applicant3SpousesIncome}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Vehicle Information
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (1)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (2)
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    Applicant (3)
                  </h6>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Driver’s License</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantDriversLicense"
                    value={data.applicantDriversLicense}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2DriversLicense"
                    value={data.applicant2DriversLicense}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3DriversLicense"
                    value={data.applicant3DriversLicense}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Make of Vehicle</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantVehicleMadeBy"
                    value={data.applicantVehicleMadeBy}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2VehicleMadeBy"
                    value={data.applicant2VehicleMadeBy}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3VehicleMadeBy"
                    value={data.applicant3VehicleMadeBy}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">Model and Year</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantVehicleModel"
                    value={
                      data.applicantVehicleModel +
                      " " +
                      data.applicantVehicleYear
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2VehicleModel"
                    value={
                      data.applicant2VehicleModel +
                      " " +
                      data.applicant2VehicleYear
                    }
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3VehicleModel"
                    value={
                      data.applicant3VehicleModel +
                      " " +
                      data.applicant3VehicleYear
                    }
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">
                    Vehicle License No.
                  </span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantVehicleLicenseNumber"
                    value={data.applicantVehicleLicenseNumber}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2VehicleLicenseNumber"
                    value={data.applicant2VehicleLicenseNumber}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3VehicleLicenseNumber"
                    value={data.applicant3VehicleLicenseNumber}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1">
                    References [Must be completed in Full]
                  </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1"> </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1"> </h6>
                </div>
                <div className="col  borderBlack1  p-0 m-0 text-center bgGray quarterBreak">
                  <h6 className="headApplicationReview mb-0 p-1"> </h6>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">#1: Name</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantFirstReferencesName"
                    value={data.applicantFirstReferencesName}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2FirstReferencesName"
                    value={data.applicant2FirstReferencesName}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3FirstReferencesName"
                    value={data.applicant3FirstReferencesName}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">#1: Address</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantFirstReferencesAddress"
                    value={data.applicantFirstReferencesAddress}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2FirstReferencesAddress"
                    value={data.applicant2FirstReferencesAddress}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3FirstReferencesAddress"
                    value={data.applicant3FirstReferencesAddress}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">#1: Telephone</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantFirstReferencesTelephone"
                    value={data.applicantFirstReferencesTelephone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2FirstReferencesTelephone"
                    value={data.applicant2FirstReferencesTelephone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3FirstReferencesTelephone"
                    value={data.applicant3FirstReferencesTelephone}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">#2: Name</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantSecondReferencesName"
                    value={data.applicantSecondReferencesName}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2SecondReferencesName"
                    value={data.applicant2SecondReferencesName}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3SecondReferencesName"
                    value={data.applicant3SecondReferencesName}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">#2: Address</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantSecondReferencesAddress"
                    value={data.applicantSecondReferencesAddress}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2SecondReferencesAddress"
                    value={data.applicant2SecondReferencesAddress}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3SecondReferencesAddress"
                    value={data.applicant3SecondReferencesAddress}
                    type={"text"}
                  ></input>
                </div>
              </div>
              <div className="row p-0 m-0">
                <div className="col  borderBlack  p-0 m-0 text-left d-flex align-content-center flex-wrap quarterBreak ">
                  <span className="span7 tableLeftPad ">#2: Telephone</span>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(1)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicantSecondReferencesTelephone"
                    value={data.applicantSecondReferencesTelephone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(2)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant2SecondReferencesTelephone"
                    value={data.applicant2SecondReferencesTelephone}
                    type={"text"}
                  ></input>
                </div>
                <div className="col  borderBlack  p-0 m-0 quarterBreak">
                  <span className="span7 tableResNo ">(3)</span>
                  <input
                    className=" ApplicationInputAgreement3 "
                    name="applicant3SecondReferencesTelephone"
                    value={data.applicant3SecondReferencesTelephone}
                    type={"text"}
                  ></input>
                </div>
              </div>
            </div>
            <div className="agreementTableBody1 pt-3">
              <div className="row p-0 m-0">
                <span className="span7  align-self-end text-center">
                  IN CASE OF EMERGENCY CONTACT:
                </span>
                <input
                  className="ApplicationInput1 m-0 col ml-2 fixedInputSize"
                  name="emergencyContactName"
                  value={data.emergencyContactName}
                  type="text"
                ></input>
              </div>
              <div className="row p-0 m-0">
                <span className="span7  align-self-end text-center">
                  Phone No.: Tel:
                </span>
                <input
                  className="ApplicationInput1 m-0 col ml-2 fixedInputSize"
                  name="emergencyContactPhone"
                  value={data.emergencyContactPhone}
                  type="text"
                ></input>
                <span className="span7  align-self-end text-center">Fax:</span>
                <input
                  className="ApplicationInput1 m-0 col ml-2 fixedInputSize"
                  name="emergencyContactFax"
                  value={data.emergencyContactFax}
                  type="text"
                ></input>
                <span className="span7  align-self-end text-center">
                  Relationship:
                </span>
                <input
                  className="ApplicationInput1 m-0 col ml-2 fixedInputSize"
                  name="emergencyContactRelation"
                  value={data.emergencyContactRelation}
                  type="text"
                ></input>
              </div>
            </div>
            <div className="row agreementTableBody1 pb-0 mb-0 pt-2  mt-3">
              <div className="col p-0 m-0 borderBlack pb-2">
                <div className="row pt-2 agreementTableBody1">
                  <span className="span7 col-lg-7   p-0 m-0 pb-3">
                    Summary of Monies Received with Application – to be paid
                    upon approval
                  </span>
                  <div className="col p-0 m-0  ">
                    <ApplicationInput
                      name="By Money Order"
                      type="checkedBox"
                      target="transfer"
                      value={data.summaryMoney === "MoneyOrder"}
                    />
                  </div>
                  <div className="col p-0 m-0 ">
                    <ApplicationInput
                      name="By Cheque"
                      type="checkedBox"
                      target="transfer"
                      value={data.summaryMoney === "Chq"}
                    />
                  </div>
                </div>
                <div className="row agreementTableBody1 pt-0 mt-0">
                  <span className="span7 col-3 p-0 m-0 text-left align-self-end">
                    Prorated Rent:
                  </span>
                  <span className="span7  p-0 m-0 align-self-end">
                    <i className="fa fa-usd " aria-hidden="true"></i>
                  </span>
                  <input
                    className="ApplicationInput1 m-0 col-3 ml-2 fixedInputSize"
                    name="summaryProRatedRent"
                    value={data.summaryProRatedRent}
                    type="number"
                  ></input>
                </div>
                <div className="row agreementTableBody1 pt-0 mt-0">
                  <span className="span7 col-3 p-0 m-0 text-left align-self-end">
                    First Month Rent:
                  </span>
                  <span className="span7  p-0 m-0 align-self-end">
                    <i className="fa fa-usd " aria-hidden="true"></i>
                  </span>
                  <input
                    className="ApplicationInput1 m-0 col-3 ml-2 fixedInputSize"
                    name="summaryFirstMonthRent"
                    value={data.summaryFirstMonthRent}
                    type="number"
                  ></input>
                </div>
                <div className="row agreementTableBody1 pt-0 mt-0">
                  <span className="span7 col-3 p-0 m-0 text-left align-self-end">
                    Prepaid Last Month Rent:
                  </span>
                  <span className="span7  p-0 m-0 align-self-end">
                    <i className="fa fa-usd " aria-hidden="true"></i>
                  </span>
                  <input
                    className="ApplicationInput1 m-0 col-3 ml-2 fixedInputSize"
                    name="summaryLastMonthRent"
                    value={data.summaryLastMonthRent}
                    type="number"
                  ></input>
                </div>
                <div className="row agreementTableBody1 pt-0 mt-0">
                  <h6 className="headApplicationReview col-3 p-0 m-0 text-left align-self-end">
                    TOTAL RECEIVED
                  </h6>
                  <span className="span7  p-0 m-0 align-self-end">
                    <i className="fa fa-usd " aria-hidden="true"></i>
                  </span>
                  <input
                    className="ApplicationInput1 m-0 col-3 ml-2 fixedInputSize"
                    name="summaryTotal"
                    value={data.summaryTotal}
                    type="number"
                  ></input>
                </div>
              </div>
            </div>
          </div>
          <div className="card pb-5 mt-2">
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">- 4 -</span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4">
              <div className="col p-0 m-0 text-justify">
                <span className="span7 ">
                  If the property being applied is located within a building
                  subject to the government of the Condominium Act, I/We agree
                  to be bound by the provisions of the Condominium Act, R.S.O.
                  1998, and amendments thereto, the Declaration, the By-laws,
                  the Rules, and the Regulation of said Condominium.
                </span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 pt-3">
              <div className="col p-0 m-0 text-justify">
                <span className="span7 ">
                  I/we warrant and represent that I/we do not have a pet and
                  that I/we will not bring a pet into the Rented Premises if
                  this application is accepted. Furthermore should a pet be kept
                  on the premises, the I/we acknowledge that the pet is no
                  larger than 30lbs and that only one is permitted. I/We agree
                  to bear full liability for any and all loss or damages
                  resulting from said pet and herewith saves and indemnifies the
                  Landlord, and the Landlord’s agent from any claim made against
                  them a as a result of keeping said pet.
                </span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 pt-3">
              <div className="col p-0 m-0 text-justify">
                <span className="span7 ">
                  I/We acknowledge that the Landlords insurance on the premise
                  does not cover personal property nor liability of the tenant.
                  I/We agree not to do anything on the premises for which the
                  Owner’s/Landlord insurance if any, may be increased, and agree
                  to hold the Owner/Landlord harmless from damages of any kind,
                  including liability from injury to anyone regardless of fault
                  during the Lease Term. I/We agree to have and maintain
                  throughout the term of this lease a standard Tenant’s Contents
                  and Liability insurance on the premises.
                </span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 pt-3">
              <div className="col p-0 m-0  text-justify">
                <span className="span7">
                  I/We agree that the rent for the second year and any
                  subsequent year may be increased by the maximum government
                  guideline in accordance with the Residential Tenancies Act.
                </span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 pt-3">
              <div className="col p-0 m-0 text-justify">
                <span className="span7 ">
                  I/We agree to execute a Lease Agreement on the Manager’s
                  standard Lease form.
                </span>
              </div>
            </div>
            <div className="row text-justify  applicationAgreementHead4 pt-5 mt-5 ">
              <div className="col p-0 m-0">
                <span className="span7  align-self-end text-center">
                  <b>AGREEMENT: </b>I/we deposit with the landlord the sum of
                  <i className="fa fa-usd" aria-hidden="true"></i>
                </span>
                <input
                  className="ApplicationInput2 m-0  ml-2 fixedInputSize"
                  name="agreementSum"
                  value={data.agreementSum}
                  type="number"
                ></input>
                <span
                  className="span7  text-justify "
                  style={{ lineHeight: "25px" }}
                >
                  in certified funds and understand and agree that the
                  landlord’s acceptance of the deposit does not constitute a
                  tenancy agreement. I/We further understand that upon the
                  approval and acceptance of this Rental Application by the
                  landlord, I/we shall be deemed to have entered into a tenancy
                  agreement with the landlord in the terms of the landlord’s
                  usual form which I/we have had an opportunity to read and
                  understand prior to signing this Rental Application. I/we
                  further agree that upon acceptance of this Rental Application
                  by the landlord, I/we will execute the written tenancy
                  agreement in the landlord’s usual form, and in the event that
                  I/we refuse or neglect to do so, I/we agree to be bound by its
                  terms notwithstanding that I/we have not signed it. If the
                  landlord is unable to give possession of the rented premises
                  on the date of commencement date of the term for any reason,
                  I/ we agree that the landlord shall not be subject to any
                  liability to me/us and shall give possession to me/us as soon
                  as the landlord is able to do so. In the event that this
                  Rental Application is not accepted by the landlord by reason
                  that any of the above information proves not to be true, or is
                  incomplete or misleading in a material way, I/ we agree that
                  the deposit may be returned less the sum of $150.00 for the
                  landlord’s expenses and time incurred. I/we consent to the
                  landlord and its authorized agents obtaining any information
                  about me/us as the landlord in its sole discretion may deem
                  necessary, at any time in connection with the Rented Premises
                  hereby applied for, the tenancy agreement, or matters arising
                  there from, or any renewal or extension thereof. I/We hereby
                  certify that the information provided on this Rental
                  Application is true and accurate and acknowledge that any
                  false statement or misrepresentation may lead to charges for
                  fraud or other remedies available at law. I/we further consent
                  to the disclosure of any information concerning me/us to any
                  credit reporting agency, or to any person or company with whom
                  I/we have, may have, or will in the future have a financial
                  relationship. Dated at (City)
                </span>
                <input
                  className="ApplicationInput2 m-0  ml-2 fixedInputSize"
                  name="agreementCity"
                  value={data.agreementCity}
                  type="text"
                ></input>
                <span className="span7  align-self-end text-center">
                  , this day of
                </span>
                <input
                  className="ApplicationInput2 m-0  ml-2 fixedInputSize"
                  name="agreementDay"
                  value={data.agreementDay}
                  type="text"
                ></input>
                <span className="span7  align-self-end text-center">
                  &nbsp;
                </span>
                <input
                  className="ApplicationInput2 m-0  ml-2 fixedInputSize"
                  name="agreementMonth"
                  value={data.agreementMonth}
                  type="text"
                ></input>
                <span className="span7  align-self-end text-center">,</span>
                <input
                  className="ApplicationInput2 m-0  ml-2 fixedInputSize"
                  name="agreementYear"
                  value={data.agreementYear}
                  type="text"
                ></input>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 ">
              <div className="col-md  ">
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(1)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="witness"
                      value={data.witness}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7  ">Witness</span>
                  </div>
                </div>
              </div>
              <div className="col-md  ">
                <div className="row p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(1)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="applicantPrintName"
                      value={data.applicantPrintName}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7  ">Tenant: Print Name</span>
                  </div>
                </div>
              </div>
              <div className="col-md  ">
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(1)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="applicantSignName"
                      value={data.applicantSignName}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col p-0 m-0">
                    <span className="span7  ">Sign Name</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 pt-0 mt-0">
              <div className="col-md">
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(2)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="witness2"
                      value={data.witness2}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7  ">Witness</span>
                  </div>
                </div>
              </div>
              <div className="col-md  ">
                <div className="row p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(2)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="applicant2PrintName"
                      value={data.applicant2PrintName}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7  ">Tenant: Print Name</span>
                  </div>
                </div>
              </div>
              <div className="col-md  ">
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(2)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="applicant2SignName"
                      value={data.applicant2SignName}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col p-0 m-0">
                    <span className="span7  ">Sign Name</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 pt-0 mt-0">
              <div className="col-md  ">
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(3)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="witness3"
                      value={data.witness3}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7  ">Witness</span>
                  </div>
                </div>
              </div>
              <div className="col-md  ">
                <div className="row p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(3)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="applicant3PrintName"
                      value={data.applicant3PrintName}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7  ">Tenant: Print Name</span>
                  </div>
                </div>
              </div>
              <div className="col-md  ">
                <div className="row  p-0 m-0">
                  <div className="col  p-0 m-0">
                    <span className="span7 tableResNo2 ">(3)</span>
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="applicant3SignName"
                      value={data.applicant3SignName}
                      type="number"
                    ></input>
                  </div>
                </div>
                <div className="row  p-0 m-0">
                  <div className="col p-0 m-0">
                    <span className="span7  ">Sign Name</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 ">
              <div className="col  ">
                <span className="span7">
                  <b>Acceptance by the Landlord:</b>
                </span>
                <br></br>
                <span className="span7">
                  The Landlord hereby accepts this application for the Rented
                  Premises as described in this Rental Application:
                </span>
              </div>
            </div>
            <div className="row  applicationAgreementHead4 pt-0 mt-0 pb-5 mb-4">
              <div className="col-md  ">
                <div className="row">
                  <div className="col ">
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="date2"
                      value={data.date2}
                      type="date"
                    ></input>
                  </div>
                </div>
                <div className="row ">
                  <div className="col ">
                    <span className="span7  ">Date</span>
                  </div>
                </div>
              </div>
              <div className="col-md  ">
                <div className="row ">
                  <div className="col  ">
                    <input
                      className="ApplicationInput1 m-0 co"
                      name="signatureLandlord"
                      value={data.signatureLandlord}
                      type="text"
                    ></input>
                  </div>
                </div>
                <div className="row  ">
                  <div className="col  ">
                    <span className="span7  ">
                      Signature of Landlord or Agent
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ApplicationReview;
