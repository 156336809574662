import axios from "axios";
import qs from "qs";
import JwtAuthService from "./JwtAuthService";
import CookieService from "./CookieService";

export const url = process.env.REACT_APP_STATUS==="Production"?process.env.REACT_APP_PROD_API:process.env.REACT_APP_DEV_API;
export const url1 = "https://dev-api.axallant.com/";
export const urlProd = "https://api.axallant.com/";
export const local = "http://192.168.1.3:9085/";

export const stripeKey = process.env.REACT_APP_STATUS==="Production"?process.env.REACT_APP_PROD_STRIPE_KEY:process.env.REACT_APP_DEV_STRIPE_KEY;
export const geoKey = "AIzaSyBSjzsDH3F0JVL5K1yzHYOMi4zf6QDZ_Qc";

//get token for specific email,password
export const getToken = (email, password) => {
  let data = {
    grant_type: "password",
    username: email,
    password: password,
  };

  return new Promise((resolve, reject) => {
    fetch(url + "oauth/token", {
      method: "POST",
      auth: {
        username: "mobile",
        password: "pin",
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        // Authorization: "Basic d2ViOnBpbg==",
        Authorization: "Basic bW9iaWxlOnBpbg==",
      },
      body: qs.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
//check token is valid or not
export const checkToken = async (token) => {
  try {
    return await axios({
      method: "get",
      url: url + "oauth/check_token",
      auth: {
        username: "mobile",
        password: "pin",
      },
      params: { token: token },
    });
    
  } catch (error) {
    return errorHandle(error);
  }
};
export const offline = () => {
  if (!window.navigator.onLine) {
    if (window.confirm("You are now offline, please Reconnect ... ")) {
      window.location.reload();
    }
  }
};
export const getTokenIntoRefresh = (refresh_token) => {
  let data = {
    grant_type: "refresh_token",
    refresh_token: refresh_token,
  };
  return new Promise((resolve, reject) => {
    fetch(url + "oauth/token", {
      method: "POST",
      auth: {
        username: "mobile",
        password: "pin",
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic bW9iaWxlOnBpbg==",
      },
      body: qs.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
        window.location.reload();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const reToken = () => {
  let refresh_token = CookieService.getCookie("axReTok");
  if (
    refresh_token !== "" ||
    refresh_token !== null ||
    refresh_token !== undefined
  ) {
    getTokenIntoRefresh(refresh_token).then((data) => {
      JwtAuthService.setSession(data.access_token, data.refresh_token);
      return data;
    });
    return true;
  } else {
    JwtAuthService.logout("refresh");
    return false;
  }
};
//this function handle error in apis
const errorHandle = (error) => {
  if (error.message === "Network Error") {
    offline();
    return [];
  } else {
    if (error.response) {
    
      if (error.response.status === 401) {
        reToken();
      }
    } else {
      offline();
      return [];
    }
    return error;
  }
};

//mail link conformation 
export const registrationConfirmation = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/registrationConfirmation",
      data: data,
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//If password is forgot then send link to email
export const forgotPasswordCode = async (id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/forgot-password/code/" + id,
    });
    
  } catch (error) {
    return errorHandle(error);
  }
};
//change password
export const resetPassword = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/forgot-password/reset",
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change password with auth
export const userResetPassword = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/password/reset",
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//if registration link is expire then send link again
export const resendLink = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/resend/token",
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
export const PostData = async (email, password) => {
  let data = {
    grant_type: "password",
    username: email,
    password: password,
  };
  return  axios({
    method: "post",
    url: url + "oauth/token",
    data: qs.stringify(data),

    auth: {
      username: "mobile",
      password: "pin",
    },
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic bW9iaWxlOnBpbg==",
    },
  });

};

//get data from license image 
export const LicenseData = async (image) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/license/upload",
      data: image,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};

//upload contract pdf
export const uploadContractPdf = async (pdf, contractId) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/" + contractId + "/contractAgreement/upload",
      data: pdf,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//upload contract any type file
export const uploadContractFile = async (file, contractId, category) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/" + contractId + "/" + category + "/upload",
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    
  } catch (error) {
    return errorHandle(error);
  }
};
export const uploadFile = async (file, applicationId, categoryType) => {
  const formData = new FormData();
  formData.append("file", file, "application.pdf");

  try {
    return await axios({
      method: "post",
      url:
        url +
        "api/application/" +
        applicationId +
        "/" +
        categoryType +
        "/upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//get user role type
export const getRole = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/userDetails",
    });
  
  } catch (error) {
    return errorHandle(error);
  }
};

//get user details
export const getUser = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/user",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get user details with specific user_id
export const userDetail = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/user/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get user details with specific group of user_id
export const userListDetail = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/list",
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};

//registration 
export const signUp = async (role, data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/signup-user/" + role,
      data: data,
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//update user details
export const updateUserDetails = async (data) => {
  try {
    return await axios({
      method: "put",
      url: url + "api/user/update",
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
// update user profile image
export const uploadProfileImage = async (image, id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/image/upload",
      data: image,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//get all rental lists (open api)
export const getRentalLists = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/listing-all",
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//get property vise listings (open api)
export const getListings = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/" + id + "/listing",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get property vise listings images (open api)
export const getRentalListsImages = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/" + id + "/image/downloadList",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get applications for tenant
export const getApplications = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/application/tenant/applications",
    });
  } catch (error) {
    return errorHandle(error);
  }
};

//get application count for specific application
export const getApplicationsCount = async (id) => {
  return axios({
    method: "get",
    url: url + "api/application/count/" + id + "/application",
  });
};
//set Application
export const setApplication = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application",
      data: data,
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + CookieService.getCookie("axTok"),
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//set Application (Washington)
export const setWashingtonApplication = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/washington",
      data: data,
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + CookieService.getCookie("axTok"),
      },
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//set Application (Washington) version 02
export const setWashingtonApplicationV2 = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/v2/washington",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });

  } catch (error) {
    return errorHandle(error);
  }
};
//set Application (Washington) second  applicant
export const setWashingtonSecondaryApplication = async (
  data,
  order,
  applicationId
) => {
  try {
    return await axios({
      method: "put",
      url: url + "api/application/join-tenant/" + order + "/" + applicationId,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//set Application (Toronto) 
export const setTorontoApplication = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/toronto",
      data: data,
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + CookieService.getCookie("axTok"),
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//set Application (Toronto office)
export const setTorontoOfficeApplication = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/toronto/officeLease",
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
// get specific listing details by id
export const getPropertyLists = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/listing/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
// get specific listing status
export const changeListsStatus = async (status, data) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/listing/" + data.id + "/" + status,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get landlord details by rental id
export const getLandlordByRentalId = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/landlords/Details/" + id,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//check user id in our data base
export const isAvailableUser = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/user/isAvailable/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//delete property listing image by id
export const deletePropertyLists = async (id, image) => {
  try {
    return await axios({
      method: "delete",
      url: url + "api/property/listing/deleteImage/" + id + "/" + image,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add property listing by id
export const addPropertyListsById = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/property/add-listing-by-id",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add bulk image in listing
export const addPropertyListingImageBulk = async (image) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/property/image/upload-bulk",
      data: image,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  } catch (error) {
    return errorHandle(error);
  }
};
// get pending application data 
export const getPendingApplication = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/application/landlord/pending-applications",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
// get all application data
export const getAllApplication = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/application/landlord/all-applications",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change application status as cancel
export const canceledApplication = async (id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/cancel/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change application status as rejected
export const rejectedApplication = async (id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/status/" + id + "/REJECTED",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change application status as approved
export const approvedApplication = async (id,data) => {
  try {
    return await axios({
      method: "post",
      data:data,
      url: url + "api/application/status/" + id + "/APPROVED",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get lead data
export const getLeads = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/user/leads",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get lead data 
export const downloadLeads = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/leads/download",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
// set leads
export const sendLeads = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/leads/email",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};

export const sendMessage = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/user/lead",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get notification count with user id
export const getNotificationCount = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/notification/count/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get notification data with user id
export const getNotification = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/notification/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//delete notification 
export const deleteNotification = async (id) => {
  try {
    return await axios({
      method: "delete",
      url: url + "api/notification/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//update notification 
export const updateNotification = async (id) => {
  try {
    return await axios({
      url: url + "api/notification/notification-update/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//create contract 
export const createContract = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/create-contract",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change contract status as cancel
export const cancelContract = async (id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/cancel-contract/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//check payment status tenant
export const checkPaymentStatus = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/check-status",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//check payment status landlord
export const checkPaymentAccount = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/check-account",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get payment account details
export const checkPaymentAccountDetail = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/account",
    });
  } catch (error) {
    return errorHandle(error);
  }
};

//create payment account
export const createPaymentAccount = async (type) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/create-account/" + type + "/true",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//recreate payment account
export const reCreatePaymentAccount = async (type) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/recreate-account",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//stripe account
export const stripeLogin = async (type) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/stripe-login",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get payment details
export const receivedPayment = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/received-payments/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get payment details
export const getCustomerPayment = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/customer/",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get paid payment details
export const getPayedPayment = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/paid-payments/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get tenant payment details
export const getTenantPayments = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/tenant-payments/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get landlord payment details
export const getLandlordPayments = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/landlord-payments/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//update payment method
export const updatePaymentMethod = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/payment/update_payment_method",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//delete payment methods
export const deletePaymentMethod = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/payment/delete_payment_method",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get landlord contract
export const getLandlordContracts = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/contract/landlord/contract",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get tenant contract
export const getTenantContracts = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/contract/tenant/contract",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//delete contract with id
export const deleteContracts = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/contract/reject-contract/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//accept the contract 
export const acceptContract = async (modalData) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/accept-contract/primary",
      data: modalData,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//accept the contract 
export const acceptContractSecondary = async (id, token, tenantUsername) => {
  try {
    return await axios({
      method: "get",
      url:
        url +
        "api/contract/accept-contract/secondary/" +
        id +
        "/" +
        token +
        "/" +
        tenantUsername,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get join tenant status 
export const joinTenantStatus = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/contract/tenants/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get join tenants status 
export const joinTenantsStatus = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/contract/tenants-details/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//pay rent on time
export const payRent = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/payment/multiple-charge",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//paid rent as one time
export const payOneTime = async (data, month) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/payment/one-time-rent/" + month,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get payment data by payer
export const getPaymentData = async (payer, id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/tenant-payments/" + payer + "/" + id,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//view contract document
export const viewContractDocument = async (id, file) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/contract/" + id + "/file/" + file + "/download",
      responseType: "blob",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//delete contract file with id
export const deleteContractDocument = async (id) => {
  try {
    return await axios({
      method: "delete",
      url: url + "api/contract/" + id + "/delete",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//update visibility of document
export const changeVisibleContractDocument = async (file, id) => {
  try {
    return await axios({
      method: "put",
      url: url + "api/contract/" + id + "/updateAccess",
      data: file,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//download document
export const viewDocument = async (id, file) => {
  return  axios({
    method: "get",
    url: url + "api/application/" + id + "/file/" + file + "/download",
    responseType: "blob",
  });
};
//get all Maintenance datas
export const getMaintenance = async () => {
  try {
    const role = CookieService.getArrayCookie("axUserRole");
    let roleName = "";
    if (role[0].id === 2) {
      roleName = "landlord";
    } else if (role[0].id === 1) {
      roleName = "tenant";
    } else {
      roleName = "other";
    }
    return await axios({
      method: "get",
      url: url + "api/contract/maintenance/filter/" + roleName,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get maintenance request details 
export const getMaintenanceRequest = async () => {
  try {
    const role = CookieService.getArrayCookie("axUserRole");
    let roleName = "";
    if (role[0].id === 2) {
      roleName = "landlord";
    } else if (role[0].id === 1) {
      roleName = "tenant";
    } else {
      roleName = "other";
    }
    return await axios({
      method: "get",
      url: url + "api/maintenance/v2/service-requests/" + roleName,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//create maintenance request
export const createMaintenanceRequest = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/service-request",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//upload maintenance request images
export const uploadMaintenanceRequest = async (image, id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/service-requests/image/upload/" + id,
      data: image,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change maintenance request tenant status
export const changeMaintenanceRequestTenantStatus = async (id, status) => {
  try {
    return await axios({
      method: "patch",
      url:
        url + "api/maintenance/v2/service-requests/tenant/" + id + "/" + status,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change maintenance request landlord status
export const changeMaintenanceRequestLandlordStatus = async (id, status) => {
  try {
    return await axios({
      method: "patch",
      url:
        url +
        "api/maintenance/v2/service-requests/landlord/" +
        id +
        "/" +
        status,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get open provider data
export const getOpenProviderData = async (token, providerId, requestId) => {
  try {
    return await axios({
      method: "get",
      url: url+"api/maintenance/v2/maintenance-provider/details/" +
      providerId +
      "/" +
      requestId,
      params: { token: token },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//Quotation
//create maintenance quotation
export const createMaintenanceQuotation = async (data,token) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/service-quotation/"+token,
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//upload maintenance image
export const uploadQuotationImage = async (image, id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/service-quotation/image/upload/" + id,
      data: image,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//Job Appointment 
//create maintenance appointment
export const createMaintenanceAppointment = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/job-appintment/",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//change maintenance appointment status
export const changeMaintenanceAppointmentStatus = async (token,status,id) => {
  try {
    return await axios({
      method: "put",
      url: url + "api/maintenance/v2/job-appintment/maintenance-provider/status/"+id+"/"+status,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      params: { token: token },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//old Maintenance version apis
//create maintenance
export const createMaintenance = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/create-maintenance",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//upload maintenance image
export const uploadMaintenance = async (image, id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/maintenance/" + id + "/image/upload",
      data: image,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//accept maintenance request by landlord
export const acceptMaintenance = async (id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/maintenance/update-landlord/" + id + "/approved",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//reject maintenance
export const rejectMaintenance = async (id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/contract/maintenance/update-landlord/" + id + "/rejected",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get listing by id
export const getListing = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "property/by-listing/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get property by user
export const getProperty = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/user/property",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get property by id
export const getPropertyById = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//delete property by id 
export const deleteProperty = async (id) => {
  try {
    return await axios({
      method: "delete",
      url: url + "api/property/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add property under axallant management
export const allowOnPropertyForUs = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/allow-axallant/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//remove property under axallant management
export const removeOnPropertyForUs = async (id) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/property/remove-axallant/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add property
export const addProperty = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/property",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add property by axallant
export const addPropertyByAxallant = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/property/v2",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//update property
export const updateProperty = async (data, id) => {
  try {
    return await axios({
      method: "put",
      url: url + "api/property/" + id,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//upload property image
export const uploadPropertyImage = async (image, id) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/property/" + id + "/image/upload",
      data: image,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add payment method
export const addPaymentMethod = async (id, data) => {
  try {
    return await axios({
      method: "post",
      url:
        url +
        (id === "addNew"
          ? `api/payment/add_payment_method`
          : `api/payment/create-customer`),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add payment method   
export const addPaymentMethodGuest = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/application/washington",
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get application rent fee
export const getPropertyRent = async (propertyName) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/application/fee/" + propertyName,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//verify payment method
export const verifyPaymentMethod = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/payment/verify-account",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      data: data,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get stripe token
export const stripeToken = async (data) => {
  try {
    return await axios({
      method: "post",
      url: "https://api.stripe.com/v1/tokens",
      data: data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: "Bearer " + stripeKey,
      },
    });
  } catch (error) {
    if (error.message === "Network Error") {
      offline();
    } else {
      if (error.response.status === 401) {
        // reToken();
      }
    }
    return error;
  }
};
//just for test
export const getTest = async (id) => {
  try {
    return await axios({
      method: "get",
      url: local+"/api/application/abc",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get payment dashboard data for landlord
export const getPaymentDashboardDatas = async () => {
  try {
    return await axios({
      method: "get",
      url: url + "api/payment/dashboard/landlord",
    });
  } catch (error) {
    return errorHandle(error);
  }
};

//Provider
//get all providers details
export const getProvidersAll = async () => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/maintenance-provider/all",
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//get providers details
export const getProviders = async (user) => {
  try {
    return await axios({
      method: "get",
      url: url + "api/maintenance/v2/maintenance-provider/by-landlord/"+user,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//add providers
export const addProvider = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/maintenance-provider",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};

//check providers exists or not
export const checkProviderExists = async (id) => {
  try {
    return await axios({
      method: "get",
      url: "api/maintenance/v2/maintenance-provider/isExcist/" + id,
    });
  } catch (error) {
    return errorHandle(error);
  }
};
//send provider invitation
export const sendProviderInvitation = async (data) => {
  try {
    return await axios({
      method: "post",
      url: url + "api/maintenance/v2/provider-invite",
      data: data,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  } catch (error) {
    return errorHandle(error);
  }
};
