import axios from "axios";
import CookieService from "./CookieService";
class JwtAuthService {
  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded

  logout = (refresh) => {
    this.setSession(null);
    this.removeUser();
    this.removeRole();
    CookieService.setCookie("staySignIn", "false", 7);
    if(refresh==="refresh"){
      window.location.reload();
    }
  };

  // Set token to all http request header, so you don't need to attach everytime
  setSession = (token, refresh_token) => {
    if (token) {
      CookieService.setCookie("axTok", token, 7);
      CookieService.setCookie("axReTok", refresh_token, 7);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      CookieService.eraseCookie("axUserRole")
      CookieService.eraseCookie("axTok");
      CookieService.eraseCookie("axReTok");
      CookieService.eraseCookie("axUserDetails");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to cookie
  setUser = (user) => {
    CookieService.setArrayCookie("axUserDetails", user, 7);
  };
  setRole = (role) => {
    CookieService.setArrayCookie("axUserRole", role, 7);
  };
  // Remove user from cookie
  removeUser = () => {
    CookieService.eraseCookie("axUserDetails");
  };
  removeRole = () => {
    CookieService.eraseCookie("axUserRole");
  };
}

export default new JwtAuthService();
