// @author Karunaaharan Bavaram

import AesEncryptionServices from "./AesEncryptionServices";
class CookieService {
  setCookie(name, value, exDate) {
    let d = new Date();
    d.setTime(d.getTime() + exDate * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie =
      name +
      "=" +
      AesEncryptionServices.encrypt(value) +
      ";" +
      expires +
      ";SameSite=Lax;path=/";
  }
  setArrayCookie(name, value, exDay) {
    this.setCookie(name, JSON.stringify(value), exDay);
  }
  getArrayCookie(name) {
    if (this.getCookie(name) === "") {
      return "";
    } else {
      return JSON.parse(this.getCookie(name));
    }
  }
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (const element of ca) {
      let c = element;
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return AesEncryptionServices.decrypt(
          c.substring(name.length, c.length)
        );
      }
    }
    return "";
  }

  checkCookie(name) {
    let user = this.getCookie(name);
    return user !== "";
  }
  eraseCookie(name) {
    this.setCookie(name, "", -1);
  }
}

export default new CookieService();
