import React from "react";
import "./loader2.css";

const Loader2 = (props) => {
  const styleLoader={
    minWidth:props.width,
  }
  return (
    <div className="loader2" style={ styleLoader }>
      <div className="loader"></div>
    </div>
  );
};
export default Loader2;
