import React, { Component } from "react";
import {
  updatePaymentMethod,
  deletePaymentMethod,
} from "../../../Services/ApiServices";
import Loader3 from "../../Loader/loader3";
import "./card.css";

export class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      default: this.props.id === this.props.data.id ? true : false,
      loader: false,
    };
    this.deleteCard = this.deleteCard.bind(this);
    this.addCard = this.addCard.bind(this);
    this.addCard1 = this.addCard1.bind(this);
  }
  date(date) {
    var a = new Date(date);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date1 = a.getDate();
    var date2 = "0";

    if (parseInt(date1) < 10) {
      date2 = "0" + date1.toString();
    } else {
      date2 = date1;
    }
    var time = month + "-" + date2 + "-" + year;
    return time;
  }
  deleteCard() {
    // let x=this.props;
    this.setState({
      loader: true,
    });
    var item = {
      sourceId: this.props.data.id,
      object: this.props.data.object,
    };
    var form_data = [];
    for (var key in item) {
      var encodedKey = encodeURIComponent(key);
      var encodedValue = encodeURIComponent(item[key]);
      form_data.push(encodedKey + "=" + encodedValue);
    }
    form_data = form_data.join("&");
    try {
      deletePaymentMethod(form_data).then(async (response) => {
        if (response.data.status) {
          if (response.data.data) {
            this.props.pushAlert("Successfully Card removed ", "success");
            setTimeout(() => {
              this.setState({
                loader: false,
              });
            }, 2000);
          } else {
            this.props.pushAlert(response.data.data, "danger");
          }
          await this.props.getCustomer();
        } else {
          this.props.pushAlert("Error in process.Try again later!", "danger");
        }
        this.setState({
          isButtonDisable: true,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  addCard1() {
    var formBody = [];
    var details = {
      sourceId: this.props.data.id,
    };
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    try {
      updatePaymentMethod(formBody).then((response) => {
        if (response.data.status) {
          this.props.pushAlert("Successfully Default card changed", "success");
          window.location.reload();
        } else {
          this.props.pushAlert("Error in process.Try again later!", "danger");
        }
        this.setState({
          isButtonDisable: true,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  addCard() {
    var formBody = [];
    var details = {
      sourceId: this.props.data.id,
    };
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    try {
      updatePaymentMethod(formBody).then((response) => {
        if (response.data.status) {
          // this.props.pushAlert(response.data.data,"success");
          this.props.pushAlert("Successfully Default card changed", "success");
          this.props.getCustomer();
          this.props.history.push({
            pathname: "/payments",
          });
        } else {
          this.props.pushAlert("Error in process.Try again later!", "danger");
        }
        this.setState({
          isButtonDisable: true,
        });
      });
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    return (
      <>
        <div className="card1 d-flex justify-content-center">
          <div className="card1__front shadow">
            <div
              className={
                this.state.default ? "card1__front--bg" : "card1__front1--bg"
              }
            >
              <div
                className={
                  this.state.default ? "front--bg--s1" : "front1--bg--s1"
                }
              ></div>
            </div>

            <div
              className="card1__brand row"
              style={{ marginTop: "10px", paddingLeft: "0px" }}
            >
              {this.state.default ? (
                <span
                  className="card1__brand--logo font-logo col-5 "
                  style={{ marginLeft: "-30px" }}
                >
                  <span className="logo-letter logo-mid"></span>
                </span>
              ) : (
                <span
                  className="card1__brand--logo font-logo col-5 "
                  style={{ marginTop: "-40px", marginLeft: "-30px" }}
                >
                  <span className="logo-letter logo-mid"></span>
                </span>
              )}

              <span
                className="card1__brand--name font-normal1 col text-right"
                style={{ marginRight: "-30px" }}
              >
                {this.props.data.brand
                  ? this.props.data.brand
                  : this.props.data.bankName
                  ? this.props.data.bankName
                  : ""}
                {this.state.default ? (
                  <div className="row">
                    <div className="col">
                      <span className="span2 text-light">Default</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="row">
                      <div className="col">
                        {this.props.data.status === "new" ? (
                          <span
                            className="badge badge-secondary default mt-3"
                            style={{ padding: "5px" }}
                            onClick={() => this.addCard1()}
                          >
                            Make as default & verify
                          </span>
                        ) : (
                          <span
                            className="badge badge-secondary default mt-3"
                            style={{ padding: "5px" }}
                            onClick={() => this.addCard()}
                          >
                            Make as default
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col ">
                        {!this.state.loader ? (
                          <span
                            className="badge badge-danger delete mt-1"
                            style={{ padding: "5px" }}
                            onClick={() => this.deleteCard()}
                          >
                            Delete
                          </span>
                        ) : (
                          <>
                            <span
                              className="badge badge-danger  mt-1 disable"
                              style={{ padding: "5px" }}
                              disable="disable"
                            >
                              Delete
                            </span>
                            <div
                              className=" absolute  "
                              style={{ right: "30px", paddingTop: "40px" }}
                            >
                              <Loader3 />
                            </div>{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </span>
            </div>

            <div className="card1__chip">
              <div className="card1__chip--shape"></div>
              <div className="card1__chip--shape"></div>
              <div className="card1__chip--shape"></div>
            </div>

            {/* <div className="card1__extra">
                <div className="card1__extra--contactless">
                  <i className="fas fa-wifi"></i>
                </div>
              </div> */}

            <div
              className={
                this.state.default
                  ? "card1__number font-number"
                  : "card1__number1 font-number"
              }
            >
              {`**** **** **** ${this.props.data.last4}`}
            </div>

            <div
              className={
                this.state.default ? "card1__payment" : "card1__payment1"
              }
              style={{ marginRight: "-15px" }}
            >
              {this.props.data.object === "card" ? (
                <i className="fa fa-credit-card-alt ">
                  <span className="span2">
                    &nbsp;&nbsp;{this.props.data.country}
                  </span>
                </i>
              ) : (
                <i className="fa fa-university">
                  <span className="span2">{this.props.data.country}</span>
                </i>
              )}
            </div>
            <div
              className="card1__user row p-0 m-0"
              style={{ marginLeft: "0px" }}
            >
              <span className="card1__user--name  font-number col-5 p-0 ">
                {this.props.data.accountHolderName
                  ? this.props.data.accountHolderName
                  : this.props.user.firstName + " " + this.props.user.lastName}
              </span>
              {this.props.data.object === "card" ? (
                <div className="card1__user--valid col-3 p-0 m-0">
                  <div className="card1__user--valid-txt font-normal">
                    VALID THRU
                  </div>
                  <div className="card1__user--valid-date  font-number">
                    {this.props.data.expMonth} /{" "}
                    {String(this.props.data.expYear).substring(2, 4)}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default Card;
