//Profile Details Alert Reducer
const profileAlertReducer = (
  state = { open: false, percentage: 360 },
  action
) => {
  switch (action.type) {
    case "SET_OPEN_PROFILE_ALERT":
      state.open = action.open;
      return state;
    case "CHANGE_PERCENTAGE_PROFILE_ALERT":
      state.percentage = action.percentage;
      return state;
    default:
      return state;
  }
};
export default profileAlertReducer;
