import React, { Component } from "react";
import Loader from "../../Loader/Loader";
import { viewDocument } from "../../../Services/ApiServices";

export default class ApplicationAgreementPDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdfLink: "",
      loader: true,
    };
  }
  componentDidMount() {
    this.getAppAgreementData();
  }
  async getAppAgreementData() {
    viewDocument(this.props.id, "application")
      .then((response) => {
        this.setState({ loader: false });
        if (response.status === 200) {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: response.data.type })
          );
          const link = document.createElement("a");
          link.href = url;
          this.setState({ pdfLink: link });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
      });
  }

  render() {
    return (
      <center>
        {!this.state.loader ? (
          this.state.pdfLink ? (
            <>
              <div className="PDFWeb">
                <object
                  data={this.state.pdfLink}
                  type="application/pdf"
                  width="800px"
                  height="2100px"
                >
                  <p>
                    Your web browser doesn't have a PDF plugin. Instead you can{" "}
                    <a href={this.state.pdfLink}>
                      click here to download the PDF file.
                    </a>
                  </p>
                </object>
              </div>
              <div className="PDFMobile">
                <object
                  data={this.state.pdfLink}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    Your web browser doesn't have a PDF plugin. Instead you can{" "}
                    <a href={this.state.pdfLink}>
                      click here to download the PDF file.
                    </a>
                  </p>
                </object>
              </div>
              <a className="pt-0 mt-0 PDFMobile " href={this.state.pdfLink}>
                click here to view the full PDF file.
              </a>
            </>
          ) : (
            // <embed src= width="800px" height="2100px" />
            <div className="row">
              <div className="col  text-center mt-5 pt-5">
                <span className="span2  ">File is Not Available</span>
              </div>
            </div>
          )
        ) : (
          <Loader />
        )}
      </center>
    );
  }
}
