// @author Karunaaharan Bavaram
import React, { Component } from "react";
import "./chat.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { appendAlert } from "../../../actions";
import { url } from "../../../Services/ApiServices";
import image1 from "../../../Images/profile.png";
import logo from "../../../Images/favicon.ico";

class ChatContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.images > 0 ? this.props.images[0].image : image1,
    };
  }

  //get store image or fetch profile image
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    if (this.props.images > 0) {
      this.setState({ image: this.props.images[0].image });
    } else {
      if (this.props.contact !== undefined) {
        if (this.props.contact.provider === "local") {
          fetch(url + `api/user/${this.props.contact.username}/image/download`)
            .then((res) => {
              if (res.status === 200) {
                this.setState({ image: res.url });
                this.props.storeImage(this.props.contact.username, res.url);
              } else {
                this.setState({ image: image1 });
                this.props.storeImage(this.props.contact.username, image1);
              }
            })
            .catch((_res) => {
              this.setState({ image: image1 });
              this.props.storeImage(this.props.contact.username, image1);
            });
        } else {
          this.setState({ image: image1 });
          this.props.storeImage(this.props.contact.username, image1);
        }
      }
    }
  }
  render() {
    return (
      this.props.contact.username !== this.props.userDetailArray.username && (
        <div
          className="contact"
          onClick={() => this.props.goToChat(this.props.contact)}
        >
          <img
            src={
              this.props.contact.username === "info@axallant.com"
                ? logo
                : this.state.image
            }
            alt="profileImage"
          />
          <p>
            <strong>
              {this.props.contact.firstName + " " + this.props.contact.lastName}
            </strong>
            <br></br>
            <span>{this.props.contact.username}</span>
          </p>
          <div className="status available"></div>
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    user: state.userReducer,
    userDetailArray: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert })(ChatContact)
);
