import React, { Component } from "react";
import Loader3 from "../Loader/loader3";

import "../Pages/Application/application.css";
import DateInput from "./DateInput";

class ApplicationInput extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const style = {
      paddingRight: this.props.icon && "60px",
      paddingLeft: this.props.iconL && "40px",
    };

    return (
      <>
        {this.props.type === "YN" || this.props.type === "optional" ? (
          <div className="outSpan">
            <span
              className={
                this.props.titleBreak
                  ? this.props.dark
                    ? "searchListHeadAppDark"
                    : "searchListHeadApp"
                  : this.props.dark
                  ? "searchListHeadAppDark noBreak"
                  : "searchListHeadApp noBreak"
              }
              style={{ width: "100%" }}
            >
              {this.props.name}
              {this.props.important && (
                <span className="textPri" style={{ fontSize: "15px" }}>
                  {" "}
                  &nbsp;*
                </span>
              )}
              {this.props.info && (
                <span className="infoHandel mb-5">
                  <i
                    className="fa fa-question-circle infoIcon"
                    aria-hidden="true"
                  ></i>
                  <br></br>
                  <div
                    className={
                      this.props.infoStyle === "infoContent2"
                        ? "informationListApp mt-2 infoContent2 "
                        : " informationListApp mt-2 infoContent optionInfo"
                    }
                  >
                    <span className="searchListContentApp">
                      {this.props.info}
                    </span>
                  </div>
                </span>
              )}
            </span>
            <select
              className={
                this.props.validate && this.props.important
                  ? this.props.designType === "2"
                    ? "ApplicationInputSelectAgg custom-select alert"
                    : "ApplicationInputSelect custom-select alert"
                  : this.props.designType === "2"
                  ? "ApplicationInputSelectAgg custom-select"
                  : "ApplicationInputSelect custom-select"
              }
              onChange={this.props.onChange}
              name={this.props.target}
              value={this.props.value}
              id={this.props.id}
            >
              <option value="" disabled="">
                Select {this.props.select ? this.props.select : "value"}
              </option>
              {this.props.type === "YN" ? (
                <>
                  <option value={"true"}>{this.props.options[0]}</option>
                  <option value={"false"}>{this.props.options[1]}</option>
                </>
              ) : (
                this.props.options.length > 0 &&
                this.props.optionValue.length > 0 &&
                this.props.options.map((option, index) => (
                  <option value={this.props.optionValue[index]} key={"ApplicationInput"+this.props.target+index}>
                    {option}
                  </option>
                ))
              )}
            </select>{" "}
            {this.props.validate && this.props.important && (
              <p className="spanValidateApp textPri">
                Please choose {this.props.errorMessage}
              </p>
            )}
          </div>
        ) : this.props.type === "optionYN" ? (
          <div className="outSpan">
            <span
              className={
                this.props.titleBreak
                  ? this.props.dark
                    ? "searchListHeadAppDark"
                    : "searchListHeadApp"
                  : this.props.dark
                  ? "searchListHeadAppDark noBreak"
                  : "searchListHeadApp noBreak"
              }
            >
              {this.props.name}
              {this.props.important && (
                <span className="textPri" style={{ fontSize: "15px" }}>
                  {" "}
                  &nbsp;*
                </span>
              )}
              {this.props.info && (
                <span className="infoHandel mb-5">
                  <i
                    className="fa fa-question-circle infoIcon"
                    aria-hidden="true"
                  ></i>
                  <br></br>
                  <div
                    className={
                      this.props.infoStyle === "infoContent2"
                        ? "informationListApp mt-2 infoContent2"
                        : " informationListApp mt-2 infoContent optionInfo"
                    }
                  >
                    <span className="searchListContentApp">
                      {this.props.info}
                    </span>
                  </div>
                </span>
              )}
            </span>
            <br></br>
            <label
              className={
                this.props.size === "long"
                  ? "switchApplication1 mt-3"
                  : "switchApplication mt-3"
              }
            >
              <input
                type="checkbox"
                name={this.props.target}
                checked={this.props.value ? "checked" : ""}
                onChange={this.props.onChange}
                max={this.props.max}
                min={this.props.min}
                id={this.props.id}
              />
              <div
                className={
                  this.props.size === "long"
                    ? "sliderApplication1 round "
                    : "sliderApplication round"
                }
              >
                <span className="onApp">
                  <b>{this.props.options ? this.props.options[0] : "Yes"}</b>
                </span>
                <span className="offApp">
                  <b>{this.props.options ? this.props.options[1] : "No"}</b>
                </span>
              </div>
            </label>
          </div>
        ) : this.props.type === "info" ? (
          <div className="outSpan">
            <span
              className={
                this.props.titleBreak
                  ? this.props.dark
                    ? "searchListHeadAppDark"
                    : "searchListHeadApp"
                  : this.props.dark
                  ? "searchListHeadAppDark noBreak"
                  : "searchListHeadApp noBreak"
              }
            >
              {this.props.name}
              {this.props.important && (
                <span className="textPri" style={{ fontSize: "15px" }}>
                  {" "}
                  &nbsp;*
                </span>
              )}
            </span>
            <span className="infoHandel">
              <i
                className="fa fa-question-circle infoIcon"
                aria-hidden="true"
              ></i>
              <br></br>
              {this.props.value !== "" && (
                <div
                  className={
                    this.props.infoStyle === "infoContent2"
                      ? "informationListApp mt-2 infoContent2"
                      : " informationListApp mt-2 infoContent optionInfo"
                  }
                >
                  <span className="searchListContentApp">
                    {this.props.value}
                  </span>
                </div>
              )}
            </span>
          </div>
        ) : this.props.type === "textArea" ? (
          <div className="outSpan">
            {this.props.name === "" ? (
              <span
                className={
                  this.props.titleBreak
                    ? this.props.dark
                      ? "searchListHeadAppDark"
                      : "searchListHeadApp"
                    : this.props.dark
                    ? "searchListHeadAppDark noBreak"
                    : "searchListHeadApp noBreak"
                }
              >
                -
              </span>
            ) : this.props.name === "null" ? (
              <></>
            ) : (
              <span
                className={
                  this.props.titleBreak
                    ? this.props.dark
                      ? "searchListHeadAppDark"
                      : "searchListHeadApp"
                    : this.props.dark
                    ? "searchListHeadAppDark noBreak"
                    : "searchListHeadApp noBreak"
                }
                style={{ width: "100%" }}
              >
                {this.props.name}
                {this.props.subName && (
                    <span className="spanThin">{this.props.subName}</span>
                  )}
                {this.props.important && (
                  <span className="textPri" style={{ fontSize: "15px" }}>
                    {" "}
                    &nbsp;*
                  </span>
                )}
              </span>
            )}
            {this.props.iconL && (
              <i
                className={
                  this.props.iconL + " fa  fa-10x absolute pointer iconL"
                }
                style={{ marginLeft: "30px", marginTop: "45px", left: 0 }}
                aria-hidden="true"
              ></i>
            )}
            <textarea
              className={
                this.props.validate && this.props.important
                  ? this.props.designType === "2"
                    ? "ApplicationInputAgg alert"
                    : "ApplicationInput alert"
                  : this.props.designType === "2"
                  ? "ApplicationInputAgg"
                  : "ApplicationInput"
              }
              type={
                this.props.value === "please wait..." ? "text" : this.props.type
              }
              placeholder={this.props.place}
              onChange={this.props.onChange}
              name={this.props.target}
              value={this.props.value}
              disabled={this.props.value === "please wait..." && "disabled"}
              max={this.props.max}
              min={this.props.min}
              rows={this.props.row}
            ></textarea>{" "}
            {this.props.icon && (
              <i
                className={this.props.icon + " fa  fa-10x absolute pointer"}
                style={{ marginLeft: "-40px", marginTop: "20px",top:"25px",right:"30px" }}
                aria-hidden="true"
                onClick={() => this.props.clickIcon()}
              ></i>
            )}
            {this.props.value === "please wait..." && <Loader3 />}
            {this.props.validate && this.props.important && (
              <p className="spanValidateApp textPri">
                Please enter {this.props.errorMessage}
              </p>
            )}
          </div>
        ) : this.props.type === "textArea2" ? (
          <div className="outSpan">
            {this.props.name === "" ? (
              <span
                className={
                  this.props.titleBreak
                    ? this.props.dark
                      ? "searchListHeadAppDark"
                      : "searchListHeadApp"
                    : this.props.dark
                    ? "searchListHeadAppDark noBreak"
                    : "searchListHeadApp noBreak"
                }
              >
                -
              </span>
            ) : this.props.name === "null" ? (
              <></>
            ) : (
              this.props.name !== "non" && (
                <span
                  className={
                    this.props.titleBreak
                      ? this.props.dark
                        ? "searchListHeadAppDark2"
                        : "searchListHeadApp2"
                      : this.props.dark
                      ? "searchListHeadAppDark2 noBreak"
                      : "searchListHeadApp2 noBreak"
                  }
                  style={{ width: "100%" }}
                >
                  {this.props.name}
                  {this.props.subName && (
                    <span className="spanThin">{this.props.subName}</span>
                  )}
                  {this.props.important && (
                    <span className="textPri" style={{ fontSize: "15px" }}>
                      {" "}
                      &nbsp;*
                    </span>
                  )}
                </span>
              )
            )}
            {this.props.iconL && (
              <i
                className={
                  this.props.iconL + " fa  fa-10x absolute pointer iconL"
                }
                style={{ marginLeft: "30px", marginTop: "45px", left: 0 }}
                aria-hidden="true"
              ></i>
            )}
            <textarea
              className={
                this.props.validate && this.props.important
                  ? this.props.designType === "2"
                    ? "ApplicationInputAgg2 alert"
                    : "ApplicationInput2 alert"
                  : this.props.designType === "2"
                  ? "ApplicationInputAgg2"
                  : "ApplicationInput2"
              }
              type={
                this.props.value === "please wait..." ? "text" : this.props.type
              }
              placeholder={this.props.place}
              onChange={this.props.onChange}
              name={this.props.target}
              value={this.props.value}
              disabled={this.props.value === "please wait..." && "disabled"}
              max={this.props.max}
              min={this.props.min}
              rows={this.props.row}
            ></textarea>{" "}
            {this.props.icon && (
              <i
                className={this.props.icon + " fa  fa-10x absolute pointer"}
                style={{ marginLeft: "-40px", marginTop: "20px" ,top:"25px",right:"30px"}}
                aria-hidden="true"
                onClick={() => this.props.clickIcon()}
              ></i>
            )}
            {this.props.value === "please wait..." && <Loader3 />}
            {this.props.validate && this.props.important && (
              <p className="spanValidateApp textPri">
                Please enter {this.props.errorMessage}
              </p>
            )}
          </div>
        ) : this.props.type === "checkedBox" ? (
          <div className="outSpan">
            <label className="checkedContainer ">
              {" "}
              {this.props.name}
              {this.props.important && (
                <span className="textPri" style={{ fontSize: "15px" }}>
                  {" "}
                  &nbsp;*
                </span>
              )}
              <input
                type="checkbox"
                onChange={this.props.onChange}
                name={this.props.target}
                checked={this.props.value ? "checked" : ""}
                max={this.props.max}
                min={this.props.min}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        ) : this.props.type === "optionBox" ? (
          <div className="outSpan">
            <p
              className={"optionTitle textDark"}
              style={{ marginBottom: "10px" }}
            >
              {this.props.name}
              {this.props.important && (
                <span className="textPri" style={{ fontSize: "15px" }}>
                  {" "}
                  &nbsp;*
                </span>
              )}
            </p>
            {this.props.options.map((option, index) => (
              <label className="optionContainer ">
                {" "}
                {option}
                <input
                  type="radio"
                  checked={
                    this.props.value === this.props.optionValue[index]
                      ? "checked"
                      : ""
                  }
                  onChange={this.props.onChange}
                  name={this.props.target}
                  value={this.props.optionValue[index]}
                  max={this.props.max}
                  min={this.props.min}
                />
                <span className="checkmark1"></span>
              </label>
            ))}
          </div>
        ) : this.props.type === "text2" ? (
          <div className="outSpan">
            {this.props.name === "" ? (
              <span
                className={
                  this.props.titleBreak
                    ? this.props.dark
                      ? "searchListHeadAppDark"
                      : "searchListHeadApp"
                    : this.props.dark
                    ? "searchListHeadAppDark noBreak"
                    : "searchListHeadApp noBreak"
                }
              ></span>
            ) : this.props.name === "null" ? (
              <></>
            ) : (
              this.props.name !== "non" && (
                <span
                  className={
                    this.props.titleBreak
                      ? "searchListHeadApp2"
                      : "searchListHeadApp2 noBreak"
                  }
                  style={{ width: "100%" }}
                >
                  {this.props.name}

                  {this.props.important && (
                    <span className="textPri" style={{ fontSize: "15px" }}>
                      {" "}
                      &nbsp;*
                    </span>
                  )}
                  {this.props.info && (
                    <span className="infoHandel mb-5">
                      <i
                        className="fa fa-question-circle infoIcon"
                        aria-hidden="true"
                      ></i>
                      <br></br>
                      <div
                        className={
                          this.props.infoStyle === "infoContent2"
                            ? "informationListApp mt-2 infoContent2"
                            : " informationListApp mt-2 infoContent optionInfo"
                        }
                      >
                        <span className="searchListContentApp">
                          {this.props.info}
                        </span>
                      </div>
                    </span>
                  )}
                </span>
              )
            )}
            {this.props.iconL && (
              <i
                className={
                  this.props.iconL + " fa  fa-10x absolute pointer iconL"
                }
                style={{ marginLeft: "30px", marginTop: "45px", left: 0 }}
                aria-hidden="true"
              ></i>
            )}
            <input
              className={
                this.props.validate && this.props.important
                  ? this.props.designType === "2"
                    ? this.props.align === "right"
                      ? "ApplicationInputAgg2 alert text-right"
                      : "ApplicationInputAgg2 alert"
                    : this.props.align === "right"
                    ? "ApplicationInput2 alert text-right"
                    : "ApplicationInput2 alert"
                  : this.props.designType === "2"
                  ? this.props.align === "right"
                    ? "ApplicationInputAgg2 text-right"
                    : "ApplicationInputAgg2 "
                  : this.props.align === "right"
                  ? "ApplicationInput2 text-right"
                  : "ApplicationInput2 "
              }
              type={
                this.props.value === "please wait..." ? "text" : this.props.type
              }
              placeholder={this.props.place}
              onChange={this.props.onChange}
              name={this.props.target}
              value={this.props.value}
              disabled={this.props.value === "please wait..." && "disabled"}
              max={this.props.max}
              min={this.props.min}
              id={this.props.id}
              style={style}
            ></input>{" "}
            {this.props.icon && (
              <i
                className={this.props.icon + " fa  fa-10x absolute pointer"}
                style={{ marginLeft: "-40px", marginTop: "20px" ,top:"25px",right:"30px"}}
                aria-hidden="true"
                onClick={() => this.props.clickIcon()}
              ></i>
            )}
            {this.props.iconTextR && (
              <span
                className={"absolute span2"}
                style={{ right: "30px", top: "45px" }}
              >
                {this.props.iconTextR}
              </span>
            )}
            {this.props.value === "please wait..." && <Loader3 />}
            {this.props.validate && this.props.important && (
              <p className="spanValidateApp textPri">
                {this.props.errorStart !== "false" && "Please enter"}{" "}
                {this.props.errorMessage}
              </p>
            )}
          </div>
        ) :this.props.type === "date" ? (
          <DateInput
                    name={this.props.name}
                    target={this.props.target}
                    min={this.props.min}
                    max={this.props.max}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    place={this.props.place}
                    important={this.props.important}
                    errorMessage={this.props.errorMessage}
                    validate={this.props.validate}
                    designType={this.props.designType}
                  />
        ): (
          <div className="outSpan">
            {this.props.name === "" ? (
              <span
                className={
                  this.props.titleBreak
                    ? this.props.dark
                      ? "searchListHeadAppDark"
                      : "searchListHeadApp"
                    : this.props.dark
                    ? "searchListHeadAppDark noBreak"
                    : "searchListHeadApp noBreak"
                }
              ></span>
            ) : this.props.name === "null" ? (
              <></>
            ) : (
              <span
                className={
                  this.props.titleBreak
                    ? "searchListHeadApp"
                    : "searchListHeadApp noBreak"
                }
                style={{ width: "100%" }}
              >
                {this.props.name}

                {this.props.important && (
                  <span className="textPri" style={{ fontSize: "15px" }}>
                    {" "}
                    &nbsp;*
                  </span>
                )}
                {this.props.info && (
                  <span className="infoHandel mb-5">
                    <i
                      className="fa fa-question-circle infoIcon"
                      aria-hidden="true"
                    ></i>
                    <br></br>
                    <div
                      className={
                        this.props.infoStyle === "infoContent2"
                          ? "informationListApp mt-2 infoContent2"
                          : " informationListApp mt-2 infoContent optionInfo"
                      }
                    >
                      <span className="searchListContentApp">
                        {this.props.info}
                      </span>
                    </div>
                  </span>
                )}
              </span>
            )}
            {this.props.iconL && (
              <i
                className={
                  this.props.iconL + " fa  fa-10x absolute pointer iconL"
                }
                style={{ marginLeft: "30px", marginTop: "45px", left: 0 }}
                aria-hidden="true"
              ></i>
            )}
            <input
              className={
                this.props.validate && this.props.important
                  ? this.props.designType === "2"
                    ? this.props.align === "right"
                      ? "ApplicationInputAgg alert text-right"
                      : "ApplicationInputAgg alert"
                    : this.props.align === "right"
                    ? "ApplicationInput alert text-right"
                    : "ApplicationInput alert"
                  : this.props.designType === "2"
                  ? this.props.align === "right"
                    ? "ApplicationInputAgg text-right"
                    : "ApplicationInputAgg "
                  : this.props.align === "right"
                  ? "ApplicationInput text-right"
                  : "ApplicationInput "
              }
              type={
                this.props.value === "please wait..." ? "text" : this.props.type
              }
              placeholder={this.props.place}
              onChange={this.props.onChange}
              name={this.props.target}
              value={this.props.value}
              disabled={this.props.value === "please wait..." && "disabled"}
              max={this.props.max}
              min={this.props.min}
              id={this.props.id}
              style={style}
            ></input>{" "}
            {this.props.icon && (
              <i
                className={this.props.icon + " fa  fa-10x absolute pointer"}
                style={{ marginLeft: "-40px", marginTop: "20px",top:"25px",right:"30px"}}
                aria-hidden="true"
                onClick={() => this.props.clickIcon()}
              ></i>
            )}
            {this.props.iconTextR && (
              <span
                className={"absolute span2"}
                style={{ right: "30px", top: "45px" }}
              >
                {this.props.iconTextR}
              </span>
            )}
            {this.props.value === "please wait..." && <Loader3 />}
            {this.props.validate && this.props.important && (
              <p className="spanValidateApp textPri">
                {this.props.errorStart !== "false" && "Please enter"}{" "}
                {this.props.errorMessage}
              </p>
            )}
          </div>
        )}
      </>
    );
  }
}

export default ApplicationInput;
