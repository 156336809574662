// Temporary Property by Listing Details Reducer
const tempPropertyByUnitReducer = (state = [], action) => {
     switch (action.type) {
       case "GET_TEMP_PROPERTY_BY_UNIT_DATA":
         return state;
       case "APPEND_TEMP_PROPERTY_BY_UNIT_DATA":
          let propertyByUnit={
               id:action.id,
               units:action.listArray
          };
         let lists=[ propertyByUnit,...state];
         lists = lists.filter((obj, pos, arr) => {
          return arr
            .map(mapObj => mapObj.id)
            .indexOf(obj.id) === pos;
        });
         return  lists;
       case "APPEND_TEMP_PROPERTY_BY_UNIT_DATA_ARRAY":
         return  [...state, ...action.listArray];
       case "REMOVE_TEMP_PROPERTY_BY_UNIT_DATA_BY_ID":
         return state.filter((todo) => todo.id !== action.id )
       case "REMOVE_TEMP_PROPERTY_BY_UNIT_DATA":
         return null;
       default:
         return state;
     }
   };
export default tempPropertyByUnitReducer;
   