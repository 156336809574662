//Rental Listing Details Reducer
const rentalListReducer = (state = null, action) => {
     switch (action.type) {
       case "GET_RENTAL_LIST_DATA":
         return state;
       case "SET_RENTAL_LIST_DATA":
         return  action.rentalList;
       case "REMOVE_RENTAL_LIST_DATA_BY_ID":
         return state.filter((todo) => todo.id !== action.id )
       case "REMOVE_RENTAL_LIST_DATA":
         return null;
       default:
         return state;
     }
   };
export default rentalListReducer;
   