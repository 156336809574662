import React, { Component } from "react";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import "./ProfileCard.css";

class ApartmentDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitDetail: [],
    };
  }

  mapFeatures(data) {
    let items = [];
    for (const element of data) {
      items.push(<li>{element}</li>);
    }
    return items;
  }
  render() {
    return (
      <div className="dropdown dropLeft text-center">
        {this.props.unitDetail === undefined && "-"}
        {this.props.unitDetail && this.props.unitDetail.length !== 0 && (
          <>
            <div
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {" "}
              {this.props.unitDetail.unit}&nbsp;&nbsp;&nbsp;
              <i className="fa fa-ellipsis-v"></i>
            </div>
            <div
              className={"dropdown-menu detailsCard p-0 m-0 detailsCard2"}
              aria-labelledby="dropdownMenu2"
            >
              <div className="row p-0 m-0">
                <div className="col-md-6 p-0 m-0">
                  <img
                    src={this.props.unitDetail.image}
                    alt="Person"
                    className="card_unit__image mx-auto d-block "
                  />
                </div>
                <div className="col">
                  <span className="detailsText textPri">
                    {this.props.unitDetail.name}
                  </span>
                  <br></br>
                  <span className="detailsText text-dark">
                    {this.props.unitDetail.plan}
                  </span>
                  <br></br>
                  <span className="span2 text-dark">
                    {this.props.unitDetail.sq}
                  </span>
                  <br></br>
                  <span className="span2 text-dark">
                    <CurrencyFormat value={this.props.unitDetail.price} />
                  </span>
                  <br></br>

                  <a
                    href={this.props.unitDetail.pdf}
                    className="availableTableHead2"
                  >
                    PDF FLOOR PLAN
                  </a>
                  <br></br>
                  <span className="detailsText textPri">Features</span>
                  <br></br>
                  <ul className="detailsText2">
                    {this.mapFeatures(this.props.unitDetail.Features)}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ApartmentDetail;
