//Role Status Reducer
const RoleReducer = (state = "Guest", action) => {
  switch (action.type) {
    case "CHANGE_ROLE":
      if (action.role === 1) return "Tenant";
      else if (action.role === 2) return "Landlord";
      else if (action.role === 3) return "Admin";
      else if (action.role === 0) return state;
      else return state;
    case "CHANGE_ROLE_GUEST":
      return "Guest";
    case "CHANGE_ROLE_ADMIN":
      return "Admin";
    case "CHANGE_ROLE_TENANT":
      return "Tenant";
    case "CHANGE_ROLE_LANDLORD":
      return "Landlord";
    case "GET_ROLE":
      return state;
    default:
      return state;
  }
};
export default RoleReducer;
