import React, { Component } from "react";
import FileViewer from "react-file-viewer";
import { withRouter } from "react-router";

import {
  viewContractDocument,
  viewDocument,
  url,
} from "../../../Services/ApiServices";

import Loader2 from "../../Loader/Loader2";

export class ViewDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: "",
      downloadFileType: "",
      isDownload: false,
      isOpen: false,
      loader: true,
    };
    this.rejectContract = this.rejectContract.bind(this);
    this.acceptContract = this.acceptContract.bind(this);
  }

  componentDidMount() {
    if (
      this.props.file === "contractAgreement" ||
      this.props.file === "moveInOutCheckList"
    ) {
      let id = this.props.id;
      if (this.props.file === "contractAgreement") {
        let array = this.props.viewFilesArray
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .filter((todo) => todo.category === "contractAgreement");
        id = array[0].id;
      }
      viewContractDocument(id, this.props.file).then((response) => {
        if (response.status === 200) {
          const url1 = window.URL.createObjectURL(
            new Blob([response.data], { type: response.data.type })
          );
          const link = document.createElement("a");
          link.href = url1;
          this.setState({ imgSrc: link });
          this.setState({ loader: false });
          if (
            response.data.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            this.setState({ downloadFileType: "docx" });
          } else {
            this.setState({ downloadFileType: "pdf" });
          }
        } else {
          this.props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } else {
      viewDocument(this.props.id, this.props.file).then((response) => {
        if (response.status === 200) {
          const url2 = window.URL.createObjectURL(
            new Blob([response.data], { type: response.data.type })
          );
          const link = document.createElement("a");
          link.href = url2;
          this.setState({ imgSrc: link });
          this.setState({ loader: false });
          if (
            response.data.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            this.setState({ downloadFileType: "docx" });
          } else {
            this.setState({ downloadFileType: "pdf" });
          }
        } else {
          this.props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    }
  }

  async rejectContract() {
    try {
      await fetch(url + `api/contract/reject-contract/${this.props.id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            if (data.status) {
              this.props.history.push({ pathname: "/contract" });
              this.props.pushAlert("Contract Rejected", "success");
            } else {
              this.props.pushAlert("Error in rejection", "danger");
            }
          });
        } else {
          this.props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async acceptContract(modalData) {
    this.setState({ isOpen: false });

    try {
      await fetch(
        url + `api/contract/accept-contract/${this.props.id}/${modalData}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("access_token"),
          },
        }
      ).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            if (data.status) {
              this.props.history.push({ pathname: "/contract" });
              this.props.pushAlert("Contract Accepted", "success");
            } else {
              this.props.pushAlert("Error in acceptance", "danger");
            }
          });
        } else {
          this.props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { imgSrc } = this.state;
    let review = (
      <div>
        <br />
        {this.state.downloadFileType === "pdf" && (
          <div style={{ width: "100%", height: "100%" }}>
            {this.props.file === "contractAgreement" &&
            sessionStorage.getItem("role") === "tenant" ? (
              <div className="text-right">
                <button
                  //   variant="outline-success"
                  onClick={() => {
                    this.setState({ isOpen: true });
                  }}
                >
                  {" "}
                  Accept
                </button>
                &nbsp; &nbsp;
                <button
                  //   variant="outline-danger"
                  onClick={() => {
                    this.rejectContract(this.state.contractDatas);
                  }}
                >
                  Reject
                </button>
                <br />
                <br />
              </div>
            ) : null}

            {/* <embed src={imgSrc} width="100%" height="500px" /> */}
            <center>
              <div className="PDFWeb">
                <object
                  data={imgSrc}
                  type="application/pdf"
                  width="100%"
                  height="500px"
                >
                  <p>
                    Your web browser doesn't have a PDF plugin. Instead you can{" "}
                    <a href={imgSrc}>click here to download the PDF file.</a>
                  </p>
                </object>
              </div>
              <div className="PDFMobile">
                <object
                  data={imgSrc}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                >
                  <p>
                    Your web browser doesn't have a PDF plugin. Instead you can{" "}
                    <a href={imgSrc}>click here to download the PDF file.</a>
                  </p>
                </object>
              </div>
              <a className="pt-0 mt-0 PDFMobile " href={imgSrc}>
                click here to view the full PDF file.
              </a>
            </center>
            <br />
            <br />
          </div>
        )}
        {this.state.downloadFileType === "docx" && (
          <>
            <div className="text-right">
              <button
                onClick={() => {
                  this.setState({ isDownload: true });
                  setTimeout(
                    function () {
                      this.setState({ isDownload: false });
                    }.bind(this),
                    100
                  );
                }}
              >
                Download
              </button>
            </div>

            {this.state.isDownload && (
              // <embed src={imgSrc} width="0px" height="0px" />
              <center>
                <div className="PDFWeb">
                  <object
                    data={imgSrc}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  >
                    <p>
                      Your web browser doesn't have a PDF plugin. Instead you
                      can{" "}
                      <a href={imgSrc}>click here to download the PDF file.</a>
                    </p>
                  </object>
                </div>
                <div className="PDFMobile">
                  <object
                    data={imgSrc}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  >
                    <p>
                      Your web browser doesn't have a PDF plugin. Instead you
                      can{" "}
                      <a href={imgSrc}>click here to download the PDF file.</a>
                    </p>
                  </object>
                </div>
                <a className="pt-0 mt-0 PDFMobile " href={imgSrc}>
                  click here to view the full PDF file.
                </a>
              </center>
            )}
            <div
              style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                height: "100%",
                border: "1px solid #ddd",
                overflow: "auto",
                background: "white",
              }}
            >
              <FileViewer
                fileType={this.state.downloadFileType}
                filePath={imgSrc}
              />
            </div>
          </>
        )}
        {/* <Confirmation
          open={this.state.isOpen}
          onClose={() => this.setState({ isOpen: false })}
          onAccept={this.acceptContract}
        /> */}
      </div>
    );

    let content = review;
    if (this.state.loader) {
      return (
        <div style={{ paddingTop: "5%" }}>
          <Loader2 />
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "0px" }} className="">
          {content}
        </div>
      );
    }
  }
}
export default withRouter(ViewDocument);
