import React, { Component } from "react";
import {
  checkNull,
  convertLocalDate,
  convertLocalTime,
  exceptNull,
  sortText,
  timeCalculativeFormat,
} from "../../../../Defined/Function";
import "./MaintenanceRequestOrder.css";
import image1 from "../../../../Images/doc.png";
import image2 from "../../../../Images/paperClip.png";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { appendAlert } from "../../../../actions";

class MaintenanceRequestOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      checkNull([this.props.data.jobAppointment]) && (
        <div className="maintenanceRequestCard mt-3 ">
          <span className="maintenanceRequestDetailText1 ">Order Details</span>

          {(this.props.roleStatus !== "Tenant" ||
            this.props.roleStatus !== "Landlord") &&
            this.props.mode === "open" && (
              <span className="maintenanceRequestDetailText3 pointer  pull-right pt-1">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mark as complete
              </span>
            )}
          <div className="row maintenanceOrderContainer mb-3">
            <div className="maintenanceOrderImageAlign ">
              <img
                alt="tenant"
                src={image1}
                className="maintenanceRequestOrderImage "
              />
            </div>
            <div className="maintenanceOrderNameAlign ">
              <h1 className="maintenanceRequestText2 textLeft">Quotation</h1>
              <h3 className="maintenanceRequestText1 textLeft">
                # {this.props.data.jobAppointment.id}
              </h3>
            </div>
            <div className="maintenanceOrderImageDummyAlign"></div>
            <div className="maintenanceOrderCostAlign ">
              <h3 className="maintenanceRequestDetailText5 ">
                <CurrencyFormat
                  value={this.props.data.jobAppointment.approvedCost}
                />
              </h3>
            </div>
            <div className="maintenanceOrderTimeAlign ">
              <h3 className="maintenanceRequestDetailText5 ">
                {timeCalculativeFormat(
                  this.props.data.jobAppointment.createdAt
                )}
              </h3>
            </div>
          </div>
          <hr className="orderDivider" />
          <h3 className="maintenanceRequestDetailText4 pt-3 pb-3 requestOrderText">
            {sortText(
              checkNull([this.props.data.jobAppointment.description]) &&
                exceptNull(this.props.data.jobAppointment.description),
              0,
              475
            )}
          </h3>
          <hr className="orderDivider" />
          <div className="row mt-3">
            <div className="col">
              <div className="row">
                <div className="maintenanceRoundIcon col-4">
                  <span style={{ textDecoration: "none" }}>
                    <i className="fa fa-play text-dark" aria-hidden="true"></i>{" "}
                  </span>
                </div>

                <div className="col ">
                  <h1 className="maintenanceRequestText2 textLeft">
                    {exceptNull(
                      convertLocalDate(this.props.data.jobAppointment.startOn)
                    )}
                  </h1>
                  <h3 className="maintenanceRequestText1 textLeft">
                    {exceptNull(
                      convertLocalTime(this.props.data.jobAppointment.startOn)
                    )}
                  </h3>
                </div>
              </div>
              <div className="row mobileResponseMaintenanceOrder1 mt-2">
                <div className="maintenanceRoundIcon col-4">
                  <span style={{ textDecoration: "none" }}>
                    <i className="fa fa-play text-dark" aria-hidden="true"></i>{" "}
                  </span>
                </div>

                <div className="col ">
                  <h1 className="maintenanceRequestText2 textLeft">
                    {exceptNull(
                      convertLocalDate(this.props.data.jobAppointment.endOn)
                    )}
                  </h1>
                  <h3 className="maintenanceRequestText1 textLeft">
                    {exceptNull(
                      convertLocalTime(this.props.data.jobAppointment.endOn)
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col mobileResponseMaintenanceOrder2">
              <div className="row">
                <div className="maintenanceRoundIcon col-4">
                  <span style={{ textDecoration: "none" }}>
                    <i className="fa fa-stop text-dark" aria-hidden="true"></i>{" "}
                  </span>
                </div>

                <div className="col ">
                  <h1 className="maintenanceRequestText2 textLeft">
                    {exceptNull(
                      convertLocalDate(this.props.data.jobAppointment.endOn)
                    )}
                  </h1>
                  <h3 className="maintenanceRequestText1 textLeft">
                    {exceptNull(
                      convertLocalTime(this.props.data.jobAppointment.endOn)
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-2  borderLeftGray d-flex align-items-center justify-content-center mobileResponseMaintenanceOrder3">
              <img
                alt="tenant"
                src={image2}
                className="maintenanceRequestOrderImage2 "
              />
            </div>
          </div>
          <div className="row mt-3 pt-3  borderTopGray d-flex align-items-center justify-content-center mobileResponseMaintenanceOrder4">
            <img
              alt="tenant"
              src={image2}
              className="maintenanceRequestOrderImage2 "
            />
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
    user: state.userReducer,
    userDetail: state.user,
    role: state.RoleReducer,
    roleStatus: state.role,
    provider: state.providerReducer,
    providerArray: state.provider,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert })(MaintenanceRequestOrder)
);
