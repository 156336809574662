import React, { Component } from "react";
import Loader3 from "../Loader/loader3";
import "./TimeInput.css";

class TimeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      hour: "01",
      minute: "00",
      mode: "AM",
      hours: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
      ],
      modes: ["AM", "PM"],
      value: this.props.value,
      displayValue: "--:-- --",
      focus: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocus0 = this.handleFocus0.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleBlur0 = this.handleBlur0.bind(this);
  }
  handleFocus(e) {
    this.setState({ listOpen: true });
  }
  handleFocus0(e) {
    this.setState({ listOpen: true, focus: false });
  }
  handleBlur(e) {
    setTimeout(
      function () {
        this.setState({ listOpen: false , focus: true });
      }.bind(this),
      300
    );
  }
  handleBlur0(e) {
    setTimeout(
      function () {
        if (this.state.focus) {
          this.setState({ listOpen: false });
        }
      }.bind(this),
      300
    );
  }
  handleClick() {
    this.setState({ listOpen: true ,focus: true});
  }

  handleChange(name, value) {
    var time = "";
    var time1 = "";
    if (name === "hour") {
      if (this.state.mode === "AM") {
        time = value + ":" + this.state.minute + ":00";
        time1 = value + ":" + this.state.minute + " AM";
      } else {
        time =
          (parseInt(value) + 12).toString() + ":" + this.state.minute + ":00";
        time1 = value + ":" + this.state.minute + " PM";
      }
    } else if (name === "minute") {
      if (this.state.mode === "AM") {
        time = this.state.hour + ":" + value + ":00";
        time1 = this.state.hour + ":" + value + " AM";
      } else {
        time =
          (parseInt(this.state.hour) + 12).toString() + ":" + value + ":00";
        time1 = this.state.hour + ":" + value + " PM";
      }
    } else {
      if (value === "AM") {
        if (this.state.minute === "PM") {
          time =
            (parseInt(this.state.hour) - 12).toString() +
            ":" +
            this.state.minute +
            ":00";
          time1 = this.state.hour + ":" + this.state.minute + " PM";
        } else {
          time = this.state.hour + ":" + this.state.minute + ":00";
          time1 = this.state.hour + ":" + this.state.minute + " AM";
        }
      } else {
        time =
          (parseInt(this.state.hour) + 12).toString() +
          ":" +
          this.state.minute +
          ":00";
        time1 = this.state.hour + ":" + this.state.minute + " PM";
      }
    }
    this.props.onChange(this.props.id, name, time, time1);
    this.setState({ value: time, displayValue: time1, [name]: value});
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
        if(this.props.value!==""){
        var x=this.props.value.split(":");
        var y=x[1].split(" ");
        
       this.setState({
        hour: x[0],
        minute: y[0],
        mode: y[1],
       });
      }
    }
}
  render() {
    const style = {
      paddingRight: this.props.icon && "60px",
      paddingLeft: this.props.iconL && "40px",
    };
    return (
      <div className="outSpan">
        {this.props.name === "" ? (
          <span
            className={
              this.props.titleBreak
                ? this.props.dark
                  ? "searchListHeadAppDark"
                  : "searchListHeadApp"
                : this.props.dark
                ? "searchListHeadAppDark noBreak"
                : "searchListHeadApp noBreak"
            }
          ></span>
        ) : this.props.name === "null" ? (
          <></>
        ) : (
          <span
            className={
              this.props.titleBreak
                ? "searchListHeadApp"
                : "searchListHeadApp noBreak"
            }
            style={{ width: "100%" }}
          >
            {this.props.name}

            {this.props.important && (
              <span className="textPri" style={{ fontSize: "15px" }}>
                {" "}
                &nbsp;*
              </span>
            )}
            {this.props.info && (
              <span className="infoHandel mb-5">
                <i
                  className="fa fa-question-circle infoIcon"
                  aria-hidden="true"
                ></i>
                <br></br>
                <div
                  className={
                    this.props.infoStyle === "infoContent2"
                      ? "informationListApp mt-2 infoContent2"
                      : " informationListApp mt-2 infoContent optionInfo"
                  }
                >
                  <span className="searchListContentApp">
                    {this.props.info}
                  </span>
                </div>
              </span>
            )}
          </span>
        )}
        <input
          className={
            this.props.validate && this.props.important
              ? this.props.designType === "2"
                ? this.props.align === "right"
                  ? "ApplicationInputAgg alert text-right"
                  : "ApplicationInputAgg alert"
                : this.props.align === "right"
                ? "ApplicationInput alert text-right"
                : "ApplicationInput alert"
              : this.props.designType === "2"
              ? this.props.align === "right"
                ? "ApplicationInputAgg text-right"
                : "ApplicationInputAgg "
              : this.props.align === "right"
              ? "ApplicationInput text-right"
              : "ApplicationInput "
          }
          onFocus={this.handleFocus}
          onBlur={this.handleBlur0}
          onClick={this.handleClick}
          onChange={this.onChange}
          autocomplete="nope"
          type={"text"}
          placeholder={this.props.place}
          name={this.props.target}
          value={this.props.value}
          disabled={this.props.value === "please wait..." && "disabled"}
          max={this.props.max}
          min={this.props.min}
          id={this.props.id}
          style={style}
        ></input>{" "}
        <i
          className="absolute fa fa-clock-o"
          onClick={this.handleClick}
          style={{ marginLeft: "-40px", marginTop: "20px" ,top:"25px",right:"30px"}}
          aria-hidden="true"
        ></i>
        {this.state.listOpen && (
          <div
            className="timeInputDropDown p-0"
            tabindex="0"
            style={{ zIndex:"1500" }}
            // onBlur={this.handleBlur}
            onFocus={this.handleFocus0}
          >
             <span
                    aria-hidden="true"
                    className="badge  inputClose  absolute"
                    style={{ marginLeft:"300px" }}
                    onClick={() => this.setState({ listOpen: false })}
                  >
                    &times;
                  </span>
            <div className="row p-0 m-0">
              <div className="col-4 m-0 p-0 timeInputList">
                {this.state.hours.map((element, index) => {
                  return (
                    <div
                      onClick={() =>
                        this.handleChange("hour", this.state.hours[index])
                      }
                      className={
                        parseInt(this.state.hours[index]) ===
                        parseInt(this.state.hour)
                          ? "timeInputListPart d-flex justify-content-center active"
                          : "timeInputListPart d-flex justify-content-center"
                      }
                    >
                      {this.state.hours[index]}
                    </div>
                  );
                })}
              </div>
              <div className="col-4 m-0 p-0 timeInputList">
                {this.state.minutes.map((element, index) => {
                  return (
                    <div
                      onClick={() =>
                        this.handleChange("minute", this.state.minutes[index])
                      }
                      className={
                        parseInt(this.state.minutes[index]) ===
                        parseInt(this.state.minute)
                          ? "timeInputListPart d-flex justify-content-center active"
                          : "timeInputListPart d-flex justify-content-center"
                      }
                    >
                      {this.state.minutes[index]}
                    </div>
                  );
                })}
              </div>
              <div className="col-4 m-0 p-0 timeInputList">
                {this.state.modes.map((element, index) => {
                  return (
                    <div
                      onClick={() =>
                        this.handleChange("mode", this.state.modes[index])
                      }
                      className={
                        this.state.modes[index] === this.state.mode
                          ? "timeInputListPart d-flex justify-content-center active"
                          : "timeInputListPart d-flex justify-content-center"
                      }
                    >
                      {this.state.modes[index]}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {this.props.value === "please wait..." && <Loader3 />}
        {this.props.validate && this.props.important && (
          <p className="spanValidateApp textPri">
            {this.props.errorStart !== "false" && "Please enter"}{" "}
            {this.props.errorMessage}
          </p>
        )}
      </div>
    );
  }
}

export default TimeInput;
