// Tenant Payment Details Reducer 
const customerPaymentDetailReducer = (state = null, action) => {
     switch (action.type) {
       case "GET_CUSTOMER_PAYMENT_DETAIL":
         return state;
       case "SET_CUSTOMER_PAYMENT_DETAIL":
         return action.customerPaymentDetail;
       case "REMOVE_CUSTOMER_PAYMENT_DETAIL_BY_ID":
         return state.filter((todo) => todo.id !== action.id )
       case "REMOVE_CUSTOMER_PAYMENT_DETAIL":
         return null;
       default:
         return state;
     }
};
export default customerPaymentDetailReducer;
      