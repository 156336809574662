// Contracts Details Reducer
const contractReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_CONTRACT":
      return state;
    case "SET_CONTRACT":
      return action.contract;
    case "REMOVE_CONTRACT_BY_ID":
      return state.filter((todo) => todo.id !== action.id);
    case "REMOVE_CONTRACT":
      return null;
    default:
      return state;
  }
};
export default contractReducer;
