import React, { Component } from "react";
import Loader from "../../../Loader/Loader";
import MaintenanceRequestLandlordDetail from "./MaintenanceRequestLandlordDetail";
import MaintenanceRequestTenantDetail from "./MaintenanceRequestTenantDetail";
import { withRouter } from "react-router";
import { connect } from "react-redux";
class MaintenanceRequestDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.props.roleStatus !== "Guest" ? (
      this.props.roleStatus === "Landlord" ? (
        <MaintenanceRequestLandlordDetail />
      ) : this.props.roleStatus === "Tenant" ? (
        <MaintenanceRequestTenantDetail />
      ) : (
        <h1 style={{ paddingTop: "70px" }}>Something went wrong</h1>
      )
    ) : (
      <Loader />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
    role: state.RoleReducer,
    roleStatus: state.role,
    user: state.userReducer,
    userDetailArray: state.user,
    provider: state.providerReducer,
    providerArray: state.provider,
  };
};
export default withRouter(
  connect(mapStateToProps, {})(MaintenanceRequestDetail)
);
