import React, { useEffect, useState } from "react";
import "./contactUs.css";
import image from "../../Images/contact.jpeg";
import { appendAlert } from "../../actions";
import { useDispatch } from "react-redux";
import { sendMessage } from "../../Services/ApiServices";
import Loader3 from "../Loader/loader3";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const ContactUs = (props) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: props.history.location.state
      ? props.history.location.state.email
      : "",
    message: "",
    phone: "",
  });
  const [next, setNext] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
  }, []);
  function onchange(e) {
    let {
      target: { name, value },
    } = e;
    if (name === "phone") {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        setState({ ...state, [name]: state[name] });
      } else {
        setState({ ...state, [name]: value });
      }
    } else {
      setState({ ...state, [name]: value });
    }
  }
  const onKeyUp = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      send();
    }
  };
  const checkMobile = (value) => {
    return (value.length !== 14 || value === "") && next;
  };
  const checkMobileNext = (value) => {
    return value.length === 14 || value === "";
  };
  const checkEmail = (value) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (!re.test(String(value).toLowerCase()) || value === "") && next;
  };
  const checkEmailNext = (value) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(value).toLowerCase()) || value === "";
  };
  const checkValidate = (value) => {
    return value === "" && next;
  };
  const send = () => {
    setNext(true);
    if (
      state.firstName !== "" &&
      state.lastName !== "" &&
      state.email !== "" &&
      state.message !== "" &&
      state.phone !== "" &&
      checkEmailNext(state.email) &&
      checkMobileNext(state.phone)
    ) {
      setNext(false);
      setIsButtonDisable(true);
      let body = {
        name: state.firstName + " " + state.lastName,
        email: state.email,
        mobile: state.phone,
        source: "axallant.com",
        message: state.message,
      };
      try {
        sendMessage(body)
          .then((response) => {
            setIsButtonDisable(false);
            if (response.status === 200) {
              if (response.data.status) {
                dispatch(
                  appendAlert(
                    "We appreciate you contacting us. One of our colleagues will get back in touch with you soon ! have a great day.",
                    "success"
                  )
                );
                setState({
                  firstName: "",
                  lastName: "",
                  email: "",
                  message: "",
                  phone: "",
                });
              } else {
                dispatch(appendAlert("Something went wrong!", "danger"));
              }
            } else {
              dispatch(
                appendAlert(
                  "Something went wrong!, Please try again later.",
                  "danger"
                )
              );
            }
          })
          .catch((error) => {
            dispatch(appendAlert("Something went wrong!", "danger"));
          });
      } catch (error) {}
    } else {
      if (!checkEmailNext(state.email)) {
        dispatch(appendAlert("Valid email address is required ", "danger"));
        setState({ ...state, email: "" });
      }
      if (!checkMobileNext(state.phone)) {
        dispatch(appendAlert("Valid phone number is required", "danger"));
        setState({ ...state, phone: "" });
      }
    }
  };
  return (
    <>
      <div
        className="container-fluid pr-0 pl-0 mr-0 ml-0"
        style={{ marginTop: "65px", marginBottom: "65px" }}
      >
        <div className="container-contact100 pt-0 mt-0">
          <h1 className="two mt-5 text-center text-dark">
            You’ve got questions. We’ve got answers.{" "}
            <hr className="rdLine "></hr>
          </h1>

          <div className="wrap-contact100  ">
            <div className="contact100-form validate-form">
              <span className="contact100-form-title">Send us a message</span>

              <label className="label-input100" htmlFor="first-name">
                Tell us your name *
              </label>
              <div
                className={
                  checkValidate(state.firstName)
                    ? "wrap-input100 rs1-wrap-input100 validate-input alert-validate"
                    : "wrap-input100 rs1-wrap-input100 validate-input"
                }
                data-validate="Type first name"
              >
                <input
                  id="first-name"
                  className="input100"
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  value={state.firstName}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                />
                <span className="focus-input100"></span>
              </div>
              <div
                className={
                  checkValidate(state.lastName)
                    ? "wrap-input100 rs1-wrap-input100 validate-input alert-validate"
                    : "wrap-input100 rs1-wrap-input100 validate-input"
                }
                data-validate="Type last name"
              >
                <input
                  className="input100"
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  value={state.lastName}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                />
                <span className="focus-input100"></span>
              </div>

              <label className="label-input100" htmlFor="email">
                Enter your email *
              </label>
              <div
                className={
                  checkEmail(state.email)
                    ? "wrap-input100  validate-input alert-validate"
                    : "wrap-input100  validate-input"
                }
                data-validate="Valid email is required: ex@abc.xyz"
              >
                <input
                  id="email"
                  className="input100"
                  type="email"
                  name="email"
                  placeholder="Eg. example@email.com"
                  value={state.email}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                />
                <span className="focus-input100"></span>
              </div>

              <label className="label-input100" htmlFor="phone">
                Enter phone number
              </label>
              <div
                data-validate="Type phone number"
                className={
                  checkMobile(state.phone)
                    ? "wrap-input100 validate-input alert-validate"
                    : "wrap-input100  validate-input"
                }
              >
                <input
                  id="phone"
                  className="input100"
                  type="text"
                  name="phone"
                  placeholder="Eg. (000) 000 - 0000"
                  value={state.phone}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                />
                <span className="focus-input100"></span>
              </div>

              <label className="label-input100" htmlFor="message">
                Message *
              </label>
              <div
                className={
                  checkValidate(state.message)
                    ? "wrap-input100 validate-input alert-validate"
                    : "wrap-input100  validate-input"
                }
                data-validate="Message is required"
              >
                <textarea
                  id="message"
                  className="input100"
                  name="message"
                  placeholder="Write us a message"
                  value={state.message}
                  onChange={onchange}
                  onKeyPress={onKeyUp}
                ></textarea>
                <span className="focus-input100"></span>
              </div>

              <div className="container-contact100-form-btn">
                <button
                  disabled={isButtonDisable}
                  className={
                    isButtonDisable ? "secDisable notAllow" : " buttonPri "
                  }
                  onClick={() => send()}
                >
                  Send Message
                  {isButtonDisable && <Loader3 transY="50px" transX="0px" />}
                </button>
              </div>
            </div>

            <div
              className="contact100-more "
              style={{ backgroundImage: "url(" + image + ")" }}
            >
              <div className=" size1 ">
                <div className=" size2 pb-4">
                  <p className="txt1 ">Address</p>
                  <span className="txt2 ">
                    11420 NE 20th St, Suite B, Bellevue WA 98004.
                  </span>
                </div>
              </div>

              <div className=" size1 pb-4">
                <div className=" size2">
                  <p className="txt1 ">Lets Talk</p>
                  <span className="txt3">(425) 440 - 9735</span>
                </div>
              </div>

              <div className=" size1 ">
                <div className=" size2">
                  <p className="txt1 ">General Support</p>
                  <span className="txt3">info@axallant.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <iframe
        className="pt-5 mt-3"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d172139.41616514194!2d-122.48214724825026!3d47.61294318356923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2slk!4v1644402332828!5m2!1sen!2slk"
        width="100%"
        height="500"
        style={{ border: "0", marginBottom: "-30px" }}
        allowFullScreen=""
        loading="lazy"
        title="map"
      ></iframe> */}
    </>
  );
};

export default withRouter(ContactUs);
