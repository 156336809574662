export const maintenanceRecordStatus = (status) => {
  if (status === null || status === undefined || status === "")
    return "-";
  else if (status === "JobStatusPending")
    return "Maintenance Request job status pending";
  else if (status === "JobStatusVisited")
    return "Maintenance Request job status visited";
  else if (status === "JobStatusJobstarted")
    return "Maintenance Request job status is started";
  else if (status === "JobStatusCanceled")
    return "Maintenance Request job canceled";
  else if (status === "JobStatusFinished") return "Provider finished job ";
  else if (status === "JobStatusRejected")
    return "Maintenance Request job rejected";
  else if (status === "ServiceRequestCreated") return "Service request created";
  else if (status === "ServiceRequestApproved")
    return "Service request approved";
  else if (status === "ServiceRequestRejected")
    return "Service request rejected";
  else if (status === "ServiceRequestCanceled")
    return "Service request canceled";
  else if (
    status === "ServiceRequestUpdatedByTenant" ||
    status === "ServiceRequestUpdatedByLandlord"
  )
    return "Service request updated";
  else if (status === "ServiceRequestModificationRequested")
    return "Service request modification requested";
  else if (status === "ProviderInvited")
    return "Quotation requested to provider";
  else if (status === "QuotationReceived")
    return "Service request quotation received";
  else if (status === "QuotationApproved")
    return "Service request quotation approved";
  else if (status === "QuotationRejected")
    return "Service request quotation rejected";
  else if (status === "QuotationModificationRequested")
    return "Service request quotation modification requested";
  else if (status === "QuotationUpdated")
    return "Service request quotation updated";
  else if (status === "QuotationCanceled")
    return "Service request quotation canceled";
  else if (status === "JobAssigned") return "Job assigned";
  else if (status === "JobAssignedUpdated")
    return "Service request assigned job updated";
  else if (status === "JobFinishedByProvider")
    return "Service request job was finished by provider";
  else if (status === "JobFinishedApprovedByTenant")
    return "Tenant Accept requested job finished ";
  else if (status === "JobCanceled") return "Requested job canceled";
  else if (status === "JobDelayRequest")
    return "Extension of working hours requested";
  else if (status === "JobDelayNotified")
    return "Extension of working hours request notified";
  else return "-";
};
