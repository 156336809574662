import React, { Component } from "react";
import { withRouter } from "react-router";
import "../../officeApplication.css";

class OfficeApplicationStep3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
    };
  }
  
  render() {
    return (
      <div className="row " id={"officeAppStep" + this.props.index}>
        <div className="col-3"></div>
        <div className="col-9  pt-1 d-flex justify-content-center">
          <div className="navBottom1">
            <p className="officeApplicationTitle  ">
              Business Ownership Information
            </p>

            {this.props.state.activeOwner === 1 ? (
              <>
                <p className="officeApplicationSubTitle1 pb-0 mb-0">
                  {" "}
                  First Owner
                </p>
                <div className="row pr-3 pl-3">
                  <div className="col-6 text-center ">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      First Owner's Name
                    </p>
                    <input
                      type="text"
                      placeholder="First Owner's Name"
                      className={
                        this.props.checkNull(this.props.state.firstOwnerName)
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      name="firstOwnerName"
                      onChange={this.props.onChange}
                      value={this.props.state.firstOwnerName}
                    />
                    {this.props.checkNull(this.props.state.firstOwnerName) && (
                      <span
                        className="textPri pull-left"
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        Please Enter First Owner Name
                      </span>
                    )}
                  </div>

                  <div className="col-6 text-center ">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Email
                    </p>
                    <input
                      type="email"
                      placeholder="Email"
                      className={
                        this.props.checkEmail(this.props.state.firstOwnerEmail)
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      name="firstOwnerEmail"
                      onChange={this.props.onChange}
                      value={this.props.state.firstOwnerEmail}
                    />
                    {this.props.checkEmail(
                      this.props.state.firstOwnerEmail
                    ) && (
                      <span
                        className="textPri pull-left"
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        {this.props.checkNull(this.props.state.firstOwnerEmail)
                          ? "Please Enter First Owner Email"
                          : "Please Enter First Owner Email correct format"}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row pr-3 pl-3">
                  <div className="col-12 text-center ">
                    <p className="officeApplicationSubTitle pull-left ">
                      Contact
                    </p>
                  </div>
                  <div className="col-6 text-center ">
                    <div className="input-group ">
                      <input
                        type="tel"
                        placeholder="Phone no."
                        className={
                          this.props.checkMobile(
                            this.props.state.firstOwnerContactNumber
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="firstOwnerContactNumber"
                        onChange={this.props.onChange}
                        value={this.props.state.firstOwnerContactNumber}
                      />
                      {this.props.checkMobile(
                        this.props.state.firstOwnerContactNumber
                      ) && (
                        <span
                          className="textPri pull-left"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          {this.props.checkNull(
                            this.props.state.firstOwnerContactNumber
                          )
                            ? "Please Enter First Owner Contact No"
                            : "Please Enter First Owner Contact No correct format"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group  ">
                      <input
                        type="tel"
                        placeholder="Alternative Phone no."
                        className={
                          this.props.checkMobile(
                            this.props.state.firstOwnerContactNumber2
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="firstOwnerContactNumber2"
                        onChange={this.props.onChange}
                        value={this.props.state.firstOwnerContactNumber2}
                      />
                      {this.props.checkMobile(
                        this.props.state.firstOwnerContactNumber2
                      ) && (
                        <span
                          className="textPri pull-left"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          {this.props.checkNull(
                            this.props.state.firstOwnerContactNumber2
                          )
                            ? "Please Enter First Owner Alternative Contact No"
                            : "Please Enter First Owner Alternative Contact No correct format"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row pr-3 pl-3">
                  <div className="col-12 text-center ">
                    <p className="officeApplicationSubTitle pull-left ">
                      Address
                    </p>
                  </div>
                  <div className="col-6 text-center ">
                    <div className="input-group mb-3 ">
                      <input
                        type="text"
                        placeholder="Address"
                        className={
                          this.props.checkNull(
                            this.props.state.firstOwnerAddress
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="firstOwnerAddress"
                        onChange={this.props.onChange}
                        value={this.props.state.firstOwnerAddress}
                      />
                      {this.props.checkNull(
                        this.props.state.firstOwnerAddress
                      ) && (
                        <span
                          className="textPri pull-left"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          Please Enter First Owner Address
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group mb-3 ">
                      <input
                        type="text"
                        placeholder="State"
                        className={
                          this.props.checkNull(this.props.state.firstOwnerState)
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="firstOwnerState"
                        onChange={this.props.onChange}
                        value={this.props.state.firstOwnerState}
                      />
                      {this.props.checkNull(
                        this.props.state.firstOwnerState
                      ) && (
                        <span
                          className="textPri pull-left"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          Please Enter First Owner State
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group ">
                      <input
                        type="text"
                        placeholder="City"
                        className={
                          this.props.checkNull(this.props.state.firstOwnerCity)
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="firstOwnerCity"
                        onChange={this.props.onChange}
                        value={this.props.state.firstOwnerCity}
                      />
                      {this.props.checkNull(
                        this.props.state.firstOwnerCity
                      ) && (
                        <span
                          className="textPri pull-left"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          Please Enter First Owner City
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group ">
                      <input
                        type="text"
                        placeholder="Zip"
                        className={
                          this.props.checkNull(this.props.state.firstOwnerZip)
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="firstOwnerZip"
                        onChange={this.props.onChange}
                        value={this.props.state.firstOwnerZip}
                      />
                      {this.props.checkNull(this.props.state.firstOwnerZip) && (
                        <span
                          className="textPri pull-left"
                          style={{ width: "100%", textAlign: "left" }}
                        >
                          Please Enter First Owner Zip
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row pr-3 pl-3">
                  <div className="col-6 text-center ">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Date of Birth
                    </p>
                    <input
                      type="date"
                      placeholder="Date of Birth"
                      className={
                        this.props.checkNull(this.props.state.firstOwnerDOB)
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      name="firstOwnerDOB"
                      onChange={this.props.onChangeDate}
                      value={this.props.state.firstOwnerDOB}
                    />
                    {this.props.checkNull(this.props.state.firstOwnerDOB) && (
                      <span
                        className="textPri pull-left"
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        Please Enter First Owner Date of Birth
                      </span>
                    )}
                  </div>

                  <div className="col-6 text-center ">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Driving License Number
                    </p>
                    <input
                      type="text"
                      placeholder="Driving License Number"
                      className={
                        this.props.checkNull(this.props.state.firstOwnerDLN)
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      name="firstOwnerDLN"
                      onChange={this.props.onChange}
                      value={this.props.state.firstOwnerDLN}
                    />
                    {this.props.checkNull(this.props.state.firstOwnerDLN) && (
                      <span
                        className="textPri pull-left"
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        Please Enter First Owner License No
                      </span>
                    )}
                  </div>
                  <div className="col-6 text-center ">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Social Security Number
                    </p>
                    <input
                      type="text"
                      placeholder="Social Security Number"
                      className={
                        this.props.checkNull(this.props.state.firstOwnerSSN)
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      name="firstOwnerSSN"
                      onChange={this.props.onChange}
                      value={this.props.state.firstOwnerSSN}
                    />
                    {this.props.checkNull(this.props.state.firstOwnerSSN) && (
                      <span
                        className="textPri pull-left"
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        Please Enter First Owner SSN
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div
                className="officeBar"
                onClick={() => this.props.setParentState("activeOwner", 1)}
              >
                {" "}
                <p className="officeApplicationSubTitle1 pb-0 mb-0">
                  {" "}
                  First Owner
                </p>
              </div>
            )}

            {this.props.state.ownerCount > 1 && (
              <>
                {this.props.state.activeOwner === 2 ? (
                  <>
                    {/* <i
                      className="fa fa-window-close pull-right pt-4 pointer"
                      aria-hidden="true"
                      onClick={() => this.props.removeOwner(2)}
                    ></i> */}
                    <p className="officeApplicationSubTitle1 pb-0 mb-0 ">
                      {" "}
                      Second Owner
                    </p>
                    <div className="row pr-3 pl-3">
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Second Owner's Name
                        </p>
                        <input
                          type="text"
                          placeholder="Second Owner's Name"
                          className={
                            this.props.checkNull(
                              this.props.state.secondOwnerName
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="secondOwnerName"
                          onChange={this.props.onChange}
                          value={this.props.state.secondOwnerName}
                        />
                        {this.props.checkNull(
                          this.props.state.secondOwnerName
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter second owner name
                          </span>
                        )}
                      </div>

                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Email
                        </p>
                        <input
                          type="email"
                          placeholder="Email"
                          className={
                            this.props.checkEmail(
                              this.props.state.secondOwnerEmail
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="secondOwnerEmail"
                          onChange={this.props.onChange}
                          value={this.props.state.secondOwnerEmail}
                        />
                        {this.props.checkEmail(
                          this.props.state.secondOwnerEmail
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {this.props.checkNull(
                              this.props.state.secondOwnerEmail
                            )
                              ? "Please Enter second owner email"
                              : "Please Enter second owner email correct format"}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row pr-3 pl-3">
                      <div className="col-12 text-center ">
                        <p className="officeApplicationSubTitle pull-left ">
                          Contact
                        </p>
                      </div>
                      <div className="col-6 text-center ">
                        <div className="input-group ">
                          <input
                            type="tel"
                            placeholder="Home Phone no."
                            className={
                              this.props.checkMobile(
                                this.props.state.secondOwnerContactNumber
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="secondOwnerContactNumber"
                            onChange={this.props.onChange}
                            value={this.props.state.secondOwnerContactNumber}
                          />
                          {this.props.checkMobile(
                            this.props.state.secondOwnerContactNumber
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              {this.props.checkNull(
                                this.props.state.secondOwnerContactNumber
                              )
                                ? "Please Enter second owner phone no"
                                : "Please Enter second owner phone no correct format"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group  ">
                          <input
                            type="tel"
                            placeholder="Alternative Phone no."
                            className={
                              this.props.checkMobile(
                                this.props.state.secondOwnerContactNumber2
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="secondOwnerContactNumber2"
                            onChange={this.props.onChange}
                            value={this.props.state.secondOwnerContactNumber2}
                          />
                          {this.props.checkMobile(
                            this.props.state.secondOwnerContactNumber2
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              {this.props.checkNull(
                                this.props.state.secondOwnerContactNumber2
                              )
                                ? "Please Enter second owner alternative phone no"
                                : "Please Enter second owner alternative phone no correct format"}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row pr-3 pl-3">
                      <div className="col-12 text-center ">
                        <p className="officeApplicationSubTitle pull-left ">
                          Address
                        </p>
                      </div>
                      <div className="col-6 text-center ">
                        <div className="input-group mb-3 ">
                          <input
                            type="text"
                            placeholder="Address"
                            className={
                              this.props.checkNull(
                                this.props.state.secondOwnerAddress
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="secondOwnerAddress"
                            onChange={this.props.onChange}
                            value={this.props.state.secondOwnerAddress}
                          />
                          {this.props.checkNull(
                            this.props.state.secondOwnerAddress
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter second owner address
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group mb-3 ">
                          <input
                            type="text"
                            placeholder="State"
                            className={
                              this.props.checkNull(
                                this.props.state.secondOwnerState
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="secondOwnerState"
                            onChange={this.props.onChange}
                            value={this.props.state.secondOwnerState}
                          />
                          {this.props.checkNull(
                            this.props.state.secondOwnerState
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter second owner state
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group ">
                          <input
                            type="text"
                            placeholder="Country"
                            className={
                              this.props.checkNull(
                                this.props.state.secondOwnerCity
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="secondOwnerCity"
                            onChange={this.props.onChange}
                            value={this.props.state.secondOwnerCity}
                          />
                          {this.props.checkNull(
                            this.props.state.secondOwnerCity
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter second owner city
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group ">
                          <input
                            type="text"
                            placeholder="Zip"
                            className={
                              this.props.checkNull(
                                this.props.state.secondOwnerZip
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="secondOwnerZip"
                            onChange={this.props.onChange}
                            value={this.props.state.secondOwnerZip}
                          />
                          {this.props.checkNull(
                            this.props.state.secondOwnerZip
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter second owner zip
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row pr-3 pl-3">
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Date of Birth
                        </p>
                        <input
                          type="date"
                          placeholder="Date of Birth"
                          className={
                            this.props.checkNull(
                              this.props.state.secondOwnerDOB
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="secondOwnerDOB"
                          onChange={this.props.onChangeDate}
                          value={this.props.state.secondOwnerDOB}
                        />
                        {this.props.checkNull(
                          this.props.state.secondOwnerDOB
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter second owner date of birth
                          </span>
                        )}
                      </div>

                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Driving License Number
                        </p>
                        <input
                          type="text"
                          placeholder="Driving License Number"
                          className={
                            this.props.checkNull(
                              this.props.state.secondOwnerDLN
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="secondOwnerDLN"
                          onChange={this.props.onChange}
                          value={this.props.state.secondOwnerDLN}
                        />
                        {this.props.checkNull(
                          this.props.state.secondOwnerDLN
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter second owner License no
                          </span>
                        )}
                      </div>
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Social Security Number
                        </p>
                        <input
                          type="text"
                          placeholder="Social Security Number"
                          className={
                            this.props.checkNull(
                              this.props.state.secondOwnerSSN
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="secondOwnerSSN"
                          onChange={this.props.onChange}
                          value={this.props.state.secondOwnerSSN}
                        />
                        {this.props.checkNull(
                          this.props.state.secondOwnerSSN
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter second owner ssn
                          </span>
                        )}
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  <div
                    className="officeBar"
                    onClick={() => this.props.setParentState("activeOwner", 2)}
                  >
                    {" "}
                    <p className="officeApplicationSubTitle1 pb-0 mb-0">
                      {" "}
                      Second Owner
                    </p>
                  </div>
                )}
              </>
            )}
            {this.props.state.ownerCount > 2 && (
              <>
                {this.props.state.activeOwner === 3 ? (
                  <>
                    {/* <i
                      className="fa fa-window-close pull-right pt-4 pointer"
                      aria-hidden="true"
                      onClick={() => this.props.removeOwner(3)}
                    ></i> */}
                    <p className="officeApplicationSubTitle1 pb-0 mb-0 ">
                      {" "}
                      Third Owner
                    </p>

                    <div className="row pr-3 pl-3">
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Third Owner's Name
                        </p>
                        <input
                          type="text"
                          placeholder="Third Owner's Name"
                          className={
                            this.props.checkNull(
                              this.props.state.thirdOwnerName
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="thirdOwnerName"
                          onChange={this.props.onChange}
                          value={this.props.state.thirdOwnerName}
                        />
                        {this.props.checkNull(
                          this.props.state.thirdOwnerName
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter third owner name
                          </span>
                        )}
                      </div>

                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Email
                        </p>
                        <input
                          type="email"
                          placeholder="Email"
                          className={
                            this.props.checkEmail(
                              this.props.state.thirdOwnerEmail
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="thirdOwnerEmail"
                          onChange={this.props.onChange}
                          value={this.props.state.thirdOwnerEmail}
                        />
                        {this.props.checkEmail(
                          this.props.state.thirdOwnerEmail
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {this.props.checkNull(
                              this.props.state.thirdOwnerEmail
                            )
                              ? "Please Enter third owner email"
                              : "Please Enter third owner email correct format"}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row pr-3 pl-3">
                      <div className="col-12 text-center ">
                        <p className="officeApplicationSubTitle pull-left ">
                          Contact
                        </p>
                      </div>
                      <div className="col-6 text-center ">
                        <div className="input-group ">
                          <input
                            type="tel"
                            placeholder="Home Phone no."
                            className={
                              this.props.checkMobile(
                                this.props.state.thirdOwnerContactNumber
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="thirdOwnerContactNumber"
                            onChange={this.props.onChange}
                            value={this.props.state.thirdOwnerContactNumber}
                          />
                          {this.props.checkMobile(
                            this.props.state.thirdOwnerContactNumber
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              {this.props.checkNull(
                                this.props.state.thirdOwnerContactNumber
                              )
                                ? "Please Enter third owner contact no"
                                : "Please Enter third owner contact no correct format"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group  ">
                          <input
                            type="tel"
                            placeholder="Alternative Phone no."
                            className={
                              this.props.checkMobile(
                                this.props.state.thirdOwnerContactNumber2
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="thirdOwnerContactNumber2"
                            onChange={this.props.onChange}
                            value={this.props.state.thirdOwnerContactNumber2}
                          />
                          {this.props.checkMobile(
                            this.props.state.thirdOwnerContactNumber2
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              {this.props.checkNull(
                                this.props.state.thirdOwnerContactNumber2
                              )
                                ? "Please Enter third owner alternative contact no"
                                : "Please Enter third owner alternative contact no correct format"}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row pr-3 pl-3">
                      <div className="col-12 text-center ">
                        <p className="officeApplicationSubTitle pull-left ">
                          Address
                        </p>
                      </div>
                      <div className="col-6 text-center ">
                        <div className="input-group mb-3 ">
                          <input
                            type="text"
                            placeholder="Address"
                            className={
                              this.props.checkNull(
                                this.props.state.thirdOwnerAddress
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="thirdOwnerAddress"
                            onChange={this.props.onChange}
                            value={this.props.state.thirdOwnerAddress}
                          />
                          {this.props.checkNull(
                            this.props.state.thirdOwnerAddress
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter third owner address
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group mb-3 ">
                          <input
                            type="text"
                            placeholder="State"
                            className={
                              this.props.checkNull(
                                this.props.state.thirdOwnerState
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="thirdOwnerState"
                            onChange={this.props.onChange}
                            value={this.props.state.thirdOwnerState}
                          />
                          {this.props.checkNull(
                            this.props.state.thirdOwnerState
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter third owner state
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group ">
                          <input
                            type="text"
                            placeholder="City"
                            className={
                              this.props.checkNull(
                                this.props.state.thirdOwnerCity
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="thirdOwnerCity"
                            onChange={this.props.onChange}
                            value={this.props.state.thirdOwnerCity}
                          />
                          {this.props.checkNull(
                            this.props.state.thirdOwnerCity
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter third owner city
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group ">
                          <input
                            type="text"
                            placeholder="Zip"
                            className={
                              this.props.checkNull(
                                this.props.state.thirdOwnerZip
                              )
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="thirdOwnerZip"
                            onChange={this.props.onChange}
                            value={this.props.state.thirdOwnerZip}
                          />
                          {this.props.checkNull(
                            this.props.state.thirdOwnerZip
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              Please Enter third owner zip
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row pr-3 pl-3">
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Date of Birth
                        </p>
                        <input
                          type="date"
                          placeholder="Date of Birth"
                          className={
                            this.props.checkNull(this.props.state.thirdOwnerDOB)
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="thirdOwnerDOB"
                          onChange={this.props.onChangeDate}
                          value={this.props.state.thirdOwnerDOB}
                        />
                        {this.props.checkNull(
                          this.props.state.thirdOwnerDOB
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter third owner Date of Birth
                          </span>
                        )}
                      </div>

                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Driving License Number
                        </p>
                        <input
                          type="text"
                          placeholder="Driving License Number"
                          className={
                            this.props.checkNull(this.props.state.thirdOwnerDLN)
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="thirdOwnerDLN"
                          onChange={this.props.onChange}
                          value={this.props.state.thirdOwnerDLN}
                        />
                        {this.props.checkNull(
                          this.props.state.thirdOwnerDLN
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter third owner License Number
                          </span>
                        )}
                      </div>
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Social Security Number
                        </p>
                        <input
                          type="text"
                          placeholder="Social Security Number"
                          className={
                            this.props.checkNull(this.props.state.thirdOwnerSSN)
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="thirdOwnerSSN"
                          onChange={this.props.onChange}
                          value={this.props.state.thirdOwnerSSN}
                        />
                        {this.props.checkNull(
                          this.props.state.thirdOwnerSSN
                        ) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter third owner SSN
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    className="officeBar"
                    onClick={() => this.props.setParentState("activeOwner", 3)}
                  >
                    {" "}
                    <p className="officeApplicationSubTitle1"> Third Owner</p>
                  </div>
                )}
              </>
            )}
            {this.props.state.ownerCount < 3 && (
              <button
                className="pull-left mt-4"
                onClick={() => this.props.addOwner()}
              >
                <i className="fa fa-plus-circle " aria-hidden="true"></i> Owner
              </button>
            )}
            {this.props.state.ownerCount > 1 && (
              <button
                className="pull-left mt-4 ml-2"
                onClick={() =>
                  this.props.removeOwner(
                    this.props.state.ownerCount === 3 ? 2 : 1
                  )
                }
              >
                <i className="fa fa-minus-circle " aria-hidden="true"></i> Last
                Owner
              </button>
            )}
            {this.props.step === 3 && (
              <button
                className="pull-right mt-4"
                onClick={() => this.props.next()}
              >
                Continue
              </button>
            )}
          </div>
        </div>
       
      </div>
    );
  }
}

export default withRouter(OfficeApplicationStep3);
