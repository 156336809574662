// Temporary Listing Details Reducer
const tempListingReducer = (state = [], action) => {
     switch (action.type) {
       case "GET_TEMP_LISTING_DATA":
         return state;
       case "APPEND_TEMP_LISTING_DATA":
         var arr=state.filter(
          (obj) =>
            obj.rentalEntity.id ===action.listing.rentalEntity.id
        );
        if(arr.length>0){
          return state;
        }else{
          return [ action.listing,...state];
        }
       case "APPEND_TEMP_LISTING_DATA_ARRAY":
         return  [...state, ...action.listArray];
       case "REMOVE_TEMP_LISTING_DATA_BY_ID":
         return state.filter((todo) => todo.id !== action.id )
       case "REMOVE_TEMP_LISTING_DATA":
         return null;
       default:
         return state;
     }
   };
export default tempListingReducer;
   