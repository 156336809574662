import React, { Component } from "react";
import "../maintenance.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import {
  appendAlert,
  setMaintenance,
  appendTempUsers,
  setContract,
  setProvider,
  appendTempProfileImage,
  getTempProfileImage,
} from "../../../../actions";
import {
  betweenDates,
  betweenMinutes,
  convertGMT,
  dateToDateOnly,
  sortText,
  sortText2,
  timeFormat,
  today,
} from "../../../../Defined/Function";

import Dropzone from "react-dropzone-uploader";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import {
  changeMaintenanceAppointmentStatus,
  createMaintenanceQuotation,
  getOpenProviderData,
  uploadQuotationImage,
  url,
} from "../../../../Services/ApiServices";
import Loader from "../../../Loader/Loader";
import TimeInput from "../../../UIServices/TimeInput";
import Loader3 from "../../../Loader/loader3";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import FullNotification from "../../../UIServices/FullNotification/FullNotification";
import "./maintenanceRequestDetail.css";
import MaintenanceHistory from "../MaintenanceHistory/MaintenanceHistory";
import BasicMaintenanceRequestDetails from "../BasicDetails/BasicMaintenanceRequestDetails";
import MaintenanceRequestDetailEmpty from "../EmptyView/MaintenanceRequestDetailEmpty";
import MaintenanceRequestDescription from "../MaintenanceRequestDescription/MaintenanceRequestDescription";
import MaintenanceRequestQuotations from "../MaintenanceRequestQuotations/MaintenanceRequestQuotation";
import MaintenanceRequestOrder from "../MaintenanceRequestOrder/MaintenanceRequestOrder";
import image from "../../../../Images/profile.png";
import imageLoader from "../../../../Images/imageLoader.gif";
class MaintenanceRequestProviderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      data: {
        serviceRequest: {
          tenantAvailablityTimes: [],
          serviceQuotations: [],
          eventRecords: [],
          jobAppointment: [],
        },
        propertyAddress: [],
        landlord: [],
      },
      providerId: atob(this.props.match.params.providerId),
      requestId: atob(this.props.match.params.requestId),
      token: atob(this.props.match.params.token),
      estimatedCost: "",
      description: "",
      coverImage: [],
      next: false,
      startDate: "",
      startTime: "16:00:00",
      startTime1: "04:00 PM",
      endDate: "",
      endTime: "16:00:00",
      endTime1: "04:00 PM",
      currentImage: "",
      deleteImage: false,
      quotations: [],
      quotationsStatus: false,
      sendQuotationLoader: false,
      sendQuotationTimeDiff: false,
      tenantImage: imageLoader,
      landlordImage: imageLoader,
    };
    this.loadData = this.loadData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.createQuotation = this.createQuotation.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.removeElement = this.removeElement.bind(this);
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    await this.loadData();
  }
  loadData() {
    try {
      getOpenProviderData(
        atob(this.props.match.params.token),
        atob(this.props.match.params.providerId),
        atob(this.props.match.params.requestId)
      ).then((response) => {
        if (response.status === 200) {
          this.setState({
            data: response.data,
            loader: true,
          });
          if (
            response.data.landlord === undefined ||
            response.data.landlord.length === 0
          ) {
            let usersLandlord = this.props.tempUsersArray.filter(
              (todo) => todo.username === response.data.landlord.username
            );
            if (usersLandlord.length === 0) {
              this.getLandlordDetails();
            } else {
              this.filterLandlordImage(usersLandlord[0]);
            }
          } else {
            this.filterLandlordImage(response.data.landlord);
          }
          if (response.data.serviceRequest.tenantAvailablityTimes.length > 0) {
            var date =
              response.data.serviceRequest.tenantAvailablityTimes[0].date;
            var time =
              response.data.serviceRequest.tenantAvailablityTimes[0].time;
            this.setState({
              startDate: dateToDateOnly(date),
              endDate: dateToDateOnly(date),
              startTime: time,
              startTime1: timeFormat(time),
              endTime: time,
              endTime1: timeFormat(time),
            });
          }

          if (response.data.serviceRequest.serviceQuotations !== null) {
            this.setState({
              quotations: response.data.serviceRequest.serviceQuotations.filter(
                (element) => {
                  return (
                    element.maintenanceProvider.id ===
                    parseInt(atob(this.props.match.params.providerId))
                  );
                }
              ),
              quotationsStatus:
                response.data.serviceRequest.serviceQuotations.filter(
                  (element) => {
                    return (
                      element.maintenanceProvider.id ===
                        parseInt(atob(this.props.match.params.providerId)) &&
                      element.landlordStatus === "approved"
                    );
                  }
                ).length > 0,
            });
          }
        } else {
          this.setState({ data: [] });
        }
      });
    } catch (error) {}
  }
  filterTenantImage(data) {
    let usersTenantImage = this.props.tempProfileImagesArray.filter(
      (todo) => todo.username === data.username
    );
    if (usersTenantImage.length === 0) {
      this.setImage(data, "tenantImage");
    } else {
      this.setState({ tenantImage: usersTenantImage[0].image });
    }
    this.setState({ tenantDetails: data });
  }
  filterLandlordImage(data) {
    let usersImage = this.props.tempProfileImagesArray.filter(
      (todo) => todo.username === data.username
    );
    if (usersImage.length === 0) {
      this.setImage(data, "landlordImage");
    } else {
      this.setState({ landlordImage: usersImage[0].image });
    }
    this.setState({ landlordDetails: data });
  }
  fetchImage(username, url, state) {
    const toDataURL = (url1) =>
      fetch(url1)
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          } else {
            throw new Error(response);
          }
        })
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        )
        .catch((res) => {
          throw new Error(res);
        });

    toDataURL(url)
      .then((dataUrl) => {
        this.setState({
          [state]: dataUrl,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: dataUrl,
        });
      })
      .catch((res) => {
        this.setState({
          [state]: image,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: image,
        });
      });
  }
  setImage(data, state) {
    if (data.provider === "" || data.provider === null) {
      this.setState({ [state]: image });
      this.props.appendTempProfileImage({
        username: data.username,
        image: image,
      });
    } else if (data.provider === "local") {
      if (data.coverImage === null) {
        this.setState({ [state]: image });
      } else {
        this.fetchImage(
          data.username,
          url + `api/user/${data.username}/image/download`,
          state
        );
      }
    } else {
      this.fetchImage(data.username, data.coverImage, state);
    }
  }
  verification(value) {
    if (value === undefined) {
      return "";
    } else if (value === "" || value === null) {
      return "-";
    } else {
      return value;
    }
  }
  check(value) {
    if (value === undefined) {
      return false;
    }
    return true;
  }
  clickCreateQuotation() {
    this.setState({
      sendQuotationLoader: false,
      next: false,
    });
  }
  completeJob() {
    try {
      changeMaintenanceAppointmentStatus(
        this.state.token,
        "finished",
        parseInt(this.state.data.serviceRequest.jobAppointment.id)
      ).then((response) => {
        if (response.status) {
          window.$("#CompleteJob").modal("hide");
          this.props.appendAlert("Job status changed", "success");
          this.loadData();
        } else {
          this.props.appendAlert("Please try again later", "danger");
          window.$("#CompleteJob").modal("hide");
        }
      });
    } catch (error) {
      this.props.appendAlert("Please try again later", "danger");
    }
  }
  createQuotation() {
    let sd = convertGMT(this.state.startDate + "T" + this.state.startTime);
    let ed = convertGMT(this.state.endDate + "T" + this.state.endTime);
    let diff = parseInt(betweenMinutes(sd, ed));
    if (
      this.state.startDate !== "" &&
      this.state.endDate !== "" &&
      this.state.startTime !== "" &&
      this.state.endTime !== "" &&
      this.state.estimatedCost !== "" &&
      diff >= 0
    ) {
      let data = {
        maintenanceProvider: {
          id: parseInt(this.state.providerId),
        },
        serviceRequest: {
          id: parseInt(this.state.requestId),
        },
        estimatedCost: parseInt(this.state.estimatedCost),
        startOn: sd,
        endOn: ed,
        jobHours: diff,
        description: this.state.description,
        landlordStatus: "pending",
        landlordDescription: "",
      };
      this.setState({ sendQuotationLoader: true, sendQuotationTimeDiff: diff });
      try {
        createMaintenanceQuotation(data, this.state.token).then((response) => {
          if (response.status) {
            window.$("#MaintenanceRequestQuotation").modal("hide");
            this.props.appendAlert("Quotation send successfully", "success");

            this.setState({ sendQuotationLoader: false, next: false });
            this.uploadImage(response.data.id);
          } else {
            this.props.appendAlert("Please try again later", "danger");
            window.$("#MaintenanceRequestQuotation").modal("hide");
            this.setState({ sendQuotationLoader: false, next: false });
          }
        });
      } catch (error) {
        this.props.appendAlert("Please try again later", "danger");
        this.setState({ sendQuotationLoader: false, next: false });
      }
    } else {
      if (diff < 0) {
        this.setState({
          startTime: "",
          startTime1: "",
          endTime: "",
          endTime1: "",
          sendQuotationTimeDiff: diff,
        });
      }
      this.setState({ next: true });
    }
  }
  async handleChange(event) {
    let { name, value } = event.target;
    if (name === "estimatedCost") {
      let num = value.replace(/[^\d^.]/g, "");
      value = num;
      await this.setState({ [name]: value });
    } else {
      await this.setState({
        [name]: value,
      });
    }
  }
  handleChangeStatus = ({ file }, status, allFiles) => {
    if (status === "preparing") {
      this.handleUpload(allFiles);
    }
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
    }
  };
  async handleUpload(files) {
    files.forEach((file) => {
      if (this.state.coverImage.indexOf(file) < 0) {
        this.setState((state) => ({
          coverImage: state.coverImage.concat([file]),
        }));
      }
    });
  }
  async uploadImage(id) {
    if (this.state.coverImage.length !== 0) {
      this.state.coverImage.forEach((element, index) => {
        const formData = new FormData();
        formData.append("fileName", "maintenanceRequest");
        formData.append("category", "test");
        formData.append(
          "uploadBy",
          this.state.data.serviceRequest.providerInvites[0].maintenanceProvider
            .email
        );
        formData.append("fileType", element.file.type);
        formData.append("tenantAccess", true);
        formData.append("landlordAccess", true);
        formData.append("deleted", true);
        formData.append("version", 0);
        formData.append("document", element.file);
        uploadQuotationImage(formData, id)
          .then((response) => {
            if (response.status === 200) {
              // this.props.appendAlert("Image uploaded successfully", "success");

              if (this.state.coverImage.length - 1 === index) {
                this.loadData();
              }
            } else {
              this.loadData();
              // this.props.appendAlert(
              //   "Something went wrong!, Please try again later.",
              //   "danger"
              // );
            }
          })
          .catch((err) => {
            this.props.appendAlert("Image upload failed", "danger");
            console.log(err);
          });
      });
      window.$("#MaintenanceRequest").modal("hide");
      this.setState({ newRequestModal: false, newRequestModalLoader: false });
    } else {
      window.$("#MaintenanceRequest").modal("hide");
      this.setState({ newRequestModal: false, newRequestModalLoader: false });
    }
  }
  async onChangeTime(id, name, value, value1) {
    let sd = convertGMT(this.state.startDate + "T" + this.state.startTime);
    let ed = convertGMT(this.state.endDate + "T" + this.state.endTime);
    let diff = parseInt(betweenMinutes(sd, ed));
    this.setState({
      [id]: value,
      [id + "1"]: value1,
      sendQuotationTimeDiff: diff,
    });
  }
  checkValidate(value) {
    if (value === "" && this.state.next) {
      return true;
    }
    return false;
  }
  onChangeDate(name, value) {
    this.setState({ [name]: value });
  }
  onCurrentImageChange(index) {
    this.setState({ currentImage: index });
  }
  removeElement(name) {
    var x = this.state.coverImage.filter((obj) => {
      return obj.file.name !== name;
    });
    this.setState({ coverImage: x });
  }
  async deleteImage() {
    if (this.state.coverImage.length >= this.state.currentImage) {
      if (this.state.coverImage[this.state.currentImage].file) {
        var images = this.state.coverImage.slice();
        images.splice(this.state.currentImage, 1);
        this.setState({ coverImage: images });
        document.querySelector(".close_1x3s325").click();
      }
    } else {
      this.props.appendAlert("Error in deleting.Try again later!", "danger");
    }
  }
  addQuotation() {
    window.$("#MaintenanceRequestQuotation").modal("show");
  }

  render() {
    let imgList = [];
    this.state.coverImage.forEach((img) => {
      imgList.push({
        src: URL.createObjectURL(img.file),
        thumbnail: URL.createObjectURL(img.file),
        thumbnailWidth: img.meta.width / 2,
        thumbnailHeight: img.meta.height / 2,
      });
    });

    return this.state.loader ? (
      <div className="container-fluid ">
        <div className="maintenanceRequestDetailWindow">
          {this.state.data.serviceRequest.serviceQuotations !== null &&
            this.state.data.serviceRequest.serviceQuotations.length === 0 && (
              <FullNotification>
                Click
                <span className="textNotificationRed">add question</span> and
                submit a quotation for this task
              </FullNotification>
            )}

          {!this.state.data.jobAssigned &&
            this.state.data.status === "incomplete" && (
              <FullNotification>
                Ones you finish the task, Please make a request to close this
                task by
                <span className="textNotificationRed"> mark as complete</span>
              </FullNotification>
            )}
          <div className="row mt-4">
            <div className="col col-sm col-md col-lg col-xl">
              <BasicMaintenanceRequestDetails
                data={this.state.data.serviceRequest}
                unitData={
                  this.state.unitData && this.state.unitData.rentalEntity
                }
                unitAddress={this.state.data && this.state.data.propertyAddress}
                tenantDetails={"NotAllow"}
                landlordDetails={this.state.data.landlord}
                mode={"open"}
                landlordImage={this.state.landlordImage}
                tenantImage={this.state.tenantImage}
              />
              <div className="tabViewHistory">
                <MaintenanceHistory
                  eventRecords={this.state.data.serviceRequest.eventRecords}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-5">
              <MaintenanceRequestDescription
                data={this.state.data.serviceRequest}
              />
              {this.state.data.serviceRequest.jobAssigned && (
                <MaintenanceRequestOrder
                  role="Landlord"
                  data={this.state.data.serviceRequest}
                  mode={"open"}
                />
              )}
              {this.state.data.serviceRequest.serviceQuotations ? (
                this.state.data.serviceRequest.serviceQuotations.length > 0 ? (
                  <MaintenanceRequestQuotations
                    data={this.state.data.serviceRequest}
                    openEstimate={this.openEstimate}
                    mode={"open"}
                    addQuotation={() => this.addQuotation()}
                  />
                ) : (
                  <MaintenanceRequestDetailEmpty
                    head="Quotations"
                    subHead="List is Empty"
                    content="Looks like you haven't submit any quotations for this task. Please submit a quotation from here."
                    actionName="Add Quotation"
                    action={() => this.addQuotation()}
                  />
                )
              ) : (
                <MaintenanceRequestDetailEmpty
                  head="Quotations"
                  subHead="List is Empty"
                  content="Looks like you haven't submit any quotations for this task. Please submit a quotation from here."
                  actionName="Add Quotation"
                  action={() => this.addQuotation()}
                />
              )}
            </div>
            <div className="col col-sm col-md col-lg col-xl webViewHistory">
              <MaintenanceHistory
                eventRecords={this.state.data.serviceRequest.eventRecords}
              />
            </div>
          </div>
        </div>

        <div
          className="modal fade bd-example-modal-xl"
          id={"MaintenanceRequestQuotation"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-xl modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Send Quotation
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-12">
                        <ApplicationInput
                          name="Estimated Cost"
                          type="text"
                          designType="2"
                          target="estimatedCost"
                          onChange={this.handleChange}
                          important="true"
                          min="1"
                          errorMessage="your estimated cost"
                          validate={this.checkValidate(
                            this.state.estimatedCost
                          )}
                          iconL={"fa-usd"}
                          value={this.state.estimatedCost}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <ApplicationInput
                          name="Estimate Start"
                          type="date"
                          target="startDate"
                          min={today()}
                          max={
                            betweenDates(this.state.endDate, today()) < 0
                              ? this.state.endDate
                              : null
                          }
                          onChange={this.onChangeDate}
                          designType="2"
                          important="true"
                          errorMessage={
                            this.state.sendQuotationTimeDiff < 0
                              ? " start, end date-time logically"
                              : "estimation start date"
                          }
                          validate={this.checkValidate(
                            this.state.startDate === null ||
                              this.state.sendQuotationTimeDiff < 0
                              ? ""
                              : this.state.startDate
                          )}
                          value={
                            betweenDates(this.state.startDate, today()) < 0
                              ? this.state.startDate
                              : today()
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TimeInput
                          id="startTime"
                          name="Time"
                          designType="2"
                          target="startTime"
                          onChange={this.onChangeTime}
                          value={this.state.startTime1}
                          place="--:-- --"
                        />
                      </div>
                      <div className="col-md-6">
                        <ApplicationInput
                          name="Estimate End"
                          type="date"
                          target="endDate"
                          min={
                            this.state.startDate === ""
                              ? today()
                              : betweenDates(this.state.startDate, today()) < 0
                              ? this.state.startDate
                              : today()
                          }
                          onChange={this.onChangeDate}
                          designType="2"
                          value={
                            betweenDates(this.state.endDate, today()) < 0
                              ? this.state.endDate
                              : today()
                          }
                          important="true"
                          errorMessage={
                            this.state.sendQuotationTimeDiff
                              ? " start, end date-time logically"
                              : "estimation end date"
                          }
                          validate={this.checkValidate(
                            this.state.endDate === null ||
                              this.state.sendQuotationTimeDiff < 0
                              ? ""
                              : this.state.endDate
                          )}
                        />
                      </div>
                      <div className="col-md-6">
                        <TimeInput
                          designType="2"
                          id="endTime"
                          name="Time"
                          target="endTime"
                          onChange={this.onChangeTime}
                          value={this.state.endTime1}
                          place="--:-- --"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <ApplicationInput
                      name="More about service (If any)"
                      target="description"
                      designType="2"
                      type="textArea"
                      row="8"
                      onChange={this.handleChange}
                      value={this.state.description}
                      dark={this.props.dark}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-xl-4 pt-0 mt-0 mb-4">
                    <span className="searchListHeadApp2 mb-2">
                      Documents(s)
                    </span>
                    <Dropzone
                      inputContent={
                        <div
                        // className={this.state.next ? "alert" : ""}
                        >
                          <div
                            className="dragDrop text-center pointer "
                            style={{
                              fontSize: "60px",
                              minHeight: "200px",
                              paddingTop: "40px",
                              marginBottom: "40px",
                            }}
                          >
                            <div className="row ">
                              <div className="col">
                                <i
                                  className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <p className="licenseHead">
                                  Drag Or Click to Upload
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      inputWithFilesContent="Upload more"
                      onChangeStatus={this.handleChangeStatus}
                      // accept=".jpeg,.png,.jpg"
                      submitButtonContent="Upload"
                    />
                  </div>
                  {this.state.coverImage.length > 0 && (
                    <div
                      style={{
                        display: "block",
                        width: "100%",
                      }}
                      className="col-md pl-5 pt-4 pb-4"
                    >
                      <div className="row mb-0 pb-0">
                        {this.state.coverImage.map((data, index) => (
                          <div
                            className=" mt-4 mr-4 ml-4 text-center"
                            style={{ width: "150px" }}
                          >
                            <div className="fileType">
                              {data.file.type === "" ||
                              data.file.type === null ||
                              data.file.type === undefined
                                ? "file"
                                : sortText2(
                                    data.file.type.split("/")[1],
                                    0,
                                    4
                                  )}{" "}
                              <i
                                className="fa fa-trash pointer text-danger absolute "
                                style={{
                                  left: "10px",
                                  top: "10px",
                                  fontSize: "16px",
                                }}
                                onClick={() =>
                                  this.removeElement(data.file.name)
                                }
                                aria-hidden="true"
                              ></i>
                            </div>

                            <div className="row">
                              <div className="col">
                                <span className="span2 textSec1">
                                  {sortText(data.file.name, 0, 20)}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="modal-footer pt-3 border-0">
                <button className="pri" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  className="buttonPri"
                  onClick={() => this.createQuotation()}
                >
                  Send Quotation
                  {this.state.sendQuotationLoader && (
                    <Loader3 transY="50px" transX="0px" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.deleteImage && (
          <SmallModalContainer
            name="deleteImage"
            dark={this.props.dark}
            title="Confirm Delete?"
            confirmCommand="Delete"
            confirm={this.deleteImage}
            reject={() => this.setState({ deleteImage: false })}
            rejectCommand="Cancel"
            rejectCheck="true"
          >
            <div className="p-2 pull-left text-left">
              <span className="span7" style={{ fontSize: "16px" }}>
                Are you sure you want to delete this image?
              </span>
            </div>
          </SmallModalContainer>
        )}
        <div
          className="modal fade bd-example-modal-lg"
          id={"CompleteJob"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1 className={this.props.dark ? "axTextDark" : "axText"}>
                  Please confirm
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>
              <div
                className="modal-body bodyAvailable2 pt-0  pb-0 border-0"
                style={{
                  overflow: "scroll",
                  maxHeight: "500px",
                }}
              >
                <span className=" pl-3 maintenanceRequestText4">
                  Are you sure you want to close this job?
                </span>
              </div>

              <div className="modal-footer pt-3 border-0">
                <button className="pri" data-dismiss="modal">
                  No
                </button>
                <button
                  className="buttonPri"
                  data-dismiss="modal"
                  onClick={() => this.completeJob()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    role: state.RoleReducer,
    roleStatus: state.role,
    user: state.userReducer,
    userDetailArray: state.user,
    contract: state.contractReducer,
    contractArray: state.contract,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    provider: state.providerReducer,
    providerArray: state.provider,
    tempProfileImages: state.tempProfileImagesReducer,
    tempProfileImagesArray: state.tempProfileImages,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setMaintenance,
    appendTempUsers,
    setContract,
    setProvider,
    appendTempProfileImage,
    getTempProfileImage,
  })(MaintenanceRequestProviderDetail)
);
