import React, { Component } from "react";
import oneTime from "../../../Images/onetime.jpg";
import auto from "../../../Images/auto.jpg";
import ApplicationInput from "../../UIServices/ApplicationInput";
import {
  monthNumberArray,
  monthNumberArrayString,
} from "../../../Services/JsonData";
class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionDate: 1,
      next: false,
    };
    this.onchange = this.onchange.bind(this);
    this.checkValidate = this.checkValidate.bind(this);
  }
  checkValidate(value) {
    if (value === "" && this.state.next) {
      return true;
    }
    return false;
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleListItemClick = (value) => {
    if (value === "Subscription") {
      this.setState({ next: true });
      if (this.state.subscriptionDate !== "") {
        this.setState({ next: false });
        this.props.onAccept(true, this.state.subscriptionDate);
        window.$("#exampleModal2").modal("hide");
      } else {
        window.$("#exampleModal2").modal("show");
      }
    } else if (value === "Manual") {
      this.props.onAccept(false, null);
    }
  };
  render() {
    return (
      <>
        <div
          className="contractContainer pt-3 pb-3"
          style={{ minHeight: window.screen.height }}
        >
          <div
            className=" contractCard2  mt-4"
            data-dismiss="modal"
            aria-label="Close"
          >
            <div className="row pt-3">
              <div className="payImageAlign  text-center ">
                <img src={oneTime} className="payImage m-2" alt="appImage" />
              </div>
              <div className="col-md payImageAlign2  pt-1 ">
                <div className="row" style={{ marginTop: "-10px" }}>
                  <div className="col">
                    <p className="one">One Time Pay</p>
                  </div>
                </div>
                <div className="row" style={{ marginTop: "-30px" }}>
                  <div className="col">
                    <p className="contractText">
                      Rent must be paid manually every month before the due
                      date. Note that penalty will be there for late rent
                      payments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2">
                <button
                  className=" mr-5 mb-3 buttonPri pull-right"
                  onClick={() => this.handleListItemClick("Manual")}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
          <div className=" contractCard2  ">
            <div className="row pt-3">
              <div className=" payImageAlign  text-center ">
                <img src={auto} className="payImage m-2" alt="appImage" />
              </div>
              <div className="col-md payImageAlign2  pt-1 ">
                <div className="row" style={{ marginTop: "-10px" }}>
                  <div className="col">
                    <p className="one">Auto Pay</p>
                  </div>
                </div>
                <div className="row " style={{ marginTop: "-30px" }}>
                  <div className="col">
                    <p className="contractText">
                      Rent will be automatically paid on the specific date of
                      your lease agreement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3 pl-5">
                <ApplicationInput
                  name="Subscription Date"
                  target="subscriptionDate"
                  type="optional"
                  options={monthNumberArrayString}
                  optionValue={monthNumberArray}
                  infoStyle="infoContent2"
                  onChange={this.onchange}
                  important="true"
                  errorMessage="the subscription date"
                  validate={this.checkValidate(this.state.subscriptionDate)}
                  value={this.state.subscriptionDate}
                />
              </div>

              <div className="col-12">
                <button
                  className=" mt-3 mb-3 mr-3 buttonPri pull-right"
                  onClick={() => this.handleListItemClick("Subscription")}
                >
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PaymentMethod;
