import React, { useEffect } from "react";
import "./loader.css";

const Loader = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);
    return ( 
        <>
        <div style={{ height:window.screen.height,maxHeight:props.maxHeight,paddingTop:window.screen.height/2}}>
         <div id="loader">
            <div id="shadow"></div>
            <div id="box" className="bgPriRad2"></div>
        </div>
        </div>
        </>
        );
      
    }
export default Loader;
