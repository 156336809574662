// @author Karunaaharan Bavaram

import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Page404 from "../../AnonymousPage/404";
import Loader from "../../Loader/Loader";
import Login from "../../Login/Login";


const Permission = (props) => {
  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.role);
  const auth = useSelector((state) => state.isAuth);
  const dark = useSelector((state) => state.isDark);

  //props.routes='secure' => routes are secure
  //props.tenant='true' => tenant can access this route
  //props.landlord='true' => landlord can access this route
  //page is not authorize then hit default route or page404
  const secureRoutes = (component) => {
    return props.routes === "secure" ? (
      auth === null || role === null ? (
        <Loader />
      ) : auth ? (
        props.guest === "404" && role === "Guest" ? (
          <Page404 dark={dark} />
        ) : (props.tenant === undefined || props.tenant) &&
          (props.landlord === undefined || props.landlord) ? (
          props.withUserDetails?user?component: <Loader />:component
        ) : (props.landlord !== undefined && !props.landlord) &&
          role === "Landlord" ? (
          props.withoutLandlordRoute ? (
            <Redirect push to={props.withoutLandlordRoute} />
          ) : (
            <Page404 dark={dark} />
          )
        ) : (props.tenant !== undefined && !props.tenant) &&
          role === "Tenant" ? (
          props.withoutTenantRoute ? (
            <Redirect push to={props.withoutTenantRoute} />
          ) : (
            <Page404 dark={dark} />
          )
        ) : (
          props.withUserDetails?user?component: <Loader />:component
        )
      ) : (
        <Login hitPoint={props.defaultRoute} UserData={user}  sign={props.sign} changeSign={props.changeSign}/>
      )
    ) : props.guest === "404" && role === "Guest" ? (
      <Page404 dark={dark} />
    ) : (
      component
    );
  };

  return props.children ? secureRoutes(<>{props.children}</>) : <></>;
};

export default Permission;
