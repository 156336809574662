import React, { Component } from "react";
import NoData from "../../../Messages/NoData";
import {
  checkPaymentAccountDetail,
  createPaymentAccount,
  deleteProperty,
  getProperty,
  reCreatePaymentAccount,
} from "../../../Services/ApiServices";
import Loader from "../../Loader/Loader";
import { withRouter } from "react-router-dom";
import PropertyCard from "./PropertyCard";
import Loader3 from "../../Loader/loader3";
import { connect } from "react-redux";
import {
  appendAlert,
  setAccountPaymentStatus,
  setProperty,
  removePropertyById,
} from "../../../actions";
import SmallModalContainer from "../Documents/SmallModalContainer";

export class ViewProp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoadingStipe: false,
      noData: false,
      isButtonDisable: true,
      handleComponentDelete: true,
      stripe_account_status: "",
      id: "",
      mainLoader: true,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    if (this.props.accountPaymentStatus !== null) {
      this.setState({ mainLoader: false });
    }
    try {
      await checkPaymentAccountDetail()
        .then((response) => {
          if (response.status === 200) {
            this.props.setAccountPaymentStatus(
              response.data.capabilities.card_payments
            );
          }
          this.setState({ mainLoader: false });
        })
        .catch((err) => {
          this.setState({ mainLoader: false });
        });
      await getProperty().then(async (response) => {
        if (response.status === 200) {
          this.setState({ isLoading: false });
          if (response.data.length !== 0) {
            await this.props.setProperty(null);
            this.props.setProperty(response.data);
          } else {
            this.props.setProperty([]);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async deleteProperty(property) {
    try {
      await deleteProperty(property.id).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.setState({ handleComponentDelete: false });
            this.props.appendAlert("Property deleted successfully", "success");
            this.props.removePropertyById(property.id);

            setTimeout(() => {
              this.setState({ handleComponentDelete: true });
            }, 100);
          } else {
            this.props.appendAlert(
              "Error in deleting.Try again later!",
              "danger"
            );
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  openLink(path) {
    window.location.href = path;
  }
  manageAxallant() {
    if (this.props.accountPaymentStatus === "active") {
      this.props.history.push({
        pathname: "/properties/add-property-by-axallant",
      });
    } else {
      this.props.appendAlert("Please connect with stripe", "info");
    }
  }
  manageLandlord() {
    if (this.props.accountPaymentStatus === "active") {
      this.props.history.push({
        pathname: "/properties/add-property",
      });
    } else {
      this.props.appendAlert("Please connect with stripe", "info");
    }
  }
  openAxallantModal() {
    if (
      this.props.UserData.username === "" ||
      this.props.UserData.username === null ||
      this.props.UserData.firstName === "" ||
      this.props.UserData.firstName === null ||
      this.props.UserData.lastName === "" ||
      this.props.UserData.lastName === null ||
      this.props.UserData.address.state === "" ||
      this.props.UserData.address.state === null ||
      this.props.UserData.address.country === "" ||
      this.props.UserData.address.country === null ||
      this.props.UserData.address.zip === "" ||
      this.props.UserData.address.zip === null ||
      this.props.UserData.address.city === "" ||
      this.props.UserData.address.city === null ||
      this.props.UserData.address.address === "" ||
      this.props.UserData.address.address === "" ||
      this.props.UserData.mobileNumber === "" ||
      this.props.UserData.mobileNumber === null
    ) {
      this.props.history.push({ pathname: "/profile" });
    } else {
      if (this.props.accountPaymentStatus === "active") {
        window.$("#mangeAxallant").modal("show");
      } else {
        this.props.appendAlert("Please connect with stripe", "info");
      }
    }
  }
  render() {
    return this.props.propertyArray === null ? (
      <div>
        <Loader />
      </div>
    ) : (
      <div
        style={{
          paddingTop: "90px",
          minHeight: window.screen.height,
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col  mt-2 ">
              <button
                className="buttonPri pull-right"
                onClick={() => this.openAxallantModal()}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>&nbsp; Property
              </button>
            </div>
          </div>
          {this.state.mainLoader ? (
            <Loader />
          ) : this.props.accountPaymentStatus === null &&
            this.props.UserData.stripeAccountId !== null &&
            this.props.UserData.stripeAccountId !== "" ? (
            <Loader />
          ) : this.props.accountPaymentStatus === "inactive" ||
            this.props.accountPaymentStatus === "pending" ||
            (this.props.accountPaymentStatus === null &&
              (this.props.UserData.stripeAccountId === null ||
                this.props.UserData.stripeAccountId === "")) ? (
            <div
              className={
                this.props.isDark
                  ? " page404ContractDark "
                  : " page404Contract "
              }
            >
              <div className="row">
                <div className="col-12  text-center  pb-5">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10%"
                    viewBox="0 0 256.000000 256.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M180 1490 l0 -1070 220 0 220 0 2 -210 3 -209 388 209 389 210 489 0
                            489 0 0 1070 0 1070 -1100 0 -1100 0 0 -1070z m2050 0 l0 -920 -433 0 -434 0
                            -288 -155 c-158 -85 -291 -155 -296 -155 -5 0 -9 70 -9 155 l0 155 -220 0
                            -220 0 0 920 0 920 950 0 950 0 0 -920z"
                      />
                      <path d="M1730 1620 l0 -290 75 0 75 0 0 290 0 290 -75 0 -75 0 0 -290z" />
                      <path
                        d="M670 1495 l0 -365 75 0 75 0 0 150 0 151 123 -3 122 -3 3 -147 3
                            -148 74 0 75 0 -2 363 -3 362 -72 3 -73 3 0 -141 0 -140 -125 0 -125 0 0 140
                            0 140 -75 0 -75 0 0 -365z"
                      />
                      <path d="M1390 1495 l0 -365 75 0 75 0 -2 363 -3 362 -72 3 -73 3 0 -366z" />
                      <path
                        d="M1745 1205 c-66 -65 12 -173 94 -130 51 26 58 93 15 133 -33 29 -78
                            29 -109 -3z"
                      />
                    </g>
                  </svg>
                </div>
                <div className="col  text-center " style={{ paddingRight: "" }}>
                  {/* <h1 className="head2 mb-3">{"Add stripe account"}</h1> */}

                  <h4 className="head3 ">
                    Axallant partners with Stripe for secure payments and
                    financial services. Being our user, you are required to
                    create an account with Stripe so that you can start taking
                    payments through us. By signing up for an account with
                    Stripe, you are agreeing to the{" "}
                    <a
                      className="head3  p-0"
                      rel="noopener noreferrer"
                      href="https://stripe.com/privacy"
                      target="_blank"
                    >
                      terms of service and privacy policy
                    </a>{" "}
                    of Stripe.
                  </h4>
                  <h4 className="head3 pt-3 mb-3">
                    You can learn more about the Stripe{" "}
                    <a
                      className="head3  p-0"
                      rel="noopener noreferrer"
                      href="https://stripe.com/about"
                      target="_blank"
                    >
                      here{" "}
                    </a>
                    .
                  </h4>
                  {this.props.UserData.stripeAccountId !== null && (
                    <h4 className="head4 pt-0 mb-4 textPri">
                      If you already created a stripe account, Please wait
                      before you try again.
                    </h4>
                  )}
                  {
                    <button
                      className="sample"
                      disabled={this.state.isLoadingStipe}
                      onClick={() => {
                        this.setState({ isLoadingStipe: true });

                        if (
                          this.props.UserData.username === "" ||
                          this.props.UserData.username === null ||
                          this.props.UserData.firstName === "" ||
                          this.props.UserData.firstName === null ||
                          this.props.UserData.lastName === "" ||
                          this.props.UserData.lastName === null ||
                          this.props.UserData.address.state === "" ||
                          this.props.UserData.address.state === null ||
                          this.props.UserData.address.country === "" ||
                          this.props.UserData.address.country === null ||
                          this.props.UserData.address.zip === "" ||
                          this.props.UserData.address.zip === null ||
                          this.props.UserData.address.city === "" ||
                          this.props.UserData.address.city === null ||
                          this.props.UserData.address.address === "" ||
                          this.props.UserData.address.address === "" ||
                          this.props.UserData.mobileNumber === "" ||
                          this.props.UserData.mobileNumber === null
                        ) {
                          this.props.history.push({ pathname: "/profile" });
                        }else if (
                          this.props.UserData.stripeAccountId === null ||
                          this.props.UserData.stripeAccountId === ""
                        ) {
                          createPaymentAccount("EXPRESS").then(
                            async (response) => {
                              if (response.status === 200) {
                                await this.openLink(response.data.data);
                                this.setState({ isLoadingStipe: false });
                              } else {
                                this.props.appendAlert(
                                  "Something went wrong!, Please try again later.",
                                  "danger"
                                );
                                this.setState({ isLoadingStipe: false });
                              }
                            }
                          );
                        }  else {
                          checkPaymentAccountDetail().then((response) => {
                            if (response.status === 200) {
                              if (
                                response.data.capabilities.card_payments ===
                                "active"
                              ) {
                                this.props.setAccountPaymentStatus(
                                  response.data.capabilities.card_payments
                                );
                              } else if (
                                response.data.capabilities.card_payments ===
                                "pending"
                              ) {
                                this.setState({ isLoadingStipe: false });
                                this.props.setAccountPaymentStatus(
                                  response.data.capabilities.card_payments
                                );
                                this.props.appendAlert(
                                  "Please wait while your request is being processed",
                                  "warning"
                                );
                              } else if (
                                response.data.capabilities.card_payments ===
                                "inactive"
                              ) {
                                this.props.setAccountPaymentStatus(
                                  response.data.capabilities.card_payments
                                );
                                reCreatePaymentAccount("EXPRESS").then(
                                  async (response) => {
                                    if (response.status === 200) {
                                      await this.openLink(response.data.data);
                                      this.setState({ isLoadingStipe: false });
                                    } else {
                                      this.props.appendAlert(
                                        "Something went wrong!, Please try again later.",
                                        "danger"
                                      );
                                      this.setState({ isLoadingStipe: false });
                                    }
                                  }
                                );
                              } else {
                                this.props.setAccountPaymentStatus(
                                  response.data.capabilities.card_payments
                                );
                                console.log(response.data);
                                createPaymentAccount("EXPRESS").then(
                                  async (response) => {
                                    if (response.status === 200) {
                                      await this.openLink(response.data.data);
                                      this.setState({ isLoadingStipe: false });
                                    } else {
                                      this.props.appendAlert(
                                        "Something went wrong!, Please try again later.",
                                        "danger"
                                      );
                                      this.setState({ isLoadingStipe: false });
                                    }
                                  }
                                );
                              }
                            } else {
                              this.props.appendAlert(
                                "Something went wrong!, Please try again later.",
                                "danger"
                              );
                            }
                          });
                        }
                      }}
                    >
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 225.000000 225.000000"
                        width="15px"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                          fill="#fff"
                          stroke="none"
                        >
                          <path
                            d="M1075 2240 c-446 -38 -715 -294 -715 -680 0 -179 38 -278 150 -391
                          101 -102 181 -143 551 -283 171 -65 229 -118 229 -210 0 -43 -4 -53 -38 -86
                          -29 -29 -50 -40 -97 -50 -154 -32 -440 26 -683 140 -47 22 -86 40 -88 40 -2 0
                          -4 -130 -4 -288 l0 -288 71 -27 c335 -131 764 -146 1036 -37 274 110 425 328
                          425 614 0 187 -47 310 -162 427 -99 99 -218 161 -493 255 -71 25 -146 53 -167
                          64 -94 48 -132 110 -111 181 25 82 117 109 306 91 165 -17 349 -67 473 -131
                          l42 -22 -2 288 -3 288 -100 33 c-180 60 -427 88 -620 72z"
                          />
                        </g>
                      </svg>{" "}
                      &nbsp;&nbsp;|&nbsp;&nbsp; Connect with Stripe
                    </button>
                  }
                  {this.state.isLoadingStipe && (
                    <Loader3 transY="50px" transX="0px" />
                  )}
                </div>
              </div>
            </div>
          ) : this.props.propertyArray.length === 0 ? (
            <NoData
              message="pending properties to process"
              sub="start adding more properties"
            ></NoData>
          ) : (
            <div
              className="row text-center  m-0 p-0 mt-3 d-flex justify-content-center"
              style={{ paddingBottom: "100px" }}
            >
              {this.props.propertyArray.map((property, index) => {
                return (
                  this.state.handleComponentDelete && (
                    <div
                      className=" col propertyCardProAlign  mb-4 "
                      key={index}
                    >
                      <PropertyCard
                        property={property}
                        history={this.props.history}
                        appendAlert={this.props.appendAlert}
                        _handleDelete={this.deleteProperty.bind(this)}
                        index={index}
                      />
                    </div>
                  )
                );
              })}
            </div>
          )}
        </div>
        <SmallModalContainer
          name="mangeAxallant"
          dark={this.props.dark}
          title="How you want to manage your properties?"
          confirmCommand="Continue"
          confirm={() => this.manageLandlord()}
          reject={() => this.manageAxallant()}
          rejectCommand="Manage by Axallant"
          rejectCheck="true"
          size="lg"
        >
          <div className="p-2 pr-4 pl-4 pull-left text-left">
            <span className="span7" style={{ fontSize: "16px" }}>
              If you want the Axallant to manage your properties, choose "Manage
              by Axallant". Else choose "Continue".
            </span>
          </div>
        </SmallModalContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    property: state.propertyReducer,
    propertyArray: state.property,
    alertArray: state.alert,
    isDark: state.isDark,
    dark: state.darkReducer,
    UserData: state.user,
    accountPayment: state.accountPaymentReducer,
    accountPaymentStatus: state.accountPayment,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setProperty,
    removePropertyById,
    setAccountPaymentStatus,
  })(ViewProp)
);
