import React, { Component } from "react";
import "./MaintenanceRequestDetailEmpty.css";
import box from "../../../../Images/emptyBox.png";

class MaintenanceRequestDetailEmpty extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="maintenanceRequestCard mt-3">
        <span className="maintenanceRequestDetailText1 ">
          {this.props.head}
        </span>
        <div className="row mt-3">
          <div className="col-3 pl-4 pt-2" style={{ maxWidth: "100px" }}>
            <img
              alt="icon"
              src={box}
              className="maintenanceRequestDetailIconImage2"
            />
          </div>

          <div className="col-xl pt-1">
            <h1 className="maintenanceRequestText2 textLeft">   {this.props.subHead}</h1>
            <h3 className="maintenanceRequestDetailText4  mt-1  ">
            {this.props.content}
            </h3>
            <button className="buttonSquareLinePri " onClick={()=>this.props.action()}> {this.props.actionName}</button>
          </div>
        </div>
      </div>
    );
  }
}

export default MaintenanceRequestDetailEmpty;
