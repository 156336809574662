import React, { Component } from 'react';
import DashCard from './DashCard';
import './DashMainCard.css';

class DashMainCard extends Component {
    constructor(props) {
        super(props);
        this.state = {data:props.data};
    }
    render() {
        return (
            <div className=" text-center ">
                <div className="row ">
                    {this.state.data && this.state.data.map(count => <DashCard key={count.id} data={count} />)}
                </div> 
            </div>
        );
    }
}

export default DashMainCard;