import React, { Component } from "react";
import "./phoneInput.css";
import iconCanada from "./canada-flag-icon.svg";
import iconUSA from "./united-states-flag-icon.svg";

class PhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countryList: [
        { name: "United State", code: "+1", icon: iconUSA },
        { name: "Canada", code: "+1", icon: iconCanada },
      ],
      selectedCountry: { name: "United State", code: "+1", icon: iconUSA },
    };
    this.clickCountry = this.clickCountry.bind(this);
  }

  clickCountry(data) {
    this.setState({ selectedCountry: data });
    this.props.changeState("mobileCode", data.code);
  }
  render() {
    return (
      <div className="form-group phoneInputContainer pointer">
        <label htmlFor="phone" className="profileLabel">
          {this.props.title}
        </label>
        <div data-toggle="dropdown" class="phoneInput">
          <img
            src={this.state.selectedCountry.icon}
            alt="profile"
            style={{
              height: "25px",
              width: "25px",

              marginLeft: "0px",
            }}
          ></img>

          <i
            className="fa fa-caret-down pl-2 pt-1"
            aria-hidden="true"
            style={{ fontSize: "22px", position: "absolute" }}
          ></i>
          <span className=" phoneInputCode">
            {this.state.selectedCountry.code}
          </span>
        </div>
        <div
          className="dropdown-menu  absolute profileMenu"
          aria-labelledby="dropdownMenu2"
          style={{
            marginTop: "20px",
            marginRight: "20px",
            width: "200px",
          }}
        >
          {this.state.countryList.map((data) => (
            <span
              className="dropdown-item countryList"
              onClick={() => this.clickCountry(data)}
            >
              <img
                src={data.icon}
                alt="profile"
                style={{
                  height: "25px",
                  width: "25px",
                  marginRight: "8px",
                  marginLeft: "0px",
                }}
              ></img>{" "}
              {data.name} ( {data.code} )
            </span>
          ))}
        </div>
        <input
          type="text"
          className={this.props.alert?"form-control tag alert1":"form-control tag"}
          name="mobile"
          onChange={this.props.onChange}
          placeholder=""
          value={this.props.mobile}
          style={{ textIndent: "75px" }}
        />
      </div>
    );
  }
}

export default PhoneInput;
