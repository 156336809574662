import React, { Component } from "react";
import Dropzone from "react-dropzone-uploader";
import Gallery from "react-grid-gallery";
import Loader from "../Loader/Loader";
import { withRouter } from "react-router-dom";
import "./viewListing.css";
import {
  deletePropertyLists,
  getRentalListsImages,
  addPropertyListsById,
  addPropertyListingImageBulk,
  url,
  getPropertyLists,
} from "../../Services/ApiServices";
import ApplicationInput from "../UIServices/ApplicationInput";
import Loader3 from "../Loader/loader3";
import SmallModalContainer from "../Pages/Documents/SmallModalContainer";
import {
  convertGMT,
  convertLocalDate,
  setDateFormat,
  timeFormat2,
  today,
} from "../../Defined/Function";
import SearchInput from "../Pages/Application/SearchInput";
import TimeInput from "../UIServices/TimeInput";
import CurrencyFormat from "../UIServices/CurrencyFormat";

const initialState = {
  ids: [],
  isLoading: false,
  access_token: "",
  activeStep: 0,
  viewingSlots: 1,
  viewingDay: [],
  viewingTime: [],
  viewingTime1: [],
  bedRoom: "",
  fullRoom: "",
  bathroomAttached: "",
  bathroomSeparate: "",
  halfRoom: "",
  sqFeet: "",
  listingHeading: "",
  description: "",
  petPolicy: "don'tSpecify",
  garage: false,
  onStreet: false,
  driveway: false,
  privateLot: false,
  dedicatedSpot: false,
  covered: false,
  sharedYard: false,
  privateYard: false,
  patio: false,
  balcony: false,
  garden: false,
  washerDrier: "none",
  laundryType: "none",
  basement: "none",
  gym: false,
  airConditioning: false,
  hardwoodFloors: false,
  fireplace: false,
  dishwasher: false,
  walkInCloset: false,
  furnisher: false,
  pool: false,
  hotTub: false,
  wheelchairAccessible: false,
  microwave: false,
  refrigerator: false,
  freezer: false,
  trashCompactor: false,
  garbageDisposal: false,
  coolingCentral: false,
  coolingEvaporative: false,
  coolingGeothermal: false,
  coolingRefrigeration: false,
  coolingSolar: false,
  coolingWall: false,
  coolingOther: false,
  heatingGeothermal: false,
  heatingBaseboard: false,
  heatingForcedAir: false,
  heatingHeatPump: false,
  heatingRadiant: false,
  heatingStove: false,
  heatingOther: false,
  heatingWall: false,
  oven: false,
  monthlyRent: "",
  securityDeposit: "",
  leaseDuration: "don’tSpecifyLease" /* not used */,
  leaseMonth: "",
  whenAvailable: "don’tSpecifyDate",
  date: "",
  walkInPeriod: "",
  walkInTime: null,
  walkInDay: [],
  securityCheck: false,
  backgroundCheck: false,
  images: [],
  currentImage: null,
  type: "new",
  imageLink: [],
  isOpen: true,
  nowTab: 1,
  next: false,
  nextEnable: false,
  footer: true,
  availableFrom: null,
  weekDays: [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ],
  weekDaysValue: [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
  loader: false,
  deleteModal: false,
  screeningInfo:
    "Screening reports are FREE for landlords. Applicants pay $24.99 per report, or $39.99 if they run both ($9.99 savings). Find qualified tenants by requiring screening reports from all applicants. To view reports, you’ll need to verify your identity with Experian to ensure that these reports are being viewed by an authorized individual. ID verification requires that you have a credit history, and don’t have a freeze on your credit file with Experian.",
};

class GetStepContent extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.onchange = this.onchange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.handleFinish = this.handleFinish.bind(this);
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.changeState = this.changeState.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.iconCloseClick = this.iconCloseClick.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }
  beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?");
  };
  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = this.beforeUnloadListener;
    this.setState({ type: this.props.location.pathname.split("/")[5] });
    if (this.props.location.pathname.split("/")[5] === "edit-listing") {
      if (this.props.location.state !== undefined) {
        await this.setState({
          ids: [],
          isLoading: false,
          loader: false,
          bedRoom:
            this.props.location.state.data.rentalEntity.bedRooms !== 0
              ? this.props.location.state.data.rentalEntity.bedRooms
              : "",
          fullRoom:
            this.props.location.state.data.rentalEntity.fullRooms !== 0
              ? this.props.location.state.data.rentalEntity.fullRooms
              : "",
          bathroomAttached:
            this.props.location.state.data.rentalEntity.bathroomsAttach !== 0
              ? this.props.location.state.data.rentalEntity.bathroomsAttach
              : "",
          bathroomSeparate:
            this.props.location.state.data.rentalEntity.bathroomsSeparate !== 0
              ? this.props.location.state.data.rentalEntity.bathroomsSeparate
              : "",
          halfRoom:
            this.props.location.state.data.rentalEntity.halfRooms !== 0
              ? this.props.location.state.data.rentalEntity.halfRooms
              : "",
          sqFeet:
            this.props.location.state.data.rentalEntity.sqFeet !== 0
              ? this.props.location.state.data.rentalEntity.sqFeet
              : "",
          listingHeading: this.props.location.state.data.rentalEntity
            .listingHeading
            ? this.props.location.state.data.rentalEntity.listingHeading
            : "",
          description: this.props.location.state.data.rentalEntity.description
            ? this.props.location.state.data.rentalEntity.description
            : "",
          petPolicy: this.props.location.state.data.rentalEntity.petPolicy
            ? this.props.location.state.data.rentalEntity.petPolicy
            : "none",
          garage: this.props.location.state.data.rentalEntity.parkingGarage,
          onStreet: this.props.location.state.data.rentalEntity.parkingOnStreet,
          driveway: this.props.location.state.data.rentalEntity.parkingDriveway,
          privateLot:
            this.props.location.state.data.rentalEntity.parkingPrivateLot,
          dedicatedSpot:
            this.props.location.state.data.rentalEntity.parkingDedicatedSpot,
          covered: this.props.location.state.data.rentalEntity.parkingCovered,
          sharedYard:
            this.props.location.state.data.rentalEntity.outDoorSharedYard,
          privateYard:
            this.props.location.state.data.rentalEntity.outDoorPrivateYard,
          patio: this.props.location.state.data.rentalEntity.outDoorPatio,
          balcony: this.props.location.state.data.rentalEntity.outDoorBalcony,
          garden: this.props.location.state.data.rentalEntity.outDoorGarden,
          washerDrier: this.props.location.state.data.rentalEntity.washerDrier
            ? this.props.location.state.data.rentalEntity.washerDrier
            : "none",
          laundryType: this.props.location.state.data.rentalEntity.laundryType
            ? this.props.location.state.data.rentalEntity.laundryType
            : "none",
          basement: this.props.location.state.data.rentalEntity.basement
            ? this.props.location.state.data.rentalEntity.basement
            : "none",
          gym: this.props.location.state.data.rentalEntity.gym,
          airConditioning:
            this.props.location.state.data.rentalEntity.airConditioner,
          hardwoodFloors:
            this.props.location.state.data.rentalEntity.hardwoodFloors,
          fireplace: this.props.location.state.data.rentalEntity.fireplace,
          dishwasher: this.props.location.state.data.rentalEntity.dishwasher,
          walkInCloset:
            this.props.location.state.data.rentalEntity.walkInCloset,
          furnisher: this.props.location.state.data.rentalEntity.furnisher,
          pool: this.props.location.state.data.rentalEntity.pool,
          hotTub: this.props.location.state.data.rentalEntity.hotTub,
          wheelchairAccessible:
            this.props.location.state.data.rentalEntity.wheelChair,
          microwave: this.props.location.state.data.rentalEntity.microwave,
          refrigerator:
            this.props.location.state.data.rentalEntity.refrigerator,
          freezer: this.props.location.state.data.rentalEntity.freezer,
          trashCompactor:
            this.props.location.state.data.rentalEntity.trashCompactor,
          garbageDisposal:
            this.props.location.state.data.rentalEntity.garbageDisposal,
          coolingCentral:
            this.props.location.state.data.rentalEntity.coolingCentral,
          coolingEvaporative:
            this.props.location.state.data.rentalEntity.coolingEvaporative,
          coolingGeothermal:
            this.props.location.state.data.rentalEntity.coolingGeothermal,
          coolingRefrigeration:
            this.props.location.state.data.rentalEntity.coolingRefrigeration,
          coolingSolar:
            this.props.location.state.data.rentalEntity.coolingSolar,
          coolingWall: this.props.location.state.data.rentalEntity.coolingWall,
          coolingOther:
            this.props.location.state.data.rentalEntity.coolingOther,
          heatingBaseboard:
            this.props.location.state.data.rentalEntity.heatingBaseboard,
          heatingForcedAir:
            this.props.location.state.data.rentalEntity.heatingForcedAir,
          heatingGeothermal:
            this.props.location.state.data.rentalEntity.heatingGeothermal,
          heatingHeatPump:
            this.props.location.state.data.rentalEntity.heatingHeatPump,
          heatingRadiant:
            this.props.location.state.data.rentalEntity.heatingRadiant,
          heatingStove:
            this.props.location.state.data.rentalEntity.heatingStove,
          heatingWall: this.props.location.state.data.rentalEntity.heatingWall,
          heatingOther:
            this.props.location.state.data.rentalEntity.heatingOther,
          oven: this.props.location.state.data.rentalEntity.oven,
          monthlyRent:
            this.props.location.state.data.rentalEntity.rent !== 0
              ? this.props.location.state.data.rentalEntity.rent
              : "",
          securityDeposit:
            this.props.location.state.data.rentalEntity.securityDeposit !== 0
              ? this.props.location.state.data.rentalEntity.securityDeposit
              : "",
          leaseDuration:
            this.props.location.state.data.rentalEntity.leasePeriod !== ""
              ? "minimumLeaseDuration"
              : "don’tSpecifyLease",
          leaseMonth: this.props.location.state.data.rentalEntity.leasePeriod,
          whenAvailable:
            this.props.location.state.data.rentalEntity.availableFrom !== null
              ? "availableFrom"
              : "don’tSpecifyDate",
          date: "",
          walkInPeriod: "", //not in interface
          walkInTime: null, //not in interface
          availableFrom:
            this.props.location.state.data.rentalEntity.availableFrom !== null
              ? setDateFormat(
                  this.props.location.state.data.rentalEntity.availableFrom
                )
              : null,
          securityCheck:
            this.props.location.state.data.rentalEntity.securityChk,
          backgroundCheck:
            this.props.location.state.data.rentalEntity.backgroundChk,
          viewingSlots:
            this.props.location.state.data.rentalEntity.viewingTimes.length > 0
              ? this.props.location.state.data.rentalEntity.viewingTimes.length
              : 1, //not in interface
              nextEnable:true,
        });
        await this.setState({
          ids: this.props.match.params.rentalEntityId.split(","),
        });

        if (
          this.props.location.state.data.rentalEntity.viewingTimes.length > 0
        ) {
          let x = [];
          let y = [];
          let y1 = [];
          this.props.location.state.data.rentalEntity.viewingTimes.forEach(
            (item) => {
              x.push(item.dayOfWeek);
              y.push(item.time);
              y1.push(timeFormat2(item.time));
            }
          );
          if (x.length > 0 || y.length > 0) {
            this.setState({ viewingDay: x, viewingTime: y,viewingTime1: y1});
          } else {
            this.setState({ viewingDay: [""], viewingTime: [""] });
          }
        }
        try {
          this.setState({ isLoading: true });
          await getRentalListsImages(
            this.props.match.params.rentalEntityId
          ).then((response) => {
            if (response.status === 200) {
              if (response.data.length !== 0) {
                this.setState({ images: response.data, isLoading: false });
              } else {
                console.log("No data!");
                this.setState({ isLoading: false });
              }
            } else {
              if (response.data === undefined) {
                if (response.response.data !== undefined) {
                  this.props.pushAlert(
                    response.response.data.message,
                    "danger"
                  );
                } else {
                  this.props.pushAlert("Try again later", "warning");
                }
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          getPropertyLists(this.props.match.params.rentalEntityId).then(
            async (response1) => {
              if (response1.status === 200) {
                await this.setState({
                  ids: [],
                  isLoading: false,
                  loader: false,
                  bedRoom:
                    response1.data.rentalEntity.bedRooms !== null
                      ? response1.data.rentalEntity.bedRooms
                      : "",
                  fullRoom:
                    response1.data.rentalEntity.fullRooms !== null
                      ? response1.data.rentalEntity.fullRooms
                      : "",
                  bathroomAttached:
                    response1.data.rentalEntity.bathroomsAttach !== null
                      ? response1.data.rentalEntity.bathroomsAttach
                      : "",
                  bathroomSeparate:
                    response1.data.rentalEntity.bathroomsSeparate !== null
                      ? response1.data.rentalEntity.bathroomsSeparate
                      : "",
                  halfRoom:
                    response1.data.rentalEntity.halfRooms !== null
                      ? response1.data.rentalEntity.halfRooms
                      : "",
                  sqFeet:
                    response1.data.rentalEntity.sqFeet !== null
                      ? response1.data.rentalEntity.sqFeet
                      : "",
                  listingHeading: response1.data.rentalEntity.listingHeading
                    ? response1.data.rentalEntity.listingHeading
                    : "",
                  description: response1.data.rentalEntity.description
                    ? response1.data.rentalEntity.description !== null
                    : "",
                  petPolicy: response1.data.rentalEntity.petPolicy
                    ? response1.data.rentalEntity.petPolicy
                    : "none",
                  garage: response1.data.rentalEntity.parkingGarage,
                  onStreet: response1.data.rentalEntity.parkingOnStreet,
                  driveway: response1.data.rentalEntity.parkingDriveway,
                  privateLot: response1.data.rentalEntity.parkingPrivateLot,
                  dedicatedSpot:
                    response1.data.rentalEntity.parkingDedicatedSpot,
                  covered: response1.data.rentalEntity.parkingCovered,
                  sharedYard: response1.data.rentalEntity.outDoorSharedYard,
                  privateYard: response1.data.rentalEntity.outDoorPrivateYard,
                  patio: response1.data.rentalEntity.outDoorPatio,
                  balcony: response1.data.rentalEntity.outDoorBalcony,
                  garden: response1.data.rentalEntity.outDoorGarden,
                  washerDrier: response1.data.rentalEntity.washerDrier
                    ? response1.data.rentalEntity.washerDrier
                    : "none",
                  laundryType: response1.data.rentalEntity.laundryType
                    ? response1.data.rentalEntity.laundryType
                    : "none",
                  basement: response1.data.rentalEntity.basement
                    ? response1.data.rentalEntity.basement
                    : "none",
                  gym: response1.data.rentalEntity.gym,
                  airConditioning: response1.data.rentalEntity.airConditioner,
                  hardwoodFloors: response1.data.rentalEntity.hardwoodFloors,
                  fireplace: response1.data.rentalEntity.fireplace,
                  dishwasher: response1.data.rentalEntity.dishwasher,
                  walkInCloset: response1.data.rentalEntity.walkInCloset,
                  furnisher: response1.data.rentalEntity.furnisher,
                  pool: response1.data.rentalEntity.pool,
                  hotTub: response1.data.rentalEntity.hotTub,
                  wheelchairAccessible: response1.data.rentalEntity.wheelChair,
                  microwave: response1.data.rentalEntity.microwave,
                  refrigerator: response1.data.rentalEntity.refrigerator,
                  freezer: response1.data.rentalEntity.freezer,
                  trashCompactor: response1.data.rentalEntity.trashCompactor,
                  garbageDisposal: response1.data.rentalEntity.garbageDisposal,
                  coolingCentral: response1.data.rentalEntity.coolingCentral,
                  coolingEvaporative:
                    response1.data.rentalEntity.coolingEvaporative,
                  coolingGeothermal:
                    response1.data.rentalEntity.coolingGeothermal,
                  coolingRefrigeration:
                    response1.data.rentalEntity.coolingRefrigeration,
                  coolingSolar: response1.data.rentalEntity.coolingSolar,
                  coolingWall: response1.data.rentalEntity.coolingWall,
                  coolingOther: response1.data.rentalEntity.coolingOther,
                  heatingBaseboard:
                    response1.data.rentalEntity.heatingBaseboard,
                  heatingForcedAir:
                    response1.data.rentalEntity.heatingForcedAir,
                  heatingGeothermal:
                    response1.data.rentalEntity.heatingGeothermal,
                  heatingHeatPump: response1.data.rentalEntity.heatingHeatPump,
                  heatingRadiant: response1.data.rentalEntity.heatingRadiant,
                  heatingStove: response1.data.rentalEntity.heatingStove,
                  heatingWall: response1.data.rentalEntity.heatingWall,
                  heatingOther: response1.data.rentalEntity.heatingOther,
                  oven: response1.data.rentalEntity.oven,
                  monthlyRent:
                    response1.data.rentalEntity.rent !== 0
                      ? response1.data.rentalEntity.rent
                      : "",
                  securityDeposit:
                    response1.data.rentalEntity.securityDeposit !== 0
                      ? response1.data.rentalEntity.securityDeposit
                      : "",
                  leaseDuration:
                    response1.data.rentalEntity.leasePeriod !== ""
                      ? "minimumLeaseDuration"
                      : "don’tSpecifyLease",
                  leaseMonth: response1.data.rentalEntity.leasePeriod,
                  whenAvailable:
                    response1.data.rentalEntity.availableFrom !== null
                      ? "availableFrom"
                      : "don’tSpecifyDate",
                  date: "",
                  walkInPeriod: "", //not in interface
                  walkInTime: null, //not in interface
                  availableFrom:
                    response1.data.rentalEntity.availableFrom !== null
                      ? setDateFormat(response1.data.rentalEntity.availableFrom)
                      : null,
                  securityCheck: response1.data.rentalEntity.securityChk,
                  backgroundCheck: response1.data.rentalEntity.backgroundChk,
                  viewingSlots:
                    response1.data.rentalEntity.viewingTimes.length > 0
                      ? response1.data.rentalEntity.viewingTimes.length
                      : 1, //not in interface
                });
                await this.setState({
                  ids: this.props.match.params.rentalEntityId.split(","),
                });

                if (response1.data.rentalEntity.viewingTimes.length > 0) {
                  let x = [];
                  let y = [];
                  let y1 = [];
                  response1.data.rentalEntity.viewingTimes.forEach((item) => {
                    x.push(item.dayOfWeek);
                    y.push(item.time);
                    y1.push(timeFormat2(item.time));
                  });
                  if (x.length > 0 || y.length > 0) {
                    this.setState({ viewingDay: x, viewingTime: y ,viewingTime1: y1});
                  } else {
                    this.setState({ viewingDay: [""], viewingTime: [""] });
                  }
                }
                try {
                  this.setState({ isLoading: true });
                  getRentalListsImages(
                    this.props.match.params.rentalEntityId
                  ).then((response2) => {
                    if (response2.status === 200) {
                      if (response2.data.length !== 0) {
                        this.setState({
                          images: response2.data,
                          isLoading: false,
                        });
                      } else {
                        console.log("No data!");
                        this.setState({ isLoading: false });
                      }
                    } else {
                      if (response2.data === undefined) {
                        if (response2.response.data !== undefined) {
                          this.props.pushAlert(
                            response2.response.data.message,
                            "danger"
                          );
                        } else {
                          this.props.pushAlert("Try again later", "warning");
                        }
                      }
                    }
                  });
                } catch (error) {
                  console.error(error);
                }
              } else {
                if (response1.data === undefined) {
                  if (response1.response.data !== undefined) {
                    this.props.pushAlert(
                      response1.response.data.message,
                      "danger"
                    );
                  } else {
                    this.props.pushAlert("Try again later", "warning");
                  }
                }
              }
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      this.setState({
        ids: this.props.match.params.rentalEntityId.split(","),
        viewingDay: [""],
        viewingTime: [""],
      });
    }
  }

  async handleChange(event) {
    let { name, value, type, checked } = event.target;
    if (
      name === "fullRoom" ||
      name === "halfRoom" ||
      name === "sqFeet" ||
      name === "monthlyRent" ||
      name === "securityDeposit"
    ) {
      value = value.replace(/[^0-9]/g, "");
    }
    type === "checkbox"
      ? await this.setState({ [name]: checked })
      : await this.setState({ [name]: value });
  }

  async handleUpload(files) {
    files.forEach((file) => {
      if (this.state.images.indexOf(file) < 0) {
        this.setState((state) => ({
          images: state.images.concat([file]),
        }));
      }
    });
  }
  onCurrentImageChange(index) {
    this.setState({ currentImage: index });
  }
  async deleteImage() {
    if (this.state.images.length >= this.state.currentImage) {
      if (this.state.images[this.state.currentImage].file) {
        let images = this.state.images.slice();
        images.splice(this.state.currentImage, 1);
        this.setState({ images: images, isOpen: false });
        document.querySelector(".close_1x3s325").click();
      } else {
        try {
          await deletePropertyLists(
            this.props.match.params.rentalEntityId,
            this.state.images[this.state.currentImage]
          ).then((response) => {
            if (response.status === 200) {
              if (response.data.status) {
                let images = this.state.images.slice();
                images.splice(this.state.currentImage, 1);
                this.setState({ images: images, isOpen: false });
                this.props.pushAlert("Image deleted successfully", "success");
                document.querySelector(".close_1x3s325").click();
              } else {
                this.props.pushAlert(
                  "Error in deleting.Try again later!",
                  "danger"
                );
                document.querySelector(".close_1x3s325").click();
              }
            } else {
              if (response.data === undefined) {
                if (response.response.data !== undefined) {
                  this.props.pushAlert(
                    response.response.data.message,
                    "danger"
                  );
                } else {
                  this.props.pushAlert("Try again later", "warning");
                }
              }
            }
          });
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      this.props.pushAlert("Error in deleting.Try again later!", "danger");
    }
  }

  async handleFinish(openOption) {
    try {
      this.setState({ loading: true });
      let viewingTimes = [];
      // eslint-disable-next-line
      this.state.viewingDay.map((data,index) =>{
          this.state.viewingDay[index] !== null &&
          this.state.viewingDay[index] !== "" &&
          this.state.viewingTime[index] !== null &&
          this.state.viewingTime[index] !== "" &&
          viewingTimes.push({
            dayOfWeek: this.state.viewingDay[index],
            time: this.state.viewingTime[index],
          });
        }
      );
      let body = {
        id: this.state.ids,
        rentalEntity: {
          unitType: "",
          status: openOption,
          listingHeading: this.state.listingHeading,
          fullRooms: this.state.fullRoom,
          halfRooms: this.state.halfRoom,
          bedRooms: this.state.bedRoom,
          bathroomsAttach: this.state.bathroomAttached,
          bathroomsSeparate: this.state.bathroomSeparate,
          sqFeet: this.state.sqFeet,
          description: this.state.description,
          petPolicy: this.state.petPolicy,
          furnisher: this.state.furnisher,
          parkingGarage: this.state.garage,
          parkingOnStreet: this.state.onStreet,
          parkingDriveway: this.state.driveway,
          parkingPrivateLot: this.state.privateLot,
          parkingDedicatedSpot: this.state.dedicatedSpot,
          parkingCovered: this.state.covered,
          outDoorSharedYard: this.state.sharedYard,
          outDoorPrivateYard: this.state.privateYard,
          outDoorPatio: this.state.patio,
          outDoorBalcony: this.state.balcony,
          outDoorGarden: this.state.garden,
          washerDrier: this.state.washerDrier,
          laundryType: this.state.laundryType,
          basement: this.state.basement,

          microwave: this.state.microwave,
          refrigerator: this.state.refrigerator,
          freezer: this.state.freezer,
          trashCompactor: this.state.trashCompactor,
          garbageDisposal: this.state.garbageDisposal,
          coolingCentral: this.state.coolingCentral,
          coolingGeothermal: this.state.coolingGeothermal,
          coolingEvaporative: this.state.coolingEvaporative,
          coolingRefrigeration: this.state.coolingRefrigeration,
          coolingSolar: this.state.coolingSolar,
          coolingWall: this.state.coolingWall,
          coolingOther: this.state.coolingOther,
          heatingBaseboard: this.state.heatingBaseboard,
          heatingForcedAir: this.state.heatingForcedAir,
          heatingGeothermal: this.state.heatingGeothermal,
          heatingHeatPump: this.state.heatingHeatPump,
          heatingRadiant: this.state.heatingRadiant,
          heatingStove: this.state.heatingStove,
          heatingWall: this.state.heatingWall,
          heatingOther: this.state.heatingOther,

          oven: this.state.oven,
          gym: this.state.gym,
          airConditioner: this.state.airConditioning,
          hardwoodFloors: this.state.hardwoodFloors,
          fireplace: this.state.fireplace,
          walkInCloset: this.state.walkInCloset,
          pool: this.state.pool,
          hotTub: this.state.hotTub,
          wheelChair: this.state.wheelchairAccessible,
          dishwasher: this.state.dishwasher,

          storage: false,
          walkInTime: null,
          availableFrom:
            this.state.availableFrom !== null
              ? convertGMT(this.state.availableFrom + "T00:00:00")
              : null,
          amenities: null,
          rent: this.state.monthlyRent !== "" ? this.state.monthlyRent : 0.0,
          securityDeposit:
            this.state.securityDeposit !== ""
              ? this.state.securityDeposit
              : 0.0,
          leasePeriod:
            this.state.leaseDuration === "don’tSpecifyLease"
              ? ""
              : this.state.leaseMonth,
          securityChk: this.state.securityCheck,
          backgroundChk: this.state.backgroundCheck,
          imageLink: null, //not in interface
          link: null,
          leave: true,
        },
        viewingTimes: viewingTimes.length === 0 ? [] : viewingTimes,
      };

      addPropertyListsById(body).then(async (response) => {
        if (response.status === 200) {
          if (response.data.status) {
            if (this.state.images.length > 0) {
              const formData = new FormData();
              this.state.ids.forEach((id) => {
                formData.append("text", id.toString());
              });
              this.state.images.forEach((file) => {
                if (file.file) {
                  formData.append("file", file.file);
                }
              });
              addPropertyListingImageBulk(formData)
                .then(async (response1) => {
                  if (response1.status !== 200) {
                    this.props.pushAlert(
                      "Image upload Fail,Try again later",
                      "danger"
                    );
                  }
                  if (this.state.type === "edit-listing") {
                    this.props.pushAlert("Updated successfully", "success");
                  } else {
                    this.props.pushAlert(
                      "Listing added successfully",
                      "success"
                    );
                  }
                  this.setState({ loading: false, isLoading: false });
                  this.props.history.push({
                    pathname:
                      "/properties/" +
                      this.props.location.state.propsId +
                      "/listings",
                    state: {
                      id: this.props.location.state.propsId,
                      // tab: openOption === "OPEN" ? "Open" : "Closed",
                      tab: "Open",
                      message:
                        this.state.type === "edit-listing"
                          ? "Updated successfully"
                          : "Listing added successfully",
                    },
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              if (this.state.type === "edit-listing") {
                this.props.pushAlert("Updated successfully", "success");
              } else {
                this.props.pushAlert("Listing added successfully", "success");
              }
              this.setState({ loading: false, isLoading: false });
              this.props.history.push({
                pathname:
                  "/properties/" +
                  this.props.location.state.propsId +
                  "/listings",
                state: {
                  id: this.props.location.state.propsId,
                  tab: "Open",
                  message:
                    this.state.type === "edit-listing"
                      ? "Updated successfully"
                      : "Listing added successfully",
                },
              });
            }
          } else {
            this.setState({ loading: false, isLoading: false });
            this.props.pushAlert("Something went wrong!", "danger");
          }
        } else {
          this.setState({ loading: false, isLoading: false });
        }
      });
    } catch (error) {}
  }

  next() {
    this.setState({ next: true });
    if (this.state.nowTab < 6) {
      if (this.state.nowTab === 1) {
        window.scrollTo(0, 0);
        if (
          this.state.listingHeading !== "" &&
          this.state.bedRoom !== "" &&
          this.state.sqFeet
        ) {
          this.setState({ next: false });
          this.setState({ nowTab: this.state.nowTab + 1 });
        }
      }
      if (this.state.nowTab === 2) {
        window.scrollTo(0, 0);
        if (!this.nextCheck(this.state.nowTab + 1)) {
          this.setState({ nextEnable: false });
        }
        this.setState({ next: false, nowTab: this.state.nowTab + 1 });
      }
      if (this.state.nowTab === 3) {
        window.scrollTo(0, 0);
        if (this.state.monthlyRent !== "") {
          if (this.state.leaseDuration === "minimumLeaseDuration") {
            if (this.state.leaseMonth !== "") {
              if (this.state.whenAvailable === "availableFrom") {
                if (this.state.availableFrom !== null) {
                  this.setState({ next: false });
                  this.setState({ nowTab: this.state.nowTab + 1 });
                }
              } else {
                this.setState({ next: false });
                this.setState({ nowTab: this.state.nowTab + 1 });
              }
            }
          } else {
            if (this.state.whenAvailable === "availableFrom") {
              if (this.state.availableFrom !== null) {
                this.setState({ next: false });
                this.setState({ nowTab: this.state.nowTab + 1 });
              }
            } else {
              this.setState({ next: false });
              this.setState({ nowTab: this.state.nowTab + 1 });
            }
          }
        }
      }
      if (this.state.nowTab === 4) {
        window.scrollTo(0, 0);

        this.setState({ next: false, nowTab: this.state.nowTab + 1 });
      }
      if (this.state.nowTab === 5) {
        window.scrollTo(0, 0);
        let removedTimeArray = this.state.viewingTime;
        let removedTimeArray1 = this.state.viewingTime1;
        let removedDayArray = this.state.viewingDay;
        for (let i = 0; i < this.state.viewingTime.length; i++) {
          if (i === 0) {
            if (
              this.state.viewingTime[i] === "" ||
              this.state.viewingDay[i] === ""
            ) {
              if (this.state.viewingTime.length > 1) {
                removedTimeArray.splice(i, 1);
                removedTimeArray1.splice(i, 1);
                removedDayArray.splice(i, 1);
                i--;
              } else {
                removedTimeArray[i] = "";
                removedTimeArray1[i] = "";
                removedDayArray[i] = "";
              }
            }
          } else if (
            this.state.viewingTime[i] === "" ||
            this.state.viewingDay[i] === ""
          ) {
            removedTimeArray.splice(i, 1);
            removedTimeArray1.splice(i, 1);
            removedDayArray.splice(i, 1);
            i--;
          }
        }
        this.setState({
          viewingTime: removedTimeArray,
          viewingTime1: removedTimeArray1,
          viewingDay: removedDayArray,
        });
        this.setState({ next: false });
        this.setState({ nowTab: this.state.nowTab + 1 });
      }
    }
  }

  nextCheck(tab) {
    this.setState({ nextEnable: false });
    if (tab < 6) {
      if (tab === 1) {
        if (
          this.state.listingHeading !== "" &&
          this.state.bedRoom !== "" &&
          this.state.sqFeet
        ) {
          this.setState({ nextEnable: true });
          return true;
        }
      }
      if (tab === 2) {
        this.setState({ nextEnable: true });
        return true;
      }
      if (tab === 3) {
        if (this.state.monthlyRent !== "") {
          if (this.state.leaseDuration === "minimumLeaseDuration") {
            if (this.state.leaseMonth !== "") {
              if (this.state.whenAvailable === "availableFrom") {
                if (this.state.availableFrom !== null) {
                  this.setState({ nextEnable: true });
                  return true;
                }
              } else {
                this.setState({ nextEnable: true });
                return true;
              }
            }
          } else {
            if (this.state.whenAvailable === "availableFrom") {
              if (this.state.availableFrom !== null) {
                this.setState({ nextEnable: true });
                return true;
              }
            } else {
              this.setState({ nextEnable: true });
              return true;
            }
          }
        }
      }
      if (tab === 4) {
        this.setState({ nextEnable: true });
        return true;
      }
      if (tab === 5) {
        this.setState({ nextEnable: true });
        return true;
      }
    }
    return false;
  }

  back() {
    if (this.state.nowTab > 1) {
      window.scrollTo(0, 0);
      if (!this.nextCheck(this.state.nowTab - 1)) {
        this.setState({ nextEnable: false });
      }
      this.setState({ nowTab: this.state.nowTab - 1 });
    }
  }
  cancel() {
    this.props.history.goBack({
      state: {
        id: this.props.location.state.propsId,
        tab: "Open",
        message: null,
      },
    });
  }
  async changeState(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  async iconCloseClick(name) {
    await this.setState({ [name]: "" });
    this.nextCheck(this.state.nowTab);
  }
  async onChangeTime(id, name, value, value1) {
    let x = this.state.viewingTime;
    let x1 = this.state.viewingTime1;
    x[id] = value;
    x1[id] = value1;
    await this.setState({ viewingTime: x, viewingTime1: x1 });
    this.nextCheck(this.state.nowTab);
  }
  async onChangeDate(name, value) {
    await this.setState({ [name]: value });
    this.nextCheck(this.state.nowTab);
  }
  async onchange(e) {
    let { name, value, id } = e.target;
    if (name === "viewingDay") {
      let x = this.state.viewingDay;
      x[id] = value;
      await this.setState({ [name]: x });
    } else if (name === "viewingTime") {
      let x = this.state.viewingTime;
      x[id] = value + ":00";
      await this.setState({ [name]: x });
    } else if (name === "fullRoom" || name === "halfRoom") {
      let num = value.replace(/[^\d]/g, "");
      value = num;
      await this.setState({ [name]: value });
    } else if (
      name === "monthlyRent" ||
      name === "securityDeposit" ||
      name === "sqFeet"
    ) {
      let num = value.replace(/[^\d^.]/g, "");
      value = num;
      await this.setState({ [name]: value });
    } else {
      await this.setState({ [name]: value });
    }
    this.nextCheck(this.state.nowTab);
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: checked });
    this.nextCheck(this.state.nowTab);
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  addInput() {
    let addTimeArray = this.state.viewingTime;
    let addTimeArray1 = this.state.viewingTime1;
    let addDayArray = this.state.viewingDay;
    addTimeArray.push("");
    addTimeArray1.push("");
    addDayArray.push("");
    this.setState({ viewingTime: addTimeArray, viewingTime1: addTimeArray1, viewingDay: addDayArray });
  }
  removeInput(index) {
    let removedTimeArray = this.state.viewingTime;
    let removedTimeArray1 = this.state.viewingTime1;
    let removedDayArray = this.state.viewingDay;
    removedTimeArray.splice(index, 1);
    removedTimeArray1.splice(index, 1);
    removedDayArray.splice(index, 1);
    this.setState({
      viewingTime: removedTimeArray,
      viewingTime1: removedTimeArray1,
      viewingDay: removedDayArray,
    });
  }
  timeConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    time[3] = "";
    return time.join(""); // return adjusted time or original string
  }
  alignData(props) {
    return (
      <div className="row">
        <div className="col-5">
          <span className="span6">{props.label}</span>
        </div>
        <div className="col-1">
          <span className="span">:</span>
        </div>
        <div className="col item-left">
          <span className="span6">
            {props.value === "checked" ? (
              <i className="fa fa-check-square-o" aria-hidden="true"></i>
            ) : props.value === "unChecked" ? (
              <i className="fa fa-square-o" aria-hidden="true"></i>
            ) : (
              props.value
            )}
          </span>
        </div>
      </div>
    );
  }
  render() {
    // showing uploaded images
    let imgList = [];
    this.state.images.forEach((img) => {
      img.file
        ? imgList.push({
            src: URL.createObjectURL(img.file),
            thumbnail: URL.createObjectURL(img.file),
            thumbnailWidth: img.meta.width / 2,
            thumbnailHeight: img.meta.height / 2,
          })
        : imgList.push({
            src:
              url +
              `api/property/${this.props.match.params.rentalEntityId}/${img}/image/downloadByName`,
            thumbnail:
              url +
              `api/property/${this.props.match.params.rentalEntityId}/${img}/image/downloadByName`,
          });
    });

    return this.state.isLoading ? (
      <Loader />
    ) : (
      <>
        <div
          className="container-fluid"
          style={{ minHeight: window.screen.height }}
        >
          <div className="row stepFormNav">
            <div className="col text-center">
              <div className="row">
                <div className="col SideLineHide"></div>
                <div className="col">
                  <div className=" social-container-step activeStep">
                    <div className="social ">
                      <i className="fa fa-address-book " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 1</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 1
                      ? "col  SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 1
                      ? "col SideLine activeLine "
                      : "col SideLine"
                  }
                ></div>
                <div className="col p-0 m-0">
                  <div
                    className={
                      this.state.nowTab > 1
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i
                        className="fa fa-address-card-o "
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 2</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 2
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 2
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col p-0 m-0">
                  <div
                    className={
                      this.state.nowTab > 2
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-handshake-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 3</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 3
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 3
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col p-0 m-0">
                  <div
                    className={
                      this.state.nowTab > 3
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-gavel" aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 4</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 4
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 4
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col p-0 m-0">
                  <div
                    className={
                      this.state.nowTab > 4
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social">
                      <i className="fa fa-street-view " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 5</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 5
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
            <div className="col text-center">
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 5
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col p-0 m-0">
                  <div
                    className={
                      this.state.nowTab > 5
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social">
                      <i className="fa fa-retweet " aria-hidden="true"></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 6</span>
                  <br></br>
                </div>
                <div className="col SideLineHide"></div>
              </div>
            </div>
          </div>
          <div className="row stepFormNavTitle mt-3 mb-3 addListingTabMobile">
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">
                {this.state.nowTab === 1
                  ? "Basic"
                  : this.state.nowTab === 2
                  ? "Additional"
                  : this.state.nowTab === 3
                  ? "Lease"
                  : this.state.nowTab === 4
                  ? "Screening"
                  : this.state.nowTab === 5
                  ? "Viewing"
                  : ""}
              </p>
            </div>
          </div>
          <div className="row stepFormNavTitle addListingTabWeb">
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Basic</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle ">Additional</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Lease</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Screening</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Viewing</p>
            </div>
            <div className="col d-flex justify-content-center">
              {" "}
              <p className="stepTitle">Review</p>
            </div>
          </div>
          {this.state.nowTab === 1 && (
            <div className="cardApplication">
              <div className="row inputContainer ">
                <div className="col alignInput">
                  <SearchInput
                    name="Bedrooms"
                    id="Bedrooms"
                    type="1"
                    target="bedRoom"
                    onChange={this.onchange}
                    list={[
                      "Studio",
                      "1",
                      "2",
                      "3",
                      "4",
                      "5",
                      "6",
                      "7",
                      "8",
                      "9",
                      "10+",
                    ]}
                    inputDisable={true}
                    changeState={this.changeState}
                    value={this.state.bedRoom}
                    important="true"
                    errorMessage="bedroom's count"
                    validate={this.checkValidate(this.state.bedRoom)}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Full rooms"
                    type="text"
                    target="fullRoom"
                    onChange={this.onchange}
                    value={this.state.fullRoom}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Half rooms"
                    type="text"
                    target="halfRoom"
                    onChange={this.onchange}
                    value={this.state.halfRoom}
                  />
                </div>
                <div className="col alignInput">
                  <SearchInput
                    name="Bathrooms(Separate)"
                    id="Bathrooms(Separate)"
                    type="1"
                    target="bathroomSeparate"
                    onChange={this.onchange}
                    list={[
                      "0.5",
                      "1",
                      "1.5",
                      "2",
                      "2.5",
                      "3",
                      "3.5",
                      "4",
                      "4.5",
                      "5+",
                    ]}
                    inputDisable={true}
                    changeState={this.changeState}
                    value={this.state.bathroomSeparate}
                  />
                </div>

                <div className="col alignInput">
                  <SearchInput
                    name="Bathrooms(Attached)"
                    id="Bathrooms(Attached)"
                    type="1"
                    target="bathroomAttached"
                    onChange={this.onchange}
                    list={[
                      "0.5",
                      "1",
                      "1.5",
                      "2",
                      "2.5",
                      "3",
                      "3.5",
                      "4",
                      "4.5",
                      "5+",
                    ]}
                    inputDisable={true}
                    changeState={this.changeState}
                    value={this.state.bathroomAttached}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Square feet"
                    type="text"
                    target="sqFeet"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the square feet"
                    validate={this.checkValidate(this.state.sqFeet)}
                    value={this.state.sqFeet}
                    iconTextR={"Sq. ft."}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Listing heading"
                    type="text"
                    target="listingHeading"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the listing heading"
                    validate={this.checkValidate(this.state.listingHeading)}
                    value={this.state.listingHeading}
                  />
                </div>
                <div className="col-12 ">
                  <ApplicationInput
                    name="About the property"
                    target="description"
                    type="textArea"
                    row="5"
                    onChange={this.onchange}
                    designType="2"
                    value={this.state.description}
                  />
                </div>
              </div>
              <br></br>
              <div className="row inputContainer1  pt-0 mt-0">
                <div className="col pt-0 mt-0 ">
                  <p className=" stepContentTitle   " style={{ marginTop: "-10px" }}>
                    Upload Image(s)
                  </p>
                </div>
              </div>
              <br></br>
              <div
                className="row inputContainer   mt-0"
                style={{ paddingTop: "0px" }}
              >
                <div className="col-xl-4 pt-0 mt-0  ">
                  <MyUploader _handleUpload={this.handleUpload.bind(this)} />
                </div>
                {this.state.images.length > 0 && (
                  <div
                    style={{
                      display: "block",
                      width: "100%",
                    }}
                    className="col-md "
                  >
                    <Gallery
                      images={imgList}
                      enableLightbox={true}
                      enableImageSelection={false}
                      currentImageWillChange={this.onCurrentImageChange}
                      customControls={[
                        <button
                          key="deleteImage"
                          data-target="#deleteImage"
                          data-toggle="modal"
                          onClick={() => this.setState({ deleteImage: true })}
                          className="agreement  mr-2 ml-2 pri2"
                          style={{
                            marginTop: "50px",
                            height: "40px",
                            zIndex: "4500",
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>,
                      ]}
                      backdropClosesModal={true}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {this.state.nowTab === 2 && (
            <>
              <div className="row inputContainer1  pb-5 mt-4 cardApplication">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Pet Policy"
                    type="optionBox"
                    target="petPolicy"
                    onChange={this.onchange}
                    options={[
                      "Negotiable",
                      "No Pets",
                      "Small Dogs,Cats",
                      "Pets OK",
                      "Don't Specify",
                    ]}
                    optionValue={[
                      "petsNegotiable",
                      "noPets",
                      "smallDogsCats",
                      "petsOk",
                      "don'tSpecify",
                    ]}
                    value={this.state.petPolicy}
                  />

                  <div className="pt-1">
                    <ApplicationInput
                      name="Washer/Dryer"
                      type="optionBox"
                      target="washerDrier"
                      onChange={this.onchange}
                      options={["In Unit", "On Site", "None"]}
                      optionValue={["inUnit", "onSite", "none"]}
                      value={this.state.washerDrier}
                    />
                  </div>
                  <div className="pt-1">
                    <ApplicationInput
                      name="Laundry"
                      type="optionBox"
                      target="laundryType"
                      onChange={this.onchange}
                      options={["In Unit", "On Site", "None"]}
                      optionValue={["inUnit", "onSite", "none"]}
                      value={this.state.laundryType}
                    />
                  </div>
                  <div className="pt-1">
                    <ApplicationInput
                      name="Basement"
                      type="optionBox"
                      target="basement"
                      onChange={this.onchange}
                      options={["Finished", "Unfinished", "None"]}
                      optionValue={["finished", "unFinished", "none"]}
                      value={this.state.basement}
                    />
                  </div>
                </div>
                <div className="col alignInput">
                  <p
                    className="optionTitle textDark "
                    style={{ marginBottom: "10px  " }}
                  >
                    Appliances
                  </p>

                  <ApplicationInput
                    name="Dishwasher"
                    type="checkedBox"
                    target="dishwasher"
                    onChange={this.onchangeCheck}
                    value={this.state.dishwasher}
                  />
                  <ApplicationInput
                    name="Microwave"
                    type="checkedBox"
                    target="microwave"
                    onChange={this.onchangeCheck}
                    value={this.state.microwave}
                  />
                  <ApplicationInput
                    name="Range / Oven"
                    type="checkedBox"
                    target="oven"
                    onChange={this.onchangeCheck}
                    value={this.state.oven}
                  />
                  <ApplicationInput
                    name="Refrigerator"
                    type="checkedBox"
                    target="refrigerator"
                    onChange={this.onchangeCheck}
                    value={this.state.refrigerator}
                  />
                  <ApplicationInput
                    name="Freezer"
                    type="checkedBox"
                    target="freezer"
                    onChange={this.onchangeCheck}
                    value={this.state.freezer}
                  />
                  <ApplicationInput
                    name="Trash compactor"
                    type="checkedBox"
                    target="trashCompactor"
                    onChange={this.onchangeCheck}
                    value={this.state.trashCompactor}
                  />
                  <ApplicationInput
                    name="Garbage disposal"
                    type="checkedBox"
                    target="garbageDisposal"
                    onChange={this.onchangeCheck}
                    value={this.state.garbageDisposal}
                  />
                  <br></br>
                  <p
                    className="optionTitle textDark "
                    style={{ marginBottom: "10px  " }}
                  >
                    Heating Type
                  </p>

                  <ApplicationInput
                    name="Baseboard"
                    type="checkedBox"
                    target="heatingBaseboard"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingBaseboard}
                  />

                  <ApplicationInput
                    name="ForcedAir"
                    type="checkedBox"
                    target="heatingForcedAir"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingForcedAir}
                  />
                  <ApplicationInput
                    name="Geothermal"
                    type="checkedBox"
                    target="heatingGeothermal"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingGeothermal}
                  />
                  <ApplicationInput
                    name="Heat pump"
                    type="checkedBox"
                    target="heatingHeatPump"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingHeatPump}
                  />
                  <ApplicationInput
                    name="Radiant"
                    type="checkedBox"
                    target="heatingRadiant"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingRadiant}
                  />
                  <ApplicationInput
                    name="Stove"
                    type="checkedBox"
                    target="heatingStove"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingStove}
                  />
                  <ApplicationInput
                    name="Wall"
                    type="checkedBox"
                    target="heatingWall"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingWall}
                  />
                  <ApplicationInput
                    name="Other"
                    type="checkedBox"
                    target="heatingOther"
                    onChange={this.onchangeCheck}
                    value={this.state.heatingOther}
                  />
                </div>
                <div className="col alignInput">
                  <p
                    className="optionTitle textDark "
                    style={{ marginBottom: "10px" }}
                  >
                    Parking
                  </p>
                  <ApplicationInput
                    name="Garage"
                    type="checkedBox"
                    target="garage"
                    onChange={this.onchangeCheck}
                    value={this.state.garage}
                  />
                  <ApplicationInput
                    name="On Street"
                    type="checkedBox"
                    target="onStreet"
                    onChange={this.onchangeCheck}
                    value={this.state.onStreet}
                  />
                  <ApplicationInput
                    name="Driveway"
                    type="checkedBox"
                    target="driveway"
                    onChange={this.onchangeCheck}
                    value={this.state.driveway}
                  />
                  <ApplicationInput
                    name="Private Lot"
                    type="checkedBox"
                    target="privateLot"
                    onChange={this.onchangeCheck}
                    value={this.state.privateLot}
                  />
                  <ApplicationInput
                    name="Dedicated Spot"
                    type="checkedBox"
                    target="dedicatedSpot"
                    onChange={this.onchangeCheck}
                    value={this.state.dedicatedSpot}
                  />
                  <ApplicationInput
                    name="Covered"
                    type="checkedBox"
                    target="covered"
                    onChange={this.onchangeCheck}
                    value={this.state.covered}
                  />

                  <br></br>
                  <p
                    className="optionTitle textDark "
                    style={{ marginBottom: "10px  " }}
                  >
                    Cooling Type
                  </p>

                  <ApplicationInput
                    name="Central"
                    type="checkedBox"
                    target="coolingCentral"
                    onChange={this.onchangeCheck}
                    value={this.state.coolingCentral}
                  />

                  <ApplicationInput
                    name="Evaporative"
                    type="checkedBox"
                    target="coolingEvaporative"
                    onChange={this.onchangeCheck}
                    value={this.state.coolingEvaporative}
                  />
                  <ApplicationInput
                    name="Geothermal"
                    type="checkedBox"
                    target="coolingGeothermal"
                    onChange={this.onchangeCheck}
                    value={this.state.coolingGeothermal}
                  />
                  <ApplicationInput
                    name="Refrigeration"
                    type="checkedBox"
                    target="coolingRefrigeration"
                    onChange={this.onchangeCheck}
                    value={this.state.coolingRefrigeration}
                  />
                  <ApplicationInput
                    name="Solar"
                    type="checkedBox"
                    target="coolingSolar"
                    onChange={this.onchangeCheck}
                    value={this.state.coolingSolar}
                  />
                  <ApplicationInput
                    name="Wall"
                    type="checkedBox"
                    target="coolingWall"
                    onChange={this.onchangeCheck}
                    value={this.state.coolingWall}
                  />
                  <ApplicationInput
                    name="Other"
                    type="checkedBox"
                    target="coolingOther"
                    onChange={this.onchangeCheck}
                    value={this.state.coolingOther}
                  />
                </div>
                <div className="col alignInput">
                  <p
                    className="optionTitle textDark "
                    style={{ marginBottom: "10px" }}
                  >
                    Outdoor Space
                  </p>
                  <ApplicationInput
                    name="Shared Yard"
                    type="checkedBox"
                    target="sharedYard"
                    onChange={this.onchangeCheck}
                    value={this.state.sharedYard}
                  />
                  <ApplicationInput
                    name="Private Yard"
                    type="checkedBox"
                    target="privateYard"
                    onChange={this.onchangeCheck}
                    value={this.state.privateYard}
                  />
                  <ApplicationInput
                    name="Patio"
                    type="checkedBox"
                    target="patio"
                    onChange={this.onchangeCheck}
                    value={this.state.patio}
                  />
                  <ApplicationInput
                    name="Balcony / Deck"
                    type="checkedBox"
                    target="balcony"
                    onChange={this.onchangeCheck}
                    value={this.state.balcony}
                  />
                  <ApplicationInput
                    name="Garden"
                    type="checkedBox"
                    target="garden"
                    onChange={this.onchangeCheck}
                    value={this.state.garden}
                  />
                  <br></br>
                  <p
                    className="optionTitle textDark "
                    style={{ marginBottom: "10px" }}
                  >
                    Others
                  </p>
                  <ApplicationInput
                    name="Gym/Fitness Center"
                    type="checkedBox"
                    target="gym"
                    onChange={this.onchangeCheck}
                    value={this.state.gym}
                  />
                  <ApplicationInput
                    name="Air Conditioning"
                    type="checkedBox"
                    target="airConditioning"
                    onChange={this.onchangeCheck}
                    value={this.state.airConditioning}
                  />
                  <ApplicationInput
                    name="Hardwood Floor"
                    type="checkedBox"
                    target="hardwoodFloors"
                    onChange={this.onchangeCheck}
                    value={this.state.hardwoodFloors}
                  />
                  <ApplicationInput
                    name="Fireplace"
                    type="checkedBox"
                    target="fireplace"
                    onChange={this.onchangeCheck}
                    value={this.state.fireplace}
                  />
                  <ApplicationInput
                    name="Walk-In Closet"
                    type="checkedBox"
                    target="walkInCloset"
                    onChange={this.onchangeCheck}
                    value={this.state.walkInCloset}
                  />

                  <ApplicationInput
                    name="Furnished"
                    type="checkedBox"
                    target="furnisher"
                    onChange={this.onchangeCheck}
                    value={this.state.furnisher}
                  />
                  <ApplicationInput
                    name="Pool"
                    type="checkedBox"
                    target="pool"
                    onChange={this.onchangeCheck}
                    value={this.state.pool}
                  />
                  <ApplicationInput
                    name="Hot Tub"
                    type="checkedBox"
                    target="hotTub"
                    onChange={this.onchangeCheck}
                    value={this.state.hotTub}
                  />
                  <ApplicationInput
                    name="Wheelchair Access"
                    type="checkedBox"
                    target="wheelchairAccessible"
                    onChange={this.onchangeCheck}
                    value={this.state.wheelchairAccessible}
                  />
                </div>
              </div>
            </>
          )}
          {this.state.nowTab === 3 && (
            <>
              <div className="row inputContainer cardApplication mt-4">
                <div className="col alignInput ">
                  <ApplicationInput
                    name="Monthly rent"
                    type="text"
                    target="monthlyRent"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the monthly rent"
                    validate={this.checkValidate(this.state.monthlyRent)}
                    value={this.state.monthlyRent}
                    iconL={"fa-usd"}
                    iconTextR={"per month"}
                  />
                  <ApplicationInput
                    name="Security deposit(Optional)"
                    type="text"
                    target="securityDeposit"
                    onChange={this.onchange}
                    value={this.state.securityDeposit}
                    iconL={"fa-usd"}
                  />
                </div>
                <div
                  className="col-xl-4  alignInput "
                  style={{ marginTop: "-15px" }}
                >
                  <ApplicationInput
                    name="Lease Duration"
                    type="optionBox"
                    target="leaseDuration"
                    onChange={this.onchange}
                    options={[
                      "Don’t specify a lease duration",
                      "Minimum Lease Duration",
                    ]}
                    optionValue={["don’tSpecifyLease", "minimumLeaseDuration"]}
                    value={this.state.leaseDuration}
                  />
                  {this.state.leaseDuration !== "don’tSpecifyLease" && (
                    <ApplicationInput
                      name="Lease Duration"
                      type="optional"
                      target="leaseMonth"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={"the lease duration"}
                      select={"Duration"}
                      validate={this.checkValidate(this.state.leaseMonth)}
                      options={[
                        "1 month",
                        "6 months",
                        "1 year",
                        "Rent to own",
                        "Sublet / Temporary",
                      ]}
                      optionValue={[
                        "1 month",
                        "6 months",
                        "1 year",
                        "Rent to own",
                        "Sublet / Temporary",
                      ]}
                      value={this.state.leaseMonth}
                    />
                  )}
                </div>
                <div className="col-lg-4" style={{ marginTop: "-15px" }}>
                  <div className="row">
                    <div className="col-xl alignInput">
                      <ApplicationInput
                        name="Date Available"
                        type="optionBox"
                        target="whenAvailable"
                        onChange={this.onchange}
                        options={["Specify the date later", "Available From"]}
                        optionValue={["don’tSpecifyDate", "availableFrom"]}
                        value={this.state.whenAvailable}
                      />
                    </div>
                    {this.state.whenAvailable !== "don’tSpecifyDate" && (
                      <div className="col-xl alignInput">
                        <ApplicationInput
                          name="Date"
                          type="date"
                          target="availableFrom"
                          min={today()}
                          onChange={this.onChangeDate}
                          important="true"
                          errorMessage="the move in date"
                          validate={this.checkValidate(
                            this.state.availableFrom === null
                              ? ""
                              : this.state.availableFrom
                          )}
                          value={this.state.availableFrom}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {this.state.nowTab === 4 && (
            <>
              <div className="row inputContainer pt-0 mt-4">
                <div className="col  pt-0 ">
                  <div className="informationListAppNormal inputContainer">
                    <p
                      className="optionTitle  pt-0 mt-0"
                      style={{ marginBottom: "10px" }}
                    >
                      Applicant Screening Tools
                    </p>
                    <span className="searchListContentApp">
                      {this.state.screeningInfo}
                    </span>
                    <br></br>
                    <br></br>
                    <div className="informationListAppNormal ">
                      <p
                        className="optionTitle textDark pt-0 mt-0"
                        style={{ marginBottom: "10px" }}
                      >
                        <ApplicationInput
                          name="Require credit checks"
                          type="checkedBox"
                          target="securityCheck"
                          onChange={this.onchangeCheck}
                          value={this.state.securityCheck}
                        />
                      </p>
                      <span className="searchListContentApp ">
                        All applicants will be prompted to submit a credit
                        report with their application. Includes a credit score,
                        detailed payment history, and debt overviews.
                      </span>
                    </div>

                    <div className="informationListAppNormal shadow ">
                      <p
                        className="optionTitle textDark pt-0 mt-0"
                        style={{ marginBottom: "10px" }}
                      >
                        <ApplicationInput
                          name="Require background checks"
                          type="checkedBox"
                          target="backgroundCheck"
                          onChange={this.onchangeCheck}
                          value={this.state.backgroundCheck}
                        />
                      </p>
                      <span className="searchListContentApp">
                        All applicants will be prompted to run a background
                        check as part of their application. Includes a search
                        for eviction and national/county criminal records.
                      </span>
                    </div>
                    <br></br>
                  </div>
                </div>
              </div>
            </>
          )}

          {this.state.nowTab === 5 && (
            <div className="cardApplication  pb-5">
              <div className=" mt-2 row inputContainer  pt-0 mt-4 pb-0 mb-0">
                <div className="col-12 ">
                  <span className="  stepContentTitle textDark  ">
                    Tour Availability
                  </span>
                </div>
              </div>
              <div className=" mt-2 row inputContainer  pb-0 mb-0">
                <div className="col-xl-3 alignInput ">
                  <ApplicationInput
                    name="Day"
                    target="viewingDay"
                    type="optional"
                    options={this.state.weekDays}
                    optionValue={this.state.weekDays}
                    onChange={this.onchange}
                    id={0}
                    value={this.state.viewingDay[0]}
                  />
                </div>
                <div className="col-xl-3 alignInput ">
                  <TimeInput
                    name="Time"
                    target="viewingTime"
                    onChange={this.onChangeTime}
                    id={0}
                    value={this.state.viewingTime1[0]}
                    place="--:-- --"
                  />
                </div>
                <div className="col-xl-6 ">
                  {this.state.viewingTime.length === 1 ? (
                    <button
                      key="deleteImage"
                      onClick={() => this.addInput()}
                      className="agreement   pri"
                      style={{
                        marginTop: "35px",
                        height: "35px",
                        zIndex: "4500",
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                  ) : (
                    <button
                      key="deleteImage"
                      onClick={() => this.removeInput(0)}
                      className="agreement   pri"
                      style={{
                        marginTop: "35px",
                        height: "35px",
                        zIndex: "4500",
                      }}
                    >
                      <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  )}
                </div>

                {this.state.viewingDay.map(
                  (data, index) =>
                    index !== 0 && (
                      <>
                        <div className="col-xl-3 alignInput ">
                          <ApplicationInput
                            name=""
                            target="viewingDay"
                            type="optional"
                            options={this.state.weekDays}
                            optionValue={this.state.weekDays}
                            onChange={this.onchange}
                            id={index}
                            // important="true"
                            // errorMessage="account number"
                            // validate={this.checkValidate(this.state.viewingDay)}
                            value={this.state.viewingDay[index]}
                          />
                        </div>
                        <div className="col-xl-3 alignInput pt-4">
                          <TimeInput
                            name=""
                            target="viewingTime"
                            onChange={this.onChangeTime}
                            id={index}
                            value={this.state.viewingTime1[index]}
                            place="--:-- --"
                          />
                        </div>
                        <div className="col-xl-6 pt-2">
                          <>
                            {this.state.viewingTime.length === index + 1 ? (
                              <button
                                key="deleteImage"
                                onClick={() => this.addInput()}
                                className="agreement   pri"
                                style={{
                                  marginTop: "25px",
                                  height: "35px",
                                  zIndex: "4500",
                                }}
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            ) : (
                              <button
                                key="deleteImage"
                                onClick={() => this.removeInput(index)}
                                className="agreement   pri"
                                style={{
                                  marginTop: "25px",
                                  height: "35px",
                                  zIndex: "4500",
                                }}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                            <br></br>
                          </>
                        </div>
                      </>
                    )
                )}
              </div>
            </div>
          )}

          {this.state.nowTab === 6 && (
            <>
              <div className="row informationListAppNormal bg-white inputContainer ">
                <div className="col-xl-4 pt-2 ">
                  <p className="pt-0   stepContentTitle textPri2 ">Basic</p>
                  <div className="pt-4">
                    <this.alignData
                      label="Bed Rooms"
                      value={
                        this.state.bedRoom === "" ? "-" : this.state.bedRoom
                      }
                    />
                    <this.alignData
                      label="Half Rooms"
                      value={
                        this.state.halfRoom === "" ? "-" : this.state.halfRoom
                      }
                    />
                    <this.alignData
                      label="Full Rooms"
                      value={
                        this.state.fullRoom === "" ? "-" : this.state.fullRoom
                      }
                    />
                    <this.alignData
                      label="Attached Bathrooms"
                      value={
                        this.state.bathroomAttached === ""
                          ? "-"
                          : this.state.bathroomAttached
                      }
                    />
                    <this.alignData
                      label="Separate Bathrooms"
                      value={
                        this.state.bathroomSeparate === ""
                          ? "-"
                          : this.state.bathroomSeparate
                      }
                    />
                    <this.alignData
                      label="Area Sq.ft"
                      value={
                        this.state.sqFeet === ""
                          ? "-"
                          : this.state.sqFeet + " sq.ft"
                      }
                    />
                    <this.alignData
                      label="Listing Heading"
                      value={
                        this.state.listingHeading === ""
                          ? "-"
                          : this.state.listingHeading
                      }
                    />

                    <br></br>
                  </div>
                  <p className="pt-0   stepContentTitle textPri2 ">Lease</p>
                  <br></br>
                  <this.alignData
                    label="Monthly Rent"
                    value={
                      this.state.monthlyRent === "" ? (
                        "-"
                      ) : (
                        <CurrencyFormat value={this.state.monthlyRent} />
                      )
                    }
                  />
                  <this.alignData
                    label="Security Deposit"
                    value={
                      this.state.securityDeposit === "" ? (
                        "-"
                      ) : (
                        <CurrencyFormat value={this.state.securityDeposit} />
                      )
                    }
                  />
                  <this.alignData
                    label="Lease Duration"
                    value={
                      this.state.leaseDuration === "don’tSpecifyLease"
                        ? "-"
                        : this.state.leaseMonth
                    }
                  />

                  <this.alignData
                    label="When is it available?"
                    value={
                      this.state.whenAvailable === "don’tSpecifyDate"
                        ? "-"
                        : convertLocalDate(this.state.availableFrom)
                    }
                  />
                  <br></br>

                  <p className="pt-0   stepContentTitle textPri2 ">Cooling</p>
                  <br></br>
                  <this.alignData
                    label="Central"
                    value={this.state.coolingCentral ? "checked" : "unChecked"}
                  />
                  <this.alignData
                    label="Evaporative"
                    value={
                      this.state.coolingEvaporative ? "checked" : "unChecked"
                    }
                  />
                  <this.alignData
                    label="Geothermal"
                    value={
                      this.state.coolingGeothermal ? "checked" : "unChecked"
                    }
                  />
                  <this.alignData
                    label="Refrigeration"
                    value={
                      this.state.coolingRefrigeration ? "checked" : "unChecked"
                    }
                  />
                  <this.alignData
                    label="Solar"
                    value={this.state.coolingSolar ? "checked" : "unChecked"}
                  />
                  <this.alignData
                    label="Wall"
                    value={this.state.coolingWall ? "checked" : "unChecked"}
                  />
                  <this.alignData
                    label="Other"
                    value={this.state.coolingOther ? "checked" : "unChecked"}
                  />
                </div>
                <div className="col-md">
                  <div className="row pt-2">
                    <div className="col-lg-6">
                      <p className="pt-0   stepContentTitle textPri2">
                        {" "}
                        Outdoor Space
                      </p>
                      <br></br>
                      <this.alignData
                        label="Shared Yard"
                        value={this.state.sharedYard ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Private Yard"
                        value={this.state.privateYard ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Patio"
                        value={this.state.patio ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Balcony"
                        value={this.state.balcony ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Garden"
                        value={this.state.garden ? "checked" : "unChecked"}
                      />
                      <br></br>
                      <p className="pt-0   stepContentTitle textPri2"> Parking</p>
                      <br></br>
                      <this.alignData
                        label="Garage"
                        value={this.state.garage ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="On Street"
                        value={this.state.onStreet ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Driveway"
                        value={this.state.driveway ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Private Lot"
                        value={this.state.privateLot ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Dedicated Spot"
                        value={
                          this.state.dedicatedSpot ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Covered"
                        value={this.state.covered ? "checked" : "unChecked"}
                      />
                      <br></br>

                      <p className="pt-0   stepContentTitle textPri2 ">Heating</p>
                      <br></br>
                      <this.alignData
                        label="Baseboard"
                        value={
                          this.state.heatingBaseboard ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="ForcedAir"
                        value={
                          this.state.heatingForcedAir ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Geothermal"
                        value={
                          this.state.heatingGeothermal ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Heat pump"
                        value={
                          this.state.heatingHeatPump ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Radiant"
                        value={
                          this.state.heatingRadiant ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Stove"
                        value={
                          this.state.heatingStove ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Wall"
                        value={this.state.heatingWall ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Other"
                        value={
                          this.state.heatingOther ? "checked" : "unChecked"
                        }
                      />
                    </div>
                    <div className="col-lg-6 pt-4">
                      <this.alignData
                        label="Pet policy"
                        value={
                          this.state.petPolicy === "petsNegotiable"
                            ? "Pets Negotiable"
                            : this.state.petPolicy === "noPets"
                            ? "No Pets"
                            : this.state.petPolicy === "don'tSpecify"
                            ? "Don't Specify"
                            : this.state.petPolicy === "smallDogsCats"
                            ? "Small Dogs,Cats"
                            : "Pets OK"
                        }
                      />
                      <this.alignData
                        label="Washer/Dryer"
                        value={
                          this.state.washerDrier === "inUnit"
                            ? "In Unit"
                            : this.state.washerDrier === "onSite"
                            ? "On Site"
                            : "None"
                        }
                      />
                      <this.alignData
                        label="Laundry"
                        value={
                          this.state.laundryType === "inUnit"
                            ? "In Unit"
                            : this.state.laundryType === "onSite"
                            ? "On Site"
                            : "None"
                        }
                      />
                      <this.alignData
                        label="Basement"
                        value={
                          this.state.basement === "finished"
                            ? "Finished"
                            : this.state.basement === "unFinished"
                            ? "Unfinished"
                            : "None"
                        }
                      />
                      <br></br>

                      <p className="pt-0   stepContentTitle textPri2"> Appliances</p>

                      <br></br>
                      <this.alignData
                        label="Dishwasher"
                        value={this.state.dishwasher ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Microwave"
                        value={this.state.microwave ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Refrigerator"
                        value={
                          this.state.refrigerator ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Range / Oven"
                        value={this.state.oven ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Freezer"
                        value={this.state.freezer ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Trash Compactor"
                        value={
                          this.state.trashCompactor ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Garbage Disposal"
                        value={
                          this.state.garbageDisposal ? "checked" : "unChecked"
                        }
                      />
                      <br></br>
                      <p className="pt-0   stepContentTitle textPri2"> Others</p>
                      <br></br>
                      <this.alignData
                        label="Gym/Fitness Center"
                        value={this.state.gym ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Air Conditioning"
                        value={
                          this.state.airConditioning ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Hardwood Floors"
                        value={
                          this.state.hardwoodFloors ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Fireplace"
                        value={this.state.fireplace ? "checked" : "unChecked"}
                      />

                      <this.alignData
                        label="Walk-In Closet"
                        value={
                          this.state.walkInCloset ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Furnisher"
                        value={this.state.furnisher ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Pool"
                        value={this.state.pool ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Hot Tub"
                        value={this.state.hotTub ? "checked" : "unChecked"}
                      />
                      <this.alignData
                        label="Wheelchair Accessible"
                        value={
                          this.state.wheelchairAccessible
                            ? "checked"
                            : "unChecked"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-0">
                  <p className="pt-0    stepContentTitle textPri2">
                    About the property
                  </p>
                  <br></br>
                </div>

                <div className="col-11 item-left pl-3 ml-3 mt-2">
                  <span
                    className="span6 row"
                    style={{ textTransform: "capitalize" }}
                  >
                    {this.state.description === ""
                      ? "-"
                      : this.state.description}
                  </span>
                </div>

                <div className="col-12 pt-4">
                  <div className="row">
                    <div className="col-xl-4">
                      <p className="pt-0   stepContentTitle textPri2 ">Screening</p>
                      <br></br>

                      <this.alignData
                        label="Security Check"
                        value={
                          this.state.securityCheck ? "checked" : "unChecked"
                        }
                      />
                      <this.alignData
                        label="Background Check"
                        value={
                          this.state.backgroundCheck ? "checked" : "unChecked"
                        }
                      />
                      <br></br>
                    </div>
                    <div className="col-xl-8 mb-3">
                      <p className="pt-0   stepContentTitle textPri2 ">
                        Tour Availability
                      </p>
                      <br></br>
                      <span
                        className="span6 row mt-1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {this.state.viewingDay[0] !== "" &&
                          this.state.viewingDay.map((day, index) => (
                            <div className="col-6 pt-1">
                              <div className="row">
                                <div className="col-5">
                                  {this.state.viewingDay[index]}
                                </div>
                                <div className="col-7">
                                  - &nbsp;&nbsp;&nbsp;
                                  {this.timeConvert(
                                    this.state.viewingTime1[index]
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        {this.state.viewingDay[0] === "" && (
                          <span className="span6 pull-left pt-1 pl-3">
                            Not Specified
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12 text-center">
                  <p className="pt-0   stepContentTitle textPri2 ">Property Images</p>
                  <br></br>
                  {this.state.images.length > 0 && (
                    <>
                      <br></br>
                      <Gallery
                        images={imgList}
                        enableLightbox={true}
                        // isOpen={this.state.isOpen}
                        enableImageSelection={false}
                        currentImageWillChange={this.onCurrentImageChange}
                        customControls={[
                          <button
                            key="deleteImage"
                            className="agreement  mr-2 ml-2 pri2"
                            data-target="#deleteImage"
                            data-toggle="modal"
                            onClick={() => this.setState({ deleteImage: true })}
                            style={{
                              marginTop: "50px",
                              height: "40px",
                              zIndex: "4500",
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </button>,
                        ]}
                        backdropClosesModal={true}
                      />
                    </>
                  )}
                  {this.state.images.length === 0 && (
                    <span className="span6 pull-left pt-2">
                      Images not uploaded
                    </span>
                  )}
                  <br></br>
                  <br></br>
                </div>
              </div>
            </>
          )}

          <SmallModalContainer
            name="openUnit"
            dark={this.props.dark}
            title="Do you want to open this unit now?"
            confirmCommand="Open Now"
            confirm={() => this.handleFinish("OPEN")}
            reject={() => this.handleFinish("INACTIVE")}
            rejectCommand="Later"
            rejectCheck="true"
            size="lg"
          >
            <div className="p-2 pull-left text-left">
              <span className="span7" style={{ fontSize: "16px" }}>
                If you want to start receiving applications for this unit,
                please click Open now. You also can open or close it later.
              </span>
            </div>
          </SmallModalContainer>
          <SmallModalContainer
            name="cancel"
            dark={this.props.dark}
            title="Leave site?"
            confirmCommand="Leave"
            confirm={() => this.cancel()}
            reject={() => this.setState({ leave: false })}
            rejectCommand="Cancel"
            rejectCheck="true"
          >
            <div className="p-2 pull-left text-left">
              <span className="span7" style={{ fontSize: "16px" }}>
                Changes that you made may not be saved.
              </span>
            </div>
          </SmallModalContainer>
          {this.state.deleteImage && (
            <SmallModalContainer
              name="deleteImage"
              dark={this.props.dark}
              title="Confirm Delete?"
              confirmCommand="Delete"
              confirm={this.deleteImage}
              reject={() => this.setState({ deleteImage: false })}
              rejectCommand="Cancel"
              rejectCheck="true"
            >
              <div className="p-2 pull-left text-left">
                <span className="span7" style={{ fontSize: "16px" }}>
                  Are you sure you want to delete this image?
                </span>
              </div>
            </SmallModalContainer>
          )}

          {this.state.footer && (
            <div className="row appFootButton text-right mt-5">
              <div className="col ">
                {this.state.type !== "edit-listing" && (
                  <button
                    className="pri mr-3"
                    data-target="#cancel"
                    data-toggle="modal"
                  >
                    Cancel
                  </button>
                )}
                {this.state.nowTab !== 1 && (
                  <button className="mr-3 pri" onClick={() => this.back()}>
                    Back
                  </button>
                )}

                {this.state.type === "edit-listing" && (
                  <button
                    className="pri mr-3 "
                    onClick={() =>
                      this.props.history.push({
                        pathname:
                          "/properties/" +
                          this.props.location.state.propsId +
                          "/listings",
                        state: {
                          id: this.props.location.state.propsId,
                          tab: "Open",
                          message: null,
                        },
                      })
                    }
                  >
                    Discard
                  </button>
                )}
                {this.state.nowTab !== 6 &&
                  this.state.type !== "edit-listing" &&
                  (this.state.nextEnable ? (
                    <button className="buttonPri " onClick={() => this.next()}>
                      Next
                    </button>
                  ) : (
                    <button className="secDisable " onClick={() => this.next()}>
                      Next
                    </button>
                  ))}
                {this.state.type === "edit-listing" && (
                  <button
                    className={
                      this.state.nowTab !== 6 ? "pri mr-3 " : "mr-3 buttonPri"
                    }
                    onClick={() =>
                      this.handleFinish(
                        this.props.location.state.data.rentalEntity.status
                      )
                    }
                  >
                    Update
                    {this.state.loading && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                )}

                {this.state.nowTab === 6 && this.state.type !== "edit-listing" && (
                  <button
                    className={
                      this.state.loading ? "secDisable notAllow" : " buttonPri"
                    }
                    data-target="#openUnit"
                    data-toggle="modal"
                    disabled={this.state.loading && "disabled"}
                  >
                    Publish
                    {this.state.loading && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                )}
                {this.state.nowTab !== 6 &&
                  this.state.type === "edit-listing" &&
                  (this.state.nextEnable ? (
                    <button className="buttonPri " onClick={() => this.next()}>
                      Next
                    </button>
                  ) : (
                    <button className="secDisable " disabled="disabled">
                      Next
                    </button>
                  ))}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default withRouter(GetStepContent);

const MyUploader = React.memo((props) => {
  const handleChangeStatus = async ({ file }, status, allFiles) => {
    if (status === "preparing") {
      props._handleUpload(allFiles);
    }
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
    }
  };

  return (
    <Dropzone
      inputContent={
        <div
          className="dragDrop text-center pointer"
          style={{
            fontSize: "60px",
            minHeight: "200px",
            paddingTop: "40px",
            marginBottom: "40px",
          }}
        >
          <div className="row">
            <div className="col">
              <i
                className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="licenseHead">Drag Or Click to Upload</p>
            </div>
          </div>
        </div>
      }
      inputWithFilesContent="Upload more"
      onChangeStatus={handleChangeStatus}
      accept=".jpeg,.png,.jpg"
      submitButtonContent="Upload"
    />
  );
});
