import React, { Component } from 'react';
import './search.css';

class Search extends Component {
  state={search:0}
  render() { 
    return ( 
        <div className="row pull-right pr-3">
          <div className={this.handleGetSearchStyle()} >
            <input className="searchInput2" type="text" name="name" placeholder="Search" aria-label="Search" />
            <div className={this.handleGetSearchSubmitStyle()}>
              <label  onClick={()=> this.changeSearch()} className="nav-link searchInsideButton1" ><i className="fa fa-search " aria-hidden="true"></i> <span className="sr-only">(current)</span></label>
            </div>
          </div>
          <div className=" navItemSearch1">
            <label onClick={()=> this.changeSearch()}  className="nav-link"><i className={this.handleSearchIcon()} aria-hidden="true"></i> <span className="sr-only">(current)</span></label>
          </div>
        </div>
        
        
      
    );
  }
  changeSearch=()=>{
    this.setState({search:this.state.search===0?1:0});
  }
  handleGetSearchSubmitStyle() {
    let classes = "";
    classes += this.state.search === 0 ? " navItemSearch1 hide" :" navItemSearch1 p-0";
    return classes;
  }
  handleGetSearchStyle() {
    let classes = "";
    classes += this.state.search === 0 ? " hide" :"searchBoxOpen searchForm1";
    return classes;
  }
  handleGetSearchCloseStyle(){
    let classes = "";
    classes += this.state.search === 0 ? " hide" : " show";
    return classes;
  }
  handleSearchIcon(){
    let classes = "";
    classes += this.state.search === 0 ? " fa fa-search " : " fa fa-times ";
    return classes;
  }
}
export default Search;