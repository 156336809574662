import React, { Component } from "react";
import "../maintenance.css";
import "./MaintenanceRequestCard.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  appendAlert,
  setMaintenance,
  appendTempUsers,
  appendTempProfileImage,
  getTempProfileImage,
} from "../../../../actions";
import { exceptNameNull, sortText } from "../../../../Defined/Function";
import { url, userDetail } from "../../../../Services/ApiServices";
import image from "../../../../Images/profile.png";
import imageLoader from "../../../../Images/imageLoader.gif";

class MaintenanceHistoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tenantDetails: [],
      landlordDetails: [],
      profileImage: imageLoader,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    if (this.props.roleStatus !== "Tenant") {
      if (this.props.request.tenantId !== undefined) {
        let usersTenant = this.props.tempUsersArray.filter(
          (todo) => todo.username === this.props.request.tenantId
        );
        if (usersTenant.length === 0) {
          this.getTenantDetails();
        } else {
          let usersImage = this.props.tempProfileImagesArray.filter(
            (todo) => todo.username === this.props.request.tenantId
          );
          if (usersImage.length === 0) {
            this.setImage(usersTenant[0]);
          } else {
            this.setState({ profileImage: usersImage[0].image });
          }
          this.setState({ tenantDetails: usersTenant[0] });
        }
      }
    } else {
      this.setState({ tenantDetails: this.props.userDetailArray });
    }
    if (this.props.roleStatus !== "Landlord") {
      if (this.props.request.landlordId !== undefined) {
        let usersLandlord = this.props.tempUsersArray.filter(
          (todo) => todo.username === this.props.request.landlordId
        );
        if (usersLandlord.length === 0) {
          this.getLandlordDetails();
        } else {
          let usersImage = this.props.tempProfileImagesArray.filter(
            (todo) => todo.username === this.props.request.landlordId
          );
          if (usersImage.length === 0) {
            this.setImage(usersLandlord[0]);
          } else {
            this.setState({ profileImage: usersImage[0].image });
          }
          this.setState({ landlordDetails: usersLandlord[0] });
        }
      }
    } else {
      this.setState({ landlordDetails: this.props.userDetailArray });
    }
  }
  getTenantDetails() {
    try {
      userDetail(this.props.request.tenantId).then((response) => {
        if (response.status) {
          this.props.appendTempUsers(response.data);
          this.setState({ tenantDetails: response.data });
          this.setImage(response.data);
        }
      });
    } catch (error) {}
  }
  getLandlordDetails() {
    try {
      userDetail(this.props.request.landlordId).then((response) => {
        if (response.status) {
          this.props.appendTempUsers(response.data);
          this.setState({ landlordDetails: response.data });
          this.setImage(response.data);
        }
      });
    } catch (error) {}
  }
  fetchImage(username, url) {
    const toDataURL = (url1) =>
      fetch(url1)
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          } else {
            throw new Error(response);
          }
        })
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        )
        .catch((res) => {
          throw new Error(res);
        });

    toDataURL(url)
      .then((dataUrl) => {
        this.setState({
          profileImage: dataUrl,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: dataUrl,
        });
      })
      .catch((res) => {
        this.setState({
          profileImage: image,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: image,
        });
      });
  }
  setImage(data) {
    if (data.provider === "" || data.provider === null) {
      this.setState({ profileImage: image });
      this.props.appendTempProfileImage({
        username: data.username,
        image: image,
      });
    } else if (data.provider === "local") {
      if (data.coverImage === null) {
        this.setState({ profileImage: image });
      } else {
        this.fetchImage(
          data.username,
          url + `api/user/${data.username}/image/download`
        );
      }
    } else {
      this.fetchImage(data.username, data.coverImage);
    }
  }
  render() {
    return (
      <div className="col-md-6 col-lg-6 col-xl-4 p-3 ">
        <div
          className={"maintenanceCard pointer pb-3"}
          onClick={() =>
            this.props.history.push({
              pathname: "/maintenance/" + this.props.request.id,
              state: {
                data: this.props.request,
                tenantDetails: this.state.tenantDetails,
                landlordDetails: this.state.landlordDetails,
                contractData: this.props.contractData,
                unitData: this.props.unitData,
              },
            })
          }
        >
          <h1 className="maintenanceRequestHead pt-1">
            {this.props.request.category +
              " - " +
              this.props.request.subCategory}
          </h1>

          <div className="row pl-2 pr-2 pt-2 ">
            <div className="MaintenanceRequestCardProfile">
              <img
                alt="tenant"
                src={
                  this.state.profileImage
                    ? this.state.profileImage
                    : imageLoader
                }
                className="profileAvatar"
              />
            </div>
            <div className="MaintenanceRequestCardName ">
              {this.props.roleStatus === "Landlord" ? (
                <>
                  <h1 className="AxText5 textLeft">
                    {exceptNameNull(
                      this.state.tenantDetails.firstName,
                      this.state.tenantDetails.lastName
                    ) !== "-"
                      ? sortText(
                          exceptNameNull(
                            this.state.tenantDetails.firstName,
                            this.state.tenantDetails.lastName
                          ),
                          0,
                          17
                        )
                      : this.props.request.tenantId}
                  </h1>
                  <h3 className="AxText7 textLeft">Tenant</h3>
                </>
              ) : (
                <>
                  <h1 className="AxText5 textLeft">
                    {exceptNameNull(
                      this.state.landlordDetails.firstName,
                      this.state.landlordDetails.lastName
                    ) !== "-"
                      ? sortText(
                          exceptNameNull(
                            this.state.landlordDetails.firstName,
                            this.state.landlordDetails.lastName
                          ),
                          0,
                          17
                        )
                      : this.props.request.landlordId}
                  </h1>
                  <h3 className="AxText7 textLeft">Landlord</h3>
                </>
              )}
            </div>
          </div>
          {this.props.request.status === "incomplete" ? (
            <h1 className="AxText5 textPri textUpperCase pt-2 ">Incomplete</h1>
          ) : this.props.request.status === "complete" ? (
            <h1 className="AxText5 textSuccess textUpperCase pt-2">Complete</h1>
          ) : this.props.request.status === "canceled" ? (
            <h1 className="AxText5 textWarning textUpperCase pt-2">Canceled</h1>
          ) : (
            <h1 className="AxText5 textPri textUpperCase pt-2"> </h1>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    role: state.RoleReducer,
    roleStatus: state.role,
    user: state.userReducer,
    userDetailArray: state.user,
    tempProfileImages: state.tempProfileImagesReducer,
    tempProfileImagesArray: state.tempProfileImages,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setMaintenance,
    appendTempUsers,
    appendTempProfileImage,
    getTempProfileImage,
  })(MaintenanceHistoryCard)
);
