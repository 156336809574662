import React, { Component } from "react";
import NoCov from "../../../Images/no-cover-img.jpg";
import propertyLoader from "../../../Images/propertyLoader.gif";
import "./PropertyCard.css";
import Loader from "../../Loader/Loader";
import {
  allowOnPropertyForUs,
  removeOnPropertyForUs,
  url,
} from "../../../Services/ApiServices";
import SmallModalContainer from "../Documents/SmallModalContainer";
import { capitalizeFirstLetter, sortText } from "../../../Defined/Function";
import { connect } from "react-redux";
import { appendAlert, appendTempPropertyImage } from "../../../actions";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

class PropertyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      // redirect: false,
      expanded: false,
      anchorEl: null,
      landlords: this.props.property.landlords,
      date: this.date(this.props.property.createdAt),
      propImage: propertyLoader,
      manage: false,
      deleteModal: false,
      allowAx: false,
      removeAx: false,
    };
    this.handleExpandClick = this.handleExpandClick.bind(this);
    this.handleLLCheck = this.handleLLCheck.bind(this);
  }


  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  delete(property, index) {
    this.setState({ anchorEl: null });
    this.props._handleDelete(property, index);
  }

  async allowAxallantOnProperty(propertyId) {
    try {
      await allowOnPropertyForUs(propertyId).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.state.landlords.push("landlord1.demo@gmail.com");
            this.props.appendAlert("Access give to Axallant", "success");
            this.handleClose();
            this.setState({ manage: true });
          } else {
            this.props.appendAlert("Try again later...", "danger");
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
      this.setState({ allowAx: false });
    } catch (error) {
      console.error(error);
      this.setState({ allowAx: false });
    }
  }

  async removeAxallantOnProperty(propertyId) {
    try {
      await removeOnPropertyForUs(propertyId).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.state.landlords.splice("landlord1.demo@gmail.com");
            this.props.appendAlert("Access removed from Axallant", "success");
            this.handleClose();
            this.setState({ manage: false });
          } else {
            this.props.appendAlert("Try again later ...", "danger");
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
      this.setState({ removeAx: false });
    } catch (error) {
      console.error(error);
      this.setState({ removeAx: false });
    }
  }
  handleLLCheck() {
    this.setState({
      manage: this.state.landlords.includes("landlord1.demo@gmail.com"),
    });
  }

  handleExpandClick() {
    this.setState({ expanded: !this.state.expanded });
  }
  date(date) {
    let a = new Date(date);

    let year = a.getFullYear();
    let month = a.getMonth() + 1;
    let date1 = a.getDate();
    let date2 ;

    if (parseInt(date1) < 10) {
      date2 = "0" + date1.toString();
    } else {
      date2 = date1;
    }

    let time = month + "-" + date2 + "-" + year;
    return time;
  }
  componentDidMount() {
    this.filterImage(this.props.property.id);
    this.handleLLCheck();
  }
  fetchImage(id, url) {
    try {
      const toDataURL = (url1) =>
        fetch(url1)
          .then((response) => {
            if (response.status === 200) {
              return response.blob();
            } else {
              throw new Error(response);
            }
          })
          .then(
            (blob) =>
              new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
          )
          .catch((res) => {
            throw new Error(res);
          });

      toDataURL(url)
        .then((dataUrl) => {
          this.setState({ propImage: dataUrl });
          this.props.appendTempPropertyImage({
            id: id,
            image: dataUrl,
          });
        })
        .catch((res) => {
          this.setState({ propImage: NoCov });
          this.props.appendTempPropertyImage({
            id: id,
            image: NoCov,
          });
        });
    } catch (error) {
      this.setState({ propImage: NoCov });
    }
  }
  setImage(id) {
    if (id === "" || id === null) {
      this.setState({ propImage: NoCov });
      this.props.appendTempPropertyImage({
        id: id,
        image: NoCov,
      });
    } else {
      this.fetchImage(id, url + `api/property/${id}/image/download`);
    }
  }
  filterImage(id) {
    let propertyImage = this.props.tempPropertyImagesArray.filter(
      (todo) => todo.id === id
    );
    if (propertyImage.length === 0) {
      this.setImage(id);
    } else {
      this.setState({ propImage: propertyImage[0].image });
    }
  }

  render() {
    return (
      <>
        <div className="propertyCardPro">
          <div className="propsImageCover pointer d-flex justify-content-center">
            {this.props.property ? "" : <Loader />}
            { this.props.property.coverImage ? (
              <>
                <img
                  className="propsImagePro"
                  alt="property"
                  src={this.state.propImage}
                  onClick={() =>
                    this.props.property.rentalEntity.length!==0&&this.props.history.push({
                      pathname:
                        "/properties/" + this.props.property.id + "/listings",
                      state: {
                        id: this.props.property.id,
                        tab: "Incomplete",
                        message: null,
                      },
                    })
                  }
                />
                {this.props.property.rentalEntity.length!==0&&<button
                  className=" absolute viewListingButton"
                  onClick={() =>
                    this.props.history.push({
                      pathname:
                        "/properties/" + this.props.property.id + "/listings",
                      state: {
                        id: this.props.property.id,
                        tab: "Incomplete",
                        message: null,
                      },
                    })
                  }
                >
                  {" "}
                  View Units
                </button>}
              </>
            ) : (
              <>
                <img
                  className="propsImagePro"
                  src={NoCov}
                  alt="propsImage"
                  onClick={() =>
                    this.props.property.rentalEntity.length!==0&&this.props.history.push({
                      pathname:
                        "/properties/" + this.props.property.id + "/listings",
                      state: {
                        id: this.props.property.id,
                        tab: "Incomplete",
                        message: null,
                      },
                    })
                  }
                />
                {this.props.property.rentalEntity.length!==0&&<button
                  className=" absolute viewListingButton"
                  onClick={() =>
                    this.props.history.push({
                      pathname:
                        "/properties/" + this.props.property.id + "/listings",
                      state: {
                        id: this.props.property.id,
                        tab: "Incomplete",
                        message: null,
                      },
                    })
                  }
                >
                  {" "}
                  View Units
                </button>}
              </>
            )}
          </div>
          <div className=" propCoverContentPro ">
            <div className="row propCoverContentPro1 pl-3 ">
              <div className="col-12">
                {this.state.manage ? (
                  <th>
                    <div className="textPri">Manage by Axallant</div>
                  </th>
                ) : (
                  <th>
                    <div style={{ color: "rgb(94, 94, 94)" }}>
                      Manage by landlord
                    </div>
                  </th>
                )}
              </div>
              <div className="col-8 propsContent pb-0">
                <div className="row">
                  <div className="col-8 text-left">
                    <span className="propsContent2">Available Units </span>
                  </div>
                  <div className="col-4">
                    <span className="propsContent2">
                      :&nbsp;&nbsp;
                      {
                        this.props.property.rentalEntity.filter(
                          (unit) =>
                            unit.status === "INCOMPLETE" ||
                            unit.status === "OPEN"
                        ).length
                      }
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-8 text-left">
                    <span className="propsContent2"> Number of Units </span>
                  </div>
                  <div className="col-4">
                    <span className="propsContent2">
                      :&nbsp;&nbsp;{this.props.property.rentalEntity.length}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-4 propsContent ">
                <div className="row">
                  <svg
                    version="1.0"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12px"
                    height="12px"
                    viewBox="0 0 980.000000 980.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,980.000000) scale(0.100000,-0.100000)"
                      fill="#616161"
                      stroke="none"
                    >
                      <path
                        d="M4832 9768 c-29 -13 -1086 -543 -2350 -1178 -2474 -1244 -2350 -1178
-2402 -1285 -35 -74 -34 -180 2 -255 15 -30 42 -70 61 -88 29 -28 761 -430
3921 -2150 390 -212 725 -391 745 -398 45 -16 123 -17 176 -3 22 6 481 249
1020 540 539 290 1581 852 2315 1247 735 397 1354 736 1377 756 23 20 54 62
70 94 37 75 39 182 6 254 -49 107 67 44 -2273 1232 -1191 605 -2231 1133
-2311 1174 -139 72 -149 75 -225 79 -66 3 -88 0 -132 -19z m2067 -1613 c1061
-539 1930 -982 1930 -985 0 -3 -844 -459 -1876 -1015 -1032 -555 -1915 -1031
-1962 -1057 l-86 -46 -120 65 c-300 163 -3382 1842 -3563 1940 l-204 111 1609
808 c884 444 1766 888 1958 986 193 98 357 177 366 176 9 -2 886 -444 1948
-983z"
                      />
                      <path
                        d="M623 5432 c-335 -168 -503 -258 -528 -282 -92 -89 -118 -237 -63
-350 15 -30 42 -70 60 -87 28 -27 820 -461 4224 -2314 461 -252 488 -263 590
-241 42 8 228 104 723 370 366 198 1330 717 2141 1154 811 438 1558 840 1660
894 199 107 250 147 290 229 36 75 35 180 -3 257 -16 32 -47 74 -70 94 -47 41
-953 506 -977 502 -23 -4 -649 -342 -643 -348 2 -2 173 -91 380 -196 351 -179
374 -193 352 -204 -13 -7 -896 -483 -1961 -1056 -1065 -574 -1941 -1044 -1946
-1044 -5 0 -731 393 -1613 873 -882 480 -1751 953 -1931 1051 -181 98 -328
181 -328 185 0 3 178 96 395 206 217 110 394 203 392 207 -3 11 -625 348 -640
347 -6 0 -233 -111 -504 -247z"
                      />
                      <path
                        d="M8393 3307 c-177 -94 -322 -174 -322 -177 0 -3 157 -81 349 -175 193
-93 350 -172 350 -175 0 -3 -114 -67 -252 -142 -139 -75 -1018 -549 -1953
-1053 -935 -505 -1705 -918 -1711 -918 -8 0 -3618 1959 -3837 2082 l-48 28
108 49 c59 28 228 104 375 170 147 65 267 121 267 124 0 3 -144 83 -321 179
l-321 174 -41 -15 c-23 -9 -233 -105 -468 -213 -273 -126 -441 -209 -467 -231
-22 -19 -52 -60 -68 -92 -23 -47 -28 -70 -28 -132 1 -97 29 -164 95 -223 33
-29 754 -427 2314 -1277 1247 -679 2290 -1245 2319 -1257 70 -30 164 -30 234
0 119 51 4638 2494 4679 2530 65 57 99 134 99 227 -1 66 -5 83 -34 138 -19 35
-51 76 -72 93 -32 25 -900 459 -918 459 -3 0 -151 -78 -328 -173z"
                      />
                    </g>
                  </svg>
                  &nbsp;&nbsp; &nbsp;
                  <span className="pt-0 mt-0">
                    {this.props.property.rentalEntity[0] ?
                      (capitalizeFirstLetter(
                        this.props.property.rentalEntity[0].unitType
                      ) === ""
                        ? "Single"
                        : capitalizeFirstLetter(
                            this.props.property.rentalEntity[0].unitType
                          )):"-"}
                  </span>
                </div>
                <div className="row">
                  <span className="propsContent">
                    <i
                      className="fa fa-building-o fa-7x "
                      aria-hidden="true"
                    ></i>{" "}
                    &nbsp;&nbsp; &nbsp;
                    {this.props.property.type === "APPARTMENT_BUILDING"
                      ? "Apartment"
                      : this.props.property.type === "SINGLE_HOME"
                      ? "Home"
                      : this.props.property.type === "OFFICE"
                      ? "Office"
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="propertyHead  pr-3 pl-3 ">
            <div className="row">
              <div className="col-11 pt-2 ">
                <h4 className="propsAddressHead text-left">
                  {sortText(
                    this.props.property.address.address +
                      ", " +
                      this.props.property.address.city,
                    0,
                    30
                  )}
                </h4>
                <h4 className="propsAddress text-left pb-1">
                  {sortText(
                    this.props.property.address.state +
                      ", " +
                      this.props.property.address.country,
                    0,
                    30
                  )}
                </h4>
              </div>
              <div className="">
                <div className="btn-group pointer" title="Property menu">
                  <div
                    className="propsMenuPro  "
                    aria-hidden="true"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <svg
                      version="1.0"
                      className="propsMenuPro1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      viewBox="0 0 512.000000 512.000000"
                      preserveAspectRatio="xMidYMid meet"
                    >
                      <g
                        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                        fill="#000000"
                        stroke="none"
                      >
                        <path
                          d="M1604 4869 c-201 -23 -407 -137 -539 -296 -62 -76 -135 -210 -160
-295 l-16 -58 -372 0 c-357 0 -372 -1 -415 -21 -106 -52 -132 -188 -54 -281
48 -57 56 -58 466 -58 l374 0 22 -72 c43 -142 115 -254 233 -364 151 -140 312
-209 517 -221 346 -20 656 165 806 480 20 42 41 98 48 124 l12 48 1240 5 c913
4 1244 8 1259 17 52 30 95 104 95 165 0 50 -37 116 -83 146 l-41 27 -1234 5
-1234 5 -23 72 c-82 259 -301 471 -565 546 -56 16 -207 38 -245 36 -11 -1 -52
-5 -91 -10z m219 -374 c87 -23 155 -61 218 -124 185 -185 186 -477 2 -664
-141 -144 -353 -181 -539 -93 -165 78 -265 239 -265 426 0 99 21 170 74 252
109 170 318 253 510 203z"
                        />
                        <path
                          d="M3237 3410 c-108 -23 -267 -98 -352 -167 -123 -100 -223 -247 -271
-398 l-22 -70 -1234 -5 c-1221 -5 -1233 -5 -1268 -26 -117 -68 -117 -240 0
-308 35 -21 47 -21 1268 -26 l1233 -5 27 -80 c90 -265 292 -458 567 -541 77
-23 109 -27 220 -27 152 -1 225 15 353 74 227 105 390 291 457 523 l16 56 377
0 c372 0 377 1 417 23 125 71 125 243 0 314 -40 22 -45 23 -416 23 l-377 0
-26 83 c-68 218 -253 419 -469 510 -155 65 -336 82 -500 47z m318 -370 c203
-70 326 -240 326 -450 0 -133 -44 -240 -138 -333 -91 -91 -202 -137 -331 -137
-365 0 -591 395 -406 710 57 97 157 177 264 211 78 25 210 25 285 -1z"
                        />
                        <path
                          d="M1565 1909 c-157 -26 -308 -103 -434 -223 -108 -104 -180 -218 -221
-354 l-22 -72 -374 0 c-410 0 -418 -1 -466 -58 -78 -93 -52 -229 54 -281 43
-20 58 -21 415 -21 l372 0 16 -57 c46 -158 156 -319 287 -419 414 -319 1003
-197 1253 259 21 38 48 101 60 140 l23 72 1234 5 1234 5 41 27 c46 30 83 96
83 146 0 61 -43 135 -95 165 -15 9 -346 13 -1259 17 l-1240 5 -12 48 c-17 68
-77 190 -128 262 -181 252 -509 386 -821 334z m291 -380 c333 -106 433 -531
185 -780 -249 -248 -666 -148 -782 189 -14 39 -20 84 -20 142 0 187 100 348
265 426 115 55 229 62 352 23z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="dropdown-menu pr-2 pl-2 dropdown-menu-right ">
                    {this.state.manage ? (
                      <span
                        className="dropdown-item click span2"
                        onClick={() => this.setState({ removeAx: true })}
                        data-target="#removeAx"
                        data-toggle="modal"
                      >
                        Remove Axallant{" "}
                      </span>
                    ) : (
                      <span
                        className="dropdown-item click span2"
                        onClick={() => this.setState({ allowAx: true })}
                        data-target="#allowAx"
                        data-toggle="modal"
                      >
                        Allow Axallant to Manage
                      </span>
                    )}

                    <span
                      className="dropdown-item click span2"
                      onClick={() =>this.state.manage?
                        this.props.history.push({
                          pathname:
                            "/properties/" +
                            this.props.property.id +
                            "/edit-property-by-axallant",
                          state: {
                            data: this.props.property,
                            type: "edit",
                          },
                        }):this.props.history.push({
                          pathname:
                            "/properties/" +
                            this.props.property.id +
                            "/edit-property",
                          state: {
                            data: this.props.property,
                            type: "edit",
                          },
                        })
                      }
                    >
                      Edit Property
                    </span>
                    <span
                      className="dropdown-item click span2 text-danger"
                      onClick={() => this.setState({ deleteModal: true })}
                      data-target="#deleteProperty"
                      data-toggle="modal"
                    >
                      {" "}
                      Delete Property
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.allowAx && (
            <SmallModalContainer
              name="allowAx"
              dark={this.props.dark}
              title="Confirmation"
              size="lg"
              confirmCommand={"Ok"}
              confirm={() =>
                this.allowAxallantOnProperty(this.props.property.id)
              }
              reject={() => this.setState({ allowAx: false })}
              rejectCommand="Cancel"
              rejectCheck="true"
            >
              <div className="p-2 pr-4 pl-4 pull-left text-left">
                <span
                  className="span7 "
                  style={{ fontSize: "16px" }}
                >
                  Are you sure you want to allow axallant to manage your
                  property?
                </span>
              </div>
            </SmallModalContainer>
          )}
          {this.state.removeAx && (
            <SmallModalContainer
              name="removeAx"
              dark={this.props.dark}
              title="Confirmation"
              size="lg"
              confirmCommand={"Ok"}
              confirm={() =>
                this.removeAxallantOnProperty(this.props.property.id)
              }
              reject={() => this.setState({ removeAx: false })}
              rejectCommand="Cancel"
              rejectCheck="true"
            >
              <div className="p-2 pr-4 pl-4 pull-left text-left">
                <span
                  className="span7 "
                  style={{ fontSize: "16px" }}
                >
                  Are you sure you want to remove from axallant?
                </span>
              </div>
            </SmallModalContainer>
          )}
          {this.state.deleteModal && (
            <SmallModalContainer
              name="deleteProperty"
              dark={this.props.dark}
              title="Confirm Delete?"
              confirmCommand="Delete"
              confirm={() => this.delete(this.props.property)}
              reject={() => this.setState({ deleteModal: false })}
              rejectCommand="Cancel"
              rejectCheck="true"
            >
              <div className="p-2 pull-left text-left">
                <span className="span7" style={{ fontSize: "16px" }}>
                  Are you sure you want to delete this property?
                </span>
              </div>
            </SmallModalContainer>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    tempPropertyImages: state.tempPropertyImagesReducer,
    tempPropertyImagesArray: state.tempPropertyImages,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    appendTempPropertyImage,
  })(PropertyCard)
);
