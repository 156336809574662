import React, { Component } from "react";

import "../NavBar/NavBar.css";
import profileImage from "../../Images/profile.png";
import { timeShortCalculativeFormat } from "../../Defined/Function";

class NotificationPart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const data = this.props.data;
    const index = this.props.index;
    return (
      <>
        <div
          className={
            data.seen
              ? "row notificationWeb p-0 m-0 dropdown-item profileList pointer pt-2 pb-2"
              : "row notificationWeb p-0 m-0 dropdown-item profileList1 pointer pt-2 pb-2"
          }
          id="click"
          onClick={(e) => this.props.handleClick(data, index, e)}
        >
          <div
            className="col-1 p-0 m-0"
            style={{ marginLeft: "-330px", minWidth: "" }}
          >
            <img
              className="proImage "
              alt={data.actionBy}
              src={profileImage}
              style={{ marginLeft: "20px", marginRight: "15px" }}
            />
          </div>

          <div className="col p-0 m-0">
            <p className="applicationText">
              {" "}
              {data.rentalEnitiyAddress
                ? data.rentalEnitiyAddress
                : data.actionBy}
            </p>
            {this.props.deleteLoader ? (
              <span
                id="delete"
                className="badge DeleteNotificationDisable mr-3 ml-3 p-2 pull-right "
              >
                <i className="fa fa-trash-o" aria-hidden="true" id="delete"></i>
              </span>
            ) : (
              <span
                id="delete"
                className="badge DeleteNotification mr-3 ml-3 p-2 pull-right "
                onClick={(e) => this.props.handleDelete(data, e)}
              >
                <i
                  className="fa fa-trash-o"
                  aria-hidden="true"
                  id="delete"
                  onClick={(e) => this.props.handleDelete(data, e)}
                ></i>
              </span>
            )}
            <span className="pull-right mt-2">
              {timeShortCalculativeFormat(data.createdAt)}
            </span>
            <span
              className="badge appStatus mr-3 ml-3 pull-right  mt-2"
              style={{
                backgroundColor:
                  data.catogory === "application_received"
                    ? "#AEB6BF"
                    : data.catogory === "application_rejected"
                    ? "#E74C3C"
                    : data.catogory === "application_rejected_system"
                    ? "#E74C3C"
                    : data.catogory === "application_approved"
                    ? "#2ECC71"
                    : data.catogory === "application_canceled"
                    ? "#5499C7"
                    : data.catogory === "contract_created"
                    ? "#2ECC71"
                    : data.catogory === "contract_updated"
                    ? "#F4D03F"
                    : data.catogory === "contract_terminated"
                    ? "#E74C3C"
                    : data.catogory === "contract_approved"
                    ? "#2ECC71"
                    : data.catogory === "contract_canceled"
                    ? "#5499C7"
                    : data.catogory === "contract_accepted"
                    ? "#2ECC71"
                    : data.catogory === "backgroud_chk_received"
                    ? "#AEB6BF"
                    : data.catogory === "credit_chk_received"
                    ? "#AEB6BF"
                    : data.catogory === "viewing_req_received"
                    ? "#AEB6BF"
                    : data.catogory === "viewing_req_rejected"
                    ? "#E74C3C"
                    : data.catogory === "viewing_req_canceled"
                    ? "#5499C7"
                    : data.catogory === "viewing_req_approved"
                    ? "#2ECC71"
                    : data.catogory === "maintenance_req_posted"
                    ? "#AEB6BF"
                    : data.catogory === "maintenance_req_approved"
                    ? "#2ECC71"
                    : data.catogory === "maintenance_req_accepted"
                    ? "#2ECC71"
                    : data.catogory === "maintenance_req_porArranged"
                    ? "#F4D03F "
                    : data.catogory === "maintenance_req_finish"
                    ? "#F4D03F "
                    : data.catogory === "JobStatusPending"
                    ? "#F4D03F "
                    : data.catogory === "JobStatusVisited"
                    ? "#AEB6BF "
                    : data.catogory === "JobStatusJobstarted"
                    ? "#2ECC71 "
                    : data.catogory === "JobStatusCanceled"
                    ? "#F4D03F "
                    : data.catogory === "JobStatusFinished"
                    ? "#2ECC71 "
                    : data.catogory === "JobStatusRejected"
                    ? "#E74C3C "
                    : data.catogory === "ServiceRequestCreated"
                    ? "#2ECC71 "
                    : data.catogory === "ServiceRequestApproved"
                    ? "#2ECC71 "
                    : data.catogory === "ServiceRequestRejected"
                    ? "#E74C3C "
                    : data.catogory === "ServiceRequestCanceled"
                    ? "#F4D03F "
                    : data.catogory === "ServiceRequestUpdatedByTenant" ||
                      data.catogory === "ServiceRequestUpdatedByLandlord"
                    ? "#F4D03F "
                    : data.catogory === "ServiceRequestModificationRequested"
                    ? "#AEB6BF "
                    : data.catogory === "ProviderInvited"
                    ? "#AEB6BF "
                    : data.catogory === "QuotationReceived"
                    ? "#AEB6BF "
                    : data.catogory === "QuotationApproved"
                    ? "#2ECC71 "
                    : data.catogory === "QuotationRejected"
                    ? "#E74C3C "
                    : data.catogory === "QuotationModificationRequested"
                    ? "#AEB6BF "
                    : data.catogory === "QuotationUpdated"
                    ? "#F4D03F "
                    : data.catogory === "QuotationCanceled"
                    ? "#E74C3C "
                    : data.catogory === "JobAssigned"
                    ? "#2ECC71 "
                    : data.catogory === "JobAssignedUpdated"
                    ? "#F4D03F "
                    : data.catogory === "JobFinishedByProvider"
                    ? "#2ECC71 "
                    : data.catogory === "JobFinishedApprovedByTenant"
                    ? "#F4D03F "
                    : data.catogory === "JobCanceled"
                    ? "#E74C3C "
                    : data.catogory === "JobDelayRequest"
                    ? "#AEB6BF "
                    : data.catogory === "JobDelayNotified"
                    ? "#AEB6BF "
                    : "",
              }}
            >
              {data.catogory === "application_received"
                ? "Application received"
                : data.catogory === "application_rejected"
                ? "Application rejected"
                : data.catogory === "application_rejected_system"
                ? "Application rejected system"
                : data.catogory === "application_approved"
                ? "Application approved"
                : data.catogory === "application_canceled"
                ? "Application canceled"
                : data.catogory === "contract_created"
                ? "Contract created"
                : data.catogory === "contract_updated"
                ? "Contract updated"
                : data.catogory === "contract_terminated"
                ? "Contract terminated"
                : data.catogory === "contract_approved"
                ? "Contract approved"
                : data.catogory === "contract_canceled"
                ? "Contract canceled"
                : data.catogory === "contract_accepted"
                ? "Contract accepted"
                : data.catogory === "backgroud_chk_received"
                ? "Backgroud check report received"
                : data.catogory === "credit_chk_received"
                ? "Credit check report received"
                : data.catogory === "viewing_req_received"
                ? "Viewing time request received"
                : data.catogory === "viewing_req_rejected"
                ? "Viewing time request rejected"
                : data.catogory === "viewing_req_canceled"
                ? "Viewing time request canceled"
                : data.catogory === "viewing_req_approved"
                ? "Viewing time request approved"
                : data.catogory === "maintenance_req_posted"
                ? "Maintenance request received"
                : data.catogory === "maintenance_req_approved"
                ? "Maintenance request approved"
                : data.catogory === "maintenance_req_accepted"
                ? "Maintenance request accepted"
                : data.catogory === "maintenance_req_porArranged"
                ? "Maintenance crew acknowledge "
                : data.catogory === "maintenance_req_finish"
                ? "Maintenance crew acknowledge "
                : data.catogory === "JobStatusPending"
                ? "Job Pending"
                : data.catogory === "JobStatusVisited"
                ? "provider Visited"
                : data.catogory === "JobStatusJobstarted"
                ? "Job Started"
                : data.catogory === "JobStatusCanceled"
                ? "Job Canceled"
                : data.catogory === "JobStatusFinished"
                ? "Job Finished "
                : data.catogory === "JobStatusRejected"
                ? "Job Rejected "
                : data.catogory === "ServiceRequestCreated"
                ? "Service request created"
                : data.catogory === "ServiceRequestApproved"
                ? "Service request approved"
                : data.catogory === "ServiceRequestRejected"
                ? "Service request rejected"
                : data.catogory === "ServiceRequestCanceled"
                ? "Service request canceled"
                : data.catogory === "ServiceRequestUpdatedByTenant" ||
                  data.catogory === "ServiceRequestUpdatedByLandlord"
                ? "Service request updated"
                : data.catogory === "ServiceRequestModificationRequested"
                ? "Service modify requested"
                : data.catogory === "ProviderInvited"
                ? "Provider invited"
                : data.catogory === "QuotationReceived"
                ? "Quotation received"
                : data.catogory === "QuotationApproved"
                ? "Quotation approved"
                : data.catogory === "QuotationRejected"
                ? "Quotation rejected"
                : data.catogory === "QuotationModificationRequested"
                ? "Quotation modify requested"
                : data.catogory === "QuotationUpdated"
                ? "Quotation updated"
                : data.catogory === "QuotationCanceled"
                ? "Quotation canceled"
                : data.catogory === "JobAssigned"
                ? "Job assigned"
                : data.catogory === "JobAssignedUpdated"
                ? "Job assigned updated"
                : data.catogory === "JobFinishedByProvider"
                ? "Job finished by provider"
                : data.catogory === "JobFinishedApprovedByTenant"
                ? "Job finished by tenant"
                : data.catogory === "JobCanceled"
                ? "Job canceled"
                : data.catogory === "JobDelayRequest"
                ? "Job extension request"
                : data.catogory === "JobDelayNotified"
                ? "Job extension notify"
                : ""}
            </span>
          </div>
        </div>
        <div
          className={
            data.seen
              ? "row notificationMobile p-0 m-0 profileList pointer pt-2 pb-2"
              : "row notificationMobile p-0 m-0 profileList1 pointer pt-2 pb-0 "
          }
          id="click"
          onClick={(e) => this.props.handleClick(data, index, e)}
        >
          <div className="col-2 p-0 m-0" style={{ minWidth: "" }}>
            <div className="row p-0 m-0">
              <div className="col-2 p-0 m-0 text-center">
                <img
                  className="proImage "
                  alt={data.actionBy}
                  src={profileImage}
                  style={{ marginLeft: "0px" }}
                />
              </div>
              <div className="col-9 p-0 m-0">
                <p className="applicationText1">
                  {" "}
                  {data.rentalEnitiyAddress
                    ? data.rentalEnitiyAddress
                    : data.actionBy}
                </p>
              </div>
              <div className="col p-0 m-0" style={{ marginTop: "-80px" }}>
                <span
                  id="delete"
                  className="badge DeleteNotification mr-3 ml-3 p-2 pull-right "
                  onClick={(e) => this.props.handleDelete(data, e)}
                >
                  <i
                    className="fa fa-trash-o"
                    aria-hidden="true"
                    id="delete"
                    onClick={(e) => this.props.handleDelete(data, e)}
                  ></i>
                </span>
                <span className="pull-right mt-2">
                  {timeShortCalculativeFormat(data.createdAt)}
                </span>
                <span
                  className="badge appStatus mr-3 ml-3 pull-right  mt-2"
                  style={{
                    backgroundColor:
                      data.catogory === "application_received"
                        ? "#AEB6BF"
                        : data.catogory === "application_rejected"
                        ? "#E74C3C"
                        : data.catogory === "application_rejected_system"
                        ? "#E74C3C"
                        : data.catogory === "application_approved"
                        ? "#2ECC71"
                        : data.catogory === "application_canceled"
                        ? "#5499C7"
                        : data.catogory === "contract_created"
                        ? "#2ECC71"
                        : data.catogory === "contract_updated"
                        ? "#F4D03F"
                        : data.catogory === "contract_terminated"
                        ? "#E74C3C"
                        : data.catogory === "contract_approved"
                        ? "#2ECC71"
                        : data.catogory === "contract_canceled"
                        ? "#5499C7"
                        : data.catogory === "contract_accepted"
                        ? "#2ECC71"
                        : data.catogory === "backgroud_chk_received"
                        ? "#AEB6BF"
                        : data.catogory === "credit_chk_received"
                        ? "#AEB6BF"
                        : data.catogory === "viewing_req_received"
                        ? "#AEB6BF"
                        : data.catogory === "viewing_req_rejected"
                        ? "#E74C3C"
                        : data.catogory === "viewing_req_canceled"
                        ? "#5499C7"
                        : data.catogory === "viewing_req_approved"
                        ? "#2ECC71"
                        : data.catogory === "maintenance_req_posted"
                        ? "#AEB6BF"
                        : data.catogory === "maintenance_req_approved"
                        ? "#2ECC71"
                        : data.catogory === "maintenance_req_accepted"
                        ? "#2ECC71"
                        : data.catogory === "maintenance_req_porArranged"
                        ? "#F4D03F "
                        : data.catogory === "maintenance_req_finish"
                        ? "#F4D03F "
                        : data.catogory === "JobStatusPending"
                        ? "#F4D03F "
                        : data.catogory === "JobStatusVisited"
                        ? "#AEB6BF "
                        : data.catogory === "JobStatusJobstarted"
                        ? "#2ECC71 "
                        : data.catogory === "JobStatusCanceled"
                        ? "#F4D03F "
                        : data.catogory === "JobStatusFinished"
                        ? "#2ECC71 "
                        : data.catogory === "JobStatusRejected"
                        ? "#E74C3C "
                        : data.catogory === "ServiceRequestCreated"
                        ? "#2ECC71 "
                        : data.catogory === "ServiceRequestApproved"
                        ? "#2ECC71 "
                        : data.catogory === "ServiceRequestRejected"
                        ? "#E74C3C "
                        : data.catogory === "ServiceRequestCanceled"
                        ? "#F4D03F "
                        : data.catogory === "ServiceRequestUpdatedByTenant" ||
                          data.catogory === "ServiceRequestUpdatedByLandlord"
                        ? "#F4D03F "
                        : data.catogory ===
                          "ServiceRequestModificationRequested"
                        ? "#AEB6BF "
                        : data.catogory === "ProviderInvited"
                        ? "#AEB6BF "
                        : data.catogory === "QuotationReceived"
                        ? "#AEB6BF "
                        : data.catogory === "QuotationApproved"
                        ? "#2ECC71 "
                        : data.catogory === "QuotationRejected"
                        ? "#E74C3C "
                        : data.catogory === "QuotationModificationRequested"
                        ? "#AEB6BF "
                        : data.catogory === "QuotationUpdated"
                        ? "#F4D03F "
                        : data.catogory === "QuotationCanceled"
                        ? "#E74C3C "
                        : data.catogory === "JobAssigned"
                        ? "#2ECC71 "
                        : data.catogory === "JobAssignedUpdated"
                        ? "#F4D03F "
                        : data.catogory === "JobFinishedByProvider"
                        ? "#2ECC71 "
                        : data.catogory === "JobFinishedApprovedByTenant"
                        ? "#F4D03F "
                        : data.catogory === "JobCanceled"
                        ? "#E74C3C "
                        : data.catogory === "JobDelayRequest"
                        ? "#AEB6BF "
                        : data.catogory === "JobDelayNotified"
                        ? "#AEB6BF "
                        : "",
                  }}
                >
                  {data.catogory === "application_received"
                    ? "Application received"
                    : data.catogory === "application_rejected"
                    ? "Application rejected"
                    : data.catogory === "application_rejected_system"
                    ? "Application rejected system"
                    : data.catogory === "application_approved"
                    ? "Application approved"
                    : data.catogory === "application_canceled"
                    ? "Application canceled"
                    : data.catogory === "contract_created"
                    ? "Contract created"
                    : data.catogory === "contract_updated"
                    ? "Contract updated"
                    : data.catogory === "contract_terminated"
                    ? "Contract terminated"
                    : data.catogory === "contract_approved"
                    ? "Contract approved"
                    : data.catogory === "contract_canceled"
                    ? "Contract canceled"
                    : data.catogory === "contract_accepted"
                    ? "Contract accepted"
                    : data.catogory === "backgroud_chk_received"
                    ? "Backgroud check report received"
                    : data.catogory === "credit_chk_received"
                    ? "Credit check report received"
                    : data.catogory === "viewing_req_received"
                    ? "Viewing time request received"
                    : data.catogory === "viewing_req_rejected"
                    ? "Viewing time request rejected"
                    : data.catogory === "viewing_req_canceled"
                    ? "Viewing time request canceled"
                    : data.catogory === "viewing_req_approved"
                    ? "Viewing time request approved"
                    : data.catogory === "maintenance_req_posted"
                    ? "Maintenance request received"
                    : data.catogory === "maintenance_req_approved"
                    ? "Maintenance request approved"
                    : data.catogory === "maintenance_req_accepted"
                    ? "Maintenance request accepted"
                    : data.catogory === "maintenance_req_porArranged"
                    ? "Maintenance crew acknowledge "
                    : data.catogory === "maintenance_req_finish"
                    ? "Maintenance crew acknowledge "
                    : data.catogory === "JobStatusPending"
                    ? "Job Pending"
                    : data.catogory === "JobStatusVisited"
                    ? "provider Visited"
                    : data.catogory === "JobStatusJobstarted"
                    ? "Job Started"
                    : data.catogory === "JobStatusCanceled"
                    ? "Job Canceled"
                    : data.catogory === "JobStatusFinished"
                    ? "Job Finished "
                    : data.catogory === "JobStatusRejected"
                    ? "Job Rejected "
                    : data.catogory === "ServiceRequestCreated"
                    ? "Service request created"
                    : data.catogory === "ServiceRequestApproved"
                    ? "Service request approved"
                    : data.catogory === "ServiceRequestRejected"
                    ? "Service request rejected"
                    : data.catogory === "ServiceRequestCanceled"
                    ? "Service request canceled"
                    : data.catogory === "ServiceRequestUpdatedByTenant" ||
                      data.catogory === "ServiceRequestUpdatedByLandlord"
                    ? "Service request updated"
                    : data.catogory === "ServiceRequestModificationRequested"
                    ? "Service modify requested"
                    : data.catogory === "ProviderInvited"
                    ? "Provider invited"
                    : data.catogory === "QuotationReceived"
                    ? "Quotation received"
                    : data.catogory === "QuotationApproved"
                    ? "Quotation approved"
                    : data.catogory === "QuotationRejected"
                    ? "Quotation rejected"
                    : data.catogory === "QuotationModificationRequested"
                    ? "Quotation modify requested"
                    : data.catogory === "QuotationUpdated"
                    ? "Quotation updated"
                    : data.catogory === "QuotationCanceled"
                    ? "Quotation canceled"
                    : data.catogory === "JobAssigned"
                    ? "Job assigned"
                    : data.catogory === "JobAssignedUpdated"
                    ? "Job assigned updated"
                    : data.catogory === "JobFinishedByProvider"
                    ? "Job finished by provider"
                    : data.catogory === "JobFinishedApprovedByTenant"
                    ? "Job finished by tenant"
                    : data.catogory === "JobCanceled"
                    ? "Job canceled"
                    : data.catogory === "JobDelayRequest"
                    ? "Job extension request"
                    : data.catogory === "JobDelayNotified"
                    ? "Job extension notify"
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NotificationPart;
