import React, { Component } from "react";
import "../application.css";
import { withRouter } from "react-router-dom";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import LicenseModal from "../LicenseModal";
import {
  checkPaymentStatus,
  stripeKey,
  stripeToken,
  addPaymentMethod,
  verifyPaymentMethod,
  getApplicationsCount,
  getPropertyLists,
  isAvailableUser,
  url1,
  setTorontoApplication,
} from "../../../../Services/ApiServices";

import { StripeProvider, Elements } from "react-stripe-elements";
import CardPayment from "../../../Pages/Payment/CardPayment";
import ApplicationReview from "../Review/ApplicationReviewCanada";

import {
  canadaStep1Verification,
  canadaStep2Verification,
  canadaStep3Verification,
  canadaStep4Verification,
} from "../../../../Defined/AppDivideFunction";
import Loader3 from "../../../Loader/loader3";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import { PDFDocument } from "pdf-lib";
import {
  addMonthsYear,
  betweenMonths,
  betweenYears,
  betweenYearsMonths,
  correctDateFormat,
  correctMonthYearFormat,
} from "../../../../Defined/Function";
import CookieService from "../../../../Services/CookieService";
import { connect } from "react-redux";
import { setCustomerPaymentStatus } from "../../../../actions";

class ListingApplicationCanada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      nowTab: 1,
      tabCheck: [false, false, false, false, false, false],
      applicantCount: 1,
      additionalApplicantCount: 1,
      additionalApplicant2: false,
      employment1: false,
      employmentCount: 1,
      employment2: false,
      applicant2EmploymentCount: 1,
      applicant3EmploymentCount: 1,
      applicant2: false,
      applicant2Employment1: false,
      applicant2Employment2: false,
      additionalApplicant3: false,
      applicant3: false,
      applicant3Employment1: false,
      applicant3Employment2: false,
      next: false,
      licenseModal: false,
      footer: true,

      applicantFirstName: "",
      applicantMiddleName: "",
      applicantLastName: "",
      applicantEmail: "",
      applicantDob: "",
      applicantGender: "",
      applicantSIN: "",
      applicantMobile: "",
      applicantDriversLicense: "",
      applicantVehicleMadeBy: "",
      applicantVehicleModel: "",
      applicantVehicleYear: "",
      applicantVehicleLicenseNumber: "",

      applicant2FirstName: "",
      applicant2MiddleName: "",
      applicant2LastName: "",
      applicant2Email: "",
      applicant2Dob: "",
      applicant2Gender: "",
      applicant2SIN: "",
      applicant2Mobile: "",
      applicant2DriversLicense: "",
      applicant2VehicleMadeBy: "",
      applicant2VehicleModel: "",
      applicant2VehicleYear: "",
      applicant2VehicleLicenseNumber: "",

      applicant3FirstName: "",
      applicant3MiddleName: "",
      applicant3LastName: "",
      applicant3Email: "",
      applicant3Dob: "",
      applicant3Gender: "",
      applicant3SIN: "",
      applicant3Mobile: "",
      applicant3DriversLicense: "",
      applicant3VehicleMadeBy: "",
      applicant3VehicleModel: "",
      applicant3VehicleYear: "",
      applicant3VehicleLicenseNumber: "",

      additionalApplicantFirstName: "",
      additionalApplicantMiddleName: "",
      additionalApplicantLastName: "",
      additionalApplicantDob: "",
      additionalApplicantGender: "",
      additionalApplicantSIN: "",

      additionalApplicant2FirstName: "",
      additionalApplicant2MiddleName: "",
      additionalApplicant2LastName: "",
      additionalApplicant2Dob: "",
      additionalApplicant2Gender: "",
      additionalApplicant2SIN: "",

      additionalApplicant3FirstName: "",
      additionalApplicant3MiddleName: "",
      additionalApplicant3LastName: "",
      additionalApplicant3Dob: "",
      additionalApplicant3Gender: "",
      additionalApplicant3SIN: "",

      moveInDate: this.today(),
      term: 12,
      emergencyContactName: "",
      emergencyContactPhone: "",
      emergencyContactFax: "",
      emergencyContactRelation: "",

      hydro: false,
      gas: false,
      heat: false,
      hotWaterHeater: false,
      hotWaterTank: false,
      internet: false,
      cableTv: false,
      other: false,
      water: false,

      presentAddress: "",
      presentState: "",
      presentCity: "",
      presentZip: "",
      presentFrom: "",
      presentTo: this.today(),
      presentHomePhone: "",
      presentHomeFax: "",
      presentReasonLeaving: "",
      presentPropertyType: "",
      presentLandlord: "",
      presentLandlordPhone: "",
      presentLandlordIDProof: "",
      presentMonthlyRent: "",

      previousAddress: "",
      previousState: "",
      previousCity: "",
      previousZip: "",
      previousFrom: "",
      previousTo: "",
      previousReasonLeaving: "",
      previousPropertyType: "",
      previousLandlord: "",
      previousLandlordPhone: "",
      previousMonthlyRent: "",

      applicant2PresentAddress: "",
      applicant2PresentState: "",
      applicant2PresentCity: "",
      applicant2PresentZip: "",
      applicant2PresentFrom: "",
      applicant2PresentTo: "",
      applicant2PresentHomePhone: "",
      applicant2PresentHomeFax: "",
      applicant2PresentReasonLeaving: "",
      applicant2PresentPropertyType: "",
      applicant2PresentLandlord: "",
      applicant2PresentLandlordPhone: "",
      applicant2PresentLandlordIDProof: "",
      applicant2PresentMonthlyRent: "",

      applicant2PreviousAddress: "",
      applicant2PreviousState: "",
      applicant2PreviousCity: "",
      applicant2PreviousZip: "",
      applicant2PreviousFrom: "",
      applicant2PreviousTo: "",
      applicant2PreviousReasonLeaving: "",
      applicant2PreviousPropertyType: "",
      applicant2PreviousLandlord: "",
      applicant2PreviousLandlordPhone: "",
      applicant2PreviousMonthlyRent: "",

      applicant3PresentAddress: "",
      applicant3PresentState: "",
      applicant3PresentCity: "",
      applicant3PresentZip: "",
      applicant3PresentFrom: "",
      applicant3PresentTo: "",
      applicant3PresentHomePhone: "",
      applicant3PresentHomeFax: "",
      applicant3PresentReasonLeaving: "",
      applicant3PresentPropertyType: "",
      applicant3PresentLandlord: "",
      applicant3PresentLandlordPhone: "",
      applicant3PresentLandlordIDProof: "",
      applicant3PresentMonthlyRent: "",

      applicant3PreviousAddress: "",
      applicant3PreviousState: "",
      applicant3PreviousCity: "",
      applicant3PreviousZip: "",
      applicant3PreviousFrom: "",
      applicant3PreviousTo: "",
      applicant3PreviousReasonLeaving: "",
      applicant3PreviousPropertyType: "",
      applicant3PreviousLandlord: "",
      applicant3PreviousLandlordPhone: "",
      applicant3PreviousMonthlyRent: "",

      employmentPosition: "",
      employmentFrom: "",
      employmentUntil: "",
      employmentAnnualIncome: "",
      employmentZip: "",
      employmentAddress: "",
      employmentCity: "",
      employmentState: "",
      employmentPhone: "",
      employmentCompany: "",
      preEmploymentCompany: "",
      preEmployment2Company: "",
      preEmploymentZip: "",
      preEmploymentAddress: "",
      preEmploymentCity: "",
      preEmploymentState: "",
      preEmploymentPosition: "",
      preEmploymentFrom: "",
      preEmploymentUntil: "",
      preEmployment2Zip: "",
      preEmployment2Address: "",
      preEmployment2City: "",
      preEmployment2State: "",
      preEmployment2Position: "",
      preEmployment2From: "",
      preEmployment2Until: "",

      applicant2EmploymentPosition: "",
      applicant2EmploymentFrom: "",
      applicant2EmploymentUntil: "",
      applicant2EmploymentAnnualIncome: "",
      applicant2EmploymentZip: "",
      applicant2EmploymentAddress: "",
      applicant2EmploymentCity: "",
      applicant2EmploymentState: "",
      applicant2EmploymentPhone: "",
      applicant2EmploymentCompany: "",
      applicant2PreEmploymentCompany: "",
      applicant2PreEmployment2Company: "",

      applicant2PreEmploymentZip: "",
      applicant2PreEmploymentAddress: "",
      applicant2PreEmploymentCity: "",
      applicant2PreEmploymentState: "",
      applicant2PreEmploymentPosition: "",
      applicant2PreEmploymentFrom: "",
      applicant2PreEmploymentUntil: "",
      applicant2PreEmployment2Zip: "",
      applicant2PreEmployment2Address: "",
      applicant2PreEmployment2City: "",
      applicant2PreEmployment2State: "",
      applicant2PreEmployment2Position: "",
      applicant2PreEmployment2From: "",
      applicant2PreEmployment2Until: "",

      applicant3EmploymentPosition: "",
      applicant3EmploymentFrom: "",
      applicant3EmploymentUntil: "",
      applicant3EmploymentAnnualIncome: "",
      applicant3EmploymentZip: "",
      applicant3EmploymentAddress: "",
      applicant3EmploymentCity: "",
      applicant3EmploymentState: "",
      applicant3EmploymentPhone: "",
      applicant3EmploymentCompany: "",
      applicant3PreEmploymentCompany: "",
      applicant3PreEmployment2Company: "",

      applicant3PreEmploymentZip: "",
      applicant3PreEmploymentAddress: "",
      applicant3PreEmploymentCity: "",
      applicant3PreEmploymentState: "",
      applicant3PreEmploymentPosition: "",
      applicant3PreEmploymentFrom: "",
      applicant3PreEmploymentUntil: "",
      applicant3PreEmployment2Zip: "",
      applicant3PreEmployment2Address: "",
      applicant3PreEmployment2City: "",
      applicant3PreEmployment2State: "",
      applicant3PreEmployment2Position: "",
      applicant3PreEmployment2From: "",
      applicant3PreEmployment2Until: "",

      spousesEmployer: "",
      spousesEmployerAddress: "",
      spousesIncome: "",
      applicantFirstReferencesName: "",
      applicantFirstReferencesAddress: "",
      applicantFirstReferencesTelephone: "",
      applicantSecondReferencesName: "",
      applicantSecondReferencesAddress: "",
      applicantSecondReferencesTelephone: "",

      applicant2SpousesEmployer: "",
      applicant2SpousesEmployerAddress: "",
      applicant2SpousesIncome: "",
      applicant2FirstReferencesName: "",
      applicant2FirstReferencesAddress: "",
      applicant2FirstReferencesTelephone: "",
      applicant2SecondReferencesName: "",
      applicant2SecondReferencesAddress: "",
      applicant2SecondReferencesTelephone: "",

      applicant3SpousesEmployer: "",
      applicant3SpousesEmployerAddress: "",
      applicant3SpousesIncome: "",
      applicant3FirstReferencesName: "",
      applicant3FirstReferencesAddress: "",
      applicant3FirstReferencesTelephone: "",
      applicant3SecondReferencesName: "",
      applicant3SecondReferencesAddress: "",
      applicant3SecondReferencesTelephone: "",

      applicantPrimaryBankName: "",
      applicantPrimaryBankBranch: "",
      applicantPrimaryBankAccType: "",
      applicantPrimaryBankAccNo: "",
      applicantSecondaryBankName: "",
      applicantSecondaryBankBranch: "",
      applicantSecondaryBankAccType: "",
      applicantSecondaryBankAccNo: "",

      applicant2PrimaryBankName: "",
      applicant2PrimaryBankBranch: "",
      applicant2PrimaryBankAccType: "",
      applicant2PrimaryBankAccNo: "",
      applicant2SecondaryBankName: "",
      applicant2SecondaryBankBranch: "",
      applicant2SecondaryBankAccType: "",
      applicant2SecondaryBankAccNo: "",

      applicant3PrimaryBankName: "",
      applicant3PrimaryBankBranch: "",
      applicant3PrimaryBankAccType: "",
      applicant3PrimaryBankAccNo: "",
      applicant3SecondaryBankName: "",
      applicant3SecondaryBankBranch: "",
      applicant3SecondaryBankAccType: "",
      applicant3SecondaryBankAccNo: "",

      applicantVehicle: false,
      applicant2Vehicle: false,
      applicant3Vehicle: false,
      additionalApplicantInfo: false,
      applicantPreviousInfo: false,
      applicant2PreviousInfo: false,
      applicant3PreviousInfo: false,

      rentalUnit: this.props.match.params.id ? this.props.match.params.id : "",
      paymentToken: "",
      listingData: "",
      downloadModal: false,
      propertyRent: "40",
      dropYears: [],
      apartmentDetails: {
        blogNo: "001",
        blogName: "Toronto",
        suitNo: "102",
        blockType: "Apartment",
      },
      changeBlob: true,
      mailCheck: false,
      isButtonDisableFinish: true,
      finishedModal: true,
      pdfDocObject: "",
      rentedPremisesQut: "",
      rentedPremisesPrice: 1000,
      parkingQut: "",
      parkingPrice: 800,
      lockerQut: "",
      lockerPrice: 200,
      otherQut: "",
      otherPrice: 300,
      totalRent: 2300,
      lastMonthRentDeposit: 2000,
      proRatedRent: 200,
      payableFrom: this.today(),
      payableTo: this.today(),
      summaryMoney: "MoneyOrder", //Chq
      summaryProRatedRent: 2300,
      summaryFirstMonthRent: 1200,
      summaryLastMonthRent: 1100,
      summaryTotal: 4600,
      agreementSum: 6900,
      agreementCity: "toronto",
      agreementDay: this.todayDate(),
      agreementMonth: this.todayMonth(),
      agreementYear: this.todayYear(),
      witness: "",
      applicantPrintName: "",
      applicantSignName: "",
      witness2: "",
      applicant2PrintName: "",
      applicant2SignName: "",
      witness3: "",
      applicant3PrintName: "",
      applicant3SignName: "",
      date2: this.today(),
      signatureLandlord: "",
    };
    this.onchange = this.onchange.bind(this);
    this.next = this.next.bind(this);
    this.changeLicense = this.changeLicense.bind(this);
    this.changeLicenseData = this.changeLicenseData.bind(this);
    this.keepWait = this.keepWait.bind(this);
    this.removeKeepWait = this.removeKeepWait.bind(this);
    this.showButton = this.showButton.bind(this);
    this.setPaymentToken = this.setPaymentToken.bind(this);
    this.onchangeState = this.onchangeState.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onchangeCheckOpp = this.onchangeCheckOpp.bind(this);
    this.onchangeCheckOption = this.onchangeCheckOption.bind(this);
    this.closeDownloadModal = this.closeDownloadModal.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  openDownloadModal() {
    this.setState({ downloadModal: true });
  }
  closeDownloadModal() {
    this.setState({ downloadModal: false });
  }

  async clickStep(step) {
    if (this.state.nowTab !== step) {
      if (this.state.tabCheck[step]) {
        this.setState({ nowTab: step });
        if (this.state.nowTab === 1) {
          if (!this.state.additionalApplicantInfo) {
            this.setState({
              additionalApplicantFirstName: "",
              additionalApplicantMiddleName: "",
              additionalApplicantLastName: "",
              additionalApplicantDob: "",
              additionalApplicantGender: "",
              additionalApplicantSIN: "",
            });
            if (!this.state.additionalApplicant2) {
              this.setState({
                additionalApplicant2FirstName: "",
                additionalApplicant2MiddleName: "",
                additionalApplicant2LastName: "",
                additionalApplicant2Dob: "",
                additionalApplicant2Gender: "",
                additionalApplicant2SIN: "",
              });
            }
            if (!this.state.additionalApplicant3) {
              this.setState({
                additionalApplicant3FirstName: "",
                additionalApplicant3MiddleName: "",
                additionalApplicant3LastName: "",
                additionalApplicant3Dob: "",
                additionalApplicant3Gender: "",
                additionalApplicant3SIN: "",
              });
            }
          }
          if (!this.state.applicantVehicle) {
            this.setState({
              applicantDriversLicense: "",
              applicantVehicleMadeBy: "",
              applicantVehicleModel: "",
              applicantVehicleYear: "",
              applicantVehicleLicenseNumber: "",
            });
          }
          if (!this.state.applicant2) {
            this.setState({
              applicant2FirstName: "",
              applicant2MiddleName: "",
              applicant2LastName: "",
              applicant2Email: "",
              applicant2Dob: "",
              applicant2Gender: "",
              applicant2SIN: "",
              applicant2Mobile: "",
              applicant2FirstReferencesName: "",
              applicant2FirstReferencesAddress: "",
              applicant2FirstReferencesTelephone: "",
              applicant2SecondReferencesName: "",
              applicant2SecondReferencesAddress: "",
              applicant2SecondReferencesTelephone: "",
              applicant2DriversLicense: "",
              applicant2VehicleMadeBy: "",
              applicant2VehicleModel: "",
              applicant2VehicleYear: "",
              applicant2VehicleLicenseNumber: "",
              applicant2PresentAddress: "",
              applicant2PresentState: "",
              applicant2PresentCity: "",
              applicant2PresentZip: "",
              applicant2PresentFrom: "",
              applicant2PresentTo: "",
              applicant2PresentHomePhone: "",
              applicant2PresentHomeFax: "",
              applicant2PresentReasonLeaving: "",
              applicant2PresentPropertyType: "",
              applicant2PresentLandlord: "",
              applicant2PresentLandlordPhone: "",
              applicant2PresentLandlordIDProof: "",
              applicant2PresentMonthlyRent: "",
              applicant2PreviousAddress: "",
              applicant2PreviousState: "",
              applicant2PreviousCity: "",
              applicant2PreviousZip: "",
              applicant2PreviousFrom: "",
              applicant2PreviousTo: "",
              applicant2PreviousReasonLeaving: "",
              applicant2PreviousPropertyType: "",
              applicant2PreviousLandlord: "",
              applicant2PreviousLandlordPhone: "",
              applicant2PreviousMonthlyRent: "",
              applicant2EmploymentPosition: "",
              applicant2EmploymentFrom: "",
              applicant2EmploymentUntil: "",
              applicant2EmploymentAnnualIncome: "",
              applicant2EmploymentZip: "",
              applicant2EmploymentAddress: "",
              applicant2EmploymentCity: "",
              applicant2EmploymentState: "",
              applicant2EmploymentPhone: "",
              applicant2EmploymentCompany: "",
              applicant2PreEmploymentCompany: "",
              applicant2PreEmployment2Company: "",
              applicant2PreEmploymentZip: "",
              applicant2PreEmploymentAddress: "",
              applicant2PreEmploymentCity: "",
              applicant2PreEmploymentState: "",
              applicant2PreEmploymentPosition: "",
              applicant2PreEmploymentFrom: "",
              applicant2PreEmploymentUntil: "",
              applicant2PreEmployment2Zip: "",
              applicant2PreEmployment2Address: "",
              applicant2PreEmployment2City: "",
              applicant2PreEmployment2State: "",
              applicant2PreEmployment2Position: "",
              applicant2PreEmployment2From: "",
              applicant2PreEmployment2Until: "",
              applicant2SpousesEmployer: "",
              applicant2SpousesEmployerAddress: "",
              applicant2SpousesIncome: "",
              applicant2PrimaryBankName: "",
              applicant2PrimaryBankBranch: "",
              applicant2PrimaryBankAccType: "",
              applicant2PrimaryBankAccNo: "",
              applicant2SecondaryBankName: "",
              applicant2SecondaryBankBranch: "",
              applicant2SecondaryBankAccType: "",
              applicant2SecondaryBankAccNo: "",
            });
          } else {
            if (!this.state.applicant2Vehicle) {
              this.setState({
                applicant2DriversLicense: "",
                applicant2VehicleMadeBy: "",
                applicant2VehicleModel: "",
                applicant2VehicleYear: "",
                applicant2VehicleLicenseNumber: "",
              });
            }
          }
          if (!this.state.applicant3) {
            this.setState({
              applicant3FirstName: "",
              applicant3MiddleName: "",
              applicant3LastName: "",
              applicant3Email: "",
              applicant3Dob: "",
              applicant3Gender: "",
              applicant3SIN: "",
              applicant3Mobile: "",
              applicant3FirstReferencesName: "",
              applicant3FirstReferencesAddress: "",
              applicant3FirstReferencesTelephone: "",
              applicant3SecondReferencesName: "",
              applicant3SecondReferencesAddress: "",
              applicant3SecondReferencesTelephone: "",
              applicant3DriversLicense: "",
              applicant3VehicleMadeBy: "",
              applicant3VehicleModel: "",
              applicant3VehicleYear: "",
              applicant3VehicleLicenseNumber: "",
              applicant3PresentAddress: "",
              applicant3PresentState: "",
              applicant3PresentCity: "",
              applicant3PresentZip: "",
              applicant3PresentFrom: "",
              applicant3PresentTo: "",
              applicant3PresentHomePhone: "",
              applicant3PresentHomeFax: "",
              applicant3PresentReasonLeaving: "",
              applicant3PresentPropertyType: "",
              applicant3PresentLandlord: "",
              applicant3PresentLandlordPhone: "",
              applicant3PresentLandlordIDProof: "",
              applicant3PresentMonthlyRent: "",
              applicant3PreviousAddress: "",
              applicant3PreviousState: "",
              applicant3PreviousCity: "",
              applicant3PreviousZip: "",
              applicant3PreviousFrom: "",
              applicant3PreviousTo: "",
              applicant3PreviousReasonLeaving: "",
              applicant3PreviousPropertyType: "",
              applicant3PreviousLandlord: "",
              applicant3PreviousLandlordPhone: "",
              applicant3PreviousMonthlyRent: "",
              applicant3EmploymentPosition: "",
              applicant3EmploymentFrom: "",
              applicant3EmploymentUntil: "",
              applicant3EmploymentAnnualIncome: "",
              applicant3EmploymentZip: "",
              applicant3EmploymentAddress: "",
              applicant3EmploymentCity: "",
              applicant3EmploymentState: "",
              applicant3EmploymentPhone: "",
              applicant3EmploymentCompany: "",
              applicant3PreEmploymentCompany: "",
              applicant3PreEmployment2Company: "",
              applicant3PreEmploymentZip: "",
              applicant3PreEmploymentAddress: "",
              applicant3PreEmploymentCity: "",
              applicant3PreEmploymentState: "",
              applicant3PreEmploymentPosition: "",
              applicant3PreEmploymentFrom: "",
              applicant3PreEmploymentUntil: "",
              applicant3PreEmployment2Zip: "",
              applicant3PreEmployment2Address: "",
              applicant3PreEmployment2City: "",
              applicant3PreEmployment2State: "",
              applicant3PreEmployment2Position: "",
              applicant3PreEmployment2From: "",
              applicant3PreEmployment2Until: "",
              applicant3SpousesEmployer: "",
              applicant3SpousesEmployerAddress: "",
              applicant3SpousesIncome: "",
              applicant3PrimaryBankName: "",
              applicant3PrimaryBankBranch: "",
              applicant3PrimaryBankAccType: "",
              applicant3PrimaryBankAccNo: "",
              applicant3SecondaryBankName: "",
              applicant3SecondaryBankBranch: "",
              applicant3SecondaryBankAccType: "",
              applicant3SecondaryBankAccNo: "",
            });
          } else {
            if (!this.state.applicant3Vehicle) {
              this.setState({
                applicant3DriversLicense: "",
                applicant3VehicleMadeBy: "",
                applicant3VehicleModel: "",
                applicant3VehicleYear: "",
                applicant3VehicleLicenseNumber: "",
              });
            }
          }
        } else if (this.state.nowTab === 2) {
          if (this.state.presentPropertyType === "Own") {
            this.setState({
              presentLandlord: "",
              presentLandlordPhone: "",
              presentLandlordIDProof: "",
              presentMonthlyRent: "",
            });
          }
          if (!this.state.applicantPreviousInfo) {
            this.setState({
              previousAddress: "",
              previousState: "",
              previousCity: "",
              previousZip: "",
              previousFrom: "",
              previousTo: "",
              previousHomePhone: "",
              previousReasonLeaving: "",
              previousPropertyType: "",
            });
          }
          if (this.state.applicant2PresentPropertyType === "Own") {
            this.setState({
              applicant2PresentLandlord: "",
              applicant2PresentLandlordPhone: "",
              applicant2PresentLandlordIDProof: "",
              applicant2PresentMonthlyRent: "",
            });
          }
          if (!this.state.applicant2PreviousInfo) {
            this.setState({
              applicant2PreviousAddress: "",
              applicant2PreviousState: "",
              applicant2PreviousCity: "",
              applicant2PreviousZip: "",
              applicant2PreviousFrom: "",
              applicant2PreviousTo: "",
              applicant2PreviousHomePhone: "",
              applicant2PreviousReasonLeaving: "",
              applicant2PreviousPropertyType: "",
            });
          }
          if (this.state.applicant3PresentPropertyType === "Own") {
            this.setState({
              applicant3PresentLandlord: "",
              applicant3PresentLandlordPhone: "",
              applicant3PresentLandlordIDProof: "",
              applicant3PresentMonthlyRent: "",
            });
          }

          if (!this.state.applicant3PreviousInfo) {
            this.setState({
              applicant3PreviousAddress: "",
              applicant3PreviousState: "",
              applicant3PreviousCity: "",
              applicant3PreviousZip: "",
              applicant3PreviousFrom: "",
              applicant3PreviousTo: "",
              applicant3PreviousHomePhone: "",
              applicant3PreviousReasonLeaving: "",
              applicant3PreviousPropertyType: "",
            });
          }
        } else if (this.state.nowTab === 3) {
          if (!this.state.employment1) {
            this.setState({
              preEmploymentZip: "",
              preEmploymentAddress: "",
              preEmploymentCity: "",
              preEmploymentState: "",
              preEmploymentPosition: "",
              preEmploymentFrom: "",
              preEmploymentUntil: "",
            });
          }
          if (!this.state.employment2) {
            this.setState({
              preEmployment2Zip: "",
              preEmployment2Address: "",
              preEmployment2City: "",
              preEmployment2State: "",
              preEmployment2Position: "",
              preEmployment2From: "",
              preEmployment2Until: "",
            });
          }

          if (!this.state.applicant2Employment1) {
            this.setState({
              applicant2PreEmploymentZip: "",
              applicant2PreEmploymentAddress: "",
              applicant2PreEmploymentCity: "",
              applicant2PreEmploymentState: "",
              applicant2PreEmploymentPosition: "",
              applicant2PreEmploymentFrom: "",
              applicant2PreEmploymentUntil: "",
            });
          }
          if (!this.state.applicant2Employment2) {
            this.setState({
              applicant2PreEmployment2Zip: "",
              applicant2PreEmployment2Address: "",
              applicant2PreEmployment2City: "",
              applicant2PreEmployment2State: "",
              applicant2PreEmployment2Position: "",
              applicant2PreEmployment2From: "",
              applicant2PreEmployment2Until: "",
            });
          }

          if (!this.state.applicant3Employment1) {
            this.setState({
              applicant3PreEmploymentZip: "",
              applicant3PreEmploymentAddress: "",
              applicant3PreEmploymentCity: "",
              applicant3PreEmploymentState: "",
              applicant3PreEmploymentPosition: "",
              applicant3PreEmploymentFrom: "",
              applicant3PreEmploymentUntil: "",
            });
          }
          if (!this.state.applicant3Employment2) {
            this.setState({
              applicant3PreEmployment2Zip: "",
              applicant3PreEmployment2Address: "",
              applicant3PreEmployment2City: "",
              applicant3PreEmployment2State: "",
              applicant3PreEmployment2Position: "",
              applicant3PreEmployment2From: "",
              applicant3PreEmployment2Until: "",
            });
          }
        }
        if (step === 6) {
          this.getPdfBlob();
        }
      } else {
        if (this.state.tabCheck[this.state.nowTab]) {
          if (this.state.nowTab + 1 === step) {
            if (step === 6) {
              if (this.state.paymentToken !== "") {
                this.next();
                this.getPdfBlob();
              }
            } else {
              this.next();
            }
          } else {
            if (step === 6) {
              if (this.state.paymentToken !== "") {
                this.setState({ nowTab: step });
                this.getPdfBlob();
              }
            } else {
              this.props.pushAlert(
                "Please fill correct order, Your next step is " +
                  (this.state.nowTab + 1),
                "warning"
              );
              this.setState({ nowTab: this.state.nowTab + 1 });
            }
          }
        } else {
          if (this.next()) {
            if (this.state.nowTab + 1 !== step) {
              this.props.pushAlert(
                "Please fill correct order, Your next step is " +
                  (this.state.nowTab + 1),
                "warning"
              );
            }
          } else {
            this.props.pushAlert("Please complete current step", "warning");
          }
        }
      }
    }
  }
  ModifyString(value) {
    if (value) {
      return value.toString();
    }
    return "";
  }
  ModifyDate(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      let year = parseInt(dateItems[yearIndex]);
      let month = parseInt(dateItems[monthIndex]);
      let date = parseInt(dateItems[dayIndex]);
      return month + "/" + date + "/" + year;
    } else {
      return "";
    }
  }
  async getPdfBlob() {
    this.setState({ changeBlob: false });
    const formUrl = url1 + "api/application/toronto/download";
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    const date = form.getTextField("applicationDate");
    date.setText(correctDateFormat(this.today));
    date.enableReadOnly();

    const newApplication = form.getCheckBox("New Application");
    newApplication.check();
    newApplication.enableReadOnly();

    const Assignment = form.getCheckBox("Assignment");
    Assignment.enableReadOnly();
    const Sublet = form.getCheckBox("Sublet");
    Sublet.enableReadOnly();
    const Transfer = form.getCheckBox("Transfer");
    Transfer.enableReadOnly();

    const blogNo = form.getTextField("Bldg No");
    blogNo.setText(this.state.apartmentDetails.blogNo);
    blogNo.enableReadOnly();

    const blogName = form.getTextField("Bldg");
    blogName.setText(this.state.apartmentDetails.blogName);
    blogName.enableReadOnly();

    const suitNo = form.getTextField("Suite No");
    suitNo.setText(this.state.apartmentDetails.suitNo);
    suitNo.enableReadOnly();

    const blockType = form.getTextField("Type");
    blockType.setText(this.state.apartmentDetails.blockType);
    blockType.enableReadOnly();

    const applicantFirstName = form.getTextField("applicantFirstName");
    applicantFirstName.setText(
      (this.state.applicantLastName
        ? this.state.applicantLastName + " "
        : " ") +
        (this.state.applicantFirstName
          ? this.state.applicantFirstName + " "
          : "") +
        (this.state.applicantMiddleName
          ? this.state.applicantMiddleName + " "
          : "")
    );
    applicantFirstName.enableReadOnly();

    const applicantDob = form.getTextField("applicantDob");
    applicantDob.setText(correctDateFormat(this.state.applicantDob));
    applicantDob.enableReadOnly();

    const applicantSIN = form.getTextField("applicantSIN");
    applicantSIN.setText(this.ModifyString(this.state.applicantSIN));
    applicantSIN.enableReadOnly();
    if (this.state.applicantGender === "male") {
      form.getRadioGroup("applicantGenderMale").select("Yes");
    } else if (this.state.applicant2Gender === "female") {
      form.getRadioGroup("applicantGenderFemale").select("Yes");
    }
    const applicantGenderMale = form.getRadioGroup("applicantGenderMale");
    applicantGenderMale.enableReadOnly();
    const applicantGenderFemale = form.getRadioGroup("applicantGenderFemale");
    applicantGenderFemale.enableReadOnly();
    const applicant2FirstName = form.getTextField("applicant2FirstName");
    applicant2FirstName.setText(
      (this.state.applicant2LastName
        ? this.state.applicant2LastName + " "
        : " ") +
        (this.state.applicant2FirstName
          ? this.state.applicant2FirstName + " "
          : "") +
        (this.state.applicant2MiddleName
          ? this.state.applicant2MiddleName + " "
          : "")
    );
    applicant2FirstName.enableReadOnly();

    const applicant2Dob = form.getTextField("applicant2Dob");
    applicant2Dob.setText(correctDateFormat(this.state.applicant2Dob));
    applicant2Dob.enableReadOnly();

    const applicant2SIN = form.getTextField("applicant2SIN");
    applicant2SIN.setText(this.ModifyString(this.state.applicant2SIN));
    applicant2SIN.enableReadOnly();
    if (this.state.applicant2Gender === "male") {
      form.getRadioGroup("applicant2GenderMale").select("Yes");
    }
    if (this.state.applicant2Gender === "female") {
      form.getRadioGroup("applicant2GenderFemale").select("Yes");
    }
    const applicant2GenderFemale = form.getRadioGroup("applicant2GenderFemale");
    applicant2GenderFemale.enableReadOnly();
    const applicant2GenderMale = form.getRadioGroup("applicant2GenderMale");
    applicant2GenderMale.enableReadOnly();
    const applicant3FirstName = form.getTextField("applicant3FirstName");
    applicant3FirstName.setText(
      (this.state.applicant3LastName
        ? this.state.applicant3LastName + " "
        : " ") +
        (this.state.applicant3FirstName
          ? this.state.applicant3FirstName + " "
          : "") +
        (this.state.applicant3MiddleName
          ? this.state.applicant3MiddleName + " "
          : "")
    );
    applicant3FirstName.enableReadOnly();

    const applicant3Dob = form.getTextField("applicant3Dob");
    applicant3Dob.setText(correctDateFormat(this.state.applicant3Dob));
    applicant3Dob.enableReadOnly();

    const applicant3SIN = form.getTextField("applicant3SIN");
    applicant3SIN.setText(this.ModifyString(this.state.applicant3SIN));
    applicant3SIN.enableReadOnly();
    if (this.state.applicant3Gender === "male") {
      form.getRadioGroup("applicant3GenderMale").select("Yes");
    }
    if (this.state.applicant3Gender === "female") {
      form.getRadioGroup("applicant3GenderFemale").select("Yes");
    }
    const applicant3GenderFemale = form.getRadioGroup("applicant3GenderFemale");
    applicant3GenderFemale.enableReadOnly();
    const applicant3GenderMale = form.getRadioGroup("applicant3GenderMale");
    applicant3GenderMale.enableReadOnly();
    const additionalApplicantFirstName = form.getTextField(
      "additionalApplicantFirstName"
    );
    additionalApplicantFirstName.setText(
      (this.state.additionalApplicantLastName
        ? this.state.additionalApplicantLastName + " "
        : " ") +
        (this.state.additionalApplicantFirstName
          ? this.state.additionalApplicantFirstName + " "
          : "") +
        (this.state.additionalApplicantMiddleName
          ? this.state.additionalApplicantMiddleName + " "
          : "")
    );
    additionalApplicantFirstName.enableReadOnly();

    const additionalApplicantDob = form.getTextField("additionalApplicantDob");
    additionalApplicantDob.setText(
      correctDateFormat(this.state.additionalApplicantDob)
    );
    additionalApplicantDob.enableReadOnly();

    const additionalApplicantSIN = form.getTextField("additionalApplicantSIN");
    additionalApplicantSIN.setText(
      this.ModifyString(this.state.additionalApplicantSIN)
    );
    additionalApplicantSIN.enableReadOnly();
    if (this.state.additionalApplicantGender === "Male") {
      form.getRadioGroup("additionalApplicantGenderMale").select("M_4");
    }
    if (this.state.additionalApplicantGender === "Female") {
      form.getRadioGroup("additionalApplicantGenderFemale").select("F_4");
    }
    const additionalApplicantGenderFemale = form.getRadioGroup(
      "additionalApplicantGenderFemale"
    );
    additionalApplicantGenderFemale.enableReadOnly();
    const additionalApplicantGenderMale = form.getRadioGroup(
      "additionalApplicantGenderMale"
    );
    additionalApplicantGenderMale.enableReadOnly();
    const additionalApplicant2FirstName = form.getTextField(
      "additionalApplicant2FirstName"
    );
    additionalApplicant2FirstName.setText(
      (this.state.additionalApplicant2LastName
        ? this.state.additionalApplicant2LastName + " "
        : " ") +
        (this.state.additionalApplicant2FirstName
          ? this.state.additionalApplicant2FirstName + " "
          : "") +
        (this.state.additionalApplicant2MiddleName
          ? this.state.additionalApplicant2MiddleName + " "
          : "")
    );
    additionalApplicant2FirstName.enableReadOnly();

    const additionalApplicant2Dob = form.getTextField(
      "additionalApplicant2Dob"
    );
    additionalApplicant2Dob.setText(
      correctDateFormat(this.state.additionalApplicant2Dob)
    );
    additionalApplicant2Dob.enableReadOnly();

    const additionalApplicant2SIN = form.getTextField(
      "additionalApplicant2SIN"
    );
    additionalApplicant2SIN.setText(
      this.ModifyString(this.state.additionalApplicant2SIN)
    );
    additionalApplicant2SIN.enableReadOnly();
    if (this.state.additionalApplicant2Gender === "Male") {
      form.getRadioGroup("additionalApplicant2GenderMale").select("M_5");
    }
    if (this.state.additionalApplicant2Gender === "Female") {
      form.getRadioGroup("additionalApplicant2GenderFemale").select("F_5");
    }
    const additionalApplicant2GenderFemale = form.getRadioGroup(
      "additionalApplicant2GenderFemale"
    );
    additionalApplicant2GenderFemale.enableReadOnly();
    const additionalApplicant2GenderMale = form.getRadioGroup(
      "additionalApplicant2GenderMale"
    );
    additionalApplicant2GenderMale.enableReadOnly();
    const additionalApplicant3FirstName = form.getTextField(
      "additionalApplicant3FirstName"
    );
    additionalApplicant3FirstName.setText(
      (this.state.additionalApplicant3LastName
        ? this.state.additionalApplicant3LastName + " "
        : " ") +
        (this.state.additionalApplicant3FirstName
          ? this.state.additionalApplicant3FirstName + " "
          : "") +
        (this.state.additionalApplicant3MiddleName
          ? this.state.additionalApplicant3MiddleName + " "
          : "")
    );
    additionalApplicant3FirstName.enableReadOnly();

    const additionalApplicant3Dob = form.getTextField(
      "additionalApplicant3Dob"
    );
    additionalApplicant3Dob.setText(
      correctDateFormat(this.state.additionalApplicant3Dob)
    );
    additionalApplicant3Dob.enableReadOnly();

    const additionalApplicant3SIN = form.getTextField(
      "additionalApplicant3SIN"
    );
    additionalApplicant3SIN.setText(
      this.ModifyString(this.state.additionalApplicant3SIN)
    );
    additionalApplicant3SIN.enableReadOnly();
    if (this.state.additionalApplicant3Gender === "Male") {
      form.getRadioGroup("additionalApplicant3GenderMale").select("M_6");
    }
    if (this.state.additionalApplicant3Gender === "Female") {
      form.getRadioGroup("additionalApplicant3GenderFemale").select("F_6");
    }
    const additionalApplicant3GenderFemale = form.getRadioGroup(
      "additionalApplicant3GenderFemale"
    );
    additionalApplicant3GenderFemale.enableReadOnly();
    const additionalApplicant3GenderMale = form.getRadioGroup(
      "additionalApplicant3GenderMale"
    );
    additionalApplicant3GenderMale.enableReadOnly();

    const applicantInitial = form.getTextField("Applicants Initials");
    applicantInitial.setText(
      this.ModifyString(this.state.applicantFirstName.charAt(0))
    );
    applicantInitial.enableReadOnly();

    const term = form.getTextField("term");
    term.setText(this.ModifyString(this.state.term) + " months");
    term.enableReadOnly();

    const moveInDate = form.getTextField("moveInDate");
    moveInDate.setText(
      this.ModifyString(correctDateFormat(this.state.moveInDate))
    );
    moveInDate.enableReadOnly();

    const beginMonthYear = form.getTextField("beginMonthYear");
    beginMonthYear.setText(
      this.ModifyString(
        correctMonthYearFormat(this.state.moveInDate, this.state.term)
      )
    );
    beginMonthYear.enableReadOnly();

    const endMonthYear = form.getTextField("endMonthYear");
    endMonthYear.setText(
      this.ModifyString(addMonthsYear(this.state.moveInDate, this.state.term))
    );
    endMonthYear.enableReadOnly();

    const rentedPremisesQut = form.getTextField("rentedPremisesQut");
    rentedPremisesQut.setText(this.ModifyString(this.state.rentedPremisesQut));
    rentedPremisesQut.enableReadOnly();

    const rentedPremisesPrice = form.getTextField("rentedPremisesPrice");
    rentedPremisesPrice.setText(
      this.ModifyString(this.state.rentedPremisesPrice)
    );
    rentedPremisesPrice.enableReadOnly();

    const parkingQut = form.getTextField("parkingQut");
    parkingQut.setText(this.ModifyString(this.state.parkingQut));
    parkingQut.enableReadOnly();

    const parkingPrice = form.getTextField("parkingPrice");
    parkingPrice.setText(this.ModifyString(this.state.parkingPrice));
    parkingPrice.enableReadOnly();

    const lockerQut = form.getTextField("lockerQut");
    lockerQut.setText(this.ModifyString(this.state.lockerQut));
    lockerQut.enableReadOnly();

    const lockerPrice = form.getTextField("lockerPrice");
    lockerPrice.setText(this.ModifyString(this.state.lockerPrice));
    lockerPrice.enableReadOnly();

    const otherQut = form.getTextField("otherQut");
    otherQut.setText(this.ModifyString(this.state.otherQut));
    otherQut.enableReadOnly();

    const otherPrice = form.getTextField("otherPrice");
    otherPrice.setText(this.ModifyString(this.state.otherPrice));
    otherPrice.enableReadOnly();

    const totalRent = form.getTextField("totalRent");
    totalRent.setText(this.ModifyString(this.state.totalRent));
    totalRent.enableReadOnly();

    const lastMonthRentDeposit = form.getTextField("lastMonthRentDeposit");
    lastMonthRentDeposit.setText(
      this.ModifyString(this.state.lastMonthRentDeposit)
    );
    lastMonthRentDeposit.enableReadOnly();

    const proRatedRent = form.getTextField("proRatedRent");
    proRatedRent.setText(this.ModifyString(this.state.proRatedRent));
    proRatedRent.enableReadOnly();

    const payableFrom = form.getTextField("payableFrom");
    payableFrom.setText(this.ModifyString(this.state.payableFrom));
    payableFrom.enableReadOnly();

    const payableTo = form.getTextField("payableTo");
    payableTo.setText(this.ModifyString(this.state.payableTo));
    payableTo.enableReadOnly();

    const hydroYes = form.getCheckBox("hydroYes");
    const hydroNo = form.getCheckBox("hydroNo");
    if (this.state.hydro) {
      hydroYes.check();
      hydroYes.enableReadOnly();
      hydroNo.enableReadOnly();
    } else {
      hydroNo.check();
      hydroYes.enableReadOnly();
      hydroNo.enableReadOnly();
    }
    const gasYes = form.getCheckBox("gasYes");
    const gasNo = form.getCheckBox("gasNo");
    if (this.state.gas) {
      gasYes.check();
      gasYes.enableReadOnly();
      gasNo.enableReadOnly();
    } else {
      gasNo.check();
      gasYes.enableReadOnly();
      gasNo.enableReadOnly();
    }

    const heatYes = form.getCheckBox("heatYes");
    const heatNo = form.getCheckBox("heatNo");
    if (this.state.heat) {
      heatYes.check();
      heatYes.enableReadOnly();
      heatNo.enableReadOnly();
    } else {
      heatNo.check();
      heatNo.enableReadOnly();
      heatYes.enableReadOnly();
    }

    const hotWaterHeaterYes = form.getCheckBox("hotWaterHeaterYes");
    const hotWaterHeaterNo = form.getCheckBox("hotWaterHeaterNo");
    if (this.state.hotWaterHeater) {
      hotWaterHeaterYes.check();
      hotWaterHeaterYes.enableReadOnly();
      hotWaterHeaterNo.enableReadOnly();
    } else {
      hotWaterHeaterNo.check();
      hotWaterHeaterYes.enableReadOnly();
      hotWaterHeaterNo.enableReadOnly();
    }

    const hotWaterTankYes = form.getCheckBox("hotWaterTankYes");
    const hotWaterTankNo = form.getCheckBox("hotWaterTankNo");
    if (this.state.hotWaterTank) {
      hotWaterTankYes.check();
      hotWaterTankYes.enableReadOnly();
      hotWaterTankNo.enableReadOnly();
    } else {
      hotWaterTankNo.check();
      hotWaterTankYes.enableReadOnly();
      hotWaterTankNo.enableReadOnly();
    }

    const internetYes = form.getCheckBox("internetYes");
    const internetNo = form.getCheckBox("internetNo");
    if (this.state.internet) {
      internetYes.check();
      internetYes.enableReadOnly();
      internetNo.enableReadOnly();
    } else {
      internetNo.check();
      internetYes.enableReadOnly();
      internetNo.enableReadOnly();
    }

    const cableTvYes = form.getCheckBox("cableTvYes");
    const cableTvNo = form.getCheckBox("cableTvNo");
    if (this.state.cableTv) {
      cableTvYes.check();
      cableTvYes.enableReadOnly();
      cableTvNo.enableReadOnly();
    } else {
      cableTvNo.check();
      cableTvYes.enableReadOnly();
      cableTvNo.enableReadOnly();
    }

    const otherYes = form.getCheckBox("otherYes");
    const otherNo = form.getCheckBox("otherNo");
    if (this.state.other) {
      otherYes.check();
      otherYes.enableReadOnly();
      otherNo.enableReadOnly();
    } else {
      otherNo.check();
      otherYes.enableReadOnly();
      otherNo.enableReadOnly();
    }

    const waterYes = form.getCheckBox("waterYes");
    const waterNo = form.getCheckBox("waterNo");
    if (this.state.water) {
      waterYes.check();
      waterYes.enableReadOnly();
      waterNo.enableReadOnly();
    } else {
      waterNo.check();
      waterYes.enableReadOnly();
      waterNo.enableReadOnly();
    }

    const presentAddress = form.getTextField("presentAddress");
    presentAddress.setText(
      this.ModifyString(
        (this.state.presentAddress ? this.state.presentAddress + "," : "") +
          (this.state.presentCity ? this.state.presentCity + "," : "") +
          (this.state.presentState ? this.state.presentState + "," : "")
      )
    );
    presentAddress.enableReadOnly();
    const presentZip = form.getTextField("presentZip");
    presentZip.setText(this.ModifyString(this.state.presentZip));
    presentZip.enableReadOnly();
    const presentMonthlyRent = form.getTextField("presentMonthlyRent");
    presentMonthlyRent.setText(
      this.ModifyString(this.state.presentMonthlyRent)
    );
    presentMonthlyRent.enableReadOnly();
    const presentLengthOfOccupancy = form.getTextField(
      "presentLengthOfOccupancy"
    );
    presentLengthOfOccupancy.setText(
      this.ModifyString(
        betweenYearsMonths(this.state.presentFrom, this.state.presentTo)
      )
    );
    presentLengthOfOccupancy.enableReadOnly();
    const presentReasonLeaving = form.getTextField("presentReasonLeaving");
    presentReasonLeaving.setText(
      this.ModifyString(this.state.presentReasonLeaving)
    );
    presentReasonLeaving.enableReadOnly();
    const presentHomePhone = form.getTextField("presentHomePhone");
    presentHomePhone.setText(this.ModifyString(this.state.presentHomePhone));
    presentHomePhone.enableReadOnly();
    const presentHomeFax = form.getTextField("presentHomeFax");
    presentHomeFax.setText(this.ModifyString(this.state.presentHomeFax));
    presentHomeFax.enableReadOnly();
    const presentLandlord = form.getTextField("presentLandlord");
    presentLandlord.setText(this.ModifyString(this.state.presentLandlord));
    presentLandlord.enableReadOnly();
    const presentLandlordPhone = form.getTextField("presentLandlordPhone");
    presentLandlordPhone.setText(
      this.ModifyString(this.state.presentLandlordPhone)
    );
    presentLandlordPhone.enableReadOnly();
    const presentLandlordIDProof = form.getTextField("presentLandlordIDProof");
    presentLandlordIDProof.setText(
      this.ModifyString(this.state.presentLandlordIDProof)
    );
    presentLandlordIDProof.enableReadOnly();

    const previousAddress = form.getTextField("previousAddress");
    previousAddress.setText(
      this.ModifyString(
        (this.state.previousAddress ? this.state.previousAddress + "," : "") +
          (this.state.previousCity ? this.state.previousCity + "," : "") +
          (this.state.previousState ? this.state.previousState + "," : "")
      )
    );
    previousAddress.enableReadOnly();
    const previousZip = form.getTextField("previousZip");
    previousZip.setText(this.ModifyString(this.state.previousZip));
    previousZip.enableReadOnly();
    const previousMonthlyRent = form.getTextField("previousMonthlyRent");
    previousMonthlyRent.setText(
      this.ModifyString(this.state.previousMonthlyRent)
    );
    previousMonthlyRent.enableReadOnly();
    const previousLengthOfOccupancy = form.getTextField(
      "previousLengthOfOccupancy"
    );
    previousLengthOfOccupancy.setText(
      this.ModifyString(
        betweenYearsMonths(this.state.previousFrom, this.state.previousTo)
      )
    );
    previousLengthOfOccupancy.enableReadOnly();
    const previousReasonLeaving = form.getTextField("previousReasonLeaving");
    previousReasonLeaving.setText(
      this.ModifyString(this.state.previousReasonLeaving)
    );
    previousReasonLeaving.enableReadOnly();
    const previousLandlord = form.getTextField("previousLandlord");
    previousLandlord.setText(this.ModifyString(this.state.previousLandlord));
    previousLandlord.enableReadOnly();
    const previousLandlordPhone = form.getTextField("previousLandlordPhone");
    previousLandlordPhone.setText(
      this.ModifyString(this.state.previousLandlordPhone)
    );
    previousLandlordPhone.enableReadOnly();

    const applicant2PresentAddress = form.getTextField(
      "applicant2PresentAddress"
    );
    applicant2PresentAddress.setText(
      this.ModifyString(
        (this.state.applicant2PresentAddress
          ? this.state.applicant2PresentAddress + ","
          : "") +
          (this.state.applicant2PresentCity
            ? this.state.applicant2PresentCity + ","
            : "") +
          (this.state.applicant2PresentState
            ? this.state.applicant2PresentState + ","
            : "")
      )
    );
    applicant2PresentAddress.enableReadOnly();
    const applicant2PresentZip = form.getTextField("applicant2PresentZip");
    applicant2PresentZip.setText(
      this.ModifyString(this.state.applicant2PresentZip)
    );
    applicant2PresentZip.enableReadOnly();
    const applicant2PresentMonthlyRent = form.getTextField(
      "applicant2PresentMonthlyRent"
    );
    applicant2PresentMonthlyRent.setText(
      this.ModifyString(this.state.applicant2PresentMonthlyRent)
    );
    applicant2PresentMonthlyRent.enableReadOnly();
    const applicant2PresentLengthOfOccupancy = form.getTextField(
      "applicant2PresentLengthOfOccupancy"
    );
    applicant2PresentLengthOfOccupancy.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant2PresentFrom,
          this.state.applicant2PresentTo
        )
      )
    );
    applicant2PresentLengthOfOccupancy.enableReadOnly();
    const applicant2PresentReasonLeaving = form.getTextField(
      "applicant2PresentReasonLeaving"
    );
    applicant2PresentReasonLeaving.setText(
      this.ModifyString(this.state.applicant2PresentReasonLeaving)
    );
    applicant2PresentReasonLeaving.enableReadOnly();
    const applicant2PresentHomePhone = form.getTextField(
      "applicant2PresentHomePhone"
    );
    applicant2PresentHomePhone.setText(
      this.ModifyString(this.state.applicant2PresentHomePhone)
    );
    applicant2PresentHomePhone.enableReadOnly();
    const applicant2PresentHomeFax = form.getTextField(
      "applicant2PresentHomeFax"
    );
    applicant2PresentHomeFax.setText(
      this.ModifyString(this.state.applicant2PresentHomeFax)
    );
    applicant2PresentHomeFax.enableReadOnly();
    const applicant2PresentLandlord = form.getTextField(
      "applicant2PresentLandlord"
    );
    applicant2PresentLandlord.setText(
      this.ModifyString(this.state.applicant2PresentLandlord)
    );
    applicant2PresentLandlord.enableReadOnly();
    const applicant2PresentLandlordPhone = form.getTextField(
      "applicant2PresentLandlordPhone"
    );
    applicant2PresentLandlordPhone.setText(
      this.ModifyString(this.state.applicant2PresentLandlordPhone)
    );
    applicant2PresentLandlordPhone.enableReadOnly();
    const applicant2PresentLandlordIDProof = form.getTextField(
      "applicant2PresentLandlordIDProof"
    );
    applicant2PresentLandlordIDProof.setText(
      this.ModifyString(this.state.applicant2PresentLandlordIDProof)
    );
    applicant2PresentLandlordIDProof.enableReadOnly();

    const applicant2PreviousAddress = form.getTextField(
      "applicant2PreviousAddress"
    );
    applicant2PreviousAddress.setText(
      this.ModifyString(
        (this.state.applicant2PreviousAddress
          ? this.state.applicant2PreviousAddress + ","
          : "") +
          (this.state.applicant2PreviousCity
            ? this.state.applicant2PreviousCity + ","
            : "") +
          (this.state.applicant2PreviousState
            ? this.state.applicant2PreviousState + ","
            : "")
      )
    );
    applicant2PreviousAddress.enableReadOnly();
    const applicant2PreviousZip = form.getTextField("applicant2PreviousZip");
    applicant2PreviousZip.setText(
      this.ModifyString(this.state.applicant2PreviousZip)
    );
    applicant2PreviousZip.enableReadOnly();
    const applicant2PreviousMonthlyRent = form.getTextField(
      "applicant2PreviousMonthlyRent"
    );
    applicant2PreviousMonthlyRent.setText(
      this.ModifyString(this.state.applicant2PreviousMonthlyRent)
    );
    applicant2PreviousMonthlyRent.enableReadOnly();
    const applicant2PreviousLengthOfOccupancy = form.getTextField(
      "applicant2PreviousLengthOfOccupancy"
    );
    applicant2PreviousLengthOfOccupancy.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant2PreviousFrom,
          this.state.applicant2PreviousTo
        )
      )
    );
    applicant2PreviousLengthOfOccupancy.enableReadOnly();
    const applicant2PreviousReasonLeaving = form.getTextField(
      "applicant2PreviousReasonLeaving"
    );
    applicant2PreviousReasonLeaving.setText(
      this.ModifyString(this.state.applicant2PreviousReasonLeaving)
    );
    applicant2PreviousReasonLeaving.enableReadOnly();
    const applicant2PreviousLandlord = form.getTextField(
      "applicant2PreviousLandlord"
    );
    applicant2PreviousLandlord.setText(
      this.ModifyString(this.state.applicant2PreviousLandlord)
    );
    applicant2PreviousLandlord.enableReadOnly();
    const applicant2PreviousLandlordPhone = form.getTextField(
      "applicant2PreviousLandlordPhone"
    );
    applicant2PreviousLandlordPhone.setText(
      this.ModifyString(this.state.applicant2PreviousLandlordPhone)
    );
    applicant2PreviousLandlordPhone.enableReadOnly();

    const applicant3PresentAddress = form.getTextField(
      "applicant3PresentAddress"
    );
    applicant3PresentAddress.setText(
      this.ModifyString(
        (this.state.applicant3PresentAddress
          ? this.state.applicant3PresentAddress + ","
          : "") +
          (this.state.applicant3PresentCity
            ? this.state.applicant3PresentCity + ","
            : "") +
          (this.state.applicant3PresentState
            ? this.state.applicant3PresentState + ","
            : "")
      )
    );
    applicant3PresentAddress.enableReadOnly();
    const applicant3PresentZip = form.getTextField("applicant3PresentZip");
    applicant3PresentZip.setText(
      this.ModifyString(this.state.applicant3PresentZip)
    );
    applicant3PresentZip.enableReadOnly();
    const applicant3PresentMonthlyRent = form.getTextField(
      "applicant3PresentMonthlyRent"
    );
    applicant3PresentMonthlyRent.setText(
      this.ModifyString(this.state.applicant3PresentMonthlyRent)
    );
    applicant3PresentMonthlyRent.enableReadOnly();
    const applicant3PresentLengthOfOccupancy = form.getTextField(
      "applicant3PresentLengthOfOccupancy"
    );
    applicant3PresentLengthOfOccupancy.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant3PresentFrom,
          this.state.applicant3PresentTo
        )
      )
    );
    applicant3PresentLengthOfOccupancy.enableReadOnly();
    const applicant3PresentReasonLeaving = form.getTextField(
      "applicant3PresentReasonLeaving"
    );
    applicant3PresentReasonLeaving.setText(
      this.ModifyString(this.state.applicant3PresentReasonLeaving)
    );
    applicant3PresentReasonLeaving.enableReadOnly();
    const applicant3PresentHomePhone = form.getTextField(
      "applicant3PresentHomePhone"
    );
    applicant3PresentHomePhone.setText(
      this.ModifyString(this.state.applicant3PresentHomePhone)
    );
    applicant3PresentHomePhone.enableReadOnly();
    const applicant3PresentHomeFax = form.getTextField(
      "applicant3PresentHomeFax"
    );
    applicant3PresentHomeFax.setText(
      this.ModifyString(this.state.applicant3PresentHomeFax)
    );
    applicant3PresentHomeFax.enableReadOnly();
    const applicant3PresentLandlord = form.getTextField(
      "applicant3PresentLandlord"
    );
    applicant3PresentLandlord.setText(
      this.ModifyString(this.state.applicant3PresentLandlord)
    );
    applicant3PresentLandlord.enableReadOnly();
    const applicant3PresentLandlordPhone = form.getTextField(
      "applicant3PresentLandlordPhone"
    );
    applicant3PresentLandlordPhone.setText(
      this.ModifyString(this.state.applicant3PresentLandlordPhone)
    );
    applicant3PresentLandlordPhone.enableReadOnly();
    const applicant3PresentLandlordIDProof = form.getTextField(
      "applicant3PresentLandlordIDProof"
    );
    applicant3PresentLandlordIDProof.setText(
      this.ModifyString(this.state.applicant3PresentLandlordIDProof)
    );
    applicant3PresentLandlordIDProof.enableReadOnly();

    const applicant3PreviousAddress = form.getTextField(
      "applicant3PreviousAddress"
    );
    applicant3PreviousAddress.setText(
      this.ModifyString(
        (this.state.applicant3PreviousAddress
          ? this.state.applicant3PreviousAddress + ","
          : "") +
          (this.state.applicant3PreviousCity
            ? this.state.applicant3PreviousCity + ","
            : "") +
          (this.state.applicant3PreviousState
            ? this.state.applicant3PreviousState + ","
            : "")
      )
    );
    applicant3PreviousAddress.enableReadOnly();
    const applicant3PreviousZip = form.getTextField("applicant3PreviousZip");
    applicant3PreviousZip.setText(
      this.ModifyString(this.state.applicant3PreviousZip)
    );
    applicant3PreviousZip.enableReadOnly();
    const applicant3PreviousMonthlyRent = form.getTextField(
      "applicant3PreviousMonthlyRent"
    );
    applicant3PreviousMonthlyRent.setText(
      this.ModifyString(this.state.applicant3PreviousMonthlyRent)
    );
    applicant3PreviousMonthlyRent.enableReadOnly();
    const applicant3PreviousLengthOfOccupancy = form.getTextField(
      "applicant3PreviousLengthOfOccupancy"
    );
    applicant3PreviousLengthOfOccupancy.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant3PreviousFrom,
          this.state.applicant3PreviousTo
        )
      )
    );
    applicant3PreviousLengthOfOccupancy.enableReadOnly();
    const applicant3PreviousReasonLeaving = form.getTextField(
      "applicant3PreviousReasonLeaving"
    );
    applicant3PreviousReasonLeaving.setText(
      this.ModifyString(this.state.applicant3PreviousReasonLeaving)
    );
    applicant3PreviousReasonLeaving.enableReadOnly();
    const applicant3PreviousLandlord = form.getTextField(
      "applicant3PreviousLandlord"
    );
    applicant3PreviousLandlord.setText(
      this.ModifyString(this.state.applicant3PreviousLandlord)
    );
    applicant3PreviousLandlord.enableReadOnly();
    const applicant3PreviousLandlordPhone = form.getTextField(
      "applicant3PreviousLandlordPhone"
    );
    applicant3PreviousLandlordPhone.setText(
      this.ModifyString(this.state.applicant3PreviousLandlordPhone)
    );
    applicant3PreviousLandlordPhone.enableReadOnly();

    const empPresentEmployed = form.getTextField("empPresentEmployed");
    empPresentEmployed.setText(this.ModifyString(this.state.employmentCompany));
    empPresentEmployed.enableReadOnly();
    const empPresentPosition = form.getTextField("empPresentPosition");
    empPresentPosition.setText(
      this.ModifyString(this.state.employmentPosition)
    );
    empPresentPosition.enableReadOnly();
    const empPresentYears = form.getTextField("employmentYears");
    empPresentYears.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.employmentFrom,
          this.state.employmentUntil
        )
      )
    );
    empPresentYears.enableReadOnly();

    const empPresentAnnualIncome = form.getTextField("empPresentAnnualIncome");
    empPresentAnnualIncome.setText(
      this.ModifyString(this.state.employmentAnnualIncome)
    );
    empPresentAnnualIncome.enableReadOnly();
    const empPresentAddress = form.getTextField("empPresentAddress");
    empPresentAddress.setText(
      this.ModifyString(
        (this.state.employmentAddress
          ? this.state.employmentAddress + ","
          : "") +
          (this.state.employmentCity ? this.state.employmentCity + "," : "") +
          (this.state.employmentState ? this.state.employmentState + "," : "") +
          (this.state.employmentZip ? this.state.employmentZip + "," : "")
      )
    );
    empPresentAddress.enableReadOnly();
    const empPresentPhone = form.getTextField("empPresentPhone");
    empPresentPhone.setText(this.ModifyString(this.state.employmentPhone));
    empPresentPhone.enableReadOnly();
    const empPreviousEmployed = form.getTextField("empPreviousEmployed");
    empPreviousEmployed.setText(
      this.ModifyString(
        this.state.preEmploymentCompany +
          " " +
          (this.state.preEmploymentAddress
            ? this.state.preEmploymentAddress + ","
            : "") +
          (this.state.preEmploymentCity
            ? this.state.preEmploymentCity + ","
            : "") +
          (this.state.preEmploymentState
            ? this.state.preEmploymentState + ","
            : "") +
          (this.state.preEmploymentZip ? this.state.preEmploymentZip + "," : "")
      )
    );
    empPreviousEmployed.enableReadOnly();
    const empPreviousYears = form.getTextField("empPreviousFrom");
    empPreviousYears.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.preEmploymentFrom,
          this.state.preEmploymentUntil
        )
      )
    );
    empPreviousYears.enableReadOnly();
    const empPreviousPosition = form.getTextField("empPreviousPosition");
    empPreviousPosition.setText(
      this.ModifyString(this.state.preEmploymentPosition)
    );
    empPreviousPosition.enableReadOnly();
    const empPrevious2Employed = form.getTextField("empPrevious2Employed");
    empPrevious2Employed.setText(
      this.ModifyString(
        this.state.preEmployment2Company +
          " " +
          (this.state.preEmployment2Address
            ? this.state.preEmployment2Address + ","
            : "") +
          (this.state.preEmployment2City
            ? this.state.preEmployment2City + ","
            : "") +
          (this.state.preEmployment2State
            ? this.state.preEmployment2State + ","
            : "") +
          (this.state.preEmployment2Zip
            ? this.state.preEmployment2Zip + ","
            : "")
      )
    );
    empPrevious2Employed.enableReadOnly();
    const empPrevious2Years = form.getTextField("empPrevious2From");
    empPrevious2Years.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.preEmployment2From,
          this.state.preEmployment2Until
        )
      )
    );
    empPrevious2Years.enableReadOnly();
    const empPrevious2Position = form.getTextField("empPrevious2Position");
    empPrevious2Position.setText(
      this.ModifyString(this.state.preEmployment2Position)
    );
    empPrevious2Position.enableReadOnly();

    ////////////////////////
    const applicant2EmploymentEmployed = form.getTextField(
      "applicant2EmploymentEmployed"
    );
    applicant2EmploymentEmployed.setText(
      this.ModifyString(this.state.applicant2EmploymentCompany)
    );
    applicant2EmploymentEmployed.enableReadOnly();
    const applicant2EmploymentPosition = form.getTextField(
      "applicant2EmploymentPosition"
    );
    applicant2EmploymentPosition.setText(
      this.ModifyString(this.state.applicant2EmploymentPosition)
    );
    applicant2EmploymentPosition.enableReadOnly();
    const applicant2EmploymentYears = form.getTextField(
      "applicant2EmploymentYears"
    );
    applicant2EmploymentYears.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant2EmploymentFrom,
          this.state.applicant2EmploymentUntil
        )
      )
    );
    applicant2EmploymentYears.enableReadOnly();

    const applicant2EmploymentAnnualIncome = form.getTextField(
      "applicant2EmploymentAnnualIncome"
    );
    applicant2EmploymentAnnualIncome.setText(
      this.ModifyString(this.state.applicant2EmploymentAnnualIncome)
    );
    applicant2EmploymentAnnualIncome.enableReadOnly();
    const applicant2EmploymentAddress = form.getTextField(
      "applicant2EmploymentAddress"
    );
    applicant2EmploymentAddress.setText(
      this.ModifyString(
        (this.state.applicant2EmploymentAddress
          ? this.state.applicant2EmploymentAddress + ","
          : "") +
          (this.state.applicant2EmploymentCity
            ? this.state.applicant2EmploymentCity + ","
            : "") +
          (this.state.applicant2EmploymentState
            ? this.state.applicant2EmploymentState + ","
            : "") +
          (this.state.applicant2EmploymentZip
            ? this.state.applicant2EmploymentZip + ","
            : "")
      )
    );
    applicant2EmploymentAddress.enableReadOnly();
    const applicant2EmploymentPhone = form.getTextField(
      "applicant2EmploymentPhone"
    );
    applicant2EmploymentPhone.setText(
      this.ModifyString(this.state.applicant2EmploymentPhone)
    );
    applicant2EmploymentPhone.enableReadOnly();
    const applicant2PreEmploymentEmployed = form.getTextField(
      "applicant2PreEmploymentEmployed"
    );
    applicant2PreEmploymentEmployed.setText(
      this.ModifyString(
        this.state.applicant2PreEmploymentCompany +
          " " +
          (this.state.applicant2PreEmploymentAddress
            ? this.state.applicant2PreEmploymentAddress + ","
            : "") +
          (this.state.applicant2PreEmploymentCity
            ? this.state.applicant2PreEmploymentCity + ","
            : "") +
          (this.state.applicant2PreEmploymentState
            ? this.state.applicant2PreEmploymentState + ","
            : "") +
          (this.state.applicant2PreEmploymentZip
            ? this.state.applicant2PreEmploymentZip + ","
            : "")
      )
    );
    applicant2PreEmploymentEmployed.enableReadOnly();
    const applicant2PreEmploymentYears = form.getTextField(
      "applicant2PreEmploymentFrom"
    );
    applicant2PreEmploymentYears.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant2PreEmploymentFrom,
          this.state.applicant2PreEmploymentUntil
        )
      )
    );
    applicant2PreEmploymentYears.enableReadOnly();
    const applicant2PreEmploymentPosition = form.getTextField(
      "applicant2PreEmploymentPosition"
    );
    applicant2PreEmploymentPosition.setText(
      this.ModifyString(this.state.applicant2PreEmploymentPosition)
    );
    applicant2PreEmploymentPosition.enableReadOnly();

    const applicant2PreEmployment2Employed = form.getTextField(
      "applicant2PreEmployment2Employed"
    );
    applicant2PreEmployment2Employed.setText(
      this.ModifyString(
        this.state.applicant2PreEmployment2Company +
          " " +
          (this.state.applicant2PreEmployment2Address
            ? this.state.applicant2PreEmployment2Address + ","
            : "") +
          (this.state.applicant2PreEmployment2City
            ? this.state.applicant2PreEmployment2City + ","
            : "") +
          (this.state.applicant2PreEmployment2State
            ? this.state.applicant2PreEmployment2State + ","
            : "") +
          (this.state.applicant2PreEmployment2Zip
            ? this.state.applicant2PreEmployment2Zip + ","
            : "")
      )
    );
    applicant2PreEmployment2Employed.enableReadOnly();
    const applicant2PreEmployment2Years = form.getTextField(
      "applicant2PreEmployment2From"
    );
    applicant2PreEmployment2Years.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant2PreEmployment2From,
          this.state.applicant2PreEmployment2Until
        )
      )
    );
    applicant2PreEmployment2Years.enableReadOnly();
    const applicant2PreEmployment2Position = form.getTextField(
      "applicant2PreEmployment2Position"
    );
    applicant2PreEmployment2Position.setText(
      this.ModifyString(this.state.applicant2PreEmployment2Position)
    );
    applicant2PreEmployment2Position.enableReadOnly();

    /////////////////////

    const applicant3EmploymentEmployed = form.getTextField(
      "applicant3EmploymentEmployed"
    );
    applicant3EmploymentEmployed.setText(
      this.ModifyString(this.state.applicant3EmploymentCompany)
    );
    applicant3EmploymentEmployed.enableReadOnly();
    const applicant3EmploymentPosition = form.getTextField(
      "applicant3EmploymentPosition"
    );
    applicant3EmploymentPosition.setText(
      this.ModifyString(this.state.applicant3EmploymentPosition)
    );
    applicant3EmploymentPosition.enableReadOnly();
    const applicant3EmploymentYears = form.getTextField(
      "applicant3EmploymentYears"
    );
    applicant3EmploymentYears.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant3EmploymentFrom,
          this.state.applicant3EmploymentUntil
        )
      )
    );
    applicant3EmploymentYears.enableReadOnly();

    const applicant3EmploymentAnnualIncome = form.getTextField(
      "applicant3EmploymentAnnualIncome"
    );
    applicant3EmploymentAnnualIncome.setText(
      this.ModifyString(this.state.applicant3EmploymentAnnualIncome)
    );
    applicant3EmploymentAnnualIncome.enableReadOnly();
    const applicant3EmploymentAddress = form.getTextField(
      "applicant3EmploymentAddress"
    );
    applicant3EmploymentAddress.setText(
      this.ModifyString(
        (this.state.applicant3EmploymentAddress
          ? this.state.applicant3EmploymentAddress + ","
          : "") +
          (this.state.applicant3EmploymentCity
            ? this.state.applicant3EmploymentCity + ","
            : "") +
          (this.state.applicant3EmploymentState
            ? this.state.applicant3EmploymentState + ","
            : "") +
          (this.state.applicant3EmploymentZip
            ? this.state.applicant3EmploymentZip + ","
            : "")
      )
    );
    applicant3EmploymentAddress.enableReadOnly();
    const applicant3EmploymentPhone = form.getTextField(
      "applicant3EmploymentPhone"
    );
    applicant3EmploymentPhone.setText(
      this.ModifyString(this.state.applicant3EmploymentPhone)
    );
    applicant3EmploymentPhone.enableReadOnly();
    const applicant3PreEmploymentEmployed = form.getTextField(
      "applicant3PreEmploymentEmployed"
    );
    applicant3PreEmploymentEmployed.setText(
      this.ModifyString(
        this.state.applicant3PreEmploymentCompany +
          " " +
          (this.state.applicant3PreEmploymentAddress
            ? this.state.applicant3PreEmploymentAddress + ","
            : "") +
          (this.state.applicant3PreEmploymentCity
            ? this.state.applicant3PreEmploymentCity + ","
            : "") +
          (this.state.applicant3PreEmploymentState
            ? this.state.applicant3PreEmploymentState + ","
            : "") +
          (this.state.applicant3PreEmploymentZip
            ? this.state.applicant3PreEmploymentZip + ","
            : "")
      )
    );
    applicant3PreEmploymentEmployed.enableReadOnly();
    const applicant3PreEmploymentYears = form.getTextField(
      "applicant3PreEmploymentFrom"
    );
    applicant3PreEmploymentYears.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant3PreEmploymentFrom,
          this.state.applicant3PreEmploymentUntil
        )
      )
    );
    applicant3PreEmploymentYears.enableReadOnly();
    const applicant3PreEmploymentPosition = form.getTextField(
      "applicant3PreEmploymentPosition"
    );
    applicant3PreEmploymentPosition.setText(
      this.ModifyString(this.state.applicant3PreEmploymentPosition)
    );
    applicant3PreEmploymentPosition.enableReadOnly();

    const applicant3PreEmployment2Employed = form.getTextField(
      "applicant3PreEmployment2Employed"
    );
    applicant3PreEmployment2Employed.setText(
      this.ModifyString(
        this.state.applicant3PreEmployment2Company +
          " " +
          (this.state.applicant3PreEmployment2Address
            ? this.state.applicant3PreEmployment2Address + ","
            : "") +
          (this.state.applicant3PreEmployment2City
            ? this.state.applicant3PreEmployment2City + ","
            : "") +
          (this.state.applicant3PreEmployment2State
            ? this.state.applicant3PreEmployment2State + ","
            : "") +
          (this.state.applicant3PreEmployment2Zip
            ? this.state.applicant3PreEmployment2Zip + ","
            : "")
      )
    );
    applicant3PreEmployment2Employed.enableReadOnly();
    const applicant3PreEmployment2Years = form.getTextField(
      "applicant3PreEmployment2From"
    );
    applicant3PreEmployment2Years.setText(
      this.ModifyString(
        betweenYearsMonths(
          this.state.applicant3PreEmployment2From,
          this.state.applicant3PreEmployment2Until
        )
      )
    );
    applicant3PreEmployment2Years.enableReadOnly();
    const applicant3PreEmployment2Position = form.getTextField(
      "applicant3PreEmployment2Position"
    );
    applicant3PreEmployment2Position.setText(
      this.ModifyString(this.state.applicant3PreEmployment2Position)
    );
    applicant3PreEmployment2Position.enableReadOnly();

    const spousesEmployer = form.getTextField("spousesEmployer");
    spousesEmployer.setText(this.ModifyString(this.state.spousesEmployer));
    spousesEmployer.enableReadOnly();
    const spousesEmployerAddress = form.getTextField("spousesEmployerAddress");
    spousesEmployerAddress.setText(
      this.ModifyString(this.state.spousesEmployerAddress)
    );
    spousesEmployerAddress.enableReadOnly();
    const spousesIncome = form.getTextField("spousesIncome");
    spousesIncome.setText(this.ModifyString(this.state.spousesIncome));
    spousesIncome.enableReadOnly();

    const applicant2SpousesEmployer = form.getTextField(
      "applicant2SpousesEmployer"
    );
    applicant2SpousesEmployer.setText(
      this.ModifyString(this.state.applicant2SpousesEmployer)
    );
    applicant2SpousesEmployer.enableReadOnly();
    const applicant2SpousesEmployerAddress = form.getTextField(
      "applicant2SpousesEmployerAddress"
    );
    applicant2SpousesEmployerAddress.setText(
      this.ModifyString(this.state.applicant2SpousesEmployerAddress)
    );
    applicant2SpousesEmployerAddress.enableReadOnly();
    const applicant2SpousesIncome = form.getTextField(
      "applicant2SpousesIncome"
    );
    applicant2SpousesIncome.setText(
      this.ModifyString(this.state.applicant2SpousesIncome)
    );
    applicant2SpousesIncome.enableReadOnly();
    const applicant3SpousesEmployer = form.getTextField(
      "applicant3SpousesEmployer"
    );
    applicant3SpousesEmployer.setText(
      this.ModifyString(this.state.applicant3SpousesEmployer)
    );
    applicant3SpousesEmployer.enableReadOnly();
    const applicant3SpousesEmployerAddress = form.getTextField(
      "applicant3SpousesEmployerAddress"
    );
    applicant3SpousesEmployerAddress.setText(
      this.ModifyString(this.state.applicant3SpousesEmployerAddress)
    );
    applicant3SpousesEmployerAddress.enableReadOnly();
    const applicant3SpousesIncome = form.getTextField(
      "applicant3SpousesIncome"
    );
    applicant3SpousesIncome.setText(
      this.ModifyString(this.state.applicant3SpousesIncome)
    );
    applicant3SpousesIncome.enableReadOnly();

    const applicantPrimaryBankName = form.getTextField(
      "applicantPrimaryBankName"
    );
    applicantPrimaryBankName.setText(
      this.ModifyString(
        this.state.applicantPrimaryBankName +
          (this.state.applicantPrimaryBankBranch && ",") +
          this.state.applicantPrimaryBankBranch
      )
    );
    applicantPrimaryBankName.enableReadOnly();
    const applicant2PrimaryBankName = form.getTextField(
      "applicant2PrimaryBankName"
    );
    applicant2PrimaryBankName.setText(
      this.ModifyString(
        this.state.applicant2PrimaryBankName +
          (this.state.applicant2PrimaryBankBranch && ",") +
          this.state.applicant2PrimaryBankBranch
      )
    );
    applicant2PrimaryBankName.enableReadOnly();
    const applicant3PrimaryBankName = form.getTextField(
      "applicant3PrimaryBankName"
    );
    applicant3PrimaryBankName.setText(
      this.ModifyString(
        this.state.applicant3PrimaryBankName +
          (this.state.applicant3PrimaryBankBranch && ",") +
          this.state.applicant3PrimaryBankBranch
      )
    );
    applicant3PrimaryBankName.enableReadOnly();

    const applicantPrimaryBankAccNo = form.getTextField(
      "applicantPrimaryBankAccNo"
    );
    applicantPrimaryBankAccNo.setText(
      this.ModifyString(this.state.applicantPrimaryBankAccNo)
    );
    applicantPrimaryBankAccNo.enableReadOnly();
    const applicant2PrimaryBankAccNo = form.getTextField(
      "applicant2PrimaryBankAccNo"
    );
    applicant2PrimaryBankAccNo.setText(
      this.ModifyString(this.state.applicant2PrimaryBankAccNo)
    );
    applicant2PrimaryBankAccNo.enableReadOnly();
    const applicant3PrimaryBankAccNo = form.getTextField(
      "applicant3PrimaryBankAccNo"
    );
    applicant3PrimaryBankAccNo.setText(
      this.ModifyString(this.state.applicant3PrimaryBankAccNo)
    );
    applicant3PrimaryBankAccNo.enableReadOnly();

    if (this.state.applicantPrimaryBankAccType === "Checking") {
      form.getCheckBox("applicantPrimaryBankAccTypeChq").check();
    } else if (this.state.applicantPrimaryBankAccType === "Saving") {
      form.getCheckBox("applicantPrimaryBankAccTypeSaving").check();
    } else if (this.state.applicantPrimaryBankAccType === "Trust") {
      form.getCheckBox("applicantPrimaryBankAccTypeTrust").check();
    }

    const applicantPrimaryBankAccTypeChq = form.getCheckBox(
      "applicantPrimaryBankAccTypeChq"
    );
    applicantPrimaryBankAccTypeChq.enableReadOnly();
    const applicantPrimaryBankAccTypeSaving = form.getCheckBox(
      "applicantPrimaryBankAccTypeSaving"
    );
    applicantPrimaryBankAccTypeSaving.enableReadOnly();
    const applicantPrimaryBankAccTypeTrust = form.getCheckBox(
      "applicantPrimaryBankAccTypeTrust"
    );
    applicantPrimaryBankAccTypeTrust.enableReadOnly();

    if (this.state.applicant2PrimaryBankAccType === "Checking") {
      form.getCheckBox("applicant2PrimaryBankAccTypeChq").check();
    } else if (this.state.applicant2PrimaryBankAccType === "Saving") {
      form.getCheckBox("applicant2PrimaryBankAccTypeSaving").check();
    } else if (this.state.applicant2PrimaryBankAccType === "Trust") {
      form.getCheckBox("applicant2PrimaryBankAccTypeTrust").check();
    }

    const applicant2PrimaryBankAccTypeChq = form.getCheckBox(
      "applicant2PrimaryBankAccTypeChq"
    );
    applicant2PrimaryBankAccTypeChq.enableReadOnly();
    const applicant2PrimaryBankAccTypeSaving = form.getCheckBox(
      "applicant2PrimaryBankAccTypeSaving"
    );
    applicant2PrimaryBankAccTypeSaving.enableReadOnly();
    const applicant2PrimaryBankAccTypeTrust = form.getCheckBox(
      "applicant2PrimaryBankAccTypeTrust"
    );
    applicant2PrimaryBankAccTypeTrust.enableReadOnly();

    if (this.state.applicant3PrimaryBankAccType === "Checking") {
      form.getCheckBox("applicant3PrimaryBankAccTypeChq").check();
    } else if (this.state.applicant3PrimaryBankAccType === "Saving") {
      form.getCheckBox("applicant3PrimaryBankAccTypeSaving").check();
    } else if (this.state.applicant3PrimaryBankAccType === "Trust") {
      form.getCheckBox("applicant3PrimaryBankAccTypeTrust").check();
    }

    const applicant3PrimaryBankAccTypeChq = form.getCheckBox(
      "applicant3PrimaryBankAccTypeChq"
    );
    applicant3PrimaryBankAccTypeChq.enableReadOnly();
    const applicant3PrimaryBankAccTypeSaving = form.getCheckBox(
      "applicant3PrimaryBankAccTypeSaving"
    );
    applicant3PrimaryBankAccTypeSaving.enableReadOnly();
    const applicant3PrimaryBankAccTypeTrust = form.getCheckBox(
      "applicant3PrimaryBankAccTypeTrust"
    );
    applicant3PrimaryBankAccTypeTrust.enableReadOnly();

    const applicantSecondaryBankName = form.getTextField(
      "applicantSecondaryBankName"
    );
    applicantSecondaryBankName.setText(
      this.ModifyString(
        this.state.applicantSecondaryBankName +
          (this.state.applicantSecondaryBankBranch && ",") +
          this.state.applicantSecondaryBankBranch
      )
    );
    applicantSecondaryBankName.enableReadOnly();
    const applicant2SecondaryBankName = form.getTextField(
      "applicant2SecondaryBankName"
    );
    applicant2SecondaryBankName.setText(
      this.ModifyString(
        this.state.applicant2SecondaryBankName +
          (this.state.applicant2SecondaryBankBranch && ",") +
          this.state.applicant2SecondaryBankBranch
      )
    );
    applicant2SecondaryBankName.enableReadOnly();
    const applicant3SecondaryBankName = form.getTextField(
      "applicant3SecondaryBankName"
    );
    applicant3SecondaryBankName.setText(
      this.ModifyString(
        this.state.applicant3SecondaryBankName +
          (this.state.applicant3SecondaryBankBranch && ",") +
          this.state.applicant3SecondaryBankBranch
      )
    );
    applicant3SecondaryBankName.enableReadOnly();

    const applicantSecondaryBankAccNo = form.getTextField(
      "applicantSecondaryBankAccNo"
    );
    applicantSecondaryBankAccNo.setText(
      this.ModifyString(this.state.applicantSecondaryBankAccNo)
    );
    applicantSecondaryBankAccNo.enableReadOnly();
    const applicant2SecondaryBankAccNo = form.getTextField(
      "applicant2SecondaryBankAccNo"
    );
    applicant2SecondaryBankAccNo.setText(
      this.ModifyString(this.state.applicant2SecondaryBankAccNo)
    );
    applicant2SecondaryBankAccNo.enableReadOnly();
    const applicant3SecondaryBankAccNo = form.getTextField(
      "applicant3SecondaryBankAccNo"
    );
    applicant3SecondaryBankAccNo.setText(
      this.ModifyString(this.state.applicant3SecondaryBankAccNo)
    );
    applicant3SecondaryBankAccNo.enableReadOnly();

    if (this.state.applicantSecondaryBankAccType === "Checking") {
      form.getCheckBox("applicantSecondaryBankAccTypeChq").check();
    } else if (this.state.applicantSecondaryBankAccType === "Saving") {
      form.getCheckBox("applicantSecondaryBankAccTypeSaving").check();
    } else if (this.state.applicantSecondaryBankAccType === "Trust") {
      form.getCheckBox("applicantSecondaryBankAccTypeTrust").check();
    }

    const applicantSecondaryBankAccTypeChq = form.getCheckBox(
      "applicantSecondaryBankAccTypeChq"
    );
    applicantSecondaryBankAccTypeChq.enableReadOnly();
    const applicantSecondaryBankAccTypeSaving = form.getCheckBox(
      "applicantSecondaryBankAccTypeSaving"
    );
    applicantSecondaryBankAccTypeSaving.enableReadOnly();
    const applicantSecondaryBankAccTypeTrust = form.getCheckBox(
      "applicantSecondaryBankAccTypeTrust"
    );
    applicantSecondaryBankAccTypeTrust.enableReadOnly();

    if (this.state.applicant2SecondaryBankAccType === "Checking") {
      form.getCheckBox("applicant2SecondaryBankAccTypeChq").check();
    } else if (this.state.applicant2SecondaryBankAccType === "Saving") {
      form.getCheckBox("applicant2SecondaryBankAccTypeSaving").check();
    } else if (this.state.applicant2SecondaryBankAccType === "Trust") {
      form.getCheckBox("applicant2SecondaryBankAccTypeTrust").check();
    }

    const applicant2SecondaryBankAccTypeChq = form.getCheckBox(
      "applicant2SecondaryBankAccTypeChq"
    );
    applicant2SecondaryBankAccTypeChq.enableReadOnly();
    const applicant2SecondaryBankAccTypeSaving = form.getCheckBox(
      "applicant2SecondaryBankAccTypeSaving"
    );
    applicant2SecondaryBankAccTypeSaving.enableReadOnly();
    const applicant2SecondaryBankAccTypeTrust = form.getCheckBox(
      "applicant2SecondaryBankAccTypeTrust"
    );
    applicant2SecondaryBankAccTypeTrust.enableReadOnly();

    if (this.state.applicant3SecondaryBankAccType === "Checking") {
      form.getCheckBox("applicant3SecondaryBankAccTypeChq").check();
    } else if (this.state.applicant3SecondaryBankAccType === "Saving") {
      form.getCheckBox("applicant3SecondaryBankAccTypeSaving").check();
    } else if (this.state.applicant3SecondaryBankAccType === "Trust") {
      form.getCheckBox("applicant3SecondaryBankAccTypeTrust").check();
    }

    const applicant3SecondaryBankAccTypeChq = form.getCheckBox(
      "applicant3SecondaryBankAccTypeChq"
    );
    applicant3SecondaryBankAccTypeChq.enableReadOnly();
    const applicant3SecondaryBankAccTypeSaving = form.getCheckBox(
      "applicant3SecondaryBankAccTypeSaving"
    );
    applicant3SecondaryBankAccTypeSaving.enableReadOnly();
    const applicant3SecondaryBankAccTypeTrust = form.getCheckBox(
      "applicant3SecondaryBankAccTypeTrust"
    );
    applicant3SecondaryBankAccTypeTrust.enableReadOnly();

    const applicantDriversLicense = form.getTextField(
      "applicantDriversLicense"
    );
    applicantDriversLicense.setText(
      this.ModifyString(this.state.applicantDriversLicense)
    );
    applicantDriversLicense.enableReadOnly();
    const applicantVehicleMadeBy = form.getTextField("applicantVehicleMadeBy");
    applicantVehicleMadeBy.setText(
      this.ModifyString(this.state.applicantVehicleMadeBy)
    );
    applicantVehicleMadeBy.enableReadOnly();
    const applicantVehicleYear = form.getTextField("applicantVehicleYear");
    applicantVehicleYear.setText(
      this.ModifyString(
        this.state.applicantVehicleModel + " " + this.state.applicantVehicleYear
      )
    );
    applicantVehicleYear.enableReadOnly();
    const applicantVehicleLicenseNumber = form.getTextField(
      "applicantVehicleLicenseNumber"
    );
    applicantVehicleLicenseNumber.setText(
      this.ModifyString(this.state.applicantVehicleLicenseNumber)
    );
    applicantVehicleLicenseNumber.enableReadOnly();

    const applicant2DriversLicense = form.getTextField(
      "applicant2DriversLicense"
    );
    applicant2DriversLicense.setText(
      this.ModifyString(this.state.applicant2DriversLicense)
    );
    applicant2DriversLicense.enableReadOnly();

    const applicant2VehicleMadeBy = form.getTextField(
      "applicant2VehicleMadeBy"
    );
    applicant2VehicleMadeBy.setText(
      this.ModifyString(this.state.applicant2VehicleMadeBy)
    );
    applicant2VehicleMadeBy.enableReadOnly();

    const applicant2VehicleYear = form.getTextField("applicant2VehicleYear");
    applicant2VehicleYear.setText(
      this.ModifyString(
        this.state.applicant2VehicleModel +
          " " +
          this.state.applicant2VehicleYear
      )
    );
    applicant2VehicleYear.enableReadOnly();
    const applicant2VehicleLicenseNumber = form.getTextField(
      "applicant2VehicleLicenseNumber"
    );
    applicant2VehicleLicenseNumber.setText(
      this.ModifyString(this.state.applicant2VehicleLicenseNumber)
    );
    applicant2VehicleLicenseNumber.enableReadOnly();

    const applicant3DriversLicense = form.getTextField(
      "applicant3DriversLicense"
    );
    applicant3DriversLicense.setText(
      this.ModifyString(this.state.applicant3DriversLicense)
    );
    applicant3DriversLicense.enableReadOnly();
    const applicant3VehicleMadeBy = form.getTextField(
      "applicant3VehicleMadeBy"
    );
    applicant3VehicleMadeBy.setText(
      this.ModifyString(this.state.applicant3VehicleMadeBy)
    );
    applicant3VehicleMadeBy.enableReadOnly();
    const applicant3VehicleYear = form.getTextField("applicant3VehicleYear");
    applicant3VehicleYear.setText(
      this.ModifyString(
        this.state.applicant3VehicleModel +
          " " +
          this.state.applicant3VehicleYear
      )
    );
    applicant3VehicleYear.enableReadOnly();
    const applicant3VehicleLicenseNumber = form.getTextField(
      "applicant3VehicleLicenseNumber"
    );
    applicant3VehicleLicenseNumber.setText(
      this.ModifyString(this.state.applicant3VehicleLicenseNumber)
    );
    applicant3VehicleLicenseNumber.enableReadOnly();

    const applicantFirstReferencesName = form.getTextField(
      "applicantReferencesName"
    );
    applicantFirstReferencesName.setText(
      this.ModifyString(this.state.applicantFirstReferencesName)
    );
    applicantFirstReferencesName.enableReadOnly();
    const applicantFirstReferencesAddress = form.getTextField(
      "applicantReferencesAddress"
    );
    applicantFirstReferencesAddress.setText(
      this.ModifyString(this.state.applicantFirstReferencesAddress)
    );
    applicantFirstReferencesAddress.enableReadOnly();
    const applicantFirstReferencesTelephone = form.getTextField(
      "applicantReferencesPhone"
    );
    applicantFirstReferencesTelephone.setText(
      this.ModifyString(this.state.applicantFirstReferencesTelephone)
    );
    applicantFirstReferencesTelephone.enableReadOnly();
    const applicantSecondReferencesName = form.getTextField(
      "applicantReferences2Name"
    );
    applicantSecondReferencesName.setText(
      this.ModifyString(this.state.applicantSecondReferencesName)
    );
    applicantSecondReferencesName.enableReadOnly();
    const applicantSecondReferencesAddress = form.getTextField(
      "applicantReferences2Address"
    );
    applicantSecondReferencesAddress.setText(
      this.ModifyString(this.state.applicantSecondReferencesAddress)
    );
    applicantSecondReferencesAddress.enableReadOnly();
    const applicantSecondReferencesTelephone = form.getTextField(
      "applicantReferences2Phone"
    );
    applicantSecondReferencesTelephone.setText(
      this.ModifyString(this.state.applicantSecondReferencesTelephone)
    );
    applicantSecondReferencesTelephone.enableReadOnly();

    const applicant2FirstReferencesName = form.getTextField(
      "applicant2ReferencesName"
    );
    applicant2FirstReferencesName.setText(
      this.ModifyString(this.state.applicant2FirstReferencesName)
    );
    applicant2FirstReferencesName.enableReadOnly();
    const applicant2FirstReferencesAddress = form.getTextField(
      "applicant2ReferencesAddress"
    );
    applicant2FirstReferencesAddress.setText(
      this.ModifyString(this.state.applicant2FirstReferencesAddress)
    );
    applicant2FirstReferencesAddress.enableReadOnly();
    const applicant2FirstReferencesTelephone = form.getTextField(
      "applicant2ReferencesPhone"
    );
    applicant2FirstReferencesTelephone.setText(
      this.ModifyString(this.state.applicant2FirstReferencesTelephone)
    );
    applicant2FirstReferencesTelephone.enableReadOnly();
    const applicant2SecondReferencesName = form.getTextField(
      "applicant2References2Name"
    );
    applicant2SecondReferencesName.setText(
      this.ModifyString(this.state.applicant2SecondReferencesName)
    );
    applicant2SecondReferencesName.enableReadOnly();
    const applicant2SecondReferencesAddress = form.getTextField(
      "applicant2References2Address"
    );
    applicant2SecondReferencesAddress.setText(
      this.ModifyString(this.state.applicant2SecondReferencesAddress)
    );
    applicant2SecondReferencesAddress.enableReadOnly();
    const applicant2SecondReferencesTelephone = form.getTextField(
      "applicant2References2Phone"
    );
    applicant2SecondReferencesTelephone.setText(
      this.ModifyString(this.state.applicant2SecondReferencesTelephone)
    );
    applicant2SecondReferencesTelephone.enableReadOnly();

    const applicant3FirstReferencesName = form.getTextField(
      "applicant3ReferencesName"
    );
    applicant3FirstReferencesName.setText(
      this.ModifyString(this.state.applicant3FirstReferencesName)
    );
    applicant3FirstReferencesName.enableReadOnly();
    const applicant3FirstReferencesAddress = form.getTextField(
      "applicant3ReferencesAddress"
    );
    applicant3FirstReferencesAddress.setText(
      this.ModifyString(this.state.applicant3FirstReferencesAddress)
    );
    applicant3FirstReferencesAddress.enableReadOnly();
    const applicant3FirstReferencesTelephone = form.getTextField(
      "applicant3ReferencesPhone"
    );
    applicant3FirstReferencesTelephone.setText(
      this.ModifyString(this.state.applicant3FirstReferencesTelephone)
    );
    applicant3FirstReferencesTelephone.enableReadOnly();
    const applicant3SecondReferencesName = form.getTextField(
      "applicant3References2Name"
    );
    applicant3SecondReferencesName.setText(
      this.ModifyString(this.state.applicant3SecondReferencesName)
    );
    applicant3SecondReferencesName.enableReadOnly();
    const applicant3SecondReferencesAddress = form.getTextField(
      "applicant3References2Address"
    );
    applicant3SecondReferencesAddress.setText(
      this.ModifyString(this.state.applicant3SecondReferencesAddress)
    );
    applicant3SecondReferencesAddress.enableReadOnly();
    const applicant3SecondReferencesTelephone = form.getTextField(
      "applicant3References2Phone"
    );
    applicant3SecondReferencesTelephone.setText(
      this.ModifyString(this.state.applicant3SecondReferencesTelephone)
    );
    applicant3SecondReferencesTelephone.enableReadOnly();

    if (this.state.summaryMoney === "MoneyOrder") {
      form.getCheckBox("summaryMoneyOrder").check();
    }
    if (this.state.summaryMoney === "Chq") {
      form.getCheckBox("summaryChq").check();
    }
    const summaryMoneyOrder = form.getCheckBox("summaryMoneyOrder");
    summaryMoneyOrder.enableReadOnly();
    const summaryChq = form.getCheckBox("summaryChq");
    summaryChq.enableReadOnly();
    const summaryProRatedRent = form.getTextField("summaryProRatedRent");
    summaryProRatedRent.setText(
      this.ModifyString(this.state.summaryProRatedRent)
    );
    summaryProRatedRent.enableReadOnly();

    const summaryFirstMonthRent = form.getTextField("summaryFirstMonthRent");
    summaryFirstMonthRent.setText(
      this.ModifyString(this.state.summaryFirstMonthRent)
    );
    summaryFirstMonthRent.enableReadOnly();

    const summaryLastMonthRent = form.getTextField("summaryLastMonthRent");
    summaryLastMonthRent.setText(
      this.ModifyString(this.state.summaryLastMonthRent)
    );
    summaryLastMonthRent.enableReadOnly();

    const summaryTotal = form.getTextField("summaryTotal");
    summaryTotal.setText(this.ModifyString(this.state.summaryTotal));
    summaryTotal.enableReadOnly();

    const emergencyContactName = form.getTextField("emergencyContactName");
    emergencyContactName.setText(
      this.ModifyString(this.state.emergencyContactName)
    );
    emergencyContactName.enableReadOnly();
    const emergencyContactPhone = form.getTextField("emergencyContactPhone");
    emergencyContactPhone.setText(
      this.ModifyString(this.state.emergencyContactPhone)
    );
    emergencyContactPhone.enableReadOnly();
    const emergencyContactFax = form.getTextField("emergencyContactFax");
    emergencyContactFax.setText(
      this.ModifyString(this.state.emergencyContactFax)
    );
    emergencyContactFax.enableReadOnly();
    const emergencyContactRelation = form.getTextField(
      "emergencyContactRelation"
    );
    emergencyContactRelation.setText(
      this.ModifyString(this.state.emergencyContactRelation)
    );
    emergencyContactRelation.enableReadOnly();

    const agreementSum = form.getTextField("agreementSum");
    agreementSum.setText(this.ModifyString(this.state.agreementSum));
    agreementSum.enableReadOnly();
    const agreementCity = form.getTextField("agreementCity");
    agreementCity.setText(this.ModifyString(this.state.agreementCity));
    agreementCity.enableReadOnly();
    const agreementDay = form.getTextField("agreementDay");
    agreementDay.setText(this.ModifyString(this.state.agreementDay));
    agreementDay.enableReadOnly();
    const agreementMonth = form.getTextField("agreementMonth");
    agreementMonth.setText(this.ModifyString(this.state.agreementMonth));
    agreementMonth.enableReadOnly();
    const agreementYear = form.getTextField("agreementYear");
    agreementYear.setText(this.ModifyString(this.state.agreementYear));
    agreementYear.enableReadOnly();

    const witness = form.getTextField("witness");
    witness.setText(this.ModifyString(this.state.witness));
    witness.enableReadOnly();
    const applicantPrintName = form.getTextField("applicantPrintName");
    applicantPrintName.setText(
      this.ModifyString(this.state.applicantPrintName)
    );
    applicantPrintName.enableReadOnly();
    const applicantSignName = form.getTextField("applicantSignName");
    applicantSignName.setText(this.ModifyString(this.state.applicantSignName));
    applicantSignName.enableReadOnly();

    const witness2 = form.getTextField("witness2");
    witness2.setText(this.ModifyString(this.state.witness2));
    witness2.enableReadOnly();
    const applicant2PrintName = form.getTextField("applicant2PrintName");
    applicant2PrintName.setText(
      this.ModifyString(this.state.applicant2PrintName)
    );
    applicant2PrintName.enableReadOnly();
    const applicant2SignName = form.getTextField("applicant2SignName");
    applicant2SignName.setText(
      this.ModifyString(this.state.applicant2SignName)
    );
    applicant2SignName.enableReadOnly();

    const witness3 = form.getTextField("witness3");
    witness3.setText(this.ModifyString(this.state.witness3));
    witness3.enableReadOnly();
    const applicant3PrintName = form.getTextField("applicant3PrintName");
    applicant3PrintName.setText(
      this.ModifyString(this.state.applicant3PrintName)
    );
    applicant3PrintName.enableReadOnly();
    const applicant3SignName = form.getTextField("applicant3SignName");
    applicant3SignName.setText(
      this.ModifyString(this.state.applicant3SignName)
    );
    applicant3SignName.enableReadOnly();

    const date2 = form.getTextField("date2");
    date2.setText(this.ModifyString(this.state.date2));
    date2.enableReadOnly();


    const pdfBytes = await pdfDoc.save();
    let blob = new Blob([pdfBytes], { type: "application/pdf" });

    this.setState({ pdfDocObject: blob, changeBlob: true });


   
  }
  async downloadPDF() {
    if (this.state.pdfDocObject === "") {
      this.props.pushAlert("Downloading Error Found!!!", "danger");
    } else {
      const urlView = window.URL.createObjectURL(this.state.pdfDocObject);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = urlView;
      a.download = "file.pdf";
      a.click();
      window.URL.revokeObjectURL(urlView);
    }
  }
  emailReject() {
    window.$("#mailCheck").modal("hide");
    this.setState({ email: "" });
  }
  async emailAccept() {
    await this.setState({ mailCheck: true });
    this.next();
  }
  checkTab() {
    if (this.state.tabCheck[this.state.nowTab - 1] || this.state.nowTab === 1) {
      return true;
    } else {
      for (let i = this.state.nowTab - 1; i > 0; i--) {
        if (this.state.tabCheck[i]) {
          this.props.pushAlert("Please fill correct order", "success");
          this.setState({ nowTab: i + 1 });
          break;
        }
        if (i === 1) {
          this.props.pushAlert("Please fill correct order", "success");
          this.setState({ nowTab: 1 });
          this.setState({ next: false });
          break;
        }
      }
    }
  }
  today() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }
  todayDate() {
    let today = new Date();
    return String(today.getDate()).padStart(2, "0");
  }
  todayMonth() {
    let today = new Date();
    //January is 0!
    return String(today.getMonth() + 1).padStart(2, "0");
  }
  todayYear() {
    let today = new Date();
    return today.getFullYear();
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = this.beforeUnloadListener;
    let x = [];
    for (let i = parseInt(new Date().getFullYear()); i > 1900; i--) {
      x.push(i);
    }

    try {
      await getApplicationsCount(this.props.match.params.id).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.status) {
              response.data.data !== "0" &&
                this.props.pushAlert(response.data.data, "success");

              if (this.props.role === "Landlord") {
                this.props.history.push({ pathname: "/rental-units" });
                this.props.pushAlert(
                  "Sorry!!! Landlord can't Apply Units",
                  "warning"
                );
              }
            } else {
              this.props.history.push({ pathname: "/rental-units" });
              this.props.pushAlert(response.data.data, "danger");
            }
          } else {
            this.props.pushAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        }
      );
    } catch (error) {
     
    }
    const user = CookieService.getArrayCookie("axUserDetails");
    this.setState({ user: this.props.UserData });

    if (user) {
      this.setState({ applicantFirstName: user.firstName });
      this.setState({ applicantLastName: user.lastName });

      this.setState({ applicantEmail: user.username });
      this.setState({ presentAddress: user.address.address });
      this.setState({ presentState: user.address.state });
      this.setState({ presentCity: user.address.city });
      this.setState({ presentZip: user.address.zip });
      this.setState({ applicantMobile: user.mobileNumber });
      try {
        await checkPaymentStatus().then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.props.setCustomerPaymentStatus(response.data.data);
            }
          }
        });
      } catch (err) {}
      try {
        await getPropertyLists(this.props.match.params.id).then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.setState({
                listingData: response.data,
              });
            }
          } else {
            this.props.pushAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
            this.props.history.push({ pathname: "/rental-units" });
          }
        });
      } catch (err) {}
    }
    this.setState({ dropYears: x });
  }
  setPaymentToken(token) {
    this.setState({ paymentToken: token });
  }

  changeTokenNull() {
    this.setState({ paymentToken: "" });
  }
  onChangeParent(name, value) {
    this.setState({ [name]: value });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    if (
      name === "applicantVehicle" ||
      name === "applicant2Vehicle" ||
      name === "applicant3Vehicle"
    ) {
      this.setState({ next: false });
      this.setState({ [name]: checked });
    } else if (name === "applicantPreviousInfo") {
      let x1 = betweenYears(this.state.presentFrom, this.state.presentTo);
      if (isNaN(x1)) {
        this.props.pushAlert("Please fill the present occupancy details first");
      } else if (x1 < 3) {
        this.setState({ [name]: checked });
      } else {
        this.props.pushAlert(
          "Present Occupancy is grater than 3 year, No need to previous rental information",
          "warning"
        );
      }
    } else if (name === "applicant2PreviousInfo") {
      let x2 = betweenYears(
        this.state.applicant2PresentFrom,
        this.state.applicant2PresentTo
      );
      if (isNaN(x2)) {
        this.props.pushAlert(
          "Please fill the present occupancy details first",
          "warning"
        );
      } else if (x2 < 3) {
        this.setState({ [name]: checked });
      } else {
        this.props.pushAlert(
          "Present Occupancy is grater than 3 year, No need to previous rental information",
          "warning"
        );
      }
    } else if (name === "applicant3PreviousInfo") {
      let x3 = betweenYears(
        this.state.applicant3PresentFrom,
        this.state.applicant3PresentTo
      );
      if (isNaN(x3)) {
        this.props.pushAlert(
          "Please fill the present occupancy details first",
          "warning"
        );
      } else if (x3 < 3) {
        this.setState({ [name]: checked });
      } else {
        this.props.pushAlert(
          "Present Occupancy is grater than 3 year, No need to previous rental information",
          "warning"
        );
      }
    } else {
      await this.setState({ [name]: checked });
    }
  }
  async onchangeCheckOpp(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: !checked });
  }

  async onchangeCheckOption(name, value) {
    await this.setState({ [name]: value });
  }
  changeLicenseData(data) {
    this.setState({
      applicantDob: this.setDOB(data.dob),
      applicantDriversLicense: data.licenseNo,
      presentCity: data.city,
      presentState: data.state,
      presentZip: data.zip,
      presentAddress: data.addressLine1,
    });
    if (
      this.state.applicantFirstName === "" ||
      this.state.applicantFirstName === "please wait..."
    ) {
      this.setState({ applicantFirstName: data.firstName });
    }
    if (
      this.state.applicantLastName === "" ||
      this.state.applicantLastName === "please wait..."
    ) {
      this.setState({ applicantLastName: data.lastName });
    }
  }
  keepWait() {
    this.setState({
      applicantDob: "please wait...",
      applicantDriversLicense: "please wait...",
      presentCity: "please wait...",
      presentState: "please wait...",
      presentZip: "please wait...",
      presentAddress: "please wait...",
    });
    if (this.state.applicantFirstName === "") {
      this.setState({ applicantFirstName: "please wait..." });
    }
    if (this.state.applicantLastName === "") {
      this.setState({ applicantLastName: "please wait..." });
    }
  }
  removeKeepWait() {
    if (this.state.applicantFirstName === "please wait...") {
      this.setState({ applicantFirstName: "" });
    }
    if (this.state.applicantLastName === "please wait...") {
      this.setState({ applicantLastName: "" });
    }
    if (this.state.applicantDob === "please wait...") {
      this.setState({ applicantDob: "" });
    }
    if (this.state.applicantDriversLicense === "please wait...") {
      this.setState({ applicantDriversLicense: "" });
    }
    if (this.state.presentCity === "please wait...") {
      this.setState({ city: "" });
    }
    if (this.state.presentState === "please wait...") {
      this.setState({ presentState: "" });
    }
    if (this.state.presentZip === "please wait...") {
      this.setState({ zip: "" });
    }
    if (this.state.presentAddress === "please wait...") {
      this.setState({ presentAddress: "" });
    }
  }
  setDOB(_date) {
    if (_date) {
      let formatLowerCase = "mm/dd/yyyy";
      let formatItems = formatLowerCase.split("/");
      let dateItems = _date.split("/");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      return dateItems[yearIndex] +
        "-" +
        dateItems[monthIndex] +
        "-" +
        dateItems[dayIndex];
    } else {
      return "";
    }
  }
  changeLicense() {
    this.setState({ licenseModal: !this.state.licenseModal });
  }
  showButton() {
    this.setState({ footer: true });
  }
  beforeUnloadListener = (event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?");
  };
  next() {
    this.setState({ next: true });
    if (this.state.nowTab < 6) {
      if (this.state.nowTab === 1) {
        window.scrollTo(0, 0);
        if (canadaStep1Verification(this.state)) {
          if (this.checkTab()) {
            if (!this.state.mailCheck) {
              isAvailableUser(this.state.applicantEmail).then((response) => {
                if (response.data) {
                  window.$("#mailCheck").modal("show");
                  return true;
                } else {
                  let newArray = this.state.tabCheck;
                  newArray[this.state.nowTab] = true;
                  this.setState({ tabCheck: newArray });
                  this.setState({ next: false });
                  this.setState({ nowTab: this.state.nowTab + 1 });

                  if (!this.state.additionalApplicantInfo) {
                    this.setState({
                      additionalApplicantFirstName: "",
                      additionalApplicantMiddleName: "",
                      additionalApplicantLastName: "",
                      additionalApplicantDob: "",
                      additionalApplicantGender: "",
                      additionalApplicantSIN: "",
                    });
                    if (!this.state.additionalApplicant2) {
                      this.setState({
                        additionalApplicant2FirstName: "",
                        additionalApplicant2MiddleName: "",
                        additionalApplicant2LastName: "",
                        additionalApplicant2Dob: "",
                        additionalApplicant2Gender: "",
                        additionalApplicant2SIN: "",
                      });
                    }
                    if (!this.state.additionalApplicant3) {
                      this.setState({
                        additionalApplicant3FirstName: "",
                        additionalApplicant3MiddleName: "",
                        additionalApplicant3LastName: "",
                        additionalApplicant3Dob: "",
                        additionalApplicant3Gender: "",
                        additionalApplicant3SIN: "",
                      });
                    }
                  }
                  if (!this.state.applicantVehicle) {
                    this.setState({
                      applicantDriversLicense: "",
                      applicantVehicleMadeBy: "",
                      applicantVehicleModel: "",
                      applicantVehicleYear: "",
                      applicantVehicleLicenseNumber: "",
                    });
                  }
                  if (!this.state.applicant2) {
                    this.setState({
                      applicant2FirstName: "",
                      applicant2MiddleName: "",
                      applicant2LastName: "",
                      applicant2Email: "",
                      applicant2Dob: "",
                      applicant2Gender: "",
                      applicant2SIN: "",
                      applicant2Mobile: "",
                      applicant2FirstReferencesName: "",
                      applicant2FirstReferencesAddress: "",
                      applicant2FirstReferencesTelephone: "",
                      applicant2SecondReferencesName: "",
                      applicant2SecondReferencesAddress: "",
                      applicant2SecondReferencesTelephone: "",
                      applicant2DriversLicense: "",
                      applicant2VehicleMadeBy: "",
                      applicant2VehicleModel: "",
                      applicant2VehicleYear: "",
                      applicant2VehicleLicenseNumber: "",
                      applicant2PresentAddress: "",
                      applicant2PresentState: "",
                      applicant2PresentCity: "",
                      applicant2PresentZip: "",
                      applicant2PresentFrom: "",
                      applicant2PresentTo: "",
                      applicant2PresentHomePhone: "",
                      applicant2PresentHomeFax: "",
                      applicant2PresentReasonLeaving: "",
                      applicant2PresentPropertyType: "",
                      applicant2PresentLandlord: "",
                      applicant2PresentLandlordPhone: "",
                      applicant2PresentLandlordIDProof: "",
                      applicant2PresentMonthlyRent: "",
                      applicant2PreviousAddress: "",
                      applicant2PreviousState: "",
                      applicant2PreviousCity: "",
                      applicant2PreviousZip: "",
                      applicant2PreviousFrom: "",
                      applicant2PreviousTo: "",
                      applicant2PreviousReasonLeaving: "",
                      applicant2PreviousPropertyType: "",
                      applicant2PreviousLandlord: "",
                      applicant2PreviousLandlordPhone: "",
                      applicant2PreviousMonthlyRent: "",
                      applicant2EmploymentPosition: "",
                      applicant2EmploymentFrom: "",
                      applicant2EmploymentUntil: "",
                      applicant2EmploymentAnnualIncome: "",
                      applicant2EmploymentZip: "",
                      applicant2EmploymentAddress: "",
                      applicant2EmploymentCity: "",
                      applicant2EmploymentState: "",
                      applicant2EmploymentPhone: "",
                      applicant2EmploymentCompany: "",
                      applicant2PreEmploymentCompany: "",
                      applicant2PreEmployment2Company: "",
                      applicant2PreEmploymentZip: "",
                      applicant2PreEmploymentAddress: "",
                      applicant2PreEmploymentCity: "",
                      applicant2PreEmploymentState: "",
                      applicant2PreEmploymentPosition: "",
                      applicant2PreEmploymentFrom: "",
                      applicant2PreEmploymentUntil: "",
                      applicant2PreEmployment2Zip: "",
                      applicant2PreEmployment2Address: "",
                      applicant2PreEmployment2City: "",
                      applicant2PreEmployment2State: "",
                      applicant2PreEmployment2Position: "",
                      applicant2PreEmployment2From: "",
                      applicant2PreEmployment2Until: "",
                      applicant2SpousesEmployer: "",
                      applicant2SpousesEmployerAddress: "",
                      applicant2SpousesIncome: "",
                      applicant2PrimaryBankName: "",
                      applicant2PrimaryBankBranch: "",
                      applicant2PrimaryBankAccType: "",
                      applicant2PrimaryBankAccNo: "",
                      applicant2SecondaryBankName: "",
                      applicant2SecondaryBankBranch: "",
                      applicant2SecondaryBankAccType: "",
                      applicant2SecondaryBankAccNo: "",
                    });
                  } else {
                    if (!this.state.applicant2Vehicle) {
                      this.setState({
                        applicant2DriversLicense: "",
                        applicant2VehicleMadeBy: "",
                        applicant2VehicleModel: "",
                        applicant2VehicleYear: "",
                        applicant2VehicleLicenseNumber: "",
                      });
                    }
                  }
                  if (!this.state.applicant3) {
                    this.setState({
                      applicant3FirstName: "",
                      applicant3MiddleName: "",
                      applicant3LastName: "",
                      applicant3Email: "",
                      applicant3Dob: "",
                      applicant3Gender: "",
                      applicant3SIN: "",
                      applicant3Mobile: "",
                      applicant3FirstReferencesName: "",
                      applicant3FirstReferencesAddress: "",
                      applicant3FirstReferencesTelephone: "",
                      applicant3SecondReferencesName: "",
                      applicant3SecondReferencesAddress: "",
                      applicant3SecondReferencesTelephone: "",
                      applicant3DriversLicense: "",
                      applicant3VehicleMadeBy: "",
                      applicant3VehicleModel: "",
                      applicant3VehicleYear: "",
                      applicant3VehicleLicenseNumber: "",
                      applicant3PresentAddress: "",
                      applicant3PresentState: "",
                      applicant3PresentCity: "",
                      applicant3PresentZip: "",
                      applicant3PresentFrom: "",
                      applicant3PresentTo: "",
                      applicant3PresentHomePhone: "",
                      applicant3PresentHomeFax: "",
                      applicant3PresentReasonLeaving: "",
                      applicant3PresentPropertyType: "",
                      applicant3PresentLandlord: "",
                      applicant3PresentLandlordPhone: "",
                      applicant3PresentLandlordIDProof: "",
                      applicant3PresentMonthlyRent: "",
                      applicant3PreviousAddress: "",
                      applicant3PreviousState: "",
                      applicant3PreviousCity: "",
                      applicant3PreviousZip: "",
                      applicant3PreviousFrom: "",
                      applicant3PreviousTo: "",
                      applicant3PreviousReasonLeaving: "",
                      applicant3PreviousPropertyType: "",
                      applicant3PreviousLandlord: "",
                      applicant3PreviousLandlordPhone: "",
                      applicant3PreviousMonthlyRent: "",
                      applicant3EmploymentPosition: "",
                      applicant3EmploymentFrom: "",
                      applicant3EmploymentUntil: "",
                      applicant3EmploymentAnnualIncome: "",
                      applicant3EmploymentZip: "",
                      applicant3EmploymentAddress: "",
                      applicant3EmploymentCity: "",
                      applicant3EmploymentState: "",
                      applicant3EmploymentPhone: "",
                      applicant3EmploymentCompany: "",
                      applicant3PreEmploymentCompany: "",
                      applicant3PreEmployment2Company: "",
                      applicant3PreEmploymentZip: "",
                      applicant3PreEmploymentAddress: "",
                      applicant3PreEmploymentCity: "",
                      applicant3PreEmploymentState: "",
                      applicant3PreEmploymentPosition: "",
                      applicant3PreEmploymentFrom: "",
                      applicant3PreEmploymentUntil: "",
                      applicant3PreEmployment2Zip: "",
                      applicant3PreEmployment2Address: "",
                      applicant3PreEmployment2City: "",
                      applicant3PreEmployment2State: "",
                      applicant3PreEmployment2Position: "",
                      applicant3PreEmployment2From: "",
                      applicant3PreEmployment2Until: "",
                      applicant3SpousesEmployer: "",
                      applicant3SpousesEmployerAddress: "",
                      applicant3SpousesIncome: "",
                      applicant3PrimaryBankName: "",
                      applicant3PrimaryBankBranch: "",
                      applicant3PrimaryBankAccType: "",
                      applicant3PrimaryBankAccNo: "",
                      applicant3SecondaryBankName: "",
                      applicant3SecondaryBankBranch: "",
                      applicant3SecondaryBankAccType: "",
                      applicant3SecondaryBankAccNo: "",
                    });
                  } else {
                    if (!this.state.applicant3Vehicle) {
                      this.setState({
                        applicant3DriversLicense: "",
                        applicant3VehicleMadeBy: "",
                        applicant3VehicleModel: "",
                        applicant3VehicleYear: "",
                        applicant3VehicleLicenseNumber: "",
                      });
                    }
                  }
                  return true;
                }
              });
            } else {
              this.setState({ next: false });
              if (this.checkTab()) {
                let newArray = this.state.tabCheck;
                newArray[this.state.nowTab] = true;
                this.setState({ tabCheck: newArray });
                this.setState({ nowTab: this.state.nowTab + 1 });
                return true;
              }
            }
          }
        }
      } else if (this.state.nowTab === 2) {
        window.scrollTo(0, 0);

        if (canadaStep2Verification(this.state)) {
          if (this.checkTab()) {
            let newArray = this.state.tabCheck;
            newArray[this.state.nowTab] = true;
            this.setState({ tabCheck: newArray });
            if (this.state.presentPropertyType === "Own") {
              this.setState({
                presentLandlord: "",
                presentLandlordPhone: "",
                presentLandlordIDProof: "",
                presentMonthlyRent: "",
              });
            }
            if (!this.state.applicantPreviousInfo) {
              this.setState({
                previousAddress: "",
                previousState: "",
                previousCity: "",
                previousZip: "",
                previousFrom: "",
                previousTo: "",
                previousHomePhone: "",
                previousReasonLeaving: "",
                previousPropertyType: "",
              });
            }
            if (this.state.applicant2PresentPropertyType === "Own") {
              this.setState({
                applicant2PresentLandlord: "",
                applicant2PresentLandlordPhone: "",
                applicant2PresentLandlordIDProof: "",
                applicant2PresentMonthlyRent: "",
              });
            }
            if (!this.state.applicant2PreviousInfo) {
              this.setState({
                applicant2PreviousAddress: "",
                applicant2PreviousState: "",
                applicant2PreviousCity: "",
                applicant2PreviousZip: "",
                applicant2PreviousFrom: "",
                applicant2PreviousTo: "",
                applicant2PreviousHomePhone: "",
                applicant2PreviousReasonLeaving: "",
                applicant2PreviousPropertyType: "",
              });
            }
            if (this.state.applicant3PresentPropertyType === "Own") {
              this.setState({
                applicant3PresentLandlord: "",
                applicant3PresentLandlordPhone: "",
                applicant3PresentLandlordIDProof: "",
                applicant3PresentMonthlyRent: "",
              });
            }

            if (!this.state.applicant3PreviousInfo) {
              this.setState({
                applicant3PreviousAddress: "",
                applicant3PreviousState: "",
                applicant3PreviousCity: "",
                applicant3PreviousZip: "",
                applicant3PreviousFrom: "",
                applicant3PreviousTo: "",
                applicant3PreviousHomePhone: "",
                applicant3PreviousReasonLeaving: "",
                applicant3PreviousPropertyType: "",
              });
            }

            this.setState({ next: false });
            this.setState({ nowTab: this.state.nowTab + 1 });
            return true;
          }
        }
      } else if (this.state.nowTab === 3) {
        window.scrollTo(0, 0);

        if (canadaStep3Verification(this.state)) {
          if (this.checkTab()) {
            let newArray = this.state.tabCheck;
            newArray[this.state.nowTab] = true;
            this.setState({ tabCheck: newArray });
            if (!this.state.employment1) {
              this.setState({
                preEmploymentZip: "",
                preEmploymentAddress: "",
                preEmploymentCity: "",
                preEmploymentState: "",
                preEmploymentPosition: "",
                preEmploymentFrom: "",
                preEmploymentUntil: "",
              });
            }
            if (!this.state.employment2) {
              this.setState({
                preEmployment2Zip: "",
                preEmployment2Address: "",
                preEmployment2City: "",
                preEmployment2State: "",
                preEmployment2Position: "",
                preEmployment2From: "",
                preEmployment2Until: "",
              });
            }

            if (!this.state.applicant2Employment1) {
              this.setState({
                applicant2PreEmploymentZip: "",
                applicant2PreEmploymentAddress: "",
                applicant2PreEmploymentCity: "",
                applicant2PreEmploymentState: "",
                applicant2PreEmploymentPosition: "",
                applicant2PreEmploymentFrom: "",
                applicant2PreEmploymentUntil: "",
              });
            }
            if (!this.state.applicant2Employment2) {
              this.setState({
                applicant2PreEmployment2Zip: "",
                applicant2PreEmployment2Address: "",
                applicant2PreEmployment2City: "",
                applicant2PreEmployment2State: "",
                applicant2PreEmployment2Position: "",
                applicant2PreEmployment2From: "",
                applicant2PreEmployment2Until: "",
              });
            }

            if (!this.state.applicant3Employment1) {
              this.setState({
                applicant3PreEmploymentZip: "",
                applicant3PreEmploymentAddress: "",
                applicant3PreEmploymentCity: "",
                applicant3PreEmploymentState: "",
                applicant3PreEmploymentPosition: "",
                applicant3PreEmploymentFrom: "",
                applicant3PreEmploymentUntil: "",
              });
            }
            if (!this.state.applicant3Employment2) {
              this.setState({
                applicant3PreEmployment2Zip: "",
                applicant3PreEmployment2Address: "",
                applicant3PreEmployment2City: "",
                applicant3PreEmployment2State: "",
                applicant3PreEmployment2Position: "",
                applicant3PreEmployment2From: "",
                applicant3PreEmployment2Until: "",
              });
            }
            this.setState({ nowTab: this.state.nowTab + 1 });
            this.setState({ next: false });
            return true;
          }
        }
      } else if (this.state.nowTab === 4) {
        if (canadaStep4Verification(this.state)) {
          if (this.checkTab()) {
            let newArray = this.state.tabCheck;
            newArray[this.state.nowTab] = true;
            this.setState({ tabCheck: newArray });
            window.scrollTo(0, 0);

            this.setState({ next: false });
            this.getPdfBlob();

            if (this.props.customerPaymentStatus === "verified") {
              this.setState({ nowTab: this.state.nowTab + 2 });
              return true;
            } else {
              this.setState({ nowTab: this.state.nowTab + 1 });
              return true;
            }
          }
        }
      } else if (this.state.nowTab === 5) {
        if (this.checkTab()) {
          let newArray = this.state.tabCheck;
          newArray[this.state.nowTab] = true;
          this.setState({ tabCheck: newArray });
          window.scrollTo(0, 0);
          this.setState({ next: false });
          this.setState({ nowTab: this.state.nowTab + 1 });
          this.props.setCustomerPaymentStatus("verified");
          return true;
        }
      } else if (this.state.nowTab === 6) {
        if (this.checkTab()) {
          let newArray = this.state.tabCheck;
          newArray[this.state.nowTab] = true;
          this.setState({ tabCheck: newArray });
          window.scrollTo(0, 0);
          this.setState({ next: false });
          this.setState({ nowTab: this.state.nowTab + 1 });
          return true;
        }
      }
    } else {
      window.scrollTo(0, 0);

      this.setState({ next: false });
      this.setState({ nowTab: 1 });
    }
    return false;
  }
  finished() {
    this.setState({ isButtonDisableFinish: false });
    const userSample = [
      {
        user: {
          username: this.state.applicantEmail,
          password: "",
          enabled: false,
          accountNonExpired: true,
          credentialsNonExpired: false,
          accountNonLocked: true,
          firstName: this.state.applicantFirstName,
          lastName: this.state.applicantLastName,
          middleName: this.state.applicantMiddleName,
          address: {
            address: this.state.presentAddress,
            city: this.state.presentCity,
            state: this.state.presentState,
            zip: this.state.presentZip,
            country: "",
            lat: 0,
            lng: 0,
          },
          mobileNumber: this.state.applicantMobile,
          stripe_id: "",
          provider: "local",
          coverImage: "",
        },
        order: 1,
      },
    ];
    const applicantSample = [
      {
        applicant: {
          username: this.state.applicantEmail,
          drivingLicNo: this.state.applicantDriversLicense,
          emergencyNo: null,
          ssn: this.state.applicantSIN,
          dob: this.state.applicantDob,
          otherName: null,
          gender: this.state.applicantGender,
          resPresentAddress: this.state.presentAddress,
          resPresentCity: this.state.presentCity,
          resPresentState: this.state.presentState,
          resPresentZip: this.state.presentZip,
          resPresentRent: this.state.presentMonthlyRent
            ? parseInt(this.state.presentMonthlyRent).toFixed(2)
            : 0.0,
          resPresentFrom: this.state.presentFrom,
          resPresentTo: this.state.presentTo,
          resPresentPhone: this.state.presentHomePhone,
          resPresentFax: this.state.presentHomeFax,
          resPresentOwnRent: this.state.presentPropertyType,
          resPresentLandlord:
            this.state.presentPropertyType === "Own"
              ? ""
              : this.state.presentLandlord,
          resPresentLandlordEmail: null,
          resPresentLandlordTp:
            this.state.presentPropertyType === "Own"
              ? ""
              : this.state.presentLandlordPhone,
          resPresentLandlordFax: null,
          resPresentApartment: null,
          resPresentUnitType: "Apartment",
          resPresentMortgage: false,
          resPresentReasonLeaving: this.state.presentReasonLeaving,
          resPresentLandlordIDProof: this.state.presentLandlordIDProof,

          resPreviousAddress: this.state.previousAddress,
          resPreviousCity: this.state.previousCity,
          resPreviousState: this.state.previousState,
          resPreviousZip: this.state.previousZip,
          resPreviousRent: this.state.previousMonthlyRent
            ? parseInt(this.state.previousMonthlyRent).toFixed(2)
            : 0.0,
          resPreviousFrom: this.state.previousFrom,
          resPreviousTo: this.state.previousTo,
          resPreviousOwnRent: this.state.previousPropertyType,
          resPreviousLandlord:
            this.state.previousPropertyType === "Own"
              ? ""
              : this.state.previousLandlord,
          resPreviousLandlordEmail: null,
          resPreviousLandlordTp:
            this.state.previousPropertyType === "Own"
              ? ""
              : this.state.previousLandlordPhone,
          resPreviousLandlordFax: null,
          resPreviousApartment: null,
          resPreviousUnitType: "Apartment",
          resPreviousMortgage: false,
          resPreviousReasonLeaving: this.state.previousReasonLeaving,

          empPresentEmployed: null,
          empPresentEmail: null,
          empPresentAddress: this.state.employmentAddress,
          empPresentCity: this.state.employmentCity,
          empPresentState: this.state.employmentState,
          empPresentZip: this.state.employmentZip,
          empPresentPhone: this.state.employmentPhone,
          empPresentSalary: this.state.employmentAnnualIncome
            ? (parseInt(this.state.employmentAnnualIncome) / 12).toFixed(2)
            : 0.0,
          empPresentFrom: this.state.employmentFrom,
          empPresentTo: this.state.employmentUntil,
          empPresentPosition: this.state.employmentPosition,
          empPresentAnnualIncome: this.state.employmentAnnualIncome
            ? parseInt(this.state.employmentAnnualIncome).toFixed(2)
            : 0.0,

          empPreviousEmployed: null,
          empPreviousEmail: null,
          empPreviousAddress: this.state.preEmploymentAddress,
          empPreviousCity: this.state.preEmploymentCity,
          empPreviousState: this.state.preEmploymentState,
          empPreviousZip: this.state.preEmploymentZip,
          empPreviousPhone: null,
          empPreviousSalary: 0.0,
          empPreviousFrom: this.state.preEmploymentFrom,
          empPreviousTo: this.state.preEmploymentUntil,
          empPreviousPosition: this.state.preEmploymentPosition,
          empPreviousAnnualIncome: 0.0,
          emp2PreviousEmployed: null,
          emp2PreviousEmail: null,
          emp2PreviousAddress: this.state.preEmployment2Address,
          emp2PreviousCity: this.state.preEmployment2City,
          emp2PreviousState: this.state.preEmployment2State,
          emp2PreviousZip: this.state.preEmployment2Zip,
          emp2PreviousPhone: null,
          emp2PreviousSalary: 0.0,
          emp2PreviousFrom: this.state.preEmployment2From,
          emp2PreviousTo: this.state.preEmployment2Until,
          emp2PreviousPosition: this.state.preEmployment2Position,
          emp2PreviousAnnualIncome: 0.0,
          spousesEmployer: this.state.spousesEmployer,
          spousesEmployerAddress: this.state.spousesEmployerAddress,
          spousesIncome: this.state.spousesIncome
            ? parseInt(this.state.spousesIncome).toFixed(2)
            : 0.0,
          referenceName: this.state.applicantFirstReferencesName,
          referenceAddress: this.state.applicantFirstReferencesAddress,
          referenceTelephone: this.state.applicantFirstReferencesTelephone,
          referenceName2: this.state.applicantSecondReferencesName,
          referenceAddress2: this.state.applicantSecondReferencesAddress,
          referenceTelephone2: this.state.applicantSecondReferencesTelephone,
          emergencyContactName: this.state.emergencyContactName,
          emergencyContactPhone: this.state.emergencyContactPhone,
          emergencyContactFax: this.state.emergencyContactFax,
          emergencyContactRelation: this.state.emergencyContactRelation,
          primaryBankName: this.state.applicantPrimaryBankName,
          primaryBankBranch: this.state.applicantPrimaryBankBranch,
          primaryBankAccType: this.state.applicantPrimaryBankAccType,
          primaryBankAccNo: this.state.applicantPrimaryBankAccNo,
          secondaryBankName: this.state.applicantSecondaryBankName,
          secondaryBankBranch: this.state.applicantSecondaryBankBranch,
          secondaryBankAccType: this.state.applicantSecondaryBankAccType,
          secondaryBankAccNo: this.state.applicantSecondaryBankAccNo,
          relativeNum: null,
          relativeName: null,
          additionalIncome: null,
          bankruptcy: false,
          crimeCheck: false,
          evicted: false,
          smoke: false,
          homePhone: null,
          resPresentYears: betweenYears(
            this.state.presentFrom,
            this.state.presentTo
          ),
          resPresentMonths: betweenMonths(
            this.state.presentFrom,
            this.state.presentTo
          ),
          resPreviousYear: betweenYears(
            this.state.previousFrom,
            this.state.previousTo
          ),
          resPreviousMonth: betweenMonths(
            this.state.previousFrom,
            this.state.previousTo
          ),
          empPresentYears: betweenYears(
            this.state.employmentFrom,
            this.state.employmentUntil
          ),
          empPresentMonths: betweenMonths(
            this.state.employmentFrom,
            this.state.employmentUntil
          ),
          empPreviousYear: betweenYears(
            this.state.preEmploymentFrom,
            this.state.preEmploymentUntil
          ),
          empPreviousMonth: betweenMonths(
            this.state.preEmploymentFrom,
            this.state.preEmploymentUntil
          ),
          vehicle: this.state.applicantVehicle,
          vehicleType: null,
          vehicleMadeBy: this.state.applicantVehicleMadeBy,
          vehicleModel: this.state.applicantVehicleModel,
          vehicleYear: this.state.applicantVehicleYear,
          vehicleLicenseNumber: this.state.applicantVehicleLicenseNumber,
          // createdAt: "2021-09-17T17:45:05.546+0000",
          // updatedAt: "2021-09-17T17:45:05.546+0000",
        },
        applicantOrder: 1,
      },
    ];
    if (this.state.applicant2) {
      userSample.push({
        user: {
          username: this.state.applicant2Email,
          password: "",
          enabled: false,
          accountNonExpired: true,
          credentialsNonExpired: false,
          accountNonLocked: true,
          firstName: this.state.applicant2FirstName,
          lastName: this.state.applicant2LastName,
          middleName: this.state.applicant2MiddleName,
          address: {
            address: this.state.applicant2PresentAddress,
            city: this.state.applicant2PresentCity,
            state: this.state.applicant2PresentState,
            zip: this.state.applicant2PresentZip,
            country: "",
            lat: 0,
            lng: 0,
          },
          mobileNumber: this.state.applicant2Mobile,
          stripe_id: "",
          provider: "local",
          coverImage: "",
        },
        order: 2,
      });
      applicantSample.push({
        applicant: {
          username: this.state.applicant2Email,
          apply: null,
          drivingLicNo: this.state.applicant2DriversLicense,
          emergencyNo: null,
          ssn: this.state.applicant2SIN,
          dob: this.state.applicant2Dob,
          otherName: null,
          gender: this.state.applicant2Gender,
          resPresentAddress: this.state.applicant2PresentAddress,
          resPresentCity: this.state.applicant2PresentCity,
          resPresentState: this.state.applicant2PresentState,
          resPresentZip: this.state.applicant2PresentZip,
          resPresentRent: this.state.applicant2PresentMonthlyRent
            ? parseInt(this.state.applicant2PresentMonthlyRent).toFixed(2)
            : 0.0,
          resPresentFrom: this.state.applicant2PresentFrom,
          resPresentTo: this.state.applicant2PresentTo,
          resPresentPhone: this.state.applicant2PresentHomePhone,
          resPresentFax: this.state.applicant2PresentHomeFax,
          resPresentOwnRent: this.state.applicant2PresentPropertyType,
          resPresentLandlord:
            this.state.applicant2PresentPropertyType === "Own"
              ? ""
              : this.state.applicant2PresentLandlord,
          resPresentLandlordEmail: null,
          resPresentLandlordTp:
            this.state.applicant2PresentPropertyType === "Own"
              ? ""
              : this.state.applicant2PresentLandlordPhone,
          resPresentLandlordFax: null,
          resPresentApartment: null,
          resPresentUnitType: "Apartment",
          resPresentMortgage: false,
          resPresentReasonLeaving: this.state.applicant2PresentReasonLeaving,
          resPresentLandlordIDProof:
            this.state.applicant2PresentLandlordIDProof,

          resPreviousAddress: this.state.applicant2PreviousAddress,
          resPreviousCity: this.state.applicant2PreviousCity,
          resPreviousState: this.state.applicant2PreviousState,
          resPreviousZip: this.state.applicant2PreviousZip,
          resPreviousRent: this.state.applicant2PreviousMonthlyRent
            ? parseInt(this.state.applicant2PreviousMonthlyRent).toFixed(2)
            : 0.0,
          resPreviousFrom: this.state.applicant2PreviousFrom,
          resPreviousTo: this.state.applicant2PreviousTo,
          resPreviousOwnRent: this.state.applicant2PreviousPropertyType,
          resPreviousLandlord:
            this.state.applicant2PreviousPropertyType === "Own"
              ? ""
              : this.state.applicant2PreviousLandlord,
          resPreviousLandlordEmail: null,
          resPreviousLandlordTp:
            this.state.applicant2PreviousPropertyType === "Own"
              ? ""
              : this.state.applicant2PreviousLandlordPhone,
          resPreviousLandlordFax: null,
          resPreviousApartment: null,
          resPreviousUnitType: "Apartment",
          resPreviousMortgage: false,
          resPreviousReasonLeaving: this.state.applicant2PreviousReasonLeaving,

          empPresentEmployed: null,
          empPresentEmail: null,
          empPresentAddress: this.state.applicant2EmploymentAddress,
          empPresentCity: this.state.applicant2EmploymentCity,
          empPresentState: this.state.applicant2EmploymentState,
          empPresentZip: this.state.applicant2EmploymentZip,
          empPresentPhone: this.state.applicant2EmploymentPhone,
          empPresentSalary: this.state.applicant2EmploymentAnnualIncome
            ? (
                parseInt(this.state.applicant2EmploymentAnnualIncome) / 12
              ).toFixed(2)
            : 0.0,
          empPresentFrom: this.state.applicant2EmploymentFrom,
          empPresentTo: this.state.applicant2EmploymentUntil,
          empPresentPosition: this.state.applicant2EmploymentPosition,
          empPresentAnnualIncome: this.state.applicant2EmploymentAnnualIncome
            ? parseInt(this.state.applicant2EmploymentAnnualIncome).toFixed(2)
            : 0.0,

          empPreviousEmployed: null,
          empPreviousEmail: null,
          empPreviousAddress: this.state.applicant2PreEmploymentAddress,
          empPreviousCity: this.state.applicant2PreEmploymentCity,
          empPreviousState: this.state.applicant2PreEmploymentState,
          empPreviousZip: this.state.applicant2PreEmploymentZip,
          empPreviousPhone: null,
          empPreviousSalary: 0.0,
          empPreviousFrom: this.state.applicant2PreEmploymentFrom,
          empPreviousTo: this.state.applicant2PreEmploymentUntil,
          empPreviousPosition: this.state.applicant2PreEmploymentPosition,
          empPreviousAnnualIncome: 0.0,
          emp2PreviousEmployed: null,
          emp2PreviousEmail: null,
          emp2PreviousAddress: this.state.applicant2PreEmployment2Address,
          emp2PreviousCity: this.state.applicant2PreEmployment2City,
          emp2PreviousState: this.state.applicant2PreEmployment2State,
          emp2PreviousZip: this.state.applicant2PreEmployment2Zip,
          emp2PreviousPhone: null,
          emp2PreviousSalary: 0.0,
          emp2PreviousFrom: this.state.applicant2PreEmployment2From,
          emp2PreviousTo: this.state.applicant2PreEmployment2Until,
          emp2PreviousPosition: this.state.applicant2PreEmployment2Position,
          emp2PreviousAnnualIncome: 0.0,
          spousesEmployer: this.state.applicant2SpousesEmployer,
          spousesEmployerAddress: this.state.applicant2SpousesEmployerAddress,
          spousesIncome: this.state.applicant2SpousesIncome
            ? parseInt(this.state.applicant2SpousesIncome).toFixed(2)
            : 0.0,
          referenceName: this.state.applicant2FirstReferencesName,
          referenceAddress: this.state.applicant2FirstReferencesAddress,
          referenceTelephone: this.state.applicant2FirstReferencesTelephone,
          referenceName2: this.state.applicant2SecondReferencesName,
          referenceAddress2: this.state.applicant2SecondReferencesAddress,
          referenceTelephone2: this.state.applicant2SecondReferencesTelephone,
          emergencyContactName: this.state.emergencyContactName,
          emergencyContactPhone: this.state.emergencyContactPhone,
          emergencyContactFax: this.state.emergencyContactFax,
          emergencyContactRelation: this.state.emergencyContactRelation,
          primaryBankName: this.state.applicant2PrimaryBankName,
          primaryBankBranch: this.state.applicant2PrimaryBankBranch,
          primaryBankAccType: this.state.applicant2PrimaryBankAccType,
          primaryBankAccNo: this.state.applicant2PrimaryBankAccNo,
          secondaryBankName: this.state.applicant2SecondaryBankName,
          secondaryBankBranch: this.state.applicant2SecondaryBankBranch,
          secondaryBankAccType: this.state.applicant2SecondaryBankAccType,
          secondaryBankAccNo: this.state.applicant2SecondaryBankAccNo,
          relativeNum: null,
          relativeName: null,
          additionalIncome: null,
          bankruptcy: false,
          crimeCheck: false,
          evicted: false,
          smoke: false,
          homePhone: null,
          resPresentYears: betweenYears(
            this.state.applicant2PresentFrom,
            this.state.applicant2PresentTo
          ),
          resPresentMonths: betweenMonths(
            this.state.applicant2PresentFrom,
            this.state.applicant2PresentTo
          ),
          resPreviousYear: betweenYears(
            this.state.applicant2PreviousFrom,
            this.state.applicant2PreviousTo
          ),
          resPreviousMonth: betweenMonths(
            this.state.applicant2PreviousFrom,
            this.state.applicant2PreviousTo
          ),
          empPresentYears: betweenYears(
            this.state.applicant2EmploymentFrom,
            this.state.applicant2EmploymentUntil
          ),
          empPresentMonths: betweenMonths(
            this.state.applicant2EmploymentFrom,
            this.state.applicant2EmploymentUntil
          ),
          empPreviousYear: betweenYears(
            this.state.applicant2PreEmploymentFrom,
            this.state.applicant2PreEmploymentUntil
          ),
          empPreviousMonth: betweenMonths(
            this.state.applicant2PreEmploymentFrom,
            this.state.applicant2PreEmploymentUntil
          ),
          vehicle: this.state.applicant2Vehicle,
          vehicleType: null,
          vehicleMadeBy: this.state.applicant2VehicleMadeBy,
          vehicleModel: this.state.applicant2VehicleModel,
          vehicleYear: this.state.applicant2VehicleYear,
          vehicleLicenseNumber: this.state.applicant2VehicleLicenseNumber,
        },
        applicantOrder: 2,
      });
    }
    if (this.state.applicant3) {
      userSample.push({
        user: {
          username: this.state.applicant3Email,
          password: "",
          enabled: false,
          accountNonExpired: true,
          credentialsNonExpired: false,
          accountNonLocked: true,
          firstName: this.state.applicant3FirstName,
          lastName: this.state.applicant3LastName,
          middleName: this.state.applicant3MiddleName,
          address: {
            address: this.state.applicant3PresentAddress,
            city: this.state.applicant3PresentCity,
            state: this.state.applicant3PresentState,
            zip: this.state.applicant3PresentZip,
            country: "",
            lat: 0,
            lng: 0,
          },
          mobileNumber: this.state.applicant3Mobile,
          stripe_id: "",
          provider: "local",
          coverImage: "",
        },
        order: 3,
      });
      applicantSample.push({
        applicant: {
          username: this.state.applicant3Email,
          apply: null,
          drivingLicNo: this.state.applicant3DriversLicense,
          emergencyNo: null,
          ssn: this.state.applicant3SIN,
          dob: this.state.applicant3Dob,
          otherName: null,
          gender: this.state.applicant3Gender,
          resPresentAddress: this.state.applicant3PresentAddress,
          resPresentCity: this.state.applicant3PresentCity,
          resPresentState: this.state.applicant3PresentState,
          resPresentZip: this.state.applicant3PresentZip,
          resPresentRent: this.state.applicant3PresentMonthlyRent
            ? parseInt(this.state.applicant3PresentMonthlyRent).toFixed(2)
            : 0.0,
          resPresentFrom: this.state.applicant3PresentFrom,
          resPresentTo: this.state.applicant3PresentTo,
          resPresentPhone: this.state.applicant3PresentHomePhone,
          resPresentFax: this.state.applicant3PresentHomeFax,
          resPresentOwnRent: this.state.applicant3PresentPropertyType,
          resPresentLandlord:
            this.state.applicant3PresentPropertyType === "Own"
              ? ""
              : this.state.applicant3PresentLandlord,
          resPresentLandlordEmail: null,
          resPresentLandlordTp:
            this.state.applicant3PresentPropertyType === "Own"
              ? ""
              : this.state.applicant3PresentLandlordPhone,
          resPresentLandlordFax: null,
          resPresentApartment: null,
          resPresentUnitType: "Apartment",
          resPresentMortgage: false,
          resPresentReasonLeaving: this.state.applicant3PresentReasonLeaving,
          resPresentLandlordIDProof:
            this.state.applicant3PresentLandlordIDProof,

          resPreviousAddress: this.state.applicant3PreviousAddress,
          resPreviousCity: this.state.applicant3PreviousCity,
          resPreviousState: this.state.applicant3PreviousState,
          resPreviousZip: this.state.applicant3PreviousZip,
          resPreviousRent: this.state.applicant3PreviousMonthlyRent,
          resPreviousFrom: this.state.applicant3PreviousFrom,
          resPreviousTo: this.state.applicant3PreviousTo,
          resPreviousOwnRent: this.state.applicant3PreviousPropertyType,
          resPreviousLandlord:
            this.state.applicant3PreviousPropertyType === "Own"
              ? ""
              : this.state.applicant3PreviousLandlord,
          resPreviousLandlordEmail: null,
          resPreviousLandlordTp:
            this.state.applicant3PreviousPropertyType === "Own"
              ? ""
              : this.state.applicant3PreviousLandlordPhone,
          resPreviousLandlordFax: null,
          resPreviousApartment: null,
          resPreviousUnitType: "Apartment",
          resPreviousMortgage: false,
          resPreviousReasonLeaving: this.state.applicant3PreviousReasonLeaving,

          empPresentEmployed: null,
          empPresentEmail: null,
          empPresentAddress: this.state.applicant3EmploymentAddress,
          empPresentCity: this.state.applicant3EmploymentCity,
          empPresentState: this.state.applicant3EmploymentState,
          empPresentZip: this.state.applicant3EmploymentZip,
          empPresentPhone: this.state.applicant3EmploymentPhone,
          empPresentSalary: this.state.applicant3EmploymentAnnualIncome
            ? (
                parseInt(this.state.applicant3EmploymentAnnualIncome) / 12
              ).toFixed(2)
            : 0.0,
          empPresentFrom: this.state.applicant3EmploymentFrom,
          empPresentTo: this.state.applicant3EmploymentUntil,
          empPresentPosition: this.state.applicant3EmploymentPosition,
          empPresentAnnualIncome: this.state.applicant3EmploymentAnnualIncome
            ? parseInt(this.state.applicant3EmploymentAnnualIncome).toFixed(2)
            : 0.0,

          empPreviousEmployed: null,
          empPreviousEmail: null,
          empPreviousAddress: this.state.applicant3PreEmploymentAddress,
          empPreviousCity: this.state.applicant3PreEmploymentCity,
          empPreviousState: this.state.applicant3PreEmploymentState,
          empPreviousZip: this.state.applicant3PreEmploymentZip,
          empPreviousPhone: null,
          empPreviousSalary: 0.0,
          empPreviousFrom: this.state.applicant3PreEmploymentFrom,
          empPreviousTo: this.state.applicant3PreEmploymentUntil,
          empPreviousPosition: this.state.applicant3PreEmploymentPosition,
          empPreviousAnnualIncome: 0.0,
          emp2PreviousEmployed: null,
          emp2PreviousEmail: null,
          emp2PreviousAddress: this.state.applicant3PreEmployment2Address,
          emp2PreviousCity: this.state.applicant3PreEmployment2City,
          emp2PreviousState: this.state.applicant3PreEmployment2State,
          emp2PreviousZip: this.state.applicant3PreEmployment2Zip,
          emp2PreviousPhone: null,
          emp2PreviousSalary: 0.0,
          emp2PreviousFrom: this.state.applicant3PreEmployment2From,
          emp2PreviousTo: this.state.applicant3PreEmployment2Until,
          emp2PreviousPosition: this.state.applicant3PreEmployment2Position,
          emp2PreviousAnnualIncome: 0.0,
          spousesEmployer: this.state.applicant3SpousesEmployer,
          spousesEmployerAddress: this.state.applicant3SpousesEmployerAddress,
          spousesIncome: this.state.applicant3SpousesIncome
            ? parseInt(this.state.applicant3SpousesIncome).toFixed(2)
            : 0.0,
          referenceName: this.state.applicant3FirstReferencesName,
          referenceAddress: this.state.applicant3FirstReferencesAddress,
          referenceTelephone: this.state.applicant3FirstReferencesTelephone,
          referenceName2: this.state.applicant3SecondReferencesName,
          referenceAddress2: this.state.applicant3SecondReferencesAddress,
          referenceTelephone2: this.state.applicant3SecondReferencesTelephone,
          emergencyContactName: this.state.emergencyContactName,
          emergencyContactPhone: this.state.emergencyContactPhone,
          emergencyContactFax: this.state.emergencyContactFax,
          emergencyContactRelation: this.state.emergencyContactRelation,
          primaryBankName: this.state.applicant3PrimaryBankName,
          primaryBankBranch: this.state.applicant3PrimaryBankBranch,
          primaryBankAccType: this.state.applicant3PrimaryBankAccType,
          primaryBankAccNo: this.state.applicant3PrimaryBankAccNo,
          secondaryBankName: this.state.applicant3SecondaryBankName,
          secondaryBankBranch: this.state.applicant3SecondaryBankBranch,
          secondaryBankAccType: this.state.applicant3SecondaryBankAccType,
          secondaryBankAccNo: this.state.applicant3SecondaryBankAccNo,
          relativeNum: null,
          relativeName: null,
          additionalIncome: null,
          bankruptcy: false,
          crimeCheck: false,
          evicted: false,
          smoke: false,
          homePhone: null,
          resPresentYears: betweenYears(
            this.state.applicant3PresentFrom,
            this.state.applicant3PresentTo
          ),
          resPresentMonths: betweenMonths(
            this.state.applicant3PresentFrom,
            this.state.applicant3PresentTo
          ),
          resPreviousYear: betweenYears(
            this.state.applicant3PreviousFrom,
            this.state.applicant3PreviousTo
          ),
          resPreviousMonth: betweenMonths(
            this.state.applicant3PreviousFrom,
            this.state.applicant3PreviousTo
          ),
          empPresentYears: betweenYears(
            this.state.applicant3EmploymentFrom,
            this.state.applicant3EmploymentUntil
          ),
          empPresentMonths: betweenMonths(
            this.state.applicant3EmploymentFrom,
            this.state.applicant3EmploymentUntil
          ),
          empPreviousYear: betweenYears(
            this.state.applicant3PreEmploymentFrom,
            this.state.applicant3PreEmploymentUntil
          ),
          empPreviousMonth: betweenMonths(
            this.state.applicant3PreEmploymentFrom,
            this.state.applicant3PreEmploymentUntil
          ),
          vehicle: this.state.applicant3Vehicle,
          vehicleType: null,
          vehicleMadeBy: this.state.applicant3VehicleMadeBy,
          vehicleModel: this.state.applicant3VehicleModel,
          vehicleYear: this.state.applicant3VehicleYear,
          vehicleLicenseNumber: this.state.applicant3VehicleLicenseNumber,
        },
        applicantOrder: 3,
      });
    }
    let occupantSample = [];
    if (this.state.additionalApplicantInfo) {
      occupantSample.push({
        firstName: this.state.additionalApplicantFirstName,
        middleName: this.state.additionalApplicantMiddleName,
        lastName: this.state.additionalApplicantLastName,
        dob: this.state.additionalApplicantDob,
        gender: this.state.additionalApplicantGender,
        sin: this.state.additionalApplicantSIN,
      });
    }
    if (this.state.additionalApplicant2) {
      occupantSample.push({
        firstName: this.state.additionalApplicant2FirstName,
        middleName: this.state.additionalApplicant2MiddleName,
        lastName: this.state.additionalApplicant2LastName,
        dob: this.state.additionalApplicant2Dob,
        gender: this.state.additionalApplicant2Gender,
        sin: this.state.additionalApplicant2SIN,
      });
    }
    if (this.state.additionalApplicant3) {
      occupantSample.push({
        firstName: this.state.additionalApplicant3FirstName,
        middleName: this.state.additionalApplicant3MiddleName,
        lastName: this.state.additionalApplicant3LastName,
        dob: this.state.additionalApplicant3Dob,
        gender: this.state.additionalApplicant3Gender,
        sin: this.state.additionalApplicant3SIN,
      });
    }
    try {
      let body = {
        users: userSample,
        application: {
          apply: applicantSample,
          occupants: occupantSample,
          rentalEntityId: this.props.match.params.id
            ? this.props.match.params.id
            : "001",
          rent: 0.0,
          pets: false,
          petCount: 0,
          creditCheck: null,
          securityCheck: null,
          applicationForm: null,
          occupant: null,
          leaseTerm: parseInt(this.state.term),
          moveInDate: this.state.moveInDate,
          hydro: this.state.hydro,
          gas: this.state.gas,
          heat: this.state.heat,
          hotWaterHeater: this.state.hotWaterHeater,
          hotWaterTank: this.state.hotWaterTank,
          internet: this.state.internet,
          cableTv: this.state.cableTv,
          other: this.state.other,
          water: this.state.water,
          // createdAt: "2021-09-17T17:45:05.546+0000",
          // updatedAt: "2021-09-17T17:45:05.546+0000",
        },
        applicationFeeModel: {},
        token: "tok_visa",
      };

      setTorontoApplication(body).then((response) => {
        this.setState({ isButtonDisableFinish: true });
        if (response.status === 200) {
          if (response.status) {
            window.onbeforeunload = "";
            let message = response.data.data;
            this.props.pushAlert("Application Sent Successfully", "success");
            if (message === "Already Applied") {
              this.props.pushAlert(
                String("This application is" + message),
                "success"
              );
            }

            this.props.history.push({ pathname: "/applications" });
          }
        } else {
          this.props.pushAlert("Application creation failed", "danger");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  back() {
    if (this.state.nowTab > 1) {
      window.scrollTo(0, 0);

      this.setState({ nowTab: this.state.nowTab - 1 });
    }
    if (this.state.nowTab === 2) {
      this.setState({ mailCheck: false });
    }
    if (this.state.nowTab === 6) {
      window.scrollTo(0, 0);

      if (this.props.customerPaymentStatus === "verified") {
        this.setState({ nowTab: this.state.nowTab - 2 });
      } else {
        this.setState({ nowTab: this.state.nowTab - 1 });
      }
    }
  }
  addApplicant() {
    this.setState({ next: false });
    if (this.state.applicantCount === 1) {
      this.setState({ applicantCount: 2 });
      this.setState({ applicant2: true });
    } else if (this.state.applicantCount === 2) {
      this.setState({ applicantCount: 3 });
      if (this.state.applicant2) {
        this.setState({ applicant3: true });
      } else {
        this.setState({ applicant2: true });
      }
    }
  }
  addEmployment() {
    this.setState({ next: false });
    if (this.state.employmentCount === 1) {
      this.setState({ employmentCount: 2 });
      this.setState({ employment1: true });
    } else if (this.state.employmentCount === 2) {
      this.setState({ employmentCount: 3 });
      this.setState({ employment2: true });
    }
  }
  removeEmployment() {
    if (this.state.employmentCount === 2) {
      this.setState({ employmentCount: 1 });
      this.setState({ employment1: false });
    } else if (this.state.employmentCount === 3) {
      this.setState({ employmentCount: 2 });
      this.setState({ employment2: false });
    }
  }
  addApplicant2Employment() {
    this.setState({ next: false });
    if (this.state.applicant2EmploymentCount === 1) {
      this.setState({ applicant2EmploymentCount: 2 });
      this.setState({ applicant2Employment1: true });
    } else if (this.state.applicant2EmploymentCount === 2) {
      this.setState({ applicant2EmploymentCount: 3 });
      this.setState({ applicant2Employment2: true });
    }
  }
  removeApplicant2Employment() {
    this.setState({ next: false });
    if (this.state.applicant2EmploymentCount === 2) {
      this.setState({ applicant2EmploymentCount: 1 });
      this.setState({ applicant2Employment1: false });
    } else if (this.state.applicant2EmploymentCount === 3) {
      this.setState({ applicant2EmploymentCount: 2 });
      this.setState({ applicant2Employment2: false });
    }
  }
  addApplicant3Employment() {
    this.setState({ next: false });
    if (this.state.applicant3EmploymentCount === 1) {
      this.setState({ applicant3EmploymentCount: 2 });
      this.setState({ applicant3Employment1: true });
    } else if (this.state.applicant3EmploymentCount === 2) {
      this.setState({ applicant3EmploymentCount: 3 });
      this.setState({ applicant3Employment2: true });
    }
  }
  removeApplicant3Employment() {
    if (this.state.applicant3EmploymentCount === 2) {
      this.setState({ applicant3EmploymentCount: 1 });
      this.setState({ applicant3Employment1: false });
    } else if (this.state.applicant3EmploymentCount === 3) {
      this.setState({ applicant3EmploymentCount: 2 });
      this.setState({ applicant3Employment2: false });
    }
  }
  removeApplicant(index) {
    if (this.state.applicantCount === 3) {
      this.setState({ applicantCount: 2 });
      if (index === 2) {
        this.setState({ applicant2: false });
      } else {
        this.setState({ applicant3: false });
      }
    } else if (this.state.applicantCount === 2) {
      this.setState({ applicantCount: 1 });
      if (this.state.applicant2) {
        this.setState({ applicant2: false });
      } else {
        this.setState({ applicant3: false });
      }
    }
  }
  addAdditionalApplicant() {
    this.setState({ next: false });
    if (this.state.additionalApplicantCount === 1) {
      this.setState({ additionalApplicantCount: 2 });
      this.setState({ additionalApplicant2: true });
    } else if (this.state.additionalApplicantCount === 2) {
      this.setState({ additionalApplicantCount: 3 });
      if (this.state.additionalApplicant2) {
        this.setState({ additionalApplicant3: true });
      } else {
        this.setState({ additionalApplicant2: true });
      }
    }
  }
  removeAdditionalApplicant(index) {
    if (this.state.additionalApplicantCount === 3) {
      this.setState({ additionalApplicantCount: 2 });
      if (index === 2) {
        this.setState({ additionalApplicant2: false });
      } else {
        this.setState({ additionalApplicant3: false });
      }
    } else if (this.state.additionalApplicantCount === 2) {
      this.setState({ additionalApplicantCount: 1 });
      if (this.state.additionalApplicant2) {
        this.setState({ additionalApplicant2: false });
      } else {
        this.setState({ additionalApplicant3: false });
      }
    }
  }
  onChangeDate(name, value) {
    this.setState({ [name]: value });
  }
  async onchange(e) {
    let { name, value } = e.target;

    if (
      name === "applicantMobile" ||
      name === "applicant2Mobile" ||
      name === "applicant3Mobile" ||
      name === "emergencyContactPhone" ||
      name === "applicantFirstReferencesTelephone" ||
      name === "applicant2FirstReferencesTelephone" ||
      name === "applicant3FirstReferencesTelephone" ||
      name === "applicantSecondReferencesTelephone" ||
      name === "applicant2SecondReferencesTelephone" ||
      name === "applicant3SecondReferencesTelephone" ||
      name === "presentLandlordPhone" ||
      name === "applicant2PresentLandlordPhone" ||
      name === "applicant3PresentLandlordPhone" ||
      name === "previousLandlordPhone" ||
      name === "applicant2PreviousLandlordPhone" ||
      name === "applicant3PreviousLandlordPhone" ||
      name === "presentHomePhone" ||
      name === "applicant2PresentHomePhone" ||
      name === "applicant3PresentHomePhone" ||
      name === "employmentPhone" ||
      name === "applicant2EmploymentPhone" ||
      name === "applicant3EmploymentPhone" ||
      name === "presentHomeFax" ||
      name === "applicant2PresentHomeFax" ||
      name === "applicant3PresentHomeFax" ||
      name === "emergencyContactFax"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (
      name === "applicantPrimaryBankAccNo" ||
      name === "applicantSecondaryBankAccNo" ||
      name === "applicant2PrimaryBankAccNo" ||
      name === "applicant2SecondaryBankAccNo" ||
      name === "applicant3PrimaryBankAccNo" ||
      name === "applicant3SecondaryBankAccNo"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
    
      if (isNaN(value)) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else if (
      name === "applicantFirstName" ||
      name === "applicantMiddleName" ||
      name === "applicantLastName" ||
      name === "applicantFirstReferencesName" ||
      name === "applicantSecondReferencesName" ||
      name === "emergencyContactName" ||
      name === "applicant2FirstName" ||
      name === "applicant2MiddleName" ||
      name === "applicant2LastName" ||
      name === "applicant2FirstReferencesName" ||
      name === "applicant2SecondReferencesName" ||
      name === "applicant3FirstName" ||
      name === "applicant3MiddleName" ||
      name === "applicant3LastName" ||
      name === "additionalApplicantFirstName" ||
      name === "additionalApplicantMiddleName" ||
      name === "additionalApplicantLastName" ||
      name === "additionalApplicant2FirstName" ||
      name === "additionalApplicant2MiddleName" ||
      name === "additionalApplicant2LastName" ||
      name === "additionalApplicant3FirstName" ||
      name === "additionalApplicant3MiddleName" ||
      name === "additionalApplicant3LastName" ||
      name === "applicant3FirstReferencesName" ||
      name === "applicant3SecondReferencesName" ||
      name === "presentLandlord" ||
      name === "applicant2PresentLandlord" ||
      name === "applicant3PresentLandlord" ||
      name === "previousLandlord" ||
      name === "applicant2PreviousLandlord" ||
      name === "applicant3PreviousLandlord"
    ) {
      await this.setState({
        [name]: value.replace(/[^a-zA-Z/\s/g\s,\s.\s-]+/g, ""),
      });
    } else if (
      name === "presentZip" ||
      name === "previousZip" ||
      name === "applicant2PresentZip" ||
      name === "applicant2PreviousZip" ||
      name === "applicant3PresentZip" ||
      name === "applicant3PreviousZip" ||
      name === "employmentZip" ||
      name === "preEmploymentZip" ||
      name === "preEmployment2Zip" ||
      name === "applicant2EmploymentZip" ||
      name === "applicant2PreEmploymentZip" ||
      name === "applicant2PreEmployment2Zip" ||
      name === "applicant3EmploymentZip" ||
      name === "applicant3PreEmploymentZip" ||
      name === "applicant3PreEmployment2Zip"
    ) {
      await this.setState({
        [name]: value.replace(/[^a-zA-Z0-9\s]+/g, ""),
      });
    } else if (
      name === "applicantSIN" ||
      name === "applicant2SIN" ||
      name === "applicant3SIN" ||
      name === "additionalApplicantSIN" ||
      name === "additionalApplicant2SIN" ||
      name === "additionalApplicant3SIN" ||
      name === "presentLandlordIDProof" ||
      name === "applicant2PresentLandlordIDProof" ||
      name === "applicant3PresentLandlordIDProof"
    ) {
      let sin = value.replace(/[^\d]/g, "");
      value = sin;
      if (sin.length > 6) {
        value = sin.replace(/(\d{3})(\d{3})/, "$1 $2 ");
      } else if (sin.length > 3) {
        value = sin.replace(/(\d{3})/, "$1 ");
      }
      if (sin.length > 9) {
        await this.setState({ [name]: this.state[name] });
      } else {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
  }

  checkMobile(value) {
   
    return ((value.length !== 14 || value === "") && this.state.next);
  }
  checkSIN(value) {
  
    return ((value.length !== 11 || value === "") && this.state.next);
  }
  checkAccountNo(value) {
   
    return ((isNaN(value) || value === "") && this.state.next);
  }
  async onchangeState(e) {
    let { name, value } = e.target;
    if (
      name === "applicantMobile" ||
      name === "applicant2Mobile" ||
      name === "applicant3Mobile" ||
      name === "emergencyContactPhone" ||
      name === "applicantFirstReferencesTelephone" ||
      name === "applicant2FirstReferencesTelephone" ||
      name === "applicant3FirstReferencesTelephone" ||
      name === "applicantSecondReferencesTelephone" ||
      name === "applicant2SecondReferencesTelephone" ||
      name === "applicant3SecondReferencesTelephone" ||
      name === "presentLandlordPhone" ||
      name === "applicant2PresentLandlordPhone" ||
      name === "applicant3PresentLandlordPhone" ||
      name === "presentHomePhone" ||
      name === "applicant2PresentHomePhone" ||
      name === "applicant3PresentHomePhone" ||
      name === "employmentPhone" ||
      name === "applicant2EmploymentPhone" ||
      name === "applicant3EmploymentPhone"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 10) {
        value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      } else if (phone.length > 6) {
        value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "($1) ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "($1");
      }
      if (phone.length > 10) {
        await this.setState({ [name]: this.state[name] });
      } else if (
        name === "applicantFirstName" ||
        name === "applicantMiddleName" ||
        name === "applicantLastName" ||
        name === "applicantFirstReferencesName" ||
        name === "applicantSecondReferencesName" ||
        name === "emergencyContactName" ||
        name === "applicant2FirstName" ||
        name === "applicant2MiddleName" ||
        name === "applicant2LastName" ||
        name === "applicant2FirstReferencesName" ||
        name === "applicant2SecondReferencesName" ||
        name === "applicant3FirstName" ||
        name === "applicant3MiddleName" ||
        name === "applicant3LastName" ||
        name === "additionalApplicantFirstName" ||
        name === "additionalApplicantMiddleName" ||
        name === "additionalApplicantLastName" ||
        name === "additionalApplicant2FirstName" ||
        name === "additionalApplicant2MiddleName" ||
        name === "additionalApplicant2LastName" ||
        name === "additionalApplicant3FirstName" ||
        name === "additionalApplicant3MiddleName" ||
        name === "additionalApplicant3LastName" ||
        name === "applicant3FirstReferencesName" ||
        name === "applicant3SecondReferencesName" ||
        name === "presentLandlord" ||
        name === "applicant2PresentLandlord" ||
        name === "applicant3PresentLandlord" ||
        name === "previousLandlord" ||
        name === "applicant2PreviousLandlord" ||
        name === "applicant3PreviousLandlord"
      ) {
        await this.setState({
          [name]: value.replace(/[^a-zA-Z/\s/g\s,\s.\s-]+/g, ""),
        });
      } else if (
        name === "presentZip" ||
        name === "previousZip" ||
        name === "applicant2PresentZip" ||
        name === "applicant2PreviousZip" ||
        name === "applicant3PresentZip" ||
        name === "applicant3PreviousZip" ||
        name === "employmentZip" ||
        name === "preEmploymentZip" ||
        name === "preEmployment2Zip" ||
        name === "applicant2EmploymentZip" ||
        name === "applicant2PreEmploymentZip" ||
        name === "applicant2PreEmployment2Zip" ||
        name === "applicant3EmploymentZip" ||
        name === "applicant3PreEmploymentZip" ||
        name === "applicant3PreEmployment2Zip"
      ) {
        await this.setState({
          [name]: value.replace(/[^a-zA-Z0-9\s]+/g, ""),
        });
      } else if (
        name === "applicantSIN" ||
        name === "applicant2SIN" ||
        name === "applicant3SIN" ||
        name === "additionalApplicantSIN" ||
        name === "additionalApplicant2SIN" ||
        name === "additionalApplicant3SIN"
      ) {
        let sin = value.replace(/[^\d]/g, "");
        value = sin;
        if (sin.length > 7) {
          value = sin.replace(/(\d{3})(\d{3})/, "$1 $2 ");
        } else if (sin.length > 3) {
          value = sin.replace(/(\d{3})/, "$1 ");
        }
        if (sin.length > 9) {
          await this.setState({ [name]: this.state[name] });
        } else {
          await this.setState({ [name]: value });
        }
      } else {
        await this.setState({ [name]: value });
      }
    } else {
      await this.setState({ [name]: value });
    }
  }
  checkEmail(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
    return (
      (!re.test(String(value).toLowerCase()) || value === "") &&
      this.state.next
    ) ;
  }

  checkValidate(value) {
    
    return (value === "" && this.state.next);
  }
  render() {
    return (
      <div
        className="container-fluid"
        style={{ minHeight: window.screen.height }}
      >
        {/* <StepNavigator tab={this.state.nowTab} /> */}
        {/* <div style={{ zIndex:"5000" }}>
         <Loader/>
       </div> */}
        <div className="row stepFormNav ">
          <div
            className="col text-center pointer mobileStepView"
            id="step1"
            name="step1"
            onClick={() => this.clickStep(1)}
          >
            <div className="row">
              <div className="col SideLineHide"></div>
              <div className="col">
                <div className=" social-container-step activeStep">
                  <div className="social ">
                    <i className="fa fa-address-card-o " aria-hidden="true"></i>
                  </div>
                </div>
                <br></br>
                <span className="stepSpan">step 1</span>
                <br></br>
              </div>
              <div
                className={
                  this.state.nowTab > 1
                    ? "col  SideLine activeLine"
                    : "col SideLine"
                }
              ></div>
            </div>
          </div>
          <div
            className="col text-center pointer mobileStepView"
            id="step2"
            name="step2"
            onClick={() => this.clickStep(2)}
          >
            <div className="row">
              <div
                className={
                  this.state.nowTab > 1
                    ? "col SideLine activeLine"
                    : "col SideLine"
                }
              ></div>
              <div className="col">
                <div
                  className={
                    this.state.nowTab > 1
                      ? "social-container-step activeStep"
                      : "social-container-step"
                  }
                >
                  <div className="social ">
                    <i className="fa fa-address-book " aria-hidden="true"></i>
                  </div>
                </div>
                <br></br>
                <span className="stepSpan">step 2</span>
                <br></br>
              </div>
              <div
                className={
                  this.state.nowTab > 2
                    ? "col SideLine activeLine"
                    : "col SideLine"
                }
              ></div>
            </div>
          </div>
          <div
            className="col text-center pointer mobileStepView"
            id="step3"
            name="step3"
            onClick={() => this.clickStep(3)}
          >
            <div className="row">
              <div
                className={
                  this.state.nowTab > 2
                    ? "col SideLine activeLine"
                    : "col SideLine"
                }
              ></div>
              <div className="col ">
                <div
                  className={
                    this.state.nowTab > 2
                      ? "social-container-step activeStep"
                      : "social-container-step"
                  }
                >
                  <div className="social ">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </div>
                </div>
                <br></br>
                <span className="stepSpan">step 3</span>
                <br></br>
              </div>
              <div
                className={
                  this.state.nowTab > 3
                    ? "col SideLine activeLine"
                    : "col SideLine"
                }
              ></div>
            </div>
          </div>
          <div
            className="col text-center pointer mobileStepView"
            id="step4"
            name="step4"
            onClick={() => this.clickStep(4)}
          >
            <div className="row">
              <div
                className={
                  this.state.nowTab > 3
                    ? "col SideLine activeLine"
                    : "col SideLine"
                }
              ></div>
              <div className="col ">
                <div
                  className={
                    this.state.nowTab > 3
                      ? "social-container-step activeStep"
                      : "social-container-step"
                  }
                >
                  <div className="social ">
                    <i className="fa fa-credit-card" aria-hidden="true"></i>
                  </div>
                </div>
                <br></br>
                <span className="stepSpan">step 4</span>
                <br></br>
              </div>
              <div
                className={
                  this.state.nowTab > 4
                    ? "col SideLine activeLine"
                    : "col SideLine"
                }
              ></div>
            </div>
          </div>
          {this.props.customerPaymentStatus !== "verified" && (
            <div
              className="col text-center pointer mobileStepView"
              id="step5"
              name="step5"
              onClick={() => this.clickStep(5)}
            >
              <div className="row">
                <div
                  className={
                    this.state.nowTab > 4
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
                <div className="col ">
                  <div
                    className={
                      this.state.nowTab > 4
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i
                        className="fa fa-credit-card-alt"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <br></br>
                  <span className="stepSpan">step 5</span>
                  <br></br>
                </div>
                <div
                  className={
                    this.state.nowTab > 5
                      ? "col SideLine activeLine"
                      : "col SideLine"
                  }
                ></div>
              </div>
            </div>
          )}
          <div
            className="col text-center pointer mobileStepView"
            id="step6"
            name="step6"
            onClick={() =>
              this.clickStep(
                this.props.customerPaymentStatus === "verified" ? 5 : 6
              )
            }
          >
            <div className="row">
              <div
                className={
                  this.state.nowTab > 5
                    ? "col SideLine activeLine"
                    : "col SideLine"
                }
              ></div>

              <div className="col">
                <div
                  className={
                    this.state.nowTab > 5
                      ? "social-container-step activeStep"
                      : "social-container-step"
                  }
                >
                  <div className="social">
                    <i className="fa fa-retweet " aria-hidden="true"></i>
                  </div>
                </div>
                <br></br>
                <span className="stepSpan">
                  step{" "}
                  {this.props.customerPaymentStatus === "verified" ? "5" : "6"}
                </span>
                <br></br>
              </div>
              <div className="col SideLineHide"></div>
            </div>
          </div>
        </div>
        <div className="row stepFormNavTitle titleStep">
          <div
            className="col d-flex justify-content-center text-center pointer"
            id="step1Title"
            name="step1Title"
            onClick={() => this.clickStep(1)}
          >
            {" "}
            <p className="stepTitle">Personal Info</p>
          </div>
          <div
            className="col d-flex justify-content-center text-center pointer "
            id="step2Title"
            name="step2Title"
            onClick={() => this.clickStep(2)}
          >
            {" "}
            <p className="stepTitle ">Residence History</p>
          </div>
          <div
            className="col d-flex justify-content-center text-center pointer "
            id="step3Title"
            name="step3Title"
            onClick={() => this.clickStep(3)}
          >
            {" "}
            <p className="stepTitle">Employment Data</p>
          </div>
          <div
            className="col d-flex justify-content-center text-center pointer "
            id="step4Title"
            name="step4Title"
            onClick={() => this.clickStep(4)}
          >
            {" "}
            <p className="stepTitle">Bank Account</p>
          </div>
          {this.props.customerPaymentStatus !== "verified" && (
            <div
              className="col d-flex justify-content-center text-center pointer "
              id="step5Title"
              name="step5Title"
              onClick={() => this.clickStep(5)}
            >
              {" "}
              <p className="stepTitle">Payment Method</p>
            </div>
          )}
          <div
            className="col d-flex justify-content-center text-center"
            id="step6Title"
            name="step6Title"
            onClick={() => this.clickStep(6)}
          >
            {" "}
            <p className="stepTitle">Review</p>
          </div>
        </div>
        <div className="row stepFormNavTitle  titleStepOpp">
          <div className="col d-flex justify-content-center text-center">
            <p className="stepTitle ">
              {this.state.nowTab === 1
                ? "Personal Info"
                : this.state.nowTab === 2
                ? "Residence History"
                : this.state.nowTab === 3
                ? "Employment Data"
                : this.state.nowTab === 4
                ? "Bank Account"
                : this.state.nowTab === 5 &&
                  this.props.customerPaymentStatus !== "verified"
                ? "Payment Method"
                : this.state.nowTab === 5 &&
                  this.props.customerPaymentStatus === "verified"
                ? "Review"
                : this.state.nowTab === 6
                ? "Review"
                : ""}
            </p>
          </div>
        </div>
        {this.state.nowTab === 1 && (
          <>
            <div className="cardApplication">
              <p className="subTitle stepTitle pt-3 textPri ">
                Main Applicant Details
              </p>
              <br></br>
              <br></br>

              <p className="stepTitle subTitle mt-2 textDark ">Basic</p>
              <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                <div className="col alignInput">
                  <ApplicationInput
                    name="First Name"
                    type="text"
                    target="applicantFirstName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your first name"
                    validate={this.checkValidate(this.state.applicantFirstName)}
                    value={this.state.applicantFirstName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Middle Name"
                    type="text"
                    target="applicantMiddleName"
                    onChange={this.onchange}
                    errorMessage="your middle name"
                    validate={this.checkValidate(
                      this.state.applicantMiddleName
                    )}
                    value={this.state.applicantMiddleName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Last Name"
                    type="text"
                    target="applicantLastName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your last name"
                    validate={this.checkValidate(this.state.applicantLastName)}
                    value={this.state.applicantLastName}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Email"
                    type="email"
                    target="applicantEmail"
                    important="true"
                    onChange={this.onchange}
                    errorMessage={
                      this.checkValidate(this.state.applicantEmail)
                        ? "your email"
                        : "your email correct format"
                    }
                    validate={this.checkEmail(this.state.applicantEmail)}
                    value={this.state.applicantEmail}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Date of Birth"
                    type="date"
                    target="applicantDob"
                    min={"1910-01-01"}
                    max={this.today()}
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage="your date of birth"
                    validate={this.checkValidate(this.state.applicantDob)}
                    value={this.state.applicantDob}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Gender"
                    type="optional"
                    options={["Male", "Female"]}
                    optionValue={["male", "female"]}
                    target="applicantGender"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your gender"
                    validate={this.checkValidate(this.state.applicantGender)}
                    value={this.state.applicantGender}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="S.I.N"
                    type="text"
                    target="applicantSIN"
                    min="1"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.applicantSIN)
                        ? "your social security number"
                        : "a valid social security number"
                    }
                    validate={this.checkSIN(this.state.applicantSIN)}
                    value={this.state.applicantSIN}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Cell Number"
                    type="text"
                    target="applicantMobile"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.applicantMobile)
                        ? "your cell number"
                        : "the valid 10 digit cell number"
                    }
                    validate={this.checkMobile(this.state.applicantMobile)}
                    value={this.state.applicantMobile}
                  />
                </div>
              </div>
              <p className="subTitle stepTitle pt-3 textDark ">
                Vehicle Details
              </p>
              <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                <div className="col alignInput ">
                  <ApplicationInput
                    name="Do you have vehicle"
                    type="optionYN"
                    target="applicantVehicle"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.applicantVehicle)}
                    value={this.state.applicantVehicle}
                  />
                </div>
                {this.state.applicantVehicle && (
                  <>
                    <div className="col alignInput ">
                      <ApplicationInput
                        name="Driving License Number"
                        type="text"
                        target="applicantDriversLicense"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the driving license number"
                        validate={this.checkValidate(
                          this.state.applicantDriversLicense
                        )}
                        value={this.state.applicantDriversLicense}
                        // icon={
                        //   this.props.role !== "Guest" ? "fa-camera-retro" : ""
                        // }
                        // clickIcon={this.changeLicense}
                      />
                    </div>
                    <div className="col alignInput ">
                      <ApplicationInput
                        name="Make of Vehicle"
                        type="text"
                        target="applicantVehicleMadeBy"
                        onChange={this.onchange}
                        important="true"
                        errorMessage=" make of vehicle"
                        validate={this.checkValidate(
                          this.state.applicantVehicleMadeBy
                        )}
                        value={this.state.applicantVehicleMadeBy}
                      />
                    </div>
                    <div className="col alignInput ">
                      <ApplicationInput
                        name="Model of Vehicle"
                        type="text"
                        target="applicantVehicleModel"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="model of vehicle"
                        validate={this.checkValidate(
                          this.state.applicantVehicleModel
                        )}
                        value={this.state.applicantVehicleModel}
                      />
                    </div>
                    <div className="col-3 alignInput ">
                      <ApplicationInput
                        name="Year of Vehicle"
                        type="optional"
                        options={this.state.dropYears}
                        optionValue={this.state.dropYears}
                        target="applicantVehicleYear"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the year of vehicle"
                        validate={this.checkValidate(
                          this.state.applicantVehicleYear
                        )}
                        value={this.state.applicantVehicleYear}
                      />
                    </div>
                    <div className="col-3 alignInput ">
                      <ApplicationInput
                        name="Vehicle License No."
                        type="text"
                        min="1"
                        target="applicantVehicleLicenseNumber"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="vehicle license no"
                        validate={this.checkValidate(
                          this.state.applicantVehicleLicenseNumber
                        )}
                        value={this.state.applicantVehicleLicenseNumber}
                      />
                    </div>
                  </>
                )}
              </div>
              <p className="subTitle stepTitle pt-3 textDark">
                {this.state.applicantFirstName} References Details
              </p>
              <div className="row  pt-5 inputContainer">
                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="First Reference Name"
                    type="text"
                    target="applicantFirstReferencesName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of first reference"
                    validate={this.checkValidate(
                      this.state.applicantFirstReferencesName
                    )}
                    value={this.state.applicantFirstReferencesName}
                  />
                </div>

                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="First Reference Address"
                    type="text"
                    target="applicantFirstReferencesAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the address of first reference"
                    validate={this.checkValidate(
                      this.state.applicantFirstReferencesAddress
                    )}
                    value={this.state.applicantFirstReferencesAddress}
                  />
                </div>
                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="First Reference Phone Number"
                    type="text"
                    target="applicantFirstReferencesTelephone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(
                        this.state.applicantFirstReferencesTelephone
                      )
                        ? "the phone number of first reference."
                        : "the valid phone number of first reference."
                    }
                    validate={this.checkMobile(
                      this.state.applicantFirstReferencesTelephone
                    )}
                    value={this.state.applicantFirstReferencesTelephone}
                  />
                </div>

                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="Second Reference Name"
                    type="text"
                    target="applicantSecondReferencesName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of second reference"
                    validate={this.checkValidate(
                      this.state.applicantSecondReferencesName
                    )}
                    value={this.state.applicantSecondReferencesName}
                  />
                </div>

                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="Second Reference Address"
                    type="text"
                    target="applicantSecondReferencesAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the address of second reference"
                    validate={this.checkValidate(
                      this.state.applicantSecondReferencesAddress
                    )}
                    value={this.state.applicantSecondReferencesAddress}
                  />
                </div>
                <div className="col-4 alignInput">
                  <ApplicationInput
                    name="Second Reference Phone Number"
                    type="text"
                    target="applicantSecondReferencesTelephone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(
                        this.state.applicantSecondReferencesTelephone
                      )
                        ? "the phone number of second reference."
                        : "the valid phone number of second reference."
                    }
                    validate={this.checkMobile(
                      this.state.applicantSecondReferencesTelephone
                    )}
                    value={this.state.applicantSecondReferencesTelephone}
                  />
                </div>
              </div>
            </div>
            {this.state.applicant2 && (
              <div className="cardApplication">
                <p className="subTitle stepTitle pt-3 textPri ">
                  Second Applicant Details
                </p>
                <i
                  className="fa fa-window-close pull-right pr-5 pt-4 pointer"
                  aria-hidden="true"
                  onClick={() => this.removeApplicant(2)}
                ></i>
                <br></br>
                <br></br>
                <p className=" stepTitle subTitle mt-2 textDark ">Basic</p>
                <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="First Name"
                      type="text"
                      target="applicant2FirstName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the first name"
                      validate={this.checkValidate(
                        this.state.applicant2FirstName
                      )}
                      value={this.state.applicant2FirstName}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Middle Name"
                      type="text"
                      target="applicant2MiddleName"
                      onChange={this.onchange}
                      errorMessage="the middle name"
                      validate={this.checkValidate(
                        this.state.applicant2MiddleName
                      )}
                      value={this.state.applicant2MiddleName}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Last Name"
                      type="text"
                      target="applicant2LastName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the last name"
                      validate={this.checkValidate(
                        this.state.applicant2LastName
                      )}
                      value={this.state.applicant2LastName}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Email"
                      type="email"
                      target="applicant2Email"
                      important="true"
                      onChange={this.onchange}
                      errorMessage={
                        this.checkValidate(this.state.applicant2Email)
                          ? "your Email"
                          : "a valid email address"
                      }
                      validate={this.checkEmail(this.state.applicant2Email)}
                      value={this.state.applicant2Email}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Date of Birth"
                      type="date"
                      target="applicant2Dob"
                      min={"1910-01-01"}
                      max={this.today()}
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage="the date of birth"
                      validate={this.checkValidate(this.state.applicant2Dob)}
                      value={this.state.applicant2Dob}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Gender"
                      type="optional"
                      options={["Male", "Female"]}
                      optionValue={["male", "female"]}
                      target="applicant2Gender"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="your gender"
                      validate={this.checkValidate(this.state.applicant2Gender)}
                      value={this.state.applicant2Gender}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="S.I.N"
                      type="text"
                      target="applicant2SIN"
                      min="1"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.applicant2SIN)
                          ? "the social security number"
                          : "the valid social security number"
                      }
                      validate={this.checkSIN(this.state.applicant2SIN)}
                      value={this.state.applicant2SIN}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Cell Number"
                      type="text"
                      target="applicant2Mobile"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.applicant2Mobile)
                          ? "your cell number"
                          : "the valid 10 digit cell number "
                      }
                      validate={this.checkMobile(this.state.applicant2Mobile)}
                      value={this.state.applicant2Mobile}
                    />
                  </div>
                </div>
                <p className="subTitle stepTitle pt-3 textDark ">
                  Vehicle Details
                </p>
                <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Do you have vehicle"
                      type="optionYN"
                      target="applicant2Vehicle"
                      onChange={this.onchangeCheck}
                      validate={this.checkValidate(
                        this.state.applicant2Vehicle
                      )}
                      value={this.state.applicant2Vehicle}
                    />
                  </div>
                  {this.state.applicant2Vehicle && (
                    <>
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="Driving License Number"
                          type="text"
                          target="applicant2DriversLicense"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your driving license number"
                          validate={this.checkValidate(
                            this.state.applicant2DriversLicense
                          )}
                          value={this.state.applicant2DriversLicense}
                          // icon={
                          //   this.props.role !== "Guest" ? "fa-camera-retro" : ""
                          // }
                          // clickIcon={this.changeLicense}
                        />
                      </div>
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="Make of Vehicle"
                          type="text"
                          target="applicant2VehicleMadeBy"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the make of vehicle"
                          validate={this.checkValidate(
                            this.state.applicant2VehicleMadeBy
                          )}
                          value={this.state.applicant2VehicleMadeBy}
                        />
                      </div>
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="Model of Vehicle"
                          type="text"
                          target="applicant2VehicleModel"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the model of vehicle"
                          validate={this.checkValidate(
                            this.state.applicant2VehicleModel
                          )}
                          value={this.state.applicant2VehicleModel}
                        />
                      </div>
                      <div className="col-3 alignInput ">
                        <ApplicationInput
                          name="Year of Vehicle"
                          type="optional"
                          options={this.state.dropYears}
                          optionValue={this.state.dropYears}
                          target="applicant2VehicleYear"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the year of vehicle"
                          validate={this.checkValidate(
                            this.state.applicant2VehicleYear
                          )}
                          value={this.state.applicant2VehicleYear}
                        />
                      </div>
                      <div className="col-3 alignInput ">
                        <ApplicationInput
                          name="Vehicle License No."
                          type="text"
                          min="1"
                          target="applicant2VehicleLicenseNumber"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the vehicle license no."
                          validate={this.checkValidate(
                            this.state.applicant2VehicleLicenseNumber
                          )}
                          value={this.state.applicant2VehicleLicenseNumber}
                        />
                      </div>
                    </>
                  )}
                </div>
                <p className="subTitle stepTitle pt-3 textDark">
                  {this.state.applicant2FirstName} References Details
                </p>
                <div className="row  pt-5 inputContainer">
                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="First Reference Name"
                      type="text"
                      target="applicant2FirstReferencesName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of first reference"
                      validate={this.checkValidate(
                        this.state.applicant2FirstReferencesName
                      )}
                      value={this.state.applicant2FirstReferencesName}
                    />
                  </div>

                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="First Reference Address"
                      type="text"
                      target="applicant2FirstReferencesAddress"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the address of first reference"
                      validate={this.checkValidate(
                        this.state.applicant2FirstReferencesAddress
                      )}
                      value={this.state.applicant2FirstReferencesAddress}
                    />
                  </div>
                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="First Reference Phone Number"
                      type="text"
                      target="applicant2FirstReferencesTelephone"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(
                          this.state.applicant2FirstReferencesTelephone
                        )
                          ? "the phone number of first reference."
                          : "the valid phone number of first reference."
                      }
                      validate={this.checkMobile(
                        this.state.applicant2FirstReferencesTelephone
                      )}
                      value={this.state.applicant2FirstReferencesTelephone}
                    />
                  </div>

                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="Second Reference Name"
                      type="text"
                      target="applicant2SecondReferencesName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of second reference"
                      validate={this.checkValidate(
                        this.state.applicant2SecondReferencesName
                      )}
                      value={this.state.applicant2SecondReferencesName}
                    />
                  </div>

                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="Second Reference Address"
                      type="text"
                      target="applicant2SecondReferencesAddress"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the address of second reference"
                      validate={this.checkValidate(
                        this.state.applicant2SecondReferencesAddress
                      )}
                      value={this.state.applicant2SecondReferencesAddress}
                    />
                  </div>
                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="Second Reference Phone Number"
                      type="text"
                      target="applicant2SecondReferencesTelephone"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(
                          this.state.applicant2SecondReferencesTelephone
                        )
                          ? "the phone number of second reference."
                          : "the valid phone number of second reference."
                      }
                      validate={this.checkMobile(
                        this.state.applicant2SecondReferencesTelephone
                      )}
                      value={this.state.applicant2SecondReferencesTelephone}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.applicant3 && (
              <div className="cardApplication">
                <p className="subTitle stepTitle pt-3 textPri ">
                  Third Applicant Details
                </p>
                <i
                  className="fa fa-window-close pull-right pr-5 pt-4 pointer"
                  aria-hidden="true"
                  onClick={() => this.removeApplicant(3)}
                ></i>
                <br></br>
                <br></br>
                <p className=" stepTitle subTitle mt-2 textDark ">Basic</p>
                <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="First Name"
                      type="text"
                      target="applicant3FirstName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the first name"
                      validate={this.checkValidate(
                        this.state.applicant3FirstName
                      )}
                      value={this.state.applicant3FirstName}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Middle Name"
                      type="text"
                      target="applicant3MiddleName"
                      onChange={this.onchange}
                      errorMessage="the middle name"
                      validate={this.checkValidate(
                        this.state.applicant3MiddleName
                      )}
                      value={this.state.applicant3MiddleName}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Last Name"
                      type="text"
                      target="applicant3LastName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the last name"
                      validate={this.checkValidate(
                        this.state.applicant3LastName
                      )}
                      value={this.state.applicant3LastName}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Email"
                      type="email"
                      target="applicant3Email"
                      important="true"
                      onChange={this.onchange}
                      errorMessage={
                        this.checkValidate(this.state.applicant3Email)
                          ? "the email"
                          : "the valid email address"
                      }
                      validate={this.checkEmail(this.state.applicant3Email)}
                      value={this.state.applicant3Email}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Date of Birth"
                      type="date"
                      target="applicant3Dob"
                      min={"1910-01-01"}
                      max={this.today()}
                      onChange={this.onChangeDate}
                      important="true"
                      errorMessage="the date of birth"
                      validate={this.checkValidate(this.state.applicant3Dob)}
                      value={this.state.applicant3Dob}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Gender"
                      type="optional"
                      options={["Male", "Female"]}
                      optionValue={["male", "female"]}
                      target="applicant3Gender"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="your gender"
                      validate={this.checkValidate(this.state.applicant3Gender)}
                      value={this.state.applicant3Gender}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="S.I.N"
                      type="text"
                      target="applicant3SIN"
                      min="1"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.applicant3SIN)
                          ? "the social security number"
                          : "the valid social security number"
                      }
                      validate={this.checkSIN(this.state.applicant3SIN)}
                      value={this.state.applicant3SIN}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Cell Number"
                      type="text"
                      target="applicant3Mobile"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(this.state.applicant2Mobile)
                          ? "the cell number"
                          : "the 10 digit cell number "
                      }
                      validate={this.checkMobile(this.state.applicant3Mobile)}
                      value={this.state.applicant3Mobile}
                    />
                  </div>
                </div>
                <p className="subTitle stepTitle pt-3 textDark ">
                  Vehicle Details
                </p>
                <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Do you have vehicle"
                      type="optionYN"
                      target="applicant3Vehicle"
                      onChange={this.onchangeCheck}
                      validate={this.checkValidate(
                        this.state.applicant3Vehicle
                      )}
                      value={this.state.applicant3Vehicle}
                    />
                  </div>
                  {this.state.applicant3Vehicle && (
                    <>
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="Driving License Number"
                          type="text"
                          target="applicant3DriversLicense"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the driving license number"
                          validate={this.checkValidate(
                            this.state.applicant3DriversLicense
                          )}
                          value={this.state.applicant3DriversLicense}
                          // icon={
                          //   this.props.role !== "Guest" ? "fa-camera-retro" : ""
                          // }
                          // clickIcon={this.changeLicense}
                        />
                      </div>
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="Make of Vehicle"
                          type="text"
                          target="applicant3VehicleMadeBy"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the make of vehicle"
                          validate={this.checkValidate(
                            this.state.applicant3VehicleMadeBy
                          )}
                          value={this.state.applicant3VehicleMadeBy}
                        />
                      </div>
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="Model of Vehicle"
                          type="text"
                          target="applicant3VehicleModel"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the model of vehicle"
                          validate={this.checkValidate(
                            this.state.applicant3VehicleModel
                          )}
                          value={this.state.applicant3VehicleModel}
                        />
                      </div>
                      <div className="col-3 alignInput ">
                        <ApplicationInput
                          name="Year of Vehicle"
                          type="optional"
                          options={this.state.dropYears}
                          optionValue={this.state.dropYears}
                          target="applicant3VehicleYear"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the year of vehicle"
                          validate={this.checkValidate(
                            this.state.applicant3VehicleYear
                          )}
                          value={this.state.applicant3VehicleYear}
                        />
                      </div>
                      <div className="col-3 alignInput ">
                        <ApplicationInput
                          name="Vehicle License No."
                          type="text"
                          target="applicant3VehicleLicenseNumber"
                          min="1"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the license no of vehicle"
                          validate={this.checkValidate(
                            this.state.applicant3VehicleLicenseNumber
                          )}
                          value={this.state.applicant3VehicleLicenseNumber}
                        />
                      </div>
                    </>
                  )}
                </div>
                <p className="subTitle stepTitle pt-3 textDark">
                  {this.state.applicant3FirstName} References Details
                </p>
                <div className="row  pt-5 inputContainer">
                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="First Reference Name"
                      type="text"
                      target="applicant3FirstReferencesName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of first reference"
                      validate={this.checkValidate(
                        this.state.applicant3FirstReferencesName
                      )}
                      value={this.state.applicant3FirstReferencesName}
                    />
                  </div>

                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="First Reference Address"
                      type="text"
                      target="applicant3FirstReferencesAddress"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the address of first reference"
                      validate={this.checkValidate(
                        this.state.applicant3FirstReferencesAddress
                      )}
                      value={this.state.applicant3FirstReferencesAddress}
                    />
                  </div>
                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="First Reference Phone Number"
                      type="text"
                      target="applicant3FirstReferencesTelephone"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(
                          this.state.applicant3FirstReferencesTelephone
                        )
                          ? "the phone number of first reference."
                          : "the valid phone number of first reference."
                      }
                      validate={this.checkMobile(
                        this.state.applicant3FirstReferencesTelephone
                      )}
                      value={this.state.applicant3FirstReferencesTelephone}
                    />
                  </div>

                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="Second Reference Name"
                      type="text"
                      target="applicant3SecondReferencesName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of second reference"
                      validate={this.checkValidate(
                        this.state.applicant3SecondReferencesName
                      )}
                      value={this.state.applicant3SecondReferencesName}
                    />
                  </div>

                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="Second Reference Address"
                      type="text"
                      target="applicant3SecondReferencesAddress"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the address of second reference"
                      validate={this.checkValidate(
                        this.state.applicant3SecondReferencesAddress
                      )}
                      value={this.state.applicant3SecondReferencesAddress}
                    />
                  </div>
                  <div className="col-4 alignInput">
                    <ApplicationInput
                      name="Second Reference Phone Number"
                      type="text"
                      target="applicant3SecondReferencesTelephone"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(
                          this.state.applicant3SecondReferencesTelephone
                        )
                          ? "the phone number of second reference."
                          : "the valid phone number of second reference."
                      }
                      validate={this.checkMobile(
                        this.state.applicant3SecondReferencesTelephone
                      )}
                      value={this.state.applicant3SecondReferencesTelephone}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.applicantCount < 3 && (
              <>
                <div className="row inputContainer pt-0 mt-3 pb-3">
                  <div className="col  text-right pt-0 mt-3 ">
                    <button
                      className=" buttonPri"
                      name="addApplicationButton"
                      id="addApplicationButton"
                      onClick={() => this.addApplicant()}
                    >
                      Add Applicant
                    </button>
                  </div>
                </div>
              </>
            )}
            <hr></hr>
            <div className="cardApplication">
              <p className="subTitle stepTitle pt-3 pb-3 textPri ">
                Additional proposed occupant details&nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  className="pull-right preEmployeeHide1"
                  style={{ zIndex: "900" }}
                >
                  <ApplicationInput
                    name=""
                    type="checkedBox"
                    target="additionalApplicantInfo"
                    onChange={this.onchangeCheck}
                    value={this.state.additionalApplicantInfo}
                  />
                </div>
              </p>

              <div
                className="subTitle stepTitle pt-5 pull-left preEmployeeHide2 mb-5"
                style={{ zIndex: "900", marginBottom: "200px" }}
              >
                <ApplicationInput
                  name=""
                  type="checkedBox"
                  target="additionalApplicantInfo"
                  onChange={this.onchangeCheck}
                  value={this.state.additionalApplicantInfo}
                />
              </div>

              <div className="preEmployeeHide2">
                <br></br>
                <br></br>
              </div>
              <br></br>
              <br></br>
              <br></br>
            </div>
            {this.state.additionalApplicantInfo && (
              <>
                <div className="cardApplication pt-3 pb-4">
                  <p className="subTitle stepTitle pt-2 textDark ">
                    Occupant - 1
                  </p>
                  <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                    <div className="col alignInput ">
                      <ApplicationInput
                        name="First Name"
                        type="text"
                        target="additionalApplicantFirstName"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the first name"
                        validate={this.checkValidate(
                          this.state.additionalApplicantFirstName
                        )}
                        value={this.state.additionalApplicantFirstName}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Middle Name"
                        type="text"
                        target="additionalApplicantMiddleName"
                        onChange={this.onchange}
                        errorMessage="the middle name"
                        validate={this.checkValidate(
                          this.state.additionalApplicantMiddleName
                        )}
                        value={this.state.additionalApplicantMiddleName}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Last Name"
                        type="text"
                        target="additionalApplicantLastName"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the last name"
                        validate={this.checkValidate(
                          this.state.additionalApplicantLastName
                        )}
                        value={this.state.additionalApplicantLastName}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Date of Birth"
                        type="date"
                        target="additionalApplicantDob"
                        min={"1910-01-01"}
                        max={this.today()}
                        onChange={this.onChangeDate}
                        important="true"
                        errorMessage="the date of birth"
                        validate={this.checkValidate(
                          this.state.additionalApplicantDob
                        )}
                        value={this.state.additionalApplicantDob}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Gender"
                        type="optional"
                        options={["Male", "Female"]}
                        optionValue={["male", "female"]}
                        target="additionalApplicantGender"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your gender"
                        validate={this.checkValidate(
                          this.state.additionalApplicantGender
                        )}
                        value={this.state.additionalApplicantGender}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="S.I.N"
                        type="text"
                        target="additionalApplicantSIN"
                        min="1"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.additionalApplicantSIN)
                            ? "the social security number"
                            : "the valid social security number"
                        }
                        validate={this.checkSIN(
                          this.state.additionalApplicantSIN
                        )}
                        value={this.state.additionalApplicantSIN}
                      />
                    </div>
                  </div>
                </div>
                {this.state.additionalApplicant2 && (
                  <div className="cardApplication pt-3 pb-4">
                    <p className="subTitle stepTitle pt-2 textDark ">
                      Occupant - 2
                    </p>
                    <i
                      className="fa fa-window-close pull-right pr-5 pt-4 pointer"
                      aria-hidden="true"
                      onClick={() => this.removeAdditionalApplicant(2)}
                    ></i>

                    <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="First Name"
                          type="text"
                          target="additionalApplicant2FirstName"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the first name"
                          validate={this.checkValidate(
                            this.state.additionalApplicant2FirstName
                          )}
                          value={this.state.additionalApplicant2FirstName}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Middle Name"
                          type="text"
                          target="additionalApplicant2MiddleName"
                          onChange={this.onchange}
                          errorMessage="the middle name"
                          validate={this.checkValidate(
                            this.state.additionalApplicant2MiddleName
                          )}
                          value={this.state.additionalApplicant2MiddleName}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Last Name"
                          type="text"
                          target="additionalApplicant2LastName"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the last name"
                          validate={this.checkValidate(
                            this.state.additionalApplicant2LastName
                          )}
                          value={this.state.additionalApplicant2LastName}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Date of Birth"
                          type="date"
                          target="additionalApplicant2Dob"
                          min={"1910-01-01"}
                          max={this.today()}
                          onChange={this.onChangeDate}
                          important="true"
                          errorMessage="the date of birth"
                          validate={this.checkValidate(
                            this.state.additionalApplicant2Dob
                          )}
                          value={this.state.additionalApplicant2Dob}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Gender"
                          type="optional"
                          options={["Male", "Female"]}
                          optionValue={["male", "female"]}
                          target="additionalApplicant2Gender"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your gender"
                          validate={this.checkValidate(
                            this.state.additionalApplicant2Gender
                          )}
                          value={this.state.additionalApplicant2Gender}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="S.I.N"
                          type="text"
                          target="additionalApplicant2SIN"
                          min="1"
                          onChange={this.onchange}
                          important="true"
                          errorMessage={
                            this.checkValidate(
                              this.state.additionalApplicant2SIN
                            )
                              ? "the social security number"
                              : "the valid social security number "
                          }
                          validate={this.checkSIN(
                            this.state.additionalApplicant2SIN
                          )}
                          value={this.state.additionalApplicant2SIN}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.additionalApplicant3 && (
                  <div className="cardApplication pt-3 pb-4">
                    <p className="subTitle stepTitle pt-2 textDark ">
                      Occupant - 3
                    </p>
                    <i
                      className="fa fa-window-close pull-right pr-5 pt-4 pointer"
                      aria-hidden="true"
                      onClick={() => this.removeAdditionalApplicant(3)}
                    ></i>

                    <div className="row inputContainer d-flex justify-content-left pb-0  mb-0 pt-5">
                      <div className="col alignInput ">
                        <ApplicationInput
                          name="First Name"
                          type="text"
                          target="additionalApplicant3FirstName"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the first name"
                          validate={this.checkValidate(
                            this.state.additionalApplicant3FirstName
                          )}
                          value={this.state.additionalApplicant3FirstName}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Middle Name"
                          type="text"
                          target="additionalApplicant3MiddleName"
                          onChange={this.onchange}
                          errorMessage="the middle name"
                          validate={this.checkValidate(
                            this.state.additionalApplicant3MiddleName
                          )}
                          value={this.state.additionalApplicant3MiddleName}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Last Name"
                          type="text"
                          target="additionalApplicant3LastName"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your last name"
                          validate={this.checkValidate(
                            this.state.additionalApplicant3LastName
                          )}
                          value={this.state.additionalApplicant3LastName}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Date of Birth"
                          type="date"
                          target="additionalApplicant3Dob"
                          min={"1910-01-01"}
                          max={this.today()}
                          onChange={this.onChangeDate}
                          important="true"
                          errorMessage="your date of birth"
                          validate={this.checkValidate(
                            this.state.additionalApplicant3Dob
                          )}
                          value={this.state.additionalApplicant3Dob}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Gender"
                          type="optional"
                          options={["Male", "Female"]}
                          optionValue={["male", "female"]}
                          target="additionalApplicant3Gender"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your gender"
                          validate={this.checkValidate(
                            this.state.additionalApplicant3Gender
                          )}
                          value={this.state.additionalApplicant3Gender}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="S.I.N"
                          type="text"
                          target="additionalApplicant3SIN"
                          min="1"
                          onChange={this.onchange}
                          important="true"
                          errorMessage={
                            this.checkValidate(
                              this.state.additionalApplicant3SIN
                            )
                              ? "the Social Security Number"
                              : "the valid Social Security Number"
                          }
                          validate={this.checkSIN(
                            this.state.additionalApplicant3SIN
                          )}
                          value={this.state.additionalApplicant3SIN}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {this.state.additionalApplicantCount < 3 && (
                  <div className="row inputContainer pt-0 mt-0 pb-2">
                    <div className="col  text-right pt-0 mt-3">
                      <button
                        className=" buttonPri"
                        name="addApplication2Button"
                        id="addApplication2Button"
                        onClick={() => this.addAdditionalApplicant()}
                      >
                        Add Occupant
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}

            <br></br>
            <hr></hr>
            <div className="cardApplication pt-3 pb-4">
              <p className="subTitle stepTitle pt-3 textPri  ">Additional</p>
              <div className="row inputContainer d-flex justify-content-left pb-4  mb-0 mt-2 pt-5">
                <div className="col-3 alignInput ">
                  <ApplicationInput
                    name="Move In Date"
                    type="date"
                    target="moveInDate"
                    min={this.today()}
                    onChange={this.onChangeDate}
                    important="true"
                    errorMessage="the move in date"
                    validate={this.checkValidate(this.state.moveInDate)}
                    value={this.state.moveInDate}
                  />
                </div>
                <div className="col-3 alignInput ">
                  <ApplicationInput
                    name="Term(Months)"
                    type="number"
                    target="term"
                    min={1}
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your term of occupancy"
                    validate={this.checkValidate(this.state.term)}
                    value={this.state.term}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Emergency Contact Name"
                    type="text"
                    place="Emergency Contact Name"
                    target="emergencyContactName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of emergency contact"
                    validate={this.checkValidate(
                      this.state.emergencyContactName
                    )}
                    value={this.state.emergencyContactName}
                  />
                </div>
                <div className="col-3 alignInput">
                  <ApplicationInput
                    name="Emergency Contact Number"
                    type="text"
                    place="Contact No"
                    target="emergencyContactPhone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the cell number of emergency contact"
                    validate={this.checkMobile(
                      this.state.emergencyContactPhone
                    )}
                    value={this.state.emergencyContactPhone}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Emergency Contact Fax"
                    type="text"
                    place="Contact No"
                    target="emergencyContactFax"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage="your Emergency Contact Fax"
                    // validate={this.checkValidate(this.state.emergencyContactFax)}
                    value={this.state.emergencyContactFax}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Emergency Relation Type"
                    type="text"
                    place="Relation Type"
                    target="emergencyContactRelation"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="Please define your relationship with emergency contact"
                    errorStart="false"
                    validate={this.checkValidate(
                      this.state.emergencyContactRelation
                    )}
                    value={this.state.emergencyContactRelation}
                  />
                </div>
              </div>
            </div>
            <br></br>
            <hr></hr>
            <div className="cardApplication pt-3 pb-4">
              <div className="row inputContainer pt-0 pb-0">
                <div className="col ">
                  <p className="span7">
                    I agree to pay the supplier directly, or to pay the Landlord
                    as a reimbursement of charges paid on my behalf for each of
                    the following services applicable to the rented premises:
                  </p>
                </div>
              </div>
              <div className="row inputContainer pt-0">
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Hydro"
                    type="optionYN"
                    target="hydro"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.hydro)}
                    value={this.state.hydro}
                  />
                </div>
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Gas"
                    type="optionYN"
                    target="gas"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.gas)}
                    value={this.state.gas}
                  />
                </div>
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Heat"
                    type="optionYN"
                    target="heat"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.heat)}
                    value={this.state.heat}
                  />
                </div>
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Hot Water Heater"
                    type="optionYN"
                    target="hotWaterHeater"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.hotWaterHeater)}
                    value={this.state.hotWaterHeater}
                  />
                </div>
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Cable/Satellite TV"
                    type="optionYN"
                    target="cableTv"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.cableTv)}
                    value={this.state.cableTv}
                  />
                </div>
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Water"
                    type="optionYN"
                    target="water"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.water)}
                    value={this.state.water}
                  />
                </div>
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Hot Water Tank"
                    type="optionYN"
                    target="hotWaterTank"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.hotWaterTank)}
                    value={this.state.hotWaterTank}
                  />
                </div>
                <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Internet"
                    type="optionYN"
                    target="internet"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.internet)}
                    value={this.state.internet}
                  />
                </div>
                {/* <div className="col-4 alignInput ">
                  <ApplicationInput
                    name="Other[specify]"
                    type="optionYN"
                    target="other"
                    onChange={this.onchangeCheck}
                    validate={this.checkValidate(this.state.other)}
                    value={this.state.other}
                  />
                </div> */}
              </div>
            </div>
          </>
        )}

        {this.state.nowTab === 2 && (
          <>
            <div className="cardApplication pt-2">
              <p className="subTitle stepTitle pt-3 textDark ">
                {this.state.applicantFirstName}'s Present Address
              </p>
              <div className="row inputContainer   pt-5">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Address"
                    type="text"
                    target="presentAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your present address"
                    validate={this.checkValidate(this.state.presentAddress)}
                    value={this.state.presentAddress}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="City"
                    type="text"
                    target="presentCity"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your present city"
                    validate={this.checkValidate(this.state.presentCity)}
                    value={this.state.presentCity}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="State"
                    type="text"
                    target="presentState"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your present state"
                    validate={this.checkValidate(this.state.presentState)}
                    value={this.state.presentState}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Zip"
                    type="text"
                    target="presentZip"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your present zip code"
                    validate={this.checkValidate(this.state.presentZip)}
                    value={this.state.presentZip}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="From"
                    type="date"
                    target="presentFrom"
                    onChange={this.onChangeDate}
                    important="true"
                    min={"1910-01-01"}
                    max={this.state.presentTo}
                    errorMessage="Please select the from date of the present address."
                    errorStart="false"
                    validate={this.checkValidate(this.state.presentFrom)}
                    value={this.state.presentFrom}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="To"
                    type="date"
                    target="presentTo"
                    onChange={this.onChangeDate}
                    important="true"
                    min={this.state.presentFrom}
                    max={this.today()}
                    errorMessage="Please select the to date of the present address."
                    errorStart="false"
                    validate={this.checkValidate(this.state.presentTo)}
                    value={this.state.presentTo}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Home Phone Number"
                    type="text"
                    target="presentHomePhone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.presentHomePhone)
                        ? "the phone number."
                        : "the valid phone number."
                    }
                    validate={this.checkMobile(this.state.presentHomePhone)}
                    value={this.state.presentHomePhone}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Home Fax Number"
                    type="text"
                    target="presentHomeFax"
                    onChange={this.onchange}
                    validate={this.checkValidate(this.state.presentHomeFax)}
                    value={this.state.presentHomeFax}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Reason for leaving"
                    type="textarea"
                    target="presentReasonLeaving"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="Please specify the reason for leaving"
                    errorStart="false"
                    validate={this.checkValidate(
                      this.state.presentReasonLeaving
                    )}
                    value={this.state.presentReasonLeaving}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Property Type"
                    type="optional"
                    options={["Own", "Rent"]}
                    optionValue={["Own", "Rent"]}
                    target="presentPropertyType"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the type of property"
                    validate={this.checkValidate(
                      this.state.presentPropertyType
                    )}
                    value={this.state.presentPropertyType}
                  />
                </div>
                {this.state.presentPropertyType === "Rent" && (
                  <>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Name of Present Landlord"
                        type="text"
                        target="presentLandlord"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the name of present Landlord"
                        validate={this.checkValidate(
                          this.state.presentLandlord
                        )}
                        value={this.state.presentLandlord}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Landlord Cell Number"
                        type="text"
                        target="presentLandlordPhone"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the cell number of present Landlord"
                        validate={this.checkMobile(
                          this.state.presentLandlordPhone
                        )}
                        value={this.state.presentLandlordPhone}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Landlord Id Proof"
                        type="text"
                        target="presentLandlordIDProof"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(this.state.presentLandlordIDProof)
                            ? "the id proof of present Landlord."
                            : "the valid id proof of present Landlord"
                        }
                        validate={this.checkSIN(
                          this.state.presentLandlordIDProof
                        )}
                        value={this.state.presentLandlordIDProof}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Monthly Rent"
                        type="number"
                        target="presentMonthlyRent"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the monthly rent"
                        validate={this.checkValidate(
                          this.state.presentMonthlyRent
                        )}
                        value={this.state.presentMonthlyRent}
                        iconL={"fa-usd"}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
              <br></br>
              <hr className="line "></hr>
            </div>
            <p className="subTitle stepTitle pt-2 ">
              If present occupancy less than 3 years, provide information of
              previous resident:
            </p>
            <br></br>
            <div className="cardApplication pt-3  pb-4">
              <p
                className="subTitle stepTitle pt-3 textDark mb-5"
                style={{ zIndex: "800" }}
              >
                {this.state.applicantFirstName}'s Previous Rental Information
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div
                  className="pull-right preEmployeeHide1"
                  style={{ zIndex: "900" }}
                >
                  <ApplicationInput
                    name=""
                    type="checkedBox"
                    target="applicantPreviousInfo"
                    onChange={this.onchangeCheck}
                    value={this.state.applicantPreviousInfo}
                  />
                </div>
              </p>
              <br></br>
              <br></br>
              <div
                className="pull-right subTitle stepTitle  pull-left preEmployeeHide2 mt-3"
                style={{ zIndex: "900" }}
              >
                <ApplicationInput
                  name=""
                  type="checkedBox"
                  target="applicantPreviousInfo"
                  onChange={this.onchangeCheck}
                  value={this.state.applicantPreviousInfo}
                />
              </div>
              {this.state.applicantPreviousInfo && (
                <div className="row inputContainer  pt-0 ">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Address"
                      type="text"
                      target="previousAddress"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the previous address"
                      validate={this.checkValidate(this.state.previousAddress)}
                      value={this.state.previousAddress}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="City"
                      type="text"
                      target="previousCity"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the previous city"
                      validate={this.checkValidate(this.state.previousCity)}
                      value={this.state.previousCity}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="State"
                      type="text"
                      target="previousState"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the previous state"
                      validate={this.checkValidate(this.state.previousState)}
                      value={this.state.previousState}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Zip"
                      type="text"
                      target="previousZip"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the previous zip code"
                      validate={this.checkValidate(this.state.previousZip)}
                      value={this.state.previousZip}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="From"
                      type="date"
                      target="previousFrom"
                      onChange={this.onChangeDate}
                      important="true"
                      min={"1910-01-01"}
                      max={
                        this.state.previousTo === ""
                          ? this.state.presentFrom
                          : this.state.previousTo
                      }
                      errorMessage="Please select the from date of the previous address."
                      errorStart="false"
                      validate={this.checkValidate(this.state.previousFrom)}
                      value={this.state.previousFrom}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="To"
                      type="date"
                      target="previousTo"
                      onChange={this.onChangeDate}
                      important="true"
                      min={this.state.previousFrom}
                      max={this.state.presentFrom}
                      errorMessage="Please select the to date of the previous address."
                      errorStart="false"
                      validate={this.checkValidate(this.state.previousTo)}
                      value={this.state.previousTo}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="Reason for leaving"
                      type="textarea"
                      target="previousReasonLeaving"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="Please specify the reason for leaving."
                      errorStart="false"
                      validate={this.checkValidate(
                        this.state.previousReasonLeaving
                      )}
                      value={this.state.previousReasonLeaving}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Property Type"
                      type="optional"
                      options={["Own", "Rent"]}
                      optionValue={["Own", "Rent"]}
                      target="previousPropertyType"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the type of property."
                      validate={this.checkValidate(
                        this.state.previousPropertyType
                      )}
                      value={this.state.previousPropertyType}
                    />
                  </div>
                  {this.state.previousPropertyType === "Rent" &&
                    this.state.applicantPreviousInfo && (
                      <>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Name of previous Landlord"
                            type="text"
                            target="previousLandlord"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the name of previous landlord."
                            validate={this.checkValidate(
                              this.state.previousLandlord
                            )}
                            value={this.state.previousLandlord}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Landlord Cell Number"
                            type="text"
                            target="previousLandlordPhone"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the cell number of previous Landlord."
                            validate={this.checkValidate(
                              this.state.previousLandlordPhone
                            )}
                            value={this.state.previousLandlordPhone}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Monthly Rent"
                            type="number"
                            target="previousMonthlyRent"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the monthly rent"
                            validate={this.checkValidate(
                              this.state.previousMonthlyRent
                            )}
                            value={this.state.previousMonthlyRent}
                            iconL={"fa-usd"}
                          />
                        </div>
                      </>
                    )}
                </div>
              )}
            </div>
            {this.state.applicant2 && (
              <>
                <hr className="line "></hr>
                <div className="cardApplication pt-2">
                  <p className="subTitle stepTitle pt-3 textDark ">
                    {this.state.applicant2FirstName}'s Present Address
                  </p>
                  <div className="row inputContainer  pt-5">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="applicant2PresentAddress"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present address"
                        validate={this.checkValidate(
                          this.state.applicant2PresentAddress
                        )}
                        value={this.state.applicant2PresentAddress}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="City"
                        type="text"
                        target="applicant2PresentCity"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present city"
                        validate={this.checkValidate(
                          this.state.applicant2PresentCity
                        )}
                        value={this.state.applicant2PresentCity}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="State"
                        type="text"
                        target="applicant2PresentState"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present state"
                        validate={this.checkValidate(
                          this.state.applicant2PresentState
                        )}
                        value={this.state.applicant2PresentState}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="text"
                        target="applicant2PresentZip"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present zip code"
                        validate={this.checkValidate(
                          this.state.applicant2PresentZip
                        )}
                        value={this.state.applicant2PresentZip}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="From"
                        type="date"
                        target="applicant2PresentFrom"
                        onChange={this.onChangeDate}
                        important="true"
                        min={"1910-01-01"}
                        max={this.state.applicant2PresentTo}
                        errorMessage="Please select the from date of the present address"
                        errorStart="false"
                        validate={this.checkValidate(
                          this.state.applicant2PresentFrom
                        )}
                        value={this.state.applicant2PresentFrom}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="To"
                        type="date"
                        target="applicant2PresentTo"
                        onChange={this.onChangeDate}
                        important="true"
                        min={this.state.applicant2PresentFrom}
                        max={this.today()}
                        errorMessage="Please select the to date of the present address"
                        errorStart="false"
                        validate={this.checkValidate(
                          this.state.applicant2PresentTo
                        )}
                        value={this.state.applicant2PresentTo}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Home Phone Number"
                        type="text"
                        target="applicant2PresentHomePhone"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="Please select the to date of the present address"
                        errorStart="false"
                        validate={this.checkMobile(
                          this.state.applicant2PresentHomePhone
                        )}
                        value={this.state.applicant2PresentHomePhone}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Home Fax Number"
                        type="text"
                        target="applicant2PresentHomeFax"
                        onChange={this.onchange}
                        validate={this.checkValidate(
                          this.state.applicant2PresentHomeFax
                        )}
                        value={this.state.applicant2PresentHomeFax}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Reason for leaving"
                        type="textarea"
                        target="applicant2PresentReasonLeaving"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="Please specify the reason for leaving."
                        errorStart="false"
                        validate={this.checkValidate(
                          this.state.applicant2PresentReasonLeaving
                        )}
                        value={this.state.applicant2PresentReasonLeaving}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Property Type"
                        type="optional"
                        options={["Own", "Rent"]}
                        optionValue={["Own", "Rent"]}
                        target="applicant2PresentPropertyType"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the type of property"
                        validate={this.checkValidate(
                          this.state.applicant2PresentPropertyType
                        )}
                        value={this.state.applicant2PresentPropertyType}
                      />
                    </div>
                    {this.state.applicant2PresentPropertyType === "Rent" && (
                      <>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Name of Present Landlord"
                            type="text"
                            target="applicant2PresentLandlord"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="from the name of present Landlord"
                            validate={this.checkValidate(
                              this.state.applicant2PresentLandlord
                            )}
                            value={this.state.applicant2PresentLandlord}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Landlord Cell Number"
                            type="text"
                            target="applicant2PresentLandlordPhone"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the cell number of present Landlord you are living."
                            validate={this.checkMobile(
                              this.state.applicant2PresentLandlordPhone
                            )}
                            value={this.state.applicant2PresentLandlordPhone}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Landlord Id Proof"
                            type="text"
                            target="applicant2PresentLandlordIDProof"
                            onChange={this.onchange}
                            important="true"
                            errorMessage={
                              this.checkValidate(
                                this.state.applicant2PresentLandlordIDProof
                              )
                                ? "the id proof of present Landlord."
                                : "the valid id proof of present Landlord."
                            }
                            validate={this.checkSIN(
                              this.state.applicant2PresentLandlordIDProof
                            )}
                            value={this.state.applicant2PresentLandlordIDProof}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Monthly Rent"
                            type="number"
                            target="applicant2PresentMonthlyRent"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the monthly rent"
                            validate={this.checkValidate(
                              this.state.applicant2PresentMonthlyRent
                            )}
                            value={this.state.applicant2PresentMonthlyRent}
                            iconL={"fa-usd"}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
                  <hr className="line "></hr>
                </div>
                <p className="subTitle stepTitle pt-2 ">
                  If present occupancy less than 3 years, provide information of
                  previous resident:
                </p>
                <br></br>
                <div className="cardApplication pt-3 pb-4">
                  <p className="subTitle stepTitle pt-2 textDark mb-5">
                    {this.state.applicant2FirstName}'s Previous Rental
                    Information &nbsp;&nbsp;&nbsp;&nbsp;
                    <div
                      className="pull-right preEmployeeHide1"
                      style={{ zIndex: "900" }}
                    >
                      <ApplicationInput
                        name=""
                        type="checkedBox"
                        target="applicant2PreviousInfo"
                        onChange={this.onchangeCheck}
                        value={this.state.applicant2PreviousInfo}
                      />
                    </div>
                  </p>
                  <br></br>
                  <br></br>
                  <div
                    className="pull-right subTitle stepTitle  pull-left preEmployeeHide2"
                    style={{ zIndex: "900" }}
                  >
                    <ApplicationInput
                      name=""
                      type="checkedBox"
                      target="applicant2PreviousInfo"
                      onChange={this.onchangeCheck}
                      value={this.state.applicant2PreviousInfo}
                    />
                  </div>
                  {this.state.applicant2PreviousInfo && (
                    <div className="row inputContainer  pt-5">
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Address"
                          type="text"
                          target="applicant2PreviousAddress"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the previous address"
                          validate={this.checkValidate(
                            this.state.applicant2PreviousAddress
                          )}
                          value={this.state.applicant2PreviousAddress}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="City"
                          type="text"
                          target="applicant2PreviousCity"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the previous city"
                          validate={this.checkValidate(
                            this.state.applicant2PreviousCity
                          )}
                          value={this.state.applicant2PreviousCity}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="State"
                          type="text"
                          target="applicant2PreviousState"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the previous state"
                          validate={this.checkValidate(
                            this.state.applicant2PreviousState
                          )}
                          value={this.state.applicant2PreviousState}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Zip"
                          type="text"
                          target="applicant2PreviousZip"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your previous zip code"
                          validate={this.checkValidate(
                            this.state.applicant2PreviousZip
                          )}
                          value={this.state.applicant2PreviousZip}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="From"
                          type="date"
                          target="applicant2PreviousFrom"
                          onChange={this.onChangeDate}
                          important="true"
                          min={"1910-01-01"}
                          max={
                            this.state.applicant2PreviousTo === ""
                              ? this.state.applicant2PresentFrom
                              : this.state.applicant2PreviousTo
                          }
                          errorMessage="Please select the from date of the previous address"
                          errorStart="false"
                          validate={this.checkValidate(
                            this.state.applicant2PreviousFrom
                          )}
                          value={this.state.applicant2PreviousFrom}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="To"
                          type="date"
                          target="applicant2PreviousTo"
                          onChange={this.onChangeDate}
                          min={this.state.applicant2PreviousFrom}
                          max={this.state.applicant2PresentFrom}
                          important="true"
                          errorMessage="Please select the to date of the previous address"
                          errorStart="false"
                          validate={this.checkValidate(
                            this.state.applicant2PreviousTo
                          )}
                          value={this.state.applicant2PreviousTo}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Reason for leaving"
                          type="textarea"
                          target="applicant2PreviousReasonLeaving"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="Please specify the reason for leaving."
                          errorStart="false"
                          validate={this.checkValidate(
                            this.state.applicant2PreviousReasonLeaving
                          )}
                          value={this.state.applicant2PreviousReasonLeaving}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Property Type"
                          type="optional"
                          options={["Own", "Rent"]}
                          optionValue={["Own", "Rent"]}
                          target="applicant2PreviousPropertyType"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the type of property."
                          validate={this.checkValidate(
                            this.state.applicant2PreviousPropertyType
                          )}
                          value={this.state.applicant2PreviousPropertyType}
                        />
                      </div>
                      {this.state.applicant2PreviousPropertyType === "Rent" &&
                        this.state.applicant2PreviousInfo && (
                          <>
                            <div className="col alignInput">
                              <ApplicationInput
                                name="Name of previous Landlord"
                                type="text"
                                target="applicant2PreviousLandlord"
                                onChange={this.onchange}
                                important="true"
                                errorMessage="from the name of previous Landlord."
                                validate={this.checkValidate(
                                  this.state.applicant2PreviousLandlord
                                )}
                                value={this.state.applicant2PreviousLandlord}
                              />
                            </div>
                            <div className="col alignInput">
                              <ApplicationInput
                                name="Landlord Cell Number"
                                type="text"
                                target="applicant2PreviousLandlordPhone"
                                onChange={this.onchange}
                                important="true"
                                errorMessage="the cell number of previous Landlord."
                                validate={this.checkValidate(
                                  this.state.applicant2PreviousLandlordPhone
                                )}
                                value={
                                  this.state.applicant2PreviousLandlordPhone
                                }
                              />
                            </div>
                            <div className="col alignInput">
                              <ApplicationInput
                                name="Monthly Rent"
                                type="number"
                                target="applicant2PreviousMonthlyRent"
                                onChange={this.onchange}
                                important="true"
                                errorMessage="the monthly rent"
                                validate={this.checkValidate(
                                  this.state.applicant2PreviousMonthlyRent
                                )}
                                value={this.state.applicant2PreviousMonthlyRent}
                                iconL={"fa-usd"}
                              />
                            </div>
                          </>
                        )}
                    </div>
                  )}
                </div>
              </>
            )}

            {this.state.applicant3 && (
              <>
                <hr className="line "></hr>
                <div className="cardApplication pt-2">
                  <p className="subTitle stepTitle pt-3 textDark ">
                    {this.state.applicant3FirstName}'s Present Address
                  </p>
                  <div className="row inputContainer  pt-5">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="applicant3PresentAddress"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present address"
                        validate={this.checkValidate(
                          this.state.applicant3PresentAddress
                        )}
                        value={this.state.applicant3PresentAddress}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="City"
                        type="text"
                        target="applicant3PresentCity"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present city"
                        validate={this.checkValidate(
                          this.state.applicant3PresentCity
                        )}
                        value={this.state.applicant3PresentCity}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="State"
                        type="text"
                        target="applicant3PresentState"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present state"
                        validate={this.checkValidate(
                          this.state.applicant3PresentState
                        )}
                        value={this.state.applicant3PresentState}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="text"
                        target="applicant3PresentZip"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the present zip code"
                        validate={this.checkValidate(
                          this.state.applicant3PresentZip
                        )}
                        value={this.state.applicant3PresentZip}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="From"
                        type="date"
                        target="applicant3PresentFrom"
                        onChange={this.onChangeDate}
                        important="true"
                        min={"1910-01-01"}
                        max={this.state.applicant3PresentTo}
                        errorMessage="Please select the from date of the present address"
                        errorStart="false"
                        validate={this.checkValidate(
                          this.state.applicant3PresentFrom
                        )}
                        value={this.state.applicant3PresentFrom}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="To"
                        type="date"
                        target="applicant3PresentTo"
                        onChange={this.onChangeDate}
                        min={this.state.applicant3PresentFrom}
                        max={this.today()}
                        important="true"
                        errorMessage="Please select the to date of the present address"
                        errorStart="false"
                        validate={this.checkValidate(
                          this.state.applicant3PresentTo
                        )}
                        value={this.state.applicant3PresentTo}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Home Phone Number"
                        type="text"
                        target="applicant3PresentHomePhone"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(
                            this.state.applicant3PresentHomePhone
                          )
                            ? "the phone number."
                            : "the valid phone number."
                        }
                        validate={this.checkMobile(
                          this.state.applicant3PresentHomePhone
                        )}
                        value={this.state.applicant3PresentHomePhone}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Home Fax Number"
                        type="text"
                        target="applicant3PresentHomeFax"
                        onChange={this.onchange}
                        validate={this.checkValidate(
                          this.state.applicant3PresentHomeFax
                        )}
                        value={this.state.applicant3PresentHomeFax}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Reason for leaving"
                        type="textarea"
                        target="applicant3PresentReasonLeaving"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="Please specify the reason for leaving"
                        errorStart="false"
                        validate={this.checkValidate(
                          this.state.applicant3PresentReasonLeaving
                        )}
                        value={this.state.applicant3PresentReasonLeaving}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Property Type"
                        type="optional"
                        options={["Own", "Rent"]}
                        optionValue={["Own", "Rent"]}
                        target="applicant3PresentPropertyType"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the type of property"
                        validate={this.checkValidate(
                          this.state.applicant3PresentPropertyType
                        )}
                        value={this.state.applicant3PresentPropertyType}
                      />
                    </div>
                    {this.state.applicant3PresentPropertyType === "Rent" && (
                      <>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Name of Present Landlord"
                            type="text"
                            target="applicant3PresentLandlord"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="from the name of present Landlord"
                            validate={this.checkValidate(
                              this.state.applicant3PresentLandlord
                            )}
                            value={this.state.applicant3PresentLandlord}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Landlord Cell Number"
                            type="text"
                            target="applicant3PresentLandlordPhone"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the cell number of present Landlord."
                            validate={this.checkMobile(
                              this.state.applicant3PresentLandlordPhone
                            )}
                            value={this.state.applicant3PresentLandlordPhone}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Landlord Id Proof"
                            type="text"
                            target="applicant3PresentLandlordIDProof"
                            onChange={this.onchange}
                            important="true"
                            errorMessage={
                              this.checkValidate(
                                this.state.applicant3PresentLandlordIDProof
                              )
                                ? "the id proof of present Landlord."
                                : "the valid id proof of present Landlord."
                            }
                            validate={this.checkSIN(
                              this.state.applicant3PresentLandlordIDProof
                            )}
                            value={this.state.applicant3PresentLandlordIDProof}
                          />
                        </div>
                        <div className="col alignInput">
                          <ApplicationInput
                            name="Monthly Rent"
                            type="number"
                            target="applicant3PresentMonthlyRent"
                            onChange={this.onchange}
                            important="true"
                            errorMessage="the monthly rent"
                            validate={this.checkValidate(
                              this.state.applicant3PresentMonthlyRent
                            )}
                            value={this.state.applicant3PresentMonthlyRent}
                            iconL={"fa-usd"}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
                  <hr className="line "></hr>
                </div>
                <p className="subTitle stepTitle pt-2 ">
                  If present occupancy less than 3 years, provide information of
                  previous resident:
                </p>
                <br></br>
                <div className="cardApplication pt-3 pb-4">
                  <p className="subTitle stepTitle pt-2 textDark">
                    {this.state.applicant3FirstName}'s Previous Rental
                    Information &nbsp;&nbsp;&nbsp;&nbsp;
                    <div
                      className="pull-right preEmployeeHide1"
                      style={{ zIndex: "900" }}
                    >
                      <ApplicationInput
                        name=""
                        type="checkedBox"
                        target="applicant3PreviousInfo"
                        onChange={this.onchangeCheck}
                        value={this.state.applicant3PreviousInfo}
                      />
                    </div>
                  </p>
                  <br></br>
                  <br></br>
                  <div
                    className="pull-right subTitle stepTitle  pull-left preEmployeeHide2"
                    style={{ zIndex: "900" }}
                  >
                    <ApplicationInput
                      name=""
                      type="checkedBox"
                      target="applicant3PreviousInfo"
                      onChange={this.onchangeCheck}
                      value={this.state.applicant3PreviousInfo}
                    />
                  </div>
                  {this.state.applicant3PreviousInfo && (
                    <div className="row inputContainer  pt-5">
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Address"
                          type="text"
                          target="applicant3PreviousAddress"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the previous address"
                          validate={this.checkValidate(
                            this.state.applicant3PreviousAddress
                          )}
                          value={this.state.applicant3PreviousAddress}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="City"
                          type="text"
                          target="applicant3PreviousCity"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the previous city"
                          validate={this.checkValidate(
                            this.state.applicant3PreviousCity
                          )}
                          value={this.state.applicant3PreviousCity}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="State"
                          type="text"
                          target="applicant3PreviousState"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the previous state"
                          validate={this.checkValidate(
                            this.state.applicant3PreviousState
                          )}
                          value={this.state.applicant3PreviousState}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Zip"
                          type="text"
                          target="applicant3PreviousZip"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the previous zip code"
                          validate={this.checkValidate(
                            this.state.applicant3PreviousZip
                          )}
                          value={this.state.applicant3PreviousZip}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="From"
                          type="date"
                          target="applicant3PreviousFrom"
                          onChange={this.onChangeDate}
                          min={"1910-01-01"}
                          max={
                            this.state.applicant3PreviousTo === ""
                              ? this.state.applicant3PresentFrom
                              : this.state.applicant3PreviousTo
                          }
                          important="true"
                          errorMessage="Please select the from date of the previous address"
                          errorStart="false"
                          validate={this.checkValidate(
                            this.state.applicant3PreviousFrom
                          )}
                          value={this.state.applicant3PreviousFrom}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="To"
                          type="date"
                          target="applicant3PreviousTo"
                          onChange={this.onChangeDate}
                          min={this.state.applicant3PreviousFrom}
                          max={this.state.applicant3PresentFrom}
                          important="true"
                          errorMessage="Please select the to date of the previous address"
                          errorStart="false"
                          validate={this.checkValidate(
                            this.state.applicant3PreviousTo
                          )}
                          value={this.state.applicant3PreviousTo}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Reason for leaving"
                          type="textarea"
                          target="applicant3PreviousReasonLeaving"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="Please specify the reason for leaving."
                          errorStart="false"
                          validate={this.checkValidate(
                            this.state.applicant3PreviousReasonLeaving
                          )}
                          value={this.state.applicant3PreviousReasonLeaving}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Property Type"
                          type="optional"
                          options={["Own", "Rent"]}
                          optionValue={["Own", "Rent"]}
                          target="applicant3PreviousPropertyType"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the type of property."
                          validate={this.checkValidate(
                            this.state.applicant3PreviousPropertyType
                          )}
                          value={this.state.applicant3PreviousPropertyType}
                        />
                      </div>
                      {this.state.applicant3PreviousPropertyType === "Rent" &&
                        this.state.applicant3PreviousInfo && (
                          <>
                            <div className="col alignInput">
                              <ApplicationInput
                                name="Name of previous Landlord"
                                type="text"
                                target="applicant3PreviousLandlord"
                                onChange={this.onchange}
                                important="true"
                                errorMessage="the name of previous Landlord"
                                validate={this.checkValidate(
                                  this.state.applicant3PreviousLandlord
                                )}
                                value={this.state.applicant3PreviousLandlord}
                              />
                            </div>
                            <div className="col alignInput">
                              <ApplicationInput
                                name="Landlord Cell Number"
                                type="text"
                                target="applicant3PreviousLandlordPhone"
                                onChange={this.onchange}
                                important="true"
                                errorMessage="the cell number of previous Landlord."
                                validate={this.checkValidate(
                                  this.state.applicant3PreviousLandlordPhone
                                )}
                                value={
                                  this.state.applicant3PreviousLandlordPhone
                                }
                              />
                            </div>

                            <div className="col alignInput">
                              <ApplicationInput
                                name="Monthly Rent"
                                type="number"
                                target="applicant3PreviousMonthlyRent"
                                onChange={this.onchange}
                                important="true"
                                errorMessage="the monthly rent"
                                validate={this.checkValidate(
                                  this.state.applicant3PreviousMonthlyRent
                                )}
                                value={this.state.applicant3PreviousMonthlyRent}
                                iconL={"fa-usd"}
                              />
                            </div>
                          </>
                        )}
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {this.state.nowTab === 3 && (
          <>
            <div className="cardApplication">
              <p className="subTitle stepTitle pt-3 textDark">
                {this.state.applicantFirstName} Employment Details
              </p>
              <div className="row  pt-5 inputContainer">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Company Name"
                    type="text"
                    target="employmentCompany"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of your current company"
                    validate={this.checkValidate(this.state.employmentCompany)}
                    value={this.state.employmentCompany}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Company Phone Number"
                    type="text"
                    target="employmentPhone"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.employmentPhone)
                        ? "the phone number of your current company."
                        : "the valid phone number of your current company."
                    }
                    validate={this.checkMobile(this.state.employmentPhone)}
                    value={this.state.employmentPhone}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Address"
                    type="text"
                    target="employmentAddress"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the address of your current company."
                    validate={this.checkValidate(this.state.employmentAddress)}
                    value={this.state.employmentAddress}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="City"
                    type="text"
                    target="employmentCity"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the city of your current company."
                    validate={this.checkValidate(this.state.employmentCity)}
                    value={this.state.employmentCity}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="State"
                    type="text"
                    target="employmentState"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the state of your current company."
                    validate={this.checkValidate(this.state.employmentState)}
                    value={this.state.employmentState}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Zip"
                    type="text"
                    target="employmentZip"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the zip code of your current company."
                    validate={this.checkValidate(this.state.employmentZip)}
                    value={this.state.employmentZip}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Annual Income"
                    type="number"
                    target="employmentAnnualIncome"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your salary at the current company.(Annually)"
                    validate={this.checkValidate(
                      this.state.employmentAnnualIncome
                    )}
                    value={this.state.employmentAnnualIncome}
                    iconL={"fa-usd"}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Position"
                    type="text"
                    target="employmentPosition"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="your position at the current company."
                    validate={this.checkValidate(this.state.employmentPosition)}
                    value={this.state.employmentPosition}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed From"
                    type="date"
                    target="employmentFrom"
                    onChange={this.onChangeDate}
                    min={"1910-01-01"}
                    max={
                      this.state.employmentUntil === ""
                        ? this.today()
                        : this.state.employmentUntil
                    }
                    important="true"
                    errorMessage="the start date of your work."
                    validate={this.checkValidate(this.state.employmentFrom)}
                    value={this.state.employmentFrom}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Employed Until"
                    type="date"
                    target="employmentUntil"
                    onChange={this.onChangeDate}
                    min={this.state.employmentFrom}
                    max={this.today()}
                    important="true"
                    errorMessage="the end date of your work."
                    validate={this.checkValidate(this.state.employmentUntil)}
                    value={this.state.employmentUntil}
                  />
                </div>
              </div>
            </div>
            <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
              <hr className="line "></hr>
            </div>
            <div
              className={
                !this.state.employment1 ? "cardApplication pt-3 mt-4" : ""
              }
            >
              <p className="subTitle stepTitle pt-2 ">
                If employment less than 3 years, provide information of previous
                employers and occupation to total 3 years:
              </p>
              <br></br>

              {this.state.employment1 && (
                <div className="cardApplication pt-2">
                  <div className="row pt-2">
                    <div className="col ">
                      <p className="subTitle stepTitle pt-1 textDark ">
                        {this.state.applicantFirstName} Previous Employment
                        Information
                      </p>
                      {this.state.employmentCount === 2 ? (
                        <i
                          className="fa fa-window-close pull-right pr-4 pt-3 pointer"
                          onClick={() => this.removeEmployment()}
                        ></i>
                      ) : (
                        <br></br>
                      )}
                    </div>
                  </div>
                  <div className="row inputContainer pt-3">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Company Name"
                        type="text"
                        target="preEmploymentCompany"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the name of your current company."
                        validate={this.checkValidate(
                          this.state.preEmploymentCompany
                        )}
                        value={this.state.preEmploymentCompany}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="preEmploymentAddress"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the address of your current company."
                        validate={this.checkValidate(
                          this.state.preEmploymentAddress
                        )}
                        value={this.state.preEmploymentAddress}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="City"
                        type="text"
                        target="preEmploymentCity"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the city of your current company."
                        validate={this.checkValidate(
                          this.state.preEmploymentCity
                        )}
                        value={this.state.preEmploymentCity}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="State"
                        type="text"
                        target="preEmploymentState"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the state of your current company."
                        validate={this.checkValidate(
                          this.state.preEmploymentState
                        )}
                        value={this.state.preEmploymentState}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="text"
                        target="preEmploymentZip"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the zip code of your current company."
                        validate={this.checkValidate(
                          this.state.preEmploymentZip
                        )}
                        value={this.state.preEmploymentZip}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Position"
                        type="text"
                        target="preEmploymentPosition"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your position at the current company."
                        validate={this.checkValidate(
                          this.state.preEmploymentPosition
                        )}
                        value={this.state.preEmploymentPosition}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed From"
                        type="date"
                        target="preEmploymentFrom"
                        onChange={this.onChangeDate}
                        min={"1910-01-01"}
                        max={
                          this.state.preEmploymentUntil === ""
                            ? this.state.employmentFrom
                            : this.state.preEmploymentUntil
                        }
                        important="true"
                        errorMessage="the start date of your work."
                        validate={this.checkValidate(
                          this.state.preEmploymentFrom
                        )}
                        value={this.state.preEmploymentFrom}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed Until"
                        type="date"
                        target="preEmploymentUntil"
                        onChange={this.onChangeDate}
                        min={this.state.preEmploymentFrom}
                        max={this.state.employmentFrom}
                        important="true"
                        errorMessage="the end date of your work."
                        validate={this.checkValidate(
                          this.state.preEmploymentUntil
                        )}
                        value={this.state.preEmploymentUntil}
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.employment2 && (
                <div className="cardApplication pt-2">
                  <div className="row pt-2">
                    <div className="col ">
                      <p className="subTitle stepTitle pt-1 textDark">
                        {this.state.applicantFirstName} Previous Employment - 2
                        Information
                      </p>
                      {this.state.employmentCount === 3 ? (
                        <i
                          className="fa fa-window-close pull-right pr-4 pt-3 pointer"
                          onClick={() => this.removeEmployment()}
                        ></i>
                      ) : (
                        <br></br>
                      )}
                    </div>
                  </div>
                  <div className="row inputContainer pt-3">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Company Name"
                        type="text"
                        target="preEmployment2Company"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the name of your current company."
                        validate={this.checkValidate(
                          this.state.preEmployment2Company
                        )}
                        value={this.state.preEmployment2Company}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="preEmployment2Address"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the address of your current company."
                        validate={this.checkValidate(
                          this.state.preEmployment2Address
                        )}
                        value={this.state.preEmployment2Address}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="City"
                        type="text"
                        target="preEmployment2City"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the city of your current company."
                        validate={this.checkValidate(
                          this.state.preEmployment2City
                        )}
                        value={this.state.preEmployment2City}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="State"
                        type="text"
                        target="preEmployment2State"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the state of your current company."
                        validate={this.checkValidate(
                          this.state.preEmployment2State
                        )}
                        value={this.state.preEmployment2State}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="text"
                        target="preEmployment2Zip"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the zip code of your current company."
                        validate={this.checkValidate(
                          this.state.preEmployment2Zip
                        )}
                        value={this.state.preEmployment2Zip}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Position"
                        type="text"
                        target="preEmployment2Position"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your position at the current company."
                        validate={this.checkValidate(
                          this.state.preEmployment2Position
                        )}
                        value={this.state.preEmployment2Position}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed From"
                        type="date"
                        target="preEmployment2From"
                        onChange={this.onChangeDate}
                        min={"1910-01-01"}
                        max={
                          this.state.preEmployment2Until === ""
                            ? this.state.preEmploymentFrom
                            : this.state.preEmployment2Until
                        }
                        important="true"
                        errorMessage="the start date of your work."
                        validate={this.checkValidate(
                          this.state.preEmployment2From
                        )}
                        value={this.state.preEmployment2From}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed Until"
                        type="date"
                        target="preEmployment2Until"
                        onChange={this.onChangeDate}
                        min={this.state.preEmployment2From}
                        max={this.state.preEmploymentFrom}
                        important="true"
                        errorMessage="the end date of your work."
                        validate={this.checkValidate(
                          this.state.preEmployment2Until
                        )}
                        value={this.state.preEmployment2Until}
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.employmentCount < 3 && (
                <>
                  <div className="row inputContainer pt-0 mt-0 pb-3">
                    <div className="col  text-right pt-0 mt-3 ">
                      <button
                        className=" buttonPri"
                        name="addEmploymentButton"
                        id="addEmploymentButton"
                        onClick={() => this.addEmployment()}
                      >
                        Add Employment
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
              <hr className="line "></hr>
            </div>
            <div className="cardApplication pt-3">
              <p className="subTitle stepTitle pt-3 textDark">
                {this.state.applicantFirstName} Spouse’s Employment Details
              </p>
              <div className="row  pt-5 inputContainer">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Spouse’s Employer"
                    type="text"
                    target="spousesEmployer"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage="the spouse's employer name"
                    // validate={this.checkValidate(this.state.spousesEmployer)}
                    value={this.state.spousesEmployer}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="Spouse’s Address"
                    type="text"
                    target="spousesEmployerAddress"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage="the address of your spouse's employer company."
                    // validate={this.checkValidate(
                    //   this.state.spousesEmployerAddress
                    // )}
                    value={this.state.spousesEmployerAddress}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Spouse’s Income(Annually)"
                    type="number"
                    target="spousesIncome"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage="the spouse's income."
                    // validate={this.checkValidate(this.state.spousesIncome)}
                    value={this.state.spousesIncome}
                    iconL={"fa-usd"}
                  />
                </div>
              </div>
            </div>
            <br></br>
            {this.state.applicant2 && (
              <>
                <hr></hr>
                <div className="cardApplication ">
                  <p className="subTitle stepTitle pt-3 textDark">
                    {this.state.applicant2FirstName} Employment Details
                  </p>
                  <div className="row  pt-5 inputContainer">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Company Name"
                        type="text"
                        target="applicant2EmploymentCompany"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the name of your current company."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentCompany
                        )}
                        value={this.state.applicant2EmploymentCompany}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Company Phone Number"
                        type="text"
                        target="applicant2EmploymentPhone"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(
                            this.state.applicant2EmploymentPhone
                          )
                            ? "the phone number of your current company."
                            : "the valid phone number of your current company."
                        }
                        validate={this.checkMobile(
                          this.state.applicant2EmploymentPhone
                        )}
                        value={this.state.applicant2EmploymentPhone}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="applicant2EmploymentAddress"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the address of your current company."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentAddress
                        )}
                        value={this.state.applicant2EmploymentAddress}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="City"
                        type="text"
                        target="applicant2EmploymentCity"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the city of your current company."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentCity
                        )}
                        value={this.state.applicant2EmploymentCity}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="State"
                        type="text"
                        target="applicant2EmploymentState"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the state of your current company."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentState
                        )}
                        value={this.state.applicant2EmploymentState}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="text"
                        target="applicant2EmploymentZip"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the zip code of your current company."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentZip
                        )}
                        value={this.state.applicant2EmploymentZip}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Annual Income"
                        type="number"
                        target="applicant2EmploymentAnnualIncome"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your salary at the current company."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentAnnualIncome
                        )}
                        value={this.state.applicant2EmploymentAnnualIncome}
                        iconL={"fa-usd"}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Position"
                        type="text"
                        target="applicant2EmploymentPosition"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your position at the current company."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentPosition
                        )}
                        value={this.state.applicant2EmploymentPosition}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed From"
                        type="date"
                        target="applicant2EmploymentFrom"
                        onChange={this.onChangeDate}
                        min={"1910-01-01"}
                        max={
                          this.state.applicant2EmploymentUntil === ""
                            ? this.today()
                            : this.state.applicant2EmploymentUntil
                        }
                        important="true"
                        errorMessage="the start date of your work."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentFrom
                        )}
                        value={this.state.applicant2EmploymentFrom}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed Until"
                        type="date"
                        target="applicant2EmploymentUntil"
                        onChange={this.onChangeDate}
                        min={this.state.applicant2EmploymentFrom}
                        max={this.today()}
                        important="true"
                        errorMessage="the end date of your work."
                        validate={this.checkValidate(
                          this.state.applicant2EmploymentUntil
                        )}
                        value={this.state.applicant2EmploymentUntil}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
                  <hr className="line "></hr>
                </div>
                <p className="subTitle stepTitle pt-2 ">
                  If employment less than 3 years, provide information of
                  previous employers and occupation to total 3 years:
                </p>
                <br></br>
                {this.state.applicant2Employment1 && (
                  <div className="cardApplication pt-2">
                    <div className="row pt-2 ">
                      <div className="col ">
                        <p className="subTitle stepTitle pt-2 textDark">
                          {this.state.applicant2FirstName} Previous Employment
                          Information
                        </p>
                        {this.state.applicant2EmploymentCount === 2 ? (
                          <i
                            className="fa fa-window-close pull-right pr-5 pt-3 pointer"
                            onClick={() => this.removeApplicant2Employment()}
                          ></i>
                        ) : (
                          <br></br>
                        )}
                      </div>
                    </div>
                    <div className="row inputContainer pt-3">
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Company Name"
                          type="text"
                          target="applicant2PreEmploymentCompany"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the name of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentCompany
                          )}
                          value={this.state.applicant2PreEmploymentCompany}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Address"
                          type="text"
                          target="applicant2PreEmploymentAddress"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the address of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentAddress
                          )}
                          value={this.state.applicant2PreEmploymentAddress}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="City"
                          type="text"
                          target="applicant2PreEmploymentCity"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the city of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentCity
                          )}
                          value={this.state.applicant2PreEmploymentCity}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="State"
                          type="text"
                          target="applicant2PreEmploymentState"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the state of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentState
                          )}
                          value={this.state.applicant2PreEmploymentState}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Zip"
                          type="text"
                          target="applicant2PreEmploymentZip"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the zip code of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentZip
                          )}
                          value={this.state.applicant2PreEmploymentZip}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="Position"
                          type="text"
                          target="applicant2PreEmploymentPosition"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your position at the current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentPosition
                          )}
                          value={this.state.applicant2PreEmploymentPosition}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed From"
                          type="date"
                          target="applicant2PreEmploymentFrom"
                          onChange={this.onChangeDate}
                          min={"1910-01-01"}
                          max={
                            this.state.applicant2PreEmploymentUntil === ""
                              ? this.state.applicant2EmploymentFrom
                              : this.state.applicant2PreEmploymentUntil
                          }
                          important="true"
                          errorMessage="the start date of your work."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentFrom
                          )}
                          value={this.state.applicant2PreEmploymentFrom}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed Until"
                          type="date"
                          target="applicant2PreEmploymentUntil"
                          onChange={this.onChangeDate}
                          min={this.state.applicant2PreEmploymentFrom}
                          max={this.state.applicant2EmploymentFrom}
                          important="true"
                          errorMessage="the end date of your work."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmploymentUntil
                          )}
                          value={this.state.applicant2PreEmploymentUntil}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.applicant2Employment2 && (
                  <div className="cardApplication pt-2">
                    <div className="row pt-2">
                      <div className="col ">
                        <p className="subTitle stepTitle pt-2 textDark">
                          {this.state.applicant2FirstName} Previous Employment -
                          2 Information
                        </p>
                        {this.state.applicant2EmploymentCount === 3 ? (
                          <i
                            className="fa fa-window-close pull-right pr-5 pt-3 pointer"
                            onClick={() => this.removeApplicant2Employment()}
                          ></i>
                        ) : (
                          <br></br>
                        )}
                      </div>
                    </div>
                    <div className="row inputContainer pt-3">
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Company Name"
                          type="text"
                          target="applicant2PreEmployment2Company"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the name of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2Company
                          )}
                          value={this.state.applicant2PreEmployment2Company}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Address"
                          type="text"
                          target="applicant2PreEmployment2Address"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the address of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2Address
                          )}
                          value={this.state.applicant2PreEmployment2Address}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="City"
                          type="text"
                          target="applicant2PreEmployment2City"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the city of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2City
                          )}
                          value={this.state.applicant2PreEmployment2City}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="State"
                          type="text"
                          target="applicant2PreEmployment2State"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the state of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2State
                          )}
                          value={this.state.applicant2PreEmployment2State}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Zip"
                          type="text"
                          target="applicant2PreEmployment2Zip"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the zip code of your current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2Zip
                          )}
                          value={this.state.applicant2PreEmployment2Zip}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="Position"
                          type="text"
                          target="applicant2PreEmployment2Position"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your position at the current company."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2Position
                          )}
                          value={this.state.applicant2PreEmployment2Position}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed From"
                          type="date"
                          target="applicant2PreEmployment2From"
                          onChange={this.onChangeDate}
                          min={"1910-01-01"}
                          max={
                            this.state.applicant2PreEmployment2Until === ""
                              ? this.state.applicant2PreEmploymentFrom
                              : this.state.applicant2PreEmployment2Until
                          }
                          important="true"
                          errorMessage="the start date of your work."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2From
                          )}
                          value={this.state.applicant2PreEmployment2From}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed Until"
                          type="date"
                          target="applicant2PreEmployment2Until"
                          onChange={this.onChangeDate}
                          min={this.state.applicant2PreEmployment2From}
                          max={this.state.applicant2PreEmploymentFrom}
                          important="true"
                          errorMessage="the end date of your work."
                          validate={this.checkValidate(
                            this.state.applicant2PreEmployment2Until
                          )}
                          value={this.state.applicant2PreEmployment2Until}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.applicant2EmploymentCount < 3 && (
                  <>
                    <div className="row inputContainer pt-0 mt-0 pb-3">
                      <div className="col  text-right pt-0 mt-3 ">
                        <button
                          className=" buttonPri"
                          name="addEmployment2Button"
                          id="addEmployment2Button"
                          onClick={() => this.addApplicant2Employment()}
                        >
                          Add Employment
                        </button>
                      </div>
                    </div>
                  </>
                )}

                <div className="cardApplication pt-3">
                  <p className="subTitle stepTitle pt-3 textDark">
                    {this.state.applicant2FirstName} Spouse’s Employment Details
                  </p>
                  <div className="row  pt-5 inputContainer">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Spouse’s Employer"
                        type="text"
                        target="applicant2SpousesEmployer"
                        onChange={this.onchange}
                        // important="true"
                        // errorMessage="the spouse's employer name"
                        // validate={this.checkValidate(
                        //   this.state.applicant2SpousesEmployer
                        // )}
                        value={this.state.applicant2SpousesEmployer}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="Spouse’s Address"
                        type="text"
                        target="applicant2SpousesEmployerAddress"
                        onChange={this.onchange}
                        // important="true"
                        // errorMessage="the address of your spouse's employer company."
                        // validate={this.checkValidate(
                        //   this.state.applicant2SpousesEmployerAddress
                        // )}
                        value={this.state.applicant2SpousesEmployerAddress}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Spouse’s Income(Annually)"
                        type="number"
                        target="applicant2SpousesIncome"
                        onChange={this.onchange}
                        // important="true"
                        // errorMessage="the spouse's income."
                        // validate={this.checkValidate(
                        //   this.state.applicant2SpousesIncome
                        // )}
                        value={this.state.applicant2SpousesIncome}
                        iconL={"fa-usd"}
                      />
                    </div>
                  </div>
                </div>
                <br></br>
              </>
            )}
            {this.state.applicant3 && (
              <>
                <hr></hr>
                <div className="cardApplication">
                  <p className="subTitle stepTitle pt-3 textDark">
                    {this.state.applicant3FirstName} Employment Details
                  </p>
                  <div className="row  pt-5 inputContainer">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Company Name"
                        type="text"
                        target="applicant3EmploymentCompany"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the name of your current company."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentCompany
                        )}
                        value={this.state.applicant3EmploymentCompany}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Company Phone Number"
                        type="text"
                        target="applicant3EmploymentPhone"
                        onChange={this.onchange}
                        important="true"
                        errorMessage={
                          this.checkValidate(
                            this.state.applicant3EmploymentPhone
                          )
                            ? "the phone number of your current company."
                            : "the valid phone number of your current company."
                        }
                        validate={this.checkMobile(
                          this.state.applicant3EmploymentPhone
                        )}
                        value={this.state.applicant3EmploymentPhone}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="Address"
                        type="text"
                        target="applicant3EmploymentAddress"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the address of your current company."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentAddress
                        )}
                        value={this.state.applicant3EmploymentAddress}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="City"
                        type="text"
                        target="applicant3EmploymentCity"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the city of your current company."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentCity
                        )}
                        value={this.state.applicant3EmploymentCity}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="State"
                        type="text"
                        target="applicant3EmploymentState"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the state of your current company."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentState
                        )}
                        value={this.state.applicant3EmploymentState}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Zip"
                        type="text"
                        target="applicant3EmploymentZip"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="the zip code of your current company."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentZip
                        )}
                        value={this.state.applicant3EmploymentZip}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Annual Income"
                        type="number"
                        target="applicant3EmploymentAnnualIncome"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your salary at the current company."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentAnnualIncome
                        )}
                        value={this.state.applicant3EmploymentAnnualIncome}
                        iconL={"fa-usd"}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Position"
                        type="text"
                        target="applicant3EmploymentPosition"
                        onChange={this.onchange}
                        important="true"
                        errorMessage="your position at the current company."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentPosition
                        )}
                        value={this.state.applicant3EmploymentPosition}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed From"
                        type="date"
                        target="applicant3EmploymentFrom"
                        onChange={this.onChangeDate}
                        min={"1910-01-01"}
                        max={
                          this.state.applicant3EmploymentUntil === ""
                            ? this.today()
                            : this.state.applicant3EmploymentUntil
                        }
                        important="true"
                        errorMessage="the start date of your work."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentFrom
                        )}
                        value={this.state.applicant3EmploymentFrom}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Employed Until"
                        type="date"
                        target="applicant3EmploymentUntil"
                        onChange={this.onChangeDate}
                        min={this.state.applicant3EmploymentFrom}
                        max={this.today()}
                        important="true"
                        errorMessage="the end date of your work."
                        validate={this.checkValidate(
                          this.state.applicant3EmploymentUntil
                        )}
                        value={this.state.applicant3EmploymentUntil}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ paddingRight: "3%", paddingLeft: "3%" }}>
                  <hr className="line "></hr>
                </div>

                <p className="subTitle stepTitle pt-2 ">
                  If employment less than 3 years, provide information of
                  previous employers and occupation to total 3 years:
                </p>
                <br></br>
                {this.state.applicant3Employment1 && (
                  <div className="cardApplication pt-2">
                    <div className="row pt-2">
                      <div className="col ">
                        <p className="subTitle stepTitle pt-2 textDark">
                          {this.state.applicant3FirstName} Previous Employment
                          Information
                        </p>
                        {this.state.applicant3EmploymentCount === 2 ? (
                          <i
                            className="fa fa-window-close pull-right pr-5 pt-3 pointer"
                            onClick={() => this.removeApplicant3Employment()}
                          ></i>
                        ) : (
                          <br></br>
                        )}
                      </div>
                    </div>
                    <div className="row inputContainer pt-3">
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Company Name"
                          type="text"
                          target="applicant3PreEmploymentCompany"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the name of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentCompany
                          )}
                          value={this.state.applicant3PreEmploymentCompany}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Address"
                          type="text"
                          target="applicant3PreEmploymentAddress"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the address of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentAddress
                          )}
                          value={this.state.applicant3PreEmploymentAddress}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="City"
                          type="text"
                          target="applicant3PreEmploymentCity"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the city of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentCity
                          )}
                          value={this.state.applicant3PreEmploymentCity}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="State"
                          type="text"
                          target="applicant3PreEmploymentState"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the state of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentState
                          )}
                          value={this.state.applicant3PreEmploymentState}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Zip"
                          type="text"
                          target="applicant3PreEmploymentZip"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the zip code of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentZip
                          )}
                          value={this.state.applicant3PreEmploymentZip}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Position"
                          type="text"
                          target="applicant3PreEmploymentPosition"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your position at the current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentPosition
                          )}
                          value={this.state.applicant3PreEmploymentPosition}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed From"
                          type="date"
                          target="applicant3PreEmploymentFrom"
                          onChange={this.onChangeDate}
                          min={"1910-01-01"}
                          max={
                            this.state.applicant3PreEmploymentUntil === ""
                              ? this.state.applicant3EmploymentFrom
                              : this.state.applicant3PreEmploymentUntil
                          }
                          important="true"
                          errorMessage="the start date of your work."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentFrom
                          )}
                          value={this.state.applicant3PreEmploymentFrom}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed Until"
                          type="date"
                          target="applicant3PreEmploymentUntil"
                          onChange={this.onChangeDate}
                          min={this.state.applicant3PreEmploymentFrom}
                          max={this.state.applicant3EmploymentFrom}
                          important="true"
                          errorMessage="the end date of your work."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmploymentUntil
                          )}
                          value={this.state.applicant3PreEmploymentUntil}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.applicant3Employment2 && (
                  <div className="cardApplication pt-2">
                    <div className="row pt-2">
                      <div className="col ">
                        <p className="subTitle stepTitle pt-2 textDark">
                          {this.state.applicant3FirstName} Previous Employment -
                          2 Information
                        </p>
                        {this.state.applicant3EmploymentCount === 3 ? (
                          <i
                            className="fa fa-window-close pull-right pr-5 pt-3 pointer"
                            onClick={() => this.removeApplicant3Employment()}
                          ></i>
                        ) : (
                          <br></br>
                        )}
                      </div>
                    </div>
                    <div className="row inputContainer pt-3">
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Company Name"
                          type="text"
                          target="applicant3PreEmployment2Company"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the name of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2Company
                          )}
                          value={this.state.applicant3PreEmployment2Company}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Address"
                          type="text"
                          target="applicant3PreEmployment2Address"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the address of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2Address
                          )}
                          value={this.state.applicant3PreEmployment2Address}
                        />
                      </div>

                      <div className="col alignInput">
                        <ApplicationInput
                          name="City"
                          type="text"
                          target="applicant3PreEmployment2City"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the city of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2City
                          )}
                          value={this.state.applicant3PreEmployment2City}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="State"
                          type="text"
                          target="applicant3PreEmployment2State"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the state of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2State
                          )}
                          value={this.state.applicant3PreEmployment2State}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Zip"
                          type="text"
                          target="applicant3PreEmployment2Zip"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="the zip code of your current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2Zip
                          )}
                          value={this.state.applicant3PreEmployment2Zip}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Position"
                          type="text"
                          target="applicant3PreEmployment2Position"
                          onChange={this.onchange}
                          important="true"
                          errorMessage="your position at the current company."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2Position
                          )}
                          value={this.state.applicant3PreEmployment2Position}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed From"
                          type="date"
                          target="applicant3PreEmployment2From"
                          onChange={this.onChangeDate}
                          min={"1910-01-01"}
                          max={
                            this.state.applicant3PreEmployment2Until === ""
                              ? this.state.applicant3PreEmploymentFrom
                              : this.state.applicant3PreEmployment2Until
                          }
                          important="true"
                          errorMessage="the start date of your work."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2From
                          )}
                          value={this.state.applicant3PreEmployment2From}
                        />
                      </div>
                      <div className="col alignInput">
                        <ApplicationInput
                          name="Employed Until"
                          type="date"
                          target="applicant3PreEmployment2Until"
                          onChange={this.onChangeDate}
                          min={this.state.applicant3PreEmployment2From}
                          max={this.state.applicant3PreEmploymentFrom}
                          important="true"
                          errorMessage="the end date of your work."
                          validate={this.checkValidate(
                            this.state.applicant3PreEmployment2Until
                          )}
                          value={this.state.applicant3PreEmployment2Until}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.applicant3EmploymentCount < 3 && (
                  <>
                    <div className="row inputContainer pt-0 mt-0 pb-3">
                      <div className="col  text-right pt-0 mt-3 ">
                        <button
                          className=" buttonPri"
                          name="addEmployment3Button"
                          id="addEmployment3Button"
                          onClick={() => this.addApplicant3Employment()}
                        >
                          Add Employment
                        </button>
                      </div>
                    </div>
                  </>
                )}

                <div className="cardApplication pt-3 ">
                  <p className="subTitle stepTitle pt-3 textDark">
                    {this.state.applicant3FirstName} Spouse’s Employment Details
                  </p>
                  <div className="row  pt-5 inputContainer">
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Spouse’s Employer"
                        type="text"
                        target="applicant3SpousesEmployer"
                        onChange={this.onchange}
                        // important="true"
                        // errorMessage="the spouse's employer name"
                        // validate={this.checkValidate(
                        //   this.state.applicant3SpousesEmployer
                        // )}
                        value={this.state.applicant3SpousesEmployer}
                      />
                    </div>

                    <div className="col alignInput">
                      <ApplicationInput
                        name="Spouse’s Address"
                        type="text"
                        target="applicant3SpousesEmployerAddress"
                        onChange={this.onchange}
                        // important="true"
                        // errorMessage="the address of your spouse's employer company."
                        // validate={this.checkValidate(
                        //   this.state.applicant3SpousesEmployerAddress
                        // )}
                        value={this.state.applicant3SpousesEmployerAddress}
                      />
                    </div>
                    <div className="col alignInput">
                      <ApplicationInput
                        name="Spouse’s Income(Annually)"
                        type="number"
                        target="applicant3SpousesIncome"
                        onChange={this.onchange}
                        // important="true"
                        // errorMessage="the spouse's income."
                        // validate={this.checkValidate(
                        //   this.state.applicant3SpousesIncome
                        // )}
                        value={this.state.applicant3SpousesIncome}
                        iconL={"fa-usd"}
                      />
                    </div>
                  </div>
                </div>
                <br></br>
              </>
            )}
          </>
        )}
        {this.state.nowTab === 4 && (
          <>
            <div className="cardApplication pt-4">
              <p className="subTitle stepTitle pt-3 textDark">
                {this.state.applicantFirstName}'s Primary Bank Details
              </p>
              <div className="row  pt-5 inputContainer">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Bank Name"
                    type="text"
                    target="applicantPrimaryBankName"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the name of primary bank"
                    validate={this.checkValidate(
                      this.state.applicantPrimaryBankName
                    )}
                    value={this.state.applicantPrimaryBankName}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="Branch Name"
                    type="text"
                    target="applicantPrimaryBankBranch"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the branch name of primary bank."
                    validate={this.checkValidate(
                      this.state.applicantPrimaryBankBranch
                    )}
                    value={this.state.applicantPrimaryBankBranch}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Account Type"
                    type="optional"
                    options={["Checking", "Saving", "Trust"]}
                    optionValue={["Checking", "Saving", "Trust"]}
                    target="applicantPrimaryBankAccType"
                    onChange={this.onchange}
                    important="true"
                    errorMessage="the account type of primary bank."
                    validate={this.checkValidate(
                      this.state.applicantPrimaryBankAccType
                    )}
                    value={this.state.applicantPrimaryBankAccType}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Account Number"
                    type="text"
                    target="applicantPrimaryBankAccNo"
                    onChange={this.onchange}
                    important="true"
                    errorMessage={
                      this.checkValidate(this.state.applicantPrimaryBankAccNo)
                        ? "the account number of primary bank."
                        : "the valid account number of primary bank."
                    }
                    validate={this.checkAccountNo(
                      this.state.applicantPrimaryBankAccNo
                    )}
                    value={"    " + this.state.applicantPrimaryBankAccNo}
                    iconL={"fa-credit-card-alt"}
                  />
                </div>
              </div>
              <p className="subTitle stepTitle pt-0 textDark">
                {this.state.applicantFirstName}'s Secondary Bank Details
              </p>
              <div className="row  pt-5 inputContainer">
                <div className="col alignInput">
                  <ApplicationInput
                    name="Bank Name"
                    type="text"
                    target="applicantSecondaryBankName"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage="the name of secondary bank"
                    // validate={this.checkValidate(
                    //   this.state.applicantSecondaryBankName
                    // )}
                    value={this.state.applicantSecondaryBankName}
                  />
                </div>

                <div className="col alignInput">
                  <ApplicationInput
                    name="Branch Name"
                    type="text"
                    target="applicantSecondaryBankBranch"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage="the branch name of secondary bank."
                    // validate={this.checkValidate(
                    //   this.state.applicantSecondaryBankBranch
                    // )}
                    value={this.state.applicantSecondaryBankBranch}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Account Type"
                    type="optional"
                    options={["Checking", "Saving", "Trust"]}
                    optionValue={["Checking", "Saving", "Trust"]}
                    target="applicantSecondaryBankAccType"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage="the account type of secondary bank."
                    // validate={this.checkValidate(
                    //   this.state.applicantSecondaryBankAccType
                    // )}
                    value={this.state.applicantSecondaryBankAccType}
                  />
                </div>
                <div className="col alignInput">
                  <ApplicationInput
                    name="Account Number"
                    type="text"
                    target="applicantSecondaryBankAccNo"
                    onChange={this.onchange}
                    // important="true"
                    // errorMessage={
                    //   this.checkValidate(this.state.applicantSecondaryBankAccNo)
                    //     ? "the account number of secondary bank."
                    //     : "the valid account number of secondary bank."
                    // }
                    // validate={this.checkAccountNo(
                    //   this.state.applicantSecondaryBankAccNo
                    // )}
                    value={"    " + this.state.applicantSecondaryBankAccNo}
                    iconL={"fa-credit-card-alt"}
                  />
                </div>
              </div>
            </div>
            {this.state.applicant2 && (
              <div className="cardApplication pt-4">
                <p className="subTitle stepTitle pt-3 textDark">
                  {this.state.applicant2FirstName}'s Primary Bank Details
                </p>
                <div className="row  pt-5 inputContainer">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Bank Name"
                      type="text"
                      target="applicant2PrimaryBankName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of primary bank "
                      validate={this.checkValidate(
                        this.state.applicant2PrimaryBankName
                      )}
                      value={this.state.applicant2PrimaryBankName}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="Branch Name"
                      type="text"
                      target="applicant2PrimaryBankBranch"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the branch name of primary bank."
                      validate={this.checkValidate(
                        this.state.applicant2PrimaryBankBranch
                      )}
                      value={this.state.applicant2PrimaryBankBranch}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Type"
                      type="optional"
                      options={["Checking", "Saving", "Trust"]}
                      optionValue={["Checking", "Saving", "Trust"]}
                      target="applicant2PrimaryBankAccType"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the account type of primary bank."
                      validate={this.checkValidate(
                        this.state.applicant2PrimaryBankAccType
                      )}
                      value={this.state.applicant2PrimaryBankAccType}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Number"
                      type="text"
                      target="applicant2PrimaryBankAccNo"
                      onChange={this.onchange}
                      important="true"
                      errorMessage={
                        this.checkValidate(
                          this.state.applicant2PrimaryBankAccNo
                        )
                          ? "the account number of primary bank."
                          : "the valid account number of primary bank."
                      }
                      validate={this.checkAccountNo(
                        this.state.applicant2PrimaryBankAccNo
                      )}
                      value={"    " + this.state.applicant2PrimaryBankAccNo}
                      iconL={"fa-credit-card-alt"}
                    />
                  </div>
                </div>
                <p className="subTitle stepTitle pt-3 textDark">
                  {this.state.applicant2FirstName}'s Secondary Bank Details
                </p>
                <div className="row  pt-5 inputContainer">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Bank Name"
                      type="text"
                      target="applicant2SecondaryBankName"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage="the name of secondary bank"
                      // validate={this.checkValidate(
                      //   this.state.applicant2SecondaryBankName
                      // )}
                      value={this.state.applicant2SecondaryBankName}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="Branch Name"
                      type="text"
                      target="applicant2SecondaryBankBranch"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage="the branch name of secondary bank."
                      // validate={this.checkValidate(
                      //   this.state.applicant2SecondaryBankBranch
                      // )}
                      value={this.state.applicant2SecondaryBankBranch}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Type"
                      type="optional"
                      options={["Checking", "Saving", "Trust"]}
                      optionValue={["Checking", "Saving", "Trust"]}
                      target="applicant2SecondaryBankAccType"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage="the account type of secondary bank"
                      // validate={this.checkValidate(
                      //   this.state.applicant2SecondaryBankAccType
                      // )}
                      value={this.state.applicant2SecondaryBankAccType}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Number"
                      type="text"
                      target="applicant2SecondaryBankAccNo"
                      onChange={this.onchange}
                      // important="true"
                      value={"    " + this.state.applicant2SecondaryBankAccNo}
                      // errorMessage={
                      //   this.checkValidate(
                      //     this.state.applicant2SecondaryBankAccNo
                      //   )
                      //     ? "the account number of secondary bank"
                      //     : "the valid account number of secondary bank"
                      // }
                      validate={this.checkAccountNo(
                        this.state.applicant2SecondaryBankAccNo
                      )}
                      iconL={"fa-credit-card-alt"}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.applicant3 && (
              <div className="cardApplication pt-4">
                <p className="subTitle stepTitle pt-3 textDark">
                  {this.state.applicant3FirstName}'s Primary Bank Details
                </p>
                <div className="row  pt-5 inputContainer">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Bank Name"
                      type="text"
                      target="applicant3PrimaryBankName"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the name of primary bank"
                      validate={this.checkValidate(
                        this.state.applicant3PrimaryBankName
                      )}
                      value={this.state.applicant3PrimaryBankName}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="Branch Name"
                      type="text"
                      target="applicant3PrimaryBankBranch"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the branch name of primary bank."
                      validate={this.checkValidate(
                        this.state.applicant3PrimaryBankBranch
                      )}
                      value={this.state.applicant3PrimaryBankBranch}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Type"
                      type="optional"
                      options={["Checking", "Saving", "Trust"]}
                      optionValue={["Checking", "Saving", "Trust"]}
                      target="applicant3PrimaryBankAccType"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the account type of primary bank."
                      validate={this.checkValidate(
                        this.state.applicant3PrimaryBankAccType
                      )}
                      value={this.state.applicant3PrimaryBankAccType}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Number"
                      type="text"
                      target="applicant3PrimaryBankAccNo"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="the account number of primary bank."
                      validate={this.checkValidate(
                        this.state.applicant3PrimaryBankAccNo
                      )}
                      value={"    " + this.state.applicant3PrimaryBankAccNo}
                      iconL={"fa-credit-card-alt"}
                    />
                  </div>
                </div>
                <p className="subTitle stepTitle pt-3 textDark">
                  {this.state.applicant3FirstName}'s Secondary Bank Details
                </p>
                <div className="row  pt-5 inputContainer">
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Bank Name"
                      type="text"
                      target="applicant3SecondaryBankName"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage="the name of secondary bank"
                      // validate={this.checkValidate(
                      //   this.state.applicant3SecondaryBankName
                      // )}
                      value={this.state.applicant3SecondaryBankName}
                    />
                  </div>

                  <div className="col alignInput">
                    <ApplicationInput
                      name="Branch Name"
                      type="text"
                      target="applicant3SecondaryBankBranch"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage="the branch name of secondary bank."
                      // validate={this.checkValidate(
                      //   this.state.applicant3SecondaryBankBranch
                      // )}
                      value={this.state.applicant3SecondaryBankBranch}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Type"
                      type="optional"
                      options={["Checking", "Saving", "Trust"]}
                      optionValue={["Checking", "Saving", "Trust"]}
                      target="applicant3SecondaryBankAccType"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage="the account type of secondary bank."
                      // validate={this.checkValidate(
                      //   this.state.applicant3SecondaryBankAccType
                      // )}
                      value={this.state.applicant3SecondaryBankAccType}
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Account Number"
                      type="text"
                      target="applicant3SecondaryBankAccNo"
                      onChange={this.onchange}
                      // important="true"
                      // errorMessage={
                      //   this.checkValidate(
                      //     this.state.applicant3SecondaryBankAccNo
                      //   )
                      //     ? "the account number of secondary bank."
                      //     : "the valid account number of secondary bank."
                      // }
                      // validate={this.checkAccountNo(
                      //   this.state.applicant3SecondaryBankAccNo
                      // )}
                      value={"    " + this.state.applicant3SecondaryBankAccNo}
                      iconL={"fa-credit-card-alt"}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {this.state.nowTab === 5 && (
          <AddTenantPayment
            data={this.state.user}
            status={this.props.customerPaymentStatus}
            type="initial"
            next={this.next}
            role={this.props.role}
            pushAlert={this.props.pushAlert}
            setPaymentToken={this.setPaymentToken}
            paymentToken={this.state.paymentToken}
            changeTokenNull={this.changeTokenNull}
            propertyRent={this.state.propertyRent}
          />
        )}
        {this.state.nowTab === 6 && (
          <ApplicationReview
            listingData={this.state.listingData}
            onchangeState={this.onchangeState}
            data={this.state}
            onchangeCheck={this.onchangeCheck}
            onchangeCheckOpp={this.onchangeCheckOpp}
            onchangeCheckOption={this.onchangeCheckOption}
          />
        )}

        {this.state.footer && (
          <div className="row appFootButton text-right inputContainer pb-4 pt-0">
            <div className="col ">
              {this.state.nowTab !== 1 && (
                <button className="mr-3 pri" onClick={() => this.back()}>
                  Back
                </button>
              )}
              {this.state.nowTab !== 6 &&
                this.state.nowTab !== 0 &&
                (this.state.nowTab === 5 ? (
                  this.props.role !== "Guest" ? (
                    this.props.customerPaymentStatus !== "verified" ? (
                      <button
                        name="nextDisableButton"
                        className="secDisable"
                        style={{ cursor: "not-allowed" }}
                      >
                        Next
                      </button>
                    ) : this.state.paymentToken === "" ? (
                      <button
                        name="nextDisableButton"
                        className="secDisable"
                        style={{ cursor: "not-allowed" }}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        name="nextButton"
                        className="buttonPri "
                        onClick={() => this.next()}
                      >
                        Next
                      </button>
                    )
                  ) : this.props.customerPaymentStatus !== "verified" ? (
                    <button
                      className="secDisable"
                      name="nextDisableButton"
                      style={{ cursor: "not-allowed" }}
                    >
                      Next
                    </button>
                  ) : this.state.paymentToken === "" ? (
                    <button
                      className="secDisable"
                      name="nextDisableButton"
                      style={{ cursor: "not-allowed" }}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className="buttonPri "
                      name="nextButton"
                      type="button"
                      onClick={() => this.next()}
                    >
                      Next
                    </button>
                  )
                ) : (
                  <button
                    className="buttonPri "
                    type="button"
                    name="nextButton"
                    onClick={() => this.next()}
                  >
                    Next
                  </button>
                ))}
              {this.state.nowTab === 6 && (
                <>
                  <button
                    className={
                      !this.state.changeBlob
                        ? "secDisable notAllow"
                        : " buttonPri"
                    }
                    name="downloadPdfButton"
                    id="downloadPdfButton"
                    onClick={() => this.downloadPDF()}
                    data-target="#exampleModal1"
                    data-toggle="modal"
                  >
                    <i className="fa fa-download" aria-hidden="true"></i>
                    {!this.state.changeBlob && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>{" "}
                  &nbsp;&nbsp;
                  <button
                    className={
                      !this.state.isButtonDisableFinish
                        ? "secDisable notAllow"
                        : " buttonPri"
                    }
                    name="proceedButton"
                    id="proceedButton"
                    data-target="#finishedModal"
                    data-toggle="modal"
                    // onClick={() => this.finished()}
                    disabled={!this.state.isButtonDisableFinish}
                  >
                    Proceed
                    {!this.state.isButtonDisableFinish && (
                      <Loader3 transY="50px" transX="0px" />
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        <LicenseModal
          pushAlert={this.props.pushAlert}
          changeLicense={this.changeLicense}
          licenseModal={this.state.licenseModal}
          changeLicenseData={this.changeLicenseData}
          keepWait={this.keepWait}
          removeKeepWait={this.removeKeepWait}
        />
        {this.state.finishedModal && (
          <SmallModalContainer
            name="finishedModal"
            dark={this.props.dark}
            title="Confirmation"
            // footer="false"
            confirmCommand="Proceed to checkout"
            confirm={() => this.finished()}
            // closeContract={()=>this.setState({finishedModal:false})}
          >
            <div className="p-3">
              <span className="span7" style={{ fontSize: "16px" }}>
                {" "}
                Application Fee, {this.state.propertyRent}.00 USD will be
                charged when you submit this application
              </span>
            </div>
          </SmallModalContainer>
        )}

        <SmallModalContainer
          name="mailCheck"
          dark={this.props.dark}
          title="Hi, have you been here before?"
          // footer="false"
          rejectCheck="true"
          confirmCommand="Continue"
          rejectCommand="Try another e-mail"
          reject={() => this.emailReject()}
          size="lg"
          confirm={() => this.emailAccept()}
          // closeContract={()=>this.setState({finishedModal:false})}
        >
          <div className="p-3">
            <span className="span7" style={{ fontSize: "16px" }}>
              {" "}
              A user with this e-mail already exists. How do you want to
              continue?
            </span>
            <br></br>
            <br></br>
            <i className="fa fa-info-circle " aria-hidden="true"></i>
            &nbsp;&nbsp;
            <span className="searchListContentApp">
              If you choose to continue with existing details(Name,Mobile),
              Press continue.
            </span>
            <br></br>
            <span className="searchListContentApp">
              &nbsp;&nbsp;&nbsp;&nbsp;Else try with another e-mail.
            </span>
          </div>
        </SmallModalContainer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerPayment: state.customerPaymentReducer,
    customerPaymentStatus: state.customerPayment,
  };
};
export default withRouter(
  connect(mapStateToProps, { setCustomerPaymentStatus })(
    ListingApplicationCanada
  )
);

export class AddTenantPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      access_token: CookieService.getCookie("axTok"),
      data: this.props.data,
      accountStatus: "",
      type: "",
      activePaymentMethod: false,
      isLoading: true,
      isButtonDisable: true,
      account_holder_name: "",
      account_holder_type: "individual",
      routing_number: "",
      account_number: "",
      token_id: "",
      bank_account_id: "",
      deposit1: "",
      deposit2: "",
      nextPayment: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.verifyAccount = this.verifyAccount.bind(this);
  }
  async componentDidMount() {
    this.setState({
      accountStatus: this.props.status ? this.props.status : "",
    });
  
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit = async () => {
    if (
      this.state.account_holder_name === "" ||
      this.state.routing_number === "" ||
      this.state.account_number === ""
    ) {
      this.setState({ nextPayment: true });
    } else {
      this.setState({ nextPayment: false });

      try {
        this.setState({ isButtonDisable: false });
        let formBody = [];
        let details = {
          "bank_account[country]": "US",
          "bank_account[currency]": "usd",
          "bank_account[account_holder_name]": this.state.account_holder_name,
          "bank_account[account_holder_type]": this.state.account_holder_type,
          "bank_account[routing_number]": this.state.routing_number,
          "bank_account[account_number]": this.state.account_number,
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        stripeToken(formBody).then((response) => {
          this.setState({
            token_id: response.data.id,
            bank_account_id: response.data.bank_account.id,
          });
          let item = {
            email: this.state.data.username,
            token: this.state.token_id,
            bankAccountId: this.state.bank_account_id,
          };
          let form_data = [];
          for (let key in item) {
            let encodedKey = encodeURIComponent(key);
            let encodedValue = encodeURIComponent(item[key]);
            form_data.push(encodedKey + "=" + encodedValue);
          }
          form_data = form_data.join("&");
          addPaymentMethod(this.state.type, form_data).then((response1) => {
            if (response1.data.data === "sucess") {
              this.setState({ nextPayment: false });
              this.setState({ accountStatus: "new" });
              this.props.pushAlert("Please Verify the amounts", "info");
            } else {
              
              this.props.pushAlert(
                "Error in creating customer.Try again later!",
                "danger"
              );
              this.props.pushAlert(
                "A bank account with that routing number and account number already exists for this customer.",
                "warning"
              );
            }
            this.setState({ isButtonDisable: true });
          });
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  verifyAccount = async () => {
    this.setState({ isButtonDisable: false });
    if (this.state.deposit1 === "" || this.state.deposit2 === "") {
      this.setState({ nextPayment: true });
    } else {
      this.setState({ nextPayment: false });
      try {
        let formBody = [];
        let details = {
          deposit1: this.state.deposit1,
          deposit2: this.state.deposit2,
        };
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        verifyPaymentMethod(formBody).then((response) => {
          this.props.next();
          this.props.history.goBack();
          if (response.data.status) {
            if (response.data.data === "sucess") {
              this.setState({ nextPayment: false });
              this.props.pushAlert(response.data.data, "success");
            }
          } else {
            this.props.pushAlert(
              "Invalidate verification amount !!!",
              "danger"
            );
          }
          this.props.history.goBack();
        });
      } catch (error) {
        
      }
    }
  };
  checkValidate(value) {
    
    return (value === "" && this.state.nextPayment);
  }

  render() {
    return (
      <div className="medium-container" style={{ marginTop: "10px" }}>
        <div className="container-fluid">
          
          {this.state.accountStatus !== "new" && (
            <div className="row pb-0 mb-0">
              <div className="col">
                <StripeProvider
                  // apiKey="pk_live_moJGExia5w9VicwQ3CFFnGwg007GivhiXM"
                  // apiKey="pk_test_mYkSlWHCRNu1HAdbnrNTsEmm00gJceHZAk"
                  apiKey={stripeKey}
                >
                  <Elements>
                    <CardPayment
                      access_token={this.state.access_token}
                      email={
                        this.state.data ? this.state.data.username : "email"
                      }
                      history={this.props.history}
                      type={this.state.type}
                      pushAlert={this.props.pushAlert}
                      next={this.props.next}
                      role={this.props.role}
                      paymentToken={this.props.paymentToken}
                      setPaymentToken={this.props.setPaymentToken}
                      changeTokenNull={this.props.changeTokenNull}
                    />
                  </Elements>
                </StripeProvider>
              </div>
              <div className="col ">
                <div className="informationListAppNormal">
                  <i
                    className="fa fa-info-circle textPri"
                    aria-hidden="true"
                  ></i>
                  &nbsp;&nbsp;
                  <span className="searchListContentApp">
                    This payment method will only be used for this transaction.
                  </span>
                </div>
              </div>
            </div>
          )}
          {this.props.role !== "Guest" && (
            <>
              {this.state.accountStatus !== "new" && (
                <div
                  className="divider"
                  style={{ marginTop: "-0px", marginBottom: "25px" }}
                >
                  <span></span>
                  <span>OR</span>
                  <span></span>
                </div>
              )}

              <div className="row pr-3 pl-3 pb-5 ">
                {this.state.accountStatus === "new" ? (
                  <div className="col ">
                    <ApplicationInput
                      name="Deposit Amount 1"
                      type="text"
                      target="deposit1"
                      place="Deposit Amount 1."
                      onChange={this.handleChange}
                      important="true"
                      errorMessage="Deposit Amount 1."
                      validate={this.checkValidate(this.state.deposit1)}
                      value={this.state.deposit1}
                    />
                    <ApplicationInput
                      name="Deposit Amount 2"
                      type="text"
                      target="deposit2"
                      onChange={this.handleChange}
                      important="true"
                      place="Deposit Amount 2."
                      errorMessage="Deposit Amount 2."
                      validate={this.checkValidate(this.state.deposit2)}
                      value={this.state.deposit2}
                    />
                    <br></br>
                    <div className="text-right">
                      <button
                        type="submit"
                        className="pri"
                        name="verifyAccountButton"
                        id="verifyAccountButton"
                        onClick={() => this.verifyAccount()}
                        disabled={!this.state.isButtonDisable}
                      >
                        Verify Amounts
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="col pr-5">
                    <ApplicationInput
                      name="Full Name"
                      type="text"
                      target="account_holder_name"
                      onChange={this.handleChange}
                      important="true"
                      place="Full Name"
                      errorMessage="Full Name"
                      validate={this.checkValidate(
                        this.state.account_holder_name
                      )}
                      value={this.state.account_holder_name}
                    />
                    <ApplicationInput
                      name="Routing Number"
                      type="number"
                      target="routing_number"
                      onChange={this.handleChange}
                      important="true"
                      place="110000000"
                      errorMessage="Routing Number"
                      validate={this.checkValidate(this.state.routing_number)}
                      value={this.state.routing_number}
                    />
                    <ApplicationInput
                      name="Account Number"
                      type="number"
                      target="account_number"
                      onChange={this.handleChange}
                      important="true"
                      place="000123456789"
                      errorMessage="Account Number"
                      validate={this.checkValidate(this.state.account_number)}
                      value={this.state.account_number}
                    />
                    <br></br>
                    <br></br>
                    <div className="text-right">
                      <button
                        type="submit"
                        className="pri"
                        name="addAccountButton"
                        id="addAccountButton"
                        onClick={() => this.handleSubmit()}
                        disabled={!this.state.isButtonDisable}
                      >
                        Add Account
                      </button>
                    </div>
                  </div>
                )}
                <div className="col">
                  <h1 className="one">
                    Steps to verify your ACH payment method.
                  </h1>
                  <br></br>
                  <div className="informationListAppNormal">
                    <span className="spanRound">1</span>&nbsp;&nbsp;
                    <span className="searchListContentApp">
                      System automatically sends two small deposits for
                      verification purpose. These deposits take 1-2 business
                      days to appear on your online statement.
                    </span>
                  </div>
                  <div className="informationListAppNormal">
                    <span
                      className={
                        this.state.accountStatus === "new"
                          ? "spanRound"
                          : "spanRoundDark"
                      }
                    >
                      2
                    </span>
                    &nbsp;&nbsp;
                    <span className="searchListContentApp">
                      The statement has a description that includes AMTS
                      followed by the two micro deposit amounts.
                    </span>
                  </div>
                  <div className="informationListAppNormal">
                    <span
                      className={
                        this.state.accountStatus === "verified"
                          ? "spanRound"
                          : "spanRoundDark"
                      }
                    >
                      3
                    </span>
                    &nbsp;&nbsp;
                    <span className="searchListContentApp">
                      Once you receive the two micro deposit amounts, update it
                      in your payment portal.
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
