import { connect } from "react-redux";
import { Component } from "react";
import {
  setAccountPaymentStatus,
  setCustomerPaymentStatus,
  setCustomerPaymentDetail,
  setProperty,
  setContract,
  setApplication,
  setRecentPayment,
  setMaintenance,
  setRentalList,
  appendTempListingData,
  appendTempUsers,
  appendTempTenantByContract,
  setUser,
  setProvider,
} from "../actions";
import {
  checkPaymentAccountDetail,
  checkPaymentStatus,
  getApplications,
  getCustomerPayment,
  getLandlordContracts,
  getLandlordPayments,
  getMaintenanceRequest,
  getAllApplication,
  getProperty,
  getPropertyLists,
  getProviders,
  getRentalLists,
  getTenantContracts,
  getTenantPayments,
  userDetail,
} from "./ApiServices";
import { withRouter } from "react-router-dom";

class PreLoaderHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    let pathName = this.props.history.location.pathname;
    userDetail(this.props.userDetailArray.username).then((response) => {
      if (response.status) {
        this.props.setUser(response.data);
      }
    });
    if (this.props.roleStatus === "Tenant") {
      if (pathName !== "/rental-units") {
        this.setRentalUnit();
      }
      if (pathName !== "/applications") {
        this.setTenantApplication();
      }
      if (pathName !== "/contracts") {
        this.setTenantContract();
      }
      if (pathName !== "/payments") {
        this.setTenantPayment();
        this.setCustomerPaymentData();
      }
      if (
        pathName !== "/payments" ||
        pathName !== "/applications" ||
        pathName !== "/contracts"
      ) {
        this.setCustomerPayment();
      }
      if (pathName !== "/maintenance") {
        if(process.env.REACT_APP_STATUS!=="Production"){
          this.setMaintenance();
        }
      }
    } else if (this.props.roleStatus === "Landlord") {
      if (pathName !== "/properties") {
        this.setProperty();
      }
      if (pathName !== "/properties" || pathName !== "/payments") {
        this.setAccountPayment();
      }
      if (pathName !== "/applications") {
        this.setLandlordApplication();
      }
      if (pathName !== "/contracts") {
        this.setLandlordContract();
      }
      if (pathName !== "/payments") {
        this.setLandlordPayment();
      }
      if (pathName !== "/maintenance") {
        if(process.env.REACT_APP_STATUS!=="Production"){
          this.setMaintenance();
          this.setProviders(this.props.userDetailArray.username);
        }
      }
    }
  }

  setAccountPayment = () => {
    if (this.props.accountPaymentStatus === null) {
      try {
        checkPaymentAccountDetail().then((response) => {
          if (response.status === 200) {
            this.props.setAccountPaymentStatus(
              response.data.capabilities.card_payments
            );
          }
        });
      } catch (error) {}
    }
  };
  setCustomerPayment = () => {
    if (this.props.customerPaymentStatus === null) {
      try {
        checkPaymentStatus().then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.props.setCustomerPaymentStatus(response.data.data);
            }
          }
        });
      } catch (err) {}
    }
  };
  setCustomerPaymentData = () => {
    if (this.props.customerPaymentDetailArray === null) {
      try {
        getCustomerPayment().then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.props.setCustomerPaymentDetail(response.data);
            }
          }
        });
      } catch (err) {}
    }
  };
  //fetch to fill property redux
  setProperty = () => {
    if (this.props.propertyArray === null) {
      try {
        getProperty().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.setProperty(response.data);
            } else {
              this.props.setProperty([]);
            }
          }
        });
      } catch (err) {}
    }
  };
  //fetch to fill tenant contract redux
  setTenantContract = () => {
    if (this.props.contractArray === null) {
      try {
        getTenantContracts().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.setContract(
                response.data.sort((a, b) =>
                  b.updatedAt.localeCompare(a.updatedAt)
                )
              );
              response.data.forEach((data) => {
                this.appendList(data.rentalEntityId);
                this.appendUser(data.landLordId);
                this.appendTenantByContract(data.tenants);
              });
            } else {
              this.props.setContract([]);
            }
          }
        });
      } catch (error) {}
    }
  };
  //fetch to fill landlord contract redux
  setLandlordContract = () => {
    if (this.props.contractArray === null) {
      try {
        getLandlordContracts().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.setContract(
                response.data.sort((a, b) =>
                  b.updatedAt.localeCompare(a.updatedAt)
                )
              );
              response.data.forEach((data) => {
                this.appendList(data.rentalEntityId);
                this.appendTenantByContract(data.tenants);
              });
            } else {
              this.props.setContract([]);
            }
          }
        });
      } catch (error) {}
    }
  };

  //fetch to fill tenant application redux
  setTenantApplication = () => {
    if (this.props.applicationArray === null) {
      try {
        getApplications().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.setApplication(response.data.reverse());
              response.data.forEach((data) => {
                this.appendList(data.rentalEntityId);
                data.apply.forEach((data1) => {
                  this.appendUser(data1.applicant.username);
                });
              });
            } else {
              this.props.setApplication([]);
            }
          }
        });
      } catch (error) {}
    }
  };
  //fetch to fill landlord application redux
  setLandlordApplication = () => {
    if (this.props.applicationArray === null) {
      try {
        getAllApplication().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.setApplication(response.data.reverse());
              response.data.forEach((data) => {
                this.appendList(data.rentalEntityId);
                data.applications.forEach((data1) => {
                  data1.apply.forEach((data2) => {
                    this.appendUser(data2.applicant.username);
                  });
                });
              });
            } else {
              this.props.setApplication([]);
            }
          }
        });
      } catch (error) {}
    }
  };

  //fetch to fill tenant payment redux
  setTenantPayment = () => {
    if (this.props.recentPaymentArray === null) {
      try {
        getTenantPayments(this.props.userDetailArray.username).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.length !== 0) {
                this.props.setRecentPayment(response.data.sort((a, b) =>b.paidDate.localeCompare(a.paidDate)));
                response.data.forEach((data) => {
                  this.appendUser(data.landlordId);
                });
              } else {
                this.props.setRecentPayment([]);
              }
            }
          }
        );
      } catch (error) {}
    }
  };
  //fetch to fill landlord payment redux
  setLandlordPayment = () => {
    if (this.props.recentPaymentArray === null) {
      try {
        getLandlordPayments(this.props.userDetailArray.username).then(
          (response) => {
            if (response.status === 200) {
              if (response.data.length !== 0) {
                this.props.setRecentPayment(response.data.sort((a, b) =>b.paidDate.localeCompare(a.paidDate)));
                response.data.forEach((data) => {
                  this.appendUser(data.tenantId);
                });
              } else {
                this.props.setRecentPayment([]);
              }
            }
          }
        );
      } catch (error) {}
    }
  };

  //fetch to fill  maintenance redux
  setMaintenance = () => {
    if (this.props.maintenanceArray === null) {
      try {
        getMaintenanceRequest().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              response.data = response.data.sort((a, b) =>b.updatedAt.localeCompare(a.updatedAt));
              this.props.setMaintenance(response.data);
              response.data.forEach((data) => {
                this.appendUser(data.tenantId);
              });
            } else {
              this.props.setMaintenance([]);
            }
          }
        });
      } catch (error) {}
    }
  };
  //fetch to fill  Rental units redux
  setRentalUnit = () => {
    if (this.props.rentalListArray === null) {
      try {
        getRentalLists().then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.setRentalList(response.data.reverse());
            } else {
              this.props.setRentalList([]);
            }
          }
        });
      } catch (error) {}
    }
  };
  //fetch to fill  provider redux
  setProviders = (user) => {
    if (this.props.providerArray === null) {
      try {
        getProviders(user).then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.setProvider(response.data.reverse());
            } else {
              this.props.setProvider([]);
            }
          }
        });
      } catch (error) {}
    }
  };
  //fetch to fill temporary listings redux
  appendList = (id) => {
    let listing = this.props.tempListingArray.filter(
      (todo) => todo.rentalEntity.id === id
    );
    if (listing.length === 0) {
      try {
        getPropertyLists(id).then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.props.appendTempListingData(response.data);
            }
          }
        });
      } catch (error) {}
    }
  };
  //fetch to fill temporary users redux
  appendUser = (id) => {
    let users = this.props.tempUsersArray.filter(
      (todo) => todo.username === id
    );

    if (users.length === 0 && id !== undefined) {
      try {
        userDetail(id).then((response) => {
          if (response.status) {
            this.props.appendTempUsers(response.data);
          }
        });
      } catch (error) {}
    }
  };
  appendTenantByContract = (tenants) => {
    tenants.forEach((data) => {
      this.appendUser(data.username);
    });
  };

  render() {
    return <></>;
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
    userDetailArray: state.user,
    role: state.RoleReducer,
    roleStatus: state.role,
    property: state.propertyReducer,
    propertyArray: state.property,
    contract: state.contractReducer,
    contractArray: state.contract,
    application: state.applicationReducer,
    applicationArray: state.application,
    recentPayment: state.recentPaymentReducer,
    recentPaymentArray: state.recentPayment,
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
    rentalList: state.rentalListReducer,
    rentalListArray: state.rentalList,
    accountPayment: state.accountPaymentReducer,
    accountPaymentStatus: state.accountPayment,
    customerPayment: state.customerPaymentReducer,
    customerPaymentStatus: state.customerPayment,
    customerPaymentDetail: state.customerPaymentDetailReducer,
    customerPaymentDetailArray: state.customerPaymentDetail,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    tempTenantByContract: state.tempTenantByContractReducer,
    tempTenantByContractArray: state.tempTenantByContract,
    provider: state.providerReducer,
    providerArray: state.provider,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    setAccountPaymentStatus,
    setCustomerPaymentStatus,
    setCustomerPaymentDetail,
    setProperty,
    setContract,
    setRecentPayment,
    setMaintenance,
    setApplication,
    setRentalList,
    appendTempListingData,
    appendTempUsers,
    appendTempTenantByContract,
    setUser,
    setProvider,
  })(PreLoaderHandler)
);
