import React, { Component } from "react";
import ApplicationInput from "../UIServices/ApplicationInput";
import {
  createContract,
  getLandlordContracts,
  uploadContractPdf,
} from "../../Services/ApiServices";
import { withRouter } from "react-router-dom";
import { today } from "../../Defined/Function";
import { monthNumberArray } from "../../Services/JsonData";
import Loader3 from "../Loader/loader3";
import Dropzone from "react-dropzone";
import SmallModalContainer from "../Pages/Documents/SmallModalContainer";
import { connect } from "react-redux";
import { appendAlert, setContract } from "../../actions";

class AddTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      endDate: "",
      rent: "",
      rentCollectionDate: 1,
      isLateFee: false,
      gracePeriod: 1,
      lateFeeCharging: "",
      lateFeeAmount: "",
      error: [],
      next: false,
      isButtonDisableFinish: true,
      agreementPDF: true,
      pdfDoc: [],
      openModal: false,
    };

    this.handleFinish = this.handleFinish.bind(this);
    this.onchange = this.onchange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }
  handleValidate() {
    if (!this.validate()) {
      if (this.state.pdfDoc.length !== 0) {
        this.handleFinish();
      } else {
        this.setState({ openModal: true });
      }
    } else {
      this.setState({ next: true });
    }
  }
  handleFinish() {
    if (!this.validate()) {
      this.setState({ isButtonDisableFinish: false });
      try {
        let contractBody = {
          tenants: [
            {
              username: this.state.email,
              tenantOrder: 1,
            },
          ],
          rentalEntityId: this.props.location.state.id,
          applicationId: 0,
          agreementType: null,
          otherAgreementType: null,
          startDate: today(),
          endDate: this.state.endDate,
          rent: this.state.rent,
          rentCollectionDay: this.state.rentCollectionDate,
          rentPaidOnPeriod: null,
          isSecurityDeposit: false,
          securityDeposit: null,
          isKeyDeposit: false,
          keyDeposit: null,
          keyDepositDescription: null,
          moveInCheckList: null,
          moveOutCheckList: null,
          status: "PENDING",
          parkingAcce: false,
          parkingDescription: null,
          condominium: false,
          condominiumLink: null,
          indoorSmoking: false,
          indoorVaping: false,
          smokingDescription: null,
          emailOption: false,
          documentEmails: null,
          landlordEmergencyContactOption: false,
          landlordEmergencyContact: null,
          parkingFee: 0.0,
          otherRentFee1Name: null,
          otherRentFee1: 0.0,
          otherRentFee2Name: null,
          otherRentFee2: 0.0,
          otherRentFee3Name: null,
          otherRentFee3: 0.0,
          isRentDiscount: false,
          rentDiscount: 0.0,
          rentDiscountType: null,
          rentDiscountDescription: null,
          rentPayableTo: null,
          rentPayableMethod: null,
          electricityResponsible: null,
          heatResponsible: null,
          waterResponsible: null,
          otherResponsible: null,
          isTenantInsurance: false,
          airConditioner: false,
          gas: false,
          storage: false,
          laundry: false,
          laundryChargeType: null,
          guestParking: false,
          guestParkingType: null,
          serviceType1: null,
          serviceType2: null,
          serviceType3: null,
          serviceDescription: null,
          subscription: false,
          isPartialRent: false,
          partialRent: 0.0,
          partialRentDate: null,
          partialRentFrom: null,
          partialRentTo: null,
          islateFee: this.state.isLateFee,
          lateFeeCharging:
            this.state.lateFeeCharging !== ""
              ? this.state.lateFeeCharging
              : null,
          gracePeriod:
            this.state.gracePeriod !== "" ? this.state.gracePeriod : null,
          lateFeeAmount:
            this.state.lateFeeAmount !== "" ? this.state.lateFeeAmount : null,
          isAdditionalTerms: false,
          additionalTermsDoc: null,
          landLordId: "",
          agreementPDF: "OWN_AGREEMENT_PENDING",
        };
        let body = {
          contract: contractBody,
          shortSignature: null,
          signatureImage: null,
          signature: false,
        };
        createContract(body).then((response) => {
          this.setState({ isButtonDisable: true });
          this.setState({ isButtonDisableFinish: true });
          if (response.status === 200) {
            if (response.data.status) {
              if (this.state.pdfDoc.length !== 0) {
                const formData = new FormData();
                formData.append("file", this.state.pdfDoc);
                formData.append("version", 1);
                formData.append("tenantAccess", true);
                formData.append("landlordAccess", true);
                uploadContractPdf(formData, parseInt(response.data.data))
                  .then((response1) => {
                    if (response1.status === 200) {
                      this.props.appendAlert(
                        "Contract created and invitation has been sent",
                        "success"
                      );
                      this.props.history.push({
                        pathname:
                          "/properties/" +
                          this.props.match.params.propsId +
                          "/listings",
                        state: {
                          id: this.props.match.params.propsId,
                          tab: "Closed",
                          message: null,
                        },
                      });
                    } else {
                      this.props.appendAlert("Pdf upload failed", "danger");
                      this.props.appendAlert(
                        "Contract created and invitation has been sent",
                        "success"
                      );
                      this.props.history.push({
                        pathname:
                          "/properties/" +
                          this.props.match.params.propsId +
                          "/listings",
                        state: {
                          id: this.props.match.params.propsId,
                          tab: "Closed",
                          message: null,
                        },
                      });
                    }
                    try {
                      getLandlordContracts().then((response2) => {
                        if (response2.status === 200) {
                          if (response2.data.length !== 0) {
                            this.props.setContract(
                              response2.data.sort((a, b) =>
                                b.updatedAt.localeCompare(a.updatedAt)
                              )
                            );
                          } else {
                            this.props.setContract([]);
                          }
                        }
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  })
                  .catch(() => {
                    this.props.history.push({
                      pathname:
                        "/properties/" +
                        this.props.match.params.propsId +
                        "/listings",
                      state: {
                        id: this.props.match.params.propsId,
                        tab: "Closed",
                        message: null,
                      },
                    });
                    this.props.appendAlert(
                      "Contract created and invitation has been sent",
                      "success"
                    );
                    try {
                      getLandlordContracts().then((response3) => {
                        if (response3.status === 200) {
                          if (response3.data.length !== 0) {
                            this.props.setContract(
                              response3.data.sort((a, b) =>
                                b.updatedAt.localeCompare(a.updatedAt)
                              )
                            );
                          } else {
                            this.props.setContract([]);
                          }
                        }
                      });
                    } catch (error) {
                      console.error(error);
                    }
                  });
              } else {
                try {
                  getLandlordContracts().then((response4) => {
                    if (response4.status === 200) {
                      if (response4.data.length !== 0) {
                        this.props.setContract(
                          response4.data.sort((a, b) =>
                            b.updatedAt.localeCompare(a.updatedAt)
                          )
                        );
                      } else {
                        this.props.setContract([]);
                      }
                    }
                  });
                } catch (error) {
                  console.error(error);
                }
                this.props.appendAlert(
                  "Contract created and invitation has been sent",
                  "success"
                );
                this.props.history.push({
                  pathname:
                    "/properties/" +
                    this.props.match.params.propsId +
                    "/listings",
                  state: {
                    id: this.props.match.params.propsId,
                    tab: "Closed",
                    message: null,
                  },
                });
              }
            } else {
              this.props.appendAlert("Something went wrong!", "danger");
            }
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        });
      } catch (error) {}
    } else {
      this.setState({ next: true });
    }
  }

  validate() {
    let isValid = false;
    let errors = {};
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      this.state.email === "" ||
      !re.test(String(this.state.email).toLowerCase())
    ) {
      isValid = true;
    }

    if (this.state.endDate === "") {
      isValid = true;
    }

    if (this.state.rent === "") {
      isValid = true;
    }

    if (
      this.state.rentCollectionDate === "" ||
      parseInt(this.state.rentCollectionDate) > 31
    ) {
      isValid = true;
    }

    if (this.state.isLateFee && this.state.gracePeriod === "") {
      isValid = true;
    }
    if (this.state.isLateFee && this.state.lateFeeCharging === "") {
      isValid = true;
    }
    if (this.state.isLateFee && this.state.lateFeeAmount === "") {
      isValid = true;
    }
    this.setState({ error: errors });
    return isValid;
  }
  async onchange(e) {
    let { name, value } = e.target;
    if (
      name === "rent" ||
      name === "rentCollectionDate" ||
      name === "gracePeriod" ||
      name === "lateFeeAmount"
    ) {
      value = value.replace(/[^0-9]/g, "");
    }
    await this.setState({ [name]: value });
  }
  onChangeDate(name, value) {
    this.setState({ [name]: value });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: checked });
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  convertPdf(pdf) {
    const url = window.URL.createObjectURL(new Blob([pdf], { type: pdf.type }));
    const link = document.createElement("a");
    link.href = url;
    return link;
  }
  render() {
    return (
      <>
        <div
          className="container-fluid "
          style={{ marginTop: "80px", paddingTop: "20px", marginBottom: "25%" }}
        >
          <div className="cardApplication">
            <h1 className="axText pb-3 pl-5 mt-3">Add Tenant</h1>
            <div className="row  inputContainer1 ">
              <div className="col alignInput">
                <ApplicationInput
                  name="Tenant's email"
                  type="email"
                  target="email"
                  onChange={this.onchange}
                  important="true"
                  errorMessage="valid email"
                  validate={this.checkValidate(this.state.email)}
                  value={this.state.email}
                />
              </div>
              <div className="col alignInput">
                <ApplicationInput
                  name="Contract End Date"
                  type="date"
                  target="endDate"
                  onChange={this.onChangeDate}
                  important="true"
                  errorMessage="contract end date"
                  validate={this.checkValidate(this.state.endDate)}
                  value={this.state.endDate}
                />
              </div>

              <div className="col-md alignInput">
                <ApplicationInput
                  name="Monthly Rent"
                  type="number"
                  target="rent"
                  onChange={this.onchange}
                  important="true"
                  errorMessage="rent amount"
                  validate={this.checkValidate(this.state.rent)}
                  value={this.state.rent}
                  iconL={"fa-usd"}
                />
              </div>

              <div className="col-md ">
                <ApplicationInput
                  name="Rent Due Date"
                  target="rentCollectionDate"
                  type="optional"
                  options={monthNumberArray}
                  optionValue={monthNumberArray}
                  infoStyle="infoContent2"
                  info={
                    <>
                      <b>Note:</b>
                      <br></br>
                      <span>
                        {this.state.rentCollectionDate}{" "}
                        {this.state.rentCollectionDate > 10 &&
                        this.state.rentCollectionDate < 20
                          ? "th"
                          : this.state.rentCollectionDate % 10 === 1
                          ? "st"
                          : this.state.rentCollectionDate % 10 === 2
                          ? "nd"
                          : this.state.rentCollectionDate % 10 === 3
                          ? "rd"
                          : "th"}{" "}
                        day of month
                      </span>
                    </>
                  }
                  onChange={this.onchange}
                  important="true"
                  errorMessage="rent collection date"
                  validate={this.checkValidate(this.state.rentCollectionDate)}
                  value={this.state.rentCollectionDate}
                />
              </div>
            </div>
            <div className="row  inputContainer1 ">
              <div className="col-md-3 ">
                <ApplicationInput
                  name="Late Rent Payment"
                  type="optionYN"
                  target="isLateFee"
                  onChange={this.onchangeCheck}
                  validate={this.checkValidate(this.state.isLateFee)}
                  value={this.state.isLateFee}
                />
              </div>

              {this.state.isLateFee && (
                <>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Grace period"
                      type="number"
                      target="gracePeriod"
                      onChange={this.onchange}
                      important="true"
                      errorMessage="rent gracePeriod"
                      validate={this.checkValidate(this.state.gracePeriod)}
                      value={this.state.gracePeriod}
                      infoStyle="infoContent2"
                      info={
                        <>
                          <b>Note:</b>
                          <br></br>
                          <span>
                            {this.state.gracePeriod}{" "}
                            {this.state.gracePeriod === 1 ? "day" : "days"}
                          </span>
                        </>
                      }
                    />
                  </div>
                  <div className="col alignInput">
                    <ApplicationInput
                      name="Charging Method"
                      target="lateFeeCharging"
                      type="optional"
                      options={["Fixed amount", "Charge per day", "Percentage"]}
                      optionValue={["FIXED", "PERDAY", "PERCENTAGE"]}
                      onChange={this.onchange}
                      important="true"
                      errorMessage="unit type you are living"
                      validate={this.checkValidate(this.state.lateFeeCharging)}
                      value={this.state.lateFeeCharging}
                    />
                  </div>
                  <div className="col alignInput ">
                    <ApplicationInput
                      name="Amount"
                      type="number"
                      place={
                        this.state.lateFeeCharging !== ""
                          ? String(
                              this.state.lateFeeCharging
                            ).toLocaleLowerCase()
                          : "20 or 5%"
                      }
                      target="lateFeeAmount"
                      onChange={this.onchange}
                      iconL={"fa-usd"}
                      value={this.state.lateFeeAmount}
                      important="true"
                      errorMessage="late fee amount"
                      validate={this.checkValidate(this.state.lateFeeAmount)}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="row inputContainer1 mt-2">
              {this.state.pdfDoc.length !== 0 && (
                <div className="col-md-12 ">
                  {/* <embed
                  src={this.convertPdf(this.state.pdfDoc)}
                  width="100%"
                  height="500px"
                /> */}
                  <center>
                    <div className="PDFWeb">
                      <object
                        data={this.convertPdf(this.state.pdfDoc)}
                        type="application/pdf"
                        width="100%"
                        height="500px"
                      >
                        <p>
                          Your web browser doesn't have a PDF plugin. Instead
                          you can{" "}
                          <a href={this.convertPdf(this.state.pdfDoc)}>
                            click here to download the PDF file.
                          </a>
                        </p>
                      </object>
                    </div>
                    <div className="PDFMobile">
                      <object
                        data={this.convertPdf(this.state.pdfDoc)}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      >
                        <p>
                          Your web browser doesn't have a PDF plugin. Instead
                          you can{" "}
                          <a href={this.convertPdf(this.state.pdfDoc)}>
                            click here to download the PDF file.
                          </a>
                        </p>
                      </object>
                    </div>
                    <a
                      className="pt-0 mt-0 PDFMobile "
                      href={this.convertPdf(this.state.pdfDoc)}
                    >
                      click here to view the full PDF file.
                    </a>
                  </center>
                </div>
              )}
              <div className="col-md-12 ">
                <p className="span2  text-center  ">Upload Agreement Form</p>
                <Dropzone
                  accept=".pdf"
                  maxSize={10485760}
                  onDrop={(acceptedFiles) => {
                    if (acceptedFiles[0] === undefined) {
                      this.props.appendAlert(
                        "Pdf size is too Long (max size = 10mb) ",
                        "danger"
                      );
                    } else {
                      this.setState({ pdfDoc: acceptedFiles[0] });
                    }
                  }}
                  multiple={false}
                  submitButtonContent="Upload"
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      <div className={"mt-4"} {...getRootProps()}>
                        <input {...getInputProps()} />

                        <div
                          className="dragDrop text-center "
                          style={{
                            fontSize: "60px",
                            minHeight: "300px",
                            paddingTop: "70px",
                          }}
                        >
                          <div className="row">
                            <div className="col">
                              <i
                                className={
                                  this.props.dark
                                    ? "fa fa-cloud-upload  fa-10x dragIcon text-light vert-move "
                                    : "fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                }
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <p
                                className={
                                  this.props.dark
                                    ? "licenseHeadDark"
                                    : "licenseHead"
                                }
                              >
                                Drag Or Click to Upload Pdf
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Dropzone>
                <span className="span2 textPri text-center pull-right ">
                  Max file size is 10MB. Pdf format only.
                </span>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col  mb-4 pr-5">
                <button
                  onClick={() => this.handleValidate()}
                  disabled={!this.state.isButtonDisableFinish}
                  className={
                    !this.state.isButtonDisableFinish
                      ? "secDisable notAllow pull-right"
                      : " buttonPri pull-right"
                  }
                  data-target={this.state.pdfDoc.length === 0 && "#openBox"}
                  data-toggle={this.state.pdfDoc.length === 0 && "modal"}
                >
                  Add Tenant
                  {!this.state.isButtonDisableFinish && (
                    <Loader3 transY="50px" transX="0px" />
                  )}
                </button>
                <button
                  className="pri pull-right mr-3"
                  onClick={() =>
                    this.props.history.push({
                      pathname:
                        "/properties/" +
                        this.props.match.params.propsId +
                        "/listings",
                      state: {
                        id: this.props.match.params.propsId,
                        tab: "Open",
                        message: null,
                      },
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {this.state.openModal && (
            <SmallModalContainer
              name="openBox"
              dark={this.props.dark}
              title="Forgot to upload ?"
              confirmCommand="Continue"
              confirm={() => this.setState({ openModal: false })}
              reject={() => this.handleFinish()}
              rejectCommand="Later"
              rejectCheck="true"
              size="lg"
            >
              <div className="p-2 pull-left text-left">
                <span className="span7" style={{ fontSize: "16px" }}>
                  If you want to upload agreement form for this unit, please
                  click Continue. If you want to create agreement without pdf,
                  click later.
                </span>
              </div>
            </SmallModalContainer>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    contract: state.contractReducer,
    contractArray: state.contract,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setContract })(AddTenant)
);
