import React, { useState, useEffect } from "react";
import "./style.css";
import { withRouter } from "react-router-dom";
import { acceptContractSecondary } from "../../../Services/ApiServices";
import Loader from "../../Loader/Loader";

const AcceptContract = (props) => {
  const [loader, setLoader] = useState(false);
  const [accept, setAccept] = useState(false);
  useEffect(() => {
    if (props.tenant === "primary") {
      props.history.push({
        pathname: "/signup",
        state: {
          email: window.atob(props.match.params.tenantEmail),
          type: "directEmailTenantLogin",
          sign:false
        },
      });
    }
  }, [props.match.params.tenantEmail, props.tenant, props.history]);
  function Accept(percent) {
    setLoader(true);
    try {
      acceptContractSecondary(
        props.match.params.contractId,
        atob(props.match.params.token),
        atob(props.match.params.tenantEmail)
      ).then((response) => {
        setLoader(false);
        if (response.status === 200) {
          if (response.data.status) {
            setAccept(true);

            props.pushAlert("Successfully contract accepted", "success");
          } else {
            props.pushAlert("This link is expired", "warning");
          }
        } else {
          this.props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  
  return (
    props.tenant !== "primary" && (
      <div
        className="container-fluid page404Contract"
        style={{ minHeight: window.screen.height }}
      >
        {loader ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-6   Image404Contract">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 260.000000 280.000000"
                preserveAspectRatio="xMidYMid meet"
              >
                <g
                  transform="translate(0.000000,280.000000) scale(0.100000,-0.100000)"
                  fill="#000000"
                  stroke="none"
                >
                  <path
                    d="M1290 2190 c0 -27 -3 -30 -30 -30 -16 0 -30 -4 -30 -10 0 -5 14 -10
                      30 -10 27 0 30 -3 30 -30 0 -16 5 -30 10 -30 6 0 10 14 10 30 0 27 3 30 30 30
                      17 0 30 5 30 10 0 6 -13 10 -30 10 -27 0 -30 3 -30 30 0 17 -4 30 -10 30 -5 0
                      -10 -13 -10 -30z"
                  />
                  <path
                    d="M857 2002 l-197 -197 58 -58 58 -57 24 25 24 25 33 -32 c19 -18 37
                    -46 42 -63 7 -26 4 -35 -25 -65 -42 -44 -56 -82 -44 -114 11 -30 47 -56 77
                    -56 19 0 23 -6 23 -28 0 -35 37 -72 72 -72 21 0 26 -5 26 -26 0 -34 42 -76 76
                    -76 22 0 26 -5 26 -28 0 -66 90 -93 142 -43 l28 27 28 -27 c54 -52 142 -22
                    142 49 0 20 5 24 28 24 36 0 72 37 72 73 0 23 4 27 28 27 36 0 72 37 72 73 0
                    21 5 27 23 27 30 0 66 26 78 56 11 31 -8 79 -45 113 -37 35 -34 72 11 121 l36
                    41 26 -25 27 -26 57 58 57 57 -200 200 -200 200 -57 -57 -57 -57 27 -29 c26
                    -27 26 -29 10 -44 -37 -32 -64 -38 -169 -38 -100 0 -109 2 -141 26 l-35 26 26
                    27 25 26 -53 58 c-29 31 -55 57 -57 57 -2 0 -93 -89 -202 -198z m1053 -197 c0
                    -6 -18 -29 -40 -50 l-40 -39 -187 187 -188 187 45 45 44 45 183 -182 c101
                    -101 183 -188 183 -193z m-805 325 l39 -40 -187 -187 -187 -187 -40 39 c-22
                    21 -40 44 -40 49 0 14 352 366 365 366 6 0 29 -18 50 -40z m31 -131 c10 -12
                    29 -25 42 -30 22 -8 22 -10 8 -31 -13 -18 -16 -55 -16 -196 l0 -174 32 8 c74
                    16 108 68 108 163 l0 61 83 0 83 0 142 -143 c149 -150 160 -169 122 -207 -22
                    -22 -51 -25 -82 -9 -19 10 -20 9 -13 -12 12 -39 8 -72 -11 -86 -21 -15 -70
                    -17 -79 -3 -4 6 -11 7 -17 4 -6 -4 -6 -10 1 -17 14 -14 14 -52 1 -78 -12 -21
                    -74 -27 -85 -9 -4 6 -11 7 -17 4 -6 -4 -6 -10 1 -17 15 -15 14 -59 -3 -82 -17
                    -24 -69 -24 -96 0 -25 23 -25 30 0 62 39 50 13 117 -51 130 -22 4 -29 11 -29
                    28 0 29 -46 75 -75 75 -17 0 -24 7 -28 29 -8 39 -44 71 -79 71 -16 0 -27 4
                    -23 10 3 5 -1 25 -9 44 -16 39 -47 52 -96 42 -23 -4 -30 -2 -30 9 0 9 -19 36
                    -42 61 l-42 45 135 135 c73 74 136 134 140 134 3 0 14 -10 25 -21z m427 -47
                    l68 -69 -36 -31 c-19 -18 -43 -33 -53 -34 -35 -4 -80 -6 -164 -7 l-88 -1 0
                    -65 c0 -77 -18 -118 -60 -140 -16 -8 -31 -15 -35 -15 -3 0 -5 74 -5 163 0 205
                    -3 200 141 207 91 5 101 8 126 33 15 15 30 27 33 27 3 0 36 -31 73 -68z m179
                    -216 c-9 -11 -23 -27 -31 -35 -7 -8 -19 -28 -25 -43 l-12 -27 -84 84 c-70 70
                    -81 85 -62 85 29 0 71 22 96 49 l20 22 58 -58 c56 -55 57 -58 40 -77z m-716
                    -141 c23 -36 12 -65 -45 -118 -56 -52 -89 -59 -121 -27 -31 31 -24 57 31 116
                    43 44 58 54 85 54 25 0 38 -7 50 -25z m98 -97 c25 -36 19 -56 -35 -114 -39
                    -41 -59 -54 -80 -54 -28 0 -69 30 -69 50 0 23 31 68 69 103 49 44 91 49 115
                    15z m98 -95 c34 -31 23 -65 -41 -126 -33 -32 -67 -57 -76 -57 -21 0 -65 44
                    -65 65 0 9 25 43 57 76 60 63 90 73 125 42z m98 -103 c33 -33 25 -66 -29 -122
                    -39 -41 -55 -51 -81 -51 -38 0 -60 23 -60 63 0 20 15 41 54 79 59 55 85 62
                    116 31z"
                  />
                </g>
              </svg>
            </div>
            <div className="col-6" style={{ paddingTop: "10%" }}>
              {/* <h1 className="head">Hi </h1> */}
              {!accept ? (
                <h1 className="head2 mb-3">Hello !!</h1>
              ) : (
                <h1 className="head2 mb-3">Thank you !!</h1>
              )}
              <h4 className="head2 ">
                <b>{atob(props.match.params.tenantEmail)}</b>
              </h4>
              {!accept ? (
                <p className="span1  pr-5">
                  Thank you for your application. Landlord has approved your
                  application and invite you to accept the lease agreement.
                </p>
              ) : (
                <p className="  ">Your Lease agreement is accepted.</p>
              )}
             
              {!accept ? (
                <button
                  className="pri "
                  onClick={() => {
                    Accept();
                  }}
                >
                  Accept
                </button>
              ) : (
                <button
                  className="pri "
                  onClick={() => props.history.push({ pathname: "/login",state: {
                    sign:true
                  } })}
                >
                  Login
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default withRouter(AcceptContract);
