import React, { Component } from "react";
import "./Occupancy.css";
import chartImage from "../Images/building.png";

class Occupancy extends Component {
  render() {
    return (
      <>
        <div className="BackStatus dashCover  occ">
          <div className="row p-0 ml-1 mb-2">
            <div className="col response">
              <h3 className="text-dark p-3 cardHead">Occupancy</h3>
            </div>
            <div className="col response ">
              <span className=" text-right occupied">
                {this.props.occupied}
              </span>
            </div>
          </div>

          <div className="row p-0 ml-1 mb-2">
            <div className="col response">
              <div className="Bar">
                <div className="colorBox"></div>

                <div className={this.getHeightColor()}>
                  {" "}
                  <img src={chartImage} alt="Building" className="imageBox" />
                </div>
                <div className={this.getLineHeight()}></div>
              </div>
            </div>
            <div className="col-2 ">
              <h6 className="textSize"> </h6>
              <h6 className="textSize"> </h6>
              <h6 className="textSize"> </h6>
              <h6 className="textSize">{this.indicator(100)}</h6>
              <h6 className="textSize">{this.indicator(90)}</h6>
              <h6 className="textSize">{this.indicator(80)}</h6>
              <h6 className="textSize">{this.indicator(70)}</h6>
              <h6 className="textSize">{this.indicator(60)}</h6>
              <h6 className="textSize">{this.indicator(50)}</h6>
              <h6 className="textSize">{this.indicator(40)}</h6>
              <h6 className="textSize">{this.indicator(30)}</h6>
              <h6 className="textSize">{this.indicator(20)}</h6>
              <h6 className="textSize">{this.indicator(10)}</h6>
              <h6 className="textSize">{this.indicator(0)}</h6>
            </div>
          </div>
        </div>
      </>
    );
  }
  getLineHeight() {
    return "colorLine" + this.getHeight();
  }
  getHeightColor() {
    return "colorBox colorChanger Box" + this.getHeight();
  }
  getHeight() {
    let percentage =
      (parseInt(this.props.occupied) / parseInt(this.props.total)) * 100;
    if (percentage > 95) {
      return " height100 ";
    } else if (percentage > 90) {
      return " height95";
    } else if (percentage > 85) {
      return " height90";
    } else if (percentage > 80) {
      return " height85";
    } else if (percentage > 75) {
      return " height80";
    } else if (percentage > 70) {
      return " height75";
    } else if (percentage > 65) {
      return " height70";
    } else if (percentage > 60) {
      return " height65";
    } else if (percentage > 55) {
      return " height60";
    } else if (percentage > 50) {
      return " height55";
    } else if (percentage > 45) {
      return " height50";
    } else if (percentage > 40) {
      return " height45";
    } else if (percentage > 35) {
      return " height40";
    } else if (percentage > 30) {
      return " height35";
    } else if (percentage > 25) {
      return " height30";
    } else if (percentage > 20) {
      return " height25";
    } else if (percentage > 15) {
      return " height20";
    } else if (percentage > 10) {
      return " height15";
    } else if (percentage > 5) {
      return " height10";
    } else if (percentage > 0) {
      return " height05";
    } else {
      return " height00";
    }
  }
  indicator(value) {
    return (parseInt(this.props.total) * value) / 100;
  }
}
export default Occupancy;
