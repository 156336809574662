import React, { Component } from "react";
import "./contracts.css";
import { withRouter } from "react-router-dom";
import CookieService from "../../../Services/CookieService";
import  ContractLandlord  from "./ContractLandlord/ContractLandlord";
import  ContractTenant  from "./ContractTenant/ContractTenant";
class Contracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "APPROVED",
      contractData: [],
      role: this.props.role,
      user: CookieService.getArrayCookie("axUserDetails"),
      email: "",
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    await this.setState({
      role: CookieService.getArrayCookie("axUserDetails"),
    });
  }

  render() {
    if (this.state.role !== null) {
      if (this.props.role === "Landlord") {
        return  (
          <ContractLandlord refresh={this.props.location.state
            ? this.props.location.state.refresh
            : null}/>
        );
      } else if (this.props.role === "Tenant") {
        return  (
          <ContractTenant/>
        );
      } else {
        return <h1 style={{ paddingTop: "70px" }}>Something went wrong</h1>;
      }
    } else {
      window.location.reload();
    }
  }
}

export default withRouter(Contracts);
