import React, { Component } from "react";
import "../application.css";
import "../dropdownForm.css";
import {
  getPropertyLists,
  getRentalListsImages,
  url,
} from "../../../../Services/ApiServices";
import Loader3 from "../../../Loader/loader3";
import coverImage from "../../../../Images/no-cover-img2.svg";
import { connect } from "react-redux";
import {
  appendAlert,
  appendTempListingData,
  setApplication,
} from "../../../../actions";
import { withRouter } from "react-router-dom";
import ContractUserDetail from "./ContractUserDetail";
import { addressFormatter } from "../../../../Defined/Function";
class ApplicationLandlordList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingData: [],
      showModal: false,
      modalData: {},
      menu: 0,
      status: "RECEIVED",
      applicationData: this.props.data.applications,
      listImage: "",
      tab: 0,
      failFetching: false,
    };
  }
  componentDidMount() {
    let listing = this.props.tempListingArray.filter(
      (todo) => todo.rentalEntity.id === this.props.data.rentalEntityId
    );

    if (listing.length === 0) {
      this.getPropList();
    } else {
      this.setState({ listingData: listing[0] });
      this.setState({
        failFetching: false,
      });
      this.getPropList();
    }
    try {
      getRentalListsImages(this.props.data.rentalEntityId).then(
        (responseImage) => {
          if (responseImage.status === 200) {
            if (responseImage.data.length !== 0) {
              this.setState({
                listImage:
                  url +
                  "api/property/" +
                  this.props.data.rentalEntityId +
                  "/" +
                  responseImage.data[0] +
                  "/image/downloadByName",
                spinner: false,
              });
            }
          } else {
            if (responseImage.data !== undefined) {
              this.props.appendAlert(responseImage.data.status, "danger");
            } else {
              this.props.appendAlert(
                responseImage.response.data.error,
                "danger"
              );
            }

            this.props.appendAlert(
              "Application cancellation failed !!!",
              "danger"
            );
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
    if (
      this.state.applicationData.filter((app) => app.status !== "APPROVED")
        .length === 0
    ) {
      if (
        this.state.applicationData.filter((app) => !app.contractCreated)
          .length === 0
      ) {
        this.setState({ status: "CONVERT_AS_CONTRACT" });
      } else {
        this.setState({ status: "READY_FOR_CONTRACT" });
      }
    }
  }
  async getPropList() {
    try {
      await getPropertyLists(this.props.data.rentalEntityId)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.setState({
                listingData: response.data,
                failFetching: false,
              });
              this.props.appendTempListingData(response.data);
            } else {
              console.log("Error in processing.Try again later!");
            }
          } else {
            this.setState({ failFetching: true });
          }
        })
        .catch(() => {
          this.setState({ failFetching: true });
        });
    } catch (error) {
      console.error(error);
    }
  }
  menuStyle(mode) {
    let classes = "contractCardDetail shadow2 ";
    classes +=
      this.state.menu === mode
        ? "showMenu contractCardDetailPaddingBottom"
        : "hideMenu";
    return classes;
  }
  nowListLength() {
    return this.state.status === "RECEIVED"
      ? this.state.applicationData.filter((app) => app.status !== "APPROVED")
          .length
      : this.state.status === "READY_FOR_CONTRACT"
      ? this.state.applicationData.filter(
          (app) => app.status === "APPROVED" && !app.contractCreated
        ).length
      : this.state.status === "CONVERT_AS_CONTRACT" &&
        this.state.applicationData.filter(
          (app) => app.status === "APPROVED" && app.contractCreated
        ).length;
  }
  render() {
    return this.state.failFetching ? (
      <></>
    ) : this.state.listingData.length === 0 ? (
      <div className="p-5" style={{ marginTop: "10px" }}>
        <Loader3 />
      </div>
    ) : (
      this.state.applicationData && (
        <>
          <div
            className={
              this.props.isDark
                ? "row applicationCardDark "
                : "row applicationCard "
            }
          >
            <div
              className="col-3 applicationListImage1  justify-content-center"
              style={{
                backgroundImage:
                  "url(" +
                  (this.state.listImage === ""
                    ? coverImage
                    : this.state.listImage) +
                  ")",
                borderBottomLeftRadius: "15px",
                borderTopLeftRadius: "15px",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <span className="roundNumberBadge align-self-center">
                {this.nowListLength()}
              </span>
            </div>
            <div
              className="col-12 applicationListImage2  justify-content-center"
              style={{
                backgroundImage:
                  "url(" +
                  (this.state.listImage === ""
                    ? coverImage
                    : this.state.listImage) +
                  ")",
                height: "200px",
                borderTopRightRadius: "15px",
                borderTopLeftRadius: "15px",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <span className="roundNumberBadge align-self-center">
                {" "}
                {this.nowListLength()}
              </span>
            </div>
            <div className="col pb-0 mb-0 pl-0 pr-0 ">
              <div className="row pt-2 pb-2 contractDivider ml-1 mr-0">
                <div className="col-md-2 col-lg-2 applicationListAlign ">
                  <p
                    className={
                      this.props.isDark ? "contractTextDark" : "contractText "
                    }
                    style={{ fontWeight: "bold" }}
                  >
                    Unit
                  </p>
                  <p
                    className={
                      this.props.isDark ? "contractTextDark" : "contractText "
                    }
                  >
                    {this.state.listingData.rentalEntity &&
                      (this.state.listingData.rentalEntity.unitName
                        ? this.state.listingData.rentalEntity.unitName
                        : " - ")}
                  </p>
                </div>
                <div className=" col-md applicationListAlign">
                  <p
                    className={
                      this.props.isDark ? "contractTextDark" : "contractText "
                    }
                    style={{ fontWeight: "bold" }}
                  >
                    Address
                  </p>
                  <p
                    className={
                      this.props.isDark ? "contractTextDark" : "contractText "
                    }
                  >
                    {addressFormatter(this.state.listingData.address)}
                  </p>
                </div>

                <div className="col-lg-2 mt-3 applicationListAlign2">
                  <button
                    className={
                      this.state.tab !== 0
                        ? this.props.isDark
                          ? "roundButtonDark mr-2"
                          : "roundButton mr-2"
                        : this.props.isDark
                        ? "roundButtonDarkDisable mr-2"
                        : "roundButtonDisable mr-2"
                    }
                    data-placement="top"
                    title="Preview"
                    disabled={this.state.tab === 0}
                    onClick={() => this.setState({ tab: this.state.tab - 1 })}
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </button>
                  <button
                    className={
                      Math.round(this.nowListLength() / 5) > this.state.tab + 1
                        ? this.props.isDark
                          ? "roundButtonDark "
                          : "roundButton "
                        : this.props.isDark
                        ? "roundButtonDarkDisable"
                        : "roundButtonDisable"
                    }
                    data-placement="top"
                    title="Next"
                    disabled={
                      Math.round(this.nowListLength() / 5) <= this.state.tab + 1
                    }
                    onClick={() => this.setState({ tab: this.state.tab + 1 })}
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div className="row  p-0 m-0">
                <div className="col-md col-lg  p-0 m-0">
                  {this.nowListLength() > 5 && (
                    <div
                      className="progress pb-0 mb-0"
                      style={{ height: "1px" }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width:
                            ((this.state.tab + 1) /
                              Math.round(this.nowListLength() / 5)) *
                              100 +
                            "%",
                          backgroundColor: "#EC7063",
                        }}
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  )}
                </div>
              </div>

              <div className=" tabAppList">
                {this.state.status === "RECEIVED" ? (
                  <span
                    className="buttonTabLineSec "
                    onClick={() => this.setState({ status: "RECEIVED" })}
                  >
                    Received
                  </span>
                ) : (
                  <span
                    className="buttonTabSec"
                    onClick={() => this.setState({ status: "RECEIVED" })}
                  >
                    Received
                  </span>
                )}
                {this.state.status === "READY_FOR_CONTRACT" ? (
                  <span
                    className="buttonTabLineSec"
                    onClick={() =>
                      this.setState({ status: "READY_FOR_CONTRACT" })
                    }
                  >
                    SELECTED
                  </span>
                ) : (
                  <span
                    className="buttonTabSec"
                    onClick={() =>
                      this.setState({ status: "READY_FOR_CONTRACT" })
                    }
                  >
                    SELECTED
                  </span>
                )}

                {this.state.status === "CONVERT_AS_CONTRACT" ? (
                  <span
                    className="buttonTabLineSec"
                    onClick={() =>
                      this.setState({ status: "CONVERT_AS_CONTRACT" })
                    }
                  >
                    CONVERTED
                  </span>
                ) : (
                  <span
                    className="buttonTabSec"
                    onClick={() =>
                      this.setState({ status: "CONVERT_AS_CONTRACT" })
                    }
                  >
                    CONVERTED
                  </span>
                )}
              </div>

              <div className="row mt-3 pb-0 tenantListAlign">
                <div className="col-md col-lg ">
                  {this.state.status === "RECEIVED"
                    ? this.state.applicationData
                        .filter((app) => app.status !== "APPROVED")
                        .map(
                          (data, index) =>
                            Math.round((index - 2) / 5) === this.state.tab && (
                              <ContractUserDetail
                                application={data}
                                listingData={this.state.listingData}
                                modalData={data}
                                status={true}
                              />
                            )
                        )
                    : this.state.status === "READY_FOR_CONTRACT"
                    ? this.state.applicationData
                        .filter(
                          (app) =>
                            app.status === "APPROVED" && !app.contractCreated
                        )
                        .map(
                          (data, index) =>
                            Math.round((index - 2) / 5) === this.state.tab && (
                              <ContractUserDetail
                                application={data}
                                listingData={this.state.listingData}
                                modalData={data}
                                status={false}
                              />
                            )
                        )
                    : this.state.status === "CONVERT_AS_CONTRACT" &&
                      this.state.applicationData
                        .filter(
                          (app) =>
                            app.status === "APPROVED" && app.contractCreated
                        )
                        .map(
                          (data, index) =>
                            Math.round((index - 2) / 5) === this.state.tab && (
                              <ContractUserDetail
                                application={data}
                                listingData={this.state.listingData}
                                modalData={data}
                                status={false}
                                
                              />
                            )
                        )}
                </div>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    dark: state.darkReducer,
    isDark: state.isDark,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    application: state.applicationReducer,
    applicationArray: state.application,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    appendTempListingData,
    setApplication,
  })(ApplicationLandlordList)
);
