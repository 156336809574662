import React, { Component } from "react";
import {
  exceptNameNull,
  exceptNull,
  removeDuplicate,
  sortText,
} from "../../../../Defined/Function";
import "./MaintenanceRequestProvider.css";
import image1 from "../../../../Images/profile.png";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { setProvider } from "../../../../actions";

class MaintenanceRequestProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: [],
      expand: false,
      selectedSkill: "All",
    };
  }
  filterProvider() {
    if (this.state.expand) {
      return this.getViewArray();
    } else {
      return this.getViewArray().slice(0, 6);
    }
  }
  getViewArray() {
    return this.props.providerArray.filter((element) => {
      return (
        this.checkSkill(element.skills)
      );
    });
  }

  checkSkill(arr) {
    var array = arr.split(",");
    if (this.state.selectedSkill === "All") return true;
    return (
      array.filter((element) => {
        return element === this.state.selectedSkill;
      }).length > 0
    );
  }
  filterSkills() {
    var skills = "";
    this.props.providerArray.forEach((element) => {
      skills = skills + "," + element.skills;
    });
    var skillsArr = skills.split(",");
    return removeDuplicate(skillsArr).filter((element) => {
      return element !== "";
    });
  }
  checkBottomBorder(index) {
    if (
      this.filterProvider().length % 2 === 0 &&
      (this.filterProvider().length - 1 === index ||
        this.filterProvider().length - 2 === index)
    ) {
      return false;
    } else if (
      this.filterProvider().length % 2 !== 0 &&
      this.filterProvider().length - 1 === index
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      this.props.providerArray && (
        <div className="maintenanceRequestCard mt-3 pr-0">
          <div className="pr-4">
            <span className="maintenanceRequestDetailText1 ">
              Service Provider
            </span>
            {this.getViewArray().length > 6 && (
              <>
                <span
                  className="maintenanceRequestDetailText3 pointer pull-right pt-1"
                  onClick={() => this.setState({ expand: !this.state.expand })}
                >
                  {this.state.expand ? "See less" : "See more"}
                </span>
                <span className="maintenanceRequestDetailText1  pull-right requestProviderDivider pt-1">
                  |
                </span>
              </>
            )}
            <span
              className="maintenanceRequestDetailText3 pointer  pull-right pt-1"
              data-target="#AddProvider"
              data-toggle="modal"
              onClick={() => this.props.addProvider()}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add Provider
            </span>
          </div>
          <div className="requestProviderText pr-4">
            <div className={"row maintenanceProviderPartContainer "}>
              {this.props.providerArray !== null &&
                this.filterProvider().map((data, index) => (
                  <div
                    className={
                      this.checkBottomBorder(index)
                        ? "maintenanceProviderPart"
                        : " maintenanceProviderPart2"
                    }
                  >
                    <div
                      className={
                        index % 2 === 0
                          ? "row pl-3 "
                          : "row borderProviderLeftGray pl-3"
                      }
                    >
                      <div className="maintenanceProviderPartImageAlign ">
                        <img
                          alt="tenant"
                          src={image1}
                          className="maintenanceRequestProviderPartImage"
                        />
                      </div>
                      <div className=" maintenanceProviderPartNameAlign">
                        <h1 className="maintenanceRequestText2 textLeft">
                          {sortText(
                            exceptNameNull(data.firstName, data.lastName),
                            0,
                            17
                          )}
                        </h1>
                        <h3 className="maintenanceRequestText1 textLeft">
                          {sortText(exceptNull(data.skills), 0, 17)}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="row">
            <div className="skillBudgeCollection  ">
              <label
                className={
                  this.state.selectedSkill === "All"
                    ? " activeSkillBudge"
                    : "skillBudge"
                }
                onClick={() => this.setState({ selectedSkill: "All" })}
              >
                All
              </label>
              {this.filterSkills().map((data) => (
                <label
                  className={
                    this.state.selectedSkill === data
                      ? " activeSkillBudge"
                      : "skillBudge"
                  }
                  onClick={() => this.setState({ selectedSkill: data })}
                >
                  {data}
                </label>
              ))}
            </div>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    provider: state.providerReducer,
    providerArray: state.provider,
    user: state.userReducer,
    userDetailArray: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    setProvider,
  })(MaintenanceRequestProvider)
);
