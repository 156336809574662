import React, { Component } from "react";
import "./maintenance.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { appendAlert, setMaintenance, setProvider } from "../../../actions";

import ApplicationInput from "../../UIServices/ApplicationInput";
import Dropzone from "react-dropzone-uploader";
import {
  createMaintenanceRequest,
  getMaintenanceRequest,
  getProviders,
  uploadMaintenanceRequest,
} from "../../../Services/ApiServices";
import Gallery from "react-grid-gallery";
import Loader2 from "../../Loader/Loader2";
import Loader3 from "../../Loader/loader3";
import Loader from "../../Loader/Loader";
import TimeInput from "../../UIServices/TimeInput";
import SmallModalContainer from "../Documents/SmallModalContainer";
import { addressFormatter, convertGMT, today } from "../../../Defined/Function";
import MaintenanceRequestCard from "./MaintenanceShowCase/MaintenanceRequestCard";
import MaintenanceTaskCard from "./MaintenanceShowCase/MaintenanceTaskCard";
import MaintenanceHistoryCard from "./MaintenanceShowCase/MaintenanceHistoryCard";

class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractId:
        this.props.location.state !== undefined
          ? this.props.location.state.contractId !== undefined
            ? this.props.location.state.contractId
            : 0
          : 0,
      activeContractDetails: [],
      activeUnitDetails: [],
      coverImage: [],
      next: false,
      catFinder: 0,
      category: "",
      subCategory: "",
      description: "",
      newRequestModal: false,
      newRequestModalLoader: false,
      appointmentDate: "",
      appointmentAnotherDate: "",
      appointmentTime: "",
      appointmentTime1: "",
      appointmentAnotherTime: "",
      appointmentAnotherTime1: "",
      requestExpand: false,
      taskExpand: false,
      historyExpand: false,
      maintenanceRequest: [
        "Plumbing",
        "Electrical",
        "Handyman",
        "HVAC",
        "Pest Control",
      ],
      cat: [
        [],
        [
          "Drain Repair",
          "Slink Replacement",
          "Plumbing Service",
          "Bathroom Repair",
          "Faucet Repair",
        ],

        [
          "Lights",
          "Wiring",
          "Lights",
          "Circuit breaker panel or fuse box",
          "Switches",
          "Ceiling fan",
        ],

        [
          "Broken Door",
          "Broken Windows",
          "Walls(inside)",
          "Walls(outside)",
          "Flooring",
          "Cabinets Repair",
        ],

        ["Heater repair", "Ventilation", "Air conditioner repair"],
        ["Insect Removal", "Rodent Elimination", "Bird Prevention"],
      ],
      currentImage: "",
      deleteImage: false,
      sendQuotationTimeDiff: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.createRequest = this.createRequest.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeTime = this.onChangeTime.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    this.loadMaintenance();
    this.loadProviders();
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.contractId !== undefined) {
        let contractData = [];
        let unitData = [];
        if (parseInt(this.props.location.state.contractId) !== 0) {
          if (this.props.contractArray !== null) {
            contractData = this.props.contractArray.filter(
              (contract) =>
                contract.contractId ===
                parseInt(this.props.location.state.contractId)
            );
            contractData = contractData[0];
          }
        }
        unitData = this.props.tempListingArray.filter(
          (unit) => unit.rentalEntity.id === contractData.rentalEntityId
        );
        unitData = unitData[0];
        this.setState({
          activeContractDetails: contractData,
          activeUnitDetails: unitData,
        });
      }
    }
  }
  async handleChange(event) {
    let { name, value } = event.target;
    if (name === "category") {
      if (value === "Plumbing") {
        this.setState({
          catFinder: 1,
        });
      } else if (value === "Electrical") {
        this.setState({
          catFinder: 2,
        });
      } else if (value === "Handyman") {
        this.setState({
          catFinder: 3,
        });
      } else if (value === "HVAC") {
        this.setState({
          catFinder: 4,
        });
      } else if (value === "Pest Control") {
        this.setState({
          catFinder: 5,
        });
      }
    } else if (name === "contractId") {
      let contractData = [];
      let unitData = [];
      if (parseInt(value) !== 0) {
        contractData = this.props.contractArray.filter(
          (contract) => contract.contractId === parseInt(value)
        );
        contractData = contractData[0];
      }
      unitData = this.props.tempListingArray.filter(
        (unit) => unit.rentalEntity.id === contractData.rentalEntityId
      );
      unitData = unitData[0];
      this.setState({
        activeContractDetails: contractData,
        activeUnitDetails: unitData,
      });
    }
    await this.setState({
      [name]: value,
    });
  }
  onChangeDate(name, value) {
    this.setState({ [name]: value });
  }
  async onChangeTime(id, name, value, value1) {
    this.setState({ [id]: value, [id + "1"]: value1 });
  }
  checkValidate(value) {
    return value === "" && this.state.next;
  }
  createRequest() {
    if (
      this.state.description !== "" &&
      this.state.category !== "" &&
      this.state.subCategory !== "" &&
      this.state.coverImage.length !== 0
    ) {
      this.setState({ newRequestModalLoader: true });
      let tenantAvailabilityTimes = [];
      if (this.state.appointmentDate !== "") {
        tenantAvailabilityTimes.push({
          date: convertGMT(
            this.state.appointmentDate + "T" + this.state.appointmentTime
          ),
          dayOfWeek: "MONDAY",
          time: this.state.appointmentTime,
        });
      }
      if (this.state.appointmentAnotherDate !== "") {
        tenantAvailabilityTimes.push({
          date: convertGMT(
            this.state.appointmentAnotherDate +
              "T" +
              this.state.appointmentAnotherTime
          ),
          dayOfWeek: "MONDAY",
          time: this.state.appointmentAnotherTime,
        });
      }
      try {
        let body = {
          tenantId: this.props.userDetail.username,
          landlordId: this.state.activeContractDetails.landLordId,
          contractId: parseInt(this.state.contractId),
          rentalEntityId: this.state.activeContractDetails.rentalEntityId,
          category: this.state.category,
          subCategory: this.state.subCategory,
          description: this.state.description,
          tenantAvailablityTimes: tenantAvailabilityTimes,
        };
        createMaintenanceRequest(body).then((response) => {
          if (response.status === 200) {
            if (response.data.status) {
              this.props.appendAlert(
                "Maintenance request sent successfully",
                "success"
              );
              this.uploadImage(response.data.id);
              this.loadMaintenance();
            } else {
              this.props.appendAlert("Something went wrong!", "danger");
            }
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        });
      } catch (error) {}
    } else {
      console.log("error");
      this.setState({ next: true });
    }
  }

  async uploadImage(id) {
    if (this.state.coverImage.length !== 0) {
      this.state.coverImage.forEach((element, index) => {
        const formData = new FormData();
        formData.append("fileName", "maintenanceRequest");
        formData.append("category", "test");
        formData.append("uploadBy", this.props.userDetail.username);
        formData.append("fileType", element.file.type);
        formData.append("tenantAccess", true);
        formData.append("landlordAccess", true);
        formData.append("deleted", true);
        formData.append("version", 0);
        formData.append("document", element.file);
        uploadMaintenanceRequest(formData, id)
          .then((response) => {
            if (response.status === 200) {
              if (this.state.coverImage.length - 1 === index) {
                this.loadMaintenance();
              }
            } else {
              this.loadMaintenance();
              this.props.appendAlert(
                "Something went wrong!, Please try again later.",
                "danger"
              );
            }
          })
          .catch((err) => {
            this.props.appendAlert("Image upload failed", "danger");
            console.log(err);
          });
      });
      window.$("#MaintenanceRequest").modal("hide");
      this.setState({ newRequestModal: false, newRequestModalLoader: false });
    } else {
      window.$("#MaintenanceRequest").modal("hide");
      this.loadMaintenance();
      this.setState({ newRequestModal: false, newRequestModalLoader: false });
    }
  }
  async handleUpload(files) {
    files.forEach((file) => {
      if (this.state.coverImage.indexOf(file) < 0) {
        this.setState((state) => ({
          coverImage: state.coverImage.concat([file]),
        }));
      }
    });
  }
  async loadMaintenance() {
    try {
      getMaintenanceRequest().then(async(response) => {
        if (response.status === 200) {
          response.data = response.data.sort((a, b) =>b.updatedAt.localeCompare(a.updatedAt));
          await this.props.setMaintenance(response.data);
          this.props.setMaintenance(response.data);
        } else {
          this.props.setMaintenance([]);
        }
      });
    } catch (error) {}
  }
  async loadProviders() {
    if (this.props.roleStatus === "Landlord") {
      try {
        getProviders(this.props.userDetail.username).then(async (response) => {
          if (response.status === 200) {
            await this.props.setProvider(null);
            this.props.setProvider(response.data.reverse());
          } else {
            this.props.setProvider([]);
          }
        });
      } catch (error) {}
    }
  }
  handleChangeStatus = ({ file }, status, allFiles) => {
    if (status === "preparing") {
      this.handleUpload(allFiles);
    }
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
    }
  };
  getUnit(contract, index) {
    let unit = this.props.tempListingArray.filter(
      (unit) => unit.rentalEntity.id === contract.rentalEntityId
    );
    if (unit !== null) {
      if (unit.length > 0) {
        return (
          <option
            value={contract.contractId}
            selected={
              contract.contractId === parseInt(this.state.contractId) && "selected"
            }
            key={"maintenance"+this.state.contractId+"_"+index}
          >
            {addressFormatter(unit[0].address)}
          </option>
        );
      }
    }
  }
  newRequest() {
    if (parseInt(this.state.contractId) !== 0) {
      this.setState({ newRequestModal: true });
    } else {
      if (this.props.contractArray.length > 0) {
        this.props.appendAlert(
          "Please select the contract property location",
          "warning"
        );
      } else {
        this.props.appendAlert(
          "You don't have any property contract",
          "warning"
        );
      }
    }
  }
  filterContract(data) {
    return this.props.contractArray.filter(
      (contract) => parseInt(contract.contractId) === parseInt(data.contractId)
    )[0];
  }
  filterUnit(data) {
    return this.props.tempListingArray.filter(
      (unit) =>
        unit.rentalEntity.id === this.filterContract(data).rentalEntityId
    )[0];
  }
  onCurrentImageChange(index) {
    this.setState({ currentImage: index });
  }
  filterMaintenanceRequestCount() {
    if (this.props.maintenanceArray) {
      return this.props.maintenanceArray.filter(
        (data) =>
          (parseInt(this.state.contractId) === 0 &&
            data.landlordApprovel === "pending" &&
            data.status === "incomplete") ||
          (parseInt(this.state.contractId) === parseInt(data.contractId) &&
            data.landlordApprovel === "pending" &&
            data.status === "incomplete")
      ).length;
    } else {
      return 0;
    }
  }
  filterMaintenanceRequest() {
    if (this.props.maintenanceArray) {
      if (this.state.requestExpand) {
        return this.props.maintenanceArray.filter(
          (data) =>
            (parseInt(this.state.contractId) === 0 &&
              data.landlordApprovel === "pending" &&
              data.status === "incomplete") ||
            (parseInt(this.state.contractId) === parseInt(data.contractId) &&
              data.landlordApprovel === "pending" &&
              data.status === "incomplete")
        );
      } else {
        return this.props.maintenanceArray
          .filter(
            (data) =>
              (parseInt(this.state.contractId) === 0 &&
                data.landlordApprovel === "pending" &&
                data.status === "incomplete") ||
              (parseInt(this.state.contractId) === parseInt(data.contractId) &&
                data.landlordApprovel === "pending" &&
                data.status === "incomplete")
          )
          .slice(0, 3);
      }
    } else {
      return [];
    }
  }
  filterMaintenanceTaskCount() {
    if (this.props.maintenanceArray) {
      return this.props.maintenanceArray.filter(
        (data) =>
          (parseInt(this.state.contractId) === 0 &&
            data.landlordApprovel !== "pending" &&
            data.status === "incomplete") ||
          (parseInt(this.state.contractId) === parseInt(data.contractId) &&
            data.landlordApprovel !== "pending" &&
            data.status === "incomplete")
      ).length;
    } else {
      return 0;
    }
  }
  filterMaintenanceTask() {
    if (this.props.maintenanceArray) {
      if (this.state.taskExpand) {
        return this.props.maintenanceArray.filter(
          (data) =>
            (parseInt(this.state.contractId) === 0 &&
              data.landlordApprovel !== "pending" &&
              data.status === "incomplete") ||
            (parseInt(this.state.contractId) === parseInt(data.contractId) &&
              data.landlordApprovel !== "pending" &&
              data.status === "incomplete")
        );
      } else {
        return this.props.maintenanceArray
          .filter(
            (data) =>
              (parseInt(this.state.contractId) === 0 &&
                data.landlordApprovel !== "pending" &&
                data.status === "incomplete") ||
              (parseInt(this.state.contractId) === parseInt(data.contractId) &&
                data.landlordApprovel !== "pending" &&
                data.status === "incomplete")
          )
          .slice(0, 3);
      }
    } else {
      return [];
    }
  }

  filterMaintenanceHistoryCount() {
    if (this.props.maintenanceArray) {
      return this.props.maintenanceArray.filter(
        (data) =>
          (parseInt(this.state.contractId) === 0 &&
            (data.status === "complete" || data.status === "canceled")) ||
          (parseInt(this.state.contractId) === parseInt(data.contractId) &&
            (data.status === "complete" || data.status === "canceled"))
      ).length;
    } else {
      return 0;
    }
  }
  filterMaintenanceHistory() {
    if (this.props.maintenanceArray) {
      if (this.state.historyExpand) {
        return this.props.maintenanceArray.filter(
          (data) =>
            (parseInt(this.state.contractId) === 0 &&
              (data.status === "complete" || data.status === "canceled")) ||
            (parseInt(this.state.contractId) === parseInt(data.contractId) &&
              (data.status === "complete" || data.status === "canceled"))
        );
      } else {
        return this.props.maintenanceArray
          .filter(
            (data) =>
              (parseInt(this.state.contractId) === 0 &&
                (data.status === "complete" || data.status === "canceled")) ||
              (parseInt(this.state.contractId) === parseInt(data.contractId) &&
                (data.status === "complete" || data.status === "canceled"))
          )
          .slice(0, 3);
      }
    } else {
      return [];
    }
  }
  async deleteImage() {
    if (this.state.coverImage.length >= this.state.currentImage) {
      if (this.state.coverImage[this.state.currentImage].file) {
        let images = this.state.coverImage.slice();
        images.splice(this.state.currentImage, 1);
        this.setState({ coverImage: images });
        document.querySelector(".close_1x3s325").click();
      }
    } else {
      this.props.appendAlert("Error in deleting.Try again later!", "danger");
    }
  }
  render() {
    let imgList = [];
    this.state.coverImage.forEach((img) => {
      imgList.push({
        src: URL.createObjectURL(img.file),
        thumbnail: URL.createObjectURL(img.file),
        thumbnailWidth: img.meta.width / 2,
        thumbnailHeight: img.meta.height / 2,
      });
    });
    return (
      <div className="container-fluid maintenanceRequestContainer">
        {this.props.contractArray === null ||
        this.props.tempListingArray === null ? (
          <div className="col-12  mb-5 ">
            <Loader />
          </div>
        ) : (
          <>
            <div className="row maintenanceRequestAlign maintenanceRequestHeaderAlign pr-3 pl-3">
              <div className="col-md-2 squareDivPri ">Property Location</div>
              <div className="col-md maintenanceInputSelectAlign">
                <select
                  className={"maintenanceInputSelect custom-select"}
                  onChange={this.handleChange}
                  name={"contractId"}
                  id={"contractId"}
                >
                  <option value={0} selected="selected">
                    All property
                  </option>
                  {this.props.contractArray
                    .filter((con) => con.status === "ACTIVE")
                    .map((contract, index) => this.getUnit(contract, index))}
                </select>
              </div>
              <div className="col-md-2 squareDivPri2 textUpperCase ">
                24 X 7 Support
              </div>
            </div>
            <div className="row maintenanceRequestAlign maintenanceRequestHeaderAlign2 ">
              <div className="col-12 ">
                <div className=" squareDivPri2 textUpperCase mb-4 mr-3 ml-3">
                  24 X 7 Support
                </div>
              </div>
              <div className="col-12 maintenanceInputSelectAlign">
                <select
                  className={"maintenanceInputSelect custom-select"}
                  onChange={this.handleChange}
                  name={"contractId"}
                  id={"contractId"}
                >
                  <option value={0} selected="selected">
                    All property
                  </option>
                  {this.props.contractArray
                    .filter((con) => con.status === "ACTIVE")
                    .map((contract, index) => this.getUnit(contract, index))}
                </select>
              </div>
            </div>
            <hr className="line "></hr>
            <div className="row maintenanceRequestAlign mt-4 ">
              <div className="col-md-6 ">
                <h1 className={"axText0"}>
                  Requests{" "}
                  {this.filterMaintenanceRequestCount() > 0 && (
                    <label className="maintenanceRequestCardCount">
                      {this.filterMaintenanceRequestCount()}
                    </label>
                  )}
                </h1>
              </div>
              <div className="col  pt-1">
                {this.props.roleStatus === "Tenant" && (
                  <h3
                    className="AxText8 pull-right textUpperCase pointer"
                    data-target="#MaintenanceRequest"
                    data-toggle="modal"
                    onClick={() => this.newRequest()}
                  >
                    New Request
                  </h3>
                )}
              </div>
            </div>
            <div className="row maintenanceRequestAlign ">
              {this.props.maintenanceArray === null ? (
                <div className="col-12 mb-5">
                  <Loader2 />
                </div>
              ) : this.filterMaintenanceRequestCount() > 0 ? (
                this.filterMaintenanceRequest().map((data,index) => (
                  <MaintenanceRequestCard
                    request={data}
                    contractData={this.filterContract(data)}
                    unitData={this.filterUnit(data)}

                    key={"MaintenanceRequestCard"+index}
                  />
                ))
              ) : (
                <div className="col  text-center mt-3 mb-3">
                  <span className="span2  ">
                    You have no maintenance requests
                  </span>
                </div>
              )}
              {this.filterMaintenanceRequestCount() > 3 ? (
                <div className="col-12 requestShowMore  ">
                  {this.state.requestExpand ? (
                    <p
                      className="  text-right"
                      onClick={() => this.setState({ requestExpand: false })}
                    >
                      Show Less{" "}
                      <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    </p>
                  ) : (
                    <p
                      className="  text-right"
                      onClick={() => this.setState({ requestExpand: true })}
                    >
                      Show More{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </p>
                  )}
                </div>
              ): <div className="col-12 requestShowMore  "><br></br><br></br></div>}
            </div>
            <div className="row maintenanceRequestAlign ">
              <div className="col-md ">
                <h1 className={"axText0"}>
                  Tasks{" "}
                  {this.filterMaintenanceTaskCount() > 0 && (
                    <label className="maintenanceRequestCardCount">
                      {this.filterMaintenanceTaskCount()}
                    </label>
                  )}
                </h1>
              </div>
            </div>
            <div className="row maintenanceRequestAlign ">
              {this.props.maintenanceArray === null ? (
                <div className="col-12 mb-5">
                  <Loader2 />
                </div>
              ) : this.filterMaintenanceTaskCount() > 0 ? (
                this.filterMaintenanceTask().map((data,index) => (
                  <MaintenanceTaskCard
                    request={data}
                    contractData={this.filterContract(data)}
                    unitData={this.filterUnit(data)}
                    key={"MaintenanceTaskCard"+index}
                  />
                ))
              ) : (
                <div className="col text-center  mt-3 mb-3">
                  <span className="span2  ">You have no maintenance tasks</span>
                </div>
              )}
              {this.filterMaintenanceTaskCount() > 3 ? (
                <div className="col-12 requestShowMore ">
                  {this.state.taskExpand ? (
                    <p
                      className="text-right"
                      onClick={() => this.setState({ taskExpand: false })}
                    >
                      Show Less{" "}
                      <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    </p>
                  ) : (
                    <p
                      className="text-right"
                      onClick={() => this.setState({ taskExpand: true })}
                    >
                      Show More{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </p>
                  )}
                </div>
              ): <div className="col-12 requestShowMore  "><br></br><br></br></div>}
            </div>
            <div className="row maintenanceRequestAlign mb-3">
              <div className="col-md ">
                <h1 className={"axText0"}>History</h1>
              </div>
            </div>
            <div className="row maintenanceRequestAlign ">
              {this.props.maintenanceArray === null ? (
                <div className="col-12 mb-5">
                  <Loader2 />
                </div>
              ) : this.filterMaintenanceHistoryCount() > 0 ? (
                this.filterMaintenanceHistory().map((data,index) => (
                  <MaintenanceHistoryCard
                    request={data}
                    contractData={this.filterContract(data)}
                    unitData={this.filterUnit(data)}
                    key={"MaintenanceHistoryCard"+index}
                  />
                ))
              ) : (
                <div className="col text-center  mt-3 mb-3">
                  <span className="span2  ">You have no maintenance tasks</span>
                </div>
              )}
              {this.filterMaintenanceHistoryCount() > 3 ? (
                <div className="col-12 requestShowMore   ">
                  {this.state.historyExpand ? (
                    <p
                      className="text-right"
                      onClick={() => this.setState({ historyExpand: false })}
                    >
                      Show Less{" "}
                      <i className="fa fa-chevron-up" aria-hidden="true"></i>
                    </p>
                  ) : (
                    <p
                      className="text-right"
                      onClick={() => this.setState({ historyExpand: true })}
                    >
                      Show More{" "}
                      <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </p>
                  )}
                </div>
              ): <div className="col-12 requestShowMore  "><br></br><br></br></div>}
            </div>

            {this.state.newRequestModal && (
              <div
                className="modal fade bd-example-modal-xl"
                id={"MaintenanceRequest"}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ zIndex: "1500" }}
              >
                <div className="modal-dialog modal-xl" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className={"axText"}>New service request</h1>
                      <button
                        type="button"
                        className="close "
                        data-dismiss="modal"
                        onClick={() =>
                          this.setState({ newRequestModal: false })
                        }
                        aria-label="Close"
                      >
                        <span aria-hidden="true" className="closeModal textPri">
                          &times;
                        </span>
                      </button>
                    </div>
                    <div
                      className="modal-body bodyAvailable2 pt-0  pb-0"
                      style={{
                        overflow: "scroll",
                        maxHeight: "500px",
                      }}
                    >
                      <div id="containerForm" className="p-0 m-0">
                        <div className="row mt-3 mr-3 ml-3">
                          <div className="col-md-6">
                            <ApplicationInput
                              name="Maintenance Request"
                              target="category"
                              type="optional"
                              designType="2"
                              options={this.state.maintenanceRequest}
                              optionValue={this.state.maintenanceRequest}
                              onChange={this.handleChange}
                              important="true"
                              errorMessage="maintenance request category"
                              validate={this.checkValidate(this.state.category)}
                              value={this.state.category}
                              dark={this.props.dark}
                            />
                          </div>
                          <div className="col-md-6">
                            <ApplicationInput
                              name="Work Focus"
                              target="subCategory"
                              type="optional"
                              designType="2"
                              options={this.state.cat[this.state.catFinder]}
                              optionValue={this.state.cat[this.state.catFinder]}
                              onChange={this.handleChange}
                              important="true"
                              errorMessage="sub category"
                              validate={this.checkValidate(
                                this.state.subCategory
                              )}
                              value={this.state.subCategory}
                              dark={this.props.dark}
                            />
                          </div>

                          <div className="col-12 mt-3 mb-3">
                            <ApplicationInput
                              name="Service Description"
                              subName=" ( Please provide as much information as you can )"
                              row={5}
                              type="textArea"
                              designType="2"
                              target="description"
                              onChange={this.handleChange}
                              value={this.state.description}
                              errorMessage=" service request description"
                              important="true"
                              validate={this.checkValidate(
                                this.state.description
                              )}
                            />
                          </div>
                          <div className="col-xl-12 pt-0 mt-0 ">
                            <span className="searchListHeadApp2 mb-2">
                              Schedule an appointment
                            </span>
                          </div>
                          <div className="col-md-5">
                            <ApplicationInput
                              name="If available, which day works best for you?"
                              type="date"
                              min={today()}
                              target="appointmentDate"
                              onChange={this.onChangeDate}
                              designType="2"
                              // important="true"
                              // errorMessage="the move in date"
                              // validate={this.checkValidate(
                              //   this.state.appointmentAnotherDate === null
                              //     ? ""
                              //     : this.state.appointmentAnotherDate
                              // )}
                              value={this.state.appointmentDate}
                            />
                          </div>
                          <div className="col-md-6">
                            <TimeInput
                              id="appointmentTime"
                              name="appointmentTime"
                              designType="2"
                              target="appointmentTime"
                              onChange={this.onChangeTime}
                              value={this.state.appointmentTime1}
                              place="--:-- --"
                            />
                          </div>
                          <div className="col-md-5">
                            <ApplicationInput
                              name="What is another day that works for you?"
                              type="date"
                              min={today()}
                              target="appointmentAnotherDate"
                              onChange={this.onChangeDate}
                              designType="2"
                              value={this.state.appointmentAnotherDate}
                            />
                          </div>
                          <div className="col-md-6">
                            <TimeInput
                              designType="2"
                              id="appointmentAnotherTime"
                              name="appointmentAnotherTime"
                              target="appointmentAnotherTime"
                              onChange={this.onChangeTime}
                              value={this.state.appointmentAnotherTime1}
                              place="--:-- --"
                            />
                          </div>
                          <div className="col-xl-4 pt-0 mt-0 mb-4">
                            <span className="searchListHeadApp2 mb-2">
                              Image(s)
                            </span>
                            <Dropzone
                              inputContent={
                                <div className={this.state.next ? "alert" : ""}>
                                  <div
                                    className="dragDrop text-center pointer "
                                    style={{
                                      fontSize: "60px",
                                      minHeight: "200px",
                                      paddingTop: "40px",
                                      marginBottom: "40px",
                                    }}
                                  >
                                    <div className="row ">
                                      <div className="col">
                                        <i
                                          className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        <p className="licenseHead">
                                          Drag Or Click to Upload
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              inputWithFilesContent="Upload more"
                              onChangeStatus={this.handleChangeStatus}
                              accept=".jpeg,.png,.jpg"
                              submitButtonContent="Upload"
                            />
                          </div>
                          {this.state.coverImage.length > 0 && (
                            <div
                              style={{
                                display: "block",
                                width: "100%",
                              }}
                              className="col-md pl-5 pt-4 pb-4"
                            >
                              <Gallery
                                images={imgList}
                                enableLightbox={true}
                                enableImageSelection={false}
                                currentImageWillChange={
                                  this.onCurrentImageChange
                                }
                                customControls={[
                                  <button
                                    key="deleteImage"
                                    data-target="#deleteImage"
                                    data-toggle="modal"
                                    onClick={() =>
                                      this.setState({ deleteImage: true })
                                    }
                                    className="agreement  mr-2 ml-2 pri2"
                                    style={{
                                      marginTop: "50px",
                                      height: "40px",
                                      zIndex: "4500",
                                    }}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>,
                                ]}
                                backdropClosesModal={true}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer pt-3 ">
                      <button
                        className="pri"
                        data-dismiss="modal"
                        onClick={() =>
                          this.setState({ newRequestModal: false })
                        }
                      >
                        {" "}
                        Cancel
                      </button>
                      <button
                        className="buttonPri"
                        onClick={() => this.createRequest()}
                      >
                        Send Request
                        {this.state.newRequestModalLoader && (
                          <Loader3 transY="50px" transX="0px" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {this.state.deleteImage && (
          <SmallModalContainer
            name="deleteImage"
            dark={this.props.dark}
            title="Confirm Delete?"
            confirmCommand="Delete"
            confirm={this.deleteImage}
            reject={() => this.setState({ deleteImage: false })}
            rejectCommand="Cancel"
            rejectCheck="true"
          >
            <div className="p-2 pull-left text-left">
              <span className="span7" style={{ fontSize: "16px" }}>
                Are you sure you want to delete this image?
              </span>
            </div>
          </SmallModalContainer>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    maintenance: state.maintenanceReducer,
    maintenanceArray: state.maintenance,
    user: state.userReducer,
    userDetail: state.user,
    contract: state.contractReducer,
    contractArray: state.contract,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    role: state.RoleReducer,
    roleStatus: state.role,
    provider: state.providerReducer,
    providerArray: state.provider,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setMaintenance, setProvider })(
    Maintenance
  )
);
