import React, { Component } from "react";
import {
  exceptNameNull,
  exceptNull,
  sortText,
  timeCalculativeFormat,
} from "../../../../Defined/Function";
import image1 from "../../../../Images/profile.png";
import doc from "../../../../Images/doc.png";
import expand from "../../../../Images/expand.png";
import "./MaintenanceRequestQuotations.css";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";

class MaintenanceRequestQuotations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      color: ["#F7D4AA", "#BAE5F5", "#E7D3F1", "#E1EF90"],
    };
  }

  removeDuplicate(array) {
    let arr = array.filter((element, index) => {
      return this.findIndex(array, element.maintenanceProvider) === index;
    });
    return arr;
  }
  findIndex(array, arr) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].maintenanceProvider.id === arr.id) {
        return i;
      }
    }
    return -1;
  }
  getViewArray() {
    if (this.state.expand) {
      if (this.props.mode === "open") return this.props.data.serviceQuotations;
      else return this.removeDuplicate(this.props.data.serviceQuotations);
    } else {
      if (this.props.mode === "open")
        return this.props.data.serviceQuotations.slice(0, 3);
      else
        return this.removeDuplicate(this.props.data.serviceQuotations).slice(
          0,
          3
        );
    }
  }
  open() {
    if (this.props.data.serviceQuotations.length > 3) {
      this.setState({ expand: !this.state.expand });
    }
  }
  render() {
    return (
      <div className="maintenanceRequestCard mt-3 pr-0">
        <div className="pr-4">
          <span className="maintenanceRequestDetailText1 ">Quotation</span>
          {this.props.data.serviceQuotations.length > 3 && (
            <>
              <span
                className={
                  "maintenanceRequestDetailText3 pointer pull-right pt-1"
                }
                onClick={() => this.open()}
              >
                {this.state.expand ? "See less" : "See more"}
              </span>
              <span className="maintenanceRequestDetailText1  pull-right requestInvitationDivider pt-1">
                |
              </span>
            </>
          )}
          {this.props.mode === "open" && (
            <span
              className="maintenanceRequestDetailText3 pointer  pull-right pt-1"
              data-target="#AddProvider"
              data-toggle="modal"
              onClick={() => this.props.addQuotation()}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add Quotation
            </span>
          )}
        </div>
        <div className="requestQuotationText">
          {this.getViewArray().map((data, index) => (
            <>
              <div className={"row maintenanceProviderContainer "}>
                {this.props.mode === "open" && (
                  <>
                    <div className="maintenanceQuotationImageAlign ">
                      <img
                        alt="tenant"
                        src={doc}
                        className="maintenanceRequestQuotationImage2 "
                        style={{ backgroundColor: this.state.color[index % 5] }}
                      />
                    </div>
                    <div className="maintenanceQuotationNameAlign ">
                      <h1 className="maintenanceRequestText2 textLeft">
                        Quotation
                      </h1>
                      <h3 className="maintenanceRequestText1 textLeft">
                        # {data.id}
                      </h3>
                    </div>
                  </>
                )}
                {this.props.mode !== "open" && (
                  <>
                    <div className="maintenanceProviderQuotationImageAlign ">
                      <img
                        alt="tenant"
                        src={image1}
                        className="maintenanceRequestQuotationImage"
                      />
                    </div>
                    <div className=" maintenanceProviderQuotationNameAlign">
                      <h1 className="maintenanceRequestText2 textLeft">
                        {sortText(
                          exceptNameNull(
                            data.maintenanceProvider.firstName,
                            data.maintenanceProvider.lastName
                          ),
                          0,
                          17
                        )}
                      </h1>
                      <h3 className="maintenanceRequestText1 textLeft">
                        {sortText(
                          exceptNull(data.maintenanceProvider.skills),
                          0,
                          23
                        )}
                      </h3>
                    </div>
                  </>
                )}

                {this.props.mode === "open" && (
                  <div className="maintenanceQuotationCostAlign ">
                    <h3 className="maintenanceRequestDetailText5 ">
                      <CurrencyFormat value={data.estimatedCost} />
                    </h3>
                  </div>
                )}
                {this.props.mode !== "open" && (
                  <div className=" pt-1 maintenanceQuotationAlign0">
                    <span className="maintenanceRequestQuotationCount ">
                      {
                        this.props.data.serviceQuotations.filter((element) => {
                          return (
                            element.maintenanceProvider.id ===
                            data.maintenanceProvider.id
                          );
                        }).length
                      }
                    </span>
                  </div>
                )}
                <div className="maintenanceQuotationImageDummyAlign"></div>
                <div className=" maintenanceQuotationHourAlign ">
                  <h3 className="maintenanceRequestDetailText6 ">
                    {timeCalculativeFormat(data.createdAt)}
                  </h3>
                </div>
                {this.props.mode !== "open" && (
                  <div className=" pt-1 maintenanceQuotationAlign">
                    <span className="maintenanceRequestQuotationCount ">
                      {
                        this.props.data.serviceQuotations.filter((element) => {
                          return (
                            element.maintenanceProvider.id ===
                            data.maintenanceProvider.id
                          );
                        }).length
                      }
                    </span>
                  </div>
                )}
                <div
                  className=" maintenanceQuotationProviderIconAlign pointer"
                  data-target="#QuotationAppoint"
                  data-toggle="modal"
                  onClick={() =>
                    this.props.openEstimate(data.maintenanceProvider.id)
                  }
                >
                  <img
                    alt="tenant"
                    src={expand}
                    className="maintenanceRequestDetailIcon"
                  />
                </div>
              </div>
              {this.getViewArray().length - 1 !== index && (
                <hr className="quotationDivider"></hr>
              )}
            </>
          ))}
        </div>
      </div>
    );
  }
}

export default MaintenanceRequestQuotations;
