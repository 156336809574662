// Maintenance Provider Details Reducer 
const providerReducer = (state = null, action) => {
     switch (action.type) {
       case "GET_PROVIDER":
         return state;
       case "SET_PROVIDER":
         return action.provider;
       case "REMOVE_PROVIDER_BY_ID":
         return state.filter((todo) => todo.id !== action.id);
       case "REMOVE_PROVIDER":
         return null;
       default:
         return state;
     }
   };
export default providerReducer;
   