import React, { Component } from "react";
import "../application.css";
import "../dropdownForm.css";
import {
  approvedApplication,
  getAllApplication,
  rejectedApplication,
  userDetail,
} from "../../../../Services/ApiServices";
import { connect } from "react-redux";
import {
  appendTempUsers,
  setApplication,
  appendAlert,
} from "../../../../actions";
import { withRouter } from "react-router-dom";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import { ApplicationModal } from "./ApplicationModal";
import SignaturePad from "../../../UIServices/SignaturePad/SignaturePad";
import Dropzone from "react-dropzone";
import Loader3 from "../../../Loader/loader3";
import { sortText } from "../../../../Defined/Function";
class ContractUserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user:
        this.props.application.apply[0] &&
        this.props.application.apply[0].applicant.username,
      tenantData: {
        accountNonExpired: true,
        accountNonLocked: true,
        address: {
          address: "No.123 1st cross street",
          city: "",
          state: "",
          country: "",
        },
        coverImage: null,
        credentialsNonExpired: true,
        enabled: true,
        firstName: "",
        lastName: "",
        middleName: "",
        mobileNumber: "",
        password: "",
        provider: "",
        stripeAccountId: "",
        stripeCustomerId: null,
        username: "",
      },
      stage: "pdf",
      signatureObject: null,
      signatureTab: "pad",
      signLoader: false,
      openModal: false,
    };
    this.rejectApplication = this.rejectApplication.bind(this);
    this.changeSignatureObject = this.changeSignatureObject.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  async openModal() {
    if(this.props.application.status === "APPROVED" && this.props.application.contractCreated){
      this.props.history.push({ pathname: "/contracts/"+this.props.application.contractId });
    }else{
      await this.setState({ openModal: false });
      this.setState({ openModal: true });
    }
  }
  getUser() {
    try {
      userDetail(this.props.application.apply[0].applicant.username).then(
        (response) => {
          if (response.status) {
            this.setState({ tenantData: response.data });
            this.props.appendTempUsers(response.data);
          }
        }
      );
    } catch (error) {}
  }
  componentDidMount() {
    if (this.props.application.apply[0]) {
      let users = this.props.tempUsersArray.filter(
        (todo) =>
          todo.username === this.props.application.apply[0].applicant.username
      );
      if (users.length === 0) {
        if (this.props.application) {
          this.getUser();
        }
      } else {
        this.setState({ tenantData: users[0] });
        this.getUser();
      }
    }
  }
  async rejectApplication(modalData) {
    try {
      await rejectedApplication(modalData.id).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            // this.setState((prevState) => ({
            //   applicationData: prevState.applicationData.filter(
            //     (initApp) => initApp !== modalData
            //   ),
            // }));
            this.getAllApp();
            this.props.appendAlert(
              "Application rejected successfully !!!",
              "success"
            );
          } else {
            this.props.appendAlert(
              "Application rejection failed !!!",
              "danger"
            );
          }
        } else {
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getAllApp() {
    try {
      await getAllApplication().then(async (response) => {
        window.scrollTo(0, 0);
        if (response.status === 200) {
          if (response.data.length !== 0) {
            await this.props.setApplication(null);
            this.props.setApplication(response.data.reverse());
          } else {
            this.props.setApplication([]);
          }
        } else {
          this.props.setApplication(null);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  async onAccept() {
    await this.setState({ stage: "sign", openSignModal: false });
    this.setState({ openSignModal: true });
    window.$("#applicationPdfModal").modal("hide");
    window.$("#signatureModal").modal("show");
  }
  changeSignatureObject(object) {
    this.setState({ signatureObject: object, signLoader: true });

    let formData = new FormData();
    formData.append("file", object);
    formData.append("signature", true);
    try {
      approvedApplication(this.props.application.id, formData).then(
        (response) => {
          if (response.status === 200) {
            if (response.data.status) {
              this.getAllApp();
              this.setState({ signLoader: false });
              this.props.appendAlert(
                "Application approved successfully !!!",
                "success"
              );
              window.$("#applicationPdfModal").modal("hide");
              window.$("#signatureModal").modal("hide");
            } else {
              this.props.appendAlert(
                "Application approved failed !!!",
                "danger"
              );
            }
          } else {
            this.setState({ signLoader: false });
            window.$("#applicationPdfModal").modal("hide");
            window.$("#signatureModal").modal("hide");
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  render() {
    return (
      <>
        <label
          className="applicationTenantBudge "
          data-target={"#applicationPdfModal" + this.props.application.id}
          data-toggle="modal"
          onClick={() => this.openModal()}
        >
          <p
            className={
              this.props.isDark ? "contractTextDark" : "contractText  "
            }
          >
            {this.props.application.apply[0] ? (
              this.state.tenantData.firstName === "" &&
              this.state.tenantData.lastName === "" ? (
                <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                  {sortText(
                    this.props.application.apply[0].applicant.username,
                    0,
                    17
                  )}
                </span>
              ) : (
                <>
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    {sortText(
                      this.state.tenantData.firstName +
                        " " +
                        this.state.tenantData.lastName,
                      0,
                      23
                    )}
                    ,
                  </span>{" "}
                  <span style={{ fontSize: "14px" }}>
                    {sortText(
                      this.props.application.apply[0].applicant.username,
                      0,
                      17
                    )}
                  </span>
                </>
              )
            ) : (
              <>&nbsp;</>
            )}

            <i
              className="fa fa-chevron-circle-right pull-right"
              aria-hidden="true"
            ></i>
            {this.props.status &&
              (this.props.application.status === "PENDING" ? (
                <span className="badgeApplicationStatusYellow pull-right">
                  {this.props.application.status}
                </span>
              ) : this.props.application.status === "REJECTED" ? (
                <span className="badgeApplicationStatusRed pull-right">
                  {this.props.application.status}
                </span>
              ) : this.props.application.status === "APPROVED" ? (
                <span className="badgeApplicationStatusRed pull-right">
                  {this.props.application.status}
                </span>
              ) : (
                <span className="badgeApplicationStatusYellow pull-right">
                  {this.props.application.status}
                </span>
              ))}
          </p>
        </label>
        {this.state.openModal && (
          <ApplicationModal
            data={this.props.listingData}
            application={this.props.application}
            property={this.props.listingData.rentalEntity.property}
            modaldata={this.props.modalData}
            history={this.props.history}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            onReject={(data) => {
              this.rejectApplication(data);
            }}
            onAccept={() => this.onAccept()}
          />
        )}
        {this.state.openSignModal && (
          <SmallModalContainer
            name="signatureModal"
            dark={this.props.dark}
            title="Please sign here"
            footer="false"
            size="lg"
          >
            <div className="row">
              <div className="col ">
                <button
                  className={
                    this.state.signatureTab === "pad"
                      ? "mr-2 tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() =>
                    this.setState({
                      signatureTab: "pad",
                      signatureObject: null,
                    })
                  }
                >
                  Draw
                </button>
                <button
                  className={
                    this.state.signatureTab === "upload"
                      ? "mr-2  tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() =>
                    this.setState({
                      signatureTab: "upload",
                      signatureObject: null,
                    })
                  }
                >
                  Upload
                </button>
              </div>
            </div>

            <div className={"tab-content  borderSec"} id="myTabContent">
              <div
                className={
                  this.state.signatureTab === "pad"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-center pt-5 pb-4">
                  <SignaturePad
                    name="applicationLast"
                    submitAlign="pull-right"
                    submitName="Submit and Approve"
                    value={this.state.signatureObject}
                    submit={this.changeSignatureObject}
                    submitDisable={!this.state.signLoader}
                    type="base64"
                    maxWidth="450"
                  />
                </div>
              </div>
              <div
                className={
                  this.state.signatureTab === "upload"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-start mb-5">
                  <Dropzone
                    accept=".jpeg,.png,.jpg"
                    maxSize={10485760}
                    onDrop={(files) => {
                      files.map((file) => {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                          if (event.target.result === undefined) {
                            this.props.appendAlert(
                              "Image size is too Long (max size = 10mb) ",
                              "danger"
                            );
                          } else {
                            this.setState({
                              signatureObject: event.target.result,
                            });
                          }
                        };
                        reader.readAsDataURL(file);
                        return file;
                      });
                      // if (acceptedFiles[0] === undefined) {
                      //   this.props.appendAlert(
                      //     "Image size is too Long (max size = 10mb) ",
                      //     "danger"
                      //   );
                      // } else {
                      //   this.setState({
                      //     signatureObject: acceptedFiles[0],
                      //   });
                      // }
                    }}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.state.signatureObject === null ? (
                            <div className="text-left p-4">
                              <div
                                className="dragDrop text-center  pointer"
                                style={{
                                  fontSize: "60px",
                                  minHeight: "250px",
                                  paddingTop: "70px",
                                }}
                              >
                                <div className="row">
                                  <div className="col">
                                    <i
                                      className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p className="licenseHead">
                                      Drag Or Click to Upload
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <span className="span5 textPri ">
                                Max file size is 10MB per image. JPG, PNG, or
                                GIF formats only.
                              </span>
                            </div>
                          ) : (
                            <div className=" text-center ">
                              <img
                                src={this.state.signatureObject}
                                className="licenseImage dragDrop pointer"
                                alt="propsImage"
                                style={{ height: "100%", width: "100%" }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <div className="col ">
                    <button
                      className="buttonSquareLinePri pull-left ml-3 mt-3"
                      onClick={() => this.setState({ signatureObject: null })}
                    >
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    {this.state.signatureObject !== null ? (
                      <button
                        className=" mr-3 pull-right mb-3"
                        onClick={() =>
                          this.changeSignatureObject(this.state.signatureObject)
                        }
                      >
                        Submit and Approve
                      </button>
                    ) : (
                      <button
                        className="secDisable pull-right mb-3 mr-3"
                        style={{ cursor: "not-allowed" }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Please provide your signature image"
                      >
                        Submit and Approve
                      </button>
                    )}
                  </div>
                  {this.state.signatureTab === "upload" &&
                    this.state.signLoader && (
                      <div className="pull-right">
                        <Loader3 transY="80px" transX="-150px" />
                      </div>
                    )}
                </div>
              </div>
              {this.state.signatureTab === "pad" && this.state.signLoader && (
                <div className="pull-right">
                  <Loader3 transY="10px" transX="-180px" />
                </div>
              )}
            </div>
          </SmallModalContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    dark: state.darkReducer,
    isDark: state.isDark,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendTempUsers,
    appendAlert,
    setApplication,
  })(ContractUserDetail)
);
