import React, { Component } from "react";
import "../application.css";
import "../dropdownForm.css";
import {
  getPropertyLists,
  canceledApplication,
  getRentalListsImages,
  url,
  userDetail,
} from "../../../../Services/ApiServices";
import Loader3 from "../../../Loader/loader3";
import coverImage from "../../../../Images/no-cover-img.jpg";
import { connect } from "react-redux";
import {
  appendAlert,
  appendTempListingData,
  appendTempUsers,
} from "../../../../actions";
import { withRouter } from "react-router-dom";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import { addressFormatter, convertLocalDate } from "../../../../Defined/Function";
import ModalContainer from "../../Documents/ModalContainer";
import ApplicationAgreementPDF from "../ApplicationAgreementPDF";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";

class ApplicationTenantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listingData: [],
      applicationData: this.props.data,
      listImage: "",
      failFetching: false,
      cancel: false,
      tenantData: [],
      downloadModal: false,
    };
    this.cancelApplication = this.cancelApplication.bind(this);
    this.getTenantData = this.getTenantData.bind(this);
    this.openDownloadModal = this.openDownloadModal.bind(this);
    this.closeDownloadModal = this.closeDownloadModal.bind(this);
  }
  openDownloadModal() {
    this.setState({ downloadModal: true });
  }
  closeDownloadModal() {
    this.setState({ downloadModal: false });
  }
  componentDidMount() {
    let listing = this.props.tempListingArray.filter(
      (todo) => todo.rentalEntity.id === this.props.data.rentalEntityId
    );

    if (listing.length === 0) {
      this.getProps();
    } else {
      this.setState({ listingData: listing[0] });
      this.setState({
        failFetching: false,
      });
      this.getProps();
    }

    try {
      getRentalListsImages(this.props.data.rentalEntityId).then(
        (responseImage) => {
          if (responseImage.status === 200) {
            if (responseImage.data.length !== 0) {
              this.setState({
                listImage:
                  url +
                  "api/property/" +
                  this.props.data.rentalEntityId +
                  "/" +
                  responseImage.data[0] +
                  "/image/downloadByName",
                spinner: false,
              });
            }
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
    this.getTenantData();
  }
  async getTenantData() {
    try {
      let td = [];
      await this.props.data.apply.forEach((element) => {
        let users = this.props.tempUsersArray.filter(
          (todo) => todo.username === element.id.applicantId
        );
        if (users.length === 0) {
          userDetail(element.id.applicantId).then((response) => {
            if (response.status) {
              td.push(response.data);
              this.props.appendTempUsers(response.data);
            } else {
              td.push({ username: element.id.applicantId });
            }
          });
        }
      });
      this.setState({ tenantData: td });
    } catch (error) {}
  }
  async getProps() {
    try {
      await getPropertyLists(this.props.data.rentalEntityId)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              this.setState({
                listingData: response.data,
                failFetching: false,
              });
              this.props.appendTempListingData(response.data);
            } else {
              console.log("Error in processing.Try again later!");
            }
          } else {
            this.setState({ failFetching: true });
          }
        })
        .catch(() => {
          this.setState({ failFetching: true });
        });
    } catch (error) {
      console.error(error);
    }
  }

  async cancelApplication(modalData) {
    let updated = modalData;
    modalData.status = "CANCEL";

    try {
      await canceledApplication(modalData.id).then((response) => {
        if (response.status === 200) {
          if (response.data.status) {
            this.setState((prevState) => {
              let newData = prevState.applicationData;
              Object.assign(newData, updated);
              return { applicationData: newData };
            });
            this.props.appendAlert(
              "Application cancelled successfully !!!",
              "success"
            );
          }
        } else {
          this.props.appendAlert(
            "Application cancellation failed !!!",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    return this.state.failFetching ? (
      <></>
    ) : this.state.listingData.length === 0 ? (
      <div className="p-5" style={{ marginTop: "10px" }}>
        <Loader3 />
      </div>
    ) : (
      this.state.applicationData && (
        <>
          <div
            className={ "row applicationCard2 " }
          >
          
                <div
                  className="col-2 applicationListImage1  "
                  style={{
                    backgroundImage:
                      "url(" +
                      (this.state.listImage === ""
                        ? coverImage
                        : this.state.listImage) +
                      ")",
                    borderBottomLeftRadius: "15px",
                    borderTopLeftRadius: "15px",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
                <div
                  className="col-12 applicationListImage3 mb-3 "
                  style={{
                    backgroundImage:
                      "url(" +
                      (this.state.listImage === ""
                        ? coverImage
                        : this.state.listImage) +
                      ")",
                    
                    borderTopRightRadius: "15px",
                    borderTopLeftRadius: "15px",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
                <div className="col pb-0 mb-0">
                  <div className="row  contractDivider mt-1 pb-3">
                    <div className="col-12 pl-3 ">
                      <span className="badge badge02 ">Rental</span>
                    </div>
                    <div className="col-3  contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Unit
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        {this.state.listingData.rentalEntity &&
                          (this.state.listingData.rentalEntity.unitName
                            ? this.state.listingData.rentalEntity.unitName
                            : " - ")}
                      </p>
                    </div>
                    <div className="col-2 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Apartment
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        {this.state.listingData.rentalEntity &&
                          (this.state.listingData.rentalEntity.property.name
                            ? this.state.listingData.rentalEntity.property.name
                            : " - ")}
                      </p>
                    </div>

                    <div className="col-md-6 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Address
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        {addressFormatter(this.state.listingData.address)}
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 pb-2 contractDivider">
                    <div className="col-12 pl-3">
                      <span className="badge badge02 ">Tenant</span>
                    </div>
                    <div className="col-3 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Primary
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        {this.state.tenantData[0]
                          ? this.state.tenantData[0].firstName === "" &&
                            this.state.tenantData[0].lastName === ""
                            ? this.state.tenantData[0].username
                            : this.state.applicationData.apply[0].id.applicantId
                          : this.state.applicationData.apply[0].id.applicantId}
                      </p>
                    </div>
                    {this.state.tenantData.length > 1 && (
                      <div className="col-4 contractListAlign">
                        <p
                          className={
                            this.props.dark
                              ? "contractTextDark"
                              : "contractText "
                          }
                          style={{ fontWeight: "bold" }}
                        >
                          Others
                        </p>
                        <p
                          className={
                            this.props.dark
                              ? "contractTextDark"
                              : "contractText"
                          }
                        >
                          <div className="row">
                            <div className="col">
                              {this.state.tenantData[1]
                                ? this.state.tenantData[1].firstName === "" &&
                                  this.state.tenantData[1].lastName === ""
                                  ? this.state.tenantData[1].username
                                  : this.state.applicationData.apply[1].id
                                      .applicantId
                                : this.state.applicationData.apply[1].id
                                    .applicantId}
                            </div>
                            <div className="col-2">
                              <div className="dropdown show pull-right">
                                <span
                                  className="badge bg-gray dropdown-toggle pointer p-2 pb-1"
                                  id="dropdownChangeEmail2"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fa fa-pencil-square-o "
                                    aria-hidden="true"
                                  ></i>{" "}
                                </span>
                                <div
                                  className="dropdown-menu   profileMenu navProfile pr-3 pl-3 shadow"
                                  aria-labelledby="dropdownChangeEmail2"
                                >
                                  <ApplicationInput
                                    name="Email"
                                    target="tenant2Email"
                                    type="email"
                                    onChange={this.onchange}
                                    important="true"
                                    errorMessage="the email "
                                    // validate={this.checkValidate(
                                    //   this.state.tenant2Email
                                    // )}
                                    value={this.state.tenant2Email}
                                  />

                                  <button
                                    className=" mt-3 mb-3 buttonPri pull-right"
                                    onClick={this.changeMethod}
                                  >
                                    Change Email
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </p>
                        {this.state.tenantData.length > 2 && (
                          <p
                            className={
                              this.props.dark
                                ? "contractTextDark"
                                : "contractText"
                            }
                          >
                            <div className="row">
                              <div className="col">
                                {this.state.tenantData[2]
                                  ? this.state.tenantData[2].firstName === "" &&
                                    this.state.tenantData[2].lastName === ""
                                    ? this.state.tenantData[2].username
                                    : this.state.applicationData.apply[2].id
                                        .applicantId
                                  : this.state.applicationData.apply[2].id
                                      .applicantId}
                              </div>
                              <div className="col-2">
                                <div className="dropdown show pull-right">
                                  <span
                                    className="badge bg-gray dropdown-toggle pointer p-2 pb-1"
                                    id="dropdownChangeEmail2"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i
                                      className="fa fa-pencil-square-o "
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </span>
                                  <div
                                    className="dropdown-menu   profileMenu navProfile pr-3 pl-3 shadow"
                                    aria-labelledby="dropdownChangeEmail2"
                                  >
                                    <ApplicationInput
                                      name="Email"
                                      target="tenant2Email"
                                      type="email"
                                      onChange={this.onchange}
                                      important="true"
                                      errorMessage="the email "
                                      // validate={this.checkValidate(
                                      //   this.state.tenant2Email
                                      // )}
                                      value={this.state.tenant2Email}
                                    />

                                    <button
                                      className=" mt-3 mb-3 buttonPri pull-right"
                                      onClick={this.changeMethod}
                                    >
                                      Change Email
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </p>
                        )}
                      </div>
                    )}

                    <div className="col-2 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Total Rent
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        
                        <CurrencyFormat value={this.state.listingData.rentalEntity.rent} />
                      </p>
                    </div>
                    <div className="col-md-3 contractListAlign">
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                        style={{ fontWeight: "bold" }}
                      >
                        Move In Date
                      </p>
                      <p
                        className={
                          this.props.dark ? "contractTextDark" : "contractText "
                        }
                      >
                        {convertLocalDate(this.state.applicationData.moveInDate)}
                      </p>
                    </div>
                  </div>
                  
                  <div className="row mt-3 mb-3">
                    <div className="col-md-2 contractListImage1">
                      <span
                        className="badge appStatus "
                        style={{
                          backgroundColor:
                            this.state.applicationData.status === "APPROVED"
                              ? "#2ECC71"
                              : this.state.applicationData.status === "PENDING"
                              ? "#F4D03F"
                              : this.state.applicationData.status === "CANCEL"
                              ? "#5499C7"
                              : "#E74C3C",
                        }}
                      >
                        {this.state.applicationData.status === "CANCEL"
                          ? "CANCELED"
                          : this.state.applicationData.status}
                      </span>
                    </div>

                    <div className="col  ">
                      {this.state.applicationData.status === "PENDING" && (
                        // && this.state.applicationData.status !== "CANCEL"
                        <button
                          className=" pull-right  "
                          data-target="#cancel"
                          data-toggle="modal"
                          onClick={() => this.setState({ cancel: true })}
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        className="pri pull-right  mr-3"
                        data-target="#exampleModal"
                        data-toggle="modal"
                        onClick={() => this.openDownloadModal()}
                      >
                        Application
                      </button>
                    </div>
                  </div>
                  {/* <div className="row" style={{ marginTop: "-25px" }}>
                    <div className="col ">
                      
                    </div>
                  </div> */}
                </div>
            {this.state.cancel && (
              <SmallModalContainer
                name="cancel"
                dark={this.props.dark}
                title="Confirm Cancel"
                confirmCommand="Yes"
                confirm={() =>
                  this.cancelApplication(this.state.applicationData)
                }
                reject={() => this.setState({ cancel: false })}
                rejectCommand="No"
                rejectCheck="true"
              >
                <div className="p-2 pull-left text-left">
                  <span className="span7" style={{ fontSize: "16px" }}>
                    Are you sure you want to cancel this application?
                  </span>
                </div>
              </SmallModalContainer>
            )}
            {this.state.downloadModal && (
              <ModalContainer
                max={window.innerHeight - 200}
                width={window.screen.width - 60}
                name="exampleModal"
                title={"Application"}
                footer="false"
                closeContract={this.closeDownloadModal}
              >
                <ApplicationAgreementPDF id={this.state.applicationData.id} />
              </ModalContainer>
            )}
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    isDark: state.isDark,
    dark: state.darkReducer,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    appendTempListingData,
    appendTempUsers,
  })(ApplicationTenantList)
);
