// Application Details Reducer
const applicationReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_APPLICATION":
      return state;
    case "SET_APPLICATION":
      return action.application;
    case "REMOVE_APPLICATION_BY_ID":
      return state.filter((todo) => todo.id !== action.id )
    case "REMOVE_APPLICATION":
      return null;
    default:
      return state;
  }
};
export default applicationReducer;
   