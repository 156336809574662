import React from "react";
import "./contactUs.css";
import "./aboutUs.css";
import image from "../../Images/property.jpg";

const AboutUs = () => {
  return (
    <>
      <div
        className="aboutImage "
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <p className="line-1 anim-typewriter">
          Share and Find your Home with us
        </p>
      </div>
      <div className="container-fluid ">
        <div className="aboutContain" style={{}}>
          <h1 className="two mt-5 text-center text-dark">
            About axallant.com <hr className="rdLine "></hr>
          </h1>
          <p className="aboutText ">
            Welcome to{" "}
            <strong style={{ fontWeight: "900" }}>axallant.com</strong>
            <span style={{ Bottom: "20px" }}>®</span> . We’re dedicated to
            providing you the very best rental experience. With tons of homes
            and apartments for rent in almost every state, city, and
            neighborhood across the nation, we’re here to help you find your
            next home. Tenant manager account allows you to follow up with your
            landlord regarding the leasing and rent payments. We also offers
            landlords a bevy of useful resources through the Property manager
            account. Join with us today, to make your rental experience better!.
            We hope you enjoy our products as much as we enjoy offering them to
            you. If you have any questions or comments, please don’t hesitate to
            contact us.
          </p>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
