// @author Karunaaharan Bavaram 

import React, { Component } from "react";
import { getMonth } from "../../../Defined/Function";
import "./columnChart.css";

class ColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      max: 5000,
    };
  }

  componentDidMount() {
    this.findMax();
  }
  findMax() {
    var array = this.props.data;
    var max = 0;
    for (let index = 0; index < array.length; index++) {
      if (max < array[index].rent) {
        max = array[index].rent;
      } else if (max < array[index].lateFee) {
        max = array[index].lateFee;
      } else if (max < array[index].other) {
        max = array[index].other;
      }
    }
    this.setState({ max: max + 1000 });
  }
  render() {
    return (
      <>
        <style>
          {`
           
          `}
        </style>

        <div className="row ">
          <div className="col ">
            <div id="bar-chart">
              <div className="graph">
                <ul className="x-axis">
                  <li>
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[0]
                            ? 0
                            : this.props.data[0].monthYear === null
                            ? 0
                            : this.props.data[0].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li>
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[1]
                            ? 0
                            : this.props.data[1].monthYear === null
                            ? 0
                            : this.props.data[1].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li>
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[2]
                            ? 0
                            : this.props.data[2].monthYear === null
                            ? 0
                            : this.props.data[2].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li>
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[3]
                            ? 0
                            : this.props.data[3].monthYear === null
                            ? 0
                            : this.props.data[3].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li className="paymentGraphMobile2">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[4]
                            ? 0
                            : this.props.data[4].monthYear === null
                            ? 0
                            : this.props.data[4].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li className="paymentGraphMobile1">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[5]
                            ? 0
                            : this.props.data[5].monthYear === null
                            ? 0
                            : this.props.data[5].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>

                  <li className="paymentGraphBigScreen1">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[6]
                            ? 0
                            : this.props.data[6].monthYear === null
                            ? 0
                            : this.props.data[6].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li className="paymentGraphBigScreen1">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[7]
                            ? 0
                            : this.props.data[7].monthYear === null
                            ? 0
                            : this.props.data[7].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li className="paymentGraphBigScreen1">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[8]
                            ? 0
                            : this.props.data[8].monthYear === null
                            ? 0
                            : this.props.data[8].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li className="paymentGraphBigScreen2">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[9]
                            ? 0
                            : this.props.data[9].monthYear === null
                            ? 0
                            : this.props.data[9].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li className="paymentGraphBigScreen2">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[10]
                            ? 0
                            : this.props.data[10].monthYear === null
                            ? 0
                            : this.props.data[10].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                  <li className="paymentGraphBigScreen2">
                    <span>
                      {getMonth(
                        parseInt(
                          !this.props.data[11]
                            ? "-"
                            : this.props.data[11].monthYear === null
                            ? "-"
                            : this.props.data[11].monthYear.slice(-2)
                        )
                      )}
                    </span>
                  </li>
                </ul>
                <ul className="y-axis">
                  <li>
                    <span>{((this.state.max / 10000) * 10).toFixed(1)}k</span>
                  </li>
                  <li>
                    <span>{((this.state.max / 10000) * 8).toFixed(1)}k</span>
                  </li>
                  <li>
                    <span>{((this.state.max / 10000) * 6).toFixed(1)}k</span>
                  </li>
                  <li>
                    <span>{((this.state.max / 10000) * 4).toFixed(1)}k</span>
                  </li>
                  <li>
                    <span>{((this.state.max / 10000) * 2).toFixed(1)}k</span>
                  </li>
                  <li>
                    <span>0k</span>
                  </li>
                </ul>
                <div className="bars">
                  <div className="bar-group">
                    <div
                      className={
                        !this.props.data[0]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[0]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[0].rent === null
                            ? 0.0
                            : this.props.data[0].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[0]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[0].rent === null
                          ? 0.0
                          : this.props.data[0].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[0]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[0]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[0].lateFee === null
                            ? 0.0
                            : this.props.data[0].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[0]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[0].lateFee === null
                          ? 0.0
                          : this.props.data[0].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[0]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[0]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[0].other === null
                            ? 0.0
                            : this.props.data[0].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[0]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[0].other === null
                          ? 0.0
                          : this.props.data[0].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group">
                    <div
                      className={
                        !this.props.data[1]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[1]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[1].rent === null
                            ? 0.0
                            : this.props.data[1].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[1]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[1].rent === null
                          ? 0.0
                          : this.props.data[1].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[1]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[1]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[1].lateFee === null
                            ? 0.0
                            : this.props.data[1].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[1]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[1].lateFee === null
                          ? 0.0
                          : this.props.data[1].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[1]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[1]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[1].other === null
                            ? 0.0
                            : this.props.data[1].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[1]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[1].other === null
                          ? 0.0
                          : this.props.data[1].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group">
                    <div
                      className={
                        !this.props.data[2]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[2]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[2].rent === null
                            ? 0.0
                            : this.props.data[2].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[2]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[2].rent === null
                          ? 0.0
                          : this.props.data[2].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[2]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[2]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[2].lateFee === null
                            ? 0.0
                            : this.props.data[2].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[2]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[2].lateFee === null
                          ? 0.0
                          : this.props.data[2].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[2]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[2]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[2].other === null
                            ? 0.0
                            : this.props.data[2].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[2]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[2].other === null
                          ? 0.0
                          : this.props.data[2].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group">
                    <div
                      className={
                        !this.props.data[3]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[3]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[3].rent === null
                            ? 0.0
                            : this.props.data[3].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[3]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[3].rent === null
                          ? 0.0
                          : this.props.data[3].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[3]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[3]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[3].lateFee === null
                            ? 0.0
                            : this.props.data[3].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[3]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[3].lateFee === null
                          ? 0.0
                          : this.props.data[3].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[3]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[3]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[3].other === null
                            ? 0.0
                            : this.props.data[3].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[3]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[3].other === null
                          ? 0.0
                          : this.props.data[3].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group paymentGraphMobile2">
                    <div
                      className={
                        !this.props.data[4]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[4]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[4].rent === null
                            ? 0.0
                            : this.props.data[4].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[4]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[4].rent === null
                          ? 0.0
                          : this.props.data[4].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[4]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[4]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[4].lateFee === null
                            ? 0.0
                            : this.props.data[4].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[4]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[4].lateFee === null
                          ? 0.0
                          : this.props.data[4].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[4]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[4]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[4].other === null
                            ? 0.0
                            : this.props.data[4].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[4]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[4].other === null
                          ? 0.0
                          : this.props.data[4].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group paymentGraphMobile1">
                    <div
                      className={
                        !this.props.data[5]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[5]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[5].rent === null
                            ? 0.0
                            : this.props.data[5].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[5]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[5].rent === null
                          ? 0.0
                          : this.props.data[5].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[5]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[5]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[5].lateFee === null
                            ? 0.0
                            : this.props.data[5].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[5]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[5].lateFee === null
                          ? 0.0
                          : this.props.data[5].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[5]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[5]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[5].other === null
                            ? 0.0
                            : this.props.data[5].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[5]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[5].other === null
                          ? 0.0
                          : this.props.data[5].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group paymentGraphBigScreen1">
                    <div
                      className={
                        !this.props.data[6]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[6]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[6].rent === null
                            ? 0.0
                            : this.props.data[6].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[6]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[6].rent === null
                          ? 0.0
                          : this.props.data[6].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[6]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[6]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[6].lateFee === null
                            ? 0.0
                            : this.props.data[6].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[6]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[6].lateFee === null
                          ? 0.0
                          : this.props.data[6].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[6]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[6]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[6].other === null
                            ? 0.0
                            : this.props.data[6].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[6]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[6].other === null
                          ? 0.0
                          : this.props.data[6].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group paymentGraphBigScreen1">
                    <div
                      className={
                        !this.props.data[7]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[7]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[7].rent === null
                            ? 0.0
                            : this.props.data[7].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[7]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[7].rent === null
                          ? 0.0
                          : this.props.data[7].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[7]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[7]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[7].lateFee === null
                            ? 0.0
                            : this.props.data[7].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[7]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[7].lateFee === null
                          ? 0.0
                          : this.props.data[7].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[7]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[7]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[7].other === null
                            ? 0.0
                            : this.props.data[7].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[7]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[7].other === null
                          ? 0.0
                          : this.props.data[7].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group paymentGraphBigScreen1">
                    <div
                      className={
                        !this.props.data[8]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[8]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[8].rent === null
                            ? 0.0
                            : this.props.data[8].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[8]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[8].rent === null
                          ? 0.0
                          : this.props.data[8].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[8]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[8]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[8].lateFee === null
                            ? 0.0
                            : this.props.data[8].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[8]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[8].lateFee === null
                          ? 0.0
                          : this.props.data[8].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[8]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[8]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[8].other === null
                            ? 0.0
                            : this.props.data[8].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[8]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[8].other === null
                          ? 0.0
                          : this.props.data[8].other}
                      </span>
                    </div>
                  </div>

                  <div className="bar-group paymentGraphBigScreen2">
                    <div
                      className={
                        !this.props.data[9]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[9]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[9].rent === null
                            ? 0.0
                            : this.props.data[9].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[9]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[9].rent === null
                          ? 0.0
                          : this.props.data[9].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[9]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[9]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[9].lateFee === null
                            ? 0.0
                            : this.props.data[9].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[9]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[9].lateFee === null
                          ? 0.0
                          : this.props.data[9].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[9]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[9]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[9].other === null
                            ? 0.0
                            : this.props.data[9].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[9]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[9].other === null
                          ? 0.0
                          : this.props.data[9].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group paymentGraphBigScreen2">
                    <div
                      className={
                        !this.props.data[10]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[10]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[10].rent === null
                            ? 0.0
                            : this.props.data[10].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[10]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[10].rent === null
                          ? 0.0
                          : this.props.data[10].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[10]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[10]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[10].lateFee === null
                            ? 0.0
                            : this.props.data[10].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[10]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[10].lateFee === null
                          ? 0.0
                          : this.props.data[10].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[10]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[10]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[10].other === null
                            ? 0.0
                            : this.props.data[10].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[10]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[10].other === null
                          ? 0.0
                          : this.props.data[10].other}
                      </span>
                    </div>
                  </div>
                  <div className="bar-group paymentGraphBigScreen2">
                    <div
                      className={
                        !this.props.data[11]
                          ? "bar bar-1 stat-1-Disable"
                          : "bar bar-1 stat-1"
                      }
                      style={{
                        height:
                          (!this.props.data[11]
                            ? (this.state.max * 0.1) / this.state.max
                            : this.props.data[11].rent === null
                            ? 0.0
                            : this.props.data[11].rent / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[11]
                          ? (this.state.max * 0.1) / this.state.max
                          : this.props.data[11].rent === null
                          ? 0.0
                          : this.props.data[11].rent}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[11]
                          ? "bar bar-2 stat-2-Disable"
                          : "bar bar-2 stat-2"
                      }
                      style={{
                        height:
                          (!this.props.data[11]
                            ? (this.state.max * 0.3) / this.state.max
                            : this.props.data[11].lateFee === null
                            ? 0.0
                            : this.props.data[11].lateFee / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[11]
                          ? (this.state.max * 0.3) / this.state.max
                          : this.props.data[11].lateFee === null
                          ? 0.0
                          : this.props.data[11].lateFee}
                      </span>
                    </div>
                    <div
                      className={
                        !this.props.data[11]
                          ? "bar bar-3 stat-3-Disable"
                          : "bar bar-3 stat-3"
                      }
                      style={{
                        height:
                          (!this.props.data[11]
                            ? (this.state.max * 0.2) / this.state.max
                            : this.props.data[11].other === null
                            ? 0.0
                            : this.props.data[11].other / this.state.max) *
                            100 +
                          "%",
                      }}
                    >
                      <span>
                        {!this.props.data[11]
                          ? (this.state.max * 0.2) / this.state.max
                          : this.props.data[11].other === null
                          ? 0.0
                          : this.props.data[11].other}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3.5  p-0 m-0 pl-5 text-right">
            {/* <select
              className={"ApplicationInputSelectNormal  mr-4 "}
              onChange={this.props.onChange}
              value={this.props.paymentMonth}
              name="paymentMonth"
            >
              {this.props.months.map((option, index) => (
                <option className="ApplicationInputOptionNormal" value={index}>
                  {option}
                </option>
              ))}
            </select>
            <br></br>
            <select
              className={"ApplicationInputSelectNormal  mr-4 mb-5"}
              onChange={this.props.onChange}
              value={this.props.paymentYear}
              name="paymentYear"
            >
              {this.props.years.map((option, index) => (
                <option className="ApplicationInputOptionNormal" value={option}>
                  {option}
                </option>
              ))}
            </select> */}
            <p className="graphLabel text-left">
              <span className="badge bg-paymentGraph1 pr-3 ">&nbsp;</span>
              <span className="paymentText2">Rent</span>
            </p>
            <p className="graphLabel text-left">
              <span className="badge bg-paymentGraph2 pr-3 ">&nbsp;</span>
              <span className="paymentText2">Late Fee</span>
            </p>
            <p className="graphLabel text-left">
              <span className="badge bg-paymentGraph3 pr-3 ">&nbsp;</span>
              <span className="paymentText2">Others</span>
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default ColumnChart;
