// Payment Dashboard Details Reducer For Landlord 
const paymentDashboardDataReducer = (state = null, action) => {
     switch (action.type) {
       case "GET_PAYMENT_DASHBOARD_DATA":
         return state;
       case "SET_PAYMENT_DASHBOARD_DATA":
         return action.paymentDashboardData;
       case "REMOVE_PAYMENT_DASHBOARD_DATA":
         return null;
       default:
         return state;
     }
   };
export default paymentDashboardDataReducer;
   