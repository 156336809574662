import React, { Component } from "react";
import { withRouter } from "react-router";
import "../../officeApplication.css";

class OfficeApplicationStep4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
    };
    this.onchangeCheck = this.onchangeCheck.bind(this);
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    this.setState({ [name]: checked });
  }
  render() {
    return (
      <div className="row " id={"officeAppStep" + this.props.index}>
      <div className="col-3"></div>
            <div className="col-9  pt-1 d-flex justify-content-center">
              <div className="navBottom1">
          <p className="officeApplicationTitle mb-4 ">Banking Information</p>
          <p className="officeApplicationSubTitle1 pb-0 mb-0 "> Primary</p>
          <div className="row pr-3 pl-3">
            <div className="col-6 text-center ">
              <p className="officeApplicationSubTitle pull-left mb-3">
                Banking Institution
              </p>
              <input
                type="text"
                placeholder="Banking Institution"
                className={
                  this.props.checkNull(this.props.state.primaryBankName)
                    ? "form-control alert mb-0"
                    : "form-control"
                }
                name="primaryBankName"
                onChange={this.props.onChange}
                value={this.props.state.primaryBankName}
              />
              {this.props.checkNull(
                this.props.state.primaryBankName
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter primary Bank Name
                </span>
              )}
            </div>
            <div className="col-6 text-center ">
              <p className="officeApplicationSubTitle pull-left mb-3">Phone</p>
              <input
                type="tel"
                placeholder="Bank Phone"
                className={
                  this.props.checkMobile(this.props.state.primaryBankPhone)
                    ? "form-control alert mb-0"
                    : "form-control"
                }
                name="primaryBankPhone"
                onChange={this.props.onChange}
                value={this.props.state.primaryBankPhone}
              />
              {this.props.checkMobile(
                this.props.state.primaryBankPhone
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                 {this.props.checkNull(this.props.state.primaryBankPhone)? "Please Enter primary Bank Phone":"Please Enter primary Bank Phone Correct Format"}
                </span>
              )}
            </div>
          </div>
          <div className="row pr-3 pl-3">
            <div className="col-12 text-center ">
              <p className="officeApplicationSubTitle pull-left ">Address</p>
            </div>
            <div className="col-6 text-center ">
              <div className="input-group mb-3 ">
                <input type="text"  placeholder="Address" 
                className={
                  this.props.checkNull(this.props.state.primaryBankAddress)
                    ? "form-control alert mb-0"
                    : "form-control"
                }
                name="primaryBankAddress"
                onChange={this.props.onChange}
                value={this.props.state.primaryBankAddress}
              />
              {this.props.checkNull(
                this.props.state.primaryBankAddress
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter primary Bank Address
                </span>
              )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group mb-3 ">
                <input type="text" placeholder="State"
                className={
                  this.props.checkNull(this.props.state.primaryBankState)
                    ? "form-control alert mb-0"
                    : "form-control"
                }
                name="primaryBankState"
                onChange={this.props.onChange}
                value={this.props.state.primaryBankState}
              />
              {this.props.checkNull(
                this.props.state.primaryBankState
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter primary Bank State
                </span>
              )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input type="text"  placeholder="City" 
                className={
                  this.props.checkNull(this.props.state.primaryBankCity)
                    ? "form-control alert mb-0"
                    : "form-control"
                }
                name="primaryBankCity"
                onChange={this.props.onChange}
                value={this.props.state.primaryBankCity}
              />
              {this.props.checkNull(
                this.props.state.primaryBankCity
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter primary Bank City
                </span>
              )}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input type="text" placeholder="Zip" 
                className={
                  this.props.checkNull(this.props.state.primaryBankZip)
                    ? "form-control alert mb-0"
                    : "form-control"
                }
                name="primaryBankZip"
                onChange={this.props.onChange}
                value={this.props.state.primaryBankZip}
              />
              {this.props.checkNull(
                this.props.state.primaryBankZip
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter primary Bank Zip
                </span>
              )}
              </div>
            </div>
          </div>
          <p className="officeApplicationSubTitle mt-5 mb-2">
            (If you bank with more than one Institution, please list second bank
            below)
          </p>
          <div className="row pr-3 pl-3">
            <div className="col-6 text-center ">
              <p className="officeApplicationSubTitle pull-left mb-3 ">
                Banking Institution
              </p>
              <input
                type="text"
               
                placeholder="Banking Institution"
                className={
                  this.props.checkNull(this.props.state.secondaryBankName)
                    ? "form-control "
                    : "form-control"
                }
                name="secondaryBankName"
                onChange={this.props.onChange}
                value={this.props.state.secondaryBankName}
              />
              {/* {this.props.checkNull(
                this.props.state.secondaryBankName
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter Secondary Bank Name
                </span>
              )} */}
            </div>
            <div className="col-6 text-center ">
              <p className="officeApplicationSubTitle pull-left mb-3">Phone</p>
              <input
                type="tel"
               
                placeholder="Bank Phone"
                className={
                  this.props.checkMobile(this.props.state.secondaryBankPhone)
                    ? "form-control "
                    : "form-control"
                }
                name="secondaryBankPhone"
                onChange={this.props.onChange}
                value={this.props.state.secondaryBankPhone}
              />
              {/* {this.props.checkMobile(
                this.props.state.secondaryBankPhone
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                   {this.props.checkNull(this.props.state.secondaryBankPhone)? "Please Enter secondary bank phone no":"Please Enter secondary bank phone no correct format"}
                </span>
              )} */}
            </div>
          </div>
          <div className="row pr-3 pl-3">
            <div className="col-12 text-center ">
              <p className="officeApplicationSubTitle pull-left ">Address</p>
            </div>
            <div className="col-6 text-center ">
              <div className="input-group mb-3 ">
                <input type="text" placeholder="Address" 
                className={
                  this.props.checkNull(this.props.state.secondaryBankAddress)
                    ? "form-control "
                    : "form-control"
                }
                name="secondaryBankAddress"
                onChange={this.props.onChange}
                value={this.props.state.secondaryBankAddress}
              />
              {/* {this.props.checkNull(
                this.props.state.secondaryBankAddress
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter secondary bank address
                </span>
              )} */}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group mb-3 ">
                <input type="text"  placeholder="State" 
                className={
                  this.props.checkNull(this.props.state.secondaryBankState)
                    ? "form-control "
                    : "form-control"
                }
                name="secondaryBankState"
                onChange={this.props.onChange}
                value={this.props.state.secondaryBankState}
              />
              {/* {this.props.checkNull(
                this.props.state.secondaryBankState
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter secondary bank state
                </span>
              )} */}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input type="text" placeholder="City" 
                className={
                  this.props.checkNull(this.props.state.secondaryBankCity)
                    ? "form-control "
                    : "form-control"
                }
                name="secondaryBankCity"
                onChange={this.props.onChange}
                value={this.props.state.secondaryBankCity}
              />
              {/* {this.props.checkNull(
                this.props.state.secondaryBankCity
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter secondary bank city
                </span>
              )} */}
              </div>
            </div>
            <div className="col-6 text-center">
              <div className="input-group ">
                <input type="text"  placeholder="Zip" 
                className={
                  this.props.checkNull(this.props.state.secondaryBankZip)
                    ? "form-control "
                    : "form-control"
                }
                name="secondaryBankZip"
                onChange={this.props.onChange}
                value={this.props.state.secondaryBankZip}
              />
              {/* {this.props.checkNull(
                this.props.state.secondaryBankZip
              ) && (
                <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                  Please Enter secondary bank Zip
                </span>
              )} */}
              </div>
            </div>
          </div>
          {this.props.step === 4 && (
            <button
              className="pull-right mt-4"
              onClick={() => this.props.next()}
            >
              Continue
            </button>
          )}
        </div>
      </div>
      </div>
    );
  }
}

export default withRouter(OfficeApplicationStep4);
