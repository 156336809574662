import React/*,{ useEffect, useState }*/ from "react";
import "./DashCard.css";

const DashCard = (props) => {
  const { label, amount, percentage, icon } = props.data;
  function printPercentage(percent) {
    if (percent > 75) {
      return (
        <span className="float-sm-left text-muted cardSub">
          <br />
        </span>
      );
    } else if (percent > 40) {
      return (
        <span className="float-sm-left text-muted cardSub">
          <b>
            <i className="fa fa-angle-down text-primary"></i>
          </b>
          <span className="text-primary">&nbsp; {percent}% &nbsp;</span>
          <span className="received">received</span>
        </span>
      );
    } else {
      return (
        <span className="float-sm-left text-muted cardSub">
          <b>
            <i className="fa fa-angle-down text-danger"></i>
          </b>
          <span className="text-danger">&nbsp; {percent}% &nbsp;</span>
          <span className="received">received</span>
        </span>
      );
    }
  }
  return (
    <div className="col cartPart cart BackStatus dashCover ">
      <div className="row">
        <div className="col-sm-5 cardIcon ">
          <i className={icon}></i>
        </div>
        <div className="col-sm-7 " >
          <span className="float-sm-left cardSub">{label}</span>
          <h2 className="float-sm-left cardMain">${amount}</h2>
          {printPercentage(percentage)}
        </div>
      </div>
    </div>
  );
};

export default DashCard;
