import React, { Component } from "react";
import { withRouter } from "react-router";

import "../../officeApplication.css";

class OfficeApplicationStep5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    };
    
  }

  render() {
    return (
      <div className="row " id={"officeAppStep" + this.props.index}>
        <div className="col-3"></div>
        <div className="col-9  pt-1 d-flex justify-content-center">
          <div className="navBottom1">
            <p className="officeApplicationTitle mb-4 ">Credit Reference</p>
            {this.props.state.activeReference === 1 ? (
              <>
                <p className="officeApplicationSubTitle1 pb-0 mb-0">
                  {" "}
                  First Reference
                </p>
                <div className="row pr-3 pl-3">
                  <div className="col-6 text-center ">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Company Name
                    </p>
                    <input
                      type="text"
                      placeholder="Company Name"
                      name="referenceCompanyName"
                      className={
                        this.props.checkNull(
                          this.props.state.referenceCompanyName
                        )
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      onChange={this.props.onChange}
                      value={this.props.state.referenceCompanyName}
                      
                    />
                    {(this.props.checkNull(this.props.state.referenceCompanyName) && 
                      <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                        Please Enter Company Name 
                      </span>
                    )}
                  </div>

                  <div className="col-6 text-center ">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Contact Name
                    </p>
                    <input
                      type="text"
                      className={
                        this.props.checkNull(
                          this.props.state.referenceCompanyContactName
                        )
                          ? "form-control alert mb-0"
                          : "form-control"
                      }
                      placeholder="Contact Name"
                      name="referenceCompanyContactName"
                      onChange={this.props.onChange}

                      value={this.props.state.referenceCompanyContactName}
                      
                    />
                    {(this.props.checkNull(this.props.state.referenceCompanyContactName) && 
                      <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                        Please Enter Company Contact Name 
                      </span>
                    )}
                  </div>
                </div>
                <div className="row pr-3 pl-3">
                  <div className="col-12 text-center ">
                    <p className="officeApplicationSubTitle pull-left ">
                      Address
                    </p>
                  </div>
                  <div className="col-6 text-center ">
                    <div className="input-group mb-3 ">
                      <input
                        type="text"
                        placeholder="Address"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompanyAddress
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompanyAddress"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompanyAddress}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompanyAddress) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company Address 
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group mb-3 ">
                      <input
                        type="text"
                        placeholder="State"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompanyState
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompanyState"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompanyState}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompanyState) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company State
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group ">
                      <input
                        type="text"
                        placeholder="City"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompanyCity
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompanyCity"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompanyCity}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompanyCity) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company City 
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="input-group ">
                      <input
                        type="text"
                        placeholder="Zip"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompanyZip
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompanyZip"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompanyZip}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompanyZip) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company Zip 
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <p className="officeApplicationSubTitle pull-left mb-3">
                      Phone
                    </p>
                    <div className="input-group ">
                      <input
                        type="tel"
                        placeholder="Phone"
                        className={
                          this.props.checkMobile(
                            this.props.state.referenceCompanyPhone
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompanyPhone"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompanyPhone}
                        
                      />
                      {(this.props.checkMobile(this.props.state.referenceCompanyPhone) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          {this.props.checkNull(this.props.state.referenceCompany3Phone)? "Please Enter Company Phone no ":"Please Enter Company Phone no correct format"}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div
                className="officeBar"
                onClick={() => this.props.setParentState("activeReference", 1)}
              >
                {" "}
                <p className="officeApplicationSubTitle1 pb-0 mb-0">
                  {" "}
                  First Reference
                </p>
              </div>
            )}
            {this.props.state.stage > 1 &&
              (this.props.state.activeReference === 2 ? (
                <>
                  <p className="officeApplicationSubTitle1 pb-0 mb-0">
                    {" "}
                    Second Reference
                  </p>
                  <div className="row pr-3 pl-3">
                    <div className="col-6 text-center ">
                      <p className="officeApplicationSubTitle pull-left mb-3">
                        Company Name
                      </p>
                      <input
                        type="text"
                        placeholder="Company Name"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompany2Name
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompany2Name"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompany2Name}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompany2Name) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company Name 
                        </span>
                      )}
                    </div>

                    <div className="col-6 text-center ">
                      <p className="officeApplicationSubTitle pull-left mb-3">
                        Contact Name
                      </p>
                      <input
                        type="text"
                        placeholder="Contact Name"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompany2ContactName
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompany2ContactName"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompany2ContactName}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompany2ContactName) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company Contact Name 
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row pr-3 pl-3">
                    <div className="col-12 text-center ">
                      <p className="officeApplicationSubTitle pull-left ">
                        Address
                      </p>
                    </div>
                    <div className="col-6 text-center ">
                      <div className="input-group mb-3 ">
                        <input
                          type="text"
                          placeholder="Address"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany2Address
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany2Address"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany2Address}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany2Address) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company Address 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <div className="input-group mb-3 ">
                        <input
                          type="text"
                          placeholder="State"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany2State
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany2State"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany2State}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany2State) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company State 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="City"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany2City
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany2City"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany2City}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany2City) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company City 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="Zip"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany2Zip
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany2Zip"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany2Zip}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany2Zip) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company Zip 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <p className="officeApplicationSubTitle pull-left mb-3">
                        Phone
                      </p>
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="Phone no"
                          className={
                            this.props.checkMobile(
                              this.props.state.referenceCompany2Phone
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany2Phone"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany2Phone}
                          
                        />
                        {(this.props.checkMobile(this.props.state.referenceCompany2Phone) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                           {this.props.checkNull(this.props.state.referenceCompany2Phone)? "Please Enter Company Phone no ":"Please Enter Company Phone no correct format"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="officeBar"
                  onClick={() => this.props.setParentState("activeReference", 2)}
                >
                  {" "}
                  <p className="officeApplicationSubTitle1 pb-0 mb-0">
                    {" "}
                    Second Reference
                  </p>
                </div>
              ))}
            {this.props.state.stage > 2 &&
              (this.props.state.activeReference === 3 ? (
                <>
                  <p className="officeApplicationSubTitle1 pb-0 mb-0">
                    {" "}
                    Third Reference
                  </p>
                  <div className="row pr-3 pl-3">
                    <div className="col-6 text-center ">
                      <p className="officeApplicationSubTitle pull-left mb-3">
                        Company Name
                      </p>
                      <input
                        type="text"
                        placeholder="Company Name"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompany3Name
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompany3Name"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompany3Name}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompany3Name) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company Name 
                        </span>
                      )}
                    </div>

                    <div className="col-6 text-center ">
                      <p className="officeApplicationSubTitle pull-left mb-3">
                        Contact Name
                      </p>
                      <input
                        type="text"
                        placeholder="Contact Name"
                        className={
                          this.props.checkNull(
                            this.props.state.referenceCompany3ContactName
                          )
                            ? "form-control alert mb-0"
                            : "form-control"
                        }
                        name="referenceCompany3ContactName"
                        onChange={this.props.onChange}
  
                        value={this.props.state.referenceCompany3ContactName}
                        
                      />
                      {(this.props.checkNull(this.props.state.referenceCompany3ContactName) && 
                        <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                          Please Enter Company Contact Name 
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row pr-3 pl-3">
                    <div className="col-12 text-center ">
                      <p className="officeApplicationSubTitle pull-left ">
                        Address
                      </p>
                    </div>
                    <div className="col-6 text-center ">
                      <div className="input-group mb-3 ">
                        <input
                          type="text"
                          placeholder="Address"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany3Address
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany3Address"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany3Address}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany3Address) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company Address 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <div className="input-group mb-3 ">
                        <input
                          type="text"
                          placeholder="State"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany3State
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany3State"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany3State}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany3State) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company State 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="City"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany3City
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany3City"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany3City}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany3City) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company City 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="Zip"
                          className={
                            this.props.checkNull(
                              this.props.state.referenceCompany3Zip
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany3Zip"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany3Zip}
                          
                        />
                        {(this.props.checkNull(this.props.state.referenceCompany3Zip) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                            Please Enter Company Zip 
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <p className="officeApplicationSubTitle pull-left mb-3">
                        Phone
                      </p>
                      <div className="input-group ">
                        <input
                          type="text"
                          placeholder="Phone"
                          className={
                            this.props.checkMobile(
                              this.props.state.referenceCompany3Phone
                            )
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="referenceCompany3Phone"
                          onChange={this.props.onChange}
    
                          value={this.props.state.referenceCompany3Phone}
                          
                        />
                        {(this.props.checkMobile(this.props.state.referenceCompany3Phone) && 
                          <span className="textPri pull-left" style={{ width:"100%",textAlign:"left" }}>
                             {this.props.checkNull(this.props.state.referenceCompany3Phone)? "Please Enter Company Phone no ":"Please Enter Company Phone no correct format"}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="officeBar"
                  onClick={() => this.props.setParentState("activeReference",3)}
                >
                  {" "}
                  <p className="officeApplicationSubTitle1 pb-0 mb-0">
                    {" "}
                    Third Reference
                  </p>
                </div>
              ))}
            {this.props.step === 5 && this.props.state.stage > 2 ? (
              <button
                className="pull-right mt-4"
                onClick={() => this.props.next()}
              >
                Continue
              </button>
            ) : (
              <button
                className="pull-right mt-4"
                onClick={() => this.props.addReference()}
              >
                Add{" "}
                {this.props.state.stage === 1
                  ? "Second"
                  : this.props.state.stage === 2
                  ? "Third"
                  : ""}{" "}
                Reference
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfficeApplicationStep5);
