import React, { Component } from "react";
import "./torontoStyle.css";
import logo from "../../../../Images/logo.jpeg";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
class InfoReview extends Component {
  render() {
    return (
      <div>
        <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className="row applicationAgreementHead">
              <div className="col-6 torontoAgreementHeadLeft">
                <div className=" text-left">
                  <img src={logo} className="agreementLogo " alt="logo" />
                </div>
              </div>

              <div className="col-6 applicationAgreementHeadRight">
                <div className=" text-right">
                  <span className="span7">
                    <b>Appendix: General Information</b>
                  </span>
                </div>
              </div>
            </div>

            <div className="col m-4 ">
              <span className=" p-0 m-0 span7 ">
                This Appendix sets out basic information for landlords and
                tenants. It is not intended as legal advice, and it is not an
                official interpretation of the Residential Tenancies Act, 2006
                (the Act). Please refer to the Act for the specific rules. The
                Landlord and Tenant Board also provides information about
                landlords' and tenants' rights and responsibilities under the
                Act.
              </span>

              <div>
                <span className="span7  ">
                  <b>Landlord and Tenant Board: </b> <br></br>
                  Toll free: 1-888-332-3234 <br></br>
                  Toronto area: 416-645-8080 <br></br>
                  TTY: Bell Relay Service at 1-800-855-0511 <br></br>
                  Website: www.tribunalsontario.ca/ltb/
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">A. When to Use This Form</p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  This form (standard form of lease) must be used for most
                  residential tenancy agreements (leases).<br></br>
                  This form should not be used for:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      care homes, <br></br>
                    </li>
                    <li className="p-2">
                      sites in mobile home parks or land lease communities,{" "}
                      <br></br>
                    </li>
                    <li className="p-2">
                      social and supportive housing that is exempt from the rent
                      increase guideline (see the regulation under the Act for
                    </li>
                    <li className="p-2">
                      specific exemptions), <br></br>
                    </li>
                    <li className="p-2">
                      member units in co-operative housing, and <br></br>
                    </li>
                    <li className="p-2">
                      any other accommodation that is exempt from the Act (see
                      Section 5 of the Act).
                    </li>
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">B. Change of Landlord</p>
                </div>
              </div>

              <div className="col p-0 m-0 pt-2">
                <span className="span7 ">
                  A new landlord has the same rights and duties as the previous
                  landlord. A new landlord must follow all the terms of this
                  agreement unless the tenant and new landlord agree to other
                  terms. A new landlord should provide the tenant with their
                  legal name and address
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    <b>C. Renewing a Tenancy Agreement </b> (Part V of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  If the landlord and tenant agree that the tenancy will last
                  for a specific period of time, this is called a fixed term
                  tenancy. This is because both the start and end date are set
                  out in the tenancy agreement. <br></br>
                  The end of an agreement does not mean the tenant has to move
                  out or sign a renewal or new agreement in order to stay. The
                  rules of the agreement will still apply and the tenant still
                  has the right to stay:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      as a monthly tenant, if the agreement was for a fixed term
                      or monthly tenancy, <br></br>
                    </li>
                    <li className="p-2">
                      as a weekly tenant, if the agreement was for a weekly
                      tenancy, or <br></br>
                    </li>

                    <li className="p-2">
                      as a daily tenant, if the agreement was for a daily
                      tenancy.
                    </li>
                  </span>
                </div>
                <span className="span7 ">
                  The landlord and tenant can also agree to renew the agreement
                  for another fixed term or enter into a new agreement. In any
                  case, changes to the rent must follow the rules under the Act
                  (see Part I below for further information).
                </span>
              </div>
            </div>
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    <b>D. Ending the Tenancy </b>(Part V of the Act){" "}
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  The landlord or tenant must follow the rules of the Act when
                  ending a tenancy. <br></br>
                  <b>When the tenant can end the tenancy </b> <br></br>
                  he tenant can end a tenancy by giving the landlord proper
                  notice using the appropriate Landlord and Tenant Board form.
                  They must give:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      at least 60 days’ notice if they have a monthly or fixed
                      term tenancy, or <br></br>
                    </li>
                    <li className="p-2">
                      at least 28 days’ notice if they have a daily or weekly
                      tenancy.
                    </li>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  For a fixed term tenancy, the notice cannot be effective
                  before the last day of the fixed term. For a monthly or weekly
                  tenancy, the notice must be effective on the last day of a
                  rental period (e.g. month or week). <br></br>
                  In certain situations, a tenant who has experienced sexual or
                  domestic violence can give 28 days’ notice to end the tenancy
                  at any time, even if the tenant has a fixed term agreement
                  (e.g., one year agreement). They must use the notice form
                  approved by the Landlord and Tenant Board. <br></br>
                  <b>When the landlord can end the tenancy </b> <br></br>
                  The landlord can only give the tenant notice to end the
                  tenancy in certain situations. These situations are set out in
                  the Act. The landlord cannot evict the tenant unless the
                  landlord follows the proper rules. These rules are set out in
                  the Act. In most cases, the landlord must give proper notice
                  to end the tenancy using the right form. Forms are available
                  on the Landlord and Tenant Board’s website. <br></br>
                  If the landlord gives a tenant notice to end the tenancy, the
                  tenant does not have to move out. <br></br>
                  The landlord can give the tenant notice to end the tenancy in
                  certain situations where the tenant is at fault. Examples
                  include:
                </span>

                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      tenant does not pay the full rent when it is due,{" "}
                      <br></br>
                    </li>
                    <li className="p-2">
                      tenant causes damage to the rental unit or building, and{" "}
                      <br></br>
                    </li>
                    <li className="p-2">
                      tenant substantially interferes with the reasonable
                      enjoyment of other tenants or the landlord.
                    </li>
                  </span>
                </div>
                <span className="span7 ">
                  The landlord may also give notice to end a tenancy in certain
                  situations that are not the tenant’s fault, but only at the
                  end of the term or rental period. In these cases, landlords
                  must still give proper notice, and tenants may be entitled to
                  compensation and/or the right to return to the unit. Examples
                  include:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      landlord or purchaser needs the unit for themselves, an
                      immediate family member, or caregiver, and <br></br>
                    </li>
                    <li className="p-2">
                      landlord needs to do extensive repairs or renovations that
                      require a building permit and vacant possession of the
                      unit.
                    </li>
                  </span>
                </div>
                <span className="span7 ">
                  If the tenant does not move out, the landlord must apply to
                  the Landlord and Tenant Board in order to evict the tenant.
                  The Landlord and Tenant Board will hold a hearing and decide
                  if the tenancy should end. Both the landlord and the tenant
                  can come to the hearing and explain their side to the Landlord
                  and Tenant Board. If the Landlord and Tenant Board orders an
                  eviction, the eviction order can only be enforced by the
                  Sheriff (Court Enforcement Officer). <br></br>
                  It is an offence for the landlord to evict a tenant without
                  following this process. If convicted, the landlord could face
                  a fine of up to <CurrencyFormat value={50000} /> (for an individual) or <CurrencyFormat value={250000} /> (for a
                  corporation).<br></br>
                  <b>
                    If the Landlord and Tenant agree to end the tenancy{" "}
                  </b>{" "}
                  <br></br>
                  The tenant and landlord can agree to end a tenancy at any time
                  by using the proper Landlord and Tenant Board form. Some
                  landlords may ask the tenant to sign that form when signing
                  the tenancy agreement (lease). In most cases, an agreement to
                  end a tenancy signed at the beginning of the tenancy agreement
                  is unenforceable and the tenant does not have to move out.{" "}
                  <br></br>
                  There is more information on how to end a tenancy and reasons
                  for eviction in the Act and in brochures on the Landlord and
                  Tenant Board website.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    <b>E. Giving Notices and Documents </b> (Part XII of the
                    Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  The landlord and tenant have to deliver some official notices
                  and other documents in writing. These notices and documents
                  can be:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      hand delivered, <br></br>
                    </li>
                    <li className="p-2">
                      left in a mail box or a place where mail is ordinarily
                      delivered, or <br></br>
                    </li>
                    <li className="p-2">
                      mailed (this will count as delivered five days after
                      mailing). <br></br>
                    </li>
                  </span>
                </div>
                <span className="span7 ">
                  There are also other ways to serve notices and documents. For
                  more information, contact the Landlord and Tenant Board or see
                  the Rules of Practice on its website
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b> F. Rent and Rent Receipts </b> (Part VII of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  Rent is the amount the tenant pays to the landlord to occupy
                  the rental unit and receive services or facilities agreed to
                  in this agreement. <br></br>
                  The tenant must pay their rent on time. If they do not, the
                  landlord can give them notice to end the tenancy. <br></br>
                  If the tenant asks for a receipt for rent or any payment or
                  deposit, the landlord must give them one for free. This also
                  applies to a former tenant who asks for a receipt within 12
                  months after the end of their tenancy.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    <b>G. Rent Discounts </b> (Part XII of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  The landlord can offer the tenant a discount for paying rent
                  on or before the date it is due. This discount can be up to
                  two per cent of the lawful rent. <br></br>
                  The landlord can also offer rent-free periods or discounts in
                  one of three ways:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      Rent-free periods of up to three months within any
                      12-month period, <br></br>
                    </li>
                    <li className="p-2">
                      A discount of up to one month’s rent spread evenly over
                      eight months, or <br></br>
                    </li>
                    <li className="p-2">
                      A discount of up to two months’ rent, with up to one
                      month’s rent spread evenly over the first seven months,
                      and up to one month’s rent discounted in one of the last
                      five months.
                    </li>
                  </span>
                </div>
                <span className="span7 ">
                  These types of discounts must be agreed to in writing.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b> H. Deposits </b> (Part VII of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  The landlord can only collect a deposit for the last month’s
                  rent and a refundable key deposit. The tenant does not have to
                  provide any other form of deposit, such as pet or damage
                  deposits. If the tenant pays anything more, the tenant can
                  apply to the Landlord and Tenant Board to get the money back.{" "}
                  <br></br>
                  <b> Rent deposit (i.e. last month’s rent):</b> The landlord
                  can require a rent deposit on or before the tenant enters into
                  the tenancy agreement. The landlord must apply this money to
                  the rent for the last period of the tenancy. The rent deposit
                  must not be more than one month’s rent or the rent for one
                  rental period (e.g., one week in a weekly tenancy), whichever
                  is less. <br></br>
                  The landlord must pay the tenant interest on the rent deposit
                  every year. If the rent increases after the tenant has paid a
                  rent deposit, the landlord can require the tenant to top-up
                  the rent deposit so that it is the same as the new rent. The
                  landlord can use the interest on the rent deposit to top-up
                  the rent deposit. <br></br>
                  If the landlord is unable to let the tenant move into the
                  rental unit, the landlord must return the deposit, unless the
                  tenant agrees to rent a different unit. <br></br>
                  <b> Key deposit: </b> If the landlord collects a deposit for
                  key(s), remote entry devices or cards, the landlord must
                  return the deposit when the tenant gives back their key(s) at
                  the end of the tenancy. <br></br>
                  The landlord can charge the tenant for additional keys that
                  the tenant requests (for example, if the tenant wants an extra
                  key or if the tenant has lost their key), but the charge
                  cannot be more than actual cost of the keys. This is not a key
                  deposit.
                </span>
              </div>
            </div>
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    <b>I. Rent Increases and Decreases </b> (Part VII of the
                    Act){" "}
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7">
                  Normally, the landlord can increase the rent only once every
                  12 months. The landlord must use the proper Landlord and
                  Tenant Board form and give the tenant at least 90 days' notice
                  before the rent increase is to take effect. <br></br>
                  <b>Guideline Rent Increases </b> <br></br>
                  In most cases, the rent can be increased by no more than the
                  rent increase guideline unless the Landlord and Tenant Board
                  approves a rent increase above the guideline. The guideline
                  for each year can be found on the Landlord and Tenant Board's
                  website. Some newer units are not subject to the rent increase
                  guideline, including:
                </span>
                <div className="col pt-2">
                  <span className="span7 ">
                    • A unit in a new building, if no part of the building was
                    occupied for residential purposes on or before November 15,
                    2018; <br></br>• A unit in a new addition to an existing
                    building, if no part of the addition was occupied for
                    residential purposes on or before November 15, 2018; and,{" "}
                    <br></br>• A new second unit in an existing house, such as a
                    basement apartment, that was created after November 15, 2018
                    and that meets the requirements set out in the Act.{" "}
                    <br></br>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  <b>Rent Increases above the Guideline </b> <br></br>
                  The landlord can apply to the Landlord and Tenant Board for
                  approval to raise the rent by more than the rent increase
                  guideline. Affected tenants can oppose this application at the
                  Landlord and Tenant Board. <br></br>
                  This kind of rent increase is called an above-guideline rent
                  increase. The Landlord and Tenant Board can allow this kind of
                  rent increase if: <br></br>
                </span>

                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      the landlord's municipal taxes and charges have increased
                      significantly, <br></br>
                    </li>
                    <li className="p-2">
                      the landlord has done major repairs or renovations, or{" "}
                      <br></br>
                    </li>
                    <li className="p-2">
                      the costs of external security services (i.e. not
                      performed by the landlord’s employees) have increased, or
                      external security services are being provided for the
                      first time. <br></br>
                    </li>
                  </span>
                </div>
                <span className="span7 ">
                  The landlord and tenant can also agree to an above-guideline
                  rent increase, if the landlord agrees to renovate or add a new
                  service for the tenant. Certain rules apply. <br></br>
                  <b>Rent Reductions: </b>
                  The landlord <b> must </b> reduce the rent if: <br></br>
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      the municipal property tax goes down by more than 2.49 per
                      cent, or <br></br>
                    </li>
                    <li className="p-2">
                      the rent was increased above the guideline to pay for
                      repairs or renovations and the costs have been fully paid
                      for (this only applies to tenants who were living in the
                      unit when the above guideline rent increase happened).{" "}
                      <br></br>
                    </li>
                  </span>
                </div>
                <span className="span7 ">
                  The tenant can apply to the Landlord and Tenant Board to
                  reduce their rent if:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      municipal property taxes or charges on the rental property
                      go down, <br></br>
                    </li>
                    <li className="p-2">
                      the landlord reduced or removed a service without reducing
                      the rent, or <br></br>
                    </li>
                    <li className="p-2">
                      the landlord did not keep a promise they made in an
                      agreement for a rent increase above the guideline{" "}
                      <br></br>
                    </li>
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b> J. Maintenance and Repairs </b> (Part III, IV, V and XIV
                    of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  The landlord must keep the rental unit and property in good
                  repair and comply with all health, safety and maintenance
                  standards. This includes the maintenance and repair of things
                  that came with the unit, such as appliances, and of common
                  areas, such as parking lots, elevators, and hallways.{" "}
                  <br></br>
                  The tenant must pay their rent, even if they have problems
                  with the maintenance and repair of their unit or property. If
                  the tenant is having a maintenance or repair problem, the
                  tenant should let the landlord know. If needed, the tenant can
                  apply to the Landlord and Tenant Board. <br></br>
                  The tenant is responsible for any damage to the rental
                  property caused by the tenant, the tenant’s guest or another
                  person who lives in the rental unit. This applies to any
                  damage caused on purpose or by not being careful enough. This
                  does not include damage that results from normal use of the
                  rental unit over time (“wear and tear”). The landlord can
                  apply to the Landlord and Tenant Board if the tenant has not
                  repaired such damage. <br></br>
                  The tenant is responsible for ordinary cleanliness of the
                  rental unit, except for any cleaning the landlord agreed to
                  do.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b> K. Vital Services </b> (Part I and III of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 p-0 m-0">
                  “Vital services” are hot or cold water, fuel, electricity, gas
                  and heat. <br></br>
                  The landlord must ensure that a rental unit has heating
                  equipment capable of maintaining a minimum temperature of 20°
                  Celsius from September 1 to June 15. Some municipal by-laws
                  may have stricter requirements. <br></br>
                  The landlord cannot withhold or shut off the reasonable supply
                  of a vital service, care service or food that the landlord
                  must supply under the tenancy agreement. If a vital service is
                  cut-off because the landlord failed to pay their bill, the
                  landlord is considered to have withheld that service. However,
                  if a vital service is cut-off or disconnected because the
                  tenant failed to pay their own utility bill, the tenant cannot
                  claim that the landlord withheld a vital service. <br></br>
                  The landlord cannot deliberately interfere with the reasonable
                  supply of any vital service, care service or food, whether or
                  not the landlord is obligated to supply it under the tenancy
                  agreement. <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b> L. Harassment </b> (Part III and IV of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  It is against the law for the landlord (or anyone acting for
                  the landlord, such as a superintendent or property manager) to
                  harass the tenant, or for the tenant to harass the landlord.
                  If the landlord or the tenant is experiencing harassment they
                  can apply to the Landlord and Tenant Board.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>M. Discrimination </b>
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  f the landlord (or anyone acting for the landlord)
                  discriminates against the tenant based on prohibited grounds
                  of discrimination under the Ontario Human Rights Code (the
                  Code), they may be violating the tenant’s rights under the
                  Code. The Landlord and Tenant Board may be able to consider
                  discrimination if it relates to an application under the
                  Residential Tenancies Act, 2006. In other situations, the
                  tenant may have to take their case to the Human Rights
                  Tribunal of Ontario.
                </span>
              </div>
            </div>
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    <b>N. Landlord’s Entry into Rental Unit </b> (Part III of
                    the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  The tenant is entitled to reasonable enjoyment of the rental
                  unit (e.g. quiet enjoyment, reasonable privacy, freedom from
                  unreasonable disturbance and exclusive use of the rental
                  unit). <br></br>
                  The landlord can enter the rental unit with 24 hours’ written
                  notice only for the following reasons:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      make repairs, <br></br>
                    </li>
                    <li className="p-2">
                      inspect the unit to see if repairs are needed, if the
                      inspection is reasonable, <br></br>
                    </li>
                    <li className="p-2">
                      show the rental unit to a possible buyer, insurer or
                      mortgage lender, <br></br>
                    </li>
                    <li className="p-2">
                      let a real estate agent show the unit to a possible buyer,{" "}
                      <br></br>
                    </li>
                    <li className="p-2">
                      have a property inspection done before converting the
                      residential building into a condominium, or <br></br>
                    </li>
                    <li className="p-2">
                      for any reasonable purpose listed in the tenancy
                      agreement. <br></br>
                    </li>
                  </span>
                </div>
                <span className="span7">
                  The written notice must include the reason for the entry and
                  state the date and time (between 8 a.m. and 8 p.m.) that the
                  landlord will enter the unit. With proper notice, the landlord
                  can enter the unit when the tenant is not at home. <br></br>
                  The landlord does not need to give a notice to enter:
                </span>
                <div className="col pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      in case of emergency, <br></br>
                    </li>
                    <li className="p-2">
                      if the tenant consents to entry, <br></br>
                    </li>
                    <li className="p-2">
                      if the tenancy agreement requires the landlord to clean
                      the unit, or <br></br>
                    </li>
                    <li className="p-2">
                      if the tenancy is coming to an end and the landlord wants
                      to show the unit to a potential new tenant – the landlord
                      can only show the unit between 8:00 a.m. and 8:00 p.m. and
                      must make a reasonable effort to let the tenant know when
                      this will happen.
                    </li>
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>O. Locks </b> (Part III and IV of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 ">
                  The landlord cannot change the locks of the rental unit unless
                  the landlord gives the new keys to the tenant. The tenant
                  cannot change the locks of the rental unit without the consent
                  of the landlord.
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>Q. Guests </b> (Part III of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7  ">
                  The landlord cannot stop tenants from having guests, require
                  the tenant to notify the landlord or get the landlord's
                  permission before having guests. The landlord cannot charge
                  extra fees or raise the rent due to guests in the rental unit.
                  However, the tenant is responsible for the behaviour of their
                  guests. <br></br>
                  <br></br>
                  The landlord cannot prevent the tenant from having a roommate,
                  as long as municipal by-laws on occupancy standards are
                  respected.<br></br>
                  <br></br>
                  If a tenant rents their whole unit to someone else (e.g.
                  short-term rental), this person is not a “guest”. The tenant
                  may have to get the landlord’s permission.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>R. Pets</b> (Part III of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7  ">
                  A tenancy agreement cannot prohibit animals in the rental unit
                  or in or around the residential building. There are some cases
                  where the landlord can apply to the Landlord and Tenant Board
                  to evict a tenant who has a pet. These are some common
                  examples:
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>S. Smoking </b> (Part V of the Act)
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7  ">
                  The Act does not discuss smoking in a rental unit. The
                  landlord and tenant can use Section 10 of this lease to agree
                  to either allow or prohibit smoking in the unit, and/or on the
                  landlord’s property. Even if the lease doesn’t prohibit
                  smoking, the landlord may apply to the Landlord and Tenant
                  Board to end the tenancy if the smoking:
                </span>

                <div></div>

                <span className="span7  ">
                  If the tenant believes that other people smoking in their
                  building affects their health or safety, contravenes
                  maintenance standards, or substantially interferes with their
                  reasonable enjoyment of the rental unit, they should discuss
                  it with their landlord before contacting the Landlord and
                  Tenant Board.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>T. Smoke and Carbon Monoxide Alarms </b>
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7  ">
                  The landlord must provide the rental unit with working smoke
                  alarms and, where applicable, carbon monoxide alarms. The
                  landlord is responsible for keeping smoke and carbon monoxide
                  alarms in working condition, which includes replacing the
                  batteries. The tenant must not disconnect or tamper with any
                  smoke or carbon monoxide alarm and must notify the landlord
                  immediately of any alarms not working properly.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>U. Resolving Disputes </b>
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7  ">
                  The landlord and tenant are required to follow the law. If
                  they have problems or disagreements, the landlord and tenant
                  should first discuss the issue and attempt to resolve it
                  themselves. If the landlord or tenant feels that the other is
                  not obeying the law, they may contact the Landlord and Tenant
                  Board for information about their rights and responsibilities,
                  including whether they may apply to the Landlord and Tenant
                  Board to resolve the dispute.
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <p className="pl-2 m-1">
                    {" "}
                    <b>Guide to the Standard Lease</b>{" "}
                  </p>
                </div>
              </div>

              <div className="col pt-2 p-0 m-0">
                <span className="span7 "></span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default InfoReview;
