import React, { Component } from "react";
import "./App.css";
import Occupancy from "./components/Occupancy/Occupancy";
import DashCardApplication from "./components/Application/DashCardApplication";
import DashMainCard from "./components/firstCard/DashMainCard";
import MaintenanceTable from "./components/Maintenance/MaintenanceTable";
import RentalTable from "./components/Rental/RentalTable";
import Rating from "./components/Rating/Rating";
import Lead from "./components/Lead/Lead";
import { getLeads } from "../../Services/ApiServices";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount1: (238650).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      amount2: (125659).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      amount3: (59133.6).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      Leads: [],
      Leads1: [],
      refreshSearch: true,
      data: [
        {
          id: "001",
          icon: "fa fa-pie-chart fa-2x round",
          label: "Received (YTD)",
          amount: (238650).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          percentage: "82",
        },
        {
          id: "002",
          icon: "fa fa-calendar fa-2x round",
          label: "Received (MTD)",
          amount: (125659).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          percentage: "68",
        },

        {
          id: "003",
          icon: "fa fa-bell fa-2x round",
          label: "Receivables",
          amount: (59133.6).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          percentage: "32",
        },
      ],
      rentalData: [
        {
          id: "001",
          unitName: "B124",
          tenantName: "	Thomas Joe",
          tenantEmail: "tjoe@gmail.com",
          phoneNo: "+61 076 7020249",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Dec 12 2020",
          ContractStartDate: "Apr 5 2020",
          ContractEndDate: "Apr 5 2021",
          No_Maintains: "5",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "002",
          unitName: "B125",
          tenantName: "George	Reece",
          tenantEmail: "ree7george@gmail.com",
          phoneNo: "+61 076 7023742",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Nov 31 2020",
          ContractStartDate: "Oct 12 2019",
          ContractEndDate: "Oct 12 2021",
          No_Maintains: "4",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "003",
          unitName: "B126",
          tenantName: "Oscar	Rhys",
          tenantEmail: "oscarrhy19@gmauil.com",
          phoneNo: "+61 076 76040275",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Dec 31 2020",
          ContractStartDate: "Feb 12 2020",
          ContractEndDate: "Feb 12 2021",
          No_Maintains: "12",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "004",
          unitName: "B127",
          tenantName: "James	Charlie",
          tenantEmail: "jamescharlie12@gmauil.com",
          phoneNo: "+61 076 2020247",
          rentReceived: "40",
          transactionType: "application",
          LastPaymentDate: "Dec 29 2020",
          ContractStartDate: "Apr 01 2018",
          ContractEndDate: "Apr 01 2021",
          No_Maintains: "11",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "005",
          unitName: "B128",
          tenantName: "Oliver	Jake",
          tenantEmail: "oliverjake78@gmauil.com",
          phoneNo: "+61 076 1020742",
          rentReceived: "300",
          transactionType: "pet",
          LastPaymentDate: "Nov 30 2020",
          ContractStartDate: "May 11 2020",
          ContractEndDate: "May 11 2022",
          No_Maintains: "8",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "006",
          unitName: "B129",
          tenantName: "Jack Connor",
          tenantEmail: "jackconner78@gmauil.com",
          phoneNo: "+61 076 7010243",
          rentReceived: "1000",
          transactionType: "security",
          LastPaymentDate: "Dec 30 2020",
          ContractStartDate: "Jan 14 2019",
          ContractEndDate: "Jan 14 2020",
          No_Maintains: "2",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "007",
          unitName: "B130",
          tenantName: "Harry Callum",
          tenantEmail: "harry123@gmauil.com",
          phoneNo: "+61 076 2020751",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Dec 31 2020",
          ContractStartDate: "June 25 2020",
          ContractEndDate: "June 25 2022",
          No_Maintains: "9",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "008",
          unitName: "B131",
          tenantName: "Emma	William",
          tenantEmail: "emma145@gmauil.com",
          phoneNo: "+61 076 7420547",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Jan 12 2021",
          ContractStartDate: "Oct 18 2020",
          ContractEndDate: "Oct 18 2022",
          No_Maintains: "2",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "009",
          unitName: "B132",
          tenantName: "Charlie Kyle",
          tenantEmail: "kyle12@gmauil.com",
          phoneNo: "+61 076 1040589",
          rentReceived: "1000",
          transactionType: "security",
          LastPaymentDate: "Nov 25 2020",
          ContractStartDate: "Nov 25 2020",
          ContractEndDate: "Nov 25 2020",
          No_Maintains: "14",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "010",
          unitName: "B133",
          tenantName: "Isabella	Elizabeth",
          tenantEmail: "isabella45@gmauil.com",
          phoneNo: "+61 076 4070596",
          rentReceived: "40",
          transactionType: "application",
          LastPaymentDate: "Nov 30 2020",
          ContractStartDate: "Jun 01 20218",
          ContractEndDate: "Jun 01 2021",
          No_Maintains: "14",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "011",
          unitName: "B134",
          tenantName: "Mia Barbara",
          tenantEmail: "sam@gmauil.com",
          phoneNo: "+61 076 7010219",
          rentReceived: "100",
          transactionType: "mail box",
          LastPaymentDate: "Nov 25 2020",
          ContractStartDate: "Nov 25 2020",
          ContractEndDate: "Nov 25 2020",
          No_Maintains: "24",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "012",
          unitName: "B135",
          tenantName: "Emily Susan",
          tenantEmail: "emily145@gmauil.com",
          phoneNo: "+61 076 7030159",
          rentReceived: "1000",
          transactionType: "security",
          LastPaymentDate: "Nov 29 2020",
          ContractStartDate: "Jan 12 2019",
          ContractEndDate: "Jan 12 2021",
          No_Maintains: "16",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "013",
          unitName: "B136",
          tenantName: "Abigail Margaret",
          tenantEmail: "abigail452@gmauil.com",
          phoneNo: "+61 076 7040595",
          rentReceived: "100",
          transactionType: "mail box",
          LastPaymentDate: "Dec 29 2020",
          ContractStartDate: "Aug 12 2018",
          ContractEndDate: "Aug 12 2020",
          No_Maintains: "12",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "014",
          unitName: "B137",
          tenantName: "Madison Jessica",
          tenantEmail: "madisonjessica@gmauil.com",
          phoneNo: "+61 076 7020359",
          rentReceived: "40",
          transactionType: "application",
          LastPaymentDate: "Dec 30 2020",
          ContractStartDate: "Oct 01 2019",
          ContractEndDate: "Oct 01 2020",
          No_Maintains: "11",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "015",
          unitName: "B138",
          tenantName: "Charlotte	Sarah",
          tenantEmail: "charlttasarah@gmauil.com",
          phoneNo: "+61 076 4020556",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Dec 25 2020",
          ContractStartDate: "Feb 14 2018",
          ContractEndDate: "Feb 14 2021",
          No_Maintains: "24",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "016",
          unitName: "B139",
          tenantName: "Sophie	Tracy",
          tenantEmail: "sophietra1235@gmauil.com",
          phoneNo: "+61 076 4020428",
          rentReceived: "1000",
          transactionType: "security",
          LastPaymentDate: "Nov 25 2020",
          ContractStartDate: "Aug 11 2017",
          ContractEndDate: "Aug 11 2021",
          No_Maintains: "7",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "017",
          unitName: "B140",
          tenantName: "Smith	Murphy",
          tenantEmail: "murphysmith78@gmauil.com",
          phoneNo: "+61 076 1050275",
          rentReceived: "40",
          transactionType: "application",
          LastPaymentDate: "Dec 30 2020",
          ContractStartDate: "July 01 2020",
          ContractEndDate: "July 01 2021",
          No_Maintains: "6",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "018",
          unitName: "B141",
          tenantName: "	O'Kelly	Johnson",
          tenantEmail: "johnson95K@gmauil.com",
          phoneNo: "+61 076 7070123",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Jan 28 2020",
          ContractStartDate: "Nov 12 2018",
          ContractEndDate: "Nov 12 2020",
          No_Maintains: "16",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "019",
          unitName: "B142",
          tenantName: "Wals	Brown",
          tenantEmail: "sam@gmauil.com",
          phoneNo: "+61 076 7045785",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Dec 28 2020",
          ContractStartDate: "Jun 18 2019",
          ContractEndDate: "Jun 18 2021",
          No_Maintains: "14",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "020",
          unitName: "B143",
          tenantName: "Wilson	Byrne",
          tenantEmail: "wilby07@gmauil.com",
          phoneNo: "+61 076 2055658",
          rentReceived: "1500",
          transactionType: "rent",
          LastPaymentDate: "Dec 28 2020",
          ContractStartDate: "Oct 12 2018",
          ContractEndDate: "Oct 12 2020",
          No_Maintains: "6",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "021",
          unitName: "B144",
          tenantName: "Evans O'Ryan",
          tenantEmail: "evansor245@gmauil.com",
          phoneNo: "+61 076 7020247",
          rentReceived: "300",
          transactionType: "pet",
          LastPaymentDate: "Jan 2 2021",
          ContractStartDate: "May 2 2020",
          ContractEndDate: "May 2 2022",
          No_Maintains: "12",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "0",
          Key_Dep: "100",
          pet_dep: "300",
        },
        {
          id: "022",
          unitName: "B145",
          tenantName: "Jones	Wilson",
          tenantEmail: "johneswil@gmauil.com",
          phoneNo: "+61 076 7020142",
          rentReceived: "300",
          transactionType: "pet",
          LastPaymentDate: "Dec 31 2020",
          ContractStartDate: "Apr 2 2020",
          ContractEndDate: "Apr 2 2021",
          No_Maintains: "3",
          imagePath: "ram.png",
          application_Fee: "40",
          security_dep: "1000",
          parking_reservation: "2",
          Key_Dep: "100",
          pet_dep: "300",
        },
      ],
      MaintainanceData: [
        {
          id: "001",
          unitName: "B124",
          maintenanceType: "Plumbing",
          payerName: "Ragu",
          tenantEmail: "rahunath@gmauil.com",
          phoneNo: "0767000249",
          amount: "112",
          date: "Nov 3 2020",
          payerType: "Tenant",
          imagePath: "ram.png",
        },
        {
          id: "002",
          unitName: "B126",
          payerName: "",
          maintenanceType: "Electrical",
          tenantEmail: "rahunath@gmauil.com",
          phoneNo: "0767000249",
          amount: "152",
          date: "Nov 4 2020",
          payerType: "Tenant",
          imagePath: "ram.png",
        },
        {
          id: "003",
          unitName: "B114",
          payerName: "Electrical",
          maintenanceType: "Heating & air",
          tenantEmail: "rahunath@gmauil.com",
          phoneNo: "0767000249",
          amount: "124",
          date: "Nov 9 2020",
          payerType: "LandLoad",
          imagePath: "ram.png",
        },
        {
          id: "004",
          unitName: "B138",
          payerName: "Electrical",
          maintenanceType: "Electrical",
          tenantEmail: "rahunath@gmauil.com",
          phoneNo: "0767000249",
          amount: "152",
          date: "Nov 18 2020",
          payerType: "Tenant",
          imagePath: "ram.png",
        },
        {
          id: "005",
          unitName: "B104",
          payerName: "Other",
          maintenanceType: "Other",
          tenantEmail: "rahunath@gmauil.com",
          phoneNo: "0767000249",
          amount: "92",
          date: "Nov 18 2020",
          payerType: "LandLoad",
          imagePath: "ram.png",
        },
        {
          id: "006",
          unitName: "B191",
          payerName: "Ragu",
          maintenanceType: "Electrical",
          tenantEmail: "rahunath@gmauil.com",
          phoneNo: "0767000249",
          amount: "152",
          date: "Nov 24 2020",
          payerType: "Tenant",
          imagePath: "ram.png",
        },
        {
          id: "007",
          unitName: "B057",
          payerName: "Ragu",
          maintenanceType: "Heating & air",
          tenantEmail: "rahunath@gmauil.com",
          phoneNo: "0767000249",
          amount: "124",
          date: "Nov 24 2020",
          payerType: "Tenant",
          imagePath: "ram.png",
        },
      ],
      rating: [
        {
          id: "1",
          name: "James Cha",
          finalRating: "99",
          jan: "5",
          feb: "5",
          mar: "5",
          apr: "5",
          may: "4",
          jun: "5",
          jul: "5",
          aug: "5",
          sep: "4",
          oct: "5",
          nov: "5",
          dec: "5",
        },
        {
          id: "2",
          name: "Oliver Jake",
          finalRating: "91",
          jan: "4",
          feb: "5",
          mar: "4",
          apr: "5",
          may: "5",
          jun: "5",
          jul: "5",
          aug: "4",
          sep: "5",
          oct: "5",
          nov: "5",
          dec: "5",
        },
        {
          id: "3",
          name: "Mia Barbara",
          finalRating: "83",
          jan: "4",
          feb: "5",
          mar: "5",
          apr: "5",
          may: "5",
          jun: "5",
          jul: "4",
          aug: "5",
          sep: "5",
          oct: "5",
          nov: "5",
          dec: "4",
        },
        {
          id: "4",
          name: "Jack Connor",
          finalRating: "75",
          jan: "5",
          feb: "5",
          mar: "5",
          apr: "5",
          may: "4",
          jun: "5",
          jul: "5",
          aug: "4",
          sep: "5",
          oct: "4",
          nov: "5",
          dec: "5",
        },
        {
          id: "5",
          name: "James Charlie",
          finalRating: "73",
          jan: "5",
          feb: "4",
          mar: "5",
          apr: "4",
          may: "5",
          jun: "5",
          jul: "4",
          aug: "5",
          sep: "4",
          oct: "4",
          nov: "4",
          dec: "5",
        },
        {
          id: "6",
          name: "Jack Connor",
          finalRating: "72",
          jan: "5",
          feb: "5",
          mar: "4",
          apr: "5",
          may: "4",
          jun: "4",
          jul: "5",
          aug: "4",
          sep: "4",
          oct: "5",
          nov: "5",
          dec: "4",
        },
        {
          id: "7",
          name: "Harry Callum",
          finalRating: "70",
          jan: "4",
          feb: "4",
          mar: "3",
          apr: "5",
          may: "4",
          jun: "4",
          jul: "4",
          aug: "4",
          sep: "4",
          oct: "5",
          nov: "4",
          dec: "5",
        },
        {
          id: "8",
          name: "George Reece",
          finalRating: "68",
          jan: "4",
          feb: "4",
          mar: "4",
          apr: "4",
          may: "4",
          jun: "5",
          jul: "3",
          aug: "4",
          sep: "4",
          oct: "3",
          nov: "5",
          dec: "4",
        },
        {
          id: "9",
          name: "Isabella Elizabeth",
          finalRating: "68",
          jan: "4",
          feb: "5",
          mar: "2",
          apr: "4",
          may: "4",
          jun: "3",
          jul: "4",
          aug: "4",
          sep: "4",
          oct: "4",
          nov: "2",
          dec: "4",
        },
      ],
    };
    this.search = this.search.bind(this);
    this.rentalRefreshFun = this.rentalRefreshFun.bind(this);
  }
  search(type, value) {
    if (type === "Email") {
      let data = this.state.Leads1.filter(
        (d) =>
          d.email
            .replace(/\s/g, "")
            .toLowerCase()
            .search(value.replace(/\s/g, "").toLowerCase()) !== -1
      );
      this.setState({ Leads1: data });
    }
    if (type === "Name") {
      let data0 = this.state.Leads1.filter(
        (data) =>
          data.name
            .replace(/\s/g, "")
            .toLowerCase()
            .search(value.replace(/\s/g, "").toLowerCase()) !== -1
      );
      this.setState({ Leads1: data0 });
    }
    if (type === "Mobile") {
      let data1 = this.state.Leads1.filter(
        (data) =>
          data.mobile
            .replace(/\s/g, "")
            .toLowerCase()
            .search(value.replace(/\s/g, "").toLowerCase()) !== -1
      );
      this.setState({ Leads1: data1 });
    }
    if (type === "Property") {
      let data2 = this.state.Leads1.filter(
        (data) =>
          data.property
            .replace(/\s/g, "")
            .toLowerCase()
            .search(value.replace(/\s/g, "").toLowerCase()) !== -1
      );
      this.setState({ Leads1: data2 });
    }
    if (type === "Unit") {
      let data3 = this.state.Leads1.filter(
        (data) =>
          data.rentalEntity
            .replace(/\s/g, "")
            .toLowerCase()
            .search(value.replace(/\s/g, "").toLowerCase()) !== -1
      );
      this.setState({ Leads1: data3 });
    }
  }
  async rentalRefreshFun() {
    await this.setState({ Leads1: this.state.Leads, refreshSearch: false });
    this.setState({ refreshSearch: true });
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      await getLeads().then((response) => {
        response.data = response.data.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
        if (response.status === 200) {
          if (response.data.length !== 0) {
            this.setState({ Leads: response.data });
            this.setState({ Leads1: response.data });
          }
        } else {
          this.props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {}
  }
  render() {
    return (
      <div className="AppDashboard">
        {this.props.role === "Admin" && (
          <Lead
            tableData={this.state.Leads1}
            search={this.search}
            refreshSearch={this.state.refreshSearch}
            rentalRefreshFun={this.rentalRefreshFun}
            pushAlert={this.props.pushAlert}
          />
        )}
        <br></br>
        {this.props.role === "Landlord" && (
          <DashMainCard data={this.state.data} />
        )}
        <br />
        {this.props.role === "Landlord" && (
          <div className="row">
            <div className="col-lg-8">
              <DashCardApplication />
            </div>
            <div className="col-lg-4 ">
              <Occupancy total={120} occupied={84} />
            </div>
          </div>
        )}
        <br></br>

        {this.props.role === "Landlord" && <Rating data={this.state.rating} />}
        <br />
        {/* <div className="row">
        <div className="col-lg-8">
        <MaintainGraph />
        </div>
        <div className="col-lg-4 ">
       
        </div>
      </div>
      <br></br> */}
        {this.props.role === "Landlord" && (
          <RentalTable tableData={this.state.rentalData} />
        )}
        <br />
        {this.props.role === "Landlord" && (
          <MaintenanceTable tableData={this.state.MaintainanceData} />
        )}
      </div>
    );
  }
}
export default Dashboard;
