import React, { useState } from "react";

import "./RentalUnits.css";

const SearchRental = (props) => {
  
  const [state, setState] = useState("");
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      props.search(props.name, state.inputValue);
    }
  };
  async function onchange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  }
  return (
    <>
      <span className="span2">{props.name}</span>
      <input
        className="RentalInput"
        name="inputValue"
        onChange={onchange}
        onKeyPress={onKeyUp}
      ></input>
      <i
        className="fa fa-search searchListHeadButton"
        aria-hidden="true"
        onClick={() => props.search(props.name, state.inputValue)}
      ></i>
    </>
  );
};

export default SearchRental;
