import React, { Component } from "react";
import "./SignaturePad.css";

class SignaturePad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signatureData: null,
      buttonDisable: false,
    };
    this.submit = this.submit.bind(this);
    this.clear = this.clear.bind(this);
  }

  componentDidMount() {
    let requestAnimFrame = (function (callback) {
      return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.oRequestAnimationFrame ||
        window.msRequestAnimaitonFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 60);
        }
      );
    })();
    let canvas = document.getElementById(this.props.name + "-canvas");
    let ctx = canvas.getContext("2d");
    ctx.strokeStyle = "#222222";
    ctx.lineWidth = 2;
    ctx.lineCap = "round";
    ctx.lineJoin = "round";

    let drawing = false;
    let mousePos = {
      x: 0,
      y: 0,
    };
    let lastPos = mousePos;

    canvas.addEventListener(
      "mousedown",
      function (e) {
        drawing = true;
        lastPos = getMousePos(canvas, e);
      },
      false
    );

    canvas.addEventListener(
      "mouseup",
      function (e) {
        drawing = false;
        this.setState({ buttonDisable: true });
      }.bind(this),
      false
    );

    canvas.addEventListener(
      "mousemove",
      function (e) {
        mousePos = getMousePos(canvas, e);
      },
      false
    );
    // Add touch event support for mobile
    canvas.addEventListener("touchstart", function (e) {}, false);

    canvas.addEventListener(
      "touchmove",
      function (e) {
        let touch = e.touches[0];
        let me = new MouseEvent("mousemove", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        canvas.dispatchEvent(me);
      },
      false
    );

    canvas.addEventListener(
      "touchstart",
      function (e) {
        mousePos = getTouchPos(canvas, e);
        let touch = e.touches[0];
        let me = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY,
        });
        canvas.dispatchEvent(me);
      },
      false
    );

    canvas.addEventListener(
      "touchend",
      function (e) {
        let me = new MouseEvent("mouseup", {});
        canvas.dispatchEvent(me);
      },
      false
    );

    function getMousePos(canvasDom, mouseEvent) {
      let rect = canvasDom.getBoundingClientRect();

      return {
        x: mouseEvent.clientX - rect.left,
        y: mouseEvent.clientY - rect.top,
      };
    }

    function getTouchPos(canvasDom, touchEvent) {
      let rect = canvasDom.getBoundingClientRect();

      return {
        x: touchEvent.touches[0].clientX - rect.left,
        y: touchEvent.touches[0].clientY - rect.top,
      };
    }

    function renderCanvas() {
      if (drawing) {
        ctx.moveTo(lastPos.x, lastPos.y);
        ctx.lineTo(mousePos.x, mousePos.y);
        ctx.stroke();
        lastPos = mousePos;
      }
    }

    // Prevent scrolling when touching the canvas
    document.body.addEventListener(
      "touchstart",
      function (e) {
        if (e.target === canvas) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      "touchend",
      function (e) {
        if (e.target === canvas) {
          e.preventDefault();
        }
      },
      false
    );
    document.body.addEventListener(
      "touchmove",
      function (e) {
        if (e.target === canvas) {
          e.preventDefault();
        }
      },
      false
    );

    (function drawLoop() {
      requestAnimFrame(drawLoop);
      renderCanvas();
    })();
  }
  submit() {
    let canvas = document.getElementById(this.props.name + "-canvas");
    let dataUrl = canvas.toDataURL();
    let blob = new Blob([dataUrl], {
      type: "image/jpeg",
    });
    if (this.props.type === "base64") {
      this.setState({ signatureData: dataUrl });
      this.props.submit(dataUrl);
      this.props.type2&&this.props.submit2(blob);
    } else {
      this.setState({ signatureData: blob });
      this.props.submit(blob);
      this.props.type2&&this.props.submit2(dataUrl);
    }
  }
  clear() {
    let canvas = document.getElementById(this.props.name + "-canvas");
    // eslint-disable-next-line
    canvas.width = canvas.width;
    this.setState({ buttonDisable: false });
  }

  preventScroll(e) {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }

  disable() {
    document
      .querySelector(".signatureParent")
      .addEventListener("wheel", this.preventScroll);
  }
  width(){
    return window.innerWidth-(window.innerWidth*0.2);
  }
  render() {
    return (
      <div className="">
        <div className="row">
          <div className="col-md-12 p-0 m-0 signatureParent">
            <canvas
              id={this.props.name + "-canvas"}
              className="sig-canvas "
              // width="450"
              // width={this.width()}
              width={this.props.maxWidth?this.props.maxWidth<window.innerWidth?this.props.maxWidth:window.innerWidth-(window.innerWidth*0.2):window.innerWidth-(window.innerWidth*0.2)}
              height="200"
            >
              Get a better browser, bro.
            </canvas>
       
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 p-0 m-0 mb-3 mt-4">
            {this.props.submitAlign === "left" &&
              (this.state.buttonDisable && this.props.submitDisable ? (
                <button className=" mr-2" onClick={() => this.submit()}>
                  {this.props.submitName
                    ? this.props.submitName
                    : "Submit Signature"}
                </button>
              ) : (
                <button
                  className="secDisable pull-right"
                  style={{ cursor: "not-allowed" }}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Please provide your signature"
                >
                  {this.props.submitName
                    ? this.props.submitName
                    : "Submit Signature"}
                </button>
              ))}
            <button
              className="buttonSquareLinePri mr-2 mt-2"
              onClick={() => this.clear()}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
            {(this.props.submitAlign === "right" ||
              this.props.submitAlign === "pull-right") &&
              (this.state.buttonDisable && this.props.submitDisable ? (
                <button
                  className={this.props.submitAlign}
                  onClick={() => this.submit()}
                >
                  {this.props.submitName
                    ? this.props.submitName
                    : "Submit Signature"}
                </button>
              ) : (
                <button
                  className="secDisable pull-right"
                  style={{ cursor: "not-allowed" }}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Please provide your signature"
                >
                  {this.props.submitName
                    ? this.props.submitName
                    : "Submit Signature"}
                </button>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SignaturePad;
