import React, { Component } from "react";
import "../NavBar/NavBar.css";
import NotificationPart from "./NotificationPart";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className=" absolute profileMenu notificationBox p-0 m-0">
          {this.props.notificationData.data.length === 0 ? (
            <div className="pr-5 pl-5 mr-5 ml-5 ">
              <span className="span2">Nothing to show</span>
            </div>
          ) : (
            this.props.notificationData.data
              .sort(function (a, b) {
                return parseInt(b.id) - parseInt(a.id);
              })
              .map((data, index) => (
                <>
                  <NotificationPart
                    handleDelete={this.props.handleDelete}
                    notificationData={this.props.notificationData}
                    handleClick={this.props.handleClick}
                    data={data}
                    index={index}
                    deleteLoader={this.props.deleteLoader}
                  />
                </>
              ))
          )}
        </div>
      </>
    );
  }
}

export default Notification;
