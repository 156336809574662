import React, { Component } from "react";
import {
  cancelContract,
  changeListsStatus,
  getListings,
  getRentalListsImages,
  url,
} from "../../Services/ApiServices";
import { withRouter } from "react-router-dom";
import Loader from "../Loader/Loader";
import "./viewListing.css";
import {
  capitalizeFirstLetter,
  convertLocalDate,
} from "../../Defined/Function";
import RentalUnitModal from "../Pages/RentalUnit/RentalUnitModal";
import SmallModalContainer from "../Pages/Documents/SmallModalContainer";
import { connect } from "react-redux";
import {
  appendAlert,
  appendTempPropertyByUnitData,
  getTempPropertyByUnitData,
} from "../../actions";
import CurrencyFormat from "../UIServices/CurrencyFormat";

class ViewListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      listingData: [],
      incompleteList: [],
      completeList: [],
      closeList: [],
      listingIds: [],
      results: [],
      checkedAllIncomplete: false,
      checkedAllComplete: false,
      checkedAllClose: false,
      addList: 0,
      revoke: false,
      modalData: [],
      image: [],
      spinner: true,
      revokeId: "",
      tabOn: this.props.history.location.state
        ? this.props.history.location.state.tab
        : "Incomplete",
      moveEarly: true,
    };

    this.onchange = this.onchange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
  }
  async onchangeCheck(e) {
    let { name, checked, id } = e.target;
    let completeSample = this.state.completeList.map((data) => {
      if (parseInt(data.rentalEntity.id) === parseInt(id)) {
        let r = data.rentalEntity;
        if (r.status === "OPEN") {
          r.status = "INACTIVE";
        } else {
          r.status = "OPEN";
        }
        try {
          changeListsStatus(r.status, data.rentalEntity).then((response) => {
            if (response.status === 200) {
              this.props.appendAlert("Status changed", "success");
            } else {
              this.props.appendAlert("Try again later", "warning");
            }
          });
        } catch (error) {
          this.props.appendAlert("Try again later", "warning");
          console.error(error);
        }
        return {
          ...data,
          rentalEntity: r,
        };
      } else {
        return data;
      }
    });
    await this.setState({ [name]: checked, completeList: completeSample });
  }
  handelDetail(data) {
    this.setState({ modalData: data, image: [], spinner: true });
    try {
      getRentalListsImages(data.rentalEntity.id).then((response) => {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            let imgList = [];
            response.data.forEach((img) => {
              imgList.push(
                url +
                  `api/property/${data.rentalEntity.id}/${img}/image/downloadByName`
              );
            });
            this.setState({ image: imgList, spinner: false });
          } else {
            console.log("No data!");
            this.setState({ spinner: false });
          }
        } else {
          if (response.data === undefined) {
            if (response.response.data !== undefined) {
              this.props.appendAlert(response.response.data.message, "danger");
            } else {
              this.props.appendAlert("Try again later", "warning");
            }
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  getLists() {
    try {
      getListings(this.props.match.params.propsId).then(async (response) => {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            let listData = response.data.reverse();
            await this.updateList(listData);
            this.props.appendTempPropertyByUnitData(
              listData,
              this.props.match.params.propsId
            );
          } else {
            console.log("Error in processing.Try again later!");
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    let propertyByListing = this.props.tempPropertyByUnitArray.filter(
      (todo) => todo.id === this.props.match.params.propsId
    );
    if (propertyByListing.length === 0) {
      this.getLists();
    } else {
      this.updateList(propertyByListing[0].units);
      this.getLists();
    }
  }
  async updateList(list) {
    let incomplete = list.filter(
      (data) => data.rentalEntity.status === "INCOMPLETE"
    );
    let complete = list.filter(
      (data) =>
        data.rentalEntity.status === "OPEN" ||
        data.rentalEntity.status === "INACTIVE"
    );
    let close = list.filter(
      (data) =>
        data.rentalEntity.status === "CLOSED" ||
        data.rentalEntity.status === "TENANT_INVITED"
    );

    this.setState({
      listingData: list,
      incompleteList: incomplete,
      completeList: complete,
      closeList: close,
      isLoading: false,
    });
    if (
      list.filter((data) => data.rentalEntity.status === "INCOMPLETE")
        .length === 0
    ) {
      if (
        list.filter(
          (data) =>
            data.rentalEntity.status === "OPEN" ||
            data.rentalEntity.status === "INACTIVE"
        ).length === 0
      ) {
        this.setState({ tabOn: "Closed" });
      } else {
        this.setState({ tabOn: "Open" });
      }
    }
  }

  checkedOne(name, id) {
    if (document.getElementById(name).checked === true) {
      document.getElementById(name).checked = false;
      this.setState({ addList: this.state.addList - 1 });
      let removeIds = this.state.listingIds.filter((data) => data !== id);
      this.setState({ listingIds: removeIds });
    } else {
      document.getElementById(name).checked = true;
      this.setState({ addList: this.state.addList + 1 });
      let ids = this.state.listingIds;
      ids.push(id);
      this.setState({ listingIds: ids });
    }
  }
  changeTeb(tab) {
    if (tab === "Open") {
      if (this.state.incompleteList.length > 0) {
        let checkbox = document.getElementById("incompleteCheck");
        checkbox.indeterminate = false;
        for (let i = 0; i < this.state.incompleteList.length; i++) {
          let check = document.getElementById("incompleteCheck" + i);
          check.checked = false;
        }
      }
      this.setState({ addList: 0, tabOn: "Open" });
    } else if (tab === "Closed") {
      if (this.state.incompleteList.length > 0) {
        let checkbox3 = document.getElementById("incompleteCheck");
        checkbox3.indeterminate = false;
        for (let i1 = 0; i1 < this.state.incompleteList.length; i1++) {
          let check2 = document.getElementById("incompleteCheck" + i1);
          check2.checked = false;
        }
      }
      this.setState({ addList: 0, tabOn: "Closed" });
    } else {
      this.setState({ addList: 0, tabOn: "Incomplete" });
    }
    this.updateList(this.state.listingData);
  }
  cancelContract() {
    if (this.state.revokeId !== 0 && this.state.revokeId !== "") {
      try {
        cancelContract(this.state.revokeId).then((response) => {
          if (response.status === 200) {
            this.setState({ revoke: false });
            if (response.data.status) {
              this.getLists();
              this.props.appendAlert(
                "Contact cancelled successfully !!!",
                "success"
              );
            }
          } else {
            if (response.data === undefined) {
              if (response.response.data !== undefined) {
                this.props.appendAlert(
                  response.response.data.message,
                  "danger"
                );
              } else {
                this.props.appendAlert("Try again later", "warning");
              }
            }
            this.props.appendAlert(
              "Contract cancellation failed !!!",
              "danger"
            );
          }
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      this.setState({ revoke: false });
      this.props.appendAlert("Contract revoked failed !!!", "danger");
    }
  }
  checkedIncompleteAll() {
    let checkbox = document.getElementById("incompleteCheck");
    if (!this.state.checkedAllIncomplete) {
      checkbox.indeterminate = true;
      this.setState({ checkedAllIncomplete: true });
      for (let i = 0; i < this.state.incompleteList.length; i++) {
        let check6 = document.getElementById("incompleteCheck" + i);
        check6.checked = true;
      }
      this.setState({ addList: this.state.incompleteList.length });
      let allIds = [];
      this.state.incompleteList.map((data) =>
        allIds.push(data.rentalEntity.id)
      );
      this.setState({ listingIds: allIds });
    } else {
      checkbox.indeterminate = false;
      this.setState({ checkedAllIncomplete: false });
      for (let j = 0; j < this.state.incompleteList.length; j++) {
        let check7 = document.getElementById("incompleteCheck" + j);
        check7.checked = false;
      }
      this.setState({ addList: 0 });
      this.setState({ listingIds: [] });
    }
  }

  checkedCloseAll() {
    let checkbox = document.getElementById("closeCheck");
    if (!this.state.checkedAllClose) {
      checkbox.indeterminate = true;
      this.setState({ checkedAllClose: true });
      for (let i = 0; i < this.state.closeList.length; i++) {
        let check = document.getElementById("closeCheck" + i);
        check.checked = true;
      }
      this.setState({ addList: this.state.closeList.length });
      let allIds = [];
      this.state.closeList.map((data) => allIds.push(data.rentalEntity.id));
      this.setState({ listingIds: allIds });
    } else {
      checkbox.indeterminate = false;
      this.setState({ checkedAllClose: false });
      for (let j = 0; j < this.state.closeList.length; j++) {
        let check1 = document.getElementById("closeCheck" + j);
        check1.checked = false;
      }
      this.setState({ addList: 0 });
      this.setState({ listingIds: [] });
    }
  }
  onchange(e) {
    let { name, value } = e.target;
    if (name === "search") {
      if (this.state.tabOn === "Incomplete") {
        let incomplete = this.state.listingData.filter(
          (data) => data.rentalEntity.status === "INCOMPLETE"
        );
        if (value === "") {
          this.setState({ incompleteList: incomplete });
        } else {
          let incompleteSearch = incomplete.filter(
            (data) =>
              data.address &&
              (
                data.rentalEntity.unitName +
                data.address.address +
                data.address.state +
                data.address.city +
                data.address.country
              )
                .toLowerCase()
                .search(value.toLowerCase()) !== -1
          );
          this.setState({ incompleteList: incompleteSearch });
        }
      }
      if (this.state.tabOn === "Open") {
        let complete = this.state.listingData.filter(
          (data) => data.rentalEntity.status === "OPEN"
        );
        if (value === "") {
          this.setState({ completeList: complete });
        } else {
          let completeSearch = complete.filter((data) => {
            return (
              data.address &&
              (
                data.rentalEntity.unitName +
                data.address.address +
                data.address.state +
                data.address.city +
                data.address.country
              )
                .toLowerCase()
                .search(value.toLowerCase()) !== -1
            );
          });
          this.setState({ completeList: completeSearch });
        }
      }
      if (this.state.tabOn === "Closed") {
        let close = this.state.listingData.filter(
          (data) =>
            data.rentalEntity.status === "CLOSED" ||
            data.rentalEntity.status === "TENANT_INVITED"
        );
        if (value === "") {
          this.setState({ closeList: close });
        } else {
          let closeSearch = close.filter(
            (data) =>
              data.address &&
              (
                data.rentalEntity.unitName +
                data.address.address +
                data.address.state +
                data.address.city +
                data.address.country
              )
                .toLowerCase()
                .search(value.toLowerCase()) !== -1
          );
          this.setState({ closeList: closeSearch });
        }
      }
    }
  }
  changeRevoke(id) {
    this.setState({ revokeId: id, revoke: true });
  }
  render() {
    return this.state.isLoading ? (
      <Loader />
    ) : (
      <div
        style={{ marginTop: "80px", paddingTop: "20px", padding: "20px" }}
        key={this.props.match.params.propsId}
      >
        {this.state.addList !== 0 && (
          <div className="row">
            <div className="col mr-3  text-right ">
              {this.state.tabOn === "Incomplete" && (
                <button
                  className="pri2 pull-right addList"
                  style={{ zIndex: "00" }}
                  onClick={() =>
                    this.props.history.push({
                      pathname:
                        "/properties/" +
                        this.props.match.params.propsId +
                        "/listings/" +
                        this.state.listingIds +
                        "/add-listing",
                      state: {
                        type: "add",
                        ids: this.state.listingIds,
                        propsId: this.props.match.params.propsId,
                      },
                    })
                  }
                >
                  <i className="fa fa-plus" aria-hidden="true">
                    {" "}
                    &nbsp;
                  </i>{" "}
                  Listing
                </button>
              )}
            </div>
          </div>
        )}
        <ul className="nav nav-tabs pb-0 mb-0 " id="myTab" role="tablist">
          {(this.state.incompleteList.length > 0 ||
            this.state.tabOn === "Incomplete") && (
            <li className="nav-item  pointer">
              <a
                className={
                  this.state.tabOn === "Incomplete"
                    ? "nav-link navTabLink active"
                    : "nav-link navTabLink"
                }
                id="Incomplete-tab"
                data-toggle="tab"
                href="#Incomplete"
                role="tab"
                aria-controls="Incomplete"
                aria-selected="true"
                onClick={() => this.changeTeb("Incomplete")}
                style={{ zIndex: "200" }}
              >
                Incomplete <label className="removeUnit pointer">Units</label>
              </a>
            </li>
          )}
          {(this.state.completeList.length > 0 ||
            this.state.tabOn === "Open") && (
            <li className="nav-item pointer">
              <a
                className={
                  this.state.tabOn === "Open"
                    ? "nav-link navTabLink active"
                    : "nav-link navTabLink"
                }
                id="Open-tab"
                data-toggle="tab"
                href="#Open"
                role="tab"
                aria-controls="Open"
                aria-selected="false"
                onClick={() => this.changeTeb("Open")}
              >
                Completed <label className="removeUnit pointer">Units</label>
              </a>
            </li>
          )}
          {(this.state.closeList.length > 0 ||
            this.state.tabOn === "Closed") && (
            <li className="nav-item pointer">
              <a
                className={
                  this.state.tabOn === "Closed"
                    ? "nav-link navTabLink active"
                    : "nav-link navTabLink"
                }
                id="Closed-tab"
                data-toggle="tab"
                href="#Closed"
                role="tab"
                aria-controls="Closed"
                aria-selected="false"
                onClick={() => this.changeTeb("Closed")}
              >
                Closed <label className="removeUnit pointer">Units</label>
              </a>
            </li>
          )}
        </ul>
        <div
          className="tab-content  pt-0 mt-0 viewListingPad"
          style={{ minHeight: "700px" }}
          id="myTabContent"
        >
          {this.state.addList !== 0 && (
            <div className="pull-left pl-4 pt-4 ">
              <span className="span2 text-secondary">
                {this.state.addList} Lists selected
              </span>
            </div>
          )}
          <div className="col-md-4 pull-right text-right">
            <input
              className="tag2 tabSearchInput"
              name="search"
              onChange={this.onchange}
            ></input>
            <i className="fa fa-search searchTab" aria-hidden="true"></i>
          </div>

          <div
            className={
              this.state.tabOn === "Incomplete"
                ? "tab-pane fade show active pt-5"
                : "tab-pane fade pt-5"
            }
            id="Incomplete"
            role="tabpanel"
            aria-labelledby="Incomplete-tab"
          >
            <table
              className="table table-striped table-hover  table-responsive tableMaintain"
              style={{ width: "100%" }}
            >
              <thead className=" text-white ">
                <tr className="bgDarkBlue rowTable ">
                  <th
                    className="headName text-center "
                    scope="col"
                    style={{ width: "5%" }}
                  >
                    <div
                      className="custom-control custom-checkbox"
                      onClick={() => this.checkedIncompleteAll()}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input listCheck"
                        id="incompleteCheck"
                        style={{ background: "#000" }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      ></label>
                    </div>
                  </th>
                  <th
                    className="headName text-center"
                    scope="col"
                    style={{ width: "10%", minWidth: "200px" }}
                  >
                    Unit
                  </th>
                  <th
                    className="headName text-center"
                    scope="col"
                    style={{ width: "40%", minWidth: "350px" }}
                  >
                    Address
                  </th>

                  <th
                    className="headName text-center"
                    scope="col"
                    style={{ width: "35%", minWidth: "200px" }}
                  >
                    Type
                  </th>
                  {/* <th
                    className="headName text-center"
                    scope="col"
                    style={{ width: "25%", minWidth: "150px" }}
                  >
                    Rent
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.incompleteList.map((data, index) => (
                  <tr className="rowTable">
                    <td className=" text-center " style={{ width: "5%" }}>
                      <div
                        className="custom-control custom-checkbox"
                        onClick={() =>
                          this.checkedOne(
                            "incompleteCheck" + index,
                            data.rentalEntity.id
                          )
                        }
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input listCheck"
                          id={"incompleteCheck" + index}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        ></label>
                      </div>
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "10%", minWidth: "200px" }}
                    >
                      {data.rentalEntity.unitName
                        ? data.rentalEntity.unitName !== "" &&
                          data.rentalEntity.unitName !== null
                          ? data.rentalEntity.unitName
                          : "-"
                        : "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "40%", minWidth: "350px" }}
                    >
                      {data.address
                        ? data.address.address +
                          ", " +
                          data.address.city +
                          ", " +
                          data.address.state +
                          " " +
                          data.address.zip
                        : " "}
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "35%", minWidth: "200px" }}
                    >
                      {data.rentalEntity.property.type === "APPARTMENT_BUILDING"
                        ? "Apartment"
                        : data.rentalEntity.property.type === "SINGLE_HOME"
                        ? "Single Home"
                        : data.rentalEntity.property.type === "OFFICE"
                        ? "Office"
                        : ""}
                      {(data.rentalEntity.property.type !== "" ||
                        data.rentalEntity.unitType !== "") &&
                        data.rentalEntity.property.type !== "" &&
                        data.rentalEntity.unitType !== "" &&
                        " - "}
                      {capitalizeFirstLetter(data.rentalEntity.unitType)}
                    </td>
                    {/* <td className="text-center" style={{ width: "25%" }}>
                      {data.rentalEntity.rent ?  "$"+data.rentalEntity.rent.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }) : "$"+0.0}
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            className={
              this.state.tabOn === "Open"
                ? "tab-pane fade show active "
                : "tab-pane fade"
            }
            id="Open"
            role="tabpanel"
            aria-labelledby="Open-tab"
          >
            <table
              className="table table-striped table-hover  table-responsive tableMaintain"
              style={{ width: "100%" }}
            >
              <thead className=" text-white ">
                <tr className="bgDarkBlue rowTable ">
                  <th
                    className="headName text-center"
                    style={{ width: "10%", minWidth: "200px" }}
                  >
                    Unit
                  </th>
                  <th
                    className="headName text-center"
                    style={{ width: "25%", minWidth: "230px" }}
                  >
                    Address
                  </th>

                  <th
                    className="headName text-center"
                    style={{ width: "19%", minWidth: "250px" }}
                  >
                    Available
                  </th>
                  <th
                    className="headName text-center"
                    style={{ width: "18%", minWidth: "300px" }}
                  >
                    Rent
                  </th>
                  <th
                    className="headName text-center"
                    style={{ width: "18%", minWidth: "80px" }}
                  >
                    Status
                  </th>
                  <th
                    className="headName text-center"
                    style={{ width: "15%", minWidth: "200px" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.completeList.map((data) => (
                  <tr className="rowTable">
                    <td
                      className="text-center"
                      style={{ width: "10%", minWidth: "200px" }}
                    >
                      {data.rentalEntity.unitName
                        ? data.rentalEntity.unitName !== "" &&
                          data.rentalEntity.unitName !== null
                          ? data.rentalEntity.unitName
                          : "-"
                        : "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "25%", minWidth: "250px" }}
                    >
                      {data.address
                        ? data.address.address +
                          ", " +
                          data.address.city +
                          ", " +
                          data.address.state +
                          " " +
                          data.address.zip
                        : " "}
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "19%", minWidth: "250px" }}
                    >
                      {data.rentalEntity.availableFrom
                        ? data.rentalEntity.availableFrom !== null
                          ? convertLocalDate(data.rentalEntity.availableFrom)
                          : "-"
                        : "- "}
                    </td>
                    <td
                      className="text-center "
                      style={{ width: "18%", minWidth: "300px" }}
                    >
                      <CurrencyFormat value={data.rentalEntity.rent} />
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "18%", minWidth: "80px" }}
                    >
                      <label className={"switchApplication2 mt-1 "}>
                        <input
                          type="checkbox"
                          name={"active"}
                          checked={
                            data.rentalEntity.status === "OPEN" ? "checked" : ""
                          }
                          onChange={this.onchangeCheck}
                          id={data.rentalEntity.id}
                        />
                        <div className={"sliderApplication2 round "}>
                          <span className="onApp">
                            <b>
                              {this.props.options
                                ? this.props.options[0]
                                : "Active"}
                            </b>
                          </span>
                          <span className="offApp">
                            <b>
                              {this.props.options
                                ? this.props.options[1]
                                : "Inactive"}
                            </b>
                          </span>
                        </div>
                      </label>
                    </td>
                    <th
                      className="text-center pt-1 pb-1 mt-0 mb-0"
                      style={{ width: "15%", minWidth: "200px" }}
                    >
                      <button
                        className="listButtonIcon  mr-2 ml-2 "
                        data-placement="top"
                        title="View"
                        onClick={() => this.handelDetail(data)}
                        data-target="#exampleModal"
                        data-toggle="modal"
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </button>
                      {data.rentalEntity.status === "OPEN" ? (
                        <button
                          className="listButtonIcon  mr-2 ml-3 "
                          data-placement="top"
                          title="Add Tenant"
                          onClick={() =>
                            this.props.history.push({
                              pathname:
                                "/properties/" +
                                data.rentalEntity.property.id +
                                "/listings/" +
                                data.rentalEntity.id +
                                "/add-tenant",
                              state: { id: data.rentalEntity.id },
                            })
                          }
                          data-target="#exampleModal"
                          data-toggle="modal"
                        >
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            width="14px"
                            viewBox="0 0 64.000000 64.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                              fill="#fff"
                              stroke="none"
                            >
                              <path
                                d="M255 626 c-42 -18 -93 -79 -101 -121 -9 -49 3 -100 31 -134 14 -16
                                25 -31 25 -34 0 -3 -24 -19 -53 -35 -82 -46 -139 -125 -153 -210 -5 -35 -3
                                -44 19 -66 26 -26 27 -26 200 -26 177 0 192 3 180 38 -4 9 -48 12 -174 12
                                -192 0 -193 0 -165 73 50 131 204 206 336 163 28 -9 55 -14 60 -11 17 10 11
                                32 -12 47 l-22 15 26 32 c51 60 49 146 -5 208 -52 60 -125 78 -192 49z m135
                                -65 c31 -23 54 -82 46 -116 -11 -45 -57 -86 -101 -91 -49 -6 -82 10 -113 56
                                -71 105 65 227 168 151z"
                              />
                              <path
                                d="M513 232 c-6 -4 -13 -25 -15 -47 -3 -39 -4 -40 -43 -43 -71 -6 -65
                              -52 6 -52 39 0 39 0 39 -39 0 -22 5 -43 10 -46 19 -12 40 16 40 51 0 32 2 34
                              34 34 35 0 63 21 51 40 -3 5 -24 10 -46 10 -39 0 -39 0 -39 39 0 40 -17 65
                              -37 53z"
                              />
                            </g>
                          </svg>
                        </button>
                      ) : (
                        <button
                          className="listButtonIconDisable  mr-2 ml-3  "
                          data-placement="top"
                          title="Add Tenant"
                        >
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            className=""
                            width="14px"
                            viewBox="0 0 64.000000 64.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            <g
                              transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
                              fill="#fff"
                              stroke="none"
                            >
                              <path
                                d="M255 626 c-42 -18 -93 -79 -101 -121 -9 -49 3 -100 31 -134 14 -16
                                25 -31 25 -34 0 -3 -24 -19 -53 -35 -82 -46 -139 -125 -153 -210 -5 -35 -3
                                -44 19 -66 26 -26 27 -26 200 -26 177 0 192 3 180 38 -4 9 -48 12 -174 12
                                -192 0 -193 0 -165 73 50 131 204 206 336 163 28 -9 55 -14 60 -11 17 10 11
                                32 -12 47 l-22 15 26 32 c51 60 49 146 -5 208 -52 60 -125 78 -192 49z m135
                                -65 c31 -23 54 -82 46 -116 -11 -45 -57 -86 -101 -91 -49 -6 -82 10 -113 56
                                -71 105 65 227 168 151z"
                              />
                              <path
                                d="M513 232 c-6 -4 -13 -25 -15 -47 -3 -39 -4 -40 -43 -43 -71 -6 -65
                              -52 6 -52 39 0 39 0 39 -39 0 -22 5 -43 10 -46 19 -12 40 16 40 51 0 32 2 34
                              34 34 35 0 63 21 51 40 -3 5 -24 10 -46 10 -39 0 -39 0 -39 39 0 40 -17 65
                              -37 53z"
                              />
                            </g>
                          </svg>
                        </button>
                      )}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            className={
              this.state.tabOn === "Closed"
                ? "tab-pane fade show active "
                : "tab-pane fade"
            }
            id="Closed"
            role="tabpanel"
            aria-labelledby="Closed-tab"
          >
            <table
              className="table table-striped table-hover table-responsive tableMaintain "
              style={{ width: "100%" }}
            >
              <thead className=" text-white ">
                <tr className="bgDarkBlue rowTable ">
                  <th
                    className="headName text-center"
                    style={{ width: "10%", minWidth: "200px" }}
                  >
                    Unit
                  </th>
                  <th
                    className="headName text-center"
                    style={{ width: "30%", minWidth: "300px" }}
                  >
                    Address
                  </th>

                  <th
                    className="headName text-center"
                    style={{ width: "18%", minWidth: "220px" }}
                  >
                    Type
                  </th>
                  <th
                    className="headName text-center"
                    style={{ width: "15%", minWidth: "200px" }}
                  >
                    Rent
                  </th>
                  <th className="headName text-center" style={{ width: "15%" }}>
                    Status
                  </th>
                  <th
                    className="headName text-center"
                    style={{ width: "10%", minWidth: "100px" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.closeList.map((data) => (
                  <tr className="rowTable">
                    <td
                      className="text-center"
                      style={{ width: "10%", minWidth: "200px" }}
                    >
                      {data.address
                        ? data.rentalEntity.unitName
                          ? data.rentalEntity.unitName
                          : ""
                        : " "}
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "30%", minWidth: "350px" }}
                    >
                      {data.address
                        ? data.address.address +
                          ", " +
                          data.address.city +
                          ", " +
                          data.address.state +
                          " " +
                          data.address.zip
                        : " "}
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "18%", minWidth: "250px" }}
                    >
                      {data.rentalEntity.property.type === "APPARTMENT_BUILDING"
                        ? "Apartment"
                        : data.rentalEntity.property.type === "SINGLE_HOME"
                        ? "Single Home"
                        : data.rentalEntity.property.type === "OFFICE"
                        ? "Office"
                        : ""}
                      {(data.rentalEntity.property.type !== "" ||
                        data.rentalEntity.unitType !== "") &&
                        data.rentalEntity.property.type !== "" &&
                        data.rentalEntity.unitType !== "" &&
                        " - "}
                      {capitalizeFirstLetter(data.rentalEntity.unitType)}
                    </td>
                    <td
                      className="text-center"
                      style={{ width: "15%", minWidth: "200px" }}
                    >
                      <CurrencyFormat value={data.rentalEntity.rent} />
                    </td>

                    <td
                      className="text-center pt-2 pb-0 mt-0 mb-0"
                      style={{ width: "15%", minWidth: "200px" }}
                    >
                      {data.rentalEntity.status === "TENANT_INVITED" ? (
                        <span
                          className="badge appStatus "
                          style={{
                            backgroundColor: "#85C1E9",
                          }}
                        >
                          Tenant Invited
                        </span>
                      ) : (
                        <span
                          className="badge appStatus "
                          style={{
                            backgroundColor: "#2ECC71",
                          }}
                        >
                          Occupied
                        </span>
                      )}
                    </td>
                    <td
                      className="text-center pt-2 pb-0 mt-0 mb-0"
                      style={{ width: "10%", minWidth: "100px" }}
                    >
                      {data.rentalEntity.status === "TENANT_INVITED" && (
                        <button
                          className="listButtonIcon  "
                          data-placement="top"
                          title="Revoke"
                          onClick={() =>
                            this.changeRevoke(data.rentalEntity.contractId)
                          }
                          data-target="#revoke"
                          data-toggle="modal"
                        >
                          <i className="fa fa-reply-all" aria-hidden="true"></i>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {this.state.revoke && (
          <SmallModalContainer
            name="revoke"
            dark={this.props.isDark}
            title="Do you want to revoke the leasing?"
            confirmCommand="Revoke"
            size="lg"
            confirm={() => this.cancelContract()}
            reject={() => this.setState({ revoke: false })}
            rejectCommand="Cancel"
            rejectCheck="true"
          >
            <div className="p-2 pull-left text-left">
              <span className="span7" style={{ fontSize: "16px" }}>
                If you want to revoke the leasing offer for this unit, please
                click Revoke. Remember, the changes cannot be undone.
              </span>
            </div>
          </SmallModalContainer>
        )}
        {this.state.modalData && (
          <RentalUnitModal
            modalData={
              this.state.modalData.rentalEntity
                ? this.state.modalData.rentalEntity
                : []
            }
            modalData1={this.state.modalData}
            spinner={this.state.spinner}
            images={this.state.image}
            type="view"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    tempPropertyByUnit: state.tempPropertyByUnitReducer,
    tempPropertyByUnitArray: state.tempPropertyByUnit,
    alertArray: state.alert,
    isDark: state.isDark,
    dark: state.darkReducer,
    UserData: state.user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    getTempPropertyByUnitData,
    appendTempPropertyByUnitData,
  })(ViewListing)
);
