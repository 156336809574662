import React, { Component } from "react";
import { withRouter } from "react-router";
import "../officeApplication.css";
import OfficeApplicationStep1 from "./OfficeAppComponent/OfficeApplicationStep1";
import OfficeApplicationStep2 from "./OfficeAppComponent/OfficeApplicationStep2";
import OfficeApplicationStep3 from "./OfficeAppComponent/OfficeApplicationStep3";
import OfficeApplicationStep4 from "./OfficeAppComponent/OfficeApplicationStep4";
import OfficeApplicationStep5 from "./OfficeAppComponent/OfficeApplicationStep5";
import OfficeApplicationStep6 from "./OfficeAppComponent/OfficeApplicationStep6";
import {
  getPropertyLists,
  setTorontoOfficeApplication,
} from "../../../../Services/ApiServices";
import { setDateFormat } from "../../../../Defined/Function";

class OfficeApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      emailMessage: false,
      rentalEntity: [],
      next: false,
      username: "",
      business: {
        businessName: "",
        establish: "",
        contactPhone: "",
        contactFax: "",
        employessCount: "",
        businessType: "",
        ownershipType: "Sole Proprietor",
        intend: "",
      },
      parentCompany: "",
      parentCompanyName: "",
      parentCompanyAddress: "",
      parentCompanyCity: "",
      parentCompanyState: "",
      parentCompanyZip: "",
      parentCompanyCountry: "",
      emergencyContactName: "",
      emergencyContactPhone: "",
      profitLossYear1: "",
      profitLossYear2: "",
      profitLossYear3: "",
      profitLossDoc1: "",
      profitLossDoc2: "",
      profitLossDoc3: "",
      financialYear1: "",
      financialYear2: "",
      financialYear3: "",
      financialDoc1: "",
      financialDoc2: "",
      financialDoc3: "",
      TaxYear1: "",
      TaxYear2: "",
      TaxYear3: "",
      TaxDoc1: "",
      TaxDoc2: "",
      TaxDoc3: "",
      stageRental: 1,
      activeRental: 1,
      resPresentLandlord: "",
      resPresentLandlordTp: "",
      resPresentAddress: "",
      resPresentCity: "",
      resPresentState: "",
      resPresentZip: "",
      resPresentRent: "",
      resPresentFrom: "",
      resPresentTo: "",

      resPreviousLandlord: "",
      resPreviousLandlordTp: "",
      resPreviousAddress: "",
      resPreviousCity: "",
      resPreviousState: "",
      resPreviousZip: "",
      resPreviousRent: "",
      resPreviousFrom: "",
      resPreviousTo: "",

      firstOwnerName: "",
      firstOwnerEmail: "",
      firstOwnerContactNumber: "",
      firstOwnerContactNumber2: "",
      firstOwnerAddress: "",
      firstOwnerState: "",
      firstOwnerCity: "",
      firstOwnerZip: "",
      firstOwnerDOB: "",
      firstOwnerSSN: "",
      firstOwnerDLN: "",
      secondOwnerName: "",
      secondOwnerEmail: "",
      secondOwnerContactNumber: "",
      secondOwnerContactNumber2: "",
      secondOwnerAddress: "",
      secondOwnerState: "",
      secondOwnerCity: "",
      secondOwnerZip: "",
      secondOwnerDOB: "",
      secondOwnerSSN: "",
      secondOwnerDLN: "",
      thirdOwnerName: "",
      thirdOwnerEmail: "",
      thirdOwnerContactNumber: "",
      thirdOwnerContactNumber2: "",
      thirdOwnerAddress: "",
      thirdOwnerState: "",
      thirdOwnerCity: "",
      thirdOwnerZip: "",
      thirdOwnerDOB: "",
      thirdOwnerSSN: "",
      thirdOwnerDLN: "",
      ownerCount: 1,
      activeOwner: 1,
      primaryBankName: "",
      primaryBankPhone: "",
      primaryBankAddress: "",
      primaryBankState: "",
      primaryBankCity: "",
      primaryBankZip: "",
      secondaryBankName: "",
      secondaryBankPhone: "",
      secondaryBankAddress: "",
      secondaryBankState: "",
      secondaryBankCity: "",
      secondaryBankZip: "",
      stage: 1,
      activeReference: 1,
      referenceCompanyName: "",
      referenceCompanyContactName: "",
      referenceCompanyAddress: "",
      referenceCompanyCity: "",
      referenceCompanyState: "",
      referenceCompanyZip: "",
      referenceCompanyPhone: "",

      referenceCompany2Name: "",
      referenceCompany2ContactName: "",
      referenceCompany2Address: "",
      referenceCompany2City: "",
      referenceCompany2State: "",
      referenceCompany2Zip: "",
      referenceCompany2Phone: "",

      referenceCompany3Name: "",
      referenceCompany3ContactName: "",
      referenceCompany3Address: "",
      referenceCompany3City: "",
      referenceCompany3State: "",
      referenceCompany3Zip: "",
      referenceCompany3Phone: "",
    };
    this.next = this.next.bind(this);
    this.onchangeArray = this.onchangeArray.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onChangeParent = this.onChangeParent.bind(this);
    this.addRental = this.addRental.bind(this);
    this.removeRental = this.removeRental.bind(this);
    this.setParentState = this.setParentState.bind(this);
    this.addOwner = this.addOwner.bind(this);
    this.removeOwner = this.removeOwner.bind(this);
    this.addReference = this.addReference.bind(this);
    this.submit = this.submit.bind(this);
    this.changeEmailMessage = this.changeEmailMessage.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }
  getEntity() {
    if (!this.props.location.state) {
      try {
        getPropertyLists(this.props.match.params.id).then((response) => {
          if (response.status === 200) {
            if (response.status) {
              this.setState({ rentalEntity: response.data.rentalEntity });
            }
          } else {
            this.props.pushAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
            this.setState({ rentalEntity: null });
          }
        });
      } catch (error) {
        this.setState({ rentalEntity: null });
        console.log(error);
      }
    } else {
      this.setState({ rentalEntity: this.props.location.state });
    }
  }
  changeEmailMessage() {
    this.setState({ emailMessage: true });
    setTimeout(
      function () {
        this.setState({ emailMessage: false });
      }.bind(this),
      3000
    );
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    if (name === "parentCompany") {
      this.setState({
        business: { ...this.state.business, [name]: checked },
      });
    }
    this.setState({ [name]: checked });
  }
  submit() {
    let owner = [
      {
        name: this.state.firstOwnerName,
        ownerAddress: {
          address: this.state.firstOwnerAddress,
          city: this.state.firstOwnerCity,
          state: this.state.firstOwnerState,
          zip: this.state.firstOwnerZip,
          country: null,
          lat: 0.0,
          lng: 0.0,
        },
        email: this.state.firstOwnerEmail,
        phone: this.state.firstOwnerContactNumber,
        phoneAlteranative: this.state.firstOwnerContactNumber2,
        dob: this.state.firstOwnerDOB,
        drivingLicenceNo: this.state.firstOwnerDLN,
        ssn: this.state.firstOwnerSSN,
      },
    ];
    if (this.state.ownerCount > 1) {
      owner.push({
        name: this.state.secondOwnerName,
        ownerAddress: {
          address: this.state.secondOwnerAddress,
          city: this.state.secondOwnerCity,
          state: this.state.secondOwnerState,
          zip: this.state.secondOwnerZip,
          country: null,
          lat: 0.0,
          lng: 0.0,
        },
        email: this.state.secondOwnerEmail,
        phone: this.state.secondOwnerContactNumber,
        phoneAlteranative: this.state.secondOwnerContactNumber2,
        dob: this.state.secondOwnerDOB,
        drivingLicenceNo: this.state.secondOwnerDLN,
        ssn: this.state.secondOwnerSSN,
      });
    }
    if (this.state.ownerCount > 2) {
      owner.push({
        name: this.state.thirdOwnerName,
        ownerAddress: {
          address: this.state.thirdOwnerAddress,
          city: this.state.thirdOwnerCity,
          state: this.state.thirdOwnerState,
          zip: this.state.thirdOwnerZip,
          country: null,
          lat: 0.0,
          lng: 0.0,
        },
        email: this.state.thirdOwnerEmail,
        phone: this.state.thirdOwnerContactNumber,
        phoneAlteranative: this.state.thirdOwnerContactNumber2,
        dob: this.state.thirdOwnerDOB,
        drivingLicenceNo: this.state.thirdOwnerDLN,
        ssn: this.state.thirdOwnerSSN,
      });
    }
    let body = {
      users: [
        {
          user: {
            username: this.state.username,
            password: "",
            enabled: false,
            accountNonExpired: true,
            credentialsNonExpired: false,
            accountNonLocked: true,
            firstName: "",
            lastName: "",
            middleName: "",
            address: {
              address: null,
              city: null,
              state: null,
              zip: null,
              country: null,
              lat: 0.0,
              lng: 0.0,
            },
            mobileNumber: "(077) 401-0371",
            stripe_id: "",
            provider: "local",
            coverImage: "",
          },
          order: 1,
        },
      ],
      application: {
        apply: [
          {
            applicant: {
              username: this.state.username,
              drivingLicNo: "67578",
              emergencyNo: null,
              ssn: null,
              dob: null,
              otherName: null,
              gender: null,
              resPresentAddress: this.state.resPresentAddress,
              resPresentCity: this.state.resPresentCity,
              resPresentState: this.state.resPresentState,
              resPresentZip: this.state.resPresentZip,
              resPresentPhone: null,
              resPresentFax: null,
              resPresentRent: this.state.resPresentRent
                ? parseInt(this.state.resPresentRent).toFixed(2)
                : 0.0,
              resPresentFrom: this.state.resPresentFrom,
              resPresentTo: this.state.resPresentTo,
              resPresentOwnRent: null,
              resPresentLandlord: this.state.resPresentLandlord,
              resPresentLandlordEmail: null,
              resPresentLandlordTp: this.state.resPresentLandlordTp,
              resPresentLandlordFax: null,
              resPresentApartment: null,
              resPresentUnitType: null,
              resPresentMortgage: false,
              resPresentReasonLeaving: null,
              resPresentLandlordIDProof: null,
              resPreviousAddress: this.state.resPreviousAddress,
              resPreviousCity: this.state.resPreviousCity,
              resPreviousState: this.state.resPreviousState,
              resPreviousZip: this.state.resPreviousZip,
              resPreviousRent: this.state.resPreviousRent
                ? parseInt(this.state.resPreviousRent).toFixed(2)
                : 0.0,
              resPreviousFrom: this.state.resPreviousFrom,
              resPreviousTo: this.state.resPreviousTo,
              resPreviousOwnRent: null,
              resPreviousLandlord: this.state.resPreviousLandlord,
              resPreviousLandlordEmail: null,
              resPreviousLandlordTp: this.state.resPreviousLandlordTp,
              resPreviousLandlordFax: null,
              resPreviousApartment: null,
              resPreviousUnitType: null,
              resPreviousMortgage: false,
              resPreviousReasonLeaving: null,
              empPresentEmployed: null,
              empPresentEmail: null,
              empPresentAddress: null,
              empPresentCity: null,
              empPresentState: null,
              empPresentZip: null,
              empPresentPhone: null,
              empPresentSalary: 0.0,
              empPresentFrom: null,
              empPresentTo: null,
              empPresentPosition: null,
              empPresentAnnualIncome: 0.0,
              empPreviousEmployed: null,
              empPreviousEmail: null,
              empPreviousAddress: null,
              empPreviousCity: null,
              empPreviousState: null,
              empPreviousZip: null,
              empPreviousPhone: null,
              empPreviousSalary: 0.0,
              empPreviousFrom: null,
              empPreviousTo: null,
              empPreviousPosition: null,
              empPreviousAnnualIncome: 0.0,
              emp2PreviousEmployed: null,
              emp2PreviousEmail: null,
              emp2PreviousAddress: null,
              emp2PreviousCity: null,
              emp2PreviousState: null,
              emp2PreviousZip: null,
              emp2PreviousPhone: null,
              emp2PreviousSalary: 0.0,
              emp2PreviousFrom: null,
              emp2PreviousTo: null,
              emp2PreviousPosition: null,
              emp2PreviousAnnualIncome: 0.0,
              spousesEmployer: null,
              spousesEmployerAddress: null,
              spousesIncome: 0.0,
              referenceName: null,
              referenceAddress: null,
              referenceTelephone: null,
              referenceName2: null,
              referenceAddress2: null,
              referenceTelephone2: null,
              emergencyContactName: this.state.emergencyContactName,
              emergencyContactPhone: this.state.emergencyContactPhone,
              emergencyContactFax: null,
              emergencyContactRelation: null,
              primaryBankName: null,
              primaryBankBranch: null,
              primaryBankAccType: null,
              primaryBankAccNo: null,
              secondaryBankName: null,
              secondaryBankBranch: null,
              secondaryBankAccType: null,
              secondaryBankAccNo: null,
              relativeNum: null,
              relativeName: null,
              additionalIncome: null,
              bankruptcy: false,
              crimeCheck: false,
              evicted: false,
              smoke: false,
              homePhone: null,
              resPresentYears: 0,
              resPresentMonths: 0,
              resPreviousYear: 0,
              resPreviousMonth: 0,
              empPresentYears: 0,
              empPresentMonths: 0,
              empPreviousYear: 0,
              empPreviousMonth: 0,
              vehicle: false,
              vehicleType: null,
              vehicleMadeBy: null,
              vehicleModel: null,
              vehicleYear: null,
              vehicleLicenseNumber: null,
              // createdAt: "2021-09-17T17:45:05.546+0000",
              // updatedAt: "2021-09-17T17:45:05.546+0000",
            },
            applicantOrder: 1,
          },
        ],
        businessApplication: {
          businessName: this.state.business.businessName,
          establish: this.state.business.establish,
          contactPhone: this.state.business.contactPhone,
          contactFax: this.state.business.contactFax,
          employessCount: parseInt(this.state.business.employessCount),
          businessType: this.state.business.businessType,
          ownershipType: this.state.business.ownershipType,
          intend: this.state.business.intend,
          parentCompany: this.state.parentCompany,
          parentCompanyName: this.state.parentCompany
            ? this.state.parentCompanyName
            : "",
          parentCompanyAddress: {
            address: this.state.parentCompany
              ? this.state.parentCompanyAddress
              : "",
            city: this.state.parentCompany ? this.state.parentCompanyCity : "",
            state: this.state.parentCompany
              ? this.state.parentCompanyState
              : "",
            zip: this.state.parentCompany ? this.state.parentCompanyZip : "",
            country: this.state.parentCompany
              ? this.state.parentCompanyCountry
              : "",
            lat: 0.0,
            lng: 0.0,
          },
          owners: owner,
          primaryBank: this.state.primaryBankName,
          primaryBankPhone: this.state.primaryBankPhone,
          primaryBankAddress: {
            address: this.state.primaryBankAddress,
            city: this.state.primaryBankCity,
            state: this.state.primaryBankState,
            zip: this.state.primaryBankZip,
            country: null,
            lat: 0.0,
            lng: 0.0,
          },
          secondaryBank: this.state.secondaryBankName,
          secondaryBankPhone: this.state.secondaryBankPhone,
          secondaryBankAddress: {
            address: this.state.secondaryBankAddress,
            city: this.state.secondaryBankCity,
            state: this.state.secondaryBankState,
            zip: this.state.secondaryBankZip,
            country: null,
            lat: 0.0,
            lng: 0.0,
          },
          creditReferences: [
            {
              companyName: this.state.referenceCompanyName,
              contactName: this.state.referenceCompanyContactName,
              phone: this.state.referenceCompanyPhone,
              companyAddress: {
                address: this.state.referenceCompanyAddress,
                city: this.state.referenceCompanyCity,
                state: this.state.referenceCompanyState,
                zip: this.state.referenceCompanyZip,
                country: null,
                lat: 0.0,
                lng: 0.0,
              },
            },
            {
              companyName: this.state.referenceCompany2Name,
              contactName: this.state.referenceCompany2ContactName,
              phone: this.state.referenceCompany2Phone,
              companyAddress: {
                address: this.state.referenceCompany2Address,
                city: this.state.referenceCompany2City,
                state: this.state.referenceCompany2State,
                zip: this.state.referenceCompany2Zip,
                country: null,
                lat: 0.0,
                lng: 0.0,
              },
            },
            {
              companyName: this.state.referenceCompany3Name,
              contactName: this.state.referenceCompany3ContactName,
              phone: this.state.referenceCompany3Phone,
              companyAddress: {
                address: this.state.referenceCompany3Address,
                city: this.state.referenceCompany3City,
                state: this.state.referenceCompany3State,
                zip: this.state.referenceCompany3Zip,
                country: null,
                lat: 0.0,
                lng: 0.0,
              },
            },
          ],
        },
        rentalEntityId: this.props.match.params.id
          ? this.props.match.params.id
          : 1,
        rent: this.state.rentalEntity && this.state.rentalEntity.rent,
        pets: false,
        petCount: 0,
        creditCheck: null,
        securityCheck: null,
        applicationForm: null,
        occupant: null,
        leaseTerm: 0,
        moveInDate: null,
        status: "PENDING",
        totalOccupants: 0,
        hydro: false,
        gas: false,
        heat: false,
        hotWaterHeater: false,
        hotWaterTank: false,
        internet: false,
        cableTv: false,
        other: false,
        water: false,
      },
      applicationFeeModel: {},
      token: "tok_visa",
    };
    try {
      setTorontoOfficeApplication(body).then((response) => {
        if (response.status === 200) {
          if (response.status) {
            let message = response.data.data;
            this.props.pushAlert("Application Sent Successfully", "success");
            if (message === "Already Applied") {
              this.props.pushAlert(
                String("This application is" + message),
                "warning"
              );
            }

            this.props.history.push({ pathname: "/rental-units" });
          }
        } else {
          this.props.pushAlert("Application creation failed", "danger");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  async addReference() {
    await this.setState({ next: true });
    if (this.state.stage === 1) {
      if (
        this.state.referenceCompanyName !== "" &&
        this.state.referenceCompanyContactName !== "" &&
        this.state.referenceCompanyAddress !== "" &&
        this.state.referenceCompanyCity !== "" &&
        this.state.referenceCompanyState !== "" &&
        this.state.referenceCompanyZip !== "" &&
        this.checkMobileNext(this.state.referenceCompanyPhone) &&
        this.state.referenceCompanyPhone !== ""
      ) {
        this.setState({
          activeReference: this.state.activeReference + 1,
          stage: this.state.stage + 1,
          next: false,
        });
      }
    } else if (this.state.stage === 2) {
      if (
        this.state.referenceCompanyName !== "" &&
        this.state.referenceCompanyContactName !== "" &&
        this.state.referenceCompanyAddress !== "" &&
        this.state.referenceCompanyCity !== "" &&
        this.state.referenceCompanyState !== "" &&
        this.state.referenceCompanyZip !== "" &&
        this.state.referenceCompanyPhone !== "" &&
        this.checkMobileNext(this.state.referenceCompanyPhone) &&
        this.state.referenceCompany2Name !== "" &&
        this.state.referenceCompany2ContactName !== "" &&
        this.state.referenceCompany2Address !== "" &&
        this.state.referenceCompany2City !== "" &&
        this.state.referenceCompany2State !== "" &&
        this.state.referenceCompany2Zip !== "" &&
        this.checkMobileNext(this.state.referenceCompany2Phone) &&
        this.state.referenceCompany2Phone !== ""
      ) {
        this.setState({
          activeReference: this.state.activeReference + 1,
          stage: this.state.stage + 1,
          next: false,
        });
      }
    }
  }
  async addOwner() {
    await this.setState({ next: true });
    if (this.state.ownerCount === 1) {
      if (
        this.state.firstOwnerName !== "" &&
        this.state.firstOwnerEmail !== "" &&
        this.checkEmailNext(this.state.firstOwnerEmail) &&
        this.state.firstOwnerContactNumber !== "" &&
        this.checkMobileNext(this.state.firstOwnerContactNumber) &&
        this.checkMobileNext(this.state.firstOwnerContactNumber2) &&
        this.state.firstOwnerContactNumber2 !== "" &&
        this.state.firstOwnerAddress !== "" &&
        this.state.firstOwnerState !== "" &&
        this.state.firstOwnerCity !== "" &&
        this.state.firstOwnerZip !== "" &&
        this.state.firstOwnerDOB !== "" &&
        this.state.firstOwnerSSN !== "" &&
        this.state.firstOwnerDLN !== ""
      ) {
        this.setState({
          ownerCount: this.state.ownerCount + 1,
          activeOwner: this.state.ownerCount + 1,
          next: false,
        });
      }
    } else if (this.state.ownerCount === 2) {
      if (
        this.state.firstOwnerName !== "" &&
        this.state.firstOwnerEmail !== "" &&
        this.checkEmailNext(this.state.firstOwnerEmail) &&
        this.state.firstOwnerContactNumber !== "" &&
        this.checkMobileNext(this.state.firstOwnerContactNumber) &&
        this.state.firstOwnerContactNumber2 !== "" &&
        this.checkMobileNext(this.state.firstOwnerContactNumber2) &&
        this.state.firstOwnerAddress !== "" &&
        this.state.firstOwnerState !== "" &&
        this.state.firstOwnerCity !== "" &&
        this.state.firstOwnerZip !== "" &&
        this.state.firstOwnerDOB !== "" &&
        this.state.firstOwnerSSN !== "" &&
        this.state.firstOwnerDLN !== "" &&
        this.state.secondOwnerName !== "" &&
        this.state.secondOwnerEmail !== "" &&
        this.checkEmailNext(this.state.secondOwnerEmail) &&
        this.state.secondOwnerContactNumber !== "" &&
        this.state.secondOwnerContactNumber2 !== "" &&
        this.checkMobileNext(this.state.secondOwnerContactNumber) &&
        this.checkMobileNext(this.state.secondOwnerContactNumber2) &&
        this.state.secondOwnerAddress !== "" &&
        this.state.secondOwnerState !== "" &&
        this.state.secondOwnerCity !== "" &&
        this.state.secondOwnerZip !== "" &&
        this.state.secondOwnerDOB !== "" &&
        this.state.secondOwnerSSN !== "" &&
        this.state.secondOwnerDLN !== ""
      ) {
        this.setState({
          ownerCount: this.state.ownerCount + 1,
          activeOwner: this.state.ownerCount + 1,
          next: false,
        });
      }
    }
  }
  removeOwner() {
    this.setState({ ownerCount: this.state.ownerCount - 1 });
  }
  checkNull(value) {
   
    return ((value === "" || value === null) && this.state.next);
  }
  goToStep(step) {
    if (step === 0) {
      window.scrollTo(0, 0);
      window.onbeforeunload = "";
      this.setState({ nowTap: 0 });
    } else if (step <= this.state.step) {
      const x = document.getElementById("stepBottom" + (step - 1)).offsetTop;
      window.scrollTo(0, x);
      this.setState({ nowTap: step });
    } else {
      this.props.pushAlert("Please Fill the form correct order", "warning");
    }
  }
  checkEmail(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    return (
      (!re.test(String(value).toLowerCase()) || value === "") &&
      this.state.next
    );
  }

  checkMobile(value) {
    
    return ((value.length !== 14 || value === "") && this.state.next);
  }
  checkSIN(value) {
    
    return ((value.length !== 11 || value === "") && this.state.next);
  }
  checkAccountNo(value) {
    return ((value.length !== 25 || value === "") && this.state.next);
  }
  checkEmailNext(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
    return !(!re.test(String(value).toLowerCase()) || value === "");
  }
  checkMobileNext(value) {
   
    return (value.length === 14);
  }
  checkAccountNoNext(value) {
    
    return (value.length === 25) ;
  }
  checkSINNext(value) {

    return (value.length === 11);
  }
  reCheck(checkTab) {
    if (checkTab === 0) {
      if (
        this.state.business.businessName !== "" &&
        this.state.business.establish !== "" &&
        this.state.business.contactPhone !== "" &&
        this.checkMobileNext(this.state.business.contactPhone) &&
        // this.state.business.contactFax !== "" &&
        this.checkEmailNext(this.state.username) &&
        this.state.username !== ""
      ) {
        return true;
      } else {
        setTimeout(function () {
          window.scrollTo(0, 0);
          window.onbeforeunload = "";
        }, 100);
        return false;
      }
    } else if (checkTab === 1) {
      if (
        this.state.business.employessCount !== "" &&
        this.state.business.businessType !== "" &&
        this.state.business.ownershipType !== "" &&
        this.state.business.intend !== "" &&
        this.state.emergencyContactName !== "" &&
        this.checkMobileNext(this.state.emergencyContactPhone) &&
        this.state.emergencyContactPhone !== ""
      ) {
        if (this.state.parentCompany) {
          if (
            this.state.parentCompanyName !== "" &&
            this.state.parentCompanyAddress !== "" &&
            this.state.parentCompanyCity !== "" &&
            this.state.parentCompanyState !== "" &&
            this.state.parentCompanyZip !== ""
          ) {
            return true;
          } else {
            const x = document.getElementById("stepBottom0").offsetTop;
            setTimeout(function () {
              window.scrollTo(0, x);
            }, 100);
            return false;
          }
        } else {
          return true;
        }
      } else {
        const x = document.getElementById("stepBottom0").offsetTop;
        setTimeout(function () {
          window.scrollTo(0, x);
        }, 100);
        return false;
      }
    } else if (checkTab === 2) {
      if (
        this.state.resPresentLandlord !== "" &&
        this.state.resPresentAddress !== "" &&
        this.state.resPresentLandlordTp !== "" &&
        this.checkMobileNext(this.state.resPresentLandlordTp) &&
        this.state.resPresentCity !== "" &&
        this.state.resPresentState !== "" &&
        this.state.resPresentZip !== "" &&
        this.state.resPresentRent !== "" &&
        this.state.resPresentFrom !== "" &&
        this.state.resPresentTo !== ""
      ) {
        if (this.state.activeRental !== 1) {
          if (
            this.state.resPreviousLandlord !== "" &&
            this.state.resPreviousAddress !== "" &&
            this.state.resPreviousLandlordTp !== "" &&
            this.checkMobileNext(this.state.resPreviousLandlordTp) &&
            this.state.resPreviousCity !== "" &&
            this.state.resPreviousState !== "" &&
            this.state.resPreviousZip !== "" &&
            this.state.resPreviousRent !== "" &&
            this.state.resPreviousFrom !== "" &&
            this.state.resPreviousTo !== ""
          ) {
            return true;
          } else {
            const x = document.getElementById("stepBottom1").offsetTop;
            setTimeout(function () {
              window.scrollTo(0, x);
            }, 100);
            return false;
          }
        } else {
          return true;
        }
      } else {
        const x = document.getElementById("stepBottom1").offsetTop;
        setTimeout(function () {
          window.scrollTo(0, x);
        }, 100);
        return false;
      }
    } else if (checkTab === 3) {
      if (
        this.state.firstOwnerName !== "" &&
        this.state.firstOwnerEmail !== "" &&
        this.checkEmailNext(this.state.firstOwnerEmail) &&
        this.state.firstOwnerContactNumber !== "" &&
        this.state.firstOwnerContactNumber2 !== "" &&
        this.checkMobileNext(this.state.firstOwnerContactNumber) &&
        this.checkMobileNext(this.state.firstOwnerContactNumber2) &&
        this.state.firstOwnerAddress !== "" &&
        this.state.firstOwnerState !== "" &&
        this.state.firstOwnerCity !== "" &&
        this.state.firstOwnerZip !== "" &&
        this.state.firstOwnerDOB !== "" &&
        this.state.firstOwnerSSN !== "" &&
        this.state.firstOwnerDLN !== ""
      ) {
        if (this.state.ownerCount > 1) {
          if (
            this.state.secondOwnerName !== "" &&
            this.state.secondOwnerEmail !== "" &&
            this.checkEmailNext(this.state.secondOwnerEmail) &&
            this.state.secondOwnerContactNumber !== "" &&
            this.state.secondOwnerContactNumber2 !== "" &&
            this.checkMobileNext(this.state.secondOwnerContactNumber) &&
            this.checkMobileNext(this.state.secondOwnerContactNumber2) &&
            this.state.secondOwnerAddress !== "" &&
            this.state.secondOwnerState !== "" &&
            this.state.secondOwnerCity !== "" &&
            this.state.secondOwnerZip !== "" &&
            this.state.secondOwnerDOB !== "" &&
            this.state.secondOwnerSSN !== "" &&
            this.state.secondOwnerDLN !== ""
          ) {
            if (this.state.ownerCount > 2) {
              if (
                this.state.thirdOwnerName !== "" &&
                this.state.thirdOwnerEmail !== "" &&
                this.checkEmailNext(this.state.thirdOwnerEmail) &&
                this.state.thirdOwnerContactNumber !== "" &&
                this.state.thirdOwnerContactNumber2 !== "" &&
                this.checkMobileNext(this.state.thirdOwnerContactNumber) &&
                this.checkMobileNext(this.state.thirdOwnerContactNumber2) &&
                this.state.thirdOwnerAddress !== "" &&
                this.state.thirdOwnerState !== "" &&
                this.state.thirdOwnerCity !== "" &&
                this.state.thirdOwnerZip !== "" &&
                this.state.thirdOwnerDOB !== "" &&
                this.state.thirdOwnerSSN !== "" &&
                this.state.thirdOwnerDLN !== ""
              ) {
                return true;
              } else {
                const x = document.getElementById("stepBottom2").offsetTop;
                setTimeout(function () {
                  window.scrollTo(0, x);
                }, 100);
                return false;
              }
            } else {
              return true;
            }
          } else {
            const x = document.getElementById("stepBottom2").offsetTop;
            setTimeout(function () {
              window.scrollTo(0, x);
            }, 100);
            return false;
          }
        } else {
          return true;
        }
      } else {
        const x = document.getElementById("stepBottom2").offsetTop;
        setTimeout(function () {
          window.scrollTo(0, x);
        }, 100);
        return false;
      }
    } else if (checkTab === 4) {
      if (
        this.state.primaryBankName !== "" &&
        this.state.primaryBankPhone !== "" &&
        this.checkMobileNext(this.state.primaryBankPhone) &&
        this.state.primaryBankAddress !== "" &&
        this.state.primaryBankState !== "" &&
        this.state.primaryBankCity !== "" &&
        this.state.primaryBankZip !== ""
        // this.state.secondaryBankName !== "" &&
        // this.state.secondaryBankPhone !== "" &&
        // this.checkMobileNext(this.state.secondaryBankPhone) &&
        // this.state.secondaryBankAddress !== "" &&
        // this.state.secondaryBankState !== "" &&
        // this.state.secondaryBankCity !== "" &&
        // this.state.secondaryBankZip !== ""
      ) {
        return true;
      } else {
        const x = document.getElementById("stepBottom3").offsetTop;
        setTimeout(function () {
          window.scrollTo(0, x);
        }, 100);
        return false;
      }
    } else if (checkTab === 5) {
      if (
        this.state.referenceCompanyName !== "" &&
        this.state.referenceCompanyContactName !== "" &&
        this.state.referenceCompanyAddress !== "" &&
        this.state.referenceCompanyCity !== "" &&
        this.state.referenceCompanyState !== "" &&
        this.state.referenceCompanyZip !== "" &&
        this.state.referenceCompanyPhone !== "" &&
        this.checkMobileNext(this.state.referenceCompanyPhone) &&
        this.state.referenceCompany2Name !== "" &&
        this.state.referenceCompany2ContactName !== "" &&
        this.state.referenceCompany2Address !== "" &&
        this.state.referenceCompany2City !== "" &&
        this.state.referenceCompany2State !== "" &&
        this.state.referenceCompany2Zip !== "" &&
        this.state.referenceCompany2Phone !== "" &&
        this.checkMobileNext(this.state.referenceCompany2Phone) &&
        this.state.referenceCompany3Name !== "" &&
        this.state.referenceCompany3ContactName !== "" &&
        this.state.referenceCompany3Address !== "" &&
        this.state.referenceCompany3City !== "" &&
        this.state.referenceCompany3State !== "" &&
        this.state.referenceCompany3Zip !== "" &&
        this.checkMobileNext(this.state.referenceCompany3Phone) &&
        this.state.referenceCompany3Phone !== ""
      ) {
        return true;
      } else {
        const x = document.getElementById("stepBottom4").offsetTop;
        setTimeout(function () {
          window.scrollTo(0, x);
        }, 100);
        return false;
      }
    }
  }
  async next() {
    await this.setState({ next: true });
    if (this.state.step === 0) {
      if (
        this.state.business.businessName !== "" &&
        this.state.business.establish !== "" &&
        this.state.business.contactPhone !== "" &&
        this.checkMobileNext(this.state.business.contactPhone) &&
        // this.state.business.contactFax !== "" &&
        this.checkEmailNext(this.state.username) &&
        this.state.username !== ""
      ) {
        await this.setState({ step: 1, next: false });
        const x = document.getElementById("stepBottom0").offsetTop;
        setTimeout(function () {
          window.scrollTo(0, x);
        }, 100);
      }
    } else if (this.state.step === 1) {
      if (
        this.state.business.employessCount !== "" &&
        this.state.business.businessType !== "" &&
        this.state.business.ownershipType !== "" &&
        this.state.business.intend !== "" &&
        this.state.emergencyContactName !== "" &&
        this.checkMobileNext(this.state.emergencyContactPhone) &&
        this.state.emergencyContactPhone !== ""
      ) {
        if (this.state.parentCompany) {
          if (
            this.state.parentCompanyName !== "" &&
            this.state.parentCompanyAddress !== "" &&
            this.state.parentCompanyCity !== "" &&
            this.state.parentCompanyState !== "" &&
            this.state.parentCompanyZip !== ""
          ) {
            if (this.reCheck(0)) {
              await this.setState({ step: 2, next: false });
              const x = document.getElementById("stepBottom1").offsetTop;
              setTimeout(function () {
                window.scrollTo(0, x);
              }, 100);
            }
          }
        } else {
          if (this.reCheck(0)) {
            await this.setState({ step: 2, next: false });
            const x = document.getElementById("stepBottom1").offsetTop;
            setTimeout(function () {
              window.scrollTo(0, x);
            }, 100);
          }
        }
      }
    } else if (this.state.step === 2) {
      if (
        this.state.resPresentLandlord !== "" &&
        this.state.resPresentAddress !== "" &&
        this.state.resPresentLandlordTp !== "" &&
        this.checkMobileNext(this.state.resPresentLandlordTp) &&
        this.state.resPresentCity !== "" &&
        this.state.resPresentState !== "" &&
        this.state.resPresentZip !== "" &&
        this.state.resPresentRent !== "" &&
        this.state.resPresentFrom !== "" &&
        this.state.resPresentTo !== ""
      ) {
        if (this.state.activeRental !== 1) {
          if (
            this.state.resPreviousLandlord !== "" &&
            this.state.resPreviousAddress !== "" &&
            this.state.resPreviousLandlordTp !== "" &&
            this.checkMobileNext(this.state.resPreviousLandlordTp) &&
            this.state.resPreviousCity !== "" &&
            this.state.resPreviousState !== "" &&
            this.state.resPreviousZip !== "" &&
            this.state.resPreviousRent !== "" &&
            this.state.resPreviousFrom !== "" &&
            this.state.resPreviousTo !== ""
          ) {
            if (this.reCheck(0) && this.reCheck(1)) {
              await this.setState({ step: 3, next: false });
              const x = document.getElementById("stepBottom2").offsetTop;
              setTimeout(function () {
                window.scrollTo(0, x);
              }, 100);
            }
          }
        } else {
          if (this.reCheck(0) && this.reCheck(1)) {
            await this.setState({ step: 3, next: false });
            const x = document.getElementById("stepBottom2").offsetTop;
            setTimeout(function () {
              window.scrollTo(0, x);
            }, 100);
          }
        }
      }
    } else if (this.state.step === 3) {
      if (
        this.state.firstOwnerName !== "" &&
        this.state.firstOwnerEmail !== "" &&
        this.checkEmailNext(this.state.firstOwnerEmail) &&
        this.state.firstOwnerContactNumber !== "" &&
        this.state.firstOwnerContactNumber2 !== "" &&
        this.checkMobileNext(this.state.firstOwnerContactNumber) &&
        this.checkMobileNext(this.state.firstOwnerContactNumber2) &&
        this.state.firstOwnerAddress !== "" &&
        this.state.firstOwnerState !== "" &&
        this.state.firstOwnerCity !== "" &&
        this.state.firstOwnerZip !== "" &&
        this.state.firstOwnerDOB !== "" &&
        this.state.firstOwnerSSN !== "" &&
        this.state.firstOwnerDLN !== ""
      ) {
        if (this.state.ownerCount > 1) {
          if (
            this.state.secondOwnerName !== "" &&
            this.state.secondOwnerEmail !== "" &&
            this.checkEmailNext(this.state.secondOwnerEmail) &&
            this.state.secondOwnerContactNumber !== "" &&
            this.state.secondOwnerContactNumber2 !== "" &&
            this.checkMobileNext(this.state.secondOwnerContactNumber) &&
            this.checkMobileNext(this.state.secondOwnerContactNumber2) &&
            this.state.secondOwnerAddress !== "" &&
            this.state.secondOwnerState !== "" &&
            this.state.secondOwnerCity !== "" &&
            this.state.secondOwnerZip !== "" &&
            this.state.secondOwnerDOB !== "" &&
            this.state.secondOwnerSSN !== "" &&
            this.state.secondOwnerDLN !== ""
          ) {
            if (this.state.ownerCount > 2) {
              if (
                this.state.thirdOwnerName !== "" &&
                this.state.thirdOwnerEmail !== "" &&
                this.checkEmailNext(this.state.thirdOwnerEmail) &&
                this.state.thirdOwnerContactNumber !== "" &&
                this.state.thirdOwnerContactNumber2 !== "" &&
                this.checkMobileNext(this.state.thirdOwnerContactNumber) &&
                this.checkMobileNext(this.state.thirdOwnerContactNumber2) &&
                this.state.thirdOwnerAddress !== "" &&
                this.state.thirdOwnerState !== "" &&
                this.state.thirdOwnerCity !== "" &&
                this.state.thirdOwnerZip !== "" &&
                this.state.thirdOwnerDOB !== "" &&
                this.state.thirdOwnerSSN !== "" &&
                this.state.thirdOwnerDLN !== ""
              ) {
                if (this.reCheck(0) && this.reCheck(1) && this.reCheck(2)) {
                  await this.setState({ step: 4, next: false });
                  const x = document.getElementById("stepBottom3").offsetTop;
                  setTimeout(function () {
                    window.scrollTo(0, x);
                  }, 100);
                }
              }
            } else {
              if (this.reCheck(0) && this.reCheck(1) && this.reCheck(2)) {
                await this.setState({ step: 4, next: false });
                const x = document.getElementById("stepBottom3").offsetTop;
                setTimeout(function () {
                  window.scrollTo(0, x);
                }, 100);
              }
            }
          }
        } else {
          if (this.reCheck(0) && this.reCheck(1) && this.reCheck(2)) {
            await this.setState({ step: 4, next: false });
            const x = document.getElementById("stepBottom3").offsetTop;
            setTimeout(function () {
              window.scrollTo(0, x);
            }, 100);
          }
        }
      }
    } else if (this.state.step === 4) {
      if (
        this.state.primaryBankName !== "" &&
        this.state.primaryBankPhone !== "" &&
        this.checkMobileNext(this.state.primaryBankPhone) &&
        this.state.primaryBankAddress !== "" &&
        this.state.primaryBankState !== "" &&
        this.state.primaryBankCity !== "" &&
        this.state.primaryBankZip !== ""
        // this.state.secondaryBankName !== "" &&
        // this.state.secondaryBankPhone !== "" &&
        // this.checkMobileNext(this.state.secondaryBankPhone) &&
        // this.state.secondaryBankAddress !== "" &&
        // this.state.secondaryBankState !== "" &&
        // this.state.secondaryBankCity !== "" &&
        // this.state.secondaryBankZip !== ""
      ) {
        if (
          this.reCheck(0) &&
          this.reCheck(1) &&
          this.reCheck(2) &&
          this.reCheck(3)
        ) {
          await this.setState({ step: 5, next: false });
          const x = document.getElementById("stepBottom4").offsetTop;
          setTimeout(function () {
            window.scrollTo(0, x);
          }, 100);
        }
      }
    } else if (this.state.step === 5) {
      if (
        this.state.referenceCompanyName !== "" &&
        this.state.referenceCompanyContactName !== "" &&
        this.state.referenceCompanyAddress !== "" &&
        this.state.referenceCompanyCity !== "" &&
        this.state.referenceCompanyState !== "" &&
        this.state.referenceCompanyZip !== "" &&
        this.state.referenceCompanyPhone !== "" &&
        this.checkMobileNext(this.state.referenceCompanyPhone) &&
        this.state.referenceCompany2Name !== "" &&
        this.state.referenceCompany2ContactName !== "" &&
        this.state.referenceCompany2Address !== "" &&
        this.state.referenceCompany2City !== "" &&
        this.state.referenceCompany2State !== "" &&
        this.state.referenceCompany2Zip !== "" &&
        this.state.referenceCompany2Phone !== "" &&
        this.checkMobileNext(this.state.referenceCompany2Phone) &&
        this.state.referenceCompany3Name !== "" &&
        this.state.referenceCompany3ContactName !== "" &&
        this.state.referenceCompany3Address !== "" &&
        this.state.referenceCompany3City !== "" &&
        this.state.referenceCompany3State !== "" &&
        this.state.referenceCompany3Zip !== "" &&
        this.checkMobileNext(this.state.referenceCompany3Phone) &&
        this.state.referenceCompany3Phone !== ""
      ) {
        if (
          this.reCheck(0) &&
          this.reCheck(1) &&
          this.reCheck(2) &&
          this.reCheck(3) &&
          this.reCheck(4)
        ) {
          await this.setState({ step: 6, next: false });
          const x = document.getElementById("stepBottom5").offsetTop;
          setTimeout(function () {
            window.scrollTo(0, x);
          }, 100);
        }
      }
    } else if (this.state.step === 6) {
      if (
        this.state.profitLossYear1 !== "" &&
        this.state.profitLossYear2 !== "" &&
        this.state.profitLossYear3 !== "" &&
        this.state.profitLossDoc1 !== "" &&
        this.state.profitLossDoc2 !== "" &&
        this.state.profitLossDoc3 !== "" &&
        this.state.financialYear1 !== "" &&
        this.state.financialYear2 !== "" &&
        this.state.financialYear3 !== "" &&
        this.state.financialDoc1 !== "" &&
        this.state.financialDoc2 !== "" &&
        this.state.financialDoc3 !== "" &&
        this.state.TaxYear1 !== "" &&
        this.state.TaxYear2 !== "" &&
        this.state.TaxYear3 !== "" &&
        this.state.TaxDoc1 !== "" &&
        this.state.TaxDoc2 !== "" &&
        this.state.TaxDoc3 !== ""
      ) {
        if (
          this.reCheck(0) &&
          this.reCheck(1) &&
          this.reCheck(2) &&
          this.reCheck(3) &&
          this.reCheck(4) &&
          this.reCheck(5)
        ) {
          await this.setState({ next: false });
          this.submit();
        }
      } else {
        this.props.pushAlert(
          "Please upload all documents and fill document years",
          "warning"
        );
      }
    }
  }
  onChangeParent(name, value) {
    this.setState({ [name]: value });
  }
  async onChange(e) {
    let { name, value } = e.target;

    if (
      name === "businessName" ||
      name === "establish" ||
      name === "contactPhone" ||
      name === "contactFax" ||
      name === "employessCount" ||
      name === "businessType" ||
      name === "ownershipType" ||
      name === "intend"
    ) {
      if (name === "contactPhone" || name === "contactFax") {
        let phone = value.replace(/[^\d]/g, "");
        value = phone;
        if (phone.length === 10) {
          value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        } else if (phone.length > 6) {
          value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
        } else if (phone.length > 3) {
          value = phone.replace(/(\d{3})/, "($1) ");
        } else if (phone.length > 1) {
          value = phone.replace(/(\d{1})/, "($1");
        }
        if (phone.length > 10) {
          this.onchangeArray(name, this.state.business[name], "business");
        } else {
          this.onchangeArray(name, value, "business");
        }
      } else if (name === "employessCount") {
        value = value.replace(/[^0-9]+/g, "");
        this.onchangeArray(name, value, "business");
      } else {
        this.onchangeArray(name, value, "business");
      }
    } else if (name === "resPresentRent" || name === "resPreviousRent") {
      value = value.replace(/[^0-9]+/g, "");
      this.setState({ [name]: value });
    } else if (
      name === "firstOwnerDLN" ||
      name === "secondOwnerDLN" ||
      name === "thirdOwnerDLN"
    ) {
      value = value.replace(/[^0-9a-zA-Z]+/g, "");
      this.setState({ [name]: value });
    } else if (
      name === "firstOwnerSSN" ||
      name === "secondOwnerSSN" ||
      name === "thirdOwnerSSN"
    ) {
      let phone = value.replace(/[^\d]/g, "");
      value = phone;
      if (phone.length === 9) {
        value = phone.replace(/(\d{3})(\d{2})(\d{4})/, "$1 - $2 - $3");
      } else if (phone.length > 5) {
        value = phone.replace(/(\d{3})(\d{2})/, "$1 - $2 - ");
      } else if (phone.length > 3) {
        value = phone.replace(/(\d{3})/, "$1 - ");
      } else if (phone.length > 1) {
        value = phone.replace(/(\d{1})/, "$1");
      }
      if (phone.length > 9) {
        this.setState({ [name]: this.state[name] });
      } else {
        this.setState({ [name]: value });
      }
    } else if (name === "username") {
      this.changeEmailMessage();
      await this.setState({ [name]: value });
    } else if (name === "businessName") {
      this.onchangeArray(name, value, "business");
    } else {
      if (
        name === "emergencyContactPhone" ||
        name === "primaryBankPhone" ||
        name === "secondaryBankPhone" ||
        name === "referenceCompanyPhone" ||
        name === "referenceCompany2Phone" ||
        name === "referenceCompany3Phone" ||
        name === "resPresentLandlordTp" ||
        name === "thirdOwnerContactNumber" ||
        name === "thirdOwnerContactNumber2" ||
        name === "firstOwnerContactNumber" ||
        name === "firstOwnerContactNumber2" ||
        name === "secondOwnerContactNumber" ||
        name === "secondOwnerContactNumber2" ||
        name === "resPreviousLandlordTp"
      ) {
        let phone = value.replace(/[^\d]/g, "");
        value = phone;
        if (phone.length === 10) {
          value = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        } else if (phone.length > 6) {
          value = phone.replace(/(\d{3})(\d{3})/, "($1) $2-");
        } else if (phone.length > 3) {
          value = phone.replace(/(\d{3})/, "($1) ");
        } else if (phone.length > 1) {
          value = phone.replace(/(\d{1})/, "($1");
        }
        if (phone.length > 10) {
          await this.setState({ [name]: this.state[name] });
        } else {
          await this.setState({ [name]: value });
        }
      } else {
        await this.setState({ [name]: value });
      }
    }
  }
  async onchangeArray(name, value, array) {
    await this.setState({ [array]: { ...this.state[array], [name]: value } });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    this.getEntity();
  }
  async addRental() {
    await this.setState({ next: true });
    if (
      this.state.resPresentLandlord !== "" &&
      this.state.resPresentAddress !== "" &&
      this.state.resPresentLandlordTp !== "" &&
      this.checkMobileNext(this.state.resPresentLandlordTp) &&
      this.state.resPresentCity !== "" &&
      this.state.resPresentState !== "" &&
      this.state.resPresentZip !== "" &&
      this.state.resPresentRent !== "" &&
      this.state.resPresentFrom !== "" &&
      this.state.resPresentTo !== ""
    ) {
      this.setState({
        activeRental: this.state.activeRental + 1,
        stageRental: this.state.stageRental + 1,
        next: false,
      });
    }
  }
  removeRental() {
    this.setState({
      activeRental: this.state.activeRental - 1,
      stageRental: this.state.stageRental - 1,
    });
  }
  setParentState(name, value) {
    this.setState({ [name]: value });
  }
  alignData(props) {
    return (
      <div className="row">
        <div className="col-5">
          <span className="span6">{props.label}</span>
        </div>
        <div className="col-1">
          <span className="span">:</span>
        </div>
        <div className="col-6 item-left">
          <span className="span6">
            {props.value === "checked" ? (
              <i className="fa fa-check-square-o" aria-hidden="true"></i>
            ) : props.value === "unChecked" ? (
              <i className="fa fa-square-o" aria-hidden="true"></i>
            ) : (
              props.value
            )}
          </span>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div
        className="medium-container"
        style={{ marginTop: "120px", minHeight: window.screen.height }}
      >
        <div className="container-fluid ">
          <div className="row ">
            <div className=" navBottom0 text-left ">
              <div className="  pt-1 pl-5">
                <p className="officeApplicationTitle mb-4 ">Steps</p>
                <div className="pointer" onClick={() => this.goToStep(0)}>
                  <div className=" social-container-step activeStep">
                    <div className="social ">
                      <i className="fa fa-play" aria-hidden="true"></i>
                    </div>
                  </div>
                  <span className="officeApplicationPara pl-3">
                    Beginning Process
                  </span>
                </div>
                {this.state.step > 0 ? (
                  <div className="verticalLineRed"></div>
                ) : (
                  <div className="verticalLine"></div>
                )}
                <div className="pointer" onClick={() => this.goToStep(1)}>
                  <div
                    className={
                      this.state.step > 0
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-briefcase" aria-hidden="true"></i>
                    </div>
                  </div>
                  <span className="officeApplicationPara pl-3">
                    Business Information
                  </span>
                </div>
                {this.state.step > 1 ? (
                  <div className="verticalLineRed"></div>
                ) : (
                  <div className="verticalLine"></div>
                )}
                <div className="pointer" onClick={() => this.goToStep(2)}>
                  <div
                    className={
                      this.state.step > 1
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-home" aria-hidden="true"></i>
                    </div>
                  </div>
                  <span className="officeApplicationPara pl-3">
                    Rental Information
                  </span>
                </div>
                {this.state.step > 2 ? (
                  <div className="verticalLineRed"></div>
                ) : (
                  <div className="verticalLine"></div>
                )}
                <div className="pointer" onClick={() => this.goToStep(3)}>
                  <div
                    className={
                      this.state.step > 2
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-key" aria-hidden="true"></i>
                    </div>
                  </div>
                  <span className="officeApplicationPara pl-3">
                    Ownership Information
                  </span>
                </div>
                {this.state.step > 3 ? (
                  <div className="verticalLineRed"></div>
                ) : (
                  <div className="verticalLine"></div>
                )}
                <div className="pointer" onClick={() => this.goToStep(4)}>
                  <div
                    className={
                      this.state.step > 3
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-building" aria-hidden="true"></i>
                    </div>
                  </div>
                  <span className="officeApplicationPara pl-3">
                    Banking Information
                  </span>
                </div>
                {this.state.step > 4 ? (
                  <div className="verticalLineRed"></div>
                ) : (
                  <div className="verticalLine"></div>
                )}
                <div className="pointer" onClick={() => this.goToStep(5)}>
                  <div
                    className={
                      this.state.step > 4
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                  </div>
                  <span className="officeApplicationPara pl-3">
                    Credit Reference
                  </span>
                </div>
                {this.state.step > 5 ? (
                  <div className="verticalLineRed"></div>
                ) : (
                  <div className="verticalLine"></div>
                )}
                <div className="pointer" onClick={() => this.goToStep(6)}>
                  <div
                    className={
                      this.state.step > 5
                        ? "social-container-step activeStep"
                        : "social-container-step"
                    }
                  >
                    <div className="social ">
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </div>
                  </div>
                  <span className="officeApplicationPara pl-3">
                    Upload Documents
                  </span>
                </div>
              </div>
            </div>
            <div className="col-3"></div>

            <div className="col-9  pt-1 d-flex justify-content-center">
              <div className="navBottom1">
                <p className="officeApplicationTitle mb-4 ">
                  Beginning Process
                </p>
                <div className="row">
                  {/* <div className="col-3 text-center mt-3">
                    <img
                      src={image}
                      className="officeOpenClock"
                      alt="appImage"
                    />
                  </div> */}
                  <div className="col pr-3 pl-3">
                    {/* <p className="officeApplicationSubTitle1">
                      Time to Complete
                    </p>
                    <span className="officeApplicationPara">
                      The online rental application will guide you through
                      several steps. The process takes approximately 20 minutes
                      to complete. Required field are indicated with an (*).
                    </span>

                    <p className="officeApplicationSubTitle1">Don't Worry</p>
                    <span className="officeApplicationPara">
                      If you need to step away from your computer to gether
                      information, feel free to log out. Upon returning , log in
                      and will find all your information in the same place you
                      left it.
                    </span> */}

                    <div className="row ">
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Business Name
                        </p>
                        <input
                          type="text"
                          name="businessName"
                          className={
                            this.checkNull(this.state.business.businessName)
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          placeholder="Business Name"
                          onChange={this.onChange}
                          value={this.state.business.businessName}
                        />
                        {this.checkNull(this.state.business.businessName) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter Business Name
                          </span>
                        )}
                      </div>

                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left mb-3">
                          Established
                        </p>
                        <input
                          type="date"
                          className={
                            this.checkNull(this.state.business.establish)
                              ? "form-control alert mb-0"
                              : "form-control"
                          }
                          name="establish"
                          onChange={this.onChangeParent}
                          value={this.state.business.establish}
                        />
                        {this.checkNull(this.state.business.establish) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            Please Enter Business Name
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 text-center ">
                        <p className="officeApplicationSubTitle pull-left ">
                          Contact
                        </p>
                      </div>
                      <div className="col-6 text-center ">
                        <div className="input-group ">
                          <input
                            type="text"
                            className={
                              this.checkMobile(this.state.business.contactPhone)
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            name="contactPhone"
                            placeholder="Phone No"
                            onChange={this.onChange}
                            value={this.state.business.contactPhone}
                          />
                          {this.checkMobile(
                            this.state.business.contactPhone
                          ) && (
                            <span
                              className="textPri pull-left"
                              style={{ width: "100%", textAlign: "left" }}
                            >
                              {this.checkNull(this.state.business.contactPhone)
                                ? "Please Enter Business Phone No"
                                : "Please Enter Phone No Correct Format"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-6 text-center">
                        <div className="input-group  ">
                          <input
                            type="fax"
                            className={
                              // this.checkNull(this.state.business.contactFax)
                              //   ? "form-control alert mb-0"
                              //   :
                              "form-control"
                            }
                            placeholder="Fax No"
                            name="contactFax"
                            onChange={this.onChange}
                            value={this.state.business.contactFax}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 text-center ">
                        <p className="officeApplicationSubTitle pull-left ">
                          Email
                        </p>
                        <div
                          className="outSpan absolute "
                          style={{ marginLeft: "50px", marginTop: "20px" }}
                          onMouseOver={this.changeEmailMessage}
                        >
                          <span className="infoHandel">
                            <i
                              className="fa fa-question-circle infoIcon"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                        <div className="input-group">
                          <input
                            type="email"
                            className={
                              this.checkEmail(this.state.username)
                                ? "form-control alert mb-0"
                                : "form-control"
                            }
                            placeholder="Email"
                            name="username"
                            onChange={this.onChange}
                            value={this.state.username}
                          />
                          {this.state.emailMessage && (
                            <div
                              className=" informationListApp mt-5 absolute"
                              style={{ width: "330px" }}
                            >
                              <span className="searchListContentApp">
                                {
                                  "This will be used as a primary email for this account"
                                }
                              </span>
                            </div>
                          )}
                        </div>

                        {this.checkEmail(this.state.username) && (
                          <span
                            className="textPri pull-left"
                            style={{ width: "100%", textAlign: "left" }}
                          >
                            {this.checkNull(this.state.username)
                              ? "Please Enter Business Email"
                              : "Please Enter Business Email correct format "}
                          </span>
                        )}
                      </div>
                      <div className="col-6"></div>
                    </div>
                    {this.state.step === 0 && (
                      <button
                        className="pull-right mt-4"
                        onClick={() => this.next()}
                      >
                        Continue
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="stepBottom0" className="stepBottom"></div>
          {this.state.step > 0 && (
            <OfficeApplicationStep1
              index={1}
              step={this.state.step}
              next={this.next}
              state={this.state}
              checkNull={this.checkNull}
              onChange={this.onChange}
              onChangeDate={this.onChangeParent}
              checkMobile={this.checkMobile}
              checkEmail={this.checkEmail}
              onchangeCheck={this.onchangeCheck}
            />
          )}
          <div id="stepBottom1" className="stepBottom"></div>
          {this.state.step > 1 && (
            <OfficeApplicationStep2
              index={2}
              state={this.state}
              step={this.state.step}
              next={this.next}
              checkNull={this.checkNull}
              onChange={this.onChange}
              onChangeDate={this.onChangeParent}
              setParentState={this.setParentState}
              addRental={this.addRental}
              removeRental={this.removeRental}
              checkMobile={this.checkMobile}
              checkEmail={this.checkEmail}
            />
          )}
          <div id="stepBottom2" className="stepBottom"></div>
          {this.state.step > 2 && (
            <OfficeApplicationStep3
              index={3}
              step={this.state.step}
              next={this.next}
              state={this.state}
              checkNull={this.checkNull}
              onChange={this.onChange}
              setParentState={this.setParentState}
              addRental={this.addRental}
              onChangeDate={this.onChangeParent}
              removeRental={this.removeRental}
              addOwner={this.addOwner}
              removeOwner={this.removeOwner}
              checkMobile={this.checkMobile}
              checkEmail={this.checkEmail}
            />
          )}
          <div id="stepBottom3" className="stepBottom"></div>
          {this.state.step > 3 && (
            <OfficeApplicationStep4
              index={4}
              step={this.state.step}
              next={this.next}
              state={this.state}
              checkNull={this.checkNull}
              onChangeDate={this.onChangeParent}
              onChange={this.onChange}
              setParentState={this.setParentState}
              checkMobile={this.checkMobile}
              checkEmail={this.checkEmail}
            />
          )}
          <div id="stepBottom4" className="stepBottom"></div>
          {this.state.step > 4 && (
            <OfficeApplicationStep5
              index={5}
              step={this.state.step}
              next={this.next}
              state={this.state}
              onChangeDate={this.onChangeParent}
              checkNull={this.checkNull}
              onChange={this.onChange}
              setParentState={this.setParentState}
              addReference={this.addReference}
              checkMobile={this.checkMobile}
              checkEmail={this.checkEmail}
            />
          )}
          <div id="stepBottom5" className="stepBottom"></div>
          {this.state.step > 5 && (
            <OfficeApplicationStep6
              index={6}
              step={this.state.step}
              next={this.next}
              onChangeDate={this.onChangeParent}
              checkNull={this.checkNull}
              state={this.state}
              onChange={this.onChange}
              onChangeParent={this.onChangeParent}
              setParentState={this.setParentState}
              submit={this.submit}
              checkMobile={this.checkMobile}
              checkEmail={this.checkEmail}
            />
          )}
          <div id="stepBottom6" className="stepBottom"></div>
        </div>
        <div
          className="modal fade bd-example-modal-xl"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div
                className="modal-header shadow"
                style={{ height: "80px", borderBottom: "1px solid #e09b81" }}
              >
                <h1
                  className="modal-title  textPri axText"
                  id="exampleModalLabel"
                  style={{ fontSize: "25px", marginTop: "0px" }}
                >
                  Application
                </h1>
                <button
                  type="button"
                  className="close "
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="closeModal textPri">
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body bodyAvailable pt-2  pb-0 bodyApplication">
                <div style={{ height: "12px" }} />
                <div>
                  <div className="row m-2 border shadow  mt-1 pt-4 pb-2 ">
                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 ">Basic</p>
                      <br />
                      <div className="pt-4">
                        <this.alignData
                          label="Business Name"
                          value={this.state.business.businessName}
                        />
                        <this.alignData
                          label="Established"
                          value={this.state.business.establish}
                        />
                        <this.alignData
                          label="Contact Phone"
                          value={this.state.business.contactPhone}
                        />
                        <this.alignData
                          label="Contact Fax"
                          value={this.state.business.contactFax}
                        />
                        <this.alignData
                          label="Email"
                          value={this.state.username}
                        />
                      </div>
                      <p className="pt-2   stepTitle  ">Primary Bank</p>
                      <br />
                      <div className="pt-2">
                        <this.alignData
                          label="Bank name"
                          value={this.state.primaryBankName}
                        />
                        <this.alignData
                          label="Bank Phone"
                          value={this.state.primaryBankPhone}
                        />
                        <this.alignData
                          label="Bank Address"
                          value={
                            this.state.primaryBankAddress +
                            ", " +
                            this.state.primaryBankCity +
                            ", " +
                            this.state.primaryBankState +
                            ", " +
                            this.state.primaryBankZip
                          }
                        />
                      </div>
                      <p className="pt-2   stepTitle  ">Secondary Bank</p>
                      <br />
                      <div className="pt-2">
                        <this.alignData
                          label="Bank name"
                          value={this.state.secondaryBankName}
                        />
                        <this.alignData
                          label="Bank Phone"
                          value={this.state.secondaryBankPhone}
                        />
                        <this.alignData
                          label="Bank Address"
                          value={
                            this.state.secondaryBankAddress +
                            ", " +
                            this.state.secondaryBankCity +
                            ", " +
                            this.state.secondaryBankState +
                            ", " +
                            this.state.secondaryBankZip
                          }
                        />
                      </div>
                    </div>
                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 ">
                        Business Info
                      </p>
                      <br />
                      <div className="pt-4">
                        <this.alignData
                          label="Number of Employee"
                          value={this.state.business.employessCount}
                        />
                        <this.alignData
                          label="Business Type"
                          value={this.state.business.businessType}
                        />
                        <this.alignData
                          label="Ownership Type"
                          value={this.state.business.ownershipType}
                        />
                        <this.alignData
                          label="Intent"
                          value={this.state.business.intend}
                        />
                        <this.alignData
                          label="Emergency Contact Name"
                          value={this.state.emergencyContactName}
                        />
                        <this.alignData
                          label="Emergency Contact Phone"
                          value={this.state.emergencyContactPhone}
                        />
                        {this.state.parentCompany && (
                          <>
                            <this.alignData
                              label="Parent Company"
                              value={this.state.parentCompanyName}
                            />
                            <this.alignData
                              label="Parent Company Address"
                              value={
                                this.state.parentCompanyAddress +
                                ", " +
                                this.state.parentCompanyState +
                                ", " +
                                this.state.parentCompanyCity +
                                ", " +
                                this.state.parentCompanyZip
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 ">Rental Info</p>
                      <br />
                      <p className="pt-4   stepTitle  ">Present</p>
                      <br />
                      <div className="pt-4 mb-2">
                        <this.alignData
                          label="Landlord Name"
                          value={this.state.resPresentLandlord}
                        />
                        <this.alignData
                          label="Phone"
                          value={this.state.resPresentLandlordTp}
                        />
                        <this.alignData
                          label="Address"
                          value={
                            this.state.resPresentAddress +
                            ", " +
                            this.state.resPresentState +
                            ", " +
                            this.state.resPresentZip
                          }
                        />
                        <this.alignData
                          label="Occupancy From"
                          value={setDateFormat(this.state.resPresentFrom)}
                        />
                        <this.alignData
                          label="Occupancy To"
                          value={setDateFormat(this.state.resPresentTo)}
                        />
                        <this.alignData
                          label="Monthly Rent"
                          value={this.state.resPresentRent}
                        />
                      </div>
                      {this.state.activeRental !== 1 && (
                        <>
                          <p className="pt-1   stepTitle  ">Previous</p>
                          {/* <br/> */}
                          <div className="pt-4">
                            <this.alignData
                              label="Landlord Name"
                              value={this.state.resPreviousLandlord}
                            />
                            <this.alignData
                              label="Phone"
                              value={this.state.resPreviousLandlordTp}
                            />
                            <this.alignData
                              label="Address"
                              value={
                                this.state.resPreviousAddress +
                                ", " +
                                this.state.resPreviousState +
                                ", " +
                                this.state.resPreviousZip
                              }
                            />
                            <this.alignData
                              label="Occupancy From"
                              value={setDateFormat(this.state.resPreviousFrom)}
                            />
                            <this.alignData
                              label="Occupancy To"
                              value={setDateFormat(this.state.resPreviousTo)}
                            />
                            <this.alignData
                              label="Monthly Rent"
                              value={this.state.resPreviousRent}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="col-xl-4  ">
                      <br />
                      <p className="pt-0   stepTitle textPri2 ">
                        Credit Reference
                      </p>
                      <br />
                      <p className="pt-4 stepTitle">First Reference</p>
                      <br />
                      <br />
                      <div className="pt-4">
                        <this.alignData
                          label="Company Name"
                          value={this.state.referenceCompanyName}
                        />
                        <this.alignData
                          label="Contact Name"
                          value={this.state.referenceCompanyContactName}
                        />
                        <this.alignData
                          label="Contact Phone"
                          value={this.state.referenceCompanyPhone}
                        />
                        <this.alignData
                          label="Address"
                          value={
                            this.state.referenceCompanyAddress +
                            ", " +
                            this.state.referenceCompanyCity +
                            ", " +
                            this.state.referenceCompanyState +
                            ", " +
                            this.state.referenceCompanyZip +
                            ", "
                          }
                        />
                      </div>
                    </div>

                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 "></p>
                      <br />
                      <br />
                      <p className="pt-4 stepTitle">Second Reference</p>
                      <br />
                      <div className="pt-4">
                        <this.alignData
                          label="Company Name"
                          value={this.state.referenceCompany2Name}
                        />
                        <this.alignData
                          label="Contact Name"
                          value={this.state.referenceCompany2ContactName}
                        />
                        <this.alignData
                          label="Contact Phone"
                          value={this.state.referenceCompany2Phone}
                        />
                        <this.alignData
                          label="Address"
                          value={
                            this.state.referenceCompany2Address +
                            ", " +
                            this.state.referenceCompany2City +
                            ", " +
                            this.state.referenceCompany2State +
                            ", " +
                            this.state.referenceCompany2Zip +
                            ", "
                          }
                        />
                      </div>
                    </div>

                    <div className="col-xl-4  ">
                      <p className="pt-0   stepTitle textPri2 "></p>
                      <br /> <br />
                      <p className="pt-4 stepTitle">Third Reference</p>
                      <br />
                      <div className="pt-4">
                        <this.alignData
                          label="Company Name"
                          value={this.state.referenceCompany3Name}
                        />
                        <this.alignData
                          label="Contact Name"
                          value={this.state.referenceCompany3ContactName}
                        />
                        <this.alignData
                          label="Contact Phone"
                          value={this.state.referenceCompany3Phone}
                        />
                        <this.alignData
                          label="Address"
                          value={
                            this.state.referenceCompany3Address +
                            ", " +
                            this.state.referenceCompany3City +
                            ", " +
                            this.state.referenceCompany3State +
                            ", " +
                            this.state.referenceCompany3Zip +
                            ", "
                          }
                        />
                      </div>
                    </div>
                    {this.state.ownerCount > 0 && (
                      <div className="col-xl-4  ">
                        <br />
                        <p className="pt-0   stepTitle textPri2 ">
                          Owners Info
                        </p>
                        <br />
                        <p className="pt-4   stepTitle  ">First owner</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Name"
                            value={this.state.firstOwnerName}
                          />

                          <this.alignData
                            label="Email"
                            value={this.state.firstOwnerEmail}
                          />
                          <this.alignData
                            label="Phone"
                            value={this.state.firstOwnerContactNumber}
                          />
                          <this.alignData
                            label="Alternative Phone"
                            value={this.state.firstOwnerContactNumber2}
                          />
                          <this.alignData
                            label="Date of Birth"
                            value={this.state.firstOwnerDOB}
                          />
                          <this.alignData
                            label="Driving License No"
                            value={this.state.firstOwnerDLN}
                          />
                          <this.alignData
                            label="SSN"
                            value={this.state.firstOwnerSSN}
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.firstOwnerAddress +
                              ", " +
                              this.state.firstOwnerCity +
                              ", " +
                              this.state.firstOwnerState +
                              ", " +
                              this.state.firstOwnerZip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.ownerCount > 1 && (
                      <div className="col-xl-4  ">
                        <p className="pt-0   stepTitle textPri2 "></p>
                        <br /> <br />
                        <p className="pt-4   stepTitle  ">Second owner</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Name"
                            value={this.state.secondOwnerName}
                          />
                          <this.alignData
                            label="Email"
                            value={this.state.secondOwnerEmail}
                          />
                          <this.alignData
                            label="Phone"
                            value={this.state.secondOwnerContactNumber}
                          />
                          <this.alignData
                            label="Alternative Phone"
                            value={this.state.secondOwnerContactNumber2}
                          />
                          <this.alignData
                            label="Date of Birth"
                            value={this.state.secondOwnerDOB}
                          />
                          <this.alignData
                            label="Driving License No"
                            value={this.state.secondOwnerDLN}
                          />
                          <this.alignData
                            label="SSN"
                            value={this.state.secondOwnerSSN}
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.secondOwnerAddress +
                              ", " +
                              this.state.secondOwnerCity +
                              ", " +
                              this.state.secondOwnerState +
                              ", " +
                              this.state.secondOwnerZip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                    {this.state.ownerCount > 2 && (
                      <div className="col-xl-4  ">
                        <br /> <br />
                        <p className="pt-0 stepTitle textPri2"></p>
                        <p className="pt-4 stepTitle">First owner</p>
                        <br />
                        <div className="pt-4">
                          <this.alignData
                            label="Name"
                            value={this.state.thirdOwnerName}
                          />
                          <this.alignData
                            label="Email"
                            value={this.state.thirdOwnerEmail}
                          />
                          <this.alignData
                            label="Phone"
                            value={this.state.thirdOwnerContactNumber}
                          />
                          <this.alignData
                            label="Alternative Phone"
                            value={this.state.thirdOwnerContactNumber2}
                          />
                          <this.alignData
                            label="Date of Birth"
                            value={this.state.thirdOwnerDOB}
                          />
                          <this.alignData
                            label="Driving License No"
                            value={this.state.thirdOwnerDLN}
                          />
                          <this.alignData
                            label="SSN"
                            value={this.state.thirdOwnerSSN}
                          />
                          <this.alignData
                            label="Address"
                            value={
                              this.state.thirdOwnerAddress +
                              ", " +
                              this.state.thirdOwnerCity +
                              ", " +
                              this.state.thirdOwnerState +
                              ", " +
                              this.state.thirdOwnerZip +
                              ", "
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <br />
              </div>
              <div
                className="modal-footer pt-3 shadow1"
                style={{ borderTop: "1px solid #d15428" }}
              >
                <button className="pri" data-dismiss="modal">
                  cancel
                </button>
                <button
                  className="buttonPri"
                  data-dismiss="modal"
                  onClick={() => this.next()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(OfficeApplication);
