import React, { Component } from "react";
import "../application.css";
import "../dropdownForm.css";
import Loader from "../../../Loader/Loader";
import NoData from "../../../../Messages/NoData";
import { getApplications } from "../../../../Services/ApiServices";
import { connect } from "react-redux";
import { appendAlert, setApplication } from "../../../../actions";
import { withRouter } from "react-router-dom";
import ApplicationTenantList from "./ApplicationTenantList";

class ApplicationTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    this.getApp();
  }

  async getApp() {
    try {
      await getApplications().then((response) => {
        window.scrollTo(0, 0);
        if (response.status === 200) {
          if (response.data.length !== 0) {
            this.props.setApplication(null);
            this.props.setApplication(response.data.reverse());
          } else {
            this.props.setApplication([]);
          }
        } else {
          this.props.setApplication([]);
        }
      });
    } catch (error) {
      this.props.setApplication([]);
    }
  }

  handleActiveKeyChange = (activeKey) => {
    if (this.state.activeKey === activeKey) {
      activeKey = -1;
    }
    this.setState({ activeKey });
  };

  render() {
    return this.props.applicationArray === null ? (
      <Loader />
    ) : this.props.applicationArray.length === 0 ? (
      <div style={{ paddingTop: "70px", minHeight: window.screen.height }}>
        <NoData
          message="pending applications to process"
          sub="start applying for more properties"
        ></NoData>
      </div>
    ) : (
      <div
        className="applicationCardTop"
        style={{ paddingTop: "55px", paddingBottom: "83px" }}
      >
        {this.props.applicationArray.map((data, index) => {
          return (
            <ApplicationTenantList
              data={data}
              eventKey={index}
              handleActiveKeyChange={this.handleActiveKeyChange}
              key={"ApplicationTenant"+index}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    application: state.applicationReducer,
    applicationArray: state.application,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setApplication,
  })(ApplicationTenant)
);
