import React, { Component } from "react";
import "./fullNotification.css";
import { withRouter } from "react-router";

class FullNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
  }

  render() {
    return (
      <div className="fullNotificationWindow">
        <i
          className="fa fa-info-circle fullNotificationIcon "
          aria-hidden="true"
        ></i>
        <p className="fullNotificationText">{this.props.children}</p>
        <span className="fullNotificationClose"> X </span>
      </div>
    );
  }
}

export default withRouter(FullNotification);
