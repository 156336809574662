export async function CountryJson() {
    const json=[
        {
            "id": 233,
            "name": "United States",
            "iso3": "USA",
            "iso2": "US",
            "phone_code": "1",
            "capital": "Washington",
            "currency": "USD",
            "states": [
                {
                    "id": 1456,
                    "name": "Alabama",
                    "state_code": "AL",
                    "cities": [
                        {
                            "id": 110968,
                            "name": "Abbeville",
                            "latitude": "31.57184000",
                            "longitude": "-85.25049000"
                        },
                        {
                            "id": 111032,
                            "name": "Adamsville",
                            "latitude": "33.60094000",
                            "longitude": "-86.95611000"
                        },
                        {
                            "id": 111083,
                            "name": "Alabaster",
                            "latitude": "33.24428000",
                            "longitude": "-86.81638000"
                        },
                        {
                            "id": 111120,
                            "name": "Albertville",
                            "latitude": "34.26783000",
                            "longitude": "-86.20878000"
                        },
                        {
                            "id": 111146,
                            "name": "Alexander City",
                            "latitude": "32.94401000",
                            "longitude": "-85.95385000"
                        },
                        {
                            "id": 111152,
                            "name": "Alexandria",
                            "latitude": "33.77399000",
                            "longitude": "-85.88552000"
                        },
                        {
                            "id": 111172,
                            "name": "Aliceville",
                            "latitude": "33.12957000",
                            "longitude": "-88.15142000"
                        },
                        {
                            "id": 111309,
                            "name": "Andalusia",
                            "latitude": "31.30808000",
                            "longitude": "-86.48243000"
                        },
                        {
                            "id": 111355,
                            "name": "Anniston",
                            "latitude": "33.65983000",
                            "longitude": "-85.83163000"
                        },
                        {
                            "id": 111407,
                            "name": "Arab",
                            "latitude": "34.31815000",
                            "longitude": "-86.49582000"
                        },
                        {
                            "id": 111448,
                            "name": "Argo",
                            "latitude": "33.68778000",
                            "longitude": "-86.50051000"
                        },
                        {
                            "id": 111524,
                            "name": "Ashford",
                            "latitude": "31.18296000",
                            "longitude": "-85.23632000"
                        },
                        {
                            "id": 111533,
                            "name": "Ashland",
                            "latitude": "33.27373000",
                            "longitude": "-85.83607000"
                        },
                        {
                            "id": 111548,
                            "name": "Ashville",
                            "latitude": "33.83704000",
                            "longitude": "-86.25442000"
                        },
                        {
                            "id": 111577,
                            "name": "Athens",
                            "latitude": "34.80243000",
                            "longitude": "-86.97219000"
                        },
                        {
                            "id": 111602,
                            "name": "Atmore",
                            "latitude": "31.02379000",
                            "longitude": "-87.49387000"
                        },
                        {
                            "id": 111608,
                            "name": "Attalla",
                            "latitude": "34.02176000",
                            "longitude": "-86.08859000"
                        },
                        {
                            "id": 111623,
                            "name": "Auburn",
                            "latitude": "32.60986000",
                            "longitude": "-85.48078000"
                        },
                        {
                            "id": 111671,
                            "name": "Autauga County",
                            "latitude": "32.53492000",
                            "longitude": "-86.64276000"
                        },
                        {
                            "id": 111761,
                            "name": "Baldwin County",
                            "latitude": "30.66097000",
                            "longitude": "-87.74984000"
                        },
                        {
                            "id": 111774,
                            "name": "Ballplay",
                            "latitude": "34.05871000",
                            "longitude": "-85.80802000"
                        },
                        {
                            "id": 111815,
                            "name": "Barbour County",
                            "latitude": "31.86960000",
                            "longitude": "-85.39320000"
                        },
                        {
                            "id": 111929,
                            "name": "Bay Minette",
                            "latitude": "30.88296000",
                            "longitude": "-87.77305000"
                        },
                        {
                            "id": 111948,
                            "name": "Bayou La Batre",
                            "latitude": "30.40352000",
                            "longitude": "-88.24852000"
                        },
                        {
                            "id": 111975,
                            "name": "Bear Creek",
                            "latitude": "34.27482000",
                            "longitude": "-87.70058000"
                        },
                        {
                            "id": 112252,
                            "name": "Berry",
                            "latitude": "33.65983000",
                            "longitude": "-87.60001000"
                        },
                        {
                            "id": 112267,
                            "name": "Bessemer",
                            "latitude": "33.40178000",
                            "longitude": "-86.95444000"
                        },
                        {
                            "id": 112309,
                            "name": "Bibb County",
                            "latitude": "32.99864000",
                            "longitude": "-87.12644000"
                        },
                        {
                            "id": 112358,
                            "name": "Birmingham",
                            "latitude": "33.52066000",
                            "longitude": "-86.80249000"
                        },
                        {
                            "id": 112457,
                            "name": "Blount County",
                            "latitude": "33.98087000",
                            "longitude": "-86.56737000"
                        },
                        {
                            "id": 112460,
                            "name": "Blountsville",
                            "latitude": "34.08149000",
                            "longitude": "-86.59110000"
                        },
                        {
                            "id": 112474,
                            "name": "Blue Ridge",
                            "latitude": "32.49264000",
                            "longitude": "-86.19052000"
                        },
                        {
                            "id": 112490,
                            "name": "Boaz",
                            "latitude": "34.20065000",
                            "longitude": "-86.16637000"
                        },
                        {
                            "id": 112725,
                            "name": "Brent",
                            "latitude": "32.93735000",
                            "longitude": "-87.16472000"
                        },
                        {
                            "id": 112747,
                            "name": "Brewton",
                            "latitude": "31.10518000",
                            "longitude": "-87.07219000"
                        },
                        {
                            "id": 112758,
                            "name": "Bridgeport",
                            "latitude": "34.94758000",
                            "longitude": "-85.71442000"
                        },
                        {
                            "id": 112786,
                            "name": "Brighton",
                            "latitude": "33.43428000",
                            "longitude": "-86.94721000"
                        },
                        {
                            "id": 112848,
                            "name": "Brook Highland",
                            "latitude": "33.43566000",
                            "longitude": "-86.67388000"
                        },
                        {
                            "id": 112886,
                            "name": "Brookside",
                            "latitude": "33.63788000",
                            "longitude": "-86.91666000"
                        },
                        {
                            "id": 112897,
                            "name": "Brookwood",
                            "latitude": "33.25567000",
                            "longitude": "-87.32083000"
                        },
                        {
                            "id": 112946,
                            "name": "Brundidge",
                            "latitude": "31.72016000",
                            "longitude": "-85.81606000"
                        },
                        {
                            "id": 113034,
                            "name": "Bullock County",
                            "latitude": "32.10055000",
                            "longitude": "-85.71570000"
                        },
                        {
                            "id": 113106,
                            "name": "Butler",
                            "latitude": "32.08959000",
                            "longitude": "-88.22197000"
                        },
                        {
                            "id": 113114,
                            "name": "Butler County",
                            "latitude": "31.75243000",
                            "longitude": "-86.68029000"
                        },
                        {
                            "id": 113138,
                            "name": "Bynum",
                            "latitude": "33.61316000",
                            "longitude": "-85.96108000"
                        },
                        {
                            "id": 113167,
                            "name": "Cahaba Heights",
                            "latitude": "33.46400000",
                            "longitude": "-86.73193000"
                        },
                        {
                            "id": 113194,
                            "name": "Calera",
                            "latitude": "33.10290000",
                            "longitude": "-86.75360000"
                        },
                        {
                            "id": 113200,
                            "name": "Calhoun County",
                            "latitude": "33.77143000",
                            "longitude": "-85.82603000"
                        },
                        {
                            "id": 113259,
                            "name": "Camden",
                            "latitude": "31.99098000",
                            "longitude": "-87.29055000"
                        },
                        {
                            "id": 113386,
                            "name": "Carbon Hill",
                            "latitude": "33.89177000",
                            "longitude": "-87.52612000"
                        },
                        {
                            "id": 113407,
                            "name": "Carlisle-Rockledge",
                            "latitude": "34.11445000",
                            "longitude": "-86.12407000"
                        },
                        {
                            "id": 113463,
                            "name": "Carrollton",
                            "latitude": "33.26169000",
                            "longitude": "-88.09503000"
                        },
                        {
                            "id": 113601,
                            "name": "Cedar Bluff",
                            "latitude": "34.22009000",
                            "longitude": "-85.60774000"
                        },
                        {
                            "id": 113646,
                            "name": "Center Point",
                            "latitude": "33.64566000",
                            "longitude": "-86.68360000"
                        },
                        {
                            "id": 113688,
                            "name": "Centre",
                            "latitude": "34.15204000",
                            "longitude": "-85.67885000"
                        },
                        {
                            "id": 113691,
                            "name": "Centreville",
                            "latitude": "32.94620000",
                            "longitude": "-87.11669000"
                        },
                        {
                            "id": 113713,
                            "name": "Chalkville",
                            "latitude": "33.65316000",
                            "longitude": "-86.64777000"
                        },
                        {
                            "id": 113720,
                            "name": "Chambers County",
                            "latitude": "32.91437000",
                            "longitude": "-85.39204000"
                        },
                        {
                            "id": 113804,
                            "name": "Chatom",
                            "latitude": "31.46517000",
                            "longitude": "-88.25446000"
                        },
                        {
                            "id": 113829,
                            "name": "Chelsea",
                            "latitude": "33.34011000",
                            "longitude": "-86.63026000"
                        },
                        {
                            "id": 113845,
                            "name": "Cherokee",
                            "latitude": "34.75703000",
                            "longitude": "-87.97281000"
                        },
                        {
                            "id": 113849,
                            "name": "Cherokee County",
                            "latitude": "34.17596000",
                            "longitude": "-85.60379000"
                        },
                        {
                            "id": 113938,
                            "name": "Chickasaw",
                            "latitude": "30.76380000",
                            "longitude": "-88.07472000"
                        },
                        {
                            "id": 113949,
                            "name": "Childersburg",
                            "latitude": "33.27817000",
                            "longitude": "-86.35498000"
                        },
                        {
                            "id": 113957,
                            "name": "Chilton County",
                            "latitude": "32.84785000",
                            "longitude": "-86.71881000"
                        },
                        {
                            "id": 113984,
                            "name": "Choccolocco",
                            "latitude": "33.65927000",
                            "longitude": "-85.70357000"
                        },
                        {
                            "id": 113986,
                            "name": "Choctaw County",
                            "latitude": "32.01961000",
                            "longitude": "-88.26320000"
                        },
                        {
                            "id": 114033,
                            "name": "Citronelle",
                            "latitude": "31.09073000",
                            "longitude": "-88.22806000"
                        },
                        {
                            "id": 114099,
                            "name": "Clanton",
                            "latitude": "32.83874000",
                            "longitude": "-86.62943000"
                        },
                        {
                            "id": 114134,
                            "name": "Clarke County",
                            "latitude": "31.67666000",
                            "longitude": "-87.83081000"
                        },
                        {
                            "id": 114165,
                            "name": "Clay",
                            "latitude": "33.70260000",
                            "longitude": "-86.59971000"
                        },
                        {
                            "id": 114173,
                            "name": "Clay County",
                            "latitude": "33.26909000",
                            "longitude": "-85.86055000"
                        },
                        {
                            "id": 114196,
                            "name": "Clayton",
                            "latitude": "31.87822000",
                            "longitude": "-85.44966000"
                        },
                        {
                            "id": 114230,
                            "name": "Cleburne County",
                            "latitude": "33.67456000",
                            "longitude": "-85.51877000"
                        },
                        {
                            "id": 114240,
                            "name": "Cleveland",
                            "latitude": "33.99093000",
                            "longitude": "-86.57749000"
                        },
                        {
                            "id": 114301,
                            "name": "Clio",
                            "latitude": "31.70878000",
                            "longitude": "-85.61050000"
                        },
                        {
                            "id": 114337,
                            "name": "Coaling",
                            "latitude": "33.15901000",
                            "longitude": "-87.34083000"
                        },
                        {
                            "id": 114367,
                            "name": "Coffee County",
                            "latitude": "31.40263000",
                            "longitude": "-85.98821000"
                        },
                        {
                            "id": 114379,
                            "name": "Colbert County",
                            "latitude": "34.70043000",
                            "longitude": "-87.80498000"
                        },
                        {
                            "id": 114433,
                            "name": "Collinsville",
                            "latitude": "34.26398000",
                            "longitude": "-85.86053000"
                        },
                        {
                            "id": 114487,
                            "name": "Columbiana",
                            "latitude": "33.17817000",
                            "longitude": "-86.60721000"
                        },
                        {
                            "id": 114534,
                            "name": "Concord",
                            "latitude": "33.46761000",
                            "longitude": "-87.03111000"
                        },
                        {
                            "id": 114550,
                            "name": "Conecuh County",
                            "latitude": "31.42927000",
                            "longitude": "-86.99368000"
                        },
                        {
                            "id": 114613,
                            "name": "Coosa County",
                            "latitude": "32.93623000",
                            "longitude": "-86.24766000"
                        },
                        {
                            "id": 114614,
                            "name": "Coosada",
                            "latitude": "32.49791000",
                            "longitude": "-86.33136000"
                        },
                        {
                            "id": 114638,
                            "name": "Cordova",
                            "latitude": "33.75983000",
                            "longitude": "-87.18333000"
                        },
                        {
                            "id": 114699,
                            "name": "Cottonwood",
                            "latitude": "31.04879000",
                            "longitude": "-85.30493000"
                        },
                        {
                            "id": 114742,
                            "name": "Covington County",
                            "latitude": "31.24849000",
                            "longitude": "-86.45125000"
                        },
                        {
                            "id": 114745,
                            "name": "Cowarts",
                            "latitude": "31.20018000",
                            "longitude": "-85.30465000"
                        },
                        {
                            "id": 114791,
                            "name": "Crenshaw County",
                            "latitude": "31.73149000",
                            "longitude": "-86.31355000"
                        },
                        {
                            "id": 114792,
                            "name": "Creola",
                            "latitude": "30.89185000",
                            "longitude": "-88.03972000"
                        },
                        {
                            "id": 114855,
                            "name": "Crossville",
                            "latitude": "34.28759000",
                            "longitude": "-85.99414000"
                        },
                        {
                            "id": 114895,
                            "name": "Cullman",
                            "latitude": "34.17482000",
                            "longitude": "-86.84361000"
                        },
                        {
                            "id": 114896,
                            "name": "Cullman County",
                            "latitude": "34.13194000",
                            "longitude": "-86.86762000"
                        },
                        {
                            "id": 114968,
                            "name": "Dadeville",
                            "latitude": "32.83124000",
                            "longitude": "-85.76357000"
                        },
                        {
                            "id": 114982,
                            "name": "Dale County",
                            "latitude": "31.43182000",
                            "longitude": "-85.61103000"
                        },
                        {
                            "id": 114983,
                            "name": "Daleville",
                            "latitude": "31.31017000",
                            "longitude": "-85.71299000"
                        },
                        {
                            "id": 114994,
                            "name": "Dallas County",
                            "latitude": "32.32597000",
                            "longitude": "-87.10648000"
                        },
                        {
                            "id": 115032,
                            "name": "Danville",
                            "latitude": "34.41454000",
                            "longitude": "-87.08751000"
                        },
                        {
                            "id": 115040,
                            "name": "Daphne",
                            "latitude": "30.60353000",
                            "longitude": "-87.90360000"
                        },
                        {
                            "id": 115057,
                            "name": "Dauphin Island",
                            "latitude": "30.25548000",
                            "longitude": "-88.10972000"
                        },
                        {
                            "id": 115148,
                            "name": "Deatsville",
                            "latitude": "32.60819000",
                            "longitude": "-86.39581000"
                        },
                        {
                            "id": 115149,
                            "name": "Decatur",
                            "latitude": "34.60593000",
                            "longitude": "-86.98334000"
                        },
                        {
                            "id": 115128,
                            "name": "DeKalb County",
                            "latitude": "34.45977000",
                            "longitude": "-85.80414000"
                        },
                        {
                            "id": 115232,
                            "name": "Demopolis",
                            "latitude": "32.51764000",
                            "longitude": "-87.83640000"
                        },
                        {
                            "id": 115360,
                            "name": "Dixiana",
                            "latitude": "33.74021000",
                            "longitude": "-86.64938000"
                        },
                        {
                            "id": 115407,
                            "name": "Dora",
                            "latitude": "33.72872000",
                            "longitude": "-87.09028000"
                        },
                        {
                            "id": 115415,
                            "name": "Dothan",
                            "latitude": "31.22323000",
                            "longitude": "-85.39049000"
                        },
                        {
                            "id": 115417,
                            "name": "Double Springs",
                            "latitude": "34.14637000",
                            "longitude": "-87.40247000"
                        },
                        {
                            "id": 115636,
                            "name": "East Brewton",
                            "latitude": "31.09323000",
                            "longitude": "-87.06275000"
                        },
                        {
                            "id": 115662,
                            "name": "East Florence",
                            "latitude": "34.80953000",
                            "longitude": "-87.64947000"
                        },
                        {
                            "id": 115816,
                            "name": "Eclectic",
                            "latitude": "32.63541000",
                            "longitude": "-86.03441000"
                        },
                        {
                            "id": 115941,
                            "name": "Elba",
                            "latitude": "31.41461000",
                            "longitude": "-86.06772000"
                        },
                        {
                            "id": 115944,
                            "name": "Elberta",
                            "latitude": "30.41436000",
                            "longitude": "-87.59776000"
                        },
                        {
                            "id": 116056,
                            "name": "Elmore",
                            "latitude": "32.53874000",
                            "longitude": "-86.31497000"
                        },
                        {
                            "id": 116057,
                            "name": "Elmore County",
                            "latitude": "32.59665000",
                            "longitude": "-86.14915000"
                        },
                        {
                            "id": 116092,
                            "name": "Emerald Mountain",
                            "latitude": "32.44793000",
                            "longitude": "-86.09429000"
                        },
                        {
                            "id": 116148,
                            "name": "Enterprise",
                            "latitude": "31.31517000",
                            "longitude": "-85.85522000"
                        },
                        {
                            "id": 116179,
                            "name": "Escambia County",
                            "latitude": "31.12612000",
                            "longitude": "-87.16162000"
                        },
                        {
                            "id": 116219,
                            "name": "Etowah County",
                            "latitude": "34.04525000",
                            "longitude": "-86.03476000"
                        },
                        {
                            "id": 116224,
                            "name": "Eufaula",
                            "latitude": "31.89127000",
                            "longitude": "-85.14549000"
                        },
                        {
                            "id": 116242,
                            "name": "Eutaw",
                            "latitude": "32.84059000",
                            "longitude": "-87.88762000"
                        },
                        {
                            "id": 116261,
                            "name": "Evergreen",
                            "latitude": "31.43350000",
                            "longitude": "-86.95692000"
                        },
                        {
                            "id": 116309,
                            "name": "Fairfield",
                            "latitude": "33.48594000",
                            "longitude": "-86.91194000"
                        },
                        {
                            "id": 116326,
                            "name": "Fairhope",
                            "latitude": "30.52297000",
                            "longitude": "-87.90333000"
                        },
                        {
                            "id": 116369,
                            "name": "Falkville",
                            "latitude": "34.36843000",
                            "longitude": "-86.90862000"
                        },
                        {
                            "id": 116438,
                            "name": "Fayette",
                            "latitude": "33.68455000",
                            "longitude": "-87.83085000"
                        },
                        {
                            "id": 116443,
                            "name": "Fayette County",
                            "latitude": "33.72121000",
                            "longitude": "-87.73886000"
                        },
                        {
                            "id": 116453,
                            "name": "Fayetteville",
                            "latitude": "33.14567000",
                            "longitude": "-86.40581000"
                        },
                        {
                            "id": 116567,
                            "name": "Flint City",
                            "latitude": "34.52315000",
                            "longitude": "-86.97029000"
                        },
                        {
                            "id": 116569,
                            "name": "Flomaton",
                            "latitude": "31.00018000",
                            "longitude": "-87.26081000"
                        },
                        {
                            "id": 116576,
                            "name": "Florala",
                            "latitude": "31.00518000",
                            "longitude": "-86.32800000"
                        },
                        {
                            "id": 116577,
                            "name": "Florence",
                            "latitude": "34.79981000",
                            "longitude": "-87.67725000"
                        },
                        {
                            "id": 116619,
                            "name": "Foley",
                            "latitude": "30.40659000",
                            "longitude": "-87.68360000"
                        },
                        {
                            "id": 116669,
                            "name": "Forestdale",
                            "latitude": "33.57011000",
                            "longitude": "-86.89638000"
                        },
                        {
                            "id": 116705,
                            "name": "Fort Deposit",
                            "latitude": "31.98459000",
                            "longitude": "-86.57859000"
                        },
                        {
                            "id": 116738,
                            "name": "Fort Payne",
                            "latitude": "34.44425000",
                            "longitude": "-85.71969000"
                        },
                        {
                            "id": 116747,
                            "name": "Fort Rucker",
                            "latitude": "31.34282000",
                            "longitude": "-85.71538000"
                        },
                        {
                            "id": 116843,
                            "name": "Franklin County",
                            "latitude": "34.44167000",
                            "longitude": "-87.84381000"
                        },
                        {
                            "id": 116957,
                            "name": "Frisco City",
                            "latitude": "31.43350000",
                            "longitude": "-87.40138000"
                        },
                        {
                            "id": 116999,
                            "name": "Fultondale",
                            "latitude": "33.60483000",
                            "longitude": "-86.79388000"
                        },
                        {
                            "id": 117003,
                            "name": "Fyffe",
                            "latitude": "34.44676000",
                            "longitude": "-85.90414000"
                        },
                        {
                            "id": 117004,
                            "name": "Gadsden",
                            "latitude": "34.01434000",
                            "longitude": "-86.00639000"
                        },
                        {
                            "id": 117070,
                            "name": "Gardendale",
                            "latitude": "33.66010000",
                            "longitude": "-86.81277000"
                        },
                        {
                            "id": 117139,
                            "name": "Geneva",
                            "latitude": "31.03296000",
                            "longitude": "-85.86382000"
                        },
                        {
                            "id": 117146,
                            "name": "Geneva County",
                            "latitude": "31.09502000",
                            "longitude": "-85.83898000"
                        },
                        {
                            "id": 117167,
                            "name": "Georgiana",
                            "latitude": "31.63710000",
                            "longitude": "-86.74192000"
                        },
                        {
                            "id": 117276,
                            "name": "Glencoe",
                            "latitude": "33.95704000",
                            "longitude": "-85.93247000"
                        },
                        {
                            "id": 117377,
                            "name": "Good Hope",
                            "latitude": "34.11593000",
                            "longitude": "-86.86361000"
                        },
                        {
                            "id": 117391,
                            "name": "Goodwater",
                            "latitude": "33.06567000",
                            "longitude": "-86.05330000"
                        },
                        {
                            "id": 117395,
                            "name": "Gordo",
                            "latitude": "33.32012000",
                            "longitude": "-87.90280000"
                        },
                        {
                            "id": 117449,
                            "name": "Grand Bay",
                            "latitude": "30.47631000",
                            "longitude": "-88.34223000"
                        },
                        {
                            "id": 117565,
                            "name": "Grayson Valley",
                            "latitude": "33.64816000",
                            "longitude": "-86.63943000"
                        },
                        {
                            "id": 117566,
                            "name": "Graysville",
                            "latitude": "33.62066000",
                            "longitude": "-86.97138000"
                        },
                        {
                            "id": 117631,
                            "name": "Greene County",
                            "latitude": "32.85314000",
                            "longitude": "-87.95223000"
                        },
                        {
                            "id": 117664,
                            "name": "Greensboro",
                            "latitude": "32.70415000",
                            "longitude": "-87.59550000"
                        },
                        {
                            "id": 117680,
                            "name": "Greenville",
                            "latitude": "31.82960000",
                            "longitude": "-86.61775000"
                        },
                        {
                            "id": 117757,
                            "name": "Grove Hill",
                            "latitude": "31.70877000",
                            "longitude": "-87.77722000"
                        },
                        {
                            "id": 117789,
                            "name": "Guin",
                            "latitude": "33.96566000",
                            "longitude": "-87.91475000"
                        },
                        {
                            "id": 117795,
                            "name": "Gulf Shores",
                            "latitude": "30.24604000",
                            "longitude": "-87.70082000"
                        },
                        {
                            "id": 117806,
                            "name": "Guntersville",
                            "latitude": "34.35823000",
                            "longitude": "-86.29446000"
                        },
                        {
                            "id": 117830,
                            "name": "Hackleburg",
                            "latitude": "34.27732000",
                            "longitude": "-87.82864000"
                        },
                        {
                            "id": 117848,
                            "name": "Hale County",
                            "latitude": "32.76266000",
                            "longitude": "-87.62912000"
                        },
                        {
                            "id": 117853,
                            "name": "Haleyville",
                            "latitude": "34.22649000",
                            "longitude": "-87.62141000"
                        },
                        {
                            "id": 117887,
                            "name": "Hamilton",
                            "latitude": "34.14232000",
                            "longitude": "-87.98864000"
                        },
                        {
                            "id": 117946,
                            "name": "Hanceville",
                            "latitude": "34.06065000",
                            "longitude": "-86.76750000"
                        },
                        {
                            "id": 118036,
                            "name": "Harpersville",
                            "latitude": "33.34400000",
                            "longitude": "-86.43804000"
                        },
                        {
                            "id": 118084,
                            "name": "Hartford",
                            "latitude": "31.10240000",
                            "longitude": "-85.69688000"
                        },
                        {
                            "id": 118102,
                            "name": "Hartselle",
                            "latitude": "34.44343000",
                            "longitude": "-86.93528000"
                        },
                        {
                            "id": 118110,
                            "name": "Harvest",
                            "latitude": "34.85564000",
                            "longitude": "-86.75083000"
                        },
                        {
                            "id": 118177,
                            "name": "Hayden",
                            "latitude": "33.89260000",
                            "longitude": "-86.75777000"
                        },
                        {
                            "id": 118189,
                            "name": "Hayneville",
                            "latitude": "32.18403000",
                            "longitude": "-86.58025000"
                        },
                        {
                            "id": 118204,
                            "name": "Hazel Green",
                            "latitude": "34.93231000",
                            "longitude": "-86.57194000"
                        },
                        {
                            "id": 118216,
                            "name": "Headland",
                            "latitude": "31.35128000",
                            "longitude": "-85.34216000"
                        },
                        {
                            "id": 118243,
                            "name": "Heflin",
                            "latitude": "33.64899000",
                            "longitude": "-85.58746000"
                        },
                        {
                            "id": 118246,
                            "name": "Helena",
                            "latitude": "33.29622000",
                            "longitude": "-86.84360000"
                        },
                        {
                            "id": 118272,
                            "name": "Henagar",
                            "latitude": "34.63508000",
                            "longitude": "-85.76719000"
                        },
                        {
                            "id": 118297,
                            "name": "Henry County",
                            "latitude": "31.51469000",
                            "longitude": "-85.24141000"
                        },
                        {
                            "id": 118408,
                            "name": "Highland Lakes",
                            "latitude": "33.39838000",
                            "longitude": "-86.65130000"
                        },
                        {
                            "id": 118516,
                            "name": "Hokes Bluff",
                            "latitude": "33.99815000",
                            "longitude": "-85.86636000"
                        },
                        {
                            "id": 118574,
                            "name": "Holt",
                            "latitude": "33.23401000",
                            "longitude": "-87.48445000"
                        },
                        {
                            "id": 118581,
                            "name": "Holtville",
                            "latitude": "32.63624000",
                            "longitude": "-86.32664000"
                        },
                        {
                            "id": 118607,
                            "name": "Homewood",
                            "latitude": "33.47177000",
                            "longitude": "-86.80082000"
                        },
                        {
                            "id": 118638,
                            "name": "Hoover",
                            "latitude": "33.40539000",
                            "longitude": "-86.81138000"
                        },
                        {
                            "id": 118702,
                            "name": "Houston County",
                            "latitude": "31.15318000",
                            "longitude": "-85.30252000"
                        },
                        {
                            "id": 118756,
                            "name": "Hueytown",
                            "latitude": "33.45122000",
                            "longitude": "-86.99666000"
                        },
                        {
                            "id": 118770,
                            "name": "Huguley",
                            "latitude": "32.83457000",
                            "longitude": "-85.22966000"
                        },
                        {
                            "id": 118814,
                            "name": "Huntsville",
                            "latitude": "34.73040000",
                            "longitude": "-86.58594000"
                        },
                        {
                            "id": 118919,
                            "name": "Indian Springs Village",
                            "latitude": "33.35539000",
                            "longitude": "-86.75443000"
                        },
                        {
                            "id": 118954,
                            "name": "Inverness",
                            "latitude": "32.01488000",
                            "longitude": "-85.74606000"
                        },
                        {
                            "id": 118994,
                            "name": "Irondale",
                            "latitude": "33.53816000",
                            "longitude": "-86.70721000"
                        },
                        {
                            "id": 119060,
                            "name": "Jackson",
                            "latitude": "31.50905000",
                            "longitude": "-87.89444000"
                        },
                        {
                            "id": 119075,
                            "name": "Jackson County",
                            "latitude": "34.77941000",
                            "longitude": "-85.99930000"
                        },
                        {
                            "id": 119099,
                            "name": "Jacksonville",
                            "latitude": "33.81382000",
                            "longitude": "-85.76130000"
                        },
                        {
                            "id": 119138,
                            "name": "Jasper",
                            "latitude": "33.83122000",
                            "longitude": "-87.27751000"
                        },
                        {
                            "id": 119175,
                            "name": "Jefferson County",
                            "latitude": "33.55431000",
                            "longitude": "-86.89649000"
                        },
                        {
                            "id": 119211,
                            "name": "Jemison",
                            "latitude": "32.95985000",
                            "longitude": "-86.74665000"
                        },
                        {
                            "id": 119569,
                            "name": "Kimberly",
                            "latitude": "33.77344000",
                            "longitude": "-86.81388000"
                        },
                        {
                            "id": 119641,
                            "name": "Kinsey",
                            "latitude": "31.29906000",
                            "longitude": "-85.34438000"
                        },
                        {
                            "id": 119826,
                            "name": "Ladonia",
                            "latitude": "32.46820000",
                            "longitude": "-85.07910000"
                        },
                        {
                            "id": 119831,
                            "name": "Lafayette",
                            "latitude": "32.89985000",
                            "longitude": "-85.40106000"
                        },
                        {
                            "id": 119967,
                            "name": "Lake Purdy",
                            "latitude": "33.43011000",
                            "longitude": "-86.68054000"
                        },
                        {
                            "id": 119986,
                            "name": "Lake View",
                            "latitude": "33.28067000",
                            "longitude": "-87.13750000"
                        },
                        {
                            "id": 120061,
                            "name": "Lamar County",
                            "latitude": "33.77921000",
                            "longitude": "-88.09690000"
                        },
                        {
                            "id": 120108,
                            "name": "Lanett",
                            "latitude": "32.86874000",
                            "longitude": "-85.19050000"
                        },
                        {
                            "id": 120179,
                            "name": "Lauderdale County",
                            "latitude": "34.90137000",
                            "longitude": "-87.65400000"
                        },
                        {
                            "id": 120229,
                            "name": "Lawrence County",
                            "latitude": "34.52165000",
                            "longitude": "-87.31104000"
                        },
                        {
                            "id": 120306,
                            "name": "Lee County",
                            "latitude": "32.60114000",
                            "longitude": "-85.35556000"
                        },
                        {
                            "id": 120320,
                            "name": "Leeds",
                            "latitude": "33.54816000",
                            "longitude": "-86.54443000"
                        },
                        {
                            "id": 120323,
                            "name": "Leesburg",
                            "latitude": "34.17982000",
                            "longitude": "-85.76136000"
                        },
                        {
                            "id": 120404,
                            "name": "Level Plains",
                            "latitude": "31.29962000",
                            "longitude": "-85.77799000"
                        },
                        {
                            "id": 120494,
                            "name": "Limestone County",
                            "latitude": "34.81008000",
                            "longitude": "-86.98137000"
                        },
                        {
                            "id": 120499,
                            "name": "Lincoln",
                            "latitude": "33.61316000",
                            "longitude": "-86.11831000"
                        },
                        {
                            "id": 120560,
                            "name": "Linden",
                            "latitude": "32.30625000",
                            "longitude": "-87.79807000"
                        },
                        {
                            "id": 120577,
                            "name": "Lineville",
                            "latitude": "33.31067000",
                            "longitude": "-85.75441000"
                        },
                        {
                            "id": 120595,
                            "name": "Lipscomb",
                            "latitude": "33.42566000",
                            "longitude": "-86.92666000"
                        },
                        {
                            "id": 120647,
                            "name": "Livingston",
                            "latitude": "32.58430000",
                            "longitude": "-88.18725000"
                        },
                        {
                            "id": 120685,
                            "name": "Locust Fork",
                            "latitude": "33.90760000",
                            "longitude": "-86.61527000"
                        },
                        {
                            "id": 120846,
                            "name": "Lowndes County",
                            "latitude": "32.15475000",
                            "longitude": "-86.65011000"
                        },
                        {
                            "id": 120852,
                            "name": "Loxley",
                            "latitude": "30.61825000",
                            "longitude": "-87.75305000"
                        },
                        {
                            "id": 120899,
                            "name": "Luverne",
                            "latitude": "31.71655000",
                            "longitude": "-86.26385000"
                        },
                        {
                            "id": 120967,
                            "name": "Macon County",
                            "latitude": "32.38597000",
                            "longitude": "-85.69267000"
                        },
                        {
                            "id": 120984,
                            "name": "Madison",
                            "latitude": "34.69926000",
                            "longitude": "-86.74833000"
                        },
                        {
                            "id": 121002,
                            "name": "Madison County",
                            "latitude": "34.76309000",
                            "longitude": "-86.55021000"
                        },
                        {
                            "id": 121070,
                            "name": "Malvern",
                            "latitude": "31.13934000",
                            "longitude": "-85.51910000"
                        },
                        {
                            "id": 121192,
                            "name": "Marbury",
                            "latitude": "32.70124000",
                            "longitude": "-86.47109000"
                        },
                        {
                            "id": 121203,
                            "name": "Marengo County",
                            "latitude": "32.24761000",
                            "longitude": "-87.78952000"
                        },
                        {
                            "id": 121205,
                            "name": "Margaret",
                            "latitude": "33.68621000",
                            "longitude": "-86.47498000"
                        },
                        {
                            "id": 121231,
                            "name": "Marion",
                            "latitude": "32.63235000",
                            "longitude": "-87.31917000"
                        },
                        {
                            "id": 121247,
                            "name": "Marion County",
                            "latitude": "34.13655000",
                            "longitude": "-87.88714000"
                        },
                        {
                            "id": 121310,
                            "name": "Marshall County",
                            "latitude": "34.36696000",
                            "longitude": "-86.30664000"
                        },
                        {
                            "id": 121565,
                            "name": "Meadowbrook",
                            "latitude": "33.40205000",
                            "longitude": "-86.69665000"
                        },
                        {
                            "id": 121698,
                            "name": "Meridianville",
                            "latitude": "34.85148000",
                            "longitude": "-86.57222000"
                        },
                        {
                            "id": 121796,
                            "name": "Midfield",
                            "latitude": "33.46150000",
                            "longitude": "-86.90888000"
                        },
                        {
                            "id": 121803,
                            "name": "Midland City",
                            "latitude": "31.31906000",
                            "longitude": "-85.49382000"
                        },
                        {
                            "id": 121829,
                            "name": "Mignon",
                            "latitude": "33.18345000",
                            "longitude": "-86.26109000"
                        },
                        {
                            "id": 121872,
                            "name": "Millbrook",
                            "latitude": "32.47986000",
                            "longitude": "-86.36192000"
                        },
                        {
                            "id": 121963,
                            "name": "Minor",
                            "latitude": "33.53733000",
                            "longitude": "-86.94055000"
                        },
                        {
                            "id": 122010,
                            "name": "Mobile",
                            "latitude": "30.69436000",
                            "longitude": "-88.04305000"
                        },
                        {
                            "id": 122011,
                            "name": "Mobile County",
                            "latitude": "30.68515000",
                            "longitude": "-88.19753000"
                        },
                        {
                            "id": 122067,
                            "name": "Monroe County",
                            "latitude": "31.57084000",
                            "longitude": "-87.36543000"
                        },
                        {
                            "id": 122084,
                            "name": "Monroeville",
                            "latitude": "31.52794000",
                            "longitude": "-87.32471000"
                        },
                        {
                            "id": 122125,
                            "name": "Montevallo",
                            "latitude": "33.10067000",
                            "longitude": "-86.86416000"
                        },
                        {
                            "id": 122130,
                            "name": "Montgomery",
                            "latitude": "32.36681000",
                            "longitude": "-86.29997000"
                        },
                        {
                            "id": 122139,
                            "name": "Montgomery County",
                            "latitude": "32.22026000",
                            "longitude": "-86.20761000"
                        },
                        {
                            "id": 122195,
                            "name": "Moody",
                            "latitude": "33.59094000",
                            "longitude": "-86.49082000"
                        },
                        {
                            "id": 122207,
                            "name": "Moores Mill",
                            "latitude": "34.84398000",
                            "longitude": "-86.51832000"
                        },
                        {
                            "id": 122236,
                            "name": "Morgan County",
                            "latitude": "34.45347000",
                            "longitude": "-86.85293000"
                        },
                        {
                            "id": 122265,
                            "name": "Morris",
                            "latitude": "33.74816000",
                            "longitude": "-86.80860000"
                        },
                        {
                            "id": 122314,
                            "name": "Moulton",
                            "latitude": "34.48121000",
                            "longitude": "-87.29335000"
                        },
                        {
                            "id": 122328,
                            "name": "Moundville",
                            "latitude": "32.99762000",
                            "longitude": "-87.63001000"
                        },
                        {
                            "id": 122367,
                            "name": "Mount Olive",
                            "latitude": "33.67094000",
                            "longitude": "-86.85610000"
                        },
                        {
                            "id": 122393,
                            "name": "Mount Vernon",
                            "latitude": "31.08518000",
                            "longitude": "-88.01333000"
                        },
                        {
                            "id": 122410,
                            "name": "Mountain Brook",
                            "latitude": "33.50094000",
                            "longitude": "-86.75221000"
                        },
                        {
                            "id": 122470,
                            "name": "Munford",
                            "latitude": "33.52983000",
                            "longitude": "-85.95080000"
                        },
                        {
                            "id": 122503,
                            "name": "Muscle Shoals",
                            "latitude": "34.74481000",
                            "longitude": "-87.66753000"
                        },
                        {
                            "id": 122676,
                            "name": "New Brockton",
                            "latitude": "31.38572000",
                            "longitude": "-85.92939000"
                        },
                        {
                            "id": 122723,
                            "name": "New Hope",
                            "latitude": "34.53712000",
                            "longitude": "-86.39426000"
                        },
                        {
                            "id": 122749,
                            "name": "New Market",
                            "latitude": "34.91003000",
                            "longitude": "-86.42779000"
                        },
                        {
                            "id": 122857,
                            "name": "Newton",
                            "latitude": "31.33517000",
                            "longitude": "-85.60521000"
                        },
                        {
                            "id": 122990,
                            "name": "North Bibb",
                            "latitude": "33.20401000",
                            "longitude": "-87.15305000"
                        },
                        {
                            "id": 123160,
                            "name": "Northport",
                            "latitude": "33.22901000",
                            "longitude": "-87.57723000"
                        },
                        {
                            "id": 123357,
                            "name": "Odenville",
                            "latitude": "33.67732000",
                            "longitude": "-86.39665000"
                        },
                        {
                            "id": 123381,
                            "name": "Ohatchee",
                            "latitude": "33.78343000",
                            "longitude": "-86.00247000"
                        },
                        {
                            "id": 123478,
                            "name": "Oneonta",
                            "latitude": "33.94815000",
                            "longitude": "-86.47276000"
                        },
                        {
                            "id": 123496,
                            "name": "Opelika",
                            "latitude": "32.64541000",
                            "longitude": "-85.37828000"
                        },
                        {
                            "id": 123498,
                            "name": "Opp",
                            "latitude": "31.28267000",
                            "longitude": "-86.25551000"
                        },
                        {
                            "id": 123511,
                            "name": "Orange Beach",
                            "latitude": "30.29437000",
                            "longitude": "-87.57359000"
                        },
                        {
                            "id": 123663,
                            "name": "Owens Cross Roads",
                            "latitude": "34.58815000",
                            "longitude": "-86.45888000"
                        },
                        {
                            "id": 123675,
                            "name": "Oxford",
                            "latitude": "33.61427000",
                            "longitude": "-85.83496000"
                        },
                        {
                            "id": 123696,
                            "name": "Ozark",
                            "latitude": "31.45906000",
                            "longitude": "-85.64049000"
                        },
                        {
                            "id": 123988,
                            "name": "Pelham",
                            "latitude": "33.28567000",
                            "longitude": "-86.80999000"
                        },
                        {
                            "id": 123997,
                            "name": "Pell City",
                            "latitude": "33.58621000",
                            "longitude": "-86.28609000"
                        },
                        {
                            "id": 124074,
                            "name": "Perry County",
                            "latitude": "32.63847000",
                            "longitude": "-87.29440000"
                        },
                        {
                            "id": 124123,
                            "name": "Phenix City",
                            "latitude": "32.47098000",
                            "longitude": "-85.00077000"
                        },
                        {
                            "id": 124124,
                            "name": "Phil Campbell",
                            "latitude": "34.35093000",
                            "longitude": "-87.70642000"
                        },
                        {
                            "id": 124156,
                            "name": "Pickens County",
                            "latitude": "33.28088000",
                            "longitude": "-88.08869000"
                        },
                        {
                            "id": 124164,
                            "name": "Piedmont",
                            "latitude": "33.92455000",
                            "longitude": "-85.61135000"
                        },
                        {
                            "id": 124185,
                            "name": "Pike County",
                            "latitude": "31.80272000",
                            "longitude": "-85.94092000"
                        },
                        {
                            "id": 124196,
                            "name": "Pike Road",
                            "latitude": "32.28431000",
                            "longitude": "-86.10302000"
                        },
                        {
                            "id": 124235,
                            "name": "Pine Level",
                            "latitude": "32.58374000",
                            "longitude": "-86.46553000"
                        },
                        {
                            "id": 124272,
                            "name": "Pinson",
                            "latitude": "33.68899000",
                            "longitude": "-86.68332000"
                        },
                        {
                            "id": 124366,
                            "name": "Pleasant Grove",
                            "latitude": "33.49094000",
                            "longitude": "-86.97027000"
                        },
                        {
                            "id": 124427,
                            "name": "Point Clear",
                            "latitude": "30.47408000",
                            "longitude": "-87.91916000"
                        },
                        {
                            "id": 124638,
                            "name": "Prattville",
                            "latitude": "32.46402000",
                            "longitude": "-86.45970000"
                        },
                        {
                            "id": 124666,
                            "name": "Priceville",
                            "latitude": "34.52509000",
                            "longitude": "-86.89473000"
                        },
                        {
                            "id": 124667,
                            "name": "Prichard",
                            "latitude": "30.73880000",
                            "longitude": "-88.07889000"
                        },
                        {
                            "id": 124821,
                            "name": "Ragland",
                            "latitude": "33.74454000",
                            "longitude": "-86.15581000"
                        },
                        {
                            "id": 124824,
                            "name": "Rainbow City",
                            "latitude": "33.95482000",
                            "longitude": "-86.04192000"
                        },
                        {
                            "id": 124829,
                            "name": "Rainsville",
                            "latitude": "34.49425000",
                            "longitude": "-85.84775000"
                        },
                        {
                            "id": 124872,
                            "name": "Randolph County",
                            "latitude": "33.29379000",
                            "longitude": "-85.45907000"
                        },
                        {
                            "id": 124939,
                            "name": "Red Bay",
                            "latitude": "34.43982000",
                            "longitude": "-88.14087000"
                        },
                        {
                            "id": 124981,
                            "name": "Redstone Arsenal",
                            "latitude": "34.68387000",
                            "longitude": "-86.64764000"
                        },
                        {
                            "id": 124999,
                            "name": "Reform",
                            "latitude": "33.37845000",
                            "longitude": "-88.01530000"
                        },
                        {
                            "id": 125003,
                            "name": "Rehobeth",
                            "latitude": "31.12296000",
                            "longitude": "-85.45271000"
                        },
                        {
                            "id": 125201,
                            "name": "Riverside",
                            "latitude": "33.60621000",
                            "longitude": "-86.20442000"
                        },
                        {
                            "id": 125226,
                            "name": "Roanoke",
                            "latitude": "33.15123000",
                            "longitude": "-85.37217000"
                        },
                        {
                            "id": 125244,
                            "name": "Robertsdale",
                            "latitude": "30.55380000",
                            "longitude": "-87.71193000"
                        },
                        {
                            "id": 125275,
                            "name": "Rock Creek",
                            "latitude": "33.47705000",
                            "longitude": "-87.08027000"
                        },
                        {
                            "id": 125299,
                            "name": "Rockford",
                            "latitude": "32.88957000",
                            "longitude": "-86.21969000"
                        },
                        {
                            "id": 125354,
                            "name": "Rogersville",
                            "latitude": "34.82578000",
                            "longitude": "-87.29676000"
                        },
                        {
                            "id": 125536,
                            "name": "Russell County",
                            "latitude": "32.28838000",
                            "longitude": "-85.18496000"
                        },
                        {
                            "id": 125542,
                            "name": "Russellville",
                            "latitude": "34.50787000",
                            "longitude": "-87.72864000"
                        },
                        {
                            "id": 125621,
                            "name": "Saint Clair County",
                            "latitude": "33.71570000",
                            "longitude": "-86.31469000"
                        },
                        {
                            "id": 125721,
                            "name": "Saks",
                            "latitude": "33.69871000",
                            "longitude": "-85.83969000"
                        },
                        {
                            "id": 125781,
                            "name": "Samson",
                            "latitude": "31.11295000",
                            "longitude": "-86.04605000"
                        },
                        {
                            "id": 125934,
                            "name": "Saraland",
                            "latitude": "30.82074000",
                            "longitude": "-88.07056000"
                        },
                        {
                            "id": 125949,
                            "name": "Sardis City",
                            "latitude": "34.17426000",
                            "longitude": "-86.12275000"
                        },
                        {
                            "id": 125957,
                            "name": "Satsuma",
                            "latitude": "30.85324000",
                            "longitude": "-88.05611000"
                        },
                        {
                            "id": 126061,
                            "name": "Scottsboro",
                            "latitude": "34.67231000",
                            "longitude": "-86.03415000"
                        },
                        {
                            "id": 126138,
                            "name": "Selma",
                            "latitude": "32.40736000",
                            "longitude": "-87.02110000"
                        },
                        {
                            "id": 126143,
                            "name": "Selmont-West Selmont",
                            "latitude": "32.37843000",
                            "longitude": "-87.00740000"
                        },
                        {
                            "id": 126151,
                            "name": "Semmes",
                            "latitude": "30.77824000",
                            "longitude": "-88.25917000"
                        },
                        {
                            "id": 126259,
                            "name": "Sheffield",
                            "latitude": "34.76509000",
                            "longitude": "-87.69864000"
                        },
                        {
                            "id": 126267,
                            "name": "Shelby",
                            "latitude": "33.11040000",
                            "longitude": "-86.58415000"
                        },
                        {
                            "id": 126272,
                            "name": "Shelby County",
                            "latitude": "33.26428000",
                            "longitude": "-86.66065000"
                        },
                        {
                            "id": 126363,
                            "name": "Shoal Creek",
                            "latitude": "33.43076000",
                            "longitude": "-86.61092000"
                        },
                        {
                            "id": 126506,
                            "name": "Slocomb",
                            "latitude": "31.10823000",
                            "longitude": "-85.59438000"
                        },
                        {
                            "id": 126523,
                            "name": "Smiths Station",
                            "latitude": "32.54014000",
                            "longitude": "-85.09855000"
                        },
                        {
                            "id": 126532,
                            "name": "Smoke Rise",
                            "latitude": "33.89177000",
                            "longitude": "-86.82027000"
                        },
                        {
                            "id": 126786,
                            "name": "Southside",
                            "latitude": "33.92454000",
                            "longitude": "-86.02247000"
                        },
                        {
                            "id": 126799,
                            "name": "Spanish Fort",
                            "latitude": "30.67491000",
                            "longitude": "-87.91527000"
                        },
                        {
                            "id": 126914,
                            "name": "Springville",
                            "latitude": "33.77505000",
                            "longitude": "-86.47191000"
                        },
                        {
                            "id": 127007,
                            "name": "Steele",
                            "latitude": "33.93982000",
                            "longitude": "-86.20164000"
                        },
                        {
                            "id": 127044,
                            "name": "Stevenson",
                            "latitude": "34.86869000",
                            "longitude": "-85.83942000"
                        },
                        {
                            "id": 127054,
                            "name": "Stewartville",
                            "latitude": "33.07929000",
                            "longitude": "-86.24442000"
                        },
                        {
                            "id": 127180,
                            "name": "Sulligent",
                            "latitude": "33.90177000",
                            "longitude": "-88.13448000"
                        },
                        {
                            "id": 127200,
                            "name": "Sumiton",
                            "latitude": "33.75566000",
                            "longitude": "-87.05000000"
                        },
                        {
                            "id": 127201,
                            "name": "Summerdale",
                            "latitude": "30.48770000",
                            "longitude": "-87.69971000"
                        },
                        {
                            "id": 127228,
                            "name": "Sumter County",
                            "latitude": "32.59101000",
                            "longitude": "-88.19879000"
                        },
                        {
                            "id": 127354,
                            "name": "Sylacauga",
                            "latitude": "33.17317000",
                            "longitude": "-86.25164000"
                        },
                        {
                            "id": 127358,
                            "name": "Sylvan Springs",
                            "latitude": "33.51566000",
                            "longitude": "-87.01499000"
                        },
                        {
                            "id": 127359,
                            "name": "Sylvania",
                            "latitude": "34.56231000",
                            "longitude": "-85.81247000"
                        },
                        {
                            "id": 127389,
                            "name": "Talladega",
                            "latitude": "33.43594000",
                            "longitude": "-86.10580000"
                        },
                        {
                            "id": 127390,
                            "name": "Talladega County",
                            "latitude": "33.38006000",
                            "longitude": "-86.16591000"
                        },
                        {
                            "id": 127394,
                            "name": "Tallapoosa County",
                            "latitude": "32.86240000",
                            "longitude": "-85.79750000"
                        },
                        {
                            "id": 127395,
                            "name": "Tallassee",
                            "latitude": "32.53597000",
                            "longitude": "-85.89329000"
                        },
                        {
                            "id": 127434,
                            "name": "Tarrant",
                            "latitude": "33.58344000",
                            "longitude": "-86.77277000"
                        },
                        {
                            "id": 127445,
                            "name": "Taylor",
                            "latitude": "31.16490000",
                            "longitude": "-85.46827000"
                        },
                        {
                            "id": 127568,
                            "name": "Theodore",
                            "latitude": "30.54769000",
                            "longitude": "-88.17528000"
                        },
                        {
                            "id": 127587,
                            "name": "Thomasville",
                            "latitude": "31.91349000",
                            "longitude": "-87.73584000"
                        },
                        {
                            "id": 127608,
                            "name": "Thorsby",
                            "latitude": "32.91568000",
                            "longitude": "-86.71582000"
                        },
                        {
                            "id": 127651,
                            "name": "Tillmans Corner",
                            "latitude": "30.59019000",
                            "longitude": "-88.17084000"
                        },
                        {
                            "id": 127757,
                            "name": "Town Creek",
                            "latitude": "34.68120000",
                            "longitude": "-87.40613000"
                        },
                        {
                            "id": 127825,
                            "name": "Trinity",
                            "latitude": "34.60676000",
                            "longitude": "-87.08835000"
                        },
                        {
                            "id": 127841,
                            "name": "Troy",
                            "latitude": "31.80877000",
                            "longitude": "-85.96995000"
                        },
                        {
                            "id": 127860,
                            "name": "Trussville",
                            "latitude": "33.61983000",
                            "longitude": "-86.60888000"
                        },
                        {
                            "id": 127909,
                            "name": "Tuscaloosa",
                            "latitude": "33.20984000",
                            "longitude": "-87.56917000"
                        },
                        {
                            "id": 127910,
                            "name": "Tuscaloosa County",
                            "latitude": "33.28955000",
                            "longitude": "-87.52503000"
                        },
                        {
                            "id": 127914,
                            "name": "Tuscumbia",
                            "latitude": "34.73120000",
                            "longitude": "-87.70253000"
                        },
                        {
                            "id": 127916,
                            "name": "Tuskegee",
                            "latitude": "32.42415000",
                            "longitude": "-85.69096000"
                        },
                        {
                            "id": 127968,
                            "name": "Underwood-Petersville",
                            "latitude": "34.87695000",
                            "longitude": "-87.69717000"
                        },
                        {
                            "id": 128011,
                            "name": "Union Springs",
                            "latitude": "32.14432000",
                            "longitude": "-85.71495000"
                        },
                        {
                            "id": 128015,
                            "name": "Uniontown",
                            "latitude": "32.44958000",
                            "longitude": "-87.51417000"
                        },
                        {
                            "id": 128097,
                            "name": "Valley",
                            "latitude": "32.81874000",
                            "longitude": "-85.17939000"
                        },
                        {
                            "id": 128110,
                            "name": "Valley Grande",
                            "latitude": "32.50902000",
                            "longitude": "-86.98749000"
                        },
                        {
                            "id": 128140,
                            "name": "Vance",
                            "latitude": "33.17428000",
                            "longitude": "-87.23361000"
                        },
                        {
                            "id": 128153,
                            "name": "Vandiver",
                            "latitude": "33.47066000",
                            "longitude": "-86.51332000"
                        },
                        {
                            "id": 128188,
                            "name": "Vernon",
                            "latitude": "33.75705000",
                            "longitude": "-88.10892000"
                        },
                        {
                            "id": 128212,
                            "name": "Vestavia Hills",
                            "latitude": "33.44872000",
                            "longitude": "-86.78777000"
                        },
                        {
                            "id": 128264,
                            "name": "Vincent",
                            "latitude": "33.38455000",
                            "longitude": "-86.41192000"
                        },
                        {
                            "id": 128386,
                            "name": "Walker County",
                            "latitude": "33.80333000",
                            "longitude": "-87.29736000"
                        },
                        {
                            "id": 128513,
                            "name": "Warrior",
                            "latitude": "33.81427000",
                            "longitude": "-86.80944000"
                        },
                        {
                            "id": 128552,
                            "name": "Washington County",
                            "latitude": "31.40760000",
                            "longitude": "-88.20788000"
                        },
                        {
                            "id": 128721,
                            "name": "Weaver",
                            "latitude": "33.75205000",
                            "longitude": "-85.81135000"
                        },
                        {
                            "id": 128724,
                            "name": "Webb",
                            "latitude": "31.26045000",
                            "longitude": "-85.27327000"
                        },
                        {
                            "id": 128750,
                            "name": "Wedowee",
                            "latitude": "33.30900000",
                            "longitude": "-85.48467000"
                        },
                        {
                            "id": 128827,
                            "name": "West Blocton",
                            "latitude": "33.11817000",
                            "longitude": "-87.12500000"
                        },
                        {
                            "id": 128864,
                            "name": "West End-Cobb Town",
                            "latitude": "33.65250000",
                            "longitude": "-85.87420000"
                        },
                        {
                            "id": 129067,
                            "name": "Westover",
                            "latitude": "33.34955000",
                            "longitude": "-86.53582000"
                        },
                        {
                            "id": 129092,
                            "name": "Wetumpka",
                            "latitude": "32.54374000",
                            "longitude": "-86.21191000"
                        },
                        {
                            "id": 129180,
                            "name": "Whitesboro",
                            "latitude": "34.16343000",
                            "longitude": "-86.06942000"
                        },
                        {
                            "id": 129228,
                            "name": "Wilcox County",
                            "latitude": "31.98924000",
                            "longitude": "-87.30820000"
                        },
                        {
                            "id": 129340,
                            "name": "Wilsonville",
                            "latitude": "33.23428000",
                            "longitude": "-86.48359000"
                        },
                        {
                            "id": 129392,
                            "name": "Winfield",
                            "latitude": "33.92899000",
                            "longitude": "-87.81725000"
                        },
                        {
                            "id": 129434,
                            "name": "Winston County",
                            "latitude": "34.14923000",
                            "longitude": "-87.37368000"
                        },
                        {
                            "id": 129560,
                            "name": "Woodstock",
                            "latitude": "33.20678000",
                            "longitude": "-87.15000000"
                        },
                        {
                            "id": 129690,
                            "name": "York",
                            "latitude": "32.48625000",
                            "longitude": "-88.29642000"
                        }
                    ]
                },
                {
                    "id": 1400,
                    "name": "Alaska",
                    "state_code": "AK",
                    "cities": [
                        {
                            "id": 111081,
                            "name": "Akutan",
                            "latitude": "54.13350000",
                            "longitude": "-165.77686000"
                        },
                        {
                            "id": 111143,
                            "name": "Aleutians East Borough",
                            "latitude": "54.85000000",
                            "longitude": "-163.41667000"
                        },
                        {
                            "id": 111144,
                            "name": "Aleutians West Census Area",
                            "latitude": "52.16298000",
                            "longitude": "-174.28505000"
                        },
                        {
                            "id": 111303,
                            "name": "Anchor Point",
                            "latitude": "59.77667000",
                            "longitude": "-151.83139000"
                        },
                        {
                            "id": 111305,
                            "name": "Anchorage",
                            "latitude": "61.21806000",
                            "longitude": "-149.90028000"
                        },
                        {
                            "id": 111306,
                            "name": "Anchorage Municipality",
                            "latitude": "61.16667000",
                            "longitude": "-149.25056000"
                        },
                        {
                            "id": 111724,
                            "name": "Badger",
                            "latitude": "64.80000000",
                            "longitude": "-147.53333000"
                        },
                        {
                            "id": 111852,
                            "name": "Barrow",
                            "latitude": "71.29058000",
                            "longitude": "-156.78872000"
                        },
                        {
                            "id": 111976,
                            "name": "Bear Creek",
                            "latitude": "60.16417000",
                            "longitude": "-149.39500000"
                        },
                        {
                            "id": 112281,
                            "name": "Bethel",
                            "latitude": "60.79222000",
                            "longitude": "-161.75583000"
                        },
                        {
                            "id": 112284,
                            "name": "Bethel Census Area",
                            "latitude": "60.75056000",
                            "longitude": "-160.50056000"
                        },
                        {
                            "id": 112325,
                            "name": "Big Lake",
                            "latitude": "61.52139000",
                            "longitude": "-149.95444000"
                        },
                        {
                            "id": 112813,
                            "name": "Bristol Bay Borough",
                            "latitude": "58.75056000",
                            "longitude": "-156.83333000"
                        },
                        {
                            "id": 113124,
                            "name": "Butte",
                            "latitude": "61.54222000",
                            "longitude": "-149.03333000"
                        },
                        {
                            "id": 113917,
                            "name": "Chevak",
                            "latitude": "61.52778000",
                            "longitude": "-165.58639000"
                        },
                        {
                            "id": 114045,
                            "name": "City and Borough of Wrangell",
                            "latitude": "56.41331000",
                            "longitude": "-132.32009000"
                        },
                        {
                            "id": 114374,
                            "name": "Cohoe",
                            "latitude": "60.36861000",
                            "longitude": "-151.30639000"
                        },
                        {
                            "id": 114412,
                            "name": "College",
                            "latitude": "64.85694000",
                            "longitude": "-147.80278000"
                        },
                        {
                            "id": 114640,
                            "name": "Cordova",
                            "latitude": "60.54320000",
                            "longitude": "-145.75867000"
                        },
                        {
                            "id": 114756,
                            "name": "Craig",
                            "latitude": "55.47639000",
                            "longitude": "-133.14833000"
                        },
                        {
                            "id": 115227,
                            "name": "Deltana",
                            "latitude": "63.87217000",
                            "longitude": "-145.21773000"
                        },
                        {
                            "id": 115235,
                            "name": "Denali Borough",
                            "latitude": "63.78889000",
                            "longitude": "-150.19167000"
                        },
                        {
                            "id": 115315,
                            "name": "Diamond Ridge",
                            "latitude": "59.67611000",
                            "longitude": "-151.55750000"
                        },
                        {
                            "id": 115340,
                            "name": "Dillingham",
                            "latitude": "59.03972000",
                            "longitude": "-158.45750000"
                        },
                        {
                            "id": 115341,
                            "name": "Dillingham Census Area",
                            "latitude": "59.76193000",
                            "longitude": "-158.31848000"
                        },
                        {
                            "id": 115578,
                            "name": "Dutch Harbor",
                            "latitude": "53.88980000",
                            "longitude": "-166.54220000"
                        },
                        {
                            "id": 115612,
                            "name": "Eagle River",
                            "latitude": "61.32139000",
                            "longitude": "-149.56778000"
                        },
                        {
                            "id": 115909,
                            "name": "Eielson Air Force Base",
                            "latitude": "64.66327000",
                            "longitude": "-147.05442000"
                        },
                        {
                            "id": 116049,
                            "name": "Elmendorf Air Force Base",
                            "latitude": "61.25703000",
                            "longitude": "-149.63139000"
                        },
                        {
                            "id": 116206,
                            "name": "Ester",
                            "latitude": "64.84722000",
                            "longitude": "-148.01444000"
                        },
                        {
                            "id": 116290,
                            "name": "Fairbanks",
                            "latitude": "64.83778000",
                            "longitude": "-147.71639000"
                        },
                        {
                            "id": 116291,
                            "name": "Fairbanks North Star Borough",
                            "latitude": "64.83333000",
                            "longitude": "-146.41667000"
                        },
                        {
                            "id": 116401,
                            "name": "Farm Loop",
                            "latitude": "61.63891000",
                            "longitude": "-149.14215000"
                        },
                        {
                            "id": 116404,
                            "name": "Farmers Loop",
                            "latitude": "64.90822000",
                            "longitude": "-147.69866000"
                        },
                        {
                            "id": 116531,
                            "name": "Fishhook",
                            "latitude": "61.74402000",
                            "longitude": "-149.23613000"
                        },
                        {
                            "id": 116959,
                            "name": "Fritz Creek",
                            "latitude": "59.73611000",
                            "longitude": "-151.29528000"
                        },
                        {
                            "id": 117124,
                            "name": "Gateway",
                            "latitude": "61.57278000",
                            "longitude": "-149.24083000"
                        },
                        {
                            "id": 117230,
                            "name": "Girdwood",
                            "latitude": "60.94250000",
                            "longitude": "-149.16639000"
                        },
                        {
                            "id": 117843,
                            "name": "Haines",
                            "latitude": "59.23595000",
                            "longitude": "-135.44533000"
                        },
                        {
                            "id": 117844,
                            "name": "Haines Borough",
                            "latitude": "59.25056000",
                            "longitude": "-135.50056000"
                        },
                        {
                            "id": 118219,
                            "name": "Healy",
                            "latitude": "63.85694000",
                            "longitude": "-148.96611000"
                        },
                        {
                            "id": 118597,
                            "name": "Homer",
                            "latitude": "59.64250000",
                            "longitude": "-151.54940000"
                        },
                        {
                            "id": 118632,
                            "name": "Hoonah-Angoon Census Area",
                            "latitude": "57.94807000",
                            "longitude": "-134.94153000"
                        },
                        {
                            "id": 118634,
                            "name": "Hooper Bay",
                            "latitude": "61.53111000",
                            "longitude": "-166.09667000"
                        },
                        {
                            "id": 118701,
                            "name": "Houston",
                            "latitude": "61.63028000",
                            "longitude": "-149.81806000"
                        },
                        {
                            "id": 119338,
                            "name": "Juneau",
                            "latitude": "58.30194000",
                            "longitude": "-134.41972000"
                        },
                        {
                            "id": 119364,
                            "name": "Kalifornsky",
                            "latitude": "60.41833000",
                            "longitude": "-151.29000000"
                        },
                        {
                            "id": 119440,
                            "name": "Kenai",
                            "latitude": "60.55444000",
                            "longitude": "-151.25833000"
                        },
                        {
                            "id": 119441,
                            "name": "Kenai Peninsula Borough",
                            "latitude": "60.41667000",
                            "longitude": "-151.25056000"
                        },
                        {
                            "id": 119523,
                            "name": "Ketchikan",
                            "latitude": "55.34180000",
                            "longitude": "-131.64757000"
                        },
                        {
                            "id": 119524,
                            "name": "Ketchikan Gateway Borough",
                            "latitude": "55.50056000",
                            "longitude": "-131.41667000"
                        },
                        {
                            "id": 119583,
                            "name": "King Cove",
                            "latitude": "55.06087000",
                            "longitude": "-162.31853000"
                        },
                        {
                            "id": 119677,
                            "name": "Knik-Fairview",
                            "latitude": "61.51262000",
                            "longitude": "-149.60012000"
                        },
                        {
                            "id": 119698,
                            "name": "Kodiak",
                            "latitude": "57.78852000",
                            "longitude": "-152.40533000"
                        },
                        {
                            "id": 119699,
                            "name": "Kodiak Island Borough",
                            "latitude": "57.33333000",
                            "longitude": "-153.33333000"
                        },
                        {
                            "id": 119700,
                            "name": "Kodiak Station",
                            "latitude": "57.76587000",
                            "longitude": "-152.60004000"
                        },
                        {
                            "id": 119712,
                            "name": "Kotzebue",
                            "latitude": "66.89846000",
                            "longitude": "-162.59809000"
                        },
                        {
                            "id": 120003,
                            "name": "Lake and Peninsula Borough",
                            "latitude": "57.00056000",
                            "longitude": "-158.00056000"
                        },
                        {
                            "id": 120024,
                            "name": "Lakes",
                            "latitude": "61.60713000",
                            "longitude": "-149.30861000"
                        },
                        {
                            "id": 120256,
                            "name": "Lazy Mountain",
                            "latitude": "61.62611000",
                            "longitude": "-148.94556000"
                        },
                        {
                            "id": 121399,
                            "name": "Matanuska-Susitna Borough",
                            "latitude": "62.50056000",
                            "longitude": "-150.00556000"
                        },
                        {
                            "id": 121561,
                            "name": "Meadow Lakes",
                            "latitude": "61.62472000",
                            "longitude": "-149.60111000"
                        },
                        {
                            "id": 121735,
                            "name": "Metlakatla",
                            "latitude": "55.12848000",
                            "longitude": "-131.57519000"
                        },
                        {
                            "id": 122898,
                            "name": "Nikiski",
                            "latitude": "60.69028000",
                            "longitude": "-151.28889000"
                        },
                        {
                            "id": 122934,
                            "name": "Nome",
                            "latitude": "64.50111000",
                            "longitude": "-165.40639000"
                        },
                        {
                            "id": 122935,
                            "name": "Nome Census Area",
                            "latitude": "64.83333000",
                            "longitude": "-163.75056000"
                        },
                        {
                            "id": 123084,
                            "name": "North Pole",
                            "latitude": "64.75111000",
                            "longitude": "-147.34944000"
                        },
                        {
                            "id": 123107,
                            "name": "North Slope Borough",
                            "latitude": "69.25056000",
                            "longitude": "-152.00056000"
                        },
                        {
                            "id": 123173,
                            "name": "Northwest Arctic Borough",
                            "latitude": "66.83333000",
                            "longitude": "-161.00056000"
                        },
                        {
                            "id": 123760,
                            "name": "Palmer",
                            "latitude": "61.59941000",
                            "longitude": "-149.11456000"
                        },
                        {
                            "id": 124108,
                            "name": "Petersburg",
                            "latitude": "56.81250000",
                            "longitude": "-132.95556000"
                        },
                        {
                            "id": 124110,
                            "name": "Petersburg Borough",
                            "latitude": "56.76529000",
                            "longitude": "-133.19313000"
                        },
                        {
                            "id": 124724,
                            "name": "Prudhoe Bay",
                            "latitude": "70.25528000",
                            "longitude": "-148.33722000"
                        },
                        {
                            "id": 125130,
                            "name": "Ridgeway",
                            "latitude": "60.53194000",
                            "longitude": "-151.08528000"
                        },
                        {
                            "id": 125724,
                            "name": "Salcha",
                            "latitude": "64.52399000",
                            "longitude": "-146.90210000"
                        },
                        {
                            "id": 125860,
                            "name": "Sand Point",
                            "latitude": "55.33655000",
                            "longitude": "-160.49880000"
                        },
                        {
                            "id": 126187,
                            "name": "Seward",
                            "latitude": "60.10426000",
                            "longitude": "-149.44350000"
                        },
                        {
                            "id": 126470,
                            "name": "Sitka",
                            "latitude": "57.05315000",
                            "longitude": "-135.33088000"
                        },
                        {
                            "id": 126471,
                            "name": "Sitka City and Borough",
                            "latitude": "57.08255000",
                            "longitude": "-135.26917000"
                        },
                        {
                            "id": 126475,
                            "name": "Skagway Municipality",
                            "latitude": "59.46852000",
                            "longitude": "-135.30596000"
                        },
                        {
                            "id": 126567,
                            "name": "Soldotna",
                            "latitude": "60.48778000",
                            "longitude": "-151.05833000"
                        },
                        {
                            "id": 126766,
                            "name": "Southeast Fairbanks Census Area",
                            "latitude": "63.75056000",
                            "longitude": "-143.25056000"
                        },
                        {
                            "id": 127031,
                            "name": "Sterling",
                            "latitude": "60.53722000",
                            "longitude": "-150.76472000"
                        },
                        {
                            "id": 127316,
                            "name": "Sutton-Alpine",
                            "latitude": "61.77789000",
                            "longitude": "-148.76450000"
                        },
                        {
                            "id": 127409,
                            "name": "Tanaina",
                            "latitude": "61.62694000",
                            "longitude": "-149.42806000"
                        },
                        {
                            "id": 127697,
                            "name": "Tok",
                            "latitude": "63.33667000",
                            "longitude": "-142.98556000"
                        },
                        {
                            "id": 127966,
                            "name": "Unalaska",
                            "latitude": "53.87413000",
                            "longitude": "-166.53408000"
                        },
                        {
                            "id": 128083,
                            "name": "Valdez",
                            "latitude": "61.13083000",
                            "longitude": "-146.34833000"
                        },
                        {
                            "id": 128084,
                            "name": "Valdez-Cordova Census Area",
                            "latitude": "61.50056000",
                            "longitude": "-144.50056000"
                        },
                        {
                            "id": 128593,
                            "name": "Wasilla",
                            "latitude": "61.58090000",
                            "longitude": "-149.44150000"
                        },
                        {
                            "id": 129304,
                            "name": "Willow",
                            "latitude": "61.74722000",
                            "longitude": "-150.03750000"
                        },
                        {
                            "id": 129601,
                            "name": "Wrangell",
                            "latitude": "56.47083000",
                            "longitude": "-132.37667000"
                        },
                        {
                            "id": 129646,
                            "name": "Y",
                            "latitude": "62.16129000",
                            "longitude": "-149.85075000"
                        },
                        {
                            "id": 129652,
                            "name": "Yakutat City and Borough",
                            "latitude": "59.66667000",
                            "longitude": "-139.13333000"
                        },
                        {
                            "id": 129731,
                            "name": "Yukon-Koyukuk Census Area",
                            "latitude": "65.70000000",
                            "longitude": "-152.71667000"
                        }
                    ]
                },
                {
                    "id": 1424,
                    "name": "American Samoa",
                    "state_code": "AS",
                    "cities": []
                },
                {
                    "id": 1434,
                    "name": "Arizona",
                    "state_code": "AZ",
                    "cities": [
                        {
                            "id": 111065,
                            "name": "Ahwatukee Foothills",
                            "latitude": "33.34171000",
                            "longitude": "-111.98403000"
                        },
                        {
                            "id": 111075,
                            "name": "Ajo",
                            "latitude": "32.37172000",
                            "longitude": "-112.86071000"
                        },
                        {
                            "id": 111170,
                            "name": "Alhambra",
                            "latitude": "33.49838000",
                            "longitude": "-112.13432000"
                        },
                        {
                            "id": 111367,
                            "name": "Anthem",
                            "latitude": "33.86726000",
                            "longitude": "-112.14682000"
                        },
                        {
                            "id": 111380,
                            "name": "Apache County",
                            "latitude": "35.39560000",
                            "longitude": "-109.48884000"
                        },
                        {
                            "id": 111381,
                            "name": "Apache Junction",
                            "latitude": "33.41505000",
                            "longitude": "-111.54958000"
                        },
                        {
                            "id": 111453,
                            "name": "Arivaca Junction",
                            "latitude": "31.72731000",
                            "longitude": "-111.06120000"
                        },
                        {
                            "id": 111454,
                            "name": "Arizona City",
                            "latitude": "32.75589000",
                            "longitude": "-111.67096000"
                        },
                        {
                            "id": 111679,
                            "name": "Avenue B and C",
                            "latitude": "32.71904000",
                            "longitude": "-114.66005000"
                        },
                        {
                            "id": 111701,
                            "name": "Avondale",
                            "latitude": "33.43560000",
                            "longitude": "-112.34960000"
                        },
                        {
                            "id": 111705,
                            "name": "Avra Valley",
                            "latitude": "32.43785000",
                            "longitude": "-111.31539000"
                        },
                        {
                            "id": 111727,
                            "name": "Bagdad",
                            "latitude": "34.58113000",
                            "longitude": "-113.20464000"
                        },
                        {
                            "id": 112002,
                            "name": "Beaver Dam",
                            "latitude": "36.89942000",
                            "longitude": "-113.93274000"
                        },
                        {
                            "id": 112184,
                            "name": "Benson",
                            "latitude": "31.96786000",
                            "longitude": "-110.29452000"
                        },
                        {
                            "id": 112327,
                            "name": "Big Park",
                            "latitude": "34.78030000",
                            "longitude": "-111.76265000"
                        },
                        {
                            "id": 112360,
                            "name": "Bisbee",
                            "latitude": "31.44815000",
                            "longitude": "-109.92841000"
                        },
                        {
                            "id": 112371,
                            "name": "Black Canyon City",
                            "latitude": "34.07087000",
                            "longitude": "-112.15071000"
                        },
                        {
                            "id": 112395,
                            "name": "Blackwater",
                            "latitude": "33.03117000",
                            "longitude": "-111.58263000"
                        },
                        {
                            "id": 112977,
                            "name": "Buckeye",
                            "latitude": "33.37032000",
                            "longitude": "-112.58378000"
                        },
                        {
                            "id": 113031,
                            "name": "Bullhead City",
                            "latitude": "35.14778000",
                            "longitude": "-114.56830000"
                        },
                        {
                            "id": 113136,
                            "name": "Bylas",
                            "latitude": "33.13428000",
                            "longitude": "-110.12004000"
                        },
                        {
                            "id": 113158,
                            "name": "Cactus Flat",
                            "latitude": "32.75840000",
                            "longitude": "-109.71619000"
                        },
                        {
                            "id": 113295,
                            "name": "Camp Verde",
                            "latitude": "34.56364000",
                            "longitude": "-111.85432000"
                        },
                        {
                            "id": 113357,
                            "name": "Canyon Day",
                            "latitude": "33.78477000",
                            "longitude": "-110.02649000"
                        },
                        {
                            "id": 113392,
                            "name": "Carefree",
                            "latitude": "33.82226000",
                            "longitude": "-111.91820000"
                        },
                        {
                            "id": 113508,
                            "name": "Casa Blanca",
                            "latitude": "33.12033000",
                            "longitude": "-111.88819000"
                        },
                        {
                            "id": 113510,
                            "name": "Casa Grande",
                            "latitude": "32.87950000",
                            "longitude": "-111.75735000"
                        },
                        {
                            "id": 113512,
                            "name": "Casas Adobes",
                            "latitude": "32.32341000",
                            "longitude": "-110.99510000"
                        },
                        {
                            "id": 113563,
                            "name": "Catalina",
                            "latitude": "32.50556000",
                            "longitude": "-110.92111000"
                        },
                        {
                            "id": 113564,
                            "name": "Catalina Foothills",
                            "latitude": "32.29785000",
                            "longitude": "-110.91870000"
                        },
                        {
                            "id": 113585,
                            "name": "Cave Creek",
                            "latitude": "33.83333000",
                            "longitude": "-111.95083000"
                        },
                        {
                            "id": 113635,
                            "name": "Centennial Park",
                            "latitude": "36.95381000",
                            "longitude": "-112.98132000"
                        },
                        {
                            "id": 113672,
                            "name": "Central City",
                            "latitude": "33.44001000",
                            "longitude": "-112.05805000"
                        },
                        {
                            "id": 113676,
                            "name": "Central Heights-Midland City",
                            "latitude": "33.40372000",
                            "longitude": "-110.81541000"
                        },
                        {
                            "id": 113731,
                            "name": "Chandler",
                            "latitude": "33.30616000",
                            "longitude": "-111.84125000"
                        },
                        {
                            "id": 113967,
                            "name": "Chinle",
                            "latitude": "36.15445000",
                            "longitude": "-109.55261000"
                        },
                        {
                            "id": 113970,
                            "name": "Chino Valley",
                            "latitude": "34.75752000",
                            "longitude": "-112.45378000"
                        },
                        {
                            "id": 114016,
                            "name": "Cibecue",
                            "latitude": "34.04477000",
                            "longitude": "-110.48539000"
                        },
                        {
                            "id": 114021,
                            "name": "Cienega Springs",
                            "latitude": "34.18863000",
                            "longitude": "-114.22467000"
                        },
                        {
                            "id": 114040,
                            "name": "Citrus Park",
                            "latitude": "33.54865000",
                            "longitude": "-112.44433000"
                        },
                        {
                            "id": 114133,
                            "name": "Clarkdale",
                            "latitude": "34.77113000",
                            "longitude": "-112.05794000"
                        },
                        {
                            "id": 114193,
                            "name": "Claypool",
                            "latitude": "33.41117000",
                            "longitude": "-110.84261000"
                        },
                        {
                            "id": 114260,
                            "name": "Clifton",
                            "latitude": "33.05090000",
                            "longitude": "-109.29618000"
                        },
                        {
                            "id": 114349,
                            "name": "Cochise County",
                            "latitude": "31.87957000",
                            "longitude": "-109.75114000"
                        },
                        {
                            "id": 114360,
                            "name": "Coconino County",
                            "latitude": "35.83873000",
                            "longitude": "-111.77050000"
                        },
                        {
                            "id": 114454,
                            "name": "Colorado City",
                            "latitude": "36.99026000",
                            "longitude": "-112.97577000"
                        },
                        {
                            "id": 114558,
                            "name": "Congress",
                            "latitude": "34.16253000",
                            "longitude": "-112.85074000"
                        },
                        {
                            "id": 114598,
                            "name": "Coolidge",
                            "latitude": "32.97784000",
                            "longitude": "-111.51762000"
                        },
                        {
                            "id": 114637,
                            "name": "Cordes Lakes",
                            "latitude": "34.30781000",
                            "longitude": "-112.10349000"
                        },
                        {
                            "id": 114657,
                            "name": "Cornville",
                            "latitude": "34.71780000",
                            "longitude": "-111.92154000"
                        },
                        {
                            "id": 114663,
                            "name": "Corona de Tucson",
                            "latitude": "31.96536000",
                            "longitude": "-110.77564000"
                        },
                        {
                            "id": 114701,
                            "name": "Cottonwood",
                            "latitude": "34.73919000",
                            "longitude": "-112.00988000"
                        },
                        {
                            "id": 115180,
                            "name": "Deer Valley",
                            "latitude": "33.68393000",
                            "longitude": "-112.13488000"
                        },
                        {
                            "id": 115281,
                            "name": "Desert Hills",
                            "latitude": "34.55390000",
                            "longitude": "-114.37246000"
                        },
                        {
                            "id": 115305,
                            "name": "Dewey-Humboldt",
                            "latitude": "34.53000000",
                            "longitude": "-112.24222000"
                        },
                        {
                            "id": 115338,
                            "name": "Dilkon",
                            "latitude": "35.38529000",
                            "longitude": "-110.32068000"
                        },
                        {
                            "id": 115386,
                            "name": "Dolan Springs",
                            "latitude": "35.59194000",
                            "longitude": "-114.27329000"
                        },
                        {
                            "id": 115402,
                            "name": "Donovan Estates",
                            "latitude": "32.70935000",
                            "longitude": "-114.67822000"
                        },
                        {
                            "id": 115423,
                            "name": "Douglas",
                            "latitude": "31.34455000",
                            "longitude": "-109.54534000"
                        },
                        {
                            "id": 115476,
                            "name": "Drexel Heights",
                            "latitude": "32.14119000",
                            "longitude": "-111.02843000"
                        },
                        {
                            "id": 115594,
                            "name": "Eagar",
                            "latitude": "34.11124000",
                            "longitude": "-109.29238000"
                        },
                        {
                            "id": 115753,
                            "name": "East Sahuarita",
                            "latitude": "31.94286000",
                            "longitude": "-110.92842000"
                        },
                        {
                            "id": 115907,
                            "name": "Ehrenberg",
                            "latitude": "33.60419000",
                            "longitude": "-114.52523000"
                        },
                        {
                            "id": 115927,
                            "name": "El Mirage",
                            "latitude": "33.61309000",
                            "longitude": "-112.32460000"
                        },
                        {
                            "id": 116065,
                            "name": "Eloy",
                            "latitude": "32.75590000",
                            "longitude": "-111.55484000"
                        },
                        {
                            "id": 116116,
                            "name": "Encanto",
                            "latitude": "33.47937000",
                            "longitude": "-112.07823000"
                        },
                        {
                            "id": 116523,
                            "name": "First Mesa",
                            "latitude": "35.83667000",
                            "longitude": "-110.38152000"
                        },
                        {
                            "id": 116546,
                            "name": "Flagstaff",
                            "latitude": "35.19807000",
                            "longitude": "-111.65127000"
                        },
                        {
                            "id": 116583,
                            "name": "Florence",
                            "latitude": "33.03145000",
                            "longitude": "-111.38734000"
                        },
                        {
                            "id": 116603,
                            "name": "Flowing Wells",
                            "latitude": "32.29396000",
                            "longitude": "-111.00982000"
                        },
                        {
                            "id": 116704,
                            "name": "Fort Defiance",
                            "latitude": "35.74446000",
                            "longitude": "-109.07648000"
                        },
                        {
                            "id": 116768,
                            "name": "Fortuna Foothills",
                            "latitude": "32.65783000",
                            "longitude": "-114.41189000"
                        },
                        {
                            "id": 116782,
                            "name": "Fountain Hills",
                            "latitude": "33.61171000",
                            "longitude": "-111.71736000"
                        },
                        {
                            "id": 116898,
                            "name": "Fredonia",
                            "latitude": "36.94554000",
                            "longitude": "-112.52659000"
                        },
                        {
                            "id": 117048,
                            "name": "Ganado",
                            "latitude": "35.71140000",
                            "longitude": "-109.54205000"
                        },
                        {
                            "id": 117200,
                            "name": "Gila Bend",
                            "latitude": "32.94782000",
                            "longitude": "-112.71683000"
                        },
                        {
                            "id": 117201,
                            "name": "Gila County",
                            "latitude": "33.79975000",
                            "longitude": "-110.81174000"
                        },
                        {
                            "id": 117204,
                            "name": "Gilbert",
                            "latitude": "33.35283000",
                            "longitude": "-111.78903000"
                        },
                        {
                            "id": 117285,
                            "name": "Glendale",
                            "latitude": "33.53865000",
                            "longitude": "-112.18599000"
                        },
                        {
                            "id": 117321,
                            "name": "Globe",
                            "latitude": "33.39422000",
                            "longitude": "-110.78650000"
                        },
                        {
                            "id": 117341,
                            "name": "Gold Camp",
                            "latitude": "33.29367000",
                            "longitude": "-111.30429000"
                        },
                        {
                            "id": 117342,
                            "name": "Gold Canyon",
                            "latitude": "33.37145000",
                            "longitude": "-111.43691000"
                        },
                        {
                            "id": 117353,
                            "name": "Golden Shores",
                            "latitude": "34.78188000",
                            "longitude": "-114.47775000"
                        },
                        {
                            "id": 117356,
                            "name": "Golden Valley",
                            "latitude": "35.22333000",
                            "longitude": "-114.22301000"
                        },
                        {
                            "id": 117393,
                            "name": "Goodyear",
                            "latitude": "33.43532000",
                            "longitude": "-112.35821000"
                        },
                        {
                            "id": 117439,
                            "name": "Graham County",
                            "latitude": "32.93272000",
                            "longitude": "-109.88744000"
                        },
                        {
                            "id": 117452,
                            "name": "Grand Canyon",
                            "latitude": "36.05443000",
                            "longitude": "-112.13934000"
                        },
                        {
                            "id": 117453,
                            "name": "Grand Canyon Village",
                            "latitude": "36.04637000",
                            "longitude": "-112.15406000"
                        },
                        {
                            "id": 117610,
                            "name": "Green Valley",
                            "latitude": "31.85425000",
                            "longitude": "-110.99370000"
                        },
                        {
                            "id": 117659,
                            "name": "Greenlee County",
                            "latitude": "33.21536000",
                            "longitude": "-109.24010000"
                        },
                        {
                            "id": 117776,
                            "name": "Guadalupe",
                            "latitude": "33.37088000",
                            "longitude": "-111.96292000"
                        },
                        {
                            "id": 118231,
                            "name": "Heber-Overgaard",
                            "latitude": "34.41414000",
                            "longitude": "-110.56956000"
                        },
                        {
                            "id": 118519,
                            "name": "Holbrook",
                            "latitude": "34.90225000",
                            "longitude": "-110.15818000"
                        },
                        {
                            "id": 118688,
                            "name": "Houck",
                            "latitude": "35.28308000",
                            "longitude": "-109.20704000"
                        },
                        {
                            "id": 118729,
                            "name": "Huachuca City",
                            "latitude": "31.62787000",
                            "longitude": "-110.33397000"
                        },
                        {
                            "id": 119319,
                            "name": "Joseph City",
                            "latitude": "34.95586000",
                            "longitude": "-110.33401000"
                        },
                        {
                            "id": 119349,
                            "name": "Kachina Village",
                            "latitude": "35.09696000",
                            "longitude": "-111.69266000"
                        },
                        {
                            "id": 119356,
                            "name": "Kaibito",
                            "latitude": "36.59722000",
                            "longitude": "-111.07431000"
                        },
                        {
                            "id": 119402,
                            "name": "Kayenta",
                            "latitude": "36.72778000",
                            "longitude": "-110.25458000"
                        },
                        {
                            "id": 119413,
                            "name": "Kearny",
                            "latitude": "33.05701000",
                            "longitude": "-110.91067000"
                        },
                        {
                            "id": 119595,
                            "name": "Kingman",
                            "latitude": "35.18944000",
                            "longitude": "-114.05301000"
                        },
                        {
                            "id": 119771,
                            "name": "La Paz County",
                            "latitude": "33.72926000",
                            "longitude": "-113.98134000"
                        },
                        {
                            "id": 119918,
                            "name": "Lake Havasu City",
                            "latitude": "34.48390000",
                            "longitude": "-114.32245000"
                        },
                        {
                            "id": 119946,
                            "name": "Lake Montezuma",
                            "latitude": "34.63224000",
                            "longitude": "-111.77793000"
                        },
                        {
                            "id": 120007,
                            "name": "Lake of the Woods",
                            "latitude": "34.16393000",
                            "longitude": "-109.98955000"
                        },
                        {
                            "id": 120217,
                            "name": "Laveen",
                            "latitude": "33.36282000",
                            "longitude": "-112.16932000"
                        },
                        {
                            "id": 120266,
                            "name": "LeChee",
                            "latitude": "36.86211000",
                            "longitude": "-111.44063000"
                        },
                        {
                            "id": 120565,
                            "name": "Linden",
                            "latitude": "34.28504000",
                            "longitude": "-110.15706000"
                        },
                        {
                            "id": 120609,
                            "name": "Litchfield Park",
                            "latitude": "33.49337000",
                            "longitude": "-112.35794000"
                        },
                        {
                            "id": 120874,
                            "name": "Lukachukai",
                            "latitude": "36.41695000",
                            "longitude": "-109.22871000"
                        },
                        {
                            "id": 121076,
                            "name": "Mammoth",
                            "latitude": "32.72257000",
                            "longitude": "-110.64065000"
                        },
                        {
                            "id": 121164,
                            "name": "Many Farms",
                            "latitude": "36.35278000",
                            "longitude": "-109.61789000"
                        },
                        {
                            "id": 121183,
                            "name": "Marana",
                            "latitude": "32.43674000",
                            "longitude": "-111.22538000"
                        },
                        {
                            "id": 121211,
                            "name": "Maricopa",
                            "latitude": "33.05811000",
                            "longitude": "-112.04764000"
                        },
                        {
                            "id": 121213,
                            "name": "Maricopa County",
                            "latitude": "33.34883000",
                            "longitude": "-112.49123000"
                        },
                        {
                            "id": 121362,
                            "name": "Maryvale",
                            "latitude": "33.50199000",
                            "longitude": "-112.17765000"
                        },
                        {
                            "id": 121433,
                            "name": "Mayer",
                            "latitude": "34.39781000",
                            "longitude": "-112.23627000"
                        },
                        {
                            "id": 121573,
                            "name": "Meadview",
                            "latitude": "36.00221000",
                            "longitude": "-114.06829000"
                        },
                        {
                            "id": 121720,
                            "name": "Mesa",
                            "latitude": "33.42227000",
                            "longitude": "-111.82264000"
                        },
                        {
                            "id": 121723,
                            "name": "Mescal",
                            "latitude": "31.99008000",
                            "longitude": "-110.43535000"
                        },
                        {
                            "id": 121748,
                            "name": "Miami",
                            "latitude": "33.39922000",
                            "longitude": "-110.86872000"
                        },
                        {
                            "id": 122019,
                            "name": "Mohave County",
                            "latitude": "35.70404000",
                            "longitude": "-113.75791000"
                        },
                        {
                            "id": 122020,
                            "name": "Mohave Valley",
                            "latitude": "34.93306000",
                            "longitude": "-114.58885000"
                        },
                        {
                            "id": 122230,
                            "name": "Morenci",
                            "latitude": "33.07867000",
                            "longitude": "-109.36535000"
                        },
                        {
                            "id": 122436,
                            "name": "Mountainaire",
                            "latitude": "35.08529000",
                            "longitude": "-111.66599000"
                        },
                        {
                            "id": 122531,
                            "name": "Naco",
                            "latitude": "31.33538000",
                            "longitude": "-109.94813000"
                        },
                        {
                            "id": 122598,
                            "name": "Navajo County",
                            "latitude": "35.39963000",
                            "longitude": "-110.32140000"
                        },
                        {
                            "id": 122736,
                            "name": "New Kingman-Butler",
                            "latitude": "35.26504000",
                            "longitude": "-114.03226000"
                        },
                        {
                            "id": 122771,
                            "name": "New River",
                            "latitude": "33.91587000",
                            "longitude": "-112.13599000"
                        },
                        {
                            "id": 122927,
                            "name": "Nogales",
                            "latitude": "31.34038000",
                            "longitude": "-110.93425000"
                        },
                        {
                            "id": 123027,
                            "name": "North Fork",
                            "latitude": "34.00167000",
                            "longitude": "-109.96355000"
                        },
                        {
                            "id": 123502,
                            "name": "Oracle",
                            "latitude": "32.61091000",
                            "longitude": "-110.77093000"
                        },
                        {
                            "id": 123571,
                            "name": "Oro Valley",
                            "latitude": "32.39091000",
                            "longitude": "-110.96649000"
                        },
                        {
                            "id": 123717,
                            "name": "Page",
                            "latitude": "36.91472000",
                            "longitude": "-111.45583000"
                        },
                        {
                            "id": 123822,
                            "name": "Paradise Valley",
                            "latitude": "33.53115000",
                            "longitude": "-111.94265000"
                        },
                        {
                            "id": 123861,
                            "name": "Parker",
                            "latitude": "34.15002000",
                            "longitude": "-114.28912000"
                        },
                        {
                            "id": 123873,
                            "name": "Parks",
                            "latitude": "35.26057000",
                            "longitude": "-111.94877000"
                        },
                        {
                            "id": 123921,
                            "name": "Paulden",
                            "latitude": "34.88558000",
                            "longitude": "-112.46823000"
                        },
                        {
                            "id": 123949,
                            "name": "Payson",
                            "latitude": "34.23087000",
                            "longitude": "-111.32514000"
                        },
                        {
                            "id": 123958,
                            "name": "Peach Springs",
                            "latitude": "35.52916000",
                            "longitude": "-113.42549000"
                        },
                        {
                            "id": 124047,
                            "name": "Peoria",
                            "latitude": "33.58060000",
                            "longitude": "-112.23738000"
                        },
                        {
                            "id": 124060,
                            "name": "Peridot",
                            "latitude": "33.31034000",
                            "longitude": "-110.45538000"
                        },
                        {
                            "id": 124148,
                            "name": "Phoenix",
                            "latitude": "33.44838000",
                            "longitude": "-112.07404000"
                        },
                        {
                            "id": 124163,
                            "name": "Picture Rocks",
                            "latitude": "32.34591000",
                            "longitude": "-111.24621000"
                        },
                        {
                            "id": 124203,
                            "name": "Pima",
                            "latitude": "32.89656000",
                            "longitude": "-109.82835000"
                        },
                        {
                            "id": 124204,
                            "name": "Pima County",
                            "latitude": "32.09738000",
                            "longitude": "-111.78995000"
                        },
                        {
                            "id": 124206,
                            "name": "Pinal County",
                            "latitude": "32.90431000",
                            "longitude": "-111.34471000"
                        },
                        {
                            "id": 124212,
                            "name": "Pine",
                            "latitude": "34.38447000",
                            "longitude": "-111.45514000"
                        },
                        {
                            "id": 124257,
                            "name": "Pinetop-Lakeside",
                            "latitude": "34.14254000",
                            "longitude": "-109.96038000"
                        },
                        {
                            "id": 124279,
                            "name": "Pirtleville",
                            "latitude": "31.35716000",
                            "longitude": "-109.56352000"
                        },
                        {
                            "id": 124643,
                            "name": "Prescott",
                            "latitude": "34.54002000",
                            "longitude": "-112.46850000"
                        },
                        {
                            "id": 124645,
                            "name": "Prescott Valley",
                            "latitude": "34.61002000",
                            "longitude": "-112.31572000"
                        },
                        {
                            "id": 124783,
                            "name": "Quartzsite",
                            "latitude": "33.66391000",
                            "longitude": "-114.22995000"
                        },
                        {
                            "id": 124788,
                            "name": "Queen Creek",
                            "latitude": "33.24866000",
                            "longitude": "-111.63430000"
                        },
                        {
                            "id": 125159,
                            "name": "Rio Rico",
                            "latitude": "31.47148000",
                            "longitude": "-110.97648000"
                        },
                        {
                            "id": 125160,
                            "name": "Rio Verde",
                            "latitude": "33.72254000",
                            "longitude": "-111.67569000"
                        },
                        {
                            "id": 125573,
                            "name": "Sacaton",
                            "latitude": "33.07672000",
                            "longitude": "-111.73930000"
                        },
                        {
                            "id": 125580,
                            "name": "Saddle Brooke",
                            "latitude": "32.53472000",
                            "longitude": "-110.87361000"
                        },
                        {
                            "id": 125583,
                            "name": "Safford",
                            "latitude": "32.83395000",
                            "longitude": "-109.70758000"
                        },
                        {
                            "id": 125593,
                            "name": "Sahuarita",
                            "latitude": "31.95758000",
                            "longitude": "-110.95565000"
                        },
                        {
                            "id": 125630,
                            "name": "Saint David",
                            "latitude": "31.90425000",
                            "longitude": "-110.21424000"
                        },
                        {
                            "id": 125666,
                            "name": "Saint Johns",
                            "latitude": "34.50587000",
                            "longitude": "-109.36093000"
                        },
                        {
                            "id": 125699,
                            "name": "Saint Michaels",
                            "latitude": "35.64474000",
                            "longitude": "-109.09565000"
                        },
                        {
                            "id": 125766,
                            "name": "Salome",
                            "latitude": "33.78114000",
                            "longitude": "-113.61465000"
                        },
                        {
                            "id": 125797,
                            "name": "San Carlos",
                            "latitude": "33.34562000",
                            "longitude": "-110.45504000"
                        },
                        {
                            "id": 125827,
                            "name": "San Luis",
                            "latitude": "32.48700000",
                            "longitude": "-114.78218000"
                        },
                        {
                            "id": 125831,
                            "name": "San Manuel",
                            "latitude": "32.59979000",
                            "longitude": "-110.63093000"
                        },
                        {
                            "id": 125850,
                            "name": "San Tan Valley",
                            "latitude": "33.19110000",
                            "longitude": "-111.52800000"
                        },
                        {
                            "id": 125911,
                            "name": "Santa Cruz County",
                            "latitude": "31.52600000",
                            "longitude": "-110.84657000"
                        },
                        {
                            "id": 125999,
                            "name": "Scenic",
                            "latitude": "36.79359000",
                            "longitude": "-114.01275000"
                        },
                        {
                            "id": 126063,
                            "name": "Scottsdale",
                            "latitude": "33.50921000",
                            "longitude": "-111.89903000"
                        },
                        {
                            "id": 126121,
                            "name": "Sedona",
                            "latitude": "34.86974000",
                            "longitude": "-111.76099000"
                        },
                        {
                            "id": 126137,
                            "name": "Sells",
                            "latitude": "31.91202000",
                            "longitude": "-111.88123000"
                        },
                        {
                            "id": 126381,
                            "name": "Show Low",
                            "latitude": "34.25421000",
                            "longitude": "-110.02983000"
                        },
                        {
                            "id": 126406,
                            "name": "Sierra Vista",
                            "latitude": "31.55454000",
                            "longitude": "-110.30369000"
                        },
                        {
                            "id": 126407,
                            "name": "Sierra Vista Southeast",
                            "latitude": "31.45385000",
                            "longitude": "-110.21637000"
                        },
                        {
                            "id": 126472,
                            "name": "Six Shooter Canyon",
                            "latitude": "33.36678000",
                            "longitude": "-110.77460000"
                        },
                        {
                            "id": 126547,
                            "name": "Snowflake",
                            "latitude": "34.51337000",
                            "longitude": "-110.07845000"
                        },
                        {
                            "id": 126591,
                            "name": "Somerton",
                            "latitude": "32.59644000",
                            "longitude": "-114.70968000"
                        },
                        {
                            "id": 126737,
                            "name": "South Tucson",
                            "latitude": "32.19952000",
                            "longitude": "-110.96842000"
                        },
                        {
                            "id": 126878,
                            "name": "Spring Valley",
                            "latitude": "34.34503000",
                            "longitude": "-112.15905000"
                        },
                        {
                            "id": 126888,
                            "name": "Springerville",
                            "latitude": "34.13355000",
                            "longitude": "-109.28834000"
                        },
                        {
                            "id": 126981,
                            "name": "Star Valley",
                            "latitude": "34.25504000",
                            "longitude": "-111.25847000"
                        },
                        {
                            "id": 127214,
                            "name": "Summit",
                            "latitude": "32.06702000",
                            "longitude": "-110.95148000"
                        },
                        {
                            "id": 127232,
                            "name": "Sun City",
                            "latitude": "33.59754000",
                            "longitude": "-112.27182000"
                        },
                        {
                            "id": 127235,
                            "name": "Sun City West",
                            "latitude": "33.66198000",
                            "longitude": "-112.34127000"
                        },
                        {
                            "id": 127236,
                            "name": "Sun Lakes",
                            "latitude": "33.21116000",
                            "longitude": "-111.87542000"
                        },
                        {
                            "id": 127240,
                            "name": "Sun Valley",
                            "latitude": "34.25420000",
                            "longitude": "-111.26125000"
                        },
                        {
                            "id": 127280,
                            "name": "Superior",
                            "latitude": "33.29395000",
                            "longitude": "-111.09623000"
                        },
                        {
                            "id": 127290,
                            "name": "Surprise",
                            "latitude": "33.63059000",
                            "longitude": "-112.33322000"
                        },
                        {
                            "id": 127346,
                            "name": "Swift Trail Junction",
                            "latitude": "32.72979000",
                            "longitude": "-109.71397000"
                        },
                        {
                            "id": 127420,
                            "name": "Tanque Verde",
                            "latitude": "32.25174000",
                            "longitude": "-110.73731000"
                        },
                        {
                            "id": 127450,
                            "name": "Taylor",
                            "latitude": "34.46504000",
                            "longitude": "-110.09123000"
                        },
                        {
                            "id": 127494,
                            "name": "Tempe",
                            "latitude": "33.41477000",
                            "longitude": "-111.90931000"
                        },
                        {
                            "id": 127495,
                            "name": "Tempe Junction",
                            "latitude": "33.41421000",
                            "longitude": "-111.94348000"
                        },
                        {
                            "id": 127550,
                            "name": "Thatcher",
                            "latitude": "32.84923000",
                            "longitude": "-109.75925000"
                        },
                        {
                            "id": 127616,
                            "name": "Three Points",
                            "latitude": "32.07675000",
                            "longitude": "-111.31371000"
                        },
                        {
                            "id": 127703,
                            "name": "Tolleson",
                            "latitude": "33.45004000",
                            "longitude": "-112.25932000"
                        },
                        {
                            "id": 127711,
                            "name": "Tombstone",
                            "latitude": "31.71287000",
                            "longitude": "-110.06758000"
                        },
                        {
                            "id": 127724,
                            "name": "Tonto Basin",
                            "latitude": "33.83171000",
                            "longitude": "-111.29457000"
                        },
                        {
                            "id": 127746,
                            "name": "Tortolita",
                            "latitude": "32.41035000",
                            "longitude": "-111.01732000"
                        },
                        {
                            "id": 127864,
                            "name": "Tsaile",
                            "latitude": "36.30330000",
                            "longitude": "-109.21566000"
                        },
                        {
                            "id": 127866,
                            "name": "Tuba City",
                            "latitude": "36.13499000",
                            "longitude": "-111.23986000"
                        },
                        {
                            "id": 127867,
                            "name": "Tubac",
                            "latitude": "31.61259000",
                            "longitude": "-111.04592000"
                        },
                        {
                            "id": 127874,
                            "name": "Tucson",
                            "latitude": "32.22174000",
                            "longitude": "-110.92648000"
                        },
                        {
                            "id": 127875,
                            "name": "Tucson Estates",
                            "latitude": "32.18758000",
                            "longitude": "-111.09093000"
                        },
                        {
                            "id": 128075,
                            "name": "Vail",
                            "latitude": "32.04786000",
                            "longitude": "-110.71203000"
                        },
                        {
                            "id": 128090,
                            "name": "Valencia West",
                            "latitude": "32.13238000",
                            "longitude": "-111.11414000"
                        },
                        {
                            "id": 128095,
                            "name": "Valle Vista",
                            "latitude": "35.41088000",
                            "longitude": "-113.86271000"
                        },
                        {
                            "id": 128179,
                            "name": "Verde Village",
                            "latitude": "34.71050000",
                            "longitude": "-112.01152000"
                        },
                        {
                            "id": 128253,
                            "name": "Village of Oak Creek (Big Park)",
                            "latitude": "34.78090000",
                            "longitude": "-111.76227000"
                        },
                        {
                            "id": 128795,
                            "name": "Wellton",
                            "latitude": "32.67283000",
                            "longitude": "-114.14688000"
                        },
                        {
                            "id": 128978,
                            "name": "West Sedona",
                            "latitude": "34.86724000",
                            "longitude": "-111.80543000"
                        },
                        {
                            "id": 129122,
                            "name": "Whetstone",
                            "latitude": "31.95731000",
                            "longitude": "-110.34202000"
                        },
                        {
                            "id": 129147,
                            "name": "White Mountain Lake",
                            "latitude": "34.34865000",
                            "longitude": "-109.99789000"
                        },
                        {
                            "id": 129179,
                            "name": "Whiteriver",
                            "latitude": "33.83699000",
                            "longitude": "-109.96427000"
                        },
                        {
                            "id": 129219,
                            "name": "Wickenburg",
                            "latitude": "33.96864000",
                            "longitude": "-112.72962000"
                        },
                        {
                            "id": 129259,
                            "name": "Willcox",
                            "latitude": "32.25285000",
                            "longitude": "-109.83201000"
                        },
                        {
                            "id": 129260,
                            "name": "Williams",
                            "latitude": "35.24946000",
                            "longitude": "-112.19100000"
                        },
                        {
                            "id": 129275,
                            "name": "Williamson",
                            "latitude": "34.69002000",
                            "longitude": "-112.54101000"
                        },
                        {
                            "id": 129312,
                            "name": "Willow Valley",
                            "latitude": "34.91195000",
                            "longitude": "-114.60663000"
                        },
                        {
                            "id": 129376,
                            "name": "Window Rock",
                            "latitude": "35.68057000",
                            "longitude": "-109.05259000"
                        },
                        {
                            "id": 129429,
                            "name": "Winslow",
                            "latitude": "35.02419000",
                            "longitude": "-110.69736000"
                        },
                        {
                            "id": 129672,
                            "name": "Yavapai County",
                            "latitude": "34.59988000",
                            "longitude": "-112.55387000"
                        },
                        {
                            "id": 129721,
                            "name": "Youngtown",
                            "latitude": "33.59393000",
                            "longitude": "-112.30294000"
                        },
                        {
                            "id": 129733,
                            "name": "Yuma",
                            "latitude": "32.72532000",
                            "longitude": "-114.62440000"
                        },
                        {
                            "id": 129735,
                            "name": "Yuma County",
                            "latitude": "32.76940000",
                            "longitude": "-113.90556000"
                        }
                    ]
                },
                {
                    "id": 1444,
                    "name": "Arkansas",
                    "state_code": "AR",
                    "cities": [
                        {
                            "id": 111145,
                            "name": "Alexander",
                            "latitude": "34.62954000",
                            "longitude": "-92.44127000"
                        },
                        {
                            "id": 111206,
                            "name": "Alma",
                            "latitude": "35.47787000",
                            "longitude": "-94.22188000"
                        },
                        {
                            "id": 111455,
                            "name": "Arkadelphia",
                            "latitude": "34.12093000",
                            "longitude": "-93.05378000"
                        },
                        {
                            "id": 111456,
                            "name": "Arkansas City",
                            "latitude": "33.60872000",
                            "longitude": "-91.20678000"
                        },
                        {
                            "id": 111458,
                            "name": "Arkansas County",
                            "latitude": "34.29081000",
                            "longitude": "-91.37491000"
                        },
                        {
                            "id": 111510,
                            "name": "Ash Flat",
                            "latitude": "36.22396000",
                            "longitude": "-91.60848000"
                        },
                        {
                            "id": 111518,
                            "name": "Ashdown",
                            "latitude": "33.67429000",
                            "longitude": "-94.13131000"
                        },
                        {
                            "id": 111545,
                            "name": "Ashley County",
                            "latitude": "33.19122000",
                            "longitude": "-91.76845000"
                        },
                        {
                            "id": 111584,
                            "name": "Atkins",
                            "latitude": "35.24647000",
                            "longitude": "-92.93656000"
                        },
                        {
                            "id": 111645,
                            "name": "Augusta",
                            "latitude": "35.28231000",
                            "longitude": "-91.36541000"
                        },
                        {
                            "id": 111666,
                            "name": "Austin",
                            "latitude": "34.99842000",
                            "longitude": "-91.98376000"
                        },
                        {
                            "id": 111751,
                            "name": "Bald Knob",
                            "latitude": "35.30981000",
                            "longitude": "-91.56791000"
                        },
                        {
                            "id": 111824,
                            "name": "Barling",
                            "latitude": "35.32565000",
                            "longitude": "-94.30160000"
                        },
                        {
                            "id": 111892,
                            "name": "Batesville",
                            "latitude": "35.76980000",
                            "longitude": "-91.64097000"
                        },
                        {
                            "id": 111917,
                            "name": "Baxter County",
                            "latitude": "36.28719000",
                            "longitude": "-92.33697000"
                        },
                        {
                            "id": 111920,
                            "name": "Bay",
                            "latitude": "35.74230000",
                            "longitude": "-90.56233000"
                        },
                        {
                            "id": 112036,
                            "name": "Beebe",
                            "latitude": "35.07064000",
                            "longitude": "-91.87959000"
                        },
                        {
                            "id": 112071,
                            "name": "Bella Vista",
                            "latitude": "36.48070000",
                            "longitude": "-94.27134000"
                        },
                        {
                            "id": 112191,
                            "name": "Benton",
                            "latitude": "34.56454000",
                            "longitude": "-92.58683000"
                        },
                        {
                            "id": 112199,
                            "name": "Benton County",
                            "latitude": "36.33872000",
                            "longitude": "-94.25619000"
                        },
                        {
                            "id": 112210,
                            "name": "Bentonville",
                            "latitude": "36.37285000",
                            "longitude": "-94.20882000"
                        },
                        {
                            "id": 112254,
                            "name": "Berryville",
                            "latitude": "36.36479000",
                            "longitude": "-93.56797000"
                        },
                        {
                            "id": 112285,
                            "name": "Bethel Heights",
                            "latitude": "36.21424000",
                            "longitude": "-94.12937000"
                        },
                        {
                            "id": 112486,
                            "name": "Blytheville",
                            "latitude": "35.92730000",
                            "longitude": "-89.91898000"
                        },
                        {
                            "id": 112549,
                            "name": "Bono",
                            "latitude": "35.90868000",
                            "longitude": "-90.80262000"
                        },
                        {
                            "id": 112555,
                            "name": "Boone County",
                            "latitude": "36.30859000",
                            "longitude": "-93.09150000"
                        },
                        {
                            "id": 112562,
                            "name": "Booneville",
                            "latitude": "35.14009000",
                            "longitude": "-93.92159000"
                        },
                        {
                            "id": 112675,
                            "name": "Bradley County",
                            "latitude": "33.46642000",
                            "longitude": "-92.16240000"
                        },
                        {
                            "id": 112799,
                            "name": "Brinkley",
                            "latitude": "34.88787000",
                            "longitude": "-91.19457000"
                        },
                        {
                            "id": 112864,
                            "name": "Brookland",
                            "latitude": "35.90007000",
                            "longitude": "-90.58205000"
                        },
                        {
                            "id": 112963,
                            "name": "Bryant",
                            "latitude": "34.59593000",
                            "longitude": "-92.48905000"
                        },
                        {
                            "id": 113028,
                            "name": "Bull Shoals",
                            "latitude": "36.38396000",
                            "longitude": "-92.58155000"
                        },
                        {
                            "id": 113154,
                            "name": "Cabot",
                            "latitude": "34.97453000",
                            "longitude": "-92.01653000"
                        },
                        {
                            "id": 113201,
                            "name": "Calhoun County",
                            "latitude": "33.55803000",
                            "longitude": "-92.50304000"
                        },
                        {
                            "id": 113212,
                            "name": "Calico Rock",
                            "latitude": "36.11951000",
                            "longitude": "-92.13599000"
                        },
                        {
                            "id": 113260,
                            "name": "Camden",
                            "latitude": "33.58456000",
                            "longitude": "-92.83433000"
                        },
                        {
                            "id": 113380,
                            "name": "Caraway",
                            "latitude": "35.75813000",
                            "longitude": "-90.32232000"
                        },
                        {
                            "id": 113401,
                            "name": "Carlisle",
                            "latitude": "34.78315000",
                            "longitude": "-91.74652000"
                        },
                        {
                            "id": 113450,
                            "name": "Carroll County",
                            "latitude": "36.34102000",
                            "longitude": "-93.53818000"
                        },
                        {
                            "id": 113583,
                            "name": "Cave City",
                            "latitude": "35.94174000",
                            "longitude": "-91.54847000"
                        },
                        {
                            "id": 113589,
                            "name": "Cave Springs",
                            "latitude": "36.26341000",
                            "longitude": "-94.23187000"
                        },
                        {
                            "id": 113628,
                            "name": "Cedarville",
                            "latitude": "35.56981000",
                            "longitude": "-94.36688000"
                        },
                        {
                            "id": 113651,
                            "name": "Centerton",
                            "latitude": "36.35980000",
                            "longitude": "-94.28521000"
                        },
                        {
                            "id": 113761,
                            "name": "Charleston",
                            "latitude": "35.29704000",
                            "longitude": "-94.03632000"
                        },
                        {
                            "id": 113857,
                            "name": "Cherokee Village",
                            "latitude": "36.29784000",
                            "longitude": "-91.51597000"
                        },
                        {
                            "id": 113947,
                            "name": "Chicot County",
                            "latitude": "33.26725000",
                            "longitude": "-91.29397000"
                        },
                        {
                            "id": 114110,
                            "name": "Clarendon",
                            "latitude": "34.69315000",
                            "longitude": "-91.31374000"
                        },
                        {
                            "id": 114121,
                            "name": "Clark County",
                            "latitude": "34.05096000",
                            "longitude": "-93.17637000"
                        },
                        {
                            "id": 114152,
                            "name": "Clarksville",
                            "latitude": "35.47147000",
                            "longitude": "-93.46657000"
                        },
                        {
                            "id": 114174,
                            "name": "Clay County",
                            "latitude": "36.36839000",
                            "longitude": "-90.41738000"
                        },
                        {
                            "id": 114231,
                            "name": "Cleburne County",
                            "latitude": "35.53811000",
                            "longitude": "-92.02674000"
                        },
                        {
                            "id": 114248,
                            "name": "Cleveland County",
                            "latitude": "33.89836000",
                            "longitude": "-92.18514000"
                        },
                        {
                            "id": 114267,
                            "name": "Clinton",
                            "latitude": "35.59147000",
                            "longitude": "-92.46044000"
                        },
                        {
                            "id": 114331,
                            "name": "Coal Hill",
                            "latitude": "35.43731000",
                            "longitude": "-93.67297000"
                        },
                        {
                            "id": 114477,
                            "name": "Columbia County",
                            "latitude": "33.21429000",
                            "longitude": "-93.22731000"
                        },
                        {
                            "id": 114581,
                            "name": "Conway",
                            "latitude": "35.08870000",
                            "longitude": "-92.44210000"
                        },
                        {
                            "id": 114587,
                            "name": "Conway County",
                            "latitude": "35.26221000",
                            "longitude": "-92.70132000"
                        },
                        {
                            "id": 114651,
                            "name": "Corning",
                            "latitude": "36.40784000",
                            "longitude": "-90.57983000"
                        },
                        {
                            "id": 114759,
                            "name": "Craighead County",
                            "latitude": "35.83079000",
                            "longitude": "-90.63285000"
                        },
                        {
                            "id": 114774,
                            "name": "Crawford County",
                            "latitude": "35.58883000",
                            "longitude": "-94.24312000"
                        },
                        {
                            "id": 114825,
                            "name": "Crittenden County",
                            "latitude": "35.20794000",
                            "longitude": "-90.30886000"
                        },
                        {
                            "id": 114848,
                            "name": "Cross County",
                            "latitude": "35.29573000",
                            "longitude": "-90.77123000"
                        },
                        {
                            "id": 114854,
                            "name": "Crossett",
                            "latitude": "33.12818000",
                            "longitude": "-91.96124000"
                        },
                        {
                            "id": 114995,
                            "name": "Dallas County",
                            "latitude": "33.96983000",
                            "longitude": "-92.65444000"
                        },
                        {
                            "id": 115031,
                            "name": "Danville",
                            "latitude": "35.05398000",
                            "longitude": "-93.39352000"
                        },
                        {
                            "id": 115042,
                            "name": "Dardanelle",
                            "latitude": "35.22314000",
                            "longitude": "-93.15795000"
                        },
                        {
                            "id": 115113,
                            "name": "De Queen",
                            "latitude": "34.03789000",
                            "longitude": "-94.34132000"
                        },
                        {
                            "id": 115121,
                            "name": "De Witt",
                            "latitude": "34.29288000",
                            "longitude": "-91.33790000"
                        },
                        {
                            "id": 115150,
                            "name": "Decatur",
                            "latitude": "36.33591000",
                            "longitude": "-94.46077000"
                        },
                        {
                            "id": 115264,
                            "name": "Dermott",
                            "latitude": "33.52539000",
                            "longitude": "-91.43595000"
                        },
                        {
                            "id": 115270,
                            "name": "Des Arc",
                            "latitude": "34.97704000",
                            "longitude": "-91.49513000"
                        },
                        {
                            "id": 115285,
                            "name": "Desha County",
                            "latitude": "33.83333000",
                            "longitude": "-91.25395000"
                        },
                        {
                            "id": 115318,
                            "name": "Diaz",
                            "latitude": "35.63841000",
                            "longitude": "-91.26513000"
                        },
                        {
                            "id": 115334,
                            "name": "Dierks",
                            "latitude": "34.11928000",
                            "longitude": "-94.01658000"
                        },
                        {
                            "id": 115444,
                            "name": "Dover",
                            "latitude": "35.40147000",
                            "longitude": "-93.11434000"
                        },
                        {
                            "id": 115474,
                            "name": "Drew County",
                            "latitude": "33.58945000",
                            "longitude": "-91.72002000"
                        },
                        {
                            "id": 115514,
                            "name": "Dumas",
                            "latitude": "33.88705000",
                            "longitude": "-91.49179000"
                        },
                        {
                            "id": 115616,
                            "name": "Earle",
                            "latitude": "35.27509000",
                            "longitude": "-90.46677000"
                        },
                        {
                            "id": 115656,
                            "name": "East End",
                            "latitude": "34.55065000",
                            "longitude": "-92.34099000"
                        },
                        {
                            "id": 115919,
                            "name": "El Dorado",
                            "latitude": "33.20763000",
                            "longitude": "-92.66627000"
                        },
                        {
                            "id": 116005,
                            "name": "Elkins",
                            "latitude": "36.00147000",
                            "longitude": "-94.00825000"
                        },
                        {
                            "id": 116045,
                            "name": "Elm Springs",
                            "latitude": "36.20619000",
                            "longitude": "-94.23437000"
                        },
                        {
                            "id": 116128,
                            "name": "England",
                            "latitude": "34.54426000",
                            "longitude": "-91.96903000"
                        },
                        {
                            "id": 116222,
                            "name": "Eudora",
                            "latitude": "33.10957000",
                            "longitude": "-91.26206000"
                        },
                        {
                            "id": 116240,
                            "name": "Eureka Springs",
                            "latitude": "36.40118000",
                            "longitude": "-93.73797000"
                        },
                        {
                            "id": 116318,
                            "name": "Fairfield Bay",
                            "latitude": "35.59424000",
                            "longitude": "-92.27793000"
                        },
                        {
                            "id": 116412,
                            "name": "Farmington",
                            "latitude": "36.04202000",
                            "longitude": "-94.24715000"
                        },
                        {
                            "id": 116434,
                            "name": "Faulkner County",
                            "latitude": "35.14698000",
                            "longitude": "-92.33204000"
                        },
                        {
                            "id": 116454,
                            "name": "Fayetteville",
                            "latitude": "36.06258000",
                            "longitude": "-94.15743000"
                        },
                        {
                            "id": 116568,
                            "name": "Flippin",
                            "latitude": "36.27896000",
                            "longitude": "-92.59711000"
                        },
                        {
                            "id": 116642,
                            "name": "Fordyce",
                            "latitude": "33.81372000",
                            "longitude": "-92.41293000"
                        },
                        {
                            "id": 116679,
                            "name": "Forrest City",
                            "latitude": "35.00815000",
                            "longitude": "-90.78983000"
                        },
                        {
                            "id": 116750,
                            "name": "Fort Smith",
                            "latitude": "35.38592000",
                            "longitude": "-94.39855000"
                        },
                        {
                            "id": 116844,
                            "name": "Franklin County",
                            "latitude": "35.51235000",
                            "longitude": "-93.89062000"
                        },
                        {
                            "id": 116992,
                            "name": "Fulton County",
                            "latitude": "36.38167000",
                            "longitude": "-91.81824000"
                        },
                        {
                            "id": 117091,
                            "name": "Garland County",
                            "latitude": "34.57669000",
                            "longitude": "-93.15043000"
                        },
                        {
                            "id": 117112,
                            "name": "Gassville",
                            "latitude": "36.28312000",
                            "longitude": "-92.49405000"
                        },
                        {
                            "id": 117150,
                            "name": "Gentry",
                            "latitude": "36.26758000",
                            "longitude": "-94.48466000"
                        },
                        {
                            "id": 117186,
                            "name": "Gibson",
                            "latitude": "34.88426000",
                            "longitude": "-92.23570000"
                        },
                        {
                            "id": 117312,
                            "name": "Glenwood",
                            "latitude": "34.32677000",
                            "longitude": "-93.55074000"
                        },
                        {
                            "id": 117407,
                            "name": "Goshen",
                            "latitude": "36.10119000",
                            "longitude": "-93.99131000"
                        },
                        {
                            "id": 117412,
                            "name": "Gosnell",
                            "latitude": "35.95979000",
                            "longitude": "-89.97203000"
                        },
                        {
                            "id": 117508,
                            "name": "Grant County",
                            "latitude": "34.29002000",
                            "longitude": "-92.42358000"
                        },
                        {
                            "id": 117546,
                            "name": "Gravel Ridge",
                            "latitude": "34.86842000",
                            "longitude": "-92.19070000"
                        },
                        {
                            "id": 117549,
                            "name": "Gravette",
                            "latitude": "36.42202000",
                            "longitude": "-94.45355000"
                        },
                        {
                            "id": 117595,
                            "name": "Green Forest",
                            "latitude": "36.33535000",
                            "longitude": "-93.43602000"
                        },
                        {
                            "id": 117619,
                            "name": "Greenbrier",
                            "latitude": "35.23397000",
                            "longitude": "-92.38765000"
                        },
                        {
                            "id": 117632,
                            "name": "Greene County",
                            "latitude": "36.11769000",
                            "longitude": "-90.55908000"
                        },
                        {
                            "id": 117656,
                            "name": "Greenland",
                            "latitude": "35.99425000",
                            "longitude": "-94.17520000"
                        },
                        {
                            "id": 117701,
                            "name": "Greenwood",
                            "latitude": "35.21565000",
                            "longitude": "-94.25577000"
                        },
                        {
                            "id": 117808,
                            "name": "Gurdon",
                            "latitude": "33.92094000",
                            "longitude": "-93.15406000"
                        },
                        {
                            "id": 117881,
                            "name": "Hamburg",
                            "latitude": "33.22818000",
                            "longitude": "-91.79763000"
                        },
                        {
                            "id": 117926,
                            "name": "Hampton",
                            "latitude": "33.53789000",
                            "longitude": "-92.46988000"
                        },
                        {
                            "id": 118047,
                            "name": "Harrisburg",
                            "latitude": "35.56425000",
                            "longitude": "-90.71678000"
                        },
                        {
                            "id": 118054,
                            "name": "Harrison",
                            "latitude": "36.22979000",
                            "longitude": "-93.10768000"
                        },
                        {
                            "id": 118122,
                            "name": "Haskell",
                            "latitude": "34.50148000",
                            "longitude": "-92.63655000"
                        },
                        {
                            "id": 118209,
                            "name": "Hazen",
                            "latitude": "34.78093000",
                            "longitude": "-91.58097000"
                        },
                        {
                            "id": 118230,
                            "name": "Heber Springs",
                            "latitude": "35.49147000",
                            "longitude": "-92.03126000"
                        },
                        {
                            "id": 118247,
                            "name": "Helena",
                            "latitude": "34.52955000",
                            "longitude": "-90.59177000"
                        },
                        {
                            "id": 118257,
                            "name": "Helena-West Helena",
                            "latitude": "34.52910000",
                            "longitude": "-90.59000000"
                        },
                        {
                            "id": 118271,
                            "name": "Hempstead County",
                            "latitude": "33.73530000",
                            "longitude": "-93.66844000"
                        },
                        {
                            "id": 118392,
                            "name": "Highland",
                            "latitude": "36.27590000",
                            "longitude": "-91.52403000"
                        },
                        {
                            "id": 118533,
                            "name": "Holiday Island",
                            "latitude": "36.48535000",
                            "longitude": "-93.73214000"
                        },
                        {
                            "id": 118642,
                            "name": "Hope",
                            "latitude": "33.66706000",
                            "longitude": "-93.59157000"
                        },
                        {
                            "id": 118662,
                            "name": "Horatio",
                            "latitude": "33.93845000",
                            "longitude": "-94.35715000"
                        },
                        {
                            "id": 118676,
                            "name": "Horseshoe Bend",
                            "latitude": "36.22923000",
                            "longitude": "-91.76431000"
                        },
                        {
                            "id": 118681,
                            "name": "Hot Spring County",
                            "latitude": "34.31763000",
                            "longitude": "-92.94601000"
                        },
                        {
                            "id": 118682,
                            "name": "Hot Springs",
                            "latitude": "34.50370000",
                            "longitude": "-93.05518000"
                        },
                        {
                            "id": 118685,
                            "name": "Hot Springs National Park",
                            "latitude": "34.53170000",
                            "longitude": "-93.06377000"
                        },
                        {
                            "id": 118686,
                            "name": "Hot Springs Village",
                            "latitude": "34.67204000",
                            "longitude": "-92.99879000"
                        },
                        {
                            "id": 118712,
                            "name": "Howard County",
                            "latitude": "34.08874000",
                            "longitude": "-93.99349000"
                        },
                        {
                            "id": 118726,
                            "name": "Hoxie",
                            "latitude": "36.05035000",
                            "longitude": "-90.97512000"
                        },
                        {
                            "id": 118757,
                            "name": "Hughes",
                            "latitude": "34.94926000",
                            "longitude": "-90.47149000"
                        },
                        {
                            "id": 118815,
                            "name": "Huntsville",
                            "latitude": "36.08619000",
                            "longitude": "-93.74130000"
                        },
                        {
                            "id": 118898,
                            "name": "Independence County",
                            "latitude": "35.74158000",
                            "longitude": "-91.56972000"
                        },
                        {
                            "id": 119054,
                            "name": "Izard County",
                            "latitude": "36.09487000",
                            "longitude": "-91.91342000"
                        },
                        {
                            "id": 119076,
                            "name": "Jackson County",
                            "latitude": "35.59923000",
                            "longitude": "-91.21457000"
                        },
                        {
                            "id": 119100,
                            "name": "Jacksonville",
                            "latitude": "34.86620000",
                            "longitude": "-92.11015000"
                        },
                        {
                            "id": 119139,
                            "name": "Jasper",
                            "latitude": "36.00813000",
                            "longitude": "-93.18657000"
                        },
                        {
                            "id": 119176,
                            "name": "Jefferson County",
                            "latitude": "34.26879000",
                            "longitude": "-91.93151000"
                        },
                        {
                            "id": 119254,
                            "name": "Johnson",
                            "latitude": "36.13286000",
                            "longitude": "-94.16548000"
                        },
                        {
                            "id": 119260,
                            "name": "Johnson County",
                            "latitude": "35.57005000",
                            "longitude": "-93.45991000"
                        },
                        {
                            "id": 119299,
                            "name": "Jonesboro",
                            "latitude": "35.84230000",
                            "longitude": "-90.70428000"
                        },
                        {
                            "id": 119328,
                            "name": "Judsonia",
                            "latitude": "35.27009000",
                            "longitude": "-91.63986000"
                        },
                        {
                            "id": 119479,
                            "name": "Kensett",
                            "latitude": "35.23175000",
                            "longitude": "-91.66764000"
                        },
                        {
                            "id": 119838,
                            "name": "Lafayette County",
                            "latitude": "33.24098000",
                            "longitude": "-93.60704000"
                        },
                        {
                            "id": 119878,
                            "name": "Lake City",
                            "latitude": "35.81619000",
                            "longitude": "-90.43427000"
                        },
                        {
                            "id": 119916,
                            "name": "Lake Hamilton",
                            "latitude": "34.42453000",
                            "longitude": "-93.09518000"
                        },
                        {
                            "id": 119989,
                            "name": "Lake Village",
                            "latitude": "33.32873000",
                            "longitude": "-91.28178000"
                        },
                        {
                            "id": 120058,
                            "name": "Lamar",
                            "latitude": "35.44064000",
                            "longitude": "-93.38796000"
                        },
                        {
                            "id": 120101,
                            "name": "Landmark",
                            "latitude": "34.61120000",
                            "longitude": "-92.31960000"
                        },
                        {
                            "id": 120213,
                            "name": "Lavaca",
                            "latitude": "35.33620000",
                            "longitude": "-94.17326000"
                        },
                        {
                            "id": 120230,
                            "name": "Lawrence County",
                            "latitude": "36.04125000",
                            "longitude": "-91.10708000"
                        },
                        {
                            "id": 120269,
                            "name": "Leachville",
                            "latitude": "35.93591000",
                            "longitude": "-90.25788000"
                        },
                        {
                            "id": 120307,
                            "name": "Lee County",
                            "latitude": "34.78062000",
                            "longitude": "-90.78207000"
                        },
                        {
                            "id": 120394,
                            "name": "Lepanto",
                            "latitude": "35.61119000",
                            "longitude": "-90.32982000"
                        },
                        {
                            "id": 120434,
                            "name": "Lewisville",
                            "latitude": "33.35846000",
                            "longitude": "-93.57768000"
                        },
                        {
                            "id": 120500,
                            "name": "Lincoln",
                            "latitude": "35.94953000",
                            "longitude": "-94.42355000"
                        },
                        {
                            "id": 120516,
                            "name": "Lincoln County",
                            "latitude": "33.95747000",
                            "longitude": "-91.73332000"
                        },
                        {
                            "id": 120621,
                            "name": "Little Flock",
                            "latitude": "36.38591000",
                            "longitude": "-94.13520000"
                        },
                        {
                            "id": 120624,
                            "name": "Little River County",
                            "latitude": "33.70054000",
                            "longitude": "-94.23434000"
                        },
                        {
                            "id": 120626,
                            "name": "Little Rock",
                            "latitude": "34.74648000",
                            "longitude": "-92.28959000"
                        },
                        {
                            "id": 120628,
                            "name": "Little Rock Air Force Base",
                            "latitude": "34.89149000",
                            "longitude": "-92.15955000"
                        },
                        {
                            "id": 120696,
                            "name": "Logan County",
                            "latitude": "35.21527000",
                            "longitude": "-93.71631000"
                        },
                        {
                            "id": 120720,
                            "name": "London",
                            "latitude": "35.32897000",
                            "longitude": "-93.25296000"
                        },
                        {
                            "id": 120761,
                            "name": "Lonoke",
                            "latitude": "34.78398000",
                            "longitude": "-91.89986000"
                        },
                        {
                            "id": 120762,
                            "name": "Lonoke County",
                            "latitude": "34.75427000",
                            "longitude": "-91.88867000"
                        },
                        {
                            "id": 120833,
                            "name": "Lowell",
                            "latitude": "36.25535000",
                            "longitude": "-94.13076000"
                        },
                        {
                            "id": 120902,
                            "name": "Luxora",
                            "latitude": "35.75619000",
                            "longitude": "-89.92814000"
                        },
                        {
                            "id": 121003,
                            "name": "Madison County",
                            "latitude": "36.01096000",
                            "longitude": "-93.72456000"
                        },
                        {
                            "id": 121035,
                            "name": "Magnolia",
                            "latitude": "33.26707000",
                            "longitude": "-93.23933000"
                        },
                        {
                            "id": 121071,
                            "name": "Malvern",
                            "latitude": "34.36231000",
                            "longitude": "-92.81295000"
                        },
                        {
                            "id": 121119,
                            "name": "Manila",
                            "latitude": "35.88007000",
                            "longitude": "-90.16704000"
                        },
                        {
                            "id": 121142,
                            "name": "Mansfield",
                            "latitude": "35.05954000",
                            "longitude": "-94.25271000"
                        },
                        {
                            "id": 121208,
                            "name": "Marianna",
                            "latitude": "34.77371000",
                            "longitude": "-90.75761000"
                        },
                        {
                            "id": 121232,
                            "name": "Marion",
                            "latitude": "35.21453000",
                            "longitude": "-90.19648000"
                        },
                        {
                            "id": 121248,
                            "name": "Marion County",
                            "latitude": "36.26835000",
                            "longitude": "-92.68422000"
                        },
                        {
                            "id": 121267,
                            "name": "Marked Tree",
                            "latitude": "35.53286000",
                            "longitude": "-90.42066000"
                        },
                        {
                            "id": 121289,
                            "name": "Marmaduke",
                            "latitude": "36.18701000",
                            "longitude": "-90.38316000"
                        },
                        {
                            "id": 121301,
                            "name": "Marshall",
                            "latitude": "35.90896000",
                            "longitude": "-92.63127000"
                        },
                        {
                            "id": 121352,
                            "name": "Marvell",
                            "latitude": "34.55566000",
                            "longitude": "-90.91289000"
                        },
                        {
                            "id": 121421,
                            "name": "Maumelle",
                            "latitude": "34.86676000",
                            "longitude": "-92.40432000"
                        },
                        {
                            "id": 121438,
                            "name": "Mayflower",
                            "latitude": "34.95703000",
                            "longitude": "-92.42738000"
                        },
                        {
                            "id": 121464,
                            "name": "McAlmont",
                            "latitude": "34.80842000",
                            "longitude": "-92.18181000"
                        },
                        {
                            "id": 121487,
                            "name": "McCrory",
                            "latitude": "35.25620000",
                            "longitude": "-91.20012000"
                        },
                        {
                            "id": 121501,
                            "name": "McGehee",
                            "latitude": "33.62900000",
                            "longitude": "-91.39956000"
                        },
                        {
                            "id": 121621,
                            "name": "Melbourne",
                            "latitude": "36.05951000",
                            "longitude": "-91.90848000"
                        },
                        {
                            "id": 121643,
                            "name": "Mena",
                            "latitude": "34.58622000",
                            "longitude": "-94.23966000"
                        },
                        {
                            "id": 121812,
                            "name": "Midway",
                            "latitude": "36.38534000",
                            "longitude": "-92.46183000"
                        },
                        {
                            "id": 121880,
                            "name": "Miller County",
                            "latitude": "33.31215000",
                            "longitude": "-93.89156000"
                        },
                        {
                            "id": 121939,
                            "name": "Mineral Springs",
                            "latitude": "33.87512000",
                            "longitude": "-93.91380000"
                        },
                        {
                            "id": 121989,
                            "name": "Mississippi County",
                            "latitude": "35.76390000",
                            "longitude": "-90.05417000"
                        },
                        {
                            "id": 122040,
                            "name": "Monette",
                            "latitude": "35.89063000",
                            "longitude": "-90.34427000"
                        },
                        {
                            "id": 122068,
                            "name": "Monroe County",
                            "latitude": "34.67784000",
                            "longitude": "-91.20389000"
                        },
                        {
                            "id": 122140,
                            "name": "Montgomery County",
                            "latitude": "34.53879000",
                            "longitude": "-93.65953000"
                        },
                        {
                            "id": 122158,
                            "name": "Monticello",
                            "latitude": "33.62900000",
                            "longitude": "-91.79096000"
                        },
                        {
                            "id": 122264,
                            "name": "Morrilton",
                            "latitude": "35.15092000",
                            "longitude": "-92.74405000"
                        },
                        {
                            "id": 122355,
                            "name": "Mount Ida",
                            "latitude": "34.55676000",
                            "longitude": "-93.63408000"
                        },
                        {
                            "id": 122415,
                            "name": "Mountain Home",
                            "latitude": "36.33534000",
                            "longitude": "-92.38516000"
                        },
                        {
                            "id": 122428,
                            "name": "Mountain View",
                            "latitude": "35.86841000",
                            "longitude": "-92.11765000"
                        },
                        {
                            "id": 122452,
                            "name": "Mulberry",
                            "latitude": "35.50064000",
                            "longitude": "-94.05159000"
                        },
                        {
                            "id": 122480,
                            "name": "Murfreesboro",
                            "latitude": "34.06233000",
                            "longitude": "-93.68990000"
                        },
                        {
                            "id": 122572,
                            "name": "Nashville",
                            "latitude": "33.94567000",
                            "longitude": "-93.84713000"
                        },
                        {
                            "id": 122652,
                            "name": "Nevada County",
                            "latitude": "33.66397000",
                            "longitude": "-93.30714000"
                        },
                        {
                            "id": 122800,
                            "name": "Newark",
                            "latitude": "35.70174000",
                            "longitude": "-91.44152000"
                        },
                        {
                            "id": 122839,
                            "name": "Newport",
                            "latitude": "35.60480000",
                            "longitude": "-91.28180000"
                        },
                        {
                            "id": 122868,
                            "name": "Newton County",
                            "latitude": "35.91997000",
                            "longitude": "-93.21787000"
                        },
                        {
                            "id": 123012,
                            "name": "North Crossett",
                            "latitude": "33.16568000",
                            "longitude": "-91.94152000"
                        },
                        {
                            "id": 123059,
                            "name": "North Little Rock",
                            "latitude": "34.76954000",
                            "longitude": "-92.26709000"
                        },
                        {
                            "id": 123411,
                            "name": "Ola",
                            "latitude": "35.03231000",
                            "longitude": "-93.22323000"
                        },
                        {
                            "id": 123597,
                            "name": "Osceola",
                            "latitude": "35.70508000",
                            "longitude": "-89.96953000"
                        },
                        {
                            "id": 123644,
                            "name": "Ouachita County",
                            "latitude": "33.59336000",
                            "longitude": "-92.88193000"
                        },
                        {
                            "id": 123697,
                            "name": "Ozark",
                            "latitude": "35.48703000",
                            "longitude": "-93.82770000"
                        },
                        {
                            "id": 123823,
                            "name": "Paragould",
                            "latitude": "36.05840000",
                            "longitude": "-90.49733000"
                        },
                        {
                            "id": 123829,
                            "name": "Paris",
                            "latitude": "35.29203000",
                            "longitude": "-93.72992000"
                        },
                        {
                            "id": 123870,
                            "name": "Parkin",
                            "latitude": "35.26342000",
                            "longitude": "-90.57122000"
                        },
                        {
                            "id": 123951,
                            "name": "Pea Ridge",
                            "latitude": "36.45396000",
                            "longitude": "-94.11520000"
                        },
                        {
                            "id": 124075,
                            "name": "Perry County",
                            "latitude": "34.94737000",
                            "longitude": "-92.93147000"
                        },
                        {
                            "id": 124088,
                            "name": "Perryville",
                            "latitude": "35.00481000",
                            "longitude": "-92.80267000"
                        },
                        {
                            "id": 124135,
                            "name": "Phillips County",
                            "latitude": "34.42829000",
                            "longitude": "-90.84802000"
                        },
                        {
                            "id": 124184,
                            "name": "Piggott",
                            "latitude": "36.38284000",
                            "longitude": "-90.19065000"
                        },
                        {
                            "id": 124186,
                            "name": "Pike County",
                            "latitude": "34.16350000",
                            "longitude": "-93.65634000"
                        },
                        {
                            "id": 124214,
                            "name": "Pine Bluff",
                            "latitude": "34.22843000",
                            "longitude": "-92.00320000"
                        },
                        {
                            "id": 124266,
                            "name": "Piney",
                            "latitude": "34.50314000",
                            "longitude": "-93.12602000"
                        },
                        {
                            "id": 124413,
                            "name": "Pocahontas",
                            "latitude": "36.26146000",
                            "longitude": "-90.97123000"
                        },
                        {
                            "id": 124425,
                            "name": "Poinsett County",
                            "latitude": "35.57404000",
                            "longitude": "-90.66293000"
                        },
                        {
                            "id": 124440,
                            "name": "Polk County",
                            "latitude": "34.48584000",
                            "longitude": "-94.22807000"
                        },
                        {
                            "id": 124481,
                            "name": "Pope County",
                            "latitude": "35.44763000",
                            "longitude": "-93.03416000"
                        },
                        {
                            "id": 124600,
                            "name": "Pottsville",
                            "latitude": "35.24814000",
                            "longitude": "-93.04906000"
                        },
                        {
                            "id": 124624,
                            "name": "Prairie County",
                            "latitude": "34.82979000",
                            "longitude": "-91.55277000"
                        },
                        {
                            "id": 124626,
                            "name": "Prairie Creek",
                            "latitude": "36.34202000",
                            "longitude": "-94.06187000"
                        },
                        {
                            "id": 124627,
                            "name": "Prairie Grove",
                            "latitude": "35.97591000",
                            "longitude": "-94.31771000"
                        },
                        {
                            "id": 124642,
                            "name": "Prescott",
                            "latitude": "33.80261000",
                            "longitude": "-93.38101000"
                        },
                        {
                            "id": 124737,
                            "name": "Pulaski County",
                            "latitude": "34.76993000",
                            "longitude": "-92.31180000"
                        },
                        {
                            "id": 124873,
                            "name": "Randolph County",
                            "latitude": "36.34148000",
                            "longitude": "-91.02772000"
                        },
                        {
                            "id": 124935,
                            "name": "Rector",
                            "latitude": "36.26312000",
                            "longitude": "-90.29260000"
                        },
                        {
                            "id": 124967,
                            "name": "Redfield",
                            "latitude": "34.44510000",
                            "longitude": "-92.18320000"
                        },
                        {
                            "id": 125172,
                            "name": "Rison",
                            "latitude": "33.95843000",
                            "longitude": "-92.19015000"
                        },
                        {
                            "id": 125328,
                            "name": "Rockwell",
                            "latitude": "34.46426000",
                            "longitude": "-93.13379000"
                        },
                        {
                            "id": 125348,
                            "name": "Rogers",
                            "latitude": "36.33202000",
                            "longitude": "-94.11854000"
                        },
                        {
                            "id": 125543,
                            "name": "Russellville",
                            "latitude": "35.27842000",
                            "longitude": "-93.13379000"
                        },
                        {
                            "id": 125636,
                            "name": "Saint Francis County",
                            "latitude": "35.02200000",
                            "longitude": "-90.74778000"
                        },
                        {
                            "id": 125726,
                            "name": "Salem",
                            "latitude": "34.62898000",
                            "longitude": "-92.55822000"
                        },
                        {
                            "id": 125749,
                            "name": "Saline County",
                            "latitude": "34.64662000",
                            "longitude": "-92.67657000"
                        },
                        {
                            "id": 126043,
                            "name": "Scott County",
                            "latitude": "34.86077000",
                            "longitude": "-94.06325000"
                        },
                        {
                            "id": 126092,
                            "name": "Searcy",
                            "latitude": "35.25064000",
                            "longitude": "-91.73625000"
                        },
                        {
                            "id": 126093,
                            "name": "Searcy County",
                            "latitude": "35.91090000",
                            "longitude": "-92.69949000"
                        },
                        {
                            "id": 126107,
                            "name": "Sebastian County",
                            "latitude": "35.19926000",
                            "longitude": "-94.27391000"
                        },
                        {
                            "id": 126180,
                            "name": "Sevier County",
                            "latitude": "33.99720000",
                            "longitude": "-94.24122000"
                        },
                        {
                            "id": 126221,
                            "name": "Shannon Hills",
                            "latitude": "34.62009000",
                            "longitude": "-92.39543000"
                        },
                        {
                            "id": 126233,
                            "name": "Sharp County",
                            "latitude": "36.16116000",
                            "longitude": "-91.47986000"
                        },
                        {
                            "id": 126317,
                            "name": "Sheridan",
                            "latitude": "34.30704000",
                            "longitude": "-92.40127000"
                        },
                        {
                            "id": 126340,
                            "name": "Sherwood",
                            "latitude": "34.81509000",
                            "longitude": "-92.22432000"
                        },
                        {
                            "id": 126417,
                            "name": "Siloam Springs",
                            "latitude": "36.18814000",
                            "longitude": "-94.54050000"
                        },
                        {
                            "id": 126508,
                            "name": "Smackover",
                            "latitude": "33.36485000",
                            "longitude": "-92.72488000"
                        },
                        {
                            "id": 126787,
                            "name": "Southside",
                            "latitude": "35.69841000",
                            "longitude": "-91.62347000"
                        },
                        {
                            "id": 126883,
                            "name": "Springdale",
                            "latitude": "36.18674000",
                            "longitude": "-94.12881000"
                        },
                        {
                            "id": 126946,
                            "name": "Stamps",
                            "latitude": "33.36540000",
                            "longitude": "-93.49518000"
                        },
                        {
                            "id": 126979,
                            "name": "Star City",
                            "latitude": "33.94288000",
                            "longitude": "-91.84347000"
                        },
                        {
                            "id": 127080,
                            "name": "Stone County",
                            "latitude": "35.85987000",
                            "longitude": "-92.15668000"
                        },
                        {
                            "id": 127155,
                            "name": "Stuttgart",
                            "latitude": "34.50037000",
                            "longitude": "-91.55263000"
                        },
                        {
                            "id": 127196,
                            "name": "Sulphur Springs",
                            "latitude": "34.18065000",
                            "longitude": "-92.12348000"
                        },
                        {
                            "id": 127544,
                            "name": "Texarkana",
                            "latitude": "33.44179000",
                            "longitude": "-94.03769000"
                        },
                        {
                            "id": 127723,
                            "name": "Tontitown",
                            "latitude": "36.17786000",
                            "longitude": "-94.23354000"
                        },
                        {
                            "id": 127856,
                            "name": "Trumann",
                            "latitude": "35.67369000",
                            "longitude": "-90.50733000"
                        },
                        {
                            "id": 127872,
                            "name": "Tuckerman",
                            "latitude": "35.73063000",
                            "longitude": "-91.19846000"
                        },
                        {
                            "id": 127988,
                            "name": "Union County",
                            "latitude": "33.17136000",
                            "longitude": "-92.59729000"
                        },
                        {
                            "id": 128126,
                            "name": "Van Buren",
                            "latitude": "35.43676000",
                            "longitude": "-94.34827000"
                        },
                        {
                            "id": 128129,
                            "name": "Van Buren County",
                            "latitude": "35.58065000",
                            "longitude": "-92.51570000"
                        },
                        {
                            "id": 128261,
                            "name": "Vilonia",
                            "latitude": "35.08398000",
                            "longitude": "-92.20793000"
                        },
                        {
                            "id": 128370,
                            "name": "Waldo",
                            "latitude": "33.35151000",
                            "longitude": "-93.29573000"
                        },
                        {
                            "id": 128377,
                            "name": "Waldron",
                            "latitude": "34.89843000",
                            "longitude": "-94.09076000"
                        },
                        {
                            "id": 128423,
                            "name": "Walnut Ridge",
                            "latitude": "36.06840000",
                            "longitude": "-90.95595000"
                        },
                        {
                            "id": 128457,
                            "name": "Ward",
                            "latitude": "35.03036000",
                            "longitude": "-91.95042000"
                        },
                        {
                            "id": 128476,
                            "name": "Warren",
                            "latitude": "33.61261000",
                            "longitude": "-92.06458000"
                        },
                        {
                            "id": 128553,
                            "name": "Washington County",
                            "latitude": "35.97907000",
                            "longitude": "-94.21558000"
                        },
                        {
                            "id": 128854,
                            "name": "West Crossett",
                            "latitude": "33.14096000",
                            "longitude": "-91.99402000"
                        },
                        {
                            "id": 128872,
                            "name": "West Fork",
                            "latitude": "35.92425000",
                            "longitude": "-94.18854000"
                        },
                        {
                            "id": 128893,
                            "name": "West Helena",
                            "latitude": "34.55066000",
                            "longitude": "-90.64177000"
                        },
                        {
                            "id": 128928,
                            "name": "West Memphis",
                            "latitude": "35.14648000",
                            "longitude": "-90.18454000"
                        },
                        {
                            "id": 129134,
                            "name": "White County",
                            "latitude": "35.25627000",
                            "longitude": "-91.74555000"
                        },
                        {
                            "id": 129139,
                            "name": "White Hall",
                            "latitude": "34.27399000",
                            "longitude": "-92.09098000"
                        },
                        {
                            "id": 129548,
                            "name": "Woodruff County",
                            "latitude": "35.18633000",
                            "longitude": "-91.24307000"
                        },
                        {
                            "id": 129613,
                            "name": "Wrightsville",
                            "latitude": "34.60232000",
                            "longitude": "-92.21681000"
                        },
                        {
                            "id": 129632,
                            "name": "Wynne",
                            "latitude": "35.22453000",
                            "longitude": "-90.78678000"
                        },
                        {
                            "id": 129677,
                            "name": "Yell County",
                            "latitude": "35.00260000",
                            "longitude": "-93.41125000"
                        },
                        {
                            "id": 129680,
                            "name": "Yellville",
                            "latitude": "36.22618000",
                            "longitude": "-92.68489000"
                        }
                    ]
                },
                {
                    "id": 1402,
                    "name": "Baker Island",
                    "state_code": "UM-81",
                    "cities": []
                },
                {
                    "id": 1416,
                    "name": "California",
                    "state_code": "CA",
                    "cities": [
                        {
                            "id": 110992,
                            "name": "Acalanes Ridge",
                            "latitude": "37.90472000",
                            "longitude": "-122.07857000"
                        },
                        {
                            "id": 111001,
                            "name": "Acton",
                            "latitude": "34.46999000",
                            "longitude": "-118.19674000"
                        },
                        {
                            "id": 111043,
                            "name": "Adelanto",
                            "latitude": "34.58277000",
                            "longitude": "-117.40922000"
                        },
                        {
                            "id": 111056,
                            "name": "Agoura",
                            "latitude": "34.14306000",
                            "longitude": "-118.73787000"
                        },
                        {
                            "id": 111057,
                            "name": "Agoura Hills",
                            "latitude": "34.13639000",
                            "longitude": "-118.77453000"
                        },
                        {
                            "id": 111058,
                            "name": "Agua Dulce",
                            "latitude": "34.49638000",
                            "longitude": "-118.32563000"
                        },
                        {
                            "id": 111061,
                            "name": "Aguanga",
                            "latitude": "33.44281000",
                            "longitude": "-116.86502000"
                        },
                        {
                            "id": 111064,
                            "name": "Ahwahnee",
                            "latitude": "37.36550000",
                            "longitude": "-119.72627000"
                        },
                        {
                            "id": 111088,
                            "name": "Alameda",
                            "latitude": "37.76521000",
                            "longitude": "-122.24164000"
                        },
                        {
                            "id": 111089,
                            "name": "Alameda County",
                            "latitude": "37.65055000",
                            "longitude": "-121.91789000"
                        },
                        {
                            "id": 111093,
                            "name": "Alamo",
                            "latitude": "37.85020000",
                            "longitude": "-122.03218000"
                        },
                        {
                            "id": 111110,
                            "name": "Albany",
                            "latitude": "37.88687000",
                            "longitude": "-122.29775000"
                        },
                        {
                            "id": 111169,
                            "name": "Alhambra",
                            "latitude": "34.09529000",
                            "longitude": "-118.12701000"
                        },
                        {
                            "id": 111175,
                            "name": "Aliso Viejo",
                            "latitude": "33.56504000",
                            "longitude": "-117.72712000"
                        },
                        {
                            "id": 111196,
                            "name": "Allendale",
                            "latitude": "38.44463000",
                            "longitude": "-121.94302000"
                        },
                        {
                            "id": 111215,
                            "name": "Alondra Park",
                            "latitude": "33.88946000",
                            "longitude": "-118.33091000"
                        },
                        {
                            "id": 111217,
                            "name": "Alpaugh",
                            "latitude": "35.88773000",
                            "longitude": "-119.48734000"
                        },
                        {
                            "id": 111223,
                            "name": "Alpine",
                            "latitude": "32.83505000",
                            "longitude": "-116.76641000"
                        },
                        {
                            "id": 111226,
                            "name": "Alpine County",
                            "latitude": "38.59725000",
                            "longitude": "-119.82065000"
                        },
                        {
                            "id": 111230,
                            "name": "Alta Sierra",
                            "latitude": "35.73126000",
                            "longitude": "-118.55390000"
                        },
                        {
                            "id": 111231,
                            "name": "Altadena",
                            "latitude": "34.18973000",
                            "longitude": "-118.13118000"
                        },
                        {
                            "id": 111251,
                            "name": "Alturas",
                            "latitude": "41.48714000",
                            "longitude": "-120.54349000"
                        },
                        {
                            "id": 111254,
                            "name": "Alum Rock",
                            "latitude": "37.36605000",
                            "longitude": "-121.82718000"
                        },
                        {
                            "id": 111261,
                            "name": "Amador County",
                            "latitude": "38.44639000",
                            "longitude": "-120.65112000"
                        },
                        {
                            "id": 111271,
                            "name": "American Canyon",
                            "latitude": "38.17492000",
                            "longitude": "-122.26080000"
                        },
                        {
                            "id": 111280,
                            "name": "Amesti",
                            "latitude": "36.96356000",
                            "longitude": "-121.77912000"
                        },
                        {
                            "id": 111299,
                            "name": "Anaheim",
                            "latitude": "33.83529000",
                            "longitude": "-117.91450000"
                        },
                        {
                            "id": 111314,
                            "name": "Anderson",
                            "latitude": "40.44821000",
                            "longitude": "-122.29778000"
                        },
                        {
                            "id": 111337,
                            "name": "Angels Camp",
                            "latitude": "38.06826000",
                            "longitude": "-120.53965000"
                        },
                        {
                            "id": 111343,
                            "name": "Angwin",
                            "latitude": "38.57574000",
                            "longitude": "-122.44998000"
                        },
                        {
                            "id": 111364,
                            "name": "Antelope",
                            "latitude": "38.70824000",
                            "longitude": "-121.32995000"
                        },
                        {
                            "id": 111373,
                            "name": "Antioch",
                            "latitude": "38.00492000",
                            "longitude": "-121.80579000"
                        },
                        {
                            "id": 111378,
                            "name": "Anza",
                            "latitude": "33.55503000",
                            "longitude": "-116.67363000"
                        },
                        {
                            "id": 111394,
                            "name": "Apple Valley",
                            "latitude": "34.50083000",
                            "longitude": "-117.18588000"
                        },
                        {
                            "id": 111403,
                            "name": "Aptos",
                            "latitude": "36.97717000",
                            "longitude": "-121.89940000"
                        },
                        {
                            "id": 111404,
                            "name": "Aptos Hills-Larkin Valley",
                            "latitude": "36.96064000",
                            "longitude": "-121.83404000"
                        },
                        {
                            "id": 111416,
                            "name": "Arbuckle",
                            "latitude": "39.01740000",
                            "longitude": "-122.05775000"
                        },
                        {
                            "id": 111425,
                            "name": "Arcadia",
                            "latitude": "34.13973000",
                            "longitude": "-118.03534000"
                        },
                        {
                            "id": 111426,
                            "name": "Arcata",
                            "latitude": "40.86652000",
                            "longitude": "-124.08284000"
                        },
                        {
                            "id": 111440,
                            "name": "Arden-Arcade",
                            "latitude": "38.60250000",
                            "longitude": "-121.37854000"
                        },
                        {
                            "id": 111477,
                            "name": "Armona",
                            "latitude": "36.31578000",
                            "longitude": "-119.70846000"
                        },
                        {
                            "id": 111488,
                            "name": "Arnold",
                            "latitude": "38.25547000",
                            "longitude": "-120.35103000"
                        },
                        {
                            "id": 111491,
                            "name": "Aromas",
                            "latitude": "36.88856000",
                            "longitude": "-121.64300000"
                        },
                        {
                            "id": 111494,
                            "name": "Arroyo Grande",
                            "latitude": "35.11859000",
                            "longitude": "-120.59073000"
                        },
                        {
                            "id": 111496,
                            "name": "Artesia",
                            "latitude": "33.86585000",
                            "longitude": "-118.08312000"
                        },
                        {
                            "id": 111505,
                            "name": "Arvin",
                            "latitude": "35.20913000",
                            "longitude": "-118.82843000"
                        },
                        {
                            "id": 111538,
                            "name": "Ashland",
                            "latitude": "37.69465000",
                            "longitude": "-122.11385000"
                        },
                        {
                            "id": 111564,
                            "name": "Atascadero",
                            "latitude": "35.48942000",
                            "longitude": "-120.67073000"
                        },
                        {
                            "id": 111582,
                            "name": "Atherton",
                            "latitude": "37.46133000",
                            "longitude": "-122.19774000"
                        },
                        {
                            "id": 111613,
                            "name": "Atwater",
                            "latitude": "37.34772000",
                            "longitude": "-120.60908000"
                        },
                        {
                            "id": 111617,
                            "name": "Auberry",
                            "latitude": "37.08078000",
                            "longitude": "-119.48541000"
                        },
                        {
                            "id": 111631,
                            "name": "Auburn",
                            "latitude": "38.89657000",
                            "longitude": "-121.07689000"
                        },
                        {
                            "id": 111635,
                            "name": "Auburn Lake Trails",
                            "latitude": "38.91434000",
                            "longitude": "-120.95244000"
                        },
                        {
                            "id": 111644,
                            "name": "August",
                            "latitude": "37.97881000",
                            "longitude": "-121.26217000"
                        },
                        {
                            "id": 111675,
                            "name": "Avalon",
                            "latitude": "33.34281000",
                            "longitude": "-118.32785000"
                        },
                        {
                            "id": 111676,
                            "name": "Avenal",
                            "latitude": "36.00412000",
                            "longitude": "-120.12903000"
                        },
                        {
                            "id": 111683,
                            "name": "Avila Beach",
                            "latitude": "35.17998000",
                            "longitude": "-120.73184000"
                        },
                        {
                            "id": 111689,
                            "name": "Avocado Heights",
                            "latitude": "34.03612000",
                            "longitude": "-117.99118000"
                        },
                        {
                            "id": 111713,
                            "name": "Azusa",
                            "latitude": "34.13362000",
                            "longitude": "-117.90756000"
                        },
                        {
                            "id": 111744,
                            "name": "Bakersfield",
                            "latitude": "35.37329000",
                            "longitude": "-119.01871000"
                        },
                        {
                            "id": 111763,
                            "name": "Baldwin Park",
                            "latitude": "34.08529000",
                            "longitude": "-117.96090000"
                        },
                        {
                            "id": 111803,
                            "name": "Banning",
                            "latitude": "33.92557000",
                            "longitude": "-116.87641000"
                        },
                        {
                            "id": 111857,
                            "name": "Barstow",
                            "latitude": "34.89859000",
                            "longitude": "-117.02282000"
                        },
                        {
                            "id": 111858,
                            "name": "Barstow Heights",
                            "latitude": "34.86971000",
                            "longitude": "-117.05615000"
                        },
                        {
                            "id": 111932,
                            "name": "Bay Point",
                            "latitude": "38.02909000",
                            "longitude": "-121.96163000"
                        },
                        {
                            "id": 111957,
                            "name": "Bayside",
                            "latitude": "40.84235000",
                            "longitude": "-124.06367000"
                        },
                        {
                            "id": 111959,
                            "name": "Bayview",
                            "latitude": "40.77263000",
                            "longitude": "-124.18395000"
                        },
                        {
                            "id": 111971,
                            "name": "Beale Air Force Base",
                            "latitude": "39.10917000",
                            "longitude": "-121.35444000"
                        },
                        {
                            "id": 111979,
                            "name": "Bear Valley Springs",
                            "latitude": "35.15913000",
                            "longitude": "-118.62842000"
                        },
                        {
                            "id": 111990,
                            "name": "Beaumont",
                            "latitude": "33.92946000",
                            "longitude": "-116.97725000"
                        },
                        {
                            "id": 112066,
                            "name": "Bell",
                            "latitude": "33.97751000",
                            "longitude": "-118.18702000"
                        },
                        {
                            "id": 112070,
                            "name": "Bell Gardens",
                            "latitude": "33.96529000",
                            "longitude": "-118.15146000"
                        },
                        {
                            "id": 112072,
                            "name": "Bella Vista",
                            "latitude": "40.64071000",
                            "longitude": "-122.23250000"
                        },
                        {
                            "id": 112118,
                            "name": "Bellflower",
                            "latitude": "33.88168000",
                            "longitude": "-118.11701000"
                        },
                        {
                            "id": 112142,
                            "name": "Belmont",
                            "latitude": "37.52021000",
                            "longitude": "-122.27580000"
                        },
                        {
                            "id": 112153,
                            "name": "Belvedere",
                            "latitude": "37.87270000",
                            "longitude": "-122.46442000"
                        },
                        {
                            "id": 112163,
                            "name": "Ben Lomond",
                            "latitude": "37.08911000",
                            "longitude": "-122.08635000"
                        },
                        {
                            "id": 112168,
                            "name": "Benicia",
                            "latitude": "38.04937000",
                            "longitude": "-122.15858000"
                        },
                        {
                            "id": 112223,
                            "name": "Berkeley",
                            "latitude": "37.87159000",
                            "longitude": "-122.27275000"
                        },
                        {
                            "id": 112240,
                            "name": "Bermuda Dunes",
                            "latitude": "33.74280000",
                            "longitude": "-116.28918000"
                        },
                        {
                            "id": 112253,
                            "name": "Berry Creek",
                            "latitude": "39.64516000",
                            "longitude": "-121.40330000"
                        },
                        {
                            "id": 112260,
                            "name": "Bertsch-Oceanview",
                            "latitude": "41.75250000",
                            "longitude": "-124.15875000"
                        },
                        {
                            "id": 112286,
                            "name": "Bethel Island",
                            "latitude": "38.01492000",
                            "longitude": "-121.64051000"
                        },
                        {
                            "id": 112306,
                            "name": "Beverly Hills",
                            "latitude": "34.07362000",
                            "longitude": "-118.40036000"
                        },
                        {
                            "id": 112315,
                            "name": "Big Bear City",
                            "latitude": "34.26112000",
                            "longitude": "-116.84503000"
                        },
                        {
                            "id": 112316,
                            "name": "Big Bear Lake",
                            "latitude": "34.24390000",
                            "longitude": "-116.91142000"
                        },
                        {
                            "id": 112328,
                            "name": "Big Pine",
                            "latitude": "37.16493000",
                            "longitude": "-118.28955000"
                        },
                        {
                            "id": 112331,
                            "name": "Big River",
                            "latitude": "34.14002000",
                            "longitude": "-114.36134000"
                        },
                        {
                            "id": 112340,
                            "name": "Biggs",
                            "latitude": "39.41239000",
                            "longitude": "-121.71275000"
                        },
                        {
                            "id": 112352,
                            "name": "Biola",
                            "latitude": "36.80217000",
                            "longitude": "-120.01627000"
                        },
                        {
                            "id": 112364,
                            "name": "Bishop",
                            "latitude": "37.36354000",
                            "longitude": "-118.39511000"
                        },
                        {
                            "id": 112381,
                            "name": "Black Point-Green Point",
                            "latitude": "38.11547000",
                            "longitude": "-122.51318000"
                        },
                        {
                            "id": 112387,
                            "name": "Blackhawk",
                            "latitude": "37.82076000",
                            "longitude": "-121.90774000"
                        },
                        {
                            "id": 112453,
                            "name": "Bloomington",
                            "latitude": "34.07029000",
                            "longitude": "-117.39588000"
                        },
                        {
                            "id": 112470,
                            "name": "Blue Lake",
                            "latitude": "40.88291000",
                            "longitude": "-123.98395000"
                        },
                        {
                            "id": 112485,
                            "name": "Blythe",
                            "latitude": "33.61030000",
                            "longitude": "-114.59635000"
                        },
                        {
                            "id": 112495,
                            "name": "Bodega Bay",
                            "latitude": "38.33325000",
                            "longitude": "-123.04806000"
                        },
                        {
                            "id": 112496,
                            "name": "Bodfish",
                            "latitude": "35.58801000",
                            "longitude": "-118.49203000"
                        },
                        {
                            "id": 112514,
                            "name": "Bolinas",
                            "latitude": "37.90937000",
                            "longitude": "-122.68637000"
                        },
                        {
                            "id": 112529,
                            "name": "Bonadelle Ranchos-Madera Ranchos",
                            "latitude": "36.98467000",
                            "longitude": "-119.87463000"
                        },
                        {
                            "id": 112537,
                            "name": "Bonita",
                            "latitude": "32.65783000",
                            "longitude": "-117.03003000"
                        },
                        {
                            "id": 112548,
                            "name": "Bonny Doon",
                            "latitude": "37.04162000",
                            "longitude": "-122.15052000"
                        },
                        {
                            "id": 112550,
                            "name": "Bonsall",
                            "latitude": "33.28892000",
                            "longitude": "-117.22559000"
                        },
                        {
                            "id": 112571,
                            "name": "Boonville",
                            "latitude": "39.00907000",
                            "longitude": "-123.36612000"
                        },
                        {
                            "id": 112578,
                            "name": "Boron",
                            "latitude": "34.99942000",
                            "longitude": "-117.64978000"
                        },
                        {
                            "id": 112579,
                            "name": "Boronda",
                            "latitude": "36.69885000",
                            "longitude": "-121.67495000"
                        },
                        {
                            "id": 112581,
                            "name": "Borrego Springs",
                            "latitude": "33.25587000",
                            "longitude": "-116.37501000"
                        },
                        {
                            "id": 112591,
                            "name": "Bostonia",
                            "latitude": "32.80755000",
                            "longitude": "-116.93642000"
                        },
                        {
                            "id": 112604,
                            "name": "Boulder Creek",
                            "latitude": "37.12606000",
                            "longitude": "-122.12219000"
                        },
                        {
                            "id": 112646,
                            "name": "Boyes Hot Springs",
                            "latitude": "38.31380000",
                            "longitude": "-122.48193000"
                        },
                        {
                            "id": 112649,
                            "name": "Boyle Heights",
                            "latitude": "34.03390000",
                            "longitude": "-118.20535000"
                        },
                        {
                            "id": 112658,
                            "name": "Bradbury",
                            "latitude": "34.14695000",
                            "longitude": "-117.97090000"
                        },
                        {
                            "id": 112700,
                            "name": "Brawley",
                            "latitude": "32.97866000",
                            "longitude": "-115.53027000"
                        },
                        {
                            "id": 112707,
                            "name": "Brea",
                            "latitude": "33.91668000",
                            "longitude": "-117.90006000"
                        },
                        {
                            "id": 112733,
                            "name": "Brentwood",
                            "latitude": "37.93187000",
                            "longitude": "-121.69579000"
                        },
                        {
                            "id": 112736,
                            "name": "Bret Harte",
                            "latitude": "37.60207000",
                            "longitude": "-121.00519000"
                        },
                        {
                            "id": 112765,
                            "name": "Bridgeport",
                            "latitude": "38.25575000",
                            "longitude": "-119.23127000"
                        },
                        {
                            "id": 112800,
                            "name": "Brisbane",
                            "latitude": "37.68077000",
                            "longitude": "-122.39997000"
                        },
                        {
                            "id": 112825,
                            "name": "Broadmoor",
                            "latitude": "37.68660000",
                            "longitude": "-122.48275000"
                        },
                        {
                            "id": 112851,
                            "name": "Brookdale",
                            "latitude": "37.10634000",
                            "longitude": "-122.10608000"
                        },
                        {
                            "id": 112893,
                            "name": "Brooktrails",
                            "latitude": "39.44377000",
                            "longitude": "-123.38529000"
                        },
                        {
                            "id": 112983,
                            "name": "Buckhorn",
                            "latitude": "38.45216000",
                            "longitude": "-120.52854000"
                        },
                        {
                            "id": 112997,
                            "name": "Buellton",
                            "latitude": "34.61360000",
                            "longitude": "-120.19265000"
                        },
                        {
                            "id": 112999,
                            "name": "Buena Park",
                            "latitude": "33.86751000",
                            "longitude": "-117.99812000"
                        },
                        {
                            "id": 113003,
                            "name": "Buena Vista",
                            "latitude": "37.32133000",
                            "longitude": "-121.91662000"
                        },
                        {
                            "id": 113047,
                            "name": "Burbank",
                            "latitude": "34.18084000",
                            "longitude": "-118.30897000"
                        },
                        {
                            "id": 113065,
                            "name": "Burlingame",
                            "latitude": "37.58410000",
                            "longitude": "-122.36608000"
                        },
                        {
                            "id": 113082,
                            "name": "Burney",
                            "latitude": "40.88238000",
                            "longitude": "-121.66082000"
                        },
                        {
                            "id": 113125,
                            "name": "Butte County",
                            "latitude": "39.66693000",
                            "longitude": "-121.60067000"
                        },
                        {
                            "id": 113129,
                            "name": "Buttonwillow",
                            "latitude": "35.40052000",
                            "longitude": "-119.46956000"
                        },
                        {
                            "id": 113146,
                            "name": "Byron",
                            "latitude": "37.86715000",
                            "longitude": "-121.63801000"
                        },
                        {
                            "id": 113148,
                            "name": "Bystrom",
                            "latitude": "37.62076000",
                            "longitude": "-120.98577000"
                        },
                        {
                            "id": 113150,
                            "name": "Cabazon",
                            "latitude": "33.91752000",
                            "longitude": "-116.78724000"
                        },
                        {
                            "id": 113173,
                            "name": "Calabasas",
                            "latitude": "34.15778000",
                            "longitude": "-118.63842000"
                        },
                        {
                            "id": 113176,
                            "name": "Calaveras County",
                            "latitude": "38.20461000",
                            "longitude": "-120.55413000"
                        },
                        {
                            "id": 113196,
                            "name": "Calexico",
                            "latitude": "32.67895000",
                            "longitude": "-115.49888000"
                        },
                        {
                            "id": 113218,
                            "name": "California City",
                            "latitude": "35.12580000",
                            "longitude": "-117.98590000"
                        },
                        {
                            "id": 113219,
                            "name": "Calimesa",
                            "latitude": "34.00390000",
                            "longitude": "-117.06198000"
                        },
                        {
                            "id": 113220,
                            "name": "Calipatria",
                            "latitude": "33.12560000",
                            "longitude": "-115.51415000"
                        },
                        {
                            "id": 113221,
                            "name": "Calistoga",
                            "latitude": "38.57880000",
                            "longitude": "-122.57971000"
                        },
                        {
                            "id": 113226,
                            "name": "Callender",
                            "latitude": "35.05303000",
                            "longitude": "-120.59628000"
                        },
                        {
                            "id": 113242,
                            "name": "Camarillo",
                            "latitude": "34.21639000",
                            "longitude": "-119.03760000"
                        },
                        {
                            "id": 113246,
                            "name": "Cambria",
                            "latitude": "35.56414000",
                            "longitude": "-121.08075000"
                        },
                        {
                            "id": 113249,
                            "name": "Cambrian Park",
                            "latitude": "37.25689000",
                            "longitude": "-121.93079000"
                        },
                        {
                            "id": 113281,
                            "name": "Cameron Park",
                            "latitude": "38.66879000",
                            "longitude": "-120.98716000"
                        },
                        {
                            "id": 113285,
                            "name": "Camino",
                            "latitude": "38.73824000",
                            "longitude": "-120.67493000"
                        },
                        {
                            "id": 113289,
                            "name": "Camp Meeker",
                            "latitude": "38.42519000",
                            "longitude": "-122.95944000"
                        },
                        {
                            "id": 113290,
                            "name": "Camp Pendleton North",
                            "latitude": "33.31465000",
                            "longitude": "-117.31603000"
                        },
                        {
                            "id": 113291,
                            "name": "Camp Pendleton South",
                            "latitude": "33.22844000",
                            "longitude": "-117.37929000"
                        },
                        {
                            "id": 113298,
                            "name": "Campbell",
                            "latitude": "37.28717000",
                            "longitude": "-121.94996000"
                        },
                        {
                            "id": 113308,
                            "name": "Campo",
                            "latitude": "32.60645000",
                            "longitude": "-116.46891000"
                        },
                        {
                            "id": 113335,
                            "name": "Canoga Park",
                            "latitude": "34.20112000",
                            "longitude": "-118.59814000"
                        },
                        {
                            "id": 113359,
                            "name": "Canyon Lake",
                            "latitude": "33.68502000",
                            "longitude": "-117.27309000"
                        },
                        {
                            "id": 113376,
                            "name": "Capitola",
                            "latitude": "36.97523000",
                            "longitude": "-121.95329000"
                        },
                        {
                            "id": 113408,
                            "name": "Carlsbad",
                            "latitude": "33.15809000",
                            "longitude": "-117.35059000"
                        },
                        {
                            "id": 113420,
                            "name": "Carmel Valley Village",
                            "latitude": "36.50605000",
                            "longitude": "-121.76594000"
                        },
                        {
                            "id": 113421,
                            "name": "Carmel-by-the-Sea",
                            "latitude": "36.55524000",
                            "longitude": "-121.92329000"
                        },
                        {
                            "id": 113423,
                            "name": "Carmichael",
                            "latitude": "38.61713000",
                            "longitude": "-121.32828000"
                        },
                        {
                            "id": 113440,
                            "name": "Carpinteria",
                            "latitude": "34.39888000",
                            "longitude": "-119.51846000"
                        },
                        {
                            "id": 113474,
                            "name": "Carson",
                            "latitude": "33.83141000",
                            "longitude": "-118.28202000"
                        },
                        {
                            "id": 113498,
                            "name": "Caruthers",
                            "latitude": "36.54273000",
                            "longitude": "-119.83320000"
                        },
                        {
                            "id": 113509,
                            "name": "Casa Conejo",
                            "latitude": "34.18362000",
                            "longitude": "-118.94343000"
                        },
                        {
                            "id": 113511,
                            "name": "Casa de Oro-Mount Helix",
                            "latitude": "32.76397000",
                            "longitude": "-116.96877000"
                        },
                        {
                            "id": 113540,
                            "name": "Castaic",
                            "latitude": "34.48888000",
                            "longitude": "-118.62287000"
                        },
                        {
                            "id": 113557,
                            "name": "Castro Valley",
                            "latitude": "37.69410000",
                            "longitude": "-122.08635000"
                        },
                        {
                            "id": 113559,
                            "name": "Castroville",
                            "latitude": "36.76579000",
                            "longitude": "-121.75800000"
                        },
                        {
                            "id": 113570,
                            "name": "Cathedral City",
                            "latitude": "33.77974000",
                            "longitude": "-116.46529000"
                        },
                        {
                            "id": 113592,
                            "name": "Cayucos",
                            "latitude": "35.44275000",
                            "longitude": "-120.89213000"
                        },
                        {
                            "id": 113622,
                            "name": "Cedar Ridge",
                            "latitude": "38.06576000",
                            "longitude": "-120.27686000"
                        },
                        {
                            "id": 113683,
                            "name": "Central Valley (historical)",
                            "latitude": "40.68043000",
                            "longitude": "-122.37112000"
                        },
                        {
                            "id": 113698,
                            "name": "Century City",
                            "latitude": "34.05557000",
                            "longitude": "-118.41786000"
                        },
                        {
                            "id": 113700,
                            "name": "Ceres",
                            "latitude": "37.59493000",
                            "longitude": "-120.95771000"
                        },
                        {
                            "id": 113701,
                            "name": "Cerritos",
                            "latitude": "33.85835000",
                            "longitude": "-118.06479000"
                        },
                        {
                            "id": 113714,
                            "name": "Challenge-Brownsville",
                            "latitude": "39.46447000",
                            "longitude": "-121.26338000"
                        },
                        {
                            "id": 113734,
                            "name": "Channel Islands Beach",
                            "latitude": "34.15806000",
                            "longitude": "-119.22316000"
                        },
                        {
                            "id": 113790,
                            "name": "Charter Oak",
                            "latitude": "34.10306000",
                            "longitude": "-117.84589000"
                        },
                        {
                            "id": 113807,
                            "name": "Chatsworth",
                            "latitude": "34.25723000",
                            "longitude": "-118.60120000"
                        },
                        {
                            "id": 113865,
                            "name": "Cherry Valley",
                            "latitude": "33.97252000",
                            "longitude": "-116.97725000"
                        },
                        {
                            "id": 113867,
                            "name": "Cherryland",
                            "latitude": "37.67938000",
                            "longitude": "-122.10330000"
                        },
                        {
                            "id": 113892,
                            "name": "Chester",
                            "latitude": "40.30627000",
                            "longitude": "-121.23191000"
                        },
                        {
                            "id": 113943,
                            "name": "Chico",
                            "latitude": "39.72849000",
                            "longitude": "-121.83748000"
                        },
                        {
                            "id": 113963,
                            "name": "China Lake Acres",
                            "latitude": "35.64051000",
                            "longitude": "-117.76395000"
                        },
                        {
                            "id": 113964,
                            "name": "Chinatown",
                            "latitude": "37.79660000",
                            "longitude": "-122.40858000"
                        },
                        {
                            "id": 113968,
                            "name": "Chino",
                            "latitude": "34.01223000",
                            "longitude": "-117.68894000"
                        },
                        {
                            "id": 113969,
                            "name": "Chino Hills",
                            "latitude": "33.99380000",
                            "longitude": "-117.75888000"
                        },
                        {
                            "id": 113993,
                            "name": "Chowchilla",
                            "latitude": "37.12300000",
                            "longitude": "-120.26018000"
                        },
                        {
                            "id": 114003,
                            "name": "Chualar",
                            "latitude": "36.57052000",
                            "longitude": "-121.51855000"
                        },
                        {
                            "id": 114005,
                            "name": "Chula Vista",
                            "latitude": "32.64005000",
                            "longitude": "-117.08420000"
                        },
                        {
                            "id": 114034,
                            "name": "Citrus",
                            "latitude": "34.11501000",
                            "longitude": "-117.89173000"
                        },
                        {
                            "id": 114037,
                            "name": "Citrus Heights",
                            "latitude": "38.70712000",
                            "longitude": "-121.28106000"
                        },
                        {
                            "id": 114046,
                            "name": "City and County of San Francisco",
                            "latitude": "37.77823000",
                            "longitude": "-122.44250000"
                        },
                        {
                            "id": 114106,
                            "name": "Claremont",
                            "latitude": "34.09668000",
                            "longitude": "-117.71978000"
                        },
                        {
                            "id": 114169,
                            "name": "Clay",
                            "latitude": "38.33602000",
                            "longitude": "-121.15939000"
                        },
                        {
                            "id": 114203,
                            "name": "Clayton",
                            "latitude": "37.94103000",
                            "longitude": "-121.93579000"
                        },
                        {
                            "id": 114213,
                            "name": "Clear Lake Riviera",
                            "latitude": "38.95406000",
                            "longitude": "-122.72082000"
                        },
                        {
                            "id": 114219,
                            "name": "Clearlake",
                            "latitude": "38.95823000",
                            "longitude": "-122.62637000"
                        },
                        {
                            "id": 114220,
                            "name": "Clearlake Oaks",
                            "latitude": "39.02628000",
                            "longitude": "-122.67193000"
                        },
                        {
                            "id": 114311,
                            "name": "Cloverdale",
                            "latitude": "38.80546000",
                            "longitude": "-123.01722000"
                        },
                        {
                            "id": 114315,
                            "name": "Clovis",
                            "latitude": "36.82523000",
                            "longitude": "-119.70292000"
                        },
                        {
                            "id": 114324,
                            "name": "Coachella",
                            "latitude": "33.68030000",
                            "longitude": "-116.17389000"
                        },
                        {
                            "id": 114338,
                            "name": "Coalinga",
                            "latitude": "36.13968000",
                            "longitude": "-120.36015000"
                        },
                        {
                            "id": 114340,
                            "name": "Coarsegold",
                            "latitude": "37.26217000",
                            "longitude": "-119.70098000"
                        },
                        {
                            "id": 114343,
                            "name": "Cobb",
                            "latitude": "38.82213000",
                            "longitude": "-122.72305000"
                        },
                        {
                            "id": 114408,
                            "name": "Colfax",
                            "latitude": "39.10073000",
                            "longitude": "-120.95328000"
                        },
                        {
                            "id": 114425,
                            "name": "Collierville",
                            "latitude": "38.21464000",
                            "longitude": "-121.26884000"
                        },
                        {
                            "id": 114440,
                            "name": "Colma",
                            "latitude": "37.67688000",
                            "longitude": "-122.45969000"
                        },
                        {
                            "id": 114463,
                            "name": "Colton",
                            "latitude": "34.07390000",
                            "longitude": "-117.31365000"
                        },
                        {
                            "id": 114474,
                            "name": "Columbia",
                            "latitude": "38.03631000",
                            "longitude": "-120.40131000"
                        },
                        {
                            "id": 114504,
                            "name": "Colusa",
                            "latitude": "39.21433000",
                            "longitude": "-122.00942000"
                        },
                        {
                            "id": 114505,
                            "name": "Colusa County",
                            "latitude": "39.17757000",
                            "longitude": "-122.23703000"
                        },
                        {
                            "id": 114525,
                            "name": "Commerce",
                            "latitude": "34.00057000",
                            "longitude": "-118.15979000"
                        },
                        {
                            "id": 114529,
                            "name": "Compton",
                            "latitude": "33.89585000",
                            "longitude": "-118.22007000"
                        },
                        {
                            "id": 114543,
                            "name": "Concord",
                            "latitude": "37.97798000",
                            "longitude": "-122.03107000"
                        },
                        {
                            "id": 114575,
                            "name": "Contra Costa Centre",
                            "latitude": "37.92752000",
                            "longitude": "-122.05786000"
                        },
                        {
                            "id": 114576,
                            "name": "Contra Costa County",
                            "latitude": "37.92342000",
                            "longitude": "-121.95121000"
                        },
                        {
                            "id": 114596,
                            "name": "Cool",
                            "latitude": "38.88722000",
                            "longitude": "-121.01472000"
                        },
                        {
                            "id": 114622,
                            "name": "Copperopolis",
                            "latitude": "37.98104000",
                            "longitude": "-120.64187000"
                        },
                        {
                            "id": 114633,
                            "name": "Corcoran",
                            "latitude": "36.09801000",
                            "longitude": "-119.56040000"
                        },
                        {
                            "id": 114654,
                            "name": "Corning",
                            "latitude": "39.92766000",
                            "longitude": "-122.17916000"
                        },
                        {
                            "id": 114662,
                            "name": "Corona",
                            "latitude": "33.87529000",
                            "longitude": "-117.56644000"
                        },
                        {
                            "id": 114664,
                            "name": "Coronado",
                            "latitude": "32.68589000",
                            "longitude": "-117.18309000"
                        },
                        {
                            "id": 114667,
                            "name": "Corralitos",
                            "latitude": "36.98856000",
                            "longitude": "-121.80634000"
                        },
                        {
                            "id": 114672,
                            "name": "Corte Madera",
                            "latitude": "37.92548000",
                            "longitude": "-122.52748000"
                        },
                        {
                            "id": 114686,
                            "name": "Costa Mesa",
                            "latitude": "33.64113000",
                            "longitude": "-117.91867000"
                        },
                        {
                            "id": 114688,
                            "name": "Cotati",
                            "latitude": "38.32686000",
                            "longitude": "-122.70721000"
                        },
                        {
                            "id": 114689,
                            "name": "Coto De Caza",
                            "latitude": "33.60419000",
                            "longitude": "-117.58699000"
                        },
                        {
                            "id": 114702,
                            "name": "Cottonwood",
                            "latitude": "40.38571000",
                            "longitude": "-122.28084000"
                        },
                        {
                            "id": 114715,
                            "name": "Country Club",
                            "latitude": "37.96881000",
                            "longitude": "-121.34078000"
                        },
                        {
                            "id": 114731,
                            "name": "Covelo",
                            "latitude": "39.79327000",
                            "longitude": "-123.24922000"
                        },
                        {
                            "id": 114734,
                            "name": "Covina",
                            "latitude": "34.09001000",
                            "longitude": "-117.89034000"
                        },
                        {
                            "id": 114796,
                            "name": "Crescent City",
                            "latitude": "41.75595000",
                            "longitude": "-124.20175000"
                        },
                        {
                            "id": 114802,
                            "name": "Crest",
                            "latitude": "32.80727000",
                            "longitude": "-116.86808000"
                        },
                        {
                            "id": 114805,
                            "name": "Crestline",
                            "latitude": "34.24195000",
                            "longitude": "-117.28560000"
                        },
                        {
                            "id": 114830,
                            "name": "Crockett",
                            "latitude": "38.05242000",
                            "longitude": "-122.21302000"
                        },
                        {
                            "id": 114890,
                            "name": "Cudahy",
                            "latitude": "33.96057000",
                            "longitude": "-118.18535000"
                        },
                        {
                            "id": 114904,
                            "name": "Culver City",
                            "latitude": "34.02112000",
                            "longitude": "-118.39647000"
                        },
                        {
                            "id": 114924,
                            "name": "Cupertino",
                            "latitude": "37.32300000",
                            "longitude": "-122.03218000"
                        },
                        {
                            "id": 114947,
                            "name": "Cutler",
                            "latitude": "36.52328000",
                            "longitude": "-119.28678000"
                        },
                        {
                            "id": 114951,
                            "name": "Cutten",
                            "latitude": "40.76985000",
                            "longitude": "-124.14284000"
                        },
                        {
                            "id": 114954,
                            "name": "Cypress",
                            "latitude": "33.81696000",
                            "longitude": "-118.03729000"
                        },
                        {
                            "id": 115006,
                            "name": "Daly City",
                            "latitude": "37.70577000",
                            "longitude": "-122.46192000"
                        },
                        {
                            "id": 115012,
                            "name": "Dana Point",
                            "latitude": "33.46697000",
                            "longitude": "-117.69811000"
                        },
                        {
                            "id": 115038,
                            "name": "Danville",
                            "latitude": "37.82159000",
                            "longitude": "-121.99996000"
                        },
                        {
                            "id": 115073,
                            "name": "Davis",
                            "latitude": "38.54491000",
                            "longitude": "-121.74052000"
                        },
                        {
                            "id": 115090,
                            "name": "Day Valley",
                            "latitude": "37.03578000",
                            "longitude": "-121.86246000"
                        },
                        {
                            "id": 115178,
                            "name": "Deer Park",
                            "latitude": "38.68185000",
                            "longitude": "-120.82327000"
                        },
                        {
                            "id": 115186,
                            "name": "Del Aire",
                            "latitude": "33.91613000",
                            "longitude": "-118.36952000"
                        },
                        {
                            "id": 115188,
                            "name": "Del Mar",
                            "latitude": "32.95949000",
                            "longitude": "-117.26531000"
                        },
                        {
                            "id": 115189,
                            "name": "Del Monte Forest",
                            "latitude": "36.58635000",
                            "longitude": "-121.94746000"
                        },
                        {
                            "id": 115191,
                            "name": "Del Norte County",
                            "latitude": "41.74496000",
                            "longitude": "-123.95781000"
                        },
                        {
                            "id": 115192,
                            "name": "Del Rey",
                            "latitude": "36.65912000",
                            "longitude": "-119.59374000"
                        },
                        {
                            "id": 115193,
                            "name": "Del Rey Oaks",
                            "latitude": "36.59329000",
                            "longitude": "-121.83495000"
                        },
                        {
                            "id": 115194,
                            "name": "Del Rio",
                            "latitude": "37.74354000",
                            "longitude": "-121.01188000"
                        },
                        {
                            "id": 115199,
                            "name": "Delano",
                            "latitude": "35.76884000",
                            "longitude": "-119.24705000"
                        },
                        {
                            "id": 115213,
                            "name": "Delhi",
                            "latitude": "37.43216000",
                            "longitude": "-120.77854000"
                        },
                        {
                            "id": 115234,
                            "name": "Denair",
                            "latitude": "37.52632000",
                            "longitude": "-120.79687000"
                        },
                        {
                            "id": 115276,
                            "name": "Descanso",
                            "latitude": "32.85283000",
                            "longitude": "-116.61585000"
                        },
                        {
                            "id": 115280,
                            "name": "Desert Edge",
                            "latitude": "33.92417000",
                            "longitude": "-116.44139000"
                        },
                        {
                            "id": 115282,
                            "name": "Desert Hot Springs",
                            "latitude": "33.96173000",
                            "longitude": "-116.50353000"
                        },
                        {
                            "id": 115283,
                            "name": "Desert Shores",
                            "latitude": "33.40420000",
                            "longitude": "-116.03972000"
                        },
                        {
                            "id": 115284,
                            "name": "Desert View Highlands",
                            "latitude": "34.59082000",
                            "longitude": "-118.15257000"
                        },
                        {
                            "id": 115312,
                            "name": "Diablo",
                            "latitude": "37.83493000",
                            "longitude": "-121.95801000"
                        },
                        {
                            "id": 115314,
                            "name": "Diamond Bar",
                            "latitude": "34.02862000",
                            "longitude": "-117.81034000"
                        },
                        {
                            "id": 115316,
                            "name": "Diamond Springs",
                            "latitude": "38.69463000",
                            "longitude": "-120.81494000"
                        },
                        {
                            "id": 115351,
                            "name": "Dinuba",
                            "latitude": "36.54328000",
                            "longitude": "-119.38707000"
                        },
                        {
                            "id": 115353,
                            "name": "Discovery Bay",
                            "latitude": "37.90854000",
                            "longitude": "-121.60023000"
                        },
                        {
                            "id": 115367,
                            "name": "Dixon",
                            "latitude": "38.44546000",
                            "longitude": "-121.82330000"
                        },
                        {
                            "id": 115369,
                            "name": "Dixon Lane-Meadow Creek",
                            "latitude": "37.38639000",
                            "longitude": "-118.41527000"
                        },
                        {
                            "id": 115385,
                            "name": "Dogtown",
                            "latitude": "38.21381000",
                            "longitude": "-121.08855000"
                        },
                        {
                            "id": 115390,
                            "name": "Dollar Point",
                            "latitude": "39.18796000",
                            "longitude": "-120.09991000"
                        },
                        {
                            "id": 115414,
                            "name": "Dos Palos",
                            "latitude": "36.98606000",
                            "longitude": "-120.62657000"
                        },
                        {
                            "id": 115460,
                            "name": "Downey",
                            "latitude": "33.94001000",
                            "longitude": "-118.13257000"
                        },
                        {
                            "id": 115461,
                            "name": "Downieville",
                            "latitude": "39.55934000",
                            "longitude": "-120.82689000"
                        },
                        {
                            "id": 115490,
                            "name": "Duarte",
                            "latitude": "34.13945000",
                            "longitude": "-117.97729000"
                        },
                        {
                            "id": 115496,
                            "name": "Dublin",
                            "latitude": "37.70215000",
                            "longitude": "-121.93579000"
                        },
                        {
                            "id": 115551,
                            "name": "Dunnigan",
                            "latitude": "38.88518000",
                            "longitude": "-121.96969000"
                        },
                        {
                            "id": 115553,
                            "name": "Dunsmuir",
                            "latitude": "41.20821000",
                            "longitude": "-122.27195000"
                        },
                        {
                            "id": 115573,
                            "name": "Durham",
                            "latitude": "39.64627000",
                            "longitude": "-121.79998000"
                        },
                        {
                            "id": 115618,
                            "name": "Earlimart",
                            "latitude": "35.88412000",
                            "longitude": "-119.27233000"
                        },
                        {
                            "id": 115663,
                            "name": "East Foothills",
                            "latitude": "37.38105000",
                            "longitude": "-121.81745000"
                        },
                        {
                            "id": 115689,
                            "name": "East Hemet",
                            "latitude": "33.74002000",
                            "longitude": "-116.93891000"
                        },
                        {
                            "id": 115699,
                            "name": "East La Mirada",
                            "latitude": "33.92446000",
                            "longitude": "-117.98895000"
                        },
                        {
                            "id": 115706,
                            "name": "East Los Angeles",
                            "latitude": "34.02390000",
                            "longitude": "-118.17202000"
                        },
                        {
                            "id": 115725,
                            "name": "East Oakdale",
                            "latitude": "37.78798000",
                            "longitude": "-120.80382000"
                        },
                        {
                            "id": 115728,
                            "name": "East Palo Alto",
                            "latitude": "37.46883000",
                            "longitude": "-122.14108000"
                        },
                        {
                            "id": 115729,
                            "name": "East Pasadena",
                            "latitude": "34.13814000",
                            "longitude": "-118.07384000"
                        },
                        {
                            "id": 115739,
                            "name": "East Porterville",
                            "latitude": "36.05745000",
                            "longitude": "-118.97566000"
                        },
                        {
                            "id": 115742,
                            "name": "East Quincy",
                            "latitude": "39.93406000",
                            "longitude": "-120.89801000"
                        },
                        {
                            "id": 115744,
                            "name": "East Rancho Dominguez",
                            "latitude": "33.89807000",
                            "longitude": "-118.19535000"
                        },
                        {
                            "id": 115746,
                            "name": "East Richmond Heights",
                            "latitude": "37.94492000",
                            "longitude": "-122.31358000"
                        },
                        {
                            "id": 115755,
                            "name": "East San Gabriel",
                            "latitude": "34.09168000",
                            "longitude": "-118.09118000"
                        },
                        {
                            "id": 115759,
                            "name": "East Sonora",
                            "latitude": "37.97770000",
                            "longitude": "-120.36130000"
                        },
                        {
                            "id": 115791,
                            "name": "Easton",
                            "latitude": "36.65023000",
                            "longitude": "-119.79070000"
                        },
                        {
                            "id": 115797,
                            "name": "Eastvale",
                            "latitude": "33.96358000",
                            "longitude": "-117.56418000"
                        },
                        {
                            "id": 115814,
                            "name": "Echo Park",
                            "latitude": "34.07808000",
                            "longitude": "-118.26066000"
                        },
                        {
                            "id": 115888,
                            "name": "Edwards Air Force Base",
                            "latitude": "34.91637000",
                            "longitude": "-117.93535000"
                        },
                        {
                            "id": 115911,
                            "name": "El Cajon",
                            "latitude": "32.79477000",
                            "longitude": "-116.96253000"
                        },
                        {
                            "id": 115914,
                            "name": "El Centro",
                            "latitude": "32.79200000",
                            "longitude": "-115.56305000"
                        },
                        {
                            "id": 115915,
                            "name": "El Cerrito",
                            "latitude": "37.91576000",
                            "longitude": "-122.31164000"
                        },
                        {
                            "id": 115916,
                            "name": "El Cerrito Corona",
                            "latitude": "33.84057000",
                            "longitude": "-117.52283000"
                        },
                        {
                            "id": 115921,
                            "name": "El Dorado County",
                            "latitude": "38.77874000",
                            "longitude": "-120.52465000"
                        },
                        {
                            "id": 115922,
                            "name": "El Dorado Hills",
                            "latitude": "38.68574000",
                            "longitude": "-121.08217000"
                        },
                        {
                            "id": 115924,
                            "name": "El Granada",
                            "latitude": "37.50272000",
                            "longitude": "-122.46942000"
                        },
                        {
                            "id": 115928,
                            "name": "El Monte",
                            "latitude": "34.06862000",
                            "longitude": "-118.02757000"
                        },
                        {
                            "id": 115936,
                            "name": "El Rio",
                            "latitude": "34.23578000",
                            "longitude": "-119.16383000"
                        },
                        {
                            "id": 115937,
                            "name": "El Segundo",
                            "latitude": "33.91918000",
                            "longitude": "-118.41647000"
                        },
                        {
                            "id": 115938,
                            "name": "El Sobrante",
                            "latitude": "37.97715000",
                            "longitude": "-122.29525000"
                        },
                        {
                            "id": 115940,
                            "name": "El Verano",
                            "latitude": "38.29769000",
                            "longitude": "-122.49165000"
                        },
                        {
                            "id": 115957,
                            "name": "Eldridge",
                            "latitude": "38.34880000",
                            "longitude": "-122.51081000"
                        },
                        {
                            "id": 115987,
                            "name": "Elk Grove",
                            "latitude": "38.40880000",
                            "longitude": "-121.37162000"
                        },
                        {
                            "id": 116003,
                            "name": "Elkhorn",
                            "latitude": "36.82440000",
                            "longitude": "-121.74050000"
                        },
                        {
                            "id": 116075,
                            "name": "Elverta",
                            "latitude": "38.71379000",
                            "longitude": "-121.46273000"
                        },
                        {
                            "id": 116090,
                            "name": "Emerald Lake Hills",
                            "latitude": "37.46466000",
                            "longitude": "-122.26802000"
                        },
                        {
                            "id": 116097,
                            "name": "Emeryville",
                            "latitude": "37.83132000",
                            "longitude": "-122.28525000"
                        },
                        {
                            "id": 116110,
                            "name": "Empire",
                            "latitude": "37.63826000",
                            "longitude": "-120.90215000"
                        },
                        {
                            "id": 116118,
                            "name": "Encinitas",
                            "latitude": "33.03699000",
                            "longitude": "-117.29198000"
                        },
                        {
                            "id": 116119,
                            "name": "Encino",
                            "latitude": "34.15917000",
                            "longitude": "-118.50119000"
                        },
                        {
                            "id": 116178,
                            "name": "Escalon",
                            "latitude": "37.79781000",
                            "longitude": "-120.99792000"
                        },
                        {
                            "id": 116184,
                            "name": "Escondido",
                            "latitude": "33.11921000",
                            "longitude": "-117.08642000"
                        },
                        {
                            "id": 116187,
                            "name": "Esparto",
                            "latitude": "38.69213000",
                            "longitude": "-122.01719000"
                        },
                        {
                            "id": 116221,
                            "name": "Eucalyptus Hills",
                            "latitude": "32.87977000",
                            "longitude": "-116.94669000"
                        },
                        {
                            "id": 116235,
                            "name": "Eureka",
                            "latitude": "40.80207000",
                            "longitude": "-124.16367000"
                        },
                        {
                            "id": 116274,
                            "name": "Exeter",
                            "latitude": "36.29606000",
                            "longitude": "-119.14205000"
                        },
                        {
                            "id": 116286,
                            "name": "Fair Oaks",
                            "latitude": "38.64463000",
                            "longitude": "-121.27217000"
                        },
                        {
                            "id": 116292,
                            "name": "Fairbanks Ranch",
                            "latitude": "32.99393000",
                            "longitude": "-117.18726000"
                        },
                        {
                            "id": 116306,
                            "name": "Fairfax",
                            "latitude": "37.98715000",
                            "longitude": "-122.58887000"
                        },
                        {
                            "id": 116316,
                            "name": "Fairfield",
                            "latitude": "38.24936000",
                            "longitude": "-122.03997000"
                        },
                        {
                            "id": 116333,
                            "name": "Fairmead",
                            "latitude": "37.07633000",
                            "longitude": "-120.19295000"
                        },
                        {
                            "id": 116355,
                            "name": "Fairview",
                            "latitude": "37.67854000",
                            "longitude": "-122.04580000"
                        },
                        {
                            "id": 116376,
                            "name": "Fallbrook",
                            "latitude": "33.37642000",
                            "longitude": "-117.25115000"
                        },
                        {
                            "id": 116407,
                            "name": "Farmersville",
                            "latitude": "36.29773000",
                            "longitude": "-119.20678000"
                        },
                        {
                            "id": 116471,
                            "name": "Felton",
                            "latitude": "37.05134000",
                            "longitude": "-122.07330000"
                        },
                        {
                            "id": 116489,
                            "name": "Ferndale",
                            "latitude": "40.57624000",
                            "longitude": "-124.26394000"
                        },
                        {
                            "id": 116503,
                            "name": "Fetters Hot Springs-Agua Caliente",
                            "latitude": "38.32140000",
                            "longitude": "-122.48682000"
                        },
                        {
                            "id": 116508,
                            "name": "Fillmore",
                            "latitude": "34.39916000",
                            "longitude": "-118.91815000"
                        },
                        {
                            "id": 116520,
                            "name": "Firebaugh",
                            "latitude": "36.85884000",
                            "longitude": "-120.45601000"
                        },
                        {
                            "id": 116589,
                            "name": "Florence-Graham",
                            "latitude": "33.96772000",
                            "longitude": "-118.24438000"
                        },
                        {
                            "id": 116595,
                            "name": "Florin",
                            "latitude": "38.49602000",
                            "longitude": "-121.40884000"
                        },
                        {
                            "id": 116626,
                            "name": "Folsom",
                            "latitude": "38.67796000",
                            "longitude": "-121.17606000"
                        },
                        {
                            "id": 116631,
                            "name": "Fontana",
                            "latitude": "34.09223000",
                            "longitude": "-117.43505000"
                        },
                        {
                            "id": 116632,
                            "name": "Foothill Farms",
                            "latitude": "38.67877000",
                            "longitude": "-121.35114000"
                        },
                        {
                            "id": 116633,
                            "name": "Foothill Ranch",
                            "latitude": "33.68641000",
                            "longitude": "-117.66088000"
                        },
                        {
                            "id": 116635,
                            "name": "Ford City",
                            "latitude": "35.15441000",
                            "longitude": "-119.45623000"
                        },
                        {
                            "id": 116662,
                            "name": "Forest Meadows",
                            "latitude": "38.16851000",
                            "longitude": "-120.40659000"
                        },
                        {
                            "id": 116667,
                            "name": "Forest Ranch",
                            "latitude": "39.88211000",
                            "longitude": "-121.67275000"
                        },
                        {
                            "id": 116671,
                            "name": "Foresthill",
                            "latitude": "39.02018000",
                            "longitude": "-120.81799000"
                        },
                        {
                            "id": 116673,
                            "name": "Forestville",
                            "latitude": "38.47352000",
                            "longitude": "-122.89027000"
                        },
                        {
                            "id": 116695,
                            "name": "Fort Bragg",
                            "latitude": "39.44572000",
                            "longitude": "-123.80529000"
                        },
                        {
                            "id": 116719,
                            "name": "Fort Irwin",
                            "latitude": "35.26275000",
                            "longitude": "-116.68475000"
                        },
                        {
                            "id": 116767,
                            "name": "Fortuna",
                            "latitude": "40.59819000",
                            "longitude": "-124.15728000"
                        },
                        {
                            "id": 116776,
                            "name": "Foster City",
                            "latitude": "37.55855000",
                            "longitude": "-122.27108000"
                        },
                        {
                            "id": 116784,
                            "name": "Fountain Valley",
                            "latitude": "33.70918000",
                            "longitude": "-117.95367000"
                        },
                        {
                            "id": 116797,
                            "name": "Fowler",
                            "latitude": "36.63051000",
                            "longitude": "-119.67847000"
                        },
                        {
                            "id": 116883,
                            "name": "Frazier Park",
                            "latitude": "34.82276000",
                            "longitude": "-118.94482000"
                        },
                        {
                            "id": 116903,
                            "name": "Freedom",
                            "latitude": "36.93523000",
                            "longitude": "-121.77301000"
                        },
                        {
                            "id": 116924,
                            "name": "Fremont",
                            "latitude": "37.54827000",
                            "longitude": "-121.98857000"
                        },
                        {
                            "id": 116929,
                            "name": "French Camp",
                            "latitude": "37.88409000",
                            "longitude": "-121.27106000"
                        },
                        {
                            "id": 116939,
                            "name": "Fresno",
                            "latitude": "36.74773000",
                            "longitude": "-119.77237000"
                        },
                        {
                            "id": 116940,
                            "name": "Fresno County",
                            "latitude": "36.75818000",
                            "longitude": "-119.64932000"
                        },
                        {
                            "id": 116975,
                            "name": "Fruitridge Pocket",
                            "latitude": "38.53265000",
                            "longitude": "-121.45581000"
                        },
                        {
                            "id": 116983,
                            "name": "Fullerton",
                            "latitude": "33.87029000",
                            "longitude": "-117.92534000"
                        },
                        {
                            "id": 117040,
                            "name": "Galt",
                            "latitude": "38.25464000",
                            "longitude": "-121.29995000"
                        },
                        {
                            "id": 117053,
                            "name": "Garden Acres",
                            "latitude": "37.96381000",
                            "longitude": "-121.22939000"
                        },
                        {
                            "id": 117065,
                            "name": "Garden Grove",
                            "latitude": "33.77391000",
                            "longitude": "-117.94145000"
                        },
                        {
                            "id": 117069,
                            "name": "Gardena",
                            "latitude": "33.88835000",
                            "longitude": "-118.30896000"
                        },
                        {
                            "id": 117094,
                            "name": "Garnet",
                            "latitude": "33.90196000",
                            "longitude": "-116.54557000"
                        },
                        {
                            "id": 117165,
                            "name": "Georgetown",
                            "latitude": "38.90684000",
                            "longitude": "-120.83855000"
                        },
                        {
                            "id": 117169,
                            "name": "Gerber",
                            "latitude": "40.05627000",
                            "longitude": "-122.15027000"
                        },
                        {
                            "id": 117225,
                            "name": "Gilroy",
                            "latitude": "37.00578000",
                            "longitude": "-121.56828000"
                        },
                        {
                            "id": 117261,
                            "name": "Glen Avon",
                            "latitude": "34.01168000",
                            "longitude": "-117.48477000"
                        },
                        {
                            "id": 117286,
                            "name": "Glendale",
                            "latitude": "34.14251000",
                            "longitude": "-118.25508000"
                        },
                        {
                            "id": 117291,
                            "name": "Glendora",
                            "latitude": "34.13612000",
                            "longitude": "-117.86534000"
                        },
                        {
                            "id": 117296,
                            "name": "Glenn County",
                            "latitude": "39.59840000",
                            "longitude": "-122.39221000"
                        },
                        {
                            "id": 117345,
                            "name": "Gold River",
                            "latitude": "38.62629000",
                            "longitude": "-121.24662000"
                        },
                        {
                            "id": 117351,
                            "name": "Golden Hills",
                            "latitude": "35.14247000",
                            "longitude": "-118.49036000"
                        },
                        {
                            "id": 117367,
                            "name": "Goleta",
                            "latitude": "34.43583000",
                            "longitude": "-119.82764000"
                        },
                        {
                            "id": 117372,
                            "name": "Gonzales",
                            "latitude": "36.50663000",
                            "longitude": "-121.44438000"
                        },
                        {
                            "id": 117378,
                            "name": "Good Hope",
                            "latitude": "33.76474000",
                            "longitude": "-117.26698000"
                        },
                        {
                            "id": 117410,
                            "name": "Goshen",
                            "latitude": "36.35106000",
                            "longitude": "-119.42012000"
                        },
                        {
                            "id": 117476,
                            "name": "Grand Terrace",
                            "latitude": "34.03390000",
                            "longitude": "-117.31365000"
                        },
                        {
                            "id": 117493,
                            "name": "Granite Bay",
                            "latitude": "38.76323000",
                            "longitude": "-121.16384000"
                        },
                        {
                            "id": 117499,
                            "name": "Granite Hills",
                            "latitude": "32.80311000",
                            "longitude": "-116.90475000"
                        },
                        {
                            "id": 117543,
                            "name": "Grass Valley",
                            "latitude": "39.21906000",
                            "longitude": "-121.06106000"
                        },
                        {
                            "id": 117545,
                            "name": "Graton",
                            "latitude": "38.43630000",
                            "longitude": "-122.86972000"
                        },
                        {
                            "id": 117590,
                            "name": "Green Acres",
                            "latitude": "33.73808000",
                            "longitude": "-117.07642000"
                        },
                        {
                            "id": 117611,
                            "name": "Green Valley",
                            "latitude": "38.25297000",
                            "longitude": "-122.16219000"
                        },
                        {
                            "id": 117613,
                            "name": "Greenacres",
                            "latitude": "35.38329000",
                            "longitude": "-119.10983000"
                        },
                        {
                            "id": 117654,
                            "name": "Greenfield",
                            "latitude": "36.32080000",
                            "longitude": "-121.24381000"
                        },
                        {
                            "id": 117696,
                            "name": "Greenville",
                            "latitude": "40.13961000",
                            "longitude": "-120.95107000"
                        },
                        {
                            "id": 117730,
                            "name": "Gridley",
                            "latitude": "39.36378000",
                            "longitude": "-121.69358000"
                        },
                        {
                            "id": 117760,
                            "name": "Grover Beach",
                            "latitude": "35.12164000",
                            "longitude": "-120.62128000"
                        },
                        {
                            "id": 117777,
                            "name": "Guadalupe",
                            "latitude": "34.97164000",
                            "longitude": "-120.57184000"
                        },
                        {
                            "id": 117780,
                            "name": "Guerneville",
                            "latitude": "38.50186000",
                            "longitude": "-122.99611000"
                        },
                        {
                            "id": 117810,
                            "name": "Gustine",
                            "latitude": "37.25772000",
                            "longitude": "-120.99882000"
                        },
                        {
                            "id": 117825,
                            "name": "Hacienda Heights",
                            "latitude": "33.99307000",
                            "longitude": "-117.96868000"
                        },
                        {
                            "id": 117856,
                            "name": "Half Moon Bay",
                            "latitude": "37.46355000",
                            "longitude": "-122.42859000"
                        },
                        {
                            "id": 117895,
                            "name": "Hamilton City",
                            "latitude": "39.74266000",
                            "longitude": "-122.01359000"
                        },
                        {
                            "id": 117960,
                            "name": "Hanford",
                            "latitude": "36.32745000",
                            "longitude": "-119.64568000"
                        },
                        {
                            "id": 117982,
                            "name": "Happy Camp",
                            "latitude": "41.79275000",
                            "longitude": "-123.38080000"
                        },
                        {
                            "id": 117986,
                            "name": "Harbison Canyon",
                            "latitude": "32.82033000",
                            "longitude": "-116.83002000"
                        },
                        {
                            "id": 118099,
                            "name": "Hartley",
                            "latitude": "38.41713000",
                            "longitude": "-121.94691000"
                        },
                        {
                            "id": 118160,
                            "name": "Hawaiian Gardens",
                            "latitude": "33.83140000",
                            "longitude": "-118.07284000"
                        },
                        {
                            "id": 118175,
                            "name": "Hawthorne",
                            "latitude": "33.91640000",
                            "longitude": "-118.35257000"
                        },
                        {
                            "id": 118186,
                            "name": "Hayfork",
                            "latitude": "40.55431000",
                            "longitude": "-123.18308000"
                        },
                        {
                            "id": 118197,
                            "name": "Hayward",
                            "latitude": "37.66882000",
                            "longitude": "-122.08080000"
                        },
                        {
                            "id": 118217,
                            "name": "Healdsburg",
                            "latitude": "38.61047000",
                            "longitude": "-122.86916000"
                        },
                        {
                            "id": 118228,
                            "name": "Heber",
                            "latitude": "32.73089000",
                            "longitude": "-115.52972000"
                        },
                        {
                            "id": 118264,
                            "name": "Hemet",
                            "latitude": "33.74761000",
                            "longitude": "-116.97307000"
                        },
                        {
                            "id": 118311,
                            "name": "Herald",
                            "latitude": "38.29575000",
                            "longitude": "-121.24439000"
                        },
                        {
                            "id": 118314,
                            "name": "Hercules",
                            "latitude": "38.01714000",
                            "longitude": "-122.28858000"
                        },
                        {
                            "id": 118331,
                            "name": "Hermosa Beach",
                            "latitude": "33.86224000",
                            "longitude": "-118.39952000"
                        },
                        {
                            "id": 118344,
                            "name": "Hesperia",
                            "latitude": "34.42639000",
                            "longitude": "-117.30088000"
                        },
                        {
                            "id": 118376,
                            "name": "Hidden Hills",
                            "latitude": "34.16028000",
                            "longitude": "-118.65231000"
                        },
                        {
                            "id": 118377,
                            "name": "Hidden Meadows",
                            "latitude": "33.22531000",
                            "longitude": "-117.11253000"
                        },
                        {
                            "id": 118380,
                            "name": "Hidden Valley Lake",
                            "latitude": "38.80796000",
                            "longitude": "-122.55832000"
                        },
                        {
                            "id": 118391,
                            "name": "Highgrove",
                            "latitude": "34.01585000",
                            "longitude": "-117.33338000"
                        },
                        {
                            "id": 118398,
                            "name": "Highland",
                            "latitude": "34.12834000",
                            "longitude": "-117.20865000"
                        },
                        {
                            "id": 118421,
                            "name": "Highlands-Baywood Park",
                            "latitude": "37.52272000",
                            "longitude": "-122.34506000"
                        },
                        {
                            "id": 118455,
                            "name": "Hillsborough",
                            "latitude": "37.57410000",
                            "longitude": "-122.37942000"
                        },
                        {
                            "id": 118471,
                            "name": "Hilmar-Irwin",
                            "latitude": "37.40454000",
                            "longitude": "-120.85042000"
                        },
                        {
                            "id": 118552,
                            "name": "Hollister",
                            "latitude": "36.85245000",
                            "longitude": "-121.40160000"
                        },
                        {
                            "id": 118567,
                            "name": "Hollywood",
                            "latitude": "34.09834000",
                            "longitude": "-118.32674000"
                        },
                        {
                            "id": 118582,
                            "name": "Holtville",
                            "latitude": "32.81116000",
                            "longitude": "-115.38026000"
                        },
                        {
                            "id": 118586,
                            "name": "Home Garden",
                            "latitude": "36.30328000",
                            "longitude": "-119.63624000"
                        },
                        {
                            "id": 118587,
                            "name": "Home Gardens",
                            "latitude": "33.87807000",
                            "longitude": "-117.52088000"
                        },
                        {
                            "id": 118590,
                            "name": "Homeland",
                            "latitude": "33.74308000",
                            "longitude": "-117.10920000"
                        },
                        {
                            "id": 118764,
                            "name": "Hughson",
                            "latitude": "37.59688000",
                            "longitude": "-120.86604000"
                        },
                        {
                            "id": 118779,
                            "name": "Humboldt County",
                            "latitude": "40.70501000",
                            "longitude": "-123.91582000"
                        },
                        {
                            "id": 118781,
                            "name": "Humboldt Hill",
                            "latitude": "40.72596000",
                            "longitude": "-124.18978000"
                        },
                        {
                            "id": 118805,
                            "name": "Huntington Beach",
                            "latitude": "33.66030000",
                            "longitude": "-117.99923000"
                        },
                        {
                            "id": 118807,
                            "name": "Huntington Park",
                            "latitude": "33.98168000",
                            "longitude": "-118.22507000"
                        },
                        {
                            "id": 118825,
                            "name": "Huron",
                            "latitude": "36.20273000",
                            "longitude": "-120.10292000"
                        },
                        {
                            "id": 118855,
                            "name": "Hydesville",
                            "latitude": "40.54763000",
                            "longitude": "-124.09727000"
                        },
                        {
                            "id": 118874,
                            "name": "Idyllwild",
                            "latitude": "33.74002000",
                            "longitude": "-116.71891000"
                        },
                        {
                            "id": 118875,
                            "name": "Idyllwild-Pine Cove",
                            "latitude": "33.74429000",
                            "longitude": "-116.72587000"
                        },
                        {
                            "id": 118883,
                            "name": "Imperial",
                            "latitude": "32.84755000",
                            "longitude": "-115.56944000"
                        },
                        {
                            "id": 118885,
                            "name": "Imperial Beach",
                            "latitude": "32.58394000",
                            "longitude": "-117.11308000"
                        },
                        {
                            "id": 118886,
                            "name": "Imperial County",
                            "latitude": "33.03951000",
                            "longitude": "-115.36532000"
                        },
                        {
                            "id": 118921,
                            "name": "Indian Wells",
                            "latitude": "33.71791000",
                            "longitude": "-116.34311000"
                        },
                        {
                            "id": 118929,
                            "name": "Indio",
                            "latitude": "33.72070000",
                            "longitude": "-116.21677000"
                        },
                        {
                            "id": 118937,
                            "name": "Inglewood",
                            "latitude": "33.96168000",
                            "longitude": "-118.35313000"
                        },
                        {
                            "id": 118951,
                            "name": "Interlaken",
                            "latitude": "36.95134000",
                            "longitude": "-121.73384000"
                        },
                        {
                            "id": 118957,
                            "name": "Inverness",
                            "latitude": "38.10103000",
                            "longitude": "-122.85694000"
                        },
                        {
                            "id": 118964,
                            "name": "Inyo County",
                            "latitude": "36.51113000",
                            "longitude": "-117.41079000"
                        },
                        {
                            "id": 118965,
                            "name": "Inyokern",
                            "latitude": "35.64690000",
                            "longitude": "-117.81257000"
                        },
                        {
                            "id": 118970,
                            "name": "Ione",
                            "latitude": "38.35269000",
                            "longitude": "-120.93272000"
                        },
                        {
                            "id": 119004,
                            "name": "Irvine",
                            "latitude": "33.66946000",
                            "longitude": "-117.82311000"
                        },
                        {
                            "id": 119014,
                            "name": "Irwindale",
                            "latitude": "34.10695000",
                            "longitude": "-117.93534000"
                        },
                        {
                            "id": 119021,
                            "name": "Isla Vista",
                            "latitude": "34.41333000",
                            "longitude": "-119.86097000"
                        },
                        {
                            "id": 119049,
                            "name": "Ivanhoe",
                            "latitude": "36.38717000",
                            "longitude": "-119.21789000"
                        },
                        {
                            "id": 119073,
                            "name": "Jackson",
                            "latitude": "38.34880000",
                            "longitude": "-120.77410000"
                        },
                        {
                            "id": 119126,
                            "name": "Jamestown",
                            "latitude": "37.95326000",
                            "longitude": "-120.42270000"
                        },
                        {
                            "id": 119128,
                            "name": "Jamul",
                            "latitude": "32.71700000",
                            "longitude": "-116.87613000"
                        },
                        {
                            "id": 119132,
                            "name": "Janesville",
                            "latitude": "40.29656000",
                            "longitude": "-120.52411000"
                        },
                        {
                            "id": 119283,
                            "name": "Johnstonville",
                            "latitude": "40.38434000",
                            "longitude": "-120.58745000"
                        },
                        {
                            "id": 119323,
                            "name": "Joshua Tree",
                            "latitude": "34.13473000",
                            "longitude": "-116.31307000"
                        },
                        {
                            "id": 119330,
                            "name": "Julian",
                            "latitude": "33.07866000",
                            "longitude": "-116.60196000"
                        },
                        {
                            "id": 119343,
                            "name": "Jurupa Valley",
                            "latitude": "33.99251000",
                            "longitude": "-117.51644000"
                        },
                        {
                            "id": 119431,
                            "name": "Kelseyville",
                            "latitude": "38.97795000",
                            "longitude": "-122.83944000"
                        },
                        {
                            "id": 119469,
                            "name": "Kennedy",
                            "latitude": "37.92992000",
                            "longitude": "-121.25272000"
                        },
                        {
                            "id": 119484,
                            "name": "Kensington",
                            "latitude": "37.91048000",
                            "longitude": "-122.28025000"
                        },
                        {
                            "id": 119495,
                            "name": "Kentfield",
                            "latitude": "37.95215000",
                            "longitude": "-122.55720000"
                        },
                        {
                            "id": 119505,
                            "name": "Kenwood",
                            "latitude": "38.41380000",
                            "longitude": "-122.54609000"
                        },
                        {
                            "id": 119512,
                            "name": "Kerman",
                            "latitude": "36.72356000",
                            "longitude": "-120.05988000"
                        },
                        {
                            "id": 119514,
                            "name": "Kern County",
                            "latitude": "35.34285000",
                            "longitude": "-118.72990000"
                        },
                        {
                            "id": 119516,
                            "name": "Kernville",
                            "latitude": "35.75467000",
                            "longitude": "-118.42536000"
                        },
                        {
                            "id": 119528,
                            "name": "Kettleman City",
                            "latitude": "36.00829000",
                            "longitude": "-119.96180000"
                        },
                        {
                            "id": 119543,
                            "name": "Keyes",
                            "latitude": "37.55660000",
                            "longitude": "-120.91549000"
                        },
                        {
                            "id": 119579,
                            "name": "King City",
                            "latitude": "36.21274000",
                            "longitude": "-121.12603000"
                        },
                        {
                            "id": 119598,
                            "name": "Kings Beach",
                            "latitude": "39.23768000",
                            "longitude": "-120.02658000"
                        },
                        {
                            "id": 119600,
                            "name": "Kings County",
                            "latitude": "36.07536000",
                            "longitude": "-119.81550000"
                        },
                        {
                            "id": 119609,
                            "name": "Kingsburg",
                            "latitude": "36.51384000",
                            "longitude": "-119.55402000"
                        },
                        {
                            "id": 119675,
                            "name": "Knightsen",
                            "latitude": "37.96881000",
                            "longitude": "-121.66801000"
                        },
                        {
                            "id": 119708,
                            "name": "Koreatown",
                            "latitude": "34.05779000",
                            "longitude": "-118.30091000"
                        },
                        {
                            "id": 119736,
                            "name": "La Cañada Flintridge",
                            "latitude": "34.19917000",
                            "longitude": "-118.18785000"
                        },
                        {
                            "id": 119742,
                            "name": "La Crescenta-Montrose",
                            "latitude": "34.23216000",
                            "longitude": "-118.23529000"
                        },
                        {
                            "id": 119755,
                            "name": "La Habra",
                            "latitude": "33.93196000",
                            "longitude": "-117.94617000"
                        },
                        {
                            "id": 119756,
                            "name": "La Habra Heights",
                            "latitude": "33.96085000",
                            "longitude": "-117.95062000"
                        },
                        {
                            "id": 119760,
                            "name": "La Jolla",
                            "latitude": "32.84727000",
                            "longitude": "-117.27420000"
                        },
                        {
                            "id": 119765,
                            "name": "La Mesa",
                            "latitude": "32.76783000",
                            "longitude": "-117.02308000"
                        },
                        {
                            "id": 119767,
                            "name": "La Mirada",
                            "latitude": "33.91724000",
                            "longitude": "-118.01201000"
                        },
                        {
                            "id": 119769,
                            "name": "La Palma",
                            "latitude": "33.84640000",
                            "longitude": "-118.04673000"
                        },
                        {
                            "id": 119779,
                            "name": "La Presa",
                            "latitude": "32.70811000",
                            "longitude": "-116.99725000"
                        },
                        {
                            "id": 119782,
                            "name": "La Puente",
                            "latitude": "34.02001000",
                            "longitude": "-117.94951000"
                        },
                        {
                            "id": 119783,
                            "name": "La Quinta",
                            "latitude": "33.66336000",
                            "longitude": "-116.31001000"
                        },
                        {
                            "id": 119784,
                            "name": "La Riviera",
                            "latitude": "38.56685000",
                            "longitude": "-121.35690000"
                        },
                        {
                            "id": 119789,
                            "name": "La Selva Beach",
                            "latitude": "36.93662000",
                            "longitude": "-121.86468000"
                        },
                        {
                            "id": 119793,
                            "name": "La Verne",
                            "latitude": "34.10084000",
                            "longitude": "-117.76784000"
                        },
                        {
                            "id": 119823,
                            "name": "Ladera",
                            "latitude": "37.39994000",
                            "longitude": "-122.19830000"
                        },
                        {
                            "id": 119824,
                            "name": "Ladera Heights",
                            "latitude": "33.99418000",
                            "longitude": "-118.37535000"
                        },
                        {
                            "id": 119825,
                            "name": "Ladera Ranch",
                            "latitude": "33.57086000",
                            "longitude": "-117.63561000"
                        },
                        {
                            "id": 119835,
                            "name": "Lafayette",
                            "latitude": "37.88576000",
                            "longitude": "-122.11802000"
                        },
                        {
                            "id": 119849,
                            "name": "Laguna",
                            "latitude": "38.42102000",
                            "longitude": "-121.42384000"
                        },
                        {
                            "id": 119852,
                            "name": "Laguna Beach",
                            "latitude": "33.54225000",
                            "longitude": "-117.78311000"
                        },
                        {
                            "id": 119854,
                            "name": "Laguna Hills",
                            "latitude": "33.61252000",
                            "longitude": "-117.71283000"
                        },
                        {
                            "id": 119855,
                            "name": "Laguna Niguel",
                            "latitude": "33.52253000",
                            "longitude": "-117.70755000"
                        },
                        {
                            "id": 119858,
                            "name": "Laguna Woods",
                            "latitude": "33.61030000",
                            "longitude": "-117.72533000"
                        },
                        {
                            "id": 119859,
                            "name": "Lagunitas-Forest Knolls",
                            "latitude": "38.01793000",
                            "longitude": "-122.69124000"
                        },
                        {
                            "id": 119866,
                            "name": "Lake Arrowhead",
                            "latitude": "34.24834000",
                            "longitude": "-117.18921000"
                        },
                        {
                            "id": 119896,
                            "name": "Lake County",
                            "latitude": "39.09965000",
                            "longitude": "-122.75318000"
                        },
                        {
                            "id": 119906,
                            "name": "Lake Elsinore",
                            "latitude": "33.66808000",
                            "longitude": "-117.32726000"
                        },
                        {
                            "id": 119911,
                            "name": "Lake Forest",
                            "latitude": "33.64697000",
                            "longitude": "-117.68922000"
                        },
                        {
                            "id": 119923,
                            "name": "Lake Isabella",
                            "latitude": "35.61801000",
                            "longitude": "-118.47314000"
                        },
                        {
                            "id": 119931,
                            "name": "Lake Los Angeles",
                            "latitude": "34.61249000",
                            "longitude": "-117.82812000"
                        },
                        {
                            "id": 119950,
                            "name": "Lake Nacimiento",
                            "latitude": "35.72830000",
                            "longitude": "-120.87963000"
                        },
                        {
                            "id": 120005,
                            "name": "Lake of the Pines",
                            "latitude": "39.03962000",
                            "longitude": "-121.05661000"
                        },
                        {
                            "id": 119973,
                            "name": "Lake San Marcos",
                            "latitude": "33.12615000",
                            "longitude": "-117.20837000"
                        },
                        {
                            "id": 119993,
                            "name": "Lake Wildwood",
                            "latitude": "39.23295000",
                            "longitude": "-121.20051000"
                        },
                        {
                            "id": 120020,
                            "name": "Lakeland Village",
                            "latitude": "33.63863000",
                            "longitude": "-117.34393000"
                        },
                        {
                            "id": 120023,
                            "name": "Lakeport",
                            "latitude": "39.04295000",
                            "longitude": "-122.91583000"
                        },
                        {
                            "id": 120032,
                            "name": "Lakeside",
                            "latitude": "32.85727000",
                            "longitude": "-116.92225000"
                        },
                        {
                            "id": 120040,
                            "name": "Lakeview",
                            "latitude": "33.83863000",
                            "longitude": "-117.11809000"
                        },
                        {
                            "id": 120051,
                            "name": "Lakewood",
                            "latitude": "33.85363000",
                            "longitude": "-118.13396000"
                        },
                        {
                            "id": 120074,
                            "name": "Lamont",
                            "latitude": "35.25968000",
                            "longitude": "-118.91427000"
                        },
                        {
                            "id": 120089,
                            "name": "Lancaster",
                            "latitude": "34.69804000",
                            "longitude": "-118.13674000"
                        },
                        {
                            "id": 120147,
                            "name": "Larkfield-Wikiup",
                            "latitude": "38.51342000",
                            "longitude": "-122.75094000"
                        },
                        {
                            "id": 120148,
                            "name": "Larkspur",
                            "latitude": "37.93409000",
                            "longitude": "-122.53525000"
                        },
                        {
                            "id": 120156,
                            "name": "Las Flores",
                            "latitude": "33.58808000",
                            "longitude": "-117.62672000"
                        },
                        {
                            "id": 120158,
                            "name": "Las Lomas",
                            "latitude": "36.86523000",
                            "longitude": "-121.73495000"
                        },
                        {
                            "id": 120166,
                            "name": "Lassen County",
                            "latitude": "40.67359000",
                            "longitude": "-120.59433000"
                        },
                        {
                            "id": 120170,
                            "name": "Lathrop",
                            "latitude": "37.82270000",
                            "longitude": "-121.27661000"
                        },
                        {
                            "id": 120174,
                            "name": "Laton",
                            "latitude": "36.43328000",
                            "longitude": "-119.68680000"
                        },
                        {
                            "id": 120222,
                            "name": "Lawndale",
                            "latitude": "33.88724000",
                            "longitude": "-118.35257000"
                        },
                        {
                            "id": 120255,
                            "name": "Laytonville",
                            "latitude": "39.68821000",
                            "longitude": "-123.48279000"
                        },
                        {
                            "id": 120260,
                            "name": "Le Grand",
                            "latitude": "37.22855000",
                            "longitude": "-120.24823000"
                        },
                        {
                            "id": 120298,
                            "name": "Lebec",
                            "latitude": "34.84164000",
                            "longitude": "-118.86482000"
                        },
                        {
                            "id": 120359,
                            "name": "Lemon Grove",
                            "latitude": "32.74255000",
                            "longitude": "-117.03142000"
                        },
                        {
                            "id": 120362,
                            "name": "Lemoore",
                            "latitude": "36.30078000",
                            "longitude": "-119.78291000"
                        },
                        {
                            "id": 120363,
                            "name": "Lemoore Station",
                            "latitude": "36.26326000",
                            "longitude": "-119.90476000"
                        },
                        {
                            "id": 120371,
                            "name": "Lennox",
                            "latitude": "33.93807000",
                            "longitude": "-118.35258000"
                        },
                        {
                            "id": 120378,
                            "name": "Lenwood",
                            "latitude": "34.87665000",
                            "longitude": "-117.10393000"
                        },
                        {
                            "id": 120387,
                            "name": "Leona Valley",
                            "latitude": "34.61832000",
                            "longitude": "-118.28813000"
                        },
                        {
                            "id": 120427,
                            "name": "Lewiston",
                            "latitude": "40.70737000",
                            "longitude": "-122.80752000"
                        },
                        {
                            "id": 120454,
                            "name": "Lexington Hills",
                            "latitude": "37.16467000",
                            "longitude": "-121.97301000"
                        },
                        {
                            "id": 120509,
                            "name": "Lincoln",
                            "latitude": "38.89156000",
                            "longitude": "-121.29301000"
                        },
                        {
                            "id": 120547,
                            "name": "Lincoln Village",
                            "latitude": "38.00520000",
                            "longitude": "-121.32828000"
                        },
                        {
                            "id": 120557,
                            "name": "Linda",
                            "latitude": "39.12767000",
                            "longitude": "-121.55080000"
                        },
                        {
                            "id": 120566,
                            "name": "Linden",
                            "latitude": "38.02131000",
                            "longitude": "-121.08383000"
                        },
                        {
                            "id": 120574,
                            "name": "Lindsay",
                            "latitude": "36.20301000",
                            "longitude": "-119.08816000"
                        },
                        {
                            "id": 120633,
                            "name": "Littlerock",
                            "latitude": "34.52110000",
                            "longitude": "-117.98368000"
                        },
                        {
                            "id": 120640,
                            "name": "Live Oak",
                            "latitude": "36.98356000",
                            "longitude": "-121.98052000"
                        },
                        {
                            "id": 120644,
                            "name": "Livermore",
                            "latitude": "37.68187000",
                            "longitude": "-121.76801000"
                        },
                        {
                            "id": 120652,
                            "name": "Livingston",
                            "latitude": "37.38688000",
                            "longitude": "-120.72353000"
                        },
                        {
                            "id": 120675,
                            "name": "Lockeford",
                            "latitude": "38.16353000",
                            "longitude": "-121.14994000"
                        },
                        {
                            "id": 120691,
                            "name": "Lodi",
                            "latitude": "38.13020000",
                            "longitude": "-121.27245000"
                        },
                        {
                            "id": 120712,
                            "name": "Loma Linda",
                            "latitude": "34.04835000",
                            "longitude": "-117.26115000"
                        },
                        {
                            "id": 120713,
                            "name": "Loma Rica",
                            "latitude": "39.31183000",
                            "longitude": "-121.41774000"
                        },
                        {
                            "id": 120716,
                            "name": "Lomita",
                            "latitude": "33.79224000",
                            "longitude": "-118.31507000"
                        },
                        {
                            "id": 120717,
                            "name": "Lompico",
                            "latitude": "37.10550000",
                            "longitude": "-122.05274000"
                        },
                        {
                            "id": 120718,
                            "name": "Lompoc",
                            "latitude": "34.63915000",
                            "longitude": "-120.45794000"
                        },
                        {
                            "id": 120722,
                            "name": "London",
                            "latitude": "36.47606000",
                            "longitude": "-119.44318000"
                        },
                        {
                            "id": 120729,
                            "name": "Lone Pine",
                            "latitude": "36.60626000",
                            "longitude": "-118.06462000"
                        },
                        {
                            "id": 120738,
                            "name": "Long Beach",
                            "latitude": "33.76696000",
                            "longitude": "-118.18923000"
                        },
                        {
                            "id": 120767,
                            "name": "Loomis",
                            "latitude": "38.82129000",
                            "longitude": "-121.19300000"
                        },
                        {
                            "id": 120778,
                            "name": "Los Alamitos",
                            "latitude": "33.80307000",
                            "longitude": "-118.07256000"
                        },
                        {
                            "id": 120779,
                            "name": "Los Alamos",
                            "latitude": "34.74443000",
                            "longitude": "-120.27821000"
                        },
                        {
                            "id": 120782,
                            "name": "Los Altos",
                            "latitude": "37.38522000",
                            "longitude": "-122.11413000"
                        },
                        {
                            "id": 120783,
                            "name": "Los Altos Hills",
                            "latitude": "37.37966000",
                            "longitude": "-122.13746000"
                        },
                        {
                            "id": 120784,
                            "name": "Los Angeles",
                            "latitude": "34.05223000",
                            "longitude": "-118.24368000"
                        },
                        {
                            "id": 120785,
                            "name": "Los Angeles County",
                            "latitude": "34.19801000",
                            "longitude": "-118.26102000"
                        },
                        {
                            "id": 120786,
                            "name": "Los Banos",
                            "latitude": "37.05828000",
                            "longitude": "-120.84992000"
                        },
                        {
                            "id": 120789,
                            "name": "Los Gatos",
                            "latitude": "37.22661000",
                            "longitude": "-121.97468000"
                        },
                        {
                            "id": 120792,
                            "name": "Los Molinos",
                            "latitude": "40.02127000",
                            "longitude": "-122.10027000"
                        },
                        {
                            "id": 120793,
                            "name": "Los Olivos",
                            "latitude": "34.66776000",
                            "longitude": "-120.11487000"
                        },
                        {
                            "id": 120794,
                            "name": "Los Osos",
                            "latitude": "35.31109000",
                            "longitude": "-120.83240000"
                        },
                        {
                            "id": 120796,
                            "name": "Los Serranos",
                            "latitude": "33.97279000",
                            "longitude": "-117.70811000"
                        },
                        {
                            "id": 120798,
                            "name": "Lost Hills",
                            "latitude": "35.61635000",
                            "longitude": "-119.69429000"
                        },
                        {
                            "id": 120842,
                            "name": "Lower Lake",
                            "latitude": "38.91045000",
                            "longitude": "-122.61026000"
                        },
                        {
                            "id": 120855,
                            "name": "Loyola",
                            "latitude": "37.35133000",
                            "longitude": "-122.10052000"
                        },
                        {
                            "id": 120862,
                            "name": "Lucas Valley-Marinwood",
                            "latitude": "38.04011000",
                            "longitude": "-122.57550000"
                        },
                        {
                            "id": 120865,
                            "name": "Lucerne",
                            "latitude": "36.38078000",
                            "longitude": "-119.66430000"
                        },
                        {
                            "id": 120866,
                            "name": "Lucerne Valley",
                            "latitude": "34.44389000",
                            "longitude": "-116.96781000"
                        },
                        {
                            "id": 120934,
                            "name": "Lynwood",
                            "latitude": "33.93029000",
                            "longitude": "-118.21146000"
                        },
                        {
                            "id": 120980,
                            "name": "Madera",
                            "latitude": "36.96134000",
                            "longitude": "-120.06072000"
                        },
                        {
                            "id": 120981,
                            "name": "Madera Acres",
                            "latitude": "37.01911000",
                            "longitude": "-120.06683000"
                        },
                        {
                            "id": 120982,
                            "name": "Madera County",
                            "latitude": "37.21804000",
                            "longitude": "-119.76265000"
                        },
                        {
                            "id": 121031,
                            "name": "Magalia",
                            "latitude": "39.81211000",
                            "longitude": "-121.57831000"
                        },
                        {
                            "id": 121062,
                            "name": "Malibu",
                            "latitude": "34.02577000",
                            "longitude": "-118.78040000"
                        },
                        {
                            "id": 121077,
                            "name": "Mammoth Lakes",
                            "latitude": "37.64855000",
                            "longitude": "-118.97208000"
                        },
                        {
                            "id": 121117,
                            "name": "Manhattan Beach",
                            "latitude": "33.88474000",
                            "longitude": "-118.41091000"
                        },
                        {
                            "id": 121154,
                            "name": "Manteca",
                            "latitude": "37.79743000",
                            "longitude": "-121.21605000"
                        },
                        {
                            "id": 121196,
                            "name": "March Air Force Base",
                            "latitude": "33.89209000",
                            "longitude": "-117.26310000"
                        },
                        {
                            "id": 121212,
                            "name": "Maricopa",
                            "latitude": "35.05886000",
                            "longitude": "-119.40095000"
                        },
                        {
                            "id": 121221,
                            "name": "Marin City",
                            "latitude": "37.86854000",
                            "longitude": "-122.50914000"
                        },
                        {
                            "id": 121222,
                            "name": "Marin County",
                            "latitude": "38.05518000",
                            "longitude": "-122.74886000"
                        },
                        {
                            "id": 121223,
                            "name": "Marina",
                            "latitude": "36.68440000",
                            "longitude": "-121.80217000"
                        },
                        {
                            "id": 121224,
                            "name": "Marina del Rey",
                            "latitude": "33.98029000",
                            "longitude": "-118.45174000"
                        },
                        {
                            "id": 121264,
                            "name": "Mariposa",
                            "latitude": "37.48494000",
                            "longitude": "-119.96628000"
                        },
                        {
                            "id": 121265,
                            "name": "Mariposa County",
                            "latitude": "37.58152000",
                            "longitude": "-119.90552000"
                        },
                        {
                            "id": 121345,
                            "name": "Martinez",
                            "latitude": "38.01937000",
                            "longitude": "-122.13413000"
                        },
                        {
                            "id": 121360,
                            "name": "Marysville",
                            "latitude": "39.14573000",
                            "longitude": "-121.59135000"
                        },
                        {
                            "id": 121401,
                            "name": "Matheny",
                            "latitude": "36.17066000",
                            "longitude": "-119.35158000"
                        },
                        {
                            "id": 121430,
                            "name": "Maxwell",
                            "latitude": "39.27628000",
                            "longitude": "-122.19137000"
                        },
                        {
                            "id": 121439,
                            "name": "Mayflower Village",
                            "latitude": "34.11501000",
                            "longitude": "-118.00979000"
                        },
                        {
                            "id": 121459,
                            "name": "Maywood",
                            "latitude": "33.98668000",
                            "longitude": "-118.18535000"
                        },
                        {
                            "id": 121471,
                            "name": "McCloud",
                            "latitude": "41.25571000",
                            "longitude": "-122.13945000"
                        },
                        {
                            "id": 121500,
                            "name": "McFarland",
                            "latitude": "35.67801000",
                            "longitude": "-119.22927000"
                        },
                        {
                            "id": 121524,
                            "name": "McKinleyville",
                            "latitude": "40.94652000",
                            "longitude": "-124.10062000"
                        },
                        {
                            "id": 121554,
                            "name": "Mead Valley",
                            "latitude": "33.83335000",
                            "longitude": "-117.29615000"
                        },
                        {
                            "id": 121563,
                            "name": "Meadow Vista",
                            "latitude": "39.00101000",
                            "longitude": "-121.02189000"
                        },
                        {
                            "id": 121567,
                            "name": "Meadowbrook",
                            "latitude": "33.72578000",
                            "longitude": "-117.28509000"
                        },
                        {
                            "id": 121578,
                            "name": "Mecca",
                            "latitude": "33.57219000",
                            "longitude": "-116.07820000"
                        },
                        {
                            "id": 121619,
                            "name": "Meiners Oaks",
                            "latitude": "34.44694000",
                            "longitude": "-119.27928000"
                        },
                        {
                            "id": 121652,
                            "name": "Mendocino County",
                            "latitude": "39.43362000",
                            "longitude": "-123.43155000"
                        },
                        {
                            "id": 121657,
                            "name": "Mendota",
                            "latitude": "36.75356000",
                            "longitude": "-120.38156000"
                        },
                        {
                            "id": 121659,
                            "name": "Menifee",
                            "latitude": "33.72835000",
                            "longitude": "-117.14642000"
                        },
                        {
                            "id": 121661,
                            "name": "Menlo Park",
                            "latitude": "37.45383000",
                            "longitude": "-122.18219000"
                        },
                        {
                            "id": 121667,
                            "name": "Mentone",
                            "latitude": "34.07001000",
                            "longitude": "-117.13448000"
                        },
                        {
                            "id": 121671,
                            "name": "Merced",
                            "latitude": "37.30216000",
                            "longitude": "-120.48297000"
                        },
                        {
                            "id": 121672,
                            "name": "Merced County",
                            "latitude": "37.19186000",
                            "longitude": "-120.71767000"
                        },
                        {
                            "id": 121722,
                            "name": "Mesa Verde",
                            "latitude": "33.60586000",
                            "longitude": "-114.73107000"
                        },
                        {
                            "id": 121794,
                            "name": "Middletown",
                            "latitude": "38.75240000",
                            "longitude": "-122.61499000"
                        },
                        {
                            "id": 121809,
                            "name": "Midpines",
                            "latitude": "37.54438000",
                            "longitude": "-119.92045000"
                        },
                        {
                            "id": 121821,
                            "name": "Midway City",
                            "latitude": "33.74474000",
                            "longitude": "-117.98923000"
                        },
                        {
                            "id": 121868,
                            "name": "Mill Valley",
                            "latitude": "37.90604000",
                            "longitude": "-122.54498000"
                        },
                        {
                            "id": 121871,
                            "name": "Millbrae",
                            "latitude": "37.59855000",
                            "longitude": "-122.38719000"
                        },
                        {
                            "id": 121909,
                            "name": "Milpitas",
                            "latitude": "37.42827000",
                            "longitude": "-121.90662000"
                        },
                        {
                            "id": 121949,
                            "name": "Minkler",
                            "latitude": "36.72384000",
                            "longitude": "-119.45818000"
                        },
                        {
                            "id": 121971,
                            "name": "Mira Mesa",
                            "latitude": "32.91560000",
                            "longitude": "-117.14392000"
                        },
                        {
                            "id": 121972,
                            "name": "Mira Monte",
                            "latitude": "34.43361000",
                            "longitude": "-119.28511000"
                        },
                        {
                            "id": 121984,
                            "name": "Mission Canyon",
                            "latitude": "34.45083000",
                            "longitude": "-119.71291000"
                        },
                        {
                            "id": 121985,
                            "name": "Mission District",
                            "latitude": "37.75993000",
                            "longitude": "-122.41914000"
                        },
                        {
                            "id": 121987,
                            "name": "Mission Hills",
                            "latitude": "34.68609000",
                            "longitude": "-120.43683000"
                        },
                        {
                            "id": 121988,
                            "name": "Mission Viejo",
                            "latitude": "33.60002000",
                            "longitude": "-117.67200000"
                        },
                        {
                            "id": 122014,
                            "name": "Modesto",
                            "latitude": "37.63910000",
                            "longitude": "-120.99688000"
                        },
                        {
                            "id": 122015,
                            "name": "Modoc County",
                            "latitude": "41.58985000",
                            "longitude": "-120.72497000"
                        },
                        {
                            "id": 122023,
                            "name": "Mojave",
                            "latitude": "35.05247000",
                            "longitude": "-118.17396000"
                        },
                        {
                            "id": 122048,
                            "name": "Mono County",
                            "latitude": "37.93899000",
                            "longitude": "-118.88671000"
                        },
                        {
                            "id": 122049,
                            "name": "Mono Vista",
                            "latitude": "37.99770000",
                            "longitude": "-120.26991000"
                        },
                        {
                            "id": 122088,
                            "name": "Monrovia",
                            "latitude": "34.14806000",
                            "longitude": "-117.99895000"
                        },
                        {
                            "id": 122098,
                            "name": "Montague",
                            "latitude": "41.72820000",
                            "longitude": "-122.52780000"
                        },
                        {
                            "id": 122100,
                            "name": "Montalvin",
                            "latitude": "37.99548000",
                            "longitude": "-122.33275000"
                        },
                        {
                            "id": 122102,
                            "name": "Montara",
                            "latitude": "37.54216000",
                            "longitude": "-122.51609000"
                        },
                        {
                            "id": 122107,
                            "name": "Montclair",
                            "latitude": "34.07751000",
                            "longitude": "-117.68978000"
                        },
                        {
                            "id": 122109,
                            "name": "Monte Rio",
                            "latitude": "38.46547000",
                            "longitude": "-123.00889000"
                        },
                        {
                            "id": 122110,
                            "name": "Monte Sereno",
                            "latitude": "37.23633000",
                            "longitude": "-121.99246000"
                        },
                        {
                            "id": 122114,
                            "name": "Montebello",
                            "latitude": "34.00946000",
                            "longitude": "-118.10535000"
                        },
                        {
                            "id": 122115,
                            "name": "Montecito",
                            "latitude": "34.43666000",
                            "longitude": "-119.63208000"
                        },
                        {
                            "id": 122120,
                            "name": "Monterey",
                            "latitude": "36.60024000",
                            "longitude": "-121.89468000"
                        },
                        {
                            "id": 122121,
                            "name": "Monterey County",
                            "latitude": "36.23977000",
                            "longitude": "-121.30890000"
                        },
                        {
                            "id": 122122,
                            "name": "Monterey Park",
                            "latitude": "34.06251000",
                            "longitude": "-118.12285000"
                        },
                        {
                            "id": 122193,
                            "name": "Monument Hills",
                            "latitude": "38.66429000",
                            "longitude": "-121.87566000"
                        },
                        {
                            "id": 122213,
                            "name": "Moorpark",
                            "latitude": "34.28556000",
                            "longitude": "-118.88204000"
                        },
                        {
                            "id": 122221,
                            "name": "Morada",
                            "latitude": "38.03853000",
                            "longitude": "-121.24578000"
                        },
                        {
                            "id": 122222,
                            "name": "Moraga",
                            "latitude": "37.83493000",
                            "longitude": "-122.12969000"
                        },
                        {
                            "id": 122231,
                            "name": "Moreno Valley",
                            "latitude": "33.93752000",
                            "longitude": "-117.23059000"
                        },
                        {
                            "id": 122246,
                            "name": "Morgan Hill",
                            "latitude": "37.13050000",
                            "longitude": "-121.65439000"
                        },
                        {
                            "id": 122261,
                            "name": "Morongo Valley",
                            "latitude": "34.04695000",
                            "longitude": "-116.58085000"
                        },
                        {
                            "id": 122288,
                            "name": "Morro Bay",
                            "latitude": "35.36581000",
                            "longitude": "-120.84990000"
                        },
                        {
                            "id": 122307,
                            "name": "Moss Beach",
                            "latitude": "37.52744000",
                            "longitude": "-122.51331000"
                        },
                        {
                            "id": 122347,
                            "name": "Mount Hermon",
                            "latitude": "37.05106000",
                            "longitude": "-122.05857000"
                        },
                        {
                            "id": 122388,
                            "name": "Mount Shasta",
                            "latitude": "41.31024000",
                            "longitude": "-122.31225000"
                        },
                        {
                            "id": 122418,
                            "name": "Mountain House",
                            "latitude": "37.78326000",
                            "longitude": "-121.54273000"
                        },
                        {
                            "id": 122425,
                            "name": "Mountain Ranch",
                            "latitude": "38.22825000",
                            "longitude": "-120.54076000"
                        },
                        {
                            "id": 122431,
                            "name": "Mountain View",
                            "latitude": "37.38605000",
                            "longitude": "-122.08385000"
                        },
                        {
                            "id": 122434,
                            "name": "Mountain View Acres",
                            "latitude": "34.49666000",
                            "longitude": "-117.34894000"
                        },
                        {
                            "id": 122488,
                            "name": "Murphys",
                            "latitude": "38.13762000",
                            "longitude": "-120.46105000"
                        },
                        {
                            "id": 122498,
                            "name": "Murrieta",
                            "latitude": "33.55391000",
                            "longitude": "-117.21392000"
                        },
                        {
                            "id": 122499,
                            "name": "Murrieta Hot Springs",
                            "latitude": "33.56058000",
                            "longitude": "-117.15809000"
                        },
                        {
                            "id": 122506,
                            "name": "Muscoy",
                            "latitude": "34.15418000",
                            "longitude": "-117.34421000"
                        },
                        {
                            "id": 122525,
                            "name": "Myrtletown",
                            "latitude": "40.78874000",
                            "longitude": "-124.13034000"
                        },
                        {
                            "id": 122546,
                            "name": "Napa",
                            "latitude": "38.29714000",
                            "longitude": "-122.28553000"
                        },
                        {
                            "id": 122547,
                            "name": "Napa County",
                            "latitude": "38.50647000",
                            "longitude": "-122.33053000"
                        },
                        {
                            "id": 122591,
                            "name": "National City",
                            "latitude": "32.67811000",
                            "longitude": "-117.09920000"
                        },
                        {
                            "id": 122614,
                            "name": "Needles",
                            "latitude": "34.84806000",
                            "longitude": "-114.61413000"
                        },
                        {
                            "id": 122651,
                            "name": "Nevada City",
                            "latitude": "39.26173000",
                            "longitude": "-121.01779000"
                        },
                        {
                            "id": 122653,
                            "name": "Nevada County",
                            "latitude": "39.30137000",
                            "longitude": "-120.76875000"
                        },
                        {
                            "id": 122805,
                            "name": "Newark",
                            "latitude": "37.52966000",
                            "longitude": "-122.04024000"
                        },
                        {
                            "id": 122821,
                            "name": "Newcastle",
                            "latitude": "38.87407000",
                            "longitude": "-121.13328000"
                        },
                        {
                            "id": 122834,
                            "name": "Newman",
                            "latitude": "37.31383000",
                            "longitude": "-121.02076000"
                        },
                        {
                            "id": 122853,
                            "name": "Newport Beach",
                            "latitude": "33.61891000",
                            "longitude": "-117.92895000"
                        },
                        {
                            "id": 122885,
                            "name": "Nice",
                            "latitude": "39.12323000",
                            "longitude": "-122.84833000"
                        },
                        {
                            "id": 122899,
                            "name": "Niland",
                            "latitude": "33.24004000",
                            "longitude": "-115.51888000"
                        },
                        {
                            "id": 122905,
                            "name": "Nipomo",
                            "latitude": "35.04275000",
                            "longitude": "-120.47600000"
                        },
                        {
                            "id": 122925,
                            "name": "Noe Valley",
                            "latitude": "37.75018000",
                            "longitude": "-122.43369000"
                        },
                        {
                            "id": 122939,
                            "name": "Norco",
                            "latitude": "33.93113000",
                            "longitude": "-117.54866000"
                        },
                        {
                            "id": 122968,
                            "name": "North Auburn",
                            "latitude": "38.93129000",
                            "longitude": "-121.08189000"
                        },
                        {
                            "id": 123020,
                            "name": "North Edwards",
                            "latitude": "35.01664000",
                            "longitude": "-117.83284000"
                        },
                        {
                            "id": 123021,
                            "name": "North El Monte",
                            "latitude": "34.10279000",
                            "longitude": "-118.02423000"
                        },
                        {
                            "id": 123024,
                            "name": "North Fair Oaks",
                            "latitude": "37.47438000",
                            "longitude": "-122.19663000"
                        },
                        {
                            "id": 123039,
                            "name": "North Highlands",
                            "latitude": "38.68574000",
                            "longitude": "-121.37217000"
                        },
                        {
                            "id": 123041,
                            "name": "North Hills",
                            "latitude": "34.23639000",
                            "longitude": "-118.48472000"
                        },
                        {
                            "id": 123042,
                            "name": "North Hollywood",
                            "latitude": "34.17223000",
                            "longitude": "-118.37897000"
                        },
                        {
                            "id": 123050,
                            "name": "North Lakeport",
                            "latitude": "39.08831000",
                            "longitude": "-122.90538000"
                        },
                        {
                            "id": 123094,
                            "name": "North Richmond",
                            "latitude": "37.95881000",
                            "longitude": "-122.36747000"
                        },
                        {
                            "id": 123117,
                            "name": "North Tustin",
                            "latitude": "33.76446000",
                            "longitude": "-117.79394000"
                        },
                        {
                            "id": 123163,
                            "name": "Northridge",
                            "latitude": "34.22834000",
                            "longitude": "-118.53675000"
                        },
                        {
                            "id": 123191,
                            "name": "Norwalk",
                            "latitude": "33.90224000",
                            "longitude": "-118.08173000"
                        },
                        {
                            "id": 123207,
                            "name": "Novato",
                            "latitude": "38.10742000",
                            "longitude": "-122.56970000"
                        },
                        {
                            "id": 123216,
                            "name": "Nuevo",
                            "latitude": "33.80141000",
                            "longitude": "-117.14587000"
                        },
                        {
                            "id": 123248,
                            "name": "Oak Hills",
                            "latitude": "34.38313000",
                            "longitude": "-117.38135000"
                        },
                        {
                            "id": 123257,
                            "name": "Oak Park",
                            "latitude": "34.17917000",
                            "longitude": "-118.76287000"
                        },
                        {
                            "id": 123266,
                            "name": "Oak View",
                            "latitude": "34.40000000",
                            "longitude": "-119.30011000"
                        },
                        {
                            "id": 123274,
                            "name": "Oakdale",
                            "latitude": "37.76659000",
                            "longitude": "-120.84715000"
                        },
                        {
                            "id": 123281,
                            "name": "Oakhurst",
                            "latitude": "37.32800000",
                            "longitude": "-119.64932000"
                        },
                        {
                            "id": 123293,
                            "name": "Oakland",
                            "latitude": "37.80437000",
                            "longitude": "-122.27080000"
                        },
                        {
                            "id": 123298,
                            "name": "Oakley",
                            "latitude": "37.99742000",
                            "longitude": "-121.71245000"
                        },
                        {
                            "id": 123313,
                            "name": "Oasis",
                            "latitude": "33.46586000",
                            "longitude": "-116.09889000"
                        },
                        {
                            "id": 123321,
                            "name": "Occidental",
                            "latitude": "38.40741000",
                            "longitude": "-122.94833000"
                        },
                        {
                            "id": 123341,
                            "name": "Oceano",
                            "latitude": "35.09886000",
                            "longitude": "-120.61239000"
                        },
                        {
                            "id": 123344,
                            "name": "Oceanside",
                            "latitude": "33.19587000",
                            "longitude": "-117.37948000"
                        },
                        {
                            "id": 123388,
                            "name": "Oildale",
                            "latitude": "35.41968000",
                            "longitude": "-119.01955000"
                        },
                        {
                            "id": 123390,
                            "name": "Ojai",
                            "latitude": "34.44805000",
                            "longitude": "-119.24289000"
                        },
                        {
                            "id": 123418,
                            "name": "Old Fig Garden",
                            "latitude": "36.79885000",
                            "longitude": "-119.80515000"
                        },
                        {
                            "id": 123441,
                            "name": "Olivehurst",
                            "latitude": "39.09545000",
                            "longitude": "-121.55219000"
                        },
                        {
                            "id": 123486,
                            "name": "Ontario",
                            "latitude": "34.06334000",
                            "longitude": "-117.65089000"
                        },
                        {
                            "id": 123495,
                            "name": "Opal Cliffs",
                            "latitude": "36.96078000",
                            "longitude": "-121.96413000"
                        },
                        {
                            "id": 123510,
                            "name": "Orange",
                            "latitude": "33.78779000",
                            "longitude": "-117.85311000"
                        },
                        {
                            "id": 123521,
                            "name": "Orange County",
                            "latitude": "33.67691000",
                            "longitude": "-117.77617000"
                        },
                        {
                            "id": 123522,
                            "name": "Orange Cove",
                            "latitude": "36.62439000",
                            "longitude": "-119.31373000"
                        },
                        {
                            "id": 123530,
                            "name": "Orangevale",
                            "latitude": "38.67851000",
                            "longitude": "-121.22578000"
                        },
                        {
                            "id": 123541,
                            "name": "Orcutt",
                            "latitude": "34.86526000",
                            "longitude": "-120.43600000"
                        },
                        {
                            "id": 123554,
                            "name": "Orinda",
                            "latitude": "37.87715000",
                            "longitude": "-122.17969000"
                        },
                        {
                            "id": 123559,
                            "name": "Orland",
                            "latitude": "39.74738000",
                            "longitude": "-122.19637000"
                        },
                        {
                            "id": 123577,
                            "name": "Orosi",
                            "latitude": "36.54495000",
                            "longitude": "-119.28734000"
                        },
                        {
                            "id": 123578,
                            "name": "Oroville",
                            "latitude": "39.51394000",
                            "longitude": "-121.55776000"
                        },
                        {
                            "id": 123580,
                            "name": "Oroville East",
                            "latitude": "39.51126000",
                            "longitude": "-121.47519000"
                        },
                        {
                            "id": 123689,
                            "name": "Oxnard",
                            "latitude": "34.19750000",
                            "longitude": "-119.17705000"
                        },
                        {
                            "id": 123705,
                            "name": "Pacheco",
                            "latitude": "37.98353000",
                            "longitude": "-122.07524000"
                        },
                        {
                            "id": 123710,
                            "name": "Pacific Grove",
                            "latitude": "36.61774000",
                            "longitude": "-121.91662000"
                        },
                        {
                            "id": 123711,
                            "name": "Pacifica",
                            "latitude": "37.61383000",
                            "longitude": "-122.48692000"
                        },
                        {
                            "id": 123729,
                            "name": "Pajaro",
                            "latitude": "36.90412000",
                            "longitude": "-121.74856000"
                        },
                        {
                            "id": 123735,
                            "name": "Palermo",
                            "latitude": "39.43544000",
                            "longitude": "-121.53802000"
                        },
                        {
                            "id": 123748,
                            "name": "Palm Desert",
                            "latitude": "33.72255000",
                            "longitude": "-116.37697000"
                        },
                        {
                            "id": 123752,
                            "name": "Palm Springs",
                            "latitude": "33.83030000",
                            "longitude": "-116.54529000"
                        },
                        {
                            "id": 123757,
                            "name": "Palmdale",
                            "latitude": "34.57943000",
                            "longitude": "-118.11646000"
                        },
                        {
                            "id": 123781,
                            "name": "Palo Alto",
                            "latitude": "37.44188000",
                            "longitude": "-122.14302000"
                        },
                        {
                            "id": 123783,
                            "name": "Palo Cedro",
                            "latitude": "40.56376000",
                            "longitude": "-122.23889000"
                        },
                        {
                            "id": 123791,
                            "name": "Palos Verdes Estates",
                            "latitude": "33.80105000",
                            "longitude": "-118.39245000"
                        },
                        {
                            "id": 123818,
                            "name": "Paradise",
                            "latitude": "39.75961000",
                            "longitude": "-121.62192000"
                        },
                        {
                            "id": 123824,
                            "name": "Paramount",
                            "latitude": "33.88946000",
                            "longitude": "-118.15979000"
                        },
                        {
                            "id": 123874,
                            "name": "Parksdale",
                            "latitude": "36.94717000",
                            "longitude": "-120.02294000"
                        },
                        {
                            "id": 123881,
                            "name": "Parkway",
                            "latitude": "38.49602000",
                            "longitude": "-121.45884000"
                        },
                        {
                            "id": 123882,
                            "name": "Parkwood",
                            "latitude": "36.92689000",
                            "longitude": "-120.04461000"
                        },
                        {
                            "id": 123884,
                            "name": "Parlier",
                            "latitude": "36.61162000",
                            "longitude": "-119.52707000"
                        },
                        {
                            "id": 123897,
                            "name": "Pasadena",
                            "latitude": "34.14778000",
                            "longitude": "-118.14452000"
                        },
                        {
                            "id": 123899,
                            "name": "Pasatiempo",
                            "latitude": "37.00439000",
                            "longitude": "-122.02580000"
                        },
                        {
                            "id": 123904,
                            "name": "Paso Robles",
                            "latitude": "35.62664000",
                            "longitude": "-120.69100000"
                        },
                        {
                            "id": 123916,
                            "name": "Patterson",
                            "latitude": "37.47160000",
                            "longitude": "-121.12966000"
                        },
                        {
                            "id": 123917,
                            "name": "Patterson Tract",
                            "latitude": "36.37952000",
                            "longitude": "-119.29560000"
                        },
                        {
                            "id": 123983,
                            "name": "Pedley",
                            "latitude": "33.97529000",
                            "longitude": "-117.47588000"
                        },
                        {
                            "id": 124024,
                            "name": "Penn Valley",
                            "latitude": "39.19600000",
                            "longitude": "-121.19107000"
                        },
                        {
                            "id": 124028,
                            "name": "Penngrove",
                            "latitude": "38.29964000",
                            "longitude": "-122.66665000"
                        },
                        {
                            "id": 124066,
                            "name": "Perris",
                            "latitude": "33.78252000",
                            "longitude": "-117.22865000"
                        },
                        {
                            "id": 124101,
                            "name": "Petaluma",
                            "latitude": "38.23242000",
                            "longitude": "-122.63665000"
                        },
                        {
                            "id": 124119,
                            "name": "Phelan",
                            "latitude": "34.42611000",
                            "longitude": "-117.57228000"
                        },
                        {
                            "id": 124150,
                            "name": "Phoenix Lake",
                            "latitude": "38.00594000",
                            "longitude": "-120.30702000"
                        },
                        {
                            "id": 124162,
                            "name": "Pico Rivera",
                            "latitude": "33.98307000",
                            "longitude": "-118.09673000"
                        },
                        {
                            "id": 124168,
                            "name": "Piedmont",
                            "latitude": "37.82437000",
                            "longitude": "-122.23163000"
                        },
                        {
                            "id": 124222,
                            "name": "Pine Grove",
                            "latitude": "38.41297000",
                            "longitude": "-120.65882000"
                        },
                        {
                            "id": 124226,
                            "name": "Pine Hills",
                            "latitude": "40.73318000",
                            "longitude": "-124.15228000"
                        },
                        {
                            "id": 124239,
                            "name": "Pine Mountain Club",
                            "latitude": "34.84637000",
                            "longitude": "-119.14955000"
                        },
                        {
                            "id": 124246,
                            "name": "Pine Valley",
                            "latitude": "32.82144000",
                            "longitude": "-116.52918000"
                        },
                        {
                            "id": 124271,
                            "name": "Pinole",
                            "latitude": "38.00437000",
                            "longitude": "-122.29886000"
                        },
                        {
                            "id": 124309,
                            "name": "Piñon Hills",
                            "latitude": "34.43333000",
                            "longitude": "-117.64672000"
                        },
                        {
                            "id": 124274,
                            "name": "Pioneer",
                            "latitude": "38.43186000",
                            "longitude": "-120.57187000"
                        },
                        {
                            "id": 124280,
                            "name": "Piru",
                            "latitude": "34.41527000",
                            "longitude": "-118.79398000"
                        },
                        {
                            "id": 124283,
                            "name": "Pismo Beach",
                            "latitude": "35.14275000",
                            "longitude": "-120.64128000"
                        },
                        {
                            "id": 124294,
                            "name": "Pittsburg",
                            "latitude": "38.02798000",
                            "longitude": "-121.88468000"
                        },
                        {
                            "id": 124308,
                            "name": "Pixley",
                            "latitude": "35.96856000",
                            "longitude": "-119.29178000"
                        },
                        {
                            "id": 124310,
                            "name": "Placentia",
                            "latitude": "33.87224000",
                            "longitude": "-117.87034000"
                        },
                        {
                            "id": 124311,
                            "name": "Placer County",
                            "latitude": "39.06343000",
                            "longitude": "-120.71766000"
                        },
                        {
                            "id": 124312,
                            "name": "Placerville",
                            "latitude": "38.72963000",
                            "longitude": "-120.79855000"
                        },
                        {
                            "id": 124338,
                            "name": "Planada",
                            "latitude": "37.29077000",
                            "longitude": "-120.31852000"
                        },
                        {
                            "id": 124372,
                            "name": "Pleasant Hill",
                            "latitude": "37.94798000",
                            "longitude": "-122.06080000"
                        },
                        {
                            "id": 124384,
                            "name": "Pleasanton",
                            "latitude": "37.66243000",
                            "longitude": "-121.87468000"
                        },
                        {
                            "id": 124395,
                            "name": "Plumas County",
                            "latitude": "40.00468000",
                            "longitude": "-120.83860000"
                        },
                        {
                            "id": 124396,
                            "name": "Plumas Lake",
                            "latitude": "39.02073000",
                            "longitude": "-121.55802000"
                        },
                        {
                            "id": 124453,
                            "name": "Pollock Pines",
                            "latitude": "38.76158000",
                            "longitude": "-120.58611000"
                        },
                        {
                            "id": 124459,
                            "name": "Pomona",
                            "latitude": "34.05529000",
                            "longitude": "-117.75228000"
                        },
                        {
                            "id": 124486,
                            "name": "Poplar-Cotton Center",
                            "latitude": "36.05635000",
                            "longitude": "-119.14919000"
                        },
                        {
                            "id": 124510,
                            "name": "Port Hueneme",
                            "latitude": "34.14778000",
                            "longitude": "-119.19511000"
                        },
                        {
                            "id": 124556,
                            "name": "Porterville",
                            "latitude": "36.06523000",
                            "longitude": "-119.01677000"
                        },
                        {
                            "id": 124566,
                            "name": "Portola",
                            "latitude": "39.81046000",
                            "longitude": "-120.46910000"
                        },
                        {
                            "id": 124567,
                            "name": "Portola Hills",
                            "latitude": "33.67919000",
                            "longitude": "-117.63116000"
                        },
                        {
                            "id": 124568,
                            "name": "Portola Valley",
                            "latitude": "37.38411000",
                            "longitude": "-122.23524000"
                        },
                        {
                            "id": 124606,
                            "name": "Poway",
                            "latitude": "32.96282000",
                            "longitude": "-117.03586000"
                        },
                        {
                            "id": 124725,
                            "name": "Prunedale",
                            "latitude": "36.77579000",
                            "longitude": "-121.66967000"
                        },
                        {
                            "id": 124777,
                            "name": "Quail Valley",
                            "latitude": "33.70697000",
                            "longitude": "-117.24504000"
                        },
                        {
                            "id": 124782,
                            "name": "Quartz Hill",
                            "latitude": "34.64526000",
                            "longitude": "-118.21813000"
                        },
                        {
                            "id": 124799,
                            "name": "Quincy",
                            "latitude": "39.93682000",
                            "longitude": "-120.94647000"
                        },
                        {
                            "id": 124823,
                            "name": "Rainbow",
                            "latitude": "33.41031000",
                            "longitude": "-117.14781000"
                        },
                        {
                            "id": 124838,
                            "name": "Ramona",
                            "latitude": "33.04171000",
                            "longitude": "-116.86808000"
                        },
                        {
                            "id": 124848,
                            "name": "Rancho Calaveras",
                            "latitude": "38.12742000",
                            "longitude": "-120.85827000"
                        },
                        {
                            "id": 124849,
                            "name": "Rancho Cordova",
                            "latitude": "38.58907000",
                            "longitude": "-121.30273000"
                        },
                        {
                            "id": 124850,
                            "name": "Rancho Cucamonga",
                            "latitude": "34.10640000",
                            "longitude": "-117.59311000"
                        },
                        {
                            "id": 124851,
                            "name": "Rancho Mirage",
                            "latitude": "33.73974000",
                            "longitude": "-116.41279000"
                        },
                        {
                            "id": 124852,
                            "name": "Rancho Murieta",
                            "latitude": "38.50185000",
                            "longitude": "-121.09467000"
                        },
                        {
                            "id": 124853,
                            "name": "Rancho Palos Verdes",
                            "latitude": "33.74446000",
                            "longitude": "-118.38702000"
                        },
                        {
                            "id": 124854,
                            "name": "Rancho Penasquitos",
                            "latitude": "32.95949000",
                            "longitude": "-117.11531000"
                        },
                        {
                            "id": 124855,
                            "name": "Rancho San Diego",
                            "latitude": "32.74727000",
                            "longitude": "-116.93530000"
                        },
                        {
                            "id": 124856,
                            "name": "Rancho Santa Fe",
                            "latitude": "33.02032000",
                            "longitude": "-117.20281000"
                        },
                        {
                            "id": 124857,
                            "name": "Rancho Santa Margarita",
                            "latitude": "33.64086000",
                            "longitude": "-117.60310000"
                        },
                        {
                            "id": 124858,
                            "name": "Rancho Tehama Reserve",
                            "latitude": "40.01569000",
                            "longitude": "-122.40072000"
                        },
                        {
                            "id": 124940,
                            "name": "Red Bluff",
                            "latitude": "40.17849000",
                            "longitude": "-122.23583000"
                        },
                        {
                            "id": 124945,
                            "name": "Red Corral",
                            "latitude": "38.41165000",
                            "longitude": "-120.60552000"
                        },
                        {
                            "id": 124966,
                            "name": "Redding",
                            "latitude": "40.58654000",
                            "longitude": "-122.39168000"
                        },
                        {
                            "id": 124976,
                            "name": "Redlands",
                            "latitude": "34.05557000",
                            "longitude": "-117.18254000"
                        },
                        {
                            "id": 124980,
                            "name": "Redondo Beach",
                            "latitude": "33.84918000",
                            "longitude": "-118.38841000"
                        },
                        {
                            "id": 124983,
                            "name": "Redway",
                            "latitude": "40.12014000",
                            "longitude": "-123.82336000"
                        },
                        {
                            "id": 124986,
                            "name": "Redwood City",
                            "latitude": "37.48522000",
                            "longitude": "-122.23635000"
                        },
                        {
                            "id": 124989,
                            "name": "Redwood Shores",
                            "latitude": "37.53188000",
                            "longitude": "-122.24802000"
                        },
                        {
                            "id": 124990,
                            "name": "Redwood Valley",
                            "latitude": "39.26544000",
                            "longitude": "-123.20445000"
                        },
                        {
                            "id": 124993,
                            "name": "Reedley",
                            "latitude": "36.59634000",
                            "longitude": "-119.45040000"
                        },
                        {
                            "id": 125049,
                            "name": "Rialto",
                            "latitude": "34.10640000",
                            "longitude": "-117.37032000"
                        },
                        {
                            "id": 125066,
                            "name": "Richgrove",
                            "latitude": "35.79662000",
                            "longitude": "-119.10788000"
                        },
                        {
                            "id": 125096,
                            "name": "Richmond",
                            "latitude": "37.93576000",
                            "longitude": "-122.34775000"
                        },
                        {
                            "id": 125118,
                            "name": "Ridgecrest",
                            "latitude": "35.62246000",
                            "longitude": "-117.67090000"
                        },
                        {
                            "id": 125127,
                            "name": "Ridgemark",
                            "latitude": "36.81246000",
                            "longitude": "-121.36577000"
                        },
                        {
                            "id": 125151,
                            "name": "Rio Del Mar",
                            "latitude": "36.96828000",
                            "longitude": "-121.90023000"
                        },
                        {
                            "id": 125152,
                            "name": "Rio Dell",
                            "latitude": "40.49930000",
                            "longitude": "-124.10644000"
                        },
                        {
                            "id": 125157,
                            "name": "Rio Linda",
                            "latitude": "38.69101000",
                            "longitude": "-121.44857000"
                        },
                        {
                            "id": 125161,
                            "name": "Rio Vista",
                            "latitude": "38.16389000",
                            "longitude": "-121.69583000"
                        },
                        {
                            "id": 125168,
                            "name": "Ripon",
                            "latitude": "37.74159000",
                            "longitude": "-121.12438000"
                        },
                        {
                            "id": 125190,
                            "name": "Riverbank",
                            "latitude": "37.73604000",
                            "longitude": "-120.93549000"
                        },
                        {
                            "id": 125196,
                            "name": "Riverdale",
                            "latitude": "36.43106000",
                            "longitude": "-119.85958000"
                        },
                        {
                            "id": 125199,
                            "name": "Riverdale Park",
                            "latitude": "37.60938000",
                            "longitude": "-121.05188000"
                        },
                        {
                            "id": 125209,
                            "name": "Riverside",
                            "latitude": "33.95335000",
                            "longitude": "-117.39616000"
                        },
                        {
                            "id": 125210,
                            "name": "Riverside County",
                            "latitude": "33.74368000",
                            "longitude": "-115.99386000"
                        },
                        {
                            "id": 125313,
                            "name": "Rocklin",
                            "latitude": "38.79073000",
                            "longitude": "-121.23578000"
                        },
                        {
                            "id": 125341,
                            "name": "Rodeo",
                            "latitude": "38.03298000",
                            "longitude": "-122.26691000"
                        },
                        {
                            "id": 125358,
                            "name": "Rohnert Park",
                            "latitude": "38.33964000",
                            "longitude": "-122.70110000"
                        },
                        {
                            "id": 125366,
                            "name": "Rolling Hills",
                            "latitude": "33.75739000",
                            "longitude": "-118.35752000"
                        },
                        {
                            "id": 125367,
                            "name": "Rolling Hills Estates",
                            "latitude": "33.78779000",
                            "longitude": "-118.35813000"
                        },
                        {
                            "id": 125370,
                            "name": "Rollingwood",
                            "latitude": "37.96520000",
                            "longitude": "-122.32997000"
                        },
                        {
                            "id": 125384,
                            "name": "Romoland",
                            "latitude": "33.74585000",
                            "longitude": "-117.17503000"
                        },
                        {
                            "id": 125397,
                            "name": "Rosamond",
                            "latitude": "34.86414000",
                            "longitude": "-118.16341000"
                        },
                        {
                            "id": 125419,
                            "name": "Rosedale",
                            "latitude": "35.38357000",
                            "longitude": "-119.14538000"
                        },
                        {
                            "id": 125424,
                            "name": "Roseland",
                            "latitude": "38.42213000",
                            "longitude": "-122.72804000"
                        },
                        {
                            "id": 125429,
                            "name": "Rosemead",
                            "latitude": "34.08057000",
                            "longitude": "-118.07285000"
                        },
                        {
                            "id": 125431,
                            "name": "Rosemont",
                            "latitude": "38.55185000",
                            "longitude": "-121.36467000"
                        },
                        {
                            "id": 125441,
                            "name": "Roseville",
                            "latitude": "38.75212000",
                            "longitude": "-121.28801000"
                        },
                        {
                            "id": 125451,
                            "name": "Ross",
                            "latitude": "37.96242000",
                            "longitude": "-122.55498000"
                        },
                        {
                            "id": 125455,
                            "name": "Rossmoor",
                            "latitude": "33.78557000",
                            "longitude": "-118.08506000"
                        },
                        {
                            "id": 125480,
                            "name": "Rowland Heights",
                            "latitude": "33.97612000",
                            "longitude": "-117.90534000"
                        },
                        {
                            "id": 125501,
                            "name": "Rubidoux",
                            "latitude": "33.99613000",
                            "longitude": "-117.40560000"
                        },
                        {
                            "id": 125514,
                            "name": "Running Springs",
                            "latitude": "34.20779000",
                            "longitude": "-117.10920000"
                        },
                        {
                            "id": 125577,
                            "name": "Sacramento",
                            "latitude": "38.58157000",
                            "longitude": "-121.49440000"
                        },
                        {
                            "id": 125578,
                            "name": "Sacramento County",
                            "latitude": "38.44932000",
                            "longitude": "-121.34424000"
                        },
                        {
                            "id": 125647,
                            "name": "Saint Helena",
                            "latitude": "38.50519000",
                            "longitude": "-122.47026000"
                        },
                        {
                            "id": 125742,
                            "name": "Salida",
                            "latitude": "37.70576000",
                            "longitude": "-121.08494000"
                        },
                        {
                            "id": 125747,
                            "name": "Salinas",
                            "latitude": "36.67774000",
                            "longitude": "-121.65550000"
                        },
                        {
                            "id": 125770,
                            "name": "Salton City",
                            "latitude": "33.29865000",
                            "longitude": "-115.95611000"
                        },
                        {
                            "id": 125783,
                            "name": "San Andreas",
                            "latitude": "38.19603000",
                            "longitude": "-120.68049000"
                        },
                        {
                            "id": 125785,
                            "name": "San Anselmo",
                            "latitude": "37.97465000",
                            "longitude": "-122.56164000"
                        },
                        {
                            "id": 125788,
                            "name": "San Antonio Heights",
                            "latitude": "34.15556000",
                            "longitude": "-117.65644000"
                        },
                        {
                            "id": 125792,
                            "name": "San Benito County",
                            "latitude": "36.60571000",
                            "longitude": "-121.07500000"
                        },
                        {
                            "id": 125793,
                            "name": "San Bernardino",
                            "latitude": "34.10834000",
                            "longitude": "-117.28977000"
                        },
                        {
                            "id": 125794,
                            "name": "San Bernardino County",
                            "latitude": "34.84143000",
                            "longitude": "-116.17846000"
                        },
                        {
                            "id": 125795,
                            "name": "San Bruno",
                            "latitude": "37.63049000",
                            "longitude": "-122.41108000"
                        },
                        {
                            "id": 125798,
                            "name": "San Carlos",
                            "latitude": "37.50716000",
                            "longitude": "-122.26052000"
                        },
                        {
                            "id": 125800,
                            "name": "San Clemente",
                            "latitude": "33.42697000",
                            "longitude": "-117.61199000"
                        },
                        {
                            "id": 125802,
                            "name": "San Diego",
                            "latitude": "32.71571000",
                            "longitude": "-117.16472000"
                        },
                        {
                            "id": 125803,
                            "name": "San Diego Country Estates",
                            "latitude": "33.00671000",
                            "longitude": "-116.78364000"
                        },
                        {
                            "id": 125804,
                            "name": "San Diego County",
                            "latitude": "33.02820000",
                            "longitude": "-116.77021000"
                        },
                        {
                            "id": 125805,
                            "name": "San Dimas",
                            "latitude": "34.10668000",
                            "longitude": "-117.80673000"
                        },
                        {
                            "id": 125808,
                            "name": "San Fernando",
                            "latitude": "34.28195000",
                            "longitude": "-118.43897000"
                        },
                        {
                            "id": 125809,
                            "name": "San Francisco",
                            "latitude": "37.77493000",
                            "longitude": "-122.41942000"
                        },
                        {
                            "id": 125810,
                            "name": "San Gabriel",
                            "latitude": "34.09611000",
                            "longitude": "-118.10583000"
                        },
                        {
                            "id": 125811,
                            "name": "San Jacinto",
                            "latitude": "33.78391000",
                            "longitude": "-116.95864000"
                        },
                        {
                            "id": 125813,
                            "name": "San Joaquin",
                            "latitude": "36.60662000",
                            "longitude": "-120.18904000"
                        },
                        {
                            "id": 125814,
                            "name": "San Joaquin County",
                            "latitude": "37.93478000",
                            "longitude": "-121.27145000"
                        },
                        {
                            "id": 125815,
                            "name": "San Joaquin Hills",
                            "latitude": "33.61169000",
                            "longitude": "-117.83672000"
                        },
                        {
                            "id": 125816,
                            "name": "San Jose",
                            "latitude": "37.33939000",
                            "longitude": "-121.89496000"
                        },
                        {
                            "id": 125818,
                            "name": "San Juan Bautista",
                            "latitude": "36.84551000",
                            "longitude": "-121.53800000"
                        },
                        {
                            "id": 125819,
                            "name": "San Juan Capistrano",
                            "latitude": "33.50169000",
                            "longitude": "-117.66255000"
                        },
                        {
                            "id": 125824,
                            "name": "San Leandro",
                            "latitude": "37.72493000",
                            "longitude": "-122.15608000"
                        },
                        {
                            "id": 125826,
                            "name": "San Lorenzo",
                            "latitude": "37.68104000",
                            "longitude": "-122.12441000"
                        },
                        {
                            "id": 125829,
                            "name": "San Luis Obispo",
                            "latitude": "35.28275000",
                            "longitude": "-120.65962000"
                        },
                        {
                            "id": 125830,
                            "name": "San Luis Obispo County",
                            "latitude": "35.38742000",
                            "longitude": "-120.45220000"
                        },
                        {
                            "id": 125833,
                            "name": "San Marcos",
                            "latitude": "33.14337000",
                            "longitude": "-117.16614000"
                        },
                        {
                            "id": 125834,
                            "name": "San Marino",
                            "latitude": "34.12140000",
                            "longitude": "-118.10646000"
                        },
                        {
                            "id": 125835,
                            "name": "San Martin",
                            "latitude": "37.08495000",
                            "longitude": "-121.61022000"
                        },
                        {
                            "id": 125836,
                            "name": "San Mateo",
                            "latitude": "37.56299000",
                            "longitude": "-122.32553000"
                        },
                        {
                            "id": 125837,
                            "name": "San Mateo County",
                            "latitude": "37.43621000",
                            "longitude": "-122.35566000"
                        },
                        {
                            "id": 125838,
                            "name": "San Miguel",
                            "latitude": "35.75247000",
                            "longitude": "-120.69628000"
                        },
                        {
                            "id": 125842,
                            "name": "San Pablo",
                            "latitude": "37.96215000",
                            "longitude": "-122.34553000"
                        },
                        {
                            "id": 125843,
                            "name": "San Pasqual",
                            "latitude": "33.09171000",
                            "longitude": "-116.95392000"
                        },
                        {
                            "id": 125845,
                            "name": "San Pedro",
                            "latitude": "33.73585000",
                            "longitude": "-118.29229000"
                        },
                        {
                            "id": 125846,
                            "name": "San Rafael",
                            "latitude": "37.97353000",
                            "longitude": "-122.53109000"
                        },
                        {
                            "id": 125847,
                            "name": "San Ramon",
                            "latitude": "37.77993000",
                            "longitude": "-121.97802000"
                        },
                        {
                            "id": 125892,
                            "name": "Sanger",
                            "latitude": "36.70801000",
                            "longitude": "-119.55597000"
                        },
                        {
                            "id": 125899,
                            "name": "Santa Ana",
                            "latitude": "33.74557000",
                            "longitude": "-117.86783000"
                        },
                        {
                            "id": 125901,
                            "name": "Santa Barbara",
                            "latitude": "34.42083000",
                            "longitude": "-119.69819000"
                        },
                        {
                            "id": 125902,
                            "name": "Santa Barbara County",
                            "latitude": "34.53834000",
                            "longitude": "-120.03078000"
                        },
                        {
                            "id": 125903,
                            "name": "Santa Clara",
                            "latitude": "37.35411000",
                            "longitude": "-121.95524000"
                        },
                        {
                            "id": 125906,
                            "name": "Santa Clara County",
                            "latitude": "37.23249000",
                            "longitude": "-121.69627000"
                        },
                        {
                            "id": 125908,
                            "name": "Santa Clarita",
                            "latitude": "34.39166000",
                            "longitude": "-118.54259000"
                        },
                        {
                            "id": 125910,
                            "name": "Santa Cruz",
                            "latitude": "36.97412000",
                            "longitude": "-122.03080000"
                        },
                        {
                            "id": 125912,
                            "name": "Santa Cruz County",
                            "latitude": "37.02161000",
                            "longitude": "-122.00979000"
                        },
                        {
                            "id": 125916,
                            "name": "Santa Fe Springs",
                            "latitude": "33.94724000",
                            "longitude": "-118.08535000"
                        },
                        {
                            "id": 125917,
                            "name": "Santa Margarita",
                            "latitude": "35.38997000",
                            "longitude": "-120.60906000"
                        },
                        {
                            "id": 125918,
                            "name": "Santa Maria",
                            "latitude": "34.95303000",
                            "longitude": "-120.43572000"
                        },
                        {
                            "id": 125919,
                            "name": "Santa Monica",
                            "latitude": "34.01945000",
                            "longitude": "-118.49119000"
                        },
                        {
                            "id": 125920,
                            "name": "Santa Paula",
                            "latitude": "34.35417000",
                            "longitude": "-119.05927000"
                        },
                        {
                            "id": 125922,
                            "name": "Santa Rosa",
                            "latitude": "38.44047000",
                            "longitude": "-122.71443000"
                        },
                        {
                            "id": 125925,
                            "name": "Santa Susana",
                            "latitude": "34.27167000",
                            "longitude": "-118.70898000"
                        },
                        {
                            "id": 125927,
                            "name": "Santa Venetia",
                            "latitude": "37.99853000",
                            "longitude": "-122.52525000"
                        },
                        {
                            "id": 125928,
                            "name": "Santa Ynez",
                            "latitude": "34.61443000",
                            "longitude": "-120.07987000"
                        },
                        {
                            "id": 125930,
                            "name": "Santee",
                            "latitude": "32.83838000",
                            "longitude": "-116.97392000"
                        },
                        {
                            "id": 125937,
                            "name": "Saranap",
                            "latitude": "37.88492000",
                            "longitude": "-122.07607000"
                        },
                        {
                            "id": 125941,
                            "name": "Saratoga",
                            "latitude": "37.26383000",
                            "longitude": "-122.02301000"
                        },
                        {
                            "id": 125956,
                            "name": "Saticoy",
                            "latitude": "34.28306000",
                            "longitude": "-119.14983000"
                        },
                        {
                            "id": 125970,
                            "name": "Sausalito",
                            "latitude": "37.85909000",
                            "longitude": "-122.48525000"
                        },
                        {
                            "id": 126059,
                            "name": "Scotts Valley",
                            "latitude": "37.05106000",
                            "longitude": "-122.01468000"
                        },
                        {
                            "id": 126075,
                            "name": "Sea Ranch",
                            "latitude": "38.71519000",
                            "longitude": "-123.45445000"
                        },
                        {
                            "id": 126083,
                            "name": "Seacliff",
                            "latitude": "36.97412000",
                            "longitude": "-121.91579000"
                        },
                        {
                            "id": 126090,
                            "name": "Seal Beach",
                            "latitude": "33.74141000",
                            "longitude": "-118.10479000"
                        },
                        {
                            "id": 126095,
                            "name": "Searles Valley",
                            "latitude": "35.76745000",
                            "longitude": "-117.40395000"
                        },
                        {
                            "id": 126099,
                            "name": "Seaside",
                            "latitude": "36.61107000",
                            "longitude": "-121.85162000"
                        },
                        {
                            "id": 126108,
                            "name": "Sebastopol",
                            "latitude": "38.40214000",
                            "longitude": "-122.82388000"
                        },
                        {
                            "id": 126116,
                            "name": "Sedco Hills",
                            "latitude": "33.64169000",
                            "longitude": "-117.29087000"
                        },
                        {
                            "id": 126124,
                            "name": "Seeley",
                            "latitude": "32.79311000",
                            "longitude": "-115.69111000"
                        },
                        {
                            "id": 126141,
                            "name": "Selma",
                            "latitude": "36.57078000",
                            "longitude": "-119.61208000"
                        },
                        {
                            "id": 126176,
                            "name": "Seven Trees",
                            "latitude": "37.28605000",
                            "longitude": "-121.83856000"
                        },
                        {
                            "id": 126198,
                            "name": "Shackelford",
                            "latitude": "37.61382000",
                            "longitude": "-120.99271000"
                        },
                        {
                            "id": 126202,
                            "name": "Shadow Hills",
                            "latitude": "34.26195000",
                            "longitude": "-118.35175000"
                        },
                        {
                            "id": 126210,
                            "name": "Shafter",
                            "latitude": "35.50051000",
                            "longitude": "-119.27178000"
                        },
                        {
                            "id": 126217,
                            "name": "Shandon",
                            "latitude": "35.65525000",
                            "longitude": "-120.37543000"
                        },
                        {
                            "id": 126238,
                            "name": "Shasta",
                            "latitude": "40.59932000",
                            "longitude": "-122.49196000"
                        },
                        {
                            "id": 126239,
                            "name": "Shasta County",
                            "latitude": "40.76377000",
                            "longitude": "-122.04052000"
                        },
                        {
                            "id": 126240,
                            "name": "Shasta Lake",
                            "latitude": "40.68043000",
                            "longitude": "-122.37084000"
                        },
                        {
                            "id": 126321,
                            "name": "Sheridan",
                            "latitude": "38.97962000",
                            "longitude": "-121.37551000"
                        },
                        {
                            "id": 126336,
                            "name": "Sherman Oaks",
                            "latitude": "34.15112000",
                            "longitude": "-118.44925000"
                        },
                        {
                            "id": 126351,
                            "name": "Shingle Springs",
                            "latitude": "38.66574000",
                            "longitude": "-120.92605000"
                        },
                        {
                            "id": 126353,
                            "name": "Shingletown",
                            "latitude": "40.49238000",
                            "longitude": "-121.88916000"
                        },
                        {
                            "id": 126402,
                            "name": "Sierra County",
                            "latitude": "39.58040000",
                            "longitude": "-120.51600000"
                        },
                        {
                            "id": 126404,
                            "name": "Sierra Madre",
                            "latitude": "34.16167000",
                            "longitude": "-118.05285000"
                        },
                        {
                            "id": 126411,
                            "name": "Signal Hill",
                            "latitude": "33.80446000",
                            "longitude": "-118.16785000"
                        },
                        {
                            "id": 126431,
                            "name": "Silver Lake",
                            "latitude": "34.08668000",
                            "longitude": "-118.27023000"
                        },
                        {
                            "id": 126432,
                            "name": "Silver Lakes",
                            "latitude": "34.74558000",
                            "longitude": "-117.34098000"
                        },
                        {
                            "id": 126445,
                            "name": "Simi Valley",
                            "latitude": "34.26945000",
                            "longitude": "-118.78148000"
                        },
                        {
                            "id": 126465,
                            "name": "Siskiyou County",
                            "latitude": "41.59265000",
                            "longitude": "-122.54037000"
                        },
                        {
                            "id": 126486,
                            "name": "Sky Valley",
                            "latitude": "33.89001000",
                            "longitude": "-116.35251000"
                        },
                        {
                            "id": 126499,
                            "name": "Sleepy Hollow",
                            "latitude": "38.01048000",
                            "longitude": "-122.58442000"
                        },
                        {
                            "id": 126561,
                            "name": "Soda Bay",
                            "latitude": "39.00101000",
                            "longitude": "-122.78916000"
                        },
                        {
                            "id": 126565,
                            "name": "Solana Beach",
                            "latitude": "32.99115000",
                            "longitude": "-117.27115000"
                        },
                        {
                            "id": 126566,
                            "name": "Solano County",
                            "latitude": "38.26692000",
                            "longitude": "-121.94001000"
                        },
                        {
                            "id": 126568,
                            "name": "Soledad",
                            "latitude": "36.42469000",
                            "longitude": "-121.32632000"
                        },
                        {
                            "id": 126572,
                            "name": "Solvang",
                            "latitude": "34.59582000",
                            "longitude": "-120.13765000"
                        },
                        {
                            "id": 126598,
                            "name": "Sonoma",
                            "latitude": "38.29186000",
                            "longitude": "-122.45804000"
                        },
                        {
                            "id": 126599,
                            "name": "Sonoma County",
                            "latitude": "38.52529000",
                            "longitude": "-122.92254000"
                        },
                        {
                            "id": 126600,
                            "name": "Sonora",
                            "latitude": "37.98409000",
                            "longitude": "-120.38214000"
                        },
                        {
                            "id": 126604,
                            "name": "Soquel",
                            "latitude": "36.98801000",
                            "longitude": "-121.95663000"
                        },
                        {
                            "id": 126606,
                            "name": "Sorrento Valley",
                            "latitude": "32.89991000",
                            "longitude": "-117.19451000"
                        },
                        {
                            "id": 126608,
                            "name": "Soulsbyville",
                            "latitude": "37.98465000",
                            "longitude": "-120.26380000"
                        },
                        {
                            "id": 126648,
                            "name": "South Dos Palos",
                            "latitude": "36.96439000",
                            "longitude": "-120.65324000"
                        },
                        {
                            "id": 126650,
                            "name": "South El Monte",
                            "latitude": "34.05195000",
                            "longitude": "-118.04673000"
                        },
                        {
                            "id": 126659,
                            "name": "South Gate",
                            "latitude": "33.95474000",
                            "longitude": "-118.21202000"
                        },
                        {
                            "id": 126684,
                            "name": "South Lake Tahoe",
                            "latitude": "38.93324000",
                            "longitude": "-119.98435000"
                        },
                        {
                            "id": 126699,
                            "name": "South Oroville",
                            "latitude": "39.49655000",
                            "longitude": "-121.55219000"
                        },
                        {
                            "id": 126706,
                            "name": "South Pasadena",
                            "latitude": "34.11612000",
                            "longitude": "-118.15035000"
                        },
                        {
                            "id": 126723,
                            "name": "South San Francisco",
                            "latitude": "37.65466000",
                            "longitude": "-122.40775000"
                        },
                        {
                            "id": 126724,
                            "name": "South San Gabriel",
                            "latitude": "34.04915000",
                            "longitude": "-118.09462000"
                        },
                        {
                            "id": 126725,
                            "name": "South San Jose Hills",
                            "latitude": "34.01279000",
                            "longitude": "-117.90478000"
                        },
                        {
                            "id": 126733,
                            "name": "South Taft",
                            "latitude": "35.13469000",
                            "longitude": "-119.45623000"
                        },
                        {
                            "id": 126748,
                            "name": "South Whittier",
                            "latitude": "33.95015000",
                            "longitude": "-118.03917000"
                        },
                        {
                            "id": 126756,
                            "name": "South Yuba City",
                            "latitude": "39.11656000",
                            "longitude": "-121.63913000"
                        },
                        {
                            "id": 126880,
                            "name": "Spring Valley",
                            "latitude": "32.74477000",
                            "longitude": "-116.99892000"
                        },
                        {
                            "id": 126882,
                            "name": "Spring Valley Lake",
                            "latitude": "34.49364000",
                            "longitude": "-117.26832000"
                        },
                        {
                            "id": 126924,
                            "name": "Squaw Valley",
                            "latitude": "36.74023000",
                            "longitude": "-119.24679000"
                        },
                        {
                            "id": 126941,
                            "name": "Stallion Springs",
                            "latitude": "35.08886000",
                            "longitude": "-118.64259000"
                        },
                        {
                            "id": 126954,
                            "name": "Stanford",
                            "latitude": "37.42411000",
                            "longitude": "-122.16608000"
                        },
                        {
                            "id": 126957,
                            "name": "Stanislaus County",
                            "latitude": "37.55914000",
                            "longitude": "-120.99769000"
                        },
                        {
                            "id": 126969,
                            "name": "Stanton",
                            "latitude": "33.80252000",
                            "longitude": "-117.99312000"
                        },
                        {
                            "id": 127046,
                            "name": "Stevenson Ranch",
                            "latitude": "34.39048000",
                            "longitude": "-118.57372000"
                        },
                        {
                            "id": 127074,
                            "name": "Stockton",
                            "latitude": "37.95770000",
                            "longitude": "-121.29078000"
                        },
                        {
                            "id": 127127,
                            "name": "Stratford",
                            "latitude": "36.18940000",
                            "longitude": "-119.82319000"
                        },
                        {
                            "id": 127131,
                            "name": "Strathmore",
                            "latitude": "36.14551000",
                            "longitude": "-119.06066000"
                        },
                        {
                            "id": 127133,
                            "name": "Strawberry",
                            "latitude": "37.89687000",
                            "longitude": "-122.50886000"
                        },
                        {
                            "id": 127146,
                            "name": "Studio City",
                            "latitude": "34.14862000",
                            "longitude": "-118.39647000"
                        },
                        {
                            "id": 127177,
                            "name": "Suisun",
                            "latitude": "38.23825000",
                            "longitude": "-122.04024000"
                        },
                        {
                            "id": 127204,
                            "name": "Summerland",
                            "latitude": "34.42138000",
                            "longitude": "-119.59652000"
                        },
                        {
                            "id": 127233,
                            "name": "Sun City",
                            "latitude": "33.70919000",
                            "longitude": "-117.19726000"
                        },
                        {
                            "id": 127243,
                            "name": "Sun Village",
                            "latitude": "34.55952000",
                            "longitude": "-117.95676000"
                        },
                        {
                            "id": 127253,
                            "name": "Sunland",
                            "latitude": "34.26695000",
                            "longitude": "-118.30230000"
                        },
                        {
                            "id": 127259,
                            "name": "Sunnyside",
                            "latitude": "36.74912000",
                            "longitude": "-119.69931000"
                        },
                        {
                            "id": 127261,
                            "name": "Sunnyside-Tahoe City",
                            "latitude": "39.15023000",
                            "longitude": "-120.16120000"
                        },
                        {
                            "id": 127262,
                            "name": "Sunnyslope",
                            "latitude": "34.01196000",
                            "longitude": "-117.43338000"
                        },
                        {
                            "id": 127265,
                            "name": "Sunnyvale",
                            "latitude": "37.36883000",
                            "longitude": "-122.03635000"
                        },
                        {
                            "id": 127296,
                            "name": "Susanville",
                            "latitude": "40.41628000",
                            "longitude": "-120.65301000"
                        },
                        {
                            "id": 127308,
                            "name": "Sutter",
                            "latitude": "39.15989000",
                            "longitude": "-121.75275000"
                        },
                        {
                            "id": 127309,
                            "name": "Sutter County",
                            "latitude": "39.03452000",
                            "longitude": "-121.69484000"
                        },
                        {
                            "id": 127310,
                            "name": "Sutter Creek",
                            "latitude": "38.39297000",
                            "longitude": "-120.80244000"
                        },
                        {
                            "id": 127373,
                            "name": "Taft",
                            "latitude": "35.14247000",
                            "longitude": "-119.45651000"
                        },
                        {
                            "id": 127374,
                            "name": "Taft Heights",
                            "latitude": "35.13469000",
                            "longitude": "-119.47262000"
                        },
                        {
                            "id": 127375,
                            "name": "Taft Mosswood",
                            "latitude": "37.91385000",
                            "longitude": "-121.28316000"
                        },
                        {
                            "id": 127378,
                            "name": "Tahoe Vista",
                            "latitude": "39.23991000",
                            "longitude": "-120.05102000"
                        },
                        {
                            "id": 127380,
                            "name": "Tahoma",
                            "latitude": "39.06741000",
                            "longitude": "-120.12824000"
                        },
                        {
                            "id": 127397,
                            "name": "Talmage",
                            "latitude": "39.13323000",
                            "longitude": "-123.16778000"
                        },
                        {
                            "id": 127402,
                            "name": "Tamalpais Valley",
                            "latitude": "37.87965000",
                            "longitude": "-122.54581000"
                        },
                        {
                            "id": 127403,
                            "name": "Tamalpais-Homestead Valley",
                            "latitude": "37.87834000",
                            "longitude": "-122.53625000"
                        },
                        {
                            "id": 127427,
                            "name": "Tara Hills",
                            "latitude": "37.99353000",
                            "longitude": "-122.31636000"
                        },
                        {
                            "id": 127432,
                            "name": "Tarpey Village",
                            "latitude": "36.79301000",
                            "longitude": "-119.70097000"
                        },
                        {
                            "id": 127483,
                            "name": "Tehachapi",
                            "latitude": "35.13219000",
                            "longitude": "-118.44897000"
                        },
                        {
                            "id": 127484,
                            "name": "Tehama County",
                            "latitude": "40.12574000",
                            "longitude": "-122.23388000"
                        },
                        {
                            "id": 127492,
                            "name": "Temecula",
                            "latitude": "33.49364000",
                            "longitude": "-117.14836000"
                        },
                        {
                            "id": 127493,
                            "name": "Temelec",
                            "latitude": "38.26658000",
                            "longitude": "-122.49276000"
                        },
                        {
                            "id": 127501,
                            "name": "Temple City",
                            "latitude": "34.10723000",
                            "longitude": "-118.05785000"
                        },
                        {
                            "id": 127505,
                            "name": "Templeton",
                            "latitude": "35.54969000",
                            "longitude": "-120.70600000"
                        },
                        {
                            "id": 127515,
                            "name": "Terra Bella",
                            "latitude": "35.96245000",
                            "longitude": "-119.04427000"
                        },
                        {
                            "id": 127541,
                            "name": "Teviston",
                            "latitude": "35.92894000",
                            "longitude": "-119.27831000"
                        },
                        {
                            "id": 127570,
                            "name": "Thermal",
                            "latitude": "33.64030000",
                            "longitude": "-116.13945000"
                        },
                        {
                            "id": 127571,
                            "name": "Thermalito",
                            "latitude": "39.51128000",
                            "longitude": "-121.58692000"
                        },
                        {
                            "id": 127604,
                            "name": "Thornton",
                            "latitude": "38.22603000",
                            "longitude": "-121.42467000"
                        },
                        {
                            "id": 127609,
                            "name": "Thousand Oaks",
                            "latitude": "34.17056000",
                            "longitude": "-118.83759000"
                        },
                        {
                            "id": 127610,
                            "name": "Thousand Palms",
                            "latitude": "33.82002000",
                            "longitude": "-116.39029000"
                        },
                        {
                            "id": 127620,
                            "name": "Three Rivers",
                            "latitude": "36.43884000",
                            "longitude": "-118.90454000"
                        },
                        {
                            "id": 127632,
                            "name": "Tiburon",
                            "latitude": "37.87354000",
                            "longitude": "-122.45664000"
                        },
                        {
                            "id": 127637,
                            "name": "Tierra Buena",
                            "latitude": "39.14878000",
                            "longitude": "-121.66691000"
                        },
                        {
                            "id": 127678,
                            "name": "Tipton",
                            "latitude": "36.05940000",
                            "longitude": "-119.31206000"
                        },
                        {
                            "id": 127730,
                            "name": "Topanga",
                            "latitude": "34.09362000",
                            "longitude": "-118.60147000"
                        },
                        {
                            "id": 127741,
                            "name": "Toro Canyon",
                            "latitude": "34.42000000",
                            "longitude": "-119.56707000"
                        },
                        {
                            "id": 127742,
                            "name": "Torrance",
                            "latitude": "33.83585000",
                            "longitude": "-118.34063000"
                        },
                        {
                            "id": 127769,
                            "name": "Trabuco Canyon",
                            "latitude": "33.66252000",
                            "longitude": "-117.59033000"
                        },
                        {
                            "id": 127771,
                            "name": "Tracy",
                            "latitude": "37.73987000",
                            "longitude": "-121.42618000"
                        },
                        {
                            "id": 127829,
                            "name": "Trinity County",
                            "latitude": "40.65069000",
                            "longitude": "-123.11263000"
                        },
                        {
                            "id": 127853,
                            "name": "Truckee",
                            "latitude": "39.32796000",
                            "longitude": "-120.18325000"
                        },
                        {
                            "id": 127878,
                            "name": "Tujunga",
                            "latitude": "34.25223000",
                            "longitude": "-118.28841000"
                        },
                        {
                            "id": 127882,
                            "name": "Tulare",
                            "latitude": "36.20773000",
                            "longitude": "-119.34734000"
                        },
                        {
                            "id": 127883,
                            "name": "Tulare County",
                            "latitude": "36.22016000",
                            "longitude": "-118.80047000"
                        },
                        {
                            "id": 127895,
                            "name": "Tuolumne City",
                            "latitude": "37.96270000",
                            "longitude": "-120.24130000"
                        },
                        {
                            "id": 127896,
                            "name": "Tuolumne County",
                            "latitude": "38.02760000",
                            "longitude": "-119.95475000"
                        },
                        {
                            "id": 127900,
                            "name": "Turlock",
                            "latitude": "37.49466000",
                            "longitude": "-120.84659000"
                        },
                        {
                            "id": 127917,
                            "name": "Tustin",
                            "latitude": "33.74585000",
                            "longitude": "-117.82617000"
                        },
                        {
                            "id": 127920,
                            "name": "Twain Harte",
                            "latitude": "38.03965000",
                            "longitude": "-120.23269000"
                        },
                        {
                            "id": 127921,
                            "name": "Twentynine Palms",
                            "latitude": "34.13556000",
                            "longitude": "-116.05417000"
                        },
                        {
                            "id": 127930,
                            "name": "Twin Lakes",
                            "latitude": "36.96745000",
                            "longitude": "-121.99802000"
                        },
                        {
                            "id": 127958,
                            "name": "Ukiah",
                            "latitude": "39.15017000",
                            "longitude": "-123.20778000"
                        },
                        {
                            "id": 127987,
                            "name": "Union City",
                            "latitude": "37.59577000",
                            "longitude": "-122.01913000"
                        },
                        {
                            "id": 128023,
                            "name": "Universal City",
                            "latitude": "34.13890000",
                            "longitude": "-118.35341000"
                        },
                        {
                            "id": 128039,
                            "name": "Upland",
                            "latitude": "34.09751000",
                            "longitude": "-117.64839000"
                        },
                        {
                            "id": 128045,
                            "name": "Upper Lake",
                            "latitude": "39.16461000",
                            "longitude": "-122.91055000"
                        },
                        {
                            "id": 128072,
                            "name": "Vacaville",
                            "latitude": "38.35658000",
                            "longitude": "-121.98774000"
                        },
                        {
                            "id": 128078,
                            "name": "Val Verde",
                            "latitude": "34.44500000",
                            "longitude": "-118.65759000"
                        },
                        {
                            "id": 128087,
                            "name": "Valencia",
                            "latitude": "34.44361000",
                            "longitude": "-118.60953000"
                        },
                        {
                            "id": 128093,
                            "name": "Valinda",
                            "latitude": "34.04529000",
                            "longitude": "-117.94367000"
                        },
                        {
                            "id": 128094,
                            "name": "Valle Vista",
                            "latitude": "33.74780000",
                            "longitude": "-116.89336000"
                        },
                        {
                            "id": 128096,
                            "name": "Vallejo",
                            "latitude": "38.10409000",
                            "longitude": "-122.25664000"
                        },
                        {
                            "id": 128100,
                            "name": "Valley Center",
                            "latitude": "33.21837000",
                            "longitude": "-117.03420000"
                        },
                        {
                            "id": 128109,
                            "name": "Valley Glen",
                            "latitude": "34.18991000",
                            "longitude": "-118.44953000"
                        },
                        {
                            "id": 128115,
                            "name": "Valley Springs",
                            "latitude": "38.19159000",
                            "longitude": "-120.82910000"
                        },
                        {
                            "id": 128137,
                            "name": "Van Nuys",
                            "latitude": "34.18667000",
                            "longitude": "-118.44897000"
                        },
                        {
                            "id": 128147,
                            "name": "Vandenberg Air Force Base",
                            "latitude": "34.74830000",
                            "longitude": "-120.51817000"
                        },
                        {
                            "id": 128148,
                            "name": "Vandenberg Village",
                            "latitude": "34.70832000",
                            "longitude": "-120.46766000"
                        },
                        {
                            "id": 128172,
                            "name": "Venice",
                            "latitude": "33.99084000",
                            "longitude": "-118.46008000"
                        },
                        {
                            "id": 128175,
                            "name": "Ventura",
                            "latitude": "34.27834000",
                            "longitude": "-119.29317000"
                        },
                        {
                            "id": 128176,
                            "name": "Ventura County",
                            "latitude": "34.35753000",
                            "longitude": "-119.12603000"
                        },
                        {
                            "id": 128224,
                            "name": "Victorville",
                            "latitude": "34.53611000",
                            "longitude": "-117.29116000"
                        },
                        {
                            "id": 128238,
                            "name": "View Park-Windsor Hills",
                            "latitude": "33.99551000",
                            "longitude": "-118.34835000"
                        },
                        {
                            "id": 128244,
                            "name": "Villa Park",
                            "latitude": "33.81446000",
                            "longitude": "-117.81311000"
                        },
                        {
                            "id": 128265,
                            "name": "Vincent",
                            "latitude": "34.50055000",
                            "longitude": "-118.11646000"
                        },
                        {
                            "id": 128269,
                            "name": "Vine Hill",
                            "latitude": "38.00853000",
                            "longitude": "-122.09608000"
                        },
                        {
                            "id": 128273,
                            "name": "Vineyard",
                            "latitude": "38.46449000",
                            "longitude": "-121.34692000"
                        },
                        {
                            "id": 128293,
                            "name": "Visalia",
                            "latitude": "36.33023000",
                            "longitude": "-119.29206000"
                        },
                        {
                            "id": 128294,
                            "name": "Visitacion Valley",
                            "latitude": "37.71715000",
                            "longitude": "-122.40433000"
                        },
                        {
                            "id": 128295,
                            "name": "Vista",
                            "latitude": "33.20004000",
                            "longitude": "-117.24254000"
                        },
                        {
                            "id": 128297,
                            "name": "Vista Santa Rosa",
                            "latitude": "33.62780000",
                            "longitude": "-116.21806000"
                        },
                        {
                            "id": 128374,
                            "name": "Waldon",
                            "latitude": "37.92631000",
                            "longitude": "-122.05552000"
                        },
                        {
                            "id": 128414,
                            "name": "Walnut",
                            "latitude": "34.02029000",
                            "longitude": "-117.86534000"
                        },
                        {
                            "id": 128416,
                            "name": "Walnut Creek",
                            "latitude": "37.90631000",
                            "longitude": "-122.06496000"
                        },
                        {
                            "id": 128419,
                            "name": "Walnut Grove",
                            "latitude": "38.24214000",
                            "longitude": "-121.51162000"
                        },
                        {
                            "id": 128422,
                            "name": "Walnut Park",
                            "latitude": "33.96807000",
                            "longitude": "-118.22507000"
                        },
                        {
                            "id": 128528,
                            "name": "Wasco",
                            "latitude": "35.59412000",
                            "longitude": "-119.34095000"
                        },
                        {
                            "id": 128609,
                            "name": "Waterford",
                            "latitude": "37.64132000",
                            "longitude": "-120.76048000"
                        },
                        {
                            "id": 128641,
                            "name": "Watsonville",
                            "latitude": "36.91023000",
                            "longitude": "-121.75689000"
                        },
                        {
                            "id": 128723,
                            "name": "Weaverville",
                            "latitude": "40.73098000",
                            "longitude": "-122.94197000"
                        },
                        {
                            "id": 128751,
                            "name": "Weed",
                            "latitude": "41.42265000",
                            "longitude": "-122.38613000"
                        },
                        {
                            "id": 128752,
                            "name": "Weedpatch",
                            "latitude": "35.23802000",
                            "longitude": "-118.91510000"
                        },
                        {
                            "id": 128770,
                            "name": "Weldon",
                            "latitude": "35.66579000",
                            "longitude": "-118.29036000"
                        },
                        {
                            "id": 128818,
                            "name": "West Athens",
                            "latitude": "33.92335000",
                            "longitude": "-118.30341000"
                        },
                        {
                            "id": 128826,
                            "name": "West Bishop",
                            "latitude": "37.36104000",
                            "longitude": "-118.45511000"
                        },
                        {
                            "id": 128842,
                            "name": "West Carson",
                            "latitude": "33.82168000",
                            "longitude": "-118.29257000"
                        },
                        {
                            "id": 128853,
                            "name": "West Covina",
                            "latitude": "34.06862000",
                            "longitude": "-117.93895000"
                        },
                        {
                            "id": 128897,
                            "name": "West Hills",
                            "latitude": "34.19731000",
                            "longitude": "-118.64398000"
                        },
                        {
                            "id": 128899,
                            "name": "West Hollywood",
                            "latitude": "34.09001000",
                            "longitude": "-118.36174000"
                        },
                        {
                            "id": 128929,
                            "name": "West Menlo Park",
                            "latitude": "37.43355000",
                            "longitude": "-122.20302000"
                        },
                        {
                            "id": 128934,
                            "name": "West Modesto",
                            "latitude": "37.61754000",
                            "longitude": "-121.03914000"
                        },
                        {
                            "id": 128950,
                            "name": "West Park",
                            "latitude": "36.71023000",
                            "longitude": "-119.85126000"
                        },
                        {
                            "id": 128964,
                            "name": "West Puente Valley",
                            "latitude": "34.05168000",
                            "longitude": "-117.96840000"
                        },
                        {
                            "id": 128966,
                            "name": "West Rancho Dominguez",
                            "latitude": "33.89390000",
                            "longitude": "-118.27063000"
                        },
                        {
                            "id": 128971,
                            "name": "West Sacramento",
                            "latitude": "38.58046000",
                            "longitude": "-121.53023000"
                        },
                        {
                            "id": 129005,
                            "name": "West Whittier-Los Nietos",
                            "latitude": "33.97600000",
                            "longitude": "-118.06909000"
                        },
                        {
                            "id": 129037,
                            "name": "Westhaven-Moonstone",
                            "latitude": "41.04489000",
                            "longitude": "-124.10239000"
                        },
                        {
                            "id": 129040,
                            "name": "Westlake Village",
                            "latitude": "34.14584000",
                            "longitude": "-118.80565000"
                        },
                        {
                            "id": 129048,
                            "name": "Westminster",
                            "latitude": "33.75918000",
                            "longitude": "-118.00673000"
                        },
                        {
                            "id": 129051,
                            "name": "Westmont",
                            "latitude": "33.94140000",
                            "longitude": "-118.30230000"
                        },
                        {
                            "id": 129057,
                            "name": "Westmorland",
                            "latitude": "33.03727000",
                            "longitude": "-115.62138000"
                        },
                        {
                            "id": 129087,
                            "name": "Westwood",
                            "latitude": "34.05612000",
                            "longitude": "-118.43063000"
                        },
                        {
                            "id": 129108,
                            "name": "Wheatland",
                            "latitude": "39.00989000",
                            "longitude": "-121.42301000"
                        },
                        {
                            "id": 129210,
                            "name": "Whittier",
                            "latitude": "33.97918000",
                            "longitude": "-118.03284000"
                        },
                        {
                            "id": 129235,
                            "name": "Wildomar",
                            "latitude": "33.59891000",
                            "longitude": "-117.28004000"
                        },
                        {
                            "id": 129261,
                            "name": "Williams",
                            "latitude": "39.15461000",
                            "longitude": "-122.14942000"
                        },
                        {
                            "id": 129302,
                            "name": "Willits",
                            "latitude": "39.40961000",
                            "longitude": "-123.35557000"
                        },
                        {
                            "id": 129305,
                            "name": "Willow Creek",
                            "latitude": "40.93958000",
                            "longitude": "-123.63144000"
                        },
                        {
                            "id": 129315,
                            "name": "Willowbrook",
                            "latitude": "33.91696000",
                            "longitude": "-118.25507000"
                        },
                        {
                            "id": 129316,
                            "name": "Willows",
                            "latitude": "39.52433000",
                            "longitude": "-122.19359000"
                        },
                        {
                            "id": 129346,
                            "name": "Wilton",
                            "latitude": "38.41186000",
                            "longitude": "-121.27217000"
                        },
                        {
                            "id": 129360,
                            "name": "Winchester",
                            "latitude": "33.70697000",
                            "longitude": "-117.08447000"
                        },
                        {
                            "id": 129386,
                            "name": "Windsor",
                            "latitude": "38.54713000",
                            "longitude": "-122.81638000"
                        },
                        {
                            "id": 129439,
                            "name": "Winter Gardens",
                            "latitude": "32.83116000",
                            "longitude": "-116.93336000"
                        },
                        {
                            "id": 129445,
                            "name": "Winters",
                            "latitude": "38.52491000",
                            "longitude": "-121.97080000"
                        },
                        {
                            "id": 129454,
                            "name": "Winton",
                            "latitude": "37.38938000",
                            "longitude": "-120.61325000"
                        },
                        {
                            "id": 129467,
                            "name": "Wofford Heights",
                            "latitude": "35.70690000",
                            "longitude": "-118.45620000"
                        },
                        {
                            "id": 129493,
                            "name": "Woodacre",
                            "latitude": "38.01270000",
                            "longitude": "-122.64526000"
                        },
                        {
                            "id": 129502,
                            "name": "Woodbridge",
                            "latitude": "38.15408000",
                            "longitude": "-121.30134000"
                        },
                        {
                            "id": 129516,
                            "name": "Woodcrest",
                            "latitude": "33.88224000",
                            "longitude": "-117.35727000"
                        },
                        {
                            "id": 129524,
                            "name": "Woodlake",
                            "latitude": "36.41356000",
                            "longitude": "-119.09872000"
                        },
                        {
                            "id": 129526,
                            "name": "Woodland",
                            "latitude": "38.67852000",
                            "longitude": "-121.77330000"
                        },
                        {
                            "id": 129530,
                            "name": "Woodland Hills",
                            "latitude": "34.16834000",
                            "longitude": "-118.60592000"
                        },
                        {
                            "id": 129556,
                            "name": "Woodside",
                            "latitude": "37.42994000",
                            "longitude": "-122.25386000"
                        },
                        {
                            "id": 129574,
                            "name": "Woodville",
                            "latitude": "36.09356000",
                            "longitude": "-119.19900000"
                        },
                        {
                            "id": 129617,
                            "name": "Wrightwood",
                            "latitude": "34.36083000",
                            "longitude": "-117.63339000"
                        },
                        {
                            "id": 129686,
                            "name": "Yolo County",
                            "latitude": "38.68665000",
                            "longitude": "-121.90162000"
                        },
                        {
                            "id": 129689,
                            "name": "Yorba Linda",
                            "latitude": "33.88863000",
                            "longitude": "-117.81311000"
                        },
                        {
                            "id": 129711,
                            "name": "Yosemite Lakes",
                            "latitude": "37.19106000",
                            "longitude": "-119.77265000"
                        },
                        {
                            "id": 129712,
                            "name": "Yosemite Valley",
                            "latitude": "37.74075000",
                            "longitude": "-119.57788000"
                        },
                        {
                            "id": 129723,
                            "name": "Yountville",
                            "latitude": "38.40158000",
                            "longitude": "-122.36081000"
                        },
                        {
                            "id": 129725,
                            "name": "Yreka",
                            "latitude": "41.73542000",
                            "longitude": "-122.63447000"
                        },
                        {
                            "id": 129726,
                            "name": "Yuba City",
                            "latitude": "39.14045000",
                            "longitude": "-121.61691000"
                        },
                        {
                            "id": 129727,
                            "name": "Yuba County",
                            "latitude": "39.26902000",
                            "longitude": "-121.35126000"
                        },
                        {
                            "id": 129728,
                            "name": "Yucaipa",
                            "latitude": "34.03363000",
                            "longitude": "-117.04309000"
                        },
                        {
                            "id": 129729,
                            "name": "Yucca Valley",
                            "latitude": "34.11417000",
                            "longitude": "-116.43224000"
                        }
                    ]
                },
                {
                    "id": 1450,
                    "name": "Colorado",
                    "state_code": "CO",
                    "cities": [
                        {
                            "id": 110998,
                            "name": "Acres Green",
                            "latitude": "39.55666000",
                            "longitude": "-104.89609000"
                        },
                        {
                            "id": 111024,
                            "name": "Adams County",
                            "latitude": "39.87363000",
                            "longitude": "-104.33791000"
                        },
                        {
                            "id": 111070,
                            "name": "Air Force Academy",
                            "latitude": "38.99425000",
                            "longitude": "-104.86375000"
                        },
                        {
                            "id": 111080,
                            "name": "Akron",
                            "latitude": "40.16054000",
                            "longitude": "-103.21438000"
                        },
                        {
                            "id": 111098,
                            "name": "Alamosa",
                            "latitude": "37.46945000",
                            "longitude": "-105.87002000"
                        },
                        {
                            "id": 111099,
                            "name": "Alamosa County",
                            "latitude": "37.57289000",
                            "longitude": "-105.78829000"
                        },
                        {
                            "id": 111100,
                            "name": "Alamosa East",
                            "latitude": "37.47735000",
                            "longitude": "-105.84217000"
                        },
                        {
                            "id": 111399,
                            "name": "Applewood",
                            "latitude": "39.75778000",
                            "longitude": "-105.16250000"
                        },
                        {
                            "id": 111415,
                            "name": "Arapahoe County",
                            "latitude": "39.64977000",
                            "longitude": "-104.33924000"
                        },
                        {
                            "id": 111434,
                            "name": "Archuleta County",
                            "latitude": "37.19360000",
                            "longitude": "-107.04833000"
                        },
                        {
                            "id": 111452,
                            "name": "Aristocrat Ranchettes",
                            "latitude": "40.10915000",
                            "longitude": "-104.76247000"
                        },
                        {
                            "id": 111503,
                            "name": "Arvada",
                            "latitude": "39.80276000",
                            "longitude": "-105.08748000"
                        },
                        {
                            "id": 111552,
                            "name": "Aspen",
                            "latitude": "39.19110000",
                            "longitude": "-106.81754000"
                        },
                        {
                            "id": 111653,
                            "name": "Ault",
                            "latitude": "40.58248000",
                            "longitude": "-104.73191000"
                        },
                        {
                            "id": 111662,
                            "name": "Aurora",
                            "latitude": "39.72943000",
                            "longitude": "-104.83192000"
                        },
                        {
                            "id": 111695,
                            "name": "Avon",
                            "latitude": "39.63137000",
                            "longitude": "-106.52225000"
                        },
                        {
                            "id": 111717,
                            "name": "Baca County",
                            "latitude": "37.31918000",
                            "longitude": "-102.56047000"
                        },
                        {
                            "id": 111875,
                            "name": "Basalt",
                            "latitude": "39.36887000",
                            "longitude": "-107.03282000"
                        },
                        {
                            "id": 111910,
                            "name": "Battlement Mesa",
                            "latitude": "39.44137000",
                            "longitude": "-108.02507000"
                        },
                        {
                            "id": 111941,
                            "name": "Bayfield",
                            "latitude": "37.22556000",
                            "longitude": "-107.59811000"
                        },
                        {
                            "id": 112173,
                            "name": "Bennett",
                            "latitude": "39.75887000",
                            "longitude": "-104.42746000"
                        },
                        {
                            "id": 112188,
                            "name": "Bent County",
                            "latitude": "37.95509000",
                            "longitude": "-103.07170000"
                        },
                        {
                            "id": 112231,
                            "name": "Berkley",
                            "latitude": "39.80443000",
                            "longitude": "-105.02609000"
                        },
                        {
                            "id": 112257,
                            "name": "Berthoud",
                            "latitude": "40.30832000",
                            "longitude": "-105.08109000"
                        },
                        {
                            "id": 112376,
                            "name": "Black Forest",
                            "latitude": "39.01305000",
                            "longitude": "-104.70081000"
                        },
                        {
                            "id": 112600,
                            "name": "Boulder",
                            "latitude": "40.01499000",
                            "longitude": "-105.27055000"
                        },
                        {
                            "id": 112603,
                            "name": "Boulder County",
                            "latitude": "40.09246000",
                            "longitude": "-105.35770000"
                        },
                        {
                            "id": 112713,
                            "name": "Breckenridge",
                            "latitude": "39.48165000",
                            "longitude": "-106.03835000"
                        },
                        {
                            "id": 112791,
                            "name": "Brighton",
                            "latitude": "39.98526000",
                            "longitude": "-104.82053000"
                        },
                        {
                            "id": 112900,
                            "name": "Broomfield",
                            "latitude": "39.92054000",
                            "longitude": "-105.08665000"
                        },
                        {
                            "id": 112901,
                            "name": "Broomfield County",
                            "latitude": "39.95413000",
                            "longitude": "-105.05266000"
                        },
                        {
                            "id": 112953,
                            "name": "Brush",
                            "latitude": "40.25887000",
                            "longitude": "-103.62384000"
                        },
                        {
                            "id": 113004,
                            "name": "Buena Vista",
                            "latitude": "38.84222000",
                            "longitude": "-106.13113000"
                        },
                        {
                            "id": 113074,
                            "name": "Burlington",
                            "latitude": "39.30611000",
                            "longitude": "-102.26936000"
                        },
                        {
                            "id": 113134,
                            "name": "Byers",
                            "latitude": "39.71137000",
                            "longitude": "-104.22774000"
                        },
                        {
                            "id": 113307,
                            "name": "Campion",
                            "latitude": "40.34943000",
                            "longitude": "-105.07776000"
                        },
                        {
                            "id": 113597,
                            "name": "Cañon City",
                            "latitude": "38.44098000",
                            "longitude": "-105.24245000"
                        },
                        {
                            "id": 113390,
                            "name": "Carbondale",
                            "latitude": "39.40221000",
                            "longitude": "-107.21116000"
                        },
                        {
                            "id": 113443,
                            "name": "Carriage Club",
                            "latitude": "39.53249000",
                            "longitude": "-104.90109000"
                        },
                        {
                            "id": 113518,
                            "name": "Cascade-Chipita Park",
                            "latitude": "38.94354000",
                            "longitude": "-105.00237000"
                        },
                        {
                            "id": 113546,
                            "name": "Castle Pines",
                            "latitude": "39.45804000",
                            "longitude": "-104.89609000"
                        },
                        {
                            "id": 113547,
                            "name": "Castle Pines North",
                            "latitude": "39.47174000",
                            "longitude": "-104.89482000"
                        },
                        {
                            "id": 113549,
                            "name": "Castle Rock",
                            "latitude": "39.37221000",
                            "longitude": "-104.85609000"
                        },
                        {
                            "id": 113555,
                            "name": "Castlewood",
                            "latitude": "39.58471000",
                            "longitude": "-104.90109000"
                        },
                        {
                            "id": 113625,
                            "name": "Cedaredge",
                            "latitude": "38.90165000",
                            "longitude": "-107.92645000"
                        },
                        {
                            "id": 113634,
                            "name": "Centennial",
                            "latitude": "39.57916000",
                            "longitude": "-104.87692000"
                        },
                        {
                            "id": 113638,
                            "name": "Center",
                            "latitude": "37.75306000",
                            "longitude": "-106.10864000"
                        },
                        {
                            "id": 113671,
                            "name": "Central City",
                            "latitude": "39.80193000",
                            "longitude": "-105.51416000"
                        },
                        {
                            "id": 113710,
                            "name": "Chaffee County",
                            "latitude": "38.74690000",
                            "longitude": "-106.19407000"
                        },
                        {
                            "id": 113859,
                            "name": "Cherry Creek",
                            "latitude": "39.63455000",
                            "longitude": "-104.88286000"
                        },
                        {
                            "id": 113863,
                            "name": "Cherry Hills Village",
                            "latitude": "39.64165000",
                            "longitude": "-104.95943000"
                        },
                        {
                            "id": 113927,
                            "name": "Cheyenne County",
                            "latitude": "38.82794000",
                            "longitude": "-102.60340000"
                        },
                        {
                            "id": 113930,
                            "name": "Cheyenne Wells",
                            "latitude": "38.82140000",
                            "longitude": "-102.35324000"
                        },
                        {
                            "id": 114025,
                            "name": "Cimarron Hills",
                            "latitude": "38.85861000",
                            "longitude": "-104.69886000"
                        },
                        {
                            "id": 114208,
                            "name": "Clear Creek County",
                            "latitude": "39.68910000",
                            "longitude": "-105.64436000"
                        },
                        {
                            "id": 114261,
                            "name": "Clifton",
                            "latitude": "39.09193000",
                            "longitude": "-108.44898000"
                        },
                        {
                            "id": 114329,
                            "name": "Coal Creek",
                            "latitude": "39.90638000",
                            "longitude": "-105.37749000"
                        },
                        {
                            "id": 114455,
                            "name": "Colorado City",
                            "latitude": "37.94529000",
                            "longitude": "-104.83526000"
                        },
                        {
                            "id": 114458,
                            "name": "Colorado Springs",
                            "latitude": "38.83388000",
                            "longitude": "-104.82136000"
                        },
                        {
                            "id": 114488,
                            "name": "Columbine",
                            "latitude": "39.58777000",
                            "longitude": "-105.06943000"
                        },
                        {
                            "id": 114489,
                            "name": "Columbine Valley",
                            "latitude": "39.60110000",
                            "longitude": "-105.03221000"
                        },
                        {
                            "id": 114526,
                            "name": "Commerce City",
                            "latitude": "39.80832000",
                            "longitude": "-104.93387000"
                        },
                        {
                            "id": 114552,
                            "name": "Conejos",
                            "latitude": "37.08835000",
                            "longitude": "-106.01974000"
                        },
                        {
                            "id": 114553,
                            "name": "Conejos County",
                            "latitude": "37.20070000",
                            "longitude": "-106.19163000"
                        },
                        {
                            "id": 114674,
                            "name": "Cortez",
                            "latitude": "37.34888000",
                            "longitude": "-108.58593000"
                        },
                        {
                            "id": 114687,
                            "name": "Costilla County",
                            "latitude": "37.27810000",
                            "longitude": "-105.42827000"
                        },
                        {
                            "id": 114755,
                            "name": "Craig",
                            "latitude": "40.51525000",
                            "longitude": "-107.54645000"
                        },
                        {
                            "id": 114787,
                            "name": "Creede",
                            "latitude": "37.84917000",
                            "longitude": "-106.92643000"
                        },
                        {
                            "id": 114804,
                            "name": "Crested Butte",
                            "latitude": "38.86971000",
                            "longitude": "-106.98782000"
                        },
                        {
                            "id": 114821,
                            "name": "Cripple Creek",
                            "latitude": "38.74666000",
                            "longitude": "-105.17831000"
                        },
                        {
                            "id": 114865,
                            "name": "Crowley County",
                            "latitude": "38.32666000",
                            "longitude": "-103.78483000"
                        },
                        {
                            "id": 114937,
                            "name": "Custer County",
                            "latitude": "38.10868000",
                            "longitude": "-105.36747000"
                        },
                        {
                            "id": 114962,
                            "name": "Dacono",
                            "latitude": "40.08471000",
                            "longitude": "-104.93942000"
                        },
                        {
                            "id": 115190,
                            "name": "Del Norte",
                            "latitude": "37.67889000",
                            "longitude": "-106.35337000"
                        },
                        {
                            "id": 115222,
                            "name": "Delta",
                            "latitude": "38.74221000",
                            "longitude": "-108.06896000"
                        },
                        {
                            "id": 115226,
                            "name": "Delta County",
                            "latitude": "38.86137000",
                            "longitude": "-107.86288000"
                        },
                        {
                            "id": 115253,
                            "name": "Denver",
                            "latitude": "39.73915000",
                            "longitude": "-104.98470000"
                        },
                        {
                            "id": 115255,
                            "name": "Denver County",
                            "latitude": "39.76204000",
                            "longitude": "-104.87635000"
                        },
                        {
                            "id": 115262,
                            "name": "Derby",
                            "latitude": "39.83943000",
                            "longitude": "-104.91859000"
                        },
                        {
                            "id": 115391,
                            "name": "Dolores County",
                            "latitude": "37.75160000",
                            "longitude": "-108.51722000"
                        },
                        {
                            "id": 115433,
                            "name": "Douglas County",
                            "latitude": "39.32972000",
                            "longitude": "-104.92956000"
                        },
                        {
                            "id": 115442,
                            "name": "Dove Creek",
                            "latitude": "37.76610000",
                            "longitude": "-108.90594000"
                        },
                        {
                            "id": 115443,
                            "name": "Dove Valley",
                            "latitude": "39.57771000",
                            "longitude": "-104.82940000"
                        },
                        {
                            "id": 115565,
                            "name": "Durango",
                            "latitude": "37.27528000",
                            "longitude": "-107.88007000"
                        },
                        {
                            "id": 115592,
                            "name": "Eads",
                            "latitude": "38.48056000",
                            "longitude": "-102.78186000"
                        },
                        {
                            "id": 115597,
                            "name": "Eagle",
                            "latitude": "39.65526000",
                            "longitude": "-106.82865000"
                        },
                        {
                            "id": 115600,
                            "name": "Eagle County",
                            "latitude": "39.62783000",
                            "longitude": "-106.69530000"
                        },
                        {
                            "id": 115802,
                            "name": "Eaton",
                            "latitude": "40.53026000",
                            "longitude": "-104.71135000"
                        },
                        {
                            "id": 115855,
                            "name": "Edgewater",
                            "latitude": "39.75304000",
                            "longitude": "-105.06415000"
                        },
                        {
                            "id": 115887,
                            "name": "Edwards",
                            "latitude": "39.64499000",
                            "longitude": "-106.59420000"
                        },
                        {
                            "id": 115925,
                            "name": "El Jebel",
                            "latitude": "39.39498000",
                            "longitude": "-107.09033000"
                        },
                        {
                            "id": 115931,
                            "name": "El Paso County",
                            "latitude": "38.83209000",
                            "longitude": "-104.52558000"
                        },
                        {
                            "id": 115943,
                            "name": "Elbert County",
                            "latitude": "39.28656000",
                            "longitude": "-104.13589000"
                        },
                        {
                            "id": 115974,
                            "name": "Elizabeth",
                            "latitude": "39.36027000",
                            "longitude": "-104.59691000"
                        },
                        {
                            "id": 116023,
                            "name": "Ellicott",
                            "latitude": "38.83833000",
                            "longitude": "-104.38691000"
                        },
                        {
                            "id": 116133,
                            "name": "Englewood",
                            "latitude": "39.64777000",
                            "longitude": "-104.98776000"
                        },
                        {
                            "id": 116167,
                            "name": "Erie",
                            "latitude": "40.05026000",
                            "longitude": "-105.04998000"
                        },
                        {
                            "id": 116208,
                            "name": "Estes Park",
                            "latitude": "40.37721000",
                            "longitude": "-105.52167000"
                        },
                        {
                            "id": 116246,
                            "name": "Evans",
                            "latitude": "40.37637000",
                            "longitude": "-104.69219000"
                        },
                        {
                            "id": 116263,
                            "name": "Evergreen",
                            "latitude": "39.63332000",
                            "longitude": "-105.31721000"
                        },
                        {
                            "id": 116344,
                            "name": "Fairplay",
                            "latitude": "39.22471000",
                            "longitude": "-106.00196000"
                        },
                        {
                            "id": 116464,
                            "name": "Federal Heights",
                            "latitude": "39.85137000",
                            "longitude": "-104.99859000"
                        },
                        {
                            "id": 116521,
                            "name": "Firestone",
                            "latitude": "40.11248000",
                            "longitude": "-104.93664000"
                        },
                        {
                            "id": 116585,
                            "name": "Florence",
                            "latitude": "38.39028000",
                            "longitude": "-105.11860000"
                        },
                        {
                            "id": 116699,
                            "name": "Fort Carson",
                            "latitude": "38.73749000",
                            "longitude": "-104.78886000"
                        },
                        {
                            "id": 116701,
                            "name": "Fort Collins",
                            "latitude": "40.58526000",
                            "longitude": "-105.08442000"
                        },
                        {
                            "id": 116726,
                            "name": "Fort Lupton",
                            "latitude": "40.08471000",
                            "longitude": "-104.81303000"
                        },
                        {
                            "id": 116733,
                            "name": "Fort Morgan",
                            "latitude": "40.25026000",
                            "longitude": "-103.79995000"
                        },
                        {
                            "id": 116778,
                            "name": "Fountain",
                            "latitude": "38.68222000",
                            "longitude": "-104.70081000"
                        },
                        {
                            "id": 116796,
                            "name": "Fowler",
                            "latitude": "38.12917000",
                            "longitude": "-104.02329000"
                        },
                        {
                            "id": 116881,
                            "name": "Fraser",
                            "latitude": "39.94499000",
                            "longitude": "-105.81723000"
                        },
                        {
                            "id": 116887,
                            "name": "Frederick",
                            "latitude": "40.09915000",
                            "longitude": "-104.93720000"
                        },
                        {
                            "id": 116926,
                            "name": "Fremont County",
                            "latitude": "38.47297000",
                            "longitude": "-105.43966000"
                        },
                        {
                            "id": 116956,
                            "name": "Frisco",
                            "latitude": "39.57443000",
                            "longitude": "-106.09752000"
                        },
                        {
                            "id": 116968,
                            "name": "Fruita",
                            "latitude": "39.15887000",
                            "longitude": "-108.72899000"
                        },
                        {
                            "id": 116976,
                            "name": "Fruitvale",
                            "latitude": "39.08165000",
                            "longitude": "-108.49676000"
                        },
                        {
                            "id": 117084,
                            "name": "Garfield County",
                            "latitude": "39.59931000",
                            "longitude": "-107.90395000"
                        },
                        {
                            "id": 117134,
                            "name": "Genesee",
                            "latitude": "39.68582000",
                            "longitude": "-105.27277000"
                        },
                        {
                            "id": 117164,
                            "name": "Georgetown",
                            "latitude": "39.70610000",
                            "longitude": "-105.69750000"
                        },
                        {
                            "id": 117209,
                            "name": "Gilcrest",
                            "latitude": "40.28193000",
                            "longitude": "-104.77775000"
                        },
                        {
                            "id": 117224,
                            "name": "Gilpin County",
                            "latitude": "39.85756000",
                            "longitude": "-105.52253000"
                        },
                        {
                            "id": 117287,
                            "name": "Glendale",
                            "latitude": "39.70499000",
                            "longitude": "-104.93359000"
                        },
                        {
                            "id": 117292,
                            "name": "Gleneagle",
                            "latitude": "39.04527000",
                            "longitude": "-104.82442000"
                        },
                        {
                            "id": 117318,
                            "name": "Glenwood Springs",
                            "latitude": "39.55054000",
                            "longitude": "-107.32478000"
                        },
                        {
                            "id": 117346,
                            "name": "Golden",
                            "latitude": "39.75554000",
                            "longitude": "-105.22110000"
                        },
                        {
                            "id": 117448,
                            "name": "Granby",
                            "latitude": "40.08610000",
                            "longitude": "-105.93946000"
                        },
                        {
                            "id": 117456,
                            "name": "Grand County",
                            "latitude": "40.10261000",
                            "longitude": "-106.11836000"
                        },
                        {
                            "id": 117465,
                            "name": "Grand Junction",
                            "latitude": "39.06387000",
                            "longitude": "-108.55065000"
                        },
                        {
                            "id": 117586,
                            "name": "Greeley",
                            "latitude": "40.42331000",
                            "longitude": "-104.70913000"
                        },
                        {
                            "id": 117712,
                            "name": "Greenwood Village",
                            "latitude": "39.61721000",
                            "longitude": "-104.95081000"
                        },
                        {
                            "id": 117801,
                            "name": "Gunbarrel",
                            "latitude": "40.06335000",
                            "longitude": "-105.17107000"
                        },
                        {
                            "id": 117802,
                            "name": "Gunnison",
                            "latitude": "38.54582000",
                            "longitude": "-106.92532000"
                        },
                        {
                            "id": 117804,
                            "name": "Gunnison County",
                            "latitude": "38.66680000",
                            "longitude": "-107.03170000"
                        },
                        {
                            "id": 117822,
                            "name": "Gypsum",
                            "latitude": "39.64693000",
                            "longitude": "-106.95171000"
                        },
                        {
                            "id": 118178,
                            "name": "Hayden",
                            "latitude": "40.49529000",
                            "longitude": "-107.25729000"
                        },
                        {
                            "id": 118420,
                            "name": "Highlands Ranch",
                            "latitude": "39.55388000",
                            "longitude": "-104.96943000"
                        },
                        {
                            "id": 118487,
                            "name": "Hinsdale County",
                            "latitude": "37.82134000",
                            "longitude": "-107.30031000"
                        },
                        {
                            "id": 118558,
                            "name": "Holly Hills",
                            "latitude": "39.66757000",
                            "longitude": "-104.91797000"
                        },
                        {
                            "id": 118584,
                            "name": "Holyoke",
                            "latitude": "40.58444000",
                            "longitude": "-102.30241000"
                        },
                        {
                            "id": 118687,
                            "name": "Hot Sulphur Springs",
                            "latitude": "40.07304000",
                            "longitude": "-106.10280000"
                        },
                        {
                            "id": 118747,
                            "name": "Hudson",
                            "latitude": "40.07359000",
                            "longitude": "-104.64302000"
                        },
                        {
                            "id": 118755,
                            "name": "Huerfano County",
                            "latitude": "37.68468000",
                            "longitude": "-104.96058000"
                        },
                        {
                            "id": 118767,
                            "name": "Hugo",
                            "latitude": "39.13610000",
                            "longitude": "-103.46994000"
                        },
                        {
                            "id": 118872,
                            "name": "Idaho Springs",
                            "latitude": "39.74249000",
                            "longitude": "-105.51361000"
                        },
                        {
                            "id": 118908,
                            "name": "Indian Hills",
                            "latitude": "39.61665000",
                            "longitude": "-105.23721000"
                        },
                        {
                            "id": 118958,
                            "name": "Inverness",
                            "latitude": "39.57738000",
                            "longitude": "-104.86135000"
                        },
                        {
                            "id": 119094,
                            "name": "Jackson County",
                            "latitude": "40.66643000",
                            "longitude": "-106.34279000"
                        },
                        {
                            "id": 119194,
                            "name": "Jefferson County",
                            "latitude": "39.58642000",
                            "longitude": "-105.25047000"
                        },
                        {
                            "id": 119286,
                            "name": "Johnstown",
                            "latitude": "40.33693000",
                            "longitude": "-104.91220000"
                        },
                        {
                            "id": 119329,
                            "name": "Julesburg",
                            "latitude": "40.98833000",
                            "longitude": "-102.26435000"
                        },
                        {
                            "id": 119422,
                            "name": "Keenesburg",
                            "latitude": "40.10832000",
                            "longitude": "-104.51996000"
                        },
                        {
                            "id": 119439,
                            "name": "Ken Caryl",
                            "latitude": "39.57582000",
                            "longitude": "-105.11221000"
                        },
                        {
                            "id": 119519,
                            "name": "Kersey",
                            "latitude": "40.38748000",
                            "longitude": "-104.56163000"
                        },
                        {
                            "id": 119547,
                            "name": "Keystone",
                            "latitude": "39.59943000",
                            "longitude": "-105.98724000"
                        },
                        {
                            "id": 119645,
                            "name": "Kiowa",
                            "latitude": "39.34721000",
                            "longitude": "-104.46441000"
                        },
                        {
                            "id": 119648,
                            "name": "Kiowa County",
                            "latitude": "38.43269000",
                            "longitude": "-102.74034000"
                        },
                        {
                            "id": 119659,
                            "name": "Kit Carson County",
                            "latitude": "39.30544000",
                            "longitude": "-102.60289000"
                        },
                        {
                            "id": 119666,
                            "name": "Kittredge",
                            "latitude": "39.65471000",
                            "longitude": "-105.29971000"
                        },
                        {
                            "id": 119716,
                            "name": "Kremmling",
                            "latitude": "40.05887000",
                            "longitude": "-106.38892000"
                        },
                        {
                            "id": 119762,
                            "name": "La Junta",
                            "latitude": "37.98501000",
                            "longitude": "-103.54383000"
                        },
                        {
                            "id": 119775,
                            "name": "La Plata County",
                            "latitude": "37.28656000",
                            "longitude": "-107.84334000"
                        },
                        {
                            "id": 119786,
                            "name": "La Salle",
                            "latitude": "40.34887000",
                            "longitude": "-104.70191000"
                        },
                        {
                            "id": 119836,
                            "name": "Lafayette",
                            "latitude": "39.99360000",
                            "longitude": "-105.08971000"
                        },
                        {
                            "id": 119886,
                            "name": "Lake City",
                            "latitude": "38.03000000",
                            "longitude": "-107.31533000"
                        },
                        {
                            "id": 119897,
                            "name": "Lake County",
                            "latitude": "39.20238000",
                            "longitude": "-106.34484000"
                        },
                        {
                            "id": 120050,
                            "name": "Lakewood",
                            "latitude": "39.70471000",
                            "longitude": "-105.08137000"
                        },
                        {
                            "id": 120060,
                            "name": "Lamar",
                            "latitude": "38.08723000",
                            "longitude": "-102.62075000"
                        },
                        {
                            "id": 120136,
                            "name": "Laporte",
                            "latitude": "40.62633000",
                            "longitude": "-105.13916000"
                        },
                        {
                            "id": 120145,
                            "name": "Larimer County",
                            "latitude": "40.66641000",
                            "longitude": "-105.46116000"
                        },
                        {
                            "id": 120153,
                            "name": "Las Animas",
                            "latitude": "38.06667000",
                            "longitude": "-103.22271000"
                        },
                        {
                            "id": 120154,
                            "name": "Las Animas County",
                            "latitude": "37.31585000",
                            "longitude": "-104.03872000"
                        },
                        {
                            "id": 120271,
                            "name": "Leadville",
                            "latitude": "39.25082000",
                            "longitude": "-106.29252000"
                        },
                        {
                            "id": 120272,
                            "name": "Leadville North",
                            "latitude": "39.25760000",
                            "longitude": "-106.30138000"
                        },
                        {
                            "id": 120498,
                            "name": "Limon",
                            "latitude": "39.26388000",
                            "longitude": "-103.69217000"
                        },
                        {
                            "id": 120530,
                            "name": "Lincoln County",
                            "latitude": "38.98807000",
                            "longitude": "-103.51397000"
                        },
                        {
                            "id": 120545,
                            "name": "Lincoln Park",
                            "latitude": "38.42916000",
                            "longitude": "-105.21999000"
                        },
                        {
                            "id": 120636,
                            "name": "Littleton",
                            "latitude": "39.61332000",
                            "longitude": "-105.01665000"
                        },
                        {
                            "id": 120670,
                            "name": "Lochbuie",
                            "latitude": "40.00721000",
                            "longitude": "-104.71608000"
                        },
                        {
                            "id": 120703,
                            "name": "Logan County",
                            "latitude": "40.72468000",
                            "longitude": "-103.11010000"
                        },
                        {
                            "id": 120711,
                            "name": "Loma",
                            "latitude": "39.19581000",
                            "longitude": "-108.81316000"
                        },
                        {
                            "id": 120732,
                            "name": "Lone Tree",
                            "latitude": "39.55171000",
                            "longitude": "-104.88630000"
                        },
                        {
                            "id": 120754,
                            "name": "Longmont",
                            "latitude": "40.16721000",
                            "longitude": "-105.10193000"
                        },
                        {
                            "id": 120817,
                            "name": "Louisville",
                            "latitude": "39.97776000",
                            "longitude": "-105.13193000"
                        },
                        {
                            "id": 120822,
                            "name": "Loveland",
                            "latitude": "40.39776000",
                            "longitude": "-105.07498000"
                        },
                        {
                            "id": 120945,
                            "name": "Lyons",
                            "latitude": "40.22471000",
                            "longitude": "-105.27138000"
                        },
                        {
                            "id": 121105,
                            "name": "Mancos",
                            "latitude": "37.34500000",
                            "longitude": "-108.28925000"
                        },
                        {
                            "id": 121126,
                            "name": "Manitou Springs",
                            "latitude": "38.85971000",
                            "longitude": "-104.91720000"
                        },
                        {
                            "id": 121552,
                            "name": "Mead",
                            "latitude": "40.23332000",
                            "longitude": "-104.99859000"
                        },
                        {
                            "id": 121613,
                            "name": "Meeker",
                            "latitude": "40.03747000",
                            "longitude": "-107.91313000"
                        },
                        {
                            "id": 121694,
                            "name": "Meridian",
                            "latitude": "39.53957000",
                            "longitude": "-104.84528000"
                        },
                        {
                            "id": 121721,
                            "name": "Mesa County",
                            "latitude": "39.01828000",
                            "longitude": "-108.46645000"
                        },
                        {
                            "id": 121890,
                            "name": "Milliken",
                            "latitude": "40.32943000",
                            "longitude": "-104.85525000"
                        },
                        {
                            "id": 121935,
                            "name": "Mineral County",
                            "latitude": "37.66900000",
                            "longitude": "-106.92409000"
                        },
                        {
                            "id": 121969,
                            "name": "Minturn",
                            "latitude": "39.58637000",
                            "longitude": "-106.43086000"
                        },
                        {
                            "id": 122016,
                            "name": "Moffat County",
                            "latitude": "40.61843000",
                            "longitude": "-108.20730000"
                        },
                        {
                            "id": 122111,
                            "name": "Monte Vista",
                            "latitude": "37.57917000",
                            "longitude": "-106.14808000"
                        },
                        {
                            "id": 122129,
                            "name": "Montezuma County",
                            "latitude": "37.33841000",
                            "longitude": "-108.59671000"
                        },
                        {
                            "id": 122184,
                            "name": "Montrose",
                            "latitude": "38.47832000",
                            "longitude": "-107.87617000"
                        },
                        {
                            "id": 122185,
                            "name": "Montrose County",
                            "latitude": "38.40218000",
                            "longitude": "-108.26936000"
                        },
                        {
                            "id": 122191,
                            "name": "Monument",
                            "latitude": "39.09166000",
                            "longitude": "-104.87276000"
                        },
                        {
                            "id": 122244,
                            "name": "Morgan County",
                            "latitude": "40.26271000",
                            "longitude": "-103.80982000"
                        },
                        {
                            "id": 122435,
                            "name": "Mountain Village",
                            "latitude": "37.93138000",
                            "longitude": "-107.85645000"
                        },
                        {
                            "id": 122611,
                            "name": "Nederland",
                            "latitude": "39.96138000",
                            "longitude": "-105.51083000"
                        },
                        {
                            "id": 122689,
                            "name": "New Castle",
                            "latitude": "39.57276000",
                            "longitude": "-107.53644000"
                        },
                        {
                            "id": 122911,
                            "name": "Niwot",
                            "latitude": "40.10387000",
                            "longitude": "-105.17082000"
                        },
                        {
                            "id": 123155,
                            "name": "Northglenn",
                            "latitude": "39.88554000",
                            "longitude": "-104.98720000"
                        },
                        {
                            "id": 123413,
                            "name": "Olathe",
                            "latitude": "38.60499000",
                            "longitude": "-107.98229000"
                        },
                        {
                            "id": 123532,
                            "name": "Orchard City",
                            "latitude": "38.82832000",
                            "longitude": "-107.97090000"
                        },
                        {
                            "id": 123537,
                            "name": "Orchard Mesa",
                            "latitude": "39.04304000",
                            "longitude": "-108.55232000"
                        },
                        {
                            "id": 123543,
                            "name": "Ordway",
                            "latitude": "38.21806000",
                            "longitude": "-103.75606000"
                        },
                        {
                            "id": 123625,
                            "name": "Otero County",
                            "latitude": "37.90270000",
                            "longitude": "-103.71645000"
                        },
                        {
                            "id": 123646,
                            "name": "Ouray",
                            "latitude": "38.02277000",
                            "longitude": "-107.67145000"
                        },
                        {
                            "id": 123647,
                            "name": "Ouray County",
                            "latitude": "38.15550000",
                            "longitude": "-107.76932000"
                        },
                        {
                            "id": 123722,
                            "name": "Pagosa Springs",
                            "latitude": "37.26945000",
                            "longitude": "-107.00976000"
                        },
                        {
                            "id": 123738,
                            "name": "Palisade",
                            "latitude": "39.11026000",
                            "longitude": "-108.35092000"
                        },
                        {
                            "id": 123762,
                            "name": "Palmer Lake",
                            "latitude": "39.12221000",
                            "longitude": "-104.91720000"
                        },
                        {
                            "id": 123813,
                            "name": "Paonia",
                            "latitude": "38.86832000",
                            "longitude": "-107.59200000"
                        },
                        {
                            "id": 123815,
                            "name": "Parachute",
                            "latitude": "39.45192000",
                            "longitude": "-108.05285000"
                        },
                        {
                            "id": 123841,
                            "name": "Park County",
                            "latitude": "39.11930000",
                            "longitude": "-105.71717000"
                        },
                        {
                            "id": 123863,
                            "name": "Parker",
                            "latitude": "39.51860000",
                            "longitude": "-104.76136000"
                        },
                        {
                            "id": 124043,
                            "name": "Penrose",
                            "latitude": "38.42500000",
                            "longitude": "-105.02276000"
                        },
                        {
                            "id": 124084,
                            "name": "Perry Park",
                            "latitude": "39.25666000",
                            "longitude": "-104.99248000"
                        },
                        {
                            "id": 124137,
                            "name": "Phillips County",
                            "latitude": "40.59388000",
                            "longitude": "-102.35758000"
                        },
                        {
                            "id": 124286,
                            "name": "Pitkin County",
                            "latitude": "39.21711000",
                            "longitude": "-106.91658000"
                        },
                        {
                            "id": 124358,
                            "name": "Platteville",
                            "latitude": "40.21498000",
                            "longitude": "-104.82275000"
                        },
                        {
                            "id": 124470,
                            "name": "Ponderosa Park",
                            "latitude": "39.40832000",
                            "longitude": "-104.65108000"
                        },
                        {
                            "id": 124722,
                            "name": "Prowers County",
                            "latitude": "37.95518000",
                            "longitude": "-102.39335000"
                        },
                        {
                            "id": 124728,
                            "name": "Pueblo",
                            "latitude": "38.25445000",
                            "longitude": "-104.60914000"
                        },
                        {
                            "id": 124729,
                            "name": "Pueblo County",
                            "latitude": "38.17342000",
                            "longitude": "-104.51285000"
                        },
                        {
                            "id": 124730,
                            "name": "Pueblo West",
                            "latitude": "38.35000000",
                            "longitude": "-104.72275000"
                        },
                        {
                            "id": 124881,
                            "name": "Rangely",
                            "latitude": "40.08748000",
                            "longitude": "-108.80483000"
                        },
                        {
                            "id": 124977,
                            "name": "Redlands",
                            "latitude": "39.07887000",
                            "longitude": "-108.63565000"
                        },
                        {
                            "id": 125136,
                            "name": "Rifle",
                            "latitude": "39.53470000",
                            "longitude": "-107.78312000"
                        },
                        {
                            "id": 125148,
                            "name": "Rio Blanco County",
                            "latitude": "39.97984000",
                            "longitude": "-108.21721000"
                        },
                        {
                            "id": 125155,
                            "name": "Rio Grande County",
                            "latitude": "37.58252000",
                            "longitude": "-106.38321000"
                        },
                        {
                            "id": 125334,
                            "name": "Rocky Ford",
                            "latitude": "38.05251000",
                            "longitude": "-103.72023000"
                        },
                        {
                            "id": 125476,
                            "name": "Routt County",
                            "latitude": "40.48507000",
                            "longitude": "-106.99119000"
                        },
                        {
                            "id": 125485,
                            "name": "Roxborough Park",
                            "latitude": "39.47388000",
                            "longitude": "-105.08526000"
                        },
                        {
                            "id": 125591,
                            "name": "Saguache",
                            "latitude": "38.08750000",
                            "longitude": "-106.14197000"
                        },
                        {
                            "id": 125592,
                            "name": "Saguache County",
                            "latitude": "38.08055000",
                            "longitude": "-106.28156000"
                        },
                        {
                            "id": 125743,
                            "name": "Salida",
                            "latitude": "38.53472000",
                            "longitude": "-105.99890000"
                        },
                        {
                            "id": 125820,
                            "name": "San Juan County",
                            "latitude": "37.76404000",
                            "longitude": "-107.67615000"
                        },
                        {
                            "id": 125828,
                            "name": "San Luis",
                            "latitude": "37.20085000",
                            "longitude": "-105.42390000"
                        },
                        {
                            "id": 125840,
                            "name": "San Miguel County",
                            "latitude": "38.00374000",
                            "longitude": "-108.40583000"
                        },
                        {
                            "id": 126113,
                            "name": "Security-Widefield",
                            "latitude": "38.74728000",
                            "longitude": "-104.71439000"
                        },
                        {
                            "id": 126120,
                            "name": "Sedgwick County",
                            "latitude": "40.87591000",
                            "longitude": "-102.35179000"
                        },
                        {
                            "id": 126177,
                            "name": "Severance",
                            "latitude": "40.52415000",
                            "longitude": "-104.85108000"
                        },
                        {
                            "id": 126246,
                            "name": "Shaw Heights",
                            "latitude": "39.85250000",
                            "longitude": "-105.04306000"
                        },
                        {
                            "id": 126320,
                            "name": "Sheridan",
                            "latitude": "39.64693000",
                            "longitude": "-105.02526000"
                        },
                        {
                            "id": 126337,
                            "name": "Sherrelwood",
                            "latitude": "39.83776000",
                            "longitude": "-105.00137000"
                        },
                        {
                            "id": 126419,
                            "name": "Silt",
                            "latitude": "39.54859000",
                            "longitude": "-107.65617000"
                        },
                        {
                            "id": 126440,
                            "name": "Silverthorne",
                            "latitude": "39.63214000",
                            "longitude": "-106.07428000"
                        },
                        {
                            "id": 126441,
                            "name": "Silverton",
                            "latitude": "37.81194000",
                            "longitude": "-107.66451000"
                        },
                        {
                            "id": 126548,
                            "name": "Snowmass Village",
                            "latitude": "39.21304000",
                            "longitude": "-106.93782000"
                        },
                        {
                            "id": 126776,
                            "name": "Southglenn",
                            "latitude": "39.58721000",
                            "longitude": "-104.95276000"
                        },
                        {
                            "id": 126906,
                            "name": "Springfield",
                            "latitude": "37.40835000",
                            "longitude": "-102.61436000"
                        },
                        {
                            "id": 127003,
                            "name": "Steamboat Springs",
                            "latitude": "40.48498000",
                            "longitude": "-106.83172000"
                        },
                        {
                            "id": 127030,
                            "name": "Sterling",
                            "latitude": "40.62554000",
                            "longitude": "-103.20771000"
                        },
                        {
                            "id": 127088,
                            "name": "Stonegate",
                            "latitude": "39.53082000",
                            "longitude": "-104.80386000"
                        },
                        {
                            "id": 127121,
                            "name": "Strasburg",
                            "latitude": "39.73832000",
                            "longitude": "-104.32329000"
                        },
                        {
                            "id": 127132,
                            "name": "Stratmoor",
                            "latitude": "38.77388000",
                            "longitude": "-104.77970000"
                        },
                        {
                            "id": 127216,
                            "name": "Summit County",
                            "latitude": "39.63417000",
                            "longitude": "-106.11638000"
                        },
                        {
                            "id": 127281,
                            "name": "Superior",
                            "latitude": "39.95276000",
                            "longitude": "-105.16860000"
                        },
                        {
                            "id": 127489,
                            "name": "Teller County",
                            "latitude": "38.88217000",
                            "longitude": "-105.16183000"
                        },
                        {
                            "id": 127491,
                            "name": "Telluride",
                            "latitude": "37.93749000",
                            "longitude": "-107.81229000"
                        },
                        {
                            "id": 127563,
                            "name": "The Pinery",
                            "latitude": "39.45527000",
                            "longitude": "-104.73442000"
                        },
                        {
                            "id": 127603,
                            "name": "Thornton",
                            "latitude": "39.86804000",
                            "longitude": "-104.97192000"
                        },
                        {
                            "id": 127695,
                            "name": "Todd Creek",
                            "latitude": "39.97804000",
                            "longitude": "-104.87331000"
                        },
                        {
                            "id": 127753,
                            "name": "Towaoc",
                            "latitude": "37.20444000",
                            "longitude": "-108.72954000"
                        },
                        {
                            "id": 127823,
                            "name": "Trinidad",
                            "latitude": "37.16946000",
                            "longitude": "-104.50054000"
                        },
                        {
                            "id": 127931,
                            "name": "Twin Lakes",
                            "latitude": "39.82499000",
                            "longitude": "-105.00470000"
                        },
                        {
                            "id": 128041,
                            "name": "Upper Bear Creek",
                            "latitude": "39.62385000",
                            "longitude": "-105.41780000"
                        },
                        {
                            "id": 128076,
                            "name": "Vail",
                            "latitude": "39.64026000",
                            "longitude": "-106.37420000"
                        },
                        {
                            "id": 128369,
                            "name": "Walden",
                            "latitude": "40.73164000",
                            "longitude": "-106.28364000"
                        },
                        {
                            "id": 128426,
                            "name": "Walsenburg",
                            "latitude": "37.62418000",
                            "longitude": "-104.78026000"
                        },
                        {
                            "id": 128577,
                            "name": "Washington County",
                            "latitude": "39.97106000",
                            "longitude": "-103.20125000"
                        },
                        {
                            "id": 128764,
                            "name": "Welby",
                            "latitude": "39.83665000",
                            "longitude": "-104.95915000"
                        },
                        {
                            "id": 128768,
                            "name": "Weld County",
                            "latitude": "40.55484000",
                            "longitude": "-104.39253000"
                        },
                        {
                            "id": 128778,
                            "name": "Wellington",
                            "latitude": "40.70387000",
                            "longitude": "-105.00859000"
                        },
                        {
                            "id": 128957,
                            "name": "West Pleasant View",
                            "latitude": "39.73256000",
                            "longitude": "-105.17852000"
                        },
                        {
                            "id": 129021,
                            "name": "Westcliffe",
                            "latitude": "38.13472000",
                            "longitude": "-105.46584000"
                        },
                        {
                            "id": 129047,
                            "name": "Westminster",
                            "latitude": "39.83665000",
                            "longitude": "-105.03720000"
                        },
                        {
                            "id": 129107,
                            "name": "Wheat Ridge",
                            "latitude": "39.76610000",
                            "longitude": "-105.07721000"
                        },
                        {
                            "id": 129387,
                            "name": "Windsor",
                            "latitude": "40.47748000",
                            "longitude": "-104.90136000"
                        },
                        {
                            "id": 129533,
                            "name": "Woodland Park",
                            "latitude": "38.99388000",
                            "longitude": "-105.05693000"
                        },
                        {
                            "id": 129543,
                            "name": "Woodmoor",
                            "latitude": "39.10138000",
                            "longitude": "-104.84748000"
                        },
                        {
                            "id": 129602,
                            "name": "Wray",
                            "latitude": "40.07582000",
                            "longitude": "-102.22325000"
                        },
                        {
                            "id": 129734,
                            "name": "Yuma",
                            "latitude": "40.12221000",
                            "longitude": "-102.72521000"
                        },
                        {
                            "id": 129736,
                            "name": "Yuma County",
                            "latitude": "40.00290000",
                            "longitude": "-102.42423000"
                        }
                    ]
                },
                {
                    "id": 1435,
                    "name": "Connecticut",
                    "state_code": "CT",
                    "cities": [
                        {
                            "id": 111362,
                            "name": "Ansonia",
                            "latitude": "41.34621000",
                            "longitude": "-73.07900000"
                        },
                        {
                            "id": 111781,
                            "name": "Baltic",
                            "latitude": "41.61704000",
                            "longitude": "-72.08452000"
                        },
                        {
                            "id": 112280,
                            "name": "Bethel",
                            "latitude": "41.37121000",
                            "longitude": "-73.41401000"
                        },
                        {
                            "id": 112292,
                            "name": "Bethlehem Village",
                            "latitude": "41.64010000",
                            "longitude": "-73.20308000"
                        },
                        {
                            "id": 112468,
                            "name": "Blue Hills",
                            "latitude": "41.81288000",
                            "longitude": "-72.69759000"
                        },
                        {
                            "id": 112693,
                            "name": "Branford",
                            "latitude": "41.27954000",
                            "longitude": "-72.81510000"
                        },
                        {
                            "id": 112694,
                            "name": "Branford Center",
                            "latitude": "41.27738000",
                            "longitude": "-72.81511000"
                        },
                        {
                            "id": 112766,
                            "name": "Bridgeport",
                            "latitude": "41.17923000",
                            "longitude": "-73.18945000"
                        },
                        {
                            "id": 112809,
                            "name": "Bristol",
                            "latitude": "41.67176000",
                            "longitude": "-72.94927000"
                        },
                        {
                            "id": 113140,
                            "name": "Byram",
                            "latitude": "41.00426000",
                            "longitude": "-73.65374000"
                        },
                        {
                            "id": 113313,
                            "name": "Canaan",
                            "latitude": "42.02731000",
                            "longitude": "-73.32928000"
                        },
                        {
                            "id": 113351,
                            "name": "Canton Valley",
                            "latitude": "41.83426000",
                            "longitude": "-72.89177000"
                        },
                        {
                            "id": 113684,
                            "name": "Central Waterford",
                            "latitude": "41.34504000",
                            "longitude": "-72.12948000"
                        },
                        {
                            "id": 113877,
                            "name": "Cheshire",
                            "latitude": "41.49899000",
                            "longitude": "-72.90066000"
                        },
                        {
                            "id": 113879,
                            "name": "Cheshire Village",
                            "latitude": "41.50260000",
                            "longitude": "-72.89952000"
                        },
                        {
                            "id": 113894,
                            "name": "Chester Center",
                            "latitude": "41.40132000",
                            "longitude": "-72.45270000"
                        },
                        {
                            "id": 114071,
                            "name": "City of Milford (balance)",
                            "latitude": "41.22374000",
                            "longitude": "-73.06164000"
                        },
                        {
                            "id": 114285,
                            "name": "Clinton",
                            "latitude": "41.27871000",
                            "longitude": "-72.52759000"
                        },
                        {
                            "id": 114383,
                            "name": "Colchester",
                            "latitude": "41.57565000",
                            "longitude": "-72.33203000"
                        },
                        {
                            "id": 114439,
                            "name": "Collinsville",
                            "latitude": "41.81288000",
                            "longitude": "-72.92010000"
                        },
                        {
                            "id": 114566,
                            "name": "Conning Towers-Nautilus Park",
                            "latitude": "41.38548000",
                            "longitude": "-72.06877000"
                        },
                        {
                            "id": 114684,
                            "name": "Cos Cob",
                            "latitude": "41.03343000",
                            "longitude": "-73.59957000"
                        },
                        {
                            "id": 114733,
                            "name": "Coventry Lake",
                            "latitude": "41.77232000",
                            "longitude": "-72.33258000"
                        },
                        {
                            "id": 114835,
                            "name": "Cromwell",
                            "latitude": "41.59510000",
                            "longitude": "-72.64537000"
                        },
                        {
                            "id": 114879,
                            "name": "Crystal Lake",
                            "latitude": "41.93176000",
                            "longitude": "-72.37842000"
                        },
                        {
                            "id": 115015,
                            "name": "Danbury",
                            "latitude": "41.39482000",
                            "longitude": "-73.45401000"
                        },
                        {
                            "id": 115025,
                            "name": "Danielson",
                            "latitude": "41.80260000",
                            "longitude": "-71.88591000"
                        },
                        {
                            "id": 115046,
                            "name": "Darien",
                            "latitude": "41.07871000",
                            "longitude": "-73.46929000"
                        },
                        {
                            "id": 115170,
                            "name": "Deep River Center",
                            "latitude": "41.38221000",
                            "longitude": "-72.43862000"
                        },
                        {
                            "id": 115261,
                            "name": "Derby",
                            "latitude": "41.32065000",
                            "longitude": "-73.08900000"
                        },
                        {
                            "id": 115570,
                            "name": "Durham",
                            "latitude": "41.48176000",
                            "longitude": "-72.68121000"
                        },
                        {
                            "id": 115640,
                            "name": "East Brooklyn",
                            "latitude": "41.79677000",
                            "longitude": "-71.89729000"
                        },
                        {
                            "id": 115677,
                            "name": "East Haddam",
                            "latitude": "41.45315000",
                            "longitude": "-72.46120000"
                        },
                        {
                            "id": 115678,
                            "name": "East Hampton",
                            "latitude": "41.57593000",
                            "longitude": "-72.50259000"
                        },
                        {
                            "id": 115684,
                            "name": "East Hartford",
                            "latitude": "41.78232000",
                            "longitude": "-72.61203000"
                        },
                        {
                            "id": 115686,
                            "name": "East Haven",
                            "latitude": "41.27621000",
                            "longitude": "-72.86843000"
                        },
                        {
                            "id": 115723,
                            "name": "East Norwalk",
                            "latitude": "41.10565000",
                            "longitude": "-73.39845000"
                        },
                        {
                            "id": 115774,
                            "name": "East Windsor",
                            "latitude": "41.91232000",
                            "longitude": "-72.54509000"
                        },
                        {
                            "id": 115786,
                            "name": "Easton",
                            "latitude": "41.25287000",
                            "longitude": "-73.29734000"
                        },
                        {
                            "id": 116026,
                            "name": "Ellington",
                            "latitude": "41.90399000",
                            "longitude": "-72.46981000"
                        },
                        {
                            "id": 116125,
                            "name": "Enfield",
                            "latitude": "41.97621000",
                            "longitude": "-72.59176000"
                        },
                        {
                            "id": 116200,
                            "name": "Essex Village",
                            "latitude": "41.35544000",
                            "longitude": "-72.39101000"
                        },
                        {
                            "id": 116312,
                            "name": "Fairfield",
                            "latitude": "41.14121000",
                            "longitude": "-73.26373000"
                        },
                        {
                            "id": 116320,
                            "name": "Fairfield County",
                            "latitude": "41.22496000",
                            "longitude": "-73.37120000"
                        },
                        {
                            "id": 116415,
                            "name": "Farmington",
                            "latitude": "41.71982000",
                            "longitude": "-72.83204000"
                        },
                        {
                            "id": 117026,
                            "name": "Gales Ferry",
                            "latitude": "41.42982000",
                            "longitude": "-72.08202000"
                        },
                        {
                            "id": 117161,
                            "name": "Georgetown",
                            "latitude": "41.25565000",
                            "longitude": "-73.43484000"
                        },
                        {
                            "id": 117255,
                            "name": "Glastonbury",
                            "latitude": "41.71232000",
                            "longitude": "-72.60815000"
                        },
                        {
                            "id": 117256,
                            "name": "Glastonbury Center",
                            "latitude": "41.70093000",
                            "longitude": "-72.59953000"
                        },
                        {
                            "id": 117311,
                            "name": "Glenville",
                            "latitude": "41.03538000",
                            "longitude": "-73.65985000"
                        },
                        {
                            "id": 117698,
                            "name": "Greenwich",
                            "latitude": "41.02649000",
                            "longitude": "-73.62846000"
                        },
                        {
                            "id": 117749,
                            "name": "Groton",
                            "latitude": "41.35010000",
                            "longitude": "-72.07841000"
                        },
                        {
                            "id": 117784,
                            "name": "Guilford",
                            "latitude": "41.28899000",
                            "longitude": "-72.68176000"
                        },
                        {
                            "id": 117786,
                            "name": "Guilford Center",
                            "latitude": "41.28156000",
                            "longitude": "-72.67619000"
                        },
                        {
                            "id": 117886,
                            "name": "Hamden",
                            "latitude": "41.39593000",
                            "longitude": "-72.89677000"
                        },
                        {
                            "id": 118087,
                            "name": "Hartford",
                            "latitude": "41.76371000",
                            "longitude": "-72.68509000"
                        },
                        {
                            "id": 118095,
                            "name": "Hartford County",
                            "latitude": "41.80642000",
                            "longitude": "-72.73284000"
                        },
                        {
                            "id": 118201,
                            "name": "Hazardville",
                            "latitude": "41.98732000",
                            "longitude": "-72.54481000"
                        },
                        {
                            "id": 118234,
                            "name": "Hebron",
                            "latitude": "41.65788000",
                            "longitude": "-72.36592000"
                        },
                        {
                            "id": 118322,
                            "name": "Heritage Village",
                            "latitude": "41.48565000",
                            "longitude": "-73.23789000"
                        },
                        {
                            "id": 118383,
                            "name": "Higganum",
                            "latitude": "41.49704000",
                            "longitude": "-72.55703000"
                        },
                        {
                            "id": 119245,
                            "name": "Jewett City",
                            "latitude": "41.60677000",
                            "longitude": "-71.98091000"
                        },
                        {
                            "id": 119481,
                            "name": "Kensington",
                            "latitude": "41.63538000",
                            "longitude": "-72.76871000"
                        },
                        {
                            "id": 119486,
                            "name": "Kent",
                            "latitude": "41.72482000",
                            "longitude": "-73.47707000"
                        },
                        {
                            "id": 119561,
                            "name": "Killingly Center",
                            "latitude": "41.83871000",
                            "longitude": "-71.86924000"
                        },
                        {
                            "id": 119965,
                            "name": "Lake Pocotopaug",
                            "latitude": "41.59843000",
                            "longitude": "-72.51037000"
                        },
                        {
                            "id": 120302,
                            "name": "Ledyard",
                            "latitude": "41.43982000",
                            "longitude": "-72.01424000"
                        },
                        {
                            "id": 120597,
                            "name": "Lisbon",
                            "latitude": "41.60399000",
                            "longitude": "-72.01174000"
                        },
                        {
                            "id": 120604,
                            "name": "Litchfield",
                            "latitude": "41.74732000",
                            "longitude": "-73.18872000"
                        },
                        {
                            "id": 120608,
                            "name": "Litchfield County",
                            "latitude": "41.79249000",
                            "longitude": "-73.24532000"
                        },
                        {
                            "id": 120744,
                            "name": "Long Hill",
                            "latitude": "41.35399000",
                            "longitude": "-72.05230000"
                        },
                        {
                            "id": 120992,
                            "name": "Madison",
                            "latitude": "41.27954000",
                            "longitude": "-72.59843000"
                        },
                        {
                            "id": 121001,
                            "name": "Madison Center",
                            "latitude": "41.27925000",
                            "longitude": "-72.60048000"
                        },
                        {
                            "id": 121094,
                            "name": "Manchester",
                            "latitude": "41.77593000",
                            "longitude": "-72.52148000"
                        },
                        {
                            "id": 121149,
                            "name": "Mansfield City",
                            "latitude": "41.76593000",
                            "longitude": "-72.23369000"
                        },
                        {
                            "id": 121689,
                            "name": "Meriden",
                            "latitude": "41.53815000",
                            "longitude": "-72.80704000"
                        },
                        {
                            "id": 121771,
                            "name": "Middlebury",
                            "latitude": "41.52787000",
                            "longitude": "-73.12761000"
                        },
                        {
                            "id": 121779,
                            "name": "Middlesex County",
                            "latitude": "41.43538000",
                            "longitude": "-72.52312000"
                        },
                        {
                            "id": 121788,
                            "name": "Middletown",
                            "latitude": "41.56232000",
                            "longitude": "-72.65065000"
                        },
                        {
                            "id": 121847,
                            "name": "Milford",
                            "latitude": "41.22232000",
                            "longitude": "-73.05650000"
                        },
                        {
                            "id": 122189,
                            "name": "Montville Center",
                            "latitude": "41.47899000",
                            "longitude": "-72.15119000"
                        },
                        {
                            "id": 122194,
                            "name": "Moodus",
                            "latitude": "41.50288000",
                            "longitude": "-72.45009000"
                        },
                        {
                            "id": 122217,
                            "name": "Moosup",
                            "latitude": "41.71288000",
                            "longitude": "-71.88091000"
                        },
                        {
                            "id": 122526,
                            "name": "Mystic",
                            "latitude": "41.35427000",
                            "longitude": "-71.96646000"
                        },
                        {
                            "id": 122595,
                            "name": "Naugatuck",
                            "latitude": "41.48593000",
                            "longitude": "-73.05066000"
                        },
                        {
                            "id": 122674,
                            "name": "New Britain",
                            "latitude": "41.66121000",
                            "longitude": "-72.77954000"
                        },
                        {
                            "id": 122679,
                            "name": "New Canaan",
                            "latitude": "41.14676000",
                            "longitude": "-73.49484000"
                        },
                        {
                            "id": 122705,
                            "name": "New Fairfield",
                            "latitude": "41.46648000",
                            "longitude": "-73.48568000"
                        },
                        {
                            "id": 122713,
                            "name": "New Hartford Center",
                            "latitude": "41.87996000",
                            "longitude": "-72.97530000"
                        },
                        {
                            "id": 122716,
                            "name": "New Haven",
                            "latitude": "41.30815000",
                            "longitude": "-72.92816000"
                        },
                        {
                            "id": 122719,
                            "name": "New Haven County",
                            "latitude": "41.34882000",
                            "longitude": "-72.89986000"
                        },
                        {
                            "id": 122741,
                            "name": "New London",
                            "latitude": "41.35565000",
                            "longitude": "-72.09952000"
                        },
                        {
                            "id": 122746,
                            "name": "New London County",
                            "latitude": "41.46678000",
                            "longitude": "-72.10650000"
                        },
                        {
                            "id": 122754,
                            "name": "New Milford",
                            "latitude": "41.57704000",
                            "longitude": "-73.40845000"
                        },
                        {
                            "id": 122767,
                            "name": "New Preston",
                            "latitude": "41.67510000",
                            "longitude": "-73.35179000"
                        },
                        {
                            "id": 122831,
                            "name": "Newington",
                            "latitude": "41.69788000",
                            "longitude": "-72.72371000"
                        },
                        {
                            "id": 122875,
                            "name": "Newtown",
                            "latitude": "41.41398000",
                            "longitude": "-73.30345000"
                        },
                        {
                            "id": 122883,
                            "name": "Niantic",
                            "latitude": "41.32538000",
                            "longitude": "-72.19313000"
                        },
                        {
                            "id": 122915,
                            "name": "Noank",
                            "latitude": "41.32788000",
                            "longitude": "-71.99063000"
                        },
                        {
                            "id": 122995,
                            "name": "North Branford",
                            "latitude": "41.32760000",
                            "longitude": "-72.76732000"
                        },
                        {
                            "id": 123031,
                            "name": "North Granby",
                            "latitude": "41.99593000",
                            "longitude": "-72.82954000"
                        },
                        {
                            "id": 123033,
                            "name": "North Grosvenor Dale",
                            "latitude": "41.98565000",
                            "longitude": "-71.89868000"
                        },
                        {
                            "id": 123037,
                            "name": "North Haven",
                            "latitude": "41.39093000",
                            "longitude": "-72.85954000"
                        },
                        {
                            "id": 123111,
                            "name": "North Stamford",
                            "latitude": "41.13815000",
                            "longitude": "-73.54346000"
                        },
                        {
                            "id": 123177,
                            "name": "Northwest Harwinton",
                            "latitude": "41.77685000",
                            "longitude": "-73.07922000"
                        },
                        {
                            "id": 123190,
                            "name": "Norwalk",
                            "latitude": "41.11760000",
                            "longitude": "-73.40790000"
                        },
                        {
                            "id": 123195,
                            "name": "Norwich",
                            "latitude": "41.52426000",
                            "longitude": "-72.07591000"
                        },
                        {
                            "id": 123307,
                            "name": "Oakville",
                            "latitude": "41.59343000",
                            "longitude": "-73.08539000"
                        },
                        {
                            "id": 123420,
                            "name": "Old Greenwich",
                            "latitude": "41.02287000",
                            "longitude": "-73.56485000"
                        },
                        {
                            "id": 123423,
                            "name": "Old Mystic",
                            "latitude": "41.39149000",
                            "longitude": "-71.96174000"
                        },
                        {
                            "id": 123427,
                            "name": "Old Saybrook",
                            "latitude": "41.29177000",
                            "longitude": "-72.37620000"
                        },
                        {
                            "id": 123428,
                            "name": "Old Saybrook Center",
                            "latitude": "41.29150000",
                            "longitude": "-72.36528000"
                        },
                        {
                            "id": 123507,
                            "name": "Orange",
                            "latitude": "41.27843000",
                            "longitude": "-73.02566000"
                        },
                        {
                            "id": 123681,
                            "name": "Oxford",
                            "latitude": "41.43399000",
                            "longitude": "-73.11678000"
                        },
                        {
                            "id": 123690,
                            "name": "Oxoboxo River",
                            "latitude": "41.44391000",
                            "longitude": "-72.12502000"
                        },
                        {
                            "id": 123929,
                            "name": "Pawcatuck",
                            "latitude": "41.37732000",
                            "longitude": "-71.83368000"
                        },
                        {
                            "id": 124002,
                            "name": "Pemberwick",
                            "latitude": "41.02565000",
                            "longitude": "-73.66068000"
                        },
                        {
                            "id": 124319,
                            "name": "Plainfield",
                            "latitude": "41.67649000",
                            "longitude": "-71.91507000"
                        },
                        {
                            "id": 124322,
                            "name": "Plainfield Village",
                            "latitude": "41.67686000",
                            "longitude": "-71.92489000"
                        },
                        {
                            "id": 124334,
                            "name": "Plainville",
                            "latitude": "41.67454000",
                            "longitude": "-72.85816000"
                        },
                        {
                            "id": 124400,
                            "name": "Plymouth",
                            "latitude": "41.67204000",
                            "longitude": "-73.05289000"
                        },
                        {
                            "id": 124488,
                            "name": "Poquonock Bridge",
                            "latitude": "41.34510000",
                            "longitude": "-72.02480000"
                        },
                        {
                            "id": 124559,
                            "name": "Portland",
                            "latitude": "41.57288000",
                            "longitude": "-72.64065000"
                        },
                        {
                            "id": 124658,
                            "name": "Preston City",
                            "latitude": "41.52899000",
                            "longitude": "-71.97396000"
                        },
                        {
                            "id": 124706,
                            "name": "Prospect",
                            "latitude": "41.50232000",
                            "longitude": "-72.97872000"
                        },
                        {
                            "id": 124760,
                            "name": "Putnam",
                            "latitude": "41.91510000",
                            "longitude": "-71.90896000"
                        },
                        {
                            "id": 124801,
                            "name": "Quinebaug",
                            "latitude": "42.02371000",
                            "longitude": "-71.94980000"
                        },
                        {
                            "id": 125119,
                            "name": "Ridgefield",
                            "latitude": "41.28148000",
                            "longitude": "-73.49818000"
                        },
                        {
                            "id": 125204,
                            "name": "Riverside",
                            "latitude": "41.03371000",
                            "longitude": "-73.57818000"
                        },
                        {
                            "id": 125323,
                            "name": "Rockville",
                            "latitude": "41.86676000",
                            "longitude": "-72.44953000"
                        },
                        {
                            "id": 125732,
                            "name": "Salem",
                            "latitude": "41.49038000",
                            "longitude": "-72.27536000"
                        },
                        {
                            "id": 125764,
                            "name": "Salmon Brook",
                            "latitude": "41.95649000",
                            "longitude": "-72.79537000"
                        },
                        {
                            "id": 125986,
                            "name": "Saybrook Manor",
                            "latitude": "41.28538000",
                            "longitude": "-72.39897000"
                        },
                        {
                            "id": 126196,
                            "name": "Seymour",
                            "latitude": "41.39676000",
                            "longitude": "-73.07594000"
                        },
                        {
                            "id": 126297,
                            "name": "Shelton",
                            "latitude": "41.31649000",
                            "longitude": "-73.09316000"
                        },
                        {
                            "id": 126331,
                            "name": "Sherman",
                            "latitude": "41.57926000",
                            "longitude": "-73.49568000"
                        },
                        {
                            "id": 126343,
                            "name": "Sherwood Manor",
                            "latitude": "42.01343000",
                            "longitude": "-72.56425000"
                        },
                        {
                            "id": 126453,
                            "name": "Simsbury Center",
                            "latitude": "41.88088000",
                            "longitude": "-72.81116000"
                        },
                        {
                            "id": 126575,
                            "name": "Somers",
                            "latitude": "41.98537000",
                            "longitude": "-72.44620000"
                        },
                        {
                            "id": 126644,
                            "name": "South Coventry",
                            "latitude": "41.77010000",
                            "longitude": "-72.30508000"
                        },
                        {
                            "id": 126751,
                            "name": "South Windham",
                            "latitude": "41.67954000",
                            "longitude": "-72.17036000"
                        },
                        {
                            "id": 126753,
                            "name": "South Windsor",
                            "latitude": "41.82371000",
                            "longitude": "-72.62120000"
                        },
                        {
                            "id": 126754,
                            "name": "South Woodstock",
                            "latitude": "41.93899000",
                            "longitude": "-71.95952000"
                        },
                        {
                            "id": 126763,
                            "name": "Southbury",
                            "latitude": "41.48148000",
                            "longitude": "-73.21317000"
                        },
                        {
                            "id": 126784,
                            "name": "Southport",
                            "latitude": "41.13649000",
                            "longitude": "-73.28345000"
                        },
                        {
                            "id": 126792,
                            "name": "Southwood Acres",
                            "latitude": "41.96260000",
                            "longitude": "-72.57148000"
                        },
                        {
                            "id": 126934,
                            "name": "Stafford",
                            "latitude": "41.98482000",
                            "longitude": "-72.28897000"
                        },
                        {
                            "id": 126938,
                            "name": "Stafford Springs",
                            "latitude": "41.95426000",
                            "longitude": "-72.30230000"
                        },
                        {
                            "id": 126944,
                            "name": "Stamford",
                            "latitude": "41.05343000",
                            "longitude": "-73.53873000"
                        },
                        {
                            "id": 127105,
                            "name": "Storrs",
                            "latitude": "41.80843000",
                            "longitude": "-72.24952000"
                        },
                        {
                            "id": 127124,
                            "name": "Stratford",
                            "latitude": "41.18454000",
                            "longitude": "-73.13317000"
                        },
                        {
                            "id": 127165,
                            "name": "Suffield Depot",
                            "latitude": "41.98121000",
                            "longitude": "-72.64981000"
                        },
                        {
                            "id": 127430,
                            "name": "Tariffville",
                            "latitude": "41.90871000",
                            "longitude": "-72.76010000"
                        },
                        {
                            "id": 127519,
                            "name": "Terramuggus",
                            "latitude": "41.63510000",
                            "longitude": "-72.47036000"
                        },
                        {
                            "id": 127535,
                            "name": "Terryville",
                            "latitude": "41.67815000",
                            "longitude": "-73.01094000"
                        },
                        {
                            "id": 127584,
                            "name": "Thomaston",
                            "latitude": "41.67399000",
                            "longitude": "-73.07316000"
                        },
                        {
                            "id": 127590,
                            "name": "Thompson",
                            "latitude": "41.95871000",
                            "longitude": "-71.86257000"
                        },
                        {
                            "id": 127594,
                            "name": "Thompsonville",
                            "latitude": "41.99704000",
                            "longitude": "-72.59898000"
                        },
                        {
                            "id": 127701,
                            "name": "Tolland",
                            "latitude": "41.87149000",
                            "longitude": "-72.36869000"
                        },
                        {
                            "id": 127702,
                            "name": "Tolland County",
                            "latitude": "41.85501000",
                            "longitude": "-72.33649000"
                        },
                        {
                            "id": 127744,
                            "name": "Torrington",
                            "latitude": "41.80065000",
                            "longitude": "-73.12122000"
                        },
                        {
                            "id": 127858,
                            "name": "Trumbull",
                            "latitude": "41.24287000",
                            "longitude": "-73.20067000"
                        },
                        {
                            "id": 127967,
                            "name": "Uncasville",
                            "latitude": "41.43454000",
                            "longitude": "-72.10980000"
                        },
                        {
                            "id": 128406,
                            "name": "Wallingford",
                            "latitude": "41.45704000",
                            "longitude": "-72.82316000"
                        },
                        {
                            "id": 128407,
                            "name": "Wallingford Center",
                            "latitude": "41.44987000",
                            "longitude": "-72.81892000"
                        },
                        {
                            "id": 128545,
                            "name": "Washington",
                            "latitude": "41.63148000",
                            "longitude": "-73.31067000"
                        },
                        {
                            "id": 128602,
                            "name": "Waterbury",
                            "latitude": "41.55815000",
                            "longitude": "-73.05150000"
                        },
                        {
                            "id": 128610,
                            "name": "Waterford",
                            "latitude": "41.34170000",
                            "longitude": "-72.13597000"
                        },
                        {
                            "id": 128620,
                            "name": "Watertown",
                            "latitude": "41.60621000",
                            "longitude": "-73.11817000"
                        },
                        {
                            "id": 128658,
                            "name": "Wauregan",
                            "latitude": "41.74427000",
                            "longitude": "-71.90924000"
                        },
                        {
                            "id": 128720,
                            "name": "Weatogue",
                            "latitude": "41.84371000",
                            "longitude": "-72.82843000"
                        },
                        {
                            "id": 128885,
                            "name": "West Hartford",
                            "latitude": "41.76204000",
                            "longitude": "-72.74204000"
                        },
                        {
                            "id": 128887,
                            "name": "West Haven",
                            "latitude": "41.27065000",
                            "longitude": "-72.94705000"
                        },
                        {
                            "id": 128982,
                            "name": "West Simsbury",
                            "latitude": "41.87315000",
                            "longitude": "-72.85815000"
                        },
                        {
                            "id": 128991,
                            "name": "West Torrington",
                            "latitude": "41.81843000",
                            "longitude": "-73.14372000"
                        },
                        {
                            "id": 129014,
                            "name": "Westbrook Center",
                            "latitude": "41.27997000",
                            "longitude": "-72.44254000"
                        },
                        {
                            "id": 129072,
                            "name": "Westport",
                            "latitude": "41.14149000",
                            "longitude": "-73.35790000"
                        },
                        {
                            "id": 129090,
                            "name": "Wethersfield",
                            "latitude": "41.71427000",
                            "longitude": "-72.65259000"
                        },
                        {
                            "id": 129293,
                            "name": "Willimantic",
                            "latitude": "41.71065000",
                            "longitude": "-72.20813000"
                        },
                        {
                            "id": 129342,
                            "name": "Wilton",
                            "latitude": "41.19537000",
                            "longitude": "-73.43790000"
                        },
                        {
                            "id": 129362,
                            "name": "Winchester Center",
                            "latitude": "41.90010000",
                            "longitude": "-73.13483000"
                        },
                        {
                            "id": 129371,
                            "name": "Windham",
                            "latitude": "41.69982000",
                            "longitude": "-72.15702000"
                        },
                        {
                            "id": 129373,
                            "name": "Windham County",
                            "latitude": "41.83003000",
                            "longitude": "-71.98749000"
                        },
                        {
                            "id": 129382,
                            "name": "Windsor",
                            "latitude": "41.85260000",
                            "longitude": "-72.64370000"
                        },
                        {
                            "id": 129390,
                            "name": "Windsor Locks",
                            "latitude": "41.92926000",
                            "longitude": "-72.62731000"
                        },
                        {
                            "id": 129430,
                            "name": "Winsted",
                            "latitude": "41.92121000",
                            "longitude": "-73.06011000"
                        },
                        {
                            "id": 129468,
                            "name": "Wolcott",
                            "latitude": "41.60232000",
                            "longitude": "-72.98677000"
                        },
                        {
                            "id": 129499,
                            "name": "Woodbridge",
                            "latitude": "41.35260000",
                            "longitude": "-73.00844000"
                        },
                        {
                            "id": 129508,
                            "name": "Woodbury",
                            "latitude": "41.54454000",
                            "longitude": "-73.20900000"
                        },
                        {
                            "id": 129511,
                            "name": "Woodbury Center",
                            "latitude": "41.54453000",
                            "longitude": "-73.20476000"
                        },
                        {
                            "id": 129542,
                            "name": "Woodmont",
                            "latitude": "41.22815000",
                            "longitude": "-72.99149000"
                        }
                    ]
                },
                {
                    "id": 1399,
                    "name": "Delaware",
                    "state_code": "DE",
                    "cities": [
                        {
                            "id": 111974,
                            "name": "Bear",
                            "latitude": "39.62928000",
                            "longitude": "-75.65826000"
                        },
                        {
                            "id": 112098,
                            "name": "Bellefonte",
                            "latitude": "39.76622000",
                            "longitude": "-75.50936000"
                        },
                        {
                            "id": 112277,
                            "name": "Bethany Beach",
                            "latitude": "38.53956000",
                            "longitude": "-75.05518000"
                        },
                        {
                            "id": 112401,
                            "name": "Blades",
                            "latitude": "38.63567000",
                            "longitude": "-75.60993000"
                        },
                        {
                            "id": 112772,
                            "name": "Bridgeville",
                            "latitude": "38.74261000",
                            "longitude": "-75.60437000"
                        },
                        {
                            "id": 112887,
                            "name": "Brookside",
                            "latitude": "39.66706000",
                            "longitude": "-75.72688000"
                        },
                        {
                            "id": 113261,
                            "name": "Camden",
                            "latitude": "39.11345000",
                            "longitude": "-75.54187000"
                        },
                        {
                            "id": 113914,
                            "name": "Cheswold",
                            "latitude": "39.21928000",
                            "longitude": "-75.58576000"
                        },
                        {
                            "id": 114192,
                            "name": "Claymont",
                            "latitude": "39.80067000",
                            "longitude": "-75.45964000"
                        },
                        {
                            "id": 114197,
                            "name": "Clayton",
                            "latitude": "39.29067000",
                            "longitude": "-75.63437000"
                        },
                        {
                            "id": 115203,
                            "name": "Delaware City",
                            "latitude": "39.57789000",
                            "longitude": "-75.58881000"
                        },
                        {
                            "id": 115217,
                            "name": "Delmar",
                            "latitude": "38.45651000",
                            "longitude": "-75.57715000"
                        },
                        {
                            "id": 115445,
                            "name": "Dover",
                            "latitude": "39.15817000",
                            "longitude": "-75.52437000"
                        },
                        {
                            "id": 115453,
                            "name": "Dover Base Housing",
                            "latitude": "39.11763000",
                            "longitude": "-75.48393000"
                        },
                        {
                            "id": 115847,
                            "name": "Edgemoor",
                            "latitude": "39.75011000",
                            "longitude": "-75.49964000"
                        },
                        {
                            "id": 116070,
                            "name": "Elsmere",
                            "latitude": "39.73928000",
                            "longitude": "-75.59798000"
                        },
                        {
                            "id": 116470,
                            "name": "Felton",
                            "latitude": "39.00845000",
                            "longitude": "-75.57798000"
                        },
                        {
                            "id": 117155,
                            "name": "Georgetown",
                            "latitude": "38.69011000",
                            "longitude": "-75.38547000"
                        },
                        {
                            "id": 117245,
                            "name": "Glasgow",
                            "latitude": "39.60483000",
                            "longitude": "-75.74521000"
                        },
                        {
                            "id": 117681,
                            "name": "Greenville",
                            "latitude": "39.77900000",
                            "longitude": "-75.59826000"
                        },
                        {
                            "id": 117700,
                            "name": "Greenwood",
                            "latitude": "38.80706000",
                            "longitude": "-75.59132000"
                        },
                        {
                            "id": 118041,
                            "name": "Harrington",
                            "latitude": "38.92372000",
                            "longitude": "-75.57770000"
                        },
                        {
                            "id": 118401,
                            "name": "Highland Acres",
                            "latitude": "39.12095000",
                            "longitude": "-75.52187000"
                        },
                        {
                            "id": 118503,
                            "name": "Hockessin",
                            "latitude": "39.78761000",
                            "longitude": "-75.69660000"
                        },
                        {
                            "id": 119488,
                            "name": "Kent Acres",
                            "latitude": "39.13178000",
                            "longitude": "-75.52492000"
                        },
                        {
                            "id": 119490,
                            "name": "Kent County",
                            "latitude": "39.09595000",
                            "longitude": "-75.50461000"
                        },
                        {
                            "id": 120187,
                            "name": "Laurel",
                            "latitude": "38.55650000",
                            "longitude": "-75.57131000"
                        },
                        {
                            "id": 120410,
                            "name": "Lewes",
                            "latitude": "38.77456000",
                            "longitude": "-75.13935000"
                        },
                        {
                            "id": 120748,
                            "name": "Long Neck",
                            "latitude": "38.62011000",
                            "longitude": "-75.15074000"
                        },
                        {
                            "id": 121785,
                            "name": "Middletown",
                            "latitude": "39.44956000",
                            "longitude": "-75.71632000"
                        },
                        {
                            "id": 121846,
                            "name": "Milford",
                            "latitude": "38.91261000",
                            "longitude": "-75.42797000"
                        },
                        {
                            "id": 121900,
                            "name": "Millsboro",
                            "latitude": "38.59150000",
                            "longitude": "-75.29130000"
                        },
                        {
                            "id": 121911,
                            "name": "Milton",
                            "latitude": "38.77761000",
                            "longitude": "-75.30991000"
                        },
                        {
                            "id": 122683,
                            "name": "New Castle",
                            "latitude": "39.66206000",
                            "longitude": "-75.56631000"
                        },
                        {
                            "id": 122690,
                            "name": "New Castle County",
                            "latitude": "39.57833000",
                            "longitude": "-75.63898000"
                        },
                        {
                            "id": 122799,
                            "name": "Newark",
                            "latitude": "39.68372000",
                            "longitude": "-75.74966000"
                        },
                        {
                            "id": 122838,
                            "name": "Newport",
                            "latitude": "39.71372000",
                            "longitude": "-75.60937000"
                        },
                        {
                            "id": 123112,
                            "name": "North Star",
                            "latitude": "39.76122000",
                            "longitude": "-75.71910000"
                        },
                        {
                            "id": 123338,
                            "name": "Ocean View",
                            "latitude": "38.54511000",
                            "longitude": "-75.08907000"
                        },
                        {
                            "id": 124194,
                            "name": "Pike Creek",
                            "latitude": "39.73095000",
                            "longitude": "-75.70410000"
                        },
                        {
                            "id": 124195,
                            "name": "Pike Creek Valley",
                            "latitude": "39.73622000",
                            "longitude": "-75.69827000"
                        },
                        {
                            "id": 125005,
                            "name": "Rehoboth Beach",
                            "latitude": "38.72095000",
                            "longitude": "-75.07601000"
                        },
                        {
                            "id": 125171,
                            "name": "Rising Sun-Lebanon",
                            "latitude": "39.09977000",
                            "longitude": "-75.50488000"
                        },
                        {
                            "id": 125217,
                            "name": "Riverview",
                            "latitude": "39.02650000",
                            "longitude": "-75.51076000"
                        },
                        {
                            "id": 125342,
                            "name": "Rodney Village",
                            "latitude": "39.13206000",
                            "longitude": "-75.53242000"
                        },
                        {
                            "id": 126085,
                            "name": "Seaford",
                            "latitude": "38.64123000",
                            "longitude": "-75.61104000"
                        },
                        {
                            "id": 126132,
                            "name": "Selbyville",
                            "latitude": "38.46039000",
                            "longitude": "-75.22074000"
                        },
                        {
                            "id": 126534,
                            "name": "Smyrna",
                            "latitude": "39.29983000",
                            "longitude": "-75.60465000"
                        },
                        {
                            "id": 127303,
                            "name": "Sussex County",
                            "latitude": "38.68330000",
                            "longitude": "-75.33954000"
                        },
                        {
                            "id": 127764,
                            "name": "Townsend",
                            "latitude": "39.39511000",
                            "longitude": "-75.69160000"
                        },
                        {
                            "id": 129321,
                            "name": "Wilmington",
                            "latitude": "39.74595000",
                            "longitude": "-75.54659000"
                        },
                        {
                            "id": 129326,
                            "name": "Wilmington Manor",
                            "latitude": "39.68678000",
                            "longitude": "-75.58437000"
                        },
                        {
                            "id": 129557,
                            "name": "Woodside East",
                            "latitude": "39.06756000",
                            "longitude": "-75.53748000"
                        },
                        {
                            "id": 129634,
                            "name": "Wyoming",
                            "latitude": "39.11817000",
                            "longitude": "-75.55881000"
                        }
                    ]
                },
                {
                    "id": 1437,
                    "name": "District of Columbia",
                    "state_code": "DC",
                    "cities": [
                        {
                            "id": 111028,
                            "name": "Adams Morgan",
                            "latitude": "38.92150000",
                            "longitude": "-77.04220000"
                        },
                        {
                            "id": 112444,
                            "name": "Bloomingdale",
                            "latitude": "38.91678000",
                            "longitude": "-77.01137000"
                        },
                        {
                            "id": 113920,
                            "name": "Chevy Chase",
                            "latitude": "38.96400000",
                            "longitude": "-77.06776000"
                        },
                        {
                            "id": 126244,
                            "name": "Shaw",
                            "latitude": "38.91206000",
                            "longitude": "-77.02137000"
                        },
                        {
                            "id": 128587,
                            "name": "Washington, D.C.",
                            "latitude": "38.89511000",
                            "longitude": "-77.03637000"
                        }
                    ]
                },
                {
                    "id": 1436,
                    "name": "Florida",
                    "state_code": "FL",
                    "cities": [
                        {
                            "id": 110972,
                            "name": "Aberdeen",
                            "latitude": "26.55063000",
                            "longitude": "-80.14866000"
                        },
                        {
                            "id": 111084,
                            "name": "Alachua",
                            "latitude": "29.75163000",
                            "longitude": "-82.42483000"
                        },
                        {
                            "id": 111085,
                            "name": "Alachua County",
                            "latitude": "29.67476000",
                            "longitude": "-82.35770000"
                        },
                        {
                            "id": 111086,
                            "name": "Alafaya",
                            "latitude": "28.56410000",
                            "longitude": "-81.21140000"
                        },
                        {
                            "id": 111177,
                            "name": "Allapattah",
                            "latitude": "25.81454000",
                            "longitude": "-80.22394000"
                        },
                        {
                            "id": 111237,
                            "name": "Altamonte Springs",
                            "latitude": "28.66111000",
                            "longitude": "-81.36562000"
                        },
                        {
                            "id": 111250,
                            "name": "Alturas",
                            "latitude": "27.87169000",
                            "longitude": "-81.71508000"
                        },
                        {
                            "id": 111255,
                            "name": "Alva",
                            "latitude": "26.71562000",
                            "longitude": "-81.61008000"
                        },
                        {
                            "id": 111321,
                            "name": "Andover",
                            "latitude": "25.96843000",
                            "longitude": "-80.21283000"
                        },
                        {
                            "id": 111348,
                            "name": "Anna Maria",
                            "latitude": "27.53115000",
                            "longitude": "-82.73343000"
                        },
                        {
                            "id": 111382,
                            "name": "Apalachicola",
                            "latitude": "29.72600000",
                            "longitude": "-84.98560000"
                        },
                        {
                            "id": 111388,
                            "name": "Apollo Beach",
                            "latitude": "27.77308000",
                            "longitude": "-82.40759000"
                        },
                        {
                            "id": 111389,
                            "name": "Apopka",
                            "latitude": "28.67617000",
                            "longitude": "-81.51186000"
                        },
                        {
                            "id": 111420,
                            "name": "Arcadia",
                            "latitude": "27.21588000",
                            "longitude": "-81.85842000"
                        },
                        {
                            "id": 111429,
                            "name": "Archer",
                            "latitude": "29.52997000",
                            "longitude": "-82.51900000"
                        },
                        {
                            "id": 111507,
                            "name": "Asbury Lake",
                            "latitude": "30.04913000",
                            "longitude": "-81.82149000"
                        },
                        {
                            "id": 111559,
                            "name": "Astatula",
                            "latitude": "28.70972000",
                            "longitude": "-81.73285000"
                        },
                        {
                            "id": 111560,
                            "name": "Astor",
                            "latitude": "29.16248000",
                            "longitude": "-81.52535000"
                        },
                        {
                            "id": 111595,
                            "name": "Atlantic Beach",
                            "latitude": "30.33441000",
                            "longitude": "-81.39870000"
                        },
                        {
                            "id": 111601,
                            "name": "Atlantis",
                            "latitude": "26.59090000",
                            "longitude": "-80.10088000"
                        },
                        {
                            "id": 111636,
                            "name": "Auburndale",
                            "latitude": "28.06530000",
                            "longitude": "-81.78869000"
                        },
                        {
                            "id": 111678,
                            "name": "Aventura",
                            "latitude": "25.95648000",
                            "longitude": "-80.13921000"
                        },
                        {
                            "id": 111696,
                            "name": "Avon Park",
                            "latitude": "27.59587000",
                            "longitude": "-81.50619000"
                        },
                        {
                            "id": 111709,
                            "name": "Azalea Park",
                            "latitude": "28.54111000",
                            "longitude": "-81.30062000"
                        },
                        {
                            "id": 111715,
                            "name": "Babson Park",
                            "latitude": "27.83197000",
                            "longitude": "-81.52230000"
                        },
                        {
                            "id": 111726,
                            "name": "Bagdad",
                            "latitude": "30.59880000",
                            "longitude": "-87.03223000"
                        },
                        {
                            "id": 111741,
                            "name": "Baker County",
                            "latitude": "30.33107000",
                            "longitude": "-82.28459000"
                        },
                        {
                            "id": 111747,
                            "name": "Bal Harbour",
                            "latitude": "25.89176000",
                            "longitude": "-80.12699000"
                        },
                        {
                            "id": 111753,
                            "name": "Baldwin",
                            "latitude": "30.30274000",
                            "longitude": "-81.97539000"
                        },
                        {
                            "id": 111778,
                            "name": "Balm",
                            "latitude": "27.75947000",
                            "longitude": "-82.26120000"
                        },
                        {
                            "id": 111872,
                            "name": "Bartow",
                            "latitude": "27.89641000",
                            "longitude": "-81.84314000"
                        },
                        {
                            "id": 111924,
                            "name": "Bay County",
                            "latitude": "30.23765000",
                            "longitude": "-85.63262000"
                        },
                        {
                            "id": 111927,
                            "name": "Bay Harbor Islands",
                            "latitude": "25.88759000",
                            "longitude": "-80.13116000"
                        },
                        {
                            "id": 111928,
                            "name": "Bay Hill",
                            "latitude": "28.46806000",
                            "longitude": "-81.51618000"
                        },
                        {
                            "id": 111931,
                            "name": "Bay Pines",
                            "latitude": "27.81419000",
                            "longitude": "-82.77816000"
                        },
                        {
                            "id": 111944,
                            "name": "Bayonet Point",
                            "latitude": "28.32667000",
                            "longitude": "-82.68343000"
                        },
                        {
                            "id": 111954,
                            "name": "Bayshore Gardens",
                            "latitude": "27.42532000",
                            "longitude": "-82.59038000"
                        },
                        {
                            "id": 111969,
                            "name": "Beacon Square",
                            "latitude": "28.20862000",
                            "longitude": "-82.75538000"
                        },
                        {
                            "id": 112035,
                            "name": "Bee Ridge",
                            "latitude": "27.28394000",
                            "longitude": "-82.48065000"
                        },
                        {
                            "id": 112073,
                            "name": "Bellair-Meadowbrook Terrace",
                            "latitude": "30.17881000",
                            "longitude": "-81.74341000"
                        },
                        {
                            "id": 112082,
                            "name": "Belle Glade",
                            "latitude": "26.68451000",
                            "longitude": "-80.66756000"
                        },
                        {
                            "id": 112083,
                            "name": "Belle Glade Camp",
                            "latitude": "26.65757000",
                            "longitude": "-80.68284000"
                        },
                        {
                            "id": 112086,
                            "name": "Belle Isle",
                            "latitude": "28.45834000",
                            "longitude": "-81.35924000"
                        },
                        {
                            "id": 112094,
                            "name": "Belleair",
                            "latitude": "27.93585000",
                            "longitude": "-82.80621000"
                        },
                        {
                            "id": 112095,
                            "name": "Belleair Beach",
                            "latitude": "27.92308000",
                            "longitude": "-82.84316000"
                        },
                        {
                            "id": 112096,
                            "name": "Belleair Bluffs",
                            "latitude": "27.92141000",
                            "longitude": "-82.81705000"
                        },
                        {
                            "id": 112102,
                            "name": "Belleview",
                            "latitude": "29.05526000",
                            "longitude": "-82.06231000"
                        },
                        {
                            "id": 112128,
                            "name": "Bellview",
                            "latitude": "30.46159000",
                            "longitude": "-87.31497000"
                        },
                        {
                            "id": 112303,
                            "name": "Beverly Hills",
                            "latitude": "28.91692000",
                            "longitude": "-82.45815000"
                        },
                        {
                            "id": 112319,
                            "name": "Big Coppitt Key",
                            "latitude": "24.59653000",
                            "longitude": "-81.66009000"
                        },
                        {
                            "id": 112329,
                            "name": "Big Pine Key",
                            "latitude": "24.66987000",
                            "longitude": "-81.35397000"
                        },
                        {
                            "id": 112361,
                            "name": "Biscayne Park",
                            "latitude": "25.88260000",
                            "longitude": "-80.18060000"
                        },
                        {
                            "id": 112369,
                            "name": "Bithlo",
                            "latitude": "28.55472000",
                            "longitude": "-81.10645000"
                        },
                        {
                            "id": 112373,
                            "name": "Black Diamond",
                            "latitude": "28.91248000",
                            "longitude": "-82.48593000"
                        },
                        {
                            "id": 112445,
                            "name": "Bloomingdale",
                            "latitude": "27.89364000",
                            "longitude": "-82.24037000"
                        },
                        {
                            "id": 112459,
                            "name": "Blountstown",
                            "latitude": "30.44379000",
                            "longitude": "-85.04744000"
                        },
                        {
                            "id": 112492,
                            "name": "Boca Del Mar",
                            "latitude": "26.34508000",
                            "longitude": "-80.14671000"
                        },
                        {
                            "id": 112493,
                            "name": "Boca Pointe",
                            "latitude": "26.33313000",
                            "longitude": "-80.15949000"
                        },
                        {
                            "id": 112494,
                            "name": "Boca Raton",
                            "latitude": "26.35869000",
                            "longitude": "-80.08310000"
                        },
                        {
                            "id": 112511,
                            "name": "Bokeelia",
                            "latitude": "26.70563000",
                            "longitude": "-82.15898000"
                        },
                        {
                            "id": 112536,
                            "name": "Bonifay",
                            "latitude": "30.79186000",
                            "longitude": "-85.67965000"
                        },
                        {
                            "id": 112538,
                            "name": "Bonita Springs",
                            "latitude": "26.33981000",
                            "longitude": "-81.77870000"
                        },
                        {
                            "id": 112606,
                            "name": "Boulevard Gardens",
                            "latitude": "26.12326000",
                            "longitude": "-80.17997000"
                        },
                        {
                            "id": 112627,
                            "name": "Bowling Green",
                            "latitude": "27.63837000",
                            "longitude": "-81.82397000"
                        },
                        {
                            "id": 112647,
                            "name": "Boyette",
                            "latitude": "27.81753000",
                            "longitude": "-82.22259000"
                        },
                        {
                            "id": 112652,
                            "name": "Boynton Beach",
                            "latitude": "26.52535000",
                            "longitude": "-80.06643000"
                        },
                        {
                            "id": 112662,
                            "name": "Bradenton",
                            "latitude": "27.49893000",
                            "longitude": "-82.57482000"
                        },
                        {
                            "id": 112663,
                            "name": "Bradenton Beach",
                            "latitude": "27.46698000",
                            "longitude": "-82.70399000"
                        },
                        {
                            "id": 112668,
                            "name": "Bradford County",
                            "latitude": "29.94996000",
                            "longitude": "-82.16878000"
                        },
                        {
                            "id": 112688,
                            "name": "Brandon",
                            "latitude": "27.93780000",
                            "longitude": "-82.28592000"
                        },
                        {
                            "id": 112726,
                            "name": "Brent",
                            "latitude": "30.46881000",
                            "longitude": "-87.23608000"
                        },
                        {
                            "id": 112738,
                            "name": "Brevard County",
                            "latitude": "28.30031000",
                            "longitude": "-80.70121000"
                        },
                        {
                            "id": 112802,
                            "name": "Bristol",
                            "latitude": "30.43247000",
                            "longitude": "-84.97702000"
                        },
                        {
                            "id": 112828,
                            "name": "Broadview Park",
                            "latitude": "26.09953000",
                            "longitude": "-80.20866000"
                        },
                        {
                            "id": 112844,
                            "name": "Bronson",
                            "latitude": "29.44774000",
                            "longitude": "-82.64233000"
                        },
                        {
                            "id": 112880,
                            "name": "Brookridge",
                            "latitude": "28.55110000",
                            "longitude": "-82.49204000"
                        },
                        {
                            "id": 112890,
                            "name": "Brooksville",
                            "latitude": "28.55554000",
                            "longitude": "-82.38991000"
                        },
                        {
                            "id": 112904,
                            "name": "Broward County",
                            "latitude": "26.15186000",
                            "longitude": "-80.45589000"
                        },
                        {
                            "id": 112905,
                            "name": "Broward Estates",
                            "latitude": "26.12564000",
                            "longitude": "-80.19338000"
                        },
                        {
                            "id": 112928,
                            "name": "Brownsville",
                            "latitude": "25.82176000",
                            "longitude": "-80.24116000"
                        },
                        {
                            "id": 112982,
                            "name": "Buckhead Ridge",
                            "latitude": "27.13033000",
                            "longitude": "-80.89367000"
                        },
                        {
                            "id": 112984,
                            "name": "Buckingham",
                            "latitude": "26.67507000",
                            "longitude": "-81.73203000"
                        },
                        {
                            "id": 113006,
                            "name": "Buenaventura Lakes",
                            "latitude": "28.33584000",
                            "longitude": "-81.35313000"
                        },
                        {
                            "id": 113037,
                            "name": "Bunche Park",
                            "latitude": "25.92065000",
                            "longitude": "-80.23699000"
                        },
                        {
                            "id": 113045,
                            "name": "Bunnell",
                            "latitude": "29.46609000",
                            "longitude": "-81.25784000"
                        },
                        {
                            "id": 113092,
                            "name": "Burnt Store Marina",
                            "latitude": "26.76507000",
                            "longitude": "-82.05092000"
                        },
                        {
                            "id": 113101,
                            "name": "Bushnell",
                            "latitude": "28.66499000",
                            "longitude": "-82.11286000"
                        },
                        {
                            "id": 113113,
                            "name": "Butler Beach",
                            "latitude": "29.79830000",
                            "longitude": "-81.26701000"
                        },
                        {
                            "id": 113202,
                            "name": "Calhoun County",
                            "latitude": "30.40603000",
                            "longitude": "-85.19721000"
                        },
                        {
                            "id": 113222,
                            "name": "Callahan",
                            "latitude": "30.56218000",
                            "longitude": "-81.83066000"
                        },
                        {
                            "id": 113224,
                            "name": "Callaway",
                            "latitude": "30.15298000",
                            "longitude": "-85.56993000"
                        },
                        {
                            "id": 113296,
                            "name": "Campbell",
                            "latitude": "28.25890000",
                            "longitude": "-81.45646000"
                        },
                        {
                            "id": 113352,
                            "name": "Cantonment",
                            "latitude": "30.60853000",
                            "longitude": "-87.33998000"
                        },
                        {
                            "id": 113363,
                            "name": "Cape Canaveral",
                            "latitude": "28.40584000",
                            "longitude": "-80.60477000"
                        },
                        {
                            "id": 113366,
                            "name": "Cape Coral",
                            "latitude": "26.56285000",
                            "longitude": "-81.94953000"
                        },
                        {
                            "id": 113433,
                            "name": "Carol City",
                            "latitude": "25.94065000",
                            "longitude": "-80.24560000"
                        },
                        {
                            "id": 113441,
                            "name": "Carrabelle",
                            "latitude": "29.85326000",
                            "longitude": "-84.66435000"
                        },
                        {
                            "id": 113472,
                            "name": "Carrollwood",
                            "latitude": "28.05002000",
                            "longitude": "-82.49287000"
                        },
                        {
                            "id": 113473,
                            "name": "Carrollwood Village",
                            "latitude": "28.06752000",
                            "longitude": "-82.52093000"
                        },
                        {
                            "id": 113503,
                            "name": "Carver Ranches",
                            "latitude": "25.98842000",
                            "longitude": "-80.19227000"
                        },
                        {
                            "id": 113535,
                            "name": "Casselberry",
                            "latitude": "28.67778000",
                            "longitude": "-81.32785000"
                        },
                        {
                            "id": 113610,
                            "name": "Cedar Grove",
                            "latitude": "30.17103000",
                            "longitude": "-85.62520000"
                        },
                        {
                            "id": 113629,
                            "name": "Celebration",
                            "latitude": "28.32529000",
                            "longitude": "-81.53313000"
                        },
                        {
                            "id": 113643,
                            "name": "Center Hill",
                            "latitude": "28.64999000",
                            "longitude": "-81.99258000"
                        },
                        {
                            "id": 113697,
                            "name": "Century",
                            "latitude": "30.97324000",
                            "longitude": "-87.26386000"
                        },
                        {
                            "id": 113781,
                            "name": "Charlotte County",
                            "latitude": "26.89985000",
                            "longitude": "-81.95031000"
                        },
                        {
                            "id": 113785,
                            "name": "Charlotte Harbor",
                            "latitude": "26.95839000",
                            "longitude": "-82.06703000"
                        },
                        {
                            "id": 113786,
                            "name": "Charlotte Park",
                            "latitude": "26.91006000",
                            "longitude": "-82.05398000"
                        },
                        {
                            "id": 113808,
                            "name": "Chattahoochee",
                            "latitude": "30.70546000",
                            "longitude": "-84.84574000"
                        },
                        {
                            "id": 113918,
                            "name": "Cheval",
                            "latitude": "28.14862000",
                            "longitude": "-82.51454000"
                        },
                        {
                            "id": 113948,
                            "name": "Chiefland",
                            "latitude": "29.47496000",
                            "longitude": "-82.85984000"
                        },
                        {
                            "id": 113972,
                            "name": "Chipley",
                            "latitude": "30.78186000",
                            "longitude": "-85.53854000"
                        },
                        {
                            "id": 114001,
                            "name": "Christmas",
                            "latitude": "28.53639000",
                            "longitude": "-81.01756000"
                        },
                        {
                            "id": 114006,
                            "name": "Chuluota",
                            "latitude": "28.64194000",
                            "longitude": "-81.12340000"
                        },
                        {
                            "id": 114032,
                            "name": "Citra",
                            "latitude": "29.41192000",
                            "longitude": "-82.10982000"
                        },
                        {
                            "id": 114036,
                            "name": "Citrus County",
                            "latitude": "28.84757000",
                            "longitude": "-82.52011000"
                        },
                        {
                            "id": 114038,
                            "name": "Citrus Hills",
                            "latitude": "28.88831000",
                            "longitude": "-82.43260000"
                        },
                        {
                            "id": 114039,
                            "name": "Citrus Park",
                            "latitude": "28.07835000",
                            "longitude": "-82.56982000"
                        },
                        {
                            "id": 114041,
                            "name": "Citrus Ridge",
                            "latitude": "28.33385000",
                            "longitude": "-81.64232000"
                        },
                        {
                            "id": 114042,
                            "name": "Citrus Springs",
                            "latitude": "28.99748000",
                            "longitude": "-82.47065000"
                        },
                        {
                            "id": 114101,
                            "name": "Clarcona",
                            "latitude": "28.61278000",
                            "longitude": "-81.49868000"
                        },
                        {
                            "id": 114175,
                            "name": "Clay County",
                            "latitude": "29.98307000",
                            "longitude": "-81.85789000"
                        },
                        {
                            "id": 114222,
                            "name": "Clearwater",
                            "latitude": "27.96585000",
                            "longitude": "-82.80010000"
                        },
                        {
                            "id": 114238,
                            "name": "Clermont",
                            "latitude": "28.54944000",
                            "longitude": "-81.77285000"
                        },
                        {
                            "id": 114241,
                            "name": "Cleveland",
                            "latitude": "26.96173000",
                            "longitude": "-81.98398000"
                        },
                        {
                            "id": 114252,
                            "name": "Clewiston",
                            "latitude": "26.75423000",
                            "longitude": "-80.93368000"
                        },
                        {
                            "id": 114357,
                            "name": "Cocoa",
                            "latitude": "28.38612000",
                            "longitude": "-80.74200000"
                        },
                        {
                            "id": 114358,
                            "name": "Cocoa Beach",
                            "latitude": "28.32055000",
                            "longitude": "-80.60922000"
                        },
                        {
                            "id": 114359,
                            "name": "Cocoa West",
                            "latitude": "28.35942000",
                            "longitude": "-80.77109000"
                        },
                        {
                            "id": 114361,
                            "name": "Coconut Creek",
                            "latitude": "26.25175000",
                            "longitude": "-80.17894000"
                        },
                        {
                            "id": 114362,
                            "name": "Coconut Grove",
                            "latitude": "25.71260000",
                            "longitude": "-80.25699000"
                        },
                        {
                            "id": 114423,
                            "name": "Collier County",
                            "latitude": "26.09924000",
                            "longitude": "-81.38097000"
                        },
                        {
                            "id": 114478,
                            "name": "Columbia County",
                            "latitude": "30.22424000",
                            "longitude": "-82.62154000"
                        },
                        {
                            "id": 114515,
                            "name": "Combee Settlement",
                            "latitude": "28.05835000",
                            "longitude": "-81.90536000"
                        },
                        {
                            "id": 114565,
                            "name": "Connerton",
                            "latitude": "28.31441000",
                            "longitude": "-82.47539000"
                        },
                        {
                            "id": 114582,
                            "name": "Conway",
                            "latitude": "28.50278000",
                            "longitude": "-81.33062000"
                        },
                        {
                            "id": 114602,
                            "name": "Cooper City",
                            "latitude": "26.05731000",
                            "longitude": "-80.27172000"
                        },
                        {
                            "id": 114624,
                            "name": "Coral Gables",
                            "latitude": "25.72149000",
                            "longitude": "-80.26838000"
                        },
                        {
                            "id": 114626,
                            "name": "Coral Springs",
                            "latitude": "26.27119000",
                            "longitude": "-80.27060000"
                        },
                        {
                            "id": 114627,
                            "name": "Coral Terrace",
                            "latitude": "25.74593000",
                            "longitude": "-80.30450000"
                        },
                        {
                            "id": 114673,
                            "name": "Cortez",
                            "latitude": "27.46921000",
                            "longitude": "-82.68621000"
                        },
                        {
                            "id": 114714,
                            "name": "Country Club",
                            "latitude": "25.94815000",
                            "longitude": "-80.31700000"
                        },
                        {
                            "id": 114724,
                            "name": "Country Walk",
                            "latitude": "25.63399000",
                            "longitude": "-80.43228000"
                        },
                        {
                            "id": 114785,
                            "name": "Crawfordville",
                            "latitude": "30.17604000",
                            "longitude": "-84.37518000"
                        },
                        {
                            "id": 114795,
                            "name": "Crescent City",
                            "latitude": "29.43025000",
                            "longitude": "-81.51063000"
                        },
                        {
                            "id": 114807,
                            "name": "Crestview",
                            "latitude": "30.76213000",
                            "longitude": "-86.57051000"
                        },
                        {
                            "id": 114838,
                            "name": "Crooked Lake Park",
                            "latitude": "27.82919000",
                            "longitude": "-81.58397000"
                        },
                        {
                            "id": 114847,
                            "name": "Cross City",
                            "latitude": "29.63465000",
                            "longitude": "-83.12694000"
                        },
                        {
                            "id": 114878,
                            "name": "Crystal Lake",
                            "latitude": "28.03558000",
                            "longitude": "-81.90841000"
                        },
                        {
                            "id": 114882,
                            "name": "Crystal River",
                            "latitude": "28.90248000",
                            "longitude": "-82.59260000"
                        },
                        {
                            "id": 114883,
                            "name": "Crystal Springs",
                            "latitude": "28.18140000",
                            "longitude": "-82.15758000"
                        },
                        {
                            "id": 114891,
                            "name": "Cudjoe Key",
                            "latitude": "24.67153000",
                            "longitude": "-81.49842000"
                        },
                        {
                            "id": 114946,
                            "name": "Cutler",
                            "latitude": "25.61510000",
                            "longitude": "-80.31061000"
                        },
                        {
                            "id": 114948,
                            "name": "Cutler Bay",
                            "latitude": "25.57830000",
                            "longitude": "-80.33770000"
                        },
                        {
                            "id": 114949,
                            "name": "Cutler Ridge",
                            "latitude": "25.58066000",
                            "longitude": "-80.34672000"
                        },
                        {
                            "id": 114955,
                            "name": "Cypress Gardens",
                            "latitude": "27.99391000",
                            "longitude": "-81.69008000"
                        },
                        {
                            "id": 114957,
                            "name": "Cypress Lake",
                            "latitude": "26.53813000",
                            "longitude": "-81.89925000"
                        },
                        {
                            "id": 114958,
                            "name": "Cypress Quarters",
                            "latitude": "27.25199000",
                            "longitude": "-80.81395000"
                        },
                        {
                            "id": 114964,
                            "name": "Dade City",
                            "latitude": "28.36472000",
                            "longitude": "-82.19592000"
                        },
                        {
                            "id": 114965,
                            "name": "Dade City North",
                            "latitude": "28.38334000",
                            "longitude": "-82.19389000"
                        },
                        {
                            "id": 115021,
                            "name": "Dania Beach",
                            "latitude": "26.05231000",
                            "longitude": "-80.14393000"
                        },
                        {
                            "id": 115058,
                            "name": "Davenport",
                            "latitude": "28.16140000",
                            "longitude": "-81.60174000"
                        },
                        {
                            "id": 115067,
                            "name": "Davie",
                            "latitude": "26.06287000",
                            "longitude": "-80.23310000"
                        },
                        {
                            "id": 115102,
                            "name": "Daytona Beach",
                            "latitude": "29.21081000",
                            "longitude": "-81.02283000"
                        },
                        {
                            "id": 115103,
                            "name": "Daytona Beach Shores",
                            "latitude": "29.17609000",
                            "longitude": "-80.98283000"
                        },
                        {
                            "id": 115108,
                            "name": "De Land Southwest",
                            "latitude": "29.00770000",
                            "longitude": "-81.31129000"
                        },
                        {
                            "id": 115110,
                            "name": "De Leon Springs",
                            "latitude": "29.11989000",
                            "longitude": "-81.35286000"
                        },
                        {
                            "id": 115124,
                            "name": "DeBary",
                            "latitude": "28.88305000",
                            "longitude": "-81.30868000"
                        },
                        {
                            "id": 115184,
                            "name": "Deerfield Beach",
                            "latitude": "26.31841000",
                            "longitude": "-80.09977000"
                        },
                        {
                            "id": 115126,
                            "name": "DeFuniak Springs",
                            "latitude": "30.72102000",
                            "longitude": "-86.11522000"
                        },
                        {
                            "id": 115134,
                            "name": "DeLand",
                            "latitude": "29.02832000",
                            "longitude": "-81.30312000"
                        },
                        {
                            "id": 115221,
                            "name": "Delray Beach",
                            "latitude": "26.46146000",
                            "longitude": "-80.07282000"
                        },
                        {
                            "id": 115229,
                            "name": "Deltona",
                            "latitude": "28.90054000",
                            "longitude": "-81.26367000"
                        },
                        {
                            "id": 115139,
                            "name": "DeSoto County",
                            "latitude": "27.18632000",
                            "longitude": "-81.80930000"
                        },
                        {
                            "id": 115287,
                            "name": "Desoto Lakes",
                            "latitude": "27.37143000",
                            "longitude": "-82.48982000"
                        },
                        {
                            "id": 115289,
                            "name": "Destin",
                            "latitude": "30.39353000",
                            "longitude": "-86.49578000"
                        },
                        {
                            "id": 115361,
                            "name": "Dixie County",
                            "latitude": "29.58124000",
                            "longitude": "-83.18703000"
                        },
                        {
                            "id": 115373,
                            "name": "Doctor Phillips",
                            "latitude": "28.44945000",
                            "longitude": "-81.49229000"
                        },
                        {
                            "id": 115408,
                            "name": "Doral",
                            "latitude": "25.81954000",
                            "longitude": "-80.35533000"
                        },
                        {
                            "id": 115446,
                            "name": "Dover",
                            "latitude": "27.99419000",
                            "longitude": "-82.21953000"
                        },
                        {
                            "id": 115528,
                            "name": "Dundee",
                            "latitude": "28.02252000",
                            "longitude": "-81.61924000"
                        },
                        {
                            "id": 115534,
                            "name": "Dunedin",
                            "latitude": "28.01990000",
                            "longitude": "-82.77323000"
                        },
                        {
                            "id": 115550,
                            "name": "Dunnellon",
                            "latitude": "29.04914000",
                            "longitude": "-82.46093000"
                        },
                        {
                            "id": 115580,
                            "name": "Duval County",
                            "latitude": "30.33544000",
                            "longitude": "-81.64801000"
                        },
                        {
                            "id": 115602,
                            "name": "Eagle Lake",
                            "latitude": "27.97836000",
                            "longitude": "-81.75647000"
                        },
                        {
                            "id": 115638,
                            "name": "East Bronson",
                            "latitude": "29.45928000",
                            "longitude": "-82.59040000"
                        },
                        {
                            "id": 115700,
                            "name": "East Lake",
                            "latitude": "28.11085000",
                            "longitude": "-82.69482000"
                        },
                        {
                            "id": 115701,
                            "name": "East Lake-Orient Park",
                            "latitude": "27.98269000",
                            "longitude": "-82.37878000"
                        },
                        {
                            "id": 115714,
                            "name": "East Milton",
                            "latitude": "30.61519000",
                            "longitude": "-87.02163000"
                        },
                        {
                            "id": 115718,
                            "name": "East Naples",
                            "latitude": "26.13842000",
                            "longitude": "-81.76648000"
                        },
                        {
                            "id": 115727,
                            "name": "East Palatka",
                            "latitude": "29.65830000",
                            "longitude": "-81.59841000"
                        },
                        {
                            "id": 115731,
                            "name": "East Pensacola Heights",
                            "latitude": "30.42881000",
                            "longitude": "-87.17997000"
                        },
                        {
                            "id": 115734,
                            "name": "East Perrine",
                            "latitude": "25.60872000",
                            "longitude": "-80.33894000"
                        },
                        {
                            "id": 115793,
                            "name": "Eastpoint",
                            "latitude": "29.73660000",
                            "longitude": "-84.87852000"
                        },
                        {
                            "id": 115808,
                            "name": "Eatonville",
                            "latitude": "28.61472000",
                            "longitude": "-81.38062000"
                        },
                        {
                            "id": 115851,
                            "name": "Edgewater",
                            "latitude": "28.98888000",
                            "longitude": "-80.90228000"
                        },
                        {
                            "id": 115857,
                            "name": "Edgewood",
                            "latitude": "28.48612000",
                            "longitude": "-81.37229000"
                        },
                        {
                            "id": 115903,
                            "name": "Eglin Air Force Base",
                            "latitude": "30.45907000",
                            "longitude": "-86.55026000"
                        },
                        {
                            "id": 115904,
                            "name": "Eglin Village",
                            "latitude": "30.46298000",
                            "longitude": "-86.53940000"
                        },
                        {
                            "id": 115906,
                            "name": "Egypt Lake-Leto",
                            "latitude": "28.01769000",
                            "longitude": "-82.50619000"
                        },
                        {
                            "id": 115933,
                            "name": "El Portal",
                            "latitude": "25.85537000",
                            "longitude": "-80.19310000"
                        },
                        {
                            "id": 115962,
                            "name": "Elfers",
                            "latitude": "28.21668000",
                            "longitude": "-82.72232000"
                        },
                        {
                            "id": 116019,
                            "name": "Ellenton",
                            "latitude": "27.52171000",
                            "longitude": "-82.52760000"
                        },
                        {
                            "id": 116129,
                            "name": "Englewood",
                            "latitude": "26.96201000",
                            "longitude": "-82.35260000"
                        },
                        {
                            "id": 116147,
                            "name": "Ensley",
                            "latitude": "30.51881000",
                            "longitude": "-87.27275000"
                        },
                        {
                            "id": 116180,
                            "name": "Escambia County",
                            "latitude": "30.61440000",
                            "longitude": "-87.34136000"
                        },
                        {
                            "id": 116207,
                            "name": "Estero",
                            "latitude": "26.43814000",
                            "longitude": "-81.80675000"
                        },
                        {
                            "id": 116241,
                            "name": "Eustis",
                            "latitude": "28.85277000",
                            "longitude": "-81.68535000"
                        },
                        {
                            "id": 116360,
                            "name": "Fairview Shores",
                            "latitude": "28.59111000",
                            "longitude": "-81.39424000"
                        },
                        {
                            "id": 116463,
                            "name": "Feather Sound",
                            "latitude": "27.90079000",
                            "longitude": "-82.67349000"
                        },
                        {
                            "id": 116469,
                            "name": "Fellsmere",
                            "latitude": "27.76781000",
                            "longitude": "-80.60144000"
                        },
                        {
                            "id": 116483,
                            "name": "Fern Park",
                            "latitude": "28.64916000",
                            "longitude": "-81.35118000"
                        },
                        {
                            "id": 116485,
                            "name": "Fernandina Beach",
                            "latitude": "30.66968000",
                            "longitude": "-81.46259000"
                        },
                        {
                            "id": 116499,
                            "name": "Ferry Pass",
                            "latitude": "30.51020000",
                            "longitude": "-87.21247000"
                        },
                        {
                            "id": 116525,
                            "name": "Fish Hawk",
                            "latitude": "27.85058000",
                            "longitude": "-82.21092000"
                        },
                        {
                            "id": 116540,
                            "name": "Five Points",
                            "latitude": "30.20912000",
                            "longitude": "-82.63735000"
                        },
                        {
                            "id": 116542,
                            "name": "Flagami",
                            "latitude": "25.76232000",
                            "longitude": "-80.31616000"
                        },
                        {
                            "id": 116543,
                            "name": "Flagler Beach",
                            "latitude": "29.47498000",
                            "longitude": "-81.12700000"
                        },
                        {
                            "id": 116544,
                            "name": "Flagler County",
                            "latitude": "29.47115000",
                            "longitude": "-81.29299000"
                        },
                        {
                            "id": 116545,
                            "name": "Flagler Estates",
                            "latitude": "29.64553000",
                            "longitude": "-81.45700000"
                        },
                        {
                            "id": 116560,
                            "name": "Fleming Island",
                            "latitude": "30.09330000",
                            "longitude": "-81.71898000"
                        },
                        {
                            "id": 116574,
                            "name": "Floral City",
                            "latitude": "28.74999000",
                            "longitude": "-82.29676000"
                        },
                        {
                            "id": 116593,
                            "name": "Florida City",
                            "latitude": "25.44789000",
                            "longitude": "-80.47922000"
                        },
                        {
                            "id": 116594,
                            "name": "Florida Ridge",
                            "latitude": "27.58031000",
                            "longitude": "-80.38672000"
                        },
                        {
                            "id": 116646,
                            "name": "Forest City",
                            "latitude": "28.66678000",
                            "longitude": "-81.44334000"
                        },
                        {
                            "id": 116722,
                            "name": "Fort Lauderdale",
                            "latitude": "26.12231000",
                            "longitude": "-80.14338000"
                        },
                        {
                            "id": 116728,
                            "name": "Fort Meade",
                            "latitude": "27.75225000",
                            "longitude": "-81.80175000"
                        },
                        {
                            "id": 116734,
                            "name": "Fort Myers",
                            "latitude": "26.62168000",
                            "longitude": "-81.84059000"
                        },
                        {
                            "id": 116735,
                            "name": "Fort Myers Beach",
                            "latitude": "26.45271000",
                            "longitude": "-81.95011000"
                        },
                        {
                            "id": 116736,
                            "name": "Fort Myers Shores",
                            "latitude": "26.70924000",
                            "longitude": "-81.74592000"
                        },
                        {
                            "id": 116739,
                            "name": "Fort Pierce",
                            "latitude": "27.44671000",
                            "longitude": "-80.32561000"
                        },
                        {
                            "id": 116740,
                            "name": "Fort Pierce North",
                            "latitude": "27.47364000",
                            "longitude": "-80.35930000"
                        },
                        {
                            "id": 116741,
                            "name": "Fort Pierce South",
                            "latitude": "27.40962000",
                            "longitude": "-80.35483000"
                        },
                        {
                            "id": 116759,
                            "name": "Fort Walton Beach",
                            "latitude": "30.42059000",
                            "longitude": "-86.61707000"
                        },
                        {
                            "id": 116785,
                            "name": "Fountainebleau",
                            "latitude": "25.77288000",
                            "longitude": "-80.34783000"
                        },
                        {
                            "id": 116791,
                            "name": "Four Corners",
                            "latitude": "28.33287000",
                            "longitude": "-81.64738000"
                        },
                        {
                            "id": 116845,
                            "name": "Franklin County",
                            "latitude": "29.81168000",
                            "longitude": "-84.80046000"
                        },
                        {
                            "id": 116910,
                            "name": "Freeport",
                            "latitude": "30.49825000",
                            "longitude": "-86.13605000"
                        },
                        {
                            "id": 116965,
                            "name": "Frostproof",
                            "latitude": "27.74586000",
                            "longitude": "-81.53063000"
                        },
                        {
                            "id": 116966,
                            "name": "Fruit Cove",
                            "latitude": "30.11107000",
                            "longitude": "-81.64176000"
                        },
                        {
                            "id": 116973,
                            "name": "Fruitland Park",
                            "latitude": "28.86138000",
                            "longitude": "-81.90647000"
                        },
                        {
                            "id": 116977,
                            "name": "Fruitville",
                            "latitude": "27.32977000",
                            "longitude": "-82.45760000"
                        },
                        {
                            "id": 116980,
                            "name": "Fuller Heights",
                            "latitude": "27.90919000",
                            "longitude": "-81.99814000"
                        },
                        {
                            "id": 117002,
                            "name": "Fussels Corner",
                            "latitude": "28.05419000",
                            "longitude": "-81.86064000"
                        },
                        {
                            "id": 117006,
                            "name": "Gadsden County",
                            "latitude": "30.57947000",
                            "longitude": "-84.61360000"
                        },
                        {
                            "id": 117014,
                            "name": "Gainesville",
                            "latitude": "29.65163000",
                            "longitude": "-82.32483000"
                        },
                        {
                            "id": 117049,
                            "name": "Gandy",
                            "latitude": "27.86850000",
                            "longitude": "-82.61612000"
                        },
                        {
                            "id": 117123,
                            "name": "Gateway",
                            "latitude": "26.57757000",
                            "longitude": "-81.75036000"
                        },
                        {
                            "id": 117140,
                            "name": "Geneva",
                            "latitude": "28.73972000",
                            "longitude": "-81.11506000"
                        },
                        {
                            "id": 117191,
                            "name": "Gibsonia",
                            "latitude": "28.11474000",
                            "longitude": "-81.97369000"
                        },
                        {
                            "id": 117193,
                            "name": "Gibsonton",
                            "latitude": "27.85364000",
                            "longitude": "-82.38259000"
                        },
                        {
                            "id": 117197,
                            "name": "Gifford",
                            "latitude": "27.67531000",
                            "longitude": "-80.40922000"
                        },
                        {
                            "id": 117208,
                            "name": "Gilchrist County",
                            "latitude": "29.72582000",
                            "longitude": "-82.80037000"
                        },
                        {
                            "id": 117233,
                            "name": "Glades County",
                            "latitude": "26.95648000",
                            "longitude": "-81.18898000"
                        },
                        {
                            "id": 117234,
                            "name": "Gladeview",
                            "latitude": "25.83926000",
                            "longitude": "-80.23560000"
                        },
                        {
                            "id": 117277,
                            "name": "Glencoe",
                            "latitude": "29.02582000",
                            "longitude": "-80.97200000"
                        },
                        {
                            "id": 117308,
                            "name": "Glenvar Heights",
                            "latitude": "25.70760000",
                            "longitude": "-80.32561000"
                        },
                        {
                            "id": 117348,
                            "name": "Golden Gate",
                            "latitude": "26.18787000",
                            "longitude": "-81.69509000"
                        },
                        {
                            "id": 117349,
                            "name": "Golden Glades",
                            "latitude": "25.91176000",
                            "longitude": "-80.20033000"
                        },
                        {
                            "id": 117361,
                            "name": "Goldenrod",
                            "latitude": "28.61028000",
                            "longitude": "-81.28868000"
                        },
                        {
                            "id": 117374,
                            "name": "Gonzalez",
                            "latitude": "30.58158000",
                            "longitude": "-87.29136000"
                        },
                        {
                            "id": 117414,
                            "name": "Gotha",
                            "latitude": "28.52778000",
                            "longitude": "-81.52313000"
                        },
                        {
                            "id": 117416,
                            "name": "Goulding",
                            "latitude": "30.44298000",
                            "longitude": "-87.22247000"
                        },
                        {
                            "id": 117417,
                            "name": "Goulds",
                            "latitude": "25.56261000",
                            "longitude": "-80.38228000"
                        },
                        {
                            "id": 117425,
                            "name": "Graceville",
                            "latitude": "30.95685000",
                            "longitude": "-85.51660000"
                        },
                        {
                            "id": 117524,
                            "name": "Grant-Valkaria",
                            "latitude": "27.93980000",
                            "longitude": "-80.57104000"
                        },
                        {
                            "id": 117581,
                            "name": "Greater Northdale",
                            "latitude": "28.10545000",
                            "longitude": "-82.52594000"
                        },
                        {
                            "id": 117594,
                            "name": "Green Cove Springs",
                            "latitude": "29.99191000",
                            "longitude": "-81.67815000"
                        },
                        {
                            "id": 117614,
                            "name": "Greenacres City",
                            "latitude": "26.62368000",
                            "longitude": "-80.12532000"
                        },
                        {
                            "id": 117618,
                            "name": "Greenbriar",
                            "latitude": "28.01128000",
                            "longitude": "-82.75272000"
                        },
                        {
                            "id": 117724,
                            "name": "Gretna",
                            "latitude": "30.61714000",
                            "longitude": "-84.65991000"
                        },
                        {
                            "id": 117755,
                            "name": "Grove City",
                            "latitude": "26.91423000",
                            "longitude": "-82.32704000"
                        },
                        {
                            "id": 117758,
                            "name": "Groveland",
                            "latitude": "28.55805000",
                            "longitude": "-81.85119000"
                        },
                        {
                            "id": 117790,
                            "name": "Gulf Breeze",
                            "latitude": "30.35714000",
                            "longitude": "-87.16386000"
                        },
                        {
                            "id": 117791,
                            "name": "Gulf County",
                            "latitude": "29.90862000",
                            "longitude": "-85.26101000"
                        },
                        {
                            "id": 117792,
                            "name": "Gulf Gate Estates",
                            "latitude": "27.25173000",
                            "longitude": "-82.51471000"
                        },
                        {
                            "id": 117796,
                            "name": "Gulfport",
                            "latitude": "27.74836000",
                            "longitude": "-82.70343000"
                        },
                        {
                            "id": 117845,
                            "name": "Haines City",
                            "latitude": "28.11450000",
                            "longitude": "-81.62009000"
                        },
                        {
                            "id": 117869,
                            "name": "Hallandale Beach",
                            "latitude": "25.98120000",
                            "longitude": "-80.14838000"
                        },
                        {
                            "id": 117896,
                            "name": "Hamilton County",
                            "latitude": "30.49643000",
                            "longitude": "-82.94796000"
                        },
                        {
                            "id": 117989,
                            "name": "Harbor Bluffs",
                            "latitude": "27.90947000",
                            "longitude": "-82.82760000"
                        },
                        {
                            "id": 117992,
                            "name": "Harbour Heights",
                            "latitude": "26.99089000",
                            "longitude": "-82.00231000"
                        },
                        {
                            "id": 117993,
                            "name": "Hardee County",
                            "latitude": "27.49270000",
                            "longitude": "-81.80993000"
                        },
                        {
                            "id": 118021,
                            "name": "Harlem",
                            "latitude": "26.73757000",
                            "longitude": "-80.95090000"
                        },
                        {
                            "id": 118024,
                            "name": "Harlem Heights",
                            "latitude": "26.51619000",
                            "longitude": "-81.92787000"
                        },
                        {
                            "id": 118145,
                            "name": "Havana",
                            "latitude": "30.62381000",
                            "longitude": "-84.41463000"
                        },
                        {
                            "id": 118149,
                            "name": "Haverhill",
                            "latitude": "26.69118000",
                            "longitude": "-80.12004000"
                        },
                        {
                            "id": 118172,
                            "name": "Hawthorne",
                            "latitude": "29.59191000",
                            "longitude": "-82.08732000"
                        },
                        {
                            "id": 118224,
                            "name": "Heathrow",
                            "latitude": "28.76333000",
                            "longitude": "-81.37225000"
                        },
                        {
                            "id": 118289,
                            "name": "Hendry County",
                            "latitude": "26.55349000",
                            "longitude": "-81.16590000"
                        },
                        {
                            "id": 118321,
                            "name": "Heritage Pines",
                            "latitude": "28.42522000",
                            "longitude": "-82.62111000"
                        },
                        {
                            "id": 118332,
                            "name": "Hernando",
                            "latitude": "28.89998000",
                            "longitude": "-82.37454000"
                        },
                        {
                            "id": 118334,
                            "name": "Hernando Beach",
                            "latitude": "28.46944000",
                            "longitude": "-82.65927000"
                        },
                        {
                            "id": 118335,
                            "name": "Hernando County",
                            "latitude": "28.55617000",
                            "longitude": "-82.46849000"
                        },
                        {
                            "id": 118354,
                            "name": "Hialeah",
                            "latitude": "25.85760000",
                            "longitude": "-80.27811000"
                        },
                        {
                            "id": 118355,
                            "name": "Hialeah Gardens",
                            "latitude": "25.86510000",
                            "longitude": "-80.32450000"
                        },
                        {
                            "id": 118387,
                            "name": "High Point",
                            "latitude": "28.54687000",
                            "longitude": "-82.52468000"
                        },
                        {
                            "id": 118389,
                            "name": "High Springs",
                            "latitude": "29.82691000",
                            "longitude": "-82.59678000"
                        },
                        {
                            "id": 118402,
                            "name": "Highland Beach",
                            "latitude": "26.39952000",
                            "longitude": "-80.06560000"
                        },
                        {
                            "id": 118403,
                            "name": "Highland City",
                            "latitude": "27.96530000",
                            "longitude": "-81.87786000"
                        },
                        {
                            "id": 118419,
                            "name": "Highlands County",
                            "latitude": "27.34340000",
                            "longitude": "-81.34097000"
                        },
                        {
                            "id": 118427,
                            "name": "Hiland Park",
                            "latitude": "30.20103000",
                            "longitude": "-85.62687000"
                        },
                        {
                            "id": 118432,
                            "name": "Hill 'n Dale",
                            "latitude": "28.51972000",
                            "longitude": "-82.29926000"
                        },
                        {
                            "id": 118444,
                            "name": "Hilliard",
                            "latitude": "30.69107000",
                            "longitude": "-81.91733000"
                        },
                        {
                            "id": 118453,
                            "name": "Hillsboro Beach",
                            "latitude": "26.29397000",
                            "longitude": "-80.07893000"
                        },
                        {
                            "id": 118457,
                            "name": "Hillsborough County",
                            "latitude": "27.90623000",
                            "longitude": "-82.34692000"
                        },
                        {
                            "id": 118501,
                            "name": "Hobe Sound",
                            "latitude": "27.05950000",
                            "longitude": "-80.13643000"
                        },
                        {
                            "id": 118525,
                            "name": "Holden Heights",
                            "latitude": "28.49667000",
                            "longitude": "-81.38785000"
                        },
                        {
                            "id": 118529,
                            "name": "Holiday",
                            "latitude": "28.18779000",
                            "longitude": "-82.73955000"
                        },
                        {
                            "id": 118542,
                            "name": "Holley",
                            "latitude": "30.44686000",
                            "longitude": "-86.90691000"
                        },
                        {
                            "id": 118556,
                            "name": "Holly Hill",
                            "latitude": "29.24359000",
                            "longitude": "-81.03756000"
                        },
                        {
                            "id": 118565,
                            "name": "Hollywood",
                            "latitude": "26.01120000",
                            "longitude": "-80.14949000"
                        },
                        {
                            "id": 118570,
                            "name": "Holmes Beach",
                            "latitude": "27.49532000",
                            "longitude": "-82.71093000"
                        },
                        {
                            "id": 118571,
                            "name": "Holmes County",
                            "latitude": "30.86789000",
                            "longitude": "-85.81410000"
                        },
                        {
                            "id": 118601,
                            "name": "Homestead",
                            "latitude": "25.46872000",
                            "longitude": "-80.47756000"
                        },
                        {
                            "id": 118610,
                            "name": "Homosassa",
                            "latitude": "28.78137000",
                            "longitude": "-82.61510000"
                        },
                        {
                            "id": 118611,
                            "name": "Homosassa Springs",
                            "latitude": "28.80359000",
                            "longitude": "-82.57593000"
                        },
                        {
                            "id": 118665,
                            "name": "Horizon West",
                            "latitude": "28.43383000",
                            "longitude": "-81.62270000"
                        },
                        {
                            "id": 118724,
                            "name": "Howey-in-the-Hills",
                            "latitude": "28.71694000",
                            "longitude": "-81.77341000"
                        },
                        {
                            "id": 118735,
                            "name": "Hudson",
                            "latitude": "28.36445000",
                            "longitude": "-82.69343000"
                        },
                        {
                            "id": 118790,
                            "name": "Hunters Creek",
                            "latitude": "28.36056000",
                            "longitude": "-81.42229000"
                        },
                        {
                            "id": 118839,
                            "name": "Hutchinson Island South",
                            "latitude": "27.29949000",
                            "longitude": "-80.22045000"
                        },
                        {
                            "id": 118856,
                            "name": "Hypoluxo",
                            "latitude": "26.56646000",
                            "longitude": "-80.05337000"
                        },
                        {
                            "id": 118880,
                            "name": "Immokalee",
                            "latitude": "26.41869000",
                            "longitude": "-81.41730000"
                        },
                        {
                            "id": 118901,
                            "name": "Indialantic",
                            "latitude": "28.08946000",
                            "longitude": "-80.56561000"
                        },
                        {
                            "id": 118902,
                            "name": "Indian Harbour Beach",
                            "latitude": "28.14890000",
                            "longitude": "-80.58839000"
                        },
                        {
                            "id": 118913,
                            "name": "Indian River County",
                            "latitude": "27.69639000",
                            "longitude": "-80.57409000"
                        },
                        {
                            "id": 118914,
                            "name": "Indian River Estates",
                            "latitude": "27.36449000",
                            "longitude": "-80.30977000"
                        },
                        {
                            "id": 118915,
                            "name": "Indian River Shores",
                            "latitude": "27.71670000",
                            "longitude": "-80.38422000"
                        },
                        {
                            "id": 118916,
                            "name": "Indian Rocks Beach",
                            "latitude": "27.87530000",
                            "longitude": "-82.85122000"
                        },
                        {
                            "id": 118917,
                            "name": "Indian Shores",
                            "latitude": "27.86280000",
                            "longitude": "-82.84844000"
                        },
                        {
                            "id": 118928,
                            "name": "Indiantown",
                            "latitude": "27.02728000",
                            "longitude": "-80.48561000"
                        },
                        {
                            "id": 118939,
                            "name": "Inglis",
                            "latitude": "29.03025000",
                            "longitude": "-82.66872000"
                        },
                        {
                            "id": 118950,
                            "name": "Interlachen",
                            "latitude": "29.62421000",
                            "longitude": "-81.89256000"
                        },
                        {
                            "id": 118955,
                            "name": "Inverness",
                            "latitude": "28.83582000",
                            "longitude": "-82.33037000"
                        },
                        {
                            "id": 118959,
                            "name": "Inverness Highlands North",
                            "latitude": "28.86420000",
                            "longitude": "-82.37688000"
                        },
                        {
                            "id": 118960,
                            "name": "Inverness Highlands South",
                            "latitude": "28.80055000",
                            "longitude": "-82.33710000"
                        },
                        {
                            "id": 118961,
                            "name": "Inwood",
                            "latitude": "28.03697000",
                            "longitude": "-81.76508000"
                        },
                        {
                            "id": 118968,
                            "name": "Iona",
                            "latitude": "26.52036000",
                            "longitude": "-81.96398000"
                        },
                        {
                            "id": 119022,
                            "name": "Islamorada",
                            "latitude": "24.92430000",
                            "longitude": "-80.62784000"
                        },
                        {
                            "id": 119028,
                            "name": "Island Walk",
                            "latitude": "26.25099000",
                            "longitude": "-81.71101000"
                        },
                        {
                            "id": 119031,
                            "name": "Isle of Normandy",
                            "latitude": "25.85287000",
                            "longitude": "-80.13505000"
                        },
                        {
                            "id": 119050,
                            "name": "Ives Estates",
                            "latitude": "25.96231000",
                            "longitude": "-80.17671000"
                        },
                        {
                            "id": 119077,
                            "name": "Jackson County",
                            "latitude": "30.79539000",
                            "longitude": "-85.21546000"
                        },
                        {
                            "id": 119101,
                            "name": "Jacksonville",
                            "latitude": "30.33218000",
                            "longitude": "-81.65565000"
                        },
                        {
                            "id": 119106,
                            "name": "Jacksonville Beach",
                            "latitude": "30.29469000",
                            "longitude": "-81.39314000"
                        },
                        {
                            "id": 119129,
                            "name": "Jan-Phyl Village",
                            "latitude": "28.01474000",
                            "longitude": "-81.77175000"
                        },
                        {
                            "id": 119136,
                            "name": "Jasmine Estates",
                            "latitude": "28.29306000",
                            "longitude": "-82.69010000"
                        },
                        {
                            "id": 119140,
                            "name": "Jasper",
                            "latitude": "30.51827000",
                            "longitude": "-82.94819000"
                        },
                        {
                            "id": 119177,
                            "name": "Jefferson County",
                            "latitude": "30.42346000",
                            "longitude": "-83.90047000"
                        },
                        {
                            "id": 119222,
                            "name": "Jensen Beach",
                            "latitude": "27.25449000",
                            "longitude": "-80.22977000"
                        },
                        {
                            "id": 119336,
                            "name": "June Park",
                            "latitude": "28.07224000",
                            "longitude": "-80.68006000"
                        },
                        {
                            "id": 119341,
                            "name": "Juno Beach",
                            "latitude": "26.87978000",
                            "longitude": "-80.05337000"
                        },
                        {
                            "id": 119342,
                            "name": "Jupiter",
                            "latitude": "26.93422000",
                            "longitude": "-80.09421000"
                        },
                        {
                            "id": 119393,
                            "name": "Kathleen",
                            "latitude": "28.12085000",
                            "longitude": "-82.02314000"
                        },
                        {
                            "id": 119444,
                            "name": "Kendale Lakes",
                            "latitude": "25.70816000",
                            "longitude": "-80.40700000"
                        },
                        {
                            "id": 119445,
                            "name": "Kendall",
                            "latitude": "25.67927000",
                            "longitude": "-80.31727000"
                        },
                        {
                            "id": 119448,
                            "name": "Kendall Green",
                            "latitude": "26.25397000",
                            "longitude": "-80.12393000"
                        },
                        {
                            "id": 119450,
                            "name": "Kendall West",
                            "latitude": "25.70650000",
                            "longitude": "-80.43880000"
                        },
                        {
                            "id": 119472,
                            "name": "Kenneth City",
                            "latitude": "27.81558000",
                            "longitude": "-82.72010000"
                        },
                        {
                            "id": 119485,
                            "name": "Kensington Park",
                            "latitude": "27.35949000",
                            "longitude": "-82.49649000"
                        },
                        {
                            "id": 119537,
                            "name": "Key Biscayne",
                            "latitude": "25.69371000",
                            "longitude": "-80.16282000"
                        },
                        {
                            "id": 119539,
                            "name": "Key Largo",
                            "latitude": "25.08652000",
                            "longitude": "-80.44728000"
                        },
                        {
                            "id": 119540,
                            "name": "Key Vista",
                            "latitude": "28.19470000",
                            "longitude": "-82.77038000"
                        },
                        {
                            "id": 119541,
                            "name": "Key West",
                            "latitude": "24.55524000",
                            "longitude": "-81.78163000"
                        },
                        {
                            "id": 119546,
                            "name": "Keystone",
                            "latitude": "28.15585000",
                            "longitude": "-82.62121000"
                        },
                        {
                            "id": 119548,
                            "name": "Keystone Heights",
                            "latitude": "29.78608000",
                            "longitude": "-82.03149000"
                        },
                        {
                            "id": 119607,
                            "name": "Kings Point",
                            "latitude": "26.44535000",
                            "longitude": "-80.13977000"
                        },
                        {
                            "id": 119658,
                            "name": "Kissimmee",
                            "latitude": "28.30468000",
                            "longitude": "-81.41667000"
                        },
                        {
                            "id": 119798,
                            "name": "LaBelle",
                            "latitude": "26.76173000",
                            "longitude": "-81.43841000"
                        },
                        {
                            "id": 119820,
                            "name": "Lacoochee",
                            "latitude": "28.46583000",
                            "longitude": "-82.17203000"
                        },
                        {
                            "id": 119829,
                            "name": "Lady Lake",
                            "latitude": "28.91749000",
                            "longitude": "-81.92286000"
                        },
                        {
                            "id": 119839,
                            "name": "Lafayette County",
                            "latitude": "29.98552000",
                            "longitude": "-83.18107000"
                        },
                        {
                            "id": 119851,
                            "name": "Laguna Beach",
                            "latitude": "30.23965000",
                            "longitude": "-85.92410000"
                        },
                        {
                            "id": 119862,
                            "name": "Lake Alfred",
                            "latitude": "28.09196000",
                            "longitude": "-81.72341000"
                        },
                        {
                            "id": 119870,
                            "name": "Lake Belvedere Estates",
                            "latitude": "26.68923000",
                            "longitude": "-80.13338000"
                        },
                        {
                            "id": 119873,
                            "name": "Lake Butler",
                            "latitude": "30.02274000",
                            "longitude": "-82.33956000"
                        },
                        {
                            "id": 119879,
                            "name": "Lake City",
                            "latitude": "30.18968000",
                            "longitude": "-82.63929000"
                        },
                        {
                            "id": 119887,
                            "name": "Lake Clarke Shores",
                            "latitude": "26.64534000",
                            "longitude": "-80.07588000"
                        },
                        {
                            "id": 119889,
                            "name": "Lake County",
                            "latitude": "28.76147000",
                            "longitude": "-81.71130000"
                        },
                        {
                            "id": 119909,
                            "name": "Lake Forest",
                            "latitude": "25.97759000",
                            "longitude": "-80.18310000"
                        },
                        {
                            "id": 119917,
                            "name": "Lake Hamilton",
                            "latitude": "28.04446000",
                            "longitude": "-81.62785000"
                        },
                        {
                            "id": 119919,
                            "name": "Lake Helen",
                            "latitude": "28.98082000",
                            "longitude": "-81.23339000"
                        },
                        {
                            "id": 119930,
                            "name": "Lake Lorraine",
                            "latitude": "30.44159000",
                            "longitude": "-86.56523000"
                        },
                        {
                            "id": 119933,
                            "name": "Lake Lucerne",
                            "latitude": "25.96509000",
                            "longitude": "-80.24144000"
                        },
                        {
                            "id": 119936,
                            "name": "Lake Mack-Forest Hills",
                            "latitude": "29.00074000",
                            "longitude": "-81.42397000"
                        },
                        {
                            "id": 119937,
                            "name": "Lake Magdalene",
                            "latitude": "28.07418000",
                            "longitude": "-82.47176000"
                        },
                        {
                            "id": 119939,
                            "name": "Lake Mary",
                            "latitude": "28.75888000",
                            "longitude": "-81.31784000"
                        },
                        {
                            "id": 119957,
                            "name": "Lake Panasoffkee",
                            "latitude": "28.75582000",
                            "longitude": "-82.09481000"
                        },
                        {
                            "id": 119959,
                            "name": "Lake Park",
                            "latitude": "26.80034000",
                            "longitude": "-80.06643000"
                        },
                        {
                            "id": 119962,
                            "name": "Lake Placid",
                            "latitude": "27.35197000",
                            "longitude": "-81.32631000"
                        },
                        {
                            "id": 119974,
                            "name": "Lake Sarasota",
                            "latitude": "27.29255000",
                            "longitude": "-82.43760000"
                        },
                        {
                            "id": 119991,
                            "name": "Lake Wales",
                            "latitude": "27.90141000",
                            "longitude": "-81.58591000"
                        },
                        {
                            "id": 119997,
                            "name": "Lake Worth",
                            "latitude": "26.61708000",
                            "longitude": "-80.07231000"
                        },
                        {
                            "id": 119999,
                            "name": "Lake Worth Corridor",
                            "latitude": "26.61649000",
                            "longitude": "-80.10102000"
                        },
                        {
                            "id": 120012,
                            "name": "Lakeland",
                            "latitude": "28.03947000",
                            "longitude": "-81.94980000"
                        },
                        {
                            "id": 120017,
                            "name": "Lakeland Highlands",
                            "latitude": "27.95975000",
                            "longitude": "-81.95008000"
                        },
                        {
                            "id": 120025,
                            "name": "Lakes by the Bay",
                            "latitude": "25.57233000",
                            "longitude": "-80.32533000"
                        },
                        {
                            "id": 120029,
                            "name": "Lakeside",
                            "latitude": "30.12996000",
                            "longitude": "-81.76815000"
                        },
                        {
                            "id": 120054,
                            "name": "Lakewood Park",
                            "latitude": "27.54309000",
                            "longitude": "-80.40227000"
                        },
                        {
                            "id": 120095,
                            "name": "Land O' Lakes",
                            "latitude": "28.21890000",
                            "longitude": "-82.45759000"
                        },
                        {
                            "id": 120129,
                            "name": "Lantana",
                            "latitude": "26.58674000",
                            "longitude": "-80.05199000"
                        },
                        {
                            "id": 120143,
                            "name": "Largo",
                            "latitude": "27.90979000",
                            "longitude": "-82.78842000"
                        },
                        {
                            "id": 120182,
                            "name": "Lauderdale Lakes",
                            "latitude": "26.16647000",
                            "longitude": "-80.20838000"
                        },
                        {
                            "id": 120183,
                            "name": "Lauderdale-by-the-Sea",
                            "latitude": "26.19203000",
                            "longitude": "-80.09643000"
                        },
                        {
                            "id": 120184,
                            "name": "Lauderhill",
                            "latitude": "26.14036000",
                            "longitude": "-80.21338000"
                        },
                        {
                            "id": 120188,
                            "name": "Laurel",
                            "latitude": "27.14378000",
                            "longitude": "-82.46158000"
                        },
                        {
                            "id": 120278,
                            "name": "Lealman",
                            "latitude": "27.82114000",
                            "longitude": "-82.67927000"
                        },
                        {
                            "id": 120299,
                            "name": "Lecanto",
                            "latitude": "28.85165000",
                            "longitude": "-82.48760000"
                        },
                        {
                            "id": 120308,
                            "name": "Lee County",
                            "latitude": "26.62536000",
                            "longitude": "-81.84952000"
                        },
                        {
                            "id": 120324,
                            "name": "Leesburg",
                            "latitude": "28.81082000",
                            "longitude": "-81.87786000"
                        },
                        {
                            "id": 120334,
                            "name": "Lehigh Acres",
                            "latitude": "26.62535000",
                            "longitude": "-81.62480000"
                        },
                        {
                            "id": 120340,
                            "name": "Leisure City",
                            "latitude": "25.49539000",
                            "longitude": "-80.42922000"
                        },
                        {
                            "id": 120353,
                            "name": "Lely",
                            "latitude": "26.10065000",
                            "longitude": "-81.72842000"
                        },
                        {
                            "id": 120354,
                            "name": "Lely Resort",
                            "latitude": "26.08093000",
                            "longitude": "-81.69786000"
                        },
                        {
                            "id": 120384,
                            "name": "Leon County",
                            "latitude": "30.45804000",
                            "longitude": "-84.27788000"
                        },
                        {
                            "id": 120409,
                            "name": "Levy County",
                            "latitude": "29.28221000",
                            "longitude": "-82.78861000"
                        },
                        {
                            "id": 120470,
                            "name": "Liberty County",
                            "latitude": "30.24136000",
                            "longitude": "-84.88291000"
                        },
                        {
                            "id": 120480,
                            "name": "Lighthouse Point",
                            "latitude": "26.27564000",
                            "longitude": "-80.08727000"
                        },
                        {
                            "id": 120496,
                            "name": "Limestone Creek",
                            "latitude": "26.94284000",
                            "longitude": "-80.14115000"
                        },
                        {
                            "id": 120638,
                            "name": "Live Oak",
                            "latitude": "30.29495000",
                            "longitude": "-82.98402000"
                        },
                        {
                            "id": 120672,
                            "name": "Lochmoor Waterway Estates",
                            "latitude": "26.64424000",
                            "longitude": "-81.90981000"
                        },
                        {
                            "id": 120676,
                            "name": "Lockhart",
                            "latitude": "28.61944000",
                            "longitude": "-81.44257000"
                        },
                        {
                            "id": 120751,
                            "name": "Longboat Key",
                            "latitude": "27.41254000",
                            "longitude": "-82.65899000"
                        },
                        {
                            "id": 120760,
                            "name": "Longwood",
                            "latitude": "28.70305000",
                            "longitude": "-81.33840000"
                        },
                        {
                            "id": 120803,
                            "name": "Loughman",
                            "latitude": "28.24196000",
                            "longitude": "-81.56674000"
                        },
                        {
                            "id": 120841,
                            "name": "Lower Grand Lagoon",
                            "latitude": "30.14409000",
                            "longitude": "-85.75076000"
                        },
                        {
                            "id": 120851,
                            "name": "Loxahatchee Groves",
                            "latitude": "26.68368000",
                            "longitude": "-80.27977000"
                        },
                        {
                            "id": 120898,
                            "name": "Lutz",
                            "latitude": "28.15112000",
                            "longitude": "-82.46148000"
                        },
                        {
                            "id": 120929,
                            "name": "Lynn Haven",
                            "latitude": "30.24548000",
                            "longitude": "-85.64826000"
                        },
                        {
                            "id": 120953,
                            "name": "Macclenny",
                            "latitude": "30.28218000",
                            "longitude": "-82.12206000"
                        },
                        {
                            "id": 120978,
                            "name": "Madeira Beach",
                            "latitude": "27.79808000",
                            "longitude": "-82.79732000"
                        },
                        {
                            "id": 120985,
                            "name": "Madison",
                            "latitude": "30.46938000",
                            "longitude": "-83.41293000"
                        },
                        {
                            "id": 121004,
                            "name": "Madison County",
                            "latitude": "30.44414000",
                            "longitude": "-83.47012000"
                        },
                        {
                            "id": 121051,
                            "name": "Maitland",
                            "latitude": "28.62778000",
                            "longitude": "-81.36312000"
                        },
                        {
                            "id": 121056,
                            "name": "Malabar",
                            "latitude": "28.00363000",
                            "longitude": "-80.56561000"
                        },
                        {
                            "id": 121065,
                            "name": "Malone",
                            "latitude": "30.95769000",
                            "longitude": "-85.16215000"
                        },
                        {
                            "id": 121080,
                            "name": "Manasota Key",
                            "latitude": "26.92534000",
                            "longitude": "-82.35204000"
                        },
                        {
                            "id": 121084,
                            "name": "Manatee County",
                            "latitude": "27.47752000",
                            "longitude": "-82.35754000"
                        },
                        {
                            "id": 121085,
                            "name": "Manatee Road",
                            "latitude": "29.51302000",
                            "longitude": "-82.91429000"
                        },
                        {
                            "id": 121108,
                            "name": "Mango",
                            "latitude": "27.97974000",
                            "longitude": "-82.30648000"
                        },
                        {
                            "id": 121109,
                            "name": "Mangonia Park",
                            "latitude": "26.76034000",
                            "longitude": "-80.07365000"
                        },
                        {
                            "id": 121184,
                            "name": "Marathon",
                            "latitude": "24.71375000",
                            "longitude": "-81.09035000"
                        },
                        {
                            "id": 121197,
                            "name": "Marco",
                            "latitude": "25.97260000",
                            "longitude": "-81.72898000"
                        },
                        {
                            "id": 121198,
                            "name": "Marco Island",
                            "latitude": "25.94121000",
                            "longitude": "-81.71842000"
                        },
                        {
                            "id": 121206,
                            "name": "Margate",
                            "latitude": "26.24453000",
                            "longitude": "-80.20644000"
                        },
                        {
                            "id": 121209,
                            "name": "Marianna",
                            "latitude": "30.77436000",
                            "longitude": "-85.22687000"
                        },
                        {
                            "id": 121249,
                            "name": "Marion County",
                            "latitude": "29.21020000",
                            "longitude": "-82.05668000"
                        },
                        {
                            "id": 121337,
                            "name": "Martin County",
                            "latitude": "27.08157000",
                            "longitude": "-80.39851000"
                        },
                        {
                            "id": 121354,
                            "name": "Mary Esther",
                            "latitude": "30.41015000",
                            "longitude": "-86.66509000"
                        },
                        {
                            "id": 121366,
                            "name": "Masaryktown",
                            "latitude": "28.44167000",
                            "longitude": "-82.45704000"
                        },
                        {
                            "id": 121368,
                            "name": "Mascotte",
                            "latitude": "28.57833000",
                            "longitude": "-81.88675000"
                        },
                        {
                            "id": 121442,
                            "name": "Mayo",
                            "latitude": "30.05300000",
                            "longitude": "-83.17486000"
                        },
                        {
                            "id": 121505,
                            "name": "McGregor",
                            "latitude": "26.56091000",
                            "longitude": "-81.91453000"
                        },
                        {
                            "id": 121562,
                            "name": "Meadow Oaks",
                            "latitude": "28.34619000",
                            "longitude": "-82.60284000"
                        },
                        {
                            "id": 121564,
                            "name": "Meadow Woods",
                            "latitude": "28.38556000",
                            "longitude": "-81.36646000"
                        },
                        {
                            "id": 121609,
                            "name": "Medulla",
                            "latitude": "27.96780000",
                            "longitude": "-81.97342000"
                        },
                        {
                            "id": 121620,
                            "name": "Melbourne",
                            "latitude": "28.08363000",
                            "longitude": "-80.60811000"
                        },
                        {
                            "id": 121622,
                            "name": "Melbourne Beach",
                            "latitude": "28.06835000",
                            "longitude": "-80.56033000"
                        },
                        {
                            "id": 121631,
                            "name": "Melrose Park",
                            "latitude": "26.11342000",
                            "longitude": "-80.19338000"
                        },
                        {
                            "id": 121638,
                            "name": "Memphis",
                            "latitude": "27.53587000",
                            "longitude": "-82.56121000"
                        },
                        {
                            "id": 121714,
                            "name": "Merritt Island",
                            "latitude": "28.53917000",
                            "longitude": "-80.67200000"
                        },
                        {
                            "id": 121744,
                            "name": "Mexico Beach",
                            "latitude": "29.94809000",
                            "longitude": "-85.41995000"
                        },
                        {
                            "id": 121746,
                            "name": "Miami",
                            "latitude": "25.77427000",
                            "longitude": "-80.19366000"
                        },
                        {
                            "id": 121750,
                            "name": "Miami Beach",
                            "latitude": "25.79065000",
                            "longitude": "-80.13005000"
                        },
                        {
                            "id": 121753,
                            "name": "Miami Gardens",
                            "latitude": "25.94204000",
                            "longitude": "-80.24560000"
                        },
                        {
                            "id": 121754,
                            "name": "Miami Lakes",
                            "latitude": "25.90871000",
                            "longitude": "-80.30866000"
                        },
                        {
                            "id": 121755,
                            "name": "Miami Shores",
                            "latitude": "25.86315000",
                            "longitude": "-80.19283000"
                        },
                        {
                            "id": 121756,
                            "name": "Miami Springs",
                            "latitude": "25.82232000",
                            "longitude": "-80.28950000"
                        },
                        {
                            "id": 121757,
                            "name": "Miami-Dade County",
                            "latitude": "25.60897000",
                            "longitude": "-80.49867000"
                        },
                        {
                            "id": 121758,
                            "name": "Micco",
                            "latitude": "27.88058000",
                            "longitude": "-80.50033000"
                        },
                        {
                            "id": 121768,
                            "name": "Middleburg",
                            "latitude": "30.06885000",
                            "longitude": "-81.86038000"
                        },
                        {
                            "id": 121820,
                            "name": "Midway",
                            "latitude": "30.40648000",
                            "longitude": "-87.00553000"
                        },
                        {
                            "id": 121912,
                            "name": "Milton",
                            "latitude": "30.63241000",
                            "longitude": "-87.03969000"
                        },
                        {
                            "id": 121926,
                            "name": "Mims",
                            "latitude": "28.66527000",
                            "longitude": "-80.84478000"
                        },
                        {
                            "id": 121954,
                            "name": "Minneola",
                            "latitude": "28.57444000",
                            "longitude": "-81.74619000"
                        },
                        {
                            "id": 121973,
                            "name": "Miramar",
                            "latitude": "25.98731000",
                            "longitude": "-80.23227000"
                        },
                        {
                            "id": 121974,
                            "name": "Miramar Beach",
                            "latitude": "30.37437000",
                            "longitude": "-86.35856000"
                        },
                        {
                            "id": 122029,
                            "name": "Molino",
                            "latitude": "30.72408000",
                            "longitude": "-87.31414000"
                        },
                        {
                            "id": 122069,
                            "name": "Monroe County",
                            "latitude": "25.55731000",
                            "longitude": "-80.91705000"
                        },
                        {
                            "id": 122159,
                            "name": "Monticello",
                            "latitude": "30.54515000",
                            "longitude": "-83.87130000"
                        },
                        {
                            "id": 122188,
                            "name": "Montverde",
                            "latitude": "28.60028000",
                            "longitude": "-81.67396000"
                        },
                        {
                            "id": 122204,
                            "name": "Moore Haven",
                            "latitude": "26.83312000",
                            "longitude": "-81.09312000"
                        },
                        {
                            "id": 122341,
                            "name": "Mount Dora",
                            "latitude": "28.80249000",
                            "longitude": "-81.64452000"
                        },
                        {
                            "id": 122383,
                            "name": "Mount Plymouth",
                            "latitude": "28.80805000",
                            "longitude": "-81.53313000"
                        },
                        {
                            "id": 122453,
                            "name": "Mulberry",
                            "latitude": "27.89530000",
                            "longitude": "-81.97342000"
                        },
                        {
                            "id": 122522,
                            "name": "Myrtle Grove",
                            "latitude": "30.42103000",
                            "longitude": "-87.30747000"
                        },
                        {
                            "id": 122552,
                            "name": "Naples",
                            "latitude": "26.14234000",
                            "longitude": "-81.79596000"
                        },
                        {
                            "id": 122556,
                            "name": "Naples Manor",
                            "latitude": "26.08870000",
                            "longitude": "-81.72620000"
                        },
                        {
                            "id": 122557,
                            "name": "Naples Park",
                            "latitude": "26.26175000",
                            "longitude": "-81.80925000"
                        },
                        {
                            "id": 122562,
                            "name": "Naranja",
                            "latitude": "25.51816000",
                            "longitude": "-80.42283000"
                        },
                        {
                            "id": 122582,
                            "name": "Nassau County",
                            "latitude": "30.61058000",
                            "longitude": "-81.77142000"
                        },
                        {
                            "id": 122584,
                            "name": "Nassau Village-Ratliff",
                            "latitude": "30.51111000",
                            "longitude": "-81.80925000"
                        },
                        {
                            "id": 122600,
                            "name": "Navarre",
                            "latitude": "30.40159000",
                            "longitude": "-86.86357000"
                        },
                        {
                            "id": 122637,
                            "name": "Neptune Beach",
                            "latitude": "30.31191000",
                            "longitude": "-81.39647000"
                        },
                        {
                            "id": 122764,
                            "name": "New Port Richey",
                            "latitude": "28.24418000",
                            "longitude": "-82.71927000"
                        },
                        {
                            "id": 122765,
                            "name": "New Port Richey East",
                            "latitude": "28.26027000",
                            "longitude": "-82.69261000"
                        },
                        {
                            "id": 122779,
                            "name": "New Smyrna Beach",
                            "latitude": "29.02582000",
                            "longitude": "-80.92700000"
                        },
                        {
                            "id": 122810,
                            "name": "Newberry",
                            "latitude": "29.64635000",
                            "longitude": "-82.60650000"
                        },
                        {
                            "id": 122886,
                            "name": "Niceville",
                            "latitude": "30.51686000",
                            "longitude": "-86.48217000"
                        },
                        {
                            "id": 122922,
                            "name": "Nocatee",
                            "latitude": "27.16033000",
                            "longitude": "-81.88231000"
                        },
                        {
                            "id": 122929,
                            "name": "Nokomis",
                            "latitude": "27.11922000",
                            "longitude": "-82.44426000"
                        },
                        {
                            "id": 122946,
                            "name": "Norland",
                            "latitude": "25.94898000",
                            "longitude": "-80.21227000"
                        },
                        {
                            "id": 122963,
                            "name": "North Andrews Gardens",
                            "latitude": "26.19147000",
                            "longitude": "-80.14421000"
                        },
                        {
                            "id": 122976,
                            "name": "North Bay Village",
                            "latitude": "25.84621000",
                            "longitude": "-80.15394000"
                        },
                        {
                            "id": 122997,
                            "name": "North Brooksville",
                            "latitude": "28.57305000",
                            "longitude": "-82.40815000"
                        },
                        {
                            "id": 123013,
                            "name": "North DeLand",
                            "latitude": "29.04943000",
                            "longitude": "-81.29812000"
                        },
                        {
                            "id": 123029,
                            "name": "North Fort Myers",
                            "latitude": "26.66729000",
                            "longitude": "-81.88009000"
                        },
                        {
                            "id": 123047,
                            "name": "North Key Largo",
                            "latitude": "25.26734000",
                            "longitude": "-80.32339000"
                        },
                        {
                            "id": 123053,
                            "name": "North Lauderdale",
                            "latitude": "26.21730000",
                            "longitude": "-80.22588000"
                        },
                        {
                            "id": 123066,
                            "name": "North Miami",
                            "latitude": "25.89009000",
                            "longitude": "-80.18671000"
                        },
                        {
                            "id": 123067,
                            "name": "North Miami Beach",
                            "latitude": "25.93315000",
                            "longitude": "-80.16255000"
                        },
                        {
                            "id": 123075,
                            "name": "North Palm Beach",
                            "latitude": "26.81756000",
                            "longitude": "-80.08199000"
                        },
                        {
                            "id": 123085,
                            "name": "North Port",
                            "latitude": "27.04422000",
                            "longitude": "-82.23593000"
                        },
                        {
                            "id": 123092,
                            "name": "North Redington Beach",
                            "latitude": "27.81614000",
                            "longitude": "-82.82066000"
                        },
                        {
                            "id": 123095,
                            "name": "North River Shores",
                            "latitude": "27.21755000",
                            "longitude": "-80.26977000"
                        },
                        {
                            "id": 123100,
                            "name": "North Sarasota",
                            "latitude": "27.37393000",
                            "longitude": "-82.51843000"
                        },
                        {
                            "id": 123127,
                            "name": "North Weeki Wachee",
                            "latitude": "28.55014000",
                            "longitude": "-82.55888000"
                        },
                        {
                            "id": 123145,
                            "name": "Northdale",
                            "latitude": "28.09390000",
                            "longitude": "-82.50561000"
                        },
                        {
                            "id": 123243,
                            "name": "Oak Hill",
                            "latitude": "28.86443000",
                            "longitude": "-80.85450000"
                        },
                        {
                            "id": 123260,
                            "name": "Oak Ridge",
                            "latitude": "28.47112000",
                            "longitude": "-81.42452000"
                        },
                        {
                            "id": 123282,
                            "name": "Oakland",
                            "latitude": "28.55500000",
                            "longitude": "-81.63313000"
                        },
                        {
                            "id": 123296,
                            "name": "Oakland Park",
                            "latitude": "26.17231000",
                            "longitude": "-80.13199000"
                        },
                        {
                            "id": 123297,
                            "name": "Oakleaf Plantation",
                            "latitude": "30.17083000",
                            "longitude": "-81.83549000"
                        },
                        {
                            "id": 123320,
                            "name": "Ocala",
                            "latitude": "29.18720000",
                            "longitude": "-82.14009000"
                        },
                        {
                            "id": 123325,
                            "name": "Ocean City",
                            "latitude": "30.44103000",
                            "longitude": "-86.61356000"
                        },
                        {
                            "id": 123335,
                            "name": "Ocean Ridge",
                            "latitude": "26.52702000",
                            "longitude": "-80.04837000"
                        },
                        {
                            "id": 123347,
                            "name": "Ocoee",
                            "latitude": "28.56917000",
                            "longitude": "-81.54396000"
                        },
                        {
                            "id": 123358,
                            "name": "Odessa",
                            "latitude": "28.19390000",
                            "longitude": "-82.59176000"
                        },
                        {
                            "id": 123391,
                            "name": "Ojus",
                            "latitude": "25.94843000",
                            "longitude": "-80.15060000"
                        },
                        {
                            "id": 123392,
                            "name": "Okaloosa County",
                            "latitude": "30.66503000",
                            "longitude": "-86.59218000"
                        },
                        {
                            "id": 123398,
                            "name": "Okeechobee",
                            "latitude": "27.24393000",
                            "longitude": "-80.82978000"
                        },
                        {
                            "id": 123399,
                            "name": "Okeechobee County",
                            "latitude": "27.38629000",
                            "longitude": "-80.88858000"
                        },
                        {
                            "id": 123434,
                            "name": "Oldsmar",
                            "latitude": "28.03418000",
                            "longitude": "-82.66510000"
                        },
                        {
                            "id": 123437,
                            "name": "Olga",
                            "latitude": "26.71896000",
                            "longitude": "-81.71230000"
                        },
                        {
                            "id": 123461,
                            "name": "Olympia Heights",
                            "latitude": "25.72677000",
                            "longitude": "-80.35533000"
                        },
                        {
                            "id": 123494,
                            "name": "Opa-locka",
                            "latitude": "25.90232000",
                            "longitude": "-80.25033000"
                        },
                        {
                            "id": 123512,
                            "name": "Orange City",
                            "latitude": "28.94888000",
                            "longitude": "-81.29867000"
                        },
                        {
                            "id": 123514,
                            "name": "Orange County",
                            "latitude": "28.51442000",
                            "longitude": "-81.32348000"
                        },
                        {
                            "id": 123525,
                            "name": "Orange Park",
                            "latitude": "30.16607000",
                            "longitude": "-81.70648000"
                        },
                        {
                            "id": 123529,
                            "name": "Orangetree",
                            "latitude": "26.29286000",
                            "longitude": "-81.58842000"
                        },
                        {
                            "id": 123555,
                            "name": "Oriole Beach",
                            "latitude": "30.37381000",
                            "longitude": "-87.09136000"
                        },
                        {
                            "id": 123562,
                            "name": "Orlando",
                            "latitude": "28.53834000",
                            "longitude": "-81.37924000"
                        },
                        {
                            "id": 123568,
                            "name": "Orlovista",
                            "latitude": "28.53834000",
                            "longitude": "-81.46035000"
                        },
                        {
                            "id": 123569,
                            "name": "Ormond Beach",
                            "latitude": "29.28581000",
                            "longitude": "-81.05589000"
                        },
                        {
                            "id": 123570,
                            "name": "Ormond-by-the-Sea",
                            "latitude": "29.34914000",
                            "longitude": "-81.06645000"
                        },
                        {
                            "id": 123603,
                            "name": "Osceola County",
                            "latitude": "28.06266000",
                            "longitude": "-81.14948000"
                        },
                        {
                            "id": 123613,
                            "name": "Osprey",
                            "latitude": "27.19616000",
                            "longitude": "-82.49037000"
                        },
                        {
                            "id": 123656,
                            "name": "Oviedo",
                            "latitude": "28.67000000",
                            "longitude": "-81.20812000"
                        },
                        {
                            "id": 123704,
                            "name": "Pace",
                            "latitude": "30.59936000",
                            "longitude": "-87.16108000"
                        },
                        {
                            "id": 123723,
                            "name": "Pahokee",
                            "latitude": "26.82006000",
                            "longitude": "-80.66534000"
                        },
                        {
                            "id": 123732,
                            "name": "Palatka",
                            "latitude": "29.64858000",
                            "longitude": "-81.63758000"
                        },
                        {
                            "id": 123740,
                            "name": "Palm Aire",
                            "latitude": "26.20619000",
                            "longitude": "-80.19171000"
                        },
                        {
                            "id": 123741,
                            "name": "Palm Bay",
                            "latitude": "28.03446000",
                            "longitude": "-80.58866000"
                        },
                        {
                            "id": 123742,
                            "name": "Palm Beach",
                            "latitude": "26.70562000",
                            "longitude": "-80.03643000"
                        },
                        {
                            "id": 123743,
                            "name": "Palm Beach County",
                            "latitude": "26.64757000",
                            "longitude": "-80.43651000"
                        },
                        {
                            "id": 123744,
                            "name": "Palm Beach Gardens",
                            "latitude": "26.82339000",
                            "longitude": "-80.13865000"
                        },
                        {
                            "id": 123745,
                            "name": "Palm Beach Shores",
                            "latitude": "26.77812000",
                            "longitude": "-80.03560000"
                        },
                        {
                            "id": 123746,
                            "name": "Palm City",
                            "latitude": "27.16783000",
                            "longitude": "-80.26616000"
                        },
                        {
                            "id": 123747,
                            "name": "Palm Coast",
                            "latitude": "29.58497000",
                            "longitude": "-81.20784000"
                        },
                        {
                            "id": 123749,
                            "name": "Palm Harbor",
                            "latitude": "28.07807000",
                            "longitude": "-82.76371000"
                        },
                        {
                            "id": 123750,
                            "name": "Palm River-Clair Mel",
                            "latitude": "27.92386000",
                            "longitude": "-82.37939000"
                        },
                        {
                            "id": 123751,
                            "name": "Palm Springs",
                            "latitude": "26.63590000",
                            "longitude": "-80.09615000"
                        },
                        {
                            "id": 123753,
                            "name": "Palm Springs North",
                            "latitude": "25.93510000",
                            "longitude": "-80.33383000"
                        },
                        {
                            "id": 123754,
                            "name": "Palm Valley",
                            "latitude": "30.17746000",
                            "longitude": "-81.38758000"
                        },
                        {
                            "id": 123764,
                            "name": "Palmetto",
                            "latitude": "27.52143000",
                            "longitude": "-82.57232000"
                        },
                        {
                            "id": 123766,
                            "name": "Palmetto Bay",
                            "latitude": "25.62177000",
                            "longitude": "-80.32477000"
                        },
                        {
                            "id": 123767,
                            "name": "Palmetto Estates",
                            "latitude": "25.62149000",
                            "longitude": "-80.36200000"
                        },
                        {
                            "id": 123769,
                            "name": "Palmona Park",
                            "latitude": "26.68646000",
                            "longitude": "-81.89648000"
                        },
                        {
                            "id": 123798,
                            "name": "Panama City",
                            "latitude": "30.15946000",
                            "longitude": "-85.65983000"
                        },
                        {
                            "id": 123799,
                            "name": "Panama City Beach",
                            "latitude": "30.17659000",
                            "longitude": "-85.80549000"
                        },
                        {
                            "id": 123820,
                            "name": "Paradise Heights",
                            "latitude": "28.62361000",
                            "longitude": "-81.54396000"
                        },
                        {
                            "id": 123858,
                            "name": "Parker",
                            "latitude": "30.13104000",
                            "longitude": "-85.60326000"
                        },
                        {
                            "id": 123871,
                            "name": "Parkland",
                            "latitude": "26.31008000",
                            "longitude": "-80.23727000"
                        },
                        {
                            "id": 123898,
                            "name": "Pasadena Hills",
                            "latitude": "28.28001000",
                            "longitude": "-82.22438000"
                        },
                        {
                            "id": 123902,
                            "name": "Pasco County",
                            "latitude": "28.30674000",
                            "longitude": "-82.43887000"
                        },
                        {
                            "id": 123974,
                            "name": "Pebble Creek",
                            "latitude": "28.14835000",
                            "longitude": "-82.34565000"
                        },
                        {
                            "id": 123994,
                            "name": "Pelican Bay",
                            "latitude": "26.23120000",
                            "longitude": "-81.80564000"
                        },
                        {
                            "id": 124008,
                            "name": "Pembroke Park",
                            "latitude": "25.98787000",
                            "longitude": "-80.17477000"
                        },
                        {
                            "id": 124009,
                            "name": "Pembroke Pines",
                            "latitude": "26.00315000",
                            "longitude": "-80.22394000"
                        },
                        {
                            "id": 124045,
                            "name": "Pensacola",
                            "latitude": "30.42131000",
                            "longitude": "-87.21691000"
                        },
                        {
                            "id": 124067,
                            "name": "Perry",
                            "latitude": "30.11766000",
                            "longitude": "-83.58274000"
                        },
                        {
                            "id": 124180,
                            "name": "Pierson",
                            "latitude": "29.23942000",
                            "longitude": "-81.46563000"
                        },
                        {
                            "id": 124217,
                            "name": "Pine Castle",
                            "latitude": "28.47195000",
                            "longitude": "-81.36785000"
                        },
                        {
                            "id": 124225,
                            "name": "Pine Hills",
                            "latitude": "28.55778000",
                            "longitude": "-81.45340000"
                        },
                        {
                            "id": 124229,
                            "name": "Pine Island Center",
                            "latitude": "26.61369000",
                            "longitude": "-82.11815000"
                        },
                        {
                            "id": 124230,
                            "name": "Pine Island Ridge",
                            "latitude": "26.09481000",
                            "longitude": "-80.27394000"
                        },
                        {
                            "id": 124237,
                            "name": "Pine Manor",
                            "latitude": "26.57285000",
                            "longitude": "-81.87814000"
                        },
                        {
                            "id": 124242,
                            "name": "Pine Ridge",
                            "latitude": "28.93831000",
                            "longitude": "-82.47343000"
                        },
                        {
                            "id": 124248,
                            "name": "Pinecrest",
                            "latitude": "25.66705000",
                            "longitude": "-80.30811000"
                        },
                        {
                            "id": 124254,
                            "name": "Pinellas County",
                            "latitude": "27.90268000",
                            "longitude": "-82.73955000"
                        },
                        {
                            "id": 124255,
                            "name": "Pinellas Park",
                            "latitude": "27.84280000",
                            "longitude": "-82.69954000"
                        },
                        {
                            "id": 124264,
                            "name": "Pinewood",
                            "latitude": "25.86898000",
                            "longitude": "-80.21699000"
                        },
                        {
                            "id": 124313,
                            "name": "Placid Lakes",
                            "latitude": "27.24144000",
                            "longitude": "-81.40702000"
                        },
                        {
                            "id": 124345,
                            "name": "Plant City",
                            "latitude": "28.01888000",
                            "longitude": "-82.11469000"
                        },
                        {
                            "id": 124346,
                            "name": "Plantation",
                            "latitude": "26.13421000",
                            "longitude": "-80.23184000"
                        },
                        {
                            "id": 124347,
                            "name": "Plantation Mobile Home Park",
                            "latitude": "26.70312000",
                            "longitude": "-80.13227000"
                        },
                        {
                            "id": 124424,
                            "name": "Poinciana",
                            "latitude": "28.14029000",
                            "longitude": "-81.45841000"
                        },
                        {
                            "id": 124426,
                            "name": "Point Baker",
                            "latitude": "30.69019000",
                            "longitude": "-87.05358000"
                        },
                        {
                            "id": 124438,
                            "name": "Polk City",
                            "latitude": "28.18251000",
                            "longitude": "-81.82397000"
                        },
                        {
                            "id": 124441,
                            "name": "Polk County",
                            "latitude": "27.94888000",
                            "longitude": "-81.69767000"
                        },
                        {
                            "id": 124460,
                            "name": "Pompano Beach",
                            "latitude": "26.23786000",
                            "longitude": "-80.12477000"
                        },
                        {
                            "id": 124461,
                            "name": "Pompano Beach Highlands",
                            "latitude": "26.28286000",
                            "longitude": "-80.10699000"
                        },
                        {
                            "id": 124465,
                            "name": "Ponce Inlet",
                            "latitude": "29.09637000",
                            "longitude": "-80.93700000"
                        },
                        {
                            "id": 124472,
                            "name": "Ponte Vedra Beach",
                            "latitude": "30.23969000",
                            "longitude": "-81.38564000"
                        },
                        {
                            "id": 124502,
                            "name": "Port Charlotte",
                            "latitude": "26.97617000",
                            "longitude": "-82.09064000"
                        },
                        {
                            "id": 124516,
                            "name": "Port LaBelle",
                            "latitude": "26.75645000",
                            "longitude": "-81.40508000"
                        },
                        {
                            "id": 124524,
                            "name": "Port Orange",
                            "latitude": "29.13832000",
                            "longitude": "-80.99561000"
                        },
                        {
                            "id": 124529,
                            "name": "Port Richey",
                            "latitude": "28.27168000",
                            "longitude": "-82.71955000"
                        },
                        {
                            "id": 124532,
                            "name": "Port Saint Joe",
                            "latitude": "29.81188000",
                            "longitude": "-85.30297000"
                        },
                        {
                            "id": 124533,
                            "name": "Port Saint John",
                            "latitude": "28.47695000",
                            "longitude": "-80.78867000"
                        },
                        {
                            "id": 124534,
                            "name": "Port Saint Lucie",
                            "latitude": "27.29393000",
                            "longitude": "-80.35033000"
                        },
                        {
                            "id": 124535,
                            "name": "Port Salerno",
                            "latitude": "27.14422000",
                            "longitude": "-80.20060000"
                        },
                        {
                            "id": 124662,
                            "name": "Pretty Bayou",
                            "latitude": "30.19659000",
                            "longitude": "-85.69660000"
                        },
                        {
                            "id": 124680,
                            "name": "Princeton",
                            "latitude": "25.53844000",
                            "longitude": "-80.40894000"
                        },
                        {
                            "id": 124703,
                            "name": "Progress Village",
                            "latitude": "27.90030000",
                            "longitude": "-82.36454000"
                        },
                        {
                            "id": 124748,
                            "name": "Punta Gorda",
                            "latitude": "26.92978000",
                            "longitude": "-82.04537000"
                        },
                        {
                            "id": 124749,
                            "name": "Punta Gorda Isles",
                            "latitude": "26.91756000",
                            "longitude": "-82.07842000"
                        },
                        {
                            "id": 124750,
                            "name": "Punta Rassa",
                            "latitude": "26.48786000",
                            "longitude": "-82.01231000"
                        },
                        {
                            "id": 124761,
                            "name": "Putnam County",
                            "latitude": "29.60863000",
                            "longitude": "-81.74430000"
                        },
                        {
                            "id": 124776,
                            "name": "Quail Ridge",
                            "latitude": "28.34905000",
                            "longitude": "-82.55532000"
                        },
                        {
                            "id": 124795,
                            "name": "Quincy",
                            "latitude": "30.58714000",
                            "longitude": "-84.58325000"
                        },
                        {
                            "id": 124971,
                            "name": "Redington Beach",
                            "latitude": "27.80864000",
                            "longitude": "-82.81121000"
                        },
                        {
                            "id": 124972,
                            "name": "Redington Shores",
                            "latitude": "27.82614000",
                            "longitude": "-82.82899000"
                        },
                        {
                            "id": 125102,
                            "name": "Richmond Heights",
                            "latitude": "25.63149000",
                            "longitude": "-80.36894000"
                        },
                        {
                            "id": 125106,
                            "name": "Richmond West",
                            "latitude": "25.61050000",
                            "longitude": "-80.42971000"
                        },
                        {
                            "id": 125115,
                            "name": "Ridge Manor",
                            "latitude": "28.50750000",
                            "longitude": "-82.17036000"
                        },
                        {
                            "id": 125116,
                            "name": "Ridge Wood Heights",
                            "latitude": "27.28727000",
                            "longitude": "-82.51315000"
                        },
                        {
                            "id": 125117,
                            "name": "Ridgecrest",
                            "latitude": "27.89750000",
                            "longitude": "-82.80529000"
                        },
                        {
                            "id": 125184,
                            "name": "River Park",
                            "latitude": "27.31421000",
                            "longitude": "-80.34727000"
                        },
                        {
                            "id": 125216,
                            "name": "Riverview",
                            "latitude": "27.86614000",
                            "longitude": "-82.32648000"
                        },
                        {
                            "id": 125221,
                            "name": "Riviera Beach",
                            "latitude": "26.77534000",
                            "longitude": "-80.05810000"
                        },
                        {
                            "id": 125282,
                            "name": "Rock Island",
                            "latitude": "26.15509000",
                            "longitude": "-80.17699000"
                        },
                        {
                            "id": 125311,
                            "name": "Rockledge",
                            "latitude": "28.35084000",
                            "longitude": "-80.72533000"
                        },
                        {
                            "id": 125395,
                            "name": "Roosevelt Gardens",
                            "latitude": "26.14087000",
                            "longitude": "-80.18027000"
                        },
                        {
                            "id": 125421,
                            "name": "Roseland",
                            "latitude": "27.83586000",
                            "longitude": "-80.49311000"
                        },
                        {
                            "id": 125467,
                            "name": "Rotonda West",
                            "latitude": "26.88368000",
                            "longitude": "-82.29009000"
                        },
                        {
                            "id": 125491,
                            "name": "Royal Palm Beach",
                            "latitude": "26.70840000",
                            "longitude": "-80.23060000"
                        },
                        {
                            "id": 125492,
                            "name": "Royal Palm Estates",
                            "latitude": "26.68173000",
                            "longitude": "-80.12504000"
                        },
                        {
                            "id": 125531,
                            "name": "Ruskin",
                            "latitude": "27.72086000",
                            "longitude": "-82.43315000"
                        },
                        {
                            "id": 125582,
                            "name": "Safety Harbor",
                            "latitude": "27.99085000",
                            "longitude": "-82.69316000"
                        },
                        {
                            "id": 125604,
                            "name": "Saint Augustine",
                            "latitude": "29.89469000",
                            "longitude": "-81.31452000"
                        },
                        {
                            "id": 125605,
                            "name": "Saint Augustine Beach",
                            "latitude": "29.85053000",
                            "longitude": "-81.26535000"
                        },
                        {
                            "id": 125606,
                            "name": "Saint Augustine Shores",
                            "latitude": "29.81080000",
                            "longitude": "-81.31035000"
                        },
                        {
                            "id": 125607,
                            "name": "Saint Augustine South",
                            "latitude": "29.84249000",
                            "longitude": "-81.31448000"
                        },
                        {
                            "id": 125626,
                            "name": "Saint Cloud",
                            "latitude": "28.24890000",
                            "longitude": "-81.28118000"
                        },
                        {
                            "id": 125640,
                            "name": "Saint George",
                            "latitude": "28.05641000",
                            "longitude": "-82.72788000"
                        },
                        {
                            "id": 125657,
                            "name": "Saint James City",
                            "latitude": "26.49758000",
                            "longitude": "-82.07843000"
                        },
                        {
                            "id": 125667,
                            "name": "Saint Johns County",
                            "latitude": "29.91218000",
                            "longitude": "-81.40989000"
                        },
                        {
                            "id": 125679,
                            "name": "Saint Leo",
                            "latitude": "28.33723000",
                            "longitude": "-82.25842000"
                        },
                        {
                            "id": 125684,
                            "name": "Saint Lucie County",
                            "latitude": "27.38081000",
                            "longitude": "-80.44554000"
                        },
                        {
                            "id": 125707,
                            "name": "Saint Pete Beach",
                            "latitude": "27.72531000",
                            "longitude": "-82.74121000"
                        },
                        {
                            "id": 125779,
                            "name": "Samoset",
                            "latitude": "27.46948000",
                            "longitude": "-82.54149000"
                        },
                        {
                            "id": 125782,
                            "name": "Samsula-Spruce Creek",
                            "latitude": "29.04932000",
                            "longitude": "-81.06192000"
                        },
                        {
                            "id": 125786,
                            "name": "San Antonio",
                            "latitude": "28.33611000",
                            "longitude": "-82.27453000"
                        },
                        {
                            "id": 125799,
                            "name": "San Carlos Park",
                            "latitude": "26.46730000",
                            "longitude": "-81.80147000"
                        },
                        {
                            "id": 125862,
                            "name": "Sandalfoot Cove",
                            "latitude": "26.33863000",
                            "longitude": "-80.18690000"
                        },
                        {
                            "id": 125886,
                            "name": "Sanford",
                            "latitude": "28.80055000",
                            "longitude": "-81.27312000"
                        },
                        {
                            "id": 125894,
                            "name": "Sanibel",
                            "latitude": "26.44897000",
                            "longitude": "-82.02231000"
                        },
                        {
                            "id": 125924,
                            "name": "Santa Rosa County",
                            "latitude": "30.69290000",
                            "longitude": "-87.01845000"
                        },
                        {
                            "id": 125938,
                            "name": "Sarasota",
                            "latitude": "27.33643000",
                            "longitude": "-82.53065000"
                        },
                        {
                            "id": 125939,
                            "name": "Sarasota County",
                            "latitude": "27.18248000",
                            "longitude": "-82.36498000"
                        },
                        {
                            "id": 125940,
                            "name": "Sarasota Springs",
                            "latitude": "27.30894000",
                            "longitude": "-82.47954000"
                        },
                        {
                            "id": 125955,
                            "name": "Satellite Beach",
                            "latitude": "28.17612000",
                            "longitude": "-80.59005000"
                        },
                        {
                            "id": 125980,
                            "name": "Sawgrass",
                            "latitude": "30.19274000",
                            "longitude": "-81.37064000"
                        },
                        {
                            "id": 126001,
                            "name": "Schall Circle",
                            "latitude": "26.71562000",
                            "longitude": "-80.11504000"
                        },
                        {
                            "id": 126054,
                            "name": "Scott Lake",
                            "latitude": "25.94148000",
                            "longitude": "-80.23199000"
                        },
                        {
                            "id": 126097,
                            "name": "Seaside",
                            "latitude": "30.32103000",
                            "longitude": "-86.14161000"
                        },
                        {
                            "id": 126105,
                            "name": "Sebastian",
                            "latitude": "27.81641000",
                            "longitude": "-80.47061000"
                        },
                        {
                            "id": 126111,
                            "name": "Sebring",
                            "latitude": "27.49559000",
                            "longitude": "-81.44091000"
                        },
                        {
                            "id": 126127,
                            "name": "Seffner",
                            "latitude": "27.98363000",
                            "longitude": "-82.27565000"
                        },
                        {
                            "id": 126144,
                            "name": "Seminole",
                            "latitude": "27.83975000",
                            "longitude": "-82.79121000"
                        },
                        {
                            "id": 126147,
                            "name": "Seminole County",
                            "latitude": "28.71698000",
                            "longitude": "-81.23630000"
                        },
                        {
                            "id": 126150,
                            "name": "Seminole Manor",
                            "latitude": "26.58368000",
                            "longitude": "-80.10032000"
                        },
                        {
                            "id": 126184,
                            "name": "Sewall's Point",
                            "latitude": "27.19949000",
                            "longitude": "-80.20227000"
                        },
                        {
                            "id": 126204,
                            "name": "Shady Hills",
                            "latitude": "28.41000000",
                            "longitude": "-82.54288000"
                        },
                        {
                            "id": 126234,
                            "name": "Sharpes",
                            "latitude": "28.43223000",
                            "longitude": "-80.76005000"
                        },
                        {
                            "id": 126409,
                            "name": "Siesta Key",
                            "latitude": "27.26785000",
                            "longitude": "-82.54526000"
                        },
                        {
                            "id": 126427,
                            "name": "Silver Lake",
                            "latitude": "28.84193000",
                            "longitude": "-81.79841000"
                        },
                        {
                            "id": 126436,
                            "name": "Silver Springs",
                            "latitude": "29.21664000",
                            "longitude": "-82.05759000"
                        },
                        {
                            "id": 126437,
                            "name": "Silver Springs Shores",
                            "latitude": "29.10442000",
                            "longitude": "-82.02064000"
                        },
                        {
                            "id": 126485,
                            "name": "Sky Lake",
                            "latitude": "28.45723000",
                            "longitude": "-81.39146000"
                        },
                        {
                            "id": 126538,
                            "name": "Sneads",
                            "latitude": "30.70801000",
                            "longitude": "-84.92552000"
                        },
                        {
                            "id": 126614,
                            "name": "South Apopka",
                            "latitude": "28.66194000",
                            "longitude": "-81.50952000"
                        },
                        {
                            "id": 126618,
                            "name": "South Bay",
                            "latitude": "26.66396000",
                            "longitude": "-80.71617000"
                        },
                        {
                            "id": 126619,
                            "name": "South Beach",
                            "latitude": "27.59115000",
                            "longitude": "-80.34422000"
                        },
                        {
                            "id": 126631,
                            "name": "South Bradenton",
                            "latitude": "27.46310000",
                            "longitude": "-82.58176000"
                        },
                        {
                            "id": 126632,
                            "name": "South Brooksville",
                            "latitude": "28.53582000",
                            "longitude": "-82.38403000"
                        },
                        {
                            "id": 126645,
                            "name": "South Daytona",
                            "latitude": "29.16582000",
                            "longitude": "-81.00450000"
                        },
                        {
                            "id": 126660,
                            "name": "South Gate Ridge",
                            "latitude": "27.28644000",
                            "longitude": "-82.49676000"
                        },
                        {
                            "id": 126670,
                            "name": "South Highpoint",
                            "latitude": "27.91697000",
                            "longitude": "-82.71288000"
                        },
                        {
                            "id": 126691,
                            "name": "South Miami",
                            "latitude": "25.70760000",
                            "longitude": "-80.29338000"
                        },
                        {
                            "id": 126692,
                            "name": "South Miami Heights",
                            "latitude": "25.59761000",
                            "longitude": "-80.38061000"
                        },
                        {
                            "id": 126701,
                            "name": "South Palm Beach",
                            "latitude": "26.58896000",
                            "longitude": "-80.03865000"
                        },
                        {
                            "id": 126705,
                            "name": "South Pasadena",
                            "latitude": "27.75503000",
                            "longitude": "-82.73760000"
                        },
                        {
                            "id": 126707,
                            "name": "South Patrick Shores",
                            "latitude": "28.20223000",
                            "longitude": "-80.60950000"
                        },
                        {
                            "id": 126727,
                            "name": "South Sarasota",
                            "latitude": "27.28616000",
                            "longitude": "-82.53288000"
                        },
                        {
                            "id": 126742,
                            "name": "South Venice",
                            "latitude": "27.05311000",
                            "longitude": "-82.42426000"
                        },
                        {
                            "id": 126764,
                            "name": "Southchase",
                            "latitude": "28.39306000",
                            "longitude": "-81.38340000"
                        },
                        {
                            "id": 126765,
                            "name": "Southeast Arcadia",
                            "latitude": "27.18629000",
                            "longitude": "-81.85210000"
                        },
                        {
                            "id": 126773,
                            "name": "Southgate",
                            "latitude": "27.30810000",
                            "longitude": "-82.50982000"
                        },
                        {
                            "id": 126790,
                            "name": "Southwest Ranches",
                            "latitude": "26.05870000",
                            "longitude": "-80.33727000"
                        },
                        {
                            "id": 126860,
                            "name": "Spring Hill",
                            "latitude": "28.47688000",
                            "longitude": "-82.52546000"
                        },
                        {
                            "id": 126889,
                            "name": "Springfield",
                            "latitude": "30.15326000",
                            "longitude": "-85.61132000"
                        },
                        {
                            "id": 126926,
                            "name": "St. Johns",
                            "latitude": "30.08150000",
                            "longitude": "-81.54774000"
                        },
                        {
                            "id": 126930,
                            "name": "St. Petersburg",
                            "latitude": "27.77086000",
                            "longitude": "-82.67927000"
                        },
                        {
                            "id": 126986,
                            "name": "Starke",
                            "latitude": "29.94413000",
                            "longitude": "-82.10983000"
                        },
                        {
                            "id": 127018,
                            "name": "Steinhatchee",
                            "latitude": "29.67106000",
                            "longitude": "-83.38764000"
                        },
                        {
                            "id": 127066,
                            "name": "Stock Island",
                            "latitude": "24.56709000",
                            "longitude": "-81.73842000"
                        },
                        {
                            "id": 127142,
                            "name": "Stuart",
                            "latitude": "27.19755000",
                            "longitude": "-80.25283000"
                        },
                        {
                            "id": 127176,
                            "name": "Sugarmill Woods",
                            "latitude": "28.73221000",
                            "longitude": "-82.50621000"
                        },
                        {
                            "id": 127229,
                            "name": "Sumter County",
                            "latitude": "28.70482000",
                            "longitude": "-82.08100000"
                        },
                        {
                            "id": 127234,
                            "name": "Sun City Center",
                            "latitude": "27.71809000",
                            "longitude": "-82.35176000"
                        },
                        {
                            "id": 127246,
                            "name": "Suncoast Estates",
                            "latitude": "26.71174000",
                            "longitude": "-81.86897000"
                        },
                        {
                            "id": 127256,
                            "name": "Sunny Isles Beach",
                            "latitude": "25.95065000",
                            "longitude": "-80.12282000"
                        },
                        {
                            "id": 127267,
                            "name": "Sunrise",
                            "latitude": "26.13397000",
                            "longitude": "-80.11310000"
                        },
                        {
                            "id": 127271,
                            "name": "Sunset",
                            "latitude": "25.70594000",
                            "longitude": "-80.35228000"
                        },
                        {
                            "id": 127277,
                            "name": "Sunshine Ranches",
                            "latitude": "26.04592000",
                            "longitude": "-80.32894000"
                        },
                        {
                            "id": 127287,
                            "name": "Surfside",
                            "latitude": "25.87843000",
                            "longitude": "-80.12560000"
                        },
                        {
                            "id": 127318,
                            "name": "Suwannee County",
                            "latitude": "30.19561000",
                            "longitude": "-82.99149000"
                        },
                        {
                            "id": 127340,
                            "name": "Sweetwater",
                            "latitude": "25.76343000",
                            "longitude": "-80.37311000"
                        },
                        {
                            "id": 127371,
                            "name": "Taft",
                            "latitude": "28.42973000",
                            "longitude": "-81.36507000"
                        },
                        {
                            "id": 127391,
                            "name": "Tallahassee",
                            "latitude": "30.43826000",
                            "longitude": "-84.28073000"
                        },
                        {
                            "id": 127405,
                            "name": "Tamarac",
                            "latitude": "26.21286000",
                            "longitude": "-80.24977000"
                        },
                        {
                            "id": 127406,
                            "name": "Tamiami",
                            "latitude": "25.75871000",
                            "longitude": "-80.39839000"
                        },
                        {
                            "id": 127407,
                            "name": "Tampa",
                            "latitude": "27.94752000",
                            "longitude": "-82.45843000"
                        },
                        {
                            "id": 127412,
                            "name": "Tangelo Park",
                            "latitude": "28.45584000",
                            "longitude": "-81.44590000"
                        },
                        {
                            "id": 127414,
                            "name": "Tangerine",
                            "latitude": "28.76499000",
                            "longitude": "-81.63063000"
                        },
                        {
                            "id": 127433,
                            "name": "Tarpon Springs",
                            "latitude": "28.14612000",
                            "longitude": "-82.75677000"
                        },
                        {
                            "id": 127442,
                            "name": "Tavares",
                            "latitude": "28.80416000",
                            "longitude": "-81.72563000"
                        },
                        {
                            "id": 127443,
                            "name": "Tavernier",
                            "latitude": "25.01152000",
                            "longitude": "-80.51506000"
                        },
                        {
                            "id": 127451,
                            "name": "Taylor County",
                            "latitude": "30.01957000",
                            "longitude": "-83.61897000"
                        },
                        {
                            "id": 127458,
                            "name": "Taylor Creek",
                            "latitude": "27.21671000",
                            "longitude": "-80.78950000"
                        },
                        {
                            "id": 127481,
                            "name": "Tedder",
                            "latitude": "26.28425000",
                            "longitude": "-80.12227000"
                        },
                        {
                            "id": 127503,
                            "name": "Temple Terrace",
                            "latitude": "28.03530000",
                            "longitude": "-82.38926000"
                        },
                        {
                            "id": 127513,
                            "name": "Tequesta",
                            "latitude": "26.96811000",
                            "longitude": "-80.12865000"
                        },
                        {
                            "id": 127516,
                            "name": "Terra Mar",
                            "latitude": "26.21619000",
                            "longitude": "-80.09532000"
                        },
                        {
                            "id": 127553,
                            "name": "The Acreage",
                            "latitude": "26.79404000",
                            "longitude": "-80.26749000"
                        },
                        {
                            "id": 127556,
                            "name": "The Crossings",
                            "latitude": "25.67066000",
                            "longitude": "-80.40117000"
                        },
                        {
                            "id": 127559,
                            "name": "The Hammocks",
                            "latitude": "25.67149000",
                            "longitude": "-80.44450000"
                        },
                        {
                            "id": 127562,
                            "name": "The Meadows",
                            "latitude": "27.36171000",
                            "longitude": "-82.46898000"
                        },
                        {
                            "id": 127565,
                            "name": "The Villages",
                            "latitude": "28.93408000",
                            "longitude": "-81.95994000"
                        },
                        {
                            "id": 127597,
                            "name": "Thonotosassa",
                            "latitude": "28.06141000",
                            "longitude": "-82.30231000"
                        },
                        {
                            "id": 127612,
                            "name": "Three Lakes",
                            "latitude": "25.64205000",
                            "longitude": "-80.39839000"
                        },
                        {
                            "id": 127614,
                            "name": "Three Oaks",
                            "latitude": "26.47008000",
                            "longitude": "-81.79397000"
                        },
                        {
                            "id": 127633,
                            "name": "Tice",
                            "latitude": "26.67479000",
                            "longitude": "-81.81508000"
                        },
                        {
                            "id": 127638,
                            "name": "Tierra Verde",
                            "latitude": "27.69197000",
                            "longitude": "-82.72343000"
                        },
                        {
                            "id": 127644,
                            "name": "Tiger Point",
                            "latitude": "30.37853000",
                            "longitude": "-87.05552000"
                        },
                        {
                            "id": 127657,
                            "name": "Timber Pines",
                            "latitude": "28.47028000",
                            "longitude": "-82.60316000"
                        },
                        {
                            "id": 127685,
                            "name": "Titusville",
                            "latitude": "28.61222000",
                            "longitude": "-80.80755000"
                        },
                        {
                            "id": 127756,
                            "name": "Town 'n' Country",
                            "latitude": "28.01057000",
                            "longitude": "-82.57732000"
                        },
                        {
                            "id": 127790,
                            "name": "Treasure Island",
                            "latitude": "27.76919000",
                            "longitude": "-82.76899000"
                        },
                        {
                            "id": 127801,
                            "name": "Trenton",
                            "latitude": "29.61329000",
                            "longitude": "-82.81762000"
                        },
                        {
                            "id": 127824,
                            "name": "Trinity",
                            "latitude": "28.18085000",
                            "longitude": "-82.68177000"
                        },
                        {
                            "id": 127928,
                            "name": "Twin Lakes",
                            "latitude": "26.18092000",
                            "longitude": "-80.16005000"
                        },
                        {
                            "id": 127946,
                            "name": "Tyndall Air Force Base",
                            "latitude": "30.08535000",
                            "longitude": "-85.60731000"
                        },
                        {
                            "id": 127961,
                            "name": "Umatilla",
                            "latitude": "28.92943000",
                            "longitude": "-81.66563000"
                        },
                        {
                            "id": 127989,
                            "name": "Union County",
                            "latitude": "30.04384000",
                            "longitude": "-82.37144000"
                        },
                        {
                            "id": 128009,
                            "name": "Union Park",
                            "latitude": "28.56806000",
                            "longitude": "-81.28618000"
                        },
                        {
                            "id": 128025,
                            "name": "University",
                            "latitude": "28.07389000",
                            "longitude": "-82.43902000"
                        },
                        {
                            "id": 128031,
                            "name": "University Park",
                            "latitude": "25.74649000",
                            "longitude": "-80.36755000"
                        },
                        {
                            "id": 128044,
                            "name": "Upper Grand Lagoon",
                            "latitude": "30.16326000",
                            "longitude": "-85.74076000"
                        },
                        {
                            "id": 128120,
                            "name": "Valparaiso",
                            "latitude": "30.50853000",
                            "longitude": "-86.50273000"
                        },
                        {
                            "id": 128122,
                            "name": "Valrico",
                            "latitude": "27.93789000",
                            "longitude": "-82.23644000"
                        },
                        {
                            "id": 128123,
                            "name": "Vamo",
                            "latitude": "27.22200000",
                            "longitude": "-82.49787000"
                        },
                        {
                            "id": 128170,
                            "name": "Venice",
                            "latitude": "27.09978000",
                            "longitude": "-82.45426000"
                        },
                        {
                            "id": 128173,
                            "name": "Venice Gardens",
                            "latitude": "27.07311000",
                            "longitude": "-82.40760000"
                        },
                        {
                            "id": 128198,
                            "name": "Vero Beach",
                            "latitude": "27.63864000",
                            "longitude": "-80.39727000"
                        },
                        {
                            "id": 128199,
                            "name": "Vero Beach South",
                            "latitude": "27.61638000",
                            "longitude": "-80.41308000"
                        },
                        {
                            "id": 128206,
                            "name": "Verona Walk",
                            "latitude": "26.08412000",
                            "longitude": "-81.67985000"
                        },
                        {
                            "id": 128236,
                            "name": "Viera East",
                            "latitude": "28.26234000",
                            "longitude": "-80.71449000"
                        },
                        {
                            "id": 128237,
                            "name": "Viera West",
                            "latitude": "28.24504000",
                            "longitude": "-80.73380000"
                        },
                        {
                            "id": 128255,
                            "name": "Villages of Oriole",
                            "latitude": "26.46230000",
                            "longitude": "-80.15282000"
                        },
                        {
                            "id": 128256,
                            "name": "Villano Beach",
                            "latitude": "29.93858000",
                            "longitude": "-81.30202000"
                        },
                        {
                            "id": 128257,
                            "name": "Villas",
                            "latitude": "26.55035000",
                            "longitude": "-81.86870000"
                        },
                        {
                            "id": 128275,
                            "name": "Vineyards",
                            "latitude": "26.22373000",
                            "longitude": "-81.72798000"
                        },
                        {
                            "id": 128291,
                            "name": "Virginia Gardens",
                            "latitude": "25.81038000",
                            "longitude": "-80.30227000"
                        },
                        {
                            "id": 128303,
                            "name": "Volusia County",
                            "latitude": "29.06345000",
                            "longitude": "-81.14857000"
                        },
                        {
                            "id": 128314,
                            "name": "Wabasso Beach",
                            "latitude": "27.76475000",
                            "longitude": "-80.39894000"
                        },
                        {
                            "id": 128333,
                            "name": "Wahneta",
                            "latitude": "27.95280000",
                            "longitude": "-81.72702000"
                        },
                        {
                            "id": 128365,
                            "name": "Wakulla County",
                            "latitude": "30.14777000",
                            "longitude": "-84.37561000"
                        },
                        {
                            "id": 128371,
                            "name": "Waldo",
                            "latitude": "29.78969000",
                            "longitude": "-82.16732000"
                        },
                        {
                            "id": 128396,
                            "name": "Wallace",
                            "latitude": "30.67741000",
                            "longitude": "-87.17997000"
                        },
                        {
                            "id": 128437,
                            "name": "Walton County",
                            "latitude": "30.61847000",
                            "longitude": "-86.17152000"
                        },
                        {
                            "id": 128468,
                            "name": "Warm Mineral Springs",
                            "latitude": "27.05978000",
                            "longitude": "-82.26009000"
                        },
                        {
                            "id": 128512,
                            "name": "Warrington",
                            "latitude": "30.38409000",
                            "longitude": "-87.27497000"
                        },
                        {
                            "id": 128554,
                            "name": "Washington County",
                            "latitude": "30.61059000",
                            "longitude": "-85.66533000"
                        },
                        {
                            "id": 128584,
                            "name": "Washington Park",
                            "latitude": "26.13259000",
                            "longitude": "-80.18116000"
                        },
                        {
                            "id": 128618,
                            "name": "Watertown",
                            "latitude": "30.19245000",
                            "longitude": "-82.61457000"
                        },
                        {
                            "id": 128645,
                            "name": "Wauchula",
                            "latitude": "27.54726000",
                            "longitude": "-81.81147000"
                        },
                        {
                            "id": 128747,
                            "name": "Wedgefield",
                            "latitude": "28.48778000",
                            "longitude": "-81.07729000"
                        },
                        {
                            "id": 128755,
                            "name": "Weeki Wachee Gardens",
                            "latitude": "28.53361000",
                            "longitude": "-82.62954000"
                        },
                        {
                            "id": 128763,
                            "name": "Wekiwa Springs",
                            "latitude": "28.69861000",
                            "longitude": "-81.42563000"
                        },
                        {
                            "id": 128772,
                            "name": "Wellborn",
                            "latitude": "30.23106000",
                            "longitude": "-82.81957000"
                        },
                        {
                            "id": 128776,
                            "name": "Wellington",
                            "latitude": "26.65868000",
                            "longitude": "-80.24144000"
                        },
                        {
                            "id": 128809,
                            "name": "Wesley Chapel",
                            "latitude": "28.23973000",
                            "longitude": "-82.32787000"
                        },
                        {
                            "id": 129011,
                            "name": "West and East Lealman",
                            "latitude": "27.81993000",
                            "longitude": "-82.68944000"
                        },
                        {
                            "id": 128831,
                            "name": "West Bradenton",
                            "latitude": "27.50254000",
                            "longitude": "-82.61399000"
                        },
                        {
                            "id": 128855,
                            "name": "West DeLand",
                            "latitude": "29.01582000",
                            "longitude": "-81.33312000"
                        },
                        {
                            "id": 128876,
                            "name": "West Gate",
                            "latitude": "26.70257000",
                            "longitude": "-80.09810000"
                        },
                        {
                            "id": 128898,
                            "name": "West Hollywood",
                            "latitude": "26.02065000",
                            "longitude": "-80.18394000"
                        },
                        {
                            "id": 128920,
                            "name": "West Little River",
                            "latitude": "25.85704000",
                            "longitude": "-80.23699000"
                        },
                        {
                            "id": 128927,
                            "name": "West Melbourne",
                            "latitude": "28.07168000",
                            "longitude": "-80.65339000"
                        },
                        {
                            "id": 128930,
                            "name": "West Miami",
                            "latitude": "25.76343000",
                            "longitude": "-80.29616000"
                        },
                        {
                            "id": 128947,
                            "name": "West Palm Beach",
                            "latitude": "26.71534000",
                            "longitude": "-80.05337000"
                        },
                        {
                            "id": 128949,
                            "name": "West Park",
                            "latitude": "25.98454000",
                            "longitude": "-80.19894000"
                        },
                        {
                            "id": 128952,
                            "name": "West Pensacola",
                            "latitude": "30.42659000",
                            "longitude": "-87.27969000"
                        },
                        {
                            "id": 128954,
                            "name": "West Perrine",
                            "latitude": "25.60594000",
                            "longitude": "-80.36283000"
                        },
                        {
                            "id": 128974,
                            "name": "West Samoset",
                            "latitude": "27.46948000",
                            "longitude": "-82.55676000"
                        },
                        {
                            "id": 128998,
                            "name": "West Vero Corridor",
                            "latitude": "27.63775000",
                            "longitude": "-80.48576000"
                        },
                        {
                            "id": 129017,
                            "name": "Westchase",
                            "latitude": "28.05502000",
                            "longitude": "-82.60982000"
                        },
                        {
                            "id": 129018,
                            "name": "Westchester",
                            "latitude": "25.75482000",
                            "longitude": "-80.32727000"
                        },
                        {
                            "id": 129058,
                            "name": "Weston",
                            "latitude": "26.10037000",
                            "longitude": "-80.39977000"
                        },
                        {
                            "id": 129075,
                            "name": "Westview",
                            "latitude": "25.88204000",
                            "longitude": "-80.24199000"
                        },
                        {
                            "id": 129088,
                            "name": "Westwood Lake",
                            "latitude": "25.72927000",
                            "longitude": "-80.37283000"
                        },
                        {
                            "id": 129094,
                            "name": "Wewahitchka",
                            "latitude": "30.11270000",
                            "longitude": "-85.20047000"
                        },
                        {
                            "id": 129123,
                            "name": "Whiskey Creek",
                            "latitude": "26.57258000",
                            "longitude": "-81.89009000"
                        },
                        {
                            "id": 129130,
                            "name": "White City",
                            "latitude": "27.37393000",
                            "longitude": "-80.33394000"
                        },
                        {
                            "id": 129193,
                            "name": "Whitfield",
                            "latitude": "27.41171000",
                            "longitude": "-82.56593000"
                        },
                        {
                            "id": 129236,
                            "name": "Wildwood",
                            "latitude": "28.86542000",
                            "longitude": "-82.04058000"
                        },
                        {
                            "id": 129265,
                            "name": "Williamsburg",
                            "latitude": "28.41445000",
                            "longitude": "-81.44285000"
                        },
                        {
                            "id": 129296,
                            "name": "Williston",
                            "latitude": "29.38747000",
                            "longitude": "-82.44677000"
                        },
                        {
                            "id": 129300,
                            "name": "Williston Highlands",
                            "latitude": "29.34052000",
                            "longitude": "-82.54150000"
                        },
                        {
                            "id": 129307,
                            "name": "Willow Oak",
                            "latitude": "27.91614000",
                            "longitude": "-82.01786000"
                        },
                        {
                            "id": 129347,
                            "name": "Wilton Manors",
                            "latitude": "26.16036000",
                            "longitude": "-80.13893000"
                        },
                        {
                            "id": 129348,
                            "name": "Wimauma",
                            "latitude": "27.71253000",
                            "longitude": "-82.29898000"
                        },
                        {
                            "id": 129370,
                            "name": "Windermere",
                            "latitude": "28.49556000",
                            "longitude": "-81.53480000"
                        },
                        {
                            "id": 129432,
                            "name": "Winston",
                            "latitude": "28.03169000",
                            "longitude": "-82.01481000"
                        },
                        {
                            "id": 129437,
                            "name": "Winter Beach",
                            "latitude": "27.71920000",
                            "longitude": "-80.42061000"
                        },
                        {
                            "id": 129438,
                            "name": "Winter Garden",
                            "latitude": "28.56528000",
                            "longitude": "-81.58618000"
                        },
                        {
                            "id": 129440,
                            "name": "Winter Haven",
                            "latitude": "28.02224000",
                            "longitude": "-81.73286000"
                        },
                        {
                            "id": 129441,
                            "name": "Winter Park",
                            "latitude": "28.60000000",
                            "longitude": "-81.33924000"
                        },
                        {
                            "id": 129442,
                            "name": "Winter Springs",
                            "latitude": "28.69889000",
                            "longitude": "-81.30812000"
                        },
                        {
                            "id": 129538,
                            "name": "Woodlawn Beach",
                            "latitude": "30.38825000",
                            "longitude": "-86.99080000"
                        },
                        {
                            "id": 129570,
                            "name": "Woodville",
                            "latitude": "30.31409000",
                            "longitude": "-84.24740000"
                        },
                        {
                            "id": 129605,
                            "name": "Wright",
                            "latitude": "30.45575000",
                            "longitude": "-86.63829000"
                        },
                        {
                            "id": 129653,
                            "name": "Yalaha",
                            "latitude": "28.73860000",
                            "longitude": "-81.80869000"
                        },
                        {
                            "id": 129716,
                            "name": "Youngstown",
                            "latitude": "30.36436000",
                            "longitude": "-85.43826000"
                        },
                        {
                            "id": 129732,
                            "name": "Yulee",
                            "latitude": "30.63190000",
                            "longitude": "-81.60649000"
                        },
                        {
                            "id": 129747,
                            "name": "Zellwood",
                            "latitude": "28.73111000",
                            "longitude": "-81.60118000"
                        },
                        {
                            "id": 129749,
                            "name": "Zephyrhills",
                            "latitude": "28.23362000",
                            "longitude": "-82.18119000"
                        },
                        {
                            "id": 129750,
                            "name": "Zephyrhills North",
                            "latitude": "28.25172000",
                            "longitude": "-82.16557000"
                        },
                        {
                            "id": 129751,
                            "name": "Zephyrhills South",
                            "latitude": "28.21492000",
                            "longitude": "-82.18873000"
                        },
                        {
                            "id": 129752,
                            "name": "Zephyrhills West",
                            "latitude": "28.23081000",
                            "longitude": "-82.20556000"
                        },
                        {
                            "id": 129760,
                            "name": "Zolfo Springs",
                            "latitude": "27.49337000",
                            "longitude": "-81.79592000"
                        }
                    ]
                },
                {
                    "id": 1455,
                    "name": "Georgia",
                    "state_code": "GA",
                    "cities": [
                        {
                            "id": 110965,
                            "name": "Abbeville",
                            "latitude": "31.99212000",
                            "longitude": "-83.30682000"
                        },
                        {
                            "id": 111004,
                            "name": "Acworth",
                            "latitude": "34.06635000",
                            "longitude": "-84.67837000"
                        },
                        {
                            "id": 111012,
                            "name": "Adairsville",
                            "latitude": "34.36870000",
                            "longitude": "-84.93411000"
                        },
                        {
                            "id": 111041,
                            "name": "Adel",
                            "latitude": "31.13727000",
                            "longitude": "-83.42408000"
                        },
                        {
                            "id": 111090,
                            "name": "Alamo",
                            "latitude": "32.14712000",
                            "longitude": "-82.77792000"
                        },
                        {
                            "id": 111101,
                            "name": "Albany",
                            "latitude": "31.57851000",
                            "longitude": "-84.15574000"
                        },
                        {
                            "id": 111207,
                            "name": "Alma",
                            "latitude": "31.53937000",
                            "longitude": "-82.46236000"
                        },
                        {
                            "id": 111221,
                            "name": "Alpharetta",
                            "latitude": "34.07538000",
                            "longitude": "-84.29409000"
                        },
                        {
                            "id": 111239,
                            "name": "Alto",
                            "latitude": "34.46732000",
                            "longitude": "-83.57378000"
                        },
                        {
                            "id": 111274,
                            "name": "Americus",
                            "latitude": "32.07239000",
                            "longitude": "-84.23269000"
                        },
                        {
                            "id": 111400,
                            "name": "Appling County",
                            "latitude": "31.74928000",
                            "longitude": "-82.28898000"
                        },
                        {
                            "id": 111409,
                            "name": "Aragon",
                            "latitude": "34.04565000",
                            "longitude": "-85.05606000"
                        },
                        {
                            "id": 111418,
                            "name": "Arcade",
                            "latitude": "34.07789000",
                            "longitude": "-83.56155000"
                        },
                        {
                            "id": 111460,
                            "name": "Arlington",
                            "latitude": "31.43990000",
                            "longitude": "-84.72492000"
                        },
                        {
                            "id": 111513,
                            "name": "Ashburn",
                            "latitude": "31.70601000",
                            "longitude": "-83.65322000"
                        },
                        {
                            "id": 111573,
                            "name": "Athens",
                            "latitude": "33.96095000",
                            "longitude": "-83.37794000"
                        },
                        {
                            "id": 111589,
                            "name": "Atkinson County",
                            "latitude": "31.29711000",
                            "longitude": "-82.87999000"
                        },
                        {
                            "id": 111590,
                            "name": "Atlanta",
                            "latitude": "33.74900000",
                            "longitude": "-84.38798000"
                        },
                        {
                            "id": 111619,
                            "name": "Auburn",
                            "latitude": "34.01372000",
                            "longitude": "-83.82768000"
                        },
                        {
                            "id": 111646,
                            "name": "Augusta",
                            "latitude": "33.47097000",
                            "longitude": "-81.97484000"
                        },
                        {
                            "id": 111665,
                            "name": "Austell",
                            "latitude": "33.81261000",
                            "longitude": "-84.63438000"
                        },
                        {
                            "id": 111702,
                            "name": "Avondale Estates",
                            "latitude": "33.77149000",
                            "longitude": "-84.26714000"
                        },
                        {
                            "id": 111720,
                            "name": "Bacon County",
                            "latitude": "31.55367000",
                            "longitude": "-82.45269000"
                        },
                        {
                            "id": 111732,
                            "name": "Bainbridge",
                            "latitude": "30.90380000",
                            "longitude": "-84.57547000"
                        },
                        {
                            "id": 111742,
                            "name": "Baker County",
                            "latitude": "31.32618000",
                            "longitude": "-84.44467000"
                        },
                        {
                            "id": 111752,
                            "name": "Baldwin",
                            "latitude": "34.49177000",
                            "longitude": "-83.53739000"
                        },
                        {
                            "id": 111760,
                            "name": "Baldwin County",
                            "latitude": "33.06928000",
                            "longitude": "-83.24959000"
                        },
                        {
                            "id": 111769,
                            "name": "Ball Ground",
                            "latitude": "34.33815000",
                            "longitude": "-84.37659000"
                        },
                        {
                            "id": 111798,
                            "name": "Banks County",
                            "latitude": "34.35413000",
                            "longitude": "-83.49737000"
                        },
                        {
                            "id": 111829,
                            "name": "Barnesville",
                            "latitude": "33.05457000",
                            "longitude": "-84.15575000"
                        },
                        {
                            "id": 111853,
                            "name": "Barrow County",
                            "latitude": "33.99320000",
                            "longitude": "-83.71276000"
                        },
                        {
                            "id": 111873,
                            "name": "Bartow County",
                            "latitude": "34.23786000",
                            "longitude": "-84.84050000"
                        },
                        {
                            "id": 111913,
                            "name": "Baxley",
                            "latitude": "31.77825000",
                            "longitude": "-82.34846000"
                        },
                        {
                            "id": 112154,
                            "name": "Belvedere Park",
                            "latitude": "33.75483000",
                            "longitude": "-84.26742000"
                        },
                        {
                            "id": 112162,
                            "name": "Ben Hill County",
                            "latitude": "31.75978000",
                            "longitude": "-83.22046000"
                        },
                        {
                            "id": 112227,
                            "name": "Berkeley Lake",
                            "latitude": "33.98371000",
                            "longitude": "-84.18658000"
                        },
                        {
                            "id": 112249,
                            "name": "Berrien County",
                            "latitude": "31.27606000",
                            "longitude": "-83.22962000"
                        },
                        {
                            "id": 112310,
                            "name": "Bibb County",
                            "latitude": "32.80659000",
                            "longitude": "-83.69776000"
                        },
                        {
                            "id": 112391,
                            "name": "Blackshear",
                            "latitude": "31.30605000",
                            "longitude": "-82.24207000"
                        },
                        {
                            "id": 112412,
                            "name": "Blairsville",
                            "latitude": "34.87620000",
                            "longitude": "-83.95824000"
                        },
                        {
                            "id": 112414,
                            "name": "Blakely",
                            "latitude": "31.37768000",
                            "longitude": "-84.93409000"
                        },
                        {
                            "id": 112427,
                            "name": "Bleckley County",
                            "latitude": "32.43444000",
                            "longitude": "-83.32784000"
                        },
                        {
                            "id": 112443,
                            "name": "Bloomingdale",
                            "latitude": "32.13242000",
                            "longitude": "-81.29900000"
                        },
                        {
                            "id": 112475,
                            "name": "Blue Ridge",
                            "latitude": "34.86397000",
                            "longitude": "-84.32409000"
                        },
                        {
                            "id": 112499,
                            "name": "Bogart",
                            "latitude": "33.94928000",
                            "longitude": "-83.53461000"
                        },
                        {
                            "id": 112530,
                            "name": "Bonanza",
                            "latitude": "33.46567000",
                            "longitude": "-84.33659000"
                        },
                        {
                            "id": 112588,
                            "name": "Boston",
                            "latitude": "30.79186000",
                            "longitude": "-83.78989000"
                        },
                        {
                            "id": 112622,
                            "name": "Bowdon",
                            "latitude": "33.53789000",
                            "longitude": "-85.25328000"
                        },
                        {
                            "id": 112696,
                            "name": "Brantley County",
                            "latitude": "31.19688000",
                            "longitude": "-81.98190000"
                        },
                        {
                            "id": 112697,
                            "name": "Braselton",
                            "latitude": "34.10927000",
                            "longitude": "-83.76267000"
                        },
                        {
                            "id": 112720,
                            "name": "Bremen",
                            "latitude": "33.72122000",
                            "longitude": "-85.14550000"
                        },
                        {
                            "id": 112856,
                            "name": "Brookhaven",
                            "latitude": "33.85844000",
                            "longitude": "-84.34020000"
                        },
                        {
                            "id": 112866,
                            "name": "Brooklet",
                            "latitude": "32.37963000",
                            "longitude": "-81.66317000"
                        },
                        {
                            "id": 112883,
                            "name": "Brooks County",
                            "latitude": "30.84197000",
                            "longitude": "-83.58021000"
                        },
                        {
                            "id": 112941,
                            "name": "Broxton",
                            "latitude": "31.62519000",
                            "longitude": "-82.88681000"
                        },
                        {
                            "id": 112947,
                            "name": "Brunswick",
                            "latitude": "31.14995000",
                            "longitude": "-81.49149000"
                        },
                        {
                            "id": 112960,
                            "name": "Bryan County",
                            "latitude": "32.01322000",
                            "longitude": "-81.44247000"
                        },
                        {
                            "id": 112969,
                            "name": "Buchanan",
                            "latitude": "33.80260000",
                            "longitude": "-85.18856000"
                        },
                        {
                            "id": 113000,
                            "name": "Buena Vista",
                            "latitude": "32.31904000",
                            "longitude": "-84.51714000"
                        },
                        {
                            "id": 113022,
                            "name": "Buford",
                            "latitude": "34.12066000",
                            "longitude": "-84.00435000"
                        },
                        {
                            "id": 113033,
                            "name": "Bulloch County",
                            "latitude": "32.39681000",
                            "longitude": "-81.74318000"
                        },
                        {
                            "id": 113056,
                            "name": "Burke County",
                            "latitude": "33.06115000",
                            "longitude": "-82.00078000"
                        },
                        {
                            "id": 113107,
                            "name": "Butler",
                            "latitude": "32.55709000",
                            "longitude": "-84.23825000"
                        },
                        {
                            "id": 113130,
                            "name": "Butts County",
                            "latitude": "33.28785000",
                            "longitude": "-83.95717000"
                        },
                        {
                            "id": 113143,
                            "name": "Byron",
                            "latitude": "32.65376000",
                            "longitude": "-83.75963000"
                        },
                        {
                            "id": 113169,
                            "name": "Cairo",
                            "latitude": "30.87751000",
                            "longitude": "-84.20214000"
                        },
                        {
                            "id": 113197,
                            "name": "Calhoun",
                            "latitude": "34.50259000",
                            "longitude": "-84.95105000"
                        },
                        {
                            "id": 113203,
                            "name": "Calhoun County",
                            "latitude": "31.52920000",
                            "longitude": "-84.62451000"
                        },
                        {
                            "id": 113268,
                            "name": "Camden County",
                            "latitude": "30.92249000",
                            "longitude": "-81.63639000"
                        },
                        {
                            "id": 113283,
                            "name": "Camilla",
                            "latitude": "31.23129000",
                            "longitude": "-84.21046000"
                        },
                        {
                            "id": 113324,
                            "name": "Candler County",
                            "latitude": "32.40344000",
                            "longitude": "-82.07367000"
                        },
                        {
                            "id": 113325,
                            "name": "Candler-McAfee",
                            "latitude": "33.72672000",
                            "longitude": "-84.27246000"
                        },
                        {
                            "id": 113339,
                            "name": "Canton",
                            "latitude": "34.23676000",
                            "longitude": "-84.49076000"
                        },
                        {
                            "id": 113427,
                            "name": "Carnesville",
                            "latitude": "34.36983000",
                            "longitude": "-83.23516000"
                        },
                        {
                            "id": 113451,
                            "name": "Carroll County",
                            "latitude": "33.58282000",
                            "longitude": "-85.07974000"
                        },
                        {
                            "id": 113464,
                            "name": "Carrollton",
                            "latitude": "33.58011000",
                            "longitude": "-85.07661000"
                        },
                        {
                            "id": 113488,
                            "name": "Cartersville",
                            "latitude": "34.16533000",
                            "longitude": "-84.80231000"
                        },
                        {
                            "id": 113577,
                            "name": "Catoosa County",
                            "latitude": "34.90366000",
                            "longitude": "-85.13825000"
                        },
                        {
                            "id": 113587,
                            "name": "Cave Spring",
                            "latitude": "34.10760000",
                            "longitude": "-85.33634000"
                        },
                        {
                            "id": 113627,
                            "name": "Cedartown",
                            "latitude": "34.01123000",
                            "longitude": "-85.25593000"
                        },
                        {
                            "id": 113652,
                            "name": "Centerville",
                            "latitude": "32.63014000",
                            "longitude": "-83.68963000"
                        },
                        {
                            "id": 113723,
                            "name": "Chamblee",
                            "latitude": "33.89205000",
                            "longitude": "-84.29881000"
                        },
                        {
                            "id": 113789,
                            "name": "Charlton County",
                            "latitude": "30.78172000",
                            "longitude": "-82.13769000"
                        },
                        {
                            "id": 113801,
                            "name": "Chatham County",
                            "latitude": "31.97402000",
                            "longitude": "-81.09243000"
                        },
                        {
                            "id": 113805,
                            "name": "Chatsworth",
                            "latitude": "34.76591000",
                            "longitude": "-84.76994000"
                        },
                        {
                            "id": 113809,
                            "name": "Chattahoochee County",
                            "latitude": "32.34697000",
                            "longitude": "-84.78705000"
                        },
                        {
                            "id": 113810,
                            "name": "Chattahoochee Hills",
                            "latitude": "33.55063000",
                            "longitude": "-84.76049000"
                        },
                        {
                            "id": 113812,
                            "name": "Chattanooga Valley",
                            "latitude": "34.93285000",
                            "longitude": "-85.35551000"
                        },
                        {
                            "id": 113813,
                            "name": "Chattooga County",
                            "latitude": "34.47502000",
                            "longitude": "-85.34529000"
                        },
                        {
                            "id": 113850,
                            "name": "Cherokee County",
                            "latitude": "34.24393000",
                            "longitude": "-84.47620000"
                        },
                        {
                            "id": 113881,
                            "name": "Chester",
                            "latitude": "32.39378000",
                            "longitude": "-83.15293000"
                        },
                        {
                            "id": 113937,
                            "name": "Chickamauga",
                            "latitude": "34.87119000",
                            "longitude": "-85.29079000"
                        },
                        {
                            "id": 114135,
                            "name": "Clarke County",
                            "latitude": "33.95117000",
                            "longitude": "-83.36733000"
                        },
                        {
                            "id": 114139,
                            "name": "Clarkesville",
                            "latitude": "34.61260000",
                            "longitude": "-83.52489000"
                        },
                        {
                            "id": 114148,
                            "name": "Clarkston",
                            "latitude": "33.80955000",
                            "longitude": "-84.23964000"
                        },
                        {
                            "id": 114164,
                            "name": "Claxton",
                            "latitude": "32.16158000",
                            "longitude": "-81.90400000"
                        },
                        {
                            "id": 114176,
                            "name": "Clay County",
                            "latitude": "31.62628000",
                            "longitude": "-84.98010000"
                        },
                        {
                            "id": 114198,
                            "name": "Clayton",
                            "latitude": "34.87815000",
                            "longitude": "-83.40099000"
                        },
                        {
                            "id": 114205,
                            "name": "Clayton County",
                            "latitude": "33.54189000",
                            "longitude": "-84.35769000"
                        },
                        {
                            "id": 114242,
                            "name": "Cleveland",
                            "latitude": "34.59704000",
                            "longitude": "-83.76324000"
                        },
                        {
                            "id": 114265,
                            "name": "Clinch County",
                            "latitude": "30.91495000",
                            "longitude": "-82.70624000"
                        },
                        {
                            "id": 114344,
                            "name": "Cobb County",
                            "latitude": "33.94147000",
                            "longitude": "-84.57667000"
                        },
                        {
                            "id": 114351,
                            "name": "Cochran",
                            "latitude": "32.38683000",
                            "longitude": "-83.35461000"
                        },
                        {
                            "id": 114368,
                            "name": "Coffee County",
                            "latitude": "31.54927000",
                            "longitude": "-82.84920000"
                        },
                        {
                            "id": 114413,
                            "name": "College Park",
                            "latitude": "33.65344000",
                            "longitude": "-84.44937000"
                        },
                        {
                            "id": 114459,
                            "name": "Colquitt",
                            "latitude": "31.17129000",
                            "longitude": "-84.73325000"
                        },
                        {
                            "id": 114460,
                            "name": "Colquitt County",
                            "latitude": "31.18839000",
                            "longitude": "-83.76885000"
                        },
                        {
                            "id": 114479,
                            "name": "Columbia County",
                            "latitude": "33.54412000",
                            "longitude": "-82.26406000"
                        },
                        {
                            "id": 114491,
                            "name": "Columbus",
                            "latitude": "32.46098000",
                            "longitude": "-84.98771000"
                        },
                        {
                            "id": 114519,
                            "name": "Comer",
                            "latitude": "34.06372000",
                            "longitude": "-83.12543000"
                        },
                        {
                            "id": 114522,
                            "name": "Commerce",
                            "latitude": "34.20400000",
                            "longitude": "-83.45711000"
                        },
                        {
                            "id": 114559,
                            "name": "Conley",
                            "latitude": "33.64483000",
                            "longitude": "-84.32576000"
                        },
                        {
                            "id": 114589,
                            "name": "Conyers",
                            "latitude": "33.66761000",
                            "longitude": "-84.01769000"
                        },
                        {
                            "id": 114591,
                            "name": "Cook County",
                            "latitude": "31.15399000",
                            "longitude": "-83.43047000"
                        },
                        {
                            "id": 114635,
                            "name": "Cordele",
                            "latitude": "31.96351000",
                            "longitude": "-83.78239000"
                        },
                        {
                            "id": 114646,
                            "name": "Cornelia",
                            "latitude": "34.51149000",
                            "longitude": "-83.52712000"
                        },
                        {
                            "id": 114716,
                            "name": "Country Club Estates",
                            "latitude": "31.21273000",
                            "longitude": "-81.46427000"
                        },
                        {
                            "id": 114735,
                            "name": "Covington",
                            "latitude": "33.59678000",
                            "longitude": "-83.86018000"
                        },
                        {
                            "id": 114747,
                            "name": "Coweta County",
                            "latitude": "33.35346000",
                            "longitude": "-84.76337000"
                        },
                        {
                            "id": 114775,
                            "name": "Crawford County",
                            "latitude": "32.71450000",
                            "longitude": "-83.98634000"
                        },
                        {
                            "id": 114786,
                            "name": "Crawfordville",
                            "latitude": "33.55402000",
                            "longitude": "-82.89598000"
                        },
                        {
                            "id": 114823,
                            "name": "Crisp County",
                            "latitude": "31.92293000",
                            "longitude": "-83.76810000"
                        },
                        {
                            "id": 114923,
                            "name": "Cumming",
                            "latitude": "34.20732000",
                            "longitude": "-84.14019000"
                        },
                        {
                            "id": 114933,
                            "name": "Cusseta",
                            "latitude": "32.30543000",
                            "longitude": "-84.77270000"
                        },
                        {
                            "id": 114945,
                            "name": "Cuthbert",
                            "latitude": "31.77127000",
                            "longitude": "-84.78937000"
                        },
                        {
                            "id": 114963,
                            "name": "Dacula",
                            "latitude": "33.98872000",
                            "longitude": "-83.89796000"
                        },
                        {
                            "id": 114966,
                            "name": "Dade County",
                            "latitude": "34.85452000",
                            "longitude": "-85.50453000"
                        },
                        {
                            "id": 114971,
                            "name": "Dahlonega",
                            "latitude": "34.53259000",
                            "longitude": "-83.98491000"
                        },
                        {
                            "id": 114988,
                            "name": "Dallas",
                            "latitude": "33.92371000",
                            "longitude": "-84.84077000"
                        },
                        {
                            "id": 115001,
                            "name": "Dalton",
                            "latitude": "34.76980000",
                            "longitude": "-84.97022000"
                        },
                        {
                            "id": 115026,
                            "name": "Danielsville",
                            "latitude": "34.12428000",
                            "longitude": "-83.22126000"
                        },
                        {
                            "id": 115045,
                            "name": "Darien",
                            "latitude": "31.37023000",
                            "longitude": "-81.43399000"
                        },
                        {
                            "id": 115077,
                            "name": "Davisboro",
                            "latitude": "32.97904000",
                            "longitude": "-82.60791000"
                        },
                        {
                            "id": 115081,
                            "name": "Dawson",
                            "latitude": "31.77382000",
                            "longitude": "-84.44800000"
                        },
                        {
                            "id": 115083,
                            "name": "Dawson County",
                            "latitude": "34.44430000",
                            "longitude": "-84.17064000"
                        },
                        {
                            "id": 115088,
                            "name": "Dawsonville",
                            "latitude": "34.42121000",
                            "longitude": "-84.11908000"
                        },
                        {
                            "id": 115152,
                            "name": "Decatur",
                            "latitude": "33.77483000",
                            "longitude": "-84.29631000"
                        },
                        {
                            "id": 115159,
                            "name": "Decatur County",
                            "latitude": "30.87834000",
                            "longitude": "-84.57907000"
                        },
                        {
                            "id": 115169,
                            "name": "Deenwood",
                            "latitude": "31.22201000",
                            "longitude": "-82.38725000"
                        },
                        {
                            "id": 115129,
                            "name": "DeKalb County",
                            "latitude": "33.77153000",
                            "longitude": "-84.22641000"
                        },
                        {
                            "id": 115233,
                            "name": "Demorest",
                            "latitude": "34.56510000",
                            "longitude": "-83.54517000"
                        },
                        {
                            "id": 115372,
                            "name": "Dock Junction",
                            "latitude": "31.20245000",
                            "longitude": "-81.51677000"
                        },
                        {
                            "id": 115377,
                            "name": "Dodge County",
                            "latitude": "32.17218000",
                            "longitude": "-83.16840000"
                        },
                        {
                            "id": 115395,
                            "name": "Donalsonville",
                            "latitude": "31.04046000",
                            "longitude": "-84.87909000"
                        },
                        {
                            "id": 115404,
                            "name": "Dooly County",
                            "latitude": "32.15718000",
                            "longitude": "-83.79875000"
                        },
                        {
                            "id": 115409,
                            "name": "Doraville",
                            "latitude": "33.89816000",
                            "longitude": "-84.28326000"
                        },
                        {
                            "id": 115418,
                            "name": "Dougherty County",
                            "latitude": "31.53337000",
                            "longitude": "-84.21625000"
                        },
                        {
                            "id": 115419,
                            "name": "Douglas",
                            "latitude": "31.50881000",
                            "longitude": "-82.84987000"
                        },
                        {
                            "id": 115425,
                            "name": "Douglas County",
                            "latitude": "33.70182000",
                            "longitude": "-84.76793000"
                        },
                        {
                            "id": 115440,
                            "name": "Douglasville",
                            "latitude": "33.75150000",
                            "longitude": "-84.74771000"
                        },
                        {
                            "id": 115480,
                            "name": "Druid Hills",
                            "latitude": "33.78038000",
                            "longitude": "-84.33604000"
                        },
                        {
                            "id": 115491,
                            "name": "Dublin",
                            "latitude": "32.54044000",
                            "longitude": "-82.90375000"
                        },
                        {
                            "id": 115512,
                            "name": "Duluth",
                            "latitude": "34.00288000",
                            "longitude": "-84.14464000"
                        },
                        {
                            "id": 115555,
                            "name": "Dunwoody",
                            "latitude": "33.94621000",
                            "longitude": "-84.33465000"
                        },
                        {
                            "id": 115623,
                            "name": "Early County",
                            "latitude": "31.32283000",
                            "longitude": "-84.90364000"
                        },
                        {
                            "id": 115651,
                            "name": "East Dublin",
                            "latitude": "32.54822000",
                            "longitude": "-82.87181000"
                        },
                        {
                            "id": 115675,
                            "name": "East Griffin",
                            "latitude": "33.24373000",
                            "longitude": "-84.22881000"
                        },
                        {
                            "id": 115721,
                            "name": "East Newnan",
                            "latitude": "33.35067000",
                            "longitude": "-84.77660000"
                        },
                        {
                            "id": 115737,
                            "name": "East Point",
                            "latitude": "33.67955000",
                            "longitude": "-84.43937000"
                        },
                        {
                            "id": 115783,
                            "name": "Eastman",
                            "latitude": "32.19767000",
                            "longitude": "-83.17765000"
                        },
                        {
                            "id": 115806,
                            "name": "Eatonton",
                            "latitude": "33.32680000",
                            "longitude": "-83.38850000"
                        },
                        {
                            "id": 115815,
                            "name": "Echols County",
                            "latitude": "30.71009000",
                            "longitude": "-82.89394000"
                        },
                        {
                            "id": 115874,
                            "name": "Edison",
                            "latitude": "31.55823000",
                            "longitude": "-84.73825000"
                        },
                        {
                            "id": 115898,
                            "name": "Effingham County",
                            "latitude": "32.36731000",
                            "longitude": "-81.34134000"
                        },
                        {
                            "id": 115942,
                            "name": "Elbert County",
                            "latitude": "34.11679000",
                            "longitude": "-82.84010000"
                        },
                        {
                            "id": 115945,
                            "name": "Elberton",
                            "latitude": "34.11159000",
                            "longitude": "-82.86863000"
                        },
                        {
                            "id": 116016,
                            "name": "Ellaville",
                            "latitude": "32.23821000",
                            "longitude": "-84.30908000"
                        },
                        {
                            "id": 116025,
                            "name": "Ellijay",
                            "latitude": "34.69481000",
                            "longitude": "-84.48215000"
                        },
                        {
                            "id": 116088,
                            "name": "Emanuel County",
                            "latitude": "32.58976000",
                            "longitude": "-82.30171000"
                        },
                        {
                            "id": 116093,
                            "name": "Emerson",
                            "latitude": "34.12704000",
                            "longitude": "-84.75549000"
                        },
                        {
                            "id": 116139,
                            "name": "Enigma",
                            "latitude": "31.41297000",
                            "longitude": "-83.32905000"
                        },
                        {
                            "id": 116227,
                            "name": "Euharlee",
                            "latitude": "34.14482000",
                            "longitude": "-84.93300000"
                        },
                        {
                            "id": 116245,
                            "name": "Evans",
                            "latitude": "33.53375000",
                            "longitude": "-82.13067000"
                        },
                        {
                            "id": 116248,
                            "name": "Evans County",
                            "latitude": "32.15676000",
                            "longitude": "-81.88688000"
                        },
                        {
                            "id": 116276,
                            "name": "Experiment",
                            "latitude": "33.26539000",
                            "longitude": "-84.28159000"
                        },
                        {
                            "id": 116285,
                            "name": "Fair Oaks",
                            "latitude": "33.91649000",
                            "longitude": "-84.54465000"
                        },
                        {
                            "id": 116293,
                            "name": "Fairburn",
                            "latitude": "33.56706000",
                            "longitude": "-84.58104000"
                        },
                        {
                            "id": 116347,
                            "name": "Fairview",
                            "latitude": "34.94563000",
                            "longitude": "-85.28440000"
                        },
                        {
                            "id": 116392,
                            "name": "Fannin County",
                            "latitude": "34.86411000",
                            "longitude": "-84.31985000"
                        },
                        {
                            "id": 116444,
                            "name": "Fayette County",
                            "latitude": "33.41394000",
                            "longitude": "-84.49419000"
                        },
                        {
                            "id": 116455,
                            "name": "Fayetteville",
                            "latitude": "33.44873000",
                            "longitude": "-84.45493000"
                        },
                        {
                            "id": 116522,
                            "name": "Firing Range",
                            "latitude": "33.43752000",
                            "longitude": "-82.69068000"
                        },
                        {
                            "id": 116536,
                            "name": "Fitzgerald",
                            "latitude": "31.71491000",
                            "longitude": "-83.25265000"
                        },
                        {
                            "id": 116602,
                            "name": "Flowery Branch",
                            "latitude": "34.18510000",
                            "longitude": "-83.92518000"
                        },
                        {
                            "id": 116606,
                            "name": "Floyd County",
                            "latitude": "34.26316000",
                            "longitude": "-85.21428000"
                        },
                        {
                            "id": 116621,
                            "name": "Folkston",
                            "latitude": "30.83095000",
                            "longitude": "-82.01131000"
                        },
                        {
                            "id": 116664,
                            "name": "Forest Park",
                            "latitude": "33.62205000",
                            "longitude": "-84.36909000"
                        },
                        {
                            "id": 116683,
                            "name": "Forsyth",
                            "latitude": "33.03430000",
                            "longitude": "-83.93824000"
                        },
                        {
                            "id": 116686,
                            "name": "Forsyth County",
                            "latitude": "34.22551000",
                            "longitude": "-84.12502000"
                        },
                        {
                            "id": 116711,
                            "name": "Fort Gaines",
                            "latitude": "31.60924000",
                            "longitude": "-85.04933000"
                        },
                        {
                            "id": 116737,
                            "name": "Fort Oglethorpe",
                            "latitude": "34.94896000",
                            "longitude": "-85.25690000"
                        },
                        {
                            "id": 116751,
                            "name": "Fort Stewart",
                            "latitude": "31.87217000",
                            "longitude": "-81.61001000"
                        },
                        {
                            "id": 116757,
                            "name": "Fort Valley",
                            "latitude": "32.55376000",
                            "longitude": "-83.88741000"
                        },
                        {
                            "id": 116826,
                            "name": "Franklin",
                            "latitude": "33.27762000",
                            "longitude": "-85.09800000"
                        },
                        {
                            "id": 116846,
                            "name": "Franklin County",
                            "latitude": "34.37544000",
                            "longitude": "-83.22918000"
                        },
                        {
                            "id": 116871,
                            "name": "Franklin Springs",
                            "latitude": "34.28483000",
                            "longitude": "-83.14432000"
                        },
                        {
                            "id": 116993,
                            "name": "Fulton County",
                            "latitude": "33.79025000",
                            "longitude": "-84.46702000"
                        },
                        {
                            "id": 117015,
                            "name": "Gainesville",
                            "latitude": "34.29788000",
                            "longitude": "-83.82407000"
                        },
                        {
                            "id": 117054,
                            "name": "Garden City",
                            "latitude": "32.11437000",
                            "longitude": "-81.15400000"
                        },
                        {
                            "id": 117156,
                            "name": "Georgetown",
                            "latitude": "31.98326000",
                            "longitude": "-81.22733000"
                        },
                        {
                            "id": 117187,
                            "name": "Gibson",
                            "latitude": "33.23348000",
                            "longitude": "-82.59541000"
                        },
                        {
                            "id": 117222,
                            "name": "Gilmer County",
                            "latitude": "34.69121000",
                            "longitude": "-84.45559000"
                        },
                        {
                            "id": 117243,
                            "name": "Glascock County",
                            "latitude": "33.22928000",
                            "longitude": "-82.61070000"
                        },
                        {
                            "id": 117300,
                            "name": "Glennville",
                            "latitude": "31.93659000",
                            "longitude": "-81.92845000"
                        },
                        {
                            "id": 117331,
                            "name": "Glynn County",
                            "latitude": "31.21324000",
                            "longitude": "-81.49370000"
                        },
                        {
                            "id": 117396,
                            "name": "Gordon",
                            "latitude": "32.88209000",
                            "longitude": "-83.33238000"
                        },
                        {
                            "id": 117398,
                            "name": "Gordon County",
                            "latitude": "34.50336000",
                            "longitude": "-84.87575000"
                        },
                        {
                            "id": 117426,
                            "name": "Grady County",
                            "latitude": "30.87467000",
                            "longitude": "-84.23443000"
                        },
                        {
                            "id": 117532,
                            "name": "Grantville",
                            "latitude": "33.23484000",
                            "longitude": "-84.83577000"
                        },
                        {
                            "id": 117550,
                            "name": "Gray",
                            "latitude": "33.00958000",
                            "longitude": "-83.53378000"
                        },
                        {
                            "id": 117560,
                            "name": "Grayson",
                            "latitude": "33.89427000",
                            "longitude": "-83.95574000"
                        },
                        {
                            "id": 117633,
                            "name": "Greene County",
                            "latitude": "33.57878000",
                            "longitude": "-83.16666000"
                        },
                        {
                            "id": 117665,
                            "name": "Greensboro",
                            "latitude": "33.57568000",
                            "longitude": "-83.18238000"
                        },
                        {
                            "id": 117684,
                            "name": "Greenville",
                            "latitude": "33.02874000",
                            "longitude": "-84.71298000"
                        },
                        {
                            "id": 117723,
                            "name": "Gresham Park",
                            "latitude": "33.70344000",
                            "longitude": "-84.31437000"
                        },
                        {
                            "id": 117731,
                            "name": "Griffin",
                            "latitude": "33.24678000",
                            "longitude": "-84.26409000"
                        },
                        {
                            "id": 117765,
                            "name": "Grovetown",
                            "latitude": "33.45042000",
                            "longitude": "-82.19818000"
                        },
                        {
                            "id": 117799,
                            "name": "Gumlog",
                            "latitude": "34.49177000",
                            "longitude": "-83.09654000"
                        },
                        {
                            "id": 117819,
                            "name": "Guyton",
                            "latitude": "32.33630000",
                            "longitude": "-81.39150000"
                        },
                        {
                            "id": 117821,
                            "name": "Gwinnett County",
                            "latitude": "33.96173000",
                            "longitude": "-84.02363000"
                        },
                        {
                            "id": 117824,
                            "name": "Habersham County",
                            "latitude": "34.63102000",
                            "longitude": "-83.53112000"
                        },
                        {
                            "id": 117839,
                            "name": "Hahira",
                            "latitude": "30.99131000",
                            "longitude": "-83.37266000"
                        },
                        {
                            "id": 117864,
                            "name": "Hall County",
                            "latitude": "34.31689000",
                            "longitude": "-83.81968000"
                        },
                        {
                            "id": 117888,
                            "name": "Hamilton",
                            "latitude": "32.75791000",
                            "longitude": "-84.87493000"
                        },
                        {
                            "id": 117927,
                            "name": "Hampton",
                            "latitude": "33.38706000",
                            "longitude": "-84.28298000"
                        },
                        {
                            "id": 117950,
                            "name": "Hancock County",
                            "latitude": "33.27043000",
                            "longitude": "-83.00069000"
                        },
                        {
                            "id": 117963,
                            "name": "Hannahs Mill",
                            "latitude": "32.93291000",
                            "longitude": "-84.34936000"
                        },
                        {
                            "id": 117981,
                            "name": "Hapeville",
                            "latitude": "33.66011000",
                            "longitude": "-84.41020000"
                        },
                        {
                            "id": 117985,
                            "name": "Haralson County",
                            "latitude": "33.79423000",
                            "longitude": "-85.21103000"
                        },
                        {
                            "id": 118007,
                            "name": "Hardwick",
                            "latitude": "33.06820000",
                            "longitude": "-83.22349000"
                        },
                        {
                            "id": 118022,
                            "name": "Harlem",
                            "latitude": "33.41458000",
                            "longitude": "-82.31262000"
                        },
                        {
                            "id": 118044,
                            "name": "Harris County",
                            "latitude": "32.73600000",
                            "longitude": "-84.90899000"
                        },
                        {
                            "id": 118082,
                            "name": "Hart County",
                            "latitude": "34.35090000",
                            "longitude": "-82.96425000"
                        },
                        {
                            "id": 118107,
                            "name": "Hartwell",
                            "latitude": "34.35288000",
                            "longitude": "-82.93209000"
                        },
                        {
                            "id": 118167,
                            "name": "Hawkinsville",
                            "latitude": "32.28377000",
                            "longitude": "-83.47212000"
                        },
                        {
                            "id": 118211,
                            "name": "Hazlehurst",
                            "latitude": "31.86963000",
                            "longitude": "-82.59430000"
                        },
                        {
                            "id": 118220,
                            "name": "Heard County",
                            "latitude": "33.29703000",
                            "longitude": "-85.12827000"
                        },
                        {
                            "id": 118248,
                            "name": "Helena",
                            "latitude": "32.07379000",
                            "longitude": "-82.91459000"
                        },
                        {
                            "id": 118279,
                            "name": "Henderson",
                            "latitude": "32.00813000",
                            "longitude": "-81.25887000"
                        },
                        {
                            "id": 118298,
                            "name": "Henry County",
                            "latitude": "33.45300000",
                            "longitude": "-84.15420000"
                        },
                        {
                            "id": 118309,
                            "name": "Hephzibah",
                            "latitude": "33.31403000",
                            "longitude": "-82.09679000"
                        },
                        {
                            "id": 118356,
                            "name": "Hiawassee",
                            "latitude": "34.94926000",
                            "longitude": "-83.75739000"
                        },
                        {
                            "id": 118482,
                            "name": "Hinesville",
                            "latitude": "31.84688000",
                            "longitude": "-81.59595000"
                        },
                        {
                            "id": 118490,
                            "name": "Hiram",
                            "latitude": "33.87566000",
                            "longitude": "-84.76216000"
                        },
                        {
                            "id": 118511,
                            "name": "Hogansville",
                            "latitude": "33.17318000",
                            "longitude": "-84.91494000"
                        },
                        {
                            "id": 118561,
                            "name": "Holly Springs",
                            "latitude": "34.17399000",
                            "longitude": "-84.50132000"
                        },
                        {
                            "id": 118592,
                            "name": "Homer",
                            "latitude": "34.33372000",
                            "longitude": "-83.49905000"
                        },
                        {
                            "id": 118600,
                            "name": "Homerville",
                            "latitude": "31.03660000",
                            "longitude": "-82.74708000"
                        },
                        {
                            "id": 118680,
                            "name": "Hoschton",
                            "latitude": "34.09650000",
                            "longitude": "-83.76128000"
                        },
                        {
                            "id": 118703,
                            "name": "Houston County",
                            "latitude": "32.45901000",
                            "longitude": "-83.66624000"
                        },
                        {
                            "id": 118918,
                            "name": "Indian Springs",
                            "latitude": "34.95785000",
                            "longitude": "-85.16440000"
                        },
                        {
                            "id": 118995,
                            "name": "Irondale",
                            "latitude": "33.48067000",
                            "longitude": "-84.35881000"
                        },
                        {
                            "id": 119013,
                            "name": "Irwin County",
                            "latitude": "31.60228000",
                            "longitude": "-83.27638000"
                        },
                        {
                            "id": 119015,
                            "name": "Irwinton",
                            "latitude": "32.81126000",
                            "longitude": "-83.17265000"
                        },
                        {
                            "id": 119030,
                            "name": "Isle of Hope",
                            "latitude": "31.98188000",
                            "longitude": "-81.06094000"
                        },
                        {
                            "id": 119061,
                            "name": "Jackson",
                            "latitude": "33.29457000",
                            "longitude": "-83.96602000"
                        },
                        {
                            "id": 119078,
                            "name": "Jackson County",
                            "latitude": "34.13388000",
                            "longitude": "-83.56635000"
                        },
                        {
                            "id": 119142,
                            "name": "Jasper",
                            "latitude": "34.46787000",
                            "longitude": "-84.42909000"
                        },
                        {
                            "id": 119145,
                            "name": "Jasper County",
                            "latitude": "33.31643000",
                            "longitude": "-83.68809000"
                        },
                        {
                            "id": 119160,
                            "name": "Jeff Davis County",
                            "latitude": "31.80560000",
                            "longitude": "-82.63694000"
                        },
                        {
                            "id": 119162,
                            "name": "Jefferson",
                            "latitude": "34.11705000",
                            "longitude": "-83.57239000"
                        },
                        {
                            "id": 119178,
                            "name": "Jefferson County",
                            "latitude": "33.05484000",
                            "longitude": "-82.41815000"
                        },
                        {
                            "id": 119207,
                            "name": "Jeffersonville",
                            "latitude": "32.68765000",
                            "longitude": "-83.34656000"
                        },
                        {
                            "id": 119215,
                            "name": "Jenkins County",
                            "latitude": "32.79247000",
                            "longitude": "-81.96353000"
                        },
                        {
                            "id": 119239,
                            "name": "Jesup",
                            "latitude": "31.60785000",
                            "longitude": "-81.88634000"
                        },
                        {
                            "id": 119252,
                            "name": "Johns Creek",
                            "latitude": "34.02893000",
                            "longitude": "-84.19858000"
                        },
                        {
                            "id": 119261,
                            "name": "Johnson County",
                            "latitude": "32.70146000",
                            "longitude": "-82.66008000"
                        },
                        {
                            "id": 119292,
                            "name": "Jones County",
                            "latitude": "33.02513000",
                            "longitude": "-83.56052000"
                        },
                        {
                            "id": 119300,
                            "name": "Jonesboro",
                            "latitude": "33.52150000",
                            "longitude": "-84.35381000"
                        },
                        {
                            "id": 119471,
                            "name": "Kennesaw",
                            "latitude": "34.02343000",
                            "longitude": "-84.61549000"
                        },
                        {
                            "id": 119597,
                            "name": "Kings Bay Base",
                            "latitude": "30.79836000",
                            "longitude": "-81.56589000"
                        },
                        {
                            "id": 119615,
                            "name": "Kingsland",
                            "latitude": "30.79996000",
                            "longitude": "-81.68983000"
                        },
                        {
                            "id": 119693,
                            "name": "Knoxville",
                            "latitude": "32.72431000",
                            "longitude": "-83.99769000"
                        },
                        {
                            "id": 119799,
                            "name": "LaFayette",
                            "latitude": "34.70480000",
                            "longitude": "-85.28190000"
                        },
                        {
                            "id": 119801,
                            "name": "LaGrange",
                            "latitude": "33.03929000",
                            "longitude": "-85.03133000"
                        },
                        {
                            "id": 119880,
                            "name": "Lake City",
                            "latitude": "33.60650000",
                            "longitude": "-84.33520000"
                        },
                        {
                            "id": 120013,
                            "name": "Lakeland",
                            "latitude": "31.04104000",
                            "longitude": "-83.07515000"
                        },
                        {
                            "id": 120037,
                            "name": "Lakeview",
                            "latitude": "34.97924000",
                            "longitude": "-85.25884000"
                        },
                        {
                            "id": 120042,
                            "name": "Lakeview Estates",
                            "latitude": "33.70678000",
                            "longitude": "-84.03158000"
                        },
                        {
                            "id": 120062,
                            "name": "Lamar County",
                            "latitude": "33.07654000",
                            "longitude": "-84.13946000"
                        },
                        {
                            "id": 120119,
                            "name": "Lanier County",
                            "latitude": "31.03789000",
                            "longitude": "-83.06265000"
                        },
                        {
                            "id": 120208,
                            "name": "Laurens County",
                            "latitude": "32.46366000",
                            "longitude": "-82.92224000"
                        },
                        {
                            "id": 120220,
                            "name": "Lavonia",
                            "latitude": "34.43594000",
                            "longitude": "-83.10682000"
                        },
                        {
                            "id": 120243,
                            "name": "Lawrenceville",
                            "latitude": "33.95621000",
                            "longitude": "-83.98796000"
                        },
                        {
                            "id": 120309,
                            "name": "Lee County",
                            "latitude": "31.77951000",
                            "longitude": "-84.14113000"
                        },
                        {
                            "id": 120325,
                            "name": "Leesburg",
                            "latitude": "31.73212000",
                            "longitude": "-84.17074000"
                        },
                        {
                            "id": 120438,
                            "name": "Lexington",
                            "latitude": "33.86984000",
                            "longitude": "-83.11182000"
                        },
                        {
                            "id": 120471,
                            "name": "Liberty County",
                            "latitude": "31.80723000",
                            "longitude": "-81.45626000"
                        },
                        {
                            "id": 120485,
                            "name": "Lilburn",
                            "latitude": "33.89010000",
                            "longitude": "-84.14297000"
                        },
                        {
                            "id": 120517,
                            "name": "Lincoln County",
                            "latitude": "33.79366000",
                            "longitude": "-82.45121000"
                        },
                        {
                            "id": 120551,
                            "name": "Lincolnton",
                            "latitude": "33.79235000",
                            "longitude": "-82.47902000"
                        },
                        {
                            "id": 120558,
                            "name": "Lindale",
                            "latitude": "34.18676000",
                            "longitude": "-85.17467000"
                        },
                        {
                            "id": 120610,
                            "name": "Lithia Springs",
                            "latitude": "33.79400000",
                            "longitude": "-84.66049000"
                        },
                        {
                            "id": 120611,
                            "name": "Lithonia",
                            "latitude": "33.71233000",
                            "longitude": "-84.10519000"
                        },
                        {
                            "id": 120686,
                            "name": "Locust Grove",
                            "latitude": "33.34595000",
                            "longitude": "-84.10908000"
                        },
                        {
                            "id": 120708,
                            "name": "Loganville",
                            "latitude": "33.83900000",
                            "longitude": "-83.90074000"
                        },
                        {
                            "id": 120741,
                            "name": "Long County",
                            "latitude": "31.75258000",
                            "longitude": "-81.74577000"
                        },
                        {
                            "id": 120765,
                            "name": "Lookout Mountain",
                            "latitude": "34.97758000",
                            "longitude": "-85.35774000"
                        },
                        {
                            "id": 120811,
                            "name": "Louisville",
                            "latitude": "33.00154000",
                            "longitude": "-82.41124000"
                        },
                        {
                            "id": 120821,
                            "name": "Lovejoy",
                            "latitude": "33.43622000",
                            "longitude": "-84.31437000"
                        },
                        {
                            "id": 120847,
                            "name": "Lowndes County",
                            "latitude": "30.83386000",
                            "longitude": "-83.26771000"
                        },
                        {
                            "id": 120871,
                            "name": "Ludowici",
                            "latitude": "31.70799000",
                            "longitude": "-81.74234000"
                        },
                        {
                            "id": 120875,
                            "name": "Lula",
                            "latitude": "34.38760000",
                            "longitude": "-83.66629000"
                        },
                        {
                            "id": 120878,
                            "name": "Lumber City",
                            "latitude": "31.92935000",
                            "longitude": "-82.67958000"
                        },
                        {
                            "id": 120882,
                            "name": "Lumpkin",
                            "latitude": "32.05099000",
                            "longitude": "-84.79909000"
                        },
                        {
                            "id": 120883,
                            "name": "Lumpkin County",
                            "latitude": "34.57219000",
                            "longitude": "-84.00265000"
                        },
                        {
                            "id": 120940,
                            "name": "Lyons",
                            "latitude": "32.20435000",
                            "longitude": "-82.32179000"
                        },
                        {
                            "id": 120949,
                            "name": "Mableton",
                            "latitude": "33.81872000",
                            "longitude": "-84.58243000"
                        },
                        {
                            "id": 120964,
                            "name": "Macon",
                            "latitude": "32.84069000",
                            "longitude": "-83.63240000"
                        },
                        {
                            "id": 120968,
                            "name": "Macon County",
                            "latitude": "32.35839000",
                            "longitude": "-84.04248000"
                        },
                        {
                            "id": 120986,
                            "name": "Madison",
                            "latitude": "33.59568000",
                            "longitude": "-83.46794000"
                        },
                        {
                            "id": 121005,
                            "name": "Madison County",
                            "latitude": "34.12778000",
                            "longitude": "-83.20904000"
                        },
                        {
                            "id": 121089,
                            "name": "Manchester",
                            "latitude": "32.85985000",
                            "longitude": "-84.61993000"
                        },
                        {
                            "id": 121216,
                            "name": "Marietta",
                            "latitude": "33.95260000",
                            "longitude": "-84.54993000"
                        },
                        {
                            "id": 121250,
                            "name": "Marion County",
                            "latitude": "32.35338000",
                            "longitude": "-84.52464000"
                        },
                        {
                            "id": 121324,
                            "name": "Marshallville",
                            "latitude": "32.45626000",
                            "longitude": "-83.94019000"
                        },
                        {
                            "id": 121344,
                            "name": "Martinez",
                            "latitude": "33.51736000",
                            "longitude": "-82.07567000"
                        },
                        {
                            "id": 121448,
                            "name": "Maysville",
                            "latitude": "34.25288000",
                            "longitude": "-83.56155000"
                        },
                        {
                            "id": 121467,
                            "name": "McCaysville",
                            "latitude": "34.98619000",
                            "longitude": "-84.37131000"
                        },
                        {
                            "id": 121493,
                            "name": "McDonough",
                            "latitude": "33.44734000",
                            "longitude": "-84.14686000"
                        },
                        {
                            "id": 121497,
                            "name": "McDuffie County",
                            "latitude": "33.48285000",
                            "longitude": "-82.48137000"
                        },
                        {
                            "id": 121513,
                            "name": "McIntosh County",
                            "latitude": "31.48381000",
                            "longitude": "-81.37557000"
                        },
                        {
                            "id": 121550,
                            "name": "McRae",
                            "latitude": "32.06795000",
                            "longitude": "-82.90070000"
                        },
                        {
                            "id": 121617,
                            "name": "Meigs",
                            "latitude": "31.07241000",
                            "longitude": "-84.08907000"
                        },
                        {
                            "id": 121699,
                            "name": "Meriwether County",
                            "latitude": "33.04066000",
                            "longitude": "-84.68831000"
                        },
                        {
                            "id": 121737,
                            "name": "Metter",
                            "latitude": "32.39712000",
                            "longitude": "-82.06012000"
                        },
                        {
                            "id": 121813,
                            "name": "Midway",
                            "latitude": "31.80577000",
                            "longitude": "-81.43066000"
                        },
                        {
                            "id": 121877,
                            "name": "Milledgeville",
                            "latitude": "33.08014000",
                            "longitude": "-83.23210000"
                        },
                        {
                            "id": 121878,
                            "name": "Millen",
                            "latitude": "32.80405000",
                            "longitude": "-81.94928000"
                        },
                        {
                            "id": 121881,
                            "name": "Miller County",
                            "latitude": "31.16399000",
                            "longitude": "-84.73072000"
                        },
                        {
                            "id": 121920,
                            "name": "Milton",
                            "latitude": "34.13216000",
                            "longitude": "-84.30067000"
                        },
                        {
                            "id": 121999,
                            "name": "Mitchell County",
                            "latitude": "31.22532000",
                            "longitude": "-84.19431000"
                        },
                        {
                            "id": 122057,
                            "name": "Monroe",
                            "latitude": "33.79484000",
                            "longitude": "-83.71323000"
                        },
                        {
                            "id": 122070,
                            "name": "Monroe County",
                            "latitude": "33.01408000",
                            "longitude": "-83.91872000"
                        },
                        {
                            "id": 122127,
                            "name": "Montezuma",
                            "latitude": "32.30516000",
                            "longitude": "-84.02741000"
                        },
                        {
                            "id": 122131,
                            "name": "Montgomery",
                            "latitude": "31.94049000",
                            "longitude": "-81.12205000"
                        },
                        {
                            "id": 122141,
                            "name": "Montgomery County",
                            "latitude": "32.17336000",
                            "longitude": "-82.53482000"
                        },
                        {
                            "id": 122160,
                            "name": "Monticello",
                            "latitude": "33.30485000",
                            "longitude": "-83.68323000"
                        },
                        {
                            "id": 122233,
                            "name": "Morgan",
                            "latitude": "31.53767000",
                            "longitude": "-84.59936000"
                        },
                        {
                            "id": 122237,
                            "name": "Morgan County",
                            "latitude": "33.59083000",
                            "longitude": "-83.49238000"
                        },
                        {
                            "id": 122289,
                            "name": "Morrow",
                            "latitude": "33.58317000",
                            "longitude": "-84.33937000"
                        },
                        {
                            "id": 122316,
                            "name": "Moultrie",
                            "latitude": "31.17991000",
                            "longitude": "-83.78906000"
                        },
                        {
                            "id": 122329,
                            "name": "Mount Airy",
                            "latitude": "34.51871000",
                            "longitude": "-83.50073000"
                        },
                        {
                            "id": 122395,
                            "name": "Mount Vernon",
                            "latitude": "32.17851000",
                            "longitude": "-82.59458000"
                        },
                        {
                            "id": 122408,
                            "name": "Mount Zion",
                            "latitude": "33.63428000",
                            "longitude": "-85.18717000"
                        },
                        {
                            "id": 122411,
                            "name": "Mountain City",
                            "latitude": "34.91815000",
                            "longitude": "-83.38544000"
                        },
                        {
                            "id": 122424,
                            "name": "Mountain Park",
                            "latitude": "33.84427000",
                            "longitude": "-84.12936000"
                        },
                        {
                            "id": 122493,
                            "name": "Murray County",
                            "latitude": "34.78845000",
                            "longitude": "-84.74809000"
                        },
                        {
                            "id": 122505,
                            "name": "Muscogee County",
                            "latitude": "32.50996000",
                            "longitude": "-84.87704000"
                        },
                        {
                            "id": 122536,
                            "name": "Nahunta",
                            "latitude": "31.20439000",
                            "longitude": "-81.98123000"
                        },
                        {
                            "id": 122575,
                            "name": "Nashville",
                            "latitude": "31.20742000",
                            "longitude": "-83.25015000"
                        },
                        {
                            "id": 122624,
                            "name": "Nelson",
                            "latitude": "34.38204000",
                            "longitude": "-84.37103000"
                        },
                        {
                            "id": 122837,
                            "name": "Newnan",
                            "latitude": "33.38067000",
                            "longitude": "-84.79966000"
                        },
                        {
                            "id": 122859,
                            "name": "Newton",
                            "latitude": "31.31296000",
                            "longitude": "-84.33574000"
                        },
                        {
                            "id": 122869,
                            "name": "Newton County",
                            "latitude": "33.55505000",
                            "longitude": "-83.85019000"
                        },
                        {
                            "id": 122890,
                            "name": "Nicholls",
                            "latitude": "31.51742000",
                            "longitude": "-82.63486000"
                        },
                        {
                            "id": 122892,
                            "name": "Nicholson",
                            "latitude": "34.11400000",
                            "longitude": "-83.43155000"
                        },
                        {
                            "id": 122940,
                            "name": "Norcross",
                            "latitude": "33.94121000",
                            "longitude": "-84.21353000"
                        },
                        {
                            "id": 122966,
                            "name": "North Atlanta",
                            "latitude": "33.86510000",
                            "longitude": "-84.33659000"
                        },
                        {
                            "id": 123014,
                            "name": "North Decatur",
                            "latitude": "33.79038000",
                            "longitude": "-84.30603000"
                        },
                        {
                            "id": 123015,
                            "name": "North Druid Hills",
                            "latitude": "33.81677000",
                            "longitude": "-84.31326000"
                        },
                        {
                            "id": 123308,
                            "name": "Oakwood",
                            "latitude": "34.22760000",
                            "longitude": "-83.88435000"
                        },
                        {
                            "id": 123346,
                            "name": "Ocilla",
                            "latitude": "31.59447000",
                            "longitude": "-83.25130000"
                        },
                        {
                            "id": 123348,
                            "name": "Oconee County",
                            "latitude": "33.83494000",
                            "longitude": "-83.43705000"
                        },
                        {
                            "id": 123378,
                            "name": "Oglethorpe",
                            "latitude": "32.29377000",
                            "longitude": "-84.06102000"
                        },
                        {
                            "id": 123379,
                            "name": "Oglethorpe County",
                            "latitude": "33.88065000",
                            "longitude": "-83.08070000"
                        },
                        {
                            "id": 123465,
                            "name": "Omega",
                            "latitude": "31.34102000",
                            "longitude": "-83.59350000"
                        },
                        {
                            "id": 123676,
                            "name": "Oxford",
                            "latitude": "33.61900000",
                            "longitude": "-83.86741000"
                        },
                        {
                            "id": 123765,
                            "name": "Palmetto",
                            "latitude": "33.51789000",
                            "longitude": "-84.66965000"
                        },
                        {
                            "id": 123808,
                            "name": "Panthersville",
                            "latitude": "33.70733000",
                            "longitude": "-84.27187000"
                        },
                        {
                            "id": 123922,
                            "name": "Paulding County",
                            "latitude": "33.92055000",
                            "longitude": "-84.86729000"
                        },
                        {
                            "id": 123956,
                            "name": "Peach County",
                            "latitude": "32.56878000",
                            "longitude": "-83.82688000"
                        },
                        {
                            "id": 123959,
                            "name": "Peachtree City",
                            "latitude": "33.39678000",
                            "longitude": "-84.59576000"
                        },
                        {
                            "id": 123960,
                            "name": "Peachtree Corners",
                            "latitude": "33.97010000",
                            "longitude": "-84.22159000"
                        },
                        {
                            "id": 123973,
                            "name": "Pearson",
                            "latitude": "31.29770000",
                            "longitude": "-82.85237000"
                        },
                        {
                            "id": 123989,
                            "name": "Pelham",
                            "latitude": "31.12802000",
                            "longitude": "-84.15304000"
                        },
                        {
                            "id": 124004,
                            "name": "Pembroke",
                            "latitude": "32.13634000",
                            "longitude": "-81.62348000"
                        },
                        {
                            "id": 124068,
                            "name": "Perry",
                            "latitude": "32.45821000",
                            "longitude": "-83.73157000"
                        },
                        {
                            "id": 124157,
                            "name": "Pickens County",
                            "latitude": "34.46432000",
                            "longitude": "-84.46564000"
                        },
                        {
                            "id": 124171,
                            "name": "Pierce County",
                            "latitude": "31.35876000",
                            "longitude": "-82.21274000"
                        },
                        {
                            "id": 124187,
                            "name": "Pike County",
                            "latitude": "33.09227000",
                            "longitude": "-84.38923000"
                        },
                        {
                            "id": 124238,
                            "name": "Pine Mountain",
                            "latitude": "32.86485000",
                            "longitude": "-84.85410000"
                        },
                        {
                            "id": 124442,
                            "name": "Polk County",
                            "latitude": "34.00178000",
                            "longitude": "-85.18815000"
                        },
                        {
                            "id": 124479,
                            "name": "Pooler",
                            "latitude": "32.11548000",
                            "longitude": "-81.24706000"
                        },
                        {
                            "id": 124542,
                            "name": "Port Wentworth",
                            "latitude": "32.14909000",
                            "longitude": "-81.16317000"
                        },
                        {
                            "id": 124555,
                            "name": "Porterdale",
                            "latitude": "33.57511000",
                            "longitude": "-83.89380000"
                        },
                        {
                            "id": 124608,
                            "name": "Powder Springs",
                            "latitude": "33.85955000",
                            "longitude": "-84.68382000"
                        },
                        {
                            "id": 124653,
                            "name": "Preston",
                            "latitude": "32.06599000",
                            "longitude": "-84.53742000"
                        },
                        {
                            "id": 124738,
                            "name": "Pulaski County",
                            "latitude": "32.23230000",
                            "longitude": "-83.47596000"
                        },
                        {
                            "id": 124762,
                            "name": "Putnam County",
                            "latitude": "33.32177000",
                            "longitude": "-83.37284000"
                        },
                        {
                            "id": 124770,
                            "name": "Putney",
                            "latitude": "31.47018000",
                            "longitude": "-84.11768000"
                        },
                        {
                            "id": 124805,
                            "name": "Quitman",
                            "latitude": "30.78492000",
                            "longitude": "-83.55988000"
                        },
                        {
                            "id": 124808,
                            "name": "Quitman County",
                            "latitude": "31.86733000",
                            "longitude": "-85.01878000"
                        },
                        {
                            "id": 124810,
                            "name": "Rabun County",
                            "latitude": "34.88168000",
                            "longitude": "-83.40214000"
                        },
                        {
                            "id": 124874,
                            "name": "Randolph County",
                            "latitude": "31.76262000",
                            "longitude": "-84.75419000"
                        },
                        {
                            "id": 124892,
                            "name": "Raoul",
                            "latitude": "34.44954000",
                            "longitude": "-83.59434000"
                        },
                        {
                            "id": 124915,
                            "name": "Ray City",
                            "latitude": "31.07464000",
                            "longitude": "-83.19932000"
                        },
                        {
                            "id": 124964,
                            "name": "Redan",
                            "latitude": "33.74538000",
                            "longitude": "-84.13158000"
                        },
                        {
                            "id": 124992,
                            "name": "Reed Creek",
                            "latitude": "34.44594000",
                            "longitude": "-82.92487000"
                        },
                        {
                            "id": 125007,
                            "name": "Reidsville",
                            "latitude": "32.08686000",
                            "longitude": "-82.11790000"
                        },
                        {
                            "id": 125013,
                            "name": "Remerton",
                            "latitude": "30.84409000",
                            "longitude": "-83.31043000"
                        },
                        {
                            "id": 125039,
                            "name": "Reynolds",
                            "latitude": "32.55987000",
                            "longitude": "-84.09630000"
                        },
                        {
                            "id": 125067,
                            "name": "Richland",
                            "latitude": "32.08793000",
                            "longitude": "-84.66742000"
                        },
                        {
                            "id": 125098,
                            "name": "Richmond County",
                            "latitude": "33.35963000",
                            "longitude": "-82.07355000"
                        },
                        {
                            "id": 125104,
                            "name": "Richmond Hill",
                            "latitude": "31.93827000",
                            "longitude": "-81.30344000"
                        },
                        {
                            "id": 125139,
                            "name": "Rincon",
                            "latitude": "32.29603000",
                            "longitude": "-81.23539000"
                        },
                        {
                            "id": 125141,
                            "name": "Ringgold",
                            "latitude": "34.91591000",
                            "longitude": "-85.10912000"
                        },
                        {
                            "id": 125192,
                            "name": "Riverdale",
                            "latitude": "33.57261000",
                            "longitude": "-84.41326000"
                        },
                        {
                            "id": 125252,
                            "name": "Robins Air Force Base",
                            "latitude": "32.60911000",
                            "longitude": "-83.58444000"
                        },
                        {
                            "id": 125258,
                            "name": "Rochelle",
                            "latitude": "31.95101000",
                            "longitude": "-83.45627000"
                        },
                        {
                            "id": 125297,
                            "name": "Rockdale County",
                            "latitude": "33.65424000",
                            "longitude": "-84.02661000"
                        },
                        {
                            "id": 125314,
                            "name": "Rockmart",
                            "latitude": "34.00260000",
                            "longitude": "-85.04161000"
                        },
                        {
                            "id": 125375,
                            "name": "Rome",
                            "latitude": "34.25704000",
                            "longitude": "-85.16467000"
                        },
                        {
                            "id": 125456,
                            "name": "Rossville",
                            "latitude": "34.98313000",
                            "longitude": "-85.28607000"
                        },
                        {
                            "id": 125462,
                            "name": "Roswell",
                            "latitude": "34.02316000",
                            "longitude": "-84.36159000"
                        },
                        {
                            "id": 125500,
                            "name": "Royston",
                            "latitude": "34.28705000",
                            "longitude": "-83.11015000"
                        },
                        {
                            "id": 125532,
                            "name": "Russell",
                            "latitude": "33.97872000",
                            "longitude": "-83.70017000"
                        },
                        {
                            "id": 125560,
                            "name": "Rydal",
                            "latitude": "34.33537000",
                            "longitude": "-84.71549000"
                        },
                        {
                            "id": 125714,
                            "name": "Saint Simon Mills",
                            "latitude": "31.17079000",
                            "longitude": "-81.40732000"
                        },
                        {
                            "id": 125715,
                            "name": "Saint Simons Island",
                            "latitude": "31.15051000",
                            "longitude": "-81.36954000"
                        },
                        {
                            "id": 125865,
                            "name": "Sandersville",
                            "latitude": "32.98154000",
                            "longitude": "-82.81014000"
                        },
                        {
                            "id": 125884,
                            "name": "Sandy Springs",
                            "latitude": "33.92427000",
                            "longitude": "-84.37854000"
                        },
                        {
                            "id": 125947,
                            "name": "Sardis",
                            "latitude": "32.79652000",
                            "longitude": "-84.63881000"
                        },
                        {
                            "id": 125974,
                            "name": "Savannah",
                            "latitude": "32.08354000",
                            "longitude": "-81.09983000"
                        },
                        {
                            "id": 126009,
                            "name": "Schley County",
                            "latitude": "32.26169000",
                            "longitude": "-84.31472000"
                        },
                        {
                            "id": 126055,
                            "name": "Scottdale",
                            "latitude": "33.78983000",
                            "longitude": "-84.26409000"
                        },
                        {
                            "id": 126068,
                            "name": "Screven County",
                            "latitude": "32.75059000",
                            "longitude": "-81.61193000"
                        },
                        {
                            "id": 126148,
                            "name": "Seminole County",
                            "latitude": "30.93878000",
                            "longitude": "-84.86887000"
                        },
                        {
                            "id": 126162,
                            "name": "Senoia",
                            "latitude": "33.30234000",
                            "longitude": "-84.55382000"
                        },
                        {
                            "id": 126218,
                            "name": "Shannon",
                            "latitude": "34.33676000",
                            "longitude": "-85.07134000"
                        },
                        {
                            "id": 126479,
                            "name": "Skidaway Island",
                            "latitude": "31.93494000",
                            "longitude": "-81.04705000"
                        },
                        {
                            "id": 126535,
                            "name": "Smyrna",
                            "latitude": "33.88399000",
                            "longitude": "-84.51438000"
                        },
                        {
                            "id": 126541,
                            "name": "Snellville",
                            "latitude": "33.85733000",
                            "longitude": "-84.01991000"
                        },
                        {
                            "id": 126555,
                            "name": "Social Circle",
                            "latitude": "33.65623000",
                            "longitude": "-83.71823000"
                        },
                        {
                            "id": 126602,
                            "name": "Soperton",
                            "latitude": "32.37712000",
                            "longitude": "-82.59236000"
                        },
                        {
                            "id": 126795,
                            "name": "Spalding County",
                            "latitude": "33.26087000",
                            "longitude": "-84.28416000"
                        },
                        {
                            "id": 126803,
                            "name": "Sparks",
                            "latitude": "31.16686000",
                            "longitude": "-83.43738000"
                        },
                        {
                            "id": 126807,
                            "name": "Sparta",
                            "latitude": "33.27570000",
                            "longitude": "-82.97626000"
                        },
                        {
                            "id": 126891,
                            "name": "Springfield",
                            "latitude": "32.37241000",
                            "longitude": "-81.31150000"
                        },
                        {
                            "id": 126929,
                            "name": "St. Marys",
                            "latitude": "30.73051000",
                            "longitude": "-81.54649000"
                        },
                        {
                            "id": 126996,
                            "name": "Statenville",
                            "latitude": "30.70327000",
                            "longitude": "-83.02764000"
                        },
                        {
                            "id": 126997,
                            "name": "Statesboro",
                            "latitude": "32.44879000",
                            "longitude": "-81.78317000"
                        },
                        {
                            "id": 126999,
                            "name": "Statham",
                            "latitude": "33.96511000",
                            "longitude": "-83.59655000"
                        },
                        {
                            "id": 127021,
                            "name": "Stephens County",
                            "latitude": "34.55394000",
                            "longitude": "-83.29343000"
                        },
                        {
                            "id": 127050,
                            "name": "Stewart County",
                            "latitude": "32.07846000",
                            "longitude": "-84.83520000"
                        },
                        {
                            "id": 127067,
                            "name": "Stockbridge",
                            "latitude": "33.54428000",
                            "longitude": "-84.23381000"
                        },
                        {
                            "id": 127083,
                            "name": "Stone Mountain",
                            "latitude": "33.80816000",
                            "longitude": "-84.17020000"
                        },
                        {
                            "id": 127087,
                            "name": "Stonecrest",
                            "latitude": "33.70849000",
                            "longitude": "-84.13485000"
                        },
                        {
                            "id": 127172,
                            "name": "Sugar Hill",
                            "latitude": "34.10649000",
                            "longitude": "-84.03352000"
                        },
                        {
                            "id": 127209,
                            "name": "Summerville",
                            "latitude": "34.48064000",
                            "longitude": "-85.34773000"
                        },
                        {
                            "id": 127230,
                            "name": "Sumter County",
                            "latitude": "32.03997000",
                            "longitude": "-84.19704000"
                        },
                        {
                            "id": 127257,
                            "name": "Sunnyside",
                            "latitude": "31.23938000",
                            "longitude": "-82.34207000"
                        },
                        {
                            "id": 127317,
                            "name": "Suwanee",
                            "latitude": "34.05149000",
                            "longitude": "-84.07130000"
                        },
                        {
                            "id": 127320,
                            "name": "Swainsboro",
                            "latitude": "32.59739000",
                            "longitude": "-82.33374000"
                        },
                        {
                            "id": 127360,
                            "name": "Sylvania",
                            "latitude": "32.75044000",
                            "longitude": "-81.63678000"
                        },
                        {
                            "id": 127361,
                            "name": "Sylvester",
                            "latitude": "31.53092000",
                            "longitude": "-83.83693000"
                        },
                        {
                            "id": 127383,
                            "name": "Talbot County",
                            "latitude": "32.69949000",
                            "longitude": "-84.53301000"
                        },
                        {
                            "id": 127385,
                            "name": "Talbotton",
                            "latitude": "32.67764000",
                            "longitude": "-84.53937000"
                        },
                        {
                            "id": 127387,
                            "name": "Taliaferro County",
                            "latitude": "33.56609000",
                            "longitude": "-82.87876000"
                        },
                        {
                            "id": 127393,
                            "name": "Tallapoosa",
                            "latitude": "33.74455000",
                            "longitude": "-85.28801000"
                        },
                        {
                            "id": 127439,
                            "name": "Tattnall County",
                            "latitude": "32.04575000",
                            "longitude": "-82.05818000"
                        },
                        {
                            "id": 127452,
                            "name": "Taylor County",
                            "latitude": "32.55546000",
                            "longitude": "-84.25046000"
                        },
                        {
                            "id": 127486,
                            "name": "Telfair County",
                            "latitude": "31.92980000",
                            "longitude": "-82.93899000"
                        },
                        {
                            "id": 127497,
                            "name": "Temple",
                            "latitude": "33.73705000",
                            "longitude": "-85.03244000"
                        },
                        {
                            "id": 127511,
                            "name": "Tennille",
                            "latitude": "32.93599000",
                            "longitude": "-82.81153000"
                        },
                        {
                            "id": 127527,
                            "name": "Terrell County",
                            "latitude": "31.77688000",
                            "longitude": "-84.43692000"
                        },
                        {
                            "id": 127579,
                            "name": "Thomas County",
                            "latitude": "30.86368000",
                            "longitude": "-83.91931000"
                        },
                        {
                            "id": 127583,
                            "name": "Thomaston",
                            "latitude": "32.88819000",
                            "longitude": "-84.32659000"
                        },
                        {
                            "id": 127588,
                            "name": "Thomasville",
                            "latitude": "30.83658000",
                            "longitude": "-83.97878000"
                        },
                        {
                            "id": 127596,
                            "name": "Thomson",
                            "latitude": "33.47069000",
                            "longitude": "-82.50457000"
                        },
                        {
                            "id": 127627,
                            "name": "Thunderbolt",
                            "latitude": "32.03354000",
                            "longitude": "-81.04983000"
                        },
                        {
                            "id": 127641,
                            "name": "Tift County",
                            "latitude": "31.45744000",
                            "longitude": "-83.52659000"
                        },
                        {
                            "id": 127642,
                            "name": "Tifton",
                            "latitude": "31.45046000",
                            "longitude": "-83.50850000"
                        },
                        {
                            "id": 127691,
                            "name": "Toccoa",
                            "latitude": "34.57732000",
                            "longitude": "-83.33239000"
                        },
                        {
                            "id": 127729,
                            "name": "Toombs County",
                            "latitude": "32.12172000",
                            "longitude": "-82.33129000"
                        },
                        {
                            "id": 127763,
                            "name": "Towns County",
                            "latitude": "34.91665000",
                            "longitude": "-83.73728000"
                        },
                        {
                            "id": 127802,
                            "name": "Trenton",
                            "latitude": "34.87202000",
                            "longitude": "-85.50913000"
                        },
                        {
                            "id": 127812,
                            "name": "Treutlen County",
                            "latitude": "32.40388000",
                            "longitude": "-82.56728000"
                        },
                        {
                            "id": 127830,
                            "name": "Trion",
                            "latitude": "34.54397000",
                            "longitude": "-85.31051000"
                        },
                        {
                            "id": 127836,
                            "name": "Troup County",
                            "latitude": "33.03351000",
                            "longitude": "-85.02834000"
                        },
                        {
                            "id": 127870,
                            "name": "Tucker",
                            "latitude": "33.85455000",
                            "longitude": "-84.21714000"
                        },
                        {
                            "id": 127903,
                            "name": "Turner County",
                            "latitude": "31.71638000",
                            "longitude": "-83.62409000"
                        },
                        {
                            "id": 127922,
                            "name": "Twiggs County",
                            "latitude": "32.66720000",
                            "longitude": "-83.42708000"
                        },
                        {
                            "id": 127923,
                            "name": "Twin City",
                            "latitude": "32.58294000",
                            "longitude": "-82.15512000"
                        },
                        {
                            "id": 127937,
                            "name": "Tybee Island",
                            "latitude": "32.00022000",
                            "longitude": "-80.84567000"
                        },
                        {
                            "id": 127949,
                            "name": "Tyrone",
                            "latitude": "33.47123000",
                            "longitude": "-84.59715000"
                        },
                        {
                            "id": 127964,
                            "name": "Unadilla",
                            "latitude": "32.26155000",
                            "longitude": "-83.73657000"
                        },
                        {
                            "id": 127980,
                            "name": "Union City",
                            "latitude": "33.58706000",
                            "longitude": "-84.54243000"
                        },
                        {
                            "id": 127990,
                            "name": "Union County",
                            "latitude": "34.83401000",
                            "longitude": "-83.99076000"
                        },
                        {
                            "id": 128010,
                            "name": "Union Point",
                            "latitude": "33.61568000",
                            "longitude": "-83.07460000"
                        },
                        {
                            "id": 128017,
                            "name": "Unionville",
                            "latitude": "31.43491000",
                            "longitude": "-83.50961000"
                        },
                        {
                            "id": 128054,
                            "name": "Upson County",
                            "latitude": "32.88127000",
                            "longitude": "-84.29934000"
                        },
                        {
                            "id": 128085,
                            "name": "Valdosta",
                            "latitude": "30.83334000",
                            "longitude": "-83.28032000"
                        },
                        {
                            "id": 128155,
                            "name": "Varnell",
                            "latitude": "34.90119000",
                            "longitude": "-84.97383000"
                        },
                        {
                            "id": 128227,
                            "name": "Vidalia",
                            "latitude": "32.21769000",
                            "longitude": "-82.41346000"
                        },
                        {
                            "id": 128230,
                            "name": "Vienna",
                            "latitude": "32.09156000",
                            "longitude": "-83.79545000"
                        },
                        {
                            "id": 128245,
                            "name": "Villa Rica",
                            "latitude": "33.73205000",
                            "longitude": "-84.91911000"
                        },
                        {
                            "id": 128276,
                            "name": "Vinings",
                            "latitude": "33.86483000",
                            "longitude": "-84.46437000"
                        },
                        {
                            "id": 128325,
                            "name": "Wadley",
                            "latitude": "32.86682000",
                            "longitude": "-82.40402000"
                        },
                        {
                            "id": 128387,
                            "name": "Walker County",
                            "latitude": "34.73566000",
                            "longitude": "-85.30098000"
                        },
                        {
                            "id": 128417,
                            "name": "Walnut Grove",
                            "latitude": "33.74261000",
                            "longitude": "-83.85240000"
                        },
                        {
                            "id": 128433,
                            "name": "Walthourville",
                            "latitude": "31.77410000",
                            "longitude": "-81.63261000"
                        },
                        {
                            "id": 128438,
                            "name": "Walton County",
                            "latitude": "33.78156000",
                            "longitude": "-83.73385000"
                        },
                        {
                            "id": 128463,
                            "name": "Ware County",
                            "latitude": "31.05363000",
                            "longitude": "-82.42368000"
                        },
                        {
                            "id": 128474,
                            "name": "Warner Robins",
                            "latitude": "32.61574000",
                            "longitude": "-83.62664000"
                        },
                        {
                            "id": 128486,
                            "name": "Warren County",
                            "latitude": "33.40896000",
                            "longitude": "-82.67676000"
                        },
                        {
                            "id": 128504,
                            "name": "Warrenton",
                            "latitude": "33.40708000",
                            "longitude": "-82.66208000"
                        },
                        {
                            "id": 128539,
                            "name": "Washington",
                            "latitude": "33.73679000",
                            "longitude": "-82.73931000"
                        },
                        {
                            "id": 128555,
                            "name": "Washington County",
                            "latitude": "32.96954000",
                            "longitude": "-82.79590000"
                        },
                        {
                            "id": 128635,
                            "name": "Watkinsville",
                            "latitude": "33.86290000",
                            "longitude": "-83.40877000"
                        },
                        {
                            "id": 128676,
                            "name": "Waycross",
                            "latitude": "31.21368000",
                            "longitude": "-82.35570000"
                        },
                        {
                            "id": 128689,
                            "name": "Wayne County",
                            "latitude": "31.55143000",
                            "longitude": "-81.91676000"
                        },
                        {
                            "id": 128705,
                            "name": "Waynesboro",
                            "latitude": "33.08987000",
                            "longitude": "-82.01567000"
                        },
                        {
                            "id": 128736,
                            "name": "Webster County",
                            "latitude": "32.04665000",
                            "longitude": "-84.55105000"
                        },
                        {
                            "id": 128958,
                            "name": "West Point",
                            "latitude": "32.87791000",
                            "longitude": "-85.18327000"
                        },
                        {
                            "id": 129001,
                            "name": "West Warrenton",
                            "latitude": "33.41217000",
                            "longitude": "-82.67517000"
                        },
                        {
                            "id": 129116,
                            "name": "Wheeler County",
                            "latitude": "32.11707000",
                            "longitude": "-82.72459000"
                        },
                        {
                            "id": 129135,
                            "name": "White County",
                            "latitude": "34.64636000",
                            "longitude": "-83.74711000"
                        },
                        {
                            "id": 129178,
                            "name": "Whitemarsh Island",
                            "latitude": "32.02882000",
                            "longitude": "-81.01678000"
                        },
                        {
                            "id": 129195,
                            "name": "Whitfield County",
                            "latitude": "34.80561000",
                            "longitude": "-84.96722000"
                        },
                        {
                            "id": 129229,
                            "name": "Wilcox County",
                            "latitude": "31.97290000",
                            "longitude": "-83.43236000"
                        },
                        {
                            "id": 129244,
                            "name": "Wilkes County",
                            "latitude": "33.78195000",
                            "longitude": "-82.74323000"
                        },
                        {
                            "id": 129250,
                            "name": "Wilkinson County",
                            "latitude": "32.80241000",
                            "longitude": "-83.17125000"
                        },
                        {
                            "id": 129254,
                            "name": "Willacoochee",
                            "latitude": "31.34076000",
                            "longitude": "-83.04598000"
                        },
                        {
                            "id": 129325,
                            "name": "Wilmington Island",
                            "latitude": "32.00355000",
                            "longitude": "-80.97372000"
                        },
                        {
                            "id": 129369,
                            "name": "Winder",
                            "latitude": "33.99261000",
                            "longitude": "-83.72017000"
                        },
                        {
                            "id": 129447,
                            "name": "Winterville",
                            "latitude": "33.96706000",
                            "longitude": "-83.27821000"
                        },
                        {
                            "id": 129494,
                            "name": "Woodbine",
                            "latitude": "30.96396000",
                            "longitude": "-81.72416000"
                        },
                        {
                            "id": 129561,
                            "name": "Woodstock",
                            "latitude": "34.10149000",
                            "longitude": "-84.51938000"
                        },
                        {
                            "id": 129592,
                            "name": "Worth County",
                            "latitude": "31.55151000",
                            "longitude": "-83.85088000"
                        },
                        {
                            "id": 129603,
                            "name": "Wrens",
                            "latitude": "33.20765000",
                            "longitude": "-82.39179000"
                        },
                        {
                            "id": 129614,
                            "name": "Wrightsville",
                            "latitude": "32.72933000",
                            "longitude": "-82.71986000"
                        },
                        {
                            "id": 129715,
                            "name": "Young Harris",
                            "latitude": "34.93315000",
                            "longitude": "-83.84712000"
                        },
                        {
                            "id": 129742,
                            "name": "Zebulon",
                            "latitude": "33.10235000",
                            "longitude": "-84.34270000"
                        }
                    ]
                },
                {
                    "id": 1412,
                    "name": "Guam",
                    "state_code": "GU",
                    "cities": []
                },
                {
                    "id": 1411,
                    "name": "Hawaii",
                    "state_code": "HI",
                    "cities": [
                        {
                            "id": 129769,
                            "name": "‘Āhuimanu",
                            "latitude": "21.44472000",
                            "longitude": "-157.83778000"
                        },
                        {
                            "id": 129764,
                            "name": "‘Aiea",
                            "latitude": "21.38222000",
                            "longitude": "-157.93361000"
                        },
                        {
                            "id": 129765,
                            "name": "‘Ele‘ele",
                            "latitude": "21.90738000",
                            "longitude": "-159.58322000"
                        },
                        {
                            "id": 129766,
                            "name": "‘Ewa Beach",
                            "latitude": "21.31556000",
                            "longitude": "-158.00722000"
                        },
                        {
                            "id": 129767,
                            "name": "‘Ewa Gentry",
                            "latitude": "21.33999000",
                            "longitude": "-158.03039000"
                        },
                        {
                            "id": 129768,
                            "name": "‘Ewa Villages",
                            "latitude": "21.34127000",
                            "longitude": "-158.03970000"
                        },
                        {
                            "id": 129770,
                            "name": "‘Ōma‘o",
                            "latitude": "21.92581000",
                            "longitude": "-159.48818000"
                        },
                        {
                            "id": 111068,
                            "name": "Ainaloa",
                            "latitude": "19.52694000",
                            "longitude": "-154.99306000"
                        },
                        {
                            "id": 111300,
                            "name": "Anahola",
                            "latitude": "22.14226000",
                            "longitude": "-159.31388000"
                        },
                        {
                            "id": 113378,
                            "name": "Captain Cook",
                            "latitude": "19.49694000",
                            "longitude": "-155.92167000"
                        },
                        {
                            "id": 115693,
                            "name": "East Honolulu",
                            "latitude": "21.28906000",
                            "longitude": "-157.71734000"
                        },
                        {
                            "id": 116481,
                            "name": "Fern Acres",
                            "latitude": "19.51222000",
                            "longitude": "-155.08028000"
                        },
                        {
                            "id": 117841,
                            "name": "Haiku-Pauwela",
                            "latitude": "20.92187000",
                            "longitude": "-156.30508000"
                        },
                        {
                            "id": 118859,
                            "name": "Hālawa",
                            "latitude": "21.37944000",
                            "longitude": "-157.92167000"
                        },
                        {
                            "id": 118860,
                            "name": "Hālawa Heights",
                            "latitude": "21.37848000",
                            "longitude": "-157.91388000"
                        },
                        {
                            "id": 117854,
                            "name": "Hale‘iwa",
                            "latitude": "21.59284000",
                            "longitude": "-158.10339000"
                        },
                        {
                            "id": 117941,
                            "name": "Hana",
                            "latitude": "20.75806000",
                            "longitude": "-155.99028000"
                        },
                        {
                            "id": 117943,
                            "name": "Hanamā‘ulu",
                            "latitude": "21.99773000",
                            "longitude": "-159.35918000"
                        },
                        {
                            "id": 117944,
                            "name": "Hanapēpē",
                            "latitude": "21.90733000",
                            "longitude": "-159.59440000"
                        },
                        {
                            "id": 117945,
                            "name": "Hanapēpē Heights",
                            "latitude": "21.91633000",
                            "longitude": "-159.58995000"
                        },
                        {
                            "id": 118144,
                            "name": "Hau‘ula",
                            "latitude": "21.60760000",
                            "longitude": "-157.90868000"
                        },
                        {
                            "id": 118157,
                            "name": "Hawaii County",
                            "latitude": "19.60240000",
                            "longitude": "-155.52289000"
                        },
                        {
                            "id": 118158,
                            "name": "Hawaiian Acres",
                            "latitude": "19.53806000",
                            "longitude": "-155.05222000"
                        },
                        {
                            "id": 118159,
                            "name": "Hawaiian Beaches",
                            "latitude": "19.54306000",
                            "longitude": "-154.91583000"
                        },
                        {
                            "id": 118161,
                            "name": "Hawaiian Ocean View",
                            "latitude": "19.06861000",
                            "longitude": "-155.76500000"
                        },
                        {
                            "id": 118162,
                            "name": "Hawaiian Paradise Park",
                            "latitude": "19.59333000",
                            "longitude": "-154.97306000"
                        },
                        {
                            "id": 118861,
                            "name": "Hāwī",
                            "latitude": "20.24122000",
                            "longitude": "-155.83351000"
                        },
                        {
                            "id": 118353,
                            "name": "He‘eia",
                            "latitude": "21.43054000",
                            "longitude": "-157.81611000"
                        },
                        {
                            "id": 118360,
                            "name": "Hickam Field",
                            "latitude": "21.33967000",
                            "longitude": "-157.96018000"
                        },
                        {
                            "id": 118472,
                            "name": "Hilo",
                            "latitude": "19.72991000",
                            "longitude": "-155.09073000"
                        },
                        {
                            "id": 118862,
                            "name": "Hōlualoa",
                            "latitude": "19.61979000",
                            "longitude": "-155.94831000"
                        },
                        {
                            "id": 118613,
                            "name": "Honalo",
                            "latitude": "19.54639000",
                            "longitude": "-155.93194000"
                        },
                        {
                            "id": 118614,
                            "name": "Honaunau-Napoopoo",
                            "latitude": "19.45627000",
                            "longitude": "-155.86466000"
                        },
                        {
                            "id": 118622,
                            "name": "Honoka‘a",
                            "latitude": "20.07931000",
                            "longitude": "-155.46691000"
                        },
                        {
                            "id": 118623,
                            "name": "Honolulu",
                            "latitude": "21.30694000",
                            "longitude": "-157.85833000"
                        },
                        {
                            "id": 118624,
                            "name": "Honolulu County",
                            "latitude": "21.45543000",
                            "longitude": "-157.96138000"
                        },
                        {
                            "id": 119001,
                            "name": "Iroquois Point",
                            "latitude": "21.32776000",
                            "longitude": "-157.98301000"
                        },
                        {
                            "id": 119404,
                            "name": "Ka‘a‘awa",
                            "latitude": "21.55445000",
                            "longitude": "-157.85103000"
                        },
                        {
                            "id": 119730,
                            "name": "Kā‘anapali",
                            "latitude": "20.92881000",
                            "longitude": "-156.69422000"
                        },
                        {
                            "id": 119348,
                            "name": "Kaanapali Landing",
                            "latitude": "20.93090000",
                            "longitude": "-156.68778000"
                        },
                        {
                            "id": 119352,
                            "name": "Kahalu‘u",
                            "latitude": "21.45759000",
                            "longitude": "-157.84431000"
                        },
                        {
                            "id": 119351,
                            "name": "Kahaluu-Keauhou",
                            "latitude": "19.57181000",
                            "longitude": "-155.96172000"
                        },
                        {
                            "id": 119354,
                            "name": "Kahuku",
                            "latitude": "21.68048000",
                            "longitude": "-157.95237000"
                        },
                        {
                            "id": 119355,
                            "name": "Kahului",
                            "latitude": "20.88953000",
                            "longitude": "-156.47432000"
                        },
                        {
                            "id": 119357,
                            "name": "Kailua",
                            "latitude": "21.40241000",
                            "longitude": "-157.74054000"
                        },
                        {
                            "id": 119358,
                            "name": "Kailua-Kona",
                            "latitude": "19.64016000",
                            "longitude": "-155.99912000"
                        },
                        {
                            "id": 119369,
                            "name": "Kalāheo",
                            "latitude": "21.92416000",
                            "longitude": "-159.52686000"
                        },
                        {
                            "id": 119362,
                            "name": "Kalaoa",
                            "latitude": "19.72861000",
                            "longitude": "-155.98167000"
                        },
                        {
                            "id": 119363,
                            "name": "Kalawao County",
                            "latitude": "21.18569000",
                            "longitude": "-156.95944000"
                        },
                        {
                            "id": 119379,
                            "name": "Kaneohe",
                            "latitude": "21.39994000",
                            "longitude": "-157.79895000"
                        },
                        {
                            "id": 119386,
                            "name": "Kapa‘a",
                            "latitude": "22.07521000",
                            "longitude": "-159.31895000"
                        },
                        {
                            "id": 119385,
                            "name": "Kapaau",
                            "latitude": "20.23389000",
                            "longitude": "-155.80194000"
                        },
                        {
                            "id": 119388,
                            "name": "Kapolei",
                            "latitude": "21.33555000",
                            "longitude": "-158.05820000"
                        },
                        {
                            "id": 119396,
                            "name": "Kauai County",
                            "latitude": "22.01108000",
                            "longitude": "-159.70544000"
                        },
                        {
                            "id": 119400,
                            "name": "Kaunakakai",
                            "latitude": "21.09363000",
                            "longitude": "-157.02613000"
                        },
                        {
                            "id": 119415,
                            "name": "Kea‘au",
                            "latitude": "19.62265000",
                            "longitude": "-155.03744000"
                        },
                        {
                            "id": 119405,
                            "name": "Kealakekua",
                            "latitude": "19.52083000",
                            "longitude": "-155.92250000"
                        },
                        {
                            "id": 119427,
                            "name": "Kekaha",
                            "latitude": "21.96686000",
                            "longitude": "-159.71186000"
                        },
                        {
                            "id": 119731,
                            "name": "Kēōkea",
                            "latitude": "20.70711000",
                            "longitude": "-156.35446000"
                        },
                        {
                            "id": 119732,
                            "name": "Kīhei",
                            "latitude": "20.76462000",
                            "longitude": "-156.44578000"
                        },
                        {
                            "id": 119733,
                            "name": "Kīlauea",
                            "latitude": "22.21208000",
                            "longitude": "-159.41342000"
                        },
                        {
                            "id": 119697,
                            "name": "Ko Olina",
                            "latitude": "21.33993000",
                            "longitude": "-158.12562000"
                        },
                        {
                            "id": 119703,
                            "name": "Koloa",
                            "latitude": "21.90690000",
                            "longitude": "-159.46994000"
                        },
                        {
                            "id": 119721,
                            "name": "Kualapu‘u",
                            "latitude": "21.15306000",
                            "longitude": "-157.03677000"
                        },
                        {
                            "id": 119722,
                            "name": "Kula",
                            "latitude": "20.79094000",
                            "longitude": "-156.32695000"
                        },
                        {
                            "id": 119727,
                            "name": "Kurtistown",
                            "latitude": "19.60361000",
                            "longitude": "-155.05722000"
                        },
                        {
                            "id": 120947,
                            "name": "Lā‘ie",
                            "latitude": "21.64547000",
                            "longitude": "-157.92250000"
                        },
                        {
                            "id": 119860,
                            "name": "Lahaina",
                            "latitude": "20.87429000",
                            "longitude": "-156.67663000"
                        },
                        {
                            "id": 120078,
                            "name": "Lanai City",
                            "latitude": "20.82757000",
                            "longitude": "-156.92399000"
                        },
                        {
                            "id": 120221,
                            "name": "Lawai",
                            "latitude": "21.92159000",
                            "longitude": "-159.50376000"
                        },
                        {
                            "id": 120339,
                            "name": "Leilani Estates",
                            "latitude": "19.46972000",
                            "longitude": "-154.91778000"
                        },
                        {
                            "id": 120483,
                            "name": "Lihue",
                            "latitude": "21.98121000",
                            "longitude": "-159.37210000"
                        },
                        {
                            "id": 122530,
                            "name": "Mā‘ili",
                            "latitude": "21.41629000",
                            "longitude": "-158.17531000"
                        },
                        {
                            "id": 122528,
                            "name": "Mākaha",
                            "latitude": "21.46627000",
                            "longitude": "-158.21037000"
                        },
                        {
                            "id": 122529,
                            "name": "Mākaha Valley",
                            "latitude": "21.47583000",
                            "longitude": "-158.19918000"
                        },
                        {
                            "id": 121054,
                            "name": "Makakilo City",
                            "latitude": "21.34694000",
                            "longitude": "-158.08583000"
                        },
                        {
                            "id": 121055,
                            "name": "Makawao",
                            "latitude": "20.85694000",
                            "longitude": "-156.31306000"
                        },
                        {
                            "id": 121226,
                            "name": "Marine Corps Base Hawaii - MCBH",
                            "latitude": "21.44336000",
                            "longitude": "-157.74981000"
                        },
                        {
                            "id": 121419,
                            "name": "Maui County",
                            "latitude": "20.86774000",
                            "longitude": "-156.61706000"
                        },
                        {
                            "id": 121422,
                            "name": "Maunawili",
                            "latitude": "21.37278000",
                            "longitude": "-157.77056000"
                        },
                        {
                            "id": 121861,
                            "name": "Mililani Town",
                            "latitude": "21.45000000",
                            "longitude": "-158.00111000"
                        },
                        {
                            "id": 122025,
                            "name": "Mokulēia",
                            "latitude": "21.57967000",
                            "longitude": "-158.15313000"
                        },
                        {
                            "id": 122432,
                            "name": "Mountain View",
                            "latitude": "19.55583000",
                            "longitude": "-155.10806000"
                        },
                        {
                            "id": 123224,
                            "name": "Nānākuli",
                            "latitude": "21.39362000",
                            "longitude": "-158.15429000"
                        },
                        {
                            "id": 122539,
                            "name": "Nanawale Estates",
                            "latitude": "19.50611000",
                            "longitude": "-154.91194000"
                        },
                        {
                            "id": 122551,
                            "name": "Napili-Honokowai",
                            "latitude": "20.97533000",
                            "longitude": "-156.67826000"
                        },
                        {
                            "id": 123334,
                            "name": "Ocean Pointe",
                            "latitude": "21.31066000",
                            "longitude": "-158.03638000"
                        },
                        {
                            "id": 123540,
                            "name": "Orchidlands Estates",
                            "latitude": "19.56084000",
                            "longitude": "-155.01527000"
                        },
                        {
                            "id": 124773,
                            "name": "Pāhala",
                            "latitude": "19.20297000",
                            "longitude": "-155.47860000"
                        },
                        {
                            "id": 123725,
                            "name": "Paia",
                            "latitude": "20.90333000",
                            "longitude": "-156.36944000"
                        },
                        {
                            "id": 124774,
                            "name": "Pāpa‘ikou",
                            "latitude": "19.78718000",
                            "longitude": "-155.09326000"
                        },
                        {
                            "id": 123965,
                            "name": "Pearl City",
                            "latitude": "21.39734000",
                            "longitude": "-157.97516000"
                        },
                        {
                            "id": 124052,
                            "name": "Pepeekeo",
                            "latitude": "19.83361000",
                            "longitude": "-155.10722000"
                        },
                        {
                            "id": 124696,
                            "name": "Princeville",
                            "latitude": "22.21758000",
                            "longitude": "-159.47888000"
                        },
                        {
                            "id": 124731,
                            "name": "Puhi",
                            "latitude": "21.96888000",
                            "longitude": "-159.40012000"
                        },
                        {
                            "id": 124732,
                            "name": "Pukalani",
                            "latitude": "20.83667000",
                            "longitude": "-156.33667000"
                        },
                        {
                            "id": 124747,
                            "name": "Punalu‘u",
                            "latitude": "21.57045000",
                            "longitude": "-157.87557000"
                        },
                        {
                            "id": 124752,
                            "name": "Pupukea",
                            "latitude": "21.65456000",
                            "longitude": "-158.06065000"
                        },
                        {
                            "id": 125489,
                            "name": "Royal Kunia",
                            "latitude": "21.39392000",
                            "longitude": "-158.02670000"
                        },
                        {
                            "id": 126014,
                            "name": "Schofield Barracks",
                            "latitude": "21.49837000",
                            "longitude": "-158.06515000"
                        },
                        {
                            "id": 128249,
                            "name": "Village Park",
                            "latitude": "21.39806000",
                            "longitude": "-158.03028000"
                        },
                        {
                            "id": 128299,
                            "name": "Volcano",
                            "latitude": "19.44276000",
                            "longitude": "-155.23398000"
                        },
                        {
                            "id": 128331,
                            "name": "Wahiawā",
                            "latitude": "21.50279000",
                            "longitude": "-158.02464000"
                        },
                        {
                            "id": 128336,
                            "name": "Waialua",
                            "latitude": "21.57688000",
                            "longitude": "-158.13154000"
                        },
                        {
                            "id": 128337,
                            "name": "Waianae",
                            "latitude": "21.43785000",
                            "longitude": "-158.18555000"
                        },
                        {
                            "id": 128338,
                            "name": "Waihee-Waiehu",
                            "latitude": "20.93022000",
                            "longitude": "-156.50458000"
                        },
                        {
                            "id": 128339,
                            "name": "Waikapū",
                            "latitude": "20.85806000",
                            "longitude": "-156.50694000"
                        },
                        {
                            "id": 128340,
                            "name": "Waikoloa",
                            "latitude": "19.93942000",
                            "longitude": "-155.78931000"
                        },
                        {
                            "id": 128341,
                            "name": "Wailea",
                            "latitude": "20.68973000",
                            "longitude": "-156.44190000"
                        },
                        {
                            "id": 128342,
                            "name": "Wailua",
                            "latitude": "22.05271000",
                            "longitude": "-159.33781000"
                        },
                        {
                            "id": 128343,
                            "name": "Wailua Homesteads",
                            "latitude": "22.07244000",
                            "longitude": "-159.37677000"
                        },
                        {
                            "id": 128344,
                            "name": "Wailuku",
                            "latitude": "20.89133000",
                            "longitude": "-156.50604000"
                        },
                        {
                            "id": 128345,
                            "name": "Waimalu",
                            "latitude": "21.40472000",
                            "longitude": "-157.94333000"
                        },
                        {
                            "id": 128346,
                            "name": "Waimanalo",
                            "latitude": "21.34614000",
                            "longitude": "-157.72380000"
                        },
                        {
                            "id": 128348,
                            "name": "Waimānalo Beach",
                            "latitude": "21.33407000",
                            "longitude": "-157.70003000"
                        },
                        {
                            "id": 128347,
                            "name": "Waimea",
                            "latitude": "20.02323000",
                            "longitude": "-155.67288000"
                        },
                        {
                            "id": 128349,
                            "name": "Wainaku",
                            "latitude": "19.74472000",
                            "longitude": "-155.09500000"
                        },
                        {
                            "id": 128350,
                            "name": "Waipahu",
                            "latitude": "21.38667000",
                            "longitude": "-158.00917000"
                        },
                        {
                            "id": 128352,
                            "name": "Waipi‘o Acres",
                            "latitude": "21.46485000",
                            "longitude": "-158.01331000"
                        },
                        {
                            "id": 128351,
                            "name": "Waipio",
                            "latitude": "21.41823000",
                            "longitude": "-157.99906000"
                        },
                        {
                            "id": 129207,
                            "name": "Whitmore Village",
                            "latitude": "21.51429000",
                            "longitude": "-158.02464000"
                        }
                    ]
                },
                {
                    "id": 1398,
                    "name": "Howland Island",
                    "state_code": "UM-84",
                    "cities": []
                },
                {
                    "id": 1460,
                    "name": "Idaho",
                    "state_code": "ID",
                    "cities": [
                        {
                            "id": 110978,
                            "name": "Aberdeen",
                            "latitude": "42.94408000",
                            "longitude": "-112.83833000"
                        },
                        {
                            "id": 111007,
                            "name": "Ada County",
                            "latitude": "43.45112000",
                            "longitude": "-116.24109000"
                        },
                        {
                            "id": 111025,
                            "name": "Adams County",
                            "latitude": "44.88965000",
                            "longitude": "-116.45387000"
                        },
                        {
                            "id": 111272,
                            "name": "American Falls",
                            "latitude": "42.78602000",
                            "longitude": "-112.85444000"
                        },
                        {
                            "id": 111293,
                            "name": "Ammon",
                            "latitude": "43.46964000",
                            "longitude": "-111.96664000"
                        },
                        {
                            "id": 111435,
                            "name": "Arco",
                            "latitude": "43.63657000",
                            "longitude": "-113.30028000"
                        },
                        {
                            "id": 111546,
                            "name": "Ashton",
                            "latitude": "44.07158000",
                            "longitude": "-111.44829000"
                        },
                        {
                            "id": 111804,
                            "name": "Bannock County",
                            "latitude": "42.66851000",
                            "longitude": "-112.22463000"
                        },
                        {
                            "id": 111977,
                            "name": "Bear Lake County",
                            "latitude": "42.28479000",
                            "longitude": "-111.32965000"
                        },
                        {
                            "id": 112116,
                            "name": "Bellevue",
                            "latitude": "43.46352000",
                            "longitude": "-114.26060000"
                        },
                        {
                            "id": 112167,
                            "name": "Benewah County",
                            "latitude": "47.21755000",
                            "longitude": "-116.65883000"
                        },
                        {
                            "id": 112349,
                            "name": "Bingham County",
                            "latitude": "43.21652000",
                            "longitude": "-112.39805000"
                        },
                        {
                            "id": 112385,
                            "name": "Blackfoot",
                            "latitude": "43.19047000",
                            "longitude": "-112.34498000"
                        },
                        {
                            "id": 112407,
                            "name": "Blaine County",
                            "latitude": "43.41233000",
                            "longitude": "-113.98040000"
                        },
                        {
                            "id": 112508,
                            "name": "Boise",
                            "latitude": "43.61350000",
                            "longitude": "-116.20345000"
                        },
                        {
                            "id": 112510,
                            "name": "Boise County",
                            "latitude": "43.98913000",
                            "longitude": "-115.73024000"
                        },
                        {
                            "id": 112542,
                            "name": "Bonner County",
                            "latitude": "48.29975000",
                            "longitude": "-116.60097000"
                        },
                        {
                            "id": 112545,
                            "name": "Bonners Ferry",
                            "latitude": "48.69133000",
                            "longitude": "-116.31631000"
                        },
                        {
                            "id": 112546,
                            "name": "Bonneville County",
                            "latitude": "43.38773000",
                            "longitude": "-111.61493000"
                        },
                        {
                            "id": 112609,
                            "name": "Boundary County",
                            "latitude": "48.76702000",
                            "longitude": "-116.46288000"
                        },
                        {
                            "id": 113023,
                            "name": "Buhl",
                            "latitude": "42.59907000",
                            "longitude": "-114.75949000"
                        },
                        {
                            "id": 113063,
                            "name": "Burley",
                            "latitude": "42.53574000",
                            "longitude": "-113.79279000"
                        },
                        {
                            "id": 113126,
                            "name": "Butte County",
                            "latitude": "43.72287000",
                            "longitude": "-113.17202000"
                        },
                        {
                            "id": 113182,
                            "name": "Caldwell",
                            "latitude": "43.66294000",
                            "longitude": "-116.68736000"
                        },
                        {
                            "id": 113244,
                            "name": "Camas County",
                            "latitude": "43.46325000",
                            "longitude": "-114.80585000"
                        },
                        {
                            "id": 113356,
                            "name": "Canyon County",
                            "latitude": "43.62513000",
                            "longitude": "-116.70929000"
                        },
                        {
                            "id": 113395,
                            "name": "Caribou County",
                            "latitude": "42.77051000",
                            "longitude": "-111.56224000"
                        },
                        {
                            "id": 113514,
                            "name": "Cascade",
                            "latitude": "44.51628000",
                            "longitude": "-116.04180000"
                        },
                        {
                            "id": 113537,
                            "name": "Cassia County",
                            "latitude": "42.28387000",
                            "longitude": "-113.60037000"
                        },
                        {
                            "id": 113715,
                            "name": "Challis",
                            "latitude": "44.50464000",
                            "longitude": "-114.23173000"
                        },
                        {
                            "id": 114004,
                            "name": "Chubbuck",
                            "latitude": "42.92075000",
                            "longitude": "-112.46609000"
                        },
                        {
                            "id": 114130,
                            "name": "Clark County",
                            "latitude": "44.28398000",
                            "longitude": "-112.35135000"
                        },
                        {
                            "id": 114227,
                            "name": "Clearwater County",
                            "latitude": "46.67370000",
                            "longitude": "-115.65686000"
                        },
                        {
                            "id": 114366,
                            "name": "Coeur d'Alene",
                            "latitude": "47.67768000",
                            "longitude": "-116.78047000"
                        },
                        {
                            "id": 114711,
                            "name": "Council",
                            "latitude": "44.72989000",
                            "longitude": "-116.43820000"
                        },
                        {
                            "id": 114938,
                            "name": "Custer County",
                            "latitude": "44.24142000",
                            "longitude": "-114.28180000"
                        },
                        {
                            "id": 115004,
                            "name": "Dalton Gardens",
                            "latitude": "47.72963000",
                            "longitude": "-116.77019000"
                        },
                        {
                            "id": 115478,
                            "name": "Driggs",
                            "latitude": "43.72325000",
                            "longitude": "-111.11133000"
                        },
                        {
                            "id": 115497,
                            "name": "Dubois",
                            "latitude": "44.17630000",
                            "longitude": "-112.23082000"
                        },
                        {
                            "id": 115598,
                            "name": "Eagle",
                            "latitude": "43.69544000",
                            "longitude": "-116.35401000"
                        },
                        {
                            "id": 116058,
                            "name": "Elmore County",
                            "latitude": "43.35390000",
                            "longitude": "-115.46918000"
                        },
                        {
                            "id": 116105,
                            "name": "Emmett",
                            "latitude": "43.87350000",
                            "longitude": "-116.49930000"
                        },
                        {
                            "id": 116317,
                            "name": "Fairfield",
                            "latitude": "43.34657000",
                            "longitude": "-114.79173000"
                        },
                        {
                            "id": 116507,
                            "name": "Filer",
                            "latitude": "42.57019000",
                            "longitude": "-114.60782000"
                        },
                        {
                            "id": 116714,
                            "name": "Fort Hall",
                            "latitude": "43.03325000",
                            "longitude": "-112.43831000"
                        },
                        {
                            "id": 116864,
                            "name": "Franklin County",
                            "latitude": "42.18117000",
                            "longitude": "-111.81323000"
                        },
                        {
                            "id": 116927,
                            "name": "Fremont County",
                            "latitude": "44.22879000",
                            "longitude": "-111.48202000"
                        },
                        {
                            "id": 116972,
                            "name": "Fruitland",
                            "latitude": "44.00766000",
                            "longitude": "-116.91655000"
                        },
                        {
                            "id": 117061,
                            "name": "Garden City",
                            "latitude": "43.62211000",
                            "longitude": "-116.23817000"
                        },
                        {
                            "id": 117133,
                            "name": "Gem County",
                            "latitude": "44.06169000",
                            "longitude": "-116.39723000"
                        },
                        {
                            "id": 117299,
                            "name": "Glenns Ferry",
                            "latitude": "42.95490000",
                            "longitude": "-115.30090000"
                        },
                        {
                            "id": 117381,
                            "name": "Gooding",
                            "latitude": "42.93879000",
                            "longitude": "-114.71311000"
                        },
                        {
                            "id": 117382,
                            "name": "Gooding County",
                            "latitude": "42.97090000",
                            "longitude": "-114.81152000"
                        },
                        {
                            "id": 117490,
                            "name": "Grangeville",
                            "latitude": "45.92655000",
                            "longitude": "-116.12237000"
                        },
                        {
                            "id": 117842,
                            "name": "Hailey",
                            "latitude": "43.51963000",
                            "longitude": "-114.31532000"
                        },
                        {
                            "id": 117976,
                            "name": "Hansen",
                            "latitude": "42.53068000",
                            "longitude": "-114.30101000"
                        },
                        {
                            "id": 118179,
                            "name": "Hayden",
                            "latitude": "47.76602000",
                            "longitude": "-116.78658000"
                        },
                        {
                            "id": 118351,
                            "name": "Heyburn",
                            "latitude": "42.55852000",
                            "longitude": "-113.76390000"
                        },
                        {
                            "id": 118378,
                            "name": "Hidden Spring",
                            "latitude": "43.72216000",
                            "longitude": "-116.25093000"
                        },
                        {
                            "id": 118589,
                            "name": "Homedale",
                            "latitude": "43.61766000",
                            "longitude": "-116.93376000"
                        },
                        {
                            "id": 118869,
                            "name": "Idaho City",
                            "latitude": "43.82850000",
                            "longitude": "-115.83455000"
                        },
                        {
                            "id": 118870,
                            "name": "Idaho County",
                            "latitude": "45.84420000",
                            "longitude": "-115.46745000"
                        },
                        {
                            "id": 118871,
                            "name": "Idaho Falls",
                            "latitude": "43.46658000",
                            "longitude": "-112.03414000"
                        },
                        {
                            "id": 118969,
                            "name": "Iona",
                            "latitude": "43.52630000",
                            "longitude": "-111.93302000"
                        },
                        {
                            "id": 119195,
                            "name": "Jefferson County",
                            "latitude": "43.82014000",
                            "longitude": "-112.31128000"
                        },
                        {
                            "id": 119229,
                            "name": "Jerome",
                            "latitude": "42.72407000",
                            "longitude": "-114.51865000"
                        },
                        {
                            "id": 119230,
                            "name": "Jerome County",
                            "latitude": "42.68990000",
                            "longitude": "-114.26403000"
                        },
                        {
                            "id": 119371,
                            "name": "Kamiah",
                            "latitude": "46.22712000",
                            "longitude": "-116.02931000"
                        },
                        {
                            "id": 119429,
                            "name": "Kellogg",
                            "latitude": "47.53826000",
                            "longitude": "-116.11933000"
                        },
                        {
                            "id": 119525,
                            "name": "Ketchum",
                            "latitude": "43.68074000",
                            "longitude": "-114.36366000"
                        },
                        {
                            "id": 119571,
                            "name": "Kimberly",
                            "latitude": "42.53380000",
                            "longitude": "-114.36476000"
                        },
                        {
                            "id": 119707,
                            "name": "Kootenai County",
                            "latitude": "47.67456000",
                            "longitude": "-116.70001000"
                        },
                        {
                            "id": 119725,
                            "name": "Kuna",
                            "latitude": "43.49183000",
                            "longitude": "-116.42012000"
                        },
                        {
                            "id": 120137,
                            "name": "Lapwai",
                            "latitude": "46.40489000",
                            "longitude": "-116.80487000"
                        },
                        {
                            "id": 120167,
                            "name": "Latah County",
                            "latitude": "46.81613000",
                            "longitude": "-116.71168000"
                        },
                        {
                            "id": 120356,
                            "name": "Lemhi County",
                            "latitude": "44.94331000",
                            "longitude": "-113.93324000"
                        },
                        {
                            "id": 120416,
                            "name": "Lewis County",
                            "latitude": "46.23702000",
                            "longitude": "-116.42625000"
                        },
                        {
                            "id": 120428,
                            "name": "Lewiston",
                            "latitude": "46.41655000",
                            "longitude": "-117.01766000"
                        },
                        {
                            "id": 120430,
                            "name": "Lewiston Orchards",
                            "latitude": "46.38044000",
                            "longitude": "-116.97543000"
                        },
                        {
                            "id": 120511,
                            "name": "Lincoln",
                            "latitude": "43.51297000",
                            "longitude": "-111.96441000"
                        },
                        {
                            "id": 120533,
                            "name": "Lincoln County",
                            "latitude": "43.00241000",
                            "longitude": "-114.13831000"
                        },
                        {
                            "id": 121018,
                            "name": "Madison County",
                            "latitude": "43.78419000",
                            "longitude": "-111.65934000"
                        },
                        {
                            "id": 121057,
                            "name": "Malad City",
                            "latitude": "42.19159000",
                            "longitude": "-112.25080000"
                        },
                        {
                            "id": 121330,
                            "name": "Marsing",
                            "latitude": "43.54544000",
                            "longitude": "-116.81320000"
                        },
                        {
                            "id": 121465,
                            "name": "McCall",
                            "latitude": "44.91101000",
                            "longitude": "-116.09874000"
                        },
                        {
                            "id": 121695,
                            "name": "Meridian",
                            "latitude": "43.61211000",
                            "longitude": "-116.39151000"
                        },
                        {
                            "id": 121784,
                            "name": "Middleton",
                            "latitude": "43.70683000",
                            "longitude": "-116.62014000"
                        },
                        {
                            "id": 121947,
                            "name": "Minidoka County",
                            "latitude": "42.85440000",
                            "longitude": "-113.63752000"
                        },
                        {
                            "id": 122178,
                            "name": "Montpelier",
                            "latitude": "42.32215000",
                            "longitude": "-111.29770000"
                        },
                        {
                            "id": 122228,
                            "name": "Moreland",
                            "latitude": "43.22269000",
                            "longitude": "-112.44248000"
                        },
                        {
                            "id": 122300,
                            "name": "Moscow",
                            "latitude": "46.73239000",
                            "longitude": "-117.00017000"
                        },
                        {
                            "id": 122417,
                            "name": "Mountain Home",
                            "latitude": "43.13295000",
                            "longitude": "-115.69120000"
                        },
                        {
                            "id": 122487,
                            "name": "Murphy",
                            "latitude": "43.21822000",
                            "longitude": "-116.55234000"
                        },
                        {
                            "id": 122538,
                            "name": "Nampa",
                            "latitude": "43.54072000",
                            "longitude": "-116.56346000"
                        },
                        {
                            "id": 122763,
                            "name": "New Plymouth",
                            "latitude": "43.96989000",
                            "longitude": "-116.81904000"
                        },
                        {
                            "id": 122878,
                            "name": "Nez Perce County",
                            "latitude": "46.32676000",
                            "longitude": "-116.75024000"
                        },
                        {
                            "id": 122879,
                            "name": "Nezperce",
                            "latitude": "46.23489000",
                            "longitude": "-116.24070000"
                        },
                        {
                            "id": 123477,
                            "name": "Oneida County",
                            "latitude": "42.19490000",
                            "longitude": "-112.53962000"
                        },
                        {
                            "id": 123572,
                            "name": "Orofino",
                            "latitude": "46.47935000",
                            "longitude": "-116.25514000"
                        },
                        {
                            "id": 123596,
                            "name": "Osburn",
                            "latitude": "47.50604000",
                            "longitude": "-115.99933000"
                        },
                        {
                            "id": 123674,
                            "name": "Owyhee County",
                            "latitude": "42.58153000",
                            "longitude": "-116.16998000"
                        },
                        {
                            "id": 123836,
                            "name": "Paris",
                            "latitude": "42.22715000",
                            "longitude": "-111.40104000"
                        },
                        {
                            "id": 123885,
                            "name": "Parma",
                            "latitude": "43.78516000",
                            "longitude": "-116.94321000"
                        },
                        {
                            "id": 123920,
                            "name": "Paul",
                            "latitude": "42.60796000",
                            "longitude": "-113.78335000"
                        },
                        {
                            "id": 123944,
                            "name": "Payette",
                            "latitude": "44.07822000",
                            "longitude": "-116.93377000"
                        },
                        {
                            "id": 123945,
                            "name": "Payette County",
                            "latitude": "44.00674000",
                            "longitude": "-116.76084000"
                        },
                        {
                            "id": 124253,
                            "name": "Pinehurst",
                            "latitude": "47.53881000",
                            "longitude": "-116.23739000"
                        },
                        {
                            "id": 124397,
                            "name": "Plummer",
                            "latitude": "47.33518000",
                            "longitude": "-116.88851000"
                        },
                        {
                            "id": 124418,
                            "name": "Pocatello",
                            "latitude": "42.87130000",
                            "longitude": "-112.44553000"
                        },
                        {
                            "id": 124469,
                            "name": "Ponderay",
                            "latitude": "48.30548000",
                            "longitude": "-116.53380000"
                        },
                        {
                            "id": 124577,
                            "name": "Post Falls",
                            "latitude": "47.71796000",
                            "longitude": "-116.95159000"
                        },
                        {
                            "id": 124615,
                            "name": "Power County",
                            "latitude": "42.69369000",
                            "longitude": "-112.84067000"
                        },
                        {
                            "id": 124657,
                            "name": "Preston",
                            "latitude": "42.09631000",
                            "longitude": "-111.87662000"
                        },
                        {
                            "id": 124669,
                            "name": "Priest River",
                            "latitude": "48.18097000",
                            "longitude": "-116.91157000"
                        },
                        {
                            "id": 124900,
                            "name": "Rathdrum",
                            "latitude": "47.81240000",
                            "longitude": "-116.89659000"
                        },
                        {
                            "id": 125038,
                            "name": "Rexburg",
                            "latitude": "43.82602000",
                            "longitude": "-111.78969000"
                        },
                        {
                            "id": 125137,
                            "name": "Rigby",
                            "latitude": "43.67241000",
                            "longitude": "-111.91497000"
                        },
                        {
                            "id": 125515,
                            "name": "Rupert",
                            "latitude": "42.61908000",
                            "longitude": "-113.67723000"
                        },
                        {
                            "id": 125602,
                            "name": "Saint Anthony",
                            "latitude": "43.96630000",
                            "longitude": "-111.68218000"
                        },
                        {
                            "id": 125685,
                            "name": "Saint Maries",
                            "latitude": "47.31435000",
                            "longitude": "-116.56267000"
                        },
                        {
                            "id": 125763,
                            "name": "Salmon",
                            "latitude": "45.17575000",
                            "longitude": "-113.89590000"
                        },
                        {
                            "id": 125871,
                            "name": "Sandpoint",
                            "latitude": "48.27659000",
                            "longitude": "-116.55325000"
                        },
                        {
                            "id": 126294,
                            "name": "Shelley",
                            "latitude": "43.38130000",
                            "longitude": "-112.12331000"
                        },
                        {
                            "id": 126379,
                            "name": "Shoshone",
                            "latitude": "42.93602000",
                            "longitude": "-114.40588000"
                        },
                        {
                            "id": 126380,
                            "name": "Shoshone County",
                            "latitude": "47.35167000",
                            "longitude": "-115.89103000"
                        },
                        {
                            "id": 126562,
                            "name": "Soda Springs",
                            "latitude": "42.65437000",
                            "longitude": "-111.60467000"
                        },
                        {
                            "id": 126839,
                            "name": "Spirit Lake",
                            "latitude": "47.96629000",
                            "longitude": "-116.86853000"
                        },
                        {
                            "id": 126978,
                            "name": "Star",
                            "latitude": "43.69211000",
                            "longitude": "-116.49346000"
                        },
                        {
                            "id": 127169,
                            "name": "Sugar City",
                            "latitude": "43.87297000",
                            "longitude": "-111.74830000"
                        },
                        {
                            "id": 127241,
                            "name": "Sun Valley",
                            "latitude": "43.69713000",
                            "longitude": "-114.35172000"
                        },
                        {
                            "id": 127537,
                            "name": "Teton County",
                            "latitude": "43.75946000",
                            "longitude": "-111.20770000"
                        },
                        {
                            "id": 127924,
                            "name": "Twin Falls",
                            "latitude": "42.56297000",
                            "longitude": "-114.46087000"
                        },
                        {
                            "id": 127925,
                            "name": "Twin Falls County",
                            "latitude": "42.35598000",
                            "longitude": "-114.66716000"
                        },
                        {
                            "id": 127939,
                            "name": "Tyhee",
                            "latitude": "42.95158000",
                            "longitude": "-112.46637000"
                        },
                        {
                            "id": 127953,
                            "name": "Ucon",
                            "latitude": "43.59630000",
                            "longitude": "-111.96386000"
                        },
                        {
                            "id": 128104,
                            "name": "Valley County",
                            "latitude": "44.76670000",
                            "longitude": "-115.56613000"
                        },
                        {
                            "id": 128218,
                            "name": "Victor",
                            "latitude": "43.60270000",
                            "longitude": "-111.11133000"
                        },
                        {
                            "id": 128398,
                            "name": "Wallace",
                            "latitude": "47.47409000",
                            "longitude": "-115.92794000"
                        },
                        {
                            "id": 128579,
                            "name": "Washington County",
                            "latitude": "44.45243000",
                            "longitude": "-116.78477000"
                        },
                        {
                            "id": 128761,
                            "name": "Weiser",
                            "latitude": "44.25100000",
                            "longitude": "-116.96933000"
                        },
                        {
                            "id": 128800,
                            "name": "Wendell",
                            "latitude": "42.77574000",
                            "longitude": "-114.70422000"
                        },
                        {
                            "id": 129233,
                            "name": "Wilder",
                            "latitude": "43.67655000",
                            "longitude": "-116.91182000"
                        }
                    ]
                },
                {
                    "id": 1425,
                    "name": "Illinois",
                    "state_code": "IL",
                    "cities": [
                        {
                            "id": 110985,
                            "name": "Abingdon",
                            "latitude": "40.80448000",
                            "longitude": "-90.40180000"
                        },
                        {
                            "id": 111017,
                            "name": "Adams County",
                            "latitude": "39.98789000",
                            "longitude": "-91.18849000"
                        },
                        {
                            "id": 111035,
                            "name": "Addison",
                            "latitude": "41.93170000",
                            "longitude": "-87.98896000"
                        },
                        {
                            "id": 111114,
                            "name": "Albany Park",
                            "latitude": "41.96836000",
                            "longitude": "-87.72339000"
                        },
                        {
                            "id": 111117,
                            "name": "Albers",
                            "latitude": "38.54338000",
                            "longitude": "-89.61231000"
                        },
                        {
                            "id": 111123,
                            "name": "Albion",
                            "latitude": "38.37755000",
                            "longitude": "-88.05615000"
                        },
                        {
                            "id": 111142,
                            "name": "Aledo",
                            "latitude": "41.19976000",
                            "longitude": "-90.74931000"
                        },
                        {
                            "id": 111147,
                            "name": "Alexander County",
                            "latitude": "37.19160000",
                            "longitude": "-89.33764000"
                        },
                        {
                            "id": 111167,
                            "name": "Algonquin",
                            "latitude": "42.16558000",
                            "longitude": "-88.29425000"
                        },
                        {
                            "id": 111216,
                            "name": "Alorton",
                            "latitude": "38.58977000",
                            "longitude": "-90.12011000"
                        },
                        {
                            "id": 111227,
                            "name": "Alsip",
                            "latitude": "41.66892000",
                            "longitude": "-87.73866000"
                        },
                        {
                            "id": 111232,
                            "name": "Altamont",
                            "latitude": "39.06199000",
                            "longitude": "-88.74811000"
                        },
                        {
                            "id": 111242,
                            "name": "Alton",
                            "latitude": "38.89060000",
                            "longitude": "-90.18428000"
                        },
                        {
                            "id": 111265,
                            "name": "Amboy",
                            "latitude": "41.71420000",
                            "longitude": "-89.32871000"
                        },
                        {
                            "id": 111308,
                            "name": "Andalusia",
                            "latitude": "41.43920000",
                            "longitude": "-90.71764000"
                        },
                        {
                            "id": 111346,
                            "name": "Anna",
                            "latitude": "37.46033000",
                            "longitude": "-89.24703000"
                        },
                        {
                            "id": 111372,
                            "name": "Antioch",
                            "latitude": "42.47724000",
                            "longitude": "-88.09564000"
                        },
                        {
                            "id": 111436,
                            "name": "Arcola",
                            "latitude": "39.68476000",
                            "longitude": "-88.30644000"
                        },
                        {
                            "id": 111472,
                            "name": "Arlington Heights",
                            "latitude": "42.08836000",
                            "longitude": "-87.98063000"
                        },
                        {
                            "id": 111498,
                            "name": "Arthur",
                            "latitude": "39.71476000",
                            "longitude": "-88.47228000"
                        },
                        {
                            "id": 111515,
                            "name": "Ashburn",
                            "latitude": "41.74753000",
                            "longitude": "-87.71116000"
                        },
                        {
                            "id": 111528,
                            "name": "Ashland",
                            "latitude": "39.88783000",
                            "longitude": "-90.00789000"
                        },
                        {
                            "id": 111557,
                            "name": "Assumption",
                            "latitude": "39.52032000",
                            "longitude": "-89.04897000"
                        },
                        {
                            "id": 111561,
                            "name": "Astoria",
                            "latitude": "40.22754000",
                            "longitude": "-90.35957000"
                        },
                        {
                            "id": 111574,
                            "name": "Athens",
                            "latitude": "39.96088000",
                            "longitude": "-89.72399000"
                        },
                        {
                            "id": 111592,
                            "name": "Atlanta",
                            "latitude": "40.25948000",
                            "longitude": "-89.23342000"
                        },
                        {
                            "id": 111614,
                            "name": "Atwood",
                            "latitude": "39.79948000",
                            "longitude": "-88.46228000"
                        },
                        {
                            "id": 111621,
                            "name": "Auburn",
                            "latitude": "39.59172000",
                            "longitude": "-89.74649000"
                        },
                        {
                            "id": 111633,
                            "name": "Auburn Gresham",
                            "latitude": "41.74179000",
                            "longitude": "-87.65322000"
                        },
                        {
                            "id": 111659,
                            "name": "Aurora",
                            "latitude": "41.76058000",
                            "longitude": "-88.32007000"
                        },
                        {
                            "id": 111686,
                            "name": "Aviston",
                            "latitude": "38.60672000",
                            "longitude": "-89.60759000"
                        },
                        {
                            "id": 111700,
                            "name": "Avondale",
                            "latitude": "41.93892000",
                            "longitude": "-87.71117000"
                        },
                        {
                            "id": 111805,
                            "name": "Bannockburn",
                            "latitude": "42.19336000",
                            "longitude": "-87.86646000"
                        },
                        {
                            "id": 111846,
                            "name": "Barrington",
                            "latitude": "42.15391000",
                            "longitude": "-88.13619000"
                        },
                        {
                            "id": 111849,
                            "name": "Barrington Hills",
                            "latitude": "42.14475000",
                            "longitude": "-88.15563000"
                        },
                        {
                            "id": 111854,
                            "name": "Barry",
                            "latitude": "39.69421000",
                            "longitude": "-91.03902000"
                        },
                        {
                            "id": 111863,
                            "name": "Bartlett",
                            "latitude": "41.99503000",
                            "longitude": "-88.18563000"
                        },
                        {
                            "id": 111871,
                            "name": "Bartonville",
                            "latitude": "40.65032000",
                            "longitude": "-89.65205000"
                        },
                        {
                            "id": 111887,
                            "name": "Batavia",
                            "latitude": "41.85003000",
                            "longitude": "-88.31257000"
                        },
                        {
                            "id": 111966,
                            "name": "Beach Park",
                            "latitude": "42.42224000",
                            "longitude": "-87.85730000"
                        },
                        {
                            "id": 111980,
                            "name": "Beardstown",
                            "latitude": "40.01755000",
                            "longitude": "-90.42429000"
                        },
                        {
                            "id": 112011,
                            "name": "Beckemeyer",
                            "latitude": "38.60560000",
                            "longitude": "-89.43592000"
                        },
                        {
                            "id": 112039,
                            "name": "Beecher",
                            "latitude": "41.34059000",
                            "longitude": "-87.62143000"
                        },
                        {
                            "id": 112103,
                            "name": "Belleville",
                            "latitude": "38.52005000",
                            "longitude": "-89.98399000"
                        },
                        {
                            "id": 112110,
                            "name": "Bellevue",
                            "latitude": "40.68448000",
                            "longitude": "-89.68010000"
                        },
                        {
                            "id": 112131,
                            "name": "Bellwood",
                            "latitude": "41.88142000",
                            "longitude": "-87.88312000"
                        },
                        {
                            "id": 112143,
                            "name": "Belmont Cragin",
                            "latitude": "41.93170000",
                            "longitude": "-87.76867000"
                        },
                        {
                            "id": 112155,
                            "name": "Belvidere",
                            "latitude": "42.26391000",
                            "longitude": "-88.84427000"
                        },
                        {
                            "id": 112159,
                            "name": "Bement",
                            "latitude": "39.92198000",
                            "longitude": "-88.57201000"
                        },
                        {
                            "id": 112172,
                            "name": "Benld",
                            "latitude": "39.09282000",
                            "longitude": "-89.80398000"
                        },
                        {
                            "id": 112180,
                            "name": "Bensenville",
                            "latitude": "41.95503000",
                            "longitude": "-87.94007000"
                        },
                        {
                            "id": 112193,
                            "name": "Benton",
                            "latitude": "37.99672000",
                            "longitude": "-88.92007000"
                        },
                        {
                            "id": 112222,
                            "name": "Berkeley",
                            "latitude": "41.88892000",
                            "longitude": "-87.90340000"
                        },
                        {
                            "id": 112264,
                            "name": "Berwyn",
                            "latitude": "41.85059000",
                            "longitude": "-87.79367000"
                        },
                        {
                            "id": 112271,
                            "name": "Bethalto",
                            "latitude": "38.90921000",
                            "longitude": "-90.04066000"
                        },
                        {
                            "id": 112272,
                            "name": "Bethany",
                            "latitude": "39.64559000",
                            "longitude": "-88.73813000"
                        },
                        {
                            "id": 112332,
                            "name": "Big Rock",
                            "latitude": "41.76392000",
                            "longitude": "-88.54702000"
                        },
                        {
                            "id": 112447,
                            "name": "Bloomingdale",
                            "latitude": "41.95753000",
                            "longitude": "-88.08090000"
                        },
                        {
                            "id": 112451,
                            "name": "Bloomington",
                            "latitude": "40.48420000",
                            "longitude": "-88.99369000"
                        },
                        {
                            "id": 112469,
                            "name": "Blue Island",
                            "latitude": "41.65726000",
                            "longitude": "-87.68005000"
                        },
                        {
                            "id": 112471,
                            "name": "Blue Mound",
                            "latitude": "39.70115000",
                            "longitude": "-89.12314000"
                        },
                        {
                            "id": 112516,
                            "name": "Bolingbrook",
                            "latitude": "41.69864000",
                            "longitude": "-88.06840000"
                        },
                        {
                            "id": 112531,
                            "name": "Bond County",
                            "latitude": "38.88682000",
                            "longitude": "-89.43555000"
                        },
                        {
                            "id": 112559,
                            "name": "Boone County",
                            "latitude": "42.32308000",
                            "longitude": "-88.82336000"
                        },
                        {
                            "id": 112605,
                            "name": "Boulder Hill",
                            "latitude": "41.71253000",
                            "longitude": "-88.33618000"
                        },
                        {
                            "id": 112615,
                            "name": "Bourbonnais",
                            "latitude": "41.15376000",
                            "longitude": "-87.88754000"
                        },
                        {
                            "id": 112672,
                            "name": "Bradley",
                            "latitude": "41.14198000",
                            "longitude": "-87.86115000"
                        },
                        {
                            "id": 112680,
                            "name": "Braidwood",
                            "latitude": "41.26503000",
                            "longitude": "-88.21228000"
                        },
                        {
                            "id": 112717,
                            "name": "Breese",
                            "latitude": "38.61060000",
                            "longitude": "-89.52703000"
                        },
                        {
                            "id": 112761,
                            "name": "Bridgeport",
                            "latitude": "41.83809000",
                            "longitude": "-87.65116000"
                        },
                        {
                            "id": 112771,
                            "name": "Bridgeview",
                            "latitude": "41.75003000",
                            "longitude": "-87.80422000"
                        },
                        {
                            "id": 112787,
                            "name": "Brighton",
                            "latitude": "39.03977000",
                            "longitude": "-90.14066000"
                        },
                        {
                            "id": 112793,
                            "name": "Brighton Park",
                            "latitude": "41.81892000",
                            "longitude": "-87.69894000"
                        },
                        {
                            "id": 112827,
                            "name": "Broadview",
                            "latitude": "41.86392000",
                            "longitude": "-87.85339000"
                        },
                        {
                            "id": 112854,
                            "name": "Brookfield",
                            "latitude": "41.82392000",
                            "longitude": "-87.85173000"
                        },
                        {
                            "id": 112907,
                            "name": "Brown County",
                            "latitude": "39.96181000",
                            "longitude": "-90.75034000"
                        },
                        {
                            "id": 113021,
                            "name": "Buffalo Grove",
                            "latitude": "42.15141000",
                            "longitude": "-87.95979000"
                        },
                        {
                            "id": 113029,
                            "name": "Bull Valley",
                            "latitude": "42.32058000",
                            "longitude": "-88.35509000"
                        },
                        {
                            "id": 113040,
                            "name": "Bunker Hill",
                            "latitude": "39.04282000",
                            "longitude": "-89.95177000"
                        },
                        {
                            "id": 113046,
                            "name": "Burbank",
                            "latitude": "41.73392000",
                            "longitude": "-87.77950000"
                        },
                        {
                            "id": 113049,
                            "name": "Bureau County",
                            "latitude": "41.40415000",
                            "longitude": "-89.52868000"
                        },
                        {
                            "id": 113083,
                            "name": "Burnham",
                            "latitude": "41.63892000",
                            "longitude": "-87.55671000"
                        },
                        {
                            "id": 113093,
                            "name": "Burr Ridge",
                            "latitude": "41.74892000",
                            "longitude": "-87.91839000"
                        },
                        {
                            "id": 113102,
                            "name": "Bushnell",
                            "latitude": "40.55282000",
                            "longitude": "-90.50624000"
                        },
                        {
                            "id": 113144,
                            "name": "Byron",
                            "latitude": "42.12697000",
                            "longitude": "-89.25566000"
                        },
                        {
                            "id": 113168,
                            "name": "Cahokia",
                            "latitude": "38.57088000",
                            "longitude": "-90.19011000"
                        },
                        {
                            "id": 113170,
                            "name": "Cairo",
                            "latitude": "37.00533000",
                            "longitude": "-89.17646000"
                        },
                        {
                            "id": 113204,
                            "name": "Calhoun County",
                            "latitude": "39.16930000",
                            "longitude": "-90.66753000"
                        },
                        {
                            "id": 113230,
                            "name": "Calumet City",
                            "latitude": "41.61559000",
                            "longitude": "-87.52949000"
                        },
                        {
                            "id": 113232,
                            "name": "Calumet Park",
                            "latitude": "41.66281000",
                            "longitude": "-87.66060000"
                        },
                        {
                            "id": 113245,
                            "name": "Cambria",
                            "latitude": "37.78144000",
                            "longitude": "-89.11925000"
                        },
                        {
                            "id": 113251,
                            "name": "Cambridge",
                            "latitude": "41.30365000",
                            "longitude": "-90.19290000"
                        },
                        {
                            "id": 113292,
                            "name": "Camp Point",
                            "latitude": "40.03921000",
                            "longitude": "-91.06930000"
                        },
                        {
                            "id": 113343,
                            "name": "Canton",
                            "latitude": "40.55809000",
                            "longitude": "-90.03512000"
                        },
                        {
                            "id": 113377,
                            "name": "Capron",
                            "latitude": "42.39974000",
                            "longitude": "-88.74038000"
                        },
                        {
                            "id": 113381,
                            "name": "Carbon Cliff",
                            "latitude": "41.49476000",
                            "longitude": "-90.39068000"
                        },
                        {
                            "id": 113388,
                            "name": "Carbondale",
                            "latitude": "37.72727000",
                            "longitude": "-89.21675000"
                        },
                        {
                            "id": 113400,
                            "name": "Carlinville",
                            "latitude": "39.27977000",
                            "longitude": "-89.88177000"
                        },
                        {
                            "id": 113414,
                            "name": "Carlyle",
                            "latitude": "38.61033000",
                            "longitude": "-89.37258000"
                        },
                        {
                            "id": 113422,
                            "name": "Carmi",
                            "latitude": "38.09088000",
                            "longitude": "-88.15865000"
                        },
                        {
                            "id": 113434,
                            "name": "Carol Stream",
                            "latitude": "41.91253000",
                            "longitude": "-88.13479000"
                        },
                        {
                            "id": 113439,
                            "name": "Carpentersville",
                            "latitude": "42.12114000",
                            "longitude": "-88.25786000"
                        },
                        {
                            "id": 113444,
                            "name": "Carrier Mills",
                            "latitude": "37.68422000",
                            "longitude": "-88.63283000"
                        },
                        {
                            "id": 113459,
                            "name": "Carroll County",
                            "latitude": "42.06861000",
                            "longitude": "-89.93433000"
                        },
                        {
                            "id": 113465,
                            "name": "Carrollton",
                            "latitude": "39.30227000",
                            "longitude": "-90.40706000"
                        },
                        {
                            "id": 113489,
                            "name": "Carterville",
                            "latitude": "37.76005000",
                            "longitude": "-89.07730000"
                        },
                        {
                            "id": 113496,
                            "name": "Carthage",
                            "latitude": "40.41643000",
                            "longitude": "-91.13625000"
                        },
                        {
                            "id": 113506,
                            "name": "Cary",
                            "latitude": "42.21197000",
                            "longitude": "-88.23814000"
                        },
                        {
                            "id": 113519,
                            "name": "Casey",
                            "latitude": "39.29920000",
                            "longitude": "-87.99253000"
                        },
                        {
                            "id": 113521,
                            "name": "Caseyville",
                            "latitude": "38.63672000",
                            "longitude": "-90.02566000"
                        },
                        {
                            "id": 113526,
                            "name": "Cass County",
                            "latitude": "39.97356000",
                            "longitude": "-90.24738000"
                        },
                        {
                            "id": 113573,
                            "name": "Catlin",
                            "latitude": "40.06504000",
                            "longitude": "-87.70197000"
                        },
                        {
                            "id": 113667,
                            "name": "Central City",
                            "latitude": "38.54894000",
                            "longitude": "-89.12701000"
                        },
                        {
                            "id": 113685,
                            "name": "Centralia",
                            "latitude": "38.52505000",
                            "longitude": "-89.13340000"
                        },
                        {
                            "id": 113692,
                            "name": "Centreville",
                            "latitude": "38.58338000",
                            "longitude": "-90.12511000"
                        },
                        {
                            "id": 113702,
                            "name": "Cerro Gordo",
                            "latitude": "39.89059000",
                            "longitude": "-88.72813000"
                        },
                        {
                            "id": 113724,
                            "name": "Champaign",
                            "latitude": "40.11642000",
                            "longitude": "-88.24338000"
                        },
                        {
                            "id": 113725,
                            "name": "Champaign County",
                            "latitude": "40.14008000",
                            "longitude": "-88.19919000"
                        },
                        {
                            "id": 113733,
                            "name": "Channahon",
                            "latitude": "41.42948000",
                            "longitude": "-88.22867000"
                        },
                        {
                            "id": 113735,
                            "name": "Channel Lake",
                            "latitude": "42.47863000",
                            "longitude": "-88.13759000"
                        },
                        {
                            "id": 113762,
                            "name": "Charleston",
                            "latitude": "39.49615000",
                            "longitude": "-88.17615000"
                        },
                        {
                            "id": 113797,
                            "name": "Chatham",
                            "latitude": "41.74115000",
                            "longitude": "-87.61255000"
                        },
                        {
                            "id": 113806,
                            "name": "Chatsworth",
                            "latitude": "40.75365000",
                            "longitude": "-88.29199000"
                        },
                        {
                            "id": 113820,
                            "name": "Chebanse",
                            "latitude": "41.00309000",
                            "longitude": "-87.90810000"
                        },
                        {
                            "id": 113841,
                            "name": "Chenoa",
                            "latitude": "40.74170000",
                            "longitude": "-88.71979000"
                        },
                        {
                            "id": 113864,
                            "name": "Cherry Valley",
                            "latitude": "42.23474000",
                            "longitude": "-88.94899000"
                        },
                        {
                            "id": 113882,
                            "name": "Chester",
                            "latitude": "37.91366000",
                            "longitude": "-89.82205000"
                        },
                        {
                            "id": 113931,
                            "name": "Chicago",
                            "latitude": "41.85003000",
                            "longitude": "-87.65005000"
                        },
                        {
                            "id": 113932,
                            "name": "Chicago Heights",
                            "latitude": "41.50615000",
                            "longitude": "-87.63560000"
                        },
                        {
                            "id": 113933,
                            "name": "Chicago Lawn",
                            "latitude": "41.77503000",
                            "longitude": "-87.69644000"
                        },
                        {
                            "id": 113934,
                            "name": "Chicago Loop",
                            "latitude": "41.88407000",
                            "longitude": "-87.63330000"
                        },
                        {
                            "id": 113935,
                            "name": "Chicago Ridge",
                            "latitude": "41.70142000",
                            "longitude": "-87.77922000"
                        },
                        {
                            "id": 113954,
                            "name": "Chillicothe",
                            "latitude": "40.92226000",
                            "longitude": "-89.48620000"
                        },
                        {
                            "id": 113994,
                            "name": "Chrisman",
                            "latitude": "39.80365000",
                            "longitude": "-87.67364000"
                        },
                        {
                            "id": 113995,
                            "name": "Christian County",
                            "latitude": "39.54579000",
                            "longitude": "-89.27727000"
                        },
                        {
                            "id": 114002,
                            "name": "Christopher",
                            "latitude": "37.97255000",
                            "longitude": "-89.05341000"
                        },
                        {
                            "id": 114019,
                            "name": "Cicero",
                            "latitude": "41.84559000",
                            "longitude": "-87.75394000"
                        },
                        {
                            "id": 114114,
                            "name": "Clarendon Hills",
                            "latitude": "41.79753000",
                            "longitude": "-87.95478000"
                        },
                        {
                            "id": 114122,
                            "name": "Clark County",
                            "latitude": "39.33357000",
                            "longitude": "-87.78772000"
                        },
                        {
                            "id": 114177,
                            "name": "Clay County",
                            "latitude": "38.75416000",
                            "longitude": "-88.49019000"
                        },
                        {
                            "id": 114257,
                            "name": "Clifton",
                            "latitude": "40.93531000",
                            "longitude": "-87.93449000"
                        },
                        {
                            "id": 114279,
                            "name": "Clinton",
                            "latitude": "40.15365000",
                            "longitude": "-88.96453000"
                        },
                        {
                            "id": 114289,
                            "name": "Clinton County",
                            "latitude": "38.60645000",
                            "longitude": "-89.42248000"
                        },
                        {
                            "id": 114327,
                            "name": "Coal City",
                            "latitude": "41.28781000",
                            "longitude": "-88.28562000"
                        },
                        {
                            "id": 114333,
                            "name": "Coal Valley",
                            "latitude": "41.42865000",
                            "longitude": "-90.46096000"
                        },
                        {
                            "id": 114346,
                            "name": "Cobden",
                            "latitude": "37.53144000",
                            "longitude": "-89.25342000"
                        },
                        {
                            "id": 114382,
                            "name": "Colchester",
                            "latitude": "40.42643000",
                            "longitude": "-90.79263000"
                        },
                        {
                            "id": 114402,
                            "name": "Coles County",
                            "latitude": "39.52029000",
                            "longitude": "-88.22180000"
                        },
                        {
                            "id": 114406,
                            "name": "Colfax",
                            "latitude": "40.56698000",
                            "longitude": "-88.61645000"
                        },
                        {
                            "id": 114434,
                            "name": "Collinsville",
                            "latitude": "38.67033000",
                            "longitude": "-89.98455000"
                        },
                        {
                            "id": 114445,
                            "name": "Colona",
                            "latitude": "41.48392000",
                            "longitude": "-90.35318000"
                        },
                        {
                            "id": 114465,
                            "name": "Columbia",
                            "latitude": "38.44366000",
                            "longitude": "-90.20122000"
                        },
                        {
                            "id": 114592,
                            "name": "Cook County",
                            "latitude": "41.89540000",
                            "longitude": "-87.64616000"
                        },
                        {
                            "id": 114675,
                            "name": "Cortland",
                            "latitude": "41.92003000",
                            "longitude": "-88.68870000"
                        },
                        {
                            "id": 114718,
                            "name": "Country Club Hills",
                            "latitude": "41.56809000",
                            "longitude": "-87.72033000"
                        },
                        {
                            "id": 114726,
                            "name": "Countryside",
                            "latitude": "41.78281000",
                            "longitude": "-87.87811000"
                        },
                        {
                            "id": 114761,
                            "name": "Crainville",
                            "latitude": "37.75199000",
                            "longitude": "-89.06785000"
                        },
                        {
                            "id": 114776,
                            "name": "Crawford County",
                            "latitude": "39.00269000",
                            "longitude": "-87.75956000"
                        },
                        {
                            "id": 114803,
                            "name": "Crest Hill",
                            "latitude": "41.55475000",
                            "longitude": "-88.09867000"
                        },
                        {
                            "id": 114811,
                            "name": "Crestwood",
                            "latitude": "41.64463000",
                            "longitude": "-87.74154000"
                        },
                        {
                            "id": 114814,
                            "name": "Crete",
                            "latitude": "41.44448000",
                            "longitude": "-87.63143000"
                        },
                        {
                            "id": 114817,
                            "name": "Creve Coeur",
                            "latitude": "40.64726000",
                            "longitude": "-89.59121000"
                        },
                        {
                            "id": 114880,
                            "name": "Crystal Lake",
                            "latitude": "42.24113000",
                            "longitude": "-88.31620000"
                        },
                        {
                            "id": 114881,
                            "name": "Crystal Lawns",
                            "latitude": "41.57031000",
                            "longitude": "-88.15812000"
                        },
                        {
                            "id": 114886,
                            "name": "Cuba",
                            "latitude": "42.18391000",
                            "longitude": "-88.19091000"
                        },
                        {
                            "id": 114912,
                            "name": "Cumberland County",
                            "latitude": "39.27332000",
                            "longitude": "-88.24023000"
                        },
                        {
                            "id": 115030,
                            "name": "Danvers",
                            "latitude": "40.52948000",
                            "longitude": "-89.17731000"
                        },
                        {
                            "id": 115035,
                            "name": "Danville",
                            "latitude": "40.12448000",
                            "longitude": "-87.63002000"
                        },
                        {
                            "id": 115047,
                            "name": "Darien",
                            "latitude": "41.75198000",
                            "longitude": "-87.97395000"
                        },
                        {
                            "id": 115076,
                            "name": "Davis Junction",
                            "latitude": "42.10169000",
                            "longitude": "-89.09316000"
                        },
                        {
                            "id": 115116,
                            "name": "De Soto",
                            "latitude": "37.81755000",
                            "longitude": "-89.22786000"
                        },
                        {
                            "id": 115123,
                            "name": "De Witt County",
                            "latitude": "40.17463000",
                            "longitude": "-88.90409000"
                        },
                        {
                            "id": 115151,
                            "name": "Decatur",
                            "latitude": "39.84031000",
                            "longitude": "-88.95480000"
                        },
                        {
                            "id": 115176,
                            "name": "Deer Park",
                            "latitude": "42.16086000",
                            "longitude": "-88.08147000"
                        },
                        {
                            "id": 115181,
                            "name": "Deerfield",
                            "latitude": "42.17114000",
                            "longitude": "-87.84451000"
                        },
                        {
                            "id": 115127,
                            "name": "DeKalb",
                            "latitude": "41.92947000",
                            "longitude": "-88.75036000"
                        },
                        {
                            "id": 115132,
                            "name": "DeKalb County",
                            "latitude": "41.89353000",
                            "longitude": "-88.77031000"
                        },
                        {
                            "id": 115200,
                            "name": "Delavan",
                            "latitude": "40.37254000",
                            "longitude": "-89.54732000"
                        },
                        {
                            "id": 115259,
                            "name": "Depue",
                            "latitude": "41.32420000",
                            "longitude": "-89.30675000"
                        },
                        {
                            "id": 115275,
                            "name": "Des Plaines",
                            "latitude": "42.03336000",
                            "longitude": "-87.88340000"
                        },
                        {
                            "id": 115313,
                            "name": "Diamond",
                            "latitude": "41.28864000",
                            "longitude": "-88.25173000"
                        },
                        {
                            "id": 115356,
                            "name": "Divernon",
                            "latitude": "39.56561000",
                            "longitude": "-89.65732000"
                        },
                        {
                            "id": 115363,
                            "name": "Dixmoor",
                            "latitude": "41.63170000",
                            "longitude": "-87.66088000"
                        },
                        {
                            "id": 115366,
                            "name": "Dixon",
                            "latitude": "41.83892000",
                            "longitude": "-89.47955000"
                        },
                        {
                            "id": 115392,
                            "name": "Dolton",
                            "latitude": "41.63892000",
                            "longitude": "-87.60727000"
                        },
                        {
                            "id": 115420,
                            "name": "Douglas",
                            "latitude": "41.83476000",
                            "longitude": "-87.61811000"
                        },
                        {
                            "id": 115426,
                            "name": "Douglas County",
                            "latitude": "39.76946000",
                            "longitude": "-88.21735000"
                        },
                        {
                            "id": 115459,
                            "name": "Downers Grove",
                            "latitude": "41.80892000",
                            "longitude": "-88.01117000"
                        },
                        {
                            "id": 115486,
                            "name": "Du Quoin",
                            "latitude": "38.01144000",
                            "longitude": "-89.23619000"
                        },
                        {
                            "id": 115544,
                            "name": "Dunlap",
                            "latitude": "40.86170000",
                            "longitude": "-89.67871000"
                        },
                        {
                            "id": 115488,
                            "name": "DuPage County",
                            "latitude": "41.85195000",
                            "longitude": "-88.08567000"
                        },
                        {
                            "id": 115557,
                            "name": "Dupo",
                            "latitude": "38.51616000",
                            "longitude": "-90.21039000"
                        },
                        {
                            "id": 115562,
                            "name": "Durand",
                            "latitude": "42.43640000",
                            "longitude": "-89.33206000"
                        },
                        {
                            "id": 115584,
                            "name": "Dwight",
                            "latitude": "41.09448000",
                            "longitude": "-88.42506000"
                        },
                        {
                            "id": 115621,
                            "name": "Earlville",
                            "latitude": "41.58948000",
                            "longitude": "-88.92203000"
                        },
                        {
                            "id": 115626,
                            "name": "East Alton",
                            "latitude": "38.88033000",
                            "longitude": "-90.11122000"
                        },
                        {
                            "id": 115652,
                            "name": "East Dubuque",
                            "latitude": "42.49223000",
                            "longitude": "-90.64291000"
                        },
                        {
                            "id": 115653,
                            "name": "East Dundee",
                            "latitude": "42.09891000",
                            "longitude": "-88.27147000"
                        },
                        {
                            "id": 115668,
                            "name": "East Garfield Park",
                            "latitude": "41.88087000",
                            "longitude": "-87.70283000"
                        },
                        {
                            "id": 115687,
                            "name": "East Hazel Crest",
                            "latitude": "41.57365000",
                            "longitude": "-87.64643000"
                        },
                        {
                            "id": 115716,
                            "name": "East Moline",
                            "latitude": "41.50087000",
                            "longitude": "-90.44430000"
                        },
                        {
                            "id": 115732,
                            "name": "East Peoria",
                            "latitude": "40.66615000",
                            "longitude": "-89.58010000"
                        },
                        {
                            "id": 115754,
                            "name": "East Saint Louis",
                            "latitude": "38.62450000",
                            "longitude": "-90.15094000"
                        },
                        {
                            "id": 115836,
                            "name": "Edgar County",
                            "latitude": "39.67853000",
                            "longitude": "-87.74557000"
                        },
                        {
                            "id": 115853,
                            "name": "Edgewater",
                            "latitude": "41.98337000",
                            "longitude": "-87.66395000"
                        },
                        {
                            "id": 115869,
                            "name": "Edinburg",
                            "latitude": "39.65727000",
                            "longitude": "-89.38953000"
                        },
                        {
                            "id": 115889,
                            "name": "Edwards County",
                            "latitude": "38.41653000",
                            "longitude": "-88.05327000"
                        },
                        {
                            "id": 115894,
                            "name": "Edwardsville",
                            "latitude": "38.81144000",
                            "longitude": "-89.95316000"
                        },
                        {
                            "id": 115896,
                            "name": "Effingham",
                            "latitude": "39.12004000",
                            "longitude": "-88.54338000"
                        },
                        {
                            "id": 115899,
                            "name": "Effingham County",
                            "latitude": "39.05977000",
                            "longitude": "-88.58986000"
                        },
                        {
                            "id": 115929,
                            "name": "El Paso",
                            "latitude": "40.73920000",
                            "longitude": "-89.01646000"
                        },
                        {
                            "id": 115948,
                            "name": "Elburn",
                            "latitude": "41.89225000",
                            "longitude": "-88.47230000"
                        },
                        {
                            "id": 115952,
                            "name": "Eldorado",
                            "latitude": "37.81366000",
                            "longitude": "-88.43810000"
                        },
                        {
                            "id": 115966,
                            "name": "Elgin",
                            "latitude": "42.03725000",
                            "longitude": "-88.28119000"
                        },
                        {
                            "id": 115977,
                            "name": "Elizabethtown",
                            "latitude": "37.44588000",
                            "longitude": "-88.30504000"
                        },
                        {
                            "id": 115988,
                            "name": "Elk Grove Village",
                            "latitude": "42.00392000",
                            "longitude": "-87.97035000"
                        },
                        {
                            "id": 116051,
                            "name": "Elmhurst",
                            "latitude": "41.89947000",
                            "longitude": "-87.94034000"
                        },
                        {
                            "id": 116061,
                            "name": "Elmwood",
                            "latitude": "40.77782000",
                            "longitude": "-89.96650000"
                        },
                        {
                            "id": 116062,
                            "name": "Elmwood Park",
                            "latitude": "41.92114000",
                            "longitude": "-87.80923000"
                        },
                        {
                            "id": 116080,
                            "name": "Elwood",
                            "latitude": "41.40392000",
                            "longitude": "-88.11172000"
                        },
                        {
                            "id": 116122,
                            "name": "Energy",
                            "latitude": "37.77394000",
                            "longitude": "-89.02646000"
                        },
                        {
                            "id": 116131,
                            "name": "Englewood",
                            "latitude": "41.77976000",
                            "longitude": "-87.64588000"
                        },
                        {
                            "id": 116165,
                            "name": "Erie",
                            "latitude": "41.65642000",
                            "longitude": "-90.07929000"
                        },
                        {
                            "id": 116234,
                            "name": "Eureka",
                            "latitude": "40.72143000",
                            "longitude": "-89.27286000"
                        },
                        {
                            "id": 116251,
                            "name": "Evanston",
                            "latitude": "42.04114000",
                            "longitude": "-87.69006000"
                        },
                        {
                            "id": 116265,
                            "name": "Evergreen Park",
                            "latitude": "41.72059000",
                            "longitude": "-87.70172000"
                        },
                        {
                            "id": 116294,
                            "name": "Fairbury",
                            "latitude": "40.74726000",
                            "longitude": "-88.51478000"
                        },
                        {
                            "id": 116310,
                            "name": "Fairfield",
                            "latitude": "38.37894000",
                            "longitude": "-88.35977000"
                        },
                        {
                            "id": 116336,
                            "name": "Fairmont",
                            "latitude": "41.55614000",
                            "longitude": "-88.05923000"
                        },
                        {
                            "id": 116338,
                            "name": "Fairmont City",
                            "latitude": "38.64977000",
                            "longitude": "-90.09316000"
                        },
                        {
                            "id": 116358,
                            "name": "Fairview Heights",
                            "latitude": "38.58894000",
                            "longitude": "-89.99038000"
                        },
                        {
                            "id": 116402,
                            "name": "Farmer City",
                            "latitude": "40.24337000",
                            "longitude": "-88.64257000"
                        },
                        {
                            "id": 116416,
                            "name": "Farmington",
                            "latitude": "40.69809000",
                            "longitude": "-90.00595000"
                        },
                        {
                            "id": 116445,
                            "name": "Fayette County",
                            "latitude": "39.00019000",
                            "longitude": "-89.02414000"
                        },
                        {
                            "id": 116527,
                            "name": "Fisher",
                            "latitude": "40.31476000",
                            "longitude": "-88.35005000"
                        },
                        {
                            "id": 116547,
                            "name": "Flanagan",
                            "latitude": "40.87809000",
                            "longitude": "-88.86118000"
                        },
                        {
                            "id": 116570,
                            "name": "Flora",
                            "latitude": "38.66894000",
                            "longitude": "-88.48560000"
                        },
                        {
                            "id": 116598,
                            "name": "Flossmoor",
                            "latitude": "41.54281000",
                            "longitude": "-87.68477000"
                        },
                        {
                            "id": 116637,
                            "name": "Ford County",
                            "latitude": "40.59718000",
                            "longitude": "-88.22326000"
                        },
                        {
                            "id": 116638,
                            "name": "Ford Heights",
                            "latitude": "41.50642000",
                            "longitude": "-87.59171000"
                        },
                        {
                            "id": 116660,
                            "name": "Forest Lake",
                            "latitude": "42.20752000",
                            "longitude": "-88.05563000"
                        },
                        {
                            "id": 116666,
                            "name": "Forest Park",
                            "latitude": "41.87948000",
                            "longitude": "-87.81367000"
                        },
                        {
                            "id": 116678,
                            "name": "Forrest",
                            "latitude": "40.75198000",
                            "longitude": "-88.41116000"
                        },
                        {
                            "id": 116681,
                            "name": "Forreston",
                            "latitude": "42.12614000",
                            "longitude": "-89.57928000"
                        },
                        {
                            "id": 116682,
                            "name": "Forsyth",
                            "latitude": "39.93254000",
                            "longitude": "-88.95119000"
                        },
                        {
                            "id": 116803,
                            "name": "Fox Lake",
                            "latitude": "42.39669000",
                            "longitude": "-88.18370000"
                        },
                        {
                            "id": 116805,
                            "name": "Fox Lake Hills",
                            "latitude": "42.40808000",
                            "longitude": "-88.13175000"
                        },
                        {
                            "id": 116807,
                            "name": "Fox River Grove",
                            "latitude": "42.20086000",
                            "longitude": "-88.21453000"
                        },
                        {
                            "id": 116819,
                            "name": "Frankfort",
                            "latitude": "41.49587000",
                            "longitude": "-87.84866000"
                        },
                        {
                            "id": 116824,
                            "name": "Frankfort Square",
                            "latitude": "41.51892000",
                            "longitude": "-87.80310000"
                        },
                        {
                            "id": 116847,
                            "name": "Franklin County",
                            "latitude": "37.99229000",
                            "longitude": "-88.92415000"
                        },
                        {
                            "id": 116868,
                            "name": "Franklin Park",
                            "latitude": "41.93531000",
                            "longitude": "-87.86562000"
                        },
                        {
                            "id": 116900,
                            "name": "Freeburg",
                            "latitude": "38.42755000",
                            "longitude": "-89.91371000"
                        },
                        {
                            "id": 116912,
                            "name": "Freeport",
                            "latitude": "42.29669000",
                            "longitude": "-89.62123000"
                        },
                        {
                            "id": 116990,
                            "name": "Fulton",
                            "latitude": "41.86725000",
                            "longitude": "-90.15957000"
                        },
                        {
                            "id": 116995,
                            "name": "Fulton County",
                            "latitude": "40.47277000",
                            "longitude": "-90.20747000"
                        },
                        {
                            "id": 117009,
                            "name": "Gage Park",
                            "latitude": "41.79503000",
                            "longitude": "-87.69616000"
                        },
                        {
                            "id": 117010,
                            "name": "Gages Lake",
                            "latitude": "42.35169000",
                            "longitude": "-87.98258000"
                        },
                        {
                            "id": 117024,
                            "name": "Galena",
                            "latitude": "42.41667000",
                            "longitude": "-90.42902000"
                        },
                        {
                            "id": 117027,
                            "name": "Galesburg",
                            "latitude": "40.94782000",
                            "longitude": "-90.37124000"
                        },
                        {
                            "id": 117034,
                            "name": "Gallatin County",
                            "latitude": "37.76275000",
                            "longitude": "-88.23050000"
                        },
                        {
                            "id": 117041,
                            "name": "Galva",
                            "latitude": "41.16754000",
                            "longitude": "-90.04261000"
                        },
                        {
                            "id": 117076,
                            "name": "Gardner",
                            "latitude": "41.18559000",
                            "longitude": "-88.30978000"
                        },
                        {
                            "id": 117137,
                            "name": "Geneseo",
                            "latitude": "41.44809000",
                            "longitude": "-90.15428000"
                        },
                        {
                            "id": 117142,
                            "name": "Geneva",
                            "latitude": "41.88753000",
                            "longitude": "-88.30535000"
                        },
                        {
                            "id": 117147,
                            "name": "Genoa",
                            "latitude": "42.09725000",
                            "longitude": "-88.69287000"
                        },
                        {
                            "id": 117162,
                            "name": "Georgetown",
                            "latitude": "42.13975000",
                            "longitude": "-89.82873000"
                        },
                        {
                            "id": 117171,
                            "name": "Germantown",
                            "latitude": "38.55366000",
                            "longitude": "-89.53842000"
                        },
                        {
                            "id": 117175,
                            "name": "Germantown Hills",
                            "latitude": "40.76643000",
                            "longitude": "-89.46787000"
                        },
                        {
                            "id": 117188,
                            "name": "Gibson City",
                            "latitude": "40.45843000",
                            "longitude": "-88.38460000"
                        },
                        {
                            "id": 117198,
                            "name": "Gifford",
                            "latitude": "40.30587000",
                            "longitude": "-88.02115000"
                        },
                        {
                            "id": 117206,
                            "name": "Gilberts",
                            "latitude": "42.10336000",
                            "longitude": "-88.37286000"
                        },
                        {
                            "id": 117214,
                            "name": "Gillespie",
                            "latitude": "39.12977000",
                            "longitude": "-89.81954000"
                        },
                        {
                            "id": 117219,
                            "name": "Gilman",
                            "latitude": "40.76670000",
                            "longitude": "-87.99226000"
                        },
                        {
                            "id": 117226,
                            "name": "Girard",
                            "latitude": "39.44644000",
                            "longitude": "-89.78093000"
                        },
                        {
                            "id": 117244,
                            "name": "Glasford",
                            "latitude": "40.57254000",
                            "longitude": "-89.81344000"
                        },
                        {
                            "id": 117263,
                            "name": "Glen Carbon",
                            "latitude": "38.74838000",
                            "longitude": "-89.98316000"
                        },
                        {
                            "id": 117265,
                            "name": "Glen Ellyn",
                            "latitude": "41.87753000",
                            "longitude": "-88.06701000"
                        },
                        {
                            "id": 117278,
                            "name": "Glencoe",
                            "latitude": "42.13503000",
                            "longitude": "-87.75812000"
                        },
                        {
                            "id": 117288,
                            "name": "Glendale Heights",
                            "latitude": "41.91460000",
                            "longitude": "-88.06486000"
                        },
                        {
                            "id": 117309,
                            "name": "Glenview",
                            "latitude": "42.06975000",
                            "longitude": "-87.78784000"
                        },
                        {
                            "id": 117314,
                            "name": "Glenwood",
                            "latitude": "41.54253000",
                            "longitude": "-87.60227000"
                        },
                        {
                            "id": 117334,
                            "name": "Godfrey",
                            "latitude": "38.95560000",
                            "longitude": "-90.18678000"
                        },
                        {
                            "id": 117338,
                            "name": "Golconda",
                            "latitude": "37.36727000",
                            "longitude": "-88.48643000"
                        },
                        {
                            "id": 117383,
                            "name": "Goodings Grove",
                            "latitude": "41.62920000",
                            "longitude": "-87.93089000"
                        },
                        {
                            "id": 117402,
                            "name": "Goreville",
                            "latitude": "37.55450000",
                            "longitude": "-88.97229000"
                        },
                        {
                            "id": 117451,
                            "name": "Grand Boulevard",
                            "latitude": "41.81392000",
                            "longitude": "-87.61727000"
                        },
                        {
                            "id": 117478,
                            "name": "Grandview",
                            "latitude": "39.81644000",
                            "longitude": "-89.61871000"
                        },
                        {
                            "id": 117484,
                            "name": "Grandwood Park",
                            "latitude": "42.39308000",
                            "longitude": "-87.98674000"
                        },
                        {
                            "id": 117494,
                            "name": "Granite City",
                            "latitude": "38.70144000",
                            "longitude": "-90.14872000"
                        },
                        {
                            "id": 117523,
                            "name": "Grant Park",
                            "latitude": "41.24114000",
                            "longitude": "-87.64615000"
                        },
                        {
                            "id": 117535,
                            "name": "Granville",
                            "latitude": "41.26115000",
                            "longitude": "-89.22759000"
                        },
                        {
                            "id": 117559,
                            "name": "Grayslake",
                            "latitude": "42.34447000",
                            "longitude": "-88.04175000"
                        },
                        {
                            "id": 117568,
                            "name": "Grayville",
                            "latitude": "38.25755000",
                            "longitude": "-87.99364000"
                        },
                        {
                            "id": 117580,
                            "name": "Greater Grand Crossing",
                            "latitude": "41.76113000",
                            "longitude": "-87.61485000"
                        },
                        {
                            "id": 117604,
                            "name": "Green Oaks",
                            "latitude": "42.29002000",
                            "longitude": "-87.90341000"
                        },
                        {
                            "id": 117607,
                            "name": "Green Rock",
                            "latitude": "41.47309000",
                            "longitude": "-90.35763000"
                        },
                        {
                            "id": 117634,
                            "name": "Greene County",
                            "latitude": "39.35620000",
                            "longitude": "-90.39049000"
                        },
                        {
                            "id": 117645,
                            "name": "Greenfield",
                            "latitude": "39.34366000",
                            "longitude": "-90.21261000"
                        },
                        {
                            "id": 117676,
                            "name": "Greenup",
                            "latitude": "39.24782000",
                            "longitude": "-88.16337000"
                        },
                        {
                            "id": 117683,
                            "name": "Greenville",
                            "latitude": "38.89227000",
                            "longitude": "-89.41314000"
                        },
                        {
                            "id": 117729,
                            "name": "Gridley",
                            "latitude": "40.74337000",
                            "longitude": "-88.88146000"
                        },
                        {
                            "id": 117735,
                            "name": "Griggsville",
                            "latitude": "39.70894000",
                            "longitude": "-90.72457000"
                        },
                        {
                            "id": 117772,
                            "name": "Grundy County",
                            "latitude": "41.28509000",
                            "longitude": "-88.41850000"
                        },
                        {
                            "id": 117809,
                            "name": "Gurnee",
                            "latitude": "42.37030000",
                            "longitude": "-87.90202000"
                        },
                        {
                            "id": 117846,
                            "name": "Hainesville",
                            "latitude": "42.34502000",
                            "longitude": "-88.06786000"
                        },
                        {
                            "id": 117892,
                            "name": "Hamilton",
                            "latitude": "40.39643000",
                            "longitude": "-91.33904000"
                        },
                        {
                            "id": 117897,
                            "name": "Hamilton County",
                            "latitude": "38.08157000",
                            "longitude": "-88.53911000"
                        },
                        {
                            "id": 117920,
                            "name": "Hampshire",
                            "latitude": "42.09780000",
                            "longitude": "-88.53036000"
                        },
                        {
                            "id": 117932,
                            "name": "Hampton",
                            "latitude": "41.55587000",
                            "longitude": "-90.40930000"
                        },
                        {
                            "id": 117956,
                            "name": "Hancock County",
                            "latitude": "40.40378000",
                            "longitude": "-91.16470000"
                        },
                        {
                            "id": 117962,
                            "name": "Hanna City",
                            "latitude": "40.69170000",
                            "longitude": "-89.79511000"
                        },
                        {
                            "id": 117975,
                            "name": "Hanover Park",
                            "latitude": "41.99947000",
                            "longitude": "-88.14507000"
                        },
                        {
                            "id": 117997,
                            "name": "Hardin",
                            "latitude": "39.15671000",
                            "longitude": "-90.61790000"
                        },
                        {
                            "id": 117999,
                            "name": "Hardin County",
                            "latitude": "37.51820000",
                            "longitude": "-88.26685000"
                        },
                        {
                            "id": 118048,
                            "name": "Harrisburg",
                            "latitude": "37.73838000",
                            "longitude": "-88.54061000"
                        },
                        {
                            "id": 118072,
                            "name": "Harristown",
                            "latitude": "39.85393000",
                            "longitude": "-89.08397000"
                        },
                        {
                            "id": 118085,
                            "name": "Hartford",
                            "latitude": "38.83338000",
                            "longitude": "-90.09594000"
                        },
                        {
                            "id": 118108,
                            "name": "Harvard",
                            "latitude": "42.42224000",
                            "longitude": "-88.61371000"
                        },
                        {
                            "id": 118112,
                            "name": "Harvey",
                            "latitude": "41.61003000",
                            "longitude": "-87.64671000"
                        },
                        {
                            "id": 118120,
                            "name": "Harwood Heights",
                            "latitude": "41.96725000",
                            "longitude": "-87.80756000"
                        },
                        {
                            "id": 118146,
                            "name": "Havana",
                            "latitude": "40.30004000",
                            "longitude": "-90.06095000"
                        },
                        {
                            "id": 118171,
                            "name": "Hawthorn Woods",
                            "latitude": "42.21697000",
                            "longitude": "-88.04952000"
                        },
                        {
                            "id": 118202,
                            "name": "Hazel Crest",
                            "latitude": "41.57170000",
                            "longitude": "-87.69449000"
                        },
                        {
                            "id": 118236,
                            "name": "Hebron",
                            "latitude": "42.47169000",
                            "longitude": "-88.43232000"
                        },
                        {
                            "id": 118284,
                            "name": "Henderson County",
                            "latitude": "40.81812000",
                            "longitude": "-90.92511000"
                        },
                        {
                            "id": 118290,
                            "name": "Hennepin",
                            "latitude": "41.25420000",
                            "longitude": "-89.34231000"
                        },
                        {
                            "id": 118296,
                            "name": "Henry",
                            "latitude": "41.11142000",
                            "longitude": "-89.35648000"
                        },
                        {
                            "id": 118305,
                            "name": "Henry County",
                            "latitude": "41.35313000",
                            "longitude": "-90.13142000"
                        },
                        {
                            "id": 118319,
                            "name": "Heritage Lake",
                            "latitude": "40.54745000",
                            "longitude": "-89.32581000"
                        },
                        {
                            "id": 118339,
                            "name": "Herrin",
                            "latitude": "37.80311000",
                            "longitude": "-89.02757000"
                        },
                        {
                            "id": 118340,
                            "name": "Herscher",
                            "latitude": "41.04920000",
                            "longitude": "-88.09783000"
                        },
                        {
                            "id": 118352,
                            "name": "Heyworth",
                            "latitude": "40.31337000",
                            "longitude": "-88.97369000"
                        },
                        {
                            "id": 118369,
                            "name": "Hickory Hills",
                            "latitude": "41.72559000",
                            "longitude": "-87.82506000"
                        },
                        {
                            "id": 118393,
                            "name": "Highland",
                            "latitude": "38.73949000",
                            "longitude": "-89.67120000"
                        },
                        {
                            "id": 118411,
                            "name": "Highland Park",
                            "latitude": "42.18169000",
                            "longitude": "-87.80034000"
                        },
                        {
                            "id": 118426,
                            "name": "Highwood",
                            "latitude": "42.19975000",
                            "longitude": "-87.80923000"
                        },
                        {
                            "id": 118439,
                            "name": "Hillcrest",
                            "latitude": "41.95114000",
                            "longitude": "-89.06454000"
                        },
                        {
                            "id": 118446,
                            "name": "Hillsboro",
                            "latitude": "39.16128000",
                            "longitude": "-89.49540000"
                        },
                        {
                            "id": 118463,
                            "name": "Hillside",
                            "latitude": "41.87781000",
                            "longitude": "-87.90284000"
                        },
                        {
                            "id": 118476,
                            "name": "Hinckley",
                            "latitude": "41.76892000",
                            "longitude": "-88.64091000"
                        },
                        {
                            "id": 118485,
                            "name": "Hinsdale",
                            "latitude": "41.80086000",
                            "longitude": "-87.93701000"
                        },
                        {
                            "id": 118509,
                            "name": "Hodgkins",
                            "latitude": "41.76892000",
                            "longitude": "-87.85783000"
                        },
                        {
                            "id": 118510,
                            "name": "Hoffman Estates",
                            "latitude": "42.04281000",
                            "longitude": "-88.07980000"
                        },
                        {
                            "id": 118535,
                            "name": "Holiday Shores",
                            "latitude": "38.92199000",
                            "longitude": "-89.94066000"
                        },
                        {
                            "id": 118594,
                            "name": "Homer",
                            "latitude": "40.03476000",
                            "longitude": "-87.95809000"
                        },
                        {
                            "id": 118599,
                            "name": "Homer Glen",
                            "latitude": "41.60003000",
                            "longitude": "-87.93811000"
                        },
                        {
                            "id": 118605,
                            "name": "Hometown",
                            "latitude": "41.73448000",
                            "longitude": "-87.73144000"
                        },
                        {
                            "id": 118608,
                            "name": "Homewood",
                            "latitude": "41.55726000",
                            "longitude": "-87.66560000"
                        },
                        {
                            "id": 118636,
                            "name": "Hoopeston",
                            "latitude": "40.46726000",
                            "longitude": "-87.66836000"
                        },
                        {
                            "id": 118739,
                            "name": "Hudson",
                            "latitude": "40.60587000",
                            "longitude": "-88.98730000"
                        },
                        {
                            "id": 118812,
                            "name": "Huntley",
                            "latitude": "42.16808000",
                            "longitude": "-88.42814000"
                        },
                        {
                            "id": 118849,
                            "name": "Hyde Park",
                            "latitude": "41.79420000",
                            "longitude": "-87.59394000"
                        },
                        {
                            "id": 118887,
                            "name": "Ina",
                            "latitude": "38.15116000",
                            "longitude": "-88.90396000"
                        },
                        {
                            "id": 118904,
                            "name": "Indian Head Park",
                            "latitude": "41.77031000",
                            "longitude": "-87.90228000"
                        },
                        {
                            "id": 118934,
                            "name": "Ingalls Park",
                            "latitude": "41.52253000",
                            "longitude": "-88.04283000"
                        },
                        {
                            "id": 118956,
                            "name": "Inverness",
                            "latitude": "42.11808000",
                            "longitude": "-88.09619000"
                        },
                        {
                            "id": 119000,
                            "name": "Iroquois County",
                            "latitude": "40.74723000",
                            "longitude": "-87.82430000"
                        },
                        {
                            "id": 119006,
                            "name": "Irving Park",
                            "latitude": "41.95336000",
                            "longitude": "-87.73645000"
                        },
                        {
                            "id": 119026,
                            "name": "Island Lake",
                            "latitude": "42.27613000",
                            "longitude": "-88.19203000"
                        },
                        {
                            "id": 119040,
                            "name": "Itasca",
                            "latitude": "41.97503000",
                            "longitude": "-88.00729000"
                        },
                        {
                            "id": 119079,
                            "name": "Jackson County",
                            "latitude": "37.78514000",
                            "longitude": "-89.38212000"
                        },
                        {
                            "id": 119102,
                            "name": "Jacksonville",
                            "latitude": "39.73394000",
                            "longitude": "-90.22901000"
                        },
                        {
                            "id": 119146,
                            "name": "Jasper County",
                            "latitude": "39.01003000",
                            "longitude": "-88.15381000"
                        },
                        {
                            "id": 119179,
                            "name": "Jefferson County",
                            "latitude": "38.30052000",
                            "longitude": "-88.92401000"
                        },
                        {
                            "id": 119227,
                            "name": "Jerome",
                            "latitude": "39.76755000",
                            "longitude": "-89.68066000"
                        },
                        {
                            "id": 119232,
                            "name": "Jersey County",
                            "latitude": "39.08566000",
                            "longitude": "-90.35668000"
                        },
                        {
                            "id": 119235,
                            "name": "Jerseyville",
                            "latitude": "39.12005000",
                            "longitude": "-90.32845000"
                        },
                        {
                            "id": 119249,
                            "name": "Jo Daviess County",
                            "latitude": "42.36571000",
                            "longitude": "-90.21241000"
                        },
                        {
                            "id": 119253,
                            "name": "Johnsburg",
                            "latitude": "42.38002000",
                            "longitude": "-88.24203000"
                        },
                        {
                            "id": 119262,
                            "name": "Johnson County",
                            "latitude": "37.45963000",
                            "longitude": "-88.88089000"
                        },
                        {
                            "id": 119280,
                            "name": "Johnston City",
                            "latitude": "37.82061000",
                            "longitude": "-88.92757000"
                        },
                        {
                            "id": 119288,
                            "name": "Joliet",
                            "latitude": "41.52519000",
                            "longitude": "-88.08340000"
                        },
                        {
                            "id": 119301,
                            "name": "Jonesboro",
                            "latitude": "37.45172000",
                            "longitude": "-89.26814000"
                        },
                        {
                            "id": 119345,
                            "name": "Justice",
                            "latitude": "41.74448000",
                            "longitude": "-87.83783000"
                        },
                        {
                            "id": 119377,
                            "name": "Kane County",
                            "latitude": "41.93894000",
                            "longitude": "-88.42866000"
                        },
                        {
                            "id": 119380,
                            "name": "Kankakee",
                            "latitude": "41.12003000",
                            "longitude": "-87.86115000"
                        },
                        {
                            "id": 119381,
                            "name": "Kankakee County",
                            "latitude": "41.13770000",
                            "longitude": "-87.86180000"
                        },
                        {
                            "id": 119447,
                            "name": "Kendall County",
                            "latitude": "41.59056000",
                            "longitude": "-88.42885000"
                        },
                        {
                            "id": 119456,
                            "name": "Kenilworth",
                            "latitude": "42.08586000",
                            "longitude": "-87.71756000"
                        },
                        {
                            "id": 119504,
                            "name": "Kenwood",
                            "latitude": "41.80920000",
                            "longitude": "-87.59755000"
                        },
                        {
                            "id": 119532,
                            "name": "Kewanee",
                            "latitude": "41.24559000",
                            "longitude": "-89.92483000"
                        },
                        {
                            "id": 119555,
                            "name": "Kildeer",
                            "latitude": "42.17058000",
                            "longitude": "-88.04785000"
                        },
                        {
                            "id": 119573,
                            "name": "Kincaid",
                            "latitude": "39.58866000",
                            "longitude": "-89.41454000"
                        },
                        {
                            "id": 119624,
                            "name": "Kingston",
                            "latitude": "42.09975000",
                            "longitude": "-88.75898000"
                        },
                        {
                            "id": 119651,
                            "name": "Kirkland",
                            "latitude": "42.09253000",
                            "longitude": "-88.85121000"
                        },
                        {
                            "id": 119679,
                            "name": "Knollwood",
                            "latitude": "42.28613000",
                            "longitude": "-87.88563000"
                        },
                        {
                            "id": 119689,
                            "name": "Knox County",
                            "latitude": "40.93182000",
                            "longitude": "-90.21326000"
                        },
                        {
                            "id": 119696,
                            "name": "Knoxville",
                            "latitude": "40.90837000",
                            "longitude": "-90.28485000"
                        },
                        {
                            "id": 119752,
                            "name": "La Grange",
                            "latitude": "41.80503000",
                            "longitude": "-87.86923000"
                        },
                        {
                            "id": 119753,
                            "name": "La Grange Park",
                            "latitude": "41.83475000",
                            "longitude": "-87.86173000"
                        },
                        {
                            "id": 119757,
                            "name": "La Harpe",
                            "latitude": "40.58337000",
                            "longitude": "-90.96931000"
                        },
                        {
                            "id": 119785,
                            "name": "La Salle",
                            "latitude": "41.33337000",
                            "longitude": "-89.09175000"
                        },
                        {
                            "id": 119818,
                            "name": "Lacon",
                            "latitude": "41.02476000",
                            "longitude": "-89.41120000"
                        },
                        {
                            "id": 119822,
                            "name": "Ladd",
                            "latitude": "41.38253000",
                            "longitude": "-89.21897000"
                        },
                        {
                            "id": 119869,
                            "name": "Lake Barrington",
                            "latitude": "42.21252000",
                            "longitude": "-88.15258000"
                        },
                        {
                            "id": 119871,
                            "name": "Lake Bluff",
                            "latitude": "42.27891000",
                            "longitude": "-87.83424000"
                        },
                        {
                            "id": 119874,
                            "name": "Lake Camelot",
                            "latitude": "40.63065000",
                            "longitude": "-89.74210000"
                        },
                        {
                            "id": 119876,
                            "name": "Lake Catherine",
                            "latitude": "42.47919000",
                            "longitude": "-88.13342000"
                        },
                        {
                            "id": 119891,
                            "name": "Lake County",
                            "latitude": "42.34941000",
                            "longitude": "-87.86179000"
                        },
                        {
                            "id": 119910,
                            "name": "Lake Forest",
                            "latitude": "42.25863000",
                            "longitude": "-87.84063000"
                        },
                        {
                            "id": 119921,
                            "name": "Lake Holiday",
                            "latitude": "41.61292000",
                            "longitude": "-88.67209000"
                        },
                        {
                            "id": 120004,
                            "name": "Lake in the Hills",
                            "latitude": "42.18169000",
                            "longitude": "-88.33036000"
                        },
                        {
                            "id": 120006,
                            "name": "Lake of the Woods",
                            "latitude": "40.20642000",
                            "longitude": "-88.36867000"
                        },
                        {
                            "id": 119983,
                            "name": "Lake Summerset",
                            "latitude": "42.45446000",
                            "longitude": "-89.38956000"
                        },
                        {
                            "id": 119988,
                            "name": "Lake Villa",
                            "latitude": "42.41697000",
                            "longitude": "-88.07397000"
                        },
                        {
                            "id": 120002,
                            "name": "Lake Zurich",
                            "latitude": "42.19697000",
                            "longitude": "-88.09341000"
                        },
                        {
                            "id": 120022,
                            "name": "Lakemoor",
                            "latitude": "42.32863000",
                            "longitude": "-88.19897000"
                        },
                        {
                            "id": 120047,
                            "name": "Lakewood",
                            "latitude": "42.22919000",
                            "longitude": "-88.35509000"
                        },
                        {
                            "id": 120055,
                            "name": "Lakewood Shores",
                            "latitude": "41.28170000",
                            "longitude": "-88.14478000"
                        },
                        {
                            "id": 120079,
                            "name": "Lanark",
                            "latitude": "42.10225000",
                            "longitude": "-89.83345000"
                        },
                        {
                            "id": 120126,
                            "name": "Lansing",
                            "latitude": "41.56476000",
                            "longitude": "-87.53893000"
                        },
                        {
                            "id": 119806,
                            "name": "LaSalle County",
                            "latitude": "41.34399000",
                            "longitude": "-88.88595000"
                        },
                        {
                            "id": 120231,
                            "name": "Lawrence County",
                            "latitude": "38.71995000",
                            "longitude": "-87.72673000"
                        },
                        {
                            "id": 120244,
                            "name": "Lawrenceville",
                            "latitude": "38.72921000",
                            "longitude": "-87.68169000"
                        },
                        {
                            "id": 120262,
                            "name": "Le Roy",
                            "latitude": "40.35198000",
                            "longitude": "-88.76424000"
                        },
                        {
                            "id": 120284,
                            "name": "Lebanon",
                            "latitude": "38.60394000",
                            "longitude": "-89.80732000"
                        },
                        {
                            "id": 120317,
                            "name": "Lee County",
                            "latitude": "41.74619000",
                            "longitude": "-89.30039000"
                        },
                        {
                            "id": 120352,
                            "name": "Leland Grove",
                            "latitude": "39.77700000",
                            "longitude": "-89.67927000"
                        },
                        {
                            "id": 120360,
                            "name": "Lemont",
                            "latitude": "41.67364000",
                            "longitude": "-88.00173000"
                        },
                        {
                            "id": 120366,
                            "name": "Lena",
                            "latitude": "42.37946000",
                            "longitude": "-89.82234000"
                        },
                        {
                            "id": 120431,
                            "name": "Lewistown",
                            "latitude": "40.39310000",
                            "longitude": "-90.15484000"
                        },
                        {
                            "id": 120449,
                            "name": "Lexington",
                            "latitude": "40.64142000",
                            "longitude": "-88.78340000"
                        },
                        {
                            "id": 120476,
                            "name": "Libertyville",
                            "latitude": "42.28308000",
                            "longitude": "-87.95313000"
                        },
                        {
                            "id": 120487,
                            "name": "Lily Lake",
                            "latitude": "41.94892000",
                            "longitude": "-88.47786000"
                        },
                        {
                            "id": 120493,
                            "name": "Limestone",
                            "latitude": "41.13237000",
                            "longitude": "-87.96840000"
                        },
                        {
                            "id": 120503,
                            "name": "Lincoln",
                            "latitude": "40.14838000",
                            "longitude": "-89.36482000"
                        },
                        {
                            "id": 120540,
                            "name": "Lincoln Park",
                            "latitude": "41.92170000",
                            "longitude": "-87.64783000"
                        },
                        {
                            "id": 120546,
                            "name": "Lincoln Square",
                            "latitude": "41.97587000",
                            "longitude": "-87.68922000"
                        },
                        {
                            "id": 120550,
                            "name": "Lincolnshire",
                            "latitude": "42.19002000",
                            "longitude": "-87.90840000"
                        },
                        {
                            "id": 120555,
                            "name": "Lincolnwood",
                            "latitude": "42.00448000",
                            "longitude": "-87.73006000"
                        },
                        {
                            "id": 120567,
                            "name": "Lindenhurst",
                            "latitude": "42.41058000",
                            "longitude": "-88.02619000"
                        },
                        {
                            "id": 120602,
                            "name": "Lisle",
                            "latitude": "41.80114000",
                            "longitude": "-88.07479000"
                        },
                        {
                            "id": 120603,
                            "name": "Litchfield",
                            "latitude": "39.17533000",
                            "longitude": "-89.65426000"
                        },
                        {
                            "id": 120656,
                            "name": "Livingston County",
                            "latitude": "40.89156000",
                            "longitude": "-88.55772000"
                        },
                        {
                            "id": 120680,
                            "name": "Lockport",
                            "latitude": "41.58948000",
                            "longitude": "-88.05784000"
                        },
                        {
                            "id": 120700,
                            "name": "Logan County",
                            "latitude": "40.12456000",
                            "longitude": "-89.36755000"
                        },
                        {
                            "id": 120705,
                            "name": "Logan Square",
                            "latitude": "41.92337000",
                            "longitude": "-87.69922000"
                        },
                        {
                            "id": 120714,
                            "name": "Lombard",
                            "latitude": "41.88003000",
                            "longitude": "-88.00784000"
                        },
                        {
                            "id": 120742,
                            "name": "Long Creek",
                            "latitude": "39.81198000",
                            "longitude": "-88.84757000"
                        },
                        {
                            "id": 120743,
                            "name": "Long Grove",
                            "latitude": "42.17836000",
                            "longitude": "-87.99785000"
                        },
                        {
                            "id": 120746,
                            "name": "Long Lake",
                            "latitude": "42.37085000",
                            "longitude": "-88.12758000"
                        },
                        {
                            "id": 120812,
                            "name": "Louisville",
                            "latitude": "38.77227000",
                            "longitude": "-88.50255000"
                        },
                        {
                            "id": 120826,
                            "name": "Loves Park",
                            "latitude": "42.32002000",
                            "longitude": "-89.05816000"
                        },
                        {
                            "id": 120831,
                            "name": "Lovington",
                            "latitude": "39.71559000",
                            "longitude": "-88.63256000"
                        },
                        {
                            "id": 120843,
                            "name": "Lower West Side",
                            "latitude": "41.85420000",
                            "longitude": "-87.66561000"
                        },
                        {
                            "id": 120933,
                            "name": "Lynwood",
                            "latitude": "41.52642000",
                            "longitude": "-87.53865000"
                        },
                        {
                            "id": 120942,
                            "name": "Lyons",
                            "latitude": "41.81337000",
                            "longitude": "-87.81811000"
                        },
                        {
                            "id": 120955,
                            "name": "Machesney Park",
                            "latitude": "42.34724000",
                            "longitude": "-89.03900000"
                        },
                        {
                            "id": 120960,
                            "name": "Mackinaw",
                            "latitude": "40.53698000",
                            "longitude": "-89.35759000"
                        },
                        {
                            "id": 120961,
                            "name": "Macomb",
                            "latitude": "40.45921000",
                            "longitude": "-90.67180000"
                        },
                        {
                            "id": 120963,
                            "name": "Macon",
                            "latitude": "39.71282000",
                            "longitude": "-88.99702000"
                        },
                        {
                            "id": 120969,
                            "name": "Macon County",
                            "latitude": "39.86000000",
                            "longitude": "-88.96160000"
                        },
                        {
                            "id": 120973,
                            "name": "Macoupin County",
                            "latitude": "39.26102000",
                            "longitude": "-89.92443000"
                        },
                        {
                            "id": 120987,
                            "name": "Madison",
                            "latitude": "38.68255000",
                            "longitude": "-90.15705000"
                        },
                        {
                            "id": 121006,
                            "name": "Madison County",
                            "latitude": "38.82985000",
                            "longitude": "-89.90517000"
                        },
                        {
                            "id": 121045,
                            "name": "Mahomet",
                            "latitude": "40.19531000",
                            "longitude": "-88.40422000"
                        },
                        {
                            "id": 121067,
                            "name": "Malta",
                            "latitude": "41.92975000",
                            "longitude": "-88.86092000"
                        },
                        {
                            "id": 121114,
                            "name": "Manhattan",
                            "latitude": "41.42253000",
                            "longitude": "-87.98589000"
                        },
                        {
                            "id": 121124,
                            "name": "Manito",
                            "latitude": "40.42587000",
                            "longitude": "-89.77928000"
                        },
                        {
                            "id": 121155,
                            "name": "Manteno",
                            "latitude": "41.25059000",
                            "longitude": "-87.83143000"
                        },
                        {
                            "id": 121170,
                            "name": "Maple Park",
                            "latitude": "41.90753000",
                            "longitude": "-88.59925000"
                        },
                        {
                            "id": 121202,
                            "name": "Marengo",
                            "latitude": "42.24863000",
                            "longitude": "-88.60843000"
                        },
                        {
                            "id": 121233,
                            "name": "Marion",
                            "latitude": "37.73061000",
                            "longitude": "-88.93313000"
                        },
                        {
                            "id": 121251,
                            "name": "Marion County",
                            "latitude": "38.64959000",
                            "longitude": "-88.91897000"
                        },
                        {
                            "id": 121266,
                            "name": "Marissa",
                            "latitude": "38.25005000",
                            "longitude": "-89.75010000"
                        },
                        {
                            "id": 121270,
                            "name": "Markham",
                            "latitude": "41.59365000",
                            "longitude": "-87.69477000"
                        },
                        {
                            "id": 121291,
                            "name": "Maroa",
                            "latitude": "40.03643000",
                            "longitude": "-88.95703000"
                        },
                        {
                            "id": 121295,
                            "name": "Marquette Heights",
                            "latitude": "40.61754000",
                            "longitude": "-89.60038000"
                        },
                        {
                            "id": 121300,
                            "name": "Marseilles",
                            "latitude": "41.33087000",
                            "longitude": "-88.70813000"
                        },
                        {
                            "id": 121302,
                            "name": "Marshall",
                            "latitude": "39.39143000",
                            "longitude": "-87.69364000"
                        },
                        {
                            "id": 121318,
                            "name": "Marshall County",
                            "latitude": "41.03317000",
                            "longitude": "-89.34478000"
                        },
                        {
                            "id": 121349,
                            "name": "Martinsville",
                            "latitude": "39.33559000",
                            "longitude": "-87.88198000"
                        },
                        {
                            "id": 121363,
                            "name": "Maryville",
                            "latitude": "38.72366000",
                            "longitude": "-89.95593000"
                        },
                        {
                            "id": 121369,
                            "name": "Mascoutah",
                            "latitude": "38.49033000",
                            "longitude": "-89.79315000"
                        },
                        {
                            "id": 121376,
                            "name": "Mason City",
                            "latitude": "40.20227000",
                            "longitude": "-89.69816000"
                        },
                        {
                            "id": 121380,
                            "name": "Mason County",
                            "latitude": "40.23965000",
                            "longitude": "-89.91678000"
                        },
                        {
                            "id": 121388,
                            "name": "Massac County",
                            "latitude": "37.21903000",
                            "longitude": "-88.70774000"
                        },
                        {
                            "id": 121411,
                            "name": "Matteson",
                            "latitude": "41.50392000",
                            "longitude": "-87.71310000"
                        },
                        {
                            "id": 121414,
                            "name": "Mattoon",
                            "latitude": "39.48309000",
                            "longitude": "-88.37283000"
                        },
                        {
                            "id": 121457,
                            "name": "Maywood",
                            "latitude": "41.87920000",
                            "longitude": "-87.84312000"
                        },
                        {
                            "id": 121489,
                            "name": "McCullom Lake",
                            "latitude": "42.36835000",
                            "longitude": "-88.29259000"
                        },
                        {
                            "id": 121494,
                            "name": "McDonough County",
                            "latitude": "40.45621000",
                            "longitude": "-90.67791000"
                        },
                        {
                            "id": 121508,
                            "name": "McHenry",
                            "latitude": "42.33335000",
                            "longitude": "-88.26675000"
                        },
                        {
                            "id": 121509,
                            "name": "McHenry County",
                            "latitude": "42.32439000",
                            "longitude": "-88.45245000"
                        },
                        {
                            "id": 121523,
                            "name": "McKinley Park",
                            "latitude": "41.83170000",
                            "longitude": "-87.67366000"
                        },
                        {
                            "id": 121529,
                            "name": "McLean County",
                            "latitude": "40.49089000",
                            "longitude": "-88.84732000"
                        },
                        {
                            "id": 121531,
                            "name": "McLeansboro",
                            "latitude": "38.09338000",
                            "longitude": "-88.53561000"
                        },
                        {
                            "id": 121632,
                            "name": "Melrose Park",
                            "latitude": "41.90059000",
                            "longitude": "-87.85673000"
                        },
                        {
                            "id": 121648,
                            "name": "Menard County",
                            "latitude": "40.02740000",
                            "longitude": "-89.80217000"
                        },
                        {
                            "id": 121656,
                            "name": "Mendota",
                            "latitude": "41.54725000",
                            "longitude": "-89.11759000"
                        },
                        {
                            "id": 121677,
                            "name": "Mercer County",
                            "latitude": "41.20534000",
                            "longitude": "-90.74141000"
                        },
                        {
                            "id": 121688,
                            "name": "Meredosia",
                            "latitude": "39.83116000",
                            "longitude": "-90.55957000"
                        },
                        {
                            "id": 121713,
                            "name": "Merrionette Park",
                            "latitude": "41.68420000",
                            "longitude": "-87.70033000"
                        },
                        {
                            "id": 121731,
                            "name": "Metamora",
                            "latitude": "40.79059000",
                            "longitude": "-89.36064000"
                        },
                        {
                            "id": 121736,
                            "name": "Metropolis",
                            "latitude": "37.15117000",
                            "longitude": "-88.73200000"
                        },
                        {
                            "id": 121808,
                            "name": "Midlothian",
                            "latitude": "41.62531000",
                            "longitude": "-87.71755000"
                        },
                        {
                            "id": 121837,
                            "name": "Milan",
                            "latitude": "41.45309000",
                            "longitude": "-90.57208000"
                        },
                        {
                            "id": 121851,
                            "name": "Milford",
                            "latitude": "40.62837000",
                            "longitude": "-87.69614000"
                        },
                        {
                            "id": 121901,
                            "name": "Millstadt",
                            "latitude": "38.46144000",
                            "longitude": "-90.09178000"
                        },
                        {
                            "id": 121948,
                            "name": "Minier",
                            "latitude": "40.43365000",
                            "longitude": "-89.31315000"
                        },
                        {
                            "id": 121961,
                            "name": "Minonk",
                            "latitude": "40.90448000",
                            "longitude": "-89.03452000"
                        },
                        {
                            "id": 121962,
                            "name": "Minooka",
                            "latitude": "41.45531000",
                            "longitude": "-88.26173000"
                        },
                        {
                            "id": 121995,
                            "name": "Mitchell",
                            "latitude": "38.76199000",
                            "longitude": "-90.08538000"
                        },
                        {
                            "id": 122024,
                            "name": "Mokena",
                            "latitude": "41.52614000",
                            "longitude": "-87.88922000"
                        },
                        {
                            "id": 122027,
                            "name": "Moline",
                            "latitude": "41.50670000",
                            "longitude": "-90.51513000"
                        },
                        {
                            "id": 122030,
                            "name": "Momence",
                            "latitude": "41.16670000",
                            "longitude": "-87.66281000"
                        },
                        {
                            "id": 122037,
                            "name": "Monee",
                            "latitude": "41.42003000",
                            "longitude": "-87.74171000"
                        },
                        {
                            "id": 122042,
                            "name": "Monmouth",
                            "latitude": "40.91143000",
                            "longitude": "-90.64736000"
                        },
                        {
                            "id": 122071,
                            "name": "Monroe County",
                            "latitude": "38.27865000",
                            "longitude": "-90.17738000"
                        },
                        {
                            "id": 122133,
                            "name": "Montgomery",
                            "latitude": "41.73058000",
                            "longitude": "-88.34590000"
                        },
                        {
                            "id": 122142,
                            "name": "Montgomery County",
                            "latitude": "39.23104000",
                            "longitude": "-89.47887000"
                        },
                        {
                            "id": 122166,
                            "name": "Monticello",
                            "latitude": "40.02781000",
                            "longitude": "-88.57340000"
                        },
                        {
                            "id": 122238,
                            "name": "Morgan County",
                            "latitude": "39.71556000",
                            "longitude": "-90.20150000"
                        },
                        {
                            "id": 122247,
                            "name": "Morgan Park",
                            "latitude": "41.69031000",
                            "longitude": "-87.66672000"
                        },
                        {
                            "id": 122267,
                            "name": "Morris",
                            "latitude": "41.35725000",
                            "longitude": "-88.42118000"
                        },
                        {
                            "id": 122276,
                            "name": "Morrison",
                            "latitude": "41.80975000",
                            "longitude": "-89.96512000"
                        },
                        {
                            "id": 122278,
                            "name": "Morrisonville",
                            "latitude": "39.42005000",
                            "longitude": "-89.45565000"
                        },
                        {
                            "id": 122293,
                            "name": "Morton",
                            "latitude": "40.61282000",
                            "longitude": "-89.45926000"
                        },
                        {
                            "id": 122298,
                            "name": "Morton Grove",
                            "latitude": "42.04059000",
                            "longitude": "-87.78256000"
                        },
                        {
                            "id": 122317,
                            "name": "Moultrie County",
                            "latitude": "39.64148000",
                            "longitude": "-88.61930000"
                        },
                        {
                            "id": 122320,
                            "name": "Mound City",
                            "latitude": "37.08533000",
                            "longitude": "-89.16257000"
                        },
                        {
                            "id": 122335,
                            "name": "Mount Carmel",
                            "latitude": "38.41088000",
                            "longitude": "-87.76142000"
                        },
                        {
                            "id": 122338,
                            "name": "Mount Carroll",
                            "latitude": "42.09502000",
                            "longitude": "-89.97818000"
                        },
                        {
                            "id": 122345,
                            "name": "Mount Greenwood",
                            "latitude": "41.69809000",
                            "longitude": "-87.70866000"
                        },
                        {
                            "id": 122363,
                            "name": "Mount Morris",
                            "latitude": "42.05031000",
                            "longitude": "-89.43122000"
                        },
                        {
                            "id": 122368,
                            "name": "Mount Olive",
                            "latitude": "39.07227000",
                            "longitude": "-89.72731000"
                        },
                        {
                            "id": 122385,
                            "name": "Mount Prospect",
                            "latitude": "42.06642000",
                            "longitude": "-87.93729000"
                        },
                        {
                            "id": 122386,
                            "name": "Mount Pulaski",
                            "latitude": "40.01088000",
                            "longitude": "-89.28231000"
                        },
                        {
                            "id": 122390,
                            "name": "Mount Sterling",
                            "latitude": "39.98727000",
                            "longitude": "-90.76346000"
                        },
                        {
                            "id": 122396,
                            "name": "Mount Vernon",
                            "latitude": "38.31727000",
                            "longitude": "-88.90312000"
                        },
                        {
                            "id": 122409,
                            "name": "Mount Zion",
                            "latitude": "39.77143000",
                            "longitude": "-88.87424000"
                        },
                        {
                            "id": 122444,
                            "name": "Moweaqua",
                            "latitude": "39.62476000",
                            "longitude": "-89.01897000"
                        },
                        {
                            "id": 122468,
                            "name": "Mundelein",
                            "latitude": "42.26308000",
                            "longitude": "-88.00397000"
                        },
                        {
                            "id": 122490,
                            "name": "Murphysboro",
                            "latitude": "37.76450000",
                            "longitude": "-89.33509000"
                        },
                        {
                            "id": 122550,
                            "name": "Naperville",
                            "latitude": "41.78586000",
                            "longitude": "-88.14729000"
                        },
                        {
                            "id": 122574,
                            "name": "Nashville",
                            "latitude": "38.34366000",
                            "longitude": "-89.38064000"
                        },
                        {
                            "id": 122596,
                            "name": "Nauvoo",
                            "latitude": "40.55004000",
                            "longitude": "-91.38487000"
                        },
                        {
                            "id": 122607,
                            "name": "Near North Side",
                            "latitude": "41.90003000",
                            "longitude": "-87.63450000"
                        },
                        {
                            "id": 122608,
                            "name": "Near South Side",
                            "latitude": "41.85670000",
                            "longitude": "-87.62477000"
                        },
                        {
                            "id": 122632,
                            "name": "Neoga",
                            "latitude": "39.31948000",
                            "longitude": "-88.45283000"
                        },
                        {
                            "id": 122656,
                            "name": "New Athens",
                            "latitude": "38.32644000",
                            "longitude": "-89.87705000"
                        },
                        {
                            "id": 122658,
                            "name": "New Baden",
                            "latitude": "38.53505000",
                            "longitude": "-89.70065000"
                        },
                        {
                            "id": 122663,
                            "name": "New Berlin",
                            "latitude": "39.72533000",
                            "longitude": "-89.91066000"
                        },
                        {
                            "id": 122694,
                            "name": "New City",
                            "latitude": "41.80753000",
                            "longitude": "-87.65644000"
                        },
                        {
                            "id": 122737,
                            "name": "New Lenox",
                            "latitude": "41.51198000",
                            "longitude": "-87.96561000"
                        },
                        {
                            "id": 122802,
                            "name": "Newark",
                            "latitude": "41.53697000",
                            "longitude": "-88.58341000"
                        },
                        {
                            "id": 122864,
                            "name": "Newton",
                            "latitude": "38.99088000",
                            "longitude": "-88.16254000"
                        },
                        {
                            "id": 122900,
                            "name": "Niles",
                            "latitude": "42.01892000",
                            "longitude": "-87.80284000"
                        },
                        {
                            "id": 122930,
                            "name": "Nokomis",
                            "latitude": "39.30116000",
                            "longitude": "-89.28508000"
                        },
                        {
                            "id": 122948,
                            "name": "Normal",
                            "latitude": "40.51420000",
                            "longitude": "-88.99063000"
                        },
                        {
                            "id": 122953,
                            "name": "Norridge",
                            "latitude": "41.96336000",
                            "longitude": "-87.82728000"
                        },
                        {
                            "id": 122956,
                            "name": "Norris City",
                            "latitude": "37.98116000",
                            "longitude": "-88.32921000"
                        },
                        {
                            "id": 122970,
                            "name": "North Aurora",
                            "latitude": "41.80614000",
                            "longitude": "-88.32730000"
                        },
                        {
                            "id": 122973,
                            "name": "North Barrington",
                            "latitude": "42.20780000",
                            "longitude": "-88.14063000"
                        },
                        {
                            "id": 123003,
                            "name": "North Center",
                            "latitude": "41.95392000",
                            "longitude": "-87.67895000"
                        },
                        {
                            "id": 123006,
                            "name": "North Chicago",
                            "latitude": "42.32558000",
                            "longitude": "-87.84118000"
                        },
                        {
                            "id": 123055,
                            "name": "North Lawndale",
                            "latitude": "41.86003000",
                            "longitude": "-87.71839000"
                        },
                        {
                            "id": 123077,
                            "name": "North Pekin",
                            "latitude": "40.61504000",
                            "longitude": "-89.62232000"
                        },
                        {
                            "id": 123079,
                            "name": "North Peoria",
                            "latitude": "40.71754000",
                            "longitude": "-89.58426000"
                        },
                        {
                            "id": 123096,
                            "name": "North Riverside",
                            "latitude": "41.84281000",
                            "longitude": "-87.82311000"
                        },
                        {
                            "id": 123141,
                            "name": "Northbrook",
                            "latitude": "42.12753000",
                            "longitude": "-87.82895000"
                        },
                        {
                            "id": 123151,
                            "name": "Northfield",
                            "latitude": "42.09975000",
                            "longitude": "-87.78090000"
                        },
                        {
                            "id": 123158,
                            "name": "Northlake",
                            "latitude": "41.91725000",
                            "longitude": "-87.89562000"
                        },
                        {
                            "id": 123226,
                            "name": "O'Fallon",
                            "latitude": "38.59227000",
                            "longitude": "-89.91121000"
                        },
                        {
                            "id": 123230,
                            "name": "Oak Brook",
                            "latitude": "41.83281000",
                            "longitude": "-87.92895000"
                        },
                        {
                            "id": 123233,
                            "name": "Oak Forest",
                            "latitude": "41.60281000",
                            "longitude": "-87.74394000"
                        },
                        {
                            "id": 123252,
                            "name": "Oak Lawn",
                            "latitude": "41.71087000",
                            "longitude": "-87.75811000"
                        },
                        {
                            "id": 123255,
                            "name": "Oak Park",
                            "latitude": "41.88503000",
                            "longitude": "-87.78450000"
                        },
                        {
                            "id": 123269,
                            "name": "Oakbrook Terrace",
                            "latitude": "41.85003000",
                            "longitude": "-87.96451000"
                        },
                        {
                            "id": 123309,
                            "name": "Oakwood",
                            "latitude": "40.11615000",
                            "longitude": "-87.77836000"
                        },
                        {
                            "id": 123312,
                            "name": "Oakwood Hills",
                            "latitude": "42.24641000",
                            "longitude": "-88.24286000"
                        },
                        {
                            "id": 123319,
                            "name": "Oblong",
                            "latitude": "39.00199000",
                            "longitude": "-87.90892000"
                        },
                        {
                            "id": 123361,
                            "name": "Odin",
                            "latitude": "38.61727000",
                            "longitude": "-89.05229000"
                        },
                        {
                            "id": 123376,
                            "name": "Ogle County",
                            "latitude": "42.04264000",
                            "longitude": "-89.32065000"
                        },
                        {
                            "id": 123377,
                            "name": "Oglesby",
                            "latitude": "41.29531000",
                            "longitude": "-89.05953000"
                        },
                        {
                            "id": 123397,
                            "name": "Okawville",
                            "latitude": "38.43422000",
                            "longitude": "-89.55037000"
                        },
                        {
                            "id": 123455,
                            "name": "Olney",
                            "latitude": "38.73088000",
                            "longitude": "-88.08532000"
                        },
                        {
                            "id": 123460,
                            "name": "Olympia Fields",
                            "latitude": "41.51337000",
                            "longitude": "-87.67421000"
                        },
                        {
                            "id": 123470,
                            "name": "Onarga",
                            "latitude": "40.71504000",
                            "longitude": "-88.00615000"
                        },
                        {
                            "id": 123500,
                            "name": "Oquawka",
                            "latitude": "40.93198000",
                            "longitude": "-90.94709000"
                        },
                        {
                            "id": 123546,
                            "name": "Oregon",
                            "latitude": "42.01475000",
                            "longitude": "-89.33233000"
                        },
                        {
                            "id": 123556,
                            "name": "Orion",
                            "latitude": "41.35476000",
                            "longitude": "-90.38152000"
                        },
                        {
                            "id": 123560,
                            "name": "Orland Hills",
                            "latitude": "41.58531000",
                            "longitude": "-87.84311000"
                        },
                        {
                            "id": 123561,
                            "name": "Orland Park",
                            "latitude": "41.63031000",
                            "longitude": "-87.85394000"
                        },
                        {
                            "id": 123622,
                            "name": "Oswego",
                            "latitude": "41.68281000",
                            "longitude": "-88.35146000"
                        },
                        {
                            "id": 123637,
                            "name": "Ottawa",
                            "latitude": "41.34559000",
                            "longitude": "-88.84258000"
                        },
                        {
                            "id": 123731,
                            "name": "Palatine",
                            "latitude": "42.11030000",
                            "longitude": "-88.03424000"
                        },
                        {
                            "id": 123736,
                            "name": "Palestine",
                            "latitude": "39.00365000",
                            "longitude": "-87.61280000"
                        },
                        {
                            "id": 123788,
                            "name": "Palos Heights",
                            "latitude": "41.66809000",
                            "longitude": "-87.79644000"
                        },
                        {
                            "id": 123789,
                            "name": "Palos Hills",
                            "latitude": "41.69670000",
                            "longitude": "-87.81700000"
                        },
                        {
                            "id": 123790,
                            "name": "Palos Park",
                            "latitude": "41.66725000",
                            "longitude": "-87.83033000"
                        },
                        {
                            "id": 123796,
                            "name": "Pana",
                            "latitude": "39.38893000",
                            "longitude": "-89.08008000"
                        },
                        {
                            "id": 123830,
                            "name": "Paris",
                            "latitude": "39.61115000",
                            "longitude": "-87.69614000"
                        },
                        {
                            "id": 123839,
                            "name": "Park City",
                            "latitude": "42.34836000",
                            "longitude": "-87.88424000"
                        },
                        {
                            "id": 123845,
                            "name": "Park Forest",
                            "latitude": "41.49142000",
                            "longitude": "-87.67449000"
                        },
                        {
                            "id": 123851,
                            "name": "Park Ridge",
                            "latitude": "42.01114000",
                            "longitude": "-87.84062000"
                        },
                        {
                            "id": 123933,
                            "name": "Pawnee",
                            "latitude": "39.59172000",
                            "longitude": "-89.58037000"
                        },
                        {
                            "id": 123941,
                            "name": "Paxton",
                            "latitude": "40.46031000",
                            "longitude": "-88.09532000"
                        },
                        {
                            "id": 123948,
                            "name": "Payson",
                            "latitude": "39.81699000",
                            "longitude": "-91.24237000"
                        },
                        {
                            "id": 123978,
                            "name": "Pecatonica",
                            "latitude": "42.31391000",
                            "longitude": "-89.35928000"
                        },
                        {
                            "id": 123986,
                            "name": "Pekin",
                            "latitude": "40.56754000",
                            "longitude": "-89.64066000"
                        },
                        {
                            "id": 124046,
                            "name": "Peoria",
                            "latitude": "40.69365000",
                            "longitude": "-89.58899000"
                        },
                        {
                            "id": 124048,
                            "name": "Peoria County",
                            "latitude": "40.78808000",
                            "longitude": "-89.75999000"
                        },
                        {
                            "id": 124049,
                            "name": "Peoria Heights",
                            "latitude": "40.74726000",
                            "longitude": "-89.57398000"
                        },
                        {
                            "id": 124051,
                            "name": "Peotone",
                            "latitude": "41.33226000",
                            "longitude": "-87.78532000"
                        },
                        {
                            "id": 124076,
                            "name": "Perry County",
                            "latitude": "38.08376000",
                            "longitude": "-89.36702000"
                        },
                        {
                            "id": 124095,
                            "name": "Peru",
                            "latitude": "41.32753000",
                            "longitude": "-89.12897000"
                        },
                        {
                            "id": 124106,
                            "name": "Petersburg",
                            "latitude": "40.01172000",
                            "longitude": "-89.84817000"
                        },
                        {
                            "id": 124143,
                            "name": "Philo",
                            "latitude": "40.00698000",
                            "longitude": "-88.15810000"
                        },
                        {
                            "id": 124146,
                            "name": "Phoenix",
                            "latitude": "41.61115000",
                            "longitude": "-87.63477000"
                        },
                        {
                            "id": 124152,
                            "name": "Piatt County",
                            "latitude": "40.01037000",
                            "longitude": "-88.59109000"
                        },
                        {
                            "id": 124188,
                            "name": "Pike County",
                            "latitude": "39.62250000",
                            "longitude": "-90.88629000"
                        },
                        {
                            "id": 124210,
                            "name": "Pinckneyville",
                            "latitude": "38.08033000",
                            "longitude": "-89.38203000"
                        },
                        {
                            "id": 124269,
                            "name": "Pingree Grove",
                            "latitude": "42.06864000",
                            "longitude": "-88.41342000"
                        },
                        {
                            "id": 124284,
                            "name": "Pistakee Highlands",
                            "latitude": "42.40863000",
                            "longitude": "-88.20648000"
                        },
                        {
                            "id": 124297,
                            "name": "Pittsfield",
                            "latitude": "39.60783000",
                            "longitude": "-90.80513000"
                        },
                        {
                            "id": 124320,
                            "name": "Plainfield",
                            "latitude": "41.62697000",
                            "longitude": "-88.20395000"
                        },
                        {
                            "id": 124344,
                            "name": "Plano",
                            "latitude": "41.66281000",
                            "longitude": "-88.53702000"
                        },
                        {
                            "id": 124454,
                            "name": "Polo",
                            "latitude": "41.98614000",
                            "longitude": "-89.57928000"
                        },
                        {
                            "id": 124473,
                            "name": "Pontiac",
                            "latitude": "40.88087000",
                            "longitude": "-88.62978000"
                        },
                        {
                            "id": 124475,
                            "name": "Pontoon Beach",
                            "latitude": "38.73172000",
                            "longitude": "-90.08038000"
                        },
                        {
                            "id": 124482,
                            "name": "Pope County",
                            "latitude": "37.41276000",
                            "longitude": "-88.56158000"
                        },
                        {
                            "id": 124485,
                            "name": "Poplar Grove",
                            "latitude": "42.36835000",
                            "longitude": "-88.82205000"
                        },
                        {
                            "id": 124498,
                            "name": "Port Barrington",
                            "latitude": "42.24252000",
                            "longitude": "-88.20203000"
                        },
                        {
                            "id": 124499,
                            "name": "Port Byron",
                            "latitude": "41.60642000",
                            "longitude": "-90.33541000"
                        },
                        {
                            "id": 124548,
                            "name": "Portage Park",
                            "latitude": "41.95781000",
                            "longitude": "-87.76506000"
                        },
                        {
                            "id": 124573,
                            "name": "Posen",
                            "latitude": "41.63170000",
                            "longitude": "-87.68144000"
                        },
                        {
                            "id": 124628,
                            "name": "Prairie Grove",
                            "latitude": "42.27863000",
                            "longitude": "-88.26092000"
                        },
                        {
                            "id": 124652,
                            "name": "Prestbury",
                            "latitude": "41.78329000",
                            "longitude": "-88.41764000"
                        },
                        {
                            "id": 124660,
                            "name": "Preston Heights",
                            "latitude": "41.49170000",
                            "longitude": "-88.08172000"
                        },
                        {
                            "id": 124686,
                            "name": "Princeton",
                            "latitude": "41.36809000",
                            "longitude": "-89.46481000"
                        },
                        {
                            "id": 124695,
                            "name": "Princeville",
                            "latitude": "40.92976000",
                            "longitude": "-89.75760000"
                        },
                        {
                            "id": 124704,
                            "name": "Prophetstown",
                            "latitude": "41.67142000",
                            "longitude": "-89.93622000"
                        },
                        {
                            "id": 124708,
                            "name": "Prospect Heights",
                            "latitude": "42.09530000",
                            "longitude": "-87.93757000"
                        },
                        {
                            "id": 124739,
                            "name": "Pulaski County",
                            "latitude": "37.22291000",
                            "longitude": "-89.12657000"
                        },
                        {
                            "id": 124766,
                            "name": "Putnam County",
                            "latitude": "41.20447000",
                            "longitude": "-89.28583000"
                        },
                        {
                            "id": 124796,
                            "name": "Quincy",
                            "latitude": "39.93560000",
                            "longitude": "-91.40987000"
                        },
                        {
                            "id": 124840,
                            "name": "Ramsey",
                            "latitude": "39.14449000",
                            "longitude": "-89.10868000"
                        },
                        {
                            "id": 124875,
                            "name": "Randolph County",
                            "latitude": "38.05212000",
                            "longitude": "-89.82531000"
                        },
                        {
                            "id": 124891,
                            "name": "Rantoul",
                            "latitude": "40.30837000",
                            "longitude": "-88.15588000"
                        },
                        {
                            "id": 124942,
                            "name": "Red Bud",
                            "latitude": "38.21172000",
                            "longitude": "-89.99427000"
                        },
                        {
                            "id": 125074,
                            "name": "Richland County",
                            "latitude": "38.71236000",
                            "longitude": "-88.08510000"
                        },
                        {
                            "id": 125089,
                            "name": "Richmond",
                            "latitude": "42.47585000",
                            "longitude": "-88.30593000"
                        },
                        {
                            "id": 125108,
                            "name": "Richton Park",
                            "latitude": "41.48448000",
                            "longitude": "-87.70338000"
                        },
                        {
                            "id": 125179,
                            "name": "River Forest",
                            "latitude": "41.89781000",
                            "longitude": "-87.81395000"
                        },
                        {
                            "id": 125180,
                            "name": "River Grove",
                            "latitude": "41.92586000",
                            "longitude": "-87.83589000"
                        },
                        {
                            "id": 125193,
                            "name": "Riverdale",
                            "latitude": "41.63337000",
                            "longitude": "-87.63310000"
                        },
                        {
                            "id": 125206,
                            "name": "Riverside",
                            "latitude": "41.83503000",
                            "longitude": "-87.82284000"
                        },
                        {
                            "id": 125211,
                            "name": "Riverton",
                            "latitude": "39.84422000",
                            "longitude": "-89.53954000"
                        },
                        {
                            "id": 125220,
                            "name": "Riverwoods",
                            "latitude": "42.16753000",
                            "longitude": "-87.89701000"
                        },
                        {
                            "id": 125229,
                            "name": "Roanoke",
                            "latitude": "40.79615000",
                            "longitude": "-89.19730000"
                        },
                        {
                            "id": 125235,
                            "name": "Robbins",
                            "latitude": "41.64392000",
                            "longitude": "-87.70366000"
                        },
                        {
                            "id": 125253,
                            "name": "Robinson",
                            "latitude": "39.00532000",
                            "longitude": "-87.73919000"
                        },
                        {
                            "id": 125259,
                            "name": "Rochelle",
                            "latitude": "41.92392000",
                            "longitude": "-89.06871000"
                        },
                        {
                            "id": 125261,
                            "name": "Rochester",
                            "latitude": "39.74949000",
                            "longitude": "-89.53176000"
                        },
                        {
                            "id": 125277,
                            "name": "Rock Falls",
                            "latitude": "41.77975000",
                            "longitude": "-89.68900000"
                        },
                        {
                            "id": 125283,
                            "name": "Rock Island",
                            "latitude": "41.50948000",
                            "longitude": "-90.57875000"
                        },
                        {
                            "id": 125284,
                            "name": "Rock Island County",
                            "latitude": "41.46733000",
                            "longitude": "-90.56743000"
                        },
                        {
                            "id": 125296,
                            "name": "Rockdale",
                            "latitude": "41.50614000",
                            "longitude": "-88.11450000"
                        },
                        {
                            "id": 125300,
                            "name": "Rockford",
                            "latitude": "42.27113000",
                            "longitude": "-89.09400000"
                        },
                        {
                            "id": 125320,
                            "name": "Rockton",
                            "latitude": "42.45252000",
                            "longitude": "-89.07233000"
                        },
                        {
                            "id": 125353,
                            "name": "Rogers Park",
                            "latitude": "42.00864000",
                            "longitude": "-87.66672000"
                        },
                        {
                            "id": 125368,
                            "name": "Rolling Meadows",
                            "latitude": "42.08419000",
                            "longitude": "-88.01313000"
                        },
                        {
                            "id": 125376,
                            "name": "Rome",
                            "latitude": "40.88309000",
                            "longitude": "-89.50259000"
                        },
                        {
                            "id": 125382,
                            "name": "Romeoville",
                            "latitude": "41.64753000",
                            "longitude": "-88.08951000"
                        },
                        {
                            "id": 125389,
                            "name": "Roodhouse",
                            "latitude": "39.48394000",
                            "longitude": "-90.37151000"
                        },
                        {
                            "id": 125399,
                            "name": "Roscoe",
                            "latitude": "42.41335000",
                            "longitude": "-89.00927000"
                        },
                        {
                            "id": 125426,
                            "name": "Roselle",
                            "latitude": "41.98475000",
                            "longitude": "-88.07979000"
                        },
                        {
                            "id": 125430,
                            "name": "Rosemont",
                            "latitude": "41.99531000",
                            "longitude": "-87.88451000"
                        },
                        {
                            "id": 125442,
                            "name": "Rosewood Heights",
                            "latitude": "38.88783000",
                            "longitude": "-90.08483000"
                        },
                        {
                            "id": 125444,
                            "name": "Rosiclare",
                            "latitude": "37.42366000",
                            "longitude": "-88.34615000"
                        },
                        {
                            "id": 125459,
                            "name": "Rossville",
                            "latitude": "40.37920000",
                            "longitude": "-87.66863000"
                        },
                        {
                            "id": 125469,
                            "name": "Round Lake",
                            "latitude": "42.35336000",
                            "longitude": "-88.09341000"
                        },
                        {
                            "id": 125470,
                            "name": "Round Lake Beach",
                            "latitude": "42.37169000",
                            "longitude": "-88.09008000"
                        },
                        {
                            "id": 125471,
                            "name": "Round Lake Heights",
                            "latitude": "42.38002000",
                            "longitude": "-88.10425000"
                        },
                        {
                            "id": 125472,
                            "name": "Round Lake Park",
                            "latitude": "42.35697000",
                            "longitude": "-88.07675000"
                        },
                        {
                            "id": 125483,
                            "name": "Roxana",
                            "latitude": "38.84838000",
                            "longitude": "-90.07622000"
                        },
                        {
                            "id": 125495,
                            "name": "Royalton",
                            "latitude": "37.87699000",
                            "longitude": "-89.11452000"
                        },
                        {
                            "id": 125526,
                            "name": "Rushville",
                            "latitude": "40.12116000",
                            "longitude": "-90.56318000"
                        },
                        {
                            "id": 125599,
                            "name": "Saint Anne",
                            "latitude": "41.02503000",
                            "longitude": "-87.71392000"
                        },
                        {
                            "id": 125613,
                            "name": "Saint Charles",
                            "latitude": "41.91419000",
                            "longitude": "-88.30869000"
                        },
                        {
                            "id": 125622,
                            "name": "Saint Clair County",
                            "latitude": "38.47031000",
                            "longitude": "-89.92841000"
                        },
                        {
                            "id": 125632,
                            "name": "Saint Elmo",
                            "latitude": "39.02727000",
                            "longitude": "-88.84811000"
                        },
                        {
                            "id": 125651,
                            "name": "Saint Jacob",
                            "latitude": "38.71394000",
                            "longitude": "-89.76815000"
                        },
                        {
                            "id": 125672,
                            "name": "Saint Joseph",
                            "latitude": "40.11170000",
                            "longitude": "-88.04170000"
                        },
                        {
                            "id": 125728,
                            "name": "Salem",
                            "latitude": "38.62699000",
                            "longitude": "-88.94562000"
                        },
                        {
                            "id": 125750,
                            "name": "Saline County",
                            "latitude": "37.75318000",
                            "longitude": "-88.54080000"
                        },
                        {
                            "id": 125868,
                            "name": "Sandoval",
                            "latitude": "38.61560000",
                            "longitude": "-89.11423000"
                        },
                        {
                            "id": 125876,
                            "name": "Sandwich",
                            "latitude": "41.64586000",
                            "longitude": "-88.62174000"
                        },
                        {
                            "id": 125889,
                            "name": "Sangamon County",
                            "latitude": "39.75817000",
                            "longitude": "-89.65890000"
                        },
                        {
                            "id": 125966,
                            "name": "Sauk Village",
                            "latitude": "41.48837000",
                            "longitude": "-87.56754000"
                        },
                        {
                            "id": 125973,
                            "name": "Savanna",
                            "latitude": "42.09447000",
                            "longitude": "-90.15679000"
                        },
                        {
                            "id": 125978,
                            "name": "Savoy",
                            "latitude": "40.05475000",
                            "longitude": "-88.25172000"
                        },
                        {
                            "id": 126002,
                            "name": "Schaumburg",
                            "latitude": "42.03336000",
                            "longitude": "-88.08341000"
                        },
                        {
                            "id": 126007,
                            "name": "Schiller Park",
                            "latitude": "41.95586000",
                            "longitude": "-87.87090000"
                        },
                        {
                            "id": 126022,
                            "name": "Schuyler County",
                            "latitude": "40.15803000",
                            "longitude": "-90.61507000"
                        },
                        {
                            "id": 126040,
                            "name": "Scott Air Force Base",
                            "latitude": "38.54270000",
                            "longitude": "-89.85035000"
                        },
                        {
                            "id": 126044,
                            "name": "Scott County",
                            "latitude": "39.64414000",
                            "longitude": "-90.47470000"
                        },
                        {
                            "id": 126157,
                            "name": "Seneca",
                            "latitude": "41.31114000",
                            "longitude": "-88.60979000"
                        },
                        {
                            "id": 126168,
                            "name": "Sesser",
                            "latitude": "38.09172000",
                            "longitude": "-89.05035000"
                        },
                        {
                            "id": 126253,
                            "name": "Shawneetown",
                            "latitude": "37.71310000",
                            "longitude": "-88.18670000"
                        },
                        {
                            "id": 126273,
                            "name": "Shelby County",
                            "latitude": "39.39116000",
                            "longitude": "-88.80554000"
                        },
                        {
                            "id": 126281,
                            "name": "Shelbyville",
                            "latitude": "39.40643000",
                            "longitude": "-88.79007000"
                        },
                        {
                            "id": 126287,
                            "name": "Sheldon",
                            "latitude": "40.76920000",
                            "longitude": "-87.56392000"
                        },
                        {
                            "id": 126319,
                            "name": "Sheridan",
                            "latitude": "41.53003000",
                            "longitude": "-88.67980000"
                        },
                        {
                            "id": 126329,
                            "name": "Sherman",
                            "latitude": "39.89366000",
                            "longitude": "-89.60482000"
                        },
                        {
                            "id": 126348,
                            "name": "Shiloh",
                            "latitude": "38.56144000",
                            "longitude": "-89.89732000"
                        },
                        {
                            "id": 126370,
                            "name": "Shorewood",
                            "latitude": "41.52003000",
                            "longitude": "-88.20173000"
                        },
                        {
                            "id": 126395,
                            "name": "Sidney",
                            "latitude": "40.02503000",
                            "longitude": "-88.07337000"
                        },
                        {
                            "id": 126444,
                            "name": "Silvis",
                            "latitude": "41.51226000",
                            "longitude": "-90.41513000"
                        },
                        {
                            "id": 126483,
                            "name": "Skokie",
                            "latitude": "42.03336000",
                            "longitude": "-87.73339000"
                        },
                        {
                            "id": 126497,
                            "name": "Sleepy Hollow",
                            "latitude": "42.09419000",
                            "longitude": "-88.30258000"
                        },
                        {
                            "id": 126525,
                            "name": "Smithton",
                            "latitude": "38.40866000",
                            "longitude": "-89.99205000"
                        },
                        {
                            "id": 126597,
                            "name": "Somonauk",
                            "latitude": "41.63364000",
                            "longitude": "-88.68119000"
                        },
                        {
                            "id": 126617,
                            "name": "South Barrington",
                            "latitude": "42.09142000",
                            "longitude": "-88.12174000"
                        },
                        {
                            "id": 126623,
                            "name": "South Beloit",
                            "latitude": "42.49307000",
                            "longitude": "-89.03678000"
                        },
                        {
                            "id": 126637,
                            "name": "South Chicago",
                            "latitude": "41.73977000",
                            "longitude": "-87.55425000"
                        },
                        {
                            "id": 126638,
                            "name": "South Chicago Heights",
                            "latitude": "41.48087000",
                            "longitude": "-87.63782000"
                        },
                        {
                            "id": 126651,
                            "name": "South Elgin",
                            "latitude": "41.99419000",
                            "longitude": "-88.29230000"
                        },
                        {
                            "id": 126674,
                            "name": "South Holland",
                            "latitude": "41.60087000",
                            "longitude": "-87.60699000"
                        },
                        {
                            "id": 126679,
                            "name": "South Jacksonville",
                            "latitude": "39.70866000",
                            "longitude": "-90.22818000"
                        },
                        {
                            "id": 126687,
                            "name": "South Lawndale",
                            "latitude": "41.84364000",
                            "longitude": "-87.71255000"
                        },
                        {
                            "id": 126709,
                            "name": "South Pekin",
                            "latitude": "40.49448000",
                            "longitude": "-89.65177000"
                        },
                        {
                            "id": 126720,
                            "name": "South Roxana",
                            "latitude": "38.82949000",
                            "longitude": "-90.06288000"
                        },
                        {
                            "id": 126729,
                            "name": "South Shore",
                            "latitude": "41.76198000",
                            "longitude": "-87.57783000"
                        },
                        {
                            "id": 126771,
                            "name": "Southern View",
                            "latitude": "39.75727000",
                            "longitude": "-89.65371000"
                        },
                        {
                            "id": 126806,
                            "name": "Sparta",
                            "latitude": "38.12311000",
                            "longitude": "-89.70177000"
                        },
                        {
                            "id": 126858,
                            "name": "Spring Grove",
                            "latitude": "42.44363000",
                            "longitude": "-88.23648000"
                        },
                        {
                            "id": 126875,
                            "name": "Spring Valley",
                            "latitude": "41.32754000",
                            "longitude": "-89.19981000"
                        },
                        {
                            "id": 126890,
                            "name": "Springfield",
                            "latitude": "39.80172000",
                            "longitude": "-89.64371000"
                        },
                        {
                            "id": 126984,
                            "name": "Stark County",
                            "latitude": "41.09336000",
                            "longitude": "-89.79749000"
                        },
                        {
                            "id": 127000,
                            "name": "Staunton",
                            "latitude": "39.01227000",
                            "longitude": "-89.79121000"
                        },
                        {
                            "id": 127012,
                            "name": "Steeleville",
                            "latitude": "38.00727000",
                            "longitude": "-89.65843000"
                        },
                        {
                            "id": 127016,
                            "name": "Steger",
                            "latitude": "41.47003000",
                            "longitude": "-87.63643000"
                        },
                        {
                            "id": 127024,
                            "name": "Stephenson County",
                            "latitude": "42.35175000",
                            "longitude": "-89.66235000"
                        },
                        {
                            "id": 127029,
                            "name": "Sterling",
                            "latitude": "41.78864000",
                            "longitude": "-89.69622000"
                        },
                        {
                            "id": 127056,
                            "name": "Stickney",
                            "latitude": "41.82142000",
                            "longitude": "-87.78283000"
                        },
                        {
                            "id": 127059,
                            "name": "Stillman Valley",
                            "latitude": "42.10725000",
                            "longitude": "-89.17927000"
                        },
                        {
                            "id": 127073,
                            "name": "Stockton",
                            "latitude": "42.34974000",
                            "longitude": "-90.00679000"
                        },
                        {
                            "id": 127084,
                            "name": "Stone Park",
                            "latitude": "41.90559000",
                            "longitude": "-87.88367000"
                        },
                        {
                            "id": 127135,
                            "name": "Streamwood",
                            "latitude": "42.02558000",
                            "longitude": "-88.17841000"
                        },
                        {
                            "id": 127136,
                            "name": "Streator",
                            "latitude": "41.12087000",
                            "longitude": "-88.83535000"
                        },
                        {
                            "id": 127171,
                            "name": "Sugar Grove",
                            "latitude": "41.76142000",
                            "longitude": "-88.44369000"
                        },
                        {
                            "id": 127181,
                            "name": "Sullivan",
                            "latitude": "39.59948000",
                            "longitude": "-88.60784000"
                        },
                        {
                            "id": 127212,
                            "name": "Summit",
                            "latitude": "41.78809000",
                            "longitude": "-87.81033000"
                        },
                        {
                            "id": 127221,
                            "name": "Sumner",
                            "latitude": "38.71699000",
                            "longitude": "-87.86142000"
                        },
                        {
                            "id": 127325,
                            "name": "Swansea",
                            "latitude": "38.53394000",
                            "longitude": "-89.98899000"
                        },
                        {
                            "id": 127351,
                            "name": "Sycamore",
                            "latitude": "41.98892000",
                            "longitude": "-88.68675000"
                        },
                        {
                            "id": 127467,
                            "name": "Taylorville",
                            "latitude": "39.54894000",
                            "longitude": "-89.29453000"
                        },
                        {
                            "id": 127471,
                            "name": "Tazewell County",
                            "latitude": "40.50752000",
                            "longitude": "-89.51342000"
                        },
                        {
                            "id": 127540,
                            "name": "Teutopolis",
                            "latitude": "39.13310000",
                            "longitude": "-88.47199000"
                        },
                        {
                            "id": 127558,
                            "name": "The Galena Territory",
                            "latitude": "42.39343000",
                            "longitude": "-90.32582000"
                        },
                        {
                            "id": 127577,
                            "name": "Third Lake",
                            "latitude": "42.37391000",
                            "longitude": "-88.01091000"
                        },
                        {
                            "id": 127582,
                            "name": "Thomasboro",
                            "latitude": "40.24170000",
                            "longitude": "-88.18421000"
                        },
                        {
                            "id": 127601,
                            "name": "Thornton",
                            "latitude": "41.56809000",
                            "longitude": "-87.60810000"
                        },
                        {
                            "id": 127653,
                            "name": "Tilton",
                            "latitude": "40.09531000",
                            "longitude": "-87.64752000"
                        },
                        {
                            "id": 127666,
                            "name": "Tinley Park",
                            "latitude": "41.57337000",
                            "longitude": "-87.78449000"
                        },
                        {
                            "id": 127698,
                            "name": "Toledo",
                            "latitude": "39.27365000",
                            "longitude": "-88.24365000"
                        },
                        {
                            "id": 127704,
                            "name": "Tolono",
                            "latitude": "39.98614000",
                            "longitude": "-88.25894000"
                        },
                        {
                            "id": 127705,
                            "name": "Toluca",
                            "latitude": "41.00226000",
                            "longitude": "-89.13342000"
                        },
                        {
                            "id": 127749,
                            "name": "Toulon",
                            "latitude": "41.09365000",
                            "longitude": "-89.86483000"
                        },
                        {
                            "id": 127755,
                            "name": "Tower Lake",
                            "latitude": "42.23197000",
                            "longitude": "-88.15202000"
                        },
                        {
                            "id": 127793,
                            "name": "Tremont",
                            "latitude": "40.52754000",
                            "longitude": "-89.49260000"
                        },
                        {
                            "id": 127803,
                            "name": "Trenton",
                            "latitude": "38.60560000",
                            "longitude": "-89.68204000"
                        },
                        {
                            "id": 127843,
                            "name": "Troy",
                            "latitude": "38.72921000",
                            "longitude": "-89.88315000"
                        },
                        {
                            "id": 127911,
                            "name": "Tuscola",
                            "latitude": "39.79920000",
                            "longitude": "-88.28310000"
                        },
                        {
                            "id": 127926,
                            "name": "Twin Grove",
                            "latitude": "40.49337000",
                            "longitude": "-89.07980000"
                        },
                        {
                            "id": 127991,
                            "name": "Union County",
                            "latitude": "37.47123000",
                            "longitude": "-89.25509000"
                        },
                        {
                            "id": 128035,
                            "name": "University Park",
                            "latitude": "41.44298000",
                            "longitude": "-87.68360000"
                        },
                        {
                            "id": 128040,
                            "name": "Upper Alton",
                            "latitude": "38.91144000",
                            "longitude": "-90.15066000"
                        },
                        {
                            "id": 128058,
                            "name": "Uptown",
                            "latitude": "41.96590000",
                            "longitude": "-87.65262000"
                        },
                        {
                            "id": 128062,
                            "name": "Urbana",
                            "latitude": "40.11059000",
                            "longitude": "-88.20727000"
                        },
                        {
                            "id": 128119,
                            "name": "Valmeyer",
                            "latitude": "38.30561000",
                            "longitude": "-90.27651000"
                        },
                        {
                            "id": 128145,
                            "name": "Vandalia",
                            "latitude": "38.96060000",
                            "longitude": "-89.09368000"
                        },
                        {
                            "id": 128169,
                            "name": "Venetian Village",
                            "latitude": "42.39863000",
                            "longitude": "-88.05258000"
                        },
                        {
                            "id": 128171,
                            "name": "Venice",
                            "latitude": "38.67227000",
                            "longitude": "-90.16983000"
                        },
                        {
                            "id": 128183,
                            "name": "Vermilion County",
                            "latitude": "40.18342000",
                            "longitude": "-87.73283000"
                        },
                        {
                            "id": 128194,
                            "name": "Vernon Hills",
                            "latitude": "42.21947000",
                            "longitude": "-87.97952000"
                        },
                        {
                            "id": 128231,
                            "name": "Vienna",
                            "latitude": "37.41533000",
                            "longitude": "-88.89784000"
                        },
                        {
                            "id": 128241,
                            "name": "Villa Grove",
                            "latitude": "39.86281000",
                            "longitude": "-88.16227000"
                        },
                        {
                            "id": 128243,
                            "name": "Villa Park",
                            "latitude": "41.88975000",
                            "longitude": "-87.98895000"
                        },
                        {
                            "id": 128252,
                            "name": "Village of Campton Hills",
                            "latitude": "41.93660000",
                            "longitude": "-88.39750000"
                        },
                        {
                            "id": 128285,
                            "name": "Virden",
                            "latitude": "39.50089000",
                            "longitude": "-89.76787000"
                        },
                        {
                            "id": 128286,
                            "name": "Virginia",
                            "latitude": "39.95116000",
                            "longitude": "-90.21234000"
                        },
                        {
                            "id": 128302,
                            "name": "Volo",
                            "latitude": "42.32613000",
                            "longitude": "-88.16786000"
                        },
                        {
                            "id": 128310,
                            "name": "Wabash County",
                            "latitude": "38.44607000",
                            "longitude": "-87.84425000"
                        },
                        {
                            "id": 128326,
                            "name": "Wadsworth",
                            "latitude": "42.42863000",
                            "longitude": "-87.92397000"
                        },
                        {
                            "id": 128413,
                            "name": "Walnut",
                            "latitude": "41.55670000",
                            "longitude": "-89.59343000"
                        },
                        {
                            "id": 128443,
                            "name": "Wamac",
                            "latitude": "38.50894000",
                            "longitude": "-89.14063000"
                        },
                        {
                            "id": 128479,
                            "name": "Warren",
                            "latitude": "42.49640000",
                            "longitude": "-89.98957000"
                        },
                        {
                            "id": 128494,
                            "name": "Warren County",
                            "latitude": "40.84883000",
                            "longitude": "-90.61503000"
                        },
                        {
                            "id": 128501,
                            "name": "Warrensburg",
                            "latitude": "39.93282000",
                            "longitude": "-89.06203000"
                        },
                        {
                            "id": 128510,
                            "name": "Warrenville",
                            "latitude": "41.81781000",
                            "longitude": "-88.17340000"
                        },
                        {
                            "id": 128519,
                            "name": "Warsaw",
                            "latitude": "40.35921000",
                            "longitude": "-91.43460000"
                        },
                        {
                            "id": 128527,
                            "name": "Wasco",
                            "latitude": "41.93808000",
                            "longitude": "-88.40452000"
                        },
                        {
                            "id": 128533,
                            "name": "Washburn",
                            "latitude": "40.91920000",
                            "longitude": "-89.29120000"
                        },
                        {
                            "id": 128546,
                            "name": "Washington",
                            "latitude": "40.70365000",
                            "longitude": "-89.40731000"
                        },
                        {
                            "id": 128556,
                            "name": "Washington County",
                            "latitude": "38.35217000",
                            "longitude": "-89.41045000"
                        },
                        {
                            "id": 128585,
                            "name": "Washington Park",
                            "latitude": "38.63505000",
                            "longitude": "-90.09289000"
                        },
                        {
                            "id": 128611,
                            "name": "Waterloo",
                            "latitude": "38.33589000",
                            "longitude": "-90.14983000"
                        },
                        {
                            "id": 128617,
                            "name": "Waterman",
                            "latitude": "41.77170000",
                            "longitude": "-88.77369000"
                        },
                        {
                            "id": 128638,
                            "name": "Watseka",
                            "latitude": "40.77615000",
                            "longitude": "-87.73642000"
                        },
                        {
                            "id": 128646,
                            "name": "Wauconda",
                            "latitude": "42.25891000",
                            "longitude": "-88.13925000"
                        },
                        {
                            "id": 128648,
                            "name": "Waukegan",
                            "latitude": "42.36363000",
                            "longitude": "-87.84479000"
                        },
                        {
                            "id": 128665,
                            "name": "Waverly",
                            "latitude": "39.59172000",
                            "longitude": "-89.95288000"
                        },
                        {
                            "id": 128682,
                            "name": "Wayne",
                            "latitude": "41.95086000",
                            "longitude": "-88.24230000"
                        },
                        {
                            "id": 128688,
                            "name": "Wayne City",
                            "latitude": "38.34533000",
                            "longitude": "-88.58783000"
                        },
                        {
                            "id": 128690,
                            "name": "Wayne County",
                            "latitude": "38.42956000",
                            "longitude": "-88.42561000"
                        },
                        {
                            "id": 128846,
                            "name": "West Chicago",
                            "latitude": "41.88475000",
                            "longitude": "-88.20396000"
                        },
                        {
                            "id": 128858,
                            "name": "West Dundee",
                            "latitude": "42.09808000",
                            "longitude": "-88.28286000"
                        },
                        {
                            "id": 128862,
                            "name": "West Elsdon",
                            "latitude": "41.79392000",
                            "longitude": "-87.72450000"
                        },
                        {
                            "id": 128865,
                            "name": "West Englewood",
                            "latitude": "41.77809000",
                            "longitude": "-87.66672000"
                        },
                        {
                            "id": 128873,
                            "name": "West Frankfort",
                            "latitude": "37.89783000",
                            "longitude": "-88.93146000"
                        },
                        {
                            "id": 128875,
                            "name": "West Garfield Park",
                            "latitude": "41.88059000",
                            "longitude": "-87.72922000"
                        },
                        {
                            "id": 128913,
                            "name": "West Lawn",
                            "latitude": "41.77281000",
                            "longitude": "-87.72227000"
                        },
                        {
                            "id": 128953,
                            "name": "West Peoria",
                            "latitude": "40.69254000",
                            "longitude": "-89.62788000"
                        },
                        {
                            "id": 128969,
                            "name": "West Ridge",
                            "latitude": "41.99975000",
                            "longitude": "-87.69284000"
                        },
                        {
                            "id": 128992,
                            "name": "West Town",
                            "latitude": "41.89381000",
                            "longitude": "-87.67493000"
                        },
                        {
                            "id": 129019,
                            "name": "Westchester",
                            "latitude": "41.85059000",
                            "longitude": "-87.88200000"
                        },
                        {
                            "id": 129025,
                            "name": "Western Springs",
                            "latitude": "41.80975000",
                            "longitude": "-87.90062000"
                        },
                        {
                            "id": 129049,
                            "name": "Westmont",
                            "latitude": "41.79586000",
                            "longitude": "-87.97562000"
                        },
                        {
                            "id": 129078,
                            "name": "Westville",
                            "latitude": "40.04226000",
                            "longitude": "-87.63863000"
                        },
                        {
                            "id": 129113,
                            "name": "Wheaton",
                            "latitude": "41.86614000",
                            "longitude": "-88.10701000"
                        },
                        {
                            "id": 129120,
                            "name": "Wheeling",
                            "latitude": "42.13919000",
                            "longitude": "-87.92896000"
                        },
                        {
                            "id": 129136,
                            "name": "White County",
                            "latitude": "38.08748000",
                            "longitude": "-88.17957000"
                        },
                        {
                            "id": 129140,
                            "name": "White Hall",
                            "latitude": "39.43699000",
                            "longitude": "-90.40318000"
                        },
                        {
                            "id": 129186,
                            "name": "Whiteside County",
                            "latitude": "41.75626000",
                            "longitude": "-89.91409000"
                        },
                        {
                            "id": 129253,
                            "name": "Will County",
                            "latitude": "41.44503000",
                            "longitude": "-87.97866000"
                        },
                        {
                            "id": 129276,
                            "name": "Williamson County",
                            "latitude": "37.73025000",
                            "longitude": "-88.92994000"
                        },
                        {
                            "id": 129291,
                            "name": "Williamsville",
                            "latitude": "39.95422000",
                            "longitude": "-89.54871000"
                        },
                        {
                            "id": 129310,
                            "name": "Willow Springs",
                            "latitude": "41.74087000",
                            "longitude": "-87.86033000"
                        },
                        {
                            "id": 129313,
                            "name": "Willowbrook",
                            "latitude": "41.76975000",
                            "longitude": "-87.93589000"
                        },
                        {
                            "id": 129320,
                            "name": "Wilmette",
                            "latitude": "42.07225000",
                            "longitude": "-87.72284000"
                        },
                        {
                            "id": 129323,
                            "name": "Wilmington",
                            "latitude": "41.30781000",
                            "longitude": "-88.14672000"
                        },
                        {
                            "id": 129352,
                            "name": "Winchester",
                            "latitude": "39.62977000",
                            "longitude": "-90.45624000"
                        },
                        {
                            "id": 129377,
                            "name": "Windsor",
                            "latitude": "39.44087000",
                            "longitude": "-88.59478000"
                        },
                        {
                            "id": 129397,
                            "name": "Winfield",
                            "latitude": "41.86170000",
                            "longitude": "-88.16090000"
                        },
                        {
                            "id": 129405,
                            "name": "Winnebago",
                            "latitude": "42.26613000",
                            "longitude": "-89.24122000"
                        },
                        {
                            "id": 129408,
                            "name": "Winnebago County",
                            "latitude": "42.33626000",
                            "longitude": "-89.16085000"
                        },
                        {
                            "id": 129414,
                            "name": "Winnetka",
                            "latitude": "42.10808000",
                            "longitude": "-87.73590000"
                        },
                        {
                            "id": 129452,
                            "name": "Winthrop Harbor",
                            "latitude": "42.47891000",
                            "longitude": "-87.82368000"
                        },
                        {
                            "id": 129483,
                            "name": "Wonder Lake",
                            "latitude": "42.38530000",
                            "longitude": "-88.34731000"
                        },
                        {
                            "id": 129487,
                            "name": "Wood Dale",
                            "latitude": "41.96336000",
                            "longitude": "-87.97896000"
                        },
                        {
                            "id": 129488,
                            "name": "Wood River",
                            "latitude": "38.86116000",
                            "longitude": "-90.09761000"
                        },
                        {
                            "id": 129520,
                            "name": "Woodford County",
                            "latitude": "40.78823000",
                            "longitude": "-89.21114000"
                        },
                        {
                            "id": 129536,
                            "name": "Woodlawn",
                            "latitude": "41.77948000",
                            "longitude": "-87.59949000"
                        },
                        {
                            "id": 129545,
                            "name": "Woodridge",
                            "latitude": "41.74697000",
                            "longitude": "-88.05034000"
                        },
                        {
                            "id": 129563,
                            "name": "Woodstock",
                            "latitude": "42.31474000",
                            "longitude": "-88.44870000"
                        },
                        {
                            "id": 129588,
                            "name": "Worden",
                            "latitude": "38.93144000",
                            "longitude": "-89.83899000"
                        },
                        {
                            "id": 129591,
                            "name": "Worth",
                            "latitude": "41.68975000",
                            "longitude": "-87.79728000"
                        },
                        {
                            "id": 129635,
                            "name": "Wyoming",
                            "latitude": "41.06170000",
                            "longitude": "-89.77316000"
                        },
                        {
                            "id": 129709,
                            "name": "Yorkville",
                            "latitude": "41.64114000",
                            "longitude": "-88.44729000"
                        },
                        {
                            "id": 129745,
                            "name": "Zeigler",
                            "latitude": "37.89949000",
                            "longitude": "-89.05202000"
                        },
                        {
                            "id": 129757,
                            "name": "Zion",
                            "latitude": "42.44613000",
                            "longitude": "-87.83285000"
                        }
                    ]
                },
                {
                    "id": 1440,
                    "name": "Indiana",
                    "state_code": "IN",
                    "cities": [
                        {
                            "id": 110976,
                            "name": "Aberdeen",
                            "latitude": "41.43893000",
                            "longitude": "-87.11142000"
                        },
                        {
                            "id": 111021,
                            "name": "Adams County",
                            "latitude": "40.74566000",
                            "longitude": "-84.93665000"
                        },
                        {
                            "id": 111077,
                            "name": "Akron",
                            "latitude": "41.03838000",
                            "longitude": "-86.02805000"
                        },
                        {
                            "id": 111105,
                            "name": "Albany",
                            "latitude": "40.30088000",
                            "longitude": "-85.24191000"
                        },
                        {
                            "id": 111124,
                            "name": "Albion",
                            "latitude": "41.39560000",
                            "longitude": "-85.42442000"
                        },
                        {
                            "id": 111153,
                            "name": "Alexandria",
                            "latitude": "40.26282000",
                            "longitude": "-85.67581000"
                        },
                        {
                            "id": 111190,
                            "name": "Allen County",
                            "latitude": "41.09087000",
                            "longitude": "-85.06656000"
                        },
                        {
                            "id": 111313,
                            "name": "Anderson",
                            "latitude": "40.10532000",
                            "longitude": "-85.68025000"
                        },
                        {
                            "id": 111330,
                            "name": "Andrews",
                            "latitude": "40.86254000",
                            "longitude": "-85.60165000"
                        },
                        {
                            "id": 111340,
                            "name": "Angola",
                            "latitude": "41.63477000",
                            "longitude": "-84.99941000"
                        },
                        {
                            "id": 111423,
                            "name": "Arcadia",
                            "latitude": "40.17587000",
                            "longitude": "-86.02165000"
                        },
                        {
                            "id": 111449,
                            "name": "Argos",
                            "latitude": "41.23780000",
                            "longitude": "-86.21465000"
                        },
                        {
                            "id": 111609,
                            "name": "Attica",
                            "latitude": "40.29420000",
                            "longitude": "-87.24890000"
                        },
                        {
                            "id": 111625,
                            "name": "Auburn",
                            "latitude": "41.36699000",
                            "longitude": "-85.05886000"
                        },
                        {
                            "id": 111656,
                            "name": "Aurora",
                            "latitude": "39.05700000",
                            "longitude": "-84.90134000"
                        },
                        {
                            "id": 111667,
                            "name": "Austin",
                            "latitude": "38.75839000",
                            "longitude": "-85.80803000"
                        },
                        {
                            "id": 111684,
                            "name": "Avilla",
                            "latitude": "41.36588000",
                            "longitude": "-85.23886000"
                        },
                        {
                            "id": 111690,
                            "name": "Avon",
                            "latitude": "39.76282000",
                            "longitude": "-86.39972000"
                        },
                        {
                            "id": 111822,
                            "name": "Bargersville",
                            "latitude": "39.52088000",
                            "longitude": "-86.16777000"
                        },
                        {
                            "id": 111859,
                            "name": "Bartholomew County",
                            "latitude": "39.20597000",
                            "longitude": "-85.89760000"
                        },
                        {
                            "id": 111880,
                            "name": "Bass Lake",
                            "latitude": "41.20726000",
                            "longitude": "-86.60196000"
                        },
                        {
                            "id": 111893,
                            "name": "Batesville",
                            "latitude": "39.30005000",
                            "longitude": "-85.22218000"
                        },
                        {
                            "id": 111906,
                            "name": "Battle Ground",
                            "latitude": "40.50837000",
                            "longitude": "-86.84168000"
                        },
                        {
                            "id": 112018,
                            "name": "Bedford",
                            "latitude": "38.86116000",
                            "longitude": "-86.48721000"
                        },
                        {
                            "id": 112037,
                            "name": "Beech Grove",
                            "latitude": "39.72199000",
                            "longitude": "-86.08998000"
                        },
                        {
                            "id": 112204,
                            "name": "Benton County",
                            "latitude": "40.60626000",
                            "longitude": "-87.31091000"
                        },
                        {
                            "id": 112246,
                            "name": "Berne",
                            "latitude": "40.65782000",
                            "longitude": "-84.95191000"
                        },
                        {
                            "id": 112311,
                            "name": "Bicknell",
                            "latitude": "38.77421000",
                            "longitude": "-87.30779000"
                        },
                        {
                            "id": 112386,
                            "name": "Blackford County",
                            "latitude": "40.47360000",
                            "longitude": "-85.32482000"
                        },
                        {
                            "id": 112434,
                            "name": "Bloomfield",
                            "latitude": "39.02699000",
                            "longitude": "-86.93751000"
                        },
                        {
                            "id": 112449,
                            "name": "Bloomington",
                            "latitude": "39.16533000",
                            "longitude": "-86.52639000"
                        },
                        {
                            "id": 112484,
                            "name": "Bluffton",
                            "latitude": "40.73866000",
                            "longitude": "-85.17164000"
                        },
                        {
                            "id": 112560,
                            "name": "Boone County",
                            "latitude": "40.05080000",
                            "longitude": "-86.46870000"
                        },
                        {
                            "id": 112567,
                            "name": "Boonville",
                            "latitude": "38.04921000",
                            "longitude": "-87.27417000"
                        },
                        {
                            "id": 112612,
                            "name": "Bourbon",
                            "latitude": "41.29560000",
                            "longitude": "-86.11639000"
                        },
                        {
                            "id": 112703,
                            "name": "Brazil",
                            "latitude": "39.52365000",
                            "longitude": "-87.12502000"
                        },
                        {
                            "id": 112721,
                            "name": "Bremen",
                            "latitude": "41.44644000",
                            "longitude": "-86.14806000"
                        },
                        {
                            "id": 112785,
                            "name": "Bright",
                            "latitude": "39.21839000",
                            "longitude": "-84.85606000"
                        },
                        {
                            "id": 112805,
                            "name": "Bristol",
                            "latitude": "41.72144000",
                            "longitude": "-85.81749000"
                        },
                        {
                            "id": 112822,
                            "name": "Broad Ripple",
                            "latitude": "39.86671000",
                            "longitude": "-86.14165000"
                        },
                        {
                            "id": 112869,
                            "name": "Brooklyn",
                            "latitude": "39.53921000",
                            "longitude": "-86.36916000"
                        },
                        {
                            "id": 112889,
                            "name": "Brookston",
                            "latitude": "40.60281000",
                            "longitude": "-86.86723000"
                        },
                        {
                            "id": 112894,
                            "name": "Brookville",
                            "latitude": "39.42311000",
                            "longitude": "-85.01274000"
                        },
                        {
                            "id": 112908,
                            "name": "Brown County",
                            "latitude": "39.19621000",
                            "longitude": "-86.22737000"
                        },
                        {
                            "id": 112924,
                            "name": "Brownsburg",
                            "latitude": "39.84338000",
                            "longitude": "-86.39777000"
                        },
                        {
                            "id": 112926,
                            "name": "Brownstown",
                            "latitude": "38.87894000",
                            "longitude": "-86.04192000"
                        },
                        {
                            "id": 113089,
                            "name": "Burns Harbor",
                            "latitude": "41.62587000",
                            "longitude": "-87.13337000"
                        },
                        {
                            "id": 113109,
                            "name": "Butler",
                            "latitude": "41.42977000",
                            "longitude": "-84.87135000"
                        },
                        {
                            "id": 113257,
                            "name": "Cambridge City",
                            "latitude": "39.81255000",
                            "longitude": "-85.17163000"
                        },
                        {
                            "id": 113330,
                            "name": "Cannelton",
                            "latitude": "37.91144000",
                            "longitude": "-86.74443000"
                        },
                        {
                            "id": 113416,
                            "name": "Carmel",
                            "latitude": "39.97837000",
                            "longitude": "-86.11804000"
                        },
                        {
                            "id": 113460,
                            "name": "Carroll County",
                            "latitude": "40.58286000",
                            "longitude": "-86.56348000"
                        },
                        {
                            "id": 113530,
                            "name": "Cass County",
                            "latitude": "40.76149000",
                            "longitude": "-86.34595000"
                        },
                        {
                            "id": 113593,
                            "name": "Cayuga",
                            "latitude": "39.94865000",
                            "longitude": "-87.45974000"
                        },
                        {
                            "id": 113617,
                            "name": "Cedar Lake",
                            "latitude": "41.36476000",
                            "longitude": "-87.44115000"
                        },
                        {
                            "id": 113653,
                            "name": "Centerville",
                            "latitude": "39.81782000",
                            "longitude": "-84.99635000"
                        },
                        {
                            "id": 113728,
                            "name": "Chandler",
                            "latitude": "38.04171000",
                            "longitude": "-87.36806000"
                        },
                        {
                            "id": 113770,
                            "name": "Charlestown",
                            "latitude": "38.45312000",
                            "longitude": "-85.67024000"
                        },
                        {
                            "id": 113903,
                            "name": "Chesterfield",
                            "latitude": "40.11254000",
                            "longitude": "-85.59692000"
                        },
                        {
                            "id": 113909,
                            "name": "Chesterton",
                            "latitude": "41.61059000",
                            "longitude": "-87.06420000"
                        },
                        {
                            "id": 114015,
                            "name": "Churubusco",
                            "latitude": "41.23060000",
                            "longitude": "-85.31942000"
                        },
                        {
                            "id": 114020,
                            "name": "Cicero",
                            "latitude": "40.12393000",
                            "longitude": "-86.01332000"
                        },
                        {
                            "id": 114123,
                            "name": "Clark County",
                            "latitude": "38.47718000",
                            "longitude": "-85.70728000"
                        },
                        {
                            "id": 114153,
                            "name": "Clarksville",
                            "latitude": "38.29674000",
                            "longitude": "-85.75996000"
                        },
                        {
                            "id": 114178,
                            "name": "Clay County",
                            "latitude": "39.39273000",
                            "longitude": "-87.11576000"
                        },
                        {
                            "id": 114239,
                            "name": "Clermont",
                            "latitude": "39.80977000",
                            "longitude": "-86.32249000"
                        },
                        {
                            "id": 114268,
                            "name": "Clinton",
                            "latitude": "39.65698000",
                            "longitude": "-87.39807000"
                        },
                        {
                            "id": 114293,
                            "name": "Clinton County",
                            "latitude": "40.30169000",
                            "longitude": "-86.47516000"
                        },
                        {
                            "id": 114309,
                            "name": "Cloverdale",
                            "latitude": "39.51477000",
                            "longitude": "-86.79390000"
                        },
                        {
                            "id": 114475,
                            "name": "Columbia City",
                            "latitude": "41.15727000",
                            "longitude": "-85.48831000"
                        },
                        {
                            "id": 114490,
                            "name": "Columbus",
                            "latitude": "39.20144000",
                            "longitude": "-85.92138000"
                        },
                        {
                            "id": 114564,
                            "name": "Connersville",
                            "latitude": "39.64116000",
                            "longitude": "-85.14107000"
                        },
                        {
                            "id": 114579,
                            "name": "Converse",
                            "latitude": "40.57754000",
                            "longitude": "-85.87332000"
                        },
                        {
                            "id": 114641,
                            "name": "Cordry Sweetwater Lakes",
                            "latitude": "39.30464000",
                            "longitude": "-86.11837000"
                        },
                        {
                            "id": 114681,
                            "name": "Corydon",
                            "latitude": "38.21201000",
                            "longitude": "-86.12192000"
                        },
                        {
                            "id": 114723,
                            "name": "Country Squire Lakes",
                            "latitude": "39.03478000",
                            "longitude": "-85.69858000"
                        },
                        {
                            "id": 114740,
                            "name": "Covington",
                            "latitude": "40.14170000",
                            "longitude": "-87.39474000"
                        },
                        {
                            "id": 114777,
                            "name": "Crawford County",
                            "latitude": "38.29241000",
                            "longitude": "-86.45171000"
                        },
                        {
                            "id": 114784,
                            "name": "Crawfordsville",
                            "latitude": "40.04115000",
                            "longitude": "-86.87445000"
                        },
                        {
                            "id": 114858,
                            "name": "Crothersville",
                            "latitude": "38.80061000",
                            "longitude": "-85.84164000"
                        },
                        {
                            "id": 114867,
                            "name": "Crown Point",
                            "latitude": "41.41698000",
                            "longitude": "-87.36531000"
                        },
                        {
                            "id": 114902,
                            "name": "Culver",
                            "latitude": "41.21893000",
                            "longitude": "-86.42306000"
                        },
                        {
                            "id": 114905,
                            "name": "Cumberland",
                            "latitude": "39.77615000",
                            "longitude": "-85.95720000"
                        },
                        {
                            "id": 114979,
                            "name": "Dale",
                            "latitude": "38.16894000",
                            "longitude": "-86.99000000"
                        },
                        {
                            "id": 114985,
                            "name": "Daleville",
                            "latitude": "40.12115000",
                            "longitude": "-85.55803000"
                        },
                        {
                            "id": 115034,
                            "name": "Danville",
                            "latitude": "39.76060000",
                            "longitude": "-86.52639000"
                        },
                        {
                            "id": 115052,
                            "name": "Darmstadt",
                            "latitude": "38.09921000",
                            "longitude": "-87.57891000"
                        },
                        {
                            "id": 115069,
                            "name": "Daviess County",
                            "latitude": "38.70241000",
                            "longitude": "-87.07207000"
                        },
                        {
                            "id": 115095,
                            "name": "Dayton",
                            "latitude": "40.37420000",
                            "longitude": "-86.76890000"
                        },
                        {
                            "id": 115146,
                            "name": "Dearborn County",
                            "latitude": "39.14519000",
                            "longitude": "-84.97326000"
                        },
                        {
                            "id": 115156,
                            "name": "Decatur",
                            "latitude": "40.83060000",
                            "longitude": "-84.92913000"
                        },
                        {
                            "id": 115160,
                            "name": "Decatur County",
                            "latitude": "39.30700000",
                            "longitude": "-85.50114000"
                        },
                        {
                            "id": 115133,
                            "name": "DeKalb County",
                            "latitude": "41.39758000",
                            "longitude": "-84.99909000"
                        },
                        {
                            "id": 115206,
                            "name": "Delaware County",
                            "latitude": "40.22753000",
                            "longitude": "-85.39690000"
                        },
                        {
                            "id": 115220,
                            "name": "Delphi",
                            "latitude": "40.58754000",
                            "longitude": "-86.67501000"
                        },
                        {
                            "id": 115135,
                            "name": "DeMotte",
                            "latitude": "41.19504000",
                            "longitude": "-87.19864000"
                        },
                        {
                            "id": 115345,
                            "name": "Dillsboro",
                            "latitude": "39.01783000",
                            "longitude": "-85.05884000"
                        },
                        {
                            "id": 115498,
                            "name": "Dubois County",
                            "latitude": "38.36428000",
                            "longitude": "-86.87980000"
                        },
                        {
                            "id": 115538,
                            "name": "Dunkirk",
                            "latitude": "40.75643000",
                            "longitude": "-86.39361000"
                        },
                        {
                            "id": 115543,
                            "name": "Dunlap",
                            "latitude": "41.63783000",
                            "longitude": "-85.92166000"
                        },
                        {
                            "id": 115586,
                            "name": "Dyer",
                            "latitude": "41.49420000",
                            "longitude": "-87.52171000"
                        },
                        {
                            "id": 115645,
                            "name": "East Chicago",
                            "latitude": "41.63920000",
                            "longitude": "-87.45476000"
                        },
                        {
                            "id": 115801,
                            "name": "Eaton",
                            "latitude": "40.34032000",
                            "longitude": "-85.35080000"
                        },
                        {
                            "id": 115861,
                            "name": "Edgewood",
                            "latitude": "40.10337000",
                            "longitude": "-85.73414000"
                        },
                        {
                            "id": 115873,
                            "name": "Edinburgh",
                            "latitude": "39.35422000",
                            "longitude": "-85.96666000"
                        },
                        {
                            "id": 115998,
                            "name": "Elkhart",
                            "latitude": "41.68199000",
                            "longitude": "-85.97667000"
                        },
                        {
                            "id": 116000,
                            "name": "Elkhart County",
                            "latitude": "41.59738000",
                            "longitude": "-85.85876000"
                        },
                        {
                            "id": 116022,
                            "name": "Ellettsville",
                            "latitude": "39.23393000",
                            "longitude": "-86.62500000"
                        },
                        {
                            "id": 116079,
                            "name": "Elwood",
                            "latitude": "40.27698000",
                            "longitude": "-85.84192000"
                        },
                        {
                            "id": 116135,
                            "name": "English",
                            "latitude": "38.33450000",
                            "longitude": "-86.46415000"
                        },
                        {
                            "id": 116253,
                            "name": "Evansville",
                            "latitude": "37.97476000",
                            "longitude": "-87.55585000"
                        },
                        {
                            "id": 116323,
                            "name": "Fairfield Heights",
                            "latitude": "39.82861000",
                            "longitude": "-86.38224000"
                        },
                        {
                            "id": 116340,
                            "name": "Fairmount",
                            "latitude": "40.41532000",
                            "longitude": "-85.65053000"
                        },
                        {
                            "id": 116359,
                            "name": "Fairview Park",
                            "latitude": "39.68031000",
                            "longitude": "-87.41752000"
                        },
                        {
                            "id": 116405,
                            "name": "Farmersburg",
                            "latitude": "39.24865000",
                            "longitude": "-87.38196000"
                        },
                        {
                            "id": 116425,
                            "name": "Farmland",
                            "latitude": "40.18782000",
                            "longitude": "-85.12747000"
                        },
                        {
                            "id": 116446,
                            "name": "Fayette County",
                            "latitude": "39.64006000",
                            "longitude": "-85.17873000"
                        },
                        {
                            "id": 116477,
                            "name": "Ferdinand",
                            "latitude": "38.22394000",
                            "longitude": "-86.86222000"
                        },
                        {
                            "id": 116526,
                            "name": "Fish Lake",
                            "latitude": "41.56671000",
                            "longitude": "-86.55196000"
                        },
                        {
                            "id": 116529,
                            "name": "Fishers",
                            "latitude": "39.95559000",
                            "longitude": "-86.01387000"
                        },
                        {
                            "id": 116572,
                            "name": "Flora",
                            "latitude": "40.54726000",
                            "longitude": "-86.52444000"
                        },
                        {
                            "id": 116607,
                            "name": "Floyd County",
                            "latitude": "38.31891000",
                            "longitude": "-85.90687000"
                        },
                        {
                            "id": 116697,
                            "name": "Fort Branch",
                            "latitude": "38.25116000",
                            "longitude": "-87.58113000"
                        },
                        {
                            "id": 116763,
                            "name": "Fort Wayne",
                            "latitude": "41.13060000",
                            "longitude": "-85.12886000"
                        },
                        {
                            "id": 116769,
                            "name": "Fortville",
                            "latitude": "39.93226000",
                            "longitude": "-85.84804000"
                        },
                        {
                            "id": 116779,
                            "name": "Fountain County",
                            "latitude": "40.12087000",
                            "longitude": "-87.24199000"
                        },
                        {
                            "id": 116794,
                            "name": "Fowler",
                            "latitude": "40.61670000",
                            "longitude": "-87.32085000"
                        },
                        {
                            "id": 116820,
                            "name": "Frankfort",
                            "latitude": "40.27948000",
                            "longitude": "-86.51084000"
                        },
                        {
                            "id": 116833,
                            "name": "Franklin",
                            "latitude": "39.48061000",
                            "longitude": "-86.05499000"
                        },
                        {
                            "id": 116848,
                            "name": "Franklin County",
                            "latitude": "39.41486000",
                            "longitude": "-85.06028000"
                        },
                        {
                            "id": 116879,
                            "name": "Frankton",
                            "latitude": "40.22282000",
                            "longitude": "-85.77887000"
                        },
                        {
                            "id": 116920,
                            "name": "Fremont",
                            "latitude": "41.73088000",
                            "longitude": "-84.93274000"
                        },
                        {
                            "id": 116931,
                            "name": "French Lick",
                            "latitude": "38.54894000",
                            "longitude": "-86.61999000"
                        },
                        {
                            "id": 116996,
                            "name": "Fulton County",
                            "latitude": "41.04696000",
                            "longitude": "-86.26358000"
                        },
                        {
                            "id": 117021,
                            "name": "Galena",
                            "latitude": "38.35173000",
                            "longitude": "-85.94164000"
                        },
                        {
                            "id": 117043,
                            "name": "Galveston",
                            "latitude": "40.57893000",
                            "longitude": "-86.19027000"
                        },
                        {
                            "id": 117098,
                            "name": "Garrett",
                            "latitude": "41.34949000",
                            "longitude": "-85.13553000"
                        },
                        {
                            "id": 117106,
                            "name": "Gary",
                            "latitude": "41.59337000",
                            "longitude": "-87.34643000"
                        },
                        {
                            "id": 117109,
                            "name": "Gas City",
                            "latitude": "40.48726000",
                            "longitude": "-85.61303000"
                        },
                        {
                            "id": 117141,
                            "name": "Geneva",
                            "latitude": "40.59199000",
                            "longitude": "-84.95719000"
                        },
                        {
                            "id": 117163,
                            "name": "Georgetown",
                            "latitude": "40.74060000",
                            "longitude": "-86.50473000"
                        },
                        {
                            "id": 117189,
                            "name": "Gibson County",
                            "latitude": "38.31183000",
                            "longitude": "-87.58459000"
                        },
                        {
                            "id": 117384,
                            "name": "Goodland",
                            "latitude": "40.76337000",
                            "longitude": "-87.29363000"
                        },
                        {
                            "id": 117408,
                            "name": "Goshen",
                            "latitude": "41.58227000",
                            "longitude": "-85.83444000"
                        },
                        {
                            "id": 117424,
                            "name": "Grabill",
                            "latitude": "41.21088000",
                            "longitude": "-84.96691000"
                        },
                        {
                            "id": 117488,
                            "name": "Granger",
                            "latitude": "41.75338000",
                            "longitude": "-86.11084000"
                        },
                        {
                            "id": 117512,
                            "name": "Grant County",
                            "latitude": "40.51584000",
                            "longitude": "-85.65473000"
                        },
                        {
                            "id": 117624,
                            "name": "Greencastle",
                            "latitude": "39.64449000",
                            "longitude": "-86.86473000"
                        },
                        {
                            "id": 117626,
                            "name": "Greendale",
                            "latitude": "39.11256000",
                            "longitude": "-84.86412000"
                        },
                        {
                            "id": 117635,
                            "name": "Greene County",
                            "latitude": "39.03633000",
                            "longitude": "-86.96205000"
                        },
                        {
                            "id": 117646,
                            "name": "Greenfield",
                            "latitude": "39.78504000",
                            "longitude": "-85.76942000"
                        },
                        {
                            "id": 117669,
                            "name": "Greensburg",
                            "latitude": "39.33727000",
                            "longitude": "-85.48358000"
                        },
                        {
                            "id": 117674,
                            "name": "Greentown",
                            "latitude": "40.47809000",
                            "longitude": "-85.96665000"
                        },
                        {
                            "id": 117702,
                            "name": "Greenwood",
                            "latitude": "39.61366000",
                            "longitude": "-86.10665000"
                        },
                        {
                            "id": 117732,
                            "name": "Griffith",
                            "latitude": "41.52837000",
                            "longitude": "-87.42365000"
                        },
                        {
                            "id": 117741,
                            "name": "Grissom Air Force Base",
                            "latitude": "40.65753000",
                            "longitude": "-86.14755000"
                        },
                        {
                            "id": 117798,
                            "name": "Gulivoire Park",
                            "latitude": "41.61338000",
                            "longitude": "-86.24528000"
                        },
                        {
                            "id": 117837,
                            "name": "Hagerstown",
                            "latitude": "39.91116000",
                            "longitude": "-85.16163000"
                        },
                        {
                            "id": 117891,
                            "name": "Hamilton",
                            "latitude": "41.53366000",
                            "longitude": "-84.91274000"
                        },
                        {
                            "id": 117901,
                            "name": "Hamilton County",
                            "latitude": "40.07249000",
                            "longitude": "-86.05201000"
                        },
                        {
                            "id": 117913,
                            "name": "Hammond",
                            "latitude": "41.58337000",
                            "longitude": "-87.50004000"
                        },
                        {
                            "id": 117951,
                            "name": "Hancock County",
                            "latitude": "39.82355000",
                            "longitude": "-85.77324000"
                        },
                        {
                            "id": 117966,
                            "name": "Hanover",
                            "latitude": "38.71423000",
                            "longitude": "-85.47357000"
                        },
                        {
                            "id": 118017,
                            "name": "Harlan",
                            "latitude": "41.19616000",
                            "longitude": "-84.91969000"
                        },
                        {
                            "id": 118062,
                            "name": "Harrison County",
                            "latitude": "38.19512000",
                            "longitude": "-86.11131000"
                        },
                        {
                            "id": 118094,
                            "name": "Hartford City",
                            "latitude": "40.45115000",
                            "longitude": "-85.36997000"
                        },
                        {
                            "id": 118141,
                            "name": "Haubstadt",
                            "latitude": "38.20504000",
                            "longitude": "-87.57419000"
                        },
                        {
                            "id": 118235,
                            "name": "Hebron",
                            "latitude": "41.31865000",
                            "longitude": "-87.20031000"
                        },
                        {
                            "id": 118287,
                            "name": "Hendricks County",
                            "latitude": "39.76952000",
                            "longitude": "-86.50998000"
                        },
                        {
                            "id": 118299,
                            "name": "Henry County",
                            "latitude": "39.93104000",
                            "longitude": "-85.39644000"
                        },
                        {
                            "id": 118308,
                            "name": "Henryville",
                            "latitude": "38.54173000",
                            "longitude": "-85.76774000"
                        },
                        {
                            "id": 118320,
                            "name": "Heritage Lake",
                            "latitude": "39.72779000",
                            "longitude": "-86.71022000"
                        },
                        {
                            "id": 118379,
                            "name": "Hidden Valley",
                            "latitude": "39.16228000",
                            "longitude": "-84.84301000"
                        },
                        {
                            "id": 118396,
                            "name": "Highland",
                            "latitude": "41.55365000",
                            "longitude": "-87.45198000"
                        },
                        {
                            "id": 118497,
                            "name": "Hobart",
                            "latitude": "41.53226000",
                            "longitude": "-87.25504000"
                        },
                        {
                            "id": 118643,
                            "name": "Hope",
                            "latitude": "39.30394000",
                            "longitude": "-85.77137000"
                        },
                        {
                            "id": 118716,
                            "name": "Howard County",
                            "latitude": "40.48359000",
                            "longitude": "-86.11693000"
                        },
                        {
                            "id": 118751,
                            "name": "Hudson Lake",
                            "latitude": "41.71032000",
                            "longitude": "-86.53419000"
                        },
                        {
                            "id": 118793,
                            "name": "Huntertown",
                            "latitude": "41.22838000",
                            "longitude": "-85.17247000"
                        },
                        {
                            "id": 118794,
                            "name": "Huntingburg",
                            "latitude": "38.29894000",
                            "longitude": "-86.95500000"
                        },
                        {
                            "id": 118801,
                            "name": "Huntington",
                            "latitude": "40.88310000",
                            "longitude": "-85.49748000"
                        },
                        {
                            "id": 118806,
                            "name": "Huntington County",
                            "latitude": "40.82924000",
                            "longitude": "-85.48817000"
                        },
                        {
                            "id": 118905,
                            "name": "Indian Heights",
                            "latitude": "40.42726000",
                            "longitude": "-86.12555000"
                        },
                        {
                            "id": 118924,
                            "name": "Indianapolis",
                            "latitude": "39.76838000",
                            "longitude": "-86.15804000"
                        },
                        {
                            "id": 118933,
                            "name": "Ingalls",
                            "latitude": "39.95699000",
                            "longitude": "-85.80526000"
                        },
                        {
                            "id": 119080,
                            "name": "Jackson County",
                            "latitude": "38.90642000",
                            "longitude": "-86.03754000"
                        },
                        {
                            "id": 119137,
                            "name": "Jasonville",
                            "latitude": "39.16310000",
                            "longitude": "-87.19918000"
                        },
                        {
                            "id": 119141,
                            "name": "Jasper",
                            "latitude": "38.39144000",
                            "longitude": "-86.93111000"
                        },
                        {
                            "id": 119152,
                            "name": "Jasper County",
                            "latitude": "41.02300000",
                            "longitude": "-87.11612000"
                        },
                        {
                            "id": 119155,
                            "name": "Jay County",
                            "latitude": "40.43792000",
                            "longitude": "-85.00564000"
                        },
                        {
                            "id": 119180,
                            "name": "Jefferson County",
                            "latitude": "38.78582000",
                            "longitude": "-85.43857000"
                        },
                        {
                            "id": 119208,
                            "name": "Jeffersonville",
                            "latitude": "38.27757000",
                            "longitude": "-85.73718000"
                        },
                        {
                            "id": 119220,
                            "name": "Jennings County",
                            "latitude": "38.99693000",
                            "longitude": "-85.62806000"
                        },
                        {
                            "id": 119263,
                            "name": "Johnson County",
                            "latitude": "39.48997000",
                            "longitude": "-86.10164000"
                        },
                        {
                            "id": 119303,
                            "name": "Jonesboro",
                            "latitude": "40.47976000",
                            "longitude": "-85.62775000"
                        },
                        {
                            "id": 119451,
                            "name": "Kendallville",
                            "latitude": "41.44144000",
                            "longitude": "-85.26498000"
                        },
                        {
                            "id": 119496,
                            "name": "Kentland",
                            "latitude": "40.77032000",
                            "longitude": "-87.44530000"
                        },
                        {
                            "id": 119613,
                            "name": "Kingsford Heights",
                            "latitude": "41.48060000",
                            "longitude": "-86.69169000"
                        },
                        {
                            "id": 119676,
                            "name": "Knightstown",
                            "latitude": "39.79560000",
                            "longitude": "-85.52636000"
                        },
                        {
                            "id": 119682,
                            "name": "Knox",
                            "latitude": "41.29588000",
                            "longitude": "-86.62501000"
                        },
                        {
                            "id": 119685,
                            "name": "Knox County",
                            "latitude": "38.68909000",
                            "longitude": "-87.41801000"
                        },
                        {
                            "id": 119702,
                            "name": "Kokomo",
                            "latitude": "40.48643000",
                            "longitude": "-86.13360000"
                        },
                        {
                            "id": 119706,
                            "name": "Koontz Lake",
                            "latitude": "41.41810000",
                            "longitude": "-86.48585000"
                        },
                        {
                            "id": 119710,
                            "name": "Kosciusko County",
                            "latitude": "41.24410000",
                            "longitude": "-85.86072000"
                        },
                        {
                            "id": 119714,
                            "name": "Kouts",
                            "latitude": "41.31671000",
                            "longitude": "-87.02586000"
                        },
                        {
                            "id": 119777,
                            "name": "La Porte",
                            "latitude": "41.60774000",
                            "longitude": "-86.71389000"
                        },
                        {
                            "id": 119834,
                            "name": "Lafayette",
                            "latitude": "40.41670000",
                            "longitude": "-86.87529000"
                        },
                        {
                            "id": 119848,
                            "name": "Lagrange",
                            "latitude": "41.64172000",
                            "longitude": "-85.41665000"
                        },
                        {
                            "id": 119802,
                            "name": "LaGrange County",
                            "latitude": "41.64261000",
                            "longitude": "-85.42650000"
                        },
                        {
                            "id": 119892,
                            "name": "Lake County",
                            "latitude": "41.47221000",
                            "longitude": "-87.37637000"
                        },
                        {
                            "id": 119901,
                            "name": "Lake Dalecarlia",
                            "latitude": "41.33087000",
                            "longitude": "-87.39476000"
                        },
                        {
                            "id": 119979,
                            "name": "Lake Station",
                            "latitude": "41.57504000",
                            "longitude": "-87.23892000"
                        },
                        {
                            "id": 120026,
                            "name": "Lakes of the Four Seasons",
                            "latitude": "41.41032000",
                            "longitude": "-87.21309000"
                        },
                        {
                            "id": 120133,
                            "name": "Lapel",
                            "latitude": "40.06837000",
                            "longitude": "-85.84831000"
                        },
                        {
                            "id": 119804,
                            "name": "LaPorte",
                            "latitude": "41.61060000",
                            "longitude": "-86.72252000"
                        },
                        {
                            "id": 119805,
                            "name": "LaPorte County",
                            "latitude": "41.54902000",
                            "longitude": "-86.74237000"
                        },
                        {
                            "id": 120225,
                            "name": "Lawrence",
                            "latitude": "39.83865000",
                            "longitude": "-86.02526000"
                        },
                        {
                            "id": 120232,
                            "name": "Lawrence County",
                            "latitude": "38.84116000",
                            "longitude": "-86.48345000"
                        },
                        {
                            "id": 120240,
                            "name": "Lawrenceburg",
                            "latitude": "39.09089000",
                            "longitude": "-84.84995000"
                        },
                        {
                            "id": 120289,
                            "name": "Lebanon",
                            "latitude": "40.04837000",
                            "longitude": "-86.46917000"
                        },
                        {
                            "id": 120379,
                            "name": "Leo-Cedarville",
                            "latitude": "41.21255000",
                            "longitude": "-85.01664000"
                        },
                        {
                            "id": 120460,
                            "name": "Liberty",
                            "latitude": "39.63560000",
                            "longitude": "-84.93107000"
                        },
                        {
                            "id": 120481,
                            "name": "Ligonier",
                            "latitude": "41.46588000",
                            "longitude": "-85.58748000"
                        },
                        {
                            "id": 120589,
                            "name": "Linton",
                            "latitude": "39.03477000",
                            "longitude": "-87.16585000"
                        },
                        {
                            "id": 120707,
                            "name": "Logansport",
                            "latitude": "40.75448000",
                            "longitude": "-86.35667000"
                        },
                        {
                            "id": 120736,
                            "name": "Long Beach",
                            "latitude": "41.73893000",
                            "longitude": "-86.85697000"
                        },
                        {
                            "id": 120764,
                            "name": "Loogootee",
                            "latitude": "38.67699000",
                            "longitude": "-86.91417000"
                        },
                        {
                            "id": 120835,
                            "name": "Lowell",
                            "latitude": "41.29142000",
                            "longitude": "-87.42059000"
                        },
                        {
                            "id": 120926,
                            "name": "Lynn",
                            "latitude": "40.04977000",
                            "longitude": "-84.93969000"
                        },
                        {
                            "id": 120988,
                            "name": "Madison",
                            "latitude": "38.73589000",
                            "longitude": "-85.37996000"
                        },
                        {
                            "id": 121015,
                            "name": "Madison County",
                            "latitude": "40.16166000",
                            "longitude": "-85.71935000"
                        },
                        {
                            "id": 121243,
                            "name": "Marion",
                            "latitude": "40.55837000",
                            "longitude": "-85.65914000"
                        },
                        {
                            "id": 121252,
                            "name": "Marion County",
                            "latitude": "39.78171000",
                            "longitude": "-86.13847000"
                        },
                        {
                            "id": 121271,
                            "name": "Markle",
                            "latitude": "40.82462000",
                            "longitude": "-85.33884000"
                        },
                        {
                            "id": 121319,
                            "name": "Marshall County",
                            "latitude": "41.32485000",
                            "longitude": "-86.26176000"
                        },
                        {
                            "id": 121338,
                            "name": "Martin County",
                            "latitude": "38.70801000",
                            "longitude": "-86.80307000"
                        },
                        {
                            "id": 121348,
                            "name": "Martinsville",
                            "latitude": "39.42783000",
                            "longitude": "-86.42833000"
                        },
                        {
                            "id": 121482,
                            "name": "McCordsville",
                            "latitude": "39.90810000",
                            "longitude": "-85.92276000"
                        },
                        {
                            "id": 121627,
                            "name": "Melody Hill",
                            "latitude": "38.02615000",
                            "longitude": "-87.51585000"
                        },
                        {
                            "id": 121696,
                            "name": "Meridian Hills",
                            "latitude": "39.89004000",
                            "longitude": "-86.15721000"
                        },
                        {
                            "id": 121708,
                            "name": "Merrillville",
                            "latitude": "41.48281000",
                            "longitude": "-87.33281000"
                        },
                        {
                            "id": 121752,
                            "name": "Miami County",
                            "latitude": "40.76950000",
                            "longitude": "-86.04502000"
                        },
                        {
                            "id": 121760,
                            "name": "Michigan City",
                            "latitude": "41.70754000",
                            "longitude": "-86.89503000"
                        },
                        {
                            "id": 121772,
                            "name": "Middlebury",
                            "latitude": "41.67533000",
                            "longitude": "-85.70610000"
                        },
                        {
                            "id": 121790,
                            "name": "Middletown",
                            "latitude": "40.05727000",
                            "longitude": "-85.53720000"
                        },
                        {
                            "id": 121835,
                            "name": "Milan",
                            "latitude": "39.12117000",
                            "longitude": "-85.13135000"
                        },
                        {
                            "id": 121849,
                            "name": "Milford",
                            "latitude": "41.40977000",
                            "longitude": "-85.84555000"
                        },
                        {
                            "id": 121976,
                            "name": "Mishawaka",
                            "latitude": "41.66199000",
                            "longitude": "-86.15862000"
                        },
                        {
                            "id": 121996,
                            "name": "Mitchell",
                            "latitude": "38.73283000",
                            "longitude": "-86.47360000"
                        },
                        {
                            "id": 122050,
                            "name": "Monon",
                            "latitude": "40.86782000",
                            "longitude": "-86.87890000"
                        },
                        {
                            "id": 122072,
                            "name": "Monroe County",
                            "latitude": "39.16092000",
                            "longitude": "-86.52314000"
                        },
                        {
                            "id": 122085,
                            "name": "Monroeville",
                            "latitude": "40.97477000",
                            "longitude": "-84.86830000"
                        },
                        {
                            "id": 122087,
                            "name": "Monrovia",
                            "latitude": "39.57894000",
                            "longitude": "-86.48222000"
                        },
                        {
                            "id": 122153,
                            "name": "Montgomery County",
                            "latitude": "40.04038000",
                            "longitude": "-86.89330000"
                        },
                        {
                            "id": 122165,
                            "name": "Monticello",
                            "latitude": "40.74532000",
                            "longitude": "-86.76473000"
                        },
                        {
                            "id": 122176,
                            "name": "Montpelier",
                            "latitude": "40.55393000",
                            "longitude": "-85.27747000"
                        },
                        {
                            "id": 122209,
                            "name": "Mooresville",
                            "latitude": "39.61282000",
                            "longitude": "-86.37416000"
                        },
                        {
                            "id": 122239,
                            "name": "Morgan County",
                            "latitude": "39.48155000",
                            "longitude": "-86.44621000"
                        },
                        {
                            "id": 122260,
                            "name": "Morocco",
                            "latitude": "40.94615000",
                            "longitude": "-87.45336000"
                        },
                        {
                            "id": 122280,
                            "name": "Morristown",
                            "latitude": "39.67338000",
                            "longitude": "-85.69859000"
                        },
                        {
                            "id": 122394,
                            "name": "Mount Vernon",
                            "latitude": "37.93227000",
                            "longitude": "-87.89503000"
                        },
                        {
                            "id": 122455,
                            "name": "Mulberry",
                            "latitude": "40.34448000",
                            "longitude": "-86.66528000"
                        },
                        {
                            "id": 122465,
                            "name": "Muncie",
                            "latitude": "40.19338000",
                            "longitude": "-85.38636000"
                        },
                        {
                            "id": 122478,
                            "name": "Munster",
                            "latitude": "41.56448000",
                            "longitude": "-87.51254000"
                        },
                        {
                            "id": 122561,
                            "name": "Nappanee",
                            "latitude": "41.44283000",
                            "longitude": "-86.00139000"
                        },
                        {
                            "id": 122573,
                            "name": "Nashville",
                            "latitude": "39.20727000",
                            "longitude": "-86.25110000"
                        },
                        {
                            "id": 122654,
                            "name": "New Albany",
                            "latitude": "38.28562000",
                            "longitude": "-85.82413000"
                        },
                        {
                            "id": 122680,
                            "name": "New Carlisle",
                            "latitude": "41.70032000",
                            "longitude": "-86.50946000"
                        },
                        {
                            "id": 122684,
                            "name": "New Castle",
                            "latitude": "39.92894000",
                            "longitude": "-85.37025000"
                        },
                        {
                            "id": 122693,
                            "name": "New Chicago",
                            "latitude": "41.55837000",
                            "longitude": "-87.27448000"
                        },
                        {
                            "id": 122717,
                            "name": "New Haven",
                            "latitude": "41.07060000",
                            "longitude": "-85.01441000"
                        },
                        {
                            "id": 122758,
                            "name": "New Palestine",
                            "latitude": "39.72199000",
                            "longitude": "-85.88915000"
                        },
                        {
                            "id": 122760,
                            "name": "New Paris",
                            "latitude": "41.50033000",
                            "longitude": "-85.82805000"
                        },
                        {
                            "id": 122761,
                            "name": "New Pekin",
                            "latitude": "38.50506000",
                            "longitude": "-86.01692000"
                        },
                        {
                            "id": 122791,
                            "name": "New Whiteland",
                            "latitude": "39.55810000",
                            "longitude": "-86.09526000"
                        },
                        {
                            "id": 122816,
                            "name": "Newburgh",
                            "latitude": "37.94449000",
                            "longitude": "-87.40529000"
                        },
                        {
                            "id": 122840,
                            "name": "Newport",
                            "latitude": "39.88420000",
                            "longitude": "-87.40863000"
                        },
                        {
                            "id": 122873,
                            "name": "Newton County",
                            "latitude": "40.95585000",
                            "longitude": "-87.39754000"
                        },
                        {
                            "id": 122918,
                            "name": "Noble County",
                            "latitude": "41.39860000",
                            "longitude": "-85.41747000"
                        },
                        {
                            "id": 122921,
                            "name": "Noblesville",
                            "latitude": "40.04559000",
                            "longitude": "-86.00860000"
                        },
                        {
                            "id": 123044,
                            "name": "North Judson",
                            "latitude": "41.21504000",
                            "longitude": "-86.77585000"
                        },
                        {
                            "id": 123057,
                            "name": "North Liberty",
                            "latitude": "41.53421000",
                            "longitude": "-86.42723000"
                        },
                        {
                            "id": 123061,
                            "name": "North Madison",
                            "latitude": "38.76784000",
                            "longitude": "-85.39663000"
                        },
                        {
                            "id": 123062,
                            "name": "North Manchester",
                            "latitude": "41.00060000",
                            "longitude": "-85.76860000"
                        },
                        {
                            "id": 123114,
                            "name": "North Terre Haute",
                            "latitude": "39.52781000",
                            "longitude": "-87.36030000"
                        },
                        {
                            "id": 123121,
                            "name": "North Vernon",
                            "latitude": "39.00617000",
                            "longitude": "-85.62358000"
                        },
                        {
                            "id": 123126,
                            "name": "North Webster",
                            "latitude": "41.32560000",
                            "longitude": "-85.69776000"
                        },
                        {
                            "id": 123204,
                            "name": "Notre Dame",
                            "latitude": "41.70019000",
                            "longitude": "-86.23793000"
                        },
                        {
                            "id": 123254,
                            "name": "Oak Park",
                            "latitude": "38.30562000",
                            "longitude": "-85.69635000"
                        },
                        {
                            "id": 123294,
                            "name": "Oakland City",
                            "latitude": "38.33866000",
                            "longitude": "-87.34501000"
                        },
                        {
                            "id": 123362,
                            "name": "Odon",
                            "latitude": "38.84283000",
                            "longitude": "-86.99140000"
                        },
                        {
                            "id": 123370,
                            "name": "Ogden Dunes",
                            "latitude": "41.62281000",
                            "longitude": "-87.19170000"
                        },
                        {
                            "id": 123382,
                            "name": "Ohio County",
                            "latitude": "38.95010000",
                            "longitude": "-84.96503000"
                        },
                        {
                            "id": 123491,
                            "name": "Oolitic",
                            "latitude": "38.90088000",
                            "longitude": "-86.52527000"
                        },
                        {
                            "id": 123515,
                            "name": "Orange County",
                            "latitude": "38.54178000",
                            "longitude": "-86.49507000"
                        },
                        {
                            "id": 123563,
                            "name": "Orleans",
                            "latitude": "38.66172000",
                            "longitude": "-86.45166000"
                        },
                        {
                            "id": 123600,
                            "name": "Osceola",
                            "latitude": "41.66505000",
                            "longitude": "-86.07584000"
                        },
                        {
                            "id": 123608,
                            "name": "Osgood",
                            "latitude": "39.12922000",
                            "longitude": "-85.29163000"
                        },
                        {
                            "id": 123616,
                            "name": "Ossian",
                            "latitude": "40.88060000",
                            "longitude": "-85.16636000"
                        },
                        {
                            "id": 123642,
                            "name": "Otterbein",
                            "latitude": "40.49059000",
                            "longitude": "-87.09640000"
                        },
                        {
                            "id": 123661,
                            "name": "Owen County",
                            "latitude": "39.31281000",
                            "longitude": "-86.83765000"
                        },
                        {
                            "id": 123665,
                            "name": "Owensville",
                            "latitude": "38.27199000",
                            "longitude": "-87.68780000"
                        },
                        {
                            "id": 123682,
                            "name": "Oxford",
                            "latitude": "40.51976000",
                            "longitude": "-87.24779000"
                        },
                        {
                            "id": 123811,
                            "name": "Paoli",
                            "latitude": "38.55617000",
                            "longitude": "-86.46832000"
                        },
                        {
                            "id": 123857,
                            "name": "Parke County",
                            "latitude": "39.77363000",
                            "longitude": "-87.20636000"
                        },
                        {
                            "id": 123864,
                            "name": "Parker City",
                            "latitude": "40.18893000",
                            "longitude": "-85.20413000"
                        },
                        {
                            "id": 124016,
                            "name": "Pendleton",
                            "latitude": "39.99754000",
                            "longitude": "-85.74664000"
                        },
                        {
                            "id": 124077,
                            "name": "Perry County",
                            "latitude": "38.07965000",
                            "longitude": "-86.63803000"
                        },
                        {
                            "id": 124096,
                            "name": "Peru",
                            "latitude": "40.75365000",
                            "longitude": "-86.06888000"
                        },
                        {
                            "id": 124103,
                            "name": "Petersburg",
                            "latitude": "38.49199000",
                            "longitude": "-87.27862000"
                        },
                        {
                            "id": 124176,
                            "name": "Pierceton",
                            "latitude": "41.20032000",
                            "longitude": "-85.70554000"
                        },
                        {
                            "id": 124189,
                            "name": "Pike County",
                            "latitude": "38.39878000",
                            "longitude": "-87.23216000"
                        },
                        {
                            "id": 124289,
                            "name": "Pittsboro",
                            "latitude": "39.86393000",
                            "longitude": "-86.46694000"
                        },
                        {
                            "id": 124318,
                            "name": "Plainfield",
                            "latitude": "39.70421000",
                            "longitude": "-86.39944000"
                        },
                        {
                            "id": 124401,
                            "name": "Plymouth",
                            "latitude": "41.34366000",
                            "longitude": "-86.30973000"
                        },
                        {
                            "id": 124543,
                            "name": "Portage",
                            "latitude": "41.57587000",
                            "longitude": "-87.17615000"
                        },
                        {
                            "id": 124551,
                            "name": "Porter",
                            "latitude": "41.61559000",
                            "longitude": "-87.07420000"
                        },
                        {
                            "id": 124553,
                            "name": "Porter County",
                            "latitude": "41.50884000",
                            "longitude": "-87.07332000"
                        },
                        {
                            "id": 124560,
                            "name": "Portland",
                            "latitude": "40.43449000",
                            "longitude": "-84.97775000"
                        },
                        {
                            "id": 124574,
                            "name": "Posey County",
                            "latitude": "38.02189000",
                            "longitude": "-87.86847000"
                        },
                        {
                            "id": 124575,
                            "name": "Poseyville",
                            "latitude": "38.17004000",
                            "longitude": "-87.78308000"
                        },
                        {
                            "id": 124678,
                            "name": "Princes Lakes",
                            "latitude": "39.35366000",
                            "longitude": "-86.09805000"
                        },
                        {
                            "id": 124681,
                            "name": "Princeton",
                            "latitude": "38.35532000",
                            "longitude": "-87.56752000"
                        },
                        {
                            "id": 124743,
                            "name": "Pulaski County",
                            "latitude": "41.04183000",
                            "longitude": "-86.69878000"
                        },
                        {
                            "id": 124763,
                            "name": "Putnam County",
                            "latitude": "39.66626000",
                            "longitude": "-86.84500000"
                        },
                        {
                            "id": 124879,
                            "name": "Randolph County",
                            "latitude": "40.15764000",
                            "longitude": "-85.01131000"
                        },
                        {
                            "id": 124973,
                            "name": "Redkey",
                            "latitude": "40.34893000",
                            "longitude": "-85.14997000"
                        },
                        {
                            "id": 125014,
                            "name": "Remington",
                            "latitude": "40.76087000",
                            "longitude": "-87.15085000"
                        },
                        {
                            "id": 125023,
                            "name": "Rensselaer",
                            "latitude": "40.93670000",
                            "longitude": "-87.15086000"
                        },
                        {
                            "id": 125084,
                            "name": "Richmond",
                            "latitude": "39.82894000",
                            "longitude": "-84.89024000"
                        },
                        {
                            "id": 125165,
                            "name": "Ripley County",
                            "latitude": "39.10345000",
                            "longitude": "-85.26239000"
                        },
                        {
                            "id": 125169,
                            "name": "Rising Sun",
                            "latitude": "38.94950000",
                            "longitude": "-84.85384000"
                        },
                        {
                            "id": 125230,
                            "name": "Roanoke",
                            "latitude": "40.96255000",
                            "longitude": "-85.37331000"
                        },
                        {
                            "id": 125263,
                            "name": "Rochester",
                            "latitude": "41.06476000",
                            "longitude": "-86.21583000"
                        },
                        {
                            "id": 125315,
                            "name": "Rockport",
                            "latitude": "37.88311000",
                            "longitude": "-87.04944000"
                        },
                        {
                            "id": 125321,
                            "name": "Rockville",
                            "latitude": "39.76254000",
                            "longitude": "-87.22918000"
                        },
                        {
                            "id": 125380,
                            "name": "Rome City",
                            "latitude": "41.49616000",
                            "longitude": "-85.37665000"
                        },
                        {
                            "id": 125425,
                            "name": "Roselawn",
                            "latitude": "41.14170000",
                            "longitude": "-87.31475000"
                        },
                        {
                            "id": 125460,
                            "name": "Rossville",
                            "latitude": "40.41698000",
                            "longitude": "-86.59472000"
                        },
                        {
                            "id": 125520,
                            "name": "Rush County",
                            "latitude": "39.61995000",
                            "longitude": "-85.46576000"
                        },
                        {
                            "id": 125525,
                            "name": "Rushville",
                            "latitude": "39.60921000",
                            "longitude": "-85.44636000"
                        },
                        {
                            "id": 125545,
                            "name": "Russiaville",
                            "latitude": "40.41754000",
                            "longitude": "-86.27138000"
                        },
                        {
                            "id": 125662,
                            "name": "Saint John",
                            "latitude": "41.45004000",
                            "longitude": "-87.47004000"
                        },
                        {
                            "id": 125675,
                            "name": "Saint Joseph County",
                            "latitude": "41.61672000",
                            "longitude": "-86.28986000"
                        },
                        {
                            "id": 125700,
                            "name": "Saint Paul",
                            "latitude": "39.42810000",
                            "longitude": "-85.62831000"
                        },
                        {
                            "id": 125727,
                            "name": "Salem",
                            "latitude": "38.60561000",
                            "longitude": "-86.10109000"
                        },
                        {
                            "id": 125909,
                            "name": "Santa Claus",
                            "latitude": "38.12005000",
                            "longitude": "-86.91416000"
                        },
                        {
                            "id": 126005,
                            "name": "Schererville",
                            "latitude": "41.47892000",
                            "longitude": "-87.45476000"
                        },
                        {
                            "id": 126045,
                            "name": "Scott County",
                            "latitude": "38.68507000",
                            "longitude": "-85.74747000"
                        },
                        {
                            "id": 126062,
                            "name": "Scottsburg",
                            "latitude": "38.68561000",
                            "longitude": "-85.77025000"
                        },
                        {
                            "id": 126126,
                            "name": "Seelyville",
                            "latitude": "39.49198000",
                            "longitude": "-87.26724000"
                        },
                        {
                            "id": 126135,
                            "name": "Sellersburg",
                            "latitude": "38.39812000",
                            "longitude": "-85.75496000"
                        },
                        {
                            "id": 126192,
                            "name": "Seymour",
                            "latitude": "38.95922000",
                            "longitude": "-85.89025000"
                        },
                        {
                            "id": 126201,
                            "name": "Shadeland",
                            "latitude": "40.37365000",
                            "longitude": "-86.94890000"
                        },
                        {
                            "id": 126264,
                            "name": "Shelburn",
                            "latitude": "39.17837000",
                            "longitude": "-87.39363000"
                        },
                        {
                            "id": 126274,
                            "name": "Shelby County",
                            "latitude": "39.52369000",
                            "longitude": "-85.79170000"
                        },
                        {
                            "id": 126280,
                            "name": "Shelbyville",
                            "latitude": "39.52144000",
                            "longitude": "-85.77692000"
                        },
                        {
                            "id": 126318,
                            "name": "Sheridan",
                            "latitude": "40.13504000",
                            "longitude": "-86.22055000"
                        },
                        {
                            "id": 126364,
                            "name": "Shoals",
                            "latitude": "38.66644000",
                            "longitude": "-86.79111000"
                        },
                        {
                            "id": 126373,
                            "name": "Shorewood Forest",
                            "latitude": "41.46315000",
                            "longitude": "-87.14472000"
                        },
                        {
                            "id": 126447,
                            "name": "Simonton Lake",
                            "latitude": "41.75422000",
                            "longitude": "-85.97500000"
                        },
                        {
                            "id": 126531,
                            "name": "Smithville-Sanders",
                            "latitude": "39.05969000",
                            "longitude": "-86.51077000"
                        },
                        {
                            "id": 126624,
                            "name": "South Bend",
                            "latitude": "41.68338000",
                            "longitude": "-86.25001000"
                        },
                        {
                            "id": 126666,
                            "name": "South Haven",
                            "latitude": "41.54198000",
                            "longitude": "-87.13726000"
                        },
                        {
                            "id": 126747,
                            "name": "South Whitley",
                            "latitude": "41.08477000",
                            "longitude": "-85.62804000"
                        },
                        {
                            "id": 126782,
                            "name": "Southport",
                            "latitude": "39.66505000",
                            "longitude": "-86.12776000"
                        },
                        {
                            "id": 126818,
                            "name": "Speedway",
                            "latitude": "39.80227000",
                            "longitude": "-86.26721000"
                        },
                        {
                            "id": 126820,
                            "name": "Spencer",
                            "latitude": "39.28671000",
                            "longitude": "-86.76251000"
                        },
                        {
                            "id": 126828,
                            "name": "Spencer County",
                            "latitude": "38.01406000",
                            "longitude": "-87.00771000"
                        },
                        {
                            "id": 126987,
                            "name": "Starke County",
                            "latitude": "41.28093000",
                            "longitude": "-86.64765000"
                        },
                        {
                            "id": 127038,
                            "name": "Steuben County",
                            "latitude": "41.64387000",
                            "longitude": "-85.00077000"
                        },
                        {
                            "id": 127182,
                            "name": "Sullivan",
                            "latitude": "39.09532000",
                            "longitude": "-87.40585000"
                        },
                        {
                            "id": 127186,
                            "name": "Sullivan County",
                            "latitude": "39.08883000",
                            "longitude": "-87.41469000"
                        },
                        {
                            "id": 127255,
                            "name": "Sunman",
                            "latitude": "39.23700000",
                            "longitude": "-85.09468000"
                        },
                        {
                            "id": 127339,
                            "name": "Sweetser",
                            "latitude": "40.57198000",
                            "longitude": "-85.76915000"
                        },
                        {
                            "id": 127349,
                            "name": "Switzerland County",
                            "latitude": "38.82616000",
                            "longitude": "-85.03700000"
                        },
                        {
                            "id": 127363,
                            "name": "Syracuse",
                            "latitude": "41.42783000",
                            "longitude": "-85.75249000"
                        },
                        {
                            "id": 127488,
                            "name": "Tell City",
                            "latitude": "37.95144000",
                            "longitude": "-86.76777000"
                        },
                        {
                            "id": 127520,
                            "name": "Terre Haute",
                            "latitude": "39.46670000",
                            "longitude": "-87.41391000"
                        },
                        {
                            "id": 127605,
                            "name": "Thorntown",
                            "latitude": "40.12948000",
                            "longitude": "-86.60667000"
                        },
                        {
                            "id": 127673,
                            "name": "Tippecanoe County",
                            "latitude": "40.38862000",
                            "longitude": "-86.89410000"
                        },
                        {
                            "id": 127676,
                            "name": "Tipton",
                            "latitude": "40.28226000",
                            "longitude": "-86.04110000"
                        },
                        {
                            "id": 127680,
                            "name": "Tipton County",
                            "latitude": "40.31135000",
                            "longitude": "-86.05186000"
                        },
                        {
                            "id": 127733,
                            "name": "Topeka",
                            "latitude": "41.53922000",
                            "longitude": "-85.53971000"
                        },
                        {
                            "id": 127775,
                            "name": "Trafalgar",
                            "latitude": "39.41616000",
                            "longitude": "-86.15082000"
                        },
                        {
                            "id": 127777,
                            "name": "Trail Creek",
                            "latitude": "41.69837000",
                            "longitude": "-86.85920000"
                        },
                        {
                            "id": 127817,
                            "name": "Tri-Lakes",
                            "latitude": "41.24588000",
                            "longitude": "-85.44192000"
                        },
                        {
                            "id": 127983,
                            "name": "Union City",
                            "latitude": "40.20199000",
                            "longitude": "-84.80913000"
                        },
                        {
                            "id": 127992,
                            "name": "Union County",
                            "latitude": "39.62555000",
                            "longitude": "-84.92514000"
                        },
                        {
                            "id": 128038,
                            "name": "Upland",
                            "latitude": "40.47560000",
                            "longitude": "-85.49442000"
                        },
                        {
                            "id": 128121,
                            "name": "Valparaiso",
                            "latitude": "41.47309000",
                            "longitude": "-87.06114000"
                        },
                        {
                            "id": 128150,
                            "name": "Vanderburgh County",
                            "latitude": "38.02514000",
                            "longitude": "-87.58578000"
                        },
                        {
                            "id": 128161,
                            "name": "Veedersburg",
                            "latitude": "40.11309000",
                            "longitude": "-87.26251000"
                        },
                        {
                            "id": 128186,
                            "name": "Vermillion County",
                            "latitude": "39.85380000",
                            "longitude": "-87.46397000"
                        },
                        {
                            "id": 128208,
                            "name": "Versailles",
                            "latitude": "39.07200000",
                            "longitude": "-85.25190000"
                        },
                        {
                            "id": 128213,
                            "name": "Vevay",
                            "latitude": "38.74784000",
                            "longitude": "-85.06717000"
                        },
                        {
                            "id": 128239,
                            "name": "Vigo County",
                            "latitude": "39.43064000",
                            "longitude": "-87.38996000"
                        },
                        {
                            "id": 128263,
                            "name": "Vincennes",
                            "latitude": "38.67727000",
                            "longitude": "-87.52863000"
                        },
                        {
                            "id": 128309,
                            "name": "Wabash",
                            "latitude": "40.79782000",
                            "longitude": "-85.82054000"
                        },
                        {
                            "id": 128311,
                            "name": "Wabash County",
                            "latitude": "40.84569000",
                            "longitude": "-85.79401000"
                        },
                        {
                            "id": 128355,
                            "name": "Wakarusa",
                            "latitude": "41.53616000",
                            "longitude": "-86.02083000"
                        },
                        {
                            "id": 128391,
                            "name": "Walkerton",
                            "latitude": "41.46671000",
                            "longitude": "-86.48307000"
                        },
                        {
                            "id": 128435,
                            "name": "Walton",
                            "latitude": "40.66087000",
                            "longitude": "-86.24194000"
                        },
                        {
                            "id": 128450,
                            "name": "Wanatah",
                            "latitude": "41.43060000",
                            "longitude": "-86.89836000"
                        },
                        {
                            "id": 128477,
                            "name": "Warren",
                            "latitude": "40.68282000",
                            "longitude": "-85.42720000"
                        },
                        {
                            "id": 128495,
                            "name": "Warren County",
                            "latitude": "40.34690000",
                            "longitude": "-87.35331000"
                        },
                        {
                            "id": 128499,
                            "name": "Warren Park",
                            "latitude": "39.78199000",
                            "longitude": "-86.05026000"
                        },
                        {
                            "id": 128511,
                            "name": "Warrick County",
                            "latitude": "38.09217000",
                            "longitude": "-87.27205000"
                        },
                        {
                            "id": 128520,
                            "name": "Warsaw",
                            "latitude": "41.23810000",
                            "longitude": "-85.85305000"
                        },
                        {
                            "id": 128538,
                            "name": "Washington",
                            "latitude": "38.65922000",
                            "longitude": "-87.17279000"
                        },
                        {
                            "id": 128557,
                            "name": "Washington County",
                            "latitude": "38.59998000",
                            "longitude": "-86.10531000"
                        },
                        {
                            "id": 128613,
                            "name": "Waterloo",
                            "latitude": "41.43199000",
                            "longitude": "-85.01997000"
                        },
                        {
                            "id": 128691,
                            "name": "Wayne County",
                            "latitude": "39.86442000",
                            "longitude": "-85.00988000"
                        },
                        {
                            "id": 128786,
                            "name": "Wells County",
                            "latitude": "40.72919000",
                            "longitude": "-85.22122000"
                        },
                        {
                            "id": 128908,
                            "name": "West Lafayette",
                            "latitude": "40.42587000",
                            "longitude": "-86.90807000"
                        },
                        {
                            "id": 128989,
                            "name": "West Terre Haute",
                            "latitude": "39.46504000",
                            "longitude": "-87.45002000"
                        },
                        {
                            "id": 129027,
                            "name": "Westfield",
                            "latitude": "40.04282000",
                            "longitude": "-86.12749000"
                        },
                        {
                            "id": 129070,
                            "name": "Westport",
                            "latitude": "39.17589000",
                            "longitude": "-85.57303000"
                        },
                        {
                            "id": 129079,
                            "name": "Westville",
                            "latitude": "41.54143000",
                            "longitude": "-86.90058000"
                        },
                        {
                            "id": 129138,
                            "name": "White County",
                            "latitude": "40.74977000",
                            "longitude": "-86.86547000"
                        },
                        {
                            "id": 129176,
                            "name": "Whiteland",
                            "latitude": "39.55005000",
                            "longitude": "-86.07971000"
                        },
                        {
                            "id": 129188,
                            "name": "Whitestown",
                            "latitude": "39.99726000",
                            "longitude": "-86.34583000"
                        },
                        {
                            "id": 129196,
                            "name": "Whiting",
                            "latitude": "41.67976000",
                            "longitude": "-87.49449000"
                        },
                        {
                            "id": 129201,
                            "name": "Whitley County",
                            "latitude": "41.13938000",
                            "longitude": "-85.50512000"
                        },
                        {
                            "id": 129280,
                            "name": "Williamsport",
                            "latitude": "40.28837000",
                            "longitude": "-87.29390000"
                        },
                        {
                            "id": 129350,
                            "name": "Winamac",
                            "latitude": "41.05143000",
                            "longitude": "-86.60306000"
                        },
                        {
                            "id": 129357,
                            "name": "Winchester",
                            "latitude": "40.17199000",
                            "longitude": "-84.98135000"
                        },
                        {
                            "id": 129398,
                            "name": "Winfield",
                            "latitude": "41.40531000",
                            "longitude": "-87.27531000"
                        },
                        {
                            "id": 129426,
                            "name": "Winona Lake",
                            "latitude": "41.22727000",
                            "longitude": "-85.82193000"
                        },
                        {
                            "id": 129470,
                            "name": "Wolcottville",
                            "latitude": "41.52588000",
                            "longitude": "-85.36665000"
                        },
                        {
                            "id": 129503,
                            "name": "Woodburn",
                            "latitude": "41.12533000",
                            "longitude": "-84.85330000"
                        },
                        {
                            "id": 129596,
                            "name": "Worthington",
                            "latitude": "39.12504000",
                            "longitude": "-86.97945000"
                        },
                        {
                            "id": 129707,
                            "name": "Yorktown",
                            "latitude": "40.17365000",
                            "longitude": "-85.49414000"
                        },
                        {
                            "id": 129759,
                            "name": "Zionsville",
                            "latitude": "39.95087000",
                            "longitude": "-86.26194000"
                        }
                    ]
                },
                {
                    "id": 1459,
                    "name": "Iowa",
                    "state_code": "IA",
                    "cities": [
                        {
                            "id": 110997,
                            "name": "Ackley",
                            "latitude": "42.55415000",
                            "longitude": "-93.05326000"
                        },
                        {
                            "id": 111010,
                            "name": "Adair County",
                            "latitude": "41.33075000",
                            "longitude": "-94.47094000"
                        },
                        {
                            "id": 111020,
                            "name": "Adams County",
                            "latitude": "41.02898000",
                            "longitude": "-94.69918000"
                        },
                        {
                            "id": 111042,
                            "name": "Adel",
                            "latitude": "41.61443000",
                            "longitude": "-94.01745000"
                        },
                        {
                            "id": 111076,
                            "name": "Akron",
                            "latitude": "42.82888000",
                            "longitude": "-96.55948000"
                        },
                        {
                            "id": 111122,
                            "name": "Albia",
                            "latitude": "41.02667000",
                            "longitude": "-92.80575000"
                        },
                        {
                            "id": 111163,
                            "name": "Algona",
                            "latitude": "43.06997000",
                            "longitude": "-94.23302000"
                        },
                        {
                            "id": 111176,
                            "name": "Allamakee County",
                            "latitude": "43.28428000",
                            "longitude": "-91.37809000"
                        },
                        {
                            "id": 111201,
                            "name": "Allison",
                            "latitude": "42.75275000",
                            "longitude": "-92.79519000"
                        },
                        {
                            "id": 111229,
                            "name": "Alta",
                            "latitude": "42.67359000",
                            "longitude": "-95.29055000"
                        },
                        {
                            "id": 111245,
                            "name": "Alton",
                            "latitude": "42.98749000",
                            "longitude": "-96.01057000"
                        },
                        {
                            "id": 111247,
                            "name": "Altoona",
                            "latitude": "41.64416000",
                            "longitude": "-93.46466000"
                        },
                        {
                            "id": 111277,
                            "name": "Ames",
                            "latitude": "42.03471000",
                            "longitude": "-93.61994000"
                        },
                        {
                            "id": 111302,
                            "name": "Anamosa",
                            "latitude": "42.10834000",
                            "longitude": "-91.28516000"
                        },
                        {
                            "id": 111344,
                            "name": "Ankeny",
                            "latitude": "41.72971000",
                            "longitude": "-93.60577000"
                        },
                        {
                            "id": 111386,
                            "name": "Aplington",
                            "latitude": "42.58415000",
                            "longitude": "-92.88436000"
                        },
                        {
                            "id": 111391,
                            "name": "Appanoose County",
                            "latitude": "40.74316000",
                            "longitude": "-92.86861000"
                        },
                        {
                            "id": 111490,
                            "name": "Arnolds Park",
                            "latitude": "43.37274000",
                            "longitude": "-95.12388000"
                        },
                        {
                            "id": 111506,
                            "name": "Asbury",
                            "latitude": "42.51445000",
                            "longitude": "-90.75152000"
                        },
                        {
                            "id": 111586,
                            "name": "Atkins",
                            "latitude": "41.99694000",
                            "longitude": "-91.86213000"
                        },
                        {
                            "id": 111594,
                            "name": "Atlantic",
                            "latitude": "41.40360000",
                            "longitude": "-95.01388000"
                        },
                        {
                            "id": 111639,
                            "name": "Audubon",
                            "latitude": "41.71804000",
                            "longitude": "-94.93249000"
                        },
                        {
                            "id": 111641,
                            "name": "Audubon County",
                            "latitude": "41.68459000",
                            "longitude": "-94.90582000"
                        },
                        {
                            "id": 111687,
                            "name": "Avoca",
                            "latitude": "41.47666000",
                            "longitude": "-95.33805000"
                        },
                        {
                            "id": 111915,
                            "name": "Baxter",
                            "latitude": "41.82610000",
                            "longitude": "-93.15159000"
                        },
                        {
                            "id": 112022,
                            "name": "Bedford",
                            "latitude": "40.66693000",
                            "longitude": "-94.72136000"
                        },
                        {
                            "id": 112089,
                            "name": "Belle Plaine",
                            "latitude": "41.89694000",
                            "longitude": "-92.27824000"
                        },
                        {
                            "id": 112111,
                            "name": "Bellevue",
                            "latitude": "42.25863000",
                            "longitude": "-90.42291000"
                        },
                        {
                            "id": 112134,
                            "name": "Belmond",
                            "latitude": "42.84608000",
                            "longitude": "-93.61410000"
                        },
                        {
                            "id": 112203,
                            "name": "Benton County",
                            "latitude": "42.08019000",
                            "longitude": "-92.06569000"
                        },
                        {
                            "id": 112294,
                            "name": "Bettendorf",
                            "latitude": "41.52448000",
                            "longitude": "-90.51569000"
                        },
                        {
                            "id": 112377,
                            "name": "Black Hawk County",
                            "latitude": "42.47010000",
                            "longitude": "-92.30882000"
                        },
                        {
                            "id": 112436,
                            "name": "Bloomfield",
                            "latitude": "40.75169000",
                            "longitude": "-92.41491000"
                        },
                        {
                            "id": 112467,
                            "name": "Blue Grass",
                            "latitude": "41.50892000",
                            "longitude": "-90.76598000"
                        },
                        {
                            "id": 112534,
                            "name": "Bondurant",
                            "latitude": "41.70054000",
                            "longitude": "-93.46216000"
                        },
                        {
                            "id": 112553,
                            "name": "Boone",
                            "latitude": "42.05970000",
                            "longitude": "-93.88023000"
                        },
                        {
                            "id": 112558,
                            "name": "Boone County",
                            "latitude": "42.03655000",
                            "longitude": "-93.93167000"
                        },
                        {
                            "id": 112722,
                            "name": "Bremer County",
                            "latitude": "42.77459000",
                            "longitude": "-92.31805000"
                        },
                        {
                            "id": 112817,
                            "name": "Britt",
                            "latitude": "43.09774000",
                            "longitude": "-93.80189000"
                        },
                        {
                            "id": 112870,
                            "name": "Brooklyn",
                            "latitude": "41.73361000",
                            "longitude": "-92.44546000"
                        },
                        {
                            "id": 112975,
                            "name": "Buchanan County",
                            "latitude": "42.47078000",
                            "longitude": "-91.83784000"
                        },
                        {
                            "id": 113005,
                            "name": "Buena Vista County",
                            "latitude": "42.73549000",
                            "longitude": "-95.15115000"
                        },
                        {
                            "id": 113012,
                            "name": "Buffalo",
                            "latitude": "41.45642000",
                            "longitude": "-90.72347000"
                        },
                        {
                            "id": 113017,
                            "name": "Buffalo (historical)",
                            "latitude": "41.31110000",
                            "longitude": "-94.00356000"
                        },
                        {
                            "id": 113069,
                            "name": "Burlington",
                            "latitude": "40.80754000",
                            "longitude": "-91.11292000"
                        },
                        {
                            "id": 113118,
                            "name": "Butler County",
                            "latitude": "42.73157000",
                            "longitude": "-92.79019000"
                        },
                        {
                            "id": 113209,
                            "name": "Calhoun County",
                            "latitude": "42.38518000",
                            "longitude": "-94.64041000"
                        },
                        {
                            "id": 113239,
                            "name": "Camanche",
                            "latitude": "41.78809000",
                            "longitude": "-90.25624000"
                        },
                        {
                            "id": 113403,
                            "name": "Carlisle",
                            "latitude": "41.50082000",
                            "longitude": "-93.49105000"
                        },
                        {
                            "id": 113449,
                            "name": "Carroll",
                            "latitude": "42.06582000",
                            "longitude": "-94.86693000"
                        },
                        {
                            "id": 113458,
                            "name": "Carroll County",
                            "latitude": "42.03624000",
                            "longitude": "-94.86056000"
                        },
                        {
                            "id": 113485,
                            "name": "Carter Lake",
                            "latitude": "41.29055000",
                            "longitude": "-95.91807000"
                        },
                        {
                            "id": 113513,
                            "name": "Cascade",
                            "latitude": "42.29862000",
                            "longitude": "-91.01486000"
                        },
                        {
                            "id": 113529,
                            "name": "Cass County",
                            "latitude": "41.33149000",
                            "longitude": "-94.92783000"
                        },
                        {
                            "id": 113605,
                            "name": "Cedar County",
                            "latitude": "41.77232000",
                            "longitude": "-91.13241000"
                        },
                        {
                            "id": 113607,
                            "name": "Cedar Falls",
                            "latitude": "42.52776000",
                            "longitude": "-92.44547000"
                        },
                        {
                            "id": 113621,
                            "name": "Cedar Rapids",
                            "latitude": "42.00833000",
                            "longitude": "-91.64407000"
                        },
                        {
                            "id": 113647,
                            "name": "Center Point",
                            "latitude": "42.19083000",
                            "longitude": "-91.78518000"
                        },
                        {
                            "id": 113658,
                            "name": "Centerville",
                            "latitude": "40.73418000",
                            "longitude": "-92.87409000"
                        },
                        {
                            "id": 113668,
                            "name": "Central City",
                            "latitude": "42.20388000",
                            "longitude": "-91.52406000"
                        },
                        {
                            "id": 113703,
                            "name": "Cerro Gordo County",
                            "latitude": "43.08156000",
                            "longitude": "-93.26082000"
                        },
                        {
                            "id": 113749,
                            "name": "Chariton",
                            "latitude": "41.01389000",
                            "longitude": "-93.30660000"
                        },
                        {
                            "id": 113754,
                            "name": "Charles City",
                            "latitude": "43.06636000",
                            "longitude": "-92.67241000"
                        },
                        {
                            "id": 113848,
                            "name": "Cherokee",
                            "latitude": "42.74943000",
                            "longitude": "-95.55167000"
                        },
                        {
                            "id": 113856,
                            "name": "Cherokee County",
                            "latitude": "42.73562000",
                            "longitude": "-95.62381000"
                        },
                        {
                            "id": 113940,
                            "name": "Chickasaw County",
                            "latitude": "43.06004000",
                            "longitude": "-92.31766000"
                        },
                        {
                            "id": 114115,
                            "name": "Clarinda",
                            "latitude": "40.73981000",
                            "longitude": "-95.03800000"
                        },
                        {
                            "id": 114116,
                            "name": "Clarion",
                            "latitude": "42.73164000",
                            "longitude": "-93.73299000"
                        },
                        {
                            "id": 114138,
                            "name": "Clarke County",
                            "latitude": "41.02903000",
                            "longitude": "-93.78516000"
                        },
                        {
                            "id": 114156,
                            "name": "Clarksville",
                            "latitude": "42.78470000",
                            "longitude": "-92.66769000"
                        },
                        {
                            "id": 114187,
                            "name": "Clay County",
                            "latitude": "43.08258000",
                            "longitude": "-95.15092000"
                        },
                        {
                            "id": 114206,
                            "name": "Clayton County",
                            "latitude": "42.84475000",
                            "longitude": "-91.34143000"
                        },
                        {
                            "id": 114209,
                            "name": "Clear Lake",
                            "latitude": "43.13802000",
                            "longitude": "-93.37937000"
                        },
                        {
                            "id": 114278,
                            "name": "Clinton",
                            "latitude": "41.84447000",
                            "longitude": "-90.18874000"
                        },
                        {
                            "id": 114292,
                            "name": "Clinton County",
                            "latitude": "41.89804000",
                            "longitude": "-90.53197000"
                        },
                        {
                            "id": 114303,
                            "name": "Clive",
                            "latitude": "41.60304000",
                            "longitude": "-93.72411000"
                        },
                        {
                            "id": 114405,
                            "name": "Colfax",
                            "latitude": "41.67777000",
                            "longitude": "-93.24520000"
                        },
                        {
                            "id": 114503,
                            "name": "Columbus Junction",
                            "latitude": "41.28003000",
                            "longitude": "-91.36071000"
                        },
                        {
                            "id": 114568,
                            "name": "Conrad",
                            "latitude": "42.22471000",
                            "longitude": "-92.87465000"
                        },
                        {
                            "id": 114599,
                            "name": "Coon Rapids",
                            "latitude": "41.87082000",
                            "longitude": "-94.67748000"
                        },
                        {
                            "id": 114628,
                            "name": "Coralville",
                            "latitude": "41.67640000",
                            "longitude": "-91.58045000"
                        },
                        {
                            "id": 114652,
                            "name": "Corning",
                            "latitude": "40.98999000",
                            "longitude": "-94.74081000"
                        },
                        {
                            "id": 114682,
                            "name": "Corydon",
                            "latitude": "40.75695000",
                            "longitude": "-93.31882000"
                        },
                        {
                            "id": 114712,
                            "name": "Council Bluffs",
                            "latitude": "41.26194000",
                            "longitude": "-95.86083000"
                        },
                        {
                            "id": 114780,
                            "name": "Crawford County",
                            "latitude": "42.03721000",
                            "longitude": "-95.38197000"
                        },
                        {
                            "id": 114798,
                            "name": "Cresco",
                            "latitude": "43.38136000",
                            "longitude": "-92.11405000"
                        },
                        {
                            "id": 114806,
                            "name": "Creston",
                            "latitude": "41.05860000",
                            "longitude": "-94.36135000"
                        },
                        {
                            "id": 114974,
                            "name": "Dakota City",
                            "latitude": "42.72219000",
                            "longitude": "-94.19718000"
                        },
                        {
                            "id": 114993,
                            "name": "Dallas Center",
                            "latitude": "41.68443000",
                            "longitude": "-93.96106000"
                        },
                        {
                            "id": 114998,
                            "name": "Dallas County",
                            "latitude": "41.68489000",
                            "longitude": "-94.03974000"
                        },
                        {
                            "id": 115059,
                            "name": "Davenport",
                            "latitude": "41.52364000",
                            "longitude": "-90.57764000"
                        },
                        {
                            "id": 115074,
                            "name": "Davis County",
                            "latitude": "40.74769000",
                            "longitude": "-92.40972000"
                        },
                        {
                            "id": 115118,
                            "name": "De Soto",
                            "latitude": "41.53166000",
                            "longitude": "-94.00967000"
                        },
                        {
                            "id": 115122,
                            "name": "De Witt",
                            "latitude": "41.82336000",
                            "longitude": "-90.53819000"
                        },
                        {
                            "id": 115162,
                            "name": "Decatur County",
                            "latitude": "40.73768000",
                            "longitude": "-93.78628000"
                        },
                        {
                            "id": 115166,
                            "name": "Decorah",
                            "latitude": "43.30331000",
                            "longitude": "-91.78571000"
                        },
                        {
                            "id": 115205,
                            "name": "Delaware County",
                            "latitude": "42.47121000",
                            "longitude": "-91.36735000"
                        },
                        {
                            "id": 115238,
                            "name": "Denison",
                            "latitude": "42.01777000",
                            "longitude": "-95.35528000"
                        },
                        {
                            "id": 115251,
                            "name": "Denver",
                            "latitude": "42.67137000",
                            "longitude": "-92.33740000"
                        },
                        {
                            "id": 115271,
                            "name": "Des Moines",
                            "latitude": "41.60054000",
                            "longitude": "-93.60911000"
                        },
                        {
                            "id": 115273,
                            "name": "Des Moines County",
                            "latitude": "40.92318000",
                            "longitude": "-91.18147000"
                        },
                        {
                            "id": 115328,
                            "name": "Dickinson County",
                            "latitude": "43.37798000",
                            "longitude": "-95.15083000"
                        },
                        {
                            "id": 115337,
                            "name": "Dike",
                            "latitude": "42.46415000",
                            "longitude": "-92.62825000"
                        },
                        {
                            "id": 115500,
                            "name": "Dubuque",
                            "latitude": "42.50056000",
                            "longitude": "-90.66457000"
                        },
                        {
                            "id": 115501,
                            "name": "Dubuque County",
                            "latitude": "42.46883000",
                            "longitude": "-90.88246000"
                        },
                        {
                            "id": 115568,
                            "name": "Durant",
                            "latitude": "41.59975000",
                            "longitude": "-90.91070000"
                        },
                        {
                            "id": 115589,
                            "name": "Dyersville",
                            "latitude": "42.48444000",
                            "longitude": "-91.12291000"
                        },
                        {
                            "id": 115591,
                            "name": "Dysart",
                            "latitude": "42.17166000",
                            "longitude": "-92.30630000"
                        },
                        {
                            "id": 115601,
                            "name": "Eagle Grove",
                            "latitude": "42.66414000",
                            "longitude": "-93.90439000"
                        },
                        {
                            "id": 115617,
                            "name": "Earlham",
                            "latitude": "41.49193000",
                            "longitude": "-94.12412000"
                        },
                        {
                            "id": 115827,
                            "name": "Eddyville",
                            "latitude": "41.15650000",
                            "longitude": "-92.63739000"
                        },
                        {
                            "id": 115951,
                            "name": "Eldora",
                            "latitude": "42.36082000",
                            "longitude": "-93.09965000"
                        },
                        {
                            "id": 115956,
                            "name": "Eldridge",
                            "latitude": "41.65809000",
                            "longitude": "-90.58458000"
                        },
                        {
                            "id": 115994,
                            "name": "Elk Run Heights",
                            "latitude": "42.46693000",
                            "longitude": "-92.25657000"
                        },
                        {
                            "id": 115995,
                            "name": "Elkader",
                            "latitude": "42.85387000",
                            "longitude": "-91.40542000"
                        },
                        {
                            "id": 116084,
                            "name": "Ely",
                            "latitude": "41.87362000",
                            "longitude": "-91.58518000"
                        },
                        {
                            "id": 116102,
                            "name": "Emmet County",
                            "latitude": "43.37802000",
                            "longitude": "-94.67848000"
                        },
                        {
                            "id": 116104,
                            "name": "Emmetsburg",
                            "latitude": "43.11274000",
                            "longitude": "-94.68304000"
                        },
                        {
                            "id": 116159,
                            "name": "Epworth",
                            "latitude": "42.44500000",
                            "longitude": "-90.93208000"
                        },
                        {
                            "id": 116210,
                            "name": "Estherville",
                            "latitude": "43.40163000",
                            "longitude": "-94.83276000"
                        },
                        {
                            "id": 116250,
                            "name": "Evansdale",
                            "latitude": "42.46915000",
                            "longitude": "-92.28102000"
                        },
                        {
                            "id": 116289,
                            "name": "Fairbank",
                            "latitude": "42.63915000",
                            "longitude": "-92.04712000"
                        },
                        {
                            "id": 116304,
                            "name": "Fairfax",
                            "latitude": "41.91945000",
                            "longitude": "-91.78101000"
                        },
                        {
                            "id": 116313,
                            "name": "Fairfield",
                            "latitude": "41.00863000",
                            "longitude": "-91.96267000"
                        },
                        {
                            "id": 116399,
                            "name": "Farley",
                            "latitude": "42.44278000",
                            "longitude": "-91.00625000"
                        },
                        {
                            "id": 116441,
                            "name": "Fayette",
                            "latitude": "42.84193000",
                            "longitude": "-91.80211000"
                        },
                        {
                            "id": 116451,
                            "name": "Fayette County",
                            "latitude": "42.86259000",
                            "longitude": "-91.84432000"
                        },
                        {
                            "id": 116610,
                            "name": "Floyd County",
                            "latitude": "43.05992000",
                            "longitude": "-92.78900000"
                        },
                        {
                            "id": 116648,
                            "name": "Forest City",
                            "latitude": "43.26246000",
                            "longitude": "-93.63716000"
                        },
                        {
                            "id": 116707,
                            "name": "Fort Dodge",
                            "latitude": "42.49747000",
                            "longitude": "-94.16802000"
                        },
                        {
                            "id": 116727,
                            "name": "Fort Madison",
                            "latitude": "40.62976000",
                            "longitude": "-91.31515000"
                        },
                        {
                            "id": 116857,
                            "name": "Franklin County",
                            "latitude": "42.73255000",
                            "longitude": "-93.26247000"
                        },
                        {
                            "id": 116925,
                            "name": "Fremont County",
                            "latitude": "40.74559000",
                            "longitude": "-95.60468000"
                        },
                        {
                            "id": 117093,
                            "name": "Garner",
                            "latitude": "43.10246000",
                            "longitude": "-93.60188000"
                        },
                        {
                            "id": 117152,
                            "name": "George",
                            "latitude": "43.34386000",
                            "longitude": "-96.00224000"
                        },
                        {
                            "id": 117202,
                            "name": "Gilbert",
                            "latitude": "42.10693000",
                            "longitude": "-93.64966000"
                        },
                        {
                            "id": 117313,
                            "name": "Glenwood",
                            "latitude": "41.04694000",
                            "longitude": "-95.74251000"
                        },
                        {
                            "id": 117319,
                            "name": "Glidden",
                            "latitude": "42.05693000",
                            "longitude": "-94.72887000"
                        },
                        {
                            "id": 117487,
                            "name": "Granger",
                            "latitude": "41.76110000",
                            "longitude": "-93.82439000"
                        },
                        {
                            "id": 117628,
                            "name": "Greene",
                            "latitude": "42.89581000",
                            "longitude": "-92.80242000"
                        },
                        {
                            "id": 117641,
                            "name": "Greene County",
                            "latitude": "42.03624000",
                            "longitude": "-94.39684000"
                        },
                        {
                            "id": 117649,
                            "name": "Greenfield",
                            "latitude": "41.30527000",
                            "longitude": "-94.46135000"
                        },
                        {
                            "id": 117737,
                            "name": "Grimes",
                            "latitude": "41.68832000",
                            "longitude": "-93.79106000"
                        },
                        {
                            "id": 117740,
                            "name": "Grinnell",
                            "latitude": "41.74305000",
                            "longitude": "-92.72241000"
                        },
                        {
                            "id": 117769,
                            "name": "Grundy Center",
                            "latitude": "42.36165000",
                            "longitude": "-92.76853000"
                        },
                        {
                            "id": 117771,
                            "name": "Grundy County",
                            "latitude": "42.40187000",
                            "longitude": "-92.79142000"
                        },
                        {
                            "id": 117814,
                            "name": "Guthrie Center",
                            "latitude": "41.67721000",
                            "longitude": "-94.50330000"
                        },
                        {
                            "id": 117815,
                            "name": "Guthrie County",
                            "latitude": "41.68375000",
                            "longitude": "-94.50105000"
                        },
                        {
                            "id": 117816,
                            "name": "Guttenberg",
                            "latitude": "42.78582000",
                            "longitude": "-91.09957000"
                        },
                        {
                            "id": 117882,
                            "name": "Hamburg",
                            "latitude": "40.60445000",
                            "longitude": "-95.65777000"
                        },
                        {
                            "id": 117900,
                            "name": "Hamilton County",
                            "latitude": "42.38377000",
                            "longitude": "-93.70681000"
                        },
                        {
                            "id": 117930,
                            "name": "Hampton",
                            "latitude": "42.74192000",
                            "longitude": "-93.20242000"
                        },
                        {
                            "id": 117955,
                            "name": "Hancock County",
                            "latitude": "43.08189000",
                            "longitude": "-93.73427000"
                        },
                        {
                            "id": 118003,
                            "name": "Hardin County",
                            "latitude": "42.38388000",
                            "longitude": "-93.24040000"
                        },
                        {
                            "id": 118016,
                            "name": "Harlan",
                            "latitude": "41.65304000",
                            "longitude": "-95.32555000"
                        },
                        {
                            "id": 118067,
                            "name": "Harrison County",
                            "latitude": "41.68285000",
                            "longitude": "-95.81692000"
                        },
                        {
                            "id": 118098,
                            "name": "Hartley",
                            "latitude": "43.17997000",
                            "longitude": "-95.47695000"
                        },
                        {
                            "id": 118163,
                            "name": "Hawarden",
                            "latitude": "42.99582000",
                            "longitude": "-96.48531000"
                        },
                        {
                            "id": 118304,
                            "name": "Henry County",
                            "latitude": "40.98794000",
                            "longitude": "-91.54452000"
                        },
                        {
                            "id": 118358,
                            "name": "Hiawatha",
                            "latitude": "42.03583000",
                            "longitude": "-91.68212000"
                        },
                        {
                            "id": 118573,
                            "name": "Holstein",
                            "latitude": "42.48915000",
                            "longitude": "-95.54500000"
                        },
                        {
                            "id": 118715,
                            "name": "Howard County",
                            "latitude": "43.35677000",
                            "longitude": "-92.31720000"
                        },
                        {
                            "id": 118738,
                            "name": "Hudson",
                            "latitude": "42.40665000",
                            "longitude": "-92.45547000"
                        },
                        {
                            "id": 118771,
                            "name": "Hull",
                            "latitude": "43.18859000",
                            "longitude": "-96.13363000"
                        },
                        {
                            "id": 118777,
                            "name": "Humboldt",
                            "latitude": "42.72080000",
                            "longitude": "-94.21524000"
                        },
                        {
                            "id": 118778,
                            "name": "Humboldt County",
                            "latitude": "42.77647000",
                            "longitude": "-94.20719000"
                        },
                        {
                            "id": 118841,
                            "name": "Huxley",
                            "latitude": "41.89527000",
                            "longitude": "-93.60077000"
                        },
                        {
                            "id": 118866,
                            "name": "Ida County",
                            "latitude": "42.38687000",
                            "longitude": "-95.51350000"
                        },
                        {
                            "id": 118867,
                            "name": "Ida Grove",
                            "latitude": "42.34499000",
                            "longitude": "-95.47167000"
                        },
                        {
                            "id": 118893,
                            "name": "Independence",
                            "latitude": "42.46860000",
                            "longitude": "-91.88934000"
                        },
                        {
                            "id": 118926,
                            "name": "Indianola",
                            "latitude": "41.35805000",
                            "longitude": "-93.55744000"
                        },
                        {
                            "id": 118976,
                            "name": "Iowa City",
                            "latitude": "41.66113000",
                            "longitude": "-91.53017000"
                        },
                        {
                            "id": 118978,
                            "name": "Iowa County",
                            "latitude": "41.68632000",
                            "longitude": "-92.06552000"
                        },
                        {
                            "id": 118980,
                            "name": "Iowa Falls",
                            "latitude": "42.52248000",
                            "longitude": "-93.25131000"
                        },
                        {
                            "id": 119090,
                            "name": "Jackson County",
                            "latitude": "42.17174000",
                            "longitude": "-90.57423000"
                        },
                        {
                            "id": 119151,
                            "name": "Jasper County",
                            "latitude": "41.68604000",
                            "longitude": "-93.05376000"
                        },
                        {
                            "id": 119168,
                            "name": "Jefferson",
                            "latitude": "42.01526000",
                            "longitude": "-94.37747000"
                        },
                        {
                            "id": 119189,
                            "name": "Jefferson County",
                            "latitude": "41.03176000",
                            "longitude": "-91.94888000"
                        },
                        {
                            "id": 119240,
                            "name": "Jesup",
                            "latitude": "42.47554000",
                            "longitude": "-92.06379000"
                        },
                        {
                            "id": 119242,
                            "name": "Jewell",
                            "latitude": "42.30693000",
                            "longitude": "-93.64022000"
                        },
                        {
                            "id": 119269,
                            "name": "Johnson County",
                            "latitude": "41.67155000",
                            "longitude": "-91.58808000"
                        },
                        {
                            "id": 119278,
                            "name": "Johnston",
                            "latitude": "41.67304000",
                            "longitude": "-93.69772000"
                        },
                        {
                            "id": 119296,
                            "name": "Jones County",
                            "latitude": "42.12124000",
                            "longitude": "-91.13144000"
                        },
                        {
                            "id": 119368,
                            "name": "Kalona",
                            "latitude": "41.48307000",
                            "longitude": "-91.70600000"
                        },
                        {
                            "id": 119507,
                            "name": "Keokuk",
                            "latitude": "40.39727000",
                            "longitude": "-91.38487000"
                        },
                        {
                            "id": 119508,
                            "name": "Keokuk County",
                            "latitude": "41.33646000",
                            "longitude": "-92.17864000"
                        },
                        {
                            "id": 119509,
                            "name": "Keosauqua",
                            "latitude": "40.73031000",
                            "longitude": "-91.96239000"
                        },
                        {
                            "id": 119617,
                            "name": "Kingsley",
                            "latitude": "42.58832000",
                            "longitude": "-95.96752000"
                        },
                        {
                            "id": 119695,
                            "name": "Knoxville",
                            "latitude": "41.32083000",
                            "longitude": "-93.10937000"
                        },
                        {
                            "id": 119711,
                            "name": "Kossuth County",
                            "latitude": "43.20413000",
                            "longitude": "-94.20672000"
                        },
                        {
                            "id": 119778,
                            "name": "La Porte City",
                            "latitude": "42.31499000",
                            "longitude": "-92.19213000"
                        },
                        {
                            "id": 119882,
                            "name": "Lake City",
                            "latitude": "42.26748000",
                            "longitude": "-94.73387000"
                        },
                        {
                            "id": 119942,
                            "name": "Lake Mills",
                            "latitude": "43.41940000",
                            "longitude": "-93.53327000"
                        },
                        {
                            "id": 119958,
                            "name": "Lake Panorama",
                            "latitude": "41.71105000",
                            "longitude": "-94.39059000"
                        },
                        {
                            "id": 119961,
                            "name": "Lake Park",
                            "latitude": "43.45552000",
                            "longitude": "-95.32083000"
                        },
                        {
                            "id": 119987,
                            "name": "Lake View",
                            "latitude": "42.31165000",
                            "longitude": "-95.05332000"
                        },
                        {
                            "id": 120073,
                            "name": "Lamoni",
                            "latitude": "40.62278000",
                            "longitude": "-93.93412000"
                        },
                        {
                            "id": 120207,
                            "name": "Laurens",
                            "latitude": "42.84664000",
                            "longitude": "-94.85193000"
                        },
                        {
                            "id": 120258,
                            "name": "Le Claire",
                            "latitude": "41.59864000",
                            "longitude": "-90.34346000"
                        },
                        {
                            "id": 120261,
                            "name": "Le Mars",
                            "latitude": "42.79416000",
                            "longitude": "-96.16558000"
                        },
                        {
                            "id": 120316,
                            "name": "Lee County",
                            "latitude": "40.64198000",
                            "longitude": "-91.47926000"
                        },
                        {
                            "id": 120375,
                            "name": "Lenox",
                            "latitude": "40.88165000",
                            "longitude": "-94.56191000"
                        },
                        {
                            "id": 120383,
                            "name": "Leon",
                            "latitude": "40.73972000",
                            "longitude": "-93.74772000"
                        },
                        {
                            "id": 120583,
                            "name": "Linn County",
                            "latitude": "42.07895000",
                            "longitude": "-91.59896000"
                        },
                        {
                            "id": 120598,
                            "name": "Lisbon",
                            "latitude": "41.92112000",
                            "longitude": "-91.38545000"
                        },
                        {
                            "id": 120694,
                            "name": "Logan",
                            "latitude": "41.64305000",
                            "longitude": "-95.78890000"
                        },
                        {
                            "id": 120731,
                            "name": "Lone Tree",
                            "latitude": "41.48808000",
                            "longitude": "-91.42599000"
                        },
                        {
                            "id": 120807,
                            "name": "Louisa County",
                            "latitude": "41.21851000",
                            "longitude": "-91.25962000"
                        },
                        {
                            "id": 120861,
                            "name": "Lucas County",
                            "latitude": "41.02937000",
                            "longitude": "-93.32772000"
                        },
                        {
                            "id": 120937,
                            "name": "Lyon County",
                            "latitude": "43.38050000",
                            "longitude": "-96.21029000"
                        },
                        {
                            "id": 121014,
                            "name": "Madison County",
                            "latitude": "41.33071000",
                            "longitude": "-94.01556000"
                        },
                        {
                            "id": 121029,
                            "name": "Madrid",
                            "latitude": "41.87665000",
                            "longitude": "-93.82328000"
                        },
                        {
                            "id": 121041,
                            "name": "Maharishi Vedic City",
                            "latitude": "41.05252000",
                            "longitude": "-91.99490000"
                        },
                        {
                            "id": 121042,
                            "name": "Mahaska County",
                            "latitude": "41.33520000",
                            "longitude": "-92.64091000"
                        },
                        {
                            "id": 121072,
                            "name": "Malvern",
                            "latitude": "41.00278000",
                            "longitude": "-95.58528000"
                        },
                        {
                            "id": 121095,
                            "name": "Manchester",
                            "latitude": "42.48415000",
                            "longitude": "-91.45543000"
                        },
                        {
                            "id": 121132,
                            "name": "Manly",
                            "latitude": "43.28718000",
                            "longitude": "-93.20215000"
                        },
                        {
                            "id": 121135,
                            "name": "Manning",
                            "latitude": "41.90915000",
                            "longitude": "-95.06499000"
                        },
                        {
                            "id": 121150,
                            "name": "Manson",
                            "latitude": "42.52914000",
                            "longitude": "-94.53414000"
                        },
                        {
                            "id": 121174,
                            "name": "Mapleton",
                            "latitude": "42.16582000",
                            "longitude": "-95.79306000"
                        },
                        {
                            "id": 121181,
                            "name": "Maquoketa",
                            "latitude": "42.06891000",
                            "longitude": "-90.66569000"
                        },
                        {
                            "id": 121199,
                            "name": "Marcus",
                            "latitude": "42.82582000",
                            "longitude": "-95.80751000"
                        },
                        {
                            "id": 121201,
                            "name": "Marengo",
                            "latitude": "41.79806000",
                            "longitude": "-92.07074000"
                        },
                        {
                            "id": 121240,
                            "name": "Marion",
                            "latitude": "42.03417000",
                            "longitude": "-91.59768000"
                        },
                        {
                            "id": 121261,
                            "name": "Marion County",
                            "latitude": "41.33445000",
                            "longitude": "-93.09944000"
                        },
                        {
                            "id": 121317,
                            "name": "Marshall County",
                            "latitude": "42.03585000",
                            "longitude": "-92.99877000"
                        },
                        {
                            "id": 121323,
                            "name": "Marshalltown",
                            "latitude": "42.04943000",
                            "longitude": "-92.90798000"
                        },
                        {
                            "id": 121375,
                            "name": "Mason City",
                            "latitude": "43.15357000",
                            "longitude": "-93.20104000"
                        },
                        {
                            "id": 121584,
                            "name": "Mechanicsville",
                            "latitude": "41.90446000",
                            "longitude": "-91.25461000"
                        },
                        {
                            "id": 121599,
                            "name": "Mediapolis",
                            "latitude": "41.00809000",
                            "longitude": "-91.16404000"
                        },
                        {
                            "id": 121623,
                            "name": "Melcher-Dallas",
                            "latitude": "41.22500000",
                            "longitude": "-93.24132000"
                        },
                        {
                            "id": 121848,
                            "name": "Milford",
                            "latitude": "43.32469000",
                            "longitude": "-95.15000000"
                        },
                        {
                            "id": 121898,
                            "name": "Mills County",
                            "latitude": "41.03345000",
                            "longitude": "-95.62133000"
                        },
                        {
                            "id": 121994,
                            "name": "Missouri Valley",
                            "latitude": "41.55638000",
                            "longitude": "-95.88779000"
                        },
                        {
                            "id": 122002,
                            "name": "Mitchell County",
                            "latitude": "43.35641000",
                            "longitude": "-92.78903000"
                        },
                        {
                            "id": 122005,
                            "name": "Mitchellville",
                            "latitude": "41.66860000",
                            "longitude": "-93.35771000"
                        },
                        {
                            "id": 122051,
                            "name": "Monona",
                            "latitude": "43.05165000",
                            "longitude": "-91.38930000"
                        },
                        {
                            "id": 122053,
                            "name": "Monona County",
                            "latitude": "42.05167000",
                            "longitude": "-95.95992000"
                        },
                        {
                            "id": 122060,
                            "name": "Monroe",
                            "latitude": "41.52221000",
                            "longitude": "-93.10187000"
                        },
                        {
                            "id": 122078,
                            "name": "Monroe County",
                            "latitude": "41.02978000",
                            "longitude": "-92.86899000"
                        },
                        {
                            "id": 122128,
                            "name": "Montezuma",
                            "latitude": "41.58583000",
                            "longitude": "-92.52741000"
                        },
                        {
                            "id": 122152,
                            "name": "Montgomery County",
                            "latitude": "41.03014000",
                            "longitude": "-95.15638000"
                        },
                        {
                            "id": 122164,
                            "name": "Monticello",
                            "latitude": "42.23834000",
                            "longitude": "-91.18709000"
                        },
                        {
                            "id": 122334,
                            "name": "Mount Ayr",
                            "latitude": "40.71471000",
                            "longitude": "-94.23523000"
                        },
                        {
                            "id": 122378,
                            "name": "Mount Pleasant",
                            "latitude": "40.96364000",
                            "longitude": "-91.55794000"
                        },
                        {
                            "id": 122400,
                            "name": "Mount Vernon",
                            "latitude": "41.92195000",
                            "longitude": "-91.41684000"
                        },
                        {
                            "id": 122442,
                            "name": "Moville",
                            "latitude": "42.48888000",
                            "longitude": "-96.07252000"
                        },
                        {
                            "id": 122501,
                            "name": "Muscatine",
                            "latitude": "41.42447000",
                            "longitude": "-91.04321000"
                        },
                        {
                            "id": 122502,
                            "name": "Muscatine County",
                            "latitude": "41.48392000",
                            "longitude": "-91.11276000"
                        },
                        {
                            "id": 122570,
                            "name": "Nashua",
                            "latitude": "42.95275000",
                            "longitude": "-92.53630000"
                        },
                        {
                            "id": 122650,
                            "name": "Nevada",
                            "latitude": "42.02277000",
                            "longitude": "-93.45243000"
                        },
                        {
                            "id": 122710,
                            "name": "New Hampton",
                            "latitude": "43.05914000",
                            "longitude": "-92.31768000"
                        },
                        {
                            "id": 122742,
                            "name": "New London",
                            "latitude": "40.92698000",
                            "longitude": "-91.39960000"
                        },
                        {
                            "id": 122776,
                            "name": "New Sharon",
                            "latitude": "41.47000000",
                            "longitude": "-92.65130000"
                        },
                        {
                            "id": 122863,
                            "name": "Newton",
                            "latitude": "41.69971000",
                            "longitude": "-93.04798000"
                        },
                        {
                            "id": 122937,
                            "name": "Nora Springs",
                            "latitude": "43.14275000",
                            "longitude": "-93.00437000"
                        },
                        {
                            "id": 123023,
                            "name": "North English",
                            "latitude": "41.51390000",
                            "longitude": "-92.07629000"
                        },
                        {
                            "id": 123056,
                            "name": "North Liberty",
                            "latitude": "41.74918000",
                            "longitude": "-91.59795000"
                        },
                        {
                            "id": 123179,
                            "name": "Northwood",
                            "latitude": "43.44412000",
                            "longitude": "-93.22104000"
                        },
                        {
                            "id": 123189,
                            "name": "Norwalk",
                            "latitude": "41.47555000",
                            "longitude": "-93.67883000"
                        },
                        {
                            "id": 123225,
                            "name": "O'Brien County",
                            "latitude": "43.08375000",
                            "longitude": "-95.62488000"
                        },
                        {
                            "id": 123288,
                            "name": "Oakland",
                            "latitude": "41.30916000",
                            "longitude": "-95.39667000"
                        },
                        {
                            "id": 123363,
                            "name": "Oelwein",
                            "latitude": "42.67332000",
                            "longitude": "-91.91350000"
                        },
                        {
                            "id": 123368,
                            "name": "Ogden",
                            "latitude": "42.03915000",
                            "longitude": "-94.02773000"
                        },
                        {
                            "id": 123471,
                            "name": "Onawa",
                            "latitude": "42.02665000",
                            "longitude": "-96.09724000"
                        },
                        {
                            "id": 123513,
                            "name": "Orange City",
                            "latitude": "43.00721000",
                            "longitude": "-96.05835000"
                        },
                        {
                            "id": 123586,
                            "name": "Osage",
                            "latitude": "43.28414000",
                            "longitude": "-92.81103000"
                        },
                        {
                            "id": 123599,
                            "name": "Osceola",
                            "latitude": "41.03389000",
                            "longitude": "-93.76550000"
                        },
                        {
                            "id": 123604,
                            "name": "Osceola County",
                            "latitude": "43.37857000",
                            "longitude": "-95.62369000"
                        },
                        {
                            "id": 123612,
                            "name": "Oskaloosa",
                            "latitude": "41.29639000",
                            "longitude": "-92.64436000"
                        },
                        {
                            "id": 123643,
                            "name": "Ottumwa",
                            "latitude": "41.02001000",
                            "longitude": "-92.41130000"
                        },
                        {
                            "id": 123719,
                            "name": "Page County",
                            "latitude": "40.73914000",
                            "longitude": "-95.15017000"
                        },
                        {
                            "id": 123779,
                            "name": "Palo",
                            "latitude": "42.06611000",
                            "longitude": "-91.79546000"
                        },
                        {
                            "id": 123782,
                            "name": "Palo Alto County",
                            "latitude": "43.08206000",
                            "longitude": "-94.67814000"
                        },
                        {
                            "id": 123805,
                            "name": "Panora",
                            "latitude": "41.69165000",
                            "longitude": "-94.36302000"
                        },
                        {
                            "id": 123855,
                            "name": "Park View",
                            "latitude": "41.69420000",
                            "longitude": "-90.54569000"
                        },
                        {
                            "id": 123867,
                            "name": "Parkersburg",
                            "latitude": "42.57748000",
                            "longitude": "-92.78686000"
                        },
                        {
                            "id": 123924,
                            "name": "Paullina",
                            "latitude": "42.97915000",
                            "longitude": "-95.68807000"
                        },
                        {
                            "id": 123999,
                            "name": "Pella",
                            "latitude": "41.40805000",
                            "longitude": "-92.91631000"
                        },
                        {
                            "id": 124050,
                            "name": "Peosta",
                            "latitude": "42.45056000",
                            "longitude": "-90.85041000"
                        },
                        {
                            "id": 124070,
                            "name": "Perry",
                            "latitude": "41.83860000",
                            "longitude": "-94.10718000"
                        },
                        {
                            "id": 124370,
                            "name": "Pleasant Hill",
                            "latitude": "41.58388000",
                            "longitude": "-93.51994000"
                        },
                        {
                            "id": 124387,
                            "name": "Pleasantville",
                            "latitude": "41.38583000",
                            "longitude": "-93.26937000"
                        },
                        {
                            "id": 124409,
                            "name": "Plymouth County",
                            "latitude": "42.73783000",
                            "longitude": "-96.21404000"
                        },
                        {
                            "id": 124414,
                            "name": "Pocahontas",
                            "latitude": "42.73553000",
                            "longitude": "-94.66915000"
                        },
                        {
                            "id": 124416,
                            "name": "Pocahontas County",
                            "latitude": "42.73414000",
                            "longitude": "-94.67875000"
                        },
                        {
                            "id": 124439,
                            "name": "Polk City",
                            "latitude": "41.77138000",
                            "longitude": "-93.71300000"
                        },
                        {
                            "id": 124447,
                            "name": "Polk County",
                            "latitude": "41.68550000",
                            "longitude": "-93.57353000"
                        },
                        {
                            "id": 124578,
                            "name": "Postville",
                            "latitude": "43.08470000",
                            "longitude": "-91.56820000"
                        },
                        {
                            "id": 124591,
                            "name": "Pottawattamie County",
                            "latitude": "41.33661000",
                            "longitude": "-95.54239000"
                        },
                        {
                            "id": 124617,
                            "name": "Poweshiek County",
                            "latitude": "41.68644000",
                            "longitude": "-92.53147000"
                        },
                        {
                            "id": 124623,
                            "name": "Prairie City",
                            "latitude": "41.59943000",
                            "longitude": "-93.23521000"
                        },
                        {
                            "id": 124655,
                            "name": "Preston",
                            "latitude": "42.05030000",
                            "longitude": "-90.41402000"
                        },
                        {
                            "id": 124671,
                            "name": "Primghar",
                            "latitude": "43.08692000",
                            "longitude": "-95.62723000"
                        },
                        {
                            "id": 124957,
                            "name": "Red Oak",
                            "latitude": "41.00972000",
                            "longitude": "-95.22555000"
                        },
                        {
                            "id": 125010,
                            "name": "Reinbeck",
                            "latitude": "42.32360000",
                            "longitude": "-92.59936000"
                        },
                        {
                            "id": 125015,
                            "name": "Remsen",
                            "latitude": "42.81471000",
                            "longitude": "-95.97335000"
                        },
                        {
                            "id": 125143,
                            "name": "Ringgold County",
                            "latitude": "40.73520000",
                            "longitude": "-94.24397000"
                        },
                        {
                            "id": 125205,
                            "name": "Riverside",
                            "latitude": "41.47974000",
                            "longitude": "-91.58128000"
                        },
                        {
                            "id": 125251,
                            "name": "Robins",
                            "latitude": "42.07111000",
                            "longitude": "-91.66684000"
                        },
                        {
                            "id": 125286,
                            "name": "Rock Rapids",
                            "latitude": "43.42719000",
                            "longitude": "-96.17586000"
                        },
                        {
                            "id": 125288,
                            "name": "Rock Valley",
                            "latitude": "43.20526000",
                            "longitude": "-96.29503000"
                        },
                        {
                            "id": 125330,
                            "name": "Rockwell",
                            "latitude": "42.98524000",
                            "longitude": "-93.19187000"
                        },
                        {
                            "id": 125331,
                            "name": "Rockwell City",
                            "latitude": "42.39526000",
                            "longitude": "-94.63387000"
                        },
                        {
                            "id": 125360,
                            "name": "Roland",
                            "latitude": "42.16637000",
                            "longitude": "-93.50188000"
                        },
                        {
                            "id": 125571,
                            "name": "Sac City",
                            "latitude": "42.42220000",
                            "longitude": "-94.98971000"
                        },
                        {
                            "id": 125572,
                            "name": "Sac County",
                            "latitude": "42.38626000",
                            "longitude": "-95.10539000"
                        },
                        {
                            "id": 125600,
                            "name": "Saint Ansgar",
                            "latitude": "43.37830000",
                            "longitude": "-92.91881000"
                        },
                        {
                            "id": 125853,
                            "name": "Sanborn",
                            "latitude": "43.18164000",
                            "longitude": "-95.65557000"
                        },
                        {
                            "id": 125988,
                            "name": "Saylorville",
                            "latitude": "41.67860000",
                            "longitude": "-93.62966000"
                        },
                        {
                            "id": 126051,
                            "name": "Scott County",
                            "latitude": "41.63710000",
                            "longitude": "-90.62324000"
                        },
                        {
                            "id": 126167,
                            "name": "Sergeant Bluff",
                            "latitude": "42.40388000",
                            "longitude": "-96.35864000"
                        },
                        {
                            "id": 126260,
                            "name": "Sheffield",
                            "latitude": "42.89330000",
                            "longitude": "-93.21520000"
                        },
                        {
                            "id": 126279,
                            "name": "Shelby County",
                            "latitude": "41.68509000",
                            "longitude": "-95.31021000"
                        },
                        {
                            "id": 126286,
                            "name": "Sheldon",
                            "latitude": "43.18109000",
                            "longitude": "-95.85613000"
                        },
                        {
                            "id": 126292,
                            "name": "Shell Rock",
                            "latitude": "42.71026000",
                            "longitude": "-92.58297000"
                        },
                        {
                            "id": 126302,
                            "name": "Shenandoah",
                            "latitude": "40.76555000",
                            "longitude": "-95.37221000"
                        },
                        {
                            "id": 126391,
                            "name": "Sibley",
                            "latitude": "43.39914000",
                            "longitude": "-95.75196000"
                        },
                        {
                            "id": 126394,
                            "name": "Sidney",
                            "latitude": "40.74833000",
                            "longitude": "-95.64750000"
                        },
                        {
                            "id": 126413,
                            "name": "Sigourney",
                            "latitude": "41.33334000",
                            "longitude": "-92.20463000"
                        },
                        {
                            "id": 126457,
                            "name": "Sioux Center",
                            "latitude": "43.07971000",
                            "longitude": "-96.17558000"
                        },
                        {
                            "id": 126458,
                            "name": "Sioux City",
                            "latitude": "42.49999000",
                            "longitude": "-96.40031000"
                        },
                        {
                            "id": 126459,
                            "name": "Sioux County",
                            "latitude": "43.08262000",
                            "longitude": "-96.17788000"
                        },
                        {
                            "id": 126490,
                            "name": "Slater",
                            "latitude": "41.87776000",
                            "longitude": "-93.67855000"
                        },
                        {
                            "id": 126571,
                            "name": "Solon",
                            "latitude": "41.80723000",
                            "longitude": "-91.49406000"
                        },
                        {
                            "id": 126825,
                            "name": "Spencer",
                            "latitude": "43.14136000",
                            "longitude": "-95.14444000"
                        },
                        {
                            "id": 126838,
                            "name": "Spirit Lake",
                            "latitude": "43.42218000",
                            "longitude": "-95.10222000"
                        },
                        {
                            "id": 126915,
                            "name": "Springville",
                            "latitude": "42.05945000",
                            "longitude": "-91.44267000"
                        },
                        {
                            "id": 126991,
                            "name": "State Center",
                            "latitude": "42.01665000",
                            "longitude": "-93.16354000"
                        },
                        {
                            "id": 127103,
                            "name": "Storm Lake",
                            "latitude": "42.64109000",
                            "longitude": "-95.20972000"
                        },
                        {
                            "id": 127106,
                            "name": "Story City",
                            "latitude": "42.18721000",
                            "longitude": "-93.59577000"
                        },
                        {
                            "id": 127107,
                            "name": "Story County",
                            "latitude": "42.03624000",
                            "longitude": "-93.46504000"
                        },
                        {
                            "id": 127134,
                            "name": "Strawberry Point",
                            "latitude": "42.68360000",
                            "longitude": "-91.53403000"
                        },
                        {
                            "id": 127143,
                            "name": "Stuart",
                            "latitude": "41.50332000",
                            "longitude": "-94.31857000"
                        },
                        {
                            "id": 127222,
                            "name": "Sumner",
                            "latitude": "42.84748000",
                            "longitude": "-92.09156000"
                        },
                        {
                            "id": 127400,
                            "name": "Tama",
                            "latitude": "41.96666000",
                            "longitude": "-92.57686000"
                        },
                        {
                            "id": 127401,
                            "name": "Tama County",
                            "latitude": "42.07981000",
                            "longitude": "-92.53254000"
                        },
                        {
                            "id": 127456,
                            "name": "Taylor County",
                            "latitude": "40.73743000",
                            "longitude": "-94.69641000"
                        },
                        {
                            "id": 127640,
                            "name": "Tiffin",
                            "latitude": "41.70585000",
                            "longitude": "-91.66295000"
                        },
                        {
                            "id": 127675,
                            "name": "Tipton",
                            "latitude": "41.76974000",
                            "longitude": "-91.12793000"
                        },
                        {
                            "id": 127699,
                            "name": "Toledo",
                            "latitude": "41.99555000",
                            "longitude": "-92.57686000"
                        },
                        {
                            "id": 127774,
                            "name": "Traer",
                            "latitude": "42.19360000",
                            "longitude": "-92.46547000"
                        },
                        {
                            "id": 127831,
                            "name": "Tripoli",
                            "latitude": "42.80804000",
                            "longitude": "-92.25823000"
                        },
                        {
                            "id": 127998,
                            "name": "Union County",
                            "latitude": "41.02773000",
                            "longitude": "-94.24238000"
                        },
                        {
                            "id": 128029,
                            "name": "University Heights",
                            "latitude": "41.65502000",
                            "longitude": "-91.55684000"
                        },
                        {
                            "id": 128061,
                            "name": "Urbana",
                            "latitude": "42.22416000",
                            "longitude": "-91.87434000"
                        },
                        {
                            "id": 128063,
                            "name": "Urbandale",
                            "latitude": "41.62666000",
                            "longitude": "-93.71217000"
                        },
                        {
                            "id": 128131,
                            "name": "Van Buren County",
                            "latitude": "40.75323000",
                            "longitude": "-91.94999000"
                        },
                        {
                            "id": 128135,
                            "name": "Van Meter",
                            "latitude": "41.53193000",
                            "longitude": "-93.95412000"
                        },
                        {
                            "id": 128260,
                            "name": "Villisca",
                            "latitude": "40.92971000",
                            "longitude": "-94.97609000"
                        },
                        {
                            "id": 128280,
                            "name": "Vinton",
                            "latitude": "42.16861000",
                            "longitude": "-92.02351000"
                        },
                        {
                            "id": 128366,
                            "name": "Walcott",
                            "latitude": "41.58475000",
                            "longitude": "-90.77209000"
                        },
                        {
                            "id": 128381,
                            "name": "Walford",
                            "latitude": "41.87834000",
                            "longitude": "-91.83462000"
                        },
                        {
                            "id": 128454,
                            "name": "Wapello",
                            "latitude": "41.18142000",
                            "longitude": "-91.18543000"
                        },
                        {
                            "id": 128455,
                            "name": "Wapello County",
                            "latitude": "41.03058000",
                            "longitude": "-92.40945000"
                        },
                        {
                            "id": 128493,
                            "name": "Warren County",
                            "latitude": "41.33437000",
                            "longitude": "-93.56136000"
                        },
                        {
                            "id": 128542,
                            "name": "Washington",
                            "latitude": "41.29918000",
                            "longitude": "-91.69294000"
                        },
                        {
                            "id": 128568,
                            "name": "Washington County",
                            "latitude": "41.33559000",
                            "longitude": "-91.71787000"
                        },
                        {
                            "id": 128612,
                            "name": "Waterloo",
                            "latitude": "42.49276000",
                            "longitude": "-92.34296000"
                        },
                        {
                            "id": 128647,
                            "name": "Waukee",
                            "latitude": "41.61166000",
                            "longitude": "-93.88523000"
                        },
                        {
                            "id": 128652,
                            "name": "Waukon",
                            "latitude": "43.26942000",
                            "longitude": "-91.47570000"
                        },
                        {
                            "id": 128668,
                            "name": "Waverly",
                            "latitude": "42.72581000",
                            "longitude": "-92.47546000"
                        },
                        {
                            "id": 128698,
                            "name": "Wayne County",
                            "latitude": "40.73947000",
                            "longitude": "-93.32736000"
                        },
                        {
                            "id": 128735,
                            "name": "Webster City",
                            "latitude": "42.46942000",
                            "longitude": "-93.81605000"
                        },
                        {
                            "id": 128741,
                            "name": "Webster County",
                            "latitude": "42.42797000",
                            "longitude": "-94.18179000"
                        },
                        {
                            "id": 128781,
                            "name": "Wellman",
                            "latitude": "41.46418000",
                            "longitude": "-91.83823000"
                        },
                        {
                            "id": 128832,
                            "name": "West Branch",
                            "latitude": "41.67141000",
                            "longitude": "-91.34655000"
                        },
                        {
                            "id": 128838,
                            "name": "West Burlington",
                            "latitude": "40.82504000",
                            "longitude": "-91.15654000"
                        },
                        {
                            "id": 128857,
                            "name": "West Des Moines",
                            "latitude": "41.57721000",
                            "longitude": "-93.71133000"
                        },
                        {
                            "id": 128917,
                            "name": "West Liberty",
                            "latitude": "41.57002000",
                            "longitude": "-91.26377000"
                        },
                        {
                            "id": 128993,
                            "name": "West Union",
                            "latitude": "42.96276000",
                            "longitude": "-91.80822000"
                        },
                        {
                            "id": 129267,
                            "name": "Williamsburg",
                            "latitude": "41.66112000",
                            "longitude": "-92.00907000"
                        },
                        {
                            "id": 129343,
                            "name": "Wilton",
                            "latitude": "41.58892000",
                            "longitude": "-91.01682000"
                        },
                        {
                            "id": 129389,
                            "name": "Windsor Heights",
                            "latitude": "41.59777000",
                            "longitude": "-93.70828000"
                        },
                        {
                            "id": 129395,
                            "name": "Winfield",
                            "latitude": "41.12308000",
                            "longitude": "-91.44127000"
                        },
                        {
                            "id": 129407,
                            "name": "Winnebago County",
                            "latitude": "43.37757000",
                            "longitude": "-93.73420000"
                        },
                        {
                            "id": 129413,
                            "name": "Winneshiek County",
                            "latitude": "43.29067000",
                            "longitude": "-91.84371000"
                        },
                        {
                            "id": 129446,
                            "name": "Winterset",
                            "latitude": "41.33082000",
                            "longitude": "-94.01384000"
                        },
                        {
                            "id": 129496,
                            "name": "Woodbine",
                            "latitude": "41.73832000",
                            "longitude": "-95.70278000"
                        },
                        {
                            "id": 129512,
                            "name": "Woodbury County",
                            "latitude": "42.38972000",
                            "longitude": "-96.04477000"
                        },
                        {
                            "id": 129576,
                            "name": "Woodward",
                            "latitude": "41.85693000",
                            "longitude": "-93.92190000"
                        },
                        {
                            "id": 129593,
                            "name": "Worth County",
                            "latitude": "43.37740000",
                            "longitude": "-93.26085000"
                        },
                        {
                            "id": 129609,
                            "name": "Wright County",
                            "latitude": "42.73312000",
                            "longitude": "-93.73515000"
                        }
                    ]
                },
                {
                    "id": 1410,
                    "name": "Jarvis Island",
                    "state_code": "UM-86",
                    "cities": []
                },
                {
                    "id": 1428,
                    "name": "Johnston Atoll",
                    "state_code": "UM-67",
                    "cities": []
                },
                {
                    "id": 1406,
                    "name": "Kansas",
                    "state_code": "KS",
                    "cities": [
                        {
                            "id": 110982,
                            "name": "Abilene",
                            "latitude": "38.91722000",
                            "longitude": "-97.21391000"
                        },
                        {
                            "id": 111188,
                            "name": "Allen County",
                            "latitude": "37.88573000",
                            "longitude": "-95.30139000"
                        },
                        {
                            "id": 111208,
                            "name": "Alma",
                            "latitude": "39.01667000",
                            "longitude": "-96.28916000"
                        },
                        {
                            "id": 111233,
                            "name": "Altamont",
                            "latitude": "37.19034000",
                            "longitude": "-95.29719000"
                        },
                        {
                            "id": 111315,
                            "name": "Anderson County",
                            "latitude": "38.21420000",
                            "longitude": "-95.29333000"
                        },
                        {
                            "id": 111322,
                            "name": "Andover",
                            "latitude": "37.71390000",
                            "longitude": "-97.13643000"
                        },
                        {
                            "id": 111368,
                            "name": "Anthony",
                            "latitude": "37.15336000",
                            "longitude": "-98.03117000"
                        },
                        {
                            "id": 111457,
                            "name": "Arkansas City",
                            "latitude": "37.06197000",
                            "longitude": "-97.03837000"
                        },
                        {
                            "id": 111475,
                            "name": "Arma",
                            "latitude": "37.54394000",
                            "longitude": "-94.70024000"
                        },
                        {
                            "id": 111527,
                            "name": "Ashland",
                            "latitude": "37.18864000",
                            "longitude": "-99.76568000"
                        },
                        {
                            "id": 111567,
                            "name": "Atchison",
                            "latitude": "39.56305000",
                            "longitude": "-95.12164000"
                        },
                        {
                            "id": 111568,
                            "name": "Atchison County",
                            "latitude": "39.53174000",
                            "longitude": "-95.31344000"
                        },
                        {
                            "id": 111615,
                            "name": "Atwood",
                            "latitude": "39.80667000",
                            "longitude": "-101.04210000"
                        },
                        {
                            "id": 111622,
                            "name": "Auburn",
                            "latitude": "38.90611000",
                            "longitude": "-95.81610000"
                        },
                        {
                            "id": 111647,
                            "name": "Augusta",
                            "latitude": "37.68668000",
                            "longitude": "-96.97670000"
                        },
                        {
                            "id": 111759,
                            "name": "Baldwin City",
                            "latitude": "38.77501000",
                            "longitude": "-95.18636000"
                        },
                        {
                            "id": 111812,
                            "name": "Barber County",
                            "latitude": "37.22884000",
                            "longitude": "-98.68479000"
                        },
                        {
                            "id": 111866,
                            "name": "Barton County",
                            "latitude": "38.47896000",
                            "longitude": "-98.75646000"
                        },
                        {
                            "id": 111876,
                            "name": "Basehor",
                            "latitude": "39.14167000",
                            "longitude": "-94.93858000"
                        },
                        {
                            "id": 111919,
                            "name": "Baxter Springs",
                            "latitude": "37.02368000",
                            "longitude": "-94.73550000"
                        },
                        {
                            "id": 112074,
                            "name": "Bellaire",
                            "latitude": "37.76251000",
                            "longitude": "-97.26699000"
                        },
                        {
                            "id": 112088,
                            "name": "Belle Plaine",
                            "latitude": "37.39391000",
                            "longitude": "-97.28115000"
                        },
                        {
                            "id": 112104,
                            "name": "Belleville",
                            "latitude": "39.82445000",
                            "longitude": "-97.63254000"
                        },
                        {
                            "id": 112145,
                            "name": "Beloit",
                            "latitude": "39.45612000",
                            "longitude": "-98.10616000"
                        },
                        {
                            "id": 112543,
                            "name": "Bonner Springs",
                            "latitude": "39.05973000",
                            "longitude": "-94.88358000"
                        },
                        {
                            "id": 112613,
                            "name": "Bourbon County",
                            "latitude": "37.85523000",
                            "longitude": "-94.84930000"
                        },
                        {
                            "id": 112909,
                            "name": "Brown County",
                            "latitude": "39.82650000",
                            "longitude": "-95.56422000"
                        },
                        {
                            "id": 113024,
                            "name": "Buhler",
                            "latitude": "38.13445000",
                            "longitude": "-97.77005000"
                        },
                        {
                            "id": 113066,
                            "name": "Burlington",
                            "latitude": "38.19447000",
                            "longitude": "-95.74276000"
                        },
                        {
                            "id": 113115,
                            "name": "Butler County",
                            "latitude": "37.78127000",
                            "longitude": "-96.83907000"
                        },
                        {
                            "id": 113179,
                            "name": "Caldwell",
                            "latitude": "37.03225000",
                            "longitude": "-97.60699000"
                        },
                        {
                            "id": 113328,
                            "name": "Caney",
                            "latitude": "37.01146000",
                            "longitude": "-95.93526000"
                        },
                        {
                            "id": 113387,
                            "name": "Carbondale",
                            "latitude": "38.81862000",
                            "longitude": "-95.68915000"
                        },
                        {
                            "id": 113739,
                            "name": "Chanute",
                            "latitude": "37.67921000",
                            "longitude": "-95.45720000"
                        },
                        {
                            "id": 113744,
                            "name": "Chapman",
                            "latitude": "38.97222000",
                            "longitude": "-97.02251000"
                        },
                        {
                            "id": 113792,
                            "name": "Chase County",
                            "latitude": "38.30205000",
                            "longitude": "-96.59393000"
                        },
                        {
                            "id": 113814,
                            "name": "Chautauqua County",
                            "latitude": "37.15006000",
                            "longitude": "-96.24538000"
                        },
                        {
                            "id": 113839,
                            "name": "Cheney",
                            "latitude": "37.63001000",
                            "longitude": "-97.78255000"
                        },
                        {
                            "id": 113851,
                            "name": "Cherokee County",
                            "latitude": "37.16931000",
                            "longitude": "-94.84627000"
                        },
                        {
                            "id": 113868,
                            "name": "Cherryvale",
                            "latitude": "37.27034000",
                            "longitude": "-95.55248000"
                        },
                        {
                            "id": 113916,
                            "name": "Chetopa",
                            "latitude": "37.03729000",
                            "longitude": "-95.08996000"
                        },
                        {
                            "id": 113928,
                            "name": "Cheyenne County",
                            "latitude": "39.78587000",
                            "longitude": "-101.73110000"
                        },
                        {
                            "id": 114023,
                            "name": "Cimarron",
                            "latitude": "37.80669000",
                            "longitude": "-100.34820000"
                        },
                        {
                            "id": 114124,
                            "name": "Clark County",
                            "latitude": "37.23552000",
                            "longitude": "-99.82031000"
                        },
                        {
                            "id": 114170,
                            "name": "Clay Center",
                            "latitude": "39.37694000",
                            "longitude": "-97.12474000"
                        },
                        {
                            "id": 114179,
                            "name": "Clay County",
                            "latitude": "39.34971000",
                            "longitude": "-97.16517000"
                        },
                        {
                            "id": 114223,
                            "name": "Clearwater",
                            "latitude": "37.50280000",
                            "longitude": "-97.50449000"
                        },
                        {
                            "id": 114306,
                            "name": "Cloud County",
                            "latitude": "39.48030000",
                            "longitude": "-97.64928000"
                        },
                        {
                            "id": 114370,
                            "name": "Coffey County",
                            "latitude": "38.23684000",
                            "longitude": "-95.73411000"
                        },
                        {
                            "id": 114371,
                            "name": "Coffeyville",
                            "latitude": "37.03730000",
                            "longitude": "-95.61637000"
                        },
                        {
                            "id": 114381,
                            "name": "Colby",
                            "latitude": "39.39584000",
                            "longitude": "-101.05238000"
                        },
                        {
                            "id": 114392,
                            "name": "Coldwater",
                            "latitude": "37.26891000",
                            "longitude": "-99.32678000"
                        },
                        {
                            "id": 114492,
                            "name": "Columbus",
                            "latitude": "37.16923000",
                            "longitude": "-94.84412000"
                        },
                        {
                            "id": 114507,
                            "name": "Colwich",
                            "latitude": "37.77918000",
                            "longitude": "-97.53644000"
                        },
                        {
                            "id": 114512,
                            "name": "Comanche County",
                            "latitude": "37.19128000",
                            "longitude": "-99.27187000"
                        },
                        {
                            "id": 114544,
                            "name": "Concordia",
                            "latitude": "39.57084000",
                            "longitude": "-97.66254000"
                        },
                        {
                            "id": 114588,
                            "name": "Conway Springs",
                            "latitude": "37.39030000",
                            "longitude": "-97.64227000"
                        },
                        {
                            "id": 114704,
                            "name": "Cottonwood Falls",
                            "latitude": "38.37224000",
                            "longitude": "-96.54278000"
                        },
                        {
                            "id": 114713,
                            "name": "Council Grove",
                            "latitude": "38.66112000",
                            "longitude": "-96.49195000"
                        },
                        {
                            "id": 114748,
                            "name": "Cowley County",
                            "latitude": "37.23775000",
                            "longitude": "-96.83749000"
                        },
                        {
                            "id": 114778,
                            "name": "Crawford County",
                            "latitude": "37.50732000",
                            "longitude": "-94.85181000"
                        },
                        {
                            "id": 115115,
                            "name": "De Soto",
                            "latitude": "38.97917000",
                            "longitude": "-94.96858000"
                        },
                        {
                            "id": 115163,
                            "name": "Decatur County",
                            "latitude": "39.78476000",
                            "longitude": "-100.45990000"
                        },
                        {
                            "id": 115260,
                            "name": "Derby",
                            "latitude": "37.54557000",
                            "longitude": "-97.26893000"
                        },
                        {
                            "id": 115327,
                            "name": "Dickinson County",
                            "latitude": "38.86650000",
                            "longitude": "-97.15270000"
                        },
                        {
                            "id": 115336,
                            "name": "Dighton",
                            "latitude": "38.48196000",
                            "longitude": "-100.46708000"
                        },
                        {
                            "id": 115376,
                            "name": "Dodge City",
                            "latitude": "37.75280000",
                            "longitude": "-100.01708000"
                        },
                        {
                            "id": 115398,
                            "name": "Doniphan County",
                            "latitude": "39.78808000",
                            "longitude": "-95.14679000"
                        },
                        {
                            "id": 115427,
                            "name": "Douglas County",
                            "latitude": "38.88466000",
                            "longitude": "-95.29261000"
                        },
                        {
                            "id": 115437,
                            "name": "Douglass",
                            "latitude": "37.51946000",
                            "longitude": "-97.01281000"
                        },
                        {
                            "id": 115848,
                            "name": "Edgerton",
                            "latitude": "38.76473000",
                            "longitude": "-95.00802000"
                        },
                        {
                            "id": 115890,
                            "name": "Edwards County",
                            "latitude": "37.88765000",
                            "longitude": "-99.31217000"
                        },
                        {
                            "id": 115893,
                            "name": "Edwardsville",
                            "latitude": "39.06112000",
                            "longitude": "-94.81968000"
                        },
                        {
                            "id": 115920,
                            "name": "El Dorado",
                            "latitude": "37.81724000",
                            "longitude": "-96.86225000"
                        },
                        {
                            "id": 115984,
                            "name": "Elk County",
                            "latitude": "37.45369000",
                            "longitude": "-96.24409000"
                        },
                        {
                            "id": 115999,
                            "name": "Elkhart",
                            "latitude": "37.00808000",
                            "longitude": "-101.89017000"
                        },
                        {
                            "id": 116027,
                            "name": "Ellinwood",
                            "latitude": "38.35557000",
                            "longitude": "-98.58091000"
                        },
                        {
                            "id": 116029,
                            "name": "Ellis",
                            "latitude": "38.93807000",
                            "longitude": "-99.56067000"
                        },
                        {
                            "id": 116030,
                            "name": "Ellis County",
                            "latitude": "38.91475000",
                            "longitude": "-99.31723000"
                        },
                        {
                            "id": 116037,
                            "name": "Ellsworth",
                            "latitude": "38.73056000",
                            "longitude": "-98.22811000"
                        },
                        {
                            "id": 116040,
                            "name": "Ellsworth County",
                            "latitude": "38.69663000",
                            "longitude": "-98.20473000"
                        },
                        {
                            "id": 116077,
                            "name": "Elwood",
                            "latitude": "39.75555000",
                            "longitude": "-94.87247000"
                        },
                        {
                            "id": 116111,
                            "name": "Emporia",
                            "latitude": "38.40390000",
                            "longitude": "-96.18166000"
                        },
                        {
                            "id": 116164,
                            "name": "Erie",
                            "latitude": "37.56811000",
                            "longitude": "-95.24331000"
                        },
                        {
                            "id": 116223,
                            "name": "Eudora",
                            "latitude": "38.94334000",
                            "longitude": "-95.09858000"
                        },
                        {
                            "id": 116232,
                            "name": "Eureka",
                            "latitude": "37.82392000",
                            "longitude": "-96.28917000"
                        },
                        {
                            "id": 116362,
                            "name": "Fairway",
                            "latitude": "39.02223000",
                            "longitude": "-94.63190000"
                        },
                        {
                            "id": 116518,
                            "name": "Finney County",
                            "latitude": "38.04430000",
                            "longitude": "-100.73699000"
                        },
                        {
                            "id": 116636,
                            "name": "Ford County",
                            "latitude": "37.69170000",
                            "longitude": "-99.88794000"
                        },
                        {
                            "id": 116746,
                            "name": "Fort Riley North",
                            "latitude": "39.11081000",
                            "longitude": "-96.81392000"
                        },
                        {
                            "id": 116749,
                            "name": "Fort Scott",
                            "latitude": "37.83976000",
                            "longitude": "-94.70830000"
                        },
                        {
                            "id": 116849,
                            "name": "Franklin County",
                            "latitude": "38.56452000",
                            "longitude": "-95.28595000"
                        },
                        {
                            "id": 116895,
                            "name": "Fredonia",
                            "latitude": "37.53394000",
                            "longitude": "-95.82665000"
                        },
                        {
                            "id": 116961,
                            "name": "Frontenac",
                            "latitude": "37.45560000",
                            "longitude": "-94.68913000"
                        },
                        {
                            "id": 117022,
                            "name": "Galena",
                            "latitude": "37.07590000",
                            "longitude": "-94.63967000"
                        },
                        {
                            "id": 117059,
                            "name": "Garden City",
                            "latitude": "37.97169000",
                            "longitude": "-100.87266000"
                        },
                        {
                            "id": 117074,
                            "name": "Gardner",
                            "latitude": "38.81084000",
                            "longitude": "-94.92719000"
                        },
                        {
                            "id": 117095,
                            "name": "Garnett",
                            "latitude": "38.28058000",
                            "longitude": "-95.24192000"
                        },
                        {
                            "id": 117131,
                            "name": "Geary County",
                            "latitude": "39.00236000",
                            "longitude": "-96.75254000"
                        },
                        {
                            "id": 117227,
                            "name": "Girard",
                            "latitude": "37.51116000",
                            "longitude": "-94.83802000"
                        },
                        {
                            "id": 117332,
                            "name": "Goddard",
                            "latitude": "37.65974000",
                            "longitude": "-97.57533000"
                        },
                        {
                            "id": 117385,
                            "name": "Goodland",
                            "latitude": "39.35083000",
                            "longitude": "-101.71017000"
                        },
                        {
                            "id": 117420,
                            "name": "Gove",
                            "latitude": "38.95779000",
                            "longitude": "-100.48875000"
                        },
                        {
                            "id": 117421,
                            "name": "Gove County",
                            "latitude": "38.91610000",
                            "longitude": "-100.48290000"
                        },
                        {
                            "id": 117437,
                            "name": "Graham County",
                            "latitude": "39.34972000",
                            "longitude": "-99.88325000"
                        },
                        {
                            "id": 117482,
                            "name": "Grandview Plaza",
                            "latitude": "39.02916000",
                            "longitude": "-96.78917000"
                        },
                        {
                            "id": 117516,
                            "name": "Grant County",
                            "latitude": "37.56219000",
                            "longitude": "-101.30802000"
                        },
                        {
                            "id": 117553,
                            "name": "Gray County",
                            "latitude": "37.73820000",
                            "longitude": "-100.43786000"
                        },
                        {
                            "id": 117570,
                            "name": "Great Bend",
                            "latitude": "38.36446000",
                            "longitude": "-98.76481000"
                        },
                        {
                            "id": 117588,
                            "name": "Greeley County",
                            "latitude": "38.48054000",
                            "longitude": "-101.80597000"
                        },
                        {
                            "id": 117670,
                            "name": "Greensburg",
                            "latitude": "37.60280000",
                            "longitude": "-99.29261000"
                        },
                        {
                            "id": 117709,
                            "name": "Greenwood County",
                            "latitude": "37.87779000",
                            "longitude": "-96.23264000"
                        },
                        {
                            "id": 117877,
                            "name": "Halstead",
                            "latitude": "38.00140000",
                            "longitude": "-97.50865000"
                        },
                        {
                            "id": 117904,
                            "name": "Hamilton County",
                            "latitude": "37.99915000",
                            "longitude": "-101.79126000"
                        },
                        {
                            "id": 118031,
                            "name": "Harper",
                            "latitude": "37.28669000",
                            "longitude": "-98.02589000"
                        },
                        {
                            "id": 118033,
                            "name": "Harper County",
                            "latitude": "37.19160000",
                            "longitude": "-98.07550000"
                        },
                        {
                            "id": 118115,
                            "name": "Harvey County",
                            "latitude": "38.04322000",
                            "longitude": "-97.42727000"
                        },
                        {
                            "id": 118127,
                            "name": "Haskell County",
                            "latitude": "37.56225000",
                            "longitude": "-100.87119000"
                        },
                        {
                            "id": 118148,
                            "name": "Haven",
                            "latitude": "37.89890000",
                            "longitude": "-97.78283000"
                        },
                        {
                            "id": 118190,
                            "name": "Hays",
                            "latitude": "38.87918000",
                            "longitude": "-99.32677000"
                        },
                        {
                            "id": 118193,
                            "name": "Haysville",
                            "latitude": "37.56446000",
                            "longitude": "-97.35227000"
                        },
                        {
                            "id": 118316,
                            "name": "Herington",
                            "latitude": "38.67112000",
                            "longitude": "-96.94251000"
                        },
                        {
                            "id": 118345,
                            "name": "Hesston",
                            "latitude": "38.13834000",
                            "longitude": "-97.43143000"
                        },
                        {
                            "id": 118357,
                            "name": "Hiawatha",
                            "latitude": "39.85250000",
                            "longitude": "-95.53582000"
                        },
                        {
                            "id": 118394,
                            "name": "Highland",
                            "latitude": "39.85972000",
                            "longitude": "-95.26970000"
                        },
                        {
                            "id": 118434,
                            "name": "Hill City",
                            "latitude": "39.36473000",
                            "longitude": "-99.84206000"
                        },
                        {
                            "id": 118445,
                            "name": "Hillsboro",
                            "latitude": "38.35196000",
                            "longitude": "-97.20447000"
                        },
                        {
                            "id": 118507,
                            "name": "Hodgeman County",
                            "latitude": "38.08748000",
                            "longitude": "-99.89794000"
                        },
                        {
                            "id": 118513,
                            "name": "Hoisington",
                            "latitude": "38.51807000",
                            "longitude": "-98.77814000"
                        },
                        {
                            "id": 118521,
                            "name": "Holcomb",
                            "latitude": "37.98614000",
                            "longitude": "-100.98933000"
                        },
                        {
                            "id": 118578,
                            "name": "Holton",
                            "latitude": "39.46527000",
                            "longitude": "-95.73637000"
                        },
                        {
                            "id": 118678,
                            "name": "Horton",
                            "latitude": "39.66056000",
                            "longitude": "-95.52637000"
                        },
                        {
                            "id": 118707,
                            "name": "Howard",
                            "latitude": "37.47031000",
                            "longitude": "-96.26361000"
                        },
                        {
                            "id": 118727,
                            "name": "Hoxie",
                            "latitude": "39.35750000",
                            "longitude": "-100.44181000"
                        },
                        {
                            "id": 118768,
                            "name": "Hugoton",
                            "latitude": "37.17530000",
                            "longitude": "-101.34960000"
                        },
                        {
                            "id": 118775,
                            "name": "Humboldt",
                            "latitude": "37.81060000",
                            "longitude": "-95.43693000"
                        },
                        {
                            "id": 118835,
                            "name": "Hutchinson",
                            "latitude": "38.06084000",
                            "longitude": "-97.92977000"
                        },
                        {
                            "id": 118889,
                            "name": "Independence",
                            "latitude": "37.22424000",
                            "longitude": "-95.70831000"
                        },
                        {
                            "id": 118944,
                            "name": "Inman",
                            "latitude": "38.23195000",
                            "longitude": "-97.77338000"
                        },
                        {
                            "id": 118966,
                            "name": "Iola",
                            "latitude": "37.92448000",
                            "longitude": "-95.39998000"
                        },
                        {
                            "id": 119081,
                            "name": "Jackson County",
                            "latitude": "39.41682000",
                            "longitude": "-95.79366000"
                        },
                        {
                            "id": 119181,
                            "name": "Jefferson County",
                            "latitude": "39.23576000",
                            "longitude": "-95.38345000"
                        },
                        {
                            "id": 119241,
                            "name": "Jetmore",
                            "latitude": "38.08446000",
                            "longitude": "-99.89346000"
                        },
                        {
                            "id": 119243,
                            "name": "Jewell County",
                            "latitude": "39.78473000",
                            "longitude": "-98.21834000"
                        },
                        {
                            "id": 119256,
                            "name": "Johnson",
                            "latitude": "37.57057000",
                            "longitude": "-101.75100000"
                        },
                        {
                            "id": 119264,
                            "name": "Johnson County",
                            "latitude": "38.88376000",
                            "longitude": "-94.82226000"
                        },
                        {
                            "id": 119334,
                            "name": "Junction City",
                            "latitude": "39.02861000",
                            "longitude": "-96.83140000"
                        },
                        {
                            "id": 119383,
                            "name": "Kansas City",
                            "latitude": "39.11417000",
                            "longitude": "-94.62746000"
                        },
                        {
                            "id": 119414,
                            "name": "Kearny County",
                            "latitude": "38.00020000",
                            "longitude": "-101.31986000"
                        },
                        {
                            "id": 119416,
                            "name": "Kechi",
                            "latitude": "37.79585000",
                            "longitude": "-97.27949000"
                        },
                        {
                            "id": 119594,
                            "name": "Kingman",
                            "latitude": "37.64585000",
                            "longitude": "-98.11367000"
                        },
                        {
                            "id": 119596,
                            "name": "Kingman County",
                            "latitude": "37.55888000",
                            "longitude": "-98.13633000"
                        },
                        {
                            "id": 119642,
                            "name": "Kinsley",
                            "latitude": "37.92307000",
                            "longitude": "-99.40984000"
                        },
                        {
                            "id": 119644,
                            "name": "Kiowa",
                            "latitude": "37.01725000",
                            "longitude": "-98.48535000"
                        },
                        {
                            "id": 119646,
                            "name": "Kiowa County",
                            "latitude": "37.55826000",
                            "longitude": "-99.28605000"
                        },
                        {
                            "id": 119743,
                            "name": "La Crosse",
                            "latitude": "38.53140000",
                            "longitude": "-99.30872000"
                        },
                        {
                            "id": 119746,
                            "name": "La Cygne",
                            "latitude": "38.35002000",
                            "longitude": "-94.76135000"
                        },
                        {
                            "id": 119809,
                            "name": "Labette County",
                            "latitude": "37.19133000",
                            "longitude": "-95.29758000"
                        },
                        {
                            "id": 120056,
                            "name": "Lakin",
                            "latitude": "37.94058000",
                            "longitude": "-101.25489000"
                        },
                        {
                            "id": 120105,
                            "name": "Lane County",
                            "latitude": "38.48130000",
                            "longitude": "-100.46640000"
                        },
                        {
                            "id": 120125,
                            "name": "Lansing",
                            "latitude": "39.24861000",
                            "longitude": "-94.90024000"
                        },
                        {
                            "id": 120150,
                            "name": "Larned",
                            "latitude": "38.18057000",
                            "longitude": "-99.09871000"
                        },
                        {
                            "id": 120226,
                            "name": "Lawrence",
                            "latitude": "38.97167000",
                            "longitude": "-95.23525000"
                        },
                        {
                            "id": 120280,
                            "name": "Leavenworth",
                            "latitude": "39.31111000",
                            "longitude": "-94.92246000"
                        },
                        {
                            "id": 120282,
                            "name": "Leavenworth County",
                            "latitude": "39.19932000",
                            "longitude": "-95.03790000"
                        },
                        {
                            "id": 120283,
                            "name": "Leawood",
                            "latitude": "38.96667000",
                            "longitude": "-94.61690000"
                        },
                        {
                            "id": 120369,
                            "name": "Lenexa",
                            "latitude": "38.95362000",
                            "longitude": "-94.73357000"
                        },
                        {
                            "id": 120393,
                            "name": "Leoti",
                            "latitude": "38.47974000",
                            "longitude": "-101.35877000"
                        },
                        {
                            "id": 120458,
                            "name": "Liberal",
                            "latitude": "37.04308000",
                            "longitude": "-100.92100000"
                        },
                        {
                            "id": 120501,
                            "name": "Lincoln",
                            "latitude": "39.04084000",
                            "longitude": "-98.14477000"
                        },
                        {
                            "id": 120518,
                            "name": "Lincoln County",
                            "latitude": "39.04533000",
                            "longitude": "-98.20770000"
                        },
                        {
                            "id": 120575,
                            "name": "Lindsborg",
                            "latitude": "38.57362000",
                            "longitude": "-97.67448000"
                        },
                        {
                            "id": 120581,
                            "name": "Linn County",
                            "latitude": "38.21227000",
                            "longitude": "-94.84293000"
                        },
                        {
                            "id": 120702,
                            "name": "Logan County",
                            "latitude": "38.91734000",
                            "longitude": "-101.14839000"
                        },
                        {
                            "id": 120808,
                            "name": "Louisburg",
                            "latitude": "38.61946000",
                            "longitude": "-94.68079000"
                        },
                        {
                            "id": 120922,
                            "name": "Lyndon",
                            "latitude": "38.61001000",
                            "longitude": "-95.68443000"
                        },
                        {
                            "id": 120935,
                            "name": "Lyon County",
                            "latitude": "38.45619000",
                            "longitude": "-96.15264000"
                        },
                        {
                            "id": 120941,
                            "name": "Lyons",
                            "latitude": "38.34501000",
                            "longitude": "-98.20173000"
                        },
                        {
                            "id": 121052,
                            "name": "Maize",
                            "latitude": "37.77918000",
                            "longitude": "-97.46727000"
                        },
                        {
                            "id": 121113,
                            "name": "Manhattan",
                            "latitude": "39.18361000",
                            "longitude": "-96.57167000"
                        },
                        {
                            "id": 121129,
                            "name": "Mankato",
                            "latitude": "39.78723000",
                            "longitude": "-98.21005000"
                        },
                        {
                            "id": 121234,
                            "name": "Marion",
                            "latitude": "38.34835000",
                            "longitude": "-97.01725000"
                        },
                        {
                            "id": 121253,
                            "name": "Marion County",
                            "latitude": "38.35887000",
                            "longitude": "-97.09689000"
                        },
                        {
                            "id": 121311,
                            "name": "Marshall County",
                            "latitude": "39.78362000",
                            "longitude": "-96.52294000"
                        },
                        {
                            "id": 121357,
                            "name": "Marysville",
                            "latitude": "39.84111000",
                            "longitude": "-96.64724000"
                        },
                        {
                            "id": 121476,
                            "name": "McConnell AFB",
                            "latitude": "37.63007000",
                            "longitude": "-97.25869000"
                        },
                        {
                            "id": 121545,
                            "name": "McPherson",
                            "latitude": "38.37084000",
                            "longitude": "-97.66421000"
                        },
                        {
                            "id": 121546,
                            "name": "McPherson County",
                            "latitude": "38.39167000",
                            "longitude": "-97.64808000"
                        },
                        {
                            "id": 121555,
                            "name": "Meade",
                            "latitude": "37.28558000",
                            "longitude": "-100.34015000"
                        },
                        {
                            "id": 121557,
                            "name": "Meade County",
                            "latitude": "37.23820000",
                            "longitude": "-100.36618000"
                        },
                        {
                            "id": 121601,
                            "name": "Medicine Lodge",
                            "latitude": "37.28113000",
                            "longitude": "-98.58036000"
                        },
                        {
                            "id": 121702,
                            "name": "Merriam",
                            "latitude": "39.02362000",
                            "longitude": "-94.69357000"
                        },
                        {
                            "id": 121751,
                            "name": "Miami County",
                            "latitude": "38.56358000",
                            "longitude": "-94.83806000"
                        },
                        {
                            "id": 121950,
                            "name": "Minneapolis",
                            "latitude": "39.12194000",
                            "longitude": "-97.70670000"
                        },
                        {
                            "id": 121979,
                            "name": "Mission",
                            "latitude": "39.02778000",
                            "longitude": "-94.65579000"
                        },
                        {
                            "id": 121986,
                            "name": "Mission Hills",
                            "latitude": "39.01778000",
                            "longitude": "-94.61690000"
                        },
                        {
                            "id": 122000,
                            "name": "Mitchell County",
                            "latitude": "39.39327000",
                            "longitude": "-98.20936000"
                        },
                        {
                            "id": 122143,
                            "name": "Montgomery County",
                            "latitude": "37.19252000",
                            "longitude": "-95.74288000"
                        },
                        {
                            "id": 122269,
                            "name": "Morris County",
                            "latitude": "38.68743000",
                            "longitude": "-96.64985000"
                        },
                        {
                            "id": 122296,
                            "name": "Morton County",
                            "latitude": "37.19140000",
                            "longitude": "-101.79925000"
                        },
                        {
                            "id": 122321,
                            "name": "Mound City",
                            "latitude": "38.14281000",
                            "longitude": "-94.81357000"
                        },
                        {
                            "id": 122324,
                            "name": "Moundridge",
                            "latitude": "38.20307000",
                            "longitude": "-97.51921000"
                        },
                        {
                            "id": 122464,
                            "name": "Mulvane",
                            "latitude": "37.47446000",
                            "longitude": "-97.24393000"
                        },
                        {
                            "id": 122629,
                            "name": "Nemaha County",
                            "latitude": "39.78345000",
                            "longitude": "-96.01410000"
                        },
                        {
                            "id": 122631,
                            "name": "Neodesha",
                            "latitude": "37.41839000",
                            "longitude": "-95.68026000"
                        },
                        {
                            "id": 122634,
                            "name": "Neosho County",
                            "latitude": "37.55849000",
                            "longitude": "-95.30679000"
                        },
                        {
                            "id": 122643,
                            "name": "Ness City",
                            "latitude": "38.45279000",
                            "longitude": "-99.90651000"
                        },
                        {
                            "id": 122644,
                            "name": "Ness County",
                            "latitude": "38.47942000",
                            "longitude": "-99.91618000"
                        },
                        {
                            "id": 122692,
                            "name": "New Century, KS",
                            "latitude": "38.82253000",
                            "longitude": "-94.89971000"
                        },
                        {
                            "id": 122858,
                            "name": "Newton",
                            "latitude": "38.04668000",
                            "longitude": "-97.34504000"
                        },
                        {
                            "id": 122894,
                            "name": "Nickerson",
                            "latitude": "38.14723000",
                            "longitude": "-98.08367000"
                        },
                        {
                            "id": 123072,
                            "name": "North Newton",
                            "latitude": "38.07223000",
                            "longitude": "-97.34559000"
                        },
                        {
                            "id": 123182,
                            "name": "Norton",
                            "latitude": "39.83389000",
                            "longitude": "-99.89151000"
                        },
                        {
                            "id": 123186,
                            "name": "Norton County",
                            "latitude": "39.78439000",
                            "longitude": "-99.90348000"
                        },
                        {
                            "id": 123299,
                            "name": "Oakley",
                            "latitude": "39.13334000",
                            "longitude": "-100.86376000"
                        },
                        {
                            "id": 123316,
                            "name": "Oberlin",
                            "latitude": "39.81834000",
                            "longitude": "-100.52820000"
                        },
                        {
                            "id": 123366,
                            "name": "Ogden",
                            "latitude": "39.11111000",
                            "longitude": "-96.70612000"
                        },
                        {
                            "id": 123412,
                            "name": "Olathe",
                            "latitude": "38.88140000",
                            "longitude": "-94.81913000"
                        },
                        {
                            "id": 123588,
                            "name": "Osage City",
                            "latitude": "38.63390000",
                            "longitude": "-95.82582000"
                        },
                        {
                            "id": 123589,
                            "name": "Osage County",
                            "latitude": "38.65233000",
                            "longitude": "-95.72695000"
                        },
                        {
                            "id": 123593,
                            "name": "Osawatomie",
                            "latitude": "38.49724000",
                            "longitude": "-94.95052000"
                        },
                        {
                            "id": 123594,
                            "name": "Osborne",
                            "latitude": "39.43914000",
                            "longitude": "-98.69624000"
                        },
                        {
                            "id": 123595,
                            "name": "Osborne County",
                            "latitude": "39.35033000",
                            "longitude": "-98.76799000"
                        },
                        {
                            "id": 123611,
                            "name": "Oskaloosa",
                            "latitude": "39.21528000",
                            "longitude": "-95.31275000"
                        },
                        {
                            "id": 123621,
                            "name": "Oswego",
                            "latitude": "37.16757000",
                            "longitude": "-95.10996000"
                        },
                        {
                            "id": 123636,
                            "name": "Ottawa",
                            "latitude": "38.61557000",
                            "longitude": "-95.26775000"
                        },
                        {
                            "id": 123638,
                            "name": "Ottawa County",
                            "latitude": "39.13254000",
                            "longitude": "-97.65022000"
                        },
                        {
                            "id": 123649,
                            "name": "Overbrook",
                            "latitude": "38.78056000",
                            "longitude": "-95.55720000"
                        },
                        {
                            "id": 123651,
                            "name": "Overland Park",
                            "latitude": "38.98223000",
                            "longitude": "-94.67079000"
                        },
                        {
                            "id": 123677,
                            "name": "Oxford",
                            "latitude": "37.27419000",
                            "longitude": "-97.16893000"
                        },
                        {
                            "id": 123810,
                            "name": "Paola",
                            "latitude": "38.57224000",
                            "longitude": "-94.87913000"
                        },
                        {
                            "id": 123837,
                            "name": "Park City",
                            "latitude": "37.80001000",
                            "longitude": "-97.31838000"
                        },
                        {
                            "id": 123891,
                            "name": "Parsons",
                            "latitude": "37.34034000",
                            "longitude": "-95.26108000"
                        },
                        {
                            "id": 123936,
                            "name": "Pawnee County",
                            "latitude": "38.18133000",
                            "longitude": "-99.23673000"
                        },
                        {
                            "id": 123953,
                            "name": "Peabody",
                            "latitude": "38.16946000",
                            "longitude": "-97.10670000"
                        },
                        {
                            "id": 124136,
                            "name": "Phillips County",
                            "latitude": "39.78458000",
                            "longitude": "-99.34702000"
                        },
                        {
                            "id": 124139,
                            "name": "Phillipsburg",
                            "latitude": "39.75612000",
                            "longitude": "-99.32399000"
                        },
                        {
                            "id": 124292,
                            "name": "Pittsburg",
                            "latitude": "37.41088000",
                            "longitude": "-94.70496000"
                        },
                        {
                            "id": 124325,
                            "name": "Plains",
                            "latitude": "37.26030000",
                            "longitude": "-100.59265000"
                        },
                        {
                            "id": 124333,
                            "name": "Plainville",
                            "latitude": "39.23473000",
                            "longitude": "-99.29816000"
                        },
                        {
                            "id": 124382,
                            "name": "Pleasanton",
                            "latitude": "38.17781000",
                            "longitude": "-94.71135000"
                        },
                        {
                            "id": 124589,
                            "name": "Pottawatomie County",
                            "latitude": "39.37901000",
                            "longitude": "-96.34244000"
                        },
                        {
                            "id": 124632,
                            "name": "Prairie Village",
                            "latitude": "38.99167000",
                            "longitude": "-94.63357000"
                        },
                        {
                            "id": 124636,
                            "name": "Pratt",
                            "latitude": "37.64391000",
                            "longitude": "-98.73759000"
                        },
                        {
                            "id": 124637,
                            "name": "Pratt County",
                            "latitude": "37.64774000",
                            "longitude": "-98.73960000"
                        },
                        {
                            "id": 124913,
                            "name": "Rawlins County",
                            "latitude": "39.78524000",
                            "longitude": "-101.07571000"
                        },
                        {
                            "id": 125021,
                            "name": "Reno County",
                            "latitude": "37.95295000",
                            "longitude": "-98.08601000"
                        },
                        {
                            "id": 125033,
                            "name": "Republic County",
                            "latitude": "39.82780000",
                            "longitude": "-97.65062000"
                        },
                        {
                            "id": 125053,
                            "name": "Rice County",
                            "latitude": "38.34714000",
                            "longitude": "-98.20103000"
                        },
                        {
                            "id": 125138,
                            "name": "Riley County",
                            "latitude": "39.29646000",
                            "longitude": "-96.73518000"
                        },
                        {
                            "id": 125345,
                            "name": "Roeland Park",
                            "latitude": "39.03751000",
                            "longitude": "-94.63218000"
                        },
                        {
                            "id": 125390,
                            "name": "Rooks County",
                            "latitude": "39.35023000",
                            "longitude": "-99.32505000"
                        },
                        {
                            "id": 125403,
                            "name": "Rose Hill",
                            "latitude": "37.55835000",
                            "longitude": "-97.13504000"
                        },
                        {
                            "id": 125457,
                            "name": "Rossville",
                            "latitude": "39.13611000",
                            "longitude": "-95.95166000"
                        },
                        {
                            "id": 125521,
                            "name": "Rush County",
                            "latitude": "38.52316000",
                            "longitude": "-99.30924000"
                        },
                        {
                            "id": 125533,
                            "name": "Russell",
                            "latitude": "38.89527000",
                            "longitude": "-98.86103000"
                        },
                        {
                            "id": 125537,
                            "name": "Russell County",
                            "latitude": "38.91478000",
                            "longitude": "-98.76235000"
                        },
                        {
                            "id": 125567,
                            "name": "Sabetha",
                            "latitude": "39.90222000",
                            "longitude": "-95.80082000"
                        },
                        {
                            "id": 125635,
                            "name": "Saint Francis",
                            "latitude": "39.77222000",
                            "longitude": "-101.79990000"
                        },
                        {
                            "id": 125660,
                            "name": "Saint John",
                            "latitude": "38.00224000",
                            "longitude": "-98.76009000"
                        },
                        {
                            "id": 125692,
                            "name": "Saint Marys",
                            "latitude": "39.19416000",
                            "longitude": "-96.07110000"
                        },
                        {
                            "id": 125744,
                            "name": "Salina",
                            "latitude": "38.84028000",
                            "longitude": "-97.61142000"
                        },
                        {
                            "id": 125751,
                            "name": "Saline County",
                            "latitude": "38.78381000",
                            "longitude": "-97.64993000"
                        },
                        {
                            "id": 125954,
                            "name": "Satanta",
                            "latitude": "37.43725000",
                            "longitude": "-100.97211000"
                        },
                        {
                            "id": 126042,
                            "name": "Scott City",
                            "latitude": "38.48252000",
                            "longitude": "-100.90709000"
                        },
                        {
                            "id": 126053,
                            "name": "Scott County",
                            "latitude": "38.48217000",
                            "longitude": "-100.90686000"
                        },
                        {
                            "id": 126115,
                            "name": "Sedan",
                            "latitude": "37.12672000",
                            "longitude": "-96.18694000"
                        },
                        {
                            "id": 126117,
                            "name": "Sedgwick",
                            "latitude": "37.91668000",
                            "longitude": "-97.42254000"
                        },
                        {
                            "id": 126119,
                            "name": "Sedgwick County",
                            "latitude": "37.68476000",
                            "longitude": "-97.46097000"
                        },
                        {
                            "id": 126154,
                            "name": "Seneca",
                            "latitude": "39.83416000",
                            "longitude": "-96.06417000"
                        },
                        {
                            "id": 126189,
                            "name": "Seward County",
                            "latitude": "37.19330000",
                            "longitude": "-100.85129000"
                        },
                        {
                            "id": 126232,
                            "name": "Sharon Springs",
                            "latitude": "38.89779000",
                            "longitude": "-101.75212000"
                        },
                        {
                            "id": 126249,
                            "name": "Shawnee",
                            "latitude": "39.04167000",
                            "longitude": "-94.72024000"
                        },
                        {
                            "id": 126251,
                            "name": "Shawnee County",
                            "latitude": "39.04151000",
                            "longitude": "-95.75653000"
                        },
                        {
                            "id": 126324,
                            "name": "Sheridan County",
                            "latitude": "39.35036000",
                            "longitude": "-100.44183000"
                        },
                        {
                            "id": 126333,
                            "name": "Sherman County",
                            "latitude": "39.35143000",
                            "longitude": "-101.71998000"
                        },
                        {
                            "id": 126428,
                            "name": "Silver Lake",
                            "latitude": "39.10417000",
                            "longitude": "-95.85860000"
                        },
                        {
                            "id": 126511,
                            "name": "Smith Center",
                            "latitude": "39.77918000",
                            "longitude": "-98.78507000"
                        },
                        {
                            "id": 126512,
                            "name": "Smith County",
                            "latitude": "39.78516000",
                            "longitude": "-98.78547000"
                        },
                        {
                            "id": 126569,
                            "name": "Solomon",
                            "latitude": "38.91944000",
                            "longitude": "-97.37114000"
                        },
                        {
                            "id": 126678,
                            "name": "South Hutchinson",
                            "latitude": "38.02807000",
                            "longitude": "-97.94033000"
                        },
                        {
                            "id": 126861,
                            "name": "Spring Hill",
                            "latitude": "38.74306000",
                            "longitude": "-94.82552000"
                        },
                        {
                            "id": 126936,
                            "name": "Stafford County",
                            "latitude": "38.03100000",
                            "longitude": "-98.71744000"
                        },
                        {
                            "id": 126973,
                            "name": "Stanton County",
                            "latitude": "37.56306000",
                            "longitude": "-101.78418000"
                        },
                        {
                            "id": 127026,
                            "name": "Sterling",
                            "latitude": "38.21001000",
                            "longitude": "-98.20701000"
                        },
                        {
                            "id": 127041,
                            "name": "Stevens County",
                            "latitude": "37.19232000",
                            "longitude": "-101.31205000"
                        },
                        {
                            "id": 127071,
                            "name": "Stockton",
                            "latitude": "39.43807000",
                            "longitude": "-99.26510000"
                        },
                        {
                            "id": 127157,
                            "name": "Sublette",
                            "latitude": "37.48169000",
                            "longitude": "-100.84377000"
                        },
                        {
                            "id": 127224,
                            "name": "Sumner County",
                            "latitude": "37.23728000",
                            "longitude": "-97.47655000"
                        },
                        {
                            "id": 127366,
                            "name": "Syracuse",
                            "latitude": "37.98071000",
                            "longitude": "-101.75430000"
                        },
                        {
                            "id": 127580,
                            "name": "Thomas County",
                            "latitude": "39.35097000",
                            "longitude": "-101.05553000"
                        },
                        {
                            "id": 127719,
                            "name": "Tonganoxie",
                            "latitude": "39.10972000",
                            "longitude": "-95.08775000"
                        },
                        {
                            "id": 127732,
                            "name": "Topeka",
                            "latitude": "39.04833000",
                            "longitude": "-95.67804000"
                        },
                        {
                            "id": 127751,
                            "name": "Towanda",
                            "latitude": "37.79752000",
                            "longitude": "-96.99976000"
                        },
                        {
                            "id": 127792,
                            "name": "Trego County",
                            "latitude": "38.91432000",
                            "longitude": "-99.87274000"
                        },
                        {
                            "id": 127820,
                            "name": "Tribune",
                            "latitude": "38.46974000",
                            "longitude": "-101.75267000"
                        },
                        {
                            "id": 127842,
                            "name": "Troy",
                            "latitude": "39.78305000",
                            "longitude": "-95.08997000"
                        },
                        {
                            "id": 127960,
                            "name": "Ulysses",
                            "latitude": "37.58141000",
                            "longitude": "-101.35517000"
                        },
                        {
                            "id": 128099,
                            "name": "Valley Center",
                            "latitude": "37.83473000",
                            "longitude": "-97.37338000"
                        },
                        {
                            "id": 128106,
                            "name": "Valley Falls",
                            "latitude": "39.34333000",
                            "longitude": "-95.46025000"
                        },
                        {
                            "id": 128219,
                            "name": "Victoria",
                            "latitude": "38.85279000",
                            "longitude": "-99.14760000"
                        },
                        {
                            "id": 128315,
                            "name": "Wabaunsee County",
                            "latitude": "38.95328000",
                            "longitude": "-96.20499000"
                        },
                        {
                            "id": 128308,
                            "name": "WaKeeney",
                            "latitude": "39.02501000",
                            "longitude": "-99.87957000"
                        },
                        {
                            "id": 128399,
                            "name": "Wallace County",
                            "latitude": "38.91666000",
                            "longitude": "-101.76357000"
                        },
                        {
                            "id": 128444,
                            "name": "Wamego",
                            "latitude": "39.20194000",
                            "longitude": "-96.30500000"
                        },
                        {
                            "id": 128537,
                            "name": "Washington",
                            "latitude": "39.81806000",
                            "longitude": "-97.05086000"
                        },
                        {
                            "id": 128558,
                            "name": "Washington County",
                            "latitude": "39.78420000",
                            "longitude": "-97.08754000"
                        },
                        {
                            "id": 128633,
                            "name": "Wathena",
                            "latitude": "39.75916000",
                            "longitude": "-94.94969000"
                        },
                        {
                            "id": 128777,
                            "name": "Wellington",
                            "latitude": "37.26530000",
                            "longitude": "-97.37171000"
                        },
                        {
                            "id": 128791,
                            "name": "Wellsville",
                            "latitude": "38.71834000",
                            "longitude": "-95.08164000"
                        },
                        {
                            "id": 129052,
                            "name": "Westmoreland",
                            "latitude": "39.39389000",
                            "longitude": "-96.41361000"
                        },
                        {
                            "id": 129082,
                            "name": "Westwood",
                            "latitude": "39.04056000",
                            "longitude": "-94.61690000"
                        },
                        {
                            "id": 129215,
                            "name": "Wichita",
                            "latitude": "37.69224000",
                            "longitude": "-97.33754000"
                        },
                        {
                            "id": 129217,
                            "name": "Wichita County",
                            "latitude": "38.48208000",
                            "longitude": "-101.34736000"
                        },
                        {
                            "id": 129334,
                            "name": "Wilson County",
                            "latitude": "37.55924000",
                            "longitude": "-95.74339000"
                        },
                        {
                            "id": 129393,
                            "name": "Winfield",
                            "latitude": "37.23975000",
                            "longitude": "-96.99559000"
                        },
                        {
                            "id": 129558,
                            "name": "Woodson County",
                            "latitude": "37.88667000",
                            "longitude": "-95.74017000"
                        },
                        {
                            "id": 129622,
                            "name": "Wyandotte County",
                            "latitude": "39.11465000",
                            "longitude": "-94.76448000"
                        },
                        {
                            "id": 129670,
                            "name": "Yates Center",
                            "latitude": "37.88115000",
                            "longitude": "-95.73332000"
                        }
                    ]
                },
                {
                    "id": 1419,
                    "name": "Kentucky",
                    "state_code": "KY",
                    "cities": [
                        {
                            "id": 111008,
                            "name": "Adair County",
                            "latitude": "37.10416000",
                            "longitude": "-85.28065000"
                        },
                        {
                            "id": 111102,
                            "name": "Albany",
                            "latitude": "36.69090000",
                            "longitude": "-85.13468000"
                        },
                        {
                            "id": 111149,
                            "name": "Alexandria",
                            "latitude": "38.95951000",
                            "longitude": "-84.38799000"
                        },
                        {
                            "id": 111189,
                            "name": "Allen County",
                            "latitude": "36.75132000",
                            "longitude": "-86.19042000"
                        },
                        {
                            "id": 111304,
                            "name": "Anchorage",
                            "latitude": "38.26674000",
                            "longitude": "-85.53302000"
                        },
                        {
                            "id": 111316,
                            "name": "Anderson County",
                            "latitude": "38.00391000",
                            "longitude": "-84.99101000"
                        },
                        {
                            "id": 111356,
                            "name": "Annville",
                            "latitude": "37.31925000",
                            "longitude": "-83.97048000"
                        },
                        {
                            "id": 111526,
                            "name": "Ashland",
                            "latitude": "38.47841000",
                            "longitude": "-82.63794000"
                        },
                        {
                            "id": 111620,
                            "name": "Auburn",
                            "latitude": "36.86421000",
                            "longitude": "-86.71027000"
                        },
                        {
                            "id": 111642,
                            "name": "Audubon Park",
                            "latitude": "38.20396000",
                            "longitude": "-85.72524000"
                        },
                        {
                            "id": 111648,
                            "name": "Augusta",
                            "latitude": "38.77174000",
                            "longitude": "-84.00576000"
                        },
                        {
                            "id": 111771,
                            "name": "Ballard County",
                            "latitude": "37.05843000",
                            "longitude": "-88.99934000"
                        },
                        {
                            "id": 111816,
                            "name": "Barbourmeade",
                            "latitude": "38.29729000",
                            "longitude": "-85.60329000"
                        },
                        {
                            "id": 111818,
                            "name": "Barbourville",
                            "latitude": "36.86648000",
                            "longitude": "-83.88881000"
                        },
                        {
                            "id": 111820,
                            "name": "Bardstown",
                            "latitude": "37.80923000",
                            "longitude": "-85.46690000"
                        },
                        {
                            "id": 111821,
                            "name": "Bardwell",
                            "latitude": "36.87061000",
                            "longitude": "-89.00979000"
                        },
                        {
                            "id": 111843,
                            "name": "Barren County",
                            "latitude": "36.96558000",
                            "longitude": "-85.93366000"
                        },
                        {
                            "id": 111901,
                            "name": "Bath County",
                            "latitude": "38.14497000",
                            "longitude": "-83.74267000"
                        },
                        {
                            "id": 111984,
                            "name": "Beattyville",
                            "latitude": "37.57175000",
                            "longitude": "-83.70686000"
                        },
                        {
                            "id": 112000,
                            "name": "Beaver Dam",
                            "latitude": "37.40199000",
                            "longitude": "-86.87583000"
                        },
                        {
                            "id": 112019,
                            "name": "Bedford",
                            "latitude": "38.59256000",
                            "longitude": "-85.31773000"
                        },
                        {
                            "id": 112043,
                            "name": "Beechwood Village",
                            "latitude": "38.25479000",
                            "longitude": "-85.63135000"
                        },
                        {
                            "id": 112068,
                            "name": "Bell County",
                            "latitude": "36.73065000",
                            "longitude": "-83.67409000"
                        },
                        {
                            "id": 112109,
                            "name": "Bellevue",
                            "latitude": "39.10645000",
                            "longitude": "-84.47883000"
                        },
                        {
                            "id": 112192,
                            "name": "Benton",
                            "latitude": "36.85728000",
                            "longitude": "-88.35031000"
                        },
                        {
                            "id": 112213,
                            "name": "Berea",
                            "latitude": "37.56869000",
                            "longitude": "-84.29632000"
                        },
                        {
                            "id": 112433,
                            "name": "Bloomfield",
                            "latitude": "37.91034000",
                            "longitude": "-85.31662000"
                        },
                        {
                            "id": 112554,
                            "name": "Boone County",
                            "latitude": "38.96986000",
                            "longitude": "-84.72787000"
                        },
                        {
                            "id": 112563,
                            "name": "Booneville",
                            "latitude": "37.47620000",
                            "longitude": "-83.67491000"
                        },
                        {
                            "id": 112614,
                            "name": "Bourbon County",
                            "latitude": "38.20673000",
                            "longitude": "-84.21715000"
                        },
                        {
                            "id": 112628,
                            "name": "Bowling Green",
                            "latitude": "36.99032000",
                            "longitude": "-86.44360000"
                        },
                        {
                            "id": 112642,
                            "name": "Boyd County",
                            "latitude": "38.35957000",
                            "longitude": "-82.68773000"
                        },
                        {
                            "id": 112648,
                            "name": "Boyle County",
                            "latitude": "37.62433000",
                            "longitude": "-84.86681000"
                        },
                        {
                            "id": 112655,
                            "name": "Bracken County",
                            "latitude": "38.68881000",
                            "longitude": "-84.09019000"
                        },
                        {
                            "id": 112686,
                            "name": "Brandenburg",
                            "latitude": "37.99896000",
                            "longitude": "-86.16941000"
                        },
                        {
                            "id": 112708,
                            "name": "Breathitt County",
                            "latitude": "37.52162000",
                            "longitude": "-83.32409000"
                        },
                        {
                            "id": 112715,
                            "name": "Breckinridge Center",
                            "latitude": "37.68282000",
                            "longitude": "-87.86308000"
                        },
                        {
                            "id": 112716,
                            "name": "Breckinridge County",
                            "latitude": "37.77327000",
                            "longitude": "-86.42928000"
                        },
                        {
                            "id": 112837,
                            "name": "Brodhead",
                            "latitude": "37.40425000",
                            "longitude": "-84.41383000"
                        },
                        {
                            "id": 112881,
                            "name": "Brooks",
                            "latitude": "38.06118000",
                            "longitude": "-85.70968000"
                        },
                        {
                            "id": 112891,
                            "name": "Brooksville",
                            "latitude": "38.68257000",
                            "longitude": "-84.06576000"
                        },
                        {
                            "id": 112929,
                            "name": "Brownsville",
                            "latitude": "37.19255000",
                            "longitude": "-86.26775000"
                        },
                        {
                            "id": 112989,
                            "name": "Buckner",
                            "latitude": "38.38368000",
                            "longitude": "-85.43996000"
                        },
                        {
                            "id": 112996,
                            "name": "Buechel",
                            "latitude": "38.19507000",
                            "longitude": "-85.65190000"
                        },
                        {
                            "id": 113032,
                            "name": "Bullitt County",
                            "latitude": "37.97008000",
                            "longitude": "-85.69586000"
                        },
                        {
                            "id": 113059,
                            "name": "Burkesville",
                            "latitude": "36.79034000",
                            "longitude": "-85.37052000"
                        },
                        {
                            "id": 113067,
                            "name": "Burlington",
                            "latitude": "39.02756000",
                            "longitude": "-84.72411000"
                        },
                        {
                            "id": 113116,
                            "name": "Butler County",
                            "latitude": "37.20728000",
                            "longitude": "-86.68176000"
                        },
                        {
                            "id": 113165,
                            "name": "Cadiz",
                            "latitude": "36.86505000",
                            "longitude": "-87.83530000"
                        },
                        {
                            "id": 113183,
                            "name": "Caldwell County",
                            "latitude": "37.14533000",
                            "longitude": "-87.86791000"
                        },
                        {
                            "id": 113198,
                            "name": "Calhoun",
                            "latitude": "37.53894000",
                            "longitude": "-87.25833000"
                        },
                        {
                            "id": 113227,
                            "name": "Calloway County",
                            "latitude": "36.62110000",
                            "longitude": "-88.27220000"
                        },
                        {
                            "id": 113234,
                            "name": "Calvert City",
                            "latitude": "37.03339000",
                            "longitude": "-88.35004000"
                        },
                        {
                            "id": 113241,
                            "name": "Camargo",
                            "latitude": "37.99425000",
                            "longitude": "-83.88770000"
                        },
                        {
                            "id": 113300,
                            "name": "Campbell County",
                            "latitude": "38.94648000",
                            "longitude": "-84.37970000"
                        },
                        {
                            "id": 113305,
                            "name": "Campbellsville",
                            "latitude": "37.34340000",
                            "longitude": "-85.34191000"
                        },
                        {
                            "id": 113310,
                            "name": "Campton",
                            "latitude": "37.73425000",
                            "longitude": "-83.54741000"
                        },
                        {
                            "id": 113402,
                            "name": "Carlisle",
                            "latitude": "38.31202000",
                            "longitude": "-84.02743000"
                        },
                        {
                            "id": 113406,
                            "name": "Carlisle County",
                            "latitude": "36.85322000",
                            "longitude": "-88.97106000"
                        },
                        {
                            "id": 113452,
                            "name": "Carroll County",
                            "latitude": "38.66786000",
                            "longitude": "-85.12359000"
                        },
                        {
                            "id": 113466,
                            "name": "Carrollton",
                            "latitude": "38.68090000",
                            "longitude": "-85.17940000"
                        },
                        {
                            "id": 113480,
                            "name": "Carter County",
                            "latitude": "38.31817000",
                            "longitude": "-83.04954000"
                        },
                        {
                            "id": 113520,
                            "name": "Casey County",
                            "latitude": "37.32228000",
                            "longitude": "-84.92837000"
                        },
                        {
                            "id": 113572,
                            "name": "Catlettsburg",
                            "latitude": "38.40480000",
                            "longitude": "-82.60044000"
                        },
                        {
                            "id": 113584,
                            "name": "Cave City",
                            "latitude": "37.13672000",
                            "longitude": "-85.95692000"
                        },
                        {
                            "id": 113666,
                            "name": "Central City",
                            "latitude": "37.29393000",
                            "longitude": "-87.12333000"
                        },
                        {
                            "id": 113996,
                            "name": "Christian County",
                            "latitude": "36.89418000",
                            "longitude": "-87.49038000"
                        },
                        {
                            "id": 114125,
                            "name": "Clark County",
                            "latitude": "37.97085000",
                            "longitude": "-84.14740000"
                        },
                        {
                            "id": 114159,
                            "name": "Claryville",
                            "latitude": "38.91923000",
                            "longitude": "-84.39549000"
                        },
                        {
                            "id": 114166,
                            "name": "Clay",
                            "latitude": "37.47671000",
                            "longitude": "-87.82002000"
                        },
                        {
                            "id": 114172,
                            "name": "Clay City",
                            "latitude": "37.85925000",
                            "longitude": "-83.91853000"
                        },
                        {
                            "id": 114180,
                            "name": "Clay County",
                            "latitude": "37.15971000",
                            "longitude": "-83.71468000"
                        },
                        {
                            "id": 114269,
                            "name": "Clinton",
                            "latitude": "36.66728000",
                            "longitude": "-88.99340000"
                        },
                        {
                            "id": 114290,
                            "name": "Clinton County",
                            "latitude": "36.72748000",
                            "longitude": "-85.13601000"
                        },
                        {
                            "id": 114314,
                            "name": "Cloverport",
                            "latitude": "37.83339000",
                            "longitude": "-86.63276000"
                        },
                        {
                            "id": 114332,
                            "name": "Coal Run Village",
                            "latitude": "37.51316000",
                            "longitude": "-82.55849000"
                        },
                        {
                            "id": 114385,
                            "name": "Cold Spring",
                            "latitude": "39.02173000",
                            "longitude": "-84.43994000"
                        },
                        {
                            "id": 114391,
                            "name": "Coldstream",
                            "latitude": "38.31479000",
                            "longitude": "-85.52385000"
                        },
                        {
                            "id": 114464,
                            "name": "Columbia",
                            "latitude": "37.10284000",
                            "longitude": "-85.30635000"
                        },
                        {
                            "id": 114631,
                            "name": "Corbin",
                            "latitude": "36.94870000",
                            "longitude": "-84.09688000"
                        },
                        {
                            "id": 114736,
                            "name": "Covington",
                            "latitude": "39.08367000",
                            "longitude": "-84.50855000"
                        },
                        {
                            "id": 114797,
                            "name": "Crescent Springs",
                            "latitude": "39.05145000",
                            "longitude": "-84.58161000"
                        },
                        {
                            "id": 114808,
                            "name": "Crestview Hills",
                            "latitude": "39.02728000",
                            "longitude": "-84.58494000"
                        },
                        {
                            "id": 114809,
                            "name": "Crestwood",
                            "latitude": "38.32424000",
                            "longitude": "-85.47246000"
                        },
                        {
                            "id": 114824,
                            "name": "Crittenden",
                            "latitude": "38.78284000",
                            "longitude": "-84.60522000"
                        },
                        {
                            "id": 114826,
                            "name": "Crittenden County",
                            "latitude": "37.35272000",
                            "longitude": "-88.09722000"
                        },
                        {
                            "id": 114906,
                            "name": "Cumberland",
                            "latitude": "36.97815000",
                            "longitude": "-82.98850000"
                        },
                        {
                            "id": 114913,
                            "name": "Cumberland County",
                            "latitude": "36.78653000",
                            "longitude": "-85.38845000"
                        },
                        {
                            "id": 114952,
                            "name": "Cynthiana",
                            "latitude": "38.39035000",
                            "longitude": "-84.29410000"
                        },
                        {
                            "id": 115033,
                            "name": "Danville",
                            "latitude": "37.64563000",
                            "longitude": "-84.77217000"
                        },
                        {
                            "id": 115070,
                            "name": "Daviess County",
                            "latitude": "37.73177000",
                            "longitude": "-87.08723000"
                        },
                        {
                            "id": 115087,
                            "name": "Dawson Springs",
                            "latitude": "37.16727000",
                            "longitude": "-87.69251000"
                        },
                        {
                            "id": 115091,
                            "name": "Dayton",
                            "latitude": "39.11284000",
                            "longitude": "-84.47272000"
                        },
                        {
                            "id": 115364,
                            "name": "Dixon",
                            "latitude": "37.51782000",
                            "longitude": "-87.69029000"
                        },
                        {
                            "id": 115383,
                            "name": "Doe Valley",
                            "latitude": "37.95665000",
                            "longitude": "-86.11653000"
                        },
                        {
                            "id": 115438,
                            "name": "Douglass Hills",
                            "latitude": "38.23785000",
                            "longitude": "-85.55274000"
                        },
                        {
                            "id": 115483,
                            "name": "Dry Ridge",
                            "latitude": "38.68201000",
                            "longitude": "-84.58994000"
                        },
                        {
                            "id": 115619,
                            "name": "Earlington",
                            "latitude": "37.27421000",
                            "longitude": "-87.51194000"
                        },
                        {
                            "id": 115826,
                            "name": "Eddyville",
                            "latitude": "37.09450000",
                            "longitude": "-88.08030000"
                        },
                        {
                            "id": 115858,
                            "name": "Edgewood",
                            "latitude": "39.01867000",
                            "longitude": "-84.58189000"
                        },
                        {
                            "id": 115879,
                            "name": "Edmonson County",
                            "latitude": "37.20884000",
                            "longitude": "-86.23862000"
                        },
                        {
                            "id": 115881,
                            "name": "Edmonton",
                            "latitude": "36.98006000",
                            "longitude": "-85.61219000"
                        },
                        {
                            "id": 115978,
                            "name": "Elizabethtown",
                            "latitude": "37.69395000",
                            "longitude": "-85.85913000"
                        },
                        {
                            "id": 115986,
                            "name": "Elk Creek",
                            "latitude": "38.10034000",
                            "longitude": "-85.37107000"
                        },
                        {
                            "id": 115996,
                            "name": "Elkfork",
                            "latitude": "37.96481000",
                            "longitude": "-83.13295000"
                        },
                        {
                            "id": 116012,
                            "name": "Elkton",
                            "latitude": "36.81004000",
                            "longitude": "-87.15417000"
                        },
                        {
                            "id": 116028,
                            "name": "Elliott County",
                            "latitude": "38.11789000",
                            "longitude": "-83.09762000"
                        },
                        {
                            "id": 116071,
                            "name": "Elsmere",
                            "latitude": "39.01256000",
                            "longitude": "-84.60467000"
                        },
                        {
                            "id": 116099,
                            "name": "Eminence",
                            "latitude": "38.37007000",
                            "longitude": "-85.18051000"
                        },
                        {
                            "id": 116172,
                            "name": "Erlanger",
                            "latitude": "39.01673000",
                            "longitude": "-84.60078000"
                        },
                        {
                            "id": 116212,
                            "name": "Estill County",
                            "latitude": "37.69248000",
                            "longitude": "-83.96433000"
                        },
                        {
                            "id": 116299,
                            "name": "Fairdale",
                            "latitude": "38.10507000",
                            "longitude": "-85.75885000"
                        },
                        {
                            "id": 116386,
                            "name": "Falmouth",
                            "latitude": "38.67674000",
                            "longitude": "-84.33021000"
                        },
                        {
                            "id": 116400,
                            "name": "Farley",
                            "latitude": "37.04634000",
                            "longitude": "-88.56856000"
                        },
                        {
                            "id": 116447,
                            "name": "Fayette County",
                            "latitude": "38.04233000",
                            "longitude": "-84.45873000"
                        },
                        {
                            "id": 116482,
                            "name": "Fern Creek",
                            "latitude": "38.15979000",
                            "longitude": "-85.58774000"
                        },
                        {
                            "id": 116557,
                            "name": "Flatwoods",
                            "latitude": "38.52258000",
                            "longitude": "-82.71711000"
                        },
                        {
                            "id": 116559,
                            "name": "Fleming County",
                            "latitude": "38.37011000",
                            "longitude": "-83.69665000"
                        },
                        {
                            "id": 116561,
                            "name": "Flemingsburg",
                            "latitude": "38.42230000",
                            "longitude": "-83.73381000"
                        },
                        {
                            "id": 116578,
                            "name": "Florence",
                            "latitude": "38.99895000",
                            "longitude": "-84.62661000"
                        },
                        {
                            "id": 116608,
                            "name": "Floyd County",
                            "latitude": "37.55711000",
                            "longitude": "-82.74570000"
                        },
                        {
                            "id": 116698,
                            "name": "Fort Campbell North",
                            "latitude": "36.65429000",
                            "longitude": "-87.46056000"
                        },
                        {
                            "id": 116721,
                            "name": "Fort Knox",
                            "latitude": "37.89113000",
                            "longitude": "-85.96363000"
                        },
                        {
                            "id": 116731,
                            "name": "Fort Mitchell",
                            "latitude": "39.05950000",
                            "longitude": "-84.54744000"
                        },
                        {
                            "id": 116754,
                            "name": "Fort Thomas",
                            "latitude": "39.07506000",
                            "longitude": "-84.44716000"
                        },
                        {
                            "id": 116765,
                            "name": "Fort Wright",
                            "latitude": "39.05173000",
                            "longitude": "-84.53411000"
                        },
                        {
                            "id": 116815,
                            "name": "Francisville",
                            "latitude": "39.10506000",
                            "longitude": "-84.72439000"
                        },
                        {
                            "id": 116818,
                            "name": "Frankfort",
                            "latitude": "38.20091000",
                            "longitude": "-84.87328000"
                        },
                        {
                            "id": 116825,
                            "name": "Franklin",
                            "latitude": "36.72226000",
                            "longitude": "-86.57722000"
                        },
                        {
                            "id": 116850,
                            "name": "Franklin County",
                            "latitude": "38.23915000",
                            "longitude": "-84.87707000"
                        },
                        {
                            "id": 116933,
                            "name": "Frenchburg",
                            "latitude": "37.95092000",
                            "longitude": "-83.62575000"
                        },
                        {
                            "id": 116985,
                            "name": "Fulton",
                            "latitude": "36.50423000",
                            "longitude": "-88.87423000"
                        },
                        {
                            "id": 116994,
                            "name": "Fulton County",
                            "latitude": "36.55408000",
                            "longitude": "-89.18761000"
                        },
                        {
                            "id": 117035,
                            "name": "Gallatin County",
                            "latitude": "38.75687000",
                            "longitude": "-84.85931000"
                        },
                        {
                            "id": 117096,
                            "name": "Garrard County",
                            "latitude": "37.63958000",
                            "longitude": "-84.53763000"
                        },
                        {
                            "id": 117157,
                            "name": "Georgetown",
                            "latitude": "38.20980000",
                            "longitude": "-84.55883000"
                        },
                        {
                            "id": 117246,
                            "name": "Glasgow",
                            "latitude": "36.99588000",
                            "longitude": "-85.91192000"
                        },
                        {
                            "id": 117509,
                            "name": "Grant County",
                            "latitude": "38.64881000",
                            "longitude": "-84.62461000"
                        },
                        {
                            "id": 117547,
                            "name": "Graves County",
                            "latitude": "36.72314000",
                            "longitude": "-88.65121000"
                        },
                        {
                            "id": 117557,
                            "name": "Graymoor-Devondale",
                            "latitude": "38.27313000",
                            "longitude": "-85.62302000"
                        },
                        {
                            "id": 117561,
                            "name": "Grayson",
                            "latitude": "38.33258000",
                            "longitude": "-82.94850000"
                        },
                        {
                            "id": 117562,
                            "name": "Grayson County",
                            "latitude": "37.46082000",
                            "longitude": "-86.34388000"
                        },
                        {
                            "id": 117592,
                            "name": "Green County",
                            "latitude": "37.26411000",
                            "longitude": "-85.55311000"
                        },
                        {
                            "id": 117668,
                            "name": "Greensburg",
                            "latitude": "37.26089000",
                            "longitude": "-85.49885000"
                        },
                        {
                            "id": 117677,
                            "name": "Greenup",
                            "latitude": "38.57313000",
                            "longitude": "-82.83017000"
                        },
                        {
                            "id": 117678,
                            "name": "Greenup County",
                            "latitude": "38.54566000",
                            "longitude": "-82.92229000"
                        },
                        {
                            "id": 117682,
                            "name": "Greenville",
                            "latitude": "37.20115000",
                            "longitude": "-87.17889000"
                        },
                        {
                            "id": 117811,
                            "name": "Guthrie",
                            "latitude": "36.64838000",
                            "longitude": "-87.16639000"
                        },
                        {
                            "id": 117952,
                            "name": "Hancock County",
                            "latitude": "37.84151000",
                            "longitude": "-86.77793000"
                        },
                        {
                            "id": 118000,
                            "name": "Hardin County",
                            "latitude": "37.69792000",
                            "longitude": "-85.96337000"
                        },
                        {
                            "id": 118006,
                            "name": "Hardinsburg",
                            "latitude": "37.78006000",
                            "longitude": "-86.46053000"
                        },
                        {
                            "id": 118015,
                            "name": "Harlan",
                            "latitude": "36.84314000",
                            "longitude": "-83.32185000"
                        },
                        {
                            "id": 118018,
                            "name": "Harlan County",
                            "latitude": "36.85697000",
                            "longitude": "-83.21795000"
                        },
                        {
                            "id": 118063,
                            "name": "Harrison County",
                            "latitude": "38.44181000",
                            "longitude": "-84.33139000"
                        },
                        {
                            "id": 118078,
                            "name": "Harrodsburg",
                            "latitude": "37.76230000",
                            "longitude": "-84.84329000"
                        },
                        {
                            "id": 118083,
                            "name": "Hart County",
                            "latitude": "37.29993000",
                            "longitude": "-85.88471000"
                        },
                        {
                            "id": 118086,
                            "name": "Hartford",
                            "latitude": "37.45116000",
                            "longitude": "-86.90916000"
                        },
                        {
                            "id": 118164,
                            "name": "Hawesville",
                            "latitude": "37.90006000",
                            "longitude": "-86.75499000"
                        },
                        {
                            "id": 118200,
                            "name": "Hazard",
                            "latitude": "37.24954000",
                            "longitude": "-83.19323000"
                        },
                        {
                            "id": 118232,
                            "name": "Hebron",
                            "latitude": "39.06589000",
                            "longitude": "-84.70106000"
                        },
                        {
                            "id": 118240,
                            "name": "Hebron Estates",
                            "latitude": "38.04951000",
                            "longitude": "-85.66607000"
                        },
                        {
                            "id": 118274,
                            "name": "Henderson",
                            "latitude": "37.83615000",
                            "longitude": "-87.59001000"
                        },
                        {
                            "id": 118280,
                            "name": "Henderson County",
                            "latitude": "37.79590000",
                            "longitude": "-87.57316000"
                        },
                        {
                            "id": 118288,
                            "name": "Hendron",
                            "latitude": "37.03950000",
                            "longitude": "-88.62922000"
                        },
                        {
                            "id": 118300,
                            "name": "Henry County",
                            "latitude": "38.44847000",
                            "longitude": "-85.11893000"
                        },
                        {
                            "id": 118317,
                            "name": "Heritage Creek",
                            "latitude": "38.12368000",
                            "longitude": "-85.71968000"
                        },
                        {
                            "id": 118361,
                            "name": "Hickman",
                            "latitude": "36.57117000",
                            "longitude": "-89.18618000"
                        },
                        {
                            "id": 118363,
                            "name": "Hickman County",
                            "latitude": "36.67817000",
                            "longitude": "-88.97622000"
                        },
                        {
                            "id": 118406,
                            "name": "Highland Heights",
                            "latitude": "39.03312000",
                            "longitude": "-84.45189000"
                        },
                        {
                            "id": 118425,
                            "name": "Highview",
                            "latitude": "38.14285000",
                            "longitude": "-85.62413000"
                        },
                        {
                            "id": 118470,
                            "name": "Hillview",
                            "latitude": "38.06979000",
                            "longitude": "-85.68551000"
                        },
                        {
                            "id": 118478,
                            "name": "Hindman",
                            "latitude": "37.33593000",
                            "longitude": "-82.98044000"
                        },
                        {
                            "id": 118508,
                            "name": "Hodgenville",
                            "latitude": "37.57395000",
                            "longitude": "-85.73996000"
                        },
                        {
                            "id": 118653,
                            "name": "Hopkins County",
                            "latitude": "37.30882000",
                            "longitude": "-87.54084000"
                        },
                        {
                            "id": 118655,
                            "name": "Hopkinsville",
                            "latitude": "36.86561000",
                            "longitude": "-87.49117000"
                        },
                        {
                            "id": 118670,
                            "name": "Horse Cave",
                            "latitude": "37.17950000",
                            "longitude": "-85.90692000"
                        },
                        {
                            "id": 118830,
                            "name": "Hurstbourne",
                            "latitude": "38.23813000",
                            "longitude": "-85.58829000"
                        },
                        {
                            "id": 118831,
                            "name": "Hurstbourne Acres",
                            "latitude": "38.22118000",
                            "longitude": "-85.58913000"
                        },
                        {
                            "id": 118854,
                            "name": "Hyden",
                            "latitude": "37.16093000",
                            "longitude": "-83.37324000"
                        },
                        {
                            "id": 118890,
                            "name": "Independence",
                            "latitude": "38.94312000",
                            "longitude": "-84.54411000"
                        },
                        {
                            "id": 118906,
                            "name": "Indian Hills",
                            "latitude": "38.27257000",
                            "longitude": "-85.66274000"
                        },
                        {
                            "id": 118910,
                            "name": "Indian Hills Cherokee Section",
                            "latitude": "38.27951000",
                            "longitude": "-85.64996000"
                        },
                        {
                            "id": 118931,
                            "name": "Inez",
                            "latitude": "37.86648000",
                            "longitude": "-82.53876000"
                        },
                        {
                            "id": 118998,
                            "name": "Ironville",
                            "latitude": "38.45647000",
                            "longitude": "-82.69238000"
                        },
                        {
                            "id": 119003,
                            "name": "Irvine",
                            "latitude": "37.70064000",
                            "longitude": "-83.97381000"
                        },
                        {
                            "id": 119007,
                            "name": "Irvington",
                            "latitude": "37.88034000",
                            "longitude": "-86.28386000"
                        },
                        {
                            "id": 119062,
                            "name": "Jackson",
                            "latitude": "37.55315000",
                            "longitude": "-83.38351000"
                        },
                        {
                            "id": 119082,
                            "name": "Jackson County",
                            "latitude": "37.41978000",
                            "longitude": "-84.00577000"
                        },
                        {
                            "id": 119120,
                            "name": "Jamestown",
                            "latitude": "36.98479000",
                            "longitude": "-85.06301000"
                        },
                        {
                            "id": 119182,
                            "name": "Jefferson County",
                            "latitude": "38.18719000",
                            "longitude": "-85.65916000"
                        },
                        {
                            "id": 119205,
                            "name": "Jeffersontown",
                            "latitude": "38.19424000",
                            "longitude": "-85.56440000"
                        },
                        {
                            "id": 119206,
                            "name": "Jeffersonville",
                            "latitude": "37.97369000",
                            "longitude": "-83.84186000"
                        },
                        {
                            "id": 119214,
                            "name": "Jenkins",
                            "latitude": "37.17344000",
                            "longitude": "-82.63099000"
                        },
                        {
                            "id": 119236,
                            "name": "Jessamine County",
                            "latitude": "37.87203000",
                            "longitude": "-84.58093000"
                        },
                        {
                            "id": 119265,
                            "name": "Johnson County",
                            "latitude": "37.84664000",
                            "longitude": "-82.83154000"
                        },
                        {
                            "id": 119333,
                            "name": "Junction City",
                            "latitude": "37.58674000",
                            "longitude": "-84.79384000"
                        },
                        {
                            "id": 119499,
                            "name": "Kenton County",
                            "latitude": "38.93346000",
                            "longitude": "-84.53334000"
                        },
                        {
                            "id": 119680,
                            "name": "Knott County",
                            "latitude": "37.35405000",
                            "longitude": "-82.95413000"
                        },
                        {
                            "id": 119681,
                            "name": "Knottsville",
                            "latitude": "37.77172000",
                            "longitude": "-86.90416000"
                        },
                        {
                            "id": 119686,
                            "name": "Knox County",
                            "latitude": "36.89067000",
                            "longitude": "-83.85404000"
                        },
                        {
                            "id": 119737,
                            "name": "La Center",
                            "latitude": "37.07672000",
                            "longitude": "-88.97368000"
                        },
                        {
                            "id": 119749,
                            "name": "La Grange",
                            "latitude": "38.40757000",
                            "longitude": "-85.37885000"
                        },
                        {
                            "id": 120035,
                            "name": "Lakeside Park",
                            "latitude": "39.03562000",
                            "longitude": "-84.56911000"
                        },
                        {
                            "id": 120080,
                            "name": "Lancaster",
                            "latitude": "37.61952000",
                            "longitude": "-84.57800000"
                        },
                        {
                            "id": 120152,
                            "name": "Larue County",
                            "latitude": "37.54580000",
                            "longitude": "-85.69792000"
                        },
                        {
                            "id": 120195,
                            "name": "Laurel County",
                            "latitude": "37.11067000",
                            "longitude": "-84.11780000"
                        },
                        {
                            "id": 120233,
                            "name": "Lawrence County",
                            "latitude": "38.06788000",
                            "longitude": "-82.73475000"
                        },
                        {
                            "id": 120241,
                            "name": "Lawrenceburg",
                            "latitude": "38.03730000",
                            "longitude": "-84.89662000"
                        },
                        {
                            "id": 120285,
                            "name": "Lebanon",
                            "latitude": "37.56979000",
                            "longitude": "-85.25274000"
                        },
                        {
                            "id": 120296,
                            "name": "Lebanon Junction",
                            "latitude": "37.83451000",
                            "longitude": "-85.73190000"
                        },
                        {
                            "id": 120301,
                            "name": "Ledbetter",
                            "latitude": "37.04756000",
                            "longitude": "-88.47699000"
                        },
                        {
                            "id": 120310,
                            "name": "Lee County",
                            "latitude": "37.59480000",
                            "longitude": "-83.71628000"
                        },
                        {
                            "id": 120347,
                            "name": "Leitchfield",
                            "latitude": "37.48005000",
                            "longitude": "-86.29386000"
                        },
                        {
                            "id": 120397,
                            "name": "Leslie County",
                            "latitude": "37.09406000",
                            "longitude": "-83.38116000"
                        },
                        {
                            "id": 120400,
                            "name": "Letcher County",
                            "latitude": "37.12119000",
                            "longitude": "-82.85528000"
                        },
                        {
                            "id": 120411,
                            "name": "Lewis County",
                            "latitude": "38.53154000",
                            "longitude": "-83.37805000"
                        },
                        {
                            "id": 120422,
                            "name": "Lewisport",
                            "latitude": "37.93700000",
                            "longitude": "-86.90221000"
                        },
                        {
                            "id": 120439,
                            "name": "Lexington",
                            "latitude": "37.98869000",
                            "longitude": "-84.47772000"
                        },
                        {
                            "id": 120456,
                            "name": "Lexington-Fayette",
                            "latitude": "38.04980000",
                            "longitude": "-84.45855000"
                        },
                        {
                            "id": 120459,
                            "name": "Liberty",
                            "latitude": "37.31841000",
                            "longitude": "-84.93940000"
                        },
                        {
                            "id": 120519,
                            "name": "Lincoln County",
                            "latitude": "37.45535000",
                            "longitude": "-84.66081000"
                        },
                        {
                            "id": 120642,
                            "name": "Livermore",
                            "latitude": "37.49310000",
                            "longitude": "-87.13194000"
                        },
                        {
                            "id": 120654,
                            "name": "Livingston County",
                            "latitude": "37.20970000",
                            "longitude": "-88.35381000"
                        },
                        {
                            "id": 120697,
                            "name": "Logan County",
                            "latitude": "36.85970000",
                            "longitude": "-86.87894000"
                        },
                        {
                            "id": 120721,
                            "name": "London",
                            "latitude": "37.12898000",
                            "longitude": "-84.08326000"
                        },
                        {
                            "id": 120804,
                            "name": "Louisa",
                            "latitude": "38.11425000",
                            "longitude": "-82.60321000"
                        },
                        {
                            "id": 120813,
                            "name": "Louisville",
                            "latitude": "38.25424000",
                            "longitude": "-85.75941000"
                        },
                        {
                            "id": 120869,
                            "name": "Ludlow",
                            "latitude": "39.09256000",
                            "longitude": "-84.54744000"
                        },
                        {
                            "id": 120923,
                            "name": "Lyndon",
                            "latitude": "38.25674000",
                            "longitude": "-85.60163000"
                        },
                        {
                            "id": 120936,
                            "name": "Lyon County",
                            "latitude": "37.01913000",
                            "longitude": "-88.08328000"
                        },
                        {
                            "id": 121007,
                            "name": "Madison County",
                            "latitude": "37.72018000",
                            "longitude": "-84.27800000"
                        },
                        {
                            "id": 121025,
                            "name": "Madisonville",
                            "latitude": "37.32810000",
                            "longitude": "-87.49889000"
                        },
                        {
                            "id": 121039,
                            "name": "Magoffin County",
                            "latitude": "37.70647000",
                            "longitude": "-83.06491000"
                        },
                        {
                            "id": 121091,
                            "name": "Manchester",
                            "latitude": "37.15370000",
                            "longitude": "-83.76186000"
                        },
                        {
                            "id": 121235,
                            "name": "Marion",
                            "latitude": "37.33283000",
                            "longitude": "-88.08113000"
                        },
                        {
                            "id": 121254,
                            "name": "Marion County",
                            "latitude": "37.55253000",
                            "longitude": "-85.26963000"
                        },
                        {
                            "id": 121312,
                            "name": "Marshall County",
                            "latitude": "36.88345000",
                            "longitude": "-88.32938000"
                        },
                        {
                            "id": 121339,
                            "name": "Martin County",
                            "latitude": "37.80158000",
                            "longitude": "-82.51329000"
                        },
                        {
                            "id": 121377,
                            "name": "Mason County",
                            "latitude": "38.59517000",
                            "longitude": "-83.82425000"
                        },
                        {
                            "id": 121385,
                            "name": "Masonville",
                            "latitude": "37.67505000",
                            "longitude": "-87.03472000"
                        },
                        {
                            "id": 121387,
                            "name": "Massac",
                            "latitude": "37.01672000",
                            "longitude": "-88.73061000"
                        },
                        {
                            "id": 121436,
                            "name": "Mayfield",
                            "latitude": "36.74172000",
                            "longitude": "-88.63672000"
                        },
                        {
                            "id": 121450,
                            "name": "Maysville",
                            "latitude": "38.64119000",
                            "longitude": "-83.74437000"
                        },
                        {
                            "id": 121485,
                            "name": "McCracken County",
                            "latitude": "37.05408000",
                            "longitude": "-88.71272000"
                        },
                        {
                            "id": 121486,
                            "name": "McCreary County",
                            "latitude": "36.73714000",
                            "longitude": "-84.48417000"
                        },
                        {
                            "id": 121517,
                            "name": "McKee",
                            "latitude": "37.43036000",
                            "longitude": "-83.99798000"
                        },
                        {
                            "id": 121528,
                            "name": "McLean County",
                            "latitude": "37.52919000",
                            "longitude": "-87.26361000"
                        },
                        {
                            "id": 121556,
                            "name": "Meade County",
                            "latitude": "37.96984000",
                            "longitude": "-86.21718000"
                        },
                        {
                            "id": 121572,
                            "name": "Meads",
                            "latitude": "38.41258000",
                            "longitude": "-82.70905000"
                        },
                        {
                            "id": 121660,
                            "name": "Menifee County",
                            "latitude": "37.94138000",
                            "longitude": "-83.59887000"
                        },
                        {
                            "id": 121675,
                            "name": "Mercer County",
                            "latitude": "37.81103000",
                            "longitude": "-84.87444000"
                        },
                        {
                            "id": 121733,
                            "name": "Metcalfe County",
                            "latitude": "36.99054000",
                            "longitude": "-85.62925000"
                        },
                        {
                            "id": 121776,
                            "name": "Middlesboro",
                            "latitude": "36.60842000",
                            "longitude": "-83.71658000"
                        },
                        {
                            "id": 121787,
                            "name": "Middletown",
                            "latitude": "38.24535000",
                            "longitude": "-85.53885000"
                        },
                        {
                            "id": 121814,
                            "name": "Midway",
                            "latitude": "38.15091000",
                            "longitude": "-84.68383000"
                        },
                        {
                            "id": 122073,
                            "name": "Monroe County",
                            "latitude": "36.71218000",
                            "longitude": "-85.71652000"
                        },
                        {
                            "id": 122144,
                            "name": "Montgomery County",
                            "latitude": "38.03353000",
                            "longitude": "-83.91310000"
                        },
                        {
                            "id": 122162,
                            "name": "Monticello",
                            "latitude": "36.82979000",
                            "longitude": "-84.84911000"
                        },
                        {
                            "id": 122225,
                            "name": "Morehead",
                            "latitude": "38.18397000",
                            "longitude": "-83.43268000"
                        },
                        {
                            "id": 122240,
                            "name": "Morgan County",
                            "latitude": "37.92228000",
                            "longitude": "-83.25889000"
                        },
                        {
                            "id": 122248,
                            "name": "Morganfield",
                            "latitude": "37.68338000",
                            "longitude": "-87.91669000"
                        },
                        {
                            "id": 122251,
                            "name": "Morgantown",
                            "latitude": "37.22560000",
                            "longitude": "-86.68360000"
                        },
                        {
                            "id": 122371,
                            "name": "Mount Olivet",
                            "latitude": "38.53146000",
                            "longitude": "-84.03687000"
                        },
                        {
                            "id": 122391,
                            "name": "Mount Sterling",
                            "latitude": "38.05647000",
                            "longitude": "-83.94326000"
                        },
                        {
                            "id": 122397,
                            "name": "Mount Vernon",
                            "latitude": "37.35286000",
                            "longitude": "-84.34049000"
                        },
                        {
                            "id": 122406,
                            "name": "Mount Washington",
                            "latitude": "38.05006000",
                            "longitude": "-85.54579000"
                        },
                        {
                            "id": 122448,
                            "name": "Muhlenberg County",
                            "latitude": "37.21579000",
                            "longitude": "-87.14204000"
                        },
                        {
                            "id": 122472,
                            "name": "Munfordville",
                            "latitude": "37.27228000",
                            "longitude": "-85.89108000"
                        },
                        {
                            "id": 122491,
                            "name": "Murray",
                            "latitude": "36.61033000",
                            "longitude": "-88.31476000"
                        },
                        {
                            "id": 122626,
                            "name": "Nelson County",
                            "latitude": "37.80513000",
                            "longitude": "-85.46599000"
                        },
                        {
                            "id": 122685,
                            "name": "New Castle",
                            "latitude": "38.43340000",
                            "longitude": "-85.16968000"
                        },
                        {
                            "id": 122814,
                            "name": "Newburg",
                            "latitude": "38.16007000",
                            "longitude": "-85.65968000"
                        },
                        {
                            "id": 122841,
                            "name": "Newport",
                            "latitude": "39.09145000",
                            "longitude": "-84.49578000"
                        },
                        {
                            "id": 122887,
                            "name": "Nicholas County",
                            "latitude": "38.33560000",
                            "longitude": "-84.01533000"
                        },
                        {
                            "id": 122889,
                            "name": "Nicholasville",
                            "latitude": "37.88063000",
                            "longitude": "-84.57300000"
                        },
                        {
                            "id": 123010,
                            "name": "North Corbin",
                            "latitude": "36.96064000",
                            "longitude": "-84.09326000"
                        },
                        {
                            "id": 123148,
                            "name": "Northfield",
                            "latitude": "38.28701000",
                            "longitude": "-85.64107000"
                        },
                        {
                            "id": 123188,
                            "name": "Nortonville",
                            "latitude": "37.19088000",
                            "longitude": "-87.45278000"
                        },
                        {
                            "id": 123234,
                            "name": "Oak Grove",
                            "latitude": "36.66505000",
                            "longitude": "-87.44279000"
                        },
                        {
                            "id": 123268,
                            "name": "Oakbrook",
                            "latitude": "38.99978000",
                            "longitude": "-84.68522000"
                        },
                        {
                            "id": 123383,
                            "name": "Ohio County",
                            "latitude": "37.47819000",
                            "longitude": "-86.84889000"
                        },
                        {
                            "id": 123408,
                            "name": "Okolona",
                            "latitude": "38.14118000",
                            "longitude": "-85.68774000"
                        },
                        {
                            "id": 123432,
                            "name": "Oldham County",
                            "latitude": "38.39944000",
                            "longitude": "-85.44837000"
                        },
                        {
                            "id": 123440,
                            "name": "Olive Hill",
                            "latitude": "38.30008000",
                            "longitude": "-83.17407000"
                        },
                        {
                            "id": 123533,
                            "name": "Orchard Grass Hills",
                            "latitude": "38.32368000",
                            "longitude": "-85.52135000"
                        },
                        {
                            "id": 123662,
                            "name": "Owen County",
                            "latitude": "38.51964000",
                            "longitude": "-84.82811000"
                        },
                        {
                            "id": 123664,
                            "name": "Owensboro",
                            "latitude": "37.77422000",
                            "longitude": "-87.11333000"
                        },
                        {
                            "id": 123667,
                            "name": "Owenton",
                            "latitude": "38.53672000",
                            "longitude": "-84.84338000"
                        },
                        {
                            "id": 123670,
                            "name": "Owingsville",
                            "latitude": "38.14480000",
                            "longitude": "-83.76408000"
                        },
                        {
                            "id": 123673,
                            "name": "Owsley County",
                            "latitude": "37.41920000",
                            "longitude": "-83.68311000"
                        },
                        {
                            "id": 123715,
                            "name": "Paducah",
                            "latitude": "37.08339000",
                            "longitude": "-88.60005000"
                        },
                        {
                            "id": 123728,
                            "name": "Paintsville",
                            "latitude": "37.81454000",
                            "longitude": "-82.80711000"
                        },
                        {
                            "id": 123831,
                            "name": "Paris",
                            "latitude": "38.20980000",
                            "longitude": "-84.25299000"
                        },
                        {
                            "id": 123849,
                            "name": "Park Hills",
                            "latitude": "39.07145000",
                            "longitude": "-84.53217000"
                        },
                        {
                            "id": 124019,
                            "name": "Pendleton County",
                            "latitude": "38.69563000",
                            "longitude": "-84.36027000"
                        },
                        {
                            "id": 124078,
                            "name": "Perry County",
                            "latitude": "37.24429000",
                            "longitude": "-83.22148000"
                        },
                        {
                            "id": 124116,
                            "name": "Pewee Valley",
                            "latitude": "38.31062000",
                            "longitude": "-85.48746000"
                        },
                        {
                            "id": 124190,
                            "name": "Pike County",
                            "latitude": "37.46902000",
                            "longitude": "-82.39587000"
                        },
                        {
                            "id": 124198,
                            "name": "Pikeville",
                            "latitude": "37.47927000",
                            "longitude": "-82.51876000"
                        },
                        {
                            "id": 124232,
                            "name": "Pine Knot",
                            "latitude": "36.65091000",
                            "longitude": "-84.43855000"
                        },
                        {
                            "id": 124259,
                            "name": "Pineville",
                            "latitude": "36.76203000",
                            "longitude": "-83.69492000"
                        },
                        {
                            "id": 124275,
                            "name": "Pioneer Village",
                            "latitude": "38.06062000",
                            "longitude": "-85.67774000"
                        },
                        {
                            "id": 124342,
                            "name": "Plano",
                            "latitude": "36.88032000",
                            "longitude": "-86.41832000"
                        },
                        {
                            "id": 124389,
                            "name": "Pleasure Ridge Park",
                            "latitude": "38.14535000",
                            "longitude": "-85.85830000"
                        },
                        {
                            "id": 124612,
                            "name": "Powell County",
                            "latitude": "37.83115000",
                            "longitude": "-83.82377000"
                        },
                        {
                            "id": 124661,
                            "name": "Prestonsburg",
                            "latitude": "37.66565000",
                            "longitude": "-82.77155000"
                        },
                        {
                            "id": 124682,
                            "name": "Princeton",
                            "latitude": "37.10922000",
                            "longitude": "-87.88196000"
                        },
                        {
                            "id": 124705,
                            "name": "Prospect",
                            "latitude": "38.34507000",
                            "longitude": "-85.61552000"
                        },
                        {
                            "id": 124715,
                            "name": "Providence",
                            "latitude": "38.57451000",
                            "longitude": "-85.22107000"
                        },
                        {
                            "id": 124740,
                            "name": "Pulaski County",
                            "latitude": "37.10393000",
                            "longitude": "-84.57718000"
                        },
                        {
                            "id": 124812,
                            "name": "Raceland",
                            "latitude": "38.54008000",
                            "longitude": "-82.72850000"
                        },
                        {
                            "id": 124815,
                            "name": "Radcliff",
                            "latitude": "37.84035000",
                            "longitude": "-85.94913000"
                        },
                        {
                            "id": 125006,
                            "name": "Reidland",
                            "latitude": "37.01756000",
                            "longitude": "-88.53143000"
                        },
                        {
                            "id": 125085,
                            "name": "Richmond",
                            "latitude": "37.74786000",
                            "longitude": "-84.29465000"
                        },
                        {
                            "id": 125245,
                            "name": "Robertson County",
                            "latitude": "38.51882000",
                            "longitude": "-84.05203000"
                        },
                        {
                            "id": 125293,
                            "name": "Rockcastle County",
                            "latitude": "37.36518000",
                            "longitude": "-84.31594000"
                        },
                        {
                            "id": 125477,
                            "name": "Rowan County",
                            "latitude": "38.19626000",
                            "longitude": "-83.42108000"
                        },
                        {
                            "id": 125534,
                            "name": "Russell",
                            "latitude": "38.51730000",
                            "longitude": "-82.69766000"
                        },
                        {
                            "id": 125538,
                            "name": "Russell County",
                            "latitude": "36.99103000",
                            "longitude": "-85.05869000"
                        },
                        {
                            "id": 125540,
                            "name": "Russell Springs",
                            "latitude": "37.05618000",
                            "longitude": "-85.08857000"
                        },
                        {
                            "id": 125544,
                            "name": "Russellville",
                            "latitude": "36.84532000",
                            "longitude": "-86.88722000"
                        },
                        {
                            "id": 125565,
                            "name": "Ryland Heights",
                            "latitude": "38.95756000",
                            "longitude": "-84.46300000"
                        },
                        {
                            "id": 125631,
                            "name": "Saint Dennis",
                            "latitude": "38.18840000",
                            "longitude": "-85.84580000"
                        },
                        {
                            "id": 125695,
                            "name": "Saint Matthews",
                            "latitude": "38.25285000",
                            "longitude": "-85.65579000"
                        },
                        {
                            "id": 125711,
                            "name": "Saint Regis Park",
                            "latitude": "38.22674000",
                            "longitude": "-85.61663000"
                        },
                        {
                            "id": 125776,
                            "name": "Salyersville",
                            "latitude": "37.75259000",
                            "longitude": "-83.06878000"
                        },
                        {
                            "id": 125883,
                            "name": "Sandy Hook",
                            "latitude": "38.08647000",
                            "longitude": "-83.12628000"
                        },
                        {
                            "id": 126046,
                            "name": "Scott County",
                            "latitude": "38.29156000",
                            "longitude": "-84.58393000"
                        },
                        {
                            "id": 126064,
                            "name": "Scottsville",
                            "latitude": "36.75338000",
                            "longitude": "-86.19054000"
                        },
                        {
                            "id": 126110,
                            "name": "Sebree",
                            "latitude": "37.60699000",
                            "longitude": "-87.52862000"
                        },
                        {
                            "id": 126275,
                            "name": "Shelby County",
                            "latitude": "38.21544000",
                            "longitude": "-85.19477000"
                        },
                        {
                            "id": 126282,
                            "name": "Shelbyville",
                            "latitude": "38.21201000",
                            "longitude": "-85.22357000"
                        },
                        {
                            "id": 126312,
                            "name": "Shepherdsville",
                            "latitude": "37.98840000",
                            "longitude": "-85.71579000"
                        },
                        {
                            "id": 126362,
                            "name": "Shively",
                            "latitude": "38.20007000",
                            "longitude": "-85.82274000"
                        },
                        {
                            "id": 126425,
                            "name": "Silver Grove",
                            "latitude": "39.03451000",
                            "longitude": "-84.39022000"
                        },
                        {
                            "id": 126449,
                            "name": "Simpson County",
                            "latitude": "36.74194000",
                            "longitude": "-86.58232000"
                        },
                        {
                            "id": 126451,
                            "name": "Simpsonville",
                            "latitude": "38.22257000",
                            "longitude": "-85.35523000"
                        },
                        {
                            "id": 126522,
                            "name": "Smithland",
                            "latitude": "37.13894000",
                            "longitude": "-88.40337000"
                        },
                        {
                            "id": 126580,
                            "name": "Somerset",
                            "latitude": "37.09202000",
                            "longitude": "-84.60411000"
                        },
                        {
                            "id": 126728,
                            "name": "South Shore",
                            "latitude": "38.72091000",
                            "longitude": "-82.95823000"
                        },
                        {
                            "id": 126774,
                            "name": "Southgate",
                            "latitude": "39.07200000",
                            "longitude": "-84.47272000"
                        },
                        {
                            "id": 126829,
                            "name": "Spencer County",
                            "latitude": "38.03251000",
                            "longitude": "-85.32785000"
                        },
                        {
                            "id": 126892,
                            "name": "Springfield",
                            "latitude": "37.68534000",
                            "longitude": "-85.22218000"
                        },
                        {
                            "id": 126953,
                            "name": "Stanford",
                            "latitude": "37.53119000",
                            "longitude": "-84.66189000"
                        },
                        {
                            "id": 126966,
                            "name": "Stanton",
                            "latitude": "37.84564000",
                            "longitude": "-83.85825000"
                        },
                        {
                            "id": 127004,
                            "name": "Stearns",
                            "latitude": "36.69897000",
                            "longitude": "-84.47744000"
                        },
                        {
                            "id": 127150,
                            "name": "Sturgis",
                            "latitude": "37.54671000",
                            "longitude": "-87.98391000"
                        },
                        {
                            "id": 127453,
                            "name": "Taylor County",
                            "latitude": "37.36646000",
                            "longitude": "-85.32784000"
                        },
                        {
                            "id": 127460,
                            "name": "Taylor Mill",
                            "latitude": "38.99756000",
                            "longitude": "-84.49633000"
                        },
                        {
                            "id": 127463,
                            "name": "Taylorsville",
                            "latitude": "38.03173000",
                            "longitude": "-85.34245000"
                        },
                        {
                            "id": 127692,
                            "name": "Todd County",
                            "latitude": "36.83556000",
                            "longitude": "-87.17915000"
                        },
                        {
                            "id": 127714,
                            "name": "Tompkinsville",
                            "latitude": "36.70228000",
                            "longitude": "-85.69164000"
                        },
                        {
                            "id": 127821,
                            "name": "Trigg County",
                            "latitude": "36.80632000",
                            "longitude": "-87.87337000"
                        },
                        {
                            "id": 127822,
                            "name": "Trimble County",
                            "latitude": "38.61303000",
                            "longitude": "-85.33757000"
                        },
                        {
                            "id": 127971,
                            "name": "Union",
                            "latitude": "38.94590000",
                            "longitude": "-84.68050000"
                        },
                        {
                            "id": 127993,
                            "name": "Union County",
                            "latitude": "37.65845000",
                            "longitude": "-87.94538000"
                        },
                        {
                            "id": 128116,
                            "name": "Valley Station",
                            "latitude": "38.11118000",
                            "longitude": "-85.87024000"
                        },
                        {
                            "id": 128134,
                            "name": "Van Lear",
                            "latitude": "37.77121000",
                            "longitude": "-82.75794000"
                        },
                        {
                            "id": 128142,
                            "name": "Vanceburg",
                            "latitude": "38.59924000",
                            "longitude": "-83.31880000"
                        },
                        {
                            "id": 128200,
                            "name": "Verona",
                            "latitude": "38.81840000",
                            "longitude": "-84.66078000"
                        },
                        {
                            "id": 128209,
                            "name": "Versailles",
                            "latitude": "38.05258000",
                            "longitude": "-84.72995000"
                        },
                        {
                            "id": 128242,
                            "name": "Villa Hills",
                            "latitude": "39.06339000",
                            "longitude": "-84.59300000"
                        },
                        {
                            "id": 128268,
                            "name": "Vine Grove",
                            "latitude": "37.81007000",
                            "longitude": "-85.98135000"
                        },
                        {
                            "id": 128434,
                            "name": "Walton",
                            "latitude": "38.87562000",
                            "longitude": "-84.61022000"
                        },
                        {
                            "id": 128487,
                            "name": "Warren County",
                            "latitude": "36.99358000",
                            "longitude": "-86.42380000"
                        },
                        {
                            "id": 128515,
                            "name": "Warsaw",
                            "latitude": "38.78340000",
                            "longitude": "-84.90162000"
                        },
                        {
                            "id": 128559,
                            "name": "Washington County",
                            "latitude": "37.75338000",
                            "longitude": "-85.17475000"
                        },
                        {
                            "id": 128642,
                            "name": "Watterson Park",
                            "latitude": "38.19229000",
                            "longitude": "-85.68329000"
                        },
                        {
                            "id": 128692,
                            "name": "Wayne County",
                            "latitude": "36.80127000",
                            "longitude": "-84.82863000"
                        },
                        {
                            "id": 128737,
                            "name": "Webster County",
                            "latitude": "37.51842000",
                            "longitude": "-87.68316000"
                        },
                        {
                            "id": 128837,
                            "name": "West Buechel",
                            "latitude": "38.19701000",
                            "longitude": "-85.66329000"
                        },
                        {
                            "id": 128916,
                            "name": "West Liberty",
                            "latitude": "37.92148000",
                            "longitude": "-83.25962000"
                        },
                        {
                            "id": 129083,
                            "name": "Westwood",
                            "latitude": "38.48314000",
                            "longitude": "-82.66988000"
                        },
                        {
                            "id": 129185,
                            "name": "Whitesburg",
                            "latitude": "37.11843000",
                            "longitude": "-82.82683000"
                        },
                        {
                            "id": 129199,
                            "name": "Whitley City",
                            "latitude": "36.72341000",
                            "longitude": "-84.47049000"
                        },
                        {
                            "id": 129200,
                            "name": "Whitley County",
                            "latitude": "36.75807000",
                            "longitude": "-84.14518000"
                        },
                        {
                            "id": 129221,
                            "name": "Wickliffe",
                            "latitude": "36.96478000",
                            "longitude": "-89.08923000"
                        },
                        {
                            "id": 129231,
                            "name": "Wilder",
                            "latitude": "39.05645000",
                            "longitude": "-84.48689000"
                        },
                        {
                            "id": 129266,
                            "name": "Williamsburg",
                            "latitude": "36.74342000",
                            "longitude": "-84.15966000"
                        },
                        {
                            "id": 129285,
                            "name": "Williamstown",
                            "latitude": "38.63812000",
                            "longitude": "-84.56050000"
                        },
                        {
                            "id": 129327,
                            "name": "Wilmore",
                            "latitude": "37.86202000",
                            "longitude": "-84.66161000"
                        },
                        {
                            "id": 129353,
                            "name": "Winchester",
                            "latitude": "37.99008000",
                            "longitude": "-84.17965000"
                        },
                        {
                            "id": 129391,
                            "name": "Windy Hills",
                            "latitude": "38.27396000",
                            "longitude": "-85.63441000"
                        },
                        {
                            "id": 129477,
                            "name": "Wolfe County",
                            "latitude": "37.73932000",
                            "longitude": "-83.49318000"
                        },
                        {
                            "id": 129519,
                            "name": "Woodford County",
                            "latitude": "38.04239000",
                            "longitude": "-84.74359000"
                        },
                        {
                            "id": 129597,
                            "name": "Worthington",
                            "latitude": "38.54841000",
                            "longitude": "-82.72433000"
                        },
                        {
                            "id": 129599,
                            "name": "Worthington Hills",
                            "latitude": "38.30896000",
                            "longitude": "-85.52690000"
                        },
                        {
                            "id": 129618,
                            "name": "Wurtland",
                            "latitude": "38.55036000",
                            "longitude": "-82.77794000"
                        }
                    ]
                },
                {
                    "id": 1403,
                    "name": "Kingman Reef",
                    "state_code": "UM-89",
                    "cities": []
                },
                {
                    "id": 1457,
                    "name": "Louisiana",
                    "state_code": "LA",
                    "cities": [
                        {
                            "id": 110966,
                            "name": "Abbeville",
                            "latitude": "29.97465000",
                            "longitude": "-92.13429000"
                        },
                        {
                            "id": 110987,
                            "name": "Abita Springs",
                            "latitude": "30.47864000",
                            "longitude": "-90.04008000"
                        },
                        {
                            "id": 110991,
                            "name": "Acadia Parish",
                            "latitude": "30.29053000",
                            "longitude": "-92.41198000"
                        },
                        {
                            "id": 111033,
                            "name": "Addis",
                            "latitude": "30.35380000",
                            "longitude": "-91.26539000"
                        },
                        {
                            "id": 111103,
                            "name": "Albany",
                            "latitude": "30.50436000",
                            "longitude": "-90.58231000"
                        },
                        {
                            "id": 111150,
                            "name": "Alexandria",
                            "latitude": "31.31129000",
                            "longitude": "-92.44514000"
                        },
                        {
                            "id": 111191,
                            "name": "Allen Parish",
                            "latitude": "30.65287000",
                            "longitude": "-92.82788000"
                        },
                        {
                            "id": 111260,
                            "name": "Ama",
                            "latitude": "29.95215000",
                            "longitude": "-90.29647000"
                        },
                        {
                            "id": 111268,
                            "name": "Amelia",
                            "latitude": "29.66632000",
                            "longitude": "-91.10204000"
                        },
                        {
                            "id": 111288,
                            "name": "Amite",
                            "latitude": "30.72657000",
                            "longitude": "-90.50898000"
                        },
                        {
                            "id": 111408,
                            "name": "Arabi",
                            "latitude": "29.95437000",
                            "longitude": "-90.00535000"
                        },
                        {
                            "id": 111421,
                            "name": "Arcadia",
                            "latitude": "32.54904000",
                            "longitude": "-92.92016000"
                        },
                        {
                            "id": 111482,
                            "name": "Arnaudville",
                            "latitude": "30.39770000",
                            "longitude": "-91.93151000"
                        },
                        {
                            "id": 111509,
                            "name": "Ascension Parish",
                            "latitude": "30.20354000",
                            "longitude": "-90.91129000"
                        },
                        {
                            "id": 111558,
                            "name": "Assumption Parish",
                            "latitude": "29.90077000",
                            "longitude": "-91.06259000"
                        },
                        {
                            "id": 111698,
                            "name": "Avondale",
                            "latitude": "29.91298000",
                            "longitude": "-90.20369000"
                        },
                        {
                            "id": 111704,
                            "name": "Avoyelles Parish",
                            "latitude": "31.07624000",
                            "longitude": "-92.00138000"
                        },
                        {
                            "id": 111738,
                            "name": "Baker",
                            "latitude": "30.58824000",
                            "longitude": "-91.16816000"
                        },
                        {
                            "id": 111754,
                            "name": "Baldwin",
                            "latitude": "29.83798000",
                            "longitude": "-91.54428000"
                        },
                        {
                            "id": 111768,
                            "name": "Ball",
                            "latitude": "31.41546000",
                            "longitude": "-92.41180000"
                        },
                        {
                            "id": 111799,
                            "name": "Banks Springs",
                            "latitude": "32.08210000",
                            "longitude": "-92.09291000"
                        },
                        {
                            "id": 111811,
                            "name": "Barataria",
                            "latitude": "29.72327000",
                            "longitude": "-90.12369000"
                        },
                        {
                            "id": 111877,
                            "name": "Basile",
                            "latitude": "30.48520000",
                            "longitude": "-92.59597000"
                        },
                        {
                            "id": 111883,
                            "name": "Bastrop",
                            "latitude": "32.77828000",
                            "longitude": "-91.91144000"
                        },
                        {
                            "id": 111903,
                            "name": "Baton Rouge",
                            "latitude": "30.44332000",
                            "longitude": "-91.18747000"
                        },
                        {
                            "id": 111912,
                            "name": "Bawcomville",
                            "latitude": "32.47042000",
                            "longitude": "-92.16736000"
                        },
                        {
                            "id": 111946,
                            "name": "Bayou Cane",
                            "latitude": "29.62410000",
                            "longitude": "-90.75120000"
                        },
                        {
                            "id": 111947,
                            "name": "Bayou Gauche",
                            "latitude": "29.78743000",
                            "longitude": "-90.41314000"
                        },
                        {
                            "id": 111949,
                            "name": "Bayou Vista",
                            "latitude": "29.68965000",
                            "longitude": "-91.27094000"
                        },
                        {
                            "id": 111991,
                            "name": "Beauregard Parish",
                            "latitude": "30.64847000",
                            "longitude": "-93.34334000"
                        },
                        {
                            "id": 112080,
                            "name": "Belle Chasse",
                            "latitude": "29.85493000",
                            "longitude": "-89.99063000"
                        },
                        {
                            "id": 112092,
                            "name": "Belle Rose",
                            "latitude": "30.05048000",
                            "longitude": "-91.04149000"
                        },
                        {
                            "id": 112194,
                            "name": "Benton",
                            "latitude": "32.69487000",
                            "longitude": "-93.74185000"
                        },
                        {
                            "id": 112247,
                            "name": "Bernice",
                            "latitude": "32.82209000",
                            "longitude": "-92.65793000"
                        },
                        {
                            "id": 112261,
                            "name": "Berwick",
                            "latitude": "29.69465000",
                            "longitude": "-91.21899000"
                        },
                        {
                            "id": 112313,
                            "name": "Bienville Parish",
                            "latitude": "32.34722000",
                            "longitude": "-93.05595000"
                        },
                        {
                            "id": 112416,
                            "name": "Blanchard",
                            "latitude": "32.58098000",
                            "longitude": "-93.89268000"
                        },
                        {
                            "id": 112498,
                            "name": "Bogalusa",
                            "latitude": "30.79102000",
                            "longitude": "-89.84869000"
                        },
                        {
                            "id": 112586,
                            "name": "Bossier City",
                            "latitude": "32.51599000",
                            "longitude": "-93.73212000"
                        },
                        {
                            "id": 112587,
                            "name": "Bossier Parish",
                            "latitude": "32.67899000",
                            "longitude": "-93.60500000"
                        },
                        {
                            "id": 112616,
                            "name": "Bourg",
                            "latitude": "29.55355000",
                            "longitude": "-90.60231000"
                        },
                        {
                            "id": 112618,
                            "name": "Boutte",
                            "latitude": "29.90243000",
                            "longitude": "-90.38814000"
                        },
                        {
                            "id": 112709,
                            "name": "Breaux Bridge",
                            "latitude": "30.27353000",
                            "longitude": "-91.89928000"
                        },
                        {
                            "id": 112755,
                            "name": "Bridge City",
                            "latitude": "29.93326000",
                            "longitude": "-90.17007000"
                        },
                        {
                            "id": 112903,
                            "name": "Broussard",
                            "latitude": "30.14715000",
                            "longitude": "-91.96123000"
                        },
                        {
                            "id": 112925,
                            "name": "Brownsfield",
                            "latitude": "30.54658000",
                            "longitude": "-91.12066000"
                        },
                        {
                            "id": 112930,
                            "name": "Brownsville",
                            "latitude": "32.48709000",
                            "longitude": "-92.15430000"
                        },
                        {
                            "id": 112957,
                            "name": "Brusly",
                            "latitude": "30.39436000",
                            "longitude": "-91.25372000"
                        },
                        {
                            "id": 113044,
                            "name": "Bunkie",
                            "latitude": "30.95325000",
                            "longitude": "-92.18263000"
                        },
                        {
                            "id": 113162,
                            "name": "Caddo Parish",
                            "latitude": "32.58017000",
                            "longitude": "-93.88235000"
                        },
                        {
                            "id": 113163,
                            "name": "Cade",
                            "latitude": "30.08742000",
                            "longitude": "-91.90540000"
                        },
                        {
                            "id": 113177,
                            "name": "Calcasieu Parish",
                            "latitude": "30.22922000",
                            "longitude": "-93.35795000"
                        },
                        {
                            "id": 113187,
                            "name": "Caldwell Parish",
                            "latitude": "32.09227000",
                            "longitude": "-92.11661000"
                        },
                        {
                            "id": 113274,
                            "name": "Cameron",
                            "latitude": "29.79772000",
                            "longitude": "-93.32515000"
                        },
                        {
                            "id": 113279,
                            "name": "Cameron Parish",
                            "latitude": "29.84687000",
                            "longitude": "-93.19890000"
                        },
                        {
                            "id": 113309,
                            "name": "Campti",
                            "latitude": "31.89350000",
                            "longitude": "-93.11822000"
                        },
                        {
                            "id": 113393,
                            "name": "Carencro",
                            "latitude": "30.31714000",
                            "longitude": "-92.04901000"
                        },
                        {
                            "id": 113415,
                            "name": "Carlyss",
                            "latitude": "30.16882000",
                            "longitude": "-93.37599000"
                        },
                        {
                            "id": 113504,
                            "name": "Carville",
                            "latitude": "30.21742000",
                            "longitude": "-91.09621000"
                        },
                        {
                            "id": 113561,
                            "name": "Catahoula",
                            "latitude": "30.21464000",
                            "longitude": "-91.70900000"
                        },
                        {
                            "id": 113562,
                            "name": "Catahoula Parish",
                            "latitude": "31.66617000",
                            "longitude": "-91.84707000"
                        },
                        {
                            "id": 113600,
                            "name": "Cecilia",
                            "latitude": "30.33714000",
                            "longitude": "-91.85317000"
                        },
                        {
                            "id": 113663,
                            "name": "Central",
                            "latitude": "30.55435000",
                            "longitude": "-91.03677000"
                        },
                        {
                            "id": 113705,
                            "name": "Chackbay",
                            "latitude": "29.88354000",
                            "longitude": "-90.79732000"
                        },
                        {
                            "id": 113716,
                            "name": "Chalmette",
                            "latitude": "29.94296000",
                            "longitude": "-89.96537000"
                        },
                        {
                            "id": 113748,
                            "name": "Charenton",
                            "latitude": "29.88159000",
                            "longitude": "-91.52511000"
                        },
                        {
                            "id": 113816,
                            "name": "Chauvin",
                            "latitude": "29.43855000",
                            "longitude": "-90.59537000"
                        },
                        {
                            "id": 114009,
                            "name": "Church Point",
                            "latitude": "30.40298000",
                            "longitude": "-92.21513000"
                        },
                        {
                            "id": 114092,
                            "name": "Claiborne",
                            "latitude": "32.51598000",
                            "longitude": "-92.19180000"
                        },
                        {
                            "id": 114095,
                            "name": "Claiborne Parish",
                            "latitude": "32.82269000",
                            "longitude": "-92.99573000"
                        },
                        {
                            "id": 114140,
                            "name": "Clarks",
                            "latitude": "32.02655000",
                            "longitude": "-92.13903000"
                        },
                        {
                            "id": 114270,
                            "name": "Clinton",
                            "latitude": "30.86574000",
                            "longitude": "-91.01566000"
                        },
                        {
                            "id": 114404,
                            "name": "Colfax",
                            "latitude": "31.51906000",
                            "longitude": "-92.70682000"
                        },
                        {
                            "id": 114466,
                            "name": "Columbia",
                            "latitude": "32.10516000",
                            "longitude": "-92.07791000"
                        },
                        {
                            "id": 114547,
                            "name": "Concordia Parish",
                            "latitude": "31.44584000",
                            "longitude": "-91.64006000"
                        },
                        {
                            "id": 114577,
                            "name": "Convent",
                            "latitude": "30.02076000",
                            "longitude": "-90.82982000"
                        },
                        {
                            "id": 114698,
                            "name": "Cottonport",
                            "latitude": "30.98408000",
                            "longitude": "-92.05346000"
                        },
                        {
                            "id": 114729,
                            "name": "Coushatta",
                            "latitude": "32.01488000",
                            "longitude": "-93.34212000"
                        },
                        {
                            "id": 114737,
                            "name": "Covington",
                            "latitude": "30.47549000",
                            "longitude": "-90.10042000"
                        },
                        {
                            "id": 114863,
                            "name": "Crowley",
                            "latitude": "30.21409000",
                            "longitude": "-92.37458000"
                        },
                        {
                            "id": 114894,
                            "name": "Cullen",
                            "latitude": "32.96903000",
                            "longitude": "-93.45073000"
                        },
                        {
                            "id": 114942,
                            "name": "Cut Off",
                            "latitude": "29.54272000",
                            "longitude": "-90.33814000"
                        },
                        {
                            "id": 115120,
                            "name": "De Soto Parish",
                            "latitude": "32.05545000",
                            "longitude": "-93.73728000"
                        },
                        {
                            "id": 115209,
                            "name": "Delcambre",
                            "latitude": "29.94826000",
                            "longitude": "-91.98873000"
                        },
                        {
                            "id": 115211,
                            "name": "Delhi",
                            "latitude": "32.45764000",
                            "longitude": "-91.49317000"
                        },
                        {
                            "id": 115236,
                            "name": "Denham Springs",
                            "latitude": "30.48740000",
                            "longitude": "-90.95753000"
                        },
                        {
                            "id": 115136,
                            "name": "DeQuincy",
                            "latitude": "30.45048000",
                            "longitude": "-93.43322000"
                        },
                        {
                            "id": 115137,
                            "name": "DeRidder",
                            "latitude": "30.84631000",
                            "longitude": "-93.28905000"
                        },
                        {
                            "id": 115269,
                            "name": "Des Allemands",
                            "latitude": "29.82382000",
                            "longitude": "-90.47508000"
                        },
                        {
                            "id": 115290,
                            "name": "Destrehan",
                            "latitude": "29.94322000",
                            "longitude": "-90.35345000"
                        },
                        {
                            "id": 115297,
                            "name": "Deville",
                            "latitude": "31.35740000",
                            "longitude": "-92.16541000"
                        },
                        {
                            "id": 115394,
                            "name": "Donaldsonville",
                            "latitude": "30.10114000",
                            "longitude": "-90.99412000"
                        },
                        {
                            "id": 115509,
                            "name": "Dulac",
                            "latitude": "29.38883000",
                            "longitude": "-90.71398000"
                        },
                        {
                            "id": 115577,
                            "name": "Duson",
                            "latitude": "30.23576000",
                            "longitude": "-92.18540000"
                        },
                        {
                            "id": 115630,
                            "name": "East Baton Rouge Parish",
                            "latitude": "30.53824000",
                            "longitude": "-91.09562000"
                        },
                        {
                            "id": 115643,
                            "name": "East Carroll Parish",
                            "latitude": "32.73255000",
                            "longitude": "-91.23507000"
                        },
                        {
                            "id": 115659,
                            "name": "East Feliciana Parish",
                            "latitude": "30.84507000",
                            "longitude": "-91.04554000"
                        },
                        {
                            "id": 115799,
                            "name": "Eastwood",
                            "latitude": "32.55626000",
                            "longitude": "-93.56712000"
                        },
                        {
                            "id": 115831,
                            "name": "Eden Isle",
                            "latitude": "30.22853000",
                            "longitude": "-89.79867000"
                        },
                        {
                            "id": 115837,
                            "name": "Edgard",
                            "latitude": "30.04326000",
                            "longitude": "-90.56009000"
                        },
                        {
                            "id": 116060,
                            "name": "Elmwood",
                            "latitude": "29.95659000",
                            "longitude": "-90.18980000"
                        },
                        {
                            "id": 116073,
                            "name": "Elton",
                            "latitude": "30.48131000",
                            "longitude": "-92.69570000"
                        },
                        {
                            "id": 116160,
                            "name": "Erath",
                            "latitude": "29.95826000",
                            "longitude": "-92.03596000"
                        },
                        {
                            "id": 116177,
                            "name": "Erwinville",
                            "latitude": "30.53102000",
                            "longitude": "-91.40789000"
                        },
                        {
                            "id": 116205,
                            "name": "Estelle",
                            "latitude": "29.84576000",
                            "longitude": "-90.10674000"
                        },
                        {
                            "id": 116229,
                            "name": "Eunice",
                            "latitude": "30.49437000",
                            "longitude": "-92.41763000"
                        },
                        {
                            "id": 116244,
                            "name": "Evangeline Parish",
                            "latitude": "30.72894000",
                            "longitude": "-92.40590000"
                        },
                        {
                            "id": 116408,
                            "name": "Farmerville",
                            "latitude": "32.77347000",
                            "longitude": "-92.40570000"
                        },
                        {
                            "id": 116493,
                            "name": "Ferriday",
                            "latitude": "31.63017000",
                            "longitude": "-91.55456000"
                        },
                        {
                            "id": 116744,
                            "name": "Fort Polk North",
                            "latitude": "31.10302000",
                            "longitude": "-93.17913000"
                        },
                        {
                            "id": 116745,
                            "name": "Fort Polk South",
                            "latitude": "31.05110000",
                            "longitude": "-93.21578000"
                        },
                        {
                            "id": 116827,
                            "name": "Franklin",
                            "latitude": "29.79604000",
                            "longitude": "-91.50150000"
                        },
                        {
                            "id": 116867,
                            "name": "Franklin Parish",
                            "latitude": "32.13322000",
                            "longitude": "-91.67377000"
                        },
                        {
                            "id": 116873,
                            "name": "Franklinton",
                            "latitude": "30.84731000",
                            "longitude": "-90.15527000"
                        },
                        {
                            "id": 116932,
                            "name": "French Settlement",
                            "latitude": "30.29599000",
                            "longitude": "-90.79630000"
                        },
                        {
                            "id": 117037,
                            "name": "Galliano",
                            "latitude": "29.44216000",
                            "longitude": "-90.29925000"
                        },
                        {
                            "id": 117072,
                            "name": "Gardere",
                            "latitude": "30.34575000",
                            "longitude": "-91.14011000"
                        },
                        {
                            "id": 117107,
                            "name": "Garyville",
                            "latitude": "30.05604000",
                            "longitude": "-90.61926000"
                        },
                        {
                            "id": 117294,
                            "name": "Glenmora",
                            "latitude": "30.97658000",
                            "longitude": "-92.58514000"
                        },
                        {
                            "id": 117352,
                            "name": "Golden Meadow",
                            "latitude": "29.37911000",
                            "longitude": "-90.26008000"
                        },
                        {
                            "id": 117370,
                            "name": "Gonzales",
                            "latitude": "30.23853000",
                            "longitude": "-90.92010000"
                        },
                        {
                            "id": 117442,
                            "name": "Grambling",
                            "latitude": "32.52765000",
                            "longitude": "-92.71404000"
                        },
                        {
                            "id": 117443,
                            "name": "Gramercy",
                            "latitude": "30.04742000",
                            "longitude": "-90.68981000"
                        },
                        {
                            "id": 117463,
                            "name": "Grand Isle",
                            "latitude": "29.23662000",
                            "longitude": "-89.98729000"
                        },
                        {
                            "id": 117470,
                            "name": "Grand Point",
                            "latitude": "30.06131000",
                            "longitude": "-90.75343000"
                        },
                        {
                            "id": 117522,
                            "name": "Grant Parish",
                            "latitude": "31.59970000",
                            "longitude": "-92.55952000"
                        },
                        {
                            "id": 117551,
                            "name": "Gray",
                            "latitude": "29.69771000",
                            "longitude": "-90.78648000"
                        },
                        {
                            "id": 117671,
                            "name": "Greensburg",
                            "latitude": "30.83074000",
                            "longitude": "-90.67176000"
                        },
                        {
                            "id": 117704,
                            "name": "Greenwood",
                            "latitude": "32.44293000",
                            "longitude": "-93.97296000"
                        },
                        {
                            "id": 117725,
                            "name": "Gretna",
                            "latitude": "29.91465000",
                            "longitude": "-90.05396000"
                        },
                        {
                            "id": 117782,
                            "name": "Gueydan",
                            "latitude": "30.02604000",
                            "longitude": "-92.50847000"
                        },
                        {
                            "id": 117826,
                            "name": "Hackberry",
                            "latitude": "29.99605000",
                            "longitude": "-93.34210000"
                        },
                        {
                            "id": 117840,
                            "name": "Hahnville",
                            "latitude": "29.97659000",
                            "longitude": "-90.40897000"
                        },
                        {
                            "id": 117912,
                            "name": "Hammond",
                            "latitude": "30.50463000",
                            "longitude": "-90.46293000"
                        },
                        {
                            "id": 117984,
                            "name": "Harahan",
                            "latitude": "29.94048000",
                            "longitude": "-90.20313000"
                        },
                        {
                            "id": 118069,
                            "name": "Harrisonburg",
                            "latitude": "31.77211000",
                            "longitude": "-91.82152000"
                        },
                        {
                            "id": 118111,
                            "name": "Harvey",
                            "latitude": "29.90354000",
                            "longitude": "-90.07729000"
                        },
                        {
                            "id": 118142,
                            "name": "Haughton",
                            "latitude": "32.53265000",
                            "longitude": "-93.50406000"
                        },
                        {
                            "id": 118188,
                            "name": "Haynesville",
                            "latitude": "32.96208000",
                            "longitude": "-93.14016000"
                        },
                        {
                            "id": 118275,
                            "name": "Henderson",
                            "latitude": "30.31325000",
                            "longitude": "-91.79039000"
                        },
                        {
                            "id": 118593,
                            "name": "Homer",
                            "latitude": "32.79192000",
                            "longitude": "-93.05503000"
                        },
                        {
                            "id": 118694,
                            "name": "Houma",
                            "latitude": "29.59577000",
                            "longitude": "-90.71953000"
                        },
                        {
                            "id": 118863,
                            "name": "Iberia Parish",
                            "latitude": "29.51353000",
                            "longitude": "-91.83964000"
                        },
                        {
                            "id": 118864,
                            "name": "Iberville Parish",
                            "latitude": "30.25850000",
                            "longitude": "-91.34936000"
                        },
                        {
                            "id": 118891,
                            "name": "Independence",
                            "latitude": "30.63551000",
                            "longitude": "-90.50335000"
                        },
                        {
                            "id": 118947,
                            "name": "Inniswold",
                            "latitude": "30.40491000",
                            "longitude": "-91.08344000"
                        },
                        {
                            "id": 118974,
                            "name": "Iota",
                            "latitude": "30.33131000",
                            "longitude": "-92.49569000"
                        },
                        {
                            "id": 118975,
                            "name": "Iowa",
                            "latitude": "30.23687000",
                            "longitude": "-93.01376000"
                        },
                        {
                            "id": 119063,
                            "name": "Jackson",
                            "latitude": "30.83740000",
                            "longitude": "-91.21761000"
                        },
                        {
                            "id": 119098,
                            "name": "Jackson Parish",
                            "latitude": "32.30203000",
                            "longitude": "-92.55774000"
                        },
                        {
                            "id": 119157,
                            "name": "Jean Lafitte",
                            "latitude": "29.73604000",
                            "longitude": "-90.12674000"
                        },
                        {
                            "id": 119158,
                            "name": "Jeanerette",
                            "latitude": "29.91104000",
                            "longitude": "-91.66345000"
                        },
                        {
                            "id": 119163,
                            "name": "Jefferson",
                            "latitude": "29.96604000",
                            "longitude": "-90.15313000"
                        },
                        {
                            "id": 119200,
                            "name": "Jefferson Davis Parish",
                            "latitude": "30.26772000",
                            "longitude": "-92.81412000"
                        },
                        {
                            "id": 119203,
                            "name": "Jefferson Parish",
                            "latitude": "29.68097000",
                            "longitude": "-90.09798000"
                        },
                        {
                            "id": 119212,
                            "name": "Jena",
                            "latitude": "31.68323000",
                            "longitude": "-92.13374000"
                        },
                        {
                            "id": 119218,
                            "name": "Jennings",
                            "latitude": "30.22243000",
                            "longitude": "-92.65708000"
                        },
                        {
                            "id": 119302,
                            "name": "Jonesboro",
                            "latitude": "32.24127000",
                            "longitude": "-92.71599000"
                        },
                        {
                            "id": 119309,
                            "name": "Jonesville",
                            "latitude": "31.62656000",
                            "longitude": "-91.81818000"
                        },
                        {
                            "id": 119387,
                            "name": "Kaplan",
                            "latitude": "29.99798000",
                            "longitude": "-92.28485000"
                        },
                        {
                            "id": 119470,
                            "name": "Kenner",
                            "latitude": "29.99409000",
                            "longitude": "-90.24174000"
                        },
                        {
                            "id": 119500,
                            "name": "Kentwood",
                            "latitude": "30.93824000",
                            "longitude": "-90.50898000"
                        },
                        {
                            "id": 119560,
                            "name": "Killian",
                            "latitude": "30.35881000",
                            "longitude": "-90.58620000"
                        },
                        {
                            "id": 119574,
                            "name": "Kinder",
                            "latitude": "30.48548000",
                            "longitude": "-92.85070000"
                        },
                        {
                            "id": 119718,
                            "name": "Krotz Springs",
                            "latitude": "30.53686000",
                            "longitude": "-91.75289000"
                        },
                        {
                            "id": 119788,
                            "name": "La Salle Parish",
                            "latitude": "31.67673000",
                            "longitude": "-92.16044000"
                        },
                        {
                            "id": 119808,
                            "name": "Labadieville",
                            "latitude": "29.83743000",
                            "longitude": "-90.95621000"
                        },
                        {
                            "id": 119817,
                            "name": "Lacombe",
                            "latitude": "30.31353000",
                            "longitude": "-89.94313000"
                        },
                        {
                            "id": 119832,
                            "name": "Lafayette",
                            "latitude": "30.22409000",
                            "longitude": "-92.01984000"
                        },
                        {
                            "id": 119844,
                            "name": "Lafayette Parish",
                            "latitude": "30.20677000",
                            "longitude": "-92.06388000"
                        },
                        {
                            "id": 119846,
                            "name": "Lafourche Parish",
                            "latitude": "29.50033000",
                            "longitude": "-90.40259000"
                        },
                        {
                            "id": 119867,
                            "name": "Lake Arthur",
                            "latitude": "30.08076000",
                            "longitude": "-92.67153000"
                        },
                        {
                            "id": 119877,
                            "name": "Lake Charles",
                            "latitude": "30.21309000",
                            "longitude": "-93.20440000"
                        },
                        {
                            "id": 119966,
                            "name": "Lake Providence",
                            "latitude": "32.80499000",
                            "longitude": "-91.17098000"
                        },
                        {
                            "id": 120028,
                            "name": "Lakeshore",
                            "latitude": "32.53514000",
                            "longitude": "-92.02958000"
                        },
                        {
                            "id": 120134,
                            "name": "Laplace",
                            "latitude": "30.06698000",
                            "longitude": "-90.48147000"
                        },
                        {
                            "id": 120151,
                            "name": "Larose",
                            "latitude": "29.57244000",
                            "longitude": "-90.38175000"
                        },
                        {
                            "id": 120249,
                            "name": "Lawtell",
                            "latitude": "30.51853000",
                            "longitude": "-92.18485000"
                        },
                        {
                            "id": 120300,
                            "name": "Lecompte",
                            "latitude": "31.09463000",
                            "longitude": "-92.40041000"
                        },
                        {
                            "id": 120328,
                            "name": "Leesville",
                            "latitude": "31.14352000",
                            "longitude": "-93.26100000"
                        },
                        {
                            "id": 120392,
                            "name": "Leonville",
                            "latitude": "30.47047000",
                            "longitude": "-91.97845000"
                        },
                        {
                            "id": 120539,
                            "name": "Lincoln Parish",
                            "latitude": "32.60164000",
                            "longitude": "-92.66482000"
                        },
                        {
                            "id": 120648,
                            "name": "Livingston",
                            "latitude": "30.50213000",
                            "longitude": "-90.74787000"
                        },
                        {
                            "id": 120660,
                            "name": "Livingston Parish",
                            "latitude": "30.44014000",
                            "longitude": "-90.72791000"
                        },
                        {
                            "id": 120661,
                            "name": "Livonia",
                            "latitude": "30.55908000",
                            "longitude": "-91.55594000"
                        },
                        {
                            "id": 120679,
                            "name": "Lockport",
                            "latitude": "29.64605000",
                            "longitude": "-90.53925000"
                        },
                        {
                            "id": 120682,
                            "name": "Lockport Heights",
                            "latitude": "29.65049000",
                            "longitude": "-90.54647000"
                        },
                        {
                            "id": 120706,
                            "name": "Logansport",
                            "latitude": "31.97545000",
                            "longitude": "-93.99797000"
                        },
                        {
                            "id": 120876,
                            "name": "Luling",
                            "latitude": "29.93215000",
                            "longitude": "-90.36647000"
                        },
                        {
                            "id": 120893,
                            "name": "Lutcher",
                            "latitude": "30.04048000",
                            "longitude": "-90.69898000"
                        },
                        {
                            "id": 121023,
                            "name": "Madison Parish",
                            "latitude": "32.36440000",
                            "longitude": "-91.24258000"
                        },
                        {
                            "id": 121078,
                            "name": "Mamou",
                            "latitude": "30.63381000",
                            "longitude": "-92.41930000"
                        },
                        {
                            "id": 121107,
                            "name": "Mandeville",
                            "latitude": "30.35825000",
                            "longitude": "-90.06563000"
                        },
                        {
                            "id": 121144,
                            "name": "Mansfield",
                            "latitude": "32.03766000",
                            "longitude": "-93.70018000"
                        },
                        {
                            "id": 121152,
                            "name": "Mansura",
                            "latitude": "31.05797000",
                            "longitude": "-92.04901000"
                        },
                        {
                            "id": 121163,
                            "name": "Many",
                            "latitude": "31.56878000",
                            "longitude": "-93.48406000"
                        },
                        {
                            "id": 121230,
                            "name": "Maringouin",
                            "latitude": "30.49130000",
                            "longitude": "-91.51955000"
                        },
                        {
                            "id": 121273,
                            "name": "Marksville",
                            "latitude": "31.12797000",
                            "longitude": "-92.06624000"
                        },
                        {
                            "id": 121296,
                            "name": "Marrero",
                            "latitude": "29.89937000",
                            "longitude": "-90.10035000"
                        },
                        {
                            "id": 121402,
                            "name": "Mathews",
                            "latitude": "29.68632000",
                            "longitude": "-90.54675000"
                        },
                        {
                            "id": 121423,
                            "name": "Maurice",
                            "latitude": "30.10854000",
                            "longitude": "-92.12457000"
                        },
                        {
                            "id": 121634,
                            "name": "Melville",
                            "latitude": "30.69297000",
                            "longitude": "-91.74400000"
                        },
                        {
                            "id": 121670,
                            "name": "Meraux",
                            "latitude": "29.93017000",
                            "longitude": "-89.91623000"
                        },
                        {
                            "id": 121716,
                            "name": "Merrydale",
                            "latitude": "30.50130000",
                            "longitude": "-91.10844000"
                        },
                        {
                            "id": 121717,
                            "name": "Merryville",
                            "latitude": "30.75437000",
                            "longitude": "-93.54045000"
                        },
                        {
                            "id": 121729,
                            "name": "Metairie",
                            "latitude": "29.98409000",
                            "longitude": "-90.15285000"
                        },
                        {
                            "id": 121730,
                            "name": "Metairie Terrace",
                            "latitude": "29.97854000",
                            "longitude": "-90.16396000"
                        },
                        {
                            "id": 121815,
                            "name": "Midway",
                            "latitude": "31.69212000",
                            "longitude": "-92.15236000"
                        },
                        {
                            "id": 121913,
                            "name": "Milton",
                            "latitude": "30.10381000",
                            "longitude": "-92.07651000"
                        },
                        {
                            "id": 121928,
                            "name": "Minden",
                            "latitude": "32.61543000",
                            "longitude": "-93.28684000"
                        },
                        {
                            "id": 121964,
                            "name": "Minorca",
                            "latitude": "31.57933000",
                            "longitude": "-91.48179000"
                        },
                        {
                            "id": 122058,
                            "name": "Monroe",
                            "latitude": "32.50931000",
                            "longitude": "-92.11930000"
                        },
                        {
                            "id": 122116,
                            "name": "Montegut",
                            "latitude": "29.47439000",
                            "longitude": "-90.55703000"
                        },
                        {
                            "id": 122161,
                            "name": "Monticello",
                            "latitude": "30.48908000",
                            "longitude": "-91.04872000"
                        },
                        {
                            "id": 122190,
                            "name": "Montz",
                            "latitude": "30.00687000",
                            "longitude": "-90.46869000"
                        },
                        {
                            "id": 122227,
                            "name": "Morehouse Parish",
                            "latitude": "32.82022000",
                            "longitude": "-91.80180000"
                        },
                        {
                            "id": 122235,
                            "name": "Morgan City",
                            "latitude": "29.69937000",
                            "longitude": "-91.20677000"
                        },
                        {
                            "id": 122308,
                            "name": "Moss Bluff",
                            "latitude": "30.30270000",
                            "longitude": "-93.19071000"
                        },
                        {
                            "id": 122560,
                            "name": "Napoleonville",
                            "latitude": "29.94048000",
                            "longitude": "-91.02482000"
                        },
                        {
                            "id": 122585,
                            "name": "Natalbany",
                            "latitude": "30.54622000",
                            "longitude": "-90.48619000"
                        },
                        {
                            "id": 122588,
                            "name": "Natchitoches",
                            "latitude": "31.76072000",
                            "longitude": "-93.08627000"
                        },
                        {
                            "id": 122589,
                            "name": "Natchitoches Parish",
                            "latitude": "31.72354000",
                            "longitude": "-93.09624000"
                        },
                        {
                            "id": 122730,
                            "name": "New Iberia",
                            "latitude": "30.00354000",
                            "longitude": "-91.81873000"
                        },
                        {
                            "id": 122739,
                            "name": "New Llano",
                            "latitude": "31.11491000",
                            "longitude": "-93.27155000"
                        },
                        {
                            "id": 122756,
                            "name": "New Orleans",
                            "latitude": "29.95465000",
                            "longitude": "-90.07507000"
                        },
                        {
                            "id": 122772,
                            "name": "New Roads",
                            "latitude": "30.70157000",
                            "longitude": "-91.43622000"
                        },
                        {
                            "id": 122775,
                            "name": "New Sarpy",
                            "latitude": "29.97817000",
                            "longitude": "-90.38963000"
                        },
                        {
                            "id": 122825,
                            "name": "Newellton",
                            "latitude": "32.07265000",
                            "longitude": "-91.24095000"
                        },
                        {
                            "id": 122938,
                            "name": "Norco",
                            "latitude": "29.99909000",
                            "longitude": "-90.41230000"
                        },
                        {
                            "id": 123118,
                            "name": "North Vacherie",
                            "latitude": "29.99687000",
                            "longitude": "-90.70565000"
                        },
                        {
                            "id": 123235,
                            "name": "Oak Grove",
                            "latitude": "32.86096000",
                            "longitude": "-91.38845000"
                        },
                        {
                            "id": 123250,
                            "name": "Oak Hills Place",
                            "latitude": "30.35992000",
                            "longitude": "-91.08760000"
                        },
                        {
                            "id": 123270,
                            "name": "Oakdale",
                            "latitude": "30.81603000",
                            "longitude": "-92.66042000"
                        },
                        {
                            "id": 123315,
                            "name": "Oberlin",
                            "latitude": "30.62020000",
                            "longitude": "-92.76265000"
                        },
                        {
                            "id": 123422,
                            "name": "Old Jefferson",
                            "latitude": "30.38269000",
                            "longitude": "-91.01705000"
                        },
                        {
                            "id": 123451,
                            "name": "Olla",
                            "latitude": "31.90294000",
                            "longitude": "-92.24319000"
                        },
                        {
                            "id": 123497,
                            "name": "Opelousas",
                            "latitude": "30.53353000",
                            "longitude": "-92.08151000"
                        },
                        {
                            "id": 123567,
                            "name": "Orleans Parish",
                            "latitude": "30.06864000",
                            "longitude": "-89.92813000"
                        },
                        {
                            "id": 123619,
                            "name": "Ossun",
                            "latitude": "30.27603000",
                            "longitude": "-92.11235000"
                        },
                        {
                            "id": 123645,
                            "name": "Ouachita Parish",
                            "latitude": "32.47831000",
                            "longitude": "-92.15487000"
                        },
                        {
                            "id": 123816,
                            "name": "Paradis",
                            "latitude": "29.87965000",
                            "longitude": "-90.43397000"
                        },
                        {
                            "id": 123915,
                            "name": "Patterson",
                            "latitude": "29.69326000",
                            "longitude": "-91.30205000"
                        },
                        {
                            "id": 123923,
                            "name": "Paulina",
                            "latitude": "30.02631000",
                            "longitude": "-90.71315000"
                        },
                        {
                            "id": 123966,
                            "name": "Pearl River",
                            "latitude": "30.37603000",
                            "longitude": "-89.74840000"
                        },
                        {
                            "id": 124179,
                            "name": "Pierre Part",
                            "latitude": "29.96520000",
                            "longitude": "-91.20316000"
                        },
                        {
                            "id": 124241,
                            "name": "Pine Prairie",
                            "latitude": "30.78381000",
                            "longitude": "-92.42541000"
                        },
                        {
                            "id": 124260,
                            "name": "Pineville",
                            "latitude": "31.32240000",
                            "longitude": "-92.43430000"
                        },
                        {
                            "id": 124349,
                            "name": "Plaquemine",
                            "latitude": "30.29005000",
                            "longitude": "-91.23497000"
                        },
                        {
                            "id": 124350,
                            "name": "Plaquemines Parish",
                            "latitude": "29.54421000",
                            "longitude": "-89.82047000"
                        },
                        {
                            "id": 124435,
                            "name": "Pointe Coupee Parish",
                            "latitude": "30.70940000",
                            "longitude": "-91.60079000"
                        },
                        {
                            "id": 124466,
                            "name": "Ponchatoula",
                            "latitude": "30.43880000",
                            "longitude": "-90.44148000"
                        },
                        {
                            "id": 124492,
                            "name": "Port Allen",
                            "latitude": "30.45214000",
                            "longitude": "-91.21011000"
                        },
                        {
                            "id": 124497,
                            "name": "Port Barre",
                            "latitude": "30.56020000",
                            "longitude": "-91.95401000"
                        },
                        {
                            "id": 124536,
                            "name": "Port Sulphur",
                            "latitude": "29.48049000",
                            "longitude": "-89.69395000"
                        },
                        {
                            "id": 124620,
                            "name": "Poydras",
                            "latitude": "29.86937000",
                            "longitude": "-89.88895000"
                        },
                        {
                            "id": 124635,
                            "name": "Prairieville",
                            "latitude": "30.30297000",
                            "longitude": "-90.97205000"
                        },
                        {
                            "id": 124651,
                            "name": "Presquille",
                            "latitude": "29.56383000",
                            "longitude": "-90.64620000"
                        },
                        {
                            "id": 124668,
                            "name": "Prien",
                            "latitude": "30.18187000",
                            "longitude": "-93.27377000"
                        },
                        {
                            "id": 124811,
                            "name": "Raceland",
                            "latitude": "29.72743000",
                            "longitude": "-90.59898000"
                        },
                        {
                            "id": 124896,
                            "name": "Rapides Parish",
                            "latitude": "31.19862000",
                            "longitude": "-92.53320000"
                        },
                        {
                            "id": 124923,
                            "name": "Rayne",
                            "latitude": "30.23493000",
                            "longitude": "-92.26846000"
                        },
                        {
                            "id": 124927,
                            "name": "Rayville",
                            "latitude": "32.47736000",
                            "longitude": "-91.75485000"
                        },
                        {
                            "id": 124943,
                            "name": "Red Chute",
                            "latitude": "32.55598000",
                            "longitude": "-93.61323000"
                        },
                        {
                            "id": 124960,
                            "name": "Red River Parish",
                            "latitude": "32.09315000",
                            "longitude": "-93.33988000"
                        },
                        {
                            "id": 125034,
                            "name": "Reserve",
                            "latitude": "30.05381000",
                            "longitude": "-90.55175000"
                        },
                        {
                            "id": 125080,
                            "name": "Richland Parish",
                            "latitude": "32.41779000",
                            "longitude": "-91.76349000"
                        },
                        {
                            "id": 125109,
                            "name": "Richwood",
                            "latitude": "32.44876000",
                            "longitude": "-92.08486000"
                        },
                        {
                            "id": 125142,
                            "name": "Ringgold",
                            "latitude": "32.32849000",
                            "longitude": "-93.27989000"
                        },
                        {
                            "id": 125185,
                            "name": "River Ridge",
                            "latitude": "29.96020000",
                            "longitude": "-90.21563000"
                        },
                        {
                            "id": 125422,
                            "name": "Roseland",
                            "latitude": "30.76491000",
                            "longitude": "-90.51176000"
                        },
                        {
                            "id": 125437,
                            "name": "Rosepine",
                            "latitude": "30.91991000",
                            "longitude": "-93.28239000"
                        },
                        {
                            "id": 125547,
                            "name": "Ruston",
                            "latitude": "32.52321000",
                            "longitude": "-92.63793000"
                        },
                        {
                            "id": 125570,
                            "name": "Sabine Parish",
                            "latitude": "31.56401000",
                            "longitude": "-93.55470000"
                        },
                        {
                            "id": 125608,
                            "name": "Saint Bernard Parish",
                            "latitude": "29.87399000",
                            "longitude": "-89.82422000"
                        },
                        {
                            "id": 125617,
                            "name": "Saint Charles Parish",
                            "latitude": "29.90553000",
                            "longitude": "-90.35822000"
                        },
                        {
                            "id": 125637,
                            "name": "Saint Francisville",
                            "latitude": "30.77990000",
                            "longitude": "-91.37650000"
                        },
                        {
                            "id": 125639,
                            "name": "Saint Gabriel",
                            "latitude": "30.25770000",
                            "longitude": "-91.09927000"
                        },
                        {
                            "id": 125648,
                            "name": "Saint Helena Parish",
                            "latitude": "30.82198000",
                            "longitude": "-90.71032000"
                        },
                        {
                            "id": 125658,
                            "name": "Saint James Parish",
                            "latitude": "30.02630000",
                            "longitude": "-90.79632000"
                        },
                        {
                            "id": 125663,
                            "name": "Saint John the Baptist Parish",
                            "latitude": "30.12646000",
                            "longitude": "-90.47088000"
                        },
                        {
                            "id": 125670,
                            "name": "Saint Joseph",
                            "latitude": "31.91849000",
                            "longitude": "-91.23345000"
                        },
                        {
                            "id": 125677,
                            "name": "Saint Landry Parish",
                            "latitude": "30.59885000",
                            "longitude": "-92.00586000"
                        },
                        {
                            "id": 125687,
                            "name": "Saint Martin Parish",
                            "latitude": "30.12907000",
                            "longitude": "-91.60830000"
                        },
                        {
                            "id": 125689,
                            "name": "Saint Martinville",
                            "latitude": "30.12520000",
                            "longitude": "-91.83345000"
                        },
                        {
                            "id": 125690,
                            "name": "Saint Mary Parish",
                            "latitude": "29.63462000",
                            "longitude": "-91.47293000"
                        },
                        {
                            "id": 125713,
                            "name": "Saint Rose",
                            "latitude": "29.94687000",
                            "longitude": "-90.32313000"
                        },
                        {
                            "id": 125718,
                            "name": "Saint Tammany Parish",
                            "latitude": "30.40875000",
                            "longitude": "-89.95393000"
                        },
                        {
                            "id": 126019,
                            "name": "Schriever",
                            "latitude": "29.74215000",
                            "longitude": "-90.81037000"
                        },
                        {
                            "id": 126039,
                            "name": "Scott",
                            "latitude": "30.23576000",
                            "longitude": "-92.09457000"
                        },
                        {
                            "id": 126300,
                            "name": "Shenandoah",
                            "latitude": "30.40130000",
                            "longitude": "-91.00094000"
                        },
                        {
                            "id": 126382,
                            "name": "Shreveport",
                            "latitude": "32.52515000",
                            "longitude": "-93.75018000"
                        },
                        {
                            "id": 126390,
                            "name": "Sibley",
                            "latitude": "32.53932000",
                            "longitude": "-93.29628000"
                        },
                        {
                            "id": 126446,
                            "name": "Simmesport",
                            "latitude": "30.98352000",
                            "longitude": "-91.80012000"
                        },
                        {
                            "id": 126501,
                            "name": "Slidell",
                            "latitude": "30.27519000",
                            "longitude": "-89.78117000"
                        },
                        {
                            "id": 126605,
                            "name": "Sorrento",
                            "latitude": "30.18436000",
                            "longitude": "-90.85926000"
                        },
                        {
                            "id": 126739,
                            "name": "South Vacherie",
                            "latitude": "29.92743000",
                            "longitude": "-90.69981000"
                        },
                        {
                            "id": 126909,
                            "name": "Springhill",
                            "latitude": "33.00597000",
                            "longitude": "-93.46684000"
                        },
                        {
                            "id": 127035,
                            "name": "Sterlington",
                            "latitude": "32.69625000",
                            "longitude": "-92.08597000"
                        },
                        {
                            "id": 127091,
                            "name": "Stonewall",
                            "latitude": "32.28183000",
                            "longitude": "-93.82407000"
                        },
                        {
                            "id": 127194,
                            "name": "Sulphur",
                            "latitude": "30.23659000",
                            "longitude": "-93.37738000"
                        },
                        {
                            "id": 127272,
                            "name": "Sunset",
                            "latitude": "30.41131000",
                            "longitude": "-92.06845000"
                        },
                        {
                            "id": 127283,
                            "name": "Supreme",
                            "latitude": "29.85937000",
                            "longitude": "-90.98121000"
                        },
                        {
                            "id": 127331,
                            "name": "Swartz",
                            "latitude": "32.56875000",
                            "longitude": "-91.98513000"
                        },
                        {
                            "id": 127396,
                            "name": "Tallulah",
                            "latitude": "32.40848000",
                            "longitude": "-91.18678000"
                        },
                        {
                            "id": 127415,
                            "name": "Tangipahoa Parish",
                            "latitude": "30.62665000",
                            "longitude": "-90.40568000"
                        },
                        {
                            "id": 127512,
                            "name": "Tensas Parish",
                            "latitude": "32.00166000",
                            "longitude": "-91.34007000"
                        },
                        {
                            "id": 127525,
                            "name": "Terrebonne Parish",
                            "latitude": "29.33744000",
                            "longitude": "-90.83764000"
                        },
                        {
                            "id": 127533,
                            "name": "Terrytown",
                            "latitude": "29.91021000",
                            "longitude": "-90.03257000"
                        },
                        {
                            "id": 127573,
                            "name": "Thibodaux",
                            "latitude": "29.79576000",
                            "longitude": "-90.82287000"
                        },
                        {
                            "id": 127659,
                            "name": "Timberlane",
                            "latitude": "29.87743000",
                            "longitude": "-90.03202000"
                        },
                        {
                            "id": 128008,
                            "name": "Union Parish",
                            "latitude": "32.83190000",
                            "longitude": "-92.37482000"
                        },
                        {
                            "id": 128059,
                            "name": "Urania",
                            "latitude": "31.86378000",
                            "longitude": "-92.29597000"
                        },
                        {
                            "id": 128184,
                            "name": "Vermilion Parish",
                            "latitude": "29.80939000",
                            "longitude": "-92.30428000"
                        },
                        {
                            "id": 128195,
                            "name": "Vernon Parish",
                            "latitude": "31.10829000",
                            "longitude": "-93.18415000"
                        },
                        {
                            "id": 128228,
                            "name": "Vidalia",
                            "latitude": "31.56544000",
                            "longitude": "-91.42595000"
                        },
                        {
                            "id": 128235,
                            "name": "Vienna Bend",
                            "latitude": "31.73239000",
                            "longitude": "-93.04100000"
                        },
                        {
                            "id": 128250,
                            "name": "Village Saint George",
                            "latitude": "30.36214000",
                            "longitude": "-91.06733000"
                        },
                        {
                            "id": 128259,
                            "name": "Ville Platte",
                            "latitude": "30.68797000",
                            "longitude": "-92.27152000"
                        },
                        {
                            "id": 128279,
                            "name": "Vinton",
                            "latitude": "30.19076000",
                            "longitude": "-93.58127000"
                        },
                        {
                            "id": 128284,
                            "name": "Violet",
                            "latitude": "29.89576000",
                            "longitude": "-89.89784000"
                        },
                        {
                            "id": 128298,
                            "name": "Vivian",
                            "latitude": "32.87153000",
                            "longitude": "-93.98740000"
                        },
                        {
                            "id": 128327,
                            "name": "Waggaman",
                            "latitude": "29.91854000",
                            "longitude": "-90.21091000"
                        },
                        {
                            "id": 128383,
                            "name": "Walker",
                            "latitude": "30.48797000",
                            "longitude": "-90.86149000"
                        },
                        {
                            "id": 128583,
                            "name": "Washington Parish",
                            "latitude": "30.85334000",
                            "longitude": "-90.04052000"
                        },
                        {
                            "id": 128639,
                            "name": "Watson",
                            "latitude": "30.57574000",
                            "longitude": "-90.95316000"
                        },
                        {
                            "id": 128744,
                            "name": "Webster Parish",
                            "latitude": "32.71345000",
                            "longitude": "-93.33498000"
                        },
                        {
                            "id": 128796,
                            "name": "Welsh",
                            "latitude": "30.23604000",
                            "longitude": "-92.82265000"
                        },
                        {
                            "id": 128822,
                            "name": "West Baton Rouge Parish",
                            "latitude": "30.46341000",
                            "longitude": "-91.31275000"
                        },
                        {
                            "id": 128841,
                            "name": "West Carroll Parish",
                            "latitude": "32.78856000",
                            "longitude": "-91.45674000"
                        },
                        {
                            "id": 128870,
                            "name": "West Feliciana Parish",
                            "latitude": "30.87977000",
                            "longitude": "-91.42003000"
                        },
                        {
                            "id": 128871,
                            "name": "West Ferriday",
                            "latitude": "31.64044000",
                            "longitude": "-91.57318000"
                        },
                        {
                            "id": 128935,
                            "name": "West Monroe",
                            "latitude": "32.51848000",
                            "longitude": "-92.14764000"
                        },
                        {
                            "id": 129038,
                            "name": "Westlake",
                            "latitude": "30.24215000",
                            "longitude": "-93.25071000"
                        },
                        {
                            "id": 129044,
                            "name": "Westminster",
                            "latitude": "30.41380000",
                            "longitude": "-91.08760000"
                        },
                        {
                            "id": 129081,
                            "name": "Westwego",
                            "latitude": "29.90604000",
                            "longitude": "-90.14230000"
                        },
                        {
                            "id": 129128,
                            "name": "White Castle",
                            "latitude": "30.16992000",
                            "longitude": "-91.14705000"
                        },
                        {
                            "id": 129404,
                            "name": "Winn Parish",
                            "latitude": "31.94425000",
                            "longitude": "-92.63677000"
                        },
                        {
                            "id": 129416,
                            "name": "Winnfield",
                            "latitude": "31.92558000",
                            "longitude": "-92.64131000"
                        },
                        {
                            "id": 129418,
                            "name": "Winnsboro",
                            "latitude": "32.16321000",
                            "longitude": "-91.72068000"
                        },
                        {
                            "id": 129540,
                            "name": "Woodmere",
                            "latitude": "29.85798000",
                            "longitude": "-90.08035000"
                        },
                        {
                            "id": 129580,
                            "name": "Woodworth",
                            "latitude": "31.14658000",
                            "longitude": "-92.49736000"
                        },
                        {
                            "id": 129718,
                            "name": "Youngsville",
                            "latitude": "30.09965000",
                            "longitude": "-91.99012000"
                        },
                        {
                            "id": 129738,
                            "name": "Zachary",
                            "latitude": "30.64852000",
                            "longitude": "-91.15650000"
                        },
                        {
                            "id": 129763,
                            "name": "Zwolle",
                            "latitude": "31.63156000",
                            "longitude": "-93.64407000"
                        }
                    ]
                },
                {
                    "id": 1453,
                    "name": "Maine",
                    "state_code": "ME",
                    "cities": [
                        {
                            "id": 111000,
                            "name": "Acton",
                            "latitude": "43.53425000",
                            "longitude": "-70.90978000"
                        },
                        {
                            "id": 111036,
                            "name": "Addison",
                            "latitude": "44.61841000",
                            "longitude": "-67.74416000"
                        },
                        {
                            "id": 111125,
                            "name": "Albion",
                            "latitude": "44.53229000",
                            "longitude": "-69.44254000"
                        },
                        {
                            "id": 111159,
                            "name": "Alfred",
                            "latitude": "43.47647000",
                            "longitude": "-70.71839000"
                        },
                        {
                            "id": 111334,
                            "name": "Androscoggin County",
                            "latitude": "44.16585000",
                            "longitude": "-70.20645000"
                        },
                        {
                            "id": 111395,
                            "name": "Appleton",
                            "latitude": "44.28924000",
                            "longitude": "-69.25088000"
                        },
                        {
                            "id": 111492,
                            "name": "Aroostook County",
                            "latitude": "46.65881000",
                            "longitude": "-68.59889000"
                        },
                        {
                            "id": 111502,
                            "name": "Arundel",
                            "latitude": "43.38259000",
                            "longitude": "-70.47783000"
                        },
                        {
                            "id": 111627,
                            "name": "Auburn",
                            "latitude": "44.09785000",
                            "longitude": "-70.23117000"
                        },
                        {
                            "id": 111649,
                            "name": "Augusta",
                            "latitude": "44.31062000",
                            "longitude": "-69.77949000"
                        },
                        {
                            "id": 111792,
                            "name": "Bangor",
                            "latitude": "44.80118000",
                            "longitude": "-68.77781000"
                        },
                        {
                            "id": 111806,
                            "name": "Bar Harbor",
                            "latitude": "44.38758000",
                            "longitude": "-68.20390000"
                        },
                        {
                            "id": 111897,
                            "name": "Bath",
                            "latitude": "43.91064000",
                            "longitude": "-69.82060000"
                        },
                        {
                            "id": 112056,
                            "name": "Belfast",
                            "latitude": "44.42591000",
                            "longitude": "-69.00642000"
                        },
                        {
                            "id": 112061,
                            "name": "Belgrade",
                            "latitude": "44.44729000",
                            "longitude": "-69.83255000"
                        },
                        {
                            "id": 112197,
                            "name": "Benton",
                            "latitude": "44.58618000",
                            "longitude": "-69.55088000"
                        },
                        {
                            "id": 112262,
                            "name": "Berwick",
                            "latitude": "43.26592000",
                            "longitude": "-70.86450000"
                        },
                        {
                            "id": 112279,
                            "name": "Bethel",
                            "latitude": "44.40423000",
                            "longitude": "-70.79062000"
                        },
                        {
                            "id": 112312,
                            "name": "Biddeford",
                            "latitude": "43.49258000",
                            "longitude": "-70.45338000"
                        },
                        {
                            "id": 112572,
                            "name": "Boothbay",
                            "latitude": "43.87647000",
                            "longitude": "-69.63366000"
                        },
                        {
                            "id": 112573,
                            "name": "Boothbay Harbor",
                            "latitude": "43.85230000",
                            "longitude": "-69.62810000"
                        },
                        {
                            "id": 112665,
                            "name": "Bradford",
                            "latitude": "45.06673000",
                            "longitude": "-68.93781000"
                        },
                        {
                            "id": 112673,
                            "name": "Bradley",
                            "latitude": "44.92090000",
                            "longitude": "-68.62809000"
                        },
                        {
                            "id": 112739,
                            "name": "Brewer",
                            "latitude": "44.79674000",
                            "longitude": "-68.76142000"
                        },
                        {
                            "id": 112779,
                            "name": "Bridgton",
                            "latitude": "44.05479000",
                            "longitude": "-70.71284000"
                        },
                        {
                            "id": 112806,
                            "name": "Bristol",
                            "latitude": "43.95758000",
                            "longitude": "-69.50921000"
                        },
                        {
                            "id": 112882,
                            "name": "Brooks",
                            "latitude": "44.55035000",
                            "longitude": "-69.12087000"
                        },
                        {
                            "id": 112916,
                            "name": "Brownfield",
                            "latitude": "43.93813000",
                            "longitude": "-70.90868000"
                        },
                        {
                            "id": 112937,
                            "name": "Brownville",
                            "latitude": "45.30700000",
                            "longitude": "-69.03337000"
                        },
                        {
                            "id": 112950,
                            "name": "Brunswick",
                            "latitude": "43.91452000",
                            "longitude": "-69.96533000"
                        },
                        {
                            "id": 112979,
                            "name": "Buckfield",
                            "latitude": "44.28951000",
                            "longitude": "-70.36534000"
                        },
                        {
                            "id": 112992,
                            "name": "Bucksport",
                            "latitude": "44.57369000",
                            "longitude": "-68.79559000"
                        },
                        {
                            "id": 113084,
                            "name": "Burnham",
                            "latitude": "44.69284000",
                            "longitude": "-69.42755000"
                        },
                        {
                            "id": 113132,
                            "name": "Buxton",
                            "latitude": "43.63786000",
                            "longitude": "-70.51894000"
                        },
                        {
                            "id": 113175,
                            "name": "Calais",
                            "latitude": "45.18376000",
                            "longitude": "-67.27662000"
                        },
                        {
                            "id": 113266,
                            "name": "Camden",
                            "latitude": "44.20980000",
                            "longitude": "-69.06476000"
                        },
                        {
                            "id": 113312,
                            "name": "Canaan",
                            "latitude": "44.76173000",
                            "longitude": "-69.56144000"
                        },
                        {
                            "id": 113346,
                            "name": "Canton",
                            "latitude": "44.44080000",
                            "longitude": "-70.31649000"
                        },
                        {
                            "id": 113372,
                            "name": "Cape Neddick",
                            "latitude": "43.19370000",
                            "longitude": "-70.62089000"
                        },
                        {
                            "id": 113394,
                            "name": "Caribou",
                            "latitude": "46.86060000",
                            "longitude": "-68.01197000"
                        },
                        {
                            "id": 113418,
                            "name": "Carmel",
                            "latitude": "44.79757000",
                            "longitude": "-69.05115000"
                        },
                        {
                            "id": 113542,
                            "name": "Castine",
                            "latitude": "44.38785000",
                            "longitude": "-68.79975000"
                        },
                        {
                            "id": 113767,
                            "name": "Charleston",
                            "latitude": "45.08506000",
                            "longitude": "-69.04059000"
                        },
                        {
                            "id": 113832,
                            "name": "Chelsea",
                            "latitude": "44.25035000",
                            "longitude": "-69.71727000"
                        },
                        {
                            "id": 113866,
                            "name": "Cherryfield",
                            "latitude": "44.60730000",
                            "longitude": "-67.92584000"
                        },
                        {
                            "id": 113911,
                            "name": "Chesterville",
                            "latitude": "44.55117000",
                            "longitude": "-70.08617000"
                        },
                        {
                            "id": 113960,
                            "name": "China",
                            "latitude": "44.47868000",
                            "longitude": "-69.51726000"
                        },
                        {
                            "id": 113980,
                            "name": "Chisholm",
                            "latitude": "44.48145000",
                            "longitude": "-70.19950000"
                        },
                        {
                            "id": 114281,
                            "name": "Clinton",
                            "latitude": "44.63784000",
                            "longitude": "-69.50310000"
                        },
                        {
                            "id": 114642,
                            "name": "Corinna",
                            "latitude": "44.92117000",
                            "longitude": "-69.26171000"
                        },
                        {
                            "id": 114655,
                            "name": "Cornish",
                            "latitude": "43.80480000",
                            "longitude": "-70.80117000"
                        },
                        {
                            "id": 114656,
                            "name": "Cornville",
                            "latitude": "44.83673000",
                            "longitude": "-69.67311000"
                        },
                        {
                            "id": 114911,
                            "name": "Cumberland Center",
                            "latitude": "43.79647000",
                            "longitude": "-70.25894000"
                        },
                        {
                            "id": 114918,
                            "name": "Cumberland County",
                            "latitude": "43.80608000",
                            "longitude": "-70.33020000"
                        },
                        {
                            "id": 114932,
                            "name": "Cushing",
                            "latitude": "44.01925000",
                            "longitude": "-69.23977000"
                        },
                        {
                            "id": 115008,
                            "name": "Damariscotta",
                            "latitude": "44.03286000",
                            "longitude": "-69.51866000"
                        },
                        {
                            "id": 115100,
                            "name": "Dayton",
                            "latitude": "43.54972000",
                            "longitude": "-70.57555000"
                        },
                        {
                            "id": 115168,
                            "name": "Dedham",
                            "latitude": "44.69174000",
                            "longitude": "-68.66198000"
                        },
                        {
                            "id": 115172,
                            "name": "Deer Isle",
                            "latitude": "44.22397000",
                            "longitude": "-68.67753000"
                        },
                        {
                            "id": 115240,
                            "name": "Denmark",
                            "latitude": "43.97035000",
                            "longitude": "-70.80340000"
                        },
                        {
                            "id": 115308,
                            "name": "Dexter",
                            "latitude": "45.02395000",
                            "longitude": "-69.28977000"
                        },
                        {
                            "id": 115359,
                            "name": "Dixfield",
                            "latitude": "44.53395000",
                            "longitude": "-70.45590000"
                        },
                        {
                            "id": 115362,
                            "name": "Dixmont",
                            "latitude": "44.68035000",
                            "longitude": "-69.16282000"
                        },
                        {
                            "id": 115457,
                            "name": "Dover-Foxcroft",
                            "latitude": "45.18339000",
                            "longitude": "-69.22699000"
                        },
                        {
                            "id": 115707,
                            "name": "East Machias",
                            "latitude": "44.73924000",
                            "longitude": "-67.38999000"
                        },
                        {
                            "id": 115713,
                            "name": "East Millinocket",
                            "latitude": "45.62755000",
                            "longitude": "-68.57448000"
                        },
                        {
                            "id": 115788,
                            "name": "Easton",
                            "latitude": "46.64115000",
                            "longitude": "-67.90947000"
                        },
                        {
                            "id": 115795,
                            "name": "Eastport",
                            "latitude": "44.90619000",
                            "longitude": "-66.98998000"
                        },
                        {
                            "id": 115821,
                            "name": "Eddington",
                            "latitude": "44.82618000",
                            "longitude": "-68.69337000"
                        },
                        {
                            "id": 115840,
                            "name": "Edgecomb",
                            "latitude": "43.95841000",
                            "longitude": "-69.63060000"
                        },
                        {
                            "id": 115970,
                            "name": "Eliot",
                            "latitude": "43.15314000",
                            "longitude": "-70.80006000"
                        },
                        {
                            "id": 116038,
                            "name": "Ellsworth",
                            "latitude": "44.54341000",
                            "longitude": "-68.41946000"
                        },
                        {
                            "id": 116126,
                            "name": "Enfield",
                            "latitude": "45.24894000",
                            "longitude": "-68.56836000"
                        },
                        {
                            "id": 116215,
                            "name": "Etna",
                            "latitude": "44.82090000",
                            "longitude": "-69.11115000"
                        },
                        {
                            "id": 116314,
                            "name": "Fairfield",
                            "latitude": "44.58840000",
                            "longitude": "-69.59866000"
                        },
                        {
                            "id": 116389,
                            "name": "Falmouth",
                            "latitude": "43.72953000",
                            "longitude": "-70.24199000"
                        },
                        {
                            "id": 116390,
                            "name": "Falmouth Foreside",
                            "latitude": "43.73480000",
                            "longitude": "-70.20783000"
                        },
                        {
                            "id": 116409,
                            "name": "Farmingdale",
                            "latitude": "44.24451000",
                            "longitude": "-69.77143000"
                        },
                        {
                            "id": 116418,
                            "name": "Farmington",
                            "latitude": "44.67062000",
                            "longitude": "-70.15117000"
                        },
                        {
                            "id": 116442,
                            "name": "Fayette",
                            "latitude": "44.40896000",
                            "longitude": "-70.03367000"
                        },
                        {
                            "id": 116710,
                            "name": "Fort Fairfield",
                            "latitude": "46.77227000",
                            "longitude": "-67.83391000"
                        },
                        {
                            "id": 116720,
                            "name": "Fort Kent",
                            "latitude": "47.25865000",
                            "longitude": "-68.58949000"
                        },
                        {
                            "id": 116822,
                            "name": "Frankfort",
                            "latitude": "44.60980000",
                            "longitude": "-68.87670000"
                        },
                        {
                            "id": 116835,
                            "name": "Franklin",
                            "latitude": "44.58702000",
                            "longitude": "-68.23224000"
                        },
                        {
                            "id": 116859,
                            "name": "Franklin County",
                            "latitude": "44.97417000",
                            "longitude": "-70.44410000"
                        },
                        {
                            "id": 116913,
                            "name": "Freeport",
                            "latitude": "43.85702000",
                            "longitude": "-70.10311000"
                        },
                        {
                            "id": 116936,
                            "name": "Frenchville",
                            "latitude": "47.28087000",
                            "longitude": "-68.37976000"
                        },
                        {
                            "id": 116948,
                            "name": "Friendship",
                            "latitude": "43.98369000",
                            "longitude": "-69.33394000"
                        },
                        {
                            "id": 116978,
                            "name": "Fryeburg",
                            "latitude": "44.01646000",
                            "longitude": "-70.98062000"
                        },
                        {
                            "id": 117073,
                            "name": "Gardiner",
                            "latitude": "44.23007000",
                            "longitude": "-69.77532000"
                        },
                        {
                            "id": 117089,
                            "name": "Garland",
                            "latitude": "45.03840000",
                            "longitude": "-69.16032000"
                        },
                        {
                            "id": 117403,
                            "name": "Gorham",
                            "latitude": "43.67952000",
                            "longitude": "-70.44422000"
                        },
                        {
                            "id": 117418,
                            "name": "Gouldsboro",
                            "latitude": "44.47841000",
                            "longitude": "-68.03834000"
                        },
                        {
                            "id": 117623,
                            "name": "Greenbush",
                            "latitude": "45.08034000",
                            "longitude": "-68.65086000"
                        },
                        {
                            "id": 117629,
                            "name": "Greene",
                            "latitude": "44.18979000",
                            "longitude": "-70.14033000"
                        },
                        {
                            "id": 117691,
                            "name": "Greenville",
                            "latitude": "45.45949000",
                            "longitude": "-69.59061000"
                        },
                        {
                            "id": 117872,
                            "name": "Hallowell",
                            "latitude": "44.28590000",
                            "longitude": "-69.79088000"
                        },
                        {
                            "id": 117917,
                            "name": "Hampden",
                            "latitude": "44.74452000",
                            "longitude": "-68.83698000"
                        },
                        {
                            "id": 117948,
                            "name": "Hancock",
                            "latitude": "44.52924000",
                            "longitude": "-68.25363000"
                        },
                        {
                            "id": 117957,
                            "name": "Hancock County",
                            "latitude": "44.56289000",
                            "longitude": "-68.36821000"
                        },
                        {
                            "id": 118037,
                            "name": "Harpswell Center",
                            "latitude": "43.80175000",
                            "longitude": "-69.98421000"
                        },
                        {
                            "id": 118056,
                            "name": "Harrison",
                            "latitude": "44.11035000",
                            "longitude": "-70.67923000"
                        },
                        {
                            "id": 118089,
                            "name": "Hartford",
                            "latitude": "44.37284000",
                            "longitude": "-70.34673000"
                        },
                        {
                            "id": 118237,
                            "name": "Hebron",
                            "latitude": "44.19813000",
                            "longitude": "-70.40645000"
                        },
                        {
                            "id": 118330,
                            "name": "Hermon",
                            "latitude": "44.81007000",
                            "longitude": "-68.91337000"
                        },
                        {
                            "id": 118491,
                            "name": "Hiram",
                            "latitude": "43.87868000",
                            "longitude": "-70.80340000"
                        },
                        {
                            "id": 118506,
                            "name": "Hodgdon",
                            "latitude": "46.05394000",
                            "longitude": "-67.86668000"
                        },
                        {
                            "id": 118524,
                            "name": "Holden",
                            "latitude": "44.75285000",
                            "longitude": "-68.67892000"
                        },
                        {
                            "id": 118550,
                            "name": "Hollis Center",
                            "latitude": "43.60508000",
                            "longitude": "-70.59311000"
                        },
                        {
                            "id": 118644,
                            "name": "Hope",
                            "latitude": "44.26508000",
                            "longitude": "-69.15893000"
                        },
                        {
                            "id": 118693,
                            "name": "Houlton",
                            "latitude": "46.12616000",
                            "longitude": "-67.84030000"
                        },
                        {
                            "id": 118725,
                            "name": "Howland",
                            "latitude": "45.23867000",
                            "longitude": "-68.66364000"
                        },
                        {
                            "id": 118742,
                            "name": "Hudson",
                            "latitude": "45.00118000",
                            "longitude": "-68.88059000"
                        },
                        {
                            "id": 119154,
                            "name": "Jay",
                            "latitude": "44.50395000",
                            "longitude": "-70.21617000"
                        },
                        {
                            "id": 119169,
                            "name": "Jefferson",
                            "latitude": "44.20674000",
                            "longitude": "-69.45254000"
                        },
                        {
                            "id": 119305,
                            "name": "Jonesport",
                            "latitude": "44.53286000",
                            "longitude": "-67.59833000"
                        },
                        {
                            "id": 119452,
                            "name": "Kenduskeag",
                            "latitude": "44.91951000",
                            "longitude": "-68.93170000"
                        },
                        {
                            "id": 119465,
                            "name": "Kennebec County",
                            "latitude": "44.40916000",
                            "longitude": "-69.76726000"
                        },
                        {
                            "id": 119466,
                            "name": "Kennebunk",
                            "latitude": "43.38397000",
                            "longitude": "-70.54478000"
                        },
                        {
                            "id": 119467,
                            "name": "Kennebunkport",
                            "latitude": "43.36175000",
                            "longitude": "-70.47672000"
                        },
                        {
                            "id": 119591,
                            "name": "Kingfield",
                            "latitude": "44.95922000",
                            "longitude": "-70.15395000"
                        },
                        {
                            "id": 119662,
                            "name": "Kittery",
                            "latitude": "43.08814000",
                            "longitude": "-70.73616000"
                        },
                        {
                            "id": 119663,
                            "name": "Kittery Point",
                            "latitude": "43.08342000",
                            "longitude": "-70.70783000"
                        },
                        {
                            "id": 119690,
                            "name": "Knox County",
                            "latitude": "44.07575000",
                            "longitude": "-69.12598000"
                        },
                        {
                            "id": 119865,
                            "name": "Lake Arrowhead",
                            "latitude": "43.66369000",
                            "longitude": "-70.73478000"
                        },
                        {
                            "id": 120290,
                            "name": "Lebanon",
                            "latitude": "43.39453000",
                            "longitude": "-70.85089000"
                        },
                        {
                            "id": 120321,
                            "name": "Leeds",
                            "latitude": "44.30340000",
                            "longitude": "-70.11950000"
                        },
                        {
                            "id": 120401,
                            "name": "Levant",
                            "latitude": "44.86924000",
                            "longitude": "-68.93476000"
                        },
                        {
                            "id": 120423,
                            "name": "Lewiston",
                            "latitude": "44.10035000",
                            "longitude": "-70.21478000"
                        },
                        {
                            "id": 120490,
                            "name": "Limerick",
                            "latitude": "43.68841000",
                            "longitude": "-70.79367000"
                        },
                        {
                            "id": 120492,
                            "name": "Limestone",
                            "latitude": "46.90866000",
                            "longitude": "-67.82585000"
                        },
                        {
                            "id": 120497,
                            "name": "Limington",
                            "latitude": "43.73174000",
                            "longitude": "-70.71089000"
                        },
                        {
                            "id": 120505,
                            "name": "Lincoln",
                            "latitude": "45.36228000",
                            "longitude": "-68.50502000"
                        },
                        {
                            "id": 120526,
                            "name": "Lincoln County",
                            "latitude": "43.99779000",
                            "longitude": "-69.52576000"
                        },
                        {
                            "id": 120554,
                            "name": "Lincolnville",
                            "latitude": "44.28119000",
                            "longitude": "-69.00865000"
                        },
                        {
                            "id": 120599,
                            "name": "Lisbon",
                            "latitude": "44.03146000",
                            "longitude": "-70.10450000"
                        },
                        {
                            "id": 120601,
                            "name": "Lisbon Falls",
                            "latitude": "43.99619000",
                            "longitude": "-70.06061000"
                        },
                        {
                            "id": 120643,
                            "name": "Livermore",
                            "latitude": "44.38396000",
                            "longitude": "-70.24922000"
                        },
                        {
                            "id": 120645,
                            "name": "Livermore Falls",
                            "latitude": "44.47534000",
                            "longitude": "-70.18811000"
                        },
                        {
                            "id": 120823,
                            "name": "Lovell",
                            "latitude": "44.12674000",
                            "longitude": "-70.89173000"
                        },
                        {
                            "id": 120956,
                            "name": "Machias",
                            "latitude": "44.71508000",
                            "longitude": "-67.46138000"
                        },
                        {
                            "id": 120958,
                            "name": "Machiasport",
                            "latitude": "44.69869000",
                            "longitude": "-67.39471000"
                        },
                        {
                            "id": 120976,
                            "name": "Madawaska",
                            "latitude": "46.88421000",
                            "longitude": "-67.94725000"
                        },
                        {
                            "id": 120995,
                            "name": "Madison",
                            "latitude": "44.79756000",
                            "longitude": "-69.87978000"
                        },
                        {
                            "id": 121097,
                            "name": "Manchester",
                            "latitude": "44.32451000",
                            "longitude": "-69.86033000"
                        },
                        {
                            "id": 121579,
                            "name": "Mechanic Falls",
                            "latitude": "44.11174000",
                            "longitude": "-70.39172000"
                        },
                        {
                            "id": 121611,
                            "name": "Medway",
                            "latitude": "45.60894000",
                            "longitude": "-68.53086000"
                        },
                        {
                            "id": 121742,
                            "name": "Mexico",
                            "latitude": "44.56090000",
                            "longitude": "-70.54534000"
                        },
                        {
                            "id": 121843,
                            "name": "Milbridge",
                            "latitude": "44.53536000",
                            "longitude": "-67.88083000"
                        },
                        {
                            "id": 121853,
                            "name": "Milford",
                            "latitude": "44.94618000",
                            "longitude": "-68.64392000"
                        },
                        {
                            "id": 121893,
                            "name": "Millinocket",
                            "latitude": "45.65727000",
                            "longitude": "-68.70976000"
                        },
                        {
                            "id": 121908,
                            "name": "Milo",
                            "latitude": "45.25366000",
                            "longitude": "-68.98587000"
                        },
                        {
                            "id": 121965,
                            "name": "Minot",
                            "latitude": "44.08563000",
                            "longitude": "-70.32006000"
                        },
                        {
                            "id": 122043,
                            "name": "Monmouth",
                            "latitude": "44.23868000",
                            "longitude": "-70.03561000"
                        },
                        {
                            "id": 122401,
                            "name": "Mount Vernon",
                            "latitude": "44.50118000",
                            "longitude": "-69.98756000"
                        },
                        {
                            "id": 122709,
                            "name": "New Gloucester",
                            "latitude": "43.96285000",
                            "longitude": "-70.28255000"
                        },
                        {
                            "id": 122777,
                            "name": "New Sharon",
                            "latitude": "44.63895000",
                            "longitude": "-70.01561000"
                        },
                        {
                            "id": 122829,
                            "name": "Newfield",
                            "latitude": "43.64813000",
                            "longitude": "-70.84701000"
                        },
                        {
                            "id": 122846,
                            "name": "Newport",
                            "latitude": "44.83534000",
                            "longitude": "-69.27394000"
                        },
                        {
                            "id": 122919,
                            "name": "Nobleboro",
                            "latitude": "44.07952000",
                            "longitude": "-69.48505000"
                        },
                        {
                            "id": 122954,
                            "name": "Norridgewock",
                            "latitude": "44.71312000",
                            "longitude": "-69.79061000"
                        },
                        {
                            "id": 122974,
                            "name": "North Bath",
                            "latitude": "43.93480000",
                            "longitude": "-69.81588000"
                        },
                        {
                            "id": 122988,
                            "name": "North Berwick",
                            "latitude": "43.30370000",
                            "longitude": "-70.73339000"
                        },
                        {
                            "id": 123131,
                            "name": "North Windham",
                            "latitude": "43.83424000",
                            "longitude": "-70.43839000"
                        },
                        {
                            "id": 123161,
                            "name": "Northport",
                            "latitude": "44.33786000",
                            "longitude": "-68.96142000"
                        },
                        {
                            "id": 123193,
                            "name": "Norway",
                            "latitude": "44.21396000",
                            "longitude": "-70.54478000"
                        },
                        {
                            "id": 123289,
                            "name": "Oakland",
                            "latitude": "44.54034000",
                            "longitude": "-69.72199000"
                        },
                        {
                            "id": 123380,
                            "name": "Ogunquit",
                            "latitude": "43.24898000",
                            "longitude": "-70.59922000"
                        },
                        {
                            "id": 123425,
                            "name": "Old Orchard Beach",
                            "latitude": "43.51731000",
                            "longitude": "-70.37755000"
                        },
                        {
                            "id": 123430,
                            "name": "Old Town",
                            "latitude": "44.93423000",
                            "longitude": "-68.64531000"
                        },
                        {
                            "id": 123558,
                            "name": "Orland",
                            "latitude": "44.57035000",
                            "longitude": "-68.73586000"
                        },
                        {
                            "id": 123574,
                            "name": "Orono",
                            "latitude": "44.88312000",
                            "longitude": "-68.67198000"
                        },
                        {
                            "id": 123581,
                            "name": "Orrington",
                            "latitude": "44.73118000",
                            "longitude": "-68.82643000"
                        },
                        {
                            "id": 123671,
                            "name": "Owls Head",
                            "latitude": "44.08230000",
                            "longitude": "-69.05726000"
                        },
                        {
                            "id": 123684,
                            "name": "Oxford",
                            "latitude": "44.13174000",
                            "longitude": "-70.49311000"
                        },
                        {
                            "id": 123688,
                            "name": "Oxford County",
                            "latitude": "44.49977000",
                            "longitude": "-70.75657000"
                        },
                        {
                            "id": 123734,
                            "name": "Palermo",
                            "latitude": "44.40785000",
                            "longitude": "-69.47393000"
                        },
                        {
                            "id": 123774,
                            "name": "Palmyra",
                            "latitude": "44.84645000",
                            "longitude": "-69.35866000"
                        },
                        {
                            "id": 123835,
                            "name": "Paris",
                            "latitude": "44.25979000",
                            "longitude": "-70.50062000"
                        },
                        {
                            "id": 123894,
                            "name": "Parsonsfield",
                            "latitude": "43.72702000",
                            "longitude": "-70.92868000"
                        },
                        {
                            "id": 123914,
                            "name": "Patten",
                            "latitude": "45.99644000",
                            "longitude": "-68.44614000"
                        },
                        {
                            "id": 124041,
                            "name": "Penobscot",
                            "latitude": "44.46452000",
                            "longitude": "-68.71114000"
                        },
                        {
                            "id": 124042,
                            "name": "Penobscot County",
                            "latitude": "45.40051000",
                            "longitude": "-68.64943000"
                        },
                        {
                            "id": 124097,
                            "name": "Peru",
                            "latitude": "44.50673000",
                            "longitude": "-70.40534000"
                        },
                        {
                            "id": 124133,
                            "name": "Phillips",
                            "latitude": "44.82311000",
                            "longitude": "-70.33951000"
                        },
                        {
                            "id": 124145,
                            "name": "Phippsburg",
                            "latitude": "43.82064000",
                            "longitude": "-69.81477000"
                        },
                        {
                            "id": 124281,
                            "name": "Piscataquis County",
                            "latitude": "45.83736000",
                            "longitude": "-69.28452000"
                        },
                        {
                            "id": 124299,
                            "name": "Pittsfield",
                            "latitude": "44.78256000",
                            "longitude": "-69.38338000"
                        },
                        {
                            "id": 124303,
                            "name": "Pittston",
                            "latitude": "44.22174000",
                            "longitude": "-69.75560000"
                        },
                        {
                            "id": 124404,
                            "name": "Plymouth",
                            "latitude": "44.76729000",
                            "longitude": "-69.21033000"
                        },
                        {
                            "id": 124437,
                            "name": "Poland",
                            "latitude": "44.06063000",
                            "longitude": "-70.39367000"
                        },
                        {
                            "id": 124552,
                            "name": "Porter",
                            "latitude": "43.79591000",
                            "longitude": "-70.93256000"
                        },
                        {
                            "id": 124562,
                            "name": "Portland",
                            "latitude": "43.65737000",
                            "longitude": "-70.25890000"
                        },
                        {
                            "id": 124649,
                            "name": "Presque Isle",
                            "latitude": "46.68115000",
                            "longitude": "-68.01586000"
                        },
                        {
                            "id": 124866,
                            "name": "Randolph",
                            "latitude": "44.23035000",
                            "longitude": "-69.76671000"
                        },
                        {
                            "id": 124918,
                            "name": "Raymond",
                            "latitude": "43.90146000",
                            "longitude": "-70.47033000"
                        },
                        {
                            "id": 124928,
                            "name": "Readfield",
                            "latitude": "44.38785000",
                            "longitude": "-69.96672000"
                        },
                        {
                            "id": 125091,
                            "name": "Richmond",
                            "latitude": "44.08730000",
                            "longitude": "-69.79893000"
                        },
                        {
                            "id": 125308,
                            "name": "Rockland",
                            "latitude": "44.10369000",
                            "longitude": "-69.10893000"
                        },
                        {
                            "id": 125318,
                            "name": "Rockport",
                            "latitude": "44.18452000",
                            "longitude": "-69.07615000"
                        },
                        {
                            "id": 125377,
                            "name": "Rome",
                            "latitude": "44.58506000",
                            "longitude": "-69.86922000"
                        },
                        {
                            "id": 125507,
                            "name": "Rumford",
                            "latitude": "44.55367000",
                            "longitude": "-70.55090000"
                        },
                        {
                            "id": 125566,
                            "name": "Sabattus",
                            "latitude": "44.11980000",
                            "longitude": "-70.10755000"
                        },
                        {
                            "id": 125576,
                            "name": "Saco",
                            "latitude": "43.50092000",
                            "longitude": "-70.44283000"
                        },
                        {
                            "id": 125585,
                            "name": "Sagadahoc County",
                            "latitude": "43.91173000",
                            "longitude": "-69.83931000"
                        },
                        {
                            "id": 125595,
                            "name": "Saint Albans",
                            "latitude": "44.91006000",
                            "longitude": "-69.41005000"
                        },
                        {
                            "id": 125643,
                            "name": "Saint George",
                            "latitude": "44.01647000",
                            "longitude": "-69.19893000"
                        },
                        {
                            "id": 125888,
                            "name": "Sanford",
                            "latitude": "43.43925000",
                            "longitude": "-70.77422000"
                        },
                        {
                            "id": 125893,
                            "name": "Sangerville",
                            "latitude": "45.16478000",
                            "longitude": "-69.35644000"
                        },
                        {
                            "id": 125997,
                            "name": "Scarborough",
                            "latitude": "43.57814000",
                            "longitude": "-70.32172000"
                        },
                        {
                            "id": 126096,
                            "name": "Searsmont",
                            "latitude": "44.36174000",
                            "longitude": "-69.19504000"
                        },
                        {
                            "id": 126118,
                            "name": "Sedgwick",
                            "latitude": "44.30369000",
                            "longitude": "-68.61614000"
                        },
                        {
                            "id": 126224,
                            "name": "Shapleigh",
                            "latitude": "43.54064000",
                            "longitude": "-70.84812000"
                        },
                        {
                            "id": 126396,
                            "name": "Sidney",
                            "latitude": "44.41312000",
                            "longitude": "-69.72893000"
                        },
                        {
                            "id": 126484,
                            "name": "Skowhegan",
                            "latitude": "44.76506000",
                            "longitude": "-69.71922000"
                        },
                        {
                            "id": 126587,
                            "name": "Somerset County",
                            "latitude": "45.51385000",
                            "longitude": "-69.95882000"
                        },
                        {
                            "id": 126626,
                            "name": "South Berwick",
                            "latitude": "43.23453000",
                            "longitude": "-70.80950000"
                        },
                        {
                            "id": 126652,
                            "name": "South Eliot",
                            "latitude": "43.10814000",
                            "longitude": "-70.77755000"
                        },
                        {
                            "id": 126702,
                            "name": "South Paris",
                            "latitude": "44.22368000",
                            "longitude": "-70.51339000"
                        },
                        {
                            "id": 126713,
                            "name": "South Portland",
                            "latitude": "43.64147000",
                            "longitude": "-70.24088000"
                        },
                        {
                            "id": 126714,
                            "name": "South Portland Gardens",
                            "latitude": "43.63897000",
                            "longitude": "-70.31533000"
                        },
                        {
                            "id": 126726,
                            "name": "South Sanford",
                            "latitude": "43.41119000",
                            "longitude": "-70.74256000"
                        },
                        {
                            "id": 126735,
                            "name": "South Thomaston",
                            "latitude": "44.05147000",
                            "longitude": "-69.12782000"
                        },
                        {
                            "id": 126752,
                            "name": "South Windham",
                            "latitude": "43.73619000",
                            "longitude": "-70.42366000"
                        },
                        {
                            "id": 126912,
                            "name": "Springvale",
                            "latitude": "43.46675000",
                            "longitude": "-70.79367000"
                        },
                        {
                            "id": 127015,
                            "name": "Steep Falls",
                            "latitude": "43.79397000",
                            "longitude": "-70.65256000"
                        },
                        {
                            "id": 127036,
                            "name": "Stetson",
                            "latitude": "44.89173000",
                            "longitude": "-69.14282000"
                        },
                        {
                            "id": 127037,
                            "name": "Steuben",
                            "latitude": "44.51098000",
                            "longitude": "-67.96662000"
                        },
                        {
                            "id": 127075,
                            "name": "Stockton Springs",
                            "latitude": "44.48952000",
                            "longitude": "-68.85698000"
                        },
                        {
                            "id": 127095,
                            "name": "Stonington",
                            "latitude": "44.15619000",
                            "longitude": "-68.66669000"
                        },
                        {
                            "id": 127138,
                            "name": "Strong",
                            "latitude": "44.80756000",
                            "longitude": "-70.22090000"
                        },
                        {
                            "id": 127184,
                            "name": "Sullivan",
                            "latitude": "44.52036000",
                            "longitude": "-68.19668000"
                        },
                        {
                            "id": 127293,
                            "name": "Surry",
                            "latitude": "44.49591000",
                            "longitude": "-68.50169000"
                        },
                        {
                            "id": 127328,
                            "name": "Swanville",
                            "latitude": "44.52119000",
                            "longitude": "-68.99781000"
                        },
                        {
                            "id": 127585,
                            "name": "Thomaston",
                            "latitude": "44.07897000",
                            "longitude": "-69.18171000"
                        },
                        {
                            "id": 127736,
                            "name": "Topsham",
                            "latitude": "43.92758000",
                            "longitude": "-69.97588000"
                        },
                        {
                            "id": 127794,
                            "name": "Tremont",
                            "latitude": "44.25369000",
                            "longitude": "-68.35141000"
                        },
                        {
                            "id": 127807,
                            "name": "Trenton",
                            "latitude": "44.43897000",
                            "longitude": "-68.37002000"
                        },
                        {
                            "id": 127849,
                            "name": "Troy",
                            "latitude": "44.66479000",
                            "longitude": "-69.24088000"
                        },
                        {
                            "id": 127901,
                            "name": "Turner",
                            "latitude": "44.25646000",
                            "longitude": "-70.25617000"
                        },
                        {
                            "id": 127975,
                            "name": "Union",
                            "latitude": "44.21147000",
                            "longitude": "-69.27421000"
                        },
                        {
                            "id": 128128,
                            "name": "Van Buren",
                            "latitude": "47.15727000",
                            "longitude": "-67.93530000"
                        },
                        {
                            "id": 128158,
                            "name": "Vassalboro",
                            "latitude": "44.45923000",
                            "longitude": "-69.67755000"
                        },
                        {
                            "id": 128160,
                            "name": "Veazie",
                            "latitude": "44.83868000",
                            "longitude": "-68.70531000"
                        },
                        {
                            "id": 128262,
                            "name": "Vinalhaven",
                            "latitude": "44.04814000",
                            "longitude": "-68.83170000"
                        },
                        {
                            "id": 128372,
                            "name": "Waldo County",
                            "latitude": "44.48525000",
                            "longitude": "-69.12188000"
                        },
                        {
                            "id": 128373,
                            "name": "Waldoboro",
                            "latitude": "44.09536000",
                            "longitude": "-69.37560000"
                        },
                        {
                            "id": 128480,
                            "name": "Warren",
                            "latitude": "44.12036000",
                            "longitude": "-69.24005000"
                        },
                        {
                            "id": 128547,
                            "name": "Washington",
                            "latitude": "44.27369000",
                            "longitude": "-69.36727000"
                        },
                        {
                            "id": 128569,
                            "name": "Washington County",
                            "latitude": "44.96946000",
                            "longitude": "-67.60906000"
                        },
                        {
                            "id": 128601,
                            "name": "Waterboro",
                            "latitude": "43.53564000",
                            "longitude": "-70.71506000"
                        },
                        {
                            "id": 128626,
                            "name": "Waterville",
                            "latitude": "44.55201000",
                            "longitude": "-69.63171000"
                        },
                        {
                            "id": 128684,
                            "name": "Wayne",
                            "latitude": "44.34868000",
                            "longitude": "-70.06616000"
                        },
                        {
                            "id": 128784,
                            "name": "Wells Beach Station",
                            "latitude": "43.32397000",
                            "longitude": "-70.59144000"
                        },
                        {
                            "id": 128906,
                            "name": "West Kennebunk",
                            "latitude": "43.40870000",
                            "longitude": "-70.58144000"
                        },
                        {
                            "id": 128948,
                            "name": "West Paris",
                            "latitude": "44.32423000",
                            "longitude": "-70.57395000"
                        },
                        {
                            "id": 128977,
                            "name": "West Scarborough",
                            "latitude": "43.57036000",
                            "longitude": "-70.38783000"
                        },
                        {
                            "id": 129013,
                            "name": "Westbrook",
                            "latitude": "43.67703000",
                            "longitude": "-70.37116000"
                        },
                        {
                            "id": 129164,
                            "name": "Whitefield",
                            "latitude": "44.17007000",
                            "longitude": "-69.62532000"
                        },
                        {
                            "id": 129344,
                            "name": "Wilton",
                            "latitude": "44.59284000",
                            "longitude": "-70.22812000"
                        },
                        {
                            "id": 129383,
                            "name": "Windsor",
                            "latitude": "44.31063000",
                            "longitude": "-69.58060000"
                        },
                        {
                            "id": 129428,
                            "name": "Winslow",
                            "latitude": "44.54701000",
                            "longitude": "-69.62116000"
                        },
                        {
                            "id": 129443,
                            "name": "Winterport",
                            "latitude": "44.63785000",
                            "longitude": "-68.84504000"
                        },
                        {
                            "id": 129449,
                            "name": "Winthrop",
                            "latitude": "44.30507000",
                            "longitude": "-69.97700000"
                        },
                        {
                            "id": 129456,
                            "name": "Wiscasset",
                            "latitude": "44.00286000",
                            "longitude": "-69.66560000"
                        },
                        {
                            "id": 129567,
                            "name": "Woodstock",
                            "latitude": "44.37494000",
                            "longitude": "-70.60849000"
                        },
                        {
                            "id": 129581,
                            "name": "Woolwich",
                            "latitude": "43.91869000",
                            "longitude": "-69.80116000"
                        },
                        {
                            "id": 129666,
                            "name": "Yarmouth",
                            "latitude": "43.80064000",
                            "longitude": "-70.18672000"
                        },
                        {
                            "id": 129694,
                            "name": "York Beach",
                            "latitude": "43.17148000",
                            "longitude": "-70.60894000"
                        },
                        {
                            "id": 129697,
                            "name": "York County",
                            "latitude": "43.42923000",
                            "longitude": "-70.67015000"
                        },
                        {
                            "id": 129700,
                            "name": "York Harbor",
                            "latitude": "43.13676000",
                            "longitude": "-70.64561000"
                        }
                    ]
                },
                {
                    "id": 1401,
                    "name": "Maryland",
                    "state_code": "MD",
                    "cities": [
                        {
                            "id": 110973,
                            "name": "Aberdeen",
                            "latitude": "39.50956000",
                            "longitude": "-76.16412000"
                        },
                        {
                            "id": 110980,
                            "name": "Aberdeen Proving Ground",
                            "latitude": "39.46686000",
                            "longitude": "-76.13066000"
                        },
                        {
                            "id": 110993,
                            "name": "Accokeek",
                            "latitude": "38.66762000",
                            "longitude": "-77.02831000"
                        },
                        {
                            "id": 111029,
                            "name": "Adamstown",
                            "latitude": "39.31094000",
                            "longitude": "-77.47471000"
                        },
                        {
                            "id": 111044,
                            "name": "Adelphi",
                            "latitude": "39.00317000",
                            "longitude": "-76.97192000"
                        },
                        {
                            "id": 111166,
                            "name": "Algonquin",
                            "latitude": "38.58290000",
                            "longitude": "-76.10577000"
                        },
                        {
                            "id": 111181,
                            "name": "Allegany County",
                            "latitude": "39.62148000",
                            "longitude": "-78.69890000"
                        },
                        {
                            "id": 111332,
                            "name": "Andrews AFB",
                            "latitude": "38.80531000",
                            "longitude": "-76.87460000"
                        },
                        {
                            "id": 111352,
                            "name": "Annapolis",
                            "latitude": "38.97845000",
                            "longitude": "-76.49218000"
                        },
                        {
                            "id": 111353,
                            "name": "Anne Arundel County",
                            "latitude": "38.99416000",
                            "longitude": "-76.56760000"
                        },
                        {
                            "id": 111417,
                            "name": "Arbutus",
                            "latitude": "39.25455000",
                            "longitude": "-76.69997000"
                        },
                        {
                            "id": 111439,
                            "name": "Arden on the Severn",
                            "latitude": "39.06594000",
                            "longitude": "-76.57885000"
                        },
                        {
                            "id": 111461,
                            "name": "Arlington",
                            "latitude": "39.34857000",
                            "longitude": "-76.68324000"
                        },
                        {
                            "id": 111485,
                            "name": "Arnold",
                            "latitude": "39.03206000",
                            "longitude": "-76.50274000"
                        },
                        {
                            "id": 111547,
                            "name": "Ashton-Sandy Spring",
                            "latitude": "39.14976000",
                            "longitude": "-77.00504000"
                        },
                        {
                            "id": 111553,
                            "name": "Aspen Hill",
                            "latitude": "39.07955000",
                            "longitude": "-77.07303000"
                        },
                        {
                            "id": 111722,
                            "name": "Baden",
                            "latitude": "38.65928000",
                            "longitude": "-76.77775000"
                        },
                        {
                            "id": 111772,
                            "name": "Ballenger Creek",
                            "latitude": "39.37260000",
                            "longitude": "-77.43526000"
                        },
                        {
                            "id": 111783,
                            "name": "Baltimore",
                            "latitude": "39.29038000",
                            "longitude": "-76.61219000"
                        },
                        {
                            "id": 111784,
                            "name": "Baltimore County",
                            "latitude": "39.44307000",
                            "longitude": "-76.61632000"
                        },
                        {
                            "id": 111785,
                            "name": "Baltimore Highlands",
                            "latitude": "39.23316000",
                            "longitude": "-76.63663000"
                        },
                        {
                            "id": 111869,
                            "name": "Bartonsville",
                            "latitude": "39.39260000",
                            "longitude": "-77.35804000"
                        },
                        {
                            "id": 112046,
                            "name": "Bel Air",
                            "latitude": "39.53594000",
                            "longitude": "-76.34829000"
                        },
                        {
                            "id": 112047,
                            "name": "Bel Air North",
                            "latitude": "39.55429000",
                            "longitude": "-76.37309000"
                        },
                        {
                            "id": 112048,
                            "name": "Bel Air South",
                            "latitude": "39.50506000",
                            "longitude": "-76.31977000"
                        },
                        {
                            "id": 112151,
                            "name": "Beltsville",
                            "latitude": "39.03483000",
                            "longitude": "-76.90747000"
                        },
                        {
                            "id": 112179,
                            "name": "Bennsville",
                            "latitude": "38.60929000",
                            "longitude": "-77.01220000"
                        },
                        {
                            "id": 112234,
                            "name": "Berlin",
                            "latitude": "38.32262000",
                            "longitude": "-75.21769000"
                        },
                        {
                            "id": 112266,
                            "name": "Berwyn Heights",
                            "latitude": "38.99400000",
                            "longitude": "-76.91053000"
                        },
                        {
                            "id": 112288,
                            "name": "Bethesda",
                            "latitude": "38.98067000",
                            "longitude": "-77.10026000"
                        },
                        {
                            "id": 112400,
                            "name": "Bladensburg",
                            "latitude": "38.93928000",
                            "longitude": "-76.93386000"
                        },
                        {
                            "id": 112565,
                            "name": "Boonsboro",
                            "latitude": "39.50621000",
                            "longitude": "-77.65249000"
                        },
                        {
                            "id": 112623,
                            "name": "Bowie",
                            "latitude": "38.94278000",
                            "longitude": "-76.73028000"
                        },
                        {
                            "id": 112626,
                            "name": "Bowleys Quarters",
                            "latitude": "39.33539000",
                            "longitude": "-76.39024000"
                        },
                        {
                            "id": 112629,
                            "name": "Bowling Green",
                            "latitude": "39.62370000",
                            "longitude": "-78.80446000"
                        },
                        {
                            "id": 112660,
                            "name": "Braddock Heights",
                            "latitude": "39.41871000",
                            "longitude": "-77.50360000"
                        },
                        {
                            "id": 112692,
                            "name": "Brandywine",
                            "latitude": "38.69678000",
                            "longitude": "-76.84775000"
                        },
                        {
                            "id": 112728,
                            "name": "Brentwood",
                            "latitude": "38.94317000",
                            "longitude": "-76.95664000"
                        },
                        {
                            "id": 112832,
                            "name": "Brock Hall",
                            "latitude": "38.85011000",
                            "longitude": "-76.76108000"
                        },
                        {
                            "id": 112876,
                            "name": "Brooklyn Park",
                            "latitude": "39.22844000",
                            "longitude": "-76.61636000"
                        },
                        {
                            "id": 112878,
                            "name": "Brookmont",
                            "latitude": "38.94206000",
                            "longitude": "-77.12026000"
                        },
                        {
                            "id": 112948,
                            "name": "Brunswick",
                            "latitude": "39.31427000",
                            "longitude": "-77.62777000"
                        },
                        {
                            "id": 112962,
                            "name": "Bryans Road",
                            "latitude": "38.62706000",
                            "longitude": "-77.07303000"
                        },
                        {
                            "id": 112978,
                            "name": "Buckeystown",
                            "latitude": "39.33482000",
                            "longitude": "-77.43165000"
                        },
                        {
                            "id": 113098,
                            "name": "Burtonsville",
                            "latitude": "39.11122000",
                            "longitude": "-76.93248000"
                        },
                        {
                            "id": 113105,
                            "name": "Butcher's Hill",
                            "latitude": "39.28955000",
                            "longitude": "-76.58830000"
                        },
                        {
                            "id": 113152,
                            "name": "Cabin John",
                            "latitude": "38.97539000",
                            "longitude": "-77.15803000"
                        },
                        {
                            "id": 113216,
                            "name": "California",
                            "latitude": "38.30040000",
                            "longitude": "-76.50745000"
                        },
                        {
                            "id": 113235,
                            "name": "Calvert County",
                            "latitude": "38.53471000",
                            "longitude": "-76.53056000"
                        },
                        {
                            "id": 113236,
                            "name": "Calverton",
                            "latitude": "39.05761000",
                            "longitude": "-76.93581000"
                        },
                        {
                            "id": 113250,
                            "name": "Cambridge",
                            "latitude": "38.56317000",
                            "longitude": "-76.07883000"
                        },
                        {
                            "id": 113293,
                            "name": "Camp Springs",
                            "latitude": "38.80400000",
                            "longitude": "-76.90664000"
                        },
                        {
                            "id": 113373,
                            "name": "Cape Saint Claire",
                            "latitude": "39.04317000",
                            "longitude": "-76.44496000"
                        },
                        {
                            "id": 113375,
                            "name": "Capitol Heights",
                            "latitude": "38.88511000",
                            "longitude": "-76.91581000"
                        },
                        {
                            "id": 113428,
                            "name": "Carney",
                            "latitude": "39.39427000",
                            "longitude": "-76.52358000"
                        },
                        {
                            "id": 113437,
                            "name": "Caroline County",
                            "latitude": "38.87173000",
                            "longitude": "-75.83160000"
                        },
                        {
                            "id": 113453,
                            "name": "Carroll County",
                            "latitude": "39.56286000",
                            "longitude": "-77.02252000"
                        },
                        {
                            "id": 113575,
                            "name": "Catonsville",
                            "latitude": "39.27205000",
                            "longitude": "-76.73192000"
                        },
                        {
                            "id": 113590,
                            "name": "Cavetown",
                            "latitude": "39.64426000",
                            "longitude": "-77.58582000"
                        },
                        {
                            "id": 113598,
                            "name": "Cecil County",
                            "latitude": "39.56242000",
                            "longitude": "-75.94811000"
                        },
                        {
                            "id": 113693,
                            "name": "Centreville",
                            "latitude": "39.04178000",
                            "longitude": "-76.06633000"
                        },
                        {
                            "id": 113757,
                            "name": "Charles County",
                            "latitude": "38.47368000",
                            "longitude": "-77.01348000"
                        },
                        {
                            "id": 113760,
                            "name": "Charles Village",
                            "latitude": "39.32316000",
                            "longitude": "-76.61330000"
                        },
                        {
                            "id": 113771,
                            "name": "Charlestown",
                            "latitude": "39.57373000",
                            "longitude": "-75.97495000"
                        },
                        {
                            "id": 113784,
                            "name": "Charlotte Hall",
                            "latitude": "38.48096000",
                            "longitude": "-76.77802000"
                        },
                        {
                            "id": 113875,
                            "name": "Chesapeake Beach",
                            "latitude": "38.68623000",
                            "longitude": "-76.53468000"
                        },
                        {
                            "id": 113876,
                            "name": "Chesapeake Ranch Estates",
                            "latitude": "38.34624000",
                            "longitude": "-76.41773000"
                        },
                        {
                            "id": 113883,
                            "name": "Chester",
                            "latitude": "38.97539000",
                            "longitude": "-76.28940000"
                        },
                        {
                            "id": 113910,
                            "name": "Chestertown",
                            "latitude": "39.20900000",
                            "longitude": "-76.06661000"
                        },
                        {
                            "id": 113919,
                            "name": "Cheverly",
                            "latitude": "38.92817000",
                            "longitude": "-76.91581000"
                        },
                        {
                            "id": 113921,
                            "name": "Chevy Chase",
                            "latitude": "39.00287000",
                            "longitude": "-77.07115000"
                        },
                        {
                            "id": 113923,
                            "name": "Chevy Chase Village",
                            "latitude": "38.96928000",
                            "longitude": "-77.07887000"
                        },
                        {
                            "id": 113955,
                            "name": "Chillum",
                            "latitude": "38.96372000",
                            "longitude": "-76.99081000"
                        },
                        {
                            "id": 114048,
                            "name": "City of Baltimore",
                            "latitude": "39.29038000",
                            "longitude": "-76.61219000"
                        },
                        {
                            "id": 114143,
                            "name": "Clarksburg",
                            "latitude": "39.23872000",
                            "longitude": "-77.27943000"
                        },
                        {
                            "id": 114272,
                            "name": "Clinton",
                            "latitude": "38.76511000",
                            "longitude": "-76.89831000"
                        },
                        {
                            "id": 114308,
                            "name": "Clover Hill",
                            "latitude": "39.45621000",
                            "longitude": "-77.42887000"
                        },
                        {
                            "id": 114313,
                            "name": "Cloverly",
                            "latitude": "39.10816000",
                            "longitude": "-76.99775000"
                        },
                        {
                            "id": 114345,
                            "name": "Cobb Island",
                            "latitude": "38.25846000",
                            "longitude": "-76.84386000"
                        },
                        {
                            "id": 114355,
                            "name": "Cockeysville",
                            "latitude": "39.48122000",
                            "longitude": "-76.64386000"
                        },
                        {
                            "id": 114403,
                            "name": "Colesville",
                            "latitude": "39.07566000",
                            "longitude": "-77.00192000"
                        },
                        {
                            "id": 114414,
                            "name": "College Park",
                            "latitude": "38.98067000",
                            "longitude": "-76.93692000"
                        },
                        {
                            "id": 114441,
                            "name": "Colmar Manor",
                            "latitude": "38.93317000",
                            "longitude": "-76.94581000"
                        },
                        {
                            "id": 114467,
                            "name": "Columbia",
                            "latitude": "39.24038000",
                            "longitude": "-76.83942000"
                        },
                        {
                            "id": 114625,
                            "name": "Coral Hills",
                            "latitude": "38.87039000",
                            "longitude": "-76.92108000"
                        },
                        {
                            "id": 114690,
                            "name": "Cottage City",
                            "latitude": "38.93817000",
                            "longitude": "-76.94831000"
                        },
                        {
                            "id": 114793,
                            "name": "Cresaptown",
                            "latitude": "39.59287000",
                            "longitude": "-78.83335000"
                        },
                        {
                            "id": 114822,
                            "name": "Crisfield",
                            "latitude": "37.98346000",
                            "longitude": "-75.85382000"
                        },
                        {
                            "id": 114833,
                            "name": "Crofton",
                            "latitude": "39.00178000",
                            "longitude": "-76.68747000"
                        },
                        {
                            "id": 114841,
                            "name": "Croom",
                            "latitude": "38.75262000",
                            "longitude": "-76.76386000"
                        },
                        {
                            "id": 114869,
                            "name": "Crownsville",
                            "latitude": "39.02844000",
                            "longitude": "-76.60135000"
                        },
                        {
                            "id": 114907,
                            "name": "Cumberland",
                            "latitude": "39.65287000",
                            "longitude": "-78.76252000"
                        },
                        {
                            "id": 115009,
                            "name": "Damascus",
                            "latitude": "39.28844000",
                            "longitude": "-77.20387000"
                        },
                        {
                            "id": 115053,
                            "name": "Darnestown",
                            "latitude": "39.10344000",
                            "longitude": "-77.29082000"
                        },
                        {
                            "id": 115065,
                            "name": "Davidsonville",
                            "latitude": "38.92289000",
                            "longitude": "-76.62830000"
                        },
                        {
                            "id": 115144,
                            "name": "Deale",
                            "latitude": "38.77651000",
                            "longitude": "-76.55524000"
                        },
                        {
                            "id": 115245,
                            "name": "Denton",
                            "latitude": "38.88456000",
                            "longitude": "-75.82716000"
                        },
                        {
                            "id": 115268,
                            "name": "Derwood",
                            "latitude": "39.11733000",
                            "longitude": "-77.16109000"
                        },
                        {
                            "id": 115355,
                            "name": "District Heights",
                            "latitude": "38.85761000",
                            "longitude": "-76.88942000"
                        },
                        {
                            "id": 115410,
                            "name": "Dorchester County",
                            "latitude": "38.42261000",
                            "longitude": "-76.08332000"
                        },
                        {
                            "id": 115481,
                            "name": "Drum Point",
                            "latitude": "38.32679000",
                            "longitude": "-76.42606000"
                        },
                        {
                            "id": 115526,
                            "name": "Dundalk",
                            "latitude": "39.25066000",
                            "longitude": "-76.52052000"
                        },
                        {
                            "id": 115537,
                            "name": "Dunkirk",
                            "latitude": "38.72178000",
                            "longitude": "-76.66052000"
                        },
                        {
                            "id": 115540,
                            "name": "Dunkirk Town Center",
                            "latitude": "38.72039000",
                            "longitude": "-76.65857000"
                        },
                        {
                            "id": 115748,
                            "name": "East Riverdale",
                            "latitude": "38.96206000",
                            "longitude": "-76.92192000"
                        },
                        {
                            "id": 115785,
                            "name": "Easton",
                            "latitude": "38.77428000",
                            "longitude": "-76.07633000"
                        },
                        {
                            "id": 115844,
                            "name": "Edgemere",
                            "latitude": "39.24205000",
                            "longitude": "-76.44802000"
                        },
                        {
                            "id": 115852,
                            "name": "Edgewater",
                            "latitude": "38.95706000",
                            "longitude": "-76.54996000"
                        },
                        {
                            "id": 115859,
                            "name": "Edgewood",
                            "latitude": "39.41872000",
                            "longitude": "-76.29440000"
                        },
                        {
                            "id": 115880,
                            "name": "Edmonston",
                            "latitude": "38.94678000",
                            "longitude": "-76.93109000"
                        },
                        {
                            "id": 115949,
                            "name": "Eldersburg",
                            "latitude": "39.40371000",
                            "longitude": "-76.95026000"
                        },
                        {
                            "id": 116011,
                            "name": "Elkridge",
                            "latitude": "39.21261000",
                            "longitude": "-76.71358000"
                        },
                        {
                            "id": 116013,
                            "name": "Elkton",
                            "latitude": "39.60678000",
                            "longitude": "-75.83327000"
                        },
                        {
                            "id": 116024,
                            "name": "Ellicott City",
                            "latitude": "39.26733000",
                            "longitude": "-76.79831000"
                        },
                        {
                            "id": 116106,
                            "name": "Emmitsburg",
                            "latitude": "39.70454000",
                            "longitude": "-77.32693000"
                        },
                        {
                            "id": 116191,
                            "name": "Essex",
                            "latitude": "39.30927000",
                            "longitude": "-76.47496000"
                        },
                        {
                            "id": 116328,
                            "name": "Fairland",
                            "latitude": "39.07622000",
                            "longitude": "-76.95775000"
                        },
                        {
                            "id": 116342,
                            "name": "Fairmount Heights",
                            "latitude": "38.90095000",
                            "longitude": "-76.91553000"
                        },
                        {
                            "id": 116364,
                            "name": "Fairwood",
                            "latitude": "38.95665000",
                            "longitude": "-76.77772000"
                        },
                        {
                            "id": 116385,
                            "name": "Fallston",
                            "latitude": "39.51455000",
                            "longitude": "-76.41107000"
                        },
                        {
                            "id": 116466,
                            "name": "Federalsburg",
                            "latitude": "38.69428000",
                            "longitude": "-75.77216000"
                        },
                        {
                            "id": 116486,
                            "name": "Ferndale",
                            "latitude": "39.18316000",
                            "longitude": "-76.64024000"
                        },
                        {
                            "id": 116652,
                            "name": "Forest Glen",
                            "latitude": "39.01455000",
                            "longitude": "-77.05470000"
                        },
                        {
                            "id": 116654,
                            "name": "Forest Heights",
                            "latitude": "38.80956000",
                            "longitude": "-76.99803000"
                        },
                        {
                            "id": 116672,
                            "name": "Forestville",
                            "latitude": "38.84511000",
                            "longitude": "-76.87497000"
                        },
                        {
                            "id": 116712,
                            "name": "Fort George G Mead Junction",
                            "latitude": "39.12594000",
                            "longitude": "-76.78914000"
                        },
                        {
                            "id": 116729,
                            "name": "Fort Meade",
                            "latitude": "39.10815000",
                            "longitude": "-76.74323000"
                        },
                        {
                            "id": 116761,
                            "name": "Fort Washington",
                            "latitude": "38.70734000",
                            "longitude": "-77.02303000"
                        },
                        {
                            "id": 116786,
                            "name": "Fountainhead-Orchard Hills",
                            "latitude": "39.68636000",
                            "longitude": "-77.71901000"
                        },
                        {
                            "id": 116787,
                            "name": "Four Corners",
                            "latitude": "39.02039000",
                            "longitude": "-77.01275000"
                        },
                        {
                            "id": 116885,
                            "name": "Frederick",
                            "latitude": "39.41427000",
                            "longitude": "-77.41054000"
                        },
                        {
                            "id": 116888,
                            "name": "Frederick County",
                            "latitude": "39.47222000",
                            "longitude": "-77.39799000"
                        },
                        {
                            "id": 116947,
                            "name": "Friendly",
                            "latitude": "38.75178000",
                            "longitude": "-76.97859000"
                        },
                        {
                            "id": 116951,
                            "name": "Friendship Village",
                            "latitude": "38.96289000",
                            "longitude": "-77.08887000"
                        },
                        {
                            "id": 116964,
                            "name": "Frostburg",
                            "latitude": "39.65814000",
                            "longitude": "-78.92836000"
                        },
                        {
                            "id": 116970,
                            "name": "Fruitland",
                            "latitude": "38.32206000",
                            "longitude": "-75.62020000"
                        },
                        {
                            "id": 116987,
                            "name": "Fulton",
                            "latitude": "39.15094000",
                            "longitude": "-76.92303000"
                        },
                        {
                            "id": 117019,
                            "name": "Gaithersburg",
                            "latitude": "39.14344000",
                            "longitude": "-77.20137000"
                        },
                        {
                            "id": 117045,
                            "name": "Gambrills",
                            "latitude": "39.06705000",
                            "longitude": "-76.66524000"
                        },
                        {
                            "id": 117100,
                            "name": "Garrett County",
                            "latitude": "39.52860000",
                            "longitude": "-79.27388000"
                        },
                        {
                            "id": 117101,
                            "name": "Garrett Park",
                            "latitude": "39.03816000",
                            "longitude": "-77.09303000"
                        },
                        {
                            "id": 117102,
                            "name": "Garrison",
                            "latitude": "39.40594000",
                            "longitude": "-76.76053000"
                        },
                        {
                            "id": 117172,
                            "name": "Germantown",
                            "latitude": "39.17316000",
                            "longitude": "-77.27165000"
                        },
                        {
                            "id": 117253,
                            "name": "Glassmanor",
                            "latitude": "38.81900000",
                            "longitude": "-76.99859000"
                        },
                        {
                            "id": 117262,
                            "name": "Glen Burnie",
                            "latitude": "39.16261000",
                            "longitude": "-76.62469000"
                        },
                        {
                            "id": 117275,
                            "name": "Glenarden",
                            "latitude": "38.92928000",
                            "longitude": "-76.86164000"
                        },
                        {
                            "id": 117293,
                            "name": "Glenmont",
                            "latitude": "39.05789000",
                            "longitude": "-77.04970000"
                        },
                        {
                            "id": 117297,
                            "name": "Glenn Dale",
                            "latitude": "38.98761000",
                            "longitude": "-76.82053000"
                        },
                        {
                            "id": 117333,
                            "name": "Goddard",
                            "latitude": "38.98955000",
                            "longitude": "-76.85331000"
                        },
                        {
                            "id": 117347,
                            "name": "Golden Beach",
                            "latitude": "38.48985000",
                            "longitude": "-76.68218000"
                        },
                        {
                            "id": 117541,
                            "name": "Grasonville",
                            "latitude": "38.95817000",
                            "longitude": "-76.21023000"
                        },
                        {
                            "id": 117582,
                            "name": "Greater Upper Marlboro",
                            "latitude": "38.83142000",
                            "longitude": "-76.74827000"
                        },
                        {
                            "id": 117597,
                            "name": "Green Haven",
                            "latitude": "39.13955000",
                            "longitude": "-76.54774000"
                        },
                        {
                            "id": 117609,
                            "name": "Green Valley",
                            "latitude": "39.30927000",
                            "longitude": "-77.29721000"
                        },
                        {
                            "id": 117616,
                            "name": "Greenbelt",
                            "latitude": "39.00455000",
                            "longitude": "-76.87553000"
                        },
                        {
                            "id": 117666,
                            "name": "Greensboro",
                            "latitude": "38.97372000",
                            "longitude": "-75.80493000"
                        },
                        {
                            "id": 117838,
                            "name": "Hagerstown",
                            "latitude": "39.64176000",
                            "longitude": "-77.71999000"
                        },
                        {
                            "id": 117857,
                            "name": "Halfway",
                            "latitude": "39.62065000",
                            "longitude": "-77.75888000"
                        },
                        {
                            "id": 117923,
                            "name": "Hampstead",
                            "latitude": "39.60483000",
                            "longitude": "-76.84998000"
                        },
                        {
                            "id": 117928,
                            "name": "Hampton",
                            "latitude": "39.42288000",
                            "longitude": "-76.58469000"
                        },
                        {
                            "id": 117947,
                            "name": "Hancock",
                            "latitude": "39.69898000",
                            "longitude": "-78.17973000"
                        },
                        {
                            "id": 117967,
                            "name": "Hanover",
                            "latitude": "39.19289000",
                            "longitude": "-76.72414000"
                        },
                        {
                            "id": 118012,
                            "name": "Harford County",
                            "latitude": "39.53644000",
                            "longitude": "-76.29887000"
                        },
                        {
                            "id": 118155,
                            "name": "Havre de Grace",
                            "latitude": "39.54928000",
                            "longitude": "-76.09162000"
                        },
                        {
                            "id": 118233,
                            "name": "Hebron",
                            "latitude": "38.42012000",
                            "longitude": "-75.68771000"
                        },
                        {
                            "id": 118312,
                            "name": "Herald Harbor",
                            "latitude": "39.05372000",
                            "longitude": "-76.56913000"
                        },
                        {
                            "id": 118390,
                            "name": "Highfield-Cascade",
                            "latitude": "39.71616000",
                            "longitude": "-77.48282000"
                        },
                        {
                            "id": 118395,
                            "name": "Highland",
                            "latitude": "39.17900000",
                            "longitude": "-76.95748000"
                        },
                        {
                            "id": 118438,
                            "name": "Hillandale",
                            "latitude": "39.02650000",
                            "longitude": "-76.97414000"
                        },
                        {
                            "id": 118441,
                            "name": "Hillcrest Heights",
                            "latitude": "38.83289000",
                            "longitude": "-76.95942000"
                        },
                        {
                            "id": 118467,
                            "name": "Hillsmere Shores",
                            "latitude": "38.94011000",
                            "longitude": "-76.49496000"
                        },
                        {
                            "id": 118713,
                            "name": "Howard County",
                            "latitude": "39.25072000",
                            "longitude": "-76.93119000"
                        },
                        {
                            "id": 118762,
                            "name": "Hughesville",
                            "latitude": "38.53262000",
                            "longitude": "-76.78386000"
                        },
                        {
                            "id": 118787,
                            "name": "Hunt Valley",
                            "latitude": "39.49983000",
                            "longitude": "-76.64108000"
                        },
                        {
                            "id": 118810,
                            "name": "Huntingtown",
                            "latitude": "38.61595000",
                            "longitude": "-76.61302000"
                        },
                        {
                            "id": 118811,
                            "name": "Huntingtown Town Center",
                            "latitude": "38.62095000",
                            "longitude": "-76.61607000"
                        },
                        {
                            "id": 118823,
                            "name": "Hurlock",
                            "latitude": "38.62428000",
                            "longitude": "-75.85438000"
                        },
                        {
                            "id": 118844,
                            "name": "Hyattsville",
                            "latitude": "38.95594000",
                            "longitude": "-76.94553000"
                        },
                        {
                            "id": 118877,
                            "name": "Ilchester",
                            "latitude": "39.25094000",
                            "longitude": "-76.76469000"
                        },
                        {
                            "id": 118903,
                            "name": "Indian Head",
                            "latitude": "38.60012000",
                            "longitude": "-77.16220000"
                        },
                        {
                            "id": 119008,
                            "name": "Irvington",
                            "latitude": "39.28288000",
                            "longitude": "-76.68608000"
                        },
                        {
                            "id": 119135,
                            "name": "Jarrettsville",
                            "latitude": "39.60455000",
                            "longitude": "-76.47774000"
                        },
                        {
                            "id": 119164,
                            "name": "Jefferson",
                            "latitude": "39.36205000",
                            "longitude": "-77.53165000"
                        },
                        {
                            "id": 119237,
                            "name": "Jessup",
                            "latitude": "39.14927000",
                            "longitude": "-76.77525000"
                        },
                        {
                            "id": 119314,
                            "name": "Joppatowne",
                            "latitude": "39.45789000",
                            "longitude": "-76.35524000"
                        },
                        {
                            "id": 119417,
                            "name": "Keedysville",
                            "latitude": "39.48621000",
                            "longitude": "-77.69971000"
                        },
                        {
                            "id": 119436,
                            "name": "Kemp Mill",
                            "latitude": "39.03900000",
                            "longitude": "-77.01914000"
                        },
                        {
                            "id": 119480,
                            "name": "Kensington",
                            "latitude": "39.02567000",
                            "longitude": "-77.07637000"
                        },
                        {
                            "id": 119491,
                            "name": "Kent County",
                            "latitude": "39.23560000",
                            "longitude": "-76.09582000"
                        },
                        {
                            "id": 119526,
                            "name": "Kettering",
                            "latitude": "38.88456000",
                            "longitude": "-76.81469000"
                        },
                        {
                            "id": 119633,
                            "name": "Kingstown",
                            "latitude": "39.20483000",
                            "longitude": "-76.05133000"
                        },
                        {
                            "id": 119635,
                            "name": "Kingsville",
                            "latitude": "39.44872000",
                            "longitude": "-76.41774000"
                        },
                        {
                            "id": 119773,
                            "name": "La Plata",
                            "latitude": "38.52929000",
                            "longitude": "-76.97525000"
                        },
                        {
                            "id": 119791,
                            "name": "La Vale",
                            "latitude": "39.65564000",
                            "longitude": "-78.81058000"
                        },
                        {
                            "id": 119864,
                            "name": "Lake Arbor",
                            "latitude": "38.90789000",
                            "longitude": "-76.82969000"
                        },
                        {
                            "id": 119976,
                            "name": "Lake Shore",
                            "latitude": "39.10705000",
                            "longitude": "-76.48496000"
                        },
                        {
                            "id": 120102,
                            "name": "Landover",
                            "latitude": "38.93400000",
                            "longitude": "-76.89664000"
                        },
                        {
                            "id": 120103,
                            "name": "Landover Hills",
                            "latitude": "38.94317000",
                            "longitude": "-76.89220000"
                        },
                        {
                            "id": 120115,
                            "name": "Langley Park",
                            "latitude": "38.98872000",
                            "longitude": "-76.98136000"
                        },
                        {
                            "id": 120117,
                            "name": "Lanham",
                            "latitude": "38.96875000",
                            "longitude": "-76.86340000"
                        },
                        {
                            "id": 120118,
                            "name": "Lanham-Seabrook",
                            "latitude": "38.96835000",
                            "longitude": "-76.85108000"
                        },
                        {
                            "id": 120122,
                            "name": "Lansdowne",
                            "latitude": "39.24511000",
                            "longitude": "-76.66052000"
                        },
                        {
                            "id": 120144,
                            "name": "Largo",
                            "latitude": "38.89761000",
                            "longitude": "-76.83025000"
                        },
                        {
                            "id": 120189,
                            "name": "Laurel",
                            "latitude": "39.09928000",
                            "longitude": "-76.84831000"
                        },
                        {
                            "id": 120252,
                            "name": "Layhill",
                            "latitude": "39.09233000",
                            "longitude": "-77.04442000"
                        },
                        {
                            "id": 120345,
                            "name": "Leisure World",
                            "latitude": "39.10230000",
                            "longitude": "-77.06898000"
                        },
                        {
                            "id": 120390,
                            "name": "Leonardtown",
                            "latitude": "38.29124000",
                            "longitude": "-76.63579000"
                        },
                        {
                            "id": 120455,
                            "name": "Lexington Park",
                            "latitude": "38.26679000",
                            "longitude": "-76.45384000"
                        },
                        {
                            "id": 120578,
                            "name": "Linganore",
                            "latitude": "39.44038000",
                            "longitude": "-77.20804000"
                        },
                        {
                            "id": 120588,
                            "name": "Linthicum",
                            "latitude": "39.20511000",
                            "longitude": "-76.65275000"
                        },
                        {
                            "id": 120671,
                            "name": "Lochearn",
                            "latitude": "39.34066000",
                            "longitude": "-76.72219000"
                        },
                        {
                            "id": 120719,
                            "name": "Lonaconing",
                            "latitude": "39.56592000",
                            "longitude": "-78.98030000"
                        },
                        {
                            "id": 120725,
                            "name": "Londontowne",
                            "latitude": "38.93345000",
                            "longitude": "-76.54941000"
                        },
                        {
                            "id": 120733,
                            "name": "Long Beach",
                            "latitude": "38.46096000",
                            "longitude": "-76.46884000"
                        },
                        {
                            "id": 120891,
                            "name": "Lusby",
                            "latitude": "38.41068000",
                            "longitude": "-76.45523000"
                        },
                        {
                            "id": 120895,
                            "name": "Lutherville",
                            "latitude": "39.42122000",
                            "longitude": "-76.62608000"
                        },
                        {
                            "id": 120896,
                            "name": "Lutherville-Timonium",
                            "latitude": "39.43997000",
                            "longitude": "-76.61099000"
                        },
                        {
                            "id": 121090,
                            "name": "Manchester",
                            "latitude": "39.66121000",
                            "longitude": "-76.88498000"
                        },
                        {
                            "id": 121277,
                            "name": "Marlboro Meadows",
                            "latitude": "38.83622000",
                            "longitude": "-76.71497000"
                        },
                        {
                            "id": 121278,
                            "name": "Marlboro Village",
                            "latitude": "38.83054000",
                            "longitude": "-76.76965000"
                        },
                        {
                            "id": 121286,
                            "name": "Marlow Heights",
                            "latitude": "38.83345000",
                            "longitude": "-76.95164000"
                        },
                        {
                            "id": 121287,
                            "name": "Marlton",
                            "latitude": "38.77373000",
                            "longitude": "-76.78997000"
                        },
                        {
                            "id": 121355,
                            "name": "Maryland City",
                            "latitude": "39.09205000",
                            "longitude": "-76.81775000"
                        },
                        {
                            "id": 121418,
                            "name": "Maugansville",
                            "latitude": "39.69287000",
                            "longitude": "-77.74472000"
                        },
                        {
                            "id": 121443,
                            "name": "Mayo",
                            "latitude": "38.88761000",
                            "longitude": "-76.51190000"
                        },
                        {
                            "id": 121446,
                            "name": "Mays Chapel",
                            "latitude": "39.43316000",
                            "longitude": "-76.64941000"
                        },
                        {
                            "id": 121582,
                            "name": "Mechanicsville",
                            "latitude": "38.44290000",
                            "longitude": "-76.74385000"
                        },
                        {
                            "id": 121626,
                            "name": "Mellwood",
                            "latitude": "38.81039000",
                            "longitude": "-76.82414000"
                        },
                        {
                            "id": 121762,
                            "name": "Middle River",
                            "latitude": "39.33427000",
                            "longitude": "-76.43941000"
                        },
                        {
                            "id": 121786,
                            "name": "Middletown",
                            "latitude": "39.44371000",
                            "longitude": "-77.54471000"
                        },
                        {
                            "id": 121860,
                            "name": "Milford Mill",
                            "latitude": "39.34788000",
                            "longitude": "-76.76997000"
                        },
                        {
                            "id": 122004,
                            "name": "Mitchellville",
                            "latitude": "38.92511000",
                            "longitude": "-76.74275000"
                        },
                        {
                            "id": 122145,
                            "name": "Montgomery County",
                            "latitude": "39.13638000",
                            "longitude": "-77.20424000"
                        },
                        {
                            "id": 122156,
                            "name": "Montgomery Village",
                            "latitude": "39.17677000",
                            "longitude": "-77.19526000"
                        },
                        {
                            "id": 122257,
                            "name": "Morningside",
                            "latitude": "38.83011000",
                            "longitude": "-76.89136000"
                        },
                        {
                            "id": 122330,
                            "name": "Mount Airy",
                            "latitude": "39.37621000",
                            "longitude": "-77.15470000"
                        },
                        {
                            "id": 122387,
                            "name": "Mount Rainier",
                            "latitude": "38.94150000",
                            "longitude": "-76.96498000"
                        },
                        {
                            "id": 122421,
                            "name": "Mountain Lake Park",
                            "latitude": "39.39843000",
                            "longitude": "-79.38171000"
                        },
                        {
                            "id": 122519,
                            "name": "Myersville",
                            "latitude": "39.50510000",
                            "longitude": "-77.56638000"
                        },
                        {
                            "id": 122592,
                            "name": "National Harbor",
                            "latitude": "38.78264000",
                            "longitude": "-77.01506000"
                        },
                        {
                            "id": 122599,
                            "name": "Naval Academy",
                            "latitude": "38.98568000",
                            "longitude": "-76.48774000"
                        },
                        {
                            "id": 122681,
                            "name": "New Carrollton",
                            "latitude": "38.96983000",
                            "longitude": "-76.87997000"
                        },
                        {
                            "id": 122793,
                            "name": "New Windsor",
                            "latitude": "39.54205000",
                            "longitude": "-77.10804000"
                        },
                        {
                            "id": 122977,
                            "name": "North Beach",
                            "latitude": "38.70734000",
                            "longitude": "-76.53107000"
                        },
                        {
                            "id": 122979,
                            "name": "North Bel Air",
                            "latitude": "39.53983000",
                            "longitude": "-76.35496000"
                        },
                        {
                            "id": 122989,
                            "name": "North Bethesda",
                            "latitude": "39.04455000",
                            "longitude": "-77.11887000"
                        },
                        {
                            "id": 123017,
                            "name": "North East",
                            "latitude": "39.60011000",
                            "longitude": "-75.94133000"
                        },
                        {
                            "id": 123046,
                            "name": "North Kensington",
                            "latitude": "39.03039000",
                            "longitude": "-77.07248000"
                        },
                        {
                            "id": 123054,
                            "name": "North Laurel",
                            "latitude": "39.13900000",
                            "longitude": "-76.87053000"
                        },
                        {
                            "id": 123087,
                            "name": "North Potomac",
                            "latitude": "39.08289000",
                            "longitude": "-77.26498000"
                        },
                        {
                            "id": 123284,
                            "name": "Oakland",
                            "latitude": "39.40787000",
                            "longitude": "-79.40671000"
                        },
                        {
                            "id": 123326,
                            "name": "Ocean City",
                            "latitude": "38.33650000",
                            "longitude": "-75.08491000"
                        },
                        {
                            "id": 123333,
                            "name": "Ocean Pines",
                            "latitude": "38.39539000",
                            "longitude": "-75.15574000"
                        },
                        {
                            "id": 123356,
                            "name": "Odenton",
                            "latitude": "39.08400000",
                            "longitude": "-76.70025000"
                        },
                        {
                            "id": 123456,
                            "name": "Olney",
                            "latitude": "39.15316000",
                            "longitude": "-77.06692000"
                        },
                        {
                            "id": 123652,
                            "name": "Overlea",
                            "latitude": "39.36344000",
                            "longitude": "-76.52052000"
                        },
                        {
                            "id": 123668,
                            "name": "Owings",
                            "latitude": "38.71762000",
                            "longitude": "-76.60135000"
                        },
                        {
                            "id": 123669,
                            "name": "Owings Mills",
                            "latitude": "39.41955000",
                            "longitude": "-76.78025000"
                        },
                        {
                            "id": 123691,
                            "name": "Oxon Hill",
                            "latitude": "38.80345000",
                            "longitude": "-76.98970000"
                        },
                        {
                            "id": 123692,
                            "name": "Oxon Hill-Glassmanor",
                            "latitude": "38.79615000",
                            "longitude": "-76.97499000"
                        },
                        {
                            "id": 123825,
                            "name": "Paramount-Long Meadow",
                            "latitude": "39.68042000",
                            "longitude": "-77.69290000"
                        },
                        {
                            "id": 123877,
                            "name": "Parkville",
                            "latitude": "39.37733000",
                            "longitude": "-76.53969000"
                        },
                        {
                            "id": 123887,
                            "name": "Parole",
                            "latitude": "38.97956000",
                            "longitude": "-76.53052000"
                        },
                        {
                            "id": 123895,
                            "name": "Pasadena",
                            "latitude": "39.10733000",
                            "longitude": "-76.57108000"
                        },
                        {
                            "id": 124055,
                            "name": "Peppermill Village",
                            "latitude": "38.89472000",
                            "longitude": "-76.88654000"
                        },
                        {
                            "id": 124083,
                            "name": "Perry Hall",
                            "latitude": "39.41261000",
                            "longitude": "-76.46357000"
                        },
                        {
                            "id": 124085,
                            "name": "Perryman",
                            "latitude": "39.46955000",
                            "longitude": "-76.20440000"
                        },
                        {
                            "id": 124090,
                            "name": "Perryville",
                            "latitude": "39.56011000",
                            "longitude": "-76.07134000"
                        },
                        {
                            "id": 124197,
                            "name": "Pikesville",
                            "latitude": "39.37427000",
                            "longitude": "-76.72247000"
                        },
                        {
                            "id": 124305,
                            "name": "Pittsville",
                            "latitude": "38.39539000",
                            "longitude": "-75.41297000"
                        },
                        {
                            "id": 124373,
                            "name": "Pleasant Hills",
                            "latitude": "39.47955000",
                            "longitude": "-76.39413000"
                        },
                        {
                            "id": 124420,
                            "name": "Pocomoke City",
                            "latitude": "38.07568000",
                            "longitude": "-75.56798000"
                        },
                        {
                            "id": 124434,
                            "name": "Point of Rocks",
                            "latitude": "39.27594000",
                            "longitude": "-77.53915000"
                        },
                        {
                            "id": 124480,
                            "name": "Poolesville",
                            "latitude": "39.14594000",
                            "longitude": "-77.41693000"
                        },
                        {
                            "id": 124582,
                            "name": "Potomac",
                            "latitude": "39.01817000",
                            "longitude": "-77.20859000"
                        },
                        {
                            "id": 124583,
                            "name": "Potomac Heights",
                            "latitude": "38.60873000",
                            "longitude": "-77.14053000"
                        },
                        {
                            "id": 124585,
                            "name": "Potomac Park",
                            "latitude": "39.61176000",
                            "longitude": "-78.80585000"
                        },
                        {
                            "id": 124673,
                            "name": "Prince Frederick",
                            "latitude": "38.54040000",
                            "longitude": "-76.58440000"
                        },
                        {
                            "id": 124676,
                            "name": "Prince George's County",
                            "latitude": "38.82952000",
                            "longitude": "-76.84729000"
                        },
                        {
                            "id": 124679,
                            "name": "Princess Anne",
                            "latitude": "38.20290000",
                            "longitude": "-75.69243000"
                        },
                        {
                            "id": 124745,
                            "name": "Pumphrey",
                            "latitude": "39.21733000",
                            "longitude": "-76.63719000"
                        },
                        {
                            "id": 124785,
                            "name": "Queen Anne",
                            "latitude": "38.89872000",
                            "longitude": "-76.67830000"
                        },
                        {
                            "id": 124786,
                            "name": "Queen Anne's County",
                            "latitude": "39.03763000",
                            "longitude": "-76.08504000"
                        },
                        {
                            "id": 124789,
                            "name": "Queenland",
                            "latitude": "38.80524000",
                            "longitude": "-76.79126000"
                        },
                        {
                            "id": 124863,
                            "name": "Randallstown",
                            "latitude": "39.36733000",
                            "longitude": "-76.79525000"
                        },
                        {
                            "id": 124974,
                            "name": "Redland",
                            "latitude": "39.14539000",
                            "longitude": "-77.14415000"
                        },
                        {
                            "id": 125012,
                            "name": "Reisterstown",
                            "latitude": "39.46976000",
                            "longitude": "-76.83190000"
                        },
                        {
                            "id": 125125,
                            "name": "Ridgely",
                            "latitude": "38.94789000",
                            "longitude": "-75.88438000"
                        },
                        {
                            "id": 125170,
                            "name": "Rising Sun",
                            "latitude": "39.69789000",
                            "longitude": "-76.06273000"
                        },
                        {
                            "id": 125175,
                            "name": "Riva",
                            "latitude": "38.95206000",
                            "longitude": "-76.57802000"
                        },
                        {
                            "id": 125198,
                            "name": "Riverdale Park",
                            "latitude": "38.96344000",
                            "longitude": "-76.93164000"
                        },
                        {
                            "id": 125202,
                            "name": "Riverside",
                            "latitude": "39.47372000",
                            "longitude": "-76.24134000"
                        },
                        {
                            "id": 125222,
                            "name": "Riviera Beach",
                            "latitude": "39.16678000",
                            "longitude": "-76.50802000"
                        },
                        {
                            "id": 125255,
                            "name": "Robinwood",
                            "latitude": "39.63704000",
                            "longitude": "-77.65694000"
                        },
                        {
                            "id": 125278,
                            "name": "Rock Hall",
                            "latitude": "39.13817000",
                            "longitude": "-76.23495000"
                        },
                        {
                            "id": 125322,
                            "name": "Rockville",
                            "latitude": "39.08400000",
                            "longitude": "-77.15276000"
                        },
                        {
                            "id": 125398,
                            "name": "Rosaryville",
                            "latitude": "38.75678000",
                            "longitude": "-76.80969000"
                        },
                        {
                            "id": 125416,
                            "name": "Rosedale",
                            "latitude": "39.32011000",
                            "longitude": "-76.51552000"
                        },
                        {
                            "id": 125453,
                            "name": "Rossmoor",
                            "latitude": "39.10372000",
                            "longitude": "-77.07109000"
                        },
                        {
                            "id": 125458,
                            "name": "Rossville",
                            "latitude": "39.33844000",
                            "longitude": "-76.47968000"
                        },
                        {
                            "id": 125611,
                            "name": "Saint Charles",
                            "latitude": "38.60317000",
                            "longitude": "-76.93858000"
                        },
                        {
                            "id": 125652,
                            "name": "Saint James",
                            "latitude": "39.56260000",
                            "longitude": "-77.75805000"
                        },
                        {
                            "id": 125691,
                            "name": "Saint Mary's County",
                            "latitude": "38.21586000",
                            "longitude": "-76.52906000"
                        },
                        {
                            "id": 125698,
                            "name": "Saint Michaels",
                            "latitude": "38.78512000",
                            "longitude": "-76.22439000"
                        },
                        {
                            "id": 125754,
                            "name": "Salisbury",
                            "latitude": "38.36067000",
                            "longitude": "-75.59937000"
                        },
                        {
                            "id": 125971,
                            "name": "Savage",
                            "latitude": "39.13789000",
                            "longitude": "-76.82386000"
                        },
                        {
                            "id": 125994,
                            "name": "Scaggsville",
                            "latitude": "39.14511000",
                            "longitude": "-76.90025000"
                        },
                        {
                            "id": 126078,
                            "name": "Seabrook",
                            "latitude": "38.96805000",
                            "longitude": "-76.84658000"
                        },
                        {
                            "id": 126103,
                            "name": "Seat Pleasant",
                            "latitude": "38.89622000",
                            "longitude": "-76.90664000"
                        },
                        {
                            "id": 126131,
                            "name": "Selby-on-the-Bay",
                            "latitude": "38.91622000",
                            "longitude": "-76.52246000"
                        },
                        {
                            "id": 126178,
                            "name": "Severn",
                            "latitude": "39.13705000",
                            "longitude": "-76.69830000"
                        },
                        {
                            "id": 126179,
                            "name": "Severna Park",
                            "latitude": "39.07039000",
                            "longitude": "-76.54524000"
                        },
                        {
                            "id": 126207,
                            "name": "Shady Side",
                            "latitude": "38.84178000",
                            "longitude": "-76.51218000"
                        },
                        {
                            "id": 126426,
                            "name": "Silver Hill",
                            "latitude": "38.84178000",
                            "longitude": "-76.94581000"
                        },
                        {
                            "id": 126434,
                            "name": "Silver Spring",
                            "latitude": "38.99067000",
                            "longitude": "-77.02609000"
                        },
                        {
                            "id": 126524,
                            "name": "Smithsburg",
                            "latitude": "39.65482000",
                            "longitude": "-77.57277000"
                        },
                        {
                            "id": 126545,
                            "name": "Snow Hill",
                            "latitude": "38.17706000",
                            "longitude": "-75.39270000"
                        },
                        {
                            "id": 126570,
                            "name": "Solomons",
                            "latitude": "38.31846000",
                            "longitude": "-76.45412000"
                        },
                        {
                            "id": 126579,
                            "name": "Somerset",
                            "latitude": "38.96594000",
                            "longitude": "-77.09609000"
                        },
                        {
                            "id": 126586,
                            "name": "Somerset County",
                            "latitude": "38.08007000",
                            "longitude": "-75.85347000"
                        },
                        {
                            "id": 126621,
                            "name": "South Bel Air",
                            "latitude": "39.53316000",
                            "longitude": "-76.33746000"
                        },
                        {
                            "id": 126658,
                            "name": "South Gate",
                            "latitude": "39.12900000",
                            "longitude": "-76.62580000"
                        },
                        {
                            "id": 126682,
                            "name": "South Kensington",
                            "latitude": "39.01900000",
                            "longitude": "-77.07998000"
                        },
                        {
                            "id": 126686,
                            "name": "South Laurel",
                            "latitude": "39.06983000",
                            "longitude": "-76.85025000"
                        },
                        {
                            "id": 126831,
                            "name": "Spencerville",
                            "latitude": "39.11427000",
                            "longitude": "-76.97831000"
                        },
                        {
                            "id": 126872,
                            "name": "Spring Ridge",
                            "latitude": "39.40149000",
                            "longitude": "-77.35248000"
                        },
                        {
                            "id": 126884,
                            "name": "Springdale",
                            "latitude": "38.93761000",
                            "longitude": "-76.83886000"
                        },
                        {
                            "id": 127047,
                            "name": "Stevensville",
                            "latitude": "38.98067000",
                            "longitude": "-76.31440000"
                        },
                        {
                            "id": 127178,
                            "name": "Suitland",
                            "latitude": "38.84872000",
                            "longitude": "-76.92386000"
                        },
                        {
                            "id": 127179,
                            "name": "Suitland-Silver Hill",
                            "latitude": "38.84685000",
                            "longitude": "-76.92591000"
                        },
                        {
                            "id": 127203,
                            "name": "Summerfield",
                            "latitude": "38.90454000",
                            "longitude": "-76.86830000"
                        },
                        {
                            "id": 127352,
                            "name": "Sykesville",
                            "latitude": "39.37371000",
                            "longitude": "-76.96776000"
                        },
                        {
                            "id": 127382,
                            "name": "Takoma Park",
                            "latitude": "38.97789000",
                            "longitude": "-77.00748000"
                        },
                        {
                            "id": 127384,
                            "name": "Talbot County",
                            "latitude": "38.74910000",
                            "longitude": "-76.17862000"
                        },
                        {
                            "id": 127411,
                            "name": "Taneytown",
                            "latitude": "39.65788000",
                            "longitude": "-77.17443000"
                        },
                        {
                            "id": 127502,
                            "name": "Temple Hills",
                            "latitude": "38.81400000",
                            "longitude": "-76.94553000"
                        },
                        {
                            "id": 127629,
                            "name": "Thurmont",
                            "latitude": "39.62371000",
                            "longitude": "-77.41082000"
                        },
                        {
                            "id": 127663,
                            "name": "Timonium",
                            "latitude": "39.43705000",
                            "longitude": "-76.61969000"
                        },
                        {
                            "id": 127768,
                            "name": "Towson",
                            "latitude": "39.40150000",
                            "longitude": "-76.60191000"
                        },
                        {
                            "id": 127781,
                            "name": "Trappe",
                            "latitude": "38.65845000",
                            "longitude": "-76.05800000"
                        },
                        {
                            "id": 127786,
                            "name": "Travilah",
                            "latitude": "39.06900000",
                            "longitude": "-77.26304000"
                        },
                        {
                            "id": 128032,
                            "name": "University Park",
                            "latitude": "38.97039000",
                            "longitude": "-76.94192000"
                        },
                        {
                            "id": 128046,
                            "name": "Upper Marlboro",
                            "latitude": "38.81595000",
                            "longitude": "-76.74969000"
                        },
                        {
                            "id": 128060,
                            "name": "Urbana",
                            "latitude": "39.32594000",
                            "longitude": "-77.35137000"
                        },
                        {
                            "id": 128375,
                            "name": "Waldorf",
                            "latitude": "38.62456000",
                            "longitude": "-76.93914000"
                        },
                        {
                            "id": 128389,
                            "name": "Walker Mill",
                            "latitude": "38.87539000",
                            "longitude": "-76.88831000"
                        },
                        {
                            "id": 128390,
                            "name": "Walkersville",
                            "latitude": "39.48621000",
                            "longitude": "-77.35193000"
                        },
                        {
                            "id": 128560,
                            "name": "Washington County",
                            "latitude": "39.60367000",
                            "longitude": "-77.81398000"
                        },
                        {
                            "id": 128860,
                            "name": "West Elkridge",
                            "latitude": "39.20705000",
                            "longitude": "-76.72692000"
                        },
                        {
                            "id": 128912,
                            "name": "West Laurel",
                            "latitude": "39.10122000",
                            "longitude": "-76.89970000"
                        },
                        {
                            "id": 128943,
                            "name": "West Ocean City",
                            "latitude": "38.33150000",
                            "longitude": "-75.10685000"
                        },
                        {
                            "id": 129026,
                            "name": "Westernport",
                            "latitude": "39.48537000",
                            "longitude": "-79.04475000"
                        },
                        {
                            "id": 129043,
                            "name": "Westminster",
                            "latitude": "39.57538000",
                            "longitude": "-76.99581000"
                        },
                        {
                            "id": 129069,
                            "name": "Westphalia",
                            "latitude": "38.84539000",
                            "longitude": "-76.81108000"
                        },
                        {
                            "id": 129112,
                            "name": "Wheaton",
                            "latitude": "39.03983000",
                            "longitude": "-77.05526000"
                        },
                        {
                            "id": 129145,
                            "name": "White Marsh",
                            "latitude": "39.38372000",
                            "longitude": "-76.43218000"
                        },
                        {
                            "id": 129148,
                            "name": "White Oak",
                            "latitude": "39.03983000",
                            "longitude": "-76.99303000"
                        },
                        {
                            "id": 129222,
                            "name": "Wicomico County",
                            "latitude": "38.36942000",
                            "longitude": "-75.63151000"
                        },
                        {
                            "id": 129279,
                            "name": "Williamsport",
                            "latitude": "39.60065000",
                            "longitude": "-77.82055000"
                        },
                        {
                            "id": 129338,
                            "name": "Wilson-Conococheague",
                            "latitude": "39.65351000",
                            "longitude": "-77.83157000"
                        },
                        {
                            "id": 129534,
                            "name": "Woodlawn",
                            "latitude": "39.32288000",
                            "longitude": "-76.72803000"
                        },
                        {
                            "id": 129544,
                            "name": "Woodmore",
                            "latitude": "38.92122000",
                            "longitude": "-76.80303000"
                        },
                        {
                            "id": 129552,
                            "name": "Woodsboro",
                            "latitude": "39.53316000",
                            "longitude": "-77.31471000"
                        },
                        {
                            "id": 129586,
                            "name": "Worcester County",
                            "latitude": "38.21650000",
                            "longitude": "-75.29667000"
                        }
                    ]
                },
                {
                    "id": 1433,
                    "name": "Massachusetts",
                    "state_code": "MA",
                    "cities": [
                        {
                            "id": 110986,
                            "name": "Abington",
                            "latitude": "42.10482000",
                            "longitude": "-70.94532000"
                        },
                        {
                            "id": 110999,
                            "name": "Acton",
                            "latitude": "42.48509000",
                            "longitude": "-71.43284000"
                        },
                        {
                            "id": 111002,
                            "name": "Acushnet",
                            "latitude": "41.68066000",
                            "longitude": "-70.90782000"
                        },
                        {
                            "id": 111003,
                            "name": "Acushnet Center",
                            "latitude": "41.68508000",
                            "longitude": "-70.90642000"
                        },
                        {
                            "id": 111013,
                            "name": "Adams",
                            "latitude": "42.62425000",
                            "longitude": "-73.11760000"
                        },
                        {
                            "id": 111055,
                            "name": "Agawam",
                            "latitude": "42.06954000",
                            "longitude": "-72.61481000"
                        },
                        {
                            "id": 111279,
                            "name": "Amesbury",
                            "latitude": "42.85842000",
                            "longitude": "-70.93005000"
                        },
                        {
                            "id": 111282,
                            "name": "Amherst",
                            "latitude": "42.36723000",
                            "longitude": "-72.51852000"
                        },
                        {
                            "id": 111285,
                            "name": "Amherst Center",
                            "latitude": "42.37537000",
                            "longitude": "-72.51925000"
                        },
                        {
                            "id": 111323,
                            "name": "Andover",
                            "latitude": "42.65843000",
                            "longitude": "-71.13700000"
                        },
                        {
                            "id": 111465,
                            "name": "Arlington",
                            "latitude": "42.41537000",
                            "longitude": "-71.15644000"
                        },
                        {
                            "id": 111516,
                            "name": "Ashburnham",
                            "latitude": "42.63620000",
                            "longitude": "-71.90785000"
                        },
                        {
                            "id": 111517,
                            "name": "Ashby",
                            "latitude": "42.67787000",
                            "longitude": "-71.82035000"
                        },
                        {
                            "id": 111523,
                            "name": "Ashfield",
                            "latitude": "42.52647000",
                            "longitude": "-72.78843000"
                        },
                        {
                            "id": 111534,
                            "name": "Ashland",
                            "latitude": "42.26121000",
                            "longitude": "-71.46340000"
                        },
                        {
                            "id": 111556,
                            "name": "Assonet",
                            "latitude": "41.79594000",
                            "longitude": "-71.06782000"
                        },
                        {
                            "id": 111583,
                            "name": "Athol",
                            "latitude": "42.59592000",
                            "longitude": "-72.22675000"
                        },
                        {
                            "id": 111611,
                            "name": "Attleboro",
                            "latitude": "41.94454000",
                            "longitude": "-71.28561000"
                        },
                        {
                            "id": 111624,
                            "name": "Auburn",
                            "latitude": "42.19454000",
                            "longitude": "-71.83563000"
                        },
                        {
                            "id": 111691,
                            "name": "Avon",
                            "latitude": "42.13066000",
                            "longitude": "-71.04116000"
                        },
                        {
                            "id": 111708,
                            "name": "Ayer",
                            "latitude": "42.56120000",
                            "longitude": "-71.58979000"
                        },
                        {
                            "id": 111765,
                            "name": "Baldwinville",
                            "latitude": "42.60842000",
                            "longitude": "-72.07591000"
                        },
                        {
                            "id": 111834,
                            "name": "Barnstable",
                            "latitude": "41.70011000",
                            "longitude": "-70.29947000"
                        },
                        {
                            "id": 111835,
                            "name": "Barnstable County",
                            "latitude": "41.68419000",
                            "longitude": "-70.27405000"
                        },
                        {
                            "id": 111841,
                            "name": "Barre",
                            "latitude": "42.42287000",
                            "longitude": "-72.10508000"
                        },
                        {
                            "id": 112014,
                            "name": "Becket",
                            "latitude": "42.33203000",
                            "longitude": "-73.08288000"
                        },
                        {
                            "id": 112023,
                            "name": "Bedford",
                            "latitude": "42.49065000",
                            "longitude": "-71.27617000"
                        },
                        {
                            "id": 112051,
                            "name": "Belchertown",
                            "latitude": "42.27704000",
                            "longitude": "-72.40092000"
                        },
                        {
                            "id": 112119,
                            "name": "Bellingham",
                            "latitude": "42.08676000",
                            "longitude": "-71.47451000"
                        },
                        {
                            "id": 112138,
                            "name": "Belmont",
                            "latitude": "42.39593000",
                            "longitude": "-71.17867000"
                        },
                        {
                            "id": 112229,
                            "name": "Berkley",
                            "latitude": "41.84593000",
                            "longitude": "-71.08282000"
                        },
                        {
                            "id": 112233,
                            "name": "Berkshire County",
                            "latitude": "42.37067000",
                            "longitude": "-73.20640000"
                        },
                        {
                            "id": 112237,
                            "name": "Berlin",
                            "latitude": "42.38120000",
                            "longitude": "-71.63701000"
                        },
                        {
                            "id": 112244,
                            "name": "Bernardston",
                            "latitude": "42.67092000",
                            "longitude": "-72.54953000"
                        },
                        {
                            "id": 112300,
                            "name": "Beverly",
                            "latitude": "42.55843000",
                            "longitude": "-70.88005000"
                        },
                        {
                            "id": 112302,
                            "name": "Beverly Cove",
                            "latitude": "42.55343000",
                            "longitude": "-70.85366000"
                        },
                        {
                            "id": 112342,
                            "name": "Billerica",
                            "latitude": "42.55843000",
                            "longitude": "-71.26895000"
                        },
                        {
                            "id": 112393,
                            "name": "Blackstone",
                            "latitude": "42.01788000",
                            "longitude": "-71.54117000"
                        },
                        {
                            "id": 112430,
                            "name": "Bliss Corner",
                            "latitude": "41.61177000",
                            "longitude": "-70.93837000"
                        },
                        {
                            "id": 112525,
                            "name": "Bolton",
                            "latitude": "42.43343000",
                            "longitude": "-71.60784000"
                        },
                        {
                            "id": 112532,
                            "name": "Bondsville",
                            "latitude": "42.21259000",
                            "longitude": "-72.34536000"
                        },
                        {
                            "id": 112589,
                            "name": "Boston",
                            "latitude": "42.35843000",
                            "longitude": "-71.05977000"
                        },
                        {
                            "id": 112617,
                            "name": "Bourne",
                            "latitude": "41.74122000",
                            "longitude": "-70.59892000"
                        },
                        {
                            "id": 112638,
                            "name": "Boxborough",
                            "latitude": "42.49084000",
                            "longitude": "-71.52851000"
                        },
                        {
                            "id": 112639,
                            "name": "Boxford",
                            "latitude": "42.66120000",
                            "longitude": "-70.99672000"
                        },
                        {
                            "id": 112650,
                            "name": "Boylston",
                            "latitude": "42.39176000",
                            "longitude": "-71.70368000"
                        },
                        {
                            "id": 112682,
                            "name": "Braintree",
                            "latitude": "42.20384000",
                            "longitude": "-71.00215000"
                        },
                        {
                            "id": 112741,
                            "name": "Brewster",
                            "latitude": "41.76011000",
                            "longitude": "-70.08280000"
                        },
                        {
                            "id": 112775,
                            "name": "Bridgewater",
                            "latitude": "41.99038000",
                            "longitude": "-70.97504000"
                        },
                        {
                            "id": 112797,
                            "name": "Brimfield",
                            "latitude": "42.12287000",
                            "longitude": "-72.20091000"
                        },
                        {
                            "id": 112814,
                            "name": "Bristol County",
                            "latitude": "41.75709000",
                            "longitude": "-71.08852000"
                        },
                        {
                            "id": 112834,
                            "name": "Brockton",
                            "latitude": "42.08343000",
                            "longitude": "-71.01838000"
                        },
                        {
                            "id": 112867,
                            "name": "Brookline",
                            "latitude": "42.33176000",
                            "longitude": "-71.12116000"
                        },
                        {
                            "id": 112987,
                            "name": "Buckland",
                            "latitude": "42.59231000",
                            "longitude": "-72.79176000"
                        },
                        {
                            "id": 113070,
                            "name": "Burlington",
                            "latitude": "42.50482000",
                            "longitude": "-71.19561000"
                        },
                        {
                            "id": 113133,
                            "name": "Buzzards Bay",
                            "latitude": "41.74538000",
                            "longitude": "-70.61809000"
                        },
                        {
                            "id": 113252,
                            "name": "Cambridge",
                            "latitude": "42.37510000",
                            "longitude": "-71.10561000"
                        },
                        {
                            "id": 113344,
                            "name": "Canton",
                            "latitude": "42.15843000",
                            "longitude": "-71.14477000"
                        },
                        {
                            "id": 113404,
                            "name": "Carlisle",
                            "latitude": "42.52926000",
                            "longitude": "-71.34950000"
                        },
                        {
                            "id": 113500,
                            "name": "Carver",
                            "latitude": "41.88344000",
                            "longitude": "-70.76254000"
                        },
                        {
                            "id": 113659,
                            "name": "Centerville",
                            "latitude": "41.64872000",
                            "longitude": "-70.34808000"
                        },
                        {
                            "id": 113752,
                            "name": "Charlemont",
                            "latitude": "42.62786000",
                            "longitude": "-72.86982000"
                        },
                        {
                            "id": 113788,
                            "name": "Charlton",
                            "latitude": "42.13565000",
                            "longitude": "-71.97007000"
                        },
                        {
                            "id": 113798,
                            "name": "Chatham",
                            "latitude": "41.68206000",
                            "longitude": "-69.95974000"
                        },
                        {
                            "id": 113828,
                            "name": "Chelmsford",
                            "latitude": "42.59981000",
                            "longitude": "-71.36728000"
                        },
                        {
                            "id": 113831,
                            "name": "Chelsea",
                            "latitude": "42.39176000",
                            "longitude": "-71.03283000"
                        },
                        {
                            "id": 113904,
                            "name": "Chesterfield",
                            "latitude": "42.39175000",
                            "longitude": "-72.83982000"
                        },
                        {
                            "id": 113945,
                            "name": "Chicopee",
                            "latitude": "42.14870000",
                            "longitude": "-72.60787000"
                        },
                        {
                            "id": 114280,
                            "name": "Clinton",
                            "latitude": "42.41676000",
                            "longitude": "-71.68285000"
                        },
                        {
                            "id": 114350,
                            "name": "Cochituate",
                            "latitude": "42.32093000",
                            "longitude": "-71.36423000"
                        },
                        {
                            "id": 114372,
                            "name": "Cohasset",
                            "latitude": "42.24177000",
                            "longitude": "-70.80365000"
                        },
                        {
                            "id": 114461,
                            "name": "Colrain",
                            "latitude": "42.67314000",
                            "longitude": "-72.69676000"
                        },
                        {
                            "id": 114538,
                            "name": "Concord",
                            "latitude": "42.46037000",
                            "longitude": "-71.34895000"
                        },
                        {
                            "id": 114584,
                            "name": "Conway",
                            "latitude": "42.50981000",
                            "longitude": "-72.69953000"
                        },
                        {
                            "id": 114634,
                            "name": "Cordaville",
                            "latitude": "42.26898000",
                            "longitude": "-71.52395000"
                        },
                        {
                            "id": 114707,
                            "name": "Cotuit",
                            "latitude": "41.61678000",
                            "longitude": "-70.43697000"
                        },
                        {
                            "id": 115002,
                            "name": "Dalton",
                            "latitude": "42.47370000",
                            "longitude": "-73.16621000"
                        },
                        {
                            "id": 115029,
                            "name": "Danvers",
                            "latitude": "42.57509000",
                            "longitude": "-70.93005000"
                        },
                        {
                            "id": 115167,
                            "name": "Dedham",
                            "latitude": "42.24177000",
                            "longitude": "-71.16616000"
                        },
                        {
                            "id": 115242,
                            "name": "Dennis",
                            "latitude": "41.73539000",
                            "longitude": "-70.19391000"
                        },
                        {
                            "id": 115243,
                            "name": "Dennis Port",
                            "latitude": "41.65845000",
                            "longitude": "-70.12863000"
                        },
                        {
                            "id": 115296,
                            "name": "Devens",
                            "latitude": "42.54470000",
                            "longitude": "-71.61318000"
                        },
                        {
                            "id": 115335,
                            "name": "Dighton",
                            "latitude": "41.81399000",
                            "longitude": "-71.12032000"
                        },
                        {
                            "id": 115421,
                            "name": "Douglas",
                            "latitude": "42.05426000",
                            "longitude": "-71.73951000"
                        },
                        {
                            "id": 115448,
                            "name": "Dover",
                            "latitude": "42.24593000",
                            "longitude": "-71.28283000"
                        },
                        {
                            "id": 115466,
                            "name": "Dracut",
                            "latitude": "42.67037000",
                            "longitude": "-71.30201000"
                        },
                        {
                            "id": 115505,
                            "name": "Dudley",
                            "latitude": "42.04510000",
                            "longitude": "-71.93007000"
                        },
                        {
                            "id": 115508,
                            "name": "Dukes County",
                            "latitude": "41.38877000",
                            "longitude": "-70.69877000"
                        },
                        {
                            "id": 115554,
                            "name": "Dunstable",
                            "latitude": "42.67509000",
                            "longitude": "-71.48284000"
                        },
                        {
                            "id": 115583,
                            "name": "Duxbury",
                            "latitude": "42.04177000",
                            "longitude": "-70.67226000"
                        },
                        {
                            "id": 115637,
                            "name": "East Bridgewater",
                            "latitude": "42.03343000",
                            "longitude": "-70.95921000"
                        },
                        {
                            "id": 115639,
                            "name": "East Brookfield",
                            "latitude": "42.22787000",
                            "longitude": "-72.04674000"
                        },
                        {
                            "id": 115649,
                            "name": "East Dennis",
                            "latitude": "41.74261000",
                            "longitude": "-70.16196000"
                        },
                        {
                            "id": 115650,
                            "name": "East Douglas",
                            "latitude": "42.07232000",
                            "longitude": "-71.71340000"
                        },
                        {
                            "id": 115657,
                            "name": "East Falmouth",
                            "latitude": "41.57844000",
                            "longitude": "-70.55864000"
                        },
                        {
                            "id": 115685,
                            "name": "East Harwich",
                            "latitude": "41.70011000",
                            "longitude": "-70.02724000"
                        },
                        {
                            "id": 115705,
                            "name": "East Longmeadow",
                            "latitude": "42.06454000",
                            "longitude": "-72.51259000"
                        },
                        {
                            "id": 115733,
                            "name": "East Pepperell",
                            "latitude": "42.66537000",
                            "longitude": "-71.57312000"
                        },
                        {
                            "id": 115756,
                            "name": "East Sandwich",
                            "latitude": "41.74177000",
                            "longitude": "-70.45169000"
                        },
                        {
                            "id": 115778,
                            "name": "Eastham",
                            "latitude": "41.83011000",
                            "longitude": "-69.97391000"
                        },
                        {
                            "id": 115779,
                            "name": "Easthampton",
                            "latitude": "42.26676000",
                            "longitude": "-72.66898000"
                        },
                        {
                            "id": 115787,
                            "name": "Easton",
                            "latitude": "42.02454000",
                            "longitude": "-71.12866000"
                        },
                        {
                            "id": 115838,
                            "name": "Edgartown",
                            "latitude": "41.38901000",
                            "longitude": "-70.51336000"
                        },
                        {
                            "id": 116174,
                            "name": "Erving",
                            "latitude": "42.60009000",
                            "longitude": "-72.39814000"
                        },
                        {
                            "id": 116192,
                            "name": "Essex",
                            "latitude": "42.63204000",
                            "longitude": "-70.78283000"
                        },
                        {
                            "id": 116194,
                            "name": "Essex County",
                            "latitude": "42.63887000",
                            "longitude": "-70.86792000"
                        },
                        {
                            "id": 116258,
                            "name": "Everett",
                            "latitude": "42.40843000",
                            "longitude": "-71.05366000"
                        },
                        {
                            "id": 116325,
                            "name": "Fairhaven",
                            "latitude": "41.63760000",
                            "longitude": "-70.90365000"
                        },
                        {
                            "id": 116373,
                            "name": "Fall River",
                            "latitude": "41.70149000",
                            "longitude": "-71.15505000"
                        },
                        {
                            "id": 116388,
                            "name": "Falmouth",
                            "latitude": "41.55150000",
                            "longitude": "-70.61475000"
                        },
                        {
                            "id": 116533,
                            "name": "Fiskdale",
                            "latitude": "42.11621000",
                            "longitude": "-72.11341000"
                        },
                        {
                            "id": 116534,
                            "name": "Fitchburg",
                            "latitude": "42.58342000",
                            "longitude": "-71.80230000"
                        },
                        {
                            "id": 116670,
                            "name": "Forestdale",
                            "latitude": "41.69177000",
                            "longitude": "-70.49947000"
                        },
                        {
                            "id": 116809,
                            "name": "Foxborough",
                            "latitude": "42.06538000",
                            "longitude": "-71.24783000"
                        },
                        {
                            "id": 116811,
                            "name": "Framingham",
                            "latitude": "42.27926000",
                            "longitude": "-71.41617000"
                        },
                        {
                            "id": 116812,
                            "name": "Framingham Center",
                            "latitude": "42.29732000",
                            "longitude": "-71.43701000"
                        },
                        {
                            "id": 116834,
                            "name": "Franklin",
                            "latitude": "42.08343000",
                            "longitude": "-71.39673000"
                        },
                        {
                            "id": 116858,
                            "name": "Franklin County",
                            "latitude": "42.58312000",
                            "longitude": "-72.59187000"
                        },
                        {
                            "id": 116918,
                            "name": "Freetown",
                            "latitude": "41.76677000",
                            "longitude": "-71.03282000"
                        },
                        {
                            "id": 117075,
                            "name": "Gardner",
                            "latitude": "42.57509000",
                            "longitude": "-71.99813000"
                        },
                        {
                            "id": 117213,
                            "name": "Gill",
                            "latitude": "42.64036000",
                            "longitude": "-72.49953000"
                        },
                        {
                            "id": 117322,
                            "name": "Gloucester",
                            "latitude": "42.61405000",
                            "longitude": "-70.66313000"
                        },
                        {
                            "id": 117429,
                            "name": "Grafton",
                            "latitude": "42.20704000",
                            "longitude": "-71.68562000"
                        },
                        {
                            "id": 117447,
                            "name": "Granby",
                            "latitude": "42.25648000",
                            "longitude": "-72.51620000"
                        },
                        {
                            "id": 117534,
                            "name": "Granville",
                            "latitude": "42.06676000",
                            "longitude": "-72.86149000"
                        },
                        {
                            "id": 117569,
                            "name": "Great Barrington",
                            "latitude": "42.19592000",
                            "longitude": "-73.36206000"
                        },
                        {
                            "id": 117596,
                            "name": "Green Harbor-Cedar Crest",
                            "latitude": "42.07495000",
                            "longitude": "-70.65843000"
                        },
                        {
                            "id": 117650,
                            "name": "Greenfield",
                            "latitude": "42.58759000",
                            "longitude": "-72.59953000"
                        },
                        {
                            "id": 117752,
                            "name": "Groton",
                            "latitude": "42.61120000",
                            "longitude": "-71.57451000"
                        },
                        {
                            "id": 117759,
                            "name": "Groveland",
                            "latitude": "42.76037000",
                            "longitude": "-71.03145000"
                        },
                        {
                            "id": 117833,
                            "name": "Hadley",
                            "latitude": "42.34176000",
                            "longitude": "-72.58842000"
                        },
                        {
                            "id": 117861,
                            "name": "Halifax",
                            "latitude": "41.99121000",
                            "longitude": "-70.86199000"
                        },
                        {
                            "id": 117906,
                            "name": "Hamilton Worcester",
                            "latitude": "42.25620000",
                            "longitude": "-71.76757000"
                        },
                        {
                            "id": 117916,
                            "name": "Hampden",
                            "latitude": "42.06398000",
                            "longitude": "-72.41342000"
                        },
                        {
                            "id": 117918,
                            "name": "Hampden County",
                            "latitude": "42.13511000",
                            "longitude": "-72.63162000"
                        },
                        {
                            "id": 117922,
                            "name": "Hampshire County",
                            "latitude": "42.34014000",
                            "longitude": "-72.66377000"
                        },
                        {
                            "id": 117970,
                            "name": "Hanover",
                            "latitude": "42.11316000",
                            "longitude": "-70.81199000"
                        },
                        {
                            "id": 117978,
                            "name": "Hanson",
                            "latitude": "42.07510000",
                            "longitude": "-70.88004000"
                        },
                        {
                            "id": 118008,
                            "name": "Hardwick",
                            "latitude": "42.35009000",
                            "longitude": "-72.19952000"
                        },
                        {
                            "id": 118109,
                            "name": "Harvard",
                            "latitude": "42.50009000",
                            "longitude": "-71.58284000"
                        },
                        {
                            "id": 118117,
                            "name": "Harwich",
                            "latitude": "41.68622000",
                            "longitude": "-70.07585000"
                        },
                        {
                            "id": 118118,
                            "name": "Harwich Center",
                            "latitude": "41.69235000",
                            "longitude": "-70.06938000"
                        },
                        {
                            "id": 118119,
                            "name": "Harwich Port",
                            "latitude": "41.66678000",
                            "longitude": "-70.07863000"
                        },
                        {
                            "id": 118138,
                            "name": "Hatfield",
                            "latitude": "42.37092000",
                            "longitude": "-72.59814000"
                        },
                        {
                            "id": 118150,
                            "name": "Haverhill",
                            "latitude": "42.77620000",
                            "longitude": "-71.07728000"
                        },
                        {
                            "id": 118214,
                            "name": "Head of Westport",
                            "latitude": "41.62094000",
                            "longitude": "-71.06199000"
                        },
                        {
                            "id": 118483,
                            "name": "Hingham",
                            "latitude": "42.24177000",
                            "longitude": "-70.88977000"
                        },
                        {
                            "id": 118484,
                            "name": "Hinsdale",
                            "latitude": "42.43870000",
                            "longitude": "-73.12538000"
                        },
                        {
                            "id": 118517,
                            "name": "Holbrook",
                            "latitude": "42.15510000",
                            "longitude": "-71.00866000"
                        },
                        {
                            "id": 118523,
                            "name": "Holden",
                            "latitude": "42.35176000",
                            "longitude": "-71.86341000"
                        },
                        {
                            "id": 118538,
                            "name": "Holland",
                            "latitude": "42.06398000",
                            "longitude": "-72.15730000"
                        },
                        {
                            "id": 118553,
                            "name": "Holliston",
                            "latitude": "42.20010000",
                            "longitude": "-71.42450000"
                        },
                        {
                            "id": 118583,
                            "name": "Holyoke",
                            "latitude": "42.20426000",
                            "longitude": "-72.61620000"
                        },
                        {
                            "id": 118647,
                            "name": "Hopedale",
                            "latitude": "42.13065000",
                            "longitude": "-71.54117000"
                        },
                        {
                            "id": 118656,
                            "name": "Hopkinton",
                            "latitude": "42.22871000",
                            "longitude": "-71.52256000"
                        },
                        {
                            "id": 118695,
                            "name": "Housatonic",
                            "latitude": "42.25425000",
                            "longitude": "-73.36622000"
                        },
                        {
                            "id": 118734,
                            "name": "Hubbardston",
                            "latitude": "42.47370000",
                            "longitude": "-72.00619000"
                        },
                        {
                            "id": 118740,
                            "name": "Hudson",
                            "latitude": "42.39176000",
                            "longitude": "-71.56618000"
                        },
                        {
                            "id": 118772,
                            "name": "Hull",
                            "latitude": "42.30204000",
                            "longitude": "-70.90782000"
                        },
                        {
                            "id": 118842,
                            "name": "Hyannis",
                            "latitude": "41.65289000",
                            "longitude": "-70.28280000"
                        },
                        {
                            "id": 118982,
                            "name": "Ipswich",
                            "latitude": "42.67926000",
                            "longitude": "-70.84116000"
                        },
                        {
                            "id": 119114,
                            "name": "Jamaica Plain",
                            "latitude": "42.30982000",
                            "longitude": "-71.12033000"
                        },
                        {
                            "id": 119623,
                            "name": "Kingston",
                            "latitude": "41.99455000",
                            "longitude": "-70.72448000"
                        },
                        {
                            "id": 120083,
                            "name": "Lancaster",
                            "latitude": "42.45565000",
                            "longitude": "-71.67312000"
                        },
                        {
                            "id": 120107,
                            "name": "Lanesborough",
                            "latitude": "42.51731000",
                            "longitude": "-73.22816000"
                        },
                        {
                            "id": 120227,
                            "name": "Lawrence",
                            "latitude": "42.70704000",
                            "longitude": "-71.16311000"
                        },
                        {
                            "id": 120303,
                            "name": "Lee",
                            "latitude": "42.30425000",
                            "longitude": "-73.24816000"
                        },
                        {
                            "id": 120337,
                            "name": "Leicester",
                            "latitude": "42.24593000",
                            "longitude": "-71.90868000"
                        },
                        {
                            "id": 120376,
                            "name": "Lenox",
                            "latitude": "42.35648000",
                            "longitude": "-73.28483000"
                        },
                        {
                            "id": 120382,
                            "name": "Leominster",
                            "latitude": "42.52509000",
                            "longitude": "-71.75979000"
                        },
                        {
                            "id": 120406,
                            "name": "Leverett",
                            "latitude": "42.45203000",
                            "longitude": "-72.50148000"
                        },
                        {
                            "id": 120448,
                            "name": "Lexington",
                            "latitude": "42.44732000",
                            "longitude": "-71.22450000"
                        },
                        {
                            "id": 120504,
                            "name": "Lincoln",
                            "latitude": "42.42593000",
                            "longitude": "-71.30395000"
                        },
                        {
                            "id": 120637,
                            "name": "Littleton Common",
                            "latitude": "42.54593000",
                            "longitude": "-71.47451000"
                        },
                        {
                            "id": 120753,
                            "name": "Longmeadow",
                            "latitude": "42.05010000",
                            "longitude": "-72.58287000"
                        },
                        {
                            "id": 120836,
                            "name": "Lowell",
                            "latitude": "42.63342000",
                            "longitude": "-71.31617000"
                        },
                        {
                            "id": 120870,
                            "name": "Ludlow",
                            "latitude": "42.16009000",
                            "longitude": "-72.47592000"
                        },
                        {
                            "id": 120887,
                            "name": "Lunenburg",
                            "latitude": "42.59453000",
                            "longitude": "-71.72452000"
                        },
                        {
                            "id": 120927,
                            "name": "Lynn",
                            "latitude": "42.46676000",
                            "longitude": "-70.94949000"
                        },
                        {
                            "id": 120930,
                            "name": "Lynnfield",
                            "latitude": "42.53898000",
                            "longitude": "-71.04811000"
                        },
                        {
                            "id": 121060,
                            "name": "Malden",
                            "latitude": "42.42510000",
                            "longitude": "-71.06616000"
                        },
                        {
                            "id": 121104,
                            "name": "Manchester-by-the-Sea",
                            "latitude": "42.57787000",
                            "longitude": "-70.76894000"
                        },
                        {
                            "id": 121146,
                            "name": "Mansfield",
                            "latitude": "42.03343000",
                            "longitude": "-71.21894000"
                        },
                        {
                            "id": 121148,
                            "name": "Mansfield Center",
                            "latitude": "42.02262000",
                            "longitude": "-71.21808000"
                        },
                        {
                            "id": 121189,
                            "name": "Marblehead",
                            "latitude": "42.50010000",
                            "longitude": "-70.85783000"
                        },
                        {
                            "id": 121242,
                            "name": "Marion",
                            "latitude": "41.70010000",
                            "longitude": "-70.76281000"
                        },
                        {
                            "id": 121246,
                            "name": "Marion Center",
                            "latitude": "41.70424000",
                            "longitude": "-70.76286000"
                        },
                        {
                            "id": 121280,
                            "name": "Marlborough",
                            "latitude": "42.34593000",
                            "longitude": "-71.55229000"
                        },
                        {
                            "id": 121326,
                            "name": "Marshfield",
                            "latitude": "42.09177000",
                            "longitude": "-70.70559000"
                        },
                        {
                            "id": 121328,
                            "name": "Marshfield Hills",
                            "latitude": "42.14594000",
                            "longitude": "-70.73976000"
                        },
                        {
                            "id": 121331,
                            "name": "Marstons Mills",
                            "latitude": "41.65622000",
                            "longitude": "-70.41614000"
                        },
                        {
                            "id": 121370,
                            "name": "Mashpee",
                            "latitude": "41.64844000",
                            "longitude": "-70.48114000"
                        },
                        {
                            "id": 121407,
                            "name": "Mattapoisett",
                            "latitude": "41.65844000",
                            "longitude": "-70.81615000"
                        },
                        {
                            "id": 121408,
                            "name": "Mattapoisett Center",
                            "latitude": "41.66595000",
                            "longitude": "-70.80720000"
                        },
                        {
                            "id": 121440,
                            "name": "Maynard",
                            "latitude": "42.43343000",
                            "longitude": "-71.44951000"
                        },
                        {
                            "id": 121590,
                            "name": "Medfield",
                            "latitude": "42.18760000",
                            "longitude": "-71.30645000"
                        },
                        {
                            "id": 121592,
                            "name": "Medford",
                            "latitude": "42.41843000",
                            "longitude": "-71.10616000"
                        },
                        {
                            "id": 121610,
                            "name": "Medway",
                            "latitude": "42.14176000",
                            "longitude": "-71.39673000"
                        },
                        {
                            "id": 121628,
                            "name": "Melrose",
                            "latitude": "42.45843000",
                            "longitude": "-71.06616000"
                        },
                        {
                            "id": 121653,
                            "name": "Mendon",
                            "latitude": "42.10565000",
                            "longitude": "-71.55229000"
                        },
                        {
                            "id": 121710,
                            "name": "Merrimac",
                            "latitude": "42.83065000",
                            "longitude": "-71.00228000"
                        },
                        {
                            "id": 121734,
                            "name": "Methuen",
                            "latitude": "42.72620000",
                            "longitude": "-71.19089000"
                        },
                        {
                            "id": 121765,
                            "name": "Middleborough",
                            "latitude": "41.89316000",
                            "longitude": "-70.91115000"
                        },
                        {
                            "id": 121766,
                            "name": "Middleborough Center",
                            "latitude": "41.89460000",
                            "longitude": "-70.92618000"
                        },
                        {
                            "id": 121780,
                            "name": "Middlesex County",
                            "latitude": "42.48555000",
                            "longitude": "-71.39184000"
                        },
                        {
                            "id": 121782,
                            "name": "Middleton",
                            "latitude": "42.59509000",
                            "longitude": "-71.01616000"
                        },
                        {
                            "id": 121850,
                            "name": "Milford",
                            "latitude": "42.13982000",
                            "longitude": "-71.51617000"
                        },
                        {
                            "id": 121874,
                            "name": "Millbury",
                            "latitude": "42.19398000",
                            "longitude": "-71.76007000"
                        },
                        {
                            "id": 121885,
                            "name": "Millers Falls",
                            "latitude": "42.58203000",
                            "longitude": "-72.49259000"
                        },
                        {
                            "id": 121894,
                            "name": "Millis",
                            "latitude": "42.16760000",
                            "longitude": "-71.35784000"
                        },
                        {
                            "id": 121895,
                            "name": "Millis-Clicquot",
                            "latitude": "42.16480000",
                            "longitude": "-71.35442000"
                        },
                        {
                            "id": 121905,
                            "name": "Millville",
                            "latitude": "42.02788000",
                            "longitude": "-71.58090000"
                        },
                        {
                            "id": 121915,
                            "name": "Milton",
                            "latitude": "42.24954000",
                            "longitude": "-71.06616000"
                        },
                        {
                            "id": 122090,
                            "name": "Monson",
                            "latitude": "42.10426000",
                            "longitude": "-72.31897000"
                        },
                        {
                            "id": 122091,
                            "name": "Monson Center",
                            "latitude": "42.09898000",
                            "longitude": "-72.30481000"
                        },
                        {
                            "id": 122096,
                            "name": "Montague",
                            "latitude": "42.53564000",
                            "longitude": "-72.53509000"
                        },
                        {
                            "id": 122192,
                            "name": "Monument Beach",
                            "latitude": "41.71955000",
                            "longitude": "-70.61198000"
                        },
                        {
                            "id": 122535,
                            "name": "Nahant",
                            "latitude": "42.42649000",
                            "longitude": "-70.91894000"
                        },
                        {
                            "id": 122542,
                            "name": "Nantucket",
                            "latitude": "41.28346000",
                            "longitude": "-70.09946000"
                        },
                        {
                            "id": 122543,
                            "name": "Nantucket County",
                            "latitude": "41.26955000",
                            "longitude": "-70.02171000"
                        },
                        {
                            "id": 122590,
                            "name": "Natick",
                            "latitude": "42.28343000",
                            "longitude": "-71.34950000"
                        },
                        {
                            "id": 122613,
                            "name": "Needham",
                            "latitude": "42.28343000",
                            "longitude": "-71.23283000"
                        },
                        {
                            "id": 122662,
                            "name": "New Bedford",
                            "latitude": "41.63526000",
                            "longitude": "-70.92701000"
                        },
                        {
                            "id": 122752,
                            "name": "New Marlborough",
                            "latitude": "42.12287000",
                            "longitude": "-73.22872000"
                        },
                        {
                            "id": 122819,
                            "name": "Newburyport",
                            "latitude": "42.81259000",
                            "longitude": "-70.87728000"
                        },
                        {
                            "id": 122865,
                            "name": "Newton",
                            "latitude": "42.33704000",
                            "longitude": "-71.20922000"
                        },
                        {
                            "id": 122942,
                            "name": "Norfolk",
                            "latitude": "42.11954000",
                            "longitude": "-71.32506000"
                        },
                        {
                            "id": 122945,
                            "name": "Norfolk County",
                            "latitude": "42.17097000",
                            "longitude": "-71.18381000"
                        },
                        {
                            "id": 122958,
                            "name": "North Adams",
                            "latitude": "42.70092000",
                            "longitude": "-73.10871000"
                        },
                        {
                            "id": 122960,
                            "name": "North Amherst",
                            "latitude": "42.41037000",
                            "longitude": "-72.53092000"
                        },
                        {
                            "id": 122962,
                            "name": "North Andover",
                            "latitude": "42.69870000",
                            "longitude": "-71.13506000"
                        },
                        {
                            "id": 122967,
                            "name": "North Attleborough Center",
                            "latitude": "41.97263000",
                            "longitude": "-71.32474000"
                        },
                        {
                            "id": 122996,
                            "name": "North Brookfield",
                            "latitude": "42.26676000",
                            "longitude": "-72.08285000"
                        },
                        {
                            "id": 123007,
                            "name": "North Chicopee",
                            "latitude": "42.18343000",
                            "longitude": "-72.59953000"
                        },
                        {
                            "id": 123019,
                            "name": "North Eastham",
                            "latitude": "41.86511000",
                            "longitude": "-69.99113000"
                        },
                        {
                            "id": 123025,
                            "name": "North Falmouth",
                            "latitude": "41.64594000",
                            "longitude": "-70.61836000"
                        },
                        {
                            "id": 123051,
                            "name": "North Lakeville",
                            "latitude": "41.85760000",
                            "longitude": "-70.94226000"
                        },
                        {
                            "id": 123078,
                            "name": "North Pembroke",
                            "latitude": "42.09316000",
                            "longitude": "-70.79254000"
                        },
                        {
                            "id": 123083,
                            "name": "North Plymouth",
                            "latitude": "41.97094000",
                            "longitude": "-70.68281000"
                        },
                        {
                            "id": 123091,
                            "name": "North Reading",
                            "latitude": "42.57509000",
                            "longitude": "-71.07867000"
                        },
                        {
                            "id": 123101,
                            "name": "North Scituate",
                            "latitude": "42.21899000",
                            "longitude": "-70.78560000"
                        },
                        {
                            "id": 123104,
                            "name": "North Seekonk",
                            "latitude": "41.88927000",
                            "longitude": "-71.33005000"
                        },
                        {
                            "id": 123128,
                            "name": "North Westport",
                            "latitude": "41.66038000",
                            "longitude": "-71.08838000"
                        },
                        {
                            "id": 123134,
                            "name": "Northampton",
                            "latitude": "42.32509000",
                            "longitude": "-72.64120000"
                        },
                        {
                            "id": 123139,
                            "name": "Northborough",
                            "latitude": "42.31954000",
                            "longitude": "-71.64118000"
                        },
                        {
                            "id": 123140,
                            "name": "Northbridge",
                            "latitude": "42.15148000",
                            "longitude": "-71.64951000"
                        },
                        {
                            "id": 123150,
                            "name": "Northfield",
                            "latitude": "42.69592000",
                            "longitude": "-72.45287000"
                        },
                        {
                            "id": 123176,
                            "name": "Northwest Harwich",
                            "latitude": "41.69029000",
                            "longitude": "-70.10250000"
                        },
                        {
                            "id": 123184,
                            "name": "Norton",
                            "latitude": "41.96677000",
                            "longitude": "-71.18699000"
                        },
                        {
                            "id": 123185,
                            "name": "Norton Center",
                            "latitude": "41.97254000",
                            "longitude": "-71.18535000"
                        },
                        {
                            "id": 123194,
                            "name": "Norwell",
                            "latitude": "42.16177000",
                            "longitude": "-70.79393000"
                        },
                        {
                            "id": 123199,
                            "name": "Norwood",
                            "latitude": "42.19454000",
                            "longitude": "-71.19950000"
                        },
                        {
                            "id": 123229,
                            "name": "Oak Bluffs",
                            "latitude": "41.45428000",
                            "longitude": "-70.56197000"
                        },
                        {
                            "id": 123278,
                            "name": "Oakham",
                            "latitude": "42.35287000",
                            "longitude": "-72.04535000"
                        },
                        {
                            "id": 123324,
                            "name": "Ocean Bluff-Brant Rock",
                            "latitude": "42.10234000",
                            "longitude": "-70.65736000"
                        },
                        {
                            "id": 123330,
                            "name": "Ocean Grove",
                            "latitude": "41.72927000",
                            "longitude": "-71.20921000"
                        },
                        {
                            "id": 123483,
                            "name": "Onset",
                            "latitude": "41.74177000",
                            "longitude": "-70.65781000"
                        },
                        {
                            "id": 123508,
                            "name": "Orange",
                            "latitude": "42.59036000",
                            "longitude": "-72.30981000"
                        },
                        {
                            "id": 123564,
                            "name": "Orleans",
                            "latitude": "41.78983000",
                            "longitude": "-69.98974000"
                        },
                        {
                            "id": 123620,
                            "name": "Osterville",
                            "latitude": "41.62844000",
                            "longitude": "-70.38697000"
                        },
                        {
                            "id": 123628,
                            "name": "Otis",
                            "latitude": "42.19315000",
                            "longitude": "-73.09177000"
                        },
                        {
                            "id": 123683,
                            "name": "Oxford",
                            "latitude": "42.11676000",
                            "longitude": "-71.86479000"
                        },
                        {
                            "id": 123759,
                            "name": "Palmer",
                            "latitude": "42.15843000",
                            "longitude": "-72.32869000"
                        },
                        {
                            "id": 123942,
                            "name": "Paxton",
                            "latitude": "42.31120000",
                            "longitude": "-71.92813000"
                        },
                        {
                            "id": 123954,
                            "name": "Peabody",
                            "latitude": "42.52787000",
                            "longitude": "-70.92866000"
                        },
                        {
                            "id": 123990,
                            "name": "Pelham",
                            "latitude": "42.39315000",
                            "longitude": "-72.40370000"
                        },
                        {
                            "id": 124054,
                            "name": "Pepperell",
                            "latitude": "42.66592000",
                            "longitude": "-71.58840000"
                        },
                        {
                            "id": 124141,
                            "name": "Phillipston",
                            "latitude": "42.54870000",
                            "longitude": "-72.13286000"
                        },
                        {
                            "id": 124252,
                            "name": "Pinehurst",
                            "latitude": "42.52926000",
                            "longitude": "-71.22811000"
                        },
                        {
                            "id": 124298,
                            "name": "Pittsfield",
                            "latitude": "42.45008000",
                            "longitude": "-73.24538000"
                        },
                        {
                            "id": 124335,
                            "name": "Plainville",
                            "latitude": "42.00427000",
                            "longitude": "-71.33283000"
                        },
                        {
                            "id": 124402,
                            "name": "Plymouth",
                            "latitude": "41.95844000",
                            "longitude": "-70.66726000"
                        },
                        {
                            "id": 124410,
                            "name": "Plymouth County",
                            "latitude": "41.98743000",
                            "longitude": "-70.73707000"
                        },
                        {
                            "id": 124412,
                            "name": "Plympton",
                            "latitude": "41.95288000",
                            "longitude": "-70.81448000"
                        },
                        {
                            "id": 124417,
                            "name": "Pocasset",
                            "latitude": "41.68622000",
                            "longitude": "-70.61614000"
                        },
                        {
                            "id": 124687,
                            "name": "Princeton",
                            "latitude": "42.44870000",
                            "longitude": "-71.87730000"
                        },
                        {
                            "id": 124720,
                            "name": "Provincetown",
                            "latitude": "42.05295000",
                            "longitude": "-70.18640000"
                        },
                        {
                            "id": 124797,
                            "name": "Quincy",
                            "latitude": "42.25288000",
                            "longitude": "-71.00227000"
                        },
                        {
                            "id": 124865,
                            "name": "Randolph",
                            "latitude": "42.16260000",
                            "longitude": "-71.04116000"
                        },
                        {
                            "id": 124924,
                            "name": "Raynham",
                            "latitude": "41.94871000",
                            "longitude": "-71.07310000"
                        },
                        {
                            "id": 124925,
                            "name": "Raynham Center",
                            "latitude": "41.92371000",
                            "longitude": "-71.05227000"
                        },
                        {
                            "id": 124929,
                            "name": "Reading",
                            "latitude": "42.52565000",
                            "longitude": "-71.09533000"
                        },
                        {
                            "id": 125004,
                            "name": "Rehoboth",
                            "latitude": "41.84038000",
                            "longitude": "-71.24949000"
                        },
                        {
                            "id": 125037,
                            "name": "Revere",
                            "latitude": "42.40843000",
                            "longitude": "-71.01199000"
                        },
                        {
                            "id": 125090,
                            "name": "Richmond",
                            "latitude": "42.37314000",
                            "longitude": "-73.36761000"
                        },
                        {
                            "id": 125262,
                            "name": "Rochester",
                            "latitude": "41.73177000",
                            "longitude": "-70.82004000"
                        },
                        {
                            "id": 125309,
                            "name": "Rockland",
                            "latitude": "42.13066000",
                            "longitude": "-70.91616000"
                        },
                        {
                            "id": 125317,
                            "name": "Rockport",
                            "latitude": "42.65565000",
                            "longitude": "-70.62032000"
                        },
                        {
                            "id": 125482,
                            "name": "Rowley",
                            "latitude": "42.71676000",
                            "longitude": "-70.87866000"
                        },
                        {
                            "id": 125494,
                            "name": "Royalston",
                            "latitude": "42.67759000",
                            "longitude": "-72.18786000"
                        },
                        {
                            "id": 125555,
                            "name": "Rutland",
                            "latitude": "42.36954000",
                            "longitude": "-71.94813000"
                        },
                        {
                            "id": 125586,
                            "name": "Sagamore",
                            "latitude": "41.77011000",
                            "longitude": "-70.52836000"
                        },
                        {
                            "id": 125735,
                            "name": "Salem",
                            "latitude": "42.51954000",
                            "longitude": "-70.89672000"
                        },
                        {
                            "id": 125757,
                            "name": "Salisbury",
                            "latitude": "42.84176000",
                            "longitude": "-70.86061000"
                        },
                        {
                            "id": 125877,
                            "name": "Sandwich",
                            "latitude": "41.75900000",
                            "longitude": "-70.49392000"
                        },
                        {
                            "id": 125961,
                            "name": "Saugus",
                            "latitude": "42.46482000",
                            "longitude": "-71.01005000"
                        },
                        {
                            "id": 126030,
                            "name": "Scituate",
                            "latitude": "42.19593000",
                            "longitude": "-70.72587000"
                        },
                        {
                            "id": 126123,
                            "name": "Seekonk",
                            "latitude": "41.80843000",
                            "longitude": "-71.33700000"
                        },
                        {
                            "id": 126228,
                            "name": "Sharon",
                            "latitude": "42.12371000",
                            "longitude": "-71.17866000"
                        },
                        {
                            "id": 126262,
                            "name": "Sheffield",
                            "latitude": "42.11037000",
                            "longitude": "-73.35511000"
                        },
                        {
                            "id": 126265,
                            "name": "Shelburne",
                            "latitude": "42.58981000",
                            "longitude": "-72.68842000"
                        },
                        {
                            "id": 126266,
                            "name": "Shelburne Falls",
                            "latitude": "42.60425000",
                            "longitude": "-72.73926000"
                        },
                        {
                            "id": 126313,
                            "name": "Sherborn",
                            "latitude": "42.23899000",
                            "longitude": "-71.36978000"
                        },
                        {
                            "id": 126360,
                            "name": "Shirley",
                            "latitude": "42.54370000",
                            "longitude": "-71.64951000"
                        },
                        {
                            "id": 126385,
                            "name": "Shrewsbury",
                            "latitude": "42.29593000",
                            "longitude": "-71.71285000"
                        },
                        {
                            "id": 126389,
                            "name": "Shutesbury",
                            "latitude": "42.45648000",
                            "longitude": "-72.40981000"
                        },
                        {
                            "id": 126516,
                            "name": "Smith Mills",
                            "latitude": "41.63899000",
                            "longitude": "-70.99115000"
                        },
                        {
                            "id": 126582,
                            "name": "Somerset",
                            "latitude": "41.76955000",
                            "longitude": "-71.12866000"
                        },
                        {
                            "id": 126595,
                            "name": "Somerville",
                            "latitude": "42.38760000",
                            "longitude": "-71.09950000"
                        },
                        {
                            "id": 126613,
                            "name": "South Amherst",
                            "latitude": "42.34037000",
                            "longitude": "-72.50509000"
                        },
                        {
                            "id": 126615,
                            "name": "South Ashburnham",
                            "latitude": "42.61037000",
                            "longitude": "-71.93897000"
                        },
                        {
                            "id": 126629,
                            "name": "South Boston",
                            "latitude": "42.33343000",
                            "longitude": "-71.04949000"
                        },
                        {
                            "id": 126646,
                            "name": "South Deerfield",
                            "latitude": "42.47731000",
                            "longitude": "-72.60787000"
                        },
                        {
                            "id": 126647,
                            "name": "South Dennis",
                            "latitude": "41.68956000",
                            "longitude": "-70.15641000"
                        },
                        {
                            "id": 126649,
                            "name": "South Duxbury",
                            "latitude": "42.02316000",
                            "longitude": "-70.68281000"
                        },
                        {
                            "id": 126665,
                            "name": "South Hadley",
                            "latitude": "42.25842000",
                            "longitude": "-72.57453000"
                        },
                        {
                            "id": 126685,
                            "name": "South Lancaster",
                            "latitude": "42.44454000",
                            "longitude": "-71.68701000"
                        },
                        {
                            "id": 126708,
                            "name": "South Peabody",
                            "latitude": "42.50982000",
                            "longitude": "-70.94949000"
                        },
                        {
                            "id": 126755,
                            "name": "South Yarmouth",
                            "latitude": "41.66678000",
                            "longitude": "-70.18474000"
                        },
                        {
                            "id": 126757,
                            "name": "Southampton",
                            "latitude": "42.22926000",
                            "longitude": "-72.73009000"
                        },
                        {
                            "id": 126761,
                            "name": "Southborough",
                            "latitude": "42.30565000",
                            "longitude": "-71.52451000"
                        },
                        {
                            "id": 126762,
                            "name": "Southbridge",
                            "latitude": "42.07510000",
                            "longitude": "-72.03341000"
                        },
                        {
                            "id": 126791,
                            "name": "Southwick",
                            "latitude": "42.05482000",
                            "longitude": "-72.77037000"
                        },
                        {
                            "id": 126826,
                            "name": "Spencer",
                            "latitude": "42.24398000",
                            "longitude": "-71.99230000"
                        },
                        {
                            "id": 126897,
                            "name": "Springfield",
                            "latitude": "42.10148000",
                            "longitude": "-72.58981000"
                        },
                        {
                            "id": 127028,
                            "name": "Sterling",
                            "latitude": "42.43759000",
                            "longitude": "-71.76063000"
                        },
                        {
                            "id": 127068,
                            "name": "Stockbridge",
                            "latitude": "42.28759000",
                            "longitude": "-73.32039000"
                        },
                        {
                            "id": 127089,
                            "name": "Stoneham",
                            "latitude": "42.48010000",
                            "longitude": "-71.09950000"
                        },
                        {
                            "id": 127109,
                            "name": "Stoughton",
                            "latitude": "42.12510000",
                            "longitude": "-71.10227000"
                        },
                        {
                            "id": 127112,
                            "name": "Stow",
                            "latitude": "42.43704000",
                            "longitude": "-71.50562000"
                        },
                        {
                            "id": 127147,
                            "name": "Sturbridge",
                            "latitude": "42.10843000",
                            "longitude": "-72.07869000"
                        },
                        {
                            "id": 127161,
                            "name": "Sudbury",
                            "latitude": "42.38343000",
                            "longitude": "-71.41617000"
                        },
                        {
                            "id": 127167,
                            "name": "Suffolk County",
                            "latitude": "42.35550000",
                            "longitude": "-71.06575000"
                        },
                        {
                            "id": 127249,
                            "name": "Sunderland",
                            "latitude": "42.24454000",
                            "longitude": "-71.77174000"
                        },
                        {
                            "id": 127312,
                            "name": "Sutton",
                            "latitude": "42.15010000",
                            "longitude": "-71.76285000"
                        },
                        {
                            "id": 127321,
                            "name": "Swampscott",
                            "latitude": "42.47093000",
                            "longitude": "-70.91755000"
                        },
                        {
                            "id": 127326,
                            "name": "Swansea",
                            "latitude": "41.74816000",
                            "longitude": "-71.18977000"
                        },
                        {
                            "id": 127441,
                            "name": "Taunton",
                            "latitude": "41.90010000",
                            "longitude": "-71.08977000"
                        },
                        {
                            "id": 127476,
                            "name": "Teaticket",
                            "latitude": "41.56455000",
                            "longitude": "-70.59586000"
                        },
                        {
                            "id": 127504,
                            "name": "Templeton",
                            "latitude": "42.55564000",
                            "longitude": "-72.06758000"
                        },
                        {
                            "id": 127542,
                            "name": "Tewksbury",
                            "latitude": "42.61065000",
                            "longitude": "-71.23422000"
                        },
                        {
                            "id": 127619,
                            "name": "Three Rivers",
                            "latitude": "42.18120000",
                            "longitude": "-72.36064000"
                        },
                        {
                            "id": 127735,
                            "name": "Topsfield",
                            "latitude": "42.63759000",
                            "longitude": "-70.94950000"
                        },
                        {
                            "id": 127765,
                            "name": "Townsend",
                            "latitude": "42.66676000",
                            "longitude": "-71.70507000"
                        },
                        {
                            "id": 127859,
                            "name": "Truro",
                            "latitude": "41.99344000",
                            "longitude": "-70.04975000"
                        },
                        {
                            "id": 127905,
                            "name": "Turners Falls",
                            "latitude": "42.60425000",
                            "longitude": "-72.55648000"
                        },
                        {
                            "id": 127947,
                            "name": "Tyngsboro",
                            "latitude": "42.67676000",
                            "longitude": "-71.42451000"
                        },
                        {
                            "id": 128055,
                            "name": "Upton",
                            "latitude": "42.17454000",
                            "longitude": "-71.60229000"
                        },
                        {
                            "id": 128071,
                            "name": "Uxbridge",
                            "latitude": "42.07732000",
                            "longitude": "-71.62951000"
                        },
                        {
                            "id": 128274,
                            "name": "Vineyard Haven",
                            "latitude": "41.45428000",
                            "longitude": "-70.60364000"
                        },
                        {
                            "id": 128359,
                            "name": "Wakefield",
                            "latitude": "42.50648000",
                            "longitude": "-71.07283000"
                        },
                        {
                            "id": 128379,
                            "name": "Wales",
                            "latitude": "42.06954000",
                            "longitude": "-72.22230000"
                        },
                        {
                            "id": 128425,
                            "name": "Walpole",
                            "latitude": "42.14177000",
                            "longitude": "-71.24950000"
                        },
                        {
                            "id": 128432,
                            "name": "Waltham",
                            "latitude": "42.37649000",
                            "longitude": "-71.23561000"
                        },
                        {
                            "id": 128462,
                            "name": "Ware",
                            "latitude": "42.25981000",
                            "longitude": "-72.23980000"
                        },
                        {
                            "id": 128465,
                            "name": "Wareham Center",
                            "latitude": "41.76677000",
                            "longitude": "-70.72615000"
                        },
                        {
                            "id": 128478,
                            "name": "Warren",
                            "latitude": "42.21259000",
                            "longitude": "-72.19119000"
                        },
                        {
                            "id": 128621,
                            "name": "Watertown",
                            "latitude": "42.37093000",
                            "longitude": "-71.18283000"
                        },
                        {
                            "id": 128678,
                            "name": "Wayland",
                            "latitude": "42.36260000",
                            "longitude": "-71.36145000"
                        },
                        {
                            "id": 128731,
                            "name": "Webster",
                            "latitude": "42.05010000",
                            "longitude": "-71.88007000"
                        },
                        {
                            "id": 128773,
                            "name": "Wellesley",
                            "latitude": "42.29649000",
                            "longitude": "-71.29256000"
                        },
                        {
                            "id": 128774,
                            "name": "Wellfleet",
                            "latitude": "41.93761000",
                            "longitude": "-70.03280000"
                        },
                        {
                            "id": 128799,
                            "name": "Wendell",
                            "latitude": "42.54814000",
                            "longitude": "-72.39675000"
                        },
                        {
                            "id": 128802,
                            "name": "Wenham",
                            "latitude": "42.60426000",
                            "longitude": "-70.89116000"
                        },
                        {
                            "id": 128821,
                            "name": "West Barnstable",
                            "latitude": "41.70566000",
                            "longitude": "-70.37447000"
                        },
                        {
                            "id": 128830,
                            "name": "West Boylston",
                            "latitude": "42.36676000",
                            "longitude": "-71.78563000"
                        },
                        {
                            "id": 128835,
                            "name": "West Bridgewater",
                            "latitude": "42.01899000",
                            "longitude": "-71.00782000"
                        },
                        {
                            "id": 128836,
                            "name": "West Brookfield",
                            "latitude": "42.23537000",
                            "longitude": "-72.14119000"
                        },
                        {
                            "id": 128844,
                            "name": "West Chatham",
                            "latitude": "41.68122000",
                            "longitude": "-69.99113000"
                        },
                        {
                            "id": 128850,
                            "name": "West Concord",
                            "latitude": "42.45843000",
                            "longitude": "-71.39534000"
                        },
                        {
                            "id": 128856,
                            "name": "West Dennis",
                            "latitude": "41.66456000",
                            "longitude": "-70.17280000"
                        },
                        {
                            "id": 128868,
                            "name": "West Falmouth",
                            "latitude": "41.60427000",
                            "longitude": "-70.63447000"
                        },
                        {
                            "id": 128939,
                            "name": "West Newbury",
                            "latitude": "42.80148000",
                            "longitude": "-70.98978000"
                        },
                        {
                            "id": 128985,
                            "name": "West Springfield",
                            "latitude": "42.10704000",
                            "longitude": "-72.62037000"
                        },
                        {
                            "id": 128986,
                            "name": "West Stockbridge",
                            "latitude": "42.34592000",
                            "longitude": "-73.36622000"
                        },
                        {
                            "id": 128990,
                            "name": "West Tisbury",
                            "latitude": "41.38122000",
                            "longitude": "-70.67447000"
                        },
                        {
                            "id": 129000,
                            "name": "West Wareham",
                            "latitude": "41.78983000",
                            "longitude": "-70.76031000"
                        },
                        {
                            "id": 129008,
                            "name": "West Yarmouth",
                            "latitude": "41.65011000",
                            "longitude": "-70.24113000"
                        },
                        {
                            "id": 129012,
                            "name": "Westborough",
                            "latitude": "42.26954000",
                            "longitude": "-71.61618000"
                        },
                        {
                            "id": 129028,
                            "name": "Westfield",
                            "latitude": "42.12509000",
                            "longitude": "-72.74954000"
                        },
                        {
                            "id": 129033,
                            "name": "Westford",
                            "latitude": "42.57926000",
                            "longitude": "-71.43784000"
                        },
                        {
                            "id": 129034,
                            "name": "Westhampton",
                            "latitude": "42.30287000",
                            "longitude": "-72.77454000"
                        },
                        {
                            "id": 129046,
                            "name": "Westminster",
                            "latitude": "42.54592000",
                            "longitude": "-71.91063000"
                        },
                        {
                            "id": 129061,
                            "name": "Weston",
                            "latitude": "42.36676000",
                            "longitude": "-71.30311000"
                        },
                        {
                            "id": 129085,
                            "name": "Westwood",
                            "latitude": "42.21399000",
                            "longitude": "-71.22450000"
                        },
                        {
                            "id": 129095,
                            "name": "Weweantic",
                            "latitude": "41.73538000",
                            "longitude": "-70.73198000"
                        },
                        {
                            "id": 129100,
                            "name": "Weymouth",
                            "latitude": "42.22093000",
                            "longitude": "-70.93977000"
                        },
                        {
                            "id": 129106,
                            "name": "Whately",
                            "latitude": "42.43981000",
                            "longitude": "-72.63481000"
                        },
                        {
                            "id": 129144,
                            "name": "White Island Shores",
                            "latitude": "41.80010000",
                            "longitude": "-70.63475000"
                        },
                        {
                            "id": 129198,
                            "name": "Whitinsville",
                            "latitude": "42.11121000",
                            "longitude": "-71.66618000"
                        },
                        {
                            "id": 129203,
                            "name": "Whitman",
                            "latitude": "42.08066000",
                            "longitude": "-70.93560000"
                        },
                        {
                            "id": 129226,
                            "name": "Wilbraham",
                            "latitude": "42.12371000",
                            "longitude": "-72.43147000"
                        },
                        {
                            "id": 129269,
                            "name": "Williamsburg",
                            "latitude": "42.39314000",
                            "longitude": "-72.73009000"
                        },
                        {
                            "id": 129288,
                            "name": "Williamstown",
                            "latitude": "42.71202000",
                            "longitude": "-73.20372000"
                        },
                        {
                            "id": 129324,
                            "name": "Wilmington",
                            "latitude": "42.54648000",
                            "longitude": "-71.17367000"
                        },
                        {
                            "id": 129351,
                            "name": "Winchendon",
                            "latitude": "42.68620000",
                            "longitude": "-72.04397000"
                        },
                        {
                            "id": 129358,
                            "name": "Winchester",
                            "latitude": "42.45232000",
                            "longitude": "-71.13700000"
                        },
                        {
                            "id": 129450,
                            "name": "Winthrop",
                            "latitude": "42.37510000",
                            "longitude": "-70.98283000"
                        },
                        {
                            "id": 129466,
                            "name": "Woburn",
                            "latitude": "42.47926000",
                            "longitude": "-71.15228000"
                        },
                        {
                            "id": 129584,
                            "name": "Worcester",
                            "latitude": "42.26259000",
                            "longitude": "-71.80229000"
                        },
                        {
                            "id": 129587,
                            "name": "Worcester County",
                            "latitude": "42.35140000",
                            "longitude": "-71.90774000"
                        },
                        {
                            "id": 129604,
                            "name": "Wrentham",
                            "latitude": "42.06677000",
                            "longitude": "-71.32811000"
                        },
                        {
                            "id": 129667,
                            "name": "Yarmouth",
                            "latitude": "41.70567000",
                            "longitude": "-70.22863000"
                        },
                        {
                            "id": 129668,
                            "name": "Yarmouth Port",
                            "latitude": "41.70205000",
                            "longitude": "-70.24947000"
                        }
                    ]
                },
                {
                    "id": 1426,
                    "name": "Michigan",
                    "state_code": "MI",
                    "cities": [
                        {
                            "id": 111046,
                            "name": "Adrian",
                            "latitude": "41.89755000",
                            "longitude": "-84.03717000"
                        },
                        {
                            "id": 111126,
                            "name": "Albion",
                            "latitude": "42.24310000",
                            "longitude": "-84.75303000"
                        },
                        {
                            "id": 111133,
                            "name": "Alcona County",
                            "latitude": "44.71161000",
                            "longitude": "-83.34366000"
                        },
                        {
                            "id": 111161,
                            "name": "Alger County",
                            "latitude": "46.45110000",
                            "longitude": "-86.54755000"
                        },
                        {
                            "id": 111165,
                            "name": "Algonac",
                            "latitude": "42.61858000",
                            "longitude": "-82.53230000"
                        },
                        {
                            "id": 111178,
                            "name": "Allegan",
                            "latitude": "42.52920000",
                            "longitude": "-85.85530000"
                        },
                        {
                            "id": 111179,
                            "name": "Allegan County",
                            "latitude": "42.51766000",
                            "longitude": "-85.91034000"
                        },
                        {
                            "id": 111192,
                            "name": "Allen Park",
                            "latitude": "42.25754000",
                            "longitude": "-83.21104000"
                        },
                        {
                            "id": 111194,
                            "name": "Allendale",
                            "latitude": "42.97225000",
                            "longitude": "-85.95365000"
                        },
                        {
                            "id": 111209,
                            "name": "Alma",
                            "latitude": "43.37892000",
                            "longitude": "-84.65973000"
                        },
                        {
                            "id": 111213,
                            "name": "Almont",
                            "latitude": "42.92058000",
                            "longitude": "-83.04493000"
                        },
                        {
                            "id": 111218,
                            "name": "Alpena",
                            "latitude": "45.06168000",
                            "longitude": "-83.43275000"
                        },
                        {
                            "id": 111219,
                            "name": "Alpena County",
                            "latitude": "45.06350000",
                            "longitude": "-83.46039000"
                        },
                        {
                            "id": 111345,
                            "name": "Ann Arbor",
                            "latitude": "42.27756000",
                            "longitude": "-83.74088000"
                        },
                        {
                            "id": 111377,
                            "name": "Antrim County",
                            "latitude": "45.00737000",
                            "longitude": "-85.17579000"
                        },
                        {
                            "id": 111445,
                            "name": "Arenac County",
                            "latitude": "44.04289000",
                            "longitude": "-83.74725000"
                        },
                        {
                            "id": 111447,
                            "name": "Argentine",
                            "latitude": "42.79142000",
                            "longitude": "-83.84634000"
                        },
                        {
                            "id": 111476,
                            "name": "Armada",
                            "latitude": "42.84420000",
                            "longitude": "-82.88437000"
                        },
                        {
                            "id": 111578,
                            "name": "Athens",
                            "latitude": "42.08866000",
                            "longitude": "-85.23471000"
                        },
                        {
                            "id": 111593,
                            "name": "Atlanta",
                            "latitude": "45.00473000",
                            "longitude": "-84.14389000"
                        },
                        {
                            "id": 111616,
                            "name": "Au Sable",
                            "latitude": "44.41085000",
                            "longitude": "-83.33219000"
                        },
                        {
                            "id": 111626,
                            "name": "Auburn",
                            "latitude": "43.60336000",
                            "longitude": "-84.06970000"
                        },
                        {
                            "id": 111634,
                            "name": "Auburn Hills",
                            "latitude": "42.68753000",
                            "longitude": "-83.23410000"
                        },
                        {
                            "id": 111721,
                            "name": "Bad Axe",
                            "latitude": "43.80196000",
                            "longitude": "-83.00078000"
                        },
                        {
                            "id": 111755,
                            "name": "Baldwin",
                            "latitude": "43.90112000",
                            "longitude": "-85.85173000"
                        },
                        {
                            "id": 111791,
                            "name": "Bangor",
                            "latitude": "42.31254000",
                            "longitude": "-86.11308000"
                        },
                        {
                            "id": 111809,
                            "name": "Baraga",
                            "latitude": "46.77854000",
                            "longitude": "-88.48902000"
                        },
                        {
                            "id": 111810,
                            "name": "Baraga County",
                            "latitude": "46.69976000",
                            "longitude": "-88.35215000"
                        },
                        {
                            "id": 111827,
                            "name": "Barnes Lake-Millers Lake",
                            "latitude": "43.17956000",
                            "longitude": "-83.31230000"
                        },
                        {
                            "id": 111856,
                            "name": "Barry County",
                            "latitude": "42.59503000",
                            "longitude": "-85.30897000"
                        },
                        {
                            "id": 111896,
                            "name": "Bath",
                            "latitude": "42.81864000",
                            "longitude": "-84.44859000"
                        },
                        {
                            "id": 111904,
                            "name": "Battle Creek",
                            "latitude": "42.31730000",
                            "longitude": "-85.17816000"
                        },
                        {
                            "id": 111922,
                            "name": "Bay City",
                            "latitude": "43.59447000",
                            "longitude": "-83.88886000"
                        },
                        {
                            "id": 111925,
                            "name": "Bay County",
                            "latitude": "43.72137000",
                            "longitude": "-83.94184000"
                        },
                        {
                            "id": 111926,
                            "name": "Bay Harbor",
                            "latitude": "45.36413000",
                            "longitude": "-85.08208000"
                        },
                        {
                            "id": 112009,
                            "name": "Beaverton",
                            "latitude": "43.88225000",
                            "longitude": "-84.48473000"
                        },
                        {
                            "id": 112040,
                            "name": "Beecher",
                            "latitude": "43.09003000",
                            "longitude": "-83.69440000"
                        },
                        {
                            "id": 112042,
                            "name": "Beechwood",
                            "latitude": "42.79697000",
                            "longitude": "-86.12588000"
                        },
                        {
                            "id": 112053,
                            "name": "Belding",
                            "latitude": "43.09781000",
                            "longitude": "-85.22891000"
                        },
                        {
                            "id": 112076,
                            "name": "Bellaire",
                            "latitude": "44.98028000",
                            "longitude": "-85.21117000"
                        },
                        {
                            "id": 112105,
                            "name": "Belleville",
                            "latitude": "42.20476000",
                            "longitude": "-83.48521000"
                        },
                        {
                            "id": 112112,
                            "name": "Bellevue",
                            "latitude": "42.44337000",
                            "longitude": "-85.01805000"
                        },
                        {
                            "id": 112208,
                            "name": "Benton Harbor",
                            "latitude": "42.11671000",
                            "longitude": "-86.45419000"
                        },
                        {
                            "id": 112209,
                            "name": "Benton Heights",
                            "latitude": "42.13115000",
                            "longitude": "-86.40724000"
                        },
                        {
                            "id": 112212,
                            "name": "Benzie County",
                            "latitude": "44.61687000",
                            "longitude": "-86.13899000"
                        },
                        {
                            "id": 112230,
                            "name": "Berkley",
                            "latitude": "42.50309000",
                            "longitude": "-83.18354000"
                        },
                        {
                            "id": 112250,
                            "name": "Berrien County",
                            "latitude": "41.91863000",
                            "longitude": "-86.42807000"
                        },
                        {
                            "id": 112251,
                            "name": "Berrien Springs",
                            "latitude": "41.94643000",
                            "longitude": "-86.33890000"
                        },
                        {
                            "id": 112268,
                            "name": "Bessemer",
                            "latitude": "46.48134000",
                            "longitude": "-90.05295000"
                        },
                        {
                            "id": 112295,
                            "name": "Beulah",
                            "latitude": "44.63194000",
                            "longitude": "-86.09092000"
                        },
                        {
                            "id": 112305,
                            "name": "Beverly Hills",
                            "latitude": "42.52392000",
                            "longitude": "-83.22326000"
                        },
                        {
                            "id": 112330,
                            "name": "Big Rapids",
                            "latitude": "43.69808000",
                            "longitude": "-85.48366000"
                        },
                        {
                            "id": 112350,
                            "name": "Bingham Farms",
                            "latitude": "42.51587000",
                            "longitude": "-83.27326000"
                        },
                        {
                            "id": 112354,
                            "name": "Birch Run",
                            "latitude": "43.25086000",
                            "longitude": "-83.79413000"
                        },
                        {
                            "id": 112359,
                            "name": "Birmingham",
                            "latitude": "42.54670000",
                            "longitude": "-83.21132000"
                        },
                        {
                            "id": 112431,
                            "name": "Blissfield",
                            "latitude": "41.83255000",
                            "longitude": "-83.86244000"
                        },
                        {
                            "id": 112441,
                            "name": "Bloomfield Hills",
                            "latitude": "42.58364000",
                            "longitude": "-83.24549000"
                        },
                        {
                            "id": 112651,
                            "name": "Boyne City",
                            "latitude": "45.21668000",
                            "longitude": "-85.01394000"
                        },
                        {
                            "id": 112685,
                            "name": "Branch County",
                            "latitude": "41.91611000",
                            "longitude": "-85.05903000"
                        },
                        {
                            "id": 112711,
                            "name": "Breckenridge",
                            "latitude": "43.40808000",
                            "longitude": "-84.47500000"
                        },
                        {
                            "id": 112762,
                            "name": "Bridgeport",
                            "latitude": "43.35947000",
                            "longitude": "-83.88164000"
                        },
                        {
                            "id": 112778,
                            "name": "Bridgman",
                            "latitude": "41.94310000",
                            "longitude": "-86.55697000"
                        },
                        {
                            "id": 112789,
                            "name": "Brighton",
                            "latitude": "42.52948000",
                            "longitude": "-83.78022000"
                        },
                        {
                            "id": 112845,
                            "name": "Bronson",
                            "latitude": "41.87227000",
                            "longitude": "-85.19470000"
                        },
                        {
                            "id": 112871,
                            "name": "Brooklyn",
                            "latitude": "42.10587000",
                            "longitude": "-84.24828000"
                        },
                        {
                            "id": 112906,
                            "name": "Brown City",
                            "latitude": "43.21225000",
                            "longitude": "-82.98966000"
                        },
                        {
                            "id": 112919,
                            "name": "Brownlee Park",
                            "latitude": "42.31893000",
                            "longitude": "-85.14249000"
                        },
                        {
                            "id": 112971,
                            "name": "Buchanan",
                            "latitude": "41.82727000",
                            "longitude": "-86.36112000"
                        },
                        {
                            "id": 113002,
                            "name": "Buena Vista",
                            "latitude": "43.42030000",
                            "longitude": "-83.89858000"
                        },
                        {
                            "id": 113094,
                            "name": "Burt",
                            "latitude": "43.23669000",
                            "longitude": "-83.90636000"
                        },
                        {
                            "id": 113097,
                            "name": "Burton",
                            "latitude": "42.99947000",
                            "longitude": "-83.61634000"
                        },
                        {
                            "id": 113147,
                            "name": "Byron Center",
                            "latitude": "42.81225000",
                            "longitude": "-85.72281000"
                        },
                        {
                            "id": 113164,
                            "name": "Cadillac",
                            "latitude": "44.25195000",
                            "longitude": "-85.40116000"
                        },
                        {
                            "id": 113189,
                            "name": "Caledonia",
                            "latitude": "42.78920000",
                            "longitude": "-85.51669000"
                        },
                        {
                            "id": 113210,
                            "name": "Calhoun County",
                            "latitude": "42.24653000",
                            "longitude": "-85.00559000"
                        },
                        {
                            "id": 113316,
                            "name": "Canadian Lakes",
                            "latitude": "43.57919000",
                            "longitude": "-85.30170000"
                        },
                        {
                            "id": 113345,
                            "name": "Canton",
                            "latitude": "42.30865000",
                            "longitude": "-83.48216000"
                        },
                        {
                            "id": 113362,
                            "name": "Capac",
                            "latitude": "43.01253000",
                            "longitude": "-82.92799000"
                        },
                        {
                            "id": 113398,
                            "name": "Carleton",
                            "latitude": "42.05921000",
                            "longitude": "-83.39077000"
                        },
                        {
                            "id": 113432,
                            "name": "Caro",
                            "latitude": "43.49073000",
                            "longitude": "-83.39885000"
                        },
                        {
                            "id": 113471,
                            "name": "Carrollton",
                            "latitude": "43.45864000",
                            "longitude": "-83.93025000"
                        },
                        {
                            "id": 113477,
                            "name": "Carson City",
                            "latitude": "43.17698000",
                            "longitude": "-84.84639000"
                        },
                        {
                            "id": 113525,
                            "name": "Cass City",
                            "latitude": "43.60085000",
                            "longitude": "-83.17467000"
                        },
                        {
                            "id": 113531,
                            "name": "Cass County",
                            "latitude": "41.91540000",
                            "longitude": "-85.99346000"
                        },
                        {
                            "id": 113538,
                            "name": "Cassopolis",
                            "latitude": "41.91171000",
                            "longitude": "-86.01001000"
                        },
                        {
                            "id": 113623,
                            "name": "Cedar Springs",
                            "latitude": "43.22336000",
                            "longitude": "-85.55142000"
                        },
                        {
                            "id": 113644,
                            "name": "Center Line",
                            "latitude": "42.48504000",
                            "longitude": "-83.02770000"
                        },
                        {
                            "id": 113696,
                            "name": "Centreville",
                            "latitude": "41.92338000",
                            "longitude": "-85.52832000"
                        },
                        {
                            "id": 113774,
                            "name": "Charlevoix",
                            "latitude": "45.31806000",
                            "longitude": "-85.25840000"
                        },
                        {
                            "id": 113775,
                            "name": "Charlevoix County",
                            "latitude": "45.26715000",
                            "longitude": "-85.24017000"
                        },
                        {
                            "id": 113779,
                            "name": "Charlotte",
                            "latitude": "42.56365000",
                            "longitude": "-84.83582000"
                        },
                        {
                            "id": 113821,
                            "name": "Cheboygan",
                            "latitude": "45.64696000",
                            "longitude": "-84.47448000"
                        },
                        {
                            "id": 113822,
                            "name": "Cheboygan County",
                            "latitude": "45.47294000",
                            "longitude": "-84.49206000"
                        },
                        {
                            "id": 113833,
                            "name": "Chelsea",
                            "latitude": "42.31807000",
                            "longitude": "-84.02181000"
                        },
                        {
                            "id": 113872,
                            "name": "Chesaning",
                            "latitude": "43.18475000",
                            "longitude": "-84.11497000"
                        },
                        {
                            "id": 113973,
                            "name": "Chippewa County",
                            "latitude": "46.32818000",
                            "longitude": "-84.52936000"
                        },
                        {
                            "id": 114102,
                            "name": "Clare",
                            "latitude": "43.81947000",
                            "longitude": "-84.76863000"
                        },
                        {
                            "id": 114103,
                            "name": "Clare County",
                            "latitude": "43.98787000",
                            "longitude": "-84.84784000"
                        },
                        {
                            "id": 114149,
                            "name": "Clarkston",
                            "latitude": "42.73586000",
                            "longitude": "-83.41883000"
                        },
                        {
                            "id": 114163,
                            "name": "Clawson",
                            "latitude": "42.53337000",
                            "longitude": "-83.14632000"
                        },
                        {
                            "id": 114282,
                            "name": "Clinton",
                            "latitude": "42.07199000",
                            "longitude": "-83.97161000"
                        },
                        {
                            "id": 114294,
                            "name": "Clinton County",
                            "latitude": "42.94365000",
                            "longitude": "-84.60152000"
                        },
                        {
                            "id": 114297,
                            "name": "Clinton Township",
                            "latitude": "42.58698000",
                            "longitude": "-82.91992000"
                        },
                        {
                            "id": 114302,
                            "name": "Clio",
                            "latitude": "43.17753000",
                            "longitude": "-83.73413000"
                        },
                        {
                            "id": 114394,
                            "name": "Coldwater",
                            "latitude": "41.94033000",
                            "longitude": "-85.00052000"
                        },
                        {
                            "id": 114399,
                            "name": "Coleman",
                            "latitude": "43.75669000",
                            "longitude": "-84.58584000"
                        },
                        {
                            "id": 114443,
                            "name": "Coloma",
                            "latitude": "42.18615000",
                            "longitude": "-86.30836000"
                        },
                        {
                            "id": 114444,
                            "name": "Colon",
                            "latitude": "41.95838000",
                            "longitude": "-85.32498000"
                        },
                        {
                            "id": 114530,
                            "name": "Comstock Northwest",
                            "latitude": "42.32182000",
                            "longitude": "-85.51759000"
                        },
                        {
                            "id": 114531,
                            "name": "Comstock Park",
                            "latitude": "43.03864000",
                            "longitude": "-85.67003000"
                        },
                        {
                            "id": 114539,
                            "name": "Concord",
                            "latitude": "42.17782000",
                            "longitude": "-84.64302000"
                        },
                        {
                            "id": 114573,
                            "name": "Constantine",
                            "latitude": "41.84116000",
                            "longitude": "-85.66860000"
                        },
                        {
                            "id": 114608,
                            "name": "Coopersville",
                            "latitude": "43.06391000",
                            "longitude": "-85.93477000"
                        },
                        {
                            "id": 114679,
                            "name": "Corunna",
                            "latitude": "42.98197000",
                            "longitude": "-84.11775000"
                        },
                        {
                            "id": 114781,
                            "name": "Crawford County",
                            "latitude": "44.68361000",
                            "longitude": "-84.61030000"
                        },
                        {
                            "id": 114857,
                            "name": "Croswell",
                            "latitude": "43.27558000",
                            "longitude": "-82.62104000"
                        },
                        {
                            "id": 114877,
                            "name": "Crystal Falls",
                            "latitude": "46.09801000",
                            "longitude": "-88.33402000"
                        },
                        {
                            "id": 114950,
                            "name": "Cutlerville",
                            "latitude": "42.84086000",
                            "longitude": "-85.66364000"
                        },
                        {
                            "id": 115078,
                            "name": "Davison",
                            "latitude": "43.03475000",
                            "longitude": "-83.51801000"
                        },
                        {
                            "id": 115145,
                            "name": "Dearborn",
                            "latitude": "42.32226000",
                            "longitude": "-83.17631000"
                        },
                        {
                            "id": 115147,
                            "name": "Dearborn Heights",
                            "latitude": "42.33698000",
                            "longitude": "-83.27326000"
                        },
                        {
                            "id": 115157,
                            "name": "Decatur",
                            "latitude": "42.10810000",
                            "longitude": "-85.97446000"
                        },
                        {
                            "id": 115225,
                            "name": "Delta County",
                            "latitude": "45.79162000",
                            "longitude": "-86.87060000"
                        },
                        {
                            "id": 115291,
                            "name": "Detroit",
                            "latitude": "42.33143000",
                            "longitude": "-83.04575000"
                        },
                        {
                            "id": 115292,
                            "name": "Detroit Beach",
                            "latitude": "41.93116000",
                            "longitude": "-83.32688000"
                        },
                        {
                            "id": 115140,
                            "name": "DeWitt",
                            "latitude": "42.84226000",
                            "longitude": "-84.56915000"
                        },
                        {
                            "id": 115309,
                            "name": "Dexter",
                            "latitude": "42.33834000",
                            "longitude": "-83.88954000"
                        },
                        {
                            "id": 115329,
                            "name": "Dickinson County",
                            "latitude": "46.00935000",
                            "longitude": "-87.87021000"
                        },
                        {
                            "id": 115350,
                            "name": "Dimondale",
                            "latitude": "42.64559000",
                            "longitude": "-84.64887000"
                        },
                        {
                            "id": 115388,
                            "name": "Dollar Bay",
                            "latitude": "47.11965000",
                            "longitude": "-88.51151000"
                        },
                        {
                            "id": 115422,
                            "name": "Douglas",
                            "latitude": "42.64336000",
                            "longitude": "-86.20059000"
                        },
                        {
                            "id": 115458,
                            "name": "Dowagiac",
                            "latitude": "41.98421000",
                            "longitude": "-86.10862000"
                        },
                        {
                            "id": 115529,
                            "name": "Dundee",
                            "latitude": "41.95727000",
                            "longitude": "-83.65966000"
                        },
                        {
                            "id": 115563,
                            "name": "Durand",
                            "latitude": "42.91198000",
                            "longitude": "-83.98468000"
                        },
                        {
                            "id": 115610,
                            "name": "Eagle River",
                            "latitude": "47.41381000",
                            "longitude": "-88.29566000"
                        },
                        {
                            "id": 115671,
                            "name": "East Grand Rapids",
                            "latitude": "42.94114000",
                            "longitude": "-85.61003000"
                        },
                        {
                            "id": 115697,
                            "name": "East Jordan",
                            "latitude": "45.15806000",
                            "longitude": "-85.12423000"
                        },
                        {
                            "id": 115703,
                            "name": "East Lansing",
                            "latitude": "42.73698000",
                            "longitude": "-84.48387000"
                        },
                        {
                            "id": 115764,
                            "name": "East Tawas",
                            "latitude": "44.27946000",
                            "longitude": "-83.49025000"
                        },
                        {
                            "id": 115794,
                            "name": "Eastpointe",
                            "latitude": "42.46837000",
                            "longitude": "-82.95547000"
                        },
                        {
                            "id": 115800,
                            "name": "Eastwood",
                            "latitude": "42.30310000",
                            "longitude": "-85.55028000"
                        },
                        {
                            "id": 115803,
                            "name": "Eaton County",
                            "latitude": "42.59607000",
                            "longitude": "-84.83831000"
                        },
                        {
                            "id": 115804,
                            "name": "Eaton Rapids",
                            "latitude": "42.50920000",
                            "longitude": "-84.65581000"
                        },
                        {
                            "id": 115818,
                            "name": "Ecorse",
                            "latitude": "42.24448000",
                            "longitude": "-83.14576000"
                        },
                        {
                            "id": 115846,
                            "name": "Edgemont Park",
                            "latitude": "42.74670000",
                            "longitude": "-84.59359000"
                        },
                        {
                            "id": 115882,
                            "name": "Edmore",
                            "latitude": "43.40809000",
                            "longitude": "-85.03863000"
                        },
                        {
                            "id": 115892,
                            "name": "Edwardsburg",
                            "latitude": "41.79560000",
                            "longitude": "-86.08084000"
                        },
                        {
                            "id": 115991,
                            "name": "Elk Rapids",
                            "latitude": "44.89556000",
                            "longitude": "-85.41646000"
                        },
                        {
                            "id": 116103,
                            "name": "Emmet County",
                            "latitude": "45.58754000",
                            "longitude": "-84.98147000"
                        },
                        {
                            "id": 116181,
                            "name": "Escanaba",
                            "latitude": "45.74525000",
                            "longitude": "-87.06458000"
                        },
                        {
                            "id": 116201,
                            "name": "Essexville",
                            "latitude": "43.61530000",
                            "longitude": "-83.84192000"
                        },
                        {
                            "id": 116256,
                            "name": "Evart",
                            "latitude": "43.90058000",
                            "longitude": "-85.25810000"
                        },
                        {
                            "id": 116288,
                            "name": "Fair Plain",
                            "latitude": "42.08699000",
                            "longitude": "-86.45586000"
                        },
                        {
                            "id": 116417,
                            "name": "Farmington",
                            "latitude": "42.46448000",
                            "longitude": "-83.37632000"
                        },
                        {
                            "id": 116423,
                            "name": "Farmington Hills",
                            "latitude": "42.48531000",
                            "longitude": "-83.37716000"
                        },
                        {
                            "id": 116473,
                            "name": "Fennville",
                            "latitude": "42.59392000",
                            "longitude": "-86.10170000"
                        },
                        {
                            "id": 116475,
                            "name": "Fenton",
                            "latitude": "42.79781000",
                            "longitude": "-83.70495000"
                        },
                        {
                            "id": 116487,
                            "name": "Ferndale",
                            "latitude": "42.46059000",
                            "longitude": "-83.13465000"
                        },
                        {
                            "id": 116500,
                            "name": "Ferrysburg",
                            "latitude": "43.08446000",
                            "longitude": "-86.22033000"
                        },
                        {
                            "id": 116552,
                            "name": "Flat Rock",
                            "latitude": "42.09643000",
                            "longitude": "-83.29187000"
                        },
                        {
                            "id": 116566,
                            "name": "Flint",
                            "latitude": "43.01253000",
                            "longitude": "-83.68746000"
                        },
                        {
                            "id": 116613,
                            "name": "Flushing",
                            "latitude": "43.06308000",
                            "longitude": "-83.85107000"
                        },
                        {
                            "id": 116657,
                            "name": "Forest Hills",
                            "latitude": "42.95947000",
                            "longitude": "-85.48975000"
                        },
                        {
                            "id": 116795,
                            "name": "Fowler",
                            "latitude": "43.00170000",
                            "longitude": "-84.73972000"
                        },
                        {
                            "id": 116798,
                            "name": "Fowlerville",
                            "latitude": "42.66059000",
                            "longitude": "-84.07301000"
                        },
                        {
                            "id": 116817,
                            "name": "Frankenmuth",
                            "latitude": "43.33169000",
                            "longitude": "-83.73802000"
                        },
                        {
                            "id": 116821,
                            "name": "Frankfort",
                            "latitude": "44.63361000",
                            "longitude": "-86.23454000"
                        },
                        {
                            "id": 116836,
                            "name": "Franklin",
                            "latitude": "42.52226000",
                            "longitude": "-83.30604000"
                        },
                        {
                            "id": 116880,
                            "name": "Fraser",
                            "latitude": "42.53920000",
                            "longitude": "-82.94937000"
                        },
                        {
                            "id": 116905,
                            "name": "Freeland",
                            "latitude": "43.52503000",
                            "longitude": "-84.12276000"
                        },
                        {
                            "id": 116921,
                            "name": "Fremont",
                            "latitude": "43.46752000",
                            "longitude": "-85.94200000"
                        },
                        {
                            "id": 116974,
                            "name": "Fruitport",
                            "latitude": "43.13196000",
                            "longitude": "-86.15478000"
                        },
                        {
                            "id": 117028,
                            "name": "Galesburg",
                            "latitude": "42.28865000",
                            "longitude": "-85.41806000"
                        },
                        {
                            "id": 117057,
                            "name": "Garden City",
                            "latitude": "42.32559000",
                            "longitude": "-83.33104000"
                        },
                        {
                            "id": 117127,
                            "name": "Gaylord",
                            "latitude": "45.02751000",
                            "longitude": "-84.67475000"
                        },
                        {
                            "id": 117135,
                            "name": "Genesee County",
                            "latitude": "43.02172000",
                            "longitude": "-83.70671000"
                        },
                        {
                            "id": 117185,
                            "name": "Gibraltar",
                            "latitude": "42.09504000",
                            "longitude": "-83.18965000"
                        },
                        {
                            "id": 117237,
                            "name": "Gladstone",
                            "latitude": "45.85274000",
                            "longitude": "-87.02180000"
                        },
                        {
                            "id": 117240,
                            "name": "Gladwin",
                            "latitude": "43.98085000",
                            "longitude": "-84.48640000"
                        },
                        {
                            "id": 117241,
                            "name": "Gladwin County",
                            "latitude": "43.99067000",
                            "longitude": "-84.38825000"
                        },
                        {
                            "id": 117337,
                            "name": "Gogebic County",
                            "latitude": "46.49552000",
                            "longitude": "-89.79555000"
                        },
                        {
                            "id": 117389,
                            "name": "Goodrich",
                            "latitude": "42.91697000",
                            "longitude": "-83.50634000"
                        },
                        {
                            "id": 117450,
                            "name": "Grand Blanc",
                            "latitude": "42.92753000",
                            "longitude": "-83.62995000"
                        },
                        {
                            "id": 117460,
                            "name": "Grand Haven",
                            "latitude": "43.06307000",
                            "longitude": "-86.22839000"
                        },
                        {
                            "id": 117466,
                            "name": "Grand Ledge",
                            "latitude": "42.75337000",
                            "longitude": "-84.74638000"
                        },
                        {
                            "id": 117473,
                            "name": "Grand Rapids",
                            "latitude": "42.96336000",
                            "longitude": "-85.66809000"
                        },
                        {
                            "id": 117477,
                            "name": "Grand Traverse County",
                            "latitude": "44.71624000",
                            "longitude": "-85.55220000"
                        },
                        {
                            "id": 117483,
                            "name": "Grandville",
                            "latitude": "42.90975000",
                            "longitude": "-85.76309000"
                        },
                        {
                            "id": 117542,
                            "name": "Grass Lake",
                            "latitude": "42.25087000",
                            "longitude": "-84.21301000"
                        },
                        {
                            "id": 117544,
                            "name": "Gratiot County",
                            "latitude": "43.29273000",
                            "longitude": "-84.60491000"
                        },
                        {
                            "id": 117556,
                            "name": "Grayling",
                            "latitude": "44.66140000",
                            "longitude": "-84.71475000"
                        },
                        {
                            "id": 117690,
                            "name": "Greenville",
                            "latitude": "43.17753000",
                            "longitude": "-85.25280000"
                        },
                        {
                            "id": 117719,
                            "name": "Greilickville",
                            "latitude": "44.78306000",
                            "longitude": "-85.63869000"
                        },
                        {
                            "id": 117743,
                            "name": "Grosse Ile",
                            "latitude": "42.12921000",
                            "longitude": "-83.14437000"
                        },
                        {
                            "id": 117744,
                            "name": "Grosse Pointe",
                            "latitude": "42.38615000",
                            "longitude": "-82.91186000"
                        },
                        {
                            "id": 117745,
                            "name": "Grosse Pointe Farms",
                            "latitude": "42.40920000",
                            "longitude": "-82.89186000"
                        },
                        {
                            "id": 117746,
                            "name": "Grosse Pointe Park",
                            "latitude": "42.37587000",
                            "longitude": "-82.93742000"
                        },
                        {
                            "id": 117747,
                            "name": "Grosse Pointe Shores",
                            "latitude": "42.43670000",
                            "longitude": "-82.87686000"
                        },
                        {
                            "id": 117748,
                            "name": "Grosse Pointe Woods",
                            "latitude": "42.44365000",
                            "longitude": "-82.90686000"
                        },
                        {
                            "id": 117820,
                            "name": "Gwinn",
                            "latitude": "46.28106000",
                            "longitude": "-87.44097000"
                        },
                        {
                            "id": 117940,
                            "name": "Hamtramck",
                            "latitude": "42.39282000",
                            "longitude": "-83.04964000"
                        },
                        {
                            "id": 117949,
                            "name": "Hancock",
                            "latitude": "47.12687000",
                            "longitude": "-88.58096000"
                        },
                        {
                            "id": 117988,
                            "name": "Harbor Beach",
                            "latitude": "43.84474000",
                            "longitude": "-82.65132000"
                        },
                        {
                            "id": 117991,
                            "name": "Harbor Springs",
                            "latitude": "45.43168000",
                            "longitude": "-84.99200000"
                        },
                        {
                            "id": 118035,
                            "name": "Harper Woods",
                            "latitude": "42.43309000",
                            "longitude": "-82.92408000"
                        },
                        {
                            "id": 118057,
                            "name": "Harrison",
                            "latitude": "44.01919000",
                            "longitude": "-84.79947000"
                        },
                        {
                            "id": 118074,
                            "name": "Harrisville",
                            "latitude": "44.65640000",
                            "longitude": "-83.29469000"
                        },
                        {
                            "id": 118080,
                            "name": "Hart",
                            "latitude": "43.69834000",
                            "longitude": "-86.36397000"
                        },
                        {
                            "id": 118088,
                            "name": "Hartford",
                            "latitude": "42.20671000",
                            "longitude": "-86.16669000"
                        },
                        {
                            "id": 118113,
                            "name": "Harvey",
                            "latitude": "46.49466000",
                            "longitude": "-87.35431000"
                        },
                        {
                            "id": 118129,
                            "name": "Haslett",
                            "latitude": "42.74698000",
                            "longitude": "-84.40108000"
                        },
                        {
                            "id": 118131,
                            "name": "Hastings",
                            "latitude": "42.64587000",
                            "longitude": "-85.29084000"
                        },
                        {
                            "id": 118206,
                            "name": "Hazel Park",
                            "latitude": "42.46254000",
                            "longitude": "-83.10409000"
                        },
                        {
                            "id": 118265,
                            "name": "Hemlock",
                            "latitude": "43.41475000",
                            "longitude": "-84.23054000"
                        },
                        {
                            "id": 118412,
                            "name": "Highland Park",
                            "latitude": "42.40559000",
                            "longitude": "-83.09687000"
                        },
                        {
                            "id": 118460,
                            "name": "Hillsdale",
                            "latitude": "41.92005000",
                            "longitude": "-84.63051000"
                        },
                        {
                            "id": 118462,
                            "name": "Hillsdale County",
                            "latitude": "41.88777000",
                            "longitude": "-84.59293000"
                        },
                        {
                            "id": 118539,
                            "name": "Holland",
                            "latitude": "42.78752000",
                            "longitude": "-86.10893000"
                        },
                        {
                            "id": 118555,
                            "name": "Holly",
                            "latitude": "42.79197000",
                            "longitude": "-83.62773000"
                        },
                        {
                            "id": 118575,
                            "name": "Holt",
                            "latitude": "42.64059000",
                            "longitude": "-84.51525000"
                        },
                        {
                            "id": 118595,
                            "name": "Homer",
                            "latitude": "42.14588000",
                            "longitude": "-84.80886000"
                        },
                        {
                            "id": 118689,
                            "name": "Houghton",
                            "latitude": "47.12187000",
                            "longitude": "-88.56901000"
                        },
                        {
                            "id": 118691,
                            "name": "Houghton County",
                            "latitude": "46.99155000",
                            "longitude": "-88.65206000"
                        },
                        {
                            "id": 118692,
                            "name": "Houghton Lake",
                            "latitude": "44.31474000",
                            "longitude": "-84.76475000"
                        },
                        {
                            "id": 118711,
                            "name": "Howard City",
                            "latitude": "43.39558000",
                            "longitude": "-85.46782000"
                        },
                        {
                            "id": 118722,
                            "name": "Howell",
                            "latitude": "42.60726000",
                            "longitude": "-83.92940000"
                        },
                        {
                            "id": 118733,
                            "name": "Hubbard Lake",
                            "latitude": "44.75973000",
                            "longitude": "-83.54442000"
                        },
                        {
                            "id": 118741,
                            "name": "Hudson",
                            "latitude": "41.85505000",
                            "longitude": "-84.35384000"
                        },
                        {
                            "id": 118753,
                            "name": "Hudsonville",
                            "latitude": "42.87086000",
                            "longitude": "-85.86504000"
                        },
                        {
                            "id": 118809,
                            "name": "Huntington Woods",
                            "latitude": "42.48059000",
                            "longitude": "-83.16687000"
                        },
                        {
                            "id": 118826,
                            "name": "Huron County",
                            "latitude": "43.91007000",
                            "longitude": "-82.85551000"
                        },
                        {
                            "id": 118879,
                            "name": "Imlay City",
                            "latitude": "43.02475000",
                            "longitude": "-83.07772000"
                        },
                        {
                            "id": 118912,
                            "name": "Indian River",
                            "latitude": "45.41251000",
                            "longitude": "-84.61254000"
                        },
                        {
                            "id": 118935,
                            "name": "Ingham County",
                            "latitude": "42.59710000",
                            "longitude": "-84.37354000"
                        },
                        {
                            "id": 118943,
                            "name": "Inkster",
                            "latitude": "42.29420000",
                            "longitude": "-83.30993000"
                        },
                        {
                            "id": 118971,
                            "name": "Ionia",
                            "latitude": "42.98725000",
                            "longitude": "-85.07112000"
                        },
                        {
                            "id": 118972,
                            "name": "Ionia County",
                            "latitude": "42.94509000",
                            "longitude": "-85.07460000"
                        },
                        {
                            "id": 118973,
                            "name": "Iosco County",
                            "latitude": "44.30125000",
                            "longitude": "-83.51395000"
                        },
                        {
                            "id": 118989,
                            "name": "Iron County",
                            "latitude": "46.20869000",
                            "longitude": "-88.53053000"
                        },
                        {
                            "id": 118992,
                            "name": "Iron Mountain",
                            "latitude": "45.82023000",
                            "longitude": "-88.06596000"
                        },
                        {
                            "id": 118993,
                            "name": "Iron River",
                            "latitude": "46.09273000",
                            "longitude": "-88.64235000"
                        },
                        {
                            "id": 118999,
                            "name": "Ironwood",
                            "latitude": "46.45467000",
                            "longitude": "-90.17101000"
                        },
                        {
                            "id": 119016,
                            "name": "Isabella County",
                            "latitude": "43.64060000",
                            "longitude": "-84.84680000"
                        },
                        {
                            "id": 119020,
                            "name": "Ishpeming",
                            "latitude": "46.48855000",
                            "longitude": "-87.66764000"
                        },
                        {
                            "id": 119043,
                            "name": "Ithaca",
                            "latitude": "43.29170000",
                            "longitude": "-84.60750000"
                        },
                        {
                            "id": 119071,
                            "name": "Jackson",
                            "latitude": "42.24587000",
                            "longitude": "-84.40135000"
                        },
                        {
                            "id": 119091,
                            "name": "Jackson County",
                            "latitude": "42.24849000",
                            "longitude": "-84.42344000"
                        },
                        {
                            "id": 119213,
                            "name": "Jenison",
                            "latitude": "42.90725000",
                            "longitude": "-85.79198000"
                        },
                        {
                            "id": 119312,
                            "name": "Jonesville",
                            "latitude": "41.98421000",
                            "longitude": "-84.66190000"
                        },
                        {
                            "id": 119347,
                            "name": "K. I. Sawyer Air Force Base",
                            "latitude": "46.34651000",
                            "longitude": "-87.38632000"
                        },
                        {
                            "id": 119360,
                            "name": "Kalamazoo",
                            "latitude": "42.29171000",
                            "longitude": "-85.58723000"
                        },
                        {
                            "id": 119361,
                            "name": "Kalamazoo County",
                            "latitude": "42.24545000",
                            "longitude": "-85.53118000"
                        },
                        {
                            "id": 119366,
                            "name": "Kalkaska",
                            "latitude": "44.73417000",
                            "longitude": "-85.17589000"
                        },
                        {
                            "id": 119367,
                            "name": "Kalkaska County",
                            "latitude": "44.68466000",
                            "longitude": "-85.09023000"
                        },
                        {
                            "id": 119418,
                            "name": "Keego Harbor",
                            "latitude": "42.60809000",
                            "longitude": "-83.34382000"
                        },
                        {
                            "id": 119489,
                            "name": "Kent City",
                            "latitude": "43.22002000",
                            "longitude": "-85.75115000"
                        },
                        {
                            "id": 119492,
                            "name": "Kent County",
                            "latitude": "43.03216000",
                            "longitude": "-85.54930000"
                        },
                        {
                            "id": 119501,
                            "name": "Kentwood",
                            "latitude": "42.86947000",
                            "longitude": "-85.64475000"
                        },
                        {
                            "id": 119536,
                            "name": "Keweenaw County",
                            "latitude": "47.28296000",
                            "longitude": "-88.21198000"
                        },
                        {
                            "id": 119562,
                            "name": "Kilmanagh",
                            "latitude": "43.75613000",
                            "longitude": "-83.35690000"
                        },
                        {
                            "id": 119612,
                            "name": "Kingsford",
                            "latitude": "45.79496000",
                            "longitude": "-88.07207000"
                        },
                        {
                            "id": 119618,
                            "name": "Kingsley",
                            "latitude": "44.58473000",
                            "longitude": "-85.53590000"
                        },
                        {
                            "id": 119734,
                            "name": "L'Anse",
                            "latitude": "46.75660000",
                            "longitude": "-88.45291000"
                        },
                        {
                            "id": 119861,
                            "name": "Laingsburg",
                            "latitude": "42.89031000",
                            "longitude": "-84.35136000"
                        },
                        {
                            "id": 119884,
                            "name": "Lake City",
                            "latitude": "44.33529000",
                            "longitude": "-85.21505000"
                        },
                        {
                            "id": 119893,
                            "name": "Lake County",
                            "latitude": "43.99001000",
                            "longitude": "-85.80170000"
                        },
                        {
                            "id": 119908,
                            "name": "Lake Fenton",
                            "latitude": "42.84614000",
                            "longitude": "-83.70773000"
                        },
                        {
                            "id": 119922,
                            "name": "Lake Isabella",
                            "latitude": "43.64364000",
                            "longitude": "-84.99725000"
                        },
                        {
                            "id": 119941,
                            "name": "Lake Michigan Beach",
                            "latitude": "42.22087000",
                            "longitude": "-86.36947000"
                        },
                        {
                            "id": 119953,
                            "name": "Lake Odessa",
                            "latitude": "42.78476000",
                            "longitude": "-85.13834000"
                        },
                        {
                            "id": 119954,
                            "name": "Lake Orion",
                            "latitude": "42.78448000",
                            "longitude": "-83.23966000"
                        },
                        {
                            "id": 120038,
                            "name": "Lakeview",
                            "latitude": "43.44642000",
                            "longitude": "-85.27420000"
                        },
                        {
                            "id": 120053,
                            "name": "Lakewood Club",
                            "latitude": "43.37112000",
                            "longitude": "-86.26034000"
                        },
                        {
                            "id": 120068,
                            "name": "Lambertville",
                            "latitude": "41.76588000",
                            "longitude": "-83.62799000"
                        },
                        {
                            "id": 120127,
                            "name": "Lansing",
                            "latitude": "42.73253000",
                            "longitude": "-84.55553000"
                        },
                        {
                            "id": 120131,
                            "name": "Lapeer",
                            "latitude": "43.05142000",
                            "longitude": "-83.31883000"
                        },
                        {
                            "id": 120132,
                            "name": "Lapeer County",
                            "latitude": "43.09015000",
                            "longitude": "-83.22178000"
                        },
                        {
                            "id": 120171,
                            "name": "Lathrup Village",
                            "latitude": "42.49642000",
                            "longitude": "-83.22271000"
                        },
                        {
                            "id": 120211,
                            "name": "Laurium",
                            "latitude": "47.23743000",
                            "longitude": "-88.44317000"
                        },
                        {
                            "id": 120251,
                            "name": "Lawton",
                            "latitude": "42.16726000",
                            "longitude": "-85.84695000"
                        },
                        {
                            "id": 120322,
                            "name": "Leelanau County",
                            "latitude": "45.15177000",
                            "longitude": "-86.03850000"
                        },
                        {
                            "id": 120351,
                            "name": "Leland",
                            "latitude": "45.02305000",
                            "longitude": "-85.75981000"
                        },
                        {
                            "id": 120368,
                            "name": "Lenawee County",
                            "latitude": "41.89508000",
                            "longitude": "-84.06636000"
                        },
                        {
                            "id": 120396,
                            "name": "Leslie",
                            "latitude": "42.45143000",
                            "longitude": "-84.43247000"
                        },
                        {
                            "id": 120403,
                            "name": "Level Park-Oak Park",
                            "latitude": "42.36418000",
                            "longitude": "-85.26650000"
                        },
                        {
                            "id": 120424,
                            "name": "Lewiston",
                            "latitude": "44.88390000",
                            "longitude": "-84.30557000"
                        },
                        {
                            "id": 120451,
                            "name": "Lexington",
                            "latitude": "43.26808000",
                            "longitude": "-82.53076000"
                        },
                        {
                            "id": 120541,
                            "name": "Lincoln Park",
                            "latitude": "42.25059000",
                            "longitude": "-83.17854000"
                        },
                        {
                            "id": 120563,
                            "name": "Linden",
                            "latitude": "42.81447000",
                            "longitude": "-83.78245000"
                        },
                        {
                            "id": 120606,
                            "name": "Litchfield",
                            "latitude": "42.04393000",
                            "longitude": "-84.75746000"
                        },
                        {
                            "id": 120657,
                            "name": "Livingston County",
                            "latitude": "42.60292000",
                            "longitude": "-83.91153000"
                        },
                        {
                            "id": 120662,
                            "name": "Livonia",
                            "latitude": "42.36837000",
                            "longitude": "-83.35271000"
                        },
                        {
                            "id": 120837,
                            "name": "Lowell",
                            "latitude": "42.93364000",
                            "longitude": "-85.34196000"
                        },
                        {
                            "id": 120863,
                            "name": "Luce County",
                            "latitude": "46.36778000",
                            "longitude": "-85.50934000"
                        },
                        {
                            "id": 120868,
                            "name": "Ludington",
                            "latitude": "43.95528000",
                            "longitude": "-86.45258000"
                        },
                        {
                            "id": 120885,
                            "name": "Luna Pier",
                            "latitude": "41.80699000",
                            "longitude": "-83.44243000"
                        },
                        {
                            "id": 120959,
                            "name": "Mackinac County",
                            "latitude": "45.87184000",
                            "longitude": "-84.76227000"
                        },
                        {
                            "id": 120962,
                            "name": "Macomb County",
                            "latitude": "42.67279000",
                            "longitude": "-82.91016000"
                        },
                        {
                            "id": 121021,
                            "name": "Madison Heights",
                            "latitude": "42.48587000",
                            "longitude": "-83.10520000"
                        },
                        {
                            "id": 121087,
                            "name": "Mancelona",
                            "latitude": "44.90223000",
                            "longitude": "-85.06088000"
                        },
                        {
                            "id": 121096,
                            "name": "Manchester",
                            "latitude": "42.15032000",
                            "longitude": "-84.03772000"
                        },
                        {
                            "id": 121121,
                            "name": "Manistee",
                            "latitude": "44.24445000",
                            "longitude": "-86.32425000"
                        },
                        {
                            "id": 121122,
                            "name": "Manistee County",
                            "latitude": "44.23831000",
                            "longitude": "-86.28799000"
                        },
                        {
                            "id": 121123,
                            "name": "Manistique",
                            "latitude": "45.95775000",
                            "longitude": "-86.24625000"
                        },
                        {
                            "id": 121125,
                            "name": "Manitou Beach-Devils Lake",
                            "latitude": "41.97565000",
                            "longitude": "-84.28616000"
                        },
                        {
                            "id": 121158,
                            "name": "Manton",
                            "latitude": "44.41084000",
                            "longitude": "-85.39894000"
                        },
                        {
                            "id": 121194,
                            "name": "Marcellus",
                            "latitude": "42.02588000",
                            "longitude": "-85.81556000"
                        },
                        {
                            "id": 121225,
                            "name": "Marine City",
                            "latitude": "42.71948000",
                            "longitude": "-82.49213000"
                        },
                        {
                            "id": 121282,
                            "name": "Marlette",
                            "latitude": "43.32697000",
                            "longitude": "-83.08022000"
                        },
                        {
                            "id": 121292,
                            "name": "Marquette",
                            "latitude": "46.54354000",
                            "longitude": "-87.39542000"
                        },
                        {
                            "id": 121293,
                            "name": "Marquette County",
                            "latitude": "46.66295000",
                            "longitude": "-87.57350000"
                        },
                        {
                            "id": 121307,
                            "name": "Marshall",
                            "latitude": "42.27226000",
                            "longitude": "-84.96331000"
                        },
                        {
                            "id": 121358,
                            "name": "Marysville",
                            "latitude": "42.91253000",
                            "longitude": "-82.48686000"
                        },
                        {
                            "id": 121373,
                            "name": "Mason",
                            "latitude": "42.57920000",
                            "longitude": "-84.44358000"
                        },
                        {
                            "id": 121381,
                            "name": "Mason County",
                            "latitude": "43.95625000",
                            "longitude": "-86.42258000"
                        },
                        {
                            "id": 121410,
                            "name": "Mattawan",
                            "latitude": "42.20948000",
                            "longitude": "-85.78445000"
                        },
                        {
                            "id": 121589,
                            "name": "Mecosta County",
                            "latitude": "43.64080000",
                            "longitude": "-85.32462000"
                        },
                        {
                            "id": 121637,
                            "name": "Melvindale",
                            "latitude": "42.28254000",
                            "longitude": "-83.17520000"
                        },
                        {
                            "id": 121640,
                            "name": "Memphis",
                            "latitude": "42.89642000",
                            "longitude": "-82.76881000"
                        },
                        {
                            "id": 121662,
                            "name": "Menominee",
                            "latitude": "45.10776000",
                            "longitude": "-87.61427000"
                        },
                        {
                            "id": 121663,
                            "name": "Menominee County",
                            "latitude": "45.52514000",
                            "longitude": "-87.50969000"
                        },
                        {
                            "id": 121759,
                            "name": "Michigan Center",
                            "latitude": "42.23309000",
                            "longitude": "-84.32718000"
                        },
                        {
                            "id": 121795,
                            "name": "Middleville",
                            "latitude": "42.71309000",
                            "longitude": "-85.46196000"
                        },
                        {
                            "id": 121798,
                            "name": "Midland",
                            "latitude": "43.61558000",
                            "longitude": "-84.24721000"
                        },
                        {
                            "id": 121804,
                            "name": "Midland County",
                            "latitude": "43.64686000",
                            "longitude": "-84.38811000"
                        },
                        {
                            "id": 121838,
                            "name": "Milan",
                            "latitude": "42.08532000",
                            "longitude": "-83.68244000"
                        },
                        {
                            "id": 121852,
                            "name": "Milford",
                            "latitude": "42.59364000",
                            "longitude": "-83.59939000"
                        },
                        {
                            "id": 121892,
                            "name": "Millington",
                            "latitude": "43.28141000",
                            "longitude": "-83.52968000"
                        },
                        {
                            "id": 121970,
                            "name": "Mio",
                            "latitude": "44.65224000",
                            "longitude": "-84.12973000"
                        },
                        {
                            "id": 121978,
                            "name": "Missaukee County",
                            "latitude": "44.33730000",
                            "longitude": "-85.09467000"
                        },
                        {
                            "id": 122061,
                            "name": "Monroe",
                            "latitude": "41.91643000",
                            "longitude": "-83.39771000"
                        },
                        {
                            "id": 122079,
                            "name": "Monroe County",
                            "latitude": "41.92140000",
                            "longitude": "-83.49426000"
                        },
                        {
                            "id": 122097,
                            "name": "Montague",
                            "latitude": "43.41668000",
                            "longitude": "-86.35701000"
                        },
                        {
                            "id": 122104,
                            "name": "Montcalm County",
                            "latitude": "43.31096000",
                            "longitude": "-85.15252000"
                        },
                        {
                            "id": 122172,
                            "name": "Montmorency County",
                            "latitude": "45.02755000",
                            "longitude": "-84.12721000"
                        },
                        {
                            "id": 122181,
                            "name": "Montrose",
                            "latitude": "43.17669000",
                            "longitude": "-83.89274000"
                        },
                        {
                            "id": 122229,
                            "name": "Morenci",
                            "latitude": "41.71949000",
                            "longitude": "-84.21800000"
                        },
                        {
                            "id": 122339,
                            "name": "Mount Clemens",
                            "latitude": "42.59726000",
                            "longitude": "-82.87798000"
                        },
                        {
                            "id": 122364,
                            "name": "Mount Morris",
                            "latitude": "43.11864000",
                            "longitude": "-83.69496000"
                        },
                        {
                            "id": 122379,
                            "name": "Mount Pleasant",
                            "latitude": "43.59781000",
                            "longitude": "-84.76751000"
                        },
                        {
                            "id": 122474,
                            "name": "Munising",
                            "latitude": "46.41120000",
                            "longitude": "-86.64926000"
                        },
                        {
                            "id": 122509,
                            "name": "Muskegon",
                            "latitude": "43.23418000",
                            "longitude": "-86.24839000"
                        },
                        {
                            "id": 122510,
                            "name": "Muskegon County",
                            "latitude": "43.21919000",
                            "longitude": "-86.21246000"
                        },
                        {
                            "id": 122511,
                            "name": "Muskegon Heights",
                            "latitude": "43.20113000",
                            "longitude": "-86.23895000"
                        },
                        {
                            "id": 122558,
                            "name": "Napoleon",
                            "latitude": "42.16059000",
                            "longitude": "-84.24606000"
                        },
                        {
                            "id": 122578,
                            "name": "Nashville",
                            "latitude": "42.60281000",
                            "longitude": "-85.09305000"
                        },
                        {
                            "id": 122617,
                            "name": "Negaunee",
                            "latitude": "46.49910000",
                            "longitude": "-87.61180000"
                        },
                        {
                            "id": 122660,
                            "name": "New Baltimore",
                            "latitude": "42.68114000",
                            "longitude": "-82.73686000"
                        },
                        {
                            "id": 122678,
                            "name": "New Buffalo",
                            "latitude": "41.79393000",
                            "longitude": "-86.74392000"
                        },
                        {
                            "id": 122718,
                            "name": "New Haven",
                            "latitude": "42.72948000",
                            "longitude": "-82.80131000"
                        },
                        {
                            "id": 122806,
                            "name": "Newaygo",
                            "latitude": "43.41974000",
                            "longitude": "-85.80005000"
                        },
                        {
                            "id": 122807,
                            "name": "Newaygo County",
                            "latitude": "43.55417000",
                            "longitude": "-85.80091000"
                        },
                        {
                            "id": 122812,
                            "name": "Newberry",
                            "latitude": "46.35500000",
                            "longitude": "-85.50956000"
                        },
                        {
                            "id": 122901,
                            "name": "Niles",
                            "latitude": "41.82977000",
                            "longitude": "-86.25418000"
                        },
                        {
                            "id": 122993,
                            "name": "North Branch",
                            "latitude": "43.22947000",
                            "longitude": "-83.19661000"
                        },
                        {
                            "id": 123069,
                            "name": "North Muskegon",
                            "latitude": "43.25613000",
                            "longitude": "-86.26756000"
                        },
                        {
                            "id": 123170,
                            "name": "Northview",
                            "latitude": "43.04558000",
                            "longitude": "-85.60059000"
                        },
                        {
                            "id": 123171,
                            "name": "Northville",
                            "latitude": "42.43115000",
                            "longitude": "-83.48327000"
                        },
                        {
                            "id": 123187,
                            "name": "Norton Shores",
                            "latitude": "43.16890000",
                            "longitude": "-86.26395000"
                        },
                        {
                            "id": 123192,
                            "name": "Norway",
                            "latitude": "45.78690000",
                            "longitude": "-87.90374000"
                        },
                        {
                            "id": 123208,
                            "name": "Novi",
                            "latitude": "42.48059000",
                            "longitude": "-83.47549000"
                        },
                        {
                            "id": 123256,
                            "name": "Oak Park",
                            "latitude": "42.45948000",
                            "longitude": "-83.18271000"
                        },
                        {
                            "id": 123295,
                            "name": "Oakland County",
                            "latitude": "42.66041000",
                            "longitude": "-83.38580000"
                        },
                        {
                            "id": 123340,
                            "name": "Oceana County",
                            "latitude": "43.68178000",
                            "longitude": "-86.31683000"
                        },
                        {
                            "id": 123373,
                            "name": "Ogemaw County",
                            "latitude": "44.33494000",
                            "longitude": "-84.12641000"
                        },
                        {
                            "id": 123402,
                            "name": "Okemos",
                            "latitude": "42.72226000",
                            "longitude": "-84.42747000"
                        },
                        {
                            "id": 123447,
                            "name": "Olivet",
                            "latitude": "42.44143000",
                            "longitude": "-84.92415000"
                        },
                        {
                            "id": 123489,
                            "name": "Ontonagon",
                            "latitude": "46.87105000",
                            "longitude": "-89.31403000"
                        },
                        {
                            "id": 123490,
                            "name": "Ontonagon County",
                            "latitude": "46.77749000",
                            "longitude": "-89.30511000"
                        },
                        {
                            "id": 123536,
                            "name": "Orchard Lake",
                            "latitude": "42.58309000",
                            "longitude": "-83.35938000"
                        },
                        {
                            "id": 123583,
                            "name": "Ortonville",
                            "latitude": "42.85225000",
                            "longitude": "-83.44300000"
                        },
                        {
                            "id": 123605,
                            "name": "Osceola County",
                            "latitude": "43.98987000",
                            "longitude": "-85.32528000"
                        },
                        {
                            "id": 123607,
                            "name": "Oscoda County",
                            "latitude": "44.68175000",
                            "longitude": "-84.12974000"
                        },
                        {
                            "id": 123632,
                            "name": "Otsego",
                            "latitude": "42.46059000",
                            "longitude": "-85.69641000"
                        },
                        {
                            "id": 123634,
                            "name": "Otsego County",
                            "latitude": "45.02144000",
                            "longitude": "-84.59898000"
                        },
                        {
                            "id": 123640,
                            "name": "Ottawa County",
                            "latitude": "43.00264000",
                            "longitude": "-86.17950000"
                        },
                        {
                            "id": 123655,
                            "name": "Ovid",
                            "latitude": "43.00586000",
                            "longitude": "-84.37164000"
                        },
                        {
                            "id": 123672,
                            "name": "Owosso",
                            "latitude": "42.99780000",
                            "longitude": "-84.17664000"
                        },
                        {
                            "id": 123685,
                            "name": "Oxford",
                            "latitude": "42.82475000",
                            "longitude": "-83.26466000"
                        },
                        {
                            "id": 123827,
                            "name": "Parchment",
                            "latitude": "42.32810000",
                            "longitude": "-85.56973000"
                        },
                        {
                            "id": 123927,
                            "name": "Paw Paw",
                            "latitude": "42.21782000",
                            "longitude": "-85.89112000"
                        },
                        {
                            "id": 123928,
                            "name": "Paw Paw Lake",
                            "latitude": "42.21226000",
                            "longitude": "-86.27197000"
                        },
                        {
                            "id": 123964,
                            "name": "Pearl Beach",
                            "latitude": "42.62670000",
                            "longitude": "-82.59769000"
                        },
                        {
                            "id": 124071,
                            "name": "Perry",
                            "latitude": "42.82642000",
                            "longitude": "-84.21941000"
                        },
                        {
                            "id": 124107,
                            "name": "Petersburg",
                            "latitude": "41.90116000",
                            "longitude": "-83.71494000"
                        },
                        {
                            "id": 124111,
                            "name": "Petoskey",
                            "latitude": "45.37334000",
                            "longitude": "-84.95533000"
                        },
                        {
                            "id": 124182,
                            "name": "Pigeon",
                            "latitude": "43.83002000",
                            "longitude": "-83.26996000"
                        },
                        {
                            "id": 124209,
                            "name": "Pinckney",
                            "latitude": "42.45700000",
                            "longitude": "-83.94791000"
                        },
                        {
                            "id": 124211,
                            "name": "Pinconning",
                            "latitude": "43.85363000",
                            "longitude": "-83.96499000"
                        },
                        {
                            "id": 124336,
                            "name": "Plainwell",
                            "latitude": "42.44004000",
                            "longitude": "-85.64890000"
                        },
                        {
                            "id": 124376,
                            "name": "Pleasant Ridge",
                            "latitude": "42.47115000",
                            "longitude": "-83.14215000"
                        },
                        {
                            "id": 124403,
                            "name": "Plymouth",
                            "latitude": "42.37143000",
                            "longitude": "-83.47021000"
                        },
                        {
                            "id": 124474,
                            "name": "Pontiac",
                            "latitude": "42.63892000",
                            "longitude": "-83.29105000"
                        },
                        {
                            "id": 124511,
                            "name": "Port Huron",
                            "latitude": "42.97086000",
                            "longitude": "-82.42491000"
                        },
                        {
                            "id": 124544,
                            "name": "Portage",
                            "latitude": "42.20115000",
                            "longitude": "-85.58000000"
                        },
                        {
                            "id": 124561,
                            "name": "Portland",
                            "latitude": "42.86920000",
                            "longitude": "-84.90305000"
                        },
                        {
                            "id": 124596,
                            "name": "Potterville",
                            "latitude": "42.62920000",
                            "longitude": "-84.73887000"
                        },
                        {
                            "id": 124650,
                            "name": "Presque Isle County",
                            "latitude": "45.39845000",
                            "longitude": "-83.84354000"
                        },
                        {
                            "id": 124723,
                            "name": "Prudenville",
                            "latitude": "44.29835000",
                            "longitude": "-84.65197000"
                        },
                        {
                            "id": 124798,
                            "name": "Quincy",
                            "latitude": "41.94421000",
                            "longitude": "-84.88385000"
                        },
                        {
                            "id": 124803,
                            "name": "Quinnesec",
                            "latitude": "45.80635000",
                            "longitude": "-87.98846000"
                        },
                        {
                            "id": 124893,
                            "name": "Rapid City",
                            "latitude": "44.83445000",
                            "longitude": "-85.28256000"
                        },
                        {
                            "id": 124907,
                            "name": "Ravenna",
                            "latitude": "43.18947000",
                            "longitude": "-85.93699000"
                        },
                        {
                            "id": 124930,
                            "name": "Reading",
                            "latitude": "41.83949000",
                            "longitude": "-84.74801000"
                        },
                        {
                            "id": 124969,
                            "name": "Redford",
                            "latitude": "42.38337000",
                            "longitude": "-83.29660000"
                        },
                        {
                            "id": 124991,
                            "name": "Reed City",
                            "latitude": "43.87502000",
                            "longitude": "-85.51005000"
                        },
                        {
                            "id": 124997,
                            "name": "Reese",
                            "latitude": "43.45058000",
                            "longitude": "-83.69635000"
                        },
                        {
                            "id": 125092,
                            "name": "Richmond",
                            "latitude": "42.80920000",
                            "longitude": "-82.75576000"
                        },
                        {
                            "id": 125187,
                            "name": "River Rouge",
                            "latitude": "42.27337000",
                            "longitude": "-83.13437000"
                        },
                        {
                            "id": 125219,
                            "name": "Riverview",
                            "latitude": "42.17421000",
                            "longitude": "-83.17937000"
                        },
                        {
                            "id": 125264,
                            "name": "Rochester",
                            "latitude": "42.68059000",
                            "longitude": "-83.13382000"
                        },
                        {
                            "id": 125271,
                            "name": "Rochester Hills",
                            "latitude": "42.65837000",
                            "longitude": "-83.14993000"
                        },
                        {
                            "id": 125301,
                            "name": "Rockford",
                            "latitude": "43.12003000",
                            "longitude": "-85.56003000"
                        },
                        {
                            "id": 125333,
                            "name": "Rockwood",
                            "latitude": "42.07088000",
                            "longitude": "-83.24660000"
                        },
                        {
                            "id": 125351,
                            "name": "Rogers City",
                            "latitude": "45.42140000",
                            "longitude": "-83.81833000"
                        },
                        {
                            "id": 125381,
                            "name": "Romeo",
                            "latitude": "42.80281000",
                            "longitude": "-83.01299000"
                        },
                        {
                            "id": 125385,
                            "name": "Romulus",
                            "latitude": "42.22226000",
                            "longitude": "-83.39660000"
                        },
                        {
                            "id": 125396,
                            "name": "Roosevelt Park",
                            "latitude": "43.19640000",
                            "longitude": "-86.27228000"
                        },
                        {
                            "id": 125401,
                            "name": "Roscommon",
                            "latitude": "44.49835000",
                            "longitude": "-84.59197000"
                        },
                        {
                            "id": 125402,
                            "name": "Roscommon County",
                            "latitude": "44.33561000",
                            "longitude": "-84.61160000"
                        },
                        {
                            "id": 125439,
                            "name": "Roseville",
                            "latitude": "42.49726000",
                            "longitude": "-82.93714000"
                        },
                        {
                            "id": 125490,
                            "name": "Royal Oak",
                            "latitude": "42.48948000",
                            "longitude": "-83.14465000"
                        },
                        {
                            "id": 125588,
                            "name": "Saginaw",
                            "latitude": "43.41947000",
                            "longitude": "-83.95081000"
                        },
                        {
                            "id": 125589,
                            "name": "Saginaw County",
                            "latitude": "43.33503000",
                            "longitude": "-84.05319000"
                        },
                        {
                            "id": 125590,
                            "name": "Saginaw Township North",
                            "latitude": "43.46004000",
                            "longitude": "-84.00674000"
                        },
                        {
                            "id": 125614,
                            "name": "Saint Charles",
                            "latitude": "43.29697000",
                            "longitude": "-84.14053000"
                        },
                        {
                            "id": 125619,
                            "name": "Saint Clair",
                            "latitude": "42.82087000",
                            "longitude": "-82.48602000"
                        },
                        {
                            "id": 125624,
                            "name": "Saint Clair County",
                            "latitude": "42.93112000",
                            "longitude": "-82.66437000"
                        },
                        {
                            "id": 125625,
                            "name": "Saint Clair Shores",
                            "latitude": "42.49698000",
                            "longitude": "-82.88881000"
                        },
                        {
                            "id": 125646,
                            "name": "Saint Helen",
                            "latitude": "44.36363000",
                            "longitude": "-84.41029000"
                        },
                        {
                            "id": 125650,
                            "name": "Saint Ignace",
                            "latitude": "45.86614000",
                            "longitude": "-84.72751000"
                        },
                        {
                            "id": 125665,
                            "name": "Saint Johns",
                            "latitude": "43.00114000",
                            "longitude": "-84.55915000"
                        },
                        {
                            "id": 125673,
                            "name": "Saint Joseph",
                            "latitude": "42.10976000",
                            "longitude": "-86.48002000"
                        },
                        {
                            "id": 125676,
                            "name": "Saint Joseph County",
                            "latitude": "41.91441000",
                            "longitude": "-85.52774000"
                        },
                        {
                            "id": 125680,
                            "name": "Saint Louis",
                            "latitude": "43.40836000",
                            "longitude": "-84.60667000"
                        },
                        {
                            "id": 125748,
                            "name": "Saline",
                            "latitude": "42.16671000",
                            "longitude": "-83.78161000"
                        },
                        {
                            "id": 125859,
                            "name": "Sand Lake",
                            "latitude": "44.31918000",
                            "longitude": "-83.68470000"
                        },
                        {
                            "id": 125875,
                            "name": "Sandusky",
                            "latitude": "43.42030000",
                            "longitude": "-82.82966000"
                        },
                        {
                            "id": 125895,
                            "name": "Sanilac County",
                            "latitude": "43.44331000",
                            "longitude": "-82.64575000"
                        },
                        {
                            "id": 125935,
                            "name": "Saranac",
                            "latitude": "42.92948000",
                            "longitude": "-85.21307000"
                        },
                        {
                            "id": 125968,
                            "name": "Sault Ste. Marie",
                            "latitude": "46.49530000",
                            "longitude": "-84.34532000"
                        },
                        {
                            "id": 126017,
                            "name": "Schoolcraft",
                            "latitude": "42.11421000",
                            "longitude": "-85.63778000"
                        },
                        {
                            "id": 126018,
                            "name": "Schoolcraft County",
                            "latitude": "46.04249000",
                            "longitude": "-86.17730000"
                        },
                        {
                            "id": 126066,
                            "name": "Scottville",
                            "latitude": "43.95473000",
                            "longitude": "-86.28008000"
                        },
                        {
                            "id": 126109,
                            "name": "Sebewaing",
                            "latitude": "43.73224000",
                            "longitude": "-83.45107000"
                        },
                        {
                            "id": 126270,
                            "name": "Shelby",
                            "latitude": "43.60862000",
                            "longitude": "-86.36396000"
                        },
                        {
                            "id": 126310,
                            "name": "Shepherd",
                            "latitude": "43.52447000",
                            "longitude": "-84.69473000"
                        },
                        {
                            "id": 126345,
                            "name": "Shiawassee County",
                            "latitude": "42.95373000",
                            "longitude": "-84.14673000"
                        },
                        {
                            "id": 126346,
                            "name": "Shields",
                            "latitude": "43.41530000",
                            "longitude": "-84.05637000"
                        },
                        {
                            "id": 126375,
                            "name": "Shorewood-Tower Hills-Harbert",
                            "latitude": "41.88169000",
                            "longitude": "-86.61409000"
                        },
                        {
                            "id": 126480,
                            "name": "Skidway Lake",
                            "latitude": "44.18335000",
                            "longitude": "-84.03527000"
                        },
                        {
                            "id": 126664,
                            "name": "South Gull Lake",
                            "latitude": "42.38754000",
                            "longitude": "-85.39667000"
                        },
                        {
                            "id": 126667,
                            "name": "South Haven",
                            "latitude": "42.40309000",
                            "longitude": "-86.27364000"
                        },
                        {
                            "id": 126690,
                            "name": "South Lyon",
                            "latitude": "42.46059000",
                            "longitude": "-83.65161000"
                        },
                        {
                            "id": 126694,
                            "name": "South Monroe",
                            "latitude": "41.89588000",
                            "longitude": "-83.41771000"
                        },
                        {
                            "id": 126718,
                            "name": "South Rockwood",
                            "latitude": "42.06393000",
                            "longitude": "-83.26104000"
                        },
                        {
                            "id": 126772,
                            "name": "Southfield",
                            "latitude": "42.47337000",
                            "longitude": "-83.22187000"
                        },
                        {
                            "id": 126775,
                            "name": "Southgate",
                            "latitude": "42.21393000",
                            "longitude": "-83.19381000"
                        },
                        {
                            "id": 126811,
                            "name": "Sparta",
                            "latitude": "43.16086000",
                            "longitude": "-85.71004000"
                        },
                        {
                            "id": 126850,
                            "name": "Spring Arbor",
                            "latitude": "42.20504000",
                            "longitude": "-84.55274000"
                        },
                        {
                            "id": 126866,
                            "name": "Spring Lake",
                            "latitude": "43.07696000",
                            "longitude": "-86.19700000"
                        },
                        {
                            "id": 126898,
                            "name": "Springfield",
                            "latitude": "42.32643000",
                            "longitude": "-85.23916000"
                        },
                        {
                            "id": 126942,
                            "name": "Stambaugh, Iron River",
                            "latitude": "46.08107000",
                            "longitude": "-88.62708000"
                        },
                        {
                            "id": 126950,
                            "name": "Standish",
                            "latitude": "43.98308000",
                            "longitude": "-83.95888000"
                        },
                        {
                            "id": 126967,
                            "name": "Stanton",
                            "latitude": "43.29253000",
                            "longitude": "-85.08141000"
                        },
                        {
                            "id": 127034,
                            "name": "Sterling Heights",
                            "latitude": "42.58031000",
                            "longitude": "-83.03020000"
                        },
                        {
                            "id": 127048,
                            "name": "Stevensville",
                            "latitude": "42.01449000",
                            "longitude": "-86.51947000"
                        },
                        {
                            "id": 127069,
                            "name": "Stockbridge",
                            "latitude": "42.45115000",
                            "longitude": "-84.18051000"
                        },
                        {
                            "id": 127099,
                            "name": "Stony Point",
                            "latitude": "41.94143000",
                            "longitude": "-83.26493000"
                        },
                        {
                            "id": 127151,
                            "name": "Sturgis",
                            "latitude": "41.79922000",
                            "longitude": "-85.41915000"
                        },
                        {
                            "id": 127332,
                            "name": "Swartz Creek",
                            "latitude": "42.95725000",
                            "longitude": "-83.83051000"
                        },
                        {
                            "id": 127357,
                            "name": "Sylvan Lake",
                            "latitude": "42.61142000",
                            "longitude": "-83.32855000"
                        },
                        {
                            "id": 127444,
                            "name": "Tawas City",
                            "latitude": "44.26946000",
                            "longitude": "-83.51470000"
                        },
                        {
                            "id": 127447,
                            "name": "Taylor",
                            "latitude": "42.24087000",
                            "longitude": "-83.26965000"
                        },
                        {
                            "id": 127479,
                            "name": "Tecumseh",
                            "latitude": "42.00393000",
                            "longitude": "-83.94494000"
                        },
                        {
                            "id": 127496,
                            "name": "Temperance",
                            "latitude": "41.77921000",
                            "longitude": "-83.56882000"
                        },
                        {
                            "id": 127615,
                            "name": "Three Oaks",
                            "latitude": "41.79865000",
                            "longitude": "-86.61058000"
                        },
                        {
                            "id": 127618,
                            "name": "Three Rivers",
                            "latitude": "41.94394000",
                            "longitude": "-85.63249000"
                        },
                        {
                            "id": 127784,
                            "name": "Traverse City",
                            "latitude": "44.76306000",
                            "longitude": "-85.62063000"
                        },
                        {
                            "id": 127806,
                            "name": "Trenton",
                            "latitude": "42.13949000",
                            "longitude": "-83.17826000"
                        },
                        {
                            "id": 127840,
                            "name": "Trowbridge Park",
                            "latitude": "46.55660000",
                            "longitude": "-87.43736000"
                        },
                        {
                            "id": 127848,
                            "name": "Troy",
                            "latitude": "42.60559000",
                            "longitude": "-83.14993000"
                        },
                        {
                            "id": 127912,
                            "name": "Tuscola County",
                            "latitude": "43.49134000",
                            "longitude": "-83.43987000"
                        },
                        {
                            "id": 127927,
                            "name": "Twin Lake",
                            "latitude": "43.36279000",
                            "longitude": "-86.16478000"
                        },
                        {
                            "id": 127984,
                            "name": "Union City",
                            "latitude": "42.06671000",
                            "longitude": "-85.13609000"
                        },
                        {
                            "id": 128066,
                            "name": "Utica",
                            "latitude": "42.62614000",
                            "longitude": "-83.03354000"
                        },
                        {
                            "id": 128132,
                            "name": "Van Buren County",
                            "latitude": "42.28511000",
                            "longitude": "-86.30642000"
                        },
                        {
                            "id": 128151,
                            "name": "Vandercook Lake",
                            "latitude": "42.19337000",
                            "longitude": "-84.39107000"
                        },
                        {
                            "id": 128159,
                            "name": "Vassar",
                            "latitude": "43.37197000",
                            "longitude": "-83.58329000"
                        },
                        {
                            "id": 128216,
                            "name": "Vicksburg",
                            "latitude": "42.12005000",
                            "longitude": "-85.53278000"
                        },
                        {
                            "id": 128318,
                            "name": "Wacousta",
                            "latitude": "42.82781000",
                            "longitude": "-84.70082000"
                        },
                        {
                            "id": 128360,
                            "name": "Wakefield",
                            "latitude": "46.47523000",
                            "longitude": "-89.93989000"
                        },
                        {
                            "id": 128384,
                            "name": "Walker",
                            "latitude": "43.00141000",
                            "longitude": "-85.76809000"
                        },
                        {
                            "id": 128401,
                            "name": "Walled Lake",
                            "latitude": "42.53781000",
                            "longitude": "-83.48105000"
                        },
                        {
                            "id": 128481,
                            "name": "Warren",
                            "latitude": "42.49044000",
                            "longitude": "-83.01304000"
                        },
                        {
                            "id": 128592,
                            "name": "Washtenaw County",
                            "latitude": "42.25323000",
                            "longitude": "-83.83877000"
                        },
                        {
                            "id": 128605,
                            "name": "Waterford",
                            "latitude": "42.69303000",
                            "longitude": "-83.41181000"
                        },
                        {
                            "id": 128631,
                            "name": "Watervliet",
                            "latitude": "42.18671000",
                            "longitude": "-86.26058000"
                        },
                        {
                            "id": 128669,
                            "name": "Waverly",
                            "latitude": "42.73920000",
                            "longitude": "-84.62081000"
                        },
                        {
                            "id": 128679,
                            "name": "Wayland",
                            "latitude": "42.67392000",
                            "longitude": "-85.64474000"
                        },
                        {
                            "id": 128683,
                            "name": "Wayne",
                            "latitude": "42.28143000",
                            "longitude": "-83.38632000"
                        },
                        {
                            "id": 128699,
                            "name": "Wayne County",
                            "latitude": "42.28478000",
                            "longitude": "-83.26113000"
                        },
                        {
                            "id": 128727,
                            "name": "Webberville",
                            "latitude": "42.66698000",
                            "longitude": "-84.17413000"
                        },
                        {
                            "id": 128828,
                            "name": "West Bloomfield Township",
                            "latitude": "42.56891000",
                            "longitude": "-83.38356000"
                        },
                        {
                            "id": 128833,
                            "name": "West Branch",
                            "latitude": "44.27641000",
                            "longitude": "-84.23861000"
                        },
                        {
                            "id": 128902,
                            "name": "West Ishpeming",
                            "latitude": "46.48355000",
                            "longitude": "-87.70097000"
                        },
                        {
                            "id": 128936,
                            "name": "West Monroe",
                            "latitude": "41.91393000",
                            "longitude": "-83.43160000"
                        },
                        {
                            "id": 129041,
                            "name": "Westland",
                            "latitude": "42.32420000",
                            "longitude": "-83.40021000"
                        },
                        {
                            "id": 129084,
                            "name": "Westwood",
                            "latitude": "42.30282000",
                            "longitude": "-85.63362000"
                        },
                        {
                            "id": 129097,
                            "name": "Wexford County",
                            "latitude": "44.33835000",
                            "longitude": "-85.57842000"
                        },
                        {
                            "id": 129133,
                            "name": "White Cloud",
                            "latitude": "43.55030000",
                            "longitude": "-85.77200000"
                        },
                        {
                            "id": 129151,
                            "name": "White Pigeon",
                            "latitude": "41.79811000",
                            "longitude": "-85.64332000"
                        },
                        {
                            "id": 129168,
                            "name": "Whitehall",
                            "latitude": "43.41001000",
                            "longitude": "-86.34868000"
                        },
                        {
                            "id": 129206,
                            "name": "Whitmore Lake",
                            "latitude": "42.43970000",
                            "longitude": "-83.74530000"
                        },
                        {
                            "id": 129284,
                            "name": "Williamston",
                            "latitude": "42.68892000",
                            "longitude": "-84.28302000"
                        },
                        {
                            "id": 129465,
                            "name": "Wixom",
                            "latitude": "42.52476000",
                            "longitude": "-83.53633000"
                        },
                        {
                            "id": 129472,
                            "name": "Wolf Lake",
                            "latitude": "43.25474000",
                            "longitude": "-86.10978000"
                        },
                        {
                            "id": 129481,
                            "name": "Wolverine Lake",
                            "latitude": "42.55670000",
                            "longitude": "-83.47383000"
                        },
                        {
                            "id": 129521,
                            "name": "Woodhaven",
                            "latitude": "42.13893000",
                            "longitude": "-83.24160000"
                        },
                        {
                            "id": 129528,
                            "name": "Woodland Beach",
                            "latitude": "41.94005000",
                            "longitude": "-83.31326000"
                        },
                        {
                            "id": 129621,
                            "name": "Wyandotte",
                            "latitude": "42.21421000",
                            "longitude": "-83.14992000"
                        },
                        {
                            "id": 129636,
                            "name": "Wyoming",
                            "latitude": "42.91336000",
                            "longitude": "-85.70531000"
                        },
                        {
                            "id": 129655,
                            "name": "Yale",
                            "latitude": "43.13003000",
                            "longitude": "-82.79826000"
                        },
                        {
                            "id": 129724,
                            "name": "Ypsilanti",
                            "latitude": "42.24115000",
                            "longitude": "-83.61299000"
                        },
                        {
                            "id": 129744,
                            "name": "Zeeland",
                            "latitude": "42.81252000",
                            "longitude": "-86.01865000"
                        },
                        {
                            "id": 129755,
                            "name": "Zilwaukee",
                            "latitude": "43.47641000",
                            "longitude": "-83.92053000"
                        }
                    ]
                },
                {
                    "id": 1438,
                    "name": "Midway Atoll",
                    "state_code": "UM-71",
                    "cities": []
                },
                {
                    "id": 1420,
                    "name": "Minnesota",
                    "state_code": "MN",
                    "cities": [
                        {
                            "id": 111006,
                            "name": "Ada",
                            "latitude": "47.29969000",
                            "longitude": "-96.51535000"
                        },
                        {
                            "id": 111047,
                            "name": "Adrian",
                            "latitude": "43.63497000",
                            "longitude": "-95.93280000"
                        },
                        {
                            "id": 111053,
                            "name": "Afton",
                            "latitude": "44.90275000",
                            "longitude": "-92.78354000"
                        },
                        {
                            "id": 111073,
                            "name": "Aitkin",
                            "latitude": "46.53301000",
                            "longitude": "-93.71025000"
                        },
                        {
                            "id": 111074,
                            "name": "Aitkin County",
                            "latitude": "46.60826000",
                            "longitude": "-93.41543000"
                        },
                        {
                            "id": 111106,
                            "name": "Albany",
                            "latitude": "45.62996000",
                            "longitude": "-94.57000000"
                        },
                        {
                            "id": 111118,
                            "name": "Albert Lea",
                            "latitude": "43.64801000",
                            "longitude": "-93.36827000"
                        },
                        {
                            "id": 111121,
                            "name": "Albertville",
                            "latitude": "45.23774000",
                            "longitude": "-93.65441000"
                        },
                        {
                            "id": 111154,
                            "name": "Alexandria",
                            "latitude": "45.88524000",
                            "longitude": "-95.37754000"
                        },
                        {
                            "id": 111324,
                            "name": "Andover",
                            "latitude": "45.23330000",
                            "longitude": "-93.29134000"
                        },
                        {
                            "id": 111350,
                            "name": "Annandale",
                            "latitude": "45.26274000",
                            "longitude": "-94.12443000"
                        },
                        {
                            "id": 111358,
                            "name": "Anoka",
                            "latitude": "45.19774000",
                            "longitude": "-93.38718000"
                        },
                        {
                            "id": 111359,
                            "name": "Anoka County",
                            "latitude": "45.27324000",
                            "longitude": "-93.24645000"
                        },
                        {
                            "id": 111393,
                            "name": "Apple Valley",
                            "latitude": "44.73191000",
                            "longitude": "-93.21772000"
                        },
                        {
                            "id": 111396,
                            "name": "Appleton",
                            "latitude": "45.19691000",
                            "longitude": "-96.01977000"
                        },
                        {
                            "id": 111438,
                            "name": "Arden Hills",
                            "latitude": "45.05024000",
                            "longitude": "-93.15661000"
                        },
                        {
                            "id": 111466,
                            "name": "Arlington",
                            "latitude": "44.60830000",
                            "longitude": "-94.08053000"
                        },
                        {
                            "id": 111486,
                            "name": "Arnold",
                            "latitude": "46.88022000",
                            "longitude": "-92.09047000"
                        },
                        {
                            "id": 111612,
                            "name": "Atwater",
                            "latitude": "45.13885000",
                            "longitude": "-94.77806000"
                        },
                        {
                            "id": 111660,
                            "name": "Aurora",
                            "latitude": "47.52993000",
                            "longitude": "-92.23712000"
                        },
                        {
                            "id": 111669,
                            "name": "Austin",
                            "latitude": "43.66663000",
                            "longitude": "-92.97464000"
                        },
                        {
                            "id": 111692,
                            "name": "Avon",
                            "latitude": "45.60913000",
                            "longitude": "-94.45167000"
                        },
                        {
                            "id": 111714,
                            "name": "Babbitt",
                            "latitude": "47.70853000",
                            "longitude": "-91.94460000"
                        },
                        {
                            "id": 111728,
                            "name": "Bagley",
                            "latitude": "47.52162000",
                            "longitude": "-95.39835000"
                        },
                        {
                            "id": 111830,
                            "name": "Barnesville",
                            "latitude": "46.65218000",
                            "longitude": "-96.41979000"
                        },
                        {
                            "id": 111911,
                            "name": "Baudette",
                            "latitude": "48.71247000",
                            "longitude": "-94.59993000"
                        },
                        {
                            "id": 111916,
                            "name": "Baxter",
                            "latitude": "46.34330000",
                            "longitude": "-94.28667000"
                        },
                        {
                            "id": 111951,
                            "name": "Bayport",
                            "latitude": "45.02136000",
                            "longitude": "-92.78104000"
                        },
                        {
                            "id": 112012,
                            "name": "Becker",
                            "latitude": "45.39330000",
                            "longitude": "-93.87692000"
                        },
                        {
                            "id": 112013,
                            "name": "Becker County",
                            "latitude": "46.93465000",
                            "longitude": "-95.67392000"
                        },
                        {
                            "id": 112090,
                            "name": "Belle Plaine",
                            "latitude": "44.62274000",
                            "longitude": "-93.76857000"
                        },
                        {
                            "id": 112150,
                            "name": "Beltrami County",
                            "latitude": "47.97378000",
                            "longitude": "-94.93765000"
                        },
                        {
                            "id": 112160,
                            "name": "Bemidji",
                            "latitude": "47.47356000",
                            "longitude": "-94.88028000"
                        },
                        {
                            "id": 112183,
                            "name": "Benson",
                            "latitude": "45.31496000",
                            "longitude": "-95.60003000"
                        },
                        {
                            "id": 112205,
                            "name": "Benton County",
                            "latitude": "45.69913000",
                            "longitude": "-93.99884000"
                        },
                        {
                            "id": 112323,
                            "name": "Big Lake",
                            "latitude": "45.33246000",
                            "longitude": "-93.74608000"
                        },
                        {
                            "id": 112336,
                            "name": "Big Stone County",
                            "latitude": "45.42610000",
                            "longitude": "-96.41092000"
                        },
                        {
                            "id": 112355,
                            "name": "Birchwood",
                            "latitude": "45.06108000",
                            "longitude": "-92.97605000"
                        },
                        {
                            "id": 112403,
                            "name": "Blaine",
                            "latitude": "45.16080000",
                            "longitude": "-93.23495000"
                        },
                        {
                            "id": 112442,
                            "name": "Blooming Prairie",
                            "latitude": "43.86663000",
                            "longitude": "-93.05103000"
                        },
                        {
                            "id": 112452,
                            "name": "Bloomington",
                            "latitude": "44.84080000",
                            "longitude": "-93.29828000"
                        },
                        {
                            "id": 112465,
                            "name": "Blue Earth",
                            "latitude": "43.63746000",
                            "longitude": "-94.10218000"
                        },
                        {
                            "id": 112466,
                            "name": "Blue Earth County",
                            "latitude": "44.03459000",
                            "longitude": "-94.06703000"
                        },
                        {
                            "id": 112679,
                            "name": "Braham",
                            "latitude": "45.72274000",
                            "longitude": "-93.17078000"
                        },
                        {
                            "id": 112681,
                            "name": "Brainerd",
                            "latitude": "46.35802000",
                            "longitude": "-94.20083000"
                        },
                        {
                            "id": 112684,
                            "name": "Branch",
                            "latitude": "45.48524000",
                            "longitude": "-92.96188000"
                        },
                        {
                            "id": 112712,
                            "name": "Breckenridge",
                            "latitude": "46.26357000",
                            "longitude": "-96.58813000"
                        },
                        {
                            "id": 112718,
                            "name": "Breezy Point",
                            "latitude": "46.59001000",
                            "longitude": "-94.21982000"
                        },
                        {
                            "id": 112874,
                            "name": "Brooklyn Center",
                            "latitude": "45.07608000",
                            "longitude": "-93.33273000"
                        },
                        {
                            "id": 112877,
                            "name": "Brooklyn Park",
                            "latitude": "45.09413000",
                            "longitude": "-93.35634000"
                        },
                        {
                            "id": 112911,
                            "name": "Brown County",
                            "latitude": "44.24217000",
                            "longitude": "-94.72748000"
                        },
                        {
                            "id": 113013,
                            "name": "Buffalo",
                            "latitude": "45.17191000",
                            "longitude": "-93.87469000"
                        },
                        {
                            "id": 113091,
                            "name": "Burnsville",
                            "latitude": "44.76774000",
                            "longitude": "-93.27772000"
                        },
                        {
                            "id": 113145,
                            "name": "Byron",
                            "latitude": "44.03274000",
                            "longitude": "-92.64546000"
                        },
                        {
                            "id": 113190,
                            "name": "Caledonia",
                            "latitude": "43.63469000",
                            "longitude": "-91.49681000"
                        },
                        {
                            "id": 113253,
                            "name": "Cambridge",
                            "latitude": "45.57274000",
                            "longitude": "-93.22439000"
                        },
                        {
                            "id": 113321,
                            "name": "Canby",
                            "latitude": "44.70885000",
                            "longitude": "-96.27643000"
                        },
                        {
                            "id": 113334,
                            "name": "Cannon Falls",
                            "latitude": "44.50691000",
                            "longitude": "-92.90548000"
                        },
                        {
                            "id": 113411,
                            "name": "Carlton",
                            "latitude": "46.66383000",
                            "longitude": "-92.42491000"
                        },
                        {
                            "id": 113413,
                            "name": "Carlton County",
                            "latitude": "46.59240000",
                            "longitude": "-92.67705000"
                        },
                        {
                            "id": 113501,
                            "name": "Carver",
                            "latitude": "44.76357000",
                            "longitude": "-93.62579000"
                        },
                        {
                            "id": 113502,
                            "name": "Carver County",
                            "latitude": "44.82076000",
                            "longitude": "-93.80258000"
                        },
                        {
                            "id": 113532,
                            "name": "Cass County",
                            "latitude": "46.94959000",
                            "longitude": "-94.32535000"
                        },
                        {
                            "id": 113640,
                            "name": "Center City",
                            "latitude": "45.39385000",
                            "longitude": "-92.81660000"
                        },
                        {
                            "id": 113660,
                            "name": "Centerville",
                            "latitude": "45.16302000",
                            "longitude": "-93.05578000"
                        },
                        {
                            "id": 113727,
                            "name": "Champlin",
                            "latitude": "45.18885000",
                            "longitude": "-93.39745000"
                        },
                        {
                            "id": 113732,
                            "name": "Chanhassen",
                            "latitude": "44.86219000",
                            "longitude": "-93.53079000"
                        },
                        {
                            "id": 113794,
                            "name": "Chaska",
                            "latitude": "44.78941000",
                            "longitude": "-93.60218000"
                        },
                        {
                            "id": 113795,
                            "name": "Chatfield",
                            "latitude": "43.84552000",
                            "longitude": "-92.18905000"
                        },
                        {
                            "id": 113974,
                            "name": "Chippewa County",
                            "latitude": "45.02234000",
                            "longitude": "-95.56669000"
                        },
                        {
                            "id": 113977,
                            "name": "Chisago City",
                            "latitude": "45.37358000",
                            "longitude": "-92.88994000"
                        },
                        {
                            "id": 113978,
                            "name": "Chisago County",
                            "latitude": "45.50247000",
                            "longitude": "-92.90834000"
                        },
                        {
                            "id": 113979,
                            "name": "Chisholm",
                            "latitude": "47.48910000",
                            "longitude": "-92.88380000"
                        },
                        {
                            "id": 114030,
                            "name": "Circle Pines",
                            "latitude": "45.14858000",
                            "longitude": "-93.15161000"
                        },
                        {
                            "id": 114100,
                            "name": "Clara City",
                            "latitude": "44.95496000",
                            "longitude": "-95.36640000"
                        },
                        {
                            "id": 114188,
                            "name": "Clay County",
                            "latitude": "46.89234000",
                            "longitude": "-96.49065000"
                        },
                        {
                            "id": 114225,
                            "name": "Clearwater",
                            "latitude": "45.41941000",
                            "longitude": "-94.04887000"
                        },
                        {
                            "id": 114226,
                            "name": "Clearwater County",
                            "latitude": "47.57766000",
                            "longitude": "-95.37903000"
                        },
                        {
                            "id": 114304,
                            "name": "Cloquet",
                            "latitude": "46.72161000",
                            "longitude": "-92.45936000"
                        },
                        {
                            "id": 114373,
                            "name": "Cohasset",
                            "latitude": "47.26356000",
                            "longitude": "-93.62022000"
                        },
                        {
                            "id": 114376,
                            "name": "Cokato",
                            "latitude": "45.07580000",
                            "longitude": "-94.18998000"
                        },
                        {
                            "id": 114386,
                            "name": "Cold Spring",
                            "latitude": "45.45580000",
                            "longitude": "-94.42888000"
                        },
                        {
                            "id": 114401,
                            "name": "Coleraine",
                            "latitude": "47.28883000",
                            "longitude": "-93.42771000"
                        },
                        {
                            "id": 114419,
                            "name": "Collegeville",
                            "latitude": "45.59441000",
                            "longitude": "-94.36305000"
                        },
                        {
                            "id": 114442,
                            "name": "Cologne",
                            "latitude": "44.77163000",
                            "longitude": "-93.78135000"
                        },
                        {
                            "id": 114486,
                            "name": "Columbia Heights",
                            "latitude": "45.04080000",
                            "longitude": "-93.26300000"
                        },
                        {
                            "id": 114500,
                            "name": "Columbus",
                            "latitude": "45.26522000",
                            "longitude": "-93.05015000"
                        },
                        {
                            "id": 114593,
                            "name": "Cook County",
                            "latitude": "47.81684000",
                            "longitude": "-90.54108000"
                        },
                        {
                            "id": 114600,
                            "name": "Coon Rapids",
                            "latitude": "45.11997000",
                            "longitude": "-93.28773000"
                        },
                        {
                            "id": 114632,
                            "name": "Corcoran",
                            "latitude": "45.09524000",
                            "longitude": "-93.54746000"
                        },
                        {
                            "id": 114691,
                            "name": "Cottage Grove",
                            "latitude": "44.82774000",
                            "longitude": "-92.94382000"
                        },
                        {
                            "id": 114700,
                            "name": "Cottonwood",
                            "latitude": "44.60885000",
                            "longitude": "-95.67419000"
                        },
                        {
                            "id": 114703,
                            "name": "Cottonwood County",
                            "latitude": "44.00711000",
                            "longitude": "-95.18115000"
                        },
                        {
                            "id": 114840,
                            "name": "Crookston",
                            "latitude": "47.77414000",
                            "longitude": "-96.60812000"
                        },
                        {
                            "id": 114843,
                            "name": "Crosby",
                            "latitude": "46.48218000",
                            "longitude": "-93.95776000"
                        },
                        {
                            "id": 114849,
                            "name": "Cross Lake",
                            "latitude": "46.65941000",
                            "longitude": "-94.11387000"
                        },
                        {
                            "id": 114861,
                            "name": "Crow Wing County",
                            "latitude": "46.48237000",
                            "longitude": "-94.07087000"
                        },
                        {
                            "id": 114874,
                            "name": "Crystal",
                            "latitude": "45.03274000",
                            "longitude": "-93.36023000"
                        },
                        {
                            "id": 114976,
                            "name": "Dakota County",
                            "latitude": "44.67189000",
                            "longitude": "-93.06544000"
                        },
                        {
                            "id": 115055,
                            "name": "Dassel",
                            "latitude": "45.08163000",
                            "longitude": "-94.30693000"
                        },
                        {
                            "id": 115082,
                            "name": "Dawson",
                            "latitude": "44.93274000",
                            "longitude": "-96.05448000"
                        },
                        {
                            "id": 115096,
                            "name": "Dayton",
                            "latitude": "45.24385000",
                            "longitude": "-93.51496000"
                        },
                        {
                            "id": 115171,
                            "name": "Deephaven",
                            "latitude": "44.92969000",
                            "longitude": "-93.52246000"
                        },
                        {
                            "id": 115198,
                            "name": "Delano",
                            "latitude": "45.04191000",
                            "longitude": "-93.78913000"
                        },
                        {
                            "id": 115216,
                            "name": "Dellwood",
                            "latitude": "45.08997000",
                            "longitude": "-92.97244000"
                        },
                        {
                            "id": 115293,
                            "name": "Detroit Lakes",
                            "latitude": "46.81718000",
                            "longitude": "-95.84533000"
                        },
                        {
                            "id": 115347,
                            "name": "Dilworth",
                            "latitude": "46.87663000",
                            "longitude": "-96.70341000"
                        },
                        {
                            "id": 115375,
                            "name": "Dodge Center",
                            "latitude": "44.02802000",
                            "longitude": "-92.85464000"
                        },
                        {
                            "id": 115378,
                            "name": "Dodge County",
                            "latitude": "44.02259000",
                            "longitude": "-92.86205000"
                        },
                        {
                            "id": 115429,
                            "name": "Douglas County",
                            "latitude": "45.93372000",
                            "longitude": "-95.45352000"
                        },
                        {
                            "id": 115513,
                            "name": "Duluth",
                            "latitude": "46.78327000",
                            "longitude": "-92.10658000"
                        },
                        {
                            "id": 115527,
                            "name": "Dundas",
                            "latitude": "44.42941000",
                            "longitude": "-93.20188000"
                        },
                        {
                            "id": 115593,
                            "name": "Eagan",
                            "latitude": "44.80413000",
                            "longitude": "-93.16689000"
                        },
                        {
                            "id": 115604,
                            "name": "Eagle Lake",
                            "latitude": "44.16497000",
                            "longitude": "-93.88134000"
                        },
                        {
                            "id": 115634,
                            "name": "East Bethel",
                            "latitude": "45.31941000",
                            "longitude": "-93.20245000"
                        },
                        {
                            "id": 115670,
                            "name": "East Grand Forks",
                            "latitude": "47.92998000",
                            "longitude": "-97.02452000"
                        },
                        {
                            "id": 115676,
                            "name": "East Gull Lake",
                            "latitude": "46.40802000",
                            "longitude": "-94.35584000"
                        },
                        {
                            "id": 115832,
                            "name": "Eden Prairie",
                            "latitude": "44.85469000",
                            "longitude": "-93.47079000"
                        },
                        {
                            "id": 115833,
                            "name": "Eden Valley",
                            "latitude": "45.32607000",
                            "longitude": "-94.54611000"
                        },
                        {
                            "id": 115849,
                            "name": "Edgerton",
                            "latitude": "43.87247000",
                            "longitude": "-96.12864000"
                        },
                        {
                            "id": 115867,
                            "name": "Edina",
                            "latitude": "44.88969000",
                            "longitude": "-93.34995000"
                        },
                        {
                            "id": 115946,
                            "name": "Elbow Lake",
                            "latitude": "45.99413000",
                            "longitude": "-95.97672000"
                        },
                        {
                            "id": 115967,
                            "name": "Elgin",
                            "latitude": "44.13024000",
                            "longitude": "-92.25156000"
                        },
                        {
                            "id": 115993,
                            "name": "Elk River",
                            "latitude": "45.30385000",
                            "longitude": "-93.56718000"
                        },
                        {
                            "id": 116010,
                            "name": "Elko New Market",
                            "latitude": "44.56472000",
                            "longitude": "-93.32694000"
                        },
                        {
                            "id": 116085,
                            "name": "Ely",
                            "latitude": "47.90324000",
                            "longitude": "-91.86709000"
                        },
                        {
                            "id": 116185,
                            "name": "Esko",
                            "latitude": "46.70578000",
                            "longitude": "-92.36325000"
                        },
                        {
                            "id": 116257,
                            "name": "Eveleth",
                            "latitude": "47.46243000",
                            "longitude": "-92.53991000"
                        },
                        {
                            "id": 116269,
                            "name": "Excelsior",
                            "latitude": "44.90330000",
                            "longitude": "-93.56635000"
                        },
                        {
                            "id": 116278,
                            "name": "Eyota",
                            "latitude": "43.98830000",
                            "longitude": "-92.22850000"
                        },
                        {
                            "id": 116305,
                            "name": "Fairfax",
                            "latitude": "44.52913000",
                            "longitude": "-94.72082000"
                        },
                        {
                            "id": 116337,
                            "name": "Fairmont",
                            "latitude": "43.65218000",
                            "longitude": "-94.46108000"
                        },
                        {
                            "id": 116365,
                            "name": "Falcon Heights",
                            "latitude": "44.99163000",
                            "longitude": "-93.16633000"
                        },
                        {
                            "id": 116397,
                            "name": "Faribault",
                            "latitude": "44.29496000",
                            "longitude": "-93.26883000"
                        },
                        {
                            "id": 116398,
                            "name": "Faribault County",
                            "latitude": "43.67393000",
                            "longitude": "-93.94800000"
                        },
                        {
                            "id": 116419,
                            "name": "Farmington",
                            "latitude": "44.64024000",
                            "longitude": "-93.14355000"
                        },
                        {
                            "id": 116479,
                            "name": "Fergus Falls",
                            "latitude": "46.28302000",
                            "longitude": "-96.07756000"
                        },
                        {
                            "id": 116510,
                            "name": "Fillmore County",
                            "latitude": "43.67400000",
                            "longitude": "-92.09017000"
                        },
                        {
                            "id": 116620,
                            "name": "Foley",
                            "latitude": "45.66469000",
                            "longitude": "-93.90970000"
                        },
                        {
                            "id": 116661,
                            "name": "Forest Lake",
                            "latitude": "45.27886000",
                            "longitude": "-92.98522000"
                        },
                        {
                            "id": 116773,
                            "name": "Fosston",
                            "latitude": "47.57635000",
                            "longitude": "-95.75141000"
                        },
                        {
                            "id": 116882,
                            "name": "Frazee",
                            "latitude": "46.72801000",
                            "longitude": "-95.70088000"
                        },
                        {
                            "id": 116899,
                            "name": "Freeborn County",
                            "latitude": "43.67384000",
                            "longitude": "-93.34882000"
                        },
                        {
                            "id": 116944,
                            "name": "Fridley",
                            "latitude": "45.08608000",
                            "longitude": "-93.26328000"
                        },
                        {
                            "id": 116979,
                            "name": "Fulda",
                            "latitude": "43.87052000",
                            "longitude": "-95.60029000"
                        },
                        {
                            "id": 117128,
                            "name": "Gaylord",
                            "latitude": "44.55302000",
                            "longitude": "-94.22053000"
                        },
                        {
                            "id": 117203,
                            "name": "Gilbert",
                            "latitude": "47.48882000",
                            "longitude": "-92.46491000"
                        },
                        {
                            "id": 117279,
                            "name": "Glencoe",
                            "latitude": "44.76913000",
                            "longitude": "-94.15164000"
                        },
                        {
                            "id": 117315,
                            "name": "Glenwood",
                            "latitude": "45.65024000",
                            "longitude": "-95.38976000"
                        },
                        {
                            "id": 117330,
                            "name": "Glyndon",
                            "latitude": "46.87524000",
                            "longitude": "-96.57896000"
                        },
                        {
                            "id": 117355,
                            "name": "Golden Valley",
                            "latitude": "45.00969000",
                            "longitude": "-93.34912000"
                        },
                        {
                            "id": 117379,
                            "name": "Goodhue",
                            "latitude": "44.40052000",
                            "longitude": "-92.62380000"
                        },
                        {
                            "id": 117380,
                            "name": "Goodhue County",
                            "latitude": "44.40985000",
                            "longitude": "-92.72259000"
                        },
                        {
                            "id": 117390,
                            "name": "Goodview",
                            "latitude": "44.06246000",
                            "longitude": "-91.69571000"
                        },
                        {
                            "id": 117467,
                            "name": "Grand Marais",
                            "latitude": "47.75045000",
                            "longitude": "-90.33427000"
                        },
                        {
                            "id": 117468,
                            "name": "Grand Meadow",
                            "latitude": "43.70580000",
                            "longitude": "-92.57212000"
                        },
                        {
                            "id": 117472,
                            "name": "Grand Rapids",
                            "latitude": "47.23717000",
                            "longitude": "-93.53021000"
                        },
                        {
                            "id": 117497,
                            "name": "Granite Falls",
                            "latitude": "44.80996000",
                            "longitude": "-95.54558000"
                        },
                        {
                            "id": 117504,
                            "name": "Grant",
                            "latitude": "45.08441000",
                            "longitude": "-92.91049000"
                        },
                        {
                            "id": 117513,
                            "name": "Grant County",
                            "latitude": "45.93405000",
                            "longitude": "-96.01218000"
                        },
                        {
                            "id": 117651,
                            "name": "Greenfield",
                            "latitude": "45.10330000",
                            "longitude": "-93.69135000"
                        },
                        {
                            "id": 117871,
                            "name": "Hallock",
                            "latitude": "48.77443000",
                            "longitude": "-96.94645000"
                        },
                        {
                            "id": 117879,
                            "name": "Ham Lake",
                            "latitude": "45.25024000",
                            "longitude": "-93.24995000"
                        },
                        {
                            "id": 117971,
                            "name": "Hanover",
                            "latitude": "45.15580000",
                            "longitude": "-93.66635000"
                        },
                        {
                            "id": 118043,
                            "name": "Harris",
                            "latitude": "45.58635000",
                            "longitude": "-92.97466000"
                        },
                        {
                            "id": 118132,
                            "name": "Hastings",
                            "latitude": "44.74330000",
                            "longitude": "-92.85243000"
                        },
                        {
                            "id": 118168,
                            "name": "Hawley",
                            "latitude": "46.88079000",
                            "longitude": "-96.31673000"
                        },
                        {
                            "id": 118185,
                            "name": "Hayfield",
                            "latitude": "43.89052000",
                            "longitude": "-92.84769000"
                        },
                        {
                            "id": 118241,
                            "name": "Hector",
                            "latitude": "44.74385000",
                            "longitude": "-94.71555000"
                        },
                        {
                            "id": 118291,
                            "name": "Hennepin County",
                            "latitude": "45.00458000",
                            "longitude": "-93.47688000"
                        },
                        {
                            "id": 118326,
                            "name": "Hermantown",
                            "latitude": "46.80689000",
                            "longitude": "-92.23825000"
                        },
                        {
                            "id": 118359,
                            "name": "Hibbing",
                            "latitude": "47.42715000",
                            "longitude": "-92.93769000"
                        },
                        {
                            "id": 118477,
                            "name": "Hinckley",
                            "latitude": "46.01134000",
                            "longitude": "-92.94437000"
                        },
                        {
                            "id": 118652,
                            "name": "Hopkins",
                            "latitude": "44.92496000",
                            "longitude": "-93.46273000"
                        },
                        {
                            "id": 118706,
                            "name": "Houston County",
                            "latitude": "43.67144000",
                            "longitude": "-91.49283000"
                        },
                        {
                            "id": 118719,
                            "name": "Howard Lake",
                            "latitude": "45.06080000",
                            "longitude": "-94.07331000"
                        },
                        {
                            "id": 118728,
                            "name": "Hoyt Lakes",
                            "latitude": "47.51965000",
                            "longitude": "-92.13851000"
                        },
                        {
                            "id": 118732,
                            "name": "Hubbard County",
                            "latitude": "47.10865000",
                            "longitude": "-94.91664000"
                        },
                        {
                            "id": 118766,
                            "name": "Hugo",
                            "latitude": "45.15997000",
                            "longitude": "-92.99327000"
                        },
                        {
                            "id": 118836,
                            "name": "Hutchinson",
                            "latitude": "44.88774000",
                            "longitude": "-94.36971000"
                        },
                        {
                            "id": 118895,
                            "name": "Independence",
                            "latitude": "45.02524000",
                            "longitude": "-93.70746000"
                        },
                        {
                            "id": 118952,
                            "name": "International Falls",
                            "latitude": "48.60105000",
                            "longitude": "-93.41098000"
                        },
                        {
                            "id": 118953,
                            "name": "Inver Grove Heights",
                            "latitude": "44.84802000",
                            "longitude": "-93.04272000"
                        },
                        {
                            "id": 119017,
                            "name": "Isanti",
                            "latitude": "45.49024000",
                            "longitude": "-93.24773000"
                        },
                        {
                            "id": 119018,
                            "name": "Isanti County",
                            "latitude": "45.56149000",
                            "longitude": "-93.29518000"
                        },
                        {
                            "id": 119041,
                            "name": "Itasca County",
                            "latitude": "47.50953000",
                            "longitude": "-93.63200000"
                        },
                        {
                            "id": 119048,
                            "name": "Ivanhoe",
                            "latitude": "44.46330000",
                            "longitude": "-96.24726000"
                        },
                        {
                            "id": 119070,
                            "name": "Jackson",
                            "latitude": "43.62079000",
                            "longitude": "-94.98860000"
                        },
                        {
                            "id": 119092,
                            "name": "Jackson County",
                            "latitude": "43.67417000",
                            "longitude": "-95.15411000"
                        },
                        {
                            "id": 119130,
                            "name": "Janesville",
                            "latitude": "44.11608000",
                            "longitude": "-93.70800000"
                        },
                        {
                            "id": 119315,
                            "name": "Jordan",
                            "latitude": "44.66691000",
                            "longitude": "-93.62690000"
                        },
                        {
                            "id": 119373,
                            "name": "Kanabec County",
                            "latitude": "45.94522000",
                            "longitude": "-93.29343000"
                        },
                        {
                            "id": 119375,
                            "name": "Kandiyohi County",
                            "latitude": "45.15238000",
                            "longitude": "-95.00474000"
                        },
                        {
                            "id": 119392,
                            "name": "Kasson",
                            "latitude": "44.02996000",
                            "longitude": "-92.75074000"
                        },
                        {
                            "id": 119424,
                            "name": "Keewatin",
                            "latitude": "47.39966000",
                            "longitude": "-93.07242000"
                        },
                        {
                            "id": 119506,
                            "name": "Kenyon",
                            "latitude": "44.27219000",
                            "longitude": "-92.98548000"
                        },
                        {
                            "id": 119667,
                            "name": "Kittson County",
                            "latitude": "48.77663000",
                            "longitude": "-96.78285000"
                        },
                        {
                            "id": 119705,
                            "name": "Koochiching County",
                            "latitude": "48.24527000",
                            "longitude": "-93.78337000"
                        },
                        {
                            "id": 119741,
                            "name": "La Crescent",
                            "latitude": "43.82802000",
                            "longitude": "-91.30403000"
                        },
                        {
                            "id": 119811,
                            "name": "Lac qui Parle County",
                            "latitude": "44.99549000",
                            "longitude": "-96.17348000"
                        },
                        {
                            "id": 119883,
                            "name": "Lake City",
                            "latitude": "44.44968000",
                            "longitude": "-92.26820000"
                        },
                        {
                            "id": 119894,
                            "name": "Lake County",
                            "latitude": "47.52317000",
                            "longitude": "-91.40885000"
                        },
                        {
                            "id": 119900,
                            "name": "Lake Crystal",
                            "latitude": "44.10580000",
                            "longitude": "-94.21885000"
                        },
                        {
                            "id": 119905,
                            "name": "Lake Elmo",
                            "latitude": "44.99580000",
                            "longitude": "-92.87938000"
                        },
                        {
                            "id": 120008,
                            "name": "Lake of the Woods County",
                            "latitude": "48.77051000",
                            "longitude": "-94.90503000"
                        },
                        {
                            "id": 119971,
                            "name": "Lake Saint Croix Beach",
                            "latitude": "44.92080000",
                            "longitude": "-92.76687000"
                        },
                        {
                            "id": 119977,
                            "name": "Lake Shore",
                            "latitude": "46.48552000",
                            "longitude": "-94.36056000"
                        },
                        {
                            "id": 120009,
                            "name": "Lakefield",
                            "latitude": "43.67746000",
                            "longitude": "-95.17166000"
                        },
                        {
                            "id": 120015,
                            "name": "Lakeland",
                            "latitude": "44.95636000",
                            "longitude": "-92.76576000"
                        },
                        {
                            "id": 120043,
                            "name": "Lakeville",
                            "latitude": "44.64969000",
                            "longitude": "-93.24272000"
                        },
                        {
                            "id": 120178,
                            "name": "Lauderdale",
                            "latitude": "44.99858000",
                            "longitude": "-93.20578000"
                        },
                        {
                            "id": 120257,
                            "name": "Le Center",
                            "latitude": "44.38941000",
                            "longitude": "-93.73023000"
                        },
                        {
                            "id": 120264,
                            "name": "Le Sueur",
                            "latitude": "44.46135000",
                            "longitude": "-93.91524000"
                        },
                        {
                            "id": 120265,
                            "name": "Le Sueur County",
                            "latitude": "44.37143000",
                            "longitude": "-93.73008000"
                        },
                        {
                            "id": 120399,
                            "name": "Lester Prairie",
                            "latitude": "44.88385000",
                            "longitude": "-94.04164000"
                        },
                        {
                            "id": 120425,
                            "name": "Lewiston",
                            "latitude": "43.98441000",
                            "longitude": "-91.86932000"
                        },
                        {
                            "id": 120450,
                            "name": "Lexington",
                            "latitude": "45.14247000",
                            "longitude": "-93.16328000"
                        },
                        {
                            "id": 120527,
                            "name": "Lincoln County",
                            "latitude": "44.41260000",
                            "longitude": "-96.26709000"
                        },
                        {
                            "id": 120576,
                            "name": "Lindstrom",
                            "latitude": "45.38941000",
                            "longitude": "-92.84799000"
                        },
                        {
                            "id": 120587,
                            "name": "Lino Lakes",
                            "latitude": "45.16024000",
                            "longitude": "-93.08883000"
                        },
                        {
                            "id": 120605,
                            "name": "Litchfield",
                            "latitude": "45.12718000",
                            "longitude": "-94.52805000"
                        },
                        {
                            "id": 120613,
                            "name": "Little Canada",
                            "latitude": "45.02691000",
                            "longitude": "-93.08772000"
                        },
                        {
                            "id": 120617,
                            "name": "Little Falls",
                            "latitude": "45.97635000",
                            "longitude": "-94.36250000"
                        },
                        {
                            "id": 120627,
                            "name": "Little Rock",
                            "latitude": "47.86801000",
                            "longitude": "-95.11055000"
                        },
                        {
                            "id": 120747,
                            "name": "Long Lake",
                            "latitude": "44.98663000",
                            "longitude": "-93.57162000"
                        },
                        {
                            "id": 120749,
                            "name": "Long Prairie",
                            "latitude": "45.97469000",
                            "longitude": "-94.86558000"
                        },
                        {
                            "id": 120763,
                            "name": "Lonsdale",
                            "latitude": "44.48024000",
                            "longitude": "-93.42856000"
                        },
                        {
                            "id": 120900,
                            "name": "Luverne",
                            "latitude": "43.65414000",
                            "longitude": "-96.21281000"
                        },
                        {
                            "id": 120938,
                            "name": "Lyon County",
                            "latitude": "44.41349000",
                            "longitude": "-95.83897000"
                        },
                        {
                            "id": 120979,
                            "name": "Madelia",
                            "latitude": "44.05079000",
                            "longitude": "-94.41830000"
                        },
                        {
                            "id": 120994,
                            "name": "Madison",
                            "latitude": "45.00968000",
                            "longitude": "-96.19588000"
                        },
                        {
                            "id": 121022,
                            "name": "Madison Lake",
                            "latitude": "44.20441000",
                            "longitude": "-93.81551000"
                        },
                        {
                            "id": 121043,
                            "name": "Mahnomen",
                            "latitude": "47.31524000",
                            "longitude": "-95.96865000"
                        },
                        {
                            "id": 121044,
                            "name": "Mahnomen County",
                            "latitude": "47.32524000",
                            "longitude": "-95.80905000"
                        },
                        {
                            "id": 121047,
                            "name": "Mahtomedi",
                            "latitude": "45.06969000",
                            "longitude": "-92.95160000"
                        },
                        {
                            "id": 121130,
                            "name": "Mankato",
                            "latitude": "44.15906000",
                            "longitude": "-94.00915000"
                        },
                        {
                            "id": 121159,
                            "name": "Mantorville",
                            "latitude": "44.06913000",
                            "longitude": "-92.75575000"
                        },
                        {
                            "id": 121167,
                            "name": "Maple Grove",
                            "latitude": "45.07246000",
                            "longitude": "-93.45579000"
                        },
                        {
                            "id": 121169,
                            "name": "Maple Lake",
                            "latitude": "45.22913000",
                            "longitude": "-94.00192000"
                        },
                        {
                            "id": 121171,
                            "name": "Maple Plain",
                            "latitude": "45.00719000",
                            "longitude": "-93.65579000"
                        },
                        {
                            "id": 121175,
                            "name": "Mapleton",
                            "latitude": "43.92885000",
                            "longitude": "-93.95606000"
                        },
                        {
                            "id": 121178,
                            "name": "Maplewood",
                            "latitude": "44.95302000",
                            "longitude": "-92.99522000"
                        },
                        {
                            "id": 121308,
                            "name": "Marshall",
                            "latitude": "44.44690000",
                            "longitude": "-95.78835000"
                        },
                        {
                            "id": 121320,
                            "name": "Marshall County",
                            "latitude": "48.35813000",
                            "longitude": "-96.36847000"
                        },
                        {
                            "id": 121341,
                            "name": "Martin County",
                            "latitude": "43.67436000",
                            "longitude": "-94.55107000"
                        },
                        {
                            "id": 121432,
                            "name": "Mayer",
                            "latitude": "44.88496000",
                            "longitude": "-93.88775000"
                        },
                        {
                            "id": 121535,
                            "name": "McLeod County",
                            "latitude": "44.82354000",
                            "longitude": "-94.27242000"
                        },
                        {
                            "id": 121593,
                            "name": "Medford",
                            "latitude": "44.17413000",
                            "longitude": "-93.24632000"
                        },
                        {
                            "id": 121604,
                            "name": "Medina",
                            "latitude": "45.03524000",
                            "longitude": "-93.58246000"
                        },
                        {
                            "id": 121614,
                            "name": "Meeker County",
                            "latitude": "45.12312000",
                            "longitude": "-94.52731000"
                        },
                        {
                            "id": 121629,
                            "name": "Melrose",
                            "latitude": "45.67469000",
                            "longitude": "-94.80752000"
                        },
                        {
                            "id": 121644,
                            "name": "Menahga",
                            "latitude": "46.75385000",
                            "longitude": "-95.09808000"
                        },
                        {
                            "id": 121658,
                            "name": "Mendota Heights",
                            "latitude": "44.88358000",
                            "longitude": "-93.13827000"
                        },
                        {
                            "id": 121832,
                            "name": "Milaca",
                            "latitude": "45.75580000",
                            "longitude": "-93.65441000"
                        },
                        {
                            "id": 121876,
                            "name": "Mille Lacs County",
                            "latitude": "45.93805000",
                            "longitude": "-93.63009000"
                        },
                        {
                            "id": 121951,
                            "name": "Minneapolis",
                            "latitude": "44.97997000",
                            "longitude": "-93.26384000"
                        },
                        {
                            "id": 121955,
                            "name": "Minneota",
                            "latitude": "44.55885000",
                            "longitude": "-95.98559000"
                        },
                        {
                            "id": 121956,
                            "name": "Minnetonka",
                            "latitude": "44.91330000",
                            "longitude": "-93.50329000"
                        },
                        {
                            "id": 121957,
                            "name": "Minnetonka Mills",
                            "latitude": "44.94107000",
                            "longitude": "-93.44190000"
                        },
                        {
                            "id": 121958,
                            "name": "Minnetrista",
                            "latitude": "44.93830000",
                            "longitude": "-93.71774000"
                        },
                        {
                            "id": 122126,
                            "name": "Montevideo",
                            "latitude": "44.94803000",
                            "longitude": "-95.71701000"
                        },
                        {
                            "id": 122134,
                            "name": "Montgomery",
                            "latitude": "44.43885000",
                            "longitude": "-93.58134000"
                        },
                        {
                            "id": 122167,
                            "name": "Monticello",
                            "latitude": "45.30552000",
                            "longitude": "-93.79414000"
                        },
                        {
                            "id": 122180,
                            "name": "Montrose",
                            "latitude": "45.06496000",
                            "longitude": "-93.91108000"
                        },
                        {
                            "id": 122212,
                            "name": "Moorhead",
                            "latitude": "46.87386000",
                            "longitude": "-96.76951000"
                        },
                        {
                            "id": 122214,
                            "name": "Moose Lake",
                            "latitude": "46.45411000",
                            "longitude": "-92.76187000"
                        },
                        {
                            "id": 122218,
                            "name": "Mora",
                            "latitude": "45.87690000",
                            "longitude": "-93.29384000"
                        },
                        {
                            "id": 122268,
                            "name": "Morris",
                            "latitude": "45.58607000",
                            "longitude": "-95.91394000"
                        },
                        {
                            "id": 122277,
                            "name": "Morrison County",
                            "latitude": "46.01262000",
                            "longitude": "-94.26842000"
                        },
                        {
                            "id": 122318,
                            "name": "Mound",
                            "latitude": "44.93663000",
                            "longitude": "-93.66607000"
                        },
                        {
                            "id": 122326,
                            "name": "Mounds View",
                            "latitude": "45.10497000",
                            "longitude": "-93.20856000"
                        },
                        {
                            "id": 122419,
                            "name": "Mountain Iron",
                            "latitude": "47.53243000",
                            "longitude": "-92.62351000"
                        },
                        {
                            "id": 122420,
                            "name": "Mountain Lake",
                            "latitude": "43.93885000",
                            "longitude": "-94.92971000"
                        },
                        {
                            "id": 122445,
                            "name": "Mower County",
                            "latitude": "43.67147000",
                            "longitude": "-92.75251000"
                        },
                        {
                            "id": 122495,
                            "name": "Murray County",
                            "latitude": "44.02212000",
                            "longitude": "-95.76328000"
                        },
                        {
                            "id": 122671,
                            "name": "New Brighton",
                            "latitude": "45.06552000",
                            "longitude": "-93.20189000"
                        },
                        {
                            "id": 122726,
                            "name": "New Hope",
                            "latitude": "45.03802000",
                            "longitude": "-93.38662000"
                        },
                        {
                            "id": 122743,
                            "name": "New London",
                            "latitude": "45.30108000",
                            "longitude": "-94.94418000"
                        },
                        {
                            "id": 122766,
                            "name": "New Prague",
                            "latitude": "44.54330000",
                            "longitude": "-93.57607000"
                        },
                        {
                            "id": 122769,
                            "name": "New Richland",
                            "latitude": "43.89385000",
                            "longitude": "-93.49383000"
                        },
                        {
                            "id": 122788,
                            "name": "New Ulm",
                            "latitude": "44.31246000",
                            "longitude": "-94.46053000"
                        },
                        {
                            "id": 122797,
                            "name": "New York Mills",
                            "latitude": "46.51802000",
                            "longitude": "-95.37615000"
                        },
                        {
                            "id": 122845,
                            "name": "Newport",
                            "latitude": "44.86636000",
                            "longitude": "-93.00049000"
                        },
                        {
                            "id": 122895,
                            "name": "Nicollet",
                            "latitude": "44.27608000",
                            "longitude": "-94.18746000"
                        },
                        {
                            "id": 122896,
                            "name": "Nicollet County",
                            "latitude": "44.34989000",
                            "longitude": "-94.24730000"
                        },
                        {
                            "id": 122908,
                            "name": "Nisswa",
                            "latitude": "46.52052000",
                            "longitude": "-94.28861000"
                        },
                        {
                            "id": 122920,
                            "name": "Nobles County",
                            "latitude": "43.67424000",
                            "longitude": "-95.75339000"
                        },
                        {
                            "id": 122950,
                            "name": "Norman County",
                            "latitude": "47.32648000",
                            "longitude": "-96.45528000"
                        },
                        {
                            "id": 122994,
                            "name": "North Branch",
                            "latitude": "45.51135000",
                            "longitude": "-92.98022000"
                        },
                        {
                            "id": 123063,
                            "name": "North Mankato",
                            "latitude": "44.17330000",
                            "longitude": "-94.03385000"
                        },
                        {
                            "id": 123073,
                            "name": "North Oaks",
                            "latitude": "45.10274000",
                            "longitude": "-93.07911000"
                        },
                        {
                            "id": 123098,
                            "name": "North Saint Paul",
                            "latitude": "45.01247000",
                            "longitude": "-92.99188000"
                        },
                        {
                            "id": 123152,
                            "name": "Northfield",
                            "latitude": "44.45830000",
                            "longitude": "-93.16160000"
                        },
                        {
                            "id": 123202,
                            "name": "Norwood (historical)",
                            "latitude": "44.76802000",
                            "longitude": "-93.92747000"
                        },
                        {
                            "id": 123203,
                            "name": "Norwood Young America",
                            "latitude": "44.77357000",
                            "longitude": "-93.92163000"
                        },
                        {
                            "id": 123211,
                            "name": "Nowthen",
                            "latitude": "45.32802000",
                            "longitude": "-93.47023000"
                        },
                        {
                            "id": 123239,
                            "name": "Oak Grove",
                            "latitude": "45.34080000",
                            "longitude": "-93.32690000"
                        },
                        {
                            "id": 123258,
                            "name": "Oak Park Heights",
                            "latitude": "45.03136000",
                            "longitude": "-92.79298000"
                        },
                        {
                            "id": 123271,
                            "name": "Oakdale",
                            "latitude": "44.96302000",
                            "longitude": "-92.96494000"
                        },
                        {
                            "id": 123303,
                            "name": "Oakport",
                            "latitude": "46.93191000",
                            "longitude": "-96.77897000"
                        },
                        {
                            "id": 123450,
                            "name": "Olivia",
                            "latitude": "44.77635000",
                            "longitude": "-94.98972000"
                        },
                        {
                            "id": 123454,
                            "name": "Olmsted County",
                            "latitude": "44.00375000",
                            "longitude": "-92.40177000"
                        },
                        {
                            "id": 123573,
                            "name": "Orono",
                            "latitude": "44.97135000",
                            "longitude": "-93.60440000"
                        },
                        {
                            "id": 123575,
                            "name": "Oronoco",
                            "latitude": "44.16608000",
                            "longitude": "-92.53491000"
                        },
                        {
                            "id": 123584,
                            "name": "Ortonville",
                            "latitude": "45.30469000",
                            "longitude": "-96.44478000"
                        },
                        {
                            "id": 123592,
                            "name": "Osakis",
                            "latitude": "45.86691000",
                            "longitude": "-95.15225000"
                        },
                        {
                            "id": 123614,
                            "name": "Osseo",
                            "latitude": "45.11941000",
                            "longitude": "-93.40245000"
                        },
                        {
                            "id": 123633,
                            "name": "Otsego",
                            "latitude": "45.27413000",
                            "longitude": "-93.59135000"
                        },
                        {
                            "id": 123641,
                            "name": "Otter Tail County",
                            "latitude": "46.40880000",
                            "longitude": "-95.70800000"
                        },
                        {
                            "id": 123659,
                            "name": "Owatonna",
                            "latitude": "44.08385000",
                            "longitude": "-93.22604000"
                        },
                        {
                            "id": 123850,
                            "name": "Park Rapids",
                            "latitude": "46.92218000",
                            "longitude": "-95.05863000"
                        },
                        {
                            "id": 123866,
                            "name": "Parkers Prairie",
                            "latitude": "46.15302000",
                            "longitude": "-95.32892000"
                        },
                        {
                            "id": 123880,
                            "name": "Parkville",
                            "latitude": "47.53104000",
                            "longitude": "-92.57907000"
                        },
                        {
                            "id": 123947,
                            "name": "Paynesville",
                            "latitude": "45.38052000",
                            "longitude": "-94.71195000"
                        },
                        {
                            "id": 123996,
                            "name": "Pelican Rapids",
                            "latitude": "46.57079000",
                            "longitude": "-96.08311000"
                        },
                        {
                            "id": 124030,
                            "name": "Pennington County",
                            "latitude": "48.06623000",
                            "longitude": "-96.03667000"
                        },
                        {
                            "id": 124056,
                            "name": "Pequot Lakes",
                            "latitude": "46.60302000",
                            "longitude": "-94.30944000"
                        },
                        {
                            "id": 124059,
                            "name": "Perham",
                            "latitude": "46.59440000",
                            "longitude": "-95.57254000"
                        },
                        {
                            "id": 124181,
                            "name": "Pierz",
                            "latitude": "45.98163000",
                            "longitude": "-94.10471000"
                        },
                        {
                            "id": 124218,
                            "name": "Pine City",
                            "latitude": "45.82607000",
                            "longitude": "-92.96854000"
                        },
                        {
                            "id": 124219,
                            "name": "Pine County",
                            "latitude": "46.12077000",
                            "longitude": "-92.74127000"
                        },
                        {
                            "id": 124228,
                            "name": "Pine Island",
                            "latitude": "44.20135000",
                            "longitude": "-92.64630000"
                        },
                        {
                            "id": 124277,
                            "name": "Pipestone",
                            "latitude": "44.00053000",
                            "longitude": "-96.31753000"
                        },
                        {
                            "id": 124278,
                            "name": "Pipestone County",
                            "latitude": "44.02300000",
                            "longitude": "-96.25864000"
                        },
                        {
                            "id": 124328,
                            "name": "Plainview",
                            "latitude": "44.16497000",
                            "longitude": "-92.17156000"
                        },
                        {
                            "id": 124405,
                            "name": "Plymouth",
                            "latitude": "45.01052000",
                            "longitude": "-93.45551000"
                        },
                        {
                            "id": 124448,
                            "name": "Polk County",
                            "latitude": "47.77385000",
                            "longitude": "-96.40181000"
                        },
                        {
                            "id": 124483,
                            "name": "Pope County",
                            "latitude": "45.58602000",
                            "longitude": "-95.44448000"
                        },
                        {
                            "id": 124656,
                            "name": "Preston",
                            "latitude": "43.67024000",
                            "longitude": "-92.08322000"
                        },
                        {
                            "id": 124688,
                            "name": "Princeton",
                            "latitude": "45.56997000",
                            "longitude": "-93.58163000"
                        },
                        {
                            "id": 124698,
                            "name": "Prior Lake",
                            "latitude": "44.71330000",
                            "longitude": "-93.42273000"
                        },
                        {
                            "id": 124700,
                            "name": "Proctor",
                            "latitude": "46.74716000",
                            "longitude": "-92.22547000"
                        },
                        {
                            "id": 124841,
                            "name": "Ramsey",
                            "latitude": "45.26110000",
                            "longitude": "-93.45000000"
                        },
                        {
                            "id": 124843,
                            "name": "Ramsey County",
                            "latitude": "45.01706000",
                            "longitude": "-93.09961000"
                        },
                        {
                            "id": 124949,
                            "name": "Red Lake",
                            "latitude": "47.87635000",
                            "longitude": "-95.01694000"
                        },
                        {
                            "id": 124950,
                            "name": "Red Lake County",
                            "latitude": "47.87169000",
                            "longitude": "-96.09530000"
                        },
                        {
                            "id": 124951,
                            "name": "Red Lake Falls",
                            "latitude": "47.88219000",
                            "longitude": "-96.27421000"
                        },
                        {
                            "id": 124963,
                            "name": "Red Wing",
                            "latitude": "44.56247000",
                            "longitude": "-92.53380000"
                        },
                        {
                            "id": 124965,
                            "name": "Redby",
                            "latitude": "47.87857000",
                            "longitude": "-94.91305000"
                        },
                        {
                            "id": 124987,
                            "name": "Redwood County",
                            "latitude": "44.40366000",
                            "longitude": "-95.25383000"
                        },
                        {
                            "id": 124988,
                            "name": "Redwood Falls",
                            "latitude": "44.53940000",
                            "longitude": "-95.11694000"
                        },
                        {
                            "id": 125027,
                            "name": "Renville",
                            "latitude": "44.78913000",
                            "longitude": "-95.21167000"
                        },
                        {
                            "id": 125028,
                            "name": "Renville County",
                            "latitude": "44.72681000",
                            "longitude": "-94.94714000"
                        },
                        {
                            "id": 125052,
                            "name": "Rice",
                            "latitude": "45.75191000",
                            "longitude": "-94.22027000"
                        },
                        {
                            "id": 125054,
                            "name": "Rice County",
                            "latitude": "44.35426000",
                            "longitude": "-93.29668000"
                        },
                        {
                            "id": 125061,
                            "name": "Richfield",
                            "latitude": "44.88330000",
                            "longitude": "-93.28300000"
                        },
                        {
                            "id": 125094,
                            "name": "Richmond",
                            "latitude": "45.45413000",
                            "longitude": "-94.51833000"
                        },
                        {
                            "id": 125236,
                            "name": "Robbinsdale",
                            "latitude": "45.03219000",
                            "longitude": "-93.33856000"
                        },
                        {
                            "id": 125266,
                            "name": "Rochester",
                            "latitude": "44.02163000",
                            "longitude": "-92.46990000"
                        },
                        {
                            "id": 125272,
                            "name": "Rock County",
                            "latitude": "43.67463000",
                            "longitude": "-96.25321000"
                        },
                        {
                            "id": 125276,
                            "name": "Rock Creek",
                            "latitude": "45.75746000",
                            "longitude": "-92.96243000"
                        },
                        {
                            "id": 125302,
                            "name": "Rockford",
                            "latitude": "45.08830000",
                            "longitude": "-93.73441000"
                        },
                        {
                            "id": 125324,
                            "name": "Rockville",
                            "latitude": "45.47191000",
                            "longitude": "-94.34083000"
                        },
                        {
                            "id": 125350,
                            "name": "Rogers",
                            "latitude": "45.18885000",
                            "longitude": "-93.55301000"
                        },
                        {
                            "id": 125407,
                            "name": "Roseau",
                            "latitude": "48.84609000",
                            "longitude": "-95.76277000"
                        },
                        {
                            "id": 125408,
                            "name": "Roseau County",
                            "latitude": "48.77514000",
                            "longitude": "-95.81082000"
                        },
                        {
                            "id": 125432,
                            "name": "Rosemount",
                            "latitude": "44.73941000",
                            "longitude": "-93.12577000"
                        },
                        {
                            "id": 125440,
                            "name": "Roseville",
                            "latitude": "45.00608000",
                            "longitude": "-93.15661000"
                        },
                        {
                            "id": 125496,
                            "name": "Royalton",
                            "latitude": "45.82997000",
                            "longitude": "-94.29361000"
                        },
                        {
                            "id": 125519,
                            "name": "Rush City",
                            "latitude": "45.68551000",
                            "longitude": "-92.96549000"
                        },
                        {
                            "id": 125523,
                            "name": "Rushford",
                            "latitude": "43.80830000",
                            "longitude": "-91.75293000"
                        },
                        {
                            "id": 125601,
                            "name": "Saint Anthony",
                            "latitude": "45.02052000",
                            "longitude": "-93.21800000"
                        },
                        {
                            "id": 125603,
                            "name": "Saint Augusta",
                            "latitude": "45.45830000",
                            "longitude": "-94.19804000"
                        },
                        {
                            "id": 125610,
                            "name": "Saint Bonifacius",
                            "latitude": "44.90552000",
                            "longitude": "-93.74746000"
                        },
                        {
                            "id": 125615,
                            "name": "Saint Charles",
                            "latitude": "43.96941000",
                            "longitude": "-92.06433000"
                        },
                        {
                            "id": 125627,
                            "name": "Saint Cloud",
                            "latitude": "45.56080000",
                            "longitude": "-94.16249000"
                        },
                        {
                            "id": 125633,
                            "name": "Saint Francis",
                            "latitude": "45.38691000",
                            "longitude": "-93.35940000"
                        },
                        {
                            "id": 125655,
                            "name": "Saint James",
                            "latitude": "43.98246000",
                            "longitude": "-94.62692000"
                        },
                        {
                            "id": 125674,
                            "name": "Saint Joseph",
                            "latitude": "45.56496000",
                            "longitude": "-94.31833000"
                        },
                        {
                            "id": 125682,
                            "name": "Saint Louis County",
                            "latitude": "47.58986000",
                            "longitude": "-92.46147000"
                        },
                        {
                            "id": 125683,
                            "name": "Saint Louis Park",
                            "latitude": "44.94830000",
                            "longitude": "-93.34801000"
                        },
                        {
                            "id": 125697,
                            "name": "Saint Michael",
                            "latitude": "45.20996000",
                            "longitude": "-93.66496000"
                        },
                        {
                            "id": 125703,
                            "name": "Saint Paul",
                            "latitude": "44.94441000",
                            "longitude": "-93.09327000"
                        },
                        {
                            "id": 125705,
                            "name": "Saint Paul Park",
                            "latitude": "44.84219000",
                            "longitude": "-92.99132000"
                        },
                        {
                            "id": 125708,
                            "name": "Saint Peter",
                            "latitude": "44.32358000",
                            "longitude": "-93.95801000"
                        },
                        {
                            "id": 125874,
                            "name": "Sandstone",
                            "latitude": "46.13106000",
                            "longitude": "-92.86742000"
                        },
                        {
                            "id": 125953,
                            "name": "Sartell",
                            "latitude": "45.62163000",
                            "longitude": "-94.20694000"
                        },
                        {
                            "id": 125962,
                            "name": "Sauk Centre",
                            "latitude": "45.73747000",
                            "longitude": "-94.95252000"
                        },
                        {
                            "id": 125965,
                            "name": "Sauk Rapids",
                            "latitude": "45.59191000",
                            "longitude": "-94.16610000"
                        },
                        {
                            "id": 125972,
                            "name": "Savage",
                            "latitude": "44.77913000",
                            "longitude": "-93.33634000"
                        },
                        {
                            "id": 125995,
                            "name": "Scandia",
                            "latitude": "45.25358000",
                            "longitude": "-92.80577000"
                        },
                        {
                            "id": 126052,
                            "name": "Scott County",
                            "latitude": "44.64846000",
                            "longitude": "-93.53593000"
                        },
                        {
                            "id": 126209,
                            "name": "Shafer",
                            "latitude": "45.38691000",
                            "longitude": "-92.74771000"
                        },
                        {
                            "id": 126211,
                            "name": "Shakopee",
                            "latitude": "44.79802000",
                            "longitude": "-93.52690000"
                        },
                        {
                            "id": 126314,
                            "name": "Sherburn",
                            "latitude": "43.65218000",
                            "longitude": "-94.72692000"
                        },
                        {
                            "id": 126316,
                            "name": "Sherburne County",
                            "latitude": "45.44395000",
                            "longitude": "-93.77459000"
                        },
                        {
                            "id": 126369,
                            "name": "Shoreview",
                            "latitude": "45.07913000",
                            "longitude": "-93.14717000"
                        },
                        {
                            "id": 126371,
                            "name": "Shorewood",
                            "latitude": "44.90080000",
                            "longitude": "-93.58912000"
                        },
                        {
                            "id": 126392,
                            "name": "Sibley County",
                            "latitude": "44.57948000",
                            "longitude": "-94.23216000"
                        },
                        {
                            "id": 126420,
                            "name": "Silver Bay",
                            "latitude": "47.29436000",
                            "longitude": "-91.25739000"
                        },
                        {
                            "id": 126495,
                            "name": "Slayton",
                            "latitude": "43.98774000",
                            "longitude": "-95.75585000"
                        },
                        {
                            "id": 126496,
                            "name": "Sleepy Eye",
                            "latitude": "44.29718000",
                            "longitude": "-94.72415000"
                        },
                        {
                            "id": 126721,
                            "name": "South Saint Paul",
                            "latitude": "44.89274000",
                            "longitude": "-93.03494000"
                        },
                        {
                            "id": 126834,
                            "name": "Spicer",
                            "latitude": "45.23302000",
                            "longitude": "-94.94001000"
                        },
                        {
                            "id": 126859,
                            "name": "Spring Grove",
                            "latitude": "43.56108000",
                            "longitude": "-91.63598000"
                        },
                        {
                            "id": 126869,
                            "name": "Spring Lake Park",
                            "latitude": "45.10774000",
                            "longitude": "-93.23800000"
                        },
                        {
                            "id": 126871,
                            "name": "Spring Park",
                            "latitude": "44.93524000",
                            "longitude": "-93.63218000"
                        },
                        {
                            "id": 126876,
                            "name": "Spring Valley",
                            "latitude": "43.68691000",
                            "longitude": "-92.38906000"
                        },
                        {
                            "id": 126902,
                            "name": "Springfield",
                            "latitude": "44.23885000",
                            "longitude": "-94.97582000"
                        },
                        {
                            "id": 126931,
                            "name": "Stacy",
                            "latitude": "45.39802000",
                            "longitude": "-92.98744000"
                        },
                        {
                            "id": 126975,
                            "name": "Staples",
                            "latitude": "46.35552000",
                            "longitude": "-94.79224000"
                        },
                        {
                            "id": 126983,
                            "name": "Starbuck",
                            "latitude": "45.61440000",
                            "longitude": "-95.53115000"
                        },
                        {
                            "id": 127005,
                            "name": "Stearns County",
                            "latitude": "45.55215000",
                            "longitude": "-94.61302000"
                        },
                        {
                            "id": 127010,
                            "name": "Steele County",
                            "latitude": "44.02234000",
                            "longitude": "-93.22604000"
                        },
                        {
                            "id": 127040,
                            "name": "Stevens County",
                            "latitude": "45.58613000",
                            "longitude": "-96.00030000"
                        },
                        {
                            "id": 127055,
                            "name": "Stewartville",
                            "latitude": "43.85552000",
                            "longitude": "-92.48851000"
                        },
                        {
                            "id": 127061,
                            "name": "Stillwater",
                            "latitude": "45.05636000",
                            "longitude": "-92.80604000"
                        },
                        {
                            "id": 127345,
                            "name": "Swift County",
                            "latitude": "45.28271000",
                            "longitude": "-95.68143000"
                        },
                        {
                            "id": 127462,
                            "name": "Taylors Falls",
                            "latitude": "45.40191000",
                            "longitude": "-92.65243000"
                        },
                        {
                            "id": 127574,
                            "name": "Thief River Falls",
                            "latitude": "48.11914000",
                            "longitude": "-96.18115000"
                        },
                        {
                            "id": 127693,
                            "name": "Todd County",
                            "latitude": "46.07062000",
                            "longitude": "-94.89760000"
                        },
                        {
                            "id": 127720,
                            "name": "Tonka Bay",
                            "latitude": "44.90857000",
                            "longitude": "-93.59301000"
                        },
                        {
                            "id": 127770,
                            "name": "Tracy",
                            "latitude": "44.23329000",
                            "longitude": "-95.61918000"
                        },
                        {
                            "id": 127785,
                            "name": "Traverse County",
                            "latitude": "45.77218000",
                            "longitude": "-96.47164000"
                        },
                        {
                            "id": 127855,
                            "name": "Truman",
                            "latitude": "43.82773000",
                            "longitude": "-94.43719000"
                        },
                        {
                            "id": 127935,
                            "name": "Two Harbors",
                            "latitude": "47.02271000",
                            "longitude": "-91.67073000"
                        },
                        {
                            "id": 127941,
                            "name": "Tyler",
                            "latitude": "44.27830000",
                            "longitude": "-96.13475000"
                        },
                        {
                            "id": 128073,
                            "name": "Vadnais Heights",
                            "latitude": "45.05747000",
                            "longitude": "-93.07383000"
                        },
                        {
                            "id": 128222,
                            "name": "Victoria",
                            "latitude": "44.85857000",
                            "longitude": "-93.66163000"
                        },
                        {
                            "id": 128271,
                            "name": "Vineland",
                            "latitude": "46.16357000",
                            "longitude": "-93.75747000"
                        },
                        {
                            "id": 128287,
                            "name": "Virginia",
                            "latitude": "47.52326000",
                            "longitude": "-92.53657000"
                        },
                        {
                            "id": 128312,
                            "name": "Wabasha",
                            "latitude": "44.38386000",
                            "longitude": "-92.03294000"
                        },
                        {
                            "id": 128313,
                            "name": "Wabasha County",
                            "latitude": "44.28428000",
                            "longitude": "-92.23027000"
                        },
                        {
                            "id": 128317,
                            "name": "Waconia",
                            "latitude": "44.85080000",
                            "longitude": "-93.78691000"
                        },
                        {
                            "id": 128321,
                            "name": "Wadena",
                            "latitude": "46.44246000",
                            "longitude": "-95.13614000"
                        },
                        {
                            "id": 128322,
                            "name": "Wadena County",
                            "latitude": "46.58576000",
                            "longitude": "-94.96941000"
                        },
                        {
                            "id": 128353,
                            "name": "Waite Park",
                            "latitude": "45.55719000",
                            "longitude": "-94.22416000"
                        },
                        {
                            "id": 128385,
                            "name": "Walker",
                            "latitude": "47.10135000",
                            "longitude": "-94.58722000"
                        },
                        {
                            "id": 128448,
                            "name": "Wanamingo",
                            "latitude": "44.30441000",
                            "longitude": "-92.79047000"
                        },
                        {
                            "id": 128482,
                            "name": "Warren",
                            "latitude": "48.19664000",
                            "longitude": "-96.77284000"
                        },
                        {
                            "id": 128514,
                            "name": "Warroad",
                            "latitude": "48.90527000",
                            "longitude": "-95.31440000"
                        },
                        {
                            "id": 128530,
                            "name": "Waseca",
                            "latitude": "44.07774000",
                            "longitude": "-93.50744000"
                        },
                        {
                            "id": 128531,
                            "name": "Waseca County",
                            "latitude": "44.02212000",
                            "longitude": "-93.58728000"
                        },
                        {
                            "id": 128570,
                            "name": "Washington County",
                            "latitude": "45.03873000",
                            "longitude": "-92.88396000"
                        },
                        {
                            "id": 128622,
                            "name": "Watertown",
                            "latitude": "44.96357000",
                            "longitude": "-93.84719000"
                        },
                        {
                            "id": 128627,
                            "name": "Waterville",
                            "latitude": "44.21885000",
                            "longitude": "-93.56800000"
                        },
                        {
                            "id": 128637,
                            "name": "Watonwan County",
                            "latitude": "43.97843000",
                            "longitude": "-94.61406000"
                        },
                        {
                            "id": 128670,
                            "name": "Waverly",
                            "latitude": "45.06663000",
                            "longitude": "-93.96636000"
                        },
                        {
                            "id": 128713,
                            "name": "Wayzata",
                            "latitude": "44.97413000",
                            "longitude": "-93.50662000"
                        },
                        {
                            "id": 128782,
                            "name": "Wells",
                            "latitude": "43.74607000",
                            "longitude": "-93.72884000"
                        },
                        {
                            "id": 128852,
                            "name": "West Coon Rapids",
                            "latitude": "45.15969000",
                            "longitude": "-93.34967000"
                        },
                        {
                            "id": 128972,
                            "name": "West Saint Paul",
                            "latitude": "44.91608000",
                            "longitude": "-93.10161000"
                        },
                        {
                            "id": 129114,
                            "name": "Wheaton",
                            "latitude": "45.80441000",
                            "longitude": "-96.49923000"
                        },
                        {
                            "id": 129126,
                            "name": "White Bear Lake",
                            "latitude": "45.08469000",
                            "longitude": "-93.00994000"
                        },
                        {
                            "id": 129248,
                            "name": "Wilkin County",
                            "latitude": "46.35708000",
                            "longitude": "-96.46835000"
                        },
                        {
                            "id": 129303,
                            "name": "Willmar",
                            "latitude": "45.12191000",
                            "longitude": "-95.04334000"
                        },
                        {
                            "id": 129375,
                            "name": "Windom",
                            "latitude": "43.86635000",
                            "longitude": "-95.11694000"
                        },
                        {
                            "id": 129406,
                            "name": "Winnebago",
                            "latitude": "43.76773000",
                            "longitude": "-94.16579000"
                        },
                        {
                            "id": 129424,
                            "name": "Winona",
                            "latitude": "44.04996000",
                            "longitude": "-91.63932000"
                        },
                        {
                            "id": 129425,
                            "name": "Winona County",
                            "latitude": "43.98685000",
                            "longitude": "-91.77913000"
                        },
                        {
                            "id": 129431,
                            "name": "Winsted",
                            "latitude": "44.96385000",
                            "longitude": "-94.04747000"
                        },
                        {
                            "id": 129451,
                            "name": "Winthrop",
                            "latitude": "44.54302000",
                            "longitude": "-94.36637000"
                        },
                        {
                            "id": 129509,
                            "name": "Woodbury",
                            "latitude": "44.92386000",
                            "longitude": "-92.95938000"
                        },
                        {
                            "id": 129598,
                            "name": "Worthington",
                            "latitude": "43.61996000",
                            "longitude": "-95.59640000"
                        },
                        {
                            "id": 129610,
                            "name": "Wright County",
                            "latitude": "45.17393000",
                            "longitude": "-93.96305000"
                        },
                        {
                            "id": 129637,
                            "name": "Wyoming",
                            "latitude": "45.33636000",
                            "longitude": "-92.99716000"
                        },
                        {
                            "id": 129678,
                            "name": "Yellow Medicine County",
                            "latitude": "44.71625000",
                            "longitude": "-95.86836000"
                        },
                        {
                            "id": 129713,
                            "name": "Young America (historical)",
                            "latitude": "44.78274000",
                            "longitude": "-93.91358000"
                        },
                        {
                            "id": 129756,
                            "name": "Zimmerman",
                            "latitude": "45.44330000",
                            "longitude": "-93.58996000"
                        },
                        {
                            "id": 129761,
                            "name": "Zumbrota",
                            "latitude": "44.29413000",
                            "longitude": "-92.66908000"
                        }
                    ]
                },
                {
                    "id": 1430,
                    "name": "Mississippi",
                    "state_code": "MS",
                    "cities": [
                        {
                            "id": 110974,
                            "name": "Aberdeen",
                            "latitude": "33.82511000",
                            "longitude": "-88.54366000"
                        },
                        {
                            "id": 110996,
                            "name": "Ackerman",
                            "latitude": "33.31012000",
                            "longitude": "-89.17284000"
                        },
                        {
                            "id": 111018,
                            "name": "Adams County",
                            "latitude": "31.48294000",
                            "longitude": "-91.35350000"
                        },
                        {
                            "id": 111134,
                            "name": "Alcorn County",
                            "latitude": "34.88077000",
                            "longitude": "-88.58026000"
                        },
                        {
                            "id": 111289,
                            "name": "Amite County",
                            "latitude": "31.17440000",
                            "longitude": "-90.80443000"
                        },
                        {
                            "id": 111294,
                            "name": "Amory",
                            "latitude": "33.98428000",
                            "longitude": "-88.48810000"
                        },
                        {
                            "id": 111489,
                            "name": "Arnold Line",
                            "latitude": "31.33517000",
                            "longitude": "-89.37340000"
                        },
                        {
                            "id": 111530,
                            "name": "Ashland",
                            "latitude": "34.83287000",
                            "longitude": "-89.17590000"
                        },
                        {
                            "id": 111607,
                            "name": "Attala County",
                            "latitude": "33.08629000",
                            "longitude": "-89.58155000"
                        },
                        {
                            "id": 111766,
                            "name": "Baldwyn",
                            "latitude": "34.50954000",
                            "longitude": "-88.63533000"
                        },
                        {
                            "id": 111894,
                            "name": "Batesville",
                            "latitude": "34.31150000",
                            "longitude": "-89.94426000"
                        },
                        {
                            "id": 111933,
                            "name": "Bay Saint Louis",
                            "latitude": "30.30881000",
                            "longitude": "-89.33005000"
                        },
                        {
                            "id": 111935,
                            "name": "Bay Springs",
                            "latitude": "31.97904000",
                            "longitude": "-89.28728000"
                        },
                        {
                            "id": 112041,
                            "name": "Beechwood",
                            "latitude": "32.32765000",
                            "longitude": "-90.82677000"
                        },
                        {
                            "id": 112135,
                            "name": "Belmont",
                            "latitude": "34.50982000",
                            "longitude": "-88.20921000"
                        },
                        {
                            "id": 112158,
                            "name": "Belzoni",
                            "latitude": "33.18429000",
                            "longitude": "-90.48926000"
                        },
                        {
                            "id": 112201,
                            "name": "Benton County",
                            "latitude": "34.81729000",
                            "longitude": "-89.18848000"
                        },
                        {
                            "id": 112347,
                            "name": "Biloxi",
                            "latitude": "30.39603000",
                            "longitude": "-88.88531000"
                        },
                        {
                            "id": 112521,
                            "name": "Bolivar County",
                            "latitude": "33.79554000",
                            "longitude": "-90.88040000"
                        },
                        {
                            "id": 112564,
                            "name": "Booneville",
                            "latitude": "34.65815000",
                            "longitude": "-88.56672000"
                        },
                        {
                            "id": 112689,
                            "name": "Brandon",
                            "latitude": "32.27320000",
                            "longitude": "-89.98592000"
                        },
                        {
                            "id": 112857,
                            "name": "Brookhaven",
                            "latitude": "31.57906000",
                            "longitude": "-90.44065000"
                        },
                        {
                            "id": 112892,
                            "name": "Brooksville",
                            "latitude": "33.23457000",
                            "longitude": "-88.58227000"
                        },
                        {
                            "id": 112942,
                            "name": "Bruce",
                            "latitude": "33.99206000",
                            "longitude": "-89.34896000"
                        },
                        {
                            "id": 112995,
                            "name": "Bude",
                            "latitude": "31.46295000",
                            "longitude": "-90.85010000"
                        },
                        {
                            "id": 113135,
                            "name": "Byhalia",
                            "latitude": "34.87232000",
                            "longitude": "-89.69064000"
                        },
                        {
                            "id": 113139,
                            "name": "Byram",
                            "latitude": "32.17932000",
                            "longitude": "-90.24537000"
                        },
                        {
                            "id": 113188,
                            "name": "Caledonia",
                            "latitude": "33.68289000",
                            "longitude": "-88.32448000"
                        },
                        {
                            "id": 113199,
                            "name": "Calhoun City",
                            "latitude": "33.85539000",
                            "longitude": "-89.31146000"
                        },
                        {
                            "id": 113205,
                            "name": "Calhoun County",
                            "latitude": "33.93645000",
                            "longitude": "-89.33645000"
                        },
                        {
                            "id": 113340,
                            "name": "Canton",
                            "latitude": "32.61264000",
                            "longitude": "-90.03675000"
                        },
                        {
                            "id": 113445,
                            "name": "Carriere",
                            "latitude": "30.61686000",
                            "longitude": "-89.65256000"
                        },
                        {
                            "id": 113455,
                            "name": "Carroll County",
                            "latitude": "33.44853000",
                            "longitude": "-89.92020000"
                        },
                        {
                            "id": 113468,
                            "name": "Carrollton",
                            "latitude": "33.50818000",
                            "longitude": "-89.92036000"
                        },
                        {
                            "id": 113492,
                            "name": "Carthage",
                            "latitude": "32.73264000",
                            "longitude": "-89.53618000"
                        },
                        {
                            "id": 113694,
                            "name": "Centreville",
                            "latitude": "31.08962000",
                            "longitude": "-91.06844000"
                        },
                        {
                            "id": 113764,
                            "name": "Charleston",
                            "latitude": "34.00678000",
                            "longitude": "-90.05676000"
                        },
                        {
                            "id": 113939,
                            "name": "Chickasaw County",
                            "latitude": "33.92080000",
                            "longitude": "-88.94786000"
                        },
                        {
                            "id": 113987,
                            "name": "Choctaw County",
                            "latitude": "33.34731000",
                            "longitude": "-89.24838000"
                        },
                        {
                            "id": 114093,
                            "name": "Claiborne County",
                            "latitude": "31.97369000",
                            "longitude": "-90.91181000"
                        },
                        {
                            "id": 114136,
                            "name": "Clarke County",
                            "latitude": "32.04140000",
                            "longitude": "-88.68940000"
                        },
                        {
                            "id": 114145,
                            "name": "Clarksdale",
                            "latitude": "34.20011000",
                            "longitude": "-90.57093000"
                        },
                        {
                            "id": 114182,
                            "name": "Clay County",
                            "latitude": "33.65567000",
                            "longitude": "-88.78157000"
                        },
                        {
                            "id": 114228,
                            "name": "Cleary",
                            "latitude": "32.16543000",
                            "longitude": "-90.18064000"
                        },
                        {
                            "id": 114243,
                            "name": "Cleveland",
                            "latitude": "33.74400000",
                            "longitude": "-90.72482000"
                        },
                        {
                            "id": 114274,
                            "name": "Clinton",
                            "latitude": "32.34153000",
                            "longitude": "-90.32176000"
                        },
                        {
                            "id": 114325,
                            "name": "Coahoma County",
                            "latitude": "34.22917000",
                            "longitude": "-90.60269000"
                        },
                        {
                            "id": 114393,
                            "name": "Coldwater",
                            "latitude": "34.69177000",
                            "longitude": "-89.97731000"
                        },
                        {
                            "id": 114431,
                            "name": "Collins",
                            "latitude": "31.64544000",
                            "longitude": "-89.55535000"
                        },
                        {
                            "id": 114435,
                            "name": "Collinsville",
                            "latitude": "32.49792000",
                            "longitude": "-88.84588000"
                        },
                        {
                            "id": 114469,
                            "name": "Columbia",
                            "latitude": "31.25184000",
                            "longitude": "-89.83758000"
                        },
                        {
                            "id": 114494,
                            "name": "Columbus",
                            "latitude": "33.49567000",
                            "longitude": "-88.42726000"
                        },
                        {
                            "id": 114501,
                            "name": "Columbus Air Force Base",
                            "latitude": "33.63239000",
                            "longitude": "-88.45153000"
                        },
                        {
                            "id": 114527,
                            "name": "Como",
                            "latitude": "34.51066000",
                            "longitude": "-89.93981000"
                        },
                        {
                            "id": 114551,
                            "name": "Conehatta",
                            "latitude": "32.45125000",
                            "longitude": "-89.28534000"
                        },
                        {
                            "id": 114617,
                            "name": "Copiah County",
                            "latitude": "31.86924000",
                            "longitude": "-90.44880000"
                        },
                        {
                            "id": 114643,
                            "name": "Corinth",
                            "latitude": "34.93425000",
                            "longitude": "-88.52227000"
                        },
                        {
                            "id": 114743,
                            "name": "Covington County",
                            "latitude": "31.63322000",
                            "longitude": "-89.55263000"
                        },
                        {
                            "id": 114884,
                            "name": "Crystal Springs",
                            "latitude": "31.98738000",
                            "longitude": "-90.35704000"
                        },
                        {
                            "id": 114961,
                            "name": "D'Iberville",
                            "latitude": "30.42631000",
                            "longitude": "-88.89086000"
                        },
                        {
                            "id": 115106,
                            "name": "De Kalb",
                            "latitude": "32.76763000",
                            "longitude": "-88.65088000"
                        },
                        {
                            "id": 115111,
                            "name": "De Lisle",
                            "latitude": "30.37936000",
                            "longitude": "-89.26449000"
                        },
                        {
                            "id": 115119,
                            "name": "De Soto County",
                            "latitude": "34.87540000",
                            "longitude": "-89.99178000"
                        },
                        {
                            "id": 115153,
                            "name": "Decatur",
                            "latitude": "32.43903000",
                            "longitude": "-89.10839000"
                        },
                        {
                            "id": 115263,
                            "name": "Derma",
                            "latitude": "33.85567000",
                            "longitude": "-89.28452000"
                        },
                        {
                            "id": 115317,
                            "name": "Diamondhead",
                            "latitude": "30.39464000",
                            "longitude": "-89.36394000"
                        },
                        {
                            "id": 115473,
                            "name": "Drew",
                            "latitude": "33.80956000",
                            "longitude": "-90.52648000"
                        },
                        {
                            "id": 115504,
                            "name": "Duck Hill",
                            "latitude": "33.63318000",
                            "longitude": "-89.71119000"
                        },
                        {
                            "id": 115566,
                            "name": "Durant",
                            "latitude": "33.07513000",
                            "longitude": "-89.85453000"
                        },
                        {
                            "id": 115886,
                            "name": "Edwards",
                            "latitude": "32.33015000",
                            "longitude": "-90.60565000"
                        },
                        {
                            "id": 116035,
                            "name": "Ellisville",
                            "latitude": "31.60405000",
                            "longitude": "-89.19561000"
                        },
                        {
                            "id": 116182,
                            "name": "Escatawpa",
                            "latitude": "30.44048000",
                            "longitude": "-88.54363000"
                        },
                        {
                            "id": 116231,
                            "name": "Eupora",
                            "latitude": "33.54068000",
                            "longitude": "-89.26701000"
                        },
                        {
                            "id": 116414,
                            "name": "Farmington",
                            "latitude": "34.93009000",
                            "longitude": "-88.45227000"
                        },
                        {
                            "id": 116440,
                            "name": "Fayette",
                            "latitude": "31.71155000",
                            "longitude": "-91.06066000"
                        },
                        {
                            "id": 116571,
                            "name": "Flora",
                            "latitude": "32.54320000",
                            "longitude": "-90.30926000"
                        },
                        {
                            "id": 116579,
                            "name": "Florence",
                            "latitude": "32.15348000",
                            "longitude": "-90.13120000"
                        },
                        {
                            "id": 116604,
                            "name": "Flowood",
                            "latitude": "32.30959000",
                            "longitude": "-90.13898000"
                        },
                        {
                            "id": 116643,
                            "name": "Forest",
                            "latitude": "32.36459000",
                            "longitude": "-89.47423000"
                        },
                        {
                            "id": 116680,
                            "name": "Forrest County",
                            "latitude": "31.18887000",
                            "longitude": "-89.25786000"
                        },
                        {
                            "id": 116852,
                            "name": "Franklin County",
                            "latitude": "31.47715000",
                            "longitude": "-90.89785000"
                        },
                        {
                            "id": 116942,
                            "name": "Friars Point",
                            "latitude": "34.37088000",
                            "longitude": "-90.63834000"
                        },
                        {
                            "id": 116988,
                            "name": "Fulton",
                            "latitude": "34.27399000",
                            "longitude": "-88.40921000"
                        },
                        {
                            "id": 117126,
                            "name": "Gautier",
                            "latitude": "30.38575000",
                            "longitude": "-88.61169000"
                        },
                        {
                            "id": 117153,
                            "name": "George County",
                            "latitude": "30.86261000",
                            "longitude": "-88.64403000"
                        },
                        {
                            "id": 117281,
                            "name": "Glendale",
                            "latitude": "31.36462000",
                            "longitude": "-89.30617000"
                        },
                        {
                            "id": 117388,
                            "name": "Goodman",
                            "latitude": "32.97013000",
                            "longitude": "-89.91231000"
                        },
                        {
                            "id": 117637,
                            "name": "Greene County",
                            "latitude": "31.21422000",
                            "longitude": "-88.63916000"
                        },
                        {
                            "id": 117686,
                            "name": "Greenville",
                            "latitude": "33.40898000",
                            "longitude": "-91.05978000"
                        },
                        {
                            "id": 117705,
                            "name": "Greenwood",
                            "latitude": "33.51623000",
                            "longitude": "-90.17953000"
                        },
                        {
                            "id": 117720,
                            "name": "Grenada",
                            "latitude": "33.76900000",
                            "longitude": "-89.80842000"
                        },
                        {
                            "id": 117721,
                            "name": "Grenada County",
                            "latitude": "33.76995000",
                            "longitude": "-89.80201000"
                        },
                        {
                            "id": 117793,
                            "name": "Gulf Hills",
                            "latitude": "30.43048000",
                            "longitude": "-88.84225000"
                        },
                        {
                            "id": 117794,
                            "name": "Gulf Park Estates",
                            "latitude": "30.39187000",
                            "longitude": "-88.76114000"
                        },
                        {
                            "id": 117797,
                            "name": "Gulfport",
                            "latitude": "30.36742000",
                            "longitude": "-89.09282000"
                        },
                        {
                            "id": 117807,
                            "name": "Guntown",
                            "latitude": "34.44316000",
                            "longitude": "-88.65978000"
                        },
                        {
                            "id": 117953,
                            "name": "Hancock County",
                            "latitude": "30.39378000",
                            "longitude": "-89.47456000"
                        },
                        {
                            "id": 118064,
                            "name": "Harrison County",
                            "latitude": "30.41605000",
                            "longitude": "-89.08164000"
                        },
                        {
                            "id": 118140,
                            "name": "Hattiesburg",
                            "latitude": "31.32712000",
                            "longitude": "-89.29034000"
                        },
                        {
                            "id": 118212,
                            "name": "Hazlehurst",
                            "latitude": "31.86044000",
                            "longitude": "-90.39593000"
                        },
                        {
                            "id": 118249,
                            "name": "Helena",
                            "latitude": "30.49464000",
                            "longitude": "-88.49585000"
                        },
                        {
                            "id": 118333,
                            "name": "Hernando",
                            "latitude": "34.82399000",
                            "longitude": "-89.99370000"
                        },
                        {
                            "id": 118368,
                            "name": "Hickory Hills",
                            "latitude": "30.45687000",
                            "longitude": "-88.63919000"
                        },
                        {
                            "id": 118381,
                            "name": "Hide-A-Way Lake",
                            "latitude": "30.56492000",
                            "longitude": "-89.64020000"
                        },
                        {
                            "id": 118448,
                            "name": "Hillsboro",
                            "latitude": "32.45931000",
                            "longitude": "-89.51146000"
                        },
                        {
                            "id": 118479,
                            "name": "Hinds County",
                            "latitude": "32.26670000",
                            "longitude": "-90.44282000"
                        },
                        {
                            "id": 118541,
                            "name": "Hollandale",
                            "latitude": "33.16901000",
                            "longitude": "-90.85399000"
                        },
                        {
                            "id": 118563,
                            "name": "Holly Springs",
                            "latitude": "34.76760000",
                            "longitude": "-89.44869000"
                        },
                        {
                            "id": 118572,
                            "name": "Holmes County",
                            "latitude": "33.12351000",
                            "longitude": "-90.09205000"
                        },
                        {
                            "id": 118666,
                            "name": "Horn Lake",
                            "latitude": "34.95537000",
                            "longitude": "-90.03481000"
                        },
                        {
                            "id": 118698,
                            "name": "Houston",
                            "latitude": "33.89845000",
                            "longitude": "-88.99923000"
                        },
                        {
                            "id": 118784,
                            "name": "Humphreys County",
                            "latitude": "33.12870000",
                            "longitude": "-90.52662000"
                        },
                        {
                            "id": 118819,
                            "name": "Hurley",
                            "latitude": "30.66103000",
                            "longitude": "-88.49418000"
                        },
                        {
                            "id": 118925,
                            "name": "Indianola",
                            "latitude": "33.45095000",
                            "longitude": "-90.65509000"
                        },
                        {
                            "id": 119037,
                            "name": "Issaquena County",
                            "latitude": "32.74139000",
                            "longitude": "-90.98921000"
                        },
                        {
                            "id": 119042,
                            "name": "Itawamba County",
                            "latitude": "34.27999000",
                            "longitude": "-88.36132000"
                        },
                        {
                            "id": 119045,
                            "name": "Itta Bena",
                            "latitude": "33.49512000",
                            "longitude": "-90.31981000"
                        },
                        {
                            "id": 119046,
                            "name": "Iuka",
                            "latitude": "34.81176000",
                            "longitude": "-88.19004000"
                        },
                        {
                            "id": 119065,
                            "name": "Jackson",
                            "latitude": "32.29876000",
                            "longitude": "-90.18481000"
                        },
                        {
                            "id": 119084,
                            "name": "Jackson County",
                            "latitude": "30.46289000",
                            "longitude": "-88.62284000"
                        },
                        {
                            "id": 119148,
                            "name": "Jasper County",
                            "latitude": "32.01911000",
                            "longitude": "-89.11886000"
                        },
                        {
                            "id": 119184,
                            "name": "Jefferson County",
                            "latitude": "31.73421000",
                            "longitude": "-91.03718000"
                        },
                        {
                            "id": 119199,
                            "name": "Jefferson Davis County",
                            "latitude": "31.56964000",
                            "longitude": "-89.82300000"
                        },
                        {
                            "id": 119293,
                            "name": "Jones County",
                            "latitude": "31.62256000",
                            "longitude": "-89.16879000"
                        },
                        {
                            "id": 119306,
                            "name": "Jonestown",
                            "latitude": "34.31955000",
                            "longitude": "-90.45565000"
                        },
                        {
                            "id": 119410,
                            "name": "Kearney Park",
                            "latitude": "32.58903000",
                            "longitude": "-90.31537000"
                        },
                        {
                            "id": 119437,
                            "name": "Kemper County",
                            "latitude": "32.75456000",
                            "longitude": "-88.64116000"
                        },
                        {
                            "id": 119564,
                            "name": "Kiln",
                            "latitude": "30.40908000",
                            "longitude": "-89.43505000"
                        },
                        {
                            "id": 119709,
                            "name": "Kosciusko",
                            "latitude": "33.05800000",
                            "longitude": "-89.58956000"
                        },
                        {
                            "id": 119841,
                            "name": "Lafayette County",
                            "latitude": "34.35675000",
                            "longitude": "-89.48492000"
                        },
                        {
                            "id": 120063,
                            "name": "Lamar County",
                            "latitude": "31.20587000",
                            "longitude": "-89.50869000"
                        },
                        {
                            "id": 120067,
                            "name": "Lambert",
                            "latitude": "34.20178000",
                            "longitude": "-90.28343000"
                        },
                        {
                            "id": 120172,
                            "name": "Latimer",
                            "latitude": "30.53464000",
                            "longitude": "-88.86670000"
                        },
                        {
                            "id": 120180,
                            "name": "Lauderdale County",
                            "latitude": "32.40429000",
                            "longitude": "-88.66254000"
                        },
                        {
                            "id": 120190,
                            "name": "Laurel",
                            "latitude": "31.69405000",
                            "longitude": "-89.13061000"
                        },
                        {
                            "id": 120235,
                            "name": "Lawrence County",
                            "latitude": "31.55020000",
                            "longitude": "-90.10699000"
                        },
                        {
                            "id": 120275,
                            "name": "Leake County",
                            "latitude": "32.75353000",
                            "longitude": "-89.52406000"
                        },
                        {
                            "id": 120276,
                            "name": "Leakesville",
                            "latitude": "31.15574000",
                            "longitude": "-88.55780000"
                        },
                        {
                            "id": 120311,
                            "name": "Lee County",
                            "latitude": "34.28989000",
                            "longitude": "-88.68042000"
                        },
                        {
                            "id": 120331,
                            "name": "Leflore County",
                            "latitude": "33.55052000",
                            "longitude": "-90.30106000"
                        },
                        {
                            "id": 120349,
                            "name": "Leland",
                            "latitude": "33.40539000",
                            "longitude": "-90.89760000"
                        },
                        {
                            "id": 120441,
                            "name": "Lexington",
                            "latitude": "33.11318000",
                            "longitude": "-90.05314000"
                        },
                        {
                            "id": 120463,
                            "name": "Liberty",
                            "latitude": "31.15823000",
                            "longitude": "-90.81232000"
                        },
                        {
                            "id": 120521,
                            "name": "Lincoln County",
                            "latitude": "31.53239000",
                            "longitude": "-90.45400000"
                        },
                        {
                            "id": 120735,
                            "name": "Long Beach",
                            "latitude": "30.35048000",
                            "longitude": "-89.15282000"
                        },
                        {
                            "id": 120814,
                            "name": "Louisville",
                            "latitude": "33.12374000",
                            "longitude": "-89.05506000"
                        },
                        {
                            "id": 120848,
                            "name": "Lowndes County",
                            "latitude": "33.47291000",
                            "longitude": "-88.44331000"
                        },
                        {
                            "id": 120864,
                            "name": "Lucedale",
                            "latitude": "30.92519000",
                            "longitude": "-88.59002000"
                        },
                        {
                            "id": 120879,
                            "name": "Lumberton",
                            "latitude": "31.00129000",
                            "longitude": "-89.45229000"
                        },
                        {
                            "id": 120908,
                            "name": "Lyman",
                            "latitude": "30.49474000",
                            "longitude": "-89.12561000"
                        },
                        {
                            "id": 120914,
                            "name": "Lynchburg",
                            "latitude": "34.96232000",
                            "longitude": "-90.09593000"
                        },
                        {
                            "id": 120966,
                            "name": "Macon",
                            "latitude": "33.10540000",
                            "longitude": "-88.56088000"
                        },
                        {
                            "id": 120989,
                            "name": "Madison",
                            "latitude": "32.46181000",
                            "longitude": "-90.11536000"
                        },
                        {
                            "id": 121009,
                            "name": "Madison County",
                            "latitude": "32.63466000",
                            "longitude": "-90.03376000"
                        },
                        {
                            "id": 121032,
                            "name": "Magee",
                            "latitude": "31.87377000",
                            "longitude": "-89.73369000"
                        },
                        {
                            "id": 121036,
                            "name": "Magnolia",
                            "latitude": "31.14323000",
                            "longitude": "-90.45871000"
                        },
                        {
                            "id": 121153,
                            "name": "Mantachie",
                            "latitude": "34.32427000",
                            "longitude": "-88.49116000"
                        },
                        {
                            "id": 121237,
                            "name": "Marion",
                            "latitude": "32.41736000",
                            "longitude": "-88.64782000"
                        },
                        {
                            "id": 121256,
                            "name": "Marion County",
                            "latitude": "31.23082000",
                            "longitude": "-89.82244000"
                        },
                        {
                            "id": 121272,
                            "name": "Marks",
                            "latitude": "34.25683000",
                            "longitude": "-90.27298000"
                        },
                        {
                            "id": 121313,
                            "name": "Marshall County",
                            "latitude": "34.76225000",
                            "longitude": "-89.50305000"
                        },
                        {
                            "id": 121434,
                            "name": "Mayersville",
                            "latitude": "32.90207000",
                            "longitude": "-91.05122000"
                        },
                        {
                            "id": 121474,
                            "name": "McComb",
                            "latitude": "31.24379000",
                            "longitude": "-90.45315000"
                        },
                        {
                            "id": 121574,
                            "name": "Meadville",
                            "latitude": "31.47239000",
                            "longitude": "-90.89677000"
                        },
                        {
                            "id": 121650,
                            "name": "Mendenhall",
                            "latitude": "31.96182000",
                            "longitude": "-89.87008000"
                        },
                        {
                            "id": 121690,
                            "name": "Meridian",
                            "latitude": "32.36431000",
                            "longitude": "-88.70366000"
                        },
                        {
                            "id": 121697,
                            "name": "Meridian Station",
                            "latitude": "32.55049000",
                            "longitude": "-88.61849000"
                        },
                        {
                            "id": 121732,
                            "name": "Metcalfe",
                            "latitude": "33.45400000",
                            "longitude": "-91.00733000"
                        },
                        {
                            "id": 121830,
                            "name": "Mikoma",
                            "latitude": "33.95039000",
                            "longitude": "-90.28398000"
                        },
                        {
                            "id": 122075,
                            "name": "Monroe County",
                            "latitude": "33.89224000",
                            "longitude": "-88.48047000"
                        },
                        {
                            "id": 122147,
                            "name": "Montgomery County",
                            "latitude": "33.49410000",
                            "longitude": "-89.61640000"
                        },
                        {
                            "id": 122163,
                            "name": "Monticello",
                            "latitude": "31.55378000",
                            "longitude": "-90.10731000"
                        },
                        {
                            "id": 122211,
                            "name": "Moorhead",
                            "latitude": "33.45012000",
                            "longitude": "-90.50564000"
                        },
                        {
                            "id": 122252,
                            "name": "Morgantown",
                            "latitude": "31.57267000",
                            "longitude": "-91.34761000"
                        },
                        {
                            "id": 122291,
                            "name": "Morton",
                            "latitude": "32.35376000",
                            "longitude": "-89.65452000"
                        },
                        {
                            "id": 122309,
                            "name": "Moss Point",
                            "latitude": "30.41159000",
                            "longitude": "-88.53446000"
                        },
                        {
                            "id": 122319,
                            "name": "Mound Bayou",
                            "latitude": "33.87817000",
                            "longitude": "-90.72732000"
                        },
                        {
                            "id": 122587,
                            "name": "Natchez",
                            "latitude": "31.56017000",
                            "longitude": "-91.40329000"
                        },
                        {
                            "id": 122621,
                            "name": "Nellieburg",
                            "latitude": "32.40681000",
                            "longitude": "-88.77727000"
                        },
                        {
                            "id": 122641,
                            "name": "Neshoba County",
                            "latitude": "32.75350000",
                            "longitude": "-89.11757000"
                        },
                        {
                            "id": 122646,
                            "name": "Nettleton",
                            "latitude": "34.08900000",
                            "longitude": "-88.62227000"
                        },
                        {
                            "id": 122655,
                            "name": "New Albany",
                            "latitude": "34.49427000",
                            "longitude": "-89.00784000"
                        },
                        {
                            "id": 122657,
                            "name": "New Augusta",
                            "latitude": "31.20263000",
                            "longitude": "-89.03668000"
                        },
                        {
                            "id": 122724,
                            "name": "New Hope",
                            "latitude": "33.46817000",
                            "longitude": "-88.32670000"
                        },
                        {
                            "id": 122860,
                            "name": "Newton",
                            "latitude": "32.32126000",
                            "longitude": "-89.16339000"
                        },
                        {
                            "id": 122871,
                            "name": "Newton County",
                            "latitude": "32.40023000",
                            "longitude": "-89.11881000"
                        },
                        {
                            "id": 122893,
                            "name": "Nicholson",
                            "latitude": "30.47714000",
                            "longitude": "-89.69367000"
                        },
                        {
                            "id": 123116,
                            "name": "North Tunica",
                            "latitude": "34.70093000",
                            "longitude": "-90.37815000"
                        },
                        {
                            "id": 123212,
                            "name": "Noxubee County",
                            "latitude": "33.11011000",
                            "longitude": "-88.56982000"
                        },
                        {
                            "id": 123337,
                            "name": "Ocean Springs",
                            "latitude": "30.41131000",
                            "longitude": "-88.82781000"
                        },
                        {
                            "id": 123409,
                            "name": "Okolona",
                            "latitude": "34.00178000",
                            "longitude": "-88.75533000"
                        },
                        {
                            "id": 123410,
                            "name": "Oktibbeha County",
                            "latitude": "33.42495000",
                            "longitude": "-88.87930000"
                        },
                        {
                            "id": 123439,
                            "name": "Olive Branch",
                            "latitude": "34.96176000",
                            "longitude": "-89.82953000"
                        },
                        {
                            "id": 123678,
                            "name": "Oxford",
                            "latitude": "34.36650000",
                            "longitude": "-89.51925000"
                        },
                        {
                            "id": 123803,
                            "name": "Panola County",
                            "latitude": "34.36394000",
                            "longitude": "-89.95057000"
                        },
                        {
                            "id": 123900,
                            "name": "Pascagoula",
                            "latitude": "30.36576000",
                            "longitude": "-88.55613000"
                        },
                        {
                            "id": 123906,
                            "name": "Pass Christian",
                            "latitude": "30.31575000",
                            "longitude": "-89.24754000"
                        },
                        {
                            "id": 123963,
                            "name": "Pearl",
                            "latitude": "32.27459000",
                            "longitude": "-90.13203000"
                        },
                        {
                            "id": 123967,
                            "name": "Pearl River",
                            "latitude": "32.78347000",
                            "longitude": "-89.22784000"
                        },
                        {
                            "id": 123969,
                            "name": "Pearl River County",
                            "latitude": "30.76858000",
                            "longitude": "-89.58978000"
                        },
                        {
                            "id": 123971,
                            "name": "Pearlington",
                            "latitude": "30.24658000",
                            "longitude": "-89.61117000"
                        },
                        {
                            "id": 123987,
                            "name": "Pelahatchie",
                            "latitude": "32.31292000",
                            "longitude": "-89.79841000"
                        },
                        {
                            "id": 124080,
                            "name": "Perry County",
                            "latitude": "31.17202000",
                            "longitude": "-88.99233000"
                        },
                        {
                            "id": 124100,
                            "name": "Petal",
                            "latitude": "31.34656000",
                            "longitude": "-89.26006000"
                        },
                        {
                            "id": 124125,
                            "name": "Philadelphia",
                            "latitude": "32.77152000",
                            "longitude": "-89.11673000"
                        },
                        {
                            "id": 124153,
                            "name": "Picayune",
                            "latitude": "30.52556000",
                            "longitude": "-89.67788000"
                        },
                        {
                            "id": 124154,
                            "name": "Pickens",
                            "latitude": "32.88374000",
                            "longitude": "-89.97147000"
                        },
                        {
                            "id": 124192,
                            "name": "Pike County",
                            "latitude": "31.17491000",
                            "longitude": "-90.40416000"
                        },
                        {
                            "id": 124290,
                            "name": "Pittsboro",
                            "latitude": "33.94039000",
                            "longitude": "-89.33757000"
                        },
                        {
                            "id": 124348,
                            "name": "Plantersville",
                            "latitude": "34.21344000",
                            "longitude": "-88.66450000"
                        },
                        {
                            "id": 124476,
                            "name": "Pontotoc",
                            "latitude": "34.24788000",
                            "longitude": "-88.99867000"
                        },
                        {
                            "id": 124477,
                            "name": "Pontotoc County",
                            "latitude": "34.22544000",
                            "longitude": "-89.03741000"
                        },
                        {
                            "id": 124487,
                            "name": "Poplarville",
                            "latitude": "30.84019000",
                            "longitude": "-89.53423000"
                        },
                        {
                            "id": 124507,
                            "name": "Port Gibson",
                            "latitude": "31.96099000",
                            "longitude": "-90.98399000"
                        },
                        {
                            "id": 124640,
                            "name": "Prentiss",
                            "latitude": "31.59850000",
                            "longitude": "-89.86702000"
                        },
                        {
                            "id": 124641,
                            "name": "Prentiss County",
                            "latitude": "34.61829000",
                            "longitude": "-88.52010000"
                        },
                        {
                            "id": 124758,
                            "name": "Purvis",
                            "latitude": "31.14324000",
                            "longitude": "-89.40979000"
                        },
                        {
                            "id": 124806,
                            "name": "Quitman",
                            "latitude": "32.04015000",
                            "longitude": "-88.72810000"
                        },
                        {
                            "id": 124809,
                            "name": "Quitman County",
                            "latitude": "34.25141000",
                            "longitude": "-90.28912000"
                        },
                        {
                            "id": 124830,
                            "name": "Raleigh",
                            "latitude": "32.03349000",
                            "longitude": "-89.52229000"
                        },
                        {
                            "id": 124885,
                            "name": "Rankin County",
                            "latitude": "32.26412000",
                            "longitude": "-89.94580000"
                        },
                        {
                            "id": 124914,
                            "name": "Rawls Springs",
                            "latitude": "31.38073000",
                            "longitude": "-89.37145000"
                        },
                        {
                            "id": 124917,
                            "name": "Raymond",
                            "latitude": "32.25931000",
                            "longitude": "-90.42260000"
                        },
                        {
                            "id": 125069,
                            "name": "Richland",
                            "latitude": "32.23904000",
                            "longitude": "-90.15842000"
                        },
                        {
                            "id": 125107,
                            "name": "Richton",
                            "latitude": "31.34934000",
                            "longitude": "-88.94005000"
                        },
                        {
                            "id": 125123,
                            "name": "Ridgeland",
                            "latitude": "32.42848000",
                            "longitude": "-90.13231000"
                        },
                        {
                            "id": 125162,
                            "name": "Ripley",
                            "latitude": "34.72982000",
                            "longitude": "-88.95062000"
                        },
                        {
                            "id": 125365,
                            "name": "Rolling Fork",
                            "latitude": "32.90652000",
                            "longitude": "-90.87816000"
                        },
                        {
                            "id": 125417,
                            "name": "Rosedale",
                            "latitude": "33.85344000",
                            "longitude": "-91.02789000"
                        },
                        {
                            "id": 125506,
                            "name": "Ruleville",
                            "latitude": "33.72595000",
                            "longitude": "-90.55148000"
                        },
                        {
                            "id": 125686,
                            "name": "Saint Martin",
                            "latitude": "30.43798000",
                            "longitude": "-88.86809000"
                        },
                        {
                            "id": 125769,
                            "name": "Saltillo",
                            "latitude": "34.37649000",
                            "longitude": "-88.68172000"
                        },
                        {
                            "id": 125948,
                            "name": "Sardis",
                            "latitude": "34.43705000",
                            "longitude": "-89.91592000"
                        },
                        {
                            "id": 125958,
                            "name": "Saucier",
                            "latitude": "30.63575000",
                            "longitude": "-89.13504000"
                        },
                        {
                            "id": 126048,
                            "name": "Scott County",
                            "latitude": "32.40638000",
                            "longitude": "-89.53764000"
                        },
                        {
                            "id": 126153,
                            "name": "Senatobia",
                            "latitude": "34.61760000",
                            "longitude": "-89.96870000"
                        },
                        {
                            "id": 126219,
                            "name": "Shannon",
                            "latitude": "34.11622000",
                            "longitude": "-88.71172000"
                        },
                        {
                            "id": 126226,
                            "name": "Sharkey County",
                            "latitude": "32.87972000",
                            "longitude": "-90.81321000"
                        },
                        {
                            "id": 126227,
                            "name": "Sharon",
                            "latitude": "31.78960000",
                            "longitude": "-89.09867000"
                        },
                        {
                            "id": 126245,
                            "name": "Shaw",
                            "latitude": "33.60211000",
                            "longitude": "-90.77458000"
                        },
                        {
                            "id": 126269,
                            "name": "Shelby",
                            "latitude": "33.95094000",
                            "longitude": "-90.76788000"
                        },
                        {
                            "id": 126450,
                            "name": "Simpson County",
                            "latitude": "31.91317000",
                            "longitude": "-89.91949000"
                        },
                        {
                            "id": 126513,
                            "name": "Smith County",
                            "latitude": "32.01769000",
                            "longitude": "-89.50668000"
                        },
                        {
                            "id": 126760,
                            "name": "Southaven",
                            "latitude": "34.98898000",
                            "longitude": "-90.01259000"
                        },
                        {
                            "id": 126989,
                            "name": "Starkville",
                            "latitude": "33.45049000",
                            "longitude": "-88.81961000"
                        },
                        {
                            "id": 127082,
                            "name": "Stone County",
                            "latitude": "30.78995000",
                            "longitude": "-89.11771000"
                        },
                        {
                            "id": 127092,
                            "name": "Stonewall",
                            "latitude": "32.13181000",
                            "longitude": "-88.79338000"
                        },
                        {
                            "id": 127211,
                            "name": "Summit",
                            "latitude": "31.28379000",
                            "longitude": "-90.46843000"
                        },
                        {
                            "id": 127226,
                            "name": "Sumrall",
                            "latitude": "31.41739000",
                            "longitude": "-89.54229000"
                        },
                        {
                            "id": 127251,
                            "name": "Sunflower",
                            "latitude": "33.54290000",
                            "longitude": "-90.53703000"
                        },
                        {
                            "id": 127252,
                            "name": "Sunflower County",
                            "latitude": "33.60231000",
                            "longitude": "-90.58862000"
                        },
                        {
                            "id": 127392,
                            "name": "Tallahatchie County",
                            "latitude": "33.95047000",
                            "longitude": "-90.17326000"
                        },
                        {
                            "id": 127438,
                            "name": "Tate County",
                            "latitude": "34.65032000",
                            "longitude": "-89.94478000"
                        },
                        {
                            "id": 127464,
                            "name": "Taylorsville",
                            "latitude": "31.82960000",
                            "longitude": "-89.42812000"
                        },
                        {
                            "id": 127472,
                            "name": "Tchula",
                            "latitude": "33.18290000",
                            "longitude": "-90.22286000"
                        },
                        {
                            "id": 127530,
                            "name": "Terry",
                            "latitude": "32.09626000",
                            "longitude": "-90.29426000"
                        },
                        {
                            "id": 127672,
                            "name": "Tippah County",
                            "latitude": "34.76840000",
                            "longitude": "-88.90890000"
                        },
                        {
                            "id": 127683,
                            "name": "Tishomingo County",
                            "latitude": "34.74043000",
                            "longitude": "-88.23932000"
                        },
                        {
                            "id": 127891,
                            "name": "Tunica",
                            "latitude": "34.68455000",
                            "longitude": "-90.38288000"
                        },
                        {
                            "id": 127892,
                            "name": "Tunica County",
                            "latitude": "34.65194000",
                            "longitude": "-90.37551000"
                        },
                        {
                            "id": 127893,
                            "name": "Tunica Resorts",
                            "latitude": "34.83613000",
                            "longitude": "-90.34723000"
                        },
                        {
                            "id": 127897,
                            "name": "Tupelo",
                            "latitude": "34.25807000",
                            "longitude": "-88.70464000"
                        },
                        {
                            "id": 127919,
                            "name": "Tutwiler",
                            "latitude": "34.01483000",
                            "longitude": "-90.43176000"
                        },
                        {
                            "id": 127944,
                            "name": "Tylertown",
                            "latitude": "31.11601000",
                            "longitude": "-90.14203000"
                        },
                        {
                            "id": 127972,
                            "name": "Union",
                            "latitude": "32.57153000",
                            "longitude": "-89.12145000"
                        },
                        {
                            "id": 127994,
                            "name": "Union County",
                            "latitude": "34.49047000",
                            "longitude": "-89.00386000"
                        },
                        {
                            "id": 128024,
                            "name": "University",
                            "latitude": "34.36594000",
                            "longitude": "-89.52536000"
                        },
                        {
                            "id": 128143,
                            "name": "Vancleave",
                            "latitude": "30.54047000",
                            "longitude": "-88.68752000"
                        },
                        {
                            "id": 128154,
                            "name": "Vardaman",
                            "latitude": "33.87567000",
                            "longitude": "-89.17729000"
                        },
                        {
                            "id": 128201,
                            "name": "Verona",
                            "latitude": "34.19427000",
                            "longitude": "-88.71977000"
                        },
                        {
                            "id": 128215,
                            "name": "Vicksburg",
                            "latitude": "32.35265000",
                            "longitude": "-90.87788000"
                        },
                        {
                            "id": 128319,
                            "name": "Wade",
                            "latitude": "30.64242000",
                            "longitude": "-88.56974000"
                        },
                        {
                            "id": 128412,
                            "name": "Walls",
                            "latitude": "34.95824000",
                            "longitude": "-90.15256000"
                        },
                        {
                            "id": 128418,
                            "name": "Walnut Grove",
                            "latitude": "32.59042000",
                            "longitude": "-89.45840000"
                        },
                        {
                            "id": 128430,
                            "name": "Walthall",
                            "latitude": "33.60734000",
                            "longitude": "-89.27729000"
                        },
                        {
                            "id": 128431,
                            "name": "Walthall County",
                            "latitude": "31.14842000",
                            "longitude": "-90.10614000"
                        },
                        {
                            "id": 128489,
                            "name": "Warren County",
                            "latitude": "32.35723000",
                            "longitude": "-90.85201000"
                        },
                        {
                            "id": 128562,
                            "name": "Washington County",
                            "latitude": "33.28370000",
                            "longitude": "-90.94745000"
                        },
                        {
                            "id": 128600,
                            "name": "Water Valley",
                            "latitude": "34.15150000",
                            "longitude": "-89.63147000"
                        },
                        {
                            "id": 128664,
                            "name": "Waveland",
                            "latitude": "30.28686000",
                            "longitude": "-89.37616000"
                        },
                        {
                            "id": 128694,
                            "name": "Wayne County",
                            "latitude": "31.64078000",
                            "longitude": "-88.69580000"
                        },
                        {
                            "id": 128706,
                            "name": "Waynesboro",
                            "latitude": "31.67488000",
                            "longitude": "-88.64615000"
                        },
                        {
                            "id": 128739,
                            "name": "Webster County",
                            "latitude": "33.61307000",
                            "longitude": "-89.28482000"
                        },
                        {
                            "id": 128814,
                            "name": "Wesson",
                            "latitude": "31.70128000",
                            "longitude": "-90.39759000"
                        },
                        {
                            "id": 128882,
                            "name": "West Gulfport",
                            "latitude": "30.40409000",
                            "longitude": "-89.09420000"
                        },
                        {
                            "id": 128886,
                            "name": "West Hattiesburg",
                            "latitude": "31.31906000",
                            "longitude": "-89.37506000"
                        },
                        {
                            "id": 128959,
                            "name": "West Point",
                            "latitude": "33.60762000",
                            "longitude": "-88.65033000"
                        },
                        {
                            "id": 129223,
                            "name": "Wiggins",
                            "latitude": "30.85824000",
                            "longitude": "-89.13533000"
                        },
                        {
                            "id": 129251,
                            "name": "Wilkinson County",
                            "latitude": "31.16107000",
                            "longitude": "-91.31092000"
                        },
                        {
                            "id": 129422,
                            "name": "Winona",
                            "latitude": "33.48207000",
                            "longitude": "-89.72814000"
                        },
                        {
                            "id": 129435,
                            "name": "Winston County",
                            "latitude": "33.08849000",
                            "longitude": "-89.03443000"
                        },
                        {
                            "id": 129571,
                            "name": "Woodville",
                            "latitude": "31.10462000",
                            "longitude": "-91.29956000"
                        },
                        {
                            "id": 129656,
                            "name": "Yalobusha County",
                            "latitude": "34.02821000",
                            "longitude": "-89.70763000"
                        },
                        {
                            "id": 129673,
                            "name": "Yazoo City",
                            "latitude": "32.85513000",
                            "longitude": "-90.40565000"
                        },
                        {
                            "id": 129674,
                            "name": "Yazoo County",
                            "latitude": "32.78031000",
                            "longitude": "-90.39642000"
                        }
                    ]
                },
                {
                    "id": 1451,
                    "name": "Missouri",
                    "state_code": "MO",
                    "cities": [
                        {
                            "id": 111011,
                            "name": "Adair County",
                            "latitude": "40.19056000",
                            "longitude": "-92.60072000"
                        },
                        {
                            "id": 111045,
                            "name": "Adrian",
                            "latitude": "38.39752000",
                            "longitude": "-94.35162000"
                        },
                        {
                            "id": 111048,
                            "name": "Advance",
                            "latitude": "37.10455000",
                            "longitude": "-89.90953000"
                        },
                        {
                            "id": 111051,
                            "name": "Affton",
                            "latitude": "38.55061000",
                            "longitude": "-90.33317000"
                        },
                        {
                            "id": 111107,
                            "name": "Albany",
                            "latitude": "40.24861000",
                            "longitude": "-94.33107000"
                        },
                        {
                            "id": 111243,
                            "name": "Alton",
                            "latitude": "36.69423000",
                            "longitude": "-91.39930000"
                        },
                        {
                            "id": 111310,
                            "name": "Anderson",
                            "latitude": "36.65063000",
                            "longitude": "-94.44355000"
                        },
                        {
                            "id": 111327,
                            "name": "Andrew County",
                            "latitude": "39.98349000",
                            "longitude": "-94.80205000"
                        },
                        {
                            "id": 111398,
                            "name": "Appleton City",
                            "latitude": "38.19058000",
                            "longitude": "-94.02939000"
                        },
                        {
                            "id": 111433,
                            "name": "Archie",
                            "latitude": "38.48168000",
                            "longitude": "-94.35439000"
                        },
                        {
                            "id": 111484,
                            "name": "Arnold",
                            "latitude": "38.43283000",
                            "longitude": "-90.37762000"
                        },
                        {
                            "id": 111511,
                            "name": "Ash Grove",
                            "latitude": "37.31533000",
                            "longitude": "-93.58520000"
                        },
                        {
                            "id": 111529,
                            "name": "Ashland",
                            "latitude": "38.77448000",
                            "longitude": "-92.25713000"
                        },
                        {
                            "id": 111569,
                            "name": "Atchison County",
                            "latitude": "40.43085000",
                            "longitude": "-95.42809000"
                        },
                        {
                            "id": 111637,
                            "name": "Audrain County",
                            "latitude": "39.21576000",
                            "longitude": "-91.84159000"
                        },
                        {
                            "id": 111657,
                            "name": "Aurora",
                            "latitude": "36.97089000",
                            "longitude": "-93.71798000"
                        },
                        {
                            "id": 111672,
                            "name": "Ava",
                            "latitude": "36.95200000",
                            "longitude": "-92.66045000"
                        },
                        {
                            "id": 111776,
                            "name": "Ballwin",
                            "latitude": "38.59505000",
                            "longitude": "-90.54623000"
                        },
                        {
                            "id": 111832,
                            "name": "Barnhart",
                            "latitude": "38.34422000",
                            "longitude": "-90.39345000"
                        },
                        {
                            "id": 111855,
                            "name": "Barry County",
                            "latitude": "36.70987000",
                            "longitude": "-93.82907000"
                        },
                        {
                            "id": 111867,
                            "name": "Barton County",
                            "latitude": "37.50230000",
                            "longitude": "-94.34711000"
                        },
                        {
                            "id": 111889,
                            "name": "Bates County",
                            "latitude": "38.25729000",
                            "longitude": "-94.34000000"
                        },
                        {
                            "id": 111909,
                            "name": "Battlefield",
                            "latitude": "37.11561000",
                            "longitude": "-93.37019000"
                        },
                        {
                            "id": 112049,
                            "name": "Bel-Nor",
                            "latitude": "38.70200000",
                            "longitude": "-90.31678000"
                        },
                        {
                            "id": 112050,
                            "name": "Bel-Ridge",
                            "latitude": "38.70950000",
                            "longitude": "-90.32539000"
                        },
                        {
                            "id": 112078,
                            "name": "Belle",
                            "latitude": "38.28588000",
                            "longitude": "-91.72044000"
                        },
                        {
                            "id": 112097,
                            "name": "Bellefontaine Neighbors",
                            "latitude": "38.74033000",
                            "longitude": "-90.22650000"
                        },
                        {
                            "id": 112147,
                            "name": "Belton",
                            "latitude": "38.81195000",
                            "longitude": "-94.53190000"
                        },
                        {
                            "id": 112195,
                            "name": "Benton",
                            "latitude": "37.09783000",
                            "longitude": "-89.56258000"
                        },
                        {
                            "id": 112200,
                            "name": "Benton County",
                            "latitude": "38.29485000",
                            "longitude": "-93.28795000"
                        },
                        {
                            "id": 112221,
                            "name": "Berkeley",
                            "latitude": "38.75450000",
                            "longitude": "-90.33123000"
                        },
                        {
                            "id": 112248,
                            "name": "Bernie",
                            "latitude": "36.66894000",
                            "longitude": "-89.96870000"
                        },
                        {
                            "id": 112274,
                            "name": "Bethany",
                            "latitude": "40.26833000",
                            "longitude": "-94.02829000"
                        },
                        {
                            "id": 112343,
                            "name": "Billings",
                            "latitude": "37.06755000",
                            "longitude": "-93.55214000"
                        },
                        {
                            "id": 112366,
                            "name": "Bismarck",
                            "latitude": "37.76922000",
                            "longitude": "-90.62485000"
                        },
                        {
                            "id": 112378,
                            "name": "Black Jack",
                            "latitude": "38.79338000",
                            "longitude": "-90.26733000"
                        },
                        {
                            "id": 112435,
                            "name": "Bloomfield",
                            "latitude": "36.88589000",
                            "longitude": "-89.92926000"
                        },
                        {
                            "id": 112477,
                            "name": "Blue Springs",
                            "latitude": "39.01695000",
                            "longitude": "-94.28161000"
                        },
                        {
                            "id": 112517,
                            "name": "Bolivar",
                            "latitude": "37.61448000",
                            "longitude": "-93.41047000"
                        },
                        {
                            "id": 112524,
                            "name": "Bollinger County",
                            "latitude": "37.32219000",
                            "longitude": "-90.02595000"
                        },
                        {
                            "id": 112539,
                            "name": "Bonne Terre",
                            "latitude": "37.92311000",
                            "longitude": "-90.55540000"
                        },
                        {
                            "id": 112556,
                            "name": "Boone County",
                            "latitude": "38.99062000",
                            "longitude": "-92.30968000"
                        },
                        {
                            "id": 112568,
                            "name": "Boonville",
                            "latitude": "38.97364000",
                            "longitude": "-92.74324000"
                        },
                        {
                            "id": 112611,
                            "name": "Bourbon",
                            "latitude": "38.15477000",
                            "longitude": "-91.24403000"
                        },
                        {
                            "id": 112630,
                            "name": "Bowling Green",
                            "latitude": "39.34199000",
                            "longitude": "-91.19514000"
                        },
                        {
                            "id": 112695,
                            "name": "Branson",
                            "latitude": "36.64367000",
                            "longitude": "-93.21851000"
                        },
                        {
                            "id": 112714,
                            "name": "Breckenridge Hills",
                            "latitude": "38.71450000",
                            "longitude": "-90.36734000"
                        },
                        {
                            "id": 112727,
                            "name": "Brentwood",
                            "latitude": "38.61755000",
                            "longitude": "-90.34928000"
                        },
                        {
                            "id": 112769,
                            "name": "Bridgeton",
                            "latitude": "38.76700000",
                            "longitude": "-90.41151000"
                        },
                        {
                            "id": 112853,
                            "name": "Brookfield",
                            "latitude": "39.78447000",
                            "longitude": "-93.07353000"
                        },
                        {
                            "id": 112973,
                            "name": "Buchanan County",
                            "latitude": "39.65986000",
                            "longitude": "-94.80616000"
                        },
                        {
                            "id": 112990,
                            "name": "Buckner",
                            "latitude": "39.13251000",
                            "longitude": "-94.19856000"
                        },
                        {
                            "id": 113007,
                            "name": "Buffalo",
                            "latitude": "37.64393000",
                            "longitude": "-93.09241000"
                        },
                        {
                            "id": 113108,
                            "name": "Butler",
                            "latitude": "38.25863000",
                            "longitude": "-94.33051000"
                        },
                        {
                            "id": 113117,
                            "name": "Butler County",
                            "latitude": "36.71642000",
                            "longitude": "-90.40656000"
                        },
                        {
                            "id": 113142,
                            "name": "Byrnes Mill",
                            "latitude": "38.43783000",
                            "longitude": "-90.58179000"
                        },
                        {
                            "id": 113153,
                            "name": "Cabool",
                            "latitude": "37.12394000",
                            "longitude": "-92.10127000"
                        },
                        {
                            "id": 113184,
                            "name": "Caldwell County",
                            "latitude": "39.65575000",
                            "longitude": "-93.98280000"
                        },
                        {
                            "id": 113215,
                            "name": "California",
                            "latitude": "38.62753000",
                            "longitude": "-92.56658000"
                        },
                        {
                            "id": 113225,
                            "name": "Callaway County",
                            "latitude": "38.83552000",
                            "longitude": "-91.92601000"
                        },
                        {
                            "id": 113238,
                            "name": "Calverton Park",
                            "latitude": "38.76477000",
                            "longitude": "-90.31373000"
                        },
                        {
                            "id": 113269,
                            "name": "Camden County",
                            "latitude": "38.02704000",
                            "longitude": "-92.76605000"
                        },
                        {
                            "id": 113272,
                            "name": "Camdenton",
                            "latitude": "38.00809000",
                            "longitude": "-92.74463000"
                        },
                        {
                            "id": 113273,
                            "name": "Cameron",
                            "latitude": "39.74028000",
                            "longitude": "-94.24106000"
                        },
                        {
                            "id": 113297,
                            "name": "Campbell",
                            "latitude": "36.49339000",
                            "longitude": "-90.07509000"
                        },
                        {
                            "id": 113348,
                            "name": "Canton",
                            "latitude": "40.12504000",
                            "longitude": "-91.62516000"
                        },
                        {
                            "id": 113367,
                            "name": "Cape Girardeau",
                            "latitude": "37.30588000",
                            "longitude": "-89.51815000"
                        },
                        {
                            "id": 113368,
                            "name": "Cape Girardeau County",
                            "latitude": "37.38404000",
                            "longitude": "-89.68445000"
                        },
                        {
                            "id": 113396,
                            "name": "Carl Junction",
                            "latitude": "37.17672000",
                            "longitude": "-94.56551000"
                        },
                        {
                            "id": 113454,
                            "name": "Carroll County",
                            "latitude": "39.42698000",
                            "longitude": "-93.50518000"
                        },
                        {
                            "id": 113467,
                            "name": "Carrollton",
                            "latitude": "39.35835000",
                            "longitude": "-93.49577000"
                        },
                        {
                            "id": 113481,
                            "name": "Carter County",
                            "latitude": "36.94127000",
                            "longitude": "-90.96231000"
                        },
                        {
                            "id": 113490,
                            "name": "Carterville",
                            "latitude": "37.14923000",
                            "longitude": "-94.44300000"
                        },
                        {
                            "id": 113491,
                            "name": "Carthage",
                            "latitude": "37.17645000",
                            "longitude": "-94.31022000"
                        },
                        {
                            "id": 113499,
                            "name": "Caruthersville",
                            "latitude": "36.19312000",
                            "longitude": "-89.65564000"
                        },
                        {
                            "id": 113527,
                            "name": "Cass County",
                            "latitude": "38.64700000",
                            "longitude": "-94.35482000"
                        },
                        {
                            "id": 113539,
                            "name": "Cassville",
                            "latitude": "36.67701000",
                            "longitude": "-93.86881000"
                        },
                        {
                            "id": 113548,
                            "name": "Castle Point",
                            "latitude": "38.75811000",
                            "longitude": "-90.24817000"
                        },
                        {
                            "id": 113604,
                            "name": "Cedar County",
                            "latitude": "37.72385000",
                            "longitude": "-93.85661000"
                        },
                        {
                            "id": 113613,
                            "name": "Cedar Hill",
                            "latitude": "38.35339000",
                            "longitude": "-90.64124000"
                        },
                        {
                            "id": 113654,
                            "name": "Centerville",
                            "latitude": "37.43505000",
                            "longitude": "-90.95846000"
                        },
                        {
                            "id": 113686,
                            "name": "Centralia",
                            "latitude": "39.21032000",
                            "longitude": "-92.13795000"
                        },
                        {
                            "id": 113709,
                            "name": "Chaffee",
                            "latitude": "37.18005000",
                            "longitude": "-89.65509000"
                        },
                        {
                            "id": 113750,
                            "name": "Chariton County",
                            "latitude": "39.51508000",
                            "longitude": "-92.96262000"
                        },
                        {
                            "id": 113751,
                            "name": "Charlack",
                            "latitude": "38.70255000",
                            "longitude": "-90.34345000"
                        },
                        {
                            "id": 113763,
                            "name": "Charleston",
                            "latitude": "36.92089000",
                            "longitude": "-89.35063000"
                        },
                        {
                            "id": 113901,
                            "name": "Chesterfield",
                            "latitude": "38.66311000",
                            "longitude": "-90.57707000"
                        },
                        {
                            "id": 113953,
                            "name": "Chillicothe",
                            "latitude": "39.79529000",
                            "longitude": "-93.55244000"
                        },
                        {
                            "id": 113997,
                            "name": "Christian County",
                            "latitude": "36.96957000",
                            "longitude": "-93.18885000"
                        },
                        {
                            "id": 114081,
                            "name": "City of Saint Louis",
                            "latitude": "38.62727000",
                            "longitude": "-90.19789000"
                        },
                        {
                            "id": 114126,
                            "name": "Clark County",
                            "latitude": "40.41036000",
                            "longitude": "-91.73840000"
                        },
                        {
                            "id": 114147,
                            "name": "Clarkson Valley",
                            "latitude": "38.61839000",
                            "longitude": "-90.58929000"
                        },
                        {
                            "id": 114158,
                            "name": "Clarkton",
                            "latitude": "36.45173000",
                            "longitude": "-89.96704000"
                        },
                        {
                            "id": 114181,
                            "name": "Clay County",
                            "latitude": "39.31052000",
                            "longitude": "-94.42087000"
                        },
                        {
                            "id": 114191,
                            "name": "Claycomo",
                            "latitude": "39.20250000",
                            "longitude": "-94.49245000"
                        },
                        {
                            "id": 114199,
                            "name": "Clayton",
                            "latitude": "38.64255000",
                            "longitude": "-90.32373000"
                        },
                        {
                            "id": 114251,
                            "name": "Clever",
                            "latitude": "37.03033000",
                            "longitude": "-93.47297000"
                        },
                        {
                            "id": 114271,
                            "name": "Clinton",
                            "latitude": "38.36863000",
                            "longitude": "-93.77827000"
                        },
                        {
                            "id": 114291,
                            "name": "Clinton County",
                            "latitude": "39.60178000",
                            "longitude": "-94.40459000"
                        },
                        {
                            "id": 114395,
                            "name": "Cole Camp",
                            "latitude": "38.46002000",
                            "longitude": "-93.20270000"
                        },
                        {
                            "id": 114396,
                            "name": "Cole County",
                            "latitude": "38.50541000",
                            "longitude": "-92.28160000"
                        },
                        {
                            "id": 114468,
                            "name": "Columbia",
                            "latitude": "38.95171000",
                            "longitude": "-92.33407000"
                        },
                        {
                            "id": 114535,
                            "name": "Concord",
                            "latitude": "38.52450000",
                            "longitude": "-90.35734000"
                        },
                        {
                            "id": 114545,
                            "name": "Concordia",
                            "latitude": "38.98335000",
                            "longitude": "-93.56855000"
                        },
                        {
                            "id": 114597,
                            "name": "Cool Valley",
                            "latitude": "38.72783000",
                            "longitude": "-90.31011000"
                        },
                        {
                            "id": 114603,
                            "name": "Cooper County",
                            "latitude": "38.84354000",
                            "longitude": "-92.81012000"
                        },
                        {
                            "id": 114696,
                            "name": "Cottleville",
                            "latitude": "38.74616000",
                            "longitude": "-90.65401000"
                        },
                        {
                            "id": 114717,
                            "name": "Country Club Hills",
                            "latitude": "38.72088000",
                            "longitude": "-90.27484000"
                        },
                        {
                            "id": 114719,
                            "name": "Country Club Village",
                            "latitude": "39.83222000",
                            "longitude": "-94.82163000"
                        },
                        {
                            "id": 114768,
                            "name": "Crane",
                            "latitude": "36.90534000",
                            "longitude": "-93.57158000"
                        },
                        {
                            "id": 114779,
                            "name": "Crawford County",
                            "latitude": "37.97638000",
                            "longitude": "-91.30396000"
                        },
                        {
                            "id": 114810,
                            "name": "Crestwood",
                            "latitude": "38.55700000",
                            "longitude": "-90.38178000"
                        },
                        {
                            "id": 114816,
                            "name": "Creve Coeur",
                            "latitude": "38.66089000",
                            "longitude": "-90.42262000"
                        },
                        {
                            "id": 114827,
                            "name": "Crocker",
                            "latitude": "37.94893000",
                            "longitude": "-92.26378000"
                        },
                        {
                            "id": 114875,
                            "name": "Crystal City",
                            "latitude": "38.22117000",
                            "longitude": "-90.37901000"
                        },
                        {
                            "id": 114885,
                            "name": "Cuba",
                            "latitude": "38.06282000",
                            "longitude": "-91.40348000"
                        },
                        {
                            "id": 114967,
                            "name": "Dade County",
                            "latitude": "37.43204000",
                            "longitude": "-93.85029000"
                        },
                        {
                            "id": 114996,
                            "name": "Dallas County",
                            "latitude": "37.68041000",
                            "longitude": "-93.02366000"
                        },
                        {
                            "id": 115043,
                            "name": "Dardenne Prairie",
                            "latitude": "38.76950000",
                            "longitude": "-90.72902000"
                        },
                        {
                            "id": 115071,
                            "name": "Daviess County",
                            "latitude": "39.96075000",
                            "longitude": "-93.98547000"
                        },
                        {
                            "id": 115117,
                            "name": "De Soto",
                            "latitude": "38.13950000",
                            "longitude": "-90.55513000"
                        },
                        {
                            "id": 115130,
                            "name": "DeKalb County",
                            "latitude": "39.89318000",
                            "longitude": "-94.40471000"
                        },
                        {
                            "id": 115215,
                            "name": "Dellwood",
                            "latitude": "38.74950000",
                            "longitude": "-90.28567000"
                        },
                        {
                            "id": 115244,
                            "name": "Dent County",
                            "latitude": "37.60663000",
                            "longitude": "-91.50788000"
                        },
                        {
                            "id": 115274,
                            "name": "Des Peres",
                            "latitude": "38.60089000",
                            "longitude": "-90.43290000"
                        },
                        {
                            "id": 115286,
                            "name": "Desloge",
                            "latitude": "37.87088000",
                            "longitude": "-90.52735000"
                        },
                        {
                            "id": 115307,
                            "name": "Dexter",
                            "latitude": "36.79589000",
                            "longitude": "-89.95787000"
                        },
                        {
                            "id": 115365,
                            "name": "Dixon",
                            "latitude": "37.99171000",
                            "longitude": "-92.09378000"
                        },
                        {
                            "id": 115397,
                            "name": "Doniphan",
                            "latitude": "36.62089000",
                            "longitude": "-90.82346000"
                        },
                        {
                            "id": 115428,
                            "name": "Douglas County",
                            "latitude": "36.93260000",
                            "longitude": "-92.49881000"
                        },
                        {
                            "id": 115507,
                            "name": "Duenweg",
                            "latitude": "37.08367000",
                            "longitude": "-94.41356000"
                        },
                        {
                            "id": 115541,
                            "name": "Dunklin County",
                            "latitude": "36.10597000",
                            "longitude": "-90.16576000"
                        },
                        {
                            "id": 115560,
                            "name": "Duquesne",
                            "latitude": "37.07673000",
                            "longitude": "-94.45939000"
                        },
                        {
                            "id": 115694,
                            "name": "East Independence",
                            "latitude": "39.09556000",
                            "longitude": "-94.35523000"
                        },
                        {
                            "id": 115740,
                            "name": "East Prairie",
                            "latitude": "36.77978000",
                            "longitude": "-89.38563000"
                        },
                        {
                            "id": 115866,
                            "name": "Edina",
                            "latitude": "40.16754000",
                            "longitude": "-92.17268000"
                        },
                        {
                            "id": 115923,
                            "name": "El Dorado Springs",
                            "latitude": "37.87698000",
                            "longitude": "-94.02133000"
                        },
                        {
                            "id": 115950,
                            "name": "Eldon",
                            "latitude": "38.34836000",
                            "longitude": "-92.58158000"
                        },
                        {
                            "id": 116034,
                            "name": "Ellisville",
                            "latitude": "38.59255000",
                            "longitude": "-90.58707000"
                        },
                        {
                            "id": 116069,
                            "name": "Elsberry",
                            "latitude": "39.16672000",
                            "longitude": "-90.78096000"
                        },
                        {
                            "id": 116076,
                            "name": "Elvins",
                            "latitude": "37.83672000",
                            "longitude": "-90.53290000"
                        },
                        {
                            "id": 116100,
                            "name": "Eminence",
                            "latitude": "37.15060000",
                            "longitude": "-91.35764000"
                        },
                        {
                            "id": 116209,
                            "name": "Esther",
                            "latitude": "37.85033000",
                            "longitude": "-90.49874000"
                        },
                        {
                            "id": 116233,
                            "name": "Eureka",
                            "latitude": "38.50255000",
                            "longitude": "-90.62790000"
                        },
                        {
                            "id": 116270,
                            "name": "Excelsior Springs",
                            "latitude": "39.33917000",
                            "longitude": "-94.22606000"
                        },
                        {
                            "id": 116281,
                            "name": "Fair Grove",
                            "latitude": "37.38393000",
                            "longitude": "-93.15130000"
                        },
                        {
                            "id": 116413,
                            "name": "Farmington",
                            "latitude": "37.78088000",
                            "longitude": "-90.42179000"
                        },
                        {
                            "id": 116439,
                            "name": "Fayette",
                            "latitude": "39.14587000",
                            "longitude": "-92.68379000"
                        },
                        {
                            "id": 116474,
                            "name": "Fenton",
                            "latitude": "38.51311000",
                            "longitude": "-90.43595000"
                        },
                        {
                            "id": 116480,
                            "name": "Ferguson",
                            "latitude": "38.74422000",
                            "longitude": "-90.30539000"
                        },
                        {
                            "id": 116502,
                            "name": "Festus",
                            "latitude": "38.22061000",
                            "longitude": "-90.39595000"
                        },
                        {
                            "id": 116550,
                            "name": "Flat River",
                            "latitude": "37.85005000",
                            "longitude": "-90.51679000"
                        },
                        {
                            "id": 116597,
                            "name": "Florissant",
                            "latitude": "38.78922000",
                            "longitude": "-90.32261000"
                        },
                        {
                            "id": 116684,
                            "name": "Forsyth",
                            "latitude": "36.68506000",
                            "longitude": "-93.11990000"
                        },
                        {
                            "id": 116725,
                            "name": "Fort Leonard Wood",
                            "latitude": "37.70573000",
                            "longitude": "-92.15717000"
                        },
                        {
                            "id": 116793,
                            "name": "Four Seasons",
                            "latitude": "38.19809000",
                            "longitude": "-92.71102000"
                        },
                        {
                            "id": 116851,
                            "name": "Franklin County",
                            "latitude": "38.41114000",
                            "longitude": "-91.07499000"
                        },
                        {
                            "id": 116894,
                            "name": "Fredericktown",
                            "latitude": "37.55978000",
                            "longitude": "-90.29401000"
                        },
                        {
                            "id": 116962,
                            "name": "Frontenac",
                            "latitude": "38.63561000",
                            "longitude": "-90.41512000"
                        },
                        {
                            "id": 116986,
                            "name": "Fulton",
                            "latitude": "38.84671000",
                            "longitude": "-91.94796000"
                        },
                        {
                            "id": 117016,
                            "name": "Gainesville",
                            "latitude": "36.60312000",
                            "longitude": "-92.42822000"
                        },
                        {
                            "id": 117023,
                            "name": "Galena",
                            "latitude": "36.80534000",
                            "longitude": "-93.46658000"
                        },
                        {
                            "id": 117032,
                            "name": "Gallatin",
                            "latitude": "39.91445000",
                            "longitude": "-93.96217000"
                        },
                        {
                            "id": 117055,
                            "name": "Garden City",
                            "latitude": "38.56112000",
                            "longitude": "-94.19133000"
                        },
                        {
                            "id": 117110,
                            "name": "Gasconade County",
                            "latitude": "38.44087000",
                            "longitude": "-91.50793000"
                        },
                        {
                            "id": 117151,
                            "name": "Gentry County",
                            "latitude": "40.21211000",
                            "longitude": "-94.40992000"
                        },
                        {
                            "id": 117168,
                            "name": "Gerald",
                            "latitude": "38.39977000",
                            "longitude": "-91.33071000"
                        },
                        {
                            "id": 117196,
                            "name": "Gideon",
                            "latitude": "36.45201000",
                            "longitude": "-89.91926000"
                        },
                        {
                            "id": 117236,
                            "name": "Gladstone",
                            "latitude": "39.20389000",
                            "longitude": "-94.55468000"
                        },
                        {
                            "id": 117247,
                            "name": "Glasgow",
                            "latitude": "39.22725000",
                            "longitude": "-92.84658000"
                        },
                        {
                            "id": 117250,
                            "name": "Glasgow Village",
                            "latitude": "38.75366000",
                            "longitude": "-90.19844000"
                        },
                        {
                            "id": 117280,
                            "name": "Glendale",
                            "latitude": "38.59589000",
                            "longitude": "-90.37706000"
                        },
                        {
                            "id": 117387,
                            "name": "Goodman",
                            "latitude": "36.74174000",
                            "longitude": "-94.39911000"
                        },
                        {
                            "id": 117423,
                            "name": "Gower",
                            "latitude": "39.61083000",
                            "longitude": "-94.59940000"
                        },
                        {
                            "id": 117440,
                            "name": "Grain Valley",
                            "latitude": "39.01501000",
                            "longitude": "-94.19856000"
                        },
                        {
                            "id": 117446,
                            "name": "Granby",
                            "latitude": "36.91923000",
                            "longitude": "-94.25522000"
                        },
                        {
                            "id": 117479,
                            "name": "Grandview",
                            "latitude": "38.88584000",
                            "longitude": "-94.53301000"
                        },
                        {
                            "id": 117506,
                            "name": "Grant City",
                            "latitude": "40.48749000",
                            "longitude": "-94.41107000"
                        },
                        {
                            "id": 117555,
                            "name": "Gray Summit",
                            "latitude": "38.48978000",
                            "longitude": "-90.81680000"
                        },
                        {
                            "id": 117605,
                            "name": "Green Park",
                            "latitude": "38.52366000",
                            "longitude": "-90.33845000"
                        },
                        {
                            "id": 117636,
                            "name": "Greene County",
                            "latitude": "37.25805000",
                            "longitude": "-93.34199000"
                        },
                        {
                            "id": 117647,
                            "name": "Greenfield",
                            "latitude": "37.41532000",
                            "longitude": "-93.84104000"
                        },
                        {
                            "id": 117685,
                            "name": "Greenville",
                            "latitude": "37.12727000",
                            "longitude": "-90.45011000"
                        },
                        {
                            "id": 117703,
                            "name": "Greenwood",
                            "latitude": "38.85168000",
                            "longitude": "-94.34384000"
                        },
                        {
                            "id": 117773,
                            "name": "Grundy County",
                            "latitude": "40.11393000",
                            "longitude": "-93.56534000"
                        },
                        {
                            "id": 117875,
                            "name": "Hallsville",
                            "latitude": "39.11699000",
                            "longitude": "-92.22074000"
                        },
                        {
                            "id": 117889,
                            "name": "Hamilton",
                            "latitude": "39.74362000",
                            "longitude": "-93.99827000"
                        },
                        {
                            "id": 117961,
                            "name": "Hanley Hills",
                            "latitude": "38.68588000",
                            "longitude": "-90.32373000"
                        },
                        {
                            "id": 117965,
                            "name": "Hannibal",
                            "latitude": "39.70838000",
                            "longitude": "-91.35848000"
                        },
                        {
                            "id": 118068,
                            "name": "Harrison County",
                            "latitude": "40.35467000",
                            "longitude": "-93.99206000"
                        },
                        {
                            "id": 118071,
                            "name": "Harrisonville",
                            "latitude": "38.65334000",
                            "longitude": "-94.34884000"
                        },
                        {
                            "id": 118106,
                            "name": "Hartville",
                            "latitude": "37.25088000",
                            "longitude": "-92.51044000"
                        },
                        {
                            "id": 118194,
                            "name": "Hayti",
                            "latitude": "36.23368000",
                            "longitude": "-89.74953000"
                        },
                        {
                            "id": 118207,
                            "name": "Hazelwood",
                            "latitude": "38.77144000",
                            "longitude": "-90.37095000"
                        },
                        {
                            "id": 118301,
                            "name": "Henry County",
                            "latitude": "38.38516000",
                            "longitude": "-93.79275000"
                        },
                        {
                            "id": 118313,
                            "name": "Herculaneum",
                            "latitude": "38.26839000",
                            "longitude": "-90.38012000"
                        },
                        {
                            "id": 118325,
                            "name": "Hermann",
                            "latitude": "38.70421000",
                            "longitude": "-91.43738000"
                        },
                        {
                            "id": 118328,
                            "name": "Hermitage",
                            "latitude": "37.94142000",
                            "longitude": "-93.31631000"
                        },
                        {
                            "id": 118366,
                            "name": "Hickory County",
                            "latitude": "37.94079000",
                            "longitude": "-93.32072000"
                        },
                        {
                            "id": 118384,
                            "name": "Higginsville",
                            "latitude": "39.07251000",
                            "longitude": "-93.71716000"
                        },
                        {
                            "id": 118388,
                            "name": "High Ridge",
                            "latitude": "38.45894000",
                            "longitude": "-90.53651000"
                        },
                        {
                            "id": 118447,
                            "name": "Hillsboro",
                            "latitude": "38.23228000",
                            "longitude": "-90.56290000"
                        },
                        {
                            "id": 118459,
                            "name": "Hillsdale",
                            "latitude": "38.68338000",
                            "longitude": "-90.28400000"
                        },
                        {
                            "id": 118522,
                            "name": "Holden",
                            "latitude": "38.71418000",
                            "longitude": "-93.99133000"
                        },
                        {
                            "id": 118551,
                            "name": "Hollister",
                            "latitude": "36.62117000",
                            "longitude": "-93.21546000"
                        },
                        {
                            "id": 118576,
                            "name": "Holt County",
                            "latitude": "40.09443000",
                            "longitude": "-95.21551000"
                        },
                        {
                            "id": 118579,
                            "name": "Holts Summit",
                            "latitude": "38.64032000",
                            "longitude": "-92.12241000"
                        },
                        {
                            "id": 118697,
                            "name": "Houston",
                            "latitude": "37.32616000",
                            "longitude": "-91.95599000"
                        },
                        {
                            "id": 118714,
                            "name": "Howard County",
                            "latitude": "39.14250000",
                            "longitude": "-92.69627000"
                        },
                        {
                            "id": 118723,
                            "name": "Howell County",
                            "latitude": "36.77401000",
                            "longitude": "-91.88654000"
                        },
                        {
                            "id": 118773,
                            "name": "Humansville",
                            "latitude": "37.79448000",
                            "longitude": "-93.57798000"
                        },
                        {
                            "id": 118816,
                            "name": "Huntsville",
                            "latitude": "39.44059000",
                            "longitude": "-92.54518000"
                        },
                        {
                            "id": 118881,
                            "name": "Imperial",
                            "latitude": "38.36978000",
                            "longitude": "-90.37845000"
                        },
                        {
                            "id": 118892,
                            "name": "Independence",
                            "latitude": "39.09112000",
                            "longitude": "-94.41551000"
                        },
                        {
                            "id": 118988,
                            "name": "Iron County",
                            "latitude": "37.50426000",
                            "longitude": "-90.69003000"
                        },
                        {
                            "id": 118997,
                            "name": "Ironton",
                            "latitude": "37.59727000",
                            "longitude": "-90.62734000"
                        },
                        {
                            "id": 119064,
                            "name": "Jackson",
                            "latitude": "37.38227000",
                            "longitude": "-89.66621000"
                        },
                        {
                            "id": 119083,
                            "name": "Jackson County",
                            "latitude": "39.00850000",
                            "longitude": "-94.34609000"
                        },
                        {
                            "id": 119147,
                            "name": "Jasper County",
                            "latitude": "37.20355000",
                            "longitude": "-94.34061000"
                        },
                        {
                            "id": 119173,
                            "name": "Jefferson City",
                            "latitude": "38.57670000",
                            "longitude": "-92.17352000"
                        },
                        {
                            "id": 119183,
                            "name": "Jefferson County",
                            "latitude": "38.26107000",
                            "longitude": "-90.53769000"
                        },
                        {
                            "id": 119219,
                            "name": "Jennings",
                            "latitude": "38.71922000",
                            "longitude": "-90.26039000"
                        },
                        {
                            "id": 119266,
                            "name": "Johnson County",
                            "latitude": "38.74409000",
                            "longitude": "-93.80637000"
                        },
                        {
                            "id": 119313,
                            "name": "Joplin",
                            "latitude": "37.08423000",
                            "longitude": "-94.51328000"
                        },
                        {
                            "id": 119353,
                            "name": "Kahoka",
                            "latitude": "40.42032000",
                            "longitude": "-91.71961000"
                        },
                        {
                            "id": 119384,
                            "name": "Kansas City",
                            "latitude": "39.09973000",
                            "longitude": "-94.57857000"
                        },
                        {
                            "id": 119407,
                            "name": "Kearney",
                            "latitude": "39.36778000",
                            "longitude": "-94.36217000"
                        },
                        {
                            "id": 119473,
                            "name": "Kennett",
                            "latitude": "36.23618000",
                            "longitude": "-90.05565000"
                        },
                        {
                            "id": 119549,
                            "name": "Keytesville",
                            "latitude": "39.43447000",
                            "longitude": "-92.93825000"
                        },
                        {
                            "id": 119568,
                            "name": "Kimberling City",
                            "latitude": "36.63340000",
                            "longitude": "-93.41685000"
                        },
                        {
                            "id": 119578,
                            "name": "King City",
                            "latitude": "40.05138000",
                            "longitude": "-94.52412000"
                        },
                        {
                            "id": 119620,
                            "name": "Kingston",
                            "latitude": "39.64417000",
                            "longitude": "-94.03855000"
                        },
                        {
                            "id": 119653,
                            "name": "Kirksville",
                            "latitude": "40.19475000",
                            "longitude": "-92.58325000"
                        },
                        {
                            "id": 119654,
                            "name": "Kirkwood",
                            "latitude": "38.58339000",
                            "longitude": "-90.40678000"
                        },
                        {
                            "id": 119657,
                            "name": "Kissee Mills",
                            "latitude": "36.68367000",
                            "longitude": "-93.04990000"
                        },
                        {
                            "id": 119678,
                            "name": "Knob Noster",
                            "latitude": "38.76668000",
                            "longitude": "-93.55855000"
                        },
                        {
                            "id": 119691,
                            "name": "Knox County",
                            "latitude": "40.12825000",
                            "longitude": "-92.14807000"
                        },
                        {
                            "id": 119768,
                            "name": "La Monte",
                            "latitude": "38.77418000",
                            "longitude": "-93.42521000"
                        },
                        {
                            "id": 119774,
                            "name": "La Plata",
                            "latitude": "40.02337000",
                            "longitude": "-92.49158000"
                        },
                        {
                            "id": 119797,
                            "name": "LaBarque Creek",
                            "latitude": "38.41701000",
                            "longitude": "-90.67989000"
                        },
                        {
                            "id": 119816,
                            "name": "Laclede County",
                            "latitude": "37.65832000",
                            "longitude": "-92.59035000"
                        },
                        {
                            "id": 119828,
                            "name": "Ladue",
                            "latitude": "38.64977000",
                            "longitude": "-90.38067000"
                        },
                        {
                            "id": 119840,
                            "name": "Lafayette County",
                            "latitude": "39.06559000",
                            "longitude": "-93.78554000"
                        },
                        {
                            "id": 119932,
                            "name": "Lake Lotawana",
                            "latitude": "38.92306000",
                            "longitude": "-94.24411000"
                        },
                        {
                            "id": 119956,
                            "name": "Lake Ozark",
                            "latitude": "38.19864000",
                            "longitude": "-92.63880000"
                        },
                        {
                            "id": 119972,
                            "name": "Lake Saint Louis",
                            "latitude": "38.79755000",
                            "longitude": "-90.78568000"
                        },
                        {
                            "id": 119994,
                            "name": "Lake Winnebago",
                            "latitude": "38.83140000",
                            "longitude": "-94.35856000"
                        },
                        {
                            "id": 120027,
                            "name": "Lakeshire",
                            "latitude": "38.53866000",
                            "longitude": "-90.33512000"
                        },
                        {
                            "id": 120059,
                            "name": "Lamar",
                            "latitude": "37.49505000",
                            "longitude": "-94.27661000"
                        },
                        {
                            "id": 120085,
                            "name": "Lancaster",
                            "latitude": "40.52086000",
                            "longitude": "-92.52797000"
                        },
                        {
                            "id": 120169,
                            "name": "Lathrop",
                            "latitude": "39.54834000",
                            "longitude": "-94.32995000"
                        },
                        {
                            "id": 120234,
                            "name": "Lawrence County",
                            "latitude": "37.10635000",
                            "longitude": "-93.83294000"
                        },
                        {
                            "id": 120247,
                            "name": "Lawson",
                            "latitude": "39.43834000",
                            "longitude": "-94.20411000"
                        },
                        {
                            "id": 120273,
                            "name": "Leadwood",
                            "latitude": "37.86727000",
                            "longitude": "-90.59318000"
                        },
                        {
                            "id": 120286,
                            "name": "Lebanon",
                            "latitude": "37.68060000",
                            "longitude": "-92.66379000"
                        },
                        {
                            "id": 120318,
                            "name": "Lee's Summit",
                            "latitude": "38.91084000",
                            "longitude": "-94.38217000"
                        },
                        {
                            "id": 120355,
                            "name": "Lemay",
                            "latitude": "38.53339000",
                            "longitude": "-90.27928000"
                        },
                        {
                            "id": 120414,
                            "name": "Lewis County",
                            "latitude": "40.09690000",
                            "longitude": "-91.72214000"
                        },
                        {
                            "id": 120440,
                            "name": "Lexington",
                            "latitude": "39.18473000",
                            "longitude": "-93.87994000"
                        },
                        {
                            "id": 120461,
                            "name": "Liberty",
                            "latitude": "39.24611000",
                            "longitude": "-94.41912000"
                        },
                        {
                            "id": 120477,
                            "name": "Licking",
                            "latitude": "37.49949000",
                            "longitude": "-91.85710000"
                        },
                        {
                            "id": 120484,
                            "name": "Lilbourn",
                            "latitude": "36.59228000",
                            "longitude": "-89.61536000"
                        },
                        {
                            "id": 120502,
                            "name": "Lincoln",
                            "latitude": "38.39086000",
                            "longitude": "-93.33465000"
                        },
                        {
                            "id": 120520,
                            "name": "Lincoln County",
                            "latitude": "39.05802000",
                            "longitude": "-90.96005000"
                        },
                        {
                            "id": 120580,
                            "name": "Linn",
                            "latitude": "38.48587000",
                            "longitude": "-91.85045000"
                        },
                        {
                            "id": 120582,
                            "name": "Linn County",
                            "latitude": "39.87021000",
                            "longitude": "-93.10718000"
                        },
                        {
                            "id": 120585,
                            "name": "Linneus",
                            "latitude": "39.87863000",
                            "longitude": "-93.18882000"
                        },
                        {
                            "id": 120655,
                            "name": "Livingston County",
                            "latitude": "39.78211000",
                            "longitude": "-93.54828000"
                        },
                        {
                            "id": 120727,
                            "name": "Lone Jack",
                            "latitude": "38.87084000",
                            "longitude": "-94.17383000"
                        },
                        {
                            "id": 120810,
                            "name": "Louisiana",
                            "latitude": "39.44894000",
                            "longitude": "-91.05153000"
                        },
                        {
                            "id": 120965,
                            "name": "Macon",
                            "latitude": "39.74226000",
                            "longitude": "-92.47269000"
                        },
                        {
                            "id": 120970,
                            "name": "Macon County",
                            "latitude": "39.83080000",
                            "longitude": "-92.56461000"
                        },
                        {
                            "id": 121008,
                            "name": "Madison County",
                            "latitude": "37.47810000",
                            "longitude": "-90.34503000"
                        },
                        {
                            "id": 121059,
                            "name": "Malden",
                            "latitude": "36.55700000",
                            "longitude": "-89.96648000"
                        },
                        {
                            "id": 121092,
                            "name": "Manchester",
                            "latitude": "38.59700000",
                            "longitude": "-90.50929000"
                        },
                        {
                            "id": 121143,
                            "name": "Mansfield",
                            "latitude": "37.10672000",
                            "longitude": "-92.58072000"
                        },
                        {
                            "id": 121177,
                            "name": "Maplewood",
                            "latitude": "38.61255000",
                            "longitude": "-90.32456000"
                        },
                        {
                            "id": 121188,
                            "name": "Marble Hill",
                            "latitude": "37.30589000",
                            "longitude": "-89.97038000"
                        },
                        {
                            "id": 121193,
                            "name": "Marceline",
                            "latitude": "39.71197000",
                            "longitude": "-92.94825000"
                        },
                        {
                            "id": 121215,
                            "name": "Maries County",
                            "latitude": "38.16163000",
                            "longitude": "-91.92489000"
                        },
                        {
                            "id": 121255,
                            "name": "Marion County",
                            "latitude": "39.80596000",
                            "longitude": "-91.62235000"
                        },
                        {
                            "id": 121263,
                            "name": "Marionville",
                            "latitude": "37.00311000",
                            "longitude": "-93.63742000"
                        },
                        {
                            "id": 121279,
                            "name": "Marlborough",
                            "latitude": "38.57033000",
                            "longitude": "-90.33706000"
                        },
                        {
                            "id": 121303,
                            "name": "Marshall",
                            "latitude": "39.12308000",
                            "longitude": "-93.19687000"
                        },
                        {
                            "id": 121325,
                            "name": "Marshfield",
                            "latitude": "37.33866000",
                            "longitude": "-92.90712000"
                        },
                        {
                            "id": 121334,
                            "name": "Marthasville",
                            "latitude": "38.62838000",
                            "longitude": "-91.05764000"
                        },
                        {
                            "id": 121356,
                            "name": "Maryland Heights",
                            "latitude": "38.71311000",
                            "longitude": "-90.42984000"
                        },
                        {
                            "id": 121365,
                            "name": "Maryville",
                            "latitude": "40.34610000",
                            "longitude": "-94.87247000"
                        },
                        {
                            "id": 121449,
                            "name": "Maysville",
                            "latitude": "39.88917000",
                            "longitude": "-94.36190000"
                        },
                        {
                            "id": 121492,
                            "name": "McDonald County",
                            "latitude": "36.62867000",
                            "longitude": "-94.34836000"
                        },
                        {
                            "id": 121616,
                            "name": "Mehlville",
                            "latitude": "38.50839000",
                            "longitude": "-90.32289000"
                        },
                        {
                            "id": 121641,
                            "name": "Memphis",
                            "latitude": "40.45781000",
                            "longitude": "-92.17129000"
                        },
                        {
                            "id": 121678,
                            "name": "Mercer County",
                            "latitude": "40.42233000",
                            "longitude": "-93.56856000"
                        },
                        {
                            "id": 121703,
                            "name": "Merriam Woods",
                            "latitude": "36.71395000",
                            "longitude": "-93.16185000"
                        },
                        {
                            "id": 121741,
                            "name": "Mexico",
                            "latitude": "39.16976000",
                            "longitude": "-91.88295000"
                        },
                        {
                            "id": 121839,
                            "name": "Milan",
                            "latitude": "40.20224000",
                            "longitude": "-93.12521000"
                        },
                        {
                            "id": 121882,
                            "name": "Miller County",
                            "latitude": "38.21453000",
                            "longitude": "-92.42841000"
                        },
                        {
                            "id": 121990,
                            "name": "Mississippi County",
                            "latitude": "36.82810000",
                            "longitude": "-89.29118000"
                        },
                        {
                            "id": 122009,
                            "name": "Moberly",
                            "latitude": "39.41837000",
                            "longitude": "-92.43824000"
                        },
                        {
                            "id": 122028,
                            "name": "Moline Acres",
                            "latitude": "38.74699000",
                            "longitude": "-90.24011000"
                        },
                        {
                            "id": 122039,
                            "name": "Monett",
                            "latitude": "36.92895000",
                            "longitude": "-93.92771000"
                        },
                        {
                            "id": 122041,
                            "name": "Moniteau County",
                            "latitude": "38.63275000",
                            "longitude": "-92.58305000"
                        },
                        {
                            "id": 122066,
                            "name": "Monroe City",
                            "latitude": "39.65365000",
                            "longitude": "-91.73461000"
                        },
                        {
                            "id": 122074,
                            "name": "Monroe County",
                            "latitude": "39.49546000",
                            "longitude": "-92.00074000"
                        },
                        {
                            "id": 122138,
                            "name": "Montgomery City",
                            "latitude": "38.97754000",
                            "longitude": "-91.50488000"
                        },
                        {
                            "id": 122146,
                            "name": "Montgomery County",
                            "latitude": "38.94149000",
                            "longitude": "-91.47021000"
                        },
                        {
                            "id": 122168,
                            "name": "Monticello",
                            "latitude": "40.11838000",
                            "longitude": "-91.71211000"
                        },
                        {
                            "id": 122241,
                            "name": "Morgan County",
                            "latitude": "38.42374000",
                            "longitude": "-92.88598000"
                        },
                        {
                            "id": 122301,
                            "name": "Moscow Mills",
                            "latitude": "38.94783000",
                            "longitude": "-90.91819000"
                        },
                        {
                            "id": 122322,
                            "name": "Mound City",
                            "latitude": "40.13111000",
                            "longitude": "-95.23164000"
                        },
                        {
                            "id": 122398,
                            "name": "Mount Vernon",
                            "latitude": "37.10367000",
                            "longitude": "-93.81854000"
                        },
                        {
                            "id": 122414,
                            "name": "Mountain Grove",
                            "latitude": "37.13061000",
                            "longitude": "-92.26349000"
                        },
                        {
                            "id": 122429,
                            "name": "Mountain View",
                            "latitude": "36.99533000",
                            "longitude": "-91.70376000"
                        },
                        {
                            "id": 122484,
                            "name": "Murphy",
                            "latitude": "38.49033000",
                            "longitude": "-90.48707000"
                        },
                        {
                            "id": 122633,
                            "name": "Neosho",
                            "latitude": "36.86896000",
                            "longitude": "-94.36800000"
                        },
                        {
                            "id": 122648,
                            "name": "Nevada",
                            "latitude": "37.83921000",
                            "longitude": "-94.35467000"
                        },
                        {
                            "id": 122706,
                            "name": "New Franklin",
                            "latitude": "39.01725000",
                            "longitude": "-92.73741000"
                        },
                        {
                            "id": 122714,
                            "name": "New Haven",
                            "latitude": "38.60838000",
                            "longitude": "-91.21904000"
                        },
                        {
                            "id": 122740,
                            "name": "New London",
                            "latitude": "39.58532000",
                            "longitude": "-91.40098000"
                        },
                        {
                            "id": 122747,
                            "name": "New Madrid",
                            "latitude": "36.58645000",
                            "longitude": "-89.52785000"
                        },
                        {
                            "id": 122748,
                            "name": "New Madrid County",
                            "latitude": "36.59465000",
                            "longitude": "-89.65183000"
                        },
                        {
                            "id": 122870,
                            "name": "Newton County",
                            "latitude": "36.90551000",
                            "longitude": "-94.33925000"
                        },
                        {
                            "id": 122912,
                            "name": "Nixa",
                            "latitude": "37.04339000",
                            "longitude": "-93.29435000"
                        },
                        {
                            "id": 122924,
                            "name": "Nodaway County",
                            "latitude": "40.36077000",
                            "longitude": "-94.88343000"
                        },
                        {
                            "id": 122926,
                            "name": "Noel",
                            "latitude": "36.54563000",
                            "longitude": "-94.48522000"
                        },
                        {
                            "id": 122951,
                            "name": "Normandy",
                            "latitude": "38.72088000",
                            "longitude": "-90.29734000"
                        },
                        {
                            "id": 123045,
                            "name": "North Kansas City",
                            "latitude": "39.13000000",
                            "longitude": "-94.56218000"
                        },
                        {
                            "id": 123181,
                            "name": "Northwoods",
                            "latitude": "38.70422000",
                            "longitude": "-90.28345000"
                        },
                        {
                            "id": 123227,
                            "name": "O'Fallon",
                            "latitude": "38.81061000",
                            "longitude": "-90.69985000"
                        },
                        {
                            "id": 123236,
                            "name": "Oak Grove",
                            "latitude": "39.00501000",
                            "longitude": "-94.12939000"
                        },
                        {
                            "id": 123283,
                            "name": "Oakland",
                            "latitude": "38.57644000",
                            "longitude": "-90.38567000"
                        },
                        {
                            "id": 123306,
                            "name": "Oakville",
                            "latitude": "38.47005000",
                            "longitude": "-90.30456000"
                        },
                        {
                            "id": 123359,
                            "name": "Odessa",
                            "latitude": "38.99917000",
                            "longitude": "-93.95356000"
                        },
                        {
                            "id": 123421,
                            "name": "Old Jamestown",
                            "latitude": "38.83494000",
                            "longitude": "-90.28511000"
                        },
                        {
                            "id": 123449,
                            "name": "Olivette",
                            "latitude": "38.66533000",
                            "longitude": "-90.37595000"
                        },
                        {
                            "id": 123504,
                            "name": "Oran",
                            "latitude": "37.08505000",
                            "longitude": "-89.65536000"
                        },
                        {
                            "id": 123545,
                            "name": "Oregon",
                            "latitude": "39.98694000",
                            "longitude": "-95.14498000"
                        },
                        {
                            "id": 123549,
                            "name": "Oregon County",
                            "latitude": "36.68672000",
                            "longitude": "-91.40329000"
                        },
                        {
                            "id": 123576,
                            "name": "Oronogo",
                            "latitude": "37.18839000",
                            "longitude": "-94.47023000"
                        },
                        {
                            "id": 123587,
                            "name": "Osage Beach",
                            "latitude": "38.12956000",
                            "longitude": "-92.65277000"
                        },
                        {
                            "id": 123590,
                            "name": "Osage County",
                            "latitude": "38.46037000",
                            "longitude": "-91.86184000"
                        },
                        {
                            "id": 123598,
                            "name": "Osceola",
                            "latitude": "38.04670000",
                            "longitude": "-93.70438000"
                        },
                        {
                            "id": 123650,
                            "name": "Overland",
                            "latitude": "38.70116000",
                            "longitude": "-90.36234000"
                        },
                        {
                            "id": 123666,
                            "name": "Owensville",
                            "latitude": "38.34560000",
                            "longitude": "-91.50155000"
                        },
                        {
                            "id": 123698,
                            "name": "Ozark",
                            "latitude": "37.02089000",
                            "longitude": "-93.20602000"
                        },
                        {
                            "id": 123699,
                            "name": "Ozark County",
                            "latitude": "36.64932000",
                            "longitude": "-92.44466000"
                        },
                        {
                            "id": 123706,
                            "name": "Pacific",
                            "latitude": "38.48200000",
                            "longitude": "-90.74152000"
                        },
                        {
                            "id": 123720,
                            "name": "Pagedale",
                            "latitude": "38.68338000",
                            "longitude": "-90.30761000"
                        },
                        {
                            "id": 123772,
                            "name": "Palmyra",
                            "latitude": "39.79421000",
                            "longitude": "-91.52321000"
                        },
                        {
                            "id": 123832,
                            "name": "Paris",
                            "latitude": "39.48087000",
                            "longitude": "-92.00128000"
                        },
                        {
                            "id": 123848,
                            "name": "Park Hills",
                            "latitude": "37.85422000",
                            "longitude": "-90.51818000"
                        },
                        {
                            "id": 123878,
                            "name": "Parkville",
                            "latitude": "39.19500000",
                            "longitude": "-94.68218000"
                        },
                        {
                            "id": 123982,
                            "name": "Peculiar",
                            "latitude": "38.71918000",
                            "longitude": "-94.45856000"
                        },
                        {
                            "id": 124010,
                            "name": "Pemiscot County",
                            "latitude": "36.21145000",
                            "longitude": "-89.78538000"
                        },
                        {
                            "id": 124079,
                            "name": "Perry County",
                            "latitude": "37.70714000",
                            "longitude": "-89.82441000"
                        },
                        {
                            "id": 124089,
                            "name": "Perryville",
                            "latitude": "37.72422000",
                            "longitude": "-89.86122000"
                        },
                        {
                            "id": 124113,
                            "name": "Pettis County",
                            "latitude": "38.72829000",
                            "longitude": "-93.28510000"
                        },
                        {
                            "id": 124114,
                            "name": "Pevely",
                            "latitude": "38.28339000",
                            "longitude": "-90.39512000"
                        },
                        {
                            "id": 124121,
                            "name": "Phelps County",
                            "latitude": "37.87713000",
                            "longitude": "-91.79236000"
                        },
                        {
                            "id": 124165,
                            "name": "Piedmont",
                            "latitude": "37.15449000",
                            "longitude": "-90.69567000"
                        },
                        {
                            "id": 124170,
                            "name": "Pierce City",
                            "latitude": "36.94590000",
                            "longitude": "-94.00021000"
                        },
                        {
                            "id": 124191,
                            "name": "Pike County",
                            "latitude": "39.34384000",
                            "longitude": "-91.17136000"
                        },
                        {
                            "id": 124234,
                            "name": "Pine Lawn",
                            "latitude": "38.69588000",
                            "longitude": "-90.27511000"
                        },
                        {
                            "id": 124261,
                            "name": "Pineville",
                            "latitude": "36.59452000",
                            "longitude": "-94.38410000"
                        },
                        {
                            "id": 124352,
                            "name": "Platte City",
                            "latitude": "39.37028000",
                            "longitude": "-94.78246000"
                        },
                        {
                            "id": 124353,
                            "name": "Platte County",
                            "latitude": "39.38050000",
                            "longitude": "-94.77359000"
                        },
                        {
                            "id": 124359,
                            "name": "Plattsburg",
                            "latitude": "39.56555000",
                            "longitude": "-94.44801000"
                        },
                        {
                            "id": 124368,
                            "name": "Pleasant Hill",
                            "latitude": "38.78751000",
                            "longitude": "-94.26939000"
                        },
                        {
                            "id": 124377,
                            "name": "Pleasant Valley",
                            "latitude": "39.21639000",
                            "longitude": "-94.48412000"
                        },
                        {
                            "id": 124443,
                            "name": "Polk County",
                            "latitude": "37.61648000",
                            "longitude": "-93.40053000"
                        },
                        {
                            "id": 124484,
                            "name": "Poplar Bluff",
                            "latitude": "36.75700000",
                            "longitude": "-90.39289000"
                        },
                        {
                            "id": 124549,
                            "name": "Portageville",
                            "latitude": "36.42534000",
                            "longitude": "-89.69953000"
                        },
                        {
                            "id": 124586,
                            "name": "Potosi",
                            "latitude": "37.93644000",
                            "longitude": "-90.78791000"
                        },
                        {
                            "id": 124690,
                            "name": "Princeton",
                            "latitude": "40.40084000",
                            "longitude": "-93.58050000"
                        },
                        {
                            "id": 124741,
                            "name": "Pulaski County",
                            "latitude": "37.82463000",
                            "longitude": "-92.20766000"
                        },
                        {
                            "id": 124756,
                            "name": "Purdy",
                            "latitude": "36.81729000",
                            "longitude": "-93.92076000"
                        },
                        {
                            "id": 124767,
                            "name": "Putnam County",
                            "latitude": "40.47891000",
                            "longitude": "-93.01613000"
                        },
                        {
                            "id": 124835,
                            "name": "Ralls County",
                            "latitude": "39.52767000",
                            "longitude": "-91.52202000"
                        },
                        {
                            "id": 124876,
                            "name": "Randolph County",
                            "latitude": "39.44017000",
                            "longitude": "-92.49708000"
                        },
                        {
                            "id": 124916,
                            "name": "Ray County",
                            "latitude": "39.35241000",
                            "longitude": "-93.98988000"
                        },
                        {
                            "id": 124922,
                            "name": "Raymore",
                            "latitude": "38.80195000",
                            "longitude": "-94.45273000"
                        },
                        {
                            "id": 124926,
                            "name": "Raytown",
                            "latitude": "39.00862000",
                            "longitude": "-94.46356000"
                        },
                        {
                            "id": 125030,
                            "name": "Republic",
                            "latitude": "37.12005000",
                            "longitude": "-93.48019000"
                        },
                        {
                            "id": 125040,
                            "name": "Reynolds County",
                            "latitude": "37.36233000",
                            "longitude": "-90.96908000"
                        },
                        {
                            "id": 125057,
                            "name": "Rich Hill",
                            "latitude": "38.09642000",
                            "longitude": "-94.36106000"
                        },
                        {
                            "id": 125068,
                            "name": "Richland",
                            "latitude": "37.85698000",
                            "longitude": "-92.40434000"
                        },
                        {
                            "id": 125086,
                            "name": "Richmond",
                            "latitude": "39.27862000",
                            "longitude": "-93.97689000"
                        },
                        {
                            "id": 125103,
                            "name": "Richmond Heights",
                            "latitude": "38.62866000",
                            "longitude": "-90.31956000"
                        },
                        {
                            "id": 125166,
                            "name": "Ripley County",
                            "latitude": "36.65282000",
                            "longitude": "-90.86388000"
                        },
                        {
                            "id": 125203,
                            "name": "Riverside",
                            "latitude": "39.17750000",
                            "longitude": "-94.61301000"
                        },
                        {
                            "id": 125218,
                            "name": "Riverview",
                            "latitude": "38.74783000",
                            "longitude": "-90.21150000"
                        },
                        {
                            "id": 125279,
                            "name": "Rock Hill",
                            "latitude": "38.60755000",
                            "longitude": "-90.37845000"
                        },
                        {
                            "id": 125285,
                            "name": "Rock Port",
                            "latitude": "40.41111000",
                            "longitude": "-95.51693000"
                        },
                        {
                            "id": 125355,
                            "name": "Rogersville",
                            "latitude": "37.11700000",
                            "longitude": "-93.05573000"
                        },
                        {
                            "id": 125363,
                            "name": "Rolla",
                            "latitude": "37.95143000",
                            "longitude": "-91.77127000"
                        },
                        {
                            "id": 125598,
                            "name": "Saint Ann",
                            "latitude": "38.72727000",
                            "longitude": "-90.38317000"
                        },
                        {
                            "id": 125612,
                            "name": "Saint Charles",
                            "latitude": "38.78394000",
                            "longitude": "-90.48123000"
                        },
                        {
                            "id": 125616,
                            "name": "Saint Charles County",
                            "latitude": "38.78192000",
                            "longitude": "-90.67490000"
                        },
                        {
                            "id": 125618,
                            "name": "Saint Clair",
                            "latitude": "38.34533000",
                            "longitude": "-90.98097000"
                        },
                        {
                            "id": 125623,
                            "name": "Saint Clair County",
                            "latitude": "38.03718000",
                            "longitude": "-93.77598000"
                        },
                        {
                            "id": 125638,
                            "name": "Saint Francois County",
                            "latitude": "37.81028000",
                            "longitude": "-90.47227000"
                        },
                        {
                            "id": 125641,
                            "name": "Saint George",
                            "latitude": "38.53672000",
                            "longitude": "-90.31484000"
                        },
                        {
                            "id": 125653,
                            "name": "Saint James",
                            "latitude": "37.99726000",
                            "longitude": "-91.61432000"
                        },
                        {
                            "id": 125661,
                            "name": "Saint John",
                            "latitude": "38.71484000",
                            "longitude": "-90.34627000"
                        },
                        {
                            "id": 125664,
                            "name": "Saint Johns",
                            "latitude": "38.71338000",
                            "longitude": "-90.34317000"
                        },
                        {
                            "id": 125671,
                            "name": "Saint Joseph",
                            "latitude": "39.76861000",
                            "longitude": "-94.84663000"
                        },
                        {
                            "id": 125681,
                            "name": "Saint Louis County",
                            "latitude": "38.64068000",
                            "longitude": "-90.44341000"
                        },
                        {
                            "id": 125688,
                            "name": "Saint Martins",
                            "latitude": "38.59420000",
                            "longitude": "-92.33713000"
                        },
                        {
                            "id": 125701,
                            "name": "Saint Paul",
                            "latitude": "38.86144000",
                            "longitude": "-90.74179000"
                        },
                        {
                            "id": 125710,
                            "name": "Saint Peters",
                            "latitude": "38.80033000",
                            "longitude": "-90.62651000"
                        },
                        {
                            "id": 125712,
                            "name": "Saint Robert",
                            "latitude": "37.82810000",
                            "longitude": "-92.17767000"
                        },
                        {
                            "id": 125719,
                            "name": "Sainte Genevieve",
                            "latitude": "37.98144000",
                            "longitude": "-90.04178000"
                        },
                        {
                            "id": 125720,
                            "name": "Sainte Genevieve County",
                            "latitude": "37.89440000",
                            "longitude": "-90.19442000"
                        },
                        {
                            "id": 125730,
                            "name": "Salem",
                            "latitude": "37.64560000",
                            "longitude": "-91.53598000"
                        },
                        {
                            "id": 125752,
                            "name": "Saline County",
                            "latitude": "39.13684000",
                            "longitude": "-93.20185000"
                        },
                        {
                            "id": 125755,
                            "name": "Salisbury",
                            "latitude": "39.42392000",
                            "longitude": "-92.80158000"
                        },
                        {
                            "id": 125932,
                            "name": "Sappington",
                            "latitude": "38.53700000",
                            "longitude": "-90.37984000"
                        },
                        {
                            "id": 125946,
                            "name": "Sarcoxie",
                            "latitude": "37.06923000",
                            "longitude": "-94.11660000"
                        },
                        {
                            "id": 125975,
                            "name": "Savannah",
                            "latitude": "39.94166000",
                            "longitude": "-94.83025000"
                        },
                        {
                            "id": 126023,
                            "name": "Schuyler County",
                            "latitude": "40.47027000",
                            "longitude": "-92.52094000"
                        },
                        {
                            "id": 126037,
                            "name": "Scotland County",
                            "latitude": "40.45260000",
                            "longitude": "-92.14705000"
                        },
                        {
                            "id": 126041,
                            "name": "Scott City",
                            "latitude": "37.21672000",
                            "longitude": "-89.52453000"
                        },
                        {
                            "id": 126047,
                            "name": "Scott County",
                            "latitude": "37.05305000",
                            "longitude": "-89.56851000"
                        },
                        {
                            "id": 126114,
                            "name": "Sedalia",
                            "latitude": "38.70446000",
                            "longitude": "-93.22826000"
                        },
                        {
                            "id": 126152,
                            "name": "Senath",
                            "latitude": "36.13423000",
                            "longitude": "-90.15982000"
                        },
                        {
                            "id": 126155,
                            "name": "Seneca",
                            "latitude": "36.84146000",
                            "longitude": "-94.61106000"
                        },
                        {
                            "id": 126193,
                            "name": "Seymour",
                            "latitude": "37.14644000",
                            "longitude": "-92.76878000"
                        },
                        {
                            "id": 126220,
                            "name": "Shannon County",
                            "latitude": "37.15739000",
                            "longitude": "-91.40051000"
                        },
                        {
                            "id": 126263,
                            "name": "Shelbina",
                            "latitude": "39.69393000",
                            "longitude": "-92.04295000"
                        },
                        {
                            "id": 126276,
                            "name": "Shelby County",
                            "latitude": "39.79778000",
                            "longitude": "-92.07662000"
                        },
                        {
                            "id": 126283,
                            "name": "Shelbyville",
                            "latitude": "39.80587000",
                            "longitude": "-92.04156000"
                        },
                        {
                            "id": 126289,
                            "name": "Shell Knob",
                            "latitude": "36.63229000",
                            "longitude": "-93.63436000"
                        },
                        {
                            "id": 126383,
                            "name": "Shrewsbury",
                            "latitude": "38.59033000",
                            "longitude": "-90.33678000"
                        },
                        {
                            "id": 126414,
                            "name": "Sikeston",
                            "latitude": "36.87672000",
                            "longitude": "-89.58786000"
                        },
                        {
                            "id": 126489,
                            "name": "Slater",
                            "latitude": "39.21808000",
                            "longitude": "-93.06909000"
                        },
                        {
                            "id": 126528,
                            "name": "Smithville",
                            "latitude": "39.38694000",
                            "longitude": "-94.58107000"
                        },
                        {
                            "id": 126800,
                            "name": "Spanish Lake",
                            "latitude": "38.78783000",
                            "longitude": "-90.21594000"
                        },
                        {
                            "id": 126809,
                            "name": "Sparta",
                            "latitude": "37.00116000",
                            "longitude": "-93.08157000"
                        },
                        {
                            "id": 126893,
                            "name": "Springfield",
                            "latitude": "37.21533000",
                            "longitude": "-93.29824000"
                        },
                        {
                            "id": 126928,
                            "name": "St. Louis",
                            "latitude": "38.62727000",
                            "longitude": "-90.19789000"
                        },
                        {
                            "id": 126949,
                            "name": "Stanberry",
                            "latitude": "40.21777000",
                            "longitude": "-94.53829000"
                        },
                        {
                            "id": 127008,
                            "name": "Steele",
                            "latitude": "36.08396000",
                            "longitude": "-89.82925000"
                        },
                        {
                            "id": 127014,
                            "name": "Steelville",
                            "latitude": "37.96810000",
                            "longitude": "-91.35487000"
                        },
                        {
                            "id": 127072,
                            "name": "Stockton",
                            "latitude": "37.69893000",
                            "longitude": "-93.79604000"
                        },
                        {
                            "id": 127077,
                            "name": "Stoddard County",
                            "latitude": "36.85562000",
                            "longitude": "-89.94431000"
                        },
                        {
                            "id": 127081,
                            "name": "Stone County",
                            "latitude": "36.74694000",
                            "longitude": "-93.45600000"
                        },
                        {
                            "id": 127111,
                            "name": "Stover",
                            "latitude": "38.44086000",
                            "longitude": "-92.99187000"
                        },
                        {
                            "id": 127116,
                            "name": "Strafford",
                            "latitude": "37.26838000",
                            "longitude": "-93.11713000"
                        },
                        {
                            "id": 127170,
                            "name": "Sugar Creek",
                            "latitude": "39.10973000",
                            "longitude": "-94.44467000"
                        },
                        {
                            "id": 127183,
                            "name": "Sullivan",
                            "latitude": "38.20810000",
                            "longitude": "-91.16042000"
                        },
                        {
                            "id": 127188,
                            "name": "Sullivan County",
                            "latitude": "40.21064000",
                            "longitude": "-93.11152000"
                        },
                        {
                            "id": 127275,
                            "name": "Sunset Hills",
                            "latitude": "38.53894000",
                            "longitude": "-90.40734000"
                        },
                        {
                            "id": 127338,
                            "name": "Sweet Springs",
                            "latitude": "38.96363000",
                            "longitude": "-93.41493000"
                        },
                        {
                            "id": 127410,
                            "name": "Taney County",
                            "latitude": "36.65473000",
                            "longitude": "-93.04111000"
                        },
                        {
                            "id": 127421,
                            "name": "Taos",
                            "latitude": "38.50643000",
                            "longitude": "-92.07101000"
                        },
                        {
                            "id": 127431,
                            "name": "Tarkio",
                            "latitude": "40.44028000",
                            "longitude": "-95.37776000"
                        },
                        {
                            "id": 127523,
                            "name": "Terre du Lac",
                            "latitude": "37.91172000",
                            "longitude": "-90.62541000"
                        },
                        {
                            "id": 127521,
                            "name": "Terre Haute",
                            "latitude": "40.43946000",
                            "longitude": "-93.23410000"
                        },
                        {
                            "id": 127547,
                            "name": "Texas County",
                            "latitude": "37.31731000",
                            "longitude": "-91.96505000"
                        },
                        {
                            "id": 127551,
                            "name": "Thayer",
                            "latitude": "36.52451000",
                            "longitude": "-91.53820000"
                        },
                        {
                            "id": 127674,
                            "name": "Tipton",
                            "latitude": "38.65558000",
                            "longitude": "-92.77992000"
                        },
                        {
                            "id": 127759,
                            "name": "Town and Country",
                            "latitude": "38.61228000",
                            "longitude": "-90.46345000"
                        },
                        {
                            "id": 127809,
                            "name": "Trenton",
                            "latitude": "40.07890000",
                            "longitude": "-93.61661000"
                        },
                        {
                            "id": 127844,
                            "name": "Troy",
                            "latitude": "38.97949000",
                            "longitude": "-90.98070000"
                        },
                        {
                            "id": 127915,
                            "name": "Tuscumbia",
                            "latitude": "38.23309000",
                            "longitude": "-92.45852000"
                        },
                        {
                            "id": 127973,
                            "name": "Union",
                            "latitude": "38.45005000",
                            "longitude": "-91.00848000"
                        },
                        {
                            "id": 128020,
                            "name": "Unionville",
                            "latitude": "40.47696000",
                            "longitude": "-93.00326000"
                        },
                        {
                            "id": 128027,
                            "name": "University City",
                            "latitude": "38.65588000",
                            "longitude": "-90.30928000"
                        },
                        {
                            "id": 128114,
                            "name": "Valley Park",
                            "latitude": "38.54922000",
                            "longitude": "-90.49262000"
                        },
                        {
                            "id": 128127,
                            "name": "Van Buren",
                            "latitude": "36.99561000",
                            "longitude": "-91.01457000"
                        },
                        {
                            "id": 128146,
                            "name": "Vandalia",
                            "latitude": "39.31087000",
                            "longitude": "-91.48849000"
                        },
                        {
                            "id": 128163,
                            "name": "Velda Village",
                            "latitude": "38.69005000",
                            "longitude": "-90.29428000"
                        },
                        {
                            "id": 128164,
                            "name": "Velda Village Hills",
                            "latitude": "38.69061000",
                            "longitude": "-90.28734000"
                        },
                        {
                            "id": 128192,
                            "name": "Vernon County",
                            "latitude": "37.85058000",
                            "longitude": "-94.34244000"
                        },
                        {
                            "id": 128210,
                            "name": "Versailles",
                            "latitude": "38.43141000",
                            "longitude": "-92.84103000"
                        },
                        {
                            "id": 128232,
                            "name": "Vienna",
                            "latitude": "38.18671000",
                            "longitude": "-91.94711000"
                        },
                        {
                            "id": 128246,
                            "name": "Villa Ridge",
                            "latitude": "38.47255000",
                            "longitude": "-90.88680000"
                        },
                        {
                            "id": 128278,
                            "name": "Vinita Park",
                            "latitude": "38.69005000",
                            "longitude": "-90.34262000"
                        },
                        {
                            "id": 128461,
                            "name": "Wardsville",
                            "latitude": "38.48892000",
                            "longitude": "-92.17435000"
                        },
                        {
                            "id": 128488,
                            "name": "Warren County",
                            "latitude": "38.76462000",
                            "longitude": "-91.16069000"
                        },
                        {
                            "id": 128502,
                            "name": "Warrensburg",
                            "latitude": "38.76279000",
                            "longitude": "-93.73605000"
                        },
                        {
                            "id": 128505,
                            "name": "Warrenton",
                            "latitude": "38.81144000",
                            "longitude": "-91.14154000"
                        },
                        {
                            "id": 128517,
                            "name": "Warsaw",
                            "latitude": "38.24308000",
                            "longitude": "-93.38187000"
                        },
                        {
                            "id": 128522,
                            "name": "Warson Woods",
                            "latitude": "38.60727000",
                            "longitude": "-90.38345000"
                        },
                        {
                            "id": 128540,
                            "name": "Washington",
                            "latitude": "38.55811000",
                            "longitude": "-91.01209000"
                        },
                        {
                            "id": 128561,
                            "name": "Washington County",
                            "latitude": "37.96168000",
                            "longitude": "-90.87742000"
                        },
                        {
                            "id": 128693,
                            "name": "Wayne County",
                            "latitude": "37.11264000",
                            "longitude": "-90.46143000"
                        },
                        {
                            "id": 128712,
                            "name": "Waynesville",
                            "latitude": "37.82865000",
                            "longitude": "-92.20072000"
                        },
                        {
                            "id": 128716,
                            "name": "Weatherby Lake",
                            "latitude": "39.23778000",
                            "longitude": "-94.69607000"
                        },
                        {
                            "id": 128725,
                            "name": "Webb City",
                            "latitude": "37.14645000",
                            "longitude": "-94.46300000"
                        },
                        {
                            "id": 128738,
                            "name": "Webster County",
                            "latitude": "37.28091000",
                            "longitude": "-92.87588000"
                        },
                        {
                            "id": 128743,
                            "name": "Webster Groves",
                            "latitude": "38.59255000",
                            "longitude": "-90.35734000"
                        },
                        {
                            "id": 128771,
                            "name": "Weldon Spring",
                            "latitude": "38.71339000",
                            "longitude": "-90.68929000"
                        },
                        {
                            "id": 128790,
                            "name": "Wellston",
                            "latitude": "38.67283000",
                            "longitude": "-90.29928000"
                        },
                        {
                            "id": 128792,
                            "name": "Wellsville",
                            "latitude": "39.07198000",
                            "longitude": "-91.57016000"
                        },
                        {
                            "id": 128805,
                            "name": "Wentzville",
                            "latitude": "38.81144000",
                            "longitude": "-90.85291000"
                        },
                        {
                            "id": 128956,
                            "name": "West Plains",
                            "latitude": "36.72812000",
                            "longitude": "-91.85237000"
                        },
                        {
                            "id": 129059,
                            "name": "Weston",
                            "latitude": "39.41111000",
                            "longitude": "-94.90163000"
                        },
                        {
                            "id": 129177,
                            "name": "Whiteman Air Force Base",
                            "latitude": "38.73018000",
                            "longitude": "-93.55895000"
                        },
                        {
                            "id": 129237,
                            "name": "Wildwood",
                            "latitude": "38.58283000",
                            "longitude": "-90.66290000"
                        },
                        {
                            "id": 129257,
                            "name": "Willard",
                            "latitude": "37.30505000",
                            "longitude": "-93.42853000"
                        },
                        {
                            "id": 129309,
                            "name": "Willow Springs",
                            "latitude": "36.99228000",
                            "longitude": "-91.96987000"
                        },
                        {
                            "id": 129354,
                            "name": "Winchester",
                            "latitude": "38.59033000",
                            "longitude": "-90.52790000"
                        },
                        {
                            "id": 129379,
                            "name": "Windsor",
                            "latitude": "38.53224000",
                            "longitude": "-93.52215000"
                        },
                        {
                            "id": 129394,
                            "name": "Winfield",
                            "latitude": "38.99727000",
                            "longitude": "-90.73846000"
                        },
                        {
                            "id": 129423,
                            "name": "Winona",
                            "latitude": "37.00977000",
                            "longitude": "-91.32347000"
                        },
                        {
                            "id": 129559,
                            "name": "Woodson Terrace",
                            "latitude": "38.72505000",
                            "longitude": "-90.35845000"
                        },
                        {
                            "id": 129594,
                            "name": "Worth County",
                            "latitude": "40.47914000",
                            "longitude": "-94.42210000"
                        },
                        {
                            "id": 129607,
                            "name": "Wright City",
                            "latitude": "38.82755000",
                            "longitude": "-91.02014000"
                        },
                        {
                            "id": 129608,
                            "name": "Wright County",
                            "latitude": "37.27014000",
                            "longitude": "-92.46870000"
                        }
                    ]
                },
                {
                    "id": 1446,
                    "name": "Montana",
                    "state_code": "MT",
                    "cities": [
                        {
                            "id": 110989,
                            "name": "Absarokee",
                            "latitude": "45.52050000",
                            "longitude": "-109.44294000"
                        },
                        {
                            "id": 111296,
                            "name": "Anaconda",
                            "latitude": "46.12854000",
                            "longitude": "-112.94226000"
                        },
                        {
                            "id": 111739,
                            "name": "Baker",
                            "latitude": "46.36695000",
                            "longitude": "-104.28466000"
                        },
                        {
                            "id": 112008,
                            "name": "Beaverhead County",
                            "latitude": "45.13273000",
                            "longitude": "-112.89889000"
                        },
                        {
                            "id": 112062,
                            "name": "Belgrade",
                            "latitude": "45.77604000",
                            "longitude": "-111.17690000"
                        },
                        {
                            "id": 112321,
                            "name": "Big Horn County",
                            "latitude": "45.42346000",
                            "longitude": "-107.48970000"
                        },
                        {
                            "id": 112334,
                            "name": "Big Sky",
                            "latitude": "45.28465000",
                            "longitude": "-111.36829000"
                        },
                        {
                            "id": 112338,
                            "name": "Big Timber",
                            "latitude": "45.83494000",
                            "longitude": "-109.95546000"
                        },
                        {
                            "id": 112339,
                            "name": "Bigfork",
                            "latitude": "48.06329000",
                            "longitude": "-114.07261000"
                        },
                        {
                            "id": 112344,
                            "name": "Billings",
                            "latitude": "45.78329000",
                            "longitude": "-108.50069000"
                        },
                        {
                            "id": 112408,
                            "name": "Blaine County",
                            "latitude": "48.43276000",
                            "longitude": "-108.95866000"
                        },
                        {
                            "id": 112544,
                            "name": "Bonner-West Riverside",
                            "latitude": "46.87669000",
                            "longitude": "-113.88678000"
                        },
                        {
                            "id": 112601,
                            "name": "Boulder",
                            "latitude": "46.23659000",
                            "longitude": "-112.12083000"
                        },
                        {
                            "id": 112653,
                            "name": "Bozeman",
                            "latitude": "45.67965000",
                            "longitude": "-111.03856000"
                        },
                        {
                            "id": 112826,
                            "name": "Broadus",
                            "latitude": "45.44387000",
                            "longitude": "-105.41133000"
                        },
                        {
                            "id": 112829,
                            "name": "Broadwater County",
                            "latitude": "46.33199000",
                            "longitude": "-111.49547000"
                        },
                        {
                            "id": 112918,
                            "name": "Browning",
                            "latitude": "48.55692000",
                            "longitude": "-113.01342000"
                        },
                        {
                            "id": 113123,
                            "name": "Butte",
                            "latitude": "46.00382000",
                            "longitude": "-112.53474000"
                        },
                        {
                            "id": 113128,
                            "name": "Butte-Silver Bow (Balance)",
                            "latitude": "45.90194000",
                            "longitude": "-112.65708000"
                        },
                        {
                            "id": 113384,
                            "name": "Carbon County",
                            "latitude": "45.22737000",
                            "longitude": "-109.02832000"
                        },
                        {
                            "id": 113484,
                            "name": "Carter County",
                            "latitude": "45.51677000",
                            "longitude": "-104.53616000"
                        },
                        {
                            "id": 113515,
                            "name": "Cascade County",
                            "latitude": "47.30802000",
                            "longitude": "-111.34715000"
                        },
                        {
                            "id": 113893,
                            "name": "Chester",
                            "latitude": "48.51054000",
                            "longitude": "-110.96747000"
                        },
                        {
                            "id": 113971,
                            "name": "Chinook",
                            "latitude": "48.59000000",
                            "longitude": "-109.23128000"
                        },
                        {
                            "id": 113989,
                            "name": "Choteau",
                            "latitude": "47.81245000",
                            "longitude": "-112.18363000"
                        },
                        {
                            "id": 113991,
                            "name": "Chouteau County",
                            "latitude": "47.88056000",
                            "longitude": "-110.43520000"
                        },
                        {
                            "id": 114028,
                            "name": "Circle",
                            "latitude": "47.41667000",
                            "longitude": "-105.59222000"
                        },
                        {
                            "id": 114098,
                            "name": "Clancy",
                            "latitude": "46.46521000",
                            "longitude": "-111.98638000"
                        },
                        {
                            "id": 114287,
                            "name": "Clinton",
                            "latitude": "46.76909000",
                            "longitude": "-113.71260000"
                        },
                        {
                            "id": 114462,
                            "name": "Colstrip",
                            "latitude": "45.88416000",
                            "longitude": "-106.62364000"
                        },
                        {
                            "id": 114485,
                            "name": "Columbia Falls",
                            "latitude": "48.37246000",
                            "longitude": "-114.18152000"
                        },
                        {
                            "id": 114499,
                            "name": "Columbus",
                            "latitude": "45.63661000",
                            "longitude": "-109.25211000"
                        },
                        {
                            "id": 114569,
                            "name": "Conrad",
                            "latitude": "48.17025000",
                            "longitude": "-111.94613000"
                        },
                        {
                            "id": 114860,
                            "name": "Crow Agency",
                            "latitude": "45.60164000",
                            "longitude": "-107.46119000"
                        },
                        {
                            "id": 114939,
                            "name": "Custer County",
                            "latitude": "46.25270000",
                            "longitude": "-105.57178000"
                        },
                        {
                            "id": 114941,
                            "name": "Cut Bank",
                            "latitude": "48.63304000",
                            "longitude": "-112.32616000"
                        },
                        {
                            "id": 115024,
                            "name": "Daniels County",
                            "latitude": "48.78381000",
                            "longitude": "-105.54857000"
                        },
                        {
                            "id": 115086,
                            "name": "Dawson County",
                            "latitude": "47.26638000",
                            "longitude": "-104.89946000"
                        },
                        {
                            "id": 115173,
                            "name": "Deer Lodge",
                            "latitude": "46.39576000",
                            "longitude": "-112.73004000"
                        },
                        {
                            "id": 115174,
                            "name": "Deer Lodge County",
                            "latitude": "46.06079000",
                            "longitude": "-113.06775000"
                        },
                        {
                            "id": 115343,
                            "name": "Dillon",
                            "latitude": "45.21631000",
                            "longitude": "-112.63752000"
                        },
                        {
                            "id": 115688,
                            "name": "East Helena",
                            "latitude": "46.58966000",
                            "longitude": "-111.91555000"
                        },
                        {
                            "id": 115715,
                            "name": "East Missoula",
                            "latitude": "46.87076000",
                            "longitude": "-113.94455000"
                        },
                        {
                            "id": 115910,
                            "name": "Ekalaka",
                            "latitude": "45.88889000",
                            "longitude": "-104.55273000"
                        },
                        {
                            "id": 116236,
                            "name": "Eureka",
                            "latitude": "48.87996000",
                            "longitude": "-115.05350000"
                        },
                        {
                            "id": 116264,
                            "name": "Evergreen",
                            "latitude": "48.22579000",
                            "longitude": "-114.27624000"
                        },
                        {
                            "id": 116379,
                            "name": "Fallon County",
                            "latitude": "46.33402000",
                            "longitude": "-104.41742000"
                        },
                        {
                            "id": 116478,
                            "name": "Fergus County",
                            "latitude": "47.26357000",
                            "longitude": "-109.22433000"
                        },
                        {
                            "id": 116554,
                            "name": "Flathead County",
                            "latitude": "48.29516000",
                            "longitude": "-114.04981000"
                        },
                        {
                            "id": 116685,
                            "name": "Forsyth",
                            "latitude": "46.26638000",
                            "longitude": "-106.67781000"
                        },
                        {
                            "id": 116690,
                            "name": "Fort Belknap Agency",
                            "latitude": "48.48250000",
                            "longitude": "-108.76544000"
                        },
                        {
                            "id": 116693,
                            "name": "Fort Benton",
                            "latitude": "47.81830000",
                            "longitude": "-110.66744000"
                        },
                        {
                            "id": 116790,
                            "name": "Four Corners",
                            "latitude": "45.62965000",
                            "longitude": "-111.18606000"
                        },
                        {
                            "id": 116935,
                            "name": "Frenchtown",
                            "latitude": "47.01492000",
                            "longitude": "-114.22984000"
                        },
                        {
                            "id": 117036,
                            "name": "Gallatin County",
                            "latitude": "45.54049000",
                            "longitude": "-111.17035000"
                        },
                        {
                            "id": 117086,
                            "name": "Garfield County",
                            "latitude": "47.27770000",
                            "longitude": "-106.99283000"
                        },
                        {
                            "id": 117231,
                            "name": "Glacier County",
                            "latitude": "48.70508000",
                            "longitude": "-112.99475000"
                        },
                        {
                            "id": 117249,
                            "name": "Glasgow",
                            "latitude": "48.19696000",
                            "longitude": "-106.63671000"
                        },
                        {
                            "id": 117289,
                            "name": "Glendive",
                            "latitude": "47.10529000",
                            "longitude": "-104.71246000"
                        },
                        {
                            "id": 117358,
                            "name": "Golden Valley County",
                            "latitude": "46.38126000",
                            "longitude": "-109.17494000"
                        },
                        {
                            "id": 117495,
                            "name": "Granite County",
                            "latitude": "46.40444000",
                            "longitude": "-113.44026000"
                        },
                        {
                            "id": 117573,
                            "name": "Great Falls",
                            "latitude": "47.50024000",
                            "longitude": "-111.30081000"
                        },
                        {
                            "id": 117894,
                            "name": "Hamilton",
                            "latitude": "46.24687000",
                            "longitude": "-114.16037000"
                        },
                        {
                            "id": 117998,
                            "name": "Hardin",
                            "latitude": "45.73248000",
                            "longitude": "-107.61203000"
                        },
                        {
                            "id": 118027,
                            "name": "Harlowton",
                            "latitude": "46.43551000",
                            "longitude": "-109.83435000"
                        },
                        {
                            "id": 118154,
                            "name": "Havre",
                            "latitude": "48.55000000",
                            "longitude": "-109.68409000"
                        },
                        {
                            "id": 118251,
                            "name": "Helena",
                            "latitude": "46.59271000",
                            "longitude": "-112.03611000"
                        },
                        {
                            "id": 118252,
                            "name": "Helena Valley Northeast",
                            "latitude": "46.69882000",
                            "longitude": "-111.95207000"
                        },
                        {
                            "id": 118253,
                            "name": "Helena Valley Northwest",
                            "latitude": "46.72894000",
                            "longitude": "-112.06275000"
                        },
                        {
                            "id": 118254,
                            "name": "Helena Valley Southeast",
                            "latitude": "46.61527000",
                            "longitude": "-111.92156000"
                        },
                        {
                            "id": 118255,
                            "name": "Helena Valley West Central",
                            "latitude": "46.66291000",
                            "longitude": "-112.06044000"
                        },
                        {
                            "id": 118256,
                            "name": "Helena West Side",
                            "latitude": "46.59672000",
                            "longitude": "-112.11304000"
                        },
                        {
                            "id": 118437,
                            "name": "Hill County",
                            "latitude": "48.62823000",
                            "longitude": "-110.11131000"
                        },
                        {
                            "id": 118858,
                            "name": "Hysham",
                            "latitude": "46.29277000",
                            "longitude": "-107.23423000"
                        },
                        {
                            "id": 119196,
                            "name": "Jefferson County",
                            "latitude": "46.14849000",
                            "longitude": "-112.09374000"
                        },
                        {
                            "id": 119317,
                            "name": "Jordan",
                            "latitude": "47.32083000",
                            "longitude": "-106.91007000"
                        },
                        {
                            "id": 119326,
                            "name": "Judith Basin County",
                            "latitude": "47.04546000",
                            "longitude": "-110.26607000"
                        },
                        {
                            "id": 119365,
                            "name": "Kalispell",
                            "latitude": "48.19579000",
                            "longitude": "-114.31291000"
                        },
                        {
                            "id": 119898,
                            "name": "Lake County",
                            "latitude": "47.64594000",
                            "longitude": "-114.08935000"
                        },
                        {
                            "id": 120033,
                            "name": "Lakeside",
                            "latitude": "48.01939000",
                            "longitude": "-114.22457000"
                        },
                        {
                            "id": 120070,
                            "name": "Lame Deer",
                            "latitude": "45.62305000",
                            "longitude": "-106.66670000"
                        },
                        {
                            "id": 120193,
                            "name": "Laurel",
                            "latitude": "45.66912000",
                            "longitude": "-108.77153000"
                        },
                        {
                            "id": 120418,
                            "name": "Lewis and Clark County",
                            "latitude": "47.12234000",
                            "longitude": "-112.39035000"
                        },
                        {
                            "id": 120433,
                            "name": "Lewistown",
                            "latitude": "47.06247000",
                            "longitude": "-109.42824000"
                        },
                        {
                            "id": 120457,
                            "name": "Libby",
                            "latitude": "48.38829000",
                            "longitude": "-115.55600000"
                        },
                        {
                            "id": 120473,
                            "name": "Liberty County",
                            "latitude": "48.56169000",
                            "longitude": "-111.02461000"
                        },
                        {
                            "id": 120512,
                            "name": "Lincoln",
                            "latitude": "46.95494000",
                            "longitude": "-112.68171000"
                        },
                        {
                            "id": 120534,
                            "name": "Lincoln County",
                            "latitude": "48.54253000",
                            "longitude": "-115.40519000"
                        },
                        {
                            "id": 120653,
                            "name": "Livingston",
                            "latitude": "45.66244000",
                            "longitude": "-110.56104000"
                        },
                        {
                            "id": 120683,
                            "name": "Lockwood",
                            "latitude": "45.81912000",
                            "longitude": "-108.41486000"
                        },
                        {
                            "id": 120710,
                            "name": "Lolo",
                            "latitude": "46.75881000",
                            "longitude": "-114.08094000"
                        },
                        {
                            "id": 121019,
                            "name": "Madison County",
                            "latitude": "45.30074000",
                            "longitude": "-111.92033000"
                        },
                        {
                            "id": 121064,
                            "name": "Malmstrom Air Force Base",
                            "latitude": "47.50549000",
                            "longitude": "-111.18302000"
                        },
                        {
                            "id": 121068,
                            "name": "Malta",
                            "latitude": "48.35972000",
                            "longitude": "-107.87428000"
                        },
                        {
                            "id": 121116,
                            "name": "Manhattan",
                            "latitude": "45.85660000",
                            "longitude": "-111.33246000"
                        },
                        {
                            "id": 121475,
                            "name": "McCone County",
                            "latitude": "47.64523000",
                            "longitude": "-105.79534000"
                        },
                        {
                            "id": 121576,
                            "name": "Meagher County",
                            "latitude": "46.59819000",
                            "longitude": "-110.88564000"
                        },
                        {
                            "id": 121844,
                            "name": "Miles City",
                            "latitude": "46.40834000",
                            "longitude": "-105.84056000"
                        },
                        {
                            "id": 121937,
                            "name": "Mineral County",
                            "latitude": "47.14732000",
                            "longitude": "-114.99850000"
                        },
                        {
                            "id": 121991,
                            "name": "Missoula",
                            "latitude": "46.87215000",
                            "longitude": "-113.99400000"
                        },
                        {
                            "id": 121992,
                            "name": "Missoula County",
                            "latitude": "47.03649000",
                            "longitude": "-113.92371000"
                        },
                        {
                            "id": 122101,
                            "name": "Montana City",
                            "latitude": "46.53771000",
                            "longitude": "-111.93277000"
                        },
                        {
                            "id": 122514,
                            "name": "Musselshell County",
                            "latitude": "46.49655000",
                            "longitude": "-108.39771000"
                        },
                        {
                            "id": 122998,
                            "name": "North Browning",
                            "latitude": "48.57025000",
                            "longitude": "-113.00953000"
                        },
                        {
                            "id": 123535,
                            "name": "Orchard Homes",
                            "latitude": "46.86326000",
                            "longitude": "-114.04844000"
                        },
                        {
                            "id": 123703,
                            "name": "Pablo",
                            "latitude": "47.60021000",
                            "longitude": "-114.11900000"
                        },
                        {
                            "id": 123842,
                            "name": "Park County",
                            "latitude": "45.48834000",
                            "longitude": "-110.52632000"
                        },
                        {
                            "id": 124112,
                            "name": "Petroleum County",
                            "latitude": "47.11751000",
                            "longitude": "-108.25012000"
                        },
                        {
                            "id": 124132,
                            "name": "Philipsburg",
                            "latitude": "46.33215000",
                            "longitude": "-113.29423000"
                        },
                        {
                            "id": 124138,
                            "name": "Phillips County",
                            "latitude": "48.25909000",
                            "longitude": "-107.91329000"
                        },
                        {
                            "id": 124326,
                            "name": "Plains",
                            "latitude": "47.46021000",
                            "longitude": "-114.88291000"
                        },
                        {
                            "id": 124390,
                            "name": "Plentywood",
                            "latitude": "48.77475000",
                            "longitude": "-104.56246000"
                        },
                        {
                            "id": 124455,
                            "name": "Polson",
                            "latitude": "47.69355000",
                            "longitude": "-114.16317000"
                        },
                        {
                            "id": 124468,
                            "name": "Pondera County",
                            "latitude": "48.22798000",
                            "longitude": "-112.22639000"
                        },
                        {
                            "id": 124607,
                            "name": "Powder River County",
                            "latitude": "45.39501000",
                            "longitude": "-105.63010000"
                        },
                        {
                            "id": 124613,
                            "name": "Powell County",
                            "latitude": "46.85663000",
                            "longitude": "-112.93620000"
                        },
                        {
                            "id": 124625,
                            "name": "Prairie County",
                            "latitude": "46.86049000",
                            "longitude": "-105.37794000"
                        },
                        {
                            "id": 124903,
                            "name": "Ravalli County",
                            "latitude": "46.08170000",
                            "longitude": "-114.12069000"
                        },
                        {
                            "id": 124954,
                            "name": "Red Lodge",
                            "latitude": "45.18578000",
                            "longitude": "-109.24682000"
                        },
                        {
                            "id": 125078,
                            "name": "Richland County",
                            "latitude": "47.78792000",
                            "longitude": "-104.56134000"
                        },
                        {
                            "id": 125386,
                            "name": "Ronan",
                            "latitude": "47.52882000",
                            "longitude": "-114.10150000"
                        },
                        {
                            "id": 125394,
                            "name": "Roosevelt County",
                            "latitude": "48.29455000",
                            "longitude": "-105.01652000"
                        },
                        {
                            "id": 125413,
                            "name": "Rosebud County",
                            "latitude": "46.22974000",
                            "longitude": "-106.73082000"
                        },
                        {
                            "id": 125474,
                            "name": "Roundup",
                            "latitude": "46.44524000",
                            "longitude": "-108.54180000"
                        },
                        {
                            "id": 125564,
                            "name": "Ryegate",
                            "latitude": "46.29718000",
                            "longitude": "-109.25879000"
                        },
                        {
                            "id": 125863,
                            "name": "Sanders County",
                            "latitude": "47.67483000",
                            "longitude": "-115.13329000"
                        },
                        {
                            "id": 126031,
                            "name": "Scobey",
                            "latitude": "48.79252000",
                            "longitude": "-105.42083000"
                        },
                        {
                            "id": 126125,
                            "name": "Seeley Lake",
                            "latitude": "47.17938000",
                            "longitude": "-113.48452000"
                        },
                        {
                            "id": 126271,
                            "name": "Shelby",
                            "latitude": "48.50526000",
                            "longitude": "-111.85697000"
                        },
                        {
                            "id": 126325,
                            "name": "Sheridan County",
                            "latitude": "48.72120000",
                            "longitude": "-104.50468000"
                        },
                        {
                            "id": 126399,
                            "name": "Sidney",
                            "latitude": "47.71668000",
                            "longitude": "-104.15633000"
                        },
                        {
                            "id": 126421,
                            "name": "Silver Bow County",
                            "latitude": "45.90236000",
                            "longitude": "-112.65672000"
                        },
                        {
                            "id": 126577,
                            "name": "Somers",
                            "latitude": "48.08023000",
                            "longitude": "-114.22151000"
                        },
                        {
                            "id": 126633,
                            "name": "South Browning",
                            "latitude": "48.54608000",
                            "longitude": "-113.01425000"
                        },
                        {
                            "id": 126955,
                            "name": "Stanford",
                            "latitude": "47.15358000",
                            "longitude": "-110.21826000"
                        },
                        {
                            "id": 127049,
                            "name": "Stevensville",
                            "latitude": "46.50992000",
                            "longitude": "-114.09316000"
                        },
                        {
                            "id": 127063,
                            "name": "Stillwater County",
                            "latitude": "45.66944000",
                            "longitude": "-109.39477000"
                        },
                        {
                            "id": 127238,
                            "name": "Sun Prairie",
                            "latitude": "47.53690000",
                            "longitude": "-111.48136000"
                        },
                        {
                            "id": 127282,
                            "name": "Superior",
                            "latitude": "47.19159000",
                            "longitude": "-114.89180000"
                        },
                        {
                            "id": 127336,
                            "name": "Sweet Grass County",
                            "latitude": "45.81373000",
                            "longitude": "-109.94105000"
                        },
                        {
                            "id": 127531,
                            "name": "Terry",
                            "latitude": "46.79306000",
                            "longitude": "-105.31221000"
                        },
                        {
                            "id": 127538,
                            "name": "Teton County",
                            "latitude": "47.83729000",
                            "longitude": "-112.24080000"
                        },
                        {
                            "id": 127592,
                            "name": "Thompson Falls",
                            "latitude": "47.59489000",
                            "longitude": "-115.33834000"
                        },
                        {
                            "id": 127611,
                            "name": "Three Forks",
                            "latitude": "45.89243000",
                            "longitude": "-111.55219000"
                        },
                        {
                            "id": 127728,
                            "name": "Toole County",
                            "latitude": "48.65530000",
                            "longitude": "-111.69570000"
                        },
                        {
                            "id": 127766,
                            "name": "Townsend",
                            "latitude": "46.31910000",
                            "longitude": "-111.52080000"
                        },
                        {
                            "id": 127789,
                            "name": "Treasure County",
                            "latitude": "46.21147000",
                            "longitude": "-107.27170000"
                        },
                        {
                            "id": 128105,
                            "name": "Valley County",
                            "latitude": "48.36531000",
                            "longitude": "-106.66752000"
                        },
                        {
                            "id": 128289,
                            "name": "Virginia City",
                            "latitude": "45.29381000",
                            "longitude": "-111.94609000"
                        },
                        {
                            "id": 128470,
                            "name": "Warm Springs",
                            "latitude": "46.18131000",
                            "longitude": "-112.78476000"
                        },
                        {
                            "id": 128878,
                            "name": "West Glendive",
                            "latitude": "47.11085000",
                            "longitude": "-104.74968000"
                        },
                        {
                            "id": 129009,
                            "name": "West Yellowstone",
                            "latitude": "44.66215000",
                            "longitude": "-111.10411000"
                        },
                        {
                            "id": 129110,
                            "name": "Wheatland County",
                            "latitude": "46.46634000",
                            "longitude": "-109.84440000"
                        },
                        {
                            "id": 129163,
                            "name": "White Sulphur Springs",
                            "latitude": "46.54828000",
                            "longitude": "-110.90216000"
                        },
                        {
                            "id": 129166,
                            "name": "Whitefish",
                            "latitude": "48.41108000",
                            "longitude": "-114.33763000"
                        },
                        {
                            "id": 129172,
                            "name": "Whitehall",
                            "latitude": "45.87076000",
                            "longitude": "-112.09749000"
                        },
                        {
                            "id": 129213,
                            "name": "Wibaux",
                            "latitude": "46.98501000",
                            "longitude": "-104.18827000"
                        },
                        {
                            "id": 129214,
                            "name": "Wibaux County",
                            "latitude": "46.96535000",
                            "longitude": "-104.24897000"
                        },
                        {
                            "id": 129415,
                            "name": "Winnett",
                            "latitude": "47.00276000",
                            "longitude": "-108.35207000"
                        },
                        {
                            "id": 129473,
                            "name": "Wolf Point",
                            "latitude": "48.09057000",
                            "longitude": "-105.64056000"
                        },
                        {
                            "id": 129679,
                            "name": "Yellowstone County",
                            "latitude": "45.93725000",
                            "longitude": "-108.27435000"
                        }
                    ]
                },
                {
                    "id": 1439,
                    "name": "Navassa Island",
                    "state_code": "UM-76",
                    "cities": []
                },
                {
                    "id": 1408,
                    "name": "Nebraska",
                    "state_code": "NE",
                    "cities": [
                        {
                            "id": 111022,
                            "name": "Adams County",
                            "latitude": "40.52447000",
                            "longitude": "-98.50121000"
                        },
                        {
                            "id": 111069,
                            "name": "Ainsworth",
                            "latitude": "42.55000000",
                            "longitude": "-99.86262000"
                        },
                        {
                            "id": 111127,
                            "name": "Albion",
                            "latitude": "41.69084000",
                            "longitude": "-98.00367000"
                        },
                        {
                            "id": 111200,
                            "name": "Alliance",
                            "latitude": "42.10163000",
                            "longitude": "-102.87215000"
                        },
                        {
                            "id": 111210,
                            "name": "Alma",
                            "latitude": "40.09751000",
                            "longitude": "-99.36204000"
                        },
                        {
                            "id": 111365,
                            "name": "Antelope County",
                            "latitude": "42.17690000",
                            "longitude": "-98.06669000"
                        },
                        {
                            "id": 111413,
                            "name": "Arapahoe",
                            "latitude": "40.30417000",
                            "longitude": "-99.90040000"
                        },
                        {
                            "id": 111467,
                            "name": "Arlington",
                            "latitude": "41.45250000",
                            "longitude": "-96.35113000"
                        },
                        {
                            "id": 111499,
                            "name": "Arthur",
                            "latitude": "41.57165000",
                            "longitude": "-101.69156000"
                        },
                        {
                            "id": 111500,
                            "name": "Arthur County",
                            "latitude": "41.56890000",
                            "longitude": "-101.69591000"
                        },
                        {
                            "id": 111536,
                            "name": "Ashland",
                            "latitude": "41.03916000",
                            "longitude": "-96.36835000"
                        },
                        {
                            "id": 111588,
                            "name": "Atkinson",
                            "latitude": "42.53139000",
                            "longitude": "-98.97815000"
                        },
                        {
                            "id": 111630,
                            "name": "Auburn",
                            "latitude": "40.39278000",
                            "longitude": "-95.83889000"
                        },
                        {
                            "id": 111661,
                            "name": "Aurora",
                            "latitude": "40.86723000",
                            "longitude": "-98.00422000"
                        },
                        {
                            "id": 111800,
                            "name": "Banner County",
                            "latitude": "41.54597000",
                            "longitude": "-103.71048000"
                        },
                        {
                            "id": 111864,
                            "name": "Bartlett",
                            "latitude": "41.88529000",
                            "longitude": "-98.55230000"
                        },
                        {
                            "id": 111882,
                            "name": "Bassett",
                            "latitude": "42.58583000",
                            "longitude": "-99.53789000"
                        },
                        {
                            "id": 111905,
                            "name": "Battle Creek",
                            "latitude": "41.99945000",
                            "longitude": "-97.59839000"
                        },
                        {
                            "id": 111938,
                            "name": "Bayard",
                            "latitude": "41.75497000",
                            "longitude": "-103.32410000"
                        },
                        {
                            "id": 111981,
                            "name": "Beatrice",
                            "latitude": "40.26806000",
                            "longitude": "-96.74697000"
                        },
                        {
                            "id": 111996,
                            "name": "Beaver City",
                            "latitude": "40.13751000",
                            "longitude": "-99.82956000"
                        },
                        {
                            "id": 112113,
                            "name": "Bellevue",
                            "latitude": "41.13667000",
                            "longitude": "-95.89084000"
                        },
                        {
                            "id": 112171,
                            "name": "Benkelman",
                            "latitude": "40.04916000",
                            "longitude": "-101.53294000"
                        },
                        {
                            "id": 112176,
                            "name": "Bennington",
                            "latitude": "41.36472000",
                            "longitude": "-96.15780000"
                        },
                        {
                            "id": 112406,
                            "name": "Blaine County",
                            "latitude": "41.91276000",
                            "longitude": "-99.97690000"
                        },
                        {
                            "id": 112409,
                            "name": "Blair",
                            "latitude": "41.54444000",
                            "longitude": "-96.12502000"
                        },
                        {
                            "id": 112561,
                            "name": "Boone County",
                            "latitude": "41.70678000",
                            "longitude": "-98.06726000"
                        },
                        {
                            "id": 112635,
                            "name": "Box Butte County",
                            "latitude": "42.21977000",
                            "longitude": "-103.08568000"
                        },
                        {
                            "id": 112643,
                            "name": "Boyd County",
                            "latitude": "42.89968000",
                            "longitude": "-98.76646000"
                        },
                        {
                            "id": 112742,
                            "name": "Brewster",
                            "latitude": "41.93889000",
                            "longitude": "-99.86485000"
                        },
                        {
                            "id": 112767,
                            "name": "Bridgeport",
                            "latitude": "41.66525000",
                            "longitude": "-103.09910000"
                        },
                        {
                            "id": 112843,
                            "name": "Broken Bow",
                            "latitude": "41.40195000",
                            "longitude": "-99.63928000"
                        },
                        {
                            "id": 112912,
                            "name": "Brown County",
                            "latitude": "42.43002000",
                            "longitude": "-99.92951000"
                        },
                        {
                            "id": 113018,
                            "name": "Buffalo County",
                            "latitude": "40.85515000",
                            "longitude": "-99.07497000"
                        },
                        {
                            "id": 113095,
                            "name": "Burt County",
                            "latitude": "41.85156000",
                            "longitude": "-96.32860000"
                        },
                        {
                            "id": 113099,
                            "name": "Burwell",
                            "latitude": "41.78167000",
                            "longitude": "-99.13315000"
                        },
                        {
                            "id": 113119,
                            "name": "Butler County",
                            "latitude": "41.22608000",
                            "longitude": "-97.13177000"
                        },
                        {
                            "id": 113122,
                            "name": "Butte",
                            "latitude": "42.91139000",
                            "longitude": "-98.84926000"
                        },
                        {
                            "id": 113256,
                            "name": "Cambridge",
                            "latitude": "40.28195000",
                            "longitude": "-100.16569000"
                        },
                        {
                            "id": 113534,
                            "name": "Cass County",
                            "latitude": "40.90972000",
                            "longitude": "-96.14087000"
                        },
                        {
                            "id": 113606,
                            "name": "Cedar County",
                            "latitude": "42.59926000",
                            "longitude": "-97.25240000"
                        },
                        {
                            "id": 113637,
                            "name": "Center",
                            "latitude": "42.60945000",
                            "longitude": "-97.87673000"
                        },
                        {
                            "id": 113669,
                            "name": "Central City",
                            "latitude": "41.11585000",
                            "longitude": "-98.00172000"
                        },
                        {
                            "id": 113707,
                            "name": "Chadron",
                            "latitude": "42.82942000",
                            "longitude": "-102.99991000"
                        },
                        {
                            "id": 113711,
                            "name": "Chalco",
                            "latitude": "41.18389000",
                            "longitude": "-96.15030000"
                        },
                        {
                            "id": 113747,
                            "name": "Chappell",
                            "latitude": "41.09277000",
                            "longitude": "-102.47074000"
                        },
                        {
                            "id": 113793,
                            "name": "Chase County",
                            "latitude": "40.52420000",
                            "longitude": "-101.69795000"
                        },
                        {
                            "id": 113858,
                            "name": "Cherry County",
                            "latitude": "42.54493000",
                            "longitude": "-101.11858000"
                        },
                        {
                            "id": 113929,
                            "name": "Cheyenne County",
                            "latitude": "41.21978000",
                            "longitude": "-102.99498000"
                        },
                        {
                            "id": 114171,
                            "name": "Clay Center",
                            "latitude": "40.52168000",
                            "longitude": "-98.05533000"
                        },
                        {
                            "id": 114189,
                            "name": "Clay County",
                            "latitude": "40.52443000",
                            "longitude": "-98.05128000"
                        },
                        {
                            "id": 114410,
                            "name": "Colfax County",
                            "latitude": "41.57402000",
                            "longitude": "-97.08646000"
                        },
                        {
                            "id": 114496,
                            "name": "Columbus",
                            "latitude": "41.42973000",
                            "longitude": "-97.36838000"
                        },
                        {
                            "id": 114752,
                            "name": "Cozad",
                            "latitude": "40.85973000",
                            "longitude": "-99.98734000"
                        },
                        {
                            "id": 114790,
                            "name": "Creighton",
                            "latitude": "42.46667000",
                            "longitude": "-97.90618000"
                        },
                        {
                            "id": 114815,
                            "name": "Crete",
                            "latitude": "40.62778000",
                            "longitude": "-96.96142000"
                        },
                        {
                            "id": 114922,
                            "name": "Cuming County",
                            "latitude": "41.91640000",
                            "longitude": "-96.78740000"
                        },
                        {
                            "id": 114936,
                            "name": "Custer County",
                            "latitude": "41.39426000",
                            "longitude": "-99.72614000"
                        },
                        {
                            "id": 114975,
                            "name": "Dakota City",
                            "latitude": "42.41555000",
                            "longitude": "-96.41836000"
                        },
                        {
                            "id": 114977,
                            "name": "Dakota County",
                            "latitude": "42.39111000",
                            "longitude": "-96.56451000"
                        },
                        {
                            "id": 115061,
                            "name": "David City",
                            "latitude": "41.25279000",
                            "longitude": "-97.13004000"
                        },
                        {
                            "id": 115080,
                            "name": "Dawes County",
                            "latitude": "42.71972000",
                            "longitude": "-103.13544000"
                        },
                        {
                            "id": 115084,
                            "name": "Dawson County",
                            "latitude": "40.86994000",
                            "longitude": "-99.81957000"
                        },
                        {
                            "id": 115295,
                            "name": "Deuel County",
                            "latitude": "41.11160000",
                            "longitude": "-102.33395000"
                        },
                        {
                            "id": 115368,
                            "name": "Dixon County",
                            "latitude": "42.49319000",
                            "longitude": "-96.86775000"
                        },
                        {
                            "id": 115379,
                            "name": "Dodge County",
                            "latitude": "41.57789000",
                            "longitude": "-96.65398000"
                        },
                        {
                            "id": 115430,
                            "name": "Douglas County",
                            "latitude": "41.29535000",
                            "longitude": "-96.15448000"
                        },
                        {
                            "id": 115532,
                            "name": "Dundy County",
                            "latitude": "40.17624000",
                            "longitude": "-101.68796000"
                        },
                        {
                            "id": 115595,
                            "name": "Eagle",
                            "latitude": "40.81667000",
                            "longitude": "-96.43029000"
                        },
                        {
                            "id": 116001,
                            "name": "Elkhorn",
                            "latitude": "41.28639000",
                            "longitude": "-96.23447000"
                        },
                        {
                            "id": 116081,
                            "name": "Elwood",
                            "latitude": "40.59028000",
                            "longitude": "-99.86095000"
                        },
                        {
                            "id": 116295,
                            "name": "Fairbury",
                            "latitude": "40.13722000",
                            "longitude": "-97.18059000"
                        },
                        {
                            "id": 116381,
                            "name": "Falls City",
                            "latitude": "40.06084000",
                            "longitude": "-95.60193000"
                        },
                        {
                            "id": 116511,
                            "name": "Fillmore County",
                            "latitude": "40.52467000",
                            "longitude": "-97.59650000"
                        },
                        {
                            "id": 116839,
                            "name": "Franklin",
                            "latitude": "40.09612000",
                            "longitude": "-98.95258000"
                        },
                        {
                            "id": 116860,
                            "name": "Franklin County",
                            "latitude": "40.17634000",
                            "longitude": "-98.95280000"
                        },
                        {
                            "id": 116923,
                            "name": "Fremont",
                            "latitude": "41.43333000",
                            "longitude": "-96.49808000"
                        },
                        {
                            "id": 116946,
                            "name": "Friend",
                            "latitude": "40.65362000",
                            "longitude": "-97.28616000"
                        },
                        {
                            "id": 116963,
                            "name": "Frontier County",
                            "latitude": "40.53008000",
                            "longitude": "-100.39420000"
                        },
                        {
                            "id": 116981,
                            "name": "Fullerton",
                            "latitude": "41.36335000",
                            "longitude": "-97.96923000"
                        },
                        {
                            "id": 117001,
                            "name": "Furnas County",
                            "latitude": "40.17647000",
                            "longitude": "-99.91227000"
                        },
                        {
                            "id": 117008,
                            "name": "Gage County",
                            "latitude": "40.26193000",
                            "longitude": "-96.68944000"
                        },
                        {
                            "id": 117064,
                            "name": "Garden County",
                            "latitude": "41.61943000",
                            "longitude": "-102.33544000"
                        },
                        {
                            "id": 117083,
                            "name": "Garfield County",
                            "latitude": "41.91436000",
                            "longitude": "-98.99139000"
                        },
                        {
                            "id": 117143,
                            "name": "Geneva",
                            "latitude": "40.52695000",
                            "longitude": "-97.59588000"
                        },
                        {
                            "id": 117170,
                            "name": "Gering",
                            "latitude": "41.82580000",
                            "longitude": "-103.66050000"
                        },
                        {
                            "id": 117182,
                            "name": "Gibbon",
                            "latitude": "40.74835000",
                            "longitude": "-98.84480000"
                        },
                        {
                            "id": 117397,
                            "name": "Gordon",
                            "latitude": "42.80472000",
                            "longitude": "-102.20322000"
                        },
                        {
                            "id": 117413,
                            "name": "Gosper County",
                            "latitude": "40.51482000",
                            "longitude": "-99.83070000"
                        },
                        {
                            "id": 117415,
                            "name": "Gothenburg",
                            "latitude": "40.92945000",
                            "longitude": "-100.16068000"
                        },
                        {
                            "id": 117461,
                            "name": "Grand Island",
                            "latitude": "40.92501000",
                            "longitude": "-98.34201000"
                        },
                        {
                            "id": 117505,
                            "name": "Grant",
                            "latitude": "40.84194000",
                            "longitude": "-101.72517000"
                        },
                        {
                            "id": 117519,
                            "name": "Grant County",
                            "latitude": "41.91499000",
                            "longitude": "-101.74052000"
                        },
                        {
                            "id": 117585,
                            "name": "Greeley",
                            "latitude": "41.54862000",
                            "longitude": "-98.53118000"
                        },
                        {
                            "id": 117587,
                            "name": "Greeley County",
                            "latitude": "41.56744000",
                            "longitude": "-98.52124000"
                        },
                        {
                            "id": 117727,
                            "name": "Gretna",
                            "latitude": "41.14083000",
                            "longitude": "-96.23974000"
                        },
                        {
                            "id": 117865,
                            "name": "Hall County",
                            "latitude": "40.87257000",
                            "longitude": "-98.50217000"
                        },
                        {
                            "id": 117902,
                            "name": "Hamilton County",
                            "latitude": "40.87356000",
                            "longitude": "-98.02323000"
                        },
                        {
                            "id": 118019,
                            "name": "Harlan County",
                            "latitude": "40.17650000",
                            "longitude": "-99.40464000"
                        },
                        {
                            "id": 118052,
                            "name": "Harrisburg",
                            "latitude": "41.55636000",
                            "longitude": "-103.73856000"
                        },
                        {
                            "id": 118060,
                            "name": "Harrison",
                            "latitude": "42.68719000",
                            "longitude": "-103.88271000"
                        },
                        {
                            "id": 118096,
                            "name": "Hartington",
                            "latitude": "42.62250000",
                            "longitude": "-97.26450000"
                        },
                        {
                            "id": 118133,
                            "name": "Hastings",
                            "latitude": "40.58612000",
                            "longitude": "-98.38839000"
                        },
                        {
                            "id": 118180,
                            "name": "Hayes Center",
                            "latitude": "40.51084000",
                            "longitude": "-101.01960000"
                        },
                        {
                            "id": 118181,
                            "name": "Hayes County",
                            "latitude": "40.52478000",
                            "longitude": "-101.06184000"
                        },
                        {
                            "id": 118238,
                            "name": "Hebron",
                            "latitude": "40.16639000",
                            "longitude": "-97.58588000"
                        },
                        {
                            "id": 118362,
                            "name": "Hickman",
                            "latitude": "40.62000000",
                            "longitude": "-96.62918000"
                        },
                        {
                            "id": 118493,
                            "name": "Hitchcock County",
                            "latitude": "40.17635000",
                            "longitude": "-101.04227000"
                        },
                        {
                            "id": 118528,
                            "name": "Holdrege",
                            "latitude": "40.44029000",
                            "longitude": "-99.36982000"
                        },
                        {
                            "id": 118577,
                            "name": "Holt County",
                            "latitude": "42.45571000",
                            "longitude": "-98.78384000"
                        },
                        {
                            "id": 118629,
                            "name": "Hooker County",
                            "latitude": "41.91591000",
                            "longitude": "-101.13533000"
                        },
                        {
                            "id": 118717,
                            "name": "Howard County",
                            "latitude": "41.22004000",
                            "longitude": "-98.51709000"
                        },
                        {
                            "id": 118843,
                            "name": "Hyannis",
                            "latitude": "42.00054000",
                            "longitude": "-101.76184000"
                        },
                        {
                            "id": 118884,
                            "name": "Imperial",
                            "latitude": "40.51694000",
                            "longitude": "-101.64323000"
                        },
                        {
                            "id": 119190,
                            "name": "Jefferson County",
                            "latitude": "40.17576000",
                            "longitude": "-97.14272000"
                        },
                        {
                            "id": 119270,
                            "name": "Johnson County",
                            "latitude": "40.39263000",
                            "longitude": "-96.26510000"
                        },
                        {
                            "id": 119408,
                            "name": "Kearney",
                            "latitude": "40.69946000",
                            "longitude": "-99.08148000"
                        },
                        {
                            "id": 119409,
                            "name": "Kearney County",
                            "latitude": "40.50671000",
                            "longitude": "-98.94802000"
                        },
                        {
                            "id": 119425,
                            "name": "Keith County",
                            "latitude": "41.19879000",
                            "longitude": "-101.66135000"
                        },
                        {
                            "id": 119542,
                            "name": "Keya Paha County",
                            "latitude": "42.87883000",
                            "longitude": "-99.71235000"
                        },
                        {
                            "id": 119566,
                            "name": "Kimball",
                            "latitude": "41.23581000",
                            "longitude": "-103.66300000"
                        },
                        {
                            "id": 119567,
                            "name": "Kimball County",
                            "latitude": "41.19766000",
                            "longitude": "-103.71495000"
                        },
                        {
                            "id": 119692,
                            "name": "Knox County",
                            "latitude": "42.63678000",
                            "longitude": "-97.89190000"
                        },
                        {
                            "id": 119796,
                            "name": "La Vista",
                            "latitude": "41.18389000",
                            "longitude": "-96.03113000"
                        },
                        {
                            "id": 120092,
                            "name": "Lancaster County",
                            "latitude": "40.78417000",
                            "longitude": "-96.68776000"
                        },
                        {
                            "id": 120452,
                            "name": "Lexington",
                            "latitude": "40.78084000",
                            "longitude": "-99.74150000"
                        },
                        {
                            "id": 120506,
                            "name": "Lincoln",
                            "latitude": "40.80000000",
                            "longitude": "-96.66696000"
                        },
                        {
                            "id": 120535,
                            "name": "Lincoln County",
                            "latitude": "41.04777000",
                            "longitude": "-100.74523000"
                        },
                        {
                            "id": 120704,
                            "name": "Logan County",
                            "latitude": "41.56649000",
                            "longitude": "-100.48286000"
                        },
                        {
                            "id": 120816,
                            "name": "Louisville",
                            "latitude": "40.99778000",
                            "longitude": "-96.16224000"
                        },
                        {
                            "id": 120818,
                            "name": "Loup City",
                            "latitude": "41.27557000",
                            "longitude": "-98.96675000"
                        },
                        {
                            "id": 120819,
                            "name": "Loup County",
                            "latitude": "41.91385000",
                            "longitude": "-99.45442000"
                        },
                        {
                            "id": 120975,
                            "name": "Macy",
                            "latitude": "42.11305000",
                            "longitude": "-96.35642000"
                        },
                        {
                            "id": 120997,
                            "name": "Madison",
                            "latitude": "41.82834000",
                            "longitude": "-97.45505000"
                        },
                        {
                            "id": 121016,
                            "name": "Madison County",
                            "latitude": "41.91670000",
                            "longitude": "-97.60078000"
                        },
                        {
                            "id": 121479,
                            "name": "McCook",
                            "latitude": "40.20195000",
                            "longitude": "-100.62571000"
                        },
                        {
                            "id": 121548,
                            "name": "McPherson County",
                            "latitude": "41.56808000",
                            "longitude": "-101.06053000"
                        },
                        {
                            "id": 121705,
                            "name": "Merrick County",
                            "latitude": "41.16982000",
                            "longitude": "-98.03765000"
                        },
                        {
                            "id": 121855,
                            "name": "Milford",
                            "latitude": "40.77445000",
                            "longitude": "-97.05059000"
                        },
                        {
                            "id": 121929,
                            "name": "Minden",
                            "latitude": "40.49863000",
                            "longitude": "-98.94786000"
                        },
                        {
                            "id": 121998,
                            "name": "Mitchell",
                            "latitude": "41.94024000",
                            "longitude": "-103.80856000"
                        },
                        {
                            "id": 122263,
                            "name": "Morrill County",
                            "latitude": "41.71600000",
                            "longitude": "-103.01055000"
                        },
                        {
                            "id": 122459,
                            "name": "Mullen",
                            "latitude": "42.04278000",
                            "longitude": "-101.04266000"
                        },
                        {
                            "id": 122540,
                            "name": "Nance County",
                            "latitude": "41.39730000",
                            "longitude": "-97.99225000"
                        },
                        {
                            "id": 122609,
                            "name": "Nebraska City",
                            "latitude": "40.67667000",
                            "longitude": "-95.85917000"
                        },
                        {
                            "id": 122620,
                            "name": "Neligh",
                            "latitude": "42.12862000",
                            "longitude": "-98.02979000"
                        },
                        {
                            "id": 122625,
                            "name": "Nelson",
                            "latitude": "40.20168000",
                            "longitude": "-98.06782000"
                        },
                        {
                            "id": 122630,
                            "name": "Nemaha County",
                            "latitude": "40.38765000",
                            "longitude": "-95.84982000"
                        },
                        {
                            "id": 122943,
                            "name": "Norfolk",
                            "latitude": "42.02834000",
                            "longitude": "-97.41700000"
                        },
                        {
                            "id": 122983,
                            "name": "North Bend",
                            "latitude": "41.46195000",
                            "longitude": "-96.77975000"
                        },
                        {
                            "id": 123082,
                            "name": "North Platte",
                            "latitude": "41.12389000",
                            "longitude": "-100.76542000"
                        },
                        {
                            "id": 123214,
                            "name": "Nuckolls County",
                            "latitude": "40.17638000",
                            "longitude": "-98.04718000"
                        },
                        {
                            "id": 123228,
                            "name": "O'Neill",
                            "latitude": "42.45778000",
                            "longitude": "-98.64759000"
                        },
                        {
                            "id": 123291,
                            "name": "Oakland",
                            "latitude": "41.83583000",
                            "longitude": "-96.46697000"
                        },
                        {
                            "id": 123364,
                            "name": "Offutt Air Force Base",
                            "latitude": "41.12024000",
                            "longitude": "-95.92095000"
                        },
                        {
                            "id": 123365,
                            "name": "Ogallala",
                            "latitude": "41.12805000",
                            "longitude": "-101.71962000"
                        },
                        {
                            "id": 123463,
                            "name": "Omaha",
                            "latitude": "41.25626000",
                            "longitude": "-95.94043000"
                        },
                        {
                            "id": 123542,
                            "name": "Ord",
                            "latitude": "41.60334000",
                            "longitude": "-98.92620000"
                        },
                        {
                            "id": 123601,
                            "name": "Osceola",
                            "latitude": "41.17974000",
                            "longitude": "-97.54755000"
                        },
                        {
                            "id": 123610,
                            "name": "Oshkosh",
                            "latitude": "41.40498000",
                            "longitude": "-102.34436000"
                        },
                        {
                            "id": 123631,
                            "name": "Otoe County",
                            "latitude": "40.64850000",
                            "longitude": "-96.13478000"
                        },
                        {
                            "id": 123814,
                            "name": "Papillion",
                            "latitude": "41.15444000",
                            "longitude": "-96.04224000"
                        },
                        {
                            "id": 123935,
                            "name": "Pawnee City",
                            "latitude": "40.10833000",
                            "longitude": "-96.15445000"
                        },
                        {
                            "id": 123938,
                            "name": "Pawnee County",
                            "latitude": "40.13154000",
                            "longitude": "-96.23706000"
                        },
                        {
                            "id": 124014,
                            "name": "Pender",
                            "latitude": "42.11416000",
                            "longitude": "-96.70726000"
                        },
                        {
                            "id": 124063,
                            "name": "Perkins County",
                            "latitude": "40.85094000",
                            "longitude": "-101.64961000"
                        },
                        {
                            "id": 124122,
                            "name": "Phelps County",
                            "latitude": "40.51111000",
                            "longitude": "-99.41454000"
                        },
                        {
                            "id": 124169,
                            "name": "Pierce",
                            "latitude": "42.19917000",
                            "longitude": "-97.52672000"
                        },
                        {
                            "id": 124172,
                            "name": "Pierce County",
                            "latitude": "42.26437000",
                            "longitude": "-97.60129000"
                        },
                        {
                            "id": 124329,
                            "name": "Plainview",
                            "latitude": "42.34973000",
                            "longitude": "-97.79201000"
                        },
                        {
                            "id": 124354,
                            "name": "Platte County",
                            "latitude": "41.57129000",
                            "longitude": "-97.52116000"
                        },
                        {
                            "id": 124362,
                            "name": "Plattsmouth",
                            "latitude": "41.01139000",
                            "longitude": "-95.88223000"
                        },
                        {
                            "id": 124449,
                            "name": "Polk County",
                            "latitude": "41.18690000",
                            "longitude": "-97.56842000"
                        },
                        {
                            "id": 124463,
                            "name": "Ponca",
                            "latitude": "42.56250000",
                            "longitude": "-96.70559000"
                        },
                        {
                            "id": 124836,
                            "name": "Ralston",
                            "latitude": "41.20528000",
                            "longitude": "-96.04252000"
                        },
                        {
                            "id": 124908,
                            "name": "Ravenna",
                            "latitude": "41.02612000",
                            "longitude": "-98.91258000"
                        },
                        {
                            "id": 124944,
                            "name": "Red Cloud",
                            "latitude": "40.08890000",
                            "longitude": "-98.51950000"
                        },
                        {
                            "id": 124962,
                            "name": "Red Willow County",
                            "latitude": "40.17583000",
                            "longitude": "-100.47686000"
                        },
                        {
                            "id": 125059,
                            "name": "Richardson County",
                            "latitude": "40.12506000",
                            "longitude": "-95.71753000"
                        },
                        {
                            "id": 125273,
                            "name": "Rock County",
                            "latitude": "42.42129000",
                            "longitude": "-99.44993000"
                        },
                        {
                            "id": 125527,
                            "name": "Rushville",
                            "latitude": "42.71832000",
                            "longitude": "-102.46406000"
                        },
                        {
                            "id": 125704,
                            "name": "Saint Paul",
                            "latitude": "41.21473000",
                            "longitude": "-98.45812000"
                        },
                        {
                            "id": 125753,
                            "name": "Saline County",
                            "latitude": "40.52406000",
                            "longitude": "-97.14091000"
                        },
                        {
                            "id": 125952,
                            "name": "Sarpy County",
                            "latitude": "41.11293000",
                            "longitude": "-96.11199000"
                        },
                        {
                            "id": 125969,
                            "name": "Saunders County",
                            "latitude": "41.22637000",
                            "longitude": "-96.63740000"
                        },
                        {
                            "id": 126021,
                            "name": "Schuyler",
                            "latitude": "41.44723000",
                            "longitude": "-97.05948000"
                        },
                        {
                            "id": 126057,
                            "name": "Scotts Bluff County",
                            "latitude": "41.85061000",
                            "longitude": "-103.70795000"
                        },
                        {
                            "id": 126060,
                            "name": "Scottsbluff",
                            "latitude": "41.86663000",
                            "longitude": "-103.66717000"
                        },
                        {
                            "id": 126186,
                            "name": "Seward",
                            "latitude": "40.90695000",
                            "longitude": "-97.09892000"
                        },
                        {
                            "id": 126188,
                            "name": "Seward County",
                            "latitude": "40.87239000",
                            "longitude": "-97.13951000"
                        },
                        {
                            "id": 126298,
                            "name": "Shelton",
                            "latitude": "40.77918000",
                            "longitude": "-98.73091000"
                        },
                        {
                            "id": 126327,
                            "name": "Sheridan County",
                            "latitude": "42.50477000",
                            "longitude": "-102.40896000"
                        },
                        {
                            "id": 126332,
                            "name": "Sherman County",
                            "latitude": "41.22059000",
                            "longitude": "-98.97621000"
                        },
                        {
                            "id": 126398,
                            "name": "Sidney",
                            "latitude": "41.14276000",
                            "longitude": "-102.97798000"
                        },
                        {
                            "id": 126461,
                            "name": "Sioux County",
                            "latitude": "42.48771000",
                            "longitude": "-103.75888000"
                        },
                        {
                            "id": 126730,
                            "name": "South Sioux City",
                            "latitude": "42.47388000",
                            "longitude": "-96.41364000"
                        },
                        {
                            "id": 126899,
                            "name": "Springfield",
                            "latitude": "41.08194000",
                            "longitude": "-96.13446000"
                        },
                        {
                            "id": 126913,
                            "name": "Springview",
                            "latitude": "42.82444000",
                            "longitude": "-99.74901000"
                        },
                        {
                            "id": 126968,
                            "name": "Stanton",
                            "latitude": "41.95028000",
                            "longitude": "-97.22393000"
                        },
                        {
                            "id": 126972,
                            "name": "Stanton County",
                            "latitude": "41.91692000",
                            "longitude": "-97.19392000"
                        },
                        {
                            "id": 126977,
                            "name": "Stapleton",
                            "latitude": "41.48028000",
                            "longitude": "-100.51292000"
                        },
                        {
                            "id": 127076,
                            "name": "Stockville",
                            "latitude": "40.53279000",
                            "longitude": "-100.38348000"
                        },
                        {
                            "id": 127137,
                            "name": "Stromsburg",
                            "latitude": "41.11418000",
                            "longitude": "-97.59894000"
                        },
                        {
                            "id": 127278,
                            "name": "Superior",
                            "latitude": "40.02085000",
                            "longitude": "-98.07004000"
                        },
                        {
                            "id": 127306,
                            "name": "Sutherland",
                            "latitude": "41.15694000",
                            "longitude": "-101.12627000"
                        },
                        {
                            "id": 127314,
                            "name": "Sutton",
                            "latitude": "40.60557000",
                            "longitude": "-97.85921000"
                        },
                        {
                            "id": 127364,
                            "name": "Syracuse",
                            "latitude": "40.65722000",
                            "longitude": "-96.18640000"
                        },
                        {
                            "id": 127448,
                            "name": "Taylor",
                            "latitude": "41.77028000",
                            "longitude": "-99.37872000"
                        },
                        {
                            "id": 127480,
                            "name": "Tecumseh",
                            "latitude": "40.36667000",
                            "longitude": "-96.19612000"
                        },
                        {
                            "id": 127485,
                            "name": "Tekamah",
                            "latitude": "41.77832000",
                            "longitude": "-96.22113000"
                        },
                        {
                            "id": 127534,
                            "name": "Terrytown",
                            "latitude": "41.84747000",
                            "longitude": "-103.66161000"
                        },
                        {
                            "id": 127552,
                            "name": "Thayer County",
                            "latitude": "40.17629000",
                            "longitude": "-97.59492000"
                        },
                        {
                            "id": 127567,
                            "name": "Thedford",
                            "latitude": "41.97833000",
                            "longitude": "-100.57625000"
                        },
                        {
                            "id": 127581,
                            "name": "Thomas County",
                            "latitude": "41.91351000",
                            "longitude": "-100.55585000"
                        },
                        {
                            "id": 127630,
                            "name": "Thurston County",
                            "latitude": "42.15819000",
                            "longitude": "-96.54410000"
                        },
                        {
                            "id": 127810,
                            "name": "Trenton",
                            "latitude": "40.17555000",
                            "longitude": "-101.01294000"
                        },
                        {
                            "id": 127863,
                            "name": "Tryon",
                            "latitude": "41.55277000",
                            "longitude": "-100.95765000"
                        },
                        {
                            "id": 128091,
                            "name": "Valentine",
                            "latitude": "42.87278000",
                            "longitude": "-100.55097000"
                        },
                        {
                            "id": 128098,
                            "name": "Valley",
                            "latitude": "41.31278000",
                            "longitude": "-96.34614000"
                        },
                        {
                            "id": 128103,
                            "name": "Valley County",
                            "latitude": "41.56731000",
                            "longitude": "-98.98190000"
                        },
                        {
                            "id": 128334,
                            "name": "Wahoo",
                            "latitude": "41.21139000",
                            "longitude": "-96.62030000"
                        },
                        {
                            "id": 128362,
                            "name": "Wakefield",
                            "latitude": "42.26917000",
                            "longitude": "-96.86504000"
                        },
                        {
                            "id": 128571,
                            "name": "Washington County",
                            "latitude": "41.53104000",
                            "longitude": "-96.22203000"
                        },
                        {
                            "id": 128614,
                            "name": "Waterloo",
                            "latitude": "41.28694000",
                            "longitude": "-96.28558000"
                        },
                        {
                            "id": 128671,
                            "name": "Waverly",
                            "latitude": "40.91750000",
                            "longitude": "-96.52834000"
                        },
                        {
                            "id": 128686,
                            "name": "Wayne",
                            "latitude": "42.23056000",
                            "longitude": "-97.01782000"
                        },
                        {
                            "id": 128700,
                            "name": "Wayne County",
                            "latitude": "42.20929000",
                            "longitude": "-97.11926000"
                        },
                        {
                            "id": 128742,
                            "name": "Webster County",
                            "latitude": "40.17643000",
                            "longitude": "-98.49995000"
                        },
                        {
                            "id": 128756,
                            "name": "Weeping Water",
                            "latitude": "40.87000000",
                            "longitude": "-96.14057000"
                        },
                        {
                            "id": 128961,
                            "name": "West Point",
                            "latitude": "41.84167000",
                            "longitude": "-96.70864000"
                        },
                        {
                            "id": 129117,
                            "name": "Wheeler County",
                            "latitude": "41.91477000",
                            "longitude": "-98.52819000"
                        },
                        {
                            "id": 129225,
                            "name": "Wilber",
                            "latitude": "40.48139000",
                            "longitude": "-96.96058000"
                        },
                        {
                            "id": 129462,
                            "name": "Wisner",
                            "latitude": "41.98722000",
                            "longitude": "-96.91421000"
                        },
                        {
                            "id": 129489,
                            "name": "Wood River",
                            "latitude": "40.82057000",
                            "longitude": "-98.60007000"
                        },
                        {
                            "id": 129627,
                            "name": "Wymore",
                            "latitude": "40.12222000",
                            "longitude": "-96.66252000"
                        },
                        {
                            "id": 129693,
                            "name": "York",
                            "latitude": "40.86807000",
                            "longitude": "-97.59200000"
                        },
                        {
                            "id": 129698,
                            "name": "York County",
                            "latitude": "40.87275000",
                            "longitude": "-97.59711000"
                        },
                        {
                            "id": 129737,
                            "name": "Yutan",
                            "latitude": "41.24500000",
                            "longitude": "-96.39725000"
                        }
                    ]
                },
                {
                    "id": 1458,
                    "name": "Nevada",
                    "state_code": "NV",
                    "cities": [
                        {
                            "id": 111095,
                            "name": "Alamo",
                            "latitude": "37.36496000",
                            "longitude": "-115.16446000"
                        },
                        {
                            "id": 111908,
                            "name": "Battle Mountain",
                            "latitude": "40.64213000",
                            "longitude": "-116.93427000"
                        },
                        {
                            "id": 111982,
                            "name": "Beatty",
                            "latitude": "36.90856000",
                            "longitude": "-116.75923000"
                        },
                        {
                            "id": 112602,
                            "name": "Boulder City",
                            "latitude": "35.97859000",
                            "longitude": "-114.83249000"
                        },
                        {
                            "id": 113043,
                            "name": "Bunkerville",
                            "latitude": "36.77303000",
                            "longitude": "-114.12802000"
                        },
                        {
                            "id": 113213,
                            "name": "Caliente",
                            "latitude": "37.61496000",
                            "longitude": "-114.51194000"
                        },
                        {
                            "id": 113399,
                            "name": "Carlin",
                            "latitude": "40.71381000",
                            "longitude": "-116.10397000"
                        },
                        {
                            "id": 113478,
                            "name": "Carson City",
                            "latitude": "39.16380000",
                            "longitude": "-119.76740000"
                        },
                        {
                            "id": 114012,
                            "name": "Churchill County",
                            "latitude": "39.58088000",
                            "longitude": "-118.33578000"
                        },
                        {
                            "id": 114129,
                            "name": "Clark County",
                            "latitude": "36.21520000",
                            "longitude": "-115.01356000"
                        },
                        {
                            "id": 114389,
                            "name": "Cold Springs",
                            "latitude": "39.68019000",
                            "longitude": "-119.97659000"
                        },
                        {
                            "id": 115098,
                            "name": "Dayton",
                            "latitude": "39.23714000",
                            "longitude": "-119.59295000"
                        },
                        {
                            "id": 115434,
                            "name": "Douglas County",
                            "latitude": "38.91224000",
                            "longitude": "-119.61637000"
                        },
                        {
                            "id": 115768,
                            "name": "East Valley",
                            "latitude": "38.94340000",
                            "longitude": "-119.69923000"
                        },
                        {
                            "id": 116008,
                            "name": "Elko",
                            "latitude": "40.83242000",
                            "longitude": "-115.76312000"
                        },
                        {
                            "id": 116009,
                            "name": "Elko County",
                            "latitude": "41.14583000",
                            "longitude": "-115.35776000"
                        },
                        {
                            "id": 116086,
                            "name": "Ely",
                            "latitude": "39.24744000",
                            "longitude": "-114.88863000"
                        },
                        {
                            "id": 116149,
                            "name": "Enterprise",
                            "latitude": "36.02525000",
                            "longitude": "-115.24194000"
                        },
                        {
                            "id": 116186,
                            "name": "Esmeralda County",
                            "latitude": "37.78470000",
                            "longitude": "-117.63237000"
                        },
                        {
                            "id": 116237,
                            "name": "Eureka",
                            "latitude": "39.51271000",
                            "longitude": "-115.96061000"
                        },
                        {
                            "id": 116238,
                            "name": "Eureka County",
                            "latitude": "39.98389000",
                            "longitude": "-116.26856000"
                        },
                        {
                            "id": 116378,
                            "name": "Fallon",
                            "latitude": "39.47353000",
                            "longitude": "-118.77737000"
                        },
                        {
                            "id": 116491,
                            "name": "Fernley",
                            "latitude": "39.60797000",
                            "longitude": "-119.25183000"
                        },
                        {
                            "id": 117078,
                            "name": "Gardnerville",
                            "latitude": "38.94130000",
                            "longitude": "-119.74962000"
                        },
                        {
                            "id": 117079,
                            "name": "Gardnerville Ranchos",
                            "latitude": "38.88824000",
                            "longitude": "-119.74129000"
                        },
                        {
                            "id": 117357,
                            "name": "Golden Valley",
                            "latitude": "39.61547000",
                            "longitude": "-119.82658000"
                        },
                        {
                            "id": 117363,
                            "name": "Goldfield",
                            "latitude": "37.70854000",
                            "longitude": "-117.23563000"
                        },
                        {
                            "id": 118176,
                            "name": "Hawthorne",
                            "latitude": "38.52464000",
                            "longitude": "-118.62458000"
                        },
                        {
                            "id": 118278,
                            "name": "Henderson",
                            "latitude": "36.03970000",
                            "longitude": "-114.98194000"
                        },
                        {
                            "id": 118780,
                            "name": "Humboldt County",
                            "latitude": "41.40684000",
                            "longitude": "-118.11197000"
                        },
                        {
                            "id": 118888,
                            "name": "Incline Village",
                            "latitude": "39.25130000",
                            "longitude": "-119.97297000"
                        },
                        {
                            "id": 118909,
                            "name": "Indian Hills",
                            "latitude": "39.08602000",
                            "longitude": "-119.78407000"
                        },
                        {
                            "id": 119057,
                            "name": "Jackpot",
                            "latitude": "41.98324000",
                            "longitude": "-114.67476000"
                        },
                        {
                            "id": 119273,
                            "name": "Johnson Lane",
                            "latitude": "39.04796000",
                            "longitude": "-119.72212000"
                        },
                        {
                            "id": 119610,
                            "name": "Kingsbury",
                            "latitude": "38.97713000",
                            "longitude": "-119.90685000"
                        },
                        {
                            "id": 120097,
                            "name": "Lander County",
                            "latitude": "39.93381000",
                            "longitude": "-117.03791000"
                        },
                        {
                            "id": 120163,
                            "name": "Las Vegas",
                            "latitude": "36.17497000",
                            "longitude": "-115.13722000"
                        },
                        {
                            "id": 120185,
                            "name": "Laughlin",
                            "latitude": "35.16778000",
                            "longitude": "-114.57302000"
                        },
                        {
                            "id": 120358,
                            "name": "Lemmon Valley",
                            "latitude": "39.63602000",
                            "longitude": "-119.84325000"
                        },
                        {
                            "id": 120532,
                            "name": "Lincoln County",
                            "latitude": "37.64335000",
                            "longitude": "-114.87755000"
                        },
                        {
                            "id": 120825,
                            "name": "Lovelock",
                            "latitude": "40.17935000",
                            "longitude": "-118.47348000"
                        },
                        {
                            "id": 120939,
                            "name": "Lyon County",
                            "latitude": "39.02040000",
                            "longitude": "-119.18920000"
                        },
                        {
                            "id": 121502,
                            "name": "McGill",
                            "latitude": "39.40494000",
                            "longitude": "-114.77863000"
                        },
                        {
                            "id": 121727,
                            "name": "Mesquite",
                            "latitude": "36.80553000",
                            "longitude": "-114.06719000"
                        },
                        {
                            "id": 121930,
                            "name": "Minden",
                            "latitude": "38.95407000",
                            "longitude": "-119.76573000"
                        },
                        {
                            "id": 121936,
                            "name": "Mineral County",
                            "latitude": "38.53881000",
                            "longitude": "-118.43521000"
                        },
                        {
                            "id": 122007,
                            "name": "Moapa Town",
                            "latitude": "36.68219000",
                            "longitude": "-114.59416000"
                        },
                        {
                            "id": 122008,
                            "name": "Moapa Valley",
                            "latitude": "36.58053000",
                            "longitude": "-114.47026000"
                        },
                        {
                            "id": 122017,
                            "name": "Mogul",
                            "latitude": "39.51380000",
                            "longitude": "-119.92603000"
                        },
                        {
                            "id": 122622,
                            "name": "Nellis Air Force Base",
                            "latitude": "36.24607000",
                            "longitude": "-115.05721000"
                        },
                        {
                            "id": 123052,
                            "name": "North Las Vegas",
                            "latitude": "36.19886000",
                            "longitude": "-115.11750000"
                        },
                        {
                            "id": 123222,
                            "name": "Nye County",
                            "latitude": "38.04238000",
                            "longitude": "-116.47193000"
                        },
                        {
                            "id": 123724,
                            "name": "Pahrump",
                            "latitude": "36.20829000",
                            "longitude": "-115.98391000"
                        },
                        {
                            "id": 123819,
                            "name": "Paradise",
                            "latitude": "36.09719000",
                            "longitude": "-115.14666000"
                        },
                        {
                            "id": 124091,
                            "name": "Pershing County",
                            "latitude": "40.44036000",
                            "longitude": "-118.40444000"
                        },
                        {
                            "id": 124273,
                            "name": "Pioche",
                            "latitude": "37.92969000",
                            "longitude": "-114.45221000"
                        },
                        {
                            "id": 125020,
                            "name": "Reno",
                            "latitude": "39.52963000",
                            "longitude": "-119.81380000"
                        },
                        {
                            "id": 125885,
                            "name": "Sandy Valley",
                            "latitude": "35.81692000",
                            "longitude": "-115.63223000"
                        },
                        {
                            "id": 126435,
                            "name": "Silver Springs",
                            "latitude": "39.41547000",
                            "longitude": "-119.22461000"
                        },
                        {
                            "id": 126510,
                            "name": "Smith",
                            "latitude": "38.80047000",
                            "longitude": "-119.32738000"
                        },
                        {
                            "id": 126517,
                            "name": "Smith Valley",
                            "latitude": "38.78421000",
                            "longitude": "-119.34425000"
                        },
                        {
                            "id": 126801,
                            "name": "Spanish Springs",
                            "latitude": "39.64908000",
                            "longitude": "-119.70741000"
                        },
                        {
                            "id": 126805,
                            "name": "Sparks",
                            "latitude": "39.53491000",
                            "longitude": "-119.75269000"
                        },
                        {
                            "id": 126854,
                            "name": "Spring Creek",
                            "latitude": "40.72659000",
                            "longitude": "-115.58590000"
                        },
                        {
                            "id": 126881,
                            "name": "Spring Valley",
                            "latitude": "36.10803000",
                            "longitude": "-115.24500000"
                        },
                        {
                            "id": 126939,
                            "name": "Stagecoach",
                            "latitude": "39.37380000",
                            "longitude": "-119.37406000"
                        },
                        {
                            "id": 127102,
                            "name": "Storey County",
                            "latitude": "39.44653000",
                            "longitude": "-119.52917000"
                        },
                        {
                            "id": 127205,
                            "name": "Summerlin South",
                            "latitude": "36.11708000",
                            "longitude": "-115.33001000"
                        },
                        {
                            "id": 127242,
                            "name": "Sun Valley",
                            "latitude": "39.59630000",
                            "longitude": "-119.77602000"
                        },
                        {
                            "id": 127269,
                            "name": "Sunrise Manor",
                            "latitude": "36.21108000",
                            "longitude": "-115.07306000"
                        },
                        {
                            "id": 127722,
                            "name": "Tonopah",
                            "latitude": "38.06716000",
                            "longitude": "-117.23008000"
                        },
                        {
                            "id": 127731,
                            "name": "Topaz Ranch Estates",
                            "latitude": "38.73565000",
                            "longitude": "-119.50079000"
                        },
                        {
                            "id": 128180,
                            "name": "Verdi",
                            "latitude": "39.51824000",
                            "longitude": "-119.98881000"
                        },
                        {
                            "id": 128290,
                            "name": "Virginia City",
                            "latitude": "39.30963000",
                            "longitude": "-119.64962000"
                        },
                        {
                            "id": 128590,
                            "name": "Washoe County",
                            "latitude": "40.66542000",
                            "longitude": "-119.66430000"
                        },
                        {
                            "id": 128783,
                            "name": "Wells",
                            "latitude": "41.11159000",
                            "longitude": "-114.96449000"
                        },
                        {
                            "id": 129004,
                            "name": "West Wendover",
                            "latitude": "40.73910000",
                            "longitude": "-114.07335000"
                        },
                        {
                            "id": 129153,
                            "name": "White Pine County",
                            "latitude": "39.44216000",
                            "longitude": "-114.90159000"
                        },
                        {
                            "id": 129209,
                            "name": "Whitney",
                            "latitude": "36.09831000",
                            "longitude": "-115.03630000"
                        },
                        {
                            "id": 129361,
                            "name": "Winchester",
                            "latitude": "36.12997000",
                            "longitude": "-115.11889000"
                        },
                        {
                            "id": 129411,
                            "name": "Winnemucca",
                            "latitude": "40.97296000",
                            "longitude": "-117.73568000"
                        },
                        {
                            "id": 129682,
                            "name": "Yerington",
                            "latitude": "38.98575000",
                            "longitude": "-119.16293000"
                        }
                    ]
                },
                {
                    "id": 1404,
                    "name": "New Hampshire",
                    "state_code": "NH",
                    "cities": [
                        {
                            "id": 111155,
                            "name": "Alexandria",
                            "latitude": "43.61146000",
                            "longitude": "-71.79286000"
                        },
                        {
                            "id": 111228,
                            "name": "Alstead",
                            "latitude": "43.14897000",
                            "longitude": "-72.36064000"
                        },
                        {
                            "id": 111326,
                            "name": "Andover",
                            "latitude": "43.43702000",
                            "longitude": "-71.82341000"
                        },
                        {
                            "id": 111376,
                            "name": "Antrim",
                            "latitude": "43.03091000",
                            "longitude": "-71.93897000"
                        },
                        {
                            "id": 111535,
                            "name": "Ashland",
                            "latitude": "43.69535000",
                            "longitude": "-71.63063000"
                        },
                        {
                            "id": 111587,
                            "name": "Atkinson",
                            "latitude": "42.83842000",
                            "longitude": "-71.14700000"
                        },
                        {
                            "id": 111629,
                            "name": "Auburn",
                            "latitude": "43.00453000",
                            "longitude": "-71.34840000"
                        },
                        {
                            "id": 111836,
                            "name": "Barnstead",
                            "latitude": "43.33397000",
                            "longitude": "-71.29284000"
                        },
                        {
                            "id": 111847,
                            "name": "Barrington",
                            "latitude": "43.22286000",
                            "longitude": "-71.04701000"
                        },
                        {
                            "id": 112024,
                            "name": "Bedford",
                            "latitude": "42.94647000",
                            "longitude": "-71.51590000"
                        },
                        {
                            "id": 112065,
                            "name": "Belknap County",
                            "latitude": "43.51869000",
                            "longitude": "-71.42336000"
                        },
                        {
                            "id": 112139,
                            "name": "Belmont",
                            "latitude": "43.44536000",
                            "longitude": "-71.47785000"
                        },
                        {
                            "id": 112238,
                            "name": "Berlin",
                            "latitude": "44.46867000",
                            "longitude": "-71.18508000"
                        },
                        {
                            "id": 112582,
                            "name": "Boscawen",
                            "latitude": "43.31508000",
                            "longitude": "-71.62091000"
                        },
                        {
                            "id": 112620,
                            "name": "Bow Bog",
                            "latitude": "43.12064000",
                            "longitude": "-71.51146000"
                        },
                        {
                            "id": 112730,
                            "name": "Brentwood",
                            "latitude": "42.97870000",
                            "longitude": "-71.07284000"
                        },
                        {
                            "id": 112776,
                            "name": "Bridgewater",
                            "latitude": "43.63841000",
                            "longitude": "-71.73647000"
                        },
                        {
                            "id": 112807,
                            "name": "Bristol",
                            "latitude": "43.59119000",
                            "longitude": "-71.73675000"
                        },
                        {
                            "id": 112868,
                            "name": "Brookline",
                            "latitude": "42.73481000",
                            "longitude": "-71.65813000"
                        },
                        {
                            "id": 113323,
                            "name": "Candia",
                            "latitude": "43.07786000",
                            "longitude": "-71.27673000"
                        },
                        {
                            "id": 113337,
                            "name": "Canterbury",
                            "latitude": "43.33702000",
                            "longitude": "-71.56535000"
                        },
                        {
                            "id": 113461,
                            "name": "Carroll County",
                            "latitude": "43.87391000",
                            "longitude": "-71.20277000"
                        },
                        {
                            "id": 113642,
                            "name": "Center Harbor",
                            "latitude": "43.70980000",
                            "longitude": "-71.46035000"
                        },
                        {
                            "id": 113772,
                            "name": "Charlestown",
                            "latitude": "43.23869000",
                            "longitude": "-72.42453000"
                        },
                        {
                            "id": 113878,
                            "name": "Cheshire County",
                            "latitude": "42.91932000",
                            "longitude": "-72.25118000"
                        },
                        {
                            "id": 113887,
                            "name": "Chester",
                            "latitude": "42.95675000",
                            "longitude": "-71.25728000"
                        },
                        {
                            "id": 113905,
                            "name": "Chesterfield",
                            "latitude": "42.88730000",
                            "longitude": "-72.47037000"
                        },
                        {
                            "id": 113936,
                            "name": "Chichester",
                            "latitude": "43.24925000",
                            "longitude": "-71.39979000"
                        },
                        {
                            "id": 114105,
                            "name": "Claremont",
                            "latitude": "43.37674000",
                            "longitude": "-72.34676000"
                        },
                        {
                            "id": 114397,
                            "name": "Colebrook",
                            "latitude": "44.89449000",
                            "longitude": "-71.49592000"
                        },
                        {
                            "id": 114541,
                            "name": "Concord",
                            "latitude": "43.20814000",
                            "longitude": "-71.53757000"
                        },
                        {
                            "id": 114574,
                            "name": "Contoocook",
                            "latitude": "43.22202000",
                            "longitude": "-71.71397000"
                        },
                        {
                            "id": 114585,
                            "name": "Conway",
                            "latitude": "43.97924000",
                            "longitude": "-71.12035000"
                        },
                        {
                            "id": 114611,
                            "name": "Coos County",
                            "latitude": "44.68960000",
                            "longitude": "-71.30542000"
                        },
                        {
                            "id": 115016,
                            "name": "Danbury",
                            "latitude": "43.52563000",
                            "longitude": "-71.86175000"
                        },
                        {
                            "id": 115036,
                            "name": "Danville",
                            "latitude": "42.91259000",
                            "longitude": "-71.12450000"
                        },
                        {
                            "id": 115182,
                            "name": "Deerfield",
                            "latitude": "44.23062000",
                            "longitude": "-71.61703000"
                        },
                        {
                            "id": 115185,
                            "name": "Deering",
                            "latitude": "43.07314000",
                            "longitude": "-71.84452000"
                        },
                        {
                            "id": 115265,
                            "name": "Derry",
                            "latitude": "42.88064000",
                            "longitude": "-71.32729000"
                        },
                        {
                            "id": 115267,
                            "name": "Derry Village",
                            "latitude": "42.89175000",
                            "longitude": "-71.31201000"
                        },
                        {
                            "id": 115450,
                            "name": "Dover",
                            "latitude": "43.19786000",
                            "longitude": "-70.87367000"
                        },
                        {
                            "id": 115494,
                            "name": "Dublin",
                            "latitude": "42.90758000",
                            "longitude": "-72.06258000"
                        },
                        {
                            "id": 115571,
                            "name": "Durham",
                            "latitude": "43.13397000",
                            "longitude": "-70.92645000"
                        },
                        {
                            "id": 115647,
                            "name": "East Concord",
                            "latitude": "43.24202000",
                            "longitude": "-71.53813000"
                        },
                        {
                            "id": 115698,
                            "name": "East Kingston",
                            "latitude": "42.92564000",
                            "longitude": "-71.01672000"
                        },
                        {
                            "id": 115711,
                            "name": "East Merrimack",
                            "latitude": "42.86814000",
                            "longitude": "-71.48340000"
                        },
                        {
                            "id": 115897,
                            "name": "Effingham",
                            "latitude": "43.76119000",
                            "longitude": "-70.99645000"
                        },
                        {
                            "id": 116127,
                            "name": "Enfield",
                            "latitude": "43.64063000",
                            "longitude": "-72.14398000"
                        },
                        {
                            "id": 116157,
                            "name": "Epping",
                            "latitude": "43.03342000",
                            "longitude": "-71.07423000"
                        },
                        {
                            "id": 116158,
                            "name": "Epsom",
                            "latitude": "43.22286000",
                            "longitude": "-71.33201000"
                        },
                        {
                            "id": 116271,
                            "name": "Exeter",
                            "latitude": "42.98148000",
                            "longitude": "-70.94783000"
                        },
                        {
                            "id": 116420,
                            "name": "Farmington",
                            "latitude": "43.38980000",
                            "longitude": "-71.06506000"
                        },
                        {
                            "id": 116537,
                            "name": "Fitzwilliam",
                            "latitude": "42.78064000",
                            "longitude": "-72.14175000"
                        },
                        {
                            "id": 116813,
                            "name": "Francestown",
                            "latitude": "42.98758000",
                            "longitude": "-71.81258000"
                        },
                        {
                            "id": 116837,
                            "name": "Franklin",
                            "latitude": "43.44424000",
                            "longitude": "-71.64730000"
                        },
                        {
                            "id": 116901,
                            "name": "Freedom",
                            "latitude": "43.81230000",
                            "longitude": "-71.03562000"
                        },
                        {
                            "id": 116922,
                            "name": "Fremont",
                            "latitude": "42.99092000",
                            "longitude": "-71.14256000"
                        },
                        {
                            "id": 117212,
                            "name": "Gilford",
                            "latitude": "43.54758000",
                            "longitude": "-71.40674000"
                        },
                        {
                            "id": 117220,
                            "name": "Gilmanton",
                            "latitude": "43.42425000",
                            "longitude": "-71.41452000"
                        },
                        {
                            "id": 117336,
                            "name": "Goffstown",
                            "latitude": "43.02036000",
                            "longitude": "-71.60035000"
                        },
                        {
                            "id": 117404,
                            "name": "Gorham",
                            "latitude": "44.38784000",
                            "longitude": "-71.17313000"
                        },
                        {
                            "id": 117430,
                            "name": "Grafton",
                            "latitude": "43.55868000",
                            "longitude": "-71.94397000"
                        },
                        {
                            "id": 117433,
                            "name": "Grafton County",
                            "latitude": "43.94074000",
                            "longitude": "-71.82055000"
                        },
                        {
                            "id": 117525,
                            "name": "Grantham",
                            "latitude": "43.48952000",
                            "longitude": "-72.13759000"
                        },
                        {
                            "id": 117652,
                            "name": "Greenfield",
                            "latitude": "42.95064000",
                            "longitude": "-71.87230000"
                        },
                        {
                            "id": 117657,
                            "name": "Greenland",
                            "latitude": "43.03620000",
                            "longitude": "-70.83283000"
                        },
                        {
                            "id": 117692,
                            "name": "Greenville",
                            "latitude": "42.76731000",
                            "longitude": "-71.81230000"
                        },
                        {
                            "id": 117764,
                            "name": "Groveton",
                            "latitude": "44.59867000",
                            "longitude": "-71.51120000"
                        },
                        {
                            "id": 117925,
                            "name": "Hampstead",
                            "latitude": "42.87453000",
                            "longitude": "-71.18117000"
                        },
                        {
                            "id": 117934,
                            "name": "Hampton",
                            "latitude": "42.93759000",
                            "longitude": "-70.83894000"
                        },
                        {
                            "id": 117936,
                            "name": "Hampton Beach",
                            "latitude": "42.90731000",
                            "longitude": "-70.81200000"
                        },
                        {
                            "id": 117938,
                            "name": "Hampton Falls",
                            "latitude": "42.91620000",
                            "longitude": "-70.86366000"
                        },
                        {
                            "id": 117972,
                            "name": "Hanover",
                            "latitude": "43.70229000",
                            "longitude": "-72.28954000"
                        },
                        {
                            "id": 118075,
                            "name": "Harrisville",
                            "latitude": "42.94508000",
                            "longitude": "-72.09647000"
                        },
                        {
                            "id": 118151,
                            "name": "Haverhill",
                            "latitude": "44.03451000",
                            "longitude": "-72.06398000"
                        },
                        {
                            "id": 118293,
                            "name": "Henniker",
                            "latitude": "43.17980000",
                            "longitude": "-71.82230000"
                        },
                        {
                            "id": 118431,
                            "name": "Hill",
                            "latitude": "43.52424000",
                            "longitude": "-71.70091000"
                        },
                        {
                            "id": 118456,
                            "name": "Hillsborough",
                            "latitude": "43.11410000",
                            "longitude": "-71.89920000"
                        },
                        {
                            "id": 118458,
                            "name": "Hillsborough County",
                            "latitude": "42.91531000",
                            "longitude": "-71.71601000"
                        },
                        {
                            "id": 118486,
                            "name": "Hinsdale",
                            "latitude": "42.78619000",
                            "longitude": "-72.48648000"
                        },
                        {
                            "id": 118527,
                            "name": "Holderness",
                            "latitude": "43.73202000",
                            "longitude": "-71.58841000"
                        },
                        {
                            "id": 118548,
                            "name": "Hollis",
                            "latitude": "42.74314000",
                            "longitude": "-71.59174000"
                        },
                        {
                            "id": 118631,
                            "name": "Hooksett",
                            "latitude": "43.09675000",
                            "longitude": "-71.46507000"
                        },
                        {
                            "id": 118657,
                            "name": "Hopkinton",
                            "latitude": "43.19147000",
                            "longitude": "-71.67535000"
                        },
                        {
                            "id": 118743,
                            "name": "Hudson",
                            "latitude": "42.76481000",
                            "longitude": "-71.43979000"
                        },
                        {
                            "id": 119109,
                            "name": "Jaffrey",
                            "latitude": "42.81397000",
                            "longitude": "-72.02314000"
                        },
                        {
                            "id": 119170,
                            "name": "Jefferson",
                            "latitude": "44.41895000",
                            "longitude": "-71.47453000"
                        },
                        {
                            "id": 119421,
                            "name": "Keene",
                            "latitude": "42.93369000",
                            "longitude": "-72.27814000"
                        },
                        {
                            "id": 119482,
                            "name": "Kensington",
                            "latitude": "42.92703000",
                            "longitude": "-70.94394000"
                        },
                        {
                            "id": 119625,
                            "name": "Kingston",
                            "latitude": "42.93648000",
                            "longitude": "-71.05339000"
                        },
                        {
                            "id": 119819,
                            "name": "Laconia",
                            "latitude": "43.52785000",
                            "longitude": "-71.47035000"
                        },
                        {
                            "id": 120084,
                            "name": "Lancaster",
                            "latitude": "44.48895000",
                            "longitude": "-71.56925000"
                        },
                        {
                            "id": 120291,
                            "name": "Lebanon",
                            "latitude": "43.64229000",
                            "longitude": "-72.25176000"
                        },
                        {
                            "id": 120304,
                            "name": "Lee",
                            "latitude": "43.12314000",
                            "longitude": "-71.01145000"
                        },
                        {
                            "id": 120365,
                            "name": "Lempster",
                            "latitude": "43.23841000",
                            "longitude": "-72.21064000"
                        },
                        {
                            "id": 120607,
                            "name": "Litchfield",
                            "latitude": "42.84425000",
                            "longitude": "-71.47979000"
                        },
                        {
                            "id": 120635,
                            "name": "Littleton",
                            "latitude": "44.30617000",
                            "longitude": "-71.77009000"
                        },
                        {
                            "id": 120723,
                            "name": "Londonderry",
                            "latitude": "42.86509000",
                            "longitude": "-71.37395000"
                        },
                        {
                            "id": 120912,
                            "name": "Lyme",
                            "latitude": "43.80951000",
                            "longitude": "-72.15592000"
                        },
                        {
                            "id": 120918,
                            "name": "Lyndeborough",
                            "latitude": "42.90758000",
                            "longitude": "-71.76646000"
                        },
                        {
                            "id": 120977,
                            "name": "Madbury",
                            "latitude": "43.16925000",
                            "longitude": "-70.92395000"
                        },
                        {
                            "id": 120996,
                            "name": "Madison",
                            "latitude": "43.89924000",
                            "longitude": "-71.14840000"
                        },
                        {
                            "id": 121098,
                            "name": "Manchester",
                            "latitude": "42.99564000",
                            "longitude": "-71.45479000"
                        },
                        {
                            "id": 121281,
                            "name": "Marlborough",
                            "latitude": "42.90425000",
                            "longitude": "-72.20786000"
                        },
                        {
                            "id": 121374,
                            "name": "Mason",
                            "latitude": "42.74370000",
                            "longitude": "-71.76896000"
                        },
                        {
                            "id": 121687,
                            "name": "Meredith",
                            "latitude": "43.65757000",
                            "longitude": "-71.50035000"
                        },
                        {
                            "id": 121711,
                            "name": "Merrimack",
                            "latitude": "42.86509000",
                            "longitude": "-71.49340000"
                        },
                        {
                            "id": 121712,
                            "name": "Merrimack County",
                            "latitude": "43.29765000",
                            "longitude": "-71.68019000"
                        },
                        {
                            "id": 121840,
                            "name": "Milan",
                            "latitude": "44.57339000",
                            "longitude": "-71.18508000"
                        },
                        {
                            "id": 121856,
                            "name": "Milford",
                            "latitude": "42.83536000",
                            "longitude": "-71.64896000"
                        },
                        {
                            "id": 122094,
                            "name": "Mont Vernon",
                            "latitude": "42.89453000",
                            "longitude": "-71.67424000"
                        },
                        {
                            "id": 122315,
                            "name": "Moultonborough",
                            "latitude": "43.75480000",
                            "longitude": "-71.39674000"
                        },
                        {
                            "id": 122571,
                            "name": "Nashua",
                            "latitude": "42.76537000",
                            "longitude": "-71.46757000"
                        },
                        {
                            "id": 122669,
                            "name": "New Boston",
                            "latitude": "42.97619000",
                            "longitude": "-71.69396000"
                        },
                        {
                            "id": 122687,
                            "name": "New Castle",
                            "latitude": "43.07231000",
                            "longitude": "-70.71616000"
                        },
                        {
                            "id": 122701,
                            "name": "New Durham",
                            "latitude": "43.43675000",
                            "longitude": "-71.17229000"
                        },
                        {
                            "id": 122731,
                            "name": "New Ipswich",
                            "latitude": "42.74814000",
                            "longitude": "-71.85424000"
                        },
                        {
                            "id": 122744,
                            "name": "New London",
                            "latitude": "43.41396000",
                            "longitude": "-71.98508000"
                        },
                        {
                            "id": 122818,
                            "name": "Newbury",
                            "latitude": "43.32146000",
                            "longitude": "-72.03592000"
                        },
                        {
                            "id": 122836,
                            "name": "Newmarket",
                            "latitude": "43.08286000",
                            "longitude": "-70.93506000"
                        },
                        {
                            "id": 122847,
                            "name": "Newport",
                            "latitude": "43.36535000",
                            "longitude": "-72.17342000"
                        },
                        {
                            "id": 122866,
                            "name": "Newton",
                            "latitude": "42.86953000",
                            "longitude": "-71.03450000"
                        },
                        {
                            "id": 123009,
                            "name": "North Conway",
                            "latitude": "44.05368000",
                            "longitude": "-71.12840000"
                        },
                        {
                            "id": 123035,
                            "name": "North Hampton",
                            "latitude": "42.97259000",
                            "longitude": "-70.82978000"
                        },
                        {
                            "id": 123153,
                            "name": "Northfield",
                            "latitude": "43.43313000",
                            "longitude": "-71.59230000"
                        },
                        {
                            "id": 123164,
                            "name": "Northumberland",
                            "latitude": "44.56339000",
                            "longitude": "-71.55870000"
                        },
                        {
                            "id": 123180,
                            "name": "Northwood",
                            "latitude": "43.19425000",
                            "longitude": "-71.15090000"
                        },
                        {
                            "id": 123205,
                            "name": "Nottingham",
                            "latitude": "43.11453000",
                            "longitude": "-71.09978000"
                        },
                        {
                            "id": 123552,
                            "name": "Orford",
                            "latitude": "43.90535000",
                            "longitude": "-72.14009000"
                        },
                        {
                            "id": 123618,
                            "name": "Ossipee",
                            "latitude": "43.68536000",
                            "longitude": "-71.11673000"
                        },
                        {
                            "id": 123991,
                            "name": "Pelham",
                            "latitude": "42.73453000",
                            "longitude": "-71.32451000"
                        },
                        {
                            "id": 124007,
                            "name": "Pembroke",
                            "latitude": "43.14675000",
                            "longitude": "-71.45757000"
                        },
                        {
                            "id": 124102,
                            "name": "Peterborough",
                            "latitude": "42.87064000",
                            "longitude": "-71.95175000"
                        },
                        {
                            "id": 124207,
                            "name": "Pinardville",
                            "latitude": "42.99425000",
                            "longitude": "-71.50729000"
                        },
                        {
                            "id": 124300,
                            "name": "Pittsfield",
                            "latitude": "43.30591000",
                            "longitude": "-71.32423000"
                        },
                        {
                            "id": 124337,
                            "name": "Plaistow",
                            "latitude": "42.83648000",
                            "longitude": "-71.09478000"
                        },
                        {
                            "id": 124406,
                            "name": "Plymouth",
                            "latitude": "43.75702000",
                            "longitude": "-71.68813000"
                        },
                        {
                            "id": 124570,
                            "name": "Portsmouth",
                            "latitude": "43.07704000",
                            "longitude": "-70.75766000"
                        },
                        {
                            "id": 124919,
                            "name": "Raymond",
                            "latitude": "43.03620000",
                            "longitude": "-71.18340000"
                        },
                        {
                            "id": 125093,
                            "name": "Richmond",
                            "latitude": "42.75481000",
                            "longitude": "-72.27175000"
                        },
                        {
                            "id": 125140,
                            "name": "Rindge",
                            "latitude": "42.75120000",
                            "longitude": "-72.00980000"
                        },
                        {
                            "id": 125265,
                            "name": "Rochester",
                            "latitude": "43.30453000",
                            "longitude": "-70.97562000"
                        },
                        {
                            "id": 125307,
                            "name": "Rockingham County",
                            "latitude": "42.98454000",
                            "longitude": "-71.08897000"
                        },
                        {
                            "id": 125371,
                            "name": "Rollinsford",
                            "latitude": "43.23620000",
                            "longitude": "-70.82034000"
                        },
                        {
                            "id": 125508,
                            "name": "Rumney",
                            "latitude": "43.80535000",
                            "longitude": "-71.81258000"
                        },
                        {
                            "id": 125561,
                            "name": "Rye",
                            "latitude": "43.01342000",
                            "longitude": "-70.77089000"
                        },
                        {
                            "id": 125736,
                            "name": "Salem",
                            "latitude": "42.78842000",
                            "longitude": "-71.20089000"
                        },
                        {
                            "id": 125758,
                            "name": "Salisbury",
                            "latitude": "43.38008000",
                            "longitude": "-71.71702000"
                        },
                        {
                            "id": 125856,
                            "name": "Sanbornton",
                            "latitude": "43.48924000",
                            "longitude": "-71.58230000"
                        },
                        {
                            "id": 125857,
                            "name": "Sanbornville",
                            "latitude": "43.55425000",
                            "longitude": "-71.03090000"
                        },
                        {
                            "id": 125870,
                            "name": "Sandown",
                            "latitude": "42.92870000",
                            "longitude": "-71.18701000"
                        },
                        {
                            "id": 125878,
                            "name": "Sandwich",
                            "latitude": "43.79035000",
                            "longitude": "-71.41118000"
                        },
                        {
                            "id": 126080,
                            "name": "Seabrook",
                            "latitude": "42.89481000",
                            "longitude": "-70.87116000"
                        },
                        {
                            "id": 126590,
                            "name": "Somersworth",
                            "latitude": "43.26175000",
                            "longitude": "-70.86534000"
                        },
                        {
                            "id": 126675,
                            "name": "South Hooksett",
                            "latitude": "43.02647000",
                            "longitude": "-71.43534000"
                        },
                        {
                            "id": 126901,
                            "name": "Springfield",
                            "latitude": "43.49507000",
                            "longitude": "-72.03342000"
                        },
                        {
                            "id": 127117,
                            "name": "Strafford",
                            "latitude": "43.32703000",
                            "longitude": "-71.18423000"
                        },
                        {
                            "id": 127118,
                            "name": "Strafford County",
                            "latitude": "43.29743000",
                            "longitude": "-71.02940000"
                        },
                        {
                            "id": 127125,
                            "name": "Stratford",
                            "latitude": "44.65505000",
                            "longitude": "-71.55564000"
                        },
                        {
                            "id": 127129,
                            "name": "Stratham Station",
                            "latitude": "43.05287000",
                            "longitude": "-70.89533000"
                        },
                        {
                            "id": 127189,
                            "name": "Sullivan County",
                            "latitude": "43.36122000",
                            "longitude": "-72.22240000"
                        },
                        {
                            "id": 127244,
                            "name": "Sunapee",
                            "latitude": "43.38757000",
                            "longitude": "-72.08786000"
                        },
                        {
                            "id": 127247,
                            "name": "Suncook",
                            "latitude": "43.13064000",
                            "longitude": "-71.45312000"
                        },
                        {
                            "id": 127313,
                            "name": "Sutton",
                            "latitude": "43.33424000",
                            "longitude": "-71.95147000"
                        },
                        {
                            "id": 127329,
                            "name": "Swanzey",
                            "latitude": "42.86980000",
                            "longitude": "-72.28175000"
                        },
                        {
                            "id": 127408,
                            "name": "Tamworth",
                            "latitude": "43.85980000",
                            "longitude": "-71.26313000"
                        },
                        {
                            "id": 127499,
                            "name": "Temple",
                            "latitude": "42.81814000",
                            "longitude": "-71.85147000"
                        },
                        {
                            "id": 127602,
                            "name": "Thornton",
                            "latitude": "43.89285000",
                            "longitude": "-71.67591000"
                        },
                        {
                            "id": 127654,
                            "name": "Tilton",
                            "latitude": "43.44230000",
                            "longitude": "-71.58896000"
                        },
                        {
                            "id": 127655,
                            "name": "Tilton-Northfield",
                            "latitude": "43.44300000",
                            "longitude": "-71.59364000"
                        },
                        {
                            "id": 127850,
                            "name": "Troy",
                            "latitude": "42.82397000",
                            "longitude": "-72.18119000"
                        },
                        {
                            "id": 127877,
                            "name": "Tuftonboro",
                            "latitude": "43.69647000",
                            "longitude": "-71.22201000"
                        },
                        {
                            "id": 128021,
                            "name": "Unity",
                            "latitude": "43.29396000",
                            "longitude": "-72.26037000"
                        },
                        {
                            "id": 128361,
                            "name": "Wakefield",
                            "latitude": "43.56813000",
                            "longitude": "-71.03007000"
                        },
                        {
                            "id": 128715,
                            "name": "Weare",
                            "latitude": "43.09480000",
                            "longitude": "-71.73063000"
                        },
                        {
                            "id": 128732,
                            "name": "Webster",
                            "latitude": "43.32897000",
                            "longitude": "-71.71786000"
                        },
                        {
                            "id": 128987,
                            "name": "West Swanzey",
                            "latitude": "42.87008000",
                            "longitude": "-72.32175000"
                        },
                        {
                            "id": 129054,
                            "name": "Westmoreland",
                            "latitude": "42.96203000",
                            "longitude": "-72.44231000"
                        },
                        {
                            "id": 129165,
                            "name": "Whitefield",
                            "latitude": "44.37312000",
                            "longitude": "-71.61008000"
                        },
                        {
                            "id": 129328,
                            "name": "Wilmot",
                            "latitude": "43.45174000",
                            "longitude": "-71.91369000"
                        },
                        {
                            "id": 129345,
                            "name": "Wilton",
                            "latitude": "42.84342000",
                            "longitude": "-71.73507000"
                        },
                        {
                            "id": 129359,
                            "name": "Winchester",
                            "latitude": "42.77342000",
                            "longitude": "-72.38314000"
                        },
                        {
                            "id": 129372,
                            "name": "Windham",
                            "latitude": "42.80064000",
                            "longitude": "-71.30423000"
                        },
                        {
                            "id": 129478,
                            "name": "Wolfeboro",
                            "latitude": "43.58397000",
                            "longitude": "-71.20729000"
                        },
                        {
                            "id": 129564,
                            "name": "Woodstock",
                            "latitude": "43.97757000",
                            "longitude": "-71.68508000"
                        },
                        {
                            "id": 129569,
                            "name": "Woodsville",
                            "latitude": "44.15229000",
                            "longitude": "-72.03731000"
                        }
                    ]
                },
                {
                    "id": 1417,
                    "name": "New Jersey",
                    "state_code": "NJ",
                    "cities": [
                        {
                            "id": 110990,
                            "name": "Absecon",
                            "latitude": "39.42845000",
                            "longitude": "-74.49571000"
                        },
                        {
                            "id": 111195,
                            "name": "Allendale",
                            "latitude": "41.04149000",
                            "longitude": "-74.12903000"
                        },
                        {
                            "id": 111198,
                            "name": "Allentown",
                            "latitude": "40.17789000",
                            "longitude": "-74.58349000"
                        },
                        {
                            "id": 111204,
                            "name": "Alloway",
                            "latitude": "39.56095000",
                            "longitude": "-75.36242000"
                        },
                        {
                            "id": 111220,
                            "name": "Alpha",
                            "latitude": "40.66704000",
                            "longitude": "-75.15740000"
                        },
                        {
                            "id": 111222,
                            "name": "Alpine",
                            "latitude": "40.95593000",
                            "longitude": "-73.93125000"
                        },
                        {
                            "id": 111351,
                            "name": "Annandale",
                            "latitude": "40.64093000",
                            "longitude": "-74.88128000"
                        },
                        {
                            "id": 111508,
                            "name": "Asbury Park",
                            "latitude": "40.22039000",
                            "longitude": "-74.01208000"
                        },
                        {
                            "id": 111531,
                            "name": "Ashland",
                            "latitude": "39.86317000",
                            "longitude": "-75.00600000"
                        },
                        {
                            "id": 111570,
                            "name": "Atco",
                            "latitude": "39.76984000",
                            "longitude": "-74.88739000"
                        },
                        {
                            "id": 111598,
                            "name": "Atlantic City",
                            "latitude": "39.36415000",
                            "longitude": "-74.42306000"
                        },
                        {
                            "id": 111599,
                            "name": "Atlantic County",
                            "latitude": "39.46883000",
                            "longitude": "-74.63373000"
                        },
                        {
                            "id": 111600,
                            "name": "Atlantic Highlands",
                            "latitude": "40.40789000",
                            "longitude": "-74.03431000"
                        },
                        {
                            "id": 111638,
                            "name": "Audubon",
                            "latitude": "39.89095000",
                            "longitude": "-75.07295000"
                        },
                        {
                            "id": 111643,
                            "name": "Audubon Park",
                            "latitude": "39.89650000",
                            "longitude": "-75.08767000"
                        },
                        {
                            "id": 111673,
                            "name": "Avalon",
                            "latitude": "39.10122000",
                            "longitude": "-74.71766000"
                        },
                        {
                            "id": 111677,
                            "name": "Avenel",
                            "latitude": "40.58038000",
                            "longitude": "-74.28515000"
                        },
                        {
                            "id": 111697,
                            "name": "Avon-by-the-Sea",
                            "latitude": "40.19234000",
                            "longitude": "-74.01597000"
                        },
                        {
                            "id": 111825,
                            "name": "Barnegat",
                            "latitude": "39.75318000",
                            "longitude": "-74.22292000"
                        },
                        {
                            "id": 111845,
                            "name": "Barrington",
                            "latitude": "39.86484000",
                            "longitude": "-75.05517000"
                        },
                        {
                            "id": 111945,
                            "name": "Bayonne",
                            "latitude": "40.66871000",
                            "longitude": "-74.11431000"
                        },
                        {
                            "id": 111960,
                            "name": "Bayville",
                            "latitude": "39.90929000",
                            "longitude": "-74.15486000"
                        },
                        {
                            "id": 111964,
                            "name": "Beach Haven",
                            "latitude": "39.55928000",
                            "longitude": "-74.24320000"
                        },
                        {
                            "id": 111965,
                            "name": "Beach Haven West",
                            "latitude": "39.66984000",
                            "longitude": "-74.23181000"
                        },
                        {
                            "id": 111967,
                            "name": "Beachwood",
                            "latitude": "39.93901000",
                            "longitude": "-74.19292000"
                        },
                        {
                            "id": 111983,
                            "name": "Beattystown",
                            "latitude": "40.81315000",
                            "longitude": "-74.84294000"
                        },
                        {
                            "id": 112015,
                            "name": "Beckett",
                            "latitude": "39.75400000",
                            "longitude": "-75.35741000"
                        },
                        {
                            "id": 112031,
                            "name": "Bedminster",
                            "latitude": "40.68066000",
                            "longitude": "-74.64544000"
                        },
                        {
                            "id": 112059,
                            "name": "Belford",
                            "latitude": "40.42594000",
                            "longitude": "-74.08681000"
                        },
                        {
                            "id": 112106,
                            "name": "Belleville",
                            "latitude": "40.79371000",
                            "longitude": "-74.15014000"
                        },
                        {
                            "id": 112121,
                            "name": "Bellmawr",
                            "latitude": "39.86761000",
                            "longitude": "-75.09462000"
                        },
                        {
                            "id": 112133,
                            "name": "Belmar",
                            "latitude": "40.17845000",
                            "longitude": "-74.02180000"
                        },
                        {
                            "id": 112156,
                            "name": "Belvidere",
                            "latitude": "40.82982000",
                            "longitude": "-75.07767000"
                        },
                        {
                            "id": 112218,
                            "name": "Bergen County",
                            "latitude": "40.95977000",
                            "longitude": "-74.07441000"
                        },
                        {
                            "id": 112219,
                            "name": "Bergenfield",
                            "latitude": "40.92760000",
                            "longitude": "-73.99736000"
                        },
                        {
                            "id": 112226,
                            "name": "Berkeley Heights",
                            "latitude": "40.68343000",
                            "longitude": "-74.44265000"
                        },
                        {
                            "id": 112235,
                            "name": "Berlin",
                            "latitude": "39.79123000",
                            "longitude": "-74.92905000"
                        },
                        {
                            "id": 112245,
                            "name": "Bernardsville",
                            "latitude": "40.71871000",
                            "longitude": "-74.56932000"
                        },
                        {
                            "id": 112301,
                            "name": "Beverly",
                            "latitude": "40.06539000",
                            "longitude": "-74.91906000"
                        },
                        {
                            "id": 112397,
                            "name": "Blackwood",
                            "latitude": "39.80234000",
                            "longitude": "-75.06406000"
                        },
                        {
                            "id": 112437,
                            "name": "Bloomfield",
                            "latitude": "40.80677000",
                            "longitude": "-74.18542000"
                        },
                        {
                            "id": 112448,
                            "name": "Bloomingdale",
                            "latitude": "41.00204000",
                            "longitude": "-74.32654000"
                        },
                        {
                            "id": 112501,
                            "name": "Bogota",
                            "latitude": "40.87621000",
                            "longitude": "-74.02986000"
                        },
                        {
                            "id": 112566,
                            "name": "Boonton",
                            "latitude": "40.90260000",
                            "longitude": "-74.40710000"
                        },
                        {
                            "id": 112576,
                            "name": "Bordentown",
                            "latitude": "40.14622000",
                            "longitude": "-74.71183000"
                        },
                        {
                            "id": 112608,
                            "name": "Bound Brook",
                            "latitude": "40.56844000",
                            "longitude": "-74.53849000"
                        },
                        {
                            "id": 112674,
                            "name": "Bradley Beach",
                            "latitude": "40.20234000",
                            "longitude": "-74.01208000"
                        },
                        {
                            "id": 112677,
                            "name": "Bradley Gardens",
                            "latitude": "40.56288000",
                            "longitude": "-74.65460000"
                        },
                        {
                            "id": 112698,
                            "name": "Brass Castle",
                            "latitude": "40.76482000",
                            "longitude": "-75.01101000"
                        },
                        {
                            "id": 112770,
                            "name": "Bridgeton",
                            "latitude": "39.42734000",
                            "longitude": "-75.23408000"
                        },
                        {
                            "id": 112777,
                            "name": "Bridgewater",
                            "latitude": "40.60079000",
                            "longitude": "-74.64815000"
                        },
                        {
                            "id": 112781,
                            "name": "Brielle",
                            "latitude": "40.10789000",
                            "longitude": "-74.05653000"
                        },
                        {
                            "id": 112783,
                            "name": "Brigantine",
                            "latitude": "39.41012000",
                            "longitude": "-74.36459000"
                        },
                        {
                            "id": 112850,
                            "name": "Brookdale",
                            "latitude": "40.83371000",
                            "longitude": "-74.18292000"
                        },
                        {
                            "id": 112865,
                            "name": "Brooklawn",
                            "latitude": "39.87817000",
                            "longitude": "-75.12073000"
                        },
                        {
                            "id": 112921,
                            "name": "Browns Mills",
                            "latitude": "39.97261000",
                            "longitude": "-74.58293000"
                        },
                        {
                            "id": 112939,
                            "name": "Brownville",
                            "latitude": "40.40066000",
                            "longitude": "-74.29515000"
                        },
                        {
                            "id": 112994,
                            "name": "Budd Lake",
                            "latitude": "40.87121000",
                            "longitude": "-74.73405000"
                        },
                        {
                            "id": 112998,
                            "name": "Buena",
                            "latitude": "39.51373000",
                            "longitude": "-74.92462000"
                        },
                        {
                            "id": 113071,
                            "name": "Burlington",
                            "latitude": "40.07122000",
                            "longitude": "-74.86489000"
                        },
                        {
                            "id": 113077,
                            "name": "Burlington County",
                            "latitude": "39.87769000",
                            "longitude": "-74.66820000"
                        },
                        {
                            "id": 113110,
                            "name": "Butler",
                            "latitude": "41.00371000",
                            "longitude": "-74.34154000"
                        },
                        {
                            "id": 113181,
                            "name": "Caldwell",
                            "latitude": "40.83982000",
                            "longitude": "-74.27654000"
                        },
                        {
                            "id": 113214,
                            "name": "Califon",
                            "latitude": "40.71954000",
                            "longitude": "-74.83572000"
                        },
                        {
                            "id": 113263,
                            "name": "Camden",
                            "latitude": "39.92595000",
                            "longitude": "-75.11962000"
                        },
                        {
                            "id": 113271,
                            "name": "Camden County",
                            "latitude": "39.80353000",
                            "longitude": "-74.95976000"
                        },
                        {
                            "id": 113369,
                            "name": "Cape May",
                            "latitude": "38.93511000",
                            "longitude": "-74.90601000"
                        },
                        {
                            "id": 113370,
                            "name": "Cape May County",
                            "latitude": "39.08513000",
                            "longitude": "-74.84998000"
                        },
                        {
                            "id": 113371,
                            "name": "Cape May Court House",
                            "latitude": "39.08261000",
                            "longitude": "-74.82378000"
                        },
                        {
                            "id": 113410,
                            "name": "Carlstadt",
                            "latitude": "40.84038000",
                            "longitude": "-74.09070000"
                        },
                        {
                            "id": 113429,
                            "name": "Carneys Point",
                            "latitude": "39.71122000",
                            "longitude": "-75.47020000"
                        },
                        {
                            "id": 113486,
                            "name": "Carteret",
                            "latitude": "40.57733000",
                            "longitude": "-74.22820000"
                        },
                        {
                            "id": 113608,
                            "name": "Cedar Glen Lakes",
                            "latitude": "39.95234000",
                            "longitude": "-74.39987000"
                        },
                        {
                            "id": 113609,
                            "name": "Cedar Glen West",
                            "latitude": "40.04206000",
                            "longitude": "-74.29265000"
                        },
                        {
                            "id": 113611,
                            "name": "Cedar Grove",
                            "latitude": "40.85177000",
                            "longitude": "-74.22903000"
                        },
                        {
                            "id": 113800,
                            "name": "Chatham",
                            "latitude": "40.74093000",
                            "longitude": "-74.38376000"
                        },
                        {
                            "id": 113860,
                            "name": "Cherry Hill",
                            "latitude": "39.93484000",
                            "longitude": "-75.03073000"
                        },
                        {
                            "id": 113862,
                            "name": "Cherry Hill Mall",
                            "latitude": "39.93595000",
                            "longitude": "-75.00906000"
                        },
                        {
                            "id": 113880,
                            "name": "Chesilhurst",
                            "latitude": "39.73234000",
                            "longitude": "-74.88100000"
                        },
                        {
                            "id": 113889,
                            "name": "Chester",
                            "latitude": "40.78427000",
                            "longitude": "-74.69683000"
                        },
                        {
                            "id": 114027,
                            "name": "Cinnaminson",
                            "latitude": "39.99678000",
                            "longitude": "-74.99267000"
                        },
                        {
                            "id": 114119,
                            "name": "Clark",
                            "latitude": "40.64094000",
                            "longitude": "-74.31070000"
                        },
                        {
                            "id": 114201,
                            "name": "Clayton",
                            "latitude": "39.66011000",
                            "longitude": "-75.09212000"
                        },
                        {
                            "id": 114215,
                            "name": "Clearbrook Park",
                            "latitude": "40.30983000",
                            "longitude": "-74.46460000"
                        },
                        {
                            "id": 114232,
                            "name": "Clementon",
                            "latitude": "39.81150000",
                            "longitude": "-74.98294000"
                        },
                        {
                            "id": 114253,
                            "name": "Cliffside Park",
                            "latitude": "40.82149000",
                            "longitude": "-73.98764000"
                        },
                        {
                            "id": 114254,
                            "name": "Cliffwood Beach",
                            "latitude": "40.44205000",
                            "longitude": "-74.21681000"
                        },
                        {
                            "id": 114259,
                            "name": "Clifton",
                            "latitude": "40.85843000",
                            "longitude": "-74.16376000"
                        },
                        {
                            "id": 114284,
                            "name": "Clinton",
                            "latitude": "40.63677000",
                            "longitude": "-74.90989000"
                        },
                        {
                            "id": 114305,
                            "name": "Closter",
                            "latitude": "40.97315000",
                            "longitude": "-73.96153000"
                        },
                        {
                            "id": 114428,
                            "name": "Collings Lakes",
                            "latitude": "39.59567000",
                            "longitude": "-74.88156000"
                        },
                        {
                            "id": 114429,
                            "name": "Collingswood",
                            "latitude": "39.91817000",
                            "longitude": "-75.07128000"
                        },
                        {
                            "id": 114446,
                            "name": "Colonia",
                            "latitude": "40.57455000",
                            "longitude": "-74.30209000"
                        },
                        {
                            "id": 114546,
                            "name": "Concordia",
                            "latitude": "40.31094000",
                            "longitude": "-74.44821000"
                        },
                        {
                            "id": 114722,
                            "name": "Country Lake Estates",
                            "latitude": "39.94262000",
                            "longitude": "-74.54404000"
                        },
                        {
                            "id": 114764,
                            "name": "Cranbury",
                            "latitude": "40.31622000",
                            "longitude": "-74.51376000"
                        },
                        {
                            "id": 114767,
                            "name": "Crandon Lakes",
                            "latitude": "41.12426000",
                            "longitude": "-74.83989000"
                        },
                        {
                            "id": 114771,
                            "name": "Cranford",
                            "latitude": "40.65844000",
                            "longitude": "-74.29959000"
                        },
                        {
                            "id": 114799,
                            "name": "Cresskill",
                            "latitude": "40.94149000",
                            "longitude": "-73.95930000"
                        },
                        {
                            "id": 114812,
                            "name": "Crestwood Village",
                            "latitude": "39.94817000",
                            "longitude": "-74.36070000"
                        },
                        {
                            "id": 114915,
                            "name": "Cumberland County",
                            "latitude": "39.32807000",
                            "longitude": "-75.12934000"
                        },
                        {
                            "id": 115097,
                            "name": "Dayton",
                            "latitude": "40.37261000",
                            "longitude": "-74.51015000"
                        },
                        {
                            "id": 115197,
                            "name": "Delanco",
                            "latitude": "40.05067000",
                            "longitude": "-74.95350000"
                        },
                        {
                            "id": 115230,
                            "name": "Demarest",
                            "latitude": "40.95732000",
                            "longitude": "-73.96347000"
                        },
                        {
                            "id": 115449,
                            "name": "Dover",
                            "latitude": "40.88399000",
                            "longitude": "-74.56210000"
                        },
                        {
                            "id": 115454,
                            "name": "Dover Beaches North",
                            "latitude": "39.99123000",
                            "longitude": "-74.06375000"
                        },
                        {
                            "id": 115455,
                            "name": "Dover Beaches South",
                            "latitude": "39.95567000",
                            "longitude": "-74.07430000"
                        },
                        {
                            "id": 115518,
                            "name": "Dumont",
                            "latitude": "40.94065000",
                            "longitude": "-73.99681000"
                        },
                        {
                            "id": 115535,
                            "name": "Dunellen",
                            "latitude": "40.58927000",
                            "longitude": "-74.47182000"
                        },
                        {
                            "id": 115641,
                            "name": "East Brunswick",
                            "latitude": "40.42788000",
                            "longitude": "-74.41598000"
                        },
                        {
                            "id": 115664,
                            "name": "East Franklin",
                            "latitude": "40.49330000",
                            "longitude": "-74.47110000"
                        },
                        {
                            "id": 115665,
                            "name": "East Freehold",
                            "latitude": "40.28094000",
                            "longitude": "-74.25126000"
                        },
                        {
                            "id": 115682,
                            "name": "East Hanover",
                            "latitude": "40.82010000",
                            "longitude": "-74.36487000"
                        },
                        {
                            "id": 115720,
                            "name": "East Newark",
                            "latitude": "40.74843000",
                            "longitude": "-74.16181000"
                        },
                        {
                            "id": 115726,
                            "name": "East Orange",
                            "latitude": "40.76732000",
                            "longitude": "-74.20487000"
                        },
                        {
                            "id": 115752,
                            "name": "East Rutherford",
                            "latitude": "40.83399000",
                            "longitude": "-74.09709000"
                        },
                        {
                            "id": 115807,
                            "name": "Eatontown",
                            "latitude": "40.29622000",
                            "longitude": "-74.05097000"
                        },
                        {
                            "id": 115813,
                            "name": "Echelon",
                            "latitude": "39.84845000",
                            "longitude": "-74.99572000"
                        },
                        {
                            "id": 115854,
                            "name": "Edgewater",
                            "latitude": "40.82704000",
                            "longitude": "-73.97569000"
                        },
                        {
                            "id": 115856,
                            "name": "Edgewater Park",
                            "latitude": "40.06817000",
                            "longitude": "-74.90072000"
                        },
                        {
                            "id": 115875,
                            "name": "Edison",
                            "latitude": "40.51872000",
                            "longitude": "-74.41210000"
                        },
                        {
                            "id": 115901,
                            "name": "Egg Harbor City",
                            "latitude": "39.52873000",
                            "longitude": "-74.64794000"
                        },
                        {
                            "id": 115972,
                            "name": "Elizabeth",
                            "latitude": "40.66399000",
                            "longitude": "-74.21070000"
                        },
                        {
                            "id": 116033,
                            "name": "Ellisburg",
                            "latitude": "39.91372000",
                            "longitude": "-75.01045000"
                        },
                        {
                            "id": 116050,
                            "name": "Elmer",
                            "latitude": "39.59511000",
                            "longitude": "-75.17018000"
                        },
                        {
                            "id": 116063,
                            "name": "Elmwood Park",
                            "latitude": "40.90399000",
                            "longitude": "-74.11848000"
                        },
                        {
                            "id": 116078,
                            "name": "Elwood",
                            "latitude": "39.57651000",
                            "longitude": "-74.71683000"
                        },
                        {
                            "id": 116094,
                            "name": "Emerson",
                            "latitude": "40.97621000",
                            "longitude": "-74.02625000"
                        },
                        {
                            "id": 116132,
                            "name": "Englewood",
                            "latitude": "40.89288000",
                            "longitude": "-73.97264000"
                        },
                        {
                            "id": 116134,
                            "name": "Englewood Cliffs",
                            "latitude": "40.88538000",
                            "longitude": "-73.95236000"
                        },
                        {
                            "id": 116136,
                            "name": "Englishtown",
                            "latitude": "40.29733000",
                            "longitude": "-74.35820000"
                        },
                        {
                            "id": 116173,
                            "name": "Erma",
                            "latitude": "38.98776000",
                            "longitude": "-74.90170000"
                        },
                        {
                            "id": 116195,
                            "name": "Essex County",
                            "latitude": "40.78707000",
                            "longitude": "-74.24687000"
                        },
                        {
                            "id": 116198,
                            "name": "Essex Fells",
                            "latitude": "40.82454000",
                            "longitude": "-74.28459000"
                        },
                        {
                            "id": 116204,
                            "name": "Estell Manor",
                            "latitude": "39.41206000",
                            "longitude": "-74.74239000"
                        },
                        {
                            "id": 116268,
                            "name": "Ewing",
                            "latitude": "40.26983000",
                            "longitude": "-74.79988000"
                        },
                        {
                            "id": 116282,
                            "name": "Fair Haven",
                            "latitude": "40.36067000",
                            "longitude": "-74.03819000"
                        },
                        {
                            "id": 116284,
                            "name": "Fair Lawn",
                            "latitude": "40.94038000",
                            "longitude": "-74.13181000"
                        },
                        {
                            "id": 116315,
                            "name": "Fairfield",
                            "latitude": "40.88371000",
                            "longitude": "-74.30598000"
                        },
                        {
                            "id": 116346,
                            "name": "Fairton",
                            "latitude": "39.38178000",
                            "longitude": "-75.21991000"
                        },
                        {
                            "id": 116352,
                            "name": "Fairview",
                            "latitude": "40.81260000",
                            "longitude": "-73.99903000"
                        },
                        {
                            "id": 116394,
                            "name": "Fanwood",
                            "latitude": "40.64094000",
                            "longitude": "-74.38348000"
                        },
                        {
                            "id": 116410,
                            "name": "Farmingdale",
                            "latitude": "40.19650000",
                            "longitude": "-74.16848000"
                        },
                        {
                            "id": 116515,
                            "name": "Finderne",
                            "latitude": "40.56316000",
                            "longitude": "-74.57766000"
                        },
                        {
                            "id": 116562,
                            "name": "Flemington",
                            "latitude": "40.51233000",
                            "longitude": "-74.85933000"
                        },
                        {
                            "id": 116582,
                            "name": "Florence",
                            "latitude": "39.73428000",
                            "longitude": "-74.91822000"
                        },
                        {
                            "id": 116591,
                            "name": "Florham Park",
                            "latitude": "40.78788000",
                            "longitude": "-74.38821000"
                        },
                        {
                            "id": 116624,
                            "name": "Folsom",
                            "latitude": "39.60206000",
                            "longitude": "-74.84267000"
                        },
                        {
                            "id": 116640,
                            "name": "Fords",
                            "latitude": "40.52927000",
                            "longitude": "-74.31598000"
                        },
                        {
                            "id": 116674,
                            "name": "Forked River",
                            "latitude": "39.83984000",
                            "longitude": "-74.19014000"
                        },
                        {
                            "id": 116706,
                            "name": "Fort Dix",
                            "latitude": "40.02984000",
                            "longitude": "-74.61849000"
                        },
                        {
                            "id": 116723,
                            "name": "Fort Lee",
                            "latitude": "40.85093000",
                            "longitude": "-73.97014000"
                        },
                        {
                            "id": 116838,
                            "name": "Franklin",
                            "latitude": "41.12204000",
                            "longitude": "-74.58044000"
                        },
                        {
                            "id": 116842,
                            "name": "Franklin Center",
                            "latitude": "40.53153000",
                            "longitude": "-74.54141000"
                        },
                        {
                            "id": 116866,
                            "name": "Franklin Lakes",
                            "latitude": "41.01676000",
                            "longitude": "-74.20570000"
                        },
                        {
                            "id": 116869,
                            "name": "Franklin Park",
                            "latitude": "40.43899000",
                            "longitude": "-74.53515000"
                        },
                        {
                            "id": 116904,
                            "name": "Freehold",
                            "latitude": "40.26011000",
                            "longitude": "-74.27376000"
                        },
                        {
                            "id": 116934,
                            "name": "Frenchtown",
                            "latitude": "40.52621000",
                            "longitude": "-75.06156000"
                        },
                        {
                            "id": 117081,
                            "name": "Garfield",
                            "latitude": "40.88149000",
                            "longitude": "-74.11320000"
                        },
                        {
                            "id": 117105,
                            "name": "Garwood",
                            "latitude": "40.65177000",
                            "longitude": "-74.32293000"
                        },
                        {
                            "id": 117183,
                            "name": "Gibbsboro",
                            "latitude": "39.83817000",
                            "longitude": "-74.96489000"
                        },
                        {
                            "id": 117184,
                            "name": "Gibbstown",
                            "latitude": "39.82511000",
                            "longitude": "-75.28352000"
                        },
                        {
                            "id": 117238,
                            "name": "Gladstone",
                            "latitude": "40.72260000",
                            "longitude": "-74.66544000"
                        },
                        {
                            "id": 117251,
                            "name": "Glassboro",
                            "latitude": "39.70289000",
                            "longitude": "-75.11184000"
                        },
                        {
                            "id": 117266,
                            "name": "Glen Gardner",
                            "latitude": "40.69677000",
                            "longitude": "-74.94072000"
                        },
                        {
                            "id": 117271,
                            "name": "Glen Ridge",
                            "latitude": "40.80538000",
                            "longitude": "-74.20376000"
                        },
                        {
                            "id": 117273,
                            "name": "Glen Rock",
                            "latitude": "40.96288000",
                            "longitude": "-74.13292000"
                        },
                        {
                            "id": 117290,
                            "name": "Glendora",
                            "latitude": "39.83956000",
                            "longitude": "-75.07351000"
                        },
                        {
                            "id": 117323,
                            "name": "Gloucester City",
                            "latitude": "39.89178000",
                            "longitude": "-75.11629000"
                        },
                        {
                            "id": 117324,
                            "name": "Gloucester County",
                            "latitude": "39.71731000",
                            "longitude": "-75.14167000"
                        },
                        {
                            "id": 117354,
                            "name": "Golden Triangle",
                            "latitude": "39.92789000",
                            "longitude": "-75.03878000"
                        },
                        {
                            "id": 117600,
                            "name": "Green Knoll",
                            "latitude": "40.60010000",
                            "longitude": "-74.61210000"
                        },
                        {
                            "id": 117675,
                            "name": "Greentree",
                            "latitude": "39.89706000",
                            "longitude": "-74.95572000"
                        },
                        {
                            "id": 117766,
                            "name": "Groveville",
                            "latitude": "40.16983000",
                            "longitude": "-74.67155000"
                        },
                        {
                            "id": 117817,
                            "name": "Guttenberg",
                            "latitude": "40.79205000",
                            "longitude": "-74.00375000"
                        },
                        {
                            "id": 117828,
                            "name": "Hackensack",
                            "latitude": "40.88593000",
                            "longitude": "-74.04347000"
                        },
                        {
                            "id": 117829,
                            "name": "Hackettstown",
                            "latitude": "40.85399000",
                            "longitude": "-74.82906000"
                        },
                        {
                            "id": 117831,
                            "name": "Haddon Heights",
                            "latitude": "39.87734000",
                            "longitude": "-75.06462000"
                        },
                        {
                            "id": 117832,
                            "name": "Haddonfield",
                            "latitude": "39.89150000",
                            "longitude": "-75.03767000"
                        },
                        {
                            "id": 117850,
                            "name": "Haledon",
                            "latitude": "40.93565000",
                            "longitude": "-74.18626000"
                        },
                        {
                            "id": 117883,
                            "name": "Hamburg",
                            "latitude": "41.15343000",
                            "longitude": "-74.57627000"
                        },
                        {
                            "id": 117905,
                            "name": "Hamilton Square",
                            "latitude": "40.22733000",
                            "longitude": "-74.65321000"
                        },
                        {
                            "id": 117915,
                            "name": "Hammonton",
                            "latitude": "39.63651000",
                            "longitude": "-74.80239000"
                        },
                        {
                            "id": 117933,
                            "name": "Hampton",
                            "latitude": "40.70704000",
                            "longitude": "-74.95600000"
                        },
                        {
                            "id": 117973,
                            "name": "Hanover",
                            "latitude": "40.80454000",
                            "longitude": "-74.36682000"
                        },
                        {
                            "id": 118009,
                            "name": "Hardwick",
                            "latitude": "41.05454000",
                            "longitude": "-74.93212000"
                        },
                        {
                            "id": 118042,
                            "name": "Harrington Park",
                            "latitude": "40.98371000",
                            "longitude": "-73.97986000"
                        },
                        {
                            "id": 118058,
                            "name": "Harrison",
                            "latitude": "40.74649000",
                            "longitude": "-74.15626000"
                        },
                        {
                            "id": 118121,
                            "name": "Hasbrouck Heights",
                            "latitude": "40.85816000",
                            "longitude": "-74.08070000"
                        },
                        {
                            "id": 118170,
                            "name": "Haworth",
                            "latitude": "40.96093000",
                            "longitude": "-73.99014000"
                        },
                        {
                            "id": 118173,
                            "name": "Hawthorne",
                            "latitude": "40.94926000",
                            "longitude": "-74.15375000"
                        },
                        {
                            "id": 118223,
                            "name": "Heathcote",
                            "latitude": "40.38872000",
                            "longitude": "-74.57571000"
                        },
                        {
                            "id": 118260,
                            "name": "Helmetta",
                            "latitude": "40.37677000",
                            "longitude": "-74.42460000"
                        },
                        {
                            "id": 118385,
                            "name": "High Bridge",
                            "latitude": "40.66705000",
                            "longitude": "-74.89572000"
                        },
                        {
                            "id": 118407,
                            "name": "Highland Lake",
                            "latitude": "41.17676000",
                            "longitude": "-74.45655000"
                        },
                        {
                            "id": 118413,
                            "name": "Highland Park",
                            "latitude": "40.49594000",
                            "longitude": "-74.42432000"
                        },
                        {
                            "id": 118418,
                            "name": "Highlands",
                            "latitude": "40.40372000",
                            "longitude": "-73.99153000"
                        },
                        {
                            "id": 118424,
                            "name": "Hightstown",
                            "latitude": "40.26955000",
                            "longitude": "-74.52321000"
                        },
                        {
                            "id": 118461,
                            "name": "Hillsdale",
                            "latitude": "41.00260000",
                            "longitude": "-74.04042000"
                        },
                        {
                            "id": 118464,
                            "name": "Hillside",
                            "latitude": "40.70121000",
                            "longitude": "-74.23015000"
                        },
                        {
                            "id": 118494,
                            "name": "Ho-Ho-Kus",
                            "latitude": "40.99649000",
                            "longitude": "-74.10125000"
                        },
                        {
                            "id": 118502,
                            "name": "Hoboken",
                            "latitude": "40.74399000",
                            "longitude": "-74.03236000"
                        },
                        {
                            "id": 118530,
                            "name": "Holiday City South",
                            "latitude": "39.95324000",
                            "longitude": "-74.23778000"
                        },
                        {
                            "id": 118531,
                            "name": "Holiday City-Berkeley",
                            "latitude": "39.96380000",
                            "longitude": "-74.27803000"
                        },
                        {
                            "id": 118532,
                            "name": "Holiday Heights",
                            "latitude": "39.94595000",
                            "longitude": "-74.25403000"
                        },
                        {
                            "id": 118640,
                            "name": "Hopatcong",
                            "latitude": "40.93288000",
                            "longitude": "-74.65933000"
                        },
                        {
                            "id": 118641,
                            "name": "Hopatcong Hills",
                            "latitude": "40.94399000",
                            "longitude": "-74.67072000"
                        },
                        {
                            "id": 118650,
                            "name": "Hopewell",
                            "latitude": "40.38927000",
                            "longitude": "-74.76183000"
                        },
                        {
                            "id": 118749,
                            "name": "Hudson County",
                            "latitude": "40.73094000",
                            "longitude": "-74.07594000"
                        },
                        {
                            "id": 118789,
                            "name": "Hunterdon County",
                            "latitude": "40.56729000",
                            "longitude": "-74.91222000"
                        },
                        {
                            "id": 119009,
                            "name": "Irvington",
                            "latitude": "40.73232000",
                            "longitude": "-74.23487000"
                        },
                        {
                            "id": 119019,
                            "name": "Iselin",
                            "latitude": "40.57538000",
                            "longitude": "-74.32237000"
                        },
                        {
                            "id": 119025,
                            "name": "Island Heights",
                            "latitude": "39.94206000",
                            "longitude": "-74.14986000"
                        },
                        {
                            "id": 119066,
                            "name": "Jackson",
                            "latitude": "39.77650000",
                            "longitude": "-74.86238000"
                        },
                        {
                            "id": 119118,
                            "name": "Jamesburg",
                            "latitude": "40.35261000",
                            "longitude": "-74.44015000"
                        },
                        {
                            "id": 119231,
                            "name": "Jersey City",
                            "latitude": "40.72816000",
                            "longitude": "-74.07764000"
                        },
                        {
                            "id": 119406,
                            "name": "Keansburg",
                            "latitude": "40.44177000",
                            "longitude": "-74.12986000"
                        },
                        {
                            "id": 119412,
                            "name": "Kearny",
                            "latitude": "40.76843000",
                            "longitude": "-74.14542000"
                        },
                        {
                            "id": 119449,
                            "name": "Kendall Park",
                            "latitude": "40.42094000",
                            "longitude": "-74.56071000"
                        },
                        {
                            "id": 119457,
                            "name": "Kenilworth",
                            "latitude": "40.67649000",
                            "longitude": "-74.29070000"
                        },
                        {
                            "id": 119502,
                            "name": "Kenvil",
                            "latitude": "40.87982000",
                            "longitude": "-74.61849000"
                        },
                        {
                            "id": 119544,
                            "name": "Keyport",
                            "latitude": "40.43316000",
                            "longitude": "-74.19959000"
                        },
                        {
                            "id": 119626,
                            "name": "Kingston",
                            "latitude": "40.37538000",
                            "longitude": "-74.61349000"
                        },
                        {
                            "id": 119631,
                            "name": "Kingston Estates",
                            "latitude": "39.92372000",
                            "longitude": "-74.98795000"
                        },
                        {
                            "id": 119639,
                            "name": "Kinnelon",
                            "latitude": "41.00176000",
                            "longitude": "-74.36710000"
                        },
                        {
                            "id": 119888,
                            "name": "Lake Como",
                            "latitude": "40.15984000",
                            "longitude": "-74.02819000"
                        },
                        {
                            "id": 119944,
                            "name": "Lake Mohawk",
                            "latitude": "41.01843000",
                            "longitude": "-74.66016000"
                        },
                        {
                            "id": 119985,
                            "name": "Lake Telemark",
                            "latitude": "40.95677000",
                            "longitude": "-74.49793000"
                        },
                        {
                            "id": 120011,
                            "name": "Lakehurst",
                            "latitude": "40.01456000",
                            "longitude": "-74.31126000"
                        },
                        {
                            "id": 120048,
                            "name": "Lakewood",
                            "latitude": "40.09789000",
                            "longitude": "-74.21764000"
                        },
                        {
                            "id": 120069,
                            "name": "Lambertville",
                            "latitude": "40.36594000",
                            "longitude": "-74.94294000"
                        },
                        {
                            "id": 120098,
                            "name": "Landing",
                            "latitude": "40.90510000",
                            "longitude": "-74.66516000"
                        },
                        {
                            "id": 120199,
                            "name": "Laurel Lake",
                            "latitude": "39.33956000",
                            "longitude": "-75.02990000"
                        },
                        {
                            "id": 120201,
                            "name": "Laurel Springs",
                            "latitude": "39.82011000",
                            "longitude": "-75.00628000"
                        },
                        {
                            "id": 120205,
                            "name": "Laurence Harbor",
                            "latitude": "40.45677000",
                            "longitude": "-74.24653000"
                        },
                        {
                            "id": 120216,
                            "name": "Lavallette",
                            "latitude": "39.97040000",
                            "longitude": "-74.06875000"
                        },
                        {
                            "id": 120223,
                            "name": "Lawnside",
                            "latitude": "39.86650000",
                            "longitude": "-75.02823000"
                        },
                        {
                            "id": 120246,
                            "name": "Lawrenceville",
                            "latitude": "40.29733000",
                            "longitude": "-74.72960000"
                        },
                        {
                            "id": 120292,
                            "name": "Lebanon",
                            "latitude": "40.64177000",
                            "longitude": "-74.83600000"
                        },
                        {
                            "id": 120341,
                            "name": "Leisure Knoll",
                            "latitude": "40.01901000",
                            "longitude": "-74.29209000"
                        },
                        {
                            "id": 120342,
                            "name": "Leisure Village",
                            "latitude": "40.04262000",
                            "longitude": "-74.18486000"
                        },
                        {
                            "id": 120343,
                            "name": "Leisure Village East",
                            "latitude": "40.03012000",
                            "longitude": "-74.16431000"
                        },
                        {
                            "id": 120344,
                            "name": "Leisure Village West-Pine Lake Park",
                            "latitude": "40.00416000",
                            "longitude": "-74.26629000"
                        },
                        {
                            "id": 120346,
                            "name": "Leisuretowne",
                            "latitude": "39.89234000",
                            "longitude": "-74.70210000"
                        },
                        {
                            "id": 120389,
                            "name": "Leonardo",
                            "latitude": "40.41733000",
                            "longitude": "-74.06208000"
                        },
                        {
                            "id": 120391,
                            "name": "Leonia",
                            "latitude": "40.86149000",
                            "longitude": "-73.98819000"
                        },
                        {
                            "id": 120542,
                            "name": "Lincoln Park",
                            "latitude": "40.92427000",
                            "longitude": "-74.30209000"
                        },
                        {
                            "id": 120556,
                            "name": "Lincroft",
                            "latitude": "40.33067000",
                            "longitude": "-74.12097000"
                        },
                        {
                            "id": 120564,
                            "name": "Linden",
                            "latitude": "40.62205000",
                            "longitude": "-74.24459000"
                        },
                        {
                            "id": 120569,
                            "name": "Lindenwold",
                            "latitude": "39.82428000",
                            "longitude": "-74.99767000"
                        },
                        {
                            "id": 120592,
                            "name": "Linwood",
                            "latitude": "39.33984000",
                            "longitude": "-74.57516000"
                        },
                        {
                            "id": 120618,
                            "name": "Little Falls",
                            "latitude": "40.86899000",
                            "longitude": "-74.20820000"
                        },
                        {
                            "id": 120620,
                            "name": "Little Ferry",
                            "latitude": "40.85288000",
                            "longitude": "-74.04208000"
                        },
                        {
                            "id": 120630,
                            "name": "Little Silver",
                            "latitude": "40.33678000",
                            "longitude": "-74.04708000"
                        },
                        {
                            "id": 120651,
                            "name": "Livingston",
                            "latitude": "40.79593000",
                            "longitude": "-74.31487000"
                        },
                        {
                            "id": 120689,
                            "name": "Lodi",
                            "latitude": "40.88232000",
                            "longitude": "-74.08320000"
                        },
                        {
                            "id": 120740,
                            "name": "Long Branch",
                            "latitude": "40.30428000",
                            "longitude": "-73.99236000"
                        },
                        {
                            "id": 120750,
                            "name": "Long Valley",
                            "latitude": "40.78593000",
                            "longitude": "-74.78016000"
                        },
                        {
                            "id": 120921,
                            "name": "Lyndhurst",
                            "latitude": "40.81204000",
                            "longitude": "-74.12431000"
                        },
                        {
                            "id": 120998,
                            "name": "Madison",
                            "latitude": "40.75982000",
                            "longitude": "-74.41710000"
                        },
                        {
                            "id": 121024,
                            "name": "Madison Park",
                            "latitude": "40.45150000",
                            "longitude": "-74.30792000"
                        },
                        {
                            "id": 121037,
                            "name": "Magnolia",
                            "latitude": "39.85456000",
                            "longitude": "-75.03906000"
                        },
                        {
                            "id": 121048,
                            "name": "Mahwah",
                            "latitude": "41.08871000",
                            "longitude": "-74.14376000"
                        },
                        {
                            "id": 121079,
                            "name": "Manahawkin",
                            "latitude": "39.69540000",
                            "longitude": "-74.25875000"
                        },
                        {
                            "id": 121081,
                            "name": "Manasquan",
                            "latitude": "40.12623000",
                            "longitude": "-74.04930000"
                        },
                        {
                            "id": 121162,
                            "name": "Manville",
                            "latitude": "40.54094000",
                            "longitude": "-74.58766000"
                        },
                        {
                            "id": 121172,
                            "name": "Maple Shade",
                            "latitude": "39.95261000",
                            "longitude": "-74.99239000"
                        },
                        {
                            "id": 121179,
                            "name": "Maplewood",
                            "latitude": "40.73121000",
                            "longitude": "-74.27348000"
                        },
                        {
                            "id": 121207,
                            "name": "Margate City",
                            "latitude": "39.32789000",
                            "longitude": "-74.50349000"
                        },
                        {
                            "id": 121274,
                            "name": "Marlboro",
                            "latitude": "40.31539000",
                            "longitude": "-74.24626000"
                        },
                        {
                            "id": 121288,
                            "name": "Marlton",
                            "latitude": "39.89122000",
                            "longitude": "-74.92183000"
                        },
                        {
                            "id": 121351,
                            "name": "Martinsville",
                            "latitude": "40.60121000",
                            "longitude": "-74.55905000"
                        },
                        {
                            "id": 121400,
                            "name": "Matawan",
                            "latitude": "40.41483000",
                            "longitude": "-74.22959000"
                        },
                        {
                            "id": 121447,
                            "name": "Mays Landing",
                            "latitude": "39.45234000",
                            "longitude": "-74.72766000"
                        },
                        {
                            "id": 121458,
                            "name": "Maywood",
                            "latitude": "40.90260000",
                            "longitude": "-74.06181000"
                        },
                        {
                            "id": 121507,
                            "name": "McGuire AFB",
                            "latitude": "40.03977000",
                            "longitude": "-74.58174000"
                        },
                        {
                            "id": 121597,
                            "name": "Medford Lakes",
                            "latitude": "39.85845000",
                            "longitude": "-74.80294000"
                        },
                        {
                            "id": 121651,
                            "name": "Mendham",
                            "latitude": "40.77593000",
                            "longitude": "-74.60071000"
                        },
                        {
                            "id": 121679,
                            "name": "Mercer County",
                            "latitude": "40.28340000",
                            "longitude": "-74.70169000"
                        },
                        {
                            "id": 121684,
                            "name": "Mercerville",
                            "latitude": "40.23705000",
                            "longitude": "-74.68655000"
                        },
                        {
                            "id": 121685,
                            "name": "Mercerville-Hamilton Square",
                            "latitude": "40.23126000",
                            "longitude": "-74.67223000"
                        },
                        {
                            "id": 121686,
                            "name": "Merchantville",
                            "latitude": "39.94734000",
                            "longitude": "-75.06656000"
                        },
                        {
                            "id": 121738,
                            "name": "Metuchen",
                            "latitude": "40.54316000",
                            "longitude": "-74.36320000"
                        },
                        {
                            "id": 121774,
                            "name": "Middlebush",
                            "latitude": "40.49760000",
                            "longitude": "-74.52932000"
                        },
                        {
                            "id": 121777,
                            "name": "Middlesex",
                            "latitude": "40.57260000",
                            "longitude": "-74.49265000"
                        },
                        {
                            "id": 121781,
                            "name": "Middlesex County",
                            "latitude": "40.44004000",
                            "longitude": "-74.40889000"
                        },
                        {
                            "id": 121806,
                            "name": "Midland Park",
                            "latitude": "40.98926000",
                            "longitude": "-74.14070000"
                        },
                        {
                            "id": 121854,
                            "name": "Milford",
                            "latitude": "40.56871000",
                            "longitude": "-75.09462000"
                        },
                        {
                            "id": 121902,
                            "name": "Milltown",
                            "latitude": "40.45622000",
                            "longitude": "-74.44321000"
                        },
                        {
                            "id": 121904,
                            "name": "Millville",
                            "latitude": "39.40206000",
                            "longitude": "-75.03934000"
                        },
                        {
                            "id": 122045,
                            "name": "Monmouth Beach",
                            "latitude": "40.33039000",
                            "longitude": "-73.98153000"
                        },
                        {
                            "id": 122046,
                            "name": "Monmouth County",
                            "latitude": "40.28755000",
                            "longitude": "-74.15815000"
                        },
                        {
                            "id": 122047,
                            "name": "Monmouth Junction",
                            "latitude": "40.37900000",
                            "longitude": "-74.54654000"
                        },
                        {
                            "id": 122106,
                            "name": "Montclair",
                            "latitude": "40.82593000",
                            "longitude": "-74.20903000"
                        },
                        {
                            "id": 122187,
                            "name": "Montvale",
                            "latitude": "41.04676000",
                            "longitude": "-74.02292000"
                        },
                        {
                            "id": 122198,
                            "name": "Moonachie",
                            "latitude": "40.84121000",
                            "longitude": "-74.04514000"
                        },
                        {
                            "id": 122208,
                            "name": "Moorestown-Lenola",
                            "latitude": "39.96591000",
                            "longitude": "-74.96441000"
                        },
                        {
                            "id": 122254,
                            "name": "Morganville",
                            "latitude": "40.37650000",
                            "longitude": "-74.24431000"
                        },
                        {
                            "id": 122271,
                            "name": "Morris County",
                            "latitude": "40.86203000",
                            "longitude": "-74.54444000"
                        },
                        {
                            "id": 122274,
                            "name": "Morris Plains",
                            "latitude": "40.82177000",
                            "longitude": "-74.48099000"
                        },
                        {
                            "id": 122282,
                            "name": "Morristown",
                            "latitude": "40.79677000",
                            "longitude": "-74.48154000"
                        },
                        {
                            "id": 122333,
                            "name": "Mount Arlington",
                            "latitude": "40.92593000",
                            "longitude": "-74.63488000"
                        },
                        {
                            "id": 122342,
                            "name": "Mount Ephraim",
                            "latitude": "39.87845000",
                            "longitude": "-75.09267000"
                        },
                        {
                            "id": 122348,
                            "name": "Mount Holly",
                            "latitude": "39.99289000",
                            "longitude": "-74.78766000"
                        },
                        {
                            "id": 122361,
                            "name": "Mount Laurel",
                            "latitude": "39.93400000",
                            "longitude": "-74.89100000"
                        },
                        {
                            "id": 122422,
                            "name": "Mountain Lakes",
                            "latitude": "40.89482000",
                            "longitude": "-74.43293000"
                        },
                        {
                            "id": 122438,
                            "name": "Mountainside",
                            "latitude": "40.67232000",
                            "longitude": "-74.35737000"
                        },
                        {
                            "id": 122461,
                            "name": "Mullica Hill",
                            "latitude": "39.73928000",
                            "longitude": "-75.22407000"
                        },
                        {
                            "id": 122527,
                            "name": "Mystic Island",
                            "latitude": "39.54428000",
                            "longitude": "-74.38237000"
                        },
                        {
                            "id": 122593,
                            "name": "National Park",
                            "latitude": "39.86595000",
                            "longitude": "-75.17879000"
                        },
                        {
                            "id": 122604,
                            "name": "Navesink",
                            "latitude": "40.39955000",
                            "longitude": "-74.03542000"
                        },
                        {
                            "id": 122638,
                            "name": "Neptune City",
                            "latitude": "40.20011000",
                            "longitude": "-74.02792000"
                        },
                        {
                            "id": 122645,
                            "name": "Netcong",
                            "latitude": "40.89899000",
                            "longitude": "-74.70655000"
                        },
                        {
                            "id": 122677,
                            "name": "New Brunswick",
                            "latitude": "40.48622000",
                            "longitude": "-74.45182000"
                        },
                        {
                            "id": 122703,
                            "name": "New Egypt",
                            "latitude": "40.06761000",
                            "longitude": "-74.53071000"
                        },
                        {
                            "id": 122755,
                            "name": "New Milford",
                            "latitude": "40.93510000",
                            "longitude": "-74.01903000"
                        },
                        {
                            "id": 122768,
                            "name": "New Providence",
                            "latitude": "40.69843000",
                            "longitude": "-74.40154000"
                        },
                        {
                            "id": 122803,
                            "name": "Newark",
                            "latitude": "40.73566000",
                            "longitude": "-74.17237000"
                        },
                        {
                            "id": 122828,
                            "name": "Newfield",
                            "latitude": "39.54688000",
                            "longitude": "-75.02636000"
                        },
                        {
                            "id": 122867,
                            "name": "Newton",
                            "latitude": "41.05815000",
                            "longitude": "-74.75267000"
                        },
                        {
                            "id": 122965,
                            "name": "North Arlington",
                            "latitude": "40.78843000",
                            "longitude": "-74.13320000"
                        },
                        {
                            "id": 122978,
                            "name": "North Beach Haven",
                            "latitude": "39.57317000",
                            "longitude": "-74.23153000"
                        },
                        {
                            "id": 122987,
                            "name": "North Bergen",
                            "latitude": "40.80427000",
                            "longitude": "-74.01208000"
                        },
                        {
                            "id": 122999,
                            "name": "North Caldwell",
                            "latitude": "40.86482000",
                            "longitude": "-74.25820000"
                        },
                        {
                            "id": 123000,
                            "name": "North Cape May",
                            "latitude": "38.98206000",
                            "longitude": "-74.95795000"
                        },
                        {
                            "id": 123034,
                            "name": "North Haledon",
                            "latitude": "40.95510000",
                            "longitude": "-74.18598000"
                        },
                        {
                            "id": 123068,
                            "name": "North Middletown",
                            "latitude": "40.43955000",
                            "longitude": "-74.11903000"
                        },
                        {
                            "id": 123080,
                            "name": "North Plainfield",
                            "latitude": "40.63010000",
                            "longitude": "-74.42737000"
                        },
                        {
                            "id": 123129,
                            "name": "North Wildwood",
                            "latitude": "39.00067000",
                            "longitude": "-74.79933000"
                        },
                        {
                            "id": 123149,
                            "name": "Northfield",
                            "latitude": "39.37039000",
                            "longitude": "-74.55015000"
                        },
                        {
                            "id": 123169,
                            "name": "Northvale",
                            "latitude": "41.00649000",
                            "longitude": "-73.94903000"
                        },
                        {
                            "id": 123200,
                            "name": "Norwood",
                            "latitude": "40.99815000",
                            "longitude": "-73.96180000"
                        },
                        {
                            "id": 123219,
                            "name": "Nutley",
                            "latitude": "40.82232000",
                            "longitude": "-74.15987000"
                        },
                        {
                            "id": 123265,
                            "name": "Oak Valley",
                            "latitude": "39.80122000",
                            "longitude": "-75.16240000"
                        },
                        {
                            "id": 123280,
                            "name": "Oakhurst",
                            "latitude": "40.27094000",
                            "longitude": "-74.01625000"
                        },
                        {
                            "id": 123290,
                            "name": "Oakland",
                            "latitude": "41.01315000",
                            "longitude": "-74.26431000"
                        },
                        {
                            "id": 123301,
                            "name": "Oaklyn",
                            "latitude": "39.90095000",
                            "longitude": "-75.08462000"
                        },
                        {
                            "id": 123323,
                            "name": "Ocean Acres",
                            "latitude": "39.74345000",
                            "longitude": "-74.28098000"
                        },
                        {
                            "id": 123327,
                            "name": "Ocean City",
                            "latitude": "39.27762000",
                            "longitude": "-74.57460000"
                        },
                        {
                            "id": 123328,
                            "name": "Ocean County",
                            "latitude": "39.86600000",
                            "longitude": "-74.25003000"
                        },
                        {
                            "id": 123329,
                            "name": "Ocean Gate",
                            "latitude": "39.92679000",
                            "longitude": "-74.13375000"
                        },
                        {
                            "id": 123331,
                            "name": "Ocean Grove",
                            "latitude": "40.21206000",
                            "longitude": "-74.00653000"
                        },
                        {
                            "id": 123342,
                            "name": "Oceanport",
                            "latitude": "40.31817000",
                            "longitude": "-74.01514000"
                        },
                        {
                            "id": 123371,
                            "name": "Ogdensburg",
                            "latitude": "41.08176000",
                            "longitude": "-74.59238000"
                        },
                        {
                            "id": 123416,
                            "name": "Old Bridge",
                            "latitude": "40.41483000",
                            "longitude": "-74.36543000"
                        },
                        {
                            "id": 123429,
                            "name": "Old Tappan",
                            "latitude": "41.01065000",
                            "longitude": "-73.99125000"
                        },
                        {
                            "id": 123445,
                            "name": "Olivet",
                            "latitude": "39.54817000",
                            "longitude": "-75.15463000"
                        },
                        {
                            "id": 123503,
                            "name": "Oradell",
                            "latitude": "40.95871000",
                            "longitude": "-74.03681000"
                        },
                        {
                            "id": 123509,
                            "name": "Orange",
                            "latitude": "40.77066000",
                            "longitude": "-74.23265000"
                        },
                        {
                            "id": 123686,
                            "name": "Oxford",
                            "latitude": "40.80315000",
                            "longitude": "-74.98962000"
                        },
                        {
                            "id": 123739,
                            "name": "Palisades Park",
                            "latitude": "40.84816000",
                            "longitude": "-73.99764000"
                        },
                        {
                            "id": 123775,
                            "name": "Palmyra",
                            "latitude": "40.00706000",
                            "longitude": "-75.02823000"
                        },
                        {
                            "id": 123826,
                            "name": "Paramus",
                            "latitude": "40.94454000",
                            "longitude": "-74.07542000"
                        },
                        {
                            "id": 123852,
                            "name": "Park Ridge",
                            "latitude": "41.03760000",
                            "longitude": "-74.04070000"
                        },
                        {
                            "id": 123890,
                            "name": "Parsippany",
                            "latitude": "40.85788000",
                            "longitude": "-74.42599000"
                        },
                        {
                            "id": 123907,
                            "name": "Passaic",
                            "latitude": "40.85677000",
                            "longitude": "-74.12848000"
                        },
                        {
                            "id": 123908,
                            "name": "Passaic County",
                            "latitude": "41.03370000",
                            "longitude": "-74.30032000"
                        },
                        {
                            "id": 123911,
                            "name": "Paterson",
                            "latitude": "40.91677000",
                            "longitude": "-74.17181000"
                        },
                        {
                            "id": 123926,
                            "name": "Paulsboro",
                            "latitude": "39.83039000",
                            "longitude": "-75.24046000"
                        },
                        {
                            "id": 123961,
                            "name": "Peapack",
                            "latitude": "40.71677000",
                            "longitude": "-74.65655000"
                        },
                        {
                            "id": 124000,
                            "name": "Pemberton",
                            "latitude": "39.97206000",
                            "longitude": "-74.68294000"
                        },
                        {
                            "id": 124001,
                            "name": "Pemberton Heights",
                            "latitude": "39.96261000",
                            "longitude": "-74.67877000"
                        },
                        {
                            "id": 124029,
                            "name": "Pennington",
                            "latitude": "40.32844000",
                            "longitude": "-74.79072000"
                        },
                        {
                            "id": 124033,
                            "name": "Penns Grove",
                            "latitude": "39.72956000",
                            "longitude": "-75.46797000"
                        },
                        {
                            "id": 124034,
                            "name": "Pennsauken",
                            "latitude": "39.95622000",
                            "longitude": "-75.05795000"
                        },
                        {
                            "id": 124039,
                            "name": "Pennsville",
                            "latitude": "39.65345000",
                            "longitude": "-75.51659000"
                        },
                        {
                            "id": 124094,
                            "name": "Perth Amboy",
                            "latitude": "40.50677000",
                            "longitude": "-74.26542000"
                        },
                        {
                            "id": 124140,
                            "name": "Phillipsburg",
                            "latitude": "40.69371000",
                            "longitude": "-75.19018000"
                        },
                        {
                            "id": 124213,
                            "name": "Pine Beach",
                            "latitude": "39.93595000",
                            "longitude": "-74.17097000"
                        },
                        {
                            "id": 124224,
                            "name": "Pine Hill",
                            "latitude": "39.78428000",
                            "longitude": "-74.99211000"
                        },
                        {
                            "id": 124233,
                            "name": "Pine Lake Park",
                            "latitude": "40.00317000",
                            "longitude": "-74.25653000"
                        },
                        {
                            "id": 124245,
                            "name": "Pine Ridge at Crestwood",
                            "latitude": "39.95456000",
                            "longitude": "-74.31515000"
                        },
                        {
                            "id": 124282,
                            "name": "Piscataway",
                            "latitude": "40.49927000",
                            "longitude": "-74.39904000"
                        },
                        {
                            "id": 124287,
                            "name": "Pitman",
                            "latitude": "39.73289000",
                            "longitude": "-75.13157000"
                        },
                        {
                            "id": 124321,
                            "name": "Plainfield",
                            "latitude": "40.63371000",
                            "longitude": "-74.40737000"
                        },
                        {
                            "id": 124327,
                            "name": "Plainsboro Center",
                            "latitude": "40.33177000",
                            "longitude": "-74.59460000"
                        },
                        {
                            "id": 124386,
                            "name": "Pleasantville",
                            "latitude": "39.38984000",
                            "longitude": "-74.52404000"
                        },
                        {
                            "id": 124431,
                            "name": "Point Pleasant",
                            "latitude": "40.08317000",
                            "longitude": "-74.06819000"
                        },
                        {
                            "id": 124432,
                            "name": "Point Pleasant Beach",
                            "latitude": "40.09123000",
                            "longitude": "-74.04791000"
                        },
                        {
                            "id": 124457,
                            "name": "Pomona",
                            "latitude": "39.47845000",
                            "longitude": "-74.57516000"
                        },
                        {
                            "id": 124462,
                            "name": "Pompton Lakes",
                            "latitude": "41.00538000",
                            "longitude": "-74.29070000"
                        },
                        {
                            "id": 124519,
                            "name": "Port Monmouth",
                            "latitude": "40.43011000",
                            "longitude": "-74.09847000"
                        },
                        {
                            "id": 124522,
                            "name": "Port Norris",
                            "latitude": "39.24595000",
                            "longitude": "-75.03518000"
                        },
                        {
                            "id": 124527,
                            "name": "Port Reading",
                            "latitude": "40.56538000",
                            "longitude": "-74.26042000"
                        },
                        {
                            "id": 124528,
                            "name": "Port Republic",
                            "latitude": "39.52067000",
                            "longitude": "-74.48571000"
                        },
                        {
                            "id": 124646,
                            "name": "Presidential Lakes Estates",
                            "latitude": "39.91373000",
                            "longitude": "-74.56460000"
                        },
                        {
                            "id": 124689,
                            "name": "Princeton",
                            "latitude": "40.34872000",
                            "longitude": "-74.65905000"
                        },
                        {
                            "id": 124692,
                            "name": "Princeton Junction",
                            "latitude": "40.31733000",
                            "longitude": "-74.61988000"
                        },
                        {
                            "id": 124693,
                            "name": "Princeton Meadows",
                            "latitude": "40.33177000",
                            "longitude": "-74.56377000"
                        },
                        {
                            "id": 124710,
                            "name": "Prospect Park",
                            "latitude": "40.93704000",
                            "longitude": "-74.17431000"
                        },
                        {
                            "id": 124822,
                            "name": "Rahway",
                            "latitude": "40.60816000",
                            "longitude": "-74.27765000"
                        },
                        {
                            "id": 124837,
                            "name": "Ramblewood",
                            "latitude": "39.92872000",
                            "longitude": "-74.94378000"
                        },
                        {
                            "id": 124842,
                            "name": "Ramsey",
                            "latitude": "41.05732000",
                            "longitude": "-74.14098000"
                        },
                        {
                            "id": 124845,
                            "name": "Ramtown",
                            "latitude": "40.12095000",
                            "longitude": "-74.14375000"
                        },
                        {
                            "id": 124871,
                            "name": "Randolph",
                            "latitude": "40.84829000",
                            "longitude": "-74.58148000"
                        },
                        {
                            "id": 124899,
                            "name": "Raritan",
                            "latitude": "40.56955000",
                            "longitude": "-74.63294000"
                        },
                        {
                            "id": 124938,
                            "name": "Red Bank",
                            "latitude": "40.34705000",
                            "longitude": "-74.06431000"
                        },
                        {
                            "id": 125110,
                            "name": "Richwood",
                            "latitude": "39.72261000",
                            "longitude": "-75.16546000"
                        },
                        {
                            "id": 125120,
                            "name": "Ridgefield",
                            "latitude": "40.83427000",
                            "longitude": "-74.00875000"
                        },
                        {
                            "id": 125122,
                            "name": "Ridgefield Park",
                            "latitude": "40.85704000",
                            "longitude": "-74.02153000"
                        },
                        {
                            "id": 125131,
                            "name": "Ridgewood",
                            "latitude": "40.97926000",
                            "longitude": "-74.11653000"
                        },
                        {
                            "id": 125145,
                            "name": "Ringwood",
                            "latitude": "41.11343000",
                            "longitude": "-74.24543000"
                        },
                        {
                            "id": 125153,
                            "name": "Rio Grande",
                            "latitude": "39.01456000",
                            "longitude": "-74.88156000"
                        },
                        {
                            "id": 125177,
                            "name": "River Edge",
                            "latitude": "40.92871000",
                            "longitude": "-74.03986000"
                        },
                        {
                            "id": 125188,
                            "name": "River Vale",
                            "latitude": "40.99538000",
                            "longitude": "-74.01208000"
                        },
                        {
                            "id": 125194,
                            "name": "Riverdale",
                            "latitude": "40.99399000",
                            "longitude": "-74.30348000"
                        },
                        {
                            "id": 125212,
                            "name": "Riverton",
                            "latitude": "40.01150000",
                            "longitude": "-75.01489000"
                        },
                        {
                            "id": 125238,
                            "name": "Robbinsville",
                            "latitude": "40.21455000",
                            "longitude": "-74.61932000"
                        },
                        {
                            "id": 125248,
                            "name": "Robertsville",
                            "latitude": "40.34622000",
                            "longitude": "-74.28792000"
                        },
                        {
                            "id": 125260,
                            "name": "Rochelle Park",
                            "latitude": "40.90732000",
                            "longitude": "-74.07514000"
                        },
                        {
                            "id": 125289,
                            "name": "Rockaway",
                            "latitude": "40.90121000",
                            "longitude": "-74.51432000"
                        },
                        {
                            "id": 125343,
                            "name": "Roebling",
                            "latitude": "40.11594000",
                            "longitude": "-74.78627000"
                        },
                        {
                            "id": 125423,
                            "name": "Roseland",
                            "latitude": "40.82066000",
                            "longitude": "-74.29376000"
                        },
                        {
                            "id": 125427,
                            "name": "Roselle",
                            "latitude": "40.65223000",
                            "longitude": "-74.25882000"
                        },
                        {
                            "id": 125428,
                            "name": "Roselle Park",
                            "latitude": "40.66455000",
                            "longitude": "-74.26431000"
                        },
                        {
                            "id": 125436,
                            "name": "Rosenhayn",
                            "latitude": "39.47817000",
                            "longitude": "-75.13129000"
                        },
                        {
                            "id": 125454,
                            "name": "Rossmoor",
                            "latitude": "40.33650000",
                            "longitude": "-74.47349000"
                        },
                        {
                            "id": 125509,
                            "name": "Rumson",
                            "latitude": "40.37205000",
                            "longitude": "-73.99903000"
                        },
                        {
                            "id": 125513,
                            "name": "Runnemede",
                            "latitude": "39.85234000",
                            "longitude": "-75.06795000"
                        },
                        {
                            "id": 125549,
                            "name": "Rutherford",
                            "latitude": "40.82649000",
                            "longitude": "-74.10681000"
                        },
                        {
                            "id": 125579,
                            "name": "Saddle Brook",
                            "latitude": "40.89899000",
                            "longitude": "-74.09264000"
                        },
                        {
                            "id": 125581,
                            "name": "Saddle River",
                            "latitude": "41.03176000",
                            "longitude": "-74.10209000"
                        },
                        {
                            "id": 125729,
                            "name": "Salem",
                            "latitude": "39.57178000",
                            "longitude": "-75.46714000"
                        },
                        {
                            "id": 125741,
                            "name": "Salem County",
                            "latitude": "39.57658000",
                            "longitude": "-75.35791000"
                        },
                        {
                            "id": 125991,
                            "name": "Sayreville",
                            "latitude": "40.45927000",
                            "longitude": "-74.36098000"
                        },
                        {
                            "id": 125992,
                            "name": "Sayreville Junction",
                            "latitude": "40.46538000",
                            "longitude": "-74.33043000"
                        },
                        {
                            "id": 126032,
                            "name": "Scotch Plains",
                            "latitude": "40.65538000",
                            "longitude": "-74.38987000"
                        },
                        {
                            "id": 126071,
                            "name": "Sea Bright",
                            "latitude": "40.36150000",
                            "longitude": "-73.97403000"
                        },
                        {
                            "id": 126073,
                            "name": "Sea Girt",
                            "latitude": "40.13206000",
                            "longitude": "-74.03458000"
                        },
                        {
                            "id": 126074,
                            "name": "Sea Isle City",
                            "latitude": "39.15345000",
                            "longitude": "-74.69294000"
                        },
                        {
                            "id": 126081,
                            "name": "Seabrook Farms",
                            "latitude": "39.50095000",
                            "longitude": "-75.21796000"
                        },
                        {
                            "id": 126101,
                            "name": "Seaside Heights",
                            "latitude": "39.94429000",
                            "longitude": "-74.07291000"
                        },
                        {
                            "id": 126102,
                            "name": "Seaside Park",
                            "latitude": "39.92679000",
                            "longitude": "-74.07708000"
                        },
                        {
                            "id": 126112,
                            "name": "Secaucus",
                            "latitude": "40.78955000",
                            "longitude": "-74.05653000"
                        },
                        {
                            "id": 126190,
                            "name": "Sewaren",
                            "latitude": "40.55205000",
                            "longitude": "-74.25876000"
                        },
                        {
                            "id": 126225,
                            "name": "Shark River Hills",
                            "latitude": "40.19400000",
                            "longitude": "-74.04875000"
                        },
                        {
                            "id": 126356,
                            "name": "Ship Bottom",
                            "latitude": "39.64290000",
                            "longitude": "-74.18042000"
                        },
                        {
                            "id": 126376,
                            "name": "Short Hills",
                            "latitude": "40.74788000",
                            "longitude": "-74.32543000"
                        },
                        {
                            "id": 126386,
                            "name": "Shrewsbury",
                            "latitude": "40.32955000",
                            "longitude": "-74.06153000"
                        },
                        {
                            "id": 126393,
                            "name": "Sicklerville",
                            "latitude": "39.71734000",
                            "longitude": "-74.96933000"
                        },
                        {
                            "id": 126433,
                            "name": "Silver Ridge",
                            "latitude": "39.95928000",
                            "longitude": "-74.21848000"
                        },
                        {
                            "id": 126454,
                            "name": "Singac",
                            "latitude": "40.88677000",
                            "longitude": "-74.24098000"
                        },
                        {
                            "id": 126473,
                            "name": "Sixmile Run",
                            "latitude": "40.45761000",
                            "longitude": "-74.51154000"
                        },
                        {
                            "id": 126527,
                            "name": "Smithville",
                            "latitude": "39.49401000",
                            "longitude": "-74.45709000"
                        },
                        {
                            "id": 126556,
                            "name": "Society Hill",
                            "latitude": "40.53399000",
                            "longitude": "-74.45793000"
                        },
                        {
                            "id": 126574,
                            "name": "Somerdale",
                            "latitude": "39.84400000",
                            "longitude": "-75.02267000"
                        },
                        {
                            "id": 126578,
                            "name": "Somers Point",
                            "latitude": "39.31762000",
                            "longitude": "-74.59460000"
                        },
                        {
                            "id": 126583,
                            "name": "Somerset",
                            "latitude": "40.49760000",
                            "longitude": "-74.48849000"
                        },
                        {
                            "id": 126588,
                            "name": "Somerset County",
                            "latitude": "40.56351000",
                            "longitude": "-74.61631000"
                        },
                        {
                            "id": 126596,
                            "name": "Somerville",
                            "latitude": "40.57427000",
                            "longitude": "-74.60988000"
                        },
                        {
                            "id": 126612,
                            "name": "South Amboy",
                            "latitude": "40.47788000",
                            "longitude": "-74.29070000"
                        },
                        {
                            "id": 126622,
                            "name": "South Belmar",
                            "latitude": "40.17095000",
                            "longitude": "-74.02736000"
                        },
                        {
                            "id": 126630,
                            "name": "South Bound Brook",
                            "latitude": "40.55344000",
                            "longitude": "-74.53154000"
                        },
                        {
                            "id": 126697,
                            "name": "South Old Bridge",
                            "latitude": "40.40816000",
                            "longitude": "-74.35432000"
                        },
                        {
                            "id": 126698,
                            "name": "South Orange",
                            "latitude": "40.74899000",
                            "longitude": "-74.26126000"
                        },
                        {
                            "id": 126711,
                            "name": "South Plainfield",
                            "latitude": "40.57927000",
                            "longitude": "-74.41154000"
                        },
                        {
                            "id": 126717,
                            "name": "South River",
                            "latitude": "40.44649000",
                            "longitude": "-74.38598000"
                        },
                        {
                            "id": 126736,
                            "name": "South Toms River",
                            "latitude": "39.94206000",
                            "longitude": "-74.20431000"
                        },
                        {
                            "id": 126743,
                            "name": "South Vineland",
                            "latitude": "39.44595000",
                            "longitude": "-75.02879000"
                        },
                        {
                            "id": 126812,
                            "name": "Sparta",
                            "latitude": "41.03343000",
                            "longitude": "-74.63849000"
                        },
                        {
                            "id": 126846,
                            "name": "Spotswood",
                            "latitude": "40.39177000",
                            "longitude": "-74.39848000"
                        },
                        {
                            "id": 126867,
                            "name": "Spring Lake",
                            "latitude": "40.15345000",
                            "longitude": "-74.02819000"
                        },
                        {
                            "id": 126868,
                            "name": "Spring Lake Heights",
                            "latitude": "40.15039000",
                            "longitude": "-74.03097000"
                        },
                        {
                            "id": 126885,
                            "name": "Springdale",
                            "latitude": "39.90261000",
                            "longitude": "-74.96628000"
                        },
                        {
                            "id": 126900,
                            "name": "Springfield",
                            "latitude": "40.70491000",
                            "longitude": "-74.31723000"
                        },
                        {
                            "id": 126956,
                            "name": "Stanhope",
                            "latitude": "40.90288000",
                            "longitude": "-74.70905000"
                        },
                        {
                            "id": 127122,
                            "name": "Stratford",
                            "latitude": "39.82678000",
                            "longitude": "-75.01545000"
                        },
                        {
                            "id": 127130,
                            "name": "Strathmore",
                            "latitude": "40.39594000",
                            "longitude": "-74.21348000"
                        },
                        {
                            "id": 127160,
                            "name": "Succasunna",
                            "latitude": "40.86843000",
                            "longitude": "-74.64044000"
                        },
                        {
                            "id": 127213,
                            "name": "Summit",
                            "latitude": "40.71562000",
                            "longitude": "-74.36468000"
                        },
                        {
                            "id": 127286,
                            "name": "Surf City",
                            "latitude": "39.66206000",
                            "longitude": "-74.16514000"
                        },
                        {
                            "id": 127301,
                            "name": "Sussex",
                            "latitude": "41.20982000",
                            "longitude": "-74.60766000"
                        },
                        {
                            "id": 127305,
                            "name": "Sussex County",
                            "latitude": "41.13946000",
                            "longitude": "-74.69023000"
                        },
                        {
                            "id": 127334,
                            "name": "Swedesboro",
                            "latitude": "39.74761000",
                            "longitude": "-75.31047000"
                        },
                        {
                            "id": 127475,
                            "name": "Teaneck",
                            "latitude": "40.89760000",
                            "longitude": "-74.01597000"
                        },
                        {
                            "id": 127506,
                            "name": "Ten Mile Run",
                            "latitude": "40.41301000",
                            "longitude": "-74.60223000"
                        },
                        {
                            "id": 127507,
                            "name": "Tenafly",
                            "latitude": "40.92538000",
                            "longitude": "-73.96292000"
                        },
                        {
                            "id": 127667,
                            "name": "Tinton Falls",
                            "latitude": "40.30428000",
                            "longitude": "-74.10042000"
                        },
                        {
                            "id": 127716,
                            "name": "Toms River",
                            "latitude": "39.95373000",
                            "longitude": "-74.19792000"
                        },
                        {
                            "id": 127747,
                            "name": "Totowa",
                            "latitude": "40.90510000",
                            "longitude": "-74.20987000"
                        },
                        {
                            "id": 127808,
                            "name": "Trenton",
                            "latitude": "40.21705000",
                            "longitude": "-74.74294000"
                        },
                        {
                            "id": 127873,
                            "name": "Tuckerton",
                            "latitude": "39.60317000",
                            "longitude": "-74.34015000"
                        },
                        {
                            "id": 127906,
                            "name": "Turnersville",
                            "latitude": "39.77317000",
                            "longitude": "-75.05128000"
                        },
                        {
                            "id": 127934,
                            "name": "Twin Rivers",
                            "latitude": "40.26400000",
                            "longitude": "-74.49126000"
                        },
                        {
                            "id": 127977,
                            "name": "Union",
                            "latitude": "40.69760000",
                            "longitude": "-74.26320000"
                        },
                        {
                            "id": 127979,
                            "name": "Union Beach",
                            "latitude": "40.44650000",
                            "longitude": "-74.17820000"
                        },
                        {
                            "id": 127985,
                            "name": "Union City",
                            "latitude": "40.77955000",
                            "longitude": "-74.02375000"
                        },
                        {
                            "id": 127999,
                            "name": "Union County",
                            "latitude": "40.65980000",
                            "longitude": "-74.30859000"
                        },
                        {
                            "id": 128047,
                            "name": "Upper Montclair",
                            "latitude": "40.84621000",
                            "longitude": "-74.20126000"
                        },
                        {
                            "id": 128049,
                            "name": "Upper Pohatcong",
                            "latitude": "40.67747000",
                            "longitude": "-75.15580000"
                        },
                        {
                            "id": 128050,
                            "name": "Upper Saddle River",
                            "latitude": "41.05843000",
                            "longitude": "-74.09848000"
                        },
                        {
                            "id": 128174,
                            "name": "Ventnor City",
                            "latitude": "39.34039000",
                            "longitude": "-74.47737000"
                        },
                        {
                            "id": 128191,
                            "name": "Vernon Center",
                            "latitude": "41.18879000",
                            "longitude": "-74.50405000"
                        },
                        {
                            "id": 128196,
                            "name": "Vernon Valley",
                            "latitude": "41.23676000",
                            "longitude": "-74.48710000"
                        },
                        {
                            "id": 128203,
                            "name": "Verona",
                            "latitude": "40.82982000",
                            "longitude": "-74.24015000"
                        },
                        {
                            "id": 128225,
                            "name": "Victory Gardens",
                            "latitude": "40.87593000",
                            "longitude": "-74.54238000"
                        },
                        {
                            "id": 128226,
                            "name": "Victory Lakes",
                            "latitude": "39.63317000",
                            "longitude": "-74.96600000"
                        },
                        {
                            "id": 128258,
                            "name": "Villas",
                            "latitude": "39.02872000",
                            "longitude": "-74.93851000"
                        },
                        {
                            "id": 128266,
                            "name": "Vincentown",
                            "latitude": "39.93400000",
                            "longitude": "-74.74849000"
                        },
                        {
                            "id": 128270,
                            "name": "Vineland",
                            "latitude": "39.48623000",
                            "longitude": "-75.02573000"
                        },
                        {
                            "id": 128296,
                            "name": "Vista Center",
                            "latitude": "40.15928000",
                            "longitude": "-74.31792000"
                        },
                        {
                            "id": 128306,
                            "name": "Voorhees",
                            "latitude": "40.48122000",
                            "longitude": "-74.48321000"
                        },
                        {
                            "id": 128378,
                            "name": "Waldwick",
                            "latitude": "41.01065000",
                            "longitude": "-74.11792000"
                        },
                        {
                            "id": 128408,
                            "name": "Wallington",
                            "latitude": "40.85316000",
                            "longitude": "-74.11375000"
                        },
                        {
                            "id": 128447,
                            "name": "Wanamassa",
                            "latitude": "40.23178000",
                            "longitude": "-74.02542000"
                        },
                        {
                            "id": 128449,
                            "name": "Wanaque",
                            "latitude": "41.03815000",
                            "longitude": "-74.29404000"
                        },
                        {
                            "id": 128466,
                            "name": "Waretown",
                            "latitude": "39.79151000",
                            "longitude": "-74.19514000"
                        },
                        {
                            "id": 128496,
                            "name": "Warren County",
                            "latitude": "40.85725000",
                            "longitude": "-74.99702000"
                        },
                        {
                            "id": 128500,
                            "name": "Warren Township",
                            "latitude": "40.60822000",
                            "longitude": "-74.51803000"
                        },
                        {
                            "id": 128548,
                            "name": "Washington",
                            "latitude": "40.75843000",
                            "longitude": "-74.97934000"
                        },
                        {
                            "id": 128598,
                            "name": "Watchung",
                            "latitude": "40.63788000",
                            "longitude": "-74.45099000"
                        },
                        {
                            "id": 128685,
                            "name": "Wayne",
                            "latitude": "40.92538000",
                            "longitude": "-74.27654000"
                        },
                        {
                            "id": 128754,
                            "name": "Weehawken",
                            "latitude": "40.76955000",
                            "longitude": "-74.02042000"
                        },
                        {
                            "id": 128803,
                            "name": "Wenonah",
                            "latitude": "39.79456000",
                            "longitude": "-75.14879000"
                        },
                        {
                            "id": 128824,
                            "name": "West Belmar",
                            "latitude": "40.16928000",
                            "longitude": "-74.03542000"
                        },
                        {
                            "id": 128840,
                            "name": "West Cape May",
                            "latitude": "38.93872000",
                            "longitude": "-74.94184000"
                        },
                        {
                            "id": 128874,
                            "name": "West Freehold",
                            "latitude": "40.24206000",
                            "longitude": "-74.30126000"
                        },
                        {
                            "id": 128922,
                            "name": "West Long Branch",
                            "latitude": "40.29039000",
                            "longitude": "-74.01764000"
                        },
                        {
                            "id": 128932,
                            "name": "West Milford",
                            "latitude": "41.13121000",
                            "longitude": "-74.36737000"
                        },
                        {
                            "id": 128938,
                            "name": "West New York",
                            "latitude": "40.78788000",
                            "longitude": "-74.01431000"
                        },
                        {
                            "id": 128946,
                            "name": "West Orange",
                            "latitude": "40.79871000",
                            "longitude": "-74.23904000"
                        },
                        {
                            "id": 129029,
                            "name": "Westfield",
                            "latitude": "40.65899000",
                            "longitude": "-74.34737000"
                        },
                        {
                            "id": 129062,
                            "name": "Weston",
                            "latitude": "40.53510000",
                            "longitude": "-74.59071000"
                        },
                        {
                            "id": 129076,
                            "name": "Westville",
                            "latitude": "39.86789000",
                            "longitude": "-75.13156000"
                        },
                        {
                            "id": 129086,
                            "name": "Westwood",
                            "latitude": "40.99121000",
                            "longitude": "-74.03264000"
                        },
                        {
                            "id": 129103,
                            "name": "Wharton",
                            "latitude": "40.89315000",
                            "longitude": "-74.58183000"
                        },
                        {
                            "id": 129142,
                            "name": "White Horse",
                            "latitude": "40.19067000",
                            "longitude": "-74.70238000"
                        },
                        {
                            "id": 129146,
                            "name": "White Meadow Lake",
                            "latitude": "40.92371000",
                            "longitude": "-74.51071000"
                        },
                        {
                            "id": 129175,
                            "name": "Whitehouse Station",
                            "latitude": "40.61538000",
                            "longitude": "-74.77044000"
                        },
                        {
                            "id": 129181,
                            "name": "Whitesboro",
                            "latitude": "39.03900000",
                            "longitude": "-74.85684000"
                        },
                        {
                            "id": 129184,
                            "name": "Whitesboro-Burleigh",
                            "latitude": "39.04305000",
                            "longitude": "-74.86538000"
                        },
                        {
                            "id": 129211,
                            "name": "Whittingham",
                            "latitude": "40.32982000",
                            "longitude": "-74.44511000"
                        },
                        {
                            "id": 129238,
                            "name": "Wildwood",
                            "latitude": "38.99178000",
                            "longitude": "-74.81489000"
                        },
                        {
                            "id": 129241,
                            "name": "Wildwood Crest",
                            "latitude": "38.97484000",
                            "longitude": "-74.83350000"
                        },
                        {
                            "id": 129286,
                            "name": "Williamstown",
                            "latitude": "39.68623000",
                            "longitude": "-74.99517000"
                        },
                        {
                            "id": 129294,
                            "name": "Willingboro",
                            "latitude": "40.02789000",
                            "longitude": "-74.86905000"
                        },
                        {
                            "id": 129399,
                            "name": "Winfield",
                            "latitude": "40.64260000",
                            "longitude": "-74.28543000"
                        },
                        {
                            "id": 129491,
                            "name": "Wood-Lynne",
                            "latitude": "39.91734000",
                            "longitude": "-75.09629000"
                        },
                        {
                            "id": 129492,
                            "name": "Wood-Ridge",
                            "latitude": "40.84566000",
                            "longitude": "-74.08792000"
                        },
                        {
                            "id": 129495,
                            "name": "Woodbine",
                            "latitude": "39.24178000",
                            "longitude": "-74.81517000"
                        },
                        {
                            "id": 129501,
                            "name": "Woodbridge",
                            "latitude": "40.55760000",
                            "longitude": "-74.28459000"
                        },
                        {
                            "id": 129506,
                            "name": "Woodbury",
                            "latitude": "39.83817000",
                            "longitude": "-75.15268000"
                        },
                        {
                            "id": 129513,
                            "name": "Woodbury Heights",
                            "latitude": "39.81706000",
                            "longitude": "-75.15518000"
                        },
                        {
                            "id": 129514,
                            "name": "Woodcliff Lake",
                            "latitude": "41.02343000",
                            "longitude": "-74.06653000"
                        },
                        {
                            "id": 129532,
                            "name": "Woodland Park",
                            "latitude": "40.88982000",
                            "longitude": "-74.19487000"
                        },
                        {
                            "id": 129568,
                            "name": "Woodstown",
                            "latitude": "39.65150000",
                            "longitude": "-75.32825000"
                        },
                        {
                            "id": 129623,
                            "name": "Wyckoff",
                            "latitude": "41.00954000",
                            "longitude": "-74.17292000"
                        },
                        {
                            "id": 129665,
                            "name": "Yardville",
                            "latitude": "40.18122000",
                            "longitude": "-74.66432000"
                        },
                        {
                            "id": 129701,
                            "name": "Yorketown",
                            "latitude": "40.30789000",
                            "longitude": "-74.33765000"
                        }
                    ]
                },
                {
                    "id": 1423,
                    "name": "New Mexico",
                    "state_code": "NM",
                    "cities": [
                        {
                            "id": 111060,
                            "name": "Agua Fria",
                            "latitude": "35.65448000",
                            "longitude": "-106.02224000"
                        },
                        {
                            "id": 111094,
                            "name": "Alamo",
                            "latitude": "34.42089000",
                            "longitude": "-107.51088000"
                        },
                        {
                            "id": 111097,
                            "name": "Alamogordo",
                            "latitude": "32.89953000",
                            "longitude": "-105.96027000"
                        },
                        {
                            "id": 111130,
                            "name": "Albuquerque",
                            "latitude": "35.08449000",
                            "longitude": "-106.65114000"
                        },
                        {
                            "id": 111335,
                            "name": "Angel Fire",
                            "latitude": "36.39309000",
                            "longitude": "-105.28501000"
                        },
                        {
                            "id": 111369,
                            "name": "Anthony",
                            "latitude": "32.00399000",
                            "longitude": "-106.60583000"
                        },
                        {
                            "id": 111446,
                            "name": "Arenas Valley",
                            "latitude": "32.79396000",
                            "longitude": "-108.18421000"
                        },
                        {
                            "id": 111495,
                            "name": "Arroyo Seco",
                            "latitude": "36.51669000",
                            "longitude": "-105.56918000"
                        },
                        {
                            "id": 111497,
                            "name": "Artesia",
                            "latitude": "32.84233000",
                            "longitude": "-104.40330000"
                        },
                        {
                            "id": 111605,
                            "name": "Atoka",
                            "latitude": "32.76956000",
                            "longitude": "-104.38885000"
                        },
                        {
                            "id": 111712,
                            "name": "Aztec",
                            "latitude": "36.82223000",
                            "longitude": "-107.99285000"
                        },
                        {
                            "id": 111937,
                            "name": "Bayard",
                            "latitude": "32.76174000",
                            "longitude": "-108.13060000"
                        },
                        {
                            "id": 112054,
                            "name": "Belen",
                            "latitude": "34.66284000",
                            "longitude": "-106.77642000"
                        },
                        {
                            "id": 112220,
                            "name": "Berino",
                            "latitude": "32.07093000",
                            "longitude": "-106.62138000"
                        },
                        {
                            "id": 112242,
                            "name": "Bernalillo",
                            "latitude": "35.30004000",
                            "longitude": "-106.55114000"
                        },
                        {
                            "id": 112243,
                            "name": "Bernalillo County",
                            "latitude": "35.05131000",
                            "longitude": "-106.67017000"
                        },
                        {
                            "id": 112384,
                            "name": "Black Rock",
                            "latitude": "35.08837000",
                            "longitude": "-108.79119000"
                        },
                        {
                            "id": 112440,
                            "name": "Bloomfield",
                            "latitude": "36.71112000",
                            "longitude": "-107.98451000"
                        },
                        {
                            "id": 112512,
                            "name": "Boles Acres",
                            "latitude": "32.80703000",
                            "longitude": "-105.98610000"
                        },
                        {
                            "id": 112585,
                            "name": "Bosque Farms",
                            "latitude": "34.85478000",
                            "longitude": "-106.70530000"
                        },
                        {
                            "id": 113331,
                            "name": "Cannon Air Force Base",
                            "latitude": "34.39689000",
                            "longitude": "-103.32444000"
                        },
                        {
                            "id": 113374,
                            "name": "Capitan",
                            "latitude": "33.54536000",
                            "longitude": "-105.57220000"
                        },
                        {
                            "id": 113409,
                            "name": "Carlsbad",
                            "latitude": "32.42067000",
                            "longitude": "-104.22884000"
                        },
                        {
                            "id": 113431,
                            "name": "Carnuel",
                            "latitude": "35.06394000",
                            "longitude": "-106.45725000"
                        },
                        {
                            "id": 113448,
                            "name": "Carrizozo",
                            "latitude": "33.64174000",
                            "longitude": "-105.87721000"
                        },
                        {
                            "id": 113578,
                            "name": "Catron County",
                            "latitude": "33.91533000",
                            "longitude": "-108.40473000"
                        },
                        {
                            "id": 113717,
                            "name": "Chama",
                            "latitude": "36.90307000",
                            "longitude": "-106.57948000"
                        },
                        {
                            "id": 113740,
                            "name": "Chaparral",
                            "latitude": "32.02376000",
                            "longitude": "-106.38566000"
                        },
                        {
                            "id": 113817,
                            "name": "Chaves County",
                            "latitude": "33.36319000",
                            "longitude": "-104.46700000"
                        },
                        {
                            "id": 113958,
                            "name": "Chimayo",
                            "latitude": "36.00391000",
                            "longitude": "-105.94697000"
                        },
                        {
                            "id": 114010,
                            "name": "Church Rock",
                            "latitude": "35.53391000",
                            "longitude": "-108.59980000"
                        },
                        {
                            "id": 114017,
                            "name": "Cibola County",
                            "latitude": "34.91257000",
                            "longitude": "-107.99971000"
                        },
                        {
                            "id": 114204,
                            "name": "Clayton",
                            "latitude": "36.45169000",
                            "longitude": "-103.18410000"
                        },
                        {
                            "id": 114316,
                            "name": "Clovis",
                            "latitude": "34.40480000",
                            "longitude": "-103.20523000"
                        },
                        {
                            "id": 114411,
                            "name": "Colfax County",
                            "latitude": "36.60612000",
                            "longitude": "-104.64686000"
                        },
                        {
                            "id": 114498,
                            "name": "Columbus",
                            "latitude": "31.82760000",
                            "longitude": "-107.64002000"
                        },
                        {
                            "id": 114666,
                            "name": "Corrales",
                            "latitude": "35.23782000",
                            "longitude": "-106.60669000"
                        },
                        {
                            "id": 114868,
                            "name": "Crownpoint",
                            "latitude": "35.67808000",
                            "longitude": "-108.15118000"
                        },
                        {
                            "id": 114927,
                            "name": "Curry County",
                            "latitude": "34.57416000",
                            "longitude": "-103.34691000"
                        },
                        {
                            "id": 115104,
                            "name": "De Baca County",
                            "latitude": "34.34239000",
                            "longitude": "-104.41200000"
                        },
                        {
                            "id": 115231,
                            "name": "Deming",
                            "latitude": "32.26870000",
                            "longitude": "-107.75864000"
                        },
                        {
                            "id": 115311,
                            "name": "Dexter",
                            "latitude": "33.19733000",
                            "longitude": "-104.37302000"
                        },
                        {
                            "id": 115464,
                            "name": "Doña Ana",
                            "latitude": "32.38954000",
                            "longitude": "-106.81390000"
                        },
                        {
                            "id": 115465,
                            "name": "Doña Ana County",
                            "latitude": "32.35268000",
                            "longitude": "-106.83280000"
                        },
                        {
                            "id": 115510,
                            "name": "Dulce",
                            "latitude": "36.93362000",
                            "longitude": "-106.99893000"
                        },
                        {
                            "id": 115824,
                            "name": "Eddy County",
                            "latitude": "32.47149000",
                            "longitude": "-104.30431000"
                        },
                        {
                            "id": 115863,
                            "name": "Edgewood",
                            "latitude": "35.06144000",
                            "longitude": "-106.19141000"
                        },
                        {
                            "id": 115917,
                            "name": "El Cerro",
                            "latitude": "34.72700000",
                            "longitude": "-106.71086000"
                        },
                        {
                            "id": 115918,
                            "name": "El Cerro Mission",
                            "latitude": "34.76227000",
                            "longitude": "-106.64450000"
                        },
                        {
                            "id": 115934,
                            "name": "El Rancho",
                            "latitude": "35.88919000",
                            "longitude": "-106.07975000"
                        },
                        {
                            "id": 115939,
                            "name": "El Valle de Arroyo Seco",
                            "latitude": "35.96280000",
                            "longitude": "-106.02947000"
                        },
                        {
                            "id": 115955,
                            "name": "Eldorado at Santa Fe",
                            "latitude": "35.52642000",
                            "longitude": "-105.93474000"
                        },
                        {
                            "id": 115961,
                            "name": "Elephant Butte",
                            "latitude": "33.14868000",
                            "longitude": "-107.18475000"
                        },
                        {
                            "id": 116117,
                            "name": "Enchanted Hills",
                            "latitude": "35.33676000",
                            "longitude": "-106.59296000"
                        },
                        {
                            "id": 116188,
                            "name": "Española",
                            "latitude": "35.99113000",
                            "longitude": "-106.08058000"
                        },
                        {
                            "id": 116203,
                            "name": "Estancia",
                            "latitude": "34.75839000",
                            "longitude": "-106.05585000"
                        },
                        {
                            "id": 116230,
                            "name": "Eunice",
                            "latitude": "32.43734000",
                            "longitude": "-103.15908000"
                        },
                        {
                            "id": 116421,
                            "name": "Farmington",
                            "latitude": "36.72806000",
                            "longitude": "-108.21869000"
                        },
                        {
                            "id": 116573,
                            "name": "Flora Vista",
                            "latitude": "36.79445000",
                            "longitude": "-108.08035000"
                        },
                        {
                            "id": 116753,
                            "name": "Fort Sumner",
                            "latitude": "34.47173000",
                            "longitude": "-104.24553000"
                        },
                        {
                            "id": 117039,
                            "name": "Gallup",
                            "latitude": "35.52808000",
                            "longitude": "-108.74258000"
                        },
                        {
                            "id": 117517,
                            "name": "Grant County",
                            "latitude": "32.73901000",
                            "longitude": "-108.38225000"
                        },
                        {
                            "id": 117527,
                            "name": "Grants",
                            "latitude": "35.14760000",
                            "longitude": "-107.85261000"
                        },
                        {
                            "id": 117779,
                            "name": "Guadalupe County",
                            "latitude": "34.86333000",
                            "longitude": "-104.79070000"
                        },
                        {
                            "id": 117836,
                            "name": "Hagerman",
                            "latitude": "33.11511000",
                            "longitude": "-104.32691000"
                        },
                        {
                            "id": 118004,
                            "name": "Harding County",
                            "latitude": "35.85794000",
                            "longitude": "-103.81993000"
                        },
                        {
                            "id": 118137,
                            "name": "Hatch",
                            "latitude": "32.66536000",
                            "longitude": "-107.15307000"
                        },
                        {
                            "id": 118375,
                            "name": "Hidalgo County",
                            "latitude": "31.91416000",
                            "longitude": "-108.71482000"
                        },
                        {
                            "id": 118500,
                            "name": "Hobbs",
                            "latitude": "32.70261000",
                            "longitude": "-103.13604000"
                        },
                        {
                            "id": 118554,
                            "name": "Holloman Air Force Base",
                            "latitude": "32.84827000",
                            "longitude": "-106.09977000"
                        },
                        {
                            "id": 118822,
                            "name": "Hurley",
                            "latitude": "32.69924000",
                            "longitude": "-108.13199000"
                        },
                        {
                            "id": 119110,
                            "name": "Jal",
                            "latitude": "32.11318000",
                            "longitude": "-103.19351000"
                        },
                        {
                            "id": 119133,
                            "name": "Jarales",
                            "latitude": "34.61312000",
                            "longitude": "-106.76364000"
                        },
                        {
                            "id": 119210,
                            "name": "Jemez Pueblo",
                            "latitude": "35.61422000",
                            "longitude": "-106.72832000"
                        },
                        {
                            "id": 119419,
                            "name": "Keeler Farm",
                            "latitude": "32.31607000",
                            "longitude": "-107.76023000"
                        },
                        {
                            "id": 119655,
                            "name": "Kirtland",
                            "latitude": "36.73417000",
                            "longitude": "-108.35980000"
                        },
                        {
                            "id": 119739,
                            "name": "La Cienega",
                            "latitude": "35.56281000",
                            "longitude": "-106.13086000"
                        },
                        {
                            "id": 119759,
                            "name": "La Huerta",
                            "latitude": "32.44290000",
                            "longitude": "-104.22106000"
                        },
                        {
                            "id": 119763,
                            "name": "La Luz",
                            "latitude": "32.97787000",
                            "longitude": "-105.94193000"
                        },
                        {
                            "id": 119766,
                            "name": "La Mesilla",
                            "latitude": "35.94836000",
                            "longitude": "-106.07058000"
                        },
                        {
                            "id": 119781,
                            "name": "La Puebla",
                            "latitude": "35.98919000",
                            "longitude": "-105.99641000"
                        },
                        {
                            "id": 119790,
                            "name": "La Union",
                            "latitude": "31.95066000",
                            "longitude": "-106.66166000"
                        },
                        {
                            "id": 119850,
                            "name": "Laguna",
                            "latitude": "35.03671000",
                            "longitude": "-107.38282000"
                        },
                        {
                            "id": 120155,
                            "name": "Las Cruces",
                            "latitude": "32.31232000",
                            "longitude": "-106.77834000"
                        },
                        {
                            "id": 120159,
                            "name": "Las Maravillas",
                            "latitude": "34.73815000",
                            "longitude": "-106.66854000"
                        },
                        {
                            "id": 120164,
                            "name": "Las Vegas",
                            "latitude": "35.59393000",
                            "longitude": "-105.22390000"
                        },
                        {
                            "id": 120267,
                            "name": "Lea County",
                            "latitude": "32.79218000",
                            "longitude": "-103.41245000"
                        },
                        {
                            "id": 120305,
                            "name": "Lee Acres",
                            "latitude": "35.14866000",
                            "longitude": "-106.64697000"
                        },
                        {
                            "id": 120531,
                            "name": "Lincoln County",
                            "latitude": "33.74524000",
                            "longitude": "-105.45925000"
                        },
                        {
                            "id": 120770,
                            "name": "Lordsburg",
                            "latitude": "32.35036000",
                            "longitude": "-108.70866000"
                        },
                        {
                            "id": 120780,
                            "name": "Los Alamos",
                            "latitude": "35.88808000",
                            "longitude": "-106.30697000"
                        },
                        {
                            "id": 120781,
                            "name": "Los Alamos County",
                            "latitude": "35.86937000",
                            "longitude": "-106.30729000"
                        },
                        {
                            "id": 120787,
                            "name": "Los Chavez",
                            "latitude": "34.72571000",
                            "longitude": "-106.75726000"
                        },
                        {
                            "id": 120791,
                            "name": "Los Lunas",
                            "latitude": "34.80617000",
                            "longitude": "-106.73336000"
                        },
                        {
                            "id": 120795,
                            "name": "Los Ranchos de Albuquerque",
                            "latitude": "35.16199000",
                            "longitude": "-106.64280000"
                        },
                        {
                            "id": 120828,
                            "name": "Loving",
                            "latitude": "32.28623000",
                            "longitude": "-104.09577000"
                        },
                        {
                            "id": 120832,
                            "name": "Lovington",
                            "latitude": "32.94401000",
                            "longitude": "-103.34855000"
                        },
                        {
                            "id": 120884,
                            "name": "Luna County",
                            "latitude": "32.18215000",
                            "longitude": "-107.74977000"
                        },
                        {
                            "id": 121511,
                            "name": "McIntosh",
                            "latitude": "34.86478000",
                            "longitude": "-106.05169000"
                        },
                        {
                            "id": 121522,
                            "name": "McKinley County",
                            "latitude": "35.58061000",
                            "longitude": "-108.26193000"
                        },
                        {
                            "id": 121560,
                            "name": "Meadow Lake",
                            "latitude": "34.80144000",
                            "longitude": "-106.54363000"
                        },
                        {
                            "id": 121724,
                            "name": "Mescalero",
                            "latitude": "33.15759000",
                            "longitude": "-105.77415000"
                        },
                        {
                            "id": 121725,
                            "name": "Mesilla",
                            "latitude": "32.27009000",
                            "longitude": "-106.80084000"
                        },
                        {
                            "id": 121728,
                            "name": "Mesquite",
                            "latitude": "32.16454000",
                            "longitude": "-106.69666000"
                        },
                        {
                            "id": 121841,
                            "name": "Milan",
                            "latitude": "35.16976000",
                            "longitude": "-107.89089000"
                        },
                        {
                            "id": 122123,
                            "name": "Monterey Park",
                            "latitude": "34.75898000",
                            "longitude": "-106.64085000"
                        },
                        {
                            "id": 122219,
                            "name": "Mora",
                            "latitude": "35.97420000",
                            "longitude": "-105.33001000"
                        },
                        {
                            "id": 122220,
                            "name": "Mora County",
                            "latitude": "36.00995000",
                            "longitude": "-104.94458000"
                        },
                        {
                            "id": 122255,
                            "name": "Moriarty",
                            "latitude": "34.99005000",
                            "longitude": "-106.04919000"
                        },
                        {
                            "id": 122306,
                            "name": "Mosquero",
                            "latitude": "35.77698000",
                            "longitude": "-103.95664000"
                        },
                        {
                            "id": 122537,
                            "name": "Nambe",
                            "latitude": "35.89336000",
                            "longitude": "-105.98252000"
                        },
                        {
                            "id": 122597,
                            "name": "Navajo",
                            "latitude": "35.90001000",
                            "longitude": "-109.03398000"
                        },
                        {
                            "id": 123119,
                            "name": "North Valley",
                            "latitude": "35.17338000",
                            "longitude": "-106.62336000"
                        },
                        {
                            "id": 123386,
                            "name": "Ohkay Owingeh",
                            "latitude": "36.05082000",
                            "longitude": "-106.06897000"
                        },
                        {
                            "id": 123626,
                            "name": "Otero County",
                            "latitude": "32.61316000",
                            "longitude": "-105.74168000"
                        },
                        {
                            "id": 123821,
                            "name": "Paradise Hills",
                            "latitude": "35.20060000",
                            "longitude": "-106.70114000"
                        },
                        {
                            "id": 123979,
                            "name": "Pecos",
                            "latitude": "35.57420000",
                            "longitude": "-105.67502000"
                        },
                        {
                            "id": 124057,
                            "name": "Peralta",
                            "latitude": "34.83700000",
                            "longitude": "-106.69058000"
                        },
                        {
                            "id": 124314,
                            "name": "Placitas",
                            "latitude": "35.30698000",
                            "longitude": "-106.42475000"
                        },
                        {
                            "id": 124436,
                            "name": "Pojoaque",
                            "latitude": "35.89280000",
                            "longitude": "-106.02308000"
                        },
                        {
                            "id": 124471,
                            "name": "Ponderosa Pine",
                            "latitude": "34.97699000",
                            "longitude": "-106.32419000"
                        },
                        {
                            "id": 124550,
                            "name": "Portales",
                            "latitude": "34.18619000",
                            "longitude": "-103.33440000"
                        },
                        {
                            "id": 124784,
                            "name": "Quay County",
                            "latitude": "35.10436000",
                            "longitude": "-103.54974000"
                        },
                        {
                            "id": 124794,
                            "name": "Questa",
                            "latitude": "36.70391000",
                            "longitude": "-105.59501000"
                        },
                        {
                            "id": 124817,
                            "name": "Radium Springs",
                            "latitude": "32.50120000",
                            "longitude": "-106.92807000"
                        },
                        {
                            "id": 124860,
                            "name": "Ranchos de Taos",
                            "latitude": "36.35864000",
                            "longitude": "-105.60946000"
                        },
                        {
                            "id": 124901,
                            "name": "Raton",
                            "latitude": "36.90336000",
                            "longitude": "-104.43915000"
                        },
                        {
                            "id": 125035,
                            "name": "Reserve",
                            "latitude": "33.71311000",
                            "longitude": "-108.75784000"
                        },
                        {
                            "id": 125147,
                            "name": "Rio Arriba County",
                            "latitude": "36.50957000",
                            "longitude": "-106.69311000"
                        },
                        {
                            "id": 125150,
                            "name": "Rio Communities",
                            "latitude": "34.64959000",
                            "longitude": "-106.73403000"
                        },
                        {
                            "id": 125158,
                            "name": "Rio Rancho",
                            "latitude": "35.23338000",
                            "longitude": "-106.66447000"
                        },
                        {
                            "id": 125393,
                            "name": "Roosevelt County",
                            "latitude": "34.02127000",
                            "longitude": "-103.48018000"
                        },
                        {
                            "id": 125463,
                            "name": "Roswell",
                            "latitude": "33.39437000",
                            "longitude": "-104.52491000"
                        },
                        {
                            "id": 125504,
                            "name": "Ruidoso",
                            "latitude": "33.33175000",
                            "longitude": "-105.67304000"
                        },
                        {
                            "id": 125505,
                            "name": "Ruidoso Downs",
                            "latitude": "33.32897000",
                            "longitude": "-105.60443000"
                        },
                        {
                            "id": 125807,
                            "name": "San Felipe Pueblo",
                            "latitude": "35.43392000",
                            "longitude": "-106.44669000"
                        },
                        {
                            "id": 125821,
                            "name": "San Juan County",
                            "latitude": "36.50847000",
                            "longitude": "-108.32060000"
                        },
                        {
                            "id": 125839,
                            "name": "San Miguel",
                            "latitude": "32.15538000",
                            "longitude": "-106.73500000"
                        },
                        {
                            "id": 125841,
                            "name": "San Miguel County",
                            "latitude": "35.48029000",
                            "longitude": "-104.81585000"
                        },
                        {
                            "id": 125851,
                            "name": "San Ysidro",
                            "latitude": "32.35093000",
                            "longitude": "-106.81112000"
                        },
                        {
                            "id": 125866,
                            "name": "Sandia Heights",
                            "latitude": "35.17699000",
                            "longitude": "-106.49141000"
                        },
                        {
                            "id": 125867,
                            "name": "Sandia Knolls",
                            "latitude": "35.16393000",
                            "longitude": "-106.31141000"
                        },
                        {
                            "id": 125869,
                            "name": "Sandoval County",
                            "latitude": "35.68855000",
                            "longitude": "-106.86584000"
                        },
                        {
                            "id": 125904,
                            "name": "Santa Clara",
                            "latitude": "32.77952000",
                            "longitude": "-108.15032000"
                        },
                        {
                            "id": 125907,
                            "name": "Santa Clara Pueblo",
                            "latitude": "35.96558000",
                            "longitude": "-106.08863000"
                        },
                        {
                            "id": 125914,
                            "name": "Santa Fe",
                            "latitude": "35.68698000",
                            "longitude": "-105.93780000"
                        },
                        {
                            "id": 125915,
                            "name": "Santa Fe County",
                            "latitude": "35.50686000",
                            "longitude": "-105.97612000"
                        },
                        {
                            "id": 125923,
                            "name": "Santa Rosa",
                            "latitude": "34.93867000",
                            "longitude": "-104.68249000"
                        },
                        {
                            "id": 125926,
                            "name": "Santa Teresa",
                            "latitude": "31.85594000",
                            "longitude": "-106.63916000"
                        },
                        {
                            "id": 125931,
                            "name": "Santo Domingo Pueblo",
                            "latitude": "35.51476000",
                            "longitude": "-106.36586000"
                        },
                        {
                            "id": 126358,
                            "name": "Shiprock",
                            "latitude": "36.78555000",
                            "longitude": "-108.68703000"
                        },
                        {
                            "id": 126403,
                            "name": "Sierra County",
                            "latitude": "33.13047000",
                            "longitude": "-107.19250000"
                        },
                        {
                            "id": 126422,
                            "name": "Silver City",
                            "latitude": "32.77007000",
                            "longitude": "-108.28033000"
                        },
                        {
                            "id": 126488,
                            "name": "Skyline-Ganipa",
                            "latitude": "35.03279000",
                            "longitude": "-107.61396000"
                        },
                        {
                            "id": 126557,
                            "name": "Socorro",
                            "latitude": "34.05840000",
                            "longitude": "-106.89142000"
                        },
                        {
                            "id": 126559,
                            "name": "Socorro County",
                            "latitude": "34.00724000",
                            "longitude": "-106.93033000"
                        },
                        {
                            "id": 126740,
                            "name": "South Valley",
                            "latitude": "35.01005000",
                            "longitude": "-106.67808000"
                        },
                        {
                            "id": 126832,
                            "name": "Spencerville",
                            "latitude": "36.82000000",
                            "longitude": "-108.05813000"
                        },
                        {
                            "id": 127254,
                            "name": "Sunland Park",
                            "latitude": "31.79650000",
                            "longitude": "-106.57999000"
                        },
                        {
                            "id": 127422,
                            "name": "Taos",
                            "latitude": "36.40725000",
                            "longitude": "-105.57307000"
                        },
                        {
                            "id": 127423,
                            "name": "Taos County",
                            "latitude": "36.57830000",
                            "longitude": "-105.63097000"
                        },
                        {
                            "id": 127424,
                            "name": "Taos Pueblo",
                            "latitude": "36.43864000",
                            "longitude": "-105.54445000"
                        },
                        {
                            "id": 127549,
                            "name": "Texico",
                            "latitude": "34.38869000",
                            "longitude": "-103.05134000"
                        },
                        {
                            "id": 127598,
                            "name": "Thoreau",
                            "latitude": "35.40253000",
                            "longitude": "-108.22340000"
                        },
                        {
                            "id": 127636,
                            "name": "Tierra Amarilla",
                            "latitude": "36.70029000",
                            "longitude": "-106.54976000"
                        },
                        {
                            "id": 127712,
                            "name": "Tome",
                            "latitude": "34.74089000",
                            "longitude": "-106.72836000"
                        },
                        {
                            "id": 127743,
                            "name": "Torrance County",
                            "latitude": "34.64047000",
                            "longitude": "-105.85078000"
                        },
                        {
                            "id": 127861,
                            "name": "Truth or Consequences",
                            "latitude": "33.12840000",
                            "longitude": "-107.25281000"
                        },
                        {
                            "id": 127876,
                            "name": "Tucumcari",
                            "latitude": "35.17191000",
                            "longitude": "-103.72686000"
                        },
                        {
                            "id": 127884,
                            "name": "Tularosa",
                            "latitude": "33.07397000",
                            "longitude": "-106.01860000"
                        },
                        {
                            "id": 127932,
                            "name": "Twin Lakes",
                            "latitude": "35.70919000",
                            "longitude": "-108.77481000"
                        },
                        {
                            "id": 128002,
                            "name": "Union County",
                            "latitude": "36.48176000",
                            "longitude": "-103.47099000"
                        },
                        {
                            "id": 128034,
                            "name": "University Park",
                            "latitude": "32.28343000",
                            "longitude": "-106.75334000"
                        },
                        {
                            "id": 128043,
                            "name": "Upper Fruitland",
                            "latitude": "36.71584000",
                            "longitude": "-108.31424000"
                        },
                        {
                            "id": 128074,
                            "name": "Vado",
                            "latitude": "32.11176000",
                            "longitude": "-106.66250000"
                        },
                        {
                            "id": 128088,
                            "name": "Valencia",
                            "latitude": "34.79950000",
                            "longitude": "-106.70030000"
                        },
                        {
                            "id": 128089,
                            "name": "Valencia County",
                            "latitude": "34.71545000",
                            "longitude": "-106.80911000"
                        },
                        {
                            "id": 128604,
                            "name": "Waterflow",
                            "latitude": "36.75972000",
                            "longitude": "-108.48175000"
                        },
                        {
                            "id": 128884,
                            "name": "West Hammond",
                            "latitude": "36.68071000",
                            "longitude": "-108.04921000"
                        },
                        {
                            "id": 129158,
                            "name": "White Rock",
                            "latitude": "35.82753000",
                            "longitude": "-106.20391000"
                        },
                        {
                            "id": 129160,
                            "name": "White Sands",
                            "latitude": "32.38093000",
                            "longitude": "-106.47944000"
                        },
                        {
                            "id": 129762,
                            "name": "Zuni Pueblo",
                            "latitude": "35.07253000",
                            "longitude": "-108.85064000"
                        }
                    ]
                },
                {
                    "id": 1452,
                    "name": "New York",
                    "state_code": "NY",
                    "cities": [
                        {
                            "id": 111014,
                            "name": "Adams",
                            "latitude": "43.80923000",
                            "longitude": "-76.02409000"
                        },
                        {
                            "id": 111016,
                            "name": "Adams Center",
                            "latitude": "43.86006000",
                            "longitude": "-76.00548000"
                        },
                        {
                            "id": 111037,
                            "name": "Addison",
                            "latitude": "42.10285000",
                            "longitude": "-77.23359000"
                        },
                        {
                            "id": 111071,
                            "name": "Airmont",
                            "latitude": "41.10093000",
                            "longitude": "-74.11625000"
                        },
                        {
                            "id": 111078,
                            "name": "Akron",
                            "latitude": "43.02089000",
                            "longitude": "-78.49530000"
                        },
                        {
                            "id": 111082,
                            "name": "Alabama",
                            "latitude": "43.09645000",
                            "longitude": "-78.39086000"
                        },
                        {
                            "id": 111108,
                            "name": "Albany",
                            "latitude": "42.65258000",
                            "longitude": "-73.75623000"
                        },
                        {
                            "id": 111112,
                            "name": "Albany County",
                            "latitude": "42.60018000",
                            "longitude": "-73.97356000"
                        },
                        {
                            "id": 111119,
                            "name": "Albertson",
                            "latitude": "40.77343000",
                            "longitude": "-73.64318000"
                        },
                        {
                            "id": 111128,
                            "name": "Albion",
                            "latitude": "43.24645000",
                            "longitude": "-78.19363000"
                        },
                        {
                            "id": 111136,
                            "name": "Alden",
                            "latitude": "42.90006000",
                            "longitude": "-78.49197000"
                        },
                        {
                            "id": 111157,
                            "name": "Alexandria Bay",
                            "latitude": "44.33588000",
                            "longitude": "-75.91773000"
                        },
                        {
                            "id": 111160,
                            "name": "Alfred",
                            "latitude": "42.25424000",
                            "longitude": "-77.79055000"
                        },
                        {
                            "id": 111180,
                            "name": "Allegany",
                            "latitude": "42.09006000",
                            "longitude": "-78.49419000"
                        },
                        {
                            "id": 111182,
                            "name": "Allegany County",
                            "latitude": "42.25739000",
                            "longitude": "-78.02756000"
                        },
                        {
                            "id": 111235,
                            "name": "Altamont",
                            "latitude": "42.70063000",
                            "longitude": "-74.03374000"
                        },
                        {
                            "id": 111262,
                            "name": "Amagansett",
                            "latitude": "40.97371000",
                            "longitude": "-72.14369000"
                        },
                        {
                            "id": 111283,
                            "name": "Amherst",
                            "latitude": "42.97839000",
                            "longitude": "-78.79976000"
                        },
                        {
                            "id": 111292,
                            "name": "Amityville",
                            "latitude": "40.67899000",
                            "longitude": "-73.41707000"
                        },
                        {
                            "id": 111295,
                            "name": "Amsterdam",
                            "latitude": "42.93869000",
                            "longitude": "-74.18819000"
                        },
                        {
                            "id": 111325,
                            "name": "Andover",
                            "latitude": "42.15646000",
                            "longitude": "-77.79555000"
                        },
                        {
                            "id": 111341,
                            "name": "Angola",
                            "latitude": "42.63839000",
                            "longitude": "-79.02782000"
                        },
                        {
                            "id": 111342,
                            "name": "Angola on the Lake",
                            "latitude": "42.65478000",
                            "longitude": "-79.04893000"
                        },
                        {
                            "id": 111383,
                            "name": "Apalachin",
                            "latitude": "42.06952000",
                            "longitude": "-76.15465000"
                        },
                        {
                            "id": 111405,
                            "name": "Aquebogue",
                            "latitude": "40.94454000",
                            "longitude": "-72.62704000"
                        },
                        {
                            "id": 111419,
                            "name": "Arcade",
                            "latitude": "42.53395000",
                            "longitude": "-78.42307000"
                        },
                        {
                            "id": 111444,
                            "name": "Ardsley",
                            "latitude": "41.01065000",
                            "longitude": "-73.84375000"
                        },
                        {
                            "id": 111468,
                            "name": "Arlington",
                            "latitude": "41.69593000",
                            "longitude": "-73.89680000"
                        },
                        {
                            "id": 111478,
                            "name": "Armonk",
                            "latitude": "41.12648000",
                            "longitude": "-73.71402000"
                        },
                        {
                            "id": 111493,
                            "name": "Arrochar",
                            "latitude": "40.59844000",
                            "longitude": "-74.07264000"
                        },
                        {
                            "id": 111504,
                            "name": "Arverne",
                            "latitude": "40.59122000",
                            "longitude": "-73.79597000"
                        },
                        {
                            "id": 111562,
                            "name": "Astoria",
                            "latitude": "40.77205000",
                            "longitude": "-73.93014000"
                        },
                        {
                            "id": 111579,
                            "name": "Athens",
                            "latitude": "42.26036000",
                            "longitude": "-73.80957000"
                        },
                        {
                            "id": 111597,
                            "name": "Atlantic Beach",
                            "latitude": "40.58899000",
                            "longitude": "-73.72902000"
                        },
                        {
                            "id": 111610,
                            "name": "Attica",
                            "latitude": "42.86423000",
                            "longitude": "-78.28029000"
                        },
                        {
                            "id": 111628,
                            "name": "Auburn",
                            "latitude": "42.93173000",
                            "longitude": "-76.56605000"
                        },
                        {
                            "id": 111650,
                            "name": "Augusta",
                            "latitude": "42.97479000",
                            "longitude": "-75.50129000"
                        },
                        {
                            "id": 111680,
                            "name": "Averill Park",
                            "latitude": "42.63397000",
                            "longitude": "-73.55373000"
                        },
                        {
                            "id": 111693,
                            "name": "Avon",
                            "latitude": "42.91201000",
                            "longitude": "-77.74556000"
                        },
                        {
                            "id": 111716,
                            "name": "Babylon",
                            "latitude": "40.69566000",
                            "longitude": "-73.32568000"
                        },
                        {
                            "id": 111733,
                            "name": "Bainbridge",
                            "latitude": "42.29341000",
                            "longitude": "-75.47935000"
                        },
                        {
                            "id": 111737,
                            "name": "Baiting Hollow",
                            "latitude": "40.95621000",
                            "longitude": "-72.74427000"
                        },
                        {
                            "id": 111756,
                            "name": "Baldwin",
                            "latitude": "40.65649000",
                            "longitude": "-73.60930000"
                        },
                        {
                            "id": 111762,
                            "name": "Baldwin Harbor",
                            "latitude": "40.63955000",
                            "longitude": "-73.60846000"
                        },
                        {
                            "id": 111764,
                            "name": "Baldwinsville",
                            "latitude": "43.15868000",
                            "longitude": "-76.33271000"
                        },
                        {
                            "id": 111775,
                            "name": "Ballston Spa",
                            "latitude": "43.00091000",
                            "longitude": "-73.84901000"
                        },
                        {
                            "id": 111779,
                            "name": "Balmville",
                            "latitude": "41.53482000",
                            "longitude": "-74.01486000"
                        },
                        {
                            "id": 111819,
                            "name": "Bardonia",
                            "latitude": "41.10954000",
                            "longitude": "-73.99625000"
                        },
                        {
                            "id": 111837,
                            "name": "Barnum Island",
                            "latitude": "40.60455000",
                            "longitude": "-73.64402000"
                        },
                        {
                            "id": 111888,
                            "name": "Batavia",
                            "latitude": "42.99812000",
                            "longitude": "-78.18752000"
                        },
                        {
                            "id": 111898,
                            "name": "Bath",
                            "latitude": "42.33702000",
                            "longitude": "-77.31776000"
                        },
                        {
                            "id": 111900,
                            "name": "Bath Beach",
                            "latitude": "40.60455000",
                            "longitude": "-74.00431000"
                        },
                        {
                            "id": 111918,
                            "name": "Baxter Estates",
                            "latitude": "40.83482000",
                            "longitude": "-73.69541000"
                        },
                        {
                            "id": 111930,
                            "name": "Bay Park",
                            "latitude": "40.63316000",
                            "longitude": "-73.67041000"
                        },
                        {
                            "id": 111934,
                            "name": "Bay Shore",
                            "latitude": "40.72510000",
                            "longitude": "-73.24539000"
                        },
                        {
                            "id": 111936,
                            "name": "Bay Wood",
                            "latitude": "40.75010000",
                            "longitude": "-73.29123000"
                        },
                        {
                            "id": 111940,
                            "name": "Baychester",
                            "latitude": "40.86928000",
                            "longitude": "-73.83645000"
                        },
                        {
                            "id": 111952,
                            "name": "Bayport",
                            "latitude": "40.73843000",
                            "longitude": "-73.05067000"
                        },
                        {
                            "id": 111955,
                            "name": "Bayside",
                            "latitude": "40.76844000",
                            "longitude": "-73.77708000"
                        },
                        {
                            "id": 111961,
                            "name": "Bayville",
                            "latitude": "40.91065000",
                            "longitude": "-73.56207000"
                        },
                        {
                            "id": 111968,
                            "name": "Beacon",
                            "latitude": "41.50482000",
                            "longitude": "-73.96958000"
                        },
                        {
                            "id": 112003,
                            "name": "Beaver Dam Lake",
                            "latitude": "41.44743000",
                            "longitude": "-74.11463000"
                        },
                        {
                            "id": 112007,
                            "name": "Beaverdam Lake-Salisbury Mills",
                            "latitude": "41.44162000",
                            "longitude": "-74.11629000"
                        },
                        {
                            "id": 112025,
                            "name": "Bedford",
                            "latitude": "41.20426000",
                            "longitude": "-73.64374000"
                        },
                        {
                            "id": 112030,
                            "name": "Bedford Hills",
                            "latitude": "41.23676000",
                            "longitude": "-73.69458000"
                        },
                        {
                            "id": 112077,
                            "name": "Bellaire",
                            "latitude": "40.71399000",
                            "longitude": "-73.75402000"
                        },
                        {
                            "id": 112084,
                            "name": "Belle Harbor",
                            "latitude": "40.57594000",
                            "longitude": "-73.84819000"
                        },
                        {
                            "id": 112100,
                            "name": "Bellerose",
                            "latitude": "40.72427000",
                            "longitude": "-73.71513000"
                        },
                        {
                            "id": 112101,
                            "name": "Bellerose Terrace",
                            "latitude": "40.72066000",
                            "longitude": "-73.72596000"
                        },
                        {
                            "id": 112123,
                            "name": "Bellmore",
                            "latitude": "40.66871000",
                            "longitude": "-73.52707000"
                        },
                        {
                            "id": 112125,
                            "name": "Bellport",
                            "latitude": "40.75704000",
                            "longitude": "-72.93927000"
                        },
                        {
                            "id": 112140,
                            "name": "Belmont",
                            "latitude": "42.22312000",
                            "longitude": "-78.03445000"
                        },
                        {
                            "id": 112187,
                            "name": "Bensonhurst",
                            "latitude": "40.60177000",
                            "longitude": "-73.99403000"
                        },
                        {
                            "id": 112216,
                            "name": "Bergen",
                            "latitude": "43.08534000",
                            "longitude": "-77.94223000"
                        },
                        {
                            "id": 112217,
                            "name": "Bergen Beach",
                            "latitude": "40.62038000",
                            "longitude": "-73.90680000"
                        },
                        {
                            "id": 112293,
                            "name": "Bethpage",
                            "latitude": "40.74427000",
                            "longitude": "-73.48207000"
                        },
                        {
                            "id": 112320,
                            "name": "Big Flats",
                            "latitude": "42.13730000",
                            "longitude": "-76.93691000"
                        },
                        {
                            "id": 112346,
                            "name": "Billington Heights",
                            "latitude": "42.78423000",
                            "longitude": "-78.62642000"
                        },
                        {
                            "id": 112351,
                            "name": "Binghamton",
                            "latitude": "42.09869000",
                            "longitude": "-75.91797000"
                        },
                        {
                            "id": 112382,
                            "name": "Black River",
                            "latitude": "44.01256000",
                            "longitude": "-75.79437000"
                        },
                        {
                            "id": 112424,
                            "name": "Blasdell",
                            "latitude": "42.79728000",
                            "longitude": "-78.82337000"
                        },
                        {
                            "id": 112425,
                            "name": "Blauvelt",
                            "latitude": "41.06343000",
                            "longitude": "-73.95764000"
                        },
                        {
                            "id": 112438,
                            "name": "Bloomfield",
                            "latitude": "40.61260000",
                            "longitude": "-74.17820000"
                        },
                        {
                            "id": 112473,
                            "name": "Blue Point",
                            "latitude": "40.74399000",
                            "longitude": "-73.03455000"
                        },
                        {
                            "id": 112502,
                            "name": "Bohemia",
                            "latitude": "40.76926000",
                            "longitude": "-73.11511000"
                        },
                        {
                            "id": 112520,
                            "name": "Bolivar",
                            "latitude": "42.06673000",
                            "longitude": "-78.16779000"
                        },
                        {
                            "id": 112570,
                            "name": "Boonville",
                            "latitude": "43.48368000",
                            "longitude": "-75.33656000"
                        },
                        {
                            "id": 112580,
                            "name": "Borough Park",
                            "latitude": "40.63399000",
                            "longitude": "-73.99681000"
                        },
                        {
                            "id": 112590,
                            "name": "Boston",
                            "latitude": "42.62895000",
                            "longitude": "-78.73753000"
                        },
                        {
                            "id": 112731,
                            "name": "Brentwood",
                            "latitude": "40.78121000",
                            "longitude": "-73.24623000"
                        },
                        {
                            "id": 112740,
                            "name": "Brewerton",
                            "latitude": "43.23812000",
                            "longitude": "-76.14076000"
                        },
                        {
                            "id": 112743,
                            "name": "Brewster",
                            "latitude": "41.39732000",
                            "longitude": "-73.61707000"
                        },
                        {
                            "id": 112746,
                            "name": "Brewster Hill",
                            "latitude": "41.42398000",
                            "longitude": "-73.60429000"
                        },
                        {
                            "id": 112750,
                            "name": "Briarcliff Manor",
                            "latitude": "41.14565000",
                            "longitude": "-73.82375000"
                        },
                        {
                            "id": 112751,
                            "name": "Briarwood",
                            "latitude": "40.70935000",
                            "longitude": "-73.81529000"
                        },
                        {
                            "id": 112757,
                            "name": "Bridgehampton",
                            "latitude": "40.93788000",
                            "longitude": "-72.30092000"
                        },
                        {
                            "id": 112763,
                            "name": "Bridgeport",
                            "latitude": "43.15535000",
                            "longitude": "-75.96936000"
                        },
                        {
                            "id": 112790,
                            "name": "Brighton",
                            "latitude": "43.14756000",
                            "longitude": "-77.55055000"
                        },
                        {
                            "id": 112792,
                            "name": "Brighton Beach",
                            "latitude": "40.57788000",
                            "longitude": "-73.95958000"
                        },
                        {
                            "id": 112794,
                            "name": "Brightwaters",
                            "latitude": "40.72093000",
                            "longitude": "-73.26734000"
                        },
                        {
                            "id": 112798,
                            "name": "Brinckerhoff",
                            "latitude": "41.54398000",
                            "longitude": "-73.86819000"
                        },
                        {
                            "id": 112820,
                            "name": "Broad Channel",
                            "latitude": "40.60316000",
                            "longitude": "-73.82041000"
                        },
                        {
                            "id": 112823,
                            "name": "Broadalbin",
                            "latitude": "43.05868000",
                            "longitude": "-74.19652000"
                        },
                        {
                            "id": 112833,
                            "name": "Brockport",
                            "latitude": "43.21367000",
                            "longitude": "-77.93918000"
                        },
                        {
                            "id": 112836,
                            "name": "Brocton",
                            "latitude": "42.38867000",
                            "longitude": "-79.44116000"
                        },
                        {
                            "id": 112846,
                            "name": "Bronx",
                            "latitude": "40.82732000",
                            "longitude": "-73.92357000"
                        },
                        {
                            "id": 112847,
                            "name": "Bronxville",
                            "latitude": "40.93815000",
                            "longitude": "-73.83208000"
                        },
                        {
                            "id": 112860,
                            "name": "Brookhaven",
                            "latitude": "40.77927000",
                            "longitude": "-72.91538000"
                        },
                        {
                            "id": 112872,
                            "name": "Brooklyn",
                            "latitude": "40.65010000",
                            "longitude": "-73.94958000"
                        },
                        {
                            "id": 112875,
                            "name": "Brooklyn Heights",
                            "latitude": "40.69538000",
                            "longitude": "-73.99375000"
                        },
                        {
                            "id": 112895,
                            "name": "Brookville",
                            "latitude": "40.81316000",
                            "longitude": "-73.56735000"
                        },
                        {
                            "id": 112899,
                            "name": "Broome County",
                            "latitude": "42.16022000",
                            "longitude": "-75.81962000"
                        },
                        {
                            "id": 112933,
                            "name": "Brownsville",
                            "latitude": "40.66094000",
                            "longitude": "-73.92014000"
                        },
                        {
                            "id": 112938,
                            "name": "Brownville",
                            "latitude": "44.00700000",
                            "longitude": "-75.98409000"
                        },
                        {
                            "id": 112972,
                            "name": "Buchanan",
                            "latitude": "41.26204000",
                            "longitude": "-73.93819000"
                        },
                        {
                            "id": 113014,
                            "name": "Buffalo",
                            "latitude": "42.88645000",
                            "longitude": "-78.87837000"
                        },
                        {
                            "id": 113103,
                            "name": "Bushwick",
                            "latitude": "40.69427000",
                            "longitude": "-73.91875000"
                        },
                        {
                            "id": 113171,
                            "name": "Cairo",
                            "latitude": "42.29897000",
                            "longitude": "-73.99847000"
                        },
                        {
                            "id": 113178,
                            "name": "Calcium",
                            "latitude": "44.02173000",
                            "longitude": "-75.84604000"
                        },
                        {
                            "id": 113191,
                            "name": "Caledonia",
                            "latitude": "42.97312000",
                            "longitude": "-77.85278000"
                        },
                        {
                            "id": 113237,
                            "name": "Calverton",
                            "latitude": "40.90649000",
                            "longitude": "-72.74343000"
                        },
                        {
                            "id": 113248,
                            "name": "Cambria Heights",
                            "latitude": "40.69455000",
                            "longitude": "-73.73847000"
                        },
                        {
                            "id": 113254,
                            "name": "Cambridge",
                            "latitude": "43.02813000",
                            "longitude": "-73.38122000"
                        },
                        {
                            "id": 113267,
                            "name": "Camden",
                            "latitude": "43.33451000",
                            "longitude": "-75.74796000"
                        },
                        {
                            "id": 113284,
                            "name": "Camillus",
                            "latitude": "43.03923000",
                            "longitude": "-76.30410000"
                        },
                        {
                            "id": 113317,
                            "name": "Canajoharie",
                            "latitude": "42.90563000",
                            "longitude": "-74.57181000"
                        },
                        {
                            "id": 113318,
                            "name": "Canandaigua",
                            "latitude": "42.87423000",
                            "longitude": "-77.28804000"
                        },
                        {
                            "id": 113319,
                            "name": "Canarsie",
                            "latitude": "40.64372000",
                            "longitude": "-73.90069000"
                        },
                        {
                            "id": 113320,
                            "name": "Canastota",
                            "latitude": "43.07951000",
                            "longitude": "-75.75074000"
                        },
                        {
                            "id": 113329,
                            "name": "Canisteo",
                            "latitude": "42.27035000",
                            "longitude": "-77.60582000"
                        },
                        {
                            "id": 113347,
                            "name": "Canton",
                            "latitude": "44.59562000",
                            "longitude": "-75.16909000"
                        },
                        {
                            "id": 113397,
                            "name": "Carle Place",
                            "latitude": "40.75260000",
                            "longitude": "-73.61041000"
                        },
                        {
                            "id": 113417,
                            "name": "Carmel",
                            "latitude": "41.43009000",
                            "longitude": "-73.68013000"
                        },
                        {
                            "id": 113419,
                            "name": "Carmel Hamlet",
                            "latitude": "41.41485000",
                            "longitude": "-73.68524000"
                        },
                        {
                            "id": 113497,
                            "name": "Carthage",
                            "latitude": "43.97812000",
                            "longitude": "-75.60936000"
                        },
                        {
                            "id": 113553,
                            "name": "Castleton-on-Hudson",
                            "latitude": "42.51841000",
                            "longitude": "-73.75123000"
                        },
                        {
                            "id": 113579,
                            "name": "Catskill",
                            "latitude": "42.21731000",
                            "longitude": "-73.86457000"
                        },
                        {
                            "id": 113580,
                            "name": "Cattaraugus County",
                            "latitude": "42.24863000",
                            "longitude": "-78.67885000"
                        },
                        {
                            "id": 113594,
                            "name": "Cayuga County",
                            "latitude": "43.01033000",
                            "longitude": "-76.57436000"
                        },
                        {
                            "id": 113595,
                            "name": "Cayuga Heights",
                            "latitude": "42.46010000",
                            "longitude": "-76.48776000"
                        },
                        {
                            "id": 113596,
                            "name": "Cazenovia",
                            "latitude": "42.93007000",
                            "longitude": "-75.85269000"
                        },
                        {
                            "id": 113626,
                            "name": "Cedarhurst",
                            "latitude": "40.62288000",
                            "longitude": "-73.72430000"
                        },
                        {
                            "id": 113632,
                            "name": "Celoron",
                            "latitude": "42.10950000",
                            "longitude": "-79.28310000"
                        },
                        {
                            "id": 113645,
                            "name": "Center Moriches",
                            "latitude": "40.80038000",
                            "longitude": "-72.78982000"
                        },
                        {
                            "id": 113648,
                            "name": "Centereach",
                            "latitude": "40.85843000",
                            "longitude": "-73.09955000"
                        },
                        {
                            "id": 113650,
                            "name": "Centerport",
                            "latitude": "40.88538000",
                            "longitude": "-73.37623000"
                        },
                        {
                            "id": 113678,
                            "name": "Central Islip",
                            "latitude": "40.79065000",
                            "longitude": "-73.20178000"
                        },
                        {
                            "id": 113681,
                            "name": "Central Square",
                            "latitude": "43.28674000",
                            "longitude": "-76.14604000"
                        },
                        {
                            "id": 113682,
                            "name": "Central Valley",
                            "latitude": "41.33176000",
                            "longitude": "-74.12098000"
                        },
                        {
                            "id": 113708,
                            "name": "Chadwicks",
                            "latitude": "43.02785000",
                            "longitude": "-75.27155000"
                        },
                        {
                            "id": 113726,
                            "name": "Champlain",
                            "latitude": "44.98643000",
                            "longitude": "-73.44653000"
                        },
                        {
                            "id": 113746,
                            "name": "Chappaqua",
                            "latitude": "41.15954000",
                            "longitude": "-73.76485000"
                        },
                        {
                            "id": 113768,
                            "name": "Charleston",
                            "latitude": "40.53677000",
                            "longitude": "-74.23737000"
                        },
                        {
                            "id": 113799,
                            "name": "Chatham",
                            "latitude": "42.36425000",
                            "longitude": "-73.59484000"
                        },
                        {
                            "id": 113815,
                            "name": "Chautauqua County",
                            "latitude": "42.30294000",
                            "longitude": "-79.40576000"
                        },
                        {
                            "id": 113824,
                            "name": "Cheektowaga",
                            "latitude": "42.90339000",
                            "longitude": "-78.75475000"
                        },
                        {
                            "id": 113834,
                            "name": "Chelsea",
                            "latitude": "40.60066000",
                            "longitude": "-74.19487000"
                        },
                        {
                            "id": 113836,
                            "name": "Chemung County",
                            "latitude": "42.14125000",
                            "longitude": "-76.76003000"
                        },
                        {
                            "id": 113837,
                            "name": "Chenango Bridge",
                            "latitude": "42.16674000",
                            "longitude": "-75.86242000"
                        },
                        {
                            "id": 113838,
                            "name": "Chenango County",
                            "latitude": "42.49351000",
                            "longitude": "-75.61158000"
                        },
                        {
                            "id": 113888,
                            "name": "Chester",
                            "latitude": "41.36259000",
                            "longitude": "-74.27126000"
                        },
                        {
                            "id": 113912,
                            "name": "Chestnut Ridge",
                            "latitude": "41.08426000",
                            "longitude": "-74.05570000"
                        },
                        {
                            "id": 113981,
                            "name": "Chittenango",
                            "latitude": "43.04507000",
                            "longitude": "-75.86658000"
                        },
                        {
                            "id": 114013,
                            "name": "Churchville",
                            "latitude": "43.10423000",
                            "longitude": "-77.88445000"
                        },
                        {
                            "id": 114043,
                            "name": "City Island",
                            "latitude": "40.84732000",
                            "longitude": "-73.78652000"
                        },
                        {
                            "id": 114108,
                            "name": "Clarence",
                            "latitude": "42.97673000",
                            "longitude": "-78.59197000"
                        },
                        {
                            "id": 114109,
                            "name": "Clarence Center",
                            "latitude": "43.01061000",
                            "longitude": "-78.63753000"
                        },
                        {
                            "id": 114132,
                            "name": "Clark Mills",
                            "latitude": "43.09229000",
                            "longitude": "-75.37962000"
                        },
                        {
                            "id": 114146,
                            "name": "Clarkson",
                            "latitude": "43.23312000",
                            "longitude": "-77.92751000"
                        },
                        {
                            "id": 114202,
                            "name": "Clayton",
                            "latitude": "44.23949000",
                            "longitude": "-76.08578000"
                        },
                        {
                            "id": 114258,
                            "name": "Clifton",
                            "latitude": "40.62010000",
                            "longitude": "-74.07709000"
                        },
                        {
                            "id": 114264,
                            "name": "Clifton Springs",
                            "latitude": "42.96173000",
                            "longitude": "-77.13998000"
                        },
                        {
                            "id": 114283,
                            "name": "Clinton",
                            "latitude": "43.04840000",
                            "longitude": "-75.37850000"
                        },
                        {
                            "id": 114295,
                            "name": "Clinton County",
                            "latitude": "44.74623000",
                            "longitude": "-73.67817000"
                        },
                        {
                            "id": 114298,
                            "name": "Clintondale",
                            "latitude": "41.69482000",
                            "longitude": "-74.05125000"
                        },
                        {
                            "id": 114320,
                            "name": "Clyde",
                            "latitude": "43.08423000",
                            "longitude": "-76.86940000"
                        },
                        {
                            "id": 114322,
                            "name": "Clymer",
                            "latitude": "42.02089000",
                            "longitude": "-79.63005000"
                        },
                        {
                            "id": 114347,
                            "name": "Cobleskill",
                            "latitude": "42.67785000",
                            "longitude": "-74.48542000"
                        },
                        {
                            "id": 114375,
                            "name": "Cohoes",
                            "latitude": "42.77424000",
                            "longitude": "-73.70012000"
                        },
                        {
                            "id": 114387,
                            "name": "Cold Spring",
                            "latitude": "41.42009000",
                            "longitude": "-73.95458000"
                        },
                        {
                            "id": 114388,
                            "name": "Cold Spring Harbor",
                            "latitude": "40.87149000",
                            "longitude": "-73.45679000"
                        },
                        {
                            "id": 114416,
                            "name": "College Point",
                            "latitude": "40.78760000",
                            "longitude": "-73.84597000"
                        },
                        {
                            "id": 114452,
                            "name": "Colonie",
                            "latitude": "42.71786000",
                            "longitude": "-73.83346000"
                        },
                        {
                            "id": 114480,
                            "name": "Columbia County",
                            "latitude": "42.25008000",
                            "longitude": "-73.63185000"
                        },
                        {
                            "id": 114521,
                            "name": "Commack",
                            "latitude": "40.84288000",
                            "longitude": "-73.29289000"
                        },
                        {
                            "id": 114540,
                            "name": "Concord",
                            "latitude": "40.60816000",
                            "longitude": "-74.08431000"
                        },
                        {
                            "id": 114556,
                            "name": "Coney Island",
                            "latitude": "40.57788000",
                            "longitude": "-73.99403000"
                        },
                        {
                            "id": 114557,
                            "name": "Congers",
                            "latitude": "41.15065000",
                            "longitude": "-73.94542000"
                        },
                        {
                            "id": 114572,
                            "name": "Constantia",
                            "latitude": "43.24785000",
                            "longitude": "-76.00020000"
                        },
                        {
                            "id": 114605,
                            "name": "Cooperstown",
                            "latitude": "42.70048000",
                            "longitude": "-74.92426000"
                        },
                        {
                            "id": 114616,
                            "name": "Copiague",
                            "latitude": "40.68149000",
                            "longitude": "-73.39984000"
                        },
                        {
                            "id": 114629,
                            "name": "Coram",
                            "latitude": "40.86871000",
                            "longitude": "-73.00149000"
                        },
                        {
                            "id": 114645,
                            "name": "Corinth",
                            "latitude": "43.24452000",
                            "longitude": "-73.83234000"
                        },
                        {
                            "id": 114653,
                            "name": "Corning",
                            "latitude": "42.14285000",
                            "longitude": "-77.05469000"
                        },
                        {
                            "id": 114658,
                            "name": "Cornwall",
                            "latitude": "41.44482000",
                            "longitude": "-74.01570000"
                        },
                        {
                            "id": 114661,
                            "name": "Corona",
                            "latitude": "40.74705000",
                            "longitude": "-73.86014000"
                        },
                        {
                            "id": 114676,
                            "name": "Cortland",
                            "latitude": "42.60118000",
                            "longitude": "-76.18048000"
                        },
                        {
                            "id": 114677,
                            "name": "Cortland County",
                            "latitude": "42.59501000",
                            "longitude": "-76.07027000"
                        },
                        {
                            "id": 114678,
                            "name": "Cortland West",
                            "latitude": "42.59431000",
                            "longitude": "-76.22587000"
                        },
                        {
                            "id": 114721,
                            "name": "Country Knolls",
                            "latitude": "42.91508000",
                            "longitude": "-73.80512000"
                        },
                        {
                            "id": 114751,
                            "name": "Coxsackie",
                            "latitude": "42.35092000",
                            "longitude": "-73.80290000"
                        },
                        {
                            "id": 114834,
                            "name": "Crompond",
                            "latitude": "41.29509000",
                            "longitude": "-73.86541000"
                        },
                        {
                            "id": 114859,
                            "name": "Croton-on-Hudson",
                            "latitude": "41.20843000",
                            "longitude": "-73.89125000"
                        },
                        {
                            "id": 114866,
                            "name": "Crown Heights",
                            "latitude": "41.63732000",
                            "longitude": "-73.93792000"
                        },
                        {
                            "id": 114872,
                            "name": "Crugers",
                            "latitude": "41.23343000",
                            "longitude": "-73.92264000"
                        },
                        {
                            "id": 114887,
                            "name": "Cuba",
                            "latitude": "42.21757000",
                            "longitude": "-78.27529000"
                        },
                        {
                            "id": 114920,
                            "name": "Cumberland Head",
                            "latitude": "44.71643000",
                            "longitude": "-73.40263000"
                        },
                        {
                            "id": 114944,
                            "name": "Cutchogue",
                            "latitude": "41.01066000",
                            "longitude": "-72.48509000"
                        },
                        {
                            "id": 114956,
                            "name": "Cypress Hills",
                            "latitude": "40.67705000",
                            "longitude": "-73.89125000"
                        },
                        {
                            "id": 115027,
                            "name": "Dannemora",
                            "latitude": "44.72143000",
                            "longitude": "-73.72375000"
                        },
                        {
                            "id": 115028,
                            "name": "Dansville",
                            "latitude": "42.56090000",
                            "longitude": "-77.69611000"
                        },
                        {
                            "id": 115177,
                            "name": "Deer Park",
                            "latitude": "40.76177000",
                            "longitude": "-73.32929000"
                        },
                        {
                            "id": 115207,
                            "name": "Delaware County",
                            "latitude": "42.19809000",
                            "longitude": "-74.96647000"
                        },
                        {
                            "id": 115210,
                            "name": "Delevan",
                            "latitude": "42.48923000",
                            "longitude": "-78.48085000"
                        },
                        {
                            "id": 115212,
                            "name": "Delhi",
                            "latitude": "42.27814000",
                            "longitude": "-74.91599000"
                        },
                        {
                            "id": 115218,
                            "name": "Delmar",
                            "latitude": "42.62202000",
                            "longitude": "-73.83262000"
                        },
                        {
                            "id": 115256,
                            "name": "Depew",
                            "latitude": "42.90395000",
                            "longitude": "-78.69225000"
                        },
                        {
                            "id": 115258,
                            "name": "Deposit",
                            "latitude": "42.06008000",
                            "longitude": "-75.42768000"
                        },
                        {
                            "id": 115310,
                            "name": "Dexter",
                            "latitude": "44.00784000",
                            "longitude": "-76.04437000"
                        },
                        {
                            "id": 115358,
                            "name": "Dix Hills",
                            "latitude": "40.80482000",
                            "longitude": "-73.33623000"
                        },
                        {
                            "id": 115370,
                            "name": "Dobbs Ferry",
                            "latitude": "41.01454000",
                            "longitude": "-73.87264000"
                        },
                        {
                            "id": 115387,
                            "name": "Dolgeville",
                            "latitude": "43.10090000",
                            "longitude": "-74.77293000"
                        },
                        {
                            "id": 115396,
                            "name": "Dongan Hills",
                            "latitude": "40.58844000",
                            "longitude": "-74.09625000"
                        },
                        {
                            "id": 115439,
                            "name": "Douglaston",
                            "latitude": "40.76871000",
                            "longitude": "-73.74708000"
                        },
                        {
                            "id": 115456,
                            "name": "Dover Plains",
                            "latitude": "41.74121000",
                            "longitude": "-73.57652000"
                        },
                        {
                            "id": 115485,
                            "name": "Dryden",
                            "latitude": "42.49091000",
                            "longitude": "-76.29716000"
                        },
                        {
                            "id": 115530,
                            "name": "Dundee",
                            "latitude": "42.52340000",
                            "longitude": "-76.97663000"
                        },
                        {
                            "id": 115539,
                            "name": "Dunkirk",
                            "latitude": "42.47950000",
                            "longitude": "-79.33393000"
                        },
                        {
                            "id": 115572,
                            "name": "Durham",
                            "latitude": "42.39953000",
                            "longitude": "-74.17236000"
                        },
                        {
                            "id": 115579,
                            "name": "Dutchess County",
                            "latitude": "41.76515000",
                            "longitude": "-73.74286000"
                        },
                        {
                            "id": 115590,
                            "name": "Dyker Heights",
                            "latitude": "40.62149000",
                            "longitude": "-74.00958000"
                        },
                        {
                            "id": 115627,
                            "name": "East Atlantic Beach",
                            "latitude": "40.78982000",
                            "longitude": "-73.74708000"
                        },
                        {
                            "id": 115628,
                            "name": "East Aurora",
                            "latitude": "42.76784000",
                            "longitude": "-78.61336000"
                        },
                        {
                            "id": 115655,
                            "name": "East Elmhurst",
                            "latitude": "40.76121000",
                            "longitude": "-73.86514000"
                        },
                        {
                            "id": 115658,
                            "name": "East Farmingdale",
                            "latitude": "40.72927000",
                            "longitude": "-73.41679000"
                        },
                        {
                            "id": 115661,
                            "name": "East Flatbush",
                            "latitude": "40.65371000",
                            "longitude": "-73.93042000"
                        },
                        {
                            "id": 115667,
                            "name": "East Garden City",
                            "latitude": "40.73083000",
                            "longitude": "-73.59806000"
                        },
                        {
                            "id": 115669,
                            "name": "East Glenville",
                            "latitude": "42.89452000",
                            "longitude": "-73.92790000"
                        },
                        {
                            "id": 115672,
                            "name": "East Greenbush",
                            "latitude": "42.59091000",
                            "longitude": "-73.70179000"
                        },
                        {
                            "id": 115680,
                            "name": "East Hampton",
                            "latitude": "40.96343000",
                            "longitude": "-72.18480000"
                        },
                        {
                            "id": 115681,
                            "name": "East Hampton North",
                            "latitude": "40.97276000",
                            "longitude": "-72.18911000"
                        },
                        {
                            "id": 115683,
                            "name": "East Harlem",
                            "latitude": "40.79472000",
                            "longitude": "-73.94250000"
                        },
                        {
                            "id": 115692,
                            "name": "East Hills",
                            "latitude": "40.79371000",
                            "longitude": "-73.62707000"
                        },
                        {
                            "id": 115695,
                            "name": "East Islip",
                            "latitude": "40.73204000",
                            "longitude": "-73.18567000"
                        },
                        {
                            "id": 115696,
                            "name": "East Ithaca",
                            "latitude": "42.43952000",
                            "longitude": "-76.47855000"
                        },
                        {
                            "id": 115708,
                            "name": "East Massapequa",
                            "latitude": "40.67343000",
                            "longitude": "-73.43651000"
                        },
                        {
                            "id": 115710,
                            "name": "East Meadow",
                            "latitude": "40.71399000",
                            "longitude": "-73.55902000"
                        },
                        {
                            "id": 115717,
                            "name": "East Moriches",
                            "latitude": "40.80510000",
                            "longitude": "-72.76093000"
                        },
                        {
                            "id": 115719,
                            "name": "East New York",
                            "latitude": "40.66677000",
                            "longitude": "-73.88236000"
                        },
                        {
                            "id": 115722,
                            "name": "East Northport",
                            "latitude": "40.87676000",
                            "longitude": "-73.32456000"
                        },
                        {
                            "id": 115724,
                            "name": "East Norwich",
                            "latitude": "40.84677000",
                            "longitude": "-73.53512000"
                        },
                        {
                            "id": 115730,
                            "name": "East Patchogue",
                            "latitude": "40.76704000",
                            "longitude": "-72.99622000"
                        },
                        {
                            "id": 115743,
                            "name": "East Quogue",
                            "latitude": "40.84066000",
                            "longitude": "-72.58148000"
                        },
                        {
                            "id": 115749,
                            "name": "East Rochester",
                            "latitude": "43.10867000",
                            "longitude": "-77.48750000"
                        },
                        {
                            "id": 115750,
                            "name": "East Rockaway",
                            "latitude": "40.64205000",
                            "longitude": "-73.66957000"
                        },
                        {
                            "id": 115757,
                            "name": "East Setauket",
                            "latitude": "40.94149000",
                            "longitude": "-73.10594000"
                        },
                        {
                            "id": 115758,
                            "name": "East Shoreham",
                            "latitude": "40.94482000",
                            "longitude": "-72.87955000"
                        },
                        {
                            "id": 115763,
                            "name": "East Syracuse",
                            "latitude": "43.06534000",
                            "longitude": "-76.07853000"
                        },
                        {
                            "id": 115765,
                            "name": "East Tremont",
                            "latitude": "40.84538000",
                            "longitude": "-73.89097000"
                        },
                        {
                            "id": 115769,
                            "name": "East Village",
                            "latitude": "40.72927000",
                            "longitude": "-73.98736000"
                        },
                        {
                            "id": 115773,
                            "name": "East Williston",
                            "latitude": "40.75843000",
                            "longitude": "-73.63485000"
                        },
                        {
                            "id": 115776,
                            "name": "Eastchester",
                            "latitude": "40.95833000",
                            "longitude": "-73.80861000"
                        },
                        {
                            "id": 115796,
                            "name": "Eastport",
                            "latitude": "40.82593000",
                            "longitude": "-72.73177000"
                        },
                        {
                            "id": 115805,
                            "name": "Eatons Neck",
                            "latitude": "40.93065000",
                            "longitude": "-73.40151000"
                        },
                        {
                            "id": 115830,
                            "name": "Eden",
                            "latitude": "42.65228000",
                            "longitude": "-78.89698000"
                        },
                        {
                            "id": 115845,
                            "name": "Edgemere",
                            "latitude": "40.59622000",
                            "longitude": "-73.76763000"
                        },
                        {
                            "id": 115872,
                            "name": "Edinburg",
                            "latitude": "43.22174000",
                            "longitude": "-74.10402000"
                        },
                        {
                            "id": 115902,
                            "name": "Eggertsville",
                            "latitude": "42.96339000",
                            "longitude": "-78.80392000"
                        },
                        {
                            "id": 115947,
                            "name": "Elbridge",
                            "latitude": "43.03451000",
                            "longitude": "-76.44799000"
                        },
                        {
                            "id": 115980,
                            "name": "Elizabethtown",
                            "latitude": "44.21616000",
                            "longitude": "-73.59097000"
                        },
                        {
                            "id": 116020,
                            "name": "Ellenville",
                            "latitude": "41.71704000",
                            "longitude": "-74.39571000"
                        },
                        {
                            "id": 116047,
                            "name": "Elma Center",
                            "latitude": "42.82978000",
                            "longitude": "-78.63614000"
                        },
                        {
                            "id": 116052,
                            "name": "Elmhurst",
                            "latitude": "40.73649000",
                            "longitude": "-73.87791000"
                        },
                        {
                            "id": 116053,
                            "name": "Elmira",
                            "latitude": "42.08980000",
                            "longitude": "-76.80773000"
                        },
                        {
                            "id": 116054,
                            "name": "Elmira Heights",
                            "latitude": "42.12980000",
                            "longitude": "-76.82079000"
                        },
                        {
                            "id": 116055,
                            "name": "Elmont",
                            "latitude": "40.70094000",
                            "longitude": "-73.71291000"
                        },
                        {
                            "id": 116059,
                            "name": "Elmsford",
                            "latitude": "41.05510000",
                            "longitude": "-73.82013000"
                        },
                        {
                            "id": 116072,
                            "name": "Eltingville",
                            "latitude": "40.54538000",
                            "longitude": "-74.16570000"
                        },
                        {
                            "id": 116082,
                            "name": "Elwood",
                            "latitude": "40.84538000",
                            "longitude": "-73.33512000"
                        },
                        {
                            "id": 116095,
                            "name": "Emerson Hill",
                            "latitude": "40.60872000",
                            "longitude": "-74.09598000"
                        },
                        {
                            "id": 116120,
                            "name": "Endicott",
                            "latitude": "42.09841000",
                            "longitude": "-76.04937000"
                        },
                        {
                            "id": 116121,
                            "name": "Endwell",
                            "latitude": "42.11285000",
                            "longitude": "-76.02103000"
                        },
                        {
                            "id": 116168,
                            "name": "Erie County",
                            "latitude": "42.75824000",
                            "longitude": "-78.77966000"
                        },
                        {
                            "id": 116196,
                            "name": "Essex County",
                            "latitude": "44.11722000",
                            "longitude": "-73.77271000"
                        },
                        {
                            "id": 116341,
                            "name": "Fairmount",
                            "latitude": "43.04729000",
                            "longitude": "-76.23854000"
                        },
                        {
                            "id": 116345,
                            "name": "Fairport",
                            "latitude": "43.09867000",
                            "longitude": "-77.44194000"
                        },
                        {
                            "id": 116353,
                            "name": "Fairview",
                            "latitude": "41.72370000",
                            "longitude": "-73.91986000"
                        },
                        {
                            "id": 116367,
                            "name": "Falconer",
                            "latitude": "42.11867000",
                            "longitude": "-79.19838000"
                        },
                        {
                            "id": 116384,
                            "name": "Fallsburg",
                            "latitude": "41.73204000",
                            "longitude": "-74.60127000"
                        },
                        {
                            "id": 116395,
                            "name": "Far Rockaway",
                            "latitude": "40.60538000",
                            "longitude": "-73.75513000"
                        },
                        {
                            "id": 116411,
                            "name": "Farmingdale",
                            "latitude": "40.73260000",
                            "longitude": "-73.44540000"
                        },
                        {
                            "id": 116424,
                            "name": "Farmingville",
                            "latitude": "40.83121000",
                            "longitude": "-73.02955000"
                        },
                        {
                            "id": 116460,
                            "name": "Fayetteville",
                            "latitude": "43.02979000",
                            "longitude": "-76.00436000"
                        },
                        {
                            "id": 116512,
                            "name": "Financial District",
                            "latitude": "40.70789000",
                            "longitude": "-74.00857000"
                        },
                        {
                            "id": 116524,
                            "name": "Firthcliffe",
                            "latitude": "41.43926000",
                            "longitude": "-74.04514000"
                        },
                        {
                            "id": 116532,
                            "name": "Fishkill",
                            "latitude": "41.53565000",
                            "longitude": "-73.89903000"
                        },
                        {
                            "id": 116548,
                            "name": "Flanders",
                            "latitude": "40.90343000",
                            "longitude": "-72.61759000"
                        },
                        {
                            "id": 116553,
                            "name": "Flatbush",
                            "latitude": "40.65205000",
                            "longitude": "-73.95903000"
                        },
                        {
                            "id": 116555,
                            "name": "Flatlands",
                            "latitude": "40.62122000",
                            "longitude": "-73.93486000"
                        },
                        {
                            "id": 116575,
                            "name": "Floral Park",
                            "latitude": "40.72371000",
                            "longitude": "-73.70485000"
                        },
                        {
                            "id": 116592,
                            "name": "Florida",
                            "latitude": "41.33176000",
                            "longitude": "-74.35682000"
                        },
                        {
                            "id": 116600,
                            "name": "Flower Hill",
                            "latitude": "40.80732000",
                            "longitude": "-73.68124000"
                        },
                        {
                            "id": 116629,
                            "name": "Fonda",
                            "latitude": "42.95452000",
                            "longitude": "-74.37652000"
                        },
                        {
                            "id": 116639,
                            "name": "Fordham",
                            "latitude": "40.85927000",
                            "longitude": "-73.89847000"
                        },
                        {
                            "id": 116658,
                            "name": "Forest Hills",
                            "latitude": "40.71621000",
                            "longitude": "-73.85014000"
                        },
                        {
                            "id": 116702,
                            "name": "Fort Covington Hamlet",
                            "latitude": "44.97178000",
                            "longitude": "-74.50757000"
                        },
                        {
                            "id": 116708,
                            "name": "Fort Drum",
                            "latitude": "44.05843000",
                            "longitude": "-75.76189000"
                        },
                        {
                            "id": 116709,
                            "name": "Fort Edward",
                            "latitude": "43.26702000",
                            "longitude": "-73.58456000"
                        },
                        {
                            "id": 116715,
                            "name": "Fort Hamilton",
                            "latitude": "40.61872000",
                            "longitude": "-74.03320000"
                        },
                        {
                            "id": 116732,
                            "name": "Fort Montgomery",
                            "latitude": "41.33148000",
                            "longitude": "-73.98681000"
                        },
                        {
                            "id": 116743,
                            "name": "Fort Plain",
                            "latitude": "42.93146000",
                            "longitude": "-74.62264000"
                        },
                        {
                            "id": 116748,
                            "name": "Fort Salonga",
                            "latitude": "40.91260000",
                            "longitude": "-73.30095000"
                        },
                        {
                            "id": 116758,
                            "name": "Fort Wadsworth",
                            "latitude": "40.60113000",
                            "longitude": "-74.05738000"
                        },
                        {
                            "id": 116823,
                            "name": "Frankfort",
                            "latitude": "43.03896000",
                            "longitude": "-75.07044000"
                        },
                        {
                            "id": 116861,
                            "name": "Franklin County",
                            "latitude": "44.59293000",
                            "longitude": "-74.30376000"
                        },
                        {
                            "id": 116872,
                            "name": "Franklin Square",
                            "latitude": "40.70732000",
                            "longitude": "-73.67596000"
                        },
                        {
                            "id": 116876,
                            "name": "Franklinville",
                            "latitude": "42.33701000",
                            "longitude": "-78.45808000"
                        },
                        {
                            "id": 116896,
                            "name": "Fredonia",
                            "latitude": "42.44006000",
                            "longitude": "-79.33171000"
                        },
                        {
                            "id": 116914,
                            "name": "Freeport",
                            "latitude": "40.65760000",
                            "longitude": "-73.58318000"
                        },
                        {
                            "id": 116937,
                            "name": "Fresh Meadows",
                            "latitude": "40.73482000",
                            "longitude": "-73.79347000"
                        },
                        {
                            "id": 116941,
                            "name": "Frewsburg",
                            "latitude": "42.05450000",
                            "longitude": "-79.15810000"
                        },
                        {
                            "id": 116949,
                            "name": "Friendship",
                            "latitude": "42.20646000",
                            "longitude": "-78.13751000"
                        },
                        {
                            "id": 116991,
                            "name": "Fulton",
                            "latitude": "43.32285000",
                            "longitude": "-76.41716000"
                        },
                        {
                            "id": 116997,
                            "name": "Fulton County",
                            "latitude": "43.11385000",
                            "longitude": "-74.42217000"
                        },
                        {
                            "id": 117031,
                            "name": "Galeville",
                            "latitude": "43.09007000",
                            "longitude": "-76.17298000"
                        },
                        {
                            "id": 117050,
                            "name": "Gang Mills",
                            "latitude": "42.14619000",
                            "longitude": "-77.11164000"
                        },
                        {
                            "id": 117058,
                            "name": "Garden City",
                            "latitude": "40.72677000",
                            "longitude": "-73.63430000"
                        },
                        {
                            "id": 117062,
                            "name": "Garden City Park",
                            "latitude": "40.74093000",
                            "longitude": "-73.66263000"
                        },
                        {
                            "id": 117063,
                            "name": "Garden City South",
                            "latitude": "40.71232000",
                            "longitude": "-73.66096000"
                        },
                        {
                            "id": 117077,
                            "name": "Gardnertown",
                            "latitude": "41.53509000",
                            "longitude": "-74.07014000"
                        },
                        {
                            "id": 117111,
                            "name": "Gasport",
                            "latitude": "43.19922000",
                            "longitude": "-78.57614000"
                        },
                        {
                            "id": 117120,
                            "name": "Gates-North Gates",
                            "latitude": "43.16547000",
                            "longitude": "-77.70066000"
                        },
                        {
                            "id": 117136,
                            "name": "Genesee County",
                            "latitude": "43.00093000",
                            "longitude": "-78.19371000"
                        },
                        {
                            "id": 117138,
                            "name": "Geneseo",
                            "latitude": "42.79590000",
                            "longitude": "-77.81695000"
                        },
                        {
                            "id": 117144,
                            "name": "Geneva",
                            "latitude": "42.86896000",
                            "longitude": "-76.97774000"
                        },
                        {
                            "id": 117242,
                            "name": "Glasco",
                            "latitude": "42.04370000",
                            "longitude": "-73.94736000"
                        },
                        {
                            "id": 117264,
                            "name": "Glen Cove",
                            "latitude": "40.86232000",
                            "longitude": "-73.63374000"
                        },
                        {
                            "id": 117267,
                            "name": "Glen Head",
                            "latitude": "40.83538000",
                            "longitude": "-73.62374000"
                        },
                        {
                            "id": 117269,
                            "name": "Glen Oaks",
                            "latitude": "40.74705000",
                            "longitude": "-73.71152000"
                        },
                        {
                            "id": 117283,
                            "name": "Glendale",
                            "latitude": "40.70149000",
                            "longitude": "-73.88680000"
                        },
                        {
                            "id": 117304,
                            "name": "Glens Falls",
                            "latitude": "43.30952000",
                            "longitude": "-73.64401000"
                        },
                        {
                            "id": 117305,
                            "name": "Glens Falls North",
                            "latitude": "43.33506000",
                            "longitude": "-73.68251000"
                        },
                        {
                            "id": 117317,
                            "name": "Glenwood Landing",
                            "latitude": "40.83066000",
                            "longitude": "-73.63874000"
                        },
                        {
                            "id": 117328,
                            "name": "Gloversville",
                            "latitude": "43.05285000",
                            "longitude": "-74.34375000"
                        },
                        {
                            "id": 117362,
                            "name": "Goldens Bridge",
                            "latitude": "41.29343000",
                            "longitude": "-73.67680000"
                        },
                        {
                            "id": 117399,
                            "name": "Gordon Heights",
                            "latitude": "40.85871000",
                            "longitude": "-72.97066000"
                        },
                        {
                            "id": 117409,
                            "name": "Goshen",
                            "latitude": "41.40204000",
                            "longitude": "-74.32432000"
                        },
                        {
                            "id": 117419,
                            "name": "Gouverneur",
                            "latitude": "44.33673000",
                            "longitude": "-75.46299000"
                        },
                        {
                            "id": 117422,
                            "name": "Gowanda",
                            "latitude": "42.46312000",
                            "longitude": "-78.93587000"
                        },
                        {
                            "id": 117444,
                            "name": "Gramercy Park",
                            "latitude": "40.73750000",
                            "longitude": "-73.98611000"
                        },
                        {
                            "id": 117462,
                            "name": "Grand Island",
                            "latitude": "43.03311000",
                            "longitude": "-78.96254000"
                        },
                        {
                            "id": 117485,
                            "name": "Grandyle Village",
                            "latitude": "42.99645000",
                            "longitude": "-78.95504000"
                        },
                        {
                            "id": 117503,
                            "name": "Graniteville",
                            "latitude": "40.62483000",
                            "longitude": "-74.14848000"
                        },
                        {
                            "id": 117507,
                            "name": "Grant City",
                            "latitude": "40.58205000",
                            "longitude": "-74.10486000"
                        },
                        {
                            "id": 117536,
                            "name": "Granville",
                            "latitude": "43.40785000",
                            "longitude": "-73.25955000"
                        },
                        {
                            "id": 117548,
                            "name": "Gravesend",
                            "latitude": "40.59760000",
                            "longitude": "-73.96514000"
                        },
                        {
                            "id": 117574,
                            "name": "Great Kills",
                            "latitude": "40.55427000",
                            "longitude": "-74.15153000"
                        },
                        {
                            "id": 117575,
                            "name": "Great Neck",
                            "latitude": "40.80066000",
                            "longitude": "-73.72846000"
                        },
                        {
                            "id": 117576,
                            "name": "Great Neck Estates",
                            "latitude": "40.78705000",
                            "longitude": "-73.73680000"
                        },
                        {
                            "id": 117577,
                            "name": "Great Neck Gardens",
                            "latitude": "40.79722000",
                            "longitude": "-73.72389000"
                        },
                        {
                            "id": 117578,
                            "name": "Great Neck Plaza",
                            "latitude": "40.78677000",
                            "longitude": "-73.72652000"
                        },
                        {
                            "id": 117579,
                            "name": "Great River",
                            "latitude": "40.72121000",
                            "longitude": "-73.15761000"
                        },
                        {
                            "id": 117584,
                            "name": "Greece",
                            "latitude": "43.20978000",
                            "longitude": "-77.69306000"
                        },
                        {
                            "id": 117599,
                            "name": "Green Island",
                            "latitude": "42.74424000",
                            "longitude": "-73.69151000"
                        },
                        {
                            "id": 117622,
                            "name": "Greenburgh",
                            "latitude": "41.03287000",
                            "longitude": "-73.84291000"
                        },
                        {
                            "id": 117630,
                            "name": "Greene",
                            "latitude": "42.32924000",
                            "longitude": "-75.76991000"
                        },
                        {
                            "id": 117642,
                            "name": "Greene County",
                            "latitude": "42.27652000",
                            "longitude": "-74.12271000"
                        },
                        {
                            "id": 117658,
                            "name": "Greenlawn",
                            "latitude": "40.86899000",
                            "longitude": "-73.36512000"
                        },
                        {
                            "id": 117661,
                            "name": "Greenpoint",
                            "latitude": "40.72371000",
                            "longitude": "-73.95097000"
                        },
                        {
                            "id": 117662,
                            "name": "Greenport",
                            "latitude": "41.10343000",
                            "longitude": "-72.35925000"
                        },
                        {
                            "id": 117663,
                            "name": "Greenport West",
                            "latitude": "41.10178000",
                            "longitude": "-72.37195000"
                        },
                        {
                            "id": 117679,
                            "name": "Greenvale",
                            "latitude": "40.81066000",
                            "longitude": "-73.62846000"
                        },
                        {
                            "id": 117693,
                            "name": "Greenville",
                            "latitude": "40.99315000",
                            "longitude": "-73.81986000"
                        },
                        {
                            "id": 117699,
                            "name": "Greenwich",
                            "latitude": "43.09063000",
                            "longitude": "-73.49873000"
                        },
                        {
                            "id": 117711,
                            "name": "Greenwood Lake",
                            "latitude": "41.22259000",
                            "longitude": "-74.29432000"
                        },
                        {
                            "id": 117750,
                            "name": "Groton",
                            "latitude": "42.58785000",
                            "longitude": "-76.36688000"
                        },
                        {
                            "id": 117775,
                            "name": "Grymes Hill",
                            "latitude": "40.61872000",
                            "longitude": "-74.09348000"
                        },
                        {
                            "id": 117834,
                            "name": "Hadley",
                            "latitude": "43.31729000",
                            "longitude": "-73.84818000"
                        },
                        {
                            "id": 117835,
                            "name": "Hagaman",
                            "latitude": "42.97452000",
                            "longitude": "-74.15096000"
                        },
                        {
                            "id": 117852,
                            "name": "Halesite",
                            "latitude": "40.88843000",
                            "longitude": "-73.41540000"
                        },
                        {
                            "id": 117884,
                            "name": "Hamburg",
                            "latitude": "42.71589000",
                            "longitude": "-78.82948000"
                        },
                        {
                            "id": 117893,
                            "name": "Hamilton",
                            "latitude": "42.82701000",
                            "longitude": "-75.54462000"
                        },
                        {
                            "id": 117903,
                            "name": "Hamilton County",
                            "latitude": "43.66112000",
                            "longitude": "-74.49736000"
                        },
                        {
                            "id": 117909,
                            "name": "Hamlin",
                            "latitude": "43.30312000",
                            "longitude": "-77.92112000"
                        },
                        {
                            "id": 117935,
                            "name": "Hampton Bays",
                            "latitude": "40.86899000",
                            "longitude": "-72.51759000"
                        },
                        {
                            "id": 117939,
                            "name": "Hampton Manor",
                            "latitude": "42.62091000",
                            "longitude": "-73.72845000"
                        },
                        {
                            "id": 117964,
                            "name": "Hannawa Falls",
                            "latitude": "44.61228000",
                            "longitude": "-74.97103000"
                        },
                        {
                            "id": 117990,
                            "name": "Harbor Isle",
                            "latitude": "40.60344000",
                            "longitude": "-73.66457000"
                        },
                        {
                            "id": 118023,
                            "name": "Harlem",
                            "latitude": "40.80788000",
                            "longitude": "-73.94542000"
                        },
                        {
                            "id": 118040,
                            "name": "Harriman",
                            "latitude": "41.30843000",
                            "longitude": "-74.14459000"
                        },
                        {
                            "id": 118046,
                            "name": "Harris Hill",
                            "latitude": "42.96478000",
                            "longitude": "-78.67753000"
                        },
                        {
                            "id": 118059,
                            "name": "Harrison",
                            "latitude": "40.96899000",
                            "longitude": "-73.71263000"
                        },
                        {
                            "id": 118090,
                            "name": "Hartford",
                            "latitude": "43.36368000",
                            "longitude": "-73.39372000"
                        },
                        {
                            "id": 118101,
                            "name": "Hartsdale",
                            "latitude": "41.01899000",
                            "longitude": "-73.79819000"
                        },
                        {
                            "id": 118135,
                            "name": "Hastings-on-Hudson",
                            "latitude": "40.99454000",
                            "longitude": "-73.87875000"
                        },
                        {
                            "id": 118143,
                            "name": "Hauppauge",
                            "latitude": "40.82565000",
                            "longitude": "-73.20261000"
                        },
                        {
                            "id": 118152,
                            "name": "Haverstraw",
                            "latitude": "41.19759000",
                            "longitude": "-73.96458000"
                        },
                        {
                            "id": 118153,
                            "name": "Haviland",
                            "latitude": "41.76676000",
                            "longitude": "-73.90152000"
                        },
                        {
                            "id": 118174,
                            "name": "Hawthorne",
                            "latitude": "41.10732000",
                            "longitude": "-73.79597000"
                        },
                        {
                            "id": 118215,
                            "name": "Head of the Harbor",
                            "latitude": "40.90343000",
                            "longitude": "-73.15789000"
                        },
                        {
                            "id": 118258,
                            "name": "Hell's Kitchen",
                            "latitude": "40.76496000",
                            "longitude": "-73.99090000"
                        },
                        {
                            "id": 118270,
                            "name": "Hempstead",
                            "latitude": "40.70621000",
                            "longitude": "-73.61874000"
                        },
                        {
                            "id": 118318,
                            "name": "Heritage Hills",
                            "latitude": "41.33954000",
                            "longitude": "-73.69735000"
                        },
                        {
                            "id": 118323,
                            "name": "Herkimer",
                            "latitude": "43.02563000",
                            "longitude": "-74.98599000"
                        },
                        {
                            "id": 118324,
                            "name": "Herkimer County",
                            "latitude": "43.41970000",
                            "longitude": "-74.96250000"
                        },
                        {
                            "id": 118337,
                            "name": "Herricks",
                            "latitude": "40.75538000",
                            "longitude": "-73.66680000"
                        },
                        {
                            "id": 118349,
                            "name": "Hewlett",
                            "latitude": "40.64316000",
                            "longitude": "-73.69569000"
                        },
                        {
                            "id": 118350,
                            "name": "Hewlett Harbor",
                            "latitude": "40.63621000",
                            "longitude": "-73.68152000"
                        },
                        {
                            "id": 118371,
                            "name": "Hicksville",
                            "latitude": "40.76843000",
                            "longitude": "-73.52513000"
                        },
                        {
                            "id": 118397,
                            "name": "Highland",
                            "latitude": "41.72093000",
                            "longitude": "-73.96014000"
                        },
                        {
                            "id": 118405,
                            "name": "Highland Falls",
                            "latitude": "41.36926000",
                            "longitude": "-73.96625000"
                        },
                        {
                            "id": 118409,
                            "name": "Highland Mills",
                            "latitude": "41.34704000",
                            "longitude": "-74.12626000"
                        },
                        {
                            "id": 118440,
                            "name": "Hillcrest",
                            "latitude": "41.12787000",
                            "longitude": "-74.04097000"
                        },
                        {
                            "id": 118465,
                            "name": "Hillside",
                            "latitude": "40.70788000",
                            "longitude": "-73.78680000"
                        },
                        {
                            "id": 118466,
                            "name": "Hillside Lake",
                            "latitude": "41.61482000",
                            "longitude": "-73.79819000"
                        },
                        {
                            "id": 118473,
                            "name": "Hilton",
                            "latitude": "43.28812000",
                            "longitude": "-77.79334000"
                        },
                        {
                            "id": 118518,
                            "name": "Holbrook",
                            "latitude": "40.81232000",
                            "longitude": "-73.07844000"
                        },
                        {
                            "id": 118520,
                            "name": "Holcomb",
                            "latitude": "42.90229000",
                            "longitude": "-77.41971000"
                        },
                        {
                            "id": 118540,
                            "name": "Holland",
                            "latitude": "42.64117000",
                            "longitude": "-78.54169000"
                        },
                        {
                            "id": 118543,
                            "name": "Holley",
                            "latitude": "43.22645000",
                            "longitude": "-78.02668000"
                        },
                        {
                            "id": 118549,
                            "name": "Hollis",
                            "latitude": "40.71344000",
                            "longitude": "-73.76708000"
                        },
                        {
                            "id": 118580,
                            "name": "Holtsville",
                            "latitude": "40.81538000",
                            "longitude": "-73.04511000"
                        },
                        {
                            "id": 118596,
                            "name": "Homer",
                            "latitude": "42.63701000",
                            "longitude": "-76.17882000"
                        },
                        {
                            "id": 118617,
                            "name": "Honeoye Falls",
                            "latitude": "42.95229000",
                            "longitude": "-77.59028000"
                        },
                        {
                            "id": 118637,
                            "name": "Hoosick Falls",
                            "latitude": "42.90119000",
                            "longitude": "-73.35150000"
                        },
                        {
                            "id": 118667,
                            "name": "Hornell",
                            "latitude": "42.32785000",
                            "longitude": "-77.66110000"
                        },
                        {
                            "id": 118673,
                            "name": "Horseheads",
                            "latitude": "42.16702000",
                            "longitude": "-76.82051000"
                        },
                        {
                            "id": 118674,
                            "name": "Horseheads North",
                            "latitude": "42.19278000",
                            "longitude": "-76.80782000"
                        },
                        {
                            "id": 118690,
                            "name": "Houghton",
                            "latitude": "42.42340000",
                            "longitude": "-78.15723000"
                        },
                        {
                            "id": 118710,
                            "name": "Howard Beach",
                            "latitude": "40.65788000",
                            "longitude": "-73.83625000"
                        },
                        {
                            "id": 118744,
                            "name": "Hudson",
                            "latitude": "42.25286000",
                            "longitude": "-73.79096000"
                        },
                        {
                            "id": 118750,
                            "name": "Hudson Falls",
                            "latitude": "43.30063000",
                            "longitude": "-73.58595000"
                        },
                        {
                            "id": 118769,
                            "name": "Huguenot",
                            "latitude": "40.53733000",
                            "longitude": "-74.19459000"
                        },
                        {
                            "id": 118802,
                            "name": "Huntington",
                            "latitude": "40.86815000",
                            "longitude": "-73.42568000"
                        },
                        {
                            "id": 118804,
                            "name": "Huntington Bay",
                            "latitude": "40.89982000",
                            "longitude": "-73.41484000"
                        },
                        {
                            "id": 118808,
                            "name": "Huntington Station",
                            "latitude": "40.85343000",
                            "longitude": "-73.41151000"
                        },
                        {
                            "id": 118813,
                            "name": "Hunts Point",
                            "latitude": "40.81260000",
                            "longitude": "-73.88402000"
                        },
                        {
                            "id": 118820,
                            "name": "Hurley",
                            "latitude": "41.92454000",
                            "longitude": "-74.06125000"
                        },
                        {
                            "id": 118850,
                            "name": "Hyde Park",
                            "latitude": "41.78482000",
                            "longitude": "-73.93319000"
                        },
                        {
                            "id": 118878,
                            "name": "Ilion",
                            "latitude": "43.01507000",
                            "longitude": "-75.03543000"
                        },
                        {
                            "id": 118963,
                            "name": "Inwood",
                            "latitude": "40.86566000",
                            "longitude": "-73.92680000"
                        },
                        {
                            "id": 118996,
                            "name": "Irondequoit",
                            "latitude": "43.21340000",
                            "longitude": "-77.57972000"
                        },
                        {
                            "id": 119010,
                            "name": "Irvington",
                            "latitude": "41.03922000",
                            "longitude": "-73.86823000"
                        },
                        {
                            "id": 119027,
                            "name": "Island Park",
                            "latitude": "40.60427000",
                            "longitude": "-73.65541000"
                        },
                        {
                            "id": 119029,
                            "name": "Islandia",
                            "latitude": "40.80426000",
                            "longitude": "-73.16900000"
                        },
                        {
                            "id": 119034,
                            "name": "Islip",
                            "latitude": "40.72982000",
                            "longitude": "-73.21039000"
                        },
                        {
                            "id": 119035,
                            "name": "Islip Terrace",
                            "latitude": "40.74315000",
                            "longitude": "-73.19262000"
                        },
                        {
                            "id": 119044,
                            "name": "Ithaca",
                            "latitude": "42.44063000",
                            "longitude": "-76.49661000"
                        },
                        {
                            "id": 119097,
                            "name": "Jackson Heights",
                            "latitude": "40.75566000",
                            "longitude": "-73.88541000"
                        },
                        {
                            "id": 119111,
                            "name": "Jamaica",
                            "latitude": "40.69149000",
                            "longitude": "-73.80569000"
                        },
                        {
                            "id": 119119,
                            "name": "Jamesport",
                            "latitude": "40.94954000",
                            "longitude": "-72.58148000"
                        },
                        {
                            "id": 119124,
                            "name": "Jamestown",
                            "latitude": "42.09700000",
                            "longitude": "-79.23533000"
                        },
                        {
                            "id": 119127,
                            "name": "Jamestown West",
                            "latitude": "42.08851000",
                            "longitude": "-79.28110000"
                        },
                        {
                            "id": 119191,
                            "name": "Jefferson County",
                            "latitude": "43.99885000",
                            "longitude": "-76.05211000"
                        },
                        {
                            "id": 119201,
                            "name": "Jefferson Heights",
                            "latitude": "42.23398000",
                            "longitude": "-73.88235000"
                        },
                        {
                            "id": 119204,
                            "name": "Jefferson Valley-Yorktown",
                            "latitude": "41.31797000",
                            "longitude": "-73.80066000"
                        },
                        {
                            "id": 119224,
                            "name": "Jericho",
                            "latitude": "40.79204000",
                            "longitude": "-73.53985000"
                        },
                        {
                            "id": 119259,
                            "name": "Johnson City",
                            "latitude": "42.11563000",
                            "longitude": "-75.95881000"
                        },
                        {
                            "id": 119284,
                            "name": "Johnstown",
                            "latitude": "43.00674000",
                            "longitude": "-74.36764000"
                        },
                        {
                            "id": 119316,
                            "name": "Jordan",
                            "latitude": "43.06534000",
                            "longitude": "-76.47299000"
                        },
                        {
                            "id": 119391,
                            "name": "Kaser",
                            "latitude": "41.12121000",
                            "longitude": "-74.06709000"
                        },
                        {
                            "id": 119394,
                            "name": "Katonah",
                            "latitude": "41.25898000",
                            "longitude": "-73.68541000"
                        },
                        {
                            "id": 119423,
                            "name": "Keeseville",
                            "latitude": "44.50505000",
                            "longitude": "-73.48013000"
                        },
                        {
                            "id": 119462,
                            "name": "Kenmore",
                            "latitude": "42.96589000",
                            "longitude": "-78.87004000"
                        },
                        {
                            "id": 119483,
                            "name": "Kensington",
                            "latitude": "40.79343000",
                            "longitude": "-73.72208000"
                        },
                        {
                            "id": 119511,
                            "name": "Kerhonkson",
                            "latitude": "41.77482000",
                            "longitude": "-74.29821000"
                        },
                        {
                            "id": 119529,
                            "name": "Keuka Park",
                            "latitude": "42.61535000",
                            "longitude": "-77.09219000"
                        },
                        {
                            "id": 119530,
                            "name": "Kew Gardens",
                            "latitude": "40.71427000",
                            "longitude": "-73.83097000"
                        },
                        {
                            "id": 119531,
                            "name": "Kew Gardens Hills",
                            "latitude": "40.73002000",
                            "longitude": "-73.82340000"
                        },
                        {
                            "id": 119550,
                            "name": "Kiantone",
                            "latitude": "42.02200000",
                            "longitude": "-79.19810000"
                        },
                        {
                            "id": 119575,
                            "name": "Kinderhook",
                            "latitude": "42.39536000",
                            "longitude": "-73.69790000"
                        },
                        {
                            "id": 119599,
                            "name": "Kings Bridge",
                            "latitude": "40.87871000",
                            "longitude": "-73.90514000"
                        },
                        {
                            "id": 119601,
                            "name": "Kings County",
                            "latitude": "40.63439000",
                            "longitude": "-73.95027000"
                        },
                        {
                            "id": 119605,
                            "name": "Kings Park",
                            "latitude": "40.88621000",
                            "longitude": "-73.25734000"
                        },
                        {
                            "id": 119608,
                            "name": "Kings Point",
                            "latitude": "40.81982000",
                            "longitude": "-73.73513000"
                        },
                        {
                            "id": 119627,
                            "name": "Kingston",
                            "latitude": "41.92704000",
                            "longitude": "-73.99736000"
                        },
                        {
                            "id": 119656,
                            "name": "Kiryas Joel",
                            "latitude": "41.34204000",
                            "longitude": "-74.16792000"
                        },
                        {
                            "id": 119813,
                            "name": "Lackawanna",
                            "latitude": "42.82561000",
                            "longitude": "-78.82337000"
                        },
                        {
                            "id": 119875,
                            "name": "Lake Carmel",
                            "latitude": "41.46148000",
                            "longitude": "-73.67096000"
                        },
                        {
                            "id": 119907,
                            "name": "Lake Erie Beach",
                            "latitude": "42.62423000",
                            "longitude": "-79.06698000"
                        },
                        {
                            "id": 119914,
                            "name": "Lake Grove",
                            "latitude": "40.85288000",
                            "longitude": "-73.11511000"
                        },
                        {
                            "id": 119926,
                            "name": "Lake Katrine",
                            "latitude": "41.98565000",
                            "longitude": "-73.98819000"
                        },
                        {
                            "id": 119935,
                            "name": "Lake Luzerne",
                            "latitude": "43.31285000",
                            "longitude": "-73.83484000"
                        },
                        {
                            "id": 119945,
                            "name": "Lake Mohegan",
                            "latitude": "41.31787000",
                            "longitude": "-73.84625000"
                        },
                        {
                            "id": 119963,
                            "name": "Lake Placid",
                            "latitude": "44.27962000",
                            "longitude": "-73.98198000"
                        },
                        {
                            "id": 119964,
                            "name": "Lake Pleasant",
                            "latitude": "43.47090000",
                            "longitude": "-74.41265000"
                        },
                        {
                            "id": 119970,
                            "name": "Lake Ronkonkoma",
                            "latitude": "40.83510000",
                            "longitude": "-73.13122000"
                        },
                        {
                            "id": 119982,
                            "name": "Lake Success",
                            "latitude": "40.77066000",
                            "longitude": "-73.71763000"
                        },
                        {
                            "id": 120016,
                            "name": "Lakeland",
                            "latitude": "43.09034000",
                            "longitude": "-76.24048000"
                        },
                        {
                            "id": 120039,
                            "name": "Lakeview",
                            "latitude": "40.68538000",
                            "longitude": "-73.65263000"
                        },
                        {
                            "id": 120049,
                            "name": "Lakewood",
                            "latitude": "42.10422000",
                            "longitude": "-79.33310000"
                        },
                        {
                            "id": 120086,
                            "name": "Lancaster",
                            "latitude": "42.90061000",
                            "longitude": "-78.67031000"
                        },
                        {
                            "id": 120128,
                            "name": "Lansing",
                            "latitude": "42.48424000",
                            "longitude": "-76.47994000"
                        },
                        {
                            "id": 120141,
                            "name": "Larchmont",
                            "latitude": "40.92788000",
                            "longitude": "-73.75180000"
                        },
                        {
                            "id": 120168,
                            "name": "Latham",
                            "latitude": "42.74702000",
                            "longitude": "-73.75901000"
                        },
                        {
                            "id": 120177,
                            "name": "Lattingtown",
                            "latitude": "40.89538000",
                            "longitude": "-73.60096000"
                        },
                        {
                            "id": 120192,
                            "name": "Laurel",
                            "latitude": "40.96954000",
                            "longitude": "-72.56203000"
                        },
                        {
                            "id": 120198,
                            "name": "Laurel Hollow",
                            "latitude": "40.85677000",
                            "longitude": "-73.46957000"
                        },
                        {
                            "id": 120204,
                            "name": "Laurelton",
                            "latitude": "40.67019000",
                            "longitude": "-73.74659000"
                        },
                        {
                            "id": 120228,
                            "name": "Lawrence",
                            "latitude": "40.61566000",
                            "longitude": "-73.72958000"
                        },
                        {
                            "id": 120263,
                            "name": "Le Roy",
                            "latitude": "42.97839000",
                            "longitude": "-77.98418000"
                        },
                        {
                            "id": 120407,
                            "name": "Levittown",
                            "latitude": "40.72593000",
                            "longitude": "-73.51429000"
                        },
                        {
                            "id": 120415,
                            "name": "Lewis County",
                            "latitude": "43.78469000",
                            "longitude": "-75.44879000"
                        },
                        {
                            "id": 120426,
                            "name": "Lewiston",
                            "latitude": "43.17256000",
                            "longitude": "-79.03588000"
                        },
                        {
                            "id": 120466,
                            "name": "Liberty",
                            "latitude": "41.80120000",
                            "longitude": "-74.74655000"
                        },
                        {
                            "id": 120478,
                            "name": "Lido Beach",
                            "latitude": "40.58899000",
                            "longitude": "-73.62541000"
                        },
                        {
                            "id": 120489,
                            "name": "Lima",
                            "latitude": "42.90479000",
                            "longitude": "-77.61139000"
                        },
                        {
                            "id": 120543,
                            "name": "Lincoln Park",
                            "latitude": "41.95065000",
                            "longitude": "-73.99403000"
                        },
                        {
                            "id": 120548,
                            "name": "Lincolndale",
                            "latitude": "41.32287000",
                            "longitude": "-73.71819000"
                        },
                        {
                            "id": 120568,
                            "name": "Lindenhurst",
                            "latitude": "40.68677000",
                            "longitude": "-73.37345000"
                        },
                        {
                            "id": 120570,
                            "name": "Lindley",
                            "latitude": "42.02841000",
                            "longitude": "-77.13969000"
                        },
                        {
                            "id": 120619,
                            "name": "Little Falls",
                            "latitude": "43.04340000",
                            "longitude": "-74.85960000"
                        },
                        {
                            "id": 120622,
                            "name": "Little Neck",
                            "latitude": "40.76289000",
                            "longitude": "-73.73225000"
                        },
                        {
                            "id": 120631,
                            "name": "Little Valley",
                            "latitude": "42.25256000",
                            "longitude": "-78.80559000"
                        },
                        {
                            "id": 120646,
                            "name": "Liverpool",
                            "latitude": "43.10646000",
                            "longitude": "-76.21770000"
                        },
                        {
                            "id": 120658,
                            "name": "Livingston County",
                            "latitude": "42.72808000",
                            "longitude": "-77.77549000"
                        },
                        {
                            "id": 120659,
                            "name": "Livingston Manor",
                            "latitude": "41.90037000",
                            "longitude": "-74.82822000"
                        },
                        {
                            "id": 120663,
                            "name": "Livonia",
                            "latitude": "42.82145000",
                            "longitude": "-77.66861000"
                        },
                        {
                            "id": 120667,
                            "name": "Lloyd Harbor",
                            "latitude": "40.90343000",
                            "longitude": "-73.45984000"
                        },
                        {
                            "id": 120681,
                            "name": "Lockport",
                            "latitude": "43.17061000",
                            "longitude": "-78.69031000"
                        },
                        {
                            "id": 120688,
                            "name": "Locust Valley",
                            "latitude": "40.87593000",
                            "longitude": "-73.59707000"
                        },
                        {
                            "id": 120737,
                            "name": "Long Beach",
                            "latitude": "40.58844000",
                            "longitude": "-73.65791000"
                        },
                        {
                            "id": 120745,
                            "name": "Long Island City",
                            "latitude": "40.74482000",
                            "longitude": "-73.94875000"
                        },
                        {
                            "id": 120772,
                            "name": "Lorenz Park",
                            "latitude": "42.26370000",
                            "longitude": "-73.76846000"
                        },
                        {
                            "id": 120850,
                            "name": "Lowville",
                            "latitude": "43.78674000",
                            "longitude": "-75.49185000"
                        },
                        {
                            "id": 120913,
                            "name": "Lynbrook",
                            "latitude": "40.65483000",
                            "longitude": "-73.67180000"
                        },
                        {
                            "id": 120917,
                            "name": "Lyncourt",
                            "latitude": "43.08146000",
                            "longitude": "-76.12576000"
                        },
                        {
                            "id": 120943,
                            "name": "Lyons",
                            "latitude": "43.06423000",
                            "longitude": "-76.99025000"
                        },
                        {
                            "id": 120954,
                            "name": "Macedon",
                            "latitude": "43.06923000",
                            "longitude": "-77.29887000"
                        },
                        {
                            "id": 121017,
                            "name": "Madison County",
                            "latitude": "42.91277000",
                            "longitude": "-75.66967000"
                        },
                        {
                            "id": 121046,
                            "name": "Mahopac",
                            "latitude": "41.37232000",
                            "longitude": "-73.73346000"
                        },
                        {
                            "id": 121066,
                            "name": "Malone",
                            "latitude": "44.84866000",
                            "longitude": "-74.29490000"
                        },
                        {
                            "id": 121074,
                            "name": "Malverne",
                            "latitude": "40.67899000",
                            "longitude": "-73.67402000"
                        },
                        {
                            "id": 121075,
                            "name": "Mamaroneck",
                            "latitude": "40.94871000",
                            "longitude": "-73.73263000"
                        },
                        {
                            "id": 121099,
                            "name": "Manchester",
                            "latitude": "42.96979000",
                            "longitude": "-77.23026000"
                        },
                        {
                            "id": 121111,
                            "name": "Manhasset",
                            "latitude": "40.79788000",
                            "longitude": "-73.69957000"
                        },
                        {
                            "id": 121112,
                            "name": "Manhasset Hills",
                            "latitude": "40.75927000",
                            "longitude": "-73.67985000"
                        },
                        {
                            "id": 121115,
                            "name": "Manhattan",
                            "latitude": "40.78343000",
                            "longitude": "-73.96625000"
                        },
                        {
                            "id": 121131,
                            "name": "Manlius",
                            "latitude": "43.00201000",
                            "longitude": "-75.97686000"
                        },
                        {
                            "id": 121140,
                            "name": "Manorhaven",
                            "latitude": "40.84316000",
                            "longitude": "-73.71485000"
                        },
                        {
                            "id": 121141,
                            "name": "Manorville",
                            "latitude": "40.87371000",
                            "longitude": "-72.80788000"
                        },
                        {
                            "id": 121191,
                            "name": "Marbletown",
                            "latitude": "41.88343000",
                            "longitude": "-74.11320000"
                        },
                        {
                            "id": 121195,
                            "name": "Marcellus",
                            "latitude": "42.98284000",
                            "longitude": "-76.34049000"
                        },
                        {
                            "id": 121227,
                            "name": "Mariners Harbor",
                            "latitude": "40.63677000",
                            "longitude": "-74.15875000"
                        },
                        {
                            "id": 121244,
                            "name": "Marion",
                            "latitude": "43.14340000",
                            "longitude": "-77.18915000"
                        },
                        {
                            "id": 121275,
                            "name": "Marlboro",
                            "latitude": "41.60565000",
                            "longitude": "-73.97153000"
                        },
                        {
                            "id": 121386,
                            "name": "Maspeth",
                            "latitude": "40.72316000",
                            "longitude": "-73.91264000"
                        },
                        {
                            "id": 121391,
                            "name": "Massapequa",
                            "latitude": "40.68066000",
                            "longitude": "-73.47429000"
                        },
                        {
                            "id": 121392,
                            "name": "Massapequa Park",
                            "latitude": "40.68038000",
                            "longitude": "-73.45512000"
                        },
                        {
                            "id": 121393,
                            "name": "Massena",
                            "latitude": "44.92810000",
                            "longitude": "-74.89186000"
                        },
                        {
                            "id": 121394,
                            "name": "Mastic",
                            "latitude": "40.80204000",
                            "longitude": "-72.84094000"
                        },
                        {
                            "id": 121395,
                            "name": "Mastic Beach",
                            "latitude": "40.76677000",
                            "longitude": "-72.85205000"
                        },
                        {
                            "id": 121413,
                            "name": "Mattituck",
                            "latitude": "40.99121000",
                            "longitude": "-72.53425000"
                        },
                        {
                            "id": 121415,
                            "name": "Mattydale",
                            "latitude": "43.09784000",
                            "longitude": "-76.14520000"
                        },
                        {
                            "id": 121431,
                            "name": "Maybrook",
                            "latitude": "41.48398000",
                            "longitude": "-74.21765000"
                        },
                        {
                            "id": 121455,
                            "name": "Mayville",
                            "latitude": "42.25395000",
                            "longitude": "-79.50449000"
                        },
                        {
                            "id": 121504,
                            "name": "McGraw",
                            "latitude": "42.59618000",
                            "longitude": "-76.09326000"
                        },
                        {
                            "id": 121526,
                            "name": "McKownville",
                            "latitude": "42.68397000",
                            "longitude": "-73.84762000"
                        },
                        {
                            "id": 121581,
                            "name": "Mechanicstown",
                            "latitude": "41.44287000",
                            "longitude": "-74.38849000"
                        },
                        {
                            "id": 121586,
                            "name": "Mechanicville",
                            "latitude": "42.90285000",
                            "longitude": "-73.68734000"
                        },
                        {
                            "id": 121594,
                            "name": "Medford",
                            "latitude": "40.81760000",
                            "longitude": "-73.00011000"
                        },
                        {
                            "id": 121605,
                            "name": "Medina",
                            "latitude": "43.22006000",
                            "longitude": "-78.38697000"
                        },
                        {
                            "id": 121630,
                            "name": "Melrose",
                            "latitude": "40.82455000",
                            "longitude": "-73.91041000"
                        },
                        {
                            "id": 121633,
                            "name": "Melrose Park",
                            "latitude": "42.90868000",
                            "longitude": "-76.54022000"
                        },
                        {
                            "id": 121635,
                            "name": "Melville",
                            "latitude": "40.79343000",
                            "longitude": "-73.41512000"
                        },
                        {
                            "id": 121645,
                            "name": "Menands",
                            "latitude": "42.69202000",
                            "longitude": "-73.72456000"
                        },
                        {
                            "id": 121704,
                            "name": "Merrick",
                            "latitude": "40.66288000",
                            "longitude": "-73.55152000"
                        },
                        {
                            "id": 121715,
                            "name": "Merritt Park",
                            "latitude": "41.53848000",
                            "longitude": "-73.87238000"
                        },
                        {
                            "id": 121743,
                            "name": "Mexico",
                            "latitude": "43.45951000",
                            "longitude": "-76.22882000"
                        },
                        {
                            "id": 121761,
                            "name": "Middle Island",
                            "latitude": "40.88427000",
                            "longitude": "-72.93733000"
                        },
                        {
                            "id": 121764,
                            "name": "Middle Village",
                            "latitude": "40.71649000",
                            "longitude": "-73.88125000"
                        },
                        {
                            "id": 121770,
                            "name": "Middleburgh",
                            "latitude": "42.59869000",
                            "longitude": "-74.33292000"
                        },
                        {
                            "id": 121775,
                            "name": "Middleport",
                            "latitude": "43.21256000",
                            "longitude": "-78.47641000"
                        },
                        {
                            "id": 121791,
                            "name": "Middletown",
                            "latitude": "41.44593000",
                            "longitude": "-74.42293000"
                        },
                        {
                            "id": 121802,
                            "name": "Midland Beach",
                            "latitude": "40.57316000",
                            "longitude": "-74.09459000"
                        },
                        {
                            "id": 121866,
                            "name": "Mill Neck",
                            "latitude": "40.88704000",
                            "longitude": "-73.55512000"
                        },
                        {
                            "id": 121873,
                            "name": "Millbrook",
                            "latitude": "41.78509000",
                            "longitude": "-73.69402000"
                        },
                        {
                            "id": 121883,
                            "name": "Miller Place",
                            "latitude": "40.95982000",
                            "longitude": "-72.99621000"
                        },
                        {
                            "id": 121916,
                            "name": "Milton",
                            "latitude": "43.03369000",
                            "longitude": "-73.85262000"
                        },
                        {
                            "id": 121932,
                            "name": "Mineola",
                            "latitude": "40.74927000",
                            "longitude": "-73.64068000"
                        },
                        {
                            "id": 121944,
                            "name": "Minetto",
                            "latitude": "43.39812000",
                            "longitude": "-76.47744000"
                        },
                        {
                            "id": 121945,
                            "name": "Mineville",
                            "latitude": "44.09283000",
                            "longitude": "-73.51818000"
                        },
                        {
                            "id": 121960,
                            "name": "Minoa",
                            "latitude": "43.07618000",
                            "longitude": "-76.00075000"
                        },
                        {
                            "id": 122021,
                            "name": "Mohawk",
                            "latitude": "43.01146000",
                            "longitude": "-75.00404000"
                        },
                        {
                            "id": 122062,
                            "name": "Monroe",
                            "latitude": "41.33065000",
                            "longitude": "-74.18681000"
                        },
                        {
                            "id": 122080,
                            "name": "Monroe County",
                            "latitude": "43.16512000",
                            "longitude": "-77.63626000"
                        },
                        {
                            "id": 122089,
                            "name": "Monsey",
                            "latitude": "41.11121000",
                            "longitude": "-74.06848000"
                        },
                        {
                            "id": 122103,
                            "name": "Montauk",
                            "latitude": "41.03594000",
                            "longitude": "-71.95451000"
                        },
                        {
                            "id": 122113,
                            "name": "Montebello",
                            "latitude": "41.13593000",
                            "longitude": "-74.11848000"
                        },
                        {
                            "id": 122135,
                            "name": "Montgomery",
                            "latitude": "41.52759000",
                            "longitude": "-74.23682000"
                        },
                        {
                            "id": 122154,
                            "name": "Montgomery County",
                            "latitude": "42.90228000",
                            "longitude": "-74.43968000"
                        },
                        {
                            "id": 122169,
                            "name": "Monticello",
                            "latitude": "41.65565000",
                            "longitude": "-74.68933000"
                        },
                        {
                            "id": 122174,
                            "name": "Montour Falls",
                            "latitude": "42.34730000",
                            "longitude": "-76.84524000"
                        },
                        {
                            "id": 122182,
                            "name": "Montrose",
                            "latitude": "41.25232000",
                            "longitude": "-73.93153000"
                        },
                        {
                            "id": 122223,
                            "name": "Moravia",
                            "latitude": "42.71257000",
                            "longitude": "-76.42160000"
                        },
                        {
                            "id": 122256,
                            "name": "Moriches",
                            "latitude": "40.80732000",
                            "longitude": "-72.82121000"
                        },
                        {
                            "id": 122258,
                            "name": "Morningside Heights",
                            "latitude": "40.81000000",
                            "longitude": "-73.96250000"
                        },
                        {
                            "id": 122272,
                            "name": "Morris Heights",
                            "latitude": "40.84982000",
                            "longitude": "-73.91986000"
                        },
                        {
                            "id": 122273,
                            "name": "Morris Park",
                            "latitude": "40.85232000",
                            "longitude": "-73.85347000"
                        },
                        {
                            "id": 122275,
                            "name": "Morrisania",
                            "latitude": "40.82927000",
                            "longitude": "-73.90653000"
                        },
                        {
                            "id": 122279,
                            "name": "Morrisonville",
                            "latitude": "44.69310000",
                            "longitude": "-73.56208000"
                        },
                        {
                            "id": 122285,
                            "name": "Morrisville",
                            "latitude": "42.89868000",
                            "longitude": "-75.64018000"
                        },
                        {
                            "id": 122313,
                            "name": "Mott Haven",
                            "latitude": "40.80899000",
                            "longitude": "-73.92291000"
                        },
                        {
                            "id": 122356,
                            "name": "Mount Ivy",
                            "latitude": "41.18676000",
                            "longitude": "-74.03486000"
                        },
                        {
                            "id": 122360,
                            "name": "Mount Kisco",
                            "latitude": "41.20426000",
                            "longitude": "-73.72708000"
                        },
                        {
                            "id": 122365,
                            "name": "Mount Morris",
                            "latitude": "42.72562000",
                            "longitude": "-77.87417000"
                        },
                        {
                            "id": 122389,
                            "name": "Mount Sinai",
                            "latitude": "40.94704000",
                            "longitude": "-73.02955000"
                        },
                        {
                            "id": 122402,
                            "name": "Mount Vernon",
                            "latitude": "40.91260000",
                            "longitude": "-73.83708000"
                        },
                        {
                            "id": 122423,
                            "name": "Mountain Lodge Park",
                            "latitude": "41.38843000",
                            "longitude": "-74.14181000"
                        },
                        {
                            "id": 122476,
                            "name": "Munsey Park",
                            "latitude": "40.79871000",
                            "longitude": "-73.67985000"
                        },
                        {
                            "id": 122477,
                            "name": "Munsons Corners",
                            "latitude": "42.58229000",
                            "longitude": "-76.20910000"
                        },
                        {
                            "id": 122516,
                            "name": "Muttontown",
                            "latitude": "40.82399000",
                            "longitude": "-73.54763000"
                        },
                        {
                            "id": 122517,
                            "name": "Myers Corner",
                            "latitude": "41.60620000",
                            "longitude": "-73.87291000"
                        },
                        {
                            "id": 122545,
                            "name": "Nanuet",
                            "latitude": "41.08871000",
                            "longitude": "-74.01347000"
                        },
                        {
                            "id": 122548,
                            "name": "Napanoch",
                            "latitude": "41.74398000",
                            "longitude": "-74.37154000"
                        },
                        {
                            "id": 122554,
                            "name": "Naples",
                            "latitude": "42.61535000",
                            "longitude": "-77.40249000"
                        },
                        {
                            "id": 122580,
                            "name": "Nassau",
                            "latitude": "42.51591000",
                            "longitude": "-73.61012000"
                        },
                        {
                            "id": 122583,
                            "name": "Nassau County",
                            "latitude": "40.73217000",
                            "longitude": "-73.58545000"
                        },
                        {
                            "id": 122612,
                            "name": "Nedrow",
                            "latitude": "42.97507000",
                            "longitude": "-76.14131000"
                        },
                        {
                            "id": 122636,
                            "name": "Neponsit",
                            "latitude": "40.57177000",
                            "longitude": "-73.86152000"
                        },
                        {
                            "id": 122639,
                            "name": "Nesconset",
                            "latitude": "40.85204000",
                            "longitude": "-73.15400000"
                        },
                        {
                            "id": 122672,
                            "name": "New Brighton",
                            "latitude": "40.64233000",
                            "longitude": "-74.09292000"
                        },
                        {
                            "id": 122682,
                            "name": "New Cassel",
                            "latitude": "40.75899000",
                            "longitude": "-73.56957000"
                        },
                        {
                            "id": 122695,
                            "name": "New City",
                            "latitude": "41.14760000",
                            "longitude": "-73.98931000"
                        },
                        {
                            "id": 122699,
                            "name": "New Dorp",
                            "latitude": "40.57399000",
                            "longitude": "-74.11598000"
                        },
                        {
                            "id": 122700,
                            "name": "New Dorp Beach",
                            "latitude": "40.56538000",
                            "longitude": "-74.10292000"
                        },
                        {
                            "id": 122712,
                            "name": "New Hartford",
                            "latitude": "43.07340000",
                            "longitude": "-75.28767000"
                        },
                        {
                            "id": 122720,
                            "name": "New Hempstead",
                            "latitude": "41.14982000",
                            "longitude": "-74.03375000"
                        },
                        {
                            "id": 122729,
                            "name": "New Hyde Park",
                            "latitude": "40.73510000",
                            "longitude": "-73.68791000"
                        },
                        {
                            "id": 122759,
                            "name": "New Paltz",
                            "latitude": "41.74759000",
                            "longitude": "-74.08681000"
                        },
                        {
                            "id": 122773,
                            "name": "New Rochelle",
                            "latitude": "40.91149000",
                            "longitude": "-73.78235000"
                        },
                        {
                            "id": 122781,
                            "name": "New Springville",
                            "latitude": "40.59344000",
                            "longitude": "-74.16320000"
                        },
                        {
                            "id": 122782,
                            "name": "New Square",
                            "latitude": "41.13956000",
                            "longitude": "-74.02942000"
                        },
                        {
                            "id": 122794,
                            "name": "New Windsor",
                            "latitude": "41.47676000",
                            "longitude": "-74.02375000"
                        },
                        {
                            "id": 122795,
                            "name": "New York City",
                            "latitude": "40.71427000",
                            "longitude": "-74.00597000"
                        },
                        {
                            "id": 122796,
                            "name": "New York County",
                            "latitude": "40.77427000",
                            "longitude": "-73.96981000"
                        },
                        {
                            "id": 122798,
                            "name": "New York Mills",
                            "latitude": "43.10535000",
                            "longitude": "-75.29128000"
                        },
                        {
                            "id": 122804,
                            "name": "Newark",
                            "latitude": "43.04673000",
                            "longitude": "-77.09525000"
                        },
                        {
                            "id": 122817,
                            "name": "Newburgh",
                            "latitude": "41.50343000",
                            "longitude": "-74.01042000"
                        },
                        {
                            "id": 122826,
                            "name": "Newfane",
                            "latitude": "43.28672000",
                            "longitude": "-78.71031000"
                        },
                        {
                            "id": 122881,
                            "name": "Niagara County",
                            "latitude": "43.17314000",
                            "longitude": "-78.69095000"
                        },
                        {
                            "id": 122882,
                            "name": "Niagara Falls",
                            "latitude": "43.09450000",
                            "longitude": "-79.05671000"
                        },
                        {
                            "id": 122906,
                            "name": "Niskayuna",
                            "latitude": "42.77980000",
                            "longitude": "-73.84568000"
                        },
                        {
                            "id": 122907,
                            "name": "Nissequogue",
                            "latitude": "40.90399000",
                            "longitude": "-73.19789000"
                        },
                        {
                            "id": 122910,
                            "name": "Niverville",
                            "latitude": "42.44092000",
                            "longitude": "-73.66095000"
                        },
                        {
                            "id": 122944,
                            "name": "Norfolk",
                            "latitude": "44.80089000",
                            "longitude": "-74.99103000"
                        },
                        {
                            "id": 122961,
                            "name": "North Amityville",
                            "latitude": "40.69760000",
                            "longitude": "-73.42512000"
                        },
                        {
                            "id": 122971,
                            "name": "North Babylon",
                            "latitude": "40.71649000",
                            "longitude": "-73.32179000"
                        },
                        {
                            "id": 122972,
                            "name": "North Ballston Spa",
                            "latitude": "43.01969000",
                            "longitude": "-73.85109000"
                        },
                        {
                            "id": 122975,
                            "name": "North Bay Shore",
                            "latitude": "40.73621000",
                            "longitude": "-73.26262000"
                        },
                        {
                            "id": 122981,
                            "name": "North Bellmore",
                            "latitude": "40.69149000",
                            "longitude": "-73.53346000"
                        },
                        {
                            "id": 122982,
                            "name": "North Bellport",
                            "latitude": "40.77427000",
                            "longitude": "-72.94288000"
                        },
                        {
                            "id": 122991,
                            "name": "North Boston",
                            "latitude": "42.68562000",
                            "longitude": "-78.77670000"
                        },
                        {
                            "id": 123001,
                            "name": "North Castle",
                            "latitude": "41.14000000",
                            "longitude": "-73.68389000"
                        },
                        {
                            "id": 123008,
                            "name": "North Collins",
                            "latitude": "42.59534000",
                            "longitude": "-78.94115000"
                        },
                        {
                            "id": 123022,
                            "name": "North Elba",
                            "latitude": "44.24338000",
                            "longitude": "-73.95431000"
                        },
                        {
                            "id": 123030,
                            "name": "North Gates",
                            "latitude": "43.17645000",
                            "longitude": "-77.70139000"
                        },
                        {
                            "id": 123032,
                            "name": "North Great River",
                            "latitude": "40.74732000",
                            "longitude": "-73.16984000"
                        },
                        {
                            "id": 123040,
                            "name": "North Hills",
                            "latitude": "40.78093000",
                            "longitude": "-73.67652000"
                        },
                        {
                            "id": 123058,
                            "name": "North Lindenhurst",
                            "latitude": "40.71427000",
                            "longitude": "-73.38151000"
                        },
                        {
                            "id": 123064,
                            "name": "North Massapequa",
                            "latitude": "40.70093000",
                            "longitude": "-73.46207000"
                        },
                        {
                            "id": 123065,
                            "name": "North Merrick",
                            "latitude": "40.69121000",
                            "longitude": "-73.56318000"
                        },
                        {
                            "id": 123071,
                            "name": "North New Hyde Park",
                            "latitude": "40.74316000",
                            "longitude": "-73.69319000"
                        },
                        {
                            "id": 123076,
                            "name": "North Patchogue",
                            "latitude": "40.78704000",
                            "longitude": "-73.00900000"
                        },
                        {
                            "id": 123103,
                            "name": "North Sea",
                            "latitude": "40.93288000",
                            "longitude": "-72.41425000"
                        },
                        {
                            "id": 123113,
                            "name": "North Syracuse",
                            "latitude": "43.13479000",
                            "longitude": "-76.12992000"
                        },
                        {
                            "id": 123115,
                            "name": "North Tonawanda",
                            "latitude": "43.03867000",
                            "longitude": "-78.86420000"
                        },
                        {
                            "id": 123120,
                            "name": "North Valley Stream",
                            "latitude": "40.68510000",
                            "longitude": "-73.70180000"
                        },
                        {
                            "id": 123124,
                            "name": "North Wantagh",
                            "latitude": "40.69343000",
                            "longitude": "-73.50763000"
                        },
                        {
                            "id": 123146,
                            "name": "Northeast Ithaca",
                            "latitude": "42.47032000",
                            "longitude": "-76.46228000"
                        },
                        {
                            "id": 123162,
                            "name": "Northport",
                            "latitude": "40.90093000",
                            "longitude": "-73.34317000"
                        },
                        {
                            "id": 123165,
                            "name": "Northumberland",
                            "latitude": "43.12730000",
                            "longitude": "-73.58817000"
                        },
                        {
                            "id": 123172,
                            "name": "Northville",
                            "latitude": "40.97010000",
                            "longitude": "-72.61898000"
                        },
                        {
                            "id": 123174,
                            "name": "Northwest Harbor",
                            "latitude": "41.00982000",
                            "longitude": "-72.22119000"
                        },
                        {
                            "id": 123178,
                            "name": "Northwest Ithaca",
                            "latitude": "42.47059000",
                            "longitude": "-76.54145000"
                        },
                        {
                            "id": 123196,
                            "name": "Norwich",
                            "latitude": "42.53118000",
                            "longitude": "-75.52351000"
                        },
                        {
                            "id": 123201,
                            "name": "Norwood",
                            "latitude": "44.75145000",
                            "longitude": "-74.99436000"
                        },
                        {
                            "id": 123213,
                            "name": "Noyack",
                            "latitude": "40.99566000",
                            "longitude": "-72.34119000"
                        },
                        {
                            "id": 123217,
                            "name": "Nunda",
                            "latitude": "42.57951000",
                            "longitude": "-77.94250000"
                        },
                        {
                            "id": 123221,
                            "name": "Nyack",
                            "latitude": "41.09065000",
                            "longitude": "-73.91791000"
                        },
                        {
                            "id": 123272,
                            "name": "Oakdale",
                            "latitude": "40.74399000",
                            "longitude": "-73.13872000"
                        },
                        {
                            "id": 123276,
                            "name": "Oakfield",
                            "latitude": "43.06589000",
                            "longitude": "-78.26974000"
                        },
                        {
                            "id": 123310,
                            "name": "Oakwood",
                            "latitude": "40.56399000",
                            "longitude": "-74.11598000"
                        },
                        {
                            "id": 123343,
                            "name": "Oceanside",
                            "latitude": "40.63871000",
                            "longitude": "-73.64013000"
                        },
                        {
                            "id": 123372,
                            "name": "Ogdensburg",
                            "latitude": "44.69423000",
                            "longitude": "-75.48634000"
                        },
                        {
                            "id": 123414,
                            "name": "Olcott",
                            "latitude": "43.33783000",
                            "longitude": "-78.71476000"
                        },
                        {
                            "id": 123415,
                            "name": "Old Bethpage",
                            "latitude": "40.76260000",
                            "longitude": "-73.45318000"
                        },
                        {
                            "id": 123417,
                            "name": "Old Brookville",
                            "latitude": "40.83204000",
                            "longitude": "-73.60485000"
                        },
                        {
                            "id": 123431,
                            "name": "Old Westbury",
                            "latitude": "40.78871000",
                            "longitude": "-73.59957000"
                        },
                        {
                            "id": 123435,
                            "name": "Olean",
                            "latitude": "42.07756000",
                            "longitude": "-78.42974000"
                        },
                        {
                            "id": 123473,
                            "name": "Oneida",
                            "latitude": "43.09257000",
                            "longitude": "-75.65129000"
                        },
                        {
                            "id": 123475,
                            "name": "Oneida County",
                            "latitude": "43.24175000",
                            "longitude": "-75.43584000"
                        },
                        {
                            "id": 123479,
                            "name": "Oneonta",
                            "latitude": "42.45286000",
                            "longitude": "-75.06377000"
                        },
                        {
                            "id": 123482,
                            "name": "Onondaga County",
                            "latitude": "43.00580000",
                            "longitude": "-76.19464000"
                        },
                        {
                            "id": 123485,
                            "name": "Ontario",
                            "latitude": "43.22090000",
                            "longitude": "-77.28304000"
                        },
                        {
                            "id": 123488,
                            "name": "Ontario County",
                            "latitude": "42.85285000",
                            "longitude": "-77.29982000"
                        },
                        {
                            "id": 123519,
                            "name": "Orange County",
                            "latitude": "41.40214000",
                            "longitude": "-74.30557000"
                        },
                        {
                            "id": 123524,
                            "name": "Orange Lake",
                            "latitude": "41.53982000",
                            "longitude": "-74.09820000"
                        },
                        {
                            "id": 123527,
                            "name": "Orangeburg",
                            "latitude": "41.04649000",
                            "longitude": "-73.94958000"
                        },
                        {
                            "id": 123538,
                            "name": "Orchard Park",
                            "latitude": "42.76756000",
                            "longitude": "-78.74392000"
                        },
                        {
                            "id": 123557,
                            "name": "Oriskany",
                            "latitude": "43.15729000",
                            "longitude": "-75.33267000"
                        },
                        {
                            "id": 123565,
                            "name": "Orleans County",
                            "latitude": "43.25070000",
                            "longitude": "-78.18901000"
                        },
                        {
                            "id": 123617,
                            "name": "Ossining",
                            "latitude": "41.16287000",
                            "longitude": "-73.86152000"
                        },
                        {
                            "id": 123623,
                            "name": "Oswego",
                            "latitude": "43.45535000",
                            "longitude": "-76.51050000"
                        },
                        {
                            "id": 123624,
                            "name": "Oswego County",
                            "latitude": "43.46389000",
                            "longitude": "-76.20868000"
                        },
                        {
                            "id": 123630,
                            "name": "Otisville",
                            "latitude": "41.47343000",
                            "longitude": "-74.53849000"
                        },
                        {
                            "id": 123635,
                            "name": "Otsego County",
                            "latitude": "42.63376000",
                            "longitude": "-75.03261000"
                        },
                        {
                            "id": 123660,
                            "name": "Owego",
                            "latitude": "42.10341000",
                            "longitude": "-76.26215000"
                        },
                        {
                            "id": 123687,
                            "name": "Oxford",
                            "latitude": "42.44202000",
                            "longitude": "-75.59769000"
                        },
                        {
                            "id": 123693,
                            "name": "Oyster Bay",
                            "latitude": "40.86565000",
                            "longitude": "-73.53207000"
                        },
                        {
                            "id": 123694,
                            "name": "Oyster Bay Cove",
                            "latitude": "40.87093000",
                            "longitude": "-73.51096000"
                        },
                        {
                            "id": 123702,
                            "name": "Ozone Park",
                            "latitude": "40.67677000",
                            "longitude": "-73.84375000"
                        },
                        {
                            "id": 123727,
                            "name": "Painted Post",
                            "latitude": "42.16202000",
                            "longitude": "-77.09414000"
                        },
                        {
                            "id": 123733,
                            "name": "Palenville",
                            "latitude": "42.17453000",
                            "longitude": "-74.02014000"
                        },
                        {
                            "id": 123776,
                            "name": "Palmyra",
                            "latitude": "43.06395000",
                            "longitude": "-77.23332000"
                        },
                        {
                            "id": 123854,
                            "name": "Park Slope",
                            "latitude": "40.67010000",
                            "longitude": "-73.98597000"
                        },
                        {
                            "id": 123856,
                            "name": "Parkchester",
                            "latitude": "40.83899000",
                            "longitude": "-73.86041000"
                        },
                        {
                            "id": 123910,
                            "name": "Patchogue",
                            "latitude": "40.76565000",
                            "longitude": "-73.01511000"
                        },
                        {
                            "id": 123932,
                            "name": "Pawling",
                            "latitude": "41.56204000",
                            "longitude": "-73.60263000"
                        },
                        {
                            "id": 123957,
                            "name": "Peach Lake",
                            "latitude": "41.36759000",
                            "longitude": "-73.57790000"
                        },
                        {
                            "id": 123968,
                            "name": "Pearl River",
                            "latitude": "41.05899000",
                            "longitude": "-74.02181000"
                        },
                        {
                            "id": 123984,
                            "name": "Peekskill",
                            "latitude": "41.29009000",
                            "longitude": "-73.92042000"
                        },
                        {
                            "id": 123992,
                            "name": "Pelham",
                            "latitude": "40.90982000",
                            "longitude": "-73.80791000"
                        },
                        {
                            "id": 123993,
                            "name": "Pelham Manor",
                            "latitude": "40.89538000",
                            "longitude": "-73.80708000"
                        },
                        {
                            "id": 124026,
                            "name": "Penn Yan",
                            "latitude": "42.66090000",
                            "longitude": "-77.05386000"
                        },
                        {
                            "id": 124072,
                            "name": "Perry",
                            "latitude": "42.71562000",
                            "longitude": "-78.00556000"
                        },
                        {
                            "id": 124093,
                            "name": "Perth",
                            "latitude": "43.01757000",
                            "longitude": "-74.19402000"
                        },
                        {
                            "id": 124098,
                            "name": "Peru",
                            "latitude": "44.57838000",
                            "longitude": "-73.52680000"
                        },
                        {
                            "id": 124120,
                            "name": "Phelps",
                            "latitude": "42.95756000",
                            "longitude": "-77.05747000"
                        },
                        {
                            "id": 124127,
                            "name": "Philadelphia",
                            "latitude": "44.15450000",
                            "longitude": "-75.70882000"
                        },
                        {
                            "id": 124142,
                            "name": "Philmont",
                            "latitude": "42.24842000",
                            "longitude": "-73.65318000"
                        },
                        {
                            "id": 124147,
                            "name": "Phoenix",
                            "latitude": "43.23118000",
                            "longitude": "-76.30076000"
                        },
                        {
                            "id": 124177,
                            "name": "Piermont",
                            "latitude": "41.04204000",
                            "longitude": "-73.91819000"
                        },
                        {
                            "id": 124216,
                            "name": "Pine Bush",
                            "latitude": "41.60815000",
                            "longitude": "-74.29904000"
                        },
                        {
                            "id": 124240,
                            "name": "Pine Plains",
                            "latitude": "41.97981000",
                            "longitude": "-73.65596000"
                        },
                        {
                            "id": 124302,
                            "name": "Pittsford",
                            "latitude": "43.09062000",
                            "longitude": "-77.51500000"
                        },
                        {
                            "id": 124317,
                            "name": "Plainedge",
                            "latitude": "40.71732000",
                            "longitude": "-73.48374000"
                        },
                        {
                            "id": 124330,
                            "name": "Plainview",
                            "latitude": "40.77649000",
                            "longitude": "-73.46735000"
                        },
                        {
                            "id": 124339,
                            "name": "Plandome",
                            "latitude": "40.80677000",
                            "longitude": "-73.70346000"
                        },
                        {
                            "id": 124340,
                            "name": "Plandome Heights",
                            "latitude": "40.80260000",
                            "longitude": "-73.70430000"
                        },
                        {
                            "id": 124356,
                            "name": "Plattekill",
                            "latitude": "41.61759000",
                            "longitude": "-74.07598000"
                        },
                        {
                            "id": 124360,
                            "name": "Plattsburgh",
                            "latitude": "44.69949000",
                            "longitude": "-73.45291000"
                        },
                        {
                            "id": 124361,
                            "name": "Plattsburgh West",
                            "latitude": "44.68315000",
                            "longitude": "-73.50295000"
                        },
                        {
                            "id": 124379,
                            "name": "Pleasant Valley",
                            "latitude": "41.74454000",
                            "longitude": "-73.82124000"
                        },
                        {
                            "id": 124388,
                            "name": "Pleasantville",
                            "latitude": "41.13287000",
                            "longitude": "-73.79263000"
                        },
                        {
                            "id": 124423,
                            "name": "Poestenkill",
                            "latitude": "42.69036000",
                            "longitude": "-73.56456000"
                        },
                        {
                            "id": 124428,
                            "name": "Point Lookout",
                            "latitude": "40.59233000",
                            "longitude": "-73.58068000"
                        },
                        {
                            "id": 124458,
                            "name": "Pomona",
                            "latitude": "41.16704000",
                            "longitude": "-74.04320000"
                        },
                        {
                            "id": 124500,
                            "name": "Port Byron",
                            "latitude": "43.03451000",
                            "longitude": "-76.62383000"
                        },
                        {
                            "id": 124503,
                            "name": "Port Chester",
                            "latitude": "41.00176000",
                            "longitude": "-73.66568000"
                        },
                        {
                            "id": 124504,
                            "name": "Port Dickinson",
                            "latitude": "42.13341000",
                            "longitude": "-75.89631000"
                        },
                        {
                            "id": 124506,
                            "name": "Port Ewen",
                            "latitude": "41.90537000",
                            "longitude": "-73.97625000"
                        },
                        {
                            "id": 124509,
                            "name": "Port Henry",
                            "latitude": "44.04839000",
                            "longitude": "-73.45985000"
                        },
                        {
                            "id": 124513,
                            "name": "Port Jefferson",
                            "latitude": "40.94649000",
                            "longitude": "-73.06927000"
                        },
                        {
                            "id": 124514,
                            "name": "Port Jefferson Station",
                            "latitude": "40.92538000",
                            "longitude": "-73.04733000"
                        },
                        {
                            "id": 124515,
                            "name": "Port Jervis",
                            "latitude": "41.37509000",
                            "longitude": "-74.69266000"
                        },
                        {
                            "id": 124520,
                            "name": "Port Morris",
                            "latitude": "40.80149000",
                            "longitude": "-73.90958000"
                        },
                        {
                            "id": 124530,
                            "name": "Port Richmond",
                            "latitude": "40.63316000",
                            "longitude": "-74.13653000"
                        },
                        {
                            "id": 124539,
                            "name": "Port Washington",
                            "latitude": "40.82566000",
                            "longitude": "-73.69819000"
                        },
                        {
                            "id": 124541,
                            "name": "Port Washington North",
                            "latitude": "40.84482000",
                            "longitude": "-73.70180000"
                        },
                        {
                            "id": 124563,
                            "name": "Portland",
                            "latitude": "42.37978000",
                            "longitude": "-79.46755000"
                        },
                        {
                            "id": 124588,
                            "name": "Potsdam",
                            "latitude": "44.66978000",
                            "longitude": "-74.98131000"
                        },
                        {
                            "id": 124602,
                            "name": "Poughkeepsie",
                            "latitude": "41.70037000",
                            "longitude": "-73.92097000"
                        },
                        {
                            "id": 124605,
                            "name": "Pound Ridge",
                            "latitude": "41.20871000",
                            "longitude": "-73.57485000"
                        },
                        {
                            "id": 124735,
                            "name": "Pulaski",
                            "latitude": "43.56701000",
                            "longitude": "-76.12770000"
                        },
                        {
                            "id": 124755,
                            "name": "Purchase",
                            "latitude": "41.04093000",
                            "longitude": "-73.71457000"
                        },
                        {
                            "id": 124768,
                            "name": "Putnam County",
                            "latitude": "41.42666000",
                            "longitude": "-73.74951000"
                        },
                        {
                            "id": 124769,
                            "name": "Putnam Lake",
                            "latitude": "41.46204000",
                            "longitude": "-73.54624000"
                        },
                        {
                            "id": 124790,
                            "name": "Queens",
                            "latitude": "40.68149000",
                            "longitude": "-73.83652000"
                        },
                        {
                            "id": 124791,
                            "name": "Queens County",
                            "latitude": "40.65749000",
                            "longitude": "-73.83875000"
                        },
                        {
                            "id": 124792,
                            "name": "Queens Village",
                            "latitude": "40.72677000",
                            "longitude": "-73.74152000"
                        },
                        {
                            "id": 124793,
                            "name": "Queensbury",
                            "latitude": "43.37729000",
                            "longitude": "-73.61317000"
                        },
                        {
                            "id": 124867,
                            "name": "Randolph",
                            "latitude": "42.16201000",
                            "longitude": "-78.97532000"
                        },
                        {
                            "id": 124889,
                            "name": "Ransomville",
                            "latitude": "43.23867000",
                            "longitude": "-78.90976000"
                        },
                        {
                            "id": 124897,
                            "name": "Rapids",
                            "latitude": "43.09839000",
                            "longitude": "-78.64086000"
                        },
                        {
                            "id": 124905,
                            "name": "Ravena",
                            "latitude": "42.46841000",
                            "longitude": "-73.81624000"
                        },
                        {
                            "id": 124948,
                            "name": "Red Hook",
                            "latitude": "41.99509000",
                            "longitude": "-73.87541000"
                        },
                        {
                            "id": 124958,
                            "name": "Red Oaks Mill",
                            "latitude": "41.65565000",
                            "longitude": "-73.87486000"
                        },
                        {
                            "id": 125002,
                            "name": "Rego Park",
                            "latitude": "40.72649000",
                            "longitude": "-73.85264000"
                        },
                        {
                            "id": 125016,
                            "name": "Remsenburg-Speonk",
                            "latitude": "40.82642000",
                            "longitude": "-72.69673000"
                        },
                        {
                            "id": 125024,
                            "name": "Rensselaer",
                            "latitude": "42.64258000",
                            "longitude": "-73.74290000"
                        },
                        {
                            "id": 125025,
                            "name": "Rensselaer County",
                            "latitude": "42.71105000",
                            "longitude": "-73.50972000"
                        },
                        {
                            "id": 125045,
                            "name": "Rhinebeck",
                            "latitude": "41.92676000",
                            "longitude": "-73.91264000"
                        },
                        {
                            "id": 125064,
                            "name": "Richfield Springs",
                            "latitude": "42.85341000",
                            "longitude": "-74.98543000"
                        },
                        {
                            "id": 125070,
                            "name": "Richland",
                            "latitude": "43.56951000",
                            "longitude": "-76.04770000"
                        },
                        {
                            "id": 125101,
                            "name": "Richmond County",
                            "latitude": "40.58344000",
                            "longitude": "-74.14959000"
                        },
                        {
                            "id": 125105,
                            "name": "Richmond Hill",
                            "latitude": "40.69983000",
                            "longitude": "-73.83125000"
                        },
                        {
                            "id": 125114,
                            "name": "Ridge",
                            "latitude": "40.89399000",
                            "longitude": "-72.89594000"
                        },
                        {
                            "id": 125132,
                            "name": "Ridgewood",
                            "latitude": "40.70010000",
                            "longitude": "-73.90569000"
                        },
                        {
                            "id": 125195,
                            "name": "Riverdale",
                            "latitude": "40.90056000",
                            "longitude": "-73.90639000"
                        },
                        {
                            "id": 125200,
                            "name": "Riverhead",
                            "latitude": "40.91704000",
                            "longitude": "-72.66204000"
                        },
                        {
                            "id": 125207,
                            "name": "Riverside",
                            "latitude": "40.88121000",
                            "longitude": "-72.67787000"
                        },
                        {
                            "id": 125267,
                            "name": "Rochester",
                            "latitude": "43.15478000",
                            "longitude": "-77.61556000"
                        },
                        {
                            "id": 125281,
                            "name": "Rock Hill",
                            "latitude": "41.62593000",
                            "longitude": "-74.59766000"
                        },
                        {
                            "id": 125291,
                            "name": "Rockaway Point",
                            "latitude": "40.56066000",
                            "longitude": "-73.91514000"
                        },
                        {
                            "id": 125310,
                            "name": "Rockland County",
                            "latitude": "41.15243000",
                            "longitude": "-74.02409000"
                        },
                        {
                            "id": 125325,
                            "name": "Rockville Centre",
                            "latitude": "40.65871000",
                            "longitude": "-73.64124000"
                        },
                        {
                            "id": 125338,
                            "name": "Rocky Point",
                            "latitude": "40.95260000",
                            "longitude": "-72.92538000"
                        },
                        {
                            "id": 125346,
                            "name": "Roessleville",
                            "latitude": "42.69508000",
                            "longitude": "-73.80707000"
                        },
                        {
                            "id": 125378,
                            "name": "Rome",
                            "latitude": "43.21285000",
                            "longitude": "-75.45573000"
                        },
                        {
                            "id": 125388,
                            "name": "Ronkonkoma",
                            "latitude": "40.81538000",
                            "longitude": "-73.11233000"
                        },
                        {
                            "id": 125391,
                            "name": "Roosevelt",
                            "latitude": "40.67871000",
                            "longitude": "-73.58902000"
                        },
                        {
                            "id": 125409,
                            "name": "Rosebank",
                            "latitude": "40.61399000",
                            "longitude": "-74.06625000"
                        },
                        {
                            "id": 125418,
                            "name": "Rosedale",
                            "latitude": "40.66205000",
                            "longitude": "-73.73541000"
                        },
                        {
                            "id": 125435,
                            "name": "Rosendale Village",
                            "latitude": "41.85038000",
                            "longitude": "-74.07379000"
                        },
                        {
                            "id": 125447,
                            "name": "Roslyn",
                            "latitude": "40.79982000",
                            "longitude": "-73.65096000"
                        },
                        {
                            "id": 125448,
                            "name": "Roslyn Estates",
                            "latitude": "40.79427000",
                            "longitude": "-73.66041000"
                        },
                        {
                            "id": 125449,
                            "name": "Roslyn Harbor",
                            "latitude": "40.81649000",
                            "longitude": "-73.63707000"
                        },
                        {
                            "id": 125450,
                            "name": "Roslyn Heights",
                            "latitude": "40.78871000",
                            "longitude": "-73.64735000"
                        },
                        {
                            "id": 125461,
                            "name": "Rossville",
                            "latitude": "40.55566000",
                            "longitude": "-74.21348000"
                        },
                        {
                            "id": 125468,
                            "name": "Rotterdam",
                            "latitude": "42.78702000",
                            "longitude": "-73.97096000"
                        },
                        {
                            "id": 125475,
                            "name": "Rouses Point",
                            "latitude": "44.99393000",
                            "longitude": "-73.36486000"
                        },
                        {
                            "id": 125562,
                            "name": "Rye",
                            "latitude": "40.98065000",
                            "longitude": "-73.68374000"
                        },
                        {
                            "id": 125563,
                            "name": "Rye Brook",
                            "latitude": "41.01926000",
                            "longitude": "-73.68346000"
                        },
                        {
                            "id": 125575,
                            "name": "Sackets Harbor",
                            "latitude": "43.94617000",
                            "longitude": "-76.11909000"
                        },
                        {
                            "id": 125584,
                            "name": "Sag Harbor",
                            "latitude": "40.99788000",
                            "longitude": "-72.29258000"
                        },
                        {
                            "id": 125609,
                            "name": "Saint Bonaventure",
                            "latitude": "42.08034000",
                            "longitude": "-78.47502000"
                        },
                        {
                            "id": 125656,
                            "name": "Saint James",
                            "latitude": "40.87899000",
                            "longitude": "-73.15678000"
                        },
                        {
                            "id": 125669,
                            "name": "Saint Johnsville",
                            "latitude": "42.99813000",
                            "longitude": "-74.68292000"
                        },
                        {
                            "id": 125723,
                            "name": "Salamanca",
                            "latitude": "42.15784000",
                            "longitude": "-78.71503000"
                        },
                        {
                            "id": 125759,
                            "name": "Salisbury",
                            "latitude": "40.74566000",
                            "longitude": "-73.56013000"
                        },
                        {
                            "id": 125854,
                            "name": "Sanborn",
                            "latitude": "43.13672000",
                            "longitude": "-78.88476000"
                        },
                        {
                            "id": 125872,
                            "name": "Sands Point",
                            "latitude": "40.85177000",
                            "longitude": "-73.71874000"
                        },
                        {
                            "id": 125936,
                            "name": "Saranac Lake",
                            "latitude": "44.32950000",
                            "longitude": "-74.13127000"
                        },
                        {
                            "id": 125943,
                            "name": "Saratoga County",
                            "latitude": "43.10738000",
                            "longitude": "-73.86390000"
                        },
                        {
                            "id": 125944,
                            "name": "Saratoga Springs",
                            "latitude": "43.08313000",
                            "longitude": "-73.78457000"
                        },
                        {
                            "id": 125959,
                            "name": "Saugerties",
                            "latitude": "42.07759000",
                            "longitude": "-73.95291000"
                        },
                        {
                            "id": 125960,
                            "name": "Saugerties South",
                            "latitude": "42.06139000",
                            "longitude": "-73.95067000"
                        },
                        {
                            "id": 125993,
                            "name": "Sayville",
                            "latitude": "40.73593000",
                            "longitude": "-73.08206000"
                        },
                        {
                            "id": 125998,
                            "name": "Scarsdale",
                            "latitude": "41.00510000",
                            "longitude": "-73.78458000"
                        },
                        {
                            "id": 126003,
                            "name": "Schenectady",
                            "latitude": "42.81424000",
                            "longitude": "-73.93957000"
                        },
                        {
                            "id": 126004,
                            "name": "Schenectady County",
                            "latitude": "42.81812000",
                            "longitude": "-74.05857000"
                        },
                        {
                            "id": 126015,
                            "name": "Schoharie",
                            "latitude": "42.66591000",
                            "longitude": "-74.30958000"
                        },
                        {
                            "id": 126016,
                            "name": "Schoharie County",
                            "latitude": "42.58822000",
                            "longitude": "-74.44212000"
                        },
                        {
                            "id": 126024,
                            "name": "Schuyler County",
                            "latitude": "42.39380000",
                            "longitude": "-76.87518000"
                        },
                        {
                            "id": 126025,
                            "name": "Schuylerville",
                            "latitude": "43.10008000",
                            "longitude": "-73.58178000"
                        },
                        {
                            "id": 126033,
                            "name": "Scotchtown",
                            "latitude": "41.48148000",
                            "longitude": "-74.36015000"
                        },
                        {
                            "id": 126034,
                            "name": "Scotia",
                            "latitude": "42.82647000",
                            "longitude": "-73.96429000"
                        },
                        {
                            "id": 126065,
                            "name": "Scottsville",
                            "latitude": "43.02590000",
                            "longitude": "-77.74528000"
                        },
                        {
                            "id": 126072,
                            "name": "Sea Cliff",
                            "latitude": "40.84899000",
                            "longitude": "-73.64485000"
                        },
                        {
                            "id": 126086,
                            "name": "Seaford",
                            "latitude": "40.66593000",
                            "longitude": "-73.48818000"
                        },
                        {
                            "id": 126094,
                            "name": "Searingtown",
                            "latitude": "40.77482000",
                            "longitude": "-73.65568000"
                        },
                        {
                            "id": 126098,
                            "name": "Seaside",
                            "latitude": "40.58316000",
                            "longitude": "-73.82819000"
                        },
                        {
                            "id": 126133,
                            "name": "Selden",
                            "latitude": "40.86649000",
                            "longitude": "-73.03566000"
                        },
                        {
                            "id": 126159,
                            "name": "Seneca County",
                            "latitude": "42.78108000",
                            "longitude": "-76.82378000"
                        },
                        {
                            "id": 126160,
                            "name": "Seneca Falls",
                            "latitude": "42.91062000",
                            "longitude": "-76.79662000"
                        },
                        {
                            "id": 126161,
                            "name": "Seneca Knolls",
                            "latitude": "43.12007000",
                            "longitude": "-76.28632000"
                        },
                        {
                            "id": 126169,
                            "name": "Setauket-East Setauket",
                            "latitude": "40.93064000",
                            "longitude": "-73.10179000"
                        },
                        {
                            "id": 126258,
                            "name": "Sheepshead Bay",
                            "latitude": "40.59122000",
                            "longitude": "-73.94458000"
                        },
                        {
                            "id": 126295,
                            "name": "Shelter Island",
                            "latitude": "41.06815000",
                            "longitude": "-72.33869000"
                        },
                        {
                            "id": 126296,
                            "name": "Shelter Island Heights",
                            "latitude": "41.08399000",
                            "longitude": "-72.35592000"
                        },
                        {
                            "id": 126308,
                            "name": "Shenorock",
                            "latitude": "41.33176000",
                            "longitude": "-73.73819000"
                        },
                        {
                            "id": 126315,
                            "name": "Sherburne",
                            "latitude": "42.67813000",
                            "longitude": "-75.49851000"
                        },
                        {
                            "id": 126338,
                            "name": "Sherrill",
                            "latitude": "43.07368000",
                            "longitude": "-75.59824000"
                        },
                        {
                            "id": 126354,
                            "name": "Shinnecock Hills",
                            "latitude": "40.89093000",
                            "longitude": "-72.46370000"
                        },
                        {
                            "id": 126361,
                            "name": "Shirley",
                            "latitude": "40.80149000",
                            "longitude": "-72.86760000"
                        },
                        {
                            "id": 126366,
                            "name": "Shokan",
                            "latitude": "41.97343000",
                            "longitude": "-74.21209000"
                        },
                        {
                            "id": 126378,
                            "name": "Shortsville",
                            "latitude": "42.95590000",
                            "longitude": "-77.22081000"
                        },
                        {
                            "id": 126387,
                            "name": "Shrub Oak",
                            "latitude": "41.32759000",
                            "longitude": "-73.81958000"
                        },
                        {
                            "id": 126397,
                            "name": "Sidney",
                            "latitude": "42.31480000",
                            "longitude": "-75.39157000"
                        },
                        {
                            "id": 126423,
                            "name": "Silver Creek",
                            "latitude": "42.54423000",
                            "longitude": "-79.16671000"
                        },
                        {
                            "id": 126477,
                            "name": "Skaneateles",
                            "latitude": "42.94701000",
                            "longitude": "-76.42910000"
                        },
                        {
                            "id": 126498,
                            "name": "Sleepy Hollow",
                            "latitude": "41.08565000",
                            "longitude": "-73.85847000"
                        },
                        {
                            "id": 126504,
                            "name": "Sloan",
                            "latitude": "42.89339000",
                            "longitude": "-78.79392000"
                        },
                        {
                            "id": 126505,
                            "name": "Sloatsburg",
                            "latitude": "41.15454000",
                            "longitude": "-74.19292000"
                        },
                        {
                            "id": 126526,
                            "name": "Smithtown",
                            "latitude": "40.85593000",
                            "longitude": "-73.20067000"
                        },
                        {
                            "id": 126564,
                            "name": "Sodus",
                            "latitude": "43.23784000",
                            "longitude": "-77.06136000"
                        },
                        {
                            "id": 126573,
                            "name": "Solvay",
                            "latitude": "43.05812000",
                            "longitude": "-76.20743000"
                        },
                        {
                            "id": 126609,
                            "name": "Sound Beach",
                            "latitude": "40.95621000",
                            "longitude": "-72.96788000"
                        },
                        {
                            "id": 126620,
                            "name": "South Beach",
                            "latitude": "40.58329000",
                            "longitude": "-74.07609000"
                        },
                        {
                            "id": 126627,
                            "name": "South Blooming Grove",
                            "latitude": "41.37337000",
                            "longitude": "-74.17843000"
                        },
                        {
                            "id": 126643,
                            "name": "South Corning",
                            "latitude": "42.12174000",
                            "longitude": "-77.03719000"
                        },
                        {
                            "id": 126653,
                            "name": "South Fallsburg",
                            "latitude": "41.72065000",
                            "longitude": "-74.63433000"
                        },
                        {
                            "id": 126654,
                            "name": "South Farmingdale",
                            "latitude": "40.72066000",
                            "longitude": "-73.44012000"
                        },
                        {
                            "id": 126655,
                            "name": "South Floral Park",
                            "latitude": "40.71427000",
                            "longitude": "-73.70013000"
                        },
                        {
                            "id": 126661,
                            "name": "South Glens Falls",
                            "latitude": "43.29924000",
                            "longitude": "-73.63512000"
                        },
                        {
                            "id": 126668,
                            "name": "South Hempstead",
                            "latitude": "40.68094000",
                            "longitude": "-73.61541000"
                        },
                        {
                            "id": 126672,
                            "name": "South Hill",
                            "latitude": "42.42924000",
                            "longitude": "-76.49494000"
                        },
                        {
                            "id": 126677,
                            "name": "South Huntington",
                            "latitude": "40.82371000",
                            "longitude": "-73.39873000"
                        },
                        {
                            "id": 126689,
                            "name": "South Lockport",
                            "latitude": "43.15006000",
                            "longitude": "-78.69670000"
                        },
                        {
                            "id": 126695,
                            "name": "South Nyack",
                            "latitude": "41.08315000",
                            "longitude": "-73.92014000"
                        },
                        {
                            "id": 126741,
                            "name": "South Valley Stream",
                            "latitude": "40.65594000",
                            "longitude": "-73.71763000"
                        },
                        {
                            "id": 126758,
                            "name": "Southampton",
                            "latitude": "40.88427000",
                            "longitude": "-72.38953000"
                        },
                        {
                            "id": 126781,
                            "name": "Southold",
                            "latitude": "41.06482000",
                            "longitude": "-72.42620000"
                        },
                        {
                            "id": 126785,
                            "name": "Southport",
                            "latitude": "42.05480000",
                            "longitude": "-76.81912000"
                        },
                        {
                            "id": 126794,
                            "name": "Spackenkill",
                            "latitude": "41.65593000",
                            "longitude": "-73.91347000"
                        },
                        {
                            "id": 126802,
                            "name": "Sparkill",
                            "latitude": "41.03121000",
                            "longitude": "-73.92708000"
                        },
                        {
                            "id": 126830,
                            "name": "Spencerport",
                            "latitude": "43.18645000",
                            "longitude": "-77.80390000"
                        },
                        {
                            "id": 126877,
                            "name": "Spring Valley",
                            "latitude": "41.11315000",
                            "longitude": "-74.04375000"
                        },
                        {
                            "id": 126903,
                            "name": "Springfield",
                            "latitude": "42.83618000",
                            "longitude": "-74.85348000"
                        },
                        {
                            "id": 126908,
                            "name": "Springfield Gardens",
                            "latitude": "40.66312000",
                            "longitude": "-73.76221000"
                        },
                        {
                            "id": 126910,
                            "name": "Springs",
                            "latitude": "41.01621000",
                            "longitude": "-72.15924000"
                        },
                        {
                            "id": 126917,
                            "name": "Springville",
                            "latitude": "42.50840000",
                            "longitude": "-78.66725000"
                        },
                        {
                            "id": 126923,
                            "name": "Spuyten Duyvil",
                            "latitude": "40.88121000",
                            "longitude": "-73.91736000"
                        },
                        {
                            "id": 126927,
                            "name": "St. Lawrence County",
                            "latitude": "44.50062000",
                            "longitude": "-75.11631000"
                        },
                        {
                            "id": 126945,
                            "name": "Stamford",
                            "latitude": "42.40730000",
                            "longitude": "-74.61432000"
                        },
                        {
                            "id": 126976,
                            "name": "Stapleton",
                            "latitude": "40.62649000",
                            "longitude": "-74.07764000"
                        },
                        {
                            "id": 126995,
                            "name": "Staten Island",
                            "latitude": "40.56233000",
                            "longitude": "-74.13986000"
                        },
                        {
                            "id": 127019,
                            "name": "Steinway",
                            "latitude": "40.77455000",
                            "longitude": "-73.90375000"
                        },
                        {
                            "id": 127039,
                            "name": "Steuben County",
                            "latitude": "42.26781000",
                            "longitude": "-77.38380000"
                        },
                        {
                            "id": 127052,
                            "name": "Stewart Manor",
                            "latitude": "40.71927000",
                            "longitude": "-73.68846000"
                        },
                        {
                            "id": 127062,
                            "name": "Stillwater",
                            "latitude": "42.93841000",
                            "longitude": "-73.65317000"
                        },
                        {
                            "id": 127085,
                            "name": "Stone Ridge",
                            "latitude": "41.85315000",
                            "longitude": "-74.13903000"
                        },
                        {
                            "id": 127096,
                            "name": "Stony Brook",
                            "latitude": "40.92565000",
                            "longitude": "-73.14094000"
                        },
                        {
                            "id": 127100,
                            "name": "Stony Point",
                            "latitude": "41.22954000",
                            "longitude": "-73.98708000"
                        },
                        {
                            "id": 127108,
                            "name": "Stottville",
                            "latitude": "42.28620000",
                            "longitude": "-73.73873000"
                        },
                        {
                            "id": 127164,
                            "name": "Suffern",
                            "latitude": "41.11482000",
                            "longitude": "-74.14959000"
                        },
                        {
                            "id": 127168,
                            "name": "Suffolk County",
                            "latitude": "40.94046000",
                            "longitude": "-72.68524000"
                        },
                        {
                            "id": 127190,
                            "name": "Sullivan County",
                            "latitude": "41.71642000",
                            "longitude": "-74.76814000"
                        },
                        {
                            "id": 127258,
                            "name": "Sunnyside",
                            "latitude": "40.73982000",
                            "longitude": "-73.93542000"
                        },
                        {
                            "id": 127276,
                            "name": "Sunset Park",
                            "latitude": "40.64548000",
                            "longitude": "-74.01241000"
                        },
                        {
                            "id": 127356,
                            "name": "Sylvan Beach",
                            "latitude": "42.46479000",
                            "longitude": "-77.10830000"
                        },
                        {
                            "id": 127362,
                            "name": "Syosset",
                            "latitude": "40.82621000",
                            "longitude": "-73.50207000"
                        },
                        {
                            "id": 127365,
                            "name": "Syracuse",
                            "latitude": "43.04812000",
                            "longitude": "-76.14742000"
                        },
                        {
                            "id": 127426,
                            "name": "Tappan",
                            "latitude": "41.02204000",
                            "longitude": "-73.94736000"
                        },
                        {
                            "id": 127436,
                            "name": "Tarrytown",
                            "latitude": "41.07621000",
                            "longitude": "-73.85875000"
                        },
                        {
                            "id": 127517,
                            "name": "Terrace Heights",
                            "latitude": "40.72149000",
                            "longitude": "-73.76930000"
                        },
                        {
                            "id": 127536,
                            "name": "Terryville",
                            "latitude": "40.90899000",
                            "longitude": "-73.06511000"
                        },
                        {
                            "id": 127554,
                            "name": "The Bronx",
                            "latitude": "40.84985000",
                            "longitude": "-73.86641000"
                        },
                        {
                            "id": 127575,
                            "name": "Thiells",
                            "latitude": "41.21065000",
                            "longitude": "-74.01764000"
                        },
                        {
                            "id": 127586,
                            "name": "Thomaston",
                            "latitude": "40.78621000",
                            "longitude": "-73.71374000"
                        },
                        {
                            "id": 127606,
                            "name": "Thornwood",
                            "latitude": "41.12343000",
                            "longitude": "-73.77902000"
                        },
                        {
                            "id": 127625,
                            "name": "Throgs Neck",
                            "latitude": "40.82260000",
                            "longitude": "-73.81958000"
                        },
                        {
                            "id": 127635,
                            "name": "Ticonderoga",
                            "latitude": "43.84867000",
                            "longitude": "-73.42345000"
                        },
                        {
                            "id": 127652,
                            "name": "Tillson",
                            "latitude": "41.82898000",
                            "longitude": "-74.06848000"
                        },
                        {
                            "id": 127669,
                            "name": "Tioga County",
                            "latitude": "42.17030000",
                            "longitude": "-76.30632000"
                        },
                        {
                            "id": 127688,
                            "name": "Tivoli",
                            "latitude": "42.05842000",
                            "longitude": "-73.90930000"
                        },
                        {
                            "id": 127713,
                            "name": "Tompkins County",
                            "latitude": "42.45202000",
                            "longitude": "-76.47366000"
                        },
                        {
                            "id": 127715,
                            "name": "Tompkinsville",
                            "latitude": "40.63812000",
                            "longitude": "-74.07795000"
                        },
                        {
                            "id": 127718,
                            "name": "Tonawanda",
                            "latitude": "43.02033000",
                            "longitude": "-78.88031000"
                        },
                        {
                            "id": 127758,
                            "name": "Town Line",
                            "latitude": "42.89061000",
                            "longitude": "-78.57780000"
                        },
                        {
                            "id": 127795,
                            "name": "Tremont",
                            "latitude": "40.84954000",
                            "longitude": "-73.90569000"
                        },
                        {
                            "id": 127819,
                            "name": "Tribes Hill",
                            "latitude": "42.95535000",
                            "longitude": "-74.28513000"
                        },
                        {
                            "id": 127851,
                            "name": "Troy",
                            "latitude": "42.72841000",
                            "longitude": "-73.69179000"
                        },
                        {
                            "id": 127857,
                            "name": "Trumansburg",
                            "latitude": "42.54229000",
                            "longitude": "-76.66606000"
                        },
                        {
                            "id": 127869,
                            "name": "Tuckahoe",
                            "latitude": "40.95038000",
                            "longitude": "-73.82736000"
                        },
                        {
                            "id": 127898,
                            "name": "Tupper Lake",
                            "latitude": "44.22395000",
                            "longitude": "-74.46406000"
                        },
                        {
                            "id": 127959,
                            "name": "Ulster County",
                            "latitude": "41.88815000",
                            "longitude": "-74.25857000"
                        },
                        {
                            "id": 127965,
                            "name": "Unadilla",
                            "latitude": "42.32536000",
                            "longitude": "-75.31240000"
                        },
                        {
                            "id": 128012,
                            "name": "Union Springs",
                            "latitude": "42.83979000",
                            "longitude": "-76.69328000"
                        },
                        {
                            "id": 128013,
                            "name": "Uniondale",
                            "latitude": "40.70038000",
                            "longitude": "-73.59291000"
                        },
                        {
                            "id": 128014,
                            "name": "Unionport",
                            "latitude": "40.82732000",
                            "longitude": "-73.85013000"
                        },
                        {
                            "id": 128028,
                            "name": "University Gardens",
                            "latitude": "40.77732000",
                            "longitude": "-73.72263000"
                        },
                        {
                            "id": 128030,
                            "name": "University Heights",
                            "latitude": "40.86010000",
                            "longitude": "-73.90930000"
                        },
                        {
                            "id": 128042,
                            "name": "Upper Brookville",
                            "latitude": "40.83871000",
                            "longitude": "-73.56513000"
                        },
                        {
                            "id": 128048,
                            "name": "Upper Nyack",
                            "latitude": "41.10704000",
                            "longitude": "-73.92014000"
                        },
                        {
                            "id": 128067,
                            "name": "Utica",
                            "latitude": "43.10090000",
                            "longitude": "-75.23266000"
                        },
                        {
                            "id": 128077,
                            "name": "Vails Gate",
                            "latitude": "41.45426000",
                            "longitude": "-74.05764000"
                        },
                        {
                            "id": 128081,
                            "name": "Valatie",
                            "latitude": "42.41342000",
                            "longitude": "-73.67317000"
                        },
                        {
                            "id": 128092,
                            "name": "Valhalla",
                            "latitude": "41.07482000",
                            "longitude": "-73.77513000"
                        },
                        {
                            "id": 128102,
                            "name": "Valley Cottage",
                            "latitude": "41.11815000",
                            "longitude": "-73.95542000"
                        },
                        {
                            "id": 128117,
                            "name": "Valley Stream",
                            "latitude": "40.66427000",
                            "longitude": "-73.70846000"
                        },
                        {
                            "id": 128136,
                            "name": "Van Nest",
                            "latitude": "40.84843000",
                            "longitude": "-73.86375000"
                        },
                        {
                            "id": 128190,
                            "name": "Vernon",
                            "latitude": "43.07951000",
                            "longitude": "-75.53934000"
                        },
                        {
                            "id": 128207,
                            "name": "Verplanck",
                            "latitude": "41.25287000",
                            "longitude": "-73.95986000"
                        },
                        {
                            "id": 128217,
                            "name": "Victor",
                            "latitude": "42.98256000",
                            "longitude": "-77.40888000"
                        },
                        {
                            "id": 128247,
                            "name": "Village Green",
                            "latitude": "43.13340000",
                            "longitude": "-76.31299000"
                        },
                        {
                            "id": 128254,
                            "name": "Village of the Branch",
                            "latitude": "40.85621000",
                            "longitude": "-73.18733000"
                        },
                        {
                            "id": 128283,
                            "name": "Viola",
                            "latitude": "41.13648000",
                            "longitude": "-74.08236000"
                        },
                        {
                            "id": 128301,
                            "name": "Volney",
                            "latitude": "43.34285000",
                            "longitude": "-76.35771000"
                        },
                        {
                            "id": 128307,
                            "name": "Voorheesville",
                            "latitude": "42.65397000",
                            "longitude": "-73.92874000"
                        },
                        {
                            "id": 128324,
                            "name": "Wading River",
                            "latitude": "40.95038000",
                            "longitude": "-72.84260000"
                        },
                        {
                            "id": 128363,
                            "name": "Wakefield",
                            "latitude": "40.89788000",
                            "longitude": "-73.85236000"
                        },
                        {
                            "id": 128368,
                            "name": "Walden",
                            "latitude": "41.56120000",
                            "longitude": "-74.18848000"
                        },
                        {
                            "id": 128410,
                            "name": "Wallkill",
                            "latitude": "41.60565000",
                            "longitude": "-74.18404000"
                        },
                        {
                            "id": 128436,
                            "name": "Walton",
                            "latitude": "42.16953000",
                            "longitude": "-75.12934000"
                        },
                        {
                            "id": 128439,
                            "name": "Walton Park",
                            "latitude": "41.30982000",
                            "longitude": "-74.22904000"
                        },
                        {
                            "id": 128445,
                            "name": "Wampsville",
                            "latitude": "43.07535000",
                            "longitude": "-75.70685000"
                        },
                        {
                            "id": 128446,
                            "name": "Wanakah",
                            "latitude": "42.74617000",
                            "longitude": "-78.90309000"
                        },
                        {
                            "id": 128452,
                            "name": "Wantagh",
                            "latitude": "40.68371000",
                            "longitude": "-73.51013000"
                        },
                        {
                            "id": 128456,
                            "name": "Wappingers Falls",
                            "latitude": "41.59648000",
                            "longitude": "-73.91097000"
                        },
                        {
                            "id": 128497,
                            "name": "Warren County",
                            "latitude": "43.56098000",
                            "longitude": "-73.84601000"
                        },
                        {
                            "id": 128503,
                            "name": "Warrensburg",
                            "latitude": "43.49674000",
                            "longitude": "-73.77623000"
                        },
                        {
                            "id": 128521,
                            "name": "Warsaw",
                            "latitude": "42.74006000",
                            "longitude": "-78.13279000"
                        },
                        {
                            "id": 128524,
                            "name": "Warwick",
                            "latitude": "41.25648000",
                            "longitude": "-74.35988000"
                        },
                        {
                            "id": 128572,
                            "name": "Washington County",
                            "latitude": "43.31370000",
                            "longitude": "-73.43076000"
                        },
                        {
                            "id": 128581,
                            "name": "Washington Heights",
                            "latitude": "40.85010000",
                            "longitude": "-73.93541000"
                        },
                        {
                            "id": 128582,
                            "name": "Washington Mills",
                            "latitude": "43.05007000",
                            "longitude": "-75.27294000"
                        },
                        {
                            "id": 128588,
                            "name": "Washingtonville",
                            "latitude": "41.42787000",
                            "longitude": "-74.16598000"
                        },
                        {
                            "id": 128597,
                            "name": "Watchtower",
                            "latitude": "41.63776000",
                            "longitude": "-74.26027000"
                        },
                        {
                            "id": 128599,
                            "name": "Water Mill",
                            "latitude": "40.91959000",
                            "longitude": "-72.34274000"
                        },
                        {
                            "id": 128606,
                            "name": "Waterford",
                            "latitude": "42.79258000",
                            "longitude": "-73.68123000"
                        },
                        {
                            "id": 128615,
                            "name": "Waterloo",
                            "latitude": "42.90479000",
                            "longitude": "-76.86274000"
                        },
                        {
                            "id": 128623,
                            "name": "Watertown",
                            "latitude": "43.97478000",
                            "longitude": "-75.91076000"
                        },
                        {
                            "id": 128628,
                            "name": "Waterville",
                            "latitude": "42.93118000",
                            "longitude": "-75.37989000"
                        },
                        {
                            "id": 128630,
                            "name": "Watervliet",
                            "latitude": "42.73008000",
                            "longitude": "-73.70123000"
                        },
                        {
                            "id": 128634,
                            "name": "Watkins Glen",
                            "latitude": "42.38063000",
                            "longitude": "-76.87329000"
                        },
                        {
                            "id": 128672,
                            "name": "Waverly",
                            "latitude": "42.01035000",
                            "longitude": "-76.52717000"
                        },
                        {
                            "id": 128673,
                            "name": "Wawarsing",
                            "latitude": "41.75898000",
                            "longitude": "-74.35738000"
                        },
                        {
                            "id": 128677,
                            "name": "Wayland",
                            "latitude": "42.56784000",
                            "longitude": "-77.58971000"
                        },
                        {
                            "id": 128701,
                            "name": "Wayne County",
                            "latitude": "43.06588000",
                            "longitude": "-76.97845000"
                        },
                        {
                            "id": 128733,
                            "name": "Webster",
                            "latitude": "43.21229000",
                            "longitude": "-77.42999000"
                        },
                        {
                            "id": 128753,
                            "name": "Weedsport",
                            "latitude": "43.04868000",
                            "longitude": "-76.56272000"
                        },
                        {
                            "id": 128793,
                            "name": "Wellsville",
                            "latitude": "42.12201000",
                            "longitude": "-77.94806000"
                        },
                        {
                            "id": 128811,
                            "name": "Wesley Hills",
                            "latitude": "41.15926000",
                            "longitude": "-74.06986000"
                        },
                        {
                            "id": 128816,
                            "name": "West Albany",
                            "latitude": "42.68313000",
                            "longitude": "-73.77845000"
                        },
                        {
                            "id": 128819,
                            "name": "West Babylon",
                            "latitude": "40.71816000",
                            "longitude": "-73.35429000"
                        },
                        {
                            "id": 128823,
                            "name": "West Bay Shore",
                            "latitude": "40.70871000",
                            "longitude": "-73.28123000"
                        },
                        {
                            "id": 128843,
                            "name": "West Carthage",
                            "latitude": "43.97423000",
                            "longitude": "-75.61519000"
                        },
                        {
                            "id": 128861,
                            "name": "West Elmira",
                            "latitude": "42.07813000",
                            "longitude": "-76.84524000"
                        },
                        {
                            "id": 128863,
                            "name": "West End",
                            "latitude": "42.46869000",
                            "longitude": "-75.09378000"
                        },
                        {
                            "id": 128879,
                            "name": "West Glens Falls",
                            "latitude": "43.30007000",
                            "longitude": "-73.68401000"
                        },
                        {
                            "id": 128891,
                            "name": "West Haverstraw",
                            "latitude": "41.20954000",
                            "longitude": "-73.98542000"
                        },
                        {
                            "id": 128894,
                            "name": "West Hempstead",
                            "latitude": "40.70482000",
                            "longitude": "-73.65013000"
                        },
                        {
                            "id": 128895,
                            "name": "West Hills",
                            "latitude": "40.81621000",
                            "longitude": "-73.43234000"
                        },
                        {
                            "id": 128901,
                            "name": "West Hurley",
                            "latitude": "41.99731000",
                            "longitude": "-74.10486000"
                        },
                        {
                            "id": 128903,
                            "name": "West Islip",
                            "latitude": "40.70621000",
                            "longitude": "-73.30623000"
                        },
                        {
                            "id": 128942,
                            "name": "West Nyack",
                            "latitude": "41.09649000",
                            "longitude": "-73.97292000"
                        },
                        {
                            "id": 128962,
                            "name": "West Point",
                            "latitude": "41.39148000",
                            "longitude": "-73.95597000"
                        },
                        {
                            "id": 128975,
                            "name": "West Sand Lake",
                            "latitude": "42.64341000",
                            "longitude": "-73.60873000"
                        },
                        {
                            "id": 128976,
                            "name": "West Sayville",
                            "latitude": "40.72788000",
                            "longitude": "-73.09761000"
                        },
                        {
                            "id": 128979,
                            "name": "West Seneca",
                            "latitude": "42.85006000",
                            "longitude": "-78.79975000"
                        },
                        {
                            "id": 129015,
                            "name": "Westbury",
                            "latitude": "40.75566000",
                            "longitude": "-73.58763000"
                        },
                        {
                            "id": 129020,
                            "name": "Westchester County",
                            "latitude": "41.15148000",
                            "longitude": "-73.75339000"
                        },
                        {
                            "id": 129022,
                            "name": "Westerleigh",
                            "latitude": "40.62121000",
                            "longitude": "-74.13181000"
                        },
                        {
                            "id": 129030,
                            "name": "Westfield",
                            "latitude": "42.32228000",
                            "longitude": "-79.57810000"
                        },
                        {
                            "id": 129035,
                            "name": "Westhampton",
                            "latitude": "40.82454000",
                            "longitude": "-72.66621000"
                        },
                        {
                            "id": 129036,
                            "name": "Westhampton Beach",
                            "latitude": "40.80316000",
                            "longitude": "-72.61454000"
                        },
                        {
                            "id": 129042,
                            "name": "Westmere",
                            "latitude": "42.69119000",
                            "longitude": "-73.86873000"
                        },
                        {
                            "id": 129066,
                            "name": "Weston Mills",
                            "latitude": "42.07590000",
                            "longitude": "-78.37252000"
                        },
                        {
                            "id": 129074,
                            "name": "Westvale",
                            "latitude": "43.04757000",
                            "longitude": "-76.22048000"
                        },
                        {
                            "id": 129111,
                            "name": "Wheatley Heights",
                            "latitude": "40.76371000",
                            "longitude": "-73.36984000"
                        },
                        {
                            "id": 129155,
                            "name": "White Plains",
                            "latitude": "41.03399000",
                            "longitude": "-73.76291000"
                        },
                        {
                            "id": 129169,
                            "name": "Whitehall",
                            "latitude": "43.55562000",
                            "longitude": "-73.40372000"
                        },
                        {
                            "id": 129183,
                            "name": "Whitesboro",
                            "latitude": "43.12201000",
                            "longitude": "-75.29156000"
                        },
                        {
                            "id": 129187,
                            "name": "Whitestone",
                            "latitude": "40.79455000",
                            "longitude": "-73.81847000"
                        },
                        {
                            "id": 129270,
                            "name": "Williamsburg",
                            "latitude": "40.71427000",
                            "longitude": "-73.95347000"
                        },
                        {
                            "id": 129274,
                            "name": "Williamson",
                            "latitude": "43.22395000",
                            "longitude": "-77.18609000"
                        },
                        {
                            "id": 129292,
                            "name": "Williamsville",
                            "latitude": "42.96395000",
                            "longitude": "-78.73781000"
                        },
                        {
                            "id": 129301,
                            "name": "Williston Park",
                            "latitude": "40.75649000",
                            "longitude": "-73.64485000"
                        },
                        {
                            "id": 129314,
                            "name": "Willowbrook",
                            "latitude": "40.60316000",
                            "longitude": "-74.13848000"
                        },
                        {
                            "id": 129331,
                            "name": "Wilson",
                            "latitude": "43.30978000",
                            "longitude": "-78.82615000"
                        },
                        {
                            "id": 129469,
                            "name": "Wolcott",
                            "latitude": "43.22062000",
                            "longitude": "-76.81496000"
                        },
                        {
                            "id": 129510,
                            "name": "Woodbury",
                            "latitude": "41.36454000",
                            "longitude": "-74.10598000"
                        },
                        {
                            "id": 129522,
                            "name": "Woodhaven",
                            "latitude": "40.68927000",
                            "longitude": "-73.85791000"
                        },
                        {
                            "id": 129537,
                            "name": "Woodlawn",
                            "latitude": "40.89816000",
                            "longitude": "-73.86736000"
                        },
                        {
                            "id": 129541,
                            "name": "Woodmere",
                            "latitude": "40.63205000",
                            "longitude": "-73.71263000"
                        },
                        {
                            "id": 129546,
                            "name": "Woodrow",
                            "latitude": "40.54344000",
                            "longitude": "-74.19764000"
                        },
                        {
                            "id": 129554,
                            "name": "Woodside",
                            "latitude": "40.74538000",
                            "longitude": "-73.90541000"
                        },
                        {
                            "id": 129565,
                            "name": "Woodstock",
                            "latitude": "42.04092000",
                            "longitude": "-74.11820000"
                        },
                        {
                            "id": 129585,
                            "name": "Worcester",
                            "latitude": "42.59146000",
                            "longitude": "-74.75043000"
                        },
                        {
                            "id": 129619,
                            "name": "Wurtsboro",
                            "latitude": "41.57676000",
                            "longitude": "-74.48710000"
                        },
                        {
                            "id": 129620,
                            "name": "Wyandanch",
                            "latitude": "40.75399000",
                            "longitude": "-73.36040000"
                        },
                        {
                            "id": 129624,
                            "name": "Wykagyl",
                            "latitude": "40.94149000",
                            "longitude": "-73.79902000"
                        },
                        {
                            "id": 129628,
                            "name": "Wynantskill",
                            "latitude": "42.69675000",
                            "longitude": "-73.64428000"
                        },
                        {
                            "id": 129640,
                            "name": "Wyoming County",
                            "latitude": "42.70238000",
                            "longitude": "-78.22444000"
                        },
                        {
                            "id": 129663,
                            "name": "Yaphank",
                            "latitude": "40.83677000",
                            "longitude": "-72.91705000"
                        },
                        {
                            "id": 129671,
                            "name": "Yates County",
                            "latitude": "42.63344000",
                            "longitude": "-77.10546000"
                        },
                        {
                            "id": 129688,
                            "name": "Yonkers",
                            "latitude": "40.93121000",
                            "longitude": "-73.89875000"
                        },
                        {
                            "id": 129704,
                            "name": "Yorkshire",
                            "latitude": "42.53006000",
                            "longitude": "-78.47280000"
                        },
                        {
                            "id": 129708,
                            "name": "Yorktown Heights",
                            "latitude": "41.27093000",
                            "longitude": "-73.77763000"
                        },
                        {
                            "id": 129710,
                            "name": "Yorkville",
                            "latitude": "43.11285000",
                            "longitude": "-75.27100000"
                        },
                        {
                            "id": 129717,
                            "name": "Youngstown",
                            "latitude": "43.24728000",
                            "longitude": "-79.05005000"
                        },
                        {
                            "id": 129748,
                            "name": "Zena",
                            "latitude": "42.01676000",
                            "longitude": "-74.07625000"
                        }
                    ]
                },
                {
                    "id": 1447,
                    "name": "North Carolina",
                    "state_code": "NC",
                    "cities": [
                        {
                            "id": 110975,
                            "name": "Aberdeen",
                            "latitude": "35.13155000",
                            "longitude": "-79.42948000"
                        },
                        {
                            "id": 111049,
                            "name": "Advance",
                            "latitude": "35.94125000",
                            "longitude": "-80.40922000"
                        },
                        {
                            "id": 111062,
                            "name": "Ahoskie",
                            "latitude": "36.28682000",
                            "longitude": "-76.98468000"
                        },
                        {
                            "id": 111087,
                            "name": "Alamance County",
                            "latitude": "36.04407000",
                            "longitude": "-79.39951000"
                        },
                        {
                            "id": 111115,
                            "name": "Albemarle",
                            "latitude": "35.35014000",
                            "longitude": "-80.20006000"
                        },
                        {
                            "id": 111148,
                            "name": "Alexander County",
                            "latitude": "35.92102000",
                            "longitude": "-81.17702000"
                        },
                        {
                            "id": 111183,
                            "name": "Alleghany County",
                            "latitude": "36.49134000",
                            "longitude": "-81.12719000"
                        },
                        {
                            "id": 111328,
                            "name": "Andrews",
                            "latitude": "35.20175000",
                            "longitude": "-83.82407000"
                        },
                        {
                            "id": 111338,
                            "name": "Angier",
                            "latitude": "35.50710000",
                            "longitude": "-78.73918000"
                        },
                        {
                            "id": 111361,
                            "name": "Anson County",
                            "latitude": "34.97383000",
                            "longitude": "-80.10273000"
                        },
                        {
                            "id": 111384,
                            "name": "Apex",
                            "latitude": "35.73265000",
                            "longitude": "-78.85029000"
                        },
                        {
                            "id": 111428,
                            "name": "Archdale",
                            "latitude": "35.91458000",
                            "longitude": "-79.97198000"
                        },
                        {
                            "id": 111432,
                            "name": "Archer Lodge",
                            "latitude": "35.69404000",
                            "longitude": "-78.37556000"
                        },
                        {
                            "id": 111519,
                            "name": "Ashe County",
                            "latitude": "36.43416000",
                            "longitude": "-81.50034000"
                        },
                        {
                            "id": 111520,
                            "name": "Asheboro",
                            "latitude": "35.70791000",
                            "longitude": "-79.81364000"
                        },
                        {
                            "id": 111522,
                            "name": "Asheville",
                            "latitude": "35.60095000",
                            "longitude": "-82.55402000"
                        },
                        {
                            "id": 111596,
                            "name": "Atlantic Beach",
                            "latitude": "34.69905000",
                            "longitude": "-76.74021000"
                        },
                        {
                            "id": 111681,
                            "name": "Avery County",
                            "latitude": "36.07661000",
                            "longitude": "-81.92247000"
                        },
                        {
                            "id": 111682,
                            "name": "Avery Creek",
                            "latitude": "35.46345000",
                            "longitude": "-82.58262000"
                        },
                        {
                            "id": 111707,
                            "name": "Ayden",
                            "latitude": "35.47266000",
                            "longitude": "-77.41552000"
                        },
                        {
                            "id": 111725,
                            "name": "Badin",
                            "latitude": "35.40597000",
                            "longitude": "-80.11672000"
                        },
                        {
                            "id": 111746,
                            "name": "Bakersville",
                            "latitude": "36.01567000",
                            "longitude": "-82.15874000"
                        },
                        {
                            "id": 111767,
                            "name": "Balfour",
                            "latitude": "35.34651000",
                            "longitude": "-82.47206000"
                        },
                        {
                            "id": 111801,
                            "name": "Banner Elk",
                            "latitude": "36.16318000",
                            "longitude": "-81.87150000"
                        },
                        {
                            "id": 111823,
                            "name": "Barker Heights",
                            "latitude": "35.31123000",
                            "longitude": "-82.44401000"
                        },
                        {
                            "id": 111939,
                            "name": "Bayboro",
                            "latitude": "35.14294000",
                            "longitude": "-76.77021000"
                        },
                        {
                            "id": 111953,
                            "name": "Bayshore",
                            "latitude": "34.28961000",
                            "longitude": "-77.78748000"
                        },
                        {
                            "id": 111985,
                            "name": "Beaufort",
                            "latitude": "34.71822000",
                            "longitude": "-76.66382000"
                        },
                        {
                            "id": 111987,
                            "name": "Beaufort County",
                            "latitude": "35.48583000",
                            "longitude": "-76.84516000"
                        },
                        {
                            "id": 112063,
                            "name": "Belhaven",
                            "latitude": "35.54017000",
                            "longitude": "-76.62299000"
                        },
                        {
                            "id": 112136,
                            "name": "Belmont",
                            "latitude": "35.24292000",
                            "longitude": "-81.03730000"
                        },
                        {
                            "id": 112157,
                            "name": "Belville",
                            "latitude": "34.23073000",
                            "longitude": "-77.96582000"
                        },
                        {
                            "id": 112182,
                            "name": "Benson",
                            "latitude": "35.38211000",
                            "longitude": "-78.54862000"
                        },
                        {
                            "id": 112189,
                            "name": "Bent Creek",
                            "latitude": "35.50900000",
                            "longitude": "-82.60790000"
                        },
                        {
                            "id": 112241,
                            "name": "Bermuda Run",
                            "latitude": "36.00375000",
                            "longitude": "-80.42200000"
                        },
                        {
                            "id": 112258,
                            "name": "Bertie County",
                            "latitude": "36.06556000",
                            "longitude": "-76.96660000"
                        },
                        {
                            "id": 112270,
                            "name": "Bessemer City",
                            "latitude": "35.28486000",
                            "longitude": "-81.28397000"
                        },
                        {
                            "id": 112278,
                            "name": "Bethel",
                            "latitude": "35.80710000",
                            "longitude": "-77.37886000"
                        },
                        {
                            "id": 112289,
                            "name": "Bethlehem",
                            "latitude": "35.82569000",
                            "longitude": "-81.30703000"
                        },
                        {
                            "id": 112297,
                            "name": "Beulaville",
                            "latitude": "34.92378000",
                            "longitude": "-77.77387000"
                        },
                        {
                            "id": 112348,
                            "name": "Biltmore Forest",
                            "latitude": "35.53372000",
                            "longitude": "-82.52846000"
                        },
                        {
                            "id": 112362,
                            "name": "Biscoe",
                            "latitude": "35.35986000",
                            "longitude": "-79.77976000"
                        },
                        {
                            "id": 112380,
                            "name": "Black Mountain",
                            "latitude": "35.61790000",
                            "longitude": "-82.32123000"
                        },
                        {
                            "id": 112398,
                            "name": "Bladen County",
                            "latitude": "34.61431000",
                            "longitude": "-78.56318000"
                        },
                        {
                            "id": 112399,
                            "name": "Bladenboro",
                            "latitude": "34.53878000",
                            "longitude": "-78.78752000"
                        },
                        {
                            "id": 112462,
                            "name": "Blowing Rock",
                            "latitude": "36.13513000",
                            "longitude": "-81.67761000"
                        },
                        {
                            "id": 112504,
                            "name": "Boiling Spring Lakes",
                            "latitude": "34.03045000",
                            "longitude": "-78.06721000"
                        },
                        {
                            "id": 112505,
                            "name": "Boiling Springs",
                            "latitude": "35.25429000",
                            "longitude": "-81.66704000"
                        },
                        {
                            "id": 112523,
                            "name": "Bolivia",
                            "latitude": "34.06767000",
                            "longitude": "-78.14833000"
                        },
                        {
                            "id": 112552,
                            "name": "Boone",
                            "latitude": "36.21679000",
                            "longitude": "-81.67455000"
                        },
                        {
                            "id": 112569,
                            "name": "Boonville",
                            "latitude": "36.23264000",
                            "longitude": "-80.70812000"
                        },
                        {
                            "id": 112737,
                            "name": "Brevard",
                            "latitude": "35.23345000",
                            "longitude": "-82.73429000"
                        },
                        {
                            "id": 112753,
                            "name": "Brices Creek",
                            "latitude": "35.05599000",
                            "longitude": "-77.08773000"
                        },
                        {
                            "id": 112821,
                            "name": "Broad Creek",
                            "latitude": "34.72072000",
                            "longitude": "-76.93633000"
                        },
                        {
                            "id": 112830,
                            "name": "Broadway",
                            "latitude": "35.45793000",
                            "longitude": "-79.05308000"
                        },
                        {
                            "id": 112840,
                            "name": "Brogden",
                            "latitude": "35.29266000",
                            "longitude": "-78.03443000"
                        },
                        {
                            "id": 112949,
                            "name": "Brunswick",
                            "latitude": "34.28684000",
                            "longitude": "-78.70113000"
                        },
                        {
                            "id": 112951,
                            "name": "Brunswick County",
                            "latitude": "34.03897000",
                            "longitude": "-78.22728000"
                        },
                        {
                            "id": 112968,
                            "name": "Bryson City",
                            "latitude": "35.43127000",
                            "longitude": "-83.44944000"
                        },
                        {
                            "id": 113025,
                            "name": "Buies Creek",
                            "latitude": "35.41322000",
                            "longitude": "-78.73557000"
                        },
                        {
                            "id": 113038,
                            "name": "Buncombe County",
                            "latitude": "35.61122000",
                            "longitude": "-82.53010000"
                        },
                        {
                            "id": 113050,
                            "name": "Burgaw",
                            "latitude": "34.55211000",
                            "longitude": "-77.92610000"
                        },
                        {
                            "id": 113057,
                            "name": "Burke County",
                            "latitude": "35.74952000",
                            "longitude": "-81.70470000"
                        },
                        {
                            "id": 113068,
                            "name": "Burlington",
                            "latitude": "36.09569000",
                            "longitude": "-79.43780000"
                        },
                        {
                            "id": 113090,
                            "name": "Burnsville",
                            "latitude": "35.91734000",
                            "longitude": "-82.30096000"
                        },
                        {
                            "id": 113121,
                            "name": "Butner",
                            "latitude": "36.13209000",
                            "longitude": "-78.75667000"
                        },
                        {
                            "id": 113131,
                            "name": "Buxton",
                            "latitude": "35.26768000",
                            "longitude": "-75.54237000"
                        },
                        {
                            "id": 113149,
                            "name": "Cabarrus County",
                            "latitude": "35.38687000",
                            "longitude": "-80.55204000"
                        },
                        {
                            "id": 113172,
                            "name": "Cajahs Mountain",
                            "latitude": "35.83485000",
                            "longitude": "-81.54148000"
                        },
                        {
                            "id": 113174,
                            "name": "Calabash",
                            "latitude": "33.89073000",
                            "longitude": "-78.56834000"
                        },
                        {
                            "id": 113185,
                            "name": "Caldwell County",
                            "latitude": "35.95297000",
                            "longitude": "-81.54655000"
                        },
                        {
                            "id": 113262,
                            "name": "Camden",
                            "latitude": "36.32849000",
                            "longitude": "-76.17188000"
                        },
                        {
                            "id": 113270,
                            "name": "Camden County",
                            "latitude": "36.34145000",
                            "longitude": "-76.16112000"
                        },
                        {
                            "id": 113341,
                            "name": "Canton",
                            "latitude": "35.53288000",
                            "longitude": "-82.83736000"
                        },
                        {
                            "id": 113364,
                            "name": "Cape Carteret",
                            "latitude": "34.69155000",
                            "longitude": "-77.06300000"
                        },
                        {
                            "id": 113435,
                            "name": "Carolina Beach",
                            "latitude": "34.03517000",
                            "longitude": "-77.89360000"
                        },
                        {
                            "id": 113436,
                            "name": "Carolina Shores",
                            "latitude": "33.90101000",
                            "longitude": "-78.58057000"
                        },
                        {
                            "id": 113442,
                            "name": "Carrboro",
                            "latitude": "35.91014000",
                            "longitude": "-79.07529000"
                        },
                        {
                            "id": 113487,
                            "name": "Carteret County",
                            "latitude": "34.86401000",
                            "longitude": "-76.53249000"
                        },
                        {
                            "id": 113493,
                            "name": "Carthage",
                            "latitude": "35.34599000",
                            "longitude": "-79.41697000"
                        },
                        {
                            "id": 113505,
                            "name": "Cary",
                            "latitude": "35.79154000",
                            "longitude": "-78.78112000"
                        },
                        {
                            "id": 113544,
                            "name": "Castle Hayne",
                            "latitude": "34.35572000",
                            "longitude": "-77.89999000"
                        },
                        {
                            "id": 113560,
                            "name": "Caswell County",
                            "latitude": "36.39335000",
                            "longitude": "-79.33359000"
                        },
                        {
                            "id": 113567,
                            "name": "Catawba County",
                            "latitude": "35.66261000",
                            "longitude": "-81.21448000"
                        },
                        {
                            "id": 113620,
                            "name": "Cedar Point",
                            "latitude": "34.68766000",
                            "longitude": "-77.07245000"
                        },
                        {
                            "id": 113706,
                            "name": "Chadbourn",
                            "latitude": "34.32211000",
                            "longitude": "-78.82697000"
                        },
                        {
                            "id": 113741,
                            "name": "Chapel Hill",
                            "latitude": "35.91320000",
                            "longitude": "-79.05584000"
                        },
                        {
                            "id": 113776,
                            "name": "Charlotte",
                            "latitude": "35.22709000",
                            "longitude": "-80.84313000"
                        },
                        {
                            "id": 113802,
                            "name": "Chatham County",
                            "latitude": "35.70258000",
                            "longitude": "-79.25535000"
                        },
                        {
                            "id": 113846,
                            "name": "Cherokee",
                            "latitude": "35.47427000",
                            "longitude": "-83.31487000"
                        },
                        {
                            "id": 113852,
                            "name": "Cherokee County",
                            "latitude": "35.13384000",
                            "longitude": "-84.06347000"
                        },
                        {
                            "id": 113870,
                            "name": "Cherryville",
                            "latitude": "35.37874000",
                            "longitude": "-81.37897000"
                        },
                        {
                            "id": 113961,
                            "name": "China Grove",
                            "latitude": "35.56931000",
                            "longitude": "-80.58173000"
                        },
                        {
                            "id": 113992,
                            "name": "Chowan County",
                            "latitude": "36.12656000",
                            "longitude": "-76.60216000"
                        },
                        {
                            "id": 114104,
                            "name": "Claremont",
                            "latitude": "35.71458000",
                            "longitude": "-81.14619000"
                        },
                        {
                            "id": 114183,
                            "name": "Clay County",
                            "latitude": "35.05719000",
                            "longitude": "-83.75021000"
                        },
                        {
                            "id": 114200,
                            "name": "Clayton",
                            "latitude": "35.65071000",
                            "longitude": "-78.45639000"
                        },
                        {
                            "id": 114233,
                            "name": "Clemmons",
                            "latitude": "36.02153000",
                            "longitude": "-80.38200000"
                        },
                        {
                            "id": 114249,
                            "name": "Cleveland County",
                            "latitude": "35.33411000",
                            "longitude": "-81.55561000"
                        },
                        {
                            "id": 114273,
                            "name": "Clinton",
                            "latitude": "34.99795000",
                            "longitude": "-78.32333000"
                        },
                        {
                            "id": 114318,
                            "name": "Clyde",
                            "latitude": "35.53344000",
                            "longitude": "-82.91069000"
                        },
                        {
                            "id": 114342,
                            "name": "Coats",
                            "latitude": "35.40794000",
                            "longitude": "-78.67196000"
                        },
                        {
                            "id": 114470,
                            "name": "Columbia",
                            "latitude": "35.91766000",
                            "longitude": "-76.25215000"
                        },
                        {
                            "id": 114493,
                            "name": "Columbus",
                            "latitude": "35.25317000",
                            "longitude": "-82.19706000"
                        },
                        {
                            "id": 114502,
                            "name": "Columbus County",
                            "latitude": "34.26540000",
                            "longitude": "-78.65507000"
                        },
                        {
                            "id": 114536,
                            "name": "Concord",
                            "latitude": "35.40888000",
                            "longitude": "-80.58158000"
                        },
                        {
                            "id": 114563,
                            "name": "Connelly Springs",
                            "latitude": "35.74291000",
                            "longitude": "-81.51343000"
                        },
                        {
                            "id": 114567,
                            "name": "Conover",
                            "latitude": "35.70652000",
                            "longitude": "-81.21869000"
                        },
                        {
                            "id": 114639,
                            "name": "Cordova",
                            "latitude": "34.91293000",
                            "longitude": "-79.82200000"
                        },
                        {
                            "id": 114647,
                            "name": "Cornelius",
                            "latitude": "35.48680000",
                            "longitude": "-80.86007000"
                        },
                        {
                            "id": 114730,
                            "name": "Cove Creek",
                            "latitude": "35.60649000",
                            "longitude": "-83.01125000"
                        },
                        {
                            "id": 114762,
                            "name": "Cramerton",
                            "latitude": "35.23875000",
                            "longitude": "-81.07508000"
                        },
                        {
                            "id": 114773,
                            "name": "Craven County",
                            "latitude": "35.11722000",
                            "longitude": "-77.08263000"
                        },
                        {
                            "id": 114788,
                            "name": "Creedmoor",
                            "latitude": "36.12237000",
                            "longitude": "-78.68611000"
                        },
                        {
                            "id": 114819,
                            "name": "Cricket",
                            "latitude": "36.17152000",
                            "longitude": "-81.19398000"
                        },
                        {
                            "id": 114898,
                            "name": "Cullowhee",
                            "latitude": "35.31371000",
                            "longitude": "-83.17653000"
                        },
                        {
                            "id": 114914,
                            "name": "Cumberland County",
                            "latitude": "35.04859000",
                            "longitude": "-78.82744000"
                        },
                        {
                            "id": 114925,
                            "name": "Currituck",
                            "latitude": "36.44988000",
                            "longitude": "-76.01548000"
                        },
                        {
                            "id": 114926,
                            "name": "Currituck County",
                            "latitude": "36.36724000",
                            "longitude": "-75.93683000"
                        },
                        {
                            "id": 114989,
                            "name": "Dallas",
                            "latitude": "35.31653000",
                            "longitude": "-81.17619000"
                        },
                        {
                            "id": 115011,
                            "name": "Dana",
                            "latitude": "35.32928000",
                            "longitude": "-82.37540000"
                        },
                        {
                            "id": 115013,
                            "name": "Danbury",
                            "latitude": "36.40930000",
                            "longitude": "-80.20588000"
                        },
                        {
                            "id": 115044,
                            "name": "Dare County",
                            "latitude": "35.78663000",
                            "longitude": "-75.78094000"
                        },
                        {
                            "id": 115062,
                            "name": "Davidson",
                            "latitude": "35.50233000",
                            "longitude": "-80.83912000"
                        },
                        {
                            "id": 115063,
                            "name": "Davidson County",
                            "latitude": "35.79328000",
                            "longitude": "-80.21269000"
                        },
                        {
                            "id": 115068,
                            "name": "Davie County",
                            "latitude": "35.92916000",
                            "longitude": "-80.54447000"
                        },
                        {
                            "id": 115246,
                            "name": "Denton",
                            "latitude": "35.63347000",
                            "longitude": "-80.11588000"
                        },
                        {
                            "id": 115250,
                            "name": "Denver",
                            "latitude": "35.53125000",
                            "longitude": "-81.02980000"
                        },
                        {
                            "id": 115371,
                            "name": "Dobson",
                            "latitude": "36.39569000",
                            "longitude": "-80.72257000"
                        },
                        {
                            "id": 115475,
                            "name": "Drexel",
                            "latitude": "35.75791000",
                            "longitude": "-81.60426000"
                        },
                        {
                            "id": 115546,
                            "name": "Dunn",
                            "latitude": "35.30627000",
                            "longitude": "-78.60890000"
                        },
                        {
                            "id": 115556,
                            "name": "Duplin County",
                            "latitude": "34.93628000",
                            "longitude": "-77.93294000"
                        },
                        {
                            "id": 115569,
                            "name": "Durham",
                            "latitude": "35.99403000",
                            "longitude": "-78.89862000"
                        },
                        {
                            "id": 115575,
                            "name": "Durham County",
                            "latitude": "36.03600000",
                            "longitude": "-78.87632000"
                        },
                        {
                            "id": 115660,
                            "name": "East Flat Rock",
                            "latitude": "35.28012000",
                            "longitude": "-82.42206000"
                        },
                        {
                            "id": 115751,
                            "name": "East Rockingham",
                            "latitude": "34.91821000",
                            "longitude": "-79.76256000"
                        },
                        {
                            "id": 115760,
                            "name": "East Spencer",
                            "latitude": "35.68181000",
                            "longitude": "-80.43228000"
                        },
                        {
                            "id": 115792,
                            "name": "Eastover",
                            "latitude": "35.10000000",
                            "longitude": "-78.80000000"
                        },
                        {
                            "id": 115828,
                            "name": "Eden",
                            "latitude": "36.48847000",
                            "longitude": "-79.76670000"
                        },
                        {
                            "id": 115834,
                            "name": "Edenton",
                            "latitude": "36.05794000",
                            "longitude": "-76.60772000"
                        },
                        {
                            "id": 115841,
                            "name": "Edgecombe County",
                            "latitude": "35.91298000",
                            "longitude": "-77.59707000"
                        },
                        {
                            "id": 115885,
                            "name": "Edneyville",
                            "latitude": "35.39401000",
                            "longitude": "-82.34095000"
                        },
                        {
                            "id": 115975,
                            "name": "Elizabeth City",
                            "latitude": "36.29460000",
                            "longitude": "-76.25105000"
                        },
                        {
                            "id": 115979,
                            "name": "Elizabethtown",
                            "latitude": "34.62934000",
                            "longitude": "-78.60529000"
                        },
                        {
                            "id": 116004,
                            "name": "Elkin",
                            "latitude": "36.24430000",
                            "longitude": "-80.84840000"
                        },
                        {
                            "id": 116021,
                            "name": "Ellerbe",
                            "latitude": "35.07126000",
                            "longitude": "-79.76144000"
                        },
                        {
                            "id": 116042,
                            "name": "Elm City",
                            "latitude": "35.80655000",
                            "longitude": "-77.86332000"
                        },
                        {
                            "id": 116064,
                            "name": "Elon",
                            "latitude": "36.10291000",
                            "longitude": "-79.50669000"
                        },
                        {
                            "id": 116066,
                            "name": "Elroy",
                            "latitude": "35.34266000",
                            "longitude": "-77.90859000"
                        },
                        {
                            "id": 116089,
                            "name": "Emerald Isle",
                            "latitude": "34.67794000",
                            "longitude": "-76.95078000"
                        },
                        {
                            "id": 116124,
                            "name": "Enfield",
                            "latitude": "36.18099000",
                            "longitude": "-77.66664000"
                        },
                        {
                            "id": 116143,
                            "name": "Enochville",
                            "latitude": "35.52986000",
                            "longitude": "-80.66812000"
                        },
                        {
                            "id": 116175,
                            "name": "Erwin",
                            "latitude": "35.32683000",
                            "longitude": "-78.67613000"
                        },
                        {
                            "id": 116217,
                            "name": "Etowah",
                            "latitude": "35.31762000",
                            "longitude": "-82.59429000"
                        },
                        {
                            "id": 116322,
                            "name": "Fairfield Harbour",
                            "latitude": "35.07655000",
                            "longitude": "-76.96356000"
                        },
                        {
                            "id": 116334,
                            "name": "Fairmont",
                            "latitude": "34.49683000",
                            "longitude": "-79.11420000"
                        },
                        {
                            "id": 116343,
                            "name": "Fairplains",
                            "latitude": "36.19847000",
                            "longitude": "-81.15286000"
                        },
                        {
                            "id": 116348,
                            "name": "Fairview",
                            "latitude": "35.51401000",
                            "longitude": "-82.39595000"
                        },
                        {
                            "id": 116426,
                            "name": "Farmville",
                            "latitude": "35.59544000",
                            "longitude": "-77.58525000"
                        },
                        {
                            "id": 116456,
                            "name": "Fayetteville",
                            "latitude": "35.05266000",
                            "longitude": "-78.87836000"
                        },
                        {
                            "id": 116461,
                            "name": "Fearrington Village",
                            "latitude": "35.80376000",
                            "longitude": "-79.09029000"
                        },
                        {
                            "id": 116551,
                            "name": "Flat Rock",
                            "latitude": "35.27123000",
                            "longitude": "-82.44151000"
                        },
                        {
                            "id": 116564,
                            "name": "Fletcher",
                            "latitude": "35.43067000",
                            "longitude": "-82.50123000"
                        },
                        {
                            "id": 116647,
                            "name": "Forest City",
                            "latitude": "35.33401000",
                            "longitude": "-81.86510000"
                        },
                        {
                            "id": 116663,
                            "name": "Forest Oaks",
                            "latitude": "35.98819000",
                            "longitude": "-79.70614000"
                        },
                        {
                            "id": 116687,
                            "name": "Forsyth County",
                            "latitude": "36.13049000",
                            "longitude": "-80.25636000"
                        },
                        {
                            "id": 116696,
                            "name": "Fort Bragg",
                            "latitude": "35.13900000",
                            "longitude": "-79.00603000"
                        },
                        {
                            "id": 116771,
                            "name": "Foscoe",
                            "latitude": "36.16179000",
                            "longitude": "-81.76566000"
                        },
                        {
                            "id": 116792,
                            "name": "Four Oaks",
                            "latitude": "35.44488000",
                            "longitude": "-78.42695000"
                        },
                        {
                            "id": 116828,
                            "name": "Franklin",
                            "latitude": "35.18232000",
                            "longitude": "-83.38154000"
                        },
                        {
                            "id": 116853,
                            "name": "Franklin County",
                            "latitude": "36.08279000",
                            "longitude": "-78.28561000"
                        },
                        {
                            "id": 116874,
                            "name": "Franklinton",
                            "latitude": "36.10182000",
                            "longitude": "-78.45805000"
                        },
                        {
                            "id": 116875,
                            "name": "Franklinville",
                            "latitude": "35.74375000",
                            "longitude": "-79.69225000"
                        },
                        {
                            "id": 116919,
                            "name": "Fremont",
                            "latitude": "35.54544000",
                            "longitude": "-77.97471000"
                        },
                        {
                            "id": 116971,
                            "name": "Fruitland",
                            "latitude": "35.39651000",
                            "longitude": "-82.39317000"
                        },
                        {
                            "id": 117000,
                            "name": "Fuquay-Varina",
                            "latitude": "35.58432000",
                            "longitude": "-78.80001000"
                        },
                        {
                            "id": 117046,
                            "name": "Gamewell",
                            "latitude": "35.86930000",
                            "longitude": "-81.59621000"
                        },
                        {
                            "id": 117092,
                            "name": "Garner",
                            "latitude": "35.71126000",
                            "longitude": "-78.61417000"
                        },
                        {
                            "id": 117113,
                            "name": "Gaston",
                            "latitude": "36.50043000",
                            "longitude": "-77.64498000"
                        },
                        {
                            "id": 117115,
                            "name": "Gaston County",
                            "latitude": "35.29437000",
                            "longitude": "-81.18025000"
                        },
                        {
                            "id": 117116,
                            "name": "Gastonia",
                            "latitude": "35.26208000",
                            "longitude": "-81.18730000"
                        },
                        {
                            "id": 117119,
                            "name": "Gates County",
                            "latitude": "36.44489000",
                            "longitude": "-76.70049000"
                        },
                        {
                            "id": 117121,
                            "name": "Gatesville",
                            "latitude": "36.40349000",
                            "longitude": "-76.75301000"
                        },
                        {
                            "id": 117194,
                            "name": "Gibsonville",
                            "latitude": "36.10569000",
                            "longitude": "-79.54225000"
                        },
                        {
                            "id": 117260,
                            "name": "Glen Alpine",
                            "latitude": "35.72902000",
                            "longitude": "-81.77927000"
                        },
                        {
                            "id": 117270,
                            "name": "Glen Raven",
                            "latitude": "36.11319000",
                            "longitude": "-79.47641000"
                        },
                        {
                            "id": 117364,
                            "name": "Goldsboro",
                            "latitude": "35.38488000",
                            "longitude": "-77.99277000"
                        },
                        {
                            "id": 117405,
                            "name": "Gorman",
                            "latitude": "36.03653000",
                            "longitude": "-78.82334000"
                        },
                        {
                            "id": 117434,
                            "name": "Graham",
                            "latitude": "36.06903000",
                            "longitude": "-79.40058000"
                        },
                        {
                            "id": 117438,
                            "name": "Graham County",
                            "latitude": "35.35016000",
                            "longitude": "-83.83356000"
                        },
                        {
                            "id": 117496,
                            "name": "Granite Falls",
                            "latitude": "35.79652000",
                            "longitude": "-81.43065000"
                        },
                        {
                            "id": 117500,
                            "name": "Granite Quarry",
                            "latitude": "35.61236000",
                            "longitude": "-80.44673000"
                        },
                        {
                            "id": 117537,
                            "name": "Granville County",
                            "latitude": "36.30402000",
                            "longitude": "-78.65302000"
                        },
                        {
                            "id": 117603,
                            "name": "Green Level",
                            "latitude": "36.12097000",
                            "longitude": "-79.34419000"
                        },
                        {
                            "id": 117638,
                            "name": "Greene County",
                            "latitude": "35.48541000",
                            "longitude": "-77.67587000"
                        },
                        {
                            "id": 117667,
                            "name": "Greensboro",
                            "latitude": "36.07264000",
                            "longitude": "-79.79198000"
                        },
                        {
                            "id": 117687,
                            "name": "Greenville",
                            "latitude": "35.61266000",
                            "longitude": "-77.36635000"
                        },
                        {
                            "id": 117733,
                            "name": "Grifton",
                            "latitude": "35.37266000",
                            "longitude": "-77.43746000"
                        },
                        {
                            "id": 117787,
                            "name": "Guilford County",
                            "latitude": "36.07945000",
                            "longitude": "-79.78901000"
                        },
                        {
                            "id": 117855,
                            "name": "Half Moon",
                            "latitude": "34.82600000",
                            "longitude": "-77.45941000"
                        },
                        {
                            "id": 117859,
                            "name": "Halifax",
                            "latitude": "36.32849000",
                            "longitude": "-77.58942000"
                        },
                        {
                            "id": 117862,
                            "name": "Halifax County",
                            "latitude": "36.25750000",
                            "longitude": "-77.65188000"
                        },
                        {
                            "id": 117907,
                            "name": "Hamlet",
                            "latitude": "34.88488000",
                            "longitude": "-79.69422000"
                        },
                        {
                            "id": 117924,
                            "name": "Hampstead",
                            "latitude": "34.36767000",
                            "longitude": "-77.71053000"
                        },
                        {
                            "id": 118014,
                            "name": "Harkers Island",
                            "latitude": "34.69516000",
                            "longitude": "-76.55937000"
                        },
                        {
                            "id": 118029,
                            "name": "Harnett County",
                            "latitude": "35.36860000",
                            "longitude": "-78.86931000"
                        },
                        {
                            "id": 118049,
                            "name": "Harrisburg",
                            "latitude": "35.32395000",
                            "longitude": "-80.65784000"
                        },
                        {
                            "id": 118147,
                            "name": "Havelock",
                            "latitude": "34.87905000",
                            "longitude": "-76.90133000"
                        },
                        {
                            "id": 118156,
                            "name": "Haw River",
                            "latitude": "36.09153000",
                            "longitude": "-79.36419000"
                        },
                        {
                            "id": 118182,
                            "name": "Hayesville",
                            "latitude": "35.04620000",
                            "longitude": "-83.81795000"
                        },
                        {
                            "id": 118191,
                            "name": "Hays",
                            "latitude": "36.24985000",
                            "longitude": "-81.11564000"
                        },
                        {
                            "id": 118198,
                            "name": "Haywood County",
                            "latitude": "35.55605000",
                            "longitude": "-82.98224000"
                        },
                        {
                            "id": 118208,
                            "name": "Hazelwood",
                            "latitude": "35.46871000",
                            "longitude": "-83.00403000"
                        },
                        {
                            "id": 118263,
                            "name": "Hemby Bridge",
                            "latitude": "35.10371000",
                            "longitude": "-80.62798000"
                        },
                        {
                            "id": 118276,
                            "name": "Henderson",
                            "latitude": "36.32959000",
                            "longitude": "-78.39916000"
                        },
                        {
                            "id": 118281,
                            "name": "Henderson County",
                            "latitude": "35.33629000",
                            "longitude": "-82.47991000"
                        },
                        {
                            "id": 118285,
                            "name": "Hendersonville",
                            "latitude": "35.31873000",
                            "longitude": "-82.46095000"
                        },
                        {
                            "id": 118342,
                            "name": "Hertford",
                            "latitude": "36.19016000",
                            "longitude": "-76.46605000"
                        },
                        {
                            "id": 118343,
                            "name": "Hertford County",
                            "latitude": "36.35863000",
                            "longitude": "-76.98066000"
                        },
                        {
                            "id": 118365,
                            "name": "Hickory",
                            "latitude": "35.73319000",
                            "longitude": "-81.34120000"
                        },
                        {
                            "id": 118386,
                            "name": "High Point",
                            "latitude": "35.95569000",
                            "longitude": "-80.00532000"
                        },
                        {
                            "id": 118430,
                            "name": "Hildebran",
                            "latitude": "35.71402000",
                            "longitude": "-81.42203000"
                        },
                        {
                            "id": 118454,
                            "name": "Hillsborough",
                            "latitude": "36.07542000",
                            "longitude": "-79.09973000"
                        },
                        {
                            "id": 118514,
                            "name": "Hoke County",
                            "latitude": "35.01736000",
                            "longitude": "-79.23711000"
                        },
                        {
                            "id": 118560,
                            "name": "Holly Ridge",
                            "latitude": "34.49544000",
                            "longitude": "-77.55497000"
                        },
                        {
                            "id": 118562,
                            "name": "Holly Springs",
                            "latitude": "35.65127000",
                            "longitude": "-78.83362000"
                        },
                        {
                            "id": 118635,
                            "name": "Hoopers Creek",
                            "latitude": "35.43900000",
                            "longitude": "-82.46679000"
                        },
                        {
                            "id": 118645,
                            "name": "Hope Mills",
                            "latitude": "34.97044000",
                            "longitude": "-78.94531000"
                        },
                        {
                            "id": 118672,
                            "name": "Horse Shoe",
                            "latitude": "35.34317000",
                            "longitude": "-82.55651000"
                        },
                        {
                            "id": 118736,
                            "name": "Hudson",
                            "latitude": "35.84846000",
                            "longitude": "-81.49593000"
                        },
                        {
                            "id": 118792,
                            "name": "Huntersville",
                            "latitude": "35.41069000",
                            "longitude": "-80.84285000"
                        },
                        {
                            "id": 118847,
                            "name": "Hyde County",
                            "latitude": "35.41004000",
                            "longitude": "-76.14850000"
                        },
                        {
                            "id": 118865,
                            "name": "Icard",
                            "latitude": "35.72735000",
                            "longitude": "-81.47065000"
                        },
                        {
                            "id": 118920,
                            "name": "Indian Trail",
                            "latitude": "35.07681000",
                            "longitude": "-80.66924000"
                        },
                        {
                            "id": 118985,
                            "name": "Iredell County",
                            "latitude": "35.80708000",
                            "longitude": "-80.87344000"
                        },
                        {
                            "id": 119067,
                            "name": "Jackson",
                            "latitude": "36.38960000",
                            "longitude": "-77.42136000"
                        },
                        {
                            "id": 119085,
                            "name": "Jackson County",
                            "latitude": "35.28739000",
                            "longitude": "-83.14083000"
                        },
                        {
                            "id": 119103,
                            "name": "Jacksonville",
                            "latitude": "34.75405000",
                            "longitude": "-77.43024000"
                        },
                        {
                            "id": 119115,
                            "name": "James City",
                            "latitude": "35.08877000",
                            "longitude": "-77.03495000"
                        },
                        {
                            "id": 119121,
                            "name": "Jamestown",
                            "latitude": "35.99430000",
                            "longitude": "-79.93531000"
                        },
                        {
                            "id": 119165,
                            "name": "Jefferson",
                            "latitude": "36.42040000",
                            "longitude": "-81.47344000"
                        },
                        {
                            "id": 119281,
                            "name": "Johnston County",
                            "latitude": "35.51761000",
                            "longitude": "-78.36564000"
                        },
                        {
                            "id": 119294,
                            "name": "Jones County",
                            "latitude": "35.02170000",
                            "longitude": "-77.35526000"
                        },
                        {
                            "id": 119310,
                            "name": "Jonesville",
                            "latitude": "36.23930000",
                            "longitude": "-80.84452000"
                        },
                        {
                            "id": 119382,
                            "name": "Kannapolis",
                            "latitude": "35.48736000",
                            "longitude": "-80.62173000"
                        },
                        {
                            "id": 119442,
                            "name": "Kenansville",
                            "latitude": "34.96239000",
                            "longitude": "-77.96221000"
                        },
                        {
                            "id": 119459,
                            "name": "Kenly",
                            "latitude": "35.59627000",
                            "longitude": "-78.12416000"
                        },
                        {
                            "id": 119515,
                            "name": "Kernersville",
                            "latitude": "36.11986000",
                            "longitude": "-80.07365000"
                        },
                        {
                            "id": 119557,
                            "name": "Kill Devil Hills",
                            "latitude": "36.03072000",
                            "longitude": "-75.67601000"
                        },
                        {
                            "id": 119576,
                            "name": "King",
                            "latitude": "36.28069000",
                            "longitude": "-80.35922000"
                        },
                        {
                            "id": 119602,
                            "name": "Kings Grant",
                            "latitude": "34.26295000",
                            "longitude": "-77.86360000"
                        },
                        {
                            "id": 119603,
                            "name": "Kings Mountain",
                            "latitude": "35.24513000",
                            "longitude": "-81.34119000"
                        },
                        {
                            "id": 119643,
                            "name": "Kinston",
                            "latitude": "35.26266000",
                            "longitude": "-77.58164000"
                        },
                        {
                            "id": 119668,
                            "name": "Kitty Hawk",
                            "latitude": "36.06461000",
                            "longitude": "-75.70573000"
                        },
                        {
                            "id": 119674,
                            "name": "Knightdale",
                            "latitude": "35.78765000",
                            "longitude": "-78.48056000"
                        },
                        {
                            "id": 119726,
                            "name": "Kure Beach",
                            "latitude": "33.99684000",
                            "longitude": "-77.90721000"
                        },
                        {
                            "id": 119750,
                            "name": "La Grange",
                            "latitude": "35.30683000",
                            "longitude": "-77.78803000"
                        },
                        {
                            "id": 119925,
                            "name": "Lake Junaluska",
                            "latitude": "35.52788000",
                            "longitude": "-82.95958000"
                        },
                        {
                            "id": 119934,
                            "name": "Lake Lure",
                            "latitude": "35.42790000",
                            "longitude": "-82.20483000"
                        },
                        {
                            "id": 119952,
                            "name": "Lake Norman of Catawba",
                            "latitude": "35.58680000",
                            "longitude": "-80.95952000"
                        },
                        {
                            "id": 119960,
                            "name": "Lake Park",
                            "latitude": "35.08626000",
                            "longitude": "-80.63507000"
                        },
                        {
                            "id": 119990,
                            "name": "Lake Waccamaw",
                            "latitude": "34.31906000",
                            "longitude": "-78.50001000"
                        },
                        {
                            "id": 120099,
                            "name": "Landis",
                            "latitude": "35.54569000",
                            "longitude": "-80.61090000"
                        },
                        {
                            "id": 120196,
                            "name": "Laurel Hill",
                            "latitude": "34.80905000",
                            "longitude": "-79.54783000"
                        },
                        {
                            "id": 120200,
                            "name": "Laurel Park",
                            "latitude": "35.31373000",
                            "longitude": "-82.49345000"
                        },
                        {
                            "id": 120210,
                            "name": "Laurinburg",
                            "latitude": "34.77405000",
                            "longitude": "-79.46282000"
                        },
                        {
                            "id": 120312,
                            "name": "Lee County",
                            "latitude": "35.47517000",
                            "longitude": "-79.17143000"
                        },
                        {
                            "id": 120350,
                            "name": "Leland",
                            "latitude": "34.25628000",
                            "longitude": "-78.04471000"
                        },
                        {
                            "id": 120372,
                            "name": "Lenoir",
                            "latitude": "35.91402000",
                            "longitude": "-81.53898000"
                        },
                        {
                            "id": 120374,
                            "name": "Lenoir County",
                            "latitude": "35.23915000",
                            "longitude": "-77.64127000"
                        },
                        {
                            "id": 120435,
                            "name": "Lewisville",
                            "latitude": "36.09708000",
                            "longitude": "-80.41922000"
                        },
                        {
                            "id": 120442,
                            "name": "Lexington",
                            "latitude": "35.82403000",
                            "longitude": "-80.25338000"
                        },
                        {
                            "id": 120462,
                            "name": "Liberty",
                            "latitude": "35.85347000",
                            "longitude": "-79.57169000"
                        },
                        {
                            "id": 120486,
                            "name": "Lillington",
                            "latitude": "35.39933000",
                            "longitude": "-78.81585000"
                        },
                        {
                            "id": 120522,
                            "name": "Lincoln County",
                            "latitude": "35.48618000",
                            "longitude": "-81.22387000"
                        },
                        {
                            "id": 120552,
                            "name": "Lincolnton",
                            "latitude": "35.47375000",
                            "longitude": "-81.25453000"
                        },
                        {
                            "id": 120684,
                            "name": "Locust",
                            "latitude": "35.25987000",
                            "longitude": "-80.42534000"
                        },
                        {
                            "id": 120734,
                            "name": "Long Beach",
                            "latitude": "33.91045000",
                            "longitude": "-78.11777000"
                        },
                        {
                            "id": 120756,
                            "name": "Longview",
                            "latitude": "35.72930000",
                            "longitude": "-81.38342000"
                        },
                        {
                            "id": 120809,
                            "name": "Louisburg",
                            "latitude": "36.09904000",
                            "longitude": "-78.30111000"
                        },
                        {
                            "id": 120834,
                            "name": "Lowell",
                            "latitude": "35.26792000",
                            "longitude": "-81.10285000"
                        },
                        {
                            "id": 120845,
                            "name": "Lowesville",
                            "latitude": "35.41708000",
                            "longitude": "-81.01119000"
                        },
                        {
                            "id": 120859,
                            "name": "Lucama",
                            "latitude": "35.64544000",
                            "longitude": "-78.00971000"
                        },
                        {
                            "id": 120880,
                            "name": "Lumberton",
                            "latitude": "34.61834000",
                            "longitude": "-79.01045000"
                        },
                        {
                            "id": 120971,
                            "name": "Macon County",
                            "latitude": "35.15038000",
                            "longitude": "-83.42210000"
                        },
                        {
                            "id": 120990,
                            "name": "Madison",
                            "latitude": "36.38542000",
                            "longitude": "-79.95949000"
                        },
                        {
                            "id": 121010,
                            "name": "Madison County",
                            "latitude": "35.85809000",
                            "longitude": "-82.70576000"
                        },
                        {
                            "id": 121033,
                            "name": "Maggie Valley",
                            "latitude": "35.51816000",
                            "longitude": "-83.09764000"
                        },
                        {
                            "id": 121049,
                            "name": "Maiden",
                            "latitude": "35.57569000",
                            "longitude": "-81.21175000"
                        },
                        {
                            "id": 121156,
                            "name": "Manteo",
                            "latitude": "35.90823000",
                            "longitude": "-75.67573000"
                        },
                        {
                            "id": 121182,
                            "name": "Mar-Mac",
                            "latitude": "35.33488000",
                            "longitude": "-78.05582000"
                        },
                        {
                            "id": 121236,
                            "name": "Marion",
                            "latitude": "35.68401000",
                            "longitude": "-82.00927000"
                        },
                        {
                            "id": 121299,
                            "name": "Mars Hill",
                            "latitude": "35.82650000",
                            "longitude": "-82.54930000"
                        },
                        {
                            "id": 121304,
                            "name": "Marshall",
                            "latitude": "35.79733000",
                            "longitude": "-82.68403000"
                        },
                        {
                            "id": 121329,
                            "name": "Marshville",
                            "latitude": "34.98849000",
                            "longitude": "-80.36701000"
                        },
                        {
                            "id": 121340,
                            "name": "Martin County",
                            "latitude": "35.84160000",
                            "longitude": "-77.10708000"
                        },
                        {
                            "id": 121353,
                            "name": "Marvin",
                            "latitude": "34.99182000",
                            "longitude": "-80.81479000"
                        },
                        {
                            "id": 121383,
                            "name": "Masonboro",
                            "latitude": "34.17934000",
                            "longitude": "-77.84748000"
                        },
                        {
                            "id": 121412,
                            "name": "Matthews",
                            "latitude": "35.11681000",
                            "longitude": "-80.72368000"
                        },
                        {
                            "id": 121425,
                            "name": "Maury",
                            "latitude": "35.48211000",
                            "longitude": "-77.58608000"
                        },
                        {
                            "id": 121429,
                            "name": "Maxton",
                            "latitude": "34.73516000",
                            "longitude": "-79.34893000"
                        },
                        {
                            "id": 121445,
                            "name": "Mayodan",
                            "latitude": "36.41236000",
                            "longitude": "-79.96699000"
                        },
                        {
                            "id": 121451,
                            "name": "Maysville",
                            "latitude": "34.90488000",
                            "longitude": "-77.23134000"
                        },
                        {
                            "id": 121495,
                            "name": "McDowell County",
                            "latitude": "35.68124000",
                            "longitude": "-82.04870000"
                        },
                        {
                            "id": 121532,
                            "name": "McLeansville",
                            "latitude": "36.10736000",
                            "longitude": "-79.65864000"
                        },
                        {
                            "id": 121577,
                            "name": "Mebane",
                            "latitude": "36.09597000",
                            "longitude": "-79.26696000"
                        },
                        {
                            "id": 121587,
                            "name": "Mecklenburg County",
                            "latitude": "35.24671000",
                            "longitude": "-80.83276000"
                        },
                        {
                            "id": 121797,
                            "name": "Midland",
                            "latitude": "35.22737000",
                            "longitude": "-80.50062000"
                        },
                        {
                            "id": 121816,
                            "name": "Midway",
                            "latitude": "35.95347000",
                            "longitude": "-80.21810000"
                        },
                        {
                            "id": 121884,
                            "name": "Millers Creek",
                            "latitude": "36.18930000",
                            "longitude": "-81.23759000"
                        },
                        {
                            "id": 121899,
                            "name": "Mills River",
                            "latitude": "35.38845000",
                            "longitude": "-82.56679000"
                        },
                        {
                            "id": 121940,
                            "name": "Mineral Springs",
                            "latitude": "34.93793000",
                            "longitude": "-80.66868000"
                        },
                        {
                            "id": 121968,
                            "name": "Mint Hill",
                            "latitude": "35.17959000",
                            "longitude": "-80.64729000"
                        },
                        {
                            "id": 122001,
                            "name": "Mitchell County",
                            "latitude": "36.01329000",
                            "longitude": "-82.16347000"
                        },
                        {
                            "id": 122013,
                            "name": "Mocksville",
                            "latitude": "35.89403000",
                            "longitude": "-80.56145000"
                        },
                        {
                            "id": 122059,
                            "name": "Monroe",
                            "latitude": "34.98543000",
                            "longitude": "-80.54951000"
                        },
                        {
                            "id": 122148,
                            "name": "Montgomery County",
                            "latitude": "35.33246000",
                            "longitude": "-79.90547000"
                        },
                        {
                            "id": 122201,
                            "name": "Moore County",
                            "latitude": "35.31072000",
                            "longitude": "-79.48131000"
                        },
                        {
                            "id": 122210,
                            "name": "Mooresville",
                            "latitude": "35.58486000",
                            "longitude": "-80.81007000"
                        },
                        {
                            "id": 122224,
                            "name": "Moravian Falls",
                            "latitude": "36.09680000",
                            "longitude": "-81.18231000"
                        },
                        {
                            "id": 122226,
                            "name": "Morehead City",
                            "latitude": "34.72294000",
                            "longitude": "-76.72604000"
                        },
                        {
                            "id": 122250,
                            "name": "Morganton",
                            "latitude": "35.74541000",
                            "longitude": "-81.68482000"
                        },
                        {
                            "id": 122284,
                            "name": "Morrisville",
                            "latitude": "35.82348000",
                            "longitude": "-78.82556000"
                        },
                        {
                            "id": 122331,
                            "name": "Mount Airy",
                            "latitude": "36.49930000",
                            "longitude": "-80.60729000"
                        },
                        {
                            "id": 122344,
                            "name": "Mount Gilead",
                            "latitude": "35.21487000",
                            "longitude": "-80.00228000"
                        },
                        {
                            "id": 122349,
                            "name": "Mount Holly",
                            "latitude": "35.29819000",
                            "longitude": "-81.01591000"
                        },
                        {
                            "id": 122369,
                            "name": "Mount Olive",
                            "latitude": "35.19655000",
                            "longitude": "-78.06638000"
                        },
                        {
                            "id": 122374,
                            "name": "Mount Pleasant",
                            "latitude": "35.39931000",
                            "longitude": "-80.43590000"
                        },
                        {
                            "id": 122416,
                            "name": "Mountain Home",
                            "latitude": "35.36956000",
                            "longitude": "-82.49290000"
                        },
                        {
                            "id": 122430,
                            "name": "Mountain View",
                            "latitude": "35.68319000",
                            "longitude": "-81.36898000"
                        },
                        {
                            "id": 122446,
                            "name": "Moyock",
                            "latitude": "36.52460000",
                            "longitude": "-76.17827000"
                        },
                        {
                            "id": 122454,
                            "name": "Mulberry",
                            "latitude": "36.23958000",
                            "longitude": "-81.18064000"
                        },
                        {
                            "id": 122481,
                            "name": "Murfreesboro",
                            "latitude": "36.44238000",
                            "longitude": "-77.09858000"
                        },
                        {
                            "id": 122485,
                            "name": "Murphy",
                            "latitude": "35.08758000",
                            "longitude": "-84.03463000"
                        },
                        {
                            "id": 122496,
                            "name": "Murraysville",
                            "latitude": "34.29572000",
                            "longitude": "-77.84748000"
                        },
                        {
                            "id": 122523,
                            "name": "Myrtle Grove",
                            "latitude": "34.13462000",
                            "longitude": "-77.88165000"
                        },
                        {
                            "id": 122534,
                            "name": "Nags Head",
                            "latitude": "35.95739000",
                            "longitude": "-75.62406000"
                        },
                        {
                            "id": 122568,
                            "name": "Nash County",
                            "latitude": "35.96722000",
                            "longitude": "-77.98648000"
                        },
                        {
                            "id": 122576,
                            "name": "Nashville",
                            "latitude": "35.97460000",
                            "longitude": "-77.96554000"
                        },
                        {
                            "id": 122603,
                            "name": "Navassa",
                            "latitude": "34.25545000",
                            "longitude": "-78.00749000"
                        },
                        {
                            "id": 122647,
                            "name": "Neuse Forest",
                            "latitude": "34.96377000",
                            "longitude": "-76.94467000"
                        },
                        {
                            "id": 122666,
                            "name": "New Bern",
                            "latitude": "35.10849000",
                            "longitude": "-77.04411000"
                        },
                        {
                            "id": 122711,
                            "name": "New Hanover County",
                            "latitude": "34.18141000",
                            "longitude": "-77.86561000"
                        },
                        {
                            "id": 122833,
                            "name": "Newland",
                            "latitude": "36.08735000",
                            "longitude": "-81.92734000"
                        },
                        {
                            "id": 122842,
                            "name": "Newport",
                            "latitude": "34.78655000",
                            "longitude": "-76.85911000"
                        },
                        {
                            "id": 122861,
                            "name": "Newton",
                            "latitude": "35.66986000",
                            "longitude": "-81.22147000"
                        },
                        {
                            "id": 122947,
                            "name": "Norlina",
                            "latitude": "36.44570000",
                            "longitude": "-78.19833000"
                        },
                        {
                            "id": 123130,
                            "name": "North Wilkesboro",
                            "latitude": "36.15847000",
                            "longitude": "-81.14758000"
                        },
                        {
                            "id": 123136,
                            "name": "Northampton County",
                            "latitude": "36.41766000",
                            "longitude": "-77.39674000"
                        },
                        {
                            "id": 123142,
                            "name": "Northchase",
                            "latitude": "34.30782000",
                            "longitude": "-77.87749000"
                        },
                        {
                            "id": 123159,
                            "name": "Northlakes",
                            "latitude": "35.78180000",
                            "longitude": "-81.37509000"
                        },
                        {
                            "id": 123197,
                            "name": "Norwood",
                            "latitude": "35.21959000",
                            "longitude": "-80.11895000"
                        },
                        {
                            "id": 123251,
                            "name": "Oak Island",
                            "latitude": "33.91656000",
                            "longitude": "-78.16111000"
                        },
                        {
                            "id": 123261,
                            "name": "Oak Ridge",
                            "latitude": "36.17347000",
                            "longitude": "-79.98893000"
                        },
                        {
                            "id": 123267,
                            "name": "Oakboro",
                            "latitude": "35.22570000",
                            "longitude": "-80.32895000"
                        },
                        {
                            "id": 123367,
                            "name": "Ogden",
                            "latitude": "34.27239000",
                            "longitude": "-77.81859000"
                        },
                        {
                            "id": 123484,
                            "name": "Onslow County",
                            "latitude": "34.76305000",
                            "longitude": "-77.39319000"
                        },
                        {
                            "id": 123516,
                            "name": "Orange County",
                            "latitude": "36.06130000",
                            "longitude": "-79.12060000"
                        },
                        {
                            "id": 123679,
                            "name": "Oxford",
                            "latitude": "36.31070000",
                            "longitude": "-78.59083000"
                        },
                        {
                            "id": 123793,
                            "name": "Pamlico County",
                            "latitude": "35.15152000",
                            "longitude": "-76.66716000"
                        },
                        {
                            "id": 123905,
                            "name": "Pasquotank County",
                            "latitude": "36.26490000",
                            "longitude": "-76.24913000"
                        },
                        {
                            "id": 124005,
                            "name": "Pembroke",
                            "latitude": "34.68016000",
                            "longitude": "-79.19504000"
                        },
                        {
                            "id": 124015,
                            "name": "Pender County",
                            "latitude": "34.51494000",
                            "longitude": "-77.88887000"
                        },
                        {
                            "id": 124065,
                            "name": "Perquimans County",
                            "latitude": "36.17720000",
                            "longitude": "-76.40767000"
                        },
                        {
                            "id": 124092,
                            "name": "Person County",
                            "latitude": "36.39011000",
                            "longitude": "-78.97171000"
                        },
                        {
                            "id": 124200,
                            "name": "Pilot Mountain",
                            "latitude": "36.38653000",
                            "longitude": "-80.46950000"
                        },
                        {
                            "id": 124231,
                            "name": "Pine Knoll Shores",
                            "latitude": "34.69738000",
                            "longitude": "-76.81327000"
                        },
                        {
                            "id": 124236,
                            "name": "Pine Level",
                            "latitude": "35.51322000",
                            "longitude": "-78.24444000"
                        },
                        {
                            "id": 124247,
                            "name": "Pinebluff",
                            "latitude": "35.10988000",
                            "longitude": "-79.47226000"
                        },
                        {
                            "id": 124250,
                            "name": "Pinehurst",
                            "latitude": "35.19543000",
                            "longitude": "-79.46948000"
                        },
                        {
                            "id": 124258,
                            "name": "Pinetops",
                            "latitude": "35.78849000",
                            "longitude": "-77.63775000"
                        },
                        {
                            "id": 124262,
                            "name": "Pineville",
                            "latitude": "35.08320000",
                            "longitude": "-80.89230000"
                        },
                        {
                            "id": 124267,
                            "name": "Piney Green",
                            "latitude": "34.71600000",
                            "longitude": "-77.32024000"
                        },
                        {
                            "id": 124288,
                            "name": "Pitt County",
                            "latitude": "35.59352000",
                            "longitude": "-77.37465000"
                        },
                        {
                            "id": 124291,
                            "name": "Pittsboro",
                            "latitude": "35.72015000",
                            "longitude": "-79.17724000"
                        },
                        {
                            "id": 124316,
                            "name": "Plain View",
                            "latitude": "35.24850000",
                            "longitude": "-78.55529000"
                        },
                        {
                            "id": 124365,
                            "name": "Pleasant Garden",
                            "latitude": "35.96208000",
                            "longitude": "-79.76225000"
                        },
                        {
                            "id": 124369,
                            "name": "Pleasant Hill",
                            "latitude": "35.87347000",
                            "longitude": "-79.48169000"
                        },
                        {
                            "id": 124399,
                            "name": "Plymouth",
                            "latitude": "35.86683000",
                            "longitude": "-76.74856000"
                        },
                        {
                            "id": 124444,
                            "name": "Polk County",
                            "latitude": "35.27929000",
                            "longitude": "-82.16967000"
                        },
                        {
                            "id": 124452,
                            "name": "Polkton",
                            "latitude": "35.00765000",
                            "longitude": "-80.20089000"
                        },
                        {
                            "id": 124683,
                            "name": "Princeton",
                            "latitude": "35.46599000",
                            "longitude": "-78.16055000"
                        },
                        {
                            "id": 124694,
                            "name": "Princeville",
                            "latitude": "35.88960000",
                            "longitude": "-77.53219000"
                        },
                        {
                            "id": 124746,
                            "name": "Pumpkin Center",
                            "latitude": "34.79155000",
                            "longitude": "-77.37246000"
                        },
                        {
                            "id": 124819,
                            "name": "Raeford",
                            "latitude": "34.98100000",
                            "longitude": "-79.22420000"
                        },
                        {
                            "id": 124831,
                            "name": "Raleigh",
                            "latitude": "35.77210000",
                            "longitude": "-78.63861000"
                        },
                        {
                            "id": 124839,
                            "name": "Ramseur",
                            "latitude": "35.73347000",
                            "longitude": "-79.65253000"
                        },
                        {
                            "id": 124864,
                            "name": "Randleman",
                            "latitude": "35.81791000",
                            "longitude": "-79.80309000"
                        },
                        {
                            "id": 124877,
                            "name": "Randolph County",
                            "latitude": "35.71033000",
                            "longitude": "-79.80616000"
                        },
                        {
                            "id": 124886,
                            "name": "Ranlo",
                            "latitude": "35.28625000",
                            "longitude": "-81.13035000"
                        },
                        {
                            "id": 124955,
                            "name": "Red Oak",
                            "latitude": "36.03849000",
                            "longitude": "-77.90637000"
                        },
                        {
                            "id": 124961,
                            "name": "Red Springs",
                            "latitude": "34.81516000",
                            "longitude": "-79.18309000"
                        },
                        {
                            "id": 125008,
                            "name": "Reidsville",
                            "latitude": "36.35486000",
                            "longitude": "-79.66447000"
                        },
                        {
                            "id": 125047,
                            "name": "Rhodhiss",
                            "latitude": "35.77402000",
                            "longitude": "-81.43120000"
                        },
                        {
                            "id": 125081,
                            "name": "Richlands",
                            "latitude": "34.89933000",
                            "longitude": "-77.54663000"
                        },
                        {
                            "id": 125099,
                            "name": "Richmond County",
                            "latitude": "35.00594000",
                            "longitude": "-79.74783000"
                        },
                        {
                            "id": 125176,
                            "name": "River Bend",
                            "latitude": "35.06905000",
                            "longitude": "-77.14690000"
                        },
                        {
                            "id": 125186,
                            "name": "River Road",
                            "latitude": "35.50683000",
                            "longitude": "-76.99078000"
                        },
                        {
                            "id": 125232,
                            "name": "Roanoke Rapids",
                            "latitude": "36.46154000",
                            "longitude": "-77.65415000"
                        },
                        {
                            "id": 125234,
                            "name": "Robbins",
                            "latitude": "35.43403000",
                            "longitude": "-79.58697000"
                        },
                        {
                            "id": 125237,
                            "name": "Robbinsville",
                            "latitude": "35.32287000",
                            "longitude": "-83.80740000"
                        },
                        {
                            "id": 125239,
                            "name": "Robersonville",
                            "latitude": "35.82516000",
                            "longitude": "-77.24913000"
                        },
                        {
                            "id": 125249,
                            "name": "Robeson County",
                            "latitude": "34.64009000",
                            "longitude": "-79.10353000"
                        },
                        {
                            "id": 125298,
                            "name": "Rockfish",
                            "latitude": "34.99266000",
                            "longitude": "-79.06614000"
                        },
                        {
                            "id": 125303,
                            "name": "Rockingham",
                            "latitude": "34.93932000",
                            "longitude": "-79.77395000"
                        },
                        {
                            "id": 125305,
                            "name": "Rockingham County",
                            "latitude": "36.39608000",
                            "longitude": "-79.77515000"
                        },
                        {
                            "id": 125329,
                            "name": "Rockwell",
                            "latitude": "35.55125000",
                            "longitude": "-80.40645000"
                        },
                        {
                            "id": 125335,
                            "name": "Rocky Mount",
                            "latitude": "35.93821000",
                            "longitude": "-77.79053000"
                        },
                        {
                            "id": 125337,
                            "name": "Rocky Point",
                            "latitude": "34.43517000",
                            "longitude": "-77.88776000"
                        },
                        {
                            "id": 125361,
                            "name": "Rolesville",
                            "latitude": "35.92321000",
                            "longitude": "-78.45750000"
                        },
                        {
                            "id": 125404,
                            "name": "Rose Hill",
                            "latitude": "34.82822000",
                            "longitude": "-78.02304000"
                        },
                        {
                            "id": 125410,
                            "name": "Roseboro",
                            "latitude": "34.95295000",
                            "longitude": "-78.50862000"
                        },
                        {
                            "id": 125478,
                            "name": "Rowan County",
                            "latitude": "35.63954000",
                            "longitude": "-80.52464000"
                        },
                        {
                            "id": 125479,
                            "name": "Rowland",
                            "latitude": "34.53655000",
                            "longitude": "-79.29143000"
                        },
                        {
                            "id": 125484,
                            "name": "Roxboro",
                            "latitude": "36.39375000",
                            "longitude": "-78.98279000"
                        },
                        {
                            "id": 125493,
                            "name": "Royal Pines",
                            "latitude": "35.47511000",
                            "longitude": "-82.51595000"
                        },
                        {
                            "id": 125516,
                            "name": "Rural Hall",
                            "latitude": "36.24042000",
                            "longitude": "-80.29338000"
                        },
                        {
                            "id": 125551,
                            "name": "Rutherford College",
                            "latitude": "35.74846000",
                            "longitude": "-81.52259000"
                        },
                        {
                            "id": 125552,
                            "name": "Rutherford County",
                            "latitude": "35.40225000",
                            "longitude": "-81.92009000"
                        },
                        {
                            "id": 125554,
                            "name": "Rutherfordton",
                            "latitude": "35.36929000",
                            "longitude": "-81.95677000"
                        },
                        {
                            "id": 125654,
                            "name": "Saint James",
                            "latitude": "33.92934000",
                            "longitude": "-78.11638000"
                        },
                        {
                            "id": 125706,
                            "name": "Saint Pauls",
                            "latitude": "34.80655000",
                            "longitude": "-78.97114000"
                        },
                        {
                            "id": 125717,
                            "name": "Saint Stephens",
                            "latitude": "35.76458000",
                            "longitude": "-81.27314000"
                        },
                        {
                            "id": 125731,
                            "name": "Salem",
                            "latitude": "35.69874000",
                            "longitude": "-81.69704000"
                        },
                        {
                            "id": 125756,
                            "name": "Salisbury",
                            "latitude": "35.67097000",
                            "longitude": "-80.47423000"
                        },
                        {
                            "id": 125780,
                            "name": "Sampson County",
                            "latitude": "34.99163000",
                            "longitude": "-78.37152000"
                        },
                        {
                            "id": 125887,
                            "name": "Sanford",
                            "latitude": "35.47988000",
                            "longitude": "-79.18030000"
                        },
                        {
                            "id": 125981,
                            "name": "Sawmills",
                            "latitude": "35.82485000",
                            "longitude": "-81.47454000"
                        },
                        {
                            "id": 125983,
                            "name": "Saxapahaw",
                            "latitude": "35.94736000",
                            "longitude": "-79.32196000"
                        },
                        {
                            "id": 126036,
                            "name": "Scotland County",
                            "latitude": "34.84090000",
                            "longitude": "-79.48043000"
                        },
                        {
                            "id": 126038,
                            "name": "Scotland Neck",
                            "latitude": "36.12960000",
                            "longitude": "-77.42025000"
                        },
                        {
                            "id": 126058,
                            "name": "Scotts Mill",
                            "latitude": "35.72666000",
                            "longitude": "-78.88390000"
                        },
                        {
                            "id": 126070,
                            "name": "Sea Breeze",
                            "latitude": "34.06323000",
                            "longitude": "-77.89137000"
                        },
                        {
                            "id": 126087,
                            "name": "Seagate",
                            "latitude": "34.20934000",
                            "longitude": "-77.84359000"
                        },
                        {
                            "id": 126139,
                            "name": "Selma",
                            "latitude": "35.53655000",
                            "longitude": "-78.28444000"
                        },
                        {
                            "id": 126173,
                            "name": "Seven Lakes",
                            "latitude": "35.27849000",
                            "longitude": "-79.56448000"
                        },
                        {
                            "id": 126212,
                            "name": "Shallotte",
                            "latitude": "33.97323000",
                            "longitude": "-78.38584000"
                        },
                        {
                            "id": 126235,
                            "name": "Sharpsburg",
                            "latitude": "35.86710000",
                            "longitude": "-77.82915000"
                        },
                        {
                            "id": 126268,
                            "name": "Shelby",
                            "latitude": "35.29235000",
                            "longitude": "-81.53565000"
                        },
                        {
                            "id": 126339,
                            "name": "Sherrills Ford",
                            "latitude": "35.62041000",
                            "longitude": "-80.98647000"
                        },
                        {
                            "id": 126415,
                            "name": "Siler City",
                            "latitude": "35.72347000",
                            "longitude": "-79.46224000"
                        },
                        {
                            "id": 126429,
                            "name": "Silver Lake",
                            "latitude": "34.14878000",
                            "longitude": "-77.91360000"
                        },
                        {
                            "id": 126482,
                            "name": "Skippers Corner",
                            "latitude": "34.34600000",
                            "longitude": "-77.90249000"
                        },
                        {
                            "id": 126518,
                            "name": "Smithfield",
                            "latitude": "35.50849000",
                            "longitude": "-78.33945000"
                        },
                        {
                            "id": 126539,
                            "name": "Sneads Ferry",
                            "latitude": "34.55267000",
                            "longitude": "-77.39718000"
                        },
                        {
                            "id": 126546,
                            "name": "Snow Hill",
                            "latitude": "35.45155000",
                            "longitude": "-77.68109000"
                        },
                        {
                            "id": 126657,
                            "name": "South Gastonia",
                            "latitude": "35.21930000",
                            "longitude": "-81.20563000"
                        },
                        {
                            "id": 126669,
                            "name": "South Henderson",
                            "latitude": "36.30820000",
                            "longitude": "-78.40666000"
                        },
                        {
                            "id": 126719,
                            "name": "South Rosemary",
                            "latitude": "36.45154000",
                            "longitude": "-77.69720000"
                        },
                        {
                            "id": 126768,
                            "name": "Southern Pines",
                            "latitude": "35.17405000",
                            "longitude": "-79.39225000"
                        },
                        {
                            "id": 126770,
                            "name": "Southern Shores",
                            "latitude": "36.13905000",
                            "longitude": "-75.73157000"
                        },
                        {
                            "id": 126779,
                            "name": "Southmont",
                            "latitude": "35.66792000",
                            "longitude": "-80.26700000"
                        },
                        {
                            "id": 126783,
                            "name": "Southport",
                            "latitude": "33.92156000",
                            "longitude": "-78.02027000"
                        },
                        {
                            "id": 126808,
                            "name": "Sparta",
                            "latitude": "36.50541000",
                            "longitude": "-81.12092000"
                        },
                        {
                            "id": 126821,
                            "name": "Spencer",
                            "latitude": "35.69236000",
                            "longitude": "-80.43478000"
                        },
                        {
                            "id": 126835,
                            "name": "Spindale",
                            "latitude": "35.36012000",
                            "longitude": "-81.92927000"
                        },
                        {
                            "id": 126863,
                            "name": "Spring Hope",
                            "latitude": "35.94515000",
                            "longitude": "-78.11193000"
                        },
                        {
                            "id": 126865,
                            "name": "Spring Lake",
                            "latitude": "35.16794000",
                            "longitude": "-78.97281000"
                        },
                        {
                            "id": 126919,
                            "name": "Spruce Pine",
                            "latitude": "35.91540000",
                            "longitude": "-82.06456000"
                        },
                        {
                            "id": 126940,
                            "name": "Stallings",
                            "latitude": "35.09070000",
                            "longitude": "-80.68618000"
                        },
                        {
                            "id": 126951,
                            "name": "Stanfield",
                            "latitude": "35.23348000",
                            "longitude": "-80.42701000"
                        },
                        {
                            "id": 126958,
                            "name": "Stanley",
                            "latitude": "35.35903000",
                            "longitude": "-81.09702000"
                        },
                        {
                            "id": 126964,
                            "name": "Stanly County",
                            "latitude": "35.31199000",
                            "longitude": "-80.25092000"
                        },
                        {
                            "id": 126998,
                            "name": "Statesville",
                            "latitude": "35.78264000",
                            "longitude": "-80.88730000"
                        },
                        {
                            "id": 127006,
                            "name": "Stedman",
                            "latitude": "35.01350000",
                            "longitude": "-78.69391000"
                        },
                        {
                            "id": 127078,
                            "name": "Stokes County",
                            "latitude": "36.40190000",
                            "longitude": "-80.23961000"
                        },
                        {
                            "id": 127079,
                            "name": "Stokesdale",
                            "latitude": "36.23708000",
                            "longitude": "-79.97948000"
                        },
                        {
                            "id": 127090,
                            "name": "Stoneville",
                            "latitude": "36.46653000",
                            "longitude": "-79.90698000"
                        },
                        {
                            "id": 127098,
                            "name": "Stony Point",
                            "latitude": "35.86347000",
                            "longitude": "-81.04730000"
                        },
                        {
                            "id": 127202,
                            "name": "Summerfield",
                            "latitude": "36.20875000",
                            "longitude": "-79.90476000"
                        },
                        {
                            "id": 127274,
                            "name": "Sunset Beach",
                            "latitude": "33.88073000",
                            "longitude": "-78.51223000"
                        },
                        {
                            "id": 127285,
                            "name": "Surf City",
                            "latitude": "34.42711000",
                            "longitude": "-77.54608000"
                        },
                        {
                            "id": 127294,
                            "name": "Surry County",
                            "latitude": "36.41468000",
                            "longitude": "-80.68749000"
                        },
                        {
                            "id": 127319,
                            "name": "Swain County",
                            "latitude": "35.48673000",
                            "longitude": "-83.49274000"
                        },
                        {
                            "id": 127322,
                            "name": "Swannanoa",
                            "latitude": "35.59789000",
                            "longitude": "-82.39984000"
                        },
                        {
                            "id": 127323,
                            "name": "Swanquarter",
                            "latitude": "35.40628000",
                            "longitude": "-76.32909000"
                        },
                        {
                            "id": 127324,
                            "name": "Swansboro",
                            "latitude": "34.68766000",
                            "longitude": "-77.11912000"
                        },
                        {
                            "id": 127344,
                            "name": "Swepsonville",
                            "latitude": "36.02125000",
                            "longitude": "-79.36141000"
                        },
                        {
                            "id": 127355,
                            "name": "Sylva",
                            "latitude": "35.37371000",
                            "longitude": "-83.22598000"
                        },
                        {
                            "id": 127368,
                            "name": "Tabor City",
                            "latitude": "34.14878000",
                            "longitude": "-78.87669000"
                        },
                        {
                            "id": 127428,
                            "name": "Tarboro",
                            "latitude": "35.89682000",
                            "longitude": "-77.53580000"
                        },
                        {
                            "id": 127465,
                            "name": "Taylorsville",
                            "latitude": "35.92180000",
                            "longitude": "-81.17647000"
                        },
                        {
                            "id": 127589,
                            "name": "Thomasville",
                            "latitude": "35.88264000",
                            "longitude": "-80.08199000"
                        },
                        {
                            "id": 127628,
                            "name": "Thurmond",
                            "latitude": "36.36652000",
                            "longitude": "-80.92841000"
                        },
                        {
                            "id": 127689,
                            "name": "Toast",
                            "latitude": "36.50041000",
                            "longitude": "-80.62645000"
                        },
                        {
                            "id": 127690,
                            "name": "Tobaccoville",
                            "latitude": "36.23819000",
                            "longitude": "-80.37144000"
                        },
                        {
                            "id": 127780,
                            "name": "Transylvania County",
                            "latitude": "35.20215000",
                            "longitude": "-82.79830000"
                        },
                        {
                            "id": 127800,
                            "name": "Trent Woods",
                            "latitude": "35.08210000",
                            "longitude": "-77.08634000"
                        },
                        {
                            "id": 127804,
                            "name": "Trenton",
                            "latitude": "35.06710000",
                            "longitude": "-77.35274000"
                        },
                        {
                            "id": 127826,
                            "name": "Trinity",
                            "latitude": "35.89458000",
                            "longitude": "-79.99087000"
                        },
                        {
                            "id": 127839,
                            "name": "Troutman",
                            "latitude": "35.70069000",
                            "longitude": "-80.88813000"
                        },
                        {
                            "id": 127845,
                            "name": "Troy",
                            "latitude": "35.35847000",
                            "longitude": "-79.89449000"
                        },
                        {
                            "id": 127862,
                            "name": "Tryon",
                            "latitude": "35.33986000",
                            "longitude": "-81.32203000"
                        },
                        {
                            "id": 127948,
                            "name": "Tyro",
                            "latitude": "35.80903000",
                            "longitude": "-80.37283000"
                        },
                        {
                            "id": 127951,
                            "name": "Tyrrell County",
                            "latitude": "35.87018000",
                            "longitude": "-76.17005000"
                        },
                        {
                            "id": 127995,
                            "name": "Union County",
                            "latitude": "34.98837000",
                            "longitude": "-80.53073000"
                        },
                        {
                            "id": 128018,
                            "name": "Unionville",
                            "latitude": "35.08737000",
                            "longitude": "-80.50896000"
                        },
                        {
                            "id": 128082,
                            "name": "Valdese",
                            "latitude": "35.74069000",
                            "longitude": "-81.56315000"
                        },
                        {
                            "id": 128112,
                            "name": "Valley Hill",
                            "latitude": "35.29845000",
                            "longitude": "-82.48318000"
                        },
                        {
                            "id": 128141,
                            "name": "Vance County",
                            "latitude": "36.36476000",
                            "longitude": "-78.40833000"
                        },
                        {
                            "id": 128149,
                            "name": "Vander",
                            "latitude": "35.03211000",
                            "longitude": "-78.79474000"
                        },
                        {
                            "id": 128323,
                            "name": "Wadesboro",
                            "latitude": "34.96821000",
                            "longitude": "-80.07673000"
                        },
                        {
                            "id": 128356,
                            "name": "Wake County",
                            "latitude": "35.79012000",
                            "longitude": "-78.65022000"
                        },
                        {
                            "id": 128357,
                            "name": "Wake Forest",
                            "latitude": "35.97987000",
                            "longitude": "-78.50972000"
                        },
                        {
                            "id": 128392,
                            "name": "Walkertown",
                            "latitude": "36.17541000",
                            "longitude": "-80.15310000"
                        },
                        {
                            "id": 128397,
                            "name": "Wallace",
                            "latitude": "34.73572000",
                            "longitude": "-77.99526000"
                        },
                        {
                            "id": 128400,
                            "name": "Wallburg",
                            "latitude": "36.01014000",
                            "longitude": "-80.13921000"
                        },
                        {
                            "id": 128415,
                            "name": "Walnut Cove",
                            "latitude": "36.29541000",
                            "longitude": "-80.14171000"
                        },
                        {
                            "id": 128451,
                            "name": "Wanchese",
                            "latitude": "35.84267000",
                            "longitude": "-75.63851000"
                        },
                        {
                            "id": 128490,
                            "name": "Warren County",
                            "latitude": "36.39659000",
                            "longitude": "-78.10690000"
                        },
                        {
                            "id": 128506,
                            "name": "Warrenton",
                            "latitude": "36.39848000",
                            "longitude": "-78.15527000"
                        },
                        {
                            "id": 128516,
                            "name": "Warsaw",
                            "latitude": "34.99933000",
                            "longitude": "-78.09110000"
                        },
                        {
                            "id": 128541,
                            "name": "Washington",
                            "latitude": "35.54655000",
                            "longitude": "-77.05217000"
                        },
                        {
                            "id": 128563,
                            "name": "Washington County",
                            "latitude": "35.83887000",
                            "longitude": "-76.56868000"
                        },
                        {
                            "id": 128596,
                            "name": "Watauga County",
                            "latitude": "36.23110000",
                            "longitude": "-81.69637000"
                        },
                        {
                            "id": 128675,
                            "name": "Waxhaw",
                            "latitude": "34.92459000",
                            "longitude": "-80.74340000"
                        },
                        {
                            "id": 128695,
                            "name": "Wayne County",
                            "latitude": "35.36378000",
                            "longitude": "-78.00415000"
                        },
                        {
                            "id": 128711,
                            "name": "Waynesville",
                            "latitude": "35.48871000",
                            "longitude": "-82.98875000"
                        },
                        {
                            "id": 128722,
                            "name": "Weaverville",
                            "latitude": "35.69705000",
                            "longitude": "-82.56069000"
                        },
                        {
                            "id": 128746,
                            "name": "Weddington",
                            "latitude": "35.02237000",
                            "longitude": "-80.76090000"
                        },
                        {
                            "id": 128766,
                            "name": "Welcome",
                            "latitude": "35.90292000",
                            "longitude": "-80.25699000"
                        },
                        {
                            "id": 128769,
                            "name": "Weldon",
                            "latitude": "36.42710000",
                            "longitude": "-77.59553000"
                        },
                        {
                            "id": 128798,
                            "name": "Wendell",
                            "latitude": "35.78099000",
                            "longitude": "-78.36972000"
                        },
                        {
                            "id": 128804,
                            "name": "Wentworth",
                            "latitude": "36.40014000",
                            "longitude": "-79.77448000"
                        },
                        {
                            "id": 128810,
                            "name": "Wesley Chapel",
                            "latitude": "35.00709000",
                            "longitude": "-80.67451000"
                        },
                        {
                            "id": 128839,
                            "name": "West Canton",
                            "latitude": "35.53788000",
                            "longitude": "-82.85819000"
                        },
                        {
                            "id": 128904,
                            "name": "West Jefferson",
                            "latitude": "36.40374000",
                            "longitude": "-81.49288000"
                        },
                        {
                            "id": 128925,
                            "name": "West Marion",
                            "latitude": "35.65790000",
                            "longitude": "-82.02539000"
                        },
                        {
                            "id": 128965,
                            "name": "West Raleigh",
                            "latitude": "35.78682000",
                            "longitude": "-78.66389000"
                        },
                        {
                            "id": 129071,
                            "name": "Westport",
                            "latitude": "35.50125000",
                            "longitude": "-80.97869000"
                        },
                        {
                            "id": 129124,
                            "name": "Whispering Pines",
                            "latitude": "35.25571000",
                            "longitude": "-79.37225000"
                        },
                        {
                            "id": 129154,
                            "name": "White Plains",
                            "latitude": "36.44569000",
                            "longitude": "-80.63340000"
                        },
                        {
                            "id": 129189,
                            "name": "Whiteville",
                            "latitude": "34.33878000",
                            "longitude": "-78.70307000"
                        },
                        {
                            "id": 129245,
                            "name": "Wilkes County",
                            "latitude": "36.20621000",
                            "longitude": "-81.16292000"
                        },
                        {
                            "id": 129247,
                            "name": "Wilkesboro",
                            "latitude": "36.14596000",
                            "longitude": "-81.16064000"
                        },
                        {
                            "id": 129282,
                            "name": "Williamston",
                            "latitude": "35.85460000",
                            "longitude": "-77.05551000"
                        },
                        {
                            "id": 129322,
                            "name": "Wilmington",
                            "latitude": "34.22573000",
                            "longitude": "-77.94471000"
                        },
                        {
                            "id": 129329,
                            "name": "Wilson",
                            "latitude": "35.72127000",
                            "longitude": "-77.91554000"
                        },
                        {
                            "id": 129335,
                            "name": "Wilson County",
                            "latitude": "35.70503000",
                            "longitude": "-77.91862000"
                        },
                        {
                            "id": 129339,
                            "name": "Wilsons Mills",
                            "latitude": "35.58405000",
                            "longitude": "-78.35583000"
                        },
                        {
                            "id": 129378,
                            "name": "Windsor",
                            "latitude": "35.99849000",
                            "longitude": "-76.94606000"
                        },
                        {
                            "id": 129400,
                            "name": "Wingate",
                            "latitude": "34.98432000",
                            "longitude": "-80.44923000"
                        },
                        {
                            "id": 129436,
                            "name": "Winston-Salem",
                            "latitude": "36.09986000",
                            "longitude": "-80.24422000"
                        },
                        {
                            "id": 129448,
                            "name": "Winterville",
                            "latitude": "35.52905000",
                            "longitude": "-77.40108000"
                        },
                        {
                            "id": 129453,
                            "name": "Winton",
                            "latitude": "36.39571000",
                            "longitude": "-76.93190000"
                        },
                        {
                            "id": 129518,
                            "name": "Woodfin",
                            "latitude": "35.63344000",
                            "longitude": "-82.58207000"
                        },
                        {
                            "id": 129611,
                            "name": "Wrightsboro",
                            "latitude": "34.28850000",
                            "longitude": "-77.92110000"
                        },
                        {
                            "id": 129616,
                            "name": "Wrightsville Beach",
                            "latitude": "34.20850000",
                            "longitude": "-77.79637000"
                        },
                        {
                            "id": 129648,
                            "name": "Yadkin County",
                            "latitude": "36.16054000",
                            "longitude": "-80.66534000"
                        },
                        {
                            "id": 129649,
                            "name": "Yadkinville",
                            "latitude": "36.13458000",
                            "longitude": "-80.65951000"
                        },
                        {
                            "id": 129659,
                            "name": "Yancey County",
                            "latitude": "35.89888000",
                            "longitude": "-82.30755000"
                        },
                        {
                            "id": 129660,
                            "name": "Yanceyville",
                            "latitude": "36.40403000",
                            "longitude": "-79.33613000"
                        },
                        {
                            "id": 129719,
                            "name": "Youngsville",
                            "latitude": "36.02487000",
                            "longitude": "-78.47444000"
                        },
                        {
                            "id": 129743,
                            "name": "Zebulon",
                            "latitude": "35.82432000",
                            "longitude": "-78.31472000"
                        }
                    ]
                },
                {
                    "id": 1418,
                    "name": "North Dakota",
                    "state_code": "ND",
                    "cities": [
                        {
                            "id": 111026,
                            "name": "Adams County",
                            "latitude": "46.09684000",
                            "longitude": "-102.52853000"
                        },
                        {
                            "id": 111287,
                            "name": "Amidon",
                            "latitude": "46.48223000",
                            "longitude": "-103.32185000"
                        },
                        {
                            "id": 111543,
                            "name": "Ashley",
                            "latitude": "46.03414000",
                            "longitude": "-99.37150000"
                        },
                        {
                            "id": 111826,
                            "name": "Barnes County",
                            "latitude": "46.93611000",
                            "longitude": "-98.07158000"
                        },
                        {
                            "id": 111962,
                            "name": "Beach",
                            "latitude": "46.91807000",
                            "longitude": "-104.00437000"
                        },
                        {
                            "id": 112052,
                            "name": "Belcourt",
                            "latitude": "48.83917000",
                            "longitude": "-99.74487000"
                        },
                        {
                            "id": 112058,
                            "name": "Belfield",
                            "latitude": "46.88529000",
                            "longitude": "-103.19962000"
                        },
                        {
                            "id": 112186,
                            "name": "Benson County",
                            "latitude": "48.06939000",
                            "longitude": "-99.36603000"
                        },
                        {
                            "id": 112296,
                            "name": "Beulah",
                            "latitude": "47.26334000",
                            "longitude": "-101.77795000"
                        },
                        {
                            "id": 112345,
                            "name": "Billings County",
                            "latitude": "47.02345000",
                            "longitude": "-103.37643000"
                        },
                        {
                            "id": 112367,
                            "name": "Bismarck",
                            "latitude": "46.80833000",
                            "longitude": "-100.78374000"
                        },
                        {
                            "id": 112598,
                            "name": "Bottineau",
                            "latitude": "48.82723000",
                            "longitude": "-100.44570000"
                        },
                        {
                            "id": 112599,
                            "name": "Bottineau County",
                            "latitude": "48.79216000",
                            "longitude": "-100.83333000"
                        },
                        {
                            "id": 112621,
                            "name": "Bowbells",
                            "latitude": "48.80308000",
                            "longitude": "-102.24600000"
                        },
                        {
                            "id": 112632,
                            "name": "Bowman",
                            "latitude": "46.18306000",
                            "longitude": "-103.39491000"
                        },
                        {
                            "id": 112633,
                            "name": "Bowman County",
                            "latitude": "46.11261000",
                            "longitude": "-103.52067000"
                        },
                        {
                            "id": 113058,
                            "name": "Burke County",
                            "latitude": "48.79100000",
                            "longitude": "-102.51826000"
                        },
                        {
                            "id": 113060,
                            "name": "Burleigh County",
                            "latitude": "46.97739000",
                            "longitude": "-100.46873000"
                        },
                        {
                            "id": 113075,
                            "name": "Burlington",
                            "latitude": "48.27529000",
                            "longitude": "-101.42878000"
                        },
                        {
                            "id": 113326,
                            "name": "Cando",
                            "latitude": "48.48667000",
                            "longitude": "-99.20986000"
                        },
                        {
                            "id": 113446,
                            "name": "Carrington",
                            "latitude": "47.44972000",
                            "longitude": "-99.12622000"
                        },
                        {
                            "id": 113475,
                            "name": "Carson",
                            "latitude": "46.41778000",
                            "longitude": "-101.56487000"
                        },
                        {
                            "id": 113533,
                            "name": "Cass County",
                            "latitude": "46.93297000",
                            "longitude": "-97.24804000"
                        },
                        {
                            "id": 113536,
                            "name": "Casselton",
                            "latitude": "46.90053000",
                            "longitude": "-97.21120000"
                        },
                        {
                            "id": 113581,
                            "name": "Cavalier",
                            "latitude": "48.79388000",
                            "longitude": "-97.62231000"
                        },
                        {
                            "id": 113582,
                            "name": "Cavalier County",
                            "latitude": "48.77230000",
                            "longitude": "-98.46486000"
                        },
                        {
                            "id": 113639,
                            "name": "Center",
                            "latitude": "47.11638000",
                            "longitude": "-101.29959000"
                        },
                        {
                            "id": 114606,
                            "name": "Cooperstown",
                            "latitude": "47.44444000",
                            "longitude": "-98.12398000"
                        },
                        {
                            "id": 114844,
                            "name": "Crosby",
                            "latitude": "48.91420000",
                            "longitude": "-103.29491000"
                        },
                        {
                            "id": 115298,
                            "name": "Devils Lake",
                            "latitude": "48.11278000",
                            "longitude": "-98.86512000"
                        },
                        {
                            "id": 115323,
                            "name": "Dickey County",
                            "latitude": "46.11012000",
                            "longitude": "-98.50467000"
                        },
                        {
                            "id": 115326,
                            "name": "Dickinson",
                            "latitude": "46.87918000",
                            "longitude": "-102.78962000"
                        },
                        {
                            "id": 115357,
                            "name": "Divide County",
                            "latitude": "48.81489000",
                            "longitude": "-103.48735000"
                        },
                        {
                            "id": 115548,
                            "name": "Dunn County",
                            "latitude": "47.35675000",
                            "longitude": "-102.61824000"
                        },
                        {
                            "id": 115823,
                            "name": "Eddy County",
                            "latitude": "47.71759000",
                            "longitude": "-98.90158000"
                        },
                        {
                            "id": 116017,
                            "name": "Ellendale",
                            "latitude": "46.00275000",
                            "longitude": "-98.52705000"
                        },
                        {
                            "id": 116107,
                            "name": "Emmons County",
                            "latitude": "46.28502000",
                            "longitude": "-100.23878000"
                        },
                        {
                            "id": 116396,
                            "name": "Fargo",
                            "latitude": "46.87719000",
                            "longitude": "-96.78980000"
                        },
                        {
                            "id": 116501,
                            "name": "Fessenden",
                            "latitude": "47.64917000",
                            "longitude": "-99.62929000"
                        },
                        {
                            "id": 116516,
                            "name": "Finley",
                            "latitude": "47.51416000",
                            "longitude": "-97.83593000"
                        },
                        {
                            "id": 116676,
                            "name": "Forman",
                            "latitude": "46.10774000",
                            "longitude": "-97.63649000"
                        },
                        {
                            "id": 116756,
                            "name": "Fort Totten",
                            "latitude": "47.98000000",
                            "longitude": "-98.99290000"
                        },
                        {
                            "id": 116766,
                            "name": "Fort Yates",
                            "latitude": "46.08694000",
                            "longitude": "-100.63013000"
                        },
                        {
                            "id": 116777,
                            "name": "Foster County",
                            "latitude": "47.45708000",
                            "longitude": "-98.88302000"
                        },
                        {
                            "id": 117103,
                            "name": "Garrison",
                            "latitude": "47.65222000",
                            "longitude": "-101.41572000"
                        },
                        {
                            "id": 117359,
                            "name": "Golden Valley County",
                            "latitude": "46.94022000",
                            "longitude": "-103.84670000"
                        },
                        {
                            "id": 117431,
                            "name": "Grafton",
                            "latitude": "48.41221000",
                            "longitude": "-97.41063000"
                        },
                        {
                            "id": 117457,
                            "name": "Grand Forks",
                            "latitude": "47.92526000",
                            "longitude": "-97.03285000"
                        },
                        {
                            "id": 117458,
                            "name": "Grand Forks Air Force Base",
                            "latitude": "47.95493000",
                            "longitude": "-97.38664000"
                        },
                        {
                            "id": 117459,
                            "name": "Grand Forks County",
                            "latitude": "47.92192000",
                            "longitude": "-97.45693000"
                        },
                        {
                            "id": 117518,
                            "name": "Grant County",
                            "latitude": "46.35828000",
                            "longitude": "-101.63971000"
                        },
                        {
                            "id": 117734,
                            "name": "Griggs County",
                            "latitude": "47.45729000",
                            "longitude": "-98.23704000"
                        },
                        {
                            "id": 118114,
                            "name": "Harvey",
                            "latitude": "47.76972000",
                            "longitude": "-99.93540000"
                        },
                        {
                            "id": 118210,
                            "name": "Hazen",
                            "latitude": "47.29445000",
                            "longitude": "-101.62266000"
                        },
                        {
                            "id": 118346,
                            "name": "Hettinger",
                            "latitude": "46.00139000",
                            "longitude": "-102.63682000"
                        },
                        {
                            "id": 118347,
                            "name": "Hettinger County",
                            "latitude": "46.43253000",
                            "longitude": "-102.46036000"
                        },
                        {
                            "id": 118450,
                            "name": "Hillsboro",
                            "latitude": "47.40387000",
                            "longitude": "-97.06203000"
                        },
                        {
                            "id": 118661,
                            "name": "Horace",
                            "latitude": "46.75886000",
                            "longitude": "-96.90370000"
                        },
                        {
                            "id": 119123,
                            "name": "Jamestown",
                            "latitude": "46.91054000",
                            "longitude": "-98.70844000"
                        },
                        {
                            "id": 119461,
                            "name": "Kenmare",
                            "latitude": "48.67475000",
                            "longitude": "-102.08266000"
                        },
                        {
                            "id": 119552,
                            "name": "Kidder County",
                            "latitude": "46.98014000",
                            "longitude": "-99.78009000"
                        },
                        {
                            "id": 119558,
                            "name": "Killdeer",
                            "latitude": "47.37196000",
                            "longitude": "-102.75408000"
                        },
                        {
                            "id": 120057,
                            "name": "Lakota",
                            "latitude": "48.04278000",
                            "longitude": "-98.33621000"
                        },
                        {
                            "id": 119803,
                            "name": "LaMoure County",
                            "latitude": "46.45691000",
                            "longitude": "-98.53546000"
                        },
                        {
                            "id": 120109,
                            "name": "Langdon",
                            "latitude": "48.76000000",
                            "longitude": "-98.36817000"
                        },
                        {
                            "id": 120146,
                            "name": "Larimore",
                            "latitude": "47.90666000",
                            "longitude": "-97.62675000"
                        },
                        {
                            "id": 120510,
                            "name": "Lincoln",
                            "latitude": "46.76249000",
                            "longitude": "-100.70040000"
                        },
                        {
                            "id": 120590,
                            "name": "Linton",
                            "latitude": "46.26666000",
                            "longitude": "-100.23289000"
                        },
                        {
                            "id": 120600,
                            "name": "Lisbon",
                            "latitude": "46.44163000",
                            "longitude": "-97.68121000"
                        },
                        {
                            "id": 120701,
                            "name": "Logan County",
                            "latitude": "46.45736000",
                            "longitude": "-99.47747000"
                        },
                        {
                            "id": 121106,
                            "name": "Mandan",
                            "latitude": "46.82666000",
                            "longitude": "-100.88958000"
                        },
                        {
                            "id": 121136,
                            "name": "Manning",
                            "latitude": "47.23001000",
                            "longitude": "-102.77019000"
                        },
                        {
                            "id": 121454,
                            "name": "Mayville",
                            "latitude": "47.49804000",
                            "longitude": "-97.32454000"
                        },
                        {
                            "id": 121472,
                            "name": "McClusky",
                            "latitude": "47.48583000",
                            "longitude": "-100.44318000"
                        },
                        {
                            "id": 121510,
                            "name": "McHenry County",
                            "latitude": "48.23458000",
                            "longitude": "-100.63628000"
                        },
                        {
                            "id": 121515,
                            "name": "McIntosh County",
                            "latitude": "46.11179000",
                            "longitude": "-99.44120000"
                        },
                        {
                            "id": 121521,
                            "name": "McKenzie County",
                            "latitude": "47.74015000",
                            "longitude": "-103.39527000"
                        },
                        {
                            "id": 121530,
                            "name": "McLean County",
                            "latitude": "47.60696000",
                            "longitude": "-101.32183000"
                        },
                        {
                            "id": 121608,
                            "name": "Medora",
                            "latitude": "46.91390000",
                            "longitude": "-103.52435000"
                        },
                        {
                            "id": 121681,
                            "name": "Mercer County",
                            "latitude": "47.30922000",
                            "longitude": "-101.83153000"
                        },
                        {
                            "id": 121959,
                            "name": "Minnewaukan",
                            "latitude": "48.07139000",
                            "longitude": "-99.25236000"
                        },
                        {
                            "id": 121966,
                            "name": "Minot",
                            "latitude": "48.23251000",
                            "longitude": "-101.29627000"
                        },
                        {
                            "id": 121967,
                            "name": "Minot Air Force Base",
                            "latitude": "48.42087000",
                            "longitude": "-101.33914000"
                        },
                        {
                            "id": 122018,
                            "name": "Mohall",
                            "latitude": "48.76336000",
                            "longitude": "-101.51322000"
                        },
                        {
                            "id": 122297,
                            "name": "Morton County",
                            "latitude": "46.71606000",
                            "longitude": "-101.28117000"
                        },
                        {
                            "id": 122312,
                            "name": "Mott",
                            "latitude": "46.37250000",
                            "longitude": "-102.32711000"
                        },
                        {
                            "id": 122440,
                            "name": "Mountrail County",
                            "latitude": "48.20133000",
                            "longitude": "-102.35565000"
                        },
                        {
                            "id": 122559,
                            "name": "Napoleon",
                            "latitude": "46.50831000",
                            "longitude": "-99.77122000"
                        },
                        {
                            "id": 122628,
                            "name": "Nelson County",
                            "latitude": "47.92169000",
                            "longitude": "-98.19204000"
                        },
                        {
                            "id": 122774,
                            "name": "New Rockford",
                            "latitude": "47.68000000",
                            "longitude": "-99.13790000"
                        },
                        {
                            "id": 122787,
                            "name": "New Town",
                            "latitude": "47.98085000",
                            "longitude": "-102.49018000"
                        },
                        {
                            "id": 123275,
                            "name": "Oakes",
                            "latitude": "46.13858000",
                            "longitude": "-98.09038000"
                        },
                        {
                            "id": 123443,
                            "name": "Oliver County",
                            "latitude": "47.11528000",
                            "longitude": "-101.34036000"
                        },
                        {
                            "id": 123853,
                            "name": "Park River",
                            "latitude": "48.39860000",
                            "longitude": "-97.74120000"
                        },
                        {
                            "id": 123889,
                            "name": "Parshall",
                            "latitude": "47.95335000",
                            "longitude": "-102.13489000"
                        },
                        {
                            "id": 124003,
                            "name": "Pembina County",
                            "latitude": "48.76752000",
                            "longitude": "-97.55183000"
                        },
                        {
                            "id": 124174,
                            "name": "Pierce County",
                            "latitude": "48.24960000",
                            "longitude": "-99.97182000"
                        },
                        {
                            "id": 124844,
                            "name": "Ramsey County",
                            "latitude": "48.26893000",
                            "longitude": "-98.72014000"
                        },
                        {
                            "id": 124888,
                            "name": "Ransom County",
                            "latitude": "46.45616000",
                            "longitude": "-97.65745000"
                        },
                        {
                            "id": 125029,
                            "name": "Renville County",
                            "latitude": "48.71907000",
                            "longitude": "-101.65778000"
                        },
                        {
                            "id": 125076,
                            "name": "Richland County",
                            "latitude": "46.26466000",
                            "longitude": "-96.94828000"
                        },
                        {
                            "id": 125362,
                            "name": "Rolette County",
                            "latitude": "48.77245000",
                            "longitude": "-99.84099000"
                        },
                        {
                            "id": 125364,
                            "name": "Rolla",
                            "latitude": "48.85778000",
                            "longitude": "-99.61792000"
                        },
                        {
                            "id": 125503,
                            "name": "Rugby",
                            "latitude": "48.36889000",
                            "longitude": "-99.99625000"
                        },
                        {
                            "id": 125950,
                            "name": "Sargent County",
                            "latitude": "46.10794000",
                            "longitude": "-97.63072000"
                        },
                        {
                            "id": 126288,
                            "name": "Sheldon",
                            "latitude": "46.58580000",
                            "longitude": "-97.49120000"
                        },
                        {
                            "id": 126293,
                            "name": "Shell Valley",
                            "latitude": "48.79806000",
                            "longitude": "-99.86486000"
                        },
                        {
                            "id": 126326,
                            "name": "Sheridan County",
                            "latitude": "47.57541000",
                            "longitude": "-100.34566000"
                        },
                        {
                            "id": 126460,
                            "name": "Sioux County",
                            "latitude": "46.11265000",
                            "longitude": "-101.04034000"
                        },
                        {
                            "id": 126507,
                            "name": "Slope County",
                            "latitude": "46.44722000",
                            "longitude": "-103.45990000"
                        },
                        {
                            "id": 126961,
                            "name": "Stanley",
                            "latitude": "48.31724000",
                            "longitude": "-102.39045000"
                        },
                        {
                            "id": 126970,
                            "name": "Stanton",
                            "latitude": "47.32111000",
                            "longitude": "-101.38155000"
                        },
                        {
                            "id": 126985,
                            "name": "Stark County",
                            "latitude": "46.81068000",
                            "longitude": "-102.65513000"
                        },
                        {
                            "id": 127009,
                            "name": "Steele",
                            "latitude": "46.85471000",
                            "longitude": "-99.91594000"
                        },
                        {
                            "id": 127011,
                            "name": "Steele County",
                            "latitude": "47.45615000",
                            "longitude": "-97.72467000"
                        },
                        {
                            "id": 127154,
                            "name": "Stutsman County",
                            "latitude": "46.97925000",
                            "longitude": "-98.95883000"
                        },
                        {
                            "id": 127291,
                            "name": "Surrey",
                            "latitude": "48.23640000",
                            "longitude": "-101.13349000"
                        },
                        {
                            "id": 127591,
                            "name": "Thompson",
                            "latitude": "47.77359000",
                            "longitude": "-97.10980000"
                        },
                        {
                            "id": 127668,
                            "name": "Tioga",
                            "latitude": "48.39724000",
                            "longitude": "-102.93824000"
                        },
                        {
                            "id": 127761,
                            "name": "Towner",
                            "latitude": "48.34583000",
                            "longitude": "-100.40541000"
                        },
                        {
                            "id": 127762,
                            "name": "Towner County",
                            "latitude": "48.68554000",
                            "longitude": "-99.24580000"
                        },
                        {
                            "id": 127778,
                            "name": "Traill County",
                            "latitude": "47.45418000",
                            "longitude": "-97.16158000"
                        },
                        {
                            "id": 128101,
                            "name": "Valley City",
                            "latitude": "46.92331000",
                            "longitude": "-98.00315000"
                        },
                        {
                            "id": 128165,
                            "name": "Velva",
                            "latitude": "48.05612000",
                            "longitude": "-100.92932000"
                        },
                        {
                            "id": 128335,
                            "name": "Wahpeton",
                            "latitude": "46.26524000",
                            "longitude": "-96.60591000"
                        },
                        {
                            "id": 128427,
                            "name": "Walsh County",
                            "latitude": "48.36946000",
                            "longitude": "-97.72137000"
                        },
                        {
                            "id": 128459,
                            "name": "Ward County",
                            "latitude": "48.22175000",
                            "longitude": "-101.54183000"
                        },
                        {
                            "id": 128535,
                            "name": "Washburn",
                            "latitude": "47.28916000",
                            "longitude": "-101.02903000"
                        },
                        {
                            "id": 128632,
                            "name": "Watford City",
                            "latitude": "47.80224000",
                            "longitude": "-103.28325000"
                        },
                        {
                            "id": 128787,
                            "name": "Wells County",
                            "latitude": "47.58753000",
                            "longitude": "-99.66095000"
                        },
                        {
                            "id": 128869,
                            "name": "West Fargo",
                            "latitude": "46.87497000",
                            "longitude": "-96.90036000"
                        },
                        {
                            "id": 129264,
                            "name": "Williams County",
                            "latitude": "48.34368000",
                            "longitude": "-103.48021000"
                        },
                        {
                            "id": 129299,
                            "name": "Williston",
                            "latitude": "48.14697000",
                            "longitude": "-103.61797000"
                        }
                    ]
                },
                {
                    "id": 1431,
                    "name": "Northern Mariana Islands",
                    "state_code": "MP",
                    "cities": []
                },
                {
                    "id": 4851,
                    "name": "Ohio",
                    "state_code": "OH",
                    "cities": [
                        {
                            "id": 141096,
                            "name": "Ada",
                            "latitude": "40.76950000",
                            "longitude": "-83.82271000"
                        },
                        {
                            "id": 141097,
                            "name": "Adams County",
                            "latitude": "38.84551000",
                            "longitude": "-83.47215000"
                        },
                        {
                            "id": 141098,
                            "name": "Akron",
                            "latitude": "41.08144000",
                            "longitude": "-81.51901000"
                        },
                        {
                            "id": 141099,
                            "name": "Allen County",
                            "latitude": "40.77152000",
                            "longitude": "-84.10578000"
                        },
                        {
                            "id": 141100,
                            "name": "Alliance",
                            "latitude": "40.91534000",
                            "longitude": "-81.10593000"
                        },
                        {
                            "id": 141101,
                            "name": "Amberley",
                            "latitude": "39.20478000",
                            "longitude": "-84.42800000"
                        },
                        {
                            "id": 141102,
                            "name": "Amelia",
                            "latitude": "39.02840000",
                            "longitude": "-84.21771000"
                        },
                        {
                            "id": 141103,
                            "name": "Amherst",
                            "latitude": "41.39782000",
                            "longitude": "-82.22238000"
                        },
                        {
                            "id": 141104,
                            "name": "Andover",
                            "latitude": "41.60672000",
                            "longitude": "-80.57230000"
                        },
                        {
                            "id": 141105,
                            "name": "Anna",
                            "latitude": "40.39449000",
                            "longitude": "-84.17272000"
                        },
                        {
                            "id": 141106,
                            "name": "Ansonia",
                            "latitude": "40.21449000",
                            "longitude": "-84.63690000"
                        },
                        {
                            "id": 141107,
                            "name": "Antwerp",
                            "latitude": "41.18144000",
                            "longitude": "-84.74051000"
                        },
                        {
                            "id": 141108,
                            "name": "Apple Creek",
                            "latitude": "40.75172000",
                            "longitude": "-81.83930000"
                        },
                        {
                            "id": 141109,
                            "name": "Apple Valley",
                            "latitude": "40.43890000",
                            "longitude": "-82.35391000"
                        },
                        {
                            "id": 141110,
                            "name": "Arcanum",
                            "latitude": "39.99005000",
                            "longitude": "-84.55329000"
                        },
                        {
                            "id": 141111,
                            "name": "Archbold",
                            "latitude": "41.52144000",
                            "longitude": "-84.30717000"
                        },
                        {
                            "id": 141112,
                            "name": "Arlington",
                            "latitude": "40.89366000",
                            "longitude": "-83.65021000"
                        },
                        {
                            "id": 141113,
                            "name": "Ashland",
                            "latitude": "40.86867000",
                            "longitude": "-82.31822000"
                        },
                        {
                            "id": 141114,
                            "name": "Ashland County",
                            "latitude": "40.84602000",
                            "longitude": "-82.27069000"
                        },
                        {
                            "id": 141115,
                            "name": "Ashley",
                            "latitude": "40.40895000",
                            "longitude": "-82.95546000"
                        },
                        {
                            "id": 141116,
                            "name": "Ashtabula",
                            "latitude": "41.86505000",
                            "longitude": "-80.78981000"
                        },
                        {
                            "id": 141117,
                            "name": "Ashtabula County",
                            "latitude": "41.89638000",
                            "longitude": "-80.75901000"
                        },
                        {
                            "id": 141118,
                            "name": "Ashville",
                            "latitude": "39.71562000",
                            "longitude": "-82.95296000"
                        },
                        {
                            "id": 141119,
                            "name": "Athens",
                            "latitude": "39.32924000",
                            "longitude": "-82.10126000"
                        },
                        {
                            "id": 141120,
                            "name": "Athens County",
                            "latitude": "39.33386000",
                            "longitude": "-82.04513000"
                        },
                        {
                            "id": 141121,
                            "name": "Auglaize County",
                            "latitude": "40.56091000",
                            "longitude": "-84.22174000"
                        },
                        {
                            "id": 141122,
                            "name": "Aurora",
                            "latitude": "41.31755000",
                            "longitude": "-81.34539000"
                        },
                        {
                            "id": 141123,
                            "name": "Austintown",
                            "latitude": "41.10172000",
                            "longitude": "-80.76452000"
                        },
                        {
                            "id": 141124,
                            "name": "Avon",
                            "latitude": "41.45171000",
                            "longitude": "-82.03542000"
                        },
                        {
                            "id": 141125,
                            "name": "Avon Center",
                            "latitude": "41.45976000",
                            "longitude": "-82.01959000"
                        },
                        {
                            "id": 141126,
                            "name": "Avon Lake",
                            "latitude": "41.50532000",
                            "longitude": "-82.02820000"
                        },
                        {
                            "id": 141127,
                            "name": "Bainbridge",
                            "latitude": "41.38644000",
                            "longitude": "-81.33955000"
                        },
                        {
                            "id": 141128,
                            "name": "Ballville",
                            "latitude": "41.32783000",
                            "longitude": "-83.13214000"
                        },
                        {
                            "id": 141129,
                            "name": "Baltimore",
                            "latitude": "39.84534000",
                            "longitude": "-82.60072000"
                        },
                        {
                            "id": 141130,
                            "name": "Barberton",
                            "latitude": "41.01283000",
                            "longitude": "-81.60512000"
                        },
                        {
                            "id": 141131,
                            "name": "Barnesville",
                            "latitude": "39.98813000",
                            "longitude": "-81.17650000"
                        },
                        {
                            "id": 141132,
                            "name": "Batavia",
                            "latitude": "39.07701000",
                            "longitude": "-84.17688000"
                        },
                        {
                            "id": 141133,
                            "name": "Bay Village",
                            "latitude": "41.48477000",
                            "longitude": "-81.92208000"
                        },
                        {
                            "id": 141134,
                            "name": "Beach City",
                            "latitude": "40.65312000",
                            "longitude": "-81.58096000"
                        },
                        {
                            "id": 141135,
                            "name": "Beachwood",
                            "latitude": "41.46450000",
                            "longitude": "-81.50873000"
                        },
                        {
                            "id": 141136,
                            "name": "Beavercreek",
                            "latitude": "39.70923000",
                            "longitude": "-84.06327000"
                        },
                        {
                            "id": 141137,
                            "name": "Beckett Ridge",
                            "latitude": "39.34700000",
                            "longitude": "-84.43522000"
                        },
                        {
                            "id": 141138,
                            "name": "Bedford",
                            "latitude": "41.39311000",
                            "longitude": "-81.53651000"
                        },
                        {
                            "id": 141139,
                            "name": "Bedford Heights",
                            "latitude": "41.41700000",
                            "longitude": "-81.52734000"
                        },
                        {
                            "id": 141140,
                            "name": "Beechwood Trails",
                            "latitude": "40.02367000",
                            "longitude": "-82.65072000"
                        },
                        {
                            "id": 141141,
                            "name": "Bellaire",
                            "latitude": "40.01618000",
                            "longitude": "-80.74231000"
                        },
                        {
                            "id": 141142,
                            "name": "Bellbrook",
                            "latitude": "39.63562000",
                            "longitude": "-84.07077000"
                        },
                        {
                            "id": 141143,
                            "name": "Bellefontaine",
                            "latitude": "40.36116000",
                            "longitude": "-83.75966000"
                        },
                        {
                            "id": 141144,
                            "name": "Bellevue",
                            "latitude": "41.27366000",
                            "longitude": "-82.84158000"
                        },
                        {
                            "id": 141145,
                            "name": "Bellville",
                            "latitude": "40.62006000",
                            "longitude": "-82.51072000"
                        },
                        {
                            "id": 141146,
                            "name": "Belmont County",
                            "latitude": "40.01580000",
                            "longitude": "-80.98854000"
                        },
                        {
                            "id": 141147,
                            "name": "Belpre",
                            "latitude": "39.27396000",
                            "longitude": "-81.57290000"
                        },
                        {
                            "id": 141148,
                            "name": "Berea",
                            "latitude": "41.36616000",
                            "longitude": "-81.85430000"
                        },
                        {
                            "id": 141149,
                            "name": "Bethel",
                            "latitude": "38.96368000",
                            "longitude": "-84.08077000"
                        },
                        {
                            "id": 141150,
                            "name": "Bethesda",
                            "latitude": "40.01618000",
                            "longitude": "-81.07260000"
                        },
                        {
                            "id": 141151,
                            "name": "Beverly",
                            "latitude": "39.54785000",
                            "longitude": "-81.63957000"
                        },
                        {
                            "id": 141152,
                            "name": "Bexley",
                            "latitude": "39.96895000",
                            "longitude": "-82.93768000"
                        },
                        {
                            "id": 141153,
                            "name": "Blacklick Estates",
                            "latitude": "39.90506000",
                            "longitude": "-82.86434000"
                        },
                        {
                            "id": 141154,
                            "name": "Blanchester",
                            "latitude": "39.29312000",
                            "longitude": "-83.98882000"
                        },
                        {
                            "id": 141155,
                            "name": "Blue Ash",
                            "latitude": "39.23200000",
                            "longitude": "-84.37827000"
                        },
                        {
                            "id": 141156,
                            "name": "Bluffton",
                            "latitude": "40.89533000",
                            "longitude": "-83.88883000"
                        },
                        {
                            "id": 141157,
                            "name": "Boardman",
                            "latitude": "41.02423000",
                            "longitude": "-80.66285000"
                        },
                        {
                            "id": 141158,
                            "name": "Bolindale",
                            "latitude": "41.20728000",
                            "longitude": "-80.77758000"
                        },
                        {
                            "id": 141159,
                            "name": "Boston Heights",
                            "latitude": "41.26478000",
                            "longitude": "-81.51317000"
                        },
                        {
                            "id": 141160,
                            "name": "Botkins",
                            "latitude": "40.46783000",
                            "longitude": "-84.18050000"
                        },
                        {
                            "id": 141161,
                            "name": "Bowling Green",
                            "latitude": "41.37477000",
                            "longitude": "-83.65132000"
                        },
                        {
                            "id": 141162,
                            "name": "Bradford",
                            "latitude": "40.13227000",
                            "longitude": "-84.43078000"
                        },
                        {
                            "id": 141163,
                            "name": "Bradner",
                            "latitude": "41.32422000",
                            "longitude": "-83.43854000"
                        },
                        {
                            "id": 141164,
                            "name": "Bratenahl",
                            "latitude": "41.54255000",
                            "longitude": "-81.62624000"
                        },
                        {
                            "id": 141165,
                            "name": "Brecksville",
                            "latitude": "41.31978000",
                            "longitude": "-81.62679000"
                        },
                        {
                            "id": 141166,
                            "name": "Bremen",
                            "latitude": "39.70173000",
                            "longitude": "-82.42682000"
                        },
                        {
                            "id": 141167,
                            "name": "Brewster",
                            "latitude": "40.70700000",
                            "longitude": "-81.59818000"
                        },
                        {
                            "id": 141168,
                            "name": "Bridgeport",
                            "latitude": "40.06979000",
                            "longitude": "-80.74008000"
                        },
                        {
                            "id": 141169,
                            "name": "Bridgetown",
                            "latitude": "39.15311000",
                            "longitude": "-84.63717000"
                        },
                        {
                            "id": 141170,
                            "name": "Brilliant",
                            "latitude": "40.26479000",
                            "longitude": "-80.62619000"
                        },
                        {
                            "id": 141171,
                            "name": "Brimfield",
                            "latitude": "41.10006000",
                            "longitude": "-81.34650000"
                        },
                        {
                            "id": 141172,
                            "name": "Broadview Heights",
                            "latitude": "41.31394000",
                            "longitude": "-81.68513000"
                        },
                        {
                            "id": 141173,
                            "name": "Brook Park",
                            "latitude": "41.39838000",
                            "longitude": "-81.80458000"
                        },
                        {
                            "id": 141174,
                            "name": "Brookfield Center",
                            "latitude": "41.24061000",
                            "longitude": "-80.55785000"
                        },
                        {
                            "id": 141175,
                            "name": "Brooklyn",
                            "latitude": "41.43977000",
                            "longitude": "-81.73541000"
                        },
                        {
                            "id": 141176,
                            "name": "Brooklyn Heights",
                            "latitude": "41.42533000",
                            "longitude": "-81.68818000"
                        },
                        {
                            "id": 141177,
                            "name": "Brookville",
                            "latitude": "39.83672000",
                            "longitude": "-84.41134000"
                        },
                        {
                            "id": 141178,
                            "name": "Brown County",
                            "latitude": "38.93405000",
                            "longitude": "-83.86743000"
                        },
                        {
                            "id": 141179,
                            "name": "Brunswick",
                            "latitude": "41.23811000",
                            "longitude": "-81.84180000"
                        },
                        {
                            "id": 141180,
                            "name": "Bryan",
                            "latitude": "41.47477000",
                            "longitude": "-84.55245000"
                        },
                        {
                            "id": 141181,
                            "name": "Buckeye Lake",
                            "latitude": "39.93368000",
                            "longitude": "-82.47238000"
                        },
                        {
                            "id": 141182,
                            "name": "Bucyrus",
                            "latitude": "40.80839000",
                            "longitude": "-82.97546000"
                        },
                        {
                            "id": 141183,
                            "name": "Burlington",
                            "latitude": "38.40730000",
                            "longitude": "-82.53571000"
                        },
                        {
                            "id": 141184,
                            "name": "Burton",
                            "latitude": "41.47061000",
                            "longitude": "-81.14510000"
                        },
                        {
                            "id": 141185,
                            "name": "Butler County",
                            "latitude": "39.43865000",
                            "longitude": "-84.57566000"
                        },
                        {
                            "id": 141186,
                            "name": "Byesville",
                            "latitude": "39.96979000",
                            "longitude": "-81.53651000"
                        },
                        {
                            "id": 141187,
                            "name": "Cadiz",
                            "latitude": "40.27285000",
                            "longitude": "-80.99676000"
                        },
                        {
                            "id": 141188,
                            "name": "Calcutta",
                            "latitude": "40.67340000",
                            "longitude": "-80.57646000"
                        },
                        {
                            "id": 141189,
                            "name": "Caldwell",
                            "latitude": "39.74785000",
                            "longitude": "-81.51651000"
                        },
                        {
                            "id": 141190,
                            "name": "Cambridge",
                            "latitude": "40.03118000",
                            "longitude": "-81.58846000"
                        },
                        {
                            "id": 141191,
                            "name": "Camden",
                            "latitude": "39.62894000",
                            "longitude": "-84.64856000"
                        },
                        {
                            "id": 141192,
                            "name": "Campbell",
                            "latitude": "41.07839000",
                            "longitude": "-80.59924000"
                        },
                        {
                            "id": 141193,
                            "name": "Canal Fulton",
                            "latitude": "40.88978000",
                            "longitude": "-81.59762000"
                        },
                        {
                            "id": 141194,
                            "name": "Canal Winchester",
                            "latitude": "39.84284000",
                            "longitude": "-82.80462000"
                        },
                        {
                            "id": 141195,
                            "name": "Canfield",
                            "latitude": "41.02506000",
                            "longitude": "-80.76091000"
                        },
                        {
                            "id": 141196,
                            "name": "Canton",
                            "latitude": "40.79895000",
                            "longitude": "-81.37845000"
                        },
                        {
                            "id": 141197,
                            "name": "Cardington",
                            "latitude": "40.50062000",
                            "longitude": "-82.89351000"
                        },
                        {
                            "id": 141198,
                            "name": "Carey",
                            "latitude": "40.95256000",
                            "longitude": "-83.38242000"
                        },
                        {
                            "id": 141199,
                            "name": "Carlisle",
                            "latitude": "39.58200000",
                            "longitude": "-84.32022000"
                        },
                        {
                            "id": 141200,
                            "name": "Carroll County",
                            "latitude": "40.57959000",
                            "longitude": "-81.08972000"
                        },
                        {
                            "id": 141201,
                            "name": "Carrollton",
                            "latitude": "40.57284000",
                            "longitude": "-81.08565000"
                        },
                        {
                            "id": 141202,
                            "name": "Cedarville",
                            "latitude": "39.74423000",
                            "longitude": "-83.80854000"
                        },
                        {
                            "id": 141203,
                            "name": "Celina",
                            "latitude": "40.54894000",
                            "longitude": "-84.57023000"
                        },
                        {
                            "id": 141204,
                            "name": "Centerburg",
                            "latitude": "40.30451000",
                            "longitude": "-82.69628000"
                        },
                        {
                            "id": 141205,
                            "name": "Centerville",
                            "latitude": "39.62839000",
                            "longitude": "-84.15938000"
                        },
                        {
                            "id": 141206,
                            "name": "Chagrin Falls",
                            "latitude": "41.43616000",
                            "longitude": "-81.38650000"
                        },
                        {
                            "id": 141207,
                            "name": "Champaign County",
                            "latitude": "40.13767000",
                            "longitude": "-83.76950000"
                        },
                        {
                            "id": 141208,
                            "name": "Champion Heights",
                            "latitude": "41.28999000",
                            "longitude": "-80.84595000"
                        },
                        {
                            "id": 141209,
                            "name": "Chardon",
                            "latitude": "41.61422000",
                            "longitude": "-81.14899000"
                        },
                        {
                            "id": 141210,
                            "name": "Chauncey",
                            "latitude": "39.39785000",
                            "longitude": "-82.12931000"
                        },
                        {
                            "id": 141211,
                            "name": "Cherry Grove",
                            "latitude": "39.07256000",
                            "longitude": "-84.32188000"
                        },
                        {
                            "id": 141212,
                            "name": "Chesterland",
                            "latitude": "41.52227000",
                            "longitude": "-81.33789000"
                        },
                        {
                            "id": 141213,
                            "name": "Cheviot",
                            "latitude": "39.15700000",
                            "longitude": "-84.61328000"
                        },
                        {
                            "id": 141214,
                            "name": "Chillicothe",
                            "latitude": "39.33312000",
                            "longitude": "-82.98240000"
                        },
                        {
                            "id": 141215,
                            "name": "Choctaw Lake",
                            "latitude": "39.96006000",
                            "longitude": "-83.48492000"
                        },
                        {
                            "id": 141216,
                            "name": "Churchill",
                            "latitude": "41.16200000",
                            "longitude": "-80.66480000"
                        },
                        {
                            "id": 141217,
                            "name": "Cincinnati",
                            "latitude": "39.12711000",
                            "longitude": "-84.51439000"
                        },
                        {
                            "id": 141218,
                            "name": "Circleville",
                            "latitude": "39.60062000",
                            "longitude": "-82.94601000"
                        },
                        {
                            "id": 141219,
                            "name": "Clark County",
                            "latitude": "39.91678000",
                            "longitude": "-83.78390000"
                        },
                        {
                            "id": 141220,
                            "name": "Clark-Fulton",
                            "latitude": "41.46402000",
                            "longitude": "-81.70979000"
                        },
                        {
                            "id": 141221,
                            "name": "Clayton",
                            "latitude": "39.86311000",
                            "longitude": "-84.36050000"
                        },
                        {
                            "id": 141222,
                            "name": "Clermont County",
                            "latitude": "39.04743000",
                            "longitude": "-84.15192000"
                        },
                        {
                            "id": 141223,
                            "name": "Cleveland",
                            "latitude": "41.49950000",
                            "longitude": "-81.69541000"
                        },
                        {
                            "id": 141224,
                            "name": "Cleveland Heights",
                            "latitude": "41.52005000",
                            "longitude": "-81.55624000"
                        },
                        {
                            "id": 141225,
                            "name": "Cleves",
                            "latitude": "39.16172000",
                            "longitude": "-84.74912000"
                        },
                        {
                            "id": 141226,
                            "name": "Clinton",
                            "latitude": "40.92672000",
                            "longitude": "-81.63040000"
                        },
                        {
                            "id": 141227,
                            "name": "Clinton County",
                            "latitude": "39.41498000",
                            "longitude": "-83.80838000"
                        },
                        {
                            "id": 141228,
                            "name": "Clyde",
                            "latitude": "41.30422000",
                            "longitude": "-82.97519000"
                        },
                        {
                            "id": 141229,
                            "name": "Coal Grove",
                            "latitude": "38.50341000",
                            "longitude": "-82.64711000"
                        },
                        {
                            "id": 141230,
                            "name": "Coldwater",
                            "latitude": "40.47977000",
                            "longitude": "-84.62829000"
                        },
                        {
                            "id": 141231,
                            "name": "Collinwood",
                            "latitude": "41.55838000",
                            "longitude": "-81.56929000"
                        },
                        {
                            "id": 141232,
                            "name": "Columbiana",
                            "latitude": "40.88839000",
                            "longitude": "-80.69396000"
                        },
                        {
                            "id": 141233,
                            "name": "Columbiana County",
                            "latitude": "40.76843000",
                            "longitude": "-80.77719000"
                        },
                        {
                            "id": 141234,
                            "name": "Columbus",
                            "latitude": "39.96118000",
                            "longitude": "-82.99879000"
                        },
                        {
                            "id": 141235,
                            "name": "Columbus Grove",
                            "latitude": "40.91950000",
                            "longitude": "-84.05689000"
                        },
                        {
                            "id": 141236,
                            "name": "Commercial Point",
                            "latitude": "39.76840000",
                            "longitude": "-83.05713000"
                        },
                        {
                            "id": 141237,
                            "name": "Conneaut",
                            "latitude": "41.94756000",
                            "longitude": "-80.55424000"
                        },
                        {
                            "id": 141238,
                            "name": "Continental",
                            "latitude": "41.10033000",
                            "longitude": "-84.26634000"
                        },
                        {
                            "id": 141239,
                            "name": "Convoy",
                            "latitude": "40.91672000",
                            "longitude": "-84.70274000"
                        },
                        {
                            "id": 141240,
                            "name": "Copley",
                            "latitude": "41.09894000",
                            "longitude": "-81.64457000"
                        },
                        {
                            "id": 141241,
                            "name": "Cortland",
                            "latitude": "41.33033000",
                            "longitude": "-80.72536000"
                        },
                        {
                            "id": 141242,
                            "name": "Coshocton",
                            "latitude": "40.27202000",
                            "longitude": "-81.85958000"
                        },
                        {
                            "id": 141243,
                            "name": "Coshocton County",
                            "latitude": "40.30164000",
                            "longitude": "-81.92001000"
                        },
                        {
                            "id": 141244,
                            "name": "Covedale",
                            "latitude": "39.12117000",
                            "longitude": "-84.60633000"
                        },
                        {
                            "id": 141245,
                            "name": "Covington",
                            "latitude": "40.11727000",
                            "longitude": "-84.35384000"
                        },
                        {
                            "id": 141246,
                            "name": "Craig Beach",
                            "latitude": "41.11700000",
                            "longitude": "-80.98342000"
                        },
                        {
                            "id": 141247,
                            "name": "Crawford County",
                            "latitude": "40.85077000",
                            "longitude": "-82.91978000"
                        },
                        {
                            "id": 141248,
                            "name": "Crestline",
                            "latitude": "40.78756000",
                            "longitude": "-82.73657000"
                        },
                        {
                            "id": 141249,
                            "name": "Creston",
                            "latitude": "40.98700000",
                            "longitude": "-81.89375000"
                        },
                        {
                            "id": 141250,
                            "name": "Cridersville",
                            "latitude": "40.65422000",
                            "longitude": "-84.15078000"
                        },
                        {
                            "id": 141251,
                            "name": "Crooksville",
                            "latitude": "39.76896000",
                            "longitude": "-82.09209000"
                        },
                        {
                            "id": 141252,
                            "name": "Crystal Lakes",
                            "latitude": "39.88923000",
                            "longitude": "-84.02660000"
                        },
                        {
                            "id": 141253,
                            "name": "Curtice",
                            "latitude": "41.61838000",
                            "longitude": "-83.36771000"
                        },
                        {
                            "id": 141254,
                            "name": "Cuyahoga County",
                            "latitude": "41.47875000",
                            "longitude": "-81.67786000"
                        },
                        {
                            "id": 141255,
                            "name": "Cuyahoga Falls",
                            "latitude": "41.13394000",
                            "longitude": "-81.48456000"
                        },
                        {
                            "id": 141256,
                            "name": "Dalton",
                            "latitude": "40.79894000",
                            "longitude": "-81.69541000"
                        },
                        {
                            "id": 141257,
                            "name": "Danville",
                            "latitude": "40.44756000",
                            "longitude": "-82.26016000"
                        },
                        {
                            "id": 141258,
                            "name": "Darke County",
                            "latitude": "40.13323000",
                            "longitude": "-84.61931000"
                        },
                        {
                            "id": 141259,
                            "name": "Day Heights",
                            "latitude": "39.17395000",
                            "longitude": "-84.22633000"
                        },
                        {
                            "id": 141260,
                            "name": "Dayton",
                            "latitude": "39.75895000",
                            "longitude": "-84.19161000"
                        },
                        {
                            "id": 141261,
                            "name": "De Graff",
                            "latitude": "40.31200000",
                            "longitude": "-83.91577000"
                        },
                        {
                            "id": 141262,
                            "name": "Deer Park",
                            "latitude": "39.20534000",
                            "longitude": "-84.39466000"
                        },
                        {
                            "id": 141263,
                            "name": "Defiance",
                            "latitude": "41.28449000",
                            "longitude": "-84.35578000"
                        },
                        {
                            "id": 141264,
                            "name": "Defiance County",
                            "latitude": "41.32392000",
                            "longitude": "-84.49050000"
                        },
                        {
                            "id": 141265,
                            "name": "Delaware",
                            "latitude": "40.29867000",
                            "longitude": "-83.06797000"
                        },
                        {
                            "id": 141266,
                            "name": "Delaware County",
                            "latitude": "40.27839000",
                            "longitude": "-83.00489000"
                        },
                        {
                            "id": 141267,
                            "name": "Delhi Hills",
                            "latitude": "39.09284000",
                            "longitude": "-84.61272000"
                        },
                        {
                            "id": 141268,
                            "name": "Delphos",
                            "latitude": "40.84338000",
                            "longitude": "-84.34162000"
                        },
                        {
                            "id": 141269,
                            "name": "Delta",
                            "latitude": "41.57366000",
                            "longitude": "-84.00522000"
                        },
                        {
                            "id": 141270,
                            "name": "Dennison",
                            "latitude": "40.39340000",
                            "longitude": "-81.33372000"
                        },
                        {
                            "id": 141271,
                            "name": "Dent",
                            "latitude": "39.18589000",
                            "longitude": "-84.65134000"
                        },
                        {
                            "id": 141272,
                            "name": "Deshler",
                            "latitude": "41.20755000",
                            "longitude": "-83.89911000"
                        },
                        {
                            "id": 141273,
                            "name": "Detroit-Shoreway",
                            "latitude": "41.47772000",
                            "longitude": "-81.72991000"
                        },
                        {
                            "id": 141274,
                            "name": "Devola",
                            "latitude": "39.47369000",
                            "longitude": "-81.47901000"
                        },
                        {
                            "id": 141275,
                            "name": "Dillonvale",
                            "latitude": "39.21811000",
                            "longitude": "-84.40216000"
                        },
                        {
                            "id": 141276,
                            "name": "Dover",
                            "latitude": "40.52062000",
                            "longitude": "-81.47401000"
                        },
                        {
                            "id": 141277,
                            "name": "Doylestown",
                            "latitude": "40.97005000",
                            "longitude": "-81.69652000"
                        },
                        {
                            "id": 141278,
                            "name": "Dresden",
                            "latitude": "40.12146000",
                            "longitude": "-82.01069000"
                        },
                        {
                            "id": 141279,
                            "name": "Drexel",
                            "latitude": "39.74645000",
                            "longitude": "-84.28661000"
                        },
                        {
                            "id": 141280,
                            "name": "Dry Ridge",
                            "latitude": "39.25922000",
                            "longitude": "-84.61911000"
                        },
                        {
                            "id": 141281,
                            "name": "Dry Run",
                            "latitude": "39.10423000",
                            "longitude": "-84.33049000"
                        },
                        {
                            "id": 141282,
                            "name": "Dublin",
                            "latitude": "40.09923000",
                            "longitude": "-83.11408000"
                        },
                        {
                            "id": 141283,
                            "name": "Dunlap",
                            "latitude": "39.29228000",
                            "longitude": "-84.61800000"
                        },
                        {
                            "id": 141284,
                            "name": "East Canton",
                            "latitude": "40.78728000",
                            "longitude": "-81.28261000"
                        },
                        {
                            "id": 141285,
                            "name": "East Cleveland",
                            "latitude": "41.53311000",
                            "longitude": "-81.57901000"
                        },
                        {
                            "id": 141286,
                            "name": "East Liverpool",
                            "latitude": "40.61868000",
                            "longitude": "-80.57729000"
                        },
                        {
                            "id": 141287,
                            "name": "East Palestine",
                            "latitude": "40.83395000",
                            "longitude": "-80.54035000"
                        },
                        {
                            "id": 141288,
                            "name": "Eastlake",
                            "latitude": "41.65394000",
                            "longitude": "-81.45039000"
                        },
                        {
                            "id": 141289,
                            "name": "Eaton",
                            "latitude": "39.74394000",
                            "longitude": "-84.63662000"
                        },
                        {
                            "id": 141290,
                            "name": "Eaton Estates",
                            "latitude": "41.30894000",
                            "longitude": "-82.00570000"
                        },
                        {
                            "id": 141291,
                            "name": "Edgerton",
                            "latitude": "41.44866000",
                            "longitude": "-84.74801000"
                        },
                        {
                            "id": 141292,
                            "name": "Edgewood",
                            "latitude": "41.87283000",
                            "longitude": "-80.77286000"
                        },
                        {
                            "id": 141293,
                            "name": "Elida",
                            "latitude": "40.78866000",
                            "longitude": "-84.20384000"
                        },
                        {
                            "id": 141294,
                            "name": "Elmore",
                            "latitude": "41.47616000",
                            "longitude": "-83.29576000"
                        },
                        {
                            "id": 141295,
                            "name": "Elmwood Place",
                            "latitude": "39.18728000",
                            "longitude": "-84.48800000"
                        },
                        {
                            "id": 141296,
                            "name": "Elyria",
                            "latitude": "41.36838000",
                            "longitude": "-82.10765000"
                        },
                        {
                            "id": 141297,
                            "name": "Englewood",
                            "latitude": "39.87756000",
                            "longitude": "-84.30217000"
                        },
                        {
                            "id": 141298,
                            "name": "Enon",
                            "latitude": "39.87812000",
                            "longitude": "-83.93688000"
                        },
                        {
                            "id": 141299,
                            "name": "Erie County",
                            "latitude": "41.43209000",
                            "longitude": "-82.69958000"
                        },
                        {
                            "id": 141300,
                            "name": "Etna",
                            "latitude": "39.95729000",
                            "longitude": "-82.68183000"
                        },
                        {
                            "id": 141301,
                            "name": "Euclid",
                            "latitude": "41.59310000",
                            "longitude": "-81.52679000"
                        },
                        {
                            "id": 141302,
                            "name": "Evendale",
                            "latitude": "39.25617000",
                            "longitude": "-84.41800000"
                        },
                        {
                            "id": 141303,
                            "name": "Fairborn",
                            "latitude": "39.82089000",
                            "longitude": "-84.01938000"
                        },
                        {
                            "id": 141304,
                            "name": "Fairfax",
                            "latitude": "39.14534000",
                            "longitude": "-84.39327000"
                        },
                        {
                            "id": 141305,
                            "name": "Fairfield",
                            "latitude": "39.34589000",
                            "longitude": "-84.56050000"
                        },
                        {
                            "id": 141306,
                            "name": "Fairfield Beach",
                            "latitude": "39.91590000",
                            "longitude": "-82.47516000"
                        },
                        {
                            "id": 141307,
                            "name": "Fairfield County",
                            "latitude": "39.75160000",
                            "longitude": "-82.63059000"
                        },
                        {
                            "id": 141308,
                            "name": "Fairlawn",
                            "latitude": "41.12783000",
                            "longitude": "-81.60984000"
                        },
                        {
                            "id": 141309,
                            "name": "Fairport Harbor",
                            "latitude": "41.75004000",
                            "longitude": "-81.27399000"
                        },
                        {
                            "id": 141310,
                            "name": "Fairview Park",
                            "latitude": "41.44144000",
                            "longitude": "-81.86430000"
                        },
                        {
                            "id": 141311,
                            "name": "Farmersville",
                            "latitude": "39.67950000",
                            "longitude": "-84.42911000"
                        },
                        {
                            "id": 141312,
                            "name": "Fayette",
                            "latitude": "41.67338000",
                            "longitude": "-84.32689000"
                        },
                        {
                            "id": 141313,
                            "name": "Fayette County",
                            "latitude": "39.55988000",
                            "longitude": "-83.45610000"
                        },
                        {
                            "id": 141314,
                            "name": "Findlay",
                            "latitude": "41.04422000",
                            "longitude": "-83.64993000"
                        },
                        {
                            "id": 141315,
                            "name": "Finneytown",
                            "latitude": "39.20034000",
                            "longitude": "-84.52050000"
                        },
                        {
                            "id": 141316,
                            "name": "Five Points",
                            "latitude": "39.56867000",
                            "longitude": "-84.19299000"
                        },
                        {
                            "id": 141317,
                            "name": "Forest",
                            "latitude": "40.80172000",
                            "longitude": "-83.51048000"
                        },
                        {
                            "id": 141318,
                            "name": "Forest Park",
                            "latitude": "39.29034000",
                            "longitude": "-84.50411000"
                        },
                        {
                            "id": 141319,
                            "name": "Forestville",
                            "latitude": "39.07506000",
                            "longitude": "-84.34494000"
                        },
                        {
                            "id": 141320,
                            "name": "Fort Loramie",
                            "latitude": "40.35144000",
                            "longitude": "-84.37384000"
                        },
                        {
                            "id": 141321,
                            "name": "Fort McKinley",
                            "latitude": "39.79756000",
                            "longitude": "-84.25355000"
                        },
                        {
                            "id": 141322,
                            "name": "Fort Recovery",
                            "latitude": "40.41282000",
                            "longitude": "-84.77635000"
                        },
                        {
                            "id": 141323,
                            "name": "Fort Shawnee",
                            "latitude": "40.68672000",
                            "longitude": "-84.13773000"
                        },
                        {
                            "id": 141324,
                            "name": "Fostoria",
                            "latitude": "41.15700000",
                            "longitude": "-83.41687000"
                        },
                        {
                            "id": 141325,
                            "name": "Frankfort",
                            "latitude": "39.40145000",
                            "longitude": "-83.18074000"
                        },
                        {
                            "id": 141326,
                            "name": "Franklin",
                            "latitude": "39.55895000",
                            "longitude": "-84.30411000"
                        },
                        {
                            "id": 141327,
                            "name": "Franklin County",
                            "latitude": "39.96952000",
                            "longitude": "-83.00935000"
                        },
                        {
                            "id": 141328,
                            "name": "Franklin Furnace",
                            "latitude": "38.64508000",
                            "longitude": "-82.84878000"
                        },
                        {
                            "id": 141329,
                            "name": "Frazeysburg",
                            "latitude": "40.11729000",
                            "longitude": "-82.11931000"
                        },
                        {
                            "id": 141330,
                            "name": "Fredericktown",
                            "latitude": "40.48117000",
                            "longitude": "-82.54072000"
                        },
                        {
                            "id": 141331,
                            "name": "Fremont",
                            "latitude": "41.35033000",
                            "longitude": "-83.12186000"
                        },
                        {
                            "id": 141332,
                            "name": "Fruit Hill",
                            "latitude": "39.07562000",
                            "longitude": "-84.36438000"
                        },
                        {
                            "id": 141333,
                            "name": "Fulton County",
                            "latitude": "41.60180000",
                            "longitude": "-84.13007000"
                        },
                        {
                            "id": 141334,
                            "name": "Gahanna",
                            "latitude": "40.01923000",
                            "longitude": "-82.87934000"
                        },
                        {
                            "id": 141335,
                            "name": "Galion",
                            "latitude": "40.73367000",
                            "longitude": "-82.78990000"
                        },
                        {
                            "id": 141336,
                            "name": "Gallia County",
                            "latitude": "38.82467000",
                            "longitude": "-82.31691000"
                        },
                        {
                            "id": 141337,
                            "name": "Gallipolis",
                            "latitude": "38.80980000",
                            "longitude": "-82.20237000"
                        },
                        {
                            "id": 141338,
                            "name": "Gambier",
                            "latitude": "40.37562000",
                            "longitude": "-82.39710000"
                        },
                        {
                            "id": 141339,
                            "name": "Garfield Heights",
                            "latitude": "41.41700000",
                            "longitude": "-81.60596000"
                        },
                        {
                            "id": 141340,
                            "name": "Garrettsville",
                            "latitude": "41.28422000",
                            "longitude": "-81.09649000"
                        },
                        {
                            "id": 141341,
                            "name": "Gates Mills",
                            "latitude": "41.51755000",
                            "longitude": "-81.40345000"
                        },
                        {
                            "id": 141342,
                            "name": "Geauga County",
                            "latitude": "41.49954000",
                            "longitude": "-81.17865000"
                        },
                        {
                            "id": 141343,
                            "name": "Geneva",
                            "latitude": "41.80505000",
                            "longitude": "-80.94815000"
                        },
                        {
                            "id": 141344,
                            "name": "Geneva-on-the-Lake",
                            "latitude": "41.85950000",
                            "longitude": "-80.95398000"
                        },
                        {
                            "id": 141345,
                            "name": "Genoa",
                            "latitude": "41.51811000",
                            "longitude": "-83.35909000"
                        },
                        {
                            "id": 141346,
                            "name": "Georgetown",
                            "latitude": "38.86451000",
                            "longitude": "-83.90409000"
                        },
                        {
                            "id": 141347,
                            "name": "Germantown",
                            "latitude": "39.62617000",
                            "longitude": "-84.36939000"
                        },
                        {
                            "id": 141348,
                            "name": "Gibsonburg",
                            "latitude": "41.38450000",
                            "longitude": "-83.32048000"
                        },
                        {
                            "id": 141349,
                            "name": "Girard",
                            "latitude": "41.15395000",
                            "longitude": "-80.70147000"
                        },
                        {
                            "id": 141350,
                            "name": "Glandorf",
                            "latitude": "41.02894000",
                            "longitude": "-84.07911000"
                        },
                        {
                            "id": 141351,
                            "name": "Glendale",
                            "latitude": "39.27061000",
                            "longitude": "-84.45939000"
                        },
                        {
                            "id": 141352,
                            "name": "Glenmoor",
                            "latitude": "40.66617000",
                            "longitude": "-80.62313000"
                        },
                        {
                            "id": 141353,
                            "name": "Glenville",
                            "latitude": "41.53338000",
                            "longitude": "-81.61735000"
                        },
                        {
                            "id": 141354,
                            "name": "Glouster",
                            "latitude": "39.50313000",
                            "longitude": "-82.08459000"
                        },
                        {
                            "id": 141355,
                            "name": "Gnadenhutten",
                            "latitude": "40.35840000",
                            "longitude": "-81.43428000"
                        },
                        {
                            "id": 141356,
                            "name": "Golf Manor",
                            "latitude": "39.18728000",
                            "longitude": "-84.44633000"
                        },
                        {
                            "id": 141357,
                            "name": "Goshen",
                            "latitude": "39.23339000",
                            "longitude": "-84.16132000"
                        },
                        {
                            "id": 141358,
                            "name": "Grafton",
                            "latitude": "41.27255000",
                            "longitude": "-82.05459000"
                        },
                        {
                            "id": 141359,
                            "name": "Grandview",
                            "latitude": "39.19422000",
                            "longitude": "-84.72439000"
                        },
                        {
                            "id": 141360,
                            "name": "Grandview Heights",
                            "latitude": "39.97979000",
                            "longitude": "-83.04074000"
                        },
                        {
                            "id": 141361,
                            "name": "Granville",
                            "latitude": "40.06812000",
                            "longitude": "-82.51960000"
                        },
                        {
                            "id": 141362,
                            "name": "Granville South",
                            "latitude": "40.05207000",
                            "longitude": "-82.54166000"
                        },
                        {
                            "id": 141363,
                            "name": "Green",
                            "latitude": "40.94589000",
                            "longitude": "-81.48317000"
                        },
                        {
                            "id": 141364,
                            "name": "Green Meadows",
                            "latitude": "39.86895000",
                            "longitude": "-83.94438000"
                        },
                        {
                            "id": 141365,
                            "name": "Green Springs",
                            "latitude": "41.25616000",
                            "longitude": "-83.05158000"
                        },
                        {
                            "id": 141366,
                            "name": "Greene County",
                            "latitude": "39.69148000",
                            "longitude": "-83.88989000"
                        },
                        {
                            "id": 141367,
                            "name": "Greenfield",
                            "latitude": "39.35201000",
                            "longitude": "-83.38269000"
                        },
                        {
                            "id": 141368,
                            "name": "Greenhills",
                            "latitude": "39.26811000",
                            "longitude": "-84.52300000"
                        },
                        {
                            "id": 141369,
                            "name": "Greensburg",
                            "latitude": "40.93172000",
                            "longitude": "-81.46484000"
                        },
                        {
                            "id": 141370,
                            "name": "Greentown",
                            "latitude": "40.92756000",
                            "longitude": "-81.40261000"
                        },
                        {
                            "id": 141371,
                            "name": "Greenville",
                            "latitude": "40.10283000",
                            "longitude": "-84.63301000"
                        },
                        {
                            "id": 141372,
                            "name": "Greenwich",
                            "latitude": "41.03005000",
                            "longitude": "-82.51573000"
                        },
                        {
                            "id": 141373,
                            "name": "Groesbeck",
                            "latitude": "39.22311000",
                            "longitude": "-84.58689000"
                        },
                        {
                            "id": 141374,
                            "name": "Grove City",
                            "latitude": "39.88145000",
                            "longitude": "-83.09296000"
                        },
                        {
                            "id": 141375,
                            "name": "Groveport",
                            "latitude": "39.87840000",
                            "longitude": "-82.88379000"
                        },
                        {
                            "id": 141376,
                            "name": "Guernsey County",
                            "latitude": "40.05205000",
                            "longitude": "-81.49426000"
                        },
                        {
                            "id": 141377,
                            "name": "Hamilton",
                            "latitude": "39.39950000",
                            "longitude": "-84.56134000"
                        },
                        {
                            "id": 141378,
                            "name": "Hamilton County",
                            "latitude": "39.19553000",
                            "longitude": "-84.54277000"
                        },
                        {
                            "id": 141379,
                            "name": "Hancock County",
                            "latitude": "41.00194000",
                            "longitude": "-83.66654000"
                        },
                        {
                            "id": 141380,
                            "name": "Hanover",
                            "latitude": "40.07979000",
                            "longitude": "-82.26098000"
                        },
                        {
                            "id": 141381,
                            "name": "Harbor Hills",
                            "latitude": "39.93673000",
                            "longitude": "-82.43515000"
                        },
                        {
                            "id": 141382,
                            "name": "Hardin County",
                            "latitude": "40.66151000",
                            "longitude": "-83.65944000"
                        },
                        {
                            "id": 141383,
                            "name": "Harrison",
                            "latitude": "39.26200000",
                            "longitude": "-84.81995000"
                        },
                        {
                            "id": 141384,
                            "name": "Harrison County",
                            "latitude": "40.29384000",
                            "longitude": "-81.09114000"
                        },
                        {
                            "id": 141385,
                            "name": "Hartville",
                            "latitude": "40.96367000",
                            "longitude": "-81.33122000"
                        },
                        {
                            "id": 141386,
                            "name": "Haskins",
                            "latitude": "41.46477000",
                            "longitude": "-83.70605000"
                        },
                        {
                            "id": 141387,
                            "name": "Heath",
                            "latitude": "40.02284000",
                            "longitude": "-82.44460000"
                        },
                        {
                            "id": 141388,
                            "name": "Hebron",
                            "latitude": "39.96173000",
                            "longitude": "-82.49127000"
                        },
                        {
                            "id": 141389,
                            "name": "Henry County",
                            "latitude": "41.33389000",
                            "longitude": "-84.06823000"
                        },
                        {
                            "id": 141390,
                            "name": "Hicksville",
                            "latitude": "41.29311000",
                            "longitude": "-84.76190000"
                        },
                        {
                            "id": 141391,
                            "name": "Highland County",
                            "latitude": "39.18474000",
                            "longitude": "-83.60097000"
                        },
                        {
                            "id": 141392,
                            "name": "Highland Heights",
                            "latitude": "41.55200000",
                            "longitude": "-81.47845000"
                        },
                        {
                            "id": 141393,
                            "name": "Highpoint",
                            "latitude": "39.28839000",
                            "longitude": "-84.35022000"
                        },
                        {
                            "id": 141394,
                            "name": "Hilliard",
                            "latitude": "40.03340000",
                            "longitude": "-83.15825000"
                        },
                        {
                            "id": 141395,
                            "name": "Hillsboro",
                            "latitude": "39.20229000",
                            "longitude": "-83.61159000"
                        },
                        {
                            "id": 141396,
                            "name": "Hiram",
                            "latitude": "41.31256000",
                            "longitude": "-81.14371000"
                        },
                        {
                            "id": 141397,
                            "name": "Hocking County",
                            "latitude": "39.49702000",
                            "longitude": "-82.47925000"
                        },
                        {
                            "id": 141398,
                            "name": "Holgate",
                            "latitude": "41.24894000",
                            "longitude": "-84.13300000"
                        },
                        {
                            "id": 141399,
                            "name": "Holiday Valley",
                            "latitude": "39.85617000",
                            "longitude": "-83.96854000"
                        },
                        {
                            "id": 141400,
                            "name": "Holland",
                            "latitude": "41.62172000",
                            "longitude": "-83.71160000"
                        },
                        {
                            "id": 141401,
                            "name": "Holmes County",
                            "latitude": "40.56120000",
                            "longitude": "-81.92936000"
                        },
                        {
                            "id": 141402,
                            "name": "Hough",
                            "latitude": "41.51200000",
                            "longitude": "-81.63652000"
                        },
                        {
                            "id": 141403,
                            "name": "Howland Center",
                            "latitude": "41.25117000",
                            "longitude": "-80.74536000"
                        },
                        {
                            "id": 141404,
                            "name": "Hubbard",
                            "latitude": "41.15645000",
                            "longitude": "-80.56924000"
                        },
                        {
                            "id": 141405,
                            "name": "Huber Heights",
                            "latitude": "39.84395000",
                            "longitude": "-84.12466000"
                        },
                        {
                            "id": 141406,
                            "name": "Huber Ridge",
                            "latitude": "40.08867000",
                            "longitude": "-82.91657000"
                        },
                        {
                            "id": 141407,
                            "name": "Hudson",
                            "latitude": "41.24006000",
                            "longitude": "-81.44067000"
                        },
                        {
                            "id": 141408,
                            "name": "Hunter",
                            "latitude": "39.49284000",
                            "longitude": "-84.28966000"
                        },
                        {
                            "id": 141409,
                            "name": "Huron",
                            "latitude": "41.39505000",
                            "longitude": "-82.55517000"
                        },
                        {
                            "id": 141410,
                            "name": "Huron County",
                            "latitude": "41.14615000",
                            "longitude": "-82.59841000"
                        },
                        {
                            "id": 141411,
                            "name": "Independence",
                            "latitude": "41.36866000",
                            "longitude": "-81.63790000"
                        },
                        {
                            "id": 141412,
                            "name": "Ironton",
                            "latitude": "38.53675000",
                            "longitude": "-82.68294000"
                        },
                        {
                            "id": 141413,
                            "name": "Jackson",
                            "latitude": "39.05202000",
                            "longitude": "-82.63655000"
                        },
                        {
                            "id": 141414,
                            "name": "Jackson Center",
                            "latitude": "40.43949000",
                            "longitude": "-84.04022000"
                        },
                        {
                            "id": 141415,
                            "name": "Jackson County",
                            "latitude": "39.01967000",
                            "longitude": "-82.61838000"
                        },
                        {
                            "id": 141416,
                            "name": "Jamestown",
                            "latitude": "39.65812000",
                            "longitude": "-83.73492000"
                        },
                        {
                            "id": 141417,
                            "name": "Jefferson",
                            "latitude": "41.73867000",
                            "longitude": "-80.76981000"
                        },
                        {
                            "id": 141418,
                            "name": "Jefferson County",
                            "latitude": "40.38502000",
                            "longitude": "-80.76097000"
                        },
                        {
                            "id": 141419,
                            "name": "Jeffersonville",
                            "latitude": "39.65367000",
                            "longitude": "-83.56381000"
                        },
                        {
                            "id": 141420,
                            "name": "Johnstown",
                            "latitude": "40.15367000",
                            "longitude": "-82.68517000"
                        },
                        {
                            "id": 141421,
                            "name": "Kalida",
                            "latitude": "40.98283000",
                            "longitude": "-84.19939000"
                        },
                        {
                            "id": 141422,
                            "name": "Kent",
                            "latitude": "41.15367000",
                            "longitude": "-81.35789000"
                        },
                        {
                            "id": 141423,
                            "name": "Kenton",
                            "latitude": "40.64700000",
                            "longitude": "-83.60965000"
                        },
                        {
                            "id": 141424,
                            "name": "Kenwood",
                            "latitude": "39.21061000",
                            "longitude": "-84.36716000"
                        },
                        {
                            "id": 141425,
                            "name": "Kettering",
                            "latitude": "39.68950000",
                            "longitude": "-84.16883000"
                        },
                        {
                            "id": 141426,
                            "name": "Kings Mills",
                            "latitude": "39.35561000",
                            "longitude": "-84.24855000"
                        },
                        {
                            "id": 141427,
                            "name": "Kingston",
                            "latitude": "39.47395000",
                            "longitude": "-82.91073000"
                        },
                        {
                            "id": 141428,
                            "name": "Kirtland",
                            "latitude": "41.62894000",
                            "longitude": "-81.36150000"
                        },
                        {
                            "id": 141429,
                            "name": "Knox County",
                            "latitude": "40.39877000",
                            "longitude": "-82.42153000"
                        },
                        {
                            "id": 141430,
                            "name": "La Croft",
                            "latitude": "40.64590000",
                            "longitude": "-80.59785000"
                        },
                        {
                            "id": 141431,
                            "name": "Lagrange",
                            "latitude": "41.23728000",
                            "longitude": "-82.11987000"
                        },
                        {
                            "id": 141432,
                            "name": "Lake County",
                            "latitude": "41.71393000",
                            "longitude": "-81.24527000"
                        },
                        {
                            "id": 141433,
                            "name": "Lake Darby",
                            "latitude": "39.95728000",
                            "longitude": "-83.22880000"
                        },
                        {
                            "id": 141434,
                            "name": "Lake Lakengren",
                            "latitude": "39.68843000",
                            "longitude": "-84.69347000"
                        },
                        {
                            "id": 141435,
                            "name": "Lake Mohawk",
                            "latitude": "40.66673000",
                            "longitude": "-81.19927000"
                        },
                        {
                            "id": 141436,
                            "name": "Lakemore",
                            "latitude": "41.02089000",
                            "longitude": "-81.43595000"
                        },
                        {
                            "id": 141437,
                            "name": "Lakeview",
                            "latitude": "40.48477000",
                            "longitude": "-83.92300000"
                        },
                        {
                            "id": 141438,
                            "name": "Lakewood",
                            "latitude": "41.48199000",
                            "longitude": "-81.79819000"
                        },
                        {
                            "id": 141439,
                            "name": "Lancaster",
                            "latitude": "39.71368000",
                            "longitude": "-82.59933000"
                        },
                        {
                            "id": 141440,
                            "name": "Landen",
                            "latitude": "39.31200000",
                            "longitude": "-84.28299000"
                        },
                        {
                            "id": 141441,
                            "name": "Lawrence County",
                            "latitude": "38.59847000",
                            "longitude": "-82.53675000"
                        },
                        {
                            "id": 141442,
                            "name": "Leavittsburg",
                            "latitude": "41.24783000",
                            "longitude": "-80.87703000"
                        },
                        {
                            "id": 141443,
                            "name": "Lebanon",
                            "latitude": "39.43534000",
                            "longitude": "-84.20299000"
                        },
                        {
                            "id": 141444,
                            "name": "Leesburg",
                            "latitude": "39.34506000",
                            "longitude": "-83.55297000"
                        },
                        {
                            "id": 141445,
                            "name": "Leetonia",
                            "latitude": "40.87728000",
                            "longitude": "-80.75536000"
                        },
                        {
                            "id": 141446,
                            "name": "Leipsic",
                            "latitude": "41.09838000",
                            "longitude": "-83.98467000"
                        },
                        {
                            "id": 141447,
                            "name": "Lewis Center",
                            "latitude": "40.19840000",
                            "longitude": "-83.01018000"
                        },
                        {
                            "id": 141448,
                            "name": "Lewisburg",
                            "latitude": "39.84616000",
                            "longitude": "-84.53967000"
                        },
                        {
                            "id": 141449,
                            "name": "Lexington",
                            "latitude": "40.67867000",
                            "longitude": "-82.58239000"
                        },
                        {
                            "id": 141450,
                            "name": "Liberty Center",
                            "latitude": "41.44338000",
                            "longitude": "-84.00883000"
                        },
                        {
                            "id": 141451,
                            "name": "Licking County",
                            "latitude": "40.09161000",
                            "longitude": "-82.48315000"
                        },
                        {
                            "id": 141452,
                            "name": "Lima",
                            "latitude": "40.74255000",
                            "longitude": "-84.10523000"
                        },
                        {
                            "id": 141453,
                            "name": "Lincoln Heights",
                            "latitude": "39.23895000",
                            "longitude": "-84.45550000"
                        },
                        {
                            "id": 141454,
                            "name": "Lincoln Village",
                            "latitude": "39.95479000",
                            "longitude": "-83.13074000"
                        },
                        {
                            "id": 141455,
                            "name": "Lisbon",
                            "latitude": "39.86089000",
                            "longitude": "-83.63520000"
                        },
                        {
                            "id": 141456,
                            "name": "Lithopolis",
                            "latitude": "39.80284000",
                            "longitude": "-82.80628000"
                        },
                        {
                            "id": 141457,
                            "name": "Lockland",
                            "latitude": "39.22922000",
                            "longitude": "-84.45772000"
                        },
                        {
                            "id": 141458,
                            "name": "Lodi",
                            "latitude": "41.03339000",
                            "longitude": "-82.01209000"
                        },
                        {
                            "id": 141459,
                            "name": "Logan",
                            "latitude": "39.54007000",
                            "longitude": "-82.40710000"
                        },
                        {
                            "id": 141460,
                            "name": "Logan County",
                            "latitude": "40.38845000",
                            "longitude": "-83.76587000"
                        },
                        {
                            "id": 141461,
                            "name": "Logan Elm Village",
                            "latitude": "39.56978000",
                            "longitude": "-82.95185000"
                        },
                        {
                            "id": 141462,
                            "name": "London",
                            "latitude": "39.88645000",
                            "longitude": "-83.44825000"
                        },
                        {
                            "id": 141463,
                            "name": "Lorain",
                            "latitude": "41.45282000",
                            "longitude": "-82.18237000"
                        },
                        {
                            "id": 141464,
                            "name": "Lorain County",
                            "latitude": "41.45252000",
                            "longitude": "-82.15147000"
                        },
                        {
                            "id": 141465,
                            "name": "Lordstown",
                            "latitude": "41.16561000",
                            "longitude": "-80.85758000"
                        },
                        {
                            "id": 141466,
                            "name": "Loudonville",
                            "latitude": "40.63534000",
                            "longitude": "-82.23321000"
                        },
                        {
                            "id": 141467,
                            "name": "Louisville",
                            "latitude": "40.83728000",
                            "longitude": "-81.25955000"
                        },
                        {
                            "id": 141468,
                            "name": "Loveland",
                            "latitude": "39.26895000",
                            "longitude": "-84.26383000"
                        },
                        {
                            "id": 141469,
                            "name": "Loveland Park",
                            "latitude": "39.29978000",
                            "longitude": "-84.26327000"
                        },
                        {
                            "id": 141470,
                            "name": "Lowellville",
                            "latitude": "41.03534000",
                            "longitude": "-80.53646000"
                        },
                        {
                            "id": 141471,
                            "name": "Lucas County",
                            "latitude": "41.68419000",
                            "longitude": "-83.46826000"
                        },
                        {
                            "id": 141472,
                            "name": "Lucasville",
                            "latitude": "38.87952000",
                            "longitude": "-82.99684000"
                        },
                        {
                            "id": 141473,
                            "name": "Luckey",
                            "latitude": "41.45061000",
                            "longitude": "-83.48743000"
                        },
                        {
                            "id": 141474,
                            "name": "Lynchburg",
                            "latitude": "39.24173000",
                            "longitude": "-83.79131000"
                        },
                        {
                            "id": 141475,
                            "name": "Lyndhurst",
                            "latitude": "41.52005000",
                            "longitude": "-81.48873000"
                        },
                        {
                            "id": 141476,
                            "name": "Macedonia",
                            "latitude": "41.31367000",
                            "longitude": "-81.50845000"
                        },
                        {
                            "id": 141477,
                            "name": "Mack",
                            "latitude": "39.15811000",
                            "longitude": "-84.64967000"
                        },
                        {
                            "id": 141478,
                            "name": "Madeira",
                            "latitude": "39.19089000",
                            "longitude": "-84.36355000"
                        },
                        {
                            "id": 141479,
                            "name": "Madison",
                            "latitude": "41.77116000",
                            "longitude": "-81.04982000"
                        },
                        {
                            "id": 141480,
                            "name": "Madison County",
                            "latitude": "39.89403000",
                            "longitude": "-83.40020000"
                        },
                        {
                            "id": 141481,
                            "name": "Mahoning County",
                            "latitude": "41.01464000",
                            "longitude": "-80.77629000"
                        },
                        {
                            "id": 141482,
                            "name": "Malvern",
                            "latitude": "40.69173000",
                            "longitude": "-81.18121000"
                        },
                        {
                            "id": 141483,
                            "name": "Manchester",
                            "latitude": "38.68813000",
                            "longitude": "-83.60936000"
                        },
                        {
                            "id": 141484,
                            "name": "Mansfield",
                            "latitude": "40.75839000",
                            "longitude": "-82.51545000"
                        },
                        {
                            "id": 141485,
                            "name": "Mantua",
                            "latitude": "41.28394000",
                            "longitude": "-81.22399000"
                        },
                        {
                            "id": 141486,
                            "name": "Maple Heights",
                            "latitude": "41.41533000",
                            "longitude": "-81.56596000"
                        },
                        {
                            "id": 141487,
                            "name": "Mariemont",
                            "latitude": "39.14506000",
                            "longitude": "-84.37438000"
                        },
                        {
                            "id": 141488,
                            "name": "Marietta",
                            "latitude": "39.41535000",
                            "longitude": "-81.45484000"
                        },
                        {
                            "id": 141489,
                            "name": "Marion",
                            "latitude": "40.58867000",
                            "longitude": "-83.12852000"
                        },
                        {
                            "id": 141490,
                            "name": "Marion County",
                            "latitude": "40.58719000",
                            "longitude": "-83.16087000"
                        },
                        {
                            "id": 141491,
                            "name": "Martins Ferry",
                            "latitude": "40.09591000",
                            "longitude": "-80.72453000"
                        },
                        {
                            "id": 141492,
                            "name": "Marysville",
                            "latitude": "40.23645000",
                            "longitude": "-83.36714000"
                        },
                        {
                            "id": 141493,
                            "name": "Mason",
                            "latitude": "39.36006000",
                            "longitude": "-84.30994000"
                        },
                        {
                            "id": 141494,
                            "name": "Massillon",
                            "latitude": "40.79672000",
                            "longitude": "-81.52151000"
                        },
                        {
                            "id": 141495,
                            "name": "Masury",
                            "latitude": "41.21117000",
                            "longitude": "-80.53785000"
                        },
                        {
                            "id": 141496,
                            "name": "Maumee",
                            "latitude": "41.56283000",
                            "longitude": "-83.65382000"
                        },
                        {
                            "id": 141497,
                            "name": "Mayfield",
                            "latitude": "41.55200000",
                            "longitude": "-81.43928000"
                        },
                        {
                            "id": 141498,
                            "name": "Mayfield Heights",
                            "latitude": "41.51922000",
                            "longitude": "-81.45790000"
                        },
                        {
                            "id": 141499,
                            "name": "McArthur",
                            "latitude": "39.24646000",
                            "longitude": "-82.47849000"
                        },
                        {
                            "id": 141500,
                            "name": "McComb",
                            "latitude": "41.10755000",
                            "longitude": "-83.79271000"
                        },
                        {
                            "id": 141501,
                            "name": "McConnelsville",
                            "latitude": "39.64868000",
                            "longitude": "-81.85319000"
                        },
                        {
                            "id": 141502,
                            "name": "McDonald",
                            "latitude": "41.16367000",
                            "longitude": "-80.72424000"
                        },
                        {
                            "id": 141503,
                            "name": "McKinley Heights",
                            "latitude": "41.18367000",
                            "longitude": "-80.71730000"
                        },
                        {
                            "id": 141504,
                            "name": "Mechanicsburg",
                            "latitude": "40.07200000",
                            "longitude": "-83.55631000"
                        },
                        {
                            "id": 141505,
                            "name": "Medina",
                            "latitude": "41.13839000",
                            "longitude": "-81.86375000"
                        },
                        {
                            "id": 141506,
                            "name": "Medina County",
                            "latitude": "41.11759000",
                            "longitude": "-81.89971000"
                        },
                        {
                            "id": 141507,
                            "name": "Meigs County",
                            "latitude": "39.08224000",
                            "longitude": "-82.02290000"
                        },
                        {
                            "id": 141508,
                            "name": "Mentor",
                            "latitude": "41.66616000",
                            "longitude": "-81.33955000"
                        },
                        {
                            "id": 141509,
                            "name": "Mentor-on-the-Lake",
                            "latitude": "41.70504000",
                            "longitude": "-81.36039000"
                        },
                        {
                            "id": 141510,
                            "name": "Mercer County",
                            "latitude": "40.54001000",
                            "longitude": "-84.62936000"
                        },
                        {
                            "id": 141511,
                            "name": "Miami County",
                            "latitude": "40.05345000",
                            "longitude": "-84.22885000"
                        },
                        {
                            "id": 141512,
                            "name": "Miami Heights",
                            "latitude": "39.16506000",
                            "longitude": "-84.72050000"
                        },
                        {
                            "id": 141513,
                            "name": "Miamisburg",
                            "latitude": "39.64284000",
                            "longitude": "-84.28661000"
                        },
                        {
                            "id": 141514,
                            "name": "Miamitown",
                            "latitude": "39.21589000",
                            "longitude": "-84.70411000"
                        },
                        {
                            "id": 141515,
                            "name": "Middleburg Heights",
                            "latitude": "41.36144000",
                            "longitude": "-81.81291000"
                        },
                        {
                            "id": 141516,
                            "name": "Middlefield",
                            "latitude": "41.46200000",
                            "longitude": "-81.07371000"
                        },
                        {
                            "id": 141517,
                            "name": "Middleport",
                            "latitude": "39.00175000",
                            "longitude": "-82.04875000"
                        },
                        {
                            "id": 141518,
                            "name": "Middletown",
                            "latitude": "39.51506000",
                            "longitude": "-84.39828000"
                        },
                        {
                            "id": 141519,
                            "name": "Milan",
                            "latitude": "41.29755000",
                            "longitude": "-82.60545000"
                        },
                        {
                            "id": 141520,
                            "name": "Milford",
                            "latitude": "39.17534000",
                            "longitude": "-84.29438000"
                        },
                        {
                            "id": 141521,
                            "name": "Millbury",
                            "latitude": "41.56616000",
                            "longitude": "-83.42465000"
                        },
                        {
                            "id": 141522,
                            "name": "Millersburg",
                            "latitude": "40.55451000",
                            "longitude": "-81.91792000"
                        },
                        {
                            "id": 141523,
                            "name": "Millersport",
                            "latitude": "39.90006000",
                            "longitude": "-82.53405000"
                        },
                        {
                            "id": 141524,
                            "name": "Mineral Ridge",
                            "latitude": "41.14006000",
                            "longitude": "-80.76897000"
                        },
                        {
                            "id": 141525,
                            "name": "Minerva",
                            "latitude": "40.72978000",
                            "longitude": "-81.10538000"
                        },
                        {
                            "id": 141526,
                            "name": "Minerva Park",
                            "latitude": "40.07645000",
                            "longitude": "-82.94379000"
                        },
                        {
                            "id": 141527,
                            "name": "Mingo Junction",
                            "latitude": "40.32174000",
                            "longitude": "-80.60980000"
                        },
                        {
                            "id": 141528,
                            "name": "Minster",
                            "latitude": "40.39310000",
                            "longitude": "-84.37606000"
                        },
                        {
                            "id": 141529,
                            "name": "Mogadore",
                            "latitude": "41.04645000",
                            "longitude": "-81.39789000"
                        },
                        {
                            "id": 141530,
                            "name": "Monfort Heights",
                            "latitude": "39.18839000",
                            "longitude": "-84.59522000"
                        },
                        {
                            "id": 141531,
                            "name": "Monroe",
                            "latitude": "39.44034000",
                            "longitude": "-84.36216000"
                        },
                        {
                            "id": 141532,
                            "name": "Monroe County",
                            "latitude": "39.72735000",
                            "longitude": "-81.08292000"
                        },
                        {
                            "id": 141533,
                            "name": "Monroeville",
                            "latitude": "41.24422000",
                            "longitude": "-82.69629000"
                        },
                        {
                            "id": 141534,
                            "name": "Montgomery",
                            "latitude": "39.22811000",
                            "longitude": "-84.35411000"
                        },
                        {
                            "id": 141535,
                            "name": "Montgomery County",
                            "latitude": "39.75459000",
                            "longitude": "-84.29068000"
                        },
                        {
                            "id": 141536,
                            "name": "Montpelier",
                            "latitude": "41.58450000",
                            "longitude": "-84.60551000"
                        },
                        {
                            "id": 141537,
                            "name": "Montrose-Ghent",
                            "latitude": "41.15380000",
                            "longitude": "-81.64378000"
                        },
                        {
                            "id": 141538,
                            "name": "Moraine",
                            "latitude": "39.70617000",
                            "longitude": "-84.21938000"
                        },
                        {
                            "id": 141539,
                            "name": "Moreland Hills",
                            "latitude": "41.44783000",
                            "longitude": "-81.42762000"
                        },
                        {
                            "id": 141540,
                            "name": "Morgan County",
                            "latitude": "39.62037000",
                            "longitude": "-81.85266000"
                        },
                        {
                            "id": 141541,
                            "name": "Morgandale",
                            "latitude": "41.26561000",
                            "longitude": "-80.78286000"
                        },
                        {
                            "id": 141542,
                            "name": "Morrow",
                            "latitude": "39.35450000",
                            "longitude": "-84.12716000"
                        },
                        {
                            "id": 141543,
                            "name": "Morrow County",
                            "latitude": "40.52409000",
                            "longitude": "-82.79407000"
                        },
                        {
                            "id": 141544,
                            "name": "Mount Carmel",
                            "latitude": "39.10589000",
                            "longitude": "-84.30410000"
                        },
                        {
                            "id": 141545,
                            "name": "Mount Gilead",
                            "latitude": "40.54923000",
                            "longitude": "-82.82740000"
                        },
                        {
                            "id": 141546,
                            "name": "Mount Healthy",
                            "latitude": "39.23367000",
                            "longitude": "-84.54578000"
                        },
                        {
                            "id": 141547,
                            "name": "Mount Healthy Heights",
                            "latitude": "39.27033000",
                            "longitude": "-84.56800000"
                        },
                        {
                            "id": 141548,
                            "name": "Mount Orab",
                            "latitude": "39.02757000",
                            "longitude": "-83.91965000"
                        },
                        {
                            "id": 141549,
                            "name": "Mount Repose",
                            "latitude": "39.20062000",
                            "longitude": "-84.22438000"
                        },
                        {
                            "id": 141550,
                            "name": "Mount Sterling",
                            "latitude": "39.71951000",
                            "longitude": "-83.26519000"
                        },
                        {
                            "id": 141551,
                            "name": "Mount Vernon",
                            "latitude": "40.39340000",
                            "longitude": "-82.48572000"
                        },
                        {
                            "id": 141552,
                            "name": "Mulberry",
                            "latitude": "39.19339000",
                            "longitude": "-84.24216000"
                        },
                        {
                            "id": 141553,
                            "name": "Munroe Falls",
                            "latitude": "41.14450000",
                            "longitude": "-81.43983000"
                        },
                        {
                            "id": 141554,
                            "name": "Muskingum County",
                            "latitude": "39.96542000",
                            "longitude": "-81.94438000"
                        },
                        {
                            "id": 141555,
                            "name": "Napoleon",
                            "latitude": "41.39227000",
                            "longitude": "-84.12522000"
                        },
                        {
                            "id": 141556,
                            "name": "Navarre",
                            "latitude": "40.72450000",
                            "longitude": "-81.52207000"
                        },
                        {
                            "id": 141557,
                            "name": "Nelsonville",
                            "latitude": "39.45868000",
                            "longitude": "-82.23182000"
                        },
                        {
                            "id": 141558,
                            "name": "New Albany",
                            "latitude": "40.08117000",
                            "longitude": "-82.80879000"
                        },
                        {
                            "id": 141559,
                            "name": "New Boston",
                            "latitude": "38.75230000",
                            "longitude": "-82.93684000"
                        },
                        {
                            "id": 141560,
                            "name": "New Bremen",
                            "latitude": "40.43699000",
                            "longitude": "-84.37967000"
                        },
                        {
                            "id": 141561,
                            "name": "New Burlington",
                            "latitude": "39.25950000",
                            "longitude": "-84.55717000"
                        },
                        {
                            "id": 141562,
                            "name": "New California",
                            "latitude": "40.15617000",
                            "longitude": "-83.23658000"
                        },
                        {
                            "id": 141563,
                            "name": "New Carlisle",
                            "latitude": "39.93617000",
                            "longitude": "-84.02549000"
                        },
                        {
                            "id": 141564,
                            "name": "New Concord",
                            "latitude": "39.99368000",
                            "longitude": "-81.73402000"
                        },
                        {
                            "id": 141565,
                            "name": "New Franklin",
                            "latitude": "40.94172000",
                            "longitude": "-81.54151000"
                        },
                        {
                            "id": 141566,
                            "name": "New Lebanon",
                            "latitude": "39.74533000",
                            "longitude": "-84.38495000"
                        },
                        {
                            "id": 141567,
                            "name": "New Lexington",
                            "latitude": "39.71396000",
                            "longitude": "-82.20848000"
                        },
                        {
                            "id": 141568,
                            "name": "New London",
                            "latitude": "41.08505000",
                            "longitude": "-82.39989000"
                        },
                        {
                            "id": 141569,
                            "name": "New Matamoras",
                            "latitude": "39.52452000",
                            "longitude": "-81.06705000"
                        },
                        {
                            "id": 141570,
                            "name": "New Miami",
                            "latitude": "39.43478000",
                            "longitude": "-84.53689000"
                        },
                        {
                            "id": 141571,
                            "name": "New Middletown",
                            "latitude": "40.96117000",
                            "longitude": "-80.55757000"
                        },
                        {
                            "id": 141572,
                            "name": "New Paris",
                            "latitude": "39.85699000",
                            "longitude": "-84.79329000"
                        },
                        {
                            "id": 141573,
                            "name": "New Philadelphia",
                            "latitude": "40.48979000",
                            "longitude": "-81.44567000"
                        },
                        {
                            "id": 141574,
                            "name": "New Richmond",
                            "latitude": "38.94868000",
                            "longitude": "-84.27994000"
                        },
                        {
                            "id": 141575,
                            "name": "New Vienna",
                            "latitude": "39.32367000",
                            "longitude": "-83.69103000"
                        },
                        {
                            "id": 141576,
                            "name": "New Waterford",
                            "latitude": "40.84506000",
                            "longitude": "-80.61452000"
                        },
                        {
                            "id": 141577,
                            "name": "Newark",
                            "latitude": "40.05812000",
                            "longitude": "-82.40126000"
                        },
                        {
                            "id": 141578,
                            "name": "Newburgh Heights",
                            "latitude": "41.45005000",
                            "longitude": "-81.66346000"
                        },
                        {
                            "id": 141579,
                            "name": "Newcomerstown",
                            "latitude": "40.27229000",
                            "longitude": "-81.60595000"
                        },
                        {
                            "id": 141580,
                            "name": "Newport",
                            "latitude": "39.39091000",
                            "longitude": "-81.22678000"
                        },
                        {
                            "id": 141581,
                            "name": "Newton Falls",
                            "latitude": "41.18839000",
                            "longitude": "-80.97815000"
                        },
                        {
                            "id": 141582,
                            "name": "Newtown",
                            "latitude": "39.12450000",
                            "longitude": "-84.36161000"
                        },
                        {
                            "id": 141583,
                            "name": "Niles",
                            "latitude": "41.18284000",
                            "longitude": "-80.76536000"
                        },
                        {
                            "id": 141584,
                            "name": "Noble County",
                            "latitude": "39.76596000",
                            "longitude": "-81.45556000"
                        },
                        {
                            "id": 141585,
                            "name": "North Baltimore",
                            "latitude": "41.18283000",
                            "longitude": "-83.67827000"
                        },
                        {
                            "id": 141586,
                            "name": "North Canton",
                            "latitude": "40.87589000",
                            "longitude": "-81.40234000"
                        },
                        {
                            "id": 141587,
                            "name": "North College Hill",
                            "latitude": "39.21839000",
                            "longitude": "-84.55078000"
                        },
                        {
                            "id": 141588,
                            "name": "North Fork Village",
                            "latitude": "39.33590000",
                            "longitude": "-83.02907000"
                        },
                        {
                            "id": 141589,
                            "name": "North Kingsville",
                            "latitude": "41.90589000",
                            "longitude": "-80.69036000"
                        },
                        {
                            "id": 141590,
                            "name": "North Lewisburg",
                            "latitude": "40.22311000",
                            "longitude": "-83.55743000"
                        },
                        {
                            "id": 141591,
                            "name": "North Madison",
                            "latitude": "41.80200000",
                            "longitude": "-81.04899000"
                        },
                        {
                            "id": 141592,
                            "name": "North Olmsted",
                            "latitude": "41.41560000",
                            "longitude": "-81.92347000"
                        },
                        {
                            "id": 141593,
                            "name": "North Randall",
                            "latitude": "41.43478000",
                            "longitude": "-81.52568000"
                        },
                        {
                            "id": 141594,
                            "name": "North Ridgeville",
                            "latitude": "41.38949000",
                            "longitude": "-82.01903000"
                        },
                        {
                            "id": 141595,
                            "name": "North Royalton",
                            "latitude": "41.31366000",
                            "longitude": "-81.72457000"
                        },
                        {
                            "id": 141596,
                            "name": "North Zanesville",
                            "latitude": "39.97868000",
                            "longitude": "-82.00347000"
                        },
                        {
                            "id": 141597,
                            "name": "Northbrook",
                            "latitude": "39.24645000",
                            "longitude": "-84.58356000"
                        },
                        {
                            "id": 141598,
                            "name": "Northfield",
                            "latitude": "41.34505000",
                            "longitude": "-81.52845000"
                        },
                        {
                            "id": 141599,
                            "name": "Northgate",
                            "latitude": "39.25283000",
                            "longitude": "-84.59245000"
                        },
                        {
                            "id": 141600,
                            "name": "Northridge",
                            "latitude": "39.80756000",
                            "longitude": "-84.19689000"
                        },
                        {
                            "id": 141601,
                            "name": "Northwood",
                            "latitude": "40.47283000",
                            "longitude": "-83.73243000"
                        },
                        {
                            "id": 141602,
                            "name": "Norton",
                            "latitude": "41.02922000",
                            "longitude": "-81.63818000"
                        },
                        {
                            "id": 141603,
                            "name": "Norwalk",
                            "latitude": "41.24255000",
                            "longitude": "-82.61573000"
                        },
                        {
                            "id": 141604,
                            "name": "Norwood",
                            "latitude": "39.15561000",
                            "longitude": "-84.45966000"
                        },
                        {
                            "id": 141605,
                            "name": "Oak Harbor",
                            "latitude": "41.50672000",
                            "longitude": "-83.14659000"
                        },
                        {
                            "id": 141606,
                            "name": "Oak Hill",
                            "latitude": "38.89396000",
                            "longitude": "-82.57349000"
                        },
                        {
                            "id": 141607,
                            "name": "Oakwood",
                            "latitude": "39.72534000",
                            "longitude": "-84.17411000"
                        },
                        {
                            "id": 141608,
                            "name": "Oberlin",
                            "latitude": "41.29394000",
                            "longitude": "-82.21738000"
                        },
                        {
                            "id": 141609,
                            "name": "Obetz",
                            "latitude": "39.87895000",
                            "longitude": "-82.95074000"
                        },
                        {
                            "id": 141610,
                            "name": "Olmsted Falls",
                            "latitude": "41.37505000",
                            "longitude": "-81.90819000"
                        },
                        {
                            "id": 141611,
                            "name": "Ontario",
                            "latitude": "40.75950000",
                            "longitude": "-82.59017000"
                        },
                        {
                            "id": 141612,
                            "name": "Orange",
                            "latitude": "41.44978000",
                            "longitude": "-81.48067000"
                        },
                        {
                            "id": 141613,
                            "name": "Oregon",
                            "latitude": "41.64366000",
                            "longitude": "-83.48688000"
                        },
                        {
                            "id": 141614,
                            "name": "Orrville",
                            "latitude": "40.84367000",
                            "longitude": "-81.76402000"
                        },
                        {
                            "id": 141615,
                            "name": "Orwell",
                            "latitude": "41.53506000",
                            "longitude": "-80.86814000"
                        },
                        {
                            "id": 141616,
                            "name": "Ottawa",
                            "latitude": "41.01922000",
                            "longitude": "-84.04717000"
                        },
                        {
                            "id": 141617,
                            "name": "Ottawa County",
                            "latitude": "41.49675000",
                            "longitude": "-82.94128000"
                        },
                        {
                            "id": 141618,
                            "name": "Ottawa Hills",
                            "latitude": "41.66422000",
                            "longitude": "-83.64327000"
                        },
                        {
                            "id": 141619,
                            "name": "Oxford",
                            "latitude": "39.50700000",
                            "longitude": "-84.74523000"
                        },
                        {
                            "id": 141620,
                            "name": "Painesville",
                            "latitude": "41.72449000",
                            "longitude": "-81.24566000"
                        },
                        {
                            "id": 141621,
                            "name": "Pandora",
                            "latitude": "40.94811000",
                            "longitude": "-83.96105000"
                        },
                        {
                            "id": 141622,
                            "name": "Park Layne",
                            "latitude": "39.88645000",
                            "longitude": "-84.03966000"
                        },
                        {
                            "id": 141623,
                            "name": "Parma",
                            "latitude": "41.40477000",
                            "longitude": "-81.72291000"
                        },
                        {
                            "id": 141624,
                            "name": "Parma Heights",
                            "latitude": "41.39005000",
                            "longitude": "-81.75958000"
                        },
                        {
                            "id": 141625,
                            "name": "Pataskala",
                            "latitude": "39.99562000",
                            "longitude": "-82.67433000"
                        },
                        {
                            "id": 141626,
                            "name": "Paulding",
                            "latitude": "41.13811000",
                            "longitude": "-84.58051000"
                        },
                        {
                            "id": 141627,
                            "name": "Paulding County",
                            "latitude": "41.11662000",
                            "longitude": "-84.58020000"
                        },
                        {
                            "id": 141628,
                            "name": "Payne",
                            "latitude": "41.07755000",
                            "longitude": "-84.72718000"
                        },
                        {
                            "id": 141629,
                            "name": "Peebles",
                            "latitude": "38.94896000",
                            "longitude": "-83.40575000"
                        },
                        {
                            "id": 141630,
                            "name": "Pemberville",
                            "latitude": "41.41089000",
                            "longitude": "-83.46104000"
                        },
                        {
                            "id": 141631,
                            "name": "Pepper Pike",
                            "latitude": "41.47839000",
                            "longitude": "-81.46373000"
                        },
                        {
                            "id": 141632,
                            "name": "Perry",
                            "latitude": "41.76033000",
                            "longitude": "-81.14093000"
                        },
                        {
                            "id": 141633,
                            "name": "Perry County",
                            "latitude": "39.73715000",
                            "longitude": "-82.23614000"
                        },
                        {
                            "id": 141634,
                            "name": "Perry Heights",
                            "latitude": "40.79534000",
                            "longitude": "-81.47345000"
                        },
                        {
                            "id": 141635,
                            "name": "Perrysburg",
                            "latitude": "41.55700000",
                            "longitude": "-83.62716000"
                        },
                        {
                            "id": 141636,
                            "name": "Pickaway County",
                            "latitude": "39.64194000",
                            "longitude": "-83.02439000"
                        },
                        {
                            "id": 141637,
                            "name": "Pickerington",
                            "latitude": "39.88423000",
                            "longitude": "-82.75350000"
                        },
                        {
                            "id": 141638,
                            "name": "Pike County",
                            "latitude": "39.07737000",
                            "longitude": "-83.06685000"
                        },
                        {
                            "id": 141639,
                            "name": "Piketon",
                            "latitude": "39.06813000",
                            "longitude": "-83.01434000"
                        },
                        {
                            "id": 141640,
                            "name": "Pioneer",
                            "latitude": "41.68005000",
                            "longitude": "-84.55301000"
                        },
                        {
                            "id": 141641,
                            "name": "Piqua",
                            "latitude": "40.14477000",
                            "longitude": "-84.24244000"
                        },
                        {
                            "id": 141642,
                            "name": "Plain City",
                            "latitude": "40.10756000",
                            "longitude": "-83.26742000"
                        },
                        {
                            "id": 141643,
                            "name": "Pleasant Grove",
                            "latitude": "39.95201000",
                            "longitude": "-81.95902000"
                        },
                        {
                            "id": 141644,
                            "name": "Pleasant Hill",
                            "latitude": "40.05172000",
                            "longitude": "-84.34439000"
                        },
                        {
                            "id": 141645,
                            "name": "Pleasant Run",
                            "latitude": "39.29978000",
                            "longitude": "-84.56356000"
                        },
                        {
                            "id": 141646,
                            "name": "Pleasant Run Farm",
                            "latitude": "39.30311000",
                            "longitude": "-84.54800000"
                        },
                        {
                            "id": 141647,
                            "name": "Plymouth",
                            "latitude": "40.99561000",
                            "longitude": "-82.66712000"
                        },
                        {
                            "id": 141648,
                            "name": "Poland",
                            "latitude": "41.02423000",
                            "longitude": "-80.61480000"
                        },
                        {
                            "id": 141649,
                            "name": "Pomeroy",
                            "latitude": "39.02758000",
                            "longitude": "-82.03375000"
                        },
                        {
                            "id": 141650,
                            "name": "Port Clinton",
                            "latitude": "41.51200000",
                            "longitude": "-82.93769000"
                        },
                        {
                            "id": 141651,
                            "name": "Portage County",
                            "latitude": "41.16768000",
                            "longitude": "-81.19740000"
                        },
                        {
                            "id": 141652,
                            "name": "Portage Lakes",
                            "latitude": "41.00728000",
                            "longitude": "-81.52706000"
                        },
                        {
                            "id": 141653,
                            "name": "Portsmouth",
                            "latitude": "38.73174000",
                            "longitude": "-82.99767000"
                        },
                        {
                            "id": 141654,
                            "name": "Powell",
                            "latitude": "40.15784000",
                            "longitude": "-83.07519000"
                        },
                        {
                            "id": 141655,
                            "name": "Powhatan Point",
                            "latitude": "39.86008000",
                            "longitude": "-80.81537000"
                        },
                        {
                            "id": 141656,
                            "name": "Preble County",
                            "latitude": "39.74157000",
                            "longitude": "-84.64802000"
                        },
                        {
                            "id": 141657,
                            "name": "Prospect",
                            "latitude": "40.45034000",
                            "longitude": "-83.18853000"
                        },
                        {
                            "id": 141658,
                            "name": "Putnam County",
                            "latitude": "41.02208000",
                            "longitude": "-84.13173000"
                        },
                        {
                            "id": 141659,
                            "name": "Ravenna",
                            "latitude": "41.15756000",
                            "longitude": "-81.24205000"
                        },
                        {
                            "id": 141660,
                            "name": "Reading",
                            "latitude": "39.22367000",
                            "longitude": "-84.44216000"
                        },
                        {
                            "id": 141661,
                            "name": "Reminderville",
                            "latitude": "41.34589000",
                            "longitude": "-81.39511000"
                        },
                        {
                            "id": 141662,
                            "name": "Reno",
                            "latitude": "39.37285000",
                            "longitude": "-81.39567000"
                        },
                        {
                            "id": 141663,
                            "name": "Reynoldsburg",
                            "latitude": "39.95479000",
                            "longitude": "-82.81212000"
                        },
                        {
                            "id": 141664,
                            "name": "Richfield",
                            "latitude": "41.23978000",
                            "longitude": "-81.63818000"
                        },
                        {
                            "id": 141665,
                            "name": "Richland County",
                            "latitude": "40.77468000",
                            "longitude": "-82.53648000"
                        },
                        {
                            "id": 141666,
                            "name": "Richmond Heights",
                            "latitude": "41.55283000",
                            "longitude": "-81.51012000"
                        },
                        {
                            "id": 141667,
                            "name": "Richville",
                            "latitude": "40.75117000",
                            "longitude": "-81.47790000"
                        },
                        {
                            "id": 141668,
                            "name": "Richwood",
                            "latitude": "40.42645000",
                            "longitude": "-83.29686000"
                        },
                        {
                            "id": 141669,
                            "name": "Ripley",
                            "latitude": "38.74563000",
                            "longitude": "-83.84492000"
                        },
                        {
                            "id": 141670,
                            "name": "Rittman",
                            "latitude": "40.97811000",
                            "longitude": "-81.78208000"
                        },
                        {
                            "id": 141671,
                            "name": "Riverside",
                            "latitude": "39.77978000",
                            "longitude": "-84.12410000"
                        },
                        {
                            "id": 141672,
                            "name": "Roaming Shores",
                            "latitude": "41.64311000",
                            "longitude": "-80.82342000"
                        },
                        {
                            "id": 141673,
                            "name": "Rockford",
                            "latitude": "40.68783000",
                            "longitude": "-84.64663000"
                        },
                        {
                            "id": 141674,
                            "name": "Rocky River",
                            "latitude": "41.47560000",
                            "longitude": "-81.83930000"
                        },
                        {
                            "id": 141675,
                            "name": "Rosemount",
                            "latitude": "38.78619000",
                            "longitude": "-82.97906000"
                        },
                        {
                            "id": 141676,
                            "name": "Roseville",
                            "latitude": "39.80729000",
                            "longitude": "-82.07125000"
                        },
                        {
                            "id": 141677,
                            "name": "Ross",
                            "latitude": "39.31228000",
                            "longitude": "-84.65050000"
                        },
                        {
                            "id": 141678,
                            "name": "Ross County",
                            "latitude": "39.33763000",
                            "longitude": "-83.05703000"
                        },
                        {
                            "id": 141679,
                            "name": "Rossford",
                            "latitude": "41.60977000",
                            "longitude": "-83.56438000"
                        },
                        {
                            "id": 141680,
                            "name": "Rossmoyne",
                            "latitude": "39.21367000",
                            "longitude": "-84.38688000"
                        },
                        {
                            "id": 141681,
                            "name": "Russells Point",
                            "latitude": "40.47116000",
                            "longitude": "-83.89272000"
                        },
                        {
                            "id": 141682,
                            "name": "Sabina",
                            "latitude": "39.48867000",
                            "longitude": "-83.63687000"
                        },
                        {
                            "id": 141683,
                            "name": "Saint Bernard",
                            "latitude": "39.16700000",
                            "longitude": "-84.49855000"
                        },
                        {
                            "id": 141684,
                            "name": "Saint Clairsville",
                            "latitude": "40.08063000",
                            "longitude": "-80.90009000"
                        },
                        {
                            "id": 141685,
                            "name": "Saint Henry",
                            "latitude": "40.41755000",
                            "longitude": "-84.63968000"
                        },
                        {
                            "id": 141686,
                            "name": "Saint Marys",
                            "latitude": "40.54227000",
                            "longitude": "-84.38940000"
                        },
                        {
                            "id": 141687,
                            "name": "Saint Paris",
                            "latitude": "40.12839000",
                            "longitude": "-83.95966000"
                        },
                        {
                            "id": 141688,
                            "name": "Salem",
                            "latitude": "40.90089000",
                            "longitude": "-80.85675000"
                        },
                        {
                            "id": 141689,
                            "name": "Salem Heights",
                            "latitude": "39.07173000",
                            "longitude": "-84.37827000"
                        },
                        {
                            "id": 141690,
                            "name": "Salineville",
                            "latitude": "40.62256000",
                            "longitude": "-80.83786000"
                        },
                        {
                            "id": 141691,
                            "name": "Sandusky",
                            "latitude": "41.44894000",
                            "longitude": "-82.70796000"
                        },
                        {
                            "id": 141692,
                            "name": "Sandusky County",
                            "latitude": "41.35742000",
                            "longitude": "-83.14391000"
                        },
                        {
                            "id": 141693,
                            "name": "Sawyerwood",
                            "latitude": "41.03783000",
                            "longitude": "-81.44095000"
                        },
                        {
                            "id": 141694,
                            "name": "Scioto County",
                            "latitude": "38.80396000",
                            "longitude": "-82.99283000"
                        },
                        {
                            "id": 141695,
                            "name": "Sciotodale",
                            "latitude": "38.75480000",
                            "longitude": "-82.86878000"
                        },
                        {
                            "id": 141696,
                            "name": "Sebring",
                            "latitude": "40.92284000",
                            "longitude": "-81.01898000"
                        },
                        {
                            "id": 141697,
                            "name": "Seneca County",
                            "latitude": "41.12388000",
                            "longitude": "-83.12771000"
                        },
                        {
                            "id": 141698,
                            "name": "Seven Hills",
                            "latitude": "41.39533000",
                            "longitude": "-81.67624000"
                        },
                        {
                            "id": 141699,
                            "name": "Seville",
                            "latitude": "41.01006000",
                            "longitude": "-81.86236000"
                        },
                        {
                            "id": 141700,
                            "name": "Shadyside",
                            "latitude": "39.97091000",
                            "longitude": "-80.75064000"
                        },
                        {
                            "id": 141701,
                            "name": "Shaker Heights",
                            "latitude": "41.47394000",
                            "longitude": "-81.53707000"
                        },
                        {
                            "id": 141702,
                            "name": "Sharonville",
                            "latitude": "39.26811000",
                            "longitude": "-84.41327000"
                        },
                        {
                            "id": 141703,
                            "name": "Shawnee Hills",
                            "latitude": "39.65284000",
                            "longitude": "-83.78687000"
                        },
                        {
                            "id": 141704,
                            "name": "Sheffield",
                            "latitude": "41.42115000",
                            "longitude": "-82.09626000"
                        },
                        {
                            "id": 141705,
                            "name": "Sheffield Lake",
                            "latitude": "41.48754000",
                            "longitude": "-82.10154000"
                        },
                        {
                            "id": 141706,
                            "name": "Shelby",
                            "latitude": "40.88145000",
                            "longitude": "-82.66184000"
                        },
                        {
                            "id": 141707,
                            "name": "Shelby County",
                            "latitude": "40.33153000",
                            "longitude": "-84.20473000"
                        },
                        {
                            "id": 141708,
                            "name": "Sherwood",
                            "latitude": "39.08478000",
                            "longitude": "-84.36077000"
                        },
                        {
                            "id": 141709,
                            "name": "Shiloh",
                            "latitude": "39.81867000",
                            "longitude": "-84.22855000"
                        },
                        {
                            "id": 141710,
                            "name": "Shreve",
                            "latitude": "40.68145000",
                            "longitude": "-82.02181000"
                        },
                        {
                            "id": 141711,
                            "name": "Sidney",
                            "latitude": "40.28422000",
                            "longitude": "-84.15550000"
                        },
                        {
                            "id": 141712,
                            "name": "Silver Lake",
                            "latitude": "41.15895000",
                            "longitude": "-81.45428000"
                        },
                        {
                            "id": 141713,
                            "name": "Silverton",
                            "latitude": "39.19284000",
                            "longitude": "-84.40050000"
                        },
                        {
                            "id": 141714,
                            "name": "Sixteen Mile Stand",
                            "latitude": "39.27284000",
                            "longitude": "-84.32744000"
                        },
                        {
                            "id": 141715,
                            "name": "Skyline Acres",
                            "latitude": "39.22867000",
                            "longitude": "-84.56689000"
                        },
                        {
                            "id": 141716,
                            "name": "Smithville",
                            "latitude": "40.86228000",
                            "longitude": "-81.86180000"
                        },
                        {
                            "id": 141717,
                            "name": "Solon",
                            "latitude": "41.38978000",
                            "longitude": "-81.44123000"
                        },
                        {
                            "id": 141718,
                            "name": "Somerset",
                            "latitude": "39.80701000",
                            "longitude": "-82.29709000"
                        },
                        {
                            "id": 141719,
                            "name": "South Amherst",
                            "latitude": "41.35588000",
                            "longitude": "-82.25377000"
                        },
                        {
                            "id": 141720,
                            "name": "South Bloomfield",
                            "latitude": "39.71840000",
                            "longitude": "-82.98685000"
                        },
                        {
                            "id": 141721,
                            "name": "South Canal",
                            "latitude": "41.17728000",
                            "longitude": "-80.98676000"
                        },
                        {
                            "id": 141722,
                            "name": "South Charleston",
                            "latitude": "39.82534000",
                            "longitude": "-83.63437000"
                        },
                        {
                            "id": 141723,
                            "name": "South Euclid",
                            "latitude": "41.52311000",
                            "longitude": "-81.51846000"
                        },
                        {
                            "id": 141724,
                            "name": "South Lebanon",
                            "latitude": "39.37089000",
                            "longitude": "-84.21327000"
                        },
                        {
                            "id": 141725,
                            "name": "South Point",
                            "latitude": "38.41786000",
                            "longitude": "-82.58627000"
                        },
                        {
                            "id": 141726,
                            "name": "South Russell",
                            "latitude": "41.43144000",
                            "longitude": "-81.36539000"
                        },
                        {
                            "id": 141727,
                            "name": "South Zanesville",
                            "latitude": "39.89923000",
                            "longitude": "-82.00625000"
                        },
                        {
                            "id": 141728,
                            "name": "Spencerville",
                            "latitude": "40.70894000",
                            "longitude": "-84.35356000"
                        },
                        {
                            "id": 141729,
                            "name": "Springboro",
                            "latitude": "39.55228000",
                            "longitude": "-84.23327000"
                        },
                        {
                            "id": 141730,
                            "name": "Springdale",
                            "latitude": "39.28700000",
                            "longitude": "-84.48522000"
                        },
                        {
                            "id": 141731,
                            "name": "Springfield",
                            "latitude": "39.92423000",
                            "longitude": "-83.80882000"
                        },
                        {
                            "id": 141732,
                            "name": "Stark County",
                            "latitude": "40.81389000",
                            "longitude": "-81.36564000"
                        },
                        {
                            "id": 141733,
                            "name": "Steubenville",
                            "latitude": "40.36979000",
                            "longitude": "-80.63396000"
                        },
                        {
                            "id": 141734,
                            "name": "Stony Prairie",
                            "latitude": "41.35144000",
                            "longitude": "-83.15520000"
                        },
                        {
                            "id": 141735,
                            "name": "Stow",
                            "latitude": "41.15950000",
                            "longitude": "-81.44039000"
                        },
                        {
                            "id": 141736,
                            "name": "Strasburg",
                            "latitude": "40.59478000",
                            "longitude": "-81.52679000"
                        },
                        {
                            "id": 141737,
                            "name": "Streetsboro",
                            "latitude": "41.23922000",
                            "longitude": "-81.34594000"
                        },
                        {
                            "id": 141738,
                            "name": "Strongsville",
                            "latitude": "41.31450000",
                            "longitude": "-81.83569000"
                        },
                        {
                            "id": 141739,
                            "name": "Struthers",
                            "latitude": "41.05256000",
                            "longitude": "-80.60785000"
                        },
                        {
                            "id": 141740,
                            "name": "Stryker",
                            "latitude": "41.50366000",
                            "longitude": "-84.41412000"
                        },
                        {
                            "id": 141741,
                            "name": "Sugarcreek",
                            "latitude": "40.50312000",
                            "longitude": "-81.64096000"
                        },
                        {
                            "id": 141742,
                            "name": "Sugarcreek Police Dept",
                            "latitude": "40.50253000",
                            "longitude": "-81.64176000"
                        },
                        {
                            "id": 141743,
                            "name": "Summerside",
                            "latitude": "39.10478000",
                            "longitude": "-84.28827000"
                        },
                        {
                            "id": 141744,
                            "name": "Summit County",
                            "latitude": "41.12598000",
                            "longitude": "-81.53217000"
                        },
                        {
                            "id": 141745,
                            "name": "Sunbury",
                            "latitude": "40.24256000",
                            "longitude": "-82.85907000"
                        },
                        {
                            "id": 141746,
                            "name": "Swanton",
                            "latitude": "41.58866000",
                            "longitude": "-83.89105000"
                        },
                        {
                            "id": 141747,
                            "name": "Sylvania",
                            "latitude": "41.71894000",
                            "longitude": "-83.71299000"
                        },
                        {
                            "id": 141748,
                            "name": "Tallmadge",
                            "latitude": "41.10145000",
                            "longitude": "-81.44178000"
                        },
                        {
                            "id": 141749,
                            "name": "Terrace Park",
                            "latitude": "39.15923000",
                            "longitude": "-84.30716000"
                        },
                        {
                            "id": 141750,
                            "name": "The Plains",
                            "latitude": "39.36896000",
                            "longitude": "-82.13237000"
                        },
                        {
                            "id": 141751,
                            "name": "The Village of Indian Hill",
                            "latitude": "39.17949000",
                            "longitude": "-84.33517000"
                        },
                        {
                            "id": 141752,
                            "name": "Thornport",
                            "latitude": "39.91312000",
                            "longitude": "-82.41099000"
                        },
                        {
                            "id": 141753,
                            "name": "Tiffin",
                            "latitude": "41.11450000",
                            "longitude": "-83.17797000"
                        },
                        {
                            "id": 141754,
                            "name": "Tiltonsville",
                            "latitude": "40.16674000",
                            "longitude": "-80.69980000"
                        },
                        {
                            "id": 141755,
                            "name": "Tipp City",
                            "latitude": "39.95839000",
                            "longitude": "-84.17216000"
                        },
                        {
                            "id": 141756,
                            "name": "Toledo",
                            "latitude": "41.66394000",
                            "longitude": "-83.55521000"
                        },
                        {
                            "id": 141757,
                            "name": "Toronto",
                            "latitude": "40.46423000",
                            "longitude": "-80.60091000"
                        },
                        {
                            "id": 141758,
                            "name": "Trenton",
                            "latitude": "39.48089000",
                            "longitude": "-84.45772000"
                        },
                        {
                            "id": 141759,
                            "name": "Trotwood",
                            "latitude": "39.79728000",
                            "longitude": "-84.31133000"
                        },
                        {
                            "id": 141760,
                            "name": "Troy",
                            "latitude": "40.03950000",
                            "longitude": "-84.20328000"
                        },
                        {
                            "id": 141761,
                            "name": "Trumbull County",
                            "latitude": "41.31717000",
                            "longitude": "-80.76116000"
                        },
                        {
                            "id": 141762,
                            "name": "Turpin Hills",
                            "latitude": "39.11006000",
                            "longitude": "-84.37994000"
                        },
                        {
                            "id": 141763,
                            "name": "Tuscarawas",
                            "latitude": "40.39479000",
                            "longitude": "-81.40706000"
                        },
                        {
                            "id": 141764,
                            "name": "Tuscarawas County",
                            "latitude": "40.44096000",
                            "longitude": "-81.47377000"
                        },
                        {
                            "id": 141765,
                            "name": "Twinsburg",
                            "latitude": "41.31256000",
                            "longitude": "-81.44011000"
                        },
                        {
                            "id": 141766,
                            "name": "Uhrichsville",
                            "latitude": "40.39312000",
                            "longitude": "-81.34650000"
                        },
                        {
                            "id": 141767,
                            "name": "Union",
                            "latitude": "39.89783000",
                            "longitude": "-84.30633000"
                        },
                        {
                            "id": 141768,
                            "name": "Union City",
                            "latitude": "40.19938000",
                            "longitude": "-84.80353000"
                        },
                        {
                            "id": 141769,
                            "name": "Union County",
                            "latitude": "40.29940000",
                            "longitude": "-83.37158000"
                        },
                        {
                            "id": 141770,
                            "name": "Uniontown",
                            "latitude": "40.97506000",
                            "longitude": "-81.40817000"
                        },
                        {
                            "id": 141771,
                            "name": "University Heights",
                            "latitude": "41.49783000",
                            "longitude": "-81.53735000"
                        },
                        {
                            "id": 141772,
                            "name": "Upper Arlington",
                            "latitude": "39.99451000",
                            "longitude": "-83.06241000"
                        },
                        {
                            "id": 141773,
                            "name": "Upper Sandusky",
                            "latitude": "40.82728000",
                            "longitude": "-83.28131000"
                        },
                        {
                            "id": 141774,
                            "name": "Urbana",
                            "latitude": "40.10839000",
                            "longitude": "-83.75243000"
                        },
                        {
                            "id": 141775,
                            "name": "Urbancrest",
                            "latitude": "39.89756000",
                            "longitude": "-83.08685000"
                        },
                        {
                            "id": 141776,
                            "name": "Utica",
                            "latitude": "40.23423000",
                            "longitude": "-82.45127000"
                        },
                        {
                            "id": 141777,
                            "name": "Valley View",
                            "latitude": "41.38783000",
                            "longitude": "-81.60457000"
                        },
                        {
                            "id": 141778,
                            "name": "Van Wert",
                            "latitude": "40.86949000",
                            "longitude": "-84.58412000"
                        },
                        {
                            "id": 141779,
                            "name": "Van Wert County",
                            "latitude": "40.85540000",
                            "longitude": "-84.58610000"
                        },
                        {
                            "id": 141780,
                            "name": "Vandalia",
                            "latitude": "39.89061000",
                            "longitude": "-84.19883000"
                        },
                        {
                            "id": 141781,
                            "name": "Vermilion",
                            "latitude": "41.42199000",
                            "longitude": "-82.36461000"
                        },
                        {
                            "id": 141782,
                            "name": "Vermilion-on-the-Lake",
                            "latitude": "41.42838000",
                            "longitude": "-82.32377000"
                        },
                        {
                            "id": 141783,
                            "name": "Versailles",
                            "latitude": "40.22255000",
                            "longitude": "-84.48440000"
                        },
                        {
                            "id": 141784,
                            "name": "Vinton County",
                            "latitude": "39.25099000",
                            "longitude": "-82.48535000"
                        },
                        {
                            "id": 141785,
                            "name": "Wadsworth",
                            "latitude": "41.02561000",
                            "longitude": "-81.72985000"
                        },
                        {
                            "id": 141786,
                            "name": "Wakeman",
                            "latitude": "41.25450000",
                            "longitude": "-82.39961000"
                        },
                        {
                            "id": 141787,
                            "name": "Walbridge",
                            "latitude": "41.58783000",
                            "longitude": "-83.49327000"
                        },
                        {
                            "id": 141788,
                            "name": "Walton Hills",
                            "latitude": "41.36561000",
                            "longitude": "-81.56123000"
                        },
                        {
                            "id": 141789,
                            "name": "Wapakoneta",
                            "latitude": "40.56783000",
                            "longitude": "-84.19356000"
                        },
                        {
                            "id": 141790,
                            "name": "Warren",
                            "latitude": "41.23756000",
                            "longitude": "-80.81842000"
                        },
                        {
                            "id": 141791,
                            "name": "Warren County",
                            "latitude": "39.42758000",
                            "longitude": "-84.16676000"
                        },
                        {
                            "id": 141792,
                            "name": "Warrensville Heights",
                            "latitude": "41.43505000",
                            "longitude": "-81.53623000"
                        },
                        {
                            "id": 141793,
                            "name": "Washington County",
                            "latitude": "39.45532000",
                            "longitude": "-81.49525000"
                        },
                        {
                            "id": 141794,
                            "name": "Washington Court House",
                            "latitude": "39.53645000",
                            "longitude": "-83.43908000"
                        },
                        {
                            "id": 141795,
                            "name": "Waterville",
                            "latitude": "41.50089000",
                            "longitude": "-83.71827000"
                        },
                        {
                            "id": 141796,
                            "name": "Wauseon",
                            "latitude": "41.54922000",
                            "longitude": "-84.14161000"
                        },
                        {
                            "id": 141797,
                            "name": "Waverly",
                            "latitude": "39.12673000",
                            "longitude": "-82.98546000"
                        },
                        {
                            "id": 141798,
                            "name": "Wayne County",
                            "latitude": "40.82887000",
                            "longitude": "-81.88803000"
                        },
                        {
                            "id": 141799,
                            "name": "Waynesville",
                            "latitude": "39.52978000",
                            "longitude": "-84.08660000"
                        },
                        {
                            "id": 141800,
                            "name": "Wellington",
                            "latitude": "41.16894000",
                            "longitude": "-82.21794000"
                        },
                        {
                            "id": 141801,
                            "name": "Wellston",
                            "latitude": "39.12341000",
                            "longitude": "-82.53294000"
                        },
                        {
                            "id": 141802,
                            "name": "Wellsville",
                            "latitude": "40.60284000",
                            "longitude": "-80.64896000"
                        },
                        {
                            "id": 141803,
                            "name": "West Alexandria",
                            "latitude": "39.74450000",
                            "longitude": "-84.53217000"
                        },
                        {
                            "id": 141804,
                            "name": "West Carrollton City",
                            "latitude": "39.67228000",
                            "longitude": "-84.25216000"
                        },
                        {
                            "id": 141805,
                            "name": "West Hill",
                            "latitude": "41.23283000",
                            "longitude": "-80.51924000"
                        },
                        {
                            "id": 141806,
                            "name": "West Jefferson",
                            "latitude": "39.94478000",
                            "longitude": "-83.26880000"
                        },
                        {
                            "id": 141807,
                            "name": "West Lafayette",
                            "latitude": "40.27535000",
                            "longitude": "-81.75096000"
                        },
                        {
                            "id": 141808,
                            "name": "West Liberty",
                            "latitude": "40.25228000",
                            "longitude": "-83.75577000"
                        },
                        {
                            "id": 141809,
                            "name": "West Milton",
                            "latitude": "39.96255000",
                            "longitude": "-84.32800000"
                        },
                        {
                            "id": 141810,
                            "name": "West Portsmouth",
                            "latitude": "38.75841000",
                            "longitude": "-83.02906000"
                        },
                        {
                            "id": 141811,
                            "name": "West Salem",
                            "latitude": "40.97144000",
                            "longitude": "-82.10987000"
                        },
                        {
                            "id": 141812,
                            "name": "West Union",
                            "latitude": "38.79452000",
                            "longitude": "-83.54519000"
                        },
                        {
                            "id": 141813,
                            "name": "West Unity",
                            "latitude": "41.58616000",
                            "longitude": "-84.43495000"
                        },
                        {
                            "id": 141814,
                            "name": "Westerville",
                            "latitude": "40.12617000",
                            "longitude": "-82.92907000"
                        },
                        {
                            "id": 141815,
                            "name": "Westfield Center",
                            "latitude": "41.02644000",
                            "longitude": "-81.93320000"
                        },
                        {
                            "id": 141816,
                            "name": "Westlake",
                            "latitude": "41.45532000",
                            "longitude": "-81.91792000"
                        },
                        {
                            "id": 141817,
                            "name": "Weston",
                            "latitude": "41.34477000",
                            "longitude": "-83.79716000"
                        },
                        {
                            "id": 141818,
                            "name": "Wetherington",
                            "latitude": "39.36367000",
                            "longitude": "-84.37744000"
                        },
                        {
                            "id": 141819,
                            "name": "Wheelersburg",
                            "latitude": "38.73035000",
                            "longitude": "-82.85545000"
                        },
                        {
                            "id": 141820,
                            "name": "White Oak",
                            "latitude": "39.21311000",
                            "longitude": "-84.59939000"
                        },
                        {
                            "id": 141821,
                            "name": "Whitehall",
                            "latitude": "39.96673000",
                            "longitude": "-82.88546000"
                        },
                        {
                            "id": 141822,
                            "name": "Whitehouse",
                            "latitude": "41.51894000",
                            "longitude": "-83.80383000"
                        },
                        {
                            "id": 141823,
                            "name": "Wickliffe",
                            "latitude": "41.60533000",
                            "longitude": "-81.45345000"
                        },
                        {
                            "id": 141824,
                            "name": "Wilberforce",
                            "latitude": "39.71617000",
                            "longitude": "-83.87771000"
                        },
                        {
                            "id": 141825,
                            "name": "Willard",
                            "latitude": "41.05311000",
                            "longitude": "-82.72629000"
                        },
                        {
                            "id": 141826,
                            "name": "Williams County",
                            "latitude": "41.56029000",
                            "longitude": "-84.58814000"
                        },
                        {
                            "id": 141827,
                            "name": "Williamsburg",
                            "latitude": "39.05423000",
                            "longitude": "-84.05299000"
                        },
                        {
                            "id": 141828,
                            "name": "Williamsport",
                            "latitude": "39.58590000",
                            "longitude": "-83.12046000"
                        },
                        {
                            "id": 141829,
                            "name": "Willoughby",
                            "latitude": "41.63977000",
                            "longitude": "-81.40650000"
                        },
                        {
                            "id": 141830,
                            "name": "Willoughby Hills",
                            "latitude": "41.59838000",
                            "longitude": "-81.41845000"
                        },
                        {
                            "id": 141831,
                            "name": "Willowick",
                            "latitude": "41.63310000",
                            "longitude": "-81.46873000"
                        },
                        {
                            "id": 141832,
                            "name": "Wilmington",
                            "latitude": "39.44534000",
                            "longitude": "-83.82854000"
                        },
                        {
                            "id": 141833,
                            "name": "Winchester",
                            "latitude": "38.94174000",
                            "longitude": "-83.65075000"
                        },
                        {
                            "id": 141834,
                            "name": "Windham",
                            "latitude": "41.23506000",
                            "longitude": "-81.04926000"
                        },
                        {
                            "id": 141835,
                            "name": "Wintersville",
                            "latitude": "40.37535000",
                            "longitude": "-80.70369000"
                        },
                        {
                            "id": 141836,
                            "name": "Withamsville",
                            "latitude": "39.06228000",
                            "longitude": "-84.28827000"
                        },
                        {
                            "id": 141837,
                            "name": "Wolfhurst",
                            "latitude": "40.06924000",
                            "longitude": "-80.78370000"
                        },
                        {
                            "id": 141838,
                            "name": "Wood County",
                            "latitude": "41.36169000",
                            "longitude": "-83.62299000"
                        },
                        {
                            "id": 141839,
                            "name": "Woodlawn",
                            "latitude": "39.25200000",
                            "longitude": "-84.47022000"
                        },
                        {
                            "id": 141840,
                            "name": "Woodsfield",
                            "latitude": "39.76257000",
                            "longitude": "-81.11538000"
                        },
                        {
                            "id": 141841,
                            "name": "Woodville",
                            "latitude": "41.45144000",
                            "longitude": "-83.36576000"
                        },
                        {
                            "id": 141842,
                            "name": "Wooster",
                            "latitude": "40.80517000",
                            "longitude": "-81.93646000"
                        },
                        {
                            "id": 141843,
                            "name": "Worthington",
                            "latitude": "40.09312000",
                            "longitude": "-83.01796000"
                        },
                        {
                            "id": 141844,
                            "name": "Wright-Patterson AFB",
                            "latitude": "39.81113000",
                            "longitude": "-84.05731000"
                        },
                        {
                            "id": 141845,
                            "name": "Wyandot County",
                            "latitude": "40.84237000",
                            "longitude": "-83.30437000"
                        },
                        {
                            "id": 141846,
                            "name": "Wyoming",
                            "latitude": "39.23117000",
                            "longitude": "-84.46578000"
                        },
                        {
                            "id": 141847,
                            "name": "Xenia",
                            "latitude": "39.68478000",
                            "longitude": "-83.92965000"
                        },
                        {
                            "id": 141848,
                            "name": "Yellow Springs",
                            "latitude": "39.80645000",
                            "longitude": "-83.88687000"
                        },
                        {
                            "id": 141849,
                            "name": "Yorkville",
                            "latitude": "40.15452000",
                            "longitude": "-80.71036000"
                        },
                        {
                            "id": 141850,
                            "name": "Youngstown",
                            "latitude": "41.09978000",
                            "longitude": "-80.64952000"
                        },
                        {
                            "id": 141851,
                            "name": "Zanesville",
                            "latitude": "39.94035000",
                            "longitude": "-82.01319000"
                        }
                    ]
                },
                {
                    "id": 1421,
                    "name": "Oklahoma",
                    "state_code": "OK",
                    "cities": [
                        {
                            "id": 111005,
                            "name": "Ada",
                            "latitude": "34.77453000",
                            "longitude": "-96.67834000"
                        },
                        {
                            "id": 111009,
                            "name": "Adair County",
                            "latitude": "35.88393000",
                            "longitude": "-94.65868000"
                        },
                        {
                            "id": 111052,
                            "name": "Afton",
                            "latitude": "36.69369000",
                            "longitude": "-94.96302000"
                        },
                        {
                            "id": 111158,
                            "name": "Alfalfa County",
                            "latitude": "36.73098000",
                            "longitude": "-98.32400000"
                        },
                        {
                            "id": 111252,
                            "name": "Altus",
                            "latitude": "34.63813000",
                            "longitude": "-99.33398000"
                        },
                        {
                            "id": 111256,
                            "name": "Alva",
                            "latitude": "36.80499000",
                            "longitude": "-98.66718000"
                        },
                        {
                            "id": 111298,
                            "name": "Anadarko",
                            "latitude": "35.07256000",
                            "longitude": "-98.24366000"
                        },
                        {
                            "id": 111374,
                            "name": "Antlers",
                            "latitude": "34.23121000",
                            "longitude": "-95.62025000"
                        },
                        {
                            "id": 111379,
                            "name": "Apache",
                            "latitude": "34.89368000",
                            "longitude": "-98.36589000"
                        },
                        {
                            "id": 111412,
                            "name": "Arapaho",
                            "latitude": "35.57783000",
                            "longitude": "-98.96453000"
                        },
                        {
                            "id": 111442,
                            "name": "Ardmore",
                            "latitude": "34.17426000",
                            "longitude": "-97.14363000"
                        },
                        {
                            "id": 111459,
                            "name": "Arkoma",
                            "latitude": "35.35454000",
                            "longitude": "-94.43410000"
                        },
                        {
                            "id": 111483,
                            "name": "Arnett",
                            "latitude": "36.13504000",
                            "longitude": "-99.77484000"
                        },
                        {
                            "id": 111603,
                            "name": "Atoka",
                            "latitude": "34.38593000",
                            "longitude": "-96.12833000"
                        },
                        {
                            "id": 111606,
                            "name": "Atoka County",
                            "latitude": "34.37372000",
                            "longitude": "-96.03783000"
                        },
                        {
                            "id": 111833,
                            "name": "Barnsdall",
                            "latitude": "36.56202000",
                            "longitude": "-96.16167000"
                        },
                        {
                            "id": 111860,
                            "name": "Bartlesville",
                            "latitude": "36.74731000",
                            "longitude": "-95.98082000"
                        },
                        {
                            "id": 111994,
                            "name": "Beaver",
                            "latitude": "36.81614000",
                            "longitude": "-100.51987000"
                        },
                        {
                            "id": 111998,
                            "name": "Beaver County",
                            "latitude": "36.74971000",
                            "longitude": "-100.47678000"
                        },
                        {
                            "id": 112016,
                            "name": "Beckham County",
                            "latitude": "35.26871000",
                            "longitude": "-99.68192000"
                        },
                        {
                            "id": 112045,
                            "name": "Beggs",
                            "latitude": "35.74260000",
                            "longitude": "-96.07027000"
                        },
                        {
                            "id": 112273,
                            "name": "Bethany",
                            "latitude": "35.51867000",
                            "longitude": "-97.63226000"
                        },
                        {
                            "id": 112283,
                            "name": "Bethel Acres",
                            "latitude": "35.30868000",
                            "longitude": "-97.02558000"
                        },
                        {
                            "id": 112370,
                            "name": "Bixby",
                            "latitude": "35.94204000",
                            "longitude": "-95.88332000"
                        },
                        {
                            "id": 112396,
                            "name": "Blackwell",
                            "latitude": "36.80448000",
                            "longitude": "-97.28282000"
                        },
                        {
                            "id": 112405,
                            "name": "Blaine County",
                            "latitude": "35.87525000",
                            "longitude": "-98.43346000"
                        },
                        {
                            "id": 112417,
                            "name": "Blanchard",
                            "latitude": "35.13784000",
                            "longitude": "-97.65809000"
                        },
                        {
                            "id": 112509,
                            "name": "Boise City",
                            "latitude": "36.72947000",
                            "longitude": "-102.51324000"
                        },
                        {
                            "id": 112513,
                            "name": "Boley",
                            "latitude": "35.49341000",
                            "longitude": "-96.48362000"
                        },
                        {
                            "id": 112702,
                            "name": "Bray",
                            "latitude": "34.63786000",
                            "longitude": "-97.81753000"
                        },
                        {
                            "id": 112816,
                            "name": "Bristow",
                            "latitude": "35.83063000",
                            "longitude": "-96.39112000"
                        },
                        {
                            "id": 112841,
                            "name": "Broken Arrow",
                            "latitude": "36.05260000",
                            "longitude": "-95.79082000"
                        },
                        {
                            "id": 112842,
                            "name": "Broken Bow",
                            "latitude": "34.02928000",
                            "longitude": "-94.73910000"
                        },
                        {
                            "id": 112961,
                            "name": "Bryan County",
                            "latitude": "33.96230000",
                            "longitude": "-96.25975000"
                        },
                        {
                            "id": 113008,
                            "name": "Buffalo",
                            "latitude": "36.83559000",
                            "longitude": "-99.63040000"
                        },
                        {
                            "id": 113088,
                            "name": "Burns Flat",
                            "latitude": "35.34894000",
                            "longitude": "-99.17036000"
                        },
                        {
                            "id": 113104,
                            "name": "Bushyhead",
                            "latitude": "36.46148000",
                            "longitude": "-95.49414000"
                        },
                        {
                            "id": 113137,
                            "name": "Byng",
                            "latitude": "34.86120000",
                            "longitude": "-96.66557000"
                        },
                        {
                            "id": 113155,
                            "name": "Cache",
                            "latitude": "34.62952000",
                            "longitude": "-98.62867000"
                        },
                        {
                            "id": 113159,
                            "name": "Caddo",
                            "latitude": "34.12676000",
                            "longitude": "-96.26332000"
                        },
                        {
                            "id": 113160,
                            "name": "Caddo County",
                            "latitude": "35.17438000",
                            "longitude": "-98.37515000"
                        },
                        {
                            "id": 113195,
                            "name": "Calera",
                            "latitude": "33.93454000",
                            "longitude": "-96.42860000"
                        },
                        {
                            "id": 113315,
                            "name": "Canadian County",
                            "latitude": "35.54244000",
                            "longitude": "-97.98238000"
                        },
                        {
                            "id": 113425,
                            "name": "Carnegie",
                            "latitude": "35.10367000",
                            "longitude": "-98.60367000"
                        },
                        {
                            "id": 113482,
                            "name": "Carter County",
                            "latitude": "34.25086000",
                            "longitude": "-97.28579000"
                        },
                        {
                            "id": 113576,
                            "name": "Catoosa",
                            "latitude": "36.18899000",
                            "longitude": "-95.74582000"
                        },
                        {
                            "id": 113677,
                            "name": "Central High",
                            "latitude": "34.62313000",
                            "longitude": "-98.08976000"
                        },
                        {
                            "id": 113729,
                            "name": "Chandler",
                            "latitude": "35.70173000",
                            "longitude": "-96.88086000"
                        },
                        {
                            "id": 113823,
                            "name": "Checotah",
                            "latitude": "35.47010000",
                            "longitude": "-95.52304000"
                        },
                        {
                            "id": 113830,
                            "name": "Chelsea",
                            "latitude": "36.53565000",
                            "longitude": "-95.43247000"
                        },
                        {
                            "id": 113847,
                            "name": "Cherokee",
                            "latitude": "36.75447000",
                            "longitude": "-98.35674000"
                        },
                        {
                            "id": 113853,
                            "name": "Cherokee County",
                            "latitude": "35.90663000",
                            "longitude": "-94.99966000"
                        },
                        {
                            "id": 113925,
                            "name": "Cheyenne",
                            "latitude": "35.61394000",
                            "longitude": "-99.67149000"
                        },
                        {
                            "id": 113941,
                            "name": "Chickasha",
                            "latitude": "35.05257000",
                            "longitude": "-97.93643000"
                        },
                        {
                            "id": 113985,
                            "name": "Choctaw",
                            "latitude": "35.49756000",
                            "longitude": "-97.26892000"
                        },
                        {
                            "id": 113988,
                            "name": "Choctaw County",
                            "latitude": "34.02662000",
                            "longitude": "-95.55214000"
                        },
                        {
                            "id": 113990,
                            "name": "Chouteau",
                            "latitude": "36.18593000",
                            "longitude": "-95.34302000"
                        },
                        {
                            "id": 114024,
                            "name": "Cimarron County",
                            "latitude": "36.74831000",
                            "longitude": "-102.51771000"
                        },
                        {
                            "id": 114107,
                            "name": "Claremore",
                            "latitude": "36.31260000",
                            "longitude": "-95.61609000"
                        },
                        {
                            "id": 114237,
                            "name": "Cleora",
                            "latitude": "36.57897000",
                            "longitude": "-94.97107000"
                        },
                        {
                            "id": 114245,
                            "name": "Cleveland",
                            "latitude": "36.31032000",
                            "longitude": "-96.46584000"
                        },
                        {
                            "id": 114250,
                            "name": "Cleveland County",
                            "latitude": "35.20302000",
                            "longitude": "-97.32641000"
                        },
                        {
                            "id": 114276,
                            "name": "Clinton",
                            "latitude": "35.51561000",
                            "longitude": "-98.96731000"
                        },
                        {
                            "id": 114328,
                            "name": "Coal County",
                            "latitude": "34.58819000",
                            "longitude": "-96.29786000"
                        },
                        {
                            "id": 114336,
                            "name": "Coalgate",
                            "latitude": "34.53815000",
                            "longitude": "-96.21861000"
                        },
                        {
                            "id": 114378,
                            "name": "Colbert",
                            "latitude": "33.85316000",
                            "longitude": "-96.50249000"
                        },
                        {
                            "id": 114436,
                            "name": "Collinsville",
                            "latitude": "36.36454000",
                            "longitude": "-95.83888000"
                        },
                        {
                            "id": 114510,
                            "name": "Comanche",
                            "latitude": "34.36897000",
                            "longitude": "-97.96392000"
                        },
                        {
                            "id": 114513,
                            "name": "Comanche County",
                            "latitude": "34.66210000",
                            "longitude": "-98.47166000"
                        },
                        {
                            "id": 114523,
                            "name": "Commerce",
                            "latitude": "36.93340000",
                            "longitude": "-94.87301000"
                        },
                        {
                            "id": 114615,
                            "name": "Copeland",
                            "latitude": "36.65591000",
                            "longitude": "-94.82829000"
                        },
                        {
                            "id": 114636,
                            "name": "Cordell",
                            "latitude": "35.29061000",
                            "longitude": "-98.98841000"
                        },
                        {
                            "id": 114697,
                            "name": "Cotton County",
                            "latitude": "34.29024000",
                            "longitude": "-98.37223000"
                        },
                        {
                            "id": 114746,
                            "name": "Coweta",
                            "latitude": "35.95177000",
                            "longitude": "-95.65081000"
                        },
                        {
                            "id": 114757,
                            "name": "Craig County",
                            "latitude": "36.76176000",
                            "longitude": "-95.20850000"
                        },
                        {
                            "id": 114789,
                            "name": "Creek County",
                            "latitude": "35.90268000",
                            "longitude": "-96.37094000"
                        },
                        {
                            "id": 114794,
                            "name": "Crescent",
                            "latitude": "35.95254000",
                            "longitude": "-97.59477000"
                        },
                        {
                            "id": 114931,
                            "name": "Cushing",
                            "latitude": "35.98506000",
                            "longitude": "-96.76697000"
                        },
                        {
                            "id": 114935,
                            "name": "Custer County",
                            "latitude": "35.63886000",
                            "longitude": "-99.00150000"
                        },
                        {
                            "id": 114959,
                            "name": "Cyril",
                            "latitude": "34.89646000",
                            "longitude": "-98.20060000"
                        },
                        {
                            "id": 115072,
                            "name": "Davis",
                            "latitude": "34.50453000",
                            "longitude": "-97.11946000"
                        },
                        {
                            "id": 115187,
                            "name": "Del City",
                            "latitude": "35.44201000",
                            "longitude": "-97.44087000"
                        },
                        {
                            "id": 115204,
                            "name": "Delaware County",
                            "latitude": "36.40820000",
                            "longitude": "-94.80265000"
                        },
                        {
                            "id": 115302,
                            "name": "Dewey",
                            "latitude": "36.79592000",
                            "longitude": "-95.93554000"
                        },
                        {
                            "id": 115303,
                            "name": "Dewey County",
                            "latitude": "35.98766000",
                            "longitude": "-99.00789000"
                        },
                        {
                            "id": 115330,
                            "name": "Dickson",
                            "latitude": "34.18732000",
                            "longitude": "-96.98446000"
                        },
                        {
                            "id": 115482,
                            "name": "Drumright",
                            "latitude": "35.98840000",
                            "longitude": "-96.60113000"
                        },
                        {
                            "id": 115521,
                            "name": "Duncan",
                            "latitude": "34.50230000",
                            "longitude": "-97.95781000"
                        },
                        {
                            "id": 115567,
                            "name": "Durant",
                            "latitude": "33.99399000",
                            "longitude": "-96.37082000"
                        },
                        {
                            "id": 115877,
                            "name": "Edmond",
                            "latitude": "35.65283000",
                            "longitude": "-97.47810000"
                        },
                        {
                            "id": 115935,
                            "name": "El Reno",
                            "latitude": "35.53227000",
                            "longitude": "-97.95505000"
                        },
                        {
                            "id": 115964,
                            "name": "Elgin",
                            "latitude": "34.78035000",
                            "longitude": "-98.29227000"
                        },
                        {
                            "id": 115983,
                            "name": "Elk City",
                            "latitude": "35.41199000",
                            "longitude": "-99.40426000"
                        },
                        {
                            "id": 116031,
                            "name": "Ellis County",
                            "latitude": "36.21836000",
                            "longitude": "-99.75460000"
                        },
                        {
                            "id": 116138,
                            "name": "Enid",
                            "latitude": "36.39559000",
                            "longitude": "-97.87839000"
                        },
                        {
                            "id": 116163,
                            "name": "Erick",
                            "latitude": "35.21533000",
                            "longitude": "-99.86649000"
                        },
                        {
                            "id": 116225,
                            "name": "Eufaula",
                            "latitude": "35.28722000",
                            "longitude": "-95.58250000"
                        },
                        {
                            "id": 116302,
                            "name": "Fairfax",
                            "latitude": "36.57366000",
                            "longitude": "-96.70420000"
                        },
                        {
                            "id": 116329,
                            "name": "Fairland",
                            "latitude": "36.75118000",
                            "longitude": "-94.84746000"
                        },
                        {
                            "id": 116350,
                            "name": "Fairview",
                            "latitude": "36.26892000",
                            "longitude": "-98.47980000"
                        },
                        {
                            "id": 116565,
                            "name": "Fletcher",
                            "latitude": "34.82312000",
                            "longitude": "-98.24422000"
                        },
                        {
                            "id": 116665,
                            "name": "Forest Park",
                            "latitude": "35.50423000",
                            "longitude": "-97.44615000"
                        },
                        {
                            "id": 116713,
                            "name": "Fort Gibson",
                            "latitude": "35.79760000",
                            "longitude": "-95.25052000"
                        },
                        {
                            "id": 116886,
                            "name": "Frederick",
                            "latitude": "34.39203000",
                            "longitude": "-99.01841000"
                        },
                        {
                            "id": 117082,
                            "name": "Garfield County",
                            "latitude": "36.37906000",
                            "longitude": "-97.78272000"
                        },
                        {
                            "id": 117104,
                            "name": "Garvin County",
                            "latitude": "34.70457000",
                            "longitude": "-97.30932000"
                        },
                        {
                            "id": 117130,
                            "name": "Geary",
                            "latitude": "35.63116000",
                            "longitude": "-98.31729000"
                        },
                        {
                            "id": 117176,
                            "name": "Geronimo",
                            "latitude": "34.48119000",
                            "longitude": "-98.38311000"
                        },
                        {
                            "id": 117302,
                            "name": "Glenpool",
                            "latitude": "35.95537000",
                            "longitude": "-96.00888000"
                        },
                        {
                            "id": 117365,
                            "name": "Goldsby",
                            "latitude": "35.14118000",
                            "longitude": "-97.47698000"
                        },
                        {
                            "id": 117392,
                            "name": "Goodwell",
                            "latitude": "36.59530000",
                            "longitude": "-101.63655000"
                        },
                        {
                            "id": 117427,
                            "name": "Grady County",
                            "latitude": "35.01694000",
                            "longitude": "-97.88411000"
                        },
                        {
                            "id": 117491,
                            "name": "Granite",
                            "latitude": "34.96228000",
                            "longitude": "-99.38064000"
                        },
                        {
                            "id": 117510,
                            "name": "Grant County",
                            "latitude": "36.79614000",
                            "longitude": "-97.78616000"
                        },
                        {
                            "id": 117714,
                            "name": "Greer County",
                            "latitude": "34.93570000",
                            "longitude": "-99.56080000"
                        },
                        {
                            "id": 117754,
                            "name": "Grove",
                            "latitude": "36.59369000",
                            "longitude": "-94.76912000"
                        },
                        {
                            "id": 117812,
                            "name": "Guthrie",
                            "latitude": "35.87894000",
                            "longitude": "-97.42532000"
                        },
                        {
                            "id": 117818,
                            "name": "Guymon",
                            "latitude": "36.68280000",
                            "longitude": "-101.48155000"
                        },
                        {
                            "id": 117867,
                            "name": "Hall Park",
                            "latitude": "35.23701000",
                            "longitude": "-97.40642000"
                        },
                        {
                            "id": 118028,
                            "name": "Harmon County",
                            "latitude": "34.74412000",
                            "longitude": "-99.84628000"
                        },
                        {
                            "id": 118034,
                            "name": "Harper County",
                            "latitude": "36.78873000",
                            "longitude": "-99.66736000"
                        },
                        {
                            "id": 118038,
                            "name": "Harrah",
                            "latitude": "35.48951000",
                            "longitude": "-97.16364000"
                        },
                        {
                            "id": 118103,
                            "name": "Hartshorne",
                            "latitude": "34.84510000",
                            "longitude": "-95.55748000"
                        },
                        {
                            "id": 118123,
                            "name": "Haskell",
                            "latitude": "35.82038000",
                            "longitude": "-95.67415000"
                        },
                        {
                            "id": 118125,
                            "name": "Haskell County",
                            "latitude": "35.22482000",
                            "longitude": "-95.11660000"
                        },
                        {
                            "id": 118218,
                            "name": "Healdton",
                            "latitude": "34.23315000",
                            "longitude": "-97.48780000"
                        },
                        {
                            "id": 118226,
                            "name": "Heavener",
                            "latitude": "34.88927000",
                            "longitude": "-94.60078000"
                        },
                        {
                            "id": 118250,
                            "name": "Helena",
                            "latitude": "36.54614000",
                            "longitude": "-98.27007000"
                        },
                        {
                            "id": 118292,
                            "name": "Hennessey",
                            "latitude": "36.10920000",
                            "longitude": "-97.89867000"
                        },
                        {
                            "id": 118307,
                            "name": "Henryetta",
                            "latitude": "35.43983000",
                            "longitude": "-95.98194000"
                        },
                        {
                            "id": 118488,
                            "name": "Hinton",
                            "latitude": "35.47144000",
                            "longitude": "-98.35562000"
                        },
                        {
                            "id": 118496,
                            "name": "Hobart",
                            "latitude": "35.02950000",
                            "longitude": "-99.09313000"
                        },
                        {
                            "id": 118526,
                            "name": "Holdenville",
                            "latitude": "35.08036000",
                            "longitude": "-96.39918000"
                        },
                        {
                            "id": 118547,
                            "name": "Hollis",
                            "latitude": "34.68839000",
                            "longitude": "-99.91205000"
                        },
                        {
                            "id": 118609,
                            "name": "Hominy",
                            "latitude": "36.41424000",
                            "longitude": "-96.39530000"
                        },
                        {
                            "id": 118628,
                            "name": "Hooker",
                            "latitude": "36.86003000",
                            "longitude": "-101.21350000"
                        },
                        {
                            "id": 118758,
                            "name": "Hughes County",
                            "latitude": "35.04834000",
                            "longitude": "-96.25024000"
                        },
                        {
                            "id": 118765,
                            "name": "Hugo",
                            "latitude": "34.01066000",
                            "longitude": "-95.50968000"
                        },
                        {
                            "id": 118868,
                            "name": "Idabel",
                            "latitude": "33.89566000",
                            "longitude": "-94.82633000"
                        },
                        {
                            "id": 118948,
                            "name": "Inola",
                            "latitude": "36.15121000",
                            "longitude": "-95.50942000"
                        },
                        {
                            "id": 119086,
                            "name": "Jackson County",
                            "latitude": "34.58797000",
                            "longitude": "-99.41476000"
                        },
                        {
                            "id": 119153,
                            "name": "Jay",
                            "latitude": "36.42119000",
                            "longitude": "-94.79690000"
                        },
                        {
                            "id": 119185,
                            "name": "Jefferson County",
                            "latitude": "34.11101000",
                            "longitude": "-97.83585000"
                        },
                        {
                            "id": 119217,
                            "name": "Jenks",
                            "latitude": "36.02287000",
                            "longitude": "-95.96833000"
                        },
                        {
                            "id": 119282,
                            "name": "Johnston County",
                            "latitude": "34.31649000",
                            "longitude": "-96.66066000"
                        },
                        {
                            "id": 119291,
                            "name": "Jones",
                            "latitude": "35.56589000",
                            "longitude": "-97.28698000"
                        },
                        {
                            "id": 119344,
                            "name": "Justice",
                            "latitude": "36.29287000",
                            "longitude": "-95.56664000"
                        },
                        {
                            "id": 119401,
                            "name": "Kay County",
                            "latitude": "36.81801000",
                            "longitude": "-97.14392000"
                        },
                        {
                            "id": 119430,
                            "name": "Kellyville",
                            "latitude": "35.94370000",
                            "longitude": "-96.21361000"
                        },
                        {
                            "id": 119503,
                            "name": "Kenwood",
                            "latitude": "36.31453000",
                            "longitude": "-94.98579000"
                        },
                        {
                            "id": 119553,
                            "name": "Kiefer",
                            "latitude": "35.94482000",
                            "longitude": "-96.06528000"
                        },
                        {
                            "id": 119592,
                            "name": "Kingfisher",
                            "latitude": "35.86143000",
                            "longitude": "-97.93172000"
                        },
                        {
                            "id": 119593,
                            "name": "Kingfisher County",
                            "latitude": "35.94539000",
                            "longitude": "-97.94211000"
                        },
                        {
                            "id": 119622,
                            "name": "Kingston",
                            "latitude": "33.99871000",
                            "longitude": "-96.71972000"
                        },
                        {
                            "id": 119647,
                            "name": "Kiowa County",
                            "latitude": "34.91637000",
                            "longitude": "-98.98087000"
                        },
                        {
                            "id": 119704,
                            "name": "Konawa",
                            "latitude": "34.95953000",
                            "longitude": "-96.75280000"
                        },
                        {
                            "id": 119715,
                            "name": "Krebs",
                            "latitude": "34.92787000",
                            "longitude": "-95.71582000"
                        },
                        {
                            "id": 120116,
                            "name": "Langston",
                            "latitude": "35.94505000",
                            "longitude": "-97.25531000"
                        },
                        {
                            "id": 120173,
                            "name": "Latimer County",
                            "latitude": "34.87606000",
                            "longitude": "-95.25042000"
                        },
                        {
                            "id": 120218,
                            "name": "Laverne",
                            "latitude": "36.70975000",
                            "longitude": "-99.89346000"
                        },
                        {
                            "id": 120250,
                            "name": "Lawton",
                            "latitude": "34.60869000",
                            "longitude": "-98.39033000"
                        },
                        {
                            "id": 120259,
                            "name": "Le Flore County",
                            "latitude": "34.90030000",
                            "longitude": "-94.70339000"
                        },
                        {
                            "id": 120445,
                            "name": "Lexington",
                            "latitude": "35.01479000",
                            "longitude": "-97.33558000"
                        },
                        {
                            "id": 120523,
                            "name": "Lincoln County",
                            "latitude": "35.70300000",
                            "longitude": "-96.88092000"
                        },
                        {
                            "id": 120572,
                            "name": "Lindsay",
                            "latitude": "34.83480000",
                            "longitude": "-97.60253000"
                        },
                        {
                            "id": 120687,
                            "name": "Locust Grove",
                            "latitude": "36.20009000",
                            "longitude": "-95.16774000"
                        },
                        {
                            "id": 120698,
                            "name": "Logan County",
                            "latitude": "35.91934000",
                            "longitude": "-97.44332000"
                        },
                        {
                            "id": 120726,
                            "name": "Lone Grove",
                            "latitude": "34.17537000",
                            "longitude": "-97.26279000"
                        },
                        {
                            "id": 120755,
                            "name": "Longtown",
                            "latitude": "35.24538000",
                            "longitude": "-95.51276000"
                        },
                        {
                            "id": 120820,
                            "name": "Love County",
                            "latitude": "33.94986000",
                            "longitude": "-97.24418000"
                        },
                        {
                            "id": 120894,
                            "name": "Luther",
                            "latitude": "35.66173000",
                            "longitude": "-97.19559000"
                        },
                        {
                            "id": 120983,
                            "name": "Madill",
                            "latitude": "34.09038000",
                            "longitude": "-96.77167000"
                        },
                        {
                            "id": 121053,
                            "name": "Major County",
                            "latitude": "36.31164000",
                            "longitude": "-98.53595000"
                        },
                        {
                            "id": 121110,
                            "name": "Mangum",
                            "latitude": "34.87200000",
                            "longitude": "-99.50426000"
                        },
                        {
                            "id": 121133,
                            "name": "Mannford",
                            "latitude": "36.13341000",
                            "longitude": "-96.35446000"
                        },
                        {
                            "id": 121217,
                            "name": "Marietta",
                            "latitude": "33.93704000",
                            "longitude": "-97.11668000"
                        },
                        {
                            "id": 121285,
                            "name": "Marlow",
                            "latitude": "34.64813000",
                            "longitude": "-97.95809000"
                        },
                        {
                            "id": 121314,
                            "name": "Marshall County",
                            "latitude": "34.02449000",
                            "longitude": "-96.76913000"
                        },
                        {
                            "id": 121416,
                            "name": "Maud",
                            "latitude": "35.13036000",
                            "longitude": "-96.77585000"
                        },
                        {
                            "id": 121435,
                            "name": "Mayes County",
                            "latitude": "36.30188000",
                            "longitude": "-95.23085000"
                        },
                        {
                            "id": 121452,
                            "name": "Maysville",
                            "latitude": "34.81730000",
                            "longitude": "-97.40586000"
                        },
                        {
                            "id": 121462,
                            "name": "McAlester",
                            "latitude": "34.93343000",
                            "longitude": "-95.76971000"
                        },
                        {
                            "id": 121469,
                            "name": "McClain County",
                            "latitude": "35.00934000",
                            "longitude": "-97.44430000"
                        },
                        {
                            "id": 121481,
                            "name": "McCord",
                            "latitude": "36.67847000",
                            "longitude": "-97.04000000"
                        },
                        {
                            "id": 121490,
                            "name": "McCurtain County",
                            "latitude": "34.11529000",
                            "longitude": "-94.77133000"
                        },
                        {
                            "id": 121514,
                            "name": "McIntosh County",
                            "latitude": "35.37366000",
                            "longitude": "-95.66684000"
                        },
                        {
                            "id": 121536,
                            "name": "McLoud",
                            "latitude": "35.43590000",
                            "longitude": "-97.09142000"
                        },
                        {
                            "id": 121591,
                            "name": "Medford",
                            "latitude": "36.80697000",
                            "longitude": "-97.73366000"
                        },
                        {
                            "id": 121612,
                            "name": "Meeker",
                            "latitude": "35.50340000",
                            "longitude": "-96.90280000"
                        },
                        {
                            "id": 121691,
                            "name": "Meridian",
                            "latitude": "34.42731000",
                            "longitude": "-97.97809000"
                        },
                        {
                            "id": 121747,
                            "name": "Miami",
                            "latitude": "36.87451000",
                            "longitude": "-94.87746000"
                        },
                        {
                            "id": 121824,
                            "name": "Midwest City",
                            "latitude": "35.44951000",
                            "longitude": "-97.39670000"
                        },
                        {
                            "id": 121927,
                            "name": "Minco",
                            "latitude": "35.31284000",
                            "longitude": "-97.94449000"
                        },
                        {
                            "id": 122200,
                            "name": "Moore",
                            "latitude": "35.33951000",
                            "longitude": "-97.48670000"
                        },
                        {
                            "id": 122206,
                            "name": "Mooreland",
                            "latitude": "36.43920000",
                            "longitude": "-99.20482000"
                        },
                        {
                            "id": 122266,
                            "name": "Morris",
                            "latitude": "35.60760000",
                            "longitude": "-95.86027000"
                        },
                        {
                            "id": 122325,
                            "name": "Mounds",
                            "latitude": "35.87648000",
                            "longitude": "-96.06111000"
                        },
                        {
                            "id": 122456,
                            "name": "Muldrow",
                            "latitude": "35.40620000",
                            "longitude": "-94.59883000"
                        },
                        {
                            "id": 122494,
                            "name": "Murray County",
                            "latitude": "34.48233000",
                            "longitude": "-97.06792000"
                        },
                        {
                            "id": 122512,
                            "name": "Muskogee",
                            "latitude": "35.74788000",
                            "longitude": "-95.36969000"
                        },
                        {
                            "id": 122513,
                            "name": "Muskogee County",
                            "latitude": "35.61613000",
                            "longitude": "-95.37953000"
                        },
                        {
                            "id": 122515,
                            "name": "Mustang",
                            "latitude": "35.38423000",
                            "longitude": "-97.72449000"
                        },
                        {
                            "id": 122820,
                            "name": "Newcastle",
                            "latitude": "35.24729000",
                            "longitude": "-97.59976000"
                        },
                        {
                            "id": 122832,
                            "name": "Newkirk",
                            "latitude": "36.88225000",
                            "longitude": "-97.05337000"
                        },
                        {
                            "id": 122891,
                            "name": "Nichols Hills",
                            "latitude": "35.55089000",
                            "longitude": "-97.54893000"
                        },
                        {
                            "id": 122897,
                            "name": "Nicoma Park",
                            "latitude": "35.49117000",
                            "longitude": "-97.32309000"
                        },
                        {
                            "id": 122903,
                            "name": "Ninnekah",
                            "latitude": "34.94785000",
                            "longitude": "-97.92393000"
                        },
                        {
                            "id": 122916,
                            "name": "Noble",
                            "latitude": "35.13924000",
                            "longitude": "-97.39475000"
                        },
                        {
                            "id": 122917,
                            "name": "Noble County",
                            "latitude": "36.38860000",
                            "longitude": "-97.23051000"
                        },
                        {
                            "id": 122949,
                            "name": "Norman",
                            "latitude": "35.22257000",
                            "longitude": "-97.43948000"
                        },
                        {
                            "id": 123209,
                            "name": "Nowata",
                            "latitude": "36.70065000",
                            "longitude": "-95.63803000"
                        },
                        {
                            "id": 123210,
                            "name": "Nowata County",
                            "latitude": "36.79848000",
                            "longitude": "-95.61740000"
                        },
                        {
                            "id": 123279,
                            "name": "Oakhurst",
                            "latitude": "36.07537000",
                            "longitude": "-96.06444000"
                        },
                        {
                            "id": 123287,
                            "name": "Oakland",
                            "latitude": "34.10010000",
                            "longitude": "-96.79389000"
                        },
                        {
                            "id": 123389,
                            "name": "Oilton",
                            "latitude": "36.08451000",
                            "longitude": "-96.58363000"
                        },
                        {
                            "id": 123395,
                            "name": "Okarche",
                            "latitude": "35.72588000",
                            "longitude": "-97.97644000"
                        },
                        {
                            "id": 123400,
                            "name": "Okeene",
                            "latitude": "36.11615000",
                            "longitude": "-98.31702000"
                        },
                        {
                            "id": 123401,
                            "name": "Okemah",
                            "latitude": "35.43259000",
                            "longitude": "-96.30501000"
                        },
                        {
                            "id": 123403,
                            "name": "Okfuskee County",
                            "latitude": "35.46546000",
                            "longitude": "-96.32280000"
                        },
                        {
                            "id": 123404,
                            "name": "Oklahoma City",
                            "latitude": "35.46756000",
                            "longitude": "-97.51643000"
                        },
                        {
                            "id": 123405,
                            "name": "Oklahoma County",
                            "latitude": "35.55152000",
                            "longitude": "-97.40720000"
                        },
                        {
                            "id": 123406,
                            "name": "Okmulgee",
                            "latitude": "35.62344000",
                            "longitude": "-95.96055000"
                        },
                        {
                            "id": 123407,
                            "name": "Okmulgee County",
                            "latitude": "35.64666000",
                            "longitude": "-95.96431000"
                        },
                        {
                            "id": 123492,
                            "name": "Oologah",
                            "latitude": "36.44704000",
                            "longitude": "-95.70832000"
                        },
                        {
                            "id": 123591,
                            "name": "Osage County",
                            "latitude": "36.62919000",
                            "longitude": "-96.39849000"
                        },
                        {
                            "id": 123639,
                            "name": "Ottawa County",
                            "latitude": "36.83551000",
                            "longitude": "-94.81044000"
                        },
                        {
                            "id": 123658,
                            "name": "Owasso",
                            "latitude": "36.26954000",
                            "longitude": "-95.85471000"
                        },
                        {
                            "id": 123797,
                            "name": "Panama",
                            "latitude": "35.16732000",
                            "longitude": "-94.67245000"
                        },
                        {
                            "id": 123847,
                            "name": "Park Hill",
                            "latitude": "35.86120000",
                            "longitude": "-94.95884000"
                        },
                        {
                            "id": 123925,
                            "name": "Pauls Valley",
                            "latitude": "34.74008000",
                            "longitude": "-97.22225000"
                        },
                        {
                            "id": 123930,
                            "name": "Pawhuska",
                            "latitude": "36.66784000",
                            "longitude": "-96.33723000"
                        },
                        {
                            "id": 123934,
                            "name": "Pawnee",
                            "latitude": "36.33783000",
                            "longitude": "-96.80392000"
                        },
                        {
                            "id": 123937,
                            "name": "Pawnee County",
                            "latitude": "36.31693000",
                            "longitude": "-96.69930000"
                        },
                        {
                            "id": 123946,
                            "name": "Payne County",
                            "latitude": "36.07732000",
                            "longitude": "-96.97577000"
                        },
                        {
                            "id": 124062,
                            "name": "Perkins",
                            "latitude": "35.97394000",
                            "longitude": "-97.03364000"
                        },
                        {
                            "id": 124069,
                            "name": "Perry",
                            "latitude": "36.28949000",
                            "longitude": "-97.28810000"
                        },
                        {
                            "id": 124167,
                            "name": "Piedmont",
                            "latitude": "35.64200000",
                            "longitude": "-97.74643000"
                        },
                        {
                            "id": 124270,
                            "name": "Pink",
                            "latitude": "35.26063000",
                            "longitude": "-97.11975000"
                        },
                        {
                            "id": 124295,
                            "name": "Pittsburg County",
                            "latitude": "34.92391000",
                            "longitude": "-95.74840000"
                        },
                        {
                            "id": 124419,
                            "name": "Pocola",
                            "latitude": "35.23121000",
                            "longitude": "-94.47800000"
                        },
                        {
                            "id": 124464,
                            "name": "Ponca City",
                            "latitude": "36.70698000",
                            "longitude": "-97.08559000"
                        },
                        {
                            "id": 124478,
                            "name": "Pontotoc County",
                            "latitude": "34.72800000",
                            "longitude": "-96.68444000"
                        },
                        {
                            "id": 124579,
                            "name": "Poteau",
                            "latitude": "35.05371000",
                            "longitude": "-94.62356000"
                        },
                        {
                            "id": 124590,
                            "name": "Pottawatomie County",
                            "latitude": "35.20672000",
                            "longitude": "-96.94830000"
                        },
                        {
                            "id": 124622,
                            "name": "Prague",
                            "latitude": "35.48674000",
                            "longitude": "-96.68502000"
                        },
                        {
                            "id": 124726,
                            "name": "Pryor",
                            "latitude": "36.30843000",
                            "longitude": "-95.31691000"
                        },
                        {
                            "id": 124727,
                            "name": "Pryor Creek",
                            "latitude": "36.30862000",
                            "longitude": "-95.31777000"
                        },
                        {
                            "id": 124753,
                            "name": "Purcell",
                            "latitude": "35.01368000",
                            "longitude": "-97.36114000"
                        },
                        {
                            "id": 124759,
                            "name": "Pushmataha County",
                            "latitude": "34.41620000",
                            "longitude": "-95.37579000"
                        },
                        {
                            "id": 124804,
                            "name": "Quinton",
                            "latitude": "35.12288000",
                            "longitude": "-95.37109000"
                        },
                        {
                            "id": 125144,
                            "name": "Ringling",
                            "latitude": "34.17843000",
                            "longitude": "-97.59253000"
                        },
                        {
                            "id": 125347,
                            "name": "Roger Mills County",
                            "latitude": "35.68839000",
                            "longitude": "-99.69569000"
                        },
                        {
                            "id": 125352,
                            "name": "Rogers County",
                            "latitude": "36.37157000",
                            "longitude": "-95.60436000"
                        },
                        {
                            "id": 125359,
                            "name": "Roland",
                            "latitude": "35.42120000",
                            "longitude": "-94.51466000"
                        },
                        {
                            "id": 125522,
                            "name": "Rush Springs",
                            "latitude": "34.78257000",
                            "longitude": "-97.95698000"
                        },
                        {
                            "id": 125745,
                            "name": "Salina",
                            "latitude": "36.29287000",
                            "longitude": "-95.15330000"
                        },
                        {
                            "id": 125762,
                            "name": "Sallisaw",
                            "latitude": "35.46037000",
                            "longitude": "-94.78745000"
                        },
                        {
                            "id": 125861,
                            "name": "Sand Springs",
                            "latitude": "36.13981000",
                            "longitude": "-96.10889000"
                        },
                        {
                            "id": 125933,
                            "name": "Sapulpa",
                            "latitude": "35.99870000",
                            "longitude": "-96.11417000"
                        },
                        {
                            "id": 125989,
                            "name": "Sayre",
                            "latitude": "35.29116000",
                            "longitude": "-99.64010000"
                        },
                        {
                            "id": 126145,
                            "name": "Seminole",
                            "latitude": "35.22452000",
                            "longitude": "-96.67057000"
                        },
                        {
                            "id": 126149,
                            "name": "Seminole County",
                            "latitude": "35.16749000",
                            "longitude": "-96.61552000"
                        },
                        {
                            "id": 126165,
                            "name": "Sequoyah County",
                            "latitude": "35.49535000",
                            "longitude": "-94.75524000"
                        },
                        {
                            "id": 126241,
                            "name": "Shattuck",
                            "latitude": "36.27614000",
                            "longitude": "-99.88276000"
                        },
                        {
                            "id": 126250,
                            "name": "Shawnee",
                            "latitude": "35.32729000",
                            "longitude": "-96.92530000"
                        },
                        {
                            "id": 126478,
                            "name": "Skiatook",
                            "latitude": "36.36842000",
                            "longitude": "-96.00138000"
                        },
                        {
                            "id": 126494,
                            "name": "Slaughterville",
                            "latitude": "35.08729000",
                            "longitude": "-97.33503000"
                        },
                        {
                            "id": 126549,
                            "name": "Snyder",
                            "latitude": "34.65896000",
                            "longitude": "-98.95174000"
                        },
                        {
                            "id": 126822,
                            "name": "Spencer",
                            "latitude": "35.52284000",
                            "longitude": "-97.37726000"
                        },
                        {
                            "id": 126833,
                            "name": "Sperry",
                            "latitude": "36.29731000",
                            "longitude": "-95.99139000"
                        },
                        {
                            "id": 126840,
                            "name": "Spiro",
                            "latitude": "35.24121000",
                            "longitude": "-94.61994000"
                        },
                        {
                            "id": 127022,
                            "name": "Stephens County",
                            "latitude": "34.48559000",
                            "longitude": "-97.85148000"
                        },
                        {
                            "id": 127057,
                            "name": "Stigler",
                            "latitude": "35.25371000",
                            "longitude": "-95.12302000"
                        },
                        {
                            "id": 127060,
                            "name": "Stillwater",
                            "latitude": "36.11561000",
                            "longitude": "-97.05837000"
                        },
                        {
                            "id": 127064,
                            "name": "Stilwell",
                            "latitude": "35.81453000",
                            "longitude": "-94.62856000"
                        },
                        {
                            "id": 127123,
                            "name": "Stratford",
                            "latitude": "34.79675000",
                            "longitude": "-96.95946000"
                        },
                        {
                            "id": 127139,
                            "name": "Stroud",
                            "latitude": "35.74868000",
                            "longitude": "-96.65807000"
                        },
                        {
                            "id": 127195,
                            "name": "Sulphur",
                            "latitude": "34.50787000",
                            "longitude": "-96.96835000"
                        },
                        {
                            "id": 127377,
                            "name": "Tahlequah",
                            "latitude": "35.91537000",
                            "longitude": "-94.96996000"
                        },
                        {
                            "id": 127388,
                            "name": "Talihina",
                            "latitude": "34.75149000",
                            "longitude": "-95.04802000"
                        },
                        {
                            "id": 127398,
                            "name": "Taloga",
                            "latitude": "36.03865000",
                            "longitude": "-98.96371000"
                        },
                        {
                            "id": 127478,
                            "name": "Tecumseh",
                            "latitude": "35.25785000",
                            "longitude": "-96.93669000"
                        },
                        {
                            "id": 127543,
                            "name": "Texanna",
                            "latitude": "35.34732000",
                            "longitude": "-95.43692000"
                        },
                        {
                            "id": 127548,
                            "name": "Texas County",
                            "latitude": "36.74791000",
                            "longitude": "-101.49001000"
                        },
                        {
                            "id": 127564,
                            "name": "The Village",
                            "latitude": "35.56089000",
                            "longitude": "-97.55143000"
                        },
                        {
                            "id": 127578,
                            "name": "Thomas",
                            "latitude": "35.74422000",
                            "longitude": "-98.74758000"
                        },
                        {
                            "id": 127650,
                            "name": "Tillman County",
                            "latitude": "34.37285000",
                            "longitude": "-98.92424000"
                        },
                        {
                            "id": 127682,
                            "name": "Tishomingo",
                            "latitude": "34.23621000",
                            "longitude": "-96.67861000"
                        },
                        {
                            "id": 127721,
                            "name": "Tonkawa",
                            "latitude": "36.67837000",
                            "longitude": "-97.31004000"
                        },
                        {
                            "id": 127888,
                            "name": "Tulsa",
                            "latitude": "36.15398000",
                            "longitude": "-95.99277000"
                        },
                        {
                            "id": 127889,
                            "name": "Tulsa County",
                            "latitude": "36.12108000",
                            "longitude": "-95.94148000"
                        },
                        {
                            "id": 127899,
                            "name": "Turley",
                            "latitude": "36.24204000",
                            "longitude": "-95.97583000"
                        },
                        {
                            "id": 127918,
                            "name": "Tuttle",
                            "latitude": "35.29089000",
                            "longitude": "-97.81227000"
                        },
                        {
                            "id": 127981,
                            "name": "Union City",
                            "latitude": "35.39172000",
                            "longitude": "-97.94144000"
                        },
                        {
                            "id": 128181,
                            "name": "Verdigris",
                            "latitude": "36.23482000",
                            "longitude": "-95.69109000"
                        },
                        {
                            "id": 128214,
                            "name": "Vian",
                            "latitude": "35.49843000",
                            "longitude": "-94.96967000"
                        },
                        {
                            "id": 128277,
                            "name": "Vinita",
                            "latitude": "36.63869000",
                            "longitude": "-95.15413000"
                        },
                        {
                            "id": 128329,
                            "name": "Wagoner",
                            "latitude": "35.95954000",
                            "longitude": "-95.36941000"
                        },
                        {
                            "id": 128330,
                            "name": "Wagoner County",
                            "latitude": "35.96110000",
                            "longitude": "-95.52118000"
                        },
                        {
                            "id": 128429,
                            "name": "Walters",
                            "latitude": "34.35981000",
                            "longitude": "-98.30783000"
                        },
                        {
                            "id": 128473,
                            "name": "Warner",
                            "latitude": "35.49426000",
                            "longitude": "-95.30552000"
                        },
                        {
                            "id": 128475,
                            "name": "Warr Acres",
                            "latitude": "35.52256000",
                            "longitude": "-97.61893000"
                        },
                        {
                            "id": 128564,
                            "name": "Washington County",
                            "latitude": "36.71524000",
                            "longitude": "-95.90437000"
                        },
                        {
                            "id": 128589,
                            "name": "Washita County",
                            "latitude": "35.29038000",
                            "longitude": "-98.99223000"
                        },
                        {
                            "id": 128636,
                            "name": "Watonga",
                            "latitude": "35.84477000",
                            "longitude": "-98.41313000"
                        },
                        {
                            "id": 128651,
                            "name": "Waukomis",
                            "latitude": "36.28031000",
                            "longitude": "-97.89811000"
                        },
                        {
                            "id": 128659,
                            "name": "Waurika",
                            "latitude": "34.16704000",
                            "longitude": "-97.99754000"
                        },
                        {
                            "id": 128717,
                            "name": "Weatherford",
                            "latitude": "35.52616000",
                            "longitude": "-98.70757000"
                        },
                        {
                            "id": 129077,
                            "name": "Westville",
                            "latitude": "35.99258000",
                            "longitude": "-94.56800000"
                        },
                        {
                            "id": 129091,
                            "name": "Wetumka",
                            "latitude": "35.23759000",
                            "longitude": "-96.24167000"
                        },
                        {
                            "id": 129096,
                            "name": "Wewoka",
                            "latitude": "35.15869000",
                            "longitude": "-96.49335000"
                        },
                        {
                            "id": 129227,
                            "name": "Wilburton",
                            "latitude": "34.91871000",
                            "longitude": "-95.30914000"
                        },
                        {
                            "id": 129330,
                            "name": "Wilson",
                            "latitude": "34.16204000",
                            "longitude": "-97.42586000"
                        },
                        {
                            "id": 129463,
                            "name": "Wister",
                            "latitude": "34.96732000",
                            "longitude": "-94.72467000"
                        },
                        {
                            "id": 129549,
                            "name": "Woods County",
                            "latitude": "36.76694000",
                            "longitude": "-98.86512000"
                        },
                        {
                            "id": 129575,
                            "name": "Woodward",
                            "latitude": "36.43365000",
                            "longitude": "-99.39039000"
                        },
                        {
                            "id": 129577,
                            "name": "Woodward County",
                            "latitude": "36.42267000",
                            "longitude": "-99.26497000"
                        },
                        {
                            "id": 129633,
                            "name": "Wynnewood",
                            "latitude": "34.64342000",
                            "longitude": "-97.16447000"
                        },
                        {
                            "id": 129654,
                            "name": "Yale",
                            "latitude": "36.11423000",
                            "longitude": "-96.69919000"
                        },
                        {
                            "id": 129730,
                            "name": "Yukon",
                            "latitude": "35.50672000",
                            "longitude": "-97.76254000"
                        }
                    ]
                },
                {
                    "id": 1415,
                    "name": "Oregon",
                    "state_code": "OR",
                    "cities": [
                        {
                            "id": 111111,
                            "name": "Albany",
                            "latitude": "44.63651000",
                            "longitude": "-123.10593000"
                        },
                        {
                            "id": 111214,
                            "name": "Aloha",
                            "latitude": "45.49428000",
                            "longitude": "-122.86705000"
                        },
                        {
                            "id": 111236,
                            "name": "Altamont",
                            "latitude": "42.20681000",
                            "longitude": "-121.73722000"
                        },
                        {
                            "id": 111290,
                            "name": "Amity",
                            "latitude": "45.11567000",
                            "longitude": "-123.20733000"
                        },
                        {
                            "id": 111540,
                            "name": "Ashland",
                            "latitude": "42.19458000",
                            "longitude": "-122.70948000"
                        },
                        {
                            "id": 111563,
                            "name": "Astoria",
                            "latitude": "46.18788000",
                            "longitude": "-123.83125000"
                        },
                        {
                            "id": 111572,
                            "name": "Athena",
                            "latitude": "45.81180000",
                            "longitude": "-118.49053000"
                        },
                        {
                            "id": 111655,
                            "name": "Aumsville",
                            "latitude": "44.84095000",
                            "longitude": "-122.87092000"
                        },
                        {
                            "id": 111740,
                            "name": "Baker City",
                            "latitude": "44.77487000",
                            "longitude": "-117.83438000"
                        },
                        {
                            "id": 111743,
                            "name": "Baker County",
                            "latitude": "44.70923000",
                            "longitude": "-117.67534000"
                        },
                        {
                            "id": 111790,
                            "name": "Bandon",
                            "latitude": "43.11900000",
                            "longitude": "-124.40845000"
                        },
                        {
                            "id": 111797,
                            "name": "Banks",
                            "latitude": "45.61872000",
                            "longitude": "-123.11428000"
                        },
                        {
                            "id": 111874,
                            "name": "Barview",
                            "latitude": "43.35428000",
                            "longitude": "-124.31317000"
                        },
                        {
                            "id": 111923,
                            "name": "Bay City",
                            "latitude": "45.52260000",
                            "longitude": "-123.88930000"
                        },
                        {
                            "id": 112005,
                            "name": "Beavercreek",
                            "latitude": "45.28790000",
                            "longitude": "-122.53564000"
                        },
                        {
                            "id": 112010,
                            "name": "Beaverton",
                            "latitude": "45.48706000",
                            "longitude": "-122.80371000"
                        },
                        {
                            "id": 112166,
                            "name": "Bend",
                            "latitude": "44.05817000",
                            "longitude": "-121.31531000"
                        },
                        {
                            "id": 112206,
                            "name": "Benton County",
                            "latitude": "44.49176000",
                            "longitude": "-123.42931000"
                        },
                        {
                            "id": 112276,
                            "name": "Bethany",
                            "latitude": "45.55789000",
                            "longitude": "-122.86760000"
                        },
                        {
                            "id": 112489,
                            "name": "Boardman",
                            "latitude": "45.83986000",
                            "longitude": "-119.70058000"
                        },
                        {
                            "id": 112862,
                            "name": "Brookings",
                            "latitude": "42.05261000",
                            "longitude": "-124.28398000"
                        },
                        {
                            "id": 112935,
                            "name": "Brownsville",
                            "latitude": "44.39346000",
                            "longitude": "-122.98481000"
                        },
                        {
                            "id": 113041,
                            "name": "Bunker Hill",
                            "latitude": "43.35595000",
                            "longitude": "-124.20483000"
                        },
                        {
                            "id": 113087,
                            "name": "Burns",
                            "latitude": "43.58626000",
                            "longitude": "-119.05410000"
                        },
                        {
                            "id": 113322,
                            "name": "Canby",
                            "latitude": "45.26290000",
                            "longitude": "-122.69259000"
                        },
                        {
                            "id": 113332,
                            "name": "Cannon Beach",
                            "latitude": "45.89177000",
                            "longitude": "-123.96153000"
                        },
                        {
                            "id": 113355,
                            "name": "Canyon City",
                            "latitude": "44.38960000",
                            "longitude": "-118.95023000"
                        },
                        {
                            "id": 113361,
                            "name": "Canyonville",
                            "latitude": "42.92734000",
                            "longitude": "-123.28117000"
                        },
                        {
                            "id": 113412,
                            "name": "Carlton",
                            "latitude": "45.29428000",
                            "longitude": "-123.17649000"
                        },
                        {
                            "id": 113516,
                            "name": "Cascade Locks",
                            "latitude": "45.66984000",
                            "longitude": "-121.89064000"
                        },
                        {
                            "id": 113586,
                            "name": "Cave Junction",
                            "latitude": "42.16289000",
                            "longitude": "-123.64812000"
                        },
                        {
                            "id": 113615,
                            "name": "Cedar Hills",
                            "latitude": "45.50484000",
                            "longitude": "-122.79843000"
                        },
                        {
                            "id": 113618,
                            "name": "Cedar Mill",
                            "latitude": "45.52484000",
                            "longitude": "-122.81093000"
                        },
                        {
                            "id": 113680,
                            "name": "Central Point",
                            "latitude": "42.37596000",
                            "longitude": "-122.91643000"
                        },
                        {
                            "id": 113842,
                            "name": "Chenoweth",
                            "latitude": "45.62762000",
                            "longitude": "-121.24341000"
                        },
                        {
                            "id": 114090,
                            "name": "Clackamas",
                            "latitude": "45.40762000",
                            "longitude": "-122.57037000"
                        },
                        {
                            "id": 114091,
                            "name": "Clackamas County",
                            "latitude": "45.18816000",
                            "longitude": "-122.22094000"
                        },
                        {
                            "id": 114160,
                            "name": "Clatskanie",
                            "latitude": "46.10122000",
                            "longitude": "-123.20679000"
                        },
                        {
                            "id": 114161,
                            "name": "Clatsop County",
                            "latitude": "46.01747000",
                            "longitude": "-123.71677000"
                        },
                        {
                            "id": 114348,
                            "name": "Coburg",
                            "latitude": "44.13707000",
                            "longitude": "-123.06648000"
                        },
                        {
                            "id": 114476,
                            "name": "Columbia City",
                            "latitude": "45.89011000",
                            "longitude": "-122.80705000"
                        },
                        {
                            "id": 114483,
                            "name": "Columbia County",
                            "latitude": "45.94377000",
                            "longitude": "-123.08805000"
                        },
                        {
                            "id": 114549,
                            "name": "Condon",
                            "latitude": "45.23430000",
                            "longitude": "-120.18503000"
                        },
                        {
                            "id": 114610,
                            "name": "Coos Bay",
                            "latitude": "43.36650000",
                            "longitude": "-124.21789000"
                        },
                        {
                            "id": 114612,
                            "name": "Coos County",
                            "latitude": "43.18501000",
                            "longitude": "-124.09333000"
                        },
                        {
                            "id": 114623,
                            "name": "Coquille",
                            "latitude": "43.17705000",
                            "longitude": "-124.18761000"
                        },
                        {
                            "id": 114648,
                            "name": "Cornelius",
                            "latitude": "45.51984000",
                            "longitude": "-123.05983000"
                        },
                        {
                            "id": 114680,
                            "name": "Corvallis",
                            "latitude": "44.56457000",
                            "longitude": "-123.26204000"
                        },
                        {
                            "id": 114693,
                            "name": "Cottage Grove",
                            "latitude": "43.79762000",
                            "longitude": "-123.05952000"
                        },
                        {
                            "id": 114813,
                            "name": "Creswell",
                            "latitude": "43.91790000",
                            "longitude": "-123.02453000"
                        },
                        {
                            "id": 114836,
                            "name": "Crook County",
                            "latitude": "44.14219000",
                            "longitude": "-120.35658000"
                        },
                        {
                            "id": 114899,
                            "name": "Culp Creek",
                            "latitude": "43.70346000",
                            "longitude": "-122.84757000"
                        },
                        {
                            "id": 114903,
                            "name": "Culver",
                            "latitude": "44.52568000",
                            "longitude": "-121.21310000"
                        },
                        {
                            "id": 114928,
                            "name": "Curry County",
                            "latitude": "42.46661000",
                            "longitude": "-124.21534000"
                        },
                        {
                            "id": 114992,
                            "name": "Dallas",
                            "latitude": "44.91928000",
                            "longitude": "-123.31705000"
                        },
                        {
                            "id": 115010,
                            "name": "Damascus",
                            "latitude": "45.41762000",
                            "longitude": "-122.45898000"
                        },
                        {
                            "id": 115099,
                            "name": "Dayton",
                            "latitude": "45.22067000",
                            "longitude": "-123.07621000"
                        },
                        {
                            "id": 115257,
                            "name": "Depoe Bay",
                            "latitude": "44.80845000",
                            "longitude": "-124.06317000"
                        },
                        {
                            "id": 115277,
                            "name": "Deschutes County",
                            "latitude": "43.91488000",
                            "longitude": "-121.22789000"
                        },
                        {
                            "id": 115278,
                            "name": "Deschutes River Woods",
                            "latitude": "43.99151000",
                            "longitude": "-121.35836000"
                        },
                        {
                            "id": 115393,
                            "name": "Donald",
                            "latitude": "45.22234000",
                            "longitude": "-122.83926000"
                        },
                        {
                            "id": 115435,
                            "name": "Douglas County",
                            "latitude": "43.28536000",
                            "longitude": "-123.17942000"
                        },
                        {
                            "id": 115467,
                            "name": "Drain",
                            "latitude": "43.65873000",
                            "longitude": "-123.31870000"
                        },
                        {
                            "id": 115531,
                            "name": "Dundee",
                            "latitude": "45.27817000",
                            "longitude": "-123.01094000"
                        },
                        {
                            "id": 115536,
                            "name": "Dunes City",
                            "latitude": "43.88318000",
                            "longitude": "-124.11512000"
                        },
                        {
                            "id": 115574,
                            "name": "Durham",
                            "latitude": "45.40206000",
                            "longitude": "-122.75287000"
                        },
                        {
                            "id": 115609,
                            "name": "Eagle Point",
                            "latitude": "42.47263000",
                            "longitude": "-122.80282000"
                        },
                        {
                            "id": 115968,
                            "name": "Elgin",
                            "latitude": "45.56486000",
                            "longitude": "-117.91743000"
                        },
                        {
                            "id": 116150,
                            "name": "Enterprise",
                            "latitude": "45.42626000",
                            "longitude": "-117.27878000"
                        },
                        {
                            "id": 116202,
                            "name": "Estacada",
                            "latitude": "45.28957000",
                            "longitude": "-122.33370000"
                        },
                        {
                            "id": 116226,
                            "name": "Eugene",
                            "latitude": "44.05207000",
                            "longitude": "-123.08675000"
                        },
                        {
                            "id": 116356,
                            "name": "Fairview",
                            "latitude": "45.53845000",
                            "longitude": "-122.43398000"
                        },
                        {
                            "id": 116586,
                            "name": "Florence",
                            "latitude": "43.98262000",
                            "longitude": "-124.09984000"
                        },
                        {
                            "id": 116653,
                            "name": "Forest Grove",
                            "latitude": "45.51984000",
                            "longitude": "-123.11066000"
                        },
                        {
                            "id": 116772,
                            "name": "Fossil",
                            "latitude": "44.99819000",
                            "longitude": "-120.21614000"
                        },
                        {
                            "id": 116789,
                            "name": "Four Corners",
                            "latitude": "44.92790000",
                            "longitude": "-122.98371000"
                        },
                        {
                            "id": 116969,
                            "name": "Fruitdale",
                            "latitude": "42.42206000",
                            "longitude": "-123.30811000"
                        },
                        {
                            "id": 117066,
                            "name": "Garden Home-Whitford",
                            "latitude": "45.46400000",
                            "longitude": "-122.75891000"
                        },
                        {
                            "id": 117129,
                            "name": "Gearhart",
                            "latitude": "46.02427000",
                            "longitude": "-123.91125000"
                        },
                        {
                            "id": 117178,
                            "name": "Gervais",
                            "latitude": "45.10818000",
                            "longitude": "-122.89760000"
                        },
                        {
                            "id": 117218,
                            "name": "Gilliam County",
                            "latitude": "45.37806000",
                            "longitude": "-120.21087000"
                        },
                        {
                            "id": 117239,
                            "name": "Gladstone",
                            "latitude": "45.38068000",
                            "longitude": "-122.59481000"
                        },
                        {
                            "id": 117320,
                            "name": "Glide",
                            "latitude": "43.30151000",
                            "longitude": "-123.10118000"
                        },
                        {
                            "id": 117340,
                            "name": "Gold Beach",
                            "latitude": "42.40733000",
                            "longitude": "-124.42177000"
                        },
                        {
                            "id": 117343,
                            "name": "Gold Hill",
                            "latitude": "42.43179000",
                            "longitude": "-123.05060000"
                        },
                        {
                            "id": 117474,
                            "name": "Grand Ronde",
                            "latitude": "45.06011000",
                            "longitude": "-123.60928000"
                        },
                        {
                            "id": 117520,
                            "name": "Grant County",
                            "latitude": "44.49125000",
                            "longitude": "-119.00738000"
                        },
                        {
                            "id": 117528,
                            "name": "Grants Pass",
                            "latitude": "42.43933000",
                            "longitude": "-123.33067000"
                        },
                        {
                            "id": 117589,
                            "name": "Green",
                            "latitude": "43.16039000",
                            "longitude": "-123.36785000"
                        },
                        {
                            "id": 117722,
                            "name": "Gresham",
                            "latitude": "45.49818000",
                            "longitude": "-122.43148000"
                        },
                        {
                            "id": 117983,
                            "name": "Happy Valley",
                            "latitude": "45.44679000",
                            "longitude": "-122.53037000"
                        },
                        {
                            "id": 117987,
                            "name": "Harbor",
                            "latitude": "42.05317000",
                            "longitude": "-124.26759000"
                        },
                        {
                            "id": 118030,
                            "name": "Harney County",
                            "latitude": "43.06402000",
                            "longitude": "-118.96787000"
                        },
                        {
                            "id": 118053,
                            "name": "Harrisburg",
                            "latitude": "44.27401000",
                            "longitude": "-123.17065000"
                        },
                        {
                            "id": 118183,
                            "name": "Hayesville",
                            "latitude": "44.98595000",
                            "longitude": "-122.98287000"
                        },
                        {
                            "id": 118310,
                            "name": "Heppner",
                            "latitude": "45.35318000",
                            "longitude": "-119.55780000"
                        },
                        {
                            "id": 118327,
                            "name": "Hermiston",
                            "latitude": "45.84041000",
                            "longitude": "-119.28946000"
                        },
                        {
                            "id": 118452,
                            "name": "Hillsboro",
                            "latitude": "45.52289000",
                            "longitude": "-122.98983000"
                        },
                        {
                            "id": 118480,
                            "name": "Hines",
                            "latitude": "43.56404000",
                            "longitude": "-119.08105000"
                        },
                        {
                            "id": 118626,
                            "name": "Hood River",
                            "latitude": "45.70540000",
                            "longitude": "-121.52146000"
                        },
                        {
                            "id": 118627,
                            "name": "Hood River County",
                            "latitude": "45.51911000",
                            "longitude": "-121.65111000"
                        },
                        {
                            "id": 118731,
                            "name": "Hubbard",
                            "latitude": "45.18234000",
                            "longitude": "-122.80787000"
                        },
                        {
                            "id": 118897,
                            "name": "Independence",
                            "latitude": "44.85123000",
                            "longitude": "-123.18677000"
                        },
                        {
                            "id": 119002,
                            "name": "Irrigon",
                            "latitude": "45.89569000",
                            "longitude": "-119.49141000"
                        },
                        {
                            "id": 119023,
                            "name": "Island City",
                            "latitude": "45.34097000",
                            "longitude": "-118.04466000"
                        },
                        {
                            "id": 119095,
                            "name": "Jackson County",
                            "latitude": "42.43215000",
                            "longitude": "-122.72843000"
                        },
                        {
                            "id": 119105,
                            "name": "Jacksonville",
                            "latitude": "42.31346000",
                            "longitude": "-122.96699000"
                        },
                        {
                            "id": 119172,
                            "name": "Jefferson",
                            "latitude": "44.71957000",
                            "longitude": "-123.01037000"
                        },
                        {
                            "id": 119197,
                            "name": "Jefferson County",
                            "latitude": "44.62941000",
                            "longitude": "-121.17541000"
                        },
                        {
                            "id": 119221,
                            "name": "Jennings Lodge",
                            "latitude": "45.39123000",
                            "longitude": "-122.61259000"
                        },
                        {
                            "id": 119251,
                            "name": "John Day",
                            "latitude": "44.41599000",
                            "longitude": "-118.95301000"
                        },
                        {
                            "id": 119318,
                            "name": "Joseph",
                            "latitude": "45.35432000",
                            "longitude": "-117.22961000"
                        },
                        {
                            "id": 119321,
                            "name": "Josephine County",
                            "latitude": "42.36546000",
                            "longitude": "-123.55558000"
                        },
                        {
                            "id": 119335,
                            "name": "Junction City",
                            "latitude": "44.21929000",
                            "longitude": "-123.20565000"
                        },
                        {
                            "id": 119426,
                            "name": "Keizer",
                            "latitude": "44.99012000",
                            "longitude": "-123.02621000"
                        },
                        {
                            "id": 119498,
                            "name": "Kenton",
                            "latitude": "45.58178000",
                            "longitude": "-122.68149000"
                        },
                        {
                            "id": 119580,
                            "name": "King City",
                            "latitude": "45.40262000",
                            "longitude": "-122.80399000"
                        },
                        {
                            "id": 119670,
                            "name": "Klamath County",
                            "latitude": "42.68634000",
                            "longitude": "-121.65013000"
                        },
                        {
                            "id": 119671,
                            "name": "Klamath Falls",
                            "latitude": "42.22487000",
                            "longitude": "-121.78167000"
                        },
                        {
                            "id": 119748,
                            "name": "La Grande",
                            "latitude": "45.32458000",
                            "longitude": "-118.08772000"
                        },
                        {
                            "id": 119772,
                            "name": "La Pine",
                            "latitude": "43.67040000",
                            "longitude": "-121.50364000"
                        },
                        {
                            "id": 119837,
                            "name": "Lafayette",
                            "latitude": "45.24428000",
                            "longitude": "-123.11483000"
                        },
                        {
                            "id": 119899,
                            "name": "Lake County",
                            "latitude": "42.79339000",
                            "longitude": "-120.38747000"
                        },
                        {
                            "id": 119955,
                            "name": "Lake Oswego",
                            "latitude": "45.42067000",
                            "longitude": "-122.67065000"
                        },
                        {
                            "id": 120034,
                            "name": "Lakeside",
                            "latitude": "43.57567000",
                            "longitude": "-124.17511000"
                        },
                        {
                            "id": 120041,
                            "name": "Lakeview",
                            "latitude": "42.18877000",
                            "longitude": "-120.34579000"
                        },
                        {
                            "id": 120106,
                            "name": "Lane County",
                            "latitude": "43.94155000",
                            "longitude": "-122.87655000"
                        },
                        {
                            "id": 120294,
                            "name": "Lebanon",
                            "latitude": "44.53651000",
                            "longitude": "-122.90703000"
                        },
                        {
                            "id": 120377,
                            "name": "Lents",
                            "latitude": "45.47984000",
                            "longitude": "-122.56731000"
                        },
                        {
                            "id": 120514,
                            "name": "Lincoln Beach",
                            "latitude": "44.85039000",
                            "longitude": "-124.04678000"
                        },
                        {
                            "id": 120515,
                            "name": "Lincoln City",
                            "latitude": "44.95816000",
                            "longitude": "-124.01789000"
                        },
                        {
                            "id": 120536,
                            "name": "Lincoln County",
                            "latitude": "44.64568000",
                            "longitude": "-123.90770000"
                        },
                        {
                            "id": 120584,
                            "name": "Linn County",
                            "latitude": "44.48857000",
                            "longitude": "-122.53419000"
                        },
                        {
                            "id": 120838,
                            "name": "Lowell",
                            "latitude": "43.91846000",
                            "longitude": "-122.78368000"
                        },
                        {
                            "id": 120944,
                            "name": "Lyons",
                            "latitude": "44.77457000",
                            "longitude": "-122.61509000"
                        },
                        {
                            "id": 121028,
                            "name": "Madras",
                            "latitude": "44.63345000",
                            "longitude": "-121.12949000"
                        },
                        {
                            "id": 121061,
                            "name": "Malheur County",
                            "latitude": "43.19341000",
                            "longitude": "-117.62307000"
                        },
                        {
                            "id": 121262,
                            "name": "Marion County",
                            "latitude": "44.90319000",
                            "longitude": "-122.58473000"
                        },
                        {
                            "id": 121541,
                            "name": "McMinnville",
                            "latitude": "45.21012000",
                            "longitude": "-123.19872000"
                        },
                        {
                            "id": 121596,
                            "name": "Medford",
                            "latitude": "42.32652000",
                            "longitude": "-122.87559000"
                        },
                        {
                            "id": 121701,
                            "name": "Merlin",
                            "latitude": "42.51734000",
                            "longitude": "-123.41979000"
                        },
                        {
                            "id": 121739,
                            "name": "Metzger",
                            "latitude": "45.44651000",
                            "longitude": "-122.75899000"
                        },
                        {
                            "id": 121862,
                            "name": "Mill City",
                            "latitude": "44.75401000",
                            "longitude": "-122.47814000"
                        },
                        {
                            "id": 121887,
                            "name": "Millersburg",
                            "latitude": "44.68095000",
                            "longitude": "-123.06148000"
                        },
                        {
                            "id": 121922,
                            "name": "Milton-Freewater",
                            "latitude": "45.93263000",
                            "longitude": "-118.38774000"
                        },
                        {
                            "id": 121925,
                            "name": "Milwaukie",
                            "latitude": "45.44623000",
                            "longitude": "-122.63926000"
                        },
                        {
                            "id": 121981,
                            "name": "Mission",
                            "latitude": "45.67041000",
                            "longitude": "-118.68359000"
                        },
                        {
                            "id": 122026,
                            "name": "Molalla",
                            "latitude": "45.14734000",
                            "longitude": "-122.57703000"
                        },
                        {
                            "id": 122044,
                            "name": "Monmouth",
                            "latitude": "44.84845000",
                            "longitude": "-123.23399000"
                        },
                        {
                            "id": 122259,
                            "name": "Moro",
                            "latitude": "45.48401000",
                            "longitude": "-120.73117000"
                        },
                        {
                            "id": 122290,
                            "name": "Morrow County",
                            "latitude": "45.41883000",
                            "longitude": "-119.58430000"
                        },
                        {
                            "id": 122332,
                            "name": "Mount Angel",
                            "latitude": "45.06790000",
                            "longitude": "-122.80009000"
                        },
                        {
                            "id": 122352,
                            "name": "Mount Hood Village",
                            "latitude": "45.35540000",
                            "longitude": "-121.98064000"
                        },
                        {
                            "id": 122458,
                            "name": "Mulino",
                            "latitude": "45.22151000",
                            "longitude": "-122.58203000"
                        },
                        {
                            "id": 122463,
                            "name": "Multnomah County",
                            "latitude": "45.54687000",
                            "longitude": "-122.41534000"
                        },
                        {
                            "id": 122521,
                            "name": "Myrtle Creek",
                            "latitude": "43.02012000",
                            "longitude": "-123.29312000"
                        },
                        {
                            "id": 122524,
                            "name": "Myrtle Point",
                            "latitude": "43.06483000",
                            "longitude": "-124.13899000"
                        },
                        {
                            "id": 122728,
                            "name": "New Hope",
                            "latitude": "42.36234000",
                            "longitude": "-123.36784000"
                        },
                        {
                            "id": 122808,
                            "name": "Newberg",
                            "latitude": "45.30012000",
                            "longitude": "-122.97316000"
                        },
                        {
                            "id": 122851,
                            "name": "Newport",
                            "latitude": "44.63678000",
                            "longitude": "-124.05345000"
                        },
                        {
                            "id": 122984,
                            "name": "North Bend",
                            "latitude": "43.40650000",
                            "longitude": "-124.22428000"
                        },
                        {
                            "id": 123081,
                            "name": "North Plains",
                            "latitude": "45.59706000",
                            "longitude": "-122.99344000"
                        },
                        {
                            "id": 123086,
                            "name": "North Portland",
                            "latitude": "45.61039000",
                            "longitude": "-122.70343000"
                        },
                        {
                            "id": 123223,
                            "name": "Nyssa",
                            "latitude": "43.87683000",
                            "longitude": "-116.99488000"
                        },
                        {
                            "id": 123240,
                            "name": "Oak Grove",
                            "latitude": "45.41679000",
                            "longitude": "-122.64009000"
                        },
                        {
                            "id": 123249,
                            "name": "Oak Hills",
                            "latitude": "45.54123000",
                            "longitude": "-122.84121000"
                        },
                        {
                            "id": 123304,
                            "name": "Oakridge",
                            "latitude": "43.74651000",
                            "longitude": "-122.46172000"
                        },
                        {
                            "id": 123314,
                            "name": "Oatfield",
                            "latitude": "45.41418000",
                            "longitude": "-122.60007000"
                        },
                        {
                            "id": 123354,
                            "name": "Odell",
                            "latitude": "45.62706000",
                            "longitude": "-121.54313000"
                        },
                        {
                            "id": 123487,
                            "name": "Ontario",
                            "latitude": "44.02655000",
                            "longitude": "-116.96294000"
                        },
                        {
                            "id": 123548,
                            "name": "Oregon City",
                            "latitude": "45.35734000",
                            "longitude": "-122.60676000"
                        },
                        {
                            "id": 123708,
                            "name": "Pacific City",
                            "latitude": "45.20233000",
                            "longitude": "-123.96289000"
                        },
                        {
                            "id": 124018,
                            "name": "Pendleton",
                            "latitude": "45.67207000",
                            "longitude": "-118.78860000"
                        },
                        {
                            "id": 124144,
                            "name": "Philomath",
                            "latitude": "44.54012000",
                            "longitude": "-123.36760000"
                        },
                        {
                            "id": 124149,
                            "name": "Phoenix",
                            "latitude": "42.27541000",
                            "longitude": "-122.81809000"
                        },
                        {
                            "id": 124202,
                            "name": "Pilot Rock",
                            "latitude": "45.48318000",
                            "longitude": "-118.82998000"
                        },
                        {
                            "id": 124451,
                            "name": "Polk County",
                            "latitude": "44.90348000",
                            "longitude": "-123.41337000"
                        },
                        {
                            "id": 124526,
                            "name": "Port Orford",
                            "latitude": "42.74566000",
                            "longitude": "-124.49733000"
                        },
                        {
                            "id": 124565,
                            "name": "Portland",
                            "latitude": "45.52345000",
                            "longitude": "-122.67621000"
                        },
                        {
                            "id": 124697,
                            "name": "Prineville",
                            "latitude": "44.29985000",
                            "longitude": "-120.83447000"
                        },
                        {
                            "id": 124826,
                            "name": "Rainier",
                            "latitude": "46.08900000",
                            "longitude": "-122.93594000"
                        },
                        {
                            "id": 124833,
                            "name": "Raleigh Hills",
                            "latitude": "45.48067000",
                            "longitude": "-122.76204000"
                        },
                        {
                            "id": 124978,
                            "name": "Redmond",
                            "latitude": "44.27262000",
                            "longitude": "-121.17392000"
                        },
                        {
                            "id": 124985,
                            "name": "Redwood",
                            "latitude": "42.42206000",
                            "longitude": "-123.38728000"
                        },
                        {
                            "id": 124995,
                            "name": "Reedsport",
                            "latitude": "43.70234000",
                            "longitude": "-124.09678000"
                        },
                        {
                            "id": 125113,
                            "name": "Riddle",
                            "latitude": "42.95095000",
                            "longitude": "-123.36423000"
                        },
                        {
                            "id": 125290,
                            "name": "Rockaway Beach",
                            "latitude": "45.61344000",
                            "longitude": "-123.94291000"
                        },
                        {
                            "id": 125294,
                            "name": "Rockcreek",
                            "latitude": "45.55012000",
                            "longitude": "-122.87705000"
                        },
                        {
                            "id": 125357,
                            "name": "Rogue River",
                            "latitude": "42.43595000",
                            "longitude": "-123.17200000"
                        },
                        {
                            "id": 125406,
                            "name": "Rose Lodge",
                            "latitude": "45.01039000",
                            "longitude": "-123.88039000"
                        },
                        {
                            "id": 125414,
                            "name": "Roseburg",
                            "latitude": "43.21650000",
                            "longitude": "-123.34174000"
                        },
                        {
                            "id": 125415,
                            "name": "Roseburg North",
                            "latitude": "43.26452000",
                            "longitude": "-123.30331000"
                        },
                        {
                            "id": 125649,
                            "name": "Saint Helens",
                            "latitude": "45.86400000",
                            "longitude": "-122.80649000"
                        },
                        {
                            "id": 125740,
                            "name": "Salem",
                            "latitude": "44.94290000",
                            "longitude": "-123.03510000"
                        },
                        {
                            "id": 125880,
                            "name": "Sandy",
                            "latitude": "45.39734000",
                            "longitude": "-122.26148000"
                        },
                        {
                            "id": 125996,
                            "name": "Scappoose",
                            "latitude": "45.75428000",
                            "longitude": "-122.87760000"
                        },
                        {
                            "id": 126100,
                            "name": "Seaside",
                            "latitude": "45.99316000",
                            "longitude": "-123.92264000"
                        },
                        {
                            "id": 126203,
                            "name": "Shady Cove",
                            "latitude": "42.61068000",
                            "longitude": "-122.81254000"
                        },
                        {
                            "id": 126322,
                            "name": "Sheridan",
                            "latitude": "45.09928000",
                            "longitude": "-123.39483000"
                        },
                        {
                            "id": 126335,
                            "name": "Sherman County",
                            "latitude": "45.40521000",
                            "longitude": "-120.68932000"
                        },
                        {
                            "id": 126342,
                            "name": "Sherwood",
                            "latitude": "45.35651000",
                            "longitude": "-122.84010000"
                        },
                        {
                            "id": 126416,
                            "name": "Siletz",
                            "latitude": "44.72178000",
                            "longitude": "-123.92011000"
                        },
                        {
                            "id": 126442,
                            "name": "Silverton",
                            "latitude": "45.00512000",
                            "longitude": "-122.78315000"
                        },
                        {
                            "id": 126468,
                            "name": "Sisters",
                            "latitude": "44.29095000",
                            "longitude": "-121.54921000"
                        },
                        {
                            "id": 126688,
                            "name": "South Lebanon",
                            "latitude": "44.50623000",
                            "longitude": "-122.90314000"
                        },
                        {
                            "id": 126907,
                            "name": "Springfield",
                            "latitude": "44.04624000",
                            "longitude": "-123.02203000"
                        },
                        {
                            "id": 126935,
                            "name": "Stafford",
                            "latitude": "45.35734000",
                            "longitude": "-122.72259000"
                        },
                        {
                            "id": 126952,
                            "name": "Stanfield",
                            "latitude": "45.78041000",
                            "longitude": "-119.21724000"
                        },
                        {
                            "id": 127002,
                            "name": "Stayton",
                            "latitude": "44.80068000",
                            "longitude": "-122.79453000"
                        },
                        {
                            "id": 127159,
                            "name": "Sublimity",
                            "latitude": "44.82957000",
                            "longitude": "-122.79453000"
                        },
                        {
                            "id": 127270,
                            "name": "Sunriver",
                            "latitude": "43.88401000",
                            "longitude": "-121.43864000"
                        },
                        {
                            "id": 127307,
                            "name": "Sutherlin",
                            "latitude": "43.39012000",
                            "longitude": "-123.31258000"
                        },
                        {
                            "id": 127337,
                            "name": "Sweet Home",
                            "latitude": "44.39762000",
                            "longitude": "-122.73620000"
                        },
                        {
                            "id": 127386,
                            "name": "Talent",
                            "latitude": "42.24568000",
                            "longitude": "-122.78865000"
                        },
                        {
                            "id": 127413,
                            "name": "Tangent",
                            "latitude": "44.54123000",
                            "longitude": "-123.10815000"
                        },
                        {
                            "id": 127524,
                            "name": "Terrebonne",
                            "latitude": "44.35290000",
                            "longitude": "-121.17781000"
                        },
                        {
                            "id": 127557,
                            "name": "The Dalles",
                            "latitude": "45.59456000",
                            "longitude": "-121.17868000"
                        },
                        {
                            "id": 127621,
                            "name": "Three Rivers",
                            "latitude": "43.82012000",
                            "longitude": "-121.46919000"
                        },
                        {
                            "id": 127643,
                            "name": "Tigard",
                            "latitude": "45.43123000",
                            "longitude": "-122.77149000"
                        },
                        {
                            "id": 127648,
                            "name": "Tillamook",
                            "latitude": "45.45640000",
                            "longitude": "-123.84553000"
                        },
                        {
                            "id": 127649,
                            "name": "Tillamook County",
                            "latitude": "45.45645000",
                            "longitude": "-123.75877000"
                        },
                        {
                            "id": 127700,
                            "name": "Toledo",
                            "latitude": "44.62151000",
                            "longitude": "-123.93845000"
                        },
                        {
                            "id": 127816,
                            "name": "Tri-City",
                            "latitude": "42.98456000",
                            "longitude": "-123.31173000"
                        },
                        {
                            "id": 127838,
                            "name": "Troutdale",
                            "latitude": "45.53929000",
                            "longitude": "-122.38731000"
                        },
                        {
                            "id": 127865,
                            "name": "Tualatin",
                            "latitude": "45.38401000",
                            "longitude": "-122.76399000"
                        },
                        {
                            "id": 127902,
                            "name": "Turner",
                            "latitude": "44.84318000",
                            "longitude": "-122.95287000"
                        },
                        {
                            "id": 127962,
                            "name": "Umatilla",
                            "latitude": "45.91735000",
                            "longitude": "-119.34252000"
                        },
                        {
                            "id": 127963,
                            "name": "Umatilla County",
                            "latitude": "45.59190000",
                            "longitude": "-118.73683000"
                        },
                        {
                            "id": 127978,
                            "name": "Union",
                            "latitude": "45.20847000",
                            "longitude": "-117.86521000"
                        },
                        {
                            "id": 128003,
                            "name": "Union County",
                            "latitude": "45.31035000",
                            "longitude": "-118.00900000"
                        },
                        {
                            "id": 128086,
                            "name": "Vale",
                            "latitude": "43.98211000",
                            "longitude": "-117.23823000"
                        },
                        {
                            "id": 128168,
                            "name": "Veneta",
                            "latitude": "44.04873000",
                            "longitude": "-123.35093000"
                        },
                        {
                            "id": 128197,
                            "name": "Vernonia",
                            "latitude": "45.85872000",
                            "longitude": "-123.19289000"
                        },
                        {
                            "id": 128376,
                            "name": "Waldport",
                            "latitude": "44.42679000",
                            "longitude": "-124.06873000"
                        },
                        {
                            "id": 128411,
                            "name": "Wallowa County",
                            "latitude": "45.57983000",
                            "longitude": "-117.18107000"
                        },
                        {
                            "id": 128471,
                            "name": "Warm Springs",
                            "latitude": "44.76345000",
                            "longitude": "-121.26616000"
                        },
                        {
                            "id": 128485,
                            "name": "Warren",
                            "latitude": "45.81900000",
                            "longitude": "-122.84899000"
                        },
                        {
                            "id": 128508,
                            "name": "Warrenton",
                            "latitude": "46.16510000",
                            "longitude": "-123.92376000"
                        },
                        {
                            "id": 128529,
                            "name": "Wasco County",
                            "latitude": "45.16005000",
                            "longitude": "-121.16815000"
                        },
                        {
                            "id": 128580,
                            "name": "Washington County",
                            "latitude": "45.56007000",
                            "longitude": "-123.09849000"
                        },
                        {
                            "id": 128889,
                            "name": "West Haven",
                            "latitude": "45.51762000",
                            "longitude": "-122.76954000"
                        },
                        {
                            "id": 128890,
                            "name": "West Haven-Sylvan",
                            "latitude": "45.51613000",
                            "longitude": "-122.76809000"
                        },
                        {
                            "id": 128919,
                            "name": "West Linn",
                            "latitude": "45.36568000",
                            "longitude": "-122.61231000"
                        },
                        {
                            "id": 128983,
                            "name": "West Slope",
                            "latitude": "45.49873000",
                            "longitude": "-122.76454000"
                        },
                        {
                            "id": 129119,
                            "name": "Wheeler County",
                            "latitude": "44.72606000",
                            "longitude": "-120.02746000"
                        },
                        {
                            "id": 129131,
                            "name": "White City",
                            "latitude": "42.43735000",
                            "longitude": "-122.85893000"
                        },
                        {
                            "id": 129256,
                            "name": "Willamina",
                            "latitude": "45.07873000",
                            "longitude": "-123.48594000"
                        },
                        {
                            "id": 129262,
                            "name": "Williams",
                            "latitude": "42.21873000",
                            "longitude": "-123.27394000"
                        },
                        {
                            "id": 129341,
                            "name": "Wilsonville",
                            "latitude": "45.29984000",
                            "longitude": "-122.77371000"
                        },
                        {
                            "id": 129433,
                            "name": "Winston",
                            "latitude": "43.12234000",
                            "longitude": "-123.41257000"
                        },
                        {
                            "id": 129490,
                            "name": "Wood Village",
                            "latitude": "45.53429000",
                            "longitude": "-122.41870000"
                        },
                        {
                            "id": 129504,
                            "name": "Woodburn",
                            "latitude": "45.14373000",
                            "longitude": "-122.85537000"
                        },
                        {
                            "id": 129657,
                            "name": "Yamhill",
                            "latitude": "45.34150000",
                            "longitude": "-123.18733000"
                        },
                        {
                            "id": 129658,
                            "name": "Yamhill County",
                            "latitude": "45.23260000",
                            "longitude": "-123.30815000"
                        },
                        {
                            "id": 129687,
                            "name": "Yoncalla",
                            "latitude": "43.59845000",
                            "longitude": "-123.28342000"
                        }
                    ]
                },
                {
                    "id": 1448,
                    "name": "Palmyra Atoll",
                    "state_code": "UM-95",
                    "cities": []
                },
                {
                    "id": 1422,
                    "name": "Pennsylvania",
                    "state_code": "PA",
                    "cities": [
                        {
                            "id": 110971,
                            "name": "Abbottstown",
                            "latitude": "39.88649000",
                            "longitude": "-76.98470000"
                        },
                        {
                            "id": 111019,
                            "name": "Adams County",
                            "latitude": "39.87145000",
                            "longitude": "-77.21789000"
                        },
                        {
                            "id": 111030,
                            "name": "Adamstown",
                            "latitude": "40.24120000",
                            "longitude": "-76.05633000"
                        },
                        {
                            "id": 111079,
                            "name": "Akron",
                            "latitude": "40.15676000",
                            "longitude": "-76.20217000"
                        },
                        {
                            "id": 111129,
                            "name": "Albion",
                            "latitude": "41.89061000",
                            "longitude": "-80.36645000"
                        },
                        {
                            "id": 111131,
                            "name": "Alburtis",
                            "latitude": "40.51093000",
                            "longitude": "-75.60297000"
                        },
                        {
                            "id": 111135,
                            "name": "Aldan",
                            "latitude": "39.92150000",
                            "longitude": "-75.28796000"
                        },
                        {
                            "id": 111174,
                            "name": "Aliquippa",
                            "latitude": "40.63673000",
                            "longitude": "-80.24006000"
                        },
                        {
                            "id": 111185,
                            "name": "Allegheny County",
                            "latitude": "40.46883000",
                            "longitude": "-79.98119000"
                        },
                        {
                            "id": 111186,
                            "name": "Alleghenyville",
                            "latitude": "40.23426000",
                            "longitude": "-75.98855000"
                        },
                        {
                            "id": 111199,
                            "name": "Allentown",
                            "latitude": "40.60843000",
                            "longitude": "-75.49018000"
                        },
                        {
                            "id": 111202,
                            "name": "Allison Park",
                            "latitude": "40.55951000",
                            "longitude": "-79.95867000"
                        },
                        {
                            "id": 111212,
                            "name": "Almedia",
                            "latitude": "41.01453000",
                            "longitude": "-76.38105000"
                        },
                        {
                            "id": 111248,
                            "name": "Altoona",
                            "latitude": "40.51868000",
                            "longitude": "-78.39474000"
                        },
                        {
                            "id": 111264,
                            "name": "Ambler",
                            "latitude": "40.15455000",
                            "longitude": "-75.22157000"
                        },
                        {
                            "id": 111267,
                            "name": "Ambridge",
                            "latitude": "40.58923000",
                            "longitude": "-80.22506000"
                        },
                        {
                            "id": 111291,
                            "name": "Amity Gardens",
                            "latitude": "40.27426000",
                            "longitude": "-75.73519000"
                        },
                        {
                            "id": 111307,
                            "name": "Ancient Oaks",
                            "latitude": "40.54732000",
                            "longitude": "-75.58935000"
                        },
                        {
                            "id": 111357,
                            "name": "Annville",
                            "latitude": "40.32954000",
                            "longitude": "-76.51524000"
                        },
                        {
                            "id": 111387,
                            "name": "Apollo",
                            "latitude": "40.58145000",
                            "longitude": "-79.56643000"
                        },
                        {
                            "id": 111427,
                            "name": "Archbald",
                            "latitude": "41.49480000",
                            "longitude": "-75.53685000"
                        },
                        {
                            "id": 111443,
                            "name": "Ardmore",
                            "latitude": "40.00678000",
                            "longitude": "-75.28546000"
                        },
                        {
                            "id": 111473,
                            "name": "Arlington Heights",
                            "latitude": "40.99009000",
                            "longitude": "-75.21629000"
                        },
                        {
                            "id": 111480,
                            "name": "Armstrong County",
                            "latitude": "40.81229000",
                            "longitude": "-79.46454000"
                        },
                        {
                            "id": 111487,
                            "name": "Arnold",
                            "latitude": "40.58007000",
                            "longitude": "-79.76672000"
                        },
                        {
                            "id": 111537,
                            "name": "Ashland",
                            "latitude": "40.78175000",
                            "longitude": "-76.34578000"
                        },
                        {
                            "id": 111544,
                            "name": "Ashley",
                            "latitude": "41.21036000",
                            "longitude": "-75.89659000"
                        },
                        {
                            "id": 111555,
                            "name": "Aspinwall",
                            "latitude": "40.49146000",
                            "longitude": "-79.90477000"
                        },
                        {
                            "id": 111571,
                            "name": "Atglen",
                            "latitude": "39.94927000",
                            "longitude": "-75.97356000"
                        },
                        {
                            "id": 111580,
                            "name": "Athens",
                            "latitude": "41.95730000",
                            "longitude": "-76.51800000"
                        },
                        {
                            "id": 111640,
                            "name": "Audubon",
                            "latitude": "40.12788000",
                            "longitude": "-75.43185000"
                        },
                        {
                            "id": 111674,
                            "name": "Avalon",
                            "latitude": "40.50090000",
                            "longitude": "-80.06756000"
                        },
                        {
                            "id": 111685,
                            "name": "Avis",
                            "latitude": "41.18479000",
                            "longitude": "-77.31386000"
                        },
                        {
                            "id": 111688,
                            "name": "Avoca",
                            "latitude": "41.33980000",
                            "longitude": "-75.73630000"
                        },
                        {
                            "id": 111694,
                            "name": "Avon",
                            "latitude": "40.34565000",
                            "longitude": "-76.38996000"
                        },
                        {
                            "id": 111699,
                            "name": "Avondale",
                            "latitude": "39.82344000",
                            "longitude": "-75.78327000"
                        },
                        {
                            "id": 111703,
                            "name": "Avonia",
                            "latitude": "42.04561000",
                            "longitude": "-80.26979000"
                        },
                        {
                            "id": 111718,
                            "name": "Back Mountain",
                            "latitude": "41.33591000",
                            "longitude": "-75.99631000"
                        },
                        {
                            "id": 111723,
                            "name": "Baden",
                            "latitude": "40.63507000",
                            "longitude": "-80.22812000"
                        },
                        {
                            "id": 111729,
                            "name": "Baidland",
                            "latitude": "40.19479000",
                            "longitude": "-79.97088000"
                        },
                        {
                            "id": 111734,
                            "name": "Bainbridge",
                            "latitude": "40.09093000",
                            "longitude": "-76.66747000"
                        },
                        {
                            "id": 111745,
                            "name": "Bakerstown",
                            "latitude": "40.65090000",
                            "longitude": "-79.93644000"
                        },
                        {
                            "id": 111748,
                            "name": "Bala-Cynwyd",
                            "latitude": "40.00761000",
                            "longitude": "-75.23407000"
                        },
                        {
                            "id": 111757,
                            "name": "Baldwin",
                            "latitude": "40.33813000",
                            "longitude": "-79.97894000"
                        },
                        {
                            "id": 111777,
                            "name": "Bally",
                            "latitude": "40.40232000",
                            "longitude": "-75.58713000"
                        },
                        {
                            "id": 111793,
                            "name": "Bangor",
                            "latitude": "40.86565000",
                            "longitude": "-75.20657000"
                        },
                        {
                            "id": 111828,
                            "name": "Barnesboro",
                            "latitude": "40.66257000",
                            "longitude": "-78.78003000"
                        },
                        {
                            "id": 111899,
                            "name": "Bath",
                            "latitude": "40.72565000",
                            "longitude": "-75.39407000"
                        },
                        {
                            "id": 111978,
                            "name": "Bear Rocks",
                            "latitude": "40.12285000",
                            "longitude": "-79.46170000"
                        },
                        {
                            "id": 111993,
                            "name": "Beaver",
                            "latitude": "40.69534000",
                            "longitude": "-80.30478000"
                        },
                        {
                            "id": 111997,
                            "name": "Beaver County",
                            "latitude": "40.68226000",
                            "longitude": "-80.34929000"
                        },
                        {
                            "id": 112004,
                            "name": "Beaver Falls",
                            "latitude": "40.75201000",
                            "longitude": "-80.31923000"
                        },
                        {
                            "id": 112006,
                            "name": "Beaverdale",
                            "latitude": "40.32202000",
                            "longitude": "-78.69696000"
                        },
                        {
                            "id": 112026,
                            "name": "Bedford",
                            "latitude": "40.01869000",
                            "longitude": "-78.50391000"
                        },
                        {
                            "id": 112029,
                            "name": "Bedford County",
                            "latitude": "40.00656000",
                            "longitude": "-78.49032000"
                        },
                        {
                            "id": 112032,
                            "name": "Bedminster",
                            "latitude": "40.42594000",
                            "longitude": "-75.17906000"
                        },
                        {
                            "id": 112038,
                            "name": "Beech Mountain Lakes",
                            "latitude": "41.04158000",
                            "longitude": "-75.93545000"
                        },
                        {
                            "id": 112057,
                            "name": "Belfast",
                            "latitude": "40.78065000",
                            "longitude": "-75.27796000"
                        },
                        {
                            "id": 112067,
                            "name": "Bell Acres",
                            "latitude": "40.59007000",
                            "longitude": "-80.16645000"
                        },
                        {
                            "id": 112093,
                            "name": "Belle Vernon",
                            "latitude": "40.12507000",
                            "longitude": "-79.86644000"
                        },
                        {
                            "id": 112099,
                            "name": "Bellefonte",
                            "latitude": "40.91339000",
                            "longitude": "-77.77833000"
                        },
                        {
                            "id": 112107,
                            "name": "Belleville",
                            "latitude": "40.60507000",
                            "longitude": "-77.72555000"
                        },
                        {
                            "id": 112114,
                            "name": "Bellevue",
                            "latitude": "40.49396000",
                            "longitude": "-80.05172000"
                        },
                        {
                            "id": 112132,
                            "name": "Bellwood",
                            "latitude": "40.60340000",
                            "longitude": "-78.32474000"
                        },
                        {
                            "id": 112141,
                            "name": "Belmont",
                            "latitude": "40.28730000",
                            "longitude": "-78.88947000"
                        },
                        {
                            "id": 112161,
                            "name": "Ben Avon",
                            "latitude": "40.50812000",
                            "longitude": "-80.08311000"
                        },
                        {
                            "id": 112190,
                            "name": "Bentleyville",
                            "latitude": "40.11674000",
                            "longitude": "-80.00839000"
                        },
                        {
                            "id": 112232,
                            "name": "Berks County",
                            "latitude": "40.41630000",
                            "longitude": "-75.92600000"
                        },
                        {
                            "id": 112236,
                            "name": "Berlin",
                            "latitude": "39.92064000",
                            "longitude": "-78.95780000"
                        },
                        {
                            "id": 112263,
                            "name": "Berwick",
                            "latitude": "41.05453000",
                            "longitude": "-76.23327000"
                        },
                        {
                            "id": 112265,
                            "name": "Berwyn",
                            "latitude": "40.04483000",
                            "longitude": "-75.43881000"
                        },
                        {
                            "id": 112269,
                            "name": "Bessemer",
                            "latitude": "40.97478000",
                            "longitude": "-80.49368000"
                        },
                        {
                            "id": 112287,
                            "name": "Bethel Park",
                            "latitude": "40.32757000",
                            "longitude": "-80.03950000"
                        },
                        {
                            "id": 112291,
                            "name": "Bethlehem",
                            "latitude": "40.62593000",
                            "longitude": "-75.37046000"
                        },
                        {
                            "id": 112314,
                            "name": "Big Bass Lake",
                            "latitude": "41.25383000",
                            "longitude": "-75.47644000"
                        },
                        {
                            "id": 112317,
                            "name": "Big Beaver",
                            "latitude": "40.82451000",
                            "longitude": "-80.36284000"
                        },
                        {
                            "id": 112341,
                            "name": "Biglerville",
                            "latitude": "39.93037000",
                            "longitude": "-77.24804000"
                        },
                        {
                            "id": 112356,
                            "name": "Birchwood Lakes",
                            "latitude": "41.25454000",
                            "longitude": "-74.91850000"
                        },
                        {
                            "id": 112357,
                            "name": "Birdsboro",
                            "latitude": "40.26454000",
                            "longitude": "-75.80409000"
                        },
                        {
                            "id": 112379,
                            "name": "Black Lick",
                            "latitude": "40.47250000",
                            "longitude": "-79.18688000"
                        },
                        {
                            "id": 112411,
                            "name": "Blair County",
                            "latitude": "40.48100000",
                            "longitude": "-78.34860000"
                        },
                        {
                            "id": 112413,
                            "name": "Blairsville",
                            "latitude": "40.43118000",
                            "longitude": "-79.26087000"
                        },
                        {
                            "id": 112415,
                            "name": "Blakely",
                            "latitude": "41.48091000",
                            "longitude": "-75.59463000"
                        },
                        {
                            "id": 112423,
                            "name": "Blandon",
                            "latitude": "40.44120000",
                            "longitude": "-75.88687000"
                        },
                        {
                            "id": 112426,
                            "name": "Blawnox",
                            "latitude": "40.49340000",
                            "longitude": "-79.86061000"
                        },
                        {
                            "id": 112439,
                            "name": "Bloomfield",
                            "latitude": "40.46090000",
                            "longitude": "-79.95089000"
                        },
                        {
                            "id": 112454,
                            "name": "Bloomsburg",
                            "latitude": "41.00370000",
                            "longitude": "-76.45495000"
                        },
                        {
                            "id": 112455,
                            "name": "Blossburg",
                            "latitude": "41.67952000",
                            "longitude": "-77.06386000"
                        },
                        {
                            "id": 112463,
                            "name": "Blue Ball",
                            "latitude": "40.11871000",
                            "longitude": "-76.04717000"
                        },
                        {
                            "id": 112464,
                            "name": "Blue Bell",
                            "latitude": "40.15233000",
                            "longitude": "-75.26629000"
                        },
                        {
                            "id": 112488,
                            "name": "Boalsburg",
                            "latitude": "40.77562000",
                            "longitude": "-77.79250000"
                        },
                        {
                            "id": 112507,
                            "name": "Boiling Springs",
                            "latitude": "40.14981000",
                            "longitude": "-77.12831000"
                        },
                        {
                            "id": 112541,
                            "name": "Bonneauville",
                            "latitude": "39.81204000",
                            "longitude": "-77.13721000"
                        },
                        {
                            "id": 112574,
                            "name": "Boothwyn",
                            "latitude": "39.83011000",
                            "longitude": "-75.44158000"
                        },
                        {
                            "id": 112592,
                            "name": "Boswell",
                            "latitude": "40.16147000",
                            "longitude": "-79.02892000"
                        },
                        {
                            "id": 112634,
                            "name": "Bowmansville",
                            "latitude": "40.19676000",
                            "longitude": "-76.01744000"
                        },
                        {
                            "id": 112645,
                            "name": "Boyertown",
                            "latitude": "40.33371000",
                            "longitude": "-75.63741000"
                        },
                        {
                            "id": 112656,
                            "name": "Brackenridge",
                            "latitude": "40.60812000",
                            "longitude": "-79.74116000"
                        },
                        {
                            "id": 112659,
                            "name": "Braddock",
                            "latitude": "40.40340000",
                            "longitude": "-79.86838000"
                        },
                        {
                            "id": 112661,
                            "name": "Braddock Hills",
                            "latitude": "40.41729000",
                            "longitude": "-79.86505000"
                        },
                        {
                            "id": 112666,
                            "name": "Bradford",
                            "latitude": "41.95590000",
                            "longitude": "-78.64392000"
                        },
                        {
                            "id": 112669,
                            "name": "Bradford County",
                            "latitude": "41.78867000",
                            "longitude": "-76.51545000"
                        },
                        {
                            "id": 112670,
                            "name": "Bradford Woods",
                            "latitude": "40.63757000",
                            "longitude": "-80.08172000"
                        },
                        {
                            "id": 112719,
                            "name": "Breinigsville",
                            "latitude": "40.53676000",
                            "longitude": "-75.63130000"
                        },
                        {
                            "id": 112732,
                            "name": "Brentwood",
                            "latitude": "40.37063000",
                            "longitude": "-79.97477000"
                        },
                        {
                            "id": 112735,
                            "name": "Bressler",
                            "latitude": "40.22953000",
                            "longitude": "-76.81997000"
                        },
                        {
                            "id": 112754,
                            "name": "Brickerville",
                            "latitude": "40.22593000",
                            "longitude": "-76.30246000"
                        },
                        {
                            "id": 112764,
                            "name": "Bridgeport",
                            "latitude": "40.10511000",
                            "longitude": "-75.34518000"
                        },
                        {
                            "id": 112773,
                            "name": "Bridgeville",
                            "latitude": "40.35618000",
                            "longitude": "-80.11006000"
                        },
                        {
                            "id": 112808,
                            "name": "Bristol",
                            "latitude": "40.10067000",
                            "longitude": "-74.85183000"
                        },
                        {
                            "id": 112818,
                            "name": "Brittany Farms-Highlands",
                            "latitude": "40.26901000",
                            "longitude": "-75.21401000"
                        },
                        {
                            "id": 112835,
                            "name": "Brockway",
                            "latitude": "41.24923000",
                            "longitude": "-78.79947000"
                        },
                        {
                            "id": 112839,
                            "name": "Brodheadsville",
                            "latitude": "40.92454000",
                            "longitude": "-75.39379000"
                        },
                        {
                            "id": 112858,
                            "name": "Brookhaven",
                            "latitude": "39.86928000",
                            "longitude": "-75.38241000"
                        },
                        {
                            "id": 112896,
                            "name": "Brookville",
                            "latitude": "41.16117000",
                            "longitude": "-79.08309000"
                        },
                        {
                            "id": 112898,
                            "name": "Broomall",
                            "latitude": "39.98150000",
                            "longitude": "-75.35658000"
                        },
                        {
                            "id": 112927,
                            "name": "Brownstown",
                            "latitude": "40.12371000",
                            "longitude": "-76.21384000"
                        },
                        {
                            "id": 112934,
                            "name": "Brownsville",
                            "latitude": "40.02369000",
                            "longitude": "-79.88394000"
                        },
                        {
                            "id": 112936,
                            "name": "Browntown",
                            "latitude": "41.30980000",
                            "longitude": "-75.78742000"
                        },
                        {
                            "id": 112965,
                            "name": "Bryn Athyn",
                            "latitude": "40.13150000",
                            "longitude": "-75.06739000"
                        },
                        {
                            "id": 112966,
                            "name": "Bryn Mawr",
                            "latitude": "40.01983000",
                            "longitude": "-75.30463000"
                        },
                        {
                            "id": 112991,
                            "name": "Bucks County",
                            "latitude": "40.33694000",
                            "longitude": "-75.10687000"
                        },
                        {
                            "id": 113051,
                            "name": "Burgettstown",
                            "latitude": "40.38201000",
                            "longitude": "-80.39284000"
                        },
                        {
                            "id": 113085,
                            "name": "Burnham",
                            "latitude": "40.63868000",
                            "longitude": "-77.56861000"
                        },
                        {
                            "id": 113111,
                            "name": "Butler",
                            "latitude": "40.86118000",
                            "longitude": "-79.89533000"
                        },
                        {
                            "id": 113120,
                            "name": "Butler County",
                            "latitude": "40.91172000",
                            "longitude": "-79.91299000"
                        },
                        {
                            "id": 113217,
                            "name": "California",
                            "latitude": "40.06563000",
                            "longitude": "-79.89171000"
                        },
                        {
                            "id": 113228,
                            "name": "Caln",
                            "latitude": "39.99094000",
                            "longitude": "-75.78022000"
                        },
                        {
                            "id": 113229,
                            "name": "Calumet",
                            "latitude": "40.21090000",
                            "longitude": "-79.48532000"
                        },
                        {
                            "id": 113247,
                            "name": "Cambria County",
                            "latitude": "40.49529000",
                            "longitude": "-78.71370000"
                        },
                        {
                            "id": 113258,
                            "name": "Cambridge Springs",
                            "latitude": "41.80367000",
                            "longitude": "-80.05644000"
                        },
                        {
                            "id": 113278,
                            "name": "Cameron County",
                            "latitude": "41.43680000",
                            "longitude": "-78.20378000"
                        },
                        {
                            "id": 113287,
                            "name": "Camp Hill",
                            "latitude": "40.23981000",
                            "longitude": "-76.91997000"
                        },
                        {
                            "id": 113306,
                            "name": "Campbelltown",
                            "latitude": "40.27759000",
                            "longitude": "-76.58525000"
                        },
                        {
                            "id": 113336,
                            "name": "Canonsburg",
                            "latitude": "40.26257000",
                            "longitude": "-80.18728000"
                        },
                        {
                            "id": 113349,
                            "name": "Canton",
                            "latitude": "41.65646000",
                            "longitude": "-76.85329000"
                        },
                        {
                            "id": 113382,
                            "name": "Carbon County",
                            "latitude": "40.91818000",
                            "longitude": "-75.70882000"
                        },
                        {
                            "id": 113389,
                            "name": "Carbondale",
                            "latitude": "41.57369000",
                            "longitude": "-75.50185000"
                        },
                        {
                            "id": 113405,
                            "name": "Carlisle",
                            "latitude": "40.20148000",
                            "longitude": "-77.18887000"
                        },
                        {
                            "id": 113426,
                            "name": "Carnegie",
                            "latitude": "40.40868000",
                            "longitude": "-80.08339000"
                        },
                        {
                            "id": 113430,
                            "name": "Carnot-Moon",
                            "latitude": "40.51856000",
                            "longitude": "-80.21736000"
                        },
                        {
                            "id": 113462,
                            "name": "Carroll Valley",
                            "latitude": "39.74926000",
                            "longitude": "-77.38304000"
                        },
                        {
                            "id": 113541,
                            "name": "Castanea",
                            "latitude": "41.12479000",
                            "longitude": "-77.42970000"
                        },
                        {
                            "id": 113551,
                            "name": "Castle Shannon",
                            "latitude": "40.36479000",
                            "longitude": "-80.02228000"
                        },
                        {
                            "id": 113565,
                            "name": "Catasauqua",
                            "latitude": "40.65482000",
                            "longitude": "-75.47463000"
                        },
                        {
                            "id": 113568,
                            "name": "Catawissa",
                            "latitude": "40.95203000",
                            "longitude": "-76.45967000"
                        },
                        {
                            "id": 113599,
                            "name": "Cecil-Bishop",
                            "latitude": "40.31819000",
                            "longitude": "-80.19331000"
                        },
                        {
                            "id": 113633,
                            "name": "Cementon",
                            "latitude": "40.68926000",
                            "longitude": "-75.50768000"
                        },
                        {
                            "id": 113641,
                            "name": "Center City",
                            "latitude": "39.95120000",
                            "longitude": "-75.15923000"
                        },
                        {
                            "id": 113661,
                            "name": "Centerville",
                            "latitude": "40.04535000",
                            "longitude": "-79.97561000"
                        },
                        {
                            "id": 113670,
                            "name": "Central City",
                            "latitude": "40.11063000",
                            "longitude": "-78.80197000"
                        },
                        {
                            "id": 113689,
                            "name": "Centre County",
                            "latitude": "40.91934000",
                            "longitude": "-77.81995000"
                        },
                        {
                            "id": 113690,
                            "name": "Centre Hall",
                            "latitude": "40.84756000",
                            "longitude": "-77.68611000"
                        },
                        {
                            "id": 113704,
                            "name": "Cetronia",
                            "latitude": "40.58676000",
                            "longitude": "-75.52963000"
                        },
                        {
                            "id": 113712,
                            "name": "Chalfont",
                            "latitude": "40.28844000",
                            "longitude": "-75.20906000"
                        },
                        {
                            "id": 113722,
                            "name": "Chambersburg",
                            "latitude": "39.93759000",
                            "longitude": "-77.66110000"
                        },
                        {
                            "id": 113753,
                            "name": "Charleroi",
                            "latitude": "40.13785000",
                            "longitude": "-79.89810000"
                        },
                        {
                            "id": 113871,
                            "name": "Cherryville",
                            "latitude": "40.75398000",
                            "longitude": "-75.53852000"
                        },
                        {
                            "id": 113884,
                            "name": "Chester",
                            "latitude": "39.84967000",
                            "longitude": "-75.35707000"
                        },
                        {
                            "id": 113897,
                            "name": "Chester County",
                            "latitude": "39.97314000",
                            "longitude": "-75.74845000"
                        },
                        {
                            "id": 113898,
                            "name": "Chester Heights",
                            "latitude": "39.89011000",
                            "longitude": "-75.47548000"
                        },
                        {
                            "id": 113899,
                            "name": "Chester Springs",
                            "latitude": "40.09510000",
                            "longitude": "-75.61687000"
                        },
                        {
                            "id": 113900,
                            "name": "Chesterbrook",
                            "latitude": "40.07566000",
                            "longitude": "-75.45908000"
                        },
                        {
                            "id": 113913,
                            "name": "Cheswick",
                            "latitude": "40.54173000",
                            "longitude": "-79.79922000"
                        },
                        {
                            "id": 113922,
                            "name": "Chevy Chase Heights",
                            "latitude": "40.63673000",
                            "longitude": "-79.14420000"
                        },
                        {
                            "id": 113946,
                            "name": "Chicora",
                            "latitude": "40.94812000",
                            "longitude": "-79.74283000"
                        },
                        {
                            "id": 113965,
                            "name": "Chinchilla",
                            "latitude": "41.47508000",
                            "longitude": "-75.67713000"
                        },
                        {
                            "id": 113999,
                            "name": "Christiana",
                            "latitude": "39.95483000",
                            "longitude": "-75.99689000"
                        },
                        {
                            "id": 114008,
                            "name": "Church Hill",
                            "latitude": "40.68201000",
                            "longitude": "-77.59861000"
                        },
                        {
                            "id": 114011,
                            "name": "Churchill",
                            "latitude": "40.43840000",
                            "longitude": "-79.84310000"
                        },
                        {
                            "id": 114014,
                            "name": "Churchville",
                            "latitude": "40.18622000",
                            "longitude": "-75.01878000"
                        },
                        {
                            "id": 114096,
                            "name": "Clairton",
                            "latitude": "40.29229000",
                            "longitude": "-79.88171000"
                        },
                        {
                            "id": 114117,
                            "name": "Clarion",
                            "latitude": "41.21479000",
                            "longitude": "-79.38532000"
                        },
                        {
                            "id": 114118,
                            "name": "Clarion County",
                            "latitude": "41.19239000",
                            "longitude": "-79.42096000"
                        },
                        {
                            "id": 114141,
                            "name": "Clarks Green",
                            "latitude": "41.49286000",
                            "longitude": "-75.69964000"
                        },
                        {
                            "id": 114142,
                            "name": "Clarks Summit",
                            "latitude": "41.48869000",
                            "longitude": "-75.70852000"
                        },
                        {
                            "id": 114168,
                            "name": "Clay",
                            "latitude": "40.21843000",
                            "longitude": "-76.25551000"
                        },
                        {
                            "id": 114195,
                            "name": "Claysburg",
                            "latitude": "40.29674000",
                            "longitude": "-78.44974000"
                        },
                        {
                            "id": 114216,
                            "name": "Clearfield",
                            "latitude": "41.02728000",
                            "longitude": "-78.43919000"
                        },
                        {
                            "id": 114218,
                            "name": "Clearfield County",
                            "latitude": "41.00019000",
                            "longitude": "-78.47411000"
                        },
                        {
                            "id": 114236,
                            "name": "Cleona",
                            "latitude": "40.33731000",
                            "longitude": "-76.47552000"
                        },
                        {
                            "id": 114263,
                            "name": "Clifton Heights",
                            "latitude": "39.92928000",
                            "longitude": "-75.29630000"
                        },
                        {
                            "id": 114296,
                            "name": "Clinton County",
                            "latitude": "41.23405000",
                            "longitude": "-77.63811000"
                        },
                        {
                            "id": 114323,
                            "name": "Clymer",
                            "latitude": "40.66812000",
                            "longitude": "-79.01170000"
                        },
                        {
                            "id": 114334,
                            "name": "Coaldale",
                            "latitude": "40.82287000",
                            "longitude": "-75.90687000"
                        },
                        {
                            "id": 114341,
                            "name": "Coatesville",
                            "latitude": "39.98316000",
                            "longitude": "-75.82384000"
                        },
                        {
                            "id": 114353,
                            "name": "Cochranton",
                            "latitude": "41.52005000",
                            "longitude": "-80.04839000"
                        },
                        {
                            "id": 114420,
                            "name": "Collegeville",
                            "latitude": "40.18566000",
                            "longitude": "-75.45157000"
                        },
                        {
                            "id": 114427,
                            "name": "Collingdale",
                            "latitude": "39.91178000",
                            "longitude": "-75.27713000"
                        },
                        {
                            "id": 114432,
                            "name": "Collinsburg",
                            "latitude": "40.22424000",
                            "longitude": "-79.76838000"
                        },
                        {
                            "id": 114450,
                            "name": "Colonial Park",
                            "latitude": "40.30064000",
                            "longitude": "-76.80969000"
                        },
                        {
                            "id": 114453,
                            "name": "Colony Park",
                            "latitude": "40.34683000",
                            "longitude": "-75.98240000"
                        },
                        {
                            "id": 114473,
                            "name": "Columbia",
                            "latitude": "40.03371000",
                            "longitude": "-76.50441000"
                        },
                        {
                            "id": 114481,
                            "name": "Columbia County",
                            "latitude": "41.04865000",
                            "longitude": "-76.40515000"
                        },
                        {
                            "id": 114508,
                            "name": "Colwyn",
                            "latitude": "39.91234000",
                            "longitude": "-75.25379000"
                        },
                        {
                            "id": 114532,
                            "name": "Conashaugh Lakes",
                            "latitude": "41.30593000",
                            "longitude": "-74.98962000"
                        },
                        {
                            "id": 114554,
                            "name": "Conemaugh",
                            "latitude": "40.32674000",
                            "longitude": "-78.90808000"
                        },
                        {
                            "id": 114555,
                            "name": "Conestoga",
                            "latitude": "39.94066000",
                            "longitude": "-76.34635000"
                        },
                        {
                            "id": 114560,
                            "name": "Conneaut Lakeshore",
                            "latitude": "41.62711000",
                            "longitude": "-80.31008000"
                        },
                        {
                            "id": 114562,
                            "name": "Connellsville",
                            "latitude": "40.01785000",
                            "longitude": "-79.58948000"
                        },
                        {
                            "id": 114571,
                            "name": "Conshohocken",
                            "latitude": "40.07928000",
                            "longitude": "-75.30157000"
                        },
                        {
                            "id": 114586,
                            "name": "Conway",
                            "latitude": "40.65979000",
                            "longitude": "-80.23923000"
                        },
                        {
                            "id": 114590,
                            "name": "Conyngham",
                            "latitude": "40.99203000",
                            "longitude": "-76.05659000"
                        },
                        {
                            "id": 114604,
                            "name": "Coopersburg",
                            "latitude": "40.51149000",
                            "longitude": "-75.39046000"
                        },
                        {
                            "id": 114618,
                            "name": "Coplay",
                            "latitude": "40.67010000",
                            "longitude": "-75.49546000"
                        },
                        {
                            "id": 114630,
                            "name": "Coraopolis",
                            "latitude": "40.51840000",
                            "longitude": "-80.16672000"
                        },
                        {
                            "id": 114659,
                            "name": "Cornwall",
                            "latitude": "40.27370000",
                            "longitude": "-76.40607000"
                        },
                        {
                            "id": 114660,
                            "name": "Cornwells Heights",
                            "latitude": "40.07678000",
                            "longitude": "-74.94878000"
                        },
                        {
                            "id": 114669,
                            "name": "Corry",
                            "latitude": "41.92033000",
                            "longitude": "-79.64033000"
                        },
                        {
                            "id": 114709,
                            "name": "Coudersport",
                            "latitude": "41.77479000",
                            "longitude": "-78.02056000"
                        },
                        {
                            "id": 114754,
                            "name": "Crafton",
                            "latitude": "40.43507000",
                            "longitude": "-80.06617000"
                        },
                        {
                            "id": 114763,
                            "name": "Cranberry Township",
                            "latitude": "40.68496000",
                            "longitude": "-80.10714000"
                        },
                        {
                            "id": 114782,
                            "name": "Crawford County",
                            "latitude": "41.68470000",
                            "longitude": "-80.10628000"
                        },
                        {
                            "id": 114800,
                            "name": "Cresson",
                            "latitude": "40.45979000",
                            "longitude": "-78.59168000"
                        },
                        {
                            "id": 114801,
                            "name": "Cressona",
                            "latitude": "40.62676000",
                            "longitude": "-76.19272000"
                        },
                        {
                            "id": 114870,
                            "name": "Croydon",
                            "latitude": "40.08733000",
                            "longitude": "-74.90350000"
                        },
                        {
                            "id": 114919,
                            "name": "Cumberland County",
                            "latitude": "40.16363000",
                            "longitude": "-77.26555000"
                        },
                        {
                            "id": 114929,
                            "name": "Curtisville",
                            "latitude": "40.64229000",
                            "longitude": "-79.85089000"
                        },
                        {
                            "id": 114930,
                            "name": "Curwensville",
                            "latitude": "40.97561000",
                            "longitude": "-78.52502000"
                        },
                        {
                            "id": 114980,
                            "name": "Dale",
                            "latitude": "40.31285000",
                            "longitude": "-78.90419000"
                        },
                        {
                            "id": 114991,
                            "name": "Dallas",
                            "latitude": "41.33619000",
                            "longitude": "-75.96325000"
                        },
                        {
                            "id": 114999,
                            "name": "Dallastown",
                            "latitude": "39.89954000",
                            "longitude": "-76.64025000"
                        },
                        {
                            "id": 115003,
                            "name": "Dalton",
                            "latitude": "41.53424000",
                            "longitude": "-75.73603000"
                        },
                        {
                            "id": 115037,
                            "name": "Danville",
                            "latitude": "40.96342000",
                            "longitude": "-76.61273000"
                        },
                        {
                            "id": 115041,
                            "name": "Darby",
                            "latitude": "39.91845000",
                            "longitude": "-75.25907000"
                        },
                        {
                            "id": 115056,
                            "name": "Dauphin County",
                            "latitude": "40.41544000",
                            "longitude": "-76.77947000"
                        },
                        {
                            "id": 115066,
                            "name": "Davidsville",
                            "latitude": "40.22702000",
                            "longitude": "-78.93641000"
                        },
                        {
                            "id": 115208,
                            "name": "Delaware County",
                            "latitude": "39.91681000",
                            "longitude": "-75.39890000"
                        },
                        {
                            "id": 115219,
                            "name": "Delmont",
                            "latitude": "40.41312000",
                            "longitude": "-79.57032000"
                        },
                        {
                            "id": 115252,
                            "name": "Denver",
                            "latitude": "40.23315000",
                            "longitude": "-76.13717000"
                        },
                        {
                            "id": 115266,
                            "name": "Derry",
                            "latitude": "40.33396000",
                            "longitude": "-79.29976000"
                        },
                        {
                            "id": 115300,
                            "name": "Devon",
                            "latitude": "40.04928000",
                            "longitude": "-75.42908000"
                        },
                        {
                            "id": 115301,
                            "name": "Dewart",
                            "latitude": "41.10925000",
                            "longitude": "-76.87663000"
                        },
                        {
                            "id": 115332,
                            "name": "Dickson City",
                            "latitude": "41.47147000",
                            "longitude": "-75.60769000"
                        },
                        {
                            "id": 115346,
                            "name": "Dillsburg",
                            "latitude": "40.11093000",
                            "longitude": "-77.03498000"
                        },
                        {
                            "id": 115401,
                            "name": "Donora",
                            "latitude": "40.17340000",
                            "longitude": "-79.85755000"
                        },
                        {
                            "id": 115412,
                            "name": "Dormont",
                            "latitude": "40.39590000",
                            "longitude": "-80.03311000"
                        },
                        {
                            "id": 115413,
                            "name": "Dorneyville",
                            "latitude": "40.57510000",
                            "longitude": "-75.51963000"
                        },
                        {
                            "id": 115451,
                            "name": "Dover",
                            "latitude": "40.00176000",
                            "longitude": "-76.85025000"
                        },
                        {
                            "id": 115462,
                            "name": "Downingtown",
                            "latitude": "40.00650000",
                            "longitude": "-75.70327000"
                        },
                        {
                            "id": 115463,
                            "name": "Doylestown",
                            "latitude": "40.31011000",
                            "longitude": "-75.12989000"
                        },
                        {
                            "id": 115470,
                            "name": "Dravosburg",
                            "latitude": "40.35063000",
                            "longitude": "-79.88616000"
                        },
                        {
                            "id": 115472,
                            "name": "Dresher",
                            "latitude": "40.14094000",
                            "longitude": "-75.16684000"
                        },
                        {
                            "id": 115477,
                            "name": "Drexel Hill",
                            "latitude": "39.94706000",
                            "longitude": "-75.29213000"
                        },
                        {
                            "id": 115495,
                            "name": "Dublin",
                            "latitude": "40.37177000",
                            "longitude": "-75.20156000"
                        },
                        {
                            "id": 115487,
                            "name": "DuBois",
                            "latitude": "41.11923000",
                            "longitude": "-78.76003000"
                        },
                        {
                            "id": 115499,
                            "name": "Duboistown",
                            "latitude": "41.22258000",
                            "longitude": "-77.03691000"
                        },
                        {
                            "id": 115519,
                            "name": "Dunbar",
                            "latitude": "39.97785000",
                            "longitude": "-79.61448000"
                        },
                        {
                            "id": 115523,
                            "name": "Duncannon",
                            "latitude": "40.39814000",
                            "longitude": "-77.02303000"
                        },
                        {
                            "id": 115524,
                            "name": "Duncansville",
                            "latitude": "40.42341000",
                            "longitude": "-78.43390000"
                        },
                        {
                            "id": 115545,
                            "name": "Dunmore",
                            "latitude": "41.41980000",
                            "longitude": "-75.63241000"
                        },
                        {
                            "id": 115552,
                            "name": "Dunnstown",
                            "latitude": "41.14590000",
                            "longitude": "-77.42137000"
                        },
                        {
                            "id": 115558,
                            "name": "Dupont",
                            "latitude": "41.32508000",
                            "longitude": "-75.74547000"
                        },
                        {
                            "id": 115561,
                            "name": "Duquesne",
                            "latitude": "40.38146000",
                            "longitude": "-79.85977000"
                        },
                        {
                            "id": 115576,
                            "name": "Duryea",
                            "latitude": "41.34397000",
                            "longitude": "-75.73853000"
                        },
                        {
                            "id": 115614,
                            "name": "Eagleview",
                            "latitude": "40.05938000",
                            "longitude": "-75.68076000"
                        },
                        {
                            "id": 115615,
                            "name": "Eagleville",
                            "latitude": "40.15955000",
                            "longitude": "-75.40824000"
                        },
                        {
                            "id": 115620,
                            "name": "Earlston",
                            "latitude": "40.00619000",
                            "longitude": "-78.37001000"
                        },
                        {
                            "id": 115629,
                            "name": "East Bangor",
                            "latitude": "40.87954000",
                            "longitude": "-75.18379000"
                        },
                        {
                            "id": 115631,
                            "name": "East Berlin",
                            "latitude": "39.93760000",
                            "longitude": "-76.97859000"
                        },
                        {
                            "id": 115633,
                            "name": "East Berwick",
                            "latitude": "41.06203000",
                            "longitude": "-76.22243000"
                        },
                        {
                            "id": 115648,
                            "name": "East Conemaugh",
                            "latitude": "40.34868000",
                            "longitude": "-78.88364000"
                        },
                        {
                            "id": 115654,
                            "name": "East Earl",
                            "latitude": "40.11010000",
                            "longitude": "-76.03272000"
                        },
                        {
                            "id": 115673,
                            "name": "East Greenville",
                            "latitude": "40.40649000",
                            "longitude": "-75.50185000"
                        },
                        {
                            "id": 115702,
                            "name": "East Lansdowne",
                            "latitude": "39.94567000",
                            "longitude": "-75.26129000"
                        },
                        {
                            "id": 115709,
                            "name": "East McKeesport",
                            "latitude": "40.38312000",
                            "longitude": "-79.80644000"
                        },
                        {
                            "id": 115735,
                            "name": "East Petersburg",
                            "latitude": "40.10010000",
                            "longitude": "-76.35413000"
                        },
                        {
                            "id": 115736,
                            "name": "East Pittsburgh",
                            "latitude": "40.39562000",
                            "longitude": "-79.83866000"
                        },
                        {
                            "id": 115761,
                            "name": "East Stroudsburg",
                            "latitude": "40.99954000",
                            "longitude": "-75.18129000"
                        },
                        {
                            "id": 115767,
                            "name": "East Uniontown",
                            "latitude": "39.89980000",
                            "longitude": "-79.69782000"
                        },
                        {
                            "id": 115770,
                            "name": "East Washington",
                            "latitude": "40.17368000",
                            "longitude": "-80.23756000"
                        },
                        {
                            "id": 115775,
                            "name": "East York",
                            "latitude": "39.97371000",
                            "longitude": "-76.68636000"
                        },
                        {
                            "id": 115782,
                            "name": "Eastlawn Gardens",
                            "latitude": "40.75065000",
                            "longitude": "-75.29573000"
                        },
                        {
                            "id": 115789,
                            "name": "Easton",
                            "latitude": "40.68843000",
                            "longitude": "-75.22073000"
                        },
                        {
                            "id": 115812,
                            "name": "Ebensburg",
                            "latitude": "40.48507000",
                            "longitude": "-78.72474000"
                        },
                        {
                            "id": 115817,
                            "name": "Economy",
                            "latitude": "40.60007000",
                            "longitude": "-80.22478000"
                        },
                        {
                            "id": 115822,
                            "name": "Eddington",
                            "latitude": "40.08456000",
                            "longitude": "-74.94489000"
                        },
                        {
                            "id": 115825,
                            "name": "Eddystone",
                            "latitude": "39.86011000",
                            "longitude": "-75.34436000"
                        },
                        {
                            "id": 115862,
                            "name": "Edgewood",
                            "latitude": "40.43201000",
                            "longitude": "-79.88144000"
                        },
                        {
                            "id": 115865,
                            "name": "Edgeworth",
                            "latitude": "40.55118000",
                            "longitude": "-80.19284000"
                        },
                        {
                            "id": 115868,
                            "name": "Edinboro",
                            "latitude": "41.87422000",
                            "longitude": "-80.13172000"
                        },
                        {
                            "id": 115895,
                            "name": "Edwardsville",
                            "latitude": "41.26953000",
                            "longitude": "-75.91631000"
                        },
                        {
                            "id": 115900,
                            "name": "Effort",
                            "latitude": "40.93926000",
                            "longitude": "-75.43491000"
                        },
                        {
                            "id": 115905,
                            "name": "Egypt",
                            "latitude": "40.68010000",
                            "longitude": "-75.52991000"
                        },
                        {
                            "id": 115969,
                            "name": "Elim",
                            "latitude": "40.29785000",
                            "longitude": "-78.94253000"
                        },
                        {
                            "id": 115973,
                            "name": "Elizabeth",
                            "latitude": "40.26924000",
                            "longitude": "-79.88977000"
                        },
                        {
                            "id": 115981,
                            "name": "Elizabethtown",
                            "latitude": "40.15287000",
                            "longitude": "-76.60275000"
                        },
                        {
                            "id": 115982,
                            "name": "Elizabethville",
                            "latitude": "40.54897000",
                            "longitude": "-76.81192000"
                        },
                        {
                            "id": 115985,
                            "name": "Elk County",
                            "latitude": "41.42523000",
                            "longitude": "-78.64909000"
                        },
                        {
                            "id": 116007,
                            "name": "Elkland",
                            "latitude": "41.98618000",
                            "longitude": "-77.31081000"
                        },
                        {
                            "id": 116036,
                            "name": "Ellport",
                            "latitude": "40.86395000",
                            "longitude": "-80.25895000"
                        },
                        {
                            "id": 116041,
                            "name": "Ellwood City",
                            "latitude": "40.86173000",
                            "longitude": "-80.28645000"
                        },
                        {
                            "id": 116074,
                            "name": "Elverson",
                            "latitude": "40.15676000",
                            "longitude": "-75.83271000"
                        },
                        {
                            "id": 116087,
                            "name": "Elysburg",
                            "latitude": "40.86453000",
                            "longitude": "-76.55246000"
                        },
                        {
                            "id": 116091,
                            "name": "Emerald Lakes",
                            "latitude": "41.08842000",
                            "longitude": "-75.41963000"
                        },
                        {
                            "id": 116098,
                            "name": "Emigsville",
                            "latitude": "40.02176000",
                            "longitude": "-76.72802000"
                        },
                        {
                            "id": 116101,
                            "name": "Emmaus",
                            "latitude": "40.53954000",
                            "longitude": "-75.49685000"
                        },
                        {
                            "id": 116113,
                            "name": "Emporium",
                            "latitude": "41.51145000",
                            "longitude": "-78.23529000"
                        },
                        {
                            "id": 116114,
                            "name": "Emsworth",
                            "latitude": "40.51007000",
                            "longitude": "-80.09450000"
                        },
                        {
                            "id": 116137,
                            "name": "Enhaut",
                            "latitude": "40.23176000",
                            "longitude": "-76.82692000"
                        },
                        {
                            "id": 116140,
                            "name": "Enlow",
                            "latitude": "40.45423000",
                            "longitude": "-80.23311000"
                        },
                        {
                            "id": 116144,
                            "name": "Enola",
                            "latitude": "40.29009000",
                            "longitude": "-76.93386000"
                        },
                        {
                            "id": 116155,
                            "name": "Ephrata",
                            "latitude": "40.17982000",
                            "longitude": "-76.17884000"
                        },
                        {
                            "id": 116166,
                            "name": "Erie",
                            "latitude": "42.12922000",
                            "longitude": "-80.08506000"
                        },
                        {
                            "id": 116169,
                            "name": "Erie County",
                            "latitude": "42.11748000",
                            "longitude": "-80.09811000"
                        },
                        {
                            "id": 116190,
                            "name": "Espy",
                            "latitude": "41.00620000",
                            "longitude": "-76.40994000"
                        },
                        {
                            "id": 116216,
                            "name": "Etna",
                            "latitude": "40.50424000",
                            "longitude": "-79.94894000"
                        },
                        {
                            "id": 116247,
                            "name": "Evans City",
                            "latitude": "40.76923000",
                            "longitude": "-80.06284000"
                        },
                        {
                            "id": 116249,
                            "name": "Evansburg",
                            "latitude": "40.18094000",
                            "longitude": "-75.42907000"
                        },
                        {
                            "id": 116259,
                            "name": "Everett",
                            "latitude": "40.01147000",
                            "longitude": "-78.37335000"
                        },
                        {
                            "id": 116272,
                            "name": "Exeter",
                            "latitude": "41.32064000",
                            "longitude": "-75.81908000"
                        },
                        {
                            "id": 116277,
                            "name": "Exton",
                            "latitude": "40.02900000",
                            "longitude": "-75.62077000"
                        },
                        {
                            "id": 116280,
                            "name": "Factoryville",
                            "latitude": "41.56313000",
                            "longitude": "-75.78269000"
                        },
                        {
                            "id": 116296,
                            "name": "Fairchance",
                            "latitude": "39.82480000",
                            "longitude": "-79.75449000"
                        },
                        {
                            "id": 116300,
                            "name": "Fairdale",
                            "latitude": "39.88702000",
                            "longitude": "-79.96811000"
                        },
                        {
                            "id": 116327,
                            "name": "Fairhope",
                            "latitude": "40.11368000",
                            "longitude": "-79.83977000"
                        },
                        {
                            "id": 116332,
                            "name": "Fairless Hills",
                            "latitude": "40.17955000",
                            "longitude": "-74.85516000"
                        },
                        {
                            "id": 116354,
                            "name": "Fairview",
                            "latitude": "42.03145000",
                            "longitude": "-80.25534000"
                        },
                        {
                            "id": 116361,
                            "name": "Fairview-Ferndale",
                            "latitude": "40.78037000",
                            "longitude": "-76.57528000"
                        },
                        {
                            "id": 116383,
                            "name": "Falls Creek",
                            "latitude": "41.14506000",
                            "longitude": "-78.80447000"
                        },
                        {
                            "id": 116430,
                            "name": "Farrell",
                            "latitude": "41.21228000",
                            "longitude": "-80.49674000"
                        },
                        {
                            "id": 116437,
                            "name": "Faxon",
                            "latitude": "41.24841000",
                            "longitude": "-76.97719000"
                        },
                        {
                            "id": 116452,
                            "name": "Fayette County",
                            "latitude": "39.91990000",
                            "longitude": "-79.64737000"
                        },
                        {
                            "id": 116458,
                            "name": "Fayetteville",
                            "latitude": "39.91120000",
                            "longitude": "-77.54999000"
                        },
                        {
                            "id": 116462,
                            "name": "Feasterville",
                            "latitude": "40.14400000",
                            "longitude": "-75.00517000"
                        },
                        {
                            "id": 116468,
                            "name": "Fellsburg",
                            "latitude": "40.18313000",
                            "longitude": "-79.82421000"
                        },
                        {
                            "id": 116488,
                            "name": "Ferndale",
                            "latitude": "40.28896000",
                            "longitude": "-78.91475000"
                        },
                        {
                            "id": 116492,
                            "name": "Fernway",
                            "latitude": "40.69479000",
                            "longitude": "-80.13089000"
                        },
                        {
                            "id": 116541,
                            "name": "Fivepointville",
                            "latitude": "40.18287000",
                            "longitude": "-76.05106000"
                        },
                        {
                            "id": 116558,
                            "name": "Fleetwood",
                            "latitude": "40.45398000",
                            "longitude": "-75.81798000"
                        },
                        {
                            "id": 116563,
                            "name": "Flemington",
                            "latitude": "41.12646000",
                            "longitude": "-77.47165000"
                        },
                        {
                            "id": 116599,
                            "name": "Flourtown",
                            "latitude": "40.10344000",
                            "longitude": "-75.21240000"
                        },
                        {
                            "id": 116615,
                            "name": "Flying Hills",
                            "latitude": "40.27676000",
                            "longitude": "-75.91410000"
                        },
                        {
                            "id": 116618,
                            "name": "Folcroft",
                            "latitude": "39.89095000",
                            "longitude": "-75.28380000"
                        },
                        {
                            "id": 116625,
                            "name": "Folsom",
                            "latitude": "39.88983000",
                            "longitude": "-75.32519000"
                        },
                        {
                            "id": 116634,
                            "name": "Ford City",
                            "latitude": "40.77229000",
                            "longitude": "-79.52977000"
                        },
                        {
                            "id": 116649,
                            "name": "Forest City",
                            "latitude": "41.65147000",
                            "longitude": "-75.46657000"
                        },
                        {
                            "id": 116650,
                            "name": "Forest County",
                            "latitude": "41.51307000",
                            "longitude": "-79.23601000"
                        },
                        {
                            "id": 116659,
                            "name": "Forest Hills",
                            "latitude": "40.41979000",
                            "longitude": "-79.85005000"
                        },
                        {
                            "id": 116762,
                            "name": "Fort Washington",
                            "latitude": "40.14178000",
                            "longitude": "-75.20906000"
                        },
                        {
                            "id": 116770,
                            "name": "Forty Fort",
                            "latitude": "41.27897000",
                            "longitude": "-75.87825000"
                        },
                        {
                            "id": 116775,
                            "name": "Foster Brook",
                            "latitude": "41.97506000",
                            "longitude": "-78.61725000"
                        },
                        {
                            "id": 116781,
                            "name": "Fountain Hill",
                            "latitude": "40.60149000",
                            "longitude": "-75.39518000"
                        },
                        {
                            "id": 116799,
                            "name": "Fox Chapel",
                            "latitude": "40.51340000",
                            "longitude": "-79.87977000"
                        },
                        {
                            "id": 116800,
                            "name": "Fox Chase",
                            "latitude": "40.39557000",
                            "longitude": "-75.96216000"
                        },
                        {
                            "id": 116808,
                            "name": "Fox Run",
                            "latitude": "40.70229000",
                            "longitude": "-80.08284000"
                        },
                        {
                            "id": 116810,
                            "name": "Frackville",
                            "latitude": "40.78398000",
                            "longitude": "-76.23022000"
                        },
                        {
                            "id": 116840,
                            "name": "Franklin",
                            "latitude": "41.39784000",
                            "longitude": "-79.83144000"
                        },
                        {
                            "id": 116862,
                            "name": "Franklin County",
                            "latitude": "39.92742000",
                            "longitude": "-77.72127000"
                        },
                        {
                            "id": 116870,
                            "name": "Franklin Park",
                            "latitude": "40.58340000",
                            "longitude": "-80.08784000"
                        },
                        {
                            "id": 116892,
                            "name": "Fredericksburg",
                            "latitude": "40.44370000",
                            "longitude": "-76.42829000"
                        },
                        {
                            "id": 116902,
                            "name": "Freedom",
                            "latitude": "40.68562000",
                            "longitude": "-80.25173000"
                        },
                        {
                            "id": 116906,
                            "name": "Freeland",
                            "latitude": "41.01675000",
                            "longitude": "-75.89714000"
                        },
                        {
                            "id": 116909,
                            "name": "Freemansburg",
                            "latitude": "40.62649000",
                            "longitude": "-75.34574000"
                        },
                        {
                            "id": 116915,
                            "name": "Freeport",
                            "latitude": "40.67395000",
                            "longitude": "-79.68477000"
                        },
                        {
                            "id": 116945,
                            "name": "Friedens",
                            "latitude": "40.05008000",
                            "longitude": "-78.99836000"
                        },
                        {
                            "id": 116982,
                            "name": "Fullerton",
                            "latitude": "40.63176000",
                            "longitude": "-75.47324000"
                        },
                        {
                            "id": 116998,
                            "name": "Fulton County",
                            "latitude": "39.92534000",
                            "longitude": "-78.11268000"
                        },
                        {
                            "id": 117030,
                            "name": "Galeton",
                            "latitude": "41.73312000",
                            "longitude": "-77.64193000"
                        },
                        {
                            "id": 117038,
                            "name": "Gallitzin",
                            "latitude": "40.48229000",
                            "longitude": "-78.55168000"
                        },
                        {
                            "id": 117052,
                            "name": "Gap",
                            "latitude": "39.98732000",
                            "longitude": "-76.02051000"
                        },
                        {
                            "id": 117068,
                            "name": "Garden View",
                            "latitude": "41.25424000",
                            "longitude": "-77.04608000"
                        },
                        {
                            "id": 117117,
                            "name": "Gastonville",
                            "latitude": "40.25729000",
                            "longitude": "-79.99588000"
                        },
                        {
                            "id": 117132,
                            "name": "Geistown",
                            "latitude": "40.29091000",
                            "longitude": "-78.86891000"
                        },
                        {
                            "id": 117158,
                            "name": "Georgetown",
                            "latitude": "39.93760000",
                            "longitude": "-76.08329000"
                        },
                        {
                            "id": 117179,
                            "name": "Gettysburg",
                            "latitude": "39.83093000",
                            "longitude": "-77.23110000"
                        },
                        {
                            "id": 117192,
                            "name": "Gibsonia",
                            "latitude": "40.63007000",
                            "longitude": "-79.96950000"
                        },
                        {
                            "id": 117207,
                            "name": "Gilbertsville",
                            "latitude": "40.32010000",
                            "longitude": "-75.61018000"
                        },
                        {
                            "id": 117228,
                            "name": "Girard",
                            "latitude": "42.00033000",
                            "longitude": "-80.31812000"
                        },
                        {
                            "id": 117229,
                            "name": "Girardville",
                            "latitude": "40.79148000",
                            "longitude": "-76.28356000"
                        },
                        {
                            "id": 117254,
                            "name": "Glassport",
                            "latitude": "40.32479000",
                            "longitude": "-79.89227000"
                        },
                        {
                            "id": 117268,
                            "name": "Glen Lyon",
                            "latitude": "41.17508000",
                            "longitude": "-76.07465000"
                        },
                        {
                            "id": 117272,
                            "name": "Glen Rock",
                            "latitude": "39.79316000",
                            "longitude": "-76.73025000"
                        },
                        {
                            "id": 117301,
                            "name": "Glenolden",
                            "latitude": "39.90011000",
                            "longitude": "-75.28907000"
                        },
                        {
                            "id": 117306,
                            "name": "Glenshaw",
                            "latitude": "40.53285000",
                            "longitude": "-79.96755000"
                        },
                        {
                            "id": 117307,
                            "name": "Glenside",
                            "latitude": "40.10233000",
                            "longitude": "-75.15212000"
                        },
                        {
                            "id": 117344,
                            "name": "Gold Key Lake",
                            "latitude": "41.30593000",
                            "longitude": "-74.93850000"
                        },
                        {
                            "id": 117526,
                            "name": "Grantley",
                            "latitude": "39.94038000",
                            "longitude": "-76.72913000"
                        },
                        {
                            "id": 117608,
                            "name": "Green Tree",
                            "latitude": "40.41174000",
                            "longitude": "-80.04561000"
                        },
                        {
                            "id": 117625,
                            "name": "Greencastle",
                            "latitude": "39.79037000",
                            "longitude": "-77.72777000"
                        },
                        {
                            "id": 117643,
                            "name": "Greene County",
                            "latitude": "39.85380000",
                            "longitude": "-80.22287000"
                        },
                        {
                            "id": 117655,
                            "name": "Greenfields",
                            "latitude": "40.35990000",
                            "longitude": "-75.95199000"
                        },
                        {
                            "id": 117660,
                            "name": "Greenock",
                            "latitude": "40.31229000",
                            "longitude": "-79.80671000"
                        },
                        {
                            "id": 117672,
                            "name": "Greensburg",
                            "latitude": "40.30146000",
                            "longitude": "-79.53893000"
                        },
                        {
                            "id": 117694,
                            "name": "Greenville",
                            "latitude": "41.40450000",
                            "longitude": "-80.39118000"
                        },
                        {
                            "id": 117707,
                            "name": "Greenwood",
                            "latitude": "40.53590000",
                            "longitude": "-78.35751000"
                        },
                        {
                            "id": 117736,
                            "name": "Grill",
                            "latitude": "40.29870000",
                            "longitude": "-75.94049000"
                        },
                        {
                            "id": 117756,
                            "name": "Grove City",
                            "latitude": "41.15784000",
                            "longitude": "-80.08867000"
                        },
                        {
                            "id": 117785,
                            "name": "Guilford",
                            "latitude": "39.91541000",
                            "longitude": "-77.60105000"
                        },
                        {
                            "id": 117788,
                            "name": "Guilford Siding",
                            "latitude": "39.86537000",
                            "longitude": "-77.61249000"
                        },
                        {
                            "id": 117858,
                            "name": "Halfway House",
                            "latitude": "40.28204000",
                            "longitude": "-75.64324000"
                        },
                        {
                            "id": 117868,
                            "name": "Hallam",
                            "latitude": "40.00482000",
                            "longitude": "-76.60413000"
                        },
                        {
                            "id": 117874,
                            "name": "Hallstead",
                            "latitude": "41.96119000",
                            "longitude": "-75.74324000"
                        },
                        {
                            "id": 117885,
                            "name": "Hamburg",
                            "latitude": "40.55565000",
                            "longitude": "-75.98188000"
                        },
                        {
                            "id": 117968,
                            "name": "Hanover",
                            "latitude": "39.80066000",
                            "longitude": "-76.98304000"
                        },
                        {
                            "id": 118020,
                            "name": "Harleigh",
                            "latitude": "40.98064000",
                            "longitude": "-75.97131000"
                        },
                        {
                            "id": 118025,
                            "name": "Harleysville",
                            "latitude": "40.27955000",
                            "longitude": "-75.38712000"
                        },
                        {
                            "id": 118050,
                            "name": "Harrisburg",
                            "latitude": "40.27370000",
                            "longitude": "-76.88442000"
                        },
                        {
                            "id": 118116,
                            "name": "Harveys Lake",
                            "latitude": "41.38341000",
                            "longitude": "-76.02465000"
                        },
                        {
                            "id": 118130,
                            "name": "Hasson Heights",
                            "latitude": "41.44895000",
                            "longitude": "-79.67700000"
                        },
                        {
                            "id": 118134,
                            "name": "Hastings",
                            "latitude": "40.66507000",
                            "longitude": "-78.71225000"
                        },
                        {
                            "id": 118136,
                            "name": "Hatboro",
                            "latitude": "40.17428000",
                            "longitude": "-75.10684000"
                        },
                        {
                            "id": 118139,
                            "name": "Hatfield",
                            "latitude": "40.27983000",
                            "longitude": "-75.29934000"
                        },
                        {
                            "id": 118169,
                            "name": "Hawley",
                            "latitude": "41.47592000",
                            "longitude": "-75.18212000"
                        },
                        {
                            "id": 118213,
                            "name": "Hazleton",
                            "latitude": "40.95842000",
                            "longitude": "-75.97465000"
                        },
                        {
                            "id": 118239,
                            "name": "Hebron",
                            "latitude": "40.33898000",
                            "longitude": "-76.39940000"
                        },
                        {
                            "id": 118245,
                            "name": "Heidelberg",
                            "latitude": "40.39229000",
                            "longitude": "-80.09089000"
                        },
                        {
                            "id": 118259,
                            "name": "Hellertown",
                            "latitude": "40.57954000",
                            "longitude": "-75.34073000"
                        },
                        {
                            "id": 118266,
                            "name": "Hemlock Farms",
                            "latitude": "41.32676000",
                            "longitude": "-75.03656000"
                        },
                        {
                            "id": 118329,
                            "name": "Hermitage",
                            "latitude": "41.23339000",
                            "longitude": "-80.44868000"
                        },
                        {
                            "id": 118341,
                            "name": "Hershey",
                            "latitude": "40.28592000",
                            "longitude": "-76.65025000"
                        },
                        {
                            "id": 118414,
                            "name": "Highland Park",
                            "latitude": "40.62091000",
                            "longitude": "-77.56805000"
                        },
                        {
                            "id": 118423,
                            "name": "Highspire",
                            "latitude": "40.21092000",
                            "longitude": "-76.79108000"
                        },
                        {
                            "id": 118442,
                            "name": "Hilldale",
                            "latitude": "41.28925000",
                            "longitude": "-75.83631000"
                        },
                        {
                            "id": 118443,
                            "name": "Hiller",
                            "latitude": "40.01035000",
                            "longitude": "-79.90088000"
                        },
                        {
                            "id": 118515,
                            "name": "Hokendauqua",
                            "latitude": "40.66204000",
                            "longitude": "-75.49102000"
                        },
                        {
                            "id": 118545,
                            "name": "Hollidaysburg",
                            "latitude": "40.42729000",
                            "longitude": "-78.38890000"
                        },
                        {
                            "id": 118588,
                            "name": "Homeacre-Lyndora",
                            "latitude": "40.87206000",
                            "longitude": "-79.92060000"
                        },
                        {
                            "id": 118598,
                            "name": "Homer City",
                            "latitude": "40.54340000",
                            "longitude": "-79.16226000"
                        },
                        {
                            "id": 118602,
                            "name": "Homestead",
                            "latitude": "40.40590000",
                            "longitude": "-79.91199000"
                        },
                        {
                            "id": 118606,
                            "name": "Hometown",
                            "latitude": "40.82370000",
                            "longitude": "-75.98020000"
                        },
                        {
                            "id": 118618,
                            "name": "Honesdale",
                            "latitude": "41.57676000",
                            "longitude": "-75.25879000"
                        },
                        {
                            "id": 118619,
                            "name": "Honey Brook",
                            "latitude": "40.09427000",
                            "longitude": "-75.91133000"
                        },
                        {
                            "id": 118659,
                            "name": "Hopwood",
                            "latitude": "39.87702000",
                            "longitude": "-79.70199000"
                        },
                        {
                            "id": 118677,
                            "name": "Horsham",
                            "latitude": "40.17844000",
                            "longitude": "-75.12851000"
                        },
                        {
                            "id": 118696,
                            "name": "Houserville",
                            "latitude": "40.82395000",
                            "longitude": "-77.82889000"
                        },
                        {
                            "id": 118700,
                            "name": "Houston",
                            "latitude": "40.24646000",
                            "longitude": "-80.21145000"
                        },
                        {
                            "id": 118745,
                            "name": "Hudson",
                            "latitude": "41.27480000",
                            "longitude": "-75.83603000"
                        },
                        {
                            "id": 118761,
                            "name": "Hughestown",
                            "latitude": "41.32702000",
                            "longitude": "-75.77325000"
                        },
                        {
                            "id": 118763,
                            "name": "Hughesville",
                            "latitude": "41.24119000",
                            "longitude": "-76.72385000"
                        },
                        {
                            "id": 118782,
                            "name": "Hummels Wharf",
                            "latitude": "40.83175000",
                            "longitude": "-76.83580000"
                        },
                        {
                            "id": 118783,
                            "name": "Hummelstown",
                            "latitude": "40.26537000",
                            "longitude": "-76.70830000"
                        },
                        {
                            "id": 118796,
                            "name": "Huntingdon",
                            "latitude": "40.48480000",
                            "longitude": "-78.01028000"
                        },
                        {
                            "id": 118797,
                            "name": "Huntingdon County",
                            "latitude": "40.41693000",
                            "longitude": "-77.98121000"
                        },
                        {
                            "id": 118846,
                            "name": "Hyde",
                            "latitude": "41.00256000",
                            "longitude": "-78.46252000"
                        },
                        {
                            "id": 118851,
                            "name": "Hyde Park",
                            "latitude": "40.37732000",
                            "longitude": "-75.92521000"
                        },
                        {
                            "id": 118882,
                            "name": "Imperial",
                            "latitude": "40.44951000",
                            "longitude": "-80.24450000"
                        },
                        {
                            "id": 118911,
                            "name": "Indian Mountain Lake",
                            "latitude": "41.00314000",
                            "longitude": "-75.50824000"
                        },
                        {
                            "id": 118922,
                            "name": "Indiana",
                            "latitude": "40.62146000",
                            "longitude": "-79.15253000"
                        },
                        {
                            "id": 118923,
                            "name": "Indiana County",
                            "latitude": "40.65205000",
                            "longitude": "-79.08755000"
                        },
                        {
                            "id": 118930,
                            "name": "Industry",
                            "latitude": "40.64451000",
                            "longitude": "-80.41618000"
                        },
                        {
                            "id": 118941,
                            "name": "Ingram",
                            "latitude": "40.44618000",
                            "longitude": "-80.06755000"
                        },
                        {
                            "id": 118942,
                            "name": "Inkerman",
                            "latitude": "41.29897000",
                            "longitude": "-75.81269000"
                        },
                        {
                            "id": 118949,
                            "name": "Intercourse",
                            "latitude": "40.03760000",
                            "longitude": "-76.10495000"
                        },
                        {
                            "id": 119012,
                            "name": "Irwin",
                            "latitude": "40.32451000",
                            "longitude": "-79.70115000"
                        },
                        {
                            "id": 119052,
                            "name": "Ivyland",
                            "latitude": "40.20789000",
                            "longitude": "-75.07267000"
                        },
                        {
                            "id": 119107,
                            "name": "Jacksonwald",
                            "latitude": "40.32454000",
                            "longitude": "-75.84965000"
                        },
                        {
                            "id": 119108,
                            "name": "Jacobus",
                            "latitude": "39.88316000",
                            "longitude": "-76.71052000"
                        },
                        {
                            "id": 119159,
                            "name": "Jeannette",
                            "latitude": "40.32812000",
                            "longitude": "-79.61532000"
                        },
                        {
                            "id": 119192,
                            "name": "Jefferson County",
                            "latitude": "41.12815000",
                            "longitude": "-78.99942000"
                        },
                        {
                            "id": 119202,
                            "name": "Jefferson Hills",
                            "latitude": "40.29118000",
                            "longitude": "-79.93199000"
                        },
                        {
                            "id": 119216,
                            "name": "Jenkintown",
                            "latitude": "40.09594000",
                            "longitude": "-75.12517000"
                        },
                        {
                            "id": 119226,
                            "name": "Jermyn",
                            "latitude": "41.53091000",
                            "longitude": "-75.54546000"
                        },
                        {
                            "id": 119228,
                            "name": "Jerome",
                            "latitude": "40.20896000",
                            "longitude": "-78.98364000"
                        },
                        {
                            "id": 119233,
                            "name": "Jersey Shore",
                            "latitude": "41.20202000",
                            "longitude": "-77.26442000"
                        },
                        {
                            "id": 119238,
                            "name": "Jessup",
                            "latitude": "41.46869000",
                            "longitude": "-75.56213000"
                        },
                        {
                            "id": 119247,
                            "name": "Jim Thorpe",
                            "latitude": "40.87592000",
                            "longitude": "-75.73241000"
                        },
                        {
                            "id": 119274,
                            "name": "Johnsonburg",
                            "latitude": "41.49062000",
                            "longitude": "-78.67503000"
                        },
                        {
                            "id": 119285,
                            "name": "Johnstown",
                            "latitude": "40.32674000",
                            "longitude": "-78.92197000"
                        },
                        {
                            "id": 119308,
                            "name": "Jonestown",
                            "latitude": "40.41370000",
                            "longitude": "-76.47830000"
                        },
                        {
                            "id": 119340,
                            "name": "Juniata County",
                            "latitude": "40.53106000",
                            "longitude": "-77.40216000"
                        },
                        {
                            "id": 119376,
                            "name": "Kane",
                            "latitude": "41.66284000",
                            "longitude": "-78.81114000"
                        },
                        {
                            "id": 119455,
                            "name": "Kenhorst",
                            "latitude": "40.31065000",
                            "longitude": "-75.93938000"
                        },
                        {
                            "id": 119458,
                            "name": "Kenilworth",
                            "latitude": "40.23149000",
                            "longitude": "-75.63408000"
                        },
                        {
                            "id": 119460,
                            "name": "Kenmar",
                            "latitude": "41.25341000",
                            "longitude": "-76.95941000"
                        },
                        {
                            "id": 119474,
                            "name": "Kennett Square",
                            "latitude": "39.84678000",
                            "longitude": "-75.71160000"
                        },
                        {
                            "id": 119590,
                            "name": "King of Prussia",
                            "latitude": "40.08927000",
                            "longitude": "-75.39602000"
                        },
                        {
                            "id": 119628,
                            "name": "Kingston",
                            "latitude": "41.26175000",
                            "longitude": "-75.89686000"
                        },
                        {
                            "id": 119661,
                            "name": "Kittanning",
                            "latitude": "40.81645000",
                            "longitude": "-79.52199000"
                        },
                        {
                            "id": 119683,
                            "name": "Knox",
                            "latitude": "41.23451000",
                            "longitude": "-79.53727000"
                        },
                        {
                            "id": 119723,
                            "name": "Kulpmont",
                            "latitude": "40.79342000",
                            "longitude": "-76.47245000"
                        },
                        {
                            "id": 119724,
                            "name": "Kulpsville",
                            "latitude": "40.24288000",
                            "longitude": "-75.33656000"
                        },
                        {
                            "id": 119728,
                            "name": "Kutztown",
                            "latitude": "40.51732000",
                            "longitude": "-75.77742000"
                        },
                        {
                            "id": 119814,
                            "name": "Lackawanna County",
                            "latitude": "41.43679000",
                            "longitude": "-75.60920000"
                        },
                        {
                            "id": 119843,
                            "name": "Lafayette Hill",
                            "latitude": "40.09245000",
                            "longitude": "-75.25330000"
                        },
                        {
                            "id": 119845,
                            "name": "Laflin",
                            "latitude": "41.28897000",
                            "longitude": "-75.80547000"
                        },
                        {
                            "id": 119885,
                            "name": "Lake City",
                            "latitude": "42.01422000",
                            "longitude": "-80.34534000"
                        },
                        {
                            "id": 119920,
                            "name": "Lake Heritage",
                            "latitude": "39.80954000",
                            "longitude": "-77.18387000"
                        },
                        {
                            "id": 119929,
                            "name": "Lake Latonka",
                            "latitude": "41.29039000",
                            "longitude": "-80.18129000"
                        },
                        {
                            "id": 119940,
                            "name": "Lake Meade",
                            "latitude": "39.98510000",
                            "longitude": "-77.03720000"
                        },
                        {
                            "id": 120001,
                            "name": "Lake Wynonah",
                            "latitude": "40.59926000",
                            "longitude": "-76.16383000"
                        },
                        {
                            "id": 120021,
                            "name": "Lakemont",
                            "latitude": "40.47285000",
                            "longitude": "-78.38835000"
                        },
                        {
                            "id": 120077,
                            "name": "Lampeter",
                            "latitude": "39.99010000",
                            "longitude": "-76.23968000"
                        },
                        {
                            "id": 120087,
                            "name": "Lancaster",
                            "latitude": "40.03788000",
                            "longitude": "-76.30551000"
                        },
                        {
                            "id": 120093,
                            "name": "Lancaster County",
                            "latitude": "40.04244000",
                            "longitude": "-76.24770000"
                        },
                        {
                            "id": 120100,
                            "name": "Landisville",
                            "latitude": "40.09537000",
                            "longitude": "-76.40996000"
                        },
                        {
                            "id": 120110,
                            "name": "Langhorne",
                            "latitude": "40.17455000",
                            "longitude": "-74.92267000"
                        },
                        {
                            "id": 120111,
                            "name": "Langhorne Manor",
                            "latitude": "40.16705000",
                            "longitude": "-74.91767000"
                        },
                        {
                            "id": 120121,
                            "name": "Lansdale",
                            "latitude": "40.24150000",
                            "longitude": "-75.28379000"
                        },
                        {
                            "id": 120123,
                            "name": "Lansdowne",
                            "latitude": "39.93817000",
                            "longitude": "-75.27185000"
                        },
                        {
                            "id": 120124,
                            "name": "Lansford",
                            "latitude": "40.83176000",
                            "longitude": "-75.88242000"
                        },
                        {
                            "id": 120135,
                            "name": "Laporte",
                            "latitude": "41.42397000",
                            "longitude": "-76.49411000"
                        },
                        {
                            "id": 120149,
                            "name": "Larksville",
                            "latitude": "41.24508000",
                            "longitude": "-75.93075000"
                        },
                        {
                            "id": 120175,
                            "name": "Latrobe",
                            "latitude": "40.32118000",
                            "longitude": "-79.37948000"
                        },
                        {
                            "id": 120202,
                            "name": "Laureldale",
                            "latitude": "40.38815000",
                            "longitude": "-75.91798000"
                        },
                        {
                            "id": 120212,
                            "name": "Laurys Station",
                            "latitude": "40.72315000",
                            "longitude": "-75.53018000"
                        },
                        {
                            "id": 120224,
                            "name": "Lawnton",
                            "latitude": "40.25842000",
                            "longitude": "-76.80386000"
                        },
                        {
                            "id": 120237,
                            "name": "Lawrence County",
                            "latitude": "40.99127000",
                            "longitude": "-80.33419000"
                        },
                        {
                            "id": 120239,
                            "name": "Lawrence Park",
                            "latitude": "42.15228000",
                            "longitude": "-80.02311000"
                        },
                        {
                            "id": 120248,
                            "name": "Lawson Heights",
                            "latitude": "40.29174000",
                            "longitude": "-79.38920000"
                        },
                        {
                            "id": 120293,
                            "name": "Lebanon",
                            "latitude": "40.34093000",
                            "longitude": "-76.41135000"
                        },
                        {
                            "id": 120295,
                            "name": "Lebanon County",
                            "latitude": "40.36723000",
                            "longitude": "-76.45771000"
                        },
                        {
                            "id": 120297,
                            "name": "Lebanon South",
                            "latitude": "40.32804000",
                            "longitude": "-76.40644000"
                        },
                        {
                            "id": 120319,
                            "name": "Leechburg",
                            "latitude": "40.62701000",
                            "longitude": "-79.60560000"
                        },
                        {
                            "id": 120327,
                            "name": "Leesport",
                            "latitude": "40.44704000",
                            "longitude": "-75.96632000"
                        },
                        {
                            "id": 120330,
                            "name": "Leetsdale",
                            "latitude": "40.56312000",
                            "longitude": "-80.20839000"
                        },
                        {
                            "id": 120335,
                            "name": "Lehigh County",
                            "latitude": "40.61271000",
                            "longitude": "-75.59237000"
                        },
                        {
                            "id": 120336,
                            "name": "Lehighton",
                            "latitude": "40.83370000",
                            "longitude": "-75.71380000"
                        },
                        {
                            "id": 120348,
                            "name": "Leith-Hatfield",
                            "latitude": "39.87744000",
                            "longitude": "-79.73133000"
                        },
                        {
                            "id": 120361,
                            "name": "Lemont",
                            "latitude": "40.81062000",
                            "longitude": "-77.81833000"
                        },
                        {
                            "id": 120364,
                            "name": "Lemoyne",
                            "latitude": "40.24120000",
                            "longitude": "-76.89414000"
                        },
                        {
                            "id": 120367,
                            "name": "Lenape Heights",
                            "latitude": "40.76423000",
                            "longitude": "-79.52060000"
                        },
                        {
                            "id": 120380,
                            "name": "Leola",
                            "latitude": "40.08787000",
                            "longitude": "-76.18495000"
                        },
                        {
                            "id": 120402,
                            "name": "Level Green",
                            "latitude": "40.39340000",
                            "longitude": "-79.72032000"
                        },
                        {
                            "id": 120408,
                            "name": "Levittown",
                            "latitude": "40.15511000",
                            "longitude": "-74.82877000"
                        },
                        {
                            "id": 120421,
                            "name": "Lewisburg",
                            "latitude": "40.96453000",
                            "longitude": "-76.88441000"
                        },
                        {
                            "id": 120432,
                            "name": "Lewistown",
                            "latitude": "40.59924000",
                            "longitude": "-77.57138000"
                        },
                        {
                            "id": 120467,
                            "name": "Liberty",
                            "latitude": "40.32535000",
                            "longitude": "-79.85616000"
                        },
                        {
                            "id": 120479,
                            "name": "Light Street",
                            "latitude": "41.03620000",
                            "longitude": "-76.42356000"
                        },
                        {
                            "id": 120482,
                            "name": "Ligonier",
                            "latitude": "40.24313000",
                            "longitude": "-79.23753000"
                        },
                        {
                            "id": 120488,
                            "name": "Lima",
                            "latitude": "39.91733000",
                            "longitude": "-75.44047000"
                        },
                        {
                            "id": 120491,
                            "name": "Limerick",
                            "latitude": "40.23093000",
                            "longitude": "-75.52212000"
                        },
                        {
                            "id": 120507,
                            "name": "Lincoln",
                            "latitude": "40.31896000",
                            "longitude": "-79.85477000"
                        },
                        {
                            "id": 120544,
                            "name": "Lincoln Park",
                            "latitude": "40.31481000",
                            "longitude": "-75.98549000"
                        },
                        {
                            "id": 120579,
                            "name": "Linglestown",
                            "latitude": "40.33398000",
                            "longitude": "-76.78914000"
                        },
                        {
                            "id": 120586,
                            "name": "Linntown",
                            "latitude": "40.95897000",
                            "longitude": "-76.89913000"
                        },
                        {
                            "id": 120593,
                            "name": "Linwood",
                            "latitude": "39.82650000",
                            "longitude": "-75.42547000"
                        },
                        {
                            "id": 120594,
                            "name": "Lionville",
                            "latitude": "40.05372000",
                            "longitude": "-75.65993000"
                        },
                        {
                            "id": 120612,
                            "name": "Lititz",
                            "latitude": "40.15732000",
                            "longitude": "-76.30690000"
                        },
                        {
                            "id": 120634,
                            "name": "Littlestown",
                            "latitude": "39.74454000",
                            "longitude": "-77.08804000"
                        },
                        {
                            "id": 120674,
                            "name": "Lock Haven",
                            "latitude": "41.13701000",
                            "longitude": "-77.44693000"
                        },
                        {
                            "id": 120709,
                            "name": "Loganville",
                            "latitude": "39.85566000",
                            "longitude": "-76.70747000"
                        },
                        {
                            "id": 120769,
                            "name": "Lorane",
                            "latitude": "40.28843000",
                            "longitude": "-75.85465000"
                        },
                        {
                            "id": 120775,
                            "name": "Loretto",
                            "latitude": "40.50313000",
                            "longitude": "-78.63030000"
                        },
                        {
                            "id": 120839,
                            "name": "Lower Allen",
                            "latitude": "40.22648000",
                            "longitude": "-76.90053000"
                        },
                        {
                            "id": 120840,
                            "name": "Lower Burrell",
                            "latitude": "40.55312000",
                            "longitude": "-79.75727000"
                        },
                        {
                            "id": 120854,
                            "name": "Loyalhanna",
                            "latitude": "40.32257000",
                            "longitude": "-79.36226000"
                        },
                        {
                            "id": 120903,
                            "name": "Luzerne",
                            "latitude": "41.28564000",
                            "longitude": "-75.90103000"
                        },
                        {
                            "id": 120904,
                            "name": "Luzerne County",
                            "latitude": "41.17701000",
                            "longitude": "-75.98903000"
                        },
                        {
                            "id": 120905,
                            "name": "Lycoming County",
                            "latitude": "41.34338000",
                            "longitude": "-77.06451000"
                        },
                        {
                            "id": 120907,
                            "name": "Lykens",
                            "latitude": "40.56675000",
                            "longitude": "-76.70052000"
                        },
                        {
                            "id": 120932,
                            "name": "Lynnwood-Pricedale",
                            "latitude": "40.13071000",
                            "longitude": "-79.85135000"
                        },
                        {
                            "id": 120974,
                            "name": "Macungie",
                            "latitude": "40.51593000",
                            "longitude": "-75.55519000"
                        },
                        {
                            "id": 121040,
                            "name": "Mahanoy City",
                            "latitude": "40.81259000",
                            "longitude": "-76.14160000"
                        },
                        {
                            "id": 121073,
                            "name": "Malvern",
                            "latitude": "40.03622000",
                            "longitude": "-75.51381000"
                        },
                        {
                            "id": 121100,
                            "name": "Manchester",
                            "latitude": "40.06315000",
                            "longitude": "-76.71830000"
                        },
                        {
                            "id": 121118,
                            "name": "Manheim",
                            "latitude": "40.16343000",
                            "longitude": "-76.39496000"
                        },
                        {
                            "id": 121139,
                            "name": "Manor",
                            "latitude": "40.33396000",
                            "longitude": "-79.67004000"
                        },
                        {
                            "id": 121147,
                            "name": "Mansfield",
                            "latitude": "41.80730000",
                            "longitude": "-77.07747000"
                        },
                        {
                            "id": 121166,
                            "name": "Maple Glen",
                            "latitude": "40.17928000",
                            "longitude": "-75.18045000"
                        },
                        {
                            "id": 121200,
                            "name": "Marcus Hook",
                            "latitude": "39.81928000",
                            "longitude": "-75.41853000"
                        },
                        {
                            "id": 121210,
                            "name": "Marianne",
                            "latitude": "41.24645000",
                            "longitude": "-79.42893000"
                        },
                        {
                            "id": 121214,
                            "name": "Marienville",
                            "latitude": "41.46895000",
                            "longitude": "-79.12310000"
                        },
                        {
                            "id": 121218,
                            "name": "Marietta",
                            "latitude": "40.05704000",
                            "longitude": "-76.55219000"
                        },
                        {
                            "id": 121298,
                            "name": "Mars",
                            "latitude": "40.69590000",
                            "longitude": "-80.01173000"
                        },
                        {
                            "id": 121322,
                            "name": "Marshallton",
                            "latitude": "40.78675000",
                            "longitude": "-76.53940000"
                        },
                        {
                            "id": 121347,
                            "name": "Martinsburg",
                            "latitude": "40.31119000",
                            "longitude": "-78.32418000"
                        },
                        {
                            "id": 121359,
                            "name": "Marysville",
                            "latitude": "40.34259000",
                            "longitude": "-76.92997000"
                        },
                        {
                            "id": 121384,
                            "name": "Masontown",
                            "latitude": "39.84674000",
                            "longitude": "-79.89978000"
                        },
                        {
                            "id": 121398,
                            "name": "Matamoras",
                            "latitude": "41.36870000",
                            "longitude": "-74.70016000"
                        },
                        {
                            "id": 121437,
                            "name": "Mayfield",
                            "latitude": "41.53814000",
                            "longitude": "-75.53602000"
                        },
                        {
                            "id": 121453,
                            "name": "Maytown",
                            "latitude": "40.07537000",
                            "longitude": "-76.58219000"
                        },
                        {
                            "id": 121461,
                            "name": "McAdoo",
                            "latitude": "40.90127000",
                            "longitude": "-75.99106000"
                        },
                        {
                            "id": 121477,
                            "name": "McConnellsburg",
                            "latitude": "39.93259000",
                            "longitude": "-77.99889000"
                        },
                        {
                            "id": 121478,
                            "name": "McConnellstown",
                            "latitude": "40.45257000",
                            "longitude": "-78.08167000"
                        },
                        {
                            "id": 121491,
                            "name": "McDonald",
                            "latitude": "40.37090000",
                            "longitude": "-80.23478000"
                        },
                        {
                            "id": 121503,
                            "name": "McGovern",
                            "latitude": "40.22896000",
                            "longitude": "-80.21645000"
                        },
                        {
                            "id": 121516,
                            "name": "McKean County",
                            "latitude": "41.80775000",
                            "longitude": "-78.56903000"
                        },
                        {
                            "id": 121518,
                            "name": "McKees Rocks",
                            "latitude": "40.46562000",
                            "longitude": "-80.06561000"
                        },
                        {
                            "id": 121519,
                            "name": "McKeesport",
                            "latitude": "40.34785000",
                            "longitude": "-79.86422000"
                        },
                        {
                            "id": 121543,
                            "name": "McMurray",
                            "latitude": "40.27785000",
                            "longitude": "-80.08394000"
                        },
                        {
                            "id": 121551,
                            "name": "McSherrystown",
                            "latitude": "39.80732000",
                            "longitude": "-77.01137000"
                        },
                        {
                            "id": 121570,
                            "name": "Meadowood",
                            "latitude": "40.84201000",
                            "longitude": "-79.89394000"
                        },
                        {
                            "id": 121575,
                            "name": "Meadville",
                            "latitude": "41.64144000",
                            "longitude": "-80.15145000"
                        },
                        {
                            "id": 121580,
                            "name": "Mechanicsburg",
                            "latitude": "40.21426000",
                            "longitude": "-77.00859000"
                        },
                        {
                            "id": 121585,
                            "name": "Mechanicsville",
                            "latitude": "40.96648000",
                            "longitude": "-76.58662000"
                        },
                        {
                            "id": 121598,
                            "name": "Media",
                            "latitude": "39.91678000",
                            "longitude": "-75.38769000"
                        },
                        {
                            "id": 121674,
                            "name": "Mercer",
                            "latitude": "41.22700000",
                            "longitude": "-80.23979000"
                        },
                        {
                            "id": 121680,
                            "name": "Mercer County",
                            "latitude": "41.30218000",
                            "longitude": "-80.25770000"
                        },
                        {
                            "id": 121683,
                            "name": "Mercersburg",
                            "latitude": "39.82787000",
                            "longitude": "-77.90333000"
                        },
                        {
                            "id": 121693,
                            "name": "Meridian",
                            "latitude": "40.84840000",
                            "longitude": "-79.96200000"
                        },
                        {
                            "id": 121745,
                            "name": "Meyersdale",
                            "latitude": "39.81369000",
                            "longitude": "-79.02475000"
                        },
                        {
                            "id": 121769,
                            "name": "Middleburg",
                            "latitude": "40.78592000",
                            "longitude": "-77.04720000"
                        },
                        {
                            "id": 121792,
                            "name": "Middletown",
                            "latitude": "40.19981000",
                            "longitude": "-76.73108000"
                        },
                        {
                            "id": 121799,
                            "name": "Midland",
                            "latitude": "40.63257000",
                            "longitude": "-80.44645000"
                        },
                        {
                            "id": 121818,
                            "name": "Midway",
                            "latitude": "39.80843000",
                            "longitude": "-77.00276000"
                        },
                        {
                            "id": 121825,
                            "name": "Mifflin County",
                            "latitude": "40.61041000",
                            "longitude": "-77.61704000"
                        },
                        {
                            "id": 121826,
                            "name": "Mifflinburg",
                            "latitude": "40.91758000",
                            "longitude": "-77.04775000"
                        },
                        {
                            "id": 121827,
                            "name": "Mifflintown",
                            "latitude": "40.56980000",
                            "longitude": "-77.39693000"
                        },
                        {
                            "id": 121828,
                            "name": "Mifflinville",
                            "latitude": "41.03231000",
                            "longitude": "-76.30799000"
                        },
                        {
                            "id": 121845,
                            "name": "Milesburg",
                            "latitude": "40.94173000",
                            "longitude": "-77.78500000"
                        },
                        {
                            "id": 121857,
                            "name": "Milford",
                            "latitude": "41.32232000",
                            "longitude": "-74.80239000"
                        },
                        {
                            "id": 121865,
                            "name": "Mill Hall",
                            "latitude": "41.10729000",
                            "longitude": "-77.48443000"
                        },
                        {
                            "id": 121870,
                            "name": "Millbourne",
                            "latitude": "39.96345000",
                            "longitude": "-75.25018000"
                        },
                        {
                            "id": 121886,
                            "name": "Millersburg",
                            "latitude": "40.53953000",
                            "longitude": "-76.96081000"
                        },
                        {
                            "id": 121889,
                            "name": "Millersville",
                            "latitude": "39.99788000",
                            "longitude": "-76.35413000"
                        },
                        {
                            "id": 121903,
                            "name": "Millvale",
                            "latitude": "40.48007000",
                            "longitude": "-79.97839000"
                        },
                        {
                            "id": 121910,
                            "name": "Milroy",
                            "latitude": "40.71396000",
                            "longitude": "-77.59055000"
                        },
                        {
                            "id": 121917,
                            "name": "Milton",
                            "latitude": "41.01203000",
                            "longitude": "-76.84774000"
                        },
                        {
                            "id": 121943,
                            "name": "Minersville",
                            "latitude": "40.69065000",
                            "longitude": "-76.26217000"
                        },
                        {
                            "id": 122022,
                            "name": "Mohnton",
                            "latitude": "40.28593000",
                            "longitude": "-75.98438000"
                        },
                        {
                            "id": 122032,
                            "name": "Monaca",
                            "latitude": "40.68729000",
                            "longitude": "-80.27145000"
                        },
                        {
                            "id": 122038,
                            "name": "Monessen",
                            "latitude": "40.14841000",
                            "longitude": "-79.88783000"
                        },
                        {
                            "id": 122055,
                            "name": "Monongahela",
                            "latitude": "40.20313000",
                            "longitude": "-79.92616000"
                        },
                        {
                            "id": 122081,
                            "name": "Monroe County",
                            "latitude": "41.05807000",
                            "longitude": "-75.33948000"
                        },
                        {
                            "id": 122086,
                            "name": "Monroeville",
                            "latitude": "40.42118000",
                            "longitude": "-79.78810000"
                        },
                        {
                            "id": 122092,
                            "name": "Mont Alto",
                            "latitude": "39.84426000",
                            "longitude": "-77.55832000"
                        },
                        {
                            "id": 122136,
                            "name": "Montgomery",
                            "latitude": "41.17036000",
                            "longitude": "-76.87691000"
                        },
                        {
                            "id": 122155,
                            "name": "Montgomery County",
                            "latitude": "40.21083000",
                            "longitude": "-75.36730000"
                        },
                        {
                            "id": 122157,
                            "name": "Montgomeryville",
                            "latitude": "40.24733000",
                            "longitude": "-75.24379000"
                        },
                        {
                            "id": 122173,
                            "name": "Montour County",
                            "latitude": "41.02792000",
                            "longitude": "-76.65856000"
                        },
                        {
                            "id": 122175,
                            "name": "Montoursville",
                            "latitude": "41.25425000",
                            "longitude": "-76.92052000"
                        },
                        {
                            "id": 122183,
                            "name": "Montrose",
                            "latitude": "41.83397000",
                            "longitude": "-75.87714000"
                        },
                        {
                            "id": 122216,
                            "name": "Moosic",
                            "latitude": "41.35341000",
                            "longitude": "-75.73825000"
                        },
                        {
                            "id": 122286,
                            "name": "Morrisville",
                            "latitude": "40.21150000",
                            "longitude": "-74.78794000"
                        },
                        {
                            "id": 122292,
                            "name": "Morton",
                            "latitude": "39.90983000",
                            "longitude": "-75.32352000"
                        },
                        {
                            "id": 122299,
                            "name": "Moscow",
                            "latitude": "41.33675000",
                            "longitude": "-75.51852000"
                        },
                        {
                            "id": 122337,
                            "name": "Mount Carmel",
                            "latitude": "40.79703000",
                            "longitude": "-76.41190000"
                        },
                        {
                            "id": 122340,
                            "name": "Mount Cobb",
                            "latitude": "41.41342000",
                            "longitude": "-75.49324000"
                        },
                        {
                            "id": 122351,
                            "name": "Mount Holly Springs",
                            "latitude": "40.11842000",
                            "longitude": "-77.18998000"
                        },
                        {
                            "id": 122358,
                            "name": "Mount Joy",
                            "latitude": "40.10982000",
                            "longitude": "-76.50330000"
                        },
                        {
                            "id": 122362,
                            "name": "Mount Lebanon",
                            "latitude": "40.35535000",
                            "longitude": "-80.04950000"
                        },
                        {
                            "id": 122370,
                            "name": "Mount Oliver",
                            "latitude": "40.41424000",
                            "longitude": "-79.98783000"
                        },
                        {
                            "id": 122373,
                            "name": "Mount Penn",
                            "latitude": "40.32815000",
                            "longitude": "-75.89076000"
                        },
                        {
                            "id": 122380,
                            "name": "Mount Pleasant",
                            "latitude": "40.14896000",
                            "longitude": "-79.54115000"
                        },
                        {
                            "id": 122384,
                            "name": "Mount Pocono",
                            "latitude": "41.12203000",
                            "longitude": "-75.36463000"
                        },
                        {
                            "id": 122392,
                            "name": "Mount Union",
                            "latitude": "40.38452000",
                            "longitude": "-77.88222000"
                        },
                        {
                            "id": 122407,
                            "name": "Mount Wolf",
                            "latitude": "40.06315000",
                            "longitude": "-76.70386000"
                        },
                        {
                            "id": 122427,
                            "name": "Mountain Top",
                            "latitude": "41.16953000",
                            "longitude": "-75.87742000"
                        },
                        {
                            "id": 122437,
                            "name": "Mountainhome",
                            "latitude": "41.17370000",
                            "longitude": "-75.27102000"
                        },
                        {
                            "id": 122441,
                            "name": "Mountville",
                            "latitude": "40.03926000",
                            "longitude": "-76.43080000"
                        },
                        {
                            "id": 122449,
                            "name": "Muhlenberg Park",
                            "latitude": "40.38482000",
                            "longitude": "-75.94132000"
                        },
                        {
                            "id": 122466,
                            "name": "Muncy",
                            "latitude": "41.20564000",
                            "longitude": "-76.78552000"
                        },
                        {
                            "id": 122469,
                            "name": "Mundys Corner",
                            "latitude": "40.44479000",
                            "longitude": "-78.84113000"
                        },
                        {
                            "id": 122473,
                            "name": "Munhall",
                            "latitude": "40.39229000",
                            "longitude": "-79.90005000"
                        },
                        {
                            "id": 122500,
                            "name": "Murrysville",
                            "latitude": "40.42840000",
                            "longitude": "-79.69755000"
                        },
                        {
                            "id": 122507,
                            "name": "Muse",
                            "latitude": "40.29285000",
                            "longitude": "-80.20034000"
                        },
                        {
                            "id": 122518,
                            "name": "Myerstown",
                            "latitude": "40.37454000",
                            "longitude": "-76.30273000"
                        },
                        {
                            "id": 122541,
                            "name": "Nanticoke",
                            "latitude": "41.20536000",
                            "longitude": "-76.00492000"
                        },
                        {
                            "id": 122544,
                            "name": "Nanty Glo",
                            "latitude": "40.47229000",
                            "longitude": "-78.83336000"
                        },
                        {
                            "id": 122563,
                            "name": "Narberth",
                            "latitude": "40.00845000",
                            "longitude": "-75.26046000"
                        },
                        {
                            "id": 122606,
                            "name": "Nazareth",
                            "latitude": "40.74038000",
                            "longitude": "-75.30962000"
                        },
                        {
                            "id": 122640,
                            "name": "Nescopeck",
                            "latitude": "41.05203000",
                            "longitude": "-76.22077000"
                        },
                        {
                            "id": 122642,
                            "name": "Nesquehoning",
                            "latitude": "40.86453000",
                            "longitude": "-75.81103000"
                        },
                        {
                            "id": 122661,
                            "name": "New Beaver",
                            "latitude": "40.87645000",
                            "longitude": "-80.37062000"
                        },
                        {
                            "id": 122665,
                            "name": "New Berlinville",
                            "latitude": "40.34537000",
                            "longitude": "-75.63296000"
                        },
                        {
                            "id": 122667,
                            "name": "New Bloomfield",
                            "latitude": "40.41981000",
                            "longitude": "-77.18637000"
                        },
                        {
                            "id": 122673,
                            "name": "New Brighton",
                            "latitude": "40.73034000",
                            "longitude": "-80.31006000"
                        },
                        {
                            "id": 122675,
                            "name": "New Britain",
                            "latitude": "40.29900000",
                            "longitude": "-75.18101000"
                        },
                        {
                            "id": 122688,
                            "name": "New Castle",
                            "latitude": "41.00367000",
                            "longitude": "-80.34701000"
                        },
                        {
                            "id": 122691,
                            "name": "New Castle Northwest",
                            "latitude": "41.02208000",
                            "longitude": "-80.35682000"
                        },
                        {
                            "id": 122696,
                            "name": "New Columbia",
                            "latitude": "41.04092000",
                            "longitude": "-76.86691000"
                        },
                        {
                            "id": 122697,
                            "name": "New Cumberland",
                            "latitude": "40.23231000",
                            "longitude": "-76.88470000"
                        },
                        {
                            "id": 122702,
                            "name": "New Eagle",
                            "latitude": "40.20785000",
                            "longitude": "-79.94699000"
                        },
                        {
                            "id": 122707,
                            "name": "New Freedom",
                            "latitude": "39.73788000",
                            "longitude": "-76.70136000"
                        },
                        {
                            "id": 122721,
                            "name": "New Holland",
                            "latitude": "40.10176000",
                            "longitude": "-76.08523000"
                        },
                        {
                            "id": 122727,
                            "name": "New Hope",
                            "latitude": "40.36427000",
                            "longitude": "-74.95128000"
                        },
                        {
                            "id": 122733,
                            "name": "New Kensington",
                            "latitude": "40.56979000",
                            "longitude": "-79.76477000"
                        },
                        {
                            "id": 122757,
                            "name": "New Oxford",
                            "latitude": "39.86371000",
                            "longitude": "-77.05581000"
                        },
                        {
                            "id": 122762,
                            "name": "New Philadelphia",
                            "latitude": "40.71953000",
                            "longitude": "-76.11577000"
                        },
                        {
                            "id": 122783,
                            "name": "New Stanton",
                            "latitude": "40.21924000",
                            "longitude": "-79.60948000"
                        },
                        {
                            "id": 122792,
                            "name": "New Wilmington",
                            "latitude": "41.12228000",
                            "longitude": "-80.33284000"
                        },
                        {
                            "id": 122835,
                            "name": "Newmanstown",
                            "latitude": "40.34954000",
                            "longitude": "-76.21328000"
                        },
                        {
                            "id": 122848,
                            "name": "Newport",
                            "latitude": "40.47786000",
                            "longitude": "-77.13054000"
                        },
                        {
                            "id": 122874,
                            "name": "Newtown",
                            "latitude": "40.22928000",
                            "longitude": "-74.93683000"
                        },
                        {
                            "id": 122876,
                            "name": "Newtown Grant",
                            "latitude": "40.26011000",
                            "longitude": "-74.95489000"
                        },
                        {
                            "id": 122877,
                            "name": "Newville",
                            "latitude": "40.17314000",
                            "longitude": "-77.39860000"
                        },
                        {
                            "id": 122914,
                            "name": "Nixon",
                            "latitude": "40.78340000",
                            "longitude": "-79.92950000"
                        },
                        {
                            "id": 122957,
                            "name": "Norristown",
                            "latitude": "40.12150000",
                            "longitude": "-75.33990000"
                        },
                        {
                            "id": 122964,
                            "name": "North Apollo",
                            "latitude": "40.59618000",
                            "longitude": "-79.55560000"
                        },
                        {
                            "id": 122980,
                            "name": "North Belle Vernon",
                            "latitude": "40.12924000",
                            "longitude": "-79.86810000"
                        },
                        {
                            "id": 122992,
                            "name": "North Braddock",
                            "latitude": "40.39896000",
                            "longitude": "-79.84088000"
                        },
                        {
                            "id": 123002,
                            "name": "North Catasauqua",
                            "latitude": "40.65982000",
                            "longitude": "-75.47685000"
                        },
                        {
                            "id": 123004,
                            "name": "North Charleroi",
                            "latitude": "40.15118000",
                            "longitude": "-79.90755000"
                        },
                        {
                            "id": 123018,
                            "name": "North East",
                            "latitude": "42.21561000",
                            "longitude": "-79.83422000"
                        },
                        {
                            "id": 123122,
                            "name": "North Versailles",
                            "latitude": "40.37979000",
                            "longitude": "-79.80949000"
                        },
                        {
                            "id": 123123,
                            "name": "North Wales",
                            "latitude": "40.21094000",
                            "longitude": "-75.27823000"
                        },
                        {
                            "id": 123125,
                            "name": "North Warren",
                            "latitude": "41.87423000",
                            "longitude": "-79.15227000"
                        },
                        {
                            "id": 123133,
                            "name": "North York",
                            "latitude": "39.97815000",
                            "longitude": "-76.73302000"
                        },
                        {
                            "id": 123135,
                            "name": "Northampton",
                            "latitude": "40.68621000",
                            "longitude": "-75.49685000"
                        },
                        {
                            "id": 123138,
                            "name": "Northampton County",
                            "latitude": "40.75423000",
                            "longitude": "-75.30742000"
                        },
                        {
                            "id": 123147,
                            "name": "Northern Cambria",
                            "latitude": "40.65923000",
                            "longitude": "-78.78169000"
                        },
                        {
                            "id": 123166,
                            "name": "Northumberland",
                            "latitude": "40.89175000",
                            "longitude": "-76.79747000"
                        },
                        {
                            "id": 123168,
                            "name": "Northumberland County",
                            "latitude": "40.85198000",
                            "longitude": "-76.70932000"
                        },
                        {
                            "id": 123175,
                            "name": "Northwest Harborcreek",
                            "latitude": "42.14944000",
                            "longitude": "-79.99463000"
                        },
                        {
                            "id": 123198,
                            "name": "Norwood",
                            "latitude": "39.89178000",
                            "longitude": "-75.29963000"
                        },
                        {
                            "id": 123247,
                            "name": "Oak Hills",
                            "latitude": "40.82479000",
                            "longitude": "-79.91311000"
                        },
                        {
                            "id": 123273,
                            "name": "Oakdale",
                            "latitude": "40.39812000",
                            "longitude": "-80.18561000"
                        },
                        {
                            "id": 123292,
                            "name": "Oakland",
                            "latitude": "40.30646000",
                            "longitude": "-78.88752000"
                        },
                        {
                            "id": 123302,
                            "name": "Oakmont",
                            "latitude": "40.52173000",
                            "longitude": "-79.84227000"
                        },
                        {
                            "id": 123311,
                            "name": "Oakwood",
                            "latitude": "41.01062000",
                            "longitude": "-80.37951000"
                        },
                        {
                            "id": 123385,
                            "name": "Ohioville",
                            "latitude": "40.67923000",
                            "longitude": "-80.49479000"
                        },
                        {
                            "id": 123387,
                            "name": "Oil City",
                            "latitude": "41.43395000",
                            "longitude": "-79.70644000"
                        },
                        {
                            "id": 123419,
                            "name": "Old Forge",
                            "latitude": "41.37119000",
                            "longitude": "-75.73491000"
                        },
                        {
                            "id": 123424,
                            "name": "Old Orchard",
                            "latitude": "40.65788000",
                            "longitude": "-75.26212000"
                        },
                        {
                            "id": 123436,
                            "name": "Oley",
                            "latitude": "40.38759000",
                            "longitude": "-75.78964000"
                        },
                        {
                            "id": 123442,
                            "name": "Oliver",
                            "latitude": "39.91869000",
                            "longitude": "-79.71782000"
                        },
                        {
                            "id": 123462,
                            "name": "Olyphant",
                            "latitude": "41.46841000",
                            "longitude": "-75.60297000"
                        },
                        {
                            "id": 123534,
                            "name": "Orchard Hills",
                            "latitude": "40.58618000",
                            "longitude": "-79.53143000"
                        },
                        {
                            "id": 123550,
                            "name": "Oreland",
                            "latitude": "40.11844000",
                            "longitude": "-75.17768000"
                        },
                        {
                            "id": 123585,
                            "name": "Orwigsburg",
                            "latitude": "40.65481000",
                            "longitude": "-76.10077000"
                        },
                        {
                            "id": 123606,
                            "name": "Osceola Mills",
                            "latitude": "40.85006000",
                            "longitude": "-78.27057000"
                        },
                        {
                            "id": 123680,
                            "name": "Oxford",
                            "latitude": "39.78539000",
                            "longitude": "-75.97883000"
                        },
                        {
                            "id": 123756,
                            "name": "Palmdale",
                            "latitude": "40.29787000",
                            "longitude": "-76.61858000"
                        },
                        {
                            "id": 123761,
                            "name": "Palmer Heights",
                            "latitude": "40.68732000",
                            "longitude": "-75.26240000"
                        },
                        {
                            "id": 123763,
                            "name": "Palmerton",
                            "latitude": "40.80140000",
                            "longitude": "-75.61190000"
                        },
                        {
                            "id": 123777,
                            "name": "Palmyra",
                            "latitude": "40.30898000",
                            "longitude": "-76.59330000"
                        },
                        {
                            "id": 123780,
                            "name": "Palo Alto",
                            "latitude": "40.68731000",
                            "longitude": "-76.17216000"
                        },
                        {
                            "id": 123812,
                            "name": "Paoli",
                            "latitude": "40.04205000",
                            "longitude": "-75.47631000"
                        },
                        {
                            "id": 123817,
                            "name": "Paradise",
                            "latitude": "40.00982000",
                            "longitude": "-76.12857000"
                        },
                        {
                            "id": 123846,
                            "name": "Park Forest Village",
                            "latitude": "40.80673000",
                            "longitude": "-77.91695000"
                        },
                        {
                            "id": 123869,
                            "name": "Parkesburg",
                            "latitude": "39.95872000",
                            "longitude": "-75.91939000"
                        },
                        {
                            "id": 123875,
                            "name": "Parkside",
                            "latitude": "39.86428000",
                            "longitude": "-75.37853000"
                        },
                        {
                            "id": 123879,
                            "name": "Parkville",
                            "latitude": "39.78121000",
                            "longitude": "-76.96331000"
                        },
                        {
                            "id": 123918,
                            "name": "Patton",
                            "latitude": "40.63396000",
                            "longitude": "-78.65030000"
                        },
                        {
                            "id": 123940,
                            "name": "Paxtang",
                            "latitude": "40.25898000",
                            "longitude": "-76.83192000"
                        },
                        {
                            "id": 123943,
                            "name": "Paxtonia",
                            "latitude": "40.31731000",
                            "longitude": "-76.79442000"
                        },
                        {
                            "id": 124011,
                            "name": "Pen Argyl",
                            "latitude": "40.86871000",
                            "longitude": "-75.25490000"
                        },
                        {
                            "id": 124012,
                            "name": "Penbrook",
                            "latitude": "40.27537000",
                            "longitude": "-76.84803000"
                        },
                        {
                            "id": 124022,
                            "name": "Penn Estates",
                            "latitude": "41.03750000",
                            "longitude": "-75.23956000"
                        },
                        {
                            "id": 124023,
                            "name": "Penn Hills",
                            "latitude": "40.50118000",
                            "longitude": "-79.83922000"
                        },
                        {
                            "id": 124025,
                            "name": "Penn Wynne",
                            "latitude": "39.98622000",
                            "longitude": "-75.27546000"
                        },
                        {
                            "id": 124027,
                            "name": "Penndel",
                            "latitude": "40.15205000",
                            "longitude": "-74.91656000"
                        },
                        {
                            "id": 124036,
                            "name": "Pennsburg",
                            "latitude": "40.39093000",
                            "longitude": "-75.49212000"
                        },
                        {
                            "id": 124037,
                            "name": "Pennside",
                            "latitude": "40.33732000",
                            "longitude": "-75.87854000"
                        },
                        {
                            "id": 124038,
                            "name": "Pennsport",
                            "latitude": "39.92761000",
                            "longitude": "-75.15045000"
                        },
                        {
                            "id": 124040,
                            "name": "Pennville",
                            "latitude": "39.78954000",
                            "longitude": "-76.99804000"
                        },
                        {
                            "id": 124044,
                            "name": "Penryn",
                            "latitude": "40.20509000",
                            "longitude": "-76.36829000"
                        },
                        {
                            "id": 124061,
                            "name": "Perkasie",
                            "latitude": "40.37205000",
                            "longitude": "-75.29268000"
                        },
                        {
                            "id": 124082,
                            "name": "Perry County",
                            "latitude": "40.39841000",
                            "longitude": "-77.26230000"
                        },
                        {
                            "id": 124086,
                            "name": "Perryopolis",
                            "latitude": "40.08702000",
                            "longitude": "-79.75060000"
                        },
                        {
                            "id": 124126,
                            "name": "Philadelphia",
                            "latitude": "39.95233000",
                            "longitude": "-75.16379000"
                        },
                        {
                            "id": 124128,
                            "name": "Philadelphia County",
                            "latitude": "40.00764000",
                            "longitude": "-75.13396000"
                        },
                        {
                            "id": 124131,
                            "name": "Philipsburg",
                            "latitude": "40.89645000",
                            "longitude": "-78.22057000"
                        },
                        {
                            "id": 124151,
                            "name": "Phoenixville",
                            "latitude": "40.13038000",
                            "longitude": "-75.51491000"
                        },
                        {
                            "id": 124193,
                            "name": "Pike County",
                            "latitude": "41.33199000",
                            "longitude": "-75.03383000"
                        },
                        {
                            "id": 124221,
                            "name": "Pine Grove",
                            "latitude": "40.54842000",
                            "longitude": "-76.38468000"
                        },
                        {
                            "id": 124223,
                            "name": "Pine Grove Mills",
                            "latitude": "40.73367000",
                            "longitude": "-77.88556000"
                        },
                        {
                            "id": 124244,
                            "name": "Pine Ridge",
                            "latitude": "41.14598000",
                            "longitude": "-74.99116000"
                        },
                        {
                            "id": 124285,
                            "name": "Pitcairn",
                            "latitude": "40.40312000",
                            "longitude": "-79.77810000"
                        },
                        {
                            "id": 124296,
                            "name": "Pittsburgh",
                            "latitude": "40.44062000",
                            "longitude": "-79.99589000"
                        },
                        {
                            "id": 124304,
                            "name": "Pittston",
                            "latitude": "41.32591000",
                            "longitude": "-75.78936000"
                        },
                        {
                            "id": 124323,
                            "name": "Plains",
                            "latitude": "41.27536000",
                            "longitude": "-75.85020000"
                        },
                        {
                            "id": 124364,
                            "name": "Pleasant Gap",
                            "latitude": "40.86812000",
                            "longitude": "-77.74667000"
                        },
                        {
                            "id": 124371,
                            "name": "Pleasant Hill",
                            "latitude": "40.33620000",
                            "longitude": "-76.44163000"
                        },
                        {
                            "id": 124374,
                            "name": "Pleasant Hills",
                            "latitude": "40.33563000",
                            "longitude": "-79.96061000"
                        },
                        {
                            "id": 124392,
                            "name": "Plum",
                            "latitude": "40.50035000",
                            "longitude": "-79.74949000"
                        },
                        {
                            "id": 124398,
                            "name": "Plumsteadville",
                            "latitude": "40.38733000",
                            "longitude": "-75.14656000"
                        },
                        {
                            "id": 124407,
                            "name": "Plymouth",
                            "latitude": "41.24036000",
                            "longitude": "-75.94464000"
                        },
                        {
                            "id": 124411,
                            "name": "Plymouth Meeting",
                            "latitude": "40.10233000",
                            "longitude": "-75.27435000"
                        },
                        {
                            "id": 124421,
                            "name": "Pocono Pines",
                            "latitude": "41.10675000",
                            "longitude": "-75.45435000"
                        },
                        {
                            "id": 124422,
                            "name": "Pocono Ranch Lands",
                            "latitude": "41.16454000",
                            "longitude": "-74.95212000"
                        },
                        {
                            "id": 124429,
                            "name": "Point Marion",
                            "latitude": "39.73897000",
                            "longitude": "-79.89867000"
                        },
                        {
                            "id": 124491,
                            "name": "Port Allegany",
                            "latitude": "41.81090000",
                            "longitude": "-78.27974000"
                        },
                        {
                            "id": 124501,
                            "name": "Port Carbon",
                            "latitude": "40.69648000",
                            "longitude": "-76.16883000"
                        },
                        {
                            "id": 124538,
                            "name": "Port Vue",
                            "latitude": "40.33590000",
                            "longitude": "-79.86977000"
                        },
                        {
                            "id": 124545,
                            "name": "Portage",
                            "latitude": "40.38868000",
                            "longitude": "-78.67224000"
                        },
                        {
                            "id": 124592,
                            "name": "Potter County",
                            "latitude": "41.74492000",
                            "longitude": "-77.89581000"
                        },
                        {
                            "id": 124598,
                            "name": "Pottsgrove",
                            "latitude": "40.26482000",
                            "longitude": "-75.61185000"
                        },
                        {
                            "id": 124599,
                            "name": "Pottstown",
                            "latitude": "40.24537000",
                            "longitude": "-75.64963000"
                        },
                        {
                            "id": 124601,
                            "name": "Pottsville",
                            "latitude": "40.68565000",
                            "longitude": "-76.19550000"
                        },
                        {
                            "id": 124702,
                            "name": "Progress",
                            "latitude": "40.28509000",
                            "longitude": "-76.83136000"
                        },
                        {
                            "id": 124707,
                            "name": "Prospect",
                            "latitude": "40.90451000",
                            "longitude": "-80.04645000"
                        },
                        {
                            "id": 124709,
                            "name": "Prospect Park",
                            "latitude": "39.88789000",
                            "longitude": "-75.30824000"
                        },
                        {
                            "id": 124751,
                            "name": "Punxsutawney",
                            "latitude": "40.94368000",
                            "longitude": "-78.97087000"
                        },
                        {
                            "id": 124772,
                            "name": "Pymatuning Central",
                            "latitude": "41.58546000",
                            "longitude": "-80.47960000"
                        },
                        {
                            "id": 124778,
                            "name": "Quakertown",
                            "latitude": "40.44177000",
                            "longitude": "-75.34157000"
                        },
                        {
                            "id": 124781,
                            "name": "Quarryville",
                            "latitude": "39.89705000",
                            "longitude": "-76.16357000"
                        },
                        {
                            "id": 124818,
                            "name": "Radnor",
                            "latitude": "40.04622000",
                            "longitude": "-75.35991000"
                        },
                        {
                            "id": 124883,
                            "name": "Rankin",
                            "latitude": "40.41257000",
                            "longitude": "-79.87922000"
                        },
                        {
                            "id": 124902,
                            "name": "Raubsville",
                            "latitude": "40.63566000",
                            "longitude": "-75.19295000"
                        },
                        {
                            "id": 124931,
                            "name": "Reading",
                            "latitude": "40.33565000",
                            "longitude": "-75.92687000"
                        },
                        {
                            "id": 124934,
                            "name": "Reamstown",
                            "latitude": "40.21148000",
                            "longitude": "-76.12328000"
                        },
                        {
                            "id": 124947,
                            "name": "Red Hill",
                            "latitude": "40.37288000",
                            "longitude": "-75.48101000"
                        },
                        {
                            "id": 124953,
                            "name": "Red Lion",
                            "latitude": "39.90093000",
                            "longitude": "-76.60580000"
                        },
                        {
                            "id": 125009,
                            "name": "Reiffton",
                            "latitude": "40.31981000",
                            "longitude": "-75.87354000"
                        },
                        {
                            "id": 125011,
                            "name": "Reinholds",
                            "latitude": "40.26676000",
                            "longitude": "-76.11550000"
                        },
                        {
                            "id": 125018,
                            "name": "Rennerdale",
                            "latitude": "40.39840000",
                            "longitude": "-80.14145000"
                        },
                        {
                            "id": 125022,
                            "name": "Renovo",
                            "latitude": "41.32646000",
                            "longitude": "-77.75082000"
                        },
                        {
                            "id": 125031,
                            "name": "Republic",
                            "latitude": "39.96258000",
                            "longitude": "-79.87671000"
                        },
                        {
                            "id": 125041,
                            "name": "Reynolds Heights",
                            "latitude": "41.34506000",
                            "longitude": "-80.39423000"
                        },
                        {
                            "id": 125042,
                            "name": "Reynoldsville",
                            "latitude": "41.09701000",
                            "longitude": "-78.88864000"
                        },
                        {
                            "id": 125044,
                            "name": "Rheems",
                            "latitude": "40.13009000",
                            "longitude": "-76.57052000"
                        },
                        {
                            "id": 125060,
                            "name": "Richboro",
                            "latitude": "40.21511000",
                            "longitude": "-75.01072000"
                        },
                        {
                            "id": 125071,
                            "name": "Richland",
                            "latitude": "40.35926000",
                            "longitude": "-76.25828000"
                        },
                        {
                            "id": 125083,
                            "name": "Richlandtown",
                            "latitude": "40.47010000",
                            "longitude": "-75.32046000"
                        },
                        {
                            "id": 125133,
                            "name": "Ridgway",
                            "latitude": "41.42034000",
                            "longitude": "-78.72864000"
                        },
                        {
                            "id": 125134,
                            "name": "Ridley Park",
                            "latitude": "39.88122000",
                            "longitude": "-75.32380000"
                        },
                        {
                            "id": 125189,
                            "name": "River View Park",
                            "latitude": "40.39259000",
                            "longitude": "-75.95882000"
                        },
                        {
                            "id": 125208,
                            "name": "Riverside",
                            "latitude": "40.95536000",
                            "longitude": "-76.62885000"
                        },
                        {
                            "id": 125233,
                            "name": "Roaring Spring",
                            "latitude": "40.33591000",
                            "longitude": "-78.39085000"
                        },
                        {
                            "id": 125250,
                            "name": "Robesonia",
                            "latitude": "40.35176000",
                            "longitude": "-76.13439000"
                        },
                        {
                            "id": 125268,
                            "name": "Rochester",
                            "latitude": "40.70229000",
                            "longitude": "-80.28645000"
                        },
                        {
                            "id": 125312,
                            "name": "Rockledge",
                            "latitude": "40.08122000",
                            "longitude": "-75.08962000"
                        },
                        {
                            "id": 125438,
                            "name": "Roseto",
                            "latitude": "40.88065000",
                            "longitude": "-75.21462000"
                        },
                        {
                            "id": 125466,
                            "name": "Rothsville",
                            "latitude": "40.15121000",
                            "longitude": "-76.25107000"
                        },
                        {
                            "id": 125497,
                            "name": "Royalton",
                            "latitude": "40.18731000",
                            "longitude": "-76.72997000"
                        },
                        {
                            "id": 125498,
                            "name": "Royersford",
                            "latitude": "40.18427000",
                            "longitude": "-75.53796000"
                        },
                        {
                            "id": 125535,
                            "name": "Russell",
                            "latitude": "41.94145000",
                            "longitude": "-79.13505000"
                        },
                        {
                            "id": 125541,
                            "name": "Russellton",
                            "latitude": "40.61146000",
                            "longitude": "-79.83700000"
                        },
                        {
                            "id": 125550,
                            "name": "Rutherford",
                            "latitude": "40.26898000",
                            "longitude": "-76.76803000"
                        },
                        {
                            "id": 125620,
                            "name": "Saint Clair",
                            "latitude": "40.72065000",
                            "longitude": "-76.19105000"
                        },
                        {
                            "id": 125678,
                            "name": "Saint Lawrence",
                            "latitude": "40.32704000",
                            "longitude": "-75.87187000"
                        },
                        {
                            "id": 125694,
                            "name": "Saint Marys",
                            "latitude": "41.42784000",
                            "longitude": "-78.56086000"
                        },
                        {
                            "id": 125761,
                            "name": "Salix",
                            "latitude": "40.30008000",
                            "longitude": "-78.76530000"
                        },
                        {
                            "id": 125775,
                            "name": "Salunga",
                            "latitude": "40.10093000",
                            "longitude": "-76.42469000"
                        },
                        {
                            "id": 125852,
                            "name": "Sanatoga",
                            "latitude": "40.24510000",
                            "longitude": "-75.59518000"
                        },
                        {
                            "id": 125858,
                            "name": "Sand Hill",
                            "latitude": "40.35954000",
                            "longitude": "-76.43163000"
                        },
                        {
                            "id": 125879,
                            "name": "Sandy",
                            "latitude": "41.10784000",
                            "longitude": "-78.77114000"
                        },
                        {
                            "id": 125979,
                            "name": "Saw Creek",
                            "latitude": "41.11259000",
                            "longitude": "-75.05073000"
                        },
                        {
                            "id": 125985,
                            "name": "Saxonburg",
                            "latitude": "40.75395000",
                            "longitude": "-79.81005000"
                        },
                        {
                            "id": 125987,
                            "name": "Saylorsburg",
                            "latitude": "40.89565000",
                            "longitude": "-75.32352000"
                        },
                        {
                            "id": 125990,
                            "name": "Sayre",
                            "latitude": "41.97896000",
                            "longitude": "-76.51550000"
                        },
                        {
                            "id": 126010,
                            "name": "Schlusser",
                            "latitude": "40.24175000",
                            "longitude": "-77.17693000"
                        },
                        {
                            "id": 126011,
                            "name": "Schnecksville",
                            "latitude": "40.67514000",
                            "longitude": "-75.62044000"
                        },
                        {
                            "id": 126012,
                            "name": "Schoeneck",
                            "latitude": "40.24148000",
                            "longitude": "-76.17411000"
                        },
                        {
                            "id": 126026,
                            "name": "Schuylkill County",
                            "latitude": "40.70580000",
                            "longitude": "-76.21595000"
                        },
                        {
                            "id": 126027,
                            "name": "Schuylkill Haven",
                            "latitude": "40.63065000",
                            "longitude": "-76.17105000"
                        },
                        {
                            "id": 126028,
                            "name": "Schwenksville",
                            "latitude": "40.25621000",
                            "longitude": "-75.46379000"
                        },
                        {
                            "id": 126035,
                            "name": "Scotland",
                            "latitude": "39.96870000",
                            "longitude": "-77.58721000"
                        },
                        {
                            "id": 126056,
                            "name": "Scottdale",
                            "latitude": "40.10035000",
                            "longitude": "-79.58698000"
                        },
                        {
                            "id": 126067,
                            "name": "Scranton",
                            "latitude": "41.40916000",
                            "longitude": "-75.66490000"
                        },
                        {
                            "id": 126134,
                            "name": "Selinsgrove",
                            "latitude": "40.79897000",
                            "longitude": "-76.86219000"
                        },
                        {
                            "id": 126136,
                            "name": "Sellersville",
                            "latitude": "40.35399000",
                            "longitude": "-75.30490000"
                        },
                        {
                            "id": 126158,
                            "name": "Seneca",
                            "latitude": "41.37867000",
                            "longitude": "-79.70394000"
                        },
                        {
                            "id": 126172,
                            "name": "Seven Fields",
                            "latitude": "40.69173000",
                            "longitude": "-80.06256000"
                        },
                        {
                            "id": 126191,
                            "name": "Sewickley",
                            "latitude": "40.53646000",
                            "longitude": "-80.18450000"
                        },
                        {
                            "id": 126214,
                            "name": "Shamokin",
                            "latitude": "40.78897000",
                            "longitude": "-76.55885000"
                        },
                        {
                            "id": 126215,
                            "name": "Shamokin Dam",
                            "latitude": "40.84870000",
                            "longitude": "-76.81969000"
                        },
                        {
                            "id": 126223,
                            "name": "Shanor-Northvue",
                            "latitude": "40.91045000",
                            "longitude": "-79.91562000"
                        },
                        {
                            "id": 126229,
                            "name": "Sharon",
                            "latitude": "41.23311000",
                            "longitude": "-80.49340000"
                        },
                        {
                            "id": 126231,
                            "name": "Sharon Hill",
                            "latitude": "39.90650000",
                            "longitude": "-75.27157000"
                        },
                        {
                            "id": 126236,
                            "name": "Sharpsburg",
                            "latitude": "40.49451000",
                            "longitude": "-79.92644000"
                        },
                        {
                            "id": 126237,
                            "name": "Sharpsville",
                            "latitude": "41.25922000",
                            "longitude": "-80.47201000"
                        },
                        {
                            "id": 126243,
                            "name": "Shavertown",
                            "latitude": "41.31980000",
                            "longitude": "-75.93798000"
                        },
                        {
                            "id": 126261,
                            "name": "Sheffield",
                            "latitude": "41.70395000",
                            "longitude": "-79.03560000"
                        },
                        {
                            "id": 126304,
                            "name": "Shenandoah",
                            "latitude": "40.82037000",
                            "longitude": "-76.20077000"
                        },
                        {
                            "id": 126307,
                            "name": "Shenandoah Heights",
                            "latitude": "40.82759000",
                            "longitude": "-76.20688000"
                        },
                        {
                            "id": 126347,
                            "name": "Shillington",
                            "latitude": "40.30787000",
                            "longitude": "-75.96549000"
                        },
                        {
                            "id": 126349,
                            "name": "Shiloh",
                            "latitude": "39.97815000",
                            "longitude": "-76.79719000"
                        },
                        {
                            "id": 126352,
                            "name": "Shinglehouse",
                            "latitude": "41.96368000",
                            "longitude": "-78.19084000"
                        },
                        {
                            "id": 126357,
                            "name": "Shippensburg",
                            "latitude": "40.05065000",
                            "longitude": "-77.52026000"
                        },
                        {
                            "id": 126359,
                            "name": "Shiremanstown",
                            "latitude": "40.22342000",
                            "longitude": "-76.95359000"
                        },
                        {
                            "id": 126365,
                            "name": "Shoemakersville",
                            "latitude": "40.50093000",
                            "longitude": "-75.96993000"
                        },
                        {
                            "id": 126384,
                            "name": "Shrewsbury",
                            "latitude": "39.76871000",
                            "longitude": "-76.67969000"
                        },
                        {
                            "id": 126405,
                            "name": "Sierra View",
                            "latitude": "41.01207000",
                            "longitude": "-75.45900000"
                        },
                        {
                            "id": 126448,
                            "name": "Simpson",
                            "latitude": "41.59175000",
                            "longitude": "-75.48518000"
                        },
                        {
                            "id": 126455,
                            "name": "Sinking Spring",
                            "latitude": "40.32731000",
                            "longitude": "-76.01105000"
                        },
                        {
                            "id": 126481,
                            "name": "Skippack",
                            "latitude": "40.22288000",
                            "longitude": "-75.39879000"
                        },
                        {
                            "id": 126487,
                            "name": "Skyline View",
                            "latitude": "40.33926000",
                            "longitude": "-76.72553000"
                        },
                        {
                            "id": 126492,
                            "name": "Slatington",
                            "latitude": "40.74843000",
                            "longitude": "-75.61185000"
                        },
                        {
                            "id": 126503,
                            "name": "Slippery Rock",
                            "latitude": "41.06395000",
                            "longitude": "-80.05645000"
                        },
                        {
                            "id": 126509,
                            "name": "Smethport",
                            "latitude": "41.81117000",
                            "longitude": "-78.44474000"
                        },
                        {
                            "id": 126551,
                            "name": "Snyder County",
                            "latitude": "40.76984000",
                            "longitude": "-77.07019000"
                        },
                        {
                            "id": 126584,
                            "name": "Somerset",
                            "latitude": "40.00841000",
                            "longitude": "-79.07808000"
                        },
                        {
                            "id": 126589,
                            "name": "Somerset County",
                            "latitude": "39.97244000",
                            "longitude": "-79.02827000"
                        },
                        {
                            "id": 126607,
                            "name": "Souderton",
                            "latitude": "40.31177000",
                            "longitude": "-75.32518000"
                        },
                        {
                            "id": 126640,
                            "name": "South Coatesville",
                            "latitude": "39.97427000",
                            "longitude": "-75.81995000"
                        },
                        {
                            "id": 126642,
                            "name": "South Connellsville",
                            "latitude": "39.99674000",
                            "longitude": "-79.58587000"
                        },
                        {
                            "id": 126663,
                            "name": "South Greensburg",
                            "latitude": "40.27840000",
                            "longitude": "-79.54476000"
                        },
                        {
                            "id": 126704,
                            "name": "South Park Township",
                            "latitude": "40.29864000",
                            "longitude": "-79.99405000"
                        },
                        {
                            "id": 126715,
                            "name": "South Pottstown",
                            "latitude": "40.23954000",
                            "longitude": "-75.65102000"
                        },
                        {
                            "id": 126734,
                            "name": "South Temple",
                            "latitude": "40.40000000",
                            "longitude": "-75.90000000"
                        },
                        {
                            "id": 126738,
                            "name": "South Uniontown",
                            "latitude": "39.89285000",
                            "longitude": "-79.74699000"
                        },
                        {
                            "id": 126744,
                            "name": "South Waverly",
                            "latitude": "41.99757000",
                            "longitude": "-76.53717000"
                        },
                        {
                            "id": 126750,
                            "name": "South Williamsport",
                            "latitude": "41.23202000",
                            "longitude": "-76.99913000"
                        },
                        {
                            "id": 126780,
                            "name": "Southmont",
                            "latitude": "40.31063000",
                            "longitude": "-78.93864000"
                        },
                        {
                            "id": 126789,
                            "name": "Southwest Greensburg",
                            "latitude": "40.29118000",
                            "longitude": "-79.54698000"
                        },
                        {
                            "id": 126797,
                            "name": "Spangler",
                            "latitude": "40.64285000",
                            "longitude": "-78.77280000"
                        },
                        {
                            "id": 126819,
                            "name": "Speers",
                            "latitude": "40.12452000",
                            "longitude": "-79.87977000"
                        },
                        {
                            "id": 126837,
                            "name": "Spinnerstown",
                            "latitude": "40.43899000",
                            "longitude": "-75.43712000"
                        },
                        {
                            "id": 126852,
                            "name": "Spring City",
                            "latitude": "40.17677000",
                            "longitude": "-75.54769000"
                        },
                        {
                            "id": 126857,
                            "name": "Spring Grove",
                            "latitude": "39.87454000",
                            "longitude": "-76.86581000"
                        },
                        {
                            "id": 126864,
                            "name": "Spring House",
                            "latitude": "40.18539000",
                            "longitude": "-75.22768000"
                        },
                        {
                            "id": 126870,
                            "name": "Spring Mount",
                            "latitude": "40.27566000",
                            "longitude": "-75.45657000"
                        },
                        {
                            "id": 126873,
                            "name": "Spring Ridge",
                            "latitude": "40.35287000",
                            "longitude": "-75.98994000"
                        },
                        {
                            "id": 126887,
                            "name": "Springdale",
                            "latitude": "40.54090000",
                            "longitude": "-79.78394000"
                        },
                        {
                            "id": 126894,
                            "name": "Springfield",
                            "latitude": "39.93067000",
                            "longitude": "-75.32019000"
                        },
                        {
                            "id": 126920,
                            "name": "Spry",
                            "latitude": "39.91843000",
                            "longitude": "-76.68497000"
                        },
                        {
                            "id": 126992,
                            "name": "State College",
                            "latitude": "40.79339000",
                            "longitude": "-77.86000000"
                        },
                        {
                            "id": 126993,
                            "name": "State Line",
                            "latitude": "39.72482000",
                            "longitude": "-77.72444000"
                        },
                        {
                            "id": 127013,
                            "name": "Steelton",
                            "latitude": "40.23537000",
                            "longitude": "-76.84136000"
                        },
                        {
                            "id": 127053,
                            "name": "Stewartstown",
                            "latitude": "39.75371000",
                            "longitude": "-76.59136000"
                        },
                        {
                            "id": 127058,
                            "name": "Stiles",
                            "latitude": "40.66537000",
                            "longitude": "-75.50824000"
                        },
                        {
                            "id": 127086,
                            "name": "Stoneboro",
                            "latitude": "41.33922000",
                            "longitude": "-80.10506000"
                        },
                        {
                            "id": 127097,
                            "name": "Stony Creek Mills",
                            "latitude": "40.34565000",
                            "longitude": "-75.86993000"
                        },
                        {
                            "id": 127101,
                            "name": "Stonybrook",
                            "latitude": "39.98704000",
                            "longitude": "-76.64413000"
                        },
                        {
                            "id": 127104,
                            "name": "Stormstown",
                            "latitude": "40.79339000",
                            "longitude": "-78.01667000"
                        },
                        {
                            "id": 127113,
                            "name": "Stowe",
                            "latitude": "40.25260000",
                            "longitude": "-75.67741000"
                        },
                        {
                            "id": 127119,
                            "name": "Strasburg",
                            "latitude": "39.98316000",
                            "longitude": "-76.18412000"
                        },
                        {
                            "id": 127140,
                            "name": "Stroudsburg",
                            "latitude": "40.98676000",
                            "longitude": "-75.19462000"
                        },
                        {
                            "id": 127148,
                            "name": "Sturgeon",
                            "latitude": "40.38479000",
                            "longitude": "-80.21089000"
                        },
                        {
                            "id": 127174,
                            "name": "Sugarcreek",
                            "latitude": "41.42145000",
                            "longitude": "-79.88117000"
                        },
                        {
                            "id": 127191,
                            "name": "Sullivan County",
                            "latitude": "41.44616000",
                            "longitude": "-76.51214000"
                        },
                        {
                            "id": 127218,
                            "name": "Summit Hill",
                            "latitude": "40.82481000",
                            "longitude": "-75.87103000"
                        },
                        {
                            "id": 127239,
                            "name": "Sun Valley",
                            "latitude": "40.98203000",
                            "longitude": "-75.46602000"
                        },
                        {
                            "id": 127245,
                            "name": "Sunbury",
                            "latitude": "40.86259000",
                            "longitude": "-76.79441000"
                        },
                        {
                            "id": 127268,
                            "name": "Sunrise Lake",
                            "latitude": "41.30981000",
                            "longitude": "-74.96656000"
                        },
                        {
                            "id": 127297,
                            "name": "Susquehanna",
                            "latitude": "41.94341000",
                            "longitude": "-75.59963000"
                        },
                        {
                            "id": 127298,
                            "name": "Susquehanna County",
                            "latitude": "41.82133000",
                            "longitude": "-75.80068000"
                        },
                        {
                            "id": 127299,
                            "name": "Susquehanna Trails",
                            "latitude": "39.75872000",
                            "longitude": "-76.36802000"
                        },
                        {
                            "id": 127330,
                            "name": "Swarthmore",
                            "latitude": "39.90206000",
                            "longitude": "-75.34991000"
                        },
                        {
                            "id": 127333,
                            "name": "Swartzville",
                            "latitude": "40.23315000",
                            "longitude": "-76.07828000"
                        },
                        {
                            "id": 127348,
                            "name": "Swissvale",
                            "latitude": "40.42368000",
                            "longitude": "-79.88283000"
                        },
                        {
                            "id": 127350,
                            "name": "Swoyersville",
                            "latitude": "41.29175000",
                            "longitude": "-75.87464000"
                        },
                        {
                            "id": 127353,
                            "name": "Sykesville",
                            "latitude": "41.05034000",
                            "longitude": "-78.82225000"
                        },
                        {
                            "id": 127370,
                            "name": "Tacony",
                            "latitude": "40.03122000",
                            "longitude": "-75.04434000"
                        },
                        {
                            "id": 127404,
                            "name": "Tamaqua",
                            "latitude": "40.79731000",
                            "longitude": "-75.96937000"
                        },
                        {
                            "id": 127419,
                            "name": "Tannersville",
                            "latitude": "41.04009000",
                            "longitude": "-75.30574000"
                        },
                        {
                            "id": 127429,
                            "name": "Tarentum",
                            "latitude": "40.60146000",
                            "longitude": "-79.75977000"
                        },
                        {
                            "id": 127437,
                            "name": "Tatamy",
                            "latitude": "40.74093000",
                            "longitude": "-75.25712000"
                        },
                        {
                            "id": 127449,
                            "name": "Taylor",
                            "latitude": "41.39480000",
                            "longitude": "-75.70658000"
                        },
                        {
                            "id": 127487,
                            "name": "Telford",
                            "latitude": "40.32205000",
                            "longitude": "-75.32795000"
                        },
                        {
                            "id": 127500,
                            "name": "Temple",
                            "latitude": "40.40870000",
                            "longitude": "-75.92160000"
                        },
                        {
                            "id": 127522,
                            "name": "Terre Hill",
                            "latitude": "40.15732000",
                            "longitude": "-76.05050000"
                        },
                        {
                            "id": 127560,
                            "name": "The Hideout",
                            "latitude": "41.42736000",
                            "longitude": "-75.35255000"
                        },
                        {
                            "id": 127595,
                            "name": "Thompsonville",
                            "latitude": "40.29090000",
                            "longitude": "-80.10811000"
                        },
                        {
                            "id": 127599,
                            "name": "Thorndale",
                            "latitude": "39.99288000",
                            "longitude": "-75.74522000"
                        },
                        {
                            "id": 127626,
                            "name": "Throop",
                            "latitude": "41.45147000",
                            "longitude": "-75.61185000"
                        },
                        {
                            "id": 127665,
                            "name": "Tinicum",
                            "latitude": "40.44844000",
                            "longitude": "-75.10767000"
                        },
                        {
                            "id": 127670,
                            "name": "Tioga County",
                            "latitude": "41.77216000",
                            "longitude": "-77.25426000"
                        },
                        {
                            "id": 127671,
                            "name": "Tionesta",
                            "latitude": "41.49534000",
                            "longitude": "-79.45588000"
                        },
                        {
                            "id": 127677,
                            "name": "Tipton",
                            "latitude": "40.63590000",
                            "longitude": "-78.29585000"
                        },
                        {
                            "id": 127686,
                            "name": "Titusville",
                            "latitude": "41.62700000",
                            "longitude": "-79.67366000"
                        },
                        {
                            "id": 127696,
                            "name": "Toftrees",
                            "latitude": "40.82604000",
                            "longitude": "-77.88110000"
                        },
                        {
                            "id": 127737,
                            "name": "Topton",
                            "latitude": "40.50343000",
                            "longitude": "-75.70130000"
                        },
                        {
                            "id": 127748,
                            "name": "Toughkenamon",
                            "latitude": "39.83150000",
                            "longitude": "-75.75744000"
                        },
                        {
                            "id": 127750,
                            "name": "Towamensing Trails",
                            "latitude": "41.00787000",
                            "longitude": "-75.58463000"
                        },
                        {
                            "id": 127752,
                            "name": "Towanda",
                            "latitude": "41.76758000",
                            "longitude": "-76.44272000"
                        },
                        {
                            "id": 127754,
                            "name": "Tower City",
                            "latitude": "40.58925000",
                            "longitude": "-76.55246000"
                        },
                        {
                            "id": 127776,
                            "name": "Trafford",
                            "latitude": "40.38562000",
                            "longitude": "-79.75893000"
                        },
                        {
                            "id": 127779,
                            "name": "Trainer",
                            "latitude": "39.82761000",
                            "longitude": "-75.41436000"
                        },
                        {
                            "id": 127782,
                            "name": "Trappe",
                            "latitude": "40.19899000",
                            "longitude": "-75.47629000"
                        },
                        {
                            "id": 127791,
                            "name": "Treasure Lake",
                            "latitude": "41.17339000",
                            "longitude": "-78.71586000"
                        },
                        {
                            "id": 127796,
                            "name": "Tremont",
                            "latitude": "40.62842000",
                            "longitude": "-76.38718000"
                        },
                        {
                            "id": 127813,
                            "name": "Trevorton",
                            "latitude": "40.78120000",
                            "longitude": "-76.67302000"
                        },
                        {
                            "id": 127814,
                            "name": "Trevose",
                            "latitude": "40.13928000",
                            "longitude": "-74.98100000"
                        },
                        {
                            "id": 127815,
                            "name": "Trexlertown",
                            "latitude": "40.54815000",
                            "longitude": "-75.60574000"
                        },
                        {
                            "id": 127833,
                            "name": "Trooper",
                            "latitude": "40.14983000",
                            "longitude": "-75.40185000"
                        },
                        {
                            "id": 127852,
                            "name": "Troy",
                            "latitude": "41.78591000",
                            "longitude": "-76.78801000"
                        },
                        {
                            "id": 127854,
                            "name": "Trucksville",
                            "latitude": "41.30397000",
                            "longitude": "-75.93214000"
                        },
                        {
                            "id": 127887,
                            "name": "Tullytown",
                            "latitude": "40.13928000",
                            "longitude": "-74.81461000"
                        },
                        {
                            "id": 127894,
                            "name": "Tunkhannock",
                            "latitude": "41.53869000",
                            "longitude": "-75.94659000"
                        },
                        {
                            "id": 127907,
                            "name": "Turtle Creek",
                            "latitude": "40.40590000",
                            "longitude": "-79.82505000"
                        },
                        {
                            "id": 127950,
                            "name": "Tyrone",
                            "latitude": "40.67062000",
                            "longitude": "-78.23862000"
                        },
                        {
                            "id": 127986,
                            "name": "Union City",
                            "latitude": "41.89950000",
                            "longitude": "-79.84533000"
                        },
                        {
                            "id": 128000,
                            "name": "Union County",
                            "latitude": "40.96297000",
                            "longitude": "-77.06225000"
                        },
                        {
                            "id": 128016,
                            "name": "Uniontown",
                            "latitude": "39.90008000",
                            "longitude": "-79.71643000"
                        },
                        {
                            "id": 128037,
                            "name": "Upland",
                            "latitude": "39.85261000",
                            "longitude": "-75.38269000"
                        },
                        {
                            "id": 128051,
                            "name": "Upper Saint Clair",
                            "latitude": "40.33590000",
                            "longitude": "-80.08339000"
                        },
                        {
                            "id": 128111,
                            "name": "Valley Green",
                            "latitude": "40.15731000",
                            "longitude": "-76.79275000"
                        },
                        {
                            "id": 128118,
                            "name": "Valley View",
                            "latitude": "39.95010000",
                            "longitude": "-76.70108000"
                        },
                        {
                            "id": 128152,
                            "name": "Vandergrift",
                            "latitude": "40.60284000",
                            "longitude": "-79.56477000"
                        },
                        {
                            "id": 128166,
                            "name": "Venango County",
                            "latitude": "41.40097000",
                            "longitude": "-79.75795000"
                        },
                        {
                            "id": 128204,
                            "name": "Verona",
                            "latitude": "40.50646000",
                            "longitude": "-79.84310000"
                        },
                        {
                            "id": 128211,
                            "name": "Versailles",
                            "latitude": "40.31563000",
                            "longitude": "-79.83116000"
                        },
                        {
                            "id": 128248,
                            "name": "Village Green-Green Ridge",
                            "latitude": "39.86363000",
                            "longitude": "-75.42548000"
                        },
                        {
                            "id": 128251,
                            "name": "Village Shires",
                            "latitude": "40.20316000",
                            "longitude": "-74.97045000"
                        },
                        {
                            "id": 128267,
                            "name": "Vinco",
                            "latitude": "40.40507000",
                            "longitude": "-78.85558000"
                        },
                        {
                            "id": 128402,
                            "name": "Wallenpaupack Lake Estates",
                            "latitude": "41.39898000",
                            "longitude": "-75.27402000"
                        },
                        {
                            "id": 128424,
                            "name": "Walnutport",
                            "latitude": "40.75426000",
                            "longitude": "-75.59880000"
                        },
                        {
                            "id": 128472,
                            "name": "Warminster Heights",
                            "latitude": "40.18705000",
                            "longitude": "-75.08156000"
                        },
                        {
                            "id": 128483,
                            "name": "Warren",
                            "latitude": "41.84395000",
                            "longitude": "-79.14504000"
                        },
                        {
                            "id": 128498,
                            "name": "Warren County",
                            "latitude": "41.81457000",
                            "longitude": "-79.27414000"
                        },
                        {
                            "id": 128549,
                            "name": "Washington",
                            "latitude": "40.17396000",
                            "longitude": "-80.24617000"
                        },
                        {
                            "id": 128573,
                            "name": "Washington County",
                            "latitude": "40.18940000",
                            "longitude": "-80.24824000"
                        },
                        {
                            "id": 128607,
                            "name": "Waterford",
                            "latitude": "41.94283000",
                            "longitude": "-79.98450000"
                        },
                        {
                            "id": 128640,
                            "name": "Watsontown",
                            "latitude": "41.08453000",
                            "longitude": "-76.86385000"
                        },
                        {
                            "id": 128680,
                            "name": "Waymart",
                            "latitude": "41.58036000",
                            "longitude": "-75.40824000"
                        },
                        {
                            "id": 128687,
                            "name": "Wayne",
                            "latitude": "40.04400000",
                            "longitude": "-75.38769000"
                        },
                        {
                            "id": 128702,
                            "name": "Wayne County",
                            "latitude": "41.64873000",
                            "longitude": "-75.30326000"
                        },
                        {
                            "id": 128704,
                            "name": "Wayne Heights",
                            "latitude": "39.74371000",
                            "longitude": "-77.55388000"
                        },
                        {
                            "id": 128707,
                            "name": "Waynesboro",
                            "latitude": "39.75593000",
                            "longitude": "-77.57777000"
                        },
                        {
                            "id": 128710,
                            "name": "Waynesburg",
                            "latitude": "39.89646000",
                            "longitude": "-80.17923000"
                        },
                        {
                            "id": 128719,
                            "name": "Weatherly",
                            "latitude": "40.94175000",
                            "longitude": "-75.82964000"
                        },
                        {
                            "id": 128757,
                            "name": "Weigelstown",
                            "latitude": "39.98371000",
                            "longitude": "-76.82247000"
                        },
                        {
                            "id": 128762,
                            "name": "Weissport East",
                            "latitude": "40.83697000",
                            "longitude": "-75.68643000"
                        },
                        {
                            "id": 128788,
                            "name": "Wellsboro",
                            "latitude": "41.74868000",
                            "longitude": "-77.30053000"
                        },
                        {
                            "id": 128806,
                            "name": "Wernersville",
                            "latitude": "40.33009000",
                            "longitude": "-76.08050000"
                        },
                        {
                            "id": 128807,
                            "name": "Wescosville",
                            "latitude": "40.56676000",
                            "longitude": "-75.55296000"
                        },
                        {
                            "id": 128812,
                            "name": "Wesleyville",
                            "latitude": "42.14033000",
                            "longitude": "-80.01506000"
                        },
                        {
                            "id": 128845,
                            "name": "West Chester",
                            "latitude": "39.96097000",
                            "longitude": "-75.60804000"
                        },
                        {
                            "id": 128851,
                            "name": "West Conshohocken",
                            "latitude": "40.06983000",
                            "longitude": "-75.31630000"
                        },
                        {
                            "id": 128859,
                            "name": "West Easton",
                            "latitude": "40.67871000",
                            "longitude": "-75.23684000"
                        },
                        {
                            "id": 128866,
                            "name": "West Fairview",
                            "latitude": "40.27509000",
                            "longitude": "-76.91553000"
                        },
                        {
                            "id": 128881,
                            "name": "West Grove",
                            "latitude": "39.82205000",
                            "longitude": "-75.82744000"
                        },
                        {
                            "id": 128883,
                            "name": "West Hamburg",
                            "latitude": "40.54759000",
                            "longitude": "-76.00216000"
                        },
                        {
                            "id": 128892,
                            "name": "West Hazleton",
                            "latitude": "40.95870000",
                            "longitude": "-75.99604000"
                        },
                        {
                            "id": 128896,
                            "name": "West Hills",
                            "latitude": "40.82423000",
                            "longitude": "-79.54310000"
                        },
                        {
                            "id": 128900,
                            "name": "West Homestead",
                            "latitude": "40.39396000",
                            "longitude": "-79.91199000"
                        },
                        {
                            "id": 128907,
                            "name": "West Kittanning",
                            "latitude": "40.81034000",
                            "longitude": "-79.52949000"
                        },
                        {
                            "id": 128914,
                            "name": "West Lawn",
                            "latitude": "40.32981000",
                            "longitude": "-75.99438000"
                        },
                        {
                            "id": 128915,
                            "name": "West Leechburg",
                            "latitude": "40.62229000",
                            "longitude": "-79.61282000"
                        },
                        {
                            "id": 128926,
                            "name": "West Mayfield",
                            "latitude": "40.78006000",
                            "longitude": "-80.33840000"
                        },
                        {
                            "id": 128931,
                            "name": "West Mifflin",
                            "latitude": "40.36340000",
                            "longitude": "-79.86644000"
                        },
                        {
                            "id": 128940,
                            "name": "West Newton",
                            "latitude": "40.20979000",
                            "longitude": "-79.76699000"
                        },
                        {
                            "id": 128941,
                            "name": "West Norriton",
                            "latitude": "40.12955000",
                            "longitude": "-75.37852000"
                        },
                        {
                            "id": 128955,
                            "name": "West Pittston",
                            "latitude": "41.32758000",
                            "longitude": "-75.79297000"
                        },
                        {
                            "id": 128967,
                            "name": "West Reading",
                            "latitude": "40.33370000",
                            "longitude": "-75.94743000"
                        },
                        {
                            "id": 128999,
                            "name": "West View",
                            "latitude": "40.52229000",
                            "longitude": "-80.03422000"
                        },
                        {
                            "id": 129006,
                            "name": "West Wyoming",
                            "latitude": "41.31980000",
                            "longitude": "-75.84603000"
                        },
                        {
                            "id": 129007,
                            "name": "West Wyomissing",
                            "latitude": "40.32454000",
                            "longitude": "-75.99077000"
                        },
                        {
                            "id": 129010,
                            "name": "West York",
                            "latitude": "39.95260000",
                            "longitude": "-76.75136000"
                        },
                        {
                            "id": 129031,
                            "name": "Westfield",
                            "latitude": "41.91924000",
                            "longitude": "-77.53887000"
                        },
                        {
                            "id": 129050,
                            "name": "Westmont",
                            "latitude": "40.31563000",
                            "longitude": "-78.95169000"
                        },
                        {
                            "id": 129056,
                            "name": "Westmoreland County",
                            "latitude": "40.31073000",
                            "longitude": "-79.46696000"
                        },
                        {
                            "id": 129101,
                            "name": "Wharton",
                            "latitude": "39.92678000",
                            "longitude": "-75.15712000"
                        },
                        {
                            "id": 129125,
                            "name": "Whitaker",
                            "latitude": "40.39840000",
                            "longitude": "-79.88977000"
                        },
                        {
                            "id": 129141,
                            "name": "White Haven",
                            "latitude": "41.06064000",
                            "longitude": "-75.77408000"
                        },
                        {
                            "id": 129150,
                            "name": "White Oak",
                            "latitude": "40.33757000",
                            "longitude": "-79.80921000"
                        },
                        {
                            "id": 129170,
                            "name": "Whitehall",
                            "latitude": "40.36118000",
                            "longitude": "-79.99089000"
                        },
                        {
                            "id": 129173,
                            "name": "Whitehall Township",
                            "latitude": "40.66676000",
                            "longitude": "-75.49991000"
                        },
                        {
                            "id": 129194,
                            "name": "Whitfield",
                            "latitude": "40.33593000",
                            "longitude": "-76.00605000"
                        },
                        {
                            "id": 129202,
                            "name": "Whitman",
                            "latitude": "39.91678000",
                            "longitude": "-75.15546000"
                        },
                        {
                            "id": 129220,
                            "name": "Wickerham Manor-Fisher",
                            "latitude": "40.17749000",
                            "longitude": "-79.90684000"
                        },
                        {
                            "id": 129246,
                            "name": "Wilkes-Barre",
                            "latitude": "41.24591000",
                            "longitude": "-75.88131000"
                        },
                        {
                            "id": 129249,
                            "name": "Wilkinsburg",
                            "latitude": "40.44174000",
                            "longitude": "-79.88199000"
                        },
                        {
                            "id": 129271,
                            "name": "Williamsburg",
                            "latitude": "40.46202000",
                            "longitude": "-78.19973000"
                        },
                        {
                            "id": 129281,
                            "name": "Williamsport",
                            "latitude": "41.24119000",
                            "longitude": "-77.00108000"
                        },
                        {
                            "id": 129290,
                            "name": "Williamstown",
                            "latitude": "40.58009000",
                            "longitude": "-76.61774000"
                        },
                        {
                            "id": 129306,
                            "name": "Willow Grove",
                            "latitude": "40.14400000",
                            "longitude": "-75.11573000"
                        },
                        {
                            "id": 129311,
                            "name": "Willow Street",
                            "latitude": "39.97927000",
                            "longitude": "-76.27635000"
                        },
                        {
                            "id": 129319,
                            "name": "Wilmerding",
                            "latitude": "40.39090000",
                            "longitude": "-79.81005000"
                        },
                        {
                            "id": 129332,
                            "name": "Wilson",
                            "latitude": "40.68399000",
                            "longitude": "-75.24184000"
                        },
                        {
                            "id": 129363,
                            "name": "Wind Gap",
                            "latitude": "40.84815000",
                            "longitude": "-75.29157000"
                        },
                        {
                            "id": 129366,
                            "name": "Windber",
                            "latitude": "40.23980000",
                            "longitude": "-78.83502000"
                        },
                        {
                            "id": 129380,
                            "name": "Windsor",
                            "latitude": "39.91621000",
                            "longitude": "-76.58441000"
                        },
                        {
                            "id": 129475,
                            "name": "Wolfdale",
                            "latitude": "40.19285000",
                            "longitude": "-80.28784000"
                        },
                        {
                            "id": 129482,
                            "name": "Womelsdorf",
                            "latitude": "40.36176000",
                            "longitude": "-76.18411000"
                        },
                        {
                            "id": 129497,
                            "name": "Woodbourne",
                            "latitude": "40.19233000",
                            "longitude": "-74.88878000"
                        },
                        {
                            "id": 129529,
                            "name": "Woodland Heights",
                            "latitude": "41.40978000",
                            "longitude": "-79.71172000"
                        },
                        {
                            "id": 129539,
                            "name": "Woodlyn",
                            "latitude": "39.87233000",
                            "longitude": "-75.33713000"
                        },
                        {
                            "id": 129555,
                            "name": "Woodside",
                            "latitude": "40.22178000",
                            "longitude": "-74.87544000"
                        },
                        {
                            "id": 129590,
                            "name": "Wormleysburg",
                            "latitude": "40.26287000",
                            "longitude": "-76.91386000"
                        },
                        {
                            "id": 129600,
                            "name": "Woxall",
                            "latitude": "40.31066000",
                            "longitude": "-75.44879000"
                        },
                        {
                            "id": 129615,
                            "name": "Wrightsville",
                            "latitude": "40.02565000",
                            "longitude": "-76.52997000"
                        },
                        {
                            "id": 129629,
                            "name": "Wyncote",
                            "latitude": "40.09455000",
                            "longitude": "-75.14879000"
                        },
                        {
                            "id": 129631,
                            "name": "Wyndmoor",
                            "latitude": "40.08122000",
                            "longitude": "-75.18934000"
                        },
                        {
                            "id": 129638,
                            "name": "Wyoming",
                            "latitude": "41.31175000",
                            "longitude": "-75.83742000"
                        },
                        {
                            "id": 129641,
                            "name": "Wyoming County",
                            "latitude": "41.51833000",
                            "longitude": "-76.01655000"
                        },
                        {
                            "id": 129642,
                            "name": "Wyomissing",
                            "latitude": "40.32954000",
                            "longitude": "-75.96521000"
                        },
                        {
                            "id": 129643,
                            "name": "Wyomissing Hills",
                            "latitude": "40.33759000",
                            "longitude": "-75.97966000"
                        },
                        {
                            "id": 129664,
                            "name": "Yardley",
                            "latitude": "40.24566000",
                            "longitude": "-74.84600000"
                        },
                        {
                            "id": 129675,
                            "name": "Yeadon",
                            "latitude": "39.93900000",
                            "longitude": "-75.25546000"
                        },
                        {
                            "id": 129676,
                            "name": "Yeagertown",
                            "latitude": "40.64313000",
                            "longitude": "-77.58055000"
                        },
                        {
                            "id": 129685,
                            "name": "Yoe",
                            "latitude": "39.90899000",
                            "longitude": "-76.63691000"
                        },
                        {
                            "id": 129692,
                            "name": "York",
                            "latitude": "39.96260000",
                            "longitude": "-76.72774000"
                        },
                        {
                            "id": 129699,
                            "name": "York County",
                            "latitude": "39.91996000",
                            "longitude": "-76.72651000"
                        },
                        {
                            "id": 129702,
                            "name": "Yorklyn",
                            "latitude": "39.99232000",
                            "longitude": "-76.64635000"
                        },
                        {
                            "id": 129720,
                            "name": "Youngsville",
                            "latitude": "41.85228000",
                            "longitude": "-79.31866000"
                        },
                        {
                            "id": 129722,
                            "name": "Youngwood",
                            "latitude": "40.24035000",
                            "longitude": "-79.57671000"
                        },
                        {
                            "id": 129746,
                            "name": "Zelienople",
                            "latitude": "40.79451000",
                            "longitude": "-80.13673000"
                        },
                        {
                            "id": 129758,
                            "name": "Zion",
                            "latitude": "40.91423000",
                            "longitude": "-77.68472000"
                        }
                    ]
                },
                {
                    "id": 1449,
                    "name": "Puerto Rico",
                    "state_code": "PR",
                    "cities": [
                        {
                            "id": 142982,
                            "name": "Adjuntas",
                            "latitude": "18.16277778",
                            "longitude": "-66.72222222"
                        },
                        {
                            "id": 142983,
                            "name": "Aguada",
                            "latitude": "18.37944444",
                            "longitude": "-67.18833333"
                        },
                        {
                            "id": 142984,
                            "name": "Aguadilla",
                            "latitude": "18.42745000",
                            "longitude": "-67.15407000"
                        },
                        {
                            "id": 142985,
                            "name": "Aguas Buenas",
                            "latitude": "18.25694400",
                            "longitude": "-66.10305600"
                        },
                        {
                            "id": 142986,
                            "name": "Aguas Claras",
                            "latitude": "18.24417000",
                            "longitude": "-65.66278000"
                        },
                        {
                            "id": 142987,
                            "name": "Aguilita",
                            "latitude": "18.02333333",
                            "longitude": "-66.53472222"
                        },
                        {
                            "id": 142988,
                            "name": "Aibonito",
                            "latitude": "18.14000000",
                            "longitude": "-66.26611100"
                        },
                        {
                            "id": 142993,
                            "name": "Añasco",
                            "latitude": "18.31611111",
                            "longitude": "-67.13972222"
                        },
                        {
                            "id": 142989,
                            "name": "Animas",
                            "latitude": "18.44555556",
                            "longitude": "-66.63500000"
                        },
                        {
                            "id": 142990,
                            "name": "Antón Ruiz",
                            "latitude": "18.18527778",
                            "longitude": "-65.80861111"
                        },
                        {
                            "id": 142991,
                            "name": "Arecibo",
                            "latitude": "18.47055556",
                            "longitude": "-66.72083333"
                        },
                        {
                            "id": 142992,
                            "name": "Arroyo",
                            "latitude": "17.96583300",
                            "longitude": "-66.06138900"
                        },
                        {
                            "id": 142994,
                            "name": "Bairoa",
                            "latitude": "18.25916667",
                            "longitude": "-66.04055556"
                        },
                        {
                            "id": 142995,
                            "name": "Bajadero",
                            "latitude": "18.42666667",
                            "longitude": "-66.68333333"
                        },
                        {
                            "id": 142996,
                            "name": "Bajandas",
                            "latitude": "18.16278000",
                            "longitude": "-65.78167000"
                        },
                        {
                            "id": 142997,
                            "name": "Barahona",
                            "latitude": "18.35138889",
                            "longitude": "-66.44555556"
                        },
                        {
                            "id": 142998,
                            "name": "Barceloneta",
                            "latitude": "18.45633000",
                            "longitude": "-66.54128000"
                        },
                        {
                            "id": 142999,
                            "name": "Barranquitas",
                            "latitude": "18.20357000",
                            "longitude": "-66.31211000"
                        },
                        {
                            "id": 143000,
                            "name": "Bartolo",
                            "latitude": "18.36138889",
                            "longitude": "-65.83861111"
                        },
                        {
                            "id": 143001,
                            "name": "Bayamon",
                            "latitude": "18.17777778",
                            "longitude": "-66.11333333"
                        },
                        {
                            "id": 143002,
                            "name": "Benitez",
                            "latitude": "18.27361111",
                            "longitude": "-65.87916667"
                        },
                        {
                            "id": 143003,
                            "name": "Betances",
                            "latitude": "18.02861111",
                            "longitude": "-67.13500000"
                        },
                        {
                            "id": 143004,
                            "name": "Boqueron",
                            "latitude": "18.20750000",
                            "longitude": "-65.84861111"
                        },
                        {
                            "id": 143005,
                            "name": "Boquerón, Cabo Rojo",
                            "latitude": "18.02691000",
                            "longitude": "-67.16907000"
                        },
                        {
                            "id": 143006,
                            "name": "Brenas",
                            "latitude": "18.46722222",
                            "longitude": "-66.34111111"
                        },
                        {
                            "id": 143007,
                            "name": "Buena Vista",
                            "latitude": "17.99638889",
                            "longitude": "-66.05194444"
                        },
                        {
                            "id": 143008,
                            "name": "Bufalo",
                            "latitude": "18.41833333",
                            "longitude": "-66.57333333"
                        },
                        {
                            "id": 143009,
                            "name": "Caban",
                            "latitude": "18.44333333",
                            "longitude": "-67.13611111"
                        },
                        {
                            "id": 143010,
                            "name": "Cabo Rojo",
                            "latitude": "18.08666667",
                            "longitude": "-67.14583333"
                        },
                        {
                            "id": 143011,
                            "name": "Cabo Rojo Municipio",
                            "latitude": "18.05635000",
                            "longitude": "-67.14685000"
                        },
                        {
                            "id": 143012,
                            "name": "Cacao",
                            "latitude": "18.44028000",
                            "longitude": "-66.93861000"
                        },
                        {
                            "id": 143013,
                            "name": "Caguas",
                            "latitude": "18.23333333",
                            "longitude": "-66.03333333"
                        },
                        {
                            "id": 143014,
                            "name": "Campanilla",
                            "latitude": "18.42138889",
                            "longitude": "-66.23694444"
                        },
                        {
                            "id": 143015,
                            "name": "Campo Rico",
                            "latitude": "18.33722222",
                            "longitude": "-65.89805556"
                        },
                        {
                            "id": 143016,
                            "name": "Camuy",
                            "latitude": "18.48388900",
                            "longitude": "-66.84500000"
                        },
                        {
                            "id": 143017,
                            "name": "Candelaria",
                            "latitude": "18.40416667",
                            "longitude": "-66.20888889"
                        },
                        {
                            "id": 143018,
                            "name": "Candelaria Arenas",
                            "latitude": "18.41722222",
                            "longitude": "-66.21750000"
                        },
                        {
                            "id": 143019,
                            "name": "Candelero Arriba",
                            "latitude": "18.10560000",
                            "longitude": "-65.83420000"
                        },
                        {
                            "id": 143020,
                            "name": "Canóvanas",
                            "latitude": "18.37916700",
                            "longitude": "-65.90138900"
                        },
                        {
                            "id": 143021,
                            "name": "Capitanejo",
                            "latitude": "18.01454000",
                            "longitude": "-66.53372000"
                        },
                        {
                            "id": 143022,
                            "name": "Carolina",
                            "latitude": "18.38888889",
                            "longitude": "-65.96666667"
                        },
                        {
                            "id": 143023,
                            "name": "Carrizales",
                            "latitude": "18.48194444",
                            "longitude": "-66.79000000"
                        },
                        {
                            "id": 143024,
                            "name": "Cataño",
                            "latitude": "18.44134000",
                            "longitude": "-66.11822000"
                        },
                        {
                            "id": 143025,
                            "name": "Cayey",
                            "latitude": "18.11500000",
                            "longitude": "-66.16139000"
                        },
                        {
                            "id": 143026,
                            "name": "Cayuco",
                            "latitude": "18.29194000",
                            "longitude": "-66.73528000"
                        },
                        {
                            "id": 143027,
                            "name": "Ceiba",
                            "latitude": "18.44638889",
                            "longitude": "-66.35083333"
                        },
                        {
                            "id": 143028,
                            "name": "Ceiba Municipio",
                            "latitude": "18.26051000",
                            "longitude": "-65.67183000"
                        },
                        {
                            "id": 143029,
                            "name": "Celada",
                            "latitude": "18.27166667",
                            "longitude": "-65.96611111"
                        },
                        {
                            "id": 143030,
                            "name": "Central Aguirre",
                            "latitude": "17.95472000",
                            "longitude": "-66.22611000"
                        },
                        {
                            "id": 143031,
                            "name": "Ciales",
                            "latitude": "18.33611100",
                            "longitude": "-66.46888900"
                        },
                        {
                            "id": 143032,
                            "name": "Ciales Municipio",
                            "latitude": "18.29162000",
                            "longitude": "-66.51628000"
                        },
                        {
                            "id": 143033,
                            "name": "Cidra",
                            "latitude": "18.17583333",
                            "longitude": "-66.16138889"
                        },
                        {
                            "id": 143034,
                            "name": "Coamo",
                            "latitude": "18.08000000",
                            "longitude": "-66.35805556"
                        },
                        {
                            "id": 143035,
                            "name": "Coco",
                            "latitude": "18.00722222",
                            "longitude": "-66.25944444"
                        },
                        {
                            "id": 143036,
                            "name": "Comerío",
                            "latitude": "18.21801000",
                            "longitude": "-66.22600000"
                        },
                        {
                            "id": 143037,
                            "name": "Comerío Municipio",
                            "latitude": "18.22718000",
                            "longitude": "-66.22183000"
                        },
                        {
                            "id": 143038,
                            "name": "Comunas",
                            "latitude": "18.08722222",
                            "longitude": "-65.84388889"
                        },
                        {
                            "id": 143039,
                            "name": "Coquí",
                            "latitude": "17.97416667",
                            "longitude": "-66.22722222"
                        },
                        {
                            "id": 143040,
                            "name": "Corazón",
                            "latitude": "17.99277778",
                            "longitude": "-66.08500000"
                        },
                        {
                            "id": 143041,
                            "name": "Corcovado",
                            "latitude": "18.45861111",
                            "longitude": "-66.77638889"
                        },
                        {
                            "id": 143042,
                            "name": "Corozal",
                            "latitude": "18.34166667",
                            "longitude": "-66.31694444"
                        },
                        {
                            "id": 143043,
                            "name": "Corozal Municipio",
                            "latitude": "18.30912000",
                            "longitude": "-66.32600000"
                        },
                        {
                            "id": 143044,
                            "name": "Coto Laurel",
                            "latitude": "18.04969000",
                            "longitude": "-66.55128000"
                        },
                        {
                            "id": 143045,
                            "name": "Coto Norte",
                            "latitude": "18.43078000",
                            "longitude": "-66.43989000"
                        },
                        {
                            "id": 143046,
                            "name": "Culebra",
                            "latitude": "18.31666667",
                            "longitude": "-65.29027778"
                        },
                        {
                            "id": 143047,
                            "name": "Culebra barrio-pueblo",
                            "latitude": "18.30646950",
                            "longitude": "-65.30258000"
                        },
                        {
                            "id": 143048,
                            "name": "Daguao",
                            "latitude": "18.22638889",
                            "longitude": "-65.68333333"
                        },
                        {
                            "id": 143049,
                            "name": "Dorado",
                            "latitude": "18.45888900",
                            "longitude": "-66.26777800"
                        },
                        {
                            "id": 143050,
                            "name": "Dorado Municipio",
                            "latitude": "18.43967000",
                            "longitude": "-66.28295000"
                        },
                        {
                            "id": 143051,
                            "name": "El Mangó",
                            "latitude": "18.23416667",
                            "longitude": "-65.87972222"
                        },
                        {
                            "id": 143052,
                            "name": "El Negro",
                            "latitude": "18.03750000",
                            "longitude": "-65.85138889"
                        },
                        {
                            "id": 143053,
                            "name": "El Ojo",
                            "latitude": "18.00388889",
                            "longitude": "-66.39166667"
                        },
                        {
                            "id": 143054,
                            "name": "Emajagua",
                            "latitude": "18.00055556",
                            "longitude": "-65.88277778"
                        },
                        {
                            "id": 143055,
                            "name": "Esperanza",
                            "latitude": "18.09722222",
                            "longitude": "-65.47083333"
                        },
                        {
                            "id": 143056,
                            "name": "Espino",
                            "latitude": "18.27638889",
                            "longitude": "-67.11944444"
                        },
                        {
                            "id": 143057,
                            "name": "Estancias de Florida",
                            "latitude": "18.36666667",
                            "longitude": "-66.56972222"
                        },
                        {
                            "id": 143058,
                            "name": "Fajardo",
                            "latitude": "18.32579000",
                            "longitude": "-65.65238000"
                        },
                        {
                            "id": 143059,
                            "name": "Fajardo Municipio",
                            "latitude": "18.31051000",
                            "longitude": "-65.67600000"
                        },
                        {
                            "id": 143060,
                            "name": "Florida",
                            "latitude": "18.36495300",
                            "longitude": "-66.56616100"
                        },
                        {
                            "id": 143061,
                            "name": "Franquez",
                            "latitude": "18.34027778",
                            "longitude": "-66.42777778"
                        },
                        {
                            "id": 143062,
                            "name": "Fuig",
                            "latitude": "17.98777778",
                            "longitude": "-66.91611111"
                        },
                        {
                            "id": 143063,
                            "name": "G. L. Garcia",
                            "latitude": "18.12750000",
                            "longitude": "-66.10416667"
                        },
                        {
                            "id": 143064,
                            "name": "Galateo",
                            "latitude": "18.36250000",
                            "longitude": "-66.25888889"
                        },
                        {
                            "id": 143065,
                            "name": "Garrochales",
                            "latitude": "18.45361111",
                            "longitude": "-66.56638889"
                        },
                        {
                            "id": 143073,
                            "name": "Guánica",
                            "latitude": "17.97166667",
                            "longitude": "-66.90805556"
                        },
                        {
                            "id": 143074,
                            "name": "Guánica Municipio",
                            "latitude": "17.98830000",
                            "longitude": "-66.91212000"
                        },
                        {
                            "id": 143066,
                            "name": "Guayabal",
                            "latitude": "18.08138889",
                            "longitude": "-66.50138889"
                        },
                        {
                            "id": 143067,
                            "name": "Guayama",
                            "latitude": "17.99361111",
                            "longitude": "-66.13083333"
                        },
                        {
                            "id": 143068,
                            "name": "Guayama Municipio",
                            "latitude": "18.01663000",
                            "longitude": "-66.13072000"
                        },
                        {
                            "id": 143069,
                            "name": "Guayanilla",
                            "latitude": "18.01916667",
                            "longitude": "-66.79194444"
                        },
                        {
                            "id": 143070,
                            "name": "Guaynabo",
                            "latitude": "18.36666667",
                            "longitude": "-66.10000000"
                        },
                        {
                            "id": 143071,
                            "name": "Gurabo",
                            "latitude": "18.25440000",
                            "longitude": "-65.97294000"
                        },
                        {
                            "id": 143072,
                            "name": "Gurabo Municipio",
                            "latitude": "18.27162000",
                            "longitude": "-65.97183000"
                        },
                        {
                            "id": 143075,
                            "name": "H. Rivera Colon",
                            "latitude": "18.34777778",
                            "longitude": "-66.27388889"
                        },
                        {
                            "id": 143076,
                            "name": "Hatillo",
                            "latitude": "18.48633000",
                            "longitude": "-66.82545000"
                        },
                        {
                            "id": 143077,
                            "name": "Hatillo Municipio",
                            "latitude": "18.40773000",
                            "longitude": "-66.79323000"
                        },
                        {
                            "id": 143078,
                            "name": "Hato Arriba",
                            "latitude": "18.35583333",
                            "longitude": "-67.03416667"
                        },
                        {
                            "id": 143079,
                            "name": "Hato Candal",
                            "latitude": "18.37444444",
                            "longitude": "-65.78722222"
                        },
                        {
                            "id": 143080,
                            "name": "Hormigueros",
                            "latitude": "18.13968000",
                            "longitude": "-67.12740000"
                        },
                        {
                            "id": 143081,
                            "name": "Hormigueros Municipio",
                            "latitude": "18.14162000",
                            "longitude": "-67.11629000"
                        },
                        {
                            "id": 143082,
                            "name": "Humacao",
                            "latitude": "18.14972200",
                            "longitude": "-65.82750000"
                        },
                        {
                            "id": 143083,
                            "name": "Imbery",
                            "latitude": "18.43694444",
                            "longitude": "-66.55250000"
                        },
                        {
                            "id": 143084,
                            "name": "Indios",
                            "latitude": "17.99416667",
                            "longitude": "-66.81944444"
                        },
                        {
                            "id": 143085,
                            "name": "Ingenio",
                            "latitude": "18.44222222",
                            "longitude": "-66.22611111"
                        },
                        {
                            "id": 143086,
                            "name": "Isabel Segunda",
                            "latitude": "18.14916667",
                            "longitude": "-65.44277778"
                        },
                        {
                            "id": 143087,
                            "name": "Isabela",
                            "latitude": "18.51305556",
                            "longitude": "-67.07000000"
                        },
                        {
                            "id": 143088,
                            "name": "Jagual",
                            "latitude": "18.16166667",
                            "longitude": "-65.99555556"
                        },
                        {
                            "id": 143089,
                            "name": "Jauca",
                            "latitude": "17.96916667",
                            "longitude": "-66.36583333"
                        },
                        {
                            "id": 143090,
                            "name": "Jayuya",
                            "latitude": "18.21861100",
                            "longitude": "-66.59166700"
                        },
                        {
                            "id": 143091,
                            "name": "Jobos",
                            "latitude": "17.95527778",
                            "longitude": "-66.16555556"
                        },
                        {
                            "id": 143092,
                            "name": "Juana Díaz",
                            "latitude": "18.05246000",
                            "longitude": "-66.50656000"
                        },
                        {
                            "id": 143093,
                            "name": "Juana Díaz Municipio",
                            "latitude": "18.04802000",
                            "longitude": "-66.49267000"
                        },
                        {
                            "id": 143094,
                            "name": "Juncal",
                            "latitude": "18.31388889",
                            "longitude": "-66.91916667"
                        },
                        {
                            "id": 143095,
                            "name": "Juncos",
                            "latitude": "18.22750000",
                            "longitude": "-65.92111100"
                        },
                        {
                            "id": 143096,
                            "name": "La Alianza",
                            "latitude": "18.39750000",
                            "longitude": "-66.60250000"
                        },
                        {
                            "id": 143097,
                            "name": "La Dolores",
                            "latitude": "18.37555556",
                            "longitude": "-65.85583333"
                        },
                        {
                            "id": 143098,
                            "name": "La Fermina",
                            "latitude": "18.17417000",
                            "longitude": "-65.85139000"
                        },
                        {
                            "id": 143099,
                            "name": "La Luisa",
                            "latitude": "18.44888889",
                            "longitude": "-66.51000000"
                        },
                        {
                            "id": 143100,
                            "name": "La Parguera",
                            "latitude": "17.97497000",
                            "longitude": "-67.04657000"
                        },
                        {
                            "id": 143101,
                            "name": "La Playa",
                            "latitude": "18.28750000",
                            "longitude": "-67.18694444"
                        },
                        {
                            "id": 143102,
                            "name": "La Plena",
                            "latitude": "18.04663000",
                            "longitude": "-66.20461000"
                        },
                        {
                            "id": 143103,
                            "name": "Lajas",
                            "latitude": "18.05193889",
                            "longitude": "-67.05971944"
                        },
                        {
                            "id": 143104,
                            "name": "Lajas Municipio",
                            "latitude": "18.01330000",
                            "longitude": "-67.03990000"
                        },
                        {
                            "id": 143105,
                            "name": "Lamboglia",
                            "latitude": "17.98136000",
                            "longitude": "-65.98572000"
                        },
                        {
                            "id": 143106,
                            "name": "Lares",
                            "latitude": "18.29467000",
                            "longitude": "-66.87712000"
                        },
                        {
                            "id": 143107,
                            "name": "Las Marias",
                            "latitude": "18.29333333",
                            "longitude": "-67.14638889"
                        },
                        {
                            "id": 143108,
                            "name": "Las Marías Municipio",
                            "latitude": "18.25087000",
                            "longitude": "-66.99240000"
                        },
                        {
                            "id": 143109,
                            "name": "Las Ochenta",
                            "latitude": "17.98469000",
                            "longitude": "-66.31795000"
                        },
                        {
                            "id": 143110,
                            "name": "Las Ollas",
                            "latitude": "18.03305556",
                            "longitude": "-66.42444444"
                        },
                        {
                            "id": 143111,
                            "name": "Las Piedras",
                            "latitude": "18.18301000",
                            "longitude": "-65.86627000"
                        },
                        {
                            "id": 143112,
                            "name": "Las Piedras Municipio",
                            "latitude": "18.18829000",
                            "longitude": "-65.87044000"
                        },
                        {
                            "id": 143113,
                            "name": "Levittown",
                            "latitude": "18.44861111",
                            "longitude": "-66.18027778"
                        },
                        {
                            "id": 143114,
                            "name": "Liborio Negron Torres",
                            "latitude": "18.04305556",
                            "longitude": "-66.94250000"
                        },
                        {
                            "id": 143115,
                            "name": "Lluveras",
                            "latitude": "18.03833333",
                            "longitude": "-66.90472222"
                        },
                        {
                            "id": 143118,
                            "name": "Loíza",
                            "latitude": "18.43134000",
                            "longitude": "-65.88016000"
                        },
                        {
                            "id": 143116,
                            "name": "Lomas",
                            "latitude": "18.26861111",
                            "longitude": "-65.90888889"
                        },
                        {
                            "id": 143117,
                            "name": "Los Llanos",
                            "latitude": "18.05527778",
                            "longitude": "-66.40583333"
                        },
                        {
                            "id": 143119,
                            "name": "Luis Lloréns Torres",
                            "latitude": "18.05691000",
                            "longitude": "-66.52684000"
                        },
                        {
                            "id": 143120,
                            "name": "Luis M. Cintron",
                            "latitude": "18.29972222",
                            "longitude": "-65.63861111"
                        },
                        {
                            "id": 143121,
                            "name": "Luquillo",
                            "latitude": "18.37250000",
                            "longitude": "-65.71666667"
                        },
                        {
                            "id": 143122,
                            "name": "Luquillo Municipio",
                            "latitude": "18.33967000",
                            "longitude": "-65.72461000"
                        },
                        {
                            "id": 143123,
                            "name": "Luyando",
                            "latitude": "18.36444444",
                            "longitude": "-67.15750000"
                        },
                        {
                            "id": 143124,
                            "name": "Magas Arriba",
                            "latitude": "18.01750000",
                            "longitude": "-66.76916667"
                        },
                        {
                            "id": 143125,
                            "name": "Manatí",
                            "latitude": "18.43250000",
                            "longitude": "-66.48444444"
                        },
                        {
                            "id": 143126,
                            "name": "Maria Antonia",
                            "latitude": "17.97833333",
                            "longitude": "-66.88944444"
                        },
                        {
                            "id": 143127,
                            "name": "Mariano Colón",
                            "latitude": "18.02333333",
                            "longitude": "-66.33250000"
                        },
                        {
                            "id": 143128,
                            "name": "Maricao",
                            "latitude": "18.18083300",
                            "longitude": "-66.98000000"
                        },
                        {
                            "id": 143129,
                            "name": "Maricao Municipio",
                            "latitude": "18.17301000",
                            "longitude": "-66.94546000"
                        },
                        {
                            "id": 143130,
                            "name": "Martorell",
                            "latitude": "18.07472000",
                            "longitude": "-65.89861000"
                        },
                        {
                            "id": 143131,
                            "name": "Marueño",
                            "latitude": "18.05772000",
                            "longitude": "-66.65603000"
                        },
                        {
                            "id": 143132,
                            "name": "Maunabo",
                            "latitude": "18.00719000",
                            "longitude": "-65.89933000"
                        },
                        {
                            "id": 143133,
                            "name": "Maunabo Municipio",
                            "latitude": "18.01691000",
                            "longitude": "-65.92738000"
                        },
                        {
                            "id": 143134,
                            "name": "Mayagüez",
                            "latitude": "18.20111111",
                            "longitude": "-67.13972222"
                        },
                        {
                            "id": 143135,
                            "name": "Miranda",
                            "latitude": "18.38666667",
                            "longitude": "-66.38388889"
                        },
                        {
                            "id": 143136,
                            "name": "Moca",
                            "latitude": "18.39467000",
                            "longitude": "-67.11324000"
                        },
                        {
                            "id": 143137,
                            "name": "Moca Municipio",
                            "latitude": "18.38550000",
                            "longitude": "-67.08435000"
                        },
                        {
                            "id": 143138,
                            "name": "Monserrate",
                            "latitude": "18.43694000",
                            "longitude": "-66.35639000"
                        },
                        {
                            "id": 143139,
                            "name": "Monte Grande",
                            "latitude": "18.08750000",
                            "longitude": "-67.10750000"
                        },
                        {
                            "id": 143140,
                            "name": "Mora",
                            "latitude": "18.46305556",
                            "longitude": "-67.03277778"
                        },
                        {
                            "id": 143141,
                            "name": "Morovis",
                            "latitude": "18.33333333",
                            "longitude": "-66.41666667"
                        },
                        {
                            "id": 143142,
                            "name": "Mucarabones",
                            "latitude": "18.39083333",
                            "longitude": "-66.21611111"
                        },
                        {
                            "id": 143143,
                            "name": "Naguabo",
                            "latitude": "18.21944444",
                            "longitude": "-65.73666667"
                        },
                        {
                            "id": 143144,
                            "name": "Naguabo Municipio",
                            "latitude": "18.23135000",
                            "longitude": "-65.75988000"
                        },
                        {
                            "id": 143145,
                            "name": "Naranjito",
                            "latitude": "18.30083300",
                            "longitude": "-66.24500000"
                        },
                        {
                            "id": 143146,
                            "name": "Naranjito Municipio",
                            "latitude": "18.28968000",
                            "longitude": "-66.25517000"
                        },
                        {
                            "id": 143147,
                            "name": "Olimpo",
                            "latitude": "18.00194444",
                            "longitude": "-66.10833333"
                        },
                        {
                            "id": 143148,
                            "name": "Orocovis",
                            "latitude": "18.22694400",
                            "longitude": "-66.39111100"
                        },
                        {
                            "id": 143149,
                            "name": "Orocovis Municipio",
                            "latitude": "18.21885000",
                            "longitude": "-66.43712000"
                        },
                        {
                            "id": 143174,
                            "name": "Pájaros",
                            "latitude": "18.37592060",
                            "longitude": "-66.18625500"
                        },
                        {
                            "id": 143150,
                            "name": "Pajonal",
                            "latitude": "18.38222222",
                            "longitude": "-66.55583333"
                        },
                        {
                            "id": 143151,
                            "name": "Palmarejo",
                            "latitude": "18.04027778",
                            "longitude": "-67.07694444"
                        },
                        {
                            "id": 143152,
                            "name": "Palmas",
                            "latitude": "17.98722222",
                            "longitude": "-66.02555556"
                        },
                        {
                            "id": 143153,
                            "name": "Palmer",
                            "latitude": "18.37055556",
                            "longitude": "-65.77416667"
                        },
                        {
                            "id": 143154,
                            "name": "Palo Seco",
                            "latitude": "18.00747000",
                            "longitude": "-65.93683000"
                        },
                        {
                            "id": 143155,
                            "name": "Palomas",
                            "latitude": "18.01361111",
                            "longitude": "-66.87333333"
                        },
                        {
                            "id": 143156,
                            "name": "Parcelas La Milagrosa",
                            "latitude": "18.17083333",
                            "longitude": "-66.18833333"
                        },
                        {
                            "id": 143157,
                            "name": "Parcelas Nuevas",
                            "latitude": "18.14027778",
                            "longitude": "-66.17250000"
                        },
                        {
                            "id": 143158,
                            "name": "Parcelas Peñuelas",
                            "latitude": "17.99888889",
                            "longitude": "-66.34138889"
                        },
                        {
                            "id": 143159,
                            "name": "Pastos",
                            "latitude": "18.11777778",
                            "longitude": "-66.25888889"
                        },
                        {
                            "id": 143160,
                            "name": "Patillas",
                            "latitude": "18.00635000",
                            "longitude": "-66.01572000"
                        },
                        {
                            "id": 143161,
                            "name": "Patillas Municipio",
                            "latitude": "18.04163000",
                            "longitude": "-66.01766000"
                        },
                        {
                            "id": 143162,
                            "name": "Peña Pobre",
                            "latitude": "18.21555556",
                            "longitude": "-65.82222222"
                        },
                        {
                            "id": 143163,
                            "name": "Peñuelas",
                            "latitude": "18.05937500",
                            "longitude": "-66.72254400"
                        },
                        {
                            "id": 143164,
                            "name": "Piedra Gorda",
                            "latitude": "18.43416667",
                            "longitude": "-66.88777778"
                        },
                        {
                            "id": 143165,
                            "name": "Playa Fortuna",
                            "latitude": "18.37972222",
                            "longitude": "-65.74527778"
                        },
                        {
                            "id": 143166,
                            "name": "Playita",
                            "latitude": "17.96055556",
                            "longitude": "-66.28972222"
                        },
                        {
                            "id": 143167,
                            "name": "Playita Cortada",
                            "latitude": "17.98500000",
                            "longitude": "-66.43916667"
                        },
                        {
                            "id": 143168,
                            "name": "Pole Ojea",
                            "latitude": "17.97500000",
                            "longitude": "-67.18527778"
                        },
                        {
                            "id": 143169,
                            "name": "Ponce",
                            "latitude": "18.00000000",
                            "longitude": "-66.61666667"
                        },
                        {
                            "id": 143170,
                            "name": "Potala Pastillo",
                            "latitude": "17.99138889",
                            "longitude": "-66.49666667"
                        },
                        {
                            "id": 143171,
                            "name": "Pueblito del Rio",
                            "latitude": "18.22805556",
                            "longitude": "-65.86305556"
                        },
                        {
                            "id": 143172,
                            "name": "Puerto Real",
                            "latitude": "18.07500000",
                            "longitude": "-67.18722222"
                        },
                        {
                            "id": 143173,
                            "name": "Punta Santiago",
                            "latitude": "18.16638889",
                            "longitude": "-65.74833333"
                        },
                        {
                            "id": 143175,
                            "name": "Quebrada",
                            "latitude": "18.35666667",
                            "longitude": "-66.83222222"
                        },
                        {
                            "id": 143176,
                            "name": "Quebradillas",
                            "latitude": "18.47388889",
                            "longitude": "-66.93861111"
                        },
                        {
                            "id": 143177,
                            "name": "Quebradillas Municipio",
                            "latitude": "18.43967000",
                            "longitude": "-66.92462000"
                        },
                        {
                            "id": 143178,
                            "name": "Rafael Capo",
                            "latitude": "18.40722222",
                            "longitude": "-66.78222222"
                        },
                        {
                            "id": 143179,
                            "name": "Rafael Gonzalez",
                            "latitude": "18.42750000",
                            "longitude": "-66.78694444"
                        },
                        {
                            "id": 143180,
                            "name": "Rafael Hernandez",
                            "latitude": "18.47138889",
                            "longitude": "-67.07916667"
                        },
                        {
                            "id": 143181,
                            "name": "Ramos",
                            "latitude": "18.33972222",
                            "longitude": "-65.71277778"
                        },
                        {
                            "id": 143182,
                            "name": "Rincón",
                            "latitude": "18.34023000",
                            "longitude": "-67.24990000"
                        },
                        {
                            "id": 143183,
                            "name": "Rincón Municipio",
                            "latitude": "18.33967000",
                            "longitude": "-67.23713000"
                        },
                        {
                            "id": 143186,
                            "name": "Río Blanco",
                            "latitude": "18.21833333",
                            "longitude": "-65.78861111"
                        },
                        {
                            "id": 143187,
                            "name": "Río Cañas Abajo",
                            "latitude": "18.03833333",
                            "longitude": "-66.46777778"
                        },
                        {
                            "id": 143188,
                            "name": "Río Grande",
                            "latitude": "18.38023000",
                            "longitude": "-65.83127000"
                        },
                        {
                            "id": 143189,
                            "name": "Río Grande Municipio",
                            "latitude": "18.34162000",
                            "longitude": "-65.81766000"
                        },
                        {
                            "id": 143184,
                            "name": "Rio Lajas",
                            "latitude": "18.39719150",
                            "longitude": "-66.26453900"
                        },
                        {
                            "id": 143190,
                            "name": "Río Piedras",
                            "latitude": "18.39972222",
                            "longitude": "-66.05000000"
                        },
                        {
                            "id": 143185,
                            "name": "Rosa Sanchez",
                            "latitude": "18.06166667",
                            "longitude": "-65.91361111"
                        },
                        {
                            "id": 143191,
                            "name": "Sabana",
                            "latitude": "18.46083333",
                            "longitude": "-66.35861111"
                        },
                        {
                            "id": 143192,
                            "name": "Sabana Eneas",
                            "latitude": "18.08611111",
                            "longitude": "-67.08111111"
                        },
                        {
                            "id": 143193,
                            "name": "Sabana Grande",
                            "latitude": "18.07972222",
                            "longitude": "-66.96083333"
                        },
                        {
                            "id": 143194,
                            "name": "Sabana Grande Municipio",
                            "latitude": "18.09385000",
                            "longitude": "-66.94962000"
                        },
                        {
                            "id": 143195,
                            "name": "Sabana Hoyos",
                            "latitude": "18.43388889",
                            "longitude": "-66.61388889"
                        },
                        {
                            "id": 143196,
                            "name": "Sabana Seca",
                            "latitude": "18.42694444",
                            "longitude": "-66.18472222"
                        },
                        {
                            "id": 143197,
                            "name": "Salinas",
                            "latitude": "18.01746000",
                            "longitude": "-66.25378000"
                        },
                        {
                            "id": 143198,
                            "name": "San Antonio",
                            "latitude": "18.45166667",
                            "longitude": "-66.94972222"
                        },
                        {
                            "id": 143199,
                            "name": "San Germán Municipio",
                            "latitude": "18.11468000",
                            "longitude": "-67.03573000"
                        },
                        {
                            "id": 143200,
                            "name": "San Isidro",
                            "latitude": "18.39222222",
                            "longitude": "-65.88555556"
                        },
                        {
                            "id": 143201,
                            "name": "San José",
                            "latitude": "18.39833333",
                            "longitude": "-66.25583333"
                        },
                        {
                            "id": 143202,
                            "name": "San Juan",
                            "latitude": "18.45000000",
                            "longitude": "-66.06666667"
                        },
                        {
                            "id": 143203,
                            "name": "San Lorenzo",
                            "latitude": "18.18988900",
                            "longitude": "-65.96869400"
                        },
                        {
                            "id": 143204,
                            "name": "San Sebastián",
                            "latitude": "18.33722222",
                            "longitude": "-66.99055556"
                        },
                        {
                            "id": 143205,
                            "name": "Santa Barbara",
                            "latitude": "18.39361111",
                            "longitude": "-65.91888889"
                        },
                        {
                            "id": 143206,
                            "name": "Santa Clara",
                            "latitude": "18.21861111",
                            "longitude": "-66.12888889"
                        },
                        {
                            "id": 143207,
                            "name": "Santa Isabel",
                            "latitude": "17.96611100",
                            "longitude": "-66.40500000"
                        },
                        {
                            "id": 143208,
                            "name": "Santa Isabel Municipio",
                            "latitude": "17.99663000",
                            "longitude": "-66.38711000"
                        },
                        {
                            "id": 143209,
                            "name": "Santo Domingo",
                            "latitude": "18.06333333",
                            "longitude": "-66.75250000"
                        },
                        {
                            "id": 143210,
                            "name": "Santurce",
                            "latitude": "18.44083333",
                            "longitude": "-66.04722222"
                        },
                        {
                            "id": 143211,
                            "name": "Stella",
                            "latitude": "18.32194444",
                            "longitude": "-67.24694444"
                        },
                        {
                            "id": 143213,
                            "name": "Suárez",
                            "latitude": "18.43027778",
                            "longitude": "-65.85361111"
                        },
                        {
                            "id": 143212,
                            "name": "Sumidero",
                            "latitude": "18.21861111",
                            "longitude": "-66.12888889"
                        },
                        {
                            "id": 143214,
                            "name": "Tallaboa",
                            "latitude": "17.99500000",
                            "longitude": "-66.71638889"
                        },
                        {
                            "id": 143215,
                            "name": "Tallaboa Alta",
                            "latitude": "18.05111111",
                            "longitude": "-66.70027778"
                        },
                        {
                            "id": 143216,
                            "name": "Tiburones",
                            "latitude": "18.43166667",
                            "longitude": "-66.58083333"
                        },
                        {
                            "id": 143217,
                            "name": "Tierras Nuevas Poniente",
                            "latitude": "18.46189000",
                            "longitude": "-66.48850000"
                        },
                        {
                            "id": 143218,
                            "name": "Toa Alta",
                            "latitude": "18.38828000",
                            "longitude": "-66.24822000"
                        },
                        {
                            "id": 143219,
                            "name": "Toa Alta Municipio",
                            "latitude": "18.36606000",
                            "longitude": "-66.25378000"
                        },
                        {
                            "id": 143220,
                            "name": "Toa Baja",
                            "latitude": "18.44388900",
                            "longitude": "-66.25972200"
                        },
                        {
                            "id": 143221,
                            "name": "Trujillo Alto",
                            "latitude": "18.36277800",
                            "longitude": "-66.01750000"
                        },
                        {
                            "id": 143222,
                            "name": "Trujillo Alto Municipio",
                            "latitude": "18.34162000",
                            "longitude": "-66.00517000"
                        },
                        {
                            "id": 143223,
                            "name": "Utuado",
                            "latitude": "18.27301000",
                            "longitude": "-66.69962000"
                        },
                        {
                            "id": 143224,
                            "name": "Utuado barrio-pueblo",
                            "latitude": "18.26580000",
                            "longitude": "-66.70640000"
                        },
                        {
                            "id": 143232,
                            "name": "Vázquez",
                            "latitude": "18.06583333",
                            "longitude": "-66.23861111"
                        },
                        {
                            "id": 143225,
                            "name": "Vega Alta",
                            "latitude": "18.41217000",
                            "longitude": "-66.33128000"
                        },
                        {
                            "id": 143226,
                            "name": "Vega Alta Municipio",
                            "latitude": "18.41050000",
                            "longitude": "-66.33295000"
                        },
                        {
                            "id": 143227,
                            "name": "Vega Baja",
                            "latitude": "18.44611111",
                            "longitude": "-66.38750000"
                        },
                        {
                            "id": 143228,
                            "name": "Vieques",
                            "latitude": "18.42500000",
                            "longitude": "-65.83305556"
                        },
                        {
                            "id": 143229,
                            "name": "Vieques Municipality",
                            "latitude": "18.12913000",
                            "longitude": "-65.43710000"
                        },
                        {
                            "id": 143230,
                            "name": "Villalba",
                            "latitude": "18.12722200",
                            "longitude": "-66.49222200"
                        },
                        {
                            "id": 143231,
                            "name": "Villalba Municipio",
                            "latitude": "18.12913000",
                            "longitude": "-66.47739000"
                        },
                        {
                            "id": 143233,
                            "name": "Yabucoa",
                            "latitude": "18.05052000",
                            "longitude": "-65.87933000"
                        },
                        {
                            "id": 143234,
                            "name": "Yabucoa Municipio",
                            "latitude": "18.07302000",
                            "longitude": "-65.88711000"
                        },
                        {
                            "id": 143235,
                            "name": "Yauco",
                            "latitude": "18.03694444",
                            "longitude": "-66.85027778"
                        },
                        {
                            "id": 143236,
                            "name": "Yaurel",
                            "latitude": "18.02694444",
                            "longitude": "-66.05722222"
                        }
                    ]
                },
                {
                    "id": 1461,
                    "name": "Rhode Island",
                    "state_code": "RI",
                    "cities": [
                        {
                            "id": 111512,
                            "name": "Ashaway",
                            "latitude": "41.42343000",
                            "longitude": "-71.78562000"
                        },
                        {
                            "id": 111848,
                            "name": "Barrington",
                            "latitude": "41.74066000",
                            "longitude": "-71.30866000"
                        },
                        {
                            "id": 112667,
                            "name": "Bradford",
                            "latitude": "41.39899000",
                            "longitude": "-71.73701000"
                        },
                        {
                            "id": 112812,
                            "name": "Bristol",
                            "latitude": "41.67705000",
                            "longitude": "-71.26616000"
                        },
                        {
                            "id": 112815,
                            "name": "Bristol County",
                            "latitude": "41.70554000",
                            "longitude": "-71.28612000"
                        },
                        {
                            "id": 113673,
                            "name": "Central Falls",
                            "latitude": "41.89066000",
                            "longitude": "-71.39228000"
                        },
                        {
                            "id": 113773,
                            "name": "Charlestown",
                            "latitude": "41.38316000",
                            "longitude": "-71.64173000"
                        },
                        {
                            "id": 113843,
                            "name": "Chepachet",
                            "latitude": "41.91510000",
                            "longitude": "-71.67146000"
                        },
                        {
                            "id": 114732,
                            "name": "Coventry",
                            "latitude": "41.70010000",
                            "longitude": "-71.68284000"
                        },
                        {
                            "id": 114772,
                            "name": "Cranston",
                            "latitude": "41.77982000",
                            "longitude": "-71.43728000"
                        },
                        {
                            "id": 114909,
                            "name": "Cumberland",
                            "latitude": "41.96677000",
                            "longitude": "-71.43284000"
                        },
                        {
                            "id": 114921,
                            "name": "Cumberland Hill",
                            "latitude": "41.97454000",
                            "longitude": "-71.46700000"
                        },
                        {
                            "id": 115674,
                            "name": "East Greenwich",
                            "latitude": "41.66038000",
                            "longitude": "-71.45589000"
                        },
                        {
                            "id": 115741,
                            "name": "East Providence",
                            "latitude": "41.81371000",
                            "longitude": "-71.37005000"
                        },
                        {
                            "id": 116273,
                            "name": "Exeter",
                            "latitude": "41.57760000",
                            "longitude": "-71.53756000"
                        },
                        {
                            "id": 116774,
                            "name": "Foster",
                            "latitude": "41.85371000",
                            "longitude": "-71.75812000"
                        },
                        {
                            "id": 117695,
                            "name": "Greenville",
                            "latitude": "41.87121000",
                            "longitude": "-71.55201000"
                        },
                        {
                            "id": 118076,
                            "name": "Harrisville",
                            "latitude": "41.96565000",
                            "longitude": "-71.67451000"
                        },
                        {
                            "id": 118646,
                            "name": "Hope Valley",
                            "latitude": "41.50760000",
                            "longitude": "-71.71618000"
                        },
                        {
                            "id": 118658,
                            "name": "Hopkinton",
                            "latitude": "41.46121000",
                            "longitude": "-71.77757000"
                        },
                        {
                            "id": 119125,
                            "name": "Jamestown",
                            "latitude": "41.49705000",
                            "longitude": "-71.36728000"
                        },
                        {
                            "id": 119279,
                            "name": "Johnston",
                            "latitude": "41.82186000",
                            "longitude": "-71.50675000"
                        },
                        {
                            "id": 119493,
                            "name": "Kent County",
                            "latitude": "41.67334000",
                            "longitude": "-71.57895000"
                        },
                        {
                            "id": 119629,
                            "name": "Kingston",
                            "latitude": "41.48038000",
                            "longitude": "-71.52256000"
                        },
                        {
                            "id": 120513,
                            "name": "Lincoln",
                            "latitude": "41.92111000",
                            "longitude": "-71.43500000"
                        },
                        {
                            "id": 121636,
                            "name": "Melville",
                            "latitude": "41.58705000",
                            "longitude": "-71.28338000"
                        },
                        {
                            "id": 121793,
                            "name": "Middletown",
                            "latitude": "41.54566000",
                            "longitude": "-71.29144000"
                        },
                        {
                            "id": 122564,
                            "name": "Narragansett",
                            "latitude": "41.45010000",
                            "longitude": "-71.44950000"
                        },
                        {
                            "id": 122565,
                            "name": "Narragansett Pier",
                            "latitude": "41.43232000",
                            "longitude": "-71.45644000"
                        },
                        {
                            "id": 122778,
                            "name": "New Shoreham",
                            "latitude": "41.17233000",
                            "longitude": "-71.55783000"
                        },
                        {
                            "id": 122849,
                            "name": "Newport",
                            "latitude": "41.49010000",
                            "longitude": "-71.31283000"
                        },
                        {
                            "id": 122854,
                            "name": "Newport County",
                            "latitude": "41.49980000",
                            "longitude": "-71.28100000"
                        },
                        {
                            "id": 122855,
                            "name": "Newport East",
                            "latitude": "41.51579000",
                            "longitude": "-71.28752000"
                        },
                        {
                            "id": 123048,
                            "name": "North Kingstown",
                            "latitude": "41.55010000",
                            "longitude": "-71.46617000"
                        },
                        {
                            "id": 123089,
                            "name": "North Providence",
                            "latitude": "41.85010000",
                            "longitude": "-71.46617000"
                        },
                        {
                            "id": 123102,
                            "name": "North Scituate",
                            "latitude": "41.83177000",
                            "longitude": "-71.58729000"
                        },
                        {
                            "id": 123108,
                            "name": "North Smithfield",
                            "latitude": "41.96677000",
                            "longitude": "-71.54951000"
                        },
                        {
                            "id": 123903,
                            "name": "Pascoag",
                            "latitude": "41.95565000",
                            "longitude": "-71.70229000"
                        },
                        {
                            "id": 123939,
                            "name": "Pawtucket",
                            "latitude": "41.87871000",
                            "longitude": "-71.38256000"
                        },
                        {
                            "id": 124571,
                            "name": "Portsmouth",
                            "latitude": "41.60232000",
                            "longitude": "-71.25033000"
                        },
                        {
                            "id": 124716,
                            "name": "Providence",
                            "latitude": "41.82399000",
                            "longitude": "-71.41283000"
                        },
                        {
                            "id": 124719,
                            "name": "Providence County",
                            "latitude": "41.87136000",
                            "longitude": "-71.57860000"
                        },
                        {
                            "id": 126520,
                            "name": "Smithfield",
                            "latitude": "41.92204000",
                            "longitude": "-71.54951000"
                        },
                        {
                            "id": 126683,
                            "name": "South Kingstown",
                            "latitude": "41.44718000",
                            "longitude": "-71.52494000"
                        },
                        {
                            "id": 127687,
                            "name": "Tiverton",
                            "latitude": "41.62594000",
                            "longitude": "-71.21338000"
                        },
                        {
                            "id": 128108,
                            "name": "Valley Falls",
                            "latitude": "41.90677000",
                            "longitude": "-71.39061000"
                        },
                        {
                            "id": 128364,
                            "name": "Wakefield-Peacedale",
                            "latitude": "41.44606000",
                            "longitude": "-71.50040000"
                        },
                        {
                            "id": 128484,
                            "name": "Warren",
                            "latitude": "41.73038000",
                            "longitude": "-71.28255000"
                        },
                        {
                            "id": 128525,
                            "name": "Warwick",
                            "latitude": "41.70010000",
                            "longitude": "-71.41617000"
                        },
                        {
                            "id": 128574,
                            "name": "Washington County",
                            "latitude": "41.39649000",
                            "longitude": "-71.61966000"
                        },
                        {
                            "id": 128880,
                            "name": "West Greenwich",
                            "latitude": "41.63700000",
                            "longitude": "-71.66004000"
                        },
                        {
                            "id": 129002,
                            "name": "West Warwick",
                            "latitude": "41.69689000",
                            "longitude": "-71.52194000"
                        },
                        {
                            "id": 129023,
                            "name": "Westerly",
                            "latitude": "41.37760000",
                            "longitude": "-71.82729000"
                        },
                        {
                            "id": 129582,
                            "name": "Woonsocket",
                            "latitude": "42.00288000",
                            "longitude": "-71.51478000"
                        }
                    ]
                },
                {
                    "id": 1443,
                    "name": "South Carolina",
                    "state_code": "SC",
                    "cities": [
                        {
                            "id": 110967,
                            "name": "Abbeville",
                            "latitude": "34.17817000",
                            "longitude": "-82.37901000"
                        },
                        {
                            "id": 110969,
                            "name": "Abbeville County",
                            "latitude": "34.22257000",
                            "longitude": "-82.45871000"
                        },
                        {
                            "id": 111066,
                            "name": "Aiken",
                            "latitude": "33.56042000",
                            "longitude": "-81.71955000"
                        },
                        {
                            "id": 111067,
                            "name": "Aiken County",
                            "latitude": "33.54437000",
                            "longitude": "-81.63474000"
                        },
                        {
                            "id": 111193,
                            "name": "Allendale",
                            "latitude": "33.00793000",
                            "longitude": "-81.30844000"
                        },
                        {
                            "id": 111197,
                            "name": "Allendale County",
                            "latitude": "32.98811000",
                            "longitude": "-81.35820000"
                        },
                        {
                            "id": 111311,
                            "name": "Anderson",
                            "latitude": "34.50344000",
                            "longitude": "-82.65013000"
                        },
                        {
                            "id": 111317,
                            "name": "Anderson County",
                            "latitude": "34.51909000",
                            "longitude": "-82.63788000"
                        },
                        {
                            "id": 111329,
                            "name": "Andrews",
                            "latitude": "33.45128000",
                            "longitude": "-79.56090000"
                        },
                        {
                            "id": 111422,
                            "name": "Arcadia",
                            "latitude": "34.95818000",
                            "longitude": "-81.99066000"
                        },
                        {
                            "id": 111451,
                            "name": "Arial",
                            "latitude": "34.84595000",
                            "longitude": "-82.64152000"
                        },
                        {
                            "id": 111706,
                            "name": "Awendaw",
                            "latitude": "33.03767000",
                            "longitude": "-79.61313000"
                        },
                        {
                            "id": 111786,
                            "name": "Bamberg",
                            "latitude": "33.29710000",
                            "longitude": "-81.03482000"
                        },
                        {
                            "id": 111787,
                            "name": "Bamberg County",
                            "latitude": "33.21477000",
                            "longitude": "-81.05423000"
                        },
                        {
                            "id": 111838,
                            "name": "Barnwell",
                            "latitude": "33.24487000",
                            "longitude": "-81.35872000"
                        },
                        {
                            "id": 111839,
                            "name": "Barnwell County",
                            "latitude": "33.26606000",
                            "longitude": "-81.43502000"
                        },
                        {
                            "id": 111890,
                            "name": "Batesburg",
                            "latitude": "33.90792000",
                            "longitude": "-81.54733000"
                        },
                        {
                            "id": 111891,
                            "name": "Batesburg-Leesville",
                            "latitude": "33.91014000",
                            "longitude": "-81.53733000"
                        },
                        {
                            "id": 111986,
                            "name": "Beaufort",
                            "latitude": "32.43158000",
                            "longitude": "-80.66983000"
                        },
                        {
                            "id": 111988,
                            "name": "Beaufort County",
                            "latitude": "32.35706000",
                            "longitude": "-80.69217000"
                        },
                        {
                            "id": 112148,
                            "name": "Belton",
                            "latitude": "34.52289000",
                            "longitude": "-82.49429000"
                        },
                        {
                            "id": 112152,
                            "name": "Belvedere",
                            "latitude": "33.53097000",
                            "longitude": "-81.94484000"
                        },
                        {
                            "id": 112175,
                            "name": "Bennettsville",
                            "latitude": "34.61738000",
                            "longitude": "-79.68478000"
                        },
                        {
                            "id": 112214,
                            "name": "Berea",
                            "latitude": "34.88540000",
                            "longitude": "-82.45596000"
                        },
                        {
                            "id": 112224,
                            "name": "Berkeley County",
                            "latitude": "33.19768000",
                            "longitude": "-79.95099000"
                        },
                        {
                            "id": 112365,
                            "name": "Bishopville",
                            "latitude": "34.21821000",
                            "longitude": "-80.24841000"
                        },
                        {
                            "id": 112389,
                            "name": "Blacksburg",
                            "latitude": "35.12124000",
                            "longitude": "-81.51592000"
                        },
                        {
                            "id": 112394,
                            "name": "Blackville",
                            "latitude": "33.35793000",
                            "longitude": "-81.27066000"
                        },
                        {
                            "id": 112483,
                            "name": "Bluffton",
                            "latitude": "32.23715000",
                            "longitude": "-80.86039000"
                        },
                        {
                            "id": 112487,
                            "name": "Blythewood",
                            "latitude": "34.21432000",
                            "longitude": "-80.97398000"
                        },
                        {
                            "id": 112506,
                            "name": "Boiling Springs",
                            "latitude": "35.04651000",
                            "longitude": "-81.98177000"
                        },
                        {
                            "id": 112540,
                            "name": "Bonneau Beach",
                            "latitude": "33.31989000",
                            "longitude": "-80.00036000"
                        },
                        {
                            "id": 112849,
                            "name": "Brookdale",
                            "latitude": "33.50682000",
                            "longitude": "-80.82342000"
                        },
                        {
                            "id": 113009,
                            "name": "Buffalo",
                            "latitude": "34.72569000",
                            "longitude": "-81.68343000"
                        },
                        {
                            "id": 113081,
                            "name": "Burnettown",
                            "latitude": "33.51541000",
                            "longitude": "-81.84900000"
                        },
                        {
                            "id": 113096,
                            "name": "Burton",
                            "latitude": "32.43575000",
                            "longitude": "-80.72400000"
                        },
                        {
                            "id": 113206,
                            "name": "Calhoun County",
                            "latitude": "33.67486000",
                            "longitude": "-80.78028000"
                        },
                        {
                            "id": 113211,
                            "name": "Calhoun Falls",
                            "latitude": "34.09234000",
                            "longitude": "-82.59569000"
                        },
                        {
                            "id": 113264,
                            "name": "Camden",
                            "latitude": "34.24654000",
                            "longitude": "-80.60702000"
                        },
                        {
                            "id": 113327,
                            "name": "Cane Savannah",
                            "latitude": "33.90182000",
                            "longitude": "-80.45036000"
                        },
                        {
                            "id": 113566,
                            "name": "Catawba",
                            "latitude": "34.85292000",
                            "longitude": "-80.91119000"
                        },
                        {
                            "id": 113591,
                            "name": "Cayce",
                            "latitude": "33.96571000",
                            "longitude": "-81.07398000"
                        },
                        {
                            "id": 113656,
                            "name": "Centerville",
                            "latitude": "34.53205000",
                            "longitude": "-82.70402000"
                        },
                        {
                            "id": 113665,
                            "name": "Central",
                            "latitude": "34.72427000",
                            "longitude": "-82.78125000"
                        },
                        {
                            "id": 113743,
                            "name": "Chapin",
                            "latitude": "34.16598000",
                            "longitude": "-81.34982000"
                        },
                        {
                            "id": 113765,
                            "name": "Charleston",
                            "latitude": "32.77657000",
                            "longitude": "-79.93092000"
                        },
                        {
                            "id": 113769,
                            "name": "Charleston County",
                            "latitude": "32.74917000",
                            "longitude": "-79.94202000"
                        },
                        {
                            "id": 113844,
                            "name": "Cheraw",
                            "latitude": "34.69766000",
                            "longitude": "-79.88340000"
                        },
                        {
                            "id": 113854,
                            "name": "Cherokee County",
                            "latitude": "35.04820000",
                            "longitude": "-81.62039000"
                        },
                        {
                            "id": 113869,
                            "name": "Cherryvale",
                            "latitude": "33.95571000",
                            "longitude": "-80.45814000"
                        },
                        {
                            "id": 113885,
                            "name": "Chester",
                            "latitude": "34.70486000",
                            "longitude": "-81.21426000"
                        },
                        {
                            "id": 113895,
                            "name": "Chester County",
                            "latitude": "34.69206000",
                            "longitude": "-81.15953000"
                        },
                        {
                            "id": 113902,
                            "name": "Chesterfield",
                            "latitude": "34.73599000",
                            "longitude": "-80.08812000"
                        },
                        {
                            "id": 113906,
                            "name": "Chesterfield County",
                            "latitude": "34.63978000",
                            "longitude": "-80.15872000"
                        },
                        {
                            "id": 114044,
                            "name": "City View",
                            "latitude": "34.86151000",
                            "longitude": "-82.43151000"
                        },
                        {
                            "id": 114113,
                            "name": "Clarendon County",
                            "latitude": "33.66581000",
                            "longitude": "-80.21640000"
                        },
                        {
                            "id": 114224,
                            "name": "Clearwater",
                            "latitude": "33.49680000",
                            "longitude": "-81.89206000"
                        },
                        {
                            "id": 114234,
                            "name": "Clemson",
                            "latitude": "34.68344000",
                            "longitude": "-82.83737000"
                        },
                        {
                            "id": 114277,
                            "name": "Clinton",
                            "latitude": "34.47263000",
                            "longitude": "-81.88066000"
                        },
                        {
                            "id": 114307,
                            "name": "Clover",
                            "latitude": "35.11125000",
                            "longitude": "-81.22646000"
                        },
                        {
                            "id": 114421,
                            "name": "Colleton County",
                            "latitude": "32.84343000",
                            "longitude": "-80.64968000"
                        },
                        {
                            "id": 114471,
                            "name": "Columbia",
                            "latitude": "34.00071000",
                            "longitude": "-81.03481000"
                        },
                        {
                            "id": 114583,
                            "name": "Conway",
                            "latitude": "33.83600000",
                            "longitude": "-79.04781000"
                        },
                        {
                            "id": 114750,
                            "name": "Cowpens",
                            "latitude": "35.01679000",
                            "longitude": "-81.80399000"
                        },
                        {
                            "id": 115007,
                            "name": "Dalzell",
                            "latitude": "34.01682000",
                            "longitude": "-80.43008000"
                        },
                        {
                            "id": 115049,
                            "name": "Darlington",
                            "latitude": "34.29988000",
                            "longitude": "-79.87617000"
                        },
                        {
                            "id": 115051,
                            "name": "Darlington County",
                            "latitude": "34.33235000",
                            "longitude": "-79.95769000"
                        },
                        {
                            "id": 115239,
                            "name": "Denmark",
                            "latitude": "33.32265000",
                            "longitude": "-81.14232000"
                        },
                        {
                            "id": 115249,
                            "name": "Dentsville",
                            "latitude": "34.06404000",
                            "longitude": "-80.95815000"
                        },
                        {
                            "id": 115342,
                            "name": "Dillon",
                            "latitude": "34.41655000",
                            "longitude": "-79.37116000"
                        },
                        {
                            "id": 115344,
                            "name": "Dillon County",
                            "latitude": "34.39152000",
                            "longitude": "-79.37893000"
                        },
                        {
                            "id": 115411,
                            "name": "Dorchester County",
                            "latitude": "33.07949000",
                            "longitude": "-80.40556000"
                        },
                        {
                            "id": 115506,
                            "name": "Due West",
                            "latitude": "34.33345000",
                            "longitude": "-82.38790000"
                        },
                        {
                            "id": 115522,
                            "name": "Duncan",
                            "latitude": "34.93790000",
                            "longitude": "-82.14511000"
                        },
                        {
                            "id": 115533,
                            "name": "Dunean",
                            "latitude": "34.82484000",
                            "longitude": "-82.41929000"
                        },
                        {
                            "id": 115625,
                            "name": "Easley",
                            "latitude": "34.82984000",
                            "longitude": "-82.60152000"
                        },
                        {
                            "id": 115666,
                            "name": "East Gaffney",
                            "latitude": "35.08013000",
                            "longitude": "-81.63287000"
                        },
                        {
                            "id": 115762,
                            "name": "East Sumter",
                            "latitude": "33.92544000",
                            "longitude": "-80.29619000"
                        },
                        {
                            "id": 115842,
                            "name": "Edgefield",
                            "latitude": "33.78958000",
                            "longitude": "-81.92956000"
                        },
                        {
                            "id": 115843,
                            "name": "Edgefield County",
                            "latitude": "33.77229000",
                            "longitude": "-81.96658000"
                        },
                        {
                            "id": 115876,
                            "name": "Edisto",
                            "latitude": "33.47654000",
                            "longitude": "-80.89870000"
                        },
                        {
                            "id": 115963,
                            "name": "Elgin",
                            "latitude": "34.67293000",
                            "longitude": "-80.71896000"
                        },
                        {
                            "id": 116211,
                            "name": "Estill",
                            "latitude": "32.75489000",
                            "longitude": "-81.24205000"
                        },
                        {
                            "id": 116239,
                            "name": "Eureka Mill",
                            "latitude": "34.71764000",
                            "longitude": "-81.19370000"
                        },
                        {
                            "id": 116301,
                            "name": "Fairfax",
                            "latitude": "32.95905000",
                            "longitude": "-81.23650000"
                        },
                        {
                            "id": 116319,
                            "name": "Fairfield County",
                            "latitude": "34.39511000",
                            "longitude": "-81.12123000"
                        },
                        {
                            "id": 116324,
                            "name": "Fairforest",
                            "latitude": "34.95651000",
                            "longitude": "-82.01011000"
                        },
                        {
                            "id": 116539,
                            "name": "Five Forks",
                            "latitude": "34.80484000",
                            "longitude": "-82.22956000"
                        },
                        {
                            "id": 116580,
                            "name": "Florence",
                            "latitude": "34.19543000",
                            "longitude": "-79.76256000"
                        },
                        {
                            "id": 116587,
                            "name": "Florence County",
                            "latitude": "34.02439000",
                            "longitude": "-79.70282000"
                        },
                        {
                            "id": 116623,
                            "name": "Folly Beach",
                            "latitude": "32.65518000",
                            "longitude": "-79.94037000"
                        },
                        {
                            "id": 116645,
                            "name": "Forest Acres",
                            "latitude": "34.01932000",
                            "longitude": "-80.98981000"
                        },
                        {
                            "id": 116668,
                            "name": "Forestbrook",
                            "latitude": "33.72239000",
                            "longitude": "-78.95809000"
                        },
                        {
                            "id": 116730,
                            "name": "Fort Mill",
                            "latitude": "35.00737000",
                            "longitude": "-80.94508000"
                        },
                        {
                            "id": 116783,
                            "name": "Fountain Inn",
                            "latitude": "34.68901000",
                            "longitude": "-82.19567000"
                        },
                        {
                            "id": 117005,
                            "name": "Gadsden",
                            "latitude": "33.84571000",
                            "longitude": "-80.76592000"
                        },
                        {
                            "id": 117007,
                            "name": "Gaffney",
                            "latitude": "35.07179000",
                            "longitude": "-81.64982000"
                        },
                        {
                            "id": 117051,
                            "name": "Gantt",
                            "latitude": "34.80012000",
                            "longitude": "-82.42429000"
                        },
                        {
                            "id": 117056,
                            "name": "Garden City",
                            "latitude": "33.59295000",
                            "longitude": "-79.00865000"
                        },
                        {
                            "id": 117114,
                            "name": "Gaston",
                            "latitude": "33.81710000",
                            "longitude": "-81.10093000"
                        },
                        {
                            "id": 117159,
                            "name": "Georgetown",
                            "latitude": "33.37683000",
                            "longitude": "-79.29450000"
                        },
                        {
                            "id": 117166,
                            "name": "Georgetown County",
                            "latitude": "33.41275000",
                            "longitude": "-79.29934000"
                        },
                        {
                            "id": 117329,
                            "name": "Gloverville",
                            "latitude": "33.52597000",
                            "longitude": "-81.83011000"
                        },
                        {
                            "id": 117350,
                            "name": "Golden Grove",
                            "latitude": "34.73401000",
                            "longitude": "-82.44374000"
                        },
                        {
                            "id": 117394,
                            "name": "Goose Creek",
                            "latitude": "32.98101000",
                            "longitude": "-80.03259000"
                        },
                        {
                            "id": 117502,
                            "name": "Graniteville",
                            "latitude": "33.56375000",
                            "longitude": "-81.80789000"
                        },
                        {
                            "id": 117571,
                            "name": "Great Falls",
                            "latitude": "34.57514000",
                            "longitude": "-80.90202000"
                        },
                        {
                            "id": 117688,
                            "name": "Greenville",
                            "latitude": "34.85262000",
                            "longitude": "-82.39401000"
                        },
                        {
                            "id": 117697,
                            "name": "Greenville County",
                            "latitude": "34.89431000",
                            "longitude": "-82.37072000"
                        },
                        {
                            "id": 117706,
                            "name": "Greenwood",
                            "latitude": "34.19540000",
                            "longitude": "-82.16179000"
                        },
                        {
                            "id": 117710,
                            "name": "Greenwood County",
                            "latitude": "34.15383000",
                            "longitude": "-82.12593000"
                        },
                        {
                            "id": 117713,
                            "name": "Greer",
                            "latitude": "34.93873000",
                            "longitude": "-82.22706000"
                        },
                        {
                            "id": 117929,
                            "name": "Hampton",
                            "latitude": "32.87794000",
                            "longitude": "-81.12761000"
                        },
                        {
                            "id": 117937,
                            "name": "Hampton County",
                            "latitude": "32.77628000",
                            "longitude": "-81.14070000"
                        },
                        {
                            "id": 117942,
                            "name": "Hanahan",
                            "latitude": "32.91851000",
                            "longitude": "-80.02203000"
                        },
                        {
                            "id": 117994,
                            "name": "Hardeeville",
                            "latitude": "32.28714000",
                            "longitude": "-81.08067000"
                        },
                        {
                            "id": 118104,
                            "name": "Hartsville",
                            "latitude": "34.37404000",
                            "longitude": "-80.07340000"
                        },
                        {
                            "id": 118474,
                            "name": "Hilton Head",
                            "latitude": "32.21632000",
                            "longitude": "-80.75261000"
                        },
                        {
                            "id": 118475,
                            "name": "Hilton Head Island",
                            "latitude": "32.19382000",
                            "longitude": "-80.73816000"
                        },
                        {
                            "id": 118557,
                            "name": "Holly Hill",
                            "latitude": "33.32266000",
                            "longitude": "-80.41370000"
                        },
                        {
                            "id": 118566,
                            "name": "Hollywood",
                            "latitude": "32.73434000",
                            "longitude": "-80.24177000"
                        },
                        {
                            "id": 118591,
                            "name": "Homeland Park",
                            "latitude": "34.47066000",
                            "longitude": "-82.67069000"
                        },
                        {
                            "id": 118616,
                            "name": "Honea Path",
                            "latitude": "34.44650000",
                            "longitude": "-82.39151000"
                        },
                        {
                            "id": 118651,
                            "name": "Hopkins",
                            "latitude": "33.90432000",
                            "longitude": "-80.87703000"
                        },
                        {
                            "id": 118669,
                            "name": "Horry County",
                            "latitude": "33.90448000",
                            "longitude": "-78.97615000"
                        },
                        {
                            "id": 118900,
                            "name": "India Hook",
                            "latitude": "35.00737000",
                            "longitude": "-81.02174000"
                        },
                        {
                            "id": 118945,
                            "name": "Inman",
                            "latitude": "35.04706000",
                            "longitude": "-82.09011000"
                        },
                        {
                            "id": 118946,
                            "name": "Inman Mills",
                            "latitude": "35.04151000",
                            "longitude": "-82.10428000"
                        },
                        {
                            "id": 118987,
                            "name": "Irmo",
                            "latitude": "34.08598000",
                            "longitude": "-81.18315000"
                        },
                        {
                            "id": 119011,
                            "name": "Irwin",
                            "latitude": "34.69376000",
                            "longitude": "-80.82229000"
                        },
                        {
                            "id": 119032,
                            "name": "Isle of Palms",
                            "latitude": "32.78684000",
                            "longitude": "-79.79480000"
                        },
                        {
                            "id": 119047,
                            "name": "Iva",
                            "latitude": "34.30650000",
                            "longitude": "-82.66374000"
                        },
                        {
                            "id": 119069,
                            "name": "Jackson",
                            "latitude": "33.32542000",
                            "longitude": "-81.78789000"
                        },
                        {
                            "id": 119117,
                            "name": "James Island",
                            "latitude": "32.72374000",
                            "longitude": "-79.96284000"
                        },
                        {
                            "id": 119149,
                            "name": "Jasper County",
                            "latitude": "32.42195000",
                            "longitude": "-81.02327000"
                        },
                        {
                            "id": 119250,
                            "name": "Joanna",
                            "latitude": "34.41491000",
                            "longitude": "-81.81240000"
                        },
                        {
                            "id": 119276,
                            "name": "Johnsonville",
                            "latitude": "33.81794000",
                            "longitude": "-79.44922000"
                        },
                        {
                            "id": 119277,
                            "name": "Johnston",
                            "latitude": "33.83208000",
                            "longitude": "-81.80094000"
                        },
                        {
                            "id": 119327,
                            "name": "Judson",
                            "latitude": "34.83317000",
                            "longitude": "-82.42762000"
                        },
                        {
                            "id": 119520,
                            "name": "Kershaw",
                            "latitude": "34.55182000",
                            "longitude": "-80.58368000"
                        },
                        {
                            "id": 119521,
                            "name": "Kershaw County",
                            "latitude": "34.33876000",
                            "longitude": "-80.59026000"
                        },
                        {
                            "id": 119551,
                            "name": "Kiawah Island",
                            "latitude": "32.60824000",
                            "longitude": "-80.08482000"
                        },
                        {
                            "id": 119634,
                            "name": "Kingstree",
                            "latitude": "33.66766000",
                            "longitude": "-79.83063000"
                        },
                        {
                            "id": 119827,
                            "name": "Ladson",
                            "latitude": "32.98573000",
                            "longitude": "-80.10981000"
                        },
                        {
                            "id": 119881,
                            "name": "Lake City",
                            "latitude": "33.87100000",
                            "longitude": "-79.75535000"
                        },
                        {
                            "id": 119949,
                            "name": "Lake Murray of Richland",
                            "latitude": "34.12048000",
                            "longitude": "-81.26450000"
                        },
                        {
                            "id": 119975,
                            "name": "Lake Secession",
                            "latitude": "34.28455000",
                            "longitude": "-82.59457000"
                        },
                        {
                            "id": 120000,
                            "name": "Lake Wylie",
                            "latitude": "35.10848000",
                            "longitude": "-81.04285000"
                        },
                        {
                            "id": 120045,
                            "name": "Lakewood",
                            "latitude": "33.84683000",
                            "longitude": "-80.35008000"
                        },
                        {
                            "id": 120081,
                            "name": "Lancaster",
                            "latitude": "34.72043000",
                            "longitude": "-80.77090000"
                        },
                        {
                            "id": 120090,
                            "name": "Lancaster County",
                            "latitude": "34.68670000",
                            "longitude": "-80.70543000"
                        },
                        {
                            "id": 120094,
                            "name": "Lancaster Mill",
                            "latitude": "34.70931000",
                            "longitude": "-80.79479000"
                        },
                        {
                            "id": 120104,
                            "name": "Landrum",
                            "latitude": "35.17511000",
                            "longitude": "-82.18928000"
                        },
                        {
                            "id": 120113,
                            "name": "Langley",
                            "latitude": "33.51791000",
                            "longitude": "-81.84400000"
                        },
                        {
                            "id": 120176,
                            "name": "Latta",
                            "latitude": "34.33710000",
                            "longitude": "-79.43116000"
                        },
                        {
                            "id": 120194,
                            "name": "Laurel Bay",
                            "latitude": "32.45019000",
                            "longitude": "-80.78483000"
                        },
                        {
                            "id": 120206,
                            "name": "Laurens",
                            "latitude": "34.49901000",
                            "longitude": "-82.01426000"
                        },
                        {
                            "id": 120209,
                            "name": "Laurens County",
                            "latitude": "34.48357000",
                            "longitude": "-82.00593000"
                        },
                        {
                            "id": 120313,
                            "name": "Lee County",
                            "latitude": "34.16329000",
                            "longitude": "-80.25452000"
                        },
                        {
                            "id": 120329,
                            "name": "Leesville",
                            "latitude": "33.91653000",
                            "longitude": "-81.51344000"
                        },
                        {
                            "id": 120398,
                            "name": "Lesslie",
                            "latitude": "34.89070000",
                            "longitude": "-80.95647000"
                        },
                        {
                            "id": 120443,
                            "name": "Lexington",
                            "latitude": "33.98154000",
                            "longitude": "-81.23621000"
                        },
                        {
                            "id": 120453,
                            "name": "Lexington County",
                            "latitude": "33.90233000",
                            "longitude": "-81.27219000"
                        },
                        {
                            "id": 120464,
                            "name": "Liberty",
                            "latitude": "34.78789000",
                            "longitude": "-82.69236000"
                        },
                        {
                            "id": 120553,
                            "name": "Lincolnville",
                            "latitude": "33.00684000",
                            "longitude": "-80.15537000"
                        },
                        {
                            "id": 120623,
                            "name": "Little River",
                            "latitude": "33.87323000",
                            "longitude": "-78.61418000"
                        },
                        {
                            "id": 120776,
                            "name": "Loris",
                            "latitude": "34.05628000",
                            "longitude": "-78.89030000"
                        },
                        {
                            "id": 120873,
                            "name": "Lugoff",
                            "latitude": "34.22737000",
                            "longitude": "-80.68925000"
                        },
                        {
                            "id": 120909,
                            "name": "Lyman",
                            "latitude": "34.94817000",
                            "longitude": "-82.12733000"
                        },
                        {
                            "id": 121134,
                            "name": "Manning",
                            "latitude": "33.69516000",
                            "longitude": "-80.21091000"
                        },
                        {
                            "id": 121238,
                            "name": "Marion",
                            "latitude": "34.17822000",
                            "longitude": "-79.40061000"
                        },
                        {
                            "id": 121257,
                            "name": "Marion County",
                            "latitude": "34.08006000",
                            "longitude": "-79.36251000"
                        },
                        {
                            "id": 121276,
                            "name": "Marlboro County",
                            "latitude": "34.60199000",
                            "longitude": "-79.67863000"
                        },
                        {
                            "id": 121420,
                            "name": "Mauldin",
                            "latitude": "34.77873000",
                            "longitude": "-82.31012000"
                        },
                        {
                            "id": 121444,
                            "name": "Mayo",
                            "latitude": "35.08401000",
                            "longitude": "-81.85983000"
                        },
                        {
                            "id": 121473,
                            "name": "McColl",
                            "latitude": "34.66877000",
                            "longitude": "-79.54533000"
                        },
                        {
                            "id": 121483,
                            "name": "McCormick",
                            "latitude": "33.91346000",
                            "longitude": "-82.29346000"
                        },
                        {
                            "id": 121484,
                            "name": "McCormick County",
                            "latitude": "33.89955000",
                            "longitude": "-82.30988000"
                        },
                        {
                            "id": 121615,
                            "name": "Meggett",
                            "latitude": "32.71796000",
                            "longitude": "-80.23899000"
                        },
                        {
                            "id": 122034,
                            "name": "Monarch Mill",
                            "latitude": "34.71610000",
                            "longitude": "-81.58500000"
                        },
                        {
                            "id": 122035,
                            "name": "Moncks Corner",
                            "latitude": "33.19632000",
                            "longitude": "-80.01429000"
                        },
                        {
                            "id": 122376,
                            "name": "Mount Pleasant",
                            "latitude": "32.79407000",
                            "longitude": "-79.86259000"
                        },
                        {
                            "id": 122462,
                            "name": "Mullins",
                            "latitude": "34.20572000",
                            "longitude": "-79.25449000"
                        },
                        {
                            "id": 122489,
                            "name": "Murphys Estates",
                            "latitude": "33.60125000",
                            "longitude": "-81.94428000"
                        },
                        {
                            "id": 122497,
                            "name": "Murrells Inlet",
                            "latitude": "33.55100000",
                            "longitude": "-79.04143000"
                        },
                        {
                            "id": 122520,
                            "name": "Myrtle Beach",
                            "latitude": "33.68906000",
                            "longitude": "-78.88669000"
                        },
                        {
                            "id": 122704,
                            "name": "New Ellenton",
                            "latitude": "33.42153000",
                            "longitude": "-81.68567000"
                        },
                        {
                            "id": 122811,
                            "name": "Newberry",
                            "latitude": "34.27458000",
                            "longitude": "-81.61872000"
                        },
                        {
                            "id": 122813,
                            "name": "Newberry County",
                            "latitude": "34.28981000",
                            "longitude": "-81.60012000"
                        },
                        {
                            "id": 122844,
                            "name": "Newport",
                            "latitude": "34.98986000",
                            "longitude": "-81.10091000"
                        },
                        {
                            "id": 122902,
                            "name": "Ninety Six",
                            "latitude": "34.17513000",
                            "longitude": "-82.02401000"
                        },
                        {
                            "id": 122969,
                            "name": "North Augusta",
                            "latitude": "33.50180000",
                            "longitude": "-81.96512000"
                        },
                        {
                            "id": 123005,
                            "name": "North Charleston",
                            "latitude": "32.85462000",
                            "longitude": "-79.97481000"
                        },
                        {
                            "id": 123036,
                            "name": "North Hartsville",
                            "latitude": "34.39377000",
                            "longitude": "-80.06951000"
                        },
                        {
                            "id": 123070,
                            "name": "North Myrtle Beach",
                            "latitude": "33.81601000",
                            "longitude": "-78.68002000"
                        },
                        {
                            "id": 123156,
                            "name": "Northlake",
                            "latitude": "34.56622000",
                            "longitude": "-82.68402000"
                        },
                        {
                            "id": 123241,
                            "name": "Oak Grove",
                            "latitude": "33.98090000",
                            "longitude": "-81.14286000"
                        },
                        {
                            "id": 123286,
                            "name": "Oakland",
                            "latitude": "33.98293000",
                            "longitude": "-80.48842000"
                        },
                        {
                            "id": 123349,
                            "name": "Oconee County",
                            "latitude": "34.75351000",
                            "longitude": "-83.06588000"
                        },
                        {
                            "id": 123526,
                            "name": "Orangeburg",
                            "latitude": "33.49182000",
                            "longitude": "-80.85565000"
                        },
                        {
                            "id": 123528,
                            "name": "Orangeburg County",
                            "latitude": "33.43899000",
                            "longitude": "-80.80030000"
                        },
                        {
                            "id": 123712,
                            "name": "Pacolet",
                            "latitude": "34.89902000",
                            "longitude": "-81.76177000"
                        },
                        {
                            "id": 123721,
                            "name": "Pageland",
                            "latitude": "34.77321000",
                            "longitude": "-80.39173000"
                        },
                        {
                            "id": 123795,
                            "name": "Pamplico",
                            "latitude": "33.99600000",
                            "longitude": "-79.57006000"
                        },
                        {
                            "id": 123859,
                            "name": "Parker",
                            "latitude": "34.85067000",
                            "longitude": "-82.45346000"
                        },
                        {
                            "id": 124017,
                            "name": "Pendleton",
                            "latitude": "34.65177000",
                            "longitude": "-82.78375000"
                        },
                        {
                            "id": 124155,
                            "name": "Pickens",
                            "latitude": "34.88345000",
                            "longitude": "-82.70736000"
                        },
                        {
                            "id": 124158,
                            "name": "Pickens County",
                            "latitude": "34.88752000",
                            "longitude": "-82.72532000"
                        },
                        {
                            "id": 124166,
                            "name": "Piedmont",
                            "latitude": "34.70234000",
                            "longitude": "-82.46457000"
                        },
                        {
                            "id": 124256,
                            "name": "Pineridge",
                            "latitude": "33.91043000",
                            "longitude": "-81.10454000"
                        },
                        {
                            "id": 124531,
                            "name": "Port Royal",
                            "latitude": "32.37908000",
                            "longitude": "-80.69261000"
                        },
                        {
                            "id": 124610,
                            "name": "Powdersville",
                            "latitude": "34.79178000",
                            "longitude": "-82.49291000"
                        },
                        {
                            "id": 124699,
                            "name": "Privateer",
                            "latitude": "33.83321000",
                            "longitude": "-80.41425000"
                        },
                        {
                            "id": 124712,
                            "name": "Prosperity",
                            "latitude": "34.20931000",
                            "longitude": "-81.53316000"
                        },
                        {
                            "id": 124906,
                            "name": "Ravenel",
                            "latitude": "32.76323000",
                            "longitude": "-80.25010000"
                        },
                        {
                            "id": 124936,
                            "name": "Red Bank",
                            "latitude": "33.93209000",
                            "longitude": "-81.23843000"
                        },
                        {
                            "id": 124946,
                            "name": "Red Hill",
                            "latitude": "33.82073000",
                            "longitude": "-79.01892000"
                        },
                        {
                            "id": 125075,
                            "name": "Richland County",
                            "latitude": "34.02180000",
                            "longitude": "-80.90304000"
                        },
                        {
                            "id": 125124,
                            "name": "Ridgeland",
                            "latitude": "32.48074000",
                            "longitude": "-80.98039000"
                        },
                        {
                            "id": 125129,
                            "name": "Ridgeville",
                            "latitude": "33.09572000",
                            "longitude": "-80.31537000"
                        },
                        {
                            "id": 125280,
                            "name": "Rock Hill",
                            "latitude": "34.92487000",
                            "longitude": "-81.02508000"
                        },
                        {
                            "id": 125344,
                            "name": "Roebuck",
                            "latitude": "34.87957000",
                            "longitude": "-81.96621000"
                        },
                        {
                            "id": 125597,
                            "name": "Saint Andrews",
                            "latitude": "34.04543000",
                            "longitude": "-81.12926000"
                        },
                        {
                            "id": 125642,
                            "name": "Saint George",
                            "latitude": "33.18600000",
                            "longitude": "-80.57565000"
                        },
                        {
                            "id": 125696,
                            "name": "Saint Matthews",
                            "latitude": "33.66488000",
                            "longitude": "-80.77787000"
                        },
                        {
                            "id": 125716,
                            "name": "Saint Stephen",
                            "latitude": "33.40433000",
                            "longitude": "-79.92174000"
                        },
                        {
                            "id": 125772,
                            "name": "Saluda",
                            "latitude": "34.00152000",
                            "longitude": "-81.77205000"
                        },
                        {
                            "id": 125774,
                            "name": "Saluda County",
                            "latitude": "34.00614000",
                            "longitude": "-81.72692000"
                        },
                        {
                            "id": 125890,
                            "name": "Sangaree",
                            "latitude": "33.03545000",
                            "longitude": "-80.12787000"
                        },
                        {
                            "id": 125897,
                            "name": "Sans Souci",
                            "latitude": "34.87790000",
                            "longitude": "-82.42401000"
                        },
                        {
                            "id": 125984,
                            "name": "Saxon",
                            "latitude": "34.96123000",
                            "longitude": "-81.96733000"
                        },
                        {
                            "id": 126082,
                            "name": "Seabrook Island",
                            "latitude": "32.57713000",
                            "longitude": "-80.17065000"
                        },
                        {
                            "id": 126156,
                            "name": "Seneca",
                            "latitude": "34.68566000",
                            "longitude": "-82.95320000"
                        },
                        {
                            "id": 126174,
                            "name": "Seven Oaks",
                            "latitude": "34.04876000",
                            "longitude": "-81.14648000"
                        },
                        {
                            "id": 126291,
                            "name": "Shell Point",
                            "latitude": "32.38353000",
                            "longitude": "-80.73594000"
                        },
                        {
                            "id": 126452,
                            "name": "Simpsonville",
                            "latitude": "34.73706000",
                            "longitude": "-82.25428000"
                        },
                        {
                            "id": 126491,
                            "name": "Slater-Marietta",
                            "latitude": "35.03472000",
                            "longitude": "-82.49265000"
                        },
                        {
                            "id": 126554,
                            "name": "Socastee",
                            "latitude": "33.68350000",
                            "longitude": "-78.99837000"
                        },
                        {
                            "id": 126641,
                            "name": "South Congaree",
                            "latitude": "33.91099000",
                            "longitude": "-81.13565000"
                        },
                        {
                            "id": 126732,
                            "name": "South Sumter",
                            "latitude": "33.90571000",
                            "longitude": "-80.34619000"
                        },
                        {
                            "id": 126769,
                            "name": "Southern Shops",
                            "latitude": "34.98595000",
                            "longitude": "-81.99455000"
                        },
                        {
                            "id": 126814,
                            "name": "Spartanburg",
                            "latitude": "34.94957000",
                            "longitude": "-81.93205000"
                        },
                        {
                            "id": 126815,
                            "name": "Spartanburg County",
                            "latitude": "34.93126000",
                            "longitude": "-81.99068000"
                        },
                        {
                            "id": 126886,
                            "name": "Springdale",
                            "latitude": "33.95932000",
                            "longitude": "-81.10898000"
                        },
                        {
                            "id": 126994,
                            "name": "Stateburg",
                            "latitude": "33.95765000",
                            "longitude": "-80.53481000"
                        },
                        {
                            "id": 127192,
                            "name": "Sullivans Island",
                            "latitude": "32.76323000",
                            "longitude": "-79.83675000"
                        },
                        {
                            "id": 127210,
                            "name": "Summerville",
                            "latitude": "33.01850000",
                            "longitude": "-80.17565000"
                        },
                        {
                            "id": 127227,
                            "name": "Sumter",
                            "latitude": "33.92044000",
                            "longitude": "-80.34147000"
                        },
                        {
                            "id": 127231,
                            "name": "Sumter County",
                            "latitude": "33.91617000",
                            "longitude": "-80.38232000"
                        },
                        {
                            "id": 127288,
                            "name": "Surfside Beach",
                            "latitude": "33.60600000",
                            "longitude": "-78.97309000"
                        },
                        {
                            "id": 127461,
                            "name": "Taylors",
                            "latitude": "34.92039000",
                            "longitude": "-82.29623000"
                        },
                        {
                            "id": 127482,
                            "name": "Tega Cay",
                            "latitude": "35.02431000",
                            "longitude": "-81.02785000"
                        },
                        {
                            "id": 127645,
                            "name": "Tigerville",
                            "latitude": "35.06845000",
                            "longitude": "-82.36845000"
                        },
                        {
                            "id": 127662,
                            "name": "Timmonsville",
                            "latitude": "34.13488000",
                            "longitude": "-79.93979000"
                        },
                        {
                            "id": 127783,
                            "name": "Travelers Rest",
                            "latitude": "34.96762000",
                            "longitude": "-82.44345000"
                        },
                        {
                            "id": 127974,
                            "name": "Union",
                            "latitude": "34.71541000",
                            "longitude": "-81.62371000"
                        },
                        {
                            "id": 127996,
                            "name": "Union County",
                            "latitude": "34.68928000",
                            "longitude": "-81.61942000"
                        },
                        {
                            "id": 128065,
                            "name": "Utica",
                            "latitude": "34.67816000",
                            "longitude": "-82.93154000"
                        },
                        {
                            "id": 128107,
                            "name": "Valley Falls",
                            "latitude": "35.01595000",
                            "longitude": "-81.97483000"
                        },
                        {
                            "id": 128156,
                            "name": "Varnville",
                            "latitude": "32.85044000",
                            "longitude": "-81.07927000"
                        },
                        {
                            "id": 128320,
                            "name": "Wade Hampton",
                            "latitude": "34.90373000",
                            "longitude": "-82.33317000"
                        },
                        {
                            "id": 128382,
                            "name": "Walhalla",
                            "latitude": "34.76482000",
                            "longitude": "-83.06404000"
                        },
                        {
                            "id": 128428,
                            "name": "Walterboro",
                            "latitude": "32.90517000",
                            "longitude": "-80.66677000"
                        },
                        {
                            "id": 128464,
                            "name": "Ware Shoals",
                            "latitude": "34.39845000",
                            "longitude": "-82.24679000"
                        },
                        {
                            "id": 128509,
                            "name": "Warrenville",
                            "latitude": "33.55097000",
                            "longitude": "-81.80400000"
                        },
                        {
                            "id": 128643,
                            "name": "Watts Mills",
                            "latitude": "34.51641000",
                            "longitude": "-81.98580000"
                        },
                        {
                            "id": 128748,
                            "name": "Wedgefield",
                            "latitude": "33.89266000",
                            "longitude": "-80.51814000"
                        },
                        {
                            "id": 128749,
                            "name": "Wedgewood",
                            "latitude": "33.88377000",
                            "longitude": "-80.51258000"
                        },
                        {
                            "id": 128767,
                            "name": "Welcome",
                            "latitude": "34.82651000",
                            "longitude": "-82.43901000"
                        },
                        {
                            "id": 128775,
                            "name": "Wellford",
                            "latitude": "34.95095000",
                            "longitude": "-82.10594000"
                        },
                        {
                            "id": 128848,
                            "name": "West Columbia",
                            "latitude": "33.99349000",
                            "longitude": "-81.07398000"
                        },
                        {
                            "id": 129045,
                            "name": "Westminster",
                            "latitude": "34.66482000",
                            "longitude": "-83.09654000"
                        },
                        {
                            "id": 129205,
                            "name": "Whitmire",
                            "latitude": "34.50291000",
                            "longitude": "-81.61149000"
                        },
                        {
                            "id": 129252,
                            "name": "Wilkinson Heights",
                            "latitude": "33.50210000",
                            "longitude": "-80.83315000"
                        },
                        {
                            "id": 129272,
                            "name": "Williamsburg County",
                            "latitude": "33.61993000",
                            "longitude": "-79.72771000"
                        },
                        {
                            "id": 129283,
                            "name": "Williamston",
                            "latitude": "34.61845000",
                            "longitude": "-82.47791000"
                        },
                        {
                            "id": 129297,
                            "name": "Williston",
                            "latitude": "33.40265000",
                            "longitude": "-81.42011000"
                        },
                        {
                            "id": 129419,
                            "name": "Winnsboro",
                            "latitude": "34.38070000",
                            "longitude": "-81.08648000"
                        },
                        {
                            "id": 129421,
                            "name": "Winnsboro Mills",
                            "latitude": "34.36181000",
                            "longitude": "-81.08537000"
                        },
                        {
                            "id": 129517,
                            "name": "Woodfield",
                            "latitude": "34.05932000",
                            "longitude": "-80.93092000"
                        },
                        {
                            "id": 129547,
                            "name": "Woodruff",
                            "latitude": "34.73957000",
                            "longitude": "-82.03705000"
                        },
                        {
                            "id": 129691,
                            "name": "York",
                            "latitude": "34.99430000",
                            "longitude": "-81.24202000"
                        },
                        {
                            "id": 129695,
                            "name": "York County",
                            "latitude": "34.97475000",
                            "longitude": "-81.18442000"
                        }
                    ]
                },
                {
                    "id": 1445,
                    "name": "South Dakota",
                    "state_code": "SD",
                    "cities": [
                        {
                            "id": 110977,
                            "name": "Aberdeen",
                            "latitude": "45.46470000",
                            "longitude": "-98.48648000"
                        },
                        {
                            "id": 111156,
                            "name": "Alexandria",
                            "latitude": "43.65359000",
                            "longitude": "-97.78285000"
                        },
                        {
                            "id": 111479,
                            "name": "Armour",
                            "latitude": "43.31860000",
                            "longitude": "-98.34675000"
                        },
                        {
                            "id": 111664,
                            "name": "Aurora County",
                            "latitude": "43.71801000",
                            "longitude": "-98.56155000"
                        },
                        {
                            "id": 111782,
                            "name": "Baltic",
                            "latitude": "43.76136000",
                            "longitude": "-96.74033000"
                        },
                        {
                            "id": 111970,
                            "name": "Beadle County",
                            "latitude": "44.41448000",
                            "longitude": "-98.27811000"
                        },
                        {
                            "id": 112081,
                            "name": "Belle Fourche",
                            "latitude": "44.67137000",
                            "longitude": "-103.85215000"
                        },
                        {
                            "id": 112174,
                            "name": "Bennett County",
                            "latitude": "43.19492000",
                            "longitude": "-101.66397000"
                        },
                        {
                            "id": 112215,
                            "name": "Beresford",
                            "latitude": "43.08054000",
                            "longitude": "-96.77366000"
                        },
                        {
                            "id": 112368,
                            "name": "Bison",
                            "latitude": "45.52026000",
                            "longitude": "-102.46127000"
                        },
                        {
                            "id": 112388,
                            "name": "Blackhawk",
                            "latitude": "44.15110000",
                            "longitude": "-103.30796000"
                        },
                        {
                            "id": 112528,
                            "name": "Bon Homme County",
                            "latitude": "42.98837000",
                            "longitude": "-97.88463000"
                        },
                        {
                            "id": 112636,
                            "name": "Box Elder",
                            "latitude": "44.11249000",
                            "longitude": "-103.06823000"
                        },
                        {
                            "id": 112691,
                            "name": "Brandon",
                            "latitude": "43.59470000",
                            "longitude": "-96.57199000"
                        },
                        {
                            "id": 112819,
                            "name": "Britton",
                            "latitude": "45.79162000",
                            "longitude": "-97.75094000"
                        },
                        {
                            "id": 112861,
                            "name": "Brookings",
                            "latitude": "44.31136000",
                            "longitude": "-96.79839000"
                        },
                        {
                            "id": 112863,
                            "name": "Brookings County",
                            "latitude": "44.36968000",
                            "longitude": "-96.79042000"
                        },
                        {
                            "id": 112913,
                            "name": "Brown County",
                            "latitude": "45.58972000",
                            "longitude": "-98.35161000"
                        },
                        {
                            "id": 112945,
                            "name": "Brule County",
                            "latitude": "43.71806000",
                            "longitude": "-99.08094000"
                        },
                        {
                            "id": 113015,
                            "name": "Buffalo",
                            "latitude": "45.58416000",
                            "longitude": "-103.54603000"
                        },
                        {
                            "id": 113019,
                            "name": "Buffalo County",
                            "latitude": "44.07635000",
                            "longitude": "-99.20496000"
                        },
                        {
                            "id": 113055,
                            "name": "Burke",
                            "latitude": "43.18250000",
                            "longitude": "-99.29205000"
                        },
                        {
                            "id": 113127,
                            "name": "Butte County",
                            "latitude": "44.90583000",
                            "longitude": "-103.50802000"
                        },
                        {
                            "id": 113302,
                            "name": "Campbell County",
                            "latitude": "45.77113000",
                            "longitude": "-100.05163000"
                        },
                        {
                            "id": 113350,
                            "name": "Canton",
                            "latitude": "43.30081000",
                            "longitude": "-96.59282000"
                        },
                        {
                            "id": 113718,
                            "name": "Chamberlain",
                            "latitude": "43.81083000",
                            "longitude": "-99.33066000"
                        },
                        {
                            "id": 113758,
                            "name": "Charles Mix County",
                            "latitude": "43.20791000",
                            "longitude": "-98.58789000"
                        },
                        {
                            "id": 114120,
                            "name": "Clark",
                            "latitude": "44.87774000",
                            "longitude": "-97.73314000"
                        },
                        {
                            "id": 114127,
                            "name": "Clark County",
                            "latitude": "44.85825000",
                            "longitude": "-97.72950000"
                        },
                        {
                            "id": 114190,
                            "name": "Clay County",
                            "latitude": "42.91465000",
                            "longitude": "-96.97566000"
                        },
                        {
                            "id": 114210,
                            "name": "Clear Lake",
                            "latitude": "44.74580000",
                            "longitude": "-96.68256000"
                        },
                        {
                            "id": 114363,
                            "name": "Codington County",
                            "latitude": "44.97785000",
                            "longitude": "-97.18862000"
                        },
                        {
                            "id": 114451,
                            "name": "Colonial Pine Hills",
                            "latitude": "44.00777000",
                            "longitude": "-103.31546000"
                        },
                        {
                            "id": 114671,
                            "name": "Corson County",
                            "latitude": "45.70860000",
                            "longitude": "-101.19687000"
                        },
                        {
                            "id": 114839,
                            "name": "Crooks",
                            "latitude": "43.66470000",
                            "longitude": "-96.81089000"
                        },
                        {
                            "id": 114934,
                            "name": "Custer",
                            "latitude": "43.76665000",
                            "longitude": "-103.59881000"
                        },
                        {
                            "id": 114940,
                            "name": "Custer County",
                            "latitude": "43.67763000",
                            "longitude": "-103.45154000"
                        },
                        {
                            "id": 114978,
                            "name": "Dakota Dunes",
                            "latitude": "42.48749000",
                            "longitude": "-96.48642000"
                        },
                        {
                            "id": 115079,
                            "name": "Davison County",
                            "latitude": "43.67474000",
                            "longitude": "-98.14600000"
                        },
                        {
                            "id": 115089,
                            "name": "Day County",
                            "latitude": "45.36715000",
                            "longitude": "-97.60741000"
                        },
                        {
                            "id": 115114,
                            "name": "De Smet",
                            "latitude": "44.38747000",
                            "longitude": "-97.55035000"
                        },
                        {
                            "id": 115142,
                            "name": "Deadwood",
                            "latitude": "44.37665000",
                            "longitude": "-103.72964000"
                        },
                        {
                            "id": 115214,
                            "name": "Dell Rapids",
                            "latitude": "43.82608000",
                            "longitude": "-96.70616000"
                        },
                        {
                            "id": 115294,
                            "name": "Deuel County",
                            "latitude": "44.76006000",
                            "longitude": "-96.66797000"
                        },
                        {
                            "id": 115304,
                            "name": "Dewey County",
                            "latitude": "45.15662000",
                            "longitude": "-100.87186000"
                        },
                        {
                            "id": 115431,
                            "name": "Douglas County",
                            "latitude": "43.38692000",
                            "longitude": "-98.36610000"
                        },
                        {
                            "id": 115559,
                            "name": "Dupree",
                            "latitude": "45.04748000",
                            "longitude": "-101.60099000"
                        },
                        {
                            "id": 115599,
                            "name": "Eagle Butte",
                            "latitude": "45.00248000",
                            "longitude": "-101.23349000"
                        },
                        {
                            "id": 115883,
                            "name": "Edmunds County",
                            "latitude": "45.41880000",
                            "longitude": "-99.21533000"
                        },
                        {
                            "id": 115990,
                            "name": "Elk Point",
                            "latitude": "42.68333000",
                            "longitude": "-96.68365000"
                        },
                        {
                            "id": 116375,
                            "name": "Fall River County",
                            "latitude": "43.23939000",
                            "longitude": "-103.52756000"
                        },
                        {
                            "id": 116433,
                            "name": "Faulk County",
                            "latitude": "45.07101000",
                            "longitude": "-99.14525000"
                        },
                        {
                            "id": 116435,
                            "name": "Faulkton",
                            "latitude": "45.03497000",
                            "longitude": "-99.12400000"
                        },
                        {
                            "id": 116549,
                            "name": "Flandreau",
                            "latitude": "44.04942000",
                            "longitude": "-96.59532000"
                        },
                        {
                            "id": 116742,
                            "name": "Fort Pierre",
                            "latitude": "44.35359000",
                            "longitude": "-100.37374000"
                        },
                        {
                            "id": 116755,
                            "name": "Fort Thompson",
                            "latitude": "44.06860000",
                            "longitude": "-99.43788000"
                        },
                        {
                            "id": 116908,
                            "name": "Freeman",
                            "latitude": "43.35249000",
                            "longitude": "-97.43729000"
                        },
                        {
                            "id": 117097,
                            "name": "Garretson",
                            "latitude": "43.71747000",
                            "longitude": "-96.50282000"
                        },
                        {
                            "id": 117180,
                            "name": "Gettysburg",
                            "latitude": "45.01165000",
                            "longitude": "-99.95567000"
                        },
                        {
                            "id": 117514,
                            "name": "Grant County",
                            "latitude": "45.17194000",
                            "longitude": "-96.76769000"
                        },
                        {
                            "id": 117717,
                            "name": "Gregory",
                            "latitude": "43.23222000",
                            "longitude": "-99.43038000"
                        },
                        {
                            "id": 117718,
                            "name": "Gregory County",
                            "latitude": "43.19238000",
                            "longitude": "-99.18561000"
                        },
                        {
                            "id": 117751,
                            "name": "Groton",
                            "latitude": "45.44746000",
                            "longitude": "-98.09871000"
                        },
                        {
                            "id": 117823,
                            "name": "Haakon County",
                            "latitude": "44.29443000",
                            "longitude": "-101.53999000"
                        },
                        {
                            "id": 117911,
                            "name": "Hamlin County",
                            "latitude": "44.67376000",
                            "longitude": "-97.18833000"
                        },
                        {
                            "id": 117959,
                            "name": "Hand County",
                            "latitude": "44.54778000",
                            "longitude": "-99.00494000"
                        },
                        {
                            "id": 117979,
                            "name": "Hanson County",
                            "latitude": "43.67482000",
                            "longitude": "-97.78734000"
                        },
                        {
                            "id": 118005,
                            "name": "Harding County",
                            "latitude": "45.58035000",
                            "longitude": "-103.49577000"
                        },
                        {
                            "id": 118051,
                            "name": "Harrisburg",
                            "latitude": "43.43137000",
                            "longitude": "-96.69727000"
                        },
                        {
                            "id": 118093,
                            "name": "Hartford",
                            "latitude": "43.62303000",
                            "longitude": "-96.94255000"
                        },
                        {
                            "id": 118195,
                            "name": "Hayti",
                            "latitude": "44.65719000",
                            "longitude": "-97.20507000"
                        },
                        {
                            "id": 118422,
                            "name": "Highmore",
                            "latitude": "44.52137000",
                            "longitude": "-99.44150000"
                        },
                        {
                            "id": 118683,
                            "name": "Hot Springs",
                            "latitude": "43.43165000",
                            "longitude": "-103.47436000"
                        },
                        {
                            "id": 118709,
                            "name": "Howard",
                            "latitude": "44.01081000",
                            "longitude": "-97.52674000"
                        },
                        {
                            "id": 118759,
                            "name": "Hughes County",
                            "latitude": "44.38903000",
                            "longitude": "-99.99605000"
                        },
                        {
                            "id": 118824,
                            "name": "Huron",
                            "latitude": "44.36332000",
                            "longitude": "-98.21426000"
                        },
                        {
                            "id": 118837,
                            "name": "Hutchinson County",
                            "latitude": "43.33485000",
                            "longitude": "-97.75442000"
                        },
                        {
                            "id": 118848,
                            "name": "Hyde County",
                            "latitude": "44.54736000",
                            "longitude": "-99.48711000"
                        },
                        {
                            "id": 118983,
                            "name": "Ipswich",
                            "latitude": "45.44442000",
                            "longitude": "-99.02928000"
                        },
                        {
                            "id": 119096,
                            "name": "Jackson County",
                            "latitude": "43.69421000",
                            "longitude": "-101.62813000"
                        },
                        {
                            "id": 119223,
                            "name": "Jerauld County",
                            "latitude": "44.06635000",
                            "longitude": "-98.62973000"
                        },
                        {
                            "id": 119297,
                            "name": "Jones County",
                            "latitude": "43.96061000",
                            "longitude": "-100.68972000"
                        },
                        {
                            "id": 119350,
                            "name": "Kadoka",
                            "latitude": "43.83388000",
                            "longitude": "-101.50987000"
                        },
                        {
                            "id": 119464,
                            "name": "Kennebec",
                            "latitude": "43.90360000",
                            "longitude": "-99.86178000"
                        },
                        {
                            "id": 119611,
                            "name": "Kingsbury County",
                            "latitude": "44.36959000",
                            "longitude": "-97.49148000"
                        },
                        {
                            "id": 119863,
                            "name": "Lake Andes",
                            "latitude": "43.15638000",
                            "longitude": "-98.54147000"
                        },
                        {
                            "id": 119895,
                            "name": "Lake County",
                            "latitude": "44.02204000",
                            "longitude": "-97.12938000"
                        },
                        {
                            "id": 120238,
                            "name": "Lawrence County",
                            "latitude": "44.35863000",
                            "longitude": "-103.79222000"
                        },
                        {
                            "id": 120270,
                            "name": "Lead",
                            "latitude": "44.35221000",
                            "longitude": "-103.76520000"
                        },
                        {
                            "id": 120357,
                            "name": "Lemmon",
                            "latitude": "45.94083000",
                            "longitude": "-102.15932000"
                        },
                        {
                            "id": 120370,
                            "name": "Lennox",
                            "latitude": "43.35415000",
                            "longitude": "-96.89200000"
                        },
                        {
                            "id": 120381,
                            "name": "Leola",
                            "latitude": "45.72275000",
                            "longitude": "-98.94094000"
                        },
                        {
                            "id": 120528,
                            "name": "Lincoln County",
                            "latitude": "43.27892000",
                            "longitude": "-96.72182000"
                        },
                        {
                            "id": 120911,
                            "name": "Lyman County",
                            "latitude": "43.89582000",
                            "longitude": "-99.84738000"
                        },
                        {
                            "id": 121000,
                            "name": "Madison",
                            "latitude": "44.00608000",
                            "longitude": "-97.11395000"
                        },
                        {
                            "id": 121321,
                            "name": "Marshall County",
                            "latitude": "45.75867000",
                            "longitude": "-97.59850000"
                        },
                        {
                            "id": 121336,
                            "name": "Martin",
                            "latitude": "43.17250000",
                            "longitude": "-101.73265000"
                        },
                        {
                            "id": 121480,
                            "name": "McCook County",
                            "latitude": "43.67431000",
                            "longitude": "-97.36844000"
                        },
                        {
                            "id": 121512,
                            "name": "McIntosh",
                            "latitude": "45.92139000",
                            "longitude": "-101.34958000"
                        },
                        {
                            "id": 121547,
                            "name": "McPherson County",
                            "latitude": "45.76637000",
                            "longitude": "-99.22146000"
                        },
                        {
                            "id": 121558,
                            "name": "Meade County",
                            "latitude": "44.56684000",
                            "longitude": "-102.71687000"
                        },
                        {
                            "id": 121625,
                            "name": "Mellette County",
                            "latitude": "43.58127000",
                            "longitude": "-100.75999000"
                        },
                        {
                            "id": 121842,
                            "name": "Milbank",
                            "latitude": "45.21913000",
                            "longitude": "-96.63562000"
                        },
                        {
                            "id": 121879,
                            "name": "Miller",
                            "latitude": "44.51831000",
                            "longitude": "-98.98843000"
                        },
                        {
                            "id": 121933,
                            "name": "Miner County",
                            "latitude": "44.02195000",
                            "longitude": "-97.61025000"
                        },
                        {
                            "id": 121953,
                            "name": "Minnehaha County",
                            "latitude": "43.67418000",
                            "longitude": "-96.79144000"
                        },
                        {
                            "id": 121982,
                            "name": "Mission",
                            "latitude": "43.30584000",
                            "longitude": "-100.65819000"
                        },
                        {
                            "id": 121997,
                            "name": "Mitchell",
                            "latitude": "43.70943000",
                            "longitude": "-98.02980000"
                        },
                        {
                            "id": 122012,
                            "name": "Mobridge",
                            "latitude": "45.53722000",
                            "longitude": "-100.42791000"
                        },
                        {
                            "id": 122197,
                            "name": "Moody County",
                            "latitude": "44.02201000",
                            "longitude": "-96.67092000"
                        },
                        {
                            "id": 122323,
                            "name": "Mound City",
                            "latitude": "45.72527000",
                            "longitude": "-100.06845000"
                        },
                        {
                            "id": 122479,
                            "name": "Murdo",
                            "latitude": "43.88832000",
                            "longitude": "-100.71291000"
                        },
                        {
                            "id": 123016,
                            "name": "North Eagle Butte",
                            "latitude": "45.00415000",
                            "longitude": "-101.23376000"
                        },
                        {
                            "id": 123106,
                            "name": "North Sioux City",
                            "latitude": "42.52722000",
                            "longitude": "-96.48309000"
                        },
                        {
                            "id": 123109,
                            "name": "North Spearfish",
                            "latitude": "44.50665000",
                            "longitude": "-103.89215000"
                        },
                        {
                            "id": 123374,
                            "name": "Oglala",
                            "latitude": "43.18859000",
                            "longitude": "-102.73962000"
                        },
                        {
                            "id": 123375,
                            "name": "Oglala Lakota County",
                            "latitude": "43.33559000",
                            "longitude": "-102.55162000"
                        },
                        {
                            "id": 123448,
                            "name": "Olivet",
                            "latitude": "43.24082000",
                            "longitude": "-97.67534000"
                        },
                        {
                            "id": 123480,
                            "name": "Onida",
                            "latitude": "44.70804000",
                            "longitude": "-100.05984000"
                        },
                        {
                            "id": 123862,
                            "name": "Parker",
                            "latitude": "43.39748000",
                            "longitude": "-97.13645000"
                        },
                        {
                            "id": 123876,
                            "name": "Parkston",
                            "latitude": "43.39888000",
                            "longitude": "-97.98368000"
                        },
                        {
                            "id": 124031,
                            "name": "Pennington County",
                            "latitude": "44.00373000",
                            "longitude": "-102.82383000"
                        },
                        {
                            "id": 124064,
                            "name": "Perkins County",
                            "latitude": "45.49051000",
                            "longitude": "-102.47563000"
                        },
                        {
                            "id": 124129,
                            "name": "Philip",
                            "latitude": "44.03943000",
                            "longitude": "-101.66514000"
                        },
                        {
                            "id": 124178,
                            "name": "Pierre",
                            "latitude": "44.36832000",
                            "longitude": "-100.35097000"
                        },
                        {
                            "id": 124243,
                            "name": "Pine Ridge",
                            "latitude": "43.02554000",
                            "longitude": "-102.55627000"
                        },
                        {
                            "id": 124341,
                            "name": "Plankinton",
                            "latitude": "43.71555000",
                            "longitude": "-98.48509000"
                        },
                        {
                            "id": 124351,
                            "name": "Platte",
                            "latitude": "43.38694000",
                            "longitude": "-98.84453000"
                        },
                        {
                            "id": 124490,
                            "name": "Porcupine",
                            "latitude": "43.23971000",
                            "longitude": "-102.33099000"
                        },
                        {
                            "id": 124593,
                            "name": "Potter County",
                            "latitude": "45.06450000",
                            "longitude": "-99.95725000"
                        },
                        {
                            "id": 124894,
                            "name": "Rapid City",
                            "latitude": "44.08054000",
                            "longitude": "-103.23101000"
                        },
                        {
                            "id": 124895,
                            "name": "Rapid Valley",
                            "latitude": "44.06249000",
                            "longitude": "-103.14629000"
                        },
                        {
                            "id": 124968,
                            "name": "Redfield",
                            "latitude": "44.87581000",
                            "longitude": "-98.51871000"
                        },
                        {
                            "id": 125242,
                            "name": "Roberts County",
                            "latitude": "45.62965000",
                            "longitude": "-96.94612000"
                        },
                        {
                            "id": 125412,
                            "name": "Rosebud",
                            "latitude": "43.23278000",
                            "longitude": "-100.85348000"
                        },
                        {
                            "id": 125737,
                            "name": "Salem",
                            "latitude": "43.72415000",
                            "longitude": "-97.38895000"
                        },
                        {
                            "id": 125855,
                            "name": "Sanborn County",
                            "latitude": "44.02345000",
                            "longitude": "-98.09139000"
                        },
                        {
                            "id": 126130,
                            "name": "Selby",
                            "latitude": "45.50638000",
                            "longitude": "-100.03207000"
                        },
                        {
                            "id": 126462,
                            "name": "Sioux Falls",
                            "latitude": "43.54997000",
                            "longitude": "-96.70033000"
                        },
                        {
                            "id": 126466,
                            "name": "Sisseton",
                            "latitude": "45.66468000",
                            "longitude": "-97.04980000"
                        },
                        {
                            "id": 126816,
                            "name": "Spearfish",
                            "latitude": "44.49082000",
                            "longitude": "-103.85937000"
                        },
                        {
                            "id": 126836,
                            "name": "Spink County",
                            "latitude": "44.93802000",
                            "longitude": "-98.34619000"
                        },
                        {
                            "id": 126905,
                            "name": "Springfield",
                            "latitude": "42.85417000",
                            "longitude": "-97.89729000"
                        },
                        {
                            "id": 126962,
                            "name": "Stanley County",
                            "latitude": "44.41232000",
                            "longitude": "-100.73594000"
                        },
                        {
                            "id": 127152,
                            "name": "Sturgis",
                            "latitude": "44.40971000",
                            "longitude": "-103.50908000"
                        },
                        {
                            "id": 127193,
                            "name": "Sully County",
                            "latitude": "44.71558000",
                            "longitude": "-100.13220000"
                        },
                        {
                            "id": 127207,
                            "name": "Summerset",
                            "latitude": "44.18998000",
                            "longitude": "-103.34384000"
                        },
                        {
                            "id": 127473,
                            "name": "Tea",
                            "latitude": "43.44637000",
                            "longitude": "-96.83588000"
                        },
                        {
                            "id": 127656,
                            "name": "Timber Lake",
                            "latitude": "45.42915000",
                            "longitude": "-101.07403000"
                        },
                        {
                            "id": 127694,
                            "name": "Todd County",
                            "latitude": "43.19337000",
                            "longitude": "-100.71841000"
                        },
                        {
                            "id": 127832,
                            "name": "Tripp County",
                            "latitude": "43.34587000",
                            "longitude": "-99.88400000"
                        },
                        {
                            "id": 127904,
                            "name": "Turner County",
                            "latitude": "43.31087000",
                            "longitude": "-97.14866000"
                        },
                        {
                            "id": 127945,
                            "name": "Tyndall",
                            "latitude": "42.99333000",
                            "longitude": "-97.86285000"
                        },
                        {
                            "id": 128001,
                            "name": "Union County",
                            "latitude": "42.83249000",
                            "longitude": "-96.65609000"
                        },
                        {
                            "id": 128185,
                            "name": "Vermillion",
                            "latitude": "42.77944000",
                            "longitude": "-96.92921000"
                        },
                        {
                            "id": 128300,
                            "name": "Volga",
                            "latitude": "44.32358000",
                            "longitude": "-96.92645000"
                        },
                        {
                            "id": 128328,
                            "name": "Wagner",
                            "latitude": "43.07972000",
                            "longitude": "-98.29313000"
                        },
                        {
                            "id": 128441,
                            "name": "Walworth County",
                            "latitude": "45.42995000",
                            "longitude": "-100.03156000"
                        },
                        {
                            "id": 128625,
                            "name": "Watertown",
                            "latitude": "44.89941000",
                            "longitude": "-97.11507000"
                        },
                        {
                            "id": 128734,
                            "name": "Webster",
                            "latitude": "45.33218000",
                            "longitude": "-97.52009000"
                        },
                        {
                            "id": 128813,
                            "name": "Wessington Springs",
                            "latitude": "44.07916000",
                            "longitude": "-98.56954000"
                        },
                        {
                            "id": 129156,
                            "name": "White River",
                            "latitude": "43.56805000",
                            "longitude": "-100.74542000"
                        },
                        {
                            "id": 129412,
                            "name": "Winner",
                            "latitude": "43.37667000",
                            "longitude": "-99.85901000"
                        },
                        {
                            "id": 129583,
                            "name": "Woonsocket",
                            "latitude": "44.05360000",
                            "longitude": "-98.27564000"
                        },
                        {
                            "id": 129661,
                            "name": "Yankton",
                            "latitude": "42.87111000",
                            "longitude": "-97.39728000"
                        },
                        {
                            "id": 129662,
                            "name": "Yankton County",
                            "latitude": "43.00897000",
                            "longitude": "-97.39475000"
                        },
                        {
                            "id": 129753,
                            "name": "Ziebach County",
                            "latitude": "44.98041000",
                            "longitude": "-101.66586000"
                        }
                    ]
                },
                {
                    "id": 1454,
                    "name": "Tennessee",
                    "state_code": "TN",
                    "cities": [
                        {
                            "id": 111031,
                            "name": "Adamsville",
                            "latitude": "35.23591000",
                            "longitude": "-88.39060000"
                        },
                        {
                            "id": 111091,
                            "name": "Alamo",
                            "latitude": "35.78479000",
                            "longitude": "-89.11729000"
                        },
                        {
                            "id": 111132,
                            "name": "Alcoa",
                            "latitude": "35.78953000",
                            "longitude": "-83.97379000"
                        },
                        {
                            "id": 111168,
                            "name": "Algood",
                            "latitude": "36.19589000",
                            "longitude": "-85.44858000"
                        },
                        {
                            "id": 111234,
                            "name": "Altamont",
                            "latitude": "35.42952000",
                            "longitude": "-85.72303000"
                        },
                        {
                            "id": 111318,
                            "name": "Anderson County",
                            "latitude": "36.11844000",
                            "longitude": "-84.19846000"
                        },
                        {
                            "id": 111385,
                            "name": "Apison",
                            "latitude": "35.02396000",
                            "longitude": "-85.02384000"
                        },
                        {
                            "id": 111441,
                            "name": "Ardmore",
                            "latitude": "34.99203000",
                            "longitude": "-86.84667000"
                        },
                        {
                            "id": 111462,
                            "name": "Arlington",
                            "latitude": "35.29620000",
                            "longitude": "-89.66147000"
                        },
                        {
                            "id": 111541,
                            "name": "Ashland City",
                            "latitude": "36.27422000",
                            "longitude": "-87.06417000"
                        },
                        {
                            "id": 111575,
                            "name": "Athens",
                            "latitude": "35.44285000",
                            "longitude": "-84.59299000"
                        },
                        {
                            "id": 111604,
                            "name": "Atoka",
                            "latitude": "35.44119000",
                            "longitude": "-89.77814000"
                        },
                        {
                            "id": 111802,
                            "name": "Banner Hill",
                            "latitude": "36.13066000",
                            "longitude": "-82.42458000"
                        },
                        {
                            "id": 111861,
                            "name": "Bartlett",
                            "latitude": "35.20453000",
                            "longitude": "-89.87398000"
                        },
                        {
                            "id": 111914,
                            "name": "Baxter",
                            "latitude": "36.15367000",
                            "longitude": "-85.64359000"
                        },
                        {
                            "id": 111973,
                            "name": "Bean Station",
                            "latitude": "36.34370000",
                            "longitude": "-83.28406000"
                        },
                        {
                            "id": 112028,
                            "name": "Bedford County",
                            "latitude": "35.51380000",
                            "longitude": "-86.45889000"
                        },
                        {
                            "id": 112087,
                            "name": "Belle Meade",
                            "latitude": "36.09589000",
                            "longitude": "-86.85694000"
                        },
                        {
                            "id": 112126,
                            "name": "Bells",
                            "latitude": "35.71118000",
                            "longitude": "-89.08756000"
                        },
                        {
                            "id": 112196,
                            "name": "Benton",
                            "latitude": "35.17424000",
                            "longitude": "-84.65355000"
                        },
                        {
                            "id": 112202,
                            "name": "Benton County",
                            "latitude": "36.06978000",
                            "longitude": "-88.06827000"
                        },
                        {
                            "id": 112402,
                            "name": "Blaine",
                            "latitude": "36.15425000",
                            "longitude": "-83.70407000"
                        },
                        {
                            "id": 112428,
                            "name": "Bledsoe County",
                            "latitude": "35.59642000",
                            "longitude": "-85.20516000"
                        },
                        {
                            "id": 112446,
                            "name": "Bloomingdale",
                            "latitude": "36.58455000",
                            "longitude": "-82.48932000"
                        },
                        {
                            "id": 112458,
                            "name": "Blount County",
                            "latitude": "35.68724000",
                            "longitude": "-83.92553000"
                        },
                        {
                            "id": 112461,
                            "name": "Blountville",
                            "latitude": "36.53316000",
                            "longitude": "-82.32681000"
                        },
                        {
                            "id": 112481,
                            "name": "Bluff City",
                            "latitude": "36.47427000",
                            "longitude": "-82.26097000"
                        },
                        {
                            "id": 112518,
                            "name": "Bolivar",
                            "latitude": "35.25619000",
                            "longitude": "-88.98784000"
                        },
                        {
                            "id": 112527,
                            "name": "Bon Aqua Junction",
                            "latitude": "35.92784000",
                            "longitude": "-87.31084000"
                        },
                        {
                            "id": 112664,
                            "name": "Bradford",
                            "latitude": "36.07645000",
                            "longitude": "-88.81006000"
                        },
                        {
                            "id": 112676,
                            "name": "Bradley County",
                            "latitude": "35.15411000",
                            "longitude": "-84.85960000"
                        },
                        {
                            "id": 112729,
                            "name": "Brentwood",
                            "latitude": "36.03312000",
                            "longitude": "-86.78278000"
                        },
                        {
                            "id": 112734,
                            "name": "Brentwood Estates",
                            "latitude": "36.02506000",
                            "longitude": "-86.77917000"
                        },
                        {
                            "id": 112788,
                            "name": "Brighton",
                            "latitude": "35.48397000",
                            "longitude": "-89.72508000"
                        },
                        {
                            "id": 112803,
                            "name": "Bristol",
                            "latitude": "36.59511000",
                            "longitude": "-82.18874000"
                        },
                        {
                            "id": 112931,
                            "name": "Brownsville",
                            "latitude": "35.59397000",
                            "longitude": "-89.26229000"
                        },
                        {
                            "id": 112943,
                            "name": "Bruceton",
                            "latitude": "36.03812000",
                            "longitude": "-88.24449000"
                        },
                        {
                            "id": 113086,
                            "name": "Burns",
                            "latitude": "36.05339000",
                            "longitude": "-87.31251000"
                        },
                        {
                            "id": 113141,
                            "name": "Byrdstown",
                            "latitude": "36.57451000",
                            "longitude": "-85.12884000"
                        },
                        {
                            "id": 113265,
                            "name": "Camden",
                            "latitude": "36.05895000",
                            "longitude": "-88.09782000"
                        },
                        {
                            "id": 113299,
                            "name": "Campbell County",
                            "latitude": "36.40351000",
                            "longitude": "-84.14938000"
                        },
                        {
                            "id": 113333,
                            "name": "Cannon County",
                            "latitude": "35.80868000",
                            "longitude": "-86.06174000"
                        },
                        {
                            "id": 113456,
                            "name": "Carroll County",
                            "latitude": "35.97317000",
                            "longitude": "-88.45026000"
                        },
                        {
                            "id": 113483,
                            "name": "Carter County",
                            "latitude": "36.29272000",
                            "longitude": "-82.12743000"
                        },
                        {
                            "id": 113494,
                            "name": "Carthage",
                            "latitude": "36.25228000",
                            "longitude": "-85.95165000"
                        },
                        {
                            "id": 113507,
                            "name": "Caryville",
                            "latitude": "36.29897000",
                            "longitude": "-84.22326000"
                        },
                        {
                            "id": 113630,
                            "name": "Celina",
                            "latitude": "36.55006000",
                            "longitude": "-85.50525000"
                        },
                        {
                            "id": 113655,
                            "name": "Centerville",
                            "latitude": "35.77896000",
                            "longitude": "-87.46696000"
                        },
                        {
                            "id": 113664,
                            "name": "Central",
                            "latitude": "36.32622000",
                            "longitude": "-82.28958000"
                        },
                        {
                            "id": 113742,
                            "name": "Chapel Hill",
                            "latitude": "35.62646000",
                            "longitude": "-86.69333000"
                        },
                        {
                            "id": 113777,
                            "name": "Charlotte",
                            "latitude": "36.17728000",
                            "longitude": "-87.33973000"
                        },
                        {
                            "id": 113811,
                            "name": "Chattanooga",
                            "latitude": "35.04563000",
                            "longitude": "-85.30968000"
                        },
                        {
                            "id": 113819,
                            "name": "Cheatham County",
                            "latitude": "36.26117000",
                            "longitude": "-87.08678000"
                        },
                        {
                            "id": 113896,
                            "name": "Chester County",
                            "latitude": "35.42175000",
                            "longitude": "-88.61346000"
                        },
                        {
                            "id": 113998,
                            "name": "Christiana",
                            "latitude": "35.71007000",
                            "longitude": "-86.39944000"
                        },
                        {
                            "id": 114007,
                            "name": "Church Hill",
                            "latitude": "36.52232000",
                            "longitude": "-82.71349000"
                        },
                        {
                            "id": 114094,
                            "name": "Claiborne County",
                            "latitude": "36.48585000",
                            "longitude": "-83.66042000"
                        },
                        {
                            "id": 114154,
                            "name": "Clarksville",
                            "latitude": "36.52977000",
                            "longitude": "-87.35945000"
                        },
                        {
                            "id": 114184,
                            "name": "Clay County",
                            "latitude": "36.55116000",
                            "longitude": "-85.54386000"
                        },
                        {
                            "id": 114244,
                            "name": "Cleveland",
                            "latitude": "35.15952000",
                            "longitude": "-84.87661000"
                        },
                        {
                            "id": 114255,
                            "name": "Clifton",
                            "latitude": "35.38702000",
                            "longitude": "-87.99531000"
                        },
                        {
                            "id": 114275,
                            "name": "Clinton",
                            "latitude": "36.10341000",
                            "longitude": "-84.13186000"
                        },
                        {
                            "id": 114335,
                            "name": "Coalfield",
                            "latitude": "36.02897000",
                            "longitude": "-84.42076000"
                        },
                        {
                            "id": 114354,
                            "name": "Cocke County",
                            "latitude": "35.92542000",
                            "longitude": "-83.12116000"
                        },
                        {
                            "id": 114369,
                            "name": "Coffee County",
                            "latitude": "35.49062000",
                            "longitude": "-86.07476000"
                        },
                        {
                            "id": 114418,
                            "name": "Collegedale",
                            "latitude": "35.05313000",
                            "longitude": "-85.05023000"
                        },
                        {
                            "id": 114424,
                            "name": "Collierville",
                            "latitude": "35.04204000",
                            "longitude": "-89.66453000"
                        },
                        {
                            "id": 114448,
                            "name": "Colonial Heights",
                            "latitude": "36.48510000",
                            "longitude": "-82.50320000"
                        },
                        {
                            "id": 114472,
                            "name": "Columbia",
                            "latitude": "35.61507000",
                            "longitude": "-87.03528000"
                        },
                        {
                            "id": 114548,
                            "name": "Condon",
                            "latitude": "36.18119000",
                            "longitude": "-83.79380000"
                        },
                        {
                            "id": 114595,
                            "name": "Cookeville",
                            "latitude": "36.16284000",
                            "longitude": "-85.50164000"
                        },
                        {
                            "id": 114609,
                            "name": "Coopertown",
                            "latitude": "36.43755000",
                            "longitude": "-86.96722000"
                        },
                        {
                            "id": 114650,
                            "name": "Cornersville",
                            "latitude": "35.36146000",
                            "longitude": "-86.83972000"
                        },
                        {
                            "id": 114738,
                            "name": "Covington",
                            "latitude": "35.56425000",
                            "longitude": "-89.64647000"
                        },
                        {
                            "id": 114744,
                            "name": "Cowan",
                            "latitude": "35.16453000",
                            "longitude": "-86.01054000"
                        },
                        {
                            "id": 114831,
                            "name": "Crockett County",
                            "latitude": "35.81354000",
                            "longitude": "-89.13953000"
                        },
                        {
                            "id": 114852,
                            "name": "Cross Plains",
                            "latitude": "36.54866000",
                            "longitude": "-86.69611000"
                        },
                        {
                            "id": 114856,
                            "name": "Crossville",
                            "latitude": "35.94896000",
                            "longitude": "-85.02690000"
                        },
                        {
                            "id": 114873,
                            "name": "Crump",
                            "latitude": "35.22175000",
                            "longitude": "-88.31809000"
                        },
                        {
                            "id": 114916,
                            "name": "Cumberland County",
                            "latitude": "35.95039000",
                            "longitude": "-84.99835000"
                        },
                        {
                            "id": 115018,
                            "name": "Dandridge",
                            "latitude": "36.01537000",
                            "longitude": "-83.41489000"
                        },
                        {
                            "id": 115064,
                            "name": "Davidson County",
                            "latitude": "36.17069000",
                            "longitude": "-86.77753000"
                        },
                        {
                            "id": 115092,
                            "name": "Dayton",
                            "latitude": "35.49396000",
                            "longitude": "-85.01245000"
                        },
                        {
                            "id": 115154,
                            "name": "Decatur",
                            "latitude": "35.51479000",
                            "longitude": "-84.79022000"
                        },
                        {
                            "id": 115161,
                            "name": "Decatur County",
                            "latitude": "35.60302000",
                            "longitude": "-88.10877000"
                        },
                        {
                            "id": 115164,
                            "name": "Decaturville",
                            "latitude": "35.58424000",
                            "longitude": "-88.11948000"
                        },
                        {
                            "id": 115165,
                            "name": "Decherd",
                            "latitude": "35.20980000",
                            "longitude": "-86.07943000"
                        },
                        {
                            "id": 115131,
                            "name": "DeKalb County",
                            "latitude": "35.97986000",
                            "longitude": "-85.83275000"
                        },
                        {
                            "id": 115331,
                            "name": "Dickson",
                            "latitude": "36.07700000",
                            "longitude": "-87.38779000"
                        },
                        {
                            "id": 115333,
                            "name": "Dickson County",
                            "latitude": "36.14905000",
                            "longitude": "-87.35666000"
                        },
                        {
                            "id": 115382,
                            "name": "Dodson Branch",
                            "latitude": "36.31256000",
                            "longitude": "-85.53220000"
                        },
                        {
                            "id": 115447,
                            "name": "Dover",
                            "latitude": "36.48783000",
                            "longitude": "-87.83836000"
                        },
                        {
                            "id": 115471,
                            "name": "Dresden",
                            "latitude": "36.29145000",
                            "longitude": "-88.70811000"
                        },
                        {
                            "id": 115542,
                            "name": "Dunlap",
                            "latitude": "35.37146000",
                            "longitude": "-85.39052000"
                        },
                        {
                            "id": 115585,
                            "name": "Dyer",
                            "latitude": "36.06673000",
                            "longitude": "-88.99395000"
                        },
                        {
                            "id": 115587,
                            "name": "Dyer County",
                            "latitude": "36.05906000",
                            "longitude": "-89.41377000"
                        },
                        {
                            "id": 115588,
                            "name": "Dyersburg",
                            "latitude": "36.03452000",
                            "longitude": "-89.38563000"
                        },
                        {
                            "id": 115613,
                            "name": "Eagleton Village",
                            "latitude": "35.79508000",
                            "longitude": "-83.93185000"
                        },
                        {
                            "id": 115635,
                            "name": "East Brainerd",
                            "latitude": "34.99591000",
                            "longitude": "-85.15023000"
                        },
                        {
                            "id": 115644,
                            "name": "East Chattanooga",
                            "latitude": "35.06535000",
                            "longitude": "-85.24912000"
                        },
                        {
                            "id": 115646,
                            "name": "East Cleveland",
                            "latitude": "35.16091000",
                            "longitude": "-84.85772000"
                        },
                        {
                            "id": 115747,
                            "name": "East Ridge",
                            "latitude": "35.01424000",
                            "longitude": "-85.25190000"
                        },
                        {
                            "id": 115976,
                            "name": "Elizabethton",
                            "latitude": "36.34872000",
                            "longitude": "-82.21069000"
                        },
                        {
                            "id": 116130,
                            "name": "Englewood",
                            "latitude": "35.42452000",
                            "longitude": "-84.48743000"
                        },
                        {
                            "id": 116170,
                            "name": "Erin",
                            "latitude": "36.31839000",
                            "longitude": "-87.69474000"
                        },
                        {
                            "id": 116176,
                            "name": "Erwin",
                            "latitude": "36.14511000",
                            "longitude": "-82.41681000"
                        },
                        {
                            "id": 116213,
                            "name": "Estill Springs",
                            "latitude": "35.27064000",
                            "longitude": "-86.12804000"
                        },
                        {
                            "id": 116218,
                            "name": "Etowah",
                            "latitude": "35.32341000",
                            "longitude": "-84.52493000"
                        },
                        {
                            "id": 116321,
                            "name": "Fairfield Glade",
                            "latitude": "36.00035000",
                            "longitude": "-84.88634000"
                        },
                        {
                            "id": 116339,
                            "name": "Fairmount",
                            "latitude": "35.18146000",
                            "longitude": "-85.32357000"
                        },
                        {
                            "id": 116349,
                            "name": "Fairview",
                            "latitude": "35.98201000",
                            "longitude": "-87.12140000"
                        },
                        {
                            "id": 116370,
                            "name": "Fall Branch",
                            "latitude": "36.41816000",
                            "longitude": "-82.62376000"
                        },
                        {
                            "id": 116377,
                            "name": "Falling Water",
                            "latitude": "35.20312000",
                            "longitude": "-85.25357000"
                        },
                        {
                            "id": 116429,
                            "name": "Farragut",
                            "latitude": "35.88452000",
                            "longitude": "-84.15353000"
                        },
                        {
                            "id": 116448,
                            "name": "Fayette County",
                            "latitude": "35.19708000",
                            "longitude": "-89.41437000"
                        },
                        {
                            "id": 116457,
                            "name": "Fayetteville",
                            "latitude": "35.15203000",
                            "longitude": "-86.57055000"
                        },
                        {
                            "id": 116476,
                            "name": "Fentress County",
                            "latitude": "36.38049000",
                            "longitude": "-84.93246000"
                        },
                        {
                            "id": 116513,
                            "name": "Fincastle",
                            "latitude": "36.40980000",
                            "longitude": "-84.04770000"
                        },
                        {
                            "id": 116656,
                            "name": "Forest Hills",
                            "latitude": "36.06839000",
                            "longitude": "-86.84417000"
                        },
                        {
                            "id": 116829,
                            "name": "Franklin",
                            "latitude": "35.92506000",
                            "longitude": "-86.86889000"
                        },
                        {
                            "id": 116854,
                            "name": "Franklin County",
                            "latitude": "35.15496000",
                            "longitude": "-86.09218000"
                        },
                        {
                            "id": 117013,
                            "name": "Gainesboro",
                            "latitude": "36.35561000",
                            "longitude": "-85.65887000"
                        },
                        {
                            "id": 117033,
                            "name": "Gallatin",
                            "latitude": "36.38838000",
                            "longitude": "-86.44666000"
                        },
                        {
                            "id": 117125,
                            "name": "Gatlinburg",
                            "latitude": "35.71453000",
                            "longitude": "-83.51189000"
                        },
                        {
                            "id": 117173,
                            "name": "Germantown",
                            "latitude": "35.08676000",
                            "longitude": "-89.81009000"
                        },
                        {
                            "id": 117190,
                            "name": "Gibson County",
                            "latitude": "35.99661000",
                            "longitude": "-88.93262000"
                        },
                        {
                            "id": 117210,
                            "name": "Giles County",
                            "latitude": "35.20215000",
                            "longitude": "-87.03478000"
                        },
                        {
                            "id": 117257,
                            "name": "Gleason",
                            "latitude": "36.21367000",
                            "longitude": "-88.61255000"
                        },
                        {
                            "id": 117386,
                            "name": "Goodlettsville",
                            "latitude": "36.32311000",
                            "longitude": "-86.71333000"
                        },
                        {
                            "id": 117400,
                            "name": "Gordonsville",
                            "latitude": "36.17256000",
                            "longitude": "-85.92971000"
                        },
                        {
                            "id": 117441,
                            "name": "Grainger County",
                            "latitude": "36.27625000",
                            "longitude": "-83.50962000"
                        },
                        {
                            "id": 117552,
                            "name": "Gray",
                            "latitude": "36.41983000",
                            "longitude": "-82.47654000"
                        },
                        {
                            "id": 117567,
                            "name": "Graysville",
                            "latitude": "35.44701000",
                            "longitude": "-85.08440000"
                        },
                        {
                            "id": 117598,
                            "name": "Green Hill",
                            "latitude": "36.22283000",
                            "longitude": "-86.54944000"
                        },
                        {
                            "id": 117615,
                            "name": "Greenback",
                            "latitude": "35.66119000",
                            "longitude": "-84.17214000"
                        },
                        {
                            "id": 117620,
                            "name": "Greenbrier",
                            "latitude": "36.42755000",
                            "longitude": "-86.80472000"
                        },
                        {
                            "id": 117639,
                            "name": "Greene County",
                            "latitude": "36.17536000",
                            "longitude": "-82.84582000"
                        },
                        {
                            "id": 117644,
                            "name": "Greeneville",
                            "latitude": "36.16316000",
                            "longitude": "-82.83099000"
                        },
                        {
                            "id": 117648,
                            "name": "Greenfield",
                            "latitude": "36.15340000",
                            "longitude": "-88.80062000"
                        },
                        {
                            "id": 117739,
                            "name": "Grimsley",
                            "latitude": "36.26701000",
                            "longitude": "-84.98440000"
                        },
                        {
                            "id": 117767,
                            "name": "Gruetli-Laager",
                            "latitude": "35.37230000",
                            "longitude": "-85.61803000"
                        },
                        {
                            "id": 117770,
                            "name": "Grundy County",
                            "latitude": "35.38837000",
                            "longitude": "-85.72258000"
                        },
                        {
                            "id": 117873,
                            "name": "Halls",
                            "latitude": "35.87563000",
                            "longitude": "-89.39618000"
                        },
                        {
                            "id": 117880,
                            "name": "Hamblen County",
                            "latitude": "36.21715000",
                            "longitude": "-83.26666000"
                        },
                        {
                            "id": 117898,
                            "name": "Hamilton County",
                            "latitude": "35.18086000",
                            "longitude": "-85.16479000"
                        },
                        {
                            "id": 117954,
                            "name": "Hancock County",
                            "latitude": "36.52365000",
                            "longitude": "-83.22183000"
                        },
                        {
                            "id": 117995,
                            "name": "Hardeman County",
                            "latitude": "35.20687000",
                            "longitude": "-88.99308000"
                        },
                        {
                            "id": 118001,
                            "name": "Hardin County",
                            "latitude": "35.19868000",
                            "longitude": "-88.18448000"
                        },
                        {
                            "id": 118039,
                            "name": "Harriman",
                            "latitude": "35.93396000",
                            "longitude": "-84.55244000"
                        },
                        {
                            "id": 118055,
                            "name": "Harrison",
                            "latitude": "35.11368000",
                            "longitude": "-85.13801000"
                        },
                        {
                            "id": 118079,
                            "name": "Harrogate",
                            "latitude": "36.58230000",
                            "longitude": "-83.65686000"
                        },
                        {
                            "id": 118105,
                            "name": "Hartsville",
                            "latitude": "36.39088000",
                            "longitude": "-86.16721000"
                        },
                        {
                            "id": 118166,
                            "name": "Hawkins County",
                            "latitude": "36.44117000",
                            "longitude": "-82.94468000"
                        },
                        {
                            "id": 118199,
                            "name": "Haywood County",
                            "latitude": "35.58322000",
                            "longitude": "-89.28384000"
                        },
                        {
                            "id": 118277,
                            "name": "Henderson",
                            "latitude": "35.43924000",
                            "longitude": "-88.64144000"
                        },
                        {
                            "id": 118282,
                            "name": "Henderson County",
                            "latitude": "35.65426000",
                            "longitude": "-88.38799000"
                        },
                        {
                            "id": 118286,
                            "name": "Hendersonville",
                            "latitude": "36.30477000",
                            "longitude": "-86.62000000"
                        },
                        {
                            "id": 118302,
                            "name": "Henry County",
                            "latitude": "36.33183000",
                            "longitude": "-88.30122000"
                        },
                        {
                            "id": 118364,
                            "name": "Hickman County",
                            "latitude": "35.80325000",
                            "longitude": "-87.47331000"
                        },
                        {
                            "id": 118370,
                            "name": "Hickory Withe",
                            "latitude": "35.24398000",
                            "longitude": "-89.58869000"
                        },
                        {
                            "id": 118512,
                            "name": "Hohenwald",
                            "latitude": "35.54785000",
                            "longitude": "-87.55196000"
                        },
                        {
                            "id": 118648,
                            "name": "Hopewell",
                            "latitude": "35.23479000",
                            "longitude": "-84.88800000"
                        },
                        {
                            "id": 118704,
                            "name": "Houston County",
                            "latitude": "36.28597000",
                            "longitude": "-87.71704000"
                        },
                        {
                            "id": 118776,
                            "name": "Humboldt",
                            "latitude": "35.81979000",
                            "longitude": "-88.91590000"
                        },
                        {
                            "id": 118785,
                            "name": "Humphreys County",
                            "latitude": "36.04082000",
                            "longitude": "-87.77563000"
                        },
                        {
                            "id": 118788,
                            "name": "Hunter",
                            "latitude": "36.37178000",
                            "longitude": "-82.15874000"
                        },
                        {
                            "id": 118795,
                            "name": "Huntingdon",
                            "latitude": "36.00062000",
                            "longitude": "-88.42811000"
                        },
                        {
                            "id": 118817,
                            "name": "Huntsville",
                            "latitude": "36.40980000",
                            "longitude": "-84.49049000"
                        },
                        {
                            "id": 119058,
                            "name": "Jacksboro",
                            "latitude": "36.33008000",
                            "longitude": "-84.18382000"
                        },
                        {
                            "id": 119068,
                            "name": "Jackson",
                            "latitude": "35.61452000",
                            "longitude": "-88.81395000"
                        },
                        {
                            "id": 119087,
                            "name": "Jackson County",
                            "latitude": "36.35921000",
                            "longitude": "-85.67315000"
                        },
                        {
                            "id": 119122,
                            "name": "Jamestown",
                            "latitude": "36.42757000",
                            "longitude": "-84.93189000"
                        },
                        {
                            "id": 119143,
                            "name": "Jasper",
                            "latitude": "35.07424000",
                            "longitude": "-85.62608000"
                        },
                        {
                            "id": 119174,
                            "name": "Jefferson City",
                            "latitude": "36.12231000",
                            "longitude": "-83.49240000"
                        },
                        {
                            "id": 119186,
                            "name": "Jefferson County",
                            "latitude": "36.05099000",
                            "longitude": "-83.44631000"
                        },
                        {
                            "id": 119209,
                            "name": "Jellico",
                            "latitude": "36.58786000",
                            "longitude": "-84.12687000"
                        },
                        {
                            "id": 119257,
                            "name": "Johnson City",
                            "latitude": "36.31344000",
                            "longitude": "-82.35347000"
                        },
                        {
                            "id": 119267,
                            "name": "Johnson County",
                            "latitude": "36.45494000",
                            "longitude": "-81.85175000"
                        },
                        {
                            "id": 119275,
                            "name": "Johnsonville",
                            "latitude": "36.06006000",
                            "longitude": "-87.95281000"
                        },
                        {
                            "id": 119304,
                            "name": "Jonesborough",
                            "latitude": "36.29427000",
                            "longitude": "-82.47348000"
                        },
                        {
                            "id": 119497,
                            "name": "Kenton",
                            "latitude": "36.20229000",
                            "longitude": "-89.01229000"
                        },
                        {
                            "id": 119565,
                            "name": "Kimball",
                            "latitude": "35.04786000",
                            "longitude": "-85.67191000"
                        },
                        {
                            "id": 119619,
                            "name": "Kingsport",
                            "latitude": "36.54843000",
                            "longitude": "-82.56182000"
                        },
                        {
                            "id": 119621,
                            "name": "Kingston",
                            "latitude": "35.88091000",
                            "longitude": "-84.50854000"
                        },
                        {
                            "id": 119632,
                            "name": "Kingston Springs",
                            "latitude": "36.10200000",
                            "longitude": "-87.11501000"
                        },
                        {
                            "id": 119687,
                            "name": "Knox County",
                            "latitude": "35.99322000",
                            "longitude": "-83.93709000"
                        },
                        {
                            "id": 119694,
                            "name": "Knoxville",
                            "latitude": "35.96064000",
                            "longitude": "-83.92074000"
                        },
                        {
                            "id": 119792,
                            "name": "La Vergne",
                            "latitude": "36.01562000",
                            "longitude": "-86.58194000"
                        },
                        {
                            "id": 119833,
                            "name": "Lafayette",
                            "latitude": "36.52116000",
                            "longitude": "-86.02637000"
                        },
                        {
                            "id": 119800,
                            "name": "LaFollette",
                            "latitude": "36.38286000",
                            "longitude": "-84.11993000"
                        },
                        {
                            "id": 119890,
                            "name": "Lake County",
                            "latitude": "36.33538000",
                            "longitude": "-89.49347000"
                        },
                        {
                            "id": 119984,
                            "name": "Lake Tansi",
                            "latitude": "35.87285000",
                            "longitude": "-85.05440000"
                        },
                        {
                            "id": 120014,
                            "name": "Lakeland",
                            "latitude": "35.23064000",
                            "longitude": "-89.74036000"
                        },
                        {
                            "id": 120036,
                            "name": "Lakesite",
                            "latitude": "35.20868000",
                            "longitude": "-85.12690000"
                        },
                        {
                            "id": 120046,
                            "name": "Lakewood",
                            "latitude": "36.24311000",
                            "longitude": "-86.63555000"
                        },
                        {
                            "id": 120181,
                            "name": "Lauderdale County",
                            "latitude": "35.76101000",
                            "longitude": "-89.63144000"
                        },
                        {
                            "id": 120236,
                            "name": "Lawrence County",
                            "latitude": "35.21727000",
                            "longitude": "-87.39559000"
                        },
                        {
                            "id": 120242,
                            "name": "Lawrenceburg",
                            "latitude": "35.24230000",
                            "longitude": "-87.33474000"
                        },
                        {
                            "id": 120287,
                            "name": "Lebanon",
                            "latitude": "36.20811000",
                            "longitude": "-86.29110000"
                        },
                        {
                            "id": 120373,
                            "name": "Lenoir City",
                            "latitude": "35.79730000",
                            "longitude": "-84.25603000"
                        },
                        {
                            "id": 120412,
                            "name": "Lewis County",
                            "latitude": "35.52728000",
                            "longitude": "-87.49310000"
                        },
                        {
                            "id": 120419,
                            "name": "Lewisburg",
                            "latitude": "35.44924000",
                            "longitude": "-86.78889000"
                        },
                        {
                            "id": 120444,
                            "name": "Lexington",
                            "latitude": "35.65090000",
                            "longitude": "-88.39338000"
                        },
                        {
                            "id": 120524,
                            "name": "Lincoln County",
                            "latitude": "35.14052000",
                            "longitude": "-86.58894000"
                        },
                        {
                            "id": 120561,
                            "name": "Linden",
                            "latitude": "35.61729000",
                            "longitude": "-87.83947000"
                        },
                        {
                            "id": 120649,
                            "name": "Livingston",
                            "latitude": "36.38340000",
                            "longitude": "-85.32302000"
                        },
                        {
                            "id": 120728,
                            "name": "Lone Oak",
                            "latitude": "35.20063000",
                            "longitude": "-85.36413000"
                        },
                        {
                            "id": 120766,
                            "name": "Lookout Mountain",
                            "latitude": "34.99424000",
                            "longitude": "-85.34940000"
                        },
                        {
                            "id": 120774,
                            "name": "Loretto",
                            "latitude": "35.07786000",
                            "longitude": "-87.43974000"
                        },
                        {
                            "id": 120799,
                            "name": "Loudon",
                            "latitude": "35.73285000",
                            "longitude": "-84.33381000"
                        },
                        {
                            "id": 120800,
                            "name": "Loudon County",
                            "latitude": "35.73478000",
                            "longitude": "-84.31187000"
                        },
                        {
                            "id": 120815,
                            "name": "Louisville",
                            "latitude": "35.82175000",
                            "longitude": "-84.04796000"
                        },
                        {
                            "id": 120897,
                            "name": "Luttrell",
                            "latitude": "36.19953000",
                            "longitude": "-83.74185000"
                        },
                        {
                            "id": 120915,
                            "name": "Lynchburg",
                            "latitude": "35.28314000",
                            "longitude": "-86.37416000"
                        },
                        {
                            "id": 120972,
                            "name": "Macon County",
                            "latitude": "36.53203000",
                            "longitude": "-86.00727000"
                        },
                        {
                            "id": 121011,
                            "name": "Madison County",
                            "latitude": "35.60814000",
                            "longitude": "-88.83847000"
                        },
                        {
                            "id": 121026,
                            "name": "Madisonville",
                            "latitude": "35.51980000",
                            "longitude": "-84.36353000"
                        },
                        {
                            "id": 121093,
                            "name": "Manchester",
                            "latitude": "35.48174000",
                            "longitude": "-86.08860000"
                        },
                        {
                            "id": 121258,
                            "name": "Marion County",
                            "latitude": "35.12935000",
                            "longitude": "-85.62203000"
                        },
                        {
                            "id": 121315,
                            "name": "Marshall County",
                            "latitude": "35.46886000",
                            "longitude": "-86.76502000"
                        },
                        {
                            "id": 121335,
                            "name": "Martin",
                            "latitude": "36.34340000",
                            "longitude": "-88.85034000"
                        },
                        {
                            "id": 121364,
                            "name": "Maryville",
                            "latitude": "35.75647000",
                            "longitude": "-83.97046000"
                        },
                        {
                            "id": 121367,
                            "name": "Mascot",
                            "latitude": "36.06120000",
                            "longitude": "-83.74573000"
                        },
                        {
                            "id": 121371,
                            "name": "Mason",
                            "latitude": "35.41175000",
                            "longitude": "-89.53285000"
                        },
                        {
                            "id": 121426,
                            "name": "Maury County",
                            "latitude": "35.61694000",
                            "longitude": "-87.07701000"
                        },
                        {
                            "id": 121441,
                            "name": "Maynardville",
                            "latitude": "36.25064000",
                            "longitude": "-83.79741000"
                        },
                        {
                            "id": 121498,
                            "name": "McEwen",
                            "latitude": "36.10784000",
                            "longitude": "-87.63307000"
                        },
                        {
                            "id": 121520,
                            "name": "McKenzie",
                            "latitude": "36.13256000",
                            "longitude": "-88.51866000"
                        },
                        {
                            "id": 121539,
                            "name": "McMinn County",
                            "latitude": "35.42475000",
                            "longitude": "-84.61747000"
                        },
                        {
                            "id": 121540,
                            "name": "McMinnville",
                            "latitude": "35.68340000",
                            "longitude": "-85.76998000"
                        },
                        {
                            "id": 121544,
                            "name": "McNairy County",
                            "latitude": "35.17545000",
                            "longitude": "-88.56364000"
                        },
                        {
                            "id": 121602,
                            "name": "Medina",
                            "latitude": "35.80285000",
                            "longitude": "-88.77478000"
                        },
                        {
                            "id": 121618,
                            "name": "Meigs County",
                            "latitude": "35.51283000",
                            "longitude": "-84.81339000"
                        },
                        {
                            "id": 121639,
                            "name": "Memphis",
                            "latitude": "35.14953000",
                            "longitude": "-90.04898000"
                        },
                        {
                            "id": 121763,
                            "name": "Middle Valley",
                            "latitude": "35.19590000",
                            "longitude": "-85.18468000"
                        },
                        {
                            "id": 121810,
                            "name": "Midtown",
                            "latitude": "35.87952000",
                            "longitude": "-84.56410000"
                        },
                        {
                            "id": 121817,
                            "name": "Midway",
                            "latitude": "36.30011000",
                            "longitude": "-82.42375000"
                        },
                        {
                            "id": 121836,
                            "name": "Milan",
                            "latitude": "35.91979000",
                            "longitude": "-88.75895000"
                        },
                        {
                            "id": 121888,
                            "name": "Millersville",
                            "latitude": "36.37116000",
                            "longitude": "-86.71000000"
                        },
                        {
                            "id": 121891,
                            "name": "Millington",
                            "latitude": "35.34147000",
                            "longitude": "-89.89731000"
                        },
                        {
                            "id": 122076,
                            "name": "Monroe County",
                            "latitude": "35.44265000",
                            "longitude": "-84.25279000"
                        },
                        {
                            "id": 122112,
                            "name": "Monteagle",
                            "latitude": "35.24008000",
                            "longitude": "-85.83970000"
                        },
                        {
                            "id": 122118,
                            "name": "Monterey",
                            "latitude": "36.14756000",
                            "longitude": "-85.26830000"
                        },
                        {
                            "id": 122149,
                            "name": "Montgomery County",
                            "latitude": "36.49686000",
                            "longitude": "-87.38289000"
                        },
                        {
                            "id": 122202,
                            "name": "Moore County",
                            "latitude": "35.28462000",
                            "longitude": "-86.35877000"
                        },
                        {
                            "id": 122242,
                            "name": "Morgan County",
                            "latitude": "36.13502000",
                            "longitude": "-84.64920000"
                        },
                        {
                            "id": 122281,
                            "name": "Morristown",
                            "latitude": "36.21398000",
                            "longitude": "-83.29489000"
                        },
                        {
                            "id": 122304,
                            "name": "Mosheim",
                            "latitude": "36.18954000",
                            "longitude": "-82.95849000"
                        },
                        {
                            "id": 122336,
                            "name": "Mount Carmel",
                            "latitude": "36.54538000",
                            "longitude": "-82.66099000"
                        },
                        {
                            "id": 122359,
                            "name": "Mount Juliet",
                            "latitude": "36.20005000",
                            "longitude": "-86.51861000"
                        },
                        {
                            "id": 122375,
                            "name": "Mount Pleasant",
                            "latitude": "35.53424000",
                            "longitude": "-87.20695000"
                        },
                        {
                            "id": 122412,
                            "name": "Mountain City",
                            "latitude": "36.47456000",
                            "longitude": "-81.80484000"
                        },
                        {
                            "id": 122443,
                            "name": "Mowbray Mountain",
                            "latitude": "35.27536000",
                            "longitude": "-85.22246000"
                        },
                        {
                            "id": 122471,
                            "name": "Munford",
                            "latitude": "35.44925000",
                            "longitude": "-89.81508000"
                        },
                        {
                            "id": 122482,
                            "name": "Murfreesboro",
                            "latitude": "35.84562000",
                            "longitude": "-86.39027000"
                        },
                        {
                            "id": 122577,
                            "name": "Nashville",
                            "latitude": "36.16589000",
                            "longitude": "-86.78444000"
                        },
                        {
                            "id": 122725,
                            "name": "New Hope",
                            "latitude": "35.00508000",
                            "longitude": "-85.65830000"
                        },
                        {
                            "id": 122732,
                            "name": "New Johnsonville",
                            "latitude": "36.02117000",
                            "longitude": "-87.96698000"
                        },
                        {
                            "id": 122750,
                            "name": "New Market",
                            "latitude": "36.10398000",
                            "longitude": "-83.55268000"
                        },
                        {
                            "id": 122780,
                            "name": "New South Memphis",
                            "latitude": "35.08676000",
                            "longitude": "-90.05676000"
                        },
                        {
                            "id": 122785,
                            "name": "New Tazewell",
                            "latitude": "36.44258000",
                            "longitude": "-83.59963000"
                        },
                        {
                            "id": 122789,
                            "name": "New Union",
                            "latitude": "35.53258000",
                            "longitude": "-86.08082000"
                        },
                        {
                            "id": 122809,
                            "name": "Newbern",
                            "latitude": "36.11285000",
                            "longitude": "-89.26174000"
                        },
                        {
                            "id": 122843,
                            "name": "Newport",
                            "latitude": "35.96704000",
                            "longitude": "-83.18766000"
                        },
                        {
                            "id": 122933,
                            "name": "Nolensville",
                            "latitude": "35.95229000",
                            "longitude": "-86.66944000"
                        },
                        {
                            "id": 122955,
                            "name": "Norris",
                            "latitude": "36.19563000",
                            "longitude": "-84.06797000"
                        },
                        {
                            "id": 123237,
                            "name": "Oak Grove",
                            "latitude": "36.41177000",
                            "longitude": "-82.42459000"
                        },
                        {
                            "id": 123244,
                            "name": "Oak Hill",
                            "latitude": "36.08784000",
                            "longitude": "-86.78305000"
                        },
                        {
                            "id": 123262,
                            "name": "Oak Ridge",
                            "latitude": "36.01036000",
                            "longitude": "-84.26964000"
                        },
                        {
                            "id": 123285,
                            "name": "Oakland",
                            "latitude": "35.22898000",
                            "longitude": "-89.51508000"
                        },
                        {
                            "id": 123317,
                            "name": "Obion",
                            "latitude": "36.25896000",
                            "longitude": "-89.19174000"
                        },
                        {
                            "id": 123318,
                            "name": "Obion County",
                            "latitude": "36.35825000",
                            "longitude": "-89.14880000"
                        },
                        {
                            "id": 123444,
                            "name": "Oliver Springs",
                            "latitude": "36.04452000",
                            "longitude": "-84.34437000"
                        },
                        {
                            "id": 123446,
                            "name": "Olivet",
                            "latitude": "35.21425000",
                            "longitude": "-88.20031000"
                        },
                        {
                            "id": 123472,
                            "name": "Oneida",
                            "latitude": "36.49813000",
                            "longitude": "-84.51272000"
                        },
                        {
                            "id": 123654,
                            "name": "Overton County",
                            "latitude": "36.34500000",
                            "longitude": "-85.28808000"
                        },
                        {
                            "id": 123833,
                            "name": "Paris",
                            "latitude": "36.30200000",
                            "longitude": "-88.32671000"
                        },
                        {
                            "id": 123838,
                            "name": "Park City",
                            "latitude": "35.08203000",
                            "longitude": "-86.57111000"
                        },
                        {
                            "id": 123892,
                            "name": "Parsons",
                            "latitude": "35.64979000",
                            "longitude": "-88.12670000"
                        },
                        {
                            "id": 123985,
                            "name": "Pegram",
                            "latitude": "36.10061000",
                            "longitude": "-87.05112000"
                        },
                        {
                            "id": 124081,
                            "name": "Perry County",
                            "latitude": "35.64263000",
                            "longitude": "-87.85897000"
                        },
                        {
                            "id": 124159,
                            "name": "Pickett County",
                            "latitude": "36.55841000",
                            "longitude": "-85.07499000"
                        },
                        {
                            "id": 124183,
                            "name": "Pigeon Forge",
                            "latitude": "35.78842000",
                            "longitude": "-83.55433000"
                        },
                        {
                            "id": 124199,
                            "name": "Pikeville",
                            "latitude": "35.60562000",
                            "longitude": "-85.18885000"
                        },
                        {
                            "id": 124220,
                            "name": "Pine Crest",
                            "latitude": "36.29927000",
                            "longitude": "-82.31792000"
                        },
                        {
                            "id": 124276,
                            "name": "Piperton",
                            "latitude": "35.04509000",
                            "longitude": "-89.62175000"
                        },
                        {
                            "id": 124332,
                            "name": "Plainview",
                            "latitude": "36.17731000",
                            "longitude": "-83.79534000"
                        },
                        {
                            "id": 124380,
                            "name": "Pleasant View",
                            "latitude": "36.39422000",
                            "longitude": "-87.03667000"
                        },
                        {
                            "id": 124445,
                            "name": "Polk County",
                            "latitude": "35.11990000",
                            "longitude": "-84.52330000"
                        },
                        {
                            "id": 124558,
                            "name": "Portland",
                            "latitude": "36.58171000",
                            "longitude": "-86.51638000"
                        },
                        {
                            "id": 124614,
                            "name": "Powells Crossroads",
                            "latitude": "35.18952000",
                            "longitude": "-85.48580000"
                        },
                        {
                            "id": 124733,
                            "name": "Pulaski",
                            "latitude": "35.19980000",
                            "longitude": "-87.03084000"
                        },
                        {
                            "id": 124764,
                            "name": "Putnam County",
                            "latitude": "36.14083000",
                            "longitude": "-85.49519000"
                        },
                        {
                            "id": 124937,
                            "name": "Red Bank",
                            "latitude": "35.11229000",
                            "longitude": "-85.29413000"
                        },
                        {
                            "id": 124941,
                            "name": "Red Boiling Springs",
                            "latitude": "36.53339000",
                            "longitude": "-85.84998000"
                        },
                        {
                            "id": 125043,
                            "name": "Rhea County",
                            "latitude": "35.60871000",
                            "longitude": "-84.92440000"
                        },
                        {
                            "id": 125126,
                            "name": "Ridgely",
                            "latitude": "36.26340000",
                            "longitude": "-89.48785000"
                        },
                        {
                            "id": 125128,
                            "name": "Ridgetop",
                            "latitude": "36.39505000",
                            "longitude": "-86.77944000"
                        },
                        {
                            "id": 125163,
                            "name": "Ripley",
                            "latitude": "35.74536000",
                            "longitude": "-89.52980000"
                        },
                        {
                            "id": 125223,
                            "name": "Roan Mountain",
                            "latitude": "36.19623000",
                            "longitude": "-82.07040000"
                        },
                        {
                            "id": 125224,
                            "name": "Roane County",
                            "latitude": "35.84786000",
                            "longitude": "-84.52324000"
                        },
                        {
                            "id": 125246,
                            "name": "Robertson County",
                            "latitude": "36.52546000",
                            "longitude": "-86.87057000"
                        },
                        {
                            "id": 125332,
                            "name": "Rockwood",
                            "latitude": "35.86563000",
                            "longitude": "-84.68494000"
                        },
                        {
                            "id": 125340,
                            "name": "Rocky Top",
                            "latitude": "36.21786000",
                            "longitude": "-84.15465000"
                        },
                        {
                            "id": 125356,
                            "name": "Rogersville",
                            "latitude": "36.40732000",
                            "longitude": "-83.00544000"
                        },
                        {
                            "id": 125517,
                            "name": "Rural Hill",
                            "latitude": "36.11673000",
                            "longitude": "-86.47916000"
                        },
                        {
                            "id": 125548,
                            "name": "Rutherford",
                            "latitude": "36.12757000",
                            "longitude": "-88.98590000"
                        },
                        {
                            "id": 125553,
                            "name": "Rutherford County",
                            "latitude": "35.84270000",
                            "longitude": "-86.41674000"
                        },
                        {
                            "id": 125559,
                            "name": "Rutledge",
                            "latitude": "36.28092000",
                            "longitude": "-83.51490000"
                        },
                        {
                            "id": 125725,
                            "name": "Sale Creek",
                            "latitude": "35.38229000",
                            "longitude": "-85.10884000"
                        },
                        {
                            "id": 125976,
                            "name": "Savannah",
                            "latitude": "35.22480000",
                            "longitude": "-88.24920000"
                        },
                        {
                            "id": 126049,
                            "name": "Scott County",
                            "latitude": "36.42854000",
                            "longitude": "-84.50352000"
                        },
                        {
                            "id": 126142,
                            "name": "Selmer",
                            "latitude": "35.17008000",
                            "longitude": "-88.59227000"
                        },
                        {
                            "id": 126163,
                            "name": "Sequatchie County",
                            "latitude": "35.37115000",
                            "longitude": "-85.41059000"
                        },
                        {
                            "id": 126181,
                            "name": "Sevier County",
                            "latitude": "35.78466000",
                            "longitude": "-83.52418000"
                        },
                        {
                            "id": 126183,
                            "name": "Sevierville",
                            "latitude": "35.86815000",
                            "longitude": "-83.56184000"
                        },
                        {
                            "id": 126185,
                            "name": "Sewanee",
                            "latitude": "35.20314000",
                            "longitude": "-85.92109000"
                        },
                        {
                            "id": 126194,
                            "name": "Seymour",
                            "latitude": "35.89064000",
                            "longitude": "-83.72462000"
                        },
                        {
                            "id": 126200,
                            "name": "Shackle Island",
                            "latitude": "36.37060000",
                            "longitude": "-86.61666000"
                        },
                        {
                            "id": 126277,
                            "name": "Shelby County",
                            "latitude": "35.18400000",
                            "longitude": "-89.89560000"
                        },
                        {
                            "id": 126284,
                            "name": "Shelbyville",
                            "latitude": "35.48341000",
                            "longitude": "-86.46027000"
                        },
                        {
                            "id": 126412,
                            "name": "Signal Mountain",
                            "latitude": "35.12257000",
                            "longitude": "-85.34385000"
                        },
                        {
                            "id": 126514,
                            "name": "Smith County",
                            "latitude": "36.25053000",
                            "longitude": "-85.95671000"
                        },
                        {
                            "id": 126530,
                            "name": "Smithville",
                            "latitude": "35.96062000",
                            "longitude": "-85.81415000"
                        },
                        {
                            "id": 126536,
                            "name": "Smyrna",
                            "latitude": "35.98284000",
                            "longitude": "-86.51860000"
                        },
                        {
                            "id": 126540,
                            "name": "Sneedville",
                            "latitude": "36.52981000",
                            "longitude": "-83.21740000"
                        },
                        {
                            "id": 126563,
                            "name": "Soddy-Daisy",
                            "latitude": "35.23590000",
                            "longitude": "-85.19079000"
                        },
                        {
                            "id": 126593,
                            "name": "Somerville",
                            "latitude": "35.24370000",
                            "longitude": "-89.35007000"
                        },
                        {
                            "id": 126635,
                            "name": "South Carthage",
                            "latitude": "36.24200000",
                            "longitude": "-85.95193000"
                        },
                        {
                            "id": 126639,
                            "name": "South Cleveland",
                            "latitude": "35.14119000",
                            "longitude": "-84.87217000"
                        },
                        {
                            "id": 126656,
                            "name": "South Fulton",
                            "latitude": "36.50089000",
                            "longitude": "-88.87534000"
                        },
                        {
                            "id": 126710,
                            "name": "South Pittsburg",
                            "latitude": "35.01230000",
                            "longitude": "-85.70441000"
                        },
                        {
                            "id": 126810,
                            "name": "Sparta",
                            "latitude": "35.92590000",
                            "longitude": "-85.46414000"
                        },
                        {
                            "id": 126823,
                            "name": "Spencer",
                            "latitude": "35.74729000",
                            "longitude": "-85.46664000"
                        },
                        {
                            "id": 126851,
                            "name": "Spring City",
                            "latitude": "35.69201000",
                            "longitude": "-84.86078000"
                        },
                        {
                            "id": 126862,
                            "name": "Spring Hill",
                            "latitude": "35.75118000",
                            "longitude": "-86.93000000"
                        },
                        {
                            "id": 126895,
                            "name": "Springfield",
                            "latitude": "36.50921000",
                            "longitude": "-86.88500000"
                        },
                        {
                            "id": 126922,
                            "name": "Spurgeon",
                            "latitude": "36.43955000",
                            "longitude": "-82.45570000"
                        },
                        {
                            "id": 127051,
                            "name": "Stewart County",
                            "latitude": "36.50110000",
                            "longitude": "-87.83846000"
                        },
                        {
                            "id": 127187,
                            "name": "Sullivan County",
                            "latitude": "36.51292000",
                            "longitude": "-82.30414000"
                        },
                        {
                            "id": 127225,
                            "name": "Sumner County",
                            "latitude": "36.46941000",
                            "longitude": "-86.46036000"
                        },
                        {
                            "id": 127289,
                            "name": "Surgoinsville",
                            "latitude": "36.47093000",
                            "longitude": "-82.85183000"
                        },
                        {
                            "id": 127341,
                            "name": "Sweetwater",
                            "latitude": "35.60146000",
                            "longitude": "-84.46104000"
                        },
                        {
                            "id": 127468,
                            "name": "Tazewell",
                            "latitude": "36.45425000",
                            "longitude": "-83.56935000"
                        },
                        {
                            "id": 127490,
                            "name": "Tellico Village",
                            "latitude": "35.68321000",
                            "longitude": "-84.25518000"
                        },
                        {
                            "id": 127510,
                            "name": "Tennessee Ridge",
                            "latitude": "36.31200000",
                            "longitude": "-87.77336000"
                        },
                        {
                            "id": 127593,
                            "name": "Thompson's Station",
                            "latitude": "35.80201000",
                            "longitude": "-86.91139000"
                        },
                        {
                            "id": 127622,
                            "name": "Three Way",
                            "latitude": "35.77590000",
                            "longitude": "-88.85950000"
                        },
                        {
                            "id": 127679,
                            "name": "Tipton County",
                            "latitude": "35.49687000",
                            "longitude": "-89.75924000"
                        },
                        {
                            "id": 127681,
                            "name": "Tiptonville",
                            "latitude": "36.37840000",
                            "longitude": "-89.47202000"
                        },
                        {
                            "id": 127772,
                            "name": "Tracy City",
                            "latitude": "35.26036000",
                            "longitude": "-85.73608000"
                        },
                        {
                            "id": 127805,
                            "name": "Trenton",
                            "latitude": "35.98062000",
                            "longitude": "-88.94145000"
                        },
                        {
                            "id": 127837,
                            "name": "Trousdale County",
                            "latitude": "36.39204000",
                            "longitude": "-86.15675000"
                        },
                        {
                            "id": 127847,
                            "name": "Troy",
                            "latitude": "36.33868000",
                            "longitude": "-89.16396000"
                        },
                        {
                            "id": 127886,
                            "name": "Tullahoma",
                            "latitude": "35.36202000",
                            "longitude": "-86.20943000"
                        },
                        {
                            "id": 127913,
                            "name": "Tusculum",
                            "latitude": "36.17510000",
                            "longitude": "-82.75876000"
                        },
                        {
                            "id": 127969,
                            "name": "Unicoi",
                            "latitude": "36.19539000",
                            "longitude": "-82.34958000"
                        },
                        {
                            "id": 127970,
                            "name": "Unicoi County",
                            "latitude": "36.11082000",
                            "longitude": "-82.43224000"
                        },
                        {
                            "id": 127982,
                            "name": "Union City",
                            "latitude": "36.42423000",
                            "longitude": "-89.05701000"
                        },
                        {
                            "id": 127997,
                            "name": "Union County",
                            "latitude": "36.28787000",
                            "longitude": "-83.83751000"
                        },
                        {
                            "id": 128019,
                            "name": "Unionville",
                            "latitude": "35.62174000",
                            "longitude": "-86.59250000"
                        },
                        {
                            "id": 128130,
                            "name": "Van Buren County",
                            "latitude": "35.69598000",
                            "longitude": "-85.45261000"
                        },
                        {
                            "id": 128305,
                            "name": "Vonore",
                            "latitude": "35.59008000",
                            "longitude": "-84.24186000"
                        },
                        {
                            "id": 128367,
                            "name": "Walden",
                            "latitude": "35.16479000",
                            "longitude": "-85.30135000"
                        },
                        {
                            "id": 128421,
                            "name": "Walnut Hill",
                            "latitude": "36.56983000",
                            "longitude": "-82.25680000"
                        },
                        {
                            "id": 128491,
                            "name": "Warren County",
                            "latitude": "35.67868000",
                            "longitude": "-85.77850000"
                        },
                        {
                            "id": 128523,
                            "name": "Wartburg",
                            "latitude": "36.10480000",
                            "longitude": "-84.59716000"
                        },
                        {
                            "id": 128565,
                            "name": "Washington County",
                            "latitude": "36.29330000",
                            "longitude": "-82.49743000"
                        },
                        {
                            "id": 128619,
                            "name": "Watertown",
                            "latitude": "36.10034000",
                            "longitude": "-86.13193000"
                        },
                        {
                            "id": 128666,
                            "name": "Waverly",
                            "latitude": "36.08395000",
                            "longitude": "-87.79475000"
                        },
                        {
                            "id": 128696,
                            "name": "Wayne County",
                            "latitude": "35.23992000",
                            "longitude": "-87.78801000"
                        },
                        {
                            "id": 128708,
                            "name": "Waynesboro",
                            "latitude": "35.31952000",
                            "longitude": "-87.76225000"
                        },
                        {
                            "id": 128714,
                            "name": "Weakley County",
                            "latitude": "36.29830000",
                            "longitude": "-88.71774000"
                        },
                        {
                            "id": 129053,
                            "name": "Westmoreland",
                            "latitude": "36.56199000",
                            "longitude": "-86.24804000"
                        },
                        {
                            "id": 129127,
                            "name": "White Bluff",
                            "latitude": "36.10756000",
                            "longitude": "-87.22084000"
                        },
                        {
                            "id": 129137,
                            "name": "White County",
                            "latitude": "35.92635000",
                            "longitude": "-85.45518000"
                        },
                        {
                            "id": 129143,
                            "name": "White House",
                            "latitude": "36.47032000",
                            "longitude": "-86.65138000"
                        },
                        {
                            "id": 129152,
                            "name": "White Pine",
                            "latitude": "36.10759000",
                            "longitude": "-83.28683000"
                        },
                        {
                            "id": 129190,
                            "name": "Whiteville",
                            "latitude": "35.32647000",
                            "longitude": "-89.14951000"
                        },
                        {
                            "id": 129212,
                            "name": "Whitwell",
                            "latitude": "35.20146000",
                            "longitude": "-85.51913000"
                        },
                        {
                            "id": 129239,
                            "name": "Wildwood",
                            "latitude": "35.80370000",
                            "longitude": "-83.87129000"
                        },
                        {
                            "id": 129242,
                            "name": "Wildwood Lake",
                            "latitude": "35.09202000",
                            "longitude": "-84.85439000"
                        },
                        {
                            "id": 129277,
                            "name": "Williamson County",
                            "latitude": "35.89378000",
                            "longitude": "-86.89860000"
                        },
                        {
                            "id": 129336,
                            "name": "Wilson County",
                            "latitude": "36.15486000",
                            "longitude": "-86.29763000"
                        },
                        {
                            "id": 129355,
                            "name": "Winchester",
                            "latitude": "35.18592000",
                            "longitude": "-86.11221000"
                        },
                        {
                            "id": 129507,
                            "name": "Woodbury",
                            "latitude": "35.82757000",
                            "longitude": "-86.07166000"
                        }
                    ]
                },
                {
                    "id": 1407,
                    "name": "Texas",
                    "state_code": "TX",
                    "cities": [
                        {
                            "id": 110981,
                            "name": "Abernathy",
                            "latitude": "33.83230000",
                            "longitude": "-101.84295000"
                        },
                        {
                            "id": 110983,
                            "name": "Abilene",
                            "latitude": "32.44874000",
                            "longitude": "-99.73314000"
                        },
                        {
                            "id": 110988,
                            "name": "Abram",
                            "latitude": "26.19980000",
                            "longitude": "-98.41113000"
                        },
                        {
                            "id": 111034,
                            "name": "Addison",
                            "latitude": "32.96179000",
                            "longitude": "-96.82917000"
                        },
                        {
                            "id": 111059,
                            "name": "Agua Dulce",
                            "latitude": "31.65511000",
                            "longitude": "-106.13887000"
                        },
                        {
                            "id": 111092,
                            "name": "Alamo",
                            "latitude": "26.18369000",
                            "longitude": "-98.12306000"
                        },
                        {
                            "id": 111096,
                            "name": "Alamo Heights",
                            "latitude": "29.48495000",
                            "longitude": "-98.46585000"
                        },
                        {
                            "id": 111104,
                            "name": "Albany",
                            "latitude": "32.72345000",
                            "longitude": "-99.29730000"
                        },
                        {
                            "id": 111140,
                            "name": "Aldine",
                            "latitude": "29.93245000",
                            "longitude": "-95.38021000"
                        },
                        {
                            "id": 111141,
                            "name": "Aledo",
                            "latitude": "32.69596000",
                            "longitude": "-97.60225000"
                        },
                        {
                            "id": 111171,
                            "name": "Alice",
                            "latitude": "27.75225000",
                            "longitude": "-98.06972000"
                        },
                        {
                            "id": 111173,
                            "name": "Alief",
                            "latitude": "29.71106000",
                            "longitude": "-95.59633000"
                        },
                        {
                            "id": 111187,
                            "name": "Allen",
                            "latitude": "33.10317000",
                            "longitude": "-96.67055000"
                        },
                        {
                            "id": 111224,
                            "name": "Alpine",
                            "latitude": "30.35862000",
                            "longitude": "-103.66206000"
                        },
                        {
                            "id": 111240,
                            "name": "Alto",
                            "latitude": "31.65045000",
                            "longitude": "-95.07272000"
                        },
                        {
                            "id": 111244,
                            "name": "Alton",
                            "latitude": "26.28729000",
                            "longitude": "-98.31335000"
                        },
                        {
                            "id": 111246,
                            "name": "Alton North (historical)",
                            "latitude": "26.29535000",
                            "longitude": "-98.30446000"
                        },
                        {
                            "id": 111257,
                            "name": "Alvarado",
                            "latitude": "32.40653000",
                            "longitude": "-97.21168000"
                        },
                        {
                            "id": 111258,
                            "name": "Alvin",
                            "latitude": "29.42385000",
                            "longitude": "-95.24410000"
                        },
                        {
                            "id": 111259,
                            "name": "Alvord",
                            "latitude": "33.35845000",
                            "longitude": "-97.69475000"
                        },
                        {
                            "id": 111263,
                            "name": "Amarillo",
                            "latitude": "35.22200000",
                            "longitude": "-101.83130000"
                        },
                        {
                            "id": 111276,
                            "name": "Ames",
                            "latitude": "30.05383000",
                            "longitude": "-94.74353000"
                        },
                        {
                            "id": 111301,
                            "name": "Anahuac",
                            "latitude": "29.77300000",
                            "longitude": "-94.68270000"
                        },
                        {
                            "id": 111312,
                            "name": "Anderson",
                            "latitude": "30.48715000",
                            "longitude": "-95.98690000"
                        },
                        {
                            "id": 111319,
                            "name": "Anderson County",
                            "latitude": "31.81333000",
                            "longitude": "-95.65255000"
                        },
                        {
                            "id": 111320,
                            "name": "Anderson Mill",
                            "latitude": "30.45492000",
                            "longitude": "-97.80584000"
                        },
                        {
                            "id": 111331,
                            "name": "Andrews",
                            "latitude": "32.31872000",
                            "longitude": "-102.54572000"
                        },
                        {
                            "id": 111333,
                            "name": "Andrews County",
                            "latitude": "32.30503000",
                            "longitude": "-102.63781000"
                        },
                        {
                            "id": 111336,
                            "name": "Angelina County",
                            "latitude": "31.25476000",
                            "longitude": "-94.61185000"
                        },
                        {
                            "id": 111339,
                            "name": "Angleton",
                            "latitude": "29.16941000",
                            "longitude": "-95.43188000"
                        },
                        {
                            "id": 111347,
                            "name": "Anna",
                            "latitude": "33.34900000",
                            "longitude": "-96.54860000"
                        },
                        {
                            "id": 111354,
                            "name": "Annetta",
                            "latitude": "32.70930000",
                            "longitude": "-97.67614000"
                        },
                        {
                            "id": 111360,
                            "name": "Anson",
                            "latitude": "32.75650000",
                            "longitude": "-99.89621000"
                        },
                        {
                            "id": 111370,
                            "name": "Anthony",
                            "latitude": "31.99927000",
                            "longitude": "-106.60555000"
                        },
                        {
                            "id": 111375,
                            "name": "Anton",
                            "latitude": "33.81120000",
                            "longitude": "-102.16379000"
                        },
                        {
                            "id": 111410,
                            "name": "Aransas County",
                            "latitude": "28.01501000",
                            "longitude": "-97.07382000"
                        },
                        {
                            "id": 111411,
                            "name": "Aransas Pass",
                            "latitude": "27.90947000",
                            "longitude": "-97.14999000"
                        },
                        {
                            "id": 111430,
                            "name": "Archer City",
                            "latitude": "33.59566000",
                            "longitude": "-98.62561000"
                        },
                        {
                            "id": 111431,
                            "name": "Archer County",
                            "latitude": "33.61525000",
                            "longitude": "-98.68765000"
                        },
                        {
                            "id": 111437,
                            "name": "Arcola",
                            "latitude": "29.49607000",
                            "longitude": "-95.46578000"
                        },
                        {
                            "id": 111450,
                            "name": "Argyle",
                            "latitude": "33.12123000",
                            "longitude": "-97.18335000"
                        },
                        {
                            "id": 111464,
                            "name": "Arlington",
                            "latitude": "32.73569000",
                            "longitude": "-97.10807000"
                        },
                        {
                            "id": 111481,
                            "name": "Armstrong County",
                            "latitude": "34.96493000",
                            "longitude": "-101.35740000"
                        },
                        {
                            "id": 111521,
                            "name": "Asherton",
                            "latitude": "28.44193000",
                            "longitude": "-99.76033000"
                        },
                        {
                            "id": 111554,
                            "name": "Aspermont",
                            "latitude": "33.13343000",
                            "longitude": "-100.22733000"
                        },
                        {
                            "id": 111565,
                            "name": "Atascocita",
                            "latitude": "29.99883000",
                            "longitude": "-95.17660000"
                        },
                        {
                            "id": 111566,
                            "name": "Atascosa County",
                            "latitude": "28.89352000",
                            "longitude": "-98.52713000"
                        },
                        {
                            "id": 111576,
                            "name": "Athens",
                            "latitude": "32.20487000",
                            "longitude": "-95.85552000"
                        },
                        {
                            "id": 111591,
                            "name": "Atlanta",
                            "latitude": "33.11374000",
                            "longitude": "-94.16435000"
                        },
                        {
                            "id": 111618,
                            "name": "Aubrey",
                            "latitude": "33.30428000",
                            "longitude": "-96.98612000"
                        },
                        {
                            "id": 111658,
                            "name": "Aurora",
                            "latitude": "33.06068000",
                            "longitude": "-97.50336000"
                        },
                        {
                            "id": 111668,
                            "name": "Austin",
                            "latitude": "30.26715000",
                            "longitude": "-97.74306000"
                        },
                        {
                            "id": 111670,
                            "name": "Austin County",
                            "latitude": "29.88702000",
                            "longitude": "-96.27791000"
                        },
                        {
                            "id": 111710,
                            "name": "Azle",
                            "latitude": "32.89513000",
                            "longitude": "-97.54586000"
                        },
                        {
                            "id": 111719,
                            "name": "Bacliff",
                            "latitude": "29.50690000",
                            "longitude": "-94.99243000"
                        },
                        {
                            "id": 111730,
                            "name": "Bailey County",
                            "latitude": "34.06852000",
                            "longitude": "-102.82987000"
                        },
                        {
                            "id": 111736,
                            "name": "Baird",
                            "latitude": "32.39402000",
                            "longitude": "-99.39424000"
                        },
                        {
                            "id": 111749,
                            "name": "Balch Springs",
                            "latitude": "32.72874000",
                            "longitude": "-96.62277000"
                        },
                        {
                            "id": 111750,
                            "name": "Balcones Heights",
                            "latitude": "29.48801000",
                            "longitude": "-98.55169000"
                        },
                        {
                            "id": 111773,
                            "name": "Ballinger",
                            "latitude": "31.73821000",
                            "longitude": "-99.94731000"
                        },
                        {
                            "id": 111788,
                            "name": "Bandera",
                            "latitude": "29.72661000",
                            "longitude": "-99.07365000"
                        },
                        {
                            "id": 111789,
                            "name": "Bandera County",
                            "latitude": "29.74721000",
                            "longitude": "-99.24624000"
                        },
                        {
                            "id": 111796,
                            "name": "Bangs",
                            "latitude": "31.71710000",
                            "longitude": "-99.13255000"
                        },
                        {
                            "id": 111844,
                            "name": "Barrett",
                            "latitude": "29.87995000",
                            "longitude": "-95.06298000"
                        },
                        {
                            "id": 111862,
                            "name": "Bartlett",
                            "latitude": "30.79491000",
                            "longitude": "-97.42556000"
                        },
                        {
                            "id": 111868,
                            "name": "Barton Creek",
                            "latitude": "30.28521000",
                            "longitude": "-97.86917000"
                        },
                        {
                            "id": 111870,
                            "name": "Bartonville",
                            "latitude": "33.07318000",
                            "longitude": "-97.13168000"
                        },
                        {
                            "id": 111884,
                            "name": "Bastrop",
                            "latitude": "30.11049000",
                            "longitude": "-97.31527000"
                        },
                        {
                            "id": 111885,
                            "name": "Bastrop County",
                            "latitude": "30.10361000",
                            "longitude": "-97.31201000"
                        },
                        {
                            "id": 111895,
                            "name": "Batesville",
                            "latitude": "28.95108000",
                            "longitude": "-99.61783000"
                        },
                        {
                            "id": 111921,
                            "name": "Bay City",
                            "latitude": "28.98276000",
                            "longitude": "-95.96940000"
                        },
                        {
                            "id": 111943,
                            "name": "Baylor County",
                            "latitude": "33.61649000",
                            "longitude": "-99.21351000"
                        },
                        {
                            "id": 111950,
                            "name": "Bayou Vista",
                            "latitude": "29.32635000",
                            "longitude": "-94.93853000"
                        },
                        {
                            "id": 111958,
                            "name": "Baytown",
                            "latitude": "29.73550000",
                            "longitude": "-94.97743000"
                        },
                        {
                            "id": 111963,
                            "name": "Beach City",
                            "latitude": "29.66217000",
                            "longitude": "-94.88992000"
                        },
                        {
                            "id": 111989,
                            "name": "Beaumont",
                            "latitude": "30.08605000",
                            "longitude": "-94.10185000"
                        },
                        {
                            "id": 112021,
                            "name": "Bedford",
                            "latitude": "32.84402000",
                            "longitude": "-97.14307000"
                        },
                        {
                            "id": 112033,
                            "name": "Bee Cave",
                            "latitude": "30.30854000",
                            "longitude": "-97.94501000"
                        },
                        {
                            "id": 112034,
                            "name": "Bee County",
                            "latitude": "28.41739000",
                            "longitude": "-97.74119000"
                        },
                        {
                            "id": 112044,
                            "name": "Beeville",
                            "latitude": "28.40095000",
                            "longitude": "-97.74974000"
                        },
                        {
                            "id": 112069,
                            "name": "Bell County",
                            "latitude": "31.03767000",
                            "longitude": "-97.47820000"
                        },
                        {
                            "id": 112075,
                            "name": "Bellaire",
                            "latitude": "29.70579000",
                            "longitude": "-95.45883000"
                        },
                        {
                            "id": 112122,
                            "name": "Bellmead",
                            "latitude": "31.59405000",
                            "longitude": "-97.10889000"
                        },
                        {
                            "id": 112127,
                            "name": "Bells",
                            "latitude": "33.61038000",
                            "longitude": "-96.41082000"
                        },
                        {
                            "id": 112129,
                            "name": "Bellville",
                            "latitude": "29.95023000",
                            "longitude": "-96.25719000"
                        },
                        {
                            "id": 112149,
                            "name": "Belton",
                            "latitude": "31.05601000",
                            "longitude": "-97.46445000"
                        },
                        {
                            "id": 112164,
                            "name": "Benavides",
                            "latitude": "27.59892000",
                            "longitude": "-98.40807000"
                        },
                        {
                            "id": 112165,
                            "name": "Benbrook",
                            "latitude": "32.67319000",
                            "longitude": "-97.46058000"
                        },
                        {
                            "id": 112169,
                            "name": "Benjamin",
                            "latitude": "33.58398000",
                            "longitude": "-99.79231000"
                        },
                        {
                            "id": 112256,
                            "name": "Berryville",
                            "latitude": "32.08849000",
                            "longitude": "-95.47190000"
                        },
                        {
                            "id": 112259,
                            "name": "Bertram",
                            "latitude": "30.74380000",
                            "longitude": "-98.05558000"
                        },
                        {
                            "id": 112299,
                            "name": "Beverly",
                            "latitude": "31.52517000",
                            "longitude": "-97.14195000"
                        },
                        {
                            "id": 112304,
                            "name": "Beverly Hills",
                            "latitude": "31.52156000",
                            "longitude": "-97.15389000"
                        },
                        {
                            "id": 112307,
                            "name": "Bevil Oaks",
                            "latitude": "30.15021000",
                            "longitude": "-94.26963000"
                        },
                        {
                            "id": 112308,
                            "name": "Bexar County",
                            "latitude": "29.44896000",
                            "longitude": "-98.52002000"
                        },
                        {
                            "id": 112324,
                            "name": "Big Lake",
                            "latitude": "31.19154000",
                            "longitude": "-101.46039000"
                        },
                        {
                            "id": 112333,
                            "name": "Big Sandy",
                            "latitude": "32.58375000",
                            "longitude": "-95.10883000"
                        },
                        {
                            "id": 112335,
                            "name": "Big Spring",
                            "latitude": "32.25040000",
                            "longitude": "-101.47874000"
                        },
                        {
                            "id": 112363,
                            "name": "Bishop",
                            "latitude": "27.58614000",
                            "longitude": "-97.79916000"
                        },
                        {
                            "id": 112418,
                            "name": "Blanco",
                            "latitude": "30.09799000",
                            "longitude": "-98.42141000"
                        },
                        {
                            "id": 112419,
                            "name": "Blanco County",
                            "latitude": "30.26638000",
                            "longitude": "-98.39987000"
                        },
                        {
                            "id": 112450,
                            "name": "Bloomington",
                            "latitude": "28.64777000",
                            "longitude": "-96.89249000"
                        },
                        {
                            "id": 112456,
                            "name": "Blossom",
                            "latitude": "33.66150000",
                            "longitude": "-95.38579000"
                        },
                        {
                            "id": 112472,
                            "name": "Blue Mound",
                            "latitude": "32.85652000",
                            "longitude": "-97.33891000"
                        },
                        {
                            "id": 112497,
                            "name": "Boerne",
                            "latitude": "29.79466000",
                            "longitude": "-98.73197000"
                        },
                        {
                            "id": 112500,
                            "name": "Bogata",
                            "latitude": "33.47067000",
                            "longitude": "-95.21384000"
                        },
                        {
                            "id": 112515,
                            "name": "Boling",
                            "latitude": "29.26441000",
                            "longitude": "-95.94384000"
                        },
                        {
                            "id": 112522,
                            "name": "Bolivar Peninsula",
                            "latitude": "29.47829000",
                            "longitude": "-94.57991000"
                        },
                        {
                            "id": 112535,
                            "name": "Bonham",
                            "latitude": "33.57733000",
                            "longitude": "-96.17831000"
                        },
                        {
                            "id": 112551,
                            "name": "Booker",
                            "latitude": "36.45336000",
                            "longitude": "-100.53737000"
                        },
                        {
                            "id": 112575,
                            "name": "Borden County",
                            "latitude": "32.74369000",
                            "longitude": "-101.43175000"
                        },
                        {
                            "id": 112577,
                            "name": "Borger",
                            "latitude": "35.66782000",
                            "longitude": "-101.39739000"
                        },
                        {
                            "id": 112584,
                            "name": "Bosque County",
                            "latitude": "31.90040000",
                            "longitude": "-97.63435000"
                        },
                        {
                            "id": 112619,
                            "name": "Bovina",
                            "latitude": "34.51368000",
                            "longitude": "-102.88300000"
                        },
                        {
                            "id": 112624,
                            "name": "Bowie",
                            "latitude": "33.55900000",
                            "longitude": "-97.84865000"
                        },
                        {
                            "id": 112625,
                            "name": "Bowie County",
                            "latitude": "33.44576000",
                            "longitude": "-94.42332000"
                        },
                        {
                            "id": 112641,
                            "name": "Boyd",
                            "latitude": "33.07873000",
                            "longitude": "-97.56530000"
                        },
                        {
                            "id": 112657,
                            "name": "Brackettville",
                            "latitude": "29.31051000",
                            "longitude": "-100.41786000"
                        },
                        {
                            "id": 112678,
                            "name": "Brady",
                            "latitude": "31.13517000",
                            "longitude": "-99.33506000"
                        },
                        {
                            "id": 112704,
                            "name": "Brazoria",
                            "latitude": "29.04441000",
                            "longitude": "-95.56911000"
                        },
                        {
                            "id": 112705,
                            "name": "Brazoria County",
                            "latitude": "29.16783000",
                            "longitude": "-95.43426000"
                        },
                        {
                            "id": 112706,
                            "name": "Brazos County",
                            "latitude": "30.66080000",
                            "longitude": "-96.30239000"
                        },
                        {
                            "id": 112710,
                            "name": "Breckenridge",
                            "latitude": "32.75568000",
                            "longitude": "-98.90229000"
                        },
                        {
                            "id": 112724,
                            "name": "Brenham",
                            "latitude": "30.16688000",
                            "longitude": "-96.39774000"
                        },
                        {
                            "id": 112745,
                            "name": "Brewster County",
                            "latitude": "29.81195000",
                            "longitude": "-103.25176000"
                        },
                        {
                            "id": 112748,
                            "name": "Briar",
                            "latitude": "32.99512000",
                            "longitude": "-97.54280000"
                        },
                        {
                            "id": 112749,
                            "name": "Briarcliff",
                            "latitude": "30.40742000",
                            "longitude": "-98.04446000"
                        },
                        {
                            "id": 112756,
                            "name": "Bridge City",
                            "latitude": "30.02077000",
                            "longitude": "-93.84573000"
                        },
                        {
                            "id": 112759,
                            "name": "Bridgeport",
                            "latitude": "33.21012000",
                            "longitude": "-97.75476000"
                        },
                        {
                            "id": 112801,
                            "name": "Briscoe County",
                            "latitude": "34.53026000",
                            "longitude": "-101.20859000"
                        },
                        {
                            "id": 112884,
                            "name": "Brooks County",
                            "latitude": "27.03157000",
                            "longitude": "-98.21872000"
                        },
                        {
                            "id": 112885,
                            "name": "Brookshire",
                            "latitude": "29.78606000",
                            "longitude": "-95.95107000"
                        },
                        {
                            "id": 112888,
                            "name": "Brookside Village",
                            "latitude": "29.58690000",
                            "longitude": "-95.32522000"
                        },
                        {
                            "id": 112910,
                            "name": "Brown County",
                            "latitude": "31.77426000",
                            "longitude": "-98.99979000"
                        },
                        {
                            "id": 112917,
                            "name": "Brownfield",
                            "latitude": "33.18120000",
                            "longitude": "-102.27435000"
                        },
                        {
                            "id": 112923,
                            "name": "Brownsboro",
                            "latitude": "32.30237000",
                            "longitude": "-95.61357000"
                        },
                        {
                            "id": 112932,
                            "name": "Brownsville",
                            "latitude": "25.90175000",
                            "longitude": "-97.49748000"
                        },
                        {
                            "id": 112940,
                            "name": "Brownwood",
                            "latitude": "31.70932000",
                            "longitude": "-98.99116000"
                        },
                        {
                            "id": 112944,
                            "name": "Bruceville-Eddy",
                            "latitude": "31.30517000",
                            "longitude": "-97.25167000"
                        },
                        {
                            "id": 112956,
                            "name": "Brushy Creek",
                            "latitude": "30.51353000",
                            "longitude": "-97.73973000"
                        },
                        {
                            "id": 112959,
                            "name": "Bryan",
                            "latitude": "30.67436000",
                            "longitude": "-96.36996000"
                        },
                        {
                            "id": 112976,
                            "name": "Buchanan Dam",
                            "latitude": "30.73990000",
                            "longitude": "-98.43114000"
                        },
                        {
                            "id": 112993,
                            "name": "Buda",
                            "latitude": "30.08521000",
                            "longitude": "-97.84028000"
                        },
                        {
                            "id": 113010,
                            "name": "Buffalo",
                            "latitude": "31.46379000",
                            "longitude": "-96.05802000"
                        },
                        {
                            "id": 113030,
                            "name": "Bullard",
                            "latitude": "32.13988000",
                            "longitude": "-95.32023000"
                        },
                        {
                            "id": 113035,
                            "name": "Bulverde",
                            "latitude": "29.74383000",
                            "longitude": "-98.45307000"
                        },
                        {
                            "id": 113036,
                            "name": "Buna",
                            "latitude": "30.43298000",
                            "longitude": "-93.96240000"
                        },
                        {
                            "id": 113042,
                            "name": "Bunker Hill Village",
                            "latitude": "29.76745000",
                            "longitude": "-95.52994000"
                        },
                        {
                            "id": 113053,
                            "name": "Burkburnett",
                            "latitude": "34.09787000",
                            "longitude": "-98.57061000"
                        },
                        {
                            "id": 113061,
                            "name": "Burleson",
                            "latitude": "32.54208000",
                            "longitude": "-97.32085000"
                        },
                        {
                            "id": 113062,
                            "name": "Burleson County",
                            "latitude": "30.49248000",
                            "longitude": "-96.62146000"
                        },
                        {
                            "id": 113078,
                            "name": "Burnet",
                            "latitude": "30.75824000",
                            "longitude": "-98.22836000"
                        },
                        {
                            "id": 113079,
                            "name": "Burnet County",
                            "latitude": "30.78830000",
                            "longitude": "-98.18245000"
                        },
                        {
                            "id": 113100,
                            "name": "Bushland",
                            "latitude": "35.19200000",
                            "longitude": "-102.06464000"
                        },
                        {
                            "id": 113157,
                            "name": "Cactus",
                            "latitude": "36.05226000",
                            "longitude": "-102.00240000"
                        },
                        {
                            "id": 113161,
                            "name": "Caddo Mills",
                            "latitude": "33.06567000",
                            "longitude": "-96.22776000"
                        },
                        {
                            "id": 113180,
                            "name": "Caldwell",
                            "latitude": "30.53132000",
                            "longitude": "-96.69303000"
                        },
                        {
                            "id": 113186,
                            "name": "Caldwell County",
                            "latitude": "29.83712000",
                            "longitude": "-97.62000000"
                        },
                        {
                            "id": 113207,
                            "name": "Calhoun County",
                            "latitude": "28.43911000",
                            "longitude": "-96.61507000"
                        },
                        {
                            "id": 113223,
                            "name": "Callahan County",
                            "latitude": "32.29766000",
                            "longitude": "-99.37349000"
                        },
                        {
                            "id": 113233,
                            "name": "Calvert",
                            "latitude": "30.97797000",
                            "longitude": "-96.67386000"
                        },
                        {
                            "id": 113275,
                            "name": "Cameron",
                            "latitude": "30.85325000",
                            "longitude": "-96.97693000"
                        },
                        {
                            "id": 113277,
                            "name": "Cameron County",
                            "latitude": "26.15150000",
                            "longitude": "-97.45286000"
                        },
                        {
                            "id": 113280,
                            "name": "Cameron Park",
                            "latitude": "25.96452000",
                            "longitude": "-97.47665000"
                        },
                        {
                            "id": 113282,
                            "name": "Cameron Park Colonia",
                            "latitude": "25.97147000",
                            "longitude": "-97.47832000"
                        },
                        {
                            "id": 113286,
                            "name": "Camp County",
                            "latitude": "32.97322000",
                            "longitude": "-94.97848000"
                        },
                        {
                            "id": 113294,
                            "name": "Camp Swift",
                            "latitude": "30.19077000",
                            "longitude": "-97.29221000"
                        },
                        {
                            "id": 113314,
                            "name": "Canadian",
                            "latitude": "35.91282000",
                            "longitude": "-100.38208000"
                        },
                        {
                            "id": 113342,
                            "name": "Canton",
                            "latitude": "32.55652000",
                            "longitude": "-95.86330000"
                        },
                        {
                            "id": 113353,
                            "name": "Canutillo",
                            "latitude": "31.91149000",
                            "longitude": "-106.60027000"
                        },
                        {
                            "id": 113354,
                            "name": "Canyon",
                            "latitude": "34.98033000",
                            "longitude": "-101.91880000"
                        },
                        {
                            "id": 113358,
                            "name": "Canyon Lake",
                            "latitude": "29.87522000",
                            "longitude": "-98.26251000"
                        },
                        {
                            "id": 113447,
                            "name": "Carrizo Springs",
                            "latitude": "28.52193000",
                            "longitude": "-99.86061000"
                        },
                        {
                            "id": 113470,
                            "name": "Carrollton",
                            "latitude": "32.95373000",
                            "longitude": "-96.89028000"
                        },
                        {
                            "id": 113479,
                            "name": "Carson County",
                            "latitude": "35.40351000",
                            "longitude": "-101.35418000"
                        },
                        {
                            "id": 113495,
                            "name": "Carthage",
                            "latitude": "32.15738000",
                            "longitude": "-94.33742000"
                        },
                        {
                            "id": 113528,
                            "name": "Cass County",
                            "latitude": "33.07754000",
                            "longitude": "-94.34359000"
                        },
                        {
                            "id": 113545,
                            "name": "Castle Hills",
                            "latitude": "29.52329000",
                            "longitude": "-98.51641000"
                        },
                        {
                            "id": 113556,
                            "name": "Castro County",
                            "latitude": "34.52996000",
                            "longitude": "-102.26167000"
                        },
                        {
                            "id": 113558,
                            "name": "Castroville",
                            "latitude": "29.35579000",
                            "longitude": "-98.87864000"
                        },
                        {
                            "id": 113614,
                            "name": "Cedar Hill",
                            "latitude": "32.58847000",
                            "longitude": "-96.95612000"
                        },
                        {
                            "id": 113619,
                            "name": "Cedar Park",
                            "latitude": "30.50520000",
                            "longitude": "-97.82029000"
                        },
                        {
                            "id": 113631,
                            "name": "Celina",
                            "latitude": "33.32456000",
                            "longitude": "-96.78444000"
                        },
                        {
                            "id": 113636,
                            "name": "Center",
                            "latitude": "31.79545000",
                            "longitude": "-94.17909000"
                        },
                        {
                            "id": 113657,
                            "name": "Centerville",
                            "latitude": "31.25796000",
                            "longitude": "-95.97829000"
                        },
                        {
                            "id": 113675,
                            "name": "Central Gardens",
                            "latitude": "29.99549000",
                            "longitude": "-94.01406000"
                        },
                        {
                            "id": 114960,
                            "name": "César Chávez",
                            "latitude": "26.30340000",
                            "longitude": "-98.11529000"
                        },
                        {
                            "id": 113721,
                            "name": "Chambers County",
                            "latitude": "29.70826000",
                            "longitude": "-94.67138000"
                        },
                        {
                            "id": 113730,
                            "name": "Chandler",
                            "latitude": "32.30793000",
                            "longitude": "-95.47996000"
                        },
                        {
                            "id": 113736,
                            "name": "Channelview",
                            "latitude": "29.77606000",
                            "longitude": "-95.11465000"
                        },
                        {
                            "id": 113737,
                            "name": "Channing",
                            "latitude": "35.68365000",
                            "longitude": "-102.33020000"
                        },
                        {
                            "id": 113778,
                            "name": "Charlotte",
                            "latitude": "28.86192000",
                            "longitude": "-98.70641000"
                        },
                        {
                            "id": 113855,
                            "name": "Cherokee County",
                            "latitude": "31.83695000",
                            "longitude": "-95.16518000"
                        },
                        {
                            "id": 113942,
                            "name": "Chico",
                            "latitude": "33.29595000",
                            "longitude": "-97.79892000"
                        },
                        {
                            "id": 113950,
                            "name": "Childress",
                            "latitude": "34.42645000",
                            "longitude": "-100.20400000"
                        },
                        {
                            "id": 113951,
                            "name": "Childress County",
                            "latitude": "34.52920000",
                            "longitude": "-100.20757000"
                        },
                        {
                            "id": 113959,
                            "name": "China",
                            "latitude": "30.04799000",
                            "longitude": "-94.33574000"
                        },
                        {
                            "id": 113962,
                            "name": "China Grove",
                            "latitude": "29.38885000",
                            "longitude": "-98.34890000"
                        },
                        {
                            "id": 114018,
                            "name": "Cibolo",
                            "latitude": "29.56162000",
                            "longitude": "-98.22696000"
                        },
                        {
                            "id": 114022,
                            "name": "Cienegas Terrace",
                            "latitude": "29.36745000",
                            "longitude": "-100.94371000"
                        },
                        {
                            "id": 114026,
                            "name": "Cinco Ranch",
                            "latitude": "29.73884000",
                            "longitude": "-95.75800000"
                        },
                        {
                            "id": 114029,
                            "name": "Circle D-KC Estates",
                            "latitude": "30.16100000",
                            "longitude": "-97.23473000"
                        },
                        {
                            "id": 114031,
                            "name": "Cisco",
                            "latitude": "32.38819000",
                            "longitude": "-98.97923000"
                        },
                        {
                            "id": 114035,
                            "name": "Citrus City",
                            "latitude": "26.32646000",
                            "longitude": "-98.38530000"
                        },
                        {
                            "id": 114112,
                            "name": "Clarendon",
                            "latitude": "34.93783000",
                            "longitude": "-100.88820000"
                        },
                        {
                            "id": 114155,
                            "name": "Clarksville",
                            "latitude": "33.61066000",
                            "longitude": "-95.05272000"
                        },
                        {
                            "id": 114162,
                            "name": "Claude",
                            "latitude": "35.11172000",
                            "longitude": "-101.36322000"
                        },
                        {
                            "id": 114185,
                            "name": "Clay County",
                            "latitude": "33.78553000",
                            "longitude": "-98.20850000"
                        },
                        {
                            "id": 114214,
                            "name": "Clear Lake Shores",
                            "latitude": "29.54745000",
                            "longitude": "-95.03215000"
                        },
                        {
                            "id": 114229,
                            "name": "Cleburne",
                            "latitude": "32.34764000",
                            "longitude": "-97.38668000"
                        },
                        {
                            "id": 114246,
                            "name": "Cleveland",
                            "latitude": "30.34132000",
                            "longitude": "-95.08549000"
                        },
                        {
                            "id": 114256,
                            "name": "Clifton",
                            "latitude": "31.78238000",
                            "longitude": "-97.57669000"
                        },
                        {
                            "id": 114266,
                            "name": "Clint",
                            "latitude": "31.59234000",
                            "longitude": "-106.22414000"
                        },
                        {
                            "id": 114312,
                            "name": "Cloverleaf",
                            "latitude": "29.77828000",
                            "longitude": "-95.17188000"
                        },
                        {
                            "id": 114317,
                            "name": "Clute",
                            "latitude": "29.02469000",
                            "longitude": "-95.39883000"
                        },
                        {
                            "id": 114319,
                            "name": "Clyde",
                            "latitude": "32.40596000",
                            "longitude": "-99.49369000"
                        },
                        {
                            "id": 114352,
                            "name": "Cochran County",
                            "latitude": "33.60414000",
                            "longitude": "-102.82846000"
                        },
                        {
                            "id": 114356,
                            "name": "Cockrell Hill",
                            "latitude": "32.73624000",
                            "longitude": "-96.88695000"
                        },
                        {
                            "id": 114377,
                            "name": "Coke County",
                            "latitude": "31.88853000",
                            "longitude": "-100.52991000"
                        },
                        {
                            "id": 114390,
                            "name": "Coldspring",
                            "latitude": "30.59242000",
                            "longitude": "-95.12938000"
                        },
                        {
                            "id": 114398,
                            "name": "Coleman",
                            "latitude": "31.82737000",
                            "longitude": "-99.42645000"
                        },
                        {
                            "id": 114400,
                            "name": "Coleman County",
                            "latitude": "31.77321000",
                            "longitude": "-99.45364000"
                        },
                        {
                            "id": 114417,
                            "name": "College Station",
                            "latitude": "30.62798000",
                            "longitude": "-96.33441000"
                        },
                        {
                            "id": 114422,
                            "name": "Colleyville",
                            "latitude": "32.88096000",
                            "longitude": "-97.15501000"
                        },
                        {
                            "id": 114426,
                            "name": "Collin County",
                            "latitude": "33.18791000",
                            "longitude": "-96.57237000"
                        },
                        {
                            "id": 114430,
                            "name": "Collingsworth County",
                            "latitude": "34.96488000",
                            "longitude": "-100.27007000"
                        },
                        {
                            "id": 114437,
                            "name": "Collinsville",
                            "latitude": "33.56150000",
                            "longitude": "-96.91111000"
                        },
                        {
                            "id": 114456,
                            "name": "Colorado City",
                            "latitude": "32.38817000",
                            "longitude": "-100.86456000"
                        },
                        {
                            "id": 114457,
                            "name": "Colorado County",
                            "latitude": "29.62082000",
                            "longitude": "-96.52628000"
                        },
                        {
                            "id": 114495,
                            "name": "Columbus",
                            "latitude": "29.70662000",
                            "longitude": "-96.53969000"
                        },
                        {
                            "id": 114509,
                            "name": "Comal County",
                            "latitude": "29.80818000",
                            "longitude": "-98.27825000"
                        },
                        {
                            "id": 114511,
                            "name": "Comanche",
                            "latitude": "31.89737000",
                            "longitude": "-98.60366000"
                        },
                        {
                            "id": 114514,
                            "name": "Comanche County",
                            "latitude": "31.94798000",
                            "longitude": "-98.55826000"
                        },
                        {
                            "id": 114516,
                            "name": "Combes",
                            "latitude": "26.24869000",
                            "longitude": "-97.73388000"
                        },
                        {
                            "id": 114517,
                            "name": "Combine",
                            "latitude": "32.58847000",
                            "longitude": "-96.50860000"
                        },
                        {
                            "id": 114520,
                            "name": "Comfort",
                            "latitude": "29.96771000",
                            "longitude": "-98.90503000"
                        },
                        {
                            "id": 114524,
                            "name": "Commerce",
                            "latitude": "33.24706000",
                            "longitude": "-95.89997000"
                        },
                        {
                            "id": 114533,
                            "name": "Concho County",
                            "latitude": "31.32650000",
                            "longitude": "-99.86396000"
                        },
                        {
                            "id": 114570,
                            "name": "Conroe",
                            "latitude": "30.31188000",
                            "longitude": "-95.45605000"
                        },
                        {
                            "id": 114578,
                            "name": "Converse",
                            "latitude": "29.51801000",
                            "longitude": "-98.31612000"
                        },
                        {
                            "id": 114594,
                            "name": "Cooke County",
                            "latitude": "33.63919000",
                            "longitude": "-97.21262000"
                        },
                        {
                            "id": 114601,
                            "name": "Cooper",
                            "latitude": "31.24324000",
                            "longitude": "-95.58217000"
                        },
                        {
                            "id": 114619,
                            "name": "Coppell",
                            "latitude": "32.95457000",
                            "longitude": "-97.01501000"
                        },
                        {
                            "id": 114620,
                            "name": "Copper Canyon",
                            "latitude": "33.09595000",
                            "longitude": "-97.09668000"
                        },
                        {
                            "id": 114621,
                            "name": "Copperas Cove",
                            "latitude": "31.12406000",
                            "longitude": "-97.90308000"
                        },
                        {
                            "id": 114644,
                            "name": "Corinth",
                            "latitude": "33.15401000",
                            "longitude": "-97.06473000"
                        },
                        {
                            "id": 114665,
                            "name": "Corpus Christi",
                            "latitude": "27.80058000",
                            "longitude": "-97.39638000"
                        },
                        {
                            "id": 114668,
                            "name": "Corrigan",
                            "latitude": "30.99686000",
                            "longitude": "-94.82715000"
                        },
                        {
                            "id": 114670,
                            "name": "Corsicana",
                            "latitude": "32.09543000",
                            "longitude": "-96.46887000"
                        },
                        {
                            "id": 114683,
                            "name": "Coryell County",
                            "latitude": "31.39091000",
                            "longitude": "-97.79920000"
                        },
                        {
                            "id": 114695,
                            "name": "Cottle County",
                            "latitude": "34.07765000",
                            "longitude": "-100.27874000"
                        },
                        {
                            "id": 114706,
                            "name": "Cottonwood Shores",
                            "latitude": "30.55575000",
                            "longitude": "-98.32391000"
                        },
                        {
                            "id": 114708,
                            "name": "Cotulla",
                            "latitude": "28.43693000",
                            "longitude": "-99.23503000"
                        },
                        {
                            "id": 114765,
                            "name": "Crandall",
                            "latitude": "32.62791000",
                            "longitude": "-96.45582000"
                        },
                        {
                            "id": 114769,
                            "name": "Crane",
                            "latitude": "31.39736000",
                            "longitude": "-102.35014000"
                        },
                        {
                            "id": 114770,
                            "name": "Crane County",
                            "latitude": "31.42850000",
                            "longitude": "-102.51557000"
                        },
                        {
                            "id": 114829,
                            "name": "Crockett",
                            "latitude": "31.31824000",
                            "longitude": "-95.45661000"
                        },
                        {
                            "id": 114832,
                            "name": "Crockett County",
                            "latitude": "30.72298000",
                            "longitude": "-101.41215000"
                        },
                        {
                            "id": 114842,
                            "name": "Crosby",
                            "latitude": "29.91189000",
                            "longitude": "-95.06215000"
                        },
                        {
                            "id": 114845,
                            "name": "Crosby County",
                            "latitude": "33.61462000",
                            "longitude": "-101.29996000"
                        },
                        {
                            "id": 114846,
                            "name": "Crosbyton",
                            "latitude": "33.66008000",
                            "longitude": "-101.23793000"
                        },
                        {
                            "id": 114851,
                            "name": "Cross Mountain",
                            "latitude": "29.64550000",
                            "longitude": "-98.65947000"
                        },
                        {
                            "id": 114862,
                            "name": "Crowell",
                            "latitude": "33.98397000",
                            "longitude": "-99.72482000"
                        },
                        {
                            "id": 114864,
                            "name": "Crowley",
                            "latitude": "32.57903000",
                            "longitude": "-97.36252000"
                        },
                        {
                            "id": 114876,
                            "name": "Crystal City",
                            "latitude": "28.67748000",
                            "longitude": "-99.82811000"
                        },
                        {
                            "id": 114892,
                            "name": "Cuero",
                            "latitude": "29.09387000",
                            "longitude": "-97.28916000"
                        },
                        {
                            "id": 114893,
                            "name": "Culberson County",
                            "latitude": "31.44716000",
                            "longitude": "-104.51742000"
                        },
                        {
                            "id": 114943,
                            "name": "Cut and Shoot",
                            "latitude": "30.33327000",
                            "longitude": "-95.35799000"
                        },
                        {
                            "id": 114953,
                            "name": "Cypress",
                            "latitude": "29.96911000",
                            "longitude": "-95.69717000"
                        },
                        {
                            "id": 114972,
                            "name": "Daingerfield",
                            "latitude": "33.03179000",
                            "longitude": "-94.72187000"
                        },
                        {
                            "id": 114986,
                            "name": "Dalhart",
                            "latitude": "36.05948000",
                            "longitude": "-102.51325000"
                        },
                        {
                            "id": 114987,
                            "name": "Dallam County",
                            "latitude": "36.27789000",
                            "longitude": "-102.60222000"
                        },
                        {
                            "id": 114990,
                            "name": "Dallas",
                            "latitude": "32.78306000",
                            "longitude": "-96.80667000"
                        },
                        {
                            "id": 114997,
                            "name": "Dallas County",
                            "latitude": "32.76663000",
                            "longitude": "-96.77787000"
                        },
                        {
                            "id": 115005,
                            "name": "Dalworthington Gardens",
                            "latitude": "32.70291000",
                            "longitude": "-97.15529000"
                        },
                        {
                            "id": 115014,
                            "name": "Danbury",
                            "latitude": "29.22830000",
                            "longitude": "-95.34494000"
                        },
                        {
                            "id": 115085,
                            "name": "Dawson County",
                            "latitude": "32.74253000",
                            "longitude": "-101.94768000"
                        },
                        {
                            "id": 115093,
                            "name": "Dayton",
                            "latitude": "30.04661000",
                            "longitude": "-94.88520000"
                        },
                        {
                            "id": 115107,
                            "name": "De Kalb",
                            "latitude": "33.50873000",
                            "longitude": "-94.61632000"
                        },
                        {
                            "id": 115109,
                            "name": "De Leon",
                            "latitude": "32.11097000",
                            "longitude": "-98.53588000"
                        },
                        {
                            "id": 115143,
                            "name": "Deaf Smith County",
                            "latitude": "34.96602000",
                            "longitude": "-102.60494000"
                        },
                        {
                            "id": 115155,
                            "name": "Decatur",
                            "latitude": "33.23428000",
                            "longitude": "-97.58614000"
                        },
                        {
                            "id": 115125,
                            "name": "DeCordova",
                            "latitude": "32.42986000",
                            "longitude": "-97.69503000"
                        },
                        {
                            "id": 115175,
                            "name": "Deer Park",
                            "latitude": "29.70523000",
                            "longitude": "-95.12382000"
                        },
                        {
                            "id": 115195,
                            "name": "Del Rio",
                            "latitude": "29.36273000",
                            "longitude": "-100.89676000"
                        },
                        {
                            "id": 115224,
                            "name": "Delta County",
                            "latitude": "33.38629000",
                            "longitude": "-95.67227000"
                        },
                        {
                            "id": 115237,
                            "name": "Denison",
                            "latitude": "33.75566000",
                            "longitude": "-96.53666000"
                        },
                        {
                            "id": 115247,
                            "name": "Denton",
                            "latitude": "33.21484000",
                            "longitude": "-97.13307000"
                        },
                        {
                            "id": 115248,
                            "name": "Denton County",
                            "latitude": "33.20526000",
                            "longitude": "-97.11697000"
                        },
                        {
                            "id": 115254,
                            "name": "Denver City",
                            "latitude": "32.96455000",
                            "longitude": "-102.82910000"
                        },
                        {
                            "id": 115138,
                            "name": "DeSoto",
                            "latitude": "32.58986000",
                            "longitude": "-96.85695000"
                        },
                        {
                            "id": 115299,
                            "name": "Devine",
                            "latitude": "29.13996000",
                            "longitude": "-98.90531000"
                        },
                        {
                            "id": 115306,
                            "name": "Deweyville",
                            "latitude": "30.29771000",
                            "longitude": "-93.74350000"
                        },
                        {
                            "id": 115141,
                            "name": "DeWitt County",
                            "latitude": "29.08208000",
                            "longitude": "-97.35678000"
                        },
                        {
                            "id": 115319,
                            "name": "Diboll",
                            "latitude": "31.18713000",
                            "longitude": "-94.78104000"
                        },
                        {
                            "id": 115320,
                            "name": "Dickens",
                            "latitude": "33.62175000",
                            "longitude": "-100.83652000"
                        },
                        {
                            "id": 115321,
                            "name": "Dickens County",
                            "latitude": "33.61661000",
                            "longitude": "-100.77886000"
                        },
                        {
                            "id": 115325,
                            "name": "Dickinson",
                            "latitude": "29.46079000",
                            "longitude": "-95.05132000"
                        },
                        {
                            "id": 115339,
                            "name": "Dilley",
                            "latitude": "28.66748000",
                            "longitude": "-99.17059000"
                        },
                        {
                            "id": 115348,
                            "name": "Dimmit County",
                            "latitude": "28.42254000",
                            "longitude": "-99.75673000"
                        },
                        {
                            "id": 115349,
                            "name": "Dimmitt",
                            "latitude": "34.55090000",
                            "longitude": "-102.31186000"
                        },
                        {
                            "id": 115384,
                            "name": "Doffing",
                            "latitude": "26.27452000",
                            "longitude": "-98.38585000"
                        },
                        {
                            "id": 115399,
                            "name": "Donley County",
                            "latitude": "34.96542000",
                            "longitude": "-100.81399000"
                        },
                        {
                            "id": 115400,
                            "name": "Donna",
                            "latitude": "26.17035000",
                            "longitude": "-98.05195000"
                        },
                        {
                            "id": 115403,
                            "name": "Doolittle",
                            "latitude": "26.35337000",
                            "longitude": "-98.11666000"
                        },
                        {
                            "id": 115416,
                            "name": "Double Oak",
                            "latitude": "33.06512000",
                            "longitude": "-97.11057000"
                        },
                        {
                            "id": 115479,
                            "name": "Dripping Springs",
                            "latitude": "30.19021000",
                            "longitude": "-98.08668000"
                        },
                        {
                            "id": 115492,
                            "name": "Dublin",
                            "latitude": "32.08514000",
                            "longitude": "-98.34199000"
                        },
                        {
                            "id": 115515,
                            "name": "Dumas",
                            "latitude": "35.86559000",
                            "longitude": "-101.97324000"
                        },
                        {
                            "id": 115525,
                            "name": "Duncanville",
                            "latitude": "32.65180000",
                            "longitude": "-96.90834000"
                        },
                        {
                            "id": 115581,
                            "name": "Duval County",
                            "latitude": "27.68133000",
                            "longitude": "-98.50891000"
                        },
                        {
                            "id": 115603,
                            "name": "Eagle Lake",
                            "latitude": "29.58968000",
                            "longitude": "-96.33358000"
                        },
                        {
                            "id": 115606,
                            "name": "Eagle Mountain",
                            "latitude": "32.89346000",
                            "longitude": "-97.44446000"
                        },
                        {
                            "id": 115608,
                            "name": "Eagle Pass",
                            "latitude": "28.70914000",
                            "longitude": "-100.49952000"
                        },
                        {
                            "id": 115622,
                            "name": "Early",
                            "latitude": "31.74210000",
                            "longitude": "-98.94561000"
                        },
                        {
                            "id": 115624,
                            "name": "Earth",
                            "latitude": "34.23314000",
                            "longitude": "-102.41075000"
                        },
                        {
                            "id": 115632,
                            "name": "East Bernard",
                            "latitude": "29.53107000",
                            "longitude": "-96.07107000"
                        },
                        {
                            "id": 115780,
                            "name": "Eastland",
                            "latitude": "32.40152000",
                            "longitude": "-98.81756000"
                        },
                        {
                            "id": 115781,
                            "name": "Eastland County",
                            "latitude": "32.32707000",
                            "longitude": "-98.83232000"
                        },
                        {
                            "id": 115819,
                            "name": "Ector County",
                            "latitude": "31.86916000",
                            "longitude": "-102.54276000"
                        },
                        {
                            "id": 115820,
                            "name": "Edcouch",
                            "latitude": "26.29396000",
                            "longitude": "-97.96056000"
                        },
                        {
                            "id": 115829,
                            "name": "Eden",
                            "latitude": "31.21628000",
                            "longitude": "-99.84563000"
                        },
                        {
                            "id": 115839,
                            "name": "Edgecliff Village",
                            "latitude": "32.65763000",
                            "longitude": "-97.34279000"
                        },
                        {
                            "id": 115860,
                            "name": "Edgewood",
                            "latitude": "32.69818000",
                            "longitude": "-95.88524000"
                        },
                        {
                            "id": 115870,
                            "name": "Edinburg",
                            "latitude": "26.30174000",
                            "longitude": "-98.16334000"
                        },
                        {
                            "id": 115884,
                            "name": "Edna",
                            "latitude": "28.97859000",
                            "longitude": "-96.64609000"
                        },
                        {
                            "id": 115891,
                            "name": "Edwards County",
                            "latitude": "29.98263000",
                            "longitude": "-100.30474000"
                        },
                        {
                            "id": 115908,
                            "name": "Eidson Road",
                            "latitude": "28.67720000",
                            "longitude": "-100.48702000"
                        },
                        {
                            "id": 115912,
                            "name": "El Campo",
                            "latitude": "29.19664000",
                            "longitude": "-96.26969000"
                        },
                        {
                            "id": 115913,
                            "name": "El Cenizo",
                            "latitude": "27.35224000",
                            "longitude": "-99.49254000"
                        },
                        {
                            "id": 115926,
                            "name": "El Lago",
                            "latitude": "29.56356000",
                            "longitude": "-95.04521000"
                        },
                        {
                            "id": 115930,
                            "name": "El Paso",
                            "latitude": "31.75872000",
                            "longitude": "-106.48693000"
                        },
                        {
                            "id": 115932,
                            "name": "El Paso County",
                            "latitude": "31.76855000",
                            "longitude": "-106.23483000"
                        },
                        {
                            "id": 115954,
                            "name": "Eldorado",
                            "latitude": "30.86017000",
                            "longitude": "-100.60093000"
                        },
                        {
                            "id": 115959,
                            "name": "Electra",
                            "latitude": "34.02926000",
                            "longitude": "-98.91896000"
                        },
                        {
                            "id": 115965,
                            "name": "Elgin",
                            "latitude": "30.34965000",
                            "longitude": "-97.37027000"
                        },
                        {
                            "id": 115997,
                            "name": "Elkhart",
                            "latitude": "31.62517000",
                            "longitude": "-95.57940000"
                        },
                        {
                            "id": 116032,
                            "name": "Ellis County",
                            "latitude": "32.34843000",
                            "longitude": "-96.79448000"
                        },
                        {
                            "id": 116043,
                            "name": "Elm Creek",
                            "latitude": "28.77442000",
                            "longitude": "-100.49174000"
                        },
                        {
                            "id": 116048,
                            "name": "Elmendorf",
                            "latitude": "29.25607000",
                            "longitude": "-98.33279000"
                        },
                        {
                            "id": 116068,
                            "name": "Elsa",
                            "latitude": "26.29340000",
                            "longitude": "-97.99306000"
                        },
                        {
                            "id": 116108,
                            "name": "Emory",
                            "latitude": "32.87457000",
                            "longitude": "-95.76552000"
                        },
                        {
                            "id": 116115,
                            "name": "Encantada-Ranchito-El Calaboz",
                            "latitude": "26.03344000",
                            "longitude": "-97.63307000"
                        },
                        {
                            "id": 116141,
                            "name": "Ennis",
                            "latitude": "32.32931000",
                            "longitude": "-96.62527000"
                        },
                        {
                            "id": 116161,
                            "name": "Erath County",
                            "latitude": "32.23626000",
                            "longitude": "-98.21797000"
                        },
                        {
                            "id": 116183,
                            "name": "Escobares",
                            "latitude": "26.41062000",
                            "longitude": "-98.96253000"
                        },
                        {
                            "id": 116228,
                            "name": "Euless",
                            "latitude": "32.83707000",
                            "longitude": "-97.08195000"
                        },
                        {
                            "id": 116243,
                            "name": "Evadale",
                            "latitude": "30.35493000",
                            "longitude": "-94.07268000"
                        },
                        {
                            "id": 116266,
                            "name": "Everman",
                            "latitude": "32.63097000",
                            "longitude": "-97.28918000"
                        },
                        {
                            "id": 116279,
                            "name": "Fabens",
                            "latitude": "31.50234000",
                            "longitude": "-106.15859000"
                        },
                        {
                            "id": 116287,
                            "name": "Fair Oaks Ranch",
                            "latitude": "29.74578000",
                            "longitude": "-98.64336000"
                        },
                        {
                            "id": 116298,
                            "name": "Fairchilds",
                            "latitude": "29.43135000",
                            "longitude": "-95.78023000"
                        },
                        {
                            "id": 116311,
                            "name": "Fairfield",
                            "latitude": "31.72461000",
                            "longitude": "-96.16525000"
                        },
                        {
                            "id": 116351,
                            "name": "Fairview",
                            "latitude": "33.15790000",
                            "longitude": "-96.63166000"
                        },
                        {
                            "id": 116366,
                            "name": "Falcon Lake Estates",
                            "latitude": "26.87282000",
                            "longitude": "-99.25531000"
                        },
                        {
                            "id": 116368,
                            "name": "Falfurrias",
                            "latitude": "27.22699000",
                            "longitude": "-98.14417000"
                        },
                        {
                            "id": 116382,
                            "name": "Falls County",
                            "latitude": "31.25327000",
                            "longitude": "-96.93585000"
                        },
                        {
                            "id": 116391,
                            "name": "Fannett",
                            "latitude": "29.92605000",
                            "longitude": "-94.25074000"
                        },
                        {
                            "id": 116393,
                            "name": "Fannin County",
                            "latitude": "33.59381000",
                            "longitude": "-96.10683000"
                        },
                        {
                            "id": 116403,
                            "name": "Farmers Branch",
                            "latitude": "32.92651000",
                            "longitude": "-96.89612000"
                        },
                        {
                            "id": 116406,
                            "name": "Farmersville",
                            "latitude": "33.16345000",
                            "longitude": "-96.35998000"
                        },
                        {
                            "id": 116431,
                            "name": "Farwell",
                            "latitude": "34.38341000",
                            "longitude": "-103.03800000"
                        },
                        {
                            "id": 116432,
                            "name": "Fate",
                            "latitude": "32.94151000",
                            "longitude": "-96.38137000"
                        },
                        {
                            "id": 116449,
                            "name": "Fayette County",
                            "latitude": "29.87679000",
                            "longitude": "-96.91976000"
                        },
                        {
                            "id": 116494,
                            "name": "Ferris",
                            "latitude": "32.53403000",
                            "longitude": "-96.66555000"
                        },
                        {
                            "id": 116506,
                            "name": "Fifth Street",
                            "latitude": "29.59829000",
                            "longitude": "-95.55133000"
                        },
                        {
                            "id": 116528,
                            "name": "Fisher County",
                            "latitude": "32.74282000",
                            "longitude": "-100.40217000"
                        },
                        {
                            "id": 116556,
                            "name": "Flatonia",
                            "latitude": "29.68773000",
                            "longitude": "-97.10860000"
                        },
                        {
                            "id": 116581,
                            "name": "Florence",
                            "latitude": "30.84130000",
                            "longitude": "-97.79363000"
                        },
                        {
                            "id": 116590,
                            "name": "Floresville",
                            "latitude": "29.13358000",
                            "longitude": "-98.15612000"
                        },
                        {
                            "id": 116601,
                            "name": "Flower Mound",
                            "latitude": "33.01457000",
                            "longitude": "-97.09696000"
                        },
                        {
                            "id": 116611,
                            "name": "Floyd County",
                            "latitude": "34.07242000",
                            "longitude": "-101.30323000"
                        },
                        {
                            "id": 116612,
                            "name": "Floydada",
                            "latitude": "33.98452000",
                            "longitude": "-101.33766000"
                        },
                        {
                            "id": 116616,
                            "name": "Foard County",
                            "latitude": "33.97462000",
                            "longitude": "-99.77798000"
                        },
                        {
                            "id": 116655,
                            "name": "Forest Hill",
                            "latitude": "32.67208000",
                            "longitude": "-97.26918000"
                        },
                        {
                            "id": 116677,
                            "name": "Forney",
                            "latitude": "32.74818000",
                            "longitude": "-96.47193000"
                        },
                        {
                            "id": 116692,
                            "name": "Fort Bend County",
                            "latitude": "29.52749000",
                            "longitude": "-95.77089000"
                        },
                        {
                            "id": 116694,
                            "name": "Fort Bliss",
                            "latitude": "31.81357000",
                            "longitude": "-106.41224000"
                        },
                        {
                            "id": 116700,
                            "name": "Fort Clark Springs",
                            "latitude": "29.30607000",
                            "longitude": "-100.42202000"
                        },
                        {
                            "id": 116703,
                            "name": "Fort Davis",
                            "latitude": "30.58821000",
                            "longitude": "-103.89463000"
                        },
                        {
                            "id": 116716,
                            "name": "Fort Hancock",
                            "latitude": "31.29846000",
                            "longitude": "-105.84525000"
                        },
                        {
                            "id": 116717,
                            "name": "Fort Hood",
                            "latitude": "31.13489000",
                            "longitude": "-97.77561000"
                        },
                        {
                            "id": 116752,
                            "name": "Fort Stockton",
                            "latitude": "30.89404000",
                            "longitude": "-102.87932000"
                        },
                        {
                            "id": 116764,
                            "name": "Fort Worth",
                            "latitude": "32.72541000",
                            "longitude": "-97.32085000"
                        },
                        {
                            "id": 116788,
                            "name": "Four Corners",
                            "latitude": "29.66857000",
                            "longitude": "-95.65772000"
                        },
                        {
                            "id": 116830,
                            "name": "Franklin",
                            "latitude": "31.02602000",
                            "longitude": "-96.48524000"
                        },
                        {
                            "id": 116855,
                            "name": "Franklin County",
                            "latitude": "33.17555000",
                            "longitude": "-95.21842000"
                        },
                        {
                            "id": 116877,
                            "name": "Frankston",
                            "latitude": "32.05266000",
                            "longitude": "-95.50635000"
                        },
                        {
                            "id": 116890,
                            "name": "Fredericksburg",
                            "latitude": "30.27520000",
                            "longitude": "-98.87198000"
                        },
                        {
                            "id": 116911,
                            "name": "Freeport",
                            "latitude": "28.95414000",
                            "longitude": "-95.35966000"
                        },
                        {
                            "id": 116916,
                            "name": "Freer",
                            "latitude": "27.88280000",
                            "longitude": "-98.61779000"
                        },
                        {
                            "id": 116917,
                            "name": "Freestone County",
                            "latitude": "31.70489000",
                            "longitude": "-96.14909000"
                        },
                        {
                            "id": 116938,
                            "name": "Fresno",
                            "latitude": "29.53885000",
                            "longitude": "-95.44744000"
                        },
                        {
                            "id": 116952,
                            "name": "Friendswood",
                            "latitude": "29.52940000",
                            "longitude": "-95.20104000"
                        },
                        {
                            "id": 116953,
                            "name": "Frio County",
                            "latitude": "28.86782000",
                            "longitude": "-99.10827000"
                        },
                        {
                            "id": 116954,
                            "name": "Friona",
                            "latitude": "34.64173000",
                            "longitude": "-102.72410000"
                        },
                        {
                            "id": 116955,
                            "name": "Frisco",
                            "latitude": "33.15067000",
                            "longitude": "-96.82361000"
                        },
                        {
                            "id": 116958,
                            "name": "Fritch",
                            "latitude": "35.63977000",
                            "longitude": "-101.60323000"
                        },
                        {
                            "id": 116984,
                            "name": "Fulshear",
                            "latitude": "29.68996000",
                            "longitude": "-95.89968000"
                        },
                        {
                            "id": 116989,
                            "name": "Fulton",
                            "latitude": "28.06140000",
                            "longitude": "-97.04110000"
                        },
                        {
                            "id": 117011,
                            "name": "Gail",
                            "latitude": "32.77038000",
                            "longitude": "-101.44541000"
                        },
                        {
                            "id": 117012,
                            "name": "Gaines County",
                            "latitude": "32.74073000",
                            "longitude": "-102.63518000"
                        },
                        {
                            "id": 117017,
                            "name": "Gainesville",
                            "latitude": "33.62594000",
                            "longitude": "-97.13335000"
                        },
                        {
                            "id": 117025,
                            "name": "Galena Park",
                            "latitude": "29.73356000",
                            "longitude": "-95.23021000"
                        },
                        {
                            "id": 117042,
                            "name": "Galveston",
                            "latitude": "29.30135000",
                            "longitude": "-94.79770000"
                        },
                        {
                            "id": 117044,
                            "name": "Galveston County",
                            "latitude": "29.29767000",
                            "longitude": "-94.81087000"
                        },
                        {
                            "id": 117047,
                            "name": "Ganado",
                            "latitude": "29.04054000",
                            "longitude": "-96.51358000"
                        },
                        {
                            "id": 117060,
                            "name": "Garden City",
                            "latitude": "31.86402000",
                            "longitude": "-101.48123000"
                        },
                        {
                            "id": 117067,
                            "name": "Garden Ridge",
                            "latitude": "29.63467000",
                            "longitude": "-98.30529000"
                        },
                        {
                            "id": 117071,
                            "name": "Gardendale",
                            "latitude": "32.02040000",
                            "longitude": "-102.38015000"
                        },
                        {
                            "id": 117080,
                            "name": "Garfield",
                            "latitude": "30.18744000",
                            "longitude": "-97.55778000"
                        },
                        {
                            "id": 117088,
                            "name": "Garland",
                            "latitude": "32.91262000",
                            "longitude": "-96.63888000"
                        },
                        {
                            "id": 117108,
                            "name": "Garza County",
                            "latitude": "33.17988000",
                            "longitude": "-101.29842000"
                        },
                        {
                            "id": 117122,
                            "name": "Gatesville",
                            "latitude": "31.43516000",
                            "longitude": "-97.74391000"
                        },
                        {
                            "id": 117154,
                            "name": "George West",
                            "latitude": "28.33250000",
                            "longitude": "-98.11751000"
                        },
                        {
                            "id": 117160,
                            "name": "Georgetown",
                            "latitude": "30.63269000",
                            "longitude": "-97.67723000"
                        },
                        {
                            "id": 117177,
                            "name": "Geronimo",
                            "latitude": "29.66300000",
                            "longitude": "-97.96695000"
                        },
                        {
                            "id": 117181,
                            "name": "Gholson",
                            "latitude": "31.70100000",
                            "longitude": "-97.21640000"
                        },
                        {
                            "id": 117195,
                            "name": "Giddings",
                            "latitude": "30.18272000",
                            "longitude": "-96.93637000"
                        },
                        {
                            "id": 117215,
                            "name": "Gillespie County",
                            "latitude": "30.31806000",
                            "longitude": "-98.94648000"
                        },
                        {
                            "id": 117221,
                            "name": "Gilmer",
                            "latitude": "32.72875000",
                            "longitude": "-94.94244000"
                        },
                        {
                            "id": 117235,
                            "name": "Gladewater",
                            "latitude": "32.53653000",
                            "longitude": "-94.94272000"
                        },
                        {
                            "id": 117252,
                            "name": "Glasscock County",
                            "latitude": "31.86946000",
                            "longitude": "-101.52080000"
                        },
                        {
                            "id": 117274,
                            "name": "Glen Rose",
                            "latitude": "32.23459000",
                            "longitude": "-97.75531000"
                        },
                        {
                            "id": 117298,
                            "name": "Glenn Heights",
                            "latitude": "32.54875000",
                            "longitude": "-96.85667000"
                        },
                        {
                            "id": 117335,
                            "name": "Godley",
                            "latitude": "32.44903000",
                            "longitude": "-97.52669000"
                        },
                        {
                            "id": 117366,
                            "name": "Goldthwaite",
                            "latitude": "31.44989000",
                            "longitude": "-98.57088000"
                        },
                        {
                            "id": 117368,
                            "name": "Goliad",
                            "latitude": "28.66833000",
                            "longitude": "-97.38833000"
                        },
                        {
                            "id": 117369,
                            "name": "Goliad County",
                            "latitude": "28.65710000",
                            "longitude": "-97.42649000"
                        },
                        {
                            "id": 117371,
                            "name": "Gonzales",
                            "latitude": "29.50163000",
                            "longitude": "-97.45249000"
                        },
                        {
                            "id": 117373,
                            "name": "Gonzales County",
                            "latitude": "29.45673000",
                            "longitude": "-97.49252000"
                        },
                        {
                            "id": 117406,
                            "name": "Gorman",
                            "latitude": "32.21375000",
                            "longitude": "-98.67061000"
                        },
                        {
                            "id": 117435,
                            "name": "Graham",
                            "latitude": "33.10706000",
                            "longitude": "-98.58950000"
                        },
                        {
                            "id": 117445,
                            "name": "Granbury",
                            "latitude": "32.44208000",
                            "longitude": "-97.79420000"
                        },
                        {
                            "id": 117471,
                            "name": "Grand Prairie",
                            "latitude": "32.74596000",
                            "longitude": "-96.99778000"
                        },
                        {
                            "id": 117475,
                            "name": "Grand Saline",
                            "latitude": "32.67346000",
                            "longitude": "-95.70941000"
                        },
                        {
                            "id": 117480,
                            "name": "Grandview",
                            "latitude": "32.27042000",
                            "longitude": "-97.17918000"
                        },
                        {
                            "id": 117486,
                            "name": "Granger",
                            "latitude": "30.71769000",
                            "longitude": "-97.44278000"
                        },
                        {
                            "id": 117501,
                            "name": "Granite Shoals",
                            "latitude": "30.58908000",
                            "longitude": "-98.38392000"
                        },
                        {
                            "id": 117538,
                            "name": "Grape Creek",
                            "latitude": "31.57932000",
                            "longitude": "-100.54760000"
                        },
                        {
                            "id": 117539,
                            "name": "Grapeland",
                            "latitude": "31.49185000",
                            "longitude": "-95.47856000"
                        },
                        {
                            "id": 117540,
                            "name": "Grapevine",
                            "latitude": "32.93429000",
                            "longitude": "-97.07807000"
                        },
                        {
                            "id": 117554,
                            "name": "Gray County",
                            "latitude": "35.40116000",
                            "longitude": "-100.81256000"
                        },
                        {
                            "id": 117563,
                            "name": "Grayson County",
                            "latitude": "33.62681000",
                            "longitude": "-96.67772000"
                        },
                        {
                            "id": 117583,
                            "name": "Greatwood",
                            "latitude": "29.55413000",
                            "longitude": "-95.67578000"
                        },
                        {
                            "id": 117612,
                            "name": "Green Valley Farms",
                            "latitude": "26.12202000",
                            "longitude": "-97.56054000"
                        },
                        {
                            "id": 117689,
                            "name": "Greenville",
                            "latitude": "33.13845000",
                            "longitude": "-96.11081000"
                        },
                        {
                            "id": 117715,
                            "name": "Gregg County",
                            "latitude": "32.48047000",
                            "longitude": "-94.81695000"
                        },
                        {
                            "id": 117716,
                            "name": "Gregory",
                            "latitude": "27.92224000",
                            "longitude": "-97.28999000"
                        },
                        {
                            "id": 117738,
                            "name": "Grimes County",
                            "latitude": "30.54347000",
                            "longitude": "-95.98550000"
                        },
                        {
                            "id": 117742,
                            "name": "Groesbeck",
                            "latitude": "31.52434000",
                            "longitude": "-96.53387000"
                        },
                        {
                            "id": 117761,
                            "name": "Groves",
                            "latitude": "29.94827000",
                            "longitude": "-93.91712000"
                        },
                        {
                            "id": 117762,
                            "name": "Groveton",
                            "latitude": "31.05491000",
                            "longitude": "-95.12577000"
                        },
                        {
                            "id": 117774,
                            "name": "Gruver",
                            "latitude": "36.26503000",
                            "longitude": "-101.40627000"
                        },
                        {
                            "id": 117778,
                            "name": "Guadalupe County",
                            "latitude": "29.58305000",
                            "longitude": "-97.94858000"
                        },
                        {
                            "id": 117800,
                            "name": "Gun Barrel City",
                            "latitude": "32.33459000",
                            "longitude": "-96.15136000"
                        },
                        {
                            "id": 117805,
                            "name": "Gunter",
                            "latitude": "33.44789000",
                            "longitude": "-96.74749000"
                        },
                        {
                            "id": 117813,
                            "name": "Guthrie",
                            "latitude": "33.62064000",
                            "longitude": "-100.32289000"
                        },
                        {
                            "id": 117827,
                            "name": "Hackberry",
                            "latitude": "33.15234000",
                            "longitude": "-96.91778000"
                        },
                        {
                            "id": 117847,
                            "name": "Hale Center",
                            "latitude": "34.06424000",
                            "longitude": "-101.84379000"
                        },
                        {
                            "id": 117849,
                            "name": "Hale County",
                            "latitude": "34.07051000",
                            "longitude": "-101.82688000"
                        },
                        {
                            "id": 117866,
                            "name": "Hall County",
                            "latitude": "34.53078000",
                            "longitude": "-100.68113000"
                        },
                        {
                            "id": 117870,
                            "name": "Hallettsville",
                            "latitude": "29.44385000",
                            "longitude": "-96.94109000"
                        },
                        {
                            "id": 117876,
                            "name": "Hallsville",
                            "latitude": "32.50432000",
                            "longitude": "-94.57409000"
                        },
                        {
                            "id": 117878,
                            "name": "Haltom City",
                            "latitude": "32.79957000",
                            "longitude": "-97.26918000"
                        },
                        {
                            "id": 117890,
                            "name": "Hamilton",
                            "latitude": "31.70377000",
                            "longitude": "-98.12392000"
                        },
                        {
                            "id": 117899,
                            "name": "Hamilton County",
                            "latitude": "31.70480000",
                            "longitude": "-98.11073000"
                        },
                        {
                            "id": 117910,
                            "name": "Hamlin",
                            "latitude": "32.88483000",
                            "longitude": "-100.12649000"
                        },
                        {
                            "id": 117977,
                            "name": "Hansford County",
                            "latitude": "36.27745000",
                            "longitude": "-101.35454000"
                        },
                        {
                            "id": 117996,
                            "name": "Hardeman County",
                            "latitude": "34.29029000",
                            "longitude": "-99.74572000"
                        },
                        {
                            "id": 118002,
                            "name": "Hardin County",
                            "latitude": "30.33237000",
                            "longitude": "-94.39022000"
                        },
                        {
                            "id": 118013,
                            "name": "Harker Heights",
                            "latitude": "31.08351000",
                            "longitude": "-97.65974000"
                        },
                        {
                            "id": 118026,
                            "name": "Harlingen",
                            "latitude": "26.19063000",
                            "longitude": "-97.69610000"
                        },
                        {
                            "id": 118032,
                            "name": "Harper",
                            "latitude": "30.29992000",
                            "longitude": "-99.24421000"
                        },
                        {
                            "id": 118045,
                            "name": "Harris County",
                            "latitude": "29.85728000",
                            "longitude": "-95.39234000"
                        },
                        {
                            "id": 118065,
                            "name": "Harrison County",
                            "latitude": "32.54813000",
                            "longitude": "-94.37149000"
                        },
                        {
                            "id": 118081,
                            "name": "Hart",
                            "latitude": "34.38507000",
                            "longitude": "-102.11574000"
                        },
                        {
                            "id": 118100,
                            "name": "Hartley County",
                            "latitude": "35.84002000",
                            "longitude": "-102.60289000"
                        },
                        {
                            "id": 118124,
                            "name": "Haskell",
                            "latitude": "33.15760000",
                            "longitude": "-99.73370000"
                        },
                        {
                            "id": 118126,
                            "name": "Haskell County",
                            "latitude": "33.17823000",
                            "longitude": "-99.73030000"
                        },
                        {
                            "id": 118128,
                            "name": "Haslet",
                            "latitude": "32.97485000",
                            "longitude": "-97.34780000"
                        },
                        {
                            "id": 118165,
                            "name": "Hawkins",
                            "latitude": "32.58847000",
                            "longitude": "-95.20411000"
                        },
                        {
                            "id": 118192,
                            "name": "Hays County",
                            "latitude": "30.05815000",
                            "longitude": "-98.03106000"
                        },
                        {
                            "id": 118221,
                            "name": "Hearne",
                            "latitude": "30.87852000",
                            "longitude": "-96.59303000"
                        },
                        {
                            "id": 118222,
                            "name": "Heath",
                            "latitude": "32.83651000",
                            "longitude": "-96.47499000"
                        },
                        {
                            "id": 118227,
                            "name": "Hebbronville",
                            "latitude": "27.30688000",
                            "longitude": "-98.68032000"
                        },
                        {
                            "id": 118242,
                            "name": "Hedwig Village",
                            "latitude": "29.77745000",
                            "longitude": "-95.51716000"
                        },
                        {
                            "id": 118244,
                            "name": "Heidelberg",
                            "latitude": "26.19702000",
                            "longitude": "-97.88028000"
                        },
                        {
                            "id": 118261,
                            "name": "Helotes",
                            "latitude": "29.57801000",
                            "longitude": "-98.68975000"
                        },
                        {
                            "id": 118267,
                            "name": "Hemphill",
                            "latitude": "31.34074000",
                            "longitude": "-93.84685000"
                        },
                        {
                            "id": 118268,
                            "name": "Hemphill County",
                            "latitude": "35.83757000",
                            "longitude": "-100.27047000"
                        },
                        {
                            "id": 118269,
                            "name": "Hempstead",
                            "latitude": "30.09744000",
                            "longitude": "-96.07829000"
                        },
                        {
                            "id": 118273,
                            "name": "Henderson",
                            "latitude": "32.15322000",
                            "longitude": "-94.79938000"
                        },
                        {
                            "id": 118283,
                            "name": "Henderson County",
                            "latitude": "32.21189000",
                            "longitude": "-95.85356000"
                        },
                        {
                            "id": 118295,
                            "name": "Henrietta",
                            "latitude": "33.81732000",
                            "longitude": "-98.19532000"
                        },
                        {
                            "id": 118315,
                            "name": "Hereford",
                            "latitude": "34.81521000",
                            "longitude": "-102.39932000"
                        },
                        {
                            "id": 118348,
                            "name": "Hewitt",
                            "latitude": "31.46239000",
                            "longitude": "-97.19584000"
                        },
                        {
                            "id": 118367,
                            "name": "Hickory Creek",
                            "latitude": "33.12234000",
                            "longitude": "-97.04306000"
                        },
                        {
                            "id": 118372,
                            "name": "Hico",
                            "latitude": "31.98293000",
                            "longitude": "-98.03365000"
                        },
                        {
                            "id": 118373,
                            "name": "Hidalgo",
                            "latitude": "26.10035000",
                            "longitude": "-98.26307000"
                        },
                        {
                            "id": 118374,
                            "name": "Hidalgo County",
                            "latitude": "26.39672000",
                            "longitude": "-98.18107000"
                        },
                        {
                            "id": 118382,
                            "name": "Hideaway",
                            "latitude": "32.49042000",
                            "longitude": "-95.45746000"
                        },
                        {
                            "id": 118410,
                            "name": "Highland Park",
                            "latitude": "32.83346000",
                            "longitude": "-96.79195000"
                        },
                        {
                            "id": 118416,
                            "name": "Highland Village",
                            "latitude": "33.09179000",
                            "longitude": "-97.04668000"
                        },
                        {
                            "id": 118417,
                            "name": "Highlands",
                            "latitude": "29.81884000",
                            "longitude": "-95.05604000"
                        },
                        {
                            "id": 118435,
                            "name": "Hill Country Village",
                            "latitude": "29.58245000",
                            "longitude": "-98.49085000"
                        },
                        {
                            "id": 118436,
                            "name": "Hill County",
                            "latitude": "31.99068000",
                            "longitude": "-97.13243000"
                        },
                        {
                            "id": 118449,
                            "name": "Hillsboro",
                            "latitude": "32.01099000",
                            "longitude": "-97.13001000"
                        },
                        {
                            "id": 118469,
                            "name": "Hilltop Lakes",
                            "latitude": "31.07935000",
                            "longitude": "-96.20385000"
                        },
                        {
                            "id": 118492,
                            "name": "Hitchcock",
                            "latitude": "29.34829000",
                            "longitude": "-95.01604000"
                        },
                        {
                            "id": 118505,
                            "name": "Hockley County",
                            "latitude": "33.60764000",
                            "longitude": "-102.34320000"
                        },
                        {
                            "id": 118534,
                            "name": "Holiday Lakes",
                            "latitude": "29.20969000",
                            "longitude": "-95.51689000"
                        },
                        {
                            "id": 118537,
                            "name": "Holland",
                            "latitude": "30.87824000",
                            "longitude": "-97.40167000"
                        },
                        {
                            "id": 118544,
                            "name": "Holliday",
                            "latitude": "33.81621000",
                            "longitude": "-98.69506000"
                        },
                        {
                            "id": 118559,
                            "name": "Holly Lake Ranch",
                            "latitude": "32.71336000",
                            "longitude": "-95.19756000"
                        },
                        {
                            "id": 118568,
                            "name": "Hollywood Park",
                            "latitude": "29.60050000",
                            "longitude": "-98.48724000"
                        },
                        {
                            "id": 118603,
                            "name": "Homestead Meadows North",
                            "latitude": "31.84963000",
                            "longitude": "-106.17285000"
                        },
                        {
                            "id": 118604,
                            "name": "Homestead Meadows South",
                            "latitude": "31.81097000",
                            "longitude": "-106.16427000"
                        },
                        {
                            "id": 118615,
                            "name": "Hondo",
                            "latitude": "29.34746000",
                            "longitude": "-99.14142000"
                        },
                        {
                            "id": 118620,
                            "name": "Honey Grove",
                            "latitude": "33.58344000",
                            "longitude": "-95.90997000"
                        },
                        {
                            "id": 118625,
                            "name": "Hood County",
                            "latitude": "32.42993000",
                            "longitude": "-97.83227000"
                        },
                        {
                            "id": 118630,
                            "name": "Hooks",
                            "latitude": "33.46623000",
                            "longitude": "-94.28853000"
                        },
                        {
                            "id": 118654,
                            "name": "Hopkins County",
                            "latitude": "33.14956000",
                            "longitude": "-95.56395000"
                        },
                        {
                            "id": 118664,
                            "name": "Horizon City",
                            "latitude": "31.69261000",
                            "longitude": "-106.20748000"
                        },
                        {
                            "id": 118668,
                            "name": "Hornsby Bend",
                            "latitude": "30.24743000",
                            "longitude": "-97.58333000"
                        },
                        {
                            "id": 118675,
                            "name": "Horseshoe Bay",
                            "latitude": "30.54429000",
                            "longitude": "-98.37394000"
                        },
                        {
                            "id": 118699,
                            "name": "Houston",
                            "latitude": "29.76328000",
                            "longitude": "-95.36327000"
                        },
                        {
                            "id": 118705,
                            "name": "Houston County",
                            "latitude": "31.31773000",
                            "longitude": "-95.42268000"
                        },
                        {
                            "id": 118718,
                            "name": "Howard County",
                            "latitude": "32.30617000",
                            "longitude": "-101.43551000"
                        },
                        {
                            "id": 118721,
                            "name": "Howe",
                            "latitude": "33.50872000",
                            "longitude": "-96.61221000"
                        },
                        {
                            "id": 118730,
                            "name": "Hubbard",
                            "latitude": "31.84849000",
                            "longitude": "-96.79721000"
                        },
                        {
                            "id": 118737,
                            "name": "Hudson",
                            "latitude": "31.32268000",
                            "longitude": "-94.77826000"
                        },
                        {
                            "id": 118748,
                            "name": "Hudson Bend",
                            "latitude": "30.41714000",
                            "longitude": "-97.92918000"
                        },
                        {
                            "id": 118752,
                            "name": "Hudson Oaks",
                            "latitude": "32.75707000",
                            "longitude": "-97.70670000"
                        },
                        {
                            "id": 118754,
                            "name": "Hudspeth County",
                            "latitude": "31.45618000",
                            "longitude": "-105.38646000"
                        },
                        {
                            "id": 118760,
                            "name": "Hughes Springs",
                            "latitude": "32.99846000",
                            "longitude": "-94.63076000"
                        },
                        {
                            "id": 118774,
                            "name": "Humble",
                            "latitude": "29.99883000",
                            "longitude": "-95.26216000"
                        },
                        {
                            "id": 118786,
                            "name": "Hunt County",
                            "latitude": "33.12360000",
                            "longitude": "-96.08545000"
                        },
                        {
                            "id": 118791,
                            "name": "Hunters Creek Village",
                            "latitude": "29.77051000",
                            "longitude": "-95.49550000"
                        },
                        {
                            "id": 118798,
                            "name": "Huntington",
                            "latitude": "31.27769000",
                            "longitude": "-94.57659000"
                        },
                        {
                            "id": 118818,
                            "name": "Huntsville",
                            "latitude": "30.72353000",
                            "longitude": "-95.55078000"
                        },
                        {
                            "id": 118829,
                            "name": "Hurst",
                            "latitude": "32.82346000",
                            "longitude": "-97.17057000"
                        },
                        {
                            "id": 118834,
                            "name": "Hutchins",
                            "latitude": "32.64930000",
                            "longitude": "-96.71305000"
                        },
                        {
                            "id": 118838,
                            "name": "Hutchinson County",
                            "latitude": "35.84003000",
                            "longitude": "-101.35470000"
                        },
                        {
                            "id": 118840,
                            "name": "Hutto",
                            "latitude": "30.54270000",
                            "longitude": "-97.54667000"
                        },
                        {
                            "id": 118873,
                            "name": "Idalou",
                            "latitude": "33.66647000",
                            "longitude": "-101.68294000"
                        },
                        {
                            "id": 118907,
                            "name": "Indian Hills",
                            "latitude": "26.21285000",
                            "longitude": "-97.91639000"
                        },
                        {
                            "id": 118932,
                            "name": "Inez",
                            "latitude": "28.90388000",
                            "longitude": "-96.78804000"
                        },
                        {
                            "id": 118936,
                            "name": "Ingleside",
                            "latitude": "27.87780000",
                            "longitude": "-97.21166000"
                        },
                        {
                            "id": 118940,
                            "name": "Ingram",
                            "latitude": "30.07743000",
                            "longitude": "-99.24032000"
                        },
                        {
                            "id": 118977,
                            "name": "Iowa Colony",
                            "latitude": "29.48246000",
                            "longitude": "-95.41550000"
                        },
                        {
                            "id": 118981,
                            "name": "Iowa Park",
                            "latitude": "33.95148000",
                            "longitude": "-98.66867000"
                        },
                        {
                            "id": 118984,
                            "name": "Iraan",
                            "latitude": "30.91405000",
                            "longitude": "-101.89791000"
                        },
                        {
                            "id": 118986,
                            "name": "Irion County",
                            "latitude": "31.30392000",
                            "longitude": "-100.98242000"
                        },
                        {
                            "id": 119005,
                            "name": "Irving",
                            "latitude": "32.81402000",
                            "longitude": "-96.94889000"
                        },
                        {
                            "id": 119038,
                            "name": "Italy",
                            "latitude": "32.18404000",
                            "longitude": "-96.88472000"
                        },
                        {
                            "id": 119039,
                            "name": "Itasca",
                            "latitude": "32.15959000",
                            "longitude": "-97.15001000"
                        },
                        {
                            "id": 119055,
                            "name": "Jacinto City",
                            "latitude": "29.76745000",
                            "longitude": "-95.23382000"
                        },
                        {
                            "id": 119056,
                            "name": "Jack County",
                            "latitude": "33.23346000",
                            "longitude": "-98.17246000"
                        },
                        {
                            "id": 119059,
                            "name": "Jacksboro",
                            "latitude": "33.21845000",
                            "longitude": "-98.15866000"
                        },
                        {
                            "id": 119088,
                            "name": "Jackson County",
                            "latitude": "28.95424000",
                            "longitude": "-96.57768000"
                        },
                        {
                            "id": 119104,
                            "name": "Jacksonville",
                            "latitude": "31.96378000",
                            "longitude": "-95.27050000"
                        },
                        {
                            "id": 119113,
                            "name": "Jamaica Beach",
                            "latitude": "29.18968000",
                            "longitude": "-94.97965000"
                        },
                        {
                            "id": 119134,
                            "name": "Jarrell",
                            "latitude": "30.82491000",
                            "longitude": "-97.60445000"
                        },
                        {
                            "id": 119144,
                            "name": "Jasper",
                            "latitude": "30.92020000",
                            "longitude": "-93.99658000"
                        },
                        {
                            "id": 119150,
                            "name": "Jasper County",
                            "latitude": "30.74396000",
                            "longitude": "-94.02509000"
                        },
                        {
                            "id": 119156,
                            "name": "Jayton",
                            "latitude": "33.24815000",
                            "longitude": "-100.57373000"
                        },
                        {
                            "id": 119161,
                            "name": "Jeff Davis County",
                            "latitude": "30.71548000",
                            "longitude": "-104.14002000"
                        },
                        {
                            "id": 119166,
                            "name": "Jefferson",
                            "latitude": "32.75736000",
                            "longitude": "-94.34519000"
                        },
                        {
                            "id": 119187,
                            "name": "Jefferson County",
                            "latitude": "29.85373000",
                            "longitude": "-94.15344000"
                        },
                        {
                            "id": 119234,
                            "name": "Jersey Village",
                            "latitude": "29.88772000",
                            "longitude": "-95.56300000"
                        },
                        {
                            "id": 119244,
                            "name": "Jewett",
                            "latitude": "31.36157000",
                            "longitude": "-96.14413000"
                        },
                        {
                            "id": 119246,
                            "name": "Jim Hogg County",
                            "latitude": "27.04340000",
                            "longitude": "-98.69731000"
                        },
                        {
                            "id": 119248,
                            "name": "Jim Wells County",
                            "latitude": "27.73130000",
                            "longitude": "-98.08994000"
                        },
                        {
                            "id": 119258,
                            "name": "Johnson City",
                            "latitude": "30.27687000",
                            "longitude": "-98.41197000"
                        },
                        {
                            "id": 119268,
                            "name": "Johnson County",
                            "latitude": "32.37901000",
                            "longitude": "-97.36633000"
                        },
                        {
                            "id": 119290,
                            "name": "Jollyville",
                            "latitude": "30.44270000",
                            "longitude": "-97.77501000"
                        },
                        {
                            "id": 119295,
                            "name": "Jones County",
                            "latitude": "32.73990000",
                            "longitude": "-99.87874000"
                        },
                        {
                            "id": 119298,
                            "name": "Jones Creek",
                            "latitude": "28.96858000",
                            "longitude": "-95.45522000"
                        },
                        {
                            "id": 119307,
                            "name": "Jonestown",
                            "latitude": "30.49547000",
                            "longitude": "-97.92335000"
                        },
                        {
                            "id": 119320,
                            "name": "Josephine",
                            "latitude": "33.06123000",
                            "longitude": "-96.30720000"
                        },
                        {
                            "id": 119322,
                            "name": "Joshua",
                            "latitude": "32.46153000",
                            "longitude": "-97.38807000"
                        },
                        {
                            "id": 119324,
                            "name": "Jourdanton",
                            "latitude": "28.91803000",
                            "longitude": "-98.54641000"
                        },
                        {
                            "id": 119331,
                            "name": "Junction",
                            "latitude": "30.48936000",
                            "longitude": "-99.77201000"
                        },
                        {
                            "id": 119346,
                            "name": "Justin",
                            "latitude": "33.08484000",
                            "longitude": "-97.29613000"
                        },
                        {
                            "id": 119389,
                            "name": "Karnes City",
                            "latitude": "28.88498000",
                            "longitude": "-97.90084000"
                        },
                        {
                            "id": 119390,
                            "name": "Karnes County",
                            "latitude": "28.90574000",
                            "longitude": "-97.85940000"
                        },
                        {
                            "id": 119395,
                            "name": "Katy",
                            "latitude": "29.78579000",
                            "longitude": "-95.82440000"
                        },
                        {
                            "id": 119397,
                            "name": "Kaufman",
                            "latitude": "32.58902000",
                            "longitude": "-96.30887000"
                        },
                        {
                            "id": 119398,
                            "name": "Kaufman County",
                            "latitude": "32.59930000",
                            "longitude": "-96.28780000"
                        },
                        {
                            "id": 119420,
                            "name": "Keene",
                            "latitude": "32.39681000",
                            "longitude": "-97.32390000"
                        },
                        {
                            "id": 119428,
                            "name": "Keller",
                            "latitude": "32.93457000",
                            "longitude": "-97.25168000"
                        },
                        {
                            "id": 119433,
                            "name": "Kemah",
                            "latitude": "29.54273000",
                            "longitude": "-95.02048000"
                        },
                        {
                            "id": 119435,
                            "name": "Kemp",
                            "latitude": "32.44264000",
                            "longitude": "-96.22998000"
                        },
                        {
                            "id": 119438,
                            "name": "Kempner",
                            "latitude": "31.08101000",
                            "longitude": "-98.00252000"
                        },
                        {
                            "id": 119446,
                            "name": "Kendall County",
                            "latitude": "29.94469000",
                            "longitude": "-98.71156000"
                        },
                        {
                            "id": 119453,
                            "name": "Kenedy",
                            "latitude": "28.81915000",
                            "longitude": "-97.84861000"
                        },
                        {
                            "id": 119454,
                            "name": "Kenedy County",
                            "latitude": "26.92802000",
                            "longitude": "-97.63646000"
                        },
                        {
                            "id": 119468,
                            "name": "Kennedale",
                            "latitude": "32.64680000",
                            "longitude": "-97.22585000"
                        },
                        {
                            "id": 119494,
                            "name": "Kent County",
                            "latitude": "33.18142000",
                            "longitude": "-100.77757000"
                        },
                        {
                            "id": 119510,
                            "name": "Kerens",
                            "latitude": "32.13321000",
                            "longitude": "-96.22775000"
                        },
                        {
                            "id": 119513,
                            "name": "Kermit",
                            "latitude": "31.85763000",
                            "longitude": "-103.09267000"
                        },
                        {
                            "id": 119517,
                            "name": "Kerr County",
                            "latitude": "30.06148000",
                            "longitude": "-99.35016000"
                        },
                        {
                            "id": 119518,
                            "name": "Kerrville",
                            "latitude": "30.04743000",
                            "longitude": "-99.14032000"
                        },
                        {
                            "id": 119556,
                            "name": "Kilgore",
                            "latitude": "32.38626000",
                            "longitude": "-94.87577000"
                        },
                        {
                            "id": 119559,
                            "name": "Killeen",
                            "latitude": "31.11712000",
                            "longitude": "-97.72780000"
                        },
                        {
                            "id": 119572,
                            "name": "Kimble County",
                            "latitude": "30.48678000",
                            "longitude": "-99.74870000"
                        },
                        {
                            "id": 119581,
                            "name": "King County",
                            "latitude": "33.61648000",
                            "longitude": "-100.25585000"
                        },
                        {
                            "id": 119616,
                            "name": "Kingsland",
                            "latitude": "30.65824000",
                            "longitude": "-98.44058000"
                        },
                        {
                            "id": 119636,
                            "name": "Kingsville",
                            "latitude": "27.51587000",
                            "longitude": "-97.85611000"
                        },
                        {
                            "id": 119638,
                            "name": "Kingwood Area",
                            "latitude": "30.03355000",
                            "longitude": "-95.26104000"
                        },
                        {
                            "id": 119640,
                            "name": "Kinney County",
                            "latitude": "29.35021000",
                            "longitude": "-100.41795000"
                        },
                        {
                            "id": 119649,
                            "name": "Kirby",
                            "latitude": "29.46329000",
                            "longitude": "-98.38557000"
                        },
                        {
                            "id": 119650,
                            "name": "Kirbyville",
                            "latitude": "30.66048000",
                            "longitude": "-93.89268000"
                        },
                        {
                            "id": 119672,
                            "name": "Kleberg County",
                            "latitude": "27.42671000",
                            "longitude": "-97.66839000"
                        },
                        {
                            "id": 119684,
                            "name": "Knox City",
                            "latitude": "33.41815000",
                            "longitude": "-99.81898000"
                        },
                        {
                            "id": 119688,
                            "name": "Knox County",
                            "latitude": "33.60613000",
                            "longitude": "-99.74143000"
                        },
                        {
                            "id": 119713,
                            "name": "Kountze",
                            "latitude": "30.37160000",
                            "longitude": "-94.31241000"
                        },
                        {
                            "id": 119719,
                            "name": "Krugerville",
                            "latitude": "33.28151000",
                            "longitude": "-96.99056000"
                        },
                        {
                            "id": 119720,
                            "name": "Krum",
                            "latitude": "33.26151000",
                            "longitude": "-97.23807000"
                        },
                        {
                            "id": 119729,
                            "name": "Kyle",
                            "latitude": "29.98911000",
                            "longitude": "-97.87723000"
                        },
                        {
                            "id": 119735,
                            "name": "La Blanca",
                            "latitude": "26.29285000",
                            "longitude": "-98.03778000"
                        },
                        {
                            "id": 119740,
                            "name": "La Coste",
                            "latitude": "29.31079000",
                            "longitude": "-98.81003000"
                        },
                        {
                            "id": 119747,
                            "name": "La Feria",
                            "latitude": "26.15896000",
                            "longitude": "-97.82389000"
                        },
                        {
                            "id": 119751,
                            "name": "La Grange",
                            "latitude": "29.90550000",
                            "longitude": "-96.87665000"
                        },
                        {
                            "id": 119754,
                            "name": "La Grulla",
                            "latitude": "26.26951000",
                            "longitude": "-98.64725000"
                        },
                        {
                            "id": 119758,
                            "name": "La Homa",
                            "latitude": "26.25007000",
                            "longitude": "-98.36363000"
                        },
                        {
                            "id": 119761,
                            "name": "La Joya",
                            "latitude": "26.24702000",
                            "longitude": "-98.48141000"
                        },
                        {
                            "id": 119764,
                            "name": "La Marque",
                            "latitude": "29.36857000",
                            "longitude": "-94.97131000"
                        },
                        {
                            "id": 119770,
                            "name": "La Paloma",
                            "latitude": "26.04591000",
                            "longitude": "-97.66749000"
                        },
                        {
                            "id": 119776,
                            "name": "La Porte",
                            "latitude": "29.66578000",
                            "longitude": "-95.01937000"
                        },
                        {
                            "id": 119780,
                            "name": "La Pryor",
                            "latitude": "28.94108000",
                            "longitude": "-99.84978000"
                        },
                        {
                            "id": 119787,
                            "name": "La Salle County",
                            "latitude": "28.34507000",
                            "longitude": "-99.09966000"
                        },
                        {
                            "id": 119794,
                            "name": "La Vernia",
                            "latitude": "29.35635000",
                            "longitude": "-98.11556000"
                        },
                        {
                            "id": 119795,
                            "name": "La Villa",
                            "latitude": "26.29868000",
                            "longitude": "-97.92861000"
                        },
                        {
                            "id": 119815,
                            "name": "Lackland Air Force Base",
                            "latitude": "29.38663000",
                            "longitude": "-98.61797000"
                        },
                        {
                            "id": 119821,
                            "name": "Lacy-Lakeview",
                            "latitude": "31.62933000",
                            "longitude": "-97.10278000"
                        },
                        {
                            "id": 119847,
                            "name": "Lago Vista",
                            "latitude": "30.46020000",
                            "longitude": "-97.98835000"
                        },
                        {
                            "id": 119853,
                            "name": "Laguna Heights",
                            "latitude": "26.08008000",
                            "longitude": "-97.25386000"
                        },
                        {
                            "id": 119856,
                            "name": "Laguna Park",
                            "latitude": "31.85932000",
                            "longitude": "-97.37974000"
                        },
                        {
                            "id": 119857,
                            "name": "Laguna Vista",
                            "latitude": "26.10091000",
                            "longitude": "-97.29025000"
                        },
                        {
                            "id": 119872,
                            "name": "Lake Brownwood",
                            "latitude": "31.83570000",
                            "longitude": "-99.00783000"
                        },
                        {
                            "id": 119902,
                            "name": "Lake Dallas",
                            "latitude": "33.11929000",
                            "longitude": "-97.02556000"
                        },
                        {
                            "id": 119904,
                            "name": "Lake Dunlap",
                            "latitude": "29.67578000",
                            "longitude": "-98.07223000"
                        },
                        {
                            "id": 119924,
                            "name": "Lake Jackson",
                            "latitude": "29.03386000",
                            "longitude": "-95.43439000"
                        },
                        {
                            "id": 119927,
                            "name": "Lake Kiowa",
                            "latitude": "33.57705000",
                            "longitude": "-97.01306000"
                        },
                        {
                            "id": 119998,
                            "name": "Lake Worth",
                            "latitude": "32.80485000",
                            "longitude": "-97.44502000"
                        },
                        {
                            "id": 120010,
                            "name": "Lakehills",
                            "latitude": "29.60467000",
                            "longitude": "-98.94309000"
                        },
                        {
                            "id": 120030,
                            "name": "Lakeside",
                            "latitude": "32.82235000",
                            "longitude": "-97.49335000"
                        },
                        {
                            "id": 120044,
                            "name": "Lakeway",
                            "latitude": "30.36377000",
                            "longitude": "-97.97959000"
                        },
                        {
                            "id": 120064,
                            "name": "Lamar County",
                            "latitude": "33.66726000",
                            "longitude": "-95.57120000"
                        },
                        {
                            "id": 120066,
                            "name": "Lamb County",
                            "latitude": "34.06862000",
                            "longitude": "-102.35171000"
                        },
                        {
                            "id": 120071,
                            "name": "Lamesa",
                            "latitude": "32.73760000",
                            "longitude": "-101.95099000"
                        },
                        {
                            "id": 120075,
                            "name": "Lampasas",
                            "latitude": "31.06378000",
                            "longitude": "-98.18170000"
                        },
                        {
                            "id": 120076,
                            "name": "Lampasas County",
                            "latitude": "31.19619000",
                            "longitude": "-98.24145000"
                        },
                        {
                            "id": 120082,
                            "name": "Lancaster",
                            "latitude": "32.59208000",
                            "longitude": "-96.75611000"
                        },
                        {
                            "id": 120130,
                            "name": "Lantana",
                            "latitude": "33.09073000",
                            "longitude": "-97.12416000"
                        },
                        {
                            "id": 120142,
                            "name": "Laredo",
                            "latitude": "27.50641000",
                            "longitude": "-99.50754000"
                        },
                        {
                            "id": 120157,
                            "name": "Las Lomas",
                            "latitude": "26.36479000",
                            "longitude": "-98.77530000"
                        },
                        {
                            "id": 120160,
                            "name": "Las Palmas II",
                            "latitude": "26.20171000",
                            "longitude": "-97.73760000"
                        },
                        {
                            "id": 120161,
                            "name": "Las Quintas Fronterizas",
                            "latitude": "28.69053000",
                            "longitude": "-100.46869000"
                        },
                        {
                            "id": 120162,
                            "name": "Las Quintas Fronterizas Colonia",
                            "latitude": "28.69137000",
                            "longitude": "-100.46924000"
                        },
                        {
                            "id": 120165,
                            "name": "Lasara",
                            "latitude": "26.46479000",
                            "longitude": "-97.91111000"
                        },
                        {
                            "id": 120186,
                            "name": "Laughlin Air Force Base",
                            "latitude": "29.35663000",
                            "longitude": "-100.78353000"
                        },
                        {
                            "id": 120203,
                            "name": "Laureles",
                            "latitude": "26.10924000",
                            "longitude": "-97.49415000"
                        },
                        {
                            "id": 120214,
                            "name": "Lavaca County",
                            "latitude": "29.38435000",
                            "longitude": "-96.93015000"
                        },
                        {
                            "id": 120219,
                            "name": "Lavon",
                            "latitude": "33.02762000",
                            "longitude": "-96.43415000"
                        },
                        {
                            "id": 120274,
                            "name": "League City",
                            "latitude": "29.50745000",
                            "longitude": "-95.09493000"
                        },
                        {
                            "id": 120277,
                            "name": "Leakey",
                            "latitude": "29.72884000",
                            "longitude": "-99.76145000"
                        },
                        {
                            "id": 120279,
                            "name": "Leander",
                            "latitude": "30.57881000",
                            "longitude": "-97.85307000"
                        },
                        {
                            "id": 120314,
                            "name": "Lee County",
                            "latitude": "30.31068000",
                            "longitude": "-96.96570000"
                        },
                        {
                            "id": 120385,
                            "name": "Leon County",
                            "latitude": "31.29651000",
                            "longitude": "-95.99569000"
                        },
                        {
                            "id": 120386,
                            "name": "Leon Valley",
                            "latitude": "29.49523000",
                            "longitude": "-98.61863000"
                        },
                        {
                            "id": 120388,
                            "name": "Leonard",
                            "latitude": "33.37955000",
                            "longitude": "-96.24748000"
                        },
                        {
                            "id": 120405,
                            "name": "Levelland",
                            "latitude": "33.58732000",
                            "longitude": "-102.37796000"
                        },
                        {
                            "id": 120436,
                            "name": "Lewisville",
                            "latitude": "33.04623000",
                            "longitude": "-96.99417000"
                        },
                        {
                            "id": 120446,
                            "name": "Lexington",
                            "latitude": "30.41910000",
                            "longitude": "-97.01165000"
                        },
                        {
                            "id": 120465,
                            "name": "Liberty",
                            "latitude": "30.05799000",
                            "longitude": "-94.79548000"
                        },
                        {
                            "id": 120469,
                            "name": "Liberty City",
                            "latitude": "32.44543000",
                            "longitude": "-94.94855000"
                        },
                        {
                            "id": 120472,
                            "name": "Liberty County",
                            "latitude": "30.15161000",
                            "longitude": "-94.81221000"
                        },
                        {
                            "id": 120474,
                            "name": "Liberty Hill",
                            "latitude": "30.66491000",
                            "longitude": "-97.92252000"
                        },
                        {
                            "id": 120495,
                            "name": "Limestone County",
                            "latitude": "31.54546000",
                            "longitude": "-96.58053000"
                        },
                        {
                            "id": 120559,
                            "name": "Lindale",
                            "latitude": "32.51570000",
                            "longitude": "-95.40940000"
                        },
                        {
                            "id": 120562,
                            "name": "Linden",
                            "latitude": "33.01235000",
                            "longitude": "-94.36547000"
                        },
                        {
                            "id": 120573,
                            "name": "Lindsay",
                            "latitude": "33.63594000",
                            "longitude": "-97.22279000"
                        },
                        {
                            "id": 120596,
                            "name": "Lipscomb County",
                            "latitude": "36.27771000",
                            "longitude": "-100.27312000"
                        },
                        {
                            "id": 120616,
                            "name": "Little Elm",
                            "latitude": "33.16262000",
                            "longitude": "-96.93751000"
                        },
                        {
                            "id": 120625,
                            "name": "Little River-Academy",
                            "latitude": "30.98629000",
                            "longitude": "-97.35861000"
                        },
                        {
                            "id": 120632,
                            "name": "Littlefield",
                            "latitude": "33.91731000",
                            "longitude": "-102.32490000"
                        },
                        {
                            "id": 120639,
                            "name": "Live Oak",
                            "latitude": "29.56523000",
                            "longitude": "-98.33640000"
                        },
                        {
                            "id": 120641,
                            "name": "Live Oak County",
                            "latitude": "28.35137000",
                            "longitude": "-98.12479000"
                        },
                        {
                            "id": 120650,
                            "name": "Livingston",
                            "latitude": "30.71103000",
                            "longitude": "-94.93299000"
                        },
                        {
                            "id": 120664,
                            "name": "Llano",
                            "latitude": "30.75935000",
                            "longitude": "-98.67504000"
                        },
                        {
                            "id": 120665,
                            "name": "Llano County",
                            "latitude": "30.70574000",
                            "longitude": "-98.68410000"
                        },
                        {
                            "id": 120666,
                            "name": "Llano Grande",
                            "latitude": "26.12980000",
                            "longitude": "-97.96806000"
                        },
                        {
                            "id": 120677,
                            "name": "Lockhart",
                            "latitude": "29.88494000",
                            "longitude": "-97.67000000"
                        },
                        {
                            "id": 120678,
                            "name": "Lockney",
                            "latitude": "34.12452000",
                            "longitude": "-101.44155000"
                        },
                        {
                            "id": 120730,
                            "name": "Lone Star",
                            "latitude": "32.94402000",
                            "longitude": "-94.70715000"
                        },
                        {
                            "id": 120757,
                            "name": "Longview",
                            "latitude": "32.50070000",
                            "longitude": "-94.74049000"
                        },
                        {
                            "id": 120768,
                            "name": "Lopezville",
                            "latitude": "26.23813000",
                            "longitude": "-98.15973000"
                        },
                        {
                            "id": 120771,
                            "name": "Lorena",
                            "latitude": "31.38656000",
                            "longitude": "-97.21556000"
                        },
                        {
                            "id": 120773,
                            "name": "Lorenzo",
                            "latitude": "33.67064000",
                            "longitude": "-101.53516000"
                        },
                        {
                            "id": 120788,
                            "name": "Los Fresnos",
                            "latitude": "26.07174000",
                            "longitude": "-97.47637000"
                        },
                        {
                            "id": 120790,
                            "name": "Los Indios",
                            "latitude": "26.04924000",
                            "longitude": "-97.74499000"
                        },
                        {
                            "id": 120797,
                            "name": "Lost Creek",
                            "latitude": "30.29548000",
                            "longitude": "-97.84445000"
                        },
                        {
                            "id": 120829,
                            "name": "Loving County",
                            "latitude": "31.84923000",
                            "longitude": "-103.57996000"
                        },
                        {
                            "id": 120849,
                            "name": "Lowry Crossing",
                            "latitude": "33.15484000",
                            "longitude": "-96.54721000"
                        },
                        {
                            "id": 120856,
                            "name": "Lubbock",
                            "latitude": "33.57786000",
                            "longitude": "-101.85517000"
                        },
                        {
                            "id": 120857,
                            "name": "Lubbock County",
                            "latitude": "33.61021000",
                            "longitude": "-101.82055000"
                        },
                        {
                            "id": 120860,
                            "name": "Lucas",
                            "latitude": "33.08429000",
                            "longitude": "-96.57666000"
                        },
                        {
                            "id": 120872,
                            "name": "Lufkin",
                            "latitude": "31.33824000",
                            "longitude": "-94.72910000"
                        },
                        {
                            "id": 120877,
                            "name": "Luling",
                            "latitude": "29.68051000",
                            "longitude": "-97.64750000"
                        },
                        {
                            "id": 120881,
                            "name": "Lumberton",
                            "latitude": "30.26577000",
                            "longitude": "-94.19963000"
                        },
                        {
                            "id": 120906,
                            "name": "Lyford",
                            "latitude": "26.41229000",
                            "longitude": "-97.78972000"
                        },
                        {
                            "id": 120928,
                            "name": "Lynn County",
                            "latitude": "33.17682000",
                            "longitude": "-101.81612000"
                        },
                        {
                            "id": 120946,
                            "name": "Lytle",
                            "latitude": "29.23329000",
                            "longitude": "-98.79641000"
                        },
                        {
                            "id": 120948,
                            "name": "Mabank",
                            "latitude": "32.36653000",
                            "longitude": "-96.10081000"
                        },
                        {
                            "id": 121012,
                            "name": "Madison County",
                            "latitude": "30.96554000",
                            "longitude": "-95.92841000"
                        },
                        {
                            "id": 121027,
                            "name": "Madisonville",
                            "latitude": "30.94991000",
                            "longitude": "-95.91162000"
                        },
                        {
                            "id": 121038,
                            "name": "Magnolia",
                            "latitude": "30.20938000",
                            "longitude": "-95.75078000"
                        },
                        {
                            "id": 121058,
                            "name": "Malakoff",
                            "latitude": "32.16960000",
                            "longitude": "-96.01247000"
                        },
                        {
                            "id": 121088,
                            "name": "Manchaca",
                            "latitude": "30.14077000",
                            "longitude": "-97.83306000"
                        },
                        {
                            "id": 121138,
                            "name": "Manor",
                            "latitude": "30.34076000",
                            "longitude": "-97.55695000"
                        },
                        {
                            "id": 121145,
                            "name": "Mansfield",
                            "latitude": "32.56319000",
                            "longitude": "-97.14168000"
                        },
                        {
                            "id": 121161,
                            "name": "Manvel",
                            "latitude": "29.46274000",
                            "longitude": "-95.35799000"
                        },
                        {
                            "id": 121187,
                            "name": "Marble Falls",
                            "latitude": "30.57841000",
                            "longitude": "-98.27507000"
                        },
                        {
                            "id": 121204,
                            "name": "Marfa",
                            "latitude": "30.30973000",
                            "longitude": "-104.02134000"
                        },
                        {
                            "id": 121239,
                            "name": "Marion",
                            "latitude": "29.57134000",
                            "longitude": "-98.14029000"
                        },
                        {
                            "id": 121259,
                            "name": "Marion County",
                            "latitude": "32.79798000",
                            "longitude": "-94.35722000"
                        },
                        {
                            "id": 121269,
                            "name": "Markham",
                            "latitude": "28.96026000",
                            "longitude": "-96.06524000"
                        },
                        {
                            "id": 121283,
                            "name": "Marlin",
                            "latitude": "31.30629000",
                            "longitude": "-96.89804000"
                        },
                        {
                            "id": 121305,
                            "name": "Marshall",
                            "latitude": "32.54487000",
                            "longitude": "-94.36742000"
                        },
                        {
                            "id": 121332,
                            "name": "Mart",
                            "latitude": "31.54239000",
                            "longitude": "-96.83360000"
                        },
                        {
                            "id": 121342,
                            "name": "Martin County",
                            "latitude": "32.30603000",
                            "longitude": "-101.95122000"
                        },
                        {
                            "id": 121343,
                            "name": "Martindale",
                            "latitude": "29.84550000",
                            "longitude": "-97.84084000"
                        },
                        {
                            "id": 121372,
                            "name": "Mason",
                            "latitude": "30.74879000",
                            "longitude": "-99.23061000"
                        },
                        {
                            "id": 121378,
                            "name": "Mason County",
                            "latitude": "30.71772000",
                            "longitude": "-99.22613000"
                        },
                        {
                            "id": 121396,
                            "name": "Matador",
                            "latitude": "34.01202000",
                            "longitude": "-100.82208000"
                        },
                        {
                            "id": 121397,
                            "name": "Matagorda County",
                            "latitude": "28.78565000",
                            "longitude": "-96.00398000"
                        },
                        {
                            "id": 121405,
                            "name": "Mathis",
                            "latitude": "28.09446000",
                            "longitude": "-97.82805000"
                        },
                        {
                            "id": 121417,
                            "name": "Maud",
                            "latitude": "33.33290000",
                            "longitude": "-94.34270000"
                        },
                        {
                            "id": 121424,
                            "name": "Mauriceville",
                            "latitude": "30.20354000",
                            "longitude": "-93.86628000"
                        },
                        {
                            "id": 121428,
                            "name": "Maverick County",
                            "latitude": "28.74259000",
                            "longitude": "-100.31451000"
                        },
                        {
                            "id": 121463,
                            "name": "McAllen",
                            "latitude": "26.20341000",
                            "longitude": "-98.23001000"
                        },
                        {
                            "id": 121466,
                            "name": "McCamey",
                            "latitude": "31.13598000",
                            "longitude": "-102.22430000"
                        },
                        {
                            "id": 121488,
                            "name": "McCulloch County",
                            "latitude": "31.19887000",
                            "longitude": "-99.34748000"
                        },
                        {
                            "id": 121506,
                            "name": "McGregor",
                            "latitude": "31.44406000",
                            "longitude": "-97.40918000"
                        },
                        {
                            "id": 121525,
                            "name": "McKinney",
                            "latitude": "33.19762000",
                            "longitude": "-96.61527000"
                        },
                        {
                            "id": 121533,
                            "name": "McLendon-Chisholm",
                            "latitude": "32.84235000",
                            "longitude": "-96.38054000"
                        },
                        {
                            "id": 121534,
                            "name": "McLennan County",
                            "latitude": "31.55238000",
                            "longitude": "-97.20179000"
                        },
                        {
                            "id": 121542,
                            "name": "McMullen County",
                            "latitude": "28.35269000",
                            "longitude": "-98.56784000"
                        },
                        {
                            "id": 121549,
                            "name": "McQueeney",
                            "latitude": "29.59217000",
                            "longitude": "-98.03334000"
                        },
                        {
                            "id": 121569,
                            "name": "Meadowlakes",
                            "latitude": "30.56245000",
                            "longitude": "-98.29867000"
                        },
                        {
                            "id": 121571,
                            "name": "Meadows Place",
                            "latitude": "29.65134000",
                            "longitude": "-95.58800000"
                        },
                        {
                            "id": 121603,
                            "name": "Medina",
                            "latitude": "29.79661000",
                            "longitude": "-99.24643000"
                        },
                        {
                            "id": 121607,
                            "name": "Medina County",
                            "latitude": "29.35570000",
                            "longitude": "-99.11013000"
                        },
                        {
                            "id": 121624,
                            "name": "Melissa",
                            "latitude": "33.28595000",
                            "longitude": "-96.57277000"
                        },
                        {
                            "id": 121642,
                            "name": "Memphis",
                            "latitude": "34.72478000",
                            "longitude": "-100.53401000"
                        },
                        {
                            "id": 121646,
                            "name": "Menard",
                            "latitude": "30.91767000",
                            "longitude": "-99.78646000"
                        },
                        {
                            "id": 121647,
                            "name": "Menard County",
                            "latitude": "30.88978000",
                            "longitude": "-99.82064000"
                        },
                        {
                            "id": 121668,
                            "name": "Mentone",
                            "latitude": "31.70513000",
                            "longitude": "-103.59935000"
                        },
                        {
                            "id": 121673,
                            "name": "Mercedes",
                            "latitude": "26.14980000",
                            "longitude": "-97.91361000"
                        },
                        {
                            "id": 121692,
                            "name": "Meridian",
                            "latitude": "31.92321000",
                            "longitude": "-97.65669000"
                        },
                        {
                            "id": 121700,
                            "name": "Merkel",
                            "latitude": "32.47068000",
                            "longitude": "-100.01287000"
                        },
                        {
                            "id": 121719,
                            "name": "Mertzon",
                            "latitude": "31.26183000",
                            "longitude": "-100.81733000"
                        },
                        {
                            "id": 121726,
                            "name": "Mesquite",
                            "latitude": "32.76680000",
                            "longitude": "-96.59916000"
                        },
                        {
                            "id": 121740,
                            "name": "Mexia",
                            "latitude": "31.67989000",
                            "longitude": "-96.48220000"
                        },
                        {
                            "id": 121749,
                            "name": "Miami",
                            "latitude": "35.69143000",
                            "longitude": "-100.63819000"
                        },
                        {
                            "id": 121800,
                            "name": "Midland",
                            "latitude": "31.99735000",
                            "longitude": "-102.07791000"
                        },
                        {
                            "id": 121805,
                            "name": "Midland County",
                            "latitude": "31.86917000",
                            "longitude": "-102.03162000"
                        },
                        {
                            "id": 121807,
                            "name": "Midlothian",
                            "latitude": "32.48236000",
                            "longitude": "-96.99445000"
                        },
                        {
                            "id": 121822,
                            "name": "Midway North",
                            "latitude": "26.18776000",
                            "longitude": "-98.01708000"
                        },
                        {
                            "id": 121823,
                            "name": "Midway South",
                            "latitude": "26.15694000",
                            "longitude": "-98.02011000"
                        },
                        {
                            "id": 121831,
                            "name": "Mila Doce",
                            "latitude": "26.22618000",
                            "longitude": "-97.95889000"
                        },
                        {
                            "id": 121833,
                            "name": "Milam",
                            "latitude": "31.43240000",
                            "longitude": "-93.84574000"
                        },
                        {
                            "id": 121834,
                            "name": "Milam County",
                            "latitude": "30.78634000",
                            "longitude": "-96.97685000"
                        },
                        {
                            "id": 121897,
                            "name": "Mills County",
                            "latitude": "31.49519000",
                            "longitude": "-98.59546000"
                        },
                        {
                            "id": 121931,
                            "name": "Mineola",
                            "latitude": "32.66319000",
                            "longitude": "-95.48829000"
                        },
                        {
                            "id": 121941,
                            "name": "Mineral Wells",
                            "latitude": "32.80846000",
                            "longitude": "-98.11282000"
                        },
                        {
                            "id": 121980,
                            "name": "Mission",
                            "latitude": "26.21591000",
                            "longitude": "-98.32529000"
                        },
                        {
                            "id": 121983,
                            "name": "Mission Bend",
                            "latitude": "29.69384000",
                            "longitude": "-95.66495000"
                        },
                        {
                            "id": 121993,
                            "name": "Missouri City",
                            "latitude": "29.61857000",
                            "longitude": "-95.53772000"
                        },
                        {
                            "id": 122003,
                            "name": "Mitchell County",
                            "latitude": "32.30621000",
                            "longitude": "-100.92123000"
                        },
                        {
                            "id": 122033,
                            "name": "Monahans",
                            "latitude": "31.59430000",
                            "longitude": "-102.89265000"
                        },
                        {
                            "id": 122093,
                            "name": "Mont Belvieu",
                            "latitude": "29.84772000",
                            "longitude": "-94.89076000"
                        },
                        {
                            "id": 122095,
                            "name": "Montague",
                            "latitude": "33.66483000",
                            "longitude": "-97.72059000"
                        },
                        {
                            "id": 122099,
                            "name": "Montague County",
                            "latitude": "33.67563000",
                            "longitude": "-97.72465000"
                        },
                        {
                            "id": 122108,
                            "name": "Monte Alto",
                            "latitude": "26.37312000",
                            "longitude": "-97.97167000"
                        },
                        {
                            "id": 122150,
                            "name": "Montgomery County",
                            "latitude": "30.30021000",
                            "longitude": "-95.50301000"
                        },
                        {
                            "id": 122196,
                            "name": "Moody",
                            "latitude": "31.30823000",
                            "longitude": "-97.36140000"
                        },
                        {
                            "id": 122203,
                            "name": "Moore County",
                            "latitude": "35.83769000",
                            "longitude": "-101.89292000"
                        },
                        {
                            "id": 122249,
                            "name": "Morgans Point Resort",
                            "latitude": "31.14823000",
                            "longitude": "-97.46334000"
                        },
                        {
                            "id": 122270,
                            "name": "Morris County",
                            "latitude": "33.11348000",
                            "longitude": "-94.73265000"
                        },
                        {
                            "id": 122294,
                            "name": "Morton",
                            "latitude": "33.72510000",
                            "longitude": "-102.75938000"
                        },
                        {
                            "id": 122311,
                            "name": "Motley County",
                            "latitude": "34.07409000",
                            "longitude": "-100.77983000"
                        },
                        {
                            "id": 122377,
                            "name": "Mount Pleasant",
                            "latitude": "33.15679000",
                            "longitude": "-94.96827000"
                        },
                        {
                            "id": 122399,
                            "name": "Mount Vernon",
                            "latitude": "33.18873000",
                            "longitude": "-95.22133000"
                        },
                        {
                            "id": 122447,
                            "name": "Muenster",
                            "latitude": "33.65177000",
                            "longitude": "-97.37641000"
                        },
                        {
                            "id": 122457,
                            "name": "Muleshoe",
                            "latitude": "34.22647000",
                            "longitude": "-102.72383000"
                        },
                        {
                            "id": 122467,
                            "name": "Munday",
                            "latitude": "33.44926000",
                            "longitude": "-99.62286000"
                        },
                        {
                            "id": 122475,
                            "name": "Muniz",
                            "latitude": "26.25646000",
                            "longitude": "-98.08945000"
                        },
                        {
                            "id": 122483,
                            "name": "Murillo Colonia",
                            "latitude": "26.25646000",
                            "longitude": "-98.11334000"
                        },
                        {
                            "id": 122486,
                            "name": "Murphy",
                            "latitude": "33.01512000",
                            "longitude": "-96.61305000"
                        },
                        {
                            "id": 122532,
                            "name": "Nacogdoches",
                            "latitude": "31.60351000",
                            "longitude": "-94.65549000"
                        },
                        {
                            "id": 122533,
                            "name": "Nacogdoches County",
                            "latitude": "31.61598000",
                            "longitude": "-94.61587000"
                        },
                        {
                            "id": 122553,
                            "name": "Naples",
                            "latitude": "33.20318000",
                            "longitude": "-94.68021000"
                        },
                        {
                            "id": 122567,
                            "name": "Nash",
                            "latitude": "33.44235000",
                            "longitude": "-94.13075000"
                        },
                        {
                            "id": 122581,
                            "name": "Nassau Bay",
                            "latitude": "29.54468000",
                            "longitude": "-95.09104000"
                        },
                        {
                            "id": 122586,
                            "name": "Natalia",
                            "latitude": "29.18968000",
                            "longitude": "-98.86253000"
                        },
                        {
                            "id": 122601,
                            "name": "Navarro County",
                            "latitude": "32.04693000",
                            "longitude": "-96.47249000"
                        },
                        {
                            "id": 122602,
                            "name": "Navasota",
                            "latitude": "30.38798000",
                            "longitude": "-96.08773000"
                        },
                        {
                            "id": 122610,
                            "name": "Nederland",
                            "latitude": "29.97438000",
                            "longitude": "-93.99240000"
                        },
                        {
                            "id": 122615,
                            "name": "Needville",
                            "latitude": "29.39941000",
                            "longitude": "-95.83773000"
                        },
                        {
                            "id": 122649,
                            "name": "Nevada",
                            "latitude": "33.04234000",
                            "longitude": "-96.37387000"
                        },
                        {
                            "id": 122668,
                            "name": "New Boston",
                            "latitude": "33.45984000",
                            "longitude": "-94.41548000"
                        },
                        {
                            "id": 122670,
                            "name": "New Braunfels",
                            "latitude": "29.70300000",
                            "longitude": "-98.12445000"
                        },
                        {
                            "id": 122784,
                            "name": "New Summerfield",
                            "latitude": "31.98072000",
                            "longitude": "-95.09383000"
                        },
                        {
                            "id": 122786,
                            "name": "New Territory",
                            "latitude": "29.59412000",
                            "longitude": "-95.68078000"
                        },
                        {
                            "id": 122790,
                            "name": "New Waverly",
                            "latitude": "30.53770000",
                            "longitude": "-95.48328000"
                        },
                        {
                            "id": 122801,
                            "name": "Newark",
                            "latitude": "33.00124000",
                            "longitude": "-97.48447000"
                        },
                        {
                            "id": 122862,
                            "name": "Newton",
                            "latitude": "30.84853000",
                            "longitude": "-93.75740000"
                        },
                        {
                            "id": 122872,
                            "name": "Newton County",
                            "latitude": "30.78631000",
                            "longitude": "-93.74474000"
                        },
                        {
                            "id": 122913,
                            "name": "Nixon",
                            "latitude": "29.26746000",
                            "longitude": "-97.76444000"
                        },
                        {
                            "id": 122923,
                            "name": "Nocona",
                            "latitude": "33.78677000",
                            "longitude": "-97.72586000"
                        },
                        {
                            "id": 122931,
                            "name": "Nolan County",
                            "latitude": "32.30349000",
                            "longitude": "-100.40605000"
                        },
                        {
                            "id": 122932,
                            "name": "Nolanville",
                            "latitude": "31.07879000",
                            "longitude": "-97.60557000"
                        },
                        {
                            "id": 122959,
                            "name": "North Alamo",
                            "latitude": "26.21730000",
                            "longitude": "-98.12890000"
                        },
                        {
                            "id": 123093,
                            "name": "North Richland Hills",
                            "latitude": "32.83430000",
                            "longitude": "-97.22890000"
                        },
                        {
                            "id": 123143,
                            "name": "Northcliff",
                            "latitude": "29.62106000",
                            "longitude": "-98.22473000"
                        },
                        {
                            "id": 123144,
                            "name": "Northcrest",
                            "latitude": "31.63655000",
                            "longitude": "-97.09972000"
                        },
                        {
                            "id": 123157,
                            "name": "Northlake",
                            "latitude": "33.12734000",
                            "longitude": "-97.26557000"
                        },
                        {
                            "id": 123215,
                            "name": "Nueces County",
                            "latitude": "27.73506000",
                            "longitude": "-97.51632000"
                        },
                        {
                            "id": 123218,
                            "name": "Nurillo",
                            "latitude": "26.26702000",
                            "longitude": "-98.12140000"
                        },
                        {
                            "id": 123231,
                            "name": "Oak Cliff Place",
                            "latitude": "29.92712000",
                            "longitude": "-95.62672000"
                        },
                        {
                            "id": 123253,
                            "name": "Oak Leaf",
                            "latitude": "32.52042000",
                            "longitude": "-96.85472000"
                        },
                        {
                            "id": 123259,
                            "name": "Oak Point",
                            "latitude": "33.19012000",
                            "longitude": "-96.99167000"
                        },
                        {
                            "id": 123263,
                            "name": "Oak Ridge North",
                            "latitude": "30.16022000",
                            "longitude": "-95.44438000"
                        },
                        {
                            "id": 123264,
                            "name": "Oak Trail Shores",
                            "latitude": "32.48875000",
                            "longitude": "-97.83420000"
                        },
                        {
                            "id": 123345,
                            "name": "Ochiltree County",
                            "latitude": "36.27841000",
                            "longitude": "-100.81565000"
                        },
                        {
                            "id": 123355,
                            "name": "Odem",
                            "latitude": "27.95057000",
                            "longitude": "-97.58222000"
                        },
                        {
                            "id": 123360,
                            "name": "Odessa",
                            "latitude": "31.84568000",
                            "longitude": "-102.36764000"
                        },
                        {
                            "id": 123426,
                            "name": "Old River-Winfree",
                            "latitude": "29.86828000",
                            "longitude": "-94.83270000"
                        },
                        {
                            "id": 123433,
                            "name": "Oldham County",
                            "latitude": "35.40502000",
                            "longitude": "-102.60287000"
                        },
                        {
                            "id": 123438,
                            "name": "Olivarez",
                            "latitude": "26.22841000",
                            "longitude": "-97.99223000"
                        },
                        {
                            "id": 123452,
                            "name": "Olmito",
                            "latitude": "26.02174000",
                            "longitude": "-97.53415000"
                        },
                        {
                            "id": 123453,
                            "name": "Olmos Park",
                            "latitude": "29.47884000",
                            "longitude": "-98.48752000"
                        },
                        {
                            "id": 123457,
                            "name": "Olney",
                            "latitude": "33.37066000",
                            "longitude": "-98.75284000"
                        },
                        {
                            "id": 123458,
                            "name": "Olton",
                            "latitude": "34.18341000",
                            "longitude": "-102.13463000"
                        },
                        {
                            "id": 123467,
                            "name": "Onalaska",
                            "latitude": "30.80575000",
                            "longitude": "-95.11633000"
                        },
                        {
                            "id": 123481,
                            "name": "Onion Creek",
                            "latitude": "30.13660000",
                            "longitude": "-97.78417000"
                        },
                        {
                            "id": 123505,
                            "name": "Orange",
                            "latitude": "30.09299000",
                            "longitude": "-93.73655000"
                        },
                        {
                            "id": 123517,
                            "name": "Orange County",
                            "latitude": "30.12131000",
                            "longitude": "-93.89390000"
                        },
                        {
                            "id": 123523,
                            "name": "Orange Grove",
                            "latitude": "27.95668000",
                            "longitude": "-97.93694000"
                        },
                        {
                            "id": 123544,
                            "name": "Ore City",
                            "latitude": "32.80014000",
                            "longitude": "-94.72076000"
                        },
                        {
                            "id": 123653,
                            "name": "Overton",
                            "latitude": "32.27460000",
                            "longitude": "-94.97855000"
                        },
                        {
                            "id": 123657,
                            "name": "Ovilla",
                            "latitude": "32.52653000",
                            "longitude": "-96.88639000"
                        },
                        {
                            "id": 123695,
                            "name": "Oyster Creek",
                            "latitude": "29.00302000",
                            "longitude": "-95.33188000"
                        },
                        {
                            "id": 123701,
                            "name": "Ozona",
                            "latitude": "30.71017000",
                            "longitude": "-101.20067000"
                        },
                        {
                            "id": 123716,
                            "name": "Paducah",
                            "latitude": "34.01230000",
                            "longitude": "-100.30206000"
                        },
                        {
                            "id": 123726,
                            "name": "Paint Rock",
                            "latitude": "31.50849000",
                            "longitude": "-99.92008000"
                        },
                        {
                            "id": 123730,
                            "name": "Palacios",
                            "latitude": "28.70805000",
                            "longitude": "-96.21747000"
                        },
                        {
                            "id": 123737,
                            "name": "Palestine",
                            "latitude": "31.76212000",
                            "longitude": "-95.63079000"
                        },
                        {
                            "id": 123755,
                            "name": "Palm Valley",
                            "latitude": "26.20174000",
                            "longitude": "-97.75416000"
                        },
                        {
                            "id": 123758,
                            "name": "Palmer",
                            "latitude": "32.43125000",
                            "longitude": "-96.66777000"
                        },
                        {
                            "id": 123768,
                            "name": "Palmhurst",
                            "latitude": "26.25841000",
                            "longitude": "-98.31807000"
                        },
                        {
                            "id": 123770,
                            "name": "Palmview",
                            "latitude": "26.23341000",
                            "longitude": "-98.37085000"
                        },
                        {
                            "id": 123771,
                            "name": "Palmview South",
                            "latitude": "26.21563000",
                            "longitude": "-98.37863000"
                        },
                        {
                            "id": 123784,
                            "name": "Palo Pinto",
                            "latitude": "32.76735000",
                            "longitude": "-98.29866000"
                        },
                        {
                            "id": 123785,
                            "name": "Palo Pinto County",
                            "latitude": "32.75318000",
                            "longitude": "-98.31302000"
                        },
                        {
                            "id": 123786,
                            "name": "Paloma Creek",
                            "latitude": "33.22530000",
                            "longitude": "-96.93742000"
                        },
                        {
                            "id": 123787,
                            "name": "Paloma Creek South",
                            "latitude": "33.21136000",
                            "longitude": "-96.93554000"
                        },
                        {
                            "id": 123794,
                            "name": "Pampa",
                            "latitude": "35.53616000",
                            "longitude": "-100.95987000"
                        },
                        {
                            "id": 123801,
                            "name": "Panhandle",
                            "latitude": "35.34560000",
                            "longitude": "-101.38044000"
                        },
                        {
                            "id": 123804,
                            "name": "Panola County",
                            "latitude": "32.16236000",
                            "longitude": "-94.30565000"
                        },
                        {
                            "id": 123806,
                            "name": "Panorama Village",
                            "latitude": "30.38104000",
                            "longitude": "-95.49355000"
                        },
                        {
                            "id": 123807,
                            "name": "Pantego",
                            "latitude": "32.71430000",
                            "longitude": "-97.15640000"
                        },
                        {
                            "id": 123834,
                            "name": "Paris",
                            "latitude": "33.66094000",
                            "longitude": "-95.55551000"
                        },
                        {
                            "id": 123860,
                            "name": "Parker",
                            "latitude": "33.05540000",
                            "longitude": "-96.62194000"
                        },
                        {
                            "id": 123865,
                            "name": "Parker County",
                            "latitude": "32.77765000",
                            "longitude": "-97.80510000"
                        },
                        {
                            "id": 123886,
                            "name": "Parmer County",
                            "latitude": "34.53010000",
                            "longitude": "-102.78452000"
                        },
                        {
                            "id": 123896,
                            "name": "Pasadena",
                            "latitude": "29.69106000",
                            "longitude": "-95.20910000"
                        },
                        {
                            "id": 123919,
                            "name": "Patton Village",
                            "latitude": "30.19299000",
                            "longitude": "-95.16882000"
                        },
                        {
                            "id": 123970,
                            "name": "Pearland",
                            "latitude": "29.56357000",
                            "longitude": "-95.28605000"
                        },
                        {
                            "id": 123972,
                            "name": "Pearsall",
                            "latitude": "28.89219000",
                            "longitude": "-99.09503000"
                        },
                        {
                            "id": 123975,
                            "name": "Pecan Acres",
                            "latitude": "32.97013000",
                            "longitude": "-97.47474000"
                        },
                        {
                            "id": 123976,
                            "name": "Pecan Grove",
                            "latitude": "29.62607000",
                            "longitude": "-95.73162000"
                        },
                        {
                            "id": 123977,
                            "name": "Pecan Plantation",
                            "latitude": "32.36042000",
                            "longitude": "-97.67558000"
                        },
                        {
                            "id": 123980,
                            "name": "Pecos",
                            "latitude": "31.42291000",
                            "longitude": "-103.49323000"
                        },
                        {
                            "id": 123981,
                            "name": "Pecos County",
                            "latitude": "30.78106000",
                            "longitude": "-102.72357000"
                        },
                        {
                            "id": 123995,
                            "name": "Pelican Bay",
                            "latitude": "32.92096000",
                            "longitude": "-97.51836000"
                        },
                        {
                            "id": 124021,
                            "name": "Penitas",
                            "latitude": "26.23063000",
                            "longitude": "-98.44474000"
                        },
                        {
                            "id": 124058,
                            "name": "Perezville",
                            "latitude": "26.22452000",
                            "longitude": "-98.40057000"
                        },
                        {
                            "id": 124087,
                            "name": "Perryton",
                            "latitude": "36.40003000",
                            "longitude": "-100.80265000"
                        },
                        {
                            "id": 124109,
                            "name": "Petersburg",
                            "latitude": "33.86952000",
                            "longitude": "-101.59739000"
                        },
                        {
                            "id": 124117,
                            "name": "Pflugerville",
                            "latitude": "30.43937000",
                            "longitude": "-97.62000000"
                        },
                        {
                            "id": 124118,
                            "name": "Pharr",
                            "latitude": "26.19480000",
                            "longitude": "-98.18362000"
                        },
                        {
                            "id": 124201,
                            "name": "Pilot Point",
                            "latitude": "33.39650000",
                            "longitude": "-96.96056000"
                        },
                        {
                            "id": 124227,
                            "name": "Pine Island",
                            "latitude": "30.05800000",
                            "longitude": "-96.03746000"
                        },
                        {
                            "id": 124251,
                            "name": "Pinehurst",
                            "latitude": "30.17105000",
                            "longitude": "-95.68245000"
                        },
                        {
                            "id": 124265,
                            "name": "Pinewood Estates",
                            "latitude": "30.16438000",
                            "longitude": "-94.32158000"
                        },
                        {
                            "id": 124268,
                            "name": "Piney Point Village",
                            "latitude": "29.75995000",
                            "longitude": "-95.51716000"
                        },
                        {
                            "id": 124293,
                            "name": "Pittsburg",
                            "latitude": "32.99540000",
                            "longitude": "-94.96577000"
                        },
                        {
                            "id": 124324,
                            "name": "Plains",
                            "latitude": "33.18871000",
                            "longitude": "-102.82799000"
                        },
                        {
                            "id": 124331,
                            "name": "Plainview",
                            "latitude": "34.18479000",
                            "longitude": "-101.70684000"
                        },
                        {
                            "id": 124343,
                            "name": "Plano",
                            "latitude": "33.01984000",
                            "longitude": "-96.69889000"
                        },
                        {
                            "id": 124363,
                            "name": "Pleak",
                            "latitude": "29.48913000",
                            "longitude": "-95.80773000"
                        },
                        {
                            "id": 124383,
                            "name": "Pleasanton",
                            "latitude": "28.96719000",
                            "longitude": "-98.47863000"
                        },
                        {
                            "id": 124394,
                            "name": "Plum Grove",
                            "latitude": "32.19570000",
                            "longitude": "-96.98945000"
                        },
                        {
                            "id": 124446,
                            "name": "Polk County",
                            "latitude": "30.79272000",
                            "longitude": "-94.83002000"
                        },
                        {
                            "id": 124467,
                            "name": "Ponder",
                            "latitude": "33.18290000",
                            "longitude": "-97.28724000"
                        },
                        {
                            "id": 124495,
                            "name": "Port Aransas",
                            "latitude": "27.83392000",
                            "longitude": "-97.06110000"
                        },
                        {
                            "id": 124496,
                            "name": "Port Arthur",
                            "latitude": "29.88519000",
                            "longitude": "-93.94233000"
                        },
                        {
                            "id": 124512,
                            "name": "Port Isabel",
                            "latitude": "26.07341000",
                            "longitude": "-97.20858000"
                        },
                        {
                            "id": 124517,
                            "name": "Port Lavaca",
                            "latitude": "28.61500000",
                            "longitude": "-96.62609000"
                        },
                        {
                            "id": 124521,
                            "name": "Port Neches",
                            "latitude": "29.99132000",
                            "longitude": "-93.95851000"
                        },
                        {
                            "id": 124523,
                            "name": "Port O'Connor",
                            "latitude": "28.44834000",
                            "longitude": "-96.40581000"
                        },
                        {
                            "id": 124554,
                            "name": "Porter Heights",
                            "latitude": "30.15188000",
                            "longitude": "-95.32188000"
                        },
                        {
                            "id": 124557,
                            "name": "Portland",
                            "latitude": "27.87725000",
                            "longitude": "-97.32388000"
                        },
                        {
                            "id": 124576,
                            "name": "Post",
                            "latitude": "33.19122000",
                            "longitude": "-101.37900000"
                        },
                        {
                            "id": 124580,
                            "name": "Poteet",
                            "latitude": "29.04052000",
                            "longitude": "-98.56807000"
                        },
                        {
                            "id": 124581,
                            "name": "Poth",
                            "latitude": "29.06969000",
                            "longitude": "-98.08195000"
                        },
                        {
                            "id": 124587,
                            "name": "Potosi",
                            "latitude": "32.32930000",
                            "longitude": "-99.65647000"
                        },
                        {
                            "id": 124594,
                            "name": "Potter County",
                            "latitude": "35.40127000",
                            "longitude": "-101.89400000"
                        },
                        {
                            "id": 124597,
                            "name": "Pottsboro",
                            "latitude": "33.75927000",
                            "longitude": "-96.66944000"
                        },
                        {
                            "id": 124609,
                            "name": "Powderly",
                            "latitude": "33.81122000",
                            "longitude": "-95.52440000"
                        },
                        {
                            "id": 124631,
                            "name": "Prairie View",
                            "latitude": "30.09327000",
                            "longitude": "-95.98773000"
                        },
                        {
                            "id": 124639,
                            "name": "Premont",
                            "latitude": "27.36059000",
                            "longitude": "-98.12362000"
                        },
                        {
                            "id": 124647,
                            "name": "Presidio",
                            "latitude": "29.56074000",
                            "longitude": "-104.37215000"
                        },
                        {
                            "id": 124648,
                            "name": "Presidio County",
                            "latitude": "29.99980000",
                            "longitude": "-104.24052000"
                        },
                        {
                            "id": 124654,
                            "name": "Preston",
                            "latitude": "33.88232000",
                            "longitude": "-96.63305000"
                        },
                        {
                            "id": 124670,
                            "name": "Primera",
                            "latitude": "26.22591000",
                            "longitude": "-97.75805000"
                        },
                        {
                            "id": 124684,
                            "name": "Princeton",
                            "latitude": "33.18012000",
                            "longitude": "-96.49804000"
                        },
                        {
                            "id": 124701,
                            "name": "Progreso",
                            "latitude": "26.09230000",
                            "longitude": "-97.95722000"
                        },
                        {
                            "id": 124711,
                            "name": "Prosper",
                            "latitude": "33.23623000",
                            "longitude": "-96.80111000"
                        },
                        {
                            "id": 124718,
                            "name": "Providence",
                            "latitude": "33.23340000",
                            "longitude": "-96.96158000"
                        },
                        {
                            "id": 124775,
                            "name": "Quail Creek",
                            "latitude": "28.77640000",
                            "longitude": "-97.08232000"
                        },
                        {
                            "id": 124779,
                            "name": "Quanah",
                            "latitude": "34.29785000",
                            "longitude": "-99.74037000"
                        },
                        {
                            "id": 124787,
                            "name": "Queen City",
                            "latitude": "33.14874000",
                            "longitude": "-94.15019000"
                        },
                        {
                            "id": 124802,
                            "name": "Quinlan",
                            "latitude": "32.91040000",
                            "longitude": "-96.13553000"
                        },
                        {
                            "id": 124807,
                            "name": "Quitman",
                            "latitude": "32.79596000",
                            "longitude": "-95.45106000"
                        },
                        {
                            "id": 124828,
                            "name": "Rains County",
                            "latitude": "32.87034000",
                            "longitude": "-95.79338000"
                        },
                        {
                            "id": 124834,
                            "name": "Ralls",
                            "latitude": "33.67425000",
                            "longitude": "-101.38766000"
                        },
                        {
                            "id": 124847,
                            "name": "Rancho Alegre",
                            "latitude": "27.74169000",
                            "longitude": "-98.09473000"
                        },
                        {
                            "id": 124859,
                            "name": "Rancho Viejo",
                            "latitude": "26.03952000",
                            "longitude": "-97.55638000"
                        },
                        {
                            "id": 124862,
                            "name": "Randall County",
                            "latitude": "34.96585000",
                            "longitude": "-101.89695000"
                        },
                        {
                            "id": 124882,
                            "name": "Ranger",
                            "latitude": "32.46985000",
                            "longitude": "-98.67895000"
                        },
                        {
                            "id": 124884,
                            "name": "Rankin",
                            "latitude": "31.22265000",
                            "longitude": "-101.93791000"
                        },
                        {
                            "id": 124887,
                            "name": "Ransom Canyon",
                            "latitude": "33.53342000",
                            "longitude": "-101.67961000"
                        },
                        {
                            "id": 124921,
                            "name": "Raymondville",
                            "latitude": "26.48146000",
                            "longitude": "-97.78305000"
                        },
                        {
                            "id": 124932,
                            "name": "Reagan County",
                            "latitude": "31.36619000",
                            "longitude": "-101.52301000"
                        },
                        {
                            "id": 124933,
                            "name": "Real County",
                            "latitude": "29.83174000",
                            "longitude": "-99.82217000"
                        },
                        {
                            "id": 124952,
                            "name": "Red Lick",
                            "latitude": "33.46485000",
                            "longitude": "-94.17103000"
                        },
                        {
                            "id": 124956,
                            "name": "Red Oak",
                            "latitude": "32.51764000",
                            "longitude": "-96.80444000"
                        },
                        {
                            "id": 124959,
                            "name": "Red River County",
                            "latitude": "33.62068000",
                            "longitude": "-95.05036000"
                        },
                        {
                            "id": 124975,
                            "name": "Redland",
                            "latitude": "31.40435000",
                            "longitude": "-94.72132000"
                        },
                        {
                            "id": 124982,
                            "name": "Redwater",
                            "latitude": "33.35818000",
                            "longitude": "-94.25436000"
                        },
                        {
                            "id": 124984,
                            "name": "Redwood",
                            "latitude": "29.81022000",
                            "longitude": "-97.91139000"
                        },
                        {
                            "id": 124998,
                            "name": "Reeves County",
                            "latitude": "31.32318000",
                            "longitude": "-103.69317000"
                        },
                        {
                            "id": 125000,
                            "name": "Refugio",
                            "latitude": "28.30528000",
                            "longitude": "-97.27527000"
                        },
                        {
                            "id": 125001,
                            "name": "Refugio County",
                            "latitude": "28.32158000",
                            "longitude": "-97.15952000"
                        },
                        {
                            "id": 125017,
                            "name": "Rendon",
                            "latitude": "32.57625000",
                            "longitude": "-97.24140000"
                        },
                        {
                            "id": 125019,
                            "name": "Reno",
                            "latitude": "33.66316000",
                            "longitude": "-95.46245000"
                        },
                        {
                            "id": 125048,
                            "name": "Rhome",
                            "latitude": "33.05346000",
                            "longitude": "-97.47197000"
                        },
                        {
                            "id": 125051,
                            "name": "Ricardo",
                            "latitude": "27.42143000",
                            "longitude": "-97.85111000"
                        },
                        {
                            "id": 125058,
                            "name": "Richardson",
                            "latitude": "32.94818000",
                            "longitude": "-96.72972000"
                        },
                        {
                            "id": 125079,
                            "name": "Richland Hills",
                            "latitude": "32.81596000",
                            "longitude": "-97.22807000"
                        },
                        {
                            "id": 125087,
                            "name": "Richmond",
                            "latitude": "29.58218000",
                            "longitude": "-95.76078000"
                        },
                        {
                            "id": 125111,
                            "name": "Richwood",
                            "latitude": "29.05608000",
                            "longitude": "-95.40994000"
                        },
                        {
                            "id": 125135,
                            "name": "Riesel",
                            "latitude": "31.47489000",
                            "longitude": "-96.92333000"
                        },
                        {
                            "id": 125149,
                            "name": "Rio Bravo",
                            "latitude": "27.36419000",
                            "longitude": "-99.48004000"
                        },
                        {
                            "id": 125154,
                            "name": "Rio Grande City",
                            "latitude": "26.37979000",
                            "longitude": "-98.82030000"
                        },
                        {
                            "id": 125156,
                            "name": "Rio Hondo",
                            "latitude": "26.23535000",
                            "longitude": "-97.58193000"
                        },
                        {
                            "id": 125183,
                            "name": "River Oaks",
                            "latitude": "32.77707000",
                            "longitude": "-97.39446000"
                        },
                        {
                            "id": 125227,
                            "name": "Roanoke",
                            "latitude": "33.00401000",
                            "longitude": "-97.22585000"
                        },
                        {
                            "id": 125240,
                            "name": "Robert Lee",
                            "latitude": "31.89237000",
                            "longitude": "-100.48482000"
                        },
                        {
                            "id": 125243,
                            "name": "Roberts County",
                            "latitude": "35.83849000",
                            "longitude": "-100.81344000"
                        },
                        {
                            "id": 125247,
                            "name": "Robertson County",
                            "latitude": "31.02704000",
                            "longitude": "-96.51279000"
                        },
                        {
                            "id": 125254,
                            "name": "Robinson",
                            "latitude": "31.46767000",
                            "longitude": "-97.11472000"
                        },
                        {
                            "id": 125256,
                            "name": "Robstown",
                            "latitude": "27.79030000",
                            "longitude": "-97.66888000"
                        },
                        {
                            "id": 125257,
                            "name": "Roby",
                            "latitude": "32.74483000",
                            "longitude": "-100.37761000"
                        },
                        {
                            "id": 125295,
                            "name": "Rockdale",
                            "latitude": "30.65548000",
                            "longitude": "-97.00137000"
                        },
                        {
                            "id": 125316,
                            "name": "Rockport",
                            "latitude": "28.02077000",
                            "longitude": "-97.05601000"
                        },
                        {
                            "id": 125319,
                            "name": "Rocksprings",
                            "latitude": "30.01576000",
                            "longitude": "-100.20536000"
                        },
                        {
                            "id": 125326,
                            "name": "Rockwall",
                            "latitude": "32.93123000",
                            "longitude": "-96.45971000"
                        },
                        {
                            "id": 125327,
                            "name": "Rockwall County",
                            "latitude": "32.89773000",
                            "longitude": "-96.40777000"
                        },
                        {
                            "id": 125349,
                            "name": "Rogers",
                            "latitude": "30.93157000",
                            "longitude": "-97.22666000"
                        },
                        {
                            "id": 125369,
                            "name": "Rollingwood",
                            "latitude": "30.27687000",
                            "longitude": "-97.79112000"
                        },
                        {
                            "id": 125372,
                            "name": "Roma",
                            "latitude": "26.40526000",
                            "longitude": "-99.01581000"
                        },
                        {
                            "id": 125373,
                            "name": "Roma-Los Saenz",
                            "latitude": "26.40506000",
                            "longitude": "-99.01586000"
                        },
                        {
                            "id": 125374,
                            "name": "Roman Forest",
                            "latitude": "30.17910000",
                            "longitude": "-95.16243000"
                        },
                        {
                            "id": 125400,
                            "name": "Roscoe",
                            "latitude": "32.44595000",
                            "longitude": "-100.53872000"
                        },
                        {
                            "id": 125411,
                            "name": "Rosebud",
                            "latitude": "31.07296000",
                            "longitude": "-96.97860000"
                        },
                        {
                            "id": 125433,
                            "name": "Rosenberg",
                            "latitude": "29.55718000",
                            "longitude": "-95.80856000"
                        },
                        {
                            "id": 125443,
                            "name": "Rosharon",
                            "latitude": "29.35218000",
                            "longitude": "-95.46022000"
                        },
                        {
                            "id": 125445,
                            "name": "Rosita North",
                            "latitude": "28.65644000",
                            "longitude": "-100.42218000"
                        },
                        {
                            "id": 125446,
                            "name": "Rosita South",
                            "latitude": "28.62375000",
                            "longitude": "-100.42837000"
                        },
                        {
                            "id": 125464,
                            "name": "Rotan",
                            "latitude": "32.85205000",
                            "longitude": "-100.46566000"
                        },
                        {
                            "id": 125473,
                            "name": "Round Rock",
                            "latitude": "30.50826000",
                            "longitude": "-97.67890000"
                        },
                        {
                            "id": 125481,
                            "name": "Rowlett",
                            "latitude": "32.90290000",
                            "longitude": "-96.56388000"
                        },
                        {
                            "id": 125499,
                            "name": "Royse City",
                            "latitude": "32.97512000",
                            "longitude": "-96.33248000"
                        },
                        {
                            "id": 125510,
                            "name": "Runaway Bay",
                            "latitude": "33.16789000",
                            "longitude": "-97.87837000"
                        },
                        {
                            "id": 125511,
                            "name": "Runge",
                            "latitude": "28.88331000",
                            "longitude": "-97.71305000"
                        },
                        {
                            "id": 125512,
                            "name": "Runnels County",
                            "latitude": "31.83110000",
                            "longitude": "-99.97623000"
                        },
                        {
                            "id": 125528,
                            "name": "Rusk",
                            "latitude": "31.79601000",
                            "longitude": "-95.15022000"
                        },
                        {
                            "id": 125529,
                            "name": "Rusk County",
                            "latitude": "32.10772000",
                            "longitude": "-94.76188000"
                        },
                        {
                            "id": 125568,
                            "name": "Sabinal",
                            "latitude": "29.31746000",
                            "longitude": "-99.46644000"
                        },
                        {
                            "id": 125569,
                            "name": "Sabine County",
                            "latitude": "31.34320000",
                            "longitude": "-93.85180000"
                        },
                        {
                            "id": 125574,
                            "name": "Sachse",
                            "latitude": "32.97623000",
                            "longitude": "-96.59527000"
                        },
                        {
                            "id": 125587,
                            "name": "Saginaw",
                            "latitude": "32.86013000",
                            "longitude": "-97.36391000"
                        },
                        {
                            "id": 125645,
                            "name": "Saint Hedwig",
                            "latitude": "29.41440000",
                            "longitude": "-98.20001000"
                        },
                        {
                            "id": 125659,
                            "name": "Saint Jo",
                            "latitude": "33.69483000",
                            "longitude": "-97.52252000"
                        },
                        {
                            "id": 125702,
                            "name": "Saint Paul",
                            "latitude": "33.04123000",
                            "longitude": "-96.55027000"
                        },
                        {
                            "id": 125722,
                            "name": "Salado",
                            "latitude": "30.94713000",
                            "longitude": "-97.53862000"
                        },
                        {
                            "id": 125777,
                            "name": "Sam Rayburn",
                            "latitude": "31.06408000",
                            "longitude": "-94.03575000"
                        },
                        {
                            "id": 125784,
                            "name": "San Angelo",
                            "latitude": "31.46377000",
                            "longitude": "-100.43704000"
                        },
                        {
                            "id": 125787,
                            "name": "San Antonio",
                            "latitude": "29.42412000",
                            "longitude": "-98.49363000"
                        },
                        {
                            "id": 125789,
                            "name": "San Augustine",
                            "latitude": "31.52990000",
                            "longitude": "-94.10603000"
                        },
                        {
                            "id": 125790,
                            "name": "San Augustine County",
                            "latitude": "31.39422000",
                            "longitude": "-94.16819000"
                        },
                        {
                            "id": 125791,
                            "name": "San Benito",
                            "latitude": "26.13258000",
                            "longitude": "-97.63110000"
                        },
                        {
                            "id": 125796,
                            "name": "San Carlos",
                            "latitude": "26.29563000",
                            "longitude": "-98.07195000"
                        },
                        {
                            "id": 125801,
                            "name": "San Diego",
                            "latitude": "27.76391000",
                            "longitude": "-98.23890000"
                        },
                        {
                            "id": 125806,
                            "name": "San Elizario",
                            "latitude": "31.58511000",
                            "longitude": "-106.27276000"
                        },
                        {
                            "id": 125812,
                            "name": "San Jacinto County",
                            "latitude": "30.57953000",
                            "longitude": "-95.16690000"
                        },
                        {
                            "id": 125817,
                            "name": "San Juan",
                            "latitude": "26.18924000",
                            "longitude": "-98.15529000"
                        },
                        {
                            "id": 125825,
                            "name": "San Leon",
                            "latitude": "29.48329000",
                            "longitude": "-94.92215000"
                        },
                        {
                            "id": 125832,
                            "name": "San Marcos",
                            "latitude": "29.88327000",
                            "longitude": "-97.94139000"
                        },
                        {
                            "id": 125844,
                            "name": "San Patricio County",
                            "latitude": "28.00878000",
                            "longitude": "-97.51827000"
                        },
                        {
                            "id": 125848,
                            "name": "San Saba",
                            "latitude": "31.19572000",
                            "longitude": "-98.71810000"
                        },
                        {
                            "id": 125849,
                            "name": "San Saba County",
                            "latitude": "31.15520000",
                            "longitude": "-98.81758000"
                        },
                        {
                            "id": 125864,
                            "name": "Sanderson",
                            "latitude": "30.14241000",
                            "longitude": "-102.39403000"
                        },
                        {
                            "id": 125891,
                            "name": "Sanger",
                            "latitude": "33.36317000",
                            "longitude": "-97.17390000"
                        },
                        {
                            "id": 125898,
                            "name": "Sansom Park",
                            "latitude": "32.80596000",
                            "longitude": "-97.40307000"
                        },
                        {
                            "id": 125900,
                            "name": "Santa Anna",
                            "latitude": "31.74209000",
                            "longitude": "-99.32173000"
                        },
                        {
                            "id": 125913,
                            "name": "Santa Fe",
                            "latitude": "29.37801000",
                            "longitude": "-95.10576000"
                        },
                        {
                            "id": 125921,
                            "name": "Santa Rosa",
                            "latitude": "26.25674000",
                            "longitude": "-97.82500000"
                        },
                        {
                            "id": 125951,
                            "name": "Sarita",
                            "latitude": "27.22171000",
                            "longitude": "-97.78916000"
                        },
                        {
                            "id": 125977,
                            "name": "Savannah",
                            "latitude": "33.22603000",
                            "longitude": "-96.90786000"
                        },
                        {
                            "id": 126000,
                            "name": "Scenic Oaks",
                            "latitude": "29.71078000",
                            "longitude": "-98.67586000"
                        },
                        {
                            "id": 126006,
                            "name": "Schertz",
                            "latitude": "29.55217000",
                            "longitude": "-98.26973000"
                        },
                        {
                            "id": 126008,
                            "name": "Schleicher County",
                            "latitude": "30.89745000",
                            "longitude": "-100.53855000"
                        },
                        {
                            "id": 126020,
                            "name": "Schulenburg",
                            "latitude": "29.68190000",
                            "longitude": "-96.90304000"
                        },
                        {
                            "id": 126029,
                            "name": "Scissors",
                            "latitude": "26.13980000",
                            "longitude": "-98.05389000"
                        },
                        {
                            "id": 126069,
                            "name": "Scurry County",
                            "latitude": "32.74632000",
                            "longitude": "-100.91641000"
                        },
                        {
                            "id": 126079,
                            "name": "Seabrook",
                            "latitude": "29.56412000",
                            "longitude": "-95.02548000"
                        },
                        {
                            "id": 126084,
                            "name": "Seadrift",
                            "latitude": "28.41528000",
                            "longitude": "-96.71359000"
                        },
                        {
                            "id": 126088,
                            "name": "Seagoville",
                            "latitude": "32.63958000",
                            "longitude": "-96.53832000"
                        },
                        {
                            "id": 126089,
                            "name": "Seagraves",
                            "latitude": "32.94427000",
                            "longitude": "-102.56491000"
                        },
                        {
                            "id": 126091,
                            "name": "Sealy",
                            "latitude": "29.78079000",
                            "longitude": "-96.15718000"
                        },
                        {
                            "id": 126106,
                            "name": "Sebastian",
                            "latitude": "26.34285000",
                            "longitude": "-97.79027000"
                        },
                        {
                            "id": 126128,
                            "name": "Seguin",
                            "latitude": "29.56884000",
                            "longitude": "-97.96473000"
                        },
                        {
                            "id": 126140,
                            "name": "Selma",
                            "latitude": "29.58439000",
                            "longitude": "-98.30585000"
                        },
                        {
                            "id": 126146,
                            "name": "Seminole",
                            "latitude": "32.71899000",
                            "longitude": "-102.64491000"
                        },
                        {
                            "id": 126166,
                            "name": "Serenada",
                            "latitude": "30.69936000",
                            "longitude": "-97.69195000"
                        },
                        {
                            "id": 126170,
                            "name": "Seth Ward",
                            "latitude": "34.21174000",
                            "longitude": "-101.69017000"
                        },
                        {
                            "id": 126175,
                            "name": "Seven Points",
                            "latitude": "32.32042000",
                            "longitude": "-96.21303000"
                        },
                        {
                            "id": 126195,
                            "name": "Seymour",
                            "latitude": "33.59426000",
                            "longitude": "-99.26035000"
                        },
                        {
                            "id": 126199,
                            "name": "Shackelford County",
                            "latitude": "32.73597000",
                            "longitude": "-99.35407000"
                        },
                        {
                            "id": 126205,
                            "name": "Shady Hollow",
                            "latitude": "30.16493000",
                            "longitude": "-97.86223000"
                        },
                        {
                            "id": 126206,
                            "name": "Shady Shores",
                            "latitude": "33.16512000",
                            "longitude": "-97.02945000"
                        },
                        {
                            "id": 126213,
                            "name": "Shallowater",
                            "latitude": "33.68897000",
                            "longitude": "-101.99823000"
                        },
                        {
                            "id": 126216,
                            "name": "Shamrock",
                            "latitude": "35.21422000",
                            "longitude": "-100.24901000"
                        },
                        {
                            "id": 126242,
                            "name": "Shavano Park",
                            "latitude": "29.58495000",
                            "longitude": "-98.55252000"
                        },
                        {
                            "id": 126278,
                            "name": "Shelby County",
                            "latitude": "31.79241000",
                            "longitude": "-94.14502000"
                        },
                        {
                            "id": 126285,
                            "name": "Sheldon",
                            "latitude": "29.86800000",
                            "longitude": "-95.12826000"
                        },
                        {
                            "id": 126301,
                            "name": "Shenandoah",
                            "latitude": "30.18022000",
                            "longitude": "-95.45577000"
                        },
                        {
                            "id": 126309,
                            "name": "Shepherd",
                            "latitude": "30.49798000",
                            "longitude": "-94.99660000"
                        },
                        {
                            "id": 126330,
                            "name": "Sherman",
                            "latitude": "33.63566000",
                            "longitude": "-96.60888000"
                        },
                        {
                            "id": 126334,
                            "name": "Sherman County",
                            "latitude": "36.27771000",
                            "longitude": "-101.89348000"
                        },
                        {
                            "id": 126344,
                            "name": "Sherwood Shores",
                            "latitude": "33.85232000",
                            "longitude": "-96.81778000"
                        },
                        {
                            "id": 126350,
                            "name": "Shiner",
                            "latitude": "29.42913000",
                            "longitude": "-97.17054000"
                        },
                        {
                            "id": 126367,
                            "name": "Shoreacres",
                            "latitude": "29.62023000",
                            "longitude": "-95.00993000"
                        },
                        {
                            "id": 126400,
                            "name": "Sienna Plantation",
                            "latitude": "29.48607000",
                            "longitude": "-95.50800000"
                        },
                        {
                            "id": 126401,
                            "name": "Sierra Blanca",
                            "latitude": "31.17457000",
                            "longitude": "-105.35718000"
                        },
                        {
                            "id": 126408,
                            "name": "Siesta Acres",
                            "latitude": "28.75799000",
                            "longitude": "-100.49019000"
                        },
                        {
                            "id": 126410,
                            "name": "Siesta Shores",
                            "latitude": "26.85810000",
                            "longitude": "-99.25365000"
                        },
                        {
                            "id": 126418,
                            "name": "Silsbee",
                            "latitude": "30.34910000",
                            "longitude": "-94.17796000"
                        },
                        {
                            "id": 126443,
                            "name": "Silverton",
                            "latitude": "34.47423000",
                            "longitude": "-101.30461000"
                        },
                        {
                            "id": 126456,
                            "name": "Sinton",
                            "latitude": "28.03668000",
                            "longitude": "-97.50916000"
                        },
                        {
                            "id": 126493,
                            "name": "Slaton",
                            "latitude": "33.43731000",
                            "longitude": "-101.64349000"
                        },
                        {
                            "id": 126515,
                            "name": "Smith County",
                            "latitude": "32.37504000",
                            "longitude": "-95.26918000"
                        },
                        {
                            "id": 126529,
                            "name": "Smithville",
                            "latitude": "30.00855000",
                            "longitude": "-97.15943000"
                        },
                        {
                            "id": 126550,
                            "name": "Snyder",
                            "latitude": "32.71789000",
                            "longitude": "-100.91762000"
                        },
                        {
                            "id": 126558,
                            "name": "Socorro",
                            "latitude": "31.65456000",
                            "longitude": "-106.30331000"
                        },
                        {
                            "id": 126560,
                            "name": "Socorro Mission Number 1 Colonia",
                            "latitude": "31.63622000",
                            "longitude": "-106.29054000"
                        },
                        {
                            "id": 126581,
                            "name": "Somerset",
                            "latitude": "29.22635000",
                            "longitude": "-98.65780000"
                        },
                        {
                            "id": 126592,
                            "name": "Somervell County",
                            "latitude": "32.22229000",
                            "longitude": "-97.77434000"
                        },
                        {
                            "id": 126594,
                            "name": "Somerville",
                            "latitude": "30.34604000",
                            "longitude": "-96.52830000"
                        },
                        {
                            "id": 126601,
                            "name": "Sonora",
                            "latitude": "30.56685000",
                            "longitude": "-100.64343000"
                        },
                        {
                            "id": 126610,
                            "name": "Sour Lake",
                            "latitude": "30.14021000",
                            "longitude": "-94.41102000"
                        },
                        {
                            "id": 126611,
                            "name": "South Alamo",
                            "latitude": "26.15702000",
                            "longitude": "-98.10862000"
                        },
                        {
                            "id": 126676,
                            "name": "South Houston",
                            "latitude": "29.66301000",
                            "longitude": "-95.23549000"
                        },
                        {
                            "id": 126700,
                            "name": "South Padre Island",
                            "latitude": "26.10369000",
                            "longitude": "-97.16469000"
                        },
                        {
                            "id": 126712,
                            "name": "South Point",
                            "latitude": "25.86869000",
                            "longitude": "-97.38359000"
                        },
                        {
                            "id": 126777,
                            "name": "Southlake",
                            "latitude": "32.94124000",
                            "longitude": "-97.13418000"
                        },
                        {
                            "id": 126778,
                            "name": "Southmayd",
                            "latitude": "33.63038000",
                            "longitude": "-96.76916000"
                        },
                        {
                            "id": 126788,
                            "name": "Southside Place",
                            "latitude": "29.70606000",
                            "longitude": "-95.43688000"
                        },
                        {
                            "id": 126804,
                            "name": "Sparks",
                            "latitude": "31.67261000",
                            "longitude": "-106.23970000"
                        },
                        {
                            "id": 126817,
                            "name": "Spearman",
                            "latitude": "36.19837000",
                            "longitude": "-101.19238000"
                        },
                        {
                            "id": 126841,
                            "name": "Splendora",
                            "latitude": "30.23299000",
                            "longitude": "-95.16104000"
                        },
                        {
                            "id": 126849,
                            "name": "Spring",
                            "latitude": "30.07994000",
                            "longitude": "-95.41716000"
                        },
                        {
                            "id": 126874,
                            "name": "Spring Valley",
                            "latitude": "29.78967000",
                            "longitude": "-95.50355000"
                        },
                        {
                            "id": 126911,
                            "name": "Springtown",
                            "latitude": "32.96596000",
                            "longitude": "-97.68364000"
                        },
                        {
                            "id": 126921,
                            "name": "Spur",
                            "latitude": "33.47648000",
                            "longitude": "-100.85569000"
                        },
                        {
                            "id": 126932,
                            "name": "Stafford",
                            "latitude": "29.61607000",
                            "longitude": "-95.55772000"
                        },
                        {
                            "id": 126943,
                            "name": "Stamford",
                            "latitude": "32.94539000",
                            "longitude": "-99.80287000"
                        },
                        {
                            "id": 126971,
                            "name": "Stanton",
                            "latitude": "32.12929000",
                            "longitude": "-101.78846000"
                        },
                        {
                            "id": 126990,
                            "name": "Starr County",
                            "latitude": "26.56215000",
                            "longitude": "-98.73840000"
                        },
                        {
                            "id": 127023,
                            "name": "Stephens County",
                            "latitude": "32.73586000",
                            "longitude": "-98.83617000"
                        },
                        {
                            "id": 127025,
                            "name": "Stephenville",
                            "latitude": "32.22070000",
                            "longitude": "-98.20226000"
                        },
                        {
                            "id": 127032,
                            "name": "Sterling City",
                            "latitude": "31.83625000",
                            "longitude": "-100.98483000"
                        },
                        {
                            "id": 127033,
                            "name": "Sterling County",
                            "latitude": "31.82788000",
                            "longitude": "-101.05001000"
                        },
                        {
                            "id": 127065,
                            "name": "Stinnett",
                            "latitude": "35.82698000",
                            "longitude": "-101.44294000"
                        },
                        {
                            "id": 127070,
                            "name": "Stockdale",
                            "latitude": "29.23691000",
                            "longitude": "-97.96000000"
                        },
                        {
                            "id": 127093,
                            "name": "Stonewall County",
                            "latitude": "33.17912000",
                            "longitude": "-100.25331000"
                        },
                        {
                            "id": 127115,
                            "name": "Stowell",
                            "latitude": "29.78994000",
                            "longitude": "-94.38324000"
                        },
                        {
                            "id": 127128,
                            "name": "Stratford",
                            "latitude": "36.33614000",
                            "longitude": "-102.07212000"
                        },
                        {
                            "id": 127173,
                            "name": "Sugar Land",
                            "latitude": "29.61968000",
                            "longitude": "-95.63495000"
                        },
                        {
                            "id": 127185,
                            "name": "Sullivan City",
                            "latitude": "26.27757000",
                            "longitude": "-98.56363000"
                        },
                        {
                            "id": 127197,
                            "name": "Sulphur Springs",
                            "latitude": "33.13845000",
                            "longitude": "-95.60107000"
                        },
                        {
                            "id": 127250,
                            "name": "Sundown",
                            "latitude": "33.45621000",
                            "longitude": "-102.48936000"
                        },
                        {
                            "id": 127264,
                            "name": "Sunnyvale",
                            "latitude": "32.79652000",
                            "longitude": "-96.56082000"
                        },
                        {
                            "id": 127266,
                            "name": "Sunray",
                            "latitude": "36.01670000",
                            "longitude": "-101.82462000"
                        },
                        {
                            "id": 127315,
                            "name": "Sutton County",
                            "latitude": "30.49829000",
                            "longitude": "-100.53819000"
                        },
                        {
                            "id": 127335,
                            "name": "Sweeny",
                            "latitude": "29.03886000",
                            "longitude": "-95.69856000"
                        },
                        {
                            "id": 127342,
                            "name": "Sweetwater",
                            "latitude": "32.47095000",
                            "longitude": "-100.40594000"
                        },
                        {
                            "id": 127347,
                            "name": "Swisher County",
                            "latitude": "34.53039000",
                            "longitude": "-101.73503000"
                        },
                        {
                            "id": 127372,
                            "name": "Taft",
                            "latitude": "27.97891000",
                            "longitude": "-97.39860000"
                        },
                        {
                            "id": 127376,
                            "name": "Taft Southwest (historical)",
                            "latitude": "27.97391000",
                            "longitude": "-97.40305000"
                        },
                        {
                            "id": 127379,
                            "name": "Tahoka",
                            "latitude": "33.16676000",
                            "longitude": "-101.79377000"
                        },
                        {
                            "id": 127399,
                            "name": "Talty",
                            "latitude": "32.68319000",
                            "longitude": "-96.38554000"
                        },
                        {
                            "id": 127435,
                            "name": "Tarrant County",
                            "latitude": "32.77156000",
                            "longitude": "-97.29124000"
                        },
                        {
                            "id": 127440,
                            "name": "Tatum",
                            "latitude": "32.31599000",
                            "longitude": "-94.51659000"
                        },
                        {
                            "id": 127446,
                            "name": "Taylor",
                            "latitude": "30.57076000",
                            "longitude": "-97.40944000"
                        },
                        {
                            "id": 127454,
                            "name": "Taylor County",
                            "latitude": "32.30147000",
                            "longitude": "-99.89012000"
                        },
                        {
                            "id": 127459,
                            "name": "Taylor Lake Village",
                            "latitude": "29.57523000",
                            "longitude": "-95.05021000"
                        },
                        {
                            "id": 127474,
                            "name": "Teague",
                            "latitude": "31.62711000",
                            "longitude": "-96.28386000"
                        },
                        {
                            "id": 127498,
                            "name": "Temple",
                            "latitude": "31.09823000",
                            "longitude": "-97.34278000"
                        },
                        {
                            "id": 127508,
                            "name": "Tenaha",
                            "latitude": "31.94378000",
                            "longitude": "-94.24409000"
                        },
                        {
                            "id": 127526,
                            "name": "Terrell",
                            "latitude": "32.73596000",
                            "longitude": "-96.27526000"
                        },
                        {
                            "id": 127528,
                            "name": "Terrell County",
                            "latitude": "30.22496000",
                            "longitude": "-102.07649000"
                        },
                        {
                            "id": 127529,
                            "name": "Terrell Hills",
                            "latitude": "29.47495000",
                            "longitude": "-98.45085000"
                        },
                        {
                            "id": 127532,
                            "name": "Terry County",
                            "latitude": "33.17381000",
                            "longitude": "-102.33520000"
                        },
                        {
                            "id": 127545,
                            "name": "Texarkana",
                            "latitude": "33.42513000",
                            "longitude": "-94.04769000"
                        },
                        {
                            "id": 127546,
                            "name": "Texas City",
                            "latitude": "29.38385000",
                            "longitude": "-94.90270000"
                        },
                        {
                            "id": 127555,
                            "name": "The Colony",
                            "latitude": "33.08901000",
                            "longitude": "-96.88639000"
                        },
                        {
                            "id": 127561,
                            "name": "The Hills",
                            "latitude": "30.34798000",
                            "longitude": "-97.98501000"
                        },
                        {
                            "id": 127566,
                            "name": "The Woodlands",
                            "latitude": "30.15799000",
                            "longitude": "-95.48938000"
                        },
                        {
                            "id": 127600,
                            "name": "Thorndale",
                            "latitude": "30.61381000",
                            "longitude": "-97.20555000"
                        },
                        {
                            "id": 127617,
                            "name": "Three Rivers",
                            "latitude": "28.46027000",
                            "longitude": "-98.18251000"
                        },
                        {
                            "id": 127623,
                            "name": "Throckmorton",
                            "latitude": "33.17872000",
                            "longitude": "-99.17758000"
                        },
                        {
                            "id": 127624,
                            "name": "Throckmorton County",
                            "latitude": "33.17749000",
                            "longitude": "-99.21237000"
                        },
                        {
                            "id": 127646,
                            "name": "Tiki Island",
                            "latitude": "29.29709000",
                            "longitude": "-94.91709000"
                        },
                        {
                            "id": 127647,
                            "name": "Tilden",
                            "latitude": "28.46194000",
                            "longitude": "-98.54918000"
                        },
                        {
                            "id": 127661,
                            "name": "Timberwood Park",
                            "latitude": "29.70578000",
                            "longitude": "-98.47835000"
                        },
                        {
                            "id": 127664,
                            "name": "Timpson",
                            "latitude": "31.90378000",
                            "longitude": "-94.39520000"
                        },
                        {
                            "id": 127684,
                            "name": "Titus County",
                            "latitude": "33.21660000",
                            "longitude": "-94.96567000"
                        },
                        {
                            "id": 127706,
                            "name": "Tom Bean",
                            "latitude": "33.52011000",
                            "longitude": "-96.48387000"
                        },
                        {
                            "id": 127707,
                            "name": "Tom Green County",
                            "latitude": "31.40440000",
                            "longitude": "-100.46207000"
                        },
                        {
                            "id": 127710,
                            "name": "Tomball",
                            "latitude": "30.09716000",
                            "longitude": "-95.61605000"
                        },
                        {
                            "id": 127727,
                            "name": "Tool",
                            "latitude": "32.26792000",
                            "longitude": "-96.17025000"
                        },
                        {
                            "id": 127740,
                            "name": "Tornillo",
                            "latitude": "31.44540000",
                            "longitude": "-106.08831000"
                        },
                        {
                            "id": 127787,
                            "name": "Travis County",
                            "latitude": "30.33469000",
                            "longitude": "-97.78195000"
                        },
                        {
                            "id": 127788,
                            "name": "Travis Ranch",
                            "latitude": "32.80356000",
                            "longitude": "-96.47347000"
                        },
                        {
                            "id": 127827,
                            "name": "Trinity",
                            "latitude": "30.94519000",
                            "longitude": "-95.37550000"
                        },
                        {
                            "id": 127828,
                            "name": "Trinity County",
                            "latitude": "31.08882000",
                            "longitude": "-95.13551000"
                        },
                        {
                            "id": 127834,
                            "name": "Trophy Club",
                            "latitude": "32.99790000",
                            "longitude": "-97.18362000"
                        },
                        {
                            "id": 127835,
                            "name": "Troup",
                            "latitude": "32.14460000",
                            "longitude": "-95.12050000"
                        },
                        {
                            "id": 127846,
                            "name": "Troy",
                            "latitude": "31.20684000",
                            "longitude": "-97.30278000"
                        },
                        {
                            "id": 127885,
                            "name": "Tulia",
                            "latitude": "34.53589000",
                            "longitude": "-101.75852000"
                        },
                        {
                            "id": 127938,
                            "name": "Tye",
                            "latitude": "32.45762000",
                            "longitude": "-99.87148000"
                        },
                        {
                            "id": 127940,
                            "name": "Tyler",
                            "latitude": "32.35126000",
                            "longitude": "-95.30106000"
                        },
                        {
                            "id": 127942,
                            "name": "Tyler County",
                            "latitude": "30.77123000",
                            "longitude": "-94.37659000"
                        },
                        {
                            "id": 127954,
                            "name": "Uhland",
                            "latitude": "29.95772000",
                            "longitude": "-97.78611000"
                        },
                        {
                            "id": 128022,
                            "name": "Universal City",
                            "latitude": "29.54801000",
                            "longitude": "-98.29112000"
                        },
                        {
                            "id": 128033,
                            "name": "University Park",
                            "latitude": "32.85013000",
                            "longitude": "-96.80028000"
                        },
                        {
                            "id": 128052,
                            "name": "Upshur County",
                            "latitude": "32.73628000",
                            "longitude": "-94.94148000"
                        },
                        {
                            "id": 128057,
                            "name": "Upton County",
                            "latitude": "31.36873000",
                            "longitude": "-102.04304000"
                        },
                        {
                            "id": 128068,
                            "name": "Uvalde",
                            "latitude": "29.20968000",
                            "longitude": "-99.78617000"
                        },
                        {
                            "id": 128069,
                            "name": "Uvalde County",
                            "latitude": "29.35734000",
                            "longitude": "-99.76221000"
                        },
                        {
                            "id": 128070,
                            "name": "Uvalde Estates",
                            "latitude": "29.16524000",
                            "longitude": "-99.83228000"
                        },
                        {
                            "id": 128079,
                            "name": "Val Verde County",
                            "latitude": "29.89296000",
                            "longitude": "-101.15172000"
                        },
                        {
                            "id": 128080,
                            "name": "Val Verde Park",
                            "latitude": "29.37495000",
                            "longitude": "-100.83176000"
                        },
                        {
                            "id": 128113,
                            "name": "Valley Mills",
                            "latitude": "31.65933000",
                            "longitude": "-97.47224000"
                        },
                        {
                            "id": 128124,
                            "name": "Van",
                            "latitude": "32.52486000",
                            "longitude": "-95.63718000"
                        },
                        {
                            "id": 128125,
                            "name": "Van Alstyne",
                            "latitude": "33.42150000",
                            "longitude": "-96.57721000"
                        },
                        {
                            "id": 128133,
                            "name": "Van Horn",
                            "latitude": "31.04029000",
                            "longitude": "-104.83073000"
                        },
                        {
                            "id": 128138,
                            "name": "Van Vleck",
                            "latitude": "29.01775000",
                            "longitude": "-95.88940000"
                        },
                        {
                            "id": 128139,
                            "name": "Van Zandt County",
                            "latitude": "32.56372000",
                            "longitude": "-95.83651000"
                        },
                        {
                            "id": 128162,
                            "name": "Vega",
                            "latitude": "35.24283000",
                            "longitude": "-102.42826000"
                        },
                        {
                            "id": 128177,
                            "name": "Venus",
                            "latitude": "32.43347000",
                            "longitude": "-97.10251000"
                        },
                        {
                            "id": 128189,
                            "name": "Vernon",
                            "latitude": "34.15536000",
                            "longitude": "-99.26628000"
                        },
                        {
                            "id": 128220,
                            "name": "Victoria",
                            "latitude": "28.80527000",
                            "longitude": "-97.00360000"
                        },
                        {
                            "id": 128223,
                            "name": "Victoria County",
                            "latitude": "28.79635000",
                            "longitude": "-96.97153000"
                        },
                        {
                            "id": 128229,
                            "name": "Vidor",
                            "latitude": "30.13160000",
                            "longitude": "-94.01545000"
                        },
                        {
                            "id": 128282,
                            "name": "Vinton",
                            "latitude": "31.95121000",
                            "longitude": "-106.60249000"
                        },
                        {
                            "id": 128304,
                            "name": "Von Ormy",
                            "latitude": "29.28913000",
                            "longitude": "-98.64446000"
                        },
                        {
                            "id": 128316,
                            "name": "Waco",
                            "latitude": "31.54933000",
                            "longitude": "-97.14667000"
                        },
                        {
                            "id": 128358,
                            "name": "Wake Village",
                            "latitude": "33.42679000",
                            "longitude": "-94.10630000"
                        },
                        {
                            "id": 128388,
                            "name": "Walker County",
                            "latitude": "30.73905000",
                            "longitude": "-95.57228000"
                        },
                        {
                            "id": 128403,
                            "name": "Waller",
                            "latitude": "30.05661000",
                            "longitude": "-95.92690000"
                        },
                        {
                            "id": 128405,
                            "name": "Waller County",
                            "latitude": "30.01081000",
                            "longitude": "-95.98765000"
                        },
                        {
                            "id": 128409,
                            "name": "Wallis",
                            "latitude": "29.63135000",
                            "longitude": "-96.06524000"
                        },
                        {
                            "id": 128458,
                            "name": "Ward County",
                            "latitude": "31.50947000",
                            "longitude": "-103.10243000"
                        },
                        {
                            "id": 128566,
                            "name": "Washington County",
                            "latitude": "30.21453000",
                            "longitude": "-96.40344000"
                        },
                        {
                            "id": 128594,
                            "name": "Waskom",
                            "latitude": "32.47876000",
                            "longitude": "-94.05963000"
                        },
                        {
                            "id": 128595,
                            "name": "Watauga",
                            "latitude": "32.85791000",
                            "longitude": "-97.25474000"
                        },
                        {
                            "id": 128674,
                            "name": "Waxahachie",
                            "latitude": "32.38653000",
                            "longitude": "-96.84833000"
                        },
                        {
                            "id": 128718,
                            "name": "Weatherford",
                            "latitude": "32.75930000",
                            "longitude": "-97.79725000"
                        },
                        {
                            "id": 128726,
                            "name": "Webb County",
                            "latitude": "27.76106000",
                            "longitude": "-99.33157000"
                        },
                        {
                            "id": 128730,
                            "name": "Webster",
                            "latitude": "29.53773000",
                            "longitude": "-95.11826000"
                        },
                        {
                            "id": 128758,
                            "name": "Weimar",
                            "latitude": "29.70301000",
                            "longitude": "-96.78053000"
                        },
                        {
                            "id": 128780,
                            "name": "Wellington",
                            "latitude": "34.85617000",
                            "longitude": "-100.21373000"
                        },
                        {
                            "id": 128785,
                            "name": "Wells Branch",
                            "latitude": "30.44604000",
                            "longitude": "-97.67945000"
                        },
                        {
                            "id": 128808,
                            "name": "Weslaco",
                            "latitude": "26.15952000",
                            "longitude": "-97.99084000"
                        },
                        {
                            "id": 128815,
                            "name": "West",
                            "latitude": "31.80238000",
                            "longitude": "-97.09167000"
                        },
                        {
                            "id": 128849,
                            "name": "West Columbia",
                            "latitude": "29.14386000",
                            "longitude": "-95.64522000"
                        },
                        {
                            "id": 128909,
                            "name": "West Lake Hills",
                            "latitude": "30.29798000",
                            "longitude": "-97.80195000"
                        },
                        {
                            "id": 128921,
                            "name": "West Livingston",
                            "latitude": "30.69825000",
                            "longitude": "-95.00188000"
                        },
                        {
                            "id": 128944,
                            "name": "West Odessa",
                            "latitude": "31.84235000",
                            "longitude": "-102.49876000"
                        },
                        {
                            "id": 128945,
                            "name": "West Orange",
                            "latitude": "30.08215000",
                            "longitude": "-93.75822000"
                        },
                        {
                            "id": 128980,
                            "name": "West Sharyland",
                            "latitude": "26.27257000",
                            "longitude": "-98.32863000"
                        },
                        {
                            "id": 128988,
                            "name": "West Tawakoni",
                            "latitude": "32.89373000",
                            "longitude": "-96.02941000"
                        },
                        {
                            "id": 128995,
                            "name": "West University Place",
                            "latitude": "29.71801000",
                            "longitude": "-95.43383000"
                        },
                        {
                            "id": 129024,
                            "name": "Western Lake",
                            "latitude": "32.62338000",
                            "longitude": "-97.81155000"
                        },
                        {
                            "id": 129039,
                            "name": "Westlake",
                            "latitude": "32.99124000",
                            "longitude": "-97.19501000"
                        },
                        {
                            "id": 129065,
                            "name": "Weston Lakes",
                            "latitude": "29.68324000",
                            "longitude": "-95.93571000"
                        },
                        {
                            "id": 129080,
                            "name": "Westway",
                            "latitude": "31.95871000",
                            "longitude": "-106.57805000"
                        },
                        {
                            "id": 129089,
                            "name": "Westworth",
                            "latitude": "32.75735000",
                            "longitude": "-97.41085000"
                        },
                        {
                            "id": 129102,
                            "name": "Wharton",
                            "latitude": "29.31164000",
                            "longitude": "-96.10274000"
                        },
                        {
                            "id": 129104,
                            "name": "Wharton County",
                            "latitude": "29.27786000",
                            "longitude": "-96.22210000"
                        },
                        {
                            "id": 129115,
                            "name": "Wheeler",
                            "latitude": "35.44533000",
                            "longitude": "-100.27096000"
                        },
                        {
                            "id": 129118,
                            "name": "Wheeler County",
                            "latitude": "35.40128000",
                            "longitude": "-100.26965000"
                        },
                        {
                            "id": 129149,
                            "name": "White Oak",
                            "latitude": "32.52792000",
                            "longitude": "-94.86133000"
                        },
                        {
                            "id": 129161,
                            "name": "White Settlement",
                            "latitude": "32.75957000",
                            "longitude": "-97.45835000"
                        },
                        {
                            "id": 129174,
                            "name": "Whitehouse",
                            "latitude": "32.22682000",
                            "longitude": "-95.22550000"
                        },
                        {
                            "id": 129182,
                            "name": "Whitesboro",
                            "latitude": "33.65622000",
                            "longitude": "-96.90695000"
                        },
                        {
                            "id": 129192,
                            "name": "Whitewright",
                            "latitude": "33.51289000",
                            "longitude": "-96.39248000"
                        },
                        {
                            "id": 129208,
                            "name": "Whitney",
                            "latitude": "31.95182000",
                            "longitude": "-97.32140000"
                        },
                        {
                            "id": 129216,
                            "name": "Wichita County",
                            "latitude": "33.98798000",
                            "longitude": "-98.70361000"
                        },
                        {
                            "id": 129218,
                            "name": "Wichita Falls",
                            "latitude": "33.91371000",
                            "longitude": "-98.49339000"
                        },
                        {
                            "id": 129224,
                            "name": "Wilbarger County",
                            "latitude": "34.08072000",
                            "longitude": "-99.24108000"
                        },
                        {
                            "id": 129230,
                            "name": "Wild Peach Village",
                            "latitude": "29.08358000",
                            "longitude": "-95.63384000"
                        },
                        {
                            "id": 129240,
                            "name": "Wildwood",
                            "latitude": "30.52409000",
                            "longitude": "-94.44158000"
                        },
                        {
                            "id": 129255,
                            "name": "Willacy County",
                            "latitude": "26.47701000",
                            "longitude": "-97.59182000"
                        },
                        {
                            "id": 129278,
                            "name": "Williamson County",
                            "latitude": "30.64804000",
                            "longitude": "-97.60076000"
                        },
                        {
                            "id": 129295,
                            "name": "Willis",
                            "latitude": "30.42493000",
                            "longitude": "-95.47994000"
                        },
                        {
                            "id": 129308,
                            "name": "Willow Park",
                            "latitude": "32.76263000",
                            "longitude": "-97.65058000"
                        },
                        {
                            "id": 129317,
                            "name": "Wills Point",
                            "latitude": "32.70930000",
                            "longitude": "-96.00830000"
                        },
                        {
                            "id": 129318,
                            "name": "Wilmer",
                            "latitude": "32.58902000",
                            "longitude": "-96.68527000"
                        },
                        {
                            "id": 129337,
                            "name": "Wilson County",
                            "latitude": "29.17401000",
                            "longitude": "-98.08657000"
                        },
                        {
                            "id": 129349,
                            "name": "Wimberley",
                            "latitude": "29.99744000",
                            "longitude": "-98.09862000"
                        },
                        {
                            "id": 129367,
                            "name": "Windcrest",
                            "latitude": "29.51551000",
                            "longitude": "-98.38029000"
                        },
                        {
                            "id": 129368,
                            "name": "Windemere",
                            "latitude": "30.45909000",
                            "longitude": "-97.64917000"
                        },
                        {
                            "id": 129401,
                            "name": "Wink",
                            "latitude": "31.75124000",
                            "longitude": "-103.15989000"
                        },
                        {
                            "id": 129402,
                            "name": "Winkler County",
                            "latitude": "31.85005000",
                            "longitude": "-103.04817000"
                        },
                        {
                            "id": 129417,
                            "name": "Winnie",
                            "latitude": "29.82022000",
                            "longitude": "-94.38408000"
                        },
                        {
                            "id": 129420,
                            "name": "Winnsboro",
                            "latitude": "32.95734000",
                            "longitude": "-95.29022000"
                        },
                        {
                            "id": 129444,
                            "name": "Winters",
                            "latitude": "31.95653000",
                            "longitude": "-99.96231000"
                        },
                        {
                            "id": 129460,
                            "name": "Wise County",
                            "latitude": "33.21590000",
                            "longitude": "-97.65445000"
                        },
                        {
                            "id": 129476,
                            "name": "Wolfe City",
                            "latitude": "33.37067000",
                            "longitude": "-96.06886000"
                        },
                        {
                            "id": 129479,
                            "name": "Wolfforth",
                            "latitude": "33.50592000",
                            "longitude": "-102.00906000"
                        },
                        {
                            "id": 129484,
                            "name": "Wood County",
                            "latitude": "32.78641000",
                            "longitude": "-95.38206000"
                        },
                        {
                            "id": 129498,
                            "name": "Woodbranch",
                            "latitude": "30.18105000",
                            "longitude": "-95.18882000"
                        },
                        {
                            "id": 129515,
                            "name": "Woodcreek",
                            "latitude": "30.02827000",
                            "longitude": "-98.11112000"
                        },
                        {
                            "id": 129553,
                            "name": "Woodsboro",
                            "latitude": "28.23834000",
                            "longitude": "-97.31999000"
                        },
                        {
                            "id": 129572,
                            "name": "Woodville",
                            "latitude": "30.77520000",
                            "longitude": "-94.41548000"
                        },
                        {
                            "id": 129578,
                            "name": "Woodway",
                            "latitude": "31.50600000",
                            "longitude": "-97.20501000"
                        },
                        {
                            "id": 129595,
                            "name": "Wortham",
                            "latitude": "31.78794000",
                            "longitude": "-96.46248000"
                        },
                        {
                            "id": 129625,
                            "name": "Wyldwood",
                            "latitude": "30.12938000",
                            "longitude": "-97.47277000"
                        },
                        {
                            "id": 129626,
                            "name": "Wylie",
                            "latitude": "33.01512000",
                            "longitude": "-96.53888000"
                        },
                        {
                            "id": 129683,
                            "name": "Yoakum",
                            "latitude": "29.28775000",
                            "longitude": "-97.15193000"
                        },
                        {
                            "id": 129684,
                            "name": "Yoakum County",
                            "latitude": "33.17297000",
                            "longitude": "-102.82780000"
                        },
                        {
                            "id": 129706,
                            "name": "Yorktown",
                            "latitude": "28.98109000",
                            "longitude": "-97.50277000"
                        },
                        {
                            "id": 129714,
                            "name": "Young County",
                            "latitude": "33.17670000",
                            "longitude": "-98.68777000"
                        },
                        {
                            "id": 129739,
                            "name": "Zapata",
                            "latitude": "26.90726000",
                            "longitude": "-99.27143000"
                        },
                        {
                            "id": 129740,
                            "name": "Zapata County",
                            "latitude": "27.00078000",
                            "longitude": "-99.16861000"
                        },
                        {
                            "id": 129741,
                            "name": "Zavala County",
                            "latitude": "28.86621000",
                            "longitude": "-99.76060000"
                        }
                    ]
                },
                {
                    "id": 1432,
                    "name": "United States Minor Outlying Islands",
                    "state_code": "UM",
                    "cities": []
                },
                {
                    "id": 1413,
                    "name": "United States Virgin Islands",
                    "state_code": "VI",
                    "cities": []
                },
                {
                    "id": 1414,
                    "name": "Utah",
                    "state_code": "UT",
                    "cities": [
                        {
                            "id": 111225,
                            "name": "Alpine",
                            "latitude": "40.45328000",
                            "longitude": "-111.77799000"
                        },
                        {
                            "id": 111273,
                            "name": "American Fork",
                            "latitude": "40.37690000",
                            "longitude": "-111.79576000"
                        },
                        {
                            "id": 111663,
                            "name": "Aurora",
                            "latitude": "38.92219000",
                            "longitude": "-111.93409000"
                        },
                        {
                            "id": 111770,
                            "name": "Ballard",
                            "latitude": "40.28940000",
                            "longitude": "-109.94320000"
                        },
                        {
                            "id": 111995,
                            "name": "Beaver",
                            "latitude": "38.27691000",
                            "longitude": "-112.64105000"
                        },
                        {
                            "id": 111999,
                            "name": "Beaver County",
                            "latitude": "38.35771000",
                            "longitude": "-113.23576000"
                        },
                        {
                            "id": 112170,
                            "name": "Benjamin",
                            "latitude": "40.09829000",
                            "longitude": "-111.73132000"
                        },
                        {
                            "id": 112185,
                            "name": "Benson",
                            "latitude": "41.78743000",
                            "longitude": "-111.93022000"
                        },
                        {
                            "id": 112422,
                            "name": "Blanding",
                            "latitude": "37.62433000",
                            "longitude": "-109.47966000"
                        },
                        {
                            "id": 112482,
                            "name": "Bluffdale",
                            "latitude": "40.48967000",
                            "longitude": "-111.93882000"
                        },
                        {
                            "id": 112610,
                            "name": "Bountiful",
                            "latitude": "40.88939000",
                            "longitude": "-111.88077000"
                        },
                        {
                            "id": 112637,
                            "name": "Box Elder County",
                            "latitude": "41.52097000",
                            "longitude": "-113.08209000"
                        },
                        {
                            "id": 112784,
                            "name": "Brigham City",
                            "latitude": "41.51021000",
                            "longitude": "-112.01550000"
                        },
                        {
                            "id": 113156,
                            "name": "Cache County",
                            "latitude": "41.72237000",
                            "longitude": "-111.74356000"
                        },
                        {
                            "id": 113360,
                            "name": "Canyon Rim",
                            "latitude": "40.70661000",
                            "longitude": "-111.82188000"
                        },
                        {
                            "id": 113383,
                            "name": "Carbon County",
                            "latitude": "39.64817000",
                            "longitude": "-110.58898000"
                        },
                        {
                            "id": 113391,
                            "name": "Carbonville",
                            "latitude": "39.61996000",
                            "longitude": "-110.83433000"
                        },
                        {
                            "id": 113543,
                            "name": "Castle Dale",
                            "latitude": "39.21219000",
                            "longitude": "-111.01961000"
                        },
                        {
                            "id": 113603,
                            "name": "Cedar City",
                            "latitude": "37.67748000",
                            "longitude": "-113.06189000"
                        },
                        {
                            "id": 113616,
                            "name": "Cedar Hills",
                            "latitude": "40.41412000",
                            "longitude": "-111.75854000"
                        },
                        {
                            "id": 113649,
                            "name": "Centerfield",
                            "latitude": "39.12524000",
                            "longitude": "-111.81909000"
                        },
                        {
                            "id": 113662,
                            "name": "Centerville",
                            "latitude": "40.91800000",
                            "longitude": "-111.87216000"
                        },
                        {
                            "id": 114217,
                            "name": "Clearfield",
                            "latitude": "41.11078000",
                            "longitude": "-112.02605000"
                        },
                        {
                            "id": 114288,
                            "name": "Clinton",
                            "latitude": "41.13967000",
                            "longitude": "-112.05050000"
                        },
                        {
                            "id": 114339,
                            "name": "Coalville",
                            "latitude": "40.91773000",
                            "longitude": "-111.39936000"
                        },
                        {
                            "id": 114705,
                            "name": "Cottonwood Heights",
                            "latitude": "40.61967000",
                            "longitude": "-111.81021000"
                        },
                        {
                            "id": 114969,
                            "name": "Daggett County",
                            "latitude": "40.88727000",
                            "longitude": "-109.50765000"
                        },
                        {
                            "id": 115022,
                            "name": "Daniel",
                            "latitude": "40.47079000",
                            "longitude": "-111.41463000"
                        },
                        {
                            "id": 115075,
                            "name": "Davis County",
                            "latitude": "40.99061000",
                            "longitude": "-112.11124000"
                        },
                        {
                            "id": 115223,
                            "name": "Delta",
                            "latitude": "39.35218000",
                            "longitude": "-112.57717000"
                        },
                        {
                            "id": 115469,
                            "name": "Draper",
                            "latitude": "40.52467000",
                            "longitude": "-111.86382000"
                        },
                        {
                            "id": 115502,
                            "name": "Duchesne",
                            "latitude": "40.16329000",
                            "longitude": "-110.40293000"
                        },
                        {
                            "id": 115503,
                            "name": "Duchesne County",
                            "latitude": "40.29751000",
                            "longitude": "-110.42476000"
                        },
                        {
                            "id": 115607,
                            "name": "Eagle Mountain",
                            "latitude": "40.31412000",
                            "longitude": "-112.00688000"
                        },
                        {
                            "id": 115642,
                            "name": "East Carbon City",
                            "latitude": "39.54774000",
                            "longitude": "-110.41488000"
                        },
                        {
                            "id": 115712,
                            "name": "East Millcreek",
                            "latitude": "40.69995000",
                            "longitude": "-111.81049000"
                        },
                        {
                            "id": 115992,
                            "name": "Elk Ridge",
                            "latitude": "40.01134000",
                            "longitude": "-111.67687000"
                        },
                        {
                            "id": 116083,
                            "name": "Elwood",
                            "latitude": "41.69048000",
                            "longitude": "-112.14106000"
                        },
                        {
                            "id": 116096,
                            "name": "Emery County",
                            "latitude": "38.99677000",
                            "longitude": "-110.70067000"
                        },
                        {
                            "id": 116142,
                            "name": "Enoch",
                            "latitude": "37.77331000",
                            "longitude": "-113.02439000"
                        },
                        {
                            "id": 116151,
                            "name": "Enterprise",
                            "latitude": "37.57359000",
                            "longitude": "-113.71913000"
                        },
                        {
                            "id": 116154,
                            "name": "Ephraim",
                            "latitude": "39.35968000",
                            "longitude": "-111.58631000"
                        },
                        {
                            "id": 116162,
                            "name": "Erda",
                            "latitude": "40.61272000",
                            "longitude": "-112.30439000"
                        },
                        {
                            "id": 116357,
                            "name": "Fairview",
                            "latitude": "39.62635000",
                            "longitude": "-111.43963000"
                        },
                        {
                            "id": 116422,
                            "name": "Farmington",
                            "latitude": "40.98050000",
                            "longitude": "-111.88744000"
                        },
                        {
                            "id": 116428,
                            "name": "Farr West",
                            "latitude": "41.29717000",
                            "longitude": "-112.02772000"
                        },
                        {
                            "id": 116496,
                            "name": "Ferron",
                            "latitude": "39.09358000",
                            "longitude": "-111.13322000"
                        },
                        {
                            "id": 116509,
                            "name": "Fillmore",
                            "latitude": "38.96885000",
                            "longitude": "-112.32355000"
                        },
                        {
                            "id": 116780,
                            "name": "Fountain Green",
                            "latitude": "39.62996000",
                            "longitude": "-111.63520000"
                        },
                        {
                            "id": 116814,
                            "name": "Francis",
                            "latitude": "40.61051000",
                            "longitude": "-111.28074000"
                        },
                        {
                            "id": 116967,
                            "name": "Fruit Heights",
                            "latitude": "41.03217000",
                            "longitude": "-111.90216000"
                        },
                        {
                            "id": 117085,
                            "name": "Garfield County",
                            "latitude": "37.85492000",
                            "longitude": "-111.44313000"
                        },
                        {
                            "id": 117090,
                            "name": "Garland",
                            "latitude": "41.74104000",
                            "longitude": "-112.16162000"
                        },
                        {
                            "id": 117149,
                            "name": "Genola",
                            "latitude": "39.99634000",
                            "longitude": "-111.84327000"
                        },
                        {
                            "id": 117455,
                            "name": "Grand County",
                            "latitude": "38.98174000",
                            "longitude": "-109.56971000"
                        },
                        {
                            "id": 117492,
                            "name": "Granite",
                            "latitude": "40.57300000",
                            "longitude": "-111.80604000"
                        },
                        {
                            "id": 117531,
                            "name": "Grantsville",
                            "latitude": "40.59994000",
                            "longitude": "-112.46440000"
                        },
                        {
                            "id": 117803,
                            "name": "Gunnison",
                            "latitude": "39.15524000",
                            "longitude": "-111.81826000"
                        },
                        {
                            "id": 118077,
                            "name": "Harrisville",
                            "latitude": "41.28133000",
                            "longitude": "-111.98828000"
                        },
                        {
                            "id": 118229,
                            "name": "Heber City",
                            "latitude": "40.50690000",
                            "longitude": "-111.41324000"
                        },
                        {
                            "id": 118262,
                            "name": "Helper",
                            "latitude": "39.68413000",
                            "longitude": "-110.85461000"
                        },
                        {
                            "id": 118338,
                            "name": "Herriman",
                            "latitude": "40.51411000",
                            "longitude": "-112.03299000"
                        },
                        {
                            "id": 118400,
                            "name": "Highland",
                            "latitude": "40.42548000",
                            "longitude": "-111.79447000"
                        },
                        {
                            "id": 118429,
                            "name": "Hildale",
                            "latitude": "37.00360000",
                            "longitude": "-112.96688000"
                        },
                        {
                            "id": 118433,
                            "name": "Hill Air Force Base",
                            "latitude": "41.11118000",
                            "longitude": "-111.97712000"
                        },
                        {
                            "id": 118536,
                            "name": "Holladay",
                            "latitude": "40.66884000",
                            "longitude": "-111.82466000"
                        },
                        {
                            "id": 118621,
                            "name": "Honeyville",
                            "latitude": "41.63854000",
                            "longitude": "-112.07939000"
                        },
                        {
                            "id": 118633,
                            "name": "Hooper",
                            "latitude": "41.16383000",
                            "longitude": "-112.12244000"
                        },
                        {
                            "id": 118803,
                            "name": "Huntington",
                            "latitude": "39.32664000",
                            "longitude": "-110.96461000"
                        },
                        {
                            "id": 118828,
                            "name": "Hurricane",
                            "latitude": "37.17526000",
                            "longitude": "-113.28995000"
                        },
                        {
                            "id": 118853,
                            "name": "Hyde Park",
                            "latitude": "41.79882000",
                            "longitude": "-111.81911000"
                        },
                        {
                            "id": 118857,
                            "name": "Hyrum",
                            "latitude": "41.63410000",
                            "longitude": "-111.85217000"
                        },
                        {
                            "id": 118991,
                            "name": "Iron County",
                            "latitude": "37.85917000",
                            "longitude": "-113.28927000"
                        },
                        {
                            "id": 119051,
                            "name": "Ivins",
                            "latitude": "37.16859000",
                            "longitude": "-113.67941000"
                        },
                        {
                            "id": 119325,
                            "name": "Juab County",
                            "latitude": "39.70262000",
                            "longitude": "-112.78477000"
                        },
                        {
                            "id": 119332,
                            "name": "Junction",
                            "latitude": "38.23748000",
                            "longitude": "-112.21993000"
                        },
                        {
                            "id": 119370,
                            "name": "Kamas",
                            "latitude": "40.64301000",
                            "longitude": "-111.28074000"
                        },
                        {
                            "id": 119372,
                            "name": "Kanab",
                            "latitude": "37.04749000",
                            "longitude": "-112.52631000"
                        },
                        {
                            "id": 119378,
                            "name": "Kane County",
                            "latitude": "37.28507000",
                            "longitude": "-111.88785000"
                        },
                        {
                            "id": 119403,
                            "name": "Kaysville",
                            "latitude": "41.03522000",
                            "longitude": "-111.93855000"
                        },
                        {
                            "id": 119411,
                            "name": "Kearns",
                            "latitude": "40.65995000",
                            "longitude": "-111.99633000"
                        },
                        {
                            "id": 119807,
                            "name": "LaVerkin",
                            "latitude": "37.20109000",
                            "longitude": "-113.26967000"
                        },
                        {
                            "id": 120254,
                            "name": "Layton",
                            "latitude": "41.06022000",
                            "longitude": "-111.97105000"
                        },
                        {
                            "id": 120333,
                            "name": "Lehi",
                            "latitude": "40.39162000",
                            "longitude": "-111.85077000"
                        },
                        {
                            "id": 120429,
                            "name": "Lewiston",
                            "latitude": "41.97576000",
                            "longitude": "-111.85634000"
                        },
                        {
                            "id": 120468,
                            "name": "Liberty",
                            "latitude": "41.33355000",
                            "longitude": "-111.86355000"
                        },
                        {
                            "id": 120571,
                            "name": "Lindon",
                            "latitude": "40.34329000",
                            "longitude": "-111.72076000"
                        },
                        {
                            "id": 120615,
                            "name": "Little Cottonwood Creek Valley",
                            "latitude": "40.60439000",
                            "longitude": "-111.82938000"
                        },
                        {
                            "id": 120668,
                            "name": "Loa",
                            "latitude": "38.40276000",
                            "longitude": "-111.64296000"
                        },
                        {
                            "id": 120695,
                            "name": "Logan",
                            "latitude": "41.73549000",
                            "longitude": "-111.83439000"
                        },
                        {
                            "id": 121030,
                            "name": "Maeser",
                            "latitude": "40.47718000",
                            "longitude": "-109.58681000"
                        },
                        {
                            "id": 121034,
                            "name": "Magna",
                            "latitude": "40.70911000",
                            "longitude": "-112.10161000"
                        },
                        {
                            "id": 121120,
                            "name": "Manila",
                            "latitude": "40.98801000",
                            "longitude": "-109.72265000"
                        },
                        {
                            "id": 121157,
                            "name": "Manti",
                            "latitude": "39.26830000",
                            "longitude": "-111.63686000"
                        },
                        {
                            "id": 121176,
                            "name": "Mapleton",
                            "latitude": "40.13023000",
                            "longitude": "-111.57853000"
                        },
                        {
                            "id": 121297,
                            "name": "Marriott-Slaterville",
                            "latitude": "41.25161000",
                            "longitude": "-112.02550000"
                        },
                        {
                            "id": 121655,
                            "name": "Mendon",
                            "latitude": "41.70993000",
                            "longitude": "-111.97773000"
                        },
                        {
                            "id": 121811,
                            "name": "Midvale",
                            "latitude": "40.61106000",
                            "longitude": "-111.89994000"
                        },
                        {
                            "id": 121819,
                            "name": "Midway",
                            "latitude": "40.51218000",
                            "longitude": "-111.47435000"
                        },
                        {
                            "id": 121859,
                            "name": "Milford",
                            "latitude": "38.39691000",
                            "longitude": "-113.01079000"
                        },
                        {
                            "id": 121869,
                            "name": "Millard County",
                            "latitude": "39.07381000",
                            "longitude": "-113.10046000"
                        },
                        {
                            "id": 121875,
                            "name": "Millcreek",
                            "latitude": "40.68689000",
                            "longitude": "-111.87549000"
                        },
                        {
                            "id": 121906,
                            "name": "Millville",
                            "latitude": "41.68160000",
                            "longitude": "-111.82300000"
                        },
                        {
                            "id": 122006,
                            "name": "Moab",
                            "latitude": "38.57332000",
                            "longitude": "-109.54984000"
                        },
                        {
                            "id": 122031,
                            "name": "Mona",
                            "latitude": "39.81607000",
                            "longitude": "-111.85549000"
                        },
                        {
                            "id": 122064,
                            "name": "Monroe",
                            "latitude": "38.62997000",
                            "longitude": "-112.12076000"
                        },
                        {
                            "id": 122171,
                            "name": "Monticello",
                            "latitude": "37.87138000",
                            "longitude": "-109.34289000"
                        },
                        {
                            "id": 122234,
                            "name": "Morgan",
                            "latitude": "41.03606000",
                            "longitude": "-111.67688000"
                        },
                        {
                            "id": 122245,
                            "name": "Morgan County",
                            "latitude": "41.08932000",
                            "longitude": "-111.57312000"
                        },
                        {
                            "id": 122262,
                            "name": "Moroni",
                            "latitude": "39.52496000",
                            "longitude": "-111.59047000"
                        },
                        {
                            "id": 122372,
                            "name": "Mount Olympus",
                            "latitude": "40.68550000",
                            "longitude": "-111.78854000"
                        },
                        {
                            "id": 122381,
                            "name": "Mount Pleasant",
                            "latitude": "39.54691000",
                            "longitude": "-111.45547000"
                        },
                        {
                            "id": 122413,
                            "name": "Mountain Green",
                            "latitude": "41.14300000",
                            "longitude": "-111.79160000"
                        },
                        {
                            "id": 122492,
                            "name": "Murray",
                            "latitude": "40.66689000",
                            "longitude": "-111.88799000"
                        },
                        {
                            "id": 122555,
                            "name": "Naples",
                            "latitude": "40.42691000",
                            "longitude": "-109.49930000"
                        },
                        {
                            "id": 122635,
                            "name": "Nephi",
                            "latitude": "39.71023000",
                            "longitude": "-111.83632000"
                        },
                        {
                            "id": 122884,
                            "name": "Nibley",
                            "latitude": "41.67438000",
                            "longitude": "-111.83300000"
                        },
                        {
                            "id": 123060,
                            "name": "North Logan",
                            "latitude": "41.76937000",
                            "longitude": "-111.80467000"
                        },
                        {
                            "id": 123074,
                            "name": "North Ogden",
                            "latitude": "41.30716000",
                            "longitude": "-111.96022000"
                        },
                        {
                            "id": 123099,
                            "name": "North Salt Lake",
                            "latitude": "40.84856000",
                            "longitude": "-111.90688000"
                        },
                        {
                            "id": 123300,
                            "name": "Oakley",
                            "latitude": "40.71467000",
                            "longitude": "-111.30074000"
                        },
                        {
                            "id": 123369,
                            "name": "Ogden",
                            "latitude": "41.22300000",
                            "longitude": "-111.97383000"
                        },
                        {
                            "id": 123501,
                            "name": "Oquirrh",
                            "latitude": "40.63050000",
                            "longitude": "-112.03383000"
                        },
                        {
                            "id": 123531,
                            "name": "Orangeville",
                            "latitude": "39.22719000",
                            "longitude": "-111.05350000"
                        },
                        {
                            "id": 123551,
                            "name": "Orem",
                            "latitude": "40.29690000",
                            "longitude": "-111.69465000"
                        },
                        {
                            "id": 123800,
                            "name": "Panguitch",
                            "latitude": "37.82276000",
                            "longitude": "-112.43576000"
                        },
                        {
                            "id": 123840,
                            "name": "Park City",
                            "latitude": "40.64606000",
                            "longitude": "-111.49797000"
                        },
                        {
                            "id": 123888,
                            "name": "Parowan",
                            "latitude": "37.84220000",
                            "longitude": "-112.82800000"
                        },
                        {
                            "id": 123950,
                            "name": "Payson",
                            "latitude": "40.04440000",
                            "longitude": "-111.73215000"
                        },
                        {
                            "id": 124073,
                            "name": "Perry",
                            "latitude": "41.46494000",
                            "longitude": "-112.03245000"
                        },
                        {
                            "id": 124307,
                            "name": "Piute County",
                            "latitude": "38.33645000",
                            "longitude": "-112.12695000"
                        },
                        {
                            "id": 124315,
                            "name": "Plain City",
                            "latitude": "41.29800000",
                            "longitude": "-112.08605000"
                        },
                        {
                            "id": 124367,
                            "name": "Pleasant Grove",
                            "latitude": "40.36412000",
                            "longitude": "-111.73854000"
                        },
                        {
                            "id": 124381,
                            "name": "Pleasant View",
                            "latitude": "41.31828000",
                            "longitude": "-111.99216000"
                        },
                        {
                            "id": 124663,
                            "name": "Price",
                            "latitude": "39.59941000",
                            "longitude": "-110.81071000"
                        },
                        {
                            "id": 124717,
                            "name": "Providence",
                            "latitude": "41.70632000",
                            "longitude": "-111.81717000"
                        },
                        {
                            "id": 124721,
                            "name": "Provo",
                            "latitude": "40.23384000",
                            "longitude": "-111.65853000"
                        },
                        {
                            "id": 124870,
                            "name": "Randolph",
                            "latitude": "41.66578000",
                            "longitude": "-111.18214000"
                        },
                        {
                            "id": 125056,
                            "name": "Rich County",
                            "latitude": "41.63232000",
                            "longitude": "-111.24445000"
                        },
                        {
                            "id": 125063,
                            "name": "Richfield",
                            "latitude": "38.77247000",
                            "longitude": "-112.08409000"
                        },
                        {
                            "id": 125097,
                            "name": "Richmond",
                            "latitude": "41.92271000",
                            "longitude": "-111.81356000"
                        },
                        {
                            "id": 125181,
                            "name": "River Heights",
                            "latitude": "41.72160000",
                            "longitude": "-111.82133000"
                        },
                        {
                            "id": 125197,
                            "name": "Riverdale",
                            "latitude": "41.17689000",
                            "longitude": "-112.00383000"
                        },
                        {
                            "id": 125215,
                            "name": "Riverton",
                            "latitude": "40.52189000",
                            "longitude": "-111.93910000"
                        },
                        {
                            "id": 125392,
                            "name": "Roosevelt",
                            "latitude": "40.29940000",
                            "longitude": "-109.98876000"
                        },
                        {
                            "id": 125487,
                            "name": "Roy",
                            "latitude": "41.16161000",
                            "longitude": "-112.02633000"
                        },
                        {
                            "id": 125644,
                            "name": "Saint George",
                            "latitude": "37.10415000",
                            "longitude": "-113.58412000"
                        },
                        {
                            "id": 125739,
                            "name": "Salem",
                            "latitude": "40.05301000",
                            "longitude": "-111.67354000"
                        },
                        {
                            "id": 125746,
                            "name": "Salina",
                            "latitude": "38.95774000",
                            "longitude": "-111.85993000"
                        },
                        {
                            "id": 125767,
                            "name": "Salt Lake City",
                            "latitude": "40.76078000",
                            "longitude": "-111.89105000"
                        },
                        {
                            "id": 125768,
                            "name": "Salt Lake County",
                            "latitude": "40.66758000",
                            "longitude": "-111.92403000"
                        },
                        {
                            "id": 125822,
                            "name": "San Juan County",
                            "latitude": "37.62601000",
                            "longitude": "-109.80457000"
                        },
                        {
                            "id": 125881,
                            "name": "Sandy",
                            "latitude": "40.59161000",
                            "longitude": "-111.88410000"
                        },
                        {
                            "id": 125882,
                            "name": "Sandy Hills",
                            "latitude": "40.58106000",
                            "longitude": "-111.85077000"
                        },
                        {
                            "id": 125896,
                            "name": "Sanpete County",
                            "latitude": "39.37396000",
                            "longitude": "-111.57634000"
                        },
                        {
                            "id": 125905,
                            "name": "Santa Clara",
                            "latitude": "37.13304000",
                            "longitude": "-113.65413000"
                        },
                        {
                            "id": 125929,
                            "name": "Santaquin",
                            "latitude": "39.97551000",
                            "longitude": "-111.78521000"
                        },
                        {
                            "id": 125945,
                            "name": "Saratoga Springs",
                            "latitude": "40.34912000",
                            "longitude": "-111.90466000"
                        },
                        {
                            "id": 126182,
                            "name": "Sevier County",
                            "latitude": "38.74764000",
                            "longitude": "-111.80464000"
                        },
                        {
                            "id": 126438,
                            "name": "Silver Summit",
                            "latitude": "40.74144000",
                            "longitude": "-111.48775000"
                        },
                        {
                            "id": 126521,
                            "name": "Smithfield",
                            "latitude": "41.83826000",
                            "longitude": "-111.83272000"
                        },
                        {
                            "id": 126552,
                            "name": "Snyderville",
                            "latitude": "40.69439000",
                            "longitude": "-111.54381000"
                        },
                        {
                            "id": 126680,
                            "name": "South Jordan",
                            "latitude": "40.56217000",
                            "longitude": "-111.92966000"
                        },
                        {
                            "id": 126681,
                            "name": "South Jordan Heights",
                            "latitude": "40.56384000",
                            "longitude": "-111.94938000"
                        },
                        {
                            "id": 126696,
                            "name": "South Ogden",
                            "latitude": "41.19189000",
                            "longitude": "-111.97133000"
                        },
                        {
                            "id": 126722,
                            "name": "South Salt Lake",
                            "latitude": "40.71884000",
                            "longitude": "-111.88827000"
                        },
                        {
                            "id": 126745,
                            "name": "South Weber",
                            "latitude": "41.13244000",
                            "longitude": "-111.93022000"
                        },
                        {
                            "id": 126749,
                            "name": "South Willard",
                            "latitude": "41.36327000",
                            "longitude": "-112.03578000"
                        },
                        {
                            "id": 126798,
                            "name": "Spanish Fork",
                            "latitude": "40.11496000",
                            "longitude": "-111.65492000"
                        },
                        {
                            "id": 126853,
                            "name": "Spring City",
                            "latitude": "39.48246000",
                            "longitude": "-111.49602000"
                        },
                        {
                            "id": 126855,
                            "name": "Spring Glen",
                            "latitude": "39.65941000",
                            "longitude": "-110.85349000"
                        },
                        {
                            "id": 126918,
                            "name": "Springville",
                            "latitude": "40.16523000",
                            "longitude": "-111.61075000"
                        },
                        {
                            "id": 126965,
                            "name": "Stansbury park",
                            "latitude": "40.63772000",
                            "longitude": "-112.29606000"
                        },
                        {
                            "id": 127217,
                            "name": "Summit County",
                            "latitude": "40.86815000",
                            "longitude": "-110.95567000"
                        },
                        {
                            "id": 127219,
                            "name": "Summit Park",
                            "latitude": "40.74578000",
                            "longitude": "-111.61159000"
                        },
                        {
                            "id": 127273,
                            "name": "Sunset",
                            "latitude": "41.13633000",
                            "longitude": "-112.03105000"
                        },
                        {
                            "id": 127367,
                            "name": "Syracuse",
                            "latitude": "41.08939000",
                            "longitude": "-112.06467000"
                        },
                        {
                            "id": 127466,
                            "name": "Taylorsville",
                            "latitude": "40.66772000",
                            "longitude": "-111.93883000"
                        },
                        {
                            "id": 127725,
                            "name": "Tooele",
                            "latitude": "40.53078000",
                            "longitude": "-112.29828000"
                        },
                        {
                            "id": 127726,
                            "name": "Tooele County",
                            "latitude": "40.44875000",
                            "longitude": "-113.13106000"
                        },
                        {
                            "id": 127738,
                            "name": "Toquerville",
                            "latitude": "37.25332000",
                            "longitude": "-113.28467000"
                        },
                        {
                            "id": 127797,
                            "name": "Tremonton",
                            "latitude": "41.71187000",
                            "longitude": "-112.16551000"
                        },
                        {
                            "id": 127956,
                            "name": "Uintah",
                            "latitude": "41.14411000",
                            "longitude": "-111.92327000"
                        },
                        {
                            "id": 127957,
                            "name": "Uintah County",
                            "latitude": "40.12495000",
                            "longitude": "-109.51839000"
                        },
                        {
                            "id": 128064,
                            "name": "Utah County",
                            "latitude": "40.11995000",
                            "longitude": "-111.67031000"
                        },
                        {
                            "id": 128187,
                            "name": "Vernal",
                            "latitude": "40.45552000",
                            "longitude": "-109.52875000"
                        },
                        {
                            "id": 128272,
                            "name": "Vineyard",
                            "latitude": "40.29704000",
                            "longitude": "-111.74670000"
                        },
                        {
                            "id": 128526,
                            "name": "Wasatch County",
                            "latitude": "40.33035000",
                            "longitude": "-111.16847000"
                        },
                        {
                            "id": 128551,
                            "name": "Washington",
                            "latitude": "37.13054000",
                            "longitude": "-113.50829000"
                        },
                        {
                            "id": 128578,
                            "name": "Washington County",
                            "latitude": "37.28036000",
                            "longitude": "-113.50494000"
                        },
                        {
                            "id": 128586,
                            "name": "Washington Terrace",
                            "latitude": "41.17272000",
                            "longitude": "-111.97661000"
                        },
                        {
                            "id": 128703,
                            "name": "Wayne County",
                            "latitude": "38.32436000",
                            "longitude": "-110.90367000"
                        },
                        {
                            "id": 128729,
                            "name": "Weber County",
                            "latitude": "41.26988000",
                            "longitude": "-111.91327000"
                        },
                        {
                            "id": 128779,
                            "name": "Wellington",
                            "latitude": "39.54247000",
                            "longitude": "-110.73543000"
                        },
                        {
                            "id": 128794,
                            "name": "Wellsville",
                            "latitude": "41.63854000",
                            "longitude": "-111.93383000"
                        },
                        {
                            "id": 128801,
                            "name": "Wendover",
                            "latitude": "40.73715000",
                            "longitude": "-114.03751000"
                        },
                        {
                            "id": 128829,
                            "name": "West Bountiful",
                            "latitude": "40.89383000",
                            "longitude": "-111.90188000"
                        },
                        {
                            "id": 128888,
                            "name": "West Haven",
                            "latitude": "41.20300000",
                            "longitude": "-112.05105000"
                        },
                        {
                            "id": 128905,
                            "name": "West Jordan",
                            "latitude": "40.60967000",
                            "longitude": "-111.93910000"
                        },
                        {
                            "id": 128937,
                            "name": "West Mountain",
                            "latitude": "40.06079000",
                            "longitude": "-111.78827000"
                        },
                        {
                            "id": 128963,
                            "name": "West Point",
                            "latitude": "41.11828000",
                            "longitude": "-112.08411000"
                        },
                        {
                            "id": 128997,
                            "name": "West Valley City",
                            "latitude": "40.69161000",
                            "longitude": "-112.00105000"
                        },
                        {
                            "id": 129132,
                            "name": "White City",
                            "latitude": "40.56578000",
                            "longitude": "-111.86438000"
                        },
                        {
                            "id": 129258,
                            "name": "Willard",
                            "latitude": "41.40911000",
                            "longitude": "-112.03606000"
                        },
                        {
                            "id": 129471,
                            "name": "Wolf Creek",
                            "latitude": "41.33327000",
                            "longitude": "-111.82716000"
                        },
                        {
                            "id": 129531,
                            "name": "Woodland Hills",
                            "latitude": "40.01532000",
                            "longitude": "-111.64868000"
                        },
                        {
                            "id": 129551,
                            "name": "Woods Cross",
                            "latitude": "40.87161000",
                            "longitude": "-111.89216000"
                        }
                    ]
                },
                {
                    "id": 1409,
                    "name": "Vermont",
                    "state_code": "VT",
                    "cities": [
                        {
                            "id": 111039,
                            "name": "Addison",
                            "latitude": "44.08867000",
                            "longitude": "-73.30262000"
                        },
                        {
                            "id": 111040,
                            "name": "Addison County",
                            "latitude": "44.03091000",
                            "longitude": "-73.14094000"
                        },
                        {
                            "id": 111469,
                            "name": "Arlington",
                            "latitude": "43.07480000",
                            "longitude": "-73.15400000"
                        },
                        {
                            "id": 111842,
                            "name": "Barre",
                            "latitude": "44.19701000",
                            "longitude": "-72.50205000"
                        },
                        {
                            "id": 112124,
                            "name": "Bellows Falls",
                            "latitude": "43.13341000",
                            "longitude": "-72.44398000"
                        },
                        {
                            "id": 112177,
                            "name": "Bennington",
                            "latitude": "42.87813000",
                            "longitude": "-73.19677000"
                        },
                        {
                            "id": 112178,
                            "name": "Bennington County",
                            "latitude": "43.03546000",
                            "longitude": "-73.09295000"
                        },
                        {
                            "id": 112690,
                            "name": "Brandon",
                            "latitude": "43.79812000",
                            "longitude": "-73.08761000"
                        },
                        {
                            "id": 112699,
                            "name": "Brattleboro",
                            "latitude": "42.85092000",
                            "longitude": "-72.55787000"
                        },
                        {
                            "id": 112780,
                            "name": "Bridport",
                            "latitude": "43.98506000",
                            "longitude": "-73.31262000"
                        },
                        {
                            "id": 112810,
                            "name": "Bristol",
                            "latitude": "44.13339000",
                            "longitude": "-73.07901000"
                        },
                        {
                            "id": 113072,
                            "name": "Burlington",
                            "latitude": "44.47588000",
                            "longitude": "-73.21207000"
                        },
                        {
                            "id": 113193,
                            "name": "Caledonia County",
                            "latitude": "44.46472000",
                            "longitude": "-72.10219000"
                        },
                        {
                            "id": 113552,
                            "name": "Castleton",
                            "latitude": "43.61062000",
                            "longitude": "-73.17983000"
                        },
                        {
                            "id": 113780,
                            "name": "Charlotte",
                            "latitude": "44.30977000",
                            "longitude": "-73.26096000"
                        },
                        {
                            "id": 113835,
                            "name": "Chelsea",
                            "latitude": "43.98979000",
                            "longitude": "-72.44760000"
                        },
                        {
                            "id": 113891,
                            "name": "Chester",
                            "latitude": "43.26285000",
                            "longitude": "-72.59509000"
                        },
                        {
                            "id": 113982,
                            "name": "Chittenden",
                            "latitude": "43.70784000",
                            "longitude": "-72.94816000"
                        },
                        {
                            "id": 113983,
                            "name": "Chittenden County",
                            "latitude": "44.46098000",
                            "longitude": "-73.08092000"
                        },
                        {
                            "id": 114111,
                            "name": "Clarendon",
                            "latitude": "43.51618000",
                            "longitude": "-72.96983000"
                        },
                        {
                            "id": 114384,
                            "name": "Colchester",
                            "latitude": "44.54394000",
                            "longitude": "-73.14791000"
                        },
                        {
                            "id": 115017,
                            "name": "Danby",
                            "latitude": "43.34618000",
                            "longitude": "-72.99538000"
                        },
                        {
                            "id": 115452,
                            "name": "Dover",
                            "latitude": "42.94369000",
                            "longitude": "-72.80399000"
                        },
                        {
                            "id": 116146,
                            "name": "Enosburg Falls",
                            "latitude": "44.90699000",
                            "longitude": "-72.80652000"
                        },
                        {
                            "id": 116197,
                            "name": "Essex County",
                            "latitude": "44.72779000",
                            "longitude": "-71.73605000"
                        },
                        {
                            "id": 116199,
                            "name": "Essex Junction",
                            "latitude": "44.49061000",
                            "longitude": "-73.11096000"
                        },
                        {
                            "id": 116283,
                            "name": "Fair Haven",
                            "latitude": "43.59479000",
                            "longitude": "-73.26567000"
                        },
                        {
                            "id": 116495,
                            "name": "Ferrisburgh",
                            "latitude": "44.20561000",
                            "longitude": "-73.24623000"
                        },
                        {
                            "id": 116863,
                            "name": "Franklin County",
                            "latitude": "44.85748000",
                            "longitude": "-72.91200000"
                        },
                        {
                            "id": 117464,
                            "name": "Grand Isle County",
                            "latitude": "44.79683000",
                            "longitude": "-73.29483000"
                        },
                        {
                            "id": 117783,
                            "name": "Guildhall",
                            "latitude": "44.56506000",
                            "longitude": "-71.55981000"
                        },
                        {
                            "id": 118010,
                            "name": "Hardwick",
                            "latitude": "44.50478000",
                            "longitude": "-72.36816000"
                        },
                        {
                            "id": 118092,
                            "name": "Hartford",
                            "latitude": "43.66063000",
                            "longitude": "-72.33842000"
                        },
                        {
                            "id": 118481,
                            "name": "Hinesburg",
                            "latitude": "44.32922000",
                            "longitude": "-73.11068000"
                        },
                        {
                            "id": 118852,
                            "name": "Hyde Park",
                            "latitude": "44.59394000",
                            "longitude": "-72.61651000"
                        },
                        {
                            "id": 119112,
                            "name": "Jamaica",
                            "latitude": "43.10036000",
                            "longitude": "-72.77843000"
                        },
                        {
                            "id": 119225,
                            "name": "Jericho",
                            "latitude": "44.50394000",
                            "longitude": "-72.99763000"
                        },
                        {
                            "id": 119255,
                            "name": "Johnson",
                            "latitude": "44.63561000",
                            "longitude": "-72.68040000"
                        },
                        {
                            "id": 120072,
                            "name": "Lamoille County",
                            "latitude": "44.60576000",
                            "longitude": "-72.64145000"
                        },
                        {
                            "id": 120338,
                            "name": "Leicester",
                            "latitude": "43.86673000",
                            "longitude": "-73.10789000"
                        },
                        {
                            "id": 120508,
                            "name": "Lincoln",
                            "latitude": "44.10589000",
                            "longitude": "-72.99706000"
                        },
                        {
                            "id": 120724,
                            "name": "Londonderry",
                            "latitude": "43.22646000",
                            "longitude": "-72.80649000"
                        },
                        {
                            "id": 120888,
                            "name": "Lunenburg",
                            "latitude": "44.46311000",
                            "longitude": "-71.68203000"
                        },
                        {
                            "id": 120924,
                            "name": "Lyndon",
                            "latitude": "44.51422000",
                            "longitude": "-72.01093000"
                        },
                        {
                            "id": 120925,
                            "name": "Lyndonville",
                            "latitude": "44.53367000",
                            "longitude": "-72.00315000"
                        },
                        {
                            "id": 121103,
                            "name": "Manchester Center",
                            "latitude": "43.17702000",
                            "longitude": "-73.05705000"
                        },
                        {
                            "id": 121654,
                            "name": "Mendon",
                            "latitude": "43.65198000",
                            "longitude": "-72.92780000"
                        },
                        {
                            "id": 121773,
                            "name": "Middlebury (village)",
                            "latitude": "44.01553000",
                            "longitude": "-73.16937000"
                        },
                        {
                            "id": 121919,
                            "name": "Milton",
                            "latitude": "44.63977000",
                            "longitude": "-73.11041000"
                        },
                        {
                            "id": 122137,
                            "name": "Montgomery",
                            "latitude": "44.90255000",
                            "longitude": "-72.63818000"
                        },
                        {
                            "id": 122177,
                            "name": "Montpelier",
                            "latitude": "44.26006000",
                            "longitude": "-72.57539000"
                        },
                        {
                            "id": 122232,
                            "name": "Moretown",
                            "latitude": "44.25089000",
                            "longitude": "-72.76095000"
                        },
                        {
                            "id": 122283,
                            "name": "Morristown",
                            "latitude": "44.55727000",
                            "longitude": "-72.62373000"
                        },
                        {
                            "id": 122287,
                            "name": "Morrisville",
                            "latitude": "44.56172000",
                            "longitude": "-72.59845000"
                        },
                        {
                            "id": 122350,
                            "name": "Mount Holly",
                            "latitude": "43.45229000",
                            "longitude": "-72.82482000"
                        },
                        {
                            "id": 122827,
                            "name": "Newfane",
                            "latitude": "42.98564000",
                            "longitude": "-72.65593000"
                        },
                        {
                            "id": 122850,
                            "name": "Newport",
                            "latitude": "44.93644000",
                            "longitude": "-72.20510000"
                        },
                        {
                            "id": 122986,
                            "name": "North Bennington",
                            "latitude": "42.93036000",
                            "longitude": "-73.24261000"
                        },
                        {
                            "id": 123038,
                            "name": "North Hero",
                            "latitude": "44.83125000",
                            "longitude": "-73.27323000"
                        },
                        {
                            "id": 123154,
                            "name": "Northfield",
                            "latitude": "44.15117000",
                            "longitude": "-72.65650000"
                        },
                        {
                            "id": 123520,
                            "name": "Orange County",
                            "latitude": "44.00560000",
                            "longitude": "-72.37661000"
                        },
                        {
                            "id": 123566,
                            "name": "Orleans County",
                            "latitude": "44.82881000",
                            "longitude": "-72.24381000"
                        },
                        {
                            "id": 123931,
                            "name": "Pawlet",
                            "latitude": "43.34674000",
                            "longitude": "-73.17622000"
                        },
                        {
                            "id": 124604,
                            "name": "Poultney",
                            "latitude": "43.51701000",
                            "longitude": "-73.23622000"
                        },
                        {
                            "id": 124619,
                            "name": "Pownal",
                            "latitude": "42.76564000",
                            "longitude": "-73.23594000"
                        },
                        {
                            "id": 124868,
                            "name": "Randolph",
                            "latitude": "43.92507000",
                            "longitude": "-72.66594000"
                        },
                        {
                            "id": 125065,
                            "name": "Richford",
                            "latitude": "44.99699000",
                            "longitude": "-72.67124000"
                        },
                        {
                            "id": 125304,
                            "name": "Rockingham",
                            "latitude": "43.18758000",
                            "longitude": "-72.48898000"
                        },
                        {
                            "id": 125557,
                            "name": "Rutland",
                            "latitude": "43.61062000",
                            "longitude": "-72.97261000"
                        },
                        {
                            "id": 125558,
                            "name": "Rutland County",
                            "latitude": "43.58009000",
                            "longitude": "-73.03661000"
                        },
                        {
                            "id": 125596,
                            "name": "Saint Albans",
                            "latitude": "44.81088000",
                            "longitude": "-73.08319000"
                        },
                        {
                            "id": 125668,
                            "name": "Saint Johnsbury",
                            "latitude": "44.41922000",
                            "longitude": "-72.01509000"
                        },
                        {
                            "id": 125760,
                            "name": "Salisbury",
                            "latitude": "43.89645000",
                            "longitude": "-73.09984000"
                        },
                        {
                            "id": 126616,
                            "name": "South Barre",
                            "latitude": "44.17701000",
                            "longitude": "-72.50566000"
                        },
                        {
                            "id": 126634,
                            "name": "South Burlington",
                            "latitude": "44.46699000",
                            "longitude": "-73.17096000"
                        },
                        {
                            "id": 126904,
                            "name": "Springfield",
                            "latitude": "43.29841000",
                            "longitude": "-72.48231000"
                        },
                        {
                            "id": 126925,
                            "name": "St Johnsbury",
                            "latitude": "44.42526000",
                            "longitude": "-72.01512000"
                        },
                        {
                            "id": 126988,
                            "name": "Starksboro",
                            "latitude": "44.22728000",
                            "longitude": "-73.05734000"
                        },
                        {
                            "id": 127114,
                            "name": "Stowe",
                            "latitude": "44.46533000",
                            "longitude": "-72.68456000"
                        },
                        {
                            "id": 127327,
                            "name": "Swanton",
                            "latitude": "44.91810000",
                            "longitude": "-73.12430000"
                        },
                        {
                            "id": 127767,
                            "name": "Townshend",
                            "latitude": "43.04730000",
                            "longitude": "-72.66759000"
                        },
                        {
                            "id": 128182,
                            "name": "Vergennes",
                            "latitude": "44.16728000",
                            "longitude": "-73.25401000"
                        },
                        {
                            "id": 128550,
                            "name": "Washington",
                            "latitude": "44.10562000",
                            "longitude": "-72.43260000"
                        },
                        {
                            "id": 128575,
                            "name": "Washington County",
                            "latitude": "44.27342000",
                            "longitude": "-72.61490000"
                        },
                        {
                            "id": 128603,
                            "name": "Waterbury",
                            "latitude": "44.33783000",
                            "longitude": "-72.75623000"
                        },
                        {
                            "id": 128834,
                            "name": "West Brattleboro",
                            "latitude": "42.85592000",
                            "longitude": "-72.60315000"
                        },
                        {
                            "id": 128970,
                            "name": "West Rutland",
                            "latitude": "43.59312000",
                            "longitude": "-73.04511000"
                        },
                        {
                            "id": 129157,
                            "name": "White River Junction",
                            "latitude": "43.64896000",
                            "longitude": "-72.31926000"
                        },
                        {
                            "id": 129232,
                            "name": "Wilder",
                            "latitude": "43.67285000",
                            "longitude": "-72.30870000"
                        },
                        {
                            "id": 129289,
                            "name": "Williamstown",
                            "latitude": "44.12173000",
                            "longitude": "-72.54149000"
                        },
                        {
                            "id": 129298,
                            "name": "Williston",
                            "latitude": "44.43755000",
                            "longitude": "-73.06818000"
                        },
                        {
                            "id": 129374,
                            "name": "Windham County",
                            "latitude": "42.99059000",
                            "longitude": "-72.71384000"
                        },
                        {
                            "id": 129384,
                            "name": "Windsor",
                            "latitude": "43.48035000",
                            "longitude": "-72.38481000"
                        },
                        {
                            "id": 129388,
                            "name": "Windsor County",
                            "latitude": "43.58000000",
                            "longitude": "-72.58624000"
                        },
                        {
                            "id": 129427,
                            "name": "Winooski",
                            "latitude": "44.49144000",
                            "longitude": "-73.18568000"
                        },
                        {
                            "id": 129566,
                            "name": "Woodstock",
                            "latitude": "43.62424000",
                            "longitude": "-72.51843000"
                        }
                    ]
                },
                {
                    "id": 1427,
                    "name": "Virginia",
                    "state_code": "VA",
                    "cities": [
                        {
                            "id": 110984,
                            "name": "Abingdon",
                            "latitude": "36.70983000",
                            "longitude": "-81.97735000"
                        },
                        {
                            "id": 110994,
                            "name": "Accomac",
                            "latitude": "37.71957000",
                            "longitude": "-75.66548000"
                        },
                        {
                            "id": 110995,
                            "name": "Accomack County",
                            "latitude": "37.76494000",
                            "longitude": "-75.75656000"
                        },
                        {
                            "id": 111050,
                            "name": "Adwolf",
                            "latitude": "36.78928000",
                            "longitude": "-81.58206000"
                        },
                        {
                            "id": 111116,
                            "name": "Albemarle County",
                            "latitude": "38.02289000",
                            "longitude": "-78.55654000"
                        },
                        {
                            "id": 111151,
                            "name": "Alexandria",
                            "latitude": "38.80484000",
                            "longitude": "-77.04692000"
                        },
                        {
                            "id": 111184,
                            "name": "Alleghany County",
                            "latitude": "37.78760000",
                            "longitude": "-80.00699000"
                        },
                        {
                            "id": 111238,
                            "name": "Altavista",
                            "latitude": "37.11181000",
                            "longitude": "-79.28558000"
                        },
                        {
                            "id": 111269,
                            "name": "Amelia County",
                            "latitude": "37.33603000",
                            "longitude": "-77.97614000"
                        },
                        {
                            "id": 111270,
                            "name": "Amelia Court House",
                            "latitude": "37.34293000",
                            "longitude": "-77.98056000"
                        },
                        {
                            "id": 111281,
                            "name": "Amherst",
                            "latitude": "37.58514000",
                            "longitude": "-79.05141000"
                        },
                        {
                            "id": 111286,
                            "name": "Amherst County",
                            "latitude": "37.60477000",
                            "longitude": "-79.14511000"
                        },
                        {
                            "id": 111349,
                            "name": "Annandale",
                            "latitude": "38.83039000",
                            "longitude": "-77.19637000"
                        },
                        {
                            "id": 111390,
                            "name": "Appalachia",
                            "latitude": "36.90676000",
                            "longitude": "-82.78183000"
                        },
                        {
                            "id": 111392,
                            "name": "Apple Mountain Lake",
                            "latitude": "38.92428000",
                            "longitude": "-78.10139000"
                        },
                        {
                            "id": 111401,
                            "name": "Appomattox",
                            "latitude": "37.35709000",
                            "longitude": "-78.82529000"
                        },
                        {
                            "id": 111402,
                            "name": "Appomattox County",
                            "latitude": "37.37229000",
                            "longitude": "-78.81212000"
                        },
                        {
                            "id": 111406,
                            "name": "Aquia Harbour",
                            "latitude": "38.46262000",
                            "longitude": "-77.38887000"
                        },
                        {
                            "id": 111463,
                            "name": "Arlington",
                            "latitude": "38.88101000",
                            "longitude": "-77.10428000"
                        },
                        {
                            "id": 111471,
                            "name": "Arlington County",
                            "latitude": "38.87862000",
                            "longitude": "-77.10096000"
                        },
                        {
                            "id": 111514,
                            "name": "Ashburn",
                            "latitude": "39.04372000",
                            "longitude": "-77.48749000"
                        },
                        {
                            "id": 111532,
                            "name": "Ashland",
                            "latitude": "37.75903000",
                            "longitude": "-77.47998000"
                        },
                        {
                            "id": 111585,
                            "name": "Atkins",
                            "latitude": "36.86734000",
                            "longitude": "-81.42344000"
                        },
                        {
                            "id": 111652,
                            "name": "Augusta County",
                            "latitude": "38.16452000",
                            "longitude": "-79.13383000"
                        },
                        {
                            "id": 111731,
                            "name": "Baileys Crossroads",
                            "latitude": "38.85039000",
                            "longitude": "-77.12970000"
                        },
                        {
                            "id": 111881,
                            "name": "Bassett",
                            "latitude": "36.75930000",
                            "longitude": "-79.99032000"
                        },
                        {
                            "id": 111886,
                            "name": "Basye",
                            "latitude": "38.80706000",
                            "longitude": "-78.79224000"
                        },
                        {
                            "id": 111902,
                            "name": "Bath County",
                            "latitude": "38.05869000",
                            "longitude": "-79.74109000"
                        },
                        {
                            "id": 111972,
                            "name": "Bealeton",
                            "latitude": "38.57179000",
                            "longitude": "-77.76388000"
                        },
                        {
                            "id": 112020,
                            "name": "Bedford",
                            "latitude": "37.33431000",
                            "longitude": "-79.52309000"
                        },
                        {
                            "id": 112027,
                            "name": "Bedford County",
                            "latitude": "37.31494000",
                            "longitude": "-79.52422000"
                        },
                        {
                            "id": 112085,
                            "name": "Belle Haven",
                            "latitude": "38.78539000",
                            "longitude": "-77.06303000"
                        },
                        {
                            "id": 112130,
                            "name": "Bellwood",
                            "latitude": "37.42181000",
                            "longitude": "-77.43748000"
                        },
                        {
                            "id": 112137,
                            "name": "Belmont",
                            "latitude": "39.06483000",
                            "longitude": "-77.50999000"
                        },
                        {
                            "id": 112144,
                            "name": "Belmont Estates",
                            "latitude": "38.45271000",
                            "longitude": "-78.91979000"
                        },
                        {
                            "id": 112181,
                            "name": "Bensley",
                            "latitude": "37.44681000",
                            "longitude": "-77.44332000"
                        },
                        {
                            "id": 112255,
                            "name": "Berryville",
                            "latitude": "39.15177000",
                            "longitude": "-77.98222000"
                        },
                        {
                            "id": 112337,
                            "name": "Big Stone Gap",
                            "latitude": "36.88176000",
                            "longitude": "-82.74710000"
                        },
                        {
                            "id": 112390,
                            "name": "Blacksburg",
                            "latitude": "37.22957000",
                            "longitude": "-80.41394000"
                        },
                        {
                            "id": 112392,
                            "name": "Blackstone",
                            "latitude": "37.08043000",
                            "longitude": "-77.99723000"
                        },
                        {
                            "id": 112420,
                            "name": "Bland",
                            "latitude": "37.10206000",
                            "longitude": "-81.11620000"
                        },
                        {
                            "id": 112421,
                            "name": "Bland County",
                            "latitude": "37.13397000",
                            "longitude": "-81.13028000"
                        },
                        {
                            "id": 112476,
                            "name": "Blue Ridge",
                            "latitude": "37.37847000",
                            "longitude": "-79.80698000"
                        },
                        {
                            "id": 112478,
                            "name": "Bluefield",
                            "latitude": "37.25262000",
                            "longitude": "-81.27121000"
                        },
                        {
                            "id": 112526,
                            "name": "Bon Air",
                            "latitude": "37.52487000",
                            "longitude": "-77.55777000"
                        },
                        {
                            "id": 112593,
                            "name": "Boswell's Corner",
                            "latitude": "38.50541000",
                            "longitude": "-77.37263000"
                        },
                        {
                            "id": 112594,
                            "name": "Botetourt County",
                            "latitude": "37.55712000",
                            "longitude": "-79.81233000"
                        },
                        {
                            "id": 112631,
                            "name": "Bowling Green",
                            "latitude": "38.04958000",
                            "longitude": "-77.34665000"
                        },
                        {
                            "id": 112644,
                            "name": "Boydton",
                            "latitude": "36.66764000",
                            "longitude": "-78.38750000"
                        },
                        {
                            "id": 112654,
                            "name": "Bracey",
                            "latitude": "36.59959000",
                            "longitude": "-78.14305000"
                        },
                        {
                            "id": 112683,
                            "name": "Brambleton",
                            "latitude": "38.98205000",
                            "longitude": "-77.53860000"
                        },
                        {
                            "id": 112687,
                            "name": "Brandermill",
                            "latitude": "37.43209000",
                            "longitude": "-77.64971000"
                        },
                        {
                            "id": 112774,
                            "name": "Bridgewater",
                            "latitude": "38.38207000",
                            "longitude": "-78.97670000"
                        },
                        {
                            "id": 112795,
                            "name": "Brightwood",
                            "latitude": "38.42152000",
                            "longitude": "-78.19361000"
                        },
                        {
                            "id": 112804,
                            "name": "Bristol",
                            "latitude": "36.59649000",
                            "longitude": "-82.18847000"
                        },
                        {
                            "id": 112824,
                            "name": "Broadlands",
                            "latitude": "39.01816000",
                            "longitude": "-77.52027000"
                        },
                        {
                            "id": 112831,
                            "name": "Broadway",
                            "latitude": "38.61317000",
                            "longitude": "-78.79891000"
                        },
                        {
                            "id": 112879,
                            "name": "Brookneal",
                            "latitude": "37.05014000",
                            "longitude": "-78.94418000"
                        },
                        {
                            "id": 112952,
                            "name": "Brunswick County",
                            "latitude": "36.76478000",
                            "longitude": "-77.85902000"
                        },
                        {
                            "id": 112970,
                            "name": "Buchanan",
                            "latitude": "37.52736000",
                            "longitude": "-79.67976000"
                        },
                        {
                            "id": 112974,
                            "name": "Buchanan County",
                            "latitude": "37.26663000",
                            "longitude": "-82.03603000"
                        },
                        {
                            "id": 112980,
                            "name": "Buckhall",
                            "latitude": "38.73178000",
                            "longitude": "-77.43110000"
                        },
                        {
                            "id": 112985,
                            "name": "Buckingham",
                            "latitude": "37.55015000",
                            "longitude": "-78.55556000"
                        },
                        {
                            "id": 112986,
                            "name": "Buckingham County",
                            "latitude": "37.57224000",
                            "longitude": "-78.52871000"
                        },
                        {
                            "id": 113001,
                            "name": "Buena Vista",
                            "latitude": "37.73430000",
                            "longitude": "-79.35392000"
                        },
                        {
                            "id": 113026,
                            "name": "Bull Run",
                            "latitude": "38.78373000",
                            "longitude": "-77.52055000"
                        },
                        {
                            "id": 113027,
                            "name": "Bull Run Mountain Estates",
                            "latitude": "38.90372000",
                            "longitude": "-77.66166000"
                        },
                        {
                            "id": 113054,
                            "name": "Burke",
                            "latitude": "38.79345000",
                            "longitude": "-77.27165000"
                        },
                        {
                            "id": 113301,
                            "name": "Campbell County",
                            "latitude": "37.20564000",
                            "longitude": "-79.09635000"
                        },
                        {
                            "id": 113311,
                            "name": "Cana",
                            "latitude": "36.58958000",
                            "longitude": "-80.67173000"
                        },
                        {
                            "id": 113365,
                            "name": "Cape Charles",
                            "latitude": "37.27008000",
                            "longitude": "-76.01649000"
                        },
                        {
                            "id": 113379,
                            "name": "Captains Cove",
                            "latitude": "37.98996000",
                            "longitude": "-75.42272000"
                        },
                        {
                            "id": 113438,
                            "name": "Caroline County",
                            "latitude": "38.02682000",
                            "longitude": "-77.34696000"
                        },
                        {
                            "id": 113457,
                            "name": "Carroll County",
                            "latitude": "36.73154000",
                            "longitude": "-80.73390000"
                        },
                        {
                            "id": 113469,
                            "name": "Carrollton",
                            "latitude": "36.94682000",
                            "longitude": "-76.56051000"
                        },
                        {
                            "id": 113554,
                            "name": "Castlewood",
                            "latitude": "36.89010000",
                            "longitude": "-82.27959000"
                        },
                        {
                            "id": 113588,
                            "name": "Cave Spring",
                            "latitude": "37.22764000",
                            "longitude": "-80.01282000"
                        },
                        {
                            "id": 113602,
                            "name": "Cedar Bluff",
                            "latitude": "37.08761000",
                            "longitude": "-81.75900000"
                        },
                        {
                            "id": 113674,
                            "name": "Central Garage",
                            "latitude": "37.74403000",
                            "longitude": "-77.13164000"
                        },
                        {
                            "id": 113695,
                            "name": "Centreville",
                            "latitude": "38.84039000",
                            "longitude": "-77.42888000"
                        },
                        {
                            "id": 113719,
                            "name": "Chamberlayne",
                            "latitude": "37.62653000",
                            "longitude": "-77.42859000"
                        },
                        {
                            "id": 113738,
                            "name": "Chantilly",
                            "latitude": "38.89428000",
                            "longitude": "-77.43110000"
                        },
                        {
                            "id": 113755,
                            "name": "Charles City",
                            "latitude": "37.34348000",
                            "longitude": "-77.07303000"
                        },
                        {
                            "id": 113756,
                            "name": "Charles City County",
                            "latitude": "37.35435000",
                            "longitude": "-77.05913000"
                        },
                        {
                            "id": 113782,
                            "name": "Charlotte County",
                            "latitude": "37.01161000",
                            "longitude": "-78.66164000"
                        },
                        {
                            "id": 113783,
                            "name": "Charlotte Court House",
                            "latitude": "37.05654000",
                            "longitude": "-78.63833000"
                        },
                        {
                            "id": 113787,
                            "name": "Charlottesville",
                            "latitude": "38.02931000",
                            "longitude": "-78.47668000"
                        },
                        {
                            "id": 113791,
                            "name": "Chase City",
                            "latitude": "36.79931000",
                            "longitude": "-78.45833000"
                        },
                        {
                            "id": 113796,
                            "name": "Chatham",
                            "latitude": "36.82569000",
                            "longitude": "-79.39808000"
                        },
                        {
                            "id": 113803,
                            "name": "Chatmoss",
                            "latitude": "36.65680000",
                            "longitude": "-79.81226000"
                        },
                        {
                            "id": 113861,
                            "name": "Cherry Hill",
                            "latitude": "38.56984000",
                            "longitude": "-77.26693000"
                        },
                        {
                            "id": 113874,
                            "name": "Chesapeake",
                            "latitude": "36.81904000",
                            "longitude": "-76.27494000"
                        },
                        {
                            "id": 113886,
                            "name": "Chester",
                            "latitude": "37.35682000",
                            "longitude": "-77.44165000"
                        },
                        {
                            "id": 113907,
                            "name": "Chesterfield County",
                            "latitude": "37.37852000",
                            "longitude": "-77.58694000"
                        },
                        {
                            "id": 113908,
                            "name": "Chesterfield Court House",
                            "latitude": "37.37709000",
                            "longitude": "-77.50499000"
                        },
                        {
                            "id": 113952,
                            "name": "Chilhowie",
                            "latitude": "36.79845000",
                            "longitude": "-81.68234000"
                        },
                        {
                            "id": 113966,
                            "name": "Chincoteague",
                            "latitude": "37.93318000",
                            "longitude": "-75.37881000"
                        },
                        {
                            "id": 114000,
                            "name": "Christiansburg",
                            "latitude": "37.12985000",
                            "longitude": "-80.40894000"
                        },
                        {
                            "id": 114047,
                            "name": "City of Alexandria",
                            "latitude": "38.80484000",
                            "longitude": "-77.04692000"
                        },
                        {
                            "id": 114049,
                            "name": "City of Bedford",
                            "latitude": "37.33431000",
                            "longitude": "-79.52309000"
                        },
                        {
                            "id": 114050,
                            "name": "City of Bristol",
                            "latitude": "36.59649000",
                            "longitude": "-82.18847000"
                        },
                        {
                            "id": 114051,
                            "name": "City of Buena Vista",
                            "latitude": "37.73430000",
                            "longitude": "-79.35392000"
                        },
                        {
                            "id": 114052,
                            "name": "City of Charlottesville",
                            "latitude": "38.02931000",
                            "longitude": "-78.47668000"
                        },
                        {
                            "id": 114053,
                            "name": "City of Chesapeake",
                            "latitude": "36.68765000",
                            "longitude": "-76.31216000"
                        },
                        {
                            "id": 114054,
                            "name": "City of Colonial Heights",
                            "latitude": "37.24404000",
                            "longitude": "-77.41026000"
                        },
                        {
                            "id": 114055,
                            "name": "City of Covington",
                            "latitude": "37.79346000",
                            "longitude": "-79.99395000"
                        },
                        {
                            "id": 114056,
                            "name": "City of Danville",
                            "latitude": "36.58597000",
                            "longitude": "-79.39502000"
                        },
                        {
                            "id": 114057,
                            "name": "City of Emporia",
                            "latitude": "36.68598000",
                            "longitude": "-77.54248000"
                        },
                        {
                            "id": 114058,
                            "name": "City of Fairfax",
                            "latitude": "38.84622000",
                            "longitude": "-77.30637000"
                        },
                        {
                            "id": 114059,
                            "name": "City of Falls Church",
                            "latitude": "38.88233000",
                            "longitude": "-77.17109000"
                        },
                        {
                            "id": 114060,
                            "name": "City of Franklin",
                            "latitude": "36.67765000",
                            "longitude": "-76.92246000"
                        },
                        {
                            "id": 114061,
                            "name": "City of Fredericksburg",
                            "latitude": "38.30318000",
                            "longitude": "-77.46054000"
                        },
                        {
                            "id": 114062,
                            "name": "City of Galax",
                            "latitude": "36.66124000",
                            "longitude": "-80.92397000"
                        },
                        {
                            "id": 114063,
                            "name": "City of Hampton",
                            "latitude": "37.06265000",
                            "longitude": "-76.33300000"
                        },
                        {
                            "id": 114064,
                            "name": "City of Harrisonburg",
                            "latitude": "38.44957000",
                            "longitude": "-78.86892000"
                        },
                        {
                            "id": 114065,
                            "name": "City of Hopewell",
                            "latitude": "37.30432000",
                            "longitude": "-77.28720000"
                        },
                        {
                            "id": 114066,
                            "name": "City of Lexington",
                            "latitude": "37.77309000",
                            "longitude": "-79.45244000"
                        },
                        {
                            "id": 114067,
                            "name": "City of Lynchburg",
                            "latitude": "37.41375000",
                            "longitude": "-79.14225000"
                        },
                        {
                            "id": 114068,
                            "name": "City of Manassas",
                            "latitude": "38.75095000",
                            "longitude": "-77.47527000"
                        },
                        {
                            "id": 114069,
                            "name": "City of Manassas Park",
                            "latitude": "38.78400000",
                            "longitude": "-77.46971000"
                        },
                        {
                            "id": 114070,
                            "name": "City of Martinsville",
                            "latitude": "36.69153000",
                            "longitude": "-79.87254000"
                        },
                        {
                            "id": 114072,
                            "name": "City of Newport News",
                            "latitude": "37.06265000",
                            "longitude": "-76.50801000"
                        },
                        {
                            "id": 114073,
                            "name": "City of Norfolk",
                            "latitude": "36.89126000",
                            "longitude": "-76.26188000"
                        },
                        {
                            "id": 114074,
                            "name": "City of Norton",
                            "latitude": "36.93343000",
                            "longitude": "-82.62905000"
                        },
                        {
                            "id": 114075,
                            "name": "City of Petersburg",
                            "latitude": "37.22793000",
                            "longitude": "-77.40193000"
                        },
                        {
                            "id": 114076,
                            "name": "City of Poquoson",
                            "latitude": "37.12237000",
                            "longitude": "-76.34578000"
                        },
                        {
                            "id": 114077,
                            "name": "City of Portsmouth",
                            "latitude": "36.83649000",
                            "longitude": "-76.30795000"
                        },
                        {
                            "id": 114078,
                            "name": "City of Radford",
                            "latitude": "37.13179000",
                            "longitude": "-80.57645000"
                        },
                        {
                            "id": 114079,
                            "name": "City of Richmond",
                            "latitude": "37.55376000",
                            "longitude": "-77.46026000"
                        },
                        {
                            "id": 114080,
                            "name": "City of Roanoke",
                            "latitude": "37.27097000",
                            "longitude": "-79.94143000"
                        },
                        {
                            "id": 114082,
                            "name": "City of Salem",
                            "latitude": "37.29347000",
                            "longitude": "-80.05476000"
                        },
                        {
                            "id": 114084,
                            "name": "City of Staunton",
                            "latitude": "38.14931000",
                            "longitude": "-79.05963000"
                        },
                        {
                            "id": 114085,
                            "name": "City of Suffolk",
                            "latitude": "36.70848000",
                            "longitude": "-76.60801000"
                        },
                        {
                            "id": 114086,
                            "name": "City of Virginia Beach",
                            "latitude": "36.73765000",
                            "longitude": "-76.00521000"
                        },
                        {
                            "id": 114087,
                            "name": "City of Waynesboro",
                            "latitude": "38.06847000",
                            "longitude": "-78.88947000"
                        },
                        {
                            "id": 114088,
                            "name": "City of Williamsburg",
                            "latitude": "37.27070000",
                            "longitude": "-76.70746000"
                        },
                        {
                            "id": 114089,
                            "name": "City of Winchester",
                            "latitude": "39.18566000",
                            "longitude": "-78.16333000"
                        },
                        {
                            "id": 114137,
                            "name": "Clarke County",
                            "latitude": "39.11226000",
                            "longitude": "-77.99673000"
                        },
                        {
                            "id": 114157,
                            "name": "Clarksville",
                            "latitude": "36.62403000",
                            "longitude": "-78.55694000"
                        },
                        {
                            "id": 114194,
                            "name": "Claypool Hill",
                            "latitude": "37.06261000",
                            "longitude": "-81.75178000"
                        },
                        {
                            "id": 114262,
                            "name": "Clifton Forge",
                            "latitude": "37.81624000",
                            "longitude": "-79.82449000"
                        },
                        {
                            "id": 114300,
                            "name": "Clintwood",
                            "latitude": "37.15011000",
                            "longitude": "-82.45598000"
                        },
                        {
                            "id": 114310,
                            "name": "Cloverdale",
                            "latitude": "37.36514000",
                            "longitude": "-79.90560000"
                        },
                        {
                            "id": 114365,
                            "name": "Coeburn",
                            "latitude": "36.94399000",
                            "longitude": "-82.46404000"
                        },
                        {
                            "id": 114438,
                            "name": "Collinsville",
                            "latitude": "36.71514000",
                            "longitude": "-79.91532000"
                        },
                        {
                            "id": 114447,
                            "name": "Colonial Beach",
                            "latitude": "38.25457000",
                            "longitude": "-76.96358000"
                        },
                        {
                            "id": 114449,
                            "name": "Colonial Heights",
                            "latitude": "37.26804000",
                            "longitude": "-77.40726000"
                        },
                        {
                            "id": 114537,
                            "name": "Concord",
                            "latitude": "37.34264000",
                            "longitude": "-78.97502000"
                        },
                        {
                            "id": 114725,
                            "name": "Countryside",
                            "latitude": "39.04094000",
                            "longitude": "-77.41360000"
                        },
                        {
                            "id": 114728,
                            "name": "Courtland",
                            "latitude": "36.71626000",
                            "longitude": "-77.06802000"
                        },
                        {
                            "id": 114739,
                            "name": "Covington",
                            "latitude": "37.79346000",
                            "longitude": "-79.99395000"
                        },
                        {
                            "id": 114758,
                            "name": "Craig County",
                            "latitude": "37.48125000",
                            "longitude": "-80.21234000"
                        },
                        {
                            "id": 114818,
                            "name": "Crewe",
                            "latitude": "37.17321000",
                            "longitude": "-78.12333000"
                        },
                        {
                            "id": 114820,
                            "name": "Crimora",
                            "latitude": "38.15402000",
                            "longitude": "-78.85030000"
                        },
                        {
                            "id": 114871,
                            "name": "Crozet",
                            "latitude": "38.06958000",
                            "longitude": "-78.70058000"
                        },
                        {
                            "id": 114900,
                            "name": "Culpeper",
                            "latitude": "38.47318000",
                            "longitude": "-77.99666000"
                        },
                        {
                            "id": 114901,
                            "name": "Culpeper County",
                            "latitude": "38.48606000",
                            "longitude": "-77.95589000"
                        },
                        {
                            "id": 114908,
                            "name": "Cumberland",
                            "latitude": "37.49598000",
                            "longitude": "-78.24527000"
                        },
                        {
                            "id": 114917,
                            "name": "Cumberland County",
                            "latitude": "37.51210000",
                            "longitude": "-78.24496000"
                        },
                        {
                            "id": 114970,
                            "name": "Dahlgren",
                            "latitude": "38.33124000",
                            "longitude": "-77.05109000"
                        },
                        {
                            "id": 114981,
                            "name": "Dale City",
                            "latitude": "38.63706000",
                            "longitude": "-77.31109000"
                        },
                        {
                            "id": 114984,
                            "name": "Daleville",
                            "latitude": "37.40986000",
                            "longitude": "-79.91254000"
                        },
                        {
                            "id": 115039,
                            "name": "Danville",
                            "latitude": "36.58597000",
                            "longitude": "-79.39502000"
                        },
                        {
                            "id": 115094,
                            "name": "Dayton",
                            "latitude": "38.41485000",
                            "longitude": "-78.93864000"
                        },
                        {
                            "id": 115228,
                            "name": "Deltaville",
                            "latitude": "37.55486000",
                            "longitude": "-76.33689000"
                        },
                        {
                            "id": 115322,
                            "name": "Dickenson County",
                            "latitude": "37.12574000",
                            "longitude": "-82.35035000"
                        },
                        {
                            "id": 115352,
                            "name": "Dinwiddie County",
                            "latitude": "37.07590000",
                            "longitude": "-77.63236000"
                        },
                        {
                            "id": 115405,
                            "name": "Dooms",
                            "latitude": "38.10902000",
                            "longitude": "-78.85752000"
                        },
                        {
                            "id": 115468,
                            "name": "Dranesville",
                            "latitude": "39.00067000",
                            "longitude": "-77.34582000"
                        },
                        {
                            "id": 115484,
                            "name": "Dryden",
                            "latitude": "36.77759000",
                            "longitude": "-82.94155000"
                        },
                        {
                            "id": 115493,
                            "name": "Dublin",
                            "latitude": "37.10568000",
                            "longitude": "-80.68534000"
                        },
                        {
                            "id": 115511,
                            "name": "Dulles Town Center",
                            "latitude": "39.03761000",
                            "longitude": "-77.41582000"
                        },
                        {
                            "id": 115516,
                            "name": "Dumbarton",
                            "latitude": "37.60376000",
                            "longitude": "-77.49137000"
                        },
                        {
                            "id": 115517,
                            "name": "Dumfries",
                            "latitude": "38.56762000",
                            "longitude": "-77.32804000"
                        },
                        {
                            "id": 115549,
                            "name": "Dunn Loring",
                            "latitude": "38.89344000",
                            "longitude": "-77.22165000"
                        },
                        {
                            "id": 115679,
                            "name": "East Hampton",
                            "latitude": "37.03737000",
                            "longitude": "-76.33161000"
                        },
                        {
                            "id": 115690,
                            "name": "East Highland Park",
                            "latitude": "37.58098000",
                            "longitude": "-77.40693000"
                        },
                        {
                            "id": 115704,
                            "name": "East Lexington",
                            "latitude": "37.79291000",
                            "longitude": "-79.42532000"
                        },
                        {
                            "id": 115798,
                            "name": "Eastville",
                            "latitude": "37.35264000",
                            "longitude": "-75.94576000"
                        },
                        {
                            "id": 115871,
                            "name": "Edinburg",
                            "latitude": "38.82095000",
                            "longitude": "-78.56585000"
                        },
                        {
                            "id": 116014,
                            "name": "Elkton",
                            "latitude": "38.40790000",
                            "longitude": "-78.62363000"
                        },
                        {
                            "id": 116109,
                            "name": "Emory",
                            "latitude": "36.77289000",
                            "longitude": "-81.83623000"
                        },
                        {
                            "id": 116112,
                            "name": "Emporia",
                            "latitude": "36.68598000",
                            "longitude": "-77.54248000"
                        },
                        {
                            "id": 116145,
                            "name": "Enon",
                            "latitude": "37.33070000",
                            "longitude": "-77.32276000"
                        },
                        {
                            "id": 116193,
                            "name": "Essex County",
                            "latitude": "37.93906000",
                            "longitude": "-76.94090000"
                        },
                        {
                            "id": 116220,
                            "name": "Ettrick",
                            "latitude": "37.24015000",
                            "longitude": "-77.42998000"
                        },
                        {
                            "id": 116275,
                            "name": "Exmore",
                            "latitude": "37.53180000",
                            "longitude": "-75.82299000"
                        },
                        {
                            "id": 116303,
                            "name": "Fairfax",
                            "latitude": "38.84622000",
                            "longitude": "-77.30637000"
                        },
                        {
                            "id": 116307,
                            "name": "Fairfax County",
                            "latitude": "38.83469000",
                            "longitude": "-77.27622000"
                        },
                        {
                            "id": 116308,
                            "name": "Fairfax Station",
                            "latitude": "38.80095000",
                            "longitude": "-77.32554000"
                        },
                        {
                            "id": 116330,
                            "name": "Fairlawn",
                            "latitude": "37.14846000",
                            "longitude": "-80.57839000"
                        },
                        {
                            "id": 116380,
                            "name": "Falls Church",
                            "latitude": "38.88233000",
                            "longitude": "-77.17109000"
                        },
                        {
                            "id": 116387,
                            "name": "Falmouth",
                            "latitude": "38.32402000",
                            "longitude": "-77.46832000"
                        },
                        {
                            "id": 116427,
                            "name": "Farmville",
                            "latitude": "37.30210000",
                            "longitude": "-78.39194000"
                        },
                        {
                            "id": 116436,
                            "name": "Fauquier County",
                            "latitude": "38.73855000",
                            "longitude": "-77.80927000"
                        },
                        {
                            "id": 116497,
                            "name": "Ferrum",
                            "latitude": "36.92292000",
                            "longitude": "-80.01337000"
                        },
                        {
                            "id": 116514,
                            "name": "Fincastle",
                            "latitude": "37.49930000",
                            "longitude": "-79.87726000"
                        },
                        {
                            "id": 116530,
                            "name": "Fishersville",
                            "latitude": "38.09902000",
                            "longitude": "-78.96919000"
                        },
                        {
                            "id": 116596,
                            "name": "Floris",
                            "latitude": "38.93706000",
                            "longitude": "-77.41277000"
                        },
                        {
                            "id": 116605,
                            "name": "Floyd",
                            "latitude": "36.91124000",
                            "longitude": "-80.32005000"
                        },
                        {
                            "id": 116609,
                            "name": "Floyd County",
                            "latitude": "36.93149000",
                            "longitude": "-80.36255000"
                        },
                        {
                            "id": 116614,
                            "name": "Fluvanna County",
                            "latitude": "37.84187000",
                            "longitude": "-78.27745000"
                        },
                        {
                            "id": 116644,
                            "name": "Forest",
                            "latitude": "37.36375000",
                            "longitude": "-79.28975000"
                        },
                        {
                            "id": 116691,
                            "name": "Fort Belvoir",
                            "latitude": "38.71190000",
                            "longitude": "-77.14589000"
                        },
                        {
                            "id": 116718,
                            "name": "Fort Hunt",
                            "latitude": "38.73289000",
                            "longitude": "-77.05803000"
                        },
                        {
                            "id": 116724,
                            "name": "Fort Lee",
                            "latitude": "37.24694000",
                            "longitude": "-77.33442000"
                        },
                        {
                            "id": 116816,
                            "name": "Franconia",
                            "latitude": "38.78206000",
                            "longitude": "-77.14637000"
                        },
                        {
                            "id": 116831,
                            "name": "Franklin",
                            "latitude": "36.67765000",
                            "longitude": "-76.92246000"
                        },
                        {
                            "id": 116856,
                            "name": "Franklin County",
                            "latitude": "36.99194000",
                            "longitude": "-79.88104000"
                        },
                        {
                            "id": 116889,
                            "name": "Frederick County",
                            "latitude": "39.20456000",
                            "longitude": "-78.26258000"
                        },
                        {
                            "id": 116891,
                            "name": "Fredericksburg",
                            "latitude": "38.30318000",
                            "longitude": "-77.46054000"
                        },
                        {
                            "id": 116960,
                            "name": "Front Royal",
                            "latitude": "38.91817000",
                            "longitude": "-78.19444000"
                        },
                        {
                            "id": 117018,
                            "name": "Gainesville",
                            "latitude": "38.79567000",
                            "longitude": "-77.61388000"
                        },
                        {
                            "id": 117020,
                            "name": "Galax",
                            "latitude": "36.66124000",
                            "longitude": "-80.92397000"
                        },
                        {
                            "id": 117118,
                            "name": "Gate City",
                            "latitude": "36.63788000",
                            "longitude": "-82.58099000"
                        },
                        {
                            "id": 117211,
                            "name": "Giles County",
                            "latitude": "37.31403000",
                            "longitude": "-80.70374000"
                        },
                        {
                            "id": 117232,
                            "name": "Glade Spring",
                            "latitude": "36.79122000",
                            "longitude": "-81.77123000"
                        },
                        {
                            "id": 117248,
                            "name": "Glasgow",
                            "latitude": "37.63402000",
                            "longitude": "-79.45031000"
                        },
                        {
                            "id": 117259,
                            "name": "Glen Allen",
                            "latitude": "37.66598000",
                            "longitude": "-77.50637000"
                        },
                        {
                            "id": 117325,
                            "name": "Gloucester County",
                            "latitude": "37.40121000",
                            "longitude": "-76.52297000"
                        },
                        {
                            "id": 117326,
                            "name": "Gloucester Courthouse",
                            "latitude": "37.40986000",
                            "longitude": "-76.52662000"
                        },
                        {
                            "id": 117327,
                            "name": "Gloucester Point",
                            "latitude": "37.25403000",
                            "longitude": "-76.49689000"
                        },
                        {
                            "id": 117375,
                            "name": "Goochland",
                            "latitude": "37.68431000",
                            "longitude": "-77.88527000"
                        },
                        {
                            "id": 117376,
                            "name": "Goochland County",
                            "latitude": "37.72198000",
                            "longitude": "-77.91636000"
                        },
                        {
                            "id": 117401,
                            "name": "Gordonsville",
                            "latitude": "38.13736000",
                            "longitude": "-78.18778000"
                        },
                        {
                            "id": 117564,
                            "name": "Grayson County",
                            "latitude": "36.65659000",
                            "longitude": "-81.22505000"
                        },
                        {
                            "id": 117572,
                            "name": "Great Falls",
                            "latitude": "38.99817000",
                            "longitude": "-77.28832000"
                        },
                        {
                            "id": 117617,
                            "name": "Greenbriar",
                            "latitude": "38.87345000",
                            "longitude": "-77.40082000"
                        },
                        {
                            "id": 117640,
                            "name": "Greene County",
                            "latitude": "38.29760000",
                            "longitude": "-78.46688000"
                        },
                        {
                            "id": 117673,
                            "name": "Greensville County",
                            "latitude": "36.67585000",
                            "longitude": "-77.55958000"
                        },
                        {
                            "id": 117726,
                            "name": "Gretna",
                            "latitude": "36.95375000",
                            "longitude": "-79.35891000"
                        },
                        {
                            "id": 117753,
                            "name": "Grottoes",
                            "latitude": "38.26735000",
                            "longitude": "-78.82586000"
                        },
                        {
                            "id": 117763,
                            "name": "Groveton",
                            "latitude": "38.76734000",
                            "longitude": "-77.08470000"
                        },
                        {
                            "id": 117768,
                            "name": "Grundy",
                            "latitude": "37.27789000",
                            "longitude": "-82.09902000"
                        },
                        {
                            "id": 117860,
                            "name": "Halifax",
                            "latitude": "36.76597000",
                            "longitude": "-78.92834000"
                        },
                        {
                            "id": 117863,
                            "name": "Halifax County",
                            "latitude": "36.76695000",
                            "longitude": "-78.93662000"
                        },
                        {
                            "id": 117919,
                            "name": "Hampden Sydney",
                            "latitude": "37.24237000",
                            "longitude": "-78.45972000"
                        },
                        {
                            "id": 117931,
                            "name": "Hampton",
                            "latitude": "37.02987000",
                            "longitude": "-76.34522000"
                        },
                        {
                            "id": 117969,
                            "name": "Hanover",
                            "latitude": "37.76653000",
                            "longitude": "-77.37026000"
                        },
                        {
                            "id": 117974,
                            "name": "Hanover County",
                            "latitude": "37.76015000",
                            "longitude": "-77.49087000"
                        },
                        {
                            "id": 118070,
                            "name": "Harrisonburg",
                            "latitude": "38.44957000",
                            "longitude": "-78.86892000"
                        },
                        {
                            "id": 118184,
                            "name": "Hayfield",
                            "latitude": "38.75178000",
                            "longitude": "-77.13581000"
                        },
                        {
                            "id": 118187,
                            "name": "Haymarket",
                            "latitude": "38.81206000",
                            "longitude": "-77.63638000"
                        },
                        {
                            "id": 118225,
                            "name": "Heathsville",
                            "latitude": "37.91763000",
                            "longitude": "-76.47217000"
                        },
                        {
                            "id": 118294,
                            "name": "Henrico County",
                            "latitude": "37.45771000",
                            "longitude": "-77.29646000"
                        },
                        {
                            "id": 118303,
                            "name": "Henry County",
                            "latitude": "36.68470000",
                            "longitude": "-79.98152000"
                        },
                        {
                            "id": 118306,
                            "name": "Henry Fork",
                            "latitude": "36.96625000",
                            "longitude": "-79.87031000"
                        },
                        {
                            "id": 118336,
                            "name": "Herndon",
                            "latitude": "38.96955000",
                            "longitude": "-77.38610000"
                        },
                        {
                            "id": 118404,
                            "name": "Highland County",
                            "latitude": "38.36233000",
                            "longitude": "-79.56854000"
                        },
                        {
                            "id": 118415,
                            "name": "Highland Springs",
                            "latitude": "37.54598000",
                            "longitude": "-77.32776000"
                        },
                        {
                            "id": 118468,
                            "name": "Hillsville",
                            "latitude": "36.76263000",
                            "longitude": "-80.73479000"
                        },
                        {
                            "id": 118546,
                            "name": "Hollins",
                            "latitude": "37.34125000",
                            "longitude": "-79.94310000"
                        },
                        {
                            "id": 118564,
                            "name": "Hollymead",
                            "latitude": "38.11708000",
                            "longitude": "-78.44168000"
                        },
                        {
                            "id": 118612,
                            "name": "Honaker",
                            "latitude": "37.01622000",
                            "longitude": "-81.97429000"
                        },
                        {
                            "id": 118649,
                            "name": "Hopewell",
                            "latitude": "37.30432000",
                            "longitude": "-77.28720000"
                        },
                        {
                            "id": 118671,
                            "name": "Horse Pasture",
                            "latitude": "36.62847000",
                            "longitude": "-79.95087000"
                        },
                        {
                            "id": 118799,
                            "name": "Huntington",
                            "latitude": "38.79234000",
                            "longitude": "-77.07081000"
                        },
                        {
                            "id": 118832,
                            "name": "Hurt",
                            "latitude": "37.09264000",
                            "longitude": "-79.29641000"
                        },
                        {
                            "id": 118845,
                            "name": "Hybla Valley",
                            "latitude": "38.74761000",
                            "longitude": "-77.08303000"
                        },
                        {
                            "id": 118876,
                            "name": "Idylwood",
                            "latitude": "38.89511000",
                            "longitude": "-77.21165000"
                        },
                        {
                            "id": 118894,
                            "name": "Independence",
                            "latitude": "36.62245000",
                            "longitude": "-81.15309000"
                        },
                        {
                            "id": 118899,
                            "name": "Independent Hill",
                            "latitude": "38.63595000",
                            "longitude": "-77.43776000"
                        },
                        {
                            "id": 119033,
                            "name": "Isle of Wight County",
                            "latitude": "36.90673000",
                            "longitude": "-76.70913000"
                        },
                        {
                            "id": 119116,
                            "name": "James City County",
                            "latitude": "37.31332000",
                            "longitude": "-76.77376000"
                        },
                        {
                            "id": 119167,
                            "name": "Jefferson",
                            "latitude": "38.86456000",
                            "longitude": "-77.18776000"
                        },
                        {
                            "id": 119289,
                            "name": "Jolivue",
                            "latitude": "38.10985000",
                            "longitude": "-79.07308000"
                        },
                        {
                            "id": 119311,
                            "name": "Jonesville",
                            "latitude": "36.68898000",
                            "longitude": "-83.11100000"
                        },
                        {
                            "id": 119443,
                            "name": "Kenbridge",
                            "latitude": "36.96209000",
                            "longitude": "-78.12500000"
                        },
                        {
                            "id": 119563,
                            "name": "Kilmarnock",
                            "latitude": "37.71041000",
                            "longitude": "-76.37967000"
                        },
                        {
                            "id": 119588,
                            "name": "King and Queen County",
                            "latitude": "37.71862000",
                            "longitude": "-76.89527000"
                        },
                        {
                            "id": 119589,
                            "name": "King and Queen Court House",
                            "latitude": "37.66986000",
                            "longitude": "-76.87746000"
                        },
                        {
                            "id": 119584,
                            "name": "King George",
                            "latitude": "38.26818000",
                            "longitude": "-77.18442000"
                        },
                        {
                            "id": 119585,
                            "name": "King George County",
                            "latitude": "38.27374000",
                            "longitude": "-77.15651000"
                        },
                        {
                            "id": 119586,
                            "name": "King William",
                            "latitude": "37.68736000",
                            "longitude": "-77.01358000"
                        },
                        {
                            "id": 119587,
                            "name": "King William County",
                            "latitude": "37.70660000",
                            "longitude": "-77.08839000"
                        },
                        {
                            "id": 119604,
                            "name": "Kings Park",
                            "latitude": "38.80622000",
                            "longitude": "-77.24332000"
                        },
                        {
                            "id": 119606,
                            "name": "Kings Park West",
                            "latitude": "38.81443000",
                            "longitude": "-77.29582000"
                        },
                        {
                            "id": 119868,
                            "name": "Lake Barcroft",
                            "latitude": "38.84789000",
                            "longitude": "-77.15581000"
                        },
                        {
                            "id": 119947,
                            "name": "Lake Monticello",
                            "latitude": "37.92320000",
                            "longitude": "-78.33473000"
                        },
                        {
                            "id": 119968,
                            "name": "Lake Ridge",
                            "latitude": "38.68789000",
                            "longitude": "-77.29776000"
                        },
                        {
                            "id": 120031,
                            "name": "Lakeside",
                            "latitude": "37.60765000",
                            "longitude": "-77.47693000"
                        },
                        {
                            "id": 120091,
                            "name": "Lancaster County",
                            "latitude": "37.70171000",
                            "longitude": "-76.42023000"
                        },
                        {
                            "id": 120191,
                            "name": "Laurel",
                            "latitude": "37.64292000",
                            "longitude": "-77.50887000"
                        },
                        {
                            "id": 120197,
                            "name": "Laurel Hill",
                            "latitude": "38.71663000",
                            "longitude": "-77.23686000"
                        },
                        {
                            "id": 120245,
                            "name": "Lawrenceville",
                            "latitude": "36.75765000",
                            "longitude": "-77.84694000"
                        },
                        {
                            "id": 120253,
                            "name": "Laymantown",
                            "latitude": "37.36569000",
                            "longitude": "-79.85754000"
                        },
                        {
                            "id": 120288,
                            "name": "Lebanon",
                            "latitude": "36.90094000",
                            "longitude": "-82.08013000"
                        },
                        {
                            "id": 120315,
                            "name": "Lee County",
                            "latitude": "36.70545000",
                            "longitude": "-83.12853000"
                        },
                        {
                            "id": 120326,
                            "name": "Leesburg",
                            "latitude": "39.11566000",
                            "longitude": "-77.56360000"
                        },
                        {
                            "id": 120447,
                            "name": "Lexington",
                            "latitude": "37.78402000",
                            "longitude": "-79.44282000"
                        },
                        {
                            "id": 120549,
                            "name": "Lincolnia",
                            "latitude": "38.81845000",
                            "longitude": "-77.14331000"
                        },
                        {
                            "id": 120591,
                            "name": "Linton Hall",
                            "latitude": "38.75984000",
                            "longitude": "-77.57499000"
                        },
                        {
                            "id": 120669,
                            "name": "Loch Lomond",
                            "latitude": "38.78623000",
                            "longitude": "-77.47804000"
                        },
                        {
                            "id": 120777,
                            "name": "Lorton",
                            "latitude": "38.70428000",
                            "longitude": "-77.22776000"
                        },
                        {
                            "id": 120801,
                            "name": "Loudoun County",
                            "latitude": "39.09068000",
                            "longitude": "-77.63572000"
                        },
                        {
                            "id": 120802,
                            "name": "Loudoun Valley Estates",
                            "latitude": "38.98081000",
                            "longitude": "-77.50790000"
                        },
                        {
                            "id": 120805,
                            "name": "Louisa",
                            "latitude": "38.02514000",
                            "longitude": "-78.00416000"
                        },
                        {
                            "id": 120806,
                            "name": "Louisa County",
                            "latitude": "37.97821000",
                            "longitude": "-77.96298000"
                        },
                        {
                            "id": 120827,
                            "name": "Lovettsville",
                            "latitude": "39.27260000",
                            "longitude": "-77.63666000"
                        },
                        {
                            "id": 120830,
                            "name": "Lovingston",
                            "latitude": "37.75986000",
                            "longitude": "-78.87086000"
                        },
                        {
                            "id": 120844,
                            "name": "Lowes Island",
                            "latitude": "39.05983000",
                            "longitude": "-77.35221000"
                        },
                        {
                            "id": 120886,
                            "name": "Lunenburg",
                            "latitude": "36.96098000",
                            "longitude": "-78.26555000"
                        },
                        {
                            "id": 120889,
                            "name": "Lunenburg County",
                            "latitude": "36.94621000",
                            "longitude": "-78.24057000"
                        },
                        {
                            "id": 120890,
                            "name": "Luray",
                            "latitude": "38.66540000",
                            "longitude": "-78.45945000"
                        },
                        {
                            "id": 120916,
                            "name": "Lynchburg",
                            "latitude": "37.41375000",
                            "longitude": "-79.14225000"
                        },
                        {
                            "id": 120920,
                            "name": "Lyndhurst",
                            "latitude": "38.02930000",
                            "longitude": "-78.94502000"
                        },
                        {
                            "id": 120991,
                            "name": "Madison",
                            "latitude": "38.38041000",
                            "longitude": "-78.25750000"
                        },
                        {
                            "id": 121013,
                            "name": "Madison County",
                            "latitude": "38.41369000",
                            "longitude": "-78.27924000"
                        },
                        {
                            "id": 121020,
                            "name": "Madison Heights",
                            "latitude": "37.43098000",
                            "longitude": "-79.12308000"
                        },
                        {
                            "id": 121082,
                            "name": "Manassas",
                            "latitude": "38.75095000",
                            "longitude": "-77.47527000"
                        },
                        {
                            "id": 121083,
                            "name": "Manassas Park",
                            "latitude": "38.78400000",
                            "longitude": "-77.46971000"
                        },
                        {
                            "id": 121160,
                            "name": "Mantua",
                            "latitude": "38.85372000",
                            "longitude": "-77.25943000"
                        },
                        {
                            "id": 121241,
                            "name": "Marion",
                            "latitude": "36.83484000",
                            "longitude": "-81.51484000"
                        },
                        {
                            "id": 121306,
                            "name": "Marshall",
                            "latitude": "38.86484000",
                            "longitude": "-77.85777000"
                        },
                        {
                            "id": 121350,
                            "name": "Martinsville",
                            "latitude": "36.69153000",
                            "longitude": "-79.87254000"
                        },
                        {
                            "id": 121389,
                            "name": "Massanetta Springs",
                            "latitude": "38.40040000",
                            "longitude": "-78.83419000"
                        },
                        {
                            "id": 121390,
                            "name": "Massanutten",
                            "latitude": "38.40957000",
                            "longitude": "-78.73780000"
                        },
                        {
                            "id": 121403,
                            "name": "Mathews",
                            "latitude": "37.43708000",
                            "longitude": "-76.31994000"
                        },
                        {
                            "id": 121404,
                            "name": "Mathews County",
                            "latitude": "37.41731000",
                            "longitude": "-76.27129000"
                        },
                        {
                            "id": 121406,
                            "name": "Matoaca",
                            "latitude": "37.23043000",
                            "longitude": "-77.47749000"
                        },
                        {
                            "id": 121527,
                            "name": "McLean",
                            "latitude": "38.93428000",
                            "longitude": "-77.17748000"
                        },
                        {
                            "id": 121566,
                            "name": "Meadowbrook",
                            "latitude": "37.44882000",
                            "longitude": "-77.47353000"
                        },
                        {
                            "id": 121583,
                            "name": "Mechanicsville",
                            "latitude": "37.60876000",
                            "longitude": "-77.37331000"
                        },
                        {
                            "id": 121588,
                            "name": "Mecklenburg County",
                            "latitude": "36.68036000",
                            "longitude": "-78.36273000"
                        },
                        {
                            "id": 121706,
                            "name": "Merrifield",
                            "latitude": "38.87428000",
                            "longitude": "-77.22693000"
                        },
                        {
                            "id": 121709,
                            "name": "Merrimac",
                            "latitude": "37.18957000",
                            "longitude": "-80.42561000"
                        },
                        {
                            "id": 121778,
                            "name": "Middlesex County",
                            "latitude": "37.59737000",
                            "longitude": "-76.57814000"
                        },
                        {
                            "id": 121789,
                            "name": "Middletown",
                            "latitude": "39.02761000",
                            "longitude": "-78.28056000"
                        },
                        {
                            "id": 122105,
                            "name": "Montclair",
                            "latitude": "38.61095000",
                            "longitude": "-77.33971000"
                        },
                        {
                            "id": 122119,
                            "name": "Monterey",
                            "latitude": "38.41234000",
                            "longitude": "-79.58060000"
                        },
                        {
                            "id": 122151,
                            "name": "Montgomery County",
                            "latitude": "37.17404000",
                            "longitude": "-80.38700000"
                        },
                        {
                            "id": 122179,
                            "name": "Montrose",
                            "latitude": "37.52070000",
                            "longitude": "-77.37831000"
                        },
                        {
                            "id": 122186,
                            "name": "Montross",
                            "latitude": "38.09513000",
                            "longitude": "-76.82746000"
                        },
                        {
                            "id": 122310,
                            "name": "Motley",
                            "latitude": "37.06959000",
                            "longitude": "-79.34114000"
                        },
                        {
                            "id": 122346,
                            "name": "Mount Hermon",
                            "latitude": "36.67847000",
                            "longitude": "-79.42225000"
                        },
                        {
                            "id": 122357,
                            "name": "Mount Jackson",
                            "latitude": "38.74595000",
                            "longitude": "-78.64224000"
                        },
                        {
                            "id": 122404,
                            "name": "Mount Vernon",
                            "latitude": "38.71919000",
                            "longitude": "-77.10726000"
                        },
                        {
                            "id": 122426,
                            "name": "Mountain Road",
                            "latitude": "36.75958000",
                            "longitude": "-78.98696000"
                        },
                        {
                            "id": 122566,
                            "name": "Narrows",
                            "latitude": "37.33151000",
                            "longitude": "-80.81119000"
                        },
                        {
                            "id": 122623,
                            "name": "Nellysford",
                            "latitude": "37.89042000",
                            "longitude": "-78.87224000"
                        },
                        {
                            "id": 122627,
                            "name": "Nelson County",
                            "latitude": "37.78741000",
                            "longitude": "-78.88676000"
                        },
                        {
                            "id": 122659,
                            "name": "New Baltimore",
                            "latitude": "38.76734000",
                            "longitude": "-77.72833000"
                        },
                        {
                            "id": 122686,
                            "name": "New Castle",
                            "latitude": "37.50013000",
                            "longitude": "-80.11088000"
                        },
                        {
                            "id": 122734,
                            "name": "New Kent",
                            "latitude": "37.51765000",
                            "longitude": "-76.97886000"
                        },
                        {
                            "id": 122735,
                            "name": "New Kent County",
                            "latitude": "37.50514000",
                            "longitude": "-76.99713000"
                        },
                        {
                            "id": 122751,
                            "name": "New Market",
                            "latitude": "38.64790000",
                            "longitude": "-78.67141000"
                        },
                        {
                            "id": 122830,
                            "name": "Newington",
                            "latitude": "38.73845000",
                            "longitude": "-77.18498000"
                        },
                        {
                            "id": 122856,
                            "name": "Newport News",
                            "latitude": "37.08339000",
                            "longitude": "-76.46965000"
                        },
                        {
                            "id": 122928,
                            "name": "Nokesville",
                            "latitude": "38.69873000",
                            "longitude": "-77.57971000"
                        },
                        {
                            "id": 122941,
                            "name": "Norfolk",
                            "latitude": "36.84681000",
                            "longitude": "-76.28522000"
                        },
                        {
                            "id": 123105,
                            "name": "North Shore",
                            "latitude": "37.08209000",
                            "longitude": "-79.65836000"
                        },
                        {
                            "id": 123110,
                            "name": "North Springfield",
                            "latitude": "38.80428000",
                            "longitude": "-77.20470000"
                        },
                        {
                            "id": 123137,
                            "name": "Northampton County",
                            "latitude": "37.30078000",
                            "longitude": "-75.92854000"
                        },
                        {
                            "id": 123167,
                            "name": "Northumberland County",
                            "latitude": "37.88244000",
                            "longitude": "-76.47171000"
                        },
                        {
                            "id": 123183,
                            "name": "Norton",
                            "latitude": "36.93343000",
                            "longitude": "-82.62905000"
                        },
                        {
                            "id": 123206,
                            "name": "Nottoway County",
                            "latitude": "37.14306000",
                            "longitude": "-78.05126000"
                        },
                        {
                            "id": 123238,
                            "name": "Oak Grove",
                            "latitude": "38.98400000",
                            "longitude": "-77.40388000"
                        },
                        {
                            "id": 123246,
                            "name": "Oak Hill",
                            "latitude": "38.92580000",
                            "longitude": "-77.40156000"
                        },
                        {
                            "id": 123305,
                            "name": "Oakton",
                            "latitude": "38.88095000",
                            "longitude": "-77.30082000"
                        },
                        {
                            "id": 123322,
                            "name": "Occoquan",
                            "latitude": "38.68373000",
                            "longitude": "-77.26026000"
                        },
                        {
                            "id": 123469,
                            "name": "Onancock",
                            "latitude": "37.71180000",
                            "longitude": "-75.74910000"
                        },
                        {
                            "id": 123506,
                            "name": "Orange",
                            "latitude": "38.24541000",
                            "longitude": "-78.11083000"
                        },
                        {
                            "id": 123518,
                            "name": "Orange County",
                            "latitude": "38.24624000",
                            "longitude": "-78.01349000"
                        },
                        {
                            "id": 123718,
                            "name": "Page County",
                            "latitude": "38.61998000",
                            "longitude": "-78.48413000"
                        },
                        {
                            "id": 123773,
                            "name": "Palmyra",
                            "latitude": "37.86097000",
                            "longitude": "-78.26334000"
                        },
                        {
                            "id": 123802,
                            "name": "Pannill Fork",
                            "latitude": "36.70903000",
                            "longitude": "-80.01310000"
                        },
                        {
                            "id": 123809,
                            "name": "Pantops",
                            "latitude": "38.03379000",
                            "longitude": "-78.45507000"
                        },
                        {
                            "id": 123909,
                            "name": "Passapatanzy",
                            "latitude": "38.29735000",
                            "longitude": "-77.31415000"
                        },
                        {
                            "id": 123912,
                            "name": "Patrick County",
                            "latitude": "36.67833000",
                            "longitude": "-80.28435000"
                        },
                        {
                            "id": 123913,
                            "name": "Patrick Springs",
                            "latitude": "36.64180000",
                            "longitude": "-80.19505000"
                        },
                        {
                            "id": 123962,
                            "name": "Pearisburg",
                            "latitude": "37.32673000",
                            "longitude": "-80.73702000"
                        },
                        {
                            "id": 124006,
                            "name": "Pembroke",
                            "latitude": "37.31957000",
                            "longitude": "-80.63895000"
                        },
                        {
                            "id": 124032,
                            "name": "Pennington Gap",
                            "latitude": "36.75842000",
                            "longitude": "-83.02711000"
                        },
                        {
                            "id": 124105,
                            "name": "Petersburg",
                            "latitude": "37.22793000",
                            "longitude": "-77.40193000"
                        },
                        {
                            "id": 124205,
                            "name": "Pimmit Hills",
                            "latitude": "38.91289000",
                            "longitude": "-77.20081000"
                        },
                        {
                            "id": 124306,
                            "name": "Pittsylvania County",
                            "latitude": "36.82133000",
                            "longitude": "-79.39711000"
                        },
                        {
                            "id": 124393,
                            "name": "Plum Creek",
                            "latitude": "37.12984000",
                            "longitude": "-80.50060000"
                        },
                        {
                            "id": 124489,
                            "name": "Poquoson",
                            "latitude": "37.12237000",
                            "longitude": "-76.34578000"
                        },
                        {
                            "id": 124569,
                            "name": "Portsmouth",
                            "latitude": "36.83543000",
                            "longitude": "-76.29827000"
                        },
                        {
                            "id": 124572,
                            "name": "Portsmouth Heights",
                            "latitude": "36.82098000",
                            "longitude": "-76.36883000"
                        },
                        {
                            "id": 124584,
                            "name": "Potomac Mills",
                            "latitude": "38.64595000",
                            "longitude": "-77.29415000"
                        },
                        {
                            "id": 124618,
                            "name": "Powhatan County",
                            "latitude": "37.55020000",
                            "longitude": "-77.91519000"
                        },
                        {
                            "id": 124665,
                            "name": "Prices Fork",
                            "latitude": "37.20985000",
                            "longitude": "-80.49005000"
                        },
                        {
                            "id": 124672,
                            "name": "Prince Edward County",
                            "latitude": "37.22430000",
                            "longitude": "-78.44108000"
                        },
                        {
                            "id": 124674,
                            "name": "Prince George",
                            "latitude": "37.22043000",
                            "longitude": "-77.28803000"
                        },
                        {
                            "id": 124675,
                            "name": "Prince George County",
                            "latitude": "37.18653000",
                            "longitude": "-77.22413000"
                        },
                        {
                            "id": 124677,
                            "name": "Prince William County",
                            "latitude": "38.70167000",
                            "longitude": "-77.47766000"
                        },
                        {
                            "id": 124734,
                            "name": "Pulaski",
                            "latitude": "37.04790000",
                            "longitude": "-80.77979000"
                        },
                        {
                            "id": 124742,
                            "name": "Pulaski County",
                            "latitude": "37.06361000",
                            "longitude": "-80.71434000"
                        },
                        {
                            "id": 124754,
                            "name": "Purcellville",
                            "latitude": "39.13677000",
                            "longitude": "-77.71472000"
                        },
                        {
                            "id": 124780,
                            "name": "Quantico Station",
                            "latitude": "38.52263000",
                            "longitude": "-77.31834000"
                        },
                        {
                            "id": 124816,
                            "name": "Radford",
                            "latitude": "37.13179000",
                            "longitude": "-80.57645000"
                        },
                        {
                            "id": 124898,
                            "name": "Rappahannock County",
                            "latitude": "38.68471000",
                            "longitude": "-78.15925000"
                        },
                        {
                            "id": 124904,
                            "name": "Raven",
                            "latitude": "37.08706000",
                            "longitude": "-81.85512000"
                        },
                        {
                            "id": 124911,
                            "name": "Ravensworth",
                            "latitude": "38.80400000",
                            "longitude": "-77.22054000"
                        },
                        {
                            "id": 125036,
                            "name": "Reston",
                            "latitude": "38.96872000",
                            "longitude": "-77.34110000"
                        },
                        {
                            "id": 125082,
                            "name": "Richlands",
                            "latitude": "37.09317000",
                            "longitude": "-81.79373000"
                        },
                        {
                            "id": 125088,
                            "name": "Richmond",
                            "latitude": "37.55376000",
                            "longitude": "-77.46026000"
                        },
                        {
                            "id": 125100,
                            "name": "Richmond County",
                            "latitude": "37.93705000",
                            "longitude": "-76.72968000"
                        },
                        {
                            "id": 125228,
                            "name": "Roanoke",
                            "latitude": "37.27097000",
                            "longitude": "-79.94143000"
                        },
                        {
                            "id": 125231,
                            "name": "Roanoke County",
                            "latitude": "37.20907000",
                            "longitude": "-80.05085000"
                        },
                        {
                            "id": 125292,
                            "name": "Rockbridge County",
                            "latitude": "37.81461000",
                            "longitude": "-79.44758000"
                        },
                        {
                            "id": 125306,
                            "name": "Rockingham County",
                            "latitude": "38.51213000",
                            "longitude": "-78.87576000"
                        },
                        {
                            "id": 125336,
                            "name": "Rocky Mount",
                            "latitude": "36.99764000",
                            "longitude": "-79.89198000"
                        },
                        {
                            "id": 125405,
                            "name": "Rose Hill",
                            "latitude": "38.78872000",
                            "longitude": "-77.11276000"
                        },
                        {
                            "id": 125452,
                            "name": "Rosslyn",
                            "latitude": "38.89678000",
                            "longitude": "-77.07248000"
                        },
                        {
                            "id": 125502,
                            "name": "Ruckersville",
                            "latitude": "38.23319000",
                            "longitude": "-78.36917000"
                        },
                        {
                            "id": 125518,
                            "name": "Rural Retreat",
                            "latitude": "36.89373000",
                            "longitude": "-81.27593000"
                        },
                        {
                            "id": 125524,
                            "name": "Rushmere",
                            "latitude": "37.06681000",
                            "longitude": "-76.67635000"
                        },
                        {
                            "id": 125539,
                            "name": "Russell County",
                            "latitude": "36.93376000",
                            "longitude": "-82.09564000"
                        },
                        {
                            "id": 125546,
                            "name": "Rustburg",
                            "latitude": "37.27681000",
                            "longitude": "-79.10085000"
                        },
                        {
                            "id": 125734,
                            "name": "Salem",
                            "latitude": "37.29347000",
                            "longitude": "-80.05476000"
                        },
                        {
                            "id": 125771,
                            "name": "Saltville",
                            "latitude": "36.88150000",
                            "longitude": "-81.76206000"
                        },
                        {
                            "id": 125773,
                            "name": "Saluda",
                            "latitude": "37.60597000",
                            "longitude": "-76.59495000"
                        },
                        {
                            "id": 125873,
                            "name": "Sandston",
                            "latitude": "37.52348000",
                            "longitude": "-77.31581000"
                        },
                        {
                            "id": 126050,
                            "name": "Scott County",
                            "latitude": "36.71422000",
                            "longitude": "-82.60298000"
                        },
                        {
                            "id": 126171,
                            "name": "Seven Corners",
                            "latitude": "38.87206000",
                            "longitude": "-77.15526000"
                        },
                        {
                            "id": 126252,
                            "name": "Shawnee Land",
                            "latitude": "39.19149000",
                            "longitude": "-78.34556000"
                        },
                        {
                            "id": 126254,
                            "name": "Shawsville",
                            "latitude": "37.16847000",
                            "longitude": "-80.25532000"
                        },
                        {
                            "id": 126303,
                            "name": "Shenandoah",
                            "latitude": "38.48512000",
                            "longitude": "-78.62502000"
                        },
                        {
                            "id": 126305,
                            "name": "Shenandoah County",
                            "latitude": "38.85839000",
                            "longitude": "-78.57060000"
                        },
                        {
                            "id": 126306,
                            "name": "Shenandoah Farms",
                            "latitude": "38.98178000",
                            "longitude": "-78.07555000"
                        },
                        {
                            "id": 126377,
                            "name": "Short Pump",
                            "latitude": "37.65042000",
                            "longitude": "-77.61249000"
                        },
                        {
                            "id": 126519,
                            "name": "Smithfield",
                            "latitude": "36.98237000",
                            "longitude": "-76.63107000"
                        },
                        {
                            "id": 126537,
                            "name": "Smyth County",
                            "latitude": "36.84388000",
                            "longitude": "-81.53702000"
                        },
                        {
                            "id": 126628,
                            "name": "South Boston",
                            "latitude": "36.69875000",
                            "longitude": "-78.90140000"
                        },
                        {
                            "id": 126671,
                            "name": "South Hill",
                            "latitude": "36.72653000",
                            "longitude": "-78.12889000"
                        },
                        {
                            "id": 126716,
                            "name": "South Riding",
                            "latitude": "38.92094000",
                            "longitude": "-77.50388000"
                        },
                        {
                            "id": 126731,
                            "name": "South Suffolk",
                            "latitude": "36.71709000",
                            "longitude": "-76.59023000"
                        },
                        {
                            "id": 126759,
                            "name": "Southampton County",
                            "latitude": "36.72040000",
                            "longitude": "-77.10609000"
                        },
                        {
                            "id": 126767,
                            "name": "Southern Gateway",
                            "latitude": "38.34507000",
                            "longitude": "-77.50352000"
                        },
                        {
                            "id": 126847,
                            "name": "Spotsylvania County",
                            "latitude": "38.18502000",
                            "longitude": "-77.65597000"
                        },
                        {
                            "id": 126848,
                            "name": "Spotsylvania Courthouse",
                            "latitude": "38.19791000",
                            "longitude": "-77.58777000"
                        },
                        {
                            "id": 126896,
                            "name": "Springfield",
                            "latitude": "38.78928000",
                            "longitude": "-77.18720000"
                        },
                        {
                            "id": 126916,
                            "name": "Springville",
                            "latitude": "37.19651000",
                            "longitude": "-81.40288000"
                        },
                        {
                            "id": 126933,
                            "name": "Stafford",
                            "latitude": "38.42207000",
                            "longitude": "-77.40832000"
                        },
                        {
                            "id": 126937,
                            "name": "Stafford County",
                            "latitude": "38.42070000",
                            "longitude": "-77.45743000"
                        },
                        {
                            "id": 126948,
                            "name": "Stanardsville",
                            "latitude": "38.29735000",
                            "longitude": "-78.44001000"
                        },
                        {
                            "id": 126959,
                            "name": "Stanley",
                            "latitude": "38.57540000",
                            "longitude": "-78.50251000"
                        },
                        {
                            "id": 126963,
                            "name": "Stanleytown",
                            "latitude": "36.74430000",
                            "longitude": "-79.96282000"
                        },
                        {
                            "id": 127001,
                            "name": "Staunton",
                            "latitude": "38.14991000",
                            "longitude": "-79.07320000"
                        },
                        {
                            "id": 127020,
                            "name": "Stephens City",
                            "latitude": "39.08344000",
                            "longitude": "-78.21806000"
                        },
                        {
                            "id": 127027,
                            "name": "Sterling",
                            "latitude": "39.00622000",
                            "longitude": "-77.42860000"
                        },
                        {
                            "id": 127120,
                            "name": "Strasburg",
                            "latitude": "38.98872000",
                            "longitude": "-78.35862000"
                        },
                        {
                            "id": 127144,
                            "name": "Stuart",
                            "latitude": "36.64097000",
                            "longitude": "-80.26561000"
                        },
                        {
                            "id": 127145,
                            "name": "Stuarts Draft",
                            "latitude": "38.03014000",
                            "longitude": "-79.03364000"
                        },
                        {
                            "id": 127163,
                            "name": "Sudley",
                            "latitude": "38.79289000",
                            "longitude": "-77.49749000"
                        },
                        {
                            "id": 127166,
                            "name": "Suffolk",
                            "latitude": "36.72836000",
                            "longitude": "-76.58496000"
                        },
                        {
                            "id": 127175,
                            "name": "Sugarland Run",
                            "latitude": "39.03761000",
                            "longitude": "-77.37526000"
                        },
                        {
                            "id": 127292,
                            "name": "Surry",
                            "latitude": "37.13793000",
                            "longitude": "-76.83524000"
                        },
                        {
                            "id": 127295,
                            "name": "Surry County",
                            "latitude": "37.11691000",
                            "longitude": "-76.88831000"
                        },
                        {
                            "id": 127300,
                            "name": "Sussex",
                            "latitude": "36.91515000",
                            "longitude": "-77.27914000"
                        },
                        {
                            "id": 127304,
                            "name": "Sussex County",
                            "latitude": "36.92175000",
                            "longitude": "-77.26179000"
                        },
                        {
                            "id": 127425,
                            "name": "Tappahannock",
                            "latitude": "37.92541000",
                            "longitude": "-76.85913000"
                        },
                        {
                            "id": 127469,
                            "name": "Tazewell",
                            "latitude": "37.11484000",
                            "longitude": "-81.51955000"
                        },
                        {
                            "id": 127470,
                            "name": "Tazewell County",
                            "latitude": "37.12497000",
                            "longitude": "-81.56066000"
                        },
                        {
                            "id": 127658,
                            "name": "Timberlake",
                            "latitude": "37.32070000",
                            "longitude": "-79.25753000"
                        },
                        {
                            "id": 127660,
                            "name": "Timberville",
                            "latitude": "38.63901000",
                            "longitude": "-78.77391000"
                        },
                        {
                            "id": 127818,
                            "name": "Triangle",
                            "latitude": "38.54679000",
                            "longitude": "-77.33665000"
                        },
                        {
                            "id": 127868,
                            "name": "Tuckahoe",
                            "latitude": "37.59015000",
                            "longitude": "-77.55638000"
                        },
                        {
                            "id": 127933,
                            "name": "Twin Lakes",
                            "latitude": "38.24927000",
                            "longitude": "-78.44378000"
                        },
                        {
                            "id": 127952,
                            "name": "Tysons Corner",
                            "latitude": "38.91872000",
                            "longitude": "-77.23109000"
                        },
                        {
                            "id": 128006,
                            "name": "Union Hall",
                            "latitude": "37.01875000",
                            "longitude": "-79.68642000"
                        },
                        {
                            "id": 128026,
                            "name": "University Center",
                            "latitude": "39.05705000",
                            "longitude": "-77.44415000"
                        },
                        {
                            "id": 128202,
                            "name": "Verona",
                            "latitude": "38.20208000",
                            "longitude": "-79.00836000"
                        },
                        {
                            "id": 128221,
                            "name": "Victoria",
                            "latitude": "36.99487000",
                            "longitude": "-78.22722000"
                        },
                        {
                            "id": 128234,
                            "name": "Vienna",
                            "latitude": "38.90122000",
                            "longitude": "-77.26526000"
                        },
                        {
                            "id": 128281,
                            "name": "Vinton",
                            "latitude": "37.28097000",
                            "longitude": "-79.89698000"
                        },
                        {
                            "id": 128288,
                            "name": "Virginia Beach",
                            "latitude": "36.85293000",
                            "longitude": "-75.97799000"
                        },
                        {
                            "id": 128469,
                            "name": "Warm Springs",
                            "latitude": "38.04624000",
                            "longitude": "-79.79061000"
                        },
                        {
                            "id": 128492,
                            "name": "Warren County",
                            "latitude": "38.90878000",
                            "longitude": "-78.20746000"
                        },
                        {
                            "id": 128507,
                            "name": "Warrenton",
                            "latitude": "38.71345000",
                            "longitude": "-77.79527000"
                        },
                        {
                            "id": 128518,
                            "name": "Warsaw",
                            "latitude": "37.95874000",
                            "longitude": "-76.75801000"
                        },
                        {
                            "id": 128543,
                            "name": "Washington",
                            "latitude": "38.71345000",
                            "longitude": "-78.15944000"
                        },
                        {
                            "id": 128567,
                            "name": "Washington County",
                            "latitude": "36.72448000",
                            "longitude": "-81.95966000"
                        },
                        {
                            "id": 128644,
                            "name": "Wattsville",
                            "latitude": "37.93401000",
                            "longitude": "-75.49965000"
                        },
                        {
                            "id": 128667,
                            "name": "Waverly",
                            "latitude": "37.03598000",
                            "longitude": "-77.09524000"
                        },
                        {
                            "id": 128709,
                            "name": "Waynesboro",
                            "latitude": "38.06847000",
                            "longitude": "-78.88947000"
                        },
                        {
                            "id": 128728,
                            "name": "Weber City",
                            "latitude": "37.75514000",
                            "longitude": "-78.28389000"
                        },
                        {
                            "id": 128867,
                            "name": "West Falls Church",
                            "latitude": "38.89094000",
                            "longitude": "-77.18443000"
                        },
                        {
                            "id": 128877,
                            "name": "West Gate",
                            "latitude": "38.78289000",
                            "longitude": "-77.49749000"
                        },
                        {
                            "id": 128924,
                            "name": "West Lynchburg",
                            "latitude": "37.40320000",
                            "longitude": "-79.17808000"
                        },
                        {
                            "id": 128960,
                            "name": "West Point",
                            "latitude": "37.53153000",
                            "longitude": "-76.79635000"
                        },
                        {
                            "id": 128984,
                            "name": "West Springfield",
                            "latitude": "38.77261000",
                            "longitude": "-77.22109000"
                        },
                        {
                            "id": 129055,
                            "name": "Westmoreland County",
                            "latitude": "38.11282000",
                            "longitude": "-76.79991000"
                        },
                        {
                            "id": 129099,
                            "name": "Weyers Cave",
                            "latitude": "38.28846000",
                            "longitude": "-78.91308000"
                        },
                        {
                            "id": 129268,
                            "name": "Williamsburg",
                            "latitude": "37.27070000",
                            "longitude": "-76.70746000"
                        },
                        {
                            "id": 129356,
                            "name": "Winchester",
                            "latitude": "39.18566000",
                            "longitude": "-78.16333000"
                        },
                        {
                            "id": 129381,
                            "name": "Windsor",
                            "latitude": "36.80848000",
                            "longitude": "-76.74412000"
                        },
                        {
                            "id": 129459,
                            "name": "Wise",
                            "latitude": "36.97593000",
                            "longitude": "-82.57571000"
                        },
                        {
                            "id": 129461,
                            "name": "Wise County",
                            "latitude": "36.97522000",
                            "longitude": "-82.62124000"
                        },
                        {
                            "id": 129474,
                            "name": "Wolf Trap",
                            "latitude": "38.93983000",
                            "longitude": "-77.28609000"
                        },
                        {
                            "id": 129500,
                            "name": "Woodbridge",
                            "latitude": "38.65817000",
                            "longitude": "-77.24970000"
                        },
                        {
                            "id": 129505,
                            "name": "Woodburn",
                            "latitude": "38.84745000",
                            "longitude": "-77.23605000"
                        },
                        {
                            "id": 129525,
                            "name": "Woodlake",
                            "latitude": "37.42106000",
                            "longitude": "-77.67931000"
                        },
                        {
                            "id": 129535,
                            "name": "Woodlawn",
                            "latitude": "36.72235000",
                            "longitude": "-80.82285000"
                        },
                        {
                            "id": 129562,
                            "name": "Woodstock",
                            "latitude": "38.88178000",
                            "longitude": "-78.50584000"
                        },
                        {
                            "id": 129630,
                            "name": "Wyndham",
                            "latitude": "37.69848000",
                            "longitude": "-77.61249000"
                        },
                        {
                            "id": 129644,
                            "name": "Wythe County",
                            "latitude": "36.91713000",
                            "longitude": "-81.07859000"
                        },
                        {
                            "id": 129645,
                            "name": "Wytheville",
                            "latitude": "36.94845000",
                            "longitude": "-81.08481000"
                        },
                        {
                            "id": 129696,
                            "name": "York County",
                            "latitude": "37.22541000",
                            "longitude": "-76.52046000"
                        },
                        {
                            "id": 129703,
                            "name": "Yorkshire",
                            "latitude": "38.79317000",
                            "longitude": "-77.44777000"
                        },
                        {
                            "id": 129705,
                            "name": "Yorktown",
                            "latitude": "37.23876000",
                            "longitude": "-76.50967000"
                        }
                    ]
                },
                {
                    "id": 1405,
                    "name": "Wake Island",
                    "state_code": "UM-79",
                    "cities": []
                },
                {
                    "id": 1462,
                    "name": "Washington",
                    "state_code": "WA",
                    "cities": [
                        {
                            "id": 110979,
                            "name": "Aberdeen",
                            "latitude": "46.97537000",
                            "longitude": "-123.81572000"
                        },
                        {
                            "id": 111027,
                            "name": "Adams County",
                            "latitude": "46.98338000",
                            "longitude": "-118.56050000"
                        },
                        {
                            "id": 111063,
                            "name": "Ahtanum",
                            "latitude": "46.55957000",
                            "longitude": "-120.62201000"
                        },
                        {
                            "id": 111072,
                            "name": "Airway Heights",
                            "latitude": "47.64461000",
                            "longitude": "-117.59327000"
                        },
                        {
                            "id": 111138,
                            "name": "Alderton",
                            "latitude": "47.16955000",
                            "longitude": "-122.22928000"
                        },
                        {
                            "id": 111139,
                            "name": "Alderwood Manor",
                            "latitude": "47.82204000",
                            "longitude": "-122.28207000"
                        },
                        {
                            "id": 111164,
                            "name": "Algona",
                            "latitude": "47.27899000",
                            "longitude": "-122.25206000"
                        },
                        {
                            "id": 111205,
                            "name": "Allyn",
                            "latitude": "47.38565000",
                            "longitude": "-122.82764000"
                        },
                        {
                            "id": 111266,
                            "name": "Amboy",
                            "latitude": "45.91011000",
                            "longitude": "-122.44649000"
                        },
                        {
                            "id": 111278,
                            "name": "Ames Lake",
                            "latitude": "47.63288000",
                            "longitude": "-121.96623000"
                        },
                        {
                            "id": 111297,
                            "name": "Anacortes",
                            "latitude": "48.51260000",
                            "longitude": "-122.61267000"
                        },
                        {
                            "id": 111470,
                            "name": "Arlington",
                            "latitude": "48.19871000",
                            "longitude": "-122.12514000"
                        },
                        {
                            "id": 111474,
                            "name": "Arlington Heights",
                            "latitude": "48.20205000",
                            "longitude": "-122.06208000"
                        },
                        {
                            "id": 111501,
                            "name": "Artondale",
                            "latitude": "47.29954000",
                            "longitude": "-122.62069000"
                        },
                        {
                            "id": 111550,
                            "name": "Asotin",
                            "latitude": "46.33933000",
                            "longitude": "-117.04821000"
                        },
                        {
                            "id": 111551,
                            "name": "Asotin County",
                            "latitude": "46.19186000",
                            "longitude": "-117.20307000"
                        },
                        {
                            "id": 111632,
                            "name": "Auburn",
                            "latitude": "47.30732000",
                            "longitude": "-122.22845000"
                        },
                        {
                            "id": 111654,
                            "name": "Ault Field",
                            "latitude": "48.33812000",
                            "longitude": "-122.67441000"
                        },
                        {
                            "id": 111735,
                            "name": "Bainbridge Island",
                            "latitude": "47.62621000",
                            "longitude": "-122.52124000"
                        },
                        {
                            "id": 111795,
                            "name": "Bangor Trident Base",
                            "latitude": "47.72274000",
                            "longitude": "-122.71446000"
                        },
                        {
                            "id": 111813,
                            "name": "Barberton",
                            "latitude": "45.69317000",
                            "longitude": "-122.59899000"
                        },
                        {
                            "id": 111879,
                            "name": "Basin City",
                            "latitude": "46.59403000",
                            "longitude": "-119.15223000"
                        },
                        {
                            "id": 111907,
                            "name": "Battle Ground",
                            "latitude": "45.78095000",
                            "longitude": "-122.53343000"
                        },
                        {
                            "id": 112055,
                            "name": "Belfair",
                            "latitude": "47.45065000",
                            "longitude": "-122.82737000"
                        },
                        {
                            "id": 112117,
                            "name": "Bellevue",
                            "latitude": "47.61038000",
                            "longitude": "-122.20068000"
                        },
                        {
                            "id": 112120,
                            "name": "Bellingham",
                            "latitude": "48.75955000",
                            "longitude": "-122.48822000"
                        },
                        {
                            "id": 112198,
                            "name": "Benton City",
                            "latitude": "46.26319000",
                            "longitude": "-119.48780000"
                        },
                        {
                            "id": 112207,
                            "name": "Benton County",
                            "latitude": "46.23978000",
                            "longitude": "-119.51120000"
                        },
                        {
                            "id": 112282,
                            "name": "Bethel",
                            "latitude": "47.49398000",
                            "longitude": "-122.63125000"
                        },
                        {
                            "id": 112326,
                            "name": "Big Lake",
                            "latitude": "48.40288000",
                            "longitude": "-122.24127000"
                        },
                        {
                            "id": 112353,
                            "name": "Birch Bay",
                            "latitude": "48.91789000",
                            "longitude": "-122.74462000"
                        },
                        {
                            "id": 112374,
                            "name": "Black Diamond",
                            "latitude": "47.30871000",
                            "longitude": "-122.00317000"
                        },
                        {
                            "id": 112404,
                            "name": "Blaine",
                            "latitude": "48.99372000",
                            "longitude": "-122.74712000"
                        },
                        {
                            "id": 112547,
                            "name": "Bonney Lake",
                            "latitude": "47.17705000",
                            "longitude": "-122.18651000"
                        },
                        {
                            "id": 112595,
                            "name": "Bothell",
                            "latitude": "47.76232000",
                            "longitude": "-122.20540000"
                        },
                        {
                            "id": 112596,
                            "name": "Bothell East",
                            "latitude": "47.80631000",
                            "longitude": "-122.18427000"
                        },
                        {
                            "id": 112597,
                            "name": "Bothell West",
                            "latitude": "47.80527000",
                            "longitude": "-122.24064000"
                        },
                        {
                            "id": 112607,
                            "name": "Boulevard Park",
                            "latitude": "47.48927000",
                            "longitude": "-122.31512000"
                        },
                        {
                            "id": 112723,
                            "name": "Bremerton",
                            "latitude": "47.56732000",
                            "longitude": "-122.63264000"
                        },
                        {
                            "id": 112744,
                            "name": "Brewster",
                            "latitude": "48.09598000",
                            "longitude": "-119.78062000"
                        },
                        {
                            "id": 112768,
                            "name": "Bridgeport",
                            "latitude": "48.00820000",
                            "longitude": "-119.67116000"
                        },
                        {
                            "id": 112782,
                            "name": "Brier",
                            "latitude": "47.78454000",
                            "longitude": "-122.27429000"
                        },
                        {
                            "id": 112922,
                            "name": "Browns Point",
                            "latitude": "47.30038000",
                            "longitude": "-122.44124000"
                        },
                        {
                            "id": 112955,
                            "name": "Brush Prairie",
                            "latitude": "45.73289000",
                            "longitude": "-122.54649000"
                        },
                        {
                            "id": 112964,
                            "name": "Bryant",
                            "latitude": "48.23899000",
                            "longitude": "-122.15792000"
                        },
                        {
                            "id": 112967,
                            "name": "Bryn Mawr-Skyway",
                            "latitude": "47.49430000",
                            "longitude": "-122.24092000"
                        },
                        {
                            "id": 112988,
                            "name": "Buckley",
                            "latitude": "47.16316000",
                            "longitude": "-122.02678000"
                        },
                        {
                            "id": 113039,
                            "name": "Bunk Foss",
                            "latitude": "47.96171000",
                            "longitude": "-122.09441000"
                        },
                        {
                            "id": 113048,
                            "name": "Burbank",
                            "latitude": "46.19986000",
                            "longitude": "-119.01306000"
                        },
                        {
                            "id": 113052,
                            "name": "Burien",
                            "latitude": "47.47038000",
                            "longitude": "-122.34679000"
                        },
                        {
                            "id": 113064,
                            "name": "Burley",
                            "latitude": "47.41787000",
                            "longitude": "-122.63097000"
                        },
                        {
                            "id": 113076,
                            "name": "Burlington",
                            "latitude": "48.47566000",
                            "longitude": "-122.32544000"
                        },
                        {
                            "id": 113240,
                            "name": "Camano",
                            "latitude": "48.17399000",
                            "longitude": "-122.52821000"
                        },
                        {
                            "id": 113243,
                            "name": "Camas",
                            "latitude": "45.58706000",
                            "longitude": "-122.39954000"
                        },
                        {
                            "id": 113338,
                            "name": "Canterwood",
                            "latitude": "47.37510000",
                            "longitude": "-122.58930000"
                        },
                        {
                            "id": 113424,
                            "name": "Carnation",
                            "latitude": "47.64788000",
                            "longitude": "-121.91401000"
                        },
                        {
                            "id": 113476,
                            "name": "Carson",
                            "latitude": "45.72539000",
                            "longitude": "-121.81924000"
                        },
                        {
                            "id": 113517,
                            "name": "Cascade Valley",
                            "latitude": "47.13459000",
                            "longitude": "-119.32808000"
                        },
                        {
                            "id": 113522,
                            "name": "Cashmere",
                            "latitude": "47.52235000",
                            "longitude": "-120.46980000"
                        },
                        {
                            "id": 113550,
                            "name": "Castle Rock",
                            "latitude": "46.27511000",
                            "longitude": "-122.90761000"
                        },
                        {
                            "id": 113569,
                            "name": "Cathcart",
                            "latitude": "47.84788000",
                            "longitude": "-122.09929000"
                        },
                        {
                            "id": 113571,
                            "name": "Cathlamet",
                            "latitude": "46.20317000",
                            "longitude": "-123.38318000"
                        },
                        {
                            "id": 113679,
                            "name": "Central Park",
                            "latitude": "46.97343000",
                            "longitude": "-123.69239000"
                        },
                        {
                            "id": 113687,
                            "name": "Centralia",
                            "latitude": "46.71621000",
                            "longitude": "-122.95430000"
                        },
                        {
                            "id": 113825,
                            "name": "Chehalis",
                            "latitude": "46.66205000",
                            "longitude": "-122.96402000"
                        },
                        {
                            "id": 113826,
                            "name": "Chelan",
                            "latitude": "47.84097000",
                            "longitude": "-120.01646000"
                        },
                        {
                            "id": 113827,
                            "name": "Chelan County",
                            "latitude": "47.86910000",
                            "longitude": "-120.61891000"
                        },
                        {
                            "id": 113840,
                            "name": "Cheney",
                            "latitude": "47.48739000",
                            "longitude": "-117.57576000"
                        },
                        {
                            "id": 113924,
                            "name": "Chewelah",
                            "latitude": "48.27629000",
                            "longitude": "-117.71552000"
                        },
                        {
                            "id": 113944,
                            "name": "Chico",
                            "latitude": "47.61148000",
                            "longitude": "-122.71042000"
                        },
                        {
                            "id": 114083,
                            "name": "City of Sammamish",
                            "latitude": "47.60444000",
                            "longitude": "-122.03768000"
                        },
                        {
                            "id": 114097,
                            "name": "Clallam County",
                            "latitude": "48.11044000",
                            "longitude": "-123.93432000"
                        },
                        {
                            "id": 114131,
                            "name": "Clark County",
                            "latitude": "45.77927000",
                            "longitude": "-122.48259000"
                        },
                        {
                            "id": 114150,
                            "name": "Clarkston",
                            "latitude": "46.41629000",
                            "longitude": "-117.04557000"
                        },
                        {
                            "id": 114151,
                            "name": "Clarkston Heights-Vineland",
                            "latitude": "46.38742000",
                            "longitude": "-117.08300000"
                        },
                        {
                            "id": 114207,
                            "name": "Cle Elum",
                            "latitude": "47.19540000",
                            "longitude": "-120.93925000"
                        },
                        {
                            "id": 114212,
                            "name": "Clear Lake",
                            "latitude": "48.46427000",
                            "longitude": "-122.23404000"
                        },
                        {
                            "id": 114221,
                            "name": "Clearview",
                            "latitude": "47.83371000",
                            "longitude": "-122.12596000"
                        },
                        {
                            "id": 114321,
                            "name": "Clyde Hill",
                            "latitude": "47.63177000",
                            "longitude": "-122.21790000"
                        },
                        {
                            "id": 114409,
                            "name": "Colfax",
                            "latitude": "46.88017000",
                            "longitude": "-117.36435000"
                        },
                        {
                            "id": 114415,
                            "name": "College Place",
                            "latitude": "46.04930000",
                            "longitude": "-118.38830000"
                        },
                        {
                            "id": 114484,
                            "name": "Columbia County",
                            "latitude": "46.29755000",
                            "longitude": "-117.90788000"
                        },
                        {
                            "id": 114506,
                            "name": "Colville",
                            "latitude": "48.54657000",
                            "longitude": "-117.90554000"
                        },
                        {
                            "id": 114561,
                            "name": "Connell",
                            "latitude": "46.66347000",
                            "longitude": "-118.86111000"
                        },
                        {
                            "id": 114685,
                            "name": "Cosmopolis",
                            "latitude": "46.95537000",
                            "longitude": "-123.77378000"
                        },
                        {
                            "id": 114694,
                            "name": "Cottage Lake",
                            "latitude": "47.74427000",
                            "longitude": "-122.07735000"
                        },
                        {
                            "id": 114710,
                            "name": "Coulee Dam",
                            "latitude": "47.96543000",
                            "longitude": "-118.97613000"
                        },
                        {
                            "id": 114720,
                            "name": "Country Homes",
                            "latitude": "47.74850000",
                            "longitude": "-117.40439000"
                        },
                        {
                            "id": 114727,
                            "name": "Coupeville",
                            "latitude": "48.21982000",
                            "longitude": "-122.68628000"
                        },
                        {
                            "id": 114741,
                            "name": "Covington",
                            "latitude": "47.35818000",
                            "longitude": "-122.12216000"
                        },
                        {
                            "id": 114749,
                            "name": "Cowlitz County",
                            "latitude": "46.19329000",
                            "longitude": "-122.68078000"
                        },
                        {
                            "id": 114828,
                            "name": "Crocker",
                            "latitude": "47.08091000",
                            "longitude": "-122.10383000"
                        },
                        {
                            "id": 115000,
                            "name": "Dallesport",
                            "latitude": "45.61734000",
                            "longitude": "-121.17952000"
                        },
                        {
                            "id": 115054,
                            "name": "Darrington",
                            "latitude": "48.25539000",
                            "longitude": "-121.60151000"
                        },
                        {
                            "id": 115060,
                            "name": "Davenport",
                            "latitude": "47.65405000",
                            "longitude": "-118.14997000"
                        },
                        {
                            "id": 115101,
                            "name": "Dayton",
                            "latitude": "46.32375000",
                            "longitude": "-117.97244000"
                        },
                        {
                            "id": 115179,
                            "name": "Deer Park",
                            "latitude": "47.95434000",
                            "longitude": "-117.47689000"
                        },
                        {
                            "id": 115272,
                            "name": "Des Moines",
                            "latitude": "47.40177000",
                            "longitude": "-122.32429000"
                        },
                        {
                            "id": 115279,
                            "name": "Desert Aire",
                            "latitude": "46.67930000",
                            "longitude": "-119.91727000"
                        },
                        {
                            "id": 115354,
                            "name": "Dishman",
                            "latitude": "47.66007000",
                            "longitude": "-117.27596000"
                        },
                        {
                            "id": 115389,
                            "name": "Dollar Corner",
                            "latitude": "45.78012000",
                            "longitude": "-122.60010000"
                        },
                        {
                            "id": 115436,
                            "name": "Douglas County",
                            "latitude": "47.73607000",
                            "longitude": "-119.69172000"
                        },
                        {
                            "id": 115489,
                            "name": "DuPont",
                            "latitude": "47.09676000",
                            "longitude": "-122.63124000"
                        },
                        {
                            "id": 115582,
                            "name": "Duvall",
                            "latitude": "47.74232000",
                            "longitude": "-121.98568000"
                        },
                        {
                            "id": 115691,
                            "name": "East Hill-Meridian",
                            "latitude": "47.41052000",
                            "longitude": "-122.17369000"
                        },
                        {
                            "id": 115738,
                            "name": "East Port Orchard",
                            "latitude": "47.52343000",
                            "longitude": "-122.62430000"
                        },
                        {
                            "id": 115745,
                            "name": "East Renton Highlands",
                            "latitude": "47.48482000",
                            "longitude": "-122.11234000"
                        },
                        {
                            "id": 115771,
                            "name": "East Wenatchee",
                            "latitude": "47.41568000",
                            "longitude": "-120.29313000"
                        },
                        {
                            "id": 115772,
                            "name": "East Wenatchee Bench",
                            "latitude": "47.42568000",
                            "longitude": "-120.28118000"
                        },
                        {
                            "id": 115777,
                            "name": "Eastgate",
                            "latitude": "47.57266000",
                            "longitude": "-122.14578000"
                        },
                        {
                            "id": 115784,
                            "name": "Eastmont",
                            "latitude": "47.89740000",
                            "longitude": "-122.18154000"
                        },
                        {
                            "id": 115809,
                            "name": "Eatonville",
                            "latitude": "46.86733000",
                            "longitude": "-122.26650000"
                        },
                        {
                            "id": 115864,
                            "name": "Edgewood",
                            "latitude": "47.25010000",
                            "longitude": "-122.29373000"
                        },
                        {
                            "id": 115878,
                            "name": "Edmonds",
                            "latitude": "47.81065000",
                            "longitude": "-122.37736000"
                        },
                        {
                            "id": 115960,
                            "name": "Electric City",
                            "latitude": "47.93237000",
                            "longitude": "-119.03808000"
                        },
                        {
                            "id": 115989,
                            "name": "Elk Plain",
                            "latitude": "47.05316000",
                            "longitude": "-122.39762000"
                        },
                        {
                            "id": 116018,
                            "name": "Ellensburg",
                            "latitude": "46.99651000",
                            "longitude": "-120.54785000"
                        },
                        {
                            "id": 116046,
                            "name": "Elma",
                            "latitude": "47.00343000",
                            "longitude": "-123.40877000"
                        },
                        {
                            "id": 116123,
                            "name": "Enetai",
                            "latitude": "47.58482000",
                            "longitude": "-122.59875000"
                        },
                        {
                            "id": 116152,
                            "name": "Entiat",
                            "latitude": "47.67596000",
                            "longitude": "-120.20841000"
                        },
                        {
                            "id": 116153,
                            "name": "Enumclaw",
                            "latitude": "47.20427000",
                            "longitude": "-121.99150000"
                        },
                        {
                            "id": 116156,
                            "name": "Ephrata",
                            "latitude": "47.31764000",
                            "longitude": "-119.55365000"
                        },
                        {
                            "id": 116171,
                            "name": "Erlands Point-Kitsap Lake",
                            "latitude": "47.59719000",
                            "longitude": "-122.70225000"
                        },
                        {
                            "id": 116189,
                            "name": "Esperance",
                            "latitude": "47.78899000",
                            "longitude": "-122.35541000"
                        },
                        {
                            "id": 116260,
                            "name": "Everett",
                            "latitude": "47.97898000",
                            "longitude": "-122.20208000"
                        },
                        {
                            "id": 116267,
                            "name": "Everson",
                            "latitude": "48.92012000",
                            "longitude": "-122.34266000"
                        },
                        {
                            "id": 116297,
                            "name": "Fairchild Air Force Base",
                            "latitude": "47.61879000",
                            "longitude": "-117.64826000"
                        },
                        {
                            "id": 116363,
                            "name": "Fairwood",
                            "latitude": "47.44843000",
                            "longitude": "-122.15734000"
                        },
                        {
                            "id": 116371,
                            "name": "Fall City",
                            "latitude": "47.56732000",
                            "longitude": "-121.88873000"
                        },
                        {
                            "id": 116465,
                            "name": "Federal Way",
                            "latitude": "47.32232000",
                            "longitude": "-122.31262000"
                        },
                        {
                            "id": 116467,
                            "name": "Felida",
                            "latitude": "45.70956000",
                            "longitude": "-122.70732000"
                        },
                        {
                            "id": 116484,
                            "name": "Fern Prairie",
                            "latitude": "45.63651000",
                            "longitude": "-122.39870000"
                        },
                        {
                            "id": 116490,
                            "name": "Ferndale",
                            "latitude": "48.84650000",
                            "longitude": "-122.59101000"
                        },
                        {
                            "id": 116498,
                            "name": "Ferry County",
                            "latitude": "48.47007000",
                            "longitude": "-118.51649000"
                        },
                        {
                            "id": 116504,
                            "name": "Fife",
                            "latitude": "47.23927000",
                            "longitude": "-122.35707000"
                        },
                        {
                            "id": 116505,
                            "name": "Fife Heights",
                            "latitude": "47.25899000",
                            "longitude": "-122.34568000"
                        },
                        {
                            "id": 116517,
                            "name": "Finley",
                            "latitude": "46.15402000",
                            "longitude": "-119.03390000"
                        },
                        {
                            "id": 116519,
                            "name": "Fircrest",
                            "latitude": "47.23954000",
                            "longitude": "-122.51596000"
                        },
                        {
                            "id": 116538,
                            "name": "Five Corners",
                            "latitude": "45.68456000",
                            "longitude": "-122.57510000"
                        },
                        {
                            "id": 116617,
                            "name": "Fobes Hill",
                            "latitude": "47.94899000",
                            "longitude": "-122.11985000"
                        },
                        {
                            "id": 116641,
                            "name": "Fords Prairie",
                            "latitude": "46.73510000",
                            "longitude": "-122.98902000"
                        },
                        {
                            "id": 116675,
                            "name": "Forks",
                            "latitude": "47.95036000",
                            "longitude": "-124.38549000"
                        },
                        {
                            "id": 116802,
                            "name": "Fox Island",
                            "latitude": "47.25149000",
                            "longitude": "-122.62902000"
                        },
                        {
                            "id": 116865,
                            "name": "Franklin County",
                            "latitude": "46.53477000",
                            "longitude": "-118.89889000"
                        },
                        {
                            "id": 116893,
                            "name": "Frederickson",
                            "latitude": "47.09621000",
                            "longitude": "-122.35873000"
                        },
                        {
                            "id": 116907,
                            "name": "Freeland",
                            "latitude": "48.00954000",
                            "longitude": "-122.52598000"
                        },
                        {
                            "id": 116943,
                            "name": "Friday Harbor",
                            "latitude": "48.53427000",
                            "longitude": "-123.01712000"
                        },
                        {
                            "id": 117087,
                            "name": "Garfield County",
                            "latitude": "46.43156000",
                            "longitude": "-117.54519000"
                        },
                        {
                            "id": 117099,
                            "name": "Garrett",
                            "latitude": "46.05208000",
                            "longitude": "-118.40275000"
                        },
                        {
                            "id": 117145,
                            "name": "Geneva",
                            "latitude": "48.74567000",
                            "longitude": "-122.40183000"
                        },
                        {
                            "id": 117199,
                            "name": "Gig Harbor",
                            "latitude": "47.32926000",
                            "longitude": "-122.58013000"
                        },
                        {
                            "id": 117258,
                            "name": "Gleed",
                            "latitude": "46.65818000",
                            "longitude": "-120.61340000"
                        },
                        {
                            "id": 117339,
                            "name": "Gold Bar",
                            "latitude": "47.85677000",
                            "longitude": "-121.69706000"
                        },
                        {
                            "id": 117360,
                            "name": "Goldendale",
                            "latitude": "45.82068000",
                            "longitude": "-120.82173000"
                        },
                        {
                            "id": 117436,
                            "name": "Graham",
                            "latitude": "47.05288000",
                            "longitude": "-122.29428000"
                        },
                        {
                            "id": 117454,
                            "name": "Grand Coulee",
                            "latitude": "47.94154000",
                            "longitude": "-119.00335000"
                        },
                        {
                            "id": 117469,
                            "name": "Grand Mound",
                            "latitude": "46.78788000",
                            "longitude": "-123.01125000"
                        },
                        {
                            "id": 117481,
                            "name": "Grandview",
                            "latitude": "46.25097000",
                            "longitude": "-119.90170000"
                        },
                        {
                            "id": 117489,
                            "name": "Granger",
                            "latitude": "46.34207000",
                            "longitude": "-120.18727000"
                        },
                        {
                            "id": 117498,
                            "name": "Granite Falls",
                            "latitude": "48.08399000",
                            "longitude": "-121.96874000"
                        },
                        {
                            "id": 117521,
                            "name": "Grant County",
                            "latitude": "47.20566000",
                            "longitude": "-119.45177000"
                        },
                        {
                            "id": 117558,
                            "name": "Grays Harbor County",
                            "latitude": "47.14445000",
                            "longitude": "-123.82847000"
                        },
                        {
                            "id": 117980,
                            "name": "Hansville",
                            "latitude": "47.91870000",
                            "longitude": "-122.55431000"
                        },
                        {
                            "id": 118203,
                            "name": "Hazel Dell",
                            "latitude": "45.67151000",
                            "longitude": "-122.66288000"
                        },
                        {
                            "id": 118399,
                            "name": "Highland",
                            "latitude": "46.13152000",
                            "longitude": "-119.11418000"
                        },
                        {
                            "id": 118498,
                            "name": "Hobart",
                            "latitude": "47.42177000",
                            "longitude": "-121.97289000"
                        },
                        {
                            "id": 118504,
                            "name": "Hockinson",
                            "latitude": "45.73789000",
                            "longitude": "-122.48704000"
                        },
                        {
                            "id": 118585,
                            "name": "Home",
                            "latitude": "47.27482000",
                            "longitude": "-122.76375000"
                        },
                        {
                            "id": 118660,
                            "name": "Hoquiam",
                            "latitude": "46.98092000",
                            "longitude": "-123.88933000"
                        },
                        {
                            "id": 118927,
                            "name": "Indianola",
                            "latitude": "47.74704000",
                            "longitude": "-122.52569000"
                        },
                        {
                            "id": 118938,
                            "name": "Inglewood-Finn Hill",
                            "latitude": "47.72049000",
                            "longitude": "-122.23167000"
                        },
                        {
                            "id": 119024,
                            "name": "Island County",
                            "latitude": "48.20820000",
                            "longitude": "-122.66922000"
                        },
                        {
                            "id": 119036,
                            "name": "Issaquah",
                            "latitude": "47.53010000",
                            "longitude": "-122.03262000"
                        },
                        {
                            "id": 119198,
                            "name": "Jefferson County",
                            "latitude": "47.77655000",
                            "longitude": "-123.57431000"
                        },
                        {
                            "id": 119287,
                            "name": "Joint Base Lewis McChord",
                            "latitude": "47.10787000",
                            "longitude": "-122.57694000"
                        },
                        {
                            "id": 119359,
                            "name": "Kalama",
                            "latitude": "46.00845000",
                            "longitude": "-122.84455000"
                        },
                        {
                            "id": 119432,
                            "name": "Kelso",
                            "latitude": "46.14678000",
                            "longitude": "-122.90844000"
                        },
                        {
                            "id": 119463,
                            "name": "Kenmore",
                            "latitude": "47.75732000",
                            "longitude": "-122.24401000"
                        },
                        {
                            "id": 119475,
                            "name": "Kennewick",
                            "latitude": "46.21125000",
                            "longitude": "-119.13723000"
                        },
                        {
                            "id": 119487,
                            "name": "Kent",
                            "latitude": "47.38093000",
                            "longitude": "-122.23484000"
                        },
                        {
                            "id": 119527,
                            "name": "Kettle Falls",
                            "latitude": "48.61074000",
                            "longitude": "-118.05582000"
                        },
                        {
                            "id": 119538,
                            "name": "Key Center",
                            "latitude": "47.34065000",
                            "longitude": "-122.74541000"
                        },
                        {
                            "id": 119582,
                            "name": "King County",
                            "latitude": "47.49084000",
                            "longitude": "-121.83583000"
                        },
                        {
                            "id": 119614,
                            "name": "Kingsgate",
                            "latitude": "47.72704000",
                            "longitude": "-122.17957000"
                        },
                        {
                            "id": 119630,
                            "name": "Kingston",
                            "latitude": "47.79850000",
                            "longitude": "-122.49806000"
                        },
                        {
                            "id": 119652,
                            "name": "Kirkland",
                            "latitude": "47.68149000",
                            "longitude": "-122.20874000"
                        },
                        {
                            "id": 119660,
                            "name": "Kitsap County",
                            "latitude": "47.63983000",
                            "longitude": "-122.64900000"
                        },
                        {
                            "id": 119664,
                            "name": "Kittitas",
                            "latitude": "46.98318000",
                            "longitude": "-120.41701000"
                        },
                        {
                            "id": 119665,
                            "name": "Kittitas County",
                            "latitude": "47.12417000",
                            "longitude": "-120.67972000"
                        },
                        {
                            "id": 119669,
                            "name": "Klahanie",
                            "latitude": "47.43121000",
                            "longitude": "-122.43652000"
                        },
                        {
                            "id": 119673,
                            "name": "Klickitat County",
                            "latitude": "45.87378000",
                            "longitude": "-120.78926000"
                        },
                        {
                            "id": 119738,
                            "name": "La Center",
                            "latitude": "45.86234000",
                            "longitude": "-122.67038000"
                        },
                        {
                            "id": 119812,
                            "name": "Lacey",
                            "latitude": "47.03426000",
                            "longitude": "-122.82319000"
                        },
                        {
                            "id": 119912,
                            "name": "Lake Forest Park",
                            "latitude": "47.75676000",
                            "longitude": "-122.28096000"
                        },
                        {
                            "id": 119938,
                            "name": "Lake Marcel-Stillwater",
                            "latitude": "47.69263000",
                            "longitude": "-121.91513000"
                        },
                        {
                            "id": 119948,
                            "name": "Lake Morton-Berrydale",
                            "latitude": "47.33251000",
                            "longitude": "-122.10286000"
                        },
                        {
                            "id": 119978,
                            "name": "Lake Shore",
                            "latitude": "45.69067000",
                            "longitude": "-122.69093000"
                        },
                        {
                            "id": 119980,
                            "name": "Lake Stevens",
                            "latitude": "48.01510000",
                            "longitude": "-122.06374000"
                        },
                        {
                            "id": 119981,
                            "name": "Lake Stickney",
                            "latitude": "47.87655000",
                            "longitude": "-122.26214000"
                        },
                        {
                            "id": 120018,
                            "name": "Lakeland North",
                            "latitude": "47.33343000",
                            "longitude": "-122.27695000"
                        },
                        {
                            "id": 120019,
                            "name": "Lakeland South",
                            "latitude": "47.27843000",
                            "longitude": "-122.28326000"
                        },
                        {
                            "id": 120052,
                            "name": "Lakewood",
                            "latitude": "47.17176000",
                            "longitude": "-122.51846000"
                        },
                        {
                            "id": 120114,
                            "name": "Langley",
                            "latitude": "48.04009000",
                            "longitude": "-122.40626000"
                        },
                        {
                            "id": 120140,
                            "name": "Larch Way",
                            "latitude": "47.84290000",
                            "longitude": "-122.25275000"
                        },
                        {
                            "id": 120268,
                            "name": "Lea Hill",
                            "latitude": "47.32621000",
                            "longitude": "-122.18151000"
                        },
                        {
                            "id": 120281,
                            "name": "Leavenworth",
                            "latitude": "47.59623000",
                            "longitude": "-120.66148000"
                        },
                        {
                            "id": 120417,
                            "name": "Lewis County",
                            "latitude": "46.57773000",
                            "longitude": "-122.39241000"
                        },
                        {
                            "id": 120437,
                            "name": "Lewisville",
                            "latitude": "45.80984000",
                            "longitude": "-122.52315000"
                        },
                        {
                            "id": 120475,
                            "name": "Liberty Lake",
                            "latitude": "47.67591000",
                            "longitude": "-117.11821000"
                        },
                        {
                            "id": 120537,
                            "name": "Lincoln County",
                            "latitude": "47.57619000",
                            "longitude": "-118.41879000"
                        },
                        {
                            "id": 120673,
                            "name": "Lochsloy",
                            "latitude": "48.05149000",
                            "longitude": "-122.03208000"
                        },
                        {
                            "id": 120692,
                            "name": "Lofall",
                            "latitude": "47.81204000",
                            "longitude": "-122.65821000"
                        },
                        {
                            "id": 120739,
                            "name": "Long Beach",
                            "latitude": "46.35232000",
                            "longitude": "-124.05432000"
                        },
                        {
                            "id": 120752,
                            "name": "Longbranch",
                            "latitude": "47.20898000",
                            "longitude": "-122.75680000"
                        },
                        {
                            "id": 120758,
                            "name": "Longview",
                            "latitude": "46.13817000",
                            "longitude": "-122.93817000"
                        },
                        {
                            "id": 120759,
                            "name": "Longview Heights",
                            "latitude": "46.18039000",
                            "longitude": "-122.95706000"
                        },
                        {
                            "id": 120919,
                            "name": "Lynden",
                            "latitude": "48.94650000",
                            "longitude": "-122.45211000"
                        },
                        {
                            "id": 120931,
                            "name": "Lynnwood",
                            "latitude": "47.82093000",
                            "longitude": "-122.31513000"
                        },
                        {
                            "id": 120951,
                            "name": "Mabton",
                            "latitude": "46.21485000",
                            "longitude": "-119.99671000"
                        },
                        {
                            "id": 120957,
                            "name": "Machias",
                            "latitude": "47.98149000",
                            "longitude": "-122.04596000"
                        },
                        {
                            "id": 121069,
                            "name": "Maltby",
                            "latitude": "47.80510000",
                            "longitude": "-122.11318000"
                        },
                        {
                            "id": 121102,
                            "name": "Manchester",
                            "latitude": "47.55566000",
                            "longitude": "-122.54507000"
                        },
                        {
                            "id": 121151,
                            "name": "Manson",
                            "latitude": "47.88486000",
                            "longitude": "-120.15841000"
                        },
                        {
                            "id": 121168,
                            "name": "Maple Heights-Lake Desire",
                            "latitude": "47.44413000",
                            "longitude": "-122.09736000"
                        },
                        {
                            "id": 121173,
                            "name": "Maple Valley",
                            "latitude": "47.39272000",
                            "longitude": "-122.04641000"
                        },
                        {
                            "id": 121180,
                            "name": "Maplewood",
                            "latitude": "47.40176000",
                            "longitude": "-122.55707000"
                        },
                        {
                            "id": 121219,
                            "name": "Marietta",
                            "latitude": "48.78705000",
                            "longitude": "-122.58045000"
                        },
                        {
                            "id": 121220,
                            "name": "Marietta-Alderwood",
                            "latitude": "48.78965000",
                            "longitude": "-122.55369000"
                        },
                        {
                            "id": 121333,
                            "name": "Martha Lake",
                            "latitude": "47.85093000",
                            "longitude": "-122.23930000"
                        },
                        {
                            "id": 121361,
                            "name": "Marysville",
                            "latitude": "48.05176000",
                            "longitude": "-122.17708000"
                        },
                        {
                            "id": 121382,
                            "name": "Mason County",
                            "latitude": "47.35048000",
                            "longitude": "-123.18309000"
                        },
                        {
                            "id": 121409,
                            "name": "Mattawa",
                            "latitude": "46.73791000",
                            "longitude": "-119.90282000"
                        },
                        {
                            "id": 121468,
                            "name": "McChord Air Force Base",
                            "latitude": "47.13397000",
                            "longitude": "-122.49157000"
                        },
                        {
                            "id": 121470,
                            "name": "McCleary",
                            "latitude": "47.05315000",
                            "longitude": "-123.26543000"
                        },
                        {
                            "id": 121538,
                            "name": "McMillin",
                            "latitude": "47.13982000",
                            "longitude": "-122.23651000"
                        },
                        {
                            "id": 121553,
                            "name": "Mead",
                            "latitude": "47.76739000",
                            "longitude": "-117.35494000"
                        },
                        {
                            "id": 121559,
                            "name": "Meadow Glade",
                            "latitude": "45.75845000",
                            "longitude": "-122.56038000"
                        },
                        {
                            "id": 121568,
                            "name": "Meadowdale",
                            "latitude": "47.85287000",
                            "longitude": "-122.33347000"
                        },
                        {
                            "id": 121600,
                            "name": "Medical Lake",
                            "latitude": "47.57294000",
                            "longitude": "-117.68216000"
                        },
                        {
                            "id": 121606,
                            "name": "Medina",
                            "latitude": "47.62093000",
                            "longitude": "-122.22762000"
                        },
                        {
                            "id": 121682,
                            "name": "Mercer Island",
                            "latitude": "47.57065000",
                            "longitude": "-122.22207000"
                        },
                        {
                            "id": 121801,
                            "name": "Midland",
                            "latitude": "47.16704000",
                            "longitude": "-122.40484000"
                        },
                        {
                            "id": 121863,
                            "name": "Mill Creek",
                            "latitude": "47.86010000",
                            "longitude": "-122.20430000"
                        },
                        {
                            "id": 121864,
                            "name": "Mill Creek East",
                            "latitude": "47.83602000",
                            "longitude": "-122.18766000"
                        },
                        {
                            "id": 121867,
                            "name": "Mill Plain",
                            "latitude": "45.64290000",
                            "longitude": "-122.49398000"
                        },
                        {
                            "id": 121907,
                            "name": "Millwood",
                            "latitude": "47.68128000",
                            "longitude": "-117.28271000"
                        },
                        {
                            "id": 121921,
                            "name": "Milton",
                            "latitude": "47.24816000",
                            "longitude": "-122.31290000"
                        },
                        {
                            "id": 121952,
                            "name": "Minnehaha",
                            "latitude": "45.65901000",
                            "longitude": "-122.64871000"
                        },
                        {
                            "id": 121975,
                            "name": "Mirrormont",
                            "latitude": "47.46232000",
                            "longitude": "-121.99567000"
                        },
                        {
                            "id": 122065,
                            "name": "Monroe",
                            "latitude": "47.85538000",
                            "longitude": "-121.97096000"
                        },
                        {
                            "id": 122083,
                            "name": "Monroe North",
                            "latitude": "47.88225000",
                            "longitude": "-121.98729000"
                        },
                        {
                            "id": 122124,
                            "name": "Montesano",
                            "latitude": "46.98121000",
                            "longitude": "-123.60266000"
                        },
                        {
                            "id": 122295,
                            "name": "Morton",
                            "latitude": "46.55844000",
                            "longitude": "-122.27510000"
                        },
                        {
                            "id": 122302,
                            "name": "Moses Lake",
                            "latitude": "47.13014000",
                            "longitude": "-119.27808000"
                        },
                        {
                            "id": 122303,
                            "name": "Moses Lake North",
                            "latitude": "47.19433000",
                            "longitude": "-119.31719000"
                        },
                        {
                            "id": 122403,
                            "name": "Mount Vernon",
                            "latitude": "48.42122000",
                            "longitude": "-122.33405000"
                        },
                        {
                            "id": 122405,
                            "name": "Mount Vista",
                            "latitude": "45.73428000",
                            "longitude": "-122.63288000"
                        },
                        {
                            "id": 122439,
                            "name": "Mountlake Terrace",
                            "latitude": "47.78815000",
                            "longitude": "-122.30874000"
                        },
                        {
                            "id": 122450,
                            "name": "Mukilteo",
                            "latitude": "47.94454000",
                            "longitude": "-122.30458000"
                        },
                        {
                            "id": 122549,
                            "name": "Napavine",
                            "latitude": "46.57455000",
                            "longitude": "-122.90818000"
                        },
                        {
                            "id": 122605,
                            "name": "Navy Yard City",
                            "latitude": "47.55343000",
                            "longitude": "-122.66458000"
                        },
                        {
                            "id": 122823,
                            "name": "Newcastle",
                            "latitude": "47.53899000",
                            "longitude": "-122.15568000"
                        },
                        {
                            "id": 122852,
                            "name": "Newport",
                            "latitude": "48.17963000",
                            "longitude": "-117.04326000"
                        },
                        {
                            "id": 122936,
                            "name": "Nooksack",
                            "latitude": "48.92762000",
                            "longitude": "-122.32155000"
                        },
                        {
                            "id": 122952,
                            "name": "Normandy Park",
                            "latitude": "47.43621000",
                            "longitude": "-122.34068000"
                        },
                        {
                            "id": 122985,
                            "name": "North Bend",
                            "latitude": "47.49566000",
                            "longitude": "-121.78678000"
                        },
                        {
                            "id": 123011,
                            "name": "North Creek",
                            "latitude": "47.81954000",
                            "longitude": "-122.17624000"
                        },
                        {
                            "id": 123028,
                            "name": "North Fort Lewis",
                            "latitude": "47.12131000",
                            "longitude": "-122.59452000"
                        },
                        {
                            "id": 123090,
                            "name": "North Puyallup",
                            "latitude": "47.20677000",
                            "longitude": "-122.28234000"
                        },
                        {
                            "id": 123132,
                            "name": "North Yelm",
                            "latitude": "46.96315000",
                            "longitude": "-122.60290000"
                        },
                        {
                            "id": 123242,
                            "name": "Oak Harbor",
                            "latitude": "48.29316000",
                            "longitude": "-122.64322000"
                        },
                        {
                            "id": 123332,
                            "name": "Ocean Park",
                            "latitude": "46.49177000",
                            "longitude": "-124.05208000"
                        },
                        {
                            "id": 123336,
                            "name": "Ocean Shores",
                            "latitude": "46.97370000",
                            "longitude": "-124.15629000"
                        },
                        {
                            "id": 123393,
                            "name": "Okanogan",
                            "latitude": "48.36126000",
                            "longitude": "-119.58339000"
                        },
                        {
                            "id": 123394,
                            "name": "Okanogan County",
                            "latitude": "48.54885000",
                            "longitude": "-119.74079000"
                        },
                        {
                            "id": 123459,
                            "name": "Olympia",
                            "latitude": "47.03787000",
                            "longitude": "-122.90070000"
                        },
                        {
                            "id": 123464,
                            "name": "Omak",
                            "latitude": "48.41099000",
                            "longitude": "-119.52755000"
                        },
                        {
                            "id": 123499,
                            "name": "Opportunity",
                            "latitude": "47.64995000",
                            "longitude": "-117.23991000"
                        },
                        {
                            "id": 123539,
                            "name": "Orchards",
                            "latitude": "45.66651000",
                            "longitude": "-122.56093000"
                        },
                        {
                            "id": 123579,
                            "name": "Oroville",
                            "latitude": "48.93905000",
                            "longitude": "-119.43562000"
                        },
                        {
                            "id": 123582,
                            "name": "Orting",
                            "latitude": "47.09788000",
                            "longitude": "-122.20428000"
                        },
                        {
                            "id": 123627,
                            "name": "Othello",
                            "latitude": "46.82597000",
                            "longitude": "-119.17529000"
                        },
                        {
                            "id": 123629,
                            "name": "Otis Orchards-East Farms",
                            "latitude": "47.70988000",
                            "longitude": "-117.07975000"
                        },
                        {
                            "id": 123707,
                            "name": "Pacific",
                            "latitude": "47.26455000",
                            "longitude": "-122.25012000"
                        },
                        {
                            "id": 123709,
                            "name": "Pacific County",
                            "latitude": "46.55128000",
                            "longitude": "-123.77886000"
                        },
                        {
                            "id": 123792,
                            "name": "Palouse",
                            "latitude": "46.91017000",
                            "longitude": "-117.07573000"
                        },
                        {
                            "id": 123872,
                            "name": "Parkland",
                            "latitude": "47.15538000",
                            "longitude": "-122.43401000"
                        },
                        {
                            "id": 123883,
                            "name": "Parkwood",
                            "latitude": "47.53315000",
                            "longitude": "-122.61014000"
                        },
                        {
                            "id": 123901,
                            "name": "Pasco",
                            "latitude": "46.23958000",
                            "longitude": "-119.10057000"
                        },
                        {
                            "id": 123955,
                            "name": "Peaceful Valley",
                            "latitude": "48.93815000",
                            "longitude": "-122.14733000"
                        },
                        {
                            "id": 124013,
                            "name": "Pend Oreille County",
                            "latitude": "48.53230000",
                            "longitude": "-117.27397000"
                        },
                        {
                            "id": 124160,
                            "name": "Picnic Point",
                            "latitude": "47.88111000",
                            "longitude": "-122.32840000"
                        },
                        {
                            "id": 124161,
                            "name": "Picnic Point-North Lynnwood",
                            "latitude": "47.86278000",
                            "longitude": "-122.29497000"
                        },
                        {
                            "id": 124175,
                            "name": "Pierce County",
                            "latitude": "47.03764000",
                            "longitude": "-122.13735000"
                        },
                        {
                            "id": 124433,
                            "name": "Point Roberts",
                            "latitude": "48.98538000",
                            "longitude": "-123.07797000"
                        },
                        {
                            "id": 124456,
                            "name": "Pomeroy",
                            "latitude": "46.47487000",
                            "longitude": "-117.60269000"
                        },
                        {
                            "id": 124493,
                            "name": "Port Angeles",
                            "latitude": "48.11815000",
                            "longitude": "-123.43074000"
                        },
                        {
                            "id": 124494,
                            "name": "Port Angeles East",
                            "latitude": "48.10667000",
                            "longitude": "-123.37172000"
                        },
                        {
                            "id": 124508,
                            "name": "Port Hadlock-Irondale",
                            "latitude": "48.03273000",
                            "longitude": "-122.78529000"
                        },
                        {
                            "id": 124518,
                            "name": "Port Ludlow",
                            "latitude": "47.92537000",
                            "longitude": "-122.68349000"
                        },
                        {
                            "id": 124525,
                            "name": "Port Orchard",
                            "latitude": "47.54037000",
                            "longitude": "-122.63625000"
                        },
                        {
                            "id": 124537,
                            "name": "Port Townsend",
                            "latitude": "48.11704000",
                            "longitude": "-122.76045000"
                        },
                        {
                            "id": 124603,
                            "name": "Poulsbo",
                            "latitude": "47.73593000",
                            "longitude": "-122.64654000"
                        },
                        {
                            "id": 124629,
                            "name": "Prairie Heights",
                            "latitude": "47.14933000",
                            "longitude": "-122.10530000"
                        },
                        {
                            "id": 124630,
                            "name": "Prairie Ridge",
                            "latitude": "47.13760000",
                            "longitude": "-122.14873000"
                        },
                        {
                            "id": 124714,
                            "name": "Prosser",
                            "latitude": "46.20680000",
                            "longitude": "-119.76892000"
                        },
                        {
                            "id": 124744,
                            "name": "Pullman",
                            "latitude": "46.73127000",
                            "longitude": "-117.17962000"
                        },
                        {
                            "id": 124757,
                            "name": "Purdy",
                            "latitude": "47.38899000",
                            "longitude": "-122.62541000"
                        },
                        {
                            "id": 124771,
                            "name": "Puyallup",
                            "latitude": "47.18538000",
                            "longitude": "-122.29290000"
                        },
                        {
                            "id": 124800,
                            "name": "Quincy",
                            "latitude": "47.23430000",
                            "longitude": "-119.85255000"
                        },
                        {
                            "id": 124827,
                            "name": "Rainier",
                            "latitude": "46.88815000",
                            "longitude": "-122.68846000"
                        },
                        {
                            "id": 124909,
                            "name": "Ravensdale",
                            "latitude": "47.35232000",
                            "longitude": "-121.98373000"
                        },
                        {
                            "id": 124920,
                            "name": "Raymond",
                            "latitude": "46.68649000",
                            "longitude": "-123.73294000"
                        },
                        {
                            "id": 124979,
                            "name": "Redmond",
                            "latitude": "47.67399000",
                            "longitude": "-122.12151000"
                        },
                        {
                            "id": 125026,
                            "name": "Renton",
                            "latitude": "47.48288000",
                            "longitude": "-122.21707000"
                        },
                        {
                            "id": 125032,
                            "name": "Republic",
                            "latitude": "48.64822000",
                            "longitude": "-118.73781000"
                        },
                        {
                            "id": 125072,
                            "name": "Richland",
                            "latitude": "46.28569000",
                            "longitude": "-119.28446000"
                        },
                        {
                            "id": 125121,
                            "name": "Ridgefield",
                            "latitude": "45.81511000",
                            "longitude": "-122.74260000"
                        },
                        {
                            "id": 125174,
                            "name": "Ritzville",
                            "latitude": "47.12755000",
                            "longitude": "-118.37999000"
                        },
                        {
                            "id": 125191,
                            "name": "Riverbend",
                            "latitude": "47.46649000",
                            "longitude": "-121.75039000"
                        },
                        {
                            "id": 125213,
                            "name": "Riverton",
                            "latitude": "47.48427000",
                            "longitude": "-122.29457000"
                        },
                        {
                            "id": 125270,
                            "name": "Rochester",
                            "latitude": "46.82177000",
                            "longitude": "-123.09625000"
                        },
                        {
                            "id": 125339,
                            "name": "Rocky Point",
                            "latitude": "47.59287000",
                            "longitude": "-122.66848000"
                        },
                        {
                            "id": 125420,
                            "name": "Rosedale",
                            "latitude": "47.33149000",
                            "longitude": "-122.65235000"
                        },
                        {
                            "id": 125488,
                            "name": "Royal City",
                            "latitude": "46.90097000",
                            "longitude": "-119.63059000"
                        },
                        {
                            "id": 125765,
                            "name": "Salmon Creek",
                            "latitude": "45.71067000",
                            "longitude": "-122.64899000"
                        },
                        {
                            "id": 125778,
                            "name": "Sammamish",
                            "latitude": "47.64177000",
                            "longitude": "-122.08040000"
                        },
                        {
                            "id": 125823,
                            "name": "San Juan County",
                            "latitude": "48.53116000",
                            "longitude": "-123.02490000"
                        },
                        {
                            "id": 126077,
                            "name": "Seabeck",
                            "latitude": "47.63954000",
                            "longitude": "-122.82849000"
                        },
                        {
                            "id": 126076,
                            "name": "SeaTac",
                            "latitude": "47.44846000",
                            "longitude": "-122.29217000"
                        },
                        {
                            "id": 126104,
                            "name": "Seattle",
                            "latitude": "47.60621000",
                            "longitude": "-122.33207000"
                        },
                        {
                            "id": 126122,
                            "name": "Sedro-Woolley",
                            "latitude": "48.50389000",
                            "longitude": "-122.23611000"
                        },
                        {
                            "id": 126129,
                            "name": "Selah",
                            "latitude": "46.65402000",
                            "longitude": "-120.53007000"
                        },
                        {
                            "id": 126164,
                            "name": "Sequim",
                            "latitude": "48.07963000",
                            "longitude": "-123.10234000"
                        },
                        {
                            "id": 126299,
                            "name": "Shelton",
                            "latitude": "47.21509000",
                            "longitude": "-123.10071000"
                        },
                        {
                            "id": 126368,
                            "name": "Shoreline",
                            "latitude": "47.75565000",
                            "longitude": "-122.34152000"
                        },
                        {
                            "id": 126424,
                            "name": "Silver Firs",
                            "latitude": "47.86602000",
                            "longitude": "-122.15510000"
                        },
                        {
                            "id": 126439,
                            "name": "Silverdale",
                            "latitude": "47.64454000",
                            "longitude": "-122.69487000"
                        },
                        {
                            "id": 126464,
                            "name": "Sisco Heights",
                            "latitude": "48.11538000",
                            "longitude": "-122.09708000"
                        },
                        {
                            "id": 126474,
                            "name": "Skagit County",
                            "latitude": "48.48215000",
                            "longitude": "-121.80227000"
                        },
                        {
                            "id": 126476,
                            "name": "Skamania County",
                            "latitude": "46.02276000",
                            "longitude": "-121.91510000"
                        },
                        {
                            "id": 126533,
                            "name": "Smokey Point",
                            "latitude": "48.15232000",
                            "longitude": "-122.18264000"
                        },
                        {
                            "id": 126542,
                            "name": "Snohomish",
                            "latitude": "47.91288000",
                            "longitude": "-122.09818000"
                        },
                        {
                            "id": 126543,
                            "name": "Snohomish County",
                            "latitude": "48.04602000",
                            "longitude": "-121.72218000"
                        },
                        {
                            "id": 126544,
                            "name": "Snoqualmie",
                            "latitude": "47.52871000",
                            "longitude": "-121.82539000"
                        },
                        {
                            "id": 126553,
                            "name": "Soap Lake",
                            "latitude": "47.38931000",
                            "longitude": "-119.49059000"
                        },
                        {
                            "id": 126625,
                            "name": "South Bend",
                            "latitude": "46.66315000",
                            "longitude": "-123.80461000"
                        },
                        {
                            "id": 126673,
                            "name": "South Hill",
                            "latitude": "47.14121000",
                            "longitude": "-122.27012000"
                        },
                        {
                            "id": 126746,
                            "name": "South Wenatchee",
                            "latitude": "47.39012000",
                            "longitude": "-120.28958000"
                        },
                        {
                            "id": 126793,
                            "name": "Southworth",
                            "latitude": "47.51204000",
                            "longitude": "-122.50180000"
                        },
                        {
                            "id": 126796,
                            "name": "Spanaway",
                            "latitude": "47.10399000",
                            "longitude": "-122.43457000"
                        },
                        {
                            "id": 126842,
                            "name": "Spokane",
                            "latitude": "47.65966000",
                            "longitude": "-117.42908000"
                        },
                        {
                            "id": 126843,
                            "name": "Spokane County",
                            "latitude": "47.62064000",
                            "longitude": "-117.40401000"
                        },
                        {
                            "id": 126844,
                            "name": "Spokane Valley",
                            "latitude": "47.67323000",
                            "longitude": "-117.23937000"
                        },
                        {
                            "id": 126974,
                            "name": "Stanwood",
                            "latitude": "48.24121000",
                            "longitude": "-122.37071000"
                        },
                        {
                            "id": 127017,
                            "name": "Steilacoom",
                            "latitude": "47.16982000",
                            "longitude": "-122.60263000"
                        },
                        {
                            "id": 127042,
                            "name": "Stevens County",
                            "latitude": "48.39906000",
                            "longitude": "-117.85514000"
                        },
                        {
                            "id": 127045,
                            "name": "Stevenson",
                            "latitude": "45.69567000",
                            "longitude": "-121.88452000"
                        },
                        {
                            "id": 127162,
                            "name": "Sudden Valley",
                            "latitude": "48.72289000",
                            "longitude": "-122.34655000"
                        },
                        {
                            "id": 127198,
                            "name": "Sultan",
                            "latitude": "47.86260000",
                            "longitude": "-121.81651000"
                        },
                        {
                            "id": 127199,
                            "name": "Sumas",
                            "latitude": "49.00012000",
                            "longitude": "-122.26488000"
                        },
                        {
                            "id": 127215,
                            "name": "Summit",
                            "latitude": "47.16177000",
                            "longitude": "-122.35707000"
                        },
                        {
                            "id": 127220,
                            "name": "Summit View",
                            "latitude": "47.13632000",
                            "longitude": "-122.35202000"
                        },
                        {
                            "id": 127223,
                            "name": "Sumner",
                            "latitude": "47.20316000",
                            "longitude": "-122.24040000"
                        },
                        {
                            "id": 127260,
                            "name": "Sunnyside",
                            "latitude": "46.32374000",
                            "longitude": "-120.00865000"
                        },
                        {
                            "id": 127263,
                            "name": "Sunnyslope",
                            "latitude": "47.47290000",
                            "longitude": "-120.33674000"
                        },
                        {
                            "id": 127284,
                            "name": "Suquamish",
                            "latitude": "47.73121000",
                            "longitude": "-122.55236000"
                        },
                        {
                            "id": 127369,
                            "name": "Tacoma",
                            "latitude": "47.25288000",
                            "longitude": "-122.44429000"
                        },
                        {
                            "id": 127416,
                            "name": "Tanglewilde",
                            "latitude": "47.05150000",
                            "longitude": "-122.78241000"
                        },
                        {
                            "id": 127417,
                            "name": "Tanglewilde-Thompson Place",
                            "latitude": "47.05116000",
                            "longitude": "-122.78081000"
                        },
                        {
                            "id": 127418,
                            "name": "Tanner",
                            "latitude": "47.47538000",
                            "longitude": "-121.74622000"
                        },
                        {
                            "id": 127509,
                            "name": "Tenino",
                            "latitude": "46.85677000",
                            "longitude": "-122.85291000"
                        },
                        {
                            "id": 127518,
                            "name": "Terrace Heights",
                            "latitude": "46.60624000",
                            "longitude": "-120.43979000"
                        },
                        {
                            "id": 127613,
                            "name": "Three Lakes",
                            "latitude": "47.94482000",
                            "longitude": "-122.01152000"
                        },
                        {
                            "id": 127631,
                            "name": "Thurston County",
                            "latitude": "46.92950000",
                            "longitude": "-122.83208000"
                        },
                        {
                            "id": 127639,
                            "name": "Tieton",
                            "latitude": "46.70207000",
                            "longitude": "-120.75535000"
                        },
                        {
                            "id": 127717,
                            "name": "Tonasket",
                            "latitude": "48.70515000",
                            "longitude": "-119.43950000"
                        },
                        {
                            "id": 127734,
                            "name": "Toppenish",
                            "latitude": "46.37735000",
                            "longitude": "-120.30867000"
                        },
                        {
                            "id": 127760,
                            "name": "Town and Country",
                            "latitude": "47.72739000",
                            "longitude": "-117.42161000"
                        },
                        {
                            "id": 127773,
                            "name": "Tracyton",
                            "latitude": "47.60898000",
                            "longitude": "-122.65514000"
                        },
                        {
                            "id": 127811,
                            "name": "Trentwood",
                            "latitude": "47.69656000",
                            "longitude": "-117.21076000"
                        },
                        {
                            "id": 127879,
                            "name": "Tukwila",
                            "latitude": "47.47399000",
                            "longitude": "-122.26096000"
                        },
                        {
                            "id": 127880,
                            "name": "Tulalip",
                            "latitude": "48.06843000",
                            "longitude": "-122.29181000"
                        },
                        {
                            "id": 127881,
                            "name": "Tulalip Bay",
                            "latitude": "48.03732000",
                            "longitude": "-122.31014000"
                        },
                        {
                            "id": 127890,
                            "name": "Tumwater",
                            "latitude": "47.00732000",
                            "longitude": "-122.90931000"
                        },
                        {
                            "id": 128004,
                            "name": "Union Gap",
                            "latitude": "46.55735000",
                            "longitude": "-120.47506000"
                        },
                        {
                            "id": 128007,
                            "name": "Union Hill-Novelty Hill",
                            "latitude": "47.67887000",
                            "longitude": "-122.02833000"
                        },
                        {
                            "id": 128036,
                            "name": "University Place",
                            "latitude": "47.23565000",
                            "longitude": "-122.55040000"
                        },
                        {
                            "id": 128144,
                            "name": "Vancouver",
                            "latitude": "45.63873000",
                            "longitude": "-122.66149000"
                        },
                        {
                            "id": 128157,
                            "name": "Vashon",
                            "latitude": "47.44732000",
                            "longitude": "-122.45985000"
                        },
                        {
                            "id": 128167,
                            "name": "Venersborg",
                            "latitude": "45.77373000",
                            "longitude": "-122.42454000"
                        },
                        {
                            "id": 128178,
                            "name": "Veradale",
                            "latitude": "47.64995000",
                            "longitude": "-117.20738000"
                        },
                        {
                            "id": 128332,
                            "name": "Wahkiakum County",
                            "latitude": "46.29125000",
                            "longitude": "-123.43316000"
                        },
                        {
                            "id": 128354,
                            "name": "Waitsburg",
                            "latitude": "46.27042000",
                            "longitude": "-118.15329000"
                        },
                        {
                            "id": 128393,
                            "name": "Walla Walla",
                            "latitude": "46.06458000",
                            "longitude": "-118.34302000"
                        },
                        {
                            "id": 128394,
                            "name": "Walla Walla County",
                            "latitude": "46.22980000",
                            "longitude": "-118.47845000"
                        },
                        {
                            "id": 128395,
                            "name": "Walla Walla East",
                            "latitude": "46.05184000",
                            "longitude": "-118.30403000"
                        },
                        {
                            "id": 128404,
                            "name": "Waller",
                            "latitude": "47.20066000",
                            "longitude": "-122.36929000"
                        },
                        {
                            "id": 128420,
                            "name": "Walnut Grove",
                            "latitude": "45.66789000",
                            "longitude": "-122.59899000"
                        },
                        {
                            "id": 128453,
                            "name": "Wapato",
                            "latitude": "46.44763000",
                            "longitude": "-120.42034000"
                        },
                        {
                            "id": 128460,
                            "name": "Warden",
                            "latitude": "46.96764000",
                            "longitude": "-119.03973000"
                        },
                        {
                            "id": 128467,
                            "name": "Warm Beach",
                            "latitude": "48.17065000",
                            "longitude": "-122.36460000"
                        },
                        {
                            "id": 128591,
                            "name": "Washougal",
                            "latitude": "45.58262000",
                            "longitude": "-122.35342000"
                        },
                        {
                            "id": 128629,
                            "name": "Waterville",
                            "latitude": "47.64708000",
                            "longitude": "-120.07118000"
                        },
                        {
                            "id": 128653,
                            "name": "Wauna",
                            "latitude": "47.37899000",
                            "longitude": "-122.64263000"
                        },
                        {
                            "id": 128797,
                            "name": "Wenatchee",
                            "latitude": "47.42346000",
                            "longitude": "-120.31035000"
                        },
                        {
                            "id": 128847,
                            "name": "West Clarkston-Highland",
                            "latitude": "46.40287000",
                            "longitude": "-117.06395000"
                        },
                        {
                            "id": 128910,
                            "name": "West Lake Sammamish",
                            "latitude": "47.57760000",
                            "longitude": "-122.10123000"
                        },
                        {
                            "id": 128911,
                            "name": "West Lake Stevens",
                            "latitude": "47.99343000",
                            "longitude": "-122.10180000"
                        },
                        {
                            "id": 128923,
                            "name": "West Longview",
                            "latitude": "46.16789000",
                            "longitude": "-122.99900000"
                        },
                        {
                            "id": 128951,
                            "name": "West Pasco",
                            "latitude": "46.24541000",
                            "longitude": "-119.18279000"
                        },
                        {
                            "id": 128968,
                            "name": "West Richland",
                            "latitude": "46.30430000",
                            "longitude": "-119.36141000"
                        },
                        {
                            "id": 128981,
                            "name": "West Side Highway",
                            "latitude": "46.18399000",
                            "longitude": "-122.91715000"
                        },
                        {
                            "id": 128996,
                            "name": "West Valley",
                            "latitude": "46.59207000",
                            "longitude": "-120.60507000"
                        },
                        {
                            "id": 129003,
                            "name": "West Wenatchee",
                            "latitude": "47.44374000",
                            "longitude": "-120.35341000"
                        },
                        {
                            "id": 129073,
                            "name": "Westport",
                            "latitude": "46.89009000",
                            "longitude": "-124.10406000"
                        },
                        {
                            "id": 129105,
                            "name": "Whatcom County",
                            "latitude": "48.82975000",
                            "longitude": "-121.87283000"
                        },
                        {
                            "id": 129129,
                            "name": "White Center",
                            "latitude": "47.51732000",
                            "longitude": "-122.35485000"
                        },
                        {
                            "id": 129159,
                            "name": "White Salmon",
                            "latitude": "45.72762000",
                            "longitude": "-121.48646000"
                        },
                        {
                            "id": 129204,
                            "name": "Whitman County",
                            "latitude": "46.90117000",
                            "longitude": "-117.52299000"
                        },
                        {
                            "id": 129234,
                            "name": "Wilderness Rim",
                            "latitude": "47.44697000",
                            "longitude": "-121.76857000"
                        },
                        {
                            "id": 129403,
                            "name": "Winlock",
                            "latitude": "46.49122000",
                            "longitude": "-122.93790000"
                        },
                        {
                            "id": 129480,
                            "name": "Wollochet",
                            "latitude": "47.26871000",
                            "longitude": "-122.58402000"
                        },
                        {
                            "id": 129523,
                            "name": "Woodinville",
                            "latitude": "47.75427000",
                            "longitude": "-122.16346000"
                        },
                        {
                            "id": 129527,
                            "name": "Woodland",
                            "latitude": "45.90456000",
                            "longitude": "-122.74399000"
                        },
                        {
                            "id": 129550,
                            "name": "Woods Creek",
                            "latitude": "47.87871000",
                            "longitude": "-121.89846000"
                        },
                        {
                            "id": 129579,
                            "name": "Woodway",
                            "latitude": "47.79621000",
                            "longitude": "-122.38291000"
                        },
                        {
                            "id": 129647,
                            "name": "Yacolt",
                            "latitude": "45.86595000",
                            "longitude": "-122.40621000"
                        },
                        {
                            "id": 129650,
                            "name": "Yakima",
                            "latitude": "46.60207000",
                            "longitude": "-120.50590000"
                        },
                        {
                            "id": 129651,
                            "name": "Yakima County",
                            "latitude": "46.45685000",
                            "longitude": "-120.73870000"
                        },
                        {
                            "id": 129669,
                            "name": "Yarrow Point",
                            "latitude": "47.64621000",
                            "longitude": "-122.21735000"
                        },
                        {
                            "id": 129681,
                            "name": "Yelm",
                            "latitude": "46.94204000",
                            "longitude": "-122.60596000"
                        },
                        {
                            "id": 129754,
                            "name": "Zillah",
                            "latitude": "46.40207000",
                            "longitude": "-120.26200000"
                        }
                    ]
                },
                {
                    "id": 1429,
                    "name": "West Virginia",
                    "state_code": "WV",
                    "cities": [
                        {
                            "id": 111137,
                            "name": "Alderson",
                            "latitude": "37.72595000",
                            "longitude": "-80.64202000"
                        },
                        {
                            "id": 111253,
                            "name": "Alum Creek",
                            "latitude": "38.28676000",
                            "longitude": "-81.80513000"
                        },
                        {
                            "id": 111363,
                            "name": "Ansted",
                            "latitude": "38.13622000",
                            "longitude": "-81.09955000"
                        },
                        {
                            "id": 111814,
                            "name": "Barbour County",
                            "latitude": "39.13293000",
                            "longitude": "-80.00303000"
                        },
                        {
                            "id": 111817,
                            "name": "Barboursville",
                            "latitude": "38.40953000",
                            "longitude": "-82.29459000"
                        },
                        {
                            "id": 111840,
                            "name": "Barrackville",
                            "latitude": "39.50370000",
                            "longitude": "-80.16675000"
                        },
                        {
                            "id": 111992,
                            "name": "Beaver",
                            "latitude": "37.74781000",
                            "longitude": "-81.14352000"
                        },
                        {
                            "id": 112017,
                            "name": "Beckley",
                            "latitude": "37.77817000",
                            "longitude": "-81.18816000"
                        },
                        {
                            "id": 112064,
                            "name": "Belington",
                            "latitude": "39.02510000",
                            "longitude": "-79.93563000"
                        },
                        {
                            "id": 112079,
                            "name": "Belle",
                            "latitude": "38.23205000",
                            "longitude": "-81.53762000"
                        },
                        {
                            "id": 112211,
                            "name": "Benwood",
                            "latitude": "40.01813000",
                            "longitude": "-80.73425000"
                        },
                        {
                            "id": 112225,
                            "name": "Berkeley County",
                            "latitude": "39.46407000",
                            "longitude": "-78.02754000"
                        },
                        {
                            "id": 112228,
                            "name": "Berkeley Springs",
                            "latitude": "39.62480000",
                            "longitude": "-78.22472000"
                        },
                        {
                            "id": 112275,
                            "name": "Bethany",
                            "latitude": "40.20563000",
                            "longitude": "-80.55674000"
                        },
                        {
                            "id": 112290,
                            "name": "Bethlehem",
                            "latitude": "39.39203000",
                            "longitude": "-80.28064000"
                        },
                        {
                            "id": 112429,
                            "name": "Blennerhassett",
                            "latitude": "39.26369000",
                            "longitude": "-81.62929000"
                        },
                        {
                            "id": 112479,
                            "name": "Bluefield",
                            "latitude": "37.26984000",
                            "longitude": "-81.22232000"
                        },
                        {
                            "id": 112480,
                            "name": "Bluewell",
                            "latitude": "37.31262000",
                            "longitude": "-81.25982000"
                        },
                        {
                            "id": 112491,
                            "name": "Boaz",
                            "latitude": "39.36146000",
                            "longitude": "-81.50207000"
                        },
                        {
                            "id": 112519,
                            "name": "Bolivar",
                            "latitude": "39.32343000",
                            "longitude": "-77.75277000"
                        },
                        {
                            "id": 112557,
                            "name": "Boone County",
                            "latitude": "38.02300000",
                            "longitude": "-81.71121000"
                        },
                        {
                            "id": 112671,
                            "name": "Bradley",
                            "latitude": "37.86539000",
                            "longitude": "-81.19399000"
                        },
                        {
                            "id": 112701,
                            "name": "Braxton County",
                            "latitude": "38.69987000",
                            "longitude": "-80.71929000"
                        },
                        {
                            "id": 112760,
                            "name": "Bridgeport",
                            "latitude": "39.28648000",
                            "longitude": "-80.25620000"
                        },
                        {
                            "id": 112852,
                            "name": "Brooke County",
                            "latitude": "40.27381000",
                            "longitude": "-80.57642000"
                        },
                        {
                            "id": 112859,
                            "name": "Brookhaven",
                            "latitude": "39.61175000",
                            "longitude": "-79.90451000"
                        },
                        {
                            "id": 112954,
                            "name": "Brush Fork",
                            "latitude": "37.28095000",
                            "longitude": "-81.25593000"
                        },
                        {
                            "id": 112981,
                            "name": "Buckhannon",
                            "latitude": "38.99399000",
                            "longitude": "-80.23203000"
                        },
                        {
                            "id": 113011,
                            "name": "Buffalo",
                            "latitude": "38.61759000",
                            "longitude": "-81.98180000"
                        },
                        {
                            "id": 113151,
                            "name": "Cabell County",
                            "latitude": "38.42030000",
                            "longitude": "-82.24172000"
                        },
                        {
                            "id": 113208,
                            "name": "Calhoun County",
                            "latitude": "38.84450000",
                            "longitude": "-81.11757000"
                        },
                        {
                            "id": 113699,
                            "name": "Ceredo",
                            "latitude": "38.39647000",
                            "longitude": "-82.55877000"
                        },
                        {
                            "id": 113745,
                            "name": "Chapmanville",
                            "latitude": "37.97371000",
                            "longitude": "-82.01735000"
                        },
                        {
                            "id": 113759,
                            "name": "Charles Town",
                            "latitude": "39.28899000",
                            "longitude": "-77.85972000"
                        },
                        {
                            "id": 113766,
                            "name": "Charleston",
                            "latitude": "38.34982000",
                            "longitude": "-81.63262000"
                        },
                        {
                            "id": 113818,
                            "name": "Cheat Lake",
                            "latitude": "39.67202000",
                            "longitude": "-79.85339000"
                        },
                        {
                            "id": 113873,
                            "name": "Chesapeake",
                            "latitude": "38.22344000",
                            "longitude": "-81.53623000"
                        },
                        {
                            "id": 113890,
                            "name": "Chester",
                            "latitude": "40.61312000",
                            "longitude": "-80.56285000"
                        },
                        {
                            "id": 114144,
                            "name": "Clarksburg",
                            "latitude": "39.28065000",
                            "longitude": "-80.34453000"
                        },
                        {
                            "id": 114167,
                            "name": "Clay",
                            "latitude": "38.46038000",
                            "longitude": "-81.08511000"
                        },
                        {
                            "id": 114186,
                            "name": "Clay County",
                            "latitude": "38.46253000",
                            "longitude": "-81.07509000"
                        },
                        {
                            "id": 114235,
                            "name": "Clendenin",
                            "latitude": "38.48871000",
                            "longitude": "-81.34817000"
                        },
                        {
                            "id": 114326,
                            "name": "Coal City",
                            "latitude": "37.67900000",
                            "longitude": "-81.21038000"
                        },
                        {
                            "id": 114330,
                            "name": "Coal Fork",
                            "latitude": "38.31760000",
                            "longitude": "-81.52095000"
                        },
                        {
                            "id": 114753,
                            "name": "Crab Orchard",
                            "latitude": "37.74067000",
                            "longitude": "-81.23066000"
                        },
                        {
                            "id": 114760,
                            "name": "Craigsville",
                            "latitude": "38.33067000",
                            "longitude": "-80.65315000"
                        },
                        {
                            "id": 114850,
                            "name": "Cross Lanes",
                            "latitude": "38.42037000",
                            "longitude": "-81.79068000"
                        },
                        {
                            "id": 114897,
                            "name": "Culloden",
                            "latitude": "38.42009000",
                            "longitude": "-82.05542000"
                        },
                        {
                            "id": 115023,
                            "name": "Daniels",
                            "latitude": "37.74327000",
                            "longitude": "-81.12408000"
                        },
                        {
                            "id": 115288,
                            "name": "Despard",
                            "latitude": "39.28870000",
                            "longitude": "-80.30592000"
                        },
                        {
                            "id": 115374,
                            "name": "Doddridge County",
                            "latitude": "39.26917000",
                            "longitude": "-80.70697000"
                        },
                        {
                            "id": 115520,
                            "name": "Dunbar",
                            "latitude": "38.36065000",
                            "longitude": "-81.73735000"
                        },
                        {
                            "id": 115958,
                            "name": "Eleanor",
                            "latitude": "38.53759000",
                            "longitude": "-81.93236000"
                        },
                        {
                            "id": 115971,
                            "name": "Elizabeth",
                            "latitude": "39.06341000",
                            "longitude": "-81.39512000"
                        },
                        {
                            "id": 116006,
                            "name": "Elkins",
                            "latitude": "38.92594000",
                            "longitude": "-79.84673000"
                        },
                        {
                            "id": 116015,
                            "name": "Elkview",
                            "latitude": "38.44288000",
                            "longitude": "-81.48040000"
                        },
                        {
                            "id": 116331,
                            "name": "Fairlea",
                            "latitude": "37.78068000",
                            "longitude": "-80.45702000"
                        },
                        {
                            "id": 116335,
                            "name": "Fairmont",
                            "latitude": "39.48508000",
                            "longitude": "-80.14258000"
                        },
                        {
                            "id": 116450,
                            "name": "Fayette County",
                            "latitude": "38.02878000",
                            "longitude": "-81.08119000"
                        },
                        {
                            "id": 116459,
                            "name": "Fayetteville",
                            "latitude": "38.05289000",
                            "longitude": "-81.10399000"
                        },
                        {
                            "id": 116622,
                            "name": "Follansbee",
                            "latitude": "40.32757000",
                            "longitude": "-80.59591000"
                        },
                        {
                            "id": 116688,
                            "name": "Fort Ashby",
                            "latitude": "39.50315000",
                            "longitude": "-78.76863000"
                        },
                        {
                            "id": 116832,
                            "name": "Franklin",
                            "latitude": "38.64289000",
                            "longitude": "-79.33115000"
                        },
                        {
                            "id": 117205,
                            "name": "Gilbert Creek",
                            "latitude": "37.57594000",
                            "longitude": "-81.89484000"
                        },
                        {
                            "id": 117223,
                            "name": "Gilmer County",
                            "latitude": "38.92406000",
                            "longitude": "-80.85708000"
                        },
                        {
                            "id": 117282,
                            "name": "Glendale",
                            "latitude": "39.94924000",
                            "longitude": "-80.75425000"
                        },
                        {
                            "id": 117310,
                            "name": "Glenville",
                            "latitude": "38.93426000",
                            "longitude": "-80.83760000"
                        },
                        {
                            "id": 117428,
                            "name": "Grafton",
                            "latitude": "39.34092000",
                            "longitude": "-80.01897000"
                        },
                        {
                            "id": 117511,
                            "name": "Grant County",
                            "latitude": "39.10513000",
                            "longitude": "-79.19557000"
                        },
                        {
                            "id": 117530,
                            "name": "Grantsville",
                            "latitude": "38.92342000",
                            "longitude": "-81.09595000"
                        },
                        {
                            "id": 117533,
                            "name": "Granville",
                            "latitude": "39.64591000",
                            "longitude": "-79.98729000"
                        },
                        {
                            "id": 117621,
                            "name": "Greenbrier County",
                            "latitude": "37.94693000",
                            "longitude": "-80.45295000"
                        },
                        {
                            "id": 117908,
                            "name": "Hamlin",
                            "latitude": "38.27870000",
                            "longitude": "-82.10292000"
                        },
                        {
                            "id": 117921,
                            "name": "Hampshire County",
                            "latitude": "39.31707000",
                            "longitude": "-78.61417000"
                        },
                        {
                            "id": 117958,
                            "name": "Hancock County",
                            "latitude": "40.52185000",
                            "longitude": "-80.57389000"
                        },
                        {
                            "id": 118011,
                            "name": "Hardy County",
                            "latitude": "39.00750000",
                            "longitude": "-78.85792000"
                        },
                        {
                            "id": 118066,
                            "name": "Harrison County",
                            "latitude": "39.28353000",
                            "longitude": "-80.37987000"
                        },
                        {
                            "id": 118073,
                            "name": "Harrisville",
                            "latitude": "39.20952000",
                            "longitude": "-81.05178000"
                        },
                        {
                            "id": 118489,
                            "name": "Hinton",
                            "latitude": "37.67401000",
                            "longitude": "-80.88925000"
                        },
                        {
                            "id": 118639,
                            "name": "Hooverson Heights",
                            "latitude": "40.32479000",
                            "longitude": "-80.57757000"
                        },
                        {
                            "id": 118800,
                            "name": "Huntington",
                            "latitude": "38.41925000",
                            "longitude": "-82.44515000"
                        },
                        {
                            "id": 118827,
                            "name": "Hurricane",
                            "latitude": "38.43259000",
                            "longitude": "-82.02014000"
                        },
                        {
                            "id": 118962,
                            "name": "Inwood",
                            "latitude": "39.35788000",
                            "longitude": "-78.04000000"
                        },
                        {
                            "id": 119089,
                            "name": "Jackson County",
                            "latitude": "38.83447000",
                            "longitude": "-81.67479000"
                        },
                        {
                            "id": 119188,
                            "name": "Jefferson County",
                            "latitude": "39.30762000",
                            "longitude": "-77.86274000"
                        },
                        {
                            "id": 119374,
                            "name": "Kanawha County",
                            "latitude": "38.33657000",
                            "longitude": "-81.52812000"
                        },
                        {
                            "id": 119478,
                            "name": "Kenova",
                            "latitude": "38.39897000",
                            "longitude": "-82.57821000"
                        },
                        {
                            "id": 119545,
                            "name": "Keyser",
                            "latitude": "39.44093000",
                            "longitude": "-78.97392000"
                        },
                        {
                            "id": 119637,
                            "name": "Kingwood",
                            "latitude": "39.47176000",
                            "longitude": "-79.68339000"
                        },
                        {
                            "id": 120215,
                            "name": "Lavalette",
                            "latitude": "38.32286000",
                            "longitude": "-82.44682000"
                        },
                        {
                            "id": 120395,
                            "name": "Lesage",
                            "latitude": "38.50647000",
                            "longitude": "-82.29848000"
                        },
                        {
                            "id": 120413,
                            "name": "Lewis County",
                            "latitude": "38.99587000",
                            "longitude": "-80.50216000"
                        },
                        {
                            "id": 120420,
                            "name": "Lewisburg",
                            "latitude": "37.80179000",
                            "longitude": "-80.44563000"
                        },
                        {
                            "id": 120525,
                            "name": "Lincoln County",
                            "latitude": "38.17536000",
                            "longitude": "-82.07039000"
                        },
                        {
                            "id": 120693,
                            "name": "Logan",
                            "latitude": "37.84871000",
                            "longitude": "-81.99346000"
                        },
                        {
                            "id": 120699,
                            "name": "Logan County",
                            "latitude": "37.83153000",
                            "longitude": "-81.93534000"
                        },
                        {
                            "id": 120858,
                            "name": "Lubeck",
                            "latitude": "39.23535000",
                            "longitude": "-81.63124000"
                        },
                        {
                            "id": 120950,
                            "name": "Mabscott",
                            "latitude": "37.77095000",
                            "longitude": "-81.20843000"
                        },
                        {
                            "id": 120952,
                            "name": "MacArthur",
                            "latitude": "37.75845000",
                            "longitude": "-81.21260000"
                        },
                        {
                            "id": 120993,
                            "name": "Madison",
                            "latitude": "38.06705000",
                            "longitude": "-81.81929000"
                        },
                        {
                            "id": 121063,
                            "name": "Mallory",
                            "latitude": "37.73066000",
                            "longitude": "-81.83790000"
                        },
                        {
                            "id": 121137,
                            "name": "Mannington",
                            "latitude": "39.53092000",
                            "longitude": "-80.34342000"
                        },
                        {
                            "id": 121260,
                            "name": "Marion County",
                            "latitude": "39.51000000",
                            "longitude": "-80.24340000"
                        },
                        {
                            "id": 121284,
                            "name": "Marlinton",
                            "latitude": "38.22345000",
                            "longitude": "-80.09451000"
                        },
                        {
                            "id": 121290,
                            "name": "Marmet",
                            "latitude": "38.24538000",
                            "longitude": "-81.56706000"
                        },
                        {
                            "id": 121316,
                            "name": "Marshall County",
                            "latitude": "39.86061000",
                            "longitude": "-80.66339000"
                        },
                        {
                            "id": 121346,
                            "name": "Martinsburg",
                            "latitude": "39.45621000",
                            "longitude": "-77.96389000"
                        },
                        {
                            "id": 121379,
                            "name": "Mason County",
                            "latitude": "38.76974000",
                            "longitude": "-82.02654000"
                        },
                        {
                            "id": 121496,
                            "name": "McDowell County",
                            "latitude": "37.37850000",
                            "longitude": "-81.65358000"
                        },
                        {
                            "id": 121537,
                            "name": "McMechen",
                            "latitude": "39.98813000",
                            "longitude": "-80.73147000"
                        },
                        {
                            "id": 121676,
                            "name": "Mercer County",
                            "latitude": "37.40552000",
                            "longitude": "-81.11144000"
                        },
                        {
                            "id": 121767,
                            "name": "Middlebourne",
                            "latitude": "39.49230000",
                            "longitude": "-80.90372000"
                        },
                        {
                            "id": 121914,
                            "name": "Milton",
                            "latitude": "38.43453000",
                            "longitude": "-82.13236000"
                        },
                        {
                            "id": 121934,
                            "name": "Mineral County",
                            "latitude": "39.41472000",
                            "longitude": "-78.94375000"
                        },
                        {
                            "id": 121942,
                            "name": "Mineral Wells",
                            "latitude": "39.19035000",
                            "longitude": "-81.53207000"
                        },
                        {
                            "id": 121946,
                            "name": "Mingo County",
                            "latitude": "37.72640000",
                            "longitude": "-82.13476000"
                        },
                        {
                            "id": 122054,
                            "name": "Monongah",
                            "latitude": "39.46258000",
                            "longitude": "-80.21814000"
                        },
                        {
                            "id": 122056,
                            "name": "Monongalia County",
                            "latitude": "39.63028000",
                            "longitude": "-80.04654000"
                        },
                        {
                            "id": 122077,
                            "name": "Monroe County",
                            "latitude": "37.56037000",
                            "longitude": "-80.55055000"
                        },
                        {
                            "id": 122132,
                            "name": "Montgomery",
                            "latitude": "38.18038000",
                            "longitude": "-81.32845000"
                        },
                        {
                            "id": 122205,
                            "name": "Moorefield",
                            "latitude": "39.06233000",
                            "longitude": "-78.96947000"
                        },
                        {
                            "id": 122243,
                            "name": "Morgan County",
                            "latitude": "39.56043000",
                            "longitude": "-78.25773000"
                        },
                        {
                            "id": 122253,
                            "name": "Morgantown",
                            "latitude": "39.62953000",
                            "longitude": "-79.95590000"
                        },
                        {
                            "id": 122327,
                            "name": "Moundsville",
                            "latitude": "39.92035000",
                            "longitude": "-80.74314000"
                        },
                        {
                            "id": 122343,
                            "name": "Mount Gay-Shamrock",
                            "latitude": "37.83857000",
                            "longitude": "-82.02970000"
                        },
                        {
                            "id": 122353,
                            "name": "Mount Hope",
                            "latitude": "37.89539000",
                            "longitude": "-81.16427000"
                        },
                        {
                            "id": 122460,
                            "name": "Mullens",
                            "latitude": "37.58317000",
                            "longitude": "-81.38038000"
                        },
                        {
                            "id": 122698,
                            "name": "New Cumberland",
                            "latitude": "40.49673000",
                            "longitude": "-80.60674000"
                        },
                        {
                            "id": 122715,
                            "name": "New Haven",
                            "latitude": "38.98647000",
                            "longitude": "-81.97347000"
                        },
                        {
                            "id": 122753,
                            "name": "New Martinsville",
                            "latitude": "39.64452000",
                            "longitude": "-80.85760000"
                        },
                        {
                            "id": 122824,
                            "name": "Newell",
                            "latitude": "40.61840000",
                            "longitude": "-80.60424000"
                        },
                        {
                            "id": 122888,
                            "name": "Nicholas County",
                            "latitude": "38.29170000",
                            "longitude": "-80.79933000"
                        },
                        {
                            "id": 122909,
                            "name": "Nitro",
                            "latitude": "38.41481000",
                            "longitude": "-81.84402000"
                        },
                        {
                            "id": 123220,
                            "name": "Nutter Fort",
                            "latitude": "39.26342000",
                            "longitude": "-80.31981000"
                        },
                        {
                            "id": 123245,
                            "name": "Oak Hill",
                            "latitude": "37.97233000",
                            "longitude": "-81.14871000"
                        },
                        {
                            "id": 123339,
                            "name": "Oceana",
                            "latitude": "37.69206000",
                            "longitude": "-81.62400000"
                        },
                        {
                            "id": 123384,
                            "name": "Ohio County",
                            "latitude": "40.09692000",
                            "longitude": "-80.61906000"
                        },
                        {
                            "id": 123714,
                            "name": "Paden City",
                            "latitude": "39.60285000",
                            "longitude": "-80.93677000"
                        },
                        {
                            "id": 123868,
                            "name": "Parkersburg",
                            "latitude": "39.26674000",
                            "longitude": "-81.56151000"
                        },
                        {
                            "id": 123893,
                            "name": "Parsons",
                            "latitude": "39.09649000",
                            "longitude": "-79.68090000"
                        },
                        {
                            "id": 123952,
                            "name": "Pea Ridge",
                            "latitude": "38.41397000",
                            "longitude": "-82.31987000"
                        },
                        {
                            "id": 124020,
                            "name": "Pendleton County",
                            "latitude": "38.68073000",
                            "longitude": "-79.35089000"
                        },
                        {
                            "id": 124035,
                            "name": "Pennsboro",
                            "latitude": "39.28508000",
                            "longitude": "-80.96844000"
                        },
                        {
                            "id": 124104,
                            "name": "Petersburg",
                            "latitude": "38.99261000",
                            "longitude": "-79.12392000"
                        },
                        {
                            "id": 124130,
                            "name": "Philippi",
                            "latitude": "39.15232000",
                            "longitude": "-80.04036000"
                        },
                        {
                            "id": 124208,
                            "name": "Pinch",
                            "latitude": "38.40871000",
                            "longitude": "-81.48179000"
                        },
                        {
                            "id": 124263,
                            "name": "Pineville",
                            "latitude": "37.58317000",
                            "longitude": "-81.53705000"
                        },
                        {
                            "id": 124378,
                            "name": "Pleasant Valley",
                            "latitude": "39.45536000",
                            "longitude": "-80.14175000"
                        },
                        {
                            "id": 124385,
                            "name": "Pleasants County",
                            "latitude": "39.37094000",
                            "longitude": "-81.16063000"
                        },
                        {
                            "id": 124415,
                            "name": "Pocahontas County",
                            "latitude": "38.33180000",
                            "longitude": "-80.00775000"
                        },
                        {
                            "id": 124430,
                            "name": "Point Pleasant",
                            "latitude": "38.84453000",
                            "longitude": "-82.13709000"
                        },
                        {
                            "id": 124659,
                            "name": "Preston County",
                            "latitude": "39.46930000",
                            "longitude": "-79.66816000"
                        },
                        {
                            "id": 124685,
                            "name": "Princeton",
                            "latitude": "37.36623000",
                            "longitude": "-81.10259000"
                        },
                        {
                            "id": 124713,
                            "name": "Prosperity",
                            "latitude": "37.83650000",
                            "longitude": "-81.20177000"
                        },
                        {
                            "id": 124765,
                            "name": "Putnam County",
                            "latitude": "38.50862000",
                            "longitude": "-81.90899000"
                        },
                        {
                            "id": 124825,
                            "name": "Rainelle",
                            "latitude": "37.96873000",
                            "longitude": "-80.76703000"
                        },
                        {
                            "id": 124832,
                            "name": "Raleigh County",
                            "latitude": "37.77136000",
                            "longitude": "-81.24863000"
                        },
                        {
                            "id": 124861,
                            "name": "Rand",
                            "latitude": "38.28260000",
                            "longitude": "-81.56234000"
                        },
                        {
                            "id": 124878,
                            "name": "Randolph County",
                            "latitude": "38.77472000",
                            "longitude": "-79.87580000"
                        },
                        {
                            "id": 124890,
                            "name": "Ranson",
                            "latitude": "39.29510000",
                            "longitude": "-77.86055000"
                        },
                        {
                            "id": 124910,
                            "name": "Ravenswood",
                            "latitude": "38.94814000",
                            "longitude": "-81.76096000"
                        },
                        {
                            "id": 125112,
                            "name": "Richwood",
                            "latitude": "38.22484000",
                            "longitude": "-80.53314000"
                        },
                        {
                            "id": 125164,
                            "name": "Ripley",
                            "latitude": "38.81870000",
                            "longitude": "-81.71069000"
                        },
                        {
                            "id": 125173,
                            "name": "Ritchie County",
                            "latitude": "39.17827000",
                            "longitude": "-81.06295000"
                        },
                        {
                            "id": 125225,
                            "name": "Roane County",
                            "latitude": "38.71403000",
                            "longitude": "-81.34835000"
                        },
                        {
                            "id": 125383,
                            "name": "Romney",
                            "latitude": "39.34204000",
                            "longitude": "-78.75668000"
                        },
                        {
                            "id": 125387,
                            "name": "Ronceverte",
                            "latitude": "37.74984000",
                            "longitude": "-80.46285000"
                        },
                        {
                            "id": 125594,
                            "name": "Saint Albans",
                            "latitude": "38.38565000",
                            "longitude": "-81.83624000"
                        },
                        {
                            "id": 125693,
                            "name": "Saint Marys",
                            "latitude": "39.39174000",
                            "longitude": "-81.20511000"
                        },
                        {
                            "id": 125733,
                            "name": "Salem",
                            "latitude": "39.28287000",
                            "longitude": "-80.55899000"
                        },
                        {
                            "id": 126208,
                            "name": "Shady Spring",
                            "latitude": "37.70567000",
                            "longitude": "-81.09843000"
                        },
                        {
                            "id": 126222,
                            "name": "Shannondale",
                            "latitude": "39.21705000",
                            "longitude": "-77.80749000"
                        },
                        {
                            "id": 126311,
                            "name": "Shepherdstown",
                            "latitude": "39.43010000",
                            "longitude": "-77.80416000"
                        },
                        {
                            "id": 126355,
                            "name": "Shinnston",
                            "latitude": "39.39564000",
                            "longitude": "-80.30009000"
                        },
                        {
                            "id": 126467,
                            "name": "Sissonville",
                            "latitude": "38.52815000",
                            "longitude": "-81.63096000"
                        },
                        {
                            "id": 126469,
                            "name": "Sistersville",
                            "latitude": "39.56424000",
                            "longitude": "-80.99594000"
                        },
                        {
                            "id": 126603,
                            "name": "Sophia",
                            "latitude": "37.70761000",
                            "longitude": "-81.25066000"
                        },
                        {
                            "id": 126636,
                            "name": "South Charleston",
                            "latitude": "38.36843000",
                            "longitude": "-81.69957000"
                        },
                        {
                            "id": 126824,
                            "name": "Spencer",
                            "latitude": "38.80203000",
                            "longitude": "-81.35095000"
                        },
                        {
                            "id": 126947,
                            "name": "Stanaford",
                            "latitude": "37.81595000",
                            "longitude": "-81.15232000"
                        },
                        {
                            "id": 126980,
                            "name": "Star City",
                            "latitude": "39.65841000",
                            "longitude": "-79.98645000"
                        },
                        {
                            "id": 127094,
                            "name": "Stonewood",
                            "latitude": "39.25092000",
                            "longitude": "-80.31231000"
                        },
                        {
                            "id": 127206,
                            "name": "Summers County",
                            "latitude": "37.65587000",
                            "longitude": "-80.85857000"
                        },
                        {
                            "id": 127208,
                            "name": "Summersville",
                            "latitude": "38.28122000",
                            "longitude": "-80.85260000"
                        },
                        {
                            "id": 127311,
                            "name": "Sutton",
                            "latitude": "38.66454000",
                            "longitude": "-80.70982000"
                        },
                        {
                            "id": 127455,
                            "name": "Taylor County",
                            "latitude": "39.33599000",
                            "longitude": "-80.04618000"
                        },
                        {
                            "id": 127477,
                            "name": "Teays Valley",
                            "latitude": "38.45009000",
                            "longitude": "-81.92930000"
                        },
                        {
                            "id": 127514,
                            "name": "Terra Alta",
                            "latitude": "39.44565000",
                            "longitude": "-79.54644000"
                        },
                        {
                            "id": 127739,
                            "name": "Tornado",
                            "latitude": "38.34287000",
                            "longitude": "-81.84430000"
                        },
                        {
                            "id": 127871,
                            "name": "Tucker County",
                            "latitude": "39.11360000",
                            "longitude": "-79.56497000"
                        },
                        {
                            "id": 127943,
                            "name": "Tyler County",
                            "latitude": "39.46528000",
                            "longitude": "-80.88483000"
                        },
                        {
                            "id": 127976,
                            "name": "Union",
                            "latitude": "37.59151000",
                            "longitude": "-80.54368000"
                        },
                        {
                            "id": 128053,
                            "name": "Upshur County",
                            "latitude": "38.89783000",
                            "longitude": "-80.23342000"
                        },
                        {
                            "id": 128233,
                            "name": "Vienna",
                            "latitude": "39.32702000",
                            "longitude": "-81.54846000"
                        },
                        {
                            "id": 128544,
                            "name": "Washington",
                            "latitude": "39.26119000",
                            "longitude": "-81.67180000"
                        },
                        {
                            "id": 128681,
                            "name": "Wayne",
                            "latitude": "38.22147000",
                            "longitude": "-82.44237000"
                        },
                        {
                            "id": 128697,
                            "name": "Wayne County",
                            "latitude": "38.14595000",
                            "longitude": "-82.42695000"
                        },
                        {
                            "id": 128740,
                            "name": "Webster County",
                            "latitude": "38.49474000",
                            "longitude": "-80.42187000"
                        },
                        {
                            "id": 128745,
                            "name": "Webster Springs",
                            "latitude": "38.47927000",
                            "longitude": "-80.41342000"
                        },
                        {
                            "id": 128759,
                            "name": "Weirton",
                            "latitude": "40.41896000",
                            "longitude": "-80.58952000"
                        },
                        {
                            "id": 128760,
                            "name": "Weirton Heights",
                            "latitude": "40.40840000",
                            "longitude": "-80.53924000"
                        },
                        {
                            "id": 128765,
                            "name": "Welch",
                            "latitude": "37.43289000",
                            "longitude": "-81.58455000"
                        },
                        {
                            "id": 128789,
                            "name": "Wellsburg",
                            "latitude": "40.27201000",
                            "longitude": "-80.60952000"
                        },
                        {
                            "id": 128918,
                            "name": "West Liberty",
                            "latitude": "40.16979000",
                            "longitude": "-80.59369000"
                        },
                        {
                            "id": 128994,
                            "name": "West Union",
                            "latitude": "39.29647000",
                            "longitude": "-80.77705000"
                        },
                        {
                            "id": 129060,
                            "name": "Weston",
                            "latitude": "39.03843000",
                            "longitude": "-80.46731000"
                        },
                        {
                            "id": 129068,
                            "name": "Westover",
                            "latitude": "39.63453000",
                            "longitude": "-79.96979000"
                        },
                        {
                            "id": 129093,
                            "name": "Wetzel County",
                            "latitude": "39.60526000",
                            "longitude": "-80.63910000"
                        },
                        {
                            "id": 129121,
                            "name": "Wheeling",
                            "latitude": "40.06396000",
                            "longitude": "-80.72091000"
                        },
                        {
                            "id": 129162,
                            "name": "White Sulphur Springs",
                            "latitude": "37.79651000",
                            "longitude": "-80.29757000"
                        },
                        {
                            "id": 129243,
                            "name": "Wiley Ford",
                            "latitude": "39.61453000",
                            "longitude": "-78.77502000"
                        },
                        {
                            "id": 129273,
                            "name": "Williamson",
                            "latitude": "37.67427000",
                            "longitude": "-82.27736000"
                        },
                        {
                            "id": 129287,
                            "name": "Williamstown",
                            "latitude": "39.40063000",
                            "longitude": "-81.44818000"
                        },
                        {
                            "id": 129396,
                            "name": "Winfield",
                            "latitude": "38.53314000",
                            "longitude": "-81.89347000"
                        },
                        {
                            "id": 129455,
                            "name": "Wirt County",
                            "latitude": "39.02242000",
                            "longitude": "-81.37862000"
                        },
                        {
                            "id": 129485,
                            "name": "Wood County",
                            "latitude": "39.21113000",
                            "longitude": "-81.51497000"
                        },
                        {
                            "id": 129639,
                            "name": "Wyoming County",
                            "latitude": "37.60961000",
                            "longitude": "-81.54918000"
                        }
                    ]
                },
                {
                    "id": 1441,
                    "name": "Wisconsin",
                    "state_code": "WI",
                    "cities": [
                        {
                            "id": 110970,
                            "name": "Abbotsford",
                            "latitude": "44.94636000",
                            "longitude": "-90.31597000"
                        },
                        {
                            "id": 111015,
                            "name": "Adams",
                            "latitude": "43.95608000",
                            "longitude": "-89.81818000"
                        },
                        {
                            "id": 111023,
                            "name": "Adams County",
                            "latitude": "43.96963000",
                            "longitude": "-89.77064000"
                        },
                        {
                            "id": 111038,
                            "name": "Addison",
                            "latitude": "43.42278000",
                            "longitude": "-88.37454000"
                        },
                        {
                            "id": 111109,
                            "name": "Albany",
                            "latitude": "42.70778000",
                            "longitude": "-89.43706000"
                        },
                        {
                            "id": 111162,
                            "name": "Algoma",
                            "latitude": "44.60889000",
                            "longitude": "-87.43259000"
                        },
                        {
                            "id": 111203,
                            "name": "Allouez",
                            "latitude": "44.47749000",
                            "longitude": "-88.01621000"
                        },
                        {
                            "id": 111211,
                            "name": "Alma",
                            "latitude": "44.31997000",
                            "longitude": "-91.91488000"
                        },
                        {
                            "id": 111241,
                            "name": "Alto",
                            "latitude": "43.67665000",
                            "longitude": "-88.79511000"
                        },
                        {
                            "id": 111249,
                            "name": "Altoona",
                            "latitude": "44.80468000",
                            "longitude": "-91.44321000"
                        },
                        {
                            "id": 111275,
                            "name": "Amery",
                            "latitude": "45.30691000",
                            "longitude": "-92.36214000"
                        },
                        {
                            "id": 111284,
                            "name": "Amherst",
                            "latitude": "44.45081000",
                            "longitude": "-89.28484000"
                        },
                        {
                            "id": 111371,
                            "name": "Antigo",
                            "latitude": "45.14025000",
                            "longitude": "-89.15234000"
                        },
                        {
                            "id": 111397,
                            "name": "Appleton",
                            "latitude": "44.26193000",
                            "longitude": "-88.41538000"
                        },
                        {
                            "id": 111424,
                            "name": "Arcadia",
                            "latitude": "44.25274000",
                            "longitude": "-91.50154000"
                        },
                        {
                            "id": 111525,
                            "name": "Ashford",
                            "latitude": "43.58694000",
                            "longitude": "-88.37066000"
                        },
                        {
                            "id": 111539,
                            "name": "Ashland",
                            "latitude": "46.59244000",
                            "longitude": "-90.88380000"
                        },
                        {
                            "id": 111542,
                            "name": "Ashland County",
                            "latitude": "46.50974000",
                            "longitude": "-90.71960000"
                        },
                        {
                            "id": 111549,
                            "name": "Ashwaubenon",
                            "latitude": "44.48221000",
                            "longitude": "-88.07010000"
                        },
                        {
                            "id": 111581,
                            "name": "Athens",
                            "latitude": "45.03302000",
                            "longitude": "-90.07402000"
                        },
                        {
                            "id": 111651,
                            "name": "Augusta",
                            "latitude": "44.68024000",
                            "longitude": "-91.11988000"
                        },
                        {
                            "id": 111711,
                            "name": "Aztalan",
                            "latitude": "43.07278000",
                            "longitude": "-88.86233000"
                        },
                        {
                            "id": 111758,
                            "name": "Baldwin",
                            "latitude": "44.96663000",
                            "longitude": "-92.37436000"
                        },
                        {
                            "id": 111780,
                            "name": "Balsam Lake",
                            "latitude": "45.45218000",
                            "longitude": "-92.45464000"
                        },
                        {
                            "id": 111794,
                            "name": "Bangor",
                            "latitude": "43.89302000",
                            "longitude": "-90.99041000"
                        },
                        {
                            "id": 111808,
                            "name": "Baraboo",
                            "latitude": "43.47109000",
                            "longitude": "-89.74429000"
                        },
                        {
                            "id": 111831,
                            "name": "Barneveld",
                            "latitude": "43.01555000",
                            "longitude": "-89.89540000"
                        },
                        {
                            "id": 111850,
                            "name": "Barron",
                            "latitude": "45.40135000",
                            "longitude": "-91.84906000"
                        },
                        {
                            "id": 111851,
                            "name": "Barron County",
                            "latitude": "45.42372000",
                            "longitude": "-91.84831000"
                        },
                        {
                            "id": 111865,
                            "name": "Barton",
                            "latitude": "43.44361000",
                            "longitude": "-88.18065000"
                        },
                        {
                            "id": 111942,
                            "name": "Bayfield County",
                            "latitude": "46.63544000",
                            "longitude": "-91.18068000"
                        },
                        {
                            "id": 111956,
                            "name": "Bayside",
                            "latitude": "43.18056000",
                            "longitude": "-87.90064000"
                        },
                        {
                            "id": 112001,
                            "name": "Beaver Dam",
                            "latitude": "43.45777000",
                            "longitude": "-88.83733000"
                        },
                        {
                            "id": 112060,
                            "name": "Belgium",
                            "latitude": "43.49972000",
                            "longitude": "-87.85037000"
                        },
                        {
                            "id": 112091,
                            "name": "Belle Plaine",
                            "latitude": "44.71526000",
                            "longitude": "-88.66621000"
                        },
                        {
                            "id": 112108,
                            "name": "Belleville",
                            "latitude": "42.85972000",
                            "longitude": "-89.53818000"
                        },
                        {
                            "id": 112115,
                            "name": "Bellevue",
                            "latitude": "44.44416000",
                            "longitude": "-87.92010000"
                        },
                        {
                            "id": 112146,
                            "name": "Beloit",
                            "latitude": "42.50835000",
                            "longitude": "-89.03178000"
                        },
                        {
                            "id": 112239,
                            "name": "Berlin",
                            "latitude": "43.96804000",
                            "longitude": "-88.94345000"
                        },
                        {
                            "id": 112298,
                            "name": "Bevent",
                            "latitude": "44.77053000",
                            "longitude": "-89.38956000"
                        },
                        {
                            "id": 112318,
                            "name": "Big Bend",
                            "latitude": "42.88140000",
                            "longitude": "-88.20676000"
                        },
                        {
                            "id": 112372,
                            "name": "Black Creek",
                            "latitude": "44.47749000",
                            "longitude": "-88.45066000"
                        },
                        {
                            "id": 112375,
                            "name": "Black Earth",
                            "latitude": "43.13722000",
                            "longitude": "-89.74679000"
                        },
                        {
                            "id": 112383,
                            "name": "Black River Falls",
                            "latitude": "44.29468000",
                            "longitude": "-90.85153000"
                        },
                        {
                            "id": 112410,
                            "name": "Blair",
                            "latitude": "44.29440000",
                            "longitude": "-91.23516000"
                        },
                        {
                            "id": 112432,
                            "name": "Bloomer",
                            "latitude": "45.10024000",
                            "longitude": "-91.48877000"
                        },
                        {
                            "id": 112503,
                            "name": "Bohners Lake",
                            "latitude": "42.62307000",
                            "longitude": "-88.28037000"
                        },
                        {
                            "id": 112533,
                            "name": "Bonduel",
                            "latitude": "44.74027000",
                            "longitude": "-88.44482000"
                        },
                        {
                            "id": 112583,
                            "name": "Boscobel",
                            "latitude": "43.13443000",
                            "longitude": "-90.70540000"
                        },
                        {
                            "id": 112640,
                            "name": "Boyceville",
                            "latitude": "45.04357000",
                            "longitude": "-92.04101000"
                        },
                        {
                            "id": 112752,
                            "name": "Brice Prairie",
                            "latitude": "43.93857000",
                            "longitude": "-91.29986000"
                        },
                        {
                            "id": 112796,
                            "name": "Brillion",
                            "latitude": "44.17721000",
                            "longitude": "-88.06427000"
                        },
                        {
                            "id": 112811,
                            "name": "Bristol",
                            "latitude": "42.55891000",
                            "longitude": "-88.04925000"
                        },
                        {
                            "id": 112838,
                            "name": "Brodhead",
                            "latitude": "42.61834000",
                            "longitude": "-89.37623000"
                        },
                        {
                            "id": 112855,
                            "name": "Brookfield",
                            "latitude": "43.06057000",
                            "longitude": "-88.10648000"
                        },
                        {
                            "id": 112873,
                            "name": "Brooklyn",
                            "latitude": "42.85361000",
                            "longitude": "-89.37040000"
                        },
                        {
                            "id": 112902,
                            "name": "Brothertown",
                            "latitude": "43.96805000",
                            "longitude": "-88.30899000"
                        },
                        {
                            "id": 112914,
                            "name": "Brown County",
                            "latitude": "44.47433000",
                            "longitude": "-87.99287000"
                        },
                        {
                            "id": 112915,
                            "name": "Brown Deer",
                            "latitude": "43.16334000",
                            "longitude": "-87.96453000"
                        },
                        {
                            "id": 112920,
                            "name": "Browns Lake",
                            "latitude": "42.69252000",
                            "longitude": "-88.23120000"
                        },
                        {
                            "id": 112958,
                            "name": "Brussels",
                            "latitude": "44.73611000",
                            "longitude": "-87.62093000"
                        },
                        {
                            "id": 113020,
                            "name": "Buffalo County",
                            "latitude": "44.37983000",
                            "longitude": "-91.75447000"
                        },
                        {
                            "id": 113073,
                            "name": "Burlington",
                            "latitude": "42.67807000",
                            "longitude": "-88.27620000"
                        },
                        {
                            "id": 113080,
                            "name": "Burnett County",
                            "latitude": "45.86272000",
                            "longitude": "-92.36757000"
                        },
                        {
                            "id": 113112,
                            "name": "Butler",
                            "latitude": "43.10584000",
                            "longitude": "-88.06953000"
                        },
                        {
                            "id": 113166,
                            "name": "Cadott",
                            "latitude": "44.94802000",
                            "longitude": "-91.15070000"
                        },
                        {
                            "id": 113192,
                            "name": "Caledonia",
                            "latitude": "42.80780000",
                            "longitude": "-87.92425000"
                        },
                        {
                            "id": 113231,
                            "name": "Calumet County",
                            "latitude": "44.08160000",
                            "longitude": "-88.21806000"
                        },
                        {
                            "id": 113255,
                            "name": "Cambridge",
                            "latitude": "43.00361000",
                            "longitude": "-89.01650000"
                        },
                        {
                            "id": 113276,
                            "name": "Cameron",
                            "latitude": "45.40857000",
                            "longitude": "-91.74406000"
                        },
                        {
                            "id": 113288,
                            "name": "Camp Lake",
                            "latitude": "42.53474000",
                            "longitude": "-88.14370000"
                        },
                        {
                            "id": 113304,
                            "name": "Campbellsport",
                            "latitude": "43.59777000",
                            "longitude": "-88.27899000"
                        },
                        {
                            "id": 113523,
                            "name": "Cashton",
                            "latitude": "43.74191000",
                            "longitude": "-90.77930000"
                        },
                        {
                            "id": 113574,
                            "name": "Cato",
                            "latitude": "44.14277000",
                            "longitude": "-87.86120000"
                        },
                        {
                            "id": 113612,
                            "name": "Cedar Grove",
                            "latitude": "43.56972000",
                            "longitude": "-87.82342000"
                        },
                        {
                            "id": 113624,
                            "name": "Cedarburg",
                            "latitude": "43.29667000",
                            "longitude": "-87.98759000"
                        },
                        {
                            "id": 113915,
                            "name": "Chetek",
                            "latitude": "45.31413000",
                            "longitude": "-91.65100000"
                        },
                        {
                            "id": 113956,
                            "name": "Chilton",
                            "latitude": "44.02888000",
                            "longitude": "-88.16288000"
                        },
                        {
                            "id": 113975,
                            "name": "Chippewa County",
                            "latitude": "45.06940000",
                            "longitude": "-91.27989000"
                        },
                        {
                            "id": 113976,
                            "name": "Chippewa Falls",
                            "latitude": "44.93691000",
                            "longitude": "-91.39293000"
                        },
                        {
                            "id": 114128,
                            "name": "Clark County",
                            "latitude": "44.73471000",
                            "longitude": "-90.61208000"
                        },
                        {
                            "id": 114211,
                            "name": "Clear Lake",
                            "latitude": "45.25191000",
                            "longitude": "-92.27130000"
                        },
                        {
                            "id": 114247,
                            "name": "Cleveland",
                            "latitude": "43.91499000",
                            "longitude": "-87.74731000"
                        },
                        {
                            "id": 114286,
                            "name": "Clinton",
                            "latitude": "42.55779000",
                            "longitude": "-88.86511000"
                        },
                        {
                            "id": 114299,
                            "name": "Clintonville",
                            "latitude": "44.62053000",
                            "longitude": "-88.76232000"
                        },
                        {
                            "id": 114380,
                            "name": "Colby",
                            "latitude": "44.90997000",
                            "longitude": "-90.31569000"
                        },
                        {
                            "id": 114407,
                            "name": "Colfax",
                            "latitude": "44.99746000",
                            "longitude": "-91.72712000"
                        },
                        {
                            "id": 114482,
                            "name": "Columbia County",
                            "latitude": "43.46660000",
                            "longitude": "-89.33373000"
                        },
                        {
                            "id": 114497,
                            "name": "Columbus",
                            "latitude": "43.33805000",
                            "longitude": "-89.01539000"
                        },
                        {
                            "id": 114518,
                            "name": "Combined Locks",
                            "latitude": "44.26582000",
                            "longitude": "-88.31427000"
                        },
                        {
                            "id": 114528,
                            "name": "Como",
                            "latitude": "42.61224000",
                            "longitude": "-88.48232000"
                        },
                        {
                            "id": 114542,
                            "name": "Concord",
                            "latitude": "43.06945000",
                            "longitude": "-88.59871000"
                        },
                        {
                            "id": 114607,
                            "name": "Cooperstown",
                            "latitude": "44.31277000",
                            "longitude": "-87.77453000"
                        },
                        {
                            "id": 114649,
                            "name": "Cornell",
                            "latitude": "45.16719000",
                            "longitude": "-91.14931000"
                        },
                        {
                            "id": 114692,
                            "name": "Cottage Grove",
                            "latitude": "43.07611000",
                            "longitude": "-89.19956000"
                        },
                        {
                            "id": 114766,
                            "name": "Crandon",
                            "latitude": "45.57191000",
                            "longitude": "-88.90289000"
                        },
                        {
                            "id": 114783,
                            "name": "Crawford County",
                            "latitude": "43.23946000",
                            "longitude": "-90.93105000"
                        },
                        {
                            "id": 114853,
                            "name": "Cross Plains",
                            "latitude": "43.11444000",
                            "longitude": "-89.65568000"
                        },
                        {
                            "id": 114888,
                            "name": "Cuba City",
                            "latitude": "42.60555000",
                            "longitude": "-90.42985000"
                        },
                        {
                            "id": 114889,
                            "name": "Cudahy",
                            "latitude": "42.95974000",
                            "longitude": "-87.86147000"
                        },
                        {
                            "id": 114910,
                            "name": "Cumberland",
                            "latitude": "45.53218000",
                            "longitude": "-92.01935000"
                        },
                        {
                            "id": 114973,
                            "name": "Dakota",
                            "latitude": "43.99025000",
                            "longitude": "-89.35651000"
                        },
                        {
                            "id": 115019,
                            "name": "Dane",
                            "latitude": "43.25055000",
                            "longitude": "-89.50151000"
                        },
                        {
                            "id": 115020,
                            "name": "Dane County",
                            "latitude": "43.06735000",
                            "longitude": "-89.41832000"
                        },
                        {
                            "id": 115048,
                            "name": "Darien",
                            "latitude": "42.60168000",
                            "longitude": "-88.70760000"
                        },
                        {
                            "id": 115050,
                            "name": "Darlington",
                            "latitude": "42.68306000",
                            "longitude": "-90.11763000"
                        },
                        {
                            "id": 115105,
                            "name": "De Forest",
                            "latitude": "43.24777000",
                            "longitude": "-89.34373000"
                        },
                        {
                            "id": 115112,
                            "name": "De Pere",
                            "latitude": "44.44888000",
                            "longitude": "-88.06038000"
                        },
                        {
                            "id": 115158,
                            "name": "Decatur",
                            "latitude": "42.63433000",
                            "longitude": "-89.41155000"
                        },
                        {
                            "id": 115183,
                            "name": "Deerfield",
                            "latitude": "43.05194000",
                            "longitude": "-89.07567000"
                        },
                        {
                            "id": 115196,
                            "name": "Delafield",
                            "latitude": "43.06084000",
                            "longitude": "-88.40371000"
                        },
                        {
                            "id": 115201,
                            "name": "Delavan",
                            "latitude": "42.63307000",
                            "longitude": "-88.64371000"
                        },
                        {
                            "id": 115202,
                            "name": "Delavan Lake",
                            "latitude": "42.58418000",
                            "longitude": "-88.63260000"
                        },
                        {
                            "id": 115241,
                            "name": "Denmark",
                            "latitude": "44.34777000",
                            "longitude": "-87.82732000"
                        },
                        {
                            "id": 115324,
                            "name": "Dickeyville",
                            "latitude": "42.62722000",
                            "longitude": "-90.59207000"
                        },
                        {
                            "id": 115380,
                            "name": "Dodge County",
                            "latitude": "43.41630000",
                            "longitude": "-88.70752000"
                        },
                        {
                            "id": 115381,
                            "name": "Dodgeville",
                            "latitude": "42.96027000",
                            "longitude": "-90.13012000"
                        },
                        {
                            "id": 115406,
                            "name": "Door County",
                            "latitude": "44.83834000",
                            "longitude": "-87.35779000"
                        },
                        {
                            "id": 115432,
                            "name": "Douglas County",
                            "latitude": "46.46411000",
                            "longitude": "-91.89940000"
                        },
                        {
                            "id": 115441,
                            "name": "Dousman",
                            "latitude": "43.01418000",
                            "longitude": "-88.47260000"
                        },
                        {
                            "id": 115547,
                            "name": "Dunn County",
                            "latitude": "44.94659000",
                            "longitude": "-91.89641000"
                        },
                        {
                            "id": 115564,
                            "name": "Durand",
                            "latitude": "44.62635000",
                            "longitude": "-91.96573000"
                        },
                        {
                            "id": 115596,
                            "name": "Eagle",
                            "latitude": "42.87946000",
                            "longitude": "-88.47427000"
                        },
                        {
                            "id": 115605,
                            "name": "Eagle Lake",
                            "latitude": "42.70696000",
                            "longitude": "-88.12814000"
                        },
                        {
                            "id": 115611,
                            "name": "Eagle River",
                            "latitude": "45.91718000",
                            "longitude": "-89.24430000"
                        },
                        {
                            "id": 115766,
                            "name": "East Troy",
                            "latitude": "42.78529000",
                            "longitude": "-88.40510000"
                        },
                        {
                            "id": 115790,
                            "name": "Easton",
                            "latitude": "43.83803000",
                            "longitude": "-89.80679000"
                        },
                        {
                            "id": 115810,
                            "name": "Eau Claire",
                            "latitude": "44.81135000",
                            "longitude": "-91.49849000"
                        },
                        {
                            "id": 115811,
                            "name": "Eau Claire County",
                            "latitude": "44.72677000",
                            "longitude": "-91.28600000"
                        },
                        {
                            "id": 115835,
                            "name": "Edgar",
                            "latitude": "44.92719000",
                            "longitude": "-89.96346000"
                        },
                        {
                            "id": 115850,
                            "name": "Edgerton",
                            "latitude": "42.83528000",
                            "longitude": "-89.06761000"
                        },
                        {
                            "id": 115953,
                            "name": "Eldorado",
                            "latitude": "43.82471000",
                            "longitude": "-88.62178000"
                        },
                        {
                            "id": 116002,
                            "name": "Elkhorn",
                            "latitude": "42.67279000",
                            "longitude": "-88.54454000"
                        },
                        {
                            "id": 116039,
                            "name": "Ellsworth",
                            "latitude": "44.73219000",
                            "longitude": "-92.48741000"
                        },
                        {
                            "id": 116044,
                            "name": "Elm Grove",
                            "latitude": "43.04307000",
                            "longitude": "-88.07898000"
                        },
                        {
                            "id": 116067,
                            "name": "Elroy",
                            "latitude": "43.74080000",
                            "longitude": "-90.27235000"
                        },
                        {
                            "id": 116254,
                            "name": "Evansville",
                            "latitude": "42.78028000",
                            "longitude": "-89.29928000"
                        },
                        {
                            "id": 116262,
                            "name": "Evergreen",
                            "latitude": "44.84247000",
                            "longitude": "-89.63762000"
                        },
                        {
                            "id": 116372,
                            "name": "Fall Creek",
                            "latitude": "44.76357000",
                            "longitude": "-91.27710000"
                        },
                        {
                            "id": 116374,
                            "name": "Fall River",
                            "latitude": "43.38443000",
                            "longitude": "-89.04511000"
                        },
                        {
                            "id": 116472,
                            "name": "Fennimore",
                            "latitude": "42.98360000",
                            "longitude": "-90.65540000"
                        },
                        {
                            "id": 116535,
                            "name": "Fitchburg",
                            "latitude": "42.96083000",
                            "longitude": "-89.46984000"
                        },
                        {
                            "id": 116584,
                            "name": "Florence",
                            "latitude": "45.92218000",
                            "longitude": "-88.25180000"
                        },
                        {
                            "id": 116588,
                            "name": "Florence County",
                            "latitude": "45.84845000",
                            "longitude": "-88.39816000"
                        },
                        {
                            "id": 116627,
                            "name": "Fond du Lac",
                            "latitude": "43.77500000",
                            "longitude": "-88.43883000"
                        },
                        {
                            "id": 116628,
                            "name": "Fond du Lac County",
                            "latitude": "43.75359000",
                            "longitude": "-88.48826000"
                        },
                        {
                            "id": 116630,
                            "name": "Fontana",
                            "latitude": "42.55141000",
                            "longitude": "-88.57510000"
                        },
                        {
                            "id": 116651,
                            "name": "Forest County",
                            "latitude": "45.66726000",
                            "longitude": "-88.77038000"
                        },
                        {
                            "id": 116689,
                            "name": "Fort Atkinson",
                            "latitude": "42.92889000",
                            "longitude": "-88.83705000"
                        },
                        {
                            "id": 116804,
                            "name": "Fox Lake",
                            "latitude": "43.56554000",
                            "longitude": "-88.90650000"
                        },
                        {
                            "id": 116806,
                            "name": "Fox Point",
                            "latitude": "43.15751000",
                            "longitude": "-87.90175000"
                        },
                        {
                            "id": 116841,
                            "name": "Franklin",
                            "latitude": "42.88863000",
                            "longitude": "-88.03842000"
                        },
                        {
                            "id": 116878,
                            "name": "Franksville",
                            "latitude": "42.76002000",
                            "longitude": "-87.91341000"
                        },
                        {
                            "id": 116884,
                            "name": "Frederic",
                            "latitude": "45.65912000",
                            "longitude": "-92.46714000"
                        },
                        {
                            "id": 116897,
                            "name": "Fredonia",
                            "latitude": "43.47056000",
                            "longitude": "-87.95065000"
                        },
                        {
                            "id": 116930,
                            "name": "French Island",
                            "latitude": "43.85830000",
                            "longitude": "-91.26042000"
                        },
                        {
                            "id": 116950,
                            "name": "Friendship",
                            "latitude": "43.97053000",
                            "longitude": "-89.81679000"
                        },
                        {
                            "id": 117029,
                            "name": "Galesville",
                            "latitude": "44.08163000",
                            "longitude": "-91.34904000"
                        },
                        {
                            "id": 117148,
                            "name": "Genoa City",
                            "latitude": "42.49835000",
                            "longitude": "-88.32815000"
                        },
                        {
                            "id": 117174,
                            "name": "Germantown",
                            "latitude": "43.22862000",
                            "longitude": "-88.11037000"
                        },
                        {
                            "id": 117216,
                            "name": "Gillett",
                            "latitude": "44.88999000",
                            "longitude": "-88.30732000"
                        },
                        {
                            "id": 117284,
                            "name": "Glendale",
                            "latitude": "43.13529000",
                            "longitude": "-87.93564000"
                        },
                        {
                            "id": 117295,
                            "name": "Glenmore",
                            "latitude": "44.38583000",
                            "longitude": "-87.92732000"
                        },
                        {
                            "id": 117316,
                            "name": "Glenwood City",
                            "latitude": "45.05857000",
                            "longitude": "-92.17241000"
                        },
                        {
                            "id": 117432,
                            "name": "Grafton",
                            "latitude": "43.31973000",
                            "longitude": "-87.95342000"
                        },
                        {
                            "id": 117515,
                            "name": "Grant County",
                            "latitude": "42.86749000",
                            "longitude": "-90.70622000"
                        },
                        {
                            "id": 117529,
                            "name": "Grantsburg",
                            "latitude": "45.77634000",
                            "longitude": "-92.68270000"
                        },
                        {
                            "id": 117591,
                            "name": "Green Bay",
                            "latitude": "44.51916000",
                            "longitude": "-88.01983000"
                        },
                        {
                            "id": 117593,
                            "name": "Green County",
                            "latitude": "42.67999000",
                            "longitude": "-89.60221000"
                        },
                        {
                            "id": 117601,
                            "name": "Green Lake",
                            "latitude": "43.84415000",
                            "longitude": "-88.96011000"
                        },
                        {
                            "id": 117602,
                            "name": "Green Lake County",
                            "latitude": "43.80038000",
                            "longitude": "-89.04486000"
                        },
                        {
                            "id": 117627,
                            "name": "Greendale",
                            "latitude": "42.94057000",
                            "longitude": "-87.99592000"
                        },
                        {
                            "id": 117653,
                            "name": "Greenfield",
                            "latitude": "42.96140000",
                            "longitude": "-88.01259000"
                        },
                        {
                            "id": 117708,
                            "name": "Greenwood",
                            "latitude": "44.77024000",
                            "longitude": "-90.59931000"
                        },
                        {
                            "id": 117851,
                            "name": "Hales Corners",
                            "latitude": "42.93751000",
                            "longitude": "-88.04870000"
                        },
                        {
                            "id": 117914,
                            "name": "Hammond",
                            "latitude": "44.97886000",
                            "longitude": "-92.43575000"
                        },
                        {
                            "id": 118061,
                            "name": "Harrison",
                            "latitude": "44.22776000",
                            "longitude": "-88.33591000"
                        },
                        {
                            "id": 118091,
                            "name": "Hartford",
                            "latitude": "43.31778000",
                            "longitude": "-88.37899000"
                        },
                        {
                            "id": 118097,
                            "name": "Hartland",
                            "latitude": "43.10501000",
                            "longitude": "-88.34204000"
                        },
                        {
                            "id": 118196,
                            "name": "Hayward",
                            "latitude": "46.01301000",
                            "longitude": "-91.48462000"
                        },
                        {
                            "id": 118205,
                            "name": "Hazel Green",
                            "latitude": "42.53278000",
                            "longitude": "-90.43457000"
                        },
                        {
                            "id": 118428,
                            "name": "Hilbert",
                            "latitude": "44.14027000",
                            "longitude": "-88.16399000"
                        },
                        {
                            "id": 118451,
                            "name": "Hillsboro",
                            "latitude": "43.65220000",
                            "longitude": "-90.34402000"
                        },
                        {
                            "id": 118499,
                            "name": "Hobart",
                            "latitude": "44.49925000",
                            "longitude": "-88.14986000"
                        },
                        {
                            "id": 118569,
                            "name": "Holmen",
                            "latitude": "43.96330000",
                            "longitude": "-91.25625000"
                        },
                        {
                            "id": 118663,
                            "name": "Horicon",
                            "latitude": "43.45138000",
                            "longitude": "-88.63121000"
                        },
                        {
                            "id": 118679,
                            "name": "Hortonville",
                            "latitude": "44.33470000",
                            "longitude": "-88.63816000"
                        },
                        {
                            "id": 118708,
                            "name": "Howard",
                            "latitude": "44.54360000",
                            "longitude": "-88.08816000"
                        },
                        {
                            "id": 118720,
                            "name": "Howards Grove",
                            "latitude": "43.83388000",
                            "longitude": "-87.82009000"
                        },
                        {
                            "id": 118746,
                            "name": "Hudson",
                            "latitude": "44.97469000",
                            "longitude": "-92.75687000"
                        },
                        {
                            "id": 118821,
                            "name": "Hurley",
                            "latitude": "46.44967000",
                            "longitude": "-90.18656000"
                        },
                        {
                            "id": 118833,
                            "name": "Hustisford",
                            "latitude": "43.34611000",
                            "longitude": "-88.60066000"
                        },
                        {
                            "id": 118896,
                            "name": "Independence",
                            "latitude": "44.35691000",
                            "longitude": "-91.42043000"
                        },
                        {
                            "id": 118967,
                            "name": "Iola",
                            "latitude": "44.50803000",
                            "longitude": "-89.13067000"
                        },
                        {
                            "id": 118979,
                            "name": "Iowa County",
                            "latitude": "43.00048000",
                            "longitude": "-90.13543000"
                        },
                        {
                            "id": 118990,
                            "name": "Iron County",
                            "latitude": "46.31706000",
                            "longitude": "-90.26445000"
                        },
                        {
                            "id": 119053,
                            "name": "Ixonia",
                            "latitude": "43.14389000",
                            "longitude": "-88.59732000"
                        },
                        {
                            "id": 119072,
                            "name": "Jackson",
                            "latitude": "43.32389000",
                            "longitude": "-88.16676000"
                        },
                        {
                            "id": 119093,
                            "name": "Jackson County",
                            "latitude": "44.31913000",
                            "longitude": "-90.80518000"
                        },
                        {
                            "id": 119131,
                            "name": "Janesville",
                            "latitude": "42.68279000",
                            "longitude": "-89.01872000"
                        },
                        {
                            "id": 119171,
                            "name": "Jefferson",
                            "latitude": "43.00556000",
                            "longitude": "-88.80733000"
                        },
                        {
                            "id": 119193,
                            "name": "Jefferson County",
                            "latitude": "43.02082000",
                            "longitude": "-88.77587000"
                        },
                        {
                            "id": 119272,
                            "name": "Johnson Creek",
                            "latitude": "43.07611000",
                            "longitude": "-88.77427000"
                        },
                        {
                            "id": 119337,
                            "name": "Juneau",
                            "latitude": "43.40555000",
                            "longitude": "-88.70510000"
                        },
                        {
                            "id": 119339,
                            "name": "Juneau County",
                            "latitude": "43.92444000",
                            "longitude": "-90.11402000"
                        },
                        {
                            "id": 119399,
                            "name": "Kaukauna",
                            "latitude": "44.27804000",
                            "longitude": "-88.27205000"
                        },
                        {
                            "id": 119476,
                            "name": "Kenosha",
                            "latitude": "42.58474000",
                            "longitude": "-87.82119000"
                        },
                        {
                            "id": 119477,
                            "name": "Kenosha County",
                            "latitude": "42.57280000",
                            "longitude": "-87.83981000"
                        },
                        {
                            "id": 119522,
                            "name": "Keshena",
                            "latitude": "44.88387000",
                            "longitude": "-88.63372000"
                        },
                        {
                            "id": 119533,
                            "name": "Kewaskum",
                            "latitude": "43.52083000",
                            "longitude": "-88.22899000"
                        },
                        {
                            "id": 119534,
                            "name": "Kewaunee",
                            "latitude": "44.45833000",
                            "longitude": "-87.50314000"
                        },
                        {
                            "id": 119535,
                            "name": "Kewaunee County",
                            "latitude": "44.48279000",
                            "longitude": "-87.51434000"
                        },
                        {
                            "id": 119554,
                            "name": "Kiel",
                            "latitude": "43.91249000",
                            "longitude": "-88.03565000"
                        },
                        {
                            "id": 119570,
                            "name": "Kimberly",
                            "latitude": "44.27221000",
                            "longitude": "-88.33900000"
                        },
                        {
                            "id": 119577,
                            "name": "King",
                            "latitude": "44.33748000",
                            "longitude": "-89.14178000"
                        },
                        {
                            "id": 119701,
                            "name": "Kohler",
                            "latitude": "43.73916000",
                            "longitude": "-87.78175000"
                        },
                        {
                            "id": 119717,
                            "name": "Kronenwetter",
                            "latitude": "44.82219000",
                            "longitude": "-89.59040000"
                        },
                        {
                            "id": 119744,
                            "name": "La Crosse",
                            "latitude": "43.80136000",
                            "longitude": "-91.23958000"
                        },
                        {
                            "id": 119745,
                            "name": "La Crosse County",
                            "latitude": "43.90653000",
                            "longitude": "-91.11522000"
                        },
                        {
                            "id": 119810,
                            "name": "Lac du Flambeau",
                            "latitude": "45.96967000",
                            "longitude": "-89.89210000"
                        },
                        {
                            "id": 119830,
                            "name": "Ladysmith",
                            "latitude": "45.46302000",
                            "longitude": "-91.10404000"
                        },
                        {
                            "id": 119842,
                            "name": "Lafayette County",
                            "latitude": "42.66050000",
                            "longitude": "-90.13172000"
                        },
                        {
                            "id": 119903,
                            "name": "Lake Delton",
                            "latitude": "43.60109000",
                            "longitude": "-89.79374000"
                        },
                        {
                            "id": 119913,
                            "name": "Lake Geneva",
                            "latitude": "42.59168000",
                            "longitude": "-88.43343000"
                        },
                        {
                            "id": 119915,
                            "name": "Lake Hallie",
                            "latitude": "44.87579000",
                            "longitude": "-91.44071000"
                        },
                        {
                            "id": 119928,
                            "name": "Lake Koshkonong",
                            "latitude": "42.90973000",
                            "longitude": "-88.91955000"
                        },
                        {
                            "id": 119943,
                            "name": "Lake Mills",
                            "latitude": "43.08139000",
                            "longitude": "-88.91177000"
                        },
                        {
                            "id": 119951,
                            "name": "Lake Nebagamon",
                            "latitude": "46.51494000",
                            "longitude": "-91.69991000"
                        },
                        {
                            "id": 119969,
                            "name": "Lake Ripley",
                            "latitude": "43.00583000",
                            "longitude": "-88.98622000"
                        },
                        {
                            "id": 119992,
                            "name": "Lake Wazeecha",
                            "latitude": "44.37108000",
                            "longitude": "-89.75651000"
                        },
                        {
                            "id": 119995,
                            "name": "Lake Wisconsin",
                            "latitude": "43.37360000",
                            "longitude": "-89.57568000"
                        },
                        {
                            "id": 119996,
                            "name": "Lake Wissota",
                            "latitude": "44.92635000",
                            "longitude": "-91.30099000"
                        },
                        {
                            "id": 120065,
                            "name": "Lamartine",
                            "latitude": "43.73332000",
                            "longitude": "-88.56872000"
                        },
                        {
                            "id": 120088,
                            "name": "Lancaster",
                            "latitude": "42.84749000",
                            "longitude": "-90.71068000"
                        },
                        {
                            "id": 120112,
                            "name": "Langlade County",
                            "latitude": "45.26236000",
                            "longitude": "-89.07191000"
                        },
                        {
                            "id": 120120,
                            "name": "Lannon",
                            "latitude": "43.14612000",
                            "longitude": "-88.16620000"
                        },
                        {
                            "id": 120332,
                            "name": "Legend Lake",
                            "latitude": "44.89095000",
                            "longitude": "-88.54406000"
                        },
                        {
                            "id": 120529,
                            "name": "Lincoln County",
                            "latitude": "45.33746000",
                            "longitude": "-89.73461000"
                        },
                        {
                            "id": 120614,
                            "name": "Little Chute",
                            "latitude": "44.27999000",
                            "longitude": "-88.31844000"
                        },
                        {
                            "id": 120629,
                            "name": "Little Round Lake",
                            "latitude": "45.96495000",
                            "longitude": "-91.36795000"
                        },
                        {
                            "id": 120690,
                            "name": "Lodi",
                            "latitude": "43.31388000",
                            "longitude": "-89.52651000"
                        },
                        {
                            "id": 120715,
                            "name": "Lomira",
                            "latitude": "43.59138000",
                            "longitude": "-88.44371000"
                        },
                        {
                            "id": 120853,
                            "name": "Loyal",
                            "latitude": "44.73691000",
                            "longitude": "-90.49597000"
                        },
                        {
                            "id": 120867,
                            "name": "Luck",
                            "latitude": "45.57607000",
                            "longitude": "-92.48270000"
                        },
                        {
                            "id": 120901,
                            "name": "Luxemburg",
                            "latitude": "44.53861000",
                            "longitude": "-87.70398000"
                        },
                        {
                            "id": 120999,
                            "name": "Madison",
                            "latitude": "43.07305000",
                            "longitude": "-89.40123000"
                        },
                        {
                            "id": 121050,
                            "name": "Maine",
                            "latitude": "45.02716000",
                            "longitude": "-89.69039000"
                        },
                        {
                            "id": 121086,
                            "name": "Manawa",
                            "latitude": "44.46443000",
                            "longitude": "-88.91983000"
                        },
                        {
                            "id": 121101,
                            "name": "Manchester",
                            "latitude": "43.69054000",
                            "longitude": "-89.04845000"
                        },
                        {
                            "id": 121127,
                            "name": "Manitowoc",
                            "latitude": "44.08861000",
                            "longitude": "-87.65758000"
                        },
                        {
                            "id": 121128,
                            "name": "Manitowoc County",
                            "latitude": "44.09054000",
                            "longitude": "-87.69974000"
                        },
                        {
                            "id": 121165,
                            "name": "Maple Bluff",
                            "latitude": "43.11833000",
                            "longitude": "-89.37956000"
                        },
                        {
                            "id": 121185,
                            "name": "Marathon",
                            "latitude": "44.92913000",
                            "longitude": "-89.84040000"
                        },
                        {
                            "id": 121186,
                            "name": "Marathon County",
                            "latitude": "44.89829000",
                            "longitude": "-89.75906000"
                        },
                        {
                            "id": 121228,
                            "name": "Marinette",
                            "latitude": "45.09998000",
                            "longitude": "-87.63066000"
                        },
                        {
                            "id": 121229,
                            "name": "Marinette County",
                            "latitude": "45.35090000",
                            "longitude": "-88.00222000"
                        },
                        {
                            "id": 121245,
                            "name": "Marion",
                            "latitude": "44.67081000",
                            "longitude": "-88.88927000"
                        },
                        {
                            "id": 121268,
                            "name": "Markesan",
                            "latitude": "43.70720000",
                            "longitude": "-88.99011000"
                        },
                        {
                            "id": 121294,
                            "name": "Marquette County",
                            "latitude": "43.81958000",
                            "longitude": "-89.39875000"
                        },
                        {
                            "id": 121309,
                            "name": "Marshall",
                            "latitude": "43.16833000",
                            "longitude": "-89.06678000"
                        },
                        {
                            "id": 121327,
                            "name": "Marshfield",
                            "latitude": "44.66885000",
                            "longitude": "-90.17180000"
                        },
                        {
                            "id": 121427,
                            "name": "Mauston",
                            "latitude": "43.79719000",
                            "longitude": "-90.07735000"
                        },
                        {
                            "id": 121456,
                            "name": "Mayville",
                            "latitude": "43.49416000",
                            "longitude": "-88.54482000"
                        },
                        {
                            "id": 121460,
                            "name": "Mazomanie",
                            "latitude": "43.17666000",
                            "longitude": "-89.79485000"
                        },
                        {
                            "id": 121499,
                            "name": "McFarland",
                            "latitude": "43.01250000",
                            "longitude": "-89.28984000"
                        },
                        {
                            "id": 121595,
                            "name": "Medford",
                            "latitude": "45.13858000",
                            "longitude": "-90.34014000"
                        },
                        {
                            "id": 121649,
                            "name": "Menasha",
                            "latitude": "44.20221000",
                            "longitude": "-88.44650000"
                        },
                        {
                            "id": 121664,
                            "name": "Menominee County",
                            "latitude": "45.00437000",
                            "longitude": "-88.71001000"
                        },
                        {
                            "id": 121665,
                            "name": "Menomonee Falls",
                            "latitude": "43.17890000",
                            "longitude": "-88.11731000"
                        },
                        {
                            "id": 121666,
                            "name": "Menomonie",
                            "latitude": "44.87552000",
                            "longitude": "-91.91934000"
                        },
                        {
                            "id": 121669,
                            "name": "Mequon",
                            "latitude": "43.21555000",
                            "longitude": "-88.03001000"
                        },
                        {
                            "id": 121707,
                            "name": "Merrill",
                            "latitude": "45.18052000",
                            "longitude": "-89.68346000"
                        },
                        {
                            "id": 121718,
                            "name": "Merton",
                            "latitude": "43.14667000",
                            "longitude": "-88.30676000"
                        },
                        {
                            "id": 121783,
                            "name": "Middleton",
                            "latitude": "43.09722000",
                            "longitude": "-89.50429000"
                        },
                        {
                            "id": 121858,
                            "name": "Milford",
                            "latitude": "43.10083000",
                            "longitude": "-88.84677000"
                        },
                        {
                            "id": 121918,
                            "name": "Milton",
                            "latitude": "42.77556000",
                            "longitude": "-88.94400000"
                        },
                        {
                            "id": 121923,
                            "name": "Milwaukee",
                            "latitude": "43.03890000",
                            "longitude": "-87.90647000"
                        },
                        {
                            "id": 121924,
                            "name": "Milwaukee County",
                            "latitude": "43.02172000",
                            "longitude": "-87.92908000"
                        },
                        {
                            "id": 121938,
                            "name": "Mineral Point",
                            "latitude": "42.86000000",
                            "longitude": "-90.17985000"
                        },
                        {
                            "id": 121977,
                            "name": "Mishicot",
                            "latitude": "44.23916000",
                            "longitude": "-87.64119000"
                        },
                        {
                            "id": 122036,
                            "name": "Mondovi",
                            "latitude": "44.56774000",
                            "longitude": "-91.67099000"
                        },
                        {
                            "id": 122052,
                            "name": "Monona",
                            "latitude": "43.06222000",
                            "longitude": "-89.33401000"
                        },
                        {
                            "id": 122063,
                            "name": "Monroe",
                            "latitude": "42.60112000",
                            "longitude": "-89.63845000"
                        },
                        {
                            "id": 122082,
                            "name": "Monroe County",
                            "latitude": "43.94575000",
                            "longitude": "-90.61787000"
                        },
                        {
                            "id": 122117,
                            "name": "Montello",
                            "latitude": "43.79137000",
                            "longitude": "-89.31984000"
                        },
                        {
                            "id": 122170,
                            "name": "Monticello",
                            "latitude": "42.74556000",
                            "longitude": "-89.59484000"
                        },
                        {
                            "id": 122305,
                            "name": "Mosinee",
                            "latitude": "44.79302000",
                            "longitude": "-89.70318000"
                        },
                        {
                            "id": 122354,
                            "name": "Mount Horeb",
                            "latitude": "43.00861000",
                            "longitude": "-89.73846000"
                        },
                        {
                            "id": 122366,
                            "name": "Mount Morris",
                            "latitude": "44.11442000",
                            "longitude": "-89.19067000"
                        },
                        {
                            "id": 122382,
                            "name": "Mount Pleasant",
                            "latitude": "42.69743000",
                            "longitude": "-87.85577000"
                        },
                        {
                            "id": 122451,
                            "name": "Mukwonago",
                            "latitude": "42.86668000",
                            "longitude": "-88.33343000"
                        },
                        {
                            "id": 122504,
                            "name": "Muscoda",
                            "latitude": "43.18499000",
                            "longitude": "-90.44318000"
                        },
                        {
                            "id": 122508,
                            "name": "Muskego",
                            "latitude": "42.90585000",
                            "longitude": "-88.13898000"
                        },
                        {
                            "id": 122569,
                            "name": "Nashotah",
                            "latitude": "43.09779000",
                            "longitude": "-88.40232000"
                        },
                        {
                            "id": 122579,
                            "name": "Nashville",
                            "latitude": "45.52274000",
                            "longitude": "-89.02484000"
                        },
                        {
                            "id": 122616,
                            "name": "Neenah",
                            "latitude": "44.18582000",
                            "longitude": "-88.46261000"
                        },
                        {
                            "id": 122618,
                            "name": "Neillsville",
                            "latitude": "44.55996000",
                            "longitude": "-90.59625000"
                        },
                        {
                            "id": 122619,
                            "name": "Nekoosa",
                            "latitude": "44.31246000",
                            "longitude": "-89.90429000"
                        },
                        {
                            "id": 122664,
                            "name": "New Berlin",
                            "latitude": "42.97640000",
                            "longitude": "-88.10842000"
                        },
                        {
                            "id": 122708,
                            "name": "New Glarus",
                            "latitude": "42.81445000",
                            "longitude": "-89.63512000"
                        },
                        {
                            "id": 122722,
                            "name": "New Holstein",
                            "latitude": "43.94999000",
                            "longitude": "-88.08426000"
                        },
                        {
                            "id": 122738,
                            "name": "New Lisbon",
                            "latitude": "43.87914000",
                            "longitude": "-90.16541000"
                        },
                        {
                            "id": 122745,
                            "name": "New London",
                            "latitude": "44.39276000",
                            "longitude": "-88.73983000"
                        },
                        {
                            "id": 122770,
                            "name": "New Richmond",
                            "latitude": "45.12302000",
                            "longitude": "-92.53659000"
                        },
                        {
                            "id": 122815,
                            "name": "Newburg",
                            "latitude": "43.43167000",
                            "longitude": "-88.04648000"
                        },
                        {
                            "id": 122880,
                            "name": "Niagara",
                            "latitude": "45.77135000",
                            "longitude": "-87.99485000"
                        },
                        {
                            "id": 123026,
                            "name": "North Fond du Lac",
                            "latitude": "43.81138000",
                            "longitude": "-88.48344000"
                        },
                        {
                            "id": 123043,
                            "name": "North Hudson",
                            "latitude": "44.99302000",
                            "longitude": "-92.75687000"
                        },
                        {
                            "id": 123049,
                            "name": "North La Crosse",
                            "latitude": "43.84635000",
                            "longitude": "-91.24819000"
                        },
                        {
                            "id": 123088,
                            "name": "North Prairie",
                            "latitude": "42.93446000",
                            "longitude": "-88.40537000"
                        },
                        {
                            "id": 123232,
                            "name": "Oak Creek",
                            "latitude": "42.88585000",
                            "longitude": "-87.86314000"
                        },
                        {
                            "id": 123277,
                            "name": "Oakfield",
                            "latitude": "43.68610000",
                            "longitude": "-88.54650000"
                        },
                        {
                            "id": 123350,
                            "name": "Oconomowoc",
                            "latitude": "43.11167000",
                            "longitude": "-88.49927000"
                        },
                        {
                            "id": 123351,
                            "name": "Oconto",
                            "latitude": "44.88721000",
                            "longitude": "-87.86455000"
                        },
                        {
                            "id": 123352,
                            "name": "Oconto County",
                            "latitude": "44.99851000",
                            "longitude": "-88.22056000"
                        },
                        {
                            "id": 123353,
                            "name": "Oconto Falls",
                            "latitude": "44.87388000",
                            "longitude": "-88.14288000"
                        },
                        {
                            "id": 123396,
                            "name": "Okauchee Lake",
                            "latitude": "43.12334000",
                            "longitude": "-88.44065000"
                        },
                        {
                            "id": 123466,
                            "name": "Omro",
                            "latitude": "44.03943000",
                            "longitude": "-88.74428000"
                        },
                        {
                            "id": 123468,
                            "name": "Onalaska",
                            "latitude": "43.88441000",
                            "longitude": "-91.23514000"
                        },
                        {
                            "id": 123474,
                            "name": "Oneida",
                            "latitude": "44.49860000",
                            "longitude": "-88.18288000"
                        },
                        {
                            "id": 123476,
                            "name": "Oneida County",
                            "latitude": "45.70559000",
                            "longitude": "-89.52171000"
                        },
                        {
                            "id": 123493,
                            "name": "Oostburg",
                            "latitude": "43.62277000",
                            "longitude": "-87.79453000"
                        },
                        {
                            "id": 123547,
                            "name": "Oregon",
                            "latitude": "42.92611000",
                            "longitude": "-89.38456000"
                        },
                        {
                            "id": 123553,
                            "name": "Orfordville",
                            "latitude": "42.62751000",
                            "longitude": "-89.25317000"
                        },
                        {
                            "id": 123602,
                            "name": "Osceola",
                            "latitude": "45.32052000",
                            "longitude": "-92.70493000"
                        },
                        {
                            "id": 123609,
                            "name": "Oshkosh",
                            "latitude": "44.02471000",
                            "longitude": "-88.54261000"
                        },
                        {
                            "id": 123615,
                            "name": "Osseo",
                            "latitude": "44.57218000",
                            "longitude": "-91.22738000"
                        },
                        {
                            "id": 123648,
                            "name": "Outagamie County",
                            "latitude": "44.41609000",
                            "longitude": "-88.46493000"
                        },
                        {
                            "id": 123700,
                            "name": "Ozaukee County",
                            "latitude": "43.40305000",
                            "longitude": "-87.89063000"
                        },
                        {
                            "id": 123713,
                            "name": "Paddock Lake",
                            "latitude": "42.57752000",
                            "longitude": "-88.10509000"
                        },
                        {
                            "id": 123778,
                            "name": "Palmyra",
                            "latitude": "42.87779000",
                            "longitude": "-88.58621000"
                        },
                        {
                            "id": 123828,
                            "name": "Pardeeville",
                            "latitude": "43.53776000",
                            "longitude": "-89.30012000"
                        },
                        {
                            "id": 123844,
                            "name": "Park Falls",
                            "latitude": "45.93440000",
                            "longitude": "-90.44155000"
                        },
                        {
                            "id": 123998,
                            "name": "Pell Lake",
                            "latitude": "42.53807000",
                            "longitude": "-88.35093000"
                        },
                        {
                            "id": 124053,
                            "name": "Pepin County",
                            "latitude": "44.48265000",
                            "longitude": "-92.14969000"
                        },
                        {
                            "id": 124099,
                            "name": "Peshtigo",
                            "latitude": "45.05443000",
                            "longitude": "-87.74927000"
                        },
                        {
                            "id": 124115,
                            "name": "Pewaukee",
                            "latitude": "43.08057000",
                            "longitude": "-88.26120000"
                        },
                        {
                            "id": 124134,
                            "name": "Phillips",
                            "latitude": "45.69663000",
                            "longitude": "-90.40043000"
                        },
                        {
                            "id": 124173,
                            "name": "Pierce County",
                            "latitude": "44.71963000",
                            "longitude": "-92.42249000"
                        },
                        {
                            "id": 124301,
                            "name": "Pittsfield",
                            "latitude": "44.59944000",
                            "longitude": "-88.24510000"
                        },
                        {
                            "id": 124357,
                            "name": "Platteville",
                            "latitude": "42.73416000",
                            "longitude": "-90.47846000"
                        },
                        {
                            "id": 124375,
                            "name": "Pleasant Prairie",
                            "latitude": "42.55308000",
                            "longitude": "-87.93341000"
                        },
                        {
                            "id": 124391,
                            "name": "Plover",
                            "latitude": "44.45636000",
                            "longitude": "-89.54401000"
                        },
                        {
                            "id": 124408,
                            "name": "Plymouth",
                            "latitude": "43.74861000",
                            "longitude": "-87.97704000"
                        },
                        {
                            "id": 124450,
                            "name": "Polk County",
                            "latitude": "45.46142000",
                            "longitude": "-92.44139000"
                        },
                        {
                            "id": 124505,
                            "name": "Port Edwards",
                            "latitude": "44.35080000",
                            "longitude": "-89.86540000"
                        },
                        {
                            "id": 124540,
                            "name": "Port Washington",
                            "latitude": "43.38722000",
                            "longitude": "-87.87564000"
                        },
                        {
                            "id": 124546,
                            "name": "Portage",
                            "latitude": "43.53915000",
                            "longitude": "-89.46262000"
                        },
                        {
                            "id": 124547,
                            "name": "Portage County",
                            "latitude": "44.47604000",
                            "longitude": "-89.50142000"
                        },
                        {
                            "id": 124564,
                            "name": "Portland",
                            "latitude": "43.76886000",
                            "longitude": "-90.85819000"
                        },
                        {
                            "id": 124595,
                            "name": "Potter Lake",
                            "latitude": "42.82168000",
                            "longitude": "-88.34898000"
                        },
                        {
                            "id": 124616,
                            "name": "Powers Lake",
                            "latitude": "42.55363000",
                            "longitude": "-88.29454000"
                        },
                        {
                            "id": 124621,
                            "name": "Poynette",
                            "latitude": "43.39026000",
                            "longitude": "-89.40290000"
                        },
                        {
                            "id": 124633,
                            "name": "Prairie du Chien",
                            "latitude": "43.05165000",
                            "longitude": "-91.14124000"
                        },
                        {
                            "id": 124634,
                            "name": "Prairie du Sac",
                            "latitude": "43.28693000",
                            "longitude": "-89.72401000"
                        },
                        {
                            "id": 124644,
                            "name": "Prescott",
                            "latitude": "44.74886000",
                            "longitude": "-92.80215000"
                        },
                        {
                            "id": 124664,
                            "name": "Price County",
                            "latitude": "45.68038000",
                            "longitude": "-90.36137000"
                        },
                        {
                            "id": 124691,
                            "name": "Princeton",
                            "latitude": "43.85081000",
                            "longitude": "-89.12178000"
                        },
                        {
                            "id": 124736,
                            "name": "Pulaski",
                            "latitude": "44.67222000",
                            "longitude": "-88.24260000"
                        },
                        {
                            "id": 124813,
                            "name": "Racine",
                            "latitude": "42.72613000",
                            "longitude": "-87.78285000"
                        },
                        {
                            "id": 124814,
                            "name": "Racine County",
                            "latitude": "42.72987000",
                            "longitude": "-87.81235000"
                        },
                        {
                            "id": 124869,
                            "name": "Randolph",
                            "latitude": "43.53915000",
                            "longitude": "-89.00678000"
                        },
                        {
                            "id": 124880,
                            "name": "Random Lake",
                            "latitude": "43.55222000",
                            "longitude": "-87.96176000"
                        },
                        {
                            "id": 124970,
                            "name": "Redgranite",
                            "latitude": "44.04192000",
                            "longitude": "-89.09845000"
                        },
                        {
                            "id": 124994,
                            "name": "Reedsburg",
                            "latitude": "43.53248000",
                            "longitude": "-90.00263000"
                        },
                        {
                            "id": 124996,
                            "name": "Reedsville",
                            "latitude": "44.15360000",
                            "longitude": "-87.95676000"
                        },
                        {
                            "id": 125046,
                            "name": "Rhinelander",
                            "latitude": "45.63662000",
                            "longitude": "-89.41208000"
                        },
                        {
                            "id": 125050,
                            "name": "Rib Mountain",
                            "latitude": "44.91275000",
                            "longitude": "-89.67540000"
                        },
                        {
                            "id": 125055,
                            "name": "Rice Lake",
                            "latitude": "45.50607000",
                            "longitude": "-91.73823000"
                        },
                        {
                            "id": 125062,
                            "name": "Richfield",
                            "latitude": "43.25612000",
                            "longitude": "-88.19398000"
                        },
                        {
                            "id": 125073,
                            "name": "Richland Center",
                            "latitude": "43.33471000",
                            "longitude": "-90.38679000"
                        },
                        {
                            "id": 125077,
                            "name": "Richland County",
                            "latitude": "43.37564000",
                            "longitude": "-90.42948000"
                        },
                        {
                            "id": 125095,
                            "name": "Richmond",
                            "latitude": "42.71473000",
                            "longitude": "-88.74927000"
                        },
                        {
                            "id": 125146,
                            "name": "Rio",
                            "latitude": "43.44776000",
                            "longitude": "-89.23984000"
                        },
                        {
                            "id": 125167,
                            "name": "Ripon",
                            "latitude": "43.84220000",
                            "longitude": "-88.83594000"
                        },
                        {
                            "id": 125178,
                            "name": "River Falls",
                            "latitude": "44.86136000",
                            "longitude": "-92.62381000"
                        },
                        {
                            "id": 125182,
                            "name": "River Hills",
                            "latitude": "43.17418000",
                            "longitude": "-87.92425000"
                        },
                        {
                            "id": 125241,
                            "name": "Roberts",
                            "latitude": "44.98386000",
                            "longitude": "-92.55603000"
                        },
                        {
                            "id": 125269,
                            "name": "Rochester",
                            "latitude": "42.74141000",
                            "longitude": "-88.22426000"
                        },
                        {
                            "id": 125274,
                            "name": "Rock County",
                            "latitude": "42.67122000",
                            "longitude": "-89.07158000"
                        },
                        {
                            "id": 125379,
                            "name": "Rome",
                            "latitude": "44.22060000",
                            "longitude": "-89.80843000"
                        },
                        {
                            "id": 125434,
                            "name": "Rosendale",
                            "latitude": "43.80776000",
                            "longitude": "-88.67483000"
                        },
                        {
                            "id": 125465,
                            "name": "Rothschild",
                            "latitude": "44.88719000",
                            "longitude": "-89.62012000"
                        },
                        {
                            "id": 125486,
                            "name": "Roxbury",
                            "latitude": "43.24943000",
                            "longitude": "-89.67540000"
                        },
                        {
                            "id": 125530,
                            "name": "Rusk County",
                            "latitude": "45.47513000",
                            "longitude": "-91.13312000"
                        },
                        {
                            "id": 125556,
                            "name": "Rutland",
                            "latitude": "42.87889000",
                            "longitude": "-89.35012000"
                        },
                        {
                            "id": 125628,
                            "name": "Saint Croix County",
                            "latitude": "45.03407000",
                            "longitude": "-92.45278000"
                        },
                        {
                            "id": 125629,
                            "name": "Saint Croix Falls",
                            "latitude": "45.40996000",
                            "longitude": "-92.63965000"
                        },
                        {
                            "id": 125634,
                            "name": "Saint Francis",
                            "latitude": "42.96752000",
                            "longitude": "-87.87758000"
                        },
                        {
                            "id": 125709,
                            "name": "Saint Peter",
                            "latitude": "43.83638000",
                            "longitude": "-88.34149000"
                        },
                        {
                            "id": 125738,
                            "name": "Salem",
                            "latitude": "42.55474000",
                            "longitude": "-88.11092000"
                        },
                        {
                            "id": 125963,
                            "name": "Sauk City",
                            "latitude": "43.27082000",
                            "longitude": "-89.72207000"
                        },
                        {
                            "id": 125964,
                            "name": "Sauk County",
                            "latitude": "43.42678000",
                            "longitude": "-89.94837000"
                        },
                        {
                            "id": 125967,
                            "name": "Saukville",
                            "latitude": "43.38167000",
                            "longitude": "-87.94065000"
                        },
                        {
                            "id": 125982,
                            "name": "Sawyer County",
                            "latitude": "45.87997000",
                            "longitude": "-91.14467000"
                        },
                        {
                            "id": 126013,
                            "name": "Schofield",
                            "latitude": "44.90969000",
                            "longitude": "-89.60457000"
                        },
                        {
                            "id": 126197,
                            "name": "Seymour",
                            "latitude": "44.51499000",
                            "longitude": "-88.33038000"
                        },
                        {
                            "id": 126230,
                            "name": "Sharon",
                            "latitude": "42.50252000",
                            "longitude": "-88.72899000"
                        },
                        {
                            "id": 126247,
                            "name": "Shawano",
                            "latitude": "44.78221000",
                            "longitude": "-88.60899000"
                        },
                        {
                            "id": 126248,
                            "name": "Shawano County",
                            "latitude": "44.78915000",
                            "longitude": "-88.76544000"
                        },
                        {
                            "id": 126255,
                            "name": "Sheboygan",
                            "latitude": "43.75083000",
                            "longitude": "-87.71453000"
                        },
                        {
                            "id": 126256,
                            "name": "Sheboygan County",
                            "latitude": "43.75622000",
                            "longitude": "-87.79999000"
                        },
                        {
                            "id": 126257,
                            "name": "Sheboygan Falls",
                            "latitude": "43.73197000",
                            "longitude": "-87.82213000"
                        },
                        {
                            "id": 126290,
                            "name": "Shell Lake",
                            "latitude": "45.73939000",
                            "longitude": "-91.92545000"
                        },
                        {
                            "id": 126341,
                            "name": "Sherwood",
                            "latitude": "44.17360000",
                            "longitude": "-88.25983000"
                        },
                        {
                            "id": 126372,
                            "name": "Shorewood",
                            "latitude": "43.08918000",
                            "longitude": "-87.88758000"
                        },
                        {
                            "id": 126374,
                            "name": "Shorewood Hills",
                            "latitude": "43.07750000",
                            "longitude": "-89.44568000"
                        },
                        {
                            "id": 126388,
                            "name": "Shullsburg",
                            "latitude": "42.57334000",
                            "longitude": "-90.23096000"
                        },
                        {
                            "id": 126430,
                            "name": "Silver Lake",
                            "latitude": "44.05526000",
                            "longitude": "-89.22623000"
                        },
                        {
                            "id": 126463,
                            "name": "Siren",
                            "latitude": "45.78578000",
                            "longitude": "-92.38103000"
                        },
                        {
                            "id": 126502,
                            "name": "Slinger",
                            "latitude": "43.33361000",
                            "longitude": "-88.28621000"
                        },
                        {
                            "id": 126576,
                            "name": "Somers",
                            "latitude": "42.64030000",
                            "longitude": "-87.91036000"
                        },
                        {
                            "id": 126585,
                            "name": "Somerset",
                            "latitude": "45.12441000",
                            "longitude": "-92.67354000"
                        },
                        {
                            "id": 126693,
                            "name": "South Milwaukee",
                            "latitude": "42.91057000",
                            "longitude": "-87.86064000"
                        },
                        {
                            "id": 126813,
                            "name": "Sparta",
                            "latitude": "43.94413000",
                            "longitude": "-90.81291000"
                        },
                        {
                            "id": 126827,
                            "name": "Spencer",
                            "latitude": "44.75774000",
                            "longitude": "-90.29680000"
                        },
                        {
                            "id": 126845,
                            "name": "Spooner",
                            "latitude": "45.82245000",
                            "longitude": "-91.88934000"
                        },
                        {
                            "id": 126856,
                            "name": "Spring Green",
                            "latitude": "43.17527000",
                            "longitude": "-90.06790000"
                        },
                        {
                            "id": 126879,
                            "name": "Spring Valley",
                            "latitude": "44.84524000",
                            "longitude": "-92.23880000"
                        },
                        {
                            "id": 126960,
                            "name": "Stanley",
                            "latitude": "44.95997000",
                            "longitude": "-90.93708000"
                        },
                        {
                            "id": 127043,
                            "name": "Stevens Point",
                            "latitude": "44.52358000",
                            "longitude": "-89.57456000"
                        },
                        {
                            "id": 127110,
                            "name": "Stoughton",
                            "latitude": "42.91695000",
                            "longitude": "-89.21789000"
                        },
                        {
                            "id": 127126,
                            "name": "Stratford",
                            "latitude": "44.80108000",
                            "longitude": "-90.07930000"
                        },
                        {
                            "id": 127141,
                            "name": "Strum",
                            "latitude": "44.54969000",
                            "longitude": "-91.39266000"
                        },
                        {
                            "id": 127149,
                            "name": "Sturgeon Bay",
                            "latitude": "44.83416000",
                            "longitude": "-87.37704000"
                        },
                        {
                            "id": 127153,
                            "name": "Sturtevant",
                            "latitude": "42.69807000",
                            "longitude": "-87.89452000"
                        },
                        {
                            "id": 127156,
                            "name": "Suamico",
                            "latitude": "44.63194000",
                            "longitude": "-88.03927000"
                        },
                        {
                            "id": 127237,
                            "name": "Sun Prairie",
                            "latitude": "43.18360000",
                            "longitude": "-89.21373000"
                        },
                        {
                            "id": 127279,
                            "name": "Superior",
                            "latitude": "46.72077000",
                            "longitude": "-92.10408000"
                        },
                        {
                            "id": 127302,
                            "name": "Sussex",
                            "latitude": "43.13390000",
                            "longitude": "-88.22204000"
                        },
                        {
                            "id": 127381,
                            "name": "Tainter Lake",
                            "latitude": "44.98913000",
                            "longitude": "-91.84767000"
                        },
                        {
                            "id": 127457,
                            "name": "Taylor County",
                            "latitude": "45.21153000",
                            "longitude": "-90.50125000"
                        },
                        {
                            "id": 127569,
                            "name": "Theresa",
                            "latitude": "43.51722000",
                            "longitude": "-88.45121000"
                        },
                        {
                            "id": 127576,
                            "name": "Thiensville",
                            "latitude": "43.23751000",
                            "longitude": "-87.97870000"
                        },
                        {
                            "id": 127607,
                            "name": "Thorp",
                            "latitude": "44.96108000",
                            "longitude": "-90.79986000"
                        },
                        {
                            "id": 127634,
                            "name": "Tichigan",
                            "latitude": "42.82890000",
                            "longitude": "-88.19759000"
                        },
                        {
                            "id": 127708,
                            "name": "Tomah",
                            "latitude": "43.97858000",
                            "longitude": "-90.50402000"
                        },
                        {
                            "id": 127709,
                            "name": "Tomahawk",
                            "latitude": "45.47108000",
                            "longitude": "-89.72986000"
                        },
                        {
                            "id": 127798,
                            "name": "Trempealeau",
                            "latitude": "44.00552000",
                            "longitude": "-91.44209000"
                        },
                        {
                            "id": 127799,
                            "name": "Trempealeau County",
                            "latitude": "44.30395000",
                            "longitude": "-91.35846000"
                        },
                        {
                            "id": 127908,
                            "name": "Turtle Lake",
                            "latitude": "45.39440000",
                            "longitude": "-92.14241000"
                        },
                        {
                            "id": 127929,
                            "name": "Twin Lakes",
                            "latitude": "42.53113000",
                            "longitude": "-88.24815000"
                        },
                        {
                            "id": 127936,
                            "name": "Two Rivers",
                            "latitude": "44.15388000",
                            "longitude": "-87.56925000"
                        },
                        {
                            "id": 128005,
                            "name": "Union Grove",
                            "latitude": "42.68807000",
                            "longitude": "-88.05147000"
                        },
                        {
                            "id": 128193,
                            "name": "Vernon County",
                            "latitude": "43.59386000",
                            "longitude": "-90.83438000"
                        },
                        {
                            "id": 128205,
                            "name": "Verona",
                            "latitude": "42.99083000",
                            "longitude": "-89.53318000"
                        },
                        {
                            "id": 128240,
                            "name": "Vilas County",
                            "latitude": "46.05289000",
                            "longitude": "-89.51477000"
                        },
                        {
                            "id": 128292,
                            "name": "Viroqua",
                            "latitude": "43.55692000",
                            "longitude": "-90.88874000"
                        },
                        {
                            "id": 128380,
                            "name": "Wales",
                            "latitude": "43.00445000",
                            "longitude": "-88.37676000"
                        },
                        {
                            "id": 128440,
                            "name": "Walworth",
                            "latitude": "42.53113000",
                            "longitude": "-88.59955000"
                        },
                        {
                            "id": 128442,
                            "name": "Walworth County",
                            "latitude": "42.66848000",
                            "longitude": "-88.54192000"
                        },
                        {
                            "id": 128534,
                            "name": "Washburn",
                            "latitude": "46.67327000",
                            "longitude": "-90.89491000"
                        },
                        {
                            "id": 128536,
                            "name": "Washburn County",
                            "latitude": "45.89918000",
                            "longitude": "-91.79123000"
                        },
                        {
                            "id": 128576,
                            "name": "Washington County",
                            "latitude": "43.36847000",
                            "longitude": "-88.23078000"
                        },
                        {
                            "id": 128608,
                            "name": "Waterford",
                            "latitude": "42.76307000",
                            "longitude": "-88.21426000"
                        },
                        {
                            "id": 128616,
                            "name": "Waterloo",
                            "latitude": "43.18388000",
                            "longitude": "-88.98844000"
                        },
                        {
                            "id": 128624,
                            "name": "Watertown",
                            "latitude": "43.19472000",
                            "longitude": "-88.72899000"
                        },
                        {
                            "id": 128649,
                            "name": "Waukesha",
                            "latitude": "43.01168000",
                            "longitude": "-88.23148000"
                        },
                        {
                            "id": 128650,
                            "name": "Waukesha County",
                            "latitude": "43.01819000",
                            "longitude": "-88.30453000"
                        },
                        {
                            "id": 128654,
                            "name": "Waunakee",
                            "latitude": "43.19194000",
                            "longitude": "-89.45567000"
                        },
                        {
                            "id": 128655,
                            "name": "Waupaca",
                            "latitude": "44.35803000",
                            "longitude": "-89.08595000"
                        },
                        {
                            "id": 128656,
                            "name": "Waupaca County",
                            "latitude": "44.47043000",
                            "longitude": "-88.96482000"
                        },
                        {
                            "id": 128657,
                            "name": "Waupun",
                            "latitude": "43.63332000",
                            "longitude": "-88.72955000"
                        },
                        {
                            "id": 128660,
                            "name": "Wausau",
                            "latitude": "44.95914000",
                            "longitude": "-89.63012000"
                        },
                        {
                            "id": 128661,
                            "name": "Waushara County",
                            "latitude": "44.11312000",
                            "longitude": "-89.24288000"
                        },
                        {
                            "id": 128662,
                            "name": "Wautoma",
                            "latitude": "44.07470000",
                            "longitude": "-89.28790000"
                        },
                        {
                            "id": 128663,
                            "name": "Wauwatosa",
                            "latitude": "43.04946000",
                            "longitude": "-88.00759000"
                        },
                        {
                            "id": 128817,
                            "name": "West Allis",
                            "latitude": "43.01668000",
                            "longitude": "-88.00703000"
                        },
                        {
                            "id": 128820,
                            "name": "West Baraboo",
                            "latitude": "43.47443000",
                            "longitude": "-89.77040000"
                        },
                        {
                            "id": 128825,
                            "name": "West Bend",
                            "latitude": "43.42528000",
                            "longitude": "-88.18343000"
                        },
                        {
                            "id": 128933,
                            "name": "West Milwaukee",
                            "latitude": "43.01251000",
                            "longitude": "-87.97259000"
                        },
                        {
                            "id": 128973,
                            "name": "West Salem",
                            "latitude": "43.89913000",
                            "longitude": "-91.08125000"
                        },
                        {
                            "id": 129016,
                            "name": "Westby",
                            "latitude": "43.65692000",
                            "longitude": "-90.85430000"
                        },
                        {
                            "id": 129032,
                            "name": "Westfield",
                            "latitude": "43.88359000",
                            "longitude": "-89.49346000"
                        },
                        {
                            "id": 129063,
                            "name": "Weston",
                            "latitude": "44.89080000",
                            "longitude": "-89.54762000"
                        },
                        {
                            "id": 129098,
                            "name": "Weyauwega",
                            "latitude": "44.32137000",
                            "longitude": "-88.93372000"
                        },
                        {
                            "id": 129167,
                            "name": "Whitefish Bay",
                            "latitude": "43.11334000",
                            "longitude": "-87.90009000"
                        },
                        {
                            "id": 129171,
                            "name": "Whitehall",
                            "latitude": "44.36746000",
                            "longitude": "-91.31655000"
                        },
                        {
                            "id": 129191,
                            "name": "Whitewater",
                            "latitude": "42.83362000",
                            "longitude": "-88.73233000"
                        },
                        {
                            "id": 129197,
                            "name": "Whiting",
                            "latitude": "44.49358000",
                            "longitude": "-89.55873000"
                        },
                        {
                            "id": 129263,
                            "name": "Williams Bay",
                            "latitude": "42.57807000",
                            "longitude": "-88.54093000"
                        },
                        {
                            "id": 129364,
                            "name": "Wind Lake",
                            "latitude": "42.82946000",
                            "longitude": "-88.15870000"
                        },
                        {
                            "id": 129365,
                            "name": "Wind Point",
                            "latitude": "42.78446000",
                            "longitude": "-87.76619000"
                        },
                        {
                            "id": 129385,
                            "name": "Windsor",
                            "latitude": "43.21833000",
                            "longitude": "-89.34151000"
                        },
                        {
                            "id": 129409,
                            "name": "Winnebago County",
                            "latitude": "44.06888000",
                            "longitude": "-88.64466000"
                        },
                        {
                            "id": 129410,
                            "name": "Winneconne",
                            "latitude": "44.11082000",
                            "longitude": "-88.71261000"
                        },
                        {
                            "id": 129457,
                            "name": "Wisconsin Dells",
                            "latitude": "43.62748000",
                            "longitude": "-89.77096000"
                        },
                        {
                            "id": 129458,
                            "name": "Wisconsin Rapids",
                            "latitude": "44.38358000",
                            "longitude": "-89.81735000"
                        },
                        {
                            "id": 129464,
                            "name": "Wittenberg",
                            "latitude": "44.82720000",
                            "longitude": "-89.16956000"
                        },
                        {
                            "id": 129486,
                            "name": "Wood County",
                            "latitude": "44.45532000",
                            "longitude": "-90.04158000"
                        },
                        {
                            "id": 129573,
                            "name": "Woodville",
                            "latitude": "44.95302000",
                            "longitude": "-92.29130000"
                        },
                        {
                            "id": 129612,
                            "name": "Wrightstown",
                            "latitude": "44.32582000",
                            "longitude": "-88.16288000"
                        }
                    ]
                },
                {
                    "id": 1442,
                    "name": "Wyoming",
                    "state_code": "WY",
                    "cities": [
                        {
                            "id": 111054,
                            "name": "Afton",
                            "latitude": "42.72493000",
                            "longitude": "-110.93187000"
                        },
                        {
                            "id": 111113,
                            "name": "Albany County",
                            "latitude": "41.65447000",
                            "longitude": "-105.72391000"
                        },
                        {
                            "id": 111366,
                            "name": "Antelope Valley-Crestview",
                            "latitude": "44.22488000",
                            "longitude": "-105.47409000"
                        },
                        {
                            "id": 111414,
                            "name": "Arapahoe",
                            "latitude": "42.96218000",
                            "longitude": "-108.48983000"
                        },
                        {
                            "id": 111807,
                            "name": "Bar Nunn",
                            "latitude": "42.91358000",
                            "longitude": "-106.34336000"
                        },
                        {
                            "id": 111878,
                            "name": "Basin",
                            "latitude": "44.37996000",
                            "longitude": "-108.03899000"
                        },
                        {
                            "id": 112322,
                            "name": "Big Horn County",
                            "latitude": "44.52682000",
                            "longitude": "-107.99521000"
                        },
                        {
                            "id": 113016,
                            "name": "Buffalo",
                            "latitude": "44.34831000",
                            "longitude": "-106.69894000"
                        },
                        {
                            "id": 113303,
                            "name": "Campbell County",
                            "latitude": "44.24839000",
                            "longitude": "-105.54831000"
                        },
                        {
                            "id": 113385,
                            "name": "Carbon County",
                            "latitude": "41.69459000",
                            "longitude": "-106.93061000"
                        },
                        {
                            "id": 113524,
                            "name": "Casper",
                            "latitude": "42.86663000",
                            "longitude": "-106.31308000"
                        },
                        {
                            "id": 113926,
                            "name": "Cheyenne",
                            "latitude": "41.13998000",
                            "longitude": "-104.82025000"
                        },
                        {
                            "id": 114364,
                            "name": "Cody",
                            "latitude": "44.52634000",
                            "longitude": "-109.05653000"
                        },
                        {
                            "id": 114580,
                            "name": "Converse County",
                            "latitude": "42.97233000",
                            "longitude": "-105.50706000"
                        },
                        {
                            "id": 114837,
                            "name": "Crook County",
                            "latitude": "44.58860000",
                            "longitude": "-104.56994000"
                        },
                        {
                            "id": 115424,
                            "name": "Douglas",
                            "latitude": "42.75969000",
                            "longitude": "-105.38221000"
                        },
                        {
                            "id": 116214,
                            "name": "Ethete",
                            "latitude": "43.02496000",
                            "longitude": "-108.77262000"
                        },
                        {
                            "id": 116252,
                            "name": "Evanston",
                            "latitude": "41.26828000",
                            "longitude": "-110.96324000"
                        },
                        {
                            "id": 116255,
                            "name": "Evansville",
                            "latitude": "42.85997000",
                            "longitude": "-106.26836000"
                        },
                        {
                            "id": 116760,
                            "name": "Fort Washakie",
                            "latitude": "43.00635000",
                            "longitude": "-108.88235000"
                        },
                        {
                            "id": 116801,
                            "name": "Fox Farm-College",
                            "latitude": "41.11203000",
                            "longitude": "-104.78546000"
                        },
                        {
                            "id": 116928,
                            "name": "Fremont County",
                            "latitude": "43.04053000",
                            "longitude": "-108.63042000"
                        },
                        {
                            "id": 117217,
                            "name": "Gillette",
                            "latitude": "44.29109000",
                            "longitude": "-105.50222000"
                        },
                        {
                            "id": 117303,
                            "name": "Glenrock",
                            "latitude": "42.86136000",
                            "longitude": "-105.87223000"
                        },
                        {
                            "id": 117411,
                            "name": "Goshen County",
                            "latitude": "42.08794000",
                            "longitude": "-104.35326000"
                        },
                        {
                            "id": 117606,
                            "name": "Green River",
                            "latitude": "41.52858000",
                            "longitude": "-109.46625000"
                        },
                        {
                            "id": 117728,
                            "name": "Greybull",
                            "latitude": "44.48912000",
                            "longitude": "-108.05621000"
                        },
                        {
                            "id": 117781,
                            "name": "Guernsey",
                            "latitude": "42.26969000",
                            "longitude": "-104.74163000"
                        },
                        {
                            "id": 118495,
                            "name": "Hoback",
                            "latitude": "43.28187000",
                            "longitude": "-110.78381000"
                        },
                        {
                            "id": 118684,
                            "name": "Hot Springs County",
                            "latitude": "43.71893000",
                            "longitude": "-108.44210000"
                        },
                        {
                            "id": 119074,
                            "name": "Jackson",
                            "latitude": "43.47993000",
                            "longitude": "-110.76243000"
                        },
                        {
                            "id": 119271,
                            "name": "Johnson County",
                            "latitude": "44.03877000",
                            "longitude": "-106.58463000"
                        },
                        {
                            "id": 119434,
                            "name": "Kemmerer",
                            "latitude": "41.79245000",
                            "longitude": "-110.53767000"
                        },
                        {
                            "id": 120096,
                            "name": "Lander",
                            "latitude": "42.83301000",
                            "longitude": "-108.73067000"
                        },
                        {
                            "id": 120138,
                            "name": "Laramie",
                            "latitude": "41.31137000",
                            "longitude": "-105.59110000"
                        },
                        {
                            "id": 120139,
                            "name": "Laramie County",
                            "latitude": "41.30707000",
                            "longitude": "-104.68962000"
                        },
                        {
                            "id": 120538,
                            "name": "Lincoln County",
                            "latitude": "42.26404000",
                            "longitude": "-110.65597000"
                        },
                        {
                            "id": 120824,
                            "name": "Lovell",
                            "latitude": "44.83745000",
                            "longitude": "-108.38956000"
                        },
                        {
                            "id": 120892,
                            "name": "Lusk",
                            "latitude": "42.76247000",
                            "longitude": "-104.45217000"
                        },
                        {
                            "id": 120910,
                            "name": "Lyman",
                            "latitude": "41.32745000",
                            "longitude": "-110.29293000"
                        },
                        {
                            "id": 121190,
                            "name": "Marbleton",
                            "latitude": "42.55355000",
                            "longitude": "-110.10932000"
                        },
                        {
                            "id": 121896,
                            "name": "Mills",
                            "latitude": "42.84052000",
                            "longitude": "-106.36586000"
                        },
                        {
                            "id": 122199,
                            "name": "Moorcroft",
                            "latitude": "44.26331000",
                            "longitude": "-104.95025000"
                        },
                        {
                            "id": 122215,
                            "name": "Moose Wilson Road",
                            "latitude": "43.52521000",
                            "longitude": "-110.84466000"
                        },
                        {
                            "id": 122433,
                            "name": "Mountain View",
                            "latitude": "41.26884000",
                            "longitude": "-110.33988000"
                        },
                        {
                            "id": 122594,
                            "name": "Natrona County",
                            "latitude": "42.96224000",
                            "longitude": "-106.79849000"
                        },
                        {
                            "id": 122822,
                            "name": "Newcastle",
                            "latitude": "43.85470000",
                            "longitude": "-104.20494000"
                        },
                        {
                            "id": 122904,
                            "name": "Niobrara County",
                            "latitude": "43.05650000",
                            "longitude": "-104.47538000"
                        },
                        {
                            "id": 123097,
                            "name": "North Rock Springs",
                            "latitude": "41.64358000",
                            "longitude": "-109.26568000"
                        },
                        {
                            "id": 123843,
                            "name": "Park County",
                            "latitude": "44.52057000",
                            "longitude": "-109.58853000"
                        },
                        {
                            "id": 124215,
                            "name": "Pine Bluffs",
                            "latitude": "41.18193000",
                            "longitude": "-104.06912000"
                        },
                        {
                            "id": 124249,
                            "name": "Pinedale",
                            "latitude": "42.86661000",
                            "longitude": "-109.86099000"
                        },
                        {
                            "id": 124355,
                            "name": "Platte County",
                            "latitude": "42.13305000",
                            "longitude": "-104.96582000"
                        },
                        {
                            "id": 124611,
                            "name": "Powell",
                            "latitude": "44.75384000",
                            "longitude": "-108.75735000"
                        },
                        {
                            "id": 124820,
                            "name": "Rafter J Ranch",
                            "latitude": "43.42604000",
                            "longitude": "-110.79909000"
                        },
                        {
                            "id": 124846,
                            "name": "Ranchettes",
                            "latitude": "41.21859000",
                            "longitude": "-104.79025000"
                        },
                        {
                            "id": 124912,
                            "name": "Rawlins",
                            "latitude": "41.79107000",
                            "longitude": "-107.23866000"
                        },
                        {
                            "id": 125214,
                            "name": "Riverton",
                            "latitude": "43.02496000",
                            "longitude": "-108.38010000"
                        },
                        {
                            "id": 125287,
                            "name": "Rock Springs",
                            "latitude": "41.58746000",
                            "longitude": "-109.20290000"
                        },
                        {
                            "id": 125942,
                            "name": "Saratoga",
                            "latitude": "41.45496000",
                            "longitude": "-106.80643000"
                        },
                        {
                            "id": 126323,
                            "name": "Sheridan",
                            "latitude": "44.79719000",
                            "longitude": "-106.95618000"
                        },
                        {
                            "id": 126328,
                            "name": "Sheridan County",
                            "latitude": "44.79004000",
                            "longitude": "-106.87948000"
                        },
                        {
                            "id": 126500,
                            "name": "Sleepy Hollow",
                            "latitude": "44.24109000",
                            "longitude": "-105.42222000"
                        },
                        {
                            "id": 126662,
                            "name": "South Greeley",
                            "latitude": "41.09693000",
                            "longitude": "-104.80636000"
                        },
                        {
                            "id": 126703,
                            "name": "South Park",
                            "latitude": "43.42215000",
                            "longitude": "-110.79326000"
                        },
                        {
                            "id": 126982,
                            "name": "Star Valley Ranch",
                            "latitude": "42.97139000",
                            "longitude": "-110.95556000"
                        },
                        {
                            "id": 127158,
                            "name": "Sublette County",
                            "latitude": "42.76691000",
                            "longitude": "-109.91471000"
                        },
                        {
                            "id": 127248,
                            "name": "Sundance",
                            "latitude": "44.40637000",
                            "longitude": "-104.37578000"
                        },
                        {
                            "id": 127343,
                            "name": "Sweetwater County",
                            "latitude": "41.65950000",
                            "longitude": "-108.87942000"
                        },
                        {
                            "id": 127539,
                            "name": "Teton County",
                            "latitude": "43.93476000",
                            "longitude": "-110.58974000"
                        },
                        {
                            "id": 127572,
                            "name": "Thermopolis",
                            "latitude": "43.64607000",
                            "longitude": "-108.21204000"
                        },
                        {
                            "id": 127745,
                            "name": "Torrington",
                            "latitude": "42.06246000",
                            "longitude": "-104.18439000"
                        },
                        {
                            "id": 127955,
                            "name": "Uinta County",
                            "latitude": "41.28756000",
                            "longitude": "-110.54759000"
                        },
                        {
                            "id": 128056,
                            "name": "Upton",
                            "latitude": "44.09970000",
                            "longitude": "-104.62802000"
                        },
                        {
                            "id": 128532,
                            "name": "Washakie County",
                            "latitude": "43.90500000",
                            "longitude": "-107.68286000"
                        },
                        {
                            "id": 129064,
                            "name": "Weston County",
                            "latitude": "43.84049000",
                            "longitude": "-104.56783000"
                        },
                        {
                            "id": 129109,
                            "name": "Wheatland",
                            "latitude": "42.05407000",
                            "longitude": "-104.95295000"
                        },
                        {
                            "id": 129333,
                            "name": "Wilson",
                            "latitude": "43.50076000",
                            "longitude": "-110.87521000"
                        },
                        {
                            "id": 129589,
                            "name": "Worland",
                            "latitude": "44.01690000",
                            "longitude": "-107.95537000"
                        },
                        {
                            "id": 129606,
                            "name": "Wright",
                            "latitude": "43.75124000",
                            "longitude": "-105.49201000"
                        }
                    ]
                }
            ]
        },{
			"id": 210,
			"name": "Canada",
			"iso3": "CAN",
            "iso2": "CA",
            "phone_code": "1",
            "capital": "Ottawa",
            "currency": "CAD",
			"states": [
				{
					"id": 28162,
					"name": "Alberta",
					"state_code": "AB",
                    "cities": [
						{
							"id": 11001,
							"name": "Banff"
						},
						{
							"id": 11002,
							"name": "Brooks"
						},
						{
							"id": 11003,
							"name": "Calgary"
						},
						{
							"id": 11004,
							"name": "Edmonton"
						},
						{
							"id": 11005,
							"name": "Fort McMurray"
						},
						{
							"id": 11006,
							"name": "Grande Prairie"
						},
						{
							"id": 11007,
							"name": "Jasper"
						},
						{
							"id": 11008,
							"name": "Lake Louise"
						},
						{
							"id": 11009,
							"name": "Lethbridge"
						},
						{
							"id": 11010,
							"name": "Medicine Hat"
						},
						{
							"id": 11011,
							"name": "Red Deer"
						},
						{
							"id": 11012,
							"name": "Saint Albert"
						},
					]
				},
				{
					"id": 28163,
					"name": "British Columbia",
					"state_code": "BC",
					// "state_code": "71",
                    "cities": [
						{
							"id": 21001,
							"name": "Barkerville"
						},
						{
							"id": 21002,
							"name": "Burnaby"
						},
						{
							"id": 21003,
							"name": "Campbell River"
						},
						{
							"id": 21004,
							"name": "Chilliwack"
						},
						{
							"id": 21005,
							"name": "Courtenay"
						},
						{
							"id": 21006,
							"name": "Cranbrook"
						},
						{
							"id": 21007,
							"name": "Dawson Creek"
						},
						{
							"id": 21008,
							"name": "Delta"
						},
						{
							"id": 21009,
							"name": "Esquimalt"
						},
						{
							"id": 21010,
							"name": "Fort Saint James"
						},
						{
							"id": 21011,
							"name": "Fort Saint John"
						},
						{
							"id": 21012,
							"name": "Hope"
						},
						{
							"id": 27458,
							"name": "Kamloops"
						},
						{
							"id": 21013,
							"name": "Kelowna"
						},
						{
							"id": 21014,
							"name": "Kimberley"
						},
						{
							"id": 21015,
							"name": "Kitimat"
						},
						{
							"id": 21016,
							"name": "Langley"
						},
						{
							"id": 21017,
							"name": "Nanaimo"
						},
						{
							"id": 21018,
							"name": "Nelson"
						},
						{
							"id": 21019,
							"name": "New Westminster"
						},
						{
							"id": 21020,
							"name": "North Vancouver"
						},
						{
							"id": 21021,
							"name": "Oak Bay"
						},
						{
							"id": 21022,
							"name": "Penticton"
						},
						{
							"id": 21023,
							"name": "Powell River"
						},
						{
							"id": 21024,
							"name": "Prince George"
						},
						{
							"id": 21025,
							"name": "Prince Rupert"
						},
						{
							"id": 21026,
							"name": "Quesnel"
						},
						{
							"id": 21027,
							"name": "Revelstoke"
						},
						{
							"id": 21028,
							"name": "Rossland"
						},
						{
							"id": 21029,
							"name": "Trail"
						},
						{
							"id": 21030,
							"name": "Vancouver"
						},
						{
							"id": 21031,
							"name": "Vernon"
						},
						{
							"id": 21032,
							"name": "Victoria"
						},
						{
							"id": 21033,
							"name": "West Vancouver"
						},
						{
							"id": 21034,
							"name": "White Rock"
						},
					]
				},
				{
					"id": 28164,
					"name": "Manitoba",
					"state_code": "MB",
                    "cities": [
						{
							"id": 41001,
							"name": "Brandon"
						},
						{
							"id": 41002,
							"name": "Churchill"
						},
						{
							"id": 41003,
							"name": "Dauphin"
						},
						{
							"id": 41004,
							"name": "Flin Flon"
						},
						{
							"id": 41005,
							"name": "Kildonan"
						},
						{
							"id": 41006,
							"name": "Saint Boniface"
						},
						{
							"id": 41007,
							"name": "Swan River"
						},
						{
							"id": 41008,
							"name": "Thompson"
						},
						{
							"id": 41009,
							"name": "Winnipeg"
						},
						{
							"id": 41010,
							"name": "York Factory"
						},
					]
				},
				{
					"id": 28165,
					"name": "New Brunswick",
					"state_code": "NB",
                    "cities": [
						{
							"id": 51001,
							"name": "Bathurst"
						},
						{
							"id": 51002,
							"name": "Caraquet"
						},
						{
							"id": 51003,
							"name": "Dalhousie"
						},
						{
							"id": 51004,
							"name": "Fredericton"
						},
						{
							"id": 51005,
							"name": "Miramichi"
						},
						{
							"id": 51006,
							"name": "Moncton"
						},
						{
							"id": 51007,
							"name": "Saint John"
						},
						{
							"id": 51008,
							"name": "Thompson"
						},
						{
							"id": 51009,
							"name": "Winnipeg"
						},
					]
				},
				{	
					"id": 28166,
					"name": "Newfoundland and Labrador",
					"state_code": "NL",
                    "cities": [
						{
							"id": 61001,
							"name": "Bathurst"
						},
						{
							"id": 61002,
							"name": "Bonavista"
						},
						{
							"id": 61003,
							"name": "Channel-Port aux Basques"
						},
						{
							"id": 61004,
							"name": "Corner Brook"
						},
						{
							"id": 61005,
							"name": "Ferryland"
						},
						{
							"id": 61006,
							"name": "Gander"
						},
						{
							"id": 61007,
							"name": "Grand Falls–Windsor"
						},
						{
							"id": 61008,
							"name": "Happy Valley–Goose Bay"
						},
						{
							"id": 61009,
							"name": "Harbour Grace"
						},
						{
							"id": 61010,
							"name": "Labrador City"
						},
						{
							"id": 61011,
							"name": "Placentia"
						},
						{
							"id": 61012,
							"name": "Saint Anthony"
						},
						{
							"id": 68425,
							"name": "St. John’s"
						},
						{
							"id": 61013,
							"name": "Wabana"
						},
					]
				},
				{	
					"id": 28167,
					"name": "Northwest Territories",
					"state_code": "NT",
                    "cities": [
						{
							"id": 13101,
							"name": "Fort Smith"
						},
						{
							"id": 13002,
							"name": "Hay River"
						},
						{
							"id": 13003,
							"name": "Inuvik"
						},
						{
							"id": 13004,
							"name": "Tuktoyaktuk"
						},
						{
							"id": 13005,
							"name": "Yellowknife"
						},
					]
				},
				{
					"id": 28168,
					"name": "Nova Scotia",
					"state_code": "NS",
                    "cities": [
						{
							"id": 12101,
							"name": "Baddeck"
						},
						{
							"id": 12002,
							"name": "Digby"
						},
						{
							"id": 12003,
							"name": "Glace Bay"
						},
						{
							"id": 12004,
							"name": "Halifax"
						},
						{
							"id": 12005,
							"name": "Liverpool"
						},
						{
							"id": 12006,
							"name": "Louisbourg"
						},
						{
							"id": 12007,
							"name": "Lunenburg"
						},
						{
							"id": 12008,
							"name": "Pictou"
						},
						{
							"id": 12009,
							"name": "Port Hawkesbury"
						},
						{
							"id": 12010,
							"name": "Springhill"
						},
						{
							"id": 12011,
							"name": "Sydney"
						},
						{
							"id": 12012,
							"name": "Yarmouth"
						},
					]
				},
				{
					"id": 28169,
					"name": "Nunavut",
					"state_code": "NU",
                    "cities": [
						{
							"id": 11101,
							"name": "Iqaluit"
						},
					]
				},
				{
					"id": 28170,
					"name": "Ontario",
					"state_code": "ON",
                    "cities": [
						{
							"id": 31011,
							"name": "Bancroft"
						},
						{
							"id": 31012,
							"name": "Barrie"
						},
						{
							"id": 31013,
							"name": "Belleville"
						},
						{
							"id": 31014,
							"name": "Brantford"
						},
						{
							"id": 31015,
							"name": "Brockville"
						},
						{
							"id": 31016,
							"name": "Burlington"
						},
						{
							"id": 31017,
							"name": "Cambridge"
						},
						{
							"id": 31018,
							"name": "Chatham"
						},
						{
							"id": 31019,
							"name": "Chatham-Kent"
						},
						{
							"id": 31010,
							"name": "Cornwall"
						},
						{
							"id": 31011,
							"name": "Elliot Lake"
						},
						{
							"id": 31012,
							"name": "Etobicoke"
						},
						{
							"id": 38451,
							"name": "Fort Erie"
						},
						{
							"id": 31013,
							"name": "Fort Frances"
						},
						{
							"id": 31014,
							"name": "Gananoque"
						},
						{
							"id": 31015,
							"name": "Hamilton"
						},
						{
							"id": 31016,
							"name": "Iroquois Falls"
						},
						{
							"id": 31017,
							"name": "Kapuskasing"
						},
						{
							"id": 31018,
							"name": "Kawartha Lakes"
						},
						{
							"id": 31019,
							"name": "Kenora"
						},
						{
							"id": 31020,
							"name": "Kingston"
						},
						{
							"id": 31021,
							"name": "Kirkland Lake"
						},
						{
							"id": 31022,
							"name": "Kitchener"
						},
						{
							"id": 31023,
							"name": "Laurentian Hills"
						},
						{
							"id": 31024,
							"name": "London"
						},
						{
							"id": 31025,
							"name": "Midland"
						},
						{
							"id": 31026,
							"name": "Mississauga"
						},
						{
							"id": 31027,
							"name": "Moose Factory"
						},
						{
							"id": 31028,
							"name": "Moosonee"
						},
						{
							"id": 31029,
							"name": "Niagara Falls"
						},
						{
							"id": 31030,
							"name": "Niagara-on-the-Lake"
						},
						{
							"id": 31031,
							"name": "North Bay"
						},
						{
							"id": 31032,
							"name": "North York"
						},
						{
							"id": 31033,
							"name": "Oakville"
						},
						{
							"id": 31034,
							"name": "Orillia"
						},
						{
							"id": 31035,
							"name": "Oshawa"
						},
						{
							"id": 31036,
							"name": "Ottawa"
						},
						{
							"id": 31037,
							"name": "Parry Sound"
						},
						{
							"id": 31038,
							"name": "Perth"
						},
						{
							"id": 31039,
							"name": "Peterborough"
						},
						{
							"id": 31040,
							"name": "Picton"
						},
						{
							"id": 31041,
							"name": "Port Colborne"
						},
						{
							"id": 31042,
							"name": "Saint Catharines"
						},
						{
							"id": 31043,
							"name": "Saint Thomas"
						},
						{
							"id": 31044,
							"name": "Sarnia-Clearwater"
						},
						{
							"id": 31045,
							"name": "Sault Sainte Marie"
						},
						{
							"id": 31046,
							"name": "Scarborough"
						},
						{
							"id": 31047,
							"name": "Simcoe"
						},
						{
							"id": 31048,
							"name": "Stratford"
						},
						{
							"id": 31049,
							"name": "Sudbury"
						},
						{
							"id": 31050,
							"name": "Temiskaming Shores"
						},
						{
							"id": 31051,
							"name": "Thorold"
						},
						{
							"id": 31052,
							"name": "Thunder Bay"
						},
						{
							"id": 31053,
							"name": "Timmins"
						},
						{
							"id": 31054,
							"name": "Toronto"
						},
						{
							"id": 31055,
							"name": "Trenton"
						},
						{
							"id": 31056,
							"name": "Waterloo"
						},
						{
							"id": 31057,
							"name": "Welland"
						},
						{
							"id": 31058,
							"name": "West Nipissing"
						},
						{
							"id": 31059,
							"name": "Windsor"
						},
						{
							"id": 31060,
							"name": "Woodstock"
						},
						{
							"id": 31061,
							"name": "York"
						},
					]
				},
				{
					"id": 28171,
					"name": "Prince Edward Island",
					"state_code": "PE",
                    "cities": [
						{
							"id": 10001,
							"name": "Borden"
						},
						{
							"id": 10002,
							"name": "Cavendish"
						},
						{
							"id": 10003,
							"name": "Charlottetown"
						},
						{
							"id": 10004,
							"name": "Souris"
						},
						{
							"id": 10005,
							"name": "Summerside"
						},
					]
				},
				{
					"id": 28172,
					"name": "Quebec",
					"state_code": "QC",
                    "cities": [
						{
							"id": 91001,
							"name": "Asbestos"
						},
						{
							"id": 91002,
							"name": "Baie-Comeau"
						},
						{
							"id": 91003,
							"name": "Beloeil"
						},
						{
							"id": 91004,
							"name": "Cap-de-la-Madeleine"
						},
						{
							"id": 91005,
							"name": "Chambly"
						},
						{
							"id": 91006,
							"name": "Charlesbourg"
						},
						{
							"id": 91007,
							"name": "Châteauguay"
						},
						{
							"id": 91008,
							"name": "Chibougamau"
						},
						{
							"id": 91009,
							"name": "Côte-Saint-Luc"
						},
						{
							"id": 91010,
							"name": "Dorval"
						},
						{
							"id": 91011,
							"name": "Gaspé"
						},
						{
							"id": 91012,
							"name": "Gatineau"
						},
						{
							"id": 95464,
							"name": "Granby"
						},
						{
							"id": 91013,
							"name": "Hull"
						},
						{
							"id": 91014,
							"name": "Jonquière"
						},
						{
							"id": 91015,
							"name": "Kuujjuaq"
						},
						{
							"id": 91016,
							"name": "La Salle"
						},
						{
							"id": 91017,
							"name": "La Tuque"
						},
						{
							"id": 91018,
							"name": "Lachine"
						},
						{
							"id": 91019,
							"name": "Laval"
						},
						{
							"id": 91020,
							"name": "Lévis"
						},
						{
							"id": 91021,
							"name": "Longueuil"
						},
						{
							"id": 91022,
							"name": "Magog"
						},
						{
							"id": 91023,
							"name": "Matane"
						},
						{
							"id": 91024,
							"name": "Montreal"
						},
						{
							"id": 91025,
							"name": "Montréal-Nord"
						},
						{
							"id": 91026,
							"name": "Percé"
						},
						{
							"id": 91027,
							"name": "Port-Cartier"
						},
						{
							"id": 91028,
							"name": "Quebec"
						},
						{
							"id": 91029,
							"name": "Rimouski"
						},
						{
							"id": 91030,
							"name": "Rouyn-Noranda"
						},
						{
							"id": 91031,
							"name": "North Bay"
						},
						{
							"id": 91032,
							"name": "Saguenay"
						},
						{
							"id": 91033,
							"name": "Saint-Eustache"
						},
						{
							"id": 91034,
							"name": "Saint-Hubert"
						},
						{
							"id": 91035,
							"name": "Sainte-Anne-de-Beaupré"
						},
						{
							"id": 91036,
							"name": "Sainte-Foy"
						},
						{
							"id": 91037,
							"name": "Sainte-Thérèse"
						},
						{
							"id": 91038,
							"name": "Sept-Îles"
						},
						{
							"id": 91039,
							"name": "Sherbrooke"
						},
						{
							"id": 91040,
							"name": "Sorel-Tracy"
						},
						{
							"id": 91041,
							"name": "Trois-Rivières"
						},
						{
							"id": 91042,
							"name": "Val-d’Or"
						},
						{
							"id": 91043,
							"name": "Waskaganish"
						},
					]
				},
				{
					"id": 28173,
					"name": "Saskatchewan",
					"state_code": "SK",
                    "cities": [
						{
							"id": 81001,
							"name": "Batoche"
						},
						{
							"id": 81002,
							"name": "Cumberland House"
						},
						{
							"id": 81003,
							"name": "Estevan"
						},
						{
							"id": 81004,
							"name": "Flin Flon"
						},
						{
							"id": 81005,
							"name": "Prince Albert"
						},
						{
							"id": 81006,
							"name": "Regina"
						},
						{
							"id": 81007,
							"name": "Saskatoon"
						},
						{
							"id": 81008,
							"name": "Uranium City"
						},
					]
				},
				{
					"id": 28174,
					"name": "Yukon Territory",
					"state_code": "YT",
                    "cities": [
						{
							"id": 71001,
							"name": "Dawson"
						},
						{
							"id": 71002,
							"name": "Watson Lake"
						},
						{
							"id": 71003,
							"name": "Whitehorse"
						},
					]
				}
			]
		}
        // {
        //     "id": 208,
        //     "name": "Sri Lanka",
        //     "iso3": "LKA",
        //     "iso2": "LK",
        //     "phone_code": "94",
        //     "capital": "Colombo",
        //     "currency": "LKR",
        //     "states": [
        //         {
        //             "id": 2799,
        //             "name": "Ampara District",
        //             "state_code": "52",
        //             "cities": []
        //         },
        //         {
        //             "id": 2816,
        //             "name": "Anuradhapura District",
        //             "state_code": "71",
        //             "cities": []
        //         },
        //         {
        //             "id": 2790,
        //             "name": "Badulla District",
        //             "state_code": "81",
        //             "cities": []
        //         },
        //         {
        //             "id": 2818,
        //             "name": "Batticaloa District",
        //             "state_code": "51",
        //             "cities": []
        //         },
        //         {
        //             "id": 2798,
        //             "name": "Central Province",
        //             "state_code": "2",
        //             "cities": [
        //                 {
        //                     "id": 66463,
        //                     "name": "Dambulla",
        //                     "latitude": "7.86000000",
        //                     "longitude": "80.65167000"
        //                 },
        //                 {
        //                     "id": 66472,
        //                     "name": "Gampola",
        //                     "latitude": "7.16430000",
        //                     "longitude": "80.56960000"
        //                 },
        //                 {
        //                     "id": 66476,
        //                     "name": "Hatton",
        //                     "latitude": "6.89160000",
        //                     "longitude": "80.59550000"
        //                 },
        //                 {
        //                     "id": 66485,
        //                     "name": "Kadugannawa",
        //                     "latitude": "7.25470000",
        //                     "longitude": "80.52420000"
        //                 },
        //                 {
        //                     "id": 66489,
        //                     "name": "Kandy",
        //                     "latitude": "7.29060000",
        //                     "longitude": "80.63360000"
        //                 },
        //                 {
        //                     "id": 66490,
        //                     "name": "Kandy District",
        //                     "latitude": "7.30440000",
        //                     "longitude": "80.70730000"
        //                 },
        //                 {
        //                     "id": 66505,
        //                     "name": "Matale",
        //                     "latitude": "7.46980000",
        //                     "longitude": "80.62170000"
        //                 },
        //                 {
        //                     "id": 66506,
        //                     "name": "Matale District",
        //                     "latitude": "7.65980000",
        //                     "longitude": "80.70730000"
        //                 },
        //                 {
        //                     "id": 66517,
        //                     "name": "Nuwara Eliya",
        //                     "latitude": "6.97078000",
        //                     "longitude": "80.78286000"
        //                 },
        //                 {
        //                     "id": 66518,
        //                     "name": "Nuwara Eliya District",
        //                     "latitude": "6.97850000",
        //                     "longitude": "80.71330000"
        //                 },
        //                 {
        //                     "id": 66529,
        //                     "name": "Sigiriya",
        //                     "latitude": "7.94946000",
        //                     "longitude": "80.75037000"
        //                 },
        //                 {
        //                     "id": 66531,
        //                     "name": "Talawakele",
        //                     "latitude": "6.93710000",
        //                     "longitude": "80.65810000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2815,
        //             "name": "Colombo District",
        //             "state_code": "11",
        //             "cities": []
        //         },
        //         {
        //             "id": 2808,
        //             "name": "Eastern Province",
        //             "state_code": "5",
        //             "cities": [
        //                 {
        //                     "id": 66449,
        //                     "name": "Ampara",
        //                     "latitude": "7.29754000",
        //                     "longitude": "81.68202000"
        //                 },
        //                 {
        //                     "id": 66450,
        //                     "name": "Ampara District",
        //                     "latitude": "7.08330000",
        //                     "longitude": "81.75000000"
        //                 },
        //                 {
        //                     "id": 66456,
        //                     "name": "Batticaloa",
        //                     "latitude": "7.71020000",
        //                     "longitude": "81.69240000"
        //                 },
        //                 {
        //                     "id": 66457,
        //                     "name": "Batticaloa District",
        //                     "latitude": "7.75000000",
        //                     "longitude": "81.49970000"
        //                 },
        //                 {
        //                     "id": 66467,
        //                     "name": "Eravur Town",
        //                     "latitude": "7.77820000",
        //                     "longitude": "81.60380000"
        //                 },
        //                 {
        //                     "id": 66486,
        //                     "name": "Kalmunai",
        //                     "latitude": "7.40902000",
        //                     "longitude": "81.83472000"
        //                 },
        //                 {
        //                     "id": 66534,
        //                     "name": "Trincomalee",
        //                     "latitude": "8.57780000",
        //                     "longitude": "81.22890000"
        //                 },
        //                 {
        //                     "id": 66535,
        //                     "name": "Trincomalee District",
        //                     "latitude": "8.58333000",
        //                     "longitude": "81.08333000"
        //                 },
        //                 {
        //                     "id": 66537,
        //                     "name": "Vakarai",
        //                     "latitude": "8.13333000",
        //                     "longitude": "81.43333000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2792,
        //             "name": "Galle District",
        //             "state_code": "31",
        //             "cities": []
        //         },
        //         {
        //             "id": 2804,
        //             "name": "Gampaha District",
        //             "state_code": "12",
        //             "cities": []
        //         },
        //         {
        //             "id": 2791,
        //             "name": "Hambantota District",
        //             "state_code": "33",
        //             "cities": []
        //         },
        //         {
        //             "id": 2787,
        //             "name": "Jaffna District",
        //             "state_code": "41",
        //             "cities": []
        //         },
        //         {
        //             "id": 2789,
        //             "name": "Kalutara District",
        //             "state_code": "13",
        //             "cities": []
        //         },
        //         {
        //             "id": 2788,
        //             "name": "Kandy District",
        //             "state_code": "21",
        //             "cities": []
        //         },
        //         {
        //             "id": 2797,
        //             "name": "Kegalle District",
        //             "state_code": "92",
        //             "cities": []
        //         },
        //         {
        //             "id": 2793,
        //             "name": "Kilinochchi District",
        //             "state_code": "42",
        //             "cities": []
        //         },
        //         {
        //             "id": 2805,
        //             "name": "Mannar District",
        //             "state_code": "43",
        //             "cities": []
        //         },
        //         {
        //             "id": 2810,
        //             "name": "Matale District",
        //             "state_code": "22",
        //             "cities": []
        //         },
        //         {
        //             "id": 2806,
        //             "name": "Matara District",
        //             "state_code": "32",
        //             "cities": []
        //         },
        //         {
        //             "id": 2819,
        //             "name": "Monaragala District",
        //             "state_code": "82",
        //             "cities": []
        //         },
        //         {
        //             "id": 2814,
        //             "name": "Mullaitivu District",
        //             "state_code": "45",
        //             "cities": []
        //         },
        //         {
        //             "id": 2800,
        //             "name": "North Central Province",
        //             "state_code": "7",
        //             "cities": [
        //                 {
        //                     "id": 66451,
        //                     "name": "Anuradhapura",
        //                     "latitude": "8.31223000",
        //                     "longitude": "80.41306000"
        //                 },
        //                 {
        //                     "id": 66452,
        //                     "name": "Anuradhapura District",
        //                     "latitude": "8.33333000",
        //                     "longitude": "80.50000000"
        //                 },
        //                 {
        //                     "id": 66509,
        //                     "name": "Mihintale",
        //                     "latitude": "8.35930000",
        //                     "longitude": "80.51030000"
        //                 },
        //                 {
        //                     "id": 66523,
        //                     "name": "Polonnaruwa",
        //                     "latitude": "7.93965000",
        //                     "longitude": "81.00274000"
        //                 },
        //                 {
        //                     "id": 66524,
        //                     "name": "Polonnaruwa District",
        //                     "latitude": "8.00000000",
        //                     "longitude": "81.00000000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2817,
        //             "name": "North Western Province",
        //             "state_code": "6",
        //             "cities": [
        //                 {
        //                     "id": 66460,
        //                     "name": "Chilaw",
        //                     "latitude": "7.57583000",
        //                     "longitude": "79.79528000"
        //                 },
        //                 {
        //                     "id": 66501,
        //                     "name": "Kuliyapitiya",
        //                     "latitude": "7.46880000",
        //                     "longitude": "80.04010000"
        //                 },
        //                 {
        //                     "id": 66502,
        //                     "name": "Kurunegala",
        //                     "latitude": "7.48390000",
        //                     "longitude": "80.36830000"
        //                 },
        //                 {
        //                     "id": 66503,
        //                     "name": "Kurunegala District",
        //                     "latitude": "7.75000000",
        //                     "longitude": "80.25000000"
        //                 },
        //                 {
        //                     "id": 66525,
        //                     "name": "Puttalam",
        //                     "latitude": "8.03620000",
        //                     "longitude": "79.82830000"
        //                 },
        //                 {
        //                     "id": 66526,
        //                     "name": "Puttalam District",
        //                     "latitude": "8.04540000",
        //                     "longitude": "79.93190000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2813,
        //             "name": "Northern Province",
        //             "state_code": "4",
        //             "cities": [
        //                 {
        //                     "id": 66483,
        //                     "name": "Jaffna",
        //                     "latitude": "9.66845000",
        //                     "longitude": "80.00742000"
        //                 },
        //                 {
        //                     "id": 66484,
        //                     "name": "Jaffna District",
        //                     "latitude": "9.75000000",
        //                     "longitude": "80.08333000"
        //                 },
        //                 {
        //                     "id": 66496,
        //                     "name": "Kilinochchi",
        //                     "latitude": "9.39610000",
        //                     "longitude": "80.39820000"
        //                 },
        //                 {
        //                     "id": 66497,
        //                     "name": "Kilinochchi District",
        //                     "latitude": "9.39487000",
        //                     "longitude": "80.40894000"
        //                 },
        //                 {
        //                     "id": 66522,
        //                     "name": "Point Pedro",
        //                     "latitude": "9.81667000",
        //                     "longitude": "80.23333000"
        //                 },
        //                 {
        //                     "id": 66538,
        //                     "name": "Valvedditturai",
        //                     "latitude": "9.81667000",
        //                     "longitude": "80.16667000"
        //                 },
        //                 {
        //                     "id": 66539,
        //                     "name": "Vavuniya",
        //                     "latitude": "8.75140000",
        //                     "longitude": "80.49710000"
        //                 },
        //                 {
        //                     "id": 66540,
        //                     "name": "Vavuniya District",
        //                     "latitude": "8.86134000",
        //                     "longitude": "80.47576000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2794,
        //             "name": "Nuwara Eliya District",
        //             "state_code": "23",
        //             "cities": []
        //         },
        //         {
        //             "id": 2812,
        //             "name": "Polonnaruwa District",
        //             "state_code": "72",
        //             "cities": []
        //         },
        //         {
        //             "id": 2796,
        //             "name": "Puttalam District",
        //             "state_code": "62",
        //             "cities": []
        //         },
        //         {
        //             "id": 2807,
        //             "name": "Ratnapura district",
        //             "state_code": "91",
        //             "cities": []
        //         },
        //         {
        //             "id": 2803,
        //             "name": "Sabaragamuwa Province",
        //             "state_code": "9",
        //             "cities": [
        //                 {
        //                     "id": 66493,
        //                     "name": "Kegalle",
        //                     "latitude": "7.25230000",
        //                     "longitude": "80.34360000"
        //                 },
        //                 {
        //                     "id": 66494,
        //                     "name": "Kegalle District",
        //                     "latitude": "7.11670000",
        //                     "longitude": "80.33330000"
        //                 },
        //                 {
        //                     "id": 66527,
        //                     "name": "Ratnapura",
        //                     "latitude": "6.68580000",
        //                     "longitude": "80.40360000"
        //                 },
        //                 {
        //                     "id": 66528,
        //                     "name": "Ratnapura District",
        //                     "latitude": "6.58310000",
        //                     "longitude": "80.58330000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2801,
        //             "name": "Southern Province",
        //             "state_code": "3",
        //             "cities": [
        //                 {
        //                     "id": 66448,
        //                     "name": "Ambalangoda",
        //                     "latitude": "6.23550000",
        //                     "longitude": "80.05380000"
        //                 },
        //                 {
        //                     "id": 66458,
        //                     "name": "Bentota",
        //                     "latitude": "6.42598000",
        //                     "longitude": "79.99575000"
        //                 },
        //                 {
        //                     "id": 66465,
        //                     "name": "Devinuwara",
        //                     "latitude": "5.92825000",
        //                     "longitude": "80.58880000"
        //                 },
        //                 {
        //                     "id": 66468,
        //                     "name": "Galle",
        //                     "latitude": "6.04610000",
        //                     "longitude": "80.21030000"
        //                 },
        //                 {
        //                     "id": 66469,
        //                     "name": "Galle District",
        //                     "latitude": "6.25000000",
        //                     "longitude": "80.25000000"
        //                 },
        //                 {
        //                     "id": 66473,
        //                     "name": "Hambantota District",
        //                     "latitude": "6.25440000",
        //                     "longitude": "81.11110000"
        //                 },
        //                 {
        //                     "id": 66478,
        //                     "name": "Hikkaduwa",
        //                     "latitude": "6.14070000",
        //                     "longitude": "80.10120000"
        //                 },
        //                 {
        //                     "id": 66498,
        //                     "name": "Koggala",
        //                     "latitude": "5.98860000",
        //                     "longitude": "80.32860000"
        //                 },
        //                 {
        //                     "id": 66507,
        //                     "name": "Matara",
        //                     "latitude": "5.94851000",
        //                     "longitude": "80.53528000"
        //                 },
        //                 {
        //                     "id": 66508,
        //                     "name": "Matara District",
        //                     "latitude": "6.13290000",
        //                     "longitude": "80.52800000"
        //                 },
        //                 {
        //                     "id": 66511,
        //                     "name": "Mirissa city",
        //                     "latitude": "5.94655000",
        //                     "longitude": "80.45831000"
        //                 },
        //                 {
        //                     "id": 66532,
        //                     "name": "Talpe",
        //                     "latitude": "5.99990000",
        //                     "longitude": "80.27870000"
        //                 },
        //                 {
        //                     "id": 66533,
        //                     "name": "Tangalle",
        //                     "latitude": "6.02338000",
        //                     "longitude": "80.79738000"
        //                 },
        //                 {
        //                     "id": 66536,
        //                     "name": "Unawatuna",
        //                     "latitude": "6.02120000",
        //                     "longitude": "80.25030000"
        //                 },
        //                 {
        //                     "id": 66543,
        //                     "name": "Weligama",
        //                     "latitude": "5.97501000",
        //                     "longitude": "80.42968000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2795,
        //             "name": "Trincomalee District",
        //             "state_code": "53",
        //             "cities": []
        //         },
        //         {
        //             "id": 2811,
        //             "name": "Uva Province",
        //             "state_code": "8",
        //             "cities": [
        //                 {
        //                     "id": 66453,
        //                     "name": "Badulla",
        //                     "latitude": "6.98020000",
        //                     "longitude": "81.05770000"
        //                 },
        //                 {
        //                     "id": 66454,
        //                     "name": "Badulla District",
        //                     "latitude": "6.98472000",
        //                     "longitude": "81.05639000"
        //                 },
        //                 {
        //                     "id": 66466,
        //                     "name": "Ella Town",
        //                     "latitude": "6.87560000",
        //                     "longitude": "81.04630000"
        //                 },
        //                 {
        //                     "id": 66475,
        //                     "name": "Haputale",
        //                     "latitude": "6.76566000",
        //                     "longitude": "80.95104000"
        //                 },
        //                 {
        //                     "id": 66491,
        //                     "name": "Kataragama",
        //                     "latitude": "6.41340000",
        //                     "longitude": "81.33460000"
        //                 },
        //                 {
        //                     "id": 66512,
        //                     "name": "Monaragala",
        //                     "latitude": "6.87140000",
        //                     "longitude": "81.34870000"
        //                 },
        //                 {
        //                     "id": 66513,
        //                     "name": "Moneragala District",
        //                     "latitude": "6.66667000",
        //                     "longitude": "81.33333000"
        //                 },
        //                 {
        //                     "id": 66542,
        //                     "name": "Wattegama",
        //                     "latitude": "6.79890000",
        //                     "longitude": "81.48080000"
        //                 },
        //                 {
        //                     "id": 66545,
        //                     "name": "Wellawaya",
        //                     "latitude": "6.73694000",
        //                     "longitude": "81.10279000"
        //                 }
        //             ]
        //         },
        //         {
        //             "id": 2809,
        //             "name": "Vavuniya District",
        //             "state_code": "44",
        //             "cities": []
        //         },
        //         {
        //             "id": 2802,
        //             "name": "Western Province",
        //             "state_code": "1",
        //             "cities": [
        //                 {
        //                     "id": 66455,
        //                     "name": "Battaramulla South",
        //                     "latitude": "6.89640000",
        //                     "longitude": "79.91810000"
        //                 },
        //                 {
        //                     "id": 66459,
        //                     "name": "Beruwala",
        //                     "latitude": "6.47880000",
        //                     "longitude": "79.98280000"
        //                 },
        //                 {
        //                     "id": 66461,
        //                     "name": "Colombo",
        //                     "latitude": "6.93548000",
        //                     "longitude": "79.84868000"
        //                 },
        //                 {
        //                     "id": 66462,
        //                     "name": "Colombo District",
        //                     "latitude": "6.86640000",
        //                     "longitude": "80.01660000"
        //                 },
        //                 {
        //                     "id": 66464,
        //                     "name": "Dehiwala-Mount Lavinia",
        //                     "latitude": "6.84019000",
        //                     "longitude": "79.87116000"
        //                 },
        //                 {
        //                     "id": 66470,
        //                     "name": "Gampaha",
        //                     "latitude": "7.08970000",
        //                     "longitude": "79.99250000"
        //                 },
        //                 {
        //                     "id": 66471,
        //                     "name": "Gampaha District",
        //                     "latitude": "7.13330000",
        //                     "longitude": "80.00000000"
        //                 },
        //                 {
        //                     "id": 66474,
        //                     "name": "Hanwella Ihala",
        //                     "latitude": "6.90120000",
        //                     "longitude": "80.08520000"
        //                 },
        //                 {
        //                     "id": 66477,
        //                     "name": "Hendala",
        //                     "latitude": "6.99090000",
        //                     "longitude": "79.88300000"
        //                 },
        //                 {
        //                     "id": 66479,
        //                     "name": "Homagama",
        //                     "latitude": "6.84400000",
        //                     "longitude": "80.00240000"
        //                 },
        //                 {
        //                     "id": 66480,
        //                     "name": "Horana South",
        //                     "latitude": "6.71590000",
        //                     "longitude": "80.06260000"
        //                 },
        //                 {
        //                     "id": 66481,
        //                     "name": "Horawala Junction",
        //                     "latitude": "6.48088000",
        //                     "longitude": "80.12708000"
        //                 },
        //                 {
        //                     "id": 66482,
        //                     "name": "Ja Ela",
        //                     "latitude": "7.07440000",
        //                     "longitude": "79.89190000"
        //                 },
        //                 {
        //                     "id": 66487,
        //                     "name": "Kalutara",
        //                     "latitude": "6.58310000",
        //                     "longitude": "79.95930000"
        //                 },
        //                 {
        //                     "id": 66488,
        //                     "name": "Kandana",
        //                     "latitude": "7.04800000",
        //                     "longitude": "79.89370000"
        //                 },
        //                 {
        //                     "id": 66492,
        //                     "name": "Katunayaka",
        //                     "latitude": "7.16992000",
        //                     "longitude": "79.88837000"
        //                 },
        //                 {
        //                     "id": 66495,
        //                     "name": "Kelaniya",
        //                     "latitude": "6.95530000",
        //                     "longitude": "79.92200000"
        //                 },
        //                 {
        //                     "id": 66499,
        //                     "name": "Kolonnawa",
        //                     "latitude": "6.93290000",
        //                     "longitude": "79.88480000"
        //                 },
        //                 {
        //                     "id": 66500,
        //                     "name": "Kotikawatta",
        //                     "latitude": "6.92690000",
        //                     "longitude": "79.90950000"
        //                 },
        //                 {
        //                     "id": 66504,
        //                     "name": "Maharagama",
        //                     "latitude": "6.84800000",
        //                     "longitude": "79.92650000"
        //                 },
        //                 {
        //                     "id": 66510,
        //                     "name": "Minuwangoda",
        //                     "latitude": "7.16630000",
        //                     "longitude": "79.95330000"
        //                 },
        //                 {
        //                     "id": 66514,
        //                     "name": "Moratuwa",
        //                     "latitude": "6.77300000",
        //                     "longitude": "79.88160000"
        //                 },
        //                 {
        //                     "id": 66515,
        //                     "name": "Mulleriyawa",
        //                     "latitude": "6.93300000",
        //                     "longitude": "79.92970000"
        //                 },
        //                 {
        //                     "id": 66516,
        //                     "name": "Negombo",
        //                     "latitude": "7.20830000",
        //                     "longitude": "79.83580000"
        //                 },
        //                 {
        //                     "id": 66519,
        //                     "name": "Panadura",
        //                     "latitude": "6.71320000",
        //                     "longitude": "79.90260000"
        //                 },
        //                 {
        //                     "id": 66520,
        //                     "name": "Peliyagoda",
        //                     "latitude": "6.96850000",
        //                     "longitude": "79.88360000"
        //                 },
        //                 {
        //                     "id": 66521,
        //                     "name": "Pita Kotte",
        //                     "latitude": "6.89050000",
        //                     "longitude": "79.90150000"
        //                 },
        //                 {
        //                     "id": 66530,
        //                     "name": "Sri Jayewardenepura Kotte",
        //                     "latitude": "6.88297000",
        //                     "longitude": "79.90708000"
        //                 },
        //                 {
        //                     "id": 66541,
        //                     "name": "Wattala",
        //                     "latitude": "6.98918000",
        //                     "longitude": "79.89167000"
        //                 },
        //                 {
        //                     "id": 66544,
        //                     "name": "Welisara",
        //                     "latitude": "7.02810000",
        //                     "longitude": "79.90140000"
        //                 }
        //             ]
        //         }
        //     ]
        // }
    ]
    
    return json;
}

export default CountryJson;
