import React from "react";

import ApartmentDetail from "./ApartmentDetail";

const LeadUnit = (props) => {
  const { id, email, mobile, name, property, rentalEntity } = props.data;

  return (
    <tr className="rowTable">
      {/* <td style={{ width: "5%" }}>
        <b>{props.index + 1}</b>
      </td> */}
      <td className=" text-center " style={{ width: "10%" ,minWidth:"50px"}}>
        <div
          className="custom-control custom-checkbox"
          onClick={() => props.checkedOne("unitCheck" + id, id)}
        >
          <input
            type="checkbox"
            className="custom-control-input listCheck"
            id={"unitCheck" + id}
          />
          <label
            className="custom-control-label"
            htmlFor="customCheck1"
          ></label>
        </div>
      </td>
      <td style={{ width: "20%",minWidth:"300px"  }}>{email}</td>
      <td style={{ width: "20%",minWidth:"200px"  }}>{name}</td>
      <td style={{ width: "20%",minWidth:"200px"  }}>{mobile}</td>
      <td style={{ width: "15%",minWidth:"200px"  }}>
        {" "}
        <span className="badge badge-dark ">{property} Appartment</span>
      </td>
      <td style={{ width: "15%", minWidth:"100px"  }}>
        <ApartmentDetail
          key={"aptDetail" + props.index}
          unit={rentalEntity}
          property={property}
          unitDetail={props.unitDetail}
        />{" "}
      </td>
      
    </tr>
  );
};

export default LeadUnit;
