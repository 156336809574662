import React from "react";
import "./ProfileCard.css";
import proImage from "../../../../Images/profile.png";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";

const ProfileCard = (props) => {
  const {
    tenantName,
    payerName,
    tenantEmail,
    phoneNo,
    imagePath,
    ContractStartDate,
    ContractEndDate,
  } = props.data;

  return (
    <div className="dropdown dropLeft">
      <i
        className="fa fa-ellipsis-v"
        type="button"
        id="dropdownMenu2"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      ></i>
      <div
        className={
          tenantName
            ? "dropdown-menu detailsCard p-3"
            : "dropdown-menu detailsCard1 p-3"
        }
        aria-labelledby="dropdownMenu2"
      >
        <img
          src={(imagePath, proImage)}
          alt="Person"
          className="card__image mx-auto d-block "
        />
        <h3 className="text-center text-dark">
          {tenantName ? tenantName : payerName}
        </h3>
        <div className="row">
          <div className="col">
            <span className="detailsText text-dark">
              {" "}
              <i
                className="fa fa-envelope text-dark"
                aria-hidden="true"
              ></i>{" "}
              {tenantEmail}{" "}
            </span>
          </div>
          <div className="col">
            <span className="detailsText text-dark">
              <i
                className="fa fa-phone-square text-dark"
                aria-hidden="true"
              ></i>{" "}
              {phoneNo}
            </span>
          </div>
        </div>
        {props.data.application_Fee ? (
          <>
            <br></br>
            <h6>Contract :</h6>
            <i className="fa fa-calendar text-info" aria-hidden="true"></i>
            &nbsp;{ContractStartDate}{" "}
            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>{" "}
            <i className="fa fa-calendar text-primary" aria-hidden="true"></i>
            &nbsp;{ContractEndDate}
            <br></br>
            <br></br>
            <h6>Payment Deposits:</h6>
            <div className="row">
              <div className="col-3">
                <span className="detailsText text-dark">
                  <i className="fa fa-plus text-dark" aria-hidden="true"></i>
                  &nbsp;&nbsp;application
                </span>
              </div>
              <div className="col">
                :&nbsp;&nbsp;<CurrencyFormat value={ props.data.application_Fee} />
              </div>
              <div className="col-3">
                <span className="detailsText text-dark">
                  {" "}
                  <i className="fa fa-shield text-dark" aria-hidden="true"></i>
                  &nbsp;&nbsp;security
                </span>
              </div>
              <div className="col">
                :&nbsp;&nbsp;<CurrencyFormat value={ props.data.security_dep} />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <span className="detailsText text-dark">
                  {" "}
                  <i
                    className="fa fa-envelope-o text-dark"
                    aria-hidden="true"
                  ></i>
                  &nbsp;&nbsp;key
                </span>
              </div>
              <div className="col">
                :&nbsp;&nbsp;<CurrencyFormat value={ props.data.Key_Dep} />
              </div>
              <div className="col-3">
                <span className="detailsText text-dark">
                  {" "}
                  <i className="fa fa-paw text-dark" aria-hidden="true"></i>
                  &nbsp;&nbsp;pet
                </span>
              </div>
              <div className="col">
                :&nbsp;&nbsp;
                <CurrencyFormat value={ props.data.pet_dep} />
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <span className="detailsText text-dark">
                  {" "}
                  <i className="fa fa-car text-dark" aria-hidden="true"></i>
                  &nbsp;&nbsp;parking
                </span>
              </div>
              <div className="col">
                :&nbsp;
                {props.data.parking_reservation === "0" ? (
                  <i
                    className="fa fa-times-circle text-danger"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    className="fa fa-check-circle text-success"
                    aria-hidden="true"
                  ></i>
                )}
              </div>
            </div>
            <div className="row"></div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;
