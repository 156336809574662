// Dark Mode Reducer 
const DarkReducer = (state = false, action) => {
  switch (action.type) {
    case "DARK":
      if (state) {
        document.body.style.background = "#f6f5f7";
      } else {
        document.body.style.background = "#424241";
      }
      return !state;
    default:
      return state;
  }
};
export default DarkReducer;
