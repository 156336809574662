import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { deleteAlert } from "../../actions";

import "./alert.css";

function Alert(props) {
  const [count, setCount] = useState(100);
  const dispatch = useDispatch();
  useEffect(() => {
    // set loading bar length
    const interval = setInterval(() => {
      setCount((c) => c - 1.5);
      if (count < 0) {
        dispatch(deleteAlert(props.index));
      }
    }, 100);
    return () => clearInterval(interval);
  }, [count, props.index, dispatch]);
  const close = (index) => {
    dispatch(deleteAlert(index));
  };
  return (
    <>
      <div
        key={props.index}
        className={
          props.messageType === "success"
            ? "NotificationWindow show pb-0 successAlert"
            : props.messageType === "warning"
            ? "NotificationWindow show pb-0 warningAlert"
            : props.messageType === "info"
            ? "NotificationWindow show pb-0 infoAlert"
            : props.messageType === "danger"
            ? "NotificationWindow show pb-0 dangerAlert"
            : 
            "NotificationWindow show pb-0 lightAlert"
        }
        style={{ zIndex: "1900" }}
      >
        <div className="row mt-0">
          <div className="col text-right mt-0 mr-3">
            <i
              className="fa fa-times  pointer notificationClose"
              aria-hidden="true"
              
              onClick={() => close(props.index)}
            ></i>
          </div>
        </div>
        <div className="row">
          <div className="absolute ml-3 mt-1">
            <i
              className={
                props.messageType === "success"
                  ? "fa fa-check-circle-o fa-2x "
                  : props.messageType === "warning"
                  ? "fa fa-exclamation-triangle  fa-2x"
                  : props.messageType === "info"
                  ? "fa fa-info-circle  fa-2x"
                  : props.messageType === "danger"
                  ? "fa fa-times-circle  fa-2x"
                  : "fa fa-exclamation-circle  fa-2x"
              }
            ></i>
          </div>
          <div className="col text-left p-2">
            <span className="notificationText pr-5 pl-5 text-left"> {props.content}</span>
          </div>
        </div>
        <div className="row p-0 m-0 ">
          <div className="col p-0 m-0 pt-2 mt-2">
            <div
              className="progress"
              style={{
                height: "2px",
                background: "rgba(224, 220, 220, 0.95)",
              }}
            >
              <div
                className="progress-bar bar"
                role="progressbar"
                id="progressBar"
                style={{
                  width: count.toString() + "%",
                }}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Alert;
