import React, { useEffect, useState } from "react";
import "./style.css";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { forgotPasswordCode } from "../../Services/ApiServices";
import Loader from "../Loader/loader3";

const ForgotPassword = (props) => {
  const dark = useSelector((state) => state.isDark);
  const [alertEmail, setAlertEmail] = useState(false);
  const [send, setSend] = useState(false);
  const [state, setState] = useState({
    email: props.match.params.Id === "user" ? "" : props.match.params.Id,
  });
  useEffect(() => {}, [props.match.params.Id]);
  function onchange(e) {
    const {
      target: { name, value },
    } = e;
    setState({ ...state, [name]: value });
  }
  const onKeyUp = (event) => {
    if (event.key === "Enter") {
      sendLink();
    }
  };
  function checkEmailNext(value) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
    return !(!re.test(String(value).toLowerCase()) || value === "");
  }
  function sendLink() {
    if (checkEmailNext(state.email)) {
      setSend(true);
      try {
        forgotPasswordCode(state.email).then((response) => {
          if (response.status === 200) {
            setSend(false);
            props.pushAlert("Reset link has been sent to your mail", "success");
            props.history.push({
              pathname: "/login",state: {
                sign:true
              }
            });
          } else {
            setSend(false);
            props.pushAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setAlertEmail(true);
    }
  }
  return (
    <div
      className={
        dark
          ? "container-fluid forgotAlignDark mt-5"
          : "container-fluid forgotAlign "
      }
    >
      <div className="row">
        <div className=" col d-flex justify-content-center text-center  ">
          <div className="forgotCard">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="100px"
              viewBox="0 0 820.000000 764.000000"
              preserveAspectRatio="xMidYMid meet"
              className="pb-3"
            >
              <g
                transform="translate(0.000000,764.000000) scale(0.100000,-0.100000)"
                fill="rgb(255, 102, 102)"
                stroke="none"
              >
                <path
                  d="M3810 7139 c-614 -51 -1210 -276 -1693 -637 -1014 -760 -1498 -1957
                  -1306 -3229 83 -552 329 -1106 688 -1553 109 -135 376 -402 511 -511 389 -312
                  856 -538 1345 -650 252 -57 417 -73 745 -74 326 0 461 12 713 65 494 105 983
                  336 1382 655 134 106 411 384 520 520 475 595 725 1315 725 2085 -1 893 -319
                  1690 -927 2321 -574 595 -1303 940 -2129 1009 -137 11 -435 11 -574 -1z m645
                  -318 c631 -95 1194 -353 1677 -768 275 -236 555 -632 731 -1032 139 -315 221
                  -619 262 -976 19 -165 19 -296 0 -460 -74 -635 -293 -1172 -681 -1675 -491
                  -635 -1414 -1092 -2274 -1127 -327 -13 -778 75 -1165 229 -417 166 -859 463
                  -1113 748 -395 444 -676 1025 -776 1605 -47 273 -56 466 -31 680 83 707 365
                  1340 826 1848 169 185 461 406 744 561 387 211 776 330 1285 390 85 10 382 -4
                  515 -23z"
                />
                <path
                  d="M3972 5668 c-97 -13 -238 -62 -332 -115 -64 -36 -111 -74 -190 -153
                  -121 -121 -182 -218 -229 -365 -40 -122 -51 -240 -51 -528 l0 -244 -41 -18
                  c-57 -26 -153 -119 -194 -189 -65 -110 -66 -131 -63 -791 l3 -590 24 -60 c31
                  -79 83 -157 135 -201 52 -46 165 -101 235 -114 73 -14 1609 -14 1682 0 70 13
                  183 68 235 114 52 44 104 122 135 201 l24 60 3 590 c3 509 1 600 -13 661 -28
                  127 -123 254 -234 312 -119 64 -97 62 -858 62 l-693 0 0 268 c0 294 5 326 61
                  441 20 41 57 89 99 132 221 221 573 223 794 6 104 -102 152 -207 166 -360 11
                  -110 39 -165 103 -196 115 -56 240 4 268 127 12 52 -8 210 -38 306 -51 163
                  -124 278 -255 402 -205 193 -493 283 -776 242z m863 -1755 c47 -25 105 -84
                  131 -133 18 -32 19 -68 22 -464 3 -480 1 -498 -63 -573 -19 -23 -61 -54 -93
                  -70 l-57 -28 -660 0 c-623 0 -663 1 -709 19 -65 25 -124 79 -152 141 -24 50
                  -24 51 -24 481 0 473 1 479 60 554 18 23 56 52 92 70 l62 31 676 -3 c671 -3
                  675 -3 715 -25z"
                />
              </g>
            </svg>

            <h1 className="forgotHead">Trouble Logging in?</h1>
            <p className="mr-3 ml-3">
              Enter your email and we'll send you a link to get back into your
              account.
            </p>
            <input
              className={
                alertEmail && !checkEmailNext(state.email)
                  ? "ApplicationInput alert"
                  : "ApplicationInput"
              }
              type="email"
              name="email"
              placeholder="Email"
              value={state.email === "user" ? "" : state.email}
              onChange={onchange}
              onKeyPress={onKeyUp}
            />
            {alertEmail &&
              (state.email === "" ? (
                <p className="spanValidateApp textPri ">
                  Please enter the email address
                </p>
              ) : (
                !checkEmailNext(state.email) && (
                  <p className="spanValidateApp textPri ">
                    Please enter the email address correct format
                  </p>
                )
              ))}
            <div className="row mt-5">
              <div className="col-md-12">
                <button
                  onClick={() => sendLink()}
                  name="sendLink"
                  id="sendLink"
                  disabled={send && "disabled"}
                >
                  {!send ? (
                    "Reset Password"
                  ) : (
                    <>
                      Sending
                      <div className="text-center resetLoader">
                        <Loader />
                      </div>
                    </>
                  )}
                </button>
              </div>

              <div className="col-md ">
                {" "}
                <p
                  className="mt-3 pointer resetCreateNew "
                  onClick={() => {
                    props.history.push("/signup");
                  }}
                >
                  Create New Account
                </p>
              </div>
            </div>
            <p className="pt-0 mt-0 pb-0 mb-0">
              Return to
              <label
                className="pl-2 pointer textPri"
                onClick={() => {
                  props.history.push("/login");
                }}
              >
                <b>Login</b>
              </label>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);
