import React, { Component } from "react";
import {
  getCustomerPayment,
  getTenantPayments,
} from "../../../../Services/ApiServices";
import Loader from "../../../Loader/Loader";
import "../payment.css";
import Card from "../Card";
import { withRouter } from "react-router-dom";
import TenantRecentPaymentList from "./TenantRecentPaymentList";
import { connect } from "react-redux";
import {
  appendAlert,
  setRecentPayment,
  setCustomerPaymentDetail,
} from "../../../../actions";
import Loader2 from "../../../Loader/Loader2";
import { addressFormatter } from "../../../../Defined/Function";

class PaymentTenant extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractId: 0,
      paymentsList: [],
    };
    this.getCustomer = this.getCustomer.bind(this);
    this.getUnit = this.getUnit.bind(this);
    this.onchange = this.onchange.bind(this);
  }
  async getCustomer() {
    try {
      await getCustomerPayment().then((response) => {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            this.props.setCustomerPaymentDetail(response.data);
          } else {
            this.props.setCustomerPaymentDetail([]);
          }
        }
      });

      await getTenantPayments(this.props.userDetail.username).then(
        async (response) => {
          if (response.status === 200) {
            if (response.data.length !== 0) {
              await this.props.setRecentPayment(null);
              this.props.setRecentPayment(response.data.sort((a, b) =>b.paidDate.localeCompare(a.paidDate)));
            } else {
              this.props.setRecentPayment([]);
            }
          } else {
            this.props.appendAlert(
              "Something went wrong!, Please try again later.",
              "danger"
            );
          }
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  async componentDidMount() {
    try {
      await this.getCustomer();
    } catch (error) {
      console.error(error);
    }
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  getUnit(contract, index) {
    var unit = this.props.tempListingArray.filter(
      (unit) => unit.rentalEntity.id === contract.rentalEntityId
    );
    if (index === 0) {
      // this.setState({contractId:contract.id});
    }
    if (unit !== null) {
      if (unit.length > 0) {
        return (
          <option value={contract.contractId} key={"paymentTenant"+index}>
            {addressFormatter(unit[0].address)}
          </option>
        );
      }
    }
  }

  render() {
    return this.props.customerPaymentDetailArray === null ? (
      <Loader />
    ) : (
      <>
        {" "}
        <div
          className="container-fluid"
          style={{ minHeight: window.screen.height }}
        >
          <div className="row pb-3" style={{ paddingTop: "110px" }}>
            <div className="col-12 pl-3 pb-2">
              <h1
                className={this.props.isDark ? "axText text-white" : "axText"}
              >
                Payment Methods
              </h1>
            </div>
          </div>
          {this.props.customerPaymentDetailArray.sources.data.length === 0 && (
            <>
              <div className="row pr-3 pl-3" style={{ marginTop: "-50px" }}>
                <div className="col">
                  <hr></hr>
                </div>
              </div>

              <div className="text-center mb-5 mt-3">
                <span className="span2">Please Add your card</span>
              </div>
            </>
          )}
          <div className="row">
            {this.props.customerPaymentDetailArray.sources.data.map((data,index) => (
              <div className="col mb-3 cardContainerAlign  ">
                <Card
                  data={data}
                  id={this.props.customerPaymentDetailArray.defaultSource}
                  getCustomer={this.getCustomer}
                  user={this.props.userDetail}
                  pushAlert={this.props.appendAlert}
                  history={this.props.history}
                  key={"customerPaymentDetailArray"+index}
                />
              </div>
            ))}
          </div>
          <div className="row text-right">
            <div
              className="col text-right"
              style={{ textAlign: "right", margin: "10px" }}
            >
              <button
                className="buttonPri"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/payments/add",
                    state: {
                      data: this.props.userDetail,
                      status: "add",
                      type: "addNew",
                      rentalEntityId:null
                    },
                  })
                }
              >
                Add Payment Method
              </button>
            </div>
          </div>
          <div className="row ml-1 mr-1 mb-3 mt-2">
            <div className="">
              <h1
                className={this.props.isDark ? "axText text-white" : "axText"}
              >
                Recent Payments
              </h1>
            </div>
            <div className="col ">
              <hr className="new"></hr>
            </div>
          </div>
          {this.props.contractArray === null ||
          this.props.recentPaymentArray === null ||
          this.props.tempListingArray === null ? (
            <div className="col-12 mb-5">
              <Loader2 />
            </div>
          ) : (
            this.props.contractArray.length !== 0 && (
              <>
                <div className="row mr-1 ml-1 ">
                  <div className="col-md-2 mr-2 squareDivPri maintenanceRequestHeaderAlign1 text-center">
                    Contract
                  </div>
                  <div className="col-12 maintenanceRequestHeaderAlign2 mb-2 p-0">
                    <span className="axText0">Contracts</span>
                  </div>
                  <div className="col-md pr-0 p-0">
                    <select
                      className={"maintenanceInputSelect custom-select"}
                      onChange={this.onchange}
                      name={"contractId"}
                      id={"contractId"}
                    >
                      <option value={0} selected="selected">
                        All payments
                      </option>
                      {this.props.contractArray.map((contract, index) =>
                        this.getUnit(contract, index)
                      )}
                    </select>
                  </div>
                  {/* <div className="col-md-2 squareDivPri2 textUpperCase">
                      24 X 7 Support
                    </div> */}
                </div>
                <div
                  className="row mt-4 mr-1 ml-1 mb-5  recentPayment"
                  style={{}}
                >
                  <div className="col-12 ">
                    <div className="recentPaymentTableWeb">
                      <div
                        className={"row"}
                        style={{
                          marginTop: "10px",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          borderTopLeftRadius: "25px",
                          borderTopRightRadius: "25px",
                        }}
                      >
                        <div className="col-1 "></div>
                        <div className="col m-0 p-0 text-left ">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Name
                          </p>
                        </div>
                        <div className="col-3 m-0 p-0  text-center">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Type
                          </p>
                        </div>

                        <div className="col m-0 p-0 text-center">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Amount
                          </p>
                        </div>
                        <div className="col m-0 p-0 text-center">
                          <p
                            className={"maintenanceHeadText"}
                            style={{ fontWeight: "bold" }}
                          >
                            Receipt
                          </p>
                        </div>
                        <div className="col-1 m-0 p-0 text-center">
                          <p
                            className={"maintenanceHeadText "}
                            style={{ fontWeight: "bold" }}
                          >
                            Status
                          </p>
                        </div>
                      </div>
                    </div>
                    {this.props.recentPaymentArray.length !== 0 ? (
                      this.props.recentPaymentArray
                        .filter(
                          (data) =>
                            parseInt(this.state.contractId) === 0 ||
                            parseInt(this.state.contractId) ===
                              parseInt(data.contractId)
                        )
                        .map((data,index) => {
                          return <TenantRecentPaymentList data={data} key={"TenantRecentPaymentList"+index}/>;
                        })
                    ) : (
                      <div className="text-center mb-4 pb-4 mt-4 ml-5">
                        <span className="spanBig ">
                          No transactions in recent payment history.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDark: state.isDark,
    dark: state.darkReducer,
    user: state.userReducer,
    userDetail: state.user,
    alert: state.alertReducer,
    recentPayment: state.recentPaymentReducer,
    recentPaymentArray: state.recentPayment,
    contract: state.contractReducer,
    contractArray: state.contract,
    customerPaymentDetail: state.customerPaymentDetailReducer,
    customerPaymentDetailArray: state.customerPaymentDetail,
    tempListing: state.tempListingReducer,
    tempListingArray: state.tempListing,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    setRecentPayment,
    setCustomerPaymentDetail,
  })(PaymentTenant)
);
