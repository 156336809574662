import React from "react";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import ProfileCard from "../profile/ProfileCard";

const MaintenanceRow = (props) => {
  const {
    id,
    unitName,
    payerName,
    maintenanceType,
    tenantEmail,
    phoneNo,
    amount,
    date,
    payerType,
    imagePath,
  } = props.data;
  const profileData = { payerName, tenantEmail, phoneNo, imagePath };
  return (
    <tr className="rowTable">
      <td style={{ width: "5%" }}>
        <b>{id}</b>
      </td>
      <td style={{ width: "20%" }}>{unitName}</td>
      <td style={{ width: "20%" }}>{maintenanceType}</td>
      <td style={{ width: "40%",minWidth:"420px"  }}>
        <div className="row">
          <div className="col text-right">
        <CurrencyFormat value={ amount} />
            
          </div>
          <div className="col text-center">
            <i className="fa fa-calendar text-dark " aria-hidden="true"></i>
            &nbsp;{date}
          </div>
          <div className="col text-left">
            By &nbsp;
            {payerType === "LandLoad" ? (
              <span className="badge badge-success badge-pill landLoad">
                {payerType}
              </span>
            ) : (
              <span className="badge badge-success badge-pill tenant">
                {payerType}
              </span>
            )}
          </div>
        </div>
      </td>
      <td style={{ width: "10%" }}>
        <i className="fa fa-eye text-dark" aria-hidden="true"></i>{" "}
      </td>
      <td style={{ width: "10%" }}>
        <ProfileCard data={profileData} />
      </td>
    </tr>
  );
};

export default MaintenanceRow;
