import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Home.css";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.onchange = this.onchange.bind(this);
  }
  async onchange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  render() {
    return (
      <>
        <div className=" mt-4 footer0" style={{ borderTop: "1px solid #f2f2f2c7" }}>
          <div className="row footer pt-4 ">
            <div className="col">
              <div className="row pb-4 mb-3">
                <div className="col-12 text-center">
                  <h1 className="one textPri2">Axallant</h1>
                </div>
              </div>
              <div className="row ">
                <div className="col footerContent text-center">
                  <h1 className="one pt-3">Our Software</h1>
                  <div className="pl-3 pt-1 ">
                    <p className="spanFooter">Communication & Service</p>
                    <p className="spanFooter">Accounting & Reporting</p>
                    <p className="spanFooter">Maintenance & Efficiency</p>
                    <p className="spanFooter">Staffing & Training</p>
                    <p className="spanFooter">Marketing & Leasing</p>
                  </div>
                </div>
                <div className="col footerContent text-center">
                  <h1 className="one pt-3">Who We Serve</h1>
                  <div className="pl-3 pt-1">
                    <p className="spanFooter">Residential</p>
                    <p className="spanFooter">Community Associations</p>
                    <p className="spanFooter">Commercial</p>
                    <p className="spanFooter">Axallant Investment Management</p>
                  </div>
                </div>
                <div className="col footerContent text-center">
                  <h1 className="one pt-3">Industry Insights</h1>
                  <div className="pl-3 pt-1">
                    <p className="spanFooter">Articles</p>
                    <p className="spanFooter">The Top Floor Podcast</p>
                    <p className="spanFooter">Events Calendar</p>
                  </div>
                </div>
                <div className="col footerContent text-center">
                  <h1 className="one pt-3">Why Axallant</h1>
                  <div className="pl-3 pt-1">
                    <p className="spanFooter">About Us</p>
                    <p className="spanFooter">Customer Stories</p>
                    <p className="spanFooter">Integrations</p>
                  </div>
                </div>

                <div className="col footerContent text-center">
                  <h1 className="one pt-3">Contact Us</h1>
                  <div className="pl-3 pt-3">
                    <p className="spanFooter"></p>
                    <p className="spanFooter">info@axallant.com</p>
                  </div>
                </div>
                <div className="col footerContent text-center">
                  <h1 className="one pt-3">Pricing</h1>
                  <div className="pl-3 pt-1">
                    <p className="spanFooter">All Pricing Plans</p>
                    <p className="spanFooter">Axallant FAQ</p>
                  </div>
                </div>
                <div className="col footerContent text-center">
                  <h1 className="one pt-3">Sign up for the latest</h1>
                  <div className="pl-3 pt-1">
                    <p className="spanFooter">
                      Have a pulse on industry news and updates for your
                      business.
                    </p>
                    <p className="spanFooter">Axallant FAQ</p>
                  </div>
                </div>
                <div className="col pt-3 footerContent text-center pr-5 pl-5">
                  <input
                    className="ApplicationInput "
                    placeholder="Email Address"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.onchange}
                  ></input>
                  <button
                    className="buttonPri pull-right mt-2"
                    onClick={() =>
                      this.props.history.push({
                        pathname: "/contact-us",
                        state: { email: this.state.email },
                      })
                    }
                  >
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
              <div className="row companyName mt-2 ">
                <div className="col pr-5 pl-5 pt-2">
                  <span className="span2 pull-left">
                    {" "}
                    <i
                      className="fa fa-copyright text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                    2022 Axallant, Corporation
                  </span>
                </div>
              </div>
              <div className="row mb-5 companyName-mobile mt-2">
                <div className="col pr-5 pl-5 pt-2 text-center">
                  <span className="span2 ">
                    {" "}
                    <i
                      className="fa fa-copyright text-dark"
                      aria-hidden="true"
                    ></i>{" "}
                    2022 Axallant, Corporation
                  </span>
                </div>
              </div>
              <div className="row footerLogo pt-5">
                <div className="col text-center ">
                  <div className="social-container ">
                    <a
                      href="http://facebook.com/"
                      className="social"
                      style={{ textDecoration: "none" }}
                    >
                      <i
                        className="fa fa-facebook text-dark"
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                    <a
                      href="http://google.lk/"
                      className="social"
                      style={{ textDecoration: "none" }}
                    >
                      <i
                        className="fa fa-google text-dark"
                        aria-hidden="true"
                      ></i>{" "}
                    </a>
                    <a
                      href="http://linkedin.com/"
                      className="social"
                      style={{ textDecoration: "none" }}
                    >
                      <i
                        className="fa fa-linkedin text-dark"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Footer);
