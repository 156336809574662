import React, { Component } from "react";
import {
  exceptNameNull,
  exceptNull,
  sortText,
  timeCalculativeFormat,
} from "../../../../Defined/Function";
import image1 from "../../../../Images/profile.png";
import copy from "../../../../Images/copy.png";
import "./MaintenanceRequestInvitations.css";
import SmallToggle from "../../../UIServices/SmallToggle/SmallToggle";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { appendAlert } from "../../../../actions";

class MaintenanceRequestInvitationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.onchangeCheck = this.onchangeCheck.bind(this);
  }

  async onchangeCheck(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: checked });
  }
  async copy(value) {
    try {
      await navigator.clipboard.writeText(value);
    } catch (e) {
      console.log(e);
    }

    this.props.appendAlert(" Copied ", "success");
  }
  render() {
    return (
      <div className={"row maintenanceProviderContainer "}>
        <div className="maintenanceProviderInvitationImageAlign ">
          <img
            alt="tenant"
            src={image1}
            className="maintenanceRequestInvitationImage"
          />
        </div>
        <div className=" maintenanceProviderInvitationNameAlign">
          <h1 className="maintenanceRequestText2 textLeft">
            {sortText(
              exceptNameNull(
                this.props.data.maintenanceProvider.firstName,
                this.props.data.maintenanceProvider.lastName
              ),
              0,
              17
            )}
          </h1>
          <h3 className="maintenanceRequestText1 textLeft">
            {sortText(
              exceptNull(this.props.data.maintenanceProvider.skills),
              0,
              23
            )}
          </h3>
        </div>
        <div className=" pt-2 maintenanceInvitationHourAlign">
          <h3 className="maintenanceRequestDetailText6 ">
            {timeCalculativeFormat(this.props.data.createdAt)}
          </h3>
        </div>
        <div className=" maintenanceInvitationAlign">
          <SmallToggle
            name={"active"}
            onChange={this.onchangeCheck}
            value={this.state.active}
          />
        </div>
        <div
          className=" maintenanceInvitationProviderIconAlign pointer"
          onClick={() => this.copy(this.props.data.invitationLink)}
        >
          <img
            alt="tenant"
            src={copy}
            className="maintenanceRequestDetailIcon"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    provider: state.providerReducer,
    providerArray: state.provider,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
  })(MaintenanceRequestInvitationList)
);
