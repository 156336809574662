// Temporary Image Reducer
const tempProfileImagesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_TEMP_PROFILE_IMAGES_DATA":
      return state;
    case "APPEND_TEMP_PROFILE_IMAGES_DATA":
     
      if (action==={} ||action===null||action===undefined||action==="") {
        return state;
      }else if (state.length > 0) {
        let images=[action.image, ...state];
        images = images.filter((obj, pos, arr) => {
          return arr
            .map(mapObj => mapObj.username)
            .indexOf(obj.username) === pos;
        });
        return  images;
      } else {
        return [action.image, ...state];
      }
    case "REMOVE_TEMP_PROFILE_IMAGES_DATA_BY_ID":
      return state.filter((todo) => todo.username !== action.username);
    case "REMOVE_TEMP_PROFILE_IMAGES_DATA":
      return null;
    default:
      return state;
  }
};
export default tempProfileImagesReducer;
