import { url } from "../Services/ApiServices";
import image from "../../src/Images/profile.png";

//return today date
export const today = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  return today;
};
//return mobile number (000) 000 - 0000 format
export const mobileNoFormat = (phoneNumberString) => {
  phoneNumberString = String(phoneNumberString);
  let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "-";
};
//return date and time format Jan 21 2022 02:23 am
export const dateTimeFormat = (d) => {
  if (d === null || d === "" || d === undefined || d === "-") {
    return "-";
  } else {
    let months = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let date1 = new Date(d);
    let date = date1.getDate();
    let year = date1.getFullYear();
    let month = months[date1.getMonth() + 1];
    let h = date1.getHours();
    let m = date1.getMinutes();
    let ap = "AM";
    if (m < 10) {
      m = "0" + m;
    }
    if (h > 12) {
      h = h - 12;
      ap = "PM";
    }
    return month + " " + date + ", " + year + " " + h + ":" + m + " " + ap;
  }
};
//return date  format 2022-12-22
export const dateToDateOnly = (d) => {
  let today = new Date(d);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  return today;
};
//return date and time
export const dateToTime = (d) => {
  let date1 = new Date(d);
  let h = date1.getHours();
  let m = date1.getMinutes();
  return minTwoDigits(h) + ":" + minTwoDigits(m) + ":00";
};
//return min two digits
export const minTwoDigits = (n) => {
  n = parseInt(n);
  return (n < 10 ? "0" : "") + n;
};
//return time format
export const timeFormat = (time) => {
  if (time === null || time === "" || time === undefined || time === "-") {
    return "";
  } else {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[0] = minTwoDigits(time[0]);
    }
    time[3] = "";
    return time.join(""); // return adjusted time or original string
  }
};
//return time format
export const timeFormat2 = (time) => {
  if (time === null || time === "" || time === undefined || time === "-") {
    return "";
  } else {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[0] = minTwoDigits(time[0]);
    }
    time[3] = "";
    // time[2] = "";
    return time.join(""); // return adjusted time or original string
  }
};
//return date format Jan 23, 2023
export const dateFormat = (d) => {
  if (d === null || d === "" || d === undefined) {
    return "-";
  } else {
    let months = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let date1 = new Date(d);
    let date = date1.getDate();
    let year = date1.getFullYear();
    let month = months[date1.getMonth() + 1];
    return month + " " + date + ", " + year;
  }
};

export const timeCalculativeFormat = (time) => {
  if (time === null || time === undefined || time === "") {
    return "-";
  }
  let a = new Date(time);
  let today = new Date(new Date());

  let diffTime = Math.abs(today - a);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  const diffMin = Math.ceil(diffTime / (1000 * 60));
  const diffSec = Math.ceil(diffTime / 1000);

  if (diffSec < 3) {
    return "now";
  } else if (diffSec > 2 && diffSec < 60) {
    return diffSec + " Sec ago";
  } else if (diffSec > 59 && diffSec < 121) {
    return "1 Min ago";
  } else if (diffSec > 120 && diffSec < 3601) {
    return diffMin - 1 + " Mins ago";
  } else if (diffSec > 3600 && diffSec < 7201) {
    return diffHours - 1 + " Hour ago";
  } else if (diffSec < 86400) {
    return diffHours - 1 + " Hours ago";
  } else if (diffSec > 86399 && diffSec < 172801) {
    return "Yesterday";
  } else if (diffHours > 23) {
    return diffDays - 1 + " Days ago";
  } else {
    return "-";
  }
};

export const timeShortCalculativeFormat = (time) => {
  if (time === null || time === undefined || time === "") {
    return "-";
  }
  let a = new Date(time);
  let today = new Date(new Date());

  let diffTime = Math.abs(today - a);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  const diffMin = Math.ceil(diffTime / (1000 * 60));
  const diffSec = Math.ceil(diffTime / 1000);

  if (diffDays > 1) {
    return diffDays - 1 + "d";
  } else if (diffHours > 1) {
    return diffHours - 1 + "h";
  } else if (diffSec > 59) {
    return diffMin - 1 + "m";
  } else if (diffSec > 10) {
    return diffSec + "s";
  } else {
    return "now";
  }
};
export const convertLocalToGMT = (d) => {
  if (d === null || d === "" || d === undefined) return "-";
  let convertLocal = new Date(d).toLocaleString();
  let arr = convertLocal.split(", ");
  let date = arr[0].split("/");
  let sec = arr[1].split(" ");
  return (
    date[2] +
    "-" +
    minTwoDigits(date[0]) +
    "-" +
    minTwoDigits(date[1]) +
    "T" +
    sec[0] +
    ".000+00:00"
  );
};
//convert to local time 2022-09-26T07:35:54.000+00:00 => Sep 26, 2022 11:04 PM
export const convertLocalDateTime = (d) => {
  if (d === null || d === "" || d === undefined) return "-";
  let convertLocal = new Date(d).toLocaleString("en-US");
  let arr = convertLocal.split(", ");
  let date = arr[0].split("/");
  let t = arr[1].split(":");
  let sec = t[2].split(" ");
  let time = minTwoDigits(t[0]) + ":" + minTwoDigits(t[1]) + " " + sec[1];
  let months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    months[parseInt(date[0])] +
    " " +
    minTwoDigits(date[1]) +
    ", " +
    date[2] +
    " " +
    time
  );
};

//convert to local time 2022-09-26T07:35:54.000+00:00 => Sep 26, 2022
export const convertLocalDate = (d) => {
  if (d === null || d === "" || d === undefined) return "-";
  let convertLocal = new Date(d).toLocaleString("en-US");
  let arr = convertLocal.split(", ");
  let date = arr[0].split("/");
  let months = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    months[parseInt(date[0])] + " " + minTwoDigits(date[1]) + ", " + date[2]
  );
};

//convert to local time 2022-09-26T07:35:54.000+00:00 => September 26, 2022
export const convertLocalFullDate = (d) => {
  if (d === null || d === "" || d === undefined) return "-";
  let convertLocal = new Date(d).toLocaleString("en-US");
  let arr = convertLocal.split(", ");
  let date = arr[0].split("/");
  let months = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    months[parseInt(date[0])] + " " + minTwoDigits(date[1]) + ", " + date[2]
  );
};
//convert to local time 2022-09-26T07:35:54.000+00:00 => 11:04 PM
export const convertLocalTime = (d) => {
  if (d === null || d === "" || d === undefined) return "-";
  let convertLocal = new Date(d).toLocaleString("en-US");
  let arr = convertLocal.split(", ");
  let t = arr[1].split(":");
  let sec = t[2].split(" ");
  let time = minTwoDigits(t[0]) + ":" + minTwoDigits(t[1]) + " " + sec[1];

  return time;
};
//convert to GMT time "2022-09-26T15:46:00" => "2022-08-26T10:16:00.000+00:00"
export const convertGMT = (d) => {
  if (d === null || d === "" || d === undefined || d === "-") {
    return null;
  }
  let date = new Date(d);
  return (
    date.getUTCFullYear() +
    "-" +
    minTwoDigits(parseInt(date.getUTCMonth()) + 1) +
    "-" +
    minTwoDigits(date.getUTCDate()) +
    "T" +
    minTwoDigits(date.getUTCHours()) +
    ":" +
    minTwoDigits(date.getUTCMinutes()) +
    ":" +
    minTwoDigits(date.getUTCSeconds()) +
    ".000+00:00"
  );
};
//return date format January 23, 2023
export const dateFormatFull = (d) => {
  if (d === null || d === "" || d === undefined || d === "-") {
    return "-";
  } else {
    let months = [
      "",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let date1 = new Date(d);
    let date = date1.getDate();
    let year = date1.getFullYear();
    let month = months[date1.getMonth() + 1];

    return month + " " + date + ", " + year;
  }
};
export function updateOnlineStatus(event) {
  return navigator.onLine;
}
//return sort string with ...
export const sortText = (text, start, end) => {
  if (text === null || text === "" || text === undefined) {
    return "";
  } else {
    if (text.length >= end) {
      return String(text).slice(start, end) + " ...";
    } else {
      return String(text).slice(start, end);
    }
  }
};
// return sort string
export const sortText2 = (text, start, end) => {
  if (text === null || text === "" || text === undefined) {
    return "";
  } else {
    return String(text).slice(start, end);
  }
};
//return capitalize first letter
export function capitalizeFirstLetter(string) {
  if (string === "" || string === null || string === undefined) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
//return time format
export const timeFormatHumanInteraction = (time, time1) => {
  let a = new Date(time1);
  let a1 = new Date();
  if (time1 === "" || time1 === null) {
    return "Not Define";
  }
  let diffTime = Math.abs(a - a1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  const diffMin = Math.ceil(diffTime / (1000 * 60));
  const diffSec = Math.ceil(diffTime / 1000);
  if (diffDays < 29) {
    if (a - a1 < 0) {
      if (diffDays > 1) {
        return "Expired contract before " + (diffDays - 1) + " days";
      } else if (diffHours > 1) {
        return "Expired contract before " + (diffHours - 1) + " hours";
      } else if (diffSec > 59) {
        return "Expired contract before " + (diffMin - 1) + " minutes";
      } else if (diffSec > 10) {
        return "Expired contract before " + diffSec + " sec";
      } else {
        return "Expired contract today";
      }
    } else {
      if (diffDays > 1) {
        return diffDays - 1 + " days";
      } else if (diffHours > 1) {
        return diffHours - 1 + " hours";
      } else if (diffSec > 59) {
        return diffMin - 1 + " minutes";
      } else if (diffSec > 10) {
        return diffSec + " sec";
      } else {
        return "now";
      }
    }
  } else {
    if (a - a1 < 0) {
      return "Expired contract before " + betweenYearsMonths(a, a1);
    } else {
      return betweenYearsMonths(a, a1);
    }
  }
};
export const ExpireCheck = (time, time1) => {
  let a = new Date(time1);
  let a1 = new Date();
  if (time1 === "" || time1 === null) {
    return "Not Define";
  }
  let diffTime = Math.abs(a - a1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 29) {
    if (a - a1 < 0) {
      return false;
    } else {
      return true;
    }
  } else {
    if (a - a1 < 0) {
      return false;
    } else {
      return true;
    }
  }
};
//set date format
export const setDateFormat = (date) => {
  if (date === "" || date === null) {
    return "--";
  }
  let today = new Date(date);
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  return today;
};
// export const setDateFormat = (_date) => {
//   if (_date) {
//     let formatLowerCase = "mm/dd/yyyy";
//     let formatItems = formatLowerCase.split("/");
//     let dateItems = _date.split("/");
//     let monthIndex = formatItems.indexOf("mm");
//     let dayIndex = formatItems.indexOf("dd");
//     let yearIndex = formatItems.indexOf("yyyy");
//     let formatDate =
//       dateItems[yearIndex] +
//       "-" +
//       dateItems[monthIndex] +
//       "-" +
//       dateItems[dayIndex];
//     return formatDate;
//   } else {
//     return "";
//   }
// };
//check mail format
export const checkEmail = (value, next) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return (!re.test(String(value).toLowerCase()) || value === "") && next;
};
export const betweenDates = (start, end) => {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let diff = endDate.getTime() - startDate.getTime();

  return diff;
};
//diff between minutes
export const betweenMinutes = (start, end) => {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let diff = endDate.getTime() - startDate.getTime();

  return diff / (1000 * 60);
};
//diff between hours
export const betweenHours = (start, end) => {
  let startDate = new Date(start);
  let endDate = new Date(end);
  let diff = endDate.getTime() - startDate.getTime();

  return diff / 1000 / 60 / 60;
};
//diff between days
export const betweenDays = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let Difference_In_Time = date2.getTime() - date1.getTime();
  if (Difference_In_Time === 0) {
    return 1;
  }
  return Math.round(Difference_In_Time / (1000 * 3600 * 24));
};
//diff between months
export const betweenMonths = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let months = (date2.getFullYear() - date1.getFullYear()) * 12;
  // months = Math.abs(months);

  months -= date1.getMonth();
  months += date2.getMonth();
  if (months === 0) {
    return 1;
  }
  return Math.round(Math.abs(months));
};
//diff between years
export const betweenYears = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  if (months === 0) {
    return 0;
  }
  return parseInt(Math.abs(months) / 12);
};
//diff between years and months
export const betweenYearsMonths = (d1, d2) => {
  let years = betweenYears(d1, d2);
  let months = betweenMonths(d1, d2);
  let final = "";
  if (years === 0) {
    final = "";
  } else if (years === 1) {
    final = years + " year ";
  } else if (years > 1) {
    final = years + " years ";
  }
  if (months === 1) {
    final = final + " and " + (months % 12) + " month";
  } else if (months % 12 === 0) {
    // eslint-disable-next-line
    final = final;
  } else if (months > 1) {
    if (years === 0) {
      final = (months % 12) + " months";
    } else {
      final = final + " and " + (months % 12) + " months";
    }
  }

  return final;
};
//sum months
export const addMonths = (date1, months) => {
  let date = new Date(date1);
  let d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return correctDateFormat(date);
};
//sum months and years
export const addMonthsYear = (date1, months) => {
  let date = new Date(date1);
  let d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }

  return correctMonthYearFormat(date);
};

//diff between extra month
export const betweenExtraMonths = (d1, d2) => {
  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let months = (date2.getFullYear() - date1.getFullYear()) * 12;
  months -= date1.getMonth();
  months += date2.getMonth();
  return Math.round((months <= 0 ? 0 : months) % 12);
};
//change date format
export const correctDateFormat = (date) => {
  let current_datetime = new Date(date);

  let month = "";
  let day = "";
  if (date === "") {
    return "";
  }
  if (current_datetime.getMonth() + 1 < 10) {
    month = "0" + (current_datetime.getMonth() + 1);
  } else {
    month = current_datetime.getMonth() + 1;
  }
  if (current_datetime.getDate() < 10) {
    day = "0" + current_datetime.getDate();
  } else {
    day = current_datetime.getDate();
  }
  let formatted_date = month + "-" + day + "-" + current_datetime.getFullYear();
  return formatted_date;
};
//change month year format
export const correctMonthYearFormat = (date) => {
  let current_datetime = new Date(date);

  let month = "";

  if (date === "") {
    return "";
  }

  if (current_datetime.getMonth() + 1 < 10) {
    month = "0" + (current_datetime.getMonth() + 1);
  } else {
    month = current_datetime.getMonth() + 1;
  }
  let formatted_date = month + "-" + current_datetime.getFullYear();
  return formatted_date;
};
//get sort month name with number
export function getMonth(num) {
  let n1 = num;
  if (n1 === 1) {
    return "Jan";
  } else if (n1 === 2) {
    return "Feb";
  } else if (n1 === 3) {
    return "Mar";
  } else if (n1 === 4) {
    return "Apr";
  } else if (n1 === 5) {
    return "May";
  } else if (n1 === 6) {
    return "Jun";
  } else if (n1 === 7) {
    return "Jul";
  } else if (n1 === 8) {
    return "Aug";
  } else if (n1 === 9) {
    return "Sep";
  } else if (n1 === 10) {
    return "Oct";
  } else if (n1 === 11) {
    return "Nov";
  } else if (n1 === 12) {
    return "Dec";
  } else {
    return "-";
  }
}
//get full month name with number
export function getFullMonth(num) {
  let n1 = num;
  if (n1 === 1) {
    return "January";
  } else if (n1 === 2) {
    return "February";
  } else if (n1 === 3) {
    return "March";
  } else if (n1 === 4) {
    return "April";
  } else if (n1 === 5) {
    return "May";
  } else if (n1 === 6) {
    return "June";
  } else if (n1 === 7) {
    return "July";
  } else if (n1 === 8) {
    return "August";
  } else if (n1 === 9) {
    return "September";
  } else if (n1 === 10) {
    return "October";
  } else if (n1 === 11) {
    return "November";
  } else if (n1 === 12) {
    return "Dec";
  } else {
    return "";
  }
}

//except null
export function exceptNameNull(text1, text2) {
  if (text1 === null || text1 === undefined || text1 === "") {
    if (text2 === null || text2 === undefined || text2 === "") {
      return "-";
    } else {
      return text2;
    }
  } else {
    if (text2 === null || text2 === undefined || text2 === "") {
      return text1;
    } else {
      return text1 + " " + text2;
    }
  }
}
export function exceptNull(text) {
  if (text === null || text === undefined || text === "") {
    return "-";
  }
  return text;
}
export function checkNull(array) {
  let check = true;
  array.forEach((text) => {
    if (text === null || text === undefined) {
      check = false;
    }
  });
  return check;
}
export function removeDuplicate(array) {
  var arr = array.filter((element, index) => {
    return array.indexOf(element) === index;
  });
  return arr;
}

export function copy(value) {
  navigator.clipboard.writeText(value);
}
export function addressFormatter(address) {
  if (address === null || address === undefined || address === "") {
    return "-";
  } else {
    let s = "";
    if (exceptNull(address.address) !== "-") {
      s = s + address.address.replace(/\b(\w)/g, (s) => s.toUpperCase()) + ", ";
    }
    if (exceptNull(address.city) !== "-") {
      s = s + address.city.replace(/\b(\w)/g, (s) => s.toUpperCase()) + ", ";
    }
    if (exceptNull(address.state) !== "-") {
      s = s + address.state.replace(/\b(\w)/g, (s) => s.toUpperCase()) + ", ";
    }
    if (exceptNull(address.country) !== "-") {
      s = s + address.country.replace(/\b(\w)/g, (s) => s.toUpperCase()) + ". ";
    }
    return s;
  }
}

export function setImage(data) {
  if (data.provider === "" || data.provider === null) {
    return image;
  } else if (data.provider === "local") {
    fetch(url + `api/user/${data.username}/image/download`)
      .then((res) => {
        if (res.status === 200) {
          if (data.coverImage === null) {
            return image;
          } else {
            return res.url;
          }
        } else {
          return image;
        }
      })
      .catch((res) => {
        return image;
      });
  } else {
    return data.coverImage;
  }
}
