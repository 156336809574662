// Temporary Image Reducer
const tempPropertyImagesReducer = (state = [], action) => {
  switch (action.type) {
    case "GET_TEMP_PROPERTY_IMAGES_DATA":
      return state;
    case "APPEND_TEMP_PROPERTY_IMAGES_DATA":
      if (
        action === {} ||
        action === null ||
        action === undefined ||
        action === ""
      ) {
        return state;
      } else if (state.length > 0) {
        let images = [action.image, ...state];
        images = images.filter((obj, pos, arr) => {
          return arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos;
        });
        return images;
      } else {
        return [action.image, ...state];
      }
    case "REMOVE_TEMP_PROPERTY_IMAGES_DATA_BY_ID":
      return state.filter((todo) => todo.id !== action.id);
    case "REMOVE_TEMP_PROPERTY_IMAGES_DATA":
      return null;
    default:
      return state;
  }
};
export default tempPropertyImagesReducer;
