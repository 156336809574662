import React, { Component } from "react";
import "./application.css";
import "./dropdownForm.css";
import Loader from "../../Loader/Loader";
import ApplicationLandlord from "./ApplicationLandlord/ApplicationLandlord";
import ApplicationTenant from "./ApplicationTenant/ApplicationTenant";

class Applications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return this.props.role !== "Guest" ? (
      this.props.role === "Landlord" ? (
        <div
          className="listApp"
          style={{ minHeight: (window.screen.height * 4) / 5 }}
        >
          <ApplicationLandlord/>
        </div>
      ) : this.props.role === "Tenant" ? (
        <div
          className="applicationContainer"
          style={{ minHeight: (window.screen.height * 4) / 5 }}
        >
          <ApplicationTenant/>
        </div>
      ) : (
        <h1 style={{ paddingTop: "70px" }}>Something went wrong</h1>
      )
    ) : (
      <Loader />
    );
  }
}

export default (Applications);
