import React, { Component } from "react";
import { userDetail, url } from "../../../../Services/ApiServices";
import image from "../../../../Images/profile.png";
import "../payment.css";
import { withRouter } from "react-router-dom";
import {
  convertLocalDateTime,
  getFullMonth,
  sortText,
} from "../../../../Defined/Function";
import { connect } from "react-redux";
import {
  appendAlert,
  appendTempUsers,
  appendTempProfileImage,
  getTempProfileImage,
} from "../../../../actions";
import { capitalizeFirstLetter } from "../../../../Defined/Function";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import imageLoader from "../../../../Images/imageLoader.gif";

class LandlordRecentPaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentImage: imageLoader,
      ImageLoader: true,
      tenantIdDetails: [],
    };
  }
  fetchImage(username, url, state) {
    const toDataURL = (url1) =>
      fetch(url1)
        .then((response) => {
          if (response.status === 200) {
            return response.blob();
          } else {
            throw new Error(response);
          }
        })
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        )
        .catch((res) => {
          throw new Error(res);
        });

    toDataURL(url)
      .then((dataUrl) => {
        this.setState({
          [state]: dataUrl,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: dataUrl,
        });
      })
      .catch((res) => {
        this.setState({
          [state]: image,
        });
        this.props.appendTempProfileImage({
          username: username,
          image: image,
        });
      });
  }
  setImage(data, state) {
    if (data.provider === "" || data.provider === null) {
      this.setState({ [state]: image });
      this.props.appendTempProfileImage({
        username: data.username,
        image: image,
      });
    } else if (data.provider === "local") {
      if (data.coverImage === null) {
        this.setState({ [state]: image });
      } else {
        this.fetchImage(
          data.username,
          url + `api/user/${data.username}/image/download`,
          state
        );
      }
    } else {
      this.fetchImage(data.username, data.coverImage, state);
    }
  }

  getUser() {
    try {
      userDetail(this.props.data.tenantId).then((response) => {
        if (response.status) {
          this.props.appendTempUsers(response.data);
          this.setState({ tenantIdDetails: response.data });
          this.filterImage(response.data, "paymentImage");
        }
      });
    } catch (error) {
      this.setState({ paymentImage: image, ImageLoader: false });
    }
  }
  componentDidMount() {
    var users = this.props.tempUsersArray.filter(
      (todo) => todo.username === this.props.data.tenantId
    );
    if (users.length === 0) {
      this.getUser();
    } else {
      this.setState({ tenantIdDetails: users[0] });
      this.filterImage(users[0], "paymentImage");
    }
  }
  filterImage(data, state) {
    let usersImage = this.props.tempProfileImagesArray.filter(
      (todo) => todo.username === data.username
    );
    if (usersImage.length === 0) {
      this.setImage(data, state);
    } else {
      this.setState({ [state]: usersImage[0].image });
    }
  }
  render() {
    return (
      this.props.data && (
        <>
          <div className="recentPaymentTableWeb">
            <div
              className={"row   contractDivider2" }
            >
              <div className="col-md-1 m-0 p-0 align-self-center d-flex justify-content-center ">
                <img
                  alt={""}
                  src={this.state.paymentImage}
                  className=" round payeeImage"
                />
              </div>
              <div className="col-md m-0 p-0 text-center ">
                <p className="maintenanceHeadText1 ">
                  {this.state.tenantIdDetails.length === 0 ||
                  (this.state.tenantIdDetails.firstName === "" &&
                    this.state.tenantIdDetails.lastName === "")
                    ?this.props.data.tenantId
                    :  this.state.tenantIdDetails.firstName +
                          " " +
                          this.state.tenantIdDetails.lastName}
                </p>
              </div>
              <div className="col-md-2 m-0 p-0 pt-3 text-center">
                <p
                  className={"maintenanceHeadText3 textCapitalizeCase"}
                  style={{
                    color: "#a79e9d",
                    fontWeight: 600,
                  }}
                >
                  {this.props.data.paymentType === "Subscription update"
                    ? "Rent"
                    : this.props.data.paymentType === "Rent"
                    ? "Rent - " +
                      this.props.data.yearAndMonth.slice(0, 5) +
                      " " +
                      getFullMonth(
                        parseInt(this.props.data.yearAndMonth.slice(5, 7))
                      )
                    : sortText(this.props.data.paymentType, 0, 18)}
                </p>
              </div>

              <div className="col-md m-0 p-0 text-center">
                <p
                  className={"maintenanceHeadText3"}
                  style={{
                    color: "#ee745e",
                    fontWeight: 800,
                  }}
                >
                  <CurrencyFormat value={this.props.data.paidAmount} />
                </p>
                <p className={"maintenanceHeadText4"}>
                  {convertLocalDateTime(this.props.data.paidDate)}
                </p>
              </div>
              <div className="col-md m-0 p-0 text-center">
                <p
                  className={"maintenanceHeadText3"}
                  style={{
                    color: "#ee745e",
                    fontWeight: 800,
                  }}
                >
                  <CurrencyFormat value={this.props.data.dueAmount} />
                </p>
                <p className={"maintenanceHeadText4"}>
                  {convertLocalDateTime(this.props.data.paymentDueDate)}
                </p>
              </div>
              <div className="col-md-1 m-0 p-0 text-center pt-3">
                <span
                  className="badge appStatus "
                  style={{
                    backgroundColor:
                      this.props.data.status === "succeeded"
                        ? "#2ECC71"
                        : this.props.data.status === "pending"
                        ? "#F4D03F"
                        : "#E74C3C",
                  }}
                >
                  {capitalizeFirstLetter(this.props.data.status)}
                </span>
              </div>
            </div>
          </div>
          <div className="recentPaymentTableMobile">
            <div
              className={"row   contractDivider3"}

            >
              <div className="col-3 pb-5  align-self-center d-flex justify-content-center borderBottomGray ">
                <img
                  alt={""}
                  src={this.state.paymentImage}
                  className="mt-4 round payeeImage "
                />
              </div>
              <div className=" col-9    text-left  borderLeftGray borderBottomGray">
                <p
                  className={"maintenanceHeadText pb-0 mb-0"}
                  style={{ fontWeight: "bold" }}
                >
                  Name
                </p>
                <p className="maintenanceHeadText1 pb-3">
                  {this.state.tenantIdDetails.length === 0 ||
                  (this.state.tenantIdDetails.firstName === "" &&
                    this.state.tenantIdDetails.lastName === "")
                    ? sortText(this.props.data.tenantId, 0, 20)
                    : sortText(
                        this.state.tenantIdDetails.firstName +
                          " " +
                          this.state.tenantIdDetails.lastName,
                        0,
                        20
                      )}
                </p>
              </div>
              <div className=" col-6  m-0 p-0 text-left pl-3 text-left  borderLeftGray borderBottomGray">
                <p
                  className={"maintenanceHeadText pb-0 mb-0"}
                  style={{ fontWeight: "bold" }}
                >
                  Status
                </p>
                <span
                  className="badge appStatus mt-3"
                  style={{
                    backgroundColor:
                      this.props.data.status === "succeeded"
                        ? "#2ECC71"
                        : this.props.data.status === "pending"
                        ? "#F4D03F"
                        : "#E74C3C",
                  }}
                >
                  {capitalizeFirstLetter(this.props.data.status)}
                </span>
              </div>
            
           
              <div className=" col-6 m-0 p-0  text-left pl-3 text-left  borderLeftGray borderBottomGray ">
                <p
                  className={"maintenanceHeadText pb-0 mb-0"}
                  style={{ fontWeight: "bold" }}
                >
                  Type
                </p>
                <p
                  className={"maintenanceHeadText3 textCapitalizeCase pt-2 "}
                  style={{
                    color: "#a79e9d",
                    fontWeight: 600,
                  }}
                >
                  {this.props.data.paymentType === "Subscription update"
                    ? "Rent"
                    : this.props.data.paymentType === "Rent"
                    ? "Rent - " +
                      this.props.data.yearAndMonth.slice(0, 5) +
                      " " +
                      getFullMonth(
                        parseInt(this.props.data.yearAndMonth.slice(5, 7))
                      )
                    : sortText(this.props.data.paymentType, 0, 18)}
                </p>
              </div>

              <div className="col-6 m-0 p-0 text-left pl-3 ">
                <p
                  className={"maintenanceHeadText pb-0 mb-0 "}
                  style={{ fontWeight: "bold" }}
                >
                  Paid
                </p>
                <p
                  className={"maintenanceHeadText3"}
                  style={{
                    color: "#ee745e",
                    fontWeight: 800,
                  }}
                >
                  <CurrencyFormat value={this.props.data.paidAmount} />
                </p>
                <p className={"maintenanceHeadText4"}>
                  {convertLocalDateTime(this.props.data.paidDate)}
                </p>
              </div>
              <div className="col-6 m-0 p-0 text-left borderLeftGray pl-3">
                <p
                  className={"maintenanceHeadText pb-0 mb-0"}
                  style={{ fontWeight: "bold" }}
                >
                  Due
                </p>
                <p
                  className={"maintenanceHeadText3"}
                  style={{
                    color: "#ee745e",
                    fontWeight: 800,
                  }}
                >
                  <CurrencyFormat value={this.props.data.dueAmount} />
                </p>
                <p className={"maintenanceHeadText4"}>
                  {convertLocalDateTime(this.props.data.paymentDueDate)}
                </p>
              </div>
            </div>
          </div>
        </>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isDark: state.isDark,
    dark: state.darkReducer,
    user: state.userReducer,
    userDetail: state.user,
    alert: state.alertReducer,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
    tempProfileImages: state.tempProfileImagesReducer,
    tempProfileImagesArray: state.tempProfileImages,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    appendAlert,
    appendTempUsers,
    appendTempProfileImage,
    getTempProfileImage,
  })(LandlordRecentPaymentList)
);
