import React, { Component } from "react";
import "./MaintenanceHistory.css";
import "../MaintenanceRequestDetail/maintenanceRequestDetail.css";
import { convertLocalDateTime } from "../../../../Defined/Function";
import GetUserFullName from "../../../../Defined/GetUserFullName";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { maintenanceRecordStatus } from "../../../../Defined/DataFunction";

class MaintenanceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  filterTimeLineProvider(actionById) {
    let arr = this.props.providerArray.filter((element) => {
      return parseInt(element.id) === parseInt(actionById);
    });
    if (arr.length === 0) {
      return "provider";
    } else {
      return (
        <>
          {arr[0].firstName + " " + arr[0].lastName}
          <p className="maintenanceRequestDetailText7 p-0 m-0">
            [ {arr[0].email} ]
          </p>
        </>
      );
    }
  }
  render() {
    return (
      this.props.eventRecords && (
        <div className="maintenanceRequestCard timeline-container">
          <span className="maintenanceRequestDetailText1 ">
            Status Timeline
          </span>
          {this.props.eventRecords !== undefined &&
          this.props.eventRecords.length === 0 ? (
            <h1 className="pl-4 mt-4 mb-3 maintenanceRequestText3">
              Not created jet
            </h1>
          ) : (
            <div id="timeline-content">
              <ul className="timeline">
                {this.props.eventRecords.map((record) => (
                  <li
                    className="event"
                    data-date={
                      "[ " + convertLocalDateTime(record.createdAt) + " ]"
                    }
                  >
                    <h3>{maintenanceRecordStatus(record.maintenanceEvent)}</h3>

                    <span className="timeLineMobileDate pr-4">
                      {"[ " + convertLocalDateTime(record.createdAt) + " ]"}
                    </span>
                    {record.maintenanceEvent === "QuotationReceived" ||
                    record.maintenanceEvent === "JobStatusFinished" ? (
                      <span
                        className={
                          "badge  badge07 maintenanceRequestTimelineText "
                        }
                      >
                        <strong>By :</strong> &nbsp;&nbsp;
                        {this.props.providerArray === null
                          ? "-"
                          : this.filterTimeLineProvider(record.actionById)}
                      </span>
                    ) : (
                      <span
                        className={
                          record.actionBy === "Tenant"
                            ? "badge  badge05 maintenanceRequestTimelineText "
                            : "badge  badge06 maintenanceRequestTimelineText "
                        }
                      >
                        <strong>By :</strong> &nbsp;&nbsp;
                        <GetUserFullName id={record.actionById} />
                        <p className="maintenanceRequestDetailText7 p-0 m-0">
                          [ {record.actionById} ]
                        </p>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    provider: state.providerReducer,
    providerArray: state.provider,
    role: state.RoleReducer,
    roleStatus: state.role,
  };
};

export default withRouter(connect(mapStateToProps, {})(MaintenanceHistory));
