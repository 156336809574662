import React from "react";
import Search from "../search/search";
import RentalRow from "./RentalRow";
import "./table.css";

const RentalTable = (props) => {
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="BackStatus dashCover ">
            <div className="row">
              <div className="col-sm-4">
                <h3 className="text-dark pt-3 pl-3 tableHead cardHead">
                  Rental Units
                </h3>
              </div>
              <div className="col-sm-8 pull-right pt-1">
                <Search />
              </div>
            </div>

            <table
              className="table table-striped table-hover table-responsive tableRental"
              style={{ width: "100%" }}
            >
              <thead className=" text-white ">
                <tr className="bgDarkBlue rowTable">
                  <th className="headName" scope="col" style={{ width: "5%",minWidth:"50px" }}>
                    #
                  </th>
                  <th className="headName" scope="col" style={{ width: "15%",minWidth:"50px" }}>
                    Unit
                  </th>
                  <th className="headName text-center" scope="col" style={{ width: "15%",minWidth:"100px" }}>
                    Tenant
                  </th>
                  <th
                    className="headName text-center"
                    scope="col"
                    style={{ width: "25%",minWidth:"240px" }}
                  >
                    Transaction
                  </th>
                  <th className="headName" scope="col" style={{ width: "10%" }}>
                    Maintainance
                  </th>
                  <th
                    className="headName"
                    scope="col"
                    style={{ width: "5%" }}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {props.tableData &&
                  props.tableData.map((tData) => <RentalRow data={tData} />)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default RentalTable;
