// Temporary Tenant By Contract Reducer 
const tempTenantByContractReducer = (state = [], action) => {
     switch (action.type) {
       case "GET_TEMP_TENANT_BY_CONTRACT":
         return state;
       case "APPEND_TEMP_TENANT_BY_CONTRACT":
          let tenantByContract={
               id:action.id,
               contract:action.contractArray
          };
         let contracts=[ tenantByContract,...state];
         contracts = contracts.filter((obj, pos, arr) => {
          return arr
            .map(mapObj => mapObj.id)
            .indexOf(obj.id) === pos;
        });
         return  contracts;
       case "APPEND_TEMP_TENANT_BY_CONTRACT_ARRAY":
         return  [...state, ...action.listArray];
       case "REMOVE_TEMP_TENANT_BY_CONTRACT_BY_ID":
         return state.filter((todo) => todo.id !== action.id )
       case "REMOVE_TEMP_TENANT_BY_CONTRACT":
         return null;
       default:
         return state;
     }
   };
export default tempTenantByContractReducer;
   