//Property Details Reducer 
const propertyReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_PROPERTY":
      return state;
    case "SET_PROPERTY":
      return action.property;
    case "REMOVE_PROPERTY_BY_ID":
      return state.filter((todo) => todo.id !== action.id )
    case "REMOVE_PROPERTY":
      return null;
    default:
      return state;
  }
};
export default propertyReducer;
