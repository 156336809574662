import { combineReducers } from "redux";
import AlertReducer from "./alert";
import DarkReducer from "./isDark";
import AuthReducer from "./isAuth";
import RoleReducer from "./role";
import UserReducer from "./user";
import PropertyReducer from "./property";
import customerPaymentReducer from "./customerPayment";
import accountPaymentReducer from "./accountPayment";
import applicationReducer from "./application";
import tempListingReducer from "./tempListingData";
import tempUsersReducer from "./tempUsers";
import contractReducer from "./contract";
import recentPaymentReducer from "./recentPayment";
import customerPaymentDetailReducer from "./customerPaymentData";
import rentalListReducer from "./rentalList";
import maintenanceReducer from "./maintenance";
import tempPropertyByUnitReducer from "./tempPropertyByUnit";
import tempTenantByContractReducer from "./tempTenantByContract";
import paymentDashboardDataReducer from "./paymentDashboardData";
import profileAlertReducer from "./profileAlert";
import providerReducer from "./provider";
import tempProfileImagesReducer from "./tempProfileImages";
import tempPropertyImagesReducer from "./tempPropertyImages";

const allReducers = combineReducers({
  isDark: DarkReducer,
  alert: AlertReducer,
  isAuth: AuthReducer,
  role: RoleReducer,
  user: UserReducer,
  property: PropertyReducer,
  application: applicationReducer,
  recentPayment: recentPaymentReducer,
  contract: contractReducer,
  maintenance: maintenanceReducer,
  rentalList: rentalListReducer,
  customerPayment: customerPaymentReducer,
  accountPayment: accountPaymentReducer,
  customerPaymentDetail: customerPaymentDetailReducer,
  tempPropertyByUnit: tempPropertyByUnitReducer,
  tempListing: tempListingReducer,
  tempUsers: tempUsersReducer,
  tempTenantByContract: tempTenantByContractReducer,
  paymentDashboardData: paymentDashboardDataReducer,
  profileAlert: profileAlertReducer,
  provider: providerReducer,
  tempProfileImages: tempProfileImagesReducer,
  tempPropertyImages: tempPropertyImagesReducer,
});

export default allReducers;
