import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { getUser } from "../../Services/ApiServices";
import CookieService from "../../Services/CookieService";

import Loader from "../Loader/Loader";

export default class StripeLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }
  componentDidMount() {
    getUser()
      .then((response) => {
        if (response.status === 200) {
          CookieService.setArrayCookie("axUserDetails", response.data, 7);
          this.setState({
            redirect: true,
          });
        } else {
          this.props.pushAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/properties"} />;
    }
    return (
      <div>
        <Loader />
      </div>
    );
  }
}
