//Maintenance Details Reducer
const maintenanceReducer = (state = null, action) => {
  switch (action.type) {
    case "GET_MAINTENANCE":
      return state;
    case "SET_MAINTENANCE":
      return action.maintenance;
    case "REMOVE_MAINTENANCE_BY_ID":
      return state.filter((todo) => todo.id !== action.id);
    case "REMOVE_MAINTENANCE":
      return null;
    default:
      return state;
  }
};
export default maintenanceReducer;
