import React, { Component } from "react";
import "./torontoStyle.css";
import logo from "../../../../Images/logo.jpeg";
import ApplicationInput from "../../../UIServices/ApplicationInput";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";

class TorontoReview extends Component {
  state = {};
  today() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }
  render() {
    return (
      <div>
        <div className="applicationAgreement ">
          <div className="card pb-5 p-3">
            <div className="row applicationAgreementHead">
              <div className="col-6 torontoAgreementHeadLeft">
                <div className=" text-left">
                  <img src={logo} className="agreementLogo " alt="logo" />
                </div>
              </div>

              <div className="col-6 applicationAgreementHeadRight">
                <div className="text">
                  <span className="span7">
                    <b>
                      Residential Tenancy Agreement
                      <br></br>
                      (Standard Form of Lease)
                    </b>
                  </span>
                </div>
              </div>
            </div>

            <div className="row m-4">
              <div className="col ">
                <span className="span7 ">
                  <h6>
                    <b>Note</b>
                  </h6>
                  This tenancy agreement (or lease) is required for tenancies
                  entered into on
                  <b> March 1, 2021 or later</b>. It does not apply to care
                  homes, sites in mobile home parks and land lease communities,
                  most social housing, certain other special tenancies or
                  co-operative housing (see Part A of General Information).
                  <br></br>
                  Residential tenancies in Ontario are governed by the
                  Residential Tenancies Act, 2006. This agreement cannot take
                  away a right or responsibility under the Residential Tenancies
                  Act, 2006.
                  <br></br>
                  Under the Ontario Human Rights Code, everyone has the right to
                  equal treatment in housing without discrimination or
                  harassment.
                  <br></br>
                  All sections of this agreement are mandatory and cannot be
                  changed.
                  <br></br>
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3 ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">1. Parties to the Agreement</h>
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col p-0  m-0 ">
                  <span className="col span7 m-0 p-0  ">
                    <b>
                      Residential Tenancy Agreement between:
                      <br></br>
                      Landlord(s)
                    </b>
                    <br></br>
                  </span>
                </div>
              </div>

              <div className="row p-0 m-0">
                <span className=" col-4 span7 p-0 m-0 mt-4">
                  1. Landlord’s Legal Name
                </span>
                <input
                  className="col ApplicationInput m-0 mt-2"
                  value={
                    this.props.landlordData &&
                    this.props.landlordData.firstName +
                      " " +
                      this.props.landlordData.middleName +
                      " " +
                      this.props.landlordData.lastName
                  }
                ></input>
              </div>

              <hr className="hrline"></hr>

              <div className="row p-0 m-0">
                <span className="span7 p-1">
                  <b>and Tenant(s)</b>
                </span>
              </div>

              <div className=" ApplicationNote row p-0 m-0">
                <div className="col p-1 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    See Part B in General Information
                  </span>
                </div>
              </div>
              <br></br>

              <div className="row p-0 m-0  ">
                <div className="col p-0 m-0  halfBreak agreementBoder agreementRightBoder">
                  <div className="row p-1 ">
                    <div className="col-4">
                      <span className="span7">1.Last Name</span>
                    </div>
                    <div className="col">
                      <input
                        className="ApplicationInput m-0"
                        value={
                          this.props.applicantData
                            ? this.props.applicantData[0] &&
                              this.props.applicantData[0].lastName
                            : ""
                        }
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="col p-0 m-0  halfBreak agreementBoder">
                  <div className="row p-1">
                    <div className="col-4">
                      <span className="span7">First Name</span>
                    </div>
                    <div className="col">
                      <input
                        className="ApplicationInput m-0"
                        value={
                          this.props.applicantData
                            ? this.props.applicantData[0] &&
                              this.props.applicantData[0].firstName
                            : ""
                        }
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-0 m-0  ">
                <div className="col p-0 m-0  halfBreak agreementBoder agreementRightBoder">
                  <div className="row p-1">
                    <div className="col-4">
                      <span className="span7">2.Last Name</span>
                    </div>
                    <div className="col">
                      <input
                        className="ApplicationInput m-0 "
                        value={
                          this.props.applicantData
                            ? this.props.applicantData[1] &&
                              this.props.applicantData[1].lastName
                            : ""
                        }
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="col p-0 m-0  halfBreak agreementBoder">
                  <div className="row p-1">
                    <div className="col-4">
                      <span className="span7">First Name</span>
                    </div>
                    <div className="col">
                      <input
                        className="ApplicationInput m-0"
                        value={
                          this.props.applicantData
                            ? this.props.applicantData[1] &&
                              this.props.applicantData[1].firstName
                            : ""
                        }
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-0 m-0  ">
                <div className="col p-0 m-0  halfBreak agreementBoder agreementRightBoder">
                  <div className="row p-1">
                    <div className="col-4">
                      <span className="span7">3.Last Name</span>
                    </div>
                    <div className="col">
                      <input
                        className="ApplicationInput m-0"
                        value={
                          this.props.applicantData
                            ? this.props.applicantData[2] &&
                              this.props.applicantData[2].lastName
                            : ""
                        }
                      ></input>
                    </div>
                  </div>
                </div>

                <div className="col p-0 m-0  halfBreak agreementBoder">
                  <div className="row p-1">
                    <div className="col-4">
                      <span className="span7">First Name</span>
                    </div>
                    <div className="col">
                      <input
                        className="ApplicationInput m-0"
                        value={
                          this.props.applicantData
                            ? this.props.applicantData[2] &&
                              this.props.applicantData[2].firstName
                            : ""
                        }
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-0 m-0  ">
                <div className="col p-0 m-0  halfBreak agreementBoder agreementRightBoder">
                  <div className="row p-1">
                    <div className="col-4">
                      <span className="span7">4.Last Name</span>
                    </div>
                    <div className="col">
                      <input className="ApplicationInput m-0"></input>
                    </div>
                  </div>
                </div>

                <div className="col p-0 m-0  halfBreak agreementBoder">
                  <div className="row p-1">
                    <div className="col-4">
                      <span className="span7">First Name</span>
                    </div>
                    <div className="col">
                      <input className="ApplicationInput m-0"></input>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0 ">
                  <h className="p-0 m-2">2. Rental Unit</h>
                </div>
              </div>
              <span className="span7  p-0 mt-3">
                The landlord will rent to the tenant the rental unit at:
              </span>

              <div className="row p-0 m-0  halfBreak">
                <div className="col-5 p-1 m-0 agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">
                    Unit (e.g., unit 1 or basement unit)
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    value={this.props.propertyData.rentalEntity.unitName}
                  ></input>
                </div>

                <div className="col-3 p-1 m-0 agreementBoder agreementRightBoder ">
                  <span className="span7 pl-1">Street Number</span>
                  <input className="ApplicationInput m-0"></input>
                </div>

                <div className="col p-1 m-0  agreementBoder">
                  <span className="span7 pl-1">Street Name</span>
                  <input
                    className="ApplicationInput m-0"
                    value={this.props.propertyData.address.address}
                  ></input>
                </div>
              </div>

              <div className="row p-1 m-0  halfBreak ">
                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">City/Town</span>
                  <input
                    className="ApplicationInput m-0"
                    value={this.props.propertyData.address.city}
                  ></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Province</span>
                  <br></br>
                  <span className="span7 pl-1 text-primary">Ontario</span>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Postal Code</span>
                  <input
                    className="ApplicationInput m-0"
                    value={this.props.propertyData.address.zip}
                  ></input>
                </div>
              </div>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-2 m-0  halfBreak">
                  <span className="span7  ">
                    Number of vehicle parking spaces and description (e.g.,
                    indoor/outdoor, location)
                  </span>
                  <input
                    className="ApplicationInputAgg m-0"
                    value={this.props.agreementData.parkingDescription}
                  ></input>
                </div>
              </div>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-2 m-0  halfBreak">
                  <span className="span7 pl-1 ">
                    The rental unit is a unit in a condominium.
                    &nbsp;&nbsp;&nbsp;
                  </span>

                  <div className="row p-0 m-0">
                    <div className="col p-1 m-0 ">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={this.props.agreementData.condominium}
                      />
                    </div>

                    <div className="col p-0 m-0 pt-1">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target=""
                        value={!this.props.agreementData.condominium}
                      />
                    </div>
                  </div>

                  <span className="span7 pl-1 ">
                    If yes, the tenant agrees to comply with the condominium
                    declaration, by-laws and rules, as provided by the landlord.
                  </span>
                  {/* <input className="ApplicationInputAgg m-0"></input> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pt-5 pb-5">
            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">3. Contact Information</h>
                </div>
              </div>

              <span className="span7 pl-1">
                <b>Address for Giving Notices or Documents to the Landlord</b>
              </span>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-1 m-0 agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Unit</span>
                  <input className="ApplicationInputAgg m-0"></input>
                </div>

                <div className="col-2 p-1 m-0 agreementBoder agreementRightBoder ">
                  <span className="span7 pl-1">Street Number</span>
                  <input className="ApplicationInputAgg m-0"></input>
                </div>

                <div className="col-5 p-1 m-0  agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Street Name</span>
                  <input
                    className="ApplicationInputAgg m-0"
                    value={this.props.landlordData.address.address}
                  ></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder ">
                  <span className="span7 pl-1">PO Box</span>
                  <input className="ApplicationInputAgg m-0"></input>
                </div>
              </div>

              <div className="row p-1 m-0  halfBreak ">
                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">City/Town</span>
                  <input
                    className="ApplicationInputAgg m-0"
                    value={this.props.landlordData.address.city}
                  ></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Province</span>
                  <br></br>
                  <input
                    className="ApplicationInputAgg m-0"
                    value={this.props.landlordData.address.state}
                  ></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder ">
                  <span className="span7 pl-1">Postal Code</span>
                  <input
                    className="ApplicationInputAgg m-0"
                    value={this.props.landlordData.address.zip}
                  ></input>
                </div>
              </div>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-0 m-0  halfBreak">
                  <span className="span7 pl-1 ">
                    Both the landlord and tenant agree to receive notices and
                    documents by email, where allowed by the Landlord and Tenant
                    Board’s Rules of Procedure. &nbsp;&nbsp;&nbsp;
                  </span>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col p-0 m-0 pt-1">
                  <ApplicationInput
                    name="Yes"
                    type="checkedBox"
                    target=""
                    value={this.props.agreementData.emailOption}
                  />
                </div>

                <div className="col p-0 m-0 pt-1">
                  <ApplicationInput
                    name="No"
                    type="checkedBox"
                    target=""
                    value={!this.props.agreementData.emailOption}
                  />
                </div>
              </div>
              <span className="span7 pl-1 ">
                if yes, provide email addresses:
              </span>
              <input
                className="ApplicationInputAgg m-0"
                value={
                  this.props.agreementData.documentEmails +
                  ", " +
                  this.props.agreementData.documentEmails2
                }
              ></input>
              <hr className="hrline"></hr>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-0 m-0  halfBreak">
                  <span className="span7 pl-1 ">
                    The landlord is providing phone and/or email contact
                    information for emergencies or day-to-day communications:
                    &nbsp;&nbsp;&nbsp;
                  </span>

                  <div className="row p-1 m-0">
                    <div className="col p-0 m-0 pt-1">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData
                            .landlordEmergencyContactOption
                        }
                      />
                    </div>

                    <div className="col p-0 m-0 pt-1">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target=""
                        value={
                          !this.props.agreementData
                            .landlordEmergencyContactOption
                        }
                      />
                    </div>
                  </div>

                  <div className=" p-0 m-0">
                    <span className="span7  ">
                      If yes, provide information:
                    </span>
                    <input
                      className="ApplicationInputAgg mt-2 "
                      value={this.props.agreementData.landlordEmergencyContact}
                    ></input>
                    <hr className="hrline"></hr>
                  </div>
                </div>
              </div>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    See Part B and E in General Information
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">4. Term of Tenancy Agreement</h>
                </div>
              </div>

              <div className="row p-0 m-0 mt-3">
                <span className="span7 p-0 pt-3">This tenancy starts on: </span>
                <input
                  className="col-3 ApplicationInput m-0 ml-2 p-2"
                  type="date"
                  name=""
                  value={this.props.agreementData.startDate}
                ></input>
              </div>

              <div className="row p-0 m-0 ">
                <span className="span7 pt-3">
                  This tenancy agreement is for: (select an option below and
                  fill in details as needed)
                </span>
              </div>

              <div className="row p-0 m-0 pt-1 ">
                <div className="pt-3">
                  <ApplicationInput
                    name="a fixed length of time ending on:"
                    type="checkedBox"
                    target=""
                    value={
                      this.props.agreementData.agreementType === "fixed"
                        ? true
                        : false
                    }
                  />
                </div>

                <input
                  className="col-3 ApplicationInput1 m-0 "
                  type="date"
                  name=""
                  value={this.props.agreementData.endDate}
                ></input>
              </div>

              <div className="row m-0 p-0 mt-2 ">
                <ApplicationInput
                  name="a monthly tenancy"
                  type="checkedBox"
                  target=""
                  value={
                    this.props.agreementData.agreementType === "monthly"
                      ? true
                      : false
                  }
                />
              </div>

              <div className="row p-0 m-0 mt-2 ">
                <div className="col-5 p-0 m-0">
                  <ApplicationInput
                    name="other (such as daily, weekly, please specify):"
                    type="checkedBox"
                    target=""
                    value={
                      this.props.agreementData.agreementType === "other"
                        ? true
                        : false
                    }
                  />
                </div>
                <div className="col p-0 m-0">
                  <input
                    className=" ApplicationInput1 m-0"
                    value={
                      this.props.agreementData.agreementType === "other"
                        ? this.props.agreementData.otherAgreementType
                        : ""
                    }
                  ></input>
                </div>
              </div>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    See Part B in General Information
                  </span>
                </div>
              </div>
              <br></br>
            </div>

            <div className=" torontoagreementTableBody ">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">5. Rent</h>
                </div>
              </div>

              <div>
                <div className="row p-0 pr-1 m-0 mt-4 ">
                  <span className="span7 m-0 p-0 pt-3 pr-3">
                    a) Rent is to be paid on the
                  </span>
                  <input
                    className=" col-3 ApplicationInput1 m-0 "
                    value={this.props.agreementData.rentCollectionDay}
                  ></input>
                  <span className="span7 p-3">
                    (e.g., first, second, last) day of each (select one):
                  </span>
                </div>
                <div className="row p-2 ml-4 ">
                  <ApplicationInput
                    name="Month"
                    type="checkedBox"
                    target=""
                    value={
                      this.props.agreementData.rentCollectionOnEach === "month"
                        ? true
                        : false
                    }
                  />
                </div>

                <div className="row p-2 ml-4 ">
                  <div className="col-3 p-0 m-0 pt-1">
                    <ApplicationInput
                      name=" Other (e.g., weekly)"
                      type="checkedBox"
                      target=""
                      value={
                        this.props.agreementData.rentCollectionOnEach !==
                        "month"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <input
                    className="col ApplicationInput1 m-0"
                    value={
                      this.props.agreementData.rentCollectionOnEach !== "month"
                        ? this.props.agreementData.rentCollectionOnEach ===
                          "week"
                          ? "week"
                          : this.props.agreementData.rentCollectionOnEachOther
                        : ""
                    }
                  ></input>
                </div>
                <span className="span7 m-0 p-0">
                  b) The tenant will pay the following rent:
                </span>

                <div className="row m-0 p-0 pl-3">
                  <span className="col-8 span7 m-0 p-3">
                    Base rent for the rental unit
                  </span>
                  <div className="col-4">
                    <i
                      className={"fa-usd fa  fa-10x absolute pointer iconL"}
                      style={{ marginLeft: "30px", marginTop: "13px", left: 0 }}
                      aria-hidden="true"
                    ></i>
                    <input
                      className=" ApplicationInputAgg m-0 text-right"
                      value={this.props.agreementData.rent}
                    ></input>
                  </div>
                </div>

                <div className="row m-0 p-0 pl-3 ">
                  <span className="col-8 span7 m-0 p-3">
                    Parking (if applicable)
                  </span>
                  <div className="col-4">
                    <i
                      className={"fa-usd fa  fa-10x absolute pointer iconL"}
                      style={{ marginLeft: "30px", marginTop: "13px", left: 0 }}
                      aria-hidden="true"
                    ></i>
                    <input
                      className=" ApplicationInputAgg m-0 text-right"
                      value={
                        this.props.agreementData.parkingRent === "" ||
                        this.props.agreementData.parkingRent === undefined ||
                        this.props.agreementData.parkingRent === 0
                          ? "0.0"
                          : this.props.agreementData.parkingRent
                      }
                    ></input>
                  </div>
                </div>

                <div className="row m-0 p-0 pl-3 mt-2">
                  <span className="row span7  pl-3  m-0">
                    Other services and utilities (specify if applicable):
                  </span>
                </div>

                <div className="row pl-3 pt-2 m-0">
                  <div className="col-8">
                    <input
                      className="  ApplicationInputAgg m-0"
                      value={
                        this.props.agreementData.rentType[0]
                          ? this.props.agreementData.rentType[0]
                          : "-"
                      }
                    ></input>
                  </div>
                  <div className="col-4">
                    <i
                      className={"fa-usd fa  fa-10x absolute pointer iconL"}
                      style={{ marginLeft: "30px", marginTop: "13px", left: 0 }}
                      aria-hidden="true"
                    ></i>
                    <input
                      className=" ApplicationInputAgg m-0 text-right"
                      value={
                        this.props.agreementData.rentAmount[0]
                          ? this.props.agreementData.rentAmount[0]
                          : "0.0"
                      }
                    ></input>
                  </div>
                </div>

                <div className="row pl-3 pt-2 m-0">
                  <div className="col-8">
                    <input
                      className="  ApplicationInputAgg m-0"
                      value={
                        this.props.agreementData.rentType[1]
                          ? this.props.agreementData.rentType[1]
                          : "-"
                      }
                    ></input>
                  </div>
                  <div className="col-4">
                    <i
                      className={"fa-usd fa  fa-10x absolute pointer iconL"}
                      style={{ marginLeft: "30px", marginTop: "13px", left: 0 }}
                      aria-hidden="true"
                    ></i>
                    <input
                      className=" ApplicationInputAgg m-0 text-right"
                      value={
                        this.props.agreementData.rentAmount[1]
                          ? this.props.agreementData.rentAmount[1]
                          : "0.0"
                      }
                    ></input>
                  </div>
                </div>

                <div className="row pl-3 pt-2 m-0">
                  <div className="col-8">
                    <input
                      className="  ApplicationInputAgg m-0"
                      value={
                        this.props.agreementData.rentType[2]
                          ? this.props.agreementData.rentType[2]
                          : "-"
                      }
                    ></input>
                  </div>
                  <div className="col-4">
                    <i
                      className={"fa-usd fa  fa-10x absolute pointer iconL"}
                      style={{ marginLeft: "30px", marginTop: "13px", left: 0 }}
                      aria-hidden="true"
                    ></i>
                    <input
                      className=" ApplicationInputAgg m-0 text-right"
                      value={
                        this.props.agreementData.rentAmount[2]
                          ? this.props.agreementData.rentAmount[2]
                          : "0.0"
                      }
                    ></input>
                  </div>
                </div>

                <div className="row pl-3 pt-2 m-0">
                  <div className="col-8 text-right">
                    <span className="span7  p-3">Total Rent (Lawful Rent)</span>
                  </div>
                  <div className="col-4">
                    <i
                      className={"fa-usd fa  fa-10x absolute pointer iconL"}
                      style={{ marginLeft: "30px", marginTop: "13px", left: 0 }}
                      aria-hidden="true"
                    ></i>
                    <input
                      className=" ApplicationInputAgg m-0 text-right"
                      value={
                        (this.props.agreementData.rent
                          ? parseInt(this.props.agreementData.rent)
                          : 0.0) +
                        (this.props.agreementData.parkingRent
                          ? parseInt(this.props.agreementData.parkingRent)
                          : 0.0) +
                        (this.props.agreementData.rentAmount[0]
                          ? parseInt(this.props.agreementData.rentAmount[0])
                          : 0.0) +
                        (this.props.agreementData.rentAmount[1]
                          ? parseInt(this.props.agreementData.rentAmount[1])
                          : 0.0) +
                        (this.props.agreementData.rentAmount[2]
                          ? parseInt(this.props.agreementData.rentAmount[2])
                          : 0.0)
                      }
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pt-5 pb-5">
            <div className=" torontoagreementTableBody pb-3">
              <div>
                <div className="row m-0 p-0 pt-3">
                  <div className="col m-0 p-0 pt-3">
                    <span className=" span7 m-0 p-0  ">
                      This is the lawful rent for the unit, subject to any rent
                      increases allowed under the Residential Tenancies Act,
                      2006. For example, the landlord and tenant may agree to a
                      seasonal rent increase for additional services of air
                      conditioning or a block heater plug-in. This amount does
                      not include any rent discounts (see Section 7 and Part G
                      in General Information).
                    </span>
                    <br></br>
                    <span className="row span7 m-0  pt-3 pb-3 ">
                      c) Rent is payable to:
                    </span>
                    <input
                      className="row ApplicationInputAgg m-0"
                      value={this.props.agreementData.rentPayableTo}
                    ></input>

                    <span className="row span7 m-0  pt-3 pb-3">
                      d) Rent will be paid using the following methods:
                    </span>
                    <input
                      className=" ApplicationInputAgg m-0"
                      value={this.props.agreementData.rentPayableMethod}
                    ></input>
                  </div>
                </div>
              </div>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    The tenant cannot be required to pay rent by post-dated
                    cheques or automatic payments, but can choose to do so.
                  </span>
                </div>
              </div>

              <div className="row p-0 m-0 pt-3">
                <span className="span7 pt-3 pr-3">
                  e) If the first rental period (e.g., month) is a partial
                  period, the tenant will pay a partial rent of ${" "}
                </span>
                <input
                  className="col-3 p-0 ApplicationInput1 m-0"
                  value={this.props.agreementData.partialRent}
                ></input>
                <span className="span7 p-3"> on</span>
                <input
                  className="col-3 ApplicationInput1 m-0 mt-2"
                  type="date"
                  name=""
                  value={this.props.agreementData.partialRentDate}
                ></input>
                <span className="span7 p-3">
                  {" "}
                  . This partial rent covers the rental of the unit from
                </span>
                <input
                  className="col-3 ApplicationInput1 m-0 mt-2"
                  type="date"
                  name=""
                  value={this.props.agreementData.partialRentFrom}
                ></input>
                <span className="span7 p-3"> to</span>
                <input
                  className="col-3 ApplicationInput1 m-0 mt-2"
                  type="date"
                  name=""
                  value={this.props.agreementData.partialRentTo}
                ></input>
                <span className="span7 pl-2 pt-5">.</span>
              </div>

              <div className="row span7 p-0 m-0">
                <span className="row span7 pl-0 p-3  ">
                  {" "}
                  f) If the tenant’s cheque is returned because of
                  non-sufficient funds (NSF), the tenant will have to pay the
                  landlord’s administration charge of $
                </span>
                <input
                  className="col-3 ApplicationInput1 m-0"
                  value={this.props.agreementData.lateFeeAmount}
                ></input>
                <span className="span7 p-3">
                  {" "}
                  plus any NSF charges made by the landlord's bank.
                </span>
              </div>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    The landlord’s administration charge for an NSF cheque
                    cannot be more than <CurrencyFormat value={ 20} />
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">6. Services and Utilities</h>
                </div>
              </div>

              <div className="p-0 m-0 mt-2">
                <span className="span7 ">
                  The following services are included in the lawful rent for the
                  rental unit, as specified:
                </span>
              </div>

              <div className="row p-1 m-0">
                <div className="col-5 pt-1">
                  <span className="span7"> Gas</span>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0 ">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={this.props.agreementData.gas}
                      />
                    </div>

                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target=""
                        value={!this.props.agreementData.gas}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-5 pt-2">
                  <span className="span7"> Air conditioning</span>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={this.props.agreementData.airConditioner}
                      />
                    </div>

                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target=""
                        value={!this.props.agreementData.airConditioner}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-1 m-0">
                <div className="col-5 pt-2">
                  <span className="span7"> Additional storage space </span>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={this.props.agreementData.additionalStorage}
                      />
                    </div>

                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target=""
                        value={!this.props.agreementData.additionalStorage}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-5 pt-2">
                  <span className="span7"> On-Site Laundry</span>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0 ">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={this.props.agreementData.laundry}
                      />
                    </div>

                    <div className="col-2 p-0 m-0 ">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target=""
                        value={!this.props.agreementData.laundry}
                      />
                    </div>
                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="No Charge"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.laundryChargeType ===
                          "nocharge"
                        }
                      />
                    </div>

                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Pay Per use"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.laundryChargeType ===
                          "payperuse"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-5 pt-2">
                  <span className="span7"> Guest Parking</span>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0  pt-1">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={this.props.agreementData.guestParking}
                      />
                    </div>

                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="No"
                        type="checkedBox"
                        target=""
                        value={!this.props.agreementData.guestParking}
                      />
                    </div>
                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="No Charge"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.guestParkingChargeType ===
                          "nocharge"
                        }
                      />
                    </div>

                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Pay Per use"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.guestParkingChargeType ===
                          "payperuse"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-5">
                  <div className="row p-0 m-0">
                    <span className="span7 pt-2 pr-2"> Other</span>
                    <input
                      className=" col ApplicationInput m-0"
                      value={this.props.agreementData.serviceType[0]}
                    ></input>
                  </div>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.serviceType[0] ? true : false
                        }
                      />
                    </div>

                    <div className="col-2 p-0 m-0">
                      <ApplicationInput name="No" type="checkedBox" target="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-5">
                  <div className="row p-0 m-0">
                    <span className="span7 pt-2 pr-2"> Other</span>
                    <input
                      className=" col ApplicationInput m-0"
                      value={this.props.agreementData.serviceType[1]}
                    ></input>
                  </div>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.serviceType[1] ? true : false
                        }
                      />
                    </div>

                    <div className="col-2 p-0 m-0">
                      <ApplicationInput name="No" type="checkedBox" target="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-5">
                  <div className="row p-0 m-0">
                    <span className="span7 pt-2 pr-2"> Other</span>
                    <input
                      className=" col ApplicationInput m-0"
                      value={this.props.agreementData.serviceType[2]}
                    ></input>
                  </div>
                </div>
                <div className="col-7">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-2 p-0 m-0">
                      <ApplicationInput
                        name="Yes"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.serviceType[2] ? true : false
                        }
                      />
                    </div>

                    <div className="col-2 p-0 m-0">
                      <ApplicationInput name="No" type="checkedBox" target="" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-4">
                <div className="col">
                  <span className="span7 ">
                    Provide details about services or list any additional
                    services if needed (if necessary add additional pages):{" "}
                  </span>
                  <textarea
                    className=" col ApplicationInputAgg m-0"
                    value={this.props.agreementData.otherServiceDescription}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pt-5 pb-5">
            <div className=" torontoagreementTableBody pb-3">
              <div className="row p-0 m-0">
                <span className="span7 ">
                  The following utilities are the responsibility of:
                </span>
              </div>

              <div className="row p-1 m-0">
                <div className="col-6 pt-2">
                  <span className="span7"> Electricity</span>
                </div>
                <div className="col-6">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Landlord  "
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.electricityResponsible ===
                          "landlord"
                        }
                      />
                    </div>

                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Tenant"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.electricityResponsible ===
                          "tenant"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-6 pt-2">
                  <span className="span7"> Heat</span>
                </div>
                <div className="col-6">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Landlord  "
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.heatResponsible ===
                          "landlord"
                        }
                      />
                    </div>

                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Tenant"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.heatResponsible === "tenant"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-1 m-0">
                <div className="col-6 pt-2">
                  <span className="span7"> Water</span>
                </div>
                <div className="col-6">
                  <div className="row p-0 m-0 pt-1">
                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Landlord  "
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.waterResponsible ===
                          "landlord"
                        }
                      />
                    </div>

                    <div className="col-3 p-0 m-0">
                      <ApplicationInput
                        name="Tenant"
                        type="checkedBox"
                        target=""
                        value={
                          this.props.agreementData.waterResponsible === "tenant"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-0 m-0">
                <div className="col">
                  <span className="span7 ">
                    If the tenant is responsible for any utilities, provide
                    details of the arrangement, e.g. tenant sets up account with
                    and pays the utility provider, tenant pays a portion of the
                    utility costs (if necessary add additional pages):{" "}
                  </span>
                  <textarea
                    className=" col ApplicationInputAgg m-0"
                    value={this.props.agreementData.otherResponsible}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">7. Rent Discounts</h>
                </div>
              </div>

              <div className="col p-0 m-0 pt-2">
                <span className="row span7 p-2 m-0 pl-0">Select one:</span>
                <div className="col-3">
                  <ApplicationInput
                    name="There is no rent discount.                    "
                    type="checkedBox"
                    target=""
                    value={!this.props.agreementData.isRentDiscount}
                  />
                </div>

                <span className="row span7 p-2 m-0 pl-0">or</span>
                <div className="col-3 pb-2">
                  <ApplicationInput
                    name="The lawful rent will be discounted as follows:             "
                    type="checkedBox"
                    target=""
                    value={this.props.agreementData.isRentDiscount}
                  />
                </div>

                <div className="row  p-0 m-0 ">
                  <div className="col">
                    <span className="span7 ">
                      If the tenant is responsible for any utilities, provide
                      details of the arrangement, e.g. tenant sets up account
                      with and pays the utility provider, tenant pays a portion
                      of the utility costs (if necessary add additional pages):{" "}
                    </span>
                    <textarea
                      className=" col ApplicationInputAgg m-0"
                      value={
                        this.props.agreementData.isRentDiscount &&
                        "Discount " +
                          (this.props.agreementData.rentDiscountType ===
                          "percentage"
                            ? "Percentage : " +
                              this.props.agreementData.rentDiscountPercentage
                            : "Amount : " +
                              this.props.agreementData.rentDiscountAmount) +
                          " , " +
                          this.props.agreementData.rentDiscountDescription
                      }
                    ></textarea>
                  </div>
                </div>
                <br></br>

                <div className="  row pt-3  m-0">
                  <div className=" ApplicationNote col p-2 m-0">
                    <span className="span7">
                      <b>Note</b>
                      <br></br>
                      See Part G in General Information for what types of
                      discounts are allowed.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">8. Rent Deposit</h>
                </div>
              </div>

              <div className="col p-0 m-0 pt-2">
                <span className="row span7 p-2 m-0 pl-0">Select one:</span>
                <div className="col">
                  <ApplicationInput
                    name="A rent deposit is not required.           "
                    type="checkedBox"
                    target=""
                    value={!this.props.agreementData.securityDeposit}
                  />
                </div>
                <span className="row span7 p-2 m-0 pl-0">or</span>
                <div className="col pt-2 ml-3">
                  <div className="row ">
                    <ApplicationInput
                      name=" "
                      type="checkedBox"
                      target=""
                      value={this.props.agreementData.securityDeposit}
                    />
                    <span className="span7 pt-2 pr-3 ">
                      The tenant will pay a rent deposit of ${" "}
                    </span>
                    <input
                      className="ApplicationInput2 m-0"
                      value={this.props.agreementData.securityDepositAmount}
                      name="leaseTerm"
                    ></input>
                    <span className="span7 p-2 ">
                      This can only be applied to the rent for the last rental
                      period of the tenancy.{" "}
                    </span>
                  </div>
                </div>
                <div className="  row pt-3  m-0">
                  <div className=" ApplicationNote col p-2 m-0">
                    <span className="span7">
                      <b>Note</b>
                      <br></br>
                      This amount cannot be more than one month’s rent or the
                      rent for one rental period (e.g., one week in a weekly
                      tenancy), whichever is less. This cannot be used as a
                      damage deposit. The landlord must pay the tenant interest
                      on the rent deposit every year. See Part H in General
                      Information.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pt-5 pb-5">
            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">9. Key Deposit</h>
                </div>
              </div>

              <span className="row span7 pt-3  m-0 ">Select one:</span>
              <div className="row-3 p-2">
                <ApplicationInput
                  name="A key deposit is not required.                  "
                  type="checkedBox"
                  value={!this.props.agreementData.keyDeposit}
                  target=""
                />
              </div>

              <span className="row span7  m-0 pl-0">or</span>
              <div className="row  pt-2 p-2">
                <div className="col ">
                  <ApplicationInput
                    name=""
                    type="checkedBox"
                    target=""
                    value={this.props.agreementData.keyDeposit}
                  />
                  <span className="span7  align-self-end text-center">
                    The tenant will pay a refundable key deposit of ${" "}
                  </span>

                  <input
                    className="ApplicationInput2 m-0  ml-2 fixedInputSize"
                    name="agreementSum"
                    value={this.props.agreementData.keyDepositAmount}
                    type="number"
                  ></input>
                  <span
                    className="span7  text-justify "
                    style={{ lineHeight: "25px" }}
                  >
                    to cover the cost of replacing the keys, remote entry
                    devices or cards if they are not returned to the landlord at
                    the end of the tenancy.
                  </span>
                </div>
              </div>

              <span className="span7 pl-1">
                If a refundable key deposit is required, provide description and
                number of keys, access cards and remote entry devices:{" "}
              </span>
              <textarea
                className=" col ApplicationInputAgg m-0"
                value={this.props.agreementData.keyDepositDescription}
              ></textarea>
              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    The key deposit cannot be more than the expected replacement
                    cost. See Part H in General Information.
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">10. Smoking</h>
                </div>
              </div>

              <span className="row span7 p-0 m-0 mt-3">
                Under provincial law, smoking is not allowed in any indoor
                common areas of the building. The tenant agrees to these
                additional rules on smoking:
              </span>
              <span className="span7 pl-1">Select one:</span>
              <div className="col-3 m-0 p-2">
                <ApplicationInput
                  name="  None                    "
                  type="checkedBox"
                  target=""
                  value={!this.props.agreementData.smoking}
                />
              </div>

              <span className="span7 pl-1">or</span>
              <div className="col-3 m-0 p-2">
                <ApplicationInput
                  name="Smoking rules "
                  type="checkedBox"
                  target=""
                  value={this.props.agreementData.smoking}
                />
              </div>

              <div className="col">
                <span className="span7 ">
                  Provide description of smoking rules (if necessary add
                  additional pages):
                </span>
                <textarea
                  className=" col ApplicationInputAgg m-0"
                  value={this.props.agreementData.smokingDescription}
                ></textarea>
              </div>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    In making and enforcing smoking rules, the landlord must
                    follow the Ontario Human Rights Code. See Parts M and S in
                    General Information.
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">11. Tenant’s Insurance</h>
                </div>
              </div>

              <span className="span7 pl-1">Select one:</span>
              <div className="col-3">
                <ApplicationInput
                  name="There are no tenant insurance requirements.                    "
                  type="checkedBox"
                  target=""
                  value={this.props.agreementData.tenantInsurance}
                />
              </div>

              <span className="span7 pl-1">or</span>
              <div className="row  m-0 p-0">
                <div
                  className="pl-3
              "
                >
                  <ApplicationInput
                    name="
                    "
                    type="checkedBox"
                    target=""
                    value={!this.props.agreementData.tenantInsurance}
                  />
                </div>

                <span className="col span7 p-2">
                  The tenant must have liability insurance at all times. If the
                  landlord asks for proof of coverage, the tenant must provide
                  it. It is up to the tenant to get contents insurance if they
                  want it.
                </span>
              </div>
            </div>

            <div className=" torontoagreementTableBody">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">12. Changes to the Rental Unit</h>
                </div>
              </div>

              <span className=" span7 p-0 m-0 mt-3">
                The tenant may install decorative items, such as pictures or
                window coverings. This is subject to any reasonable restrictions
                set out in the additional terms under Section 15. <br></br>
                <br></br>
                The tenant cannot make other changes to the rental unit without
                the landlord’s permission
              </span>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pt-5 pb-5">
            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">13. Maintenance and Repairs</h>
                </div>
              </div>

              <span className="span7 ">
                The landlord must keep the rental unit and property in good
                repair and comply with all health, safety and maintenance
                standards. The tenant must repair or pay for any undue damage to
                the rental unit or property caused by the wilful or negligent
                conduct of the tenant, the tenant’s guest or another person who
                lives in the rental unit.
                <br></br>
                The tenant is responsible for ordinary cleanliness of the rental
                unit, except for any cleaning the landlord agreed to do.
              </span>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    See Part J in General Information.
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">14. Assignment and Subletting</h>
                </div>
              </div>

              <span className="span7 pl-1">
                The tenant may assign or sublet the rental unit to another
                person only with the consent of the landlord. The landlord
                cannot arbitrarily or unreasonably withhold consent to a sublet
                or potential assignee.
              </span>
              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    See Part J in General Information.
                  </span>
                </div>
              </div>
            </div>

            <div className=" torontoagreementTableBody pb-3">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">15. Additional Terms</h>
                </div>
              </div>

              <div className="p-0 m-0 pt-2">
                <span className="m-0 p-0  span7">
                  Landlords and tenants can agree to additional terms. Examples
                  may include terms that:
                </span>
                <div className="p-3 m-0 pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      Require the landlord to make changes to the unit before
                      the tenant moves in, and
                    </li>
                    <li className="p-2">
                      Provide rules for use of common spaces and/or amenities.
                    </li>
                  </span>
                </div>
                <span className="span7 p-0 m-0">
                  These additional terms should be written in plain language and
                  clearly set out what the landlord or tenant must or must not
                  do to comply with the term. If typed, the additional terms
                  should be in a font size that is at least 10 points. <br></br>{" "}
                  <br></br>
                  An additional term cannot take away a right or responsibility
                  under the Residential Tenancies Act, 2006. <br></br> <br></br>
                  If a term conflicts with the Residential Tenancies Act, 2006
                  or any other terms set out in this form, the term is void (not
                  valid or legally binding) and it cannot be enforced. Some
                  examples of void and unenforceable terms include those that:
                </span>

                <div className="p-3 m-0 pt-2">
                  <span className="span7 ul p-0 m-0">
                    <li className="p-2">
                      Do not allow pets (however, the landlord can require the
                      tenant to comply with condominium rules, which may
                      prohibit certain pets),{" "}
                    </li>
                    <li className="p-2">
                      Do not allow guests, roommates, any additional occupants,
                    </li>

                    <div className="">
                      <li className=" p-2 m-0">
                        <span className="col p-0 m-0">
                          Require the tenant to pay deposits, fees or penalties
                          that are not permitted under the Residential Tenancies
                          Act 2006 (e.g., damage or pet deposits, interest on
                          rent arrears), and
                        </span>
                      </li>
                    </div>

                    <li className="p-2">
                      Require the tenant to pay for all or part of the repairs
                      that are the responsibility of the landlord.{" "}
                    </li>
                  </span>
                </div>
                <span className="span7 p-0 m-0">
                  See General Information for more details. <br></br>
                  The landlord and tenant may want to get legal advice before
                  agreeing to any additional terms
                </span>
              </div>

              <span className="span7 pl-1">Select one:</span>
              <div className="col-3">
                <ApplicationInput
                  name="There are no additional terms.                    "
                  type="checkedBox"
                  target=""
                  value={!this.props.agreementData.isAdditionalTerms}
                />
              </div>

              <span className="span7 pl-1">or</span>
              <div className="col-3">
                <ApplicationInput
                  name="This tenancy agreement includes an attachment with additional terms that the landlord and tenant agreed to.                    "
                  type="checkedBox"
                  target=""
                  value={this.props.agreementData.isAdditionalTerms}
                />
              </div>
            </div>

            <div className=" torontoagreementTableBody">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">16. Changes to this Agreement</h>
                </div>
              </div>

              <span className="span7 pl-1">
                After this agreement is signed, it can be changed only if the
                landlord and tenant agree to the changes in writing.
              </span>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    See Part J in General Information.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="applicationAgreement ">
          <div className="card pt-5 pb-5">
            <div className=" torontoagreementTableBody">
              <div className="row torontoagreementTitleBar p-0 m-0">
                <div className="col p-0 m-0">
                  <h className="p-0 m-2">17. Signatures</h>
                </div>
              </div>

              <div className="">
                <span className="span7 ">
                  By signing this agreement, the landlord(s) and the tenant(s)
                  agree to follow its terms. The landlord(s) or tenant(s) can
                  sign this lease electronically if they both agree. <br></br>
                  Unless otherwise agreed in the additional terms under Section
                  15, if there is more than one tenant, each tenant is
                  responsible for all tenant obligations under this agreement,
                  including the full amount of rent. <br></br>
                  <br></br>
                  <b>Landlord(s):</b>
                </span>
              </div>
              <div className="row p-0 m-0  halfBreak">
                <div className="col  m-0 p-1  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1 m-0">Name </span>
                  <input
                    className="ApplicationInput m-0"
                    value={
                      this.props.landlordData &&
                      this.props.landlordData.firstName +
                        " " +
                        this.props.landlordData.middleName +
                        " " +
                        this.props.landlordData.lastName
                    }
                  ></input>
                </div>

                <div className="col  m-0 p-1  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Signature</span>
                  <input className="ApplicationInput m-0"></input>
                </div>
                <div className="col p-1 m-0 halfBreak agreementBoder  ">
                  <span className="span7 pl-1">Date</span>
                  <input
                    className="col ApplicationInput m-0 "
                    type="text"
                    name=""
                    value={this.today()}
                  ></input>
                </div>
              </div>

              <div className="col m-0 p-0 pt-2">
                <span className="span7 ">
                  <b>Tenant(s):</b>
                </span>
              </div>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Name </span>
                  <input
                    className="ApplicationInput m-0"
                    value={
                      this.props.applicantData
                        ? this.props.applicantData[0] &&
                          this.props.applicantData[0].firstName +
                            " " +
                            this.props.applicantData[0].lastName
                        : ""
                    }
                  ></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Signature</span>
                  <input className="ApplicationInput m-0"></input>
                </div>
                <div className="col p-1 m-0 halfBreak agreementBoder  ">
                  <span className="span7 pl-1">Date</span>
                  <input
                    className="col ApplicationInput m-0 "
                    type="text"
                    name=""
                    value={
                      this.props.applicantData
                        ? this.props.applicantData[0] && this.today()
                        : ""
                    }
                  ></input>
                </div>
              </div>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Name </span>
                  <input
                    className="ApplicationInput m-0"
                    value={
                      this.props.applicantData
                        ? this.props.applicantData[1] &&
                          this.props.applicantData[1].firstName +
                            " " +
                            this.props.applicantData[1].lastName
                        : ""
                    }
                  ></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Signature</span>
                  <input className="ApplicationInput m-0"></input>
                </div>
                <div className="col p-1 m-0  agreementBoder ">
                  <span className="span7 pl-1">Date</span>
                  <input
                    className="col ApplicationInput m-0"
                    type="text"
                    name=""
                    value={
                      this.props.applicantData
                        ? this.props.applicantData[1] && this.today()
                        : ""
                    }
                  ></input>
                </div>
              </div>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Name </span>
                  <input
                    className="ApplicationInput m-0"
                    value={
                      this.props.applicantData
                        ? this.props.applicantData[2] &&
                          this.props.applicantData[2].firstName +
                            " " +
                            this.props.applicantData[2].lastName
                        : ""
                    }
                  ></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Signature</span>
                  <input className="ApplicationInput m-0"></input>
                </div>
                <div className="col p-1 m-0  agreementBoder ">
                  <span className="span7 pl-1">Date</span>
                  <input
                    className="col ApplicationInput m-0 "
                    type="text"
                    name=""
                    value={
                      this.props.applicantData
                        ? this.props.applicantData[2] && this.today()
                        : ""
                    }
                  ></input>
                </div>
              </div>

              <div className="row p-0 m-0  halfBreak">
                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Name </span>
                  <input className="ApplicationInput m-0"></input>
                </div>

                <div className="col p-1 m-0  halfBreak agreementBoder agreementRightBoder">
                  <span className="span7 pl-1">Signature</span>
                  <input className="ApplicationInput m-0"></input>
                </div>
                <div className="col p-1 m-0  agreementBoder ">
                  <span className="span7 pl-1">Date</span>
                  <input
                    className="col ApplicationInput m-0 "
                    type="text"
                    name=""
                  ></input>
                </div>
              </div>

              <div className="  row pt-3  m-0">
                <div className=" ApplicationNote col p-2 m-0">
                  <span className="span7">
                    <b>Note</b>
                    <br></br>
                    All of the landlords and tenants listed on the first page in
                    Section 1 (Parties to the Agreement) must sign here. The
                    landlord must give a copy of this agreement to the tenant
                    within 21 days after the tenant signs it.{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TorontoReview;
