import React, { Component } from "react";
import HalfPie from "../../UIServices/PieChart/HalfPie";
import image1 from "../../../Images/profile.png";
import ColumnChart from "../../UIServices/ColumnChart.js/ColumnChart";
import {
  checkPaymentAccountDetail,
  createPaymentAccount,
  stripeLogin,
} from "../../../Services/ApiServices";
import Loader2 from "../../Loader/Loader2";
import Loader3 from "../../Loader/loader3";
import { connect } from "react-redux";
import { setAccountPaymentStatus, appendAlert } from "../../../actions";
import { withRouter } from "react-router-dom";

class PaymentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inCome: [
        {
          monthYear: "2022-01",
          rent: 20000,
          lateFee: 71000,
          other: 12000,
          disable: true,
        },
        {
          monthYear: "2022-02",
          rent: 47000,
          lateFee: 83000,
          other: 19000,
          disable: true,
        },
        {
          monthYear: "2022-03",
          rent: 68000,
          lateFee: 75000,
          other: 26000,
          disable: true,
        },
        {
          monthYear: "2022-04",
          rent: 58000,
          lateFee: 75000,
          other: 37000,
          disable: true,
        },
        {
          monthYear: "2022-05",
          rent: 42000,
          lateFee: 63000,
          other: 22000,
          disable: true,
        },
        {
          monthYear: "2022-06",
          rent: 95000,
          lateFee: 31000,
          other: 55000,
          disable: true,
        },
        {
          monthYear: "2022-07",
          rent: 29000,
          lateFee: 55000,
          other: 12000,
          disable: true,
        },
        {
          monthYear: "2022-08",
          rent: 33000,
          lateFee: 25000,
          other: 75000,
          disable: true,
        },
        {
          monthYear: "2022-09",
          rent: 24000,
          lateFee: 75000,
          other: 12000,
          disable: true,
        },
        {
          monthYear: "2022-10",
          rent: 55000,
          lateFee: 35000,
          other: 92000,
          disable: true,
        },
        {
          monthYear: "2022-11",
          rent: 59000,
          lateFee: 65000,
          other: 52000,
          disable: true,
        },
        {
          monthYear: "2022-12",
          rent: 75000,
          lateFee: 15000,
          other: 72000,
          disable: true,
        },
      ],
      paymentMonth: new Date().getMonth(),
      paymentYear: new Date().getFullYear(),
      years: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      balance: 0,
      isLoadingStipe: false,
      mainLoader: true,
    };
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  async componentDidMount() {
    var x = [];
    for (var i = parseInt(new Date().getFullYear()); i > 1900; i--) {
      x.push(i);
    }
    this.setState({ years: x });
    if (this.props.accountPaymentStatus !== null) {
      this.setState({ mainLoader: false });
    }
    await checkPaymentAccountDetail()
      .then((response) => {
        if (response.status === 200) {
          this.props.setAccountPaymentStatus(
            response.data.capabilities.card_payments
          );
        }
        this.setState({ mainLoader: false });
      })
      .catch((err) => {
        this.setState({ mainLoader: false });
      });
  }
  openLink(path) {
    window.location.href = path;
  }

  render() {
    return (
      <>
        <div className="row mb-4">
          <div className="col-md paymentDashCard ">
            {this.props.paymentDashboardDataArray === null ? (
              <>
                <h1
                  className={
                    this.props.dark
                      ? "paymentHead text-dark  pl-3"
                      : "paymentHead text-dark pl-3"
                  }
                >
                  Axallant Charge
                </h1>
                <div style={{ marginTop: "-32px" }}>
                  <Loader2 />
                </div>
              </>
            ) : (
              <>
                <HalfPie
                  name="axallantStatus"
                  dark={this.props.dark}
                  indicator={this.props.paymentDashboardDataArray !== null}
                  data={[
                    {
                      start: 0,
                      end:
                        this.props.paymentDashboardDataArray !== null
                          ? (this.props.paymentDashboardDataArray
                              .axallantChargeMonth /
                              this.props.paymentDashboardDataArray
                                .totalInflowMonth) *
                            180
                          : 0,
                      title: "",
                      color: "#4cb38e",
                    },
                    {
                      start:
                        this.props.paymentDashboardDataArray !== null
                          ? (this.props.paymentDashboardDataArray
                              .axallantChargeMonth /
                              this.props.paymentDashboardDataArray
                                .totalInflowMonth) *
                            180
                          : 0,
                      end: 180,
                      title: "",
                      color: "#eee36b",
                    },
                  ]}
                  centerText={
                    isNaN(
                      Math.round(
                        this.props.paymentDashboardDataArray !== null
                          ? (this.props.paymentDashboardDataArray
                              .axallantChargeMonth /
                              this.props.paymentDashboardDataArray
                                .totalInflowMonth) *
                              100
                          : 0
                      )
                    )
                      ? 0 + "%"
                      : Math.round(
                          this.props.paymentDashboardDataArray !== null
                            ? (this.props.paymentDashboardDataArray
                                .axallantChargeMonth /
                                this.props.paymentDashboardDataArray
                                  .totalInflowMonth) *
                                100
                            : 0
                        ) + "%"
                  }
                  bottomOption={[
                    {
                      num:
                        this.props.paymentDashboardDataArray !== null
                          ? "$ " +
                            this.props.paymentDashboardDataArray
                              .axallantChargeMonth
                          : "$ " + 0,
                      text: "Complete",
                    },
                    {
                      num:
                        this.props.paymentDashboardDataArray !== null
                          ? "$ " +
                            (this.props.paymentDashboardDataArray
                              .totalInflowMonth -
                              this.props.paymentDashboardDataArray
                                .axallantChargeMonth)
                          : "$ ",
                      text: "Rent Due",
                    },
                  ]}
                  title="Axallant Charge"
                />
                <div
                  className="pl-3 pr-3 pt-3"
                  // style={{ display: this.props.paymentDashboardDataArray.totalInflowMonth === 0 && "none" }}
                >
                  <img
                    alt={"profile"}
                    src={image1}
                    className="paymentImage round mr-2"
                  />
                  <img
                    alt={"profile"}
                    src={image1}
                    className="paymentImage round  mr-2"
                  />
                  <label className="roundPaymentProfile ">+12</label>
                  <label className="roundPaymentProfileBudge pull-right">
                    Remind{" "}
                    <label className="roundPaymentProfileButton ">
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                    </label>
                  </label>
                </div>
              </>
            )}
          </div>
          <div className="col  paymentDashCardCenterMain">
            <div className="row pl-3 pr-2">
              <div className=" pr-3  curve1 pt-3">
                {" "}
                <h1
                  className={
                    this.props.dark
                      ? "paymentHead text-dark  pl-3 paymentHeadCenter"
                      : "paymentHead text-dark pl-3 paymentHeadCenter"
                  }
                >
                  Incoming Overview
                </h1>
              </div>
              <div className="col curve2 ">
                {" "}
                <div className=" curve3 pr-2  ">
                  {this.state.mainLoader ? (
                    <></>
                  ) : this.props.accountPaymentStatus === null &&
                    this.props.UserData.stripeAccountId !== null &&
                    this.props.UserData.stripeAccountId !== "" ? (
                    <></>
                  ) : this.props.accountPaymentStatus === "inactive" ||
                    this.props.accountPaymentStatus === "pending" ||
                    (this.props.accountPaymentStatus === null &&
                      (this.props.UserData.stripeAccountId === null ||
                        this.props.UserData.stripeAccountId === "")) ? (
                    <>
                      <button
                        className="pull-right ml-3 "
                        disabled={this.state.isLoadingStipe}
                        onClick={() => {
                          this.setState({ isLoadingStipe: true });
                          checkPaymentAccountDetail().then((response) => {
                            if (response.status === 200) {
                              if (
                                response.data.capabilities.card_payments ===
                                "active"
                              ) {
                                this.props.setAccountPaymentStatus(
                                  response.data.capabilities.card_payments
                                );
                              } else if (
                                response.data.capabilities.card_payments ===
                                "pending"
                              ) {
                                this.setState({ isLoadingStipe: false });
                                this.props.setAccountPaymentStatus(
                                  response.data.capabilities.card_payments
                                );
                                this.props.appendAlert(
                                  "Please wait while your request is being processed",
                                  "warning"
                                );
                              } else {
                                this.props.setAccountPaymentStatus(
                                  response.data.capabilities.card_payments
                                );
                                createPaymentAccount("EXPRESS").then(
                                  async (response) => {
                                    if (response.status === 200) {
                                      await this.openLink(response.data.data);
                                      this.setState({ isLoadingStipe: false });
                                    } else {
                                      this.props.appendAlert(
                                        "Something went wrong!, Please try again later.",
                                        "danger"
                                      );
                                      this.setState({ isLoadingStipe: false });
                                    }
                                  }
                                );
                              }
                            } else {
                              this.props.appendAlert(
                                "Something went wrong!, Please try again later.",
                                "danger"
                              );
                            }
                          });
                        }}
                      >
                        Add Stripe
                      </button>
                      {this.state.isLoadingStipe && (
                        <Loader3 transY="50px" transX="1px" />
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <button
                        className="pull-right sample ml-3 "
                        onClick={() => {
                          this.setState({ isLoading: true });
                          stripeLogin().then((response) => {
                            if (response.status === 200) {
                              window.open(response.data.data);
                            } else {
                              this.props.appendAlert(
                                "Something went wrong!, Please try again later.",
                                "danger"
                              );
                            }
                            this.setState({ isLoading: false });
                          });
                        }}
                      >
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 225.000000 225.000000"
                          width="15px"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <g
                            transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
                            fill="#fff"
                            stroke="none"
                          >
                            <path
                              d="M1075 2240 c-446 -38 -715 -294 -715 -680 0 -179 38 -278 150 -391
101 -102 181 -143 551 -283 171 -65 229 -118 229 -210 0 -43 -4 -53 -38 -86
-29 -29 -50 -40 -97 -50 -154 -32 -440 26 -683 140 -47 22 -86 40 -88 40 -2 0
-4 -130 -4 -288 l0 -288 71 -27 c335 -131 764 -146 1036 -37 274 110 425 328
425 614 0 187 -47 310 -162 427 -99 99 -218 161 -493 255 -71 25 -146 53 -167
64 -94 48 -132 110 -111 181 25 82 117 109 306 91 165 -17 349 -67 473 -131
l42 -22 -2 288 -3 288 -100 33 c-180 60 -427 88 -620 72z"
                            />
                          </g>
                        </svg>{" "}
                        &nbsp;&nbsp;|&nbsp;&nbsp; Login{" "}
                      </button>
                      {/* <h1
                        className={
                          this.props.dark
                            ? "paymentHead text-dark text-right pt-2 addStripePayment"
                            : "paymentHead  text-right pt-2 addStripePayment"
                        }
                      >
                        <span className="text-right stripeBalance">
                          Stripe Balance: &nbsp;&nbsp;&nbsp;
                        </span>{" "}
                        <span
                          className="text-right  stripeBalance2"
                          style={{ fontWeight: "200" }}
                        >
                          {"$" +
                            this.state.balance.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </span>
                        &nbsp;
                      </h1> */}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="paymentDashCardCenter">
              {this.props.paymentDashboardDataArray === null ? (
                <div style={{ marginTop: "-32px" }}>
                  <Loader2 />
                </div>
              ) : (
                <ColumnChart
                  data={this.props.paymentDashboardDataArray.rentalSumarry}
                  months={this.state.months}
                  years={this.state.years}
                  paymentYear={this.state.paymentYear}
                  paymentMonth={this.state.paymentMonth}
                  onChange={this.onChange}
                />
              )}
            </div>
          </div>
          <div className="col-md paymentDashCard">
            {this.props.paymentDashboardDataArray === null ? (
              <>
                <h1
                  className={
                    this.props.dark
                      ? "paymentHead text-dark  pl-3"
                      : "paymentHead text-dark pl-3"
                  }
                >
                  Rent Status
                </h1>
                <div style={{ marginTop: "-32px" }}>
                  <Loader2 />
                </div>
              </>
            ) : (
              <>
                <HalfPie
                  name="rentStatus"
                  indicator={this.props.paymentDashboardDataArray !== null}
                  dark={this.props.dark}
                  data={[
                    {
                      start: 0,
                      end:
                        this.props.paymentDashboardDataArray !== null
                          ? (this.props.paymentDashboardDataArray
                              .collectedRentMonth /
                              this.props.paymentDashboardDataArray
                                .totalRentMonth) *
                            180
                          : 0,
                      title: "",
                      color: "#4cb38e",
                    },
                    {
                      start:
                        this.props.paymentDashboardDataArray !== null
                          ? (this.props.paymentDashboardDataArray
                              .collectedRentMonth /
                              this.props.paymentDashboardDataArray
                                .totalRentMonth) *
                            180
                          : 0,
                      end: 180,
                      title: "",
                      color: "#eee36b",
                    },
                  ]}
                  centerText={
                    isNaN(
                      Math.round(
                        this.props.paymentDashboardDataArray !== null
                          ? (this.props.paymentDashboardDataArray
                              .collectedRentMonth /
                              this.props.paymentDashboardDataArray
                                .totalRentMonth) *
                              100
                          : 0
                      )
                    )
                      ? 0 + "%"
                      : Math.round(
                          this.props.paymentDashboardDataArray !== null
                            ? (this.props.paymentDashboardDataArray
                                .collectedRentMonth /
                                this.props.paymentDashboardDataArray
                                  .totalRentMonth) *
                                100
                            : 0
                        ) + "%"
                  }
                  bottomOption={[
                    {
                      num:
                        this.props.paymentDashboardDataArray !== null
                          ? "$ " +
                            this.props.paymentDashboardDataArray
                              .collectedRentMonth
                          : "$ " + 0,
                      text: "Collected",
                    },
                    {
                      num:
                        this.props.paymentDashboardDataArray !== null
                          ? "$ " +
                            (this.props.paymentDashboardDataArray
                              .totalRentMonth -
                              this.props.paymentDashboardDataArray
                                .collectedRentMonth)
                          : "$ " + 0,
                      text: "Pending",
                    },
                  ]}
                  title="Rent Status"
                />
                <div className="pl-4 pt-3 text-center">
                  <p className="paymentText4 ">
                    Total rent:
                    <span className="paymentText3">
                      $
                      {this.props.paymentDashboardDataArray !== null
                        ? this.props.paymentDashboardDataArray.totalRentMonth
                        : 0}
                    </span>
                    <i
                      className="fa fa-line-chart text-success"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md paymentDashCardMobile ">
          {this.props.paymentDashboardDataArray === null ? (
              <>
                <h1
                  className={
                    this.props.dark
                      ? "paymentHead text-dark  pl-3"
                      : "paymentHead text-dark pl-3"
                  }
                >
                  Axallant Charge
                </h1>
                <div style={{ marginTop: "-32px" }}>
                  <Loader2 />
                </div>
              </>
            ) : (
              <>
            <HalfPie
              name="axallantStatus"
              dark={this.props.dark}
              indicator={this.props.paymentDashboardDataArray !== null}
              data={[
                {
                  start: 0,
                  end:
                    this.props.paymentDashboardDataArray !== null
                      ? (this.props.paymentDashboardDataArray
                          .axallantChargeMonth /
                          this.props.paymentDashboardDataArray
                            .totalInflowMonth) *
                        180
                      : 0,
                  title: "",
                  color: "#4cb38e",
                },
                {
                  start:
                    this.props.paymentDashboardDataArray !== null
                      ? (this.props.paymentDashboardDataArray
                          .axallantChargeMonth /
                          this.props.paymentDashboardDataArray
                            .totalInflowMonth) *
                        180
                      : 0,
                  end: 180,
                  title: "",
                  color: "#eee36b",
                },
              ]}
              centerText={
                isNaN(
                  Math.round(
                    this.props.paymentDashboardDataArray !== null
                      ? (this.props.paymentDashboardDataArray
                          .axallantChargeMonth /
                          this.props.paymentDashboardDataArray
                            .totalInflowMonth) *
                          100
                      : 0
                  )
                )
                  ? 0 + "%"
                  : Math.round(
                      this.props.paymentDashboardDataArray !== null
                        ? (this.props.paymentDashboardDataArray
                            .axallantChargeMonth /
                            this.props.paymentDashboardDataArray
                              .totalInflowMonth) *
                            100
                        : 0
                    ) + "%"
              }
              bottomOption={[
                {
                  num:
                        this.props.paymentDashboardDataArray !== null
                          ? "$ " +
                            this.props.paymentDashboardDataArray
                              .axallantChargeMonth
                          : "$ " + 0,
                      text: "Complete",
                },
                {
                  num:
                  this.props.paymentDashboardDataArray !== null
                    ? "$ " +
                      (this.props.paymentDashboardDataArray
                        .totalInflowMonth -
                        this.props.paymentDashboardDataArray
                          .axallantChargeMonth)
                    : "$ ",
                text: "Rent Due",
                },
              ]}
              title="Axallant Charge"
            />
            <div className="pl-3 pr-3 pt-3">
              <img
                alt={"profile"}
                src={image1}
                className="paymentImage round mr-2"
              />
              <img
                alt={"profile"}
                src={image1}
                className="paymentImage round  mr-2"
              />
              <label className="roundPaymentProfile ">+12</label>
              <label className="roundPaymentProfileBudge pull-right">
                Remind{" "}
                <label className="roundPaymentProfileButton ">
                  <i
                    className="fa fa-angle-double-right"
                    aria-hidden="true"
                  ></i>
                </label>
              </label>
            </div>
            </>
            )}
          </div>
          <div className="col-md paymentDashCardMobile">
          {this.props.paymentDashboardDataArray === null ? (
              <>
                <h1
                  className={
                    this.props.dark
                      ? "paymentHead text-dark  pl-3"
                      : "paymentHead text-dark pl-3"
                  }
                >
                  Rent Status
                </h1>
                <div style={{ marginTop: "-32px" }}>
                  <Loader2 />
                </div>
              </>
            ) : (<>
            <HalfPie
              name="rentStatus"
              dark={this.props.dark}
              indicator={this.props.paymentDashboardDataArray !== null}
              data={[
                {
                  start: 0,
                  end:
                    this.props.paymentDashboardDataArray !== null
                      ? (this.props.paymentDashboardDataArray
                          .collectedRentMonth /
                          this.props.paymentDashboardDataArray.totalRentMonth) *
                        180
                      : 0,
                  title: "",
                  color: "#4cb38e",
                },
                {
                  start:
                    this.props.paymentDashboardDataArray !== null
                      ? (this.props.paymentDashboardDataArray
                          .collectedRentMonth /
                          this.props.paymentDashboardDataArray.totalRentMonth) *
                        180
                      : 0,
                  end: 180,
                  title: "",
                  color: "#eee36b",
                },
              ]}
              centerText={
                isNaN(
                  Math.round(
                    this.props.paymentDashboardDataArray !== null
                      ? (this.props.paymentDashboardDataArray
                          .collectedRentMonth /
                          this.props.paymentDashboardDataArray.totalRentMonth) *
                          100
                      : 0
                  )
                )
                  ? 0 + "%"
                  : Math.round(
                      this.props.paymentDashboardDataArray !== null
                        ? (this.props.paymentDashboardDataArray
                            .collectedRentMonth /
                            this.props.paymentDashboardDataArray
                              .totalRentMonth) *
                            100
                        : 0
                    ) + "%"
              }
              bottomOption={[
                {
                  num:
                    this.props.paymentDashboardDataArray !== null
                      ? "$ " +
                        this.props.paymentDashboardDataArray.collectedRentMonth
                      : "$ " + 0,
                  text: "Collected",
                },
                {
                  num:
                    this.props.paymentDashboardDataArray !== null
                      ? "$ " +
                        (this.props.paymentDashboardDataArray.totalRentMonth -
                          this.props.paymentDashboardDataArray
                            .collectedRentMonth)
                      : "$ " + 0,
                  text: "Pending",
                },
              ]}
              title="Rent Status"
            />
            <div className="pl-4 pt-3 text-center">
              <p className="paymentText4 ">
                Total rent:
                <span className="paymentText3">
                  $
                  {this.props.paymentDashboardDataArray !== null
                    ? this.props.paymentDashboardDataArray.totalRentMonth
                    : 0}
                </span>
                <i
                  className="fa fa-line-chart text-success"
                  aria-hidden="true"
                ></i>
              </p>
            </div>
            </>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountPayment: state.accountPaymentReducer,
    accountPaymentStatus: state.accountPayment,
    UserData: state.user,
    paymentDashboardData: state.paymentDashboardDataReducer,
    paymentDashboardDataArray: state.paymentDashboardData,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert, setAccountPaymentStatus })(
    PaymentDashboard
  )
);
