import React from "react";
import CurrencyFormat from "../../../UIServices/CurrencyFormat";
import ProfileCard from "../profile/ProfileCard";

const RentalRow = (props) => {
  const {
    id,
    unitName,
    tenantName,
    tenantEmail,
    phoneNo,
    rentReceived,
    LastPaymentDate,
    ContractStartDate,
    ContractEndDate,
    No_Maintains,
    imagePath,
    application_Fee,
    security_dep,
    parking_reservation,
    Key_Dep,
    pet_dep,
    transactionType,
  } = props.data;
  const profileData = {
    tenantName,
    tenantEmail,
    phoneNo,
    imagePath,
    application_Fee,
    security_dep,
    parking_reservation,
    Key_Dep,
    pet_dep,
    ContractStartDate,
    ContractEndDate,
  };
  return (
    <tr className="rowTable">
      <td style={{ width: "5%",minWidth:"50px" }}>
        <b>{id}</b>
      </td>
      <td style={{ width: "15%" ,minWidth:"50px"}}>{unitName}</td>
      <td style={{ width: "15%",minWidth:"100px" }}>{tenantName}</td>
      <td style={{ width: "25%",minWidth:"240px"  }}>
        <div className="row">
          <div className="col-4">
            <span
              className={
                transactionType === "rent"
                  ? "badge currency text-light"
                  : transactionType === "security"
                  ? "badge pet text-light"
                  : transactionType === "pet"
                  ? "badge tenant text-light"
                  : transactionType === "mail box"
                  ? "badge mail text-light"
                  : "badge application text-light"
              }
            >
              <CurrencyFormat value={ rentReceived} />
             &nbsp;&nbsp;
              <span className="badge currency bg-dark">{transactionType}</span>{" "}
            </span>
          </div>
          <div className="col">
            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
          </div>
          <div className="col-6">
            <i className="fa fa-calendar text-Secondary" aria-hidden="true"></i>
            &nbsp;{LastPaymentDate}
          </div>
        </div>
      </td>
      <td style={{ width: "10%" }} className="text-center">
        {" "}
        <span className="badge badge-dark ">{No_Maintains}</span>
      </td>
      <td style={{ width: "5%" }}>
        <ProfileCard data={profileData} />{" "}
      </td>
    </tr>
  );
};

export default RentalRow;
