// @autor Karunaaharan Bavaram

import React, { Component } from "react";
import "./halfPie.css";

class HalfPie extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
       
        <h1
          className={
            this.props.dark
              ? "paymentHead text-dark  pl-3"
              : "paymentHead text-dark pl-3"
          }
        >
          {this.props.title}
        </h1>
        <ul className={"chart-skills-" + this.props.name}>
          {this.props.data[0] && (
            <li>
              <span>{this.props.data[0].title}</span>
            </li>
          )}
          {this.props.data[1] && (
            <li>
              <span>{this.props.data[1].title}</span>
            </li>
          )}
          {this.props.data[2] && (
            <li>
              <span>{this.props.data[2].title}</span>
            </li>
          )}
          {this.props.data[3] && (
            <li>
              <span>{this.props.data[3].title}</span>
            </li>
          )}
        </ul>
        <div className="row pt-3">
          <div className="col pr-5">
            <div className=" text-center">
              <p className="paymentText1 ">{this.props.bottomOption[0].num}</p>
              <p className="paymentText2 ">{this.props.bottomOption[0].text}</p>
            </div>
          </div>
          <div className="col pl-5">
            <div className=" text-center">
              <p className="paymentText1 ">{this.props.bottomOption[1].num}</p>
              <p className="paymentText2 ">{this.props.bottomOption[1].text}</p>
            </div>
          </div>
        </div>
        {this.props.indicator&&<style>
          {`
            @keyframes rotate-one-` +
            this.props.name +
            ` {
               100% {
                 transform: rotate(` +
            (this.props.data.length > 0 ? parseInt(this.props.data[0].end) : "0") +
            `deg);
               }
             }

    @keyframes rotate-two-` +
            this.props.name +
            ` {
     0% {
       transform: rotate(` +
            (this.props.data.length > 1 ? parseInt(this.props.data[1].start) : "0") +
            `deg);
     }
     100% {
       transform: rotate(` +
            (this.props.data.length > 1 ? parseInt(this.props.data[1].end) : "0") +
            `deg);
      }
    }
   
   @keyframes rotate-three-` +
            this.props.name +
            ` {
     0% {
       transform: rotate(` +
            (this.props.data.length > 2 ? parseInt(this.props.data[2].start) : "0") +
            `deg);
     }
     100% {
       transform: rotate(` +
            (this.props.data.length > 2 ? parseInt(this.props.data[2].end) : "0") +
            `deg);
     }
   }
   
   @keyframes rotate-four-` +
            this.props.name +
            ` {
     0% {
       transform: rotate(` +
            (this.props.data.length > 3 ? parseInt(this.props.data[3].start) : "0") +
            `deg);
     }
     100% {
       transform: rotate(` +
            (this.props.data.length > 3 ? parseInt(this.props.data[3].end) : "0") +
            `deg);
     }
   }

.chart-skills-` +
            this.props.name +
            ` *,
.chart-skills-` +
            this.props.name +
            `::before {
  box-sizing: border-box;
}
.chart-skills-` +
            this.props.name +
            ` {
  position: relative;
  width:220px;
  height: 110px;
  overflow: hidden;
  margin: 0 auto ;
  margin-top: 30px;
  padding: 0;
  list-style-type: none;
}


.chart-skills-` +
            this.props.name +
            ` li {
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: inherit;
  border: 35px solid;
  border-top: none;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation-fill-mode: forwards;
  animation-duration: .4s;
  animation-timing-function: linear;
}


.chart-skills-` +
            this.props.name +
            ` span {
  position: absolute;
  font-size: .85rem;
  backface-visibility: hidden;
  animation: fade-in .4s linear forwards;
}
     .chart-skills-` +
            this.props.name +
            `::before,
     .chart-skills-` +
            this.props.name +
            `::after {
     position: absolute;
     }
   .chart-skills-` +
            this.props.name +
            `::after {
     content: '` +  this.props.centerText + `';
            left: 50%;
     bottom: 0px;
     transform: translateX(-50%);
     font-size: 1.8rem;
     font-weight: bold;
     color: rgb(0, 0, 0);
     font-family: "Montserrat", sans-serif;
   }
   .chart-skills-` +
            this.props.name +
            `::before {
  
     width: inherit;
     height: inherit;
     border: 45px solid rgba(211, 211, 211, .3);
     border-bottom: none;
     border-top-left-radius: 175px;
     border-top-right-radius: 175px;
   }
   .chart-skills-` +
            this.props.name +
            ` li:nth-child(1) {
     border-color: ` +
            (this.props.data.length > 0
              ? this.props.data[0].color
              : "#121212") +
            `;
     z-index: 4;
     animation-name: rotate-one-` +
            this.props.name +
            `;
   }
   .chart-skills-` +
            this.props.name +
            ` li:nth-child(2) {
     border-color: ` +
            (this.props.data.length > 1
              ? this.props.data[1].color
              : "#121212") +
            `;
            z-index: 3;
     animation-name: rotate-two-` +
            this.props.name +
            `;
     animation-delay: .4s;
   }
   .chart-skills-` +
            this.props.name +
            ` li:nth-child(3) {
     border-color: ` +
            (this.props.data.length > 2
              ? this.props.data[2].color
              : "#121212") +
            `;
     z-index: 2;
     animation-name: rotate-three-` +
            this.props.name +
            `;
     animation-delay: .8s;
   }
   .chart-skills-` +
            this.props.name +
            ` li:nth-child(4) {
    
     border-color: ` +
            (this.props.data.length > 3
              ? this.props.data[3].color
              : "#121212") +
            `;
     z-index: 1;
     animation-name: rotate-four-` +
            this.props.name +
            `;
     animation-delay: 1.2s;

   }
        `}
        </style>}
      </>
    );
  }
}

export default HalfPie;
