// Dark
export const changeDark = () => {
  return {
    type: "DARK",
  };
};
// Alert
export const appendAlert = (text, messageType) => {
  return {
    type: "APPEND_ALERT",
    text: text,
    messageType: messageType,
  };
};
export const changeAlertStatus = (id) => {
  return {
    type: "TOGGLE_STATUS",
    id: id,
  };
};
export const deleteAlert = (id) => {
  return {
    type: "REMOVE_ALERT",
    id: id,
  };
};
// Authorize
export const toggleAuthorize = () => {
  return {
    type: "TOGGLE_AUTHORIZE",
  };
};
export const authorize = () => {
  return {
    type: "AUTHORIZE",
  };
};
export const unAuthorize = () => {
  return {
    type: "UNAUTHORIZE",
  };
};
// Role
export const changeRole = (role) => {
  return {
    type: "CHANGE_ROLE",
    role: role,
  };
};
export const changeRoleGuest = () => {
  return {
    type: "CHANGE_ROLE_GUEST",
  };
};
export const changeRoleAdmin = () => {
  return {
    type: "CHANGE_ROLE_ADMIN",
  };
};
export const changeRoleTenant = () => {
  return {
    type: "CHANGE_ROLE_TENANT",
  };
};
export const changeRoleLandlord = () => {
  return {
    type: "CHANGE_ROLE_LANDLORD",
  };
};
export const getRole = () => {
  return {
    type: "GET_ROLE",
  };
};

//User
export const setUser = (user) => {
  return {
    type: "SET_USER",
    user: user,
  };
};
export const getUser = () => {
  return {
    type: "GET_USER",
  };
};
export const removeUser = () => {
  return {
    type: "REMOVE_USER",
  };
};

//Property

export const setProperty = (property) => {
  return {
    type: "SET_PROPERTY",
    property: property,
  };
};
export const getProperty = () => {
  return {
    type: "GET_PROPERTY",
  };
};

export const removeProperty = () => {
  return {
    type: "REMOVE_PROPERTY",
  };
};
export const removePropertyById = (id) => {
  return {
    type: "REMOVE_PROPERTY_BY_ID",
    id: id,
  };
};

//Application
export const setApplication = (application) => {
  return {
    type: "SET_APPLICATION",
    application: application,
  };
};
export const getApplication = () => {
  return {
    type: "GET_APPLICATION",
  };
};

export const removeApplication = () => {
  return {
    type: "REMOVE_APPLICATION",
  };
};
export const removeApplicationById = (id) => {
  return {
    type: "REMOVE_APPLICATION_BY_ID",
    id: id,
  };
};

//Contract
export const setContract = (contract) => {
  return {
    type: "SET_CONTRACT",
    contract: contract,
  };
};
export const getContract = () => {
  return {
    type: "GET_CONTRACT",
  };
};

export const removeContract = () => {
  return {
    type: "REMOVE_CONTRACT",
  };
};
export const removeContractById = (id) => {
  return {
    type: "REMOVE_CONTRACT_BY_ID",
    id: id,
  };
};

//Maintenance
export const setMaintenance = (maintenance) => {
  return {
    type: "SET_MAINTENANCE",
    maintenance: maintenance,
  };
};
export const getMaintenance = () => {
  return {
    type: "GET_MAINTENANCE",
  };
};

export const removeMaintenance = () => {
  return {
    type: "REMOVE_MAINTENANCE",
  };
};
export const removeMaintenanceById = (id) => {
  return {
    type: "REMOVE_MAINTENANCE_BY_ID",
    id: id,
  };
};

//RentalList
export const setRentalList = (rentalList) => {
  return {
    type: "SET_RENTAL_LIST_DATA",
    rentalList: rentalList,
  };
};
export const getRentalList = () => {
  return {
    type: "GET_RENTAL_LIST_DATA",
  };
};

export const removeRentalList = () => {
  return {
    type: "REMOVE_RENTAL_LIST_DATA",
  };
};
export const removeRentalListById = (id) => {
  return {
    type: "REMOVE_RENTAL_LIST_DATA_BY_ID",
    id: id,
  };
};

//Payment
export const setRecentPayment = (payment) => {
  return {
    type: "SET_RECENT_PAYMENT",
    payment: payment,
  };
};
export const getRecentPayment = () => {
  return {
    type: "GET_RECENT_PAYMENT",
  };
};

export const removeRecentPayment = () => {
  return {
    type: "REMOVE_RECENT_PAYMENT",
  };
};
export const removeRecentPaymentById = (id) => {
  return {
    type: "REMOVE_RECENT_PAYMENT_BY_ID",
    id: id,
  };
};

//AccountPayment
export const setAccountPaymentStatus = (paymentStatus) => {
  return {
    type: "SET_PAYMENT_ACCOUNT_STATUS",
    paymentStatus: paymentStatus,
  };
};
export const getAccountPaymentStatus = () => {
  return {
    type: "GET_PAYMENT_ACCOUNT_STATUS",
  };
};

export const removeAccountPaymentStatus = () => {
  return {
    type: "REMOVE_PAYMENT_ACCOUNT_STATUS",
  };
};

//CustomerPayment
export const setCustomerPaymentStatus = (paymentStatus) => {
  return {
    type: "SET_PAYMENT_CUSTOMER_STATUS",
    paymentStatus: paymentStatus,
  };
};
export const getCustomerPaymentStatus = () => {
  return {
    type: "GET_PAYMENT_CUSTOMER_STATUS",
  };
};

export const removeCustomerPaymentStatus = () => {
  return {
    type: "REMOVE_PAYMENT_CUSTOMER_STATUS",
  };
};

//Customer payment method details
export const setCustomerPaymentDetail = (customerPaymentDetail) => {
  return {
    type: "SET_CUSTOMER_PAYMENT_DETAIL",
    customerPaymentDetail: customerPaymentDetail,
  };
};
export const getCustomerPaymentDetail = () => {
  return {
    type: "GET_CUSTOMER_PAYMENT_DETAIL",
  };
};

export const removeCustomerPaymentDetail = () => {
  return {
    type: "REMOVE_CUSTOMER_PAYMENT_DETAIL",
  };
};
export const removeCustomerPaymentDetailById = (id) => {
  return {
    type: "REMOVE_CUSTOMER_PAYMENT_DETAIL_BY_ID",
    id: id,
  };
};

//TEMPORARY_PROPERTY_BY_UNIT_DATA
export const appendTempPropertyByUnitData = (listArray, id) => {
  return {
    type: "APPEND_TEMP_PROPERTY_BY_UNIT_DATA",
    listArray: listArray,
    id: id,
  };
};
export const appendTempPropertyByUnitDataArray = (listArray) => {
  return {
    type: "APPEND_TEMP_PROPERTY_BY_UNIT_DATA_ARRAY",
    listArray: listArray,
  };
};

export const getTempPropertyByUnitData = () => {
  return {
    type: "GET_TEMP_PROPERTY_BY_UNIT_DATA",
  };
};
export const removeTempPropertyByUnitData = () => {
  return {
    type: "REMOVE_TEMP_PROPERTY_BY_UNIT_DATA",
  };
};
export const removeTempPropertyByUnitDataById = (id) => {
  return {
    type: "REMOVE_TEMP_PROPERTY_BY_UNIT_DATA_BY_ID",
    id: id,
  };
};

//TEMPORARY_TEMP_TENANT_BY_CONTRACT
export const appendTempTenantByContract = (contract, id) => {
  return {
    type: "APPEND_TEMP_TENANT_BY_CONTRACT",
    contractArray: contract,
    id: id,
  };
};
export const appendTempTenantByContractArray = (contract) => {
  return {
    type: "APPEND_TEMP_TENANT_BY_CONTRACT_ARRAY",
    contractArray: contract,
  };
};

export const getTempTenantByContract = () => {
  return {
    type: "GET_TEMP_TENANT_BY_CONTRACT",
  };
};
export const removeTempTenantByContract = () => {
  return {
    type: "REMOVE_TEMP_TENANT_BY_CONTRACT",
  };
};
export const removeTempTenantByContractById = (id) => {
  return {
    type: "REMOVE_TEMP_TENANT_BY_CONTRACT_BY_ID",
    id: id,
  };
};

//TEMPORARY_LISTING_DATA
export const appendTempListingData = (listing) => {
  return {
    type: "APPEND_TEMP_LISTING_DATA",
    listing: listing,
  };
};
export const appendTempListingDataArray = (listArray) => {
  return {
    type: "APPEND_TEMP_LISTING_DATA_ARRAY",
    listArray: listArray,
  };
};
export const getTempListingData = () => {
  return {
    type: "GET_TEMP_LISTING_DATA",
  };
};

export const removeTempListingData = () => {
  return {
    type: "REMOVE_TEMP_LISTING_DATA",
  };
};
export const removeTempListingDataById = (id) => {
  return {
    type: "REMOVE_TEMP_LISTING_DATA_BY_ID",
    id: id,
  };
};

//TEMPORARY_USERS_DATA
export const appendTempUsers = (user) => {
  return {
    type: "APPEND_TEMP_USERS_DATA",
    user: user,
  };
};
export const getTempUsers = () => {
  return {
    type: "GET_TEMP_USERS_DATA",
  };
};

export const removeTempUsers = () => {
  return {
    type: "REMOVE_TEMP_USERS_DATA",
  };
};
export const removeTempUsersById = (username) => {
  return {
    type: "REMOVE_TEMP_USERS_DATA_BY_ID",
    username: username,
  };
};

//PaymentDashboard
export const setPaymentDashboardData = (paymentDashboardData) => {
  return {
    type: "SET_PAYMENT_DASHBOARD_DATA",
    paymentDashboardData: paymentDashboardData,
  };
};
export const getPaymentDashboardData = () => {
  return {
    type: "GET_PAYMENT_DASHBOARD_DATA",
  };
};

export const removePaymentDashboardData = () => {
  return {
    type: "REMOVE_PAYMENT_DASHBOARD_DATA",
  };
};

//Profile Update  Alert

export const setOpenProfileAlert = (open) => {
  return {
    type: "SET_OPEN_PROFILE_ALERT",
    open: open,
  };
};

export const changePercentageProfileAlert = (percentage) => {
  return {
    type: "CHANGE_PERCENTAGE_PROFILE_ALERT",
    percentage: percentage,
  };
};

//Provider
export const setProvider = (provider) => {
  return {
    type: "SET_PROVIDER",
    provider: provider,
  };
};
export const getProvider = () => {
  return {
    type: "GET_PROVIDER",
  };
};

export const removeProvider = () => {
  return {
    type: "REMOVE_PROVIDER",
  };
};
export const removeProviderById = (id) => {
  return {
    type: "REMOVE_PROVIDER_BY_ID",
    id: id,
  };
};

//TEMPORARY_USERS_IMAGE_DATA
export const appendTempProfileImage = (image) => {
  return {
    type: "APPEND_TEMP_PROFILE_IMAGES_DATA",
    image: image,
  };
};
export const getTempProfileImage = () => {
  return {
    type: "GET_TEMP_PROFILE_IMAGES_DATA",
  };
};

export const removeTempProfileImage = () => {
  return {
    type: "REMOVE_TEMP_PROFILE_IMAGES_DATA",
  };
};
export const removeTempProfileImageById = (username) => {
  return {
    type: "REMOVE_TEMP_PROFILE_IMAGES_DATA_BY_ID",
    username: username,
  };
};

//TEMPORARY_PROPERTY_IMAGE_DATA
export const appendTempPropertyImage = (image) => {
  return {
    type: "APPEND_TEMP_PROPERTY_IMAGES_DATA",
    image: image,
  };
};
export const getTempPropertyImage = () => {
  return {
    type: "GET_TEMP_PROPERTY_IMAGES_DATA",
  };
};

export const removeTempPropertyImage = () => {
  return {
    type: "REMOVE_TEMP_PROPERTY_IMAGES_DATA",
  };
};
export const removeTempPropertyImageById = (id) => {
  return {
    type: "REMOVE_TEMP_PROPERTY_IMAGES_DATA_BY_ID",
    id: id,
  };
};
