import React, { Component } from "react";
import "./ImageViewer.css";
import { withRouter } from "react-router";
import image3 from "../../../Images/gray.jpeg";
import { url } from "../../../Services/ApiServices";

class ImageViewer2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.images,
      imagesDum: [],
      imageIndex: 0,
    };
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    window.onbeforeunload = "";
    var img = [];
    if (this.props.images.length < this.props.count) {
      for (
        let index = 0;
        index < this.props.count - this.props.images.length;
        index++
      ) {
        img.push(image3);
      }
    }

    this.setState({ imagesDum: img });
  }
  next() {
    if (this.props.images.length !== this.state.imageIndex + 1) {
      this.setState({ imageIndex: this.state.imageIndex + 1 });
    }
  }
  back() {
    if (this.state.imageIndex !== 0) {
      this.setState({ imageIndex: this.state.imageIndex - 1 });
    }
  }
  render() {
    return (
      <div className="row pr-0 mr-0">
        <div className="col maintenanceImageCollection3 pr-0 mr-0">
          {this.state.images.slice(0, this.props.count).map((data, index) => (
            <div
              className={"round maintenanceImage5 pointer"}
              aria-hidden="true"
              data-target="#ExpandView"
              data-toggle="modal"
              style={{
                backgroundImage:
                  "url(" +
                  url +
                  "api/maintenance/v2/service-requests/image/download/" +
                  this.props.requestId +
                  "/" +
                  data.fileName +
                  ")",
                backgroundColor: this.props.images.length === 0 && "#dfdfdf",
              }}
              onClick={() => this.setState({ imageIndex: index })}
            ></div>
          ))}
          {this.state.imagesDum.map((data, index) => (
            <div
              className=" round maintenanceImage5 notAllow"
              style={{
                backgroundColor: "#dfdfdf",
              }}
            ></div>
          ))}
        </div>
        <div
          className=" imageViewerRightArrow"
          aria-hidden="true"
          data-target="#ExpandView"
          data-toggle="modal"
        >
          <i className="fa fa-angle-right fa-2x" aria-hidden="true"></i>
        </div>
        <div
          className="modal fade bd-example-modal-xl"
          id={"ExpandView"}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ zIndex: "1500", background: "#000", height: "100%" }}
          data-backdrop="false"
        >
          <i
            className={"fa fa-times imageViewerIconClose2 "}
            data-target="#ExpandView"
            data-toggle="modal"
          ></i>
          <div className="modal-dialog modal-xl" role="document">
            <div
              className="modal-content"
              style={{
                backgroundColor: "transparent",
                padding: "20px",
                margin: "0px",
                border: "none",
              }}
            >
              <div className="modal-body bodyAvailable2 p-0 m-0">
                <div
                  className=" maintenanceImage4"
                  style={{
                    backgroundImage:
                      "url(" +
                      url +
                      "api/maintenance/v2/service-requests/image/download/" +
                      this.props.requestId +
                      "/" +
                      this.state.images[this.state.imageIndex].fileName +
                      ")",
                  }}
                >
                  <i
                    className={
                      this.state.imageIndex === 0
                        ? "fa fa-angle-double-left imageViewerIconLeftDisable"
                        : "fa fa-angle-double-left  imageViewerIconLeft"
                    }
                    aria-hidden="true"
                    onClick={() => this.back()}
                  ></i>
                  <i
                    className={
                      this.props.images.length === this.state.imageIndex + 1
                        ? "fa fa-angle-double-right imageViewerIconRightDisable"
                        : "fa fa-angle-double-right imageViewerIconRight"
                    }
                    aria-hidden="true"
                    onClick={() => this.next()}
                  ></i>
                </div>
              </div>
              {/* <div className=" maintenanceImageCollection4">
                  {this.state.images.map((data, index) => (
                    <div
                      className={
                        this.state.imageIndex === index
                          ? " round maintenanceImage3Active pointer"
                          : "round maintenanceImage3 pointer"
                      }
                      style={{
                        backgroundImage:
                          "url(" +
                          url +
                          "api/maintenance/v2/service-requests/image/download/" +
                          this.props.requestId +
                          "/" +
                          data.fileName +
                          ")",
                        backgroundColor:
                          this.props.images.length === 0 && "#dfdfdf",
                      }}
                      onClick={() => this.setState({ imageIndex: index })}
                    ></div>
                  ))}
                  {this.state.imagesDum.map((data, index) => (
                    <div
                      className=" round maintenanceImage3 notAllow"
                      style={{
                        backgroundColor: "#dfdfdf",
                      }}
                    ></div>
                  ))}
                </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ImageViewer2);
