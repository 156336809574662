import { connect } from "react-redux";
import { Component } from "react";
import { setUser, appendTempUsers } from "../actions";
import { withRouter } from "react-router-dom";
import { userDetail } from "../Services/ApiServices";

class GetUserFullName extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getUser() {
    let users = this.props.tempUsersArray.filter(
      (todo) => todo.username === this.props.id
    );
    if (users.length === 0) {
      try {
        userDetail(this.props.id).then((response) => {
          if (response.status) {
            this.props.appendTempUsers(response.data);
            return response.data.firstName+" "+response.data.lastName;
          } else {
            return <></>;
          }
        });
      } catch (error) {}
    } else {
      return users[0].firstName+" "+users[0].lastName;;
    }
  }
  render() {
    return <>{this.getUser()}</>;
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
    userDetailArray: state.user,
    tempUsers: state.tempUsersReducer,
    tempUsersArray: state.tempUsers,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    setUser,
    appendTempUsers
  })(GetUserFullName)
);
