import React, { Component } from "react";
import "../application.css";
import logo from "../../../../Images/logo.jpeg";
import signHere from "../../../../Images/signHere.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getPropertyLists,
  urlProd,
  userListDetail,
} from "../../../../Services/ApiServices";
import { betweenDays, getFullMonth, today } from "../../../../Defined/Function";
import Loader3 from "../../../Loader/loader3";
import { PDFDocument } from "pdf-lib";
import SmallModalContainer from "../../Documents/SmallModalContainer";
import Dropzone from "react-dropzone";
import SignaturePad from "../../../UIServices/SignaturePad/SignaturePad";
import { appendAlert } from "../../../../actions";

class ContractReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tenantData: [],
      tenant2Data: [],
      tenant3Data: [],
      landLordData: [],
      data: this.props,
      listingData: [],
      today: "",
      occupants: "",
      pdfDocObject: "",
      changeBlob: true,
      signatureTab: "pad",
      whiteBack:
        "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMDAwMDAwQEBAQFBQUFBQcHBgYHBwsICQgJCAsRCwwLCwwLEQ8SDw4PEg8bFRMTFRsfGhkaHyYiIiYwLTA+PlT/wgALCAEEAisBAREA/8QAFQABAQAAAAAAAAAAAAAAAAAAAAn/2gAIAQEAAAAAqmAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/xAAUEAEAAAAAAAAAAAAAAAAAAACw/9oACAEBAAE/ABBv/9k=",
    };
    this.onChange = this.onChange.bind(this);
    this.onchangeCheck = this.onchangeCheck.bind(this);
    this.onchangeCheckOpp = this.onchangeCheckOpp.bind(this);
    this.onchangeCheckOption = this.onchangeCheckOption.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.changeSignatureObject = this.changeSignatureObject.bind(this);
    this.changeShortSignatureObject =
      this.changeShortSignatureObject.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScroll2 = this.handleScroll2.bind(this);
  }
  async onChange(e) {
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  }
  async onchangeCheck(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: checked });
  }
  async onchangeCheckOpp(e) {
    let { name, checked } = e.target;
    await this.setState({ [name]: !checked });
  }
  async onchangeCheckOption(name, value) {
    await this.setState({ [name]: value });
  }
  ModifyDate(_date) {
    if (_date) {
      let formatLowerCase = "yyyy-mm-dd";
      let formatItems = formatLowerCase.split("-");
      let dateItems = _date.split("-");
      let monthIndex = formatItems.indexOf("mm");
      let dayIndex = formatItems.indexOf("dd");
      let yearIndex = formatItems.indexOf("yyyy");
      let year = parseInt(dateItems[yearIndex]);
      let month = parseInt(dateItems[monthIndex]);
      let date = parseInt(dateItems[dayIndex]);
      return (
        month.toString().padStart(2, 0) +
        "-" +
        date.toString().padStart(2, 0) +
        "-" +
        year
      );
    } else {
      return "";
    }
  }
  async componentDidMount() {
    let today = new Date().toISOString().split("T")[0];
    this.setState({
      landLordData: this.props.UserData,
      isLoading: false,
      today: this.ModifyDate(today),
    });
    try {
      let userList = [];
      if (this.props.data.applicationData.apply[0]) {
        userList.push(
          this.props.data.applicationData.apply[0].applicant.username
        );
      }
      if (this.props.data.applicationData.apply[1]) {
        userList.push(
          this.props.data.applicationData.apply[1].applicant.username
        );
      }
      if (this.props.data.applicationData.apply[2]) {
        userList.push(
          this.props.data.applicationData.apply[2].applicant.username
        );
      }
      userListDetail(userList).then((response) => {
        if (response.status === 200) {
          if (response.data.length !== 0) {
            this.setState({ tenantData: response.data, isLoading: false });
            let occ1 = "";
           
            occ1 =
              this.ModifyString(response.data[0].firstName) +
              " " +
              this.ModifyString(response.data[0].lastName) +
              ", " +
              this.ModifyString(this.props.data.applicationData.occupant);
            this.setState({
              occupants: occ1,
            });
            getPropertyLists(
              this.props.data.applicationData.rentalEntityId
            ).then((response1) => {
              if (response1.status === 200) {
                if (response1.data.length !== 0) {
                  this.setState({
                    listingData: response1.data,
                    isLoading: false,
                  });
                  this.props.showButton();
                  this.CreatePdf();
                }
              } else {
                this.setState({
                  isLoading: false,
                });
                this.props.appendAlert(
                  "Something went wrong!, Please try again later.",
                  "danger"
                );
              }
            });
          }
        } else {
          this.setState({
            isLoading: false,
          });
          this.props.appendAlert(
            "Something went wrong!, Please try again later.",
            "danger"
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll2);
  }
  handleScroll() {
    try {
      var elementTarget = document.getElementById("shortSignDiv");
      if (
        window.scrollY >
        elementTarget.offsetTop +
          elementTarget.offsetHeight -
          window.screen.height +
          window.screen.height * 0.25
      ) {
        if (!this.props.shortSign) {
          window.$("#shortSignatureModal").modal("show");
        }
      }
    } catch (err) {}
  }
  handleScroll2() {
    try {
      var elementTarget = document.getElementById("signDiv");
      if (
        window.scrollY >
        elementTarget.offsetTop +
          elementTarget.offsetHeight -
          window.screen.height +
          window.screen.height * 0.25
      ) {
        if (!this.props.sign) {
          window.$("#signatureModal").modal("show");
        }
      }
    } catch (err) {}
  }
  ModifyString(value) {
    if (value) {
      return value.toString();
    }
    return "";
  }
  async downloadPDF() {
    if (this.state.pdfDocObject === "") {
      this.props.appendAlert("Downloading Error Found!!!", "danger");
    } else {
      const urlView = window.URL.createObjectURL(this.state.pdfDocObject);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = urlView;
      a.download = "file.pdf";
      a.click();
      window.URL.revokeObjectURL(urlView);
    }
  }
  changeTab(name, sign) {
    this.setState({ signatureTab: name });
    this.props.onChangeState(sign, null);
  }
  async refreshSign() {
    this.props.onChangeState("signObject", null);
    await this.props.onChangeState("sign", false);
    window.$("#signatureModal").modal("show");
  }
  async refreshShortSign() {
    this.props.onChangeState("shortSignObject", null);
    await this.props.onChangeState("shortSign", false);
    window.$("#shortSignatureModal").modal("show");
  }
  changeSignatureObject(object) {
    window.$("#signatureModal").modal("hide");
    this.props.onChangeState("signObject", object);
    this.props.onChangeState("sign", true);
  }
  changeShortSignatureObject(object) {
    window.$("#shortSignatureModal").modal("hide");
    this.props.onChangeState("shortSignObject", object);
    this.props.onChangeState("shortSign", true);
  }
  async CreatePdf() {
    this.setState({ changeBlob: false });
    let today = new Date().toISOString().split("T")[0];
    const formUrl = urlProd + "api/contract/template/download";
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    try {
      // // getting formdata from pdf
      // PI_AgrDtLong
      const PI_AgrDtLong = form.getTextField("PI_AgrDtLong");
      PI_AgrDtLong.setText(this.ModifyDate(today));
      PI_AgrDtLong.enableReadOnly();

      // S_Name
      const S_Name = form.getTextField("S_Name");

      S_Name.setText(
        this.ModifyString(
          this.state.landLordData && this.state.landLordData.firstName
        ) +
          " " +
          this.ModifyString(
            this.state.landLordData && this.state.landLordData.lastName
          )
      );
      S_Name.enableReadOnly();
      // S_Name2
      //   const S_Name2 = form.getTextField("S_Name2");
      // S_Name2.setText(this.ModifyString(this.state.landLordData.lastName))

      // B_Name
      const B_Name = form.getTextField("B_Name");
      B_Name.setText(
        this.ModifyString(
          this.state.tenantData && this.state.tenantData[0].firstName
        ) +
          " " +
          this.ModifyString(
            this.state.tenantData && this.state.tenantData[0].lastName
          )
      );
      B_Name.enableReadOnly();
      // B_Name2
      //   const B_Name2 = form.getTextField("B_Name2");
      // B_Name2.setText(this.ModifyString(this.state.tenantData.lastName))

      // PI_Address
      const PI_Address = form.getTextField("PI_Address");
      PI_Address.setText(
        this.ModifyString(
          this.state.listingData && this.state.listingData.address.address
        )
      );
      PI_Address.enableReadOnly();
      // PI_City
      const PI_City = form.getTextField("PI_City");
      PI_City.setText(this.state.listingData.address.city);
      PI_City.enableReadOnly();
      // PI_State
      const PI_State = form.getTextField("PI_State");
      PI_State.setText(this.state.listingData.address.state);
      PI_State.enableReadOnly();
      // PI_Zip
      const PI_Zip = form.getTextField("PI_Zip");
      PI_Zip.setText(this.state.listingData.address.zip);
      PI_Zip.enableReadOnly();
      // PI_County
      const PI_County = form.getTextField("PI_County");
      PI_County.setText(this.state.listingData.address.country);
      PI_County.enableReadOnly();
      // Term1
      form.getCheckBox("Term1").check();
      const Term1 = form.getCheckBox("Term1");
      Term1.enableReadOnly();
      // // FULL SERVICE
      // form.getCheckBox('FULL SERVICE').check(); //FULL SERVICE is provided
      // // SHORT SERVICE
      // // form.getCheckBox('SHORT SERVICE').check();//FULL SERVICE is provided
      // // CREDIT ONLY
      // // form.getCheckBox('CREDIT ONLY').check();//FULL SERVICE is provided
      // // COSIGNER
      // // form.getCheckBox('COSIGNER').check();//FULL SERVICE is provided

      // Term2
      const Term2 = form.getTextField("Term2");
      Term2.setText(
        this.ModifyString(this.state.listingData.rentalEntity.leasePeriod)
      );
      Term2.enableReadOnly();
      // LeaseStart
      const LeaseStart = form.getTextField("LeaseStart");
      LeaseStart.setText(
        this.ModifyString(this.ModifyDate(this.state.data.data.startDate))
      );
      LeaseStart.enableReadOnly();
      // LeaseEnd
      const LeaseEnd = form.getTextField("LeaseEnd");
      LeaseEnd.setText(
        this.ModifyString(this.ModifyDate(this.state.data.data.endDate))
      );
      LeaseEnd.enableReadOnly();
      // // Term3
      // form.getCheckBox('Term3').check();

      // MonthStart
      const MonthStart = form.getTextField("MonthStart");
      MonthStart.setText(this.ModifyString(""));
      MonthStart.enableReadOnly();
      // Rent1
      const Rent1 = form.getTextField("Rent1");
      Rent1.setText(
        this.ModifyString(parseFloat(this.state.data.data.rent).toFixed(2))
      );
      Rent1.enableReadOnly();
      if (this.state.data.data.rentCollectionDate === "1") {
        form.getCheckBox("Rent2").check(); // if rent on first day
        const Rent2 = form.getCheckBox("Rent2"); // if rent on first day
        Rent2.enableReadOnly();
      } else {
        form.getCheckBox("Rent3").check(); // if rent on other days
        const Rent3 = form.getCheckBox("Rent3"); // if rent on other days
        const Rent4 = form.getTextField("Rent4");
        Rent4.setText(
          this.ModifyString(this.state.data.data.rentCollectionDate)
        ); // which date?
        Rent3.enableReadOnly();
        Rent4.enableReadOnly();
      }

      //b
      // pay to listing firm
      form.getCheckBox("Rent5").check(); // pay to listing firm
      const Rent5 = form.getCheckBox("Rent5"); // pay to listing firm
      Rent5.enableReadOnly();
      //			    form.getCheckBox('Rent6').check(); // to landlord
      //			    form.getCheckBox('Rent7').check(); // others
      //			    form.getCheckBox('Rent8').check(); // other deails

      //c
      const Rent9 = form.getTextField("Rent9");
      Rent9.setText(
        this.ModifyString(parseFloat(this.state.data.data.rent).toFixed(2))
      ); // advance rent amount
      Rent9.enableReadOnly();
      const Rent10 = form.getTextField("Rent10");
      Rent10.setText(
        this.ModifyString(
          getFullMonth(new Date(this.state.data.data.startDate).getMonth() + 1)
        )
      );
      Rent10.enableReadOnly();
      //			    acroForm.setField("Rent9", "2000"); // advance rent amount
      //			    acroForm.setField("Rent10", "Yes"); //  first and other month?

      //d
      const Rent11 = form.getCheckBox("Rent11"); // pro-rated checkbox
      const Rent12 = form.getTextField("Rent12");
      const Rent13 = form.getTextField("Rent13");
      const Rent14 = form.getTextField("Rent14");
      const Rent15 = form.getTextField("Rent15");
      if (this.state.data.data.isProRent) {
        form.getCheckBox("Rent11").check(); // pro-rated checkbox
        Rent12.setText(
          this.ModifyString(this.ModifyDate(this.state.data.data.proRentFrom))
        ); // from
        Rent13.setText(
          this.ModifyString(this.ModifyDate(this.state.data.data.proRentTo))
        ); // to
        Rent14.setText(
          this.ModifyString(
            parseFloat(
              (betweenDays(
                this.state.data.data.proRentFrom,
                this.state.data.data.proRentTo
              ) /
                30) *
                parseFloat(this.state.data.data.rent).toFixed(2)
            ).toFixed(2)
          )
        ); // amount
        Rent15.setText(
          this.ModifyString(this.ModifyDate(this.state.data.data.proRentTo))
        ); // paid on
      }
      Rent11.enableReadOnly();
      Rent12.enableReadOnly();
      Rent13.enableReadOnly();
      Rent14.enableReadOnly();
      Rent15.enableReadOnly();

      //			    acroForm.setField("Rent11", ""); // pro-rated checkbox
      //			    acroForm.setField("Rent12", ""); // from
      //			    acroForm.setField("Rent13", ""); // to
      //			    acroForm.setField("Rent14", ""); // amount
      //			    acroForm.setField("Rent15", ""); // paid on

      //4
      if (this.state.data.data.isWater) {
        form.getCheckBox("U1").check();
        const U1 = form.getCheckBox("U1");
        U1.enableReadOnly();
      }
      if (this.state.data.data.isSewer) {
        form.getCheckBox("U2").check();
        const U2 = form.getCheckBox("U2");
        U2.enableReadOnly();
      }
      if (this.state.data.data.isGarbage) {
        form.getCheckBox("U3").check();
        const U3 = form.getCheckBox("U3");
        U3.enableReadOnly();
      }
      if (this.state.data.data.isOther) {
        form.getCheckBox("U4").check();
        const U4 = form.getCheckBox("U4");
        U4.enableReadOnly();
      }
      const U5 = form.getTextField("U5");
      U5.setText(this.ModifyString(this.state.data.data.otherUtility));
      U5.enableReadOnly();
      if (this.state.data.data.rentalInsurance) {
        form.getCheckBox("ri1").check();
        const ri1 = form.getCheckBox("ri1");
        ri1.enableReadOnly();
      }
      if (this.state.data.data.smokeDetectionDeviceBattery) {
        form.getCheckBox("SD1").check();
        const SD1 = form.getCheckBox("SD1");
        SD1.enableReadOnly();
      }
      if (this.state.data.data.smokeDetectionDeviceHardWired) {
        form.getCheckBox("SD2").check();
        const SD2 = form.getCheckBox("SD2");
        SD2.enableReadOnly();
      }
      const SD3 = form.getCheckBox("SD3");
      const SD4 = form.getCheckBox("SD4");
      if (this.state.data.data.smokeDetectionFireSprinkler) {
        form.getCheckBox("SD3").check();
      } else {
        form.getCheckBox("SD4").check();
      }
      SD3.enableReadOnly();
      SD4.enableReadOnly();
      const SD5 = form.getCheckBox("SD5");
      const SD6 = form.getCheckBox("SD6");
      if (this.state.data.data.smokeDetectionFireAlarm) {
        form.getCheckBox("SD5").check();
      } else {
        form.getCheckBox("SD6").check();
      }
      SD5.enableReadOnly();
      SD6.enableReadOnly();
      const SD7 = form.getCheckBox("SD7");
      const SD9 = form.getCheckBox("SD9");
      if (this.state.data.data.indoorSmoking) {
        form.getCheckBox("SD7").check();
      } else {
        form.getCheckBox("SD9").check();
      }
      SD7.enableReadOnly();
      SD9.enableReadOnly();
      const SD8 = form.getTextField("SD8");
      SD8.setText(this.ModifyString(this.state.data.data.smokingDescription));
      SD8.enableReadOnly();
      const SD10 = form.getCheckBox("SD10");
      const SD11 = form.getCheckBox("SD11");
      if (this.state.data.data.smokeDetectionEmeNotifi) {
        form.getCheckBox("SD10").check();
      } else {
        form.getCheckBox("SD11").check();
      }
      SD11.enableReadOnly();
      SD10.enableReadOnly();
      const SD12 = form.getCheckBox("SD12");
      const SD13 = form.getCheckBox("SD13");
      if (this.state.data.data.smokeDetectionEmeRelocation) {
        form.getCheckBox("SD12").check();
      } else {
        form.getCheckBox("SD13").check();
      }
      SD12.enableReadOnly();
      SD13.enableReadOnly();
      const SD14 = form.getCheckBox("SD14");
      const SD15 = form.getCheckBox("SD15");
      if (this.state.data.data.smokeDetectionEmeEvacuation) {
        form.getCheckBox("SD14").check();
      } else {
        form.getCheckBox("SD15").check();
      }
      SD14.enableReadOnly();
      SD15.enableReadOnly();
      // water checkbox

      // acroForm.setField("U1", "Yes"); // water checkbox
      // acroForm.setField("U2", "Yes"); // sewer checkbox
      // acroForm.setField("U3", "Yes"); // garbage checkbox
      //			    acroForm.setField("U4", "Yes"); // other checkbox
      //			    acroForm.setField("U5", "Yes"); // other other list

      //5
      const occ = form.getTextField("occ");
      let occ1 = "";
      occ1 =
        this.ModifyString(this.state.tenantData[0].firstName) +
        " " +
        this.ModifyString(this.state.tenantData[0].lastName) +
        ", " +
        this.ModifyString(this.props.data.applicationData.occupant);
      occ.setText(this.ModifyString(occ1));
      occ.enableReadOnly();

      // acroForm.setField("occ", application.getOccupentsName()); // other occupant names

      //footer page 1
      // acroForm.setField("Date12", "24/01/2021"); // date of sign
      const Date12 = form.getTextField("Date12");
      Date12.setText(this.ModifyDate(today));
      Date12.enableReadOnly();
      // acroForm.setField("Date22", "24/01/2021"); // date of sign
      const Date22 = form.getTextField("Date22");
      Date22.setText(this.ModifyDate(today));
      Date22.enableReadOnly();
      // acroForm.setField("Date32", "24/01/2021"); // date of sign
      const Date32 = form.getTextField("Date32");
      Date32.setText(this.ModifyDate(today));
      Date32.enableReadOnly();
      // acroForm.setField("Date42", "24/01/2021"); // date of sign
      const Date42 = form.getTextField("Date42");
      Date42.setText(this.ModifyDate(today));
      Date42.enableReadOnly();
      //page 2
      //6
      const Security1 = form.getTextField("Security1");
      const Bank = form.getTextField("Bank");
      const Branch = form.getTextField("Branch");
      const Branch2 = form.getTextField("Branch2");
      if (this.state.data.data.isSecurityDeposit) {
        Security1.setText(
          this.ModifyString(
            parseFloat(this.state.data.data.securityDeposit).toFixed(2)
          )
        );
        Bank.setText(this.ModifyString(this.state.data.data.bankName));
        Branch.setText(this.ModifyString(this.state.data.data.bankBranch));
        Branch2.setText(this.ModifyString(this.state.data.data.bankAddress));
      } else {
        Security1.setText(this.ModifyString(0.0));
        Bank.setText(this.ModifyString(""));
        Branch.setText(this.ModifyString(""));
        Branch2.setText(this.ModifyString(""));
      }

      Security1.enableReadOnly();
      Bank.enableReadOnly();
      Branch.enableReadOnly();
      Branch2.enableReadOnly();

      const CC = form.getCheckBox("CC");
      if (this.state.data.data.tenantCarpetCleaning) {
        form.getCheckBox("CC").check();
      }
      CC.enableReadOnly();

      // if (contract.getIsSecurityDeposit()) {
      //     acroForm.setField("Security1", String.valueOf(contract.getSecurityDeposit()));  // Security deposit amount
      //     acroForm.setField("Bank", contract.getBankName());
      //     acroForm.setField("Branch", "");
      //     acroForm.setField("Branch2", contract.getBankAddress());  //city of the branch
      // }
      // //7
      // acroForm.setField("CC", "Yes");  //carpet cleaning
      // form.getCheckBox("CC").check(); //carpet cleaning
      // //8
      //9
      const LC1 = form.getCheckBox("LC1");
      const LC3 = form.getCheckBox("LC3");
      const LC4 = form.getTextField("LC4");
      const LC2 = form.getTextField("LC2");
      if (this.state.data.data.isLateFee) {
        if (
          this.state.data.data.lateFeeCharging === "FIXED" ||
          this.state.data.data.lateFeeCharging === "PERCENTAGE"
        ) {
          if (this.state.data.data.lateFeeCharging === "PERCENTAGE") {
            form.getCheckBox("LC3").check();
            LC4.setText(
              this.ModifyString(
                (parseFloat(this.state.data.data.rent).toFixed(2) *
                  parseFloat(this.state.data.data.lateFeePercentage).toFixed(
                    2
                  )) /
                  100
              )
            );
          } else {
            form.getCheckBox("LC3").check();
            LC4.setText(
              this.ModifyString(
                parseFloat(this.state.data.data.lateFeeAmount).toFixed(2)
              )
            );
          }
        } else if (this.state.data.data.lateFeeCharging === "PERDAY") {
          form.getCheckBox("LC1").check();
          LC2.setText(
            this.ModifyString(
              parseFloat(this.state.data.data.lateFeeAmount).toFixed(2)
            )
          );
        }
      }
      LC1.enableReadOnly();
      LC2.enableReadOnly();
      LC3.enableReadOnly();
      LC4.enableReadOnly();
      const LC5 = form.getTextField("LC5");
      LC5.setText(this.ModifyString(parseFloat("40").toFixed(2)));
      LC5.enableReadOnly();
      // if (contract.getLateFeeCharging() == LateFeeCharging.PERDAY) {
      //     acroForm.setField("LC1", "Yes");  // per day late charge check box
      //     acroForm.setField("LC2", String.valueOf(contract.getLateFeeAmount()));  // late charge amount
      // }
      // else if (contract.getLateFeeCharging() == LateFeeCharging.FIXED) {
      //     acroForm.setField("LC3", "Yes");  // fixed late charge check box
      //     acroForm.setField("LC4", String.valueOf(contract.getLateFeeAmount()));  // fixed charge amount
      // } else {
      // }
      // acroForm.setField("LC5", "20");  // NSF charge
      //10
      const NRF1 = form.getTextField("NRF1");
      const NRF2 = form.getTextField("NRF2");
      if (this.state.data.data.isPetCharge) {
        if (!this.state.data.data.petChargeMethod) {
          NRF1.setText(
            this.ModifyString(
              parseFloat(this.state.data.data.petAmount).toFixed(2)
            )
          );
          NRF1.enableReadOnly();

          NRF2.setText(this.ModifyString("Pet Deposit"));
          NRF2.enableReadOnly();
        }
      }
      NRF1.enableReadOnly();
      NRF2.enableReadOnly();
      // if (contract.getPetChargeMethod() == PetChargeMethod.NONREFUNDABLE) {
      //     acroForm.setField("NRF1", String.valueOf(contract.getPetAmount()));  // nonrefundable amount
      //     acroForm.setField("NRF2", "PetDeposit");  // for what?
      // }
      // //11
      //12
      // form.getCheckBox("ri1").check();

      // acroForm.setField("ri1", "Yes");  // rental insurance
      // //13

      // //14
      // acroForm.setField("SD1", "Yes");  // smoke device hardwird
      // acroForm.setField("SD2", "");  // battery
      // acroForm.setField("SD3", "Yes");  // sprinkler yes
      // acroForm.setField("SD4", "");  // sprinkler no
      // acroForm.setField("SD5", "Yes");  // alarm yes
      // acroForm.setField("SD6", "");  // alarm no
      // acroForm.setField("SD7", "Yes");  // Smoking policy
      // acroForm.setField("SD8", "dddddd");  // description
      // acroForm.setField("SD9", "");  // no smoking policy
      // acroForm.setField("SD10", "Yes");  // emergency notification
      // acroForm.setField("SD11", "");  // no emergency notification
      // acroForm.setField("SD12", "Yes");  // emergency relocation
      // acroForm.setField("SD13", "");  // no emergency relocation
      // acroForm.setField("SD14", "Yes");  // emergency evacuation
      // acroForm.setField("SD15", "");  // no emergency relocation
      // //15
      // acroForm.setField("AD1", "Yes");  // leasing broker represent lessor
      // acroForm.setField("AD2", "");  //lessor and tenant
      // acroForm.setField("AD3", "Yes");  // tenant's broker lessor
      // acroForm.setField("AD4", "");  // tenant's broker tenant
      // acroForm.setField("AD5", "");  // tenant's broker lessor  and tenant
      // acroForm.setField("AD6", "");  // tenant's broker lessor  or tenant
      // //21
      // acroForm.setField("TSign1", ""); // tenant sign date
      // acroForm.setField("TSign2", ""); // tenant sign date

      // 21.MOLD DISCLOSURE
      // (Tenant side)
      const B_Address = form.getTextField("B_Address");
      B_Address.setText(
        this.ModifyString(
          this.state.tenantData && this.state.tenantData[0].address.address
        )
      );
      B_Address.enableReadOnly();
      const B_CityStateZip = form.getTextField("B_CityStateZip");
      B_CityStateZip.setText(
        this.ModifyString(
          this.state.tenantData &&
            this.state.tenantData[0].address.city +
              " " +
              this.state.tenantData[0].address.state +
              " " +
              this.state.tenantData[0].address.zip
        )
      );
      B_CityStateZip.enableReadOnly();
      const B_Phone = form.getTextField("B_Phone");
      B_Phone.setText(
        this.ModifyString(
          this.state.tenantData && this.state.tenantData[0].mobileNumber
        )
      );
      B_Phone.enableReadOnly();
      const BWTel = form.getTextField("BWTel");
      BWTel.setText(this.ModifyString(""));
      BWTel.enableReadOnly();

      const Employer = form.getTextField("Employer");
      Employer.setText(
        this.ModifyString(
          this.props.data.applicationData.apply[0] &&
            this.props.data.applicationData.apply[0].applicant
              .empPresentEmployed
        )
      );
      Employer.enableReadOnly();
      const SO_Name = form.getTextField("SO_Name");
      SO_Name.setText(
        this.ModifyString(
          this.props.data.applicationData.apply[0] &&
            this.props.data.applicationData.apply[0].applicant
              .empPresentEmployed
        )
      );
      SO_Name.enableReadOnly();
      const SB_Name = form.getTextField("SB_Name");
      SB_Name.setText(this.ModifyString(""));
      SB_Name.enableReadOnly();
      const SO_Phone = form.getTextField("SO_Phone");
      SO_Phone.setText(
        this.ModifyString(
          this.props.data.applicationData.apply[0] &&
            this.props.data.applicationData.apply[0].applicant.empPresentPhone
        )
      );
      SO_Phone.enableReadOnly();
      const SO_Email = form.getTextField("SO_Email");
      SO_Email.setText(this.ModifyString(""));
      SO_Email.enableReadOnly();

      // (Landlord side)
      const S_Address = form.getTextField("S_Address");
      S_Address.setText(
        this.ModifyString(
          this.state.landLordData && this.state.landLordData.address.address
        )
      );
      S_Address.enableReadOnly();
      const S_CityStateZip = form.getTextField("S_CityStateZip");
      S_CityStateZip.setText(
        this.ModifyString(
          this.state.landLordData &&
            this.state.landLordData.address.city +
              " " +
              this.state.landLordData.address.state +
              " " +
              this.state.landLordData.address.zip
        )
      );
      S_CityStateZip.enableReadOnly();
      const S_Phone = form.getTextField("S_Phone");
      S_Phone.setText(
        this.ModifyString(
          this.state.tenantData && this.state.landLordData.mobileNumber
        )
      );
      S_Phone.enableReadOnly();

      const LO_Name = form.getTextField("LO_Name");
      LO_Name.setText(this.ModifyString("Axallant"));
      LO_Name.enableReadOnly();
      const LB_Name = form.getTextField("LB_Name");
      LB_Name.setText(this.ModifyString(""));
      LB_Name.enableReadOnly();
      const LO_Phone = form.getTextField("LO_Phone");
      LO_Phone.setText(this.ModifyString(""));
      LO_Phone.enableReadOnly();
      const LO_Email = form.getTextField("LO_Email");
      LO_Email.setText(this.ModifyString(""));
      LO_Email.enableReadOnly();
      const LOAdr = form.getTextField("LOAdr");
      LOAdr.setText(this.ModifyString(""));
      LOAdr.enableReadOnly();

      const TSign1 = form.getTextField("TSign1");
      TSign1.setText(this.ModifyDate(today));
      TSign1.enableReadOnly();

      const TSign2 = form.getTextField("TSign2");
      TSign2.setText(this.ModifyDate(today));
      TSign2.enableReadOnly();

      const Date122 = form.getTextField("Date122");
      Date122.setText(this.ModifyDate(today));
      Date122.enableReadOnly();

      const Date222 = form.getTextField("Date222");
      Date222.setText(this.ModifyDate(today));
      Date222.enableReadOnly();
      const Date322 = form.getTextField("Date322");
      Date322.setText(this.ModifyDate(today));
      Date322.enableReadOnly();
      const Date422 = form.getTextField("Date422");
      Date422.setText(this.ModifyDate(today));
      Date422.enableReadOnly();
      const Date123 = form.getTextField("Date123");
      Date123.setText(this.ModifyDate(today));
      Date123.enableReadOnly();
      const Date223 = form.getTextField("Date223");
      Date223.setText(this.ModifyDate(today));
      const Date323 = form.getTextField("Date323");
      Date323.setText(this.ModifyDate(today));
      Date323.enableReadOnly();
      const Date423 = form.getTextField("Date423");
      Date423.setText(this.ModifyDate(today));
      Date423.enableReadOnly();
      const Date124 = form.getTextField("Date124");
      Date124.setText(this.ModifyDate(today));
      Date124.enableReadOnly();
      const Date224 = form.getTextField("Date224");
      Date224.setText(this.ModifyDate(today));
      Date224.enableReadOnly();
      const Date324 = form.getTextField("Date324");
      Date324.setText(this.ModifyDate(today));
      Date324.enableReadOnly();
      const Date424 = form.getTextField("Date424");
      Date424.setText(this.ModifyDate(today));
      Date424.enableReadOnly();
      const Date125 = form.getTextField("Date125");
      Date125.setText(this.ModifyDate(today));
      Date125.enableReadOnly();
      const Date225 = form.getTextField("Date225");
      Date225.setText(this.ModifyDate(today));
      Date225.enableReadOnly();
      const Date325 = form.getTextField("Date325");
      Date325.setText(this.ModifyDate(today));
      Date325.enableReadOnly();

      const Date425 = form.getTextField("Date425");
      Date425.setText(this.ModifyDate(today));
      Date425.enableReadOnly();

      const Date126 = form.getTextField("Date126");
      Date126.setText(this.ModifyDate(today));
      Date126.enableReadOnly();
      const Date226 = form.getTextField("Date226");
      Date226.setText(this.ModifyDate(today));
      Date226.enableReadOnly();
      const Date326 = form.getTextField("Date326");
      Date326.setText(this.ModifyDate(today));
      Date326.enableReadOnly();
      const Date426 = form.getTextField("Date426");
      Date426.setText(this.ModifyDate(today));
      Date426.enableReadOnly();
      // acroForm.setField("Date122", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign
      // acroForm.setField("", "12/12/2020"); // date of sign

      const pdfBytes = await pdfDoc.save();
      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      this.setState({ pdfDocObject: blob, changeBlob: true });
    } catch (error) {
      this.setState({ pdfDocObject: "" });
      console.log("PDF Fetching Error");
    }
  }
  render() {
    return (
      <>
        <div
          className="applicationAgreement "
          style={{ marginTop: "80px", marginBottom: "30px" }}
        >
          <div
            className="applicationAgreementCard "
            style={{ paddingBottom: "20px" }}
          >
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">Page 1 of 5</span>
              </div>
            </div>
            <div className="row applicationAgreementHead pt-0 mt-0">
              <div className="col text-left applicationAgreementHeadRight pt-0 mt-0">
                <div className="text-center">
                  <img
                    src={logo}
                    className="agreementLogo pb-0 mb-0"
                    alt="logo"
                  />

                  <h2 className="headApplicationReview1 p-3">
                    LEASE / RENTAL AGREEMENT
                  </h2>
                </div>
              </div>
            </div>
            <div className="contractAlign pt-4">
              <div className="row p-0 m-0">
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">
                    This Lease/Rental Agreement dated:
                  </span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={this.state.today}
                  ></input>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">
                    is made and entered into between
                  </span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={
                      this.ModifyString(
                        this.state.landLordData &&
                          this.state.landLordData.firstName
                      ) +
                      " " +
                      this.ModifyString(
                        this.state.landLordData &&
                          this.state.landLordData.lastName
                      )
                    }
                  ></input>{" "}
                  <br />
                  <span className="ApplicationInputLabel">Lessor </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp;&nbsp;</span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    value={""}
                    type="text"
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Lessor </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">(“Lessor”),</span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp; and</span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={
                      this.ModifyString(
                        this.state.tenantData[0] &&
                          this.state.tenantData[0].firstName
                      ) +
                      " " +
                      this.ModifyString(
                        this.state.tenantData[0] &&
                          this.state.tenantData[0].lastName
                      )
                    }
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Tenant </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp;&nbsp;</span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={""}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Tenant </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">("Tenent"), &nbsp;</span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">
                    for the “Property” commonly known as
                  </span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={this.ModifyString(
                      this.state.listingData !== [] &&
                        this.state.listingData.address &&
                        this.state.listingData.address.address
                    )}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Address </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp;&nbsp;,&nbsp;&nbsp;</span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={
                      this.state.listingData !== [] &&
                      this.state.listingData.address &&
                      this.state.listingData.address.city
                    }
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">City </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp;&nbsp;</span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={
                      this.state.listingData !== [] &&
                      this.state.listingData.address &&
                      this.state.listingData.address.state
                    }
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">State </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp;&nbsp;</span>
                </div>
                <div className="col-md p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={
                      this.state.listingData !== [] &&
                      this.state.listingData.address &&
                      this.state.listingData.address.zip
                    }
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Zip </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp;&nbsp;</span>
                </div>
                <div className=" p-0 m-0">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ maxWidth: "300px" }}
                    value={
                      this.state.listingData !== [] &&
                      this.state.listingData.address &&
                      this.state.listingData.address.country
                    }
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Country </span>
                </div>
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">&nbsp;&nbsp;.</span>
                </div>
              </div>
              <div className="row pt-4 m-0">
                <div className=" p-0 m-0 align-self-end ">
                  <span className="span7 mt-2">
                    If this Agreement is for more than one (1) year, the legal
                    description of the Property is attached as Exhibit A.
                  </span>
                </div>
              </div>

              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">1. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="headApplicationReview">
                        TERM OF AGREEMENT
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">(check one).</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-0 mt-0 pl-5">
                <div className="text-left pl-1 pt-2">
                  <span className="headApplicationReview">a. &nbsp;</span>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          <b>Lease.</b>
                          <input type="checkbox" checked={"checked"} />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        &nbsp;&nbsp;&nbsp; This Agreement is for a term of
                      </span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(
                          this.state.listingData.rentalEntity &&
                            this.state.listingData.rentalEntity.leasePeriod
                        )}
                      ></input>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">commencing on</span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(
                          this.ModifyDate(this.state.data.data.startDate)
                        )}
                      ></input>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        .&nbsp; This Agreement shall end at midnight on
                      </span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(
                          this.ModifyDate(this.state.data.data.endDate)
                        )}
                      ></input>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        Tenant must vacate the Property and surrender possession
                        on the last day of the term. If Tenant holds over
                        without the prior written consent of Lessor, Tenant
                        shall be liable for rent and all other damages sustained
                        by Lessor because of such holdover. If Tenant vacates
                        prior to the expiration of the term, the security
                        deposit shall be forfeited and Tenant shall be obligated
                        for the rent payments for the remainder of the term, or
                        until the Property has been re-rented whichever is less.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-0 mt-0 pl-5">
                <div className="text-left pl-1 pt-2">
                  <span className="headApplicationReview">b. &nbsp;</span>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          <b>Month-To-Month.</b>
                          <input
                            type="checkbox"
                            checked={this.props.value ? "checked" : ""}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        &nbsp;&nbsp;&nbsp; This Agreement is for a
                        month-to-month tenancy commencing on
                      </span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 col"
                        name="initial"
                        type="text"
                        value={this.ModifyString("")}
                      ></input>
                    </div>

                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        .&nbsp; Lessor or Tenant may terminate this Agreement
                        upon written notice at least 20 days prior to the end of
                        each monthly rental period. If any such notice is not
                        received at least 20 days in advance, then it shall not
                        be effective until the end of the following monthly
                        rental period.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">2. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        POSSESSION &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className=" text-left ">
                    <span className="span7 mt-2">
                      Tenant’s right to possession of the Property begins at the
                      commencement of the term indicated above. If, through no
                      fault of Lessor or Listing Firm, Lessor cannot deliver
                      possession of the Property to Tenant on the date indicated
                      above, Lessor shall not be liable to Tenant for damages.
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">3. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          RENT. &nbsp;
                        </span>
                      </div>
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall pay rent as follows:
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  pt-0 mt-0 pl-5">
                  <div className="text-left pl-1 pt-2">
                    <span className="headApplicationReview">a. &nbsp;</span>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="align-self-end p-0 m-0 ">
                        <span className="span7 mt-2">
                          <b>Amount and Due Date. </b>
                        </span>
                      </div>

                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          &nbsp;&nbsp;&nbsp; The rent is $
                        </span>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            parseFloat(this.state.data.data.rent).toFixed(2)
                          )}
                        ></input>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          per month, payable in advance and due on or before the
                        </span>
                      </div>
                      <div className="align-self-end p-0 m-0 ">
                        <div
                          className="outSpan "
                          style={{ marginBottom: "-10px" }}
                        >
                          <label className="checkedContainer ">
                            first day;&nbsp;&nbsp;
                            <input
                              type="checkbox"
                              checked={
                                this.ModifyString(
                                  this.state.data.data.rentCollectionDate
                                ) === "1"
                                  ? "checked"
                                  : ""
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="align-self-end p-0 m-0 ">
                        <div
                          className="outSpan "
                          style={{ marginBottom: "-10px" }}
                        >
                          <label className="checkedContainer ">
                            &nbsp;
                            <input
                              type="checkbox"
                              checked={
                                this.ModifyString(
                                  this.state.data.data.rentCollectionDate
                                ) !== "1"
                                  ? "checked"
                                  : ""
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            this.state.data.data.rentCollectionDate
                          )}
                        ></input>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          &nbsp;&nbsp;&nbsp; day of each month commencing
                        </span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          on the first month of the term.
                        </span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          Each monthly rental period shall begin on the day rent
                          is due.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-0 mt-0 pl-5 pt-2">
                  <div className="text-left pl-1 ">
                    <span className="headApplicationReview">b. &nbsp;</span>
                  </div>
                  <div className="col">
                    <div className="row ">
                      <div className=" p-0 m-0 ">
                        <span className="span7 ">
                          <b>Payments. </b>
                        </span>
                      </div>

                      <div className=" p-0 m-0  ">
                        <span className="span7">
                          &nbsp;&nbsp;&nbsp; Rent shall be paid to (check
                          one):&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                      </div>
                      <div className="align-self-end p-0 m-0 mt-1">
                        <div className="outSpan ">
                          <label className="checkedContainer ">
                            Listing Firm address;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="checkbox" checked={"checked"} />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div className="align-self-end p-0 m-0 ">
                        <div className="outSpan ">
                          <label className="checkedContainer ">
                            Lessor at the address;
                            <input
                              type="checkbox"
                              checked={this.props.value ? "checked" : ""}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className=" p-0 m-0 mt-2">
                        <span className="span7 ">&nbsp; or &nbsp;</span>
                      </div>
                      <div className=" p-0 m-0 mt-2">
                        <div className="outSpan ">
                          <label className="checkedContainer ">
                            &nbsp;
                            <input
                              type="checkbox"
                              checked={this.props.value ? "checked" : ""}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-0 mt-0 pl-5">
                  <div className="text-left pl-1 pt-2">
                    <span className="headApplicationReview">c. &nbsp;</span>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="align-self-end p-0 m-0 ">
                        <span className="span7 mt-2">
                          <b>First Month’s Rent. </b>
                        </span>
                      </div>

                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          &nbsp;&nbsp;&nbsp; Lessor acknowledges receipt of $
                        </span>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            parseFloat(this.state.data.data.rent).toFixed(2)
                          )}
                        ></input>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">as the first and</span>
                      </div>

                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            getFullMonth(
                              new Date(
                                this.state.data.data.startDate
                              ).getMonth() + 1
                            )
                          )}
                        ></input>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          month’s rent. If Lessor collects
                        </span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          last month’s rent, it can
                        </span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          {" "}
                          only be applied to the final{" "}
                        </span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          month of the term and is{" "}
                        </span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          not applicable to any other month
                        </span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2"> of the Agreement.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-0 mt-0 pl-5">
                  <div className="text-left pl-1 pt-2">
                    <span className="headApplicationReview">b. &nbsp;</span>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="align-self-end p-0 m-0 ">
                        <div
                          className="outSpan "
                          style={{ marginBottom: "-10px" }}
                        >
                          <label className="checkedContainer ">
                            <b>Pro-Rated Rent.</b>
                            <input
                              type="checkbox"
                              checked={
                                this.state.data.data.isProRent ? "checked" : ""
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">
                          &nbsp;&nbsp;&nbsp; Pro-rated rent from
                        </span>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            this.ModifyDate(this.state.data.data.proRentFrom)
                          )}
                        ></input>
                      </div>

                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">to</span>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            this.ModifyDate(this.state.data.data.proRentTo)
                          )}
                        ></input>
                      </div>

                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">is</span>
                      </div>
                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">&nbsp;&nbsp;$</span>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            parseFloat(
                              (betweenDays(
                                this.state.data.data.proRentFrom,
                                this.state.data.data.proRentTo
                              ) /
                                30) *
                                parseFloat(this.state.data.data.rent).toFixed(2)
                            ).toFixed(2)
                          )}
                        ></input>
                      </div>

                      <div className=" p-0 m-0 align-self-end ">
                        <span className="span7 mt-2">and payable on</span>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            this.ModifyDate(this.state.data.data.proRentTo)
                          )}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">4. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          UTILITIES. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall pay all utilities when due except:
                          &nbsp;&nbsp;
                        </span>
                      </div>
                      <div
                        className="align-self-end p-0 m-0 "
                        style={{ zIndex: "560" }}
                      >
                        <div
                          className="outSpan "
                          style={{ marginBottom: "-10px" }}
                        >
                          <label className="checkedContainer ">
                            water ;&nbsp;
                            <input
                              type="checkbox"
                              checked={
                                this.state.data.data.isWater ? "checked" : ""
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="align-self-end p-0 m-0 "
                        style={{ zIndex: "560" }}
                      >
                        <div
                          className="outSpan "
                          style={{ marginBottom: "-10px" }}
                        >
                          <label className="checkedContainer ">
                            sewer ;&nbsp;
                            <input
                              type="checkbox"
                              checked={
                                this.state.data.data.isSewer ? "checked" : ""
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="align-self-end p-0 m-0 "
                        style={{ zIndex: "560" }}
                      >
                        <div
                          className="outSpan "
                          style={{ marginBottom: "-10px" }}
                        >
                          <label className="checkedContainer ">
                            garbage ; &nbsp;
                            <input
                              type="checkbox"
                              checked={
                                this.state.data.data.isGarbage ? "checked" : ""
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="align-self-end p-0 m-0 ">
                        <div
                          className="outSpan "
                          style={{ marginBottom: "-10px" }}
                        >
                          <label className="checkedContainer ">
                            &nbsp;
                            <input
                              type="checkbox"
                              checked={
                                this.state.data.data.isOther ? "checked" : ""
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>

                      <div className=" p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 col"
                          name="initial"
                          type="text"
                          value={this.ModifyString(
                            this.state.data.data.otherUtility
                          )}
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">5. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          OCCUPANCY/SUBLETTING. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          The Property is rented as a private residence for the
                          following named persons: &nbsp;&nbsp;
                        </span>
                      </div>
                      <div className="col-md p-0 m-0">
                        <input
                          className="ApplicationInput1 m-0 p-0  pl-3 col"
                          name="initial"
                          type="text"
                          value={this.state.occupants}
                        ></input>
                      </div>
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall not assign this Agreement, sublet all or
                          any portion of the Property, nor give accommodation to
                          any other persons, without the prior written consent
                          of Lessor or Listing Firm.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.props.shortSign
                      ? "row pb-5 mt-0 "
                      : "row pb-5 alert mt-0 "
                  }
                  id="shortSignDiv"
                >
                  <div className="col-xl-3 pt-3 pr-4">
                    <div className="row ">
                      <div className="col">
                        <img
                          src={this.state.whiteBack}
                          className="agreementSign pb-0 mb-0"
                          alt="logo"
                        />
                        <br />
                        <span className="ApplicationInputLabel1 ">
                          Tenant’s Initials
                        </span>
                      </div>

                      <div className="col">
                        <input
                          className=" p-0  m-0 col pb-1"
                          name="initial"
                          type="text"
                          style={{ fontSize: "13px" }}
                          value={""}
                        ></input>
                        <br />
                        <span className="ApplicationInputLabel1  mt-1">
                          Date
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 pt-3 pr-4">
                    <div className="row ">
                      <div className="col">
                        <img
                          src={this.state.whiteBack}
                          className="agreementSign pb-0 mb-0"
                          alt="logo"
                        />
                        <br />
                        <span className="ApplicationInputLabel1 ">
                          Tenant’s Initials
                        </span>
                      </div>
                      <div className="col">
                        <input
                          className=" p-0  m-0 col pb-1"
                          name="initial"
                          type="text"
                          style={{ fontSize: "13px" }}
                          value={""}
                        ></input>
                        <br />
                        <span className="ApplicationInputLabel1  mt-1">
                          Date
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 pt-3 pr-4">
                    <div className="row ">
                      <div className="col">
                        <img
                          src={
                            this.props.shortSignObject !== null
                              ? this.props.shortSignObject
                              : signHere
                          }
                          className="agreementSign pb-0 mb-0"
                          alt="logo"
                        />
                        <button
                          className="buttonSquareLinePri absolut pull-right"
                          style={{ marginTop: "5px", marginRight: "-21px" }}
                          onClick={() => this.refreshShortSign()}
                        >
                          <i className="fa fa-refresh " aria-hidden="true"></i>
                        </button>
                        <br />
                        <span className="ApplicationInputLabel1 ">
                          Lessor’s Initials
                        </span>
                      </div>
                      <div className="col">
                        <input
                          className=" p-0  m-0 col pb-1"
                          name="initial"
                          type="text"
                          style={{ fontSize: "13px" }}
                          value={today()}
                        ></input>
                        <br />
                        <span className="ApplicationInputLabel1  mt-1">
                          Date
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 pt-3 pr-4">
                    <div className="row ">
                      <div className="col">
                        <img
                          src={
                            this.props.shortSignObject !== null
                              ? this.props.shortSignObject
                              : signHere
                          }
                          className="agreementSign pb-0 mb-0"
                          alt="logo"
                        />
                        <button
                          className="buttonSquareLinePri absolut pull-right"
                          style={{ marginTop: "5px", marginRight: "-21px" }}
                          onClick={() => this.refreshShortSign()}
                        >
                          <i className="fa fa-refresh " aria-hidden="true"></i>
                        </button>
                        <br />
                        <span className="ApplicationInputLabel1 ">
                          Lessor’s Initials
                        </span>
                      </div>
                      <div className="col">
                        <input
                          className=" p-0  m-0 col pb-1"
                          name="initial"
                          type="text"
                          style={{ fontSize: "13px" }}
                          value={today()}
                        ></input>
                        <br />
                        <span className="ApplicationInputLabel1  mt-1">
                          Date
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            className="applicationAgreementCard "
            style={{ paddingBottom: "20px" }}
          >
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">Page 2 of 5</span>
              </div>
            </div>
            <div className="row applicationAgreementHead pt-0 mt-0">
              <div className="col text-left applicationAgreementHeadRight pt-0 mt-0">
                <div className="text-center">
                  <h2 className="headApplicationReview1 p-3 pb-0">
                    LEASE / RENTAL AGREEMENT
                  </h2>
                  <span className="span7 ">(Continued)</span>
                </div>
              </div>
            </div>
            <div className="contractAlign pt-4">
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">6. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        SECURITY DEPOSIT. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Lessor acknowledges receipt from Tenant of the sum of $
                      </span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(
                          parseFloat(
                            this.state.data.data.securityDeposit
                          ).toFixed(2)
                        )}
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        ,which shall be deposited in a trust account in
                      </span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(this.state.data.data.bankName)}
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">Bank,</span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(
                          this.state.data.data.bankBranch
                        )}
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">Branch, in</span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(
                          this.state.data.data.bankAddress
                        )}
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        WA. Lessor or Listing Firm will give written notice of
                        any change in said depository. This deposit is security
                        for performance of Tenant’s obligations in this
                        Agreement, including but not limited to payment of rent,
                        and for any damages to and cleaning of the Property, for
                        which Tenant is responsible.
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        A written "Move In/Move Out Addendum" describing the
                        condition and cleanliness of and any damage to the
                        Property and furnishings shall be signed by Lessor or
                        Listing Firm and Tenant upon commencement of tenancy and
                        a written copy given to Tenant. No security deposit may
                        be collected unless the Move In/Move Out Addendum is
                        completed
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Within twenty-one (21) days after termination of tenancy
                        and vacation of premises (or abandonment of premises),
                        Lessor will give Tenant a full and specific statement of
                        the basis of retaining any of the deposit and a refund
                        of any portion due Tenant, delivered to Tenant
                        personally or sent U.S. first-class mail to Tenant’s
                        last known address. If the deposit is insufficient to
                        reimburse Lessor for such damages and cleaning, Tenant
                        shall pay any deficiency within fourteen (14) days of
                        Lessor’s demand.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">7. &nbsp;</span>
                </div>
                <div className="col ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        MAINTENANCE &nbsp;
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Tenant shall at all times maintain the Property,
                        including any yard and lawn, in a neat and clean
                        condition and upon termination of this Agreement will
                        leave the Property in as good condition as it is now,
                        reasonable wear and tear excepted. Tenant shall not make
                        any alterations or improvements to the Property without
                        Lessor’s prior written approval.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-0 mt-0 pl-5">
                <div className="text-left pl-1 pt-0">
                  <span className="headApplicationReview">a. &nbsp;</span>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          <b>Carpet Cleaning.</b>
                          <input
                            type="checkbox"
                            checked={
                              this.state.data.data.tenantCarpetCleaning
                                ? "checked"
                                : ""
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        &nbsp;&nbsp;&nbsp; At the end of the term,
                      </span>
                    </div>

                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        {" "}
                        Tenant shall have the carpets professionally
                      </span>
                    </div>

                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        cleaned and provide Lessor with
                      </span>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        a receipt evidencing the same.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">8. &nbsp;</span>
                </div>
                <div className="col ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        INSPECTION/SALE &nbsp;
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Lessor may enter the Property to inspect it or make
                        alterations or repairs at reasonable times and, except
                        in emergencies, shall give Tenant two days’ notice. If
                        Lessor wishes to show the Property to prospective
                        purchasers or tenants, Lessor shall provide Tenant with
                        one day’s notice.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">9. &nbsp;</span>
                </div>
                <div className="col ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        RENT LATE CHARGE/NSF CHECK &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        If any rent is not paid within five days of the due
                        date, Tenant shall pay a late charge of &nbsp; &nbsp;
                      </span>
                    </div>
                    <div
                      className="align-self-end p-0 m-0 "
                      style={{ zIndex: "560" }}
                    >
                      <div className="outSpan ">
                        <label className="checkedContainer ">
                          <span>$</span>
                          <input
                            type="checkbox"
                            checked={
                              this.state.data.data.lateFeeCharging === "PERDAY"
                                ? "checked"
                                : ""
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={
                          this.state.data.data.lateFeeCharging === "PERDAY"
                            ? this.ModifyString(
                                parseFloat(
                                  this.state.data.data.lateFeeAmount
                                ).toFixed(2)
                              )
                            : ""
                        }
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        for each day that the same is delinquent, including the
                        day of payment, up to a maximum of 10% of one month’s
                        rent; or &nbsp; &nbsp;
                      </span>
                    </div>
                    <div
                      className="align-self-end p-0 m-0 "
                      style={{ zIndex: "560" }}
                    >
                      <div className="outSpan ">
                        <label className="checkedContainer ">
                          $
                          <input
                            type="checkbox"
                            checked={
                              this.state.data.data.lateFeeCharging ===
                                "FIXED" ||
                              this.state.data.data.lateFeeCharging ===
                                "PERCENTAGE"
                                ? "checked"
                                : ""
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className=" p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={
                          this.state.data.data.lateFeeCharging === "PERCENTAGE"
                            ? this.ModifyString(
                                (parseFloat(this.state.data.data.rent).toFixed(
                                  2
                                ) *
                                  parseFloat(
                                    this.state.data.data.lateFeePercentage
                                  ).toFixed(2)) /
                                  100
                              )
                            : this.state.data.data.lateFeeCharging === "FIXED"
                            ? this.ModifyString(
                                parseFloat(
                                  this.state.data.data.lateFeeAmount
                                ).toFixed(2)
                              )
                            : ""
                        }
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Tenant shall pay a charge of&nbsp;$&nbsp;
                      </span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(parseFloat("40").toFixed(2))}
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        for each NSF check given by Tenant to Lessor. Lessor
                        shall have no obligation to redeposit any check returned
                        NSF.
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        In addition to the foregoing, Lessor may elect to
                        terminate this Agreement for nonpayment of rent. Lessor
                        shall notify Tenant of late rent and NSF check charges
                        and the same must be paid within fourteen (14) days.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">10. &nbsp;</span>
                </div>
                <div className="col ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        NONREFUNDABLE FEE. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Tenant shall pay, prior to occupancy, a nonrefundable
                        fee of $ &nbsp;
                      </span>
                    </div>
                    <div className="col-md p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3  col"
                        name="initial"
                        type="text"
                        value={
                          this.state.data.data.isPetCharge &&
                          !this.state.data.data.petChargeMethod
                            ? this.ModifyString(
                                parseFloat(
                                  this.state.data.data.petAmount
                                ).toFixed(2)
                              )
                            : ""
                        }
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">&nbsp;for&nbsp;</span>
                    </div>
                    <div className=" p-0 m-0">
                      <input
                        className="ApplicationInput1 m-0 p-0 pl-3 "
                        name="initial"
                        type="text"
                        value={
                          this.state.data.data.isPetCharge &&
                          !this.state.data.data.petChargeMethod
                            ? this.ModifyString("Pet Deposit")
                            : ""
                        }
                      ></input>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        .&nbsp; Lessor will not return this nonrefundable fee
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        under any conditions. The fee may not be used hold the
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Property for Tenant or to secure Tenant’s obligation to
                      </span>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        move in to the Property.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">12. &nbsp;</span>
                </div>
                <div className="col ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        PETS. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        No dogs, cats or other animals will be permitted on the
                        Property without a fully executed Pet Agreement (NWMLS
                        Form No. 68B).
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">13. &nbsp;</span>
                </div>
                <div className="col ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        RENTERS INSURANCE. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Renter’s insurance is available to Tenant for coverage
                        related to liability for bodily injury, property damage,
                        and for the theft, loss, or damage to Tenant’s personal
                        property.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-0 mt-0 pl-5">
                <div className="text-left pl-1 pt-0">
                  <span className="headApplicationReview">a. &nbsp;</span>
                </div>
                <div className="col">
                  <div className="row">
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          <b>Renter’s Insurance.</b>
                          <input
                            type="checkbox"
                            checked={
                              this.state.data.data.rentalInsurance
                                ? "checked"
                                : ""
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        &nbsp;&nbsp;&nbsp; Tenant shall obtain renter’s &nbsp;
                      </span>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        insurance providing &nbsp;
                      </span>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        {" "}
                        coverage for liability,&nbsp;{" "}
                      </span>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        bodily injury, property damage, &nbsp;
                      </span>
                    </div>
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        and for the theft, loss, or damage to Tenant’s personal
                        property stored on the Property. Tenant shall provide
                        Lessor a copy of the renter’s insurance policy within
                        five days of mutual acceptance of this Agreement
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.props.shortSign
                    ? "row pb-5 mt-0 "
                    : "row pb-5 alert mt-0 "
                }
              >
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            className="applicationAgreementCard"
            style={{ paddingBottom: "20px" }}
          >
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">Page 3 of 5</span>
              </div>
            </div>
            <div className="row applicationAgreementHead pt-0 mt-0">
              <div className="col text-left applicationAgreementHeadRight pt-0 mt-0">
                <div className="text-center">
                  <h2 className="headApplicationReview1 p-3 pb-0">
                    LEASE / RENTAL AGREEMENT
                  </h2>
                  <span className="span7 ">(Continued)</span>
                </div>
              </div>
            </div>
            <div className="contractAlign pt-4">
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">14. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        CARBON MONOXIDE ALARMS. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Lessor shall equip the Property with carbon monoxide
                        alarm(s) in accordance with the state building code as
                        required by RCW 19.27.530. The parties acknowledge that
                        the Brokers are not responsible for ensuring that Lessor
                        complies with RCW 19.27.530. Lessor and Tenant shall
                        hold the Brokers and their Firms harmless from any claim
                        resulting from Lessor’s failure to install a carbon
                        monoxide alarm(s) in the Property.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">15. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        SMOKE DETECTOR. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Tenant acknowledges and Lessor certifies that the
                        Property is equipped with a smoke detector(s) as
                        required by RCW 43.44.110 and that the detector(s)
                        has/have been tested and is/are operable. It is Tenant's
                        responsibility to maintain the smoke detector(s) as
                        specified by the manufacturer, including replacement of
                        batteries, if required. In addition, if the Property is
                        a multi-family building (more than one unit), Lessor
                        makes the following disclosures:
                      </span>
                    </div>
                  </div>
                  <div className="row  pt-0 mt-0 pl-2">
                    <div className="text-left pl-1 pt-0">
                      <span className="headApplicationReview">(a) &nbsp;</span>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The smoke detection device is&nbsp;&nbsp;
                          </span>
                        </div>

                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              hard-wired;&nbsp;&nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data
                                    .smokeDetectionDeviceHardWired
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              battery operated.
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data
                                    .smokeDetectionDeviceBattery
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  pt-2 mt-0 pl-2">
                    <div className="text-left pl-1 pt-0">
                      <span className="headApplicationReview">(b) &nbsp;</span>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The Building&nbsp;&nbsp;
                          </span>
                        </div>

                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              does;&nbsp;&nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data
                                    .smokeDetectionFireSprinkler
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              does not have a fire sprinkler.
                              <input
                                type="checkbox"
                                checked={
                                  !this.state.data.data
                                    .smokeDetectionFireSprinkler
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  pt-2 mt-0 pl-2">
                    <div className="text-left pl-1 pt-0">
                      <span className="headApplicationReview">(c) &nbsp;</span>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The Building&nbsp;&nbsp;
                          </span>
                        </div>

                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              does;&nbsp;&nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data.smokeDetectionFireAlarm
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              does not have a fire alarm.
                              <input
                                type="checkbox"
                                checked={
                                  !this.state.data.data.smokeDetectionFireAlarm
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  pt-2 mt-0 pl-2">
                    <div className="text-left pl-1 pt-0">
                      <span className="headApplicationReview">(d) &nbsp;</span>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data.indoorSmoking
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building has a smoking policy, as follows:
                            &nbsp;
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col align-self-end p-0 m-0 pt-2">
                          <textarea
                            className="ApplicationInputAgreement2 m-0 mt-1"
                            name="leaseTerm"
                          >
                            {this.ModifyString(
                              this.state.data.data.smokingDescription
                            )}
                          </textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  !this.state.data.data.indoorSmoking
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building does not have a smoking policy &nbsp;
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  pt-2 mt-0 pl-2">
                    <div className="text-left pl-1 pt-0">
                      <span className="headApplicationReview">(e) &nbsp;</span>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data.smokeDetectionEmeNotifi
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building has an emergency notification plan for
                            occupants, a copy of which is attached to this
                            Agreement. &nbsp;
                          </span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  !this.state.data.data.smokeDetectionEmeNotifi
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building does not have an emergency notification
                            plan for occupants.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  pt-2 mt-0 pl-2">
                    <div className="text-left pl-1 pt-0">
                      <span className="headApplicationReview">
                        (f)&nbsp;&nbsp;
                      </span>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data
                                    .smokeDetectionEmeRelocation
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building has an emergency relocation plan for
                            occupants, a copy of which is attached to this
                            Agreement.
                          </span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  !this.state.data.data
                                    .smokeDetectionEmeRelocation
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building does not have an emergency relocation
                            plan for occupants.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  pt-2 mt-0 pl-2">
                    <div className="text-left pl-1 pt-0">
                      <span className="headApplicationReview">(g) &nbsp;</span>
                    </div>
                    <div className="col">
                      <div className="row">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  this.state.data.data
                                    .smokeDetectionEmeEvacuation
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building has an emergency evacuation plan for
                            occupants, a copy of which is attached to this
                            Agreement.
                          </span>
                        </div>
                      </div>
                      <div className="row pt-2">
                        <div className="align-self-end p-0 m-0 ">
                          <div
                            className="outSpan "
                            style={{ marginBottom: "-10px" }}
                          >
                            <label className="checkedContainer ">
                              &nbsp;
                              <input
                                type="checkbox"
                                checked={
                                  !this.state.data.data
                                    .smokeDetectionEmeEvacuation
                                    ? "checked"
                                    : ""
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className=" p-0 m-0 align-self-end ">
                          <span className="span7 mt-2">
                            The building does not have an emergency evacuation
                            plan for occupants.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row  pt-2 mt-0 pl-2">
                    <div className=" p-0 m-0 align-self-end ">
                      <span className="span7 mt-2">
                        Tenant hereby acknowledges receipt of a copy of the
                        building's emergency evacuation routes.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">15. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        AGENCY DISCLOSURE. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        If real estate brokers are involved in this transaction,
                        then at the signing of this Agreement, Listing Broker
                        represents&nbsp;&nbsp;
                      </span>
                    </div>
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          Lessor;&nbsp;&nbsp;
                          <input
                            type="checkbox"
                            checked={this.props.value ? "checked" : ""}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>

                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          both Lessor and Tenant&nbsp;&nbsp;
                          <input
                            type="checkbox"
                            checked={this.props.value ? "checked" : ""}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        . Tenant’s Broker represents&nbsp;&nbsp;
                      </span>
                    </div>
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          Lessor;&nbsp;&nbsp;
                          <input
                            type="checkbox"
                            checked={this.props.value ? "checked" : ""}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          Tenant;&nbsp;&nbsp;
                          <input
                            type="checkbox"
                            checked={this.props.value ? "checked" : ""}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="align-self-end p-0 m-0 ">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          both Lessor and Tenant; &nbsp;&nbsp;
                          <input
                            type="checkbox"
                            checked={this.props.value ? "checked" : ""}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="align-self-end p-0 m-0 mt-2">
                      <div
                        className="outSpan "
                        style={{ marginBottom: "-10px" }}
                      >
                        <label className="checkedContainer ">
                          neither Lessor nor Tenant.&nbsp;&nbsp;
                          <input
                            type="checkbox"
                            checked={this.props.value ? "checked" : ""}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className=" text-left pl-2 pt-2">
                      <span className="span7 mt-2">Tenant’s Firm,</span>
                    </div>
                    <div className=" text-left pl-2 pt-2">
                      <span className="span7 mt-2">Tenant’s Firm’s</span>
                    </div>
                    <div className=" text-left pl-2 pt-2">
                      <span className="span7 mt-2">Designated Broker,</span>
                    </div>
                    <div className=" text-left pl-2 pt-2">
                      <span className="span7 mt-2">Tenant’s Broker’s</span>
                    </div>
                    <div className=" text-left pl-2 pt-2">
                      <span className="span7 mt-2">
                        Branch Manager (if any)
                      </span>
                    </div>
                    <div className=" text-left pl-2 pt-2">
                      <span className="span7 mt-2">and Tenant’s</span>
                    </div>
                    <div className=" text-left pl-2 pt-2">
                      <span className="span7 mt-2">
                        Broker’s Managing Broker (if any) represent the same
                        party that Tenant’s Broker represents. Listing Firm,
                        Listing Firm’s Designated Broker, Listing Broker’s
                        Branch Manager (if any), and Listing Broker’s Managing
                        Broker (if any) represent the same party that the
                        Listing Broker represents. If Tenant’s Broker and
                        Listing Broker are different persons affiliated with the
                        same Firm, then both Lessor and Tenant confirm their
                        consent to Designated Broker, Branch Manager (if any),
                        and Managing Broker (if any) representing both parties
                        as dual agents. If Tenant’s Broker and Listing Broker
                        are the same person representing both parties then both
                        Lessor and Tenant confirm their consent to that person
                        and his/her Designated Broker, Branch Manager (if any),
                        and Managing Broker (if any) representing both parties
                        as dual agents. All parties acknowledge receipt of the
                        pamphlet entitled “The Law of Real Estate Agency.”
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">16. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        ATTORNEYS' FEES. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        If Lessor or Tenant institutes suit against the other
                        concerning this Agreement, the prevailing party is
                        entitled to reasonable attorneys' fees and expenses.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.props.shortSign
                    ? "row pb-5 mt-0 "
                    : "row pb-5 alert mt-0 "
                }
              >
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            className="applicationAgreementCard "
            style={{ paddingBottom: "20px" }}
          >
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">Page 4 of 5</span>
              </div>
            </div>
            <div className="row applicationAgreementHead pt-0 mt-0">
              <div className="col text-left applicationAgreementHeadRight pt-0 mt-0">
                <div className="text-center">
                  <h2 className="headApplicationReview1 p-3 pb-0">
                    LEASE / RENTAL AGREEMENT
                  </h2>
                  <span className="span7 ">(Continued)</span>
                </div>
              </div>
            </div>
            <div className="contractAlign pt-4">
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">17. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        WAIVER OF SUBROGATION. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Lessor and Tenant hereby release and waive for the
                        duration of this Agreement and any extension or renewal
                        thereof their respective rights of recovery against each
                        other for any loss resulting from perils of fire and/or
                        extended coverage as defined in fire insurance policies
                        issued to either Lessor or Tenant in effect at the time
                        of the loss; provided that such waiver and release shall
                        apply only in the event such agreement does not
                        prejudice the insurance afforded by such policies.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">18. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        LOCAL ORDINANCES. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Lessor and Tenant acknowledge that there may be local
                        ordinances or regulations that require Lessor to provide
                        Tenant with certain information including, but not
                        limited to a summary of “Landlord-Tenant Laws.”
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">19. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        COMPLIANCE WITH LAWS, CC&Rs, AND RULES AND REGULATIONS.
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Tenant shall not use the Property in any way which
                        violates any law, ordinance, or governmental regulation.
                        In addition, Tenant shall abide by any applicable
                        covenants, conditions, and restrictions of record
                        (“CC&Rs”), the Rules attached to this Agreement, and any
                        other applicable Rules. Tenant acknowledges receipt of
                        any applicable CC&Rs and the Rules for the Property.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">19. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        LEAD-BASED PAINT &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        If the Property includes housing that was built before
                        1978, then the Addendum entitled "Disclosure of
                        Information on Lead-Based Paint and Lead-Based Paint
                        Hazards" (NWMLS Form 22J or equivalent), must be
                        attached to this Agreement unless this lease/rental
                        transaction is exempt from applicable federal
                        regulations.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className=" text-left pl-2">
                  <span className="headApplicationReview">20. &nbsp;</span>
                </div>
                <div className="col   ">
                  <div className="row">
                    <div className=" text-left ">
                      <span className="headApplicationReview">
                        MOLD DISCLOSURE. &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" text-left pl-2">
                      <span className="span7 mt-2">
                        Tenant acknowledges receipt of the pamphlet entitled "A
                        Brief Guide to Mold, Moisture, and Your Home."
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.props.sign
                    ? "row  pt-3 pb-4 mt-0"
                    : "row alert  pt-3 mt-0"
                }
                id="signDiv"
              >
                <div className="col">
                  <img
                    src={this.state.whiteBack}
                    className="agreementSign pb-0 mb-0"
                    alt="logo"
                  />

                  <br />
                  <span className="ApplicationInputLabel1 ">Tenant</span>
                </div>

                <div className="col">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={""}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Date </span>
                </div>
                <div className="col ">
                  <img
                    src={
                      this.props.signObject !== null
                        ? this.props.signObject
                        : signHere
                    }
                    className="agreementSign pb-0 mb-0"
                    alt="logo"
                  />
                  <button
                    className="buttonSquareLinePri absolut pull-right"
                    style={{ marginTop: "5px", marginRight: "-21px" }}
                    onClick={() => this.refreshSign()}
                  >
                    <i className="fa fa-refresh " aria-hidden="true"></i>
                  </button>
                  <br />
                  <span className="ApplicationInputLabel1 ">Lessor</span>
                </div>

                <div className="col">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={this.state.today}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Date </span>
                </div>
              </div>
              {/* <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={this.state.today}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Tenant - Date </span>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={this.state.today}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Lessor - Date </span>
                </div>
              </div> */}
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={this.ModifyString(
                      this.state.tenantData[0] &&
                        this.state.tenantData[0].address.address
                    )}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Tenant’s Present Address{" "}
                  </span>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={this.ModifyString(
                      this.state.landLordData.address &&
                        this.state.landLordData.address.address
                    )}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    Lessor’s Address{" "}
                  </span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={this.ModifyString(
                      this.state.tenantData[0] &&
                        this.state.tenantData[0].address.city +
                          " " +
                          this.state.tenantData[0].address.state +
                          " " +
                          this.state.tenantData[0].address.zip
                    )}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    City, State, Zip{" "}
                  </span>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    style={{ minWidth: "220px" }}
                    type="text"
                    value={this.ModifyString(
                      this.state.landLordData.address &&
                        this.state.landLordData.address.city +
                          " " +
                          this.state.landLordData.address.state +
                          " " +
                          this.state.landLordData.address.zip
                    )}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    City, State, Zip{" "}
                  </span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        className="ApplicationInput1 m-0 col"
                        name="initial"
                        type="text"
                        value={this.ModifyString(
                          this.state.tenantData[0] &&
                            this.state.tenantData[0].mobileNumber
                        )}
                        style={{ minWidth: "220px" }}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel">
                        {" "}
                        Home Phone{" "}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="ApplicationInput1 m-0 col"
                        name="initial"
                        type="text"
                        value={""}
                        style={{ minWidth: "220px" }}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel">
                        {" "}
                        Work Phone{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={this.ModifyString(
                      this.state.landLordData &&
                        this.state.landLordData.mobileNumber
                    )}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">Lessor’s Phone</span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={this.ModifyString(
                      this.props.data.applicationData &&
                        this.props.data.applicationData.apply[0] &&
                        this.props.data.applicationData.apply[0].applicant
                          .empPresentEmployed
                    )}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Tenant’s Employer
                  </span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={this.ModifyString(
                      this.props.data.applicationData.apply[0] &&
                        this.props.data.applicationData.apply[0].applicant
                          .empPresentEmployed
                    )}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel"> Tenant’s Firm</span>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={this.ModifyString("Axallant")}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel"> Listing Firm</span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={""}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Tenant’s Broker
                  </span>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={""}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel"> Listing Broker</span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={this.ModifyString(
                      this.props.data.applicationData.apply[0] &&
                        this.props.data.applicationData.apply[0].applicant
                          .empPresentPhone
                    )}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Tenant’s Firm’s Phone Number
                  </span>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    value={""}
                    style={{ minWidth: "220px" }}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Listing Firm’s Phone Number
                  </span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={""}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Tenant’s Broker’s E-mail Address
                  </span>
                </div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={""}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Listing Broker’s E-mail Address
                  </span>
                </div>
              </div>
              <div className="row  pt-3 mt-0">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <input
                    className="ApplicationInput1 m-0 col"
                    name="initial"
                    type="text"
                    style={{ minWidth: "220px" }}
                    value={""}
                  ></input>
                  <br />
                  <span className="ApplicationInputLabel">
                    {" "}
                    Listing Firm’s Address
                  </span>
                </div>
              </div>
              <div
                className={
                  this.props.shortSign
                    ? "row pb-5 mt-0 "
                    : "row pb-5 alert mt-0 "
                }
              >
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            className="applicationAgreementCard "
            style={{ paddingBottom: "20px" }}
          >
            <div className="row applicationAgreementHeadRight pb-0 mb-0 pt-4">
              <div className="col p-0 m-0 text-right ">
                <span className="span7 ">Page 5 of 5</span>
              </div>
            </div>
            <div className="row applicationAgreementHead pt-0 mt-0">
              <div className="col text-left applicationAgreementHeadRight pt-0 mt-0">
                <div className="text-center">
                  <h2 className="headApplicationReview1 p-3 pb-0">
                    LEASE / RENTAL AGREEMENT
                  </h2>
                  <span className="span7 ">(Continued)</span>
                </div>
              </div>
            </div>
            <div className="contractAlign pt-4 ">
              <div className="card pr-4 pl-4 pb-3">
                <span className="headApplicationReview text-center pt-2">
                  RULES
                </span>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">1. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Garbage. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall furnish his/her own garbage can and place
                          it where required for pickup.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">2. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Illegal Use. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall not use the Property for any illegal
                          purposes.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">3. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Repairs. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall promptly repair, at Tenant’s expense, any
                          broken glass in doors or windows.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">4. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Freezing. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall protect the plumbing from freezing. As a
                          minimum, Tenant shall leave the heat on low during
                          cold weather
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">5. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Drains. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall relieve stoppage of drains at Tenant’s
                          expense unless resulting from a condition existing at
                          the time Tenant moved in.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">6. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Nails/Painting. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall not drive any nails or screws into walls,
                          and shall not paint anything, without the prior
                          written consent of Lessor.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">7. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Lawns & Shrubs/Snow. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall cut and water any lawn and water any
                          shrubs, trees and landscaping so as to maintain the
                          same in as good a condition as they are presently. In
                          the event of snow, Tenant will remove the same from
                          any abutting sidewalks
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">8. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Noise/Nuisance. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall keep TV, stereo, radio and musical
                          instrument volumes low enough so that no noise
                          whatsoever shall escape from the Property. Tenant
                          shall not create or permit any other nuisance on the
                          Property.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">9. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Guests. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant is responsible for the conduct of all guests on
                          the Property and shall insure that guests comply with
                          these Rules.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">10. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Pets. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          If Lessor has given written permission for pets on the
                          Property, no pet noise whatsoever shall be allowed to
                          escape from the Property. In the case of apartments,
                          pets shall not be allowed in the halls, common spaces
                          or surrounding Property except on a leash and
                          accompanied by Tenant. It is Tenant’s responsibility
                          to clean up and dispose of any pet excrement anywhere
                          on the Property and on adjacent sidewalks, streets,
                          alleys and neighbors’ properties
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">11. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Vehicles. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Tenant shall not park or store recreation vehicles,
                          trailers, boats and inoperable or unlicensed
                          automobiles on the Property, on or in any parking area
                          provided for the Property, or on any street or alley
                          serving the Property. Tenant shall complete repairs to
                          any vehicles in these locations within 24 hours of
                          commencement.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">12. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Hallways & Common Areas. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          If there are hallways or other common areas shared
                          with other tenants, Tenant shall keep noise to a
                          minimum therein and nothing may be stored, even
                          temporarily, therein.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">13. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Fireplace Insert/Wood Stove. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Wood stoves are prohibited, unless provided by Lessor.
                          No fireplace insert may be installed without Lessor’s
                          prior written permission. If permission is given, then
                          the installation must be inspected by the applicable
                          city or county building department, at Tenant’s
                          expense, before the same is used.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">14. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Water Beds, Pianos & Heavy Objects. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          No water beds, aquariums, pianos, organs, libraries or
                          other unusually heavy objects are permitted in the
                          Property without Lessor’s written permission. As a
                          condition to permitting a water bed, Lessor may
                          require Tenant to provide and pay for water bed
                          insurance.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  pt-3 mt-0">
                  <div className=" text-left pl-2">
                    <span className="headApplicationReview">15. &nbsp;</span>
                  </div>
                  <div className="col   ">
                    <div className="row">
                      <div className=" text-left ">
                        <span className="headApplicationReview">
                          Screens. &nbsp;
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className=" text-left pl-2">
                        <span className="span7 mt-2">
                          Lessor is not obligated to provide window and/or door
                          screens. If there are any presently installed, Lessor
                          has no obligation to maintain or replace them.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.props.shortSign
                    ? "row pb-5 mt-0 "
                    : "row pb-5 alert mt-0 "
                }
              >
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={this.state.whiteBack}
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Tenant’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={""}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 pt-3 pr-4">
                  <div className="row ">
                    <div className="col">
                      <img
                        src={
                          this.props.shortSignObject !== null
                            ? this.props.shortSignObject
                            : signHere
                        }
                        className="agreementSign pb-0 mb-0"
                        alt="logo"
                      />
                      <button
                        className="buttonSquareLinePri absolut pull-right"
                        style={{ marginTop: "5px", marginRight: "-21px" }}
                        onClick={() => this.refreshShortSign()}
                      >
                        <i className="fa fa-refresh " aria-hidden="true"></i>
                      </button>
                      <br />
                      <span className="ApplicationInputLabel1 ">
                        Lessor’s Initials
                      </span>
                    </div>
                    <div className="col">
                      <input
                        className=" p-0  m-0 col pb-1"
                        name="initial"
                        type="text"
                        style={{ fontSize: "13px" }}
                        value={today()}
                      ></input>
                      <br />
                      <span className="ApplicationInputLabel1  mt-1">Date</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className={
              !this.state.changeBlob
                ? "secDisable notAllow pull-right mt-2"
                : " pri pull-right mt-2"
            }
            onClick={() => this.downloadPDF()}
            data-target="#exampleModal1"
            data-toggle="modal"
          >
            <i className="fa fa-download" aria-hidden="true"></i>
            {!this.state.changeBlob && <Loader3 transY="50px" transX="0px" />}
          </button>
        </div>
        
        {!this.props.sign && (
          <SmallModalContainer
            name="signatureModal"
            dark={this.props.dark}
            title="Sign here"
            footer="false"
            size="lg"
            
          >
            <div className="row">
              <div className="col ">
                <button
                  className={
                    this.state.signatureTab === "pad"
                      ? "mr-2 tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() => this.changeTab("pad", "signObject")}
                >
                  Draw 
                </button>
                <button
                  className={
                    this.state.signatureTab === "upload"
                      ? "mr-2  tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() => this.changeTab("upload", "signObject")}
                >
                  Upload
                </button>
              </div>
            </div>

            <div className={"tab-content  borderSec"} id="myTabContent">
              <div
                className={
                  this.state.signatureTab === "pad"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-center pt-5 pb-4">
                  <SignaturePad
                    name="applicationShortSign"
                    submitAlign="pull-right"
                    submitName="Submit"
                    type="base64"
                    value={this.props.signObject}
                    submit={this.changeSignatureObject}
                    submitDisable={true}
                    maxWidth="450"
                  />
                </div>
              </div>
              <div
                className={
                  this.state.signatureTab === "upload"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-start mb-5">
                  <Dropzone
                    accept=".jpeg,.png,.jpg"
                    maxSize={10485760}
                    onDrop={(files) => {
                      files.map((file) => {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                          if (event.target.result === undefined) {
                            this.props.appendAlert(
                              "Image size is too Long (max size = 10mb) ",
                              "danger"
                            );
                          } else {
                            this.changeSignatureObject(event.target.result);
                          }
                        };
                        reader.readAsDataURL(file);
                        return file;
                      });
                    }}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.props.signObject === null ? (
                            <div className="text-left p-4">
                              <div
                                className="dragDrop text-center  pointer"
                                style={{
                                  fontSize: "60px",
                                  minHeight: "250px",
                                  paddingTop: "70px",
                                }}
                              >
                                <div className="row">
                                  <div className="col">
                                    <i
                                      className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p className="licenseHead">
                                      Drag Or Click to Upload
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <span className="span5 textPri ">
                                Max file size is 10MB per image. JPG, PNG, or
                                GIF formats only.
                              </span>
                            </div>
                          ) : (
                            <div className=" text-center ">
                              <img
                                src={this.props.signObject}
                                className="licenseImage dragDrop pointer"
                                alt="propsImage"
                                style={{ height: "100%", width: "100%" }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <div className="col ">
                    <button
                      className="buttonSquareLinePri pull-left ml-3 mt-3"
                      onClick={() => this.changeSignatureObject(null)}
                    >
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    {this.props.signObject !== null ? (
                      <button
                        className=" mr-3 pull-right mb-3"
                        onClick={() =>
                          this.changeSignatureObject(this.props.signObject)
                        }
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="secDisable pull-right mb-3 mr-3"
                        style={{ cursor: "not-allowed" }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Please provide your signature image"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SmallModalContainer>
        )}
        {!this.props.shortSign && (
          <SmallModalContainer
            name="shortSignatureModal"
            dark={this.props.dark}
            title="Add Initials"
            footer="false"
            size="lg"
            
          >
            <div className="row">
              <div className="col ">
                <button
                  className={
                    this.state.signatureTab === "pad"
                      ? "mr-2 tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() => this.changeTab("pad", "shortSignObject")}
                >
                  Draw
                </button>
                <button
                  className={
                    this.state.signatureTab === "upload"
                      ? "mr-2  tabSignature"
                      : "mr-2  tabSignature2"
                  }
                  onClick={() => this.changeTab("upload", "shortSignObject")}
                >
                  Upload
                </button>
              </div>
            </div>

            <div className={"tab-content  borderSec"} id="myTabContent">
              <div
                className={
                  this.state.signatureTab === "pad"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-center pt-5 pb-4">
                  <SignaturePad
                    name="applicationLast"
                    submitAlign="pull-right"
                    submitName="Submit"
                    type="base64"
                    value={this.props.shortSignObject}
                    submit={this.changeShortSignatureObject}
                    submitDisable={true}
                    maxWidth="450"
                  />
                </div>
              </div>
              <div
                className={
                  this.state.signatureTab === "upload"
                    ? "tab-pane fade show active "
                    : "tab-pane fade "
                }
                id="upload"
                role="tabpanel"
                aria-labelledby="upload-tab"
              >
                <div className="d-flex justify-content-start mb-5">
                  <Dropzone
                    accept=".jpeg,.png,.jpg"
                    maxSize={10485760}
                    onDrop={(files) => {
                      files.map((file) => {
                        const reader = new FileReader();
                        reader.onload = (event) => {
                          if (event.target.result === undefined) {
                            this.props.appendAlert(
                              "Image size is too Long (max size = 10mb) ",
                              "danger"
                            );
                          } else {
                            this.changeShortSignatureObject(
                              event.target.result
                            );
                          }
                        };
                        reader.readAsDataURL(file);
                        return file;
                      });
                    }}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.props.shortSignObject === null ? (
                            <div className="text-left p-4">
                              <div
                                className="dragDrop text-center  pointer"
                                style={{
                                  fontSize: "60px",
                                  minHeight: "250px",
                                  paddingTop: "70px",
                                }}
                              >
                                <div className="row">
                                  <div className="col">
                                    <i
                                      className="fa fa-cloud-upload  fa-10x dragIcon text-dark vert-move "
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p className="licenseHead">
                                      Drag Or Click to Upload
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <span className="span5 textPri ">
                                Max file size is 10MB per image. JPG, PNG, or
                                GIF formats only.
                              </span>
                            </div>
                          ) : (
                            <div className=" text-center ">
                              <img
                                src={this.props.shortSignObject}
                                className="licenseImage dragDrop pointer"
                                alt="propsImage"
                                style={{ height: "100%", width: "100%" }}
                              />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <div className="col ">
                    <button
                      className="buttonSquareLinePri pull-left ml-3 mt-3"
                      onClick={() => this.changeShortSignatureObject(null)}
                    >
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    {this.props.shortSignObject !== null ? (
                      <button
                        className=" mr-3 pull-right mb-3"
                        onClick={() =>
                          this.changeShortSignatureObject(
                            this.props.shortSignObject
                          )
                        }
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className="secDisable pull-right mb-3 mr-3"
                        style={{ cursor: "not-allowed" }}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Please provide your signature image"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </SmallModalContainer>
        )}
        <br />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    UserData: state.user,
  };
};
export default withRouter(
  connect(mapStateToProps, { appendAlert })(ContractReview)
);
